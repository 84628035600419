import React from 'react';
import moment from 'moment';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
} from 'reactstrap';
import ReactTableBase from '@/shared/tables/table/ReactTableBase';
import Hook from 'ILHooks';
import formater from '../../../../../shared/helpers/WVFormatter';

export const modalId = 'mediaContentsCacModal';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  withSearchEngine: false,
  manualPageSize: [10, 20, 30, 40, 100],
  placeholder: 'Search...',
  sortBy: 'publishDate',
  direction: 'asc',
  useFlex: true,
};

const MediaContentsModals = () => {

  const { data: dataModal, showModal: isOpen, close } = Hook.useModalHook(modalId);


  if (!dataModal || !dataModal.deal) return false;
  const { deal, allCPApaidConversionsArray, allCPMpaidConversions } = dataModal;
  const { mediaContents } = deal;

  /* const generateMediaContentItem = (mediaContent) => {
    const { publishDate } = mediaContent;
    const publishDateMoment = moment.tz(moment.utc(mediaContent.publishDate).format('YYYY-MM-DD'), 'America/New_York');
    const CPMConversions = allCPMpaidConversions?.filter(c => moment(c.activityDatetime) >= publishDateMoment) || [];
    const CPAConversions = allCPApaidConversionsArray?.filter(c => moment.tz(moment.utc(c.datetime).format('YYYY-MM-DD'), 'America/New_York') >= publishDateMoment) || []
    console.log(CPMConversions,CPAConversions )
    return (
      <li key={mediaContent._id} >
        Published Date: {publishDate ? moment.tz(moment.utc(publishDate).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') : '-'}
        Conversions Started on PublishDate: {CPMConversions.length + CPAConversions.length}
      </li>
    )
  } */

  const data = mediaContents.map(mediaContent => {
    const { publishDate } = mediaContent;
    const publishDateMoment = moment.tz(moment.utc(mediaContent.publishDate).format('YYYY-MM-DD'), 'America/New_York');
    const CPMConversions = allCPMpaidConversions?.filter(c => moment(c.activityDatetime) >= publishDateMoment) || [];
    const CPAConversions = allCPApaidConversionsArray?.filter(c => moment.tz(moment.utc(c.datetime).format('YYYY-MM-DD'), 'America/New_York') >= publishDateMoment) || []
    console.log(CPMConversions,CPAConversions )
    return {
      publishDate,
      conversionsCount: (CPMConversions.length + CPAConversions.length),
      mediaContent,

    };
  }).sort((a, b) => new Date(a.publishDate) - new Date(b.publishDate));
  for (let i = 0; i < data.length; i++) {
    if (i + 1 < data.length) {
      let conversionsBetween = data[i].conversionsCount - data[i + 1].conversionsCount;
      data[i].conversionsBetween = conversionsBetween;
    } else {
      data[i].conversionsBetween = data[i].conversionsCount;
    }
  }
  console.log(data, 'DATA');


  const columns = [
    {
      Header: 'Media Content',
      accessor: p => `${p?.mediaContent.title}`,
      id: 'mediaContent',
      Cell: p => p.value,
      width: 300,
    },
    {
      Header: 'Publish Date',
      accessor: p => `${p?.publishDate}`,
      id: 'publishDate',
      Cell: p => p.value ? moment.tz(moment.utc(p.value).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') : '-',
      width: 100,
    },
    {
      Header: 'Conversions Count Between Media Contents',
      accessor: p => (p?.conversionsBetween || 0),
      id: 'count Between',
      Cell: p => formater.formatIntNumber(p.value),
      width: 100,
    },
    {
      Header: 'Conversions Count to the End',
      accessor: p => Number(p?.conversionsCount || 0),
      id: 'count',
      Cell: p => formater.formatIntNumber(p.value),
      width: 100,
    },

  ]
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => close()}
      style={{ minWidth: '670px' }}
    >
      <ModalHeader
        toggle={() => close()}
        tag="h4"
        className="modal-title-statistics pl-0"
      >
        Multiple Content Published on Deal
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="ltr-support">
            <ReactTableBase
              key="searchable"
              columns={columns}
              data={data}
              tableConfig={tableConfig}
            />
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" onClick={() => close()}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};


export default MediaContentsModals;
