import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import CreatorInList from './CreatorInList';
import TableCellContextMenu from './TableCellContextMenu';
import UserPropertyType from './index';
import TargetBrand from './TargetBrand';
// import DefaultApprovalStatus from './DefaultApprovalStatus';
import HsDealInList from './DealInList';
import ContactOwner from './CSP';
import NumberOfVideos from './NumberOfVideos';
import V30 from './V30';
import Amount from './Amount';
import MatTableList from '../../../../../../../shared/tables/materialTable/MatTableList';
import { formatIntNumber, getServerDate, formatCurrency } from '../../../../../../../shared/helpers/WVFormatter';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { DeleteCpmCreatorModalId } from '../../../CpmInsertionOrderProspects/components/modals/DeleteCpmCreatorFromOrder';
import { modalId } from '../modals/RefreshStatisticsOptionsModal';
import { modalId as publishModalId } from '../modals/PublishLookerProspect';


const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

const Table = React.memo(({
  action,
  resource,
  brands,
  version,
  setVersion,
  handleSelectCheckbox,
  recordsRef,
  setSingleSelectedProspectId,
  // setRefreshStatisticsOptionsModalVisible,
  setShowDealsListModal,
  setDeals,
  setShowDeleteProspectModal,
  setShowAddToInsertionOrderModal,
  dispatch,
  dealStagesOptions,
}) => (
  <MatTableList
    action={action}
    resource={resource}
    date={new Date()}
    version={version}
    UserPropertyType={UserPropertyType}
    showFilters={false}
    showTitle={false}
    sortBy="createdAt"
    direction="desc"
    v={2}
    noActions
    isResizable={false}
    useFlex
    useCheckboxes
    checkboxHandler={handleSelectCheckbox}
    recordsRef={recordsRef}
    enableWarning={false}
    notFoundProps={{ title: 'No CPM Deals found' }}
    useUnflatten
    customColumns={[
        {
          id: 'creatorName',
          Header: () => 'Creator Name',
          accessor: record => (record?.params?.influencerName || ''),
          Cell: p => useMemo(
            () => (
              <CreatorInList
                record={p.row.original}
              />
            ),
            [p.row.original],
          ),
          width: 350,
          className: 'd-flex align-items-center',
        },
        {
          id: 'createdAt',
          Header: () => 'Date Added',
          accessor: record => (record?.params?.createdAt || ''),
          Cell: p => useMemo(() => (
            p.row.original.params?.createdAt ? (
              <div style={{ width: '160px', paddingRight: '10px' }}>
                <span>{getServerDate(p.row.original.params.createdAt)}</span>
              </div>
            ) : null), [p.row.original]),
          width: 80,
          className: 'd-flex align-items-center',
        },
      {
        id: 'cpmInsertionOrderName',
        Header: () => 'Insertion Order No.',
        accessor: record => (record?.params?.cpmInsertionOrderName || ''),
        Cell: p => useMemo(() => (
          <div
            style={{ width: '100%' }}
          >
            {p.row.original.params.cpmInsertionOrderName !== '–' ? (
              <a
                href={`/research/insertion-order-prospects/${p.row.original.params.cpmInsertionOrder}`}
                target="_blank"
                rel="noreferrer"
              >
                {p.row.original.params.cpmInsertionOrderName}
              </a>) :
              p.row.original.params.cpmInsertionOrderName || '–'
            }
          </div>), [p.row.original]),
        width: 80,
        className: 'd-flex align-items-center',
        sortable: false,
      },
        {
          id: 'adminUser',
          Header: () => 'Deal Owner',
          Cell: p => useMemo(() => (
            <ContactOwner
              record={p.row.original}
              afterSaveFunc={() => setVersion((new Date()).toString())}
              updateRecordFunction={p.updateRecordFunction}
            />), [p.row.original]),
          width: 155,
          className: 'd-flex align-items-center',
          sortable: false,
        },
        {
          id: 'targetBrandName',
          Header: () => 'Target Brand',
          Cell: p => useMemo(() => (
            <TargetBrand
              record={p.row.original}
              afterSaveFunc={() => setVersion((new Date()).toString())}
              brands={brands}
              updateRecordFunction={p.updateRecordFunction}
            />), [p.row.original]),
          width: 130,
          className: 'd-flex align-items-center',
          sortable: false,
        },
        /* {
          id: 'approvalStatus',
          Header: () => 'IL Approval Status',
          Cell: p => useMemo(() => (
            <DefaultApprovalStatus
              val={p.row.original.params?.approvalStatus?.status || ''}
              notesVal={p.row.original.params?.notes || ''}
              dealJustificationVal={p.row.original.params?.dealJustification || ''}
            />), [p.row.original]),
          width: 220,
          className: 'd-flex align-items-center',
          sortable: false,
        },
        {
          id: 'brandApprovalStatus',
          Header: () => 'Brand Approval Status',
          Cell: p => useMemo(() => (
            <DefaultApprovalStatus
              val={p.row.original.params?.approvalsBrandStatus?.status || ''}
              notesVal={p.row.original.params?.brandNotes || ''}
            />), [p.row.original]),
          width: 220,
          className: 'd-flex align-items-center',
          sortable: false,
        }, */
        {
          id: 'dealName',
          Header: () => 'Deal',
          accessor: record => (record?.params?.dealName || ''),
          Cell: p => useMemo(() => (
            <HsDealInList
              record={p.row.original}
              openDealsModal={(deals) => {
                setDeals(deals);
                setShowDealsListModal(true);
              }}
            />), [p.row.original]),
          width: 150,
          className: 'd-flex align-items-center',
        },
        {
          id: 'dealStage',
          Header: () => 'Deal Stage',
          Cell: p => useMemo(() => {
            let dealstage = 'Not Assigned';
            if (p.row.original.params.dealObject?.dealstage) {
              if (dealStagesOptions.length > 0) {
                // eslint-disable-next-line eqeqeq
                const dealstageObject = dealStagesOptions.find(dealStagesOption => dealStagesOption.value == p.row.original.params.dealObject.dealstage);
                if (dealstageObject && dealstageObject.label) dealstage = dealstageObject.label;
              }
            }
            return (
              <div
                style={{ width: '100%' }}
              >
                {dealstage}
              </div>);
            }, [p.row.original]),
          width: 80,
          className: 'd-flex align-items-center',
        },
        {
          id: 'v30',
          Header: () => 'V30',
          accessor: record => (record?.params?.v30),
          Cell: p => useMemo(() => (
            <V30
              record={p.row.original}
              updateRecordFunction={p.updateRecordFunction}
            />
          ), [p.row.original]),
          width: 120,
          className: 'd-flex align-items-center',
        },
        {
          id: 'numVideos.numVideos',
          Header: () => 'No. Videos',
          accessor: record => (record?.params?.numVideos?.numVideos || 0),
          Cell: p => useMemo(() => (
            <NumberOfVideos
              record={p.row.original}
              updateRecordFunction={p.updateRecordFunction}
            />), [p.row.original]),
          width: 120,
          className: 'd-flex align-items-center',
        },
        {
          id: 'amount.amount',
          Header: () => 'Price Per Video',
          accessor: record => (record?.params?.amount?.amount || 0),
          Cell: p => useMemo(() => (
            <Amount
              record={p.row.original}
              updateRecordFunction={p.updateRecordFunction}
            />), [p.row.original]),
          width: 180,
          className: 'd-flex align-items-center',
        },
        {
          id: 'totalImpressions',
          Header: () => 'Total Impressions',
          accessor: record => (record?.params?.totalImpressions),
          Cell: p => useMemo(() => (
            <div
              style={{ width: '100%' }}
            >
              {formatIntNumber(Math.ceil(Number(p.row.original.params?.totalImpressions || 0)))}
            </div>
          ), [p.row.original]),
          width: 120,
          className: 'd-flex align-items-center',
        },
        {
          id: 'totalPrice',
          Header: () => 'Total Price',
          accessor: record => (record?.params?.totalPrice),
          Cell: p => useMemo(() => (
            <div
              style={{ width: '100%' }}
            >
              {formatCurrency(Number(p.row.original.params.totalPrice || 0))}
            </div>
          ), [p.row.original]),
          width: 120,
          className: 'd-flex align-items-center',
        },
        {
          id: 'cpm',
          Header: () => 'CPM',
          accessor: record => (record?.params?.cpm || 0),
          Cell: p => useMemo(() => (
            <div
              style={{ width: '100%' }}
            >
              {formatCurrency(Number(p.row.original.params.cpm || 0))}
            </div>
          ), [p.row.original]),
          width: 120,
          className: 'd-flex align-items-center',
        },
        {
          Header: '',
          accessor: () => null,
          id: 'cpm_prospect_actions',
          width: 50,
          Cell: (p) => {
            const addToIO = {
              label: 'Add to Insertion Order',
              handler: () => {
                setSingleSelectedProspectId(p.row.original.id);
                setShowAddToInsertionOrderModal(true);
              },
            };
            const removeFromIO = {
              label: 'Remove from Insertion Order',
              handler: () => dispatch(showModalById(DeleteCpmCreatorModalId, { record: p.row.original })),
              disabled: (p.row.original?.populated?.cpmInsertionOrder?.params?.status?.status === 'Signed'),
            };
            const contextMenuOptions = [
              p.row.original.populated.cpmInsertionOrder ? removeFromIO : addToIO,
              { type: 'divider', label: 'divider0' },
              {
                label: 'Delete',
                handler: () => {
                  setSingleSelectedProspectId(p.row.original.id);
                  setShowDeleteProspectModal(true);
                },
                disabled: (p.row.original.populated.cpmInsertionOrder || p.row.original.populated.cpmInvoice),
              },
              { type: 'divider', label: 'divider1' },
              { label: 'View Creator Profile', handler: () => goToPage(`/influencer/profile/${p.row.original.params.influencer}`) },
              { label: 'View HubSpot Contact', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.row.original.params.hsUserId}/`) },
            ];

            if (p.row.original.params.dealObject?.hs_object_id) {
              contextMenuOptions.push({ label: 'View HubSpot Deal', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original.params.dealObject.hs_object_id}/`) });
            }
            contextMenuOptions.push({ type: 'divider', label: 'divider4' });
            contextMenuOptions.push({ label: 'Publish Looker Update Data', handler: () => dispatch(showModalById(publishModalId, { record: p.row.original })), disabled: (!p.row.original?.populated?.cpmInsertionOrder) });
            

            contextMenuOptions.push({ type: 'divider', label: 'divider2' });
            contextMenuOptions.push({
              label: 'Refresh Statistics',
              handler: () => dispatch(showModalById(modalId, { record: p.row.original })),
            });

            return (
              <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                <TableCellContextMenu options={contextMenuOptions} />
              </div>
            );
          },
          disableSortBy: true,
          className: 'd-flex align-items-center align-middle',
        },
      ]}
  />
), (a, a1) => _.isEqual(a.version, a1.version)
  && _.isEqual(a.brands, a1.brands)
  && _.isEqual(a.dealStagesOptions, a1.dealStagesOptions));

Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  setVersion: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSelectCheckbox: PropTypes.func.isRequired,
  dealStagesOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  recordsRef: PropTypes.any.isRequired,
  setSingleSelectedProspectId: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  setDeals: PropTypes.func.isRequired,
  setShowDealsListModal: PropTypes.func.isRequired,
  setShowDeleteProspectModal: PropTypes.func.isRequired,
  setShowAddToInsertionOrderModal: PropTypes.func.isRequired,
};

export default connect()(Table);
