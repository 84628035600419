/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React from 'react';
import { unflatten } from 'flat';
import { Modal, ButtonToolbar, Button } from 'reactstrap';
import PropTypes from 'prop-types';

const ProgressModal = ({
  modal,
  setModalVisible,
  record,
  r,
}) => {
  const prospect = unflatten(record);
  console.log(prospect);
  return (
    <Modal
      isOpen={modal}
      toggle={() => setModalVisible(false)}
    >
      <div className="modal__header">
        <h3 className="text-modal  modal__title">Progress Report</h3>
      </div>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <div className="mt-1 mb-4">
          <h4>Timeout Logs</h4>
          <hr className="mt-1" />
          <div>
            <div className="pl-3"><pre><small>{r?.timeOutLogrray?.length > 0 ? r?.timeOutLogrray.join('\n') : '-'}</small></pre></div>
          </div>
        </div>
        <div className="mt-1 mb-4">
          <h4>Latest Logs</h4>
          <hr className="mt-1" />
          <div>
            <div className="pl-3"><pre><small>{r?.latestLogArray?.length > 0 ? r?.latestLogArray.map(t => t.join('\n')).join('\n') : '-'}</small></pre></div>
          </div>
        </div>
      </div>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => setModalVisible(false)}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

ProgressModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  r: PropTypes.objectOf(PropTypes.any).isRequired,
};

ProgressModal.defaultProps = {

};

export default ProgressModal;
