// import IconButton from '@material-ui/core/IconButton';
// import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
// import DatePicker from 'react-datepicker';
// import Tooltip from '@material-ui/core/Tooltip';
import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import Select, { components } from 'react-select';
import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import SelectAsync from 'react-select/async';
import _ from 'lodash';
import { Row, Col, Button, Container, Card, CardBody, CardTitle, Label, FormGroup } from 'reactstrap';
import Filter from '../FilterNew';
import Columns from './Columns';
import Total from './Total';
import Errors from '../ErrorsV2';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import DownloadCSV from './DownloadCSV';
import GenerationInfo from '../GenerationInfoV2';
import { getConstant } from '../../../../shared/helpers/WVConstants';
import CreatorModal from './CreatorModal';
import {
  convertDealsForCreators,
  groupByDealResp,
  groupByCreator,
  // selectDealByEmployeeID,
} from '../ReportGroupBy';
import { fetchDictionary } from '../../../../shared/helpers/WVRequest';
import LoadingReport from "../../../../shared/components/LoadingReport";


const calculateForDeal = (deal) => {
  // console.log(deal.deal.dealname, deal.totalPayoutWithMulligan);
  // const a = deal.totalRevenue - deal.totalPayoutWithMulligan;
  const a = (deal.remainingRevenueConversionsAmount + deal.payableRevenueAmount + (Number(deal.billRevenue || 0))) - (deal.payablePayoutAmount) -
  (deal.totalPayoutWithMulligan !== 0 ? (deal.remainingPayoutConversionsAmount + deal.billPayout) : 0);
  return a;

  /* let a = deal.payables.reduce((accumulator, currentValue) => {
    // eslint-disable-next-line
    accumulator += Number(currentValue.profit || 0);
    return accumulator;
  }, 0);
  if (deal.bill) {
    a -= Number(deal.billTotal);
  }
  if (deal.invoice) {
    a += Number(deal.invoiceTotal);
  }
  if (deal.currentMonthPayout) {
    a -= Number(deal.currentMonthPayout);
  }
  if (deal.currentMonthRevenue) {
    a += Number(deal.currentMonthRevenue);
  }
  return Number(a || 0); */
};
const calculateRevenueForDeal = (deal) => {
  const a = deal.remainingRevenueConversionsAmount + deal.payableRevenueAmount + (Number(deal.billRevenue || 0));
  return a;
  /* let a = deal.payables.reduce((accumulator, currentValue) => {
    // eslint-disable-next-line
    accumulator += Number(currentValue.revenueAmount || 0);
    return accumulator;
  }, 0);
  a += deal.conversionsForCalculation.reduce((accumulator, currentValue) => {
    // eslint-disable-next-line
    accumulator += Number(currentValue.revenue || 0);
    return accumulator;
  }, 0);

  a += 0;
  return Number(a || 0); */
};

const formatCreatorOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div>{record?.params.first_name} {record?.params.last_name}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record?.params.email}
    </div>
  </div>
);

const creatorOption = (props) => {
  const { record } = props.data;
  return (
    <components.Option {...props} >
      <div>
        <div className="bold-text">{record.params.first_name} {record.params.last_name}</div>
        <div >{record.params['affiliateDataObject.company']}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record.params.email}
        </div>
      </div>
    </components.Option>
  );
};
const SummaryReport = ({ addNotice }) => {
  const [loading, setLoading] = useState(false);
  const [showId, setShowId] = useState(null);
  const [loadingAdvertiser, setLoaingAdvertiser] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [selectedContentType, setSelectedContentType] = useState([]);
  // const [offers, setOffers] = useState([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState(null);
  // const [selectedOffer, setSelectedOffer] = useState(null);
  const [generatedData, setGeneratedData] = useState({ advertiser: null, creators: [] });
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [items, setItems] = useState([]);
  const [dealsArray, setDeals] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [isByDate, setIsByDate] = useState(false);
  const [errors, setErrors] = useState([]);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [tags, setTags] = useState([]);
  // const [pTags, setPTags] = useState([]);
  const [pCategories, setPCategories] = useState([]);
  // const [pToTags, setPToTags] = useState([]);
  const [pToCategories, setPToCategories] = useState([]);
  const [mc, setMc] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  // const [selectedProspectTags, setSelectedProspectTags] = useState([]);
  const [selectedProspectCategories, setSelectedProspectCategories] = useState([]);
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedDealType, setSelectedDealType] = useState([]);
  const [stats, setStats] = useState({
    grossRevenue: 0, grossProfit: 0, clicks: 0, conversions: 0, count: 0,
  });
  const [fetched, setFetched] = useState(false);
  // const [error, setError] = useState(null);
  // const [version, setVersion] = useState((new Date()).toString());
  const processDeals = (dealsToFetch) => {
    const rightDeals = [];
    let grossRevenue = 0;
    let grossProfit = 0;
    let clicks = 0;
    // let conversions = 0;
    const selectedContentTypesArray = selectedContentType?.map(a => a.value) || null;
    const selectedEmployeeArray = selectedEmployee?.map(a => a.value) || null;

    // console.log(selectedContentTypesArray);
    // console.log(mc);
    // const countwithConversions = {};
    dealsToFetch.forEach((deal) => {
      // console.log(deal?.dealTerms?.content_type);
      if (!deal?.influencer?.affiliateDataObject?.tune_id) return false;
      console.log(mc[deal?.influencer._id]);
      if (selectedStage && selectedStage.value === 1 && mc[deal?.influencer._id] > 1) {
        return false;
      }
      if (selectedStage && selectedStage.value === 2 && (!mc[deal?.influencer._id] || mc[deal?.influencer._id] <= 1)) {
        return false;
      }
      const ids = selectedDealType?.map(a => a.value);
      if (selectedDealType?.length > 0 && !ids.includes(deal?.deal?.dealTerms?.type_of_deal)) {
        if (!(ids.includes('Upfront CPM') && Number(deal.cpmConversionsCount || 0) > 0)) return false;
      }
      if (selectedContentTypesArray &&
        selectedContentTypesArray.length > 0 &&
        !selectedContentTypesArray.includes(deal?.deal?.dealTerms?.content_type)
      ) return false;
      if (selectedEmployeeArray &&
        selectedEmployeeArray.length > 0 &&
        !selectedEmployeeArray.includes(deal?.adminAF?._id)
      ) return false;
      // if (selectedTags && !selectedTags.some(item => tags.includes(item))) return false;
      if (selectedTags?.length > 0) {
        const fields = tags.filter(tag => selectedTags.map(t => t.value).includes(tag.tagName));
        console.log(fields, deal?.influencer._id);
        if (!fields.some(f => f.creators.includes(deal?.influencer._id))) return false;
      }
      if (selectedProspectCategories?.length > 0) {
        // console.log(selectedProspectCategories);
        if (!deal.influencer.hsContactVid || !pToCategories[deal.influencer.hsContactVid.toString()]) return false;
        if (!selectedProspectCategories.some(pCat => pToCategories[deal.influencer.hsContactVid.toString()].includes(pCat.value))) return false;
      }
      rightDeals.push(deal);
      // rightDeals.push(selectedEmployeeArray.length > 0 ? selectDealByEmployeeID(deal, selectedEmployeeArray): deal);
      grossRevenue += deal.deal ? calculateRevenueForDeal(deal) : 0;
      grossProfit += deal.deal ? calculateForDeal(deal) : 0;
      clicks += Number(deal.report?.clicks || 0);
      // conversions += Number(deal.conversionsArray?.length || 0);
      return true;
    });
    
    const influencers = groupByCreator(rightDeals);
    console.log(influencers);
    let totalConversion = 0;
    Object.values(influencers).forEach((influencer) => {
      influencers[influencer.tuneId].categories = pToCategories[influencer.hsContactVid.toString()];
      // const cpmCount = cpmConversions.find(d => d._id === influencer.influencer._id);
      influencers[influencer.tuneId].conversions += Number(influencers[influencer.tuneId].cpmConversionsCount || 0);
      totalConversion += influencers[influencer.tuneId].conversions;
      influencers[influencer.tuneId].countMediaContent = mc[influencer.influencer._id];
    });

    const emptyDeal = dealsToFetch.find(d => !d.influencer);
    // console.log(pToTags);
    // console.log(influencers);
    const filteredInfluencers = Object.values(influencers);
    // console.log('ITEMS: ', filteredInfluencers);
    setStats({
      grossRevenue,
      grossProfit,
      clicks,
      conversions: (totalConversion) + Number(emptyDeal?.cpmConversionsCount || 0),
      count: filteredInfluencers.filter(c => c.conversions > 0).length,
    });
    setItems(filteredInfluencers);
  };
  useEffect(() => {
    processDeals(dealsArray);
  }, [selectedContentType, selectedEmployee, selectedTags, selectedStage, selectedProspectCategories, pToCategories, dealsArray, selectedDealType]);
  useEffect(() => {
    console.log('Click Regenerate');
  }, [selectedAdvertisers, selectedInfluencers]);
  useEffect(() => {
    const api = new ApiClient();
    setLoaingAdvertiser(true);
    api.client.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      // setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands));
      setLoaingAdvertiser(false);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
    api.client.get('/api/portal/finance/get-deal-owners').then((resp) => {
      setEmployees(resp.data.dealOwners);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load employees, Check out console to see more information.',
        type: 'error',
      });
    });
    api.client.get('/api/portal/reports/get-tags').then((resp) => {
      setTags(resp.data.tags);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load Tags, Check out console to see more information.',
        type: 'error',
      });
    });
    // api.client.get('/api/portal/reports/get-prospect-tags').then((resp) => {
    //   setPTags(resp.data.prospectTags);
    // }).catch(() => {
    //   setLoaingAdvertiser(false);
    //   addNotice({
    //     message: 'There was an error when try load Tags, Check out console to see more information.',
    //     type: 'error',
    //   });
    // });
    fetchDictionary('PROSPECT_CATEGORIES').then((data) => {
      const { success, records } = data;
      if (success === true) {
        const _categories = records.PROSPECT_CATEGORIES ? JSON.parse(records.PROSPECT_CATEGORIES) : [];
        // console.log(_categories);
        const categoryOptions = _categories.map(row => ({ value: row, label: row }));
        categoryOptions.unshift({ value: '-- Not Assigned --', label: '-- Not Assigned --' });
        setPCategories(categoryOptions);
      }
    }).catch((err) => {
      console.log(err);
    });
  }, []);
  const selectedDeals = showId ? dealsArray.filter(d => d.influencer?._id === showId) : [];
  const fetchAmountReport = async () => {
    setLoading(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    console.log({
      advertiser: selectedAdvertisers?.value || null,
      creators: selectedInfluencers?.map(i => i.value) || [],
    });
    setGeneratedData({
      advertiser: selectedAdvertisers?.value || null,
      creators: selectedInfluencers?.map(i => i.value) || [],
    });
    // const pToRCache = getCache('pToRCache');
    // const mcCache = getCache('mcCache');
    // if (!pToRCache) {
    axios.post('/api/portal/reports/post-creators-by-brand-full-contents', {
      startDate,
      endDate,
      advertisers: selectedAdvertisers ? [selectedAdvertisers?.value] : null,
      // offer: selectedOffer?.value,
      creators: selectedInfluencers?.map(i => i.value), // internal _id
      employees: selectedEmployee?.map(i => i.value),
      identity,
      // ...includedLastMonth && { estimatePrevious, includePrevious, selectedStatuses },
    }).then((resp) => {
      console.log('Media Contents: ', resp.data.mediaContentns);
      const mediaContentCount = {};
      resp.data.mediaContentns.forEach((mContent) => {
        if (!mediaContentCount[mContent.influencer]) mediaContentCount[mContent.influencer] = 0;
        if (mContent.publishDate) mediaContentCount[mContent.influencer] += 1;
      });
      const pToR = {};
      resp.data.prospectToReport.forEach((pTr) => {
        const categoryValue = pTr.category?.value || '-- Not Assigned --';
        if (!pToR[pTr.hsUserId]) {
          pToR[pTr.hsUserId] = [categoryValue];
        } else {
          pToR[pTr.hsUserId] = pToR[pTr.hsUserId].concat([categoryValue]);
        }
      });
      setPToCategories(pToR);
      setMc(mediaContentCount);
      // setCache('pToRCache', pToR);
      // setCache('mcCache', mediaContentCount);
    });
    // } else {
    //   setPToCategories(pToRCache);
    //   setMc(mcCache);
    // }
    try {
      // const key = `${startDate}-${endDate}-${selectedAdvertisers?.value || '-'}-${selectedInfluencers?.map(i => i.value).join('')}-${selectedEmployee?.map(i => i.value).join('')}`;
      // const data = getCache(key);
      // console.log(key);
      // if (data) {
      //   setDeals(data.deals);
      //   // setPToTags(pToR);
      //   setFetched(true);
      //   setLoading(false);
      //   setErrors(data.notUsedConversions);
      //   return false;
      // }
      const resp = await axios.post('/api/portal/reports/post-creators-by-brand-full', {
        startDate,
        endDate,
        advertisers: selectedAdvertisers ? [selectedAdvertisers?.value] : null,
        // offer: selectedOffer?.value,
        creators: selectedInfluencers?.map(i => i.value), // internal _id
        employees: selectedEmployee?.map(i => i.value),
        identity,
        // ...includedLastMonth && { estimatePrevious, includePrevious, selectedStatuses },

      });
      if (resp.data.success) {
        // processDeals(resp.data.items);
        // setCpmConversions(resp.data.cpmConversions);
        const deals = groupByDealResp(resp.data.items);
        console.log(resp.data.items);
        console.log(deals);
        convertDealsForCreators(deals, startDate, endDate);
        console.log(Object.values(deals).map(d => ({
          dealId: d.deal?._id, totalRevenue: d.totalRevenue, hsContactVid: d.deal?.hsContactVid, mull: d.totalPayoutWithMulligan, totalProfit: d.grossProfit,
        })));
        setDeals(Object.values(deals));
        // setPToTags(pToR);
        setFetched(true);
        setLoading(false);
        setErrors(resp.data.notUsedConversions);
        // setVersion((new Date()).toString());
        // setCache(key, { deals: Object.values(deals), notUsedConversions: resp.data.notUsedConversions });
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
    return true;
  };
  /* const saveReportByDates = async (name, admins) => {
    setLoading(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    setGeneratedData({
      advertiser: selectedAdvertisers?.value,
      creators: selectedInfluencers?.map(i => i.value),
    });
    try {
      const resp = await axios.post('/api/portal/reports/post-save-report-by-dates', {
        startDate,
        endDate,
        advertiser: selectedAdvertisers?.value,
        // offer: selectedOffer?.value,
        creators: selectedInfluencers?.map(i => i.value), // internal _id
        employees: selectedEmployee?.map(i => i.value),
        identity,
        advertiserObject: selectedAdvertisers,
        creatorsArray: selectedInfluencers,
        employeesArray: selectedEmployee,
        selectedTags,
        selectedStage,
        selectedContentType,
        name,
        admins,
      });
      if (resp.data.success) {
        setLoading(false);
        setSaveReportModal(false);
        setSavedReportModal(true);
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  }; */
  /* const saveReportToSave = async (name, admins) => {
    setLoading(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    setGeneratedData({
      advertiser: selectedAdvertisers?.value,
      creators: selectedInfluencers?.map(i => i.value),
    });
    try {
      const resp = await axios.post('/api/portal/reports/post-save-report', {
        startDate,
        endDate,
        advertiser: selectedAdvertisers?.value,
        // offer: selectedOffer?.value,
        creators: selectedInfluencers?.map(i => i.value), // internal _id
        employees: selectedEmployee?.map(i => i.value),
        identity,
        // ...includedLastMonth && { estimatePrevious, includePrevious, selectedStatuses },
        advertiserObject: selectedAdvertisers,
        creatorsArray: selectedInfluencers,
        employeesArray: selectedEmployee,
        selectedTags,
        selectedStage,
        selectedContentType,
        name,
        admins,
      });
      if (resp.data.success) {
        setLoading(false);
        setSaveReportModal(false);
        setSavedReportModal(true);
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  }; */
  const fetchAmountReportByDates = async () => {
    setLoading(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    setGeneratedData({
      advertiser: selectedAdvertisers?.value,
      creators: selectedInfluencers?.map(i => i.value),
    });
    try {
      const resp = await axios.post('/api/portal/reports/post-creators-by-brand-by-dates', {
        startDate,
        endDate,
        advertiser: selectedAdvertisers?.value,
        // offer: selectedOffer?.value,
        creators: selectedInfluencers?.map(i => i.value), // internal _id
        employees: selectedEmployee?.map(i => i.value),
        identity,
        // ...includedLastMonth && { estimatePrevious, includePrevious, selectedStatuses },
      });
      if (resp.data.success) {
        console.log(resp.data.mediaContentns);
        const itemsFiltered = resp.data.items.map((item) => {
          console.log(item._id);
          const media = resp.data.mediaContentns.filter(mC => mC.deal === item._id);
          return { ...item, mediaCount: media.length };
        });
        setLoading(false);
        setErrors(resp.data.notUsedConversions);
        // processDeals(itemsFiltered);
        setTags(resp.data.tags);
        setDeals(itemsFiltered);
        setFetched(true);
        // setVersion((new Date()).toString());
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  const employeeOptions = useMemo(() => {
    const i = employees.map(item => ({ value: item._id, label: `${item.firstName || ''} ${item.lastName || ''}` }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [employees]);
  const tagsOption = useMemo(() => {
    const i = Object.keys(tags).map(key => ({ value: tags[key].tagName, label: tags[key].tagName }));
    return i;
  }, [tags]);
  // const prospectTagsOption = useMemo(() => {
  //   const i = Object.keys(pTags).map(key => ({ value: pTags[key].tagName, label: pTags[key].tagName }));
  //   return i;
  // }, [pTags]);
  const selectItem = (value) => {
    setSelectedAdvertisers(value);
  };
  const handleClickAction = (influencer) => {
    setShowId(influencer);
    console.log(influencer);
  };
  const options = useMemo(() => {
    const i = advertisers.map(item => ({ value: item._id, label: item.companyName }));
    i.sort((a, b) => a.label?.localeCompare(b.label));
    i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [advertisers]);

  const contentTypes = getConstant('mediaContent', 'types', {});
  const contentTypeOptions = useMemo(() => Object.keys(contentTypes).map(t => ({ value: t, label: contentTypes[t] })), [contentTypes]);

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40, 100],
    placeholder: 'Search...',
    sortBy: 'revenue',
    direction: 'desc',
    useFlex: true,
  };
  const loadCreatorsOptions = async (value) => {
    const response = await axios.get('/api/portal/influencer/get-creator-search', {
      params: {
        value,
      },
    });
    return response.data.records.map(record => ({
      value: record.params._id,
      label: record.params.email,
      record,
    }));
  };

  const columns = useMemo(() => Columns({ handleClickAction }), [items]);
  // console.log(generatedData?.advertiser, (selectedAdvertisers?.value || null));
  const shouldShowRenenerate = ((generatedData?.advertiser || selectedAdvertisers?.value) && generatedData?.advertiser !== (selectedAdvertisers?.value || null))
    || !_.isEqual(_.sortBy(generatedData?.creators || []), _.sortBy(selectedInfluencers?.map(i => i.value) || []));
  // const shouldShowMessage = moment(endDate).diff(moment(startDate), 'month') > 6;
  // useEffect(() => (shouldShowMessage ? setSaveReport(true) : null), [shouldShowMessage]);
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Creator Performance (Legacy)', path: false },
            ]}
          />
        </Col>
      </Row>
      {!loading && fetched && errors.length > 0 ? (
        <Errors errors={errors} startDate={startDate} endDate={endDate} />
      ) : null }
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Creator Performance (Legacy)</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3" >
                <Col>
                  <Filter
                    setValue={(param, value, isByDateSelected = false) => {
                      console.log(value);
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                      setIsByDate(isByDateSelected);
                    }}
                  />
                </Col>
                <Col lg="auto">
                  <div>
                    <Button
                      color="primary"
                      onClick={async () => {
                        // if (saveReport) {
                        //   setSaveReportModal(true);
                        //  return false;
                        // }
                        if (!isByDate) {
                          await fetchAmountReport();
                        } else {
                          await fetchAmountReportByDates();
                        }
                        return true;
                      }}
                      className="mr-2 btn-sm mt-xl-0 mt-lg-3 mb-0"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                    <GenerationInfo />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col />
                <Col lg="auto">{shouldShowRenenerate ? (<div className="grey-small-text grey-minus-margin">Click button to update report</div>) : null}</Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <FormGroup>
                    <Label>Brand</Label>
                    <Select
                      isDisabled={loadingAdvertiser}
                      value={selectedAdvertisers}
                      options={options}
                      onChange={selectItem}
                      placeholder="Select Brand..."
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Creator(s)</Label>
                    <SelectAsync
                      cacheOptions
                      isMulti
                      loadOptions={async value => loadCreatorsOptions(value)}
                      onChange={setSelectedInfluencers}
                      placeholder="Search Creator(s)..."
                      formatOptionLabel={formatCreatorOptionLabel}
                      components={{ Option: creatorOption }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {fetched ? (
                <Row className="my-2">
                  <Col>
                    <Total stats={stats} />
                  </Col>
                </Row>
              ) : null}
              {fetched ? (
                <Row className="mt-3" >
                  <Col>
                    <Select
                      isDisabled={loadingAdvertiser}
                      value={selectedEmployee}
                      options={employeeOptions}
                      onChange={setSelectedEmployee}
                      placeholder="Select Account Manager..."
                      isMulti
                    />
                  </Col>
                  <Col>
                    <Select
                      value={selectedContentType}
                      options={contentTypeOptions}
                      onChange={setSelectedContentType}
                      placeholder="Select Content Type..."
                      isMulti
                    />
                  </Col>
                  <Col>
                    <Select
                      isDisabled={loadingAdvertiser}
                      value={selectedTags}
                      options={tagsOption}
                      onChange={setSelectedTags}
                      placeholder="Select Tag..."
                      isMulti
                    />
                  </Col>
                  <Col>
                    <Select
                      isDisabled={loadingAdvertiser}
                      value={selectedProspectCategories}
                      options={pCategories}
                      onChange={setSelectedProspectCategories}
                      placeholder="Select Prospect Category..."
                      isMulti
                    />
                  </Col>
                  <Col>
                    <Select
                      value={selectedStage}
                      options={[
                        { value: 1, label: 'Test Buy' },
                        { value: 2, label: 'Rebuy' },
                      ]}
                      onChange={setSelectedStage}
                      placeholder="Campaign Stage..."
                      isClearable
                    />
                  </Col>
                </Row>
              ) : null}
              {fetched ? (
                <Row className="mt-3" >
                  <Col>
                    <Select
                      value={selectedDealType}
                      options={[
                        { value: 'Hybrid', label: 'Hybrid' },
                        { value: 'CPA/Ambassador', label: 'CPA/Ambassador' },
                        { value: 'Upfront Only', label: 'Upfront Only' },
                        { value: 'Upfront CPM', label: 'Upfront CPM' },
                        { value: 'Bonus', label: 'Bonus' },
                      ]}
                      onChange={setSelectedDealType}
                      placeholder="Deal Type..."
                      isClearable
                      isMulti
                    />
                  </Col>
                  <Col />
                  <Col />
                  <Col />
                  <Col />
                </Row>
              ) : null}
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    identity={identity}
                    topic="report"
                    isShow={!!loading}
                  />
                </Col>
              </Row> */}
              <Row className="mt-3">
                <Col />
                <Col lg="auto">
                  {dealsArray.length > 0 ? (
                    <div className="float-left mr-2 mb-0">
                      <DownloadCSV
                        items={items}
                      />
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  {!loading && fetched ? (
                    <ReactTableBase
                      key="searchable"
                      columns={columns}
                      data={items}
                      tableConfig={tableConfig}
                    />) : null
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CreatorModal selectedDeals={selectedDeals} show={!!showId} setShow={setShowId} calculateForDeal={calculateForDeal} calculateRevenueForDeal={calculateRevenueForDeal} />
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(SummaryReport);
