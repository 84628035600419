import React from 'react';
import { connect } from 'react-redux';
import {
  Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
  deleteMediaContent,
  showDeleteMediaContentDialog,
} from '../../../../../../../redux/reducers/admin/ScoringReducer';

const DeleteContentModal = ({
  showDeleteDialog,
  dispatch,
  loadingMediaContentScorecards,
  mediaContentObj,
  mediaContentScorecards,
  setVersion,
  callbackFunc,
}) => {
  const handleDeleteDialogClose = () => {
    dispatch(showDeleteMediaContentDialog(false));
  };

  const handleDeleteDialogSubmit = () => {
    dispatch(deleteMediaContent(mediaContentObj.params._id));
    dispatch(showDeleteMediaContentDialog(false));
    setTimeout(() => {
      setVersion((new Date()).toString());
      callbackFunc();
    }, 1000);
  };

  return (
    <Modal isOpen={showDeleteDialog} toggle={handleDeleteDialogClose} size="sm" className=" theme-light">
      <ModalHeader tag="h3" toggle={handleDeleteDialogClose}>
        Delete Media Content
      </ModalHeader>
      <ModalBody>
        {loadingMediaContentScorecards ? (
          <div className="text-center"><Spinner color="primary" size="lg" /></div>) : null}
        {!loadingMediaContentScorecards && mediaContentObj && mediaContentScorecards && (
          <Row>
            <Col xs="3" className="text-center mt-auto mb-auto" style={{ color: '#70bbfd' }}> <i
              className="fa fa-question"
              style={{ fontSize: '5em' }}
            />
            </Col>
            <Col xs="9">
              <p>Confirm you would like to permanent delete the following content...</p>
              <p className="font-weight-bold">{mediaContentObj?.params?.name}</p>
              <br />
              {mediaContentScorecards.length > 0 && (
                <div>
                  <p>{`Along with the following scorecard${mediaContentScorecards.length > 1 ? 's:' : ':'}`}</p>
                  {mediaContentScorecards.map(item => (
                    <p className="font-weight-bold" key={item.key}>{`${item.scoreBy} - ${item.totalScore}`}</p>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter className="modal__footer">
        <Button
          className="btn-sm"
          color="danger"
          onClick={handleDeleteDialogSubmit}
        >
          Delete
        </Button>{' '}
        <Button
          className="btn-sm"
          color="secondary"
          onClick={handleDeleteDialogClose}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
DeleteContentModal.propTypes = {
  showDeleteDialog: PropTypes.bool.isRequired,
  loadingMediaContentScorecards: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  mediaContentObj: PropTypes.objectOf(PropTypes.any),
  mediaContentScorecards: PropTypes.arrayOf(PropTypes.any),
  setVersion: PropTypes.func.isRequired,
  callbackFunc: PropTypes.func.isRequired,
};

DeleteContentModal.defaultProps = {
  mediaContentObj: {},
  mediaContentScorecards: [],
};

const mapStateToProps = state => ({
  showDeleteDialog: state.score.showDeleteDialog,
  loadingMediaContentScorecards: state.score.loading.fetchScorecardContent,
  mediaContentObj: state.score.mediaContent,
  mediaContentScorecards: state.score.mediaContentScorecards,
});

export default connect(mapStateToProps)(DeleteContentModal);
