import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import Select from 'react-select';
import IconButton from '@material-ui/core/IconButton';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import { uid } from 'react-uid';
import { Row, Col, Button, Modal, ButtonToolbar } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import formater from '../../../../shared/helpers/WVFormatter';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';


const calculateForDeal = (deal) => {
  let a = deal.payables.reduce((accumulator, currentValue) => {
    // eslint-disable-next-line
    accumulator += Number(currentValue.profit || 0);
    return accumulator;
  }, 0);
  if (deal.bill) {
    a -= Number(deal.billTotal);
  }
  if (deal.currentMonthPayout) {
    a -= Number(deal.currentMonthPayout);
  }
  if (deal.currentMonthRevenue) {
    a += Number(deal.currentMonthRevenue);
  }
  return Number(a || 0);
};

const SummaryReport = ({
  items, variable12, loading, addNotice,
}) => {
  const [itemsByBrand, setItemsByBrand] = useState([]);
  const [showId, setShowId] = useState(null);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState(null);
  const [advertisers, setAdvertisers] = useState([]);
  const [loadingAdvertiser, setLoaingAdvertiser] = useState(false);

  const getTable = (array) => {
    const influencers = {};
    console.log(selectedAdvertisers);
    console.log(array);
    // console.log(array.filter(deal => !selectedAdvertisers?.value || selectedAdvertisers?.value.toString() === deal.offer.advertiser._id.toString()));
    array.filter((deal) => {
      const advertiser = deal.offer.advertiser?._id || deal.offer.advertiser;
      return !selectedAdvertisers?.value || selectedAdvertisers.value.toString() === advertiser.toString();
    }).forEach((deal) => {
      if (!influencers[deal.tuneId]) {
        influencers[deal.tuneId] = {
          deals: [],
          tuneId: deal.tuneId,
          influencer: deal.influencer,
          grossProfit: 0,
          contact: null,
          ...deal.contact?.date_of_first_conversion ? { months: moment().diff(moment(deal.contact.date_of_first_conversion), 'months') } : { months: 0 },
        };
      }
      influencers[deal.tuneId].grossProfit += calculateForDeal(deal);
      influencers[deal.tuneId].deals.push(deal);
      return influencers[deal.tuneId];
    });
    console.log(influencers);
    return Object.values(influencers);
  };
  useEffect(() => {
    setLoaingAdvertiser(true);
    axios.get('/api/portal/finance/get-advertisers').then((resp) => {
      setAdvertisers(resp.data.advertisers);
      setLoaingAdvertiser(false);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);
  useEffect(() => {
    const influencers = getTable(items);
    setItemsByBrand(influencers);
    // setVersion((new Date()).toString());
  }, [selectedAdvertisers, items]);
  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'revenue',
    direction: 'desc',
  };
  const selectedDeals = showId ? items.filter(d => d.influencer._id === showId) : [];
  const columns = useMemo(
    () => [
      {
        Header: 'Creator',
        accessor: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
        id: 'Creator',
        Cell: p => useMemo(() => (
          <Tooltip title="Open Creator Profile">
            <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.influencer?._id}`}>{p.value}</a>
          </Tooltip>), [p.row.original]),
        width: 500,
      },
      {
        Header: 'Lifetime Value',
        accessor: deal => (deal.months ? (deal.grossProfit / deal.months) * variable12 : 0),
        id: 'revenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        width: 500,
      },
      {
        Header: '',
        accessor: p => p?.influencer?._id,
        id: 'action',
        disableGlobalFilter: true,
        width: 65,
        Cell: ({ value }) => useMemo(() => (
          <Tooltip title="Open Calculation Modal">
            <IconButton
              size="small"
              onClick={() => setShowId(value)}
              className="material-table__toolbar-button"
            >
              <InformationOutlineIcon size="15" />
            </IconButton>
          </Tooltip>
        ), [value]),
        disableSortBy: true,
      },
    ],
    [],
  );
  const selectItem = (value) => {
    setSelectedAdvertisers(value);
  };
  const options = advertisers.map(item => ({ value: item._id, label: item.companyName }));
  options.unshift({ value: null, label: ' -- All -- ' });
  return (
    <div>
      <Row>
        <Col>
          {items.length > 0 ? (
            <div className="float-right mr-2">
              <DownloadCSVButton
                records={itemsByBrand}
                className="mt-2"
                fileName="LTVReport.csv"
                csvArrayColumns={[
                  {
                   label: 'Creator',
                   func: deal => `${deal.influencer.first_name} ${deal.influencer.last_name} `,
                 }, {
                   label: 'Lifetime Value',
                   func: deal => formater.formatCurrency((deal.months ? (deal.grossProfit / deal.months) * variable12 : 0)),
                 },
               ]}
              >
                Download CSV
              </DownloadCSVButton>
            </div>
          ) : null}
        </Col>
      </Row>
      {!loading && items.length > 0 ? (
        <Row >
          <Col>
            <Select
              isDisabled={loadingAdvertiser}
              value={selectedAdvertisers}
              options={options}
              onChange={selectItem}
              placeholder="Select Advertiser..."
              isClearable
            />
          </Col>
        </Row>) : null
      }
      <Row className="mt-2">
        <Col>
          {!loading && itemsByBrand.length > 0 ? (
            <ReactTableBase
              key="searchable"
              columns={columns}
              data={itemsByBrand}
              tableConfig={tableConfig}
            />) : null
          }
        </Col>
      </Row>
      <Modal
        isOpen={!!showId}
        toggle={() => setShowId(null)}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Payment History</h4>
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body ltr-support">
          {selectedDeals.map(d => (
            <div key={uid(d.hs_object_id)}>
              <h4><a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${d.hs_object_id}/`} target="_blank" rel="noreferrer" >{d.dealname}</a></h4>
              <h5>Month Count: {(moment().diff(moment(d?.contact?.date_of_first_conversion), 'months') || 0)}</h5>
              <h5>Revenue calculated from HasOffers (Current Month): {formater.formatCurrency(d.currentMonthRevenue || 0)}</h5>
              <h5>Payout calculated from HasOffers (Current Month): {formater.formatCurrency(d.currentMonthPayout || 0)}</h5>
              <h5>Invoice total payout {formater.formatCurrency(d.invoiceTotal || 0)}</h5>
              <h5>Bill total payout {formater.formatCurrency(d.billTotal || 0)}</h5>
              <h5>Accounts Payable Revenue {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.revenueAmount || 0);
                    return accumulator;
                  }, 0) || 0)}
              </h5>
              <h5>Accounts Payable Payout {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.ilPayoutAmount || 0);
                    return accumulator;
                  }, 0) || 0)}
              </h5>
              <span><strong>Profit:</strong>{' '}
                ({formater.formatCurrency(d.currentMonthRevenue || 0)} -{' '}
                {formater.formatCurrency(d.currentMonthPayout || 0)} -{' '}
                {formater.formatCurrency(d.billTotal || 0)} +{' '}
                {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.revenueAmount || 0);
                    return accumulator;
                  }, 0) || 0)} -{' '}
                {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                  // eslint-disable-next-line
                  accumulator += Number(currentValue.ilPayoutAmount || 0);
                  return accumulator;
                }, 0) || 0)}{' '}
                ) / {(moment().diff(moment(d?.contact?.date_of_first_conversion), 'months')) || 0} * {variable12}
                = {formater.formatCurrency((moment().diff(moment(d?.contact?.date_of_first_conversion), 'months')) ?
                    ((calculateForDeal(d) / Number((moment().diff(moment(d?.contact?.date_of_first_conversion), 'months')) || 0)) * variable12) :
                  0)}
              </span>
              <hr />
            </div>
          ))}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button color="danger" onClick={() => setShowId(null)}>Close</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};
SummaryReport.propTypes = {
  loading: PropTypes.bool.isRequired,
  variable12: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
