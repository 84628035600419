import React, { useState, useEffect } from 'react';
import {Modal, Button, ModalHeader, ModalBody, Col, Row, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import DropZoneField from '../form/DropZone';
import Hook from '../../hooks';
import RadioButton from '../RadioButton';
import '../../../../src/scss/component/email-template-preview-modal.scss';


export const TaxFormUploadModalId = 'TaxFormUpload';
const TooltipMessage = 'Go to InfluenceLogic Support for further assistance';
const TooltipFileMessage = 'Show Tax Form Template';

const TaxFormTypes = [
  { value: 'W-9', label: 'W-9' },
  { value: 'W-8BEN', label: 'W-8BEN' },
  { value: 'W-8BEN-E', label: 'W-8BEN-E' },
  { value: 'W-8ECI', label: 'W-8ECI' },
  { value: 'W-8IMY', label: 'W-8IMY' },
];

const TaxFormUploadModal = ({ person, record, creators, updateData, taxFormId }) => {
  const [taxFormType, setTaxFormType] = useState(null);
  const [taxFormFile, setTaxFormFile] = useState([]);
  const [removedFile, setRemovedFile] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [endUpload, setEndUpload] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [creatorOptions, setCreatorOptions] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [uploadType, setUploadType] = useState({ agency: true, creator: false });
  const [taxFormTempUri, setTaxFormTempUri] = useState({});

  useEffect(() => {
    if (person === 'agent' && creators.length) {
      const options = creators.map((creator) => ({ value: creator._id, label: `${creator.first_name} ${creator.last_name}` }));
      setCreatorOptions(options);
    }
  }, [creators]);

  const {
    data, showModal: isShow, close: closeModal,
  } = Hook.useModalHook(TaxFormUploadModalId);
  // if (!data) return false;

  // The "source" could be 'portal', 'agent' or 'creator' to know what API we should use
  // The "person" could be 'creator' or 'agent' to know if we need to add additional chooses
  const {
    source = 'portal',
  } = data;

  useEffect(() => {
    if (isShow) {
      fetchDictionaryData();
    }
  }, [isShow]);
  const fetchDictionaryData = () => {
    axios.get(`/api/${source}/finance/get-dictionary-tax-form-templates`).then((response) => {
      if (response.data.success) {
        setTaxFormTempUri(response.data.records);
        console.log(response.data.records);
      }
    });
  };

  const close = () => {
    closeModal();
    updateData();
    setUploading(false);
    setEndUpload(false);
    setTaxFormFile([]);
    setRemovedFile(false);
    setSelectedCreator(null);
    setTaxFormType(null);
    // setCreatorOptions([]);
    setUploadType({ agency: true, creator: false });
  };

  const handleChange = (files) => {
    setTaxFormFile(files);
    if (files.length === 0) {
      setRemovedFile(true);
      setShowLink(false);
    } else {
      setRemovedFile(false);
    }
  };

  const onUpload = () => {
    setUploading(true);
    const data = new FormData();
    const file = taxFormFile.length > 0 ? taxFormFile[0] : null;
    data.append('taxFormFile', file);
    data.append('source', source);
    data.append('type', taxFormType.value);
    data.append('person', person);
    if (taxFormId) {
      data.append('taxFormId', taxFormId);
    }

    if (person === 'creator') {
      data.append('creatorId', record.id || record._id);
      data.append('userType', 'Creator');
    }
    if (person === 'agent') {
      data.append('agentId', record.id || record._id);
      if (selectedCreator) data.append('creatorId', selectedCreator.value);
      if (uploadType.agency) {
        data.append('userType', 'Agent');
      } else {
        data.append('userType', 'Creator');
      }
    }

    axios({
      method: 'post',
      url: `/api/${source}/finance/post-upload-tax-form`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    }).then((response) => {
      if (response.data.success) {
        setResultMessage('Upload was completed successfully');
      } else {
        setResultMessage('File was not uploaded.');
      }
      setUploading(false);
      setEndUpload(true);
    });
  };

  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      size="sm"
      style={{ minWidth: '900px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Upload Tax Form
      </ModalHeader>
      <ModalBody>
        {!uploading && !endUpload && (
          <div className="px-4">
            <Row>
              {person === 'agent' && (
                <Col md={4} className="theme-light radio-in-prospects pb-2">
                  <h4 style={{ marginBottom: '5px', color: '#646777' }}>Upload...</h4>
                  <RadioButton
                    className="no-padding"
                    name="action"
                    label="&nbsp;Agency Tax Form"
                    value={uploadType.agency ? 'agency' : 'creator'}
                    radioValue="agency"
                    onChange={(e) => {
                      setUploadType({
                        agency: e === 'agency',
                        creator: false,
                      });
                      setSelectedCreator(null);
                    }}
                  />
                  <RadioButton
                    className="no-padding"
                    name="action"
                    label="&nbsp;Creator's Tax Form"
                    value={uploadType.agency ? 'agency' : 'creator'}
                    radioValue="creator"
                    onChange={(e) => {
                      setUploadType({
                        creator: e === 'creator',
                        agency: false,
                      });
                    }}
                  />
                </Col>
              )}
              {uploadType.creator && (
                <Col md={4}>
                  <h4 style={{ marginBottom: '5px', color: '#646777' }}>Select Creator</h4>
                  <Select
                    value={selectedCreator}
                    options={creatorOptions}
                    onChange={setSelectedCreator}
                    placeholder="Select Creator"
                    className="mb-3"
                  />
                </Col>
              )}
              <Col md={4}>
                <h4 style={{ marginBottom: '5px', color: '#646777', display: 'inline-block' }}>Tax Form</h4>
                <div style={{ display: 'inline-block' }}>
                  <Tooltip title={TooltipMessage}>
                    <a
                      href="https://support.influencelogic.com/filled-out-tax-form-examples"
                      target="_blank"
                      title={TooltipMessage}
                      rel="noreferrer noopener"
                    >
                      <i key="question-icon" className="fa fa-question-circle"
                         style={{color: '#646777', fontSize: '1.2em', marginLeft: '5px'}}/>
                    </a>
                  </Tooltip>
                </div>
                <div style={{width: '85%', display: 'inline-block'}}>
                  <Select
                    value={taxFormType}
                    options={TaxFormTypes}
                    onChange={setTaxFormType}
                    placeholder="Select tax form type..."
                    className="mb-3"
                  />
                </div>
                <div style={{float: 'right',}}>
                  {taxFormType && (
                    <a
                      href={taxFormTempUri[taxFormType.value] || ''}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginLeft: '5px' }}
                    >
                      <Tooltip title={TooltipFileMessage}>
                        <i key="file-icon" className="fa fa-file-pdf" style={{ color: '#70bbfd', fontSize: '2em', marginTop: '5px' }} />
                      </Tooltip>
                    </a>
                  )}
                </div>
              </Col>
            </Row>
            {(taxFormFile.length > 0) && !showLink && (
              <Row>
                <Col>
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder=""
                    value={taxFormFile.length > 0 ? taxFormFile[0].name : ''}
                    disabled
                    style={{width: '100%' }}
                  />
                </Col>
              </Row>
            )}
            {showLink && record?.params?.filePath && (
              <Row>
                <Col>
                  <a href={`/api/portal/global/get-view?url=${record.params.filePath}`} target="_blank" rel="noreferrer">Click to preview</a>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <DropZoneField
                  input={{
                    id: 'taxFormFile',
                    name: 'taxFormFile',
                    value: taxFormFile,
                    accept: {
                      'image/*': ['.jpeg', '.png', '.gif', '.jpg'],
                      'application/pdf': ['.pdf'],
                    },
                    // accept: 'application/pdf',
                    onChange: handleChange,
                  }}
                  customClass=" dropzone--border"
                  customHeight
                />
              </Col>
            </Row>
          </div>
        )}
        {uploading && (
          <div className="text-center"><Spinner color="primary" size="lg" />&nbsp;&nbsp;</div>
        )}
        {endUpload && (
          <div className="text-center">
            <h4>{resultMessage}</h4>
          </div>
        )}
        <Row className="modal__footer" style={{ marginTop: '30px' }}>
          {!endUpload && (
            <Button
              className="btn btn-primary btn-sm"
              onClick={onUpload}
              color="primary"
              style={{ margin: '0 10px 0 10px' }}
              disabled={uploading || taxFormFile.length === 0 || !taxFormType}
            >
              <i className="icomoon-save" />
              <span className="btn-text">{removedFile ? 'Update' : 'Upload'}</span>
            </Button>
          )}
          <Button
            className="modal_cancel btn-sm"
            color="secondary"
            onClick={close}
            style={{ margin: '0 10px 0 10px' }}
          >
            {endUpload ? 'Close' : 'Cancel'}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

TaxFormUploadModal.propTypes = {
  taxFormId: PropTypes.string,
  updateData: PropTypes.func,
  person: PropTypes.string.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  creators: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

TaxFormUploadModal.defaultProps = {
  updateData: () => {},
  // setVersion: () => {},
  creators: [],
  taxFormId: '',
};

export default TaxFormUploadModal;
