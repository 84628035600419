import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import formater from '../../../../../../../shared/helpers/WVFormatter';
import Item from './StatItem';

const revenue = totalPayout => totalPayout / 1.15;
const profit = totalPayout => (totalPayout / 1.15) * 0.15;

const boxStyle = { minWidth: '200px', marginBottom: '40px' };

const Total = ({ stats }) => (
  <div className="text-center mt-3 mb-4">
    <Row style={{ maxWidth: '1200px', margin: '0 auto' }}>
      <Col style={boxStyle}>
        <h4>All Invoices</h4>
        <Item
          items={[{
            label: 'Total Payout',
            value: formater.formatCurrency(stats.all.totalPayout),
          },
          {
            label: 'Gross Revenue',
            value: formater.formatCurrency(revenue(stats.all.totalPayout)),
          },
          {
            label: 'Gross Profit',
            value: formater.formatCurrency(profit(stats.all.totalPayout)),
          }]}
        />
      </Col>
      <Col style={boxStyle}>
        <h4>New Invoices</h4>
        <Item
          items={[{
            label: 'Total Payout',
            value: formater.formatCurrency(stats.new.totalPayout),
          },
          {
            label: 'Gross Revenue',
            value: formater.formatCurrency(revenue(stats.new.totalPayout)),
          },
          {
            label: 'Gross Profit',
            value: formater.formatCurrency(profit(stats.new.totalPayout)),
          }]}
        />
      </Col>
      <Col style={boxStyle}>
        <h4>Sent Invoices</h4>
        <Item
          items={[{
            label: 'Total Payout',
            value: formater.formatCurrency(stats.sent.totalPayout),
          },
          {
            label: 'Gross Revenue',
            value: formater.formatCurrency(revenue(stats.sent.totalPayout)),
          },
          {
            label: 'Gross Profit',
            value: formater.formatCurrency(profit(stats.sent.totalPayout)),
          }]}
        />
      </Col>
      <Col style={boxStyle}>
        <h4>Received Invoices</h4>
        <Item
          items={[{
            label: 'Total Payout',
            value: formater.formatCurrency(stats.received.totalPayout),
          },
          {
            label: 'Gross Revenue',
            value: formater.formatCurrency(revenue(stats.received.totalPayout)),
          },
          {
            label: 'Gross Profit',
            value: formater.formatCurrency(profit(stats.received.totalPayout)),
          }]}
        />
      </Col>
      <Col style={boxStyle}>
        <h4>Paid Invoices</h4>
        <Item
          items={[{
            label: 'Total Payout',
            value: formater.formatCurrency(stats.paid.totalPayout),
          },
          {
            label: 'Gross Revenue',
            value: formater.formatCurrency(revenue(stats.paid.totalPayout)),
          },
          {
            label: 'Gross Profit',
            value: formater.formatCurrency(profit(stats.paid.totalPayout)),
          }]}
        />
      </Col>
    </Row>
  </div>);
Total.propTypes = {
  stats: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Total;
