import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import formatter from '../../../../../shared/helpers/WVFormatter';

export default () => {
  const getColor = (value) => {
    if (value < 0.85) return '#e6194B';
    if (value < 0.9) return '#ffbb28';
    return '#13c450';
  };

  const formatValue = (value, percentage, isRejected = false) => (
    <>
      <span className="d-inline-block" style={{ minWidth: '40px' }}>
        {formatter.formatIntNumber(value)}
      </span>
      {!!value && (
        <span style={{ color: getColor(isRejected ? 1 - percentage : percentage) }}>
          {formatter.formatIntNumberAndAddPercentSign(percentage)}
        </span>
      )}
    </>
  );

  return [
    {
      Header: 'Brand',
      accessor: p => p.title,
      id: 'brand',
      Cell: p => p.value,
    },
    {
      Header: 'Incoming Leads',
      accessor: p => p.total,
      id: 'incomingLeads',
      Cell: p => formatter.formatIntNumber(p.value),
    },
    {
      Header: 'IL Approved',
      accessor: p => p.ilApproved,
      id: 'ilApproved',
      Cell: p => formatValue(p.value, p.row.original.ilApprovedPercentage),
    },
    {
      Header: 'IL Rejected',
      accessor: p => p.ilRejected,
      id: 'ilRejected',
      Cell: p => formatValue(p.value, p.row.original.ilRejectedPercentage, true),
    },
    {
      Header: () => (
        <div>
          Brand Approved
          <Tooltip title="Brand Approved and Rejected percentages are calculated using IL Approved">
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: '5px' }} />
          </Tooltip>
        </div>
      ),
      accessor: p => p.brandApproved,
      id: 'brandApproved',
      Cell: p => formatValue(p.value, p.row.original.brandApprovedPercentage),
    },
    {
      Header: () => (
        <div>
          Brand Rejected
          <Tooltip title="Brand Approved and Rejected percentages are calculated using IL Approved">
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: '5px' }} />
          </Tooltip>
        </div>
      ),
      accessor: p => p.brandRejected,
      id: 'brandRejected',
      Cell: p => formatValue(p.value, p.row.original.brandRejectedPercentage, true),
    },
  ];
};
