import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../../App/store/with-notice';
import NewPriceApprovals from './custom/Modal/NewPriceApprovals';
import UploadPriceApprovalsModal from './custom/Modal/UploadPriceApprovalsModal';
// import { preLoadFilters } from '../../../../../../redux/reducers/TableReducer';
import Hook from '../../../../../../shared/hooks';

const CPMPricingApprovals = ({
  // addNotice,
  history,
  // action,
  resource,
}) => {
  // const [version, setVersion] = useState((new Date()).toString());
  // const recordsRef = useRef([]);
  const [searchUrl, setPrefill, loadPage, , useLoadOrDebounce] = Hook.usePrefillHook({
    resourceId: resource.id,
    initialState: {
      sortBy: 'createdAt',
      direction: 'desc',
      page: 1,
      perPage: 10,
    },
  });

  useEffect(() => {
    setPrefill([]);
  }, []);
  const setUrl = () => {
    loadPage();
  };
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 600);

  /* useEffect(() => {
    if (searchUrl === null) return false;
    history.push(`${history.location.pathname}?${searchUrl || ''}`);
    console.log(searchUrl, 'START LOAD DATA');

    // console.log(isPageChanged, 'isPageChanged');
    if (!isPageChanged.filters) {
      return loadPage();
    }
    return setUrlDebounced();
  }, [
    searchUrl,
  ]); */
  useLoadOrDebounce({
    loadFunc: () => loadPage(), loadDebounceFunc: () => setUrlDebounced(), setSearchUrl: () => history.push(`${history.location.pathname}?${searchUrl}`)
  });

  // const searchUrl = useSelector(state => state.table.tableFilterUrl);
  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };
  const downloadCsv = () => {
    const search = searchUrl;
    goToPage(`/api/portal/prospects/get-price-approvals-csv?${search}`);
  };
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'CPM Program', path: null },
          { title: 'Price Approval Groups', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Price Approval Groups</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                // setVersion={setVersion}
                downloadCsv={downloadCsv}
              />
              <Row style={{ marginTop: '-10px' }}>
                {searchUrl ? <Table /> : null}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <NewPriceApprovals />
      <UploadPriceApprovalsModal />
    </Container>
  );
};
CPMPricingApprovals.propTypes = {
  // addNotice: PropTypes.func.isRequired,
  // action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withNotice(withRouter(CPMPricingApprovals));
