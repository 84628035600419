import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from '../../../../../../shared/components/CopyToClipboard';

const getEmail = record => record.email || null;

const EmailComponent = ({
  record,
}) => {
  const [email, setEmail] = useState(getEmail(record));

  useEffect(() => {
    setEmail(getEmail(record))
  }, [record]);

  return (
    <div style={{ width: '200px' }}>
      {email ? (
        <CopyToClipboard
          title="Copy Email"
          value={email}
          iconClassName="il-color-deep-blue"
        >
          {email}
        </CopyToClipboard>
      ) : ('-')}
    </div>
  );
}

EmailComponent.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default EmailComponent;
