import React from 'react';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import TableCellContextMenu from '../../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';

const ContextMenuInList = ({
  record,
}) => {
  const { params } = unflatten(record);

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  return (
    <TableCellContextMenu
      options={[
        {
          label: 'View HubSpot Contact',
          handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${params.hs_object_id}`),
        },
      ]}
    />
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContextMenuInList;
