import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TableCellContextMenu from '../../../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { modalId } from '../../../../MediaContent/components/custom/modals/RefreshYoutubeDialog';
import { modalCommentAnalysisId } from '../../modals/RunCommentAllSentimentAnalysis';
import hooks from '../../../../../../../shared/hooks';

const ContextMenuInList = ({
  record, dispatch,
}) => {
  const [useShowForUsers] = hooks.useAccessHook({ hideWhenTeam: ['Auditors'] });
  const profileLink = `/influencer/profile/${record.params.influencer}`;
  const mediaContentLink = `/scorecard/media-content/edit/${record.params.mediaContent}`;
  const videoLink = `https://www.youtube.com/watch?v=${record.params.videoId}`;

  const handleClickViewCreatorProfile = () => {
    if (profileLink) {
      window.open(profileLink, '_blank').focus();
    }
  };

  const handleClickViewMediaContent = () => {
    if (mediaContentLink) {
      window.open(mediaContentLink, '_blank').focus();
    }
  };

  const handleClickViewYouTubeVideo = () => {
    if (videoLink) {
      window.open(videoLink, '_blank').focus();
    }
  };

  // const handleClickViewSentimentHistory = () => {
  //   setCurrentComment(record);
  //   setShowStatusHistoryModal(true);
  // };

  const handleClickCopyToClipboard = () => {
    if (record.params?.textOriginal) {
      const textField = document.createElement('textarea');
      textField.innerText = record.params.textOriginal;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
    }
  };

  const allowRunAnalysis = (record.params && !record.params['sentimentStatus.value']) ||
    (record.params && record.params['sentimentStatus.value'] && (record.params['sentimentStatus.value'] === 'Passed Analysis' || record.params['sentimentStatus.value'] === 'Needs Review'));
  const options = [
    // ...(useShowForUsers ? [{ label: 'View Sentiment History', handler: handleClickViewSentimentHistory },
    //   { type: 'divider', label: 'divider0' },
    // ] : []),
    { label: 'View Creator Profile', handler: handleClickViewCreatorProfile },
    { label: 'View Media Content', handler: handleClickViewMediaContent },
    { type: 'divider', label: 'divider1' },
    { label: 'View YouTube Video', handler: handleClickViewYouTubeVideo },
    { type: 'divider', label: 'divider2' },
    { label: 'Copy Comment To Clipboard', handler: handleClickCopyToClipboard },
    { type: 'divider', label: 'divider3' },
    { label: 'Refresh YouTube Video Data', handler: () => dispatch(showModalById(modalId, { record: record.populated.mediaContent })), disabled: !videoLink },
    ...(useShowForUsers ? [{ type: 'divider', label: 'divider4' },
      { label: 'Run Comment Sentiment Analysis', handler: () => dispatch(showModalById(modalCommentAnalysisId, { ids: [record.params._id] })), disabled: !allowRunAnalysis },
    ] : []),
  ];

  return (
    <div className="text-right">
      <TableCellContextMenu options={options} />
    </div>
  );
};
ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  // setShowStatusHistoryModal: PropTypes.func.isRequired,
  // setCurrentComment: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};
export default connect()(ContextMenuInList);
