import React, { useState, useMemo, useEffect } from 'react';
import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from 'mdi-react/InformationIcon';
import Select from 'react-select';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import ShowPayableJournalsButton from './ShowPayableJournals';

const StatusComponent = ({ record, type, updateRecordFunction }) => {
  const mapStatusArray = getConstant('AP', 'status', {});
  const { status, ilPayoutAmount } = record.params;
  const [selected, setSelected] = useState({ value: (status?.toString() || status), label: mapStatusArray[status] });
  const [loading, setLoading] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    setSelected({ value: (status?.toString() || status), label: mapStatusArray[status] });
  }, [status]);
  // console.log(record.params, '_____');
  const handleSelectChange = async (selectedRecord) => {
    setSelected(selectedRecord);
    setLoading(true);
    const value = selectedRecord ? selectedRecord.value : '';
    if (value === '') {
      return false;
    }
    const { id } = record;
    const data = new FormData();
    data.append('status', value);
    const api = new ApiClient();
    try {
      await api.recordAction({
        resourceId: type === 'agent' ? 'PayableAgent' : 'Payable',
        recordId: id,
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const e = await api.recordAction({
        resourceId: type === 'agent' ? 'PayableAgent' : 'Payable',
        recordId: id,
        actionName: 'show',
      });
      if (!e.data) throw new Error('Invalid responce');
      if (updateRecordFunction) {
        console.log(id, { status: value });
        updateRecordFunction(id, { status: value });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const payableJournalButton = useMemo(() =>
    // console.log(record.populated);
    // const recordToJournal = { ...record, populated: { ...record.populated, payableJournals: record.populated?.payableJournals.map(p => p.params) } };
    (
      <ShowPayableJournalsButton
        payable={record}
      />
    ), [record]);
  // const { record } = this.props;
  const mapStatus = Object.keys(mapStatusArray).map(key => ({ value: key, label: mapStatusArray[key], isDisabled: (['failed'].includes(key) || (key === 'complete' && Number(ilPayoutAmount || 0) !== 0)) }));
  const payableJournals = record && record.populated && record.populated.payableJournals ? record.populated.payableJournals : null;
  const payableJournal = payableJournals && payableJournals.length ? payableJournals[payableJournals.length - 1] : null;
  const isPayExist = !!payableJournal && payableJournal.status === 'Failed';
  // console.log(payableJournal);
  return status === null ? 'Unable To find Influencer' : (
    <div style={isPayExist ? { minWidth: '220px', width: '210px' } : {}}>
      <div style={{ minWidth: '150px', maxWidth: '150px' }} className="float-left">
        <Select
          isDisabled={loading || record.checkboxDisable}
          value={selected}
          options={mapStatus}
          onChange={handleSelectChange}
          menuPortalTarget={document.body}
        />
      </div>
      {isPayExist && (
        <div className="float-left text-center mt-1" style={{ minWidth: '30px' }}>
          <IconButton className="material-table__toolbar-button text-danger" id={`POP-${record.id}`} size="small"><InfoIcon size="25" /></IconButton>
          <Popover placement="left" isOpen={popoverOpen} target={`POP-${record.id}`} toggle={() => setPopoverOpen(!popoverOpen)}>
            <PopoverHeader>${payableJournal.description}</PopoverHeader>
            <PopoverBody>{`${payableJournal.details ? payableJournal.details : ''}`}</PopoverBody>
          </Popover>
        </div>
      )}
      <div className="float-left text-center mt-2" style={{ minWidth: '30px' }}>{payableJournalButton}</div>
    </div>
  );
};

StatusComponent.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
  type: PropTypes.string,
};
StatusComponent.defaultProps = {
  type: 'creator',
};
export default StatusComponent;
/*
export default class StatusComponent extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    updateRecordFunction: PropTypes.func.isRequired,
    type: PropTypes.string,
  }
  static defaultProps = {
    type: 'creator',
  }

  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.api = new ApiClient();
    const { record } = this.props;
    const { status } = record.params;
    this.mapStatusArray = getConstant('AP', 'status', {});
    this.state = {
      selected: { value: status ? status.toString() : status, label: this.mapStatusArray[status] },
      status,
      loading: false,
      popoverOpen: false,
    };
    this.setPopoverOpen = this.setPopoverOpen.bind(this);
  }
  setPopoverOpen() {
    this.setState(state => ({ popoverOpen: !state.popoverOpen }));
  }
  handleSelectChange(selected) {
    this.setState({ selected });
    this.setState({ loading: true });
    const { record, updateRecordFunction, type } = this.props;
    const value = selected ? selected.value : '';
    if (value === '') {
      return false;
    }
    const { id } = record;
    const data = new FormData();
    data.append('status', value);
    this.api.recordAction({
      resourceId: type === 'agent' ? 'PayableAgent' : 'Payable',
      recordId: id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      this.api.recordAction({
        resourceId: type === 'agent' ? 'PayableAgent' : 'Payable',
        recordId: id,
        actionName: 'show',
      }).then((e) => {
        this.setState({ loading: false });
        if (updateRecordFunction) updateRecordFunction(e.data.record, 'status');
      });
    });
    return true;
  }

  render() {
    const {
      selected,
      loading,
      status,
      popoverOpen,
    } = this.state;

    const { record } = this.props;
    const mapStatus = Object.keys(this.mapStatusArray).map(key => ({ value: key, label: this.mapStatusArray[key], isDisabled: (['complete', 'failed'].includes(key)) }));
    const payableJournals = record && record.populated && record.populated.payableJournals ? record.populated.payableJournals : null;
    const payableJournal = payableJournals && payableJournals.length ? payableJournals[payableJournals.length - 1] : null;
    const isPayExist = !!payableJournal && payableJournal.status === 'Failed';
    return status === null ? 'Unable To find Influencer' : (
      <div style={isPayExist ? { minWidth: '200px', maxWidth: '200px' } : {}}>
        <div style={{ minWidth: '150px', maxWidth: '150px' }} className="float-left">
          <Select
            isDisabled={loading || record.checkboxDisable}
            value={selected}
            options={mapStatus}
            onChange={this.handleSelectChange}
          />
        </div>
        {isPayExist && (
          <div className="float-left text-center" style={{ minWidth: '40px' }}>
            <IconButton className="material-table__toolbar-button text-danger" id={`POP-${record.id}`} size="small"><InfoIcon size="25" /></IconButton>
            <Popover placement="left" isOpen={popoverOpen} target={`POP-${record.id}`} toggle={this.setPopoverOpen}>
              <PopoverHeader>${payableJournal.description}</PopoverHeader>
              <PopoverBody>{`${payableJournal.details ? payableJournal.details : ''}`}</PopoverBody>
            </Popover>
          </div>
        )}
      </div>
    );
  }
} */
