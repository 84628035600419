import React, { useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import CopyToClipboard from '../../../../../../shared/components/CopyToClipboardLeft';
import { CopyIcon } from '../../../../../../shared/helpers/Icons';
import { getServerDate, formatIntNumber } from '../../../../../../shared/helpers/WVFormatter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";

const Columns = () => {
  console.log('Columns');
  return [
    {
      Header: 'Creator',
      // accessor: () => null,
      accessor: p => `${p?.first_name || ''} ${p?.last_name || ''}`,
      id: 'Creator',
      Cell: p => useMemo(() => (
        <div>
          <Tooltip title="Open Creator Profile">
            {/* eslint-disable-next-line max-len */}
            <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original._id || ''}`}>{`${(p.row.original && p.row.original.first_name) || ''} ${(p.row.original && p.row.original.last_name) || ''}`}</a>
          </Tooltip>
          {p.row.original?.hsContactVid ? (
            <a
              href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.row.original.hsContactVid}/`}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#70bbfd', marginLeft: '6px' }}
              title="Open Hubspot Contact"
            >
              <i className="fab fa-hubspot" style={{ fontSize: '1em' }} />
            </a>) : null}
          {p.row.original.affiliateDataObject?.company && (
            <>
              <br />
              {p.row.original.affiliateDataObject.company}
            </>
          )}
          <br />
          {p.row.original?.email ? (
            <div>
              <CopyToClipboard
                value={p.row.original.email || ''}
                icon={(<span style={{ color: '#70bbfd' }}><CopyIcon /></span>)}
              />
              <span>{p.row.original.email || ''}</span>
            </div>
            // <CopyToClipboard value={p.row.original.influencerObject?.email}>{p.row.original.influencerObject?.email}</CopyToClipboard>
          ) : null}
        </div>
      ), [p.row.original]),
      width: 250,
    },
    {
      Header: 'Deal Owner',
      accessor: p => `${p?.dealOwner?.firstName || ''} ${p?.dealOwner?.lastName || ''}`,
      id: 'dealOwner',
      Cell: p => useMemo(() => (
        <span>
          {`${p.row.original.dealOwner?.firstName || ''} ${p.row.original.dealOwner?.lastName || ''}`}
        </span>
      ), [p.row.original]),
      width: 200,
    },
    {
      Header: 'Brand',
      accessor: (p) => (p.deal?.dealTerms?.brand || ''),
      id: 'brand',
      Cell: p => useMemo(() => {
        let title = (<div>
          <span>Warning: This creator has multiple active deals for this brand</span>
          <br />
          <br />
          {p.row.original.allDeals.map(deal => (
            <div key={deal._id}>
              <span>{deal.dealname}</span>
              <br />
              <span>Deal Type: {deal.dealTerms.type_of_deal}</span>
              <br />
              <span>Status: Active</span>
              <br />
              <br />
            </div>
          ))}
        </div>);
        return (
          <span>{p.row.original.deal?.dealTerms?.brand} {p.row.original.allDeals.length > 1 && (
            <Tooltip title={title}>
              <IconButton size="small">
                <i className="fa fa-info-circle" style={{color: '#70bbfd', fontSize: '0.8em'}}/>
              </IconButton>
            </Tooltip>
          )}</span>
        );
      }, [p.row.original]),
      width: 200,
    },
    {
      Header: 'Date of First Conversion',
      accessor: (p) => (p.date_of_first_conversion ? getServerDate(p.date_of_first_conversion) : ''),
      id: 'date_of_first_conversion',
      Cell: p => useMemo(() => (
        <span>{p.row.original.date_of_first_conversion ? getServerDate(p.row.original.date_of_first_conversion) : ''}</span>
      ), [p.row.original]),
      className: 'align-middle text-center',
      width: 200,
    },
    {
      Header: 'URL Validation Status',
      accessor: (p) => (p.mediaContent?.urlStatus ? p.mediaContent.urlStatus : 'No Status'),
      id: 'url_validation_status',
      Cell: p => useMemo(() => (
        <span>{p.row.original.mediaContent?.urlStatus ? p.row.original.mediaContent.urlStatus : (
          <span className="text-danger">
            No Status&nbsp;
            <FontAwesomeIcon icon="thumbs-down" className="text-danger" />
          </span>
        )}</span>
      ), [p.row.original]),
      width: 200,
    },
    {
      Header: 'Last Publication Date',
      accessor: (p) => (p.mediaContent?.publishDate ? getServerDate(p.mediaContent.publishDate) : ''),
      id: 'publishDate',
      Cell: p => useMemo(() => (
        <span>{p.row.original.mediaContent?.publishDate ? getServerDate(p.row.original.mediaContent.publishDate) : ''}</span>
      ), [p.row.original]),
      width: 200,
    },
    {
      Header: 'All Time Conversion',
      accessor: (p) => (p.conversions ? p.conversions : 0),
      id: 'all_time_conversion',
      Cell: p => useMemo(() => (
        <span>{p.row.original.conversions ? formatIntNumber(p.row.original.conversions) : ''}</span>
      ), [p.row.original]),
      className: 'align-middle text-center',
      width: 160,
    },
    {
      Header: 'Conversion in Timeframe',
      accessor: (p) => (p.conversionsInTimeframe?.length ? p.conversionsInTimeframe.length : 0),
      id: 'conversion_in_timeframe',
      Cell: p => useMemo(() => (
        <span>{p.row.original.conversionsInTimeframe?.length ? formatIntNumber(p.row.original.conversionsInTimeframe.length) : 0}</span>
      ), [p.row.original]),
      className: 'align-middle text-center',
      width: 200,
    },
    {
      Header: 'Clicks in Timeframe',
      accessor: (p) => (p.rate?.clicks ? Number(p.rate.clicks) : 0),
      id: 'clicks_in_timeframe',
      Cell: p => useMemo(() => (
        <span>{p.row.original.rate?.clicks ? Number(p.row.original.rate.clicks) : ''}</span>
      ), [p.row.original]),
      className: 'align-middle text-center',
      width: 155,
    },
    {
      Header: 'Conversion Rate',
      accessor: (p) => (p.rate ? Number(p.rate.ltr) : 0),
      id: 'conversion_rate',
      Cell: p => useMemo(() => (
        <span>{p.row.original.rate ? `${Number(p.row.original.rate.ltr).toFixed(2)}%` : ''}</span>
      ), [p.row.original]),
      className: 'align-middle text-center',
      width: 150,
    },
  ];
};

export default Columns;

