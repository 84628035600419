// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Spinner,
  FormGroup,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import withNotice from '../../../../../../App/store/with-notice';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
// import { getCookie, setCookie } from '../../../../../../../shared/helpers/WVUtilities';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { modalId } from '../../../CpmInvoice/components/custom/Modal/UploadInvoiceModal';
import { AddCreatorsModalId } from '../modals/AddCreatorsModal';
import { modalId as publishInvoiceModalId } from '../modals/PublishInvoice';
import { goToPage, getCookie } from '../../../../../../../shared/helpers/WVUtilities';
import { modalId as publishLookerModalId } from '../modals/PublishLookerInvoice';
import { modalId as syncContentReleaseDateOrderModalId } from '../modals/FetchContentReleaseDate';
// import NewInsertionOrder from './NewInsertionOrder';

const Actions = React.memo(({
  onSearchChange,
  searchValue,
  // setApprovalStatusFilters,
  invoice,
  getInvoice,
  loading,
  addNotice,
  downloadCsv,
  setStartLoadCreators,
  startDate, setStartDate,
  endDate, setEndDate,
  filtersPrivacyStatus,
  setFiltersPrivacyStatus,
  dealStageFilters,
  setDealStageFilters,
  dealstagesOptions,
}) => {
  console.log('invoice: ', invoice);
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('cpmInvoiceProspectFilters') === 'true');
  const [invoiceNo, setInvoiceNo] = useState(0);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [datePaid, setDatePaid] = useState(null);
  const [loadingField, setLoading] = useState(false);

  const handleChangePrivacyStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersPrivacyStatus({
        ...filtersPrivacyStatus,
        [name]: checked,
      });
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setInvoiceNo(invoice?.params?.invoiceNumber);
  }, [invoice?.params?.invoiceNumber]);

  useEffect(() => {
    setStartDate(invoice?.params?.startDate ? moment(moment.utc(invoice?.params?.startDate).format('YYYY-MM-DD')).toDate() : null);
  }, [invoice?.params?.startDate]);
  useEffect(() => {
    setEndDate(invoice?.params?.endDate ? moment(moment.utc(invoice?.params?.endDate).format('YYYY-MM-DD')).toDate() : null);
  }, [invoice?.params?.endDate]);

  useEffect(() => {
    setDatePaid(invoice?.params?.datePaid?.currentDate ? moment(moment.utc(invoice?.params?.datePaid.currentDate).format('YYYY-MM-DD')).toDate() : null);
  }, [invoice?.params?.datePaid?.currentDate]);

  useEffect(() => {
    if (!loading) {
      setInvoiceNo(invoice?.params?.invoiceNumber);
      setStartDate(invoice?.params?.startDate ? moment(moment.utc(invoice?.params?.startDate).format('YYYY-MM-DD')).toDate() : null);
      setEndDate(invoice?.params?.endDate ? moment(moment.utc(invoice?.params?.endDate).format('YYYY-MM-DD')).toDate() : null);
      setDatePaid(invoice?.params?.datePaid?.currentDate ? moment(moment.utc(invoice?.params?.datePaid.currentDate).format('YYYY-MM-DD')).toDate() : null);
    }
  }, [loading]);

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  /* useEffect(() => {
    setCookie('cpmInvoiceProspectFilters', showFilters.toString(), 35);
  }, [showFilters]); */

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setDealStageFilters([]);
    // setApprovalStatusFilters({
    //   New: true, 'In Review': true, Signed: true,
    // });
  };
  const handleChange = async () => {
    try {
      setLoading(true);
      const data = await axios.post('/api/portal/prospects/post-cpm-invoice-update', {
        ids: [invoice.id],
        values: {
          invoiceNumber: Number(invoiceNo || 0),
        },
      });
      if (data?.data?.success) {
        getInvoice();
      }
      addNotice({
        message: 'The Invoice has been saved successfully',
        type: 'success',
      });
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to save Invoice',
        type: 'error',
      });
      getInvoice();
    }
    setLoading(false);
  };
  const handleChangeDate = async (dateParam, value) => {
    setLoading(true);
    try {
      const data = await axios.post('/api/portal/prospects/post-cpm-invoice-update', {
        ids: [invoice.id],
        values: {
          [dateParam]: moment.utc(moment(value).format('YYYY-MM-DD')).toDate(),
        },
      });
      if (data?.data?.success) {
        getInvoice();
      }
      addNotice({
        message: 'The Invoice has been saved successfully',
        type: 'success',
      });
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to save Invoice',
        type: 'error',
      });
      console.error(err);
      getInvoice();
    }
    setLoading(false);
  };
  const shouldDisable = invoice?.params?.status?.status !== 'New';

  return (
    <div>
      <Row>
        <Col xs="10">
          <h4 className="mb-3">{!loading ? invoice?.populated?.brand?.params?.companyName : (<Spinner color="primary" size="sm" />)}</h4>
          <Row>
            <Col xl="auto" lg="4">
              <FormGroup row>
                <Label className="pt-2" xl="4">Invoice No.</Label>
                <Col sm="8">
                  <Input
                    type="text"
                    className="w-100"
                    placeholder="Invoice Number"
                    value={invoiceNo || 0}
                    onChange={e => setInvoiceNo(Number(e.target?.value) || 0)}
                    onBlur={handleChange}
                    disabled={loadingField || loading || shouldDisable}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col xl="auto" lg="4">
              <FormGroup row>
                <Label className="pt-2" xl="4">Start Date</Label>
                <Col xl="8">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => { setStartDate(date); handleChangeDate('startDate', date); }}
                    dateFormat="MM/dd/yyyy"
                    // minDate={minDate.toDate()}
                    // maxDate={maxDate.toDate()}
                    placeholderText="Start Date (ET)"
                    dropDownMode="select"
                    wrapperClassName="w-100"
                    className="form-control"
                    disabled={loadingField || loading || shouldDisable}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label className="pt-2 pl-3" sm="4">End Date{' '}</Label>
                <Col xl="8">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => { setEndDate(date); handleChangeDate('endDate', date); }}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="End Date (ET)"
                    // minDate={minDate.toDate()}
                    // maxDate={maxDate.toDate()}
                    dropDownMode="select"
                    wrapperClassName="w-100"
                    className="form-control"
                    disabled={loadingField || loading || shouldDisable}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col xl="auto" lg="4">
              <FormGroup row>
                <Label className="pt-2 pl-3">Date Paid</Label>
                <Col>
                  <DatePicker
                    selected={datePaid}
                    onChange={(date) => { setDatePaid(date); handleChangeDate('datePaid', date); }}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Date Paid (ET)"
                    // minDate={minDate.toDate()}
                    // maxDate={maxDate.toDate()}
                    dropDownMode="select"
                    wrapperClassName="w-100"
                    className="form-control"
                    disabled={loadingField || loading || shouldDisable}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col />
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => { setStartLoadCreators(true); dispatch(showModalById(AddCreatorsModalId, { record: invoice })); }}
              >
                Add Creators
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem header >Invoice</DropdownItem>
              <DropdownItem
                onClick={() => { dispatch(showModalById(publishInvoiceModalId, { record: invoice })); }}
              >
                Publish
              </DropdownItem>
              <DropdownItem
                onClick={() => goToPage(invoice.params?.googleSheetFiles?.length > 0 ? invoice.params.googleSheetFiles.at(-1).fileUrl : '')}
                disabled={!invoice?.params?.googleSheetFiles?.length}
              >
                View
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => { dispatch(showModalById(publishLookerModalId, { record: invoice })); }}
              >
                Publish Looker Update Data
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  console.log('click invoice: ', invoice);
                  dispatch(showModalById(syncContentReleaseDateOrderModalId, { record: invoice }));
                }}
              >
                Sync Data from HubSpot
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  const id = invoice.params?.googleSheetFiles?.length > 0 ? invoice.params.googleSheetFiles.at(-1).fileId : '';
                  goToPage(`https://docs.google.com/spreadsheets/d/${id}/export?format=pdf&gridlines=false`);
                }}
                disabled={!invoice?.params?.googleSheetFiles?.length}
              >
                Download Unsigned PDF
              </DropdownItem>
              <DropdownItem
                onClick={() => { dispatch(showModalById(modalId, { record: invoice })); }}
                disabled={!(invoice?.params?.googleSheetFiles && invoice.params.googleSheetFiles.length > 0)}
              >
                Upload Signed PDF
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={downloadCsv}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row className="mt-4 mb-2">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Creator, Contact, Insertion Order"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} />
      </Row>
      {showFilters && (
        <Row>
          <Col md={6} lg={4} xl={3}>
            <Label className="bold-text" style={{ marginBottom: '8px' }}>
              Video Privacy Status
            </Label>
            <FormGroup style={{ marginBottom: '10px' }} >
              <CheckBoxField
                name="public"
                label="Public"
                value={filtersPrivacyStatus.public}
                onChange={event => handleChangePrivacyStatusFilters(event, 'public')}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '10px' }} >
              <CheckBoxField
                name="unlisted"
                label="Unlisted"
                value={filtersPrivacyStatus.unlisted}
                onChange={event => handleChangePrivacyStatusFilters(event, 'unlisted')}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '10px' }} >
              <CheckBoxField
                name="private"
                label="Private/Invalid"
                value={filtersPrivacyStatus.private}
                onChange={event => handleChangePrivacyStatusFilters(event, 'private')}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '0' }} >
              <CheckBoxField
                name="na"
                label="Not Assigned"
                value={filtersPrivacyStatus.na}
                onChange={event => handleChangePrivacyStatusFilters(event, 'na')}
              />
            </FormGroup>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <Label className="bold-text">
              Deal Stages
            </Label>
            <Select
              value={dealStageFilters}
              options={dealstagesOptions}
              onChange={setDealStageFilters}
              isClearable
              isMulti
            />
          </Col>
        </Row>
      )}
    </div>
  );
});
Actions.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  // setApprovalStatusFilters: PropTypes.func.isRequired,
  // approvalStatusFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  getInvoice: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  invoice: PropTypes.objectOf(PropTypes.any).isRequired,
  downloadCsv: PropTypes.func.isRequired,
  setStartLoadCreators: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
    PropTypes.bool,
  ]),
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
    PropTypes.bool,
  ]),
  setEndDate: PropTypes.func.isRequired,
  filtersPrivacyStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  setFiltersPrivacyStatus: PropTypes.func.isRequired,
  dealStageFilters: PropTypes.arrayOf(PropTypes.any),
  setDealStageFilters: PropTypes.func,
  dealstagesOptions: PropTypes.arrayOf(PropTypes.any),
};

Actions.defaultProps = {
  endDate: '',
  startDate: '',
  dealStageFilters: [],
  setDealStageFilters: () => {},
  dealstagesOptions: [],
};

export default withNotice(Actions);
