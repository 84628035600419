import React from 'react';
import { connect } from 'react-redux';
import {
  Modal, ButtonToolbar, Button, ModalHeader, ModalBody, Col, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
// import HighLight from "../../../../../../../../shared/components/HighLight";
import CopyToClipboard from '../../../../../../../../shared/components/CopyToClipboardLeft';

const InternalRequestModal = ({
  modal,
  setModalVisible,
  creatorName,
  results,
  currentAdminUser,
  prospectOwner,
}) => {
  let iconClassName = '';
  let message = '';
  let linkMessage = '';
  let link = '';
  let color = '#70bbfd';

  const handleClose = () => {
    if (results?.message === 'Success') {
      // eslint-disable-next-line max-len
      window.location.replace(`/research/internal-prospects?filters.prospectType=Standard&filters.prospectType=Internal&filters.approvalStatusinfluencelogicstatus=Approved&filters.approvalStatusinfluencelogicstatus=Hold&filters.approvalStatusinfluencelogicstatus=In+Review&filters.approvalStatusinfluencelogicstatus=Mining&filters.approvalStatusinfluencelogicstatus=Resubmit&filters.approvalStatusinfluencelogicstatus=Rejected&filters.approvalStatusbrandstatus=Approved&filters.approvalStatusbrandstatus=In+Review&filters.approvalStatusbrandstatus=Hold&filters.approvalStatusbrandstatus=Rejected&filters.existStatus=info&filters.existStatus=warning&filters.existStatus=noMatch&filters.prospectOwners=${prospectOwner || currentAdminUser._id}`);
    } else {
      setModalVisible(false);
    }
  };

  switch (results.message) {
    case 'existProspect':
      iconClassName = 'fa fa-exclamation-triangle';
      message = `${creatorName} is an existing Prospect with the same Target Brand and was not uploaded.`;
      linkMessage = 'View the Prospect';
      // eslint-disable-next-line max-len
      link = `/research/internal-prospects?filters.fullSearchName=${encodeURIComponent(results.data.email || results.data.title || results.data.name)}&filters.approvalStatusinfluencelogicstatus=Approved&filters.approvalStatusinfluencelogicstatus=Hold&filters.approvalStatusinfluencelogicstatus=In+Review&filters.approvalStatusinfluencelogicstatus=Mining&filters.approvalStatusinfluencelogicstatus=Rejected&filters.approvalStatusinfluencelogicstatus=Resubmit&filters.approvalStatusbrandstatus=Approved&filters.approvalStatusbrandstatus=In+Review&filters.approvalStatusbrandstatus=Hold&filters.approvalStatusbrandstatus=Rejected&filters.existStatus=info&filters.existStatus=warning&filters.existStatus=noMatch&filters.prospectType=Standard&filters.prospectType=Internal&filters.brands=${results.data.brand}&page=1`;
      color = 'red';
      break;
    case 'existProspectWithoutCurrentBrand':
      iconClassName = 'fa fa-exclamation-triangle';
      message = `${creatorName} is already a Prospect and was not uploaded. Enable 'Allow duplicate Prospects when Target Brand is unique' to proceed.`;
      linkMessage = 'View the Prospect';
      // eslint-disable-next-line max-len
      link = `/research/internal-prospects?filters.fullSearchName=${encodeURIComponent(results.data.email || results.data.title || results.data.name)}&filters.approvalStatusinfluencelogicstatus=Approved&filters.approvalStatusinfluencelogicstatus=Hold&filters.approvalStatusinfluencelogicstatus=In+Review&filters.approvalStatusinfluencelogicstatus=Mining&filters.approvalStatusinfluencelogicstatus=Rejected&filters.approvalStatusinfluencelogicstatus=Resubmit&filters.approvalStatusbrandstatus=Approved&filters.approvalStatusbrandstatus=In+Review&filters.approvalStatusbrandstatus=Hold&filters.approvalStatusbrandstatus=Rejected&filters.existStatus=info&filters.existStatus=warning&filters.existStatus=noMatch&filters.prospectType=Standard&filters.prospectType=Internal&filters.brands=${results.data.brand}&page=1`;
      color = 'red';
      break;
    case 'existHubspot':
      iconClassName = 'fa fa-exclamation-triangle';
      message = `${creatorName} is an existing Contact in HubSpot and was not uploaded.`;
      linkMessage = 'View the Contact';
      link = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${results.data.hsUserId}`;
      color = 'red';
      break;
    default:
      iconClassName = 'fas fa-info-circle';
      message = `${creatorName} was successfully added to the Prospect list.`;
      linkMessage = '';
      link = '';
      color = '#70bbfd';
      break;
  }
  return (
    <Modal
      isOpen={modal}
      toggle={handleClose}
      style={{ minWidth: '430px' }}
      className="ltr-support"
    >
      <ModalHeader className="modal__header modal-title-statistics" toggle={handleClose} tag="h4">
        <span>Internal Prospect Request</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="3" className="text-center" style={{ color }}> <i className={iconClassName} style={{ fontSize: '5em' }} /></Col>
          <Col xs="9">
            <h4>{results.message === 'Success' ? 'Prospect Added' : 'Unable to add Prospect'}</h4>
            <hr style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} />
            <span>{message}</span>
            <br />
            {link && (
              <div>
                <span>
                  <a
                    title={linkMessage}
                    target="_blank"
                    rel="noreferrer"
                    href={link}
                  >
                    {linkMessage}
                  </a>
                </span>
                <CopyToClipboard value={results.message === 'existHubspot' ? link : `${results.baseUrl}${link}`}> </CopyToClipboard>
              </div>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" color="primary" onClick={handleClose}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

InternalRequestModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  results: PropTypes.objectOf(PropTypes.any).isRequired,
  creatorName: PropTypes.string.isRequired,
  prospectOwner: PropTypes.string,
  currentAdminUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

InternalRequestModal.defaultProps = {
  prospectOwner: '',
};

const mapStateToProps = state => ({
  currentAdminUser: state.session,
});

export default connect(mapStateToProps)(InternalRequestModal);
