import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import ReactTags from 'react-tag-autocomplete';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
} from 'reactstrap';
import { NumericFormat as NumberFormat } from 'react-number-format';
import DatePicker from 'react-datepicker';
import IconButton from '@material-ui/core/IconButton';
import Select from 'react-select';
import TuneIcon from 'mdi-react/TuneIcon';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import { setCookie, getCookie } from '../../../../../../../shared/helpers/WVUtilities';
import IntervalDatePickerField from '../../../../../Influencer/Research/Prospects/components/custom/IntervalDatePickerField';

const comparisonSymbols = [
  { value: '=', label: '=' },
  { value: '>', label: '>' },
  { value: '>=', label: '>=' },
  { value: '<', label: '<' },
  { value: '<=', label: '<=' },
  { value: 'Between', label: 'Between' },
];

const customStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#e9ecef' : 'white',
  }),
};

const Actions = React.memo(({
  onSearchChange,
  nameFilter,
  uploadTypeFilter,
  setUploadTypeFilter,
  adminUsers,
  uploadOwnerFilter,
  setUploadOwnerFilter,
  uploadStartDateValue,
  setUploadStartDateValue,
  enableUploadStartFilter,
  setEnableUploadStartFilter,
  startUploadDateFrom,
  setStartUploadDateFrom,
  startUploadDateTo,
  setStartUploadDateTo,
  singleUploadStartDate,
  setSingleUploadStartDate,
  successPercentValue,
  setSuccessPercentValue,
  enableSuccessPercent,
  setEnableSuccessPercent,
  successPercentMin,
  setSuccessPercentMin,
  successPercentMax,
  setSuccessPercentMax,
  singleSuccessPercent,
  setSingleSuccessPercent,
  enableDuplicatePercent,
  duplicatePercentMin,
  duplicatePercentMax,
  singleDuplicatePercent,
  duplicatePercentValue,
  setEnableDuplicatePercent,
  setDuplicatePercentMin,
  setDuplicatePercentMax,
  setSingleDuplicatePercent,
  setDuplicatePercentValue,
}) => {
  const [search, setSearch] = useState(nameFilter);
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('prospectUploadsShowFilter') === 'true');
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(uploadOwnerFilter);
  }, [uploadOwnerFilter.length]);

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('prospectUploadsShowFilter', showFilters.toString(), 35);
  }, [showFilters]);

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setUploadTypeFilter({
      'Creator Search': true,
      'Internal Request': true,
      'Upload CSV': true,
    });
    setTags([]);
    setUploadOwnerFilter([]);
    setEnableUploadStartFilter(false);
    setUploadStartDateValue({ value: '<=', label: '<=' });
    setSingleUploadStartDate(null);
    setStartUploadDateTo(null);
    setStartUploadDateFrom(null);
    setEnableSuccessPercent(false);
    setSuccessPercentValue({ value: '<=', label: '<=' });
    setSingleSuccessPercent(null);
    setSuccessPercentMax(null);
    setSuccessPercentMin(null);
    setEnableDuplicatePercent(false);
    setDuplicatePercentValue({ value: '<=', label: '<=' });
    setSingleDuplicatePercent(null);
    setDuplicatePercentMax(null);
    setDuplicatePercentMin(null);
  };

  const handleChangeSuccessPercentCheckBox = ({ target }) => {
    setEnableSuccessPercent(!enableSuccessPercent);
    if (!target.checked) {
      setSingleSuccessPercent(null);
      setSuccessPercentMin(null);
      setSuccessPercentMax(null);
    }
  };

  const handleChangeDuplicatePercentCheckBox = ({ target }) => {
    setEnableDuplicatePercent(!enableDuplicatePercent);
    if (!target.checked) {
      setSingleDuplicatePercent(null);
      setDuplicatePercentMin(null);
      setDuplicatePercentMax(null);
    }
  };

  const onDeleteOwner = (i) => {
    const ownersNew = tags.slice(0);
    ownersNew.splice(i, 1);
    setTags(ownersNew);
    setUploadOwnerFilter(ownersNew);
  };

  const onAdditionOwner = (owner) => {
    const ownersNew = [].concat(tags, owner);
    setUploadOwnerFilter(ownersNew);
    setTags(ownersNew);
  };

  const handleUploadTypeFilterChange = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setUploadTypeFilter({
        ...uploadTypeFilter,
        [name]: checked,
      });
    }
  };

  const handleChangeContentReleaseDateCheckBox = ({ target }) => {
    setEnableUploadStartFilter(!enableUploadStartFilter);
    if (!target.checked) {
      setSingleUploadStartDate(null);
      setStartUploadDateTo(null);
      setStartUploadDateFrom(null);
    }
  };

  return (
    <div className="prospects-actions">
      <Row className="mt-3">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search by Name"
              value={search}
              onChange={p => setSearch(p.target.value)}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Tooltip title="Reset Filters">
            <Button
              color="secondary"
              size="sm"
              onClick={resetFilters}
              style={{
                width: '100%',
                padding: '4px 8px',
                height: '38px',
              }}
            >
              <i className="fa fa-sync" aria-hidden="true" />&nbsp;
              <span className="btn-text" style={{ fontSize: '1.2em' }}>Reset</span>
            </Button>
          </Tooltip>
        </Col>
        <Col sm={2} style={{ paddingLeft: '24px' }}>
          <Tooltip title="Toggle Filters">
            <IconButton
              id="filtersMenuButton"
              size="small"
              onClick={() => setShowFilters(!showFilters)}
              className="material-table__toolbar-button"
              style={{ height: '38px' }}
            >
              <TuneIcon size="30" />
            </IconButton>
          </Tooltip>
        </Col>
      </Row>
      { showFilters && (
      <Row>
        <Col sm={2} style={{ paddingLeft: '20px' }}>
          <Label className="bold-text" style={{ marginBottom: '14px' }}>
            Upload Type
          </Label>
          <FormGroup>
            <CheckBoxField
              name="CreatorSearch"
              label="Creator Search"
              value={uploadTypeFilter['Creator Search']}
              onChange={event => handleUploadTypeFilterChange(event, 'Creator Search')}
            />
          </FormGroup>
          <FormGroup>
            <CheckBoxField
              name="InternalRequest"
              label="Internal Request"
              value={uploadTypeFilter['Internal Request']}
              onChange={event => handleUploadTypeFilterChange(event, 'Internal Request')}
            />
          </FormGroup>
          <FormGroup>
            <CheckBoxField
              name="UploadCSV"
              label="Upload CSV"
              value={uploadTypeFilter['Upload CSV']}
              onChange={event => handleUploadTypeFilterChange(event, 'Upload CSV')}
            />
          </FormGroup>
        </Col>
        <Col sm={4}>
          <Label className="bold-text">
            Upload Owner(s)
          </Label>
          { adminUsers && adminUsers.length > 0 &&
            <ReactTags
              className="mr-2"
              tags={tags}
              suggestions={adminUsers}
              onDelete={onDeleteOwner}
              onAddition={onAdditionOwner}
              delimiters={['Enter', ',']}
              placeholderText="Prospect Upload Owner name(s)"
              autocomplete
            />
          }
        </Col>
        <Col>
          <Row>
            <Col sm={1} />
            <Col sm={11}>
              <Label className="bold-text" style={{ marginBottom: '8px' }}>
                Upload Start Time
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm={1}>
              <FormGroup
                style={{ paddingTop: '13px', paddingLeft: '8px' }}
              >
                <CheckBoxField
                  name="uploadStartTimeCheckBox"
                  value={enableUploadStartFilter}
                  onChange={e => e.target && handleChangeContentReleaseDateCheckBox(e)}
                />
              </FormGroup>
            </Col>
            <Col sm={5}>
              <Select
                value={uploadStartDateValue}
                onChange={(option) => {
                      if ((uploadStartDateValue !== 'Between' && option.value === 'Between') || (uploadStartDateValue === 'Between' && option.value !== 'Between')) {
                        setStartUploadDateFrom(null);
                        setStartUploadDateTo(null);
                        setSingleUploadStartDate(null);
                      }
                      setUploadStartDateValue(option);
                    }}
                options={comparisonSymbols}
                isDisabled={!enableUploadStartFilter}
                styles={customStyles}
              />
            </Col>
            <Col sm={6}>
              {uploadStartDateValue.value === 'Between' ? (
                <div className="pb-2">
                  <IntervalDatePickerField
                    onChange={({ start, end }) => {
                          setStartUploadDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setStartUploadDateTo(end);
                        }}
                    startDate={startUploadDateFrom}
                    endDate={startUploadDateTo}
                    maxDate={new Date()}
                    placeholderFrom="Start Date"
                    placeholderTo="End Date"
                    verticalFields
                    disabled={!enableUploadStartFilter}
                  />
                </div>
                  ) : (
                    <div className="date-picker datepicker-in-prospects">
                      <DatePicker
                        selected={singleUploadStartDate}
                        onChange={(date) => {
                          if (uploadStartDateValue.value === '<=' || uploadStartDateValue.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          setSingleUploadStartDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        className="form-control"
                        disabled={!enableUploadStartFilter}
                      />
                      {singleUploadStartDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '13px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
            </Col>
          </Row>
          <Row>
            <Col sm={1} />
            <Col sm={11}>
              <Label className="bold-text" style={{ marginBottom: '8px', marginTop: '8px' }}>
                Success Percentage
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm={1}>
              <FormGroup
                style={{ paddingTop: '13px', paddingLeft: '8px' }}
              >
                <CheckBoxField
                  name="successPercentCheckBox"
                  value={enableSuccessPercent}
                  onChange={e => e.target && handleChangeSuccessPercentCheckBox(e)}
                />
              </FormGroup>
            </Col>
            <Col sm={5} style={{ paddingLeft: '20px' }}>
              <Select
                value={successPercentValue}
                onChange={(option) => {
                      if ((successPercentValue !== 'Between' && option.value === 'Between') || (successPercentValue === 'Between' && option.value !== 'Between')) {
                        setSingleSuccessPercent(null);
                      }
                      setSuccessPercentValue(option);
                    }}
                options={comparisonSymbols}
                isDisabled={!enableSuccessPercent}
                styles={customStyles}
              />
            </Col>
            <Col sm={6}>
              {successPercentValue.value === 'Between' ? (
                <>
                  <NumberFormat
                    key="successPercentMin"
                    disabled={!enableSuccessPercent}
                    thousandSeparator
                    prefix=""
                    name="successPercentMin"
                    value={successPercentMin}
                    className="form-control"
                    placeholder="Min"
                    inputMode="numeric"
                    allowEmptyFormatting={false}
                    fixedDecimalScale
                    decimalScale={0}
                    onValueChange={values => setSuccessPercentMin(values.floatValue)}
                    onBlur={() => {
                          if (
                            successPercentMin !== null
                            && successPercentMax !== null
                            && (successPercentMin > successPercentMax)
                          ) {
                            setSuccessPercentMax(successPercentMin + 100);
                          }
                        }}
                  />
                  <NumberFormat
                    key="successPercentMax"
                    disabled={!enableSuccessPercent}
                    thousandSeparator
                    prefix=""
                    name="successPercentMax"
                    value={successPercentMax}
                    className="form-control"
                    style={{ marginTop: '24px' }}
                    placeholder="Max"
                    inputMode="numeric"
                    allowEmptyFormatting={false}
                    fixedDecimalScale
                    decimalScale={0}
                    onValueChange={values => setSuccessPercentMax(values.floatValue)}
                    onBlur={() => {
                          if (
                            successPercentMin !== null
                            && successPercentMax !== null
                            && (successPercentMin > successPercentMax)
                          ) {
                            setSuccessPercentMin(successPercentMax - 1);
                          }
                        }}
                  />
                </>
                  ) : (
                    <NumberFormat
                      key="singleSuccessPercent"
                      disabled={!enableSuccessPercent}
                      thousandSeparator
                      prefix=""
                      name="singleSuccessPercent"
                      value={singleSuccessPercent}
                      className="form-control"
                      placeholder="Success Percentage"
                      inputMode="numeric"
                      allowEmptyFormatting={false}
                      fixedDecimalScale
                      decimalScale={0}
                      onValueChange={values => setSingleSuccessPercent(values.floatValue)}
                    />
                  )}
            </Col>
          </Row>
          <Row>
            <Col sm={1} />
            <Col sm={11}>
              <Label className="bold-text" style={{ marginBottom: '8px', marginTop: '8px' }}>
                Duplicate Percentage
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm={1}>
              <FormGroup
                style={{ paddingTop: '13px', paddingLeft: '8px' }}
              >
                <CheckBoxField
                  name="duplicatePercentCheckBox"
                  value={enableDuplicatePercent}
                  onChange={e => e.target && handleChangeDuplicatePercentCheckBox(e)}
                />
              </FormGroup>
            </Col>
            <Col sm={5} style={{ paddingLeft: '20px' }}>
              <Select
                value={duplicatePercentValue}
                onChange={(option) => {
                      if ((duplicatePercentValue !== 'Between' && option.value === 'Between') || (duplicatePercentValue === 'Between' && option.value !== 'Between')) {
                        setSingleDuplicatePercent(null);
                      }
                      setDuplicatePercentValue(option);
                    }}
                options={comparisonSymbols}
                isDisabled={!enableDuplicatePercent}
                styles={customStyles}
              />
            </Col>
            <Col sm={6}>
              {duplicatePercentValue.value === 'Between' ? (
                <>
                  <NumberFormat
                    key="duplicatePercentMin"
                    disabled={!enableDuplicatePercent}
                    thousandSeparator
                    prefix=""
                    name="duplicatePercentMin"
                    value={duplicatePercentMin}
                    className="form-control"
                    placeholder="Min"
                    inputMode="numeric"
                    allowEmptyFormatting={false}
                    fixedDecimalScale
                    decimalScale={0}
                    onValueChange={values => setDuplicatePercentMin(values.floatValue)}
                    onBlur={() => {
                          if (
                            duplicatePercentMin !== null
                            && duplicatePercentMax !== null
                            && (duplicatePercentMin > duplicatePercentMax)
                          ) {
                            setDuplicatePercentMax(duplicatePercentMin + 100);
                          }
                        }}
                  />
                  <NumberFormat
                    key="duplicatePercentMax"
                    disabled={!enableDuplicatePercent}
                    thousandSeparator
                    prefix=""
                    name="duplicatePercentMax"
                    value={duplicatePercentMax}
                    className="form-control"
                    style={{ marginTop: '24px' }}
                    placeholder="Max"
                    inputMode="numeric"
                    allowEmptyFormatting={false}
                    fixedDecimalScale
                    decimalScale={0}
                    onValueChange={values => setDuplicatePercentMax(values.floatValue)}
                    onBlur={() => {
                          if (
                            duplicatePercentMin !== null
                            && duplicatePercentMax !== null
                            && (duplicatePercentMin > duplicatePercentMax)
                          ) {
                            setDuplicatePercentMin(duplicatePercentMax - 1);
                          }
                        }}
                  />
                </>
                  ) : (
                    <NumberFormat
                      key="singleDuplicatePercent"
                      disabled={!enableDuplicatePercent}
                      thousandSeparator
                      prefix=""
                      name="singleDuplicatePercent"
                      value={singleDuplicatePercent}
                      className="form-control"
                      placeholder="Duplicate Percentage"
                      inputMode="numeric"
                      allowEmptyFormatting={false}
                      fixedDecimalScale
                      decimalScale={0}
                      onValueChange={values => setSingleDuplicatePercent(values.floatValue)}
                    />
                  )}
            </Col>
          </Row>
        </Col>
      </Row>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  isEqual(a.enableUploadStartFilter, a1.enableUploadStartFilter) &&
  isEqual(a.uploadOwnerFilter, a1.uploadOwnerFilter) &&
  isEqual(a.uploadStartDateValue, a1.uploadStartDateValue) &&
  isEqual(a.singleUploadStartDate, a1.singleUploadStartDate) &&
  isEqual(a.startUploadDateFrom, a1.startUploadDateFrom) &&
  isEqual(a.startUploadDateTo, a1.startUploadDateTo) &&
  isEqual(a.uploadTypeFilter, a1.uploadTypeFilter) &&
  isEqual(a.enableSuccessPercent, a1.enableSuccessPercent) &&
  isEqual(a.successPercentValue, a1.successPercentValue) &&
  isEqual(a.singleSuccessPercent, a1.singleSuccessPercent) &&
  isEqual(a.successPercentMin, a1.successPercentMin) &&
  isEqual(a.enableDuplicatePercent, a1.enableDuplicatePercent) &&
  isEqual(a.duplicatePercentValue, a1.duplicatePercentValue) &&
  isEqual(a.singleDuplicatePercent, a1.singleDuplicatePercent) &&
  isEqual(a.duplicatePercentMin, a1.duplicatePercentMin) &&
  isEqual(a.duplicatePercentMax, a1.duplicatePercentMax));

Actions.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  nameFilter: PropTypes.string.isRequired,
  uploadTypeFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  setUploadTypeFilter: PropTypes.func.isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  setUploadOwnerFilter: PropTypes.func.isRequired,
  uploadOwnerFilter: PropTypes.arrayOf(PropTypes.any).isRequired,
  enableUploadStartFilter: PropTypes.bool.isRequired,
  setEnableUploadStartFilter: PropTypes.func.isRequired,
  uploadStartDateValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setUploadStartDateValue: PropTypes.func.isRequired,
  startUploadDateFrom: PropTypes.objectOf(PropTypes.any),
  setStartUploadDateFrom: PropTypes.func.isRequired,
  startUploadDateTo: PropTypes.objectOf(PropTypes.any),
  setStartUploadDateTo: PropTypes.func.isRequired,
  singleUploadStartDate: PropTypes.objectOf(PropTypes.any),
  setSingleUploadStartDate: PropTypes.func.isRequired,
  successPercentValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setSuccessPercentValue: PropTypes.func.isRequired,
  enableSuccessPercent: PropTypes.bool.isRequired,
  setEnableSuccessPercent: PropTypes.func.isRequired,
  successPercentMin: PropTypes.number,
  setSuccessPercentMin: PropTypes.func.isRequired,
  successPercentMax: PropTypes.number,
  setSuccessPercentMax: PropTypes.func.isRequired,
  singleSuccessPercent: PropTypes.number,
  setSingleSuccessPercent: PropTypes.func.isRequired,
  enableDuplicatePercent: PropTypes.bool.isRequired,
  duplicatePercentMin: PropTypes.number,
  duplicatePercentMax: PropTypes.number,
  singleDuplicatePercent: PropTypes.number,
  duplicatePercentValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setEnableDuplicatePercent: PropTypes.func.isRequired,
  setDuplicatePercentMin: PropTypes.func.isRequired,
  setDuplicatePercentMax: PropTypes.func.isRequired,
  setSingleDuplicatePercent: PropTypes.func.isRequired,
  setDuplicatePercentValue: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  startUploadDateFrom: null,
  startUploadDateTo: null,
  singleUploadStartDate: null,
  successPercentMin: null,
  successPercentMax: null,
  singleSuccessPercent: null,
  duplicatePercentMin: null,
  duplicatePercentMax: null,
  singleDuplicatePercent: null,
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default withStyles({
  root: {
    width: '100%',
    minWidth: '200px',
    paddingRight: '30px',
    position: 'relative',
    marginBottom: '10px',
  },
  close: {
    position: 'absolute',
    bottom: '1px',
    right: '0px',
  },
})(connect(mapStateToProps)(Actions));
