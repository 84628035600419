import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
// import IconButton from '@material-ui/core/IconButton';
// import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import { Button, Modal, ButtonToolbar } from 'reactstrap';


const GenerationInfo = ({
  startDate,
}) => {
  const [showReport, setShowReport] = useState(false);

  return (
    <div className="float-right mt-1">
      <Tooltip title="Report Details">
        <IconButton
          size="small"
          onClick={() => setShowReport(true)}
          className="material-table__toolbar-button"
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </IconButton>
      </Tooltip>
      <Modal
        isOpen={showReport}
        toggle={() => setShowReport(false)}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Report Details</h4>
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body ltr-support">
          <div className="pb-3">
            <div>This report will find all deals for the month of {moment(startDate).format('MMMM')} that have the following properties:</div>
            <strong>CPA Deal Types</strong>
            <ul>
              <li>Status is Active</li>
            </ul>
            <strong>Upfront, Upfront CPM & Media License Deal Types</strong>
            <ul>
              <li>Status is Active</li>
              <li>Deal stage is Deal Paid or Published</li>
              <li>Brand Approved Date:
                <ul>
                  <li>Has to be a valid date - can{'\''}t be empty</li>
                  <li>Can not be a date in the future</li>
                  <li>Use the Brand/Offer deal match that has the most recent Brand Approved Date</li>
                </ul>
              </li>
            </ul>
            <strong>Hybrid Deal Types</strong>
            <ul>
              <li>If there is a Guaranteed Payment Amount that is recoverable, the deal is treated the same an an Upfront until the full amount is paid back to InfluenceLogic.</li>
              <li>Once the Hybrid becomes a CPA style Deal Type we switch to CPA rules</li>
            </ul>
            <strong>Bonus Deal Type</strong>
            <ul>
              <li>Status is paid</li>
              <li>Deal stage is Deal Paid or Published</li>
              <li>Payment Status is Paid In Full or Partially Paid</li>
              <li>Bill Payment Date is in the specified month</li>
            </ul>
          </div>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button color="danger" onClick={() => setShowReport(false)}>Close</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};
GenerationInfo.propTypes = {
  startDate: PropTypes.string.isRequired,
};
export default GenerationInfo;
