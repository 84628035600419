/* eslint-disable filenames/match-exported */
import React from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { FormGroup, Label, Input, FormFeedback, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import WVValidator from '../../../../../shared/helpers/WVValidator';
import ButtonWithSpinner from '../../../../../shared/components/ButtonWithSpinner';


class BillComMFARefreshComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mfaSecurityCode: null,
      mfaChallengeId: null,
      errors: {},
      loading: {
        mfaChallenge: false,
        mfaAuthenticate: false,
      },
      modals: {
        mfaChallenge: true,
        mfaAuthenticate: false,
      },
    };
  }

  validateMFAAuthenticateErrors() {
    const { mfaSecurityCode } = this.state;

    const errors = {};
    if (WVValidator.isEmpty(mfaSecurityCode)) {
      errors.mfaSecurityCode = 'Please enter security code!';
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  processMFAAuthenticateError(error) {
    const message = error || 'Something went wrong!';

    this.setState({
      loading: { mfaAuthenticate: false },
      errors: { mfaSecurityCode: 'Provided code is invalid!' },
    });

    this.props.addNotice({
      message,
      type: 'error',
    });
  }

  challengeBillComMFA = () => {
    this.setState({ loading: { mfaChallenge: true } });

    axios({
      method: 'post',
      url: '/api/portal/billCom/mfa-challenge',
    }).then((response) => {
      if (response.data.success === true && response.data.challengeId) {
        this.setState({
          mfaChallengeId: response.data.challengeId,
          loading: { mfaChallenge: false },
          modals: {
            mfaChallenge: false,
            mfaAuthenticate: true,
          },
        });
      } else {
        this.setState({ loading: { mfaChallenge: false } });
        this.props.addNotice({
          message: response.data.error || 'Something went wrong!',
          type: 'error',
        });
      }
    }).catch((error) => {
      // console.log('CATCH ERROR: ', error);
      this.setState({ loading: { mfaChallenge: false } });
      this.props.addNotice({
        message: error.message,
        type: 'error',
      });
    });
  }

  authenticateBillComMFA = () => {
    if (!this.validateMFAAuthenticateErrors()) { return; }

    this.setState({
      loading: { mfaAuthenticate: true },
    });

    const { mfaChallengeId, mfaSecurityCode } = this.state;

    axios({
      method: 'post',
      url: '/api/portal/billCom/mfa-authenticate',
      data: {
        challengeId: mfaChallengeId,
        token: mfaSecurityCode,
      },
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);
      if (response.data.success !== true) {
        this.processMFAAuthenticateError(response.data.error);
        return;
      }

      this.setState({
        loading: { mfaAuthenticate: false },
        modals: { mfaAuthenticate: false },
      });
      this.props.addNotice({
        message: 'Bill.com MFA trusted session has been successfully renewed! You can send the payments now!',
        type: 'success',
      });
      this.props.onAuthenticationFinished();
    }).catch((error) => {
      // console.log('CATCH ERROR: ', error);
      this.processMFAAuthenticateError(error.message);
    });
  }

  handleMFACodeChange = (event) => {
    this.setState({ mfaSecurityCode: event.target.value });
  }

  toggleMFARequestModal = () => {
    const { mfaChallenge } = this.state.modals;
    if (mfaChallenge === true) {
      // hide modal, send a message
      this.props.onAuthenticationFinished();
    }
    this.setState({ modals: { mfaChallenge: !mfaChallenge } });
  }

  toggleMFAAuthenticateModal = () => {
    const { mfaAuthenticate } = this.state.modals;
    if (mfaAuthenticate === true) {
      // hide modal, send a message
      this.props.onAuthenticationFinished();
    }
    this.setState({ modals: { mfaAuthenticate: !mfaAuthenticate } });
  }
  render() {
    const { modals, errors, loading } = this.state;

    return (
      <div>
        { /* MFA Challenge modal */ }
        <Modal isOpen={modals.mfaChallenge} toggle={this.toggleMFARequestModal} style={{ minWidth: '200px' }} className="delete-modal">
          <ModalHeader>Additional verification required</ModalHeader>
          <ModalBody>
            <p>
              <a
                href="https://developer.bill.com/hc/en-us/articles/360035074972-Authentication"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bill.com
              </a>
              { ' ' }
              MFA trusted session has been expired! We‘ll send a security code to your phone number to renew it.
            </p>
          </ModalBody>
          <ModalFooter>
            <ButtonWithSpinner
              type="button"
              color="primary"
              onClick={this.challengeBillComMFA}
              loading={loading.mfaChallenge}
            >
              <span>Send Code</span>
            </ButtonWithSpinner>{' '}
            <Button color="secondary" onClick={this.toggleMFARequestModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
        { /* MFA Authenticate modal */ }
        <Modal isOpen={modals.mfaAuthenticate} toggle={this.toggleMFAAuthenticateModal} style={{ minWidth: '200px' }} className="delete-modal">
          <ModalHeader>Enter the code</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Security Code</Label>
              <Input
                type="text"
                name="email"
                placeholder="Enter security code"
                onChange={this.handleMFACodeChange}
                invalid={!!errors.mfaSecurityCode}
              />
              <FormFeedback>{errors.mfaSecurityCode}</FormFeedback>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <ButtonWithSpinner
              type="button"
              color="primary"
              onClick={this.authenticateBillComMFA}
              loading={loading.mfaAuthenticate}
            >
              <span>Submit</span>
            </ButtonWithSpinner>{' '}
            <Button color="secondary" onClick={this.toggleMFAAuthenticateModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

BillComMFARefreshComponent.propTypes = {
  // history: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
  onAuthenticationFinished: PropTypes.func.isRequired,
};

export default BillComMFARefreshComponent;
