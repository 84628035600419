import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { unflatten } from 'flat';
import { Spinner } from 'reactstrap';

const DealStageInList = React.memo(({
  record,
  dealStages,
  loadingDealStages,
}) => {
  const { params } = unflatten(record);
  const getDealStageName = () => {
    if (params.dealstage && dealStages.length > 0) {
      return dealStages.find(dealStage => dealStage.id === params.dealstage)?.name || '';
    }
    return '';
  };

  return (
    <>
      {loadingDealStages ? (
        <div className="text-center"><Spinner color="primary" size="lg" /></div>
      ) : (
        <span>
          {getDealStageName()}
        </span>
      )}
    </>
  );
}, (a, a1) => a.record.id === a1.record.id
  && _.isEqual(a.dealStages, a1.dealStages)
  && a.loadingDealStages === a1.loadingDealStages);

DealStageInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dealStages: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingDealStages: PropTypes.bool.isRequired,
};

export default DealStageInList;
