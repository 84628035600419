import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import withNotice from '../../../App/store/with-notice';
import { addNewScorecardCategory } from '../../../../redux/reducers/admin/ScoringReducer';

class ScorecardsQuestionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryName: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(selected) {
    this.setState({
      categoryName: selected.target.value,
    });
  }

  handleSubmit() {
    const { dispatch } = this.props;
    dispatch(addNewScorecardCategory(this.state.categoryName));
    this.setState({
      categoryName: '',
    });
  }

  render() {
    const {
      categoryName,
    } = this.state;

    return (
      <>
        <br /><br />
        <div className="card card-bordered">
          <div className="card-body">
            <div className="input-group">
              <Input
                type="text"
                id="newCategory"
                name="newCategory"
                placeholder="New Category Name"
                value={categoryName}
                onChange={this.handleChange}
              />
              <div className="input-group-append">
                <button onClick={this.handleSubmit} style={{ fontSize: '18px', padding: '5px 25px 4px 25px' }} className="btn btn-outline-secondary" type="button">
                  <i className="fas fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
      </>
    );
  }
}

ScorecardsQuestionForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
};


const mapStateToProps = () => ({

});

export default withNotice(connect(mapStateToProps)(ScorecardsQuestionForm));
