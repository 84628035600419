/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { unflatten } from 'flat';
import { addNotice } from '../../../containers/App/store/store';


/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const influencerProfileReducer = createSlice({
  name: 'influencer',
  initialState: {
    loading: {
      influencer: false,
      contact: false,
      deals: false,
      agreements: false,
      vanityURLs: false,
      influencerPayment: false,
      influencerStatus: false,
      influencerCompanyStatus: false,
      payments: false,
    },
    influencer: null,
    contact: null,
    prospect: null,
    deals: [],
    agents: {},
    payments: [],
    serviceAgreements: [],
    vanityURLs: [],
    billComVendors: [],
    lastFetched: new Date(),
    stripeIssue: null,
    billComIssue: null,
  },
  reducers: {
    setDeals(state, action) {
      const { deals } = action.payload;
      state.deals = deals.map(deal => unflatten({ ...deal }));
    },
    setAgents(state, action) {
      const { agents } = action.payload;
      state.agents = agents;
    },
    setAgreements(state, action) {
      const { agreements } = action.payload;
      state.serviceAgreements = agreements.map(deal => unflatten({ ...deal }));
    },
    setVanityURLs(state, action) {
      const { vanityURLs } = action.payload;
      state.vanityURLs = vanityURLs.map(vanityURL => unflatten({ ...vanityURL }));
    },
    setProspect(state, action) {
      const { prospect } = action.payload;
      state.prospect = prospect;
    },
    setBillComVendors(state, action) {
      const { vendors } = action.payload;
      state.billComVendors = vendors;
    },
    setDeal() {

    },
    saveDealStart(state) {
      state.loading = { ...state.loading, deals: true };
    },
    updateVanityURLsStart(state) {
      state.loading = { ...state.loading, vanityURLs: true };
    },
    setInfluencer(state, action) {
      const { influencer } = action.payload;
      state.influencer = unflatten(influencer);
    },
    setEditInfluencerPayment(state, action) {
      const loading = action.payload;
      state.loading = { ...state.loading, influencerPayment: loading };
    },
    setEditInfluencerStatus(state, action) {
      const loading = action.payload;
      state.loading = { ...state.loading, influencerStatus: loading };
    },
    setEditInfluencerCompanyStatus(state, action) {
      const loading = action.payload;
      state.loading = { ...state.loading, influencerCompanyStatus: loading };
    },
    setContact(state, action) {
      const { contact } = action.payload;
      state.contact = unflatten(contact);
    },
    getInfoStart(state) {
      state.loading = {
        ...state.loading,
        influencer: true,
        contact: true,
        deals: true,
        vanityURLs: true,
      };
    },
    getInfoEnd(state) {
      state.loading = {
        influencer: false,
        contact: false,
        deals: false,
        agreements: false,
        payments: false,
        vanityURLs: false,
        influencerPayment: false,
        influencerStatus: false,
        influencerCompanyStatus: false,
      };
    },

    getPaymentStart(state) {
      state.loading = {
        ...state.loading,
        payments: true,
      };
    },
    getPaymentEnd(state) {
      state.loading = {
        ...state.loading,
        payments: false,
      };
    },
    setPayments(state, action) {
      const { payments, stripeIssue, billComIssue } = action.payload;
      state.payments = unflatten(payments);
      state.stripeIssue = stripeIssue;
      state.billComIssue = billComIssue;
    },
    loadAgreements(state) {
      state.loading = {
        ...state.loading,
        agreements: true,
      };
    },
    loadVanityUrls(state) {
      state.loading = {
        ...state.loading,
        vanityURLs: true,
      };
    },
    clearAllData(state) {
      state.loading = {
        influencer: false,
        contact: false,
        deals: false,
        agreements: false,
        vanityURLs: false,
      };
      state.influencer = null;
      state.contact = null;
      state.prospect = null;
      state.deals = [];
      state.agents = [];
      state.vanityURLs = [];
    },

    saveContactStart(state) {
      state.loading = {
        ...state.loading,
        contact: true,
        deals: true,
      };
    },
    saveContactEnd(state, action) {
      const { contact, deals } = action.payload;
      state.contact = unflatten(contact);
      state.deals = deals.map(deal => unflatten({ ...deal }));
    },
    updateDealStatus(state, action) {
      // eslint-disable-next-line camelcase
      const { cpa_status: spaStatus, dealstage, id } = action.payload;
      state.deals = state.deals.map((d) => {
        const deal = { ...d };
        if (deal.id === id) {
          if (spaStatus) {
            deal.params.dealTerms.cpa_status = spaStatus;
          }
          if (dealstage) {
            deal.params.dealstage = dealstage;
          }
        }
        return deal;
      });
    },
    updateRowParams(state, action) {
      const { key, id, params } = action.payload;
      state[key] = state[key].map((r) => {
        const row = { ...r };
        if (row._id === id) {
          Object.keys(params).forEach((k) => {
            row[k] = params[k];
          });
        }
        return row;
      });
    },
  },
});
const getInfluencerApi = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/influencer/influencer-profile?id=${id}`);
};

const getAgreementsApi = async (influencerId) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/influencer-service-agreements?influencerId=${influencerId}`);
};
const getPaymentsApi = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/influencer/influencer-payments?id=${id}`);
};

const getVanityURLsApi = async (influencerId) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/influencer-vanity-urls?influencerId=${influencerId}`);
};

const refreshHasoffersApi = async (influencerId) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/influencer/post-refresh', { id: influencerId });
};
const refreshHubspotApi = async (influencerId) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/influencer/post-refresh-hubspot', { id: influencerId });
};
const refreshBillComApi = async (term) => {
  const api = new ApiClient();
  return api.client.get(`/api/utilities/billCom/search-vendors?term=${term}`);
};

const saveInfluencerContactApi = async (id, hsContactVid) => {
  const data = new FormData();
  data.append('hsContactVid', hsContactVid);
  const api = new ApiClient();
  return api.recordAction({
    resourceId: 'Influencer',
    recordId: id,
    actionName: 'edit',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const createVanityURLApi = async (data) => {
  const api = new ApiClient();
  return api.resourceAction({
    resourceId: 'VanityUrl',
    actionName: 'new',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const editVanityURLApi = async (id, data) => {
  const api = new ApiClient();
  return api.recordAction({
    resourceId: 'VanityUrl',
    recordId: id,
    actionName: 'edit',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const saveDealApi = async (id, params) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/hubil/save-deal-value', { ...params, dealId: id });
};
const fixDealApi = async (id) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/hubil/post-fix-deal', { id });
};

export const {
  setDeals,
  setDeal,
  setAgents,
  saveDealStart,
  updateVanityURLsStart,
  getInfoStart,
  getInfoEnd,
  clearAllData,
  setInfluencer,
  setContact,
  setProspect,
  saveContactStart,
  updateDealStatus,
  updateRowParams,
  loadAgreements,
  setAgreements,
  setVanityURLs,
  loadVanityUrls,
  setBillComVendors,
  setEditInfluencerPayment,
  setEditInfluencerStatus,
  setEditInfluencerCompanyStatus,
  getPaymentStart,
  getPaymentEnd,
  setPayments,
} = influencerProfileReducer.actions;

export const getAgreements = id => async (dispatch) => {
  try {
    dispatch(loadAgreements());
    const response = await getAgreementsApi(id);
    if (response.data && response.data.agreements) {
      dispatch(setAgreements({ agreements: response.data.agreements }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
    dispatch(getInfoEnd());
  } catch (err) {
    dispatch(getInfoEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getPayments = id => async (dispatch) => {
  try {
    dispatch(getPaymentStart());
    const response = await getPaymentsApi(id);
    if (response?.data?.payments) {
      dispatch(setPayments({ payments: response.data.payments, billComIssue: response.data.billComIssue, stripeIssue: response.data.stripeIssue }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
    dispatch(getPaymentEnd());
  } catch (err) {
    dispatch(getPaymentEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getVanityURLs = id => async (dispatch) => {
  try {
    dispatch(loadVanityUrls());
    const response = await getVanityURLsApi(id);
    if (response.data && response.data.vanityURLs) {
      dispatch(setVanityURLs({ vanityURLs: response.data.vanityURLs }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
    dispatch(getInfoEnd());
  } catch (err) {
    dispatch(getInfoEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getInfluencer = id => async (dispatch) => {
  try {
    if (!id) {
      dispatch(clearAllData());
    } else {
      dispatch(getInfoStart());
      const response = await getInfluencerApi(id);
      if (response.data && response.data.influencer) {
        dispatch(setInfluencer({ influencer: response.data.influencer }));
        dispatch(setContact({ contact: response.data.contact }));
        dispatch(setProspect({ prospect: response.data.prospect }));
        dispatch(setDeals({ deals: response.data.deals }));
        dispatch(setAgents({ agents: response.data.agents }));
        dispatch(setVanityURLs({ vanityURLs: response.data.vanityURLs }));
      } else {
        throw new Error('There was an error fetching records, Check out console to see more information.');
      }
    }
    dispatch(getInfoEnd());
  } catch (err) {
    dispatch(getInfoEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};
export const updateDeal = (id, params) => async (dispatch) => {
  try {
    // dispatch(saveDealStart());
    const responseDeal = await saveDealApi(id, { values: params });
    if (!responseDeal || !responseDeal.data || !responseDeal.data.success) {
      throw new Error('Unable to save contact');
    }
    dispatch(updateDealStatus({ ...params, id }));
    // dispatch(getInfoEnd());
  } catch (err) {
    // dispatch(getInfoEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};
export const fixDeal = id => async (dispatch) => {
  try {
    dispatch(saveDealStart());
    const responseDeal = await fixDealApi(id);
    if (!responseDeal || !responseDeal.data || !responseDeal.data.success) {
      throw new Error('Unable to fix Deal');
    }
    dispatch(setDeals({ deals: responseDeal.data.deals }));
    dispatch(getInfoEnd());
  } catch (err) {
    dispatch(getInfoEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const createVanityURL = (id, data) => async (dispatch) => {
  try {
    dispatch(updateVanityURLsStart());
    const response = await createVanityURLApi(data);
    if (response.data && response.data.record && Object.keys(response.data.record.errors).length === 0) {
      const responseVanityURLs = await getVanityURLsApi(id);
      if (responseVanityURLs.data && responseVanityURLs.data.vanityURLs) {
        dispatch(setVanityURLs({ vanityURLs: responseVanityURLs.data.vanityURLs }));
        dispatch(getInfoEnd());
        dispatch(addNotice({
          message: 'Successfully created a new Vanity URL',
          type: 'success',
        }));
      } else {
        throw new Error('There was an error fetching records, Check out console to see more information.');
      }
    } else {
      const message = Object.keys(response.data.record.errors).length > 0 ? response.data.record.errors[Object.keys(response.data.record.errors)[0]].message : 'Unable to create a Vanity URL';
      throw new Error(message);
    }
  } catch (err) {
    dispatch(getInfoEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const editVanityURL = (id, recordId, data) => async (dispatch) => {
  try {
    dispatch(updateVanityURLsStart());
    const response = await editVanityURLApi(recordId, data);
    if (response.data && response.data.record && Object.keys(response.data.record.errors).length === 0) {
      const responseVanityURLs = await getVanityURLsApi(id);
      if (responseVanityURLs.data && responseVanityURLs.data.vanityURLs) {
        dispatch(setVanityURLs({ vanityURLs: responseVanityURLs.data.vanityURLs }));
        dispatch(getInfoEnd());
        dispatch(addNotice({
          message: 'Successfully updated the Vanity URL',
          type: 'success',
        }));
      } else {
        throw new Error('There was an error fetching records, Check out console to see more information.');
      }
    } else {
      const message = Object.keys(response.data.record.errors).length > 0 ? response.data.record.errors[Object.keys(response.data.record.errors)[0]].message : 'Unable to edit the Vanity URL';
      throw new Error(message);
    }
  } catch (err) {
    dispatch(getInfoEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const setInfluencerContact = hsContactVid => async (dispatch, getState) => {
  const { influencer } = getState();
  try {
    if (!influencer.influencer) {
      throw new Error('Invalid Influencer Selected');
    } else {
      dispatch(saveContactStart());
      const responseContact = await saveInfluencerContactApi(influencer.influencer.id, hsContactVid);
      if (!responseContact || !responseContact.data || !responseContact.data.record || Object.keys(responseContact.data.record.errors).length !== 0) {
        throw new Error('Unable to save contact');
      }
      const response = await getInfluencerApi(influencer.influencer.id);
      if (response.data && response.data.influencer) {
        dispatch(setInfluencer({ influencer: response.data.influencer }));
        dispatch(setContact({ contact: response.data.contact }));
        dispatch(setProspect({ prospect: response.data.prospect }));
        dispatch(setDeals({ deals: response.data.deals }));
      } else {
        throw new Error('There was an error fetching records, Check out console to see more information.');
      }
      dispatch(getInfoEnd());
    }
  } catch (err) {
    dispatch(getInfoEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const setRefreshHasoffers = () => async (dispatch, getState) => {
  const { influencer } = getState();
  try {
    if (!influencer.influencer) {
      throw new Error('Invalid Influencer Selected');
    } else {
      dispatch(getInfoStart());
      await refreshHasoffersApi(influencer.influencer.id);
      const response = await getInfluencerApi(influencer.influencer.id);
      if (response.data && response.data.influencer) {
        dispatch(setInfluencer({ influencer: response.data.influencer }));
        dispatch(setContact({ contact: response.data.contact }));
        dispatch(setProspect({ prospect: response.data.prospect }));
        dispatch(setDeals({ deals: response.data.deals }));
      } else {
        throw new Error('There was an error fetching records, Check out console to see more information.');
      }
      dispatch(getInfoEnd());
    }
  } catch (err) {
    dispatch(getInfoEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const setRefreshHubspot = () => async (dispatch, getState) => {
  const { influencer } = getState();
  try {
    if (!influencer.influencer) {
      throw new Error('Invalid Influencer Selected');
    } else {
      dispatch(getInfoStart());
      await refreshHubspotApi(influencer.influencer.id);
      dispatch(getInfoEnd());
    }
  } catch (err) {
    dispatch(getInfoEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const setRefreshBillCom = () => async (dispatch, getState) => {
  const { influencer } = getState();
  try {
    if (!influencer.influencer) {
      throw new Error('Invalid Influencer Selected');
    } else {
      dispatch(getInfoStart());
      const response = await refreshBillComApi(influencer?.influencer?.params?.email);
      if (response.data && response.data.accounts) {
        console.log(response.data.accounts);
        dispatch(setBillComVendors({ vendors: response.data.accounts }));
      } else {
        throw new Error('There was an error fetching Bill.com vendors, Check out console to see more information.');
      }

      dispatch(getInfoEnd());
    }
  } catch (err) {
    dispatch(getInfoEnd());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const editInfluencerPayment = (id, value) => async (dispatch) => {
  try {
    dispatch(setEditInfluencerPayment(true));
    const api = new ApiClient();
    const response = await api.client.post('/api/portal/influencer/post-save-payment-value', { id, value });
    dispatch(setInfluencer({ influencer: response.data.influencer }));
    dispatch(getInfoEnd());
  } catch (e) {
    console.log(e);
  }
};

export const editInfluencerStatus = (id, value) => async (dispatch) => {
  try {
    dispatch(setEditInfluencerStatus(true));
    const api = new ApiClient();
    const response = await api.client.post('/api/portal/influencer/post-save-status-value', { id, value });
    dispatch(setInfluencer({ influencer: response.data.influencer }));
    dispatch(getInfoEnd());
  } catch (e) {
    console.log(e);
  }
};

export const editInfluencerCompanyStatus = (id, value) => async (dispatch) => {
  try {
    dispatch(setEditInfluencerCompanyStatus(true));
    const api = new ApiClient();
    const response = await api.client.post('/api/portal/influencer/post-save-company-status-value', { id, value });
    dispatch(setInfluencer({ influencer: response.data.influencer }));
    dispatch(getInfoEnd());
  } catch (e) {
    console.log(e);
  }
};


export default influencerProfileReducer.reducer;
