import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const Overlay = ({addSpinner, spinnerSize}) => (
  <div className="overlay">
    {addSpinner && (<div className="text-center"><Spinner color="primary" size={spinnerSize}/></div>)}
  </div>
);

Overlay.propTypes = {
  addSpinner: PropTypes.bool,
  spinnerSize: PropTypes.string,
};

Overlay.defaultProps = {
  addSpinner: true,
  spinnerSize: 'lg',
};

export default Overlay;
