/* eslint-disable filenames/match-exported */
import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from 'mdi-react/InformationIcon';
import CurrencyField from '../../../../../../shared/components/form/CurrencyField';
import CalculationResults from '../CalculationResults';
import CalculationResultsAgent from '../CalculationResultsAgent';


const IlPayoutAmount = ({
  record,
  updateRecordFunction,
  type,
}) => {
  const a = record.params.ilPayoutAmount;
  const [isOpenHelper, setOpenHelper] = useState(false);
  const [loading, setLoading] = useState(false);
  const [calcData, setCalcData] = useState({});
  const [amount, setAmount] = useState(a);

  useEffect(() => {
    setAmount(a);
  }, [a]);

  const handleToogle = () => {
    if (!isOpenHelper) {
      setOpenHelper(true);
      setCalcData(null);
    }
    if (type === 'creator') {
      axios.get(`/api/portal/finance/get-calculation-data?payableId=${record.id}`).then((res) => {
        setOpenHelper(true);
        setCalcData(res.data.calcData);
        // this.setState({ isOpenHelper: true, calcData: res.data.calcData });
      });
    } else {
      axios.get(`/api/portal/finance/get-agent-calculation-data?payableId=${record.id}`).then((res) => {
        setOpenHelper(true);
        setCalcData(res.data.calcData);
        // this.setState({ isOpenHelper: true, calcData: res.data.calcData });
      });
    }
  };

  const handleChange = (event, value) => {
    // console.log(event);
    // this.setState({ loading: true });
    // const { record, updateRecordFunction, type } = this.props;
    setAmount(value);
    setLoading(true);
    // const { value } = event.target;
    const { id } = record;
    const data = new FormData();
    data.append('ilPayoutAmount', value);
    const api = new ApiClient();
    api.recordAction({
      resourceId: type === 'agent' ? 'PayableAgent' : 'Payable',
      recordId: id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      setLoading(false);
      updateRecordFunction(id, { ilPayoutAmount: value });
    });
    return true;
  };


  return (
    <Row style={{ minWidth: '180px' }}>
      <Col xs="auto" className="pr-3" style={{ maxWidth: '180px' }}>
        <CurrencyField placeholder="Payout Amount" disabled={loading || record.checkboxDisable} defaultValue={amount} onBlur={handleChange} />
      </Col>
      <Col className="p-0">
        {record.populated?.deal ? (<IconButton onClick={handleToogle} size="small" className="mt-2 ml-0"><InfoIcon size="16" className="" id={`Popover${record.id}`} /></IconButton>) : null}
        {record.populated?.deal && type === 'creator' ? (
          <CalculationResults
            modal={isOpenHelper}
            closeDialog={() => setOpenHelper(false)}
            title={`${record.populated.deal?.params?.dealname} calculation`}
            record={record}
            calcData={calcData}
          />
        ) : null}
        {record.populated?.deal && type === 'agent' ? (
          <CalculationResultsAgent
            modal={isOpenHelper}
            closeDialog={() => setOpenHelper(false)}
            title={`${record.populated.deal?.params?.dealname} calculation`}
            record={record}
            calcData={calcData}
          />
        ) : null}
      </Col>
    </Row>
  );
};

IlPayoutAmount.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};


export default IlPayoutAmount;
/* class PayoutAmount extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    updateRecordFunction: PropTypes.func.isRequired,
    type: PropTypes.string,
  }
  static defaultProps = {
    type: 'creator',
  }
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleToogle = this.handleToogle.bind(this);
    this.api = new ApiClient();
    const { record } = this.props;
    const amount = record.params.ilPayoutAmount;
    this.state = {
      amount,
      loading: false,
      isOpenHelper: false,
      calcData: {},
    };
  }
  handleToogle() {
    const { record, type } = this.props;
    if (!this.state.isOpenHelper) {
      this.setState({ isOpenHelper: true, calcData: null });
    }
    if (type === 'creator') {
      axios.get(`/api/portal/finance/get-calculation-data?payableId=${record.id}`).then((res) => {
        this.setState({ isOpenHelper: true, calcData: res.data.calcData });
      });
    } else {
      axios.get(`/api/portal/finance/get-agent-calculation-data?payableId=${record.id}`).then((res) => {
        this.setState({ isOpenHelper: true, calcData: res.data.calcData });
      });
    }
  }

  handleChange(event, value) {
    // console.log(event);
    this.setState({ loading: true });
    const { record, updateRecordFunction, type } = this.props;

    // const { value } = event.target;
    const { id } = record;
    const data = new FormData();
    data.append('ilPayoutAmount', value);
    this.api.recordAction({
      resourceId: type === 'agent' ? 'PayableAgent' : 'Payable',
      recordId: id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      this.api.recordAction({
        resourceId: type === 'agent' ? 'PayableAgent' : 'Payable',
        recordId: id,
        actionName: 'show',
      }).then(() => {
        this.setState({ loading: false });
        updateRecordFunction(id, { ilPayoutAmount: value });
        // updateRecordFunction(e.data.record, 'ilPayoutAmount');
      });
    });
    return true;
  }

  render() {
    const {
      loading,
      amount,
      isOpenHelper,
      calcData,
    } = this.state;
    const { record, type } = this.props;
    return (
      <Row style={{ minWidth: '180px' }}>
        <Col xs="auto" className="pr-3" style={{ maxWidth: '180px' }}>
          <CurrencyField placeholder="Payout Amount" disabled={loading || record.checkboxDisable} defaultValue={amount} onBlur={this.handleChange} />
        </Col>
        <Col className="p-0">
          {record.deal ? (<IconButton onClick={this.handleToogle} size="small" className="mt-2 ml-0"><InfoIcon size="16" className="" id={`Popover${record.id}`} /></IconButton>) : null}
          {record.deal && type === 'creator' ? (
            <CalculationResults
              modal={isOpenHelper}
              closeDialog={() => this.setState({ isOpenHelper: false })}
              title={`${record.deal.dealname} calculation`}
              record={record}
              calcData={calcData}
            />
          ) : null}
          {record.deal && type === 'agent' ? (
            <CalculationResultsAgent
              modal={isOpenHelper}
              closeDialog={() => this.setState({ isOpenHelper: false })}
              title={`${record.deal.dealname} calculation`}
              record={record}
              calcData={calcData}
            />
          ) : null}
        </Col>
      </Row>
    );
  }
} */
