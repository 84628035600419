import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { axios } from 'ApiClient';
import Actions from './custom/Actions';
import { useAsyncDebounce } from 'react-table';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../App/store/with-notice';
import { parseUrl } from '../../../../../shared/helpers/WVUtilities';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import UserPropertyType from './custom';
import FillBrandModal from './custom/FillBrandModal';
import { fetchDeals, syncOffers } from '../../../../../redux/reducers/admin/HubilReducer';
import DealStageInList from './custom/DealStageInList';
import ContextMenuInList from './custom/ContextMenuInList';
// import WVFormatter from '../../../../../shared/helpers/WVFormatter';

const compareStatus = {
  Active: 'Active',
  Pending: 'Pending',
  Expired: 'Expired',
  Paid: 'Paid',
};

const getStatusInitial = () => {
  const filters = parseUrl();
  const initialStatusFilters = {
    Pending: true,
    Active: true,
    Expired: true,
    Paid: true,
  };
  if (filters?.status) {
    Object.keys(initialStatusFilters).forEach((key) => {
      const rightKey = Object.keys(compareStatus).find(keyInState => keyInState === key);
      initialStatusFilters[key] = filters.status.includes(compareStatus[rightKey]);
    });
  }
  return initialStatusFilters;
};

const compareYesNo = {
  Yes: 'Yes',
  No: 'No',
};

const getPromoCodeInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    Yes: true,
    No: true,
  };
  if (filters?.promoCode) {
    Object.keys(initialFilters).forEach((key) => {
      const rightKey = Object.keys(compareYesNo).find(keyInState => keyInState === key);
      initialFilters[key] = filters.promoCode.includes(compareYesNo[rightKey]);
    });
  }
  return initialFilters;
};

const compareDealTypes = {
  Hybrid: 'Hybrid',
  'CPA/Ambassador': 'CPA/Ambassador',
  'Upfront Only': 'Upfront Only',
  'Upfront CPM': 'Upfront CPM',
  'Agent Commission': 'Agent Commission',
  'Media License': 'Media License',
  Bonus: 'Bonus',
};

const getDealTypesInitial = () => {
  const filters = parseUrl();
  const initialTypesFilters = {
    Hybrid: true,
    'CPA/Ambassador': true,
    'Upfront Only': true,
    'Upfront CPM': true,
    'Agent Commission': true,
    'Media License': true,
    Bonus: true,
  };
  if (filters?.dealTypes) {
    Object.keys(initialTypesFilters).forEach((key) => {
      const rightKey = Object.keys(compareDealTypes).find(keyInState => keyInState === key);
      initialTypesFilters[key] = filters.dealTypes.includes(compareDealTypes[rightKey]);
    });
  }
  return initialTypesFilters;
};

const getFilterValueInitial = (filterType) => {
  let initialFilterType = '<=';
  const type = new URLSearchParams(window.location.search).get(filterType);
  if (type) {
    initialFilterType = type;
  }
  return initialFilterType;
};

const getSingleFirstConversionDateInitial = () => {
  let singleDate = null;
  const filters = parseUrl();
  const type = new URLSearchParams(window.location.search).get('firstConversionDateType');
  if (type && type !== 'Between') {
    if (type === '>' || type === '>=' || type === '=') {
      if (filters?.['date_of_first_conversion.from']) {
        singleDate = new Date(filters['date_of_first_conversion.from']);
      }
    }
    if (type === '<' || type === '<=') {
      singleDate = new Date(filters['date_of_first_conversion.to']);
    }
  }
  return singleDate;
};

const getSingleLastConversionDateInitial = () => {
  let singleDate = null;
  const filters = parseUrl();
  const type = new URLSearchParams(window.location.search).get('lastConversionDateType');
  if (type && type !== 'Between') {
    if (type === '>' || type === '>=' || type === '=') {
      if (filters?.['date_of_last_conversion.from']) {
        singleDate = new Date(filters['date_of_last_conversion.from']);
      }
    }
    if (type === '<' || type === '<=') {
      singleDate = new Date(filters['date_of_last_conversion.to']);
    }
  }
  return singleDate;
};

const getSingleBrandApprovedDateInitial = () => {
  let singleDate = null;
  const filters = parseUrl();
  const type = new URLSearchParams(window.location.search).get('brandApprovedDateType');
  if (type && type !== 'Between') {
    if (type === '>' || type === '>=' || type === '=') {
      if (filters?.['brandApprovedDate.from']) {
        singleDate = new Date(filters['brandApprovedDate.from']);
      }
    }
    if (type === '<' || type === '<=') {
      singleDate = new Date(filters['brandApprovedDate.to']);
    }
  }
  return singleDate;
};

const getSingleContentReleaseDateInitial = () => {
  let singleDate = null;
  const filters = parseUrl();
  const type = new URLSearchParams(window.location.search).get('contentReleaseDateType');
  if (type && type !== 'Between') {
    if (type === '>' || type === '>=' || type === '=') {
      if (filters?.['content_release_date.from']) {
        singleDate = new Date(filters['content_release_date.from']);
      }
    }
    if (type === '<' || type === '<=') {
      singleDate = new Date(filters['content_release_date.to']);
    }
  }
  return singleDate;
};

const HubilDealsListContainer = ({
  addNotice,
  dispatch,
  history,
  action,
  resource,
}) => {
  const [loadingDeals, setLoadingDeals] = useState(false);
  const [loadingOffers, setLoadingOffers] = useState(false);
  const [fillBrandsModal, setFillBrandsModal] = useState(false);
  const [ownersSuggestions, setOwnersSuggestions] = useState([]);
  const [brandsSuggestions, setBrandsSuggestions] = useState([]);
  const [loadingDealStages, setLoadingDealStages] = useState(false);
  const [dealStagesSuggestions, setDealStagesSuggestions] = useState([]);
  const [urlIsUpdating, setUrlIsUpdating] = useState(false);
  const getTagsInitial = (tagType, returnObject) => {
    const determineName = (tag) => {
      let result = tag;
      if (tagType === 'dealOwners' && ownersSuggestions.length > 0) {
        ownersSuggestions.forEach((ownerSuggestion) => {
          if (tag === ownerSuggestion.id) {
            result = ownerSuggestion.name;
          }
        });
      }
      if (tagType === 'brands' && brandsSuggestions.length > 0) {
        brandsSuggestions.forEach((brandsSuggestion) => {
          if (tag === brandsSuggestion.id) {
            result = brandsSuggestion.name;
          }
        });
      }
      if (tagType === 'dealStages' && dealStagesSuggestions.length > 0) {
        dealStagesSuggestions.forEach((dealStagesSuggestion) => {
          if (tag === dealStagesSuggestion.id) {
            result = dealStagesSuggestion.name;
          }
        });
      }
      return result;
    };
    const determineObject = (tag) => {
      let result = { name: determineName(tag) };
      if (tagType === 'dealOwners' && ownersSuggestions.length > 0) {
        ownersSuggestions.forEach((ownerSuggestion) => {
          if (tag === ownerSuggestion.id) {
            result = { name: ownerSuggestion.name, id: ownerSuggestion.id };
          }
        });
      }
      if (tagType === 'brands' && brandsSuggestions.length > 0) {
        brandsSuggestions.forEach((brandsSuggestion) => {
          if (tag === brandsSuggestion.id) {
            result = { name: brandsSuggestion.name, id: brandsSuggestion.id };
          }
        });
      }
      if (tagType === 'dealStages' && dealStagesSuggestions.length > 0) {
        dealStagesSuggestions.forEach((dealStagesSuggestion) => {
          if (tag === dealStagesSuggestion.id) {
            result = { name: dealStagesSuggestion.name, id: dealStagesSuggestion.id };
          }
        });
      }
      return result;
    };
    const filters = parseUrl();
    const initialTagFilters = returnObject ? {} : [];
    let filtersProspectTags = [];
    if (filters[tagType] && Array.isArray(filters[tagType])) {
      filtersProspectTags = filters[tagType];
    } else if (filters[tagType]) {
      filtersProspectTags = [filters[tagType]];
    }
    filtersProspectTags.forEach((tag) => {
      if (returnObject) {
        initialTagFilters[determineName(tag)] = true;
      } else {
        initialTagFilters.push(determineObject(tag));
      }
    });
    return initialTagFilters;
  };


  const filters = parseUrl();
  const firstConversionDateType = getFilterValueInitial('firstConversionDateType');
  const [firstConversionDateFrom, setFirstConversionDateFrom] = useState(filters?.['date_of_first_conversion.from'] && firstConversionDateType === 'Between' ? new Date(filters['date_of_first_conversion.from']) : null);
  const [firstConversionDateTo, setFirstConversionDateTo] = useState(filters?.['date_of_first_conversion.to'] && firstConversionDateType === 'Between' ? new Date(filters['date_of_first_conversion.to']) : null);
  const [singleFirstConversionDate, setSingleFirstConversionDate] = useState(getSingleFirstConversionDateInitial());
  const [enableFirstConversionDate, setEnableFirstConversionDate] = useState(false);
  const [firstConversionDateValue, setFirstConversionDateValue] = useState({ value: firstConversionDateType, label: firstConversionDateType });
  const lastConversionDateType = getFilterValueInitial('lastConversionDateType');
  const [lastConversionDateFrom, setLastConversionDateFrom] = useState(filters?.['date_of_last_conversion.from'] && lastConversionDateType === 'Between' ? new Date(filters['date_of_last_conversion.from']) : null);
  const [lastConversionDateTo, setLastConversionDateTo] = useState(filters?.['date_of_last_conversion.to'] && lastConversionDateType === 'Between' ? new Date(filters['date_of_last_conversion.to']) : null);
  const [singleLastConversionDate, setSingleLastConversionDate] = useState(getSingleLastConversionDateInitial());
  const [enableLastConversionDate, setEnableLastConversionDate] = useState(false);
  const [lastConversionDateValue, setLastConversionDateValue] = useState({ value: lastConversionDateType, label: lastConversionDateType });
  const brandApprovedDateType = getFilterValueInitial('brandApprovedDateType');
  const [brandApprovedDateFrom, setBrandApprovedDateFrom] = useState(filters?.['brandApprovedDate.from'] && brandApprovedDateType === 'Between' ? new Date(filters['brandApprovedDate.from']) : null);
  const [brandApprovedDateTo, setBrandApprovedDateTo] = useState(filters?.['brandApprovedDate.to'] && brandApprovedDateType === 'Between' ? new Date(filters['brandApprovedDate.to']) : null);
  const [singleBrandApprovedDate, setSingleBrandApprovedDate] = useState(getSingleBrandApprovedDateInitial());
  const [enableBrandApprovedDate, setEnableBrandApprovedDate] = useState(false);
  const [brandApprovedDateValue, setBrandApprovedDateValue] = useState({ value: brandApprovedDateType, label: brandApprovedDateType });
  const contentReleaseDateType = getFilterValueInitial('contentReleaseDateType');
  const [contentReleaseDateFrom, setContentReleaseDateFrom] = useState(filters?.['content_release_date.from'] && contentReleaseDateType === 'Between' ? new Date(filters['content_release_date.from']) : null);
  const [contentReleaseDateTo, setContentReleaseDateTo] = useState(filters?.['content_release_date.to'] && contentReleaseDateType === 'Between' ? new Date(filters['content_release_date.to']) : null);
  const [singleContentReleaseDate, setSingleContentReleaseDate] = useState(getSingleContentReleaseDateInitial());
  const [enableContentReleaseDate, setEnableContentReleaseDate] = useState(false);
  const [contentReleaseDateValue, setContentReleaseDateValue] = useState({ value: contentReleaseDateType, label: contentReleaseDateType });
  const [version, setVersion] = useState((new Date()).toString());
  const [ownersFilter, setOwnersFilter] = useState(getTagsInitial('dealOwners', true));
  const [owners, setOwners] = useState(getTagsInitial('dealOwners', false));
  const [brandsFilter, setBrandsFilter] = useState(getTagsInitial('brands', true));
  const [brands, setBrands] = useState(getTagsInitial('brands', false));
  const [dealStagesFilter, setDealStagesFilter] = useState(getTagsInitial('dealStages', true));
  const [dealStages, setDealStages] = useState(getTagsInitial('dealStages', false));
  const [statusFilters, setStatusFilters] = useState(getStatusInitial());
  const [promoCodeFilters, setPromoCodeFilters] = useState(getPromoCodeInitial());
  const [dealTypesFilters, setDealTypesFilters] = useState(getDealTypesInitial());
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }
    if (statusFilters && Object.keys(statusFilters).length > 0) {
      search.delete('filters.status');
      Object.keys(statusFilters).forEach((key) => {
        if (!statusFilters[key]) return false;
        search.append('filters.status', compareStatus[key]);
        return true;
      });
    }
    if (promoCodeFilters && Object.keys(promoCodeFilters).length > 0) {
      search.delete('filters.promoCode');
      let filterAdded = false;
      Object.keys(promoCodeFilters).forEach((key) => {
        if (!promoCodeFilters[key]) return false;
        search.append('filters.promoCode', compareYesNo[key]);
        filterAdded = true;
        return true;
      });
      if (!filterAdded) {
        search.append('filters.promoCode', 'false');
      }
    }
    if (dealTypesFilters && Object.keys(dealTypesFilters).length > 0) {
      search.delete('filters.dealTypes');
      Object.keys(dealTypesFilters).forEach((key) => {
        if (!dealTypesFilters[key]) return false;
        search.append('filters.dealTypes', compareDealTypes[key]);
        return true;
      });
    }
    search.delete('filters.dealOwners');
    if (ownersFilter && Object.keys(ownersFilter).length > 0) {
      Object.keys(ownersFilter).forEach((key) => {
        if (!ownersFilter[key]) return false;
        search.append('filters.dealOwners', key);
        return true;
      });
    }
    search.delete('filters.brands');
    if (brandsFilter && Object.keys(brandsFilter).length > 0) {
      Object.keys(brandsFilter).forEach((key) => {
        if (!brandsFilter[key]) return false;
        search.append('filters.brands', key);
        return true;
      });
    }
    search.delete('filters.dealStages');
    if (dealStagesFilter && Object.keys(dealStagesFilter).length > 0) {
      Object.keys(dealStagesFilter).forEach((key) => {
        if (!dealStagesFilter[key]) return false;
        search.append('filters.dealStages', key);
        return true;
      });
    }

    search.delete('filters.date_of_first_conversion.from');
    search.delete('filters.date_of_first_conversion.to');
    search.delete('firstConversionDateType');
    if (firstConversionDateFrom && firstConversionDateTo && firstConversionDateValue.value === 'Between') {
      if (firstConversionDateFrom < firstConversionDateTo) {
        search.append('filters.date_of_first_conversion.from', firstConversionDateFrom.toISOString());
        search.append('filters.date_of_first_conversion.to', firstConversionDateTo.toISOString());
      } else { // switch positions of interval ends
        search.append('filters.date_of_first_conversion.from', firstConversionDateTo.toISOString());
        search.append('filters.date_of_first_conversion.to', firstConversionDateFrom.toISOString());
      }
    }
    if (singleFirstConversionDate && singleFirstConversionDate !== '') {
      if (firstConversionDateValue.value === '>' || firstConversionDateValue.value === '>=') {
        search.append('filters.date_of_first_conversion.from', singleFirstConversionDate.toISOString());
      }
      if (firstConversionDateValue.value === '<' || firstConversionDateValue.value === '<=') {
        search.append('filters.date_of_first_conversion.to', singleFirstConversionDate.toISOString());
      }
      if (firstConversionDateValue.value === '=') {
        search.append('filters.date_of_first_conversion.from', singleFirstConversionDate.toISOString());
        const nextDay = new Date(singleFirstConversionDate);
        nextDay.setDate(singleFirstConversionDate.getDate() + 1);
        search.append('filters.date_of_first_conversion.to', nextDay.toISOString());
      }
    }
    if (firstConversionDateValue.value && ((firstConversionDateTo && firstConversionDateFrom) || singleFirstConversionDate)) {
      setEnableFirstConversionDate(true);
      search.append('firstConversionDateType', firstConversionDateValue.value);
    }

    search.delete('filters.date_of_last_conversion.from');
    search.delete('filters.date_of_last_conversion.to');
    search.delete('lastConversionDateType');
    if (lastConversionDateFrom && lastConversionDateTo && lastConversionDateValue.value === 'Between') {
      if (lastConversionDateFrom < lastConversionDateTo) {
        search.append('filters.date_of_last_conversion.from', lastConversionDateFrom.toISOString());
        search.append('filters.date_of_last_conversion.to', lastConversionDateTo.toISOString());
      } else { // switch positions of interval ends
        search.append('filters.date_of_last_conversion.from', lastConversionDateTo.toISOString());
        search.append('filters.date_of_last_conversion.to', lastConversionDateFrom.toISOString());
      }
    }
    if (singleLastConversionDate && singleLastConversionDate !== '') {
      if (lastConversionDateValue.value === '>' || lastConversionDateValue.value === '>=') {
        search.append('filters.date_of_last_conversion.from', singleLastConversionDate.toISOString());
      }
      if (lastConversionDateValue.value === '<' || lastConversionDateValue.value === '<=') {
        search.append('filters.date_of_last_conversion.to', singleLastConversionDate.toISOString());
      }
      if (lastConversionDateValue.value === '=') {
        search.append('filters.date_of_last_conversion.from', singleLastConversionDate.toISOString());
        const nextDay = new Date(singleLastConversionDate);
        nextDay.setDate(singleLastConversionDate.getDate() + 1);
        search.append('filters.date_of_last_conversion.to', nextDay.toISOString());
      }
    }
    if (lastConversionDateValue.value && ((lastConversionDateTo && lastConversionDateFrom) || singleLastConversionDate)) {
      setEnableLastConversionDate(true);
      search.append('lastConversionDateType', lastConversionDateValue.value);
    }

    search.delete('filters.brandApprovedDate.from');
    search.delete('filters.brandApprovedDate.to');
    search.delete('brandApprovedDateType');
    if (brandApprovedDateFrom && brandApprovedDateTo && brandApprovedDateValue.value === 'Between') {
      if (brandApprovedDateFrom < brandApprovedDateTo) {
        search.append('filters.brandApprovedDate.from', brandApprovedDateFrom.toISOString());
        search.append('filters.brandApprovedDate.to', brandApprovedDateTo.toISOString());
      } else { // switch positions of interval ends
        search.append('filters.brandApprovedDate.from', brandApprovedDateTo.toISOString());
        search.append('filters.brandApprovedDate.to', brandApprovedDateFrom.toISOString());
      }
    }
    if (singleBrandApprovedDate && singleBrandApprovedDate !== '') {
      if (brandApprovedDateValue.value === '>' || brandApprovedDateValue.value === '>=') {
        search.append('filters.brandApprovedDate.from', singleBrandApprovedDate.toISOString());
      }
      if (brandApprovedDateValue.value === '<' || brandApprovedDateValue.value === '<=') {
        search.append('filters.brandApprovedDate.to', singleBrandApprovedDate.toISOString());
      }
      if (brandApprovedDateValue.value === '=') {
        search.append('filters.brandApprovedDate.from', singleBrandApprovedDate.toISOString());
        const nextDay = new Date(singleBrandApprovedDate);
        nextDay.setDate(singleBrandApprovedDate.getDate() + 1);
        search.append('filters.brandApprovedDate.to', nextDay.toISOString());
      }
    }
    if (brandApprovedDateValue.value && ((brandApprovedDateTo && brandApprovedDateFrom) || singleBrandApprovedDate)) {
      setEnableBrandApprovedDate(true);
      search.append('brandApprovedDateType', brandApprovedDateValue.value);
    }

    search.delete('filters.content_release_date.from');
    search.delete('filters.content_release_date.to');
    search.delete('contentReleaseDateType');
    if (contentReleaseDateFrom && contentReleaseDateTo && contentReleaseDateValue.value === 'Between') {
      if (contentReleaseDateFrom < contentReleaseDateTo) {
        search.append('filters.content_release_date.from', contentReleaseDateFrom.toISOString());
        search.append('filters.content_release_date.to', contentReleaseDateTo.toISOString());
      } else { // switch positions of interval ends
        search.append('filters.content_release_date.from', contentReleaseDateTo.toISOString());
        search.append('filters.content_release_date.to', contentReleaseDateFrom.toISOString());
      }
    }
    if (singleContentReleaseDate && singleContentReleaseDate !== '') {
      if (contentReleaseDateValue.value === '>' || contentReleaseDateValue.value === '>=') {
        search.append('filters.content_release_date.from', singleContentReleaseDate.toISOString());
      }
      if (contentReleaseDateValue.value === '<' || contentReleaseDateValue.value === '<=') {
        search.append('filters.content_release_date.to', singleContentReleaseDate.toISOString());
      }
      if (contentReleaseDateValue.value === '=') {
        search.append('filters.content_release_date.from', singleContentReleaseDate.toISOString());
        const nextDay = new Date(singleContentReleaseDate);
        nextDay.setDate(singleContentReleaseDate.getDate() + 1);
        search.append('filters.content_release_date.to', nextDay.toISOString());
      }
    }
    if (contentReleaseDateValue.value && ((contentReleaseDateTo && contentReleaseDateFrom) || singleContentReleaseDate)) {
      setEnableContentReleaseDate(true);
      search.append('contentReleaseDateType', contentReleaseDateValue.value);
    }

    return search;
  };

  const firstUpdate = useRef(true);


  const setUrl = () => {
    setUrlIsUpdating(true);
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
    }
    history.push(`${history.location.pathname}?${search.toString()}`);
    setTimeout(() => {
      setUrlIsUpdating(false);
    }, 1000);
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 1000);

  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
    ownersFilter,
    brandsFilter,
    dealStagesFilter,
    statusFilters,
    promoCodeFilters,
    dealTypesFilters,
    enableFirstConversionDate,
    singleFirstConversionDate,
    firstConversionDateTo,
    firstConversionDateFrom,
    enableLastConversionDate,
    singleLastConversionDate,
    lastConversionDateTo,
    lastConversionDateFrom,
    enableBrandApprovedDate,
    singleBrandApprovedDate,
    brandApprovedDateTo,
    brandApprovedDateFrom,
    enableContentReleaseDate,
    singleContentReleaseDate,
    contentReleaseDateTo,
    contentReleaseDateFrom,
  ]);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.has('firstConversionDateType') && search.get('firstConversionDateType') !== firstConversionDateValue.value) {
      setUrl();
    }
  }, [firstConversionDateValue]);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.has('lastConversionDateType') && search.get('lastConversionDateType') !== lastConversionDateValue.value) {
      setUrl();
    }
  }, [lastConversionDateValue]);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.has('brandApprovedDateType') && search.get('brandApprovedDateType') !== brandApprovedDateValue.value) {
      setUrl();
    }
  }, [brandApprovedDateValue]);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.has('contentReleaseDateType') && search.get('contentReleaseDateType') !== contentReleaseDateValue.value) {
      setUrl();
    }
  }, [contentReleaseDateValue]);

  useEffect(() => {
    setOwners(getTagsInitial('dealOwners', false));
  }, [
    ownersSuggestions,
  ]);

  useEffect(() => {
    setBrands(getTagsInitial('brands', false));
  }, [
    brandsSuggestions,
  ]);

  useEffect(() => {
    setDealStages(getTagsInitial('dealStages', false));
  }, [
    dealStagesSuggestions,
  ]);

  useEffect(() => {
    // detects if we came back from editing prospect page
    setVersion((new Date()).toString());
  }, [document.location]);

  if (!enableFirstConversionDate && firstConversionDateValue.value && ((firstConversionDateTo && firstConversionDateFrom) || singleFirstConversionDate)) {
    setEnableFirstConversionDate(true);
  }

  if (!enableLastConversionDate && lastConversionDateValue.value && ((lastConversionDateTo && lastConversionDateFrom) || singleLastConversionDate)) {
    setEnableLastConversionDate(true);
  }

  if (!enableBrandApprovedDate && brandApprovedDateValue.value && ((brandApprovedDateTo && brandApprovedDateFrom) || singleBrandApprovedDate)) {
    setEnableBrandApprovedDate(true);
  }

  if (!enableContentReleaseDate && contentReleaseDateValue.value && ((contentReleaseDateTo && contentReleaseDateFrom) || singleContentReleaseDate)) {
    setEnableContentReleaseDate(true);
  }

  const fetchDealStagesSuggestions = () => {
    setLoadingDealStages(true);
    axios({
      method: 'get',
      url: '/api/portal/hubil/get-deal-stages-suggestions',
    }).then((response) => {
      if (response.data.success === true) {
        setDealStagesSuggestions(response.data.suggestions);
        setLoadingDealStages(false);
      }
    }).catch((err) => {
      console.log(err);
      setLoadingDealStages(false);
    });
  };

  const fetchOwnersSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/hubil/get-deal-owners-suggestions',
    }).then((response) => {
      if (response.data.success === true) {
        setOwnersSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchBrandsSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/hubil/get-brands-suggestions',
    }).then((response) => {
      if (response.data.success === true) {
        setBrandsSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    fetchDealStagesSuggestions();
    fetchOwnersSuggestions();
    fetchBrandsSuggestions();
  }, []);

  const fetchAllDeals = () => {
    if (loadingDeals) {
      return;
    }
    setLoadingDeals(true);
    addNotice({
      message: 'Fetching Deals, please wait...',
      type: 'success',
    });
    dispatch(fetchDeals(() => { setLoadingDeals(false); }));
  };

  const afterDealFetch = () => {
    fetchDealStagesSuggestions();
    fetchOwnersSuggestions();
    setVersion((new Date()).toString());
    setLoadingDeals(false);
    addNotice({
      message: 'All Deals were fetched.',
      type: 'success',
    });
  };

  const synchronizeOffers = () => {
    setLoadingOffers(true);
    addNotice({
      message: 'Synchronizing Offers to HubSpot, please wait...',
      type: 'success',
    });
    dispatch(syncOffers(() => {
      addNotice({
        message: 'All Offers were synchronized.',
        type: 'success',
      });
      setLoadingOffers(false);
    }));
  };

  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Creators', path: '/resources/Influencer/actions/list' },
          { title: 'Deals', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row>
              <Col md="4">
                <h3>Deals</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-0" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                urlIsUpdating={urlIsUpdating}
                setFillBrandsModal={setFillBrandsModal}
                fetchAllDeals={fetchAllDeals}
                synchronizeOffers={synchronizeOffers}
                onSearchChange={onSearchChange}
                dealStagesSuggestions={dealStagesSuggestions}
                setDealStagesFilter={setDealStagesFilter}
                setDealStages={setDealStages}
                dealStages={dealStages}
                getUrlSearch={getUrlSearch}
                searchValue={searchValue}
                ownersSuggestions={ownersSuggestions}
                setOwnersFilter={setOwnersFilter}
                setOwners={setOwners}
                owners={owners}
                brandsSuggestions={brandsSuggestions}
                setBrandsFilter={setBrandsFilter}
                setBrands={setBrands}
                brands={brands}
                statusFilters={statusFilters}
                setStatusFilters={setStatusFilters}
                promoCodeFilters={promoCodeFilters}
                setPromoCodeFilters={setPromoCodeFilters}
                dealTypesFilters={dealTypesFilters}
                setDealTypesFilters={setDealTypesFilters}
                firstConversionDateValue={firstConversionDateValue}
                setFirstConversionDateValue={setFirstConversionDateValue}
                enableFirstConversionDate={enableFirstConversionDate}
                setEnableFirstConversionDate={setEnableFirstConversionDate}
                firstConversionDateFrom={firstConversionDateFrom}
                setFirstConversionDateFrom={setFirstConversionDateFrom}
                firstConversionDateTo={firstConversionDateTo}
                setFirstConversionDateTo={setFirstConversionDateTo}
                singleFirstConversionDate={singleFirstConversionDate}
                setSingleFirstConversionDate={setSingleFirstConversionDate}
                lastConversionDateValue={lastConversionDateValue}
                setLastConversionDateValue={setLastConversionDateValue}
                enableLastConversionDate={enableLastConversionDate}
                setEnableLastConversionDate={setEnableLastConversionDate}
                lastConversionDateFrom={lastConversionDateFrom}
                setLastConversionDateFrom={setLastConversionDateFrom}
                lastConversionDateTo={lastConversionDateTo}
                setLastConversionDateTo={setLastConversionDateTo}
                singleLastConversionDate={singleLastConversionDate}
                setSingleLastConversionDate={setSingleLastConversionDate}
                brandApprovedDateValue={brandApprovedDateValue}
                setBrandApprovedDateValue={setBrandApprovedDateValue}
                enableBrandApprovedDate={enableBrandApprovedDate}
                setEnableBrandApprovedDate={setEnableBrandApprovedDate}
                brandApprovedDateFrom={brandApprovedDateFrom}
                setBrandApprovedDateFrom={setBrandApprovedDateFrom}
                brandApprovedDateTo={brandApprovedDateTo}
                setBrandApprovedDateTo={setBrandApprovedDateTo}
                singleBrandApprovedDate={singleBrandApprovedDate}
                setSingleBrandApprovedDate={setSingleBrandApprovedDate}
                contentReleaseDateValue={contentReleaseDateValue}
                setContentReleaseDateValue={setContentReleaseDateValue}
                enableContentReleaseDate={enableContentReleaseDate}
                setEnableContentReleaseDate={setEnableContentReleaseDate}
                contentReleaseDateFrom={contentReleaseDateFrom}
                setContentReleaseDateFrom={setContentReleaseDateFrom}
                contentReleaseDateTo={contentReleaseDateTo}
                setContentReleaseDateTo={setContentReleaseDateTo}
                singleContentReleaseDate={singleContentReleaseDate}
                setSingleContentReleaseDate={setSingleContentReleaseDate}
                loadingDeals={loadingDeals}
                loadingOffers={loadingOffers}
                afterDealFetch={afterDealFetch}
              />
              <div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
                <MatTableList
                  action={action}
                  resource={resource}
                  date={new Date()}
                  UserPropertyType={UserPropertyType}
                  sortBy="createdAt"
                  direction="desc"
                  useFlex
                  isResizable={false}
                  showTitle={false}
                  showFilters={false}
                  noActions
                  columnsSettings={{
                    dealname: {
                      width: 220,
                    },
                    'adminUserObject.fullName': {
                      width: 200,
                    },
                    'influencerObject.fullName': {
                      width: 150,
                    },
                    'dealTerms.cpa_status': {
                      width: 80,
                    },
                    'dealTerms.type_of_deal': {
                      width: 150,
                    },
                    dealstage: {
                      disableSortBy: true,
                      Cell: p => (
                        <DealStageInList
                          record={p.row.original}
                          loadingDealStages={loadingDealStages}
                          dealStages={dealStagesSuggestions}
                        />
                      ),
                      width: 150,
                    },
                    'dealTerms.brand': {
                      width: 150,
                    },
                    'dealTerms.date_of_first_conversion': {
                      width: 190,
                    },
                    'dealTerms.date_of_last_conversion': {
                      width: 190,
                    },
                    brandApprovedDate: {
                      width: 190,
                    },
                    'dealTerms.content_release_date': {
                      width: 190,
                    },
                    contextMenuDeal: {
                      Header: '',
                      accessor: () => null,
                      Cell: p => (
                        <ContextMenuInList
                          record={p.row.original}
                          adminUsers={ownersSuggestions}
                        />
                      ),
                      disableSortBy: true,
                      className: 'align-self-center',
                      width: 30,
                      maxWidth: 30,
                    },
                  }}
                  version={version}
                  hideToolbar
                  v={2}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <FillBrandModal
        isOpen={fillBrandsModal}
        toggleModal={() => setFillBrandsModal(false)}
      />
    </Container>
  );
};

HubilDealsListContainer.propTypes = {
  addNotice: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default connect()(withNotice(withRouter(HubilDealsListContainer)));
