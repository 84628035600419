import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';


const ScorecardsDropDown = ({ record }) => {
  const idToNameMappings = useSelector(state => state.AdminUserNameReducer.adminUserNames);

  return (
    <UncontrolledButtonDropdown className="m-0">
      <DropdownToggle caret className="mr-2" color="primary">
        Scorecards
      </DropdownToggle>
      <DropdownMenu>
        {record.answers.map(scoreCard => (
          <DropdownItem key={scoreCard._id} disabled={false} >
            <a rel="noreferrer" target="_blank" href={`/scorecard/score-content/${record._id}/${scoreCard._id}`} >
              {idToNameMappings[scoreCard.scoredAdminUser]} ({scoreCard.totalScore})
            </a>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

ScorecardsDropDown.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ScorecardsDropDown;
