import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import IntervalDatePickerField from '../../../../Influencer/Research/Prospects/components/custom/IntervalDatePickerField';

const lastActivityDateLabels = [
  { value: '=', label: '=' },
  { value: '>', label: '>' },
  { value: '>=', label: '>=' },
  { value: '<', label: '<' },
  { value: '<=', label: '<=' },
  { value: 'Between', label: 'Between' },
];
const customStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#e9ecef' : 'white',
    height: '44px',
  }),
};
const PublishDate = ({
  setDateFrom, setDateTo, setSingleDate, lastActivityDateValue, setLastActivityDateValue, dateFrom, dateTo, singleDate,
}) => {
  console.log();
  const [enableLastAd, setEnableLastAd] = useState(!!dateFrom);
  const handleChangeLastADCheckBox = ({ target }) => {
    setEnableLastAd(!enableLastAd);
    if (!target.checked) {
      setSingleDate(null);
      setDateTo(null);
      setDateFrom(null);
    }
  };
  return (
    <div className="mt-3">
      <Label className="bold-text" style={{ marginBottom: '8px' }}>
        Publish Date
      </Label>
      <Row>
        <Col sm={1}>
          <FormGroup
            style={{ paddingTop: '13px' }}
          >
            <CheckBoxField
              name="lastActivityDateCheckBox"
              value={enableLastAd}
              onChange={e => e.target && handleChangeLastADCheckBox(e)}
            />
          </FormGroup>
        </Col>
        <Col sm={5} style={{ paddingLeft: '0px' }} >
          <Select
            value={lastActivityDateValue}
            onChange={(option) => {
              if ((lastActivityDateValue !== 'Between' && option.value === 'Between') || (lastActivityDateValue === 'Between' && option.value !== 'Between')) {
                setDateFrom(null);
                setDateTo(null);
                setSingleDate(null);
              }
              setLastActivityDateValue(option);
            }}
            options={lastActivityDateLabels}
            isDisabled={!enableLastAd}
            styles={customStyles}
          />
        </Col>
        <Col sm={6}>
          {lastActivityDateValue.value === 'Between' ? (
            <div style={{ marginRight: '-30px' }} className="pb-2">
              <IntervalDatePickerField
                onChange={({ start, end }) => {
                  setDateFrom(start);
                  end?.setHours(23, 59, 59);
                  setDateTo(end);
                }}
                startDate={dateFrom}
                endDate={dateTo}
                maxDate={new Date()}
                placeholderFrom="Start Date"
                placeholderTo="End Date"
                verticalFields
                disabled={!enableLastAd}
              />
            </div>
          ) : (
            <div className="date-picker datepicker-in-prospects">
              <DatePicker
                selected={singleDate}
                onChange={(date) => {
                  if (lastActivityDateValue.value === '<=' || lastActivityDateValue.value === '>') {
                    date?.setHours(23, 59, 59);
                  }
                  setSingleDate(date);
                }}
                dateFormat="MMM d, yyyy"
                maxDate={new Date()}
                placeholderText="Select Date"
                dropDownMode="select"
                isClearable
                wrapperClassName="date-picker--interval w-100"
                className="form-control form-control-sm datepicker-in-prospects w-100"
                disabled={!enableLastAd}
              />
              {singleDate === null && (
                <svg
                  className="mdi-icon"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  style={{
                    position: 'absolute',
                    top: '13px',
                    right: '22px',
                    fill: '#999',
                    pointerEvents: 'none',
                  }}
                >
                  <path
                    d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                  />
                </svg>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

PublishDate.propTypes = {
  setDateFrom: PropTypes.func.isRequired,
  setDateTo: PropTypes.func.isRequired,
  setSingleDate: PropTypes.func.isRequired,
  lastActivityDateValue: PropTypes.objectOf(PropTypes.any),
  setLastActivityDateValue: PropTypes.func.isRequired,
  dateFrom: PropTypes.objectOf(PropTypes.any),
  dateTo: PropTypes.objectOf(PropTypes.any),
  singleDate: PropTypes.objectOf(PropTypes.any),
};

PublishDate.defaultProps = {
  dateFrom: null,
  dateTo: null,
  singleDate: null,
  lastActivityDateValue: null,
};
export default PublishDate;
