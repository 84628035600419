import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CopyToClipboard from '../../../../../../../shared/components/CopyToClipboard';

const NameInList = ({ record }) => {
  const isAgent = record.params?.userType === 'Agent';
  const recordToUse = isAgent
    ? record?.params?.agentUser
    : record?.params?.influencerObject;

  return (
    <div>
      <a href={`/${isAgent ? 'agent' : 'influencer'}/profile/${recordToUse?._id}`} target="_blank"
         rel="noreferrer">{recordToUse?.first_name} {recordToUse?.last_name}</a>
      {recordToUse.hsContactVid ? (
        <a
          style={{ marginLeft: '5px' }}
          href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${recordToUse.hsContactVid}/`}
          target="_blank" rel="noreferrer">
          <Tooltip title="Open Hubspot Contact">
            <IconButton size="small">
              <i className="fab fa-hubspot text-primary" style={{fontSize: '0.8em'}}/>
            </IconButton>
          </Tooltip>
        </a>) : null}
      {isAgent && (
        <Tooltip
          style={{ marginLeft: '5px' }}
          title="Document is uploaded for an agent"
        >
          <img
            style={{maxWidth: '20px'}}
            src="/images/creator/agent-enabled.png"
            alt="Document is uploaded for an agent"
          />
        </Tooltip>
      )}
      <br />
      {recordToUse && (
        <span>
          <b>
            {isAgent ? recordToUse.companyName : recordToUse.affiliateDataObject?.company}
          </b>
        </span>
      )}
      <br />
      <CopyToClipboard
        value={recordToUse.email}
      >
        {recordToUse.email.length > 25 ? `${recordToUse.email.slice(0, 25)}...` : recordToUse.email}
      </CopyToClipboard>
    </div>
  );
};

NameInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default NameInList;
