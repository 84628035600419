import React, { useState } from 'react';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import { Modal, Button, ModalHeader, ModalFooter, ModalBody, Row, Col } from 'reactstrap';
import withNotice from '../../../../App/store/with-notice';
import TableCellContextMenu from '../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';
import {
  formatDateForCreatorsPaymentsTables,
  formatIntNumber,
  formatNumber,
} from '../../../../../shared/helpers/WVFormatter';

const ContextMenuInList = ({
  record,
}) => {
  const videoStatistic = unflatten(record).params;
  const [showKeywords, setShowKeywords] = useState(false);
  const [showViews, setShowViews] = useState(false);
  const [showTVR, setShowTVR] = useState(false);

  const values = videoStatistic?.views?.length > 0 ? videoStatistic.views.map(data => (
    <div>
      <span className="bold-text">Views on {formatDateForCreatorsPaymentsTables(data?.datetime)}: </span>
      <span>{data?.views ? formatIntNumber(data.views) : 'n/a'}</span>
    </div>))
    : (<span className="bold-text">No data</span>);

  const contextMenuOptions = [
    {
      key: 'keywords',
      label: 'Show Keywords',
      handler: () => setShowKeywords(!showKeywords),
    },
    {
      key: 'views',
      label: 'Show Views History',
      handler: () => setShowViews(!showViews),
    },
    {
      key: 'tvr',
      label: 'Show all TVR values',
      handler: () => setShowTVR(!showTVR),
    },
  ];

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />

      <Modal
        isOpen={showKeywords}
        toggle={() => setShowKeywords(!showKeywords)}
        className="delete-modal"
        size="sm"
      >
        <ModalHeader toggle={() => setShowKeywords(!showKeywords)} >
          Keywords
        </ModalHeader>
        <ModalBody>
          {videoStatistic?.keywords?.length > 0 ? `${videoStatistic.keywords.join(', ')}` : 'No keywords'}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setShowKeywords(!showKeywords)}
            style={{ marginBottom: '4px' }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showViews}
        toggle={() => setShowViews(!showViews)}
        className="theme-light ltr-support login-modal-form"
        size="sm"
      >
        <ModalHeader toggle={() => setShowViews(!showViews)} className="bold-text">
          Views History
        </ModalHeader>
        <ModalBody
          style={{
            maxHeight: '400px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {values}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setShowViews(!showViews)}
            style={{ marginBottom: '4px' }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showTVR}
        toggle={() => setShowTVR(!showTVR)}
        className="theme-light ltr-support login-modal-form"
        size="sm"
      >
        <ModalHeader toggle={() => setShowTVR(!showTVR)} className="bold-text">
          TVR Values
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6}>
              <div>
                <span className="bold-text">V1: </span>
                <span>{videoStatistic?.tvr?.v1 ? formatNumber(videoStatistic.tvr.v1) : 'n/a'}</span>
              </div>
              <div>
                <span className="bold-text">V2: </span>
                <span>{videoStatistic?.tvr?.v2 ? formatIntNumber(videoStatistic.tvr.v30) : 'n/a'}</span>
              </div>
              <div>
                <span className="bold-text">V3: </span>
                <span>{videoStatistic?.tvr?.v3 ? formatIntNumber(videoStatistic.tvr.v30) : 'n/a'}</span>
              </div>
              <div>
                <span className="bold-text">V7: </span>
                <span>{videoStatistic?.tvr?.v7 ? formatIntNumber(videoStatistic.tvr.v30) : 'n/a'}</span>
              </div>
              <div>
                <span className="bold-text">V30: </span>
                <span>{videoStatistic?.tvr?.v30 ? formatIntNumber(videoStatistic.tvr.v30) : 'n/a'}</span>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <span className="bold-text">ER1: </span>
                <span>{videoStatistic?.tvr?.er1 ? formatNumber(videoStatistic.tvr.er1) : 'n/a'}</span>
              </div>
              <div>
                <span className="bold-text">ER2: </span>
                <span>{videoStatistic?.tvr?.er2 ? formatNumber(videoStatistic.tvr.er2) : 'n/a'}</span>
              </div>
              <div>
                <span className="bold-text">ER3: </span>
                <span>{videoStatistic?.tvr?.er3 ? formatNumber(videoStatistic.tvr.er3) : 'n/a'}</span>
              </div>
              <div>
                <span className="bold-text">ER7: </span>
                <span>{videoStatistic?.tvr?.er7 ? formatNumber(videoStatistic.tvr.er7) : 'n/a'}</span>
              </div>
              <div>
                <span className="bold-text">ER30: </span>
                <span>{videoStatistic?.tvr?.er30 ? formatNumber(videoStatistic.tvr.er30) : 'n/a'}</span>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setShowTVR(!showTVR)}
            style={{ marginBottom: '4px' }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withNotice(ContextMenuInList);
