import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import moment from 'moment-timezone';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import MoneyIcon from 'mdi-react/MoneyIcon';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';
import { StyledButton } from '../../../../../../../shared/components/components/ui';
import ReactTableBase from '../../../../../../../shared/tables/table/ReactTableBase';
import { getConstant } from '../../../../../../../shared/helpers/WVConstants';
import { getServerDate, formatCurrency } from '@/shared/helpers/WVFormatter';
import csvHelper from '@/shared/helpers/CSVHelper';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';

export const AddCreatorsModalId = 'InvoiceAddCreators';




const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSelectable: true,
  useFlex: true,
  isSortable: false,
  withPagination: true,
  manualPageSize: [5, 10, 15, 20],
  withSearchEngine: false,
};

const AddCreatorsModal = ({ possibleCreators, setVersion, dates }) => {
  const pipelines = getConstant('deal', 'pipelines', []);
  const { stages } = pipelines?.find(item => item.label.toLowerCase() === 'main pipeline') || { stages: {} };
  const columnTransform = [
    {
      id: 'creatorName',
      Header: 'Creator Name',
      accessor: value => `${value.row?.original.contact?.firstname || ''} ${value.row?.original.contact?.lastname || ''}`,
      Cell: value => (
        <div>
          <span>
            {`${value.row?.original.contact?.firstname || ''} ${value.row?.original.contact?.lastname || ''}`}
            {value.row?.original.contact?.hs_object_id && (
              <a
                href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${value.row.original.contact.hs_object_id}/`}
                target="_blank"
                rel="noreferrer noopener"
                title="Open HubSpot Contact"
                style={{ marginLeft: '5px' }}
              >
                <i className="fab fa-hubspot" />
              </a>
            )}
            {value.row?.original.deal?.hs_object_id && (
              <a
                href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${value.row.original.deal.hs_object_id}/`}
                target="_blank"
                rel="noreferrer noopener"
                title="Open HubSpot Deal"
                style={{ marginLeft: '5px' }}
              >
                <MoneyIcon size="20" />
              </a>
            )}
          </span>
        </div>
      ),
      width: 300,
    },
    {
      id: 'createdAt',
      Header: 'Content Release Date',
      Cell: value => (
        <div>
          <span>{value.row?.original.contentReleaseDate?.currentDate ? getServerDate(value.row.original.contentReleaseDate.currentDate) : '-'}</span>
        </div>
      ),
    },
    {
      id: 'dealStage',
      Header: 'Deal Stage',
      Cell: (value) => {
        console.log(value.row?.original);
        return (
          <div>
            <span>{value.row?.original.deal?.dealstage ? stages[value.row.original.deal.dealstage] : '-'}</span>
          </div>
        );
      },
    },
    {
      id: 'IoName',
      Header: 'IO Name',
      Cell: value => (
        <div>
          <span>{value.row?.original.cpmInsertionOrder ? value.row.original.cpmInsertionOrder.name : '-'}</span>
        </div>
      ),
    },
  ];
  
  const [loading, setLoading] = useState(false);
  const [endAdding, setEndAdding] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [selected, setSelected] = useState([]);
  const [dealStageFilter, setDealStageFilter] = useState([]);
  const [dealStageOptions, setDealStageOptions] = useState([]);
  const [creatorsFiltered, setCreatorsFiltered] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [results, setResults] = useState([]);
  const [insertionOrderFilter, setInsertionOrderFilter] = useState({ Assigned: true, Unassigned: false });

  useEffect(() => {
    if (pipelines.length > 0) {
      const stageOptions = Object.entries(stages).map(([key, val]) => ({ value: key, label: val }));
      setDealStageOptions(stageOptions);
      const dealStagesPreset = ['published', 'deal paid'];
      setDealStageFilter(stageOptions.filter(item => dealStagesPreset.includes(item.label.toLowerCase())));
    }
    if (dates.startDate) setStartDate(dates.startDate);
    if (dates.endDate) setEndDate(dates.endDate);
  }, [possibleCreators]);

  useEffect(() => {
    let result = possibleCreators;
    if (dealStageFilter && dealStageFilter.length > 0) {
      const selectedStagesArray = dealStageFilter.map(a => a.value);
      result = result.filter(creator => (
        !(selectedStagesArray &&
          selectedStagesArray.length > 0 &&
          !selectedStagesArray.includes(creator?.deal?.dealstage))
      ));
    }
    if (startDate) {
      result = result.filter((creator) => {
        if (creator.contentReleaseDate?.currentDate) {
          return (moment(creator.contentReleaseDate.currentDate).utc(false).isSameOrAfter(moment(startDate).utc(true)));
        }
        return false;
      });
    }
    if (endDate) {
      result = result.filter((creator) => {
        if (creator.contentReleaseDate?.currentDate) {
          return (moment(creator.contentReleaseDate.currentDate).utc(false).isSameOrBefore(moment(endDate).utc(true)));
        }
        return false;
      });
    }
    result = result.filter((creator) => {
      if (insertionOrderFilter.Assigned && creator.cpmInsertionOrder) {
        return true;
      }
      return insertionOrderFilter.Unassigned && !creator.cpmInsertionOrder;

    });
    setCreatorsFiltered(result);
  }, [dealStageFilter, startDate, endDate, insertionOrderFilter]);

  const modalInfo = useSelector(state => state.table.modals[AddCreatorsModalId]);
  const dispatch = useDispatch();
  if (!modalInfo) return false;
  const { data: { record }, isShow: showUploadModal } = modalInfo;

  const close = () => {
    dispatch(hideModalById(AddCreatorsModalId));
    // setLoading(false);
    setEndAdding(false);
    setVersion(new Date().toString());
  };

  const onAdd = () => {
    setLoading(true);
    const data = {
      cpmCreators: selected,
      invoiceId: record.id,
    };
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-add-creators-to-invoice',
      data,
    }).then((response) => {
      if (response.data.success) {
        setResultMessage('Creators were added successfully.');
        setResults(response.data.cpmProspects);
      } else {
        setResultMessage('Creators were not added.');
      }
      setLoading(false);
      setEndAdding(true);
    });
  };
  const downloadResults = () => {
    const data = [[
      'Creator Name',
      'Creator Email',
      'Creator Profile URL',
      'Deal Name',
      'Deal URL',
      'Deal Owner Name',
      'Pricing Approval Group',
      'Insertion Order',
      'Invoice Number',
      'CPM Total Cost',
      'Current Pricing Approval Status',
      'PA Submit Date',
      'Justification',
      'status'
    ]];
    results.forEach((record) => {
      data.push([
        `${record.influencer?.first_name || ''} ${record.influencer?.last_name || ''}`,
        record.influencer?.email || '',
        `${window.location.protocol}//${window.location.host}/influencer/profile/${record.influencer?._id || ''}`,
        record.deal?.dealname || '',
        record.deal ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${record.deal?.hs_object_id}` : '-',
        `${record.deal?.adminUserObject?.firstName || ''} ${record.deal?.adminUserObject?.lastName || ''}`,
        record.cpmPriceApprovals?.name || '-',
        record.cpmInsertionOrder?.name || '-',
        record.cpmInvoice?.name || '-',
        formatCurrency(record.totalPrice || 0),
        record?.cpmPriceApprovals?.status?.status || '-',
        record.createdAt || '-',
        record.dealJustification || '-',
        record.addingStatus,
      ]);
    });
    return csvHelper.generateCSV('CPMPrespects.csv', data);
  }

  const handleChangeIOFilter = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setInsertionOrderFilter({
        ...insertionOrderFilter,
        [name]: checked,
      });
    }
  };

  return (
    <Modal isOpen={showUploadModal} toggle={close} size="lg" style={{ maxWidth: '1000px' }}>
      <ModalHeader toggle={close} tag="h4">
        Add Creators
      </ModalHeader>
      <ModalBody>
        {dealStageOptions.length > 0 && !endAdding && (
          <div className="theme-light">
            <Row className="mb-3">
              <Col>
                <p className="mb-1 font-weight-bold">Deal Stages</p>
                <Select
                  isDisabled={dealStageOptions.length === 0}
                  value={dealStageFilter}
                  options={dealStageOptions}
                  onChange={setDealStageFilter}
                  placeholder="Select Deal Stage..."
                  isMulti
                />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col md={6}>
                <p className="mb-1 font-weight-bold">Content Release Date</p>
                <Row>
                  <Col>
                    <div>
                      <DatePicker
                        selected={startDate}
                        onChange={setStartDate}
                        dateFormat="MM/dd/yyyy"
                        maxDate={endDate || new Date()}
                        placeholderText="Start Date"
                        dropDownMode="select"
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm w-100"
                        isClearable
                      />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <DatePicker
                        selected={endDate}
                        onChange={setEndDate}
                        dateFormat="MM/dd/yyyy"
                        minDate={startDate || new Date()}
                        placeholderText="End Date"
                        dropDownMode="select"
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm w-100"
                        isClearable
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <p className="mb-1 font-weight-bold">Insertion Order?</p>
                <Row>
                  <Col>
                    <CheckBoxField
                      name="Assigned"
                      label="Assigned"
                      className="inline mt-1"
                      value={insertionOrderFilter.Assigned}
                      onChange={event => handleChangeIOFilter(event, 'Assigned')}
                    />
                    <CheckBoxField
                      name="Unassigned"
                      label="Unassigned"
                      className="inline ml-4 mt-1"
                      value={insertionOrderFilter.Unassigned}
                      onChange={event => handleChangeIOFilter(event, 'Unassigned')}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactTableBase
                  key="addCreatorsModal"
                  id="addCreatorsModal"
                  columns={columnTransform}
                  data={creatorsFiltered}
                  tableConfig={tableConfig}
                  updateSelect={(select, rows) => {
                    const selectedMap = Object.keys(select).map((sel) => {
                      const c = rows.find(r => r.id === sel);
                      return c?.original._id;
                    });
                    // console.log(selectedMap);
                    setSelected(selectedMap);
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
        {loading && (
          <div className="text-center"><Spinner color="primary" size="lg" />&nbsp;&nbsp;</div>
        )}
        {endAdding && (
          <div className="">
            <h4>{resultMessage}</h4>
            <ul>
              <li>Deals successfully added: {results.filter(a => a.addingStatus === 'Added')?.length || 0}</li>
              <li>Duplicate deals detected: {results.filter(a => a.addingStatus === 'Duplicate')?.length || 0}</li>
              <li>No deals detected: {results.filter(a => a.addingStatus === 'NoDeal')?.length || 0}</li>
              <li>Brand Missmatch deals detected: {results.filter(a => a.addingStatus === 'BrandMissmatch')?.length || 0}</li>
            </ul>
            <div><a href="#1" onClick={downloadResults}>Download</a> Results in CSV</div>
          </div>
        )}
        <Row className="modal__footer" style={{ marginTop: '30px' }}>
          {!endAdding && (
            <StyledButton
              className="btn btn-primary btn-sm"
              onClick={onAdd}
              style={{ margin: '0 10px 0 10px' }}
              disabled={loading || selected.length === 0}
            >
              <i className="icomoon-save" />
              <span className="btn-text">Add</span>
            </StyledButton>
          )}
          <Button
            className="btn btn-secondary btn-sm"
            onClick={close}
            style={{ margin: '0 10px 0 10px' }}
          >
            {endAdding ? 'Close' : 'Cancel'}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

AddCreatorsModal.propTypes = {
  possibleCreators: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  dates: PropTypes.objectOf(PropTypes.any),
};

AddCreatorsModal.defaultProps = {
  dates: {},
};

export default AddCreatorsModal;
