import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import SearchForm from './SearchForm';
import SearchResult from './SearchResult';

class SearchCard extends Component {
  static filterSearchResults = (array, filter) => array.filter((item) => {
    if (filter) {
      const lowerCaseFilter = typeof filter !== 'string' ? filter : filter.toLowerCase();
      return Object.keys(item).some((key) => {
        const pureKey = JSON.stringify(item[key]).toLowerCase();
        return pureKey.includes(lowerCaseFilter);
      });
    }
    return item;
  }).map((originalItem) => {
    const item = { ...originalItem };
    return item;
  }).sort((a, b) => ((a.name > b.name) ? 1 : -1));


  constructor(props) {
    super(props);
    this.state = { filter: '', focused: null };
    this.searchInputRef = createRef();
    this.handleInternalKeyPress = this.handleInternalKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  focusNextResultItem = (index, moveBy, refs) => {
    // eslint-disable-next-line no-nested-ternary
    const nextIndex = index + moveBy < 0 ? refs.length - 1 : index + moveBy > refs.length - 1 ? 0 : index + moveBy;
    if (refs[nextIndex]) {
      this.setState({ focused: nextIndex });
      refs[nextIndex].current.focus();
    }
  };

  handleInternalKeyPress = (e, index, refs) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
    const arrowIndexMap = {
      ArrowDown: 1,
      ArrowUp: -1,
    };

    return arrowIndexMap[e.key] && this.focusNextResultItem(index, arrowIndexMap[e.key], refs);
  };

  handleSubmit(e) {
    const { filter } = this.state;
    console.log(`Value is ${filter}`);
    e.preventDefault();
  }

  render() {
    const {
      isFetching,
      linkTail,
      arrayLength,
      maxHeight,
      array,
      linkTailAfter,
    } = this.props;
    const { filter, focused } = this.state;
    const filteredArray = this.constructor.filterSearchResults(array, filter);
    const refs = filteredArray.map(() => createRef());
    refs.push(this.searchInputRef);

    const results = () => (
      filteredArray.map((result, i) => (
        <SearchResult
          focused={focused === i}
          key={result.id}
          title={result.title}
          id={result.id}
          linkTail={linkTail}
          linkTailAfter={linkTailAfter}
          className="with-shadow"
          index={i}
          ref={refs[i]}
          handleKeyDown={e => this.handleInternalKeyPress(e, i, refs)}
          closeSearch={() => this.setState({ filter: '', focused: null })}
        />
      ))
    );
    return (
      <div>
        <SearchForm
          key="searchForm"
          isFetching={isFetching}
          handleKeyDown={e => this.handleInternalKeyPress(e, -1, refs)}
          onSearchChange={e => this.setState({ filter: e, focused: null })}
          value={filter}
          ref={this.searchInputRef}
          handleSubmit={e => this.handleSubmit(e)}
        />
        <div
          style={{
            position: 'absolute',
            top: '65%',
            left: '0px',
            zIndex: 100,
            width: '100%',
            backgroundColor: 'white',
            overflowY: 'scroll',
            borderRadius: '5px',
            maxHeight: `${maxHeight}vh`,
            paddingRight: '20px',
          }}
        >
          <div>
            {filter && filteredArray.length <= arrayLength
            && results()}
          </div>
        </div>
      </div>
    );
  }
}

SearchCard.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  array: PropTypes.array.isRequired,
  linkTail: PropTypes.string,
  linkTailAfter: PropTypes.string,
  arrayLength: PropTypes.number,
  maxHeight: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

SearchCard.defaultProps = {
  arrayLength: 100,
  maxHeight: 50,
  linkTail: '',
  linkTailAfter: '',
};


export default withRouter(SearchCard);
