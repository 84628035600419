import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Label, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import ApiClient from 'ApiClient';
import withNotice from '../../../App/store/with-notice';
import formatter from '../../../../shared/helpers/WVFormatter';
// import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import Filter from './CustomizedTimePeriodFilterForwarded';
import GenerateSummaryTable from './GenerateSummaryTable';
import GenerateDetailsTable from './GenerateDetailsTable';
import BarChartComponent from './BarChartComponent';
import LoadingReport from "../../../../shared/components/LoadingReport";

const Item = ({ label, value }) => (
  <div
    style={{
      padding: '15px 15px',
      border: '1px solid #cccccc',
      borderRadius: '5px',
      height: '100%',
    }}
  >
    <div style={{ textAlign: 'center', color: 'black' }} >
      <span
        style={{
          fontSize: '20px',
          fontWeight: '700',
          marginBottom: '12px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {value}
      </span>
      <span style={{ width: '100%', fontSize: '16px' }}>{label}</span>
    </div>
  </div>
);

Item.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
};

const Report = ({ addNotice }) => {
  const api = new ApiClient();
  const [activeTab, setActiveTab] = useState('summary');
  const [loadingReport, setLoadingReport] = useState(false);
  const [hasLoadedReport, setHasLoadedReport] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [maxDate, setMaxDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [dealOwnersOptions, setDealOwnersOptions] = useState([]);
  const [selectedDealOwners, setSelectedDealOwners] = useState([]);
  const [dealStagesOptions, setDealStagesOptions] = useState([]);
  const [selectedDealStages, setSelectedDealStages] = useState([]);
  const [reportData, setReportData] = useState({});

  useEffect(() => {
    setLoadingFilters(true);
    api.client.get('/api/portal/reports/get-future-new-vs-existing-filter-data').then((resp) => {
      setDealOwnersOptions(resp.data.dealOwnersOptions);
      setDealStagesOptions(resp.data.dealStagesOptions);
      setMaxDate(resp.data.maxDate ? moment(resp.data.maxDate).endOf('month').format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'));
      setLoadingFilters(false);
    }).catch(() => {
      setLoadingFilters(false);
      addNotice({
        message: 'There was an error trying to load brand options. Check out console to see more information.',
        type: 'error',
      });
    });

    setLoadingFilters(true);
  }, []);

  const fetchReport = async () => {
    setLoadingReport(true);
    setHasLoadedReport(false);
    setReportData({});
    setActiveTab('summary');
    try {
      const resp = await axios.post('/api/portal/reports/post-future-new-vs-existing-report', {
        startDate,
        endDate,
        selectedDealOwners: selectedDealOwners?.map(row => row.value),
        selectedDealStages: selectedDealStages?.map(row => row.value),
        identity,
      });
      setLoadingReport(false);
      if (resp.data.success) {
        setReportData(resp.data);
        setHasLoadedReport(true);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const timeFilterRef = useRef(null);

  const resetFilters = () => {
    timeFilterRef?.current?.selectDateRange('current_month');
    setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
    setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
    setSelectedDealOwners([]);
    setSelectedDealStages([]);
  };

  const totalBox = (key, label) => (
    <Col
      key={`totalbox${key}`}
    >
      <Item
        label={label}
        value={loadingReport ? (<><Spinner color="primary" size="sm" /><br /></>) : (<h3>{reportData?.totals[key] === '-' ? '-' : formatter.formatIntNumber(reportData.totals[key])}</h3>)}
      />
    </Col>
  );

  const totalBoxCurrency = (key, label) => (
    <Col
      key={`totalbox${key}`}
    >
      <Item
        label={label}
        value={loadingReport ? (<><Spinner color="primary" size="sm" /><br /></>) : (<h3>{reportData.totals[key] === '-' ? '-' : formatter.formatCurrency(reportData.totals[key])}</h3>)}
      />
    </Col>
  );

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Future New vs. Existing Report', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Future New vs. Existing Report</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col>
                  <Label className="bold-text">Content Release Date</Label>
                  <Filter
                    ref={timeFilterRef}
                    maxDate={maxDate}
                    startDateInitial={startDate}
                    endDateInitial={endDate}
                    setValue={(param, value) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('month').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('month').format('YYYY-MM-DD'));
                      }
                    }}
                    usedForConversions
                    isLoading={!!loadingReport}
                    options={[
                      { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
                      { value: 'next_month', label: 'Next Month' },
                      { value: 'next_3_months', label: 'Next 3 Months' },
                      { value: 'end_of_year', label: 'End of Year' },
                      {
                        value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
                      },
                    ]}
                    selectDateRangeFunction={(range) => {
                      let dateFrom;
                      let dateTo;
                      let showCustom = false;
                      switch (range) {
                        case 'current_month':
                          showCustom = false;
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = moment().endOf('month').toDate();
                          break;
                        case 'next_month':
                          showCustom = false;
                          dateFrom = moment().add(1, 'month').startOf('month').toDate();
                          dateTo = moment().add(1, 'month').endOf('month').toDate();
                          break;
                        case 'next_3_months':
                          showCustom = false;
                          dateFrom = moment().add(1, 'month').startOf('month').toDate();
                          dateTo = moment().add(3, 'month').endOf('month').toDate();
                          break;
                        case 'end_of_year':
                          showCustom = false;
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = maxDate && moment(maxDate).endOf('month').toDate() < moment().endOf('Year').toDate() ? moment(maxDate).endOf('month').toDate() : moment().endOf('Year').toDate();
                          break;
                        case 'custom':
                          showCustom = true;
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = maxDate ? moment(maxDate).endOf('month').toDate() : moment().endOf('month').toDate();
                          break;
                        default:
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = moment().endOf('month').toDate();
                          break;
                      }
                      return { dateFrom, dateTo, showCustom };
                    }
                  }
                  />
                </Col>
                <Col lg="auto">
                  <div style={{ paddingTop: '30px' }}>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchReport();
                        return false;
                      }}
                      className="my-0 btn-sm"
                      disabled={!!loadingReport}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2" >
                <Col md={5}>
                  <Label className="bold-text">
                    Deal Owner(s)
                  </Label>
                  <Select
                    isDisabled={loadingFilters}
                    value={selectedDealOwners}
                    options={dealOwnersOptions}
                    onChange={setSelectedDealOwners}
                    placeholder="Select Deal Owners..."
                    isClearable
                    isMulti
                  />
                </Col>
                <Col md={5}>
                  <Label className="bold-text">
                    Deal Stage(s)
                  </Label>
                  <Select
                    isDisabled={loadingFilters}
                    value={selectedDealStages}
                    options={dealStagesOptions}
                    onChange={setSelectedDealStages}
                    placeholder="Select Deal Stages..."
                    isClearable
                    isMulti
                  />
                </Col>
                <Col style={{ paddingTop: '29px' }}>
                  <Tooltip title="Reset Filters">
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={resetFilters}
                      style={{
                        float: 'right',
                        width: '150px',
                        padding: '4px 8px',
                        height: '38px',
                      }}
                    >
                      <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                      <span className="btn-text">Reset</span>
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loadingReport} />
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    topic="reportFuture"
                    identity={identity}
                    isShow={!!loadingReport}
                  />
                </Col>
              </Row> */}
              {!loadingReport && hasLoadedReport && (
                <>
                  <Label className="bold-text">
                    Upcoming Deals
                  </Label>
                  <Row>
                    <Col md={4}>
                      {totalBox('totalCreators', 'Total Creators')}
                    </Col>
                    <Col md={4}>
                      {totalBox('totalContent', 'Total Content')}
                    </Col>
                    <Col md={4}>
                      {totalBoxCurrency('totalSpend', 'Total Spend')}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={2}>
                      {totalBox('newCreators', 'New Creators')}
                    </Col>
                    <Col md={2}>
                      {totalBox('existingCreators', 'Existing Creators')}
                    </Col>
                    <Col md={2}>
                      {totalBox('newContent', 'New Content')}
                    </Col>
                    <Col md={2}>
                      {totalBox('existingContent', 'Existing Content')}
                    </Col>
                    <Col md={2}>
                      {totalBoxCurrency('newSpend', 'New Spend')}
                    </Col>
                    <Col md={2}>
                      {totalBoxCurrency('existingSpend', 'Existing Spend')}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col className="tabs">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === 'summary' })}
                            onClick={() => {
                              setActiveTab('summary');
                            }}
                          >
                            Summary Data
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === 'details' })}
                            onClick={() => {
                              setActiveTab('details');
                            }}
                          >
                            Future Deal Details
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="summary">
                          {Array.isArray(reportData.summaryByDealStage) && reportData.summaryByDealStage?.length > 0 && (
                            <GenerateSummaryTable
                              key="0"
                              items={Array.isArray(reportData.summaryByDealStage) ? reportData.summaryByDealStage : []}
                              loading={loadingReport}
                            />
                          )}
                          <Row key="1" className="mt-3 mb-2">
                            <Col md={12}>
                              <Label className="bold-text">Future Spend</Label>
                              <BarChartComponent
                                chartData={reportData.timePeriods}
                                chartType="Spend"
                                currency
                              />
                            </Col>
                          </Row>
                          <Row key="2" className="mt-3 mb-2">
                            <Col md={12}>
                              <Label className="bold-text">Future Creators</Label>
                              <BarChartComponent
                                chartData={reportData.timePeriods}
                                chartType="Creators"
                              />
                            </Col>
                          </Row>
                          <Row key="3" className="mt-3 mb-2">
                            <Col md={12}>
                              <Label className="bold-text">Future Content</Label>
                              <BarChartComponent
                                chartData={reportData.timePeriods}
                                chartType="Content"
                              />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="details">
                          {Array.isArray(reportData.detailsData) && reportData.detailsData?.length > 0 && (
                            <GenerateDetailsTable
                              key="7"
                              items={Array.isArray(reportData.detailsData) ? reportData.detailsData : []}
                              loading={loadingReport}
                            />
                          )}
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Report.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(Report);
