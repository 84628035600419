import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  ButtonToolbar,
  Button,
} from 'reactstrap';
import PermissionComponent from '../../../../shared/components/PermissionComponent';
import { CheckPermission } from '../../../../shared/helpers/WVUtilities';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../App/store/with-notice';

const Download = ({ addNotice }) => {
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [status, setStatus] = useState('pending');
  const [statusMessage, setStatusMessage] = useState('');
  const [startFrom, setStartFrom] = useState(0);
  const [nextStart, setNextStart] = useState(0);
  const [limit, setLimit] = useState(2850);

  if (!CheckPermission('admins----docusign----download')) return (<PermissionComponent />);

  const processPage = startPosition => axios.post('/api/portal/agreement/post-download', {
    identity,
    platform: 'docusign',
    params: {
      fromDate: '2000-01-01T00:00:00+00:00',
      status: 'completed',
      include: 'recipients',
      count: Math.min(limit, 150),
      startPosition,
      order: 'asc',
      orderBy: 'completed',
    },
  });

  const run = (position) => {
    if (limit > 0 && (position - startFrom >= limit)) {
      setNextStart(position);
      setStartFrom(position);
      setStatus('done');
      return;
    }
    processPage(position).then((res) => {
      const { success, nextPosition } = res.data;
      if (nextPosition) {
        if (success) {
          run(nextPosition);
          return;
        } else {
          setNextStart(nextPosition);
          setStartFrom(nextPosition);
        }
      }
      setStatus('done');
      if (!success) {
        addNotice({
          message: res.data.error,
          type: 'error',
        });
      }
    });
  }

  const start = () => {
    setNextStart(0);
    setStatus('processing');
    run(startFrom);
  };

  const limitOpts = [
    { value: 0, label: 'No Limit' },
    { value: 1, label: 1 },
  ];
  for (let i = 1; i <= 20; i += 1) limitOpts.push({ value: i * 150, label: i * 150 });

  return (
    <Container className="dashboard">
      <Breadcrumbs
        links={[
          { title: 'Home', path: '/' },
          { title: 'DocuSign', path: false },
          { title: 'Download Agreements', path: false },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row>
              <Col>
                <h3>Download Agreements</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-0" />
          </CardTitle>
          <Row>
            <Col>
              {status === 'pending' || nextStart > 0 ? (
                <>
                  <FormGroup>
                    <Label>Start From</Label>
                    <Input
                      name="startFrom"
                      type="text"
                      value={startFrom}
                      onChange={e => setStartFrom(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Limit</Label>
                    <Select
                      value={limitOpts.find(o => o.value === limit)}
                      options={limitOpts}
                      onChange={({ value }) => setLimit(value)}
                    />
                  </FormGroup>
                </>
              ) : (
                <>
                  <ProgressBar
                    topic="docusignDownload"
                    identity={identity}
                    receiveResults={res => setStatusMessage(res.statusMessage || '')}
                  />
                  {statusMessage}
                </>
              )}
              {status === 'done' && nextStart > 0 && (
                <div>
                  Use <strong>Start From</strong> value <strong>{nextStart}</strong> to download remaining agreements
                </div>
              )}
              {status !== 'processing' && (
                <ButtonToolbar className="modal__footer">
                  {(status === 'pending' || nextStart > 0) && (
                    <Button
                      className="mr-2"
                      color="primary"
                      onClick={start}
                    >
                      Download
                    </Button>
                  )}
                  <Button
                    className="ml-2"
                    color="secondary"
                    onClick={() => window.history.back()}
                  >
                    {status === 'pending' ? 'Cancel' : 'Close'}
                  </Button>
                </ButtonToolbar>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

Download.propTypes = {
    addNotice: PropTypes.func.isRequired,
};

export default withNotice(Download);
