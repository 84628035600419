import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button, Label, FormGroup,
} from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { useAsyncDebounce } from 'react-table';
// import { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import DatePicker from 'react-datepicker';
// import { NumericFormat as NumberFormat } from 'react-number-format';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import TuneIcon from 'mdi-react/TuneIcon';
import moment from 'moment';
import { getCookie, setCookie } from '../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import IntervalDatePickerField
  from '../../../Influencer/Research/Prospects/components/custom/IntervalDatePickerField';
import TimePeriodFilter from './custom/actions/TimePeriodFilter';
import { setUrl } from './custom/actions/setUrl';
import { loadBrandsOptions, loadContactsOptions } from '../../../../../shared/helpers/OptionsLoader';

const dateRangeFilterLabels = [
  { value: '=', label: '=' },
  { value: '>', label: '>' },
  { value: '>=', label: '>=' },
  { value: '<', label: '<' },
  { value: '<=', label: '<=' },
  { value: 'Between', label: 'Between' },
];

const statusFilter = {
  'Needs Review': true,
  'Pending Edits': true,
  Approved: false,
  Terminated: false,
};

// const loadBrandsOptions = async (inputValue) => {
//   let response = {};
//   if (inputValue === 'getByIds') {
//     const initialSearch = new URLSearchParams(window.location.search);
//     if (initialSearch.has('filters.brand') && initialSearch.getAll('filters.brand').length > 0) {
//       response = await axios.get('/api/portal/influencer/get-brands-by-ids', {
//         params: { ids: initialSearch.getAll('filters.brand') },
//       });
//       if (!response.data.success) {
//         return {};
//       }
//     }
//   } else {
//     response = await axios({
//       method: 'get',
//       url: `/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`,
//     });
//   }
//   return response.data.brands.map(record => ({
//     value: record.params?._id || record._id,
//     label: record.params ? record.params.companyName : record.companyName,
//     record,
//   }));
// };

// const loadCreatorsOptions = async (value) => {
//   let response = {};
//   if (value === 'getByIds') {
//     const initialSearch = new URLSearchParams(window.location.search);
//     if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
//       response = await axios.get('/api/portal/hubil/get-contacts-by-ids', {
//         params: { ids: initialSearch.getAll('filters.creator') },
//       });
//       if (!response.data.success) {
//         return {};
//       }
//     }
//   } else {
//     response = await axios.get('/api/portal/hubil/get-contact-search', {
//       params: {
//         value,
//         param: 'name',
//         defaultSearch: 'a',
//       },
//     });
//   }
//   return response.data.records.map(record => ({
//     value: record.params?._id || record._id,
//     label: record.params ? `${record.params.firstname} ${record.params.lastname}` : `${record.firstname} ${record.lastname}`,
//     record,
//   }));
// };

const Actions = ({ setShowUpdateStatusModal, selectedCommentIds, downloadCsv }) => {
  let selectedStartDateInitialState = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss')).startOf('year');
  let selectedEndDateInitialState = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss')).endOf('day');
  let selectedInitialRange = 'year_to_date';

  const initialSearch = new URLSearchParams(window.location.search);
  if (initialSearch.has('filters.lastReviewDate.from') && initialSearch.getAll('filters.lastReviewDate.from').length > 0) {
    initialSearch.getAll('filters.lastReviewDate.from').forEach((from) => {
      selectedStartDateInitialState = moment(moment.utc(from).format('YYYY-MM-DD'));
    });
  }
  if (initialSearch.has('filters.lastReviewDate.to') && initialSearch.getAll('filters.lastReviewDate.to').length > 0) {
    initialSearch.getAll('filters.lastReviewDate.to').forEach((to) => {
      selectedEndDateInitialState = moment(moment.utc(to).format('YYYY-MM-DD'));
    });
  }
  if (initialSearch.has('range') && initialSearch.getAll('range').length > 0) {
    initialSearch.getAll('range').forEach((range) => {
      selectedInitialRange = range;
    });
  }
  if (initialSearch.has('filters.reviewStatus') && initialSearch.getAll('filters.reviewStatus').length > 0) {
    Object.keys(statusFilter).map((key) => {
      statusFilter[key] = false;
      return true;
    });
    initialSearch.getAll('filters.reviewStatus').forEach((status) => {
      statusFilter[status] = true;
    });
  }
  const [search, setSearch] = useState('');
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('creatorReviewShowFilters') === 'true');
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [filtersReviewStatus, setFiltersReviewStatus] = useState(statusFilter);

  const [dateFrom, setDateFrom] = useState(selectedStartDateInitialState.format('YYYY-MM-DD HH:mm:ss'));
  const [dateTo, setDateTo] = useState(selectedEndDateInitialState.format('YYYY-MM-DD HH:mm:ss'));
  const [selectedButton, setSelectedButton] = useState(selectedInitialRange);

  // const [enableLPublishedDateFilter, setEnableLPublishedDateFilter] = useState(true);
  // const [singleLPublishedDate, setSingleLPublishedDate] = useState(null);
  // const [lPublishedDateFilterType, setLPublishedDateFilterType] = useState(dateRangeFilterLabels[5]);
  // const [lPublishedDateFrom, setLPublishedDateFrom] = useState(selectedStartDateInitialState ? selectedStartDateInitialState.toDate() : null);
  // const [lPublishedDateTo, setLPublishedDateTo] = useState(selectedEndDateInitialState ? selectedEndDateInitialState.toDate() : null);

  const [enableLastReviewDateFilter, setEnableLastReviewDateFilter] = useState(true);
  const [singleLastReviewDate, setSingleLastReviewDate] = useState(null);
  const [lastReviewDateFilterType, setLastReviewDateFilterType] = useState(dateRangeFilterLabels[5]);
  const [lastReviewDateFrom, setLastReviewDateFrom] = useState(selectedStartDateInitialState ? selectedStartDateInitialState.toDate() : null);
  const [lastReviewDateTo, setLastReviewDateTo] = useState(selectedEndDateInitialState ? selectedEndDateInitialState.toDate() : null);
  const history = useHistory();
  const firstUpdate = useRef(true);

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl({
      history,
      removeBeforeSet: false,
      search,
      dateFrom,
      dateTo,
      selectedButton,
      // lPublishedDateFrom,
      // lPublishedDateTo,
      // singleLPublishedDate,
      // lPublishedDateFilterType,
      selectedCreators,
      selectedBrands,
      filtersReviewStatus,
      lastReviewDateFrom,
      lastReviewDateTo,
      singleLastReviewDate,
      lastReviewDateFilterType,
    });
  }, 800);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setUrlDebounced();
    // console.log('Change');
  }, [
    filtersReviewStatus, selectedCreators,
    search,
    dateFrom, dateTo,
    // lPublishedDateFrom, lPublishedDateTo, singleLPublishedDate, lPublishedDateFilterType,
    selectedBrands,
    lastReviewDateFrom, lastReviewDateTo, singleLastReviewDate, lastReviewDateFilterType,
  ]);

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };
  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
    }),
  };

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      // onSearchChange(search);
      // console.log(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('creatorReviewShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  useEffect(() => {
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      loadContactsOptions('getByIds').then((response) => {
        setSelectedCreators(response);
      });
    }
    if (initialSearch.has('filters.brand') && initialSearch.getAll('filters.brand').length > 0) {
      loadBrandsOptions('getByNames').then((response) => {
        setSelectedBrands(response);
      });
    }
  }, []);
  const handleChangeStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersReviewStatus({
        ...filtersReviewStatus,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    setSelectedBrands([]);
    setSelectedCreators([]);
    setFiltersReviewStatus(statusFilter);

    // setEnableLPublishedDateFilter(false);
    // setLPublishedDateFilterType(dateRangeFilterLabels[5]);
    // setLPublishedDateFrom(null);
    // setLPublishedDateTo(null);
    // setSingleLPublishedDate(null);

    setEnableLastReviewDateFilter(false);
    setLastReviewDateFilterType(dateRangeFilterLabels[3]);
    setLastReviewDateFrom(null);
    setLastReviewDateTo(null);
    setSingleLastReviewDate(null);
  };

  return (
    <div className="mb-1">
      <TimePeriodFilter
        startDateInitial={dateFrom}
        endDateInitial={dateTo}
        selectedButtonInitial={selectedButton}
        setValue={(param, value, range) => {
          if (param === 'startDate') {
            setDateFrom(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
            setLastReviewDateFrom(moment(moment(value).format('YYYY-MM-DD HH:mm:ss')).startOf('day').toDate());
          }
          if (param === 'endDate') {
            setDateTo(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
            setLastReviewDateTo(moment(moment(value).format('YYYY-MM-DD HH:mm:ss')).endOf('day').toDate());
          }
          setSelectedButton(range);
        }}
      />
      <Row className="mt-4">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Creator Name"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => { setShowUpdateStatusModal(true); }}
                disabled={selectedCommentIds.length === 0}
              >
                Bulk Update Compliance Status
              </DropdownItem>
              <DropdownItem
                onClick={downloadCsv}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      {showFilters && (
        <>
          <Row className="mt-3">
            <Col md={4}>
              <FormGroup>
                <Label className="bold-text">
                  Brand(s)
                </Label>
                <SelectAsync
                  cacheOptions
                  defaultOptions
                  isClearable
                  isMulti
                  loadOptions={async value => loadBrandsOptions(value)}
                  onChange={setSelectedBrands}
                  placeholder="Select Brand..."
                  value={selectedBrands}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className="bold-text">
                  Creator(s)
                </Label>
                <SelectAsync
                  cacheOptions
                  defaultOptions
                  isClearable
                  isMulti
                  loadOptions={async value => loadContactsOptions(value)}
                  onChange={setSelectedCreators}
                  placeholder="Select Creator..."
                  value={selectedCreators}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <FormGroup>
                <Label className="bold-text">
                  Review Status
                </Label>
                <div>
                  <CheckBoxField
                    name="NeedsReview"
                    label="Needs Review"
                    // className="inline"
                    value={filtersReviewStatus['Needs Review']}
                    onChange={event => handleChangeStatusFilters(event, 'Needs Review')}
                  />
                  <CheckBoxField
                    name="PendingEdits"
                    label="Pending Edits"
                    // className="inline ml-4"
                    value={filtersReviewStatus['Pending Edits']}
                    onChange={event => handleChangeStatusFilters(event, 'Pending Edits')}
                  />
                  <CheckBoxField
                    name="Approved"
                    label="Approved"
                    // className="inline ml-4"
                    value={filtersReviewStatus.Approved}
                    onChange={event => handleChangeStatusFilters(event, 'Approved')}
                  />
                  <CheckBoxField
                    name="Terminated"
                    label="Terminated"
                    // className="inline ml-4"
                    value={filtersReviewStatus.Terminated}
                    onChange={event => handleChangeStatusFilters(event, 'Terminated')}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <Label className="bold-text">Last Review Date</Label>
              <Row>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '8px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="LastReviewDate"
                      value={enableLastReviewDateFilter}
                      onChange={() => { setEnableLastReviewDateFilter(!enableLastReviewDateFilter); }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={4} style={{ paddingLeft: '20px', paddingRight: 0 }}>
                  <Select
                    value={lastReviewDateFilterType}
                    onChange={(option) => {
                      setLastReviewDateFilterType(option);
                    }}
                    options={dateRangeFilterLabels}
                    isDisabled={!enableLastReviewDateFilter}
                    styles={customStyles}
                  />
                </Col>
                <Col sm={6} style={{ paddingRight: '0px' }}>
                  {lastReviewDateFilterType.value === 'Between' ? (
                    <div style={{ marginRight: '-30px' }}>
                      <IntervalDatePickerField
                        onChange={({ start, end }) => {
                          setLastReviewDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setLastReviewDateTo(end);
                        }}
                        startDate={lastReviewDateFrom}
                        endDate={(lastReviewDateTo < new Date()) ? lastReviewDateTo : new Date()}
                        maxDate={new Date()}
                        placeholderFrom="Start Date"
                        placeholderTo="End Date"
                        verticalFields
                        disabled={!enableLastReviewDateFilter}
                        datePickerCustomClass="datepicker-in-profile"
                      />
                    </div>
                  ) : (
                    <div className="date-picker datepicker-in-profile">
                      <DatePicker
                        selected={singleLastReviewDate}
                        onChange={(date) => {
                          if (lastReviewDateFilterType.value === '<=' || lastReviewDateFilterType.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          setSingleLastReviewDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm datepicker-in-profile w-100"
                        disabled={!enableLastReviewDateFilter}
                      />
                      {singleLastReviewDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '8px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            {/* <Col md={3}>
              <Label className="bold-text">Latest Publish Date</Label>
              <Row>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '8px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="CommentDate"
                      value={enableLPublishedDateFilter}
                      onChange={() => { setEnableLPublishedDateFilter(!enableLPublishedDateFilter); }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={4} style={{ paddingLeft: '20px', paddingRight: 0 }}>
                  <Select
                    value={lPublishedDateFilterType}
                    onChange={(option) => {
                      // if ((commentDateFilterType.value !== 'Between' && option.value === 'Between') || (commentDateFilterType.value === 'Between' && option.value !== 'Between')) {
                      //   setCommentDateFrom(null);
                      //   setCommentDateTo(null);
                      //   setSingleCommentDate(null);
                      // }
                      setLPublishedDateFilterType(option);
                    }}
                    options={dateRangeFilterLabels}
                    isDisabled={!enableLPublishedDateFilter}
                    styles={customStyles}
                  />
                </Col>
                <Col sm={6} style={{ paddingRight: '0px' }}>
                  {lPublishedDateFilterType.value === 'Between' ? (
                    <div style={{ marginRight: '-30px' }}>
                      <IntervalDatePickerField
                        onChange={({ start, end }) => {
                          setLPublishedDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setLPublishedDateTo(end);
                        }}
                        startDate={lPublishedDateFrom}
                        endDate={lPublishedDateTo}
                        maxDate={new Date()}
                        placeholderFrom="Start Date"
                        placeholderTo="End Date"
                        verticalFields
                        disabled={!enableLPublishedDateFilter}
                        datePickerCustomClass="datepicker-in-profile"
                      />
                    </div>
                  ) : (
                    <div className="date-picker datepicker-in-profile">
                      <DatePicker
                        selected={singleLPublishedDate}
                        onChange={(date) => {
                          if (lPublishedDateFilterType.value === '<=' || lPublishedDateFilterType.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          setSingleLPublishedDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm datepicker-in-profile w-100"
                        disabled={!enableLPublishedDateFilter}
                      />
                      {singleLPublishedDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '8px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col> */}
          </Row>
        </>
      )}
    </div>
  );
};
Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
  setShowUpdateStatusModal: PropTypes.func.isRequired,
  selectedCommentIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

// Actions.defaultProps = {
//   total: 0,
// };

const mapStateToProps = state => ({
  total: state.table.total,
});

export default connect(mapStateToProps)(Actions);
