import React from 'react';

import { 
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

 } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import AccessComponent from '@/shared/components/auth/AccessComponent';
import useShowModalHook from '../../../../../../../shared/hooks/useShowModalHook';
import ScheduleNotification from '../Modal/ScheduleNotification';



const DashboardActions = ({ categories, downloadCsv }) => {
  const history  = useHistory();
  const [showSendNotification] = useShowModalHook('ScheduleNotification')
  return (
    <>
      <UncontrolledButtonDropdown className="w-100">
        <DropdownToggle
          id="actionsMenu"
          caret
          color="primary"
          style={{
            padding: '4px 25px',
            height: '42px',
          }}
        >
          Actions
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => { history.push('/create-notification')}}>New Notification</DropdownItem>
          <DropdownItem divider />
          <AccessComponent hideWhenTeam={['Auditors']}>
            <DropdownItem onClick={() => downloadCsv()}>Download CSV</DropdownItem>
          </AccessComponent>
          <DropdownItem onClick={() => { showSendNotification({ record: true }) }}>Send/Schedule Notificaion</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      <ScheduleNotification />
    </>
  );
};
DashboardActions.propTypes = {

};


export default DashboardActions;
