import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import {
  getExtensionVersion,
} from '../../../redux/reducers/admin/ResearchReducer';

const ExtensionPage = ({
  loading,
  version,
  dispatch,
}) => {
  useEffect(() => {
    dispatch(getExtensionVersion());
  }, []);

  return (
    <Container className="dashboard">
      <div style={{ width: '100%' }}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                {loading ? (
                  <div
                    className="text-center"
                  >
                    <Spinner
                      color="primary"
                      size="lg"
                    />
                  </div>
                ) : (
                  <>
                    {version ? (
                      <p className="bold-text">{`Latest Chrome extension version: ${version}`}</p>
                    ) : (
                      <p className="bold-text">Sorry, could not fetch a version of latest Chrome extension.</p>
                    )}
                    <br />
                    <a
                      href="/api/portal/research/get-extension-zip"
                      download={`il_chrome_extension${version ? `_${version}` : ''}.zip`}
                    >
                      Download latest version
                    </a>
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
ExtensionPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  version: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => ({
  loading: state.ResearchReducer.loading.extensionData,
  version: state.ResearchReducer.extensionVersion,
}))(ExtensionPage);
