
export default ({ dealstages }) => [
  {
    initialState: '',
    func: ({ filters }) => filters?.fullSearchName || '',
    filterName: 'fullSearchName',
    funcToUrl: ({ search, item }) => {
      if (item) {
        search.set('filters.fullSearchName', item);
      } else {
        search.delete('filters.fullSearchName');
      }
      return search;
    },
  },

];
