import React, { PureComponent } from 'react';
import { Row, Col, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import WVFormatter from '../../../../../shared/helpers/WVFormatter';
import ButtonWithSpinner from '../../../../../shared/components/ButtonWithSpinner';

function GraySpinner() {
  return (
    <span>
      <div
        className="spinner-border il-text-gray spinner-border-sm"
        style={{ width: '1.5rem', height: '1.5rem', borderWidth: '.10em' }}
      />
    </span>
  );
}


class StripeBalanceControls extends PureComponent {
  static propTypes = {
    addNotice: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentBalance: 0,
      availableBalance: 0,
      loading: {
        balance: false,
        payout: false,
        addBalance: false,
        transfer: false,
      },
    };
  }

  componentDidMount() {
    this.fetchStripeBalance();
  }

  // HANDLERS
  onPayoutButton = () => {
    this.setLoading('payout', true);

    axios({
      method: 'post',
      url: '/api/portal/stripe/payout-connected-accounts',
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);
      this.setLoading('payout', false);

      if (response.data.success) {
        this.props.addNotice({
          message: 'Successfully scheduled payouts for connected accounts.',
          type: 'success',
        });
      } else {
        this.props.addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
      this.fetchStripeBalance();
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
      this.props.addNotice({
        message: error.message,
        type: 'error',
      });
    });
  };

  onTransferButton = async () => {
    this.setLoading('transfer', true);
    /*
      example of transfers data
      const transfersData = [
       {
         influencer: "Influencer._id",
         amount: 10000, // in cents
         email: "influencer.email",
         destination: "influencer.payment.stripe.accountId"
       },
      ]
    */

    const result = await axios.get('/api/portal/stripe/connected-accounts');
    const { accounts } = result.data;

    const transfersData = accounts.map(account => ({
      // eslint-disable-next-line
      influencer: account._id,
      amount: 5000, // in cents
      email: account.email,
      destination: account.payment.stripe.accountId,
    }));
    // END

    axios({
      method: 'post',
      url: '/api/portal/stripe/transfer-funds',
      data: { transfersData },
    }).then((response) => {
      if (response.data.success) {
        this.props.addNotice({
          message: 'Successfully scheduled transfer of platform funds to connected accounts.',
          type: 'success',
        });
      } else {
        this.props.addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
      this.setLoading('transfer', false);
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
      this.props.addNotice({
        message: error.message,
        type: 'error',
      });
      this.setLoading('transfer', false);
    });
  }

  onAddBalanceButton = () => {
    this.setLoading('addBalance', true);

    axios({
      method: 'post',
      url: '/api/portal/stripe/charge-platform-account',
    }).then((response) => {
      if (response.data.success) {
        this.setLoading('addBalance', false);

        this.props.addNotice({
          message: 'Successfully charged platform balance with test $1000 - 3.2% Stripe fee',
          type: 'success',
        });
        this.fetchStripeBalance();
      }
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
      this.setLoading('addBalance', false);
    });
  }
  //

  //  HELPERS
  setLoading(property, value) {
    this.setState({
      loading: {
        [property]: value,
      },
    });
  }
  //

  // METHODS
  fetchStripeBalance() {
    this.setState({
      loading: {
        balance: true,
      },
    });

    axios.get('/api/portal/stripe/balance').then((response) => {
      const { balance } = response.data;

      this.setState({
        currentBalance: balance.available[0].amount + balance.pending[0].amount,
        availableBalance: balance.available[0].amount,
        loading: {
          balance: false,
        },
      });
    }).catch((error) => {
      console.log(error);
    });
  }
  //


  render() {
    return (
      <Row className="align-items-center">
        <Col xs="auto">
          <h3>Balance</h3>
          <Row className="align-items-center">
            <Col xs="auto">
              <h3 className="font-weight-bold">
                {WVFormatter.formatCurrency(this.state.currentBalance / 100)}
              </h3>
              <h4 className="il-text-gray">
                {WVFormatter.formatCurrency(this.state.availableBalance / 100)} available
              </h4>
            </Col>
            <Col xs="auto">
              {this.state.loading.balance && GraySpinner()}
            </Col>
          </Row>
        </Col>
        { window.process.env.NODE_ENV !== 'production' &&
          <Col xs="auto">
            <ButtonToolbar>
              <ButtonWithSpinner
                className="px-5 mb-1 btn-sm"
                type="button"
                outline
                color="success"
                loading={this.state.loading.addBalance}
                onClick={this.onAddBalanceButton}
              >
                Charge Account
              </ButtonWithSpinner>
            </ButtonToolbar>
            <h6 className="text-muted">Charge test Stripe account balance with $1000</h6>
          </Col>
        }
      </Row>
    );
  }
}

export default StripeBalanceControls;
