import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment/moment';
import {
  Button,
  ButtonToolbar,
  Modal,
  ModalBody,
  ModalHeader, Label, Input, FormGroup,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { axios } from 'ApiClient';
import withNotice from '../../../../App/store/with-notice';

const dealTypeOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Expired', label: 'Expired' },
];

const AddContentReviewRuleModal = ({
  addNotice,
  isOpen,
  close,
  updateTable,
  loadingBrandOptions,
  brandOptions,
}) => {
  const [loading, setLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [name, setName] = useState([]);
  const [description, setDescription] = useState('');
  const [advertisers, setAdvertisers] = useState('');
  const [dealStatuses, setDealStatuses] = useState([]);
  const [intervalMinDays, setIntervalMinDays] = useState(10);
  const [intervalMaxDays, setIntervalMaxDays] = useState(30);
  const [startDatetime, setStartDatetime] = useState(moment().add(2, 'hour').toDate());
  const [errors, setErrors] = useState({});
  const [validity, setValidity] = useState({});

  useEffect(async () => {
    return () => {
    }
  }, []);

  useEffect(async () => {
    if (isOpen) {
      setStartDatetime(moment().add(2, 'hour').toDate());
    } else {
      setLoading(false);
      setDisableSave(true);
      setName('');
      setDescription('');
      setAdvertisers([]);
      setDealStatuses([]);
      setIntervalMinDays(0);
      setIntervalMaxDays(0);
      setStartDatetime(moment().add(1, 'hour').toDate());
      setErrors({});
      setValidity({});
    }
  }, [isOpen]);

  useEffect(async () => {
    setDisableSave(Object.values(validity).includes(false));
  }, [validity]);

  const submit = () => {
    if (Object.values(validity).includes(false)) {
      setDisableSave(true);
      addNotice({
        message: 'Invalid data detected!',
        type: 'error',
      });
      return false;
    }
    setLoading(true);
    axios.post('/api/portal/compliance/post-add-content-review-rule', {
      type: 'contentReviewRules',
      enabled: true,
      deleted: false,
      inProgress: false,
      frequency: {
        months: 0,
        days: 1,
        hours: 0,
        minutes: 0,
      },
      identifier: `contentReviewRule${name.replace(/\s+/g, '')}`,
      name,
      description,
      advertisers: advertisers.map(item => item.value) || [],
      dealCPAStatuses: dealStatuses.map(item => item.value) || [],
      intervalMinDays,
      intervalMaxDays,
      startDatetime,
    }).then((response) => {
      if (response?.data?.success) {
        addNotice({
          message: 'Rule was successfully created.',
          type: 'success',
        });
        updateTable();
        setTimeout(() => close(), 1000);
      }
      if (response?.data?.error) {
        console.error(response.data.error);
        addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
      setLoading(false);
    }).catch((error) => {
      if (error) {
        console.error(error);
        addNotice({
          message: error,
          type: 'error',
        });
      }
      setLoading(false);
    });
    return true;
  };

  const validateData = () => {
    const newValidity = { ...validity };
    const newErrors = { ...errors };
    if (!name || name?.length === 0) {
      newValidity.name = false;
      newErrors.name = 'Name can not be empty.';
    } else {
      newValidity.name = true;
      newErrors.name = null;
    }

    if (!description || description?.length === 0) {
      newValidity.description = false;
      newErrors.description = 'Description can not be empty.';
    } else {
      newValidity.description = true;
      newErrors.description = null;
    }

    if (typeof intervalMinDays === 'undefined' || intervalMinDays === null) {
      newValidity.intervalMinDays = false;
      newErrors.intervalMinDays = 'Interval Min Days can not be empty.';
    } else if (intervalMinDays < 0) {
      newValidity.intervalMinDays = false;
      newErrors.intervalMinDays = 'Interval Min Days can not be negative.';
    } else {
      newValidity.intervalMinDays = true;
      newErrors.intervalMinDays = null;
    }

    if (typeof intervalMaxDays === 'undefined' || intervalMaxDays === null) {
      newValidity.intervalMaxDays = false;
      newErrors.intervalMaxDays = 'Interval Max Days can not be empty.';
    } else if (intervalMaxDays < 0) {
      newValidity.intervalMaxDays = false;
      newErrors.intervalMaxDays = 'Interval Max Days can not be negative.';
    } else {
      newValidity.intervalMaxDays = true;
      newErrors.intervalMaxDays = null;
    }

    const theFuture = new Date();
    theFuture.setHours(theFuture.getHours() + 1);
    if (typeof startDatetime === 'undefined' || startDatetime === null) {
      newValidity.startDatetime = false;
      newErrors.startDatetime = 'Start Datetime can not be empty.';
    } else if (startDatetime < theFuture) {
      newValidity.startDatetime = false;
      newErrors.startDatetime = 'Start Datetime should be set in the future.';
    } else {
      newValidity.startDatetime = true;
      newErrors.startDatetime = null;
    }

    setValidity(newValidity);
    setErrors(newErrors);
  };

  useEffect(async () => {
    validateData();
  }, [
    name,
    description,
    intervalMinDays,
    intervalMaxDays,
    startDatetime,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={close}
      size="sm"
      style={{ minWidth: '500px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Add Content Review Rule
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="bold-text" style={{ marginBottom: '8px' }}>Name</Label>
          <Input
            disabled={loading}
            className="form-control-sm form-control"
            name="name"
            type="text"
            placeholder="Name"
            value={name || ''}
            onChange={p => setName(p.target.value)}
            style={{ height: '38px' }}
            invalid={validity.name === false}
          />
          {errors.name && (<span className="text-danger">{errors.name}</span>)}
        </FormGroup>
        <FormGroup>
          <Label className="bold-text" style={{ marginBottom: '8px' }}>Description</Label>
          <Input
            disabled={loading}
            className="form-control-sm form-control"
            name="description"
            type="text"
            placeholder="Description"
            value={description || ''}
            onChange={p => setDescription(p.target.value)}
            style={{ height: '38px' }}
            invalid={validity.description === false}
          />
          {errors.description && (<span className="text-danger">{errors.description}</span>)}
        </FormGroup>
        <FormGroup>
          <Label className="bold-text" style={{ marginBottom: '8px' }}>Brands</Label>
          <div style={{
            width: '100%',
            marginBottom: '14px',
          }}>
            <Select
              className="advertisers"
              menuPlacement="auto"
              placeholder="Brands"
              value={advertisers}
              onChange={setAdvertisers}
              options={brandOptions}
              isDisabled={loading || loadingBrandOptions}
              isMulti
            />
          </div>
          {errors.advertisers && (<span className="text-danger">{errors.advertisers}</span>)}
        </FormGroup>
        <FormGroup>
          <Label className="bold-text" style={{ marginBottom: '8px' }}>Deal Status</Label>
          <div style={{
            width: '100%',
            marginBottom: '14px',
          }}>
            <Select
              className="dealCPAStatuses"
              menuPlacement="auto"
              placeholder="Deal Status"
              value={dealStatuses}
              onChange={setDealStatuses}
              options={dealTypeOptions}
              isDisabled={loading}
              isMulti
            />
          </div>
          {errors.dealCPAStatuses && (<span className="text-danger">{errors.dealCPAStatuses}</span>)}
        </FormGroup>
        <div className="d-flex" style={{ width: '100%'}}>
          <div style={{ width: '47%' }}>
            <FormGroup>
              <Label className="bold-text" style={{ marginBottom: '8px' }}>Interval Min Days</Label>
              <Input
                type="number"
                id="intervalMinDays"
                name="intervalMinDays"
                style={{ width: '100%' }}
                min="0"
                step="1"
                invalid={validity.intervalMinDays === false}
                onChange={e => setIntervalMinDays(e.target.value ? parseInt(e.target.value, 10) : 0)}
                value={intervalMinDays}
                disabled={loading}
              />
              {errors.intervalMinDays && (<span className="text-danger">{errors.intervalMinDays}</span>)}
            </FormGroup>
          </div>
          <div style={{ width: '6%' }} />
          <div style={{ width: '47%'}}>
            <FormGroup>
              <Label className="bold-text" style={{ marginBottom: '8px' }}>Interval Max Days</Label>
              <Input
                type="number"
                id="intervalMaxDays"
                name="intervalMaxDays"
                style={{ width: '100%' }}
                min="0"
                step="1"
                invalid={validity.intervalMaxDays === false}
                onChange={e => setIntervalMaxDays(e.target.value ? parseInt(e.target.value, 10) : 0)}
                value={intervalMaxDays}
                disabled={loading}
              />
              {errors.intervalMaxDays && (<span className="text-danger">{errors.intervalMaxDays}</span>)}
            </FormGroup>
          </div>
        </div>
        <FormGroup>
          <Label className="bold-text" style={{ marginBottom: '8px' }}>Start Datetime</Label>
          <div>
            <DatePicker
              onChange={setStartDatetime}
              selected={startDatetime}
              dateFormat="M/d/yyyy h:mmaa"
              placeholderText="Start Date"
              className="form-control"
              minDate={moment().add(1, 'hour').toDate()}
              showTimeInput
            />
          </div>
          {errors.startDatetime && (<span className="text-danger">{errors.startDatetime}</span>)}
        </FormGroup>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button color="primary" onClick={submit} disabled={disableSave || loading}>Save</Button>
        &nbsp;&nbsp;
        <Button color="secondary" onClick={close}>Cancel</Button>

      </ButtonToolbar>
    </Modal>
  );
};

AddContentReviewRuleModal.propTypes = {
  addNotice: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  loadingBrandOptions: PropTypes.bool.isRequired,
  brandOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withNotice(AddContentReviewRuleModal);
