import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const ContentReleaseDate = ({ record, updateRecordFunction, afterSaveFunc }) => {
  const { contentReleaseDate, _id } = record.params;
  const [selectedDate, setDate] = useState(contentReleaseDate?.currentDate ? moment(moment.utc(contentReleaseDate.currentDate).format('YYYY-MM-DD')).toDate() : null);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setDate(contentReleaseDate?.currentDate ? moment(moment.utc(contentReleaseDate.currentDate).format('YYYY-MM-DD')).toDate() : null);
  }, [contentReleaseDate]);

  const updateField = async (date) => {
    setDisabled(true);
    try {
      const data = await axios.post('/api/portal/prospects/post-update-property-on-cpm-prospect', {
        ids: [_id],
        values: {
          contentReleaseDate: moment.utc(moment(date).format('YYYY-MM-DD')).toDate(),
        },
      });
      if (data?.data?.recordPopulated && updateRecordFunction) {
        updateRecordFunction(data?.data?.recordPopulated);
      } else {
        updateRecordFunction(record.id);
        afterSaveFunc();
      }
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
    setDisabled(false);
  };

  return (
    <>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => {
          console.log(date);
          setDate(date);
          updateField(date);
        }}
        dateFormat="MMM d, yyyy"
        placeholderText="Select Content Release Date"
        dropDownMode="select"
        isClearable
        wrapperClassName="date-picker--interval w-100"
        className="form-control form-control-sm w-100"
        disabled={disabled}
      />
    </>
  );
};
ContentReleaseDate.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func,
  afterSaveFunc: PropTypes.func,
};
ContentReleaseDate.defaultProps = {
  updateRecordFunction: () => {},
  afterSaveFunc: () => {},
};
export default ContentReleaseDate;

