const ViewHelpers = require('./view-helpers').default;

const moment = require('moment');
const momenttz = require('moment-timezone');
const { getConstant } = require('../../../../shared/helpers/WVConstants');

function getAllItems(currentAdminId) {
  let isShow = false;
  const startDate = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
  const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
  const endMonthDate = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
  // const endDateNoTime = moment().endOf('day').format('YYYY-MM-DD');
  let contractSignedDealStage = '';
  let contractSentDealStage = '';
  if (typeof window !== 'undefined') {
    const dealstages = (getConstant('deal', 'pipelines').find(p => p.label === 'Main Pipeline'))?.stages || {};
    Object.entries(dealstages).forEach(([key, value]) => {
      if (value.toLowerCase() === 'contract sent') {
        contractSentDealStage = key;
      }
      if (value.toLowerCase() === 'contract signed') {
        contractSignedDealStage = key;
      }
    });
    isShow = !window.REDUX_STATE?.session?.teams?.includes('Auditors');
    // console.log('contractSentDealStage', contractSentDealStage);
  }
  const h = new ViewHelpers();
  const r = [
    {
      key: 'podcasts',
      title: 'Podcast',
      icon: 'layers',
      items: [{
        key: 'campaign',
        title: 'Campaign',
        route: h.resourceActionUrl({ resourceId: 'Campaign', actionName: 'list' }),
      }, {
        key: 'podcasts',
        title: 'Podcasts',
        route: h.resourceActionUrl({ resourceId: 'MediaChannel', actionName: 'list' }),
      }],
    }, {
      key: 'influencers',
      title: 'Creators',
      icon: 'users',
      items: [{
        key: 'influencers',
        title: 'Creators',
        route: h.resourceActionUrl({ resourceId: 'Influencer', actionName: 'list' }),
      }, {
        key: 'dealeditor',
        title: 'Deal Editor',
        route: '/influencers/creator-deal-editor',
      }, {
        key: 'contacts',
        title: 'Contacts',
        route: '/influencers/contacts',
      }, {
        key: 'deals',
        title: 'Deals',
        // eslint-disable-next-line max-len
        route: '/resources/HubilDeal/actions/list?filters.status=Active&filters.status=Pending&filters.status=Paid&filters.status=Expired&filters.dealTypes=Hybrid&filters.dealTypes=CPA%2FAmbassador&filters.dealTypes=Upfront+Only&filters.dealTypes=Upfront+CPM&filters.dealTypes=Agent+Commission&filters.dealTypes=Media+License&filters.dealTypes=Bonus',
      }, {
        key: 'notifications',
        title: 'Notifications',
        route: '/resources/PortalNotification/actions/list?direction=desc&sortBy=createdAt',
      }, {
        key: 'tagmanager',
        title: 'Tag Manager',
        route: '/influencer/tag-manager',
      }],
    }, {
      key: 'contentscoring',
      title: 'Compliance',
      icon: 'flag', // camera-video
      items: [
        {
          key: 'submitcontent',
          title: 'Submit Content',
          route: '/scorecard/media-content/new',
        },
        {
          key: 'mediacontent',
          title: 'Media Content',
          // eslint-disable-next-line max-len
          route: `/scorecard/content-dashboard?direction=desc&sortBy=createdAt&filters.createdAt.from=${startDate}&filters.createdAt.to=${endMonthDate}&page=1&filters.scorecardsCount=0&filters.scorecardsCount=1&filters.scorecardsCount=2&filters.scorecardsCount=3&perPage=10&filters.dealType=CPA%2FAmbassador&filters.dealType=Upfront+Only&filters.dealType=Hybrid&filters.dealType=Upfront+CPM&filters.dealType=Agent+Commission&filters.dealType=Media+License`,
        },
        {
          key: 'contentmanagement',
          title: 'Content Management',
          // eslint-disable-next-line max-len
          route: `/compliance/content-management?filters.createdAt.from=${moment().startOf('year').format('YYYY-MM-DD')}&filters.createdAt.to=${endMonthDate}&range=year_to_date&filters.status=New&filters.status=First+Score&filters.status=Second+Score&filters.status=Rescore&filters.status=Pending+Edits&filters.status=Awaiting+Brand+Approval&filters.status=Brand+Approved&filters.brandStatus=Not+Set&filters.brandStatus=In+Review&filters.brandStatus=Pending+Edits&filters.brandStatus=Approved&filters.brandStatus=Rejected&page=1`,
        },
        // {
        //   key: 'commentmonitoring',
        //   title: 'Comment Monitoring',
        //   // eslint-disable-next-line max-len
        //   route: `/scorecard/comment-monitoring${isShow ? `?page=1&range=current_month&filters.publishedAt.from=${startDate}&filters.publishedAt.to=${endMonthDate}&filters.sentiment=needsReview&filters.sentimentAnalyzer=positive&filters.sentimentAnalyzer=neutral&filters.sentimentAnalyzer=negative&filters.sentimentAnalyzer=mixed` : ''}`,
        // },
        {
          key: 'aicommentmonitoring',
          title: 'AI Comment Monitoring',
          // eslint-disable-next-line max-len
          route: `/scorecard/ai-comment-monitoring${isShow ? `?page=1&perPage=100&range=current_month&filters.publishedAt.from=${startDate}&filters.publishedAt.to=${endMonthDate}&filters.humanSentiment=unreviewed&filters.brandRelevancy=unreviewed&filters.aiSentimentScoreQ=pos0--0.95%2Fneg0--0.95%2Fig0--0.95&filters.aiBrandScoreQ=rel0--0.95%2Fig0--0.95&filters.mediaContentType=youtube&filters.mediaContentType=youtubeDescription` : ''}`,
        },
        {
          key: 'contenturlvalidation',
          title: 'Content URL Validation',
          // timezone watch activated per https://influencelogic.atlassian.net/browse/IL-4804
          // eslint-disable-next-line max-len
          route: `/scorecard/content-url-validation?page=1&range=year_to_date&filters.brandApprovedDate.from=${encodeURIComponent(momenttz(moment().startOf('year')).tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))}&filters.brandApprovedDate.to=${encodeURIComponent(momenttz(endDate).tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))}&filters.urlStatus=Needs+Review`,
        },
        {
          key: 'contentreview',
          title: 'Historical Content Review',
          // eslint-disable-next-line max-len
          route: '/scorecard/content-review?page=1&filters.status=Brand+Approved&filters.reviewStatus=Needs+Review',
        },
        {
          key: 'creatorsafetyreview',
          title: 'Creator Safety Review',
          // eslint-disable-next-line max-len
          route: `/compliance/creator-safety-review?creator=1&page=1&range=year_to_date&filters.lastReviewDate.from=${moment().startOf('year').format('YYYY-MM-DD')}&filters.lastReviewDate.me=${endMonthDate}&lastReviewDateType=Between&filters.reviewStatus=Needs+Review&filters.reviewStatus=Pending+Edits&direction=desc&sortBy=lastReviewDate`,
        },
        // {
        //   key: 'youtubecomments',
        //   title: 'YouTube Video Comments',
        //   // eslint-disable-next-line max-len
        //   route: `/scorecard/comments-dashboard${isShow ? `?page=1&range=current_month&filters.publishedAt.from=${startDate}&filters.publishedAt.to=${endMonthDate}&filters.type=thread&filters.type=comment` : ''}`,
        // },
        // {
        //   key: 'scorecards',
        //   title: 'Scorecards',
        //   route: '/scorecard/scorecard-answers-dashboard?direction=desc&sortBy=scoreDate',
        // },
        {
          key: 'scorecarddefinitions',
          title: 'Scorecard Definitions',
          route: '/scorecard/scorecard-dashboard',
        }, {
          key: 'compliancereports',
          title: 'Reports',
          items: [
            {
              key: 'compliancemetrics',
              title: 'Metrics',
              route: '/compliance/metrics-dashboard',
            },
            {
              key: 'payment-verification',
              title: 'Payment Authorization',
              route: '/reports/compliance/payment-authorization-report',
            },
            {
              key: 'compliance-cpa-fraud-detection',
              title: 'CPA Fraud Detection',
              route: '/reports/compliance/cpa-fraud-detection',
            },
          ],
        },
      ],
    }, {
      key: 'cpm',
      title: 'CPM Program',
      icon: 'camera-video',
      items: [
        {
          key: 'cpmaddcreator',
          title: 'Add Deal',
          route: '/research/add-cpm-creator',
        },
        {
          key: 'cpmpricing',
          title: 'Price Approvals',
          route: '/research/cpm-pricing-approvals',
        },
        {
          key: 'cpmpricingipt',
          title: 'Price Approvals Review',
          route: '/research/cpm-pricing-approvals-ipt',
        },
        {
          key: 'cpmpriceapprovals',
          title: 'Price Approval Groups',
          route: '/research/cpm-price-approvals-groups',
        },
        {
          key: 'cpmcreators',
          title: 'CPM Deals',
          // eslint-disable-next-line max-len
          route: `/research/cpmcreators?filters.approvalStatus=approved&filters.approvalsBrandStatus=approved&filters.dealAssigned=Assigned&filters.dealAssigned=Not+Assigned&filters.insertionOrderAssigned=Not+Assigned&filters.dealStages=${contractSentDealStage}&filters.dealStages=${contractSignedDealStage}`,
        },
        {
          key: 'cpminsertionorders',
          title: 'Insertion Orders',
          route: '/research/cpm-insertion-order',
        },
        {
          key: 'invoices',
          title: 'Invoices',
          route: '/research/cpm-invoice',
        },
      ],
    }, {
      key: 'research',
      title: 'Leads',
      icon: 'magnifier',
      items: [
        {
          key: 'researchsearch',
          title: 'Creator Search',
          route: '/research/search',
        },
        {
          key: 'researchprospects',
          title: 'Prospects',
          // eslint-disable-next-line max-len
          route: '/research/prospects?filters.approvalStatusinfluencelogicstatus=Approved&filters.approvalStatusinfluencelogicstatus=Hold&filters.approvalStatusinfluencelogicstatus=In+Review&filters.approvalStatusinfluencelogicstatus=Mining&filters.approvalStatusinfluencelogicstatus=Resubmit&filters.approvalStatusinfluencelogicstatus=Rejected&filters.approvalStatusbrandstatus=Approved&filters.approvalStatusbrandstatus=In+Review&filters.approvalStatusbrandstatus=Hold&filters.approvalStatusbrandstatus=Rejected&filters.approvalStatusbrandstatus=In+Appeals&filters.existStatus=info&filters.existStatus=warning&filters.existStatus=noMatch&filters.analyst=Assigned&filters.analyst=Not+Assigned&filters.program=Not+Assigned&filters.program=CPA&filters.program=CPM&filters.prospectType=Standard&filters.prospectType=Internal',
        },
        {
          key: 'researchinternalprospects',
          title: 'Internal Prospects',
          // eslint-disable-next-line max-len
          route: `/research/internal-prospects?filters.prospectType=Standard&filters.prospectType=Internal&filters.approvalStatusinfluencelogicstatus=Approved&filters.approvalStatusinfluencelogicstatus=Hold&filters.approvalStatusinfluencelogicstatus=In+Review&filters.approvalStatusinfluencelogicstatus=Mining&filters.approvalStatusinfluencelogicstatus=Resubmit&filters.approvalStatusinfluencelogicstatus=Rejected&filters.approvalStatusbrandstatus=Approved&filters.approvalStatusbrandstatus=In+Review&filters.approvalStatusbrandstatus=Hold&filters.approvalStatusbrandstatus=Rejected&filters.approvalStatusbrandstatus=In+Appeals&filters.existStatus=info&filters.existStatus=warning&filters.existStatus=noMatch&filters.prospectOwners=${currentAdminId}`,
        },
        {
          key: 'researchuploadqueue',
          title: 'Prospect Uploads',
          route: '/research/upload-queue?customFilters.uploadProspects=true',
        },
        {
          key: 'researchmining',
          title: 'Mining',
          // eslint-disable-next-line max-len
          route: `/research/mining?filters.approvalStatusinfluencelogicstatus=Mining&filters.existStatus=info&filters.existStatus=warning&filters.existStatus=noMatch&filters.program=Not+Assigned&filters.program=CPA&filters.program=CPM&filters.analystUsers=${currentAdminId}`,
        },
        {
          key: 'researchleads',
          title: 'Import Leads',
          // eslint-disable-next-line max-len
          route: '/research/leads?filters.status=Lead&filters.existStatusLead=info&filters.existStatusLead=noMatch&filters.contactOwnerAssigned=Assigned&filters.contactOwnerAssigned=Not+Assigned&filters.dealOwnerAssigned=Assigned&filters.dealOwnerAssigned=Not+Assigned&filters.targetBrandAssigned=Assigned&filters.targetBrandAssigned=Not+Assigned',
        },
        {
          key: 'recycleleads',
          title: 'Recycle Leads',
          route: '/research/recycle-leads',
        },
        {
          key: 'researchcreators',
          title: 'Creator Watchlists',
          route: '/research/creators',
        },
        {
          key: 'researchwatchlists',
          title: 'Settings',
          route: '/research/settings',
        },
        {
          key: 'leadreports',
          title: 'Reports',
          items: [
            {
              key: 'leadoperations',
              title: 'Metrics',
              route: '/research/lead-operations-dashboard',
            },
          ],
        },
      ],
    }, {
      key: 'brands',
      title: 'Brands',
      icon: 'store',
      items: [{
        key: 'brands',
        title: 'Brands',
        route: '/resources/Advertiser/actions/list',
      }, {
        key: 'brandusers',
        title: 'Users',
        route: h.resourceActionUrl({ resourceId: 'BrandUser', actionName: 'list' }),
      }, {
        key: 'opportunities',
        title: 'Opportunities',
        route: h.resourceActionUrl({ resourceId: 'BrandOpportunity', actionName: 'list' }),
      }, {
        key: 'resources',
        title: 'Resources',
        route: h.resourceActionUrl({ resourceId: 'BrandResource', actionName: 'list' }),
      }],
    },
    {
      key: 'agents',
      title: 'Agents',
      icon: 'chart-bars',
      items: [{
        key: 'agencies',
        title: 'Agencies',
        route: h.resourceActionUrl({ resourceId: 'Agency', actionName: 'list' }),
      },
      {
        key: 'agentusers',
        title: 'Agents',
        route: h.resourceActionUrl({ resourceId: 'AgentUser', actionName: 'list' }),
      }],
    },
    {
      key: 'finance',
      title: 'Finance',
      icon: 'briefcase', // store
      items: [{
        key: 'payables',
        title: 'Payables',
        items: [
          {
            key: 'accountspayable2',
            title: 'Accounts Payable',
            route: '/finance/account-payable-report-2/',
          },
          {
            key: 'billlist',
            title: 'Bills',
            route: '/resources/Bill/actions/list',
          },
          // {
          //   key: 'billlistnewversion',
          //   title: 'Bills',
          //   route: '/finance/bills',
          // },
        ],
      }, {
        key: 'receivables',
        title: 'Receivables',
        items: [
          {
            key: 'accountsreceivable',
            title: 'Accounts Receivable',
            route: '/finance/account-receivable-report/',
          },
          {
            key: 'invoicelist',
            title: 'Invoices',
            route: '/resources/Invoice/actions/list',
          },
        ],
      }, {
        key: 'payment-methods',
        title: 'Payment Methods',
        items: [
          {
            key: 'stripe',
            title: 'Stripe',
            route: '/finance/payment-methods/stripe',
          },
        ],
      }, {
        key: 'reports',
        title: 'Reports',
        items: [
          {
            key: 'eom-report',
            title: 'Revenue & Costs',
            route: '/reports/eom-report-affiliate',
          },
          {
            key: 'bill-report-audit',
            title: 'Unpaid Payables',
            route: '/reports/bill-audits',
          }
        ],
      }, {
        key: 'taxforms',
        title: 'Tax Forms',
        route: '/finance/tax-form-management',
      }],
    }, {
      key: 'reports',
      title: 'Reports',
      icon: 'inbox',
      items: [{
        key: 'management',
        title: 'Management',
        items: [
          {
            key: 'company-overview',
            title: 'Company Overview',
            route: '/reports/company-report',
          },
          {
            key: 'powl5cs',
            title: 'POWL 5Cs Report',
            route: '/reports/management/powl-5cs',
          },
        ],
      }, {
        key: 'summary',
        title: 'Company Summary',
        items: [
          {
            key: 'summary',
            title: 'Revenue & Profit ',
            route: '/reports/summary-report3',
          },
          {
            key: 'brand5cs',
            title: '5Cs',
            route: '/reports/5c',
          },
        ],
      }, {
        key: 'creators',
        title: 'Creator Performance',
        items: [
          {
            key: 'cp-by-deal-owner',
            title: 'By Account Manager',
            route: '/reports/creators-report-by-csp',
          },
          {
            key: 'cp-by-creator',
            title: 'By Creator',
            route: '/reports/creators-report-by-creator',
          },
          {
            key: 'cp-by-csp',
            title: 'By Brand',
            route: '/reports/creators-report-by-brand',
          },
          {
            key: 'new-creators',
            title: 'New Creators',
            route: '/reports/new-creators-report',
          },
          {
            key: 'future-new-vs-existing',
            title: 'Future New vs. Existing',
            route: '/reports/future-new-vs-existing',
          },
          {
            key: 'creators-test-to-win',
            title: 'Test to Win',
            route: '/reports/creators-test-to-win',
          },
          {
            key: 'cp',
            title: 'Creator Performance (Legacy)',
            route: '/reports/creators-report2',
          },
        ],
      }, {
        key: 'categories',
        title: 'Category Performance',
        items: [
          {
            key: 'conversionsbybrand',
            title: 'By Brand',
            route: '/reports/category-by-brand',
          },
          {
            key: 'conversionsbycreator',
            title: 'By Creator',
            route: '/reports/category-by-creator',
          },
        ],
      }, {
        key: 'brandperformance',
        title: 'Brand Performance',
        route: '/reports/brand-performance',
      }, /* {
        key: 'leadsperformance',
        title: 'Leads Performance',
        route: '/reports/leads-report',
      }, */
      {
        key: 'impact',
        title: 'Impact',
        items: [
          {
            key: 'salesactivity',
            title: 'Sales Report',
            route: `/reports/sales-activity?filters.eventDate~~from=${startDate}&filters.eventDate~~to=${endDate}`,
          }, {
            key: 'clicksactivity',
            title: 'Clicks Report',
            route: `/reports/clicks-activity?filters.eventDate~~from=${startDate}&filters.eventDate~~to=${endDate}`,
          },
        ],
      },
      {
        key: 'statistics',
        title: 'Statistics',
        items: [
          {
            key: 'videocomments',
            title: 'Video Comments',
            items: [
              {
                key: 'brandcommentsanalysis',
                title: 'Brand Comments Analysis',
                route: '/reports/brand-comments-analysis',
              },
              {
                key: 'creatorcommentsanalysis',
                title: 'Creator Comments Analysis',
                route: `/reports/creator-comments-analysis`,
              },
              {
                key: 'videocommentsanalysis',
                title: 'Video Comments Analysis',
                route: `/reports/video-comments-analysis`,
              },
            ],
          }, {
            key: 'videostatistics',
            title: 'Video Statistics',
            route: `/reports/video-statistics?filters.publishedAt.from=${startDate}&filters.publishedAt.to=${endDate}`,
          }, {
            key: 'cpmconversionslist',
            title: 'CPM Conversions',
            route: h.resourceActionUrl({ resourceId: 'CpmConversion', actionName: 'list' }),
          }, {
            key: 'youtubecommentsanalysis',
            title: 'YouTube Comments Analysis',
            route: `/reports/youtube-comments-analysis?filters.publishedAt~~from=${startDate}&filters.publishedAt~~to=${endDate}`,
          }, {
            key: 'ytvideocommentsbreakdown',
            title: 'YouTube Video Comments Breakdown',
            route: '/reports/yt-video-comments-breakdown',
          },
        ],
      },
      {
        key: 'rebuytriggers',
        title: 'Rebuy',
        items: [
          {
            key: 'rebuytriggers',
            title: 'Rebuy Report',
            route: '/reports/rebuy-report',
          },
          {
            key: 'keenrebuytriggers',
            title: 'Keen Rebuy Trigger',
            route: '/reports/rebuy-triggers',
          },
        ],
      },

      {
        key: 'audit',
        title: 'Audit',
        items: [
          {
            key: 'duplicateactivedeals',
            title: 'Duplicate Active Deals',
            route: '/reports/duplicate-active-deals',
          },
          {
            key: 'eom-issues-report',
            title: 'Conversions Audit Report',
            route: '/reports/conversions-audit-report',
          },
          {
            key: 'auditpublic',
            title: 'Deals Audit ',
            route: '/reports/audit-public-v2',
          },
          {
            key: 'auditcontacts',
            title: 'Contact Audit ',
            route: '/reports/contact-audits?perPage=10&page=1&direction=desc&sortBy=count',
          },
          {
            key: 'mergeaudit',
            title: 'Merging',
            items: [
              {
                key: 'dealmerge',
                title: 'Deal Merge Audit',
                route: '/reports/deal-merge-audit-report',
              },
              {
                key: 'contactmerge',
                title: 'Contact Merge Audit',
                route: `/reports/contact-merge-audit-report`,
              },
              {
                key: 'influencer merge',
                title: 'Influencer Merge Audit',
                route: `/reports/influencers-merge-audit-report`,
              },
            ],
          },
          {
            key: 'contactmerge',
            title: 'Multiple Contacts Audit',
            route: '/reports/deal-contacts-audit-report',
          },
        ],
      }, {
        key: 'saved-report',
        title: 'Saved Reports',
        route: '/reports/saved-report',
      }, {
        key: 'settings',
        title: 'Settings',
        route: '/reports/settings',
      }],
    }, {
      key: 'thirdpartyintegrations',
      title: 'Third-Party Integrations',
      icon: 'license',
      items: [{
        key: 'impact',
        title: 'Impact',
        items: [{
          key: 'actions',
          title: 'Actions',
          route: h.resourceActionUrl({ resourceId: 'ImpactAction', actionName: 'list' }),
        }, {
          key: 'actionitems',
          title: 'Action Items',
          route: h.resourceActionUrl({ resourceId: 'ImpactActionItem', actionName: 'list' }),
        }],
      }],
    }, {
      key: 'admins',
      title: 'Admin',
      icon: 'magic-wand', // users
      items: [{
        key: 'adminusers',
        title: 'Users',
        route: h.resourceActionUrl({ resourceId: 'AdminUser', actionName: 'list' }),
      }, {
        key: 'adminteams',
        title: 'Teams',
        route: '/admin/teams',
      }, {
        key: 'employees',
        title: 'Employees',
        route: h.resourceActionUrl({ resourceId: 'Employee', actionName: 'list' }),
      }, {
        key: 'hasoffers',
        title: 'HasOffers',
        items: [{
          key: 'conversions',
          title: 'HasOffers Conversions',
          route: '/has-offers/conversions',
        }, {
          key: 'dailyconversiontotals1',
          title: 'Daily Conversion Totals',
          route: '/statistics/total-by-brand',
        }, {
          key: 'cpmconversions',
          title: 'CPM Conversions',
          route: '/has-offers/cpm-conversions',
        }, {
          key: 'cpmconversionuploads',
          title: 'CPM Conversions Uploads',
          route: h.resourceActionUrl({ resourceId: 'CpmConversionUpload', actionName: 'list' }),
        }],
      }, {
        key: 'paymentmethods',
        title: 'Payment Methods',
        items: [{
          key: 'stripe',
          title: 'Stripe',
          items: [{
            key: 'stripeevents',
            title: 'Log',
            route: h.resourceActionUrl({ resourceId: 'StripeEvent', actionName: 'list' }),
          }, {
            key: 'stripepayouts',
            title: 'Dashboard',
            route: '/stripe/payouts',
          }],
        }, {
          key: 'billcomdashboard',
          title: 'Bill.com',
          route: '/billCom/dashboard',
        }],
      }, {
        key: 'domainmanagement',
        title: 'Domain Management',
        items: [{
          key: 'redirectdomains',
          title: 'Redirect Domains',
          route: h.resourceActionUrl({ resourceId: 'RedirectDomain', actionName: 'list' }),
        }, {
          key: 'vanityurls',
          title: 'Vanity URLs',
          route: h.resourceActionUrl({ resourceId: 'VanityUrl', actionName: 'list' }),
        }],
      }, {
        key: 'hubil',
        title: 'HubIL',
        items: [{
          key: 'hubilcontacts',
          title: 'HubIL Contacts',
          route: h.resourceActionUrl({ resourceId: 'HubilContact', actionName: 'list' }),
        }, {
          key: 'hubilagents',
          title: 'HubIL Agents',
          route: h.resourceActionUrl({ resourceId: 'HubilAgent', actionName: 'list' }),
        }, {
          key: 'hubildeals',
          title: 'HubIL Deals',
          // eslint-disable-next-line max-len
          route: '/resources/HubilDeal/actions/list?filters.status=Active&filters.status=Pending&filters.status=Paid&filters.status=Expired&filters.dealTypes=Hybrid&filters.dealTypes=CPA%2FAmbassador&filters.dealTypes=Upfront+Only&filters.dealTypes=Upfront+CPM&filters.dealTypes=Agent+Commission&filters.dealTypes=Media+License&filters.dealTypes=Bonus',
        }, {
          key: 'hubilevents',
          title: 'HubIL Events',
          route: '/resources/HubILEvent/actions/list?direction=desc&sortBy=occurredAt',
        }, {
          key: 'hubildealpipeline',
          title: 'HubIL Deal Pipelines',
          route: '/resources/HubilDealPipeline/actions/list?direction=desc&sortBy=occurredAt',
        }],
      }, {
        key: 'scheduledjobs',
        title: 'Jobs',
        route: '/resources/ScheduledJob/actions/list?filters.type=general',
      }, {
        key: 'emailsettings',
        title: 'Email Templates',
        route: h.resourceActionUrl({ resourceId: 'EmailTemplate', actionName: 'list' }),
      }, {
        key: 'serviceagreements',
        title: 'Service Agreements',
        items: [{
          key: 'customserviceagreements',
          title: 'Custom Service Agreements',
          route: h.resourceActionUrl({ resourceId: 'CustomAgreementTemplate', actionName: 'list' }),
        }],
      }, {
        key: 'docusign',
        title: 'DocuSign',
        items: [{
          key: 'settings',
          title: 'Settings',
          route: '/docusign-settings',
        }, {
          key: 'download',
          title: 'Download Agreements',
          route: '/docusign/download',
        }],
      }, {
        key: 'dictionary',
        title: 'Dictionary',
        route: h.resourceActionUrl({ resourceId: 'Dictionary', actionName: 'list' }),
      }, {
        key: 'statisticsitem',
        title: 'Statistics',
        items: [{
          key: 'statisticslist',
          title: 'Statistics List',
          route: h.resourceActionUrl({ resourceId: 'Statistic', actionName: 'list' }),
        }],
      }, {
        key: 'pushnotifications',
        title: 'Mobile',
        route: '/mobile/push-notifications',
      }, {
        key: 'promocodes',
        title: 'Promo Codes',
        route: '/promocodes',
      }, {
        key: 'log',
        title: 'Developer',
        route: h.resourceActionUrl({ resourceId: 'Log', actionName: 'list' }),
      }, {
        key: 'extension',
        title: 'Chrome Extension',
        route: '/extension',
      }, {
        key: 'googleapi',
        title: 'Google Files',
        route: '/google/api',
      }, {
        key: 'metrics',
        title: 'Metrics',
        items: [{
          key: 'tubular',
          title: 'Tubular',
          route: '/tubular/api',
        }, {
          key: 'hubspotapi',
          title: 'Hubspot',
          route: '/hubspot/api',
        }],
      }],
    },
  ];
  return r;
}
function allowedItems() {
  const items = getAllItems();
  const returnValues = [];
  items.forEach((item) => {
    returnValues.push({ value: item.key, label: item.title });
    if (!item.items) return false;
    item.items.forEach((subItem) => {
      returnValues.push({ value: `${item.key}----${subItem.key}`, label: subItem.title });
      if (subItem.items) {
        subItem.items.forEach((subSubItem) => {
          returnValues.push({ value: `${item.key}----${subItem.key}----${subSubItem.key}`, label: subSubItem.title });
          if (subSubItem.items) {
            subSubItem.items.forEach((subSubSubItem) => {
              returnValues.push({ value: `${item.key}----${subItem.key}----${subSubItem.key}----${subSubSubItem.key}`, label: subSubSubItem.title });
            });
          }
        });
      }
    });
    return true;
  });
  return returnValues;
}

exports.getAllItems = getAllItems;
exports.allowedItems = allowedItems;

