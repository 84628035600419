/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip as TooltipOnLine,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { formatIntNumber } from '../../../../shared/helpers/WVFormatter';

const colorOptions = [
  '#0088FE',
];

const chartMargins = {
  top: 5,
  right: 15,
  left: 30,
  bottom: 40,
};

const CustomizedTooltip = (props) => {
  const getText = (name, value) => `${name}: ${formatIntNumber(value)}`;
  if (props.active && props.payload && props.payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'hsla(0, 0%, 100%, .9)',
          border: '1px solid #f5f5f5',
          lineHeight: '24px',
          margin: '0',
          padding: '10px',
        }}
      >
        <p className="bold-text">{props.label}</p>
        <ul
          style={{
            listStyle: 'none',
            padding: '0',
            margin: '0',
          }}
        >
          {props.payload && (
            <li
              key="count"
              style={{
                color: colorOptions[0],
              }}
            >
              {getText('Number of Comments', props.payload[0].value)}
            </li>
          )}
        </ul>
      </div>
    );
  }
  return null;
};

const CustomizedXAxisTick = ({ x, y, payload }) => {
  const values = [16, 26, 40];
  const children = payload.value?.includes('-')
    ? payload.value.split(' ').map(((string, index) => (
      <text
        key={`textKey${new Date().getTime()}-${string}`}
        x={0}
        y={0}
        dy={values[index]}
        textAnchor="middle"
        fill="#666"
      >
        {string}
      </text>
    ))) : (
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    );
  return (
    <g transform={`translate(${x},${y})`}>
      {children}
    </g>
  );
};

const CustomizedYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={0} textAnchor="end" fill="#666">
      {formatIntNumber(payload.value)}
    </text>
  </g>
);

const CustomizedLabel = ({
  x, y, stroke, value,
}) => (
  <text x={x} y={y} dy={-4} fill={stroke} fontSize={13} textAnchor="middle" fontWeight="bold">
    {value > 0 ? formatIntNumber(value) : ''}
  </text>
);

const ChartComponentCommentsTotal = ({
  periodsData, showLabels,
}) => {
  const [opacity, setOpacity] = useState({
    count: 1,
  });

  const handleMouseEnter = (dataKey) => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(companyName => localOpacity[companyName] = companyName === dataKey ? 1 : 0.2);
    setOpacity(localOpacity);
  };

  const handleMouseLeave = () => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(companyName => localOpacity[companyName] = 1);
    setOpacity(localOpacity);
  };

  return (
    <Row>
      <Col>
        {periodsData.length ? (
          <Row>
            <Col md={10} style={{ paddingRight: '0' }}>
              <ResponsiveContainer
                width="100%"
                height={400}
                debounce={1}
              >
                <LineChart
                  width="100%"
                  height="100%"
                  data={periodsData}
                  margin={chartMargins}
                >
                  <Line
                    key="lineCount"
                    type="monotone"
                    dataKey="count"
                    stroke={colorOptions[0]}
                    strokeOpacity={opacity.count || 1}
                    strokeWidth={2}
                    dot={showLabels}
                    label={showLabels ? <CustomizedLabel /> : null}
                  />
                  <XAxis
                    dataKey="label"
                    padding={{ left: 0, right: 8 }}
                    tick={<CustomizedXAxisTick />}
                    interval="preserveStartEnd"
                  />
                  <YAxis
                    padding={{ top: 8, bottom: 0 }}
                    tick={<CustomizedYAxisTick />}
                    domain={['dataMin', 'dataMax']}
                    interval="preserveStartEnd"
                    allowDecimals={false}
                  />
                  <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                  <TooltipOnLine content={<CustomizedTooltip />} />
                </LineChart>
              </ResponsiveContainer>
            </Col>
            <Col
              md={2}
              className="d-flex align-items-center justify-content-center"
            >
              <div>
                <ul
                  style={{
                    listStyle: 'none',
                    paddingLeft: '0',
                  }}
                >
                  <li
                    key="legendCount"
                    style={{
                      color: colorOptions[0],
                      cursor: 'pointer',
                      fontWeight: '600',
                    }}
                    onMouseEnter={() => handleMouseEnter('count')}
                    onMouseLeave={handleMouseLeave}
                  >
                    &#9903;  Total number of Comments
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        ) : (
          <span className="mt-2">No data to chart</span>
        )}
      </Col>
    </Row>
  );
};

ChartComponentCommentsTotal.propTypes = {
  periodsData: PropTypes.arrayOf(PropTypes.any),
  showLabels: PropTypes.bool.isRequired,
};

ChartComponentCommentsTotal.defaultProps = {
  periodsData: [],
};

export default ChartComponentCommentsTotal;
