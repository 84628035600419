import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import AccountPlusOutlineIcon from 'mdi-react/AccountPlusOutlineIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { getAdditionalContacts } from '../../../../../redux/reducers/admin/AgentReducer';
import AdditionalContactInList from './AdditionalContactInList';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';

const AdditionalContacts = ({
  match,
  dispatch,
  loading,
  additionalContacts,
  currentAgent,
}) => {
  const [showNew, setShowNew] = useState(false);

  useEffect(() => {
    if (match.params && match.params.agentId) {
      dispatch(getAdditionalContacts(match.params.agentId));
    }
  }, []);

  return (
    <div className="mb-2">
      <Row style={{ paddingLeft: '15px' }}>
        <h4 className="mb-1" style={{ paddingTop: '6px', paddingRight: '8px' }}>Additional Contacts</h4>
        {(additionalContacts.length < 5 && !showNew) && (
          <Tooltip title="Add Contact">
            <IconButton
              disabled={loading}
              style={{ color: '#70bbfd' }}
              size="small"
              onClick={() => setShowNew(true)}
            >
              <AccountPlusOutlineIcon style={{ width: '24px', height: '24px' }} />
            </IconButton>
          </Tooltip>
        )}
      </Row>
      {loading ? (
        <Row>
          <Col>
            <div className="text-center"><Spinner color="primary" size="lg" /></div>
          </Col>
        </Row>
      ) : (
        <>
          {(additionalContacts.length > 0 || showNew) && (
            <Row>
              <Col sm={9} style={{ paddingTop: '2px', paddingBottom: '8px' }}>
                <Row>
                  <Col sm={3}>
                    <h5>First Name</h5>
                  </Col>
                  <Col sm={3}>
                    <h5>Last Name</h5>
                  </Col>
                  <Col sm={3}>
                    <h5>Email</h5>
                  </Col>
                  <Col sm={3}>
                    <h5>Title</h5>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {showNew && (
            <AdditionalContactInList
              agentId={match.params.agentId}
              isNew
              setShowNew={setShowNew}
            />
          )}
          {additionalContacts.length > 0 && additionalContacts.map(contact => (
            <AdditionalContactInList
              agentId={match.params.agentId}
              record={contact}
            />
          ))}
          {additionalContacts.length === 0 && (
            <Alert color="warning">
              <p className="py-2 text-dark">
                {`No additional contacts specified for ${currentAgent.first_name} ${currentAgent.last_name}.`}
              </p>
            </Alert>
          )}
        </>
      )}
    </div>
  );
};
AdditionalContacts.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  currentAgent: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  additionalContacts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  dispatch: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  loading: state.AgentReducer.loading.additionalContacts,
  additionalContacts: state.AgentReducer.additionalContacts,
  currentAgent: state.AgentReducer.currentAgent,
});

export default connect(mapStateToProps)(withRouter(AdditionalContacts));
