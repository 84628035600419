/* eslint react/prop-types: 0 */
import React from 'react';
// import { axios } from 'ApiClient';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import ActionHeader from '../../../../shared/components/components/app/action-header';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
import UserPropertyType from './components/custom';
import Filter from '../../../../shared/components/components/app/filter';

class ResourceAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterVisible: false, // queryHasFilter(props.location.search),
    };
  }
  setChildMethod = (setRefresh, context) => {
    this.refreshMatTable = setRefresh.bind(context);
  }

  render() {
    const {
      resources,
    } = this.props;

    const {
      tag,
      filterVisible,
    } = this.state;
    const resource = resources.find(r => r.id === 'BrandOpportunity');
    if (!resource) {
      return (<NoResourceError resourceId="BrandOpportunity" />);
    }
    const action = resource.resourceActions.find(r => r.name === 'list');
    if (!action) {
      return (<NoActionError resourceId="BrandOpportunity" actionName="list" />);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs resource={resource} actionName="list" />
            <ActionHeader
              resource={resource}
              action={action}
              tag={tag}
              toggleFilter={() => this.setState({ filterVisible: !filterVisible })}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button color="primary" className="btn btn-sm btn-primary float-right" onClick={() => { document.location = '/brand-opportunities/ordering'; }}>Change Order of Opportunities</Button>
            <Button style={{ marginRight: '10px' }} color="primary" className="btn btn-sm btn-primary float-right" onClick={() => { document.location = '/brand-opportunities/new'; }}>Create New Opportunity</Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <MatTableList
              action={action}
              resource={resource}
              setTag={state => this.setState({ tag: state })}
              date={new Date()}
              UserPropertyType={UserPropertyType}
              sortBy="brand"
              direction="asc"
              useFlex
              isResizable={false}
              columnsSettings={{
                order: {
                  width: 150,
                },
                brand: {
                  width: 150,
                },
                title: {
                  width: 300,
                },
                description: {
                  disableSortBy: true,
                  width: 500,
                  // eslint-disable-next-line react/no-danger
                  Cell: r => (<div dangerouslySetInnerHTML={{ __html: r.row.original?.params?.description }} />),
                },
                buttons: {
                  disableSortBy: true,
                  width: 100,
                },
              }}
              setRefresh={this.setChildMethod}
              showFilters={false}
              v={2}
              hideToolbar
            />
          </Col>
        </Row>
        {action.showFilter ? (
          <Filter
            resource={resource}
            isVisible={filterVisible}
            toggleFilter={() => this.setState({ filterVisible: !filterVisible })}
          />
        ) : ''}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  resources: state.resources,
  loading: state.hubilReducer.loading.fetchDeals,
  role: state.session.role,
});

export default connect(mapStateToProps)(ResourceAction);
