import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup,
  Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, UncontrolledButtonDropdown,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { axios } from 'ApiClient';
import Select from 'react-select';
import withNotice from '../../../../../App/store/with-notice';
import csvHelper from '../../../../../../shared/helpers/CSVHelper';
import {
  formatCurrency, formatIntNumber, formatIntNumberAndAddPercentSign,
} from '../../../../../../shared/helpers/WVFormatter';

const Filters = ({ allItems, setFilteredItems, addNotice }) => {
  const [search, setSearch] = useState('');
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [brandsOptions, setBrandsOptions] = useState([]);
  let itemsForDownload = allItems;

  const processContent = () => {
    if (allItems.length > 0 && (search || (selectedBrands && selectedBrands.length > 0))) {
      const items = [];
      for (let i = 0; i < allItems.length; i += 1) {
        if (search) {
          if (allItems[i].content.fullSearchName?.toLowerCase().includes(search.toLowerCase())) {
            items.push(allItems[i]);
          }
        } else if (selectedBrands && selectedBrands.length > 0 && selectedBrands.findIndex(element => element.value === allItems[i].content.advertiser._id) !== -1) {
          items.push(allItems[i]);
        }
      }
      itemsForDownload = items;
      setFilteredItems(items);
    } else {
      itemsForDownload = allItems;
      setFilteredItems(allItems);
    }
  };

  useEffect(() => {
    setLoadingBrands(true);
    axios.get('/api/portal/finance/get-advertisers').then((resp) => {
      setBrandsOptions(resp.data.advertisers.filter(b => b.displayInUi === 'Yes')
        .sort((a, b) => (a.companyName > b.companyName ? 1 : -1))
        .map(a => ({ value: a._id, label: a.companyName })));
    }).catch(() => {
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
    setLoadingBrands(false);
    itemsForDownload = allItems;
  }, []);

  useEffect(() => {
    processContent();
  }, [search, selectedBrands]);

  const resetFilters = () => {
    setSearch('');
    setSelectedBrands([]);
    itemsForDownload = allItems;
  };

  const downloadCSV = () => {
    const data = [];
    data.push(['Creator Name', 'Creator Company', 'Creator Email', 'Brand', 'Video Title', 'Video URL', 'Publish Date', 'Upfront Cost', 'Total Views',
      'Total Comments', 'Positive Comments', 'Positive Percentage', 'Negative Comments', 'Negative Percentage', 'HubSpot Deal URL']);
    // console.log(itemsForDownload);
    itemsForDownload.forEach((item) => {
      data.push([
        `${item.content.influencer.first_name || ''} ${item.content.influencer.last_name || ''}`,
        item.content.influencer.companyName,
        item.content.influencer.email,
        item.content.advertiser.companyName,
        item.content.title || '',
        item.content.mediaUrl,
        item.content.publishDate || '',
        formatCurrency(item.content.deal.dealTerms.guaranteed_payment_contract_amount || 0),
        item.views || 0,
        formatIntNumber(Number(item.commentsData.totalComments || 0)),
        formatIntNumber(Number(item.commentsData.positiveNumber || 0)),
        formatIntNumberAndAddPercentSign(Number(item.commentsData.positivePercent || 0)),
        formatIntNumber(Number(item.commentsData.negativeNumber || 0)),
        formatIntNumberAndAddPercentSign(Number(item.commentsData.negativePercent || 0)),
        item.content.deal.hs_object_id ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${item.content.deal.hs_object_id}` : '',
      ]);
    });
    return csvHelper.generateCSV('YouTubeVideoCommentsBreakdownReport.csv', data);
  };

  return (
    <div className="mt-3">
      <Row className="mt-4">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search First Name, Last Name, Email, Company Name, Video Title"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={downloadCSV}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={4}>
          <FormGroup>
            <Label className="bold-text">
              Brand(s)
            </Label>
            <Select
              isDisabled={loadingBrands}
              value={selectedBrands}
              options={brandsOptions}
              onChange={setSelectedBrands}
              placeholder="Select Brand..."
              isMulti
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

Filters.propTypes = {
  allItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  setFilteredItems: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};


export default withNotice(Filters);
