import React from 'react';
import PropTypes from 'prop-types';
import TableCellContextMenu from '../../../../Research/Prospects/components/custom/TableCellContextMenu';

const ContextMenuInList = ({
  record,
  history,
}) => {
  const contextMenuOptions = [
    { label: 'Edit', handler: () => { history.push(`/custom-agreement-template/${record.id}`) } },
  ];

  return (
    <TableCellContextMenu options={contextMenuOptions} />
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default ContextMenuInList;
