/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Bar,
  BarChart,
  Tooltip as TooltipOnLine,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { formatIntNumber } from '../../../../shared/helpers/WVFormatter';
import CheckBoxField from '../../../../shared/components/CheckBox';

const chartMargins = {
  top: 10,
  right: 0,
  left: 15,
  bottom: 20,
};

const getNameFromKey = (key) => {
  let name = `${key}`;
  const index = key.indexOf(':');
  if (index !== -1) {
    name = key.substring(0, index);
  }
  return name.replace(/\s*\(.*?\)\s*/g, '');
};

const BarChartComponent = ({
  chartData,
  chartType,
  statuses,
  keys,
  showLabels,
  defaultColors,
}) => {
  const [barColors, setBarColors] = useState(defaultColors);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilters, setStatusFilters] = useState((statuses || []).reduce((accumulator, status) => ({ ...accumulator, [status]: true }), {}));
  const [names, setNames] = useState([...new Set(keys.map(key => getNameFromKey(key)))]);

  useEffect(() => {
    setBarColors(defaultColors);
  }, [defaultColors]);

  useEffect(() => {
    setNames([...new Set(keys.map(key => getNameFromKey(key)))]);
  }, [keys]);

  useEffect(() => {
    const newFilters = statuses.reduce((accumulator, status) => ({ ...accumulator, [status]: true }), {});
    setStatusFilters(newFilters);
  }, [statuses]);

  const filterData = () => {
    const newFilteredData = [];
    if (chartData?.length > 0) {
      const includesAny = (str, arr) => arr.some(element => str.includes(element));
      const selectedStatuses = Object.keys(statusFilters).filter(key => statusFilters[key]);

      chartData.forEach((entry) => {
        const item = names.reduce((newItem, name) => ({
          ...newItem,
          [name]: 0,
        }), {
          label: entry.label,
          totalCount: 0,
        });

        Object.keys(entry).forEach((dataLabel) => {
          const dataLabelName = getNameFromKey(dataLabel);
          if (names.includes(dataLabelName) && includesAny(dataLabel, selectedStatuses)) {
            const count = entry[dataLabel];
            item[dataLabelName] += count;
            item.totalCount += count;
          }
        });

        newFilteredData.push(item);
      });
    }
    setFilteredData(newFilteredData);
  };

  useEffect(() => {
    filterData();
  }, [chartData, statusFilters]);

  const handleLegendItemMouseEnter = (dataKey) => {
    const localColors = { ...defaultColors };
    // eslint-disable-next-line no-return-assign
    Object.keys(defaultColors).forEach(name => localColors[name] = name === dataKey ? defaultColors[name] : '#edeff0');
    setBarColors(localColors);
  };

  const handleLegendItemMouseLeave = () => {
    setBarColors(defaultColors);
  };
  const handleChangeStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setStatusFilters({
        ...statusFilters,
        [name]: checked,
      });
    }
  };

  const CustomizedTooltip = (props) => {
    const getText = (name, value) => `${name} - ${formatIntNumber(value)}`;
    if (props.active && props.payload && props.payload.length) {
      const totalCount = props.payload[0]?.payload?.totalCount || 0;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#ffffff',
            border: '1px solid #f5f5f5',
            lineHeight: '24px',
            margin: '0',
            padding: '10px',
          }}
        >
          <p className="bold-text">{props.label}: {totalCount}</p>
          <ul
            style={{
              listStyle: 'none',
              padding: '0',
              margin: '0',
            }}
          >
            {[...props.payload]?.reverse().map(({ name, value }) => (value ? (
              <li
                key={name}
                style={{
                    color: defaultColors[name],
                  }}
              >
                {getText(name, value)}
              </li>
            ) : null))}
          </ul>
        </div>
      );
    }
    return null;
  };

  const CustomizedXAxisTick = ({ x, y, payload }) => {
    const values = [16, 26, 40];
    const children = payload.value?.includes('-')
      ? payload.value.split(' ').map(((string, index) => (
        <text x={0} y={0} dy={values[index]} textAnchor="middle" fill="#666">
          {string}
        </text>
      ))) : (
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {payload.value}
        </text>
      );
    return (
      <g transform={`translate(${x},${y})`}>
        {children}
      </g>
    );
  };

  const CustomizedYAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {formatIntNumber(payload.value)}
      </text>
    </g>
  );

  return (
    <Row>
      <Col>
        <Col sm={8}>
          <FormGroup
            row
            style={{ marginBottom: '10px' }}
          >
            {statuses.map(status => (
              <Col>
                <CheckBoxField
                  name={`${chartType}${status}`.replace(/\s/g, '')}
                  label={status}
                  value={statusFilters[status]}
                  onChange={event => handleChangeStatusFilters(event, status)}
                />
              </Col>
            ))}
          </FormGroup>
        </Col>
        {filteredData.length ? (
          <Row>
            <Col md={10} style={{ paddingRight: '0' }}>
              <ResponsiveContainer
                width="100%"
                height={400}
                debounce={1}
              >
                <BarChart
                  width="100%"
                  height="100%"
                  data={filteredData}
                  margin={chartMargins}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                  {[...names].reverse().map((name, index) => (
                    <Bar
                      key={`bar${name}${chartType}`}
                      stackId="a"
                      dataKey={name}
                      fill={barColors[name]}
                      isAnimationActive={false}
                    >
                      {showLabels && index === (names.length - 1) && (
                        <LabelList
                          position="top"
                          dataKey="totalCount"
                        />
                      )}
                    </Bar>
                ))}
                  <XAxis
                    dataKey="label"
                    padding={{ left: 8, right: 8 }}
                    tick={<CustomizedXAxisTick />}
                    interval="preserveStartEnd"
                  />
                  <YAxis
                    padding={{ top: 8, bottom: 8 }}
                    tick={<CustomizedYAxisTick />}
                    domain={['dataMin', 'dataMax']}
                    interval="preserveStartEnd"
                    allowDecimals={false}
                  />
                  <TooltipOnLine cursor={{ fill: 'transparent' }} content={<CustomizedTooltip />} />
                </BarChart>
              </ResponsiveContainer>
            </Col>
            <Col
              md={2}
              className="d-flex align-items-center justify-content-center"
            >
              <ul
                style={{
                  listStyle: 'none',
                  paddingLeft: '0',
                }}
              >
                {names.map(name => (
                  <li
                    key={`legend${name}${chartType}`}
                    style={{
                      color: barColors[name],
                      cursor: 'pointer',
                      fontWeight: '600',
                    }}
                    onMouseEnter={() => handleLegendItemMouseEnter(name)}
                    onMouseLeave={handleLegendItemMouseLeave}
                  >
                    &#9632;  {name}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        ) : (
          <span className="mt-2">No data to chart</span>
        )}
      </Col>
    </Row>
  );
};

BarChartComponent.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.any),
  statuses: PropTypes.arrayOf(PropTypes.any),
  keys: PropTypes.arrayOf(PropTypes.string),
  chartType: PropTypes.string.isRequired,
  defaultColors: PropTypes.objectOf(PropTypes.any),
};

BarChartComponent.defaultProps = {
  chartData: [],
  statuses: [],
  keys: [],
  defaultColors: {},
};

export default BarChartComponent;
