/* eslint-disable filenames/match-exported */
/* eslint react/prop-types: 0 */
import React from 'react';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import ViewHelpers from 'admin-bro/lib/backend/utils/view-helpers';
import { Col, Row, ButtonGroup, Badge } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from 'mdi-react/FilterIcon';
import Tooltip from '@material-ui/core/Tooltip';
import ActionButton from './action-button-icon';

// import StyledButton from '../ui/styled-button';
// import theme from '../../../../theme';
/*
const HeaderWrapper = styled.section.attrs({
  className: 'level',
})`
  &&& {
    margin-bottom: 15px;
  }
`;
*/
/* const Tag = styled.span.attrs({
  className: 'tag',
})`
  &&& {
    background: #4A69F2;
    color: #fff;
    margin-left: 15px;
  }
`; */
/*
const BackBtn = styled(Link)`
  &&& {
    border-radius: 50%;
    width: ${theme.sizes.paddingLayout};
    height: ${theme.sizes.paddingLayout};
    color: ${theme.colors.lightText};
    font-size: ${theme.fonts.base};
    padding: ${theme.sizes.paddingMin};
    background-color: ${theme.colors.superLightBack};
    text-align: center;
    margin-right: ${theme.sizes.padding};
    &:hover{
      background-color: ${theme.colors.lightText};
      color: #fff;
    }
  }
`;

const HeaderTitle = styled.h1.attrs({
  className: 'level-left',
})`
  &&& {
    font-size: ${theme.fonts.header};
    font-weight: normal;
  }
`;

const HeaderButtons = styled.div.attrs({
  className: 'level-right',
})`
  &&& a {
    margin-left: ${theme.sizes.padding};
  }
`;
*/

const ActionHeader = (props) => {
  // const h = new ViewHelpers();
  const {
    resource, toggleFilter, actionPerformed, record, action, tag, recordId,
  } = props;
  const resourceId = resource.id;
  let actions = record ? record.recordActions : resource.resourceActions;

  // list action is not accessible via the ActionHeader buttons
  actions = actions && actions.filter(ra => ![action && action.name, 'list'].includes(ra.name));

  let title = action ? action.label : resource.name;

  // Z: disable all actions except Show/Edit for Influencer record
  if (resourceId === 'Influencer' && record) {
    if (action && action.name === 'show') {
      actions = record.recordActions.filter(ra => ra.name === 'edit');
      title += ' Account';
    } else if (action && action.name === 'edit') {
      actions = record.recordActions.filter(ra => ra.name === 'show');
      title += ' Account';
    }
  }
  // END Z;


  return (
    <Row className="mb-2">
      <Col>
        <h3>
          {title}
          {tag ? (<Badge color="primary ml-1">{tag}</Badge>) : ''}
        </h3>
      </Col>
      <Col className="mb-2">
        <Row className="justify-content-end">
          <ButtonGroup className="mr-3">
            {actions.map(headerAction => (
              <ActionButton
                action={headerAction}
                key={headerAction.name}
                actionPerformed={actionPerformed}
                className="is-primary btn-sm"
                resourceId={resource.id}
                recordId={recordId}
              />
            ))}
            {toggleFilter && (
              <Tooltip title="Filter">
                <IconButton
                  size="medium"
                  onClick={toggleFilter}
                  className="material-table__toolbar-button btn-sm"
                  color="primary"
                >
                  <FilterIcon />
                </IconButton>
              </Tooltip>
            )}
          </ButtonGroup >
        </Row>
      </Col>
    </Row>
  );

/*  return (
    <HeaderWrapper>
      <HeaderTitle>

      </HeaderTitle>
      <HeaderButtons>
        {actions.map(headerAction => (
          <ActionButton
            action={headerAction}
            key={headerAction.name}
            actionPerformed={actionPerformed}
            className="is-primary"
            resourceId={resource.id}
            recordId={recordId}
          />
        ))}
        {toggleFilter && (
          <StyledButton onClick={toggleFilter} as="a">
            <span className="icon">
              <i className="fas fa-sliders-h" />
            </span>
            <span className="btn-text">Filter</span>
          </StyledButton>
        )}
      </HeaderButtons>
    </HeaderWrapper>
  ); */
};

export default ActionHeader;
