import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const NumericInput = ({ originalValue, property, updateRowProperty }) => {
  const [value, setValue] = useState(originalValue);

  useEffect(() => setValue(originalValue), [originalValue]);

  const handleChange = async (newValue) => {
    setValue(newValue);
    if (originalValue !== newValue) {
      updateRowProperty(newValue);
    }
  };

  return (
    <div style={{ minWidth: '135px'}}>
      <Input
        type="number"
        name={property}
        id={`${property}Input`}
        className="w-100"
        min="1"
        step="1"
        onChange={e => setValue(e.target.value ? parseInt(e.target.value, 10) : 1)}
        value={value}
        onBlur={() => handleChange(value)}
      />
    </div>
  );
};

NumericInput.propTypes = {
  originalValue: PropTypes.number,
  updateRowProperty: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
};

NumericInput.defaultProps = {
  originalValue: 1,
};
export default NumericInput;
