import { Button, ButtonToolbar, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ApiClient from 'ApiClient';
import { currentTimezoneAggr } from '../../../../../shared/helpers/Dates';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';


const StatusHistoryModal = ({
  isOpen,
  closeFunc,
  history,
}) => {
  const [loading, setLoading] = useState(true);
  const [historyWithAdmin, setHistoryWithAdmin] = useState(true);

  const fetchAdminUsers = () => {
    const api = new ApiClient();
    setLoading(true);
    const uniqueAdmins = {};
    history.forEach((historyItem) => { uniqueAdmins[historyItem.adminUser] = true; });

    const promises = Object.keys(uniqueAdmins).map(adminUser => api.recordAction({
      resourceId: 'AdminUser',
      recordId: adminUser,
      actionName: 'show',
    }));
    Promise.all(promises)
      .then((results) => {
        const newRecyclingHistory = [];
        history.forEach((recyclingItem) => {
          const r = { ...recyclingItem };
          results.forEach((adminUser) => {
            if (adminUser.data.record.id === recyclingItem.adminUser) {
              // eslint-disable-next-line no-param-reassign
              r.adminUserName = `${adminUser.data.record.params.firstName} ${adminUser.data.record.params.lastName}`;
            }
          });
          newRecyclingHistory.push(r);
        });
        setHistoryWithAdmin(newRecyclingHistory);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      fetchAdminUsers();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '800px' }}
    >
      <ModalHeader className="modal-header-bold" style={{ padding: 0 }} toggle={() => closeFunc()} tag="h4">Status History</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {!loading ? (
              <>
                {historyWithAdmin.map(historyItem => (
                  <div key={`history${historyItem._id}`}>
                    Set {historyItem.status} on {moment(historyItem.date).format('MMM D, YYYY h:mm A')} {currentTimezoneAggr()} by {historyItem.adminUserName || ' Admin User'}
                  </div>
                ))}
                {history.length === 0 ? (
                  <Alert color="warning" bordered icon>
                    <p className="py-2">
                      No status history items
                    </p>
                  </Alert>) : null}
              </>
            ) : (
              <div className="text-center"><div className="spinner-border spinner-border-sm" /></div>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" onClick={() => closeFunc()}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

StatusHistoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(PropTypes.any).isRequired,
};


export default StatusHistoryModal;
