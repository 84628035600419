/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setLocation } from '../../../redux/reducers/admin/GlobalSelectedReducer';
// import { setPageLocation } from '../../../redux/reducers/TableReducer';
import LogInAdmin from '../../admin/LogIn/index';
import ForgotPasswordAdmin from '../../admin/ForgotPassword/index';
import ResetPasswordAdmin from '../../admin/ResetPassword/index';
import WrappedRoutesAdmin from './WrappedRoutesAdmin';
import MainWrapper from '../MainWrapper';

const getPortalTitle = () => {
  if (window.location.href.includes('localportal.influencelogic.com')) return 'IL Admin LOCAL';
  if (window.location.href.includes('devportal.influencelogic.com')) return 'IL Admin DEV';
  return 'IL Admin';
};
const portalTitle = getPortalTitle();

const Page = (props) => {
  useEffect(() => {
    document.title = props.title ? `${props.title} | ${portalTitle}` : portalTitle;
  }, [props.title]);

  return (
    <Route
      path={props.path}
      component={props.component}
    />
  );
};

const Router = ({ location, dispatch }) => {
  useEffect(() => {
    dispatch(setLocation(location));
  }, [location.pathname, location.search]);
  return (
    <MainWrapper>
      <main>
        <Switch>
          <Page
            title="Log In"
            path="/login"
            component={LogInAdmin}
          />
          <Page
            title="Forgot Password"
            path="/forgot"
            component={ForgotPasswordAdmin}
          />
          <Page
            title="Reset Password"
            path="/reset/:token"
            component={ResetPasswordAdmin}
          />
          <Route path="/" component={WrappedRoutesAdmin} />
        </Switch>
      </main>
    </MainWrapper>
  );
};
Router.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default connect()(withRouter(Router));
