import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import _ from 'lodash';

const TargetBrand = React.memo(({
  record, afterSaveFunc, brands, updateRecordFunction, setShowDuplicateTargetBrandModal, setCurrentProspect, setNewBrand,
}) => {
  // const key = 'targetBrand.0._id';
  // const keyBrandName = 'targetBrand.0.companyName';
  const rowData = record.populated.approvalStatus?.brand?.targetAdvertiserId;
  // const rowData = record.params;
  const val = (rowData && typeof rowData !== 'undefined' && rowData.params) ? { value: rowData.params._id, label: rowData.params.companyName } : { value: null, label: '-- Not Assigned --' };
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    setValue(val);
    setDisabled(false);
  }, [record.id, rowData]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    const prevValue = value;
    setValue(option);
    setNewBrand(option.label);
    try {
      const data = await axios.post('/api/portal/prospects/post-update-brand', {
        prospectIds: [record.id],
        brandId: option.value,
        brandName: option.label,
        prevBrandId: prevValue.value,
        prevBrandName: prevValue.label,
      });
      if (!data.data.success) {
        setValue(prevValue);
        setCurrentProspect(record);
        setShowDuplicateTargetBrandModal(true);
      } else {
        setValue(option);
      }
      if (data?.data?.prospectPopulated && updateRecordFunction) {
        updateRecordFunction(data?.data?.prospectPopulated);
      }
      if (data?.data?.success) {
        afterSaveFunc();
      }
    } catch (e) {
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };

  return (
    <div style={{ minWidth: '120px', maxWidth: '150px' }} >
      <Select
        value={value}
        className="targetBrandSelectBox"
        options={brands}
        onChange={handleChange}
        placeholder=""
        isDisabled={disabled || record.params?.inBlackList}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
      {error ? (<span className="text-danger">{error}</span>) : null}
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

TargetBrand.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  afterSaveFunc: PropTypes.func.isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
  setShowDuplicateTargetBrandModal: PropTypes.func,
  setCurrentProspect: PropTypes.func,
  setNewBrand: PropTypes.func,
};

TargetBrand.defaultProps = {
  setCurrentProspect: () => {},
  setShowDuplicateTargetBrandModal: () => {},
  setNewBrand: () => {},
};

export default TargetBrand;
