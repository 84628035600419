import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar, Col, Row, FormGroup, Label, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import OfflinePayout from '../../../../../shared/recordCollection/OfflinePayouts';
import OfflineAgentPayout from '../../../../../shared/recordCollection/OfflineAgentPayouts';
// import formater from '../helpers/WVFormatter';

let model = null;
let dealValid = true;
let missingDeal = false;

const OfflinePayouts = (props) => {
  const {
    selectedIds,
    show,
    title,
    hideModal,
    addNotice,
    type,
  } = props;
  const [loading, setLoading] = useState(false);
  const [record, setModel] = useState({ errors: {}, params: {}, populated: {} });
  const [dealValidationErrors, setDealValidationErrors] = useState(null);
  const [missingDealPart, setMissingDealPart] = useState(false);
  const [validDealPart, setValidDealPart] = useState(true);
  useEffect(() => {
    if (type === 'creator') {
      model = new OfflinePayout({ record: { params: { payableIds: selectedIds, date: new Date() } } }, setModel);
    } else if (type === 'agent') {
      model = new OfflineAgentPayout({ record: { params: { payableIds: selectedIds, date: new Date() } } }, setModel);
    }
  }, [selectedIds]);

  useEffect(() => {
    setMissingDealPart(missingDeal);
  }, [missingDeal]);

  useEffect(() => {
    setValidDealPart(dealValid);
  }, [dealValid]);

  const hideModalFunc = (e) => {
    hideModal(e);
    dealValid = true;
    missingDeal = false;
    setValidDealPart(true);
    setMissingDealPart(false);
    setDealValidationErrors(null);
  };

  const startOfflinePayment = async () => {
    await model.validate();
    if (model.haveErrors()) {
      return true;
    }
    setLoading(true);
    try {
      await model.save();
    } catch (e) {
      console.log(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
      setLoading(false);
      return false;
    }
    setLoading(false);
    addNotice({
      message: 'New Transfer were added successfully',
      type: 'success',
    });
    hideModalFunc(true);
    return false;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    // await model.validate();

    // Deal Validation
    if (selectedIds.length === 1) {
      const dealValidationResults = await axios.get(`/api/portal/finance/get-deal-validation-check?payableId=${selectedIds[0]}`);
      // console.log('dealValidationResults', dealValidationResults);
      if (dealValidationResults?.data.success) {
        dealValid = dealValidationResults.data.data.isValid;
        setDealValidationErrors(dealValidationResults.data.data.validationData);
      }
      if (!dealValidationResults?.data.success && dealValidationResults.data.error === 'Current Payable has no Deal') {
        missingDeal = true;
        setMissingDealPart(true);
      }
    }
    if (!dealValid || missingDeal) {
      console.log('FALSE');
      return true;
    }
    await startOfflinePayment();
    return false;
  };

  const statusValues = {
    payPal: 'PayPal',
    venmo: 'Venmo',
    stripe: 'Stripe (Dashboard)',
    billCom: 'Bill.com (Dashboard)',
    other: 'Other (Please Provide Note)',
  };
  const paymentMothodOptions = Object.keys(statusValues).map(key => ({ label: statusValues[key], value: key }));
  const paymentMothodOption = record.params.paymentMethod ? paymentMothodOptions.find(status => status.value.toString() === record.params.paymentMethod.toString()) : {};
  if (model === null) {
    return null;
  }
  return (
    <Modal
      isOpen={show}
      toggle={hideModalFunc}
      size="sm"
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">{title}</h4>
      </div>
      <div className="modal__body">
        <Row>
          <Col>
            <form onSubmit={onSubmit}>
              {missingDealPart && (
                <Row>
                  <Col xs={3} className="text-center mt-2" style={{ color: '#70bbfd' }}> <i className="fa fa-exclamation-triangle" style={{ fontSize: '5em' }} /></Col>
                  <Col xs={9}>
                    <span><strong>Are you sure?</strong></span>
                    <p>There is no deal for current payout. Do you want to proceed?</p>
                  </Col>
                </Row>
              )}
              {!validDealPart && !missingDealPart && (
                <Row>
                  <Col xs={3} className="text-center mt-2" style={{ color: '#70bbfd' }}> <i className="fa fa-exclamation-triangle" style={{ fontSize: '5em' }} /></Col>
                  <Col xs={9}>
                    <span><strong>Unable to send payment</strong></span>
                    <ul className="list-items-color-blue">
                      {dealValidationErrors.map(error => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              )}
              {!missingDealPart && validDealPart && (
                <>
                  <FormGroup>
                    <Label>Payment Date</Label>
                    <div>
                      <DatePicker
                        onChange={e => model.set('date', e)}
                        selected={typeof record.params.date === 'string' ? new Date(record.params.date) : (record.params.date || new Date())}
                        dateFormat="M/d/yyyy h:mmaa"
                        placeholderText="Due Date"
                        className="form-control"
                        showTimeInput
                      />
                    </div>
                    {model.getErrorView('date')}
                  </FormGroup>
                  <FormGroup>
                    <Label>Payment Method</Label>
                    <Select
                      value={paymentMothodOption}
                      options={paymentMothodOptions}
                      onChange={e => model.set('paymentMethod', e.value)}
                      placeholder="Payment Method"
                      isDisabled={record.params.payments && record.params.payments.length > 0}
                    />
                    {model.getErrorView('paymentMethod')}
                  </FormGroup>
                  <FormGroup>
                    <Label>Notes</Label>
                    <Input
                      type="textarea"
                      id="notes"
                      name="notes"
                      placeholder="Notes"
                      onChange={e => model.set('notes', e.target.value)}
                      value={model.get('notes')}
                    />
                    {model.getErrorView('notes')}
                  </FormGroup>
                </>
              )}
            </form>
          </Col>
        </Row>
      </div>
      <ButtonToolbar className="modal__footer">
        {validDealPart && !missingDealPart && (
          <Button
            className="modal_cancel btn-sm mr-2"
            color="primary"
            disabled={!!loading}
            onClick={onSubmit}
          >
            Save
          </Button>
        )}
        {missingDealPart && (
          <Button
            className="modal_cancel btn-sm mr-2"
            color="primary"
            disabled={!!loading}
            onClick={startOfflinePayment}
          >
            Yes
          </Button>
        )}
        <Button className="modal_cancel btn-sm" color="secondary" onClick={hideModalFunc}>{!missingDealPart ? 'Close' : 'No'}</Button>
      </ButtonToolbar>
    </Modal>
  );
};
OfflinePayouts.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default OfflinePayouts;
