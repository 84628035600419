import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import TableCellContextMenu from '../../../../Research/Prospects/components/custom/TableCellContextMenu';
import { set } from '@/redux/reducers/admin/GlobalSelectedReducer';
import CalculationModal from '../../../../Influencer/components/custom/CalculationModal';
import CreatorDetailsModal from '../../../../../../../shared/components/CreatorDetailsModal';
import Hook from '@/shared/hooks';

const ContextMenuInList = ({
  record,
  dispatch,
}) => {
  const { showError } = Hook.useNoticeHook();
  const influencer = record;
  const [spyLoginLoading, setSpyLoginLoading] = useState(false);
  const [researchDataLoading, setResearchDataLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedTubularCreatorId, setSelectedTubularCreatorId] = useState(null);

  const history = useHistory();

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const spyLogin = () => {
    setSpyLoginLoading(true);
    axios.post(
      '/api/portal/influencers/spy-login',
      {
        userId: influencer.id,
      },
    ).then((response) => {
      const { spyLoginToken, baseUrl } = response.data;
      if (spyLoginToken && baseUrl) {
        goToPage(`${baseUrl}/login-secret/${spyLoginToken}`, '_blank');
      }
      setSpyLoginLoading(false);
    }).catch((error) => {
      console.log(error);
      setSpyLoginLoading(false);
    });
  };
  const setTubularCreatorId = tubularCreatorId => setSelectedTubularCreatorId(tubularCreatorId);

  const viewResearchData = () => {
    setResearchDataLoading(true);
    axios.post(
      '/api/portal/research/do-search',
      {
        type: 'creator',
        value: influencer.params.mediaChannels.youtube_channel_url,
        filters: {},
        nextPageToken: '',
      },
    ).then((response) => {
      if (response.data.success && response.data.data) {
        const { creators } = response.data.data;
        if (creators && creators.length > 0) {
          if (creators[0] && creators[0].creator_id) {
            setTubularCreatorId(creators[0].creator_id);
          }
        }
      }
      if(!response.data.success) showError(response.data.error || 'Unable to get Right Channel data for this Influencer');
      setResearchDataLoading(false);
    }).catch((error) => {
      console.log(error);
      showError(error.message || 'Unable to get Right Channel data for this Influencer');
      setResearchDataLoading(false);
    });
  };

  const contextMenuOptions = [
    { label: `${spyLoginLoading ? 'Please wait...' : 'Spy Login'}`, handler: spyLogin },
    { type: 'divider', label: 'divider1' },
    { label: 'View Creator Profile', handler: () => goToPage(`/influencer/profile/${influencer.id}`) },
  ];

  if (influencer.params?.hsContactVid) {
    contextMenuOptions.push({ label: 'View HubSpot Contact', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${influencer.params.hsContactVid}/`) });
  }

  if (influencer.params.affiliateDataObject?.tune_id) {
    contextMenuOptions.push({ label: 'View HasOffers Partner Account', handler: () => goToPage(`https://influencelogic.hasoffers.com/admin/affiliates/view/${influencer.params.affiliateDataObject.tune_id}`) });
  }

  if (influencer.params.tune_id) {
    contextMenuOptions.push({ label: 'View HasOffers User Account', handler: () => goToPage(`https://influencelogic.hasoffers.com/admin/users/view/affiliate/${influencer.params.tune_id}`) });
  }

  if (influencer.params?.mediaChannels?.youtube_channel_url) {
    contextMenuOptions.push({ label: `${researchDataLoading ? 'Please wait...' : 'View Research Data'}`, handler: viewResearchData });
    contextMenuOptions.push({ label: 'View Audience Overlap', handler: () => goToPage(`/research/overlap/channel${btoa(influencer.params.mediaChannels.youtube_channel_url)}`) });
  }


  contextMenuOptions.push({ type: 'divider', label: 'divider2' });
  contextMenuOptions.push({
    label: 'New Deal',
    handler: () => {
      dispatch(set({
        influencer: influencer ? { ...influencer } : null,
        contact: influencer.params.contactObject ? { params: { ...influencer.params.contactObject, hs_object_id: influencer.params.hsContactVid } } : null,
      }));
      history.push('/influencers/creator-deal-editor');
    },
    disabled: influencer.params?.inBlackList,
  });
  contextMenuOptions.push({
    label: 'Gross Profit Calculation',
    handler: () => {
      setModal(true);
    },
  });

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />
      <CalculationModal modal={modal} influencer={influencer} setModal={setModal} />
      {selectedTubularCreatorId && (
      <CreatorDetailsModal
        isOpen={selectedTubularCreatorId !== null}
        creatorId={selectedTubularCreatorId}
        toggleModal={() => setSelectedTubularCreatorId(null)}
      />
        )}
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, null)(ContextMenuInList);
