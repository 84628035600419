import { unflatten } from 'flat';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { axios } from 'ApiClient';
import withNotice from '../../../../App/store/with-notice';

const Component = ({ addNotice, record }) => {
  const key = 'startDatetime';
  const [value, setValue] = useState(null);

  useEffect(() => {
    const recordUnflatten = unflatten(record);
    const rowData = recordUnflatten.params;
    let val = null;
    if (typeof rowData !== 'undefined') {
      if (typeof rowData[key] !== 'undefined' && rowData[key] !== null) {
        val = moment(rowData[key]).toDate();
      }
    }
    setValue(val);
  }, [record]);

  const onBlur = async () => {
    axios.post('/api/portal/compliance/post-edit-content-review-rule', {
      id: record.id,
      fieldsToUpdate: { startDatetime: value },
    }).then((response) => {
      if (response?.data?.success) {
        addNotice({
          message: 'Rule was successfully updated.',
          type: 'success',
        });
        return true;
      }
      if (response?.data?.error) {
        console.log('could not update the record: ', response);
        addNotice({
          message: response.data.error,
          type: 'error',
        });
        return false;
      }
    });
  };

  return (
    <DatePicker
      onChange={setValue}
      onBlur={onBlur}
      selected={value}
      dateFormat="M/d/yyyy h:mmaa"
      placeholderText="Start Date"
      className="form-control"
      minDate={moment().add(2, 'hour').toDate()}
      showTimeInput
    />
  );
};
Component.propTypes = {
  addNotice: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withNotice(Component);
