import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';
import { useDispatch } from 'react-redux';
// import UserPropertyType from './index';
import TableCellContextMenu from './TableCellContextMenu';
import ApprovalStatus from './ApprovalStatus';
import DescriptionField from './DescriptionField';
import SignedDateField from './SignedDateField';
// import MatTableList from '../../../../../../../shared/tables/materialTable/MatTableList';
import MatTableList from '../../../../../../../shared/tables/materialTable/TableRedux';
import { getServerDate } from '../../../../../../../shared/helpers/WVFormatter';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { modalId } from './Modal/DeleteDialog';
import { UploadInsertionOrderModalId } from './Modal/UploadInsertionOrderModal';
import { PublishInsertionOrderChangeLogHistoryModalId } from './Modal/PublishInsertionOrderChangeLogHistoryModal';
import { modalId as fileHistoryModalId } from './Modal/FileHistroryModal';
import { modalId as publisheLookerModalId } from '../../../CpmInsertionOrderProspects/components/modals/PublishLookerInsertinOrder';
import { modalId as fetchContentReleaseDate } from './Modal/FetchContentReleaseDate';
import Hook from '../../../../../../../shared/hooks';

const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

const Table = React.memo(({
  recordsRef,
  // handleSelectCheckbox,
}) => {
  const dispatch = useDispatch();
  const [showDeleteDialog] = Hook.useShowModalHook(modalId);
  const [showUploadInsertionOrderModalId] = Hook.useShowModalHook(UploadInsertionOrderModalId);
  const [showFileHistoryModalId] = Hook.useShowModalHook(fileHistoryModalId);
  const [showFetchContentReleaseDate] = Hook.useShowModalHook(fetchContentReleaseDate);
  const [showPublishInsertionOrderChangeLogHistoryModalId] = Hook.useShowModalHook(PublishInsertionOrderChangeLogHistoryModalId);

  const reload = Hook.useReloadTableHook();
  return (
    <MatTableList
      noActions
      isResizable={false}
      useFlex
      useCheckboxes
      useMultiPageCheckboxes
      checkboxHandler={() => {}}
      recordsRef={recordsRef}
      enableWarning={false}
      notFoundProps={{ title: 'No CPM Insertion Orders Found' }}
      useUnflatten
      customColumns={[
          {
            id: 'search',
            Header: () => 'Name',
            accessor: record => (record?.params?.name || ''),
            Cell: p => useMemo(() => (
              p.row.original.params?.name ? (
                <a
                  href={`/research/insertion-order-prospects/${p.row.original.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {p.value}
                </a>
              ) : ''), [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
          },
          {
            id: 'createdAt',
            Header: () => 'Date Created',
            accessor: record => (record?.params?.createdAt || ''),
            Cell: p => useMemo(() => (
              p.row.original.params?.createdAt ? (
                <div style={{ width: '160px', paddingRight: '10px' }}>
                  <span>{getServerDate(p.row.original.params.createdAt)}</span>
                </div>
              ) : null), [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
          },
          {
            id: 'brand',
            Header: () => 'Brand',
            Cell: p => p.row.original.populated?.brand?.params?.companyName || '',
            width: 100,
            className: 'd-flex align-items-center',
            sortable: true,
          },
          {
            id: 'status.status',
            Header: () => 'Status',
            Cell: p => useMemo(() => (
              <ApprovalStatus
                record={p.row.original}
                afterSaveFunc={() => reload()}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
            sortable: true,
          },
          {
            id: 'dateSigned',
            Header: () => 'Date Signed',
            accessor: record => (record?.params?.dateSigned || ''),
            Cell: p => useMemo(() => (
              <SignedDateField
                record={p.row.original}
                afterSaveFunc={() => reload()}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
          },
          {
            id: 'description',
            Header: () => 'Description',
            accessor: record => (record?.params?.description || ''),
            Cell: p => <DescriptionField record={p.row.original} />,
            className: 'd-flex align-items-center',
            disableSortBy: true,
            width: 250,
          },
          {
            Header: '',
            accessor: p => (p.params.googleSheetFiles?.length > 0 ? p.params.googleSheetFiles[p.params.googleSheetFiles.length - 1] : null),
            id: 'cpm_prospect_actions_files',
            width: 60,
            maxWidth: 60,
            // eslint-disable-next-line react/prop-types
            Cell: ({ value }) => {
              // console.log(value);
              if (!value) return '';
              // eslint-disable-next-line react/prop-types
              const { fileUrl, fileId } = value;
              return (
                <div>
                  <a href={fileUrl} title="Open Google Sheet File" target="_blank" rel="noreferrer" style={{ fontSize: '30px' }} className="pr-2 text-dark"><i className="fa fa-file-excel" /></a>
                  <a
                    // eslint-disable-next-line react/prop-types
                    href={`https://docs.google.com/spreadsheets/d/${fileId}/export?format=pdf&gridlines=false`}
                    title="Download PDF File"
                    target="_blank"
                    rel="noreferrer"
                    className="text-dark"
                    style={{ fontSize: '30px' }}
                  ><i className="fa fa-file-pdf" />
                  </a>
                </div>
              );
            },
            disableSortBy: true,
          },
          {
            Header: '',
            accessor: () => null,
            id: 'cpm_prospect_actions_delete',
            width: 50,
            Cell: (p) => {
              const contextMenuOptions = [
                { type: 'header', label: 'Insertion Order' },
                { label: 'View', handler: () => goToPage(`/research/insertion-order-prospects/${p.row.original.id}`) },
                {
                  label: 'View Publish History',
                  handler: () => { showFileHistoryModalId({ record: p.row.original }); },
                  disabled: !(p.row?.original?.params?.googleSheetFiles && p.row.original.params.googleSheetFiles.length > 0),
                },
                {
                  label: 'Upload Signed PDF',
                  handler: () => { showUploadInsertionOrderModalId({ record: p.row.original }); },
                  disabled: !(p.row?.original?.params?.googleSheetFiles && p.row.original.params.googleSheetFiles.length > 0),
                },
                {
                  label: 'Download Signed PDF',
                  handler: () => goToPage(`/api/portal/prospects/get-insertion-order-signed-pdf?id=${p.row.original.id}`, '_blank'),
                  disabled: !(p.row?.original?.params?.fileUrl),
                },
                { type: 'divider', label: 'divider1' },
                {
                  label: 'Publish Looker Update Data',
                  handler: () => { dispatch(showModalById(publisheLookerModalId, { record: p.row?.original })); },
                  disabled: (p.row?.original?.params?.status?.status !== 'Signed'),
                },
                { label: 'Sync Data From HubSpot', handler: () => showFetchContentReleaseDate({ ids: [p.row.original.id] }) },
                { label: 'Publish Change Log', handler: () => showPublishInsertionOrderChangeLogHistoryModalId({ ids: [p.row.original.id] }) },
                { type: 'divider', label: 'divider2' },
                { label: 'Delete', handler: () => showDeleteDialog({ record: p.row.original }), disabled: (p.row?.original?.params?.status?.status !== 'New' || p.row?.original?.params?.cpmProspects?.length > 0) },
              ];
              return (
                <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                  <TableCellContextMenu options={contextMenuOptions} />
                </div>
              );
            },
            disableSortBy: true,
            className: 'd-flex align-items-center align-middle',
          },
        ]}
    />
  );
}, () => true);

Table.propTypes = {
  // action: PropTypes.objectOf(PropTypes.any).isRequired,
  // resource: PropTypes.objectOf(PropTypes.any).isRequired,
  // version: PropTypes.string.isRequired,
  // setVersion: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  recordsRef: PropTypes.any.isRequired,
  // handleSelectCheckbox: PropTypes.func.isRequired,
};

export default Table;
