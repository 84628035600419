import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import { axios } from 'ApiClient';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../../App/store/with-notice';
import filterProperties from './custom/ActionProperties';
import { getAdminUsers } from '../../../../../../redux/reducers/admin/ResearchReducer';
// import { loadTableData } from '../../../../../../redux/reducers/TableReducer';
import Hook from '../../../../../../shared/hooks';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import NotesModal from '../../CpmPricingApprovals/components/custom/Modal/NotesModal';

import HistoryModal from '../../CpmPricingApprovals/components/custom/Modal/HistoryModal';

const getMonths = (start) => {
  const today = moment();
  const dateObjects = [];
  const currentDate = moment(start || '2022-01-01'); // Start date: January 1, 2022

  while (currentDate.isSameOrBefore(today, 'day')) {
    const formattedDate = currentDate.format('YYYY-MM-DD');
    const formattedMonthYear = currentDate.format('MMMM, YYYY');
    dateObjects.push({ value: formattedDate, label: formattedMonthYear });
    currentDate.add(1, 'month');
  }
  return dateObjects;
};

const CPMProspects = ({
  addNotice,
  history,
  resource,
}) => {
  const dealstages = (getConstant('deal', 'pipelines').find(p => p.label === 'Main Pipeline')).stages;
  const dispatch = useDispatch();
  const [settings, setSettings] = useState(null);
  const [projectedSettings, setProjectedSettings] = useState(null);
  const [cpmPriceApprovalsSuggestions, setCpmPriceApprovalsSuggestions] = useState([]);
  const adminUsers = useSelector(state => state.ResearchReducer.adminUsers);
  const [searchUrl, setPrefill, loadPage, isPageChanged, useLoadOrDebounce] = Hook.usePrefillHook({
    resourceId: resource.id,
    initialState: {
      sortBy: 'createdAt',
      direction: 'desc',
      page: 1,
      perPage: 10,
    },
    urlModify: (search) => {
      search.set('type', 'pricingApprovalsIPT');
      return search;
    },
  });
  console.log(isPageChanged);
  const fetchAmountReport = async () => {
    try {
      const resp = await axios.get('/api/portal/reports/get-rebuy-settings');
      const respProj = await axios.get('/api/portal/reports/get-projected-rebuy-settings');
      if (resp.data.success) {
        const settingsToUse = {
          types: resp.data.settings?.types || {},
          brands: {},
        };
        Object.values(resp.data.settings?.brands || {}).forEach((item) => {
          if (!settingsToUse.brands[item.advertiser]) settingsToUse.brands[item.advertiser] = {};
          if (!settingsToUse.brands[item.advertiser][item.type]) settingsToUse.brands[item.advertiser][item.type] = item;
        });
        setSettings(settingsToUse || { brands: {}, types: {} });
        setProjectedSettings(respProj.data.settings?.items);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
    return true;
  };

  useEffect(async () => {
    dispatch(getAdminUsers(true));
    await fetchAmountReport();
    try {
      const resp = await axios.get('/api/portal/prospects//get-cpm-price-approvals-suggestions');
      if (resp.data.success) {
        setCpmPriceApprovalsSuggestions(resp.data.suggestions);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  }, []);
  useEffect(() => {
    if (adminUsers?.length > 0 && cpmPriceApprovalsSuggestions?.length > 0) {
      console.log('Allow Start Load');
      setPrefill(filterProperties({
        cspOwnersSuggestions: adminUsers,
        monthOptions: getMonths(),
        justifications: [
          { label: 'Test', value: 'Test' },
          { label: 'Rebuy, Actual CAC', value: 'Rebuy, Actual CAC' },
          { label: 'Rebuy, Predicted CAC', value: 'Rebuy, Predicted CAC' },
          { label: 'Price Adjusted Rebuy to Actual', value: 'Price Adjusted Rebuy to Actual' },
          { label: 'Price Adjusted Rebuy to Predicted', value: 'Price Adjusted Rebuy to Predicted' },
          { label: 'Marginal Rebuy, Actual CAC', value: 'Marginal Rebuy, Actual CAC' },
          { label: 'Marginal Rebuy, Predicted CAC', value: 'Marginal Rebuy, Predicted CAC' },
        ],
        cpmPriceApprovalsSuggestions,
        dealstages: Object.keys(dealstages).map(d => ({ value: d, label: dealstages[d] })),
      }));
    }
  }, [adminUsers, cpmPriceApprovalsSuggestions]);
  const setUrl = () => {
    loadPage();
  };
  const downloadCsv = () => {
    window.open(`/resources/cpmprospects/download-csv-pricing?${searchUrl}`, '_blank').focus();
    // const search = searchUrl;
    // goToPage(`/api/portal/prospects/get-insertion-order-csv?${search}`);
  };
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 600);

  /* useEffect(() => {
    if (searchUrl === null) return false;
    history.push(`${history.location.pathname}?${searchUrl}`);
    // console.log(isPageChanged, 'isPageChanged');
    if (!isPageChanged.filters) {
      return loadPage();
    }
    return setUrlDebounced();
  }, [
    searchUrl,
  ]); */
  useLoadOrDebounce({
    loadFunc: () => loadPage(), loadDebounceFunc: () => setUrlDebounced(), setSearchUrl: () => history.push(`${history.location.pathname}?${searchUrl}`)
  })
  
  console.log(searchUrl, 'SEARCH');
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'CPM Program', path: null },
          { title: 'Price Approvals Review', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Price Approvals Review</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                downloadCsv={downloadCsv}
              />
              <Row style={{ marginTop: '-10px' }}>
                {searchUrl !== null && settings && projectedSettings ? <Table
                  settings={settings}
                  projectedSettings={projectedSettings}
                /> : null}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <NotesModal />
      <HistoryModal />
    </Container>
  );
};
CPMProspects.propTypes = {
  addNotice: PropTypes.func.isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withNotice(withRouter(CPMProspects));
