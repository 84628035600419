import React, { useState, useEffect } from 'react';
import SelectAsync from 'react-select/async';
import { components } from 'react-select';
import { axios } from 'ApiClient';
import { Col, Row, Card, CardBody, Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateForCreatorsPaymentsTables } from '../../../../../shared/helpers/WVFormatter';
import socialIcon from '../../../../../shared/components/icons/Social';
import { getMediaChannel } from '../../../../../shared/helpers/models/HubilContact';
import EditMediaChannelsModal from './components/modals/EditMediaChannels';

const loadContactsOptions = async (param, value) => {
  const response = await axios.get('/api/portal/hubil/get-contact-search', {
    params: {
      param,
      value,
    },
  });
  return response.data.records.map(record => ({
    value: record.params.hs_object_id,
    label: record.params.email,
    record,
  }));
};
const formatOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div >{record?.params.firstname} {record?.params.lastname}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record?.params.email}
    </div>
  </div>
);
const option = (props) => {
  const { record } = props.data;
  console.log(record);
  return (
    <components.Option {...props} >
      <div>
        <div>{record?.params.firstname} {record?.params.lastname}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record?.params.email}
        </div>
      </div>
    </components.Option>
  );
};

const allPlatforms = ['youtube', 'facebook', 'instagram', 'twitter', 'tiktok', 'podcast', 'twitch'];

const ContactProfile = ({
  loading,
  contact,
  changeHubspotContact,
  editContact,
  influencer,
}) => {
  // const [selectedContact, setContact] = useState(null);
  const [mediaChannels, setMediaChannels] = useState(allPlatforms.map(platform => getMediaChannel(contact?.params, platform)));
  const [editMediaChannelsModalIsOpen, setEditMediaChannelsModalIsOpen] = useState(false);

  const updateMediaChannels = (data = null) => {
    setMediaChannels(allPlatforms.map(platform => getMediaChannel(data || contact?.params, platform)));
  };

  useEffect(updateMediaChannels, [contact]);

  const handleChangeInfluencer = async (selected) => {
    if (changeHubspotContact) {
      changeHubspotContact(selected.value);
    }
  };
  let selectedContact = null;
  if (contact) {
    selectedContact = {
      value: contact.params.hs_object_id,
      label: contact.params.email,
      record: contact,
    };
  }
  const selectedInfluencerOption = selectedContact ? { ...selectedContact } : {};
  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Hubspot Contact</h2>
      <Card id="section-hubspot-contact">
        <CardBody>
          <Row>
            <Col>
              <div className="d-flex" style={{ maxWidth: '400px', marginBottom: 0 }}>
                <div className="pr-0">
                  <FormGroup style={{ minWidth: '350px', maxWidth: '350px', marginBottom: 0 }}>
                    <SelectAsync
                      cacheOptions
                      value={selectedInfluencerOption}
                      defaultOptions={[selectedInfluencerOption]}
                      loadOptions={async value => loadContactsOptions('email', value)}
                      onChange={handleChangeInfluencer}
                      placeholder="Please Select Contact"
                      formatOptionLabel={formatOptionLabel}
                      components={{ Option: option }}
                      isDisabled={!!loading || influencer?.params?.inBlackList}
                    />
                  </FormGroup>
                </div>
                <div className="pl-2" style={{ paddingTop: '8px' }}>
                  {selectedContact ? (
                    <a
                      href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${selectedContact ? selectedContact.value : 0}/`}
                      target="_blank"
                      title="Open Hubspot Contact"
                      rel="noreferrer noopener"
                    >
                      <i className="fab fa-hubspot" style={{ fontSize: '1.5em' }} />
                    </a>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col lg="auto">
              <Button
                color="primary"
                id="edit-contact-btn"
                className="radius-button"
                disabled={!!loading || influencer?.params?.inBlackList}
                onClick={editContact}
              >
                <i className="fas fa-pen" />
                Edit
              </Button>
            </Col>
          </Row>
          {!loading && contact && (
            <Row className="mt-3">
              <Col>
                <h5>First Name</h5>
              </Col>
              <Col>
                <h5>Last Name</h5>
              </Col>
              <Col>
                <h5>Company</h5>
              </Col>
              <Col>
                <h5>Contact Owner</h5>
              </Col>
              <Col>
                <h5>FTC Compliant Status</h5>
              </Col>
              <Col>
                <h5>Date of Compliance Review</h5>
              </Col>
              <Col>
                <h5>Media Channels</h5>
              </Col>
            </Row>
          )}
          {!loading && contact && (
            <hr />
          )}
          {!loading && contact && (
            <Row>
              <Col>
                <strong>{contact.params.firstname}</strong>
              </Col>
              <Col>
                <strong>{contact.params.lastname}</strong>
              </Col>
              <Col>
                <strong>{contact.params.company}</strong>
              </Col>
              <Col>
                <strong>{contact.params.owner ? `${contact.params.owner.firstName} ${contact.params.owner.lastName}` : '-'}</strong>
              </Col>
              <Col>
                <strong>{contact.params.ftc_compliance_status}</strong>
              </Col>
              <Col>
                {contact.params.date_of_compliance_review ? (
                  <strong>{ formatDateForCreatorsPaymentsTables(contact.params.date_of_compliance_review) }</strong>
                ) : (
                  <strong style={{ paddingLeft: '83px' }}>-</strong>
                )}
              </Col>
              <Col>
                <div>
                  {mediaChannels.map(t => socialIcon(t))}
                  {contact.params.hs_object_id && (
                    <Tooltip
                      title="Edit HubSpot Contact's Media Channels"
                    >
                      <IconButton
                        size="small"
                        onClick={() => setEditMediaChannelsModalIsOpen(true)}
                      >
                        <FontAwesomeIcon icon="edit" className="text-blue" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      <EditMediaChannelsModal
        key="editContactMediaChannelsModal"
        isOpen={editMediaChannelsModalIsOpen}
        toggle={() => { setEditMediaChannelsModalIsOpen(false); }}
        name={`${contact?.params?.firstname} ${contact?.params?.lastname}`}
        saveUrl={`/api/portal/hubil/post-save-contact?id=${contact?.params?.hs_object_id}`}
        afterSave={updateMediaChannels}
      />
    </div>
  );
};
ContactProfile.propTypes = {
  contact: PropTypes.objectOf(PropTypes.any),
  influencer: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  changeHubspotContact: PropTypes.func.isRequired,
  editContact: PropTypes.func.isRequired,
};
ContactProfile.defaultProps = {
  contact: null,
  influencer: null,
};
export default ContactProfile;
