import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import ActionHeader from '../../../../shared/components/components/app/action-header';

import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';

import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
import UserPropertyType from './components/custom';


const ResourceAction = (props) => {
  const {
    resources,
  } = props;
  const [tag, setTag] = useState('');
  const resource = resources.find(r => r.id === 'GeneralLedger');
  if (!resource) {
    return (<NoResourceError resourceId="GeneralLedger" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="GeneralLedger" actionName="list" />);
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs resource={resource} actionName="list" />
          <ActionHeader
            resource={resource}
            action={action}
            tag={tag}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <MatTableList
            action={action}
            resource={resource}
            setTag={setTag}
            date={new Date()}
            UserPropertyType={UserPropertyType}
            sortBy="_id"
            direction="asc"
            columnsSettings={{
              yearPeriod: {
                width: 150,
              },
              name: {
                width: 250,
              },
              description: {
                width: 550,
              },
            }}
            v={2}
          />
        </Col>
      </Row>
    </Container>
  );
};
ResourceAction.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
