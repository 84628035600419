import { useState, useEffect } from 'react';
import { axios } from 'ApiClient';

function useConversions(influencerIds, setLoading, startDate, endDate) {
  const [conversions, setConversions] = useState([]);
  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    async function fetchData() {
      if (influencerIds.length > 0) {
        setLoading(true);
        setConversions([]);
        const idSet = new Set();
        for (let i = 0; i < influencerIds.length; i += 1) {
          idSet.add(influencerIds[i]);
        }
        axios({
          method: 'get',
          url: `/api/portal/deals/conversions?influencerId=${[...idSet]}&dateFrom=${startDate}&dateTo=${endDate}`,
        }).then((response) => {
          if (response?.data?.success && response.data.conversions) {
            setConversions(response?.data?.conversions || []);
          }
          if (response?.data?.success && response.data.clicks) {
            setClicks(response?.data?.clicks || 0);
          }
          setLoading(false);
        }).catch((err) => {
          console.log(err);
          setLoading(false);
        });
      }
    }

    if (influencerIds) {
      fetchData();
    }
  }, [influencerIds.length, startDate, endDate]);

  return { conversions, clicks };
}

export default useConversions;
