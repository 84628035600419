import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Modal,
} from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import withNotice from '../../../../../../../App/store/with-notice';

const CustomCheckbox = withStyles({
  root: {
    color: '#70bbfd',
    '&$checked': {
      color: '#70bbfd',
    },
  },
  checked: {},
})(React.forwardRef((props, ref) => <Checkbox color="default" size="small" {...props} ref={ref} />));

const UpdateStatusOfSelectedProspectsModal = ({
  isOpen,
  toggleModal,
  loading,
  onSaveFunc,
  miningMode,
}) => {
  const ilStatusOptions = miningMode ?
    [
      { value: 'In Review', label: 'In Review' },
      { value: 'Mining', label: 'Mining' },
      { value: 'Rejected', label: 'Rejected' },
    ] : [
      { value: 'Approved', label: 'Approved' },
      { value: 'Hold', label: 'Hold' },
      { value: 'In Review', label: 'In Review' },
      { value: 'Mining', label: 'Mining' },
      { value: 'Rejected', label: 'Rejected' },
    ];
  const [updateILOption, setUpdateILOption] = useState(false);
  const [selectedILOption, setSelectedILOption] = useState({ value: 'In Review', label: 'In Review' });
  const [updateBrandOption, setUpdateBrandOption] = useState(false);
  const [selectedBrandOption, setSelectedBrandOption] = useState({ value: 'In Review', label: 'In Review' });

  useEffect(() => {
    if (!isOpen) {
      setUpdateILOption(false);
      setSelectedILOption({ value: 'In Review', label: 'In Review' });
      setUpdateBrandOption(false);
      setSelectedBrandOption({ value: 'In Review', label: 'In Review' });
    }
  }, [isOpen]);

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
      size="lg"
      style={{
        maxWidth: '900px',
        fontSize: '105%',
        fontStretch: '90%',
      }}
    >
      <div className="modal__header mb-0">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
        <h3 className="text-modal modal__title mb-1">
          Update Status
        </h3>
        <hr />
      </div>
      <div
        className="modal__body mt-0 mb-3"
      >
        <div className="d-flex">
          <CustomCheckbox
            id="updateInfluenceLogicStatus"
            name="ilCheckbox"
            label=""
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            checked={updateILOption}
            onClick={e => setUpdateILOption(!!e?.target?.checked)}
          />
          <span className="bold-text" style={{ paddingTop: '5px' }}>Update InfluenceLogic Status?</span>
        </div>
        <div
          className="w-100 research-watchlists"
          style={{ paddingTop: '6px', paddingLeft: '38px', paddingRight: '15px' }}
        >
          <Select
            isDisabled={!updateILOption}
            name="ilStatusSelect"
            value={selectedILOption}
            options={ilStatusOptions}
            onChange={setSelectedILOption}
            styles={colourStyles}
          />
        </div>
      </div>
      { !miningMode && (
        <div
          className="modal__body mt-3 mb-3"
        >
          <div className="d-flex">
            <CustomCheckbox
              name="brandCheckbox"
              label=""
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              checked={updateBrandOption}
              onClick={e => setUpdateBrandOption(!!e?.target?.checked)}
            />
            <span className="bold-text" style={{ paddingTop: '6px' }}>Update Brand Status?</span>
          </div>
          <div
            className="w-100 research-watchlists"
            style={{ paddingTop: '6px', paddingLeft: '38px', paddingRight: '15px' }}
          >
            <Select
              isDisabled={!updateBrandOption}
              name="brandStatusSelect"
              value={selectedBrandOption}
              options={
                [
                  { value: 'Approved', label: 'Approved' },
                  { value: 'In Review', label: 'In Review' },
                  { value: 'Hold', label: 'Hold' },
                  { value: 'Rejected', label: 'Rejected' },
                  { value: 'In Appeals', label: 'In Appeals' },
                ]
              }
              onChange={setSelectedBrandOption}
              styles={colourStyles}
            />
          </div>
        </div>
      )}
      <ButtonToolbar className="modal__footer mt-3">
        <Button
          color="primary"
          disabled={!(updateILOption || updateBrandOption)}
          onClick={() => onSaveFunc(updateILOption, selectedILOption.value, updateBrandOption, selectedBrandOption.value)}
        >
          {loading && (<span><div className="spinner-border text-light spinner-border-sm" />  </span>)}
          Update
        </Button>
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

UpdateStatusOfSelectedProspectsModal.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSaveFunc: PropTypes.func.isRequired,
  miningMode: PropTypes.bool,
};

UpdateStatusOfSelectedProspectsModal.defaultProps = {
  isOpen: false,
  miningMode: false,
};


export default connect()(withNotice(UpdateStatusOfSelectedProspectsModal));
