import React, { useState, useEffect, useMemo } from 'react';
import Select, { components } from 'react-select'
import { Modal, ButtonToolbar, Button, Form, FormGroup, Label, FormFeedback, ModalHeader, ModalBody, Row, Col, Input } from 'reactstrap';
import RadioButton from '../../../../../../../shared/components/RadioButton';
import ApiClient, { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import ReactDatePicker from 'react-datepicker';
import { CKEditor } from 'ckeditor4-react';
// import PropTypes from 'prop-types';
import Hook from '../../../../../../../shared/hooks';
import CheckBoxField from '@/shared/components/CheckBox';

export const modalId = 'ScheduleNotification';


const formatOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div >{record.params.first_name} {record.params.last_name}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record.params.email}
    </div>
  </div>
);
const option = (props) => {
  const { record } = props.data;
  console.log(record);
  return (
    <components.Option {...props} >
      <div>
        <div className="bold-text">{record.params.first_name} {record.params.last_name}</div>
        <div >{record.params['affiliateDataObject.company']}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record.params.email}
        </div>
      </div>
    </components.Option>
  );
};


const ScheduleNotification = () => {
  const [templateOptions, setTemplateOptions] = useState([]);
  const [stats, setStats] = useState({});
  const [influencers, setInfluencers] = useState([]);
  const [, setEditors] = useState();
  const [formData, setFormData] = useState({
      template: '',
      sendType: 'now', // Default to sending now
      startDate: '',
      userGroup: 'all', // Default to all users
      group: '',
      selectedUsers: [],
      duration: 60*60*24*1000, // Default duration
      type: [],
      headlineTitle: '',
      headlineMessage: '',
      notificationMessage: '',
      notificationTitle: '',
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
  const { showSuccess, showError } = Hook.useNoticeHook();
  const { data: dataModal, showModal: isShow, close } = Hook.useModalHook(modalId);
  console.log(dataModal, isShow);
  const closeModal = () => {
    close();
    setFormData({
      template: '',
      sendType: 'now', // Default to sending now
      startDate: '',
      userGroup: 'all', // Default to all users
      group: '',
      selectedUsers: [],
      duration: 60*60*24*1000, // Default duration
      type: [],
      headlineTitle: '',
      headlineMessage: '',
      notificationMessage: '',
      notificationTitle: '',
    })
  }
  useEffect(async() => {
    if(isShow) {
      const records = await axios.getSafety('/api/resources/PortalNotification/actions/list?perPage=100&page=1&direction=desc&sortBy=_id');
      if (records.records) {
        setTemplateOptions(records.records.map(a => ({ value: a.id, label: `${a.params.notificationName}( ${a.params.headlineText || ''} )`, record: a })))
      }
    }
  }, [isShow]);
  useEffect(async () => {
    if (template?.value) {
      const records = await axios.getSafety(`/api/portal/notification/get-stats?template=${template?.value}`);
      setStats(records.stats); 
    }
  }, [formData.template]);
  const template = useMemo(() => templateOptions.find(a => a.value === formData.template), [templateOptions, formData.template]);
  const { record } = dataModal;
  if (!record) return null;

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData((state) => ({
        ...state,
        [name]: files[0], // Handle file input
      }));
    } else {
      setFormData((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };
  const showNotificationFields  = (value) => {
    const newType = formData.type.includes('notification')
    ? formData.type.filter(item => item !== 'notification') // Remove 'notification' if it exists
    : [...formData.type, 'notification']; // Add 'notification' if it doesn't exist
    handleChange({ target: { name: 'type', value: newType } });
    if (value) {
      console.log(template?.record);
      handleChange({ target: { name: 'notificationMessage', value: template?.record?.params?.notificationText } });
      handleChange({ target: { name: 'notificationTitle', value: template?.record?.params?.headlineText } });
    }
    
  };
  const showHeadLineFields  = (value) => {
    const newType = formData.type.includes('headLine')
    ? formData.type.filter(item => item !== 'headLine') // Remove 'notification' if it exists
    : [...formData.type, 'headLine']; // Add 'notification' if it doesn't exist
    handleChange({ target: { name: 'type', value: newType } });
    if (value) {
      console.log(template?.record);
      handleChange({ target: { name: 'headlineMessage', value: template?.record?.params?.headLineNotificationText } });
      handleChange({ target: { name: 'headlineTitle', value: template?.record?.params?.headlineText } });
    }
    
  };
  const handleCKEditorDataChange = (event) => {
    console.log(event, 'event')
    const { editor} = event;
    setFormData((state) => ({
      ...state,
      [editor.name]: editor.getData(),
    }));
  };


  const validateForm = () => {
    const newErrors = {};

    if (!formData.template) {
      newErrors.template = 'Template is required';
    }
    if (formData.sendType === 'schedule' && !formData.startDate) {
      newErrors.startDate = 'Start Date is required when scheduling';
    }
    if (formData.userGroup === 'few' && formData.selectedUsers.length === 0) {
      newErrors.selectedUsers = 'At least one user must be selected';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const res = await axios.postSafety('/api/portal/notification/post-create-notifications', formData);
      if(res.success) {
        closeModal();
        showSuccess('Notification scheduled');
      } else {
        throw new Error(res.error)
      }
      // console.log('Notification sent successfully:', response.data);
      // Handle successful notification sending (e.g., show success message, clear form)
    } catch (error) {
      showError(error.message);
      console.error('Error sending notification:', error);
      // Handle error (e.g., show error message)
    } finally {
      setIsSubmitting(false);
    }
  };

  const durationOption = [{value: 60*60*1000, label: '1 Hour'}, {value: 24*60*60*1000, label: '1 day'},{value: 7*24*60*60*1000, label: '1 week'}, {value: 31*24*60*60*1000, label: '1 Month'}];
  const groupsOptions = [{value: 'withDeals', label: `With Deals (${stats?.group?.withDeals || 0})`}];
  // const typeOptions = [{value: 'notification', label: 'Notification'}, {value: 'headLine', label: 'Head Line'}];
  const loadCreatorsOptions = async (value) => {
    const response = await axios.getSafety('/api/portal/influencer/get-creator-search', {
      params: {
        value,
      },
    });
    return response?.records?.map(record => ({
      value: record.params._id,
      label: record.params.email,
      record,
    })) || [];
  };
  const loadAgentOptions = async (value) => {
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'AgentUser',
      query: value,
    });
    const res = [];
    records.forEach((record) => {
      if (record.params.email !== null) {
        res.push({
          value: record.id,
          label: record.params.email,
          params: record.params,
          record,
        });
      }
    });
    return res;
  };
  return (
    <Modal
      isOpen={isShow}
      toggle={() => closeModal()}
    >
      <ModalHeader toggle={closeModal} tag="h4" className="modal-title-statistics">
        Send Notification
      </ModalHeader>
      {/* eslint-disable-next-line react/no-danger */}
      <ModalBody className="ltr-support theme-light">
        <Form onSubmit={handleSubmit}>
          {/* Select Template */}
          <FormGroup>
            <Label for="template">Select Template</Label>
            <Select
              value={template}
              options={templateOptions}
              onChange={(value) => handleChange({ target: {name: 'template', value: value.value }})}
              name='template'
            />
            <FormFeedback>{errors.template}</FormFeedback>
          </FormGroup>
          <FormGroup style={{ marginBottom: '0' }}>
            <Label >
              {template?.record?.params?.portal ? (<span>You selected template for <strong>{template?.record?.params?.portal}</strong></span>) : null}
            </Label>
          </FormGroup>
          <Label className="bold-text">
            Type Of notification you want to send:
          </Label>
          <FormGroup style={{ marginBottom: '0' }}>
            <CheckBoxField
              name="Active1"
              label="Send As Noticiation Bell"
              value={formData.type.includes('notification')}
              onChange={event => {
                console.log(event);
                if (!event) return false;
                showNotificationFields(event.target.checked);
              }}
              disabled={!formData.template}
            />
          </FormGroup>
          {formData.type.includes('notification') ? (
            <Row>
              <Col md={12}>
                <Input
                  type="text"
                  name="notificationTitle"
                  id="notificationTitle"
                  value={formData.notificationTitle}
                  onChange={handleChange}
                  invalid={!!errors.notificationTitle}
                />
              </Col>
              <Col md={12}>
                <FormGroup style={{ width: '100%' }}>
                  <Label for="notificationText">Notification Message</Label>
                  <CKEditor
                      config={{versionCheck: false}}
                      id="notificationMessage"
                      name="notificationMessage"
                      initData={formData.notificationMessage}
                      activeClass="form-control"
                      onChange={(e) => handleCKEditorDataChange(e)}
                      onInstanceReady={({ editor }) => setEditors(state => ({ ...state, [editor.name]: editor }))}
                    />
                </FormGroup>
                <FormFeedback>{errors.notificationMessage}</FormFeedback>
              </Col>
            </Row>) : null}
          <FormGroup style={{ marginBottom: '0' }}>
            <CheckBoxField
              name="Active2"
              label="Show Headline Field"
              value={formData.type.includes('headLine')}
              onChange={event => {
                console.log(event);
                if (!event) return false;
                showHeadLineFields(event.target.checked);
              }}
              disabled={!formData.template}
            />
          </FormGroup>
          {formData.type.includes('headLine') ? (
            <Row>
              <Col md={12}>
                <Input
                  type="text"
                  name="headlineTitle"
                  id="headlineTitle"
                  value={formData.headlineTitle}
                  onChange={handleChange}
                  invalid={!!errors.headlineTitle}
                />
              </Col>
              <Col md={12}>
                <FormGroup style={{ width: '100%' }}>
                  <Label for="notificationText">Headline Message</Label>
                  <CKEditor
                      config={{versionCheck: false}}
                      id="headlineMessage"
                      name="headlineMessage"
                      initData={formData.headlineMessage}
                      activeClass="form-control"
                      onChange={(e) => handleCKEditorDataChange(e)}
                      onInstanceReady={({ editor }) => setEditors(state => ({ ...state, [editor.name]: editor }))}
                    />
                </FormGroup>
                <FormFeedback>{errors.notificationText}</FormFeedback>
              </Col>
            </Row>) : null}
          {/* Schedule Option */}
          <FormGroup>
            <Label className='pr-2'>Send Notification: </Label>
            <RadioButton
              name="sendType"
              label="&nbsp;Now"
              value={formData.sendType}
              radioValue="now"
              onChange={(v) => { handleChange({ target: { name: 'sendType', value: 'now'}}); }}
              disabled={!formData.template}
            />
            <RadioButton
              name="sendType"
              label="&nbsp;Schedule Time"
              value={formData.sendType}
              radioValue="schedule"
              onChange={(v) => { handleChange({ target: { name: 'sendType', value: 'schedule'}}); }}
              disabled={!formData.template}
            />
          </FormGroup>

          {/* Start Date (conditionally shown) */}
          {formData.sendType === 'schedule' && (
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <ReactDatePicker
                selected={formData.startDate ? new Date(formData.startDate) : null}
                onChange={(date) => {
                  handleChange({ target: { name: 'startDate', value: date}});
                }}
                dateFormat="MMM d, yyyy hh:mm"
                minDate={new Date()}
                placeholderText="Select Start Date"
                dropDownMode="select"
                isClearable
                wrapperClassName="date-picker--interval w-100"
                className="form-control form-control-sm datepicker-in-profile w-100"
                showTimeInput
              />
              <FormFeedback>{errors.startDate}</FormFeedback>
            </FormGroup>
          )}

          {/* User Group Selection */}
          <FormGroup>
            <Label className='pr-2'>{template?.record?.params?.portal === 'agent' ? 'Agent' : 'Creator'} Group: </Label>
            <RadioButton
              name="userGroup"
              label={`All (${stats.total})`}
              value={formData.userGroup}
              radioValue="all"
              disabled={!formData.template}
              onChange={(v) => { handleChange({ target: { name: 'userGroup', value: 'all'}}); }}
            />
            <RadioButton
              name="userGroup"
              label="Group"
              value={formData.userGroup}
              radioValue="group"
              disabled={!formData.template}
              onChange={(v) => { handleChange({ target: { name: 'userGroup', value: 'group'}}); }}
            />
            <RadioButton
              name="userGroup"
              label="Few"
              value={formData.userGroup}
              radioValue="few"
              disabled={!formData.template}
              onChange={(v) => { handleChange({ target: { name: 'userGroup', value: 'few'}}); }}
            />
          </FormGroup>

          {/* Select Specific Users (conditionally shown) */}
          {formData.userGroup === 'few' && template?.record?.params?.portal === 'creator' &&  (
            <FormGroup>
              <Label for="selectedUsers">Select Users</Label>
              <SelectAsync
                cacheOptions
                value={influencers}
                loadOptions={async value => loadCreatorsOptions(value)}
                formatOptionLabel={formatOptionLabel}
                components={{ Option: option }}
                onChange={selected => {
                  setInfluencers(selected);
                  console.log(selected)
                  handleChange({ target: { name: 'selectedUsers', value: selected.map(a => a.value)}})
                }}
                placeholder="Select Influencers"
                isMulti
              />
              <FormFeedback>{errors.selectedUsers}</FormFeedback>
            </FormGroup>
          )}
          {formData.userGroup === 'few' && template?.record?.params?.portal === 'agent' &&  (
            <FormGroup>
              <Label for="selectedUsers">Select Users</Label>
              <SelectAsync
                cacheOptions
                value={influencers}
                loadOptions={async value => loadAgentOptions(value)}
                formatOptionLabel={formatOptionLabel}
                components={{ Option: option }}
                onChange={selected => {
                  setInfluencers(selected);
                  console.log(selected)
                  handleChange({ target: { name: 'selectedUsers', value: selected.map(a => a.value)}})
                }}
                placeholder="Select Agents"
                isMulti
              />
              <FormFeedback>{errors.selectedUsers}</FormFeedback>
            </FormGroup>
          )}

          {formData.userGroup === 'group' && (
            <FormGroup>
              <Label for="selectedUsers">Select Users</Label>
              <Select
                name="selectedGroup"
                value={groupsOptions.find(a => Number(a.value) === Number(formData.group))}
                options={groupsOptions}
                invalid={!!errors.selectedUsers}
                onChange={(v) => { handleChange({ target: { name: 'group', value: v.value }}); }}
              />
              <FormFeedback>{errors.selectedUsers}</FormFeedback>
            </FormGroup>
          )}

          {/* Duration Selection */}
          <FormGroup>
            <Label for="duration">Duration</Label>
            <Select
                name="duration"
                id="duration"
                value={durationOption.find(a => Number(a.value) === Number(formData.duration))}
                options={durationOption}
                invalid={!!errors.selectedUsers}
                onChange={(v) => { handleChange({ target: { name: 'duration', value: v.value }}); }}
              />
          </FormGroup>
        </Form>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button color="primary" type="submit" disabled={isSubmitting} className='mr-2' onClick={handleSubmit} >Send Notification</Button>
        <Button color="secondary" onClick={() => closeModal()}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

ScheduleNotification.propTypes = {};

export default ScheduleNotification;
