import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Container, Card, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import classnames from 'classnames';
import DealRebuySettingsComponent from './DealRebuySettingsComponent';
import ProjectedSettingsComponent from './ProjectedSettingsComponent';
import CPASettingsComponent from './CPASettingsComponent';
import DealCreation from './DealCreation';

const Settings = ({ addNotice }) => {
  const [activeTab, setActiveTab] = useState('dealRebuySettings');

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Settings', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3 className="d-inline">Settings </h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col className="tabs">
                  <Nav className="mt-2" tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'dealRebuySettings' })}
                        onClick={() => {
                          setActiveTab('dealRebuySettings');
                        }}
                      >
                        Deal Threshold Settings
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'projectedSettings' })}
                        onClick={() => {
                          setActiveTab('projectedSettings');
                        }}
                      >
                        Projected CAC Settings
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'cpaSettings' })}
                        onClick={() => {
                          setActiveTab('cpaSettings');
                        }}
                      >
                        CPA Settings
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'dealCreation' })}
                        onClick={() => {
                          setActiveTab('dealCreation');
                        }}
                      >
                        Deal Creation
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="dealRebuySettings">
                      <DealRebuySettingsComponent />
                    </TabPane>
                    <TabPane tabId="projectedSettings">
                      <ProjectedSettingsComponent />
                    </TabPane>
                    <TabPane tabId="cpaSettings">
                      <CPASettingsComponent />
                    </TabPane>
                    <TabPane tabId="dealCreation">
                      <DealCreation />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
Settings.propTypes = {
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(Settings);
