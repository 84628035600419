import React, { useMemo } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
// import ReactTableBase from '@/';
import ReactTableBase from '../../../shared/tables/table/ReactTableBase';
import CreateTableData from '../../../shared/tables/CreateData';


const DefaultDashboard = () => {
  const reactTableData = CreateTableData();
  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by First name...',
  };
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        Footer: 'Middle age:',
        disableGlobalFilter: true,
        width: 65,
        Cell: ({ value }) => `# ${value}`,
        disableSortBy: true,
      },
      {
        Header: 'First name',
        accessor: 'first',
      },
      {
        Header: 'Last name',
        accessor: 'last',
        disableGlobalFilter: true,
      },
      {
        Header: 'Username',
        accessor: 'user',
        disableGlobalFilter: true,
      },
      {
        Header: 'Age',
        accessor: 'age',
        disableGlobalFilter: true,
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.age) + sum, 0),
            [info.rows],
          );
          const age = Math.round(totalAge / info.flatRows.length);
          return <span>{age}</span>;
        },
      },
      {
        Header: 'Date',
        accessor: 'date',
        disableGlobalFilter: true,
      },
      {
        Header: 'Location',
        accessor: 'location',
        disableGlobalFilter: true,
      },
      {
        Header: 'Work',
        accessor: 'work',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <ReactTableBase
                key="searchable"
                columns={columns}
                data={reactTableData.tableRowsData}
                tableConfig={tableConfig}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default connect(state => ({
  rtl: state.rtl,
}))(DefaultDashboard);
