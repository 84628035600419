/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  // FormGroup,
  PaginationLink,
  PaginationItem,
  Pagination, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  setCookie,
  getCookie,
} from '../../../../../../shared/helpers/WVUtilities';
import AdminBroServerSide from '../../../../../../shared/tables/tableAdminBro/AdminBroServerSide';
// import CheckBoxField from '../../../../../../shared/components/CheckBox';
import WVFormatter from '../../../../../../shared/helpers/WVFormatter';
import getSearchCreatorColumns from './custom/ResearchCreatorColumnsInSearchTable';
import getSearchVideoColumns from './custom/ResearchVideoColumnsInSearchTable';
import getSearchPodcastColumns from './custom/ResearchPodcastColumnsInSearchTable';
import withNotice from '../../../../../App/store/with-notice';
import CreatorDetailsModal from '../../../../../../shared/components/CreatorDetailsModal';
import CreateWatchlistModal from '../../Watchlists/components/CreateWatchlistModal';
import RelatedPodcastsModal from './custom/RelatedPodcastsModal';
import AddToProspectsModal from './custom/AddToProspectsModal';
import { getAdminUsers, uploadSearchToProspects } from '../../../../../../redux/reducers/admin/ResearchReducer';
import IndeterminateCheckbox from '../../../../../../shared/components/IndeterminateCheckbox';

const ChannelsListContainer = ({
  loading,
  searchResults,
  searchMetadata,
  gotoPage,
  pageNumber,
  atLeastOneSearchIsMade,
  searchedText,
  activeTypeCheckbox,
  searchTokens,
  addSearchItemToTheList,
  addNotice,
  getSearchDataToSave,
  watchlists,
  getMyWatchlists,
  dispatch,
  loadingAddToProspect,
  addToProspectsResults,
  searchQueryText,
  searchFilters,
  searchSortOptions,
  adminUsers,
  currentAdminUser,
}) => {
  const [singleSelectedCreatorId, setSingleSelectedCreatorId] = useState(null);
  const [openRelatedPodcastModal, setOpenRelatedPodcastModal] = useState({ isOpen: false, title: '' });
  const [newListModalVisible, setNewListModalVisible] = useState(false);
  const [addToProspectsModalVisible, setAddToProspectsModalVisible] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsForProspect, setSelectedItemsForProspect] = useState([]);
  const [currentWatchlist, setCurrentWatchlist] = useState({ value: 'master', label: 'Master List' });
  useEffect(() => {
    dispatch(getMyWatchlists());
    dispatch(getAdminUsers(false));
  }, []);
  useEffect(() => {
    setSelectedItems([]);
  }, [searchResults]);
  useEffect(async () => {
    if (watchlists && watchlists.length > 0) {
      const masterList = watchlists[0];
      const watchlistsPrepared = [];
      watchlists.forEach((element) => {
        if (element && element.value) {
          watchlistsPrepared.push(element);
        } else if (element && element.options) {
          watchlistsPrepared.push(...element.options);
        }
      });
      const watchlistIdFromCookie = getCookie('selectedWatchlistOnCreatorResearch');
      if (watchlistIdFromCookie) {
        const watchlistFromCookie = watchlistsPrepared.find(watchlist => watchlist.value === watchlistIdFromCookie);
        if (watchlistFromCookie) {
          setCurrentWatchlist(watchlistFromCookie);
        } else setCurrentWatchlist(masterList);
      } else setCurrentWatchlist(masterList);
    }
  }, [watchlists]);
  /* const selectCheckbox = (id, value) => {
    const s = [...selectedItems];
    if (value) s.push(id);
    else {
      const index = selectedItems.indexOf(id);
      if (index > -1) s.splice(index, 1);
    }
    setSelectedItems(s);
  }; */
  const addItemsToTheWatchlist = () => {
    if (selectedItems.length > 0) {
      addSearchItemToTheList(selectedItems, currentWatchlist.value);
    } else {
      addNotice({
        message: 'Select results to add to the watchlist.',
        type: 'warning',
      });
    }
  };
  const addItemsToTheProspects = (selectedItem = null) => {
    if (selectedItem) {
      setSelectedItemsForProspect([selectedItem]);
    } else if (selectedItems.length > 0) {
      setSelectedItemsForProspect(selectedItems);
    } else {
      addNotice({
        message: 'Select results to add to the prospects list.',
        type: 'warning',
      });
      return;
    }
    setAddToProspectsModalVisible(true);
  };
  const currentWatchlistChanged = (e) => {
    if (e.value !== 'createnew') {
      setCurrentWatchlist(e);
      setCookie('selectedWatchlistOnCreatorResearch', e.value, 35);
    } else {
      setNewListModalVisible(true);
    }
  };

  const showErrorNotice = (message) => {
    addNotice({
      message,
      type: 'error',
      duration: 10,
    });
  };

  const newWatchlistSaved = (record) => {
    dispatch(getMyWatchlists());
    setCurrentWatchlist({ value: record.id, label: record.params.name });
    setCookie('selectedWatchlistOnCreatorResearch', record.id, 35);
  };

  const addToProspects = (
    savedSearchData, advertiserId, tagsArr, searchItems, overwriteAlreadyInHs, overwritePossiblyInHs, overwriteAlreadyProspect, allowDuplicates, includeAll, queue, analystId,
    program, name, description, range, prospectType, prospectOwner, selectedDate, submittedBy,
  ) => {
    const formData = {
      savedSearchData,
      advertiserId,
      tags: tagsArr,
      searchItems,
      overwriteAlreadyInHs,
      overwritePossiblyInHs,
      overwriteAlreadyProspect,
      allowDuplicates,
      numberOfFoundItems: searchMetadata.total,
      includeAll,
      searchType: activeTypeCheckbox,
      searchQueryText,
      searchFilters,
      searchSortOptions,
      queue,
      analystId,
      program,
      name,
      description,
      range,
      prospectType,
      prospectOwner,
      submittedBy,
      scheduledDate: selectedDate,
    };
    dispatch(uploadSearchToProspects(formData, setErrorMessages));
    return true;
  };

  const pageSize = searchResults.length;
  const { total } = searchMetadata;
  const perPage = 25;
  const pageIndex = pageNumber - 1;
  const nextPageExists = (pageSize * pageIndex) + pageSize !== searchMetadata.total;

  let columns = [/* {
    Header: () =>  (<CheckBoxField
      name="selectAll"
      label="All"
      value={searchResults && searchResults.length > 0 && selectedItems.length === searchResults.length}
      onChange={({ target }) => {
        if (!target) return false;
        if (target.checked) {
          setSelectedItems(searchResults?.map(creator => (typeof creator.creator_id !== 'undefined' ? creator.creator_id : (typeof creator.publisher.creator_id !== 'undefined' ? creator.publisher.creator_id : ''))));
        } else {
          setSelectedItems([]);
        }
        return true;
      }}
    />),
    accessor: creator => (typeof creator.creator_id !== 'undefined' ? creator.creator_id : (typeof creator.publisher?.creator_id !== 'undefined' ? creator.publisher?.creator_id : '')),
    id: 'id',
    maxWidth: 30,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => (
      <FormGroup className="m-0">
        <CheckBoxField
          name={value || `disabled${Math.random().toString()}`}
          label=""
          value={selectedItems.includes(value)}
          onChange={({ target }) => {
            if (value && target) {
              selectCheckbox(value, target.checked);
            } else if (target) {
              showErrorNotice('Sorry, we are missing an identifier for the creator of this content so we can not save this item to a watchlist.');
            }
          }}
        />
      </FormGroup>
    ),
    disableSortBy: true,
    disableFilters: true,
  } */];
  if (activeTypeCheckbox === 'creator') {
    columns = columns.concat(getSearchCreatorColumns(currentWatchlist.value, addSearchItemToTheList, addItemsToTheProspects, setSingleSelectedCreatorId, showErrorNotice, setOpenRelatedPodcastModal));
  } else if (activeTypeCheckbox === 'video') {
    columns = columns.concat(getSearchVideoColumns(currentWatchlist.value, addSearchItemToTheList, addItemsToTheProspects, showErrorNotice));
  } else {
    columns = columns.concat(getSearchPodcastColumns(currentWatchlist.value, addSearchItemToTheList, showErrorNotice));
  }
  const tableOptionalHook = [((hooks) => {
    hooks.visibleColumns.push(columnsAll => [
      // Let's make a column for selection
      {
        minWidth: 60,
        width: 60,
        maxWidth: 60,
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        // eslint-disable-next-line react/prop-types
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          // eslint-disable-next-line react/prop-types
          const creatorId = row?.original.creator_id ? row?.original.creator_id : (row?.original.publisher?.creator_id || '');
          return (
            <div>
              {/* eslint-disable-next-line react/prop-types */}
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={creatorId === ''} disableMessage="Creator ID information is missing and cannot be added to a watchlist or Prospects." />
            </div>
          );
        },
      },
      ...columnsAll,
    ]);
  })];
  // console.log('RERENDER');

  const tableOptions = {
    columns,
    data: searchResults,
    updateDraggableData: () => {},
    defaultColumn: {},
    isEditable: false,
    withDragAndDrop: false,
    dataLength: total,
    autoResetPage: false,
    disableSortBy: false,
    manualSortBy: true,
    manualGlobalFilter: true,
    manualPagination: true,
    initialState: {
      pageIndex,
      pageSize,
    },
    pageCount: Math.ceil(Number(total || 0) / Number(perPage || 0)),
  };

  return (
    <>
      {(loading || atLeastOneSearchIsMade) && (
        <Card>
          <CardBody>
            <Row>
              <Col sm="12" className="agent-creators-list-dashboard">
                {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
                {!loading && atLeastOneSearchIsMade && (
                  <Row>
                    <Col md="4">
                      <h4><b>Search results for &apos;{searchedText}&apos;</b></h4>
                      Found {WVFormatter.formatIntNumber(searchMetadata.total)} result{searchMetadata.total !== 1 ? 's' : ''}
                    </Col>
                    {!loading && searchResults.length > 0 && (
                      <>
                        <Col md="4">
                          <div className="d-flex">
                            <span
                              style={{
                                paddingTop: '8px',
                                width: '115px',
                              }}
                            >
                              Current List:
                            </span>
                            <div className="w-100 research-watchlists">
                              <Select
                                value={currentWatchlist}
                                options={watchlists}
                                onChange={(e) => { currentWatchlistChanged(e); }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="4" style={{ textAlign: 'right' }}>
                          <UncontrolledButtonDropdown className="m-0">
                            <DropdownToggle id="actionsMenu" caret className="mr-2" color="primary" style={{ padding: '5px 25px' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i className="fas fa-plus-circle" /> Add &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem disabled={selectedItems.length === 0} onClick={addItemsToTheWatchlist}>
                                <Tooltip title="Update Status">
                                  <>Watchlists</>
                                </Tooltip>
                              </DropdownItem>
                              {activeTypeCheckbox !== 'podcast' && (
                                <DropdownItem disabled={selectedItems.length === 0} onClick={() => { addItemsToTheProspects(); }}>
                                  <Tooltip title="Update Status">
                                    <>Prospects</>
                                  </Tooltip>
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </Col>
                        <CreateWatchlistModal
                          isOpen={newListModalVisible}
                          listId={false}
                          toggleModal={() => setNewListModalVisible(false)}
                          onSaveRecordFunc={newWatchlistSaved}
                        />
                      </>
                    )}
                  </Row>
                )}
                {!loading && searchResults.length > 0 && (
                  <>
                    <AdminBroServerSide
                      key="common"
                      tableConfig={{
                        isEditable: false,
                        isResizable: false,
                        isSortable: false,
                        withPagination: false,
                        withSearchEngine: false,
                        manualPageSize: [25],
                        placeholder: 'Search ...',
                        gotoPage,
                      }}
                      tableOptions={tableOptions}
                      tableOptionalHook={tableOptionalHook}
                      isLoading={loading}
                      pageIndex={(pageNumber - 1)}
                      updateSelect={(e) => {
                        const keys = Object.keys(e)?.map(k => (searchResults[k].creator_id ? searchResults[k].creator_id : (searchResults[k].publisher?.creator_id || ''))).filter(k => k !== '');
                        console.log(keys);
                        if (keys.includes('')) {
                          showErrorNotice('Creator ID information is missing and cannot be added to a watchlist or Prospects.');
                        } else {
                          setSelectedItems(keys);
                        }
                        // if (checkboxHandler) checkboxHandler(keys);
                      } /* checkboxHandler */}
                    />
                    <Pagination className="pagination" dir="ltr">
                      <div className="pagination">
                        <PaginationLink
                          className="pagination__link pagination__link--arrow"
                          type="button"
                          onClick={() => { gotoPage(pageNumber - 2); }}
                          disabled={pageNumber === 1}
                        >
                          <ChevronLeftIcon className="pagination__link-icon" />
                        </PaginationLink>
                        {Object.keys(searchTokens[activeTypeCheckbox])?.map((i) => {
                          if (parseInt(i, 10) === Object.keys(searchTokens[activeTypeCheckbox]).length - 1 && !nextPageExists) {
                            return '';
                          }
                          return (
                            <PaginationItem
                              className="pagination__item"
                              active={pageNumber - 1 === parseInt(i, 10)}
                              key={i}
                            >
                              <PaginationLink
                                key={i}
                                className="pagination__link"
                                type="button"
                                onClick={() => gotoPage(parseInt(i, 10))}
                              >
                                {parseInt(i, 10) + 1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                          })
                        }
                        <PaginationItem className="pagination__item">
                          <PaginationLink
                            className="pagination__link pagination__link--arrow"
                            type="button"
                            onClick={() => { gotoPage(pageNumber); }}
                            disabled={pageNumber === searchTokens.length || !nextPageExists}
                          >
                            <ChevronRightIcon className="pagination__link-icon" />
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className="pagination__item pagination-info">
                          Showing {(pageSize * pageIndex) + 1} to {(pageSize * pageIndex) + pageSize} of {WVFormatter.formatIntNumber(searchMetadata.total)}
                        </PaginationItem>
                      </div>
                    </Pagination>
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
          {singleSelectedCreatorId && (
            <CreatorDetailsModal
              isOpen={!!singleSelectedCreatorId}
              creatorId={singleSelectedCreatorId}
              toggleModal={() => setSingleSelectedCreatorId(null)}
            />
          )}
          {openRelatedPodcastModal.isOpen && (
            <RelatedPodcastsModal
              isOpen={!!openRelatedPodcastModal.isOpen}
              creatorId={singleSelectedCreatorId}
              title={openRelatedPodcastModal.title}
              toggleModal={() => { setSingleSelectedCreatorId(null); setOpenRelatedPodcastModal({ isOpen: false, title: '' }); }}
            />
          )}
          <AddToProspectsModal
            title="Add To Prospects"
            modal={addToProspectsModalVisible}
            setModalVisible={setAddToProspectsModalVisible}
            addToProspects={addToProspects}
            loading={loadingAddToProspect}
            addToProspectsResults={addToProspectsResults}
            selectedItemsForProspect={selectedItemsForProspect}
            numberOfFound={searchMetadata.total}
            analysts={adminUsers}
            currentAdminUser={currentAdminUser}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            getSearchDataToSave={getSearchDataToSave}
          />
        </Card>
      )}
    </>
  );
};
ChannelsListContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  searchMetadata: PropTypes.shape({
    total: PropTypes.number.isRequired,
  }).isRequired,
  gotoPage: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  atLeastOneSearchIsMade: PropTypes.bool.isRequired,
  searchedText: PropTypes.string.isRequired,
  activeTypeCheckbox: PropTypes.string.isRequired,
  searchTokens: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  addSearchItemToTheList: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  getSearchDataToSave: PropTypes.func.isRequired,
  watchlists: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  getMyWatchlists: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  loadingAddToProspect: PropTypes.bool.isRequired,
  addToProspectsResults: PropTypes.objectOf(PropTypes.any).isRequired,
  searchSortOptions: PropTypes.objectOf(PropTypes.any),
  searchFilters: PropTypes.objectOf(PropTypes.any),
  searchQueryText: PropTypes.string,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentAdminUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

ChannelsListContainer.defaultProps = {
  searchQueryText: '',
  searchFilters: {},
  searchSortOptions: {},
};

const mapStateToProps = state => ({
  loadingAddToProspect: state.ResearchReducer.loading.addToProspects,
  addToProspectsResults: state.ResearchReducer.addToProspectsResults,
  adminUsers: state.ResearchReducer.adminUsers,
  currentAdminUser: state.session,
});

export default connect(mapStateToProps)(withRouter(withNotice(ChannelsListContainer)));
