import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row, Col, Container, Card, CardBody, CardTitle,
} from 'reactstrap';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import { NoResourceError } from '../../../../shared/components/components/ui/error404';
import Table from './components/Table';
import Actions from './components/Actions';
import NotesModal from './components/modals/NotesModal';
import getTableColumns from './components/custom/TableColumns';
import ChangeHistoryModal from './components/modals/ChangeHistoryModal';
import UpdateStatusModal from './components/modals/UpdateStatusModal';
import RefreshYoutubeDialog from '../MediaContent/components/custom/modals/RefreshYoutubeDialog';

const ContentManagement = ({ resources }) => {
  

  const [disableNote, setDisableNote] = useState(false);
  const [currentContent, setCurrentContent] = useState({});
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [notesType, setNotesType] = useState('influenceLogic');
  const [bulkUpdateType, setBulkUpdateType] = useState('influenceLogic');
  const [showStatusHistoryModal, setShowStatusHistoryModal] = useState(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [version, setVersion] = useState((new Date()).toString());
  const [selectedContentIds, setSelectedContentIds] = useState([]);
  const [loadingFiltersData, setLoadingFiltersData] = useState(true);

  const customColumns = useMemo(() => getTableColumns({
    setVersion,
    setDisableNote,
    setCurrentContent,
    setShowNotesModal,
    setNotesType,
    setShowStatusHistoryModal,
  }), []);

  const handleSelectCheckbox = (selectedIds) => {
    setSelectedContentIds(selectedIds);
  };

  const downloadCsv = () => {
    const search = new URLSearchParams(window.location.search);
    const url = `/api/portal/compliance/get-content-management-csv?${search.toString()}`;
    const win = window.open(url, '_blank');
    win.focus();
  };
  const closeStatusModal = (updated = false) => {
    setShowUpdateStatusModal(false);
    if (updated) {
      setVersion(new Date().toString());
    }
  };

  const resource = resources.find(r => r.id === 'MediaContent');
  if (!resource) {
    return (<NoResourceError resourceId="MediaContent" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Compliance', path: false },
              { title: 'Content Management', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <h3>Content Management</h3>
                <hr className="mt-3 mb-4" />
              </CardTitle>
              <Actions
                setShowUpdateStatusModal={setShowUpdateStatusModal}
                selectedContentIds={selectedContentIds}
                downloadCsv={downloadCsv}
                setLoadingFiltersData={setLoadingFiltersData}
                setBulkUpdateType={setBulkUpdateType}
              />
              <Row>
                <Table
                  action={action}
                  resource={resource}
                  version={version}
                  customColumns={customColumns}
                  handleSelectCheckbox={handleSelectCheckbox}
                  loadingFiltersData={loadingFiltersData}
                />
              </Row>
            </CardBody>
          </Card>
          <NotesModal
            isOpen={showNotesModal}
            setVersion={setVersion}
            disabled={disableNote}
            closeFunc={() => { setShowNotesModal(false); }}
            record={currentContent}
            notesType={notesType}
          />
          <ChangeHistoryModal
            record={currentContent}
            modal={showStatusHistoryModal}
            setModalVisible={setShowStatusHistoryModal}
          />
          <UpdateStatusModal
            isOpen={showUpdateStatusModal}
            closeFunc={closeStatusModal}
            ids={selectedContentIds}
            type={bulkUpdateType}
          />
        </Col>
      </Row>
      <RefreshYoutubeDialog afterSaveFunc={() => setVersion((new Date()).toString())} />
    </Container>
  );
};
ContentManagement.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(ContentManagement);
