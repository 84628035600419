import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import UserPropertyType from './custom';
import Actions from './custom/Actions';
import Roster from './custom/Roster';
import ContextMenuInList from './custom/ContextMenuInList';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import FormModal from '../../../../../shared/components/modals/Action';
import Form from '../../../../../shared/components/form/Resource';

const ListContainer = (props) => {
  const {
    resource,
    action,
    history,
  } = props;

  const [showForm, toggleForm] = useState(false);
  const [showRoster, toggleRoster] = useState(false);
  const [id, setId] = useState(null);
  const [version, setVersion] = useState((new Date()).toString());

  const formFields = {
    agencyName: { label: 'Agency Name', required: true },
    contactFirstName: { label: 'Contact First Name' },
    contactLastName: { label: 'Contact Last Name' },
    contactEmail: { label: 'Contact Email', format: 'email' },
    website: { label: 'Website URL', format: 'url', required: true },
  };

  const addRow = (id) => {
    setId(null);
    toggleForm(true);
  };

  const editRow = (id) => {
    setId(id);
    toggleForm(true);
  };

  const openRoster = (id) => {
    setId(id);
    toggleRoster(true);
  };

  return (
    <Container className="dashboard">
      <Breadcrumbs
        links={[
          { title: 'Home', path: '/' },
          { title: 'Agencies', path: false },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row>
              <Col>
                <h3>Agencies</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-0" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                history={history}
                addRow={addRow}
              />
              <div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
                <MatTableList
                  action={action}
                  resource={resource}
                  sortBy="agencyName"
                  direction="asc"
                  date={new Date()}
                  UserPropertyType={UserPropertyType}
                  showTitle={false}
                  showFilters={false}
                  isResizable={false}
                  version={version}
                  v={2}
                  useFlex
                  hideToolbar
                  noActions
                  columnsSettings={{
                    agencyName: {
                      width: 60,
                      maxWidth: 60,
                    },
                    contactName: {
                      width: 60,
                      maxWidth: 60,
                    },
                    contactEmail: {
                      width: 100,
                      maxWidth: 100,
                    },
                    contextMenuAgency: {
                      Header: '',
                      accessor: () => null,
                      Cell: p => (
                        <ContextMenuInList
                          record={p.row.original}
                          edit={editRow}
                          openRoster={openRoster}
                        />
                      ),
                      disableSortBy: true,
                      className: 'align-self-center',
                      width: 30,
                      maxWidth: 30,
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <FormModal
        isOpen={showForm}
        toggle={toggleForm}
      >
        <Form
          resource={resource}
          fields={formFields}
          label='Agency'
          id={id}
          setVersion={setVersion}
        />
      </FormModal>
      <FormModal
        isOpen={showRoster}
        toggle={toggleRoster}
        label="Agent Roster"
        customStyle={{ minWidth: '800px' }}
      >
        <Roster
          id={id}
        />
      </FormModal>
    </Container>
  );
};

ListContainer.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default ListContainer;
