import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import ApiClient from 'ApiClient';
import StyledButton from '../../../../../../shared/components/components/ui/styled-button';
import withNotice from '../../../../../App/store/with-notice';

const DeleteModalDialog = ({
  isOpen,
  addNotice,
  id,
  toggleModal,
  onDeleteRecordFunc,
}) => {
  const [loading, setLoading] = useState(false);

  const closeFormDialog = () => {
    toggleModal();
    setLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const api = new ApiClient();
    const response = await api.recordAction({ // TODO: delete creators of a watchlist as well
      resourceId: 'ResearchWatchlist', actionName: 'delete', recordId: id,
    });
    closeFormDialog();
    if (response.status === 200) {
      addNotice({
        message: 'The selected watchlist has been deleted',
        type: 'success',
      });
      onDeleteRecordFunc();
    } else {
      console.log('Response of deleting watchlist', response);
      addNotice({
        message: 'Couldn\'t delete the selected Watchlist',
        type: 'error',
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeFormDialog}
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">Delete List</h4>
      </div>
      <div className="modal__body finance-form">
        <form onSubmit={handleSubmit}>
          {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
          {!loading && (
            <div>
              <h4>Are you sure you want to delete the selected Watchlist?</h4>
            </div>
          )}
        </form>
      </div>
      <div className="modal__footer">
        {!loading && (
          <>
            <StyledButton
              type="button"
              className="btn-danger"
              onClick={handleSubmit}
            >
              <i className="icomoon-save" />
              <span className="btn-text">Delete</span>
            </StyledButton>
            &nbsp;&nbsp;
            <StyledButton
              type="button"
              className="is-primary"
              onClick={closeFormDialog}
            >
              <i className="icomoon-save" />
              <span className="btn-text">Cancel</span>
            </StyledButton>
          </>
        )}
      </div>
    </Modal>
  );
};


DeleteModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  addNotice: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onDeleteRecordFunc: PropTypes.func.isRequired,
  id: PropTypes.string,
};

DeleteModalDialog.defaultProps = {
  id: null,
};

const mapStateToProps = state => ({
  adminUser: state.session,
});

export default withNotice(connect(mapStateToProps)(DeleteModalDialog));
