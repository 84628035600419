/* eslint react/prop-types: 0 */
import React from 'react';

import SortLink from '../sort-link';
import Th from './styled/th.styled';

const PropertyHeader = (props) => {
  const { property, titleProperty } = props;

  const isMain = property.name === titleProperty.name;

  return (
    <Th className={isMain ? 'main' : undefined}>
      {property.isSortable ? <SortLink {...props} /> : property.label}
    </Th>
  );
};
export default PropertyHeader;
