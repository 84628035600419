import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { Col, Container, CustomInput, Row, Card, CardBody, ButtonToolbar, FormGroup, FormFeedback, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import withNotice from '../../../App/store/with-notice';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import ButtonWithSpinner from '../../../../shared/components/ButtonWithSpinner';
import WVValidator from '../../../../shared/helpers/WVValidator';
import MobileCreatorsTable from './components/MobileCreatorsTable';

class PushNotifications extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      body: '',
      category: 'marketing',
      destination: 'all',
      selectedIds: [],
      errors: {},
      loading: {
        sendNotification: false,
      },
    };
  }

  componentDidMount() {
    document.title = 'IL Admin: Push Notifications';
  }

  onSendButton = () => {
    if (!this.validateErrors()) { return; }

    this.setState({
      loading: { sendNotification: true },
    });

    const {
      title,
      category,
      body,
      destination,
      selectedIds,
    } = this.state;

    axios({
      method: 'post',
      url: '/api/portal/mobile/push-notification',
      data: {
        title,
        category,
        body,
        destination,
        influencerIds: selectedIds,
      },
    }).then((response) => {
      if (response.data.success !== true) {
        this.processError(response.data.error);
        return;
      }

      // SUCCESS
      this.setState({
        title: '',
        body: '',
        loading: { sendNotification: false },
      });

      this.props.addNotice({
        message: 'Your notification has been successfully sent!',
        type: 'success',
      });
    }).catch((error) => {
      this.processError(error.message);
    });
  }

  onDataChanged = (event) => {
    const { name, value } = event.target;
    const state = {
      ...this.state,
      [name]: value,
    };

    delete state.errors[name]; // clear errors

    this.setState(state);
  }

  onCreatorsTableRowSelected = (rows) => {
    const selectedIds = [];
    [...rows.entries()].forEach(([id, isSelected]) => {
      if (isSelected) {
        selectedIds.push(id);
      }
    });

    this.setState({ selectedIds });
  }

  validateErrors() {
    const { body, destination, selectedIds } = this.state;

    const errors = {};
    if (WVValidator.isEmpty(body)) {
      errors.body = 'Please fill up Notification Body!';
    }
    if (destination === 'selected' && selectedIds.length < 1) {
      this.props.addNotice({
        message: 'Please select at least one creator!',
        type: 'error',
      });
      return false;
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  processError(error) {
    const message = error || 'Something went wrong!';

    this.setState({
      loading: { sendNotification: false },
    });

    this.props.addNotice({
      message,
      type: 'error',
    });
  }

  render() {
    const {
      title,
      body,
      destination,
      errors,
      loading,
    } = this.state;

    return (
      <Container>
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            { title: 'Push Notifications', path: null },
          ]}
          isBackButton={false}
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="il-color-deep-blue font-weight-bold">Push Notifications</h3>
                  </Col>
                </Row>
                <hr className="mt-3 mb-4" />
                <section className="pb-3">
                  <FormGroup>
                    <Label>Title</Label>
                    <Input
                      type="text"
                      name="title"
                      value={title}
                      placeholder="Title (optional)"
                      onChange={this.onDataChanged}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Category</Label>
                    <Input type="select" name="category" onChange={this.onDataChanged}>
                      <option value="marketing">Marketing</option>
                      <option value="service">Service</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Body</Label>
                    <Input
                      type="textarea"
                      rows="5"
                      name="body"
                      placeholder="Body"
                      value={body}
                      invalid={!!errors.body}
                      onChange={this.onDataChanged}
                    />
                    <FormFeedback>{errors.body}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <div>
                      <CustomInput
                        type="radio"
                        id="destinationAllRadio"
                        name="destination"
                        value="all"
                        label="Send to all"
                        checked={destination === 'all'}
                        onChange={this.onDataChanged}
                      />
                      <CustomInput
                        type="radio"
                        id="destinationSelectedRadio"
                        name="destination"
                        value="selected"
                        label="Send to selected creators"
                        checked={destination === 'selected'}
                        onChange={this.onDataChanged}
                      />
                    </div>
                  </FormGroup>
                </section>
                {
                  destination === 'selected' &&
                  <MobileCreatorsTable selectedEvent={this.onCreatorsTableRowSelected} />
                }
                <ButtonToolbar className="form__button-toolbar mt-4">
                  <ButtonWithSpinner
                    type="button"
                    color="primary"
                    onClick={this.onSendButton}
                    loading={loading.sendNotification}
                  >
                    <span>Send Notification</span>
                  </ButtonWithSpinner>{' '}
                </ButtonToolbar>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

PushNotifications.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  rtl: state.rtl,
});

export default withNotice(connect(mapStateToProps)(PushNotifications));
