import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { getCreators, editPayment, refreshHubspot, getAdditionalContacts } from '../../../../../redux/reducers/admin/AgentReducer';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import AgentProfile from './AgentProfile';
import CreatorsList from './CreatorsList';
import AgentRelatedData from './AgentRelatedData';
import ContactProfile from "./ContactProfile";

class ResourceAction extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loadingPayment: PropTypes.bool.isRequired,
    agent: PropTypes.objectOf(PropTypes.any).isRequired,
    adminUser: PropTypes.objectOf(PropTypes.any).isRequired,
    employee: PropTypes.objectOf(PropTypes.any).isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  componentDidMount() {
    const { match, dispatch } = this.props;
    if (match) {
      dispatch(getCreators(match.params.agentId));
      dispatch(getAdditionalContacts(match.params.agentId));
    }
  }

  render() {
    const {
      loading, loadingPayment, history, dispatch, match,
      agent, adminUser, employee,
    } = this.props;
    return (
      <Container className="dashboard admin-dashboard full-width">
        <Row>
          <Col md={12}>
            <Breadcrumbs
              isBackButton
              links={[
                { title: 'Home', path: '/' },
                { title: 'Agent Users', path: '/resources/AgentUser/actions/list' },
                { title: 'Agent Profile', path: false },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <AgentProfile
              agentId={match.params.agentId}
              agent={agent}
              adminUser={adminUser}
              employee={employee}
              loading={loading}
              loadingPayment={loadingPayment}
              reloadAgent={() => dispatch(getCreators(agent.id))}
              connectBillCom={() => history.push(`/resources/AgentUser/agent-bill-com/${agent.id}`)}
              inviteBillCom={() => history.push(`/billCom/invite/${agent.id}/agent`)}
              editPayment={value => dispatch(editPayment(agent.id, value))}
              refreshHubspot={() => dispatch(refreshHubspot())}
              goToDealEditor={() => history.push(`/resources/AgentUser/agent-deal-editor/${agent.id}`)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ContactProfile loading={loading} contact={agent.params.contact} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <CreatorsList />
          </Col>
        </Row>
        <AgentRelatedData
          agentId={match.params.agentId}
          dispatch={dispatch}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.AgentReducer.loading.creators,
  loadingPayment: state.AgentReducer.loading.payment,
  agent: { id: state.AgentReducer.currentAgent?._id, params: state.AgentReducer.currentAgent },
  adminUser: state.AgentReducer.adminUser,
  employee: state.AgentReducer.employee,
});

export default connect(mapStateToProps)(ResourceAction);
