import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { unflatten } from 'flat';

const MediaContentInList = React.memo(({
  record,
}) => {
  const [image, setImage] = useState('/images/brands/thumbnail-default.png');
  const videoStatistic = unflatten(record).params;

  useEffect(async () => {
    setImage(videoStatistic?.mediaContentObject?.thumbnail || '/images/brands/thumbnail-default.png');
  }, [record.id]);

  return (
    <div
      className="d-flex"
      style={{ minWidth: '350px' }}
    >
      <img
        src={image}
        alt="Thumbnail"
        style={{ width: '50px', height: 'auto' }}
        className="align-self-center"
      />
      <div
        className="align-self-center flex-column"
        style={{ marginLeft: '8px' }}
      >
        <div className="firstAndLastName">
          {videoStatistic?.videoUrl ? (
            <a
              title="Open Video"
              target="_blank"
              rel="noopener noreferrer"
              href={videoStatistic.videoUrl}
            >
              {videoStatistic?.mediaContentObject?.title || ''}
            </a>
            ) : videoStatistic?.mediaContentObject?.title || ''
          }
        </div>
        <div className="" >
          {videoStatistic?.publisher?.creator_name && (
            <span>{videoStatistic?.publisher?.creator_name}</span>
          )}
        </div>
      </div>
    </div>
  );
}, (a, a1) => a.record.id === a1.record.id);

MediaContentInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MediaContentInList;
