import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
// import { CKEditor } from 'ckeditor4-react';
import withNotice from '../../../../../../../App/store/with-notice';
import { hideModalById } from '../../../../../../../../redux/reducers/TableReducer';
import Alert from '../../../../../../../../shared/easydev2/shared/components/Alert';


export const modalId = 'newCpmInvoice';

const NewInsertionOrder = ({
  updateTable,
  addNotice,
}) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [description, setDescription] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState(null);

  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const dispatch = useDispatch();
  const { isShow } = modalInfo || {};
  useEffect(() => {
    setInvoiceNumber('');
    setDescription('');
    setSelectedBrand(null);
    setStartDate(null);
    setEndDate(null);
    setError(null);
  }, [isShow]);

  if (!modalInfo) return false;
  const close = () => dispatch(hideModalById(modalId));


  /* const handleInputChange = (e) => {
    const { editor } = e;
    setDescription(editor.getData());
  }; */
  const loadBrandsOptions = async (inputValue) => {
    const response = await axios({
      method: 'get',
      url: `/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`,
    });
    if (response.data.success) {
      const res = [];
      response.data.brands.forEach((record) => {
        res.push({
          value: record._id,
          label: record.companyName,
          record,
        });
      });
      return res;
    }
    return [];
  };

  const saveCPMInsertionOrder = () => {
    const data = {
      invoiceNumber,
      description,
      brand: selectedBrand?.value || null,
      startDate: moment.utc(moment(startDate).format('YYYY-MM-DD')).toDate(),
      endDate: moment.utc(moment(endDate).format('YYYY-MM-DD')).toDate(),
    };
    const validationErrors = {};
    if (!selectedBrand) validationErrors.brand = true;
    if (!invoiceNumber) validationErrors.invoiceNumber = true;
    if (!startDate) validationErrors.startDate = true;
    if (!endDate) validationErrors.endDate = true;
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      axios.post('/api/portal/prospects/post-add-cpm-invoice', { ...data }).then((response) => {
        if (response.data.success) {
          setLoading(false);
          addNotice({
            message: 'Invoice has been saved',
            type: 'success',
          });
          updateTable();
          close();
        } else {
          setLoading(false);
          setError(response.data?.error || 'Unable to save Insertion Order');
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isShow}
      toggle={() => close()}
      style={{ minWidth: '670px' }}
    >
      <ModalHeader
        toggle={() => close()}
        tag="h4"
        className="modal-title-statistics pl-0"
      >
        New CPM Invoice
      </ModalHeader>
      <ModalBody>
        {error ? (
          <Alert color="danger" bordered icon><p className="py-2">{error}</p></Alert>
        ) : null}
        <Row>
          <Col>
            <FormGroup>
              <Label>Invoice No.</Label>
              <Input
                type="text"
                value={invoiceNumber}
                invalid={!!errors.invoiceNumber}
                onChange={(e) => {
                  setInvoiceNumber(e.target.value);
                  if (e.target.value) {
                    setErrors(er => ({
                      ...er,
                      invoiceNumber: false,
                    }));
                  }
                }}
                placeholder="Invoice No."
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Target Brand</Label>
              <SelectAsync
                cacheOptions
                value={selectedBrand}
                defaultOptions
                loadOptions={loadBrandsOptions}
                onChange={(selected) => {
                  setSelectedBrand(selected);
                  if (selected.value) {
                    setErrors(e => ({
                      ...e,
                      brand: false,
                    }));
                  }
                }}
                placeholder=""
              />
              {errors.brand ? <div className="grey-small-text grey-minus-margin" style={{ color: 'red' }}>Required</div> : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup row>
              <Label className="pt-2 pl-3">Start Date</Label>
              <Col>
                <DatePicker
                  selected={startDate}
                  onChange={setStartDate}
                  dateFormat="MM/dd/yyyy"
                  // minDate={minDate.toDate()}
                  // maxDate={maxDate.toDate()}
                  placeholderText="Start Date (ET)"
                  dropDownMode="select"
                  className={`form-control ${errors.startDate ? 'is-invalid' : ''}`}
                  wrapperClassName="w-100"
                />
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label className="pt-2 pl-3">End Date</Label>
              <Col>
                <DatePicker
                  selected={endDate}
                  onChange={setEndDate}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="End Date (ET)"
                  // minDate={minDate.toDate()}
                  // maxDate={maxDate.toDate()}
                  dropDownMode="select"
                  className={`form-control ${errors.endDate ? 'is-invalid' : ''}`}
                  wrapperClassName="w-100"
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Description</Label>
              <Input
                type="textarea"
                name="itemText"
                activeClass="form-control w-100"
                value={description}
                onChange={(e) => { setDescription(e.target.value); }}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={saveCPMInsertionOrder} disabled={loading} style={{ marginRight: '10px' }}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>
        <Button className="modal_cancel btn-sm" onClick={() => close()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};


NewInsertionOrder.propTypes = {
  updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(NewInsertionOrder);
