import React from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  FormGroup,
} from 'reactstrap';
import CheckBoxField from '../CheckBox';

const CheckboxList = ({
  label,
  values,
  setValues,
}) => {
  const onChange = (e, name) => {
    if (e?.target) {
      const { checked } = e.target;
      setValues({ ...values, [name]: checked });
    }
  };
  return (
    <>
      <Label className="bold-text" style={{ marginBottom: '14px' }}>
        {label}
      </Label>
      {Object.keys(values).map(key => (
        <FormGroup
          key={key}
          style={{ marginBottom: '10px' }}
        >
          <CheckBoxField
            name={key.replace(' ', '')}
            label={key}
            value={values[key]}
            onChange={(e) => { onChange(e, key); }}
          />
        </FormGroup>
      ))}
    </>
  );
};

CheckboxList.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  setValues: PropTypes.func.isRequired,
};

export default CheckboxList;
