import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
} from 'reactstrap';
import { axios } from 'ApiClient';
import withNotice from '../../../../../../../App/store/with-notice';
import formater from '../../../../../../../../shared/helpers/WVFormatter';

const DownloadCsvModal = ({
  isOpen,
  closeFunc,
  getUrlSearch,
}) => {
  const [csvDownloadLimit, setCsvDownloadLimit] = useState(0);

  useEffect(async () => {
    const res = await axios.get('/api/portal/prospects/get-prospect-csv-download-limit');
    if (res.data.success) {
      setCsvDownloadLimit(formater.formatIntNumber(res.data.limit));
    }
  }, []);

  const submit = async () => {
    window.open(`/resources/prospects/download-csv?${getUrlSearch().toString()}`, '_blank').focus();
    closeFunc();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '400px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">
        Download Prospects
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <span>This will download only {csvDownloadLimit} prospects. Use filters to reduce and to make sure desired prospects get exported.</span>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" size="lg" onClick={submit}>
          <span>Proceed</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="secondary" size="lg" onClick={() => closeFunc()}>
          Cancel
        </Button>
      </ButtonToolbar>
    </Modal>
  );
};


DownloadCsvModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  getUrlSearch: PropTypes.func.isRequired,
};

export default withNotice(DownloadCsvModal);
