
import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CreatorInList from './Table/CreatorInList';
import formater from '../../../../shared/helpers/WVFormatter';

export default () => [
  {
    Header: 'Creator',
    accessor: p => `${p?.creator?.first_name || ''} ${p?.creator?.last_name || ''}`,
    id: 'Creator',
    Cell: p => useMemo(() => (
      <CreatorInList record={p.row.original.creator} prospects={p.row.original.prospects} />
    ), [p.row.original.creator]),
    width: 300,
  },
  {
    Header: 'Account Manager',
    accessor: p => (`${p.adminAF?.firstName || ''} ${p.adminAF?.lastName || ''}`),
    id: 'dealOwner',
    Cell: p => p.value,
    width: 100,
  },
  {
    Header: 'Deal Name',
    accessor: deal => (deal.latestDeal?.dealname || ''),
    Cell: p => useMemo(() => {
      console.log();
      return (
        <div>
          {p.value}{' '}
          {p.row.original.latestDeal.hs_object_id ? (
            <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original?.latestDeal.hs_object_id}/`} target="_blank" rel="noreferrer">
              <Tooltip title="Open Hubspot Deal">
                <IconButton size="small">
                  <i className="fab fa-hubspot text-primary" style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </a>) : null}
        </div>
      );
    }, [p.row.original.latestDeal]),
    width: 120,
  },
  {
    Header: 'Type Of Deal',
    accessor: deal => (deal.latestDeal?.dealTerms?.type_of_deal || ''),
    width: 120,
  },
  {
    Header: 'Publish Date',
    accessor: p => p.publishDate || null,
    id: 'publishDate',
    Cell: p => (p.value ? moment.tz(moment.utc(p.value).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') : ''),
    width: 100,
  },
  {
    Header: 'Days Since Last Publish',
    accessor: p => (p.startPublishDate ? moment.tz('America/New_York').diff(moment.tz(moment.utc(p.publishDate).format('YYYY-MM-DD'), 'America/New_York'), 'days') : '-'),
    id: 'days',
    Cell: p => p.value,
    width: 100,
  },
  {
    Header: 'No. Days Between Publish',
    accessor: p => (p.dealsCount?.length > 1 ? moment.tz(p.endPublishDate, 'America/New_York').diff(moment.tz(moment.utc(p.publishDate).format('YYYY-MM-DD'), 'America/New_York'), 'days') : '-'),
    id: 'daysbetween',
    Cell: p => p.value,
    width: 100,
  },
  {
    Header: 'Revenue',
    accessor: deal => Number(deal?.revenue || 0),
    id: 'revenue',
    Cell: p => formater.formatCurrency(p.value || 0),
    width: 120,
  },
  {
    Header: 'Costs',
    accessor: deal => Number(deal?.payout || 0),
    id: 'costns',
    Cell: p => formater.formatCurrency(p.value || 0),
    width: 120,
  },
  {
    Header: 'Gross Profit',
    accessor: deal => Number(deal?.revenue || 0) - Number(deal?.payout || 0),
    id: 'gp',
    Cell: p => formater.formatCurrency(p.value || 0),
    width: 120,
  },
  {
    Header: () => (
      <>
        Rev. Conv. in Timeframe{' '}
        <Tooltip title="Number of conversions generated within the specified date range">
          <IconButton
            id="filtersMenuButton1"
            size="small"
            className="material-table__toolbar-button"
          >
            <i className="fa fa-info-circle" style={{ fontSize: '5em' }} />
          </IconButton>
        </Tooltip>
      </>
    ),
    accessor: deal => Number(deal.cpaCurrent?.length || 0 + deal.cpmCurrent?.length || 0),
    id: 'conversions',
    Cell: p => formater.formatIntNumber(p.value),
    width: 120,
  },
  {
    Header: () => (
      <>
        No. All Time Rev. Conv.{' '}
        <Tooltip title="Total number of conversions all time for this deal regardless of the date range specified">
          <IconButton
            id="filtersMenuButton11"
            size="small"
            className="material-table__toolbar-button"
          >
            <i className="fa fa-info-circle" style={{ fontSize: '5em' }} />
          </IconButton>
        </Tooltip>
      </>
    ),
    accessor: deal => Number(deal.cpaTotal.length) + Number(deal.cpmTotal.length),
    id: 'conversionsTotal',
    Cell: p => formater.formatIntNumber(p.value),
    width: 120,
  },
  {
    Header: () => (
      <>
        No. Deals{' '}
        <Tooltip title="Number of deals published for this Brand in the specified date range">
          <IconButton
            id="filtersMenuButton2"
            size="small"
            className="material-table__toolbar-button"
          >
            <i className="fa fa-info-circle" style={{ fontSize: '5em' }} />
          </IconButton>
        </Tooltip>
      </>
    ),
    accessor: deal => deal.dealsCount?.length || 0,
    id: 'deals',
    Cell: p => formater.formatIntNumber(p.value),
    width: 120,
  },
  {
    Header: () => (
      <>
        No. Content Published{' '}
        <Tooltip title="Number of media content published for this Brand in the specified date range">
          <IconButton
            id="filtersMenuButton3"
            size="small"
            className="material-table__toolbar-button"
          >
            <i className="fa fa-info-circle" style={{ fontSize: '5em' }} />
          </IconButton>
        </Tooltip>
      </>
    ),
    accessor: deal => deal.mediaContentsCount || 0,
    id: 'mediaContents',
    Cell: p => formater.formatIntNumber(p.value),
    width: 120,
  },
  {
    Header: 'CAC',
    accessor: deal => (Number(deal.cpaCurrent?.length || 0 + deal.cpmCurrent?.length || 0) > 0 ? Number(deal?.payout || 0) / Number(deal.cpaCurrent?.length || 0 + deal.cpmCurrent?.length || 0) : 0),
    id: 'cac',
    Cell: p => formater.formatCurrency(p.value),
    width: 80,
  },
  /* {
    Header: 'Last Publish Date',
    accessor: p => p.publishDate || null,
    id: 'publish_date1',
    Cell: p => (p.value ? moment.tz(p.value, 'America/New_York').format('MM/DD/YYYY') : ''),
    width: 100,
  },
  {
    Header: 'Days Since Last Publish',
    accessor: p => (p.publishDate ? moment.tz('America/New_York').diff(moment.tz(p.publishDate, 'America/New_York'), 'days') : '-'),
    id: 'days2',
    Cell: p => p.value,
    width: 100,
  }, */
];

