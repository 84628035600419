import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import _ from 'lodash';

const BlacklistedInList = React.memo(({
  record,
  updateRecordFunction,
}) => {
  const val = record.params.blacklist_;
  const [selectedOption, setSelectedOption] = useState(val ? { value: true, label: 'Yes' } : { value: false, label: 'No' });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    setSelectedOption(val ? { value: true, label: 'Yes' } : { value: false, label: 'No' });
    setDisabled(false);
  }, [record.id, record.params.blacklist_]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    const prevValue = selectedOption;
    setSelectedOption(option);
    try {
      const data = await axios.post(`/api/portal/blacklist/post-${option.value ? 'add' : 'remove'}-item`, {
        hsContactId: record.params.hs_object_id,
      });
      if (!data.data.success) {
        setSelectedOption(prevValue);
      } else {
        updateRecordFunction(record.id, { blacklist_: option.value });
        setSelectedOption(option);
      }
    } catch (e) {
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };

  return (
    <div style={{ minWidth: '120px', maxWidth: '150px' }} >
      <Select
        value={selectedOption}
        className="inBlackListSelectBox"
        options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
        onChange={handleChange}
        placeholder=""
        isDisabled={disabled}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
      {error ? (<span className="text-danger">{error}</span>) : null}
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

BlacklistedInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};

export default BlacklistedInList;
