/* eslint-disable filenames/match-exported */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { calculatePar } from '../../helpers/CACHelper';
import { formatCurrency } from '../../helpers/WVFormatter';


const WideTooltip = withStyles({
  tooltip: {
    width: '500px',
  },
})(Tooltip);

const PAR = React.memo(({
  row,
  settings,
}) => {
  const par = calculatePar(row, settings);
  if (!par) return '-';
  const describeValue = (
    <div style={{ fontSize: '14px', maxWidth: '500px', lineHeight: 2 }}>
      <span><strong>Net PAR</strong>: {formatCurrency(par / 1.15)}</span><br />
    </div>);
  return (
    <>
      <WideTooltip title={describeValue}><span>{formatCurrency(par)}</span></WideTooltip>{' '}
    </>
  );
}, (a, a1) => _.isEqual(a.row.latestDeal, a1.row.latestDeal));

PAR.propTypes = {
  row: PropTypes.objectOf(PropTypes.any),
  settings: PropTypes.objectOf(PropTypes.any),
};
PAR.defaultProps = {
  row: {},
  settings: {},
};

export default PAR;
