import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  FormGroup,
} from 'reactstrap';
import { CKEditor } from 'ckeditor4-react';
import ApiClient from 'ApiClient';

const DealJustificationModal = ({
  isOpen,
  closeFunc,
  setVersion,
  prospect,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const [dealJustification, setDealJustification] = useState('');

  useEffect(() => {
    if (isOpen && prospect?.params?.dealJustification) {
      setDealJustification(prospect.params.dealJustification);
    } else setDealJustification('');
  }, [prospect]);

  const handleInputChange = (e) => {
    const { editor } = e;
    setDealJustification(editor.getData());
  };

  const submit = async () => {
    setLoading(true);

    const { id } = prospect;
    const data = new FormData();
    data.append('dealJustification', dealJustification);
    const api = new ApiClient();
    api.recordAction({
      resourceId: 'CpmProspect',
      recordId: id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      setLoading(false);
      setVersion((new Date()).toString());
      setDealJustification('');
      closeFunc();
    });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => { setDealJustification(''); closeFunc(); }}
      style={{ minWidth: '670px' }}
    >
      <ModalHeader
        toggle={() => {
          setDealJustification('');
          closeFunc();
        }}
        tag="h4"
        className="modal-title-statistics pl-0"
      >
        Deal Justification
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {prospect && !disabled && (
              <FormGroup>
                <CKEditor
                  config={{versionCheck: false}}
                  id="bodyEditor"
                  name="itemText"
                  activeClass="form-control"
                  initData={dealJustification}
                  onChange={(e) => { handleInputChange(e); }}
                />
              </FormGroup>
            )}
            {prospect && disabled && (
              <FormGroup>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: dealJustification }} />
              </FormGroup>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        {!disabled && (
          <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading} style={{ marginRight: '10px' }}>
            {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
            <span>Save</span>
          </Button>
        )}
        <Button className="modal_cancel btn-sm" onClick={() => { setDealJustification(''); closeFunc(); }}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};


DealJustificationModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  prospect: PropTypes.objectOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DealJustificationModal;
