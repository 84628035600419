/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React from 'react';
import { unflatten } from 'flat';
import { Modal, ButtonToolbar, Button } from 'reactstrap';
import PropTypes from 'prop-types';

const DetailsModal = ({
  modal,
  setModalVisible,
  record,
  q,
}) => {
  const prospect = unflatten(record);
  console.log(prospect);
  return (
    <Modal
      isOpen={modal}
      toggle={() => setModalVisible(false)}
    >
      <div className="modal__header">
        <h3 className="text-modal  modal__title">Details</h3>
      </div>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <div className="mt-1 mb-4">
          {prospect && (
            <>
              <h4 className="font-weight-bold">Upload Name</h4>
              <hr className="mt-1 mb-2" />
              <div className="mb-3">
                <div className="pl-3">{prospect.name}</div>
              </div>
              <h4 className="font-weight-bold">Upload Description</h4>
              <hr className="mt-1 mb-2" />
              <div className="mb-3">
                <div className="pl-3">{prospect.description || '-'}</div>
              </div>
              <h4 className="font-weight-bold">Upload Source</h4>
              <hr className="mt-1 mb-2" />
              <div className="mb-3">
                <div className="pl-3">{prospect.uploadSource}</div>
              </div>
            </>
          )}
          <h4 className="font-weight-bold">Tags</h4>
          <hr className="mt-1" />
          <div>
            <div className="pl-3">{q?.meta?.tags?.length > 0 ? q?.meta?.tags.map(t => t.name).join(', ') : '-'}</div>
          </div>
        </div>
      </div>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => setModalVisible(false)}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

DetailsModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  q: PropTypes.objectOf(PropTypes.any).isRequired,
};

DetailsModal.defaultProps = {

};

export default DetailsModal;
