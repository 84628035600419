import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonToolbar,
  FormGroup, Input, Label,
  Modal,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withNotice from '../../../../../App/store/with-notice';


const SaveSearchModal = ({
  isOpen,
  toggleModal,
  searchName,
  searchVisibility,
  savedSearchesNames,
  saveSearch,
  savingSearch,
  saveSearchModalMessage,
  searchOwnerId,
  adminUser,
}) => {
  const [name, setName] = useState('');
  const [visibility, setVisibility] = useState(searchVisibility);
  const [infoMessage, setInfoMessage] = useState(name ? '' : 'Please give this search a name');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(!name);

  const onSaveButtonClick = async () => {
    await saveSearch(name.trim(), visibility);
  };

  function shouldSaveButtonBeDisabled() {
    if (name) {
      if (savedSearchesNames.includes(name.trim())) {
        if (typeof searchOwnerId !== 'undefined' && searchOwnerId !== null && searchOwnerId !== '') {
          return visibility === 'public' && adminUser._id.toString() !== searchOwnerId.toString();
        }
        return false;
      }
      return false;
    }
    return true;
  }

  useEffect(async () => {
    if (name) {
      if (savedSearchesNames.includes(name.trim())) {
        if (searchOwnerId && visibility === 'public' && adminUser._id.toString() !== searchOwnerId?.toString()) {
          setInfoMessage('You cannot overwrite this Saved Search as it belongs to the other user.');
        } else {
          setInfoMessage('This will overwrite an existing saved search.');
        }
      } else setInfoMessage('');
    } else {
      setInfoMessage('Please give this search a name');
    }
    setSaveButtonDisabled(shouldSaveButtonBeDisabled());
  }, [name, savedSearchesNames, searchOwnerId]);

  useEffect(async () => {
    if (searchName) setName(searchName);
  }, [searchName]);

  useEffect(async () => {
    if (saveSearchModalMessage) setInfoMessage(saveSearchModalMessage);
  }, [saveSearchModalMessage]);

  useEffect(async () => {
    if (searchVisibility) setVisibility(searchVisibility);
  }, [searchVisibility]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
      size="lg"
      style={{
        maxWidth: '900px',
        fontSize: '105%',
        fontStretch: '90%',
      }}
    >
      <div className="modal__header mb-0">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
        <h4 className="text-modal modal__title mb-1">
          Save Search
        </h4>
        <div>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              id="saved_search_name"
              name="saved_search_name"
              onChange={e => setName(e.target.value)}
              value={name}
            />
            {infoMessage && (
              <span
                className="help-block"
              >
                {infoMessage}
              </span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Visibility</Label>
            <Row>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Input
                    type="radio"
                    name="visibility"
                    value="public"
                    className="mt-0"
                    checked={visibility === 'public'}
                    onChange={() => { setVisibility('public'); }}
                  />
                  }
                label="Public"
              />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Input
                    type="radio"
                    name="visibility"
                    value="private"
                    className="mt-0"
                    checked={visibility === 'private'}
                    onChange={() => { setVisibility('private'); }}
                  />
                  }
                label="Private"
              />
            </Row>
          </FormGroup>
        </div>
      </div>
      <ButtonToolbar className="modal__footer">
        <Button
          color="primary"
          onClick={onSaveButtonClick}
          disabled={savingSearch || saveButtonDisabled}
        >
          {savingSearch && (<span><div className="spinner-border text-light spinner-border-sm" />  </span>)}
          {infoMessage.includes('overwrite') ? 'Save & Overwrite' : 'Save'}
        </Button>
        <Button color="danger" onClick={toggleModal}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

SaveSearchModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  saveSearch: PropTypes.func.isRequired,
  savingSearch: PropTypes.bool,
  savedSearchesNames: PropTypes.arrayOf(PropTypes.any),
  searchName: PropTypes.string,
  searchVisibility: PropTypes.string,
  saveSearchModalMessage: PropTypes.string,
  searchOwnerId: PropTypes.string,
  adminUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

SaveSearchModal.defaultProps = {
  isOpen: false,
  savingSearch: false,
  savedSearchesNames: [],
  searchName: '',
  searchVisibility: 'public',
  saveSearchModalMessage: '',
  searchOwnerId: '',
};


const mapStateToProps = state => ({
  adminUser: state.session,
});

export default withNotice(connect(mapStateToProps)(SaveSearchModal));
