import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
} from 'reactstrap';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import ApiClient from 'ApiClient';
import withNotice from '../../../../../App/store/with-notice';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import Table from './custom/Table';
import Actions from './custom/Actions';
import ViewGrossProfitModal from './custom/ViewGrossProfitModal';
import RecycleContactModal from './custom/RecycleContactModal';
import ViewRecyclingHistoryModal from './custom/ViewRecyclingHistoryModal';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';


const getDealInitial = () => {
  const filters = parseUrl();
  if (typeof filters?.hasPublishedDeals === 'string') {
    filters.hasPublishedDeals = [filters.hasPublishedDeals];
  }
  const initialDealFilters = {
    Published: true,
    'Not Published': true,
  };
  if (filters?.hasPublishedDeals) {
    Object.keys(initialDealFilters).forEach((key) => {
      initialDealFilters[key] = filters?.hasPublishedDeals.includes(key);
    });
  }
  return initialDealFilters;
};

const getRecycledInitial = () => {
  const filters = parseUrl();
  if (typeof filters?.recycled === 'string') {
    filters.recycled = [filters.recycled];
  }
  const initialRecycledFilters = {
    Yes: true,
    No: true,
  };
  if (filters?.recycled) {
    Object.keys(initialRecycledFilters).forEach((key) => {
      initialRecycledFilters[key] = filters?.recycled.includes(key);
    });
  }
  return initialRecycledFilters;
};

const getLastActivityDateValueInitial = () => {
  let initialLastADType = '<=';
  const type = new URLSearchParams(window.location.search).get('lastADType');
  if (type) {
    initialLastADType = type;
  }
  return initialLastADType;
};

const getRecycledDateValueInitial = () => {
  let initialRecycledDateType = '<=';
  const type = new URLSearchParams(window.location.search).get('recycledDateType');
  if (type) {
    initialRecycledDateType = type;
  }
  return initialRecycledDateType;
};

const getSingleDateInitial = () => {
  let singleDate = null;
  const filters = parseUrl();
  const type = new URLSearchParams(window.location.search).get('lastADType');
  if (type && type !== 'Between') {
    if (type === '>' || type === '>=' || type === '=') {
      if (filters?.['actionDate.from']) {
        singleDate = new Date(filters['actionDate.from']);
      }
    }
    if (type === '<' || type === '<=') {
      singleDate = new Date(filters['actionDate.to']);
    }
  }
  return singleDate;
};

const getRecycledSingleDateInitial = () => {
  let singleDate = null;
  const filters = parseUrl();
  const type = new URLSearchParams(window.location.search).get('recycledDateType');
  if (type && type !== 'Between') {
    if (type === '>' || type === '>=' || type === '=') {
      if (filters?.['recycleDate.from']) {
        singleDate = new Date(filters['recycleDate.from']);
      }
    }
    if (type === '<' || type === '<=') {
      singleDate = new Date(filters['recycleDate.to']);
    }
  }
  return singleDate;
};

const RecycleLeadsListContainer = ({
  action,
  resource,
  dispatch,
  addNotice,
  history,
}) => {
  const [ownersSuggestions, setOwnersSuggestions] = useState([]);
  const [ownersSuggestionsCount, setOwnersSuggestionsCount] = useState(0);
  const getTagsInitial = (tagType, returnObject) => {
    const determineName = (tag) => {
      let result = tag;
      if (tagType === 'contactOwners' && ownersSuggestions.length > 0) {
        ownersSuggestions.forEach((ownerSuggestion) => {
          if (tag === ownerSuggestion.id) {
            result = ownerSuggestion.name;
          }
        });
      }
      return result;
    };
    const determineObject = (tag) => {
      let result = { name: determineName(tag) };
      if (tagType === 'contactOwners' && ownersSuggestions.length > 0) {
        ownersSuggestions.forEach((ownerSuggestion) => {
          if (tag === ownerSuggestion.id) {
            result = { name: ownerSuggestion.name, id: ownerSuggestion.id };
          }
        });
      }
      return result;
    };
    const filters = parseUrl();
    const initialTagFilters = returnObject ? {} : [];
    let filtersProspectTags = [];
    if (filters[tagType] && Array.isArray(filters[tagType])) {
      filtersProspectTags = filters[tagType];
    } else if (filters[tagType]) {
      filtersProspectTags = [filters[tagType]];
    }
    filtersProspectTags.forEach((tag) => {
      if (returnObject) {
        initialTagFilters[determineName(tag)] = true;
      } else {
        initialTagFilters.push(determineObject(tag));
      }
    });
    return initialTagFilters;
  };

  const filters = parseUrl();
  const lastADType = getLastActivityDateValueInitial();
  const recycledDateType = getRecycledDateValueInitial();
  const [selectedRecordIds, setSelectedRecordIds] = useState([]);
  const [version, setVersion] = useState((new Date()).toString());
  const [dealFilters, setDealFilters] = useState(getDealInitial());
  const [recycledFilters, setRecycledFilters] = useState(getRecycledInitial());
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [tagsFilter, setTagsFilter] = useState(getTagsInitial('tags', true));
  const [ownersFilter, setOwnersFilter] = useState(getTagsInitial('contactOwners', true));
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [tags, setTags] = useState(getTagsInitial('tags', false));
  const [owners, setOwners] = useState(getTagsInitial('contactOwners', false));
  const [showGrossProfitModal, setShowGrossProfitModal] = useState(false);
  const [currentHsContactId, setCurrentHsContactId] = useState('');
  const [dateFrom, setDateFrom] = useState(filters?.['actionDate.from'] && lastADType === 'Between' ? new Date(filters['actionDate.from']) : null);
  const [dateTo, setDateTo] = useState(filters?.['actionDate.to'] && lastADType === 'Between' ? new Date(filters['actionDate.to']) : null);
  const [singleDate, setSingleDate] = useState(getSingleDateInitial());
  const [enableLastAd, setEnableLastAd] = useState(false);
  const [lastActivityDateValue, setLastActivityDateValue] = useState({ value: lastADType, label: lastADType });

  const [recycledDateValue, setRecycledDateValue] = useState({ value: recycledDateType, label: recycledDateType });
  const [enableRecycledDate, setEnableRecycledDate] = useState(false);
  const [recycledDateFrom, setRecycledDateFrom] = useState(filters?.['recycleDate.from'] && recycledDateType === 'Between' ? new Date(filters['recycleDate.from']) : null);
  const [recycledDateTo, setRecycledDateTo] = useState(filters?.['recycleDate.to'] && recycledDateType === 'Between' ? new Date(filters['recycleDate.to']) : null);
  const [recycledSingleDate, setRecycledSingleDate] = useState(getRecycledSingleDateInitial());

  const [showRecycleContactModal, setShowRecycleContactModal] = useState(false);
  const [contactsToRecycle, setContactsToRecycle] = useState([]);
  const [metaTotal, setMetaTotal] = useState(0);
  const [recycleContactsLimit, setRecycleContactsLimit] = useState('500');
  const [contactToShowHistory, setContactToShowHistory] = useState({});
  const [showRecycleHistoryModal, setRecycleHistoryModal] = useState(false);

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }
    search.delete('filters.tags');
    if (tagsFilter && Object.keys(tagsFilter).length > 0) {
      Object.keys(tagsFilter).forEach((key) => {
        if (!tagsFilter[key]) return false;
        search.append('filters.tags', key);
        return true;
      });
    }
    search.delete('filters.contactOwners');
    if (ownersFilter && Object.keys(ownersFilter).length > 0) {
      Object.keys(ownersFilter).forEach((key) => {
        if (!ownersFilter[key]) return false;
        search.append('filters.contactOwners', key);
        return true;
      });
    }
    if (dealFilters && Object.keys(dealFilters).length > 0) {
      search.delete('filters.hasPublishedDeals');
      let allSwitchedOff = true;
      Object.keys(dealFilters).forEach((key) => {
        if (!dealFilters[key]) return false;
        search.append('filters.hasPublishedDeals', key);
        allSwitchedOff = false;
        return true;
      });
      if (allSwitchedOff) {
        search.append('filters.hasPublishedDeals', 'Not Exist');
      }
    }
    if (recycledFilters && Object.keys(recycledFilters).length > 0) {
      search.delete('filters.recycled');
      let allSwitchedOff = true;
      Object.keys(recycledFilters).forEach((key) => {
        if (!recycledFilters[key]) return false;
        search.append('filters.recycled', key);
        allSwitchedOff = false;
        return true;
      });
      if (allSwitchedOff) {
        search.append('filters.recycled', 'Not Exist');
      }
    }
    search.delete('filters.actionDate.from');
    search.delete('filters.actionDate.to');
    search.delete('lastADType');
    if (dateFrom && dateTo) {
      search.append('filters.actionDate.from', dateFrom.toISOString());
      search.append('filters.actionDate.to', dateTo.toISOString());
    }
    if (singleDate && singleDate !== '') {
      if (lastActivityDateValue.value === '>' || lastActivityDateValue.value === '>=') {
        search.append('filters.actionDate.from', singleDate.toISOString());
      }
      if (lastActivityDateValue.value === '<' || lastActivityDateValue.value === '<=') {
        search.append('filters.actionDate.to', singleDate.toISOString());
      }
      if (lastActivityDateValue.value === '=') {
        search.append('filters.actionDate.from', singleDate.toISOString());
        const nextDay = new Date(singleDate);
        nextDay.setDate(singleDate.getDate() + 1);
        search.append('filters.actionDate.to', nextDay.toISOString());
      }
    }
    if (lastActivityDateValue.value && ((dateTo && dateFrom) || singleDate)) {
      setEnableLastAd(true);
      search.append('lastADType', lastActivityDateValue.value);
    }
    search.delete('filters.recycleDate.from');
    search.delete('filters.recycleDate.to');
    search.delete('recycledDateType');
    if (recycledDateFrom && recycledDateTo) {
      search.append('filters.recycleDate.from', recycledDateFrom.toISOString());
      search.append('filters.recycleDate.to', recycledDateTo.toISOString());
    }
    if (recycledSingleDate && recycledSingleDate !== '') {
      if (recycledDateValue.value === '>' || recycledDateValue.value === '>=') {
        search.append('filters.recycleDate.from', recycledSingleDate.toISOString());
      }
      if (recycledDateValue.value === '<' || recycledDateValue.value === '<=') {
        search.append('filters.recycleDate.to', recycledSingleDate.toISOString());
      }
      if (recycledDateValue.value === '=') {
        search.append('filters.recycleDate.from', recycledSingleDate.toISOString());
        const nextDay = new Date(recycledSingleDate);
        nextDay.setDate(recycledSingleDate.getDate() + 1);
        search.append('filters.recycleDate.to', nextDay.toISOString());
      }
    }
    if (recycledDateValue.value && ((recycledDateTo && recycledDateFrom) || recycledSingleDate)) {
      setEnableRecycledDate(true);
      search.append('recycledDateType', recycledDateValue.value);
    }
    return search;
  };

  const firstUpdate = useRef(true);
  const setUrl = () => {
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
    }
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  const fetchSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/tags/get-creator-tags',
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchOwnersSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospect-owners',
    }).then((response) => {
      if (response.data.success === true) {
        setOwnersSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const showRecycleModal = (contactToRecycleId = false) => {
    if (contactToRecycleId === false) {
      setContactsToRecycle(selectedRecordIds);
    } else {
      setContactsToRecycle([contactToRecycleId]);
    }
    setShowRecycleContactModal(true);
  };

  const showRecycleHistory = (contact) => {
    setContactToShowHistory(contact);
    setRecycleHistoryModal(true);
  };

  const fetchRecycleContactsLimit = () => {
    const api = new ApiClient();
    api.resourceAction({
      actionName: 'list',
      resourceId: 'Dictionary',
      params: new URLSearchParams('?filters.key=RECYCLE_CONTACTS_LIMIT'),
    }).then((response) => {
      const listActionReponse = response.data;
      if (listActionReponse.records?.length > 0) {
        setRecycleContactsLimit(listActionReponse.records[0].params.value);
      }
    });
  };

  useEffect(() => {
    if (ownersSuggestionsCount) setUrl();
  }, [
    searchValue,
    tagsFilter,
    ownersFilter,
    dealFilters,
    recycledFilters,
    singleDate, dateTo, dateFrom,
    recycledDateFrom, recycledDateTo, recycledSingleDate,
  ]);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.has('lastADType') && search.get('lastADType') !== lastActivityDateValue.value) {
      setUrl();
    }
    if (search.has('recycledDateType') && search.get('recycledDateType') !== recycledDateValue.value) {
      setUrl();
    }
  }, [lastActivityDateValue, recycledDateValue]);

  useEffect(() => {
    fetchSuggestions();
    fetchOwnersSuggestions();
    fetchRecycleContactsLimit();
  }, []);

  useEffect(() => {
    fetchSuggestions();
    fetchOwnersSuggestions();
  }, [version]);

  useEffect(() => {
    if (ownersSuggestionsCount === 0) {
      setOwnersSuggestionsCount(1);
      return;
    }
    if (owners.length) {
      setOwners(getTagsInitial('contactOwners', false));
    }
  }, [ownersSuggestions]);

  const handleSelectCheckbox = (selectedIds) => {
    setSelectedRecordIds(selectedIds);
  };

  if (!enableLastAd && lastActivityDateValue.value && ((dateTo && dateFrom) || singleDate)) {
    setEnableLastAd(true);
  }

  if (!enableRecycledDate && recycledDateValue.value && ((recycledDateTo && recycledDateFrom) || recycledSingleDate)) {
    setEnableRecycledDate(true);
  }

  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Research', path: null },
          { title: 'Recycle Leads', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody className="leads-recycle-dashboard">
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Recycle Leads</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Actions
            dealFilters={dealFilters}
            setDealFilters={setDealFilters}
            recycledFilters={recycledFilters}
            setRecycledFilters={setRecycledFilters}
            enableLastAd={enableLastAd}
            setEnableLastAd={setEnableLastAd}
            lastActivityDateValue={lastActivityDateValue}
            setLastActivityDateValue={setLastActivityDateValue}
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            singleDate={singleDate}
            setSingleDate={setSingleDate}
            enableRecycledDate={enableRecycledDate}
            setEnableRecycledDate={setEnableRecycledDate}
            recycledDateValue={recycledDateValue}
            setRecycledDateValue={setRecycledDateValue}
            recycledDateFrom={recycledDateFrom}
            setRecycledDateFrom={setRecycledDateFrom}
            recycledDateTo={recycledDateTo}
            setRecycledDateTo={setRecycledDateTo}
            recycledSingleDate={recycledSingleDate}
            setRecycledSingleDate={setRecycledSingleDate}
            onSearchChange={onSearchChange}
            tagsSuggestions={tagsSuggestions}
            setTagsFilter={setTagsFilter}
            setTags={setTags}
            tags={tags}
            getUrlSearch={getUrlSearch}
            searchValue={searchValue}
            selectedRecordIds={selectedRecordIds}
            ownersSuggestions={ownersSuggestions}
            setOwnersFilter={setOwnersFilter}
            setOwners={setOwners}
            owners={owners}
            showRecycleModal={showRecycleModal}
            disableRecycle={selectedRecordIds.length === 0}
          />
          <Table
            handleSelectCheckbox={handleSelectCheckbox}
            action={action}
            resource={resource}
            dispatch={dispatch}
            addNotice={addNotice}
            version={version}
            setVersion={setVersion}
            setMetaTotal={setMetaTotal}
            setSingleSelectedHsContactId={setCurrentHsContactId}
            setShowGrossProfitModal={setShowGrossProfitModal}
            showRecycleModal={showRecycleModal}
            showRecycleHistory={showRecycleHistory}
          />
        </CardBody>
      </Card>
      <ViewGrossProfitModal
        isOpen={showGrossProfitModal}
        closeFunc={() => setShowGrossProfitModal(false)}
        hsContactId={currentHsContactId}
      />
      <RecycleContactModal
        isOpen={showRecycleContactModal}
        closeFunc={() => setShowRecycleContactModal(false)}
        contactIds={contactsToRecycle}
        addNotice={addNotice}
        numberOfFound={metaTotal}
        RECYCLE_CONTACTS_LIMIT={recycleContactsLimit}
      />
      <ViewRecyclingHistoryModal
        isOpen={showRecycleHistoryModal}
        closeFunc={() => setRecycleHistoryModal(false)}
        contact={contactToShowHistory}
      />
    </Container>
  );
};

RecycleLeadsListContainer.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};
const mapStateToPros = () => ({

});

export default withNotice(connect(mapStateToPros)(withRouter(RecycleLeadsListContainer)));
