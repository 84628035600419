import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Modal,
  ModalBody,
  ModalHeader,
  Button, Spinner, Col, FormGroup, Row,
} from 'reactstrap';
import Select from 'react-select';
import withNotice from '../../../../../App/store/with-notice';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';

const ilStatusOptions = [
  { value: 'Pending Edits', label: 'Pending Edits' },
  { label: 'Rescore', value: 'Rescore' },
  { label: 'Approved', value: 'Awaiting Brand Approval' },
];

const brandStatusOptions = [
  { label: 'In Review', value: 'In Review' },
  { label: 'Pending Edits', value: 'Pending Edits' },
  { label: 'Approved', value: 'Approved' },
];

const UpdateStatusModal = ({
  isOpen,
  closeFunc,
  ids,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const [startUpdate, setStartUpdate] = useState(false);
  const [status, setStatus] = useState(ilStatusOptions[0]);

  useEffect(() => {
    if (type !== 'influenceLogic') {
      setStatus(brandStatusOptions[0]);
    }
    if (!isOpen) {
      setStartUpdate(false);
      setStatus(ilStatusOptions[0]);
    }
  }, [isOpen]);

  const submit = async () => {
    setLoading(true);
    setStartUpdate(true);
    axios.post('/api/portal/compliance/post-update-status', {
      ids,
      status: status.value,
      date: new Date(),
      type,
    }).then((response) => {
      // console.log(response.data);
      if (response.data.success) {
        setLoading(false);
      }
      if (response.data.error) {
        setLoading(false);
        console.log(response.data.error);
      }
    });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc(startUpdate)}
      style={{ minWidth: '500px' }}
    >
      <ModalHeader toggle={() => closeFunc(startUpdate)} tag="h4" className="modal-title-statistics">{`Update ${type === 'influenceLogic' ? 'InfluenceLogic' : 'Brand'} Status`}</ModalHeader>
      <ModalBody>
        <>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          {!loading && (
            <div>{`${ids.length} content${ids.length !== 1 ? 's' : ''} currently selected.`}</div>
          )}
        </>
        {!startUpdate && !loading && (
          <Row>
            <Col>
              <FormGroup>
                <Select
                  value={status}
                  className="assignStatusInput"
                  options={type === 'influenceLogic' ? ilStatusOptions : brandStatusOptions}
                  onChange={setStatus}
                  placeholder=""
                  isDisabled={!!loading}
                  menuPlacement="auto"
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {loading && (
          <div className="text-center"><Spinner color="primary" size="lg" /></div>
        )}
        {startUpdate && !loading && (
          <Alert color="success" className="mt-3 w-100">
            <p className="px-4 py-1" style={{ color: '#212529' }}>
              Status Update complete.
            </p>
          </Alert>
        )}
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        {!startUpdate && (
          <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
            {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
            <span>Update</span>
          </Button>
        )}&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color={startUpdate && loading ? 'danger' : 'secondary'} onClick={() => closeFunc(startUpdate)}>
          {startUpdate && !loading ? 'Close' : 'Cancel'}
        </Button>
      </ButtonToolbar>
    </Modal>
  );
};

UpdateStatusModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
};

export default withNotice(UpdateStatusModal);
