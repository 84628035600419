import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import withNotice from '../../../../App/store/with-notice';
import { hideModalById } from '../../../../../redux/reducers/TableReducer';

export const modalId = 'refreshPayableModalQuestion';

const RefreshBillModal = ({ addNotice }) => {
  const [loading, setLoading] = useState(false);
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const dispatch = useDispatch();
  if (!modalInfo) return false;
  console.log(modalInfo);
  const { data: { record, updateRecordFunction }, isShow } = modalInfo;
  const close = () => dispatch(hideModalById(modalId));

  const onRefreshFunc = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/api/portal/finance/post-refresh-payable', {
        affiliateId: record.params.affiliateTuneid,
        offerId: record.params.offerTuneid,
        reportId: record.params.accountsPayable,
      });
      console.log(updateRecordFunction, response?.data?.record);
      if (response?.data?.record && updateRecordFunction) {
        updateRecordFunction(response?.data?.record);
      }
      setLoading(false);
      if (response?.data.error) throw new Error(response?.data.error);
      addNotice({
        message: 'Payable has been refreshed successfully',
        type: 'success',
      });
      close();
    } catch (e) {
      console.log(e);
      addNotice({
        message: e.error,
        type: 'error',
        duration: 10,
      });
    }
    return true;
  };
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
    >
      <ModalHeader toggle={close} tag="h4">
        Refresh Payable
      </ModalHeader>
      <ModalBody
        className="mt-1 mb-1"
      >
        <p>
          Refreshing the payable will recalculate the HO and IL Payouts based on HasOffers conversions data and the matching deal configuration.<br />
          This may also update the connected creator, agent, or deal if any account changes were recently made.
        </p>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            onRefreshFunc();
            // close();
            // setSelectedOption({ value: false, label: 'No, do not overwrite' });
          }}
        >
          Refresh
        </Button>
        <Button color="secondary" onClick={close}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

RefreshBillModal.propTypes = {
  addNotice: PropTypes.func.isRequired,
};


export default withNotice(RefreshBillModal);
