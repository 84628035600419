// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { NumericFormat as NumberFormat } from 'react-number-format';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import IntervalDatePickerField from '../../../Prospects/components/custom/IntervalDatePickerField';

const Actions = React.memo(({
  onSearchChange,
  tagsSuggestions,
  setTagsFilter,
  setTags,
  tags,
  getUrlSearch,
  searchValue,
  ownersSuggestions,
  setOwnersFilter,
  setOwners,
  owners,
  setBrandsFilter,
  setBrands,
  brands,
  brandsSuggestions,
  matchFilters,
  setMatchFilters,
  contactOwnerFilters,
  setContactOwnerFilters,
  dealOwnerFilters,
  setDealOwnerFilters,
  targetBrandFilters,
  setTargetBrandFilters,
  inBlacklistFilters,
  setInBlacklistFilters,
  doHubspotImport,
  setShowAssignContactOwnerModal,
  setShowAssignDealOwnerModal,
  selectedProspectIds,
  disableGlobalImport,
  lastActivityDateValue,
  setLastActivityDateValue,
  enableLastAd,
  setEnableLastAd,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  singleDate,
  setSingleDate,
  scoreValue,
  setScoreValue,
  enableScore,
  setEnableScore,
  scoreMin,
  setScoreMin,
  scoreMax,
  setScoreMax,
  singleScore,
  setSingleScore,
  v30Value,
  setV30Value,
  enableV30,
  setEnableV30,
  v30Min,
  setV30Min,
  v30Max,
  setV30Max,
  singleV30,
  setSingleV30,
  isInternalFilters,
  setIsInternalFilters,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [scoreMinLocal, setScoreMinLocal] = useState(scoreMin);
  const [dateScoreMin, setDateScoreMin] = useState(null);
  const [scoreMaxLocal, setScoreMaxLocal] = useState(scoreMax);
  const [dateScoreMax, setDateScoreMax] = useState(null);
  const [singleScoreLocal, setSingleScoreLocal] = useState(singleScore);
  const [dateSingleScore, setDateSingleScore] = useState(null);
  const [v30MinLocal, setV30MinLocal] = useState(v30Min);
  const [dateV30Min, setDateV30Min] = useState(null);
  const [v30MaxLocal, setV30MaxLocal] = useState(v30Max);
  const [dateV30Max, setDateV30Max] = useState(null);
  const [singleV30Local, setSingleV30Local] = useState(singleV30);
  const [dateSingleV30, setDateSingleV30] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('researchLeadsShowFilters') === 'true');

  const reactTagsTags = React.createRef();
  const reactTagsOwners = React.createRef();
  const reactTagsBrands = React.createRef();

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    if (dateScoreMin) {
      clearTimeout(dateScoreMin);
    }
    setDateScoreMin(setTimeout(() => {
      setScoreMin(scoreMinLocal);
    }, 1000));
  }, [scoreMinLocal]);

  useEffect(() => {
    if (dateScoreMax) {
      clearTimeout(dateScoreMax);
    }
    setDateScoreMax(setTimeout(() => {
      setScoreMax(scoreMaxLocal);
    }, 1000));
  }, [scoreMaxLocal]);

  useEffect(() => {
    if (dateSingleScore) {
      clearTimeout(dateSingleScore);
    }
    setDateSingleScore(setTimeout(() => {
      setSingleScore(singleScoreLocal);
    }, 1000));
  }, [singleScoreLocal]);

  useEffect(() => {
    if (dateV30Min) {
      clearTimeout(dateV30Min);
    }
    setDateV30Min(setTimeout(() => {
      setV30Min(v30MinLocal);
    }, 1000));
  }, [v30MinLocal]);

  useEffect(() => {
    if (dateV30Max) {
      clearTimeout(dateV30Max);
    }
    setDateV30Max(setTimeout(() => {
      setV30Max(v30MaxLocal);
    }, 1000));
  }, [v30MaxLocal]);

  useEffect(() => {
    if (dateSingleV30) {
      clearTimeout(dateSingleV30);
    }
    setDateSingleV30(setTimeout(() => {
      setSingleV30(singleV30Local);
    }, 1000));
  }, [singleV30Local]);

  useEffect(() => {
    setCookie('researchLeadsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const setFiltersTag = (tagsNew) => {
    const tagsFilterNew = {};
    if (tagsNew.length > 0) {
      tagsNew.map((item) => {
        tagsFilterNew[item.name] = true;
        return true;
      });
    }
    setTagsFilter(tagsFilterNew);
  };

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setFiltersTag(tagsNew);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setFiltersTag(tagsNew);
    setTags(tagsNew);
  };

  const setFiltersOwner = (ownersNew) => {
    const ownersFilterNew = {};
    if (ownersNew.length > 0) {
      ownersNew.map((item) => {
        ownersFilterNew[item.id] = true;
        return true;
      });
    }
    setOwnersFilter(ownersFilterNew);
  };

  const setFiltersBrands = (brandsNew) => {
    const brandsFilterNew = {};
    if (brandsNew.length > 0) {
      brandsNew.map((item) => {
        brandsFilterNew[item.id] = true;
        return true;
      });
    }
    setBrandsFilter(brandsFilterNew);
  };

  const onDeleteOwner = (i) => {
    const ownersNew = owners.slice(0);
    ownersNew.splice(i, 1);
    setFiltersOwner(ownersNew);
    setOwners(ownersNew);
  };

  const onAdditionOwner = (owner) => {
    const ownersNew = [].concat(owners, owner);
    setFiltersOwner(ownersNew);
    setOwners(ownersNew);
  };

  const onDeleteBrand = (i) => {
    const brandsNew = brands.slice(0);
    brandsNew.splice(i, 1);
    setFiltersBrands(brandsNew);
    setBrands(brandsNew);
  };

  const onAdditionBrand = (brand) => {
    const brandsNew = [].concat(brands, brand);
    setFiltersBrands(brandsNew);
    setBrands(brandsNew);
  };

  const handleChangeMatchFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setMatchFilters({
        ...matchFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeContactOwnerFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setContactOwnerFilters({
        ...contactOwnerFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeDealOwnerFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setDealOwnerFilters({
        ...dealOwnerFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeTargetBrandFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setTargetBrandFilters({
        ...targetBrandFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeIsInternalFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setIsInternalFilters({
        ...isInternalFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeIsBlacklistFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setInBlacklistFilters({
        ...inBlacklistFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setMatchFilters({
      Match: true,
      'No Match': true,
    });
    setContactOwnerFilters({
      Assigned: true,
      'Not Assigned': true,
    });
    setDealOwnerFilters({
      Assigned: true,
      'Not Assigned': true,
    });
    setTargetBrandFilters({
      Assigned: true,
      'Not Assigned': true,
    });
    setIsInternalFilters({
      Standard: true,
      Internal: true,
    });
    setInBlacklistFilters({
      Yes: true,
      No: true,
    });
    setTags([]);
    setTagsFilter({});
    setBrands([]);
    setBrandsFilter({});
    setOwners([]);
    setOwnersFilter({});
    setEnableLastAd(false);
    setLastActivityDateValue({ value: '<=', label: '<=' });
    setSingleDate(null);
    setDateTo(null);
    setDateFrom(null);
    setEnableScore(false);
    setScoreValue({ value: '<=', label: '<=' });
    setSingleScoreLocal(null);
    setScoreMinLocal(null);
    setScoreMaxLocal(null);
    setEnableV30(false);
    setV30Value({ value: '<=', label: '<=' });
    setSingleV30Local(null);
    setV30MinLocal(null);
    setV30MaxLocal(null);
  };
  const handleChangeLastADCheckBox = ({ target }) => {
    setEnableLastAd(!enableLastAd);
    if (!target.checked) {
      setSingleDate(null);
      setDateTo(null);
      setDateFrom(null);
    }
  };


  const handleChangeScoreCheckBox = ({ target }) => {
    setEnableScore(!enableScore);
    if (!target.checked) {
      setSingleScore(null);
      setScoreMin(null);
      setScoreMax(null);
    }
  };

  const handleChangeV30CheckBox = ({ target }) => {
    setEnableV30(!enableV30);
    if (!target.checked) {
      setSingleV30(null);
      setV30Min(null);
      setV30Max(null);
    }
  };

  const lastActivityDateLabels = [
    { value: '=', label: '=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'Between', label: 'Between' },
  ];

  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
      height: '44px',
    }),
  };

  return (
    <div>
      <Row className="mt-4">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search First Name, Last Name, Email, YouTube Channel"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                disabled={selectedProspectIds.length === 0 || disableGlobalImport}
                onClick={() => { doHubspotImport(); }}
              >
                HubSpot Import
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                disabled={selectedProspectIds.length === 0}
                onClick={() => { setShowAssignContactOwnerModal(true); }}
              >
                Assign Contact Owner
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                disabled={selectedProspectIds.length === 0}
                onClick={() => { setShowAssignDealOwnerModal(true); }}
              >
                Assign Deal Owner
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => window.open(`/resources/leads/download-csv?${getUrlSearch().toString()}`, '_blank').focus()}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row>
            <Col sm={4}>
              <Label className="bold-text">
                Brand(s)
              </Label>
              <ReactTags
                ref={reactTagsBrands}
                tags={brands}
                suggestions={brandsSuggestions}
                onDelete={onDeleteBrand}
                onAddition={onAdditionBrand}
                delimiters={['Enter', ',']}
                placeholderText="Brand name(s)"
              />
            </Col>
            <Col sm={4}>
              <Label className="bold-text">
                Contact Owner(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsOwners}
                tags={owners}
                suggestions={ownersSuggestions}
                onDelete={onDeleteOwner}
                onAddition={onAdditionOwner}
                delimiters={['Enter', ',']}
                placeholderText="Contact Owner name(s)"
              />
            </Col>
            <Col sm={4}>
              <Label className="bold-text">
                Tag(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsTags}
                tags={tags}
                suggestions={tagsSuggestions}
                onDelete={onDeleteTag}
                onAddition={onAdditionTag}
                delimiters={['Enter', ',']}
                placeholderText="Tag name(s)"
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={4}>
              <Row>
                <Col sm={1} />
                <Col sm={11} style={{ paddingLeft: '20px' }}>
                  <Label className="bold-text" style={{ marginBottom: '8px' }}>
                    Last Activity Date
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '13px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="lastActivityDateCheckBox"
                      value={enableLastAd}
                      onChange={e => e.target && handleChangeLastADCheckBox(e)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={5} style={{ paddingLeft: '20px' }}>
                  <Select
                    value={lastActivityDateValue}
                    onChange={(option) => {
                      if ((lastActivityDateValue !== 'Between' && option.value === 'Between') || (lastActivityDateValue === 'Between' && option.value !== 'Between')) {
                        setDateFrom(null);
                        setDateTo(null);
                        setSingleDate(null);
                      }
                      setLastActivityDateValue(option);
                    }}
                    options={lastActivityDateLabels}
                    isDisabled={!enableLastAd}
                    styles={customStyles}
                  />
                </Col>
                <Col sm={6}>
                  {lastActivityDateValue.value === 'Between' ? (
                    <div style={{ marginRight: '-30px' }} className="pb-2">
                      <IntervalDatePickerField
                        onChange={({ start, end }) => {
                          setDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setDateTo(end);
                        }}
                        startDate={dateFrom}
                        endDate={dateTo}
                        maxDate={new Date()}
                        placeholderFrom="Start Date"
                        placeholderTo="End Date"
                        verticalFields
                        disabled={!enableLastAd}
                      />
                    </div>
                  ) : (
                    <div className="date-picker datepicker-in-prospects">
                      <DatePicker
                        selected={singleDate}
                        onChange={(date) => {
                          if (lastActivityDateValue.value === '<=' || lastActivityDateValue.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          setSingleDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm datepicker-in-prospects w-100"
                        disabled={!enableLastAd}
                      />
                      {singleDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '13px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm={4}>
              <Row>
                <Col sm={1} />
                <Col sm={11} style={{ paddingLeft: '20px' }}>
                  <Label className="bold-text" style={{ marginBottom: '8px' }}>
                    Score
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '13px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="scoreCheckBox"
                      value={enableScore}
                      onChange={e => e.target && handleChangeScoreCheckBox(e)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={5} style={{ paddingLeft: '20px' }}>
                  <Select
                    value={scoreValue}
                    onChange={(option) => {
                      if ((scoreValue !== 'Between' && option.value === 'Between') || (scoreValue === 'Between' && option.value !== 'Between')) {
                        setScoreMinLocal(null);
                        setScoreMaxLocal(null);
                        setSingleScoreLocal(null);
                      }
                      setScoreValue(option);
                    }}
                    options={lastActivityDateLabels}
                    isDisabled={!enableScore}
                    styles={customStyles}
                  />
                </Col>
                <Col sm={6}>
                  {scoreValue.value === 'Between' ? (
                    <>
                      <NumberFormat
                        key="scoreMin"
                        disabled={!enableScore}
                        thousandSeparator
                        prefix=""
                        name="what"
                        value={scoreMinLocal}
                        className="form-control"
                        style={{ height: '44px' }}
                        placeholder="Min"
                        inputMode="numeric"
                        allowEmptyFormatting={false}
                        fixedDecimalScale
                        decimalScale={0}
                        onValueChange={values => setScoreMinLocal(values.floatValue)}
                        onBlur={() => {
                          if (
                            scoreMinLocal !== null
                            && scoreMaxLocal !== null
                            && (scoreMinLocal > scoreMaxLocal)
                          ) {
                            setScoreMaxLocal(scoreMinLocal + 100);
                          }
                        }}
                      />
                      <NumberFormat
                        key="scoreMax"
                        disabled={!enableScore}
                        thousandSeparator
                        prefix=""
                        name="what"
                        value={scoreMaxLocal}
                        className="form-control"
                        style={{ height: '44px', marginTop: '24px' }}
                        placeholder="Max"
                        inputMode="numeric"
                        allowEmptyFormatting={false}
                        fixedDecimalScale
                        decimalScale={0}
                        onValueChange={values => setScoreMaxLocal(values.floatValue)}
                        onBlur={() => {
                          if (
                            scoreMinLocal !== null
                            && scoreMaxLocal !== null
                            && (scoreMinLocal > scoreMaxLocal)
                          ) {
                            setScoreMinLocal(scoreMaxLocal - 1);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <NumberFormat
                      key="singleScore"
                      disabled={!enableScore}
                      thousandSeparator
                      prefix=""
                      name="what"
                      value={singleScoreLocal}
                      className="form-control"
                      style={{ height: '44px' }}
                      placeholder="Score"
                      inputMode="numeric"
                      allowEmptyFormatting={false}
                      fixedDecimalScale
                      decimalScale={0}
                      onValueChange={values => setSingleScoreLocal(values.floatValue)}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm={4}>
              <Row>
                <Col sm={1} />
                <Col sm={11} style={{ paddingLeft: '20px' }}>
                  <Label className="bold-text" style={{ marginBottom: '8px' }}>
                    V30
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '13px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="v30CheckBox"
                      value={enableV30}
                      onChange={e => e.target && handleChangeV30CheckBox(e)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={5} style={{ paddingLeft: '20px' }}>
                  <Select
                    value={v30Value}
                    onChange={(option) => {
                      if ((v30Value !== 'Between' && option.value === 'Between') || (v30Value === 'Between' && option.value !== 'Between')) {
                        setV30MinLocal(null);
                        setV30MaxLocal(null);
                        setSingleV30Local(null);
                      }
                      setV30Value(option);
                    }}
                    options={lastActivityDateLabels}
                    isDisabled={!enableV30}
                    styles={customStyles}
                  />
                </Col>
                <Col sm={6}>
                  {v30Value.value === 'Between' ? (
                    <>
                      <NumberFormat
                        key="v30Min"
                        disabled={!enableV30}
                        thousandSeparator
                        prefix=""
                        name="what"
                        value={v30MinLocal}
                        className="form-control"
                        style={{ height: '44px' }}
                        inputMode="numeric"
                        placeholder="Min"
                        allowEmptyFormatting={false}
                        fixedDecimalScale
                        decimalScale={0}
                        onValueChange={values => setV30MinLocal(values.floatValue)}
                        onBlur={() => {
                          if (
                            v30MinLocal !== null
                            && v30MaxLocal !== null
                            && (v30MinLocal > v30MaxLocal)
                          ) {
                            setV30MaxLocal(v30MinLocal + 1000);
                          }
                        }}
                      />
                      <NumberFormat
                        key="v30Max"
                        disabled={!enableV30}
                        thousandSeparator
                        prefix=""
                        name="what"
                        value={v30MaxLocal}
                        className="form-control"
                        style={{ height: '44px', marginTop: '24px' }}
                        inputMode="numeric"
                        placeholder="Max"
                        allowEmptyFormatting={false}
                        fixedDecimalScale
                        decimalScale={0}
                        onValueChange={values => setV30MaxLocal(values.floatValue)}
                        onBlur={() => {
                          if (
                            v30MinLocal !== null
                            && v30MaxLocal !== null
                            && (v30MinLocal > v30MaxLocal)
                          ) {
                            setV30MinLocal(v30MaxLocal - 1);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <NumberFormat
                      key="singleV30"
                      disabled={!enableV30}
                      thousandSeparator
                      prefix=""
                      name="what"
                      value={singleV30Local}
                      className="form-control"
                      style={{ height: '44px' }}
                      placeholder="V30"
                      inputMode="numeric"
                      allowEmptyFormatting={false}
                      fixedDecimalScale
                      decimalScale={0}
                      onValueChange={values => setSingleV30Local(values.floatValue)}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                InfluenceLogic Contact
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Match"
                  label="Match"
                  value={matchFilters.Match}
                  onChange={event => handleChangeMatchFilters(event, 'Match')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="NoMatch"
                  label="No Match"
                  value={matchFilters['No Match']}
                  onChange={event => handleChangeMatchFilters(event, 'No Match')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Contact Owner
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="AssignedContactOwner"
                  label="Assigned"
                  value={contactOwnerFilters.Assigned}
                  onChange={event => handleChangeContactOwnerFilters(event, 'Assigned')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="NotAssignedContactOwner"
                  label="Not Assigned"
                  value={contactOwnerFilters['Not Assigned']}
                  onChange={event => handleChangeContactOwnerFilters(event, 'Not Assigned')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Deal Owner
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="AssignedDealOwner"
                  label="Assigned"
                  value={dealOwnerFilters.Assigned}
                  onChange={event => handleChangeDealOwnerFilters(event, 'Assigned')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="NotAssignedDealOwner"
                  label="Not Assigned"
                  value={dealOwnerFilters['Not Assigned']}
                  onChange={event => handleChangeDealOwnerFilters(event, 'Not Assigned')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Target Brand
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="AssignedTargetBrand"
                  label="Assigned"
                  value={targetBrandFilters.Assigned}
                  onChange={event => handleChangeTargetBrandFilters(event, 'Assigned')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="NotAssignedTargetBrand"
                  label="Not Assigned"
                  value={targetBrandFilters['Not Assigned']}
                  onChange={event => handleChangeTargetBrandFilters(event, 'Not Assigned')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Prospect Type
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Standard"
                  label="Standard"
                  value={isInternalFilters.Standard}
                  onChange={event => handleChangeIsInternalFilters(event, 'Standard')}
                />
              </FormGroup>
              <FormGroup>
                <CheckBoxField
                  name="Internal"
                  label="Internal"
                  value={isInternalFilters.Internal}
                  onChange={event => handleChangeIsInternalFilters(event, 'Internal')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Blacklist?
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Yes"
                  label="Yes"
                  value={inBlacklistFilters.Yes}
                  onChange={event => handleChangeIsBlacklistFilters(event, 'Yes')}
                />
              </FormGroup>
              <FormGroup>
                <CheckBoxField
                  name="No"
                  label="No"
                  value={inBlacklistFilters.No}
                  onChange={event => handleChangeIsBlacklistFilters(event, 'No')}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  _.isEqual(a.matchFilters, a1.matchFilters) &&
  _.isEqual(a.contactOwnerFilters, a1.contactOwnerFilters) &&
  _.isEqual(a.dealOwnerFilters, a1.dealOwnerFilters) &&
  _.isEqual(a.targetBrandFilters, a1.targetBrandFilters) &&
  _.isEqual(a.isInternalFilters, a1.isInternalFilters) &&
  _.isEqual(a.inBlacklistFilters, a1.inBlacklistFilters) &&
  _.isEqual(a.selectedProspectIds, a1.selectedProspectIds) &&
  _.isEqual(a.tags, a1.tags) &&
  _.isEqual(a.owners, a1.owners) &&
  _.isEqual(a.brands, a1.brands) &&
  _.isEqual(a.brandsSuggestions, a1.brandsSuggestions) &&
  _.isEqual(a.tagsSuggestions, a1.tagsSuggestions) &&
  _.isEqual(a.ownersSuggestions, a1.ownersSuggestions) &&
  _.isEqual(a.enableLastAd, a1.enableLastAd) &&
  _.isEqual(a.lastActivityDateValue, a1.lastActivityDateValue) &&
  _.isEqual(a.singleDate, a1.singleDate) &&
  _.isEqual(a.dateFrom, a1.dateFrom) &&
  _.isEqual(a.dateTo, a1.dateTo) &&
  _.isEqual(a.enableScore, a1.enableScore) &&
  _.isEqual(a.scoreValue, a1.scoreValue) &&
  _.isEqual(a.singleScore, a1.singleScore) &&
  _.isEqual(a.scoreMin, a1.scoreMin) &&
  _.isEqual(a.scoreMax, a1.scoreMax) &&
  _.isEqual(a.enableV30, a1.enableV30) &&
  _.isEqual(a.v30Value, a1.v30Value) &&
  _.isEqual(a.singleV30, a1.singleV30) &&
  _.isEqual(a.v30Min, a1.v30Min) &&
  _.isEqual(a.v30Max, a1.v30Max));
Actions.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  getUrlSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  tagsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsFilter: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  ownersSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setOwnersFilter: PropTypes.func.isRequired,
  setOwners: PropTypes.func.isRequired,
  owners: PropTypes.arrayOf(PropTypes.any).isRequired,
  setBrandsFilter: PropTypes.func.isRequired,
  setBrands: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setMatchFilters: PropTypes.func.isRequired,
  matchFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setContactOwnerFilters: PropTypes.func.isRequired,
  contactOwnerFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setDealOwnerFilters: PropTypes.func.isRequired,
  dealOwnerFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setTargetBrandFilters: PropTypes.func.isRequired,
  targetBrandFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  isInternalFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setIsInternalFilters: PropTypes.func.isRequired,
  inBlacklistFilters: PropTypes.objectOf(PropTypes.any),
  setInBlacklistFilters: PropTypes.func,
  setShowAssignContactOwnerModal: PropTypes.func.isRequired,
  setShowAssignDealOwnerModal: PropTypes.func.isRequired,
  selectedProspectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  doHubspotImport: PropTypes.func.isRequired,
  disableGlobalImport: PropTypes.bool.isRequired,
  enableLastAd: PropTypes.bool.isRequired,
  setEnableLastAd: PropTypes.func.isRequired,
  lastActivityDateValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setLastActivityDateValue: PropTypes.func.isRequired,
  dateFrom: PropTypes.objectOf(PropTypes.any),
  setDateFrom: PropTypes.func.isRequired,
  dateTo: PropTypes.objectOf(PropTypes.any),
  setDateTo: PropTypes.func.isRequired,
  singleDate: PropTypes.objectOf(PropTypes.any),
  setSingleDate: PropTypes.func.isRequired,
  scoreValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setScoreValue: PropTypes.func.isRequired,
  enableScore: PropTypes.bool.isRequired,
  setEnableScore: PropTypes.func.isRequired,
  scoreMin: PropTypes.number,
  setScoreMin: PropTypes.func.isRequired,
  scoreMax: PropTypes.number,
  setScoreMax: PropTypes.func.isRequired,
  singleScore: PropTypes.number,
  setSingleScore: PropTypes.func.isRequired,
  v30Value: PropTypes.objectOf(PropTypes.any).isRequired,
  setV30Value: PropTypes.func.isRequired,
  enableV30: PropTypes.bool.isRequired,
  setEnableV30: PropTypes.func.isRequired,
  v30Min: PropTypes.number,
  setV30Min: PropTypes.func.isRequired,
  v30Max: PropTypes.number,
  setV30Max: PropTypes.func.isRequired,
  singleV30: PropTypes.number,
  setSingleV30: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  dateFrom: null,
  dateTo: null,
  singleDate: null,
  scoreMin: null,
  scoreMax: null,
  singleScore: null,
  v30Min: null,
  v30Max: null,
  singleV30: null,
  inBlacklistFilters: {},
  setInBlacklistFilters: () => {},
};

export default Actions;
