import React from 'react';
import PropTypes from 'prop-types';
import { Container, Spinner, Row, Col } from 'reactstrap';
import { axios } from 'ApiClient';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import Panel from '../../../../shared/components/Panel';
import { getDealsFilterTypes } from '../../../../shared/helpers/Filter';
import Cols from './Cols';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import CheckboxFilter from '@/shared/components/CheckboxFilter';

class ServiceAgreement extends React.Component {
  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
  };
  constructor(props) {
    super(props);
    const { influencerId } = props.match.params;
    this.state = {
      loading: false,
      agreements: [],
      influencerId,
      influencerName: '',
      statusFilter: {
        Pending: true,
        Complete: true,
        Deleted: true,
      },
      dealTypeFilter: getDealsFilterTypes(),
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  setStatusFilter = (filters) => {
    this.setState({ statusFilter: filters.data });
  }

  setDealTypeFilter = (filters) => {
    this.setState({ dealTypeFilter: filters.data });
  }
  fetchData() {
    const { influencerId } = this.state;
    this.setState({ loading: true });
    axios.get(`/api/portal/influencer-service-agreements?influencerId=${influencerId}`).then((response) => {
      const { influencerName, agreements } = response.data;
      this.setState({
        influencerName,
        agreements,
        loading: false,
      });
    }).catch((error) => {
      console.log(error);
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const {
      loading,
      influencerName,
      agreements,
      statusFilter,
      dealTypeFilter,
    } = this.state;
    return (
      <Container className="dashboard">
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            { title: 'Creators', path: '/resources/Influencer/actions/list' },
            { title: 'Creator Service Agreements', path: null },
          ]}
          isBackButton
        />
        {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        {!loading &&
          <div>
            <h4 style={{ marginBottom: '20px' }}>Creator Service Agreements</h4>
            <Panel title={influencerName} showButtons={false}>
              <Row style={{ marginBottom: '-16px' }}>
                <Col xs="auto">
                  <CheckboxFilter
                    filterProp={statusFilter}
                    filterTitle="Status"
                    filterAction={this.setStatusFilter}
                    style={{ paddingTop: 0 }}
                    classname="btn-sm"
                  />
                </Col>
                <Col xs="auto">
                  <CheckboxFilter
                    filterProp={dealTypeFilter}
                    filterTitle="Deal Type"
                    filterAction={this.setDealTypeFilter}
                    style={{ paddingTop: 0 }}
                    classname="btn-sm"
                  />
                </Col>
              </Row>
              <MatTableForRedux
                dataArray={agreements}
                selectable={false}
                columns={Cols}
                version={(new Date()).toString()}
                defaultOrder={{
                  order: 'asc',
                  orderBy: 'brand',
                }}
                rowsPerPage={10}
                hideToolbar
                filters={{
                  status: statusFilter,
                  deal_type: dealTypeFilter,
                }}
              />
            </Panel>
          </div>
        }
      </Container>
    );
  }
}

export default ServiceAgreement;
