import React, { useState, useMemo, useEffect } from 'react';
import { useFlexLayout } from 'react-table';
import { connect, useDispatch } from 'react-redux';
import { Progress, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import AdminBroServerSide from './AdminBroServerSide';
import { removeSelectedRecord, setSelectedRecord, selectAll, diselectAll, setUrlParamsForTable } from '../../../redux/reducers/TableReducerDefault';

const ProgressObj = () => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    let cleanupFunction = false;
    setTimeout(() => {
      if (!cleanupFunction) setValue(value + 3);
    }, 100);
    return () => {
      cleanupFunction = true;
      return true;
    };
  }, [value]);
  return (<Progress animated value={value} />);
};
const CustomCheckbox = withStyles({
  root: {
    color: '#70bbfd',
    '&$checked': {
      color: '#70bbfd',
    },
  },
  checked: {},
})(React.forwardRef((props, ref) => <Checkbox color="default" size="small" {...props} ref={ref} />));

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    if (resolvedRef.current) resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  // console.log(rest);
  return (
    <div style={{ paddingTop: '4px' }}>
      <CustomCheckbox
        name={Math.random().toString(36).slice(2)}
        label=""
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        checked={!rest.disabled && rest.checked}
        onClick={e => !rest.disabled && e.target && rest.onChange(e)}
        ref={resolvedRef}
        disabled={rest.disabled}
      />
    </div>
  );
});
IndeterminateCheckbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  indeterminate: PropTypes.any.isRequired,
};

const CheckBox = React.memo(({
  dispatch, id, selected, selectAllCheckbox, disabled, record,
}) => {
  const defaultRef = React.useRef();
  const onChange = ({ target }) => {
    if (target.checked) {
      if (selectAllCheckbox) {
        dispatch(selectAll());
      } else {
        dispatch(setSelectedRecord({ selectedItemNumber: id, record }));
      }
    } else if (selectAllCheckbox) {
      dispatch(diselectAll());
    } else {
      dispatch(removeSelectedRecord(id));
    }
  };
  return (
    <div style={{ paddingTop: '4px' }}>
      <CustomCheckbox
        name={Math.random().toString(36).slice(2)}
        label=""
        className="myCheckBox"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        checked={selected}
        onClick={e => !disabled && e.target && onChange(e)}
        ref={defaultRef}
        disabled={disabled}
      />
    </div>
  );
});
CheckBox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.any,
  selectAllCheckbox: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
};
CheckBox.defaultProps = {
  record: null,
  disabled: false,
  id: null,
  selectAllCheckbox: false,
};
const ConnecteCheckBox = connect((state, props) => {
  let isSelected = false;
  if (state.tableRedux.records.length > 0) {
    isSelected = props.selectAllCheckbox ?
      state.tableRedux.records.length === state.tableRedux?.selectedRecords.length :
      state.tableRedux?.selectedRecords?.includes(props.id);
  }
  // console.log(state.table?.selectedRecordsIdsMultiselect);
  if (props.original?.id && state.tableRedux.useMultiPageCheckboxes) {
    isSelected = state.tableRedux?.selectedRecordsIdsMultiselect?.includes(props.original?.id);
  }
  // console.log(props);
  return {
    selected: isSelected,
    record: props.original,
  };
})(CheckBox);

const AdminBroTableRedux = React.memo(({
  records,
  direction,
  sortBy,
  isLoading,
  page,
  perPage,
  total,
  tableConfig,
  useCheckboxes,
  checkboxHandler,
  customColumns,
  updateRecordFunction,
  enabledStyles,
  enableWarning,
  notFoundProps,
}) => {
  const [columnWidths, setColumnWidths] = useState({});
  const columns = useMemo(() => {
    let ret = [];
    if (customColumns && customColumns.length > 0) {
      ret = customColumns.map((customColumn) => {
        const column = { ...customColumn };
        const width = columnWidths[column.id];
        if (width) column.width = width;
        return (column);
      });
    }
    return ret;
  }, [
    customColumns,
  ]);
  const data = records;
  const {
    isSortable,
    withDragAndDrop,
    useFlex,
  } = tableConfig;
  const tableOptions = {
    columns,
    data,
    // setFilterValue,
    updateDraggableData: () => {},
    updateEditableData: () => {},
    defaultColumn: {},
    withDragAndDrop: withDragAndDrop || false,
    dataLength: total,
    autoResetPage: false,
    disableSortBy: !isSortable,
    disableSortRemove: true, // remove third click action on Sort arrow icon in table header
    manualSortBy: true,
    manualGlobalFilter: true,
    manualPagination: true,
    initialState: {
      pageIndex: (page - 1),
      pageSize: perPage,
      // globalFilter: withSearchEngine && filterValue ? filterValue : undefined,
      sortBy: [{ id: sortBy, desc: direction === 'desc' }],
      allowChange: true,
    },
    pageCount: Math.ceil(Number(total || 0) / Number(perPage || 0)),
  };
  console.log(tableOptions);
  const dispatch = useDispatch();
  const generateUrl = (o) => {
    dispatch(setUrlParamsForTable({
      page: ((o.pageIndex || 0) + 1),
      perPage: o.pageSize.toString(),
      direction: o.sortBy[0]?.desc === false ? 'asc' : 'desc',
      sortBy: o.sortBy[0]?.id,
    }));
  };
  let tableOptionalHook = [];
  if (useFlex) tableOptionalHook = [useFlexLayout];
  if (useCheckboxes && checkboxHandler) {
    tableOptionalHook.push((hooks) => {
      hooks.visibleColumns.push(columnsAll => [
        // Let's make a column for selection
        {
          minWidth: 60,
          width: 60,
          maxWidth: 60,
          id: 'selection1',
          Header: props => (
            <div>
              <ConnecteCheckBox props={{ ...props }} selectAllCheckbox />
            </div>
          ),
          // eslint-disable-next-line react/prop-types
          Cell: ({ row }) => (
            <div>
              {/* eslint-disable-next-line react/prop-types */}
              <ConnecteCheckBox id={Number(row.id)} disabled={row?.original?.checkboxDisable || false} original={row?.original} />
            </div>
          ),
          className: (useFlex ? 'd-flex align-items-center' : ''),
        },
        ...columnsAll,
      ]);
    });
  }

  return (
    <Col>
      {isLoading ? <ProgressObj /> : null}
      <AdminBroServerSide
        useDebounce={false}
        key="common"
        onFetchData={props => generateUrl(props)}
        tableConfig={tableConfig}
        tableOptions={tableOptions}
        tableOptionalHook={tableOptionalHook}
        isLoading={isLoading}
        pageIndex={(page - 1)}
        pageSize={perPage}
        updateRecordFunction={updateRecordFunction}
        enabledStyles={enabledStyles}
        enableWarning={enableWarning}
        onColumnResize={receivedColumnWidths => setColumnWidths({ ...columnWidths, ...receivedColumnWidths })}
        notFoundProps={notFoundProps}
      />
    </Col>
  );
}, (a, a1) => _.isEqual(a.records, a1.records)
&& _.isEqual(a.customColumns, a1.customColumns)
&& a.isLoading === a1.isLoading);

AdminBroTableRedux.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any).isRequired,
  direction: PropTypes.string,
  sortBy: PropTypes.string,
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    useFlex: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
  }),
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  useCheckboxes: PropTypes.bool,
  checkboxHandler: PropTypes.func,
  customColumns: PropTypes.arrayOf(PropTypes.any),
  updateRecordFunction: PropTypes.func,
  enabledStyles: PropTypes.bool,
  enableWarning: PropTypes.bool,
  notFoundProps: PropTypes.objectOf(PropTypes.any),
};

AdminBroTableRedux.defaultProps = {
  direction: 'asc',
  sortBy: 'id',
  isLoading: false,
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withDragAndDrop: false,
    withPagination: true,
    withSearchEngine: false,
    useFlex: false,
    manualPageSize: [],
  },
  total: 2,
  page: 1,
  perPage: 10,
  useCheckboxes: false,
  checkboxHandler: null,
  customColumns: [],
  updateRecordFunction: () => console.log('function Not Defined'),
  enabledStyles: true,
  enableWarning: true,
  notFoundProps: {},
};

export default AdminBroTableRedux;
