import React, { useState, useEffect, useMemo } from 'react';
import { compile } from 'mathjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Progress,
  Button,
  Modal,
  ButtonToolbar,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { axios } from 'ApiClient';
import { useAsyncDebounce } from 'react-table';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import classnames from 'classnames';
import {
  setUploadChannelsDialogVisible,
  uploadChannels,
  refetchData,
  getFormulas,
  getMyWatchlists,
} from '../../../../../redux/reducers/admin/ResearchReducer';
import withNotice from '../../../../App/store/with-notice';
import { NoResourceError } from '../../../../../shared/components/components/ui/error404';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import StyledButton from '../../../../../shared/components/components/ui/styled-button';
import ButtonWithSpinner from '../../../../../shared/components/ButtonWithSpinner';
import ImportCreatorsModal from './components/custom/ImportCreatorsModal';
import AskingAmountInList from './components/custom/AskingAmountInList';
import OfferAmountInList from './components/custom/OfferAmountInList';
import UserPropertyType from '../../../Impact/ImpactActionItem/components/custom';
import { setCookie, getCookie } from '../../../../../shared/helpers/WVUtilities';
import ProgressBar from '../../../../../shared/helpers/ProgressBar';
import CreatorDetailsModal from '../../../../../shared/components/CreatorDetailsModal';
import TableCellContextMenu from '../../../Scorecard/MediaContent/components/custom/TableCellContextMenu';
import DeleteModalDialog from './components/custom/DeleteModalDialog';
import DetailsModalDialog from './components/custom/DetailsModalDialog';
import WVFormatter from '../../../../../shared/helpers/WVFormatter';
import CopyToClipboard from '../../../../../shared/components/CopyToClipboard';

const ProgressObj = () => {
  const [value, setValue] = useState(10);
  useEffect(() => {
    let cleanupFunction = false;
    setTimeout(() => {
      if (!cleanupFunction) setValue(97);
    }, 100);
    return () => {
      cleanupFunction = true;
      return true;
    };
  }, []);
  return (<Progress animated value={value} />);
};

const numberWithCommas = (x) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

const ResearchCreatorAccountList = ({
  resources,
  dispatch,
  loadingUploadChannels,
  modalVisible,
  creatorsGridCreated,
  addNotice,
  loadingRefetchData,
  uploadResults,
  loadingFormulas,
  formulas,
  formulaTokens,
  loadingWatchlists,
  watchlists,
}) => {
  const [selectedCreatorId, setSelectedCreatorId] = useState(null);
  const [activeTab, setActiveTab] = useState('videos');
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [tag, setTag] = useState('');
  const [showVideosTable, setShowVideosTable] = useState(false);
  const [pseudoUrlVideo, setPseudoUrlVideo] = useState('?filters.platform=youtube&sortBy=createdAt&direction=desc&page=1&perPage=10');
  const [searchValueVideo, setSearchValueVideo] = useState(''); // useState(new URLSearchParams(window.location.search).get('filters.title') || '');
  const [selectedItemsVideo, setSelectedItemsVideo] = useState([]);
  const [matTableVersionVideo, setMatTableVersionVideo] = useState(new Date().toString());
  const [showPodcastsTable, setShowPodcastsTable] = useState(false);
  const [pseudoUrlPodcast, setPseudoUrlPodcast] = useState('?filters.platform=podcast&sortBy=createdAt&direction=desc&page=1&perPage=10');
  const [searchValuePodcast, setSearchValuePodcast] = useState(''); // useState(new URLSearchParams(window.location.search).get('filters.title') || '');
  const [selectedItemsPodcast, setSelectedItemsPodcast] = useState([]);
  const [matTableVersionPodcast, setMatTableVersionPodcast] = useState(new Date().toString());
  const [currentWatchlist, setCurrentWatchlist] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [channelIdToDelete, setChannelIdToDelete] = useState('');
  const [detailsRecord, setDetailsRecord] = useState({});

  useEffect(async () => {
    dispatch(getFormulas());
    dispatch(getMyWatchlists(true));
  }, []);

  const startSearchVideo = useAsyncDebounce(() => {
    const search = new URLSearchParams(pseudoUrlVideo);
    search.delete('filters.title');
    if (searchValueVideo && searchValueVideo.length > 0) search.append('filters.title', searchValueVideo);
    search.set('page', '1');

    setPseudoUrlVideo(search.toString());
  }, 500);

  useEffect(async () => {
    startSearchVideo();
  }, [searchValueVideo]);

  useEffect(async () => {
    // do not load data without watchlist filter
    setShowVideosTable(pseudoUrlVideo && pseudoUrlVideo.indexOf('watchlist') !== -1);
  }, [pseudoUrlVideo]);

  const startSearchPodcast = useAsyncDebounce(() => {
    const search = new URLSearchParams(pseudoUrlPodcast);
    search.delete('filters.title');
    if (searchValuePodcast && searchValuePodcast.length > 0) search.append('filters.title', searchValuePodcast);
    search.set('page', '1');

    setPseudoUrlPodcast(search.toString());
  }, 500);

  useEffect(async () => {
    startSearchPodcast();
  }, [searchValuePodcast]);

  useEffect(async () => {
    // do not load data without watchlist filter
    setShowPodcastsTable(pseudoUrlPodcast && pseudoUrlPodcast.indexOf('watchlist') !== -1);
  }, [pseudoUrlPodcast]);

  useEffect(async () => {
    if (watchlists && watchlists.length > 0) {
      const masterList = watchlists[0];
      const watchlistsPrepared = [];
      watchlists.forEach((element) => {
        if (element && element.value) {
          watchlistsPrepared.push(element);
        } else if (element && element.options) {
          watchlistsPrepared.push(...element.options);
        }
      });
      const watchlistIdFromCookie = getCookie('selectedWatchlistOnCreatorResearch');
      if (watchlistIdFromCookie) {
        const watchlistFromCookie = watchlistsPrepared.find(watchlist => watchlist.value === watchlistIdFromCookie);
        if (watchlistFromCookie) {
          setCurrentWatchlist(watchlistFromCookie);
        } else setCurrentWatchlist(masterList);
      } else setCurrentWatchlist(masterList);
    }
  }, [watchlists]);

  useEffect(async () => {
    const searchVideo = new URLSearchParams(pseudoUrlVideo);
    searchVideo.delete('filters.watchlist');

    const searchPodcast = new URLSearchParams(pseudoUrlPodcast);
    searchPodcast.delete('filters.watchlist');

    if (currentWatchlist && currentWatchlist.value) {
      searchVideo.append('filters.watchlist', currentWatchlist.value);
      searchPodcast.append('filters.watchlist', currentWatchlist.value);
      setCookie('selectedWatchlistOnCreatorResearch', currentWatchlist.value, 35);
    }

    searchVideo.set('page', '1');
    searchPodcast.set('page', '1');

    setPseudoUrlVideo(searchVideo.toString());
    setPseudoUrlPodcast(searchPodcast.toString());
  }, [currentWatchlist]);

  const onSearchChange = (event) => {
    if (activeTab === 'videos') {
      setSearchValueVideo(event?.target?.value);
    } else {
      setSearchValuePodcast(event?.target?.value);
    }
    return true;
  };

  const importRecords = (watchlistId, selectedAgent, files) => {
    const file = files.length ? files[0] : false;
    if (file) {
      dispatch(uploadChannels(watchlistId, selectedAgent.value, file));
    }
    return true;
  };

  const setModalVisible = (visible) => {
    dispatch(setUploadChannelsDialogVisible(visible));
  };

  const refetchVideo = () => {
    dispatch(refetchData(selectedItemsVideo));
  };

  // eslint-disable-next-line no-unused-vars
  const handleSelectCheckboxVideo = (selectedIds) => {
    setSelectedItemsVideo(selectedIds);
  };

  // eslint-disable-next-line no-unused-vars
  const handleSelectCheckboxPodcast = (selectedIds) => {
    setSelectedItemsPodcast(selectedIds);
  };

  const rowActionDelete = (researchCreatorId) => {
    setChannelIdToDelete(researchCreatorId);
    setDeleteModalVisible(true);
  };

  const rowActionShowProperties = (recordParams) => {
    setDetailsRecord(recordParams);
    setDetailsModalVisible(true);
  };

  const rowActionCreateHsUser = (recordParams) => {
    document.location = `/research/create-hs-contact/${recordParams['researchCreator._id']}`;
  };

  const reloadGrid = () => {
    if (activeTab === 'videos') {
      setMatTableVersionVideo(new Date().toString());
    }
    if (activeTab === 'podcasts') {
      setMatTableVersionPodcast(new Date().toString());
    }
  };

  const customColumnsVideo = useMemo(() => [
    {
      Header: 'Creator',
      accessor: () => null,
      id: 'researchCreatorAccountInfo',
      minWidth: 300,
      maxWidth: 350,
      Cell: record => (
        <div
          className="d-flex"
        >
          {record.row.original.params['researchCreator.tubularCreatorId']
            ? (
              <button
                className="my-auto"
                style={{
                  padding: '0',
                  margin: '0',
                  border: 'none',
                  backgroundColor: 'white',
                  maxWidth: '65px',
                }}
                onClick={() => setSelectedCreatorId(record.row.original.params['researchCreator.tubularCreatorId'])}
              >
                <img
                  src={record.row.original.params.thumbnailUrl}
                  alt={record.row.original.params.title}
                  style={{ width: '65px', height: 'auto' }}
                />
              </button>
            )
            : (
              <img
                className="my-auto"
                src={record.row.original.params.thumbnailUrl}
                alt={record.row.original.params.title}
                style={{ width: '65px', height: 'auto' }}
              />
            )
          }
          <div
            className="my-auto"
            style={{ marginLeft: '8px' }}
          >
            <div
              className="d-flex"
            >
              <span
                className="bold-text"
              >
                {record.row.original.params.title}
                <a
                  href={record.row.original.params.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: '4px' }}
                >
                  <img
                    src={`/images/mediacontent/${record.row.original.params.platform}.png`}
                    alt="YouTube"
                    title="YouTube"
                    style={{ width: '20px' }}
                  />
                </a>
              </span>
            </div>
            <span >
              {record.row.original.params['researchCreator.genre'] || ''}
              {record.row.original.params['researchCreator.genre'] && record.row.original.params['researchCreator.country'] && (' | ')}
              {record.row.original.params['researchCreator.country'] || ''}
              {record.row.original.params['researchCreator.tubularCreatorId'] && (
                <br />
              )}
              {record.row.original.params['researchCreator.tubularCreatorId'] && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                <a
                  role="button"
                  onClick={() => setSelectedCreatorId(record.row.original.params['researchCreator.tubularCreatorId'])}
                >
                  <span
                    style={{
                      color: '#70bbfd',
                    }}
                  >
                    Details
                  </span>
                </a>
              )}
              {(record.row.original.params['researchCreator.hsUserId'] || record.row.original.params['hubilContact.hs_object_id'] || record.row.original.params['hubilAgent.hs_object_id']) && (
                <a
                  title="Show HubSpot Contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-3"
                  style={{ fontSize: '18px' }}
                  href={
                    `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.row.original.params['researchCreator.hsUserId'] ||
                    record.row.original.params['hubilContact.hs_object_id'] ||
                    record.row.original.params['hubilAgent.hs_object_id']}`
                  }
                >
                  <i className="fab fa-hubspot" />
                </a>
              )}
            </span>
          </div>
        </div>
      ),
      disableSortBy: false,
    },
    {
      Header: 'Score',
      accessor: () => null,
      id: 'influencer_score',
      maxWidth: 30,
      Cell: record => (
        <div style={{ paddingTop: '22px' }}>
          <span>
            {record.row.original.params.influencer_score
              ? numberWithCommas(Number(record.row.original.params.influencer_score).toFixed())
              : '-'
            }
          </span>
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
    },
    {
      Header: 'V30',
      accessor: () => null,
      id: 'monthly_v30',
      maxWidth: 40,
      Cell: record => (
        <div style={{ paddingTop: '22px' }}>
          <span>
            {record.row.original.params.monthly_v30
              ? numberWithCommas(Number(record.row.original.params.monthly_v30).toFixed())
              : '-'
            }
          </span>
        </div>
      ),
      disableSortBy: false,
    },
    {
      Header: 'ER30',
      accessor: () => null,
      id: 'monthly_er30',
      maxWidth: 30,
      Cell: record => (
        <div style={{ paddingTop: '22px' }}>
          <span>
            {record.row.original.params.monthly_er30
              ? numberWithCommas(Number(record.row.original.params.monthly_er30).toFixed(1))
              : '-'
            }
          </span>
        </div>
      ),
      disableSortBy: false,
    },
    {
      Header: '% Male',
      accessor: () => null,
      id: 'malePercentage',
      maxWidth: 30,
      Cell: record => (
        <div style={{ paddingTop: '22px' }}>
          <span>
            {record.row.original.params.malePercentage
              ? `${Number(record.row.original.params.malePercentage).toFixed()}%`
              : '-'
            }
          </span>
        </div>
      ),
      disableSortBy: false,
    },
    {
      Header: '% Female',
      accessor: () => null,
      id: 'femalePercentage',
      maxWidth: 80,
      Cell: record => (
        <div style={{ paddingTop: '22px' }}>
          <span>
            {record.row.original.params.femalePercentage
              ? `${Number(record.row.original.params.femalePercentage).toFixed()}%`
              : '-'
            }
          </span>
        </div>
      ),
      disableSortBy: false,
    },
    {
      Header: '% US',
      accessor: () => null,
      id: 'percentUS',
      maxWidth: 30,
      Cell: record => (
        <div style={{ paddingTop: '22px' }}>
          <span>
            {record.row.original.params.percentUS
              ? `${Number(record.row.original.params.percentUS).toFixed(1)}%`
              : '-'
            }
          </span>
        </div>
      ),
      disableSortBy: false,
    },
    {
      Header: '% Over 25 YO',
      accessor: () => null,
      id: 'percent25_55_plus',
      maxWidth: 70,
      Cell: record => (
        <div style={{ paddingTop: '22px' }}>
          <span>
            {record.row.original.params.percent25_55_plus
              ? `${Number(record.row.original.params.percent25_55_plus).toFixed(1)}%`
              : '-'
            }
          </span>
        </div>
      ),
      disableSortBy: false,
    },
    // eslint-disable-next-line array-callback-return,consistent-return
    ...formulas.map((formula) => {
      if (formula) {
        return (
          {
            Header: formula.name,
            accessor: () => null,
            id: formula.name.replaceAll(' ', ''),
            maxWidth: 70,
            Cell: (record) => {
              if (formula.formula) {
                let formulaString = `${formula.formula}`;
                Object.keys(formulaTokens).forEach((token) => {
                  if (token) {
                    let param = token.replaceAll('{', '');
                    param = param.replaceAll('}', '');
                    param = param.replaceAll(' ', '');
                    formulaString = formulaString.replaceAll(token, record.row.original.params[param] || '0');
                  }
                });
                let formulaResult = '';
                let hasError = false;
                try {
                  const code = compile(formulaString);
                  if (code) {
                    formulaResult = code.evaluate();
                  }
                } catch (e) {
                  console.log(`Error while computing formula '${formula.name}' result: `, e);
                  hasError = true;
                }
                let formattedResult = '';
                switch (formula.format) {
                  case 'currency':
                    formattedResult = WVFormatter.formatCurrency(formulaResult);
                    break;
                  case 'decimal':
                    formattedResult = `${numberWithCommas(Number(formulaResult).toFixed(2))}`;
                    break;
                  case 'integer':
                    formattedResult = `${numberWithCommas(Number(formulaResult).toFixed())}`;
                    break;
                  case 'text':
                    formattedResult = `${formulaResult}`;
                    break;
                  default:
                    formattedResult = `${formulaResult}`;
                }
                return (
                  <div style={{ paddingTop: '22px' }}>
                    {hasError
                      ? (
                        <span className="color-danger">
                          BAD FORMULA
                        </span>
                      ) : (
                        <span>
                          {formattedResult}
                        </span>
                      )
                    }
                  </div>
                );
              }
              return (
                <span>{' '}</span>
              );
            },
            disableSortBy: true,
          }
        );
      }
    }),
    {
      Header: 'Asking Amount',
      accessor: () => null,
      id: 'askingAmount',
      Cell: record => (
        <AskingAmountInList
          record={record.row.original}
          updateRecordFunction={record.updateRecordFunction}
        />
      ),
      disableSortBy: false,
      minWidth: 160,
    },
    {
      Header: 'Offer Amount',
      accessor: () => null,
      id: 'offerAmount',
      Cell: record => (
        <OfferAmountInList
          record={record.row.original}
          updateRecordFunction={record.updateRecordFunction}
        />
      ),
      disableSortBy: false,
      minWidth: 160,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'influencer_actions',
      maxWidth: 30,
      Cell: (record) => {
        const contextMenuOptions = [
          { label: 'Properties', handler: () => { rowActionShowProperties(record.row.original.params); } },
        ];
        if (typeof record.row.original.params.researchCreatorHubSpotId !== 'undefined' && record.row.original.params.researchCreatorHubSpotId) {
          contextMenuOptions.push({
            label: 'View HubSpot Contact',
            handler: () => {
              window.open(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.row.original.params.researchCreatorHubSpotId}/`);
            },
          });
        } else {
          contextMenuOptions.push({ label: 'Create HubSpot Contact', handler: () => { rowActionCreateHsUser(record.row.original.params); } });
        }
        contextMenuOptions.push({ label: 'Refresh Statistics', handler: () => { dispatch(refetchData([record.row.original.id])); } });
        contextMenuOptions.push({ label: 'Delete', handler: () => { rowActionDelete(record.row.original.params['researchCreator._id']); } });
        return (
          <div style={{ paddingTop: '8px' }}>
            <TableCellContextMenu options={contextMenuOptions} />
          </div>
        );
      },
      disableSortBy: true,
      className: 'align-middle',
    },
  ], [formulas, formulaTokens, selectedItemsVideo]);

  const customColumnsPodcast = useMemo(() => [
    {
      Header: 'Creator',
      accessor: () => null,
      id: 'researchCreatorAccountInfo',
      minWidth: 300,
      maxWidth: 350,
      Cell: record => (
        <div
          className="d-flex"
        >
          <img
            className="my-auto"
            src={record.row.original.params.thumbnailUrl}
            alt={record.row.original.params.title}
            style={{ width: '65px', height: '65px' }}
          />
          <div
            className="my-auto"
            style={{ marginLeft: '8px' }}
          >
            <div
              className="d-flex"
            >
              <span
                className="bold-text"
                style={{ display: 'inline-block' }}
              >
                {record.row.original.params.title}
                <a
                  href={record.row.original.params.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ marginLeft: '4px' }}
                >
                  <img
                    src={`/images/mediacontent/${record.row.original.params['listenNotesDetails.isEpisode'] ? 'episode_128' : 'podcast_128'}.png`}
                    alt={record.row.original.params['listenNotesDetails.isEpisode'] ? 'Episode' : 'Podcast'}
                    title={record.row.original.params['listenNotesDetails.isEpisode'] ? 'Episode' : 'Podcast'}
                    style={{ width: '20px' }}
                  />
                </a>
              </span>
              {(record.row.original.params['researchCreator.hsUserId'] || record.row.original.params['hubilContact.hs_object_id'] || record.row.original.params['hubilAgent.hs_object_id']) && (
                <a
                  title="Show HubSpot Contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-3"
                  style={{ fontSize: '18px' }}
                  href={
                    `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.row.original.params['researchCreator.hsUserId'] ||
                    record.row.original.params['hubilContact.hs_object_id'] ||
                    record.row.original.params['hubilAgent.hs_object_id']}`
                  }
                >
                  <i className="fab fa-hubspot" />
                </a>
              )}
            </div>
            {(record.row.original.params['researchCreator.genre'] || record.row.original.params['researchCreator.country']) && (
              <span style={{ display: 'block' }}>
                {record.row.original.params['researchCreator.genre'] || ''}
                {record.row.original.params['researchCreator.genre'] && record.row.original.params['researchCreator.country'] && (' | ')}
                {record.row.original.params['researchCreator.country'] || ''}
              </span>
            )}
            {typeof record.row.original.params['listenNotesDetails.email'] !== 'undefined' && record.row.original.params['listenNotesDetails.email'] !== null && (
              <span style={{ display: 'block' }}>
                <CopyToClipboard value={record.row.original.params['listenNotesDetails.email'] || ''}>
                  {record.row.original.params['listenNotesDetails.email']}
                </CopyToClipboard>
              </span>
            )}
          </div>
        </div>
      ),
      disableSortBy: false,
    },
    {
      Header: 'Listen Score',
      accessor: () => null,
      id: 'listenNotesDetails.listenScore',
      maxWidth: 30,
      Cell: record => (
        <div style={{ paddingTop: '22px' }}>
          <span>
            {record.row.original.params['listenNotesDetails.listenScore']
              ? record.row.original.params['listenNotesDetails.listenScore']
              : '-'
            }
          </span>
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
    },
    {
      Header: 'Global Rank',
      accessor: () => null,
      id: 'listenNotesDetails.listenScoreGlobalRank',
      maxWidth: 30,
      Cell: record => (
        <div style={{ paddingTop: '22px' }}>
          <span>
            {record.row.original.params['listenNotesDetails.listenScoreGlobalRank']
              ? record.row.original.params['listenNotesDetails.listenScoreGlobalRank']
              : '-'
            }
          </span>
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
    },
    {
      Header: 'Published Date',
      accessor: () => null,
      id: 'listenNotesDetails.publishedDate',
      minWidth: 100,
      Cell: record => (
        <div style={{ paddingTop: '22px' }}>
          <span>
            {record.row.original.params['listenNotesDetails.publishedDate']
              ? WVFormatter.getFormatedDate(record.row.original.params['listenNotesDetails.publishedDate'])
              : '-'
            }
          </span>
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
    },
    {
      Header: 'iTunes ID',
      accessor: () => null,
      id: 'listenNotesDetails.itunesId',
      minWidth: 120,
      Cell: record => (
        <div style={{ paddingTop: '22px' }}>
          <span>
            {record.row.original.params['listenNotesDetails.itunesId']
              ? (
                <CopyToClipboard value={record.row.original.params['listenNotesDetails.itunesId'].toString() || ''}>
                  <a
                    href={`https://podcasts.apple.com/us/podcast/id${record.row.original.params['listenNotesDetails.itunesId']}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {record.row.original.params['listenNotesDetails.itunesId']}
                  </a>
                </CopyToClipboard>
              )
              : '-'
            }
          </span>
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
    },
    {
      Header: 'Asking Amount',
      accessor: () => null,
      id: 'askingAmount',
      Cell: record => (
        <AskingAmountInList
          record={record.row.original}
          updateRecordFunction={record.updateRecordFunction}
        />
      ),
      disableSortBy: false,
      minWidth: 160,
    },
    {
      Header: 'Offer Amount',
      accessor: () => null,
      id: 'offerAmount',
      Cell: record => (
        <OfferAmountInList
          record={record.row.original}
          updateRecordFunction={record.updateRecordFunction}
        />
      ),
      disableSortBy: false,
      minWidth: 160,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'influencer_actions',
      maxWidth: 30,
      Cell: (record) => {
        const contextMenuOptions = [
          { label: 'Properties', handler: () => { rowActionShowProperties(record.row.original.params); } },
        ];
        if (typeof record.row.original.params['researchCreator.hsUserId'] !== 'undefined' && record.row.original.params['researchCreator.hsUserId']) {
          contextMenuOptions.push({
            label: 'View HubSpot Contact',
            handler: () => {
              window.open(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.row.original.params['researchCreator.hsUserId']}/`);
            },
          });
        } else {
          contextMenuOptions.push({ label: 'Create HubSpot Contact', handler: () => { rowActionCreateHsUser(record.row.original.params); } });
        }
        contextMenuOptions.push({ label: 'Refresh Statistics', handler: () => { dispatch(refetchData([record.row.original.id])); } });
        contextMenuOptions.push({ label: 'Delete', handler: () => { rowActionDelete(record.row.original.params['researchCreator._id']); } });
        return (
          <span>
            <TableCellContextMenu options={contextMenuOptions} />
          </span>
        );
      },
      disableSortBy: true,
      className: 'align-middle',
    },
  ], [selectedItemsPodcast]);

  const resource = resources.find(r => r.id === 'ResearchCreatorAccount');
  if (!resource) {
    return (<NoResourceError resourceId="ResearchCreatorAccount" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');

  const deleteSelected = async () => {
    try {
      setDeleteInProgress(true);
      axios.post('/api/portal/research/delete-accounts', { ids: activeTab === 'videos' ? selectedItemsVideo : selectedItemsPodcast }).then((response) => {
        if (response && response.data && response.data.success) {
          setDeleteInProgress(false);
          setDeleteModalOpen(false);
          reloadGrid();
        } else {
          if (response && response.data && response.data.error) {
            console.log(response.data.error);
          }
          addNotice({
            message: 'Failed to delete selected accounts. Please refresh the page and try again',
            type: 'error',
            duration: 15,
          });
          setDeleteInProgress(false);
          setDeleteModalOpen(false);
        }
      }).catch((error) => {
        console.log(error.message);
        addNotice({
          message: 'Failed to delete selected accounts. Please refresh the page and try again',
          type: 'error',
          duration: 15,
        });
        setDeleteInProgress(false);
        setDeleteModalOpen(false);
      });
    } catch (error) {
      console.log(error);
      addNotice({
        message: 'Failed to delete selected accounts. Please refresh the page and try again',
        type: 'error',
        duration: 15,
      });
      setDeleteInProgress(false);
      setDeleteModalOpen(false);
    }
  };

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  return (
    <Container className="dashboard full-width">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Creator Research', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <Row className="pb-1">
                  <Col md="4" className="pt-1">
                    <h3>Creator Watchlist</h3>
                  </Col>
                  <Col md="4">
                    <div className="d-flex">
                      <span
                        style={{
                          paddingTop: '8px',
                          width: '115px',
                        }}
                      >
                        Current List:
                      </span>
                      <div className="w-100 research-watchlists">
                        <Select
                          name="watchlistSelect"
                          isDisabled={loadingWatchlists}
                          isLoading={loadingWatchlists}
                          value={currentWatchlist}
                          options={watchlists}
                          onChange={setCurrentWatchlist}
                          placeholder="Select Watchlist..."
                          styles={colourStyles}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="4" className="ml-auto pt-1" style={{ textAlign: 'end' }}>
                    <a target="_blank" href="/research/formulas">
                      <FontAwesomeIcon icon={faCog} size="2x" />
                    </a>
                  </Col>
                </Row>
                <hr className="mt-2 mb-4" />
              </CardTitle>
              <ProgressBar
                topic="syncAllTubular"
              />
              <Row>
                <Col className="tabs">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'videos' })}
                        onClick={() => {
                          setActiveTab('videos');
                        }}
                      >
                        Videos
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'podcasts' })}
                        onClick={() => {
                          setActiveTab('podcasts');
                        }}
                      >
                        Podcasts
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="videos">
                      <Row className="mt-3">
                        <Col xs="3">
                          <InputGroup>
                            <Input
                              name="search"
                              type="text"
                              autoComplete="off"
                              placeholder="Search Videos..."
                              value={searchValueVideo}
                              onChange={onSearchChange}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"
                                  style={{ color: '#70bbfd', fontSize: 18 }}
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                        <Col xs="1" />
                        <Col xs="2">
                          <StyledButton
                            type="button"
                            className="btn btn-primary btn-lg"
                            disabled={!(selectedItemsVideo && selectedItemsVideo.length > 0)}
                            onClick={() => { setDeleteModalOpen(true); }}
                            style={{
                              width: '100%',
                              padding: '7px 25px',
                            }}
                          >
                            <i className="fa fa-trash-alt" />&nbsp;
                            <span className="btn-text">Delete</span>
                          </StyledButton>
                        </Col>
                        <Col xs="2">
                          <StyledButton
                            type="button"
                            className="btn btn-primary btn-lg"
                            onClick={() => { setModalVisible(true); }}
                            style={{
                              width: '100%',
                              padding: '7px 25px',
                            }}
                          >
                            <i className="fa fa-upload" />&nbsp;
                            <span className="btn-text">Upload</span>
                          </StyledButton>
                        </Col>
                        <Col xs="2">
                          <a
                            href={`/research/creators/download-csv?${pseudoUrlVideo}`}
                            className="btn btn-primary btn-sm justify-content-center align-content-center"
                            style={{
                              width: '100%',
                              padding: '7px 25px',
                            }}
                          >
                            <i className="fa fa-download" />&nbsp;
                            <span style={{ paddingTop: '8px' }}>Download</span>
                          </a>
                        </Col>
                        <Col xs="2">
                          <StyledButton
                            type="button"
                            className="btn btn-primary btn-lg"
                            onClick={refetchVideo}
                            disabled={loadingRefetchData}
                            style={{
                              width: '100%',
                              padding: '7px 25px',
                            }}
                          >
                            {loadingRefetchData ? (<Spinner color="primary" size="sm" />) : null }
                            {!loadingRefetchData ? (<span><i className="fa fa-sync" /></span>) : null }
                            &nbsp;
                            <span style={{ paddingTop: '8px' }}>Refresh</span>
                          </StyledButton>
                        </Col>
                      </Row>
                      {(!loadingFormulas && !loadingWatchlists && showVideosTable)
                        ? (
                          <div key={`div-${creatorsGridCreated.getTime()}`} style={{ margin: '-18px -30px 0 -30px' }}>
                            <MatTableList
                              action={action}
                              resource={resource}
                              sortBy="createdAt"
                              direction="desc"
                              setTag={setTag}
                              date={new Date().toString()}
                              UserPropertyType={UserPropertyType}
                              tag={tag}
                              v={2}
                              hideToolbar
                              showTitle={false}
                              useCheckboxes
                              checkboxHandler={handleSelectCheckboxVideo}
                              customColumns={customColumnsVideo}
                              displayProgressBar={false}
                              noActions
                              pseudoUrlFiltering
                              pseudoUrl={pseudoUrlVideo}
                              updatePseudoUrl={setPseudoUrlVideo}
                              version={matTableVersionVideo}
                            />
                          </div>
                        )
                        : (<div className="pt-3" ><ProgressObj /></div>)
                      }
                    </TabPane>
                    <TabPane tabId="podcasts">
                      <Row className="mt-3">
                        <Col xs="3">
                          <InputGroup>
                            <Input
                              name="search"
                              type="text"
                              autoComplete="off"
                              placeholder="Search Podcasts..."
                              value={searchValuePodcast}
                              onChange={onSearchChange}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"
                                  style={{ color: '#70bbfd', fontSize: 18 }}
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                        <Col xs="5" />
                        <Col xs="2">
                          <StyledButton
                            type="button"
                            className="btn btn-primary btn-lg"
                            disabled={!(selectedItemsPodcast && selectedItemsPodcast.length > 0)}
                            onClick={() => { setDeleteModalOpen(true); }}
                            style={{
                              width: '100%',
                              padding: '7px 25px',
                            }}
                          >
                            <i className="fa fa-trash-alt" />&nbsp;
                            <span className="btn-text">Delete</span>
                          </StyledButton>
                        </Col>
                        <Col xs="2">
                          <a
                            href={`/research/creators/download-csv?${pseudoUrlPodcast}`}
                            className="btn btn-primary btn-sm justify-content-center align-content-center"
                            style={{
                              width: '100%',
                              padding: '7px 25px',
                            }}
                          >
                            <i className="fa fa-download" />&nbsp;
                            <span style={{ paddingTop: '8px' }}>Download</span>
                          </a>
                        </Col>
                      </Row>
                      {(!loadingWatchlists && showPodcastsTable)
                        ? (
                          <div style={{ margin: '-18px -30px 0 -30px' }}>
                            <MatTableList
                              action={action}
                              resource={resource}
                              sortBy="createdAt"
                              direction="desc"
                              setTag={setTag}
                              date={new Date().toString()}
                              UserPropertyType={UserPropertyType}
                              tag={tag}
                              v={2}
                              hideToolbar
                              showTitle={false}
                              useCheckboxes
                              checkboxHandler={handleSelectCheckboxPodcast}
                              customColumns={customColumnsPodcast}
                              displayProgressBar={false}
                              noActions
                              pseudoUrlFiltering
                              pseudoUrl={pseudoUrlPodcast}
                              updatePseudoUrl={setPseudoUrlPodcast}
                              version={matTableVersionPodcast}
                            />
                          </div>
                        )
                        : (<div className="pt-3" ><ProgressObj /></div>)
                      }
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ImportCreatorsModal
        title="Import Channel List"
        modal={modalVisible}
        setModalVisible={setModalVisible}
        importRecords={importRecords}
        loading={loadingUploadChannels}
        uploadResults={uploadResults}
        dispatch={dispatch}
        watchlists={watchlists}
        defaultWatchlist={currentWatchlist}
      />
      {selectedCreatorId && (
        <CreatorDetailsModal
          isOpen={!!selectedCreatorId}
          creatorId={selectedCreatorId}
          toggleModal={() => setSelectedCreatorId(null)}
        />
      )}
      <Modal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(!deleteModalOpen)}
        modalClassName="ltr-support"
        className="modal-dialog"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => setDeleteModalOpen(false)} />
          <h4 className="text-modal modal__title delete-modal">Delete Accounts</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12}>
              <p className="pb-3">
                Please confirm that you wish to delete selected accounts.
              </p>
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <ButtonWithSpinner
            type="button"
            color="danger"
            onClick={deleteSelected}
            loading={deleteInProgress}
          >
            <span>Delete</span>
          </ButtonWithSpinner>{' '}
          <Button color="secondary" onClick={() => setDeleteModalOpen(!deleteModalOpen)}>Cancel</Button>
        </ButtonToolbar>
      </Modal>
      <DeleteModalDialog
        isOpen={deleteModalVisible}
        id={channelIdToDelete}
        toggleModal={() => setDeleteModalVisible(false)}
        onDeleteRecordFunc={reloadGrid}
      />
      <DetailsModalDialog
        isOpen={detailsModalVisible}
        toggleModal={() => setDetailsModalVisible(false)}
        record={detailsRecord}
        onUpdateRecordFunc={reloadGrid}
      />
    </Container>
  );
};
ResearchCreatorAccountList.propTypes = {
  addNotice: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  loadingUploadChannels: PropTypes.bool.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  creatorsGridCreated: PropTypes.instanceOf(Date).isRequired,
  loadingRefetchData: PropTypes.bool.isRequired,
  uploadResults: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingFormulas: PropTypes.bool.isRequired,
  formulas: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  formulaTokens: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  loadingWatchlists: PropTypes.bool.isRequired,
  watchlists: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

const mapStateToProps = state => ({
  
  resources: state.resources,
  loadingUploadChannels: state.ResearchReducer.loading.uploadChannels,
  modalVisible: state.ResearchReducer.uploadChannelsDialogVisible,
  creatorsGridCreated: state.ResearchReducer.creatorsGridCreated,
  loadingRefetchData: state.ResearchReducer.loading.refetchData,
  uploadResults: state.ResearchReducer.uploadResults,
  loadingFormulas: state.ResearchReducer.loading.formulas,
  formulas: state.ResearchReducer.formulas,
  formulaTokens: state.ResearchReducer.formulaTokens,
  loadingWatchlists: state.ResearchReducer.loading.watchlists,
  watchlists: state.ResearchReducer.watchlists,
});

export default connect(mapStateToProps)(withNotice(ResearchCreatorAccountList));
