import React, { useState, useEffect } from 'react';
import { Modal, ButtonToolbar, Button, Label, FormGroup, Input, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTags from 'react-tag-autocomplete';
import { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import momentTz from 'moment-timezone';
import RadioButton from '../../../../../../../../shared/components/RadioButton';
import csvHelper from '../../../../../../../../shared/helpers/CSVHelper';
import DropZoneField from '../../../../../../../../shared/components/form/DropZone';
// import { getConstant } from '../../../../../../../shared/helpers/WVConstants';
import WVFormatter from '../../../../../../../../shared/helpers/WVFormatter';
import Alert from '../../../../../../../../shared/easydev2/shared/components/Alert';

const UploadProspectsModal = ({
  modal,
  title,
  setModalVisible,
  importRecords,
  loading,
  uploadResults,
  errorMessage,
  analysts,
  currentAdminUser,
}) => {
  // const maxLimitSave = Number(getConstant('prospect', 'uploadProspectsLimit') || 0);
  const [uploading, setUploading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [logs, setLogs] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [queue] = useState(true);
  const [uploadVariant, setUploadVariant] = useState('now');
  const [name, setName] = useState(`Started on ${WVFormatter.formatToEST(momentTz()).format('M/D/YY h:mma')} by ${currentAdminUser.firstName} ${currentAdminUser.lastName}`);
  const [description, setDescription] = useState('');
  const [selectedBrand, setSelectedBrand] = useState({
    value: null,
    label: '-- Not Assigned --',
  });
  const [analyst, setAnalyst] = useState({
    value: null,
    label: '-- Not Assigned --',
  });
  const reactTags = React.createRef();

  const handleChangeFile = (files) => {
    setUploading(true);
    let nameNew = name;
    if (nameNew === '') {
      nameNew = `Started on ${WVFormatter.formatToEST(momentTz()).format('M/D/YY h:mma')} by ${currentAdminUser.firstName} ${currentAdminUser.lastName}`;
    }
    const prospectGroupsData = {
      name: nameNew,
      description,
    };
    console.log(selectedDate);
    importRecords(files, tags, selectedBrand.value, analyst.value, true, identity, prospectGroupsData, (uploadVariant === 'schedule' ? selectedDate : null));
  };

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setTags(tagsNew);
  };

  const fetchTags = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospects-tags?suggestionsOnly=true',
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const loadBrandsOptions = async (inputValue) => {
    const response = await axios({
      method: 'get',
      url: `/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`,
    });
    if (response.data.success) {
      const res = [
        { value: null, label: '-- Not Assigned --' },
      ];
      response.data.brands.forEach((record) => {
        res.push({
          value: record._id,
          label: record.companyName,
          record,
        });
        // if (selectedBrand.value === null && record.companyName.toLowerCase() === 'betterhelp') {
        //   setSelectedBrand({
        //     value: record._id,
        //     label: record.companyName,
        //   });
        // }
      });
      return res;
    }
    return [];
  };

  const handleChangeBrand = (selected) => {
    setSelectedBrand({
      value: selected.value,
      label: selected.label,
    });
  };

  const handleChangeAnalyst = (selected) => {
    setAnalyst(selected);
  };

  const downloadReport = () => {
    const data = [];
    data.push(['First Name', 'Last Name', 'Email', 'Media Url', 'Upload Status']);
    uploadResults.successfullUploads.forEach((record) => {
      data.push([record.firstname, record.lastname, record.email, record.url, 'SUCCESS']);
    });
    uploadResults.successfullPartially.forEach((record) => {
      data.push([record.firstname, record.lastname, record.email, record.url, 'SUCCESS PARTIALLY']);
    });
    uploadResults.duplicatesUploads.forEach((record) => {
      data.push([record.firstname, record.lastname, record.email, record.url, 'DUPLICATE']);
    });
    uploadResults.errorUploads.forEach((record) => {
      data.push([record.firstname, record.lastname, record.email, record.url, 'ERROR']);
    });
    return csvHelper.generateCSV('upload-results.csv', data);
  };

  useEffect(() => {
    if (modal) {
      fetchTags();
      setName(`Started on ${WVFormatter.formatToEST(momentTz()).format('M/D/YY h:mma')} by ${currentAdminUser.firstName} ${currentAdminUser.lastName}`);
      setDescription('');
    }
    setTags([]);
    setLogs({});
    setAnalyst({ value: null, label: '-- Not Assigned --' });
    setSelectedBrand({ value: null, label: '-- Not Assigned --' });
    // setQueue(false);
  }, [modal]);

  useEffect(() => {
    if (errorMessage) setUploading(false);
  }, [errorMessage]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => { setModalVisible(false); setUploading(false); }}
      modalClassName="ltr-support"
    >
      <ModalHeader style={{ padding: 0, paddingBottom: '28px', borderBottom: 'none' }} toggle={() => { setModalVisible(false); setUploading(false); }} tag="h4">
        <b>{title}</b>
      </ModalHeader>
      {!loading && !uploading && (
        <div className="modal__body finance-form">
          {errorMessage && errorMessage !== '' && (
            <Alert color="warning" className="w-100">
              <p className="py-2 text-dark">
                {errorMessage}
              </p>
            </Alert>
          )}
          {/* <div className="theme-light radio-in-prospects">
            Would you like to queue this list of Prospects for upload?&nbsp;&nbsp;&nbsp;
            <RadioButton
              name="queue"
              label="&nbsp;Yes"
              value={queue === true ? 'Yes' : 'No'}
              radioValue="Yes"
              onChange={(e) => { setQueue(e === 'Yes'); }}
            />
            <RadioButton
              name="queue"
              label="&nbsp;No"
              value={queue === true ? 'Yes' : 'No'}
              radioValue="No"
              onChange={(e) => { setQueue(e === 'Yes'); }}
            />
          </div>
          <Alert color="info" className="w-100">
            <p className="py-2">
              The Upload process will be queued if the number of results being added is larger than {maxLimitSave}.
              You will be notified when the upload process has completed.
            </p>
          </Alert> */}
          <div className="theme-light">
            Schedule Upload Time
            <div className="mb-2">
              <RadioButton
                name="schedule"
                label="&nbsp;Upload Immediately"
                value={uploadVariant}
                radioValue="now"
                className=" mr-2"
                onChange={(e) => { setUploadVariant(e); }}
              />
              <RadioButton
                name="schedule"
                label={(
                  <>Schedule Upload {uploadVariant === 'schedule' ? (
                    <div style={{
                      maxWidth: '200px', display: 'inline-block', maxHeight: '26px', marginTop: '-5px',
                    }}
                    ><DatePicker
                      selected={selectedDate}
                      timeFormat="HH:mm"
                      showTimeInput
                      selectsStart
                      minDate={new Date()}
                      onChange={date => setSelectedDate(date)}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      // placeholderText={'Select Datetime'}
                      className="form-control form-control-sm"
                      dropDownMode="select"
                    />
                    </div>) : null}
                  </>)}
                value={uploadVariant}
                radioValue="schedule"
                className=" mr-2"
                onChange={(e) => { setUploadVariant(e); }}
              />
            </div>
          </div>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              id="name_prospect_group"
              name="name_prospect_group"
              value={name}
              onChange={(e) => { setName(e.target.value); }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Input
              type="textarea"
              id="description_prospect_group"
              name="description_prospect_group"
              value={description}
              onChange={(e) => { setDescription(e.target.value); }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Brand</Label>
            <SelectAsync
              cacheOptions
              id="brandSelectBox"
              value={selectedBrand}
              defaultOptions
              loadOptions={loadBrandsOptions}
              onChange={handleChangeBrand}
              placeholder=""
            />
          </FormGroup>
          <FormGroup>
            <Label>Analyst</Label>
            <Select
              value={analyst}
              id="analystSelectBox"
              options={analysts}
              onChange={handleChangeAnalyst}
              placeholder=""
              menuPlacement="auto"
            />
            <div className="small">Optional (can be specified later)</div>
          </FormGroup>
          <FormGroup>
            <Label>Tags</Label>
            <ReactTags
              ref={reactTags}
              tags={tags}
              suggestions={tagsSuggestions}
              onDelete={onDeleteTag}
              onAddition={onAdditionTag}
              delimiters={['Enter', ',']}
              allowNew
              placeholderText="Tags"
              addOnBlur
            />
            <div className="small">Optional (can be specified later)</div>
          </FormGroup>
          <DropZoneField
            input={{
              id: 'uploadfile',
              name: 'uploadfile',
              value: loading ? [{ preview: '/images/loading.gif' }] : '',
              onChange: handleChangeFile,
              accept: '.csv, application/vnd.ms-excel, text/csv',
            }}
            customHeight
            accept=".csv, application/vnd.ms-excel, text/csv"
          />
        </div>
      )}
      {uploading && uploadVariant !== 'schedule' && (
        <div className="modal__body finance-form">
          { loading && <h3 className="mb-2">Uploading...</h3> }
          {errorMessage && errorMessage !== '' ? (
            <Alert color="warning" className="w-100">
              <p className="py-2 text-dark">
                {errorMessage}
              </p>
            </Alert>
          ) : null}
          {!queue ? (
            <div>
              <h4>Prospects successfully processed: {logs?.totalUploaded || 0}</h4>
              <h4>Prospects successfully uploaded: {logs?.fullUploaded || 0}</h4>
              <h4>Prospects partially uploaded (not found in Tubular): {logs?.partiallyUploaded || 0}</h4>
              <h4>Prospects not uploaded (duplicates found): {logs?.duplicatesUploads || 0}</h4>
              <h4>Prospects not uploaded (errors occurred): {logs?.errorUploads?.length || 0}</h4>
              {!loading && (<div className="text-right"><Button className="btn btn-sm" color="primary" onClick={downloadReport}>Download Results</Button></div>)}
            </div>
          ) : null}
          {queue && !loading ? (
            <Alert color="light" className="w-100">
              <p className="py-2" style={{ color: '#212529' }}>
                Your file is now scheduled to be uploaded and you can monitor the progress in
                the <a href="/research/upload-queue?customFilters.uploadProspects=true" target="_blank">Prospect Uploads Dashboard</a>.
                You will be notified when the upload process has been completed.
              </p>
            </Alert>
          ) : null}
        </div>
      )}
      {uploading && uploadVariant === 'schedule' && (
        <div className="modal__body finance-form">
          <Alert color="light" className="w-100">
            <p className="py-2" style={{ color: '#212529' }}>
              The upload has been successfully schedule to run at {moment(selectedDate).format('M/D/YY')} and {moment(selectedDate).format('h:mma')}
            </p>
          </Alert>
        </div>
      )}
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => { setModalVisible(false); setUploading(false); }}>Close</Button>
        {uploading && uploadVariant === 'schedule' && (
          <a href="/research/upload-queue?customFilters.uploadProspects=true" target="_blank" className="ml-2">
            <Button style={{ marginBottom: 0, width: '166px' }} className="modal_cancel btn-sm" color="primary">View Uploads</Button>
          </a>
        )}
      </ButtonToolbar>
    </Modal>
  );
};

UploadProspectsModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  importRecords: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  uploadResults: PropTypes.objectOf(PropTypes.any).isRequired,
  analysts: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentAdminUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

UploadProspectsModal.defaultProps = {

};

export default UploadProspectsModal;
