import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
  Badge,
} from 'reactstrap';
import { unflatten } from 'flat';
import { connect } from 'react-redux';
import { axios } from 'ApiClient';
// import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// import { axios } from 'ApiClient';
import Table from './custom/Table';
import Actions from './custom/Actions';
import AddCreatorsModal from './modals/AddCreatorsModal';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../../App/store/with-notice';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import DeleteCpmCreatorFromOrder from './modals/DeleteCpmCreatorFromInvoice';
import RefreshStatisticsOptionsModal from '../../CpmProspects/components/modals/RefreshStatisticsOptionsModal';
import UploadInvoiceModal from '../../CpmInvoice/components/custom/Modal/UploadInvoiceModal';
import PublishInvoice from './modals/PublishInvoice';
import { showModalById } from '../../../../../../redux/reducers/TableReducer';
import { ErrorMessageBoxAlert } from '../../../../../../shared/components/components/ui/error404';
import FetchContentReleaseDate from './modals/FetchContentReleaseDate';
import PublishLookerInvoice from './modals/PublishLookerInvoice';
import PublishLookerProspect from '../../CpmProspects/components/modals/PublishLookerProspect';

const CPMProspectsListContainer = ({
  addNotice,
  dispatch,
  history,
  action,
  resource,
  invoiceId,
}) => {
  const filters = parseUrl();
  const [version, setVersion] = useState((new Date()).toString());
  // const [approvalStatusFilters, setApprovalStatusFilters] = useState(getApprovalStatusInitial());
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [filtersPrivacyStatus, setFiltersPrivacyStatus] = useState({
    na: true,
    unlisted: true,
    public: true,
    private: true,
  });
  const [invoice, setInvoice] = useState({});
  const [loading, setLoading] = useState(false);
  const [possibleCreators, setPossibleCreators] = useState([]);
  const [startLoadCreators, setStartLoadCreators] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState(null);
  const recordsRef = useRef([]);
  const [dealStageFilters, setDealStageFilters] = useState([]);
  const [dealstagesOptions, setDealstagesOptions] = useState([]);
  const [mainPipelineStages, setMainPipelineStages] = useState({});

  const getInvoice = async () => {
    try {
      setLoading(true);
      const data = await axios.get(`/api/portal/prospects/get-cpm-invoice?id=${invoiceId}`);
      setLoading(false);
      console.log(data.data.record);
      if (data.data.success) {
        setInvoice(unflatten(data.data.record));
      } else {
        setError(data.data.error);
      }
    } catch (e) {
      addNotice({
        message: e.message || 'Unable to get Invoice',
        type: 'error',
      });
      console.log(e);
      setLoading(false);
    }
  };

  const fetchPossibleCreators = () => {
    // console.log('Fetch creators');
    axios.get(`/api/portal/prospects/get-free-cpm-creators-for-invoice?brand=${invoice.params.brand}`).then((response) => {
      if (response.data.success) {
        setPossibleCreators(response.data.cpmCreators);
      }
    });
    setStartLoadCreators(false);
  };

  useEffect(() => {
    // get main pipeline deals
    const allDealPipeLines = getConstant('deal', 'pipelines', []);
    const mainPipeline = allDealPipeLines.find(a => a.label === 'Main Pipeline');
    setMainPipelineStages(mainPipeline.stages);
  }, []);

  useEffect(() => {
    if (Object.keys(mainPipelineStages).length) {
      const options = [];
      Object.keys(mainPipelineStages).forEach(key => options.push({ value: key, label: mainPipelineStages[key] }));
      setDealstagesOptions(options);
    }
  }, [mainPipelineStages]);

  useEffect(() => getInvoice(), [invoiceId]);
  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }
    search.delete('filters.privacyStatus');
    if (filtersPrivacyStatus) {
      Object.keys(filtersPrivacyStatus).forEach((key) => {
        if (filtersPrivacyStatus[key]) {
          search.append('filters.privacyStatus', key);
        }
      });
    }
    search.delete('filters.dealStages');
    if (dealStageFilters && dealStageFilters.length > 0) {
      for (let i = 0; i < dealStageFilters.length; i += 1) {
        search.append('filters.dealStages', dealStageFilters[i].value);
      }
    }

    return search;
  };
  useEffect(() => {
    if (startLoadCreators) {
      fetchPossibleCreators();
    }
  }, [startLoadCreators]);

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const firstUpdate = useRef(true);
  const setUrl = () => {
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
    }
    // search.set('filters.allRequiredDataIsSet', 'true');
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  // const setUrlDebounced = useAsyncDebounce(() => {
  //   setUrl();
  // }, 800);

  const downloadCsv = () => {
    const search = getUrlSearch();
    search.append('filters.cpmInvoice', invoiceId);
    goToPage(`/api/portal/prospects/get-invoice-prospect-csv?${search.toString()}`);
  };

  useEffect(() => {
    setUrl();
    // setUrlDebounced();
  }, [
    searchValue,
    filtersPrivacyStatus,
    dealStageFilters,
  ]);

  if (error) {
    return (<ErrorMessageBoxAlert title="Invoice" actionName="list" >{error}</ErrorMessageBoxAlert>);
  }
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Invoice', path: '/research/cpm-invoice' },
          { title: `Invoice${invoice && invoice.params ? ` - ${invoice.params.invoiceNumber}` : ' '}`, path: null },
        ]}
        isBackButton
        backButtonUrl="/research/cpm-invoice"
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3 className="d-inline-block mr-3">Invoice {invoice && invoice.params ? ` - ${invoice.params.invoiceNumber}` : ''}</h3>
                {invoice && invoice.params ? <Badge color="success" style={{ fontSize: '20px' }}>{invoice?.params?.status?.status}</Badge> : null }
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                loading={loading}
                onSearchChange={onSearchChange}
                getUrlSearch={getUrlSearch}
                searchValue={searchValue}
                // setVersion={setVersion}
                downloadCsv={downloadCsv}
                invoice={invoice}
                getInvoice={getInvoice}
                setStartLoadCreators={setStartLoadCreators}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                filtersPrivacyStatus={filtersPrivacyStatus}
                setFiltersPrivacyStatus={setFiltersPrivacyStatus}
                dealStageFilters={dealStageFilters}
                setDealStageFilters={setDealStageFilters}
                dealstagesOptions={dealstagesOptions}
              />
              <Row style={{ marginTop: '-10px' }}>
                <Table
                  action={action}
                  resource={resource}
                  // dispatch={dispatch}
                  // addNotice={addNotice}
                  version={version}
                  // setVersion={setVersion}
                  recordsRef={recordsRef}
                  invoiceId={invoiceId}
                />
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <DeleteCpmCreatorFromOrder updateTable={() => setVersion((new Date()).toString())} />
      <RefreshStatisticsOptionsModal
        updateTable={() => setVersion((new Date()).toString())}
      />
      <UploadInvoiceModal setVersion={getInvoice} />
      <AddCreatorsModal
        setVersion={setVersion}
        possibleCreators={possibleCreators}
        dates={{ startDate, endDate }}
      />
      <FetchContentReleaseDate
        updateTable={() => setVersion((new Date()).toString())}
      />
      <PublishLookerInvoice
        updateTable={(file) => {
          getInvoice();
          dispatch(showModalById('SuccessModal', {
            title: 'Looker Update Data Published Successfully',
            message: (
              <span>Invoice <strong>{invoice.params?.invoiceNumber || ''}</strong> Looker Update Data was successfully published to google sheet file <br />
                <a href={file.spreadsheetUrl} target="_blank" rel="noreferrer" className="py-2 d-inline-block">Looker Update Sheet</a>
              </span>),
          }));
        }}
      />
      <PublishInvoice
        updateTable={(file) => {
          getInvoice();
          dispatch(showModalById('SuccessModal', {
            title: 'Invoice Published Successfully',
            message: (
              <span>Invoice <strong>{invoice.params?.invoiceNumber || ''}</strong> was successfully published to google sheet file <strong>{file.name || ''}</strong> <br />
                <a href={file.webViewLink} target="_blank" rel="noreferrer" className="py-2 d-inline-block">View Invoice</a>
              </span>),
          }));
        }}
      />
      <PublishLookerProspect />
    </Container>
  );
};

CPMProspectsListContainer.propTypes = {
  addNotice: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  invoiceId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withNotice(connect()(withRouter(CPMProspectsListContainer)));
