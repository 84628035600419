/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ApiClient, { axios } from 'ApiClient';
import moment from 'moment';
import {
  Container,
  Row,
  Col,
  Modal,
  ButtonToolbar,
  Button, Card, CardBody, CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import { withStyles } from '@material-ui/core/styles';
import MatTableList from '../../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../../shared/components/BreadCrumbs';
import UserPropertyType from '../../components/custom';
import ProgressBar from '../../../../../../shared/helpers/ProgressBarWithObject';
import { currentTimezoneAggr } from '../../../../../../shared/helpers/Dates';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import Actions from './Actions';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';
import NewInvoice from './NewInvoiceComponent';

const getPaymentStatusInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    paidinfull: true,
    partiallypaid: true,
    unpaid: true,
  };
  if (filters?.paymentStatus) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters.paymentStatus.includes(key);
    });
  }
  return initialFilters;
};

const columnTransform = {
  payouts: {
    title: 'Error',
    valueFunc: ({ error }) => error,
  },
};

const downloadCsv = [
  {
    label: 'Error',
    func: ({ error }) => error,
  },
];
const links = [
  { title: 'Home', path: '/' },
  { title: 'Finance', path: false },
  { title: 'Receivables', path: false },
  { title: 'Invoices', path: false },
];

const ResourceAction = ({
  history,
  resource,
  setTag,
  action,
  classes,
}) => {
  const filters = parseUrl();
  const [searchString, setSearchString] = useState('');
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [version, setVersion] = useState((new Date()).toString());
  const [paymentStatusFilters, setPaymentStatusFilters] = useState(getPaymentStatusInitial());
  const [showNewInvoice, setShowNewInvoice] = useState(false);
  const [apSelected, setApSelected] = useState(filters && filters['payableBills.accountsPayable'] ? { value: filters['payableBills.accountsPayable'] } : {});
  const [createDateFrom, setCreateDateFrom] = useState(filters && filters['createdAt~~from'] && new Date(filters['createdAt~~from']));
  const [createDateTo, setCreateDateTo] = useState(filters && filters['createdAt~~to'] && new Date(filters['createdAt~~to']));
  const [showFetchModalLoading, setShowFetchModalLoading] = useState(false);
  const [showFetchModalLoadingButton, setShowFetchModalLoadingButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const uniqueId = React.useMemo(() => moment().toISOString(), []);

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }
    if (createDateFrom) {
      search.set('filters.createdAt~~from', createDateFrom.toISOString());
    } else {
      search.delete('filters.createdAt~~from');
    }
    if (createDateTo) {
      search.set('filters.createdAt~~to', createDateTo.toISOString());
    } else {
      search.delete('filters.createdAt~~to');
    }
    if (apSelected?.value) {
      search.set('filters.payableBills.accountsPayable', apSelected.value);
    } else {
      search.delete('filters.payableBills.accountsPayable');
    }
    if (paymentStatusFilters && Object.keys(paymentStatusFilters).length > 0) {
      search.delete('filters.paymentStatus');
      Object.keys(paymentStatusFilters).forEach((key) => {
        if (!paymentStatusFilters[key]) return false;
        search.append('filters.paymentStatus', key);
        return true;
      });
    }
    setSearchString(search.toString());
    return search;
  };

  const setUrl = () => {
    const search = getUrlSearch();
    search.set('page', '1');
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 500);

  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
    paymentStatusFilters,
    apSelected,
    createDateFrom,
    createDateTo,
  ]);

  const fetchBills = async () => {
    await axios({
      method: 'post',
      url: '/api/portal/bill/post-fetch-all-bills',
      data: { type: 'invoice', identity: uniqueId },
    });
    setShowFetchModalLoadingButton(true);
  };

  const loadAPOptions = async (inputValue) => {
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'AccountsPayable',
      query: inputValue,
    });
    const res = [];
    const _opts = [];
    const aMonths = [
      'December',
      'November',
      'October',
      'September',
      'August',
      'July',
      'June',
      'May',
      'April',
      'March',
      'February',
      'January',
    ];
    let month;
    let year;
    records.forEach((record) => {
      res.push({
        value: record.id,
        label: record.params.name,
        params: record.params,
        record,
      });
      if ((typeof record.params.monthPeriod !== 'undefined') && (typeof record.params.startDate !== 'undefined')) {
        month = Number(record.params.monthPeriod);
        year = record.params.startDate ? record.params.startDate.substr(0, 4) : null;
        if (month && year) {
          month = 12 - month;
          if (typeof _opts[year] === 'undefined') _opts[year] = [];
          if (typeof _opts[year][month] === 'undefined') _opts[year][month] = [];
          _opts[year][month].push({
            value: record.id,
            label: (record.params.name.substr(0, 3).toLowerCase() === aMonths[month].substr(0, 3).toLowerCase()) ? aMonths[month] : record.params.name,
            params: record.params,
            record,
          });
        }
      }
    });
    if (!apSelected.label && apSelected.value && res.find(r => r.value === apSelected.value)) {
      setApSelected(res.find(r => r.value === apSelected.value));
    }
    const opts = [];
    let _i = 0;
    for (let i = _opts.length - 1; i >= 2020; i -= 1) {
      if ((typeof _opts[i] !== 'undefined') && _opts[i]) {
        opts[_i] = { label: i.toString(), options: [] };
        for (let j = 0; j < 12; j += 1) {
          if ((typeof _opts[i][j] !== 'undefined') && _opts[i][j]) {
            for (let k = 0; k < _opts[i][j].length; k += 1) {
              opts[_i].options.push(_opts[i][j][k]);
            }
          }
        }
        _i += 1;
      }
    }
    return opts;
  };

  const closeDialog = (isUpdate) => {
    setShowNewInvoice(false);
    if (isUpdate) {
      setVersion((new Date()).toString());
    }
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs links={links} isBackButton />
        </Col>
      </Row>
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Invoices</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Actions
            paymentStatusFilters={paymentStatusFilters}
            setPaymentStatusFilters={setPaymentStatusFilters}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            createDateFrom={createDateFrom}
            setCreateDateFrom={setCreateDateFrom}
            createDateTo={createDateTo}
            setCreateDateTo={setCreateDateTo}
            apSelected={apSelected}
            setApSelected={setApSelected}
            loadAPOptions={loadAPOptions}
            setShowFetchModalLoading={setShowFetchModalLoading}
            setShowNewInvoice={setShowNewInvoice}
            setErrors={setErrors}
            fetchBills={fetchBills}
            searchString={searchString}
            classes={classes}
          />
          <div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
            <MatTableList
              showTitle={false}
              action={action}
              resource={resource}
              setTag={setTag}
              date={new Date()}
              version={version}
              UserPropertyType={UserPropertyType}
              csvDownload={{ link: '/resources/Invoice/download-all-csv' }}
              meta={{ closeFunction: () => setVersion((new Date()).toString()) }}
              isResizable={false}
              v={2}
              sortBy="createdAt"
              direction="desc"
              columnsSettings={{
                'influencerObject.fullName': {
                  width: 250,
                },
                'influencerObject.email': {
                  width: 300,
                },
                createdAt: {
                  width: 180,
                  Header: () => `Create Date (${currentTimezoneAggr()})`,
                },
                invoiceNumber: {
                  width: 180,
                },
                paymentStatus: {
                  width: 180,
                },
                amount: {
                  width: 150,
                },
                amountDue: {
                  width: 150,
                },
                'dealObject.dealstage': {
                  width: 110,
                },
                actions: {
                  width: 50,
                },
              }}
            />
          </div>
        </CardBody>
      </Card>
      {showNewInvoice ? (
        <NewInvoice
          modalTitle="Add New Invoice"
          modal={showNewInvoice}
          closeDialog={closeDialog}
        />) : null}
      <Modal
        isOpen={showFetchModalLoading}
        modalClassName="ltr-support"
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Progress Dialog</h4>
        </div>
        <div className="modal__body ltr-support theme-light">
          <ProgressBar
            afterFunction={() => setShowFetchModalLoadingButton(true)}
            topic="reFetchAllBils"
            receiveResults={res =>
              setErrors(() => {
                // console.log(res);
                // console.log(showFetchModalLoadingButton);
                if (res.error) {
                  errors.push({ error: res.error });
                }
                return { errors };
              })
            }
          />
          {errors ? (
            <MatTableForRedux
              dataArray={errors}
              selectable={false}
              columns={columnTransform}
              version={(new Date()).toString()}
              rowsPerPage={5}
              csvDownload={{
                fileName: 'InvoiceErrors.csv',
                csvArrayColumns: downloadCsv,
              }}
            />
          ) : null}
        </div>
        {showFetchModalLoadingButton && (
          <ButtonToolbar className="modal__footer">
            <Button
              className="modal_cancel btn-sm"
              onClick={() => {
                setShowFetchModalLoadingButton(false);
                setShowFetchModalLoading(false);
              }}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        )}
      </Modal>
    </Container>
  );
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default withStyles({
  root: {
    width: '100%',
    minWidth: '200px',
    paddingRight: '30px',
    position: 'relative',
    marginBottom: '10px',
  },
  close: {
    position: 'absolute',
    bottom: '1px',
    right: '0px',

  },
})(connect(mapStateToProps)(ResourceAction));
