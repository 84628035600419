import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import _ from 'lodash';
import { ProgramOptions } from '../../../../../../../shared/helpers/models/Prospect';
import { getSelectedOption } from '../../../../../../../shared/helpers/Form';

const ProgramCell = React.memo(({
  record, afterSaveFunc, updateRecordFunction, miningMode,
}) => {
  const key = 'program';

  const rowData = record.params;
  const val = getSelectedOption(ProgramOptions, rowData ? rowData[key] : null);
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    setValue(val);
    setDisabled(false);
  }, [record.id, rowData[key]]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option);
    try {
      const data = await axios.post('/api/portal/prospects/post-update-program', {
        prospectIds: [record.id],
        program: option.value,
      });
      if (data?.data?.prospectPopulated && updateRecordFunction) {
        updateRecordFunction(data?.data?.prospectPopulated);
      } else {
        afterSaveFunc();
      }
    } catch (e) {
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };

  return (
    <div style={{ minWidth: '120px', maxWidth: '150px' }} >
      <Select
        value={value}
        className="programSelectionBox"
        options={ProgramOptions}
        onChange={handleChange}
        placeholder=""
        isDisabled={disabled || (miningMode && record.params?.inBlackList)}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
      {error ? (<span className="text-danger">{error}</span>) : null}
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

ProgramCell.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  afterSaveFunc: PropTypes.func.isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
  miningMode: PropTypes.bool,
};

ProgramCell.defaultProps = {
  miningMode: false,
};

export default ProgramCell;
