import React, { useState, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
// import IconButton from '@material-ui/core/IconButton';
// import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import { uid } from 'react-uid';
import { Button, Modal, ButtonToolbar } from 'reactstrap';
import { getConstant } from '../../../shared/helpers/WVConstants';


const GenerationInfo = ({
  startDate, endDate, estimatePrevious, includePrevious, selectedStatuses, isByDate,
}) => {
  const [showReport, setShowReport] = useState(false);
  const mapStatusArray = getConstant('AP', 'status', {});
  const listMonths = useMemo(() => {
    const list = [];
    if (!isByDate) {
      let i = 0;
      let cMonth;
      const listStatus = selectedStatuses.map(key => mapStatusArray[key]);
      do {
        cMonth = moment(startDate).add(i, 'month');
        if (cMonth >= moment().startOf('month')) {
          list.push((<div key={uid(i)}>{cMonth.format('MMMM YYYY')} uses the HasOffers real-time conversions data to estimate amounts</div>));
        } else if (cMonth < moment().startOf('month') && cMonth >= moment().subtract(1, 'month').startOf('month')) {
          list.push((
            <div key={uid(i)}>{cMonth.format('MMMM YYYY')}{' '}
              {!includePrevious && !estimatePrevious ? `uses the ${cMonth.format('MMMM YYYY')} AP Report (Complete payables)` : ''}
              {includePrevious ? `uses the ${cMonth.format('MMMM YYYY')} AP Report (${listStatus.join(', ')} payables)` : ''}
              {estimatePrevious ? ' uses the HasOffers real-time conversions data to estimate amounts' : ''}
            </div>));
        } else list.push((<div key={uid(i)}>{cMonth.format('MMMM YYYY') } uses the {cMonth.format('MMMM YYYY')} AP Report (Complete payables)</div>));
        i += 1;
      } while (cMonth < moment(endDate).subtract(1, 'month'));
      return list;
    }
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    const startMomentMonth = moment.tz(startDate, 'America/New_York').startOf('month');
    // const endMonth = moment(startMoment).endOf('month');
    let payablesStartDate = null;
    let payablesEndDate = null;
    let additionStartDate = null;
    let additionsEndDate = null;
    let additionDoubleStartDate = null;
    let additionDoubleEndDate = null;
    let usePayables = 0;
    let useAddition = 0;
    let useDoubleAddition = 0;
    if (startMoment.diff(startMomentMonth, 'seconds') === 0) {
      payablesStartDate = startMoment;
      additionStartDate = startMoment;
    } else {
      additionStartDate = startMoment;
      payablesStartDate = moment(startMoment).add(1, 'month').startOf('month');
      useAddition = 1;
    }
    if (payablesStartDate && moment(endMoment).add(1, 'day').diff(moment(payablesStartDate), 'month') > 0) {
      usePayables = 1;
      payablesEndDate = moment(endMoment).add(1, 'day').subtract(1, 'month').endOf('month');
      additionsEndDate = moment(payablesStartDate).subtract(1, 'month').endOf('month');
    } else {
      useAddition = 1;
      additionsEndDate = moment(endMoment);
    }
    if (usePayables && moment.tz('America/New_York').startOf('month') < moment(endMoment)) { // to include time after start of current month
      payablesEndDate = moment(endMoment);
      usePayables = 1;
    }
    if (usePayables && moment(payablesEndDate) < moment(endMoment)) { // to include time after start of current month
      // payablesEndDate = moment(endMoment);
      // usePayables = 1;
      console.log('double eddition');
      useDoubleAddition = 1;
      additionDoubleStartDate = moment(endMoment).startOf('month');
      additionDoubleEndDate = moment(endMoment);
      console.log([additionDoubleStartDate, additionDoubleEndDate]);
    }
    console.log('addition', [additionStartDate, additionsEndDate, useAddition]);
    console.log('payables', [payablesStartDate, payablesEndDate, usePayables]);
    if (useAddition) {
      list.push((<div>{additionStartDate.format('MMMM, DD YYYY')} - {additionsEndDate.format('MMMM, DD YYYY')} uses the HasOffers real-time conversions data to estimate amounts</div>));
    }
    if (usePayables) {
      let i = 0;
      let cMonth;
      const listStatus = selectedStatuses.map(key => mapStatusArray[key]);
      // list.push((<div>{payablesStartDate.format('MMMM, DD YYYY')} - {payablesEndDate.format('MMMM, DD YYYY')} uses the HasOffers real-time conversions data to estimate amounts</div>));
      do {
        cMonth = moment(payablesStartDate).add(i, 'month');
        if (cMonth >= moment().startOf('month')) {
          list.push((<div key={uid(i)}>{cMonth.format('MMMM YYYY')} uses the HasOffers real-time conversions data to estimate amounts</div>));
        } else if (cMonth < moment().startOf('month') && cMonth >= moment().subtract(1, 'month').startOf('month')) {
          list.push((
            <div key={uid(i)}>{cMonth.format('MMMM YYYY')}{' '}
              {!includePrevious && !estimatePrevious ? `uses the ${cMonth.format('MMMM YYYY')} AP Report (Complete payables)` : ''}
              {includePrevious ? `uses the ${cMonth.format('MMMM YYYY')} AP Report (${listStatus.join(', ')} payables)` : ''}
              {estimatePrevious ? ' uses the HasOffers real-time conversions data to estimate amounts' : ''}
            </div>));
        } else list.push((<div key={uid(i)}>{cMonth.format('MMMM YYYY') } uses the {cMonth.format('MMMM YYYY')} AP Report (Complete payables)</div>));
        i += 1;
      } while (cMonth < moment(payablesEndDate).subtract(1, 'month'));
    }
    if (useDoubleAddition) {
      list.push((<div>{additionDoubleStartDate.format('MMMM, DD YYYY')} - {additionDoubleEndDate.format('MMMM, DD YYYY')} uses the HasOffers real-time conversions data to estimate amounts</div>));
    }

    return list;
  }, [startDate, endDate, estimatePrevious, includePrevious, selectedStatuses]);
  return (
    <div className="float-right mt-1">
      <Tooltip title="View details about how this report is generated">
        <IconButton
          size="small"
          onClick={() => setShowReport(true)}
          className="material-table__toolbar-button"
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </IconButton>
      </Tooltip>
      <Modal
        isOpen={showReport}
        toggle={() => setShowReport(false)}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Report Details</h4>
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body ltr-support">
          <div className="text-modal  modal__title">View details about how this report is generated</div>
          <div className="pb-3">
            <ul>{listMonths.map(list => (<li key={uid(list)}>{list}</li>))}</ul>
          </div>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button color="danger" onClick={() => setShowReport(false)}>Close</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};
GenerationInfo.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  estimatePrevious: PropTypes.bool.isRequired,
  includePrevious: PropTypes.bool.isRequired,
  isByDate: PropTypes.bool.isRequired,
  selectedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default GenerationInfo;
