import React from 'react';
import { Row, Col, Card, Label, Button } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { momentUTCToLocalDate } from '../../../shared/helpers/Dates';

class AccountFinanceDates extends React.Component {
  static getMinDate(month, yearPeriod) {
    return moment({ y: yearPeriod, M: Number(month) - 1, d: 1 });
  }
  static getMaxDate(month, yearPeriod) {
    return AccountFinanceDates.getMinDate(month, yearPeriod).endOf('month');
  }
  static propTypes = {
    model: PropTypes.objectOf(PropTypes.any).isRequired,
    loading: PropTypes.objectOf(PropTypes.any).isRequired,
    saveModelFunc: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      startDate: momentUTCToLocalDate(moment(props.model.params.startDate)).toDate(),
      endDate: momentUTCToLocalDate(moment(props.model.params.endDate)).toDate(),
      isChanged: false,
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.closeAndRevert = this.closeAndRevert.bind(this);
    this.saveModel = this.saveModel.bind(this);
  }
  componentDidUpdate(prevProps) {
    this.shouldUpdateDates(prevProps);
  }
  changeDate() {
    this.setState({ isChanged: true });
  }
  handleChangeStart = startDate => this.setState({ startDate }, () => this.changeDate());
  handleChangeEnd = endDate => this.setState({ endDate }, () => this.changeDate());
  shouldUpdateDates(prevProps) {
    const start = this.props.model.params.startDate;
    const end = this.props.model.params.endDate;
    if (start !== prevProps.model.params.startDate || end !== prevProps.model.params.endDate) {
      this.setState({
        startDate: momentUTCToLocalDate(moment(start)).toDate(),
        endDate: momentUTCToLocalDate(moment(end)).toDate(),
      });
    }
  }

  saveModel() {
    const { saveModelFunc } = this.props;
    const {
      startDate, endDate,
    } = this.state;
    saveModelFunc({
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
    });
    this.setState({ isChanged: false });
  }
  closeAndRevert() {
    const start = this.props.model.params.startDate;
    const end = this.props.model.params.endDate;
    this.setState({
      startDate: momentUTCToLocalDate(moment(start)).toDate(),
      endDate: momentUTCToLocalDate(moment(end)).toDate(),
      isChanged: false,
    });
  }
  render() {
    const {
      model,
      loading,
    } = this.props;

    const {
      startDate,
      endDate,
      isChanged,
    } = this.state;
    const yearPeriod = model.populated && model.populated.generalLedger
      ? model.populated.generalLedger.params.yearPeriod : null;
    const minDate = this.constructor.getMinDate(model.params.monthPeriod, yearPeriod);
    const maxDate = this.constructor.getMaxDate(model.params.monthPeriod, yearPeriod);
    const isDisabled = model.params.status && model.params.status !== 'new';

    return (
      <Card body className="card-bordered card mb-3 px-0 py-3" style={{ height: '100%' }}>
        <div className="date-picker date-picker--interval">
          <Row>
            <Col xs="5" className="d-label">
              <Label><b>Start Date (ET):</b></Label><br />
            </Col>
            <Col xs="7">
              <DatePicker
                onChange={this.handleChangeStart}
                selected={startDate}
                dateFormat="MM/dd/yyyy"
                placeholderText="From"
                dropDownMode="select"
                minDate={minDate.toDate()}
                maxDate={maxDate.toDate()}
                className="form-control"
                disabled={isDisabled || (loading.editingFields && loading.editingFields.indexOf('startDate') >= 0)}
              />
              <svg className="mdi-icon datepicker-svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                />
              </svg>
            </Col>
          </Row>
        </div>
        <div className="date-picker date-picker--interval">
          <Row>
            <Col xs="5" className="d-label">
              <Label><b>End Date (ET):</b></Label><br />
            </Col>
            <Col xs="7">
              <DatePicker
                onChange={this.handleChangeEnd}
                selected={endDate}
                dateFormat="MM/dd/yyyy"
                placeholderText="To"
                dropDownMode="select"
                className="form-control"
                minDate={minDate.toDate()}
                maxDate={maxDate.toDate()}
                disabled={isDisabled || loading.editingFields.indexOf('endDate') >= 0}
              />
              <svg className="mdi-icon datepicker-svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                />
              </svg>
            </Col>
          </Row>
        </div>
        {isChanged ? (
          <Card body className="card">
            <div className="text-right">
              <Button color="success" size="sm" onClick={this.saveModel}>Save</Button>{' '}
              <Button color="danger" size="sm" onClick={this.closeAndRevert}>Close</Button>
            </div>
          </Card>
        ) : ''}
      </Card>
    );
  }
}
export default AccountFinanceDates;

