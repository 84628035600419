import { useState, useEffect } from 'react';
import { axios } from 'ApiClient';

function useMediaContent(influencerId) {
  const [content, setContent] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios({
        method: 'get',
        url: `/api/portal/get-creator-content?influencerId=${influencerId}&filterCompliant=false`,
      }).then((response) => {
        if (response?.data?.success && response.data?.records) {
          setContent(response.data.records);
        }
      });
    }

    fetchData();
  }, [influencerId]);

  return content;
}

export default useMediaContent;
