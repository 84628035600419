import func from '@/shared/hooks';
import { unflatten } from 'flat';

export default ({ dealstages }) => [
  {
    initialState: '',
    func: ({ filters }) => filters?.fullSearchName || '',
    filterName: 'fullSearchName',
    funcToUrl: ({ search, item }) => {
      if (item) {
        search.set('filters.fullSearchName', item);
      } else {
        search.delete('filters.fullSearchName');
      }
      return search;
    },
  },
  {
    initialState: {
      tags: dealstages.filter(a => ['deal paid'].includes(a.label.toLowerCase()))
   },
    func: func.getFromUrlSelectfunction,
    filterName: 'dealstages',
    funcToUrl: func.getToUrlSelectfunction,
    options: dealstages,
  },
  {
    initialState: {
      Stripe: true,
      'Bill.com': true,
      'Not Connected': true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'paymentMethod',
  },

  {
    initialState: {
      enable: false,
      filterType: '=',
      amountFrom: 0,
      amountTo: 0,
    },
    func: ({ initialState: IS, filters: f, filterName }) => {
      const filters = unflatten(f);
      console.log({ initialState: IS, filters, filterName }, 'INITIAL');
      const init = { ...(IS || {}) };
      if (filters[filterName]?.enable) init.enable = String(filters[filterName].enable) === 'true'
      if (filters[filterName]?.filterType) init.filterType = String(filters[filterName].filterType)
      if (filters[filterName]?.amountFrom) init.amountFrom = Number(filters[filterName].amountFrom)
      if (filters[filterName]?.amountTo) init.amountTo = Number(filters[filterName].amountTo)
      console.log('return INIT', init)
      return init;
    },
    funcToUrl: ({ search, item, filterName }) => {
      console.log(item, "itemitemitemitemitem")
      search.delete(`filters.${filterName}.enable`);
      search.delete(`filters.${filterName}.filterType`);
      search.delete(`filters.${filterName}.amountFrom`);
      search.delete(`filters.${filterName}.amountTo`);
      
      if (item.enable) search.set(`filters.${filterName}.enable`, String(item.enable))
      if (item.filterType && item.enable) search.set(`filters.${filterName}.filterType`, String(item.filterType))
      if (typeof item.amountFrom !== 'undefined' && item.enable) search.set(`filters.${filterName}.amountFrom`, String(item.amountFrom))
      if (typeof item.amountTo !== 'undefined' && item.enable) search.set(`filters.${filterName}.amountTo`, String(item.amountTo))
      return search;
    },
    filterName: 'ilPayoutAmount',
  },
];
