/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Row, Col, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';

const GenerateSummaryTable = ({
  items,
  key,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: row => row.label || '',
        id: 'label',
        Cell: ({ value }) => (<b>{value}</b>) || '',
        width: 300,
      },
      {
        Header: 'In Negotiations',
        accessor: row => row.inNegotiations || 0,
        id: 'inNegotiations',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Contract Sent',
        accessor: row => row.contractSent || 0,
        id: 'contractSent',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Contract Signed',
        accessor: row => row.contractSigned || 0,
        id: 'contractSigned',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Content In Review',
        accessor: row => row.contentInReview || 0,
        id: 'contentInReview',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Published',
        accessor: row => row.published || 0,
        id: 'published',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Deal Paid',
        accessor: row => row.dealPaid || 0,
        id: 'dealPaid',
        Cell: ({ value }) => value || '',
        width: 150,
      },
    ],
    [items],
  );

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  return (
    <div>
      <Row key={key}>
        <Col className="mt-3">
          <Label className="bold-text">Summary By Deal Stage</Label>
          <ReactTableBase
            key="searchable"
            columns={columns}
            data={items}
            tableConfig={tableConfig}
          />
        </Col>
      </Row>
    </div>
  );
};

GenerateSummaryTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  key: PropTypes.string.isRequired,
};

export default GenerateSummaryTable;
