import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonToolbar,
  Modal,
  Button,
  ModalHeader, Row, Col,
} from 'reactstrap';
import Select, { components } from 'react-select';
import moment from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideModalById } from '../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../App/store/with-notice';
import CreatorInList from '../Table/CreatorInList';
import formater from '../../../../../shared/helpers/WVFormatter';

const WideTooltip = withStyles({
  tooltip: {
    width: '500px',
  },
})(Tooltip);

export const modalId = 'CreatorLookupModal';

const formatOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div >{record.first_name} {record.last_name}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record.email}
    </div>
  </div>
);
const option = (props) => {
  const { record } = props.data;
  console.log(record);
  return (
    <components.Option {...props} >
      <div>
        <div className="bold-text">{record.first_name} {record.last_name}</div>
        <div >{record['affiliateDataObject.company']}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record.email}
        </div>
      </div>
    </components.Option>
  );
};

const CreatorLookupModal = ({ deals, settings }) => {
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [creatorDeals, setCreatorDeals] = useState([]);

  const close = () => {
    setCreatorDeals([]);
    setSelected(null);
    dispatch(hideModalById(modalId));
  };

  if (!modalInfo) return null;
  const { isShow: showUploadModal } = modalInfo;
  console.log(deals);

  const getDefaultTargetCac = (brand, type) => {
    console.log(settings, [brand._id, type]);
    let targetCac = 450;
    let targetP = settings.types ? (settings.types[type] / 100) || 0.25 : 0.25;
    if (settings.brands[brand._id] && settings.brands[brand._id][type]) {
      targetCac = settings.brands[brand._id][type].cac;
      targetP = settings.brands[brand._id][type].percent / 100;
    }
    return [targetCac * (1 + targetP), targetCac, targetP];
  };

  let creatorOptions = [];
  deals.forEach((deal) => {
    if (creatorOptions.findIndex(value => value.value === deal.creator._id) === -1) {
      creatorOptions.push({
        value: deal.creator._id,
        label: `${deal.creator.first_name || ''} ${deal.creator.last_name || ''}`,
        record: deal.creator,
      });
    }
    const cacValue = (Number((deal.CPApaidConversionsArray?.length || 0) + (deal.CPMpaidConversions?.length || 0)) !== 0 ? Number(deal.cac || 0) : '-');
    const valueNumber = cacValue === '-' ? 0 : cacValue;
    const [targetCac, defaultTargetCac, percent] = getDefaultTargetCac(deal.offer.advertiser, deal.latestDeal.dealTerms.type_of_deal);
    const allow = cacValue !== '-' && cacValue > 0 && targetCac >= cacValue;
    // eslint-disable-next-line no-param-reassign
    deal.cacValues = {
      defaultTargetCac,
      percent,
      targetCac,
      cacValue,
      valueNumber,
      allow,
    };
  });
  creatorOptions = [...new Set(creatorOptions)];

  const handleOnChange = (value) => {
    if (value) {
      setSelected(value);
      const filteredDeals = deals.filter(deal => deal.creator._id === value.value);
      console.log(filteredDeals);
      setCreatorDeals(filteredDeals);
    }
  };

  return (
    <Modal
      isOpen={showUploadModal}
      toggle={close}
      size="lg"
      style={{ maxWidth: '1200px', width: '100%' }}
    >
      <ModalHeader toggle={() => close()} tag="h3">
        Creator Lookup
      </ModalHeader>
      <div className="modal__body ltr-support px-3 pt-3">
        <Row className="mb-4">
          <Col />
          <Col md={6}>
            <strong>Creator</strong>
            <Select
              value={selected}
              options={creatorOptions}
              onChange={handleOnChange}
              placeholder="Select Creator"
              isSearchable
              formatOptionLabel={formatOptionLabel}
              components={{ Option: option }}
            />
          </Col>
          <Col />
        </Row>
        {creatorDeals?.length > 0 ? (
          <div style={{ fontSize: '14px' }}>
            {creatorDeals.map(deal => (
              <div key={Math.random() + Math.random()}>
                <hr />
                <Row>
                  <Col md={3}>
                    <CreatorInList record={deal.creator} prospects={deal.prospects} />
                  </Col>
                  <Col>
                    <Row>
                      <Col md={2}>
                        <strong>Brand</strong>
                        <br />
                        {deal.offer.advertiser.companyName}
                      </Col>
                      <Col md={2}>
                        <strong>Deal Owner</strong>
                        <br />
                        {`${deal.admin?.firstName || ''} ${deal.admin?.lastName || ''}`}
                      </Col>
                      <Col md={4}>
                        <strong>Deal Name</strong>
                        <br />
                        {deal.latestDeal.dealname}{' '}
                        {deal.latestDeal?.hs_object_id ? (
                          <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal.latestDeal.hs_object_id}/`} target="_blank" rel="noreferrer">
                            <Tooltip title="Open Hubspot Deal">
                              <IconButton size="small">
                                <i className="fab fa-hubspot text-primary" style={{ fontSize: '0.8em' }} />
                              </IconButton>
                            </Tooltip>
                          </a>) : null}
                      </Col>
                      <Col md={2}>
                        <strong>Deal Type</strong>
                        <br />
                        {deal.latestDeal?.dealTerms?.type_of_deal || ''}
                      </Col>
                      <Col md={2}>
                        <strong>Publish date</strong>
                        <br />
                        {deal.publishDate ? moment.tz(moment.utc(deal.publishDate).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') : ''}
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={2}>
                        <strong>Days Since Last Publish</strong>
                        <br />
                        {deal.publishDate ? moment.tz('America/New_York').diff(moment.tz(moment.utc(deal.publishDate).format('YYYY-MM-DD'), 'America/New_York'), 'days') : '-'}
                      </Col>
                      <Col md={2}>
                        <strong>Revenue</strong>
                        <br />
                        {formater.formatCurrency(Number(deal.revenue || 0))}
                      </Col>
                      <Col md={2}>
                        <strong>Cost</strong>
                        <br />
                        {formater.formatCurrency(Number(deal.payout || 0))}
                      </Col>
                      <Col md={2}>
                        <strong>Gross Profit</strong>
                        <br />
                        {formater.formatCurrency(Number(deal?.revenue || 0) - Number(deal?.payout || 0))}
                      </Col>
                      <Col md={2}>
                        <strong>Revenue Conversions</strong>
                        <br />
                        {formater.formatIntNumber(Number((deal.CPApaidConversionsArray?.length || 0) + (deal.CPMpaidConversions?.length || 0)))}
                      </Col>
                      <Col md={2}>
                        <strong>CAC</strong>
                        <br />
                        <>
                          <WideTooltip
                            title={
                              <div style={{ fontSize: '14px', maxWidth: '500px', lineHeight: 2 }}>
                                <strong>Target CAC</strong>: {formater.formatCurrency(Number(deal.cacValues.defaultTargetCac))}<br />
                                <strong>Target Threshold %</strong>: {Number(deal.cacValues.percent) * 100}<br />
                                <strong>Target CAC Threshold</strong>: {formater.formatCurrency(Number(deal.cacValues.targetCac))}<br />
                                <strong>Amount Over Target CAC Threshold</strong>: {deal.cacValues.valueNumber ? formater.formatCurrency(Number(deal.cacValues.cacValue || 0) - Number(deal.cacValues.targetCac)) : '-'}<br />
                                {/* eslint-disable-next-line max-len */}
                                <strong>Percent Over Target CAC Threshold</strong>: {deal.cacValues.valueNumber ? `${formater.formatNumber(((Number(deal.cacValues.cacValue || 0) - Number(deal.cacValues.targetCac)) / Number(deal.cacValues.targetCac)) * 100, 2)}%` : '-'}<br />
                                {/* eslint-disable-next-line max-len */}
                                <span><strong>CAC</strong> of <strong>{deal.cacValues.valueNumber ? formater.formatCurrency(Number(deal.cacValues.cacValue || 0)) : '-'}</strong> {deal.cacValues.allow ? 'is less than ' : 'is over'} the <strong>{formater.formatCurrency(deal.cacValues.targetCac)}</strong> threshold</span><br />
                              </div>
                            }
                          >
                            <span className={deal.cacValues.allow ? 'text-success' : 'text-danger'}>{formater.formatCurrency(deal.cacValues.cacValue)}</span>
                          </WideTooltip>{' '}
                          {deal.cacValues.allow ? (
                            <FontAwesomeIcon icon="thumbs-up" className="text-success" />
                          ) : (
                            <FontAwesomeIcon icon="thumbs-down" className="text-danger" />
                          )
                          }
                        </>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <ButtonToolbar className="modal__footer mt-3">
        <Button color="secondary" onClick={close}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

CreatorLookupModal.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.any).isRequired,
  settings: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withNotice(CreatorLookupModal);
