import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import formater from '../../../../shared/helpers/WVFormatter';
import Item from '../StatItem';


const Total = ({ stats }) => (
  <Row className="" >
    <Col>
      <Item
        items={[{
          label: 'Gross Revenue',
          value: formater.formatCurrency(stats.grossRevenue),
        }]}
      />
    </Col>
    <Col>
      <Item
        items={[{
          label: 'Gross Profit',
          value: formater.formatCurrency(stats.grossProfit),
        }]}
      />
    </Col>
    <Col>
      <Item
        items={[{
          label: 'Conversions',
          value: formater.formatIntNumber(stats.conversions),
        }]}
      />
    </Col>

  </Row>);
Total.propTypes = {
  stats: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Total;
