import React from 'react';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import ReactTableBase from '@/shared/tables/table/ReactTableBase';
import { getConstant } from '@/shared/helpers/WVConstants';
import Hook from '@/shared/hooks';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  withSearchEngine: true,
  manualPageSize: [5, 10, 15],
  placeholder: 'Search...',
  sortBy: 'published',
  direction: 'desc',
  useFlex: true,
};
const showInfoModalFunc = ({ record, showInfoModal }) => {
  const dealstages = ((getConstant('deal', 'pipelines') || []).find(p => p.label.toLowerCase() === 'main pipeline'))?.stages;
  const message = (<div>
      <ul className='my-2'>
        <li>URL status must be "Approved".</li>
        <li>Deal stage must be either "Paid" or "Published".</li>
        <li>Status must be either "Pending" or "Active".</li>
        <li>Type of deal must be either "Upfront Only", "Upfront CPM", or "Hybrid".</li>
      </ul>
      <ReactTableBase
        columns={[{
          Header: 'Title',
          accessor: p => `${p?.title || ''}`,
          Cell: ({ value }) => value,
          width: 100,
        }, {
          Header: 'Url Status',
          accessor: p => `${p?.urlStatus}`,
          Cell: ({ value }) => value,
          width: 100,
        },
        {
          Header: 'Privacy Status',
          accessor: p => `${p?.privacyStatus}`,
          Cell: ({ value }) => value,
          width: 50,
        },
        {
          id: 'published',
          Header: 'Media Published',
          accessor: p => p.publishDate,
          Cell: ({ value }) => (value ? moment.tz(moment.utc(value).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') : '-'),
          width: 60,
        },
        {
          Header: 'Deal Info',
          accessor: p => `${p?.deal?.dealname || ''}`,
          Cell: ({ value, row }) => (
            <div>
              <strong>Deal Name:</strong> <a target="_blank" rel="noreferrer" href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${row.original.deal.hs_object_id}`}>{row.original.deal?.dealname}</a><br />
              <strong>Dealstage:</strong> {dealstages[row.original.deal?.dealstage] || '-'}<br />
              <strong>Status:</strong> {row.original.deal?.dealTerms?.cpa_status || '-'}<br />
              <strong>Type Of Deal:</strong> {row.original.deal?.dealTerms?.type_of_deal || '-'}<br />
            </div>
          ),
          disableSortBy: true,
          width: 150,
        },
      ]}
      data={record.params.mediaContentsFull}
      tableConfig={tableConfig}
    />
  </div>)
  showInfoModal({ title: 'Media Content List', message, width: 700, })
};

const LastPublishDate = ({ p }) => {
  const [showInfoModal] = Hook.useShowModalHook('SuccessModal');
  
  

  const mediaContentsOther = p.row.original.params?.mediaContentsFull;
  if (!mediaContentsOther?.length || p.value !== '-') return p.value;
  return (<div>
    <span className='pr-2'>{p.value}</span><IconButton size='small' onClick={() => showInfoModalFunc({ record: p.row.original, showInfoModal })}> <i
        className="fa fa-info-circle text-danger"
        aria-hidden="true"
        style={{ fontSize: 12 }}
      /></IconButton>
  </div>)
}

  export default LastPublishDate;