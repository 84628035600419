import React, { useEffect, useState, useMemo } from 'react';
import { InputGroup, Button, Col, Row, Label,Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import PropTypes from 'prop-types';
import CheckBoxField from '@/shared/components/CheckBox';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import ApiClient from 'ApiClient';
import Select from 'react-select';
import { axios } from 'ApiClient';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getConstant } from '@/shared/helpers/WVConstants';
import DownloadCSVButton from '@/shared/tables/table/DownloadCSVButton';

const defaultDealStatusFilters = {
  'Active': true,
  null: true,
  '': true,
  undefined: true,
  'Expired': true ,
  'Flat Rate per Conversion': true,
  'No CPA': true,
  'Paid': true,
  'Pending': true,
  'Percentage': true,
};

const defaultDealMatchedFilters = {
  'Yes': true,
  'No': true,
};

const defaultDealTypeFilters = {
    'Agent Commission': true,
    'Bonus': true,
    'CPA/Ambassador': true,
    'Deep Link': true,
    'Hybrid': true,
    'Media License': true,
    'Upfront CPM': true,
    'Upfront Only': true,
};

const TableWithFilters = ({ addNotice, unFilteredReportData, setFilteredReportData, adminUsers }) => {
  const [search, setSearch] = useState();
  const [searchValue, onSearchChange] = useState('');
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(true);
  const [fData, setFData] = useState([]);

  const [loadingAdvertiser, setLoadingAdvertiser] = useState(false);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [cspOptions, setCspOptions] = useState([]);
  const [selectedDealOwners, setSelectedDealOwners] = useState([]);
  const [selectedContactOwners, setSelectedContactOwners] = useState([]);
  const [selectedAffiliateManagers, setSelectedAffiliateManagers] = useState([]);
  const [selectedDealTypes, setSelectedDealTypes] = useState([]);
  const [selectedDealStages, setSelectedDealStages] = useState([]);

  const [dealStatusFilters, setDealStatusFilters] = useState(() => {
    const saved = localStorage.getItem('dealAuditReport-selected-dealStatusFilters');
    return saved ? JSON.parse(saved) : defaultDealStatusFilters;
  });

  const [dealMatchedFilters, setDealMatchedFilters] = useState(() => {
    const saved = localStorage.getItem('dealAuditReport-selected-dealMatched-Filters');
    return saved ? JSON.parse(saved) : defaultDealMatchedFilters;
  });

  const advertiserOptions = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    const i = advertisers.filter(b => b.displayInUi === 'Yes')
      .map(item => ({ value: item.tuneId, label: item.companyName }))
      .sort((a, b) => ((a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)));
    return i;
  }, [advertisers]);

  const dealTypeOptions = useMemo(() => {
    const transDealtypes = Object.entries(defaultDealTypeFilters).map(([key, value]) => {
      return { value: key, label: key };
    });
    return transDealtypes;
  }, [defaultDealTypeFilters]);

  const dealStagesOptions = ((getConstant('deal', 'pipelines') || []).find(p => p.label.toLowerCase() === 'main pipeline'))?.stages;

  const dealStageOptions = useMemo(() => {
    if(!dealStagesOptions || dealStagesOptions.length === 0) {
      return [];
    }
    const i = Object.entries(dealStagesOptions).map(([key, value]) => {
      return { value: key, label: value };
    });
    return i;
  }, [dealStagesOptions]);

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 500));
  }, [search]);

  useEffect(async () => {
    const api = new ApiClient();
    setLoadingAdvertiser(true);
    api.client.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      setAdvertisers(Object.values(brands));
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load advertisers. Check out console to see more information.',
        type: 'error',
      });
    });

    const resp = await axios.get('/api/portal/finance/get-deal-owners');
    const admins = resp.data.dealOwners ? resp.data.dealOwners.map(a => ({ value: `${a._id}`, label: `${a.firstName} ${a.lastName}`, affiliateManagerId: String(a.affiliateManagerId || '') })) : [];
    setCspOptions(admins);
  }, []);

  useEffect(() => {
    let items = [...unFilteredReportData];

    localStorage.setItem('dealAuditReport-selected-dealStatusFilters', JSON.stringify(dealStatusFilters));
    localStorage.setItem('dealAuditReport-selected-dealMatched-Filters', JSON.stringify(dealMatchedFilters));

    items = items.filter(item => dealStatusFilters[item.deal?.dealTerms?.cpa_status] === true);
    items = items.filter(item => dealMatchedFilters[item.deal ? 'Yes' : 'No'] === true);
    if (selectedAdvertisers.length > 0) {
      items = items.filter(item => !!selectedAdvertisers.find(sa => sa.label === item?.offer?.advertiser?.companyName));
    }
    if (selectedDealOwners.length > 0) {
      items = items.filter(d => !!selectedDealOwners.find(sa => sa.label === `${d?.admin?.firstName || ''} ${d?.admin?.lastName || ''}`));
    }
    if (selectedContactOwners.length > 0) {
      items = items.filter(d => !!selectedContactOwners.find(sa => sa.label === `${d?.hubilContact?.adminUserObject?.firstName || ''} ${d?.hubilContact?.adminUserObject?.lastName || ''}`));
    }
    if (selectedAffiliateManagers.length > 0) {
      // console.log('selectedAffiliateManagers', selectedAffiliateManagers, unFilteredReportData);
      const saf = selectedAffiliateManagers.map(a => a.affiliateManagerId);
      // console.log('selectedAffiliateManagers', selectedAffiliateManagers, saf);
      items = items.filter(d => saf.includes(String(d.influencer?.affiliateDataObject?.account_manager_id)));
    }

    if (selectedDealTypes.length > 0) {
      // console.log('selectedAffiliateManagers', selectedAffiliateManagers, unFilteredReportData);
      const dealTypes = selectedDealTypes.map(a => a.label || '');
      // console.log('dealTypes', dealTypes);
      items = items.filter(d => dealTypes.includes(String(d.deal?.dealTerms?.type_of_deal)));
    }

    if (selectedDealStages.length > 0) {
      // console.log('selectedAffiliateManagers', selectedAffiliateManagers, unFilteredReportData);
      const dealStages = selectedDealStages.map(a => a.label || '');
      items = items.filter(d => dealStages.includes(String(dealStagesOptions[d?.deal?.dealstage])));
    }

    if (searchValue) {
      items = items.filter(d => `${d.deal?.dealname?.toLowerCase()}${d?.influencer?.fullName?.toLowerCase()}`.includes(searchValue.trim().toLowerCase()));
      // allow = name.toLowerCase().includes(searchValue.trim().toLowerCase());
    }
    setFData(items);
    setFilteredReportData(items);
  }, [
   dealStatusFilters, dealMatchedFilters,
   selectedAdvertisers, selectedDealOwners,
   selectedContactOwners, selectedAffiliateManagers,
   searchValue, selectedDealTypes, selectedDealStages
  ]);

  const resetFilters = () => {
    setDealStatusFilters(defaultDealStatusFilters);
    setDealMatchedFilters(defaultDealMatchedFilters);
    setSelectedAdvertisers([]);
    setSelectedDealOwners([]);
    setSelectedContactOwners([]);
    setSelectedAffiliateManagers([]);
    setSelectedDealTypes([]);
    setSelectedDealStages([]);
  };

  return (
    <div className="mt-3">
    <Row>
      <Col sm={6}>
        <InputGroup>
          <Input
            className="form-control-sm form-control"
            name="search"
            type="search"
            autoComplete="off"
            placeholder="Search Deal Name and Creator Name"
            value={search}
            onChange={p => setSearch(p.target.value)}
            style={{ height: '38px' }}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <i
                className="fa fa-search"
                aria-hidden="true"
                style={{ color: '#70bbfd', fontSize: 12 }}
              />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </Col>
      <Col sm={2}>
        <Row>
          <Col sm={8} style={{ paddingRight: '0' }}>
            <Tooltip title="Reset Filters">
              <Button
                color="secondary"
                size="sm"
                onClick={resetFilters}
                style={{
                  width: '100%',
                  height: '38px',
                }}
              >
                <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                <span className="btn-text">Reset</span>
              </Button>
            </Tooltip>
          </Col>
          <Col sm={1} style={{ paddingLeft: '24px' }}>
            <Tooltip title="Toggle Filters">
              <IconButton
                id="filtersMenuButton"
                size="small"
                onClick={() => setShowFilters(!showFilters)}
                className="material-table__toolbar-button"
              >
                <TuneIcon size="30" />
              </IconButton>
            </Tooltip>
          </Col>
        </Row>
      </Col>
      <Col />
      <Col sm="auto" className="text-right">
        <DownloadCSVButton
          records={fData}
          className="mt-0"
          fileName="audit.csv"
          csvArrayColumns={[
            {
              label: 'Creator',
              func: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
            },
            {
              label: 'Brand Name',
              func: deal => deal?.offer?.advertiser?.companyName || '',
            },
            {
              label: 'Offer',
              func: deal => deal?.offer?.name || '',
            },
            {
              label: 'Deal Name',
              func: deal => deal?.deal?.dealname || '',
            },
            {
              label: 'Deal Type',
              func: deal => deal.deal?.dealTerms?.type_of_deal || '',
            },
            {
              label: 'Deal Status',
              func: deal => deal?.deal?.dealTerms?.cpa_status || '',
            },
            {
              label: 'Deal Stage',
              func: deal => deal?.deal?.dealstage ? dealStagesOptions[deal?.deal?.dealstage] : '',
            },
            {
              label: 'Deal Link',
              func: deal => (deal.deal ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal.deal.hs_object_id}/` : ''),
            },
            {
              label: 'Deal Owner',
              func: deal => `${deal?.admin?.firstName || ''} ${deal?.admin?.lastName || ''}`,
            },
            {
              label: 'Contact Owner',
              func: d => `${d?.hubilContact?.adminUserObject?.firstName || ''} ${d?.hubilContact?.adminUserObject?.lastName || ''}`,
            },
            {
              label: 'Account Manager',
              func: (d) => {
                const admin = adminUsers.find(a => a.affiliateManagerId === String(d.influencer?.affiliateDataObject.account_manager_id));
                let aManagerName = '';
                if (admin) {
                  aManagerName = admin.label;
                }
                return aManagerName;
              },
            },
            {
              label: 'Message',
              func: deal => deal?.messages?.join(' ') || '',
            },
        ]}
        >
          Download CSV
        </DownloadCSVButton>
      </Col>
    </Row>
    { showFilters && (
      <>
        <Row>
        <Col md={4}>
            <div style={{paddingBottom: '10px'}}>
              <div>
                <Label className="bold-text">
                  Brand(s)
                </Label>
              </div>
              <Select
                isDisabled={loadingAdvertiser}
                value={selectedAdvertisers}
                options={advertiserOptions}
                onChange={setSelectedAdvertisers}
                placeholder="Select Brand(s)..."
                isClearable
                isMulti
              />
            </div>

            <Row>
              <Col>
                <div>
                  <Label className="bold-text">
                    Deal Match?
                  </Label>
                </div>
                <div>
                  <CheckBoxField
                    name="Yes"
                    label="Yes"
                    value={dealMatchedFilters['Yes']}
                    onChange={event => event.target && setDealMatchedFilters({
                      ...dealMatchedFilters,
                      'Yes': event.target?.checked || false,
                    })}
                    className="inline"
                  />
                </div>
                <div>
                  <CheckBoxField
                    name="No"
                    label="No"
                    value={dealMatchedFilters['No']}
                    onChange={event => event.target && setDealMatchedFilters({
                      ...dealMatchedFilters,
                      'No': event.target?.checked || false,
                    })}
                    className="inline"
                  />
                </div>
              </Col>

              <Col>
                <div style={{paddingBottom: '20px'}}>
                  <div>
                  <Label className="bold-text">
                    Deal Status
                  </Label>
                  </div>
                  <div>
                    <CheckBoxField
                      name="Active"
                      label="Active"
                      value={dealStatusFilters['Active']}
                      onChange={event => event.target && setDealStatusFilters({
                        ...dealStatusFilters,
                        'Active': event.target?.checked || false,
                      })}
                      className="inline"
                    />
                  </div>
                  <div>
                    <CheckBoxField
                      name="Expired"
                      label="Expired"
                      value={dealStatusFilters['Expired']}
                      onChange={event => event.target && setDealStatusFilters({
                        ...dealStatusFilters,
                        'Expired': event.target?.checked || false,
                      })}
                      className="inline"
                    />
                  </div>
                  <div>
                    <CheckBoxField
                      name="Paid"
                      label="Paid"
                      value={dealStatusFilters['Paid']}
                      onChange={event => event.target && setDealStatusFilters({
                        ...dealStatusFilters,
                        'Paid': event.target?.checked || false,
                      })}
                      className="inline"
                    />
                  </div>
                  <div>
                    <CheckBoxField
                      name="Pending"
                      label="Pending"
                      value={dealStatusFilters['Pending']}
                      onChange={event => event.target && setDealStatusFilters({
                        ...dealStatusFilters,
                        'Pending': event.target?.checked || false,
                      })}
                      className="inline"
                    />
                  </div>
                  <div>
                    <CheckBoxField
                      name="undefined"
                      label="undefined"
                      value={dealStatusFilters['undefined']}
                      onChange={event => event.target && setDealStatusFilters({
                        ...dealStatusFilters,
                        undefined: event.target?.checked || false,
                      })}
                      className="inline"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            
          </Col>
          <Col md={4}>
            <div style={{paddingBottom: '10px'}}>
              <div>
                <Label className="bold-text">
                  Deal Stage(s)
                </Label>
              </div>
            <Select
                isMulti
                value={selectedDealStages}
                options={dealStageOptions}
                onChange={setSelectedDealStages}
                placeholder="Select Deal Type(s)..."
                isClearable
              />
            </div>

            <div>
              <Label className="bold-text">
                Deal Type(s)
              </Label>
            </div>
            <Select
              isMulti
              value={selectedDealTypes}
              options={dealTypeOptions}
              onChange={setSelectedDealTypes}
              placeholder="Select Deal Type(s)..."
              isClearable
            />

          </Col>
          <Col md={4}>
            <div style={{paddingBottom: '10px'}}>
              <div>
                <Label className="bold-text">
                  Contact Owner(s)
                </Label>
              </div>
              <Select
                isMulti
                value={selectedContactOwners}
                options={cspOptions}
                onChange={setSelectedContactOwners}
                placeholder="Select Contact Owner(s)..."
                isClearable
              />
            </div>

            <div style={{paddingBottom: '10px'}}>
              <div>
                <Label className="bold-text">
                  Deal Owner(s)
                </Label>
              </div>
              <Select
                isMulti
                value={selectedDealOwners}
                options={cspOptions}
                onChange={setSelectedDealOwners}
                placeholder="Select Deal Owner(s)..."
                isClearable
              />
            </div>

            <div>
              <Label className="bold-text">
                Account Manager(s)
              </Label>
            </div>
            <Select
              isMulti
              value={selectedAffiliateManagers}
              options={cspOptions}
              onChange={setSelectedAffiliateManagers}
              placeholder="Select Account Manager(s)..."
              isClearable
            />
          </Col>
        </Row>
        </>
       )}
    </div>
  );
};

        /* <Row>
        <Tooltip title="Reset Filters">
          <Button
            color="secondary"
            size="sm"
            onClick={resetFilters}
            style={{
              width: '20%',
              padding: '4px 8px',
              height: '42px',
            }}
          >
            <i className="fa fa-sync" aria-hidden="true" />&nbsp;
            <span className="btn-text">Reset Filters</span>
          </Button>
        </Tooltip>
      </Row>
          </Container> */
TableWithFilters.propTypes = {
  setFilteredReportData: PropTypes.func.isRequired,
  unFilteredReportData: PropTypes.arrayOf(PropTypes.any).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default TableWithFilters;
