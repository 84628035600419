import {
  Button, ButtonToolbar, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
// import SelectAsync from 'react-select/async';
import { axios } from 'ApiClient';
import Select from 'react-select';
import { CKEditor } from 'ckeditor4-react';
import { hideModalById } from '../../../../../../../../redux/reducers/TableReducer';
import { getAdminUsersAll } from '../../../../../../../../redux/reducers/admin/ResearchReducer';
import WVValidator from '../../../../../../../../shared/helpers/WVValidator';
import ReactTags from 'react-tag-autocomplete';

export const modalId = 'resubmitProspect';

const requiredFields = ['firstname', 'lastname', 'email'];

const ResumbitProspectModal = ({
  currentAdminUser,
  adminUsersAll,
}) => {
  const [loading, setLoading] = useState(false);
  const [analystsSuggestions, setAnalystsSuggestions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({
    value: null,
    label: '-- Not Assigned --',
  });
  const [brands, setBrands] = useState([]);
  const [analyst, setAnalyst] = useState({
    value: null,
    label: '-- Not Assigned --',
  });
  const [notes, setNotes] = useState('');
  // const [prospectType, setProspectType] = useState(prospectTypes.standard);
  const [prospectOwner, setProspectOwner] = useState(null);
  const [submittedBy, setSubmittedBy] = useState({
    value: currentAdminUser ? currentAdminUser._id : null,
    label: currentAdminUser ? `${currentAdminUser.firstName} ${currentAdminUser.lastName}` : '-- Not Assigned --',
  });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [tags, setTags] = useState([]);
  const [tagsSuggestions, setTagsSuggestions] = useState([]);

  const reactTags = useRef([]);


  const modalInfo = useSelector(state => state.table.modals[modalId]) || { isShow: false, data: { record: null } };
  console.log(modalInfo);
  const dispatch = useDispatch();
  const { data: { record, updateRecordFunction }, isShow } = modalInfo;
  const close = () => dispatch(hideModalById(modalId));

  useEffect(() => {
    if (adminUsersAll?.length < 1) {
      dispatch(getAdminUsersAll(false));
    }
  }, []);

  const loadBrandsOptions = async () => {
    const res = [
      { value: null, label: '-- Not Assigned --' },
    ];
    let response = {};
    if (record?.params?._id) {
      response = await axios({
        method: 'get',
        url: `/api/portal/prospects/get-unique-target-brands?prospectId=${record.id}`,
      });
      if (response.data.success) {
        response.data.brands.forEach((brandRecord) => {
          res.push({
            value: brandRecord._id,
            label: brandRecord.companyName,
            disabled: response.data.existingBrandIds.findIndex(el => el === brandRecord._id) !== -1 && record.params.approvalStatus.brand.targetAdvertiserId !== brandRecord._id,
          });
        });
      }
    }
    setBrands(res);
  };
  const handleInputChange = (e) => {
    const { editor } = e;
    setNotes(editor.getData());
  };

  const handleChangeBrand = (selected) => {
    setSelectedBrand({
      value: selected.value,
      label: selected.label,
    });
  };

  const handleChangeAnalyst = (selected) => {
    setAnalyst(selected);
  };

  useEffect(() => {
    if (record?.populated?.prospectOwner) {
      setProspectOwner({
        value: record.populated.prospectOwner.params._id,
        label: `${record.populated.prospectOwner.params.firstName} ${record.populated.prospectOwner.params.lastName}`,
      });
    }
    if (record?.populated?.approvalStatus?.brand?.targetAdvertiserId?.params) {
      setSelectedBrand({
        value: record.populated.approvalStatus.brand.targetAdvertiserId.params._id,
        label: record.populated.approvalStatus.brand.targetAdvertiserId.params.companyName,
      });
    }
    setNotes(record?.params?.approvalStatus?.influencelogic?.notes || '');
    setFirstName(record?.params?.firstname || '');
    setLastName(record?.params?.lastname || '');
    setEmail(record?.params?.email || '');
  }, [record]);

  const fetchOwnersSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospect-owners',
    }).then((response) => {
      if (response.data.success === true) {
        setAnalystsSuggestions(response.data.suggestions.map(a => ({ value: a.id, label: a.name })));
        if (response.data.defaultAdminUser) {
          setAnalyst({
            value: response.data.defaultAdminUser._id,
            label: `${response.data.defaultAdminUser.firstName} ${response.data.defaultAdminUser.lastName}`,
          });
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setTags(tagsNew);
  };

  const fetchTags = () => {
    axios({
      method: 'get',
      url: `/api/portal/research/get-prospects-tags?id=${record?.id}`,
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
        setTags(response.data.tags);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    if (isShow) {
      fetchOwnersSuggestions();
      loadBrandsOptions();
      fetchTags();
    }
    setLoading(false);
    if (!isShow) {
      setLoading(false);
      setSelectedBrand({
        value: null,
        label: '-- Not Assigned --',
      });
      setAnalyst({ value: null, label: '-- Not Assigned --' });
      setSubmittedBy({
        value: currentAdminUser ? currentAdminUser._id : null,
        label: currentAdminUser ? `${currentAdminUser.firstName} ${currentAdminUser.lastName}` : '-- Not Assigned --',
      });
      setFirstName('');
      setLastName('');
      setEmail('');
      setTags([]);
    }
  }, [isShow]);


  const doClone = async () => {
    const validationErrors = {};

    requiredFields.forEach((field) => {
      switch (field) {
        case 'firstname':
          if (!firstName || !WVValidator.isAlphaNumericOrWhitespace(firstName)) validationErrors.firstname = true;
          break;
        case 'lastname':
          if (!lastName || !WVValidator.isAlphaNumericOrWhitespace(lastName)) validationErrors.lastname = true;
          break;
        case 'email':
          if (!email || !WVValidator.isEmail(email)) validationErrors.email = true;
          break;
        default:
          break;
      }
    });
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const response = await axios({
        method: 'post',
        url: '/api/portal/prospects/post-resubmit-prospect',
        data: {
          id: record.id,
          submitterId: submittedBy.value,
          prospectOwnerId: prospectOwner?.value,
          notes,
          analystId: analyst.value,
          firstname: firstName,
          lastname: lastName,
          email,
          tags,
        },
      });
      close();
      console.log(updateRecordFunction, response?.data?.recordPopulated);
      if (response?.data?.recordPopulated && updateRecordFunction) {
        updateRecordFunction(response?.data?.recordPopulated);
      }
      console.log('DoClone', response);
    }
  };

  const fetchNotes = () => {
    setNotes(record?.params?.approvalStatus?.influenceLogic?.notes || '');
  };
  if (!record) return false;

  return (
    <Modal
      isOpen={isShow}
      toggle={() => { close(); }}
      style={{ minWidth: '800px' }}
    >
      <ModalHeader toggle={() => { close(); }} tag="h4">Resubmit Prospect</ModalHeader>
      <ModalBody>
        <Row className="dashboard">
          <Col md={6}>
            <FormGroup>
              <Label>First Name</Label>
              <Input
                type="text"
                id="firstname"
                name="firstname"
                placeholder=""
                autoComplete="off"
                onChange={(e) => { setFirstName(e.target.value); setErrors({ ...errors, firstname: false }); }}
                value={firstName}
                // disabled={!!record?.params?.firstname}
              />
            </FormGroup>
            {errors.firstname && (
              <div className="grey-small-text grey-minus-margin" style={errors.firstname ? { color: 'red' } : {}}>Required</div>
            )}
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Last Name</Label>
              <Input
                type="text"
                id="lastname"
                name="lastname"
                placeholder=""
                autoComplete="off"
                onChange={(e) => { setLastName(e.target.value); setErrors({ ...errors, lastname: false }); }}
                value={lastName}
                // disabled={!!record?.params?.lastname}
              />
            </FormGroup>
            {errors.lastname && (
              <div className="grey-small-text grey-minus-margin" style={errors.lastname ? { color: 'red' } : {}}>Required</div>
            )}
          </Col>
          <Col>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="text"
                id="email"
                name="email"
                placeholder=""
                autoComplete="off"
                onChange={(e) => { setEmail(e.target.value); setErrors({ ...errors, email: false }); }}
                value={email}
                // disabled={!!record?.params?.email}
              />
            </FormGroup>
            {errors.email && (
              <div className="grey-small-text grey-minus-margin" style={errors.email ? { color: 'red' } : {}}>Required</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Submitted by</Label>
              <Select
                isDisabled={loading}
                value={submittedBy}
                options={adminUsersAll}
                onChange={(selected) => { setSubmittedBy(selected); }}
                placeholder=""
                menuPlacement="auto"
              />
            </FormGroup>
            <FormGroup>
              <Label>Prospect Owner</Label>
              <Select
                isDisabled={loading}
                value={prospectOwner}
                options={analystsSuggestions}
                onChange={(selected) => { setProspectOwner(selected); }}
                placeholder=""
                menuPlacement="auto"
              />
            </FormGroup>
            <div>
              <FormGroup>
                <Label>Tags</Label>
                <ReactTags
                  ref={reactTags}
                  tags={tags}
                  suggestions={tagsSuggestions}
                  onDelete={onDeleteTag}
                  onAddition={onAdditionTag}
                  delimiters={['Enter', ',']}
                  allowNew
                  placeholderText="Tags"
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <Label>Target Brand</Label>
                <Select
                  isDisabled
                  value={selectedBrand}
                  options={brands}
                  onChange={handleChangeBrand}
                  placeholder=""
                  menuPlacement="auto"
                  isOptionDisabled={option => option.disabled}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <Label>Analyst</Label>
                <Select
                  isDisabled
                  value={analyst}
                  options={analystsSuggestions}
                  onChange={handleChangeAnalyst}
                  placeholder=""
                  menuPlacement="auto"
                />
                <div className="small">Optional (can be specified later)</div>
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <Label>Notes and Resubmit Justification</Label>
                <CKEditor
                  config={{versionCheck: false}}
                  readOnly={loading}
                  id="bodyEditor"
                  name="itemText"
                  activeClass="form-control"
                  initData={notes}
                  onChange={(e) => { handleInputChange(e); }}
                  onInstanceReady={({ editor }) => fetchNotes(editor)}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button color="primary" className="btn-primary btn btn-sm" onClick={doClone} disabled={!!loading}>Resubmit</Button>
        &nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" onClick={() => { close(); }}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

ResumbitProspectModal.propTypes = {
  adminUsersAll: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentAdminUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  adminUsersAll: state.ResearchReducer.adminUsersAll,
  currentAdminUser: state.session,
});

export default connect(mapStateToProps)(ResumbitProspectModal);
