import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  Button,
  ButtonToolbar, DropdownItem, DropdownMenu, DropdownToggle,
  Modal, Spinner, UncontrolledButtonDropdown,
} from 'reactstrap';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import withNotice from '../../../../../../App/store/with-notice';
import CopyToClipboard from '../../../../../../../shared/components/CopyToClipboard';


const ModalComponent = ({
  isOpen,
  toggleModal,
  title,
  mode,
  onAssignFunc,
  assignedPodcastId,
  currentEmail,
}) => {
  const [loading, setLoading] = useState(true);
  const [podcasts, setPodcasts] = useState([]);
  const [enableNegativeMarginOnTermsList, setEnableNegativeMarginOnTermsList] = useState(false);
  const [localAssignedPodcastId, setLocalAssignedPodcastId] = useState('');
  const [localCurrentEmail, setLocalCurrentEmail] = useState('');

  const listRef = useRef(null);

  const fetchData = () => {
    axios({
      method: 'post',
      url: '/api/portal/research/do-get-podcasts-from-youtube',
      data: { title },
    }).then((response) => {
      if (response.data.success) {
        setPodcasts(response.data.podcasts);
        setLoading(false);
      }
    });
    return true;
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  useEffect(() => {
    if (assignedPodcastId) {
      setLocalAssignedPodcastId(assignedPodcastId);
    }
  }, [assignedPodcastId]);

  useEffect(() => {
    if (currentEmail) {
      setLocalCurrentEmail(currentEmail);
    }
  }, [currentEmail]);

  useEffect(() => {
    setEnableNegativeMarginOnTermsList(listRef.current && listRef.current.scrollHeight > listRef.current.clientHeight);
  }, [podcasts]);

  const closeFormDialog = () => {
    toggleModal();
    setPodcasts([]);
    setLoading(true);
  };

  return (
    <div style={{ display: 'inline' }}>
      <Modal
        isOpen={isOpen}
        toggle={closeFormDialog}
        modalClassName="ltr-support"
        className="modal-dialog"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeFormDialog} />
          <h4 className="text-modal  modal__title">{`Related Podcasts to ${title}`}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12}>
              {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
              {!loading && podcasts.length > 0 && (
                <div
                  className={`tokens-list${enableNegativeMarginOnTermsList ? ' tokens-list-negative-margin' : ''}`}
                  style={{
                    padding: '5px',
                    maxHeight: '400px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                  ref={listRef}
                >
                  {podcasts.map(podcast => (
                    <>
                      <Row key={podcast.id}>
                        <Col md={2} className="my-auto">
                          <img
                            style={{
                            height: '75px',
                            width: 'auto',
                            marginRight: '10px',
                          }}
                            src={podcast.thumbnail}
                            alt=""
                          />
                        </Col>
                        <Col md={mode === 'search' ? 8 : 6} className="my-auto">
                          <b>
                            <a
                              href={`https://podcasts.apple.com/us/podcast/id${podcast.itunesId}`}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {podcast.title}
                            </a>
                          </b>
                          {podcast.email
                            ? (
                              <>
                                <br />
                                <CopyToClipboard value={podcast.email} className="d-block mt-1">
                                  <span className="il-text-gray">
                                    <span className="il-text-gray">
                                      {podcast.email}
                                    </span>
                                  </span>
                                </CopyToClipboard>
                              </>
                            ) : (<br />)
                          }
                          <p style={{ marginTop: '4px' }}>{`Episodes: ${podcast.episodes}`}</p>
                        </Col>
                        <Col md={2} className="my-auto">
                          <a href={`/research/search?type=podcast&search=${encodeURIComponent(podcast.title)}`} title="See Podcast Details" target="_blank" rel="noreferrer noopener">
                            <Button
                              className="mb-0"
                              color="primary"
                              size="small"
                              style={{ paddingLeft: '20px', paddingRight: '20px' }}
                            >
                              Details
                            </Button>
                          </a>
                        </Col>
                        {mode === 'prospect' && (
                          <Col md={2} style={{ paddingLeft: '0' }} className="my-auto">
                            {(localCurrentEmail && podcast.email)
                              ? (
                                <>
                                  {localAssignedPodcastId === podcast.id
                                    ? (
                                      <Button
                                        className="mb-0"
                                        disabled
                                        color="primary"
                                        size="small"
                                        onClick={() => {}}
                                        style={{ paddingLeft: '20px', paddingRight: '20px' }}
                                      >
                                        Assigned
                                      </Button>
                                    ) : (
                                      <UncontrolledButtonDropdown
                                        style={{ marginTop: '-12px' }}
                                        disabled={localAssignedPodcastId === podcast.id}
                                      >
                                        <DropdownToggle caret color="primary mb-0" style={{ padding: '5px 20px' }}>
                                          Assign
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                          <DropdownItem
                                            onClick={() => {
                                              onAssignFunc(podcast.id, false);
                                              closeFormDialog();
                                            }}
                                          >
                                            Safe Assign
                                          </DropdownItem>
                                          <DropdownItem divider />
                                          <DropdownItem
                                            onClick={() => {
                                              onAssignFunc(podcast.id, true);
                                              closeFormDialog();
                                            }}
                                          >
                                            {`Assign and overwrite current email "${localCurrentEmail}"`}
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledButtonDropdown>
                                    )
                                  }
                                </>
                              ) : (
                                <Button
                                  className="mb-0"
                                  disabled={localAssignedPodcastId === podcast.id}
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    onAssignFunc(podcast.id, localCurrentEmail === '');
                                    closeFormDialog();
                                  }}
                                  style={{ paddingLeft: '20px', paddingRight: '20px' }}
                                >
                                  {`Assign${localAssignedPodcastId === podcast.id ? 'ed' : ''}`}
                                </Button>
                              )
                            }
                          </Col>
                        )}
                      </Row>
                      <hr />
                    </>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel btn-sm" onClick={closeFormDialog}>Close</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  mode: PropTypes.string,
  onAssignFunc: PropTypes.func,
  assignedPodcastId: PropTypes.string,
  currentEmail: PropTypes.string,
};

ModalComponent.defaultProps = {
  mode: 'search',
  onAssignFunc: () => {},
  assignedPodcastId: '',
  currentEmail: '',
};

export default withNotice(ModalComponent);
