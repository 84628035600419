import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  Input,
  Spinner,
  Container,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import SelectAsync from 'react-select/async';
import ApiClient from 'ApiClient';
import withNotice from '../../../../App/store/with-notice';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import WVFormatter from '@/shared/helpers/WVFormatter';

const ResearchCreateContact = ({
  match,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [contact, setContact] = useState({
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    selectedHSOwnerOption: {},
    selectedAgentOption: {},
  });
  const [errors, setErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
  });

  const api = new ApiClient();

  const loadHSOwnerOptions = async (inputValue) => {
    const response = await api.client.get(`/api/portal/adminUsers/get-hubspot-users-local?term=${inputValue}`);
    return response.data.hsOwners.map(item => ({
      value: item._id,
      label: `${item.firstName} ${item.lastName}`,
      item,
    }));
  };

  const loadAgentOptions = async (inputValue) => {
    const records = await api.searchRecords({
      resourceId: 'AgentUser',
      query: inputValue,
    });
    const contacts = records.map(recordItem => ({
      value: recordItem.id,
      label: `${recordItem.params.first_name} ${recordItem.params.last_name} ${recordItem.params.email !== null ? ` (${recordItem.params.email})` : ''}`,
      record: recordItem,
    }));
    return contacts;
  };

  const setContactProperty = (field, value) => {
    setContact({
      ...contact,
      [field]: value,
    });
  };

  const loadData = async () => {
    setLoadingInitial(true);
    let response = {};
    try {
      response = await api.client.get(`/api/portal/research/get-research-creator-info?id=${match.params.researchCreatorId}`);
    } catch (e) {
      response.data.success = false;
    }
    setLoadingInitial(false);
    contact.id = match.params.researchCreatorId;
    contact.email = '';
    contact.first_name = '';
    contact.last_name = '';
    contact.company = '';
    contact.selectedHSOwnerOption = {};
    contact.selectedAgentOption = {};
    if (response.data.success) {
      contact.email = response.data.researchCreator.email;
      response.data.channels.forEach((channel) => {
        if (channel.platform === 'youtube') {
          contact.youtube_channel_name = channel.title;
          contact.youtube_channel_url = WVFormatter.normalizeUrl(channel.url);
        } else if (channel.platform === 'podcast') {
          contact.podcast_title = channel.title;
          contact.apple_podcast_url =  WVFormatter.normalizeUrl(channel.url);
        } else if (channel.platform === 'instagram') {
          contact.instagram_channel_name = channel.title;
          contact.instagram_channel =  WVFormatter.normalizeUrl(channel.url);
        } else if (channel.platform === 'facebook') {
          contact.facebook_channel_url =  WVFormatter.normalizeUrl(channel.url);
        }
      });
      if (response.data.researchCreator.agent) {
        contact.selectedAgentOption = {
          label: `${response.data.researchCreator.agent.first_name} ${response.data.researchCreator.agent.last_name} (${response.data.researchCreator.agent.email})`,
          value: response.data.researchCreator.agent._id,
          record: response.data.researchCreator.agent,
        };
      }
    }
    setContact({
      ...contact,
    });
  };

  useEffect(() => {
    loadData();
  }, [match.params.researchCreatorId]);

  const submit = async () => {
    let isValid = true;
    const requiredFields = ['first_name', 'last_name', 'email', 'company', 'selectedHSOwnerOption'];
    requiredFields.forEach((field) => {
      errors[field] = false;
      if (field !== 'selectedHSOwnerOption') {
        if (typeof contact[field] === 'undefined' || contact[field] === null || contact[field] === '') {
          isValid = false;
          errors[field] = 'This field is required';
        }
      } else if (Object.keys(contact[field]).length === 0 || contact[field] === null) {
        isValid = false;
        errors[field] = 'This field is required';
      }
    });
    setErrors(errors);
    setContactProperty('first_name', contact.first_name); // to force component to re-render

    if (!isValid) {
      addNotice({
        message: 'Please, fill all required params',
        type: 'error',
      });
      return false;
    }

    setLoading(true);
    const responseContactExists = await api.client.get(`/api/portal/hubil-data/get-contact-exist?email=${encodeURIComponent(contact.email)}`);
    if (responseContactExists.data.success) {
      if (responseContactExists.data.existContact) {
        errors.email = 'HubSpot contact with the provided email address already exists';
        setErrors(errors);
        addNotice({
          message: 'HubSpot contact with the provided email address already exists',
          type: 'error',
        });
        setLoading(false);
        return false;
      }
    }

    const data = new FormData();
    const fields = ['id', 'first_name', 'last_name', 'email', 'company', 'youtube_channel_name', 'youtube_channel_description', 'youtube_channel_url',
      'apple_podcast_url', 'podcast_genre', 'podcast_title', 'instagram_channel_name', 'instagram_channel', 'facebook_channel_url'];
    fields.forEach((field) => {
      if (typeof contact[field] !== 'undefined' && contact[field]) {
        data.append(field, contact[field]);
      }
    });
    const selectFields = ['selectedHSOwnerOption', 'selectedAgentOption'];
    selectFields.forEach((field) => {
      data.append(field, contact[field] && typeof contact[field].value !== 'undefined' ? contact[field].value : null);
    });
    try {
      const response = await api.client.post('/api/portal/research/save-hubspot-user', data);
      setLoading(false);
      if (response.data.success) {
        addNotice({
          message: 'HubSpot Contact has been successfully created',
          type: 'success',
        });
      } else {
        addNotice({
          message: `An Error occurred while creating HubSpot contact. Error Message: ${response.data.error}`,
          type: 'error',
        });
      }
    } catch (err) {
      setLoading(false);
      addNotice({
        message: 'An Error occurred while creating HubSpot contact',
        type: 'error',
      });
    }
    return true;
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Creator Research', path: '/research/creators' },
              { title: 'Create a New Contact', path: false },
            ]}
            isBackButton
            backButtonUrl="/research/creators"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardTitle className="row justify-content-center">
              <Col xs="12">
                <h3>Create a New Contact</h3>
              </Col>
            </CardTitle>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <div>
                  {loadingInitial &&
                  (
                    <div className="text-center"><Spinner color="primary" size="lg" /></div>
                  )
                  }
                  {!loadingInitial &&
                  (
                    <Row>
                      <Col md={12}>
                        <h4>Contact Properties</h4>
                        <hr />
                        <div className="grey-small-text grey-minus-margin">Specify the Creator&apos;s HubSpot Contact record details</div>
                        <br />
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="first_name">First Name</label>
                              <Input
                                type="text"
                                id="first_name"
                                name="first_name"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('first_name', e.target.value)}
                                value={contact.first_name}
                                disabled={loading}
                              />
                              {errors.first_name && (<span className="text-danger">{errors.first_name}</span>)}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="last_name">Last Name</label>
                              <Input
                                type="text"
                                id="last_name"
                                name="last_name"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('last_name', e.target.value)}
                                value={contact.last_name}
                                disabled={loading}
                              />
                              {errors.last_name && (<span className="text-danger">{errors.last_name}</span>)}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="email">Contact Email</label>
                              <Input
                                type="text"
                                id="email"
                                name="email"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('email', e.target.value)}
                                value={contact.email}
                                disabled={loading}
                              />
                              {errors.email && (<span className="text-danger">{errors.email}</span>)}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="company">Company</label>
                              <Input
                                type="text"
                                id="company"
                                name="company"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('company', e.target.value)}
                                value={contact.company}
                                disabled={loading}
                              />
                              {errors.company && (<span className="text-danger">{errors.company}</span>)}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="owner">Contact Owner</label>
                              <SelectAsync
                                id="owner"
                                cacheOptions
                                value={contact.selectedHSOwnerOption}
                                defaultOptions
                                loadOptions={loadHSOwnerOptions}
                                onChange={e => setContactProperty('selectedHSOwnerOption', e)}
                                placeholder="Please Select HubSpot Owner"
                                isClearable
                                disabled={loading}
                              />
                              {errors.selectedHSOwnerOption && (<span className="text-danger">{errors.selectedHSOwnerOption}</span>)}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="agent">Agent Contact</label>
                              <SelectAsync
                                id="agent"
                                cacheOptions
                                value={contact.selectedAgentOption}
                                defaultOptions
                                loadOptions={loadAgentOptions}
                                onChange={e => setContactProperty('selectedAgentOption', e)}
                                placeholder="Please Select Agent Contact"
                                isClearable
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <br />
                        <h4>Media Channel Properties</h4>
                        <hr />
                        <div className="grey-small-text grey-minus-margin">Specify the Creator&apos;s HubSpot Media Channel details</div>
                        <br />
                        <h5 style={{ fontSize: '17px' }}><b>YouTube</b></h5>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="youtube_channel_name">YouTube Channel Name</label>
                              <Input
                                type="text"
                                id="youtube_channel_name"
                                name="youtube_channel_name"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('youtube_channel_name', e.target.value)}
                                value={contact.youtube_channel_name ? contact.youtube_channel_name : ''}
                                disabled={loading}
                              />
                              {errors.youtube_channel_name && (<span className="text-danger">{errors.youtube_channel_name}</span>)}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="youtube_channel_url">YouTube Channel URL</label>
                              <Input
                                type="text"
                                id="youtube_channel_url"
                                name="youtube_channel_url"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('youtube_channel_url', e.target.value)}
                                value={contact.youtube_channel_url ? contact.youtube_channel_url : ''}
                                disabled={loading}
                              />
                              {errors.youtube_channel_url && (<span className="text-danger">{errors.youtube_channel_url}</span>)}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="youtube_channel_description">YouTube Channel Description</label>
                              <textarea
                                id="youtube_channel_description"
                                name="youtube_channel_description"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('youtube_channel_description', e.target.value)}
                                value={contact.youtube_channel_description ? contact.youtube_channel_description : ''}
                                disabled={loading}
                                rows={4}
                                className="form-control"
                              />
                              {errors.youtube_channel_description && (<span className="text-danger">{errors.youtube_channel_description}</span>)}
                            </FormGroup>
                          </Col>
                        </Row>

                        <br />
                        <h5 style={{ fontSize: '17px' }}><b>Podcast</b></h5>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="podcast_title">Podcast Title</label>
                              <Input
                                type="text"
                                id="podcast_title"
                                name="podcast_title"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('podcast_title', e.target.value)}
                                value={contact.podcast_title ? contact.podcast_title : ''}
                                disabled={loading}
                              />
                              {errors.podcast_title && (<span className="text-danger">{errors.podcast_title}</span>)}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="podcast_genre">Podcast Genre</label>
                              <Input
                                type="text"
                                id="podcast_genre"
                                name="podcast_genre"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('podcast_genre', e.target.value)}
                                value={contact.podcast_genre ? contact.podcast_genre : ''}
                                disabled={loading}
                              />
                              {errors.podcast_genre && (<span className="text-danger">{errors.podcast_genre}</span>)}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="apple_podcast_url">Podcast URL</label>
                              <Input
                                type="text"
                                id="apple_podcast_url"
                                name="apple_podcast_url"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('apple_podcast_url', e.target.value)}
                                value={contact.apple_podcast_url ? contact.apple_podcast_url : ''}
                                disabled={loading}
                              />
                              {errors.apple_podcast_url && (<span className="text-danger">{errors.apple_podcast_url}</span>)}
                            </FormGroup>
                          </Col>
                        </Row>

                        <br />
                        <h5 style={{ fontSize: '17px' }}><b>Instagram</b></h5>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="instagram_channel_name">Instagram Channel Name</label>
                              <Input
                                type="text"
                                id="instagram_channel_name"
                                name="instagram_channel_name"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('instagram_channel_name', e.target.value)}
                                value={contact.instagram_channel_name ? contact.instagram_channel_name : ''}
                                disabled={loading}
                              />
                              {errors.instagram_channel_name && (<span className="text-danger">{errors.instagram_channel_name}</span>)}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="instagram_channel">Instagram Channel URL</label>
                              <Input
                                type="text"
                                id="instagram_channel"
                                name="instagram_channel"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('instagram_channel', e.target.value)}
                                value={contact.instagram_channel ? contact.instagram_channel : ''}
                                disabled={loading}
                              />
                              {errors.instagram_channel && (<span className="text-danger">{errors.instagram_channel}</span>)}
                            </FormGroup>
                          </Col>
                        </Row>

                        <br />
                        <h5 style={{ fontSize: '17px' }}><b>Facebook</b></h5>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                              <label htmlFor="facebook_channel_url">Facebook Channel URL</label>
                              <Input
                                type="text"
                                id="facebook_channel_url"
                                name="facebook_channel_url"
                                placeholder=""
                                autoComplete="off"
                                onChange={e => setContactProperty('facebook_channel_url', e.target.value)}
                                value={contact.facebook_channel_url ? contact.facebook_channel_url : ''}
                                disabled={loading}
                              />
                              {errors.facebook_channel_url && (<span className="text-danger">{errors.facebook_channel_url}</span>)}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12}>
                        <Button className="modal_ok btn-sm" color="primary" onClick={submit}>
                          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                          <span>Save</span>
                        </Button>{' '}
                      </Col>
                    </Row>
                  )}
                </div>
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
ResearchCreateContact.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  adminUser: state.session,
});

export default connect(mapStateToProps)(withNotice(ResearchCreateContact));
