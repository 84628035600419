import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row, Col, Button, Container, Card, CardBody, CardTitle, Label, FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import Filter from './TimePeriodFilterCustom';
import ChartComponentCommentsPercentage from './ChartComponentCommentsPercentage';
import ChartComponentCommentsWithSentinement from './ChartComponentCommentsWithSentinement';
import ChartComponentCommentsTotal from './ChartComponentCommentsTotal';
import CheckBoxField from '../../../../shared/components/CheckBox';
import hooks from '../../../../shared/hooks';
import LoadingReport from "../../../../shared/components/LoadingReport";
import InfluencersTable from './InfluencersTable';
import VideosTable from './VideosTable';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
import { formatIntNumber, getFormattedDateNoZeroes } from '../../../../shared/helpers/WVFormatter';
import { fetchDictionary } from '../../../../shared/helpers/WVRequest';

const dataOptions = [
  { value: 'byDay', label: 'By Day' },
  { value: 'week', label: 'By Week' },
  { value: 'month', label: 'By Month' },
  { value: 'total', label: 'Over Time' },
];

const csvArrayColumnsInfluencers=[
  {
    label: 'Creator',
    func: record => (record.name || ''),
  },
  {
    label: 'Video Count',
    func: record => record.totalMediaContents || 0,
  },
  {
    label: 'Total Views',
    func: record => record.totalViews || 0,
  },
  {
    label: 'Total Comments',
    func: record => record.count || 0,
  },
  {
    label: 'Negative Comments',
    func: record => record.negativeCount || 0,
  },
  {
    label: 'Negative Percentage',
    func: record => `${formatIntNumber(record.negativePercentage || 0, 3)}%`,
  },
  {
    label: 'Positive Comments',
    func: record => record.positiveCount || 0,
  },
  {
    label: 'Positive Percentage',
    func: record => `${formatIntNumber(record.positivePercentage || 0, 3)}%`,
  },
];

const csvArrayColumnsVideos=[
  {
    label: 'Video Title',
    func: record => (record.title || ''),
  },
  {
    label: 'Creator',
    func: record => (record.name || ''),
  },
  {
    label: 'PublishDate',
    func: record => getFormattedDateNoZeroes(record.publishDate),
  },
  {
    label: 'Total Views',
    func: record => record.totalViews || 0,
  },
  {
    label: 'Total Comments',
    func: record => record.count || 0,
  },
  {
    label: 'Negative Comments',
    func: record => record.negativeCount || 0,
  },
  {
    label: 'Negative Percentage',
    func: record => `${formatIntNumber(record.negativePercentage || 0, 3)}%`,
  },
  {
    label: 'Positive Comments',
    func: record => record.positiveCount || 0,
  },
  {
    label: 'Positive Percentage',
    func: record => `${formatIntNumber(record.positivePercentage || 0, 3)}%`,
  },
];


const YouTubeCommentsAnalysisReport = ({ addNotice }) => {
  const [useShowForUsers] = hooks.useAccessHook({ hideWhenTeam: ['Auditors'] });
  const [loading, setLoading] = useState(false);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState(false);
  const [loadingYouTubeCategoriesOptions, setLoadingYouTubeCategoriesOptions] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [advertisers, setAdvertisers] = useState([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [youTubeCategoriesOptions, setYouTubeCategoriesOptions] = useState([]);
  const [selectedYouTubeCategories, setSelectedYouTubeCategories] = useState(null);
  const [selectedOption, setSelectedOption] = useState(dataOptions[0]);
  const [periodsData, setPeriodsData] = useState({
    comments: [],
    influencersNegative: [],
    influencersPositive: [],
    mediaContentPositive: [],
    mediaContentNegative: [],
  });
  const advertiserOptions = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    return advertisers?.map(item => ({ value: item._id, label: item.companyName })).sort((a, b) => ((a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)));
  }, [advertisers]);
  const [filtersForCreatorUrls, setFiltersForCreatorUrls] = useState('');
  const [filtersForVideoUrls, setFiltersForVideoUrls] = useState('');

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  const commentsStats = useMemo(() => {
    const sumAll = ({
      count, negativeCount, neutralCount, positiveCount, label,
    }, item) => {
      return {
        count: count + item?.count || 0,
        negativeCount: negativeCount + item?.negativeCount || 0,
        neutralCount: neutralCount + item?.neutralCount || 0,
        positiveCount: positiveCount + item?.positiveCount || 0,
        positivePercentage: (count + item?.count || 0) ? ((positiveCount + item?.positiveCount || 0) / (count + item?.count || 0)) : 0,
        negativePercentage: (count + item?.count || 0) ? ((negativeCount + item?.negativeCount || 0) / (count + item?.count || 0)) : 0,
        label: label || item?.label,
      };
    };
    const total = selectedOption.value;
    const startMoment = moment(startDate).startOf('day');
    const endMoment = moment() > moment(endDate).endOf('day') ? moment(endDate).endOf('day') : moment().endOf('day');
    const chartData = {};
    const chartDataMonth = {};
    const chartDataWeek = {};
    const totalData = {};
    const days = endMoment.diff(startMoment, 'day');
    const months = endMoment.diff(startMoment, 'month');
    const weeks = endMoment.diff(startMoment, 'weeks') + 1;

    for (let i = 0; i <= days; i += 1) {
      const momentDate = startMoment.clone().add(i, 'day');
      const key = momentDate.format('YYYY-MM-DD');
      chartData[key] = {
        label: momentDate.format('M/D/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
      totalData[key] = {
        label: momentDate.format('M/D/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
    }
    for (let i = 0; i <= months; i += 1) {
      const momentDate = startMoment.clone().add(i, 'month');
      const key = momentDate.format('YYYY-MM');
      chartDataMonth[key] = {
        label: momentDate.format('M/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
    }
    for (let i = 0; i <= weeks; i += 1) {
      const momentDate = startMoment.clone().add(i, 'week').startOf('week');
      const key = momentDate.format('YYYY-w');
      // console.log(key, moment(key, 'YYYY-w').startOf('week'), endMoment);
      if (momentDate > endMoment) break;
      chartDataWeek[key] = {
        label: `${moment(key, 'YYYY-w').startOf('week').format('M/D/YY')} - ${moment(key, 'YYYY-w').endOf('week').format('M/D/YY')}`,
        count: 0,
        negativeCount: 0,
        neutralCount: 0,
        positiveCount: 0,
        positivePercentage: 0,
        negativePercentage: 0,
      };
    }


    periodsData.comments.forEach(({
      label, count, negativeCount, neutralCount, positiveCount,
    }) => {
      const week = moment(label).format('YYYY-w');
      const byMonthKey = label.substring(0, 7);
      chartData[label] = sumAll({
        count, negativeCount, neutralCount, positiveCount,
      }, chartData[label]);
      chartDataMonth[byMonthKey] = sumAll({
        count, negativeCount, neutralCount, positiveCount, label: moment(label).format('M/YY'),
      }, chartDataMonth[byMonthKey]);
      chartDataWeek[week] = sumAll({
        count, negativeCount, neutralCount, positiveCount,
      }, chartDataWeek[week]);
    });

    if (total === 'total') {
      let beforeValue = {
        count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0,
      };
      Object.keys(chartData).forEach((day) => {
        const valueForDays = chartData[day];
        totalData[day] = sumAll({ ...beforeValue, label: moment(day).format('M/D/YY') }, valueForDays);
        beforeValue = { ...totalData[day] };
      });
    }
    if (total === 'month') return Object.values(chartDataMonth);
    if (total === 'week') return Object.values(chartDataWeek);
    if (total === 'total') return Object.values(totalData);
    return Object.values(chartData);
  }, [selectedOption, periodsData.comments]);

  const loadBrands = () => {
    const api = new ApiClient();
    setLoadingAdvertiser(true);
    api.client.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser._id]) brands[offer.advertiser._id] = { ...offer.advertiser };
      });
      setAdvertisers(Object.values(brands));
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load advertisers. Check out console to see more information.',
        type: 'error',
      });
    });
  }

  // eslint-disable-next-line no-unused-vars
  const loadCategories = () => {
    setLoadingYouTubeCategoriesOptions(true);
    fetchDictionary('PROSPECT_').then((data) => {
      const { success, records } = data;
      if (success === true) {
        const _categories = records.PROSPECT_CATEGORIES ? JSON.parse(records.PROSPECT_CATEGORIES) : [];
        const _categorySuggestions = _categories?.map(row => ({ value: row, label: row }));
        setYouTubeCategoriesOptions(_categorySuggestions);
      }
      setLoadingYouTubeCategoriesOptions(false);
    }).catch((err) => {
      console.log(err);
      setLoadingYouTubeCategoriesOptions(false);
    });
  }

  useEffect(() => {
    loadBrands();
    loadCategories();
  }, []);

  const setPeriodDataForTypes = (comments, influencersNegative, influencersPositive, mediaContentPositive, mediaContentNegative) => {
    const array = {
      comments,
      influencersNegative,
      influencersPositive,
      mediaContentPositive,
      mediaContentNegative,
    };
    setPeriodsData(array);
  };

  const updateFiltersForUrl = () => {
    setFiltersForCreatorUrls(`${selectedAdvertiser?.value ? `&advertiser=${selectedAdvertiser?.value}` : ''}${selectedAdvertiser?.label ? `&advertiserName=${selectedAdvertiser?.label}` : ''}&startDate=${startDate}&endDate=${endDate}`);
    setFiltersForVideoUrls(`&startDate=${startDate}&endDate=${endDate}`);
  }

  const fetchReport = async () => {
    setLoading(true);
    updateFiltersForUrl();
    try {
      const resp = await axios.post('/api/portal/reports/post-report-brand-comments-analysis', {
        startDate,
        endDate,
        advertiserId: selectedAdvertiser?.value || null,
        identity,
        categories: selectedYouTubeCategories ? selectedYouTubeCategories?.map(i => i.value) : null,
      });
      setLoading(false);
      if (resp.data.success) {
        const {
          comments, influencersNegative, influencersPositive, mediaContentPositive, mediaContentNegative,
        } = resp.data;
        if (comments?.length > 0) {
          setPeriodDataForTypes(comments, influencersNegative, influencersPositive, mediaContentPositive, mediaContentNegative);
        } else {
          addNotice({
            message: 'No comments found with current filters!',
            type: 'success',
            duration: 60,
          });
        }
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  if (!useShowForUsers) {
    return null;
  }
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Statistics', path: false },
              { title: 'Video Comments', path: false },
              { title: 'Brand Comments Analysis', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Brand Comments Analysis</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col md={10}>
                  <Label className="bold-text">Comment Date</Label>
                  <Filter
                    setValue={(param, value) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                  />
                </Col>
                <Col md={2}>
                  <div
                    className="d-flex"
                    style={{
                      float: 'right',
                      paddingTop: '31px',
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchReport();
                        return false;
                      }}
                      className="my-0 btn-sm"
                      disabled={!!loading || !selectedAdvertiser}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={3}>
                  <Label className="bold-text">Brand</Label>
                  <Select
                    isClearable
                    isDisabled={loadingAdvertiser}
                    value={selectedAdvertiser}
                    options={advertiserOptions}
                    onChange={setSelectedAdvertiser}
                    placeholder="Select Brand..."
                    styles={colourStyles}
                  />
                </Col>
                <Col md={9}>
                  <Label className="bold-text">
                    Categories
                  </Label>
                  <Select
                    isMulti
                    isClearable
                    isDisabled={loadingYouTubeCategoriesOptions}
                    value={selectedYouTubeCategories}
                    options={youTubeCategoriesOptions}
                    onChange={setSelectedYouTubeCategories}
                    placeholder="Select Categories..."
                    styles={colourStyles}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {!loading && periodsData.comments.length > 0 && (
                <>
                  <Row className="mt-3">
                    <Col md={2}>
                      <div
                        className="form-group"
                      >
                        <Select
                          placeholder="Select Property to Chart..."
                          value={selectedOption}
                          options={dataOptions}
                          onChange={setSelectedOption}
                        />
                      </div>
                    </Col>
                    <Col>
                      <FormGroup style={{ paddingTop: '10px' }}>
                        <CheckBoxField
                          name="showLabels"
                          label="Show Labels on Charts"
                          value={showLabels}
                          onChange={e => setShowLabels(e.target?.checked || false)}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                    <Col />
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <h4 className="pb-3">Percent Positive & Negative Comments</h4>
                      <ChartComponentCommentsPercentage
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <h4 className="pb-3">Positive and Negative Comments</h4>
                      <ChartComponentCommentsWithSentinement
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <h4 className="pb-3">Total Comments</h4>
                      <ChartComponentCommentsTotal
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div
                        style={{
                          position: 'absolute',
                          right: '15px',
                          top: '-3px'
                        }}
                      >
                        <DownloadCSVButton
                          className=""
                          records={periodsData.influencersPositive}
                          fileName="Top10PositiveCreators.csv"
                          csvArrayColumns={csvArrayColumnsInfluencers}
                        >
                          Download CSV
                        </DownloadCSVButton>
                      </div>
                      <h4 className="pb-3">Top 10 Positive Creators</h4>
                      <InfluencersTable
                        data={periodsData.influencersPositive}
                        sortBy="positiveCount"
                        filtersForUrls={filtersForCreatorUrls}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div
                        style={{
                          position: 'absolute',
                          right: '15px',
                          top: '-3px'
                        }}
                      >
                        <DownloadCSVButton
                          className=""
                          records={periodsData.influencersNegative}
                          fileName="Top10NegativeCreators.csv"
                          csvArrayColumns={csvArrayColumnsInfluencers}
                        >
                          Download CSV
                        </DownloadCSVButton>
                      </div>
                      <h4 className="pb-3">Top 10 Negative Creators</h4>
                      <InfluencersTable
                        data={periodsData.influencersNegative}
                        sortBy="negativeCount"
                        filtersForUrls={filtersForCreatorUrls}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div
                        style={{
                          position: 'absolute',
                          right: '15px',
                          top: '-3px'
                        }}
                      >
                        <DownloadCSVButton
                          className=""
                          records={periodsData.mediaContentPositive}
                          fileName="Top10PositiveVideos.csv"
                          csvArrayColumns={csvArrayColumnsVideos}
                        >
                          Download CSV
                        </DownloadCSVButton>
                      </div>
                      <h4 className="pb-3">Top 10 Positive Videos</h4>
                      <VideosTable
                        data={periodsData.mediaContentPositive}
                        sortBy="positiveCount"
                        filtersForCreatorUrls={filtersForCreatorUrls}
                        filtersForVideoUrls={filtersForVideoUrls}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div
                        style={{
                          position: 'absolute',
                          right: '15px',
                          top: '-3px'
                        }}
                      >
                        <DownloadCSVButton
                          className=""
                          records={periodsData.mediaContentNegative}
                          fileName="Top10NegativeVideos.csv"
                          csvArrayColumns={csvArrayColumnsVideos}
                        >
                          Download CSV
                        </DownloadCSVButton>
                      </div>
                      <h4 className="pb-3">Top 10 Negative Videos</h4>
                      <VideosTable
                        data={periodsData.mediaContentNegative}
                        sortBy="negativeCount"
                        filtersForCreatorUrls={filtersForCreatorUrls}
                        filtersForVideoUrls={filtersForVideoUrls}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

YouTubeCommentsAnalysisReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(YouTubeCommentsAnalysisReport);
