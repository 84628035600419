/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { unflatten } from 'flat';
import { axios } from 'ApiClient';
import { Modal, ButtonToolbar, Button, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

const EditModal = ({
  modal,
  setModalVisible,
  record,
  setVersion,
}) => {
  const q = unflatten(record);
  const [selectedDate, setSelectedDate] = useState(new Date(q.params.startTime));
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setSelectedDate(new Date(q.params.startTime));
  }, [q.params.startTime]);
  const saveEditTime = async () => {
    setLoading(true);
    try {
      const recordData = await axios.post('/api/portal/prospects/post-edit-upload', { _id: record.params._id, startTime: selectedDate });
      console.log(recordData);
      setModalVisible(false);
      setVersion((new Date()).toString());
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  console.log(q);
  return (
    <Modal
      isOpen={modal}
      toggle={() => setModalVisible(false)}
    >
      <div className="modal__header">
        <h3 className="text-modal  modal__title">Edit {q.params.title}</h3>
      </div>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <div className="mt-1 mb-4">
          <div >
            <FormGroup>
              <Label for="startDate">Start Time</Label><br />
              <DatePicker
                selected={selectedDate}
                selectsStart
                minDate={new Date()}
                onChange={date => setSelectedDate(date)}
                timeFormat="HH:mm"
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                className="form-control form-control"
                dropDownMode="select"
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm mr-2" color="primary" disabled={loading} onClick={() => { saveEditTime(); }} >Save</Button>
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => setModalVisible(false)}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

EditModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  setVersion: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  // q: PropTypes.objectOf(PropTypes.any).isRequired,
};

EditModal.defaultProps = {

};

export default EditModal;
