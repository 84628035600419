import React, { useState, useEffect, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import moment from 'moment';
import { Col, Container, Row, Card, CardBody, ButtonToolbar, Spinner } from 'reactstrap';
import withNotice from '../../App/store/with-notice';
import ButtonWithSpinner from '../../../shared/components/ButtonWithSpinner';
import ReactTableBase from '../../../shared/tables/table/ReactTableBase';
// import { ConsoleIcon } from 'mdi-react';

const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

export default withNotice(({ addNotice }) => {
  console.log();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [files, setFiles] = useState([]);
  const [filesIO, setFilesIO] = useState([]);
  const [filesI, setFilesI] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);

  const loadToken = async () => {
    try {
      const data = await axios({
        method: 'get',
        url: '/api/portal/prospects/get-google-oauth-token',
      });
      console.log(data.data);
      if (data.data.success === true) {
        setToken(data.data.token);
      } else {
        setToken(null);
      }
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to get Google Oauth 2 Token',
        type: 'Error',
      });
    }
  };
  const getGoogleCredentials = async () => {
    setLoading(true);
    try {
      const data = await axios({
        method: 'get',
        url: '/api/portal/prospects/get-google-oauth2-url',
      });
      console.log(data.data);
      if (data.data.success === true) goToPage(data.data.url);
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to get Google Autorize Url',
        type: 'Error',
      });
    }
    setLoading(false);
  };
  const revokeGoogleCredentials = async () => {
    setLoading(true);
    try {
      const data = await axios({
        method: 'get',
        url: '/api/portal/prospects/get-remove-google-oauth',
      });
      if (data.data.success === true) await loadToken();
      console.log(data.data);
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to get Google Autorize Url',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  useEffect(async () => {
    await loadToken();
  }, []);
  useEffect(async () => {
    if (!token) {
      setFiles([]);
      setFilesIO([]);
      setFilesI([]);
      return false;
    }
    setLoadingFiles(true);
    try {
      const data = await axios({
        method: 'get',
        url: '/api/portal/prospects/get-read-google-folders',
      });
      if (data.data.success === true) setFiles(data.data.templates);
      console.log(data.data);
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to get Google Oauth 2 Token',
        type: 'Error',
      });
    }
    setLoadingFiles(false);
    return true;
  }, [token]);
  useEffect(async () => {
    if (!token) return false;
    setLoadingFiles(true);
    try {
      const data = await axios({
        method: 'get',
        url: '/api/portal/prospects/get-google-folder-insertion-order',
      });
      if (data.data.success === true) setFilesIO(data.data.files);
      console.log(data.data);
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to get Google Oauth 2 Token',
        type: 'Error',
      });
    }
    try {
      const data = await axios({
        method: 'get',
        url: '/api/portal/prospects/get-google-folder-invoice',
      });
      if (data.data.success === true) setFilesI(data.data.files);
      console.log(data.data);
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to get Google Oauth 2 Token',
        type: 'Error',
      });
    }
    setLoadingFiles(false);
    return true;
  }, [token]);
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    useFlex: true,
    withPagination: true,
    manualPageSize: [10, 20, 30, 40],
    withSearchEngine: true,
    sortBy: 'name',
    direction: 'desc',
  };

  const columnTransform = useMemo(() => {
    console.log('match');
    return [{
      Header: () => ('File Id'),
      accessor: d => d.id,
      id: 'id',
      width: 400,
      disableSortBy: true,
      disableFilters: true,
    }, {
      Header: 'Name',
      id: 'name',
      accessor: r => (r.name || ''),
      width: 300,
    }, {
      Header: 'Created Date',
      accessor: r => (r.createdTime || ''),
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => moment(value).format('M/D/YY h:mma'),
      width: 200,
    }, {
      Header: 'Last Changed Date',
      accessor: r => (r.modifiedTime || ''),
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => moment(value).format('M/D/YY h:mma'),
      width: 200,
    }, {
      Header: 'Status',
      accessor: r => (r.trashed || ''),
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (value ? 'Removed' : 'Active'),
      width: 200,
    }, {
      Header: 'Link',
      accessor: r => (r.webViewLink || ''),
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<a href={value} target="_blank" rel="noreferrer">Open Google File</a>),
      width: 200,
    }];
  }, [files]);


  return (
    <Container>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3 className="il-color-deep-blue font-weight-bold">Google 2 Oauth</h3>
                </Col>
              </Row>
              <hr className="mt-3 mb-4" />
              <ButtonToolbar className="form__button-toolbar mt-4">
                <ButtonWithSpinner
                  type="button"
                  color="primary"
                  onClick={getGoogleCredentials}
                  loading={loading}
                  disabled={!!token}
                >
                  <span>Autorize</span>
                </ButtonWithSpinner>{' '}
                <ButtonWithSpinner
                  type="button"
                  color="secondary"
                  onClick={revokeGoogleCredentials}
                  loading={loading}
                  disabled={!token}
                >
                  <span>Remove Credentials</span>
                </ButtonWithSpinner>{' '}
              </ButtonToolbar>

              <h4 className=" font-weight-bold">Templates</h4>
              <hr className="mt-3 mb-4" />
              {loading || loadingFiles ? (<div className="text-center"><Spinner /></div>) : null}
              {!loading && files.length > 0 ? (
                <ReactTableBase
                  key="files"
                  columns={columnTransform}
                  data={files}
                  tableConfig={tableConfig}
                />) : null}
              <h4 className=" font-weight-bold">Insertion Order Files</h4>
              <hr className="mt-3 mb-4" />
              {loading || loadingFiles ? (<div className="text-center"><Spinner /></div>) : null}
              {!loading && filesIO.length > 0 ? (
                <ReactTableBase
                  key="filesIO"
                  columns={columnTransform}
                  data={filesIO}
                  tableConfig={tableConfig}
                />) : null}
              <h4 className=" font-weight-bold">Invoice Files</h4>
              <hr className="mt-3 mb-4" />
              {loading || loadingFiles ? (<div className="text-center"><Spinner /></div>) : null}
              {!loading && filesI.length > 0 ? (
                <ReactTableBase
                  key="filesI"
                  columns={columnTransform}
                  data={filesI}
                  tableConfig={tableConfig}
                />) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});


// export default withNotice(connect(mapStateToProps)(PushNotifications));
