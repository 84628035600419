import React, { useEffect } /* , { useMemo } */ from 'react';
import moment from 'moment';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import CheckBoxField from '../../../shared/components/CheckBox';
import RadioButton from '../../../shared/components/RadioButton';
// import Collapse from '../../../shared/components/Collapse';
import { getConstant } from '../../../shared/helpers/WVConstants';


const LastMonthFilter = ({
  startDate, endDate, estimatePrevious, includePrevious, setEstimatePrevious, setIncludePrevious, selectedStatuses, addStatus, /* isByDate, */
}) => {
  const lastMonth = moment().subtract(1, 'month').startOf('month');
  const endMonth = moment().subtract(1, 'month').endOf('month');
  // console.log(moment(startDate).diff(lastMonth, 'day'));
  const includedLastMonth = moment(startDate).diff(lastMonth, 'day') <= 0
    && moment(endDate).diff(endMonth, 'day') >= 0
    && moment(startDate).toDate() < moment().startOf('month').toDate()
    && moment(endDate).toDate() > moment().subtract(1, 'month').startOf('month').toDate();
  const mapStatusArray = getConstant('AP', 'status', {});
  /*  const listMonths = useMemo(() => {
    const list = [];
    if (!isByDate) {
      let i = 0;
      let cMonth;
      const listStatus = selectedStatuses.map(key => mapStatusArray[key]);
      do {
        cMonth = moment(startDate).add(i, 'month');
        if (cMonth >= moment().startOf('month')) {
          list.push((<div key={uid(i)}>{cMonth.format('MMMM YYYY')} uses the HasOffers real-time conversions data to estimate amounts</div>));
        } else if (cMonth < moment().startOf('month') && cMonth >= moment().subtract(1, 'month').startOf('month')) {
          list.push((
            <div key={uid(i)}>{cMonth.format('MMMM YYYY')}{' '}
              {!includePrevious && !estimatePrevious ? `uses the ${cMonth.format('MMMM YYYY')} AP Report (Complete payables)` : ''}
              {includePrevious ? `uses the ${cMonth.format('MMMM YYYY')} AP Report (${listStatus.join(', ')} payables)` : ''}
              {estimatePrevious ? ' uses the HasOffers real-time conversions data to estimate amounts' : ''}
            </div>));
        } else list.push((<div key={uid(i)}>{cMonth.format('MMMM YYYY') } uses the {cMonth.format('MMMM YYYY')} AP Report (Complete payables)</div>));
        i += 1;
      } while (cMonth < moment(endDate).subtract(1, 'month'));
      return list;
    }
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    const startMomentMonth = moment.tz(startDate, 'America/New_York').startOf('month');
    // const endMonth = moment(startMoment).endOf('month');
    let payablesStartDate = null;
    let payablesEndDate = null;
    let additionStartDate = null;
    let additionsEndDate = null;
    let additionDoubleStartDate = null;
    let additionDoubleEndDate = null;
    let usePayables = 0;
    let useAddition = 0;
    let useDoubleAddition = 0;
    if (startMoment.diff(startMomentMonth, 'seconds') === 0) {
      payablesStartDate = startMoment;
      additionStartDate = startMoment;
    } else {
      additionStartDate = startMoment;
      payablesStartDate = moment(startMoment).add(1, 'month').startOf('month');
      useAddition = 1;
    }
    if (payablesStartDate && moment(endMoment).add(1, 'day').diff(moment(payablesStartDate), 'month') > 0) {
      usePayables = 1;
      payablesEndDate = moment(endMoment).add(1, 'day').subtract(1, 'month').endOf('month');
      additionsEndDate = moment(payablesStartDate).subtract(1, 'month').endOf('month');
    } else {
      useAddition = 1;
      additionsEndDate = moment(endMoment);
    }
    if (usePayables && moment.tz('America/New_York').startOf('month') < moment(endMoment)) { // to include time after start of current month
      payablesEndDate = moment(endMoment);
      usePayables = 1;
    }
    if (usePayables && moment(payablesEndDate) < moment(endMoment)) { // to include time after start of current month
      // payablesEndDate = moment(endMoment);
      // usePayables = 1;
      console.log('double eddition');
      useDoubleAddition = 1;
      additionDoubleStartDate = moment(endMoment).startOf('month');
      additionDoubleEndDate = moment(endMoment);
      console.log([additionDoubleStartDate, additionDoubleEndDate]);
    }
    console.log('addition', [additionStartDate, additionsEndDate, useAddition]);
    console.log('payables', [payablesStartDate, payablesEndDate, usePayables]);
    if (useAddition) {
      list.push((<div>{additionStartDate.format('MMMM, DD YYYY')} - {additionsEndDate.format('MMMM, DD YYYY')} uses the HasOffers real-time conversions data to estimate amounts</div>));
    }
    if (usePayables) {
      let i = 0;
      let cMonth;
      const listStatus = selectedStatuses.map(key => mapStatusArray[key]);
      // list.push((<div>{payablesStartDate.format('MMMM, DD YYYY')} - {payablesEndDate.format('MMMM, DD YYYY')} uses the HasOffers real-time conversions data to estimate amounts</div>));
      do {
        cMonth = moment(payablesStartDate).add(i, 'month');
        if (cMonth >= moment().startOf('month')) {
          list.push((<div key={uid(i)}>{cMonth.format('MMMM YYYY')} uses the HasOffers real-time conversions data to estimate amounts</div>));
        } else if (cMonth < moment().startOf('month') && cMonth >= moment().subtract(1, 'month').startOf('month')) {
          list.push((
            <div key={uid(i)}>{cMonth.format('MMMM YYYY')}{' '}
              {!includePrevious && !estimatePrevious ? `uses the ${cMonth.format('MMMM YYYY')} AP Report (Complete payables)` : ''}
              {includePrevious ? `uses the ${cMonth.format('MMMM YYYY')} AP Report (${listStatus.join(', ')} payables)` : ''}
              {estimatePrevious ? ' uses the HasOffers real-time conversions data to estimate amounts' : ''}
            </div>));
        } else list.push((<div key={uid(i)}>{cMonth.format('MMMM YYYY') } uses the {cMonth.format('MMMM YYYY')} AP Report (Complete payables)</div>));
        i += 1;
      } while (cMonth < moment(payablesEndDate).subtract(1, 'month'));
    }
    if (useDoubleAddition) {
      list.push((<div>{additionDoubleStartDate.format('MMMM, DD YYYY')} - {additionDoubleEndDate.format('MMMM, DD YYYY')} uses the HasOffers real-time conversions data to estimate amounts</div>));
    }

    return list;
  }, [startDate, endDate, estimatePrevious, includePrevious, selectedStatuses]); */
  useEffect(() => {
    if (includedLastMonth) {
      setIncludePrevious(false);
      setEstimatePrevious(true);
    } else {
      setIncludePrevious(false);
      setEstimatePrevious(false);
    }
  }, [includedLastMonth]);
  return (
    <Row className={includedLastMonth ? 'mt-3' : null}>
      {includedLastMonth ? (
        <Col lg="12">
          <Label>Choose how you would like to calculate Last Month ({moment().subtract(1, 'month').format('MMMM YYYY')})</Label>
          <FormGroup className="mb-0">
            <RadioButton
              name="action"
              label="Estimate using HasOffers real-time conversion data"
              value={estimatePrevious === true ? 'estimatePrevious' : 'includePrevious'}
              radioValue="estimatePrevious"
              onChange={value => value && (setIncludePrevious(false) || setEstimatePrevious(value === 'estimatePrevious') || console.log(value))}
            />
          </FormGroup>
          <FormGroup>
            <RadioButton
              name="action"
              label={`Estimate using the ${moment().subtract(1, 'month').format('MMMM YYYY')} AP Report, select payable statuses to include:`}
              value={includePrevious === true ? 'includePrevious' : 'estimatePrevious'}
              radioValue="includePrevious"
              onChange={value => value && (setIncludePrevious(value === 'includePrevious') || setEstimatePrevious(false) || console.log(value))}
            />
          </FormGroup>
          {includePrevious ? (
            <Row className="ml-4">
              <hr />
              {['complete', 'approved', 'pending', 'failed'].map(key => (
                <Col xs="auto" key={uid(key)} >
                  <FormGroup className="mb-0 color-text" inline>
                    <CheckBoxField
                      name={key}
                      label={mapStatusArray[key]}
                      value={selectedStatuses.includes(key)}
                      onChange={({ target }) => target && (addStatus(key, target.checked) || console.log(target.checked))}
                    />
                  </FormGroup>
                </Col>
              ))}
              <Col />
            </Row>) : null}
        </Col>) : null
      }
      {/* <Col lg="6">
        <Collapse title="View details about how this report is generated" className="with-shadow boxed mt-3">
          <div className="pb-3">{listMonths}</div>
        </Collapse>
    </Col> */}
    </Row>
  );
};
LastMonthFilter.propTypes = {
  setEstimatePrevious: PropTypes.func.isRequired,
  setIncludePrevious: PropTypes.func.isRequired,
  includePrevious: PropTypes.bool.isRequired,
  estimatePrevious: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  selectedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  addStatus: PropTypes.func.isRequired,
  // isByDate: PropTypes.bool,

};
/* LastMonthReportFilter.defaultProps = {
  isByDate: false,
}; */
export default LastMonthFilter;
