import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '@/shared/components/BreadCrumbs';
import withNotice from '../../../../App/store/with-notice';
import filterProperties from './custom/ActionProperties';
import Hook from '@/shared/hooks';
import NotesModal from './custom/Modal/NotesModal';
import { getIfAdminIsAllowedToEditTaxForm } from '@/redux/reducers/admin/FinanceAdminUserRolecheckReducer';
import TaxFormUploadModal from '@/shared/components/modals/TaxFormUploadModal';

const TaxForm = ({
  history,
  resource,
}) => {
  const dispatch = useDispatch();
  const [searchUrl, setPrefill, loadPage, , useLoadOrDebounce] = Hook.usePrefillHook({
    resourceId: resource.id,
    initialState: {
      sortBy: 'createdAt',
      direction: 'desc',
      page: 1,
      perPage: 10,
    },
  });
  const [taxUser, setTaxUser] = useState({});
  const [taxPerson, setTaxPerson] = useState('creator');
  const [taxFormId, setTaxFormId] = useState('');
  const reload = Hook.useReloadTableHook();

  useEffect(() => {
    dispatch(getIfAdminIsAllowedToEditTaxForm());
    if (1) {
      console.log('Allow Start Load');
      setPrefill(filterProperties({
        // yearSignedFilterTypeOptions: numberRangeLabels(),
      }));
    }
  }, []);

  const setUrl = () => {
    loadPage();
  };
  const downloadCsv = () => {
    // console.log('Download CSV');
    window.open(`/resources/TaxForm/download-csv?${searchUrl}`, '_blank').focus();
  };
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 1000);

  useLoadOrDebounce({
    loadFunc: () => loadPage(), loadDebounceFunc: () => setUrlDebounced(), setSearchUrl: () => history.push(`${history.location.pathname}?${searchUrl}`)
  });

  // console.log(searchUrl, 'SEARCH');
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Finance', path: null },
          { title: 'Tax Form', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Tax Form Management</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              {searchUrl !== null ? <Actions
                downloadCsv={downloadCsv}
              /> : null}
              <Row style={{ marginTop: '-10px' }}>
                {searchUrl !== null ? <Table setTaxUser={setTaxUser} setTaxPerson={setTaxPerson} setTaxFormId={setTaxFormId} /> : null}
              </Row>
            </Col>
          </Row>
          <TaxFormUploadModal person={taxPerson} record={taxUser} updateData={reload} taxFormId={taxFormId} />
        </CardBody>
      </Card>
      <NotesModal />
    </Container>
  );
};
TaxForm.propTypes = {
  addNotice: PropTypes.func.isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withNotice(withRouter(TaxForm));
