import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import mapMediaTypeIcons from '../../MediaContent/components/custom/mapMediaTypeIcons';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
  },
  scoredBy: {
    marginTop: 4,
  },
  mainBox: {
    marginTop: 20,
  },
  grid: {
    border: 'solid 1px',
    borderColor: '#70727f',
    borderRadius: 5,
  },
  gridItems: {
    margin: 'auto',
  },
}));

const mapType = mapMediaTypeIcons();

const MediaContentDetails = ({
  title, mediaContent, scoredBy,
}) => {
  const classes = useStyles();
  let dealTitle = '';
  const { type } = mediaContent;

  if (mediaContent && mediaContent.deal && mediaContent.deal.dealTerms) {
    const { dealTerms } = mediaContent.deal;
    if (dealTerms.type_of_deal) {
      dealTitle = dealTerms.type_of_deal;
    }
    if (dealTerms.cpa_percentage && dealTitle.length > 0) {
      dealTitle.concat(', ', dealTerms.cpa_percentage);
    } else if (dealTerms.cpa_percentage) {
      dealTitle.concat(dealTerms.cpa_percentage);
    }
    if (dealTerms.guaranteed_payment_contract_amount && dealTitle.length > 0) {
      dealTitle.concat(', ', dealTerms.guaranteed_payment_contract_amount);
    } else if (dealTerms.guaranteed_payment_contract_amount) {
      dealTitle.concat(dealTerms.guaranteed_payment_contract_amount);
    }
  }
  if (dealTitle.length === 0 && mediaContent.deal && mediaContent.deal.dealname) {
    dealTitle = mediaContent.deal.dealname;
  }
  return (
    <>
      <Row className="justify-content-center">
        <Col sm={2} style={{ marginLeft: 5 }}>
          <img
            style={{
              width: '30px',
              float: 'left',
              marginTop: '4px',
              marginRight: '10px',
            }}
            src={`/images/mediacontent/${mapType[type]}.png`}
            alt={mediaContent.type}
            title={mediaContent.type}
          />
          <h4 className={classes.scoredBy}>{mediaContent.type}</h4>
        </Col>
        <Col sm={2}><h3 className={classes.title}>{title}</h3></Col>
        <Col sm={3} style={{ textAlign: 'end' }}><h4 className={classes.scoredBy}>{`Scored By: ${scoredBy}`}</h4></Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm={7} className={classes.mainBox}>
          <Grid container spacing={1} className={classes.grid}>
            <Grid item xs={2}><Typography gutterBottom variant="subtitle2">CSP</Typography></Grid>
            <Grid item xs={9}>
              <Typography gutterBottom variant="body2">
                {`${mediaContent.submitterAdminUserId?.firstName} ${mediaContent.submitterAdminUserId?.lastName}`}
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={2}><Typography gutterBottom variant="subtitle2">Brand</Typography></Grid>
            <Grid item xs={9}>
              <Typography gutterBottom variant="body2">
                {mediaContent.advertiser.companyName}
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={2}><Typography gutterBottom variant="subtitle2">Creator</Typography></Grid>
            <Grid item xs={9}>
              <Typography gutterBottom variant="body2">
                {`${mediaContent.influencer.first_name} ${mediaContent.influencer.last_name}`}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://influencelogic.hasoffers.com/admin/affiliates/view/${mediaContent.influencer.affiliateData ? mediaContent.influencer.affiliateData.tune_id : 0}`}
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </Grid>
            {mediaContent.type !== 'Text' && (
              <Grid item xs={2}>
                <Typography gutterBottom variant="subtitle2">
                  {`${mediaContent.type} URL`}
                </Typography>
              </Grid>
            )}
            {mediaContent.type !== 'Text' && (
              <Grid item xs={9}>
                <Typography gutterBottom variant="body2">
                  {mediaContent.mediaUrl}
                </Typography>
              </Grid>
            )}
            {mediaContent.type !== 'Text' && (
              <Grid item xs={1}>
                {mediaContent.mediaUrl &&
                (<a target="_blank" rel="noopener noreferrer" href={mediaContent.mediaUrl}><i className="fas fa-external-link-alt" /></a>)
                }
              </Grid>
            )}
            {(mediaContent.type === 'TikTok') && (
              <>
                <Grid item xs={2}>
                  <Typography gutterBottom variant="subtitle2">
                    TikTok Profile
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography gutterBottom variant="body2">
                    {mediaContent.tikTokProfileUrl}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {mediaContent.tikTokProfileUrl &&
              (<a target="_blank" rel="noopener noreferrer" href={mediaContent.tikTokProfileUrl}><i className="fas fa-external-link-alt" /></a>)
              }
                </Grid>
              </>
            )}
            <Grid item xs={2}><Typography gutterBottom variant="subtitle2">Deal</Typography></Grid>
            <Grid item xs={9}>
              <Typography gutterBottom variant="body2">
                {dealTitle}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {mediaContent.deal && mediaContent.deal.hs_object_id && (
                <a target="_blank" rel="noopener noreferrer" href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${mediaContent.deal.hs_object_id}`}>
                  <i className="fas fa-external-link-alt" />
                </a>
              )}
            </Grid>
            <Grid item xs={2}><Typography gutterBottom variant="subtitle2">Notes</Typography></Grid>
            <Grid item xs={10}>
              <Typography gutterBottom variant="body2" dangerouslySetInnerHTML={{ __html: mediaContent.notes !== 'null' ? mediaContent.notes : '' }} />
            </Grid>
          </Grid>
        </Col>
      </Row>
    </>
  );
};

MediaContentDetails.propTypes = {
  mediaContent: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  scoredBy: PropTypes.string,
};

MediaContentDetails.defaultProps = {
  scoredBy: null,
};

export default MediaContentDetails;
