import React, { useState } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Row, Col, Button, Container, Card, CardBody, CardTitle } from 'reactstrap';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../App/store/with-notice';
import LastMonthFilter from '../LastMonthFilter';
import GenerationInfo from '../GenerationInfo';
import GenerateTable from './GenerateTable';
import Errors from '../Errors';
import SaveReport from '../SaveReport';
import PermissionComponent from '../../../../shared/components/PermissionComponent';
import { CheckPermission } from '../../../../shared/helpers/WVUtilities';

const SummaryReport = ({ addNotice }) => {
  
  const [loading, setLoaing] = useState(false);
  const [variable12, setVariable12] = useState(12);
  const [items, setItems] = useState([]);
  const [errors, setErrors] = useState([]);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [estimatePrevious, setEstimatePrevious] = useState(false);
  const [includePrevious, setIncludePrevious] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState(['complete']);

  const [saveReport, setSaveReport] = useState(false);
  const [saveReportModal, setSaveReportModal] = useState(false);
  const [savedReportModal, setSavedReportModal] = useState(false);


  const fetchAmountReport = async () => {
    setLoaing(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-fetch-all-deals', {
        startDate: moment('2020-08-01').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        identity,
        estimatePrevious,
        includePrevious,
        selectedStatuses,
      });
      if (resp.data.success) {
        setLoaing(false);
        setVariable12(Number(resp.data.variable12));
        setItems(resp.data.items);
        setErrors(resp.data.notUsedConversions);
        // setVersion((new Date()).toString());
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  const saveReportToSave = async (name, admins) => {
    setLoaing(true);
    // setError(null);
    setItems([]);
    try {
      const resp = await axios.post('/api/portal/reports/post-save-ltv-report', {
        startDate: moment('2020-08-01').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        identity,
        estimatePrevious,
        includePrevious,
        selectedStatuses,
        name,
        admins,
      });
      if (resp.data.success) {
        setLoaing(false);
        setSaveReportModal(false);
        setSavedReportModal(true);
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  if (!CheckPermission('reports----ltvreport')) return (<PermissionComponent />);
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Creator Lifetime Value Report', path: false },
            ]}
          />
        </Col>
      </Row>
      {!loading && items.length > 0 && errors.length > 0 ? (
        <Errors errors={errors} />
      ) : null }
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Creator Lifetime Value Report</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3" >
                <Col >
                  <h5 style={{ color: 'black' }} className="font-weight-bold">{`${moment('2020-08-01').startOf('month').format('MMM D, YYYY')} - ${moment().subtract(1, 'month').endOf('month').format('MMM D, YYYY')}`} (ET)</h5>
                </Col>
                <Col lg="auto">
                  <div className="mt-2">
                    <SaveReport
                      startDate={moment('2020-08-01').startOf('month').format('YYYY-MM-DD')}
                      endDate={moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}
                      save={(name, admins) => saveReportToSave(name, admins)}
                      saveReportModal={saveReportModal}
                      savedReportModal={savedReportModal}
                      setSaveReportModal={setSaveReportModal}
                      setSavedReportModal={setSavedReportModal}
                      saveReport={saveReport}
                      setSaveReport={setSaveReport}
                      keyPermission="reports----ltvreport"
                      type="ltvReport"
                    />
                  </div>
                </Col>
                <Col lg="auto">
                  <div>
                    <Button
                      color="primary"
                      onClick={async () => {
                        if (saveReport) {
                          setSaveReportModal(true);
                          return false;
                        }
                        await fetchAmountReport();
                        return false;
                      }}
                      className="mt-0 mr-2 btn-sm"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                    <GenerationInfo
                      estimatePrevious={estimatePrevious}
                      includePrevious={includePrevious}
                      startDate={moment('2020-08-01').startOf('month').format('YYYY-MM-DD')}
                      endDate={moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}
                      selectedStatuses={selectedStatuses}
                      isByDate={false}
                    />
                  </div>
                </Col>
              </Row>
              <LastMonthFilter
                setEstimatePrevious={setEstimatePrevious}
                setIncludePrevious={setIncludePrevious}
                estimatePrevious={estimatePrevious}
                includePrevious={includePrevious}
                startDate={moment('2020-08-01').startOf('month').format('YYYY-MM-DD')}
                endDate={moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}
                selectedStatuses={selectedStatuses}
                addStatus={(key, isChecked) => {
                  const status = [...selectedStatuses];
                  if (isChecked === true) {
                    status.push(key);
                  } else {
                    const index = status.indexOf(key);
                    if (index > -1) status.splice(index, 1);
                  }
                  setSelectedStatuses(status);
                }}
              />
              <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    identity={identity}
                    topic="report"
                    isShow={!!loading}
                  />
                </Col>
              </Row>
              <GenerateTable
                items={items}
                loading={loading}
                variable12={variable12}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
