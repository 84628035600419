import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { unflatten } from 'flat';
import Select from 'react-select';
import { updateResourceData } from '../../../helpers/WVRequest';

const SelectResource = ({
  resource,
  record,
  property,
  options,
  placeholder,
  fetching,
}) => {
  const row = unflatten(record);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const onChange = (option) => {
    setValue(option);
    setLoading(true);
    updateResourceData(resource, row.id, { [property]: option?.value || '' }).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setValue(row?.params?.[property] ? options.filter(o => o.value === row.params[property]) : null);
  }, [record, options]);

  useEffect(() => {
    setLoading(fetching);
  }, [fetching]);

  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      className="mb-3"
      isDisabled={loading}
      isClearable
      menuPlacement="auto"
      menuPortalTarget={document.body}
    />
  );
};

SelectResource.propTypes = {
  resource: PropTypes.string.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  property: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string,
  fetching: PropTypes.bool,
};

SelectResource.defaultProps = {
  placeholder: '',
  fetching: false,
};

export default SelectResource;
