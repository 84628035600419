import { Button, ButtonToolbar, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ApiClient from 'ApiClient';


const ViewRecyclingHistoryModal = ({
  isOpen,
  closeFunc,
  contact,
}) => {
  const [loading, setLoading] = useState(true);

  const fetchAdminUsers = () => {
    const api = new ApiClient();
    setLoading(true);
    const promises = contact.recycling.map(historyItem => api.recordAction({
      resourceId: 'AdminUser',
      recordId: historyItem.adminUser,
      actionName: 'show',
    }));
    Promise.all(promises)
      .then((results) => {
        const newRecyclingHistory = [];
        contact.recycling.forEach((recyclingItem) => {
          results.forEach((adminUser) => {
            if (adminUser.data.record.id === recyclingItem.adminUser) {
              // eslint-disable-next-line no-param-reassign
              recyclingItem.adminUserName = `${adminUser.data.record.params.firstName} ${adminUser.data.record.params.lastName}`;
            }
          });
          newRecyclingHistory.push(recyclingItem);
        });
        // eslint-disable-next-line no-param-reassign
        contact.recycling = newRecyclingHistory;
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      fetchAdminUsers();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '800px' }}
    >
      <ModalHeader className="modal-header-bold" style={{ padding: 0 }} toggle={() => closeFunc()} tag="h4">Recycle History</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {!loading ? (
              <>
                <span><b>{contact.firstname} {contact.lastname}</b></span>
                <hr style={{ marginTop: '0.1rem' }} />
                {contact.recycling.map(historyItem => (
                  <div key={`history${historyItem._id}`}>
                    Recycled on {moment(historyItem.date).format('MMM D, YYYY')} by {historyItem.adminUserName || ' Admin User'}
                  </div>
                ))}
              </>
            ) : (
              <div className="text-center"><div className="spinner-border spinner-border-sm" /></div>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" onClick={() => closeFunc()}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

ViewRecyclingHistoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  contact: PropTypes.objectOf(PropTypes.any).isRequired,
};


export default ViewRecyclingHistoryModal;
