import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import Alert from '../../../../shared/easydev2/shared/components/Alert';
import formater from '../../../../shared/helpers/WVFormatter';

const getWarningMessageArray = (validationResult) => {
  let message = '';
  let url = 'Something wrong';
  if (!validationResult.creator) {
    message = 'Missing Creator for Conversions';
    url = null;
  }
  if (message === '' && !validationResult.creator.category) {
    message = 'Missing Category for Creator';
    url = `/influencer/profile/${validationResult.creator._id}/`;
  }
  if (message === '') message = validationResult?.error || '';
  return [message, url];
  // return (<span>{message} {url ? (<a href={url} target="_blank" rel="noreferrer">Click here to correct issue</a>) : null } {validationResult.offerName ? `Offer Name: ${validationResult.offerName}` : null}</span>);
};

const getWarningMessage = (validationResult) => {
  const [message, url] = getWarningMessageArray(validationResult);
  return (<span>{message} {url ? (<a href={url} target="_blank" rel="noreferrer">Click here to correct issue</a>) : null }</span>);
};

const Errors = ({ errors }) => {
  const errCount = errors?.length || 0;
  const [errModal, displayErrModal] = useState(false);
  const [cspOptions, setCspOptions] = useState([]);
  const [selectedCsps, setSelectedCsps] = useState(null);
  const isCspSelected = csp => console.log(selectedCsps, csp) || !(selectedCsps && selectedCsps.length) || (csp && (selectedCsps.map(row => row.value).indexOf(csp) > -1));

  const columnTransform = {
    message: {
      valueFunc: e => (
        <div>
          <p className="py-1 text-dark">
            <div>
              <strong>Creator Owner: </strong>
              {e.admin ? (<span>{e.admin.firstName} {e.admin.lastName}</span>) : <span>-</span>}
            </div>
            <div>
              <strong>Creator: </strong>
              {e.creator ? (
                <a href={`/influencer/profile/${e.creator._id}/`} target="_blank" rel="noreferrer">
                  {`${e.creator?.first_name || ''} ${e.creator?.last_name || ''}`}
                </a>) : null}
            </div>
            <div>{e.error ? (<span>{e.error} {e.dealId ? (<a target="_blank" rel="noreferrer" href={`/influencers/creator-deal-editor/${e.dealId}`}>Open Deal</a>) : null}</span>) : getWarningMessage(e)}</div>
            <div>
              <strong>Conversions Count: </strong>
              {formater.formatIntNumber(e.conversionsCount)}
            </div>
            <div>
              <strong>Promo Code: </strong>
              {(e.promoCode || '-')}
            </div>
          </p>
          <hr className="my-1" />
        </div>
      ),
    },
  };

  useEffect(async () => {
    try {
      const resp = await axios.get('/api/portal/finance/get-deal-owners');
      const admins = resp.data.dealOwners ? resp.data.dealOwners.map(a => ({ value: `${a._id}`, label: `${a.firstName} ${a.lastName}` })) : [];
      setCspOptions(admins);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const filteredItems = useMemo(() => errors.filter(item => isCspSelected(item.admin?._id)), [selectedCsps, errors]);

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {(errCount > 0) && (
                <Alert color="warning">
                  <p className="py-2 text-dark">
                    {errCount} messages need your attention that may affect the current page performance.&nbsp;
                    <button
                      onClick={() => { displayErrModal(true); }}
                      className="link"
                    >
                      See list
                    </button>
                  </p>
                </Alert>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={errModal}
        toggle={() => displayErrModal(false)}
      >
        <ModalHeader toggle={() => displayErrModal(false)} tag="h4">
          Creators Requiring Attention
        </ModalHeader>
        {/* eslint-disable-next-line react/no-danger */}
        <ModalBody className="table-scrollable no-cell-border pb-0">
          <Row>
            <Col>
              <div>These creators need to be confirmed and possibly fixed.</div>
              <div className="float-right mr-2 mt-0">
                <DownloadCSVButton
                  records={filteredItems}
                  className="mt-0"
                  fileName="issues.csv"
                  csvArrayColumns={[
                    {
                      label: 'Creator Name',
                      func: p => (p.creator ? `${p.creator?.first_name || ''} ${p.creator?.last_name || ''}` : null),
                    },
                    {
                      label: 'Creator Owner',
                      func: e => (e.admin ? `${e.admin?.firstName || ''} ${e.admin?.lastName || ''}` : '-'),
                    },
                    {
                      label: 'Conversions',
                      func: p => Number(p?.conversionsCount || 0),
                    },
                    {
                      label: 'PromoCode',
                      func: p => String(p?.promoCode || ''),
                    },
                    {
                      label: 'Message',
                      func: (p) => {
                        const [message] = getWarningMessageArray(p);
                        return p.error ? p.error : message;
                      },
                    },
                    {
                      label: 'Link to Contact',
                      func: p => (p?.creator?.hsContactVid ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.creator?.hsContactVid}/` : ''),
                    },
                ]}
                >
                  Download CSV
                </DownloadCSVButton>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
              <Select
                isMulti
                value={selectedCsps}
                options={cspOptions}
                onChange={value => setSelectedCsps(value)}
                placeholder="Select Deal Owner..."
                isClearable
              />
            </Col>
          </Row>
          <MatTableForRedux
            dataArray={filteredItems}
            selectable={false}
            columns={columnTransform}
            version={(new Date()).toString()}
            rowsPerPage={10}
            hideToolbar
            hideHead
          />
        </ModalBody>
      </Modal>
    </>
  );
};

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
export default Errors;
