import React from 'react';
import { Container, Row, Col } from 'reactstrap';
// import CreatorContentListContainer from './components/CreatorContentListContainer';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import CreatorsListContainer from './components/CreatorsListContainer';

const AgentCreatorsList = () => (
  <Container className="dashboard">
    <Breadcrumb
      links={[
        { title: 'Home', path: '/' },
        { title: 'Agents', path: '/resources/AgentUser/actions/list' },
        { title: 'Creators', path: null },
      ]}
      isBackButton
    />
    <Row>
      <Col md={12}>
        <CreatorsListContainer />
      </Col>
    </Row>
  </Container>
);

export default AgentCreatorsList;
