import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonToolbar, Modal, Button, ModalHeader, Col, Row, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import Select from 'react-select';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { hideModalById, setState } from '../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../App/store/with-notice';


export const modalId = 'CPASettingsModal';

const PeriodItems = {
  15: {
    start: 1, end: 15,
  },
  30: {
    start: 16, end: 30,
  },
  60: {
    start: 31, end: 60,
  },
  90: {
    start: 61, end: 90,
  },
  120: {
    start: 91, end: 120,
  },
  9999: {
    start: 121, end: 9999,
  },
};
const ItemElem = ({
  item, setValue,
}) => {
  const {
    start, end, value,
  } = item;
  const onChange = (v) => {
    setValue(v);
    console.log('RUN twice', v);
  };

  return (
    <Row className="align-items-center mt-3">
      <Col sm="auto" className="">
        <Label className="mb-0">{`Days ${start} ${start === 121 ? '+' : `- ${end}`}`}</Label>
      </Col>
      <Col>
        <NumberFormat
          thousandSeparator
          name="CPA"
          value={value}
          className="form-control"
          inputMode="numeric"
          fixedDecimalScale
          decimalScale={0}
          onValueChange={(values) => {
            onChange(values.floatValue);
          }}
          isNumericString
        />
      </Col>
    </Row>
  );
};


ItemElem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired,
};

const InfoModal = () => {
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const [settings, setSettings] = useState({ brands: {} });
  const [loading, setLoading] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const dispatch = useDispatch();
  const fetchSettingsFromDictionary = async () => {
    try {
      const resp = await axios.get('/api/portal/reports/get-cpa-rebuy-settings');
      setSettings(resp.data.settings || { brands: {} });
      setState();
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (modalInfo) {
      fetchSettingsFromDictionary();
    }
  }, [modalInfo]);

  useEffect(() => {
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      // setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : -1)));
    }).catch(() => {
    });
  }, []);

  const advertiserOptions = useMemo(() => advertisers.filter(b => b.displayInUi === 'Yes').map(a => ({ label: a.companyName, value: a._id })), [advertisers]);

  useEffect(() => {
    if (advertiserOptions.length > 0 && !selectedAdvertiser) {
      setSelectedAdvertiser(advertiserOptions[0]);
    }
  }, [advertiserOptions]);

  const close = () => {
    dispatch(hideModalById(modalId));
  };
  const saveSettings = async () => {
    try {
      setLoading(true);
      await axios.post('/api/portal/reports/post-save-cpa-rebuy-settings', { settings });
      close();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const list = useMemo(() => {
    if (!settings?.brands) return [];
    const listArray = [];
    Object.keys(PeriodItems).forEach((days) => {
      // console.log('ITEM-----', settings.brands[selectedAdvertiser?.value]?.items[days]?.value);
      listArray.push({ ...PeriodItems[days], key: days, value: settings.brands[selectedAdvertiser?.value]?.items[days]?.value || 0 });
    });
    return listArray;
  }, [selectedAdvertiser, settings]);

  const setValue = (index, value) => {
    const brandSettings = { ...(settings?.brands[selectedAdvertiser?.value]?.items || {}) };
    const itemk = PeriodItems[index];
    brandSettings[index] = {
      value, key: index, ...itemk,
    };
    // console.log('FINALSettings', brandSettings);
    setSettings({
      brands: {
        ...settings.brands,
        [selectedAdvertiser.value]: {
          items: brandSettings,
        },
      },
    });
  };

  if (!modalInfo) return false;
  const { isShow: showUploadModal } = modalInfo;

  return (
    <Modal
      isOpen={showUploadModal}
      toggle={close}
      size="lg"
    >
      <ModalHeader toggle={() => close()} tag="h3">
        CPA Settings
      </ModalHeader>
      <div className="modal__body ltr-support px-3 pt-3">
        <Row className="align-items-center mb-3">
          <Col>
            <Label className="my-0">Brand</Label>
            <Select
              value={selectedAdvertiser}
              options={advertiserOptions}
              onChange={setSelectedAdvertiser}
              placeholder="Select Brand..."
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  height: '38px',
                  fontSize: '16px',
                }),
                menu: baseStyles => ({
                  ...baseStyles,
                  fontSize: '14px',
                }),
              }}
            />
          </Col>
        </Row>
        <h4 className="font-weight-bold mt-3">Number of conversions</h4>
        <hr />
        <Row>
          {list.map(item => (<Col sm={6} key={item.start}><ItemElem item={{ ...item }} setValue={e => setValue(item.key, e)} /></Col>))}
        </Row>
      </div>
      <ButtonToolbar className="modal__footer">
        <Button color="primary" onClick={saveSettings} disabled={loading} className="mr-2">Save</Button>
        <Button color="secondary" onClick={close} disabled={loading}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

export default withNotice(InfoModal);
