import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';

const NameInList = ({ record }) => {
  const recortUnflatten = unflatten(record);
  const {
    name, hubilDeal, title, mediaUrl,
  } = recortUnflatten.params;
  // let icon = '';
  const hsLink = typeof hubilDeal !== 'undefined' && typeof hubilDeal[0] !== 'undefined' ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${hubilDeal[0].hs_object_id}` : false;

  return (
    <>
      <span>
        <strong>{hsLink ? (<a href={hsLink} target="_blank" rel="noreferrer" >{name}</a>) : name}</strong>
        <div>
          <a href={mediaUrl} target="_blank" rel="noreferrer" >{title || ''}</a>
        </div>
        &nbsp;
      </span>
    </>
  );
};
NameInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default NameInList;

