import { useState, useEffect } from 'react';
import { axios } from 'ApiClient';

function useMediaContent(influencers, shouldFetch, setShouldFetch) {
  const [content, setContent] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (influencers.length > 0 && shouldFetch) {
        setShouldFetch(false);
        const idSet = new Set();
        for (let i = 0; i < influencers.length; i += 1) {
          idSet.add(influencers[i]);
        }
        axios({
          method: 'get',
          url: `/api/portal/get-creator-content?influencerId=${[...idSet]}&filterCompliant=true`,
        }).then((response) => {
          if (response?.data?.success && response.data?.records) {
            setContent(response.data.records);
          }
        });
      }
    }

    fetchData();
  }, [influencers]);

  return content;
}

export default useMediaContent;
