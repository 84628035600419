/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { axios } from 'ApiClient';
import Tooltip from '@material-ui/core/Tooltip';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
// import queryHasFilter from '@/shared/ViewHelpers/QueryHasFilter';
import ActionHeader from '../../../../shared/components/components/app/action-header';
// import Filter from '../../../../shared/components/components/app/filter';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
// import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
// import UserPropertyType from './components/custom';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import InfoModal from './InfoModal';

const ResourceAction = (props) => {
  const {
    location,
    resources,
  } = props;
 
  const [clickStats, setClickStats] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    axios.get('/api/portal/global/get-stats-categories').then((res) => {
      setItems(res.data.stats);
      setLoading(false);
    }).catch((error) => {
      console.error(error);
    });
  }, [location]);

  const columnTransform = {
    name: {
      title: 'Name',
      valueFunc: record => (`${record._id || ''}`),
      sort: record => (`${record._id || ''}`),
      searchFunc: (record, search) => (`${record._id || ''}`.toLowerCase().indexOf(search.toLowerCase()) !== -1),
    },
    count: {
      title: 'Stats Count',
      valueFunc: record => (record.statsCount || ''),
      sort: record => (record.statsCount || ''),
    },
    actions: {
      title: ' ',
      valueFunc: record => (
        <div className="text-right">
          <Tooltip title="Deal Information">
            <button
              className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall "
              onClick={() => setClickStats(record._id)}
            >
              <i className="fa fa-info-circle" />
            </button>
          </Tooltip>
          {' '}
          <InfoModal
            isOpen={clickStats && clickStats === record._id}
            record={record}
            toggleModal={() => setClickStats(null)}
          />
        </div>
      ),
      sort: () => 1,
    },
  };
  const resource = resources.find(r => r.id === 'Statistic');
  if (!resource) {
    return (<NoResourceError resourceId="Statistic" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="Statistic" actionName="list" />);
  }
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs resource={resource} actionName="list" />
          <ActionHeader
            resource={resource}
            action={action}
            // tag={tag}
            // toggleFilter={action.showFilter ? () => setFilerVisible(!filterVisible) : undefined}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Row>
            <Card>
              <CardBody>
                {!loading ? (
                  <MatTableForRedux
                    dataArray={items}
                    selectable={false}
                    columns={columnTransform}
                    version={(new Date()).toString()}
                    defaultOrder={{
                      order: 'desc',
                      orderBy: 'name',
                    }}
                    rowsPerPage={10}
                    // csvDownload={{
                    //  fileName: 'BillComConnectedAccounts.csv',
                    //  csvArrayColumns,
                    // }}
                  />
                  ) : null }
              </CardBody>
            </Card>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
