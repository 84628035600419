import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ContextMenuInList from './ContextMenuInList';
import { getCreators } from '../../../../../redux/reducers/admin/AgentReducer';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import CopyToClipboard from '../../../../../shared/components/CopyToClipboard';
import EnablePaymentSelect from './PayeeSelect';
import PaymentMethods from '../../AgentCreators/components/PaymentMethods';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';

const CreatorsList = ({
  match,
  dispatch,
  loading,
  creators,
  currentAgent,
}) => {
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    useFlex: true,
  };
  const getData = (agentId) => {
    if (match) {
      dispatch(getCreators(agentId));
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: p => `${p?.first_name || ''} ${p?.last_name || ''}`,
        Cell: ({ value }) => value,
        width: 300,
      },
      {
        Header: 'Email',
        accessor: p => p?.email || '',
        Cell: p => useMemo(() => (<CopyToClipboard value={p.row.original.email}>{p.row.original.email}</CopyToClipboard>), [p.row.original]),
        width: 400,
      },
      {
        Header: 'Pay Creator or Agent?',
        accessor: () => {},
        Cell: p => useMemo(() => (<EnablePaymentSelect record={p.row.original} />), [p.row.original]),
        disableGlobalFilter: true,
        width: 240,
      },
      {
        Header: 'Payment Methods',
        accessor: () => {},
        Cell: p => useMemo(() => (<PaymentMethods record={p.row.original} />), [p.row.original]),
        disableGlobalFilter: true,
        width: 150,
      },
      {
        Header: '',
        id: 'actions',
        accessor: () => {},
        Cell: p => useMemo(() => (
          <ContextMenuInList
            influencer={p.row.original}
            meta={{ closeFunction: () => getData(match.params.agentId) }}
          />
        ), [p.row.original]),
        disableSortBy: true,
        className: 'align-self-center',
        width: 30,
        maxWidth: 30,
      },
    ],
    [],
  );

  useEffect(() => {
    if (match.params && match.params.agentId) {
      getData(match.params.agentId);
    }
  }, []);

  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Creators</h2>
      <Card>
        <CardBody>
          <Row>
            <Col>
              {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
              {!loading && creators.length > 0 && (
                <ReactTableBase
                  key="searchable"
                  columns={columns}
                  data={creators}
                  tableConfig={tableConfig}
                />
              )}
            </Col>
          </Row>
          {!loading && creators.length === 0 && (
            <Alert color="warning">
              <p className="py-2 text-dark">
                {`${currentAgent.first_name} ${currentAgent.last_name} currently has no creators yet.`}
              </p>
            </Alert>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
CreatorsList.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  currentAgent: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  creators: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  dispatch: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  loading: state.AgentReducer.loading.creators,
  creators: state.AgentReducer.creators,
  currentAgent: state.AgentReducer.currentAgent,
});

export default connect(mapStateToProps)(withRouter(CreatorsList));
