/* eslint react/prop-types: 0 */
import React from 'react';

import PropertyHeader from './property-header';
import Th from './styled/th.styled';


/**
 * Prints `thead` section for table with records.
 *
 * @component
 * @example <caption>List with 2 properties</caption>
 * const properties = [{
 *   label: 'First Name',
 *   name: 'firstName',
 *   isSortable: true,
 * }, {
 *   label: 'Last Name',
 *   name: 'lastName',
 * }]
 * return (
 * <WrapperBox border>
 *   <Table>
 *    <RecordsTableHeader
 *      properties={properties}
 *      titleProperty={properties[0]}
 *      sortBy={'firstName'}
 *      direction={'asc'}
 *    />
 *    <tbody><tr>
 *      <td>John</td>
 *      <td>Doe</td>
 *      <td><StyledButton>Do something with John</StyledButton></td>
 *    </tr></tbody>
 *   </Table>
 * </WrapperBox>
 * )
 */
const RecordsTableHeader = (props) => {
  const {
    titleProperty, properties,
    sortBy, direction,
    onSelectAll, selectedAll,
  } = props;
  return (
    <thead>
      <tr key="header">
        {onSelectAll ? (
          <Th>
            <input
              style={{ marginLeft: 5 }}
              type="checkbox"
              onChange={() => onSelectAll()}
              checked={selectedAll}
            />
          </Th>
        ) : null}
        {properties.map(property => (
          <PropertyHeader
            key={property.name}
            titleProperty={titleProperty}
            property={property}
            sortBy={sortBy}
            direction={direction}
          />
        ))}
        <th key="actions" style={{ width: 80 }} />
      </tr>
    </thead>
  );
};

export default RecordsTableHeader;
