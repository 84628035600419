
import func from '../../../../../../shared/hooks';

const ActionProperties = () => [
  {
    initialState: {
      owner: true,
      auditor: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'role',
  },

];

export default ActionProperties;
