import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { axios } from 'ApiClient';
import {
  FormGroup, Label, Input, FormFeedback, InputGroup, InputGroupAddon, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import EyeIcon from 'mdi-react/EyeIcon';
import { Redirect } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import Alert from '../../../../shared/easydev2/shared/components/Alert';
import WVValidator from '../../../../shared/helpers/WVValidator';
import config from '../../../../shared/config/config';

const recaptchaRef = React.createRef();

class LogInForm extends PureComponent {
  constructor() {
    super();

    this.state = {
      showPassword: false,
      email: '',
      password: '',
      rememberMe: true,
      recaptchaToken: '',
      errors: {},
      isLoadingData: false,
      userHasLogined: false,
    };
  }

  componentDidMount() {
    document.title = 'IL Admin: Login';
  }

  onDataChanged = (event) => {
    const { name, value } = event.target;
    const state = {
      ...this.state,
      [name]: value,
    };

    delete state.errors[name]; // clear errors

    this.setState(state);
  }

  onRememberMeChanged = () => {
    this.setState({
      rememberMe: !this.state.rememberMe,
    });
  }

  onRecaptchaChange = (value) => {
    this.setState({
      recaptchaToken: value,
    });
  }

  showPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleSubmitError(error) {
    recaptchaRef.current.reset();

    const state = {
      ...this.state,
      isLoadingData: false,
      recaptchaToken: '',
    };

    state.errors.global = error;

    this.setState(state);
  }

  validateEmail = () => {
    const errors = {};
    const err = WVValidator.emailError(this.state.email);
    if (err) {
      errors.email = err;
    }
    this.setState({
      ...this.state,
      errors,
    });
    return Object.entries(errors).length === 0;
  };

  validateErrors() {
    const errors = {};
    const urlParams = new URLSearchParams(window.location.search);
    const testParam = urlParams.get('inTestMode');

    const err = WVValidator.emailError(this.state.email);

    if (err) {
      errors.email = err;
    }
    if (WVValidator.isEmpty(this.state.password)) {
      errors.password = 'Please enter your password.';
    }
    if (WVValidator.isEmpty(this.state.recaptchaToken) && !WVValidator.validateTestModeRecaptchaToken(testParam, window.process.env.NODE_ENV)) {
      errors.global = 'Please verify that you are not a robot.';
    }
    this.setState({
      ...this.state,
      errors,
    });

    return Object.entries(errors).length === 0;
  }

  submitForm = () => {
    if (this.state.isLoadingData) { return false; }
    if (!this.validateErrors()) { return false; }

    this.setState({
      isLoadingData: true,
    });

    const urlParams = new URLSearchParams(window.location.search);
    const testParam = urlParams.get('inTestMode');

    axios({
      method: 'post',
      url: '/api/portal/login',
      data: {
        email: this.state.email,
        password: this.state.password,
        rememberMe: this.state.rememberMe,
        recaptchaToken: this.state.recaptchaToken,
        inTestMode: testParam,
      },
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);

      if (response.data.success === true) {
        this.props.setConnection(response.data.user);
        /* this.setState({
          ['userHasLogined'] : true
        }) */
      } else {
        this.handleSubmitError(response.data.error);
      }
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
      this.handleSubmitError(error.message);
    });
    return null;
  }

  render() {
    return (
      <section style={{ maxWidth: '330px' }}>
        <h4>Login</h4>
        {this.state.userHasLogined && <Redirect to="/" />}
        <FormGroup>
          <Label for="emailInput">Email</Label>
          <Input
            name="email"
            type="email"
            bsSize="lg"
            invalid={Object.prototype.hasOwnProperty.call(this.state.errors, 'email')}
            onChange={this.onDataChanged}
            onBlur={this.validateEmail}
          />
          <FormFeedback>{this.state.errors.email}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="passwordInput">Password</Label>
          <InputGroup size="lg">
            <Input
              name="password"
              type={this.state.showPassword ? 'text' : 'password'}
              bsSize="lg"
              invalid={Object.prototype.hasOwnProperty.call(this.state.errors, 'password')}
              onChange={this.onDataChanged}
            />
            <InputGroupAddon addonType="append">
              <button
                className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                onClick={e => this.showPassword(e)}
              ><EyeIcon />
              </button>
            </InputGroupAddon>
            <FormFeedback>{this.state.errors.password}</FormFeedback>
          </InputGroup>
          <div className="text-right py-1">
            <a href="/forgot">Forgot password?</a>
          </div>
        </FormGroup>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="rememberMe"
              component={renderCheckBoxField}
              defaultChecked={this.state.rememberMe}
              label="Remember me"
              onChange={this.onRememberMeChanged}
            />
          </div>
        </div>
        <FormGroup className="captcha-div">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={config().recaptcha.siteKey}
            onChange={this.onRecaptchaChange}
          />
        </FormGroup>
        {
          Object.prototype.hasOwnProperty.call(this.state.errors, 'global') &&
          <Alert color="danger">
            <p>{this.state.errors.global}</p>
          </Alert>
        }
        <Button
          color="primary"
          size="lg"
          block
          onClick={this.submitForm}
          style={{ marginTop: '15px' }}
        >
          {this.state.isLoadingData && <span><div className="spinner-border text-light spinner-border-sm" />{' '}</span>}
          <span>Sign In</span>
        </Button>
      </section>
    );
  }
}

LogInForm.propTypes = {
  setConnection: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'log_in_form',
})(LogInForm);
