/* eslint react/prop-types: 0 */
import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Line as LineC,
  LineChart,
  Tooltip as TooltipOnLine,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';

import AxisTick from './parts/AxisTick';
import Label from './parts/Label';
import Tooltip from './parts/Tooltip';
import Legend from './parts/Legend';

const colors = [
  '#e6194B',
  '#0088FE',
  '#13c450',
  '#ffbb28',
];

const chartMargins = {
  top: 5,
  right: 0,
  left: 45,
  bottom: 40,
};

const Line = ({
  data,
  lines,
  xKey,
  showLabels,
  xLabel,
}) => {
  const defaultColors = useMemo(() => {
    const _colors = {};
    lines.forEach((line, i) => { if (colors[i]) _colors[line.value] = colors[i]; });
    return _colors;
  }, [lines]);

  const [opacity, setOpacity] = useState({});

  useEffect(() => {
    const _opacity = {};
    // eslint-disable-next-line no-return-assign
    lines.forEach((line) => { _opacity[line.value] = 1; });
    setOpacity(_opacity);
  }, [lines]);

  return (
    <Row>
      <Col>
        <>
          {data.length ? (
            <>
              <Row>
                <Col lg={9} style={{ paddingRight: '0' }}>
                  <ResponsiveContainer
                    width="100%"
                    height={400}
                    debounce={1}
                  >
                    <LineChart
                      width="100%"
                      height="100%"
                      data={data}
                      margin={chartMargins}
                    >
                      {lines.map((line, index) => (
                        <LineC
                          key={line.value}
                          type="monotone"
                          dataKey={line.value}
                          name={line.label}
                          stroke={colors[index]}
                          strokeOpacity={opacity[line.value] || 1}
                          strokeWidth={2}
                          label={showLabels ? <Label /> : null}
                          dot={showLabels}
                        />
                    ))}
                      <XAxis
                        dataKey={xKey}
                        tick={<AxisTick textAnchor="middle" formatLabel={xLabel} dy={16} />}
                        interval="preserveStartEnd"
                      />
                      <YAxis
                        tick={<AxisTick />}
                        domain={[0, 'dataMax']}
                        interval="preserveStartEnd"
                        allowDecimals={false}
                      />
                      <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                      <TooltipOnLine content={<Tooltip colors={defaultColors} />} />
                    </LineChart>
                  </ResponsiveContainer>
                </Col>
                <Col
                  lg={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div>
                    <Legend
                      keys={lines}
                      colors={defaultColors}
                      opacity={opacity}
                      setOpacity={setOpacity}
                      icon={String.fromCharCode(9903)}
                      show={!!data.length}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <span className="mt-2">No data to chart</span>
          )}
        </>
      </Col>
    </Row>
  );
};

Line.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  lines: PropTypes.arrayOf(PropTypes.any).isRequired,
  xKey: PropTypes.string.isRequired,
  showLabels: PropTypes.bool,
  xLabel: PropTypes.func.isRequired,
};

Line.defaultProps = {
  data: [],
  showLabels: false,
};

export default Line;
