import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
// import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import DropZoneField from '../../../../../../../../shared/components/form/DropZone';

import Hook from '../../../../../../../../shared/hooks';

export const modalId = 'UploadInvoice';

const UploadInvoiceModal = ({ setVersion }) => {
  const { data: dataModal, showModal: showUploadModal, close: closeDialog } = Hook.useModalHook(modalId);

  const [invoiceFile, setInvoiceFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [endUpload, setEndUpload] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [removedFile, setRemovedFile] = useState(false);

  useEffect(() => {
    if (!dataModal) return false;
    const { record } = dataModal;
    if (!record?.params?.filePath) return false;
    let preview = record.params.filePath;
    if (record.params.filePath.includes('.pdf') || record.params.filePath.includes('.PDF')) {
      preview = '/images/resources/icons/pdf.png';
      setShowLink(true);
    } else {
      setShowLink(false);
    }
    if (!removedFile) {
      setInvoiceFile([{
        name: record.params.filePath,
        path: record.params.filePath,
        preview,
      }]);
    }
    return true;
  }, [dataModal?.record?.params?.filePath]);

  const { record } = dataModal
  const close = () => {
    closeDialog();
    if (endUpload) setVersion(new Date().toString());
    setUploading(false);
    setEndUpload(false);
    setInvoiceFile([]);
    setRemovedFile(false);
  };

  const handleChange = (files) => {
    setInvoiceFile(files);
    if (files.length === 0) {
      setRemovedFile(true);
      setShowLink(false);
    } else {
      setRemovedFile(false);
    }
  };

  const onUpload = () => {
    setUploading(true);
    const data = new FormData();
    const file = invoiceFile.length > 0 ? invoiceFile[0] : null;
    data.append('invoiceFile', file);
    data.append('invoiceId', record.id);
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-upload-invoice',
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    }).then((response) => {
      if (response.data.success) {
        setResultMessage('Upload was completed successfully');
      } else {
        setResultMessage('File was not uploaded.');
      }
      setUploading(false);
      setEndUpload(true);
    });
  };
  return (
    <Modal isOpen={showUploadModal} toggle={close}>
      <ModalHeader toggle={close} tag="h4">
        Upload Signed PDF
      </ModalHeader>
      <ModalBody>
        {!uploading && !endUpload && (
          <div className="modal__body">
            {invoiceFile.length > 0 && !showLink && (
              <Row>
                <Col>
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder=""
                    value={invoiceFile.length > 0 ? invoiceFile[0].name : ''}
                    disabled
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
            )}
            {showLink && record?.params?.filePath && (
              <Row>
                <Col>
                  <a href={`/api/portal/global/get-view?url=${record.params.filePath}`} target="_blank" rel="noreferrer">Click to preview</a>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <DropZoneField
                  input={{
                    id: 'insvoiceFile',
                    name: 'insvoiceFile',
                    value: invoiceFile,
                    accept: 'application/pdf',
                    onChange: handleChange,
                  }}
                  customHeight
                />
              </Col>
            </Row>
          </div>
        )}
        {uploading && (
          <div className="text-center"><Spinner color="primary" size="lg" />&nbsp;&nbsp;</div>
        )}
        {endUpload && (
          <div className="text-center">
            <h4>{resultMessage}</h4>
          </div>
        )}
        <Row className="modal__footer" style={{ marginTop: '30px' }}>
          {!endUpload && (
            <Button
              className="btn btn-primary btn-sm"
              onClick={onUpload}
              color="primary"
              style={{ margin: '0 10px 0 10px' }}
              disabled={uploading || (invoiceFile.length === 0 && !removedFile)}
            >
              <i className="icomoon-save" />
              <span className="btn-text">{removedFile ? 'Update' : 'Upload'}</span>
            </Button>
          )}
          <Button
            className="modal_cancel btn-sm"
            color="secondary"
            onClick={close}
            style={{ margin: '0 10px 0 10px' }}
          >
            {endUpload ? 'Close' : 'Cancel'}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

UploadInvoiceModal.propTypes = {
  setVersion: PropTypes.func.isRequired,
};

export default UploadInvoiceModal;
