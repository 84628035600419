import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import withNotice from '../../../../App/store/with-notice';
import CopyToClipboard from '../../../../../shared/components/CopyToClipboard';

const BillComCreatorProfile = ({
  vendorId,
}) => {
  const [loading, setLoading] = useState(true);
  const [vendor, setVendor] = useState({});
  console.log(vendorId, 'VENDOR');
  useEffect(async () => {
    if (!vendorId) return false;
    setLoading(true);
    const data = await axios.get(`/api/portal/finance/get-bill-com-vendor?vendorId=${vendorId}`);

    if (data.data.success === true) setVendor(data.data.vendor);
    console.log(vendor);
    setLoading(false);
    return true;
  }, [vendorId]);

  const result = useMemo(() => {
    let r = '';
    if (vendor?.email?.length > 12) {
      r = vendor?.email?.slice(0, 12);
      r += '...';
    } else {
      r = vendor?.email;
    }
    return r;
  }, [vendor.email]);

  return !loading ? (
    <div >
      <div>{vendor.name}</div>
      <div>
        <CopyToClipboard value={vendor.email || ''}>
          <a className="il-text-gray" href={`mailto:${vendor.email}`}>
            {result}
          </a>
        </CopyToClipboard>
      </div>
    </div>
  ) : null;
};

BillComCreatorProfile.propTypes = {
  vendorId: PropTypes.string.isRequired,
};

export default withNotice(BillComCreatorProfile);
