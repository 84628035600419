import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import MoneyIcon from 'mdi-react/MoneyIcon';
import UserPropertyType from './index';
import MatTableList from '../../../../../../../../shared/tables/materialTable/MatTableList';
import { getServerDate } from '../../../../../../../../shared/helpers/WVFormatter';

const Table = React.memo(({
  action,
  resource,
  version,
  handleSelectCheckbox,
  recordsRef,
  stages,
  setMetaTotal,
  pseudoUrl,
  setPseudoUrl,
}) => (
  <MatTableList
    action={action}
    resource={resource}
    setMetaTotal={setMetaTotal}
    date={new Date()}
    version={version}
    pseudoUrlFiltering
    pseudoUrl={pseudoUrl}
    updatePseudoUrl={setPseudoUrl}
    UserPropertyType={UserPropertyType}
    showFilters={false}
    showTitle={false}
    sortBy="createdAt"
    direction="desc"
    v={2}
    noActions
    isResizable={false}
    useFlex
    useCheckboxes
    checkboxHandler={handleSelectCheckbox}
    recordsRef={recordsRef}
    enableWarning={false}
    notFoundProps={{ title: 'No CPM Prospects found' }}
    useUnflatten
    customColumns={[
      {
        id: 'creatorName',
        Header: 'Creator Name',
        accessor: value => `${value.row?.original.params?.contact?.firstname || ''} ${value.row?.original.params?.contact?.lastname || ''}`,
        Cell: value => (
          <div>
            <span>
              {value.row?.original.params?.contactName || ''}
              {value.row?.original.params?.contactObject?.hs_object_id && (
                <a
                  href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${value.row.original.params.contactObject.hs_object_id}/`}
                  target="_blank"
                  rel="noreferrer noopener"
                  title="Open HubSpot Contact"
                  style={{ marginLeft: '5px' }}
                >
                  <i className="fab fa-hubspot" />
                </a>
              )}
              {value.row?.original.params?.dealObject?.hs_object_id && (
                <a
                  href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${value.row.original.params.dealObject.hs_object_id}/`}
                  target="_blank"
                  rel="noreferrer noopener"
                  title="Open HubSpot Deal"
                  style={{ marginLeft: '5px' }}
                >
                  <MoneyIcon size="20" />
                </a>
              )}
            </span>
          </div>
        ),
        width: 300,
        className: 'd-flex align-items-center',
      },
      {
        id: 'createdAt',
        Header: 'Content Release Date',
        Cell: value => (
          <div>
            <span>{value.row?.original.params?.contentReleaseDate?.currentDate ? getServerDate(value.row.original.params.contentReleaseDate.currentDate) : '-'}</span>
          </div>
        ),
        className: 'd-flex align-items-center',
      },
      {
        id: 'dealStage',
        Header: 'Deal Stage',
        Cell: value => (
          <div>
            <span>{value.row?.original.params?.dealObject?.dealstage ? stages[value.row.original.params.dealObject.dealstage] : '-'}</span>
          </div>
          ),
        className: 'd-flex align-items-center',
      },
      {
        id: 'IoName',
        Header: 'IO Name',
        Cell: value => (
          <div>
            <span>{value.row?.original.params?.cpmInsertionOrderName || '-'}</span>
          </div>
        ),
        className: 'd-flex align-items-center',
      },
    ]}
  />
), (a, a1) => _.isEqual(a.version, a1.version)
  && _.isEqual(a.stages, a1.stages)
  && _.isEqual(a.pseudoUrl, a1.pseudoUrl));

Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  handleSelectCheckbox: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  recordsRef: PropTypes.any.isRequired,
  stages: PropTypes.objectOf(PropTypes.any).isRequired,
  setMetaTotal: PropTypes.func.isRequired,
  pseudoUrl: PropTypes.string.isRequired,
  setPseudoUrl: PropTypes.func.isRequired,
};

export default connect()(Table);
