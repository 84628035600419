import React from 'react';

export default (data, additionalTitle = null) => ((data?.url && data?.platform) ? (
  <a
    href={data.url}
    target="_blank"
    rel="noreferrer"
    key={data.platform}
    style={{ marginRight: '4px' }}
  >
    <img
      src={`/images/mediacontent/${data.platform}.png`}
      alt={data.platform}
      title={additionalTitle ? `${data.title} ${additionalTitle}`.trim() : data.title}
      style={{ width: '20px' }}
    />
  </a>
) : '');
