/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import queryHasFilter from '@/shared/ViewHelpers/QueryHasFilter';
import ActionHeader from '../../../../shared/components/components/app/action-header';
import Filter from '../../../../shared/components/components/app/filter';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
import UserPropertyType from './components/custom';


const ResourceAction = (props) => {
  const {
    location,
    resources,
  } = props;
  const [filterVisible, setFilerVisible] = useState(queryHasFilter(location.search));
  const [tag, setTag] = useState('');
  const resource = resources.find(r => r.id === 'Dictionary');
  if (!resource) {
    return (<NoResourceError resourceId="Dictionary" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="Dictionary" actionName="list" />);
  }
  
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs resource={resource} actionName="list" />
          <ActionHeader
            resource={resource}
            action={action}
            tag={tag}
            toggleFilter={action.showFilter ? () => setFilerVisible(!filterVisible) : undefined}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <MatTableList
            action={action}
            resource={resource}
            setTag={setTag}
            date={new Date()}
            UserPropertyType={UserPropertyType}
            sortBy="key"
            direction="asc"
            columnsSettings={{
              key: {
                width: 400,
              },
              value: {
                width: 600,
              },
            }}
            hideToolbar
            v={2}
          />
        </Col>
      </Row>
      {action.showFilter ? (
        <Filter
          resource={resource}
          isVisible={filterVisible}
          toggleFilter={() => setFilerVisible(!filterVisible)}
        />
      ) : ''}
    </Container>
  );
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
