import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

const DefaultDashboard = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Brands</h3>
      </Col>
    </Row>
  </Container>
);

export default connect(state => ({
  rtl: state.rtl,
}))(DefaultDashboard);
