import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import formater from '../../../../shared/helpers/WVFormatter';

const Item = ({ label, value }) => (
  <div
    style={{
      padding: '15px 15px',
      border: '1px solid #cccccc',
      borderRadius: '5px',
      height: '100%',
    }}
  >
    <div style={{ textAlign: 'center', color: 'black' }} >
      <span
        style={{
          fontSize: '20px',
          fontWeight: '700',
          marginBottom: '12px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {value}
      </span>
      <span style={{ width: '100%', fontSize: '16px' }}>{label}</span>
    </div>
  </div>
);


Item.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
};


const Total = ({ errors, tableItems, type }) => {
  const total = useMemo(() => {
    if (type === 'category') {
      const totalCreators = {};
      let totalConversions = 0;
      let uncategorized = 0;
      tableItems.forEach(({ creators, total: totalC }) => {
        totalConversions += Number(totalC || 0);
        creators.forEach((c) => { totalCreators[String(c._id)] = 1; });
      });
      errors?.forEach(({ conversionsCount }) => {
        uncategorized += Number(conversionsCount || 0);
      });
      return {
        creators: Object.values(totalCreators).length,
        conversions: totalConversions,
        categories: tableItems.length,
        uncategorized,
      };
    }
    const totalCarebories = {};
    let totalConversions = 0;
    let uncategorized = 0;
    tableItems.forEach(({ creator, total: totalC }) => {
      totalConversions += Number(totalC || 0);
      totalCarebories[String(creator.category)] = 1;
    });
    errors?.forEach(({ conversionsCount }) => {
      uncategorized += Number(conversionsCount || 0);
    });
    return {
      creators: tableItems.length,
      conversions: totalConversions,
      categories: Object.values(totalCarebories).length,
      uncategorized,
    };
  }, [tableItems, errors]);

  // console.log(filteredItems);
  return (
    <>
      {total ? (
        <Row className="mt-3">
          <Col className="text-center">
            <Item
              label="Revenue Conversions"
              value={(<h3>{formater.formatIntNumber(total.conversions)}</h3>)}
            />
          </Col>
          <Col className="text-center">
            <Item
              label="Uncategorized"
              value={(<h3>{formater.formatIntNumber(total.uncategorized)}</h3>)}
            />
          </Col>
          <Col className="text-center">
            <Item
              label="Active Creators"
              value={(<h3>{formater.formatIntNumber(total.creators)}</h3>)}
            />
          </Col>
          <Col className="text-center">
            <Item
              label="Categories"
              value={(<h3>{formater.formatIntNumber(total.categories)}</h3>)}
            />
          </Col>
        </Row>) : null
      }
    </>
  );
};
Total.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  tableItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  type: PropTypes.string.isRequired,
};
export default Total;

