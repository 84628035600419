import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTags from 'react-tag-autocomplete';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import IntervalDatePickerField from '../../../../Influencer/Research/Prospects/components/custom/IntervalDatePickerField';
import ProgressBar from '../../../../../../shared/helpers/ProgressBarWithObject';

const Actions = React.memo(({
  setFillBrandsModal,
  fetchAllDeals,
  synchronizeOffers,
  onSearchChange,
  getUrlSearch,
  searchValue,
  ownersSuggestions,
  setOwnersFilter,
  setOwners,
  owners,
  setBrandsFilter,
  setBrands,
  brands,
  brandsSuggestions,
  dealStagesSuggestions,
  setDealStagesFilter,
  setDealStages,
  dealStages,
  statusFilters,
  setStatusFilters,
  promoCodeFilters,
  setPromoCodeFilters,
  dealTypesFilters,
  setDealTypesFilters,
  firstConversionDateValue,
  setFirstConversionDateValue,
  enableFirstConversionDate,
  setEnableFirstConversionDate,
  firstConversionDateFrom,
  setFirstConversionDateFrom,
  firstConversionDateTo,
  setFirstConversionDateTo,
  singleFirstConversionDate,
  setSingleFirstConversionDate,
  lastConversionDateValue,
  setLastConversionDateValue,
  enableLastConversionDate,
  setEnableLastConversionDate,
  lastConversionDateFrom,
  setLastConversionDateFrom,
  lastConversionDateTo,
  setLastConversionDateTo,
  singleLastConversionDate,
  setSingleLastConversionDate,
  brandApprovedDateValue,
  setBrandApprovedDateValue,
  enableBrandApprovedDate,
  setEnableBrandApprovedDate,
  brandApprovedDateFrom,
  setBrandApprovedDateFrom,
  brandApprovedDateTo,
  setBrandApprovedDateTo,
  singleBrandApprovedDate,
  setSingleBrandApprovedDate,
  contentReleaseDateValue,
  setContentReleaseDateValue,
  enableContentReleaseDate,
  setEnableContentReleaseDate,
  contentReleaseDateFrom,
  setContentReleaseDateFrom,
  contentReleaseDateTo,
  setContentReleaseDateTo,
  singleContentReleaseDate,
  setSingleContentReleaseDate,
  loadingDeals,
  loadingOffers,
  afterDealFetch,
  role,
  urlIsUpdating,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('hubilDealsShowFilters') === 'true');

  const reactTagsDealStages = React.createRef();
  const reactTagsOwners = React.createRef();
  const reactTagsBrands = React.createRef();

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('hubilDealsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const setFiltersDealStage = (dealStagesNew) => {
    const dealStagesFilterNew = {};
    if (dealStagesNew.length > 0) {
      dealStagesNew.map((item) => {
        dealStagesFilterNew[item.id] = true;
        return true;
      });
    }
    setDealStagesFilter(dealStagesFilterNew);
  };

  const onDeleteDealStage = (i) => {
    const dealStagesNew = dealStages.slice(0);
    dealStagesNew.splice(i, 1);
    setFiltersDealStage(dealStagesNew);
    setDealStages(dealStagesNew);
  };

  const onAdditionDealStage = (dealStage) => {
    const dealStagesNew = [].concat(dealStages, dealStage);
    setFiltersDealStage(dealStagesNew);
    setDealStages(dealStagesNew);
  };

  const setFiltersOwner = (ownersNew) => {
    const ownersFilterNew = {};
    if (ownersNew.length > 0) {
      ownersNew.map((item) => {
        ownersFilterNew[item.id] = true;
        return true;
      });
    }
    setOwnersFilter(ownersFilterNew);
  };

  const onDeleteOwner = (i) => {
    const ownersNew = owners.slice(0);
    ownersNew.splice(i, 1);
    setFiltersOwner(ownersNew);
    setOwners(ownersNew);
  };

  const onAdditionOwner = (owner) => {
    const ownersNew = [].concat(owners, owner);
    setFiltersOwner(ownersNew);
    setOwners(ownersNew);
  };

  const setFiltersBrands = (brandsNew) => {
    const brandsFilterNew = {};
    if (brandsNew.length > 0) {
      brandsNew.map((item) => {
        brandsFilterNew[item.id] = true;
        return true;
      });
    }
    setBrandsFilter(brandsFilterNew);
  };

  const onDeleteBrand = (i) => {
    const brandsNew = brands.slice(0);
    brandsNew.splice(i, 1);
    setFiltersBrands(brandsNew);
    setBrands(brandsNew);
  };

  const onAdditionBrand = (brand) => {
    const brandsNew = [].concat(brands, brand);
    setFiltersBrands(brandsNew);
    setBrands(brandsNew);
  };

  const handleChangeStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setStatusFilters({
        ...statusFilters,
        [name]: checked,
      });
    }
  };

  const handleChangePromoCodeFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setPromoCodeFilters({
        ...promoCodeFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeDealTypesFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setDealTypesFilters({
        ...dealTypesFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setStatusFilters({
      Pending: true,
      Active: true,
      Expired: true,
      Paid: true,
    });
    setPromoCodeFilters({
      Yes: true,
      No: true,
    });
    setDealTypesFilters({
      Hybrid: true,
      'CPA/Ambassador': true,
      'Upfront Only': true,
      'Upfront CPM': true,
      'Agent Commission': true,
      'Media License': true,
      Bonus: true,
    });
    setDealStages([]);
    setDealStagesFilter({});
    setBrands([]);
    setBrandsFilter({});
    setOwners([]);
    setOwnersFilter({});
    setEnableFirstConversionDate(false);
    setFirstConversionDateValue({ value: '<=', label: '<=' });
    setSingleFirstConversionDate(null);
    setFirstConversionDateTo(null);
    setFirstConversionDateFrom(null);

    setEnableLastConversionDate(false);
    setLastConversionDateValue({ value: '<=', label: '<=' });
    setSingleLastConversionDate(null);
    setLastConversionDateTo(null);
    setLastConversionDateFrom(null);

    setEnableBrandApprovedDate(false);
    setBrandApprovedDateValue({ value: '<=', label: '<=' });
    setSingleBrandApprovedDate(null);
    setBrandApprovedDateTo(null);
    setBrandApprovedDateFrom(null);

    setEnableContentReleaseDate(false);
    setContentReleaseDateValue({ value: '<=', label: '<=' });
    setSingleContentReleaseDate(null);
    setContentReleaseDateTo(null);
    setContentReleaseDateFrom(null);
  };

  const handleChangeFirstConversionDateCheckBox = ({ target }) => {
    setEnableFirstConversionDate(!enableFirstConversionDate);
    if (!target.checked) {
      setSingleFirstConversionDate(null);
      setFirstConversionDateTo(null);
      setFirstConversionDateFrom(null);
    }
  };

  const handleChangeLastConversionDateCheckBox = ({ target }) => {
    setEnableLastConversionDate(!enableLastConversionDate);
    if (!target.checked) {
      setSingleLastConversionDate(null);
      setLastConversionDateTo(null);
      setLastConversionDateFrom(null);
    }
  };

  const handleChangeBrandApprovedDateCheckBox = ({ target }) => {
    setEnableBrandApprovedDate(!enableBrandApprovedDate);
    if (!target.checked) {
      setSingleBrandApprovedDate(null);
      setBrandApprovedDateTo(null);
      setBrandApprovedDateFrom(null);
    }
  };

  const handleChangeContentReleaseDateCheckBox = ({ target }) => {
    setEnableContentReleaseDate(!enableContentReleaseDate);
    if (!target.checked) {
      setSingleContentReleaseDate(null);
      setContentReleaseDateTo(null);
      setContentReleaseDateFrom(null);
    }
  };

  const conversionDateLabels = [
    { value: '=', label: '=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'Between', label: 'Between' },
  ];

  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
      height: '44px',
    }),
  };

  return (
    <div>
      <Row className="mt-2">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Creator, Deal Owner, Deal Name, Promo Code"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => window.open(`/resources/hubildeals/download-csv?${getUrlSearch().toString()}`, '_blank').focus()}
              >
                Download CSV
              </DropdownItem>
              {role === 'owner' && (
                <>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => setFillBrandsModal(true)}
                  >
                    Update Deal Brands
                  </DropdownItem>
                  <DropdownItem
                    disabled={loadingOffers}
                    onClick={synchronizeOffers}
                  >
                    {loadingOffers ? 'Synchronizing Offers to HubSpot, please wait...' : 'Synchronize Offers to HubSpot'}
                  </DropdownItem>
                  <DropdownItem
                    disabled={loadingDeals}
                    onClick={fetchAllDeals}
                  >
                    {loadingDeals ? 'Fetching Deals, please wait...' : 'Fetch All Deals'}
                  </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          { loadingDeals && <ProgressBar topic="fetchAllDeals" afterFunction={afterDealFetch} /> }
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row>
            <Col sm={4}>
              <Label className="bold-text">
                Deal Owner(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsOwners}
                tags={owners}
                suggestions={ownersSuggestions}
                onDelete={onDeleteOwner}
                onAddition={onAdditionOwner}
                delimiters={['Enter', ',']}
                placeholderText="Deal Owner name(s)"
                disabled={urlIsUpdating}
              />
            </Col>
            <Col sm={4}>
              <Label className="bold-text">
                Brand(s)
              </Label>
              <ReactTags
                ref={reactTagsBrands}
                tags={brands}
                suggestions={brandsSuggestions}
                onDelete={onDeleteBrand}
                onAddition={onAdditionBrand}
                delimiters={['Enter', ',']}
                placeholderText="Brand name(s)"
                disabled={urlIsUpdating}
              />
            </Col>
            <Col sm={4}>
              <Label className="bold-text">
                Deal Stage(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsDealStages}
                tags={dealStages}
                suggestions={dealStagesSuggestions}
                onDelete={onDeleteDealStage}
                onAddition={onAdditionDealStage}
                delimiters={['Enter', ',']}
                placeholderText="Deal Stage name(s)"
                maxSuggestionsLength={15}
                disabled={urlIsUpdating}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={4}>
              <Row>
                <Col xl={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Status
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Active"
                      label="Active"
                      value={statusFilters.Active}
                      onChange={event => handleChangeStatusFilters(event, 'Active')}
                      disabled={urlIsUpdating}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Pending"
                      label="Pending"
                      value={statusFilters.Pending}
                      onChange={event => handleChangeStatusFilters(event, 'Pending')}
                      disabled={urlIsUpdating}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Paid"
                      label="Paid"
                      value={statusFilters.Paid}
                      onChange={event => handleChangeStatusFilters(event, 'Paid')}
                      disabled={urlIsUpdating}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="Expired"
                      label="Expired"
                      value={statusFilters.Expired}
                      onChange={event => handleChangeStatusFilters(event, 'Expired')}
                      disabled={urlIsUpdating}
                    />
                  </FormGroup>
                </Col>
                <Col xl={5}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Deal Types
                  </Label>
                  <Row>
                    <Col>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="cpa"
                          label="CPA/Ambassador"
                          value={dealTypesFilters['CPA/Ambassador']}
                          onChange={event => handleChangeDealTypesFilters(event, 'CPA/Ambassador')}
                          disabled={urlIsUpdating}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="hybrid"
                          label="Hybrid"
                          value={dealTypesFilters.Hybrid}
                          onChange={event => handleChangeDealTypesFilters(event, 'Hybrid')}
                          disabled={urlIsUpdating}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="ufpront"
                          label="Upfront Only"
                          value={dealTypesFilters['Upfront Only']}
                          onChange={event => handleChangeDealTypesFilters(event, 'Upfront Only')}
                          disabled={urlIsUpdating}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <CheckBoxField
                          name="upfrontCPM"
                          label="Upfront CPM"
                          value={dealTypesFilters['Upfront CPM']}
                          onChange={event => handleChangeDealTypesFilters(event, 'Upfront CPM')}
                          disabled={urlIsUpdating}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="mediaLicense"
                          label="Media License"
                          value={dealTypesFilters['Media License']}
                          onChange={event => handleChangeDealTypesFilters(event, 'Media License')}
                          disabled={urlIsUpdating}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="agentCommission"
                          label="Agent Commission"
                          value={dealTypesFilters['Agent Commission']}
                          onChange={event => handleChangeDealTypesFilters(event, 'Agent Commission')}
                          disabled={urlIsUpdating}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <CheckBoxField
                          name="bonus"
                          label="Bonus"
                          value={dealTypesFilters.Bonus}
                          onChange={event => handleChangeDealTypesFilters(event, 'Bonus')}
                          disabled={urlIsUpdating}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xl={4}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Promo Code Exists?
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Yes"
                      label="Yes, exists"
                      value={promoCodeFilters.Yes}
                      onChange={event => handleChangePromoCodeFilters(event, 'Yes')}
                      disabled={urlIsUpdating}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="No"
                      label="No, does not exist"
                      value={promoCodeFilters.No}
                      onChange={event => handleChangePromoCodeFilters(event, 'No')}
                      disabled={urlIsUpdating}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col sm={4}>
              <Row>
                <Col sm={1} />
                <Col sm={11} style={{ paddingLeft: '20px' }}>
                  <Label className="bold-text" style={{ marginBottom: '8px' }}>
                    First Conversion Date
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '13px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="firstActivityDateCheckBox"
                      value={enableFirstConversionDate}
                      onChange={e => e.target && handleChangeFirstConversionDateCheckBox(e)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={5} style={{ paddingLeft: '20px' }}>
                  <Select
                    value={firstConversionDateValue}
                    onChange={(option) => {
                      if ((firstConversionDateValue !== 'Between' && option.value === 'Between') || (firstConversionDateValue === 'Between' && option.value !== 'Between')) {
                        setFirstConversionDateFrom(null);
                        setFirstConversionDateTo(null);
                        setSingleFirstConversionDate(null);
                      }
                      setFirstConversionDateValue(option);
                    }}
                    options={conversionDateLabels}
                    isDisabled={!enableFirstConversionDate}
                    styles={customStyles}
                  />
                </Col>
                <Col sm={6}>
                  {firstConversionDateValue.value === 'Between' ? (
                    <div style={{ marginRight: '-30px' }} className="pb-2">
                      <IntervalDatePickerField
                        onChange={({ start, end }) => {
                          setFirstConversionDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setFirstConversionDateTo(end);
                        }}
                        startDate={firstConversionDateFrom}
                        endDate={firstConversionDateTo}
                        maxDate={new Date()}
                        placeholderFrom="Start Date"
                        placeholderTo="End Date"
                        verticalFields
                        disabled={!enableFirstConversionDate}
                      />
                    </div>
                  ) : (
                    <div className="date-picker datepicker-in-prospects">
                      <DatePicker
                        selected={singleFirstConversionDate}
                        onChange={(date) => {
                          if (firstConversionDateValue.value === '<=' || firstConversionDateValue.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          setSingleFirstConversionDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm datepicker-in-prospects w-100"
                        disabled={!enableFirstConversionDate}
                      />
                      {singleFirstConversionDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '13px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={1} />
                <Col sm={11} style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                  <Label className="bold-text" style={{ marginBottom: '8px' }}>
                    Brand Approved Date
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '13px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="brandApprovedDateCheckBox"
                      value={enableBrandApprovedDate}
                      onChange={e => e.target && handleChangeBrandApprovedDateCheckBox(e)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={5} style={{ paddingLeft: '20px' }}>
                  <Select
                    value={brandApprovedDateValue}
                    onChange={(option) => {
                      if ((brandApprovedDateValue !== 'Between' && option.value === 'Between') || (brandApprovedDateValue === 'Between' && option.value !== 'Between')) {
                        setBrandApprovedDateFrom(null);
                        setBrandApprovedDateTo(null);
                        setSingleBrandApprovedDate(null);
                      }
                      setBrandApprovedDateValue(option);
                    }}
                    options={conversionDateLabels}
                    isDisabled={!enableBrandApprovedDate}
                    styles={customStyles}
                  />
                </Col>
                <Col sm={6}>
                  {brandApprovedDateValue.value === 'Between' ? (
                    <div style={{ marginRight: '-30px' }} className="pb-2">
                      <IntervalDatePickerField
                        onChange={({ start, end }) => {
                          setBrandApprovedDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setBrandApprovedDateTo(end);
                        }}
                        startDate={brandApprovedDateFrom}
                        endDate={brandApprovedDateTo}
                        maxDate={new Date()}
                        placeholderFrom="Start Date"
                        placeholderTo="End Date"
                        verticalFields
                        disabled={!enableBrandApprovedDate}
                      />
                    </div>
                  ) : (
                    <div className="date-picker datepicker-in-prospects">
                      <DatePicker
                        selected={singleBrandApprovedDate}
                        onChange={(date) => {
                          if (brandApprovedDateValue.value === '<=' || brandApprovedDateValue.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          setSingleBrandApprovedDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm datepicker-in-prospects w-100"
                        disabled={!enableBrandApprovedDate}
                      />
                      {singleBrandApprovedDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '13px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm={4}>
              <Row>
                <Col sm={1} />
                <Col sm={11} style={{ paddingLeft: '20px' }}>
                  <Label className="bold-text" style={{ marginBottom: '8px' }}>
                    Last Conversion Date
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '13px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="lastActivityDateCheckBox"
                      value={enableLastConversionDate}
                      onChange={e => e.target && handleChangeLastConversionDateCheckBox(e)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={5} style={{ paddingLeft: '20px' }}>
                  <Select
                    value={lastConversionDateValue}
                    onChange={(option) => {
                      if ((lastConversionDateValue !== 'Between' && option.value === 'Between') || (lastConversionDateValue === 'Between' && option.value !== 'Between')) {
                        setLastConversionDateFrom(null);
                        setLastConversionDateTo(null);
                        setSingleLastConversionDate(null);
                      }
                      setLastConversionDateValue(option);
                    }}
                    options={conversionDateLabels}
                    isDisabled={!enableLastConversionDate}
                    styles={customStyles}
                  />
                </Col>
                <Col sm={6}>
                  {lastConversionDateValue.value === 'Between' ? (
                    <div style={{ marginRight: '-30px' }} className="pb-2">
                      <IntervalDatePickerField
                        onChange={({ start, end }) => {
                          setLastConversionDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setLastConversionDateTo(end);
                        }}
                        startDate={lastConversionDateFrom}
                        endDate={lastConversionDateTo}
                        maxDate={new Date()}
                        placeholderFrom="Start Date"
                        placeholderTo="End Date"
                        verticalFields
                        disabled={!enableLastConversionDate}
                      />
                    </div>
                  ) : (
                    <div className="date-picker datepicker-in-prospects">
                      <DatePicker
                        selected={singleLastConversionDate}
                        onChange={(date) => {
                          if (lastConversionDateValue.value === '<=' || lastConversionDateValue.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          setSingleLastConversionDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm datepicker-in-prospects w-100"
                        disabled={!enableLastConversionDate}
                      />
                      {singleLastConversionDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '13px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={1} />
                <Col sm={11} style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                  <Label className="bold-text" style={{ marginBottom: '8px' }}>
                    Content Release Date
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '13px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="contentReleaseDateCheckBox"
                      value={enableContentReleaseDate}
                      onChange={e => e.target && handleChangeContentReleaseDateCheckBox(e)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={5} style={{ paddingLeft: '20px' }}>
                  <Select
                    value={contentReleaseDateValue}
                    onChange={(option) => {
                      if ((contentReleaseDateValue !== 'Between' && option.value === 'Between') || (contentReleaseDateValue === 'Between' && option.value !== 'Between')) {
                        setContentReleaseDateFrom(null);
                        setContentReleaseDateTo(null);
                        setSingleContentReleaseDate(null);
                      }
                      setContentReleaseDateValue(option);
                    }}
                    options={conversionDateLabels}
                    isDisabled={!enableContentReleaseDate}
                    styles={customStyles}
                  />
                </Col>
                <Col sm={6}>
                  {contentReleaseDateValue.value === 'Between' ? (
                    <div style={{ marginRight: '-30px' }} className="pb-2">
                      <IntervalDatePickerField
                        onChange={({ start, end }) => {
                          setContentReleaseDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setContentReleaseDateTo(end);
                        }}
                        startDate={contentReleaseDateFrom}
                        endDate={contentReleaseDateTo}
                        maxDate={new Date()}
                        placeholderFrom="Start Date"
                        placeholderTo="End Date"
                        verticalFields
                        disabled={!enableContentReleaseDate}
                      />
                    </div>
                  ) : (
                    <div className="date-picker datepicker-in-prospects">
                      <DatePicker
                        selected={singleContentReleaseDate}
                        onChange={(date) => {
                          if (contentReleaseDateValue.value === '<=' || contentReleaseDateValue.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          setSingleContentReleaseDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm datepicker-in-prospects w-100"
                        disabled={!enableContentReleaseDate}
                      />
                      {singleContentReleaseDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '13px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  a.loadingDeals === a1.loadingDeals &&
  a.loadingOffers === a1.loadingOffers &&
  _.isEqual(a.statusFilters, a1.statusFilters) &&
  _.isEqual(a.promoCodeFilters, a1.promoCodeFilters) &&
  _.isEqual(a.dealTypesFilters, a1.dealTypesFilters) &&
  _.isEqual(a.dealStages, a1.dealStages) &&
  _.isEqual(a.owners, a1.owners) &&
  _.isEqual(a.brands, a1.brands) &&
  _.isEqual(a.brandsSuggestions, a1.brandsSuggestions) &&
  _.isEqual(a.dealStagesSuggestions, a1.dealStagesSuggestions) &&
  _.isEqual(a.ownersSuggestions, a1.ownersSuggestions) &&
  _.isEqual(a.enableBrandApprovedDate, a1.enableBrandApprovedDate) &&
  _.isEqual(a.brandApprovedDateValue, a1.brandApprovedDateValue) &&
  _.isEqual(a.singleBrandApprovedDate, a1.singleBrandApprovedDate) &&
  _.isEqual(a.brandApprovedDateFrom, a1.brandApprovedDateFrom) &&
  _.isEqual(a.brandApprovedDateTo, a1.brandApprovedDateTo) &&
  _.isEqual(a.enableContentReleaseDate, a1.enableContentReleaseDate) &&
  _.isEqual(a.contentReleaseDateValue, a1.contentReleaseDateValue) &&
  _.isEqual(a.singleContentReleaseDate, a1.singleContentReleaseDate) &&
  _.isEqual(a.contentReleaseDateFrom, a1.contentReleaseDateFrom) &&
  _.isEqual(a.contentReleaseDateTo, a1.contentReleaseDateTo) &&
  _.isEqual(a.enableFirstConversionDate, a1.enableFirstConversionDate) &&
  _.isEqual(a.firstConversionDateValue, a1.firstConversionDateValue) &&
  _.isEqual(a.singleFirstConversionDate, a1.singleFirstConversionDate) &&
  _.isEqual(a.firstConversionDateFrom, a1.firstConversionDateFrom) &&
  _.isEqual(a.firstConversionDateTo, a1.firstConversionDateTo) &&
  _.isEqual(a.enableLastConversionDate, a1.enableLastConversionDate) &&
  _.isEqual(a.lastConversionDateValue, a1.lastConversionDateValue) &&
  _.isEqual(a.singleLastConversionDate, a1.singleLastConversionDate) &&
  _.isEqual(a.lastConversionDateFrom, a1.lastConversionDateFrom) &&
  _.isEqual(a.urlIsUpdating, a1.urlIsUpdating) &&
  _.isEqual(a.lastConversionDateTo, a1.lastConversionDateTo));
Actions.propTypes = {
  setFillBrandsModal: PropTypes.func.isRequired,
  fetchAllDeals: PropTypes.func.isRequired,
  synchronizeOffers: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  getUrlSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  dealStagesSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setDealStagesFilter: PropTypes.func.isRequired,
  setDealStages: PropTypes.func.isRequired,
  dealStages: PropTypes.arrayOf(PropTypes.any).isRequired,
  ownersSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setOwnersFilter: PropTypes.func.isRequired,
  setOwners: PropTypes.func.isRequired,
  owners: PropTypes.arrayOf(PropTypes.any).isRequired,
  setBrandsFilter: PropTypes.func.isRequired,
  setBrands: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setStatusFilters: PropTypes.func.isRequired,
  statusFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setPromoCodeFilters: PropTypes.func.isRequired,
  promoCodeFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setDealTypesFilters: PropTypes.func.isRequired,
  dealTypesFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  enableFirstConversionDate: PropTypes.bool.isRequired,
  setEnableFirstConversionDate: PropTypes.func.isRequired,
  firstConversionDateValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setFirstConversionDateValue: PropTypes.func.isRequired,
  firstConversionDateFrom: PropTypes.objectOf(PropTypes.any),
  setFirstConversionDateFrom: PropTypes.func.isRequired,
  firstConversionDateTo: PropTypes.objectOf(PropTypes.any),
  setFirstConversionDateTo: PropTypes.func.isRequired,
  singleFirstConversionDate: PropTypes.objectOf(PropTypes.any),
  setSingleFirstConversionDate: PropTypes.func.isRequired,
  enableLastConversionDate: PropTypes.bool.isRequired,
  setEnableLastConversionDate: PropTypes.func.isRequired,
  lastConversionDateValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setLastConversionDateValue: PropTypes.func.isRequired,
  lastConversionDateFrom: PropTypes.objectOf(PropTypes.any),
  setLastConversionDateFrom: PropTypes.func.isRequired,
  lastConversionDateTo: PropTypes.objectOf(PropTypes.any),
  setLastConversionDateTo: PropTypes.func.isRequired,
  singleLastConversionDate: PropTypes.objectOf(PropTypes.any),
  setSingleLastConversionDate: PropTypes.func.isRequired,
  enableBrandApprovedDate: PropTypes.bool.isRequired,
  setEnableBrandApprovedDate: PropTypes.func.isRequired,
  brandApprovedDateValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setBrandApprovedDateValue: PropTypes.func.isRequired,
  brandApprovedDateFrom: PropTypes.objectOf(PropTypes.any),
  setBrandApprovedDateFrom: PropTypes.func.isRequired,
  brandApprovedDateTo: PropTypes.objectOf(PropTypes.any),
  setBrandApprovedDateTo: PropTypes.func.isRequired,
  singleBrandApprovedDate: PropTypes.objectOf(PropTypes.any),
  setSingleBrandApprovedDate: PropTypes.func.isRequired,
  enableContentReleaseDate: PropTypes.bool.isRequired,
  setEnableContentReleaseDate: PropTypes.func.isRequired,
  contentReleaseDateValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setContentReleaseDateValue: PropTypes.func.isRequired,
  contentReleaseDateFrom: PropTypes.objectOf(PropTypes.any),
  setContentReleaseDateFrom: PropTypes.func.isRequired,
  contentReleaseDateTo: PropTypes.objectOf(PropTypes.any),
  setContentReleaseDateTo: PropTypes.func.isRequired,
  singleContentReleaseDate: PropTypes.objectOf(PropTypes.any),
  setSingleContentReleaseDate: PropTypes.func.isRequired,
  loadingDeals: PropTypes.bool.isRequired,
  loadingOffers: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  afterDealFetch: PropTypes.func.isRequired,
  urlIsUpdating: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  firstConversionDateFrom: null,
  firstConversionDateTo: null,
  singleFirstConversionDate: null,
  lastConversionDateFrom: null,
  lastConversionDateTo: null,
  singleLastConversionDate: null,
  brandApprovedDateFrom: null,
  brandApprovedDateTo: null,
  singleBrandApprovedDate: null,
  contentReleaseDateFrom: null,
  contentReleaseDateTo: null,
  singleContentReleaseDate: null,
};

const mapStateToProps = state => ({
  role: state.session.role,
});

export default connect(mapStateToProps)(Actions);
