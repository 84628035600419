// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import TuneIcon from 'mdi-react/TuneIcon';
import withNotice from '../../../../../../App/store/with-notice';
// import { getCookie, setCookie } from '../../../../../../../shared/helpers/WVUtilities';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { UploadInsertionOrderModalId } from '../../../CpmInsertionOrder/components/custom/Modal/UploadInsertionOrderModal';
import { PublishInsertionOrderChangeLogHistoryModalId } from '../../../CpmInsertionOrder/components/custom/Modal/PublishInsertionOrderChangeLogHistoryModal';

import { modalId } from '../modals/FetchContentReleaseDate';
import { AddCreatorsModalId } from '../modals/AddCreatorsModal';
import { modalId as publishedModalId } from '../modals/PublishInsertinOrder';
import { modalId as publisheLookerModalId } from '../modals/PublishLookerInsertinOrder';
import Hook from '../../../../../../../shared/hooks';

// import NewInsertionOrder from './NewInsertionOrder';
const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};
const Actions = React.memo(({
  onSearchChange,
  searchValue,
  // setApprovalStatusFilters,
  insertionOrder,
  getInsertionOrder,
  loading,
  addNotice,
  updateTable,
  downloadCsv,
  setStartLoadCreators,
  dealStageFilters,
  setDealStageFilters,
  dealstagesOptions,
}) => {
  const [showModalPublish] = Hook.useShowModalHook(PublishInsertionOrderChangeLogHistoryModalId);
  const [showModalUpload] = Hook.useShowModalHook(UploadInsertionOrderModalId);
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  // const [showFilters, setShowFilters] = useState(getCookie('cpmInsertionOrderCreatorsProspShowFilters') === 'true');
  const [startId, setStartId] = useState(0);
  const [purchaseOrderNum, setPurchaseOrderNum] = useState(null);
  const [brandPurchaseOrderNo, setBrandPurchaseOrderNum] = useState(null);
  const [loadingField, setLoading] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setStartId(insertionOrder?.params?.startId || 0);
  }, [insertionOrder?.params?.startId]);

  useEffect(() => {
    setPurchaseOrderNum(insertionOrder?.params?.purchaseOrderNum || null);
  }, [insertionOrder?.params?.purchaseOrderNum]);

  useEffect(() => {
    if (!loading) {
      setStartId(insertionOrder?.params?.startId || 0);
      setPurchaseOrderNum(insertionOrder?.params?.purchaseOrderNum || null);
      setBrandPurchaseOrderNum(insertionOrder?.params?.brandPurchaseOrderNo || null);
    }
  }, [loading]);


  useEffect(() => {
    setBrandPurchaseOrderNum(insertionOrder?.params?.brandPurchaseOrderNo || null);
  }, [insertionOrder?.params?.brandPurchaseOrderNo]);

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  /* useEffect(() => {
    setCookie('cpmInsertionOrderCreatorsProspShowFilters', showFilters.toString(), 35);
  }, [showFilters]); */

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setDealStageFilters([]);
  };
  const handleChange = async () => {
    setLoading(true);
    try {
      const data = await axios.post('/api/portal/prospects/post-cpm-insertion-order-update', {
        ids: [insertionOrder.id],
        values: {
          startId: Number(startId || 0),
          purchaseOrderNum: purchaseOrderNum ? Number(purchaseOrderNum) : null,
          brandPurchaseOrderNo: brandPurchaseOrderNo ? String(brandPurchaseOrderNo || '') : null,
        },
      });
      if (data?.data?.success) {
        getInsertionOrder();
      } else throw new Error(data?.data?.error || 'Unable to save Insertion Order');
      addNotice({
        message: 'The Insertion Order has been saved successfully',
        type: 'success',
      });
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to save Insertion Order',
        type: 'error',
        duration: 20,
      });
      getInsertionOrder();
      console.error(err);
    }
    updateTable();
    setLoading(false);
  };

  return (
    <div>
      <Row>
        <Col xs="10">
          <h4 className="mb-3">{!loading ? insertionOrder?.populated?.brand?.params?.companyName : (<Spinner color="primary" size="sm" />)}</h4>
          <div>
            <span style={{ width: '200px', display: 'inline-block' }} >Starting Insertion Order Id</span>
            <Input
              type="number"
              className="d-inline  form-control-sm"
              style={{ width: '100px' }}
              placeholder="Order Id"
              value={startId}
              onChange={e => setStartId(Number(e.target?.value) || 0)}
              onBlur={handleChange}
              disabled={loading || loadingField || insertionOrder?.params?.status?.status !== 'New'}
            />
          </div>
          <div className="mt-2" >
            <span style={{ width: '200px', display: 'inline-block' }} >IL Purchase Order No.</span>
            <Input
              type="number"
              className="d-inline  form-control-sm"
              style={{ width: '100px' }}
              placeholder="IL Purchase Order No."
              value={purchaseOrderNum || ''}
              onChange={e => setPurchaseOrderNum(Number(e.target?.value) || '')}
              onBlur={handleChange}
              disabled={loading || loadingField}
            />
          </div>
          <div className="mt-2">
            <span style={{ width: '200px', display: 'inline-block' }} >Brand Purchase Order No.</span>
            <Input
              type="text"
              className="d-inline  form-control-sm"
              style={{ width: '200px' }}
              placeholder="Brand Purchase Order No."
              value={brandPurchaseOrderNo || ''}
              onChange={e => setBrandPurchaseOrderNum(e.target?.value) || ''}
              onBlur={handleChange}
              disabled={loading || loadingField}
            />
          </div>
          <div className="mt-2">
            <div className="float-left" style={{ width: '90px' }}>Deal Stages</div>
            <div className="float-left" style={{ width: '300px' }}>
              <Select
                value={dealStageFilters}
                options={dealstagesOptions}
                onChange={setDealStageFilters}
                placeholder="Deal Stages..."
                isClearable
                isMulti
              />
            </div>
            <div className="clearfix" />
          </div>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => { setStartLoadCreators(true); dispatch(showModalById(AddCreatorsModalId, { record: insertionOrder })); }}
              >
                Add Creators
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem header >Insertion Order</DropdownItem>
              <DropdownItem
                onClick={() => { dispatch(showModalById(publishedModalId, { record: insertionOrder })); }}
              >
                Publish
              </DropdownItem>
              <DropdownItem
                onClick={() => goToPage(insertionOrder.params?.googleSheetFiles?.length > 0 ? insertionOrder.params.googleSheetFiles.at(-1).fileUrl : '')}
                disabled={!insertionOrder?.params?.googleSheetFiles?.length}
              >
                View
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const id = insertionOrder.params?.googleSheetFiles?.length > 0 ? insertionOrder.params.googleSheetFiles.at(-1).fileId : '';
                  goToPage(`https://docs.google.com/spreadsheets/d/${id}/export?format=pdf&gridlines=false`);
                }}
                disabled={!insertionOrder?.params?.googleSheetFiles?.length}
              >
                Download Unsigned PDF
              </DropdownItem>
              <DropdownItem
                onClick={() => { showModalUpload({ record: insertionOrder }); }}
                disabled={!(insertionOrder?.params?.googleSheetFiles && insertionOrder.params.googleSheetFiles.length > 0)}
              >
                Upload Signed PDF
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => { showModalPublish({ ids: [insertionOrder.id] }); }}
              >
                Publish Change Log
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => { dispatch(showModalById(publisheLookerModalId, { record: insertionOrder })); }}
                disabled={insertionOrder?.params?.status?.status !== 'Signed'}
              >
                Publish Looker Update Data
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => { dispatch(showModalById(modalId, { record: insertionOrder })); }}
              >
                Sync Data From HubSpot
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={downloadCsv}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row className="mt-4 mb-2">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Name, Email, ID"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            {/* <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
                </Col> */}
          </Row>
        </Col>
        <Col sm={2} />
      </Row>
      {/* showFilters && (
        <>
          filters
        </>
      ) */}
    </div>
  );
});
Actions.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  // setApprovalStatusFilters: PropTypes.func.isRequired,
  // approvalStatusFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  getInsertionOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  insertionOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  downloadCsv: PropTypes.func.isRequired,
  setStartLoadCreators: PropTypes.func.isRequired,
  dealStageFilters: PropTypes.arrayOf(PropTypes.any),
  setDealStageFilters: PropTypes.func,
  dealstagesOptions: PropTypes.arrayOf(PropTypes.any),
};

Actions.defaultProps = {
  dealStageFilters: [],
  setDealStageFilters: () => {},
  dealstagesOptions: [],
};

export default withNotice(Actions);
