import React, { useEffect, useState, useMemo } from 'react';
import { axios } from 'ApiClient';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import PropTypes from 'prop-types';
import Select from 'react-select';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';

import csvHelper from '../../../../shared/helpers/CSVHelper';


const Actions = ({
  selectedAdvertiser, setSelectedAdvertiser, selectedDealType, setSelectedDealType, selectedEmployee, setSelectedEmployee, onSearchChange, items,
}) => {
  const [advertisers, setAdvertisers] = useState([]);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState([]);
  const [search, setSearch] = useState();
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(true);

  const generateCSVReport = (csvArrayColumns) => {
    const data = [csvArrayColumns.map(column => column.label)];
    items.forEach((record) => {
      const itemRow = [];
      csvArrayColumns.forEach((column) => {
        itemRow.push(column.func(record));
      });
      data.push(itemRow);
    });
    if (csvArrayColumns.find(c => c.total)) {
      const totals = [];
      csvArrayColumns.forEach((column, i) => {
        if (column.total === true) totals[i] = (data.slice(1)).reduce((sum, row) => Number(row[i] || 0) + sum, 0);
        else if (column.total?.toString().length > 0) totals[i] = column.total;
        else totals[i] = '';
      });
      data.push(totals);
      console.log(totals);
    }
    console.log(data);
    csvHelper.generateCSV('CreatorPerformanceReport.csv', data);
  };

  const generateCSVReportStart = async () => {
    const columns = [
      {
        label: 'Creator Name',
        func: deal => `${deal.creator?.first_name || ''} ${deal.creator?.last_name || ''}`,
      }, {
        label: 'Creator Email',
        func: deal => `${deal.creator?.email || ''}`,
      }, {
        label: 'Brand',
        func: deal => `${deal.offer?.advertiser?.companyName || ''}`,
      }, {
        label: 'Deal Name',
        func: deal => `${deal.latestDeal?.dealname || ''}`,
      }, {
        label: 'DealType',
        func: deal => `${deal.latestDeal?.dealTerms.type_of_deal || ''}`,
      }, {
        label: 'Deal Owner',
        func: (p) => {
          if (!p.admin) return '';
          return `${p.admin?.firstName || ''} ${p.admin?.lastName || ''}`;
        },
      },
      {
        label: 'Published Date',
        func: p => p.publishDate,
      },
      {
        label: 'Revenue',
        func: record => (record.revenue || ''),
      }, {
        label: 'Payout',
        func: record => (record.payout || ''),
      }, {
        label: 'Gross Profit',
        func: record => Number(record.revenue || 0) - Number(record.payout || 0),
      }, {
        label: 'Conversions in Timeframe',
        func: deal => Number(deal.cpaCurrent?.length || 0 + deal.cpmCurrent?.length || 0),
      },
      {
        label: 'No. All Time Conversions',
        func: deal => Number(deal.cpaTotal.length) + Number(deal.cpmTotal.length),
      },
      {
        label: 'No. Deals',
        func: deal => deal.dealsCount?.length || 0,
      },
      {
        label: 'No. Content Published',
        func: deal => deal.mediaContentsCount || 0,
      },
      {
        label: 'Creator Profile URL',
        func: deal => `${window.location.origin}/influencer/profile/${deal.creator._id}`,
      },
      {
        label: 'CAC',
        func: deal => (Number(deal.cpaCurrent?.length || 0 + deal.cpmCurrent?.length || 0) > 0 ? Number(deal?.payout || 0) / Number(deal.cpaCurrent?.length || 0 + deal.cpmCurrent?.length || 0) : 0),
      },
      {
        label: 'HubSpot Deal URL',
        func: deal => `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal?.latestDeal.hs_object_id}/`,
      },
      {
        label: 'HubSpot Contact URL',
        func: deal => `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${deal?.creator?.hsContactVid}/`,
      },
    ];
    generateCSVReport(columns);
  };


  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);
  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
  };


  useEffect(() => {
    setLoadingAdvertiser(true);
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser, offers: [] };
        brands[offer.advertiser.tuneId].offers.push(String(offer._id));
      });
      // setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : -1)));
      // setAdvertisers(Object.values(brands));
      // setOffers(resp.data.offers);
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      /* addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      }); */
    });
  }, []);
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    setLoadingAdvertiser(true);
    axios.get('/api/portal/finance/get-deal-owners').then((resp) => {
      setEmployees(resp.data.dealOwners);
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      /* addNotice({
        message: 'There was an error when try load employees, Check out console to see more information.',
        type: 'error',
      }); */
    });
  }, []);
  const advertiserOptions = useMemo(() => advertisers.filter(b => b.displayInUi === 'Yes').map(a => ({ value: a._id, label: a.companyName, offers: a.offers })), [advertisers]);
  const employeeOptions = useMemo(() => {
    const i = employees.map(item => ({ value: String(item._id), label: `${item.firstName || ''} ${item.lastName || ''}`, item }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [employees]);

  return (
    <div className="mt-3">
      <Row>
        <Col sm={6}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Creator Name"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '38px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    height: '38px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col />
        <Col sm="auto" className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '38px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem disabled={false} onClick={() => { generateCSVReportStart(); }}>
                <Tooltip title="Include">
                  <>Download CSV</>
                </Tooltip>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row className="mt-3" >
            <Col>
              <Label className="font-weight-bold">Brand(s)</Label>
              <Select
                isDisabled={loadingAdvertiser}
                value={selectedAdvertiser}
                options={advertiserOptions}
                onChange={setSelectedAdvertiser}
                placeholder="Select Brand..."
                isMulti
              />
            </Col>
            <Col>
              <Label className="font-weight-bold">Deal Owner(s)</Label>
              <Select
                isDisabled={loadingAdvertiser}
                value={selectedEmployee}
                options={employeeOptions}
                onChange={setSelectedEmployee}
                placeholder="Select Deal Owner..."
                isMulti
              />
            </Col>
            <Col>
              <Label className="font-weight-bold">Deal Type(s)</Label>
              <Select
                value={selectedDealType}
                options={[
                  { value: 'Hybrid', label: 'Hybrid' },
                  { value: 'Upfront Only', label: 'Upfront Only' },
                  { value: 'Upfront CPM', label: 'Upfront CPM' },
                  { value: 'CPA/Ambassador', label: 'CPA/Ambassador' },
                ]}
                onChange={setSelectedDealType}
                placeholder="Deal Type..."
                isClearable
                isMulti
              />
            </Col>
            <Col />
          </Row>
        </>
      )}
    </div>
  );
};
Actions.propTypes = {
  setSelectedAdvertiser: PropTypes.func.isRequired,
  selectedAdvertiser: PropTypes.arrayOf(PropTypes.any),
  setSelectedDealType: PropTypes.func.isRequired,
  selectedDealType: PropTypes.arrayOf(PropTypes.any),
  setSelectedEmployee: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.arrayOf(PropTypes.any),
  onSearchChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
Actions.defaultProps = {
  selectedAdvertiser: [],
  selectedDealType: [],
  selectedEmployee: [],
};
export default Actions;
