import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
} from 'reactstrap';
import withNotice from '../../../../../../../App/store/with-notice';

const AddOrRemoveBlackListModal = ({
  isOpen,
  closeFunc,
  setVersion,
  record,
  action,
  addNotice,
  brands,
}) => {
  const [loading, setLoading] = useState(false);
  const [prospectsData, setProspectsData] = useState([]);

  // Fetch Data
  const fetchData = () => {
    setLoading(true);
    axios.get(`/api/portal/blacklist/get-prospects-by-creator-id?tubularCreatorId=${record.params?.tubularDetails?.tubularCreatorId}`).then((response) => {
      if (response.data.success) {
        const data = response.data.prospects.map((item) => {
          const targetBrand = brands.find(brand => brand.value === item.targetAdvertiserId);
          return { name: `${item.firstname} ${item.lastname}`, brand: targetBrand.label, id: item._id };
        });
        setProspectsData(data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  // SUBMIT Data
  const submit = async () => {
    setLoading(true);
    const url = action === 'add' ?
      '/api/portal/blacklist/post-add-item' :
      '/api/portal/blacklist/post-remove-item';

    axios({
      method: 'post',
      url,
      data: {
        tubularCreatorId: record.params?.tubularDetails?.tubularCreatorId,
        hsContactId: record.params?.hsUserId,
        emails: record.params.email ? [record.params.email] : [],
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        closeFunc();
        addNotice({
          message: `Prospect was successfully ${action === 'add' ? 'added to' : 'removed from'} the Blacklist.`,
          type: 'success',
        });
      } else {
        addNotice({
          message: response.data.error || 'Something went wrong...',
          type: 'error',
        });
      }
    });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">{action === 'add' ? 'Add' : 'Remove'} Prospect</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <span>{`Are you sure you would like to ${action === 'add' ? 'add' : 'remove'} selected prospect to Black list?`}</span>
            {prospectsData.map(prospect => (
              <p key={prospect.brand}><a href={`/research/prospects/edit/${prospect.id}`} target="_blank" rel="noreferrer">{prospect.name}</a> - <b>{prospect.brand}</b></p>
            ))}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="danger" onClick={submit} disabled={loading}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>{action === 'add' ? 'Add' : 'Remove'}</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};


AddOrRemoveBlackListModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  // prospectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  action: PropTypes.string,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
};

AddOrRemoveBlackListModal.defaultProps = {
  action: 'add',
};

export default withNotice(AddOrRemoveBlackListModal);
