import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
} from 'reactstrap';
import Select from 'react-select';

const AllTagsModal = ({
  isOpen,
  closeFunc,
  // setVersion,
  allTags,
  tags,
  setTags,
  setTagsFilter,
}) => {
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    if (isOpen) {
      if (tags?.length > 0) {
        setSelectedTags(tags.map(item => ({ value: item.id, label: item.name })));
      }
    }
  }, [isOpen]);

  const defaultStyles = {
    control: base => ({
      ...base,
    }),
    menu: base => ({
      ...base,
    }),
    menuList: base => ({
      ...base,
      minHeight: '500px', // your desired height
    }),
  };

  const setFiltersTag = (tagsNew) => {
    const tagsFilterNew = {};
    if (tagsNew?.length > 0) {
      tagsNew.map((item) => {
        tagsFilterNew[item.name] = true;
        return true;
      });
    }
    setTagsFilter(tagsFilterNew);
  };

  const setTagsToFilter = () => {
    let newTags = [];
    if (selectedTags && selectedTags?.length > 0) {
      newTags = selectedTags.map(item => ({ id: item.value, name: item.label }));
    }
    setTags(newTags);
    setFiltersTag(newTags);
    closeFunc();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '500px' }}
    >
      <ModalHeader toggle={() => { closeFunc(); setSelectedTags([]); }} tag="h4" className="modal-title-statistics">All Tags</ModalHeader>
      <ModalBody style={{ minHeight: '580px' }}>
        <Row>
          <Col>
            <Select
              value={selectedTags}
              options={allTags}
              onChange={setSelectedTags}
              placeholder="Select Tags..."
              isMulti
              menuIsOpen
              styles={defaultStyles}
            />
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={setTagsToFilter}>
          Set
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" onClick={() => { closeFunc(); setSelectedTags([]); }}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};


AllTagsModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  setTagsFilter: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  // setVersion: PropTypes.func.isRequired,
  allTags: PropTypes.arrayOf(PropTypes.any).isRequired,
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AllTagsModal;
