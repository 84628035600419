/* eslint react/prop-types: 0 */
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Container,
  Row,
  Col,
  Card, CardBody, CardTitle, Button,
  Spinner,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { withStyles } from '@material-ui/core/styles';
import withNotice from '../../../../../App/store/with-notice';
import MatTableList from '../../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../../shared/components/BreadCrumbs';
import UserPropertyType from '../../components/custom';
import Actions from './Actions';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import PaymentsStatus from './PaymentsStatus';
import Status from './Status';
import FetchBillsModal from './FetchBillsModal';
import TotalsModal from '../../modals/AmountDueTotalsModal';
import Modal from '../../modals/RefreshBillModal';
import ModalRefreshDeals from '../../modals/RefreshDeals';
import Authorized from './Authorized';
import Email from '../AgentEmail';
import DealStage from './DealStage';

const compare = (a, b) => {
  const keyA = a.label;
  const keyB = b.label;
  if (keyA < keyB) return -1;
  if (keyA > keyB) return 1;
  return 0;
};

const getPaymentStatusInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    paidinfull: false,
    partiallypaid: false,
    unpaid: true,
  };
  if (filters?.paymentStatus) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters.paymentStatus.includes(key);
    });
  }
  return initialFilters;
};

const getStatusInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    unassigned: false,
    approve: true,
    decline: false,
    ignore: false,
  };
  if (filters?.status) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters?.status.includes(key);
    });
  }
  return initialFilters;
};

const getPaymentRecipientInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    agent: true,
    creator: true,
    creatorCOAgent: true,
  };
  if (filters && filters.paymentRecipient) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters.paymentRecipient.includes(key);
    });
  }
  return initialFilters;
};

const getFillterDatesInitial = (dateLabel, dateFilterType) => {
  let localSingleDate = '';
  const filtersObj = parseUrl();
  const type = filtersObj[dateFilterType];
  const fromLabel = `${dateLabel}.from`;
  const toLabel = `${dateLabel}.to`;

  if (type && type !== 'Between') {
    if (type === '>' || type === '>=' || type === '=') {
      if (filtersObj?.[fromLabel]) {
        localSingleDate = new Date(filtersObj[fromLabel]);
      }
    }
    if (type === '<' || type === '<=') {
      if (filtersObj?.[toLabel]) {
        localSingleDate = new Date(filtersObj[toLabel]);
      }
    }
  }

  return localSingleDate;
};

const getSelectedFiltersInitial = (property, initialFilters = []) => {
  const filters = parseUrl();
  if (filters[property]) {
    if (Array.isArray(filters[property])) {
      filters[property].forEach((prop) => {
        initialFilters.push({ value: prop, label: prop });
      });
    } else {
      initialFilters.push({ value: filters[property], label: filters[property] });
    }
  }
  return initialFilters;
};

const getFilterDateTypeInitial = (filterType) => {
  let initialFilterType = '<=';
  const type = new URLSearchParams(window.location.search).get(filterType);
  if (type) {
    initialFilterType = type;
  }
  return initialFilterType;
};

const getSelectedDealStageFiltersInitial = () => {
  const initialFilters = [];
  const filters = new URLSearchParams(window.location.search).getAll('filters.dealstage');
  if (filters) {
    const allDealPipeLines = getConstant('deal', 'pipelines', []);
    const mainPipeline = allDealPipeLines.find(a => a.label === 'Main Pipeline');
    if (Array.isArray(filters)) {
      filters.forEach((prop) => {
        initialFilters.push({ value: prop, label: mainPipeline.stages[prop] });
      });
    } else {
      initialFilters.push({ value: filters, label: mainPipeline.stages[filters] });
    }
  }
  return initialFilters;
};

const getPaymentMethodInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    Stripe: true,
    'Bill.com': true,
    'Not Connected': true,
  };
  if (filters.paymentMethod) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters.paymentMethod.includes(key);
    });
  }
  return initialFilters;
};

const getFilterIgnoreStages = () => {
  let initial = false;
  const additionalParams = new URLSearchParams(window.location.search).get('additionalParams');
  if (additionalParams && additionalParams === 'ignoreDealStages') {
    initial = true;
  }
  return initial;
};

const getAuthorizedInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    Authorized: true,
    'Not Authorized': true,
  };
  if (filters.authorized) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters.authorized.includes(key);
    });
  }
  return initialFilters;
};

const getInvoiceNumber = () => {
  const filters = parseUrl();
  return filters.invoiceNumber;
};


const links = [{ title: 'Home', path: '/' }, { title: 'Finance', path: false }, { title: 'Payables', path: false }, { title: 'Bill', path: false }];

const ResourceAction = ({
  history,
  resource,
  setTag,
  action,
  addNotice,
}) => {
  const filters = parseUrl();
  const [searchString, setSearchString] = useState('');
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [version, setVersion] = useState((new Date()).toString());
  const [paymentStatusFilters, setPaymentStatusFilters] = useState(getPaymentStatusInitial());
  const [statusFilters, setStatusFilters] = useState(getStatusInitial());
  const [paymentRecipientFilters, setPaymentRecipientFilters] = useState(getPaymentRecipientInitial());
  const [showFetchModalLoading, setShowFetchModalLoading] = useState(false);
  const [paymentMethodFilters, setPaymentMethodFilters] = useState(getPaymentMethodInitial());

  const [publishDateFrom, setPublishDateFrom] = useState(filters?.['pusblishedDate.from'] ? moment(moment.utc(filters['pusblishedDate.from']).format('YYYY-MM-DD')).endOf('day').toDate() : null);
  const [publishDateTo, setPublishDateTo] = useState(filters?.['pusblishedDate.to'] ? moment(moment.utc(filters['pusblishedDate.to']).format('YYYY-MM-DD')).endOf('day').toDate() : null);
  const [singlePublishDate, setSinglePublishDate] = useState(null);
  const [lastPublishDateType, setLastPublishDateType] = useState({ value: '', label: '' });

  const [contentReleaseDateFrom, setContentReleaseDateFrom] = useState(filters?.['contentReleaseDate.from'] ? moment(moment.utc(filters['contentReleaseDate.from']).format('YYYY-MM-DD')).endOf('day').toDate() : null);
  const [contentReleaseDateTo, setContentReleaseDateTo] = useState(filters?.['contentReleaseDate.to'] ? moment(moment.utc(filters['contentReleaseDate.to']).format('YYYY-MM-DD')).endOf('day').toDate() : null);
  const [singleContentReleaseDate, setSingleContentReleaseDate] = useState(null);
  const [lastContentReleaseDateType, setLastContentReleaseDateType] = useState({ value: '', label: '' });

  const [brandApprovedDateFrom, setBrandApprovedDateFrom] = useState(filters?.['brandApproved.from'] ? moment(moment.utc(filters['brandApproved.from']).format('YYYY-MM-DD')).endOf('day').toDate() : null);
  const [brandApprovedDateTo, setBrandApprovedDateTo] = useState(filters?.['brandApproved.to'] ? moment(moment.utc(filters['brandApproved.to']).format('YYYY-MM-DD')).endOf('day').toDate() : null);
  const [singleBrandApprovedDate, setSingleBrandApprovedDate] = useState(null);
  const [lastBrandApprovedDateType, setLastBrandApprovedDateType] = useState({ value: '', label: '' });

  const [paymentDateFrom, setPaymentDateFrom] = useState(filters?.['paymentDate.from'] ? moment(moment.utc(filters['paymentDate.from']).format('YYYY-MM-DD')).endOf('day').toDate() : null);
  const [paymentDateTo, setPaymentDateTo] = useState(filters?.['paymentDate.to'] ? moment(moment.utc(filters['paymentDate.to']).format('YYYY-MM-DD')).endOf('day').toDate() : null);
  const [singlePaymentDate, setSinglePaymentDate] = useState(null);
  const [lastPaymentDateType, setLastPaymentDateType] = useState({ value: '', label: '' });

  const [loadingBrandOptions, setLoadingBrandOptions] = useState(true);
  const [brandOptions, setBrandOptions] = useState(getSelectedFiltersInitial('brand'));
  const [selectedBrands, setSelectedBrands] = useState(getSelectedFiltersInitial('brand'));

  const [selectedDealTypes, setSelectedDealTypes] = useState(getSelectedFiltersInitial('typeOfDeal'));
  const [dealStageFilters, setDealStageFilters] = useState(getSelectedDealStageFiltersInitial);
  const [ignoreFiltersOnStart] = useState(getFilterIgnoreStages());

  const [loadingTotals, setLoadingTotals] = useState(true);
  const [totals, setTotals] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState(getInvoiceNumber());
  const [authorized, setAuthorizedFilters] = useState(getAuthorizedInitial());
  const [mainPipelineStages, setMainPipelineStages] = useState({});
  const [dealstageOption, setDealstageOptions] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const fetchTotalsData = async () => {
    setLoadingTotals(true);
    try {
      const search = new URLSearchParams(window.location.search);
      const resp = await axios.get(`/api/portal/finance/get-pending-bills-totals-data?${search.toString()}`);
      if (resp?.data?.success) {
        const amountDue = resp.data.amountDue === '' ? <span>&mdash;</span> : resp.data.amountDue;
        const stripe = resp.data.stripe === '' ? <span>&mdash;</span> : resp.data.stripe;
        const billcom = resp.data.billcom === '' ? <span>&mdash;</span> : resp.data.billcom;
        const unverified = resp.data.unverified === '' ? <span>&mdash;</span> : resp.data.unverified;
        setTotals({
          amountDue,
          stripe,
          billcom,
          unverified,
        });
        setLoadingTotals(false);
      } else {
        throw new Error(resp?.data?.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const fetchBrandOptions = async () => {
    setLoadingBrandOptions(true);
    try {
      const resp = await axios.get('/api/portal/scorecard/media-content/get-filters-data-set');
      if (resp?.data?.success) {
        setBrandOptions(resp?.data?.brandOptions?.map(({ label }) => ({ value: label, label })).sort(compare) || []);
        setLoadingBrandOptions(false);
      } else {
        throw new Error(resp?.data?.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    fetchBrandOptions();

    // set initial values if not set
    setSingleContentReleaseDate(getFillterDatesInitial('contentReleaseDate', 'crType'));
    setSinglePublishDate(getFillterDatesInitial('pusblishedDate', 'pdType'));
    setSingleBrandApprovedDate(getFillterDatesInitial('brandApprovedDate', 'baType'));
    setSinglePaymentDate(getFillterDatesInitial('paymentDate', 'pmntdType'));

    const crType = getFilterDateTypeInitial('crType');
    setLastContentReleaseDateType({ value: crType, label: crType });
    const pdType = getFilterDateTypeInitial('pdType');
    setLastPublishDateType({ value: pdType, label: pdType });
    const baType = getFilterDateTypeInitial('baType');
    setLastBrandApprovedDateType({ value: baType, label: baType });
    const pmntdType = getFilterDateTypeInitial('pmntdType');
    setLastPaymentDateType({ value: pmntdType, label: pmntdType });

    if (selectedDealTypes.length === 0) {
      setSelectedDealTypes([
        { value: 'Upfront Only', label: 'Upfront Only' },
        { value: 'Upfront CPM', label: 'Upfront CPM' },
        { value: 'Hybrid', label: 'Hybrid' },
        { value: 'Bonus', label: 'Bonus' },
      ]);
    }
    // get main pipeline deals
    const allDealPipeLines = getConstant('deal', 'pipelines', []);
    const mainPipeline = allDealPipeLines.find(a => a.label === 'Main Pipeline');
    setMainPipelineStages(mainPipeline.stages);
    if (dealStageFilters.length === 0 && !ignoreFiltersOnStart) {
      let dealPublished = {}
      const { stages } = mainPipeline;
      Object.keys(stages).forEach((key) => {
        if (stages[key].toLowerCase() === 'published') dealPublished = { value: key, label: 'Published' };
      });
      setDealStageFilters([
        dealPublished,
      ]);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(mainPipelineStages).length) {
      const options = [];
      Object.keys(mainPipelineStages).forEach(key => options.push({ value: key, label: mainPipelineStages[key] }));
      setDealstageOptions(options);
    }
  }, [mainPipelineStages]);

  const getUrlSearch = (removeBeforeSet = false) => {
    let search = new URLSearchParams(window.location.search);
    if (removeBeforeSet) {
      search = new URLSearchParams();
    }
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }

    // PublishDate
    search.delete('filters.pusblishedDate.from');
    search.delete('filters.pusblishedDate.to');
    const momentPublishDateFrom = moment(publishDateFrom);
    if (publishDateFrom && momentPublishDateFrom.isValid()) {
      search.set('filters.pusblishedDate.from', `${momentPublishDateFrom.format('YYYY-MM-DD')} 00:00:00`);
    }

    const momentPublishDateTo = moment(publishDateTo);
    if (publishDateTo && momentPublishDateTo.isValid()) {
      search.set('filters.pusblishedDate.to', `${momentPublishDateTo.format('YYYY-MM-DD')} 23:59:59`);
    }

    const momentSinglePublishDate = moment(singlePublishDate);
    if (singlePublishDate && momentSinglePublishDate.isValid()) {
      const type = lastPublishDateType.value;
      if (type === '>') {
        search.set('filters.pusblishedDate.from', `${momentSinglePublishDate.format('YYYY-MM-DD')} 23:59:59`);
      }
      if (type === '>=') {
        search.set('filters.pusblishedDate.from', `${momentSinglePublishDate.format('YYYY-MM-DD')} 00:00:00`);
      }
      if (type === '<') {
        search.set('filters.pusblishedDate.to', `${momentSinglePublishDate.format('YYYY-MM-DD')} 00:00:00`);
      }
      if (type === '<=') {
        search.set('filters.pusblishedDate.to', `${momentSinglePublishDate.format('YYYY-MM-DD')} 23:59:59`);
      }
    }

    if (lastPublishDateType) {
      search.set('filters.pdType', lastPublishDateType.value);
    } else {
      search.delete('filters.pdType');
    }
    // PublishDate

    // contentReleaseDate
    search.delete('filters.contentReleaseDate.from');
    search.delete('filters.contentReleaseDate.to');
    const momentContentReleaseDateFrom = moment(contentReleaseDateFrom);
    if (contentReleaseDateFrom && momentContentReleaseDateFrom.isValid()) {
      search.set('filters.contentReleaseDate.from', `${momentContentReleaseDateFrom.format('YYYY-MM-DD')} 00:00:00`);
    }

    const momentContentReleaseDateTo = moment(contentReleaseDateTo);
    if (contentReleaseDateTo && momentContentReleaseDateTo.isValid()) {
      search.set('filters.contentReleaseDate.to', `${momentContentReleaseDateTo.format('YYYY-MM-DD')} 23:59:59`);
    }

    const momentSingleContentReleaseDate = moment(singleContentReleaseDate);
    if (singleContentReleaseDate && momentSingleContentReleaseDate.isValid()) {
      const type = lastContentReleaseDateType.value;
      if (type === '>') {
        search.set('filters.contentReleaseDate.from', `${momentSingleContentReleaseDate.format('YYYY-MM-DD')} 23:59:59`);
      }
      if (type === '>=') {
        search.set('filters.contentReleaseDate.from', `${momentSingleContentReleaseDate.format('YYYY-MM-DD')} 00:00:00`);
      }
      if (type === '<') {
        search.set('filters.contentReleaseDate.to', `${momentSingleContentReleaseDate.format('YYYY-MM-DD')} 00:00:00`);
      }
      if (type === '<=') {
        search.set('filters.contentReleaseDate.to', `${momentSingleContentReleaseDate.format('YYYY-MM-DD')} 23:59:59`);
      }
      if (type === '=') {
        search.set('filters.contentReleaseDate.from', `${momentSingleContentReleaseDate.format('YYYY-MM-DD')} 00:00:00`);
        search.set('filters.contentReleaseDate.to', `${momentSingleContentReleaseDate.format('YYYY-MM-DD')} 23:59:59`);
      }
    }
    if (lastContentReleaseDateType) {
      search.set('filters.crType', lastContentReleaseDateType.value);
    } else {
      search.delete('filters.crType');
    }
    // contentReleaseDate

    // BrandApprovedDate
    search.delete('filters.brandApprovedDate.from');
    search.delete('filters.brandApprovedDate.to');
    const momentBrandApprovedDateFrom = moment(brandApprovedDateFrom);
    if (brandApprovedDateFrom && momentBrandApprovedDateFrom.isValid()) {
      search.set('filters.brandApprovedDate.from', `${momentBrandApprovedDateFrom.format('YYYY-MM-DD')} 00:00:00`);
    }

    const momentBrandApprovedDateTo = moment(brandApprovedDateTo);
    if (brandApprovedDateTo && momentBrandApprovedDateTo.isValid()) {
      search.set('filters.brandApprovedDate.to', `${momentBrandApprovedDateTo.format('YYYY-MM-DD')} 23:59:59`);
    }

    const momentSingleBrandApprovedDateDate = moment(singleBrandApprovedDate);
    if (singleBrandApprovedDate && momentSingleBrandApprovedDateDate.isValid()) {
      const type = lastBrandApprovedDateType.value;
      if (type === '>') {
        search.set('filters.brandApprovedDate.from', `${momentSingleBrandApprovedDateDate.format('YYYY-MM-DD')} 23:59:59`);
      }
      if (type === '>=') {
        search.set('filters.brandApprovedDate.from', `${momentSingleBrandApprovedDateDate.format('YYYY-MM-DD')} 00:00:00`);
      }
      if (type === '<') {
        search.set('filters.brandApprovedDate.to', `${momentSingleBrandApprovedDateDate.format('YYYY-MM-DD')} 00:00:00`);
      }
      if (type === '<=') {
        search.set('filters.brandApprovedDate.to', `${momentSingleBrandApprovedDateDate.format('YYYY-MM-DD')} 23:59:59`);
      }
      if (type === '=') {
        search.set('filters.brandApprovedDate.from', `${momentSingleBrandApprovedDateDate.format('YYYY-MM-DD')} 00:00:00`);
        search.set('filters.brandApprovedDate.to', `${momentSingleBrandApprovedDateDate.format('YYYY-MM-DD')} 23:59:59`);
      }
    }

    if (lastBrandApprovedDateType) {
      search.set('filters.baType', lastBrandApprovedDateType.value);
    } else {
      search.delete('filters.baType');
    }
    // BrandApprovedDate

    // PaymentDate
    search.delete('filters.paymentDate.from');
    search.delete('filters.paymentDate.to');
    const momentPaymentDateFrom = moment(paymentDateFrom);
    if (paymentDateFrom && momentPaymentDateFrom.isValid()) {
      search.set('filters.paymentDate.from', `${momentPaymentDateFrom.format('YYYY-MM-DD')} 00:00:00`);
    }

    const momentPaymentDateTo = moment(paymentDateTo);
    if (paymentDateTo && momentPaymentDateTo.isValid()) {
      search.set('filters.paymentDate.to', `${momentPaymentDateTo.format('YYYY-MM-DD')} 23:59:59`);
    }

    const momentSinglePaymentDateDate = moment(singlePaymentDate);
    if (singlePaymentDate && momentSinglePaymentDateDate.isValid()) {
      const type = lastPaymentDateType.value;
      if (type === '>') {
        search.set('filters.paymentDate.from', `${momentSinglePaymentDateDate.format('YYYY-MM-DD')} 23:59:59`);
      }
      if (type === '>=') {
        search.set('filters.paymentDate.from', `${momentSinglePaymentDateDate.format('YYYY-MM-DD')} 00:00:00`);
      }
      if (type === '<') {
        search.set('filters.paymentDate.to', `${momentSinglePaymentDateDate.format('YYYY-MM-DD')} 00:00:00`);
      }
      if (type === '<=') {
        search.set('filters.paymentDate.to', `${momentSinglePaymentDateDate.format('YYYY-MM-DD')} 23:59:59`);
      }
      if (type === '=') {
        search.set('filters.paymentDate.from', `${momentSinglePaymentDateDate.format('YYYY-MM-DD')} 00:00:00`);
        search.set('filters.paymentDate.to', `${momentSinglePaymentDateDate.format('YYYY-MM-DD')} 23:59:59`);
      }
    }

    if (lastPaymentDateType) {
      search.set('filters.pmntdType', lastPaymentDateType.value);
    } else {
      search.delete('filters.pmntdType');
    }
    // PaymentDate

    const filterBrands = search.getAll('filters.brand');
    let deleteFilterBrands = false;

    if (!selectedBrands || selectedBrands.length === 0) {
      search.delete('filters.brand');
    }
    if (selectedBrands) {
      filterBrands.forEach((brand) => {
        if (selectedBrands.filter(item => item.value === brand).length === 0) {
          deleteFilterBrands = true;
        }
      });
      if (deleteFilterBrands) {
        search.delete('filters.brand');
      }
      selectedBrands.forEach((brand) => {
        if (brand.value !== '' && (!filterBrands.includes(brand.value) || deleteFilterBrands)) {
          search.append('filters.brand', brand.value);
        }
      });
    }

    if (paymentStatusFilters && Object.keys(paymentStatusFilters).length > 0) {
      search.delete('filters.paymentStatus');
      Object.keys(paymentStatusFilters).forEach((key) => {
        if (!paymentStatusFilters[key]) return false;
        search.append('filters.paymentStatus', key);
        return true;
      });
    }
    if (statusFilters && Object.keys(statusFilters).length > 0) {
      search.delete('filters.status');
      Object.keys(statusFilters).forEach((key) => {
        if (!statusFilters[key]) return false;
        search.append('filters.status', key);
        return true;
      });
    }

    if(paymentMethodFilters) {
      search.delete('filters.paymentMethod');
      Object.keys(paymentMethodFilters).forEach((key) => {
        if (!paymentMethodFilters[key]) return false;
        search.append('filters.paymentMethod', key);
        return true;
      });
    }

    if (selectedDealTypes && selectedDealTypes.length > 0) {
      search.delete('filters.typeOfDeal');
      for (let i = 0; i < selectedDealTypes.length; i += 1) {
        search.append('filters.typeOfDeal', selectedDealTypes[i].value);
      }
    } else {
      search.delete('filters.typeOfDeal');
    }

    if (dealStageFilters && dealStageFilters.length > 0) {
      search.delete('filters.dealstage');
      search.delete('additionalParams');
      for (let i = 0; i < dealStageFilters.length; i += 1) {
        search.append('filters.dealstage', dealStageFilters[i].value);
      }
    } else {
      search.delete('filters.dealstage');
    }

    if (paymentRecipientFilters && Object.keys(paymentRecipientFilters).length > 0) {
      search.delete('filters.paymentRecipient');
      Object.keys(paymentRecipientFilters).forEach((key) => {
        if (!paymentRecipientFilters[key]) return false;
        search.append('filters.paymentRecipient', key);
        return true;
      });
    }
    if (authorized && Object.keys(authorized).length > 0) {
      search.delete('filters.authorized');
      Object.keys(authorized).forEach((key) => {
        if (!authorized[key]) return false;
        search.append('filters.authorized', key);
        return true;
      });
    }

    if (invoiceNumber) {
      search.set('filters.invoiceNumber', invoiceNumber);
    } else {
      search.delete('filters.invoiceNumber');
    }
    setSearchString(search.toString());
    return search;
  };

  const setUrl = (refreshFilters = false) => {
    const search = getUrlSearch(refreshFilters);
    search.set('page', '1');
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 800);

  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
    paymentStatusFilters,
    statusFilters,
    dealStageFilters,
    paymentRecipientFilters,
    selectedBrands,
    invoiceNumber,
    authorized,
    //
    publishDateFrom,
    publishDateTo,
    singlePublishDate,
    lastPublishDateType,
    //
    contentReleaseDateFrom,
    contentReleaseDateTo,
    singleContentReleaseDate,
    lastContentReleaseDateType,
    //
    brandApprovedDateFrom,
    brandApprovedDateTo,
    singleBrandApprovedDate,
    lastBrandApprovedDateType,
    //
    paymentDateFrom,
    paymentDateTo,
    singlePaymentDate,
    lastPaymentDateType,
    //
    selectedDealTypes,
    paymentMethodFilters,
  ]);

  const setTableShow = useAsyncDebounce(() => {
    setShowTable(true);
  }, 1000);
  useEffect(() => {
    setTableShow();
  }, [window.location.href])

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs links={links} isBackButton />
        </Col>
      </Row>
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Bills</h3>
              </Col>
              <Col md="8" className="text-right">
                {/* <a href="/finance/bills" role="button" className="btn btn-primary btn-sm">Refresh</a> */}
                <Button className="btn btn-primary btn-sm" onClick={() => setUrl(true)} style={{ color: 'white' }} >Refresh</Button>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Actions
            paymentStatusFilters={paymentStatusFilters}
            setPaymentStatusFilters={setPaymentStatusFilters}
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters}
            dealStageFilters={dealStageFilters}
            setDealStageFilters={setDealStageFilters}
            dealstageOption={dealstageOption}
            paymentRecipientFilters={paymentRecipientFilters}
            setPaymentRecipientFilters={setPaymentRecipientFilters}
            //
            paymentMethodFilters={paymentMethodFilters}
            setPaymentMethodFilters={setPaymentMethodFilters}
            //
            publishDateFrom={publishDateFrom}
            setPublishDateFrom={setPublishDateFrom}
            publishDateTo={publishDateTo}
            setPublishDateTo={setPublishDateTo}
            singlePublishDate={singlePublishDate}
            setSinglePublishDate={setSinglePublishDate}
            lastPublishDateType={lastPublishDateType}
            setLastPublishDateType={setLastPublishDateType}
            //
            contentReleaseDateFrom={contentReleaseDateFrom}
            setContentReleaseDateFrom={setContentReleaseDateFrom}
            contentReleaseDateTo={contentReleaseDateTo}
            setContentReleaseDateTo={setContentReleaseDateTo}
            singleContentReleaseDate={singleContentReleaseDate}
            setSingleContentReleaseDate={setSingleContentReleaseDate}
            lastContentReleaseDateType={lastContentReleaseDateType}
            setLastContentReleaseDateType={setLastContentReleaseDateType}
            //
            setBrandApprovedDateFrom={setBrandApprovedDateFrom}
            setBrandApprovedDateTo={setBrandApprovedDateTo}
            setSingleBrandApprovedDate={setSingleBrandApprovedDate}
            setLastBrandApprovedDateType={setLastBrandApprovedDateType}
            lastBrandApprovedDateType={lastBrandApprovedDateType}
            brandApprovedDateFrom={brandApprovedDateFrom}
            brandApprovedDateTo={brandApprovedDateTo}
            singleBrandApprovedDate={singleBrandApprovedDate}
            //
            setPaymentDateFrom={setPaymentDateFrom}
            setPaymentDateTo={setPaymentDateTo}
            setSinglePaymentDate={setSinglePaymentDate}
            setLastPaymentDateType={setLastPaymentDateType}
            lastPaymentDateType={lastPaymentDateType}
            paymentDateFrom={paymentDateFrom}
            paymentDateTo={paymentDateTo}
            singlePaymentDate={singlePaymentDate}
            //
            selectedDealTypes={selectedDealTypes}
            setSelectedDealTypes={setSelectedDealTypes}
            //
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            setShowFetchModalLoading={setShowFetchModalLoading}
            setInvoiceNumber={setInvoiceNumber}
            history={history}
            loadingBrandOptions={loadingBrandOptions}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            brandOptions={brandOptions}
            authorized={authorized}
            setAuthorizedFilters={setAuthorizedFilters}
            csvDownloadLink={`/resources/Bill/download-all-csv?${searchString}`}
          />
          {showTable ? (
            <div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
            <MatTableList
              showTitle={false}
              action={action}
              resource={resource}
              setTag={setTag}
              date={new Date()}
              version={version}
              UserPropertyType={UserPropertyType}
              csvDownload={{ link: `/resources/Bill/download-all-csv${searchString ? `?${searchString}` : ''}` }}
              meta={{
                closeFunction: () => {
                  setVersion((new Date()).toString());
                },
              }}
              v={2}
              isResizable={false}
              sortBy="brandApprovedDate"
              direction="desc"
              columnsSettings={{
                'influencerObject.fullName': {
                  Header: () => 'Creator',
                  width: 300,
                  Cell: ({ row }) => (
                    <div>
                      <span>
                        <b>{row.original?.params['influencerObject.fullName'] ? (
                          <a
                            title="Show Influencer"
                            target="_blank"
                            rel="noopener noreferrer"
                            className=""
                            href={`/influencer/profile/${row.original?.params['influencerObject._id']}`}
                          >
                            {row.original?.params['influencerObject.fullName']}
                          </a>
                      ) : null}
                        </b>
                      </span>
                      <br />
                      <span><b> {row.original?.params['influencerObject.affiliateDataObject.company']} </b></span>
                      <br />
                      <Email record={row.original} />
                    </div>
                  ),
                  disableSortBy: true,
                  isFreeze: true,
                },
                authorized: {
                  Header: () => 'Authorized?',
                  sortable: false,
                  disableSortBy: true,
                  width: 100,
                  Cell: p => <Authorized record={p.row.original} />,
                },
                'dealObject.dealTerms.brand': {
                  width: 120,
                  Header: () => 'Brand',
                },
                invoiceNumber: {
                  width: 100,
                },
                status: {
                  width: 160,
                  Header: () => 'Approval Status',
                  Cell: p => useMemo(() => (
                    <Status
                      record={p.row.original}
                      afterSaveFunc={() => setVersion((new Date()).toString())}
                      updateRecordFunction={p.updateRecordFunction}
                    />), [p.row.original]),
                },
                paymentStatus: {
                  width: 160,
                  Cell: p => useMemo(() => (
                    <PaymentsStatus
                      record={p.row.original}
                      afterSaveFunc={() => {
                        setVersion((new Date()).toString());
                      }}
                      updateRecordFunction={p.updateRecordFunction}
                    />), [p.row.original]),
                },
                amount: {
                  Header: () => 'Total Amount',
                  width: 120,
                },
                amountDue: {
                  width: 120,
                },
                actions: {
                  width: 45,
                },
                dealstage: {
                  width: 110,
                  Cell: p => useMemo(() => (
                    <DealStage record={p.row.original} mainPipelineStages={mainPipelineStages} />
                    ), [p.row.original]),
                },
                brandApprovedDate: {
                  width: 140,
                  Header: () => 'Brand Approved Date',
                  Cell: p => useMemo(() => (p.row.original.params.brandApprovedDate ? moment(p.row.original.params.brandApprovedDate.split('T')[0]).format('MM/DD/YY') : '-'), [p.row.original]),
                },
                publishDate: {
                  width: 140,
                  Header: () => 'Publish Date',
                  Cell: p => useMemo(() => {
                    if (p.row.original.params['dealObject.dealTerms.content_type'] === 'YouTube') {
                      return p.row.original.params.publishDate ? moment(p.row.original.params.publishDate.split('T')[0]).format('MM/DD/YY') : '-';
                    }
                    return 'N/A';
                  }, [p.row.original]),
                },
                contentReleaseDate: {
                  width: 140,
                  Header: () => 'Content Release Date',
                  Cell: p => useMemo(() => (p.row.original.params.contentReleaseDate
                    ? moment(p.row.original.params.contentReleaseDate.split('T')[0]).format('MM/DD/YY')
                    : '-'), [p.row.original]),
                },
                paymentDate: {
                  width: 140,
                  Header: () => 'Payment Date',
                  Cell: p => useMemo(() => (p.row.original.params.paymentDate
                    ? moment(p.row.original.params.paymentDate.split('T')[0]).format('MM/DD/YY')
                    : 'Not Paid'), [p.row.original]),
                  disableSortBy: false,
                },
              }}
            />
          </div>) : (<div className="text-center text-primary"><Spinner /></div>)}

        </CardBody>
      </Card>
      <FetchBillsModal show={!!showFetchModalLoading} showModal={setShowFetchModalLoading} />
      <Modal
        updateTable={() => {
          setVersion((new Date()).toString());
        }}
        addNotice={addNotice}
      />
      <ModalRefreshDeals
        updateTable={() => {
          setVersion((new Date()).toString());
        }}
        addNotice={addNotice}
      />
      <TotalsModal
        fetchTotalsData={fetchTotalsData}
        totals={totals}
        loadingTotals={loadingTotals}
      />
    </Container>
  );
};

const mapStateToProps = state => ({

  resources: state.resources,
  addNotice: PropTypes.func.isRequired,
});

export default withStyles({
  root: {
    width: '100%',
    minWidth: '200px',
    paddingRight: '30px',
    position: 'relative',
    marginBottom: '10px',
  },
  close: {
    position: 'absolute',
    bottom: '1px',
    right: '0px',

  },
})(connect(mapStateToProps)(withNotice(ResourceAction)));
