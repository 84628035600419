import React from 'react';
// import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import MatTableList from '@/shared/tables/materialTable/TableRedux';
// import formater from '../../../../../../../shared/helpers/WVFormatter';
import CreatorInList from './CreatorInList';
import { getConstant } from '@/shared/helpers/WVConstants';
import TableCellContextMenu from '@/shared/components/table/TableCellContextMenu';
// import PaymentMethod from './PaymentMethod';
// import Payments from './Payments';
// import Hook from '@/shared/hooks';

const DealStage = ({ record }) => {
  const dealstages = (getConstant('deal', 'pipelines').find(p => p.label === 'Main Pipeline')).stages;
  const r = record;
  let dealStage = '-';
  if (r.params.dealObject && r.params.dealObject.dealstage) {
    dealStage = '-';

    if (dealstages[r.params.dealObject.dealstage]) dealStage = dealstages[r.params.dealObject.dealstage];
  }
  return (
    <div>
      { dealStage }
    </div>
  );
};
DealStage.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

const Table = React.memo(() => {
  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Payments Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'fullSearchName',
          Header: () => 'Creator',
          accessor: () => (''),
          // eslint-disable-next-line max-len
          Cell: p => (<CreatorInList record={p.row.original} />),
          width: 260,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'dealname',
          Header: () => 'Deal Name',
          accessor: record => (record?.params?.dealname || ''),
          // eslint-disable-next-line max-len
          Cell: p => <a
            href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original.params.hs_object_id}/`}
            target="_blank"
            title="Open Hubspot Deal"
            rel="noreferrer noopener"
          >{p.value}</a>,
          width: 100,
          className: '',
        },
        {
          id: 'dealTerms.type_of_deal',
          Header: () => 'Deal Type',
          accessor: record => (record?.params?.dealTerms.type_of_deal || ''),
          // eslint-disable-next-line max-len
          Cell: p => (p.value),
          width: 100,
          className: '',
        },
        {
          id: 'dealstage',
          Header: () => 'Deal Stage',
          accessor: record => (record?.params?.dealstage || ''),
          Cell: p => (<DealStage record={p.row.original} />),
          className: '',
          width: 100,
          disableSortBy: true,
        },
        {
          Header: '',
          accessor: () => null,
          id: 'actions',
          width: 50,
          Cell: (p) => {
            console.log(p)
            const contextMenuOptions = [
              
            ];
            return (
              <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                <TableCellContextMenu options={contextMenuOptions} />
              </div>
            );
          },
          disableSortBy: true,
          className: 'd-flex align-items-center align-middle',
        },
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
