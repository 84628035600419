import { unflatten } from 'flat';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TableCellContextMenu from './TableCellContextMenu';
import UserPropertyType from './index';
import MatTableList from '../../../../../../../shared/tables/materialTable/MatTableList';
import CreatorInList from '../../../Prospects/components/custom/CreatorInList';
import CopyToClipboard from '../../../../../../../shared/components/CopyToClipboard';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';

const moment = require('moment');


const Table = React.memo(({
  action,
  resource,
  // setSingleSelectedHsContactId,
  // addNotice,
  version,
  handleSelectCheckbox,
  setMetaTotal,
  // setShowGrossProfitModal,
  showRecycleModal,
  showRecycleHistory,
}) => (
  <div style={{ marginLeft: '-15px', marginRight: '-15px' }} >
    <MatTableList
      action={action}
      resource={resource}
      date={new Date()}
      version={version}
      UserPropertyType={UserPropertyType}
      showFilters={false}
      showTitle={false}
      sortBy="notes_last_updated"
      direction="desc"
      v={2}
      noActions
      isResizable={false}
      useCheckboxes
      checkboxHandler={handleSelectCheckbox}
      setMetaTotal={setMetaTotal}
      enableWarning={false}
      notFoundProps={{ title: 'No Recycle Leads found' }}
      useFlex
      customColumns={[
        {
          id: 'fullSearchName',
          Header: () => 'Creator',
          accessor: () => '',
          Cell: p => useMemo(
            () => {
              const unFlatP = unflatten(p.row.original.params);
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              const recycleComponent = unFlatP.recycling && unFlatP.recycling.length ? (<span
                style={{ cursor: 'pointer', color: '#70bbfd', marginLeft: '5px' }}
                title="Show Recycling History"
                onClick={() => { showRecycleHistory(unFlatP); }}
              >
                <i className="fa fa-recycle" />
                { /* eslint-disable-next-line */ }
              </span>) : (<></>);
              return unFlatP.prospectObject ? (
                <CreatorInList
                  record={{ params: unFlatP.prospectObject, id: unFlatP.prospectObject._id }}
                  editHandler={() => {}}
                  addEditIcon={false}
                  addShieldIcons={false}
                  setOpenTabs={() => {}}
                  setCurrentProspect={() => {}}
                  customComponent={recycleComponent}
                  forcedEmailAddress={unFlatP.email}
                />
              ) : (
                <>
                  <b>{unFlatP.firstname} {unFlatP.lastname}</b>
                  <br />
                  <CopyToClipboard value={unFlatP.email}>
                    {unFlatP.email}
                  </CopyToClipboard>
                  {unFlatP.hs_object_id && (
                    <a
                      title="Show HubSpot Contact"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${unFlatP.hs_object_id}`}
                    >
                      <i className="fab fa-hubspot" />
                    </a>
                  )}
                  {recycleComponent}
                </>
              );
            },
            [p.row.original],
          ),
          width: 400,
        },
        {
          Header: 'Contact Owner',
          accessor: () => null,
          id: 'fullContactOwner',
          width: 120,
          Cell: p => p.row.original.params.fullContactOwner,
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: 'Last Activity Date',
          accessor: () => null,
          id: 'notes_last_updated',
          width: 140,
          Cell: p => moment(p.row.original.params.notes_last_updated).format('M/D/YYYY'),
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: 'Recycled Date',
          accessor: () => null,
          id: 'recycleDate',
          width: 140,
          Cell: (p) => {
            if (p.row.original.params.recycleDate !== '') {
              return moment(p.row.original.params.recycleDate).format('M/D/YYYY');
            }
            return '---';
          },
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: 'No. Deals',
          accessor: () => null,
          id: 'num_associated_deals',
          width: 120,
          Cell: p => p.row.original.params.num_associated_deals || 0,
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: 'Gross Profit',
          accessor: () => null,
          id: 'grossProfit',
          width: 120,
          Cell: p => (p.row.original.params.grossProfit || p.row.original.params.grossProfit === 0 ? WVFormatter.formatCurrency(p.row.original.params.grossProfit) : '---'),
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: '',
          accessor: () => null,
          id: 'contacts_actions',
          width: 30,
          Cell: (p) => {
            const contextMenuOptions = [
              /* {
                disabled: false,
                label: 'View Gross Profit',
                handler: () => { setSingleSelectedHsContactId(p.row.original.id); setShowGrossProfitModal(true); },
              }, */
              {
                disabled: false,
                label: 'Recycle',
                handler: () => { showRecycleModal(p.row.original.id); },
              },
            ];
            return (
              <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                <TableCellContextMenu options={contextMenuOptions} />
              </div>
            );
          },
          disableSortBy: true,
          className: 'align-middle',
        },
      ]}
    />
  </div>
), (a, a1) => _.isEqual(a.version, a1.version));

Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  // setVersion: PropTypes.func.isRequired,
  // setSingleSelectedHsContactId: PropTypes.func.isRequired,
  // setShowGrossProfitModal: PropTypes.func.isRequired,
  handleSelectCheckbox: PropTypes.func.isRequired,
  setMetaTotal: PropTypes.func.isRequired,
  showRecycleModal: PropTypes.func.isRequired,
  showRecycleHistory: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // addNotice: PropTypes.func.isRequired,
};
export default Table;
