import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { axios } from 'ApiClient';
import Select from 'react-select';
import { uid } from 'react-uid';
// import PropTypes from 'prop-types';
// import DatePicker from 'react-datepicker';
import ApiClient from 'ApiClient';
import { Row, Col, Label, Nav, NavLink, NavItem, TabContent, TabPane, FormGroup, Spinner, Alert } from 'reactstrap';
import PropTypes from 'prop-types';

import formater from '../../../../shared/helpers/WVFormatter';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';


const Item = ({ items }) => (
  <div
    style={{
      padding: '15px 15px',
      border: '1px solid #cccccc',
      borderRadius: '5px',
      height: '100%',
    }}
  >
    <Row>
      {items.map(({ label, value }) => (
        <Col key={uid(label)}>
          <div style={{ textAlign: 'center', color: 'black' }} >
            <span
              style={{
                fontSize: '20px',
                fontWeight: '700',
                marginBottom: '12px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {value}
            </span>
            <span style={{ width: '100%', fontSize: '16px' }}>{label}</span>
          </div>
        </Col>
      ))}
    </Row>
  </div>);
Item.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.any),
    ]).isRequired,
  })).isRequired,
};

const SummaryReport = ({
  addNotice, contractsSignedByBrand, contractsSignedByOffer, prospectsByBrand, reportData, cspOptions, loading, cpmConversions, cpmDeals,
}) => {
  // const [loading, setLoaing] = useState(false);
  const [loadingAdvertiser, setLoaingAdvertiser] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  // const [cspOptions, setCspOptions] = useState([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState(null);
  const [selectedCsps, setSelectedCsps] = useState(null);
  // const [reportData, setReportData] = useState([]);
  const [items, setItems] = useState([]);
  const [offers, setOffers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [selectedMarketingCampaign, setSelectedMarketingCampaign] = useState([]);
  const [filtersMarketingCampaign, setFiltersMarketingCampaign] = useState({});
  // const [error, setError] = useState(null);
  // const [version, setVersion] = useState((new Date()).toString());
  // const includedLastMonth = moment(startDate).toDate() < moment().startOf('month').toDate() && moment(endDate).toDate() > moment().subtract(1, 'month').startOf('month').toDate();
  useEffect(async () => {
    const api = new ApiClient();
    setLoaingAdvertiser(true);
    api.client.get('/api/portal/finance/get-advertisers').then((resp) => {
      setAdvertisers(resp.data.advertisers);
      setLoaingAdvertiser(false);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
    const r = await axios({
      method: 'get',
      url: '/api/portal/adminUsers/get-marketing-campaing',
    });
    // const mc = {};
    // r.data?.marketingCampaigns?.forEach((item) => { mc[item.value] = item.value; });
    setFiltersMarketingCampaign(r.data?.marketingCampaigns || []);
  }, []);
  const columnsBrands = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: deal => deal?.brand.companyName || '',
        id: 'Brand',
        Cell: ({ value }) => value || '',
        width: 250,
      },
      {
        Header: 'Contracts Signed',
        accessor: deal => Number((deal.contractsSigned || 0)),
        id: 'cSigned',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.cSigned) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Clicks',
        accessor: deal => Number((deal.clicks || 0)),
        id: 'clicks',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.clicks) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalAge)}</span>;
        },
        width: 100,
      },
      {
        Header: 'Revenue Conversions',
        accessor: deal => Number((deal.conversions || 0)),
        id: 'conversions',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalAge)}</span>;
        },
        width: 240,
      },
      {
        Header: 'Payout Conversions',
        accessor: deal => Number((deal.payoutConversions || 0)),
        id: 'pConversions',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.pConversions) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalAge)}</span>;
        },
        width: 240,
      },
      {
        Header: 'Total Revenue Amount',
        accessor: deal => ((deal.revenue || 0)),
        id: 'revenue',
        Cell: ({ value }) => formater.formatCurrency(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 240,
      },
      {
        Header: 'Revenue Per Lead',
        accessor: deal => (deal.prospects ? (Number(deal.revenue || 0) / (deal.prospects || 1)) : 0),
        id: 'revenue_per_lead',
        Cell: ({ value }) => formater.formatCurrency(value),
        /* Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.revenue_per_lead) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        }, */
        width: 240,
      },
      {
        Header: 'Total Payout Amount',
        accessor: deal => ((deal.payout || 0)),
        id: 'payout',
        Cell: ({ value }) => formater.formatCurrency(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.payout) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 240,
      },
    ],
    [advertisers],
  );
  const columnsOffers = useMemo(
    () => [
      {
        Header: 'Offer',
        accessor: deal => deal?.name || '',
        id: 'Offer',
        Cell: ({ value }) => value || '',
        width: 250,
      },
      {
        Header: 'Contracts Signed',
        accessor: deal => Number((deal.contractsSigned || 0)),
        id: 'cSigned',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.cSigned) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Clicks',
        accessor: deal => Number((deal.clicks || 0)),
        id: 'clicks',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.clicks) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalAge)}</span>;
        },
        width: 100,
      },
      {
        Header: 'Revenue Conversions',
        accessor: deal => Number((deal.conversions || 0)),
        id: 'conversions',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalAge)}</span>;
        },
        width: 240,
      },
      {
        Header: 'Payout Conversions',
        accessor: deal => Number((deal.payoutConversions || 0)),
        id: 'pConversions',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.pConversions) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalAge)}</span>;
        },
        width: 240,
      },
      {
        Header: 'Total Revenue Amount',
        accessor: deal => ((deal.revenue || 0)),
        id: 'revenue',
        Cell: ({ value }) => formater.formatCurrency(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 240,
      },
      {
        Header: 'Total Payout Amount',
        accessor: deal => ((deal.payout || 0)),
        id: 'payout',
        Cell: ({ value }) => formater.formatCurrency(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.payout) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 240,
      },
    ],
    [],
  );

  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'payout',
    direction: 'desc',
  };
  // const isCspSelectedCPM = csp => !(selectedCsps && selectedCsps.length) || (csp && (selectedCsps.map(row => row.value).indexOf(csp) > -1));
  const isCspSelected = csp => !(selectedCsps && selectedCsps.length) || (csp && (selectedCsps.map(row => row.value).indexOf(csp) > -1));
  const isCspSelectedAffiliateManager = csp => !(selectedCsps && selectedCsps.length) || (csp && (selectedCsps.map(row => row.affiliateManagerId).indexOf(Number(csp)) > -1));

  const calculateContractsSigned = (data) => {
    let total = 0;
    data.forEach((row) => {
      if (isCspSelected(row.adminUserId)) {
        total += 1;
      }
    });
    return total;
  };
  const isSelectedMarketing = deal => !selectedMarketingCampaign || selectedMarketingCampaign?.length === 0 || selectedMarketingCampaign?.map(p => p.value).includes(deal?.dealTerms?.marketing_campaign);

  const filterData = () => {
    const brandsArray = {};
    const offersArray = {};
    const filteredItems = [];
    reportData.forEach((deal) => {
      if (isSelectedMarketing(deal.deal) && isCspSelected(deal.adminUserId)) {
        filteredItems.push(deal);
        if (!brandsArray[deal.offer.advertiserTuneid]) {
          brandsArray[deal.offer.advertiserTuneid] = {
            deals: [],
            tuneId: deal.offer.advertiserTuneid,
            payout: 0,
            brand: deal.offer.advertiser,
            clicks: 0,
            conversions: 0,
            payoutConversions: 0,
            revenue: 0,
            contractsSigned: calculateContractsSigned(contractsSignedByBrand[deal.offer.advertiserTuneid] || []),
            prospects: calculateContractsSigned(prospectsByBrand[deal.offer.advertiser._id] || []),
          };
        }
        if (!offersArray[deal.offer.tuneId]) {
          offersArray[deal.offer.tuneId] = {
            payout: 0,
            revenue: 0,
            deals: [],
            tuneId: deal.offer.tuneId,
            advertiserTuneid: deal.offer.advertiserTuneid,
            name: deal.offer.name,
            clicks: 0,
            conversions: 0,
            payoutConversions: 0,
            contractsSigned: calculateContractsSigned(contractsSignedByOffer[deal.offer.tuneId] || []),
          };
        }
        brandsArray[deal.offer.advertiserTuneid].payout += Number(deal.payout || 0);
        brandsArray[deal.offer.advertiserTuneid].revenue += Number(deal.revenue || 0);
        brandsArray[deal.offer.advertiserTuneid].clicks += Number(deal.clicks || 0);
        brandsArray[deal.offer.advertiserTuneid].conversions += Number(deal.conversions || 0);
        brandsArray[deal.offer.advertiserTuneid].payoutConversions += Number(deal.payoutConversions || 0);

        offersArray[deal.offer.tuneId].payout += Number(deal.payout || 0);
        offersArray[deal.offer.tuneId].revenue += Number(deal.revenue || 0);
        offersArray[deal.offer.tuneId].clicks += Number(deal.clicks || 0);
        offersArray[deal.offer.tuneId].conversions += Number(deal.conversions || 0);
        offersArray[deal.offer.tuneId].payoutConversions += Number(deal.payoutConversions || 0);
      }
    });
    console.log(brandsArray);
    cpmConversions?.forEach((c) => {
      if (!c.influencer[0] || !isCspSelectedAffiliateManager(c.influencer[0].affiliateDataObject.account_manager_id)) return false;
      // const cpm = cpmConversions.find(c => deal.offer.advertiser.toString() === c._id.toString());
      const index = Object.keys(brandsArray).find(key => brandsArray[key].brand._id === c._id.brand);
      console.log(index, brandsArray[index]);
      if (index) brandsArray[index].conversions += c.count;
      return true;
    });
    console.log(brandsArray, cpmDeals);
    cpmDeals?.forEach((deal) => {
      if (!isCspSelected(deal.hubspot_owner_id) || !isSelectedMarketing(deal)) return false;
      if (!brandsArray[deal.offer.advertiserTuneid]) brandsArray[deal.offer.advertiserTuneid] = { reveue: 0, payout: 0 };
      if (!offersArray[deal.offer.tuneId]) offersArray[deal.offer.tuneId] = { reveue: 0, payout: 0 };
      brandsArray[deal.offer.advertiserTuneid].revenue += Number(deal.dealTerms?.bh_cpm_total_cost || 0);
      brandsArray[deal.offer.advertiserTuneid].payout += Number(deal.dealTerms.guaranteed_payment_contract_amount || 0);
      offersArray[deal.offer.tuneId].revenue += Number(deal.dealTerms?.bh_cpm_total_cost || 0);
      offersArray[deal.offer.tuneId].payout += Number(deal.dealTerms.guaranteed_payment_contract_amount || 0);
      return true;
    });
    console.log(brandsArray);
    // setItems(filteredItems);
    setItems(filteredItems);
    setBrands(Object.values(brandsArray));
    setOffers(Object.values(offersArray));
  };

  useEffect(() => {
    filterData();
  }, [selectedCsps, reportData, selectedMarketingCampaign, cpmConversions, cpmDeals]);
  const filtersMarketingCampaignOptions = useMemo(() => filtersMarketingCampaign, [filtersMarketingCampaign]);


  /* const fetchAmountReport = async () => {
    setLoaing(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-report-for-brand', {
        startDate,
        endDate,
        identity,
      });
      if (resp.data.success) {
        setContractsSigned(resp.data.signedAgreements);
        setProspects(resp.data.prospects);
        setCspOptions(resp.data.adminUserOptions);
        setReportData(resp.data.items);
        setLoaing(false);
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  }; */
  const selectItem = (value) => {
    setSelectedAdvertisers(value);
    // setVersion((new Date()).toString());
  };
  const selectCsp = (values) => {
    setSelectedCsps(values);
  };
  const getTotalPayout = () => {
    let total = 0;
    items.forEach((deal) => {
      total += Number(deal.payout);
    });
    cpmDeals.forEach((deal) => {
      if (!isCspSelected(deal.hubspot_owner_id) || !isSelectedMarketing(deal)) return false;
      total += Number(deal.dealTerms.guaranteed_payment_contract_amount || 0);
      return true;
    });
    return total;
  };
  const getTotalRevenue = () => {
    let total = 0;
    items.forEach((deal) => {
      total += Number(deal.revenue);
    });
    cpmDeals.forEach((deal) => {
      if (!isCspSelected(deal.hubspot_owner_id) || !isSelectedMarketing(deal)) return false;
      total += Number(deal.dealTerms?.bh_cpm_total_cost || 0);
      return true;
    });
    return total;
  };
  const getTotalContractsSigned = () => {
    let total = 0;
    brands.forEach((brand) => {
      total += Number(brand.contractsSigned || 0);
    });
    return total;
  };
  const getTotalRevenuePerLead = () => {
    let total = 0;
    brands.forEach((brand) => {
      total += Number(brand.prospects || 0);
    });
    const revenue = getTotalRevenue();

    return revenue / (total || 1);
  };
  const getTotalClicks = () => {
    let total = 0;
    items.forEach((deal) => {
      total += Number(deal.clicks);
    });
    return total;
  };
  const getTotalConversions = () => {
    let total = 0;
    items.forEach((deal) => {
      total += Number(deal.conversions);
    });
    cpmConversions?.forEach((c) => {
      total += c.count;
    });

    return total;
  };
  const getTotalPayoutConversions = () => {
    let total = 0;
    items.forEach((deal) => {
      total += Number(deal.payoutConversions);
    });
    return total;
  };
  const options = advertisers.map(item => ({ value: item.tuneId, label: item.companyName }));
  const offersArray = useMemo(() => offers.filter(offer => !selectedAdvertisers?.value || offer.advertiserTuneid === selectedAdvertisers.value), [selectedAdvertisers, offers]);
  options.unshift({ value: null, label: ' -- All -- ' });
  return (
    <div>
      {!loading && reportData.length > 0 ? [(
        <Row key="1" className="mt-3">
          <Col sm="5">
            <Item
              items={[
                {
                  label: 'Revenue',
                  value: loading ? (<Spinner color="primary" size="sm" />) : (<h3>{formater.formatCurrency(getTotalRevenue())}</h3>),
                },
                {
                  label: 'Revenue Per Lead',
                  value: loading ? (<Spinner color="primary" size="sm" />) : (<h3>{formater.formatCurrency(getTotalRevenuePerLead())}</h3>),
                },
                {
                  label: 'Payout',
                  value: loading ? (<Spinner color="primary" size="sm" />) : (<h3>{formater.formatCurrency(getTotalPayout())}</h3>),
                },
              ]}
            />
          </Col>
          <Col>
            <Item
              items={[
                {
                  label: 'Clicks',
                  value: loading ? (<Spinner color="primary" size="sm" />) : (<h3>{formater.formatIntNumber(getTotalClicks())}</h3>),
                },
                {
                  label: 'Contracts Signed',
                  value: loading ? (<Spinner color="primary" size="sm" />) : (<h3>{formater.formatIntNumber(getTotalContractsSigned())}</h3>),
                },
              ]}
            />
          </Col>
          <Col>
            <Item
              items={[
                {
                  label: 'Revenue Conversions',
                  value: loading ? (<Spinner color="primary" size="sm" />) : (<h3>{formater.formatIntNumber(getTotalConversions())}</h3>),
                },
                {
                  label: 'Payout Conversions',
                  value: loading ? (<Spinner color="primary" size="sm" />) : (<h3>{formater.formatIntNumber(getTotalPayoutConversions())}</h3>),
                },
              ]}
            />
          </Col>
        </Row>
      ), (
        <Row key="2" className="mt-3">
          <Col>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Deal Owner(s)
                  </Label>
                  <Select
                    isMulti
                    value={selectedCsps}
                    options={cspOptions}
                    onChange={selectCsp}
                    placeholder="Select Deal Owner..."
                    isClearable
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    Marketing Campaign(s)
                  </Label>
                  <Select
                    isMulti
                    name="mCampaignSelect"
                    isDisabled={loadingAdvertiser}
                    isLoading={loadingAdvertiser}
                    isClearable
                    value={selectedMarketingCampaign || []}
                    options={filtersMarketingCampaignOptions}
                    onChange={value => setSelectedMarketingCampaign(value)}
                    placeholder="Select Marketing Campaign..."
                    style={{ maxHeight: '120px' }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col />
        </Row>
      )] : null }
      {!loading && reportData.length > 0 ? (
        <Row>
          <Col>
            <Row >
              <Col className="tabs">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        setActiveTab('1');
                      }}
                    >
                      Brands
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        setActiveTab('2');
                      }}
                    >
                      Offers
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col className="mt-3">
                        {reportData.length > 0 ? (
                          <div className="float-right mr-2">
                            <DownloadCSVButton
                              records={brands}
                              className="mt-2"
                              fileName="BrandReport.csv"
                              csvArrayColumns={[
                                {
                                  label: 'Brand',
                                  func: deal => deal?.brand?.companyName,
                                },
                                {
                                  label: 'Contracts Signed',
                                  func: record => (record.contractsSigned || 0),
                                  total: true,
                                },
                                {
                                  label: 'Clicks',
                                  func: record => (record.clicks || 0),
                                  total: true,
                                },
                                {
                                  label: 'Revenue Conversions',
                                  func: record => (record.conversions || 0),
                                  total: true,
                                },
                                {
                                  label: 'Payout Conversions',
                                  func: deal => (deal.payoutConversions || 0),
                                  total: true,
                                },
                                {
                                  label: 'Total Revenue Amount',
                                  func: deal => ((deal.revenue || 0)),
                                  total: true,
                                },
                                {
                                  label: 'Leads',
                                  func: deal => (deal.prospects || 0),
                                  total: true,
                                },
                                {
                                  label: 'Revenue Per Lead',
                                  func: deal => (deal.prospects ? (Number(deal.revenue || 0) / (deal.prospects || 1)) : 0),
                                  total: true,
                                },
                                {
                                  label: 'Total Payout Amount',
                                  func: record => (record.payout || 0),
                                  total: true,
                                },
                              ]}
                            >
                              Download CSV
                            </DownloadCSVButton>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        {!loading && reportData.length > 0 ? (
                          <ReactTableBase
                            key="searchable"
                            columns={columnsBrands}
                            data={brands}
                            tableConfig={tableConfig}
                          />) : null
                        }
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col className="mt-3">
                        <Alert color="info">
                          <p className="py-2">
                            CPM conversions is not included
                          </p>
                        </Alert>
                        {reportData.length > 0 ? (
                          <div className="float-right mr-2">
                            <DownloadCSVButton
                              records={offersArray}
                              className="mt-2"
                              fileName="OfferReport.csv"
                              csvArrayColumns={[
                                {
                                  label: 'Offer',
                                  func: record => (record?.name || ''),
                                },
                                {
                                  label: 'Contracts Signed',
                                  func: record => (record.contractsSigned || 0),
                                  total: true,
                                },
                                {
                                  label: 'Clicks',
                                  func: record => (record.clicks || 0),
                                  total: true,
                                },
                                {
                                  label: 'Revenue Conversions',
                                  func: record => (record.conversions || 0),
                                  total: true,
                                },
                                {
                                  label: 'Payout Conversions',
                                  func: deal => (deal.payoutConversions || 0),
                                  total: true,
                                },
                                {
                                  label: 'Total Revenue Amount',
                                  func: deal => (deal.revenue || 0),
                                  total: true,
                                },
                                {
                                  label: 'Total Payout Amount',
                                  func: record => (record.payout || 0),
                                  total: true,
                                },
                              ]}
                            >
                              Download CSV
                            </DownloadCSVButton>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        <Select
                          isDisabled={loadingAdvertiser}
                          value={selectedAdvertisers}
                          options={options}
                          onChange={selectItem}
                          placeholder="Select Advertiser..."
                          isClearable
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        {!loading && reportData.length > 0 ? (
                          <ReactTableBase
                            key="searchable2"
                            columns={columnsOffers}
                            data={offersArray}
                            tableConfig={tableConfig}
                          />) : null
                        }
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Row>) : null
      }
    </div>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
  contractsSignedByBrand: PropTypes.objectOf(PropTypes.any).isRequired,
  contractsSignedByOffer: PropTypes.objectOf(PropTypes.any).isRequired,
  prospectsByBrand: PropTypes.objectOf(PropTypes.any).isRequired,
  reportData: PropTypes.arrayOf(PropTypes.any).isRequired,
  cspOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  cpmConversions: PropTypes.arrayOf(PropTypes.any).isRequired,
  cpmDeals: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withNotice(SummaryReport);
