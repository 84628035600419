import MediaContentInList from './MediaContentInList';
import InfluencerInList from './InfluencerInList';
import PublishDateInList from './PublishDateInList';
import ViewsInList from './ViewsInList';
import TVRInList from './TVRInList';
import ContextMenuInList from './ContextMenuInList';

export default {
  show: {
  },
  list: {
    mediaContent: MediaContentInList,
    creatorName: InfluencerInList,
    publishedAt: PublishDateInList,
    lastViews: ViewsInList,
    'tvr.er30': TVRInList,
  },
  edit: {
  },
  actions: ContextMenuInList,
};
