import React from 'react';
import { FormGroup, Col, Row, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { axios } from 'ApiClient';
import withNotice from '../../../../../containers/App/store/with-notice';
import DropZoneField from '../../../../../shared/components/form/DropZone';
import BrandResourceFileCard from './BrandResourceFileCard';
import StyledButton from '../../../../../shared/components/components/ui/styled-button';

class BrandResourceFilesSection extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileUrlChange = this.handleFileUrlChange.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleSubmitFileUrl = this.handleSubmitFileUrl.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.moveFile = this.moveFile.bind(this);
    this.state = {
      fileUrlExternal: '',
      loading: {
        upload: false,
      },
    };
  }

  handleInputChange(e, index) {
    const { files, saveFilesData, filesIndex } = this.props;
    files[index][e.currentTarget.name] = e.currentTarget.value;
    saveFilesData(filesIndex, files, false);
  }

  handleFileUrlChange(url) {
    this.setState(() => ({
      fileUrlExternal: url,
    }));
  }

  removeFile(index) {
    const { files, saveFilesData, filesIndex } = this.props;
    const deletedFile = files[index].filePath;
    files.splice(index, 1);
    // eslint-disable-next-line array-callback-return
    files.map((file, i) => {
      files[i].order = i;
    });
    saveFilesData(filesIndex, files, deletedFile);
  }

  handleChangeFile(filesToUpload) {
    const {
      addNotice,
      filesIndex,
      company,
      files,
      saveFilesData,
    } = this.props;
    const file = filesToUpload.length ? filesToUpload[0] : false;
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('company', company);
      formData.append('subfolder', filesIndex);
      this.setState(state => ({
        loading: {
          ...state.loading,
          upload: true,
        },
      }));
      axios.post('/api/portal/brands/save-brand-resource-file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        this.setState(state => ({
          loading: {
            ...state.loading,
            upload: false,
          },
        }));
        const { success, uploadedFile } = response.data;
        if (success) {
          const newFile = {
            fileType: 'upload',
            fileUrl: uploadedFile.fileUrl,
            filePath: uploadedFile.filePath,
            title: uploadedFile.fileName,
            description: '',
            order: files.length,
          };
          files.push(newFile);
          saveFilesData(filesIndex, files, false);
        } else {
          addNotice({
            message: 'An error occurred while uploading the file',
            type: 'warning',
          });
        }
      }).catch((error) => {
        console.log(error);
        this.setState(state => ({
          loading: {
            ...state.loading,
            upload: false,
          },
        }));
      });
    }
  }

  handleSubmitFileUrl() {
    const {
      filesIndex,
      files,
      saveFilesData,
    } = this.props;
    const { fileUrlExternal } = this.state;
    if (fileUrlExternal !== '') {
      const newFile = {
        fileType: 'url',
        fileUrlExternal,
        fileUrl: '',
        filePath: '',
        title: 'Youtube Video',
        description: '',
        order: files.length,
      };
      files.push(newFile);
      saveFilesData(filesIndex, files, false);
      this.setState(() => ({
        fileUrlExternal: '',
      }));
    }
  }

  moveFile(dragIndex, hoverIndex) {
    const { files, saveFilesData, filesIndex } = this.props;
    // eslint-disable-next-line array-callback-return
    files.map((file, index) => {
      if (files[index].order >= dragIndex && files[index].order <= hoverIndex) {
        files[index].order -= 1;
      } else if (files[index].order <= dragIndex && files[index].order >= hoverIndex) {
        files[index].order += 1;
      }
    });
    files[dragIndex].order = hoverIndex;
    files.sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
    saveFilesData(filesIndex, files, false);
  }

  renderFile(file, index, filesIndex) {
    const { styles } = this.props;
    return (
      <BrandResourceFileCard
        key={`${filesIndex}-${index}`}
        styles={styles}
        file={file}
        index={index}
        filesIndex={filesIndex}
        moveFile={this.moveFile}
        handleInputChange={this.handleInputChange}
        removeFile={this.removeFile}
      />
    );
  }

  render() {
    const {
      styles,
      title,
      files,
      filesIndex,
    } = this.props;
    const {
      loading,
      fileUrlExternal,
    } = this.state;
    return (
      <FormGroup row key={`${title}`}>
        <Label sm={2} style={styles.label}>{title}</Label>
        <Col sm={10}>
          <DndProvider backend={HTML5Backend}>
            <List>
              {(files && files.length > 0) && files.map((file, index) => this.renderFile(file, index, filesIndex))}
            </List>
          </DndProvider>
          <Row style={styles.uploadRow}>
            <Col md={5}>
              <DropZoneField
                input={{
                  name: 'videoUrl',
                  id: 'videoUrl',
                  value: loading.upload ? [{ preview: '/images/loading.gif' }] : '',
                  accept: null,
                  onChange: this.handleChangeFile,
                }}
                customHeight
              />
            </Col>
            <Col md={2} style={styles.fileZoneOr}>
              OR
            </Col>
            <Col md={5} style={styles.uploadYouTubeUrl}>
              Enter YouTube URL
              <Input name="fileUrlExternal" value={fileUrlExternal} onChange={(e) => { this.handleFileUrlChange(e.currentTarget.value); }} />
              <StyledButton
                type="submit"
                className="is-primary"
                style={{ marginTop: 20 }}
                onClick={this.handleSubmitFileUrl}
              >
                <i className="icomoon-save" />
                <span className="btn-text">Save</span>
              </StyledButton>
            </Col>
          </Row>
        </Col>
      </FormGroup>
    );
  }
}

BrandResourceFilesSection.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    fileUrl: PropTypes.string,
    filePath: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    order: PropTypes.number,
  })).isRequired,
  filesIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  saveFilesData: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(BrandResourceFilesSection);
