/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from 'react';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import CheckBoxField from '../../../../shared/components/CheckBox';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
// import CopyToClipboard from '../../../../shared/components/CopyToClipboard';

const initialStatusFilters = {
  New: true,
  Existing: true,
};

const GenerateDetailsTable = ({
  items,
  key,
}) => {
  const [statusFilters, setStatusFilters] = useState(initialStatusFilters);
  const [filteredItems, setFilteredItems] = useState(items);

  const filterItems = () => {
    if (items?.length > 0) {
      setFilteredItems(items.filter(item => (statusFilters[item.newOrExisting] === true)));
    } else setFilteredItems([]);
  };

  useEffect(() => {
    filterItems();
  }, [items, statusFilters]);

  const columns = useMemo(
    () => [
      {
        Header: 'Creator',
        accessor: row => row.creator || '',
        id: 'creator',
        Cell: ({ value }) => (<b>{value}</b>) || '-',
        width: 300,
      },
      {
        Header: 'Deal Name',
        accessor: row => row.dealname || 0,
        id: 'dealname',
        Cell: ({ value, row }) => (
          <span>
            {value}
            {row?.original?.hsDealUrl && (
              <a
                className="ml-3"
                href={row.original.hsDealUrl}
                target="_blank"
                title="Open HubSpot Deal"
                rel="noreferrer noopener"
              >
                <i className="fab fa-hubspot" style={{ fontSize: '1.5em' }} />
              </a>
            )}
          </span>
        ),
        width: 150,
      },
      {
        Header: 'Deal Owner',
        accessor: row => row.dealOwner || 0,
        id: 'dealOwner',
        Cell: ({ value }) => value || '-',
        width: 150,
      },
      {
        Header: 'New or Existing',
        accessor: row => row.newOrExisting || 0,
        id: 'newOrExisting',
        Cell: ({ value }) => value || '-',
        width: 150,
      },
      {
        Header: 'Content Release Month',
        accessor: row => row.contentReleaseMonth || 0,
        id: 'contentReleaseMonth',
        Cell: ({ value }) => value || '-',
        width: 150,
      },
      {
        Header: 'Deal Stage',
        accessor: row => row.dealStage || 0,
        id: 'dealStage',
        Cell: ({ value }) => value || '-',
        width: 150,
      },
      {
        Header: 'CPM Spend',
        accessor: row => row.cpmSpend || 0,
        id: 'cpmSpend',
        Cell: ({ value }) => value || '-',
        width: 150,
      },
      // {
      //   Header: 'Hubspot Deal URL',
      //   accessor: row => row.hsDealUrl || 0,
      //   id: 'hsDealUrl',
      //   Cell: ({ value }) => (
      //     <CopyToClipboard value={value || ''}>
      //       {value}
      //     </CopyToClipboard>
      //   ) || '',
      //   width: 150,
      // },
    ],
    [filteredItems],
  );

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 50, 100],
    placeholder: 'Search all fields...',
  };

  const handleChangeStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setStatusFilters({
        ...statusFilters,
        [name]: checked,
      });
    }
  };

  return (
    <div>
      <Row key={key}>
        <Col className="mt-3">
          <div
            style={{
              position: 'absolute',
              right: '15px',
            }}
          >
            <DownloadCSVButton
              records={items}
              className=""
              fileName="Deal Details.csv"
              csvArrayColumns={[
                {
                  label: 'Creator',
                  func: record => (record.creator || '-'),
                },
                {
                  label: 'Deal Name',
                  func: record => (record.dealname || '-'),
                },
                {
                  label: 'Deal Owner',
                  func: record => (record.dealOwner || '-'),
                },
                {
                  label: 'New or Existing',
                  func: record => (record.newOrExisting || '-'),
                },
                {
                  label: 'Content Release Month',
                  func: record => (record.contentReleaseMonth || '-'),
                },
                {
                  label: 'Dealstage',
                  func: record => (record.dealStage || '-'),
                },
                {
                  label: 'CPM Spend',
                  func: record => (record.cpmSpend || '-'),
                },
                {
                  label: 'Hubspot Deal URL',
                  func: record => (record.hsDealUrl || '-'),
                },
              ]}
            >
              Download CSV
            </DownloadCSVButton>
          </div>
          <Label className="bold-text">Deal Details</Label>
          <FormGroup row style={{ width: '40%' }}>
            <Col sm={3}>
              <Label className="bold-text">New or Existing</Label>
            </Col>
            <Col sm={2}>
              <CheckBoxField
                name="filterNew"
                label="New"
                value={statusFilters.New}
                onChange={event => handleChangeStatusFilters(event, 'New')}
              />
            </Col>
            <Col>
              <CheckBoxField
                name="filterExisting"
                label="Existing"
                value={statusFilters.Existing}
                onChange={event => handleChangeStatusFilters(event, 'Existing')}
              />
            </Col>
          </FormGroup>
          <ReactTableBase
            key="searchable"
            columns={columns}
            data={filteredItems}
            tableConfig={tableConfig}
          />
        </Col>
      </Row>
    </div>
  );
};

GenerateDetailsTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  key: PropTypes.string.isRequired,
};

export default GenerateDetailsTable;
