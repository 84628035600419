import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { toOptions, yesNoOptions, boolToInt } from '../../../../../../../shared/helpers/Form';
import { dataTypes } from '../../../../../../../shared/helpers/models/CustomAgreementTemplate';

const CustomField = ({
  data,
  defaults,
  index,
  customFieldOptions,
  disableStandardField,
  errors,
  update,
  del,
}) => {
  const dataTypeOptions = toOptions(dataTypes);
  const errorClass = v => (v ? 'text-black-50' : 'text-danger');

  const handleChange = (key, value) => {
    update(index, key, value);
  };

  return (
    <Row>
      <Col md="6" xl="2">
        <FormGroup>
          <Label>{del ? 'Custom' : 'Standard'} Data Field</Label>
          <Select
            value={customFieldOptions.filter(o => o.value === (data?.customDataField || defaults?.customDataField))}
            options={customFieldOptions}
            placeholder="Select"
            onChange={(e) => { handleChange('customDataField', e.value) }}
            isDisabled={disableStandardField}
          />
          <small className={errorClass(data?.customDataField || !del)}>{errors?.customDataField || 'Required'}</small>
        </FormGroup>
      </Col>
      <Col md="6" xl="3">
        <FormGroup>
          <Label>InfluenceLogic UI Form Label</Label>
          <Input
            type="text"
            id="ilUILabel"
            name="ilUILabel"
            placeholder="InfluenceLogic UI Form Label"
            onChange={(e) => { handleChange('ilUILabel', e.target.value) }}
            value={data?.ilUILabel || defaults?.ilUILabel || ''}
            disabled={!del}
          />
          <small className={errorClass(data?.ilUILabel || defaults?.ilUILabel)}>{errors?.ilUILabel || 'Required'}</small>
        </FormGroup>
      </Col>
      <Col md="6" xl="3">
        <FormGroup>
          <Label>Data Type</Label>
          <Select
            value={dataTypeOptions.filter(o => o.value === (data?.dataType || defaults?.dataType))}
            options={dataTypeOptions}
            placeholder="Data Type"
            onChange={(e) => { handleChange('dataType', e.value) }}
            isDisabled={disableStandardField}
          />
          <small className={errorClass(data?.dataType || defaults?.dataType)}>{errors?.dataType || 'Required'}</small>
        </FormGroup>
      </Col>
      <Col md="5" xl="3">
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>Read Only?</Label>
              <Select
                value={yesNoOptions.filter(o => o.value === boolToInt(data?.readOnly))}
                options={yesNoOptions}
                placeholder=""
                onChange={(e) => { handleChange('readOnly', e.value) }}
              />
              <small className={errorClass(data?.readOnly?.toString())}>{errors?.readOnly || 'Required'}</small>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Required?</Label>
              <Select
                value={yesNoOptions.filter(o => o.value === boolToInt(data?.required))}
                options={yesNoOptions}
                placeholder=""
                onChange={(e) => { handleChange('required', e.value) }}
              />
              <small className={errorClass(data?.required?.toString())}>{errors?.required || 'Required'}</small>
            </FormGroup>
          </Col>
        </Row>
      </Col>
      {del ? (
        <Col md="1" style={{ color: '#ff0000', paddingTop: '36px' }} onClick={() => { del(index) }}>
          <i className="fa fa-times" aria-hidden="true" />
        </Col>
      ) : (
        <Col md="1" />
      )}
    </Row>
  );
};

CustomField.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  defaults: PropTypes.objectOf(PropTypes.any),
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  customFieldOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  disableStandardField: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  update: PropTypes.func.isRequired,
  del: PropTypes.func,
};

CustomField.defaultProps = {
  defaults: {},
  del: null,
};

export default CustomField;
