import { unflatten } from 'flat';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ApiClient from 'ApiClient';

const StartDatetimeInList = ({ record }) => {
  const key = 'startDatetime';
  const [value, setValue] = useState(null);

  useEffect(() => {
    const recordUnflatten = unflatten(record);
    const rowData = recordUnflatten.params;
    let val = null;
    if (typeof rowData !== 'undefined') {
      if (typeof rowData[key] !== 'undefined' && rowData[key] !== null) {
        val = moment(rowData[key]).toDate();
      }
    }
    setValue(val);
  }, [record]);

  const handleChange = async (newValue) => {
    const oldValue = Object.assign({}, value);
    setValue(newValue);
    const data = new FormData();
    data.append(key, newValue.toISOString() || '');
    const api = new ApiClient();
    const res = await api.recordAction({
      resourceId: 'ScheduledJob',
      recordId: record.id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res?.data?.success) {
      return true;
    }
    console.log('could not update the record: ', res);
    setValue(oldValue);
    return false;
  };

  return (
    <DatePicker
      onChange={handleChange}
      selected={value}
      dateFormat="M/d/yyyy h:mmaa"
      placeholderText="Start Date"
      className="form-control"
      showTimeInput
    />
  );
};
StartDatetimeInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default StartDatetimeInList;
