import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';

const InfluencelogicStatus = React.memo(({
  record,
  setShowNotesModal,
  setCurrentProspect,
  setDisableNote,
  setPortal,
}) => {
  const rowData = record.params;
  const val = (typeof rowData !== 'undefined' && typeof rowData.approvalStatus?.influencelogic?.status !== 'undefined') ? rowData.approvalStatus.influencelogic.status : '';
  const notesVal = (typeof rowData !== 'undefined' && typeof rowData.approvalStatus?.influencelogic?.notes !== 'undefined') ? rowData.approvalStatus.influencelogic.notes : '';

  const [value, setValue] = useState(val);
  let labelColor = '#646777';

  useEffect(() => {
    setValue(val);
  }, [record.id, val]);

  switch (value) {
    case 'Approved':
      labelColor = '#28A745';
      break;
    case 'Rejected':
      labelColor = '#dc3545';
      break;
    default:
      labelColor = '#646777';
      break;
  }

  return (
    <div style={{ width: '140px' }} className="text-center">
      {value && (
        <span style={{ color: labelColor }}>{value}</span>
      )}
      <div style={{ float: 'right' }}>
        <Tooltip title="IL Notes">
          <IconButton
            size="small"
            onClick={() => { setPortal('influenceLogic'); setDisableNote(false); setCurrentProspect(record); setShowNotesModal(true); }}
          >
            <FontAwesomeIcon icon={[notesVal !== '' ? 'fas' : 'far', 'file-alt']} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

InfluencelogicStatus.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setShowNotesModal: PropTypes.func.isRequired,
  setCurrentProspect: PropTypes.func.isRequired,
  setDisableNote: PropTypes.func.isRequired,
  setPortal: PropTypes.func.isRequired,
};

export default InfluencelogicStatus;
