import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row, Col, Button, Container, Card, CardBody, CardTitle, Label, FormGroup,
} from 'reactstrap';
import Select, { components } from 'react-select';
import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import Filter from './TimePeriodFilterCustom';
import ChartComponentCommentsPercentage from './ChartComponentCommentsPercentage';
import ChartComponentCommentsWithSentinement from './ChartComponentCommentsWithSentinement';
import ChartComponentCommentsTotal from './ChartComponentCommentsTotal';
import CheckBoxField from '../../../../shared/components/CheckBox';
import hooks from '../../../../shared/hooks';
import LoadingReport from "../../../../shared/components/LoadingReport";
import VideosTable from './VideosTable';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
import { formatIntNumber, getFormattedDateNoZeroes } from '../../../../shared/helpers/WVFormatter';
import SelectAsync from 'react-select/async';
import { loadCreatorsOptions } from '../../../../shared/helpers/OptionsLoader';

const dataOptions = [
  { value: 'byDay', label: 'By Day' },
  { value: 'week', label: 'By Week' },
  { value: 'month', label: 'By Month' },
  { value: 'total', label: 'Over Time' },
];

const csvArrayColumnsVideos=[
  {
    label: 'Video Title',
    func: record => (record.title || ''),
  },
  {
    label: 'PublishDate',
    func: record => getFormattedDateNoZeroes(record.publishDate),
  },
  {
    label: 'Deal Amount',
    func: record => record.dealAmount ? `$${formatIntNumber(record.dealAmount, 2)}` : '-',
  },
  {
    label: 'Total Views',
    func: record => record.totalViews || 0,
  },
  {
    label: 'Total Comments',
    func: record => record.count || 0,
  },
  {
    label: 'Negative Comments',
    func: record => record.negativeCount || 0,
  },
  {
    label: 'Negative Percentage',
    func: record => `${formatIntNumber(record.negativePercentage || 0, 3)}%`,
  },
  {
    label: 'Positive Comments',
    func: record => record.positiveCount || 0,
  },
  {
    label: 'Positive Percentage',
    func: record => `${formatIntNumber(record.positivePercentage || 0, 3)}%`,
  },
];

const formatCreatorOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div>{record?.params.first_name} {record?.params.last_name}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record?.params.email}
    </div>
  </div>
);

const creatorOption = (props) => {
  const { record } = props.data;
  return (
    <components.Option {...props} >
      <div>
        <div className="bold-text">{record.params.first_name} {record.params.last_name}</div>
        <div >{record.params['affiliateDataObject.company']}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record.params.email}
        </div>
      </div>
    </components.Option>
  );
};

const getInitialAdvertiser = () => {
  const initialSearch = new URLSearchParams(window.location.search);
  let advertiser = null;

  if (initialSearch.has('advertiser') && initialSearch.get('advertiser')) {
    advertiser = { value: initialSearch.get('advertiser'), label: initialSearch.get('advertiserName') };
  }

  return advertiser;
}

const getInitialStartDate = () => {
  const initialSearch = new URLSearchParams(window.location.search);
  let startDate = moment().startOf('month').format('YYYY-MM-DD');

  if (initialSearch.has('startDate') && initialSearch.get('startDate')) {
    startDate = initialSearch.get('startDate');
  }

  return startDate;
}

const getInitialEndDate = () => {
  const initialSearch = new URLSearchParams(window.location.search);
  let endDate = moment().endOf('month').format('YYYY-MM-DD');

  if (initialSearch.has('endDate') && initialSearch.get('endDate')) {
    endDate = initialSearch.get('endDate');
  }

  return endDate;
}


const getInitialSelectedButton = () => {
  const initialSearch = new URLSearchParams(window.location.search);
  let button = 'current_month';

  if (
    (initialSearch.has('startDate') && initialSearch.get('startDate'))
    || (initialSearch.has('endDate') && initialSearch.get('endDate'))
  ) {
    button = 'custom';
  }
  return button;
}

const YouTubeCommentsAnalysisReport = ({ addNotice }) => {
  const [useShowForUsers] = hooks.useAccessHook({ hideWhenTeam: ['Auditors'] });
  const [loading, setLoading] = useState(false);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [startDate, setStartDate] = useState(getInitialStartDate());
  const [endDate, setEndDate] = useState(getInitialEndDate);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [advertisers, setAdvertisers] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(getInitialAdvertiser());
  const [selectedOption, setSelectedOption] = useState(dataOptions[0]);
  const [periodsData, setPeriodsData] = useState({
    comments: [],
    mediaContent: [],
  });
  const [filtersForVideoUrls, setFiltersForVideoUrls] = useState('');
  const advertiserOptions = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    return advertisers?.map(item => ({ value: item._id, label: item.companyName })).sort((a, b) => ((a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)));
  }, [advertisers]);

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  const commentsStats = useMemo(() => {
    const sumAll = ({
      count, negativeCount, neutralCount, positiveCount, label,
    }, item) => {
      return {
        count: count + item?.count || 0,
        negativeCount: negativeCount + item?.negativeCount || 0,
        neutralCount: neutralCount + item?.neutralCount || 0,
        positiveCount: positiveCount + item?.positiveCount || 0,
        positivePercentage: (count + item?.count || 0) ? ((positiveCount + item?.positiveCount || 0) / (count + item?.count || 0)) : 0,
        negativePercentage: (count + item?.count || 0) ? ((negativeCount + item?.negativeCount || 0) / (count + item?.count || 0)) : 0,
        label: label || item?.label,
      };
    };
    const total = selectedOption.value;
    const startMoment = moment(startDate).startOf('day');
    const endMoment = moment() > moment(endDate).endOf('day') ? moment(endDate).endOf('day') : moment().endOf('day');
    const chartData = {};
    const chartDataMonth = {};
    const chartDataWeek = {};
    const totalData = {};
    const days = endMoment.diff(startMoment, 'day');
    const months = endMoment.diff(startMoment, 'month');
    const weeks = endMoment.diff(startMoment, 'weeks') + 1;

    for (let i = 0; i <= days; i += 1) {
      const momentDate = startMoment.clone().add(i, 'day');
      const key = momentDate.format('YYYY-MM-DD');
      chartData[key] = {
        label: momentDate.format('M/D/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
      totalData[key] = {
        label: momentDate.format('M/D/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
    }
    for (let i = 0; i <= months; i += 1) {
      const momentDate = startMoment.clone().add(i, 'month');
      const key = momentDate.format('YYYY-MM');
      chartDataMonth[key] = {
        label: momentDate.format('M/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
    }
    for (let i = 0; i <= weeks; i += 1) {
      const momentDate = startMoment.clone().add(i, 'week').startOf('week');
      const key = momentDate.format('YYYY-w');
      // console.log(key, moment(key, 'YYYY-w').startOf('week'), endMoment);
      if (momentDate > endMoment) break;
      chartDataWeek[key] = {
        label: `${moment(key, 'YYYY-w').startOf('week').format('M/D/YY')} - ${moment(key, 'YYYY-w').endOf('week').format('M/D/YY')}`,
        count: 0,
        negativeCount: 0,
        neutralCount: 0,
        positiveCount: 0,
        positivePercentage: 0,
        negativePercentage: 0,
      };
    }


    periodsData.comments.forEach(({
      label, count, negativeCount, neutralCount, positiveCount,
    }) => {
      const week = moment(label).format('YYYY-w');
      const byMonthKey = label.substring(0, 7);
      chartData[label] = sumAll({
        count, negativeCount, neutralCount, positiveCount,
      }, chartData[label]);
      chartDataMonth[byMonthKey] = sumAll({
        count, negativeCount, neutralCount, positiveCount, label: moment(label).format('M/YY'),
      }, chartDataMonth[byMonthKey]);
      chartDataWeek[week] = sumAll({
        count, negativeCount, neutralCount, positiveCount,
      }, chartDataWeek[week]);
    });

    if (total === 'total') {
      let beforeValue = {
        count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0,
      };
      Object.keys(chartData).forEach((day) => {
        const valueForDays = { ...chartData[day], label: day };
        totalData[day] = sumAll({ ...beforeValue, label: moment(day).format('M/D/YY') }, valueForDays);
        beforeValue = { ...totalData[day] };
      });
    }
    if (total === 'month') return Object.values(chartDataMonth);
    if (total === 'week') return Object.values(chartDataWeek);
    if (total === 'total') return Object.values(totalData);
    return Object.values(chartData);
  }, [selectedOption, periodsData.comments]);

  const loadBrands = () => {
    const api = new ApiClient();
    setLoadingAdvertiser(true);
    api.client.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser._id]) brands[offer.advertiser._id] = { ...offer.advertiser };
      });
      setAdvertisers(Object.values(brands));
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load advertisers. Check out console to see more information.',
        type: 'error',
      });
    });
  }

  const loadPassedCreator = async () => {
    let response = {};
    const initialSearch = new URLSearchParams(window.location.search);
    if (initialSearch.has('creator') && initialSearch.getAll('creator')) {
      response = await axios.get('/api/portal/influencer/get-creators-by-ids', {
        params: { ids: initialSearch.getAll('creator') },
      });
      if (response.data.success) {
        const option = response.data.records.map(record => ({
          value: record.params?._id || record._id,
          label: record.params ? `${record.params.first_name} ${record.params.last_name}` : `${record.first_name} ${record.last_name}`,
          record,
        }))[0];
        if (option) {
          setSelectedCreator(option);
          setTimeout(() => fetchReport(option), 1500);
        }
      }
    }
  }

  useEffect(() => {
    loadPassedCreator();
    loadBrands();
  }, []);

  const setPeriodDataForTypes = (comments, mediaContent) => {
    const array = {
      comments,
      mediaContent,
    };
    setPeriodsData(array);
  };

  const fetchReport = async (option) => {
    const creatorId = option?.value || selectedCreator?.value;
    if (creatorId) {
      setLoading(true);
      setFiltersForVideoUrls(`&startDate=${startDate}&endDate=${endDate}`);
      try {
        const resp = await axios.post('/api/portal/reports/post-report-creator-comments-analysis', {
          startDate,
          endDate,
          creatorId,
          advertiserId: selectedAdvertiser?.value || null,
          identity,
        });
        setLoading(false);
        if (resp.data.success) {
          const {
            comments,
            mediaContent,
          } = resp.data;
          if (comments?.length > 0) {
            setPeriodDataForTypes(comments, mediaContent);
          } else {
            setPeriodDataForTypes([], []);
            addNotice({
              message: 'No comments found with current filters!',
              type: 'success',
              duration: 60,
            });
          }
        } else {
          throw new Error(resp.data.error);
        }
      } catch (e) {
        console.error(e);
        addNotice({
          message: e.message,
          type: 'error',
        });
      }
    }
  };
  if (!useShowForUsers) {
    return null;
  }
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Statistics', path: false },
              { title: 'Video Comments', path: false },
              { title: 'Creator Comments Analysis', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Creator Comments Analysis</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col md={10}>
                  <Label className="bold-text">Comment Date</Label>
                  <Filter
                    setValue={(param, value) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                    selectedButtonInitial={getInitialSelectedButton()}
                    startDateInitial={startDate}
                    endDateInitial={endDate}
                  />
                </Col>
                <Col md={2}>
                  <div
                    className="d-flex"
                    style={{
                      float: 'right',
                      paddingTop: '31px',
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchReport();
                        return false;
                      }}
                      className="my-0 btn-sm"
                      disabled={!!loading || !selectedCreator}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Label className="bold-text">
                    Creator
                  </Label>
                  <SelectAsync
                    cacheOptions
                    isClearable
                    loadOptions={async value => loadCreatorsOptions(value)}
                    onChange={setSelectedCreator}
                    placeholder="Search Creator..."
                    formatOptionLabel={formatCreatorOptionLabel}
                    components={{ Option: creatorOption }}
                    value={selectedCreator}
                  />
                </Col>
                <Col md={6}>
                  <Label className="bold-text">Brand</Label>
                  <Select
                    isClearable
                    isDisabled={loadingAdvertiser}
                    value={selectedAdvertiser}
                    options={advertiserOptions}
                    onChange={setSelectedAdvertiser}
                    placeholder="Select Brand..."
                    styles={colourStyles}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {!loading && periodsData.comments.length > 0 && (
                <>
                  <Row className="mt-3">
                    <Col md={2}>
                      <div
                        className="form-group"
                      >
                        <Select
                          placeholder="Select Property to Chart..."
                          value={selectedOption}
                          options={dataOptions}
                          onChange={setSelectedOption}
                        />
                      </div>
                    </Col>
                    <Col>
                      <FormGroup style={{ paddingTop: '10px' }}>
                        <CheckBoxField
                          name="showLabels"
                          label="Show Labels on Charts"
                          value={showLabels}
                          onChange={e => setShowLabels(e.target?.checked || false)}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                    <Col />
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <h4 className="pb-3">Percent Positive & Negative Comments</h4>
                      <ChartComponentCommentsPercentage
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <h4 className="pb-3">Positive and Negative Comments</h4>
                      <ChartComponentCommentsWithSentinement
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <h4 className="pb-3">Total Comments</h4>
                      <ChartComponentCommentsTotal
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div
                        style={{
                          position: 'absolute',
                          right: '15px',
                          top: '-3px'
                        }}
                      >
                        <DownloadCSVButton
                          className=""
                          records={periodsData.mediaContent}
                          fileName={`${selectedCreator?.label || ''} Videos.csv`}
                          csvArrayColumns={csvArrayColumnsVideos}
                        >
                          Download CSV
                        </DownloadCSVButton>
                      </div>
                      <h4 className="pb-3">{`${selectedCreator?.label || ''} Videos`}</h4>
                      <VideosTable
                        data={periodsData.mediaContent}
                        filtersForUrls={filtersForVideoUrls}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

YouTubeCommentsAnalysisReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(YouTubeCommentsAnalysisReport);
