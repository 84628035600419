import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row, Col, Button, Container, Card, CardBody, CardTitle, Label, FormGroup, Input,
} from 'reactstrap';
import Select, { components } from 'react-select';
import axios from 'axios';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import Filter from './TimePeriodFilterCustom';
import ChartComponentCommentsPercentage from './ChartComponentCommentsPercentage';
import ChartComponentCommentsWithSentinement from './ChartComponentCommentsWithSentinement';
import ChartComponentCommentsTotal from './ChartComponentCommentsTotal';
import CheckBoxField from '../../../../shared/components/CheckBox';
import hooks from '../../../../shared/hooks';
import LoadingReport from "../../../../shared/components/LoadingReport";
import SelectAsync from 'react-select/async';
import { loadCreatorsOptions } from '../../../../shared/helpers/OptionsLoader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getUserTimezoneAbbreviation } from '../../../../shared/helpers/WVUtilities';
import VideoComments from './VideoComments';

const dataOptions = [
  { value: 'byDay', label: 'By Day' },
  { value: 'week', label: 'By Week' },
  { value: 'month', label: 'By Month' },
  { value: 'total', label: 'Over Time' },
];

const formatCreatorOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div>{record?.params.first_name} {record?.params.last_name}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record?.params.email}
    </div>
  </div>
);

const creatorOption = (props) => {
  const { record } = props.data;
  return (
    <components.Option {...props} >
      <div>
        <div className="bold-text">{record.params.first_name} {record.params.last_name}</div>
        <div >{record.params['affiliateDataObject.company']}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record.params.email}
        </div>
      </div>
    </components.Option>
  );
};

const getInitialQueryText = () => {
  const initialSearch = new URLSearchParams(window.location.search);
  let text = '';

  if (initialSearch.has('mediaUrl') && initialSearch.get('mediaUrl')) {
    text = initialSearch.get('mediaUrl');
  }

  return text;
}

const getInitialSearchBy = () => {
  const initialSearch = new URLSearchParams(window.location.search);
  let text = 'creator';

  if (initialSearch.has('mediaUrl') && initialSearch.get('mediaUrl')) {
    text = 'video';
  }

  return text;
}

const getInitialStartDate = () => {
  const initialSearch = new URLSearchParams(window.location.search);
  let startDate = moment().startOf('month').format('YYYY-MM-DD');

  if (initialSearch.has('startDate') && initialSearch.get('startDate')) {
    startDate = initialSearch.get('startDate');
  }

  return startDate;
}

const getInitialEndDate = () => {
  const initialSearch = new URLSearchParams(window.location.search);
  let endDate = moment().endOf('month').format('YYYY-MM-DD');

  if (initialSearch.has('endDate') && initialSearch.get('endDate')) {
    endDate = initialSearch.get('endDate');
  }

  return endDate;
}

const getInitialSelectedButton = () => {
  const initialSearch = new URLSearchParams(window.location.search);
  let button = 'current_month';

  if (
    (initialSearch.has('startDate') && initialSearch.get('startDate'))
    || (initialSearch.has('endDate') && initialSearch.get('endDate'))
  ) {
    button = 'custom';
  }
  return button;
}

const VideoCommentsAnalysisReport = ({ addNotice }) => {
  const [useShowForUsers] = hooks.useAccessHook({ hideWhenTeam: ['Auditors'] });
  const [loading, setLoading] = useState(false);
  const [loadingVideoOptions, setLoadingVideoOptions] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [startDate, setStartDate] = useState(getInitialStartDate());
  const [endDate, setEndDate] = useState(getInitialEndDate);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [videoOptions, setVideoOptions] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [queryText, setQueryText] = useState(getInitialQueryText());
  const [searchBy, setSearchBy] = useState(getInitialSearchBy());
  const [selectedOption, setSelectedOption] = useState(dataOptions[0]);
  const [periodsData, setPeriodsData] = useState({
    comments: [],
  });
  const [mediaContentId, setMediaContentId] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [influencerId, setInfluencerId] = useState(null);
  const [tableData, setTableData] = useState([]);

  const userTimezone = getUserTimezoneAbbreviation();

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  const commentsStats = useMemo(() => {
    const sumAll = ({
      count, negativeCount, neutralCount, positiveCount, label,
    }, item) => {
      return {
        count: count + item?.count || 0,
        negativeCount: negativeCount + item?.negativeCount || 0,
        neutralCount: neutralCount + item?.neutralCount || 0,
        positiveCount: positiveCount + item?.positiveCount || 0,
        positivePercentage: (count + item?.count || 0) ? ((positiveCount + item?.positiveCount || 0) / (count + item?.count || 0)) : 0,
        negativePercentage: (count + item?.count || 0) ? ((negativeCount + item?.negativeCount || 0) / (count + item?.count || 0)) : 0,
        label: label || item?.label,
      };
    };
    const total = selectedOption.value;
    const startMoment = moment(startDate).startOf('day');
    const endMoment = moment() > moment(endDate).endOf('day') ? moment(endDate).endOf('day') : moment().endOf('day');
    const chartData = {};
    const chartDataMonth = {};
    const chartDataWeek = {};
    const totalData = {};
    const days = endMoment.diff(startMoment, 'day');
    const months = endMoment.diff(startMoment, 'month');
    const weeks = endMoment.diff(startMoment, 'weeks') + 1;

    for (let i = 0; i <= days; i += 1) {
      const momentDate = startMoment.clone().add(i, 'day');
      const key = momentDate.format('YYYY-MM-DD');
      chartData[key] = {
        label: momentDate.format('M/D/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
      totalData[key] = {
        label: momentDate.format('M/D/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
    }
    for (let i = 0; i <= months; i += 1) {
      const momentDate = startMoment.clone().add(i, 'month');
      const key = momentDate.format('YYYY-MM');
      chartDataMonth[key] = {
        label: momentDate.format('M/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
    }
    for (let i = 0; i <= weeks; i += 1) {
      const momentDate = startMoment.clone().add(i, 'week').startOf('week');
      const key = momentDate.format('YYYY-w');
      // console.log(key, moment(key, 'YYYY-w').startOf('week'), endMoment);
      if (momentDate > endMoment) break;
      chartDataWeek[key] = {
        label: `${moment(key, 'YYYY-w').startOf('week').format('M/D/YY')} - ${moment(key, 'YYYY-w').endOf('week').format('M/D/YY')}`,
        count: 0,
        negativeCount: 0,
        neutralCount: 0,
        positiveCount: 0,
        positivePercentage: 0,
        negativePercentage: 0,
      };
    }


    periodsData.comments.forEach(({
      label, count, negativeCount, neutralCount, positiveCount,
    }) => {
      const week = moment(label).format('YYYY-w');
      const byMonthKey = label.substring(0, 7);
      chartData[label] = sumAll({
        count, negativeCount, neutralCount, positiveCount,
      }, chartData[label]);
      chartDataMonth[byMonthKey] = sumAll({
        count, negativeCount, neutralCount, positiveCount, label: moment(label).format('M/YY'),
      }, chartDataMonth[byMonthKey]);
      chartDataWeek[week] = sumAll({
        count, negativeCount, neutralCount, positiveCount,
      }, chartDataWeek[week]);
    });

    if (total === 'total') {
      let beforeValue = {
        count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0,
      };
      Object.keys(chartData).forEach((day) => {
        const valueForDays = { ...chartData[day], label: day };
        totalData[day] = sumAll({ ...beforeValue, label: moment(day).format('M/D/YY') }, valueForDays);
        beforeValue = { ...totalData[day] };
      });
    }
    if (total === 'month') return Object.values(chartDataMonth);
    if (total === 'week') return Object.values(chartDataWeek);
    if (total === 'total') return Object.values(totalData);
    return Object.values(chartData);
  }, [selectedOption, periodsData.comments]);

  const loadCreatorVideos = async () => {
    setLoadingVideoOptions(true);
    const response = await axios.get(`/api/portal/reports/get-creator-video-options?creatorId=${selectedCreator.value}`);
    if (response.data.success && response.data.options) {
      setVideoOptions(response.data.options);
    }
    setLoadingVideoOptions(false);
  }

  useEffect(() => {
    setVideoOptions([]);
    if (selectedCreator?.value) {
      loadCreatorVideos();
    }
  }, [selectedCreator]);

  useEffect(() => {
    const initialSearch = new URLSearchParams(window.location.search);
    if (initialSearch.has('mediaUrl') && initialSearch.get('mediaUrl')) {
      setSearchBy('video');
      setTimeout(() => fetchReport(initialSearch.get('mediaUrl')), 1500);
    }
  }, []);

  const setPeriodDataForTypes = (comments, tableData) => {
    const array = {
      comments,
      tableData,
    };
    setPeriodsData(array);
  };

  const fetchReport = async (passedMediaUrl) => {
    if (!loading) {
      setLoading(true);
      const data = {
        startDate,
        endDate,
        userTimezone: Intl.DateTimeFormat()
          .resolvedOptions().timeZone,
        identity,
      };
      if (passedMediaUrl || searchBy === 'video') {
        data.mediaContentSearch = passedMediaUrl || queryText;
      } else {
        data.mediaContentId = selectedVideo?.value;
      }
      setLoading(true);
      try {
        const resp = await axios.post('/api/portal/reports/post-report-video-comments-analysis', data);
        setLoading(false);
        if (resp.data.success) {
          const {
            comments,
            mediaContentId,
            mediaUrl,
            influencerId,
            tableData,
          } = resp.data;
          if (comments?.length > 0) {
            setMediaContentId(mediaContentId);
            setMediaUrl(mediaUrl);
            setInfluencerId(influencerId);
            setTableData(tableData);
            setPeriodDataForTypes(comments);
          } else {
            setPeriodDataForTypes([], []);
            addNotice({
              message: 'No comments found with current filters!',
              type: 'success',
              duration: 60,
            });
          }
        } else {
          throw new Error(resp.data.error);
        }
      } catch (e) {
        console.error(e);
        addNotice({
          message: e.message,
          type: 'error',
        });
      }
    }
  };

  if (!useShowForUsers) {
    return null;
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Statistics', path: false },
              { title: 'Video Comments', path: false },
              { title: 'Video Comments Analysis', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Video Comments Analysis</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col md={10}>
                  <Label className="bold-text">Comment Date</Label>
                  <Filter
                    userTimezone={userTimezone}
                    setValue={(param, value) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                    selectedButtonInitial={getInitialSelectedButton()}
                    startDateInitial={startDate}
                    endDateInitial={endDate}
                  />
                </Col>
                <Col md={2}>
                  <div
                    className="d-flex"
                    style={{
                      float: 'right',
                      paddingTop: '31px',
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchReport();
                        return false;
                      }}
                      className="my-0 btn-sm"
                      disabled={!(searchBy === 'creator' ? (selectedCreator?.value && selectedVideo?.value) : queryText) || loading === true}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={3}>
                  <Label className="bold-text">
                    Search by
                  </Label>
                  <div style={{ paddingTop: '6px', marginLeft: '30px' }}>
                    <FormControlLabel
                      control={
                        <Input
                          type="radio"
                          name="searchType"
                          value="creator"
                          className="mt-0"
                          checked={searchBy === 'creator'}
                          onChange={() => { setSearchBy('creator'); }}
                        />
                      }
                      label="Creator"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormControlLabel
                      control={
                        <Input
                          type="radio"
                          name="searchType"
                          value="video"
                          className="mt-0"
                          checked={searchBy === 'video'}
                          onChange={() => { setSearchBy('video'); }}
                        />
                      }
                      label="Video"
                    />
                  </div>
                </Col>
                {searchBy === 'creator' ? (
                  <>
                    <Col md={4}>
                      <Label className="bold-text">
                        Creator
                      </Label>
                      <SelectAsync
                        cacheOptions
                        isClearable
                        loadOptions={async value => loadCreatorsOptions(value)}
                        onChange={setSelectedCreator}
                        placeholder="Search Creator..."
                        formatOptionLabel={formatCreatorOptionLabel}
                        components={{ Option: creatorOption }}
                        value={selectedCreator}
                      />
                    </Col>
                    <Col md={5}>
                      <Label className="bold-text">Video</Label>
                      <Select
                        isClearable
                        isDisabled={!selectedCreator || loadingVideoOptions}
                        value={selectedVideo}
                        options={videoOptions}
                        onChange={setSelectedVideo}
                        placeholder="Select Video..."
                        styles={colourStyles}
                      />
                    </Col>
                  </>
                ) : (
                  <Col md={9}>
                    <Label className="bold-text">
                      Video
                    </Label>
                    <Input
                      type="search"
                      className="form-control"
                      placeholder="Search by Title or URL"
                      value={queryText}
                      onChange={event => setQueryText(event.target.value)}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {!loading && periodsData.comments.length > 0 && (
                <>
                  <Row className="mt-3">
                    <Col md={2}>
                      <div
                        className="form-group"
                      >
                        <Select
                          placeholder="Select Property to Chart..."
                          value={selectedOption}
                          options={dataOptions}
                          onChange={setSelectedOption}
                        />
                      </div>
                    </Col>
                    <Col>
                      <FormGroup style={{ paddingTop: '10px' }}>
                        <CheckBoxField
                          name="showLabels"
                          label="Show Labels on Charts"
                          value={showLabels}
                          onChange={e => setShowLabels(e.target?.checked || false)}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                    <Col />
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <h4 className="pb-3">Percent Positive & Negative Comments</h4>
                      <ChartComponentCommentsPercentage
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <h4 className="pb-3">Positive and Negative Comments</h4>
                      <ChartComponentCommentsWithSentinement
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <h4 className="pb-3">Total Comments</h4>
                      <ChartComponentCommentsTotal
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  {tableData?.length > 0 && (
                    <VideoComments
                      data={tableData}
                      mediaContentId={mediaContentId}
                      mediaUrl={mediaUrl}
                      influencerId={influencerId}
                    />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

VideoCommentsAnalysisReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(VideoCommentsAnalysisReport);
