import React from 'react';
import PropTypes from 'prop-types';
import ApiClient from 'ApiClient';
import { axios } from 'ApiClient';
import { unflatten } from 'flat';
import { Container, Row, Col, Card, CardBody, FormGroup, FormFeedback, Input, Label, ButtonToolbar, Spinner } from 'reactstrap';
import SelectAsync from 'react-select/async';
import withNotice from '../../../App/store/with-notice';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import WVValidator from '../../../../shared/helpers/WVValidator';
import WVConstants from '../../../../shared/helpers/WVConstants';
import ButtonWithSpinner from '../../../../shared/components/ButtonWithSpinner';
import BillComMFARefreshComponent from '../../Finance/CreatorPayment/components/BillComMFARefreshComponent';

class InviteVendor extends React.Component {
  constructor(props) {
    super(props);

    this.api = new ApiClient();

    this.state = {
      influencer: null,
      name: '',
      accountType: null,
      addressCountry: null,
      mfaChallenged: false,
      userTypeLabel: 'Influencer',
      loading: {
        fetchInlfuencer: false,
      },
      errors: {
      },
    };
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.influencerId && this.props.match.params.userType) {
      this.fetchInlfuencer(this.props.match.params.userType);
    }
  }

  onInviteButton = () => {
    if (this.state.loading.invite) { return; }
    if (!this.validateErrors()) { return; }

    this.setState({
      loading: { invite: true },
    });

    const {
      influencer,
      name,
      accountType,
      addressCountry,
      userTypeLabel,
    } = this.state;

    axios({
      method: 'post',
      url: '/api/portal/billCom/create-vendor',
      data: {
        influencerId: influencer.value,
        name,
        accountType,
        addressCountry,
        userType: userTypeLabel,
      },
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);
      if (response.data.success !== true) {
        // Z: request BillCom MFA session
        if (response.data.error === 'ILBillComErrorMFAInvalid') {
          this.setState({
            loading: { invite: false },
          });

          this.challengeMFAReauthentication();
          return;
        }
        // END Z;

        this.processError(response.data.error);
        return;
      }

      // SUCCESS
      this.setState({
        influencer: null,
        name: '',
        accountType: null,
        addressCountry: null,
        loading: { invite: false },
      });

      this.props.addNotice({
        message: 'New vendor has been successfully created and invited to Bill.com!',
        type: 'success',
      });
    }).catch((error) => {
      this.processError(error.message);
    });
  }

  onInputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  onAccountTypeChanged = (event) => {
    this.setState({ accountType: event.target.value });
  }

  onAddressCountryChanged = (event) => {
    this.setState({ addressCountry: event.target.value });
  }

  onMFAReauthenticationFinished = () => {
    this.setState({ mfaChallenged: false });
  }

  challengeMFAReauthentication = () => {
    this.setState({ mfaChallenged: true });
  }

  handleChangeSelect = (name, selected) => {
    const { errors } = this.state;
    errors[`${name}`] = false;

    this.setState({
      [`${name}`]: selected,
      errors,
    });
  }

  fetchInlfuencer = async (userType) => {
    const { influencerId } = this.props.match.params;
    const resourceName = userType === 'creator' ? 'Influencer' : 'AgentUser';

    this.setState({ loading: { fetchInlfuencer: true }, userTypeLabel: resourceName === 'Influencer' ? resourceName : 'Agent' });

    const response = await this.api.recordAction({
      resourceId: resourceName,
      recordId: influencerId,
      actionName: 'show',
    });

    if (!response.data || !response.data.record) {
      this.setState({ loading: { fetchInlfuencer: false } });
      return;
    }

    const { record } = response.data;

    const name = `${record.params.first_name ? record.params.first_name : ''} ${record.params.last_name ? record.params.last_name : ''}`;

    let accountType = null;
    if (record.params.accountType === undefined) {
      accountType = null;
    } else {
      accountType = record.params.accountType === 0 ? 'individual' : 'business';
    }

    const influencer = {
      value: record.params._id,
      label: `${record.params.email} - ${name}`,
      record,
    };

    this.setState({
      influencer,
      name,
      accountType,
      addressCountry: record.params['payment.countryCode'] || null,
      loading: { fetchInlfuencer: false },
    });
  }

  influencersLoadOptions = async (inputValue) => {
    const resourceName = this.props.match.params.userType === 'creator' ? 'Influencer' : 'AgentUser';
    const records = await this.api.searchRecords({
      resourceId: resourceName,
      query: inputValue,
    });
    return records.map(record => ({
      value: record.params._id,
      label: `${record.params.email} - ${record.params.first_name ? record.params.first_name : ''} ${record.params.last_name ? record.params.last_name : ''}`,
      record,
    }));
  }

  validateErrors() {
    const {
      influencer,
      name,
      accountType,
      addressCountry,
      userTypeLabel,
    } = this.state;

    const errors = {};
    //
    if (!influencer || !influencer.value || !influencer.record) {
      errors.influencer = `Please select ${userTypeLabel}.`;
    } else if (influencer && influencer.record) {
      const influencerData = unflatten(influencer.record.params);
      const { billCom } = influencerData.payment;
      if (!WVValidator.isEmpty(billCom.accountId)) {
        errors.influencer = `Selected ${userTypeLabel} already has connected Bill.com account.`;
      }
    }
    //
    if (WVValidator.isEmpty(name)) {
      errors.name = 'Please provide vendor\'s name.';
    }
    if (WVValidator.isEmpty(accountType)) {
      errors.accountType = 'Please provide vendor\'s account type.';
    }
    if (WVValidator.isEmpty(addressCountry)) {
      errors.addressCountry = 'Please provide vendor\'s address country.';
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  processError(error) {
    const message = error || 'Something went wrong!';

    this.setState({
      loading: { invite: false },
    });

    this.props.addNotice({
      message,
      type: 'error',
    });
  }

  // RENDER UI
  renderFormControls() {
    const {
      influencer,
      accountType,
      name,
      loading,
      errors,
      userTypeLabel,
      addressCountry,
    } = this.state;

    if (loading.fetchInlfuencer) {
      return null;
    }

    return (
      <section>
        <FormGroup>
          <Label>{`${userTypeLabel} *`}</Label>
          <SelectAsync
            cacheOptions
            value={influencer}
            defaultOptions
            loadOptions={this.influencersLoadOptions}
            onChange={selected => this.handleChangeSelect('influencer', selected)}
            placeholder={`Please Select ${userTypeLabel}`}
          />
          {errors.influencer && (<span className="text-danger">{errors.influencer}</span>)}
        </FormGroup>
        <FormGroup>
          <Label>Name *</Label>
          <Input
            name="name"
            type="text"
            value={name}
            invalid={!!errors.name}
            onChange={this.onInputChanged}
          />
          <FormFeedback>{errors.name}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Account Type *</Label>
          <Input
            type="select"
            onChange={this.onAccountTypeChanged}
            value={accountType || 'defaultAccountType'}
            invalid={!!errors.accountType}
          >
            <option value="defaultAccountType" disabled>Select Account Type</option>
            <option value="individual">Individual</option>
            <option value="business">Company</option>
          </Input>
          <FormFeedback>{errors.accountType}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Country *</Label>
          <Input
            type="select"
            onChange={this.onAddressCountryChanged}
            value={addressCountry || 'defaultAddressCountry'}
            invalid={!!errors.addressCountry}
          >
            <option value="defaultAddressCountry" disabled>Select Country</option>
            {
              Object.keys(WVConstants.countries()).map(code => (
                <option value={code} key={code}>{WVConstants.countries()[code]}</option>
              ))
            }
          </Input>
          <FormFeedback>{errors.addressCountry}</FormFeedback>
        </FormGroup>
        <ButtonToolbar className="form__button-toolbar mt-4">
          <ButtonWithSpinner
            color="primary"
            loading={loading.invite}
            onClick={this.onInviteButton}
          >
            Create Vendor & Invite
          </ButtonWithSpinner>
        </ButtonToolbar>
      </section>
    );
  }

  render() {
    const {
      loading,
      mfaChallenged,
      userTypeLabel,
    } = this.state;
    const userTypeModel = userTypeLabel === 'Influencer' ? userTypeLabel : 'AgentUser';

    return (
      <Container className="dashboard">
        <Breadcrumb
          links={[
            { title: `${userTypeLabel}`, path: `/resources/${userTypeModel}/actions/list` },
            { title: 'Invite to Bill.com', path: null },
          ]}
          isBackButton
        />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Create & Invite New Vendor</h5>
                  <h5 className="subhead">Add new vendor to IL Bill.com organization and invite him to connect to Bill.com </h5>
                </div>
                {loading.fetchInlfuencer && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
                { this.renderFormControls() }
                { /* MFA modals */
                  mfaChallenged &&
                  <BillComMFARefreshComponent
                    addNotice={this.props.addNotice}
                    onAuthenticationFinished={this.onMFAReauthenticationFinished}
                  />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

InviteVendor.propTypes = {
  addNotice: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withNotice(InviteVendor);
