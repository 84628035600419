import InfluencerInList from './InfluencerInList';
import EventDateInList from './EventDateInList';

export default {
  show: {
  },
  list: {
    eventDate: EventDateInList,
    creatorName: InfluencerInList,
  },
  edit: {
  },
};
