import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label, Modal, Spinner, Input } from 'reactstrap';
import ApiClient from 'ApiClient';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import StyledButton from '../../../../../../shared/components/components/ui/styled-button';
import recordToFormData from '../../../../../../shared/components/components/actions/record-to-form-data';
import withNotice from '../../../../../App/store/with-notice';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';

class AgentForm extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiClient();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.state = {
      loading: {
        form: false,
      },
      agent: {
        params: {},
        errors: {},
      },
      hsAgentInBlacklist: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modal === false) {
      this.fetchData();
    }
  }
  static requiredData = [
    'email', 'firstname', 'lastname', 'company',
  ];

  fetchData() {
    this.setState({
      agent: {
        params: {
          email: undefined,
          firstname: undefined,
          lastname: undefined,
          company: undefined,
        },
        errors: {},
      },
    });
    return null;
  }
  handleChange(propertyOrRecord, value) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        agent: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        agent: {
          ...state.agent,
          params: {
            ...state.agent.params,
            [propertyOrRecord]: value,
          },
          errors: {
            ...state.agent.errors,
            [propertyOrRecord]: undefined,
          },
        },
      }));
    }
  }

  handleInputChange(name, event) {
    this.handleChange(name, event.target.value);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { agent } = this.state;
    let isValid = true;
    this.constructor.requiredData.forEach((item) => {
      if (!agent.params[item]) {
        isValid = false;
        this.setState(state => ({
          agent: {
            ...state.agent,
            errors: {
              ...state.agent.errors,
              [item]: 'This field is required',
            },
          },
        }));
      }
    });
    if (!isValid) {
      this.props.addNotice({
        message: 'Please Check form. Seems you did not fill some fields',
        type: 'warning',
      });
      return true;
    }
    agent.params.adminUser = this.props.adminUser._id;
    const formData = recordToFormData(agent);
    this.setState({
      loading: {
        form: true,
      },
    });

    axios.get(`/api/portal/blacklist/get-item-by-email?email=${encodeURIComponent(agent.params.email.toLowerCase().trim())}`).then((response) => {
      if (response.data.success) {
        console.log(response.data.result);
        if (response.data.result) {
          this.setState({
            hsAgentInBlacklist: true,
          });
          isValid = false;
        } else {
          this.setState({
            hsAgentInBlacklist: false,
          });
        }
      }
    });

    if (!isValid) return true;
    axios({
      method: 'post',
      url: '/api/portal/agent/create-hubspot-agent',
      data: Object.fromEntries(formData),
    }).then((response) => {
      this.setState({
        loading: {
          form: false,
        },
      });
      if (response.data.success === true) {
        this.closeFormDialog(response.data.agent);
        this.props.addNotice({
          message: 'New Agent has been successfully added',
          type: 'success',
        });
      } else {
        this.props.addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
    }).catch((error) => {
      console.error(error);
      this.setState({
        loading: {
          form: false,
        },
      });
      this.props.addNotice({
        message: 'There was an error creating record, Check out console to see more information.',
        type: 'error',
      });
    });
    return false;
  }
  closeFormDialog(newHubilAgent = null) {
    const { closeDialog } = this.props;
    closeDialog(newHubilAgent);
  }

  render() {
    const { modalTitle, modal } = this.props;
    const {
      agent,
      hsAgentInBlacklist,
    } = this.state;

    return (
      <Modal
        isOpen={modal}
        toggle={this.closeFormDialog}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">{modalTitle}</h4>
        </div>
        <div className="modal__body finance-form">
          {this.state.loading.form &&
          (
            <div className="text-center"><Spinner color="primary" size="lg" /></div>
          )
          }
          {!this.state.loading.form &&
          (
            <form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label>First Name</Label>
                <Input
                  type="text"
                  id="firstname"
                  name="firstname"
                  onChange={e => this.handleInputChange('firstname', e)}
                  value={typeof agent.params !== 'undefined' && typeof agent.params.firstname !== 'undefined' ? agent.params.firstname : ''}
                />
                {agent.errors.firstname && (<span className="text-danger">{agent.errors.firstname}</span>)}
              </FormGroup>
              <FormGroup>
                <Label>Last Name</Label>
                <Input
                  type="text"
                  id="lastname"
                  name="lastname"
                  onChange={e => this.handleInputChange('lastname', e)}
                  value={typeof agent.params !== 'undefined' && typeof agent.params.lastname !== 'undefined' ? agent.params.lastname : ''}
                />
                {agent.errors.lastname && (<span className="text-danger">{agent.errors.lastname}</span>)}
              </FormGroup>
              <FormGroup>
                <Label>Company</Label>
                <Input
                  type="text"
                  id="company"
                  name="company"
                  onChange={e => this.handleInputChange('company', e)}
                  value={typeof agent.params !== 'undefined' && typeof agent.params.company !== 'undefined' ? agent.params.company : ''}
                />
                {agent.errors.company && (<span className="text-danger">{agent.errors.company}</span>)}
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="text"
                  id="email"
                  name="email"
                  onChange={e => this.handleInputChange('email', e)}
                  value={typeof agent.params !== 'undefined' && typeof agent.params.email !== 'undefined' ? agent.params.email : ''}
                />
                {agent.errors.email && (<span className="text-danger">{agent.errors.email}</span>)}
                {hsAgentInBlacklist && (
                  <Alert color="warning" className="w-100 mt-3">
                    <p className="py-2 text-dark">
                      HubSpot Contact with current email is in Black list.
                    </p>
                  </Alert>
                )}
              </FormGroup>
              <StyledButton
                type="submit"
                className="is-primary"
              >
                <i className="icomoon-save" />
                <span className="btn-text">Save</span>
              </StyledButton>
              &nbsp;&nbsp;
              <StyledButton
                type="button"
                className="btn-danger"
                onClick={this.closeFormDialog}
              >
                <i className="icomoon-save" />
                <span className="btn-text">Cancel</span>
              </StyledButton>
            </form>
          )
          }
        </div>
      </Modal>
    );
  }
}

AgentForm.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  adminUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

AgentForm.defaultProps = {};

const mapStateToProps = state => ({
  adminUser: state.session,
});


export default withNotice(connect(mapStateToProps)(AgentForm));
