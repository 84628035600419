import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import ApiClient from 'ApiClient';
import withNotice from '../../../containers/App/store/with-notice';

class ModalComponent extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    resource: PropTypes.objectOf(PropTypes.any).isRequired,
    addNotice: PropTypes.func,
  };

  static defaultProps = {
    title: 'Are you Sure?',
    message: 'Do you want delete this item?',
    colored: false,
    header: false,
    id: null,
    addNotice: null,
  };

  constructor() {
    super();
    this.close = this.close.bind(this);
    this.clickYes = this.clickYes.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  close() {
    this.props.setIsOpen(false);
  }

  deleteItem() {
    const { id } = this.props;
    const { resource, addNotice } = this.props;
    const api = new ApiClient();
    api.recordAction({
      resourceId: resource.id, actionName: 'delete', recordId: id,
    }).then((response) => {
      addNotice(response.data.notice);
      window.location.reload();
    }).catch(() => {
      addNotice({
        message: 'An error occurred while trying to remove the item.',
        type: 'error',
      });
    });
  }

  clickYes() {
    this.close();
    this.deleteItem();
  }

  render() {
    const {
      title,
      message,
      colored,
      header,
      id,
      isOpen,
    } = this.props;
    let Icon;

    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={this.close}
          modalClassName="ltr-support"
          className={`modal-dialog--danger ${modalClass} ${id}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.close} />
            {header ? '' : Icon}
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            {message}
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_ok btn-sm" outline={colored} color="danger" onClick={this.clickYes}>Remove</Button>{' '}
            <Button className="modal_cancel btn-sm" onClick={this.close}>Cancel</Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default withNotice(ModalComponent);
