/* eslint-disable filenames/match-exported */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import { getDefaultTargetCac, calculateConversionsCount, calculatePayout } from '../../../shared/helpers/CACHelper';
import { formatCurrency, formatNumber } from '../../../shared/helpers/WVFormatter';


const WideTooltip = withStyles({
  tooltip: {
    width: '500px',
  },
})(Tooltip);

const CAC = React.memo(({
  dealForCac, CPApaidConversionsArray, CPMpaidConversionsCount, brand, settings,
}) => {
  // console.log({ dealForCac, CPApaidConversionsArray, CPMpaidConversionsCount, brand, settings }, 'def');
  const conversionsCount = calculateConversionsCount({ CPApaidConversionsArray, dealForCac, CPMpaidConversions: CPMpaidConversionsCount });
  const cac = (conversionsCount ? (calculatePayout({ CPApaidConversionsArray, dealForCac }) / conversionsCount) : null);
  console.log([conversionsCount, cac, CPApaidConversionsArray, dealForCac], 'CONVERSIONS' )
  if (!brand || !dealForCac || !conversionsCount) return '-';
  const valueNumber = cac === null ? 0 : cac;
  const [targetCac, defaultTargetCac, percent] = getDefaultTargetCac(brand, dealForCac?.dealTerms.type_of_deal, settings);
  const value = cac === null ? '-' : formatCurrency(cac);
  const allow = cac !== null && cac > 0 && targetCac >= cac;
  const describeValue = (
    <div style={{ fontSize: '14px', maxWidth: '500px', lineHeight: 2 }}>
      <span><strong>Target CAC</strong>: {formatCurrency(Number(defaultTargetCac))}</span><br />
      <strong>Target Threshold %</strong>: {Number(percent) * 100}<br />
      <strong>Target CAC Threshold</strong>: {formatCurrency(Number(targetCac))}<br />
      <strong>Amount Over Target CAC Threshold</strong>: {valueNumber ? formatCurrency(Number(cac || 0) - Number(targetCac)) : '-'}<br />
      <strong>Percent Over Target CAC Threshold</strong>: {valueNumber ? `${formatNumber(((Number(cac || 0) - Number(targetCac)) / Number(targetCac)) * 100, 2)}%` : '-'}<br />
      {/* eslint-disable-next-line max-len */}
      <span><strong>CAC</strong> of <strong>{valueNumber ? formatCurrency(Number(cac || 0)) : '-'}</strong> {allow ? 'is less than ' : 'is over'} the <strong>{formatCurrency(targetCac)}</strong> threshold</span><br />
    </div>);
  return (
    <>
      <WideTooltip title={describeValue}><span className={allow ? 'text-success' : 'text-danger'}>{value}</span></WideTooltip>{' '}
      {allow ? (
        <FontAwesomeIcon icon="thumbs-up" className="text-success" />
      ) : (
        <FontAwesomeIcon icon="thumbs-down" className="text-danger" />
      )
      }
    </>
  );
}, (a, a1) => _.isEqual(a.dealForCac, a1.dealForCac));

CAC.propTypes = {
  dealForCac: PropTypes.objectOf(PropTypes.any),
  brand: PropTypes.objectOf(PropTypes.any),
  CPApaidConversionsArray: PropTypes.arrayOf(PropTypes.any),
  CPMpaidConversionsCount: PropTypes.number,
  settings: PropTypes.objectOf(PropTypes.any),
};
CAC.defaultProps = {
  dealForCac: null,
  brand: null,
  settings: {},
  CPApaidConversionsArray: [],
  CPMpaidConversionsCount: 0,
};

export default CAC;
