import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Modal,
} from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import withNotice from '../../../../../../../App/store/with-notice';

const RefreshStatisticsOptionsModal = ({
  isOpen,
  toggleModal,
  onRefreshFunc,
  prospectId,
}) => {
  const [selectedOption, setSelectedOption] = useState({ value: false, label: 'No, do not overwrite' });

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
      size="lg"
      style={{
        maxWidth: '900px',
        fontSize: '105%',
        fontStretch: '90%',
      }}
    >
      <div className="modal__header mb-0">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
        <h4 className="text-modal modal__title mb-1">
          Refresh Statistics
        </h4>
      </div>
      <div
        className="modal__body mt-3 mb-3"
      >
        <p>
          The prospect&apos;s channel titles, URLs and email may have been edited already and can possibly be updated when refreshing data from Tubular and ListenNotes.
          <br />
          Would you like to allow overwriting of this data?
        </p>
        <div className="w-100 mt-2">
          <Select
            name="select"
            value={selectedOption}
            options={
              [
                { value: false, label: 'No, do not overwrite' },
                { value: true, label: 'Yes, overwrite' },
              ]
            }
            onChange={setSelectedOption}
            styles={colourStyles}
          />
        </div>
      </div>
      <ButtonToolbar className="modal__footer mt-3">
        <Button
          color="primary"
          onClick={() => {
            onRefreshFunc(prospectId, selectedOption.value);
            toggleModal();
            setSelectedOption({ value: false, label: 'No, do not overwrite' });
          }}
        >
          Refresh
        </Button>
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

RefreshStatisticsOptionsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  onRefreshFunc: PropTypes.func.isRequired,
  prospectId: PropTypes.string,
};

RefreshStatisticsOptionsModal.defaultProps = {
  isOpen: false,
  prospectId: '',
};


export default connect()(withNotice(RefreshStatisticsOptionsModal));
