import React from 'react';
import { useSelector } from 'react-redux';
import MatTableList from '../../../../../../../shared/tables/materialTable/TableRedux';
import ContextMenuInList from './ContextMenuInList';

const Table = React.memo(({ categories, affiliateManagersSuggestions }) => {
  const perPage = useSelector(state => state.tableRedux.perPage);
  console.log('perPage', perPage)

  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Creators Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'notificationName',
          Header: () => 'Noticiation Name',
          accessor: record => (record?.params?.notificationName || ''),
          width: 250,
          className: 'align-self-center',
        },
        {
          id: 'type',
          Header: () => 'Type',
          accessor: record => (record?.params?.type || ''),
          width: 150,
          className: 'align-self-center',
        },          
        {
          id: 'actions',
          Header: () => '',
          accessor: () => null,
          Cell: p => (
            <ContextMenuInList
              record={p.row.original}
            />
          ),
          disableSortBy: true,
          className: 'align-self-center text-right',
          width: 50,
          maxWidth: 50,
        },
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
