import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import AgentDeals from './AgentRelatedDataComponents/AgentDeals';
import AgentConversions from './AgentRelatedDataComponents/AgentConversons';
import AgentServiceAgreements from './AgentRelatedDataComponents/AgentServiceAgreements';
import MediaContent from './AgentRelatedDataComponents/MediaContent';
import AgentPayments from './AgentRelatedDataComponents/AgentPayments';
import { axios } from 'ApiClient';

const AgentRelatedData = ({
  agentId,
  dispatch,
}) => {
  const [deals, setDeals] = useState([]);
  const [creators, setCreators] = useState([]);
  const [shouldRefetch, setShouldRefetch] = useState(true);

  useEffect(() => {
    if (setShouldRefetch) {
      axios.get(`/api/portal/deals/get-deals-for-agent?id=${agentId}`).then((r) => {
        if (r.data && r.data.deals) {
          setDeals(r.data.deals);
          setCreators(r.data.deals?.filter(d => d.ilDealData?.influencer).map(d => d.ilDealData.influencer));
        }
      });
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  return (
    <div>
      <Row>
        <Col md={12}>
          <AgentDeals
            deals={deals}
            setShouldRefetch={setShouldRefetch}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AgentConversions
            deals={deals}
            influencers={creators}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AgentServiceAgreements
            influencers={creators}
            dispatch={dispatch}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <MediaContent
            influencers={creators}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AgentPayments
            agentId={agentId}
          />
        </Col>
      </Row>
    </div>
  );
};

AgentRelatedData.propTypes = {
  agentId: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
};

AgentRelatedData.propTypes = {
  dispatch: () => {},
};

export default AgentRelatedData;
