import React from 'react';
import {
  Modal,
  ModalHeader,
  ButtonToolbar,
  ModalBody,
  Button,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { hideModalById } from '../../../../../../redux/reducers/TableReducer';

export const modalId = 'commentViewModal';

const CommentViewModal = () => {
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const dispatch = useDispatch();
  const close = () => dispatch(hideModalById(modalId));

  // if (!modalInfo) return false;
  const { data: { text }, isShow } = modalInfo || { data: { record: null }, isShow: false };

  return (
    <Modal
      size="lg"
      isOpen={isShow}
      toggle={close}
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Comment
      </ModalHeader>
      <ModalBody>
        {text}
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button color="secondary" onClick={close}>Cancel</Button>

      </ButtonToolbar>
    </Modal>
  );
};

export default CommentViewModal;
