import React from 'react';
import PropTypes from 'prop-types';
import { assignedTo } from '../../../../../shared/helpers/models/ServiceAgreement';

const Resend = ({ agreement }) => {
  const { name, email } = assignedTo(agreement);
  return (
    <>
      <p>Are you sure you want to send a reminder email notification?</p>
      <p>
        <strong>Agreement currently assigned to:</strong><br />
        {name} ({email})
      </p>
      <p><strong>Please note:</strong> You cannot send a reminder within 1 hour of the last reminder that was sent.</p>
    </>
  );
};

Resend.propTypes = {
  agreement: PropTypes.objectOf(PropTypes.any),
};

Resend.defaultProps = {
  agreement: null,
};

export default Resend;
