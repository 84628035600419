import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { axios } from 'ApiClient';
import moment from 'moment-timezone';
import Select from 'react-select';
import formater from '../../../../shared/helpers/WVFormatter';
import Item from '../../Reports/StatItem';

const PayableReport = () => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState({ value: 'current_month', label: 'Current Month' });

  const fetch = async () => {
    setLoading(true);
    const resp = await axios.post('/api/portal/finance/post-report-totals-value', {
      selectedPeriod: selectedPeriod.value,
    });

    setTotal(resp?.data?.total || 0);
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, [selectedPeriod]);
  const text = useMemo(() => {
    let startDate = moment.tz('America/New_York').subtract(1, 'month').startOf('month');
    let endDate = moment.tz('America/New_York').subtract(1, 'month').endOf('month');
    switch (selectedPeriod.value) {
      case 'current_month':
        startDate = moment.tz('America/New_York').startOf('month');
        endDate = moment.tz('America/New_York').endOf('day');
        break;
      case 'last_month':
        startDate = moment.tz('America/New_York').subtract(1, 'month').startOf('month');
        endDate = moment.tz('America/New_York').subtract(1, 'month').endOf('month');
        break;
      case '3_month':
        startDate = moment.tz('America/New_York').subtract(3, 'month').startOf('month');
        endDate = moment.tz('America/New_York').subtract(3, 'month').endOf('month');
        break;
      case 'start_of_year':
        startDate = moment.tz('America/New_York').startOf('year');
        endDate = moment.tz('America/New_York').endOf('day');
        break;
      case '90_days':
        startDate = moment.tz('America/New_York').subtract(90, 'days').startOf('day');
        endDate = moment.tz('America/New_York').endOf('day');
        break;
      default:
        break;
    }
    return `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`;
  }, [selectedPeriod]);

  return (
    <Card>
      <CardBody>
        <Row className="mb-3 finance-container">
          <Col xl="5" lg="4" />
          <Col xl="2" lg="4" className="align-items-stretch mb-3">
            <Item
              items={[{
                label: 'No. Unique Creators Paid',
                value: formater.formatIntNumber(total),
              }]}
            />
          </Col>
        </Row>
        <Row className="">
          <Col xl="5" lg="4" />
          <Col xl="2" lg="4" className=" mb-3">
            <Select
              value={selectedPeriod}
              options={[
                { value: 'current_month', label: 'Current Month' },
                { value: 'last_month', label: 'Last Month' },
                { value: 'start_of_year', label: 'Year To Day' },
                { value: '90_days', label: 'Last 90 Days' },
              ]}
              onChange={statusSelected => setSelectedPeriod(statusSelected)}
              placeholder=""
              isDisabled={loading}
            />
          </Col>
          <Col className="pt-2">
            <strong>{text}</strong>
          </Col>
        </Row>
      </CardBody>
    </Card>);
};
export default PayableReport;
