/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
} from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import withNotice from '../../../../../../App/store/with-notice';
import {
  changeCurrentDealOwnerId,
} from '../../../../../../../redux/reducers/admin/ResearchReducer';

const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

const DealsListModal = memo(({
  isOpen,
  closeFunc,
  deals,
  dispatch,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={() => closeFunc()}
    style={{ minWidth: '900px' }}
  >
    <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Deals</ModalHeader>
    <ModalBody>
      <div
        className="tokens-list"
        style={{ maxHeight: '300px', overflowY: 'auto' }}
      >
        {deals.map((deal, index) => (
          <div
            className="w-100"
            key={deal.hsObjectId}
          >
            <Row className="ml-0 mr-0">
              <Col md={10} className="pl-0 pt-1">
                <a
                  className="bold-text"
                  role="button"
                  onClick={() => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal.hsObjectId}/`)}
                >
                  {deal.name}
                  <FontAwesomeIcon className="ml-2" icon={faExternalLinkAlt} />
                </a>
              </Col>
              <Col md={2} className="d-flex align-items-center justify-content-center">
                <Button
                  disabled={deal.adminUserId === false}
                  className="btn-sm w-100 m-0"
                  color="primary"
                  title={deal.adminUserId ? 'Select this Deal Owner' : 'Undefined Deal Owner'}
                  onClick={() => {
                    dispatch(changeCurrentDealOwnerId(deal.adminUserId ? deal.adminUserId : ''));
                    setTimeout(closeFunc, 500);
                  }}
                >
                  Select
                </Button>
              </Col>
            </Row>
            <Row className=" ml-0 mr-0 mt-1">
              <Col md={10} className="d-flex justify-content-between">
                <div className="text-center">
                  <span className="bold-text align-bottom">
                    {deal.owner}
                  </span>
                  <br />
                  <span className="text-black-50">
                    Deal Owner
                  </span>
                </div>
                <div className="text-center">
                  <span className="bold-text align-bottom">
                    {deal.brand}
                  </span>
                  <br />
                  <span className="text-black-50">
                    Brand
                  </span>
                </div>
                <div className="text-center">
                  <span className="bold-text align-bottom">
                    {deal.type}
                  </span>
                  <br />
                  <span className="text-black-50">
                    Deal Type
                  </span>
                </div>
                <div className="text-center">
                  <span className="bold-text align-bottom">
                    {deal.stage}
                  </span>
                  <br />
                  <span className="text-black-50">
                    Deal Stage
                  </span>
                </div>
                <div className="text-center">
                  <span className="bold-text align-bottom">
                    {deal.status}
                  </span>
                  <br />
                  <span className="text-black-50">
                    Deal Status
                  </span>
                </div>
              </Col>
            </Row>
            {deals.length - 1 > index && (<hr className="mt-3 mb-2" />)}
          </div>
          ))}
      </div>
    </ModalBody>
    <ButtonToolbar className="modal__footer">
      <Button className="modal_ok btn-sm" color="primary" onClick={() => closeFunc()}>Close</Button>
    </ButtonToolbar>
  </Modal>
), (a, a1) => a.isOpen === a1.isOpen && _.isEqual(a.deals, a1.deals));

DealsListModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  deals: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withNotice(connect()(DealsListModal));
