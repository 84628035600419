import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Spinner, Label, FormGroup,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import withNotice from '../../../../../../App/store/with-notice';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { AddDealModalId } from './Modal/AddDealModal';
import { modalId as publishedModalId } from './Modal/PublishPAGroupModal';
import { goToPage } from '../../../../../../../shared/helpers/WVUtilities';

const Actions = React.memo(({
  approvalGroup,
  ilStatus,
  setIlStatus,
  brandStatus,
  setBrandStatus,
  loading,
  downloadCsv,
  justifications,
  monthFilter,
  setMonthFilter,
  justificationFilter,
  setJustificationFilter,
  search,
  setSearch,
}) => {
  const [monthOptions, setMonthOptions] = useState({});
  const dispatch = useDispatch();

  const getMonths = (start) => {
    const today = moment();
    const dateObjects = [];
    const currentDate = moment(start || '2022-01-01'); // Start date: January 1, 2022

    while (currentDate.isSameOrBefore(today, 'day')) {
      const formattedDate = currentDate.format('YYYY-MM-DD');
      const formattedMonthYear = currentDate.format('MMMM, YYYY');
      dateObjects.push({ value: formattedDate, label: formattedMonthYear });
      currentDate.add(1, 'month');
    }
    return dateObjects;
  };

  useEffect(() => {
    // console.log(approvalGroup);
    const monthsOpt = getMonths();
    setMonthOptions(monthsOpt);
  }, [approvalGroup]);

  /* useEffect(() => {
    setCookie('cpmInsertionOrderCreatorsProspShowFilters', showFilters.toString(), 35);
  }, [showFilters]); */

  const resetFilters = () => {
    setSearch('');
    setIlStatus({
      approved: true, inReview: true, pendingEdits: true, actionRequired: true, hold: true, rejected: true,
    });
    setBrandStatus({
      approved: true, inReview: true, hold: true, pendingEdits: true, rejected: true,
    });
    setMonthFilter([]);
    setJustificationFilter([]);
  };

  const handleChangeIlStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setIlStatus({
        ...ilStatus,
        [name]: checked,
      });
    }
  };
  const handleChangeBrandStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setBrandStatus({
        ...brandStatus,
        [name]: checked,
      });
    }
  };

  return (
    <div>
      <Row>
        <Col xs="10">
          <h4 className="mb-3">{!loading ? approvalGroup?.brand?.companyName : (<Spinner color="primary" size="sm" />)}</h4>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => { console.log('Add Deal'); dispatch(showModalById(AddDealModalId, { record: approvalGroup })); }}
              >
                Add Deal
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => { dispatch(showModalById(publishedModalId, { record: approvalGroup })); }}
              >
                Publish Price Approvals Group Sheet
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  goToPage(approvalGroup.googleSheetFiles?.length > 0 ? approvalGroup.googleSheetFiles.at(-1).fileUrl : '');
                }}
                disabled={!approvalGroup.googleSheetFiles?.length}
              >
                View
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const id = approvalGroup.googleSheetFiles?.length > 0 ? approvalGroup.googleSheetFiles.at(-1).fileId : '';
                  goToPage(`https://docs.google.com/spreadsheets/d/${id}/export?format=pdf&gridlines=false`);
                }}
                disabled={!approvalGroup?.googleSheetFiles?.length}
              >
                Download Unsigned PDF
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={downloadCsv}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row className="mt-4 mb-2">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Creator, Promocode"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} />
      </Row>
      <Row>
        <Col xs={2}>
          <Label className="bold-text" style={{ marginBottom: '14px' }}>
            IL Pricing Approval Status
          </Label>
          <FormGroup
            style={{ marginBottom: '10px' }}
          >
            <CheckBoxField
              name="IlApproved"
              label="Approved"
              value={ilStatus.approved}
              onChange={event => handleChangeIlStatusFilters(event, 'approved')}
            />
          </FormGroup>
          <FormGroup
            style={{ marginBottom: '10px' }}
          >
            <CheckBoxField
              name="IlInReview"
              label="In Review"
              value={ilStatus.inReview}
              onChange={event => handleChangeIlStatusFilters(event, 'inReview')}
            />
          </FormGroup>
          <FormGroup
            style={{ marginBottom: '10px' }}
          >
            <CheckBoxField
              name="IlPendingEdits"
              label="Pending Edits"
              value={ilStatus.pendingEdits}
              onChange={event => handleChangeIlStatusFilters(event, 'pendingEdits')}
            />
          </FormGroup>
          <FormGroup
            style={{ marginBottom: '10px' }}
          >
            <CheckBoxField
              name="actionRequired"
              label="Action Required"
              value={ilStatus.actionRequired}
              onChange={event => handleChangeIlStatusFilters(event, 'actionRequired')}
            />
          </FormGroup>
          <FormGroup
            style={{ marginBottom: '10px' }}
          >
            <CheckBoxField
              name="IlHold"
              label="Hold"
              value={ilStatus.hold}
              onChange={event => handleChangeIlStatusFilters(event, 'hold')}
            />
          </FormGroup>
          <FormGroup
            style={{ marginBottom: '0' }}
          >
            <CheckBoxField
              name="IlRejected"
              label="Rejected"
              value={ilStatus.rejected}
              onChange={event => handleChangeIlStatusFilters(event, 'rejected')}
            />
          </FormGroup>
        </Col>
        <Col xs={3}>
          <Label className="bold-text" style={{ marginBottom: '14px' }}>
            Brand Pricing Approval Status
          </Label>
          <FormGroup
            style={{ marginBottom: '10px' }}
          >
            <CheckBoxField
              name="brandApproved"
              label="Approved"
              value={brandStatus.approved}
              onChange={event => handleChangeBrandStatusFilters(event, 'approved')}
            />
          </FormGroup>
          <FormGroup
            style={{ marginBottom: '10px' }}
          >
            <CheckBoxField
              name="brandInReview"
              label="In Review"
              value={brandStatus.inReview}
              onChange={event => handleChangeBrandStatusFilters(event, 'inReview')}
            />
          </FormGroup>
          <FormGroup
            style={{ marginBottom: '10px' }}
          >
            <CheckBoxField
              name="brandHold"
              label="Hold"
              value={brandStatus.hold}
              onChange={event => handleChangeBrandStatusFilters(event, 'hold')}
            />
          </FormGroup>
          <FormGroup
            style={{ marginBottom: '10px' }}
          >
            <CheckBoxField
              name="brandPendingEdits"
              label="Pending Edits"
              value={brandStatus.pendingEdits}
              onChange={event => handleChangeBrandStatusFilters(event, 'pendingEdits')}
            />
          </FormGroup>
          <FormGroup
            style={{ marginBottom: '0' }}
          >
            <CheckBoxField
              name="brandRejected"
              label="Rejected"
              value={brandStatus.rejected}
              onChange={event => handleChangeBrandStatusFilters(event, 'rejected')}
            />
          </FormGroup>
        </Col>
        <Col xs={3}>
          <Label className="bold-text">
            Justification
          </Label>
          <Select
            value={justificationFilter}
            options={justifications}
            onChange={setJustificationFilter}
            className="dateOptions"
            placeholder="Justifications"
            menuPlacement="auto"
            menuPortalTarget={document.body}
            isMulti
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                minHeight: '45px',
              }),
            }}
          />
        </Col>
        <Col xs={3}>
          <Label className="bold-text">
            Month(s)
          </Label>
          <Select
            value={monthFilter}
            options={monthOptions}
            onChange={setMonthFilter}
            className="dateOptions"
            placeholder="Month(s)"
            menuPlacement="auto"
            menuPortalTarget={document.body}
            isMulti
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                minHeight: '45px',
              }),
            }}
          />
        </Col>
      </Row>
    </div>
  );
});
Actions.propTypes = {
  loading: PropTypes.bool.isRequired,
  approvalGroup: PropTypes.objectOf(PropTypes.any).isRequired,
  downloadCsv: PropTypes.func.isRequired,
  ilStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  setIlStatus: PropTypes.func.isRequired,
  brandStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  setBrandStatus: PropTypes.func.isRequired,
  justifications: PropTypes.arrayOf(PropTypes.any).isRequired,
  setMonthFilter: PropTypes.func.isRequired,
  monthFilter: PropTypes.arrayOf(PropTypes.any),
  setJustificationFilter: PropTypes.func.isRequired,
  justificationFilter: PropTypes.arrayOf(PropTypes.any),
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.string,
};

Actions.defaultProps = {
  monthFilter: [],
  justificationFilter: [],
  search: '',
};

export default withNotice(Actions);
