import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle, TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { axios } from 'ApiClient';
import classnames from 'classnames';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../App/store/with-notice';
import { parseUrl } from '../../../../../shared/helpers/WVUtilities';
import { clearUrl } from '../../../../../shared/helpers/WVValidator';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import UserPropertyType from './custom';
import { fetchContacts } from '../../../../../redux/reducers/admin/HubilReducer';
import ContactInList from './custom/ContactInList';
import CompanyInList from './custom/CompanyInList';
import ContactOwnerInList from './custom/ContactOwnerInList';
import EmailInList from './custom/EmailInList';
import BlacklistedInList from './custom/BlacklistedInList';
import ComplianceStatusInList from './custom/ComplianceStatusInList';
import FirstConversionDateInList from './custom/FirstConversionDateInList';
import LastConversionDateInList from './custom/LastConversionDateInList';
import ContextMenuInList from './custom/ContextMenuInList';

const HubilContactsListContainer = ({
  resources,
  addNotice,
  dispatch,
}) => {
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [forceFirstPage, setForceFirstPage] = useState(false);
  const [ownersSuggestions, setOwnersSuggestions] = useState([]);
  const [pseudoUrl, setPseudoUrl] = useState('');
  const [pseudoUrlContacts, setPseudoUrlContacts] = useState('');
  const [pseudoUrlAgents, setPseudoUrlAgents] = useState('');

  const compareInBlackList = {
    Yes: 'Yes',
    No: 'No',
  };

  const compareCompliance = {
    'Not Yet Published': 'Not Yet Published',
    'Under Review': 'Under Review',
    'Non-Compliant': 'Non-Compliant',
    'Fix in Progress': 'Fix in Progress',
    'Non-Response': 'Non-Response',
    Compliant: 'Compliant',
    Terminated: 'Terminated',
    'Not Set': 'Not Set',
  };

  const getFilterValueInitial = (filterType) => {
    let initialFilterType = '<=';
    const type = new URLSearchParams(pseudoUrl).get(filterType);
    if (type) {
      initialFilterType = type;
    }
    return initialFilterType;
  };

  const getSingleFirstConversionDateInitial = () => {
    let singleDate = null;
    const filters = parseUrl();
    const type = new URLSearchParams(pseudoUrl).get('firstConversionDateType');
    if (type && type !== 'Between') {
      if (type === '>' || type === '>=' || type === '=') {
        if (filters?.['date_of_first_conversion.from']) {
          singleDate = new Date(filters['date_of_first_conversion.from']);
        }
      }
      if (type === '<' || type === '<=') {
        singleDate = new Date(filters['date_of_first_conversion.to']);
      }
    }
    return singleDate;
  };

  const getSingleLastConversionDateInitial = () => {
    let singleDate = null;
    const filters = parseUrl();
    const type = new URLSearchParams(pseudoUrl).get('lastConversionDateType');
    if (type && type !== 'Between') {
      if (type === '>' || type === '>=' || type === '=') {
        if (filters?.['date_of_last_conversion.from']) {
          singleDate = new Date(filters['date_of_last_conversion.from']);
        }
      }
      if (type === '<' || type === '<=') {
        singleDate = new Date(filters['date_of_last_conversion.to']);
      }
    }
    return singleDate;
  };

  const getTagsInitial = (tagType, returnObject) => {
    const determineName = (tag) => {
      let result = tag;
      if (tagType === 'contactOwners' && ownersSuggestions.length > 0) {
        ownersSuggestions.forEach((ownerSuggestion) => {
          if (tag === ownerSuggestion.id) {
            result = ownerSuggestion.name;
          }
        });
      }
      return result;
    };
    const determineObject = (tag) => {
      let result = { name: determineName(tag) };
      if (tagType === 'contactOwners' && ownersSuggestions.length > 0) {
        ownersSuggestions.forEach((ownerSuggestion) => {
          if (tag === ownerSuggestion.id) {
            result = { name: ownerSuggestion.name, id: ownerSuggestion.id };
          }
        });
      }
      return result;
    };
    const filters = parseUrl();
    const initialTagFilters = returnObject ? {} : [];
    let filtersProspectTags = [];
    if (filters[tagType] && Array.isArray(filters[tagType])) {
      filtersProspectTags = filters[tagType];
    } else if (filters[tagType]) {
      filtersProspectTags = [filters[tagType]];
    }
    filtersProspectTags.forEach((tag) => {
      if (returnObject) {
        initialTagFilters[determineName(tag)] = true;
      } else {
        initialTagFilters.push(determineObject(tag));
      }
    });
    return initialTagFilters;
  };


  const filters = parseUrl();
  const firstConversionDateType = getFilterValueInitial('firstConversionDateType');
  const [firstConversionDateFrom, setFirstConversionDateFrom] = useState(filters?.['date_of_first_conversion.from'] && firstConversionDateType === 'Between' ? new Date(filters['date_of_first_conversion.from']) : null);
  const [firstConversionDateTo, setFirstConversionDateTo] = useState(filters?.['date_of_first_conversion.to'] && firstConversionDateType === 'Between' ? new Date(filters['date_of_first_conversion.to']) : null);
  const [singleFirstConversionDate, setSingleFirstConversionDate] = useState(getSingleFirstConversionDateInitial());
  const [enableFirstConversionDate, setEnableFirstConversionDate] = useState(false);
  const [firstConversionDateValue, setFirstConversionDateValue] = useState({ value: firstConversionDateType, label: firstConversionDateType });
  const lastConversionDateType = getFilterValueInitial('lastConversionDateType');
  const [lastConversionDateFrom, setLastConversionDateFrom] = useState(filters?.['date_of_last_conversion.from'] && lastConversionDateType === 'Between' ? new Date(filters['date_of_last_conversion.from']) : null);
  const [lastConversionDateTo, setLastConversionDateTo] = useState(filters?.['date_of_last_conversion.to'] && lastConversionDateType === 'Between' ? new Date(filters['date_of_last_conversion.to']) : null);
  const [singleLastConversionDate, setSingleLastConversionDate] = useState(getSingleLastConversionDateInitial());
  const [enableLastConversionDate, setEnableLastConversionDate] = useState(false);
  const [lastConversionDateValue, setLastConversionDateValue] = useState({ value: lastConversionDateType, label: lastConversionDateType });
  const [version, setVersion] = useState((new Date()).toString());
  const [ownersFilter, setOwnersFilter] = useState(getTagsInitial('contactOwners', true));
  const [owners, setOwners] = useState(getTagsInitial('contactOwners', false));
  const [blackListFilters, setBlackListFilters] = useState({
    Yes: true,
    No: true,
  });
  const [complianceFilters, setComplianceFilters] = useState({
    'Not Yet Published': true,
    'Under Review': true,
    'Non-Compliant': true,
    'Fix in Progress': true,
    'Non-Response': true,
    Compliant: true,
    Terminated: true,
    'Not Set': true,
  });
  const [searchValue, onSearchChange] = useState('');
  const [type, setType] = useState('contact');

  const getUrlSearch = () => {
    const search = new URLSearchParams(pseudoUrl);
    if (searchValue) {
      search.set('filters.fullSearchName', clearUrl(searchValue));
    } else {
      search.delete('filters.fullSearchName');
    }
    if (blackListFilters && Object.keys(blackListFilters).length > 0) {
      search.delete('filters.inBlackList');
      Object.keys(blackListFilters).forEach((key) => {
        if (!blackListFilters[key]) return false;
        search.append('filters.inBlackList', compareInBlackList[key]);
        return true;
      });
    }
    if (complianceFilters && Object.keys(complianceFilters).length > 0) {
      search.delete('filters.ftc_compliance_status');
      Object.keys(complianceFilters).forEach((key) => {
        if (!complianceFilters[key]) return false;
        search.append('filters.ftc_compliance_status', compareCompliance[key]);
        return true;
      });
    }
    search.delete('filters.contactOwners');
    if (ownersFilter && Object.keys(ownersFilter).length > 0) {
      Object.keys(ownersFilter).forEach((key) => {
        if (!ownersFilter[key]) return false;
        search.append('filters.contactOwners', key);
        return true;
      });
    }

    search.delete('filters.date_of_first_conversion.from');
    search.delete('filters.date_of_first_conversion.to');
    search.delete('firstConversionDateType');
    if (firstConversionDateFrom && firstConversionDateTo && firstConversionDateValue.value === 'Between') {
      if (firstConversionDateFrom < firstConversionDateTo) {
        search.append('filters.date_of_first_conversion.from', firstConversionDateFrom.toISOString());
        search.append('filters.date_of_first_conversion.to', firstConversionDateTo.toISOString());
      } else { // switch positions of interval ends
        search.append('filters.date_of_first_conversion.from', firstConversionDateTo.toISOString());
        search.append('filters.date_of_first_conversion.to', firstConversionDateFrom.toISOString());
      }
    }
    if (singleFirstConversionDate && singleFirstConversionDate !== '') {
      if (firstConversionDateValue.value === '>' || firstConversionDateValue.value === '>=') {
        search.append('filters.date_of_first_conversion.from', singleFirstConversionDate.toISOString());
      }
      if (firstConversionDateValue.value === '<' || firstConversionDateValue.value === '<=') {
        search.append('filters.date_of_first_conversion.to', singleFirstConversionDate.toISOString());
      }
      if (firstConversionDateValue.value === '=') {
        search.append('filters.date_of_first_conversion.from', singleFirstConversionDate.toISOString());
        const nextDay = new Date(singleFirstConversionDate);
        nextDay.setDate(singleFirstConversionDate.getDate() + 1);
        search.append('filters.date_of_first_conversion.to', nextDay.toISOString());
      }
    }
    if (firstConversionDateValue.value && ((firstConversionDateTo && firstConversionDateFrom) || singleFirstConversionDate)) {
      setEnableFirstConversionDate(true);
      search.append('firstConversionDateType', firstConversionDateValue.value);
    }

    search.delete('filters.date_of_last_conversion.from');
    search.delete('filters.date_of_last_conversion.to');
    search.delete('lastConversionDateType');
    if (lastConversionDateFrom && lastConversionDateTo && lastConversionDateValue.value === 'Between') {
      if (lastConversionDateFrom < lastConversionDateTo) {
        search.append('filters.date_of_last_conversion.from', lastConversionDateFrom.toISOString());
        search.append('filters.date_of_last_conversion.to', lastConversionDateTo.toISOString());
      } else { // switch positions of interval ends
        search.append('filters.date_of_last_conversion.from', lastConversionDateTo.toISOString());
        search.append('filters.date_of_last_conversion.to', lastConversionDateFrom.toISOString());
      }
    }
    if (singleLastConversionDate && singleLastConversionDate !== '') {
      if (lastConversionDateValue.value === '>' || lastConversionDateValue.value === '>=') {
        search.append('filters.date_of_last_conversion.from', singleLastConversionDate.toISOString());
      }
      if (lastConversionDateValue.value === '<' || lastConversionDateValue.value === '<=') {
        search.append('filters.date_of_last_conversion.to', singleLastConversionDate.toISOString());
      }
      if (lastConversionDateValue.value === '=') {
        search.append('filters.date_of_last_conversion.from', singleLastConversionDate.toISOString());
        const nextDay = new Date(singleLastConversionDate);
        nextDay.setDate(singleLastConversionDate.getDate() + 1);
        search.append('filters.date_of_last_conversion.to', nextDay.toISOString());
      }
    }
    if (lastConversionDateValue.value && ((lastConversionDateTo && lastConversionDateFrom) || singleLastConversionDate)) {
      setEnableLastConversionDate(true);
      search.append('lastConversionDateType', lastConversionDateValue.value);
    }

    return search;
  };

  const firstUpdate = useRef(true);
  const setUrl = () => {
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
      setForceFirstPage(true);
    }
    setPseudoUrl(`?${search.toString()}`);
  };

  useEffect(() => {
    setUrl();
  }, [
    searchValue,
    ownersFilter,
    blackListFilters,
    complianceFilters,
    enableFirstConversionDate,
    singleFirstConversionDate,
    firstConversionDateTo,
    firstConversionDateFrom,
    enableLastConversionDate,
    singleLastConversionDate,
    lastConversionDateTo,
    lastConversionDateFrom,
  ]);

  useEffect(() => {
    const search = new URLSearchParams(pseudoUrl);
    if (search.has('firstConversionDateType') && search.get('firstConversionDateType') !== firstConversionDateValue.value) {
      setUrl();
    }
  }, [firstConversionDateValue]);

  useEffect(() => {
    const search = new URLSearchParams(pseudoUrl);
    if (search.has('lastConversionDateType') && search.get('lastConversionDateType') !== lastConversionDateValue.value) {
      setUrl();
    }
  }, [lastConversionDateValue]);

  useEffect(() => {
    if (pseudoUrlContacts) {
      const newContactsSearch = new URLSearchParams(pseudoUrl);
      const contactsSearch = new URLSearchParams(pseudoUrlContacts);
      if (!forceFirstPage && contactsSearch.has('page')) {
        newContactsSearch.delete('page');
        newContactsSearch.set('page', contactsSearch.get('page'));
      }
      if (contactsSearch.has('perPage')) {
        newContactsSearch.delete('perPage');
        newContactsSearch.set('perPage', contactsSearch.get('perPage'));
      }
      if (contactsSearch.has('sortBy')) {
        newContactsSearch.delete('sortBy');
        newContactsSearch.set('sortBy', contactsSearch.get('sortBy'));
      }
      if (contactsSearch.has('direction')) {
        newContactsSearch.delete('direction');
        newContactsSearch.set('direction', contactsSearch.get('direction'));
      }
      setPseudoUrlContacts(`?${newContactsSearch.toString()}`);
    } else setPseudoUrlContacts(`?${pseudoUrl}`);

    if (pseudoUrlAgents) {
      const newAgentsSearch = new URLSearchParams(pseudoUrl);
      const agentsSearch = new URLSearchParams(pseudoUrlAgents);
      if (!forceFirstPage && agentsSearch.has('page')) {
        newAgentsSearch.delete('page');
        newAgentsSearch.set('page', agentsSearch.get('page'));
      }
      if (agentsSearch.has('perPage')) {
        newAgentsSearch.delete('perPage');
        newAgentsSearch.set('perPage', agentsSearch.get('perPage'));
      }
      if (agentsSearch.has('sortBy')) {
        newAgentsSearch.delete('sortBy');
        newAgentsSearch.set('sortBy', agentsSearch.get('sortBy'));
      }
      if (agentsSearch.has('direction')) {
        newAgentsSearch.delete('direction');
        newAgentsSearch.set('direction', agentsSearch.get('direction'));
      }
      setPseudoUrlAgents(`?${newAgentsSearch.toString()}`);
    } else setPseudoUrlAgents(`?${pseudoUrl}`);

    setForceFirstPage(false);
  }, [
    pseudoUrl,
  ]);

  if (!enableFirstConversionDate && firstConversionDateValue.value && ((firstConversionDateTo && firstConversionDateFrom) || singleFirstConversionDate)) {
    setEnableFirstConversionDate(true);
  }

  if (!enableLastConversionDate && lastConversionDateValue.value && ((lastConversionDateTo && lastConversionDateFrom) || singleLastConversionDate)) {
    setEnableLastConversionDate(true);
  }

  const fetchOwnersSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/hubil/get-deal-owners-suggestions',
    }).then((response) => {
      if (response.data.success === true) {
        setOwnersSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    fetchOwnersSuggestions();
  }, []);

  const fetchAllContacts = () => {
    if (loadingContacts) {
      return;
    }
    setLoadingContacts(true);
    addNotice({
      message: 'Fetching Contacts, please wait...',
      type: 'success',
    });
    dispatch(fetchContacts(() => { }));
  };

  const afterContactsFetch = () => {
    fetchOwnersSuggestions();
    setVersion((new Date()).toString());
    setLoadingContacts(false);
    addNotice({
      message: 'All Contacts were fetched.',
      type: 'success',
    });
  };

  const contactResource = resources.find(r => r.id === 'HubilContact');
  const contactAction = contactResource.resourceActions.find(r => r.name === 'list');

  const agentResource = resources.find(r => r.id === 'HubilAgent');
  const agentAction = agentResource.resourceActions.find(r => r.name === 'list');

  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Creators', path: '/resources/Influencer/actions/list' },
          { title: 'Contacts', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row>
              <Col md="4">
                <h3>Contacts</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-0" />
          </CardTitle>
          <Row>
            <Col md={12}>
              <Actions
                type={type}
                fetchAllContacts={fetchAllContacts}
                onSearchChange={onSearchChange}
                searchValue={searchValue}
                ownersSuggestions={ownersSuggestions}
                setOwnersFilter={setOwnersFilter}
                setOwners={setOwners}
                owners={owners}
                blackListFilters={blackListFilters}
                setBlackListFilters={setBlackListFilters}
                complianceFilters={complianceFilters}
                setComplianceFilters={setComplianceFilters}
                firstConversionDateValue={firstConversionDateValue}
                setFirstConversionDateValue={setFirstConversionDateValue}
                enableFirstConversionDate={enableFirstConversionDate}
                setEnableFirstConversionDate={setEnableFirstConversionDate}
                firstConversionDateFrom={firstConversionDateFrom}
                setFirstConversionDateFrom={setFirstConversionDateFrom}
                firstConversionDateTo={firstConversionDateTo}
                setFirstConversionDateTo={setFirstConversionDateTo}
                singleFirstConversionDate={singleFirstConversionDate}
                setSingleFirstConversionDate={setSingleFirstConversionDate}
                lastConversionDateValue={lastConversionDateValue}
                setLastConversionDateValue={setLastConversionDateValue}
                enableLastConversionDate={enableLastConversionDate}
                setEnableLastConversionDate={setEnableLastConversionDate}
                lastConversionDateFrom={lastConversionDateFrom}
                setLastConversionDateFrom={setLastConversionDateFrom}
                lastConversionDateTo={lastConversionDateTo}
                setLastConversionDateTo={setLastConversionDateTo}
                singleLastConversionDate={singleLastConversionDate}
                setSingleLastConversionDate={setSingleLastConversionDate}
                loadingContacts={loadingContacts}
                afterContactsFetch={afterContactsFetch}
                pseudoUrl={pseudoUrl}
              />
              <Nav
                className="mt-2"
                tabs
              >
                <NavItem>
                  <NavLink
                    className={classnames({ active: type === 'contact' })}
                    onClick={() => {
                      setType('contact');
                    }}
                  >
                    Contacts
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: type === 'agent' })}
                    onClick={() => {
                      setType('agent');
                    }}
                  >
                    Agents
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={type}>
                <TabPane tabId="contact">
                  {type === 'contact' && contactResource && contactAction ? (
                    <div
                      style={{
                        marginLeft: '-17px',
                        marginRight: '-17px',
                        marginBottom: '-30px',
                      }}
                    >
                      <MatTableList
                        action={contactAction}
                        resource={contactResource}
                        pseudoUrlFiltering
                        pseudoUrl={pseudoUrlContacts}
                        updatePseudoUrl={setPseudoUrlContacts}
                        date={new Date()}
                        UserPropertyType={UserPropertyType}
                        sortBy="createdAt"
                        direction="desc"
                        useFlex
                        isResizable={false}
                        showTitle={false}
                        showFilters={false}
                        noActions
                        useUnflatten
                        customColumns={[
                          {
                            id: 'fullSearchName',
                            Header: () => 'Contact Name',
                            accessor: () => 'fullSearchName',
                            Cell: p => useMemo(() => (
                              <ContactInList
                                record={p.row.original}
                                search={searchValue}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 220,
                          },
                          {
                            id: 'company',
                            Header: () => 'Company',
                            accessor: () => 'company',
                            Cell: p => useMemo(() => (
                              <CompanyInList
                                record={p.row.original}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 160,
                          },
                          {
                            id: 'fullContactOwner',
                            Header: () => 'Contact Owner',
                            accessor: () => 'fullContactOwner',
                            Cell: p => useMemo(() => (
                              <ContactOwnerInList
                                record={p.row.original}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 160,
                          },
                          {
                            id: 'email',
                            Header: () => 'Email',
                            accessor: () => 'email',
                            Cell: p => useMemo(() => (
                              <EmailInList
                                record={p.row.original}
                                search={searchValue}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 240,
                          },
                          {
                            id: 'inBlackList',
                            Header: () => 'Blacklist?',
                            accessor: () => 'inBlackList',
                            Cell: p => useMemo(() => (
                              <BlacklistedInList
                                record={p.row.original}
                                updateRecordFunction={p.updateRecordFunction}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 150,
                          },
                          {
                            id: 'ftc_compliance_status',
                            Header: () => 'Compliance Status',
                            accessor: () => 'ftc_compliance_status',
                            Cell: p => useMemo(() => (
                              <ComplianceStatusInList
                                record={p.row.original}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 170,
                          },
                          {
                            id: 'date_of_first_conversion',
                            Header: () => 'First Conversion Date',
                            accessor: () => 'date_of_first_conversion',
                            Cell: p => useMemo(() => (
                              <FirstConversionDateInList
                                record={p.row.original}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 190,
                          },
                          {
                            id: 'date_of_last_conversion',
                            Header: () => 'Last Conversion Date',
                            accessor: () => 'date_of_last_conversion',
                            Cell: p => useMemo(() => (
                              <LastConversionDateInList
                                record={p.row.original}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 190,
                          },
                          {
                            id: 'contextMenu',
                            Header: () => '',
                            accessor: () => null,
                            Cell: p => useMemo(() => (
                              <ContextMenuInList
                                record={p.row.original}
                                adminUsers={ownersSuggestions}
                              />
                            ), [p.row.original.id]),
                            disableSortBy: true,
                            className: 'align-self-center',
                            width: 30,
                            maxWidth: 30,
                          },
                        ]}
                        version={version}
                        hideToolbar
                        v={2}
                      />
                    </div>
                  ) : (
                    <span>This is not yet accessible for you. Ask your manager give you an access to this data.</span>
                  )}
                </TabPane>
                <TabPane tabId="agent">
                  {type === 'agent' && agentResource && agentAction ? (
                    <div
                      style={{
                        marginLeft: '-17px',
                        marginRight: '-17px',
                        marginBottom: '-30px',
                      }}
                    >
                      <MatTableList
                        action={agentAction}
                        resource={agentResource}
                        pseudoUrlFiltering
                        pseudoUrl={pseudoUrlAgents}
                        updatePseudoUrl={setPseudoUrlAgents}
                        date={new Date()}
                        UserPropertyType={UserPropertyType}
                        sortBy="createdAt"
                        direction="desc"
                        useFlex
                        isResizable={false}
                        showTitle={false}
                        showFilters={false}
                        noActions
                        useUnflatten
                        customColumns={[
                          {
                            id: 'fullSearchName',
                            Header: () => 'Contact Name',
                            accessor: () => 'fullSearchName',
                            Cell: p => useMemo(() => (
                              <ContactInList
                                record={p.row.original}
                                search={searchValue}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 200,
                          },
                          {
                            id: 'company',
                            Header: () => 'Company',
                            accessor: () => 'company',
                            Cell: p => useMemo(() => (
                              <CompanyInList
                                record={p.row.original}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 160,
                          },
                          {
                            id: 'fullContactOwner',
                            Header: () => 'Contact Owner',
                            accessor: () => 'fullContactOwner',
                            Cell: p => useMemo(() => (
                              <ContactOwnerInList
                                record={p.row.original}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 160,
                          },
                          {
                            id: 'email',
                            Header: () => 'Email',
                            accessor: () => 'email',
                            Cell: p => useMemo(() => (
                              <EmailInList
                                record={p.row.original}
                                search={searchValue}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 260,
                          },
                          {
                            id: 'inBlackList',
                            Header: () => 'Blacklist?',
                            accessor: () => 'inBlackList',
                            Cell: p => useMemo(() => (
                              <BlacklistedInList
                                record={p.row.original}
                                updateRecordFunction={p.updateRecordFunction}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 150,
                          },
                          {
                            id: 'ftc_compliance_status',
                            Header: () => 'Compliance Status',
                            accessor: () => 'ftc_compliance_status',
                            Cell: p => useMemo(() => (
                              <ComplianceStatusInList
                                record={p.row.original}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 190,
                          },
                          {
                            id: 'date_of_first_conversion',
                            Header: () => 'First Conversion Date',
                            accessor: () => 'date_of_first_conversion',
                            Cell: p => useMemo(() => (
                              <FirstConversionDateInList
                                record={p.row.original}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 190,
                          },
                          {
                            id: 'date_of_last_conversion',
                            Header: () => 'Last Conversion Date',
                            accessor: () => 'date_of_last_conversion',
                            Cell: p => useMemo(() => (
                              <LastConversionDateInList
                                record={p.row.original}
                              />
                            ), [p.row.original.id]),
                            className: 'align-self-center',
                            width: 190,
                          },
                          {
                            id: 'contextMenu',
                            Header: () => '',
                            accessor: () => null,
                            Cell: p => useMemo(() => (
                              <ContextMenuInList
                                record={p.row.original}
                                adminUsers={ownersSuggestions}
                              />
                            ), [p.row.original.id]),
                            disableSortBy: true,
                            className: 'align-self-center',
                            width: 30,
                            maxWidth: 30,
                          },
                        ]}
                        version={version}
                        hideToolbar
                        v={2}
                      />
                    </div>
                  ) : (
                    <span>This is not yet accessible for you. Ask your manager give you an access to this data.</span>
                  )}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

HubilContactsListContainer.propTypes = {
  addNotice: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(withNotice(withRouter(HubilContactsListContainer)));
