/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import TrashCanIcon from 'mdi-react/TrashCanIcon';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import withNotice from '../../../../../../App/store/with-notice';

const BrandSafetySearchTermsListItem = ({
  deleteTerm,
  updateTerm,
  indexInList,
  brandSafetySearchTerm,
}) => {
  const [localBrandSafetySearchTerm, setLocalBrandSafetySearchTerm] = useState('');

  useEffect(() => {
    setLocalBrandSafetySearchTerm(brandSafetySearchTerm);
  }, [brandSafetySearchTerm]);

  return (
    <FormGroup>
      <InputGroup size="md">
        <Input
          id={`term${indexInList}`}
          name={`term${indexInList}`}
          type="text"
          bsSize="md"
          onChange={event => updateTerm(event?.target?.value, indexInList)}
          value={localBrandSafetySearchTerm}
        />
        <InputGroupAddon addonType="append">
          <Tooltip title="Remove this Search Term from the List">
            <Button
              style={{
                maxHeight: '38px',
                border: '1px solid #70bbfd',
                borderRadius: '0 0.25rem 0.25rem 0',
                paddingRight: '0',
              }}
              color="danger"
              className="form__form-group-button mb-0"
              onClick={deleteTerm}
            ><TrashCanIcon />
            </Button>
          </Tooltip>
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  );
};

BrandSafetySearchTermsListItem.propTypes = {
  deleteTerm: PropTypes.func.isRequired,
  updateTerm: PropTypes.func.isRequired,
  indexInList: PropTypes.number.isRequired,
  brandSafetySearchTerm: PropTypes.string.isRequired,
};

export default connect()(withNotice(BrandSafetySearchTermsListItem));
