/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import ApiClient from 'ApiClient';
import Breadcrumbs from '../../shared/components/BreadCrumbsResource';
import ActionHeader from '../../shared/components/components/app/action-header';
import WrapperBox from '../../shared/components/components/ui/wrapper-box';
import Notice from '../../shared/components/components/app/notice';

import BaseAction from '../../shared/components/components/app/base-action-component';
import { NoResourceError, NoActionError, ErrorMessageBox } from '../../shared/components/components/ui/error404';
import NoticeWrapper from '../../shared/components/components/routes/styled/notice-wrapper.styled';
import Loader from '../../shared/components/components/ui/loader';
import shouldActionReFetchData from '../../shared/components/components/routes/utils/should-action-re-fetch-data';
import withNotice from '../App/store/with-notice';
import getBulkActionsFromRecords from
  '../../shared/components/components/app/records-table/utils/get-bulk-actions-from-records';


const NO_RECORDS_ERROR = {
  message: 'There was an error fetching records, Check out console to see more information.',
  type: 'error',
};


class BulkAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: undefined,
      isLoading: true,
      tag: undefined,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.fetchRecords(match.params);
  }

  shouldComponentUpdate(newProps) {
    const { match } = this.props;
    if (shouldActionReFetchData(match.params, newProps.match.params)) {
      this.fetchRecords(newProps.match.params);
      return false;
    }
    return true;
  }

  setTag(tagName) {
    this.setState({ tag: tagName });
  }

  fetchRecords({ resourceId, actionName }) {
    const { addNotice, location } = this.props;
    const recordIdsString = new URLSearchParams(location.search).get('recordIds');
    const recordIds = recordIdsString ? recordIdsString.split(',') : [];

    const api = new ApiClient();
    this.setState({
      isLoading: true,
      records: undefined,
    });
    return api.bulkAction({
      resourceId, recordIds, actionName,
    }).then((response) => {
      this.setState({ isLoading: false, records: response.data.records });
    }).catch((error) => {
      addNotice(NO_RECORDS_ERROR);
      throw error;
    });
  }

  render() {
    const { resources, match } = this.props;
    const { resourceId, actionName } = match.params;
    const { isLoading, tag, records } = this.state;

    const resource = resources.find(r => r.id === resourceId);

    if (!resource) {
      return (<NoResourceError resourceId={resourceId} />);
    }

    if (!records && !isLoading) {
      return (
        <ErrorMessageBox title="No records">
          <p>You have not selected any records</p>
        </ErrorMessageBox>
      );
    }

    const action = getBulkActionsFromRecords(records || []).find(r => r.name === actionName);

    if (!action && !isLoading) {
      return (<NoActionError resourceId={resourceId} actionName={actionName} />);
    }

    return (
      <div>
        <NoticeWrapper>
          <Notice />
        </NoticeWrapper>
        <WrapperBox>
          <Breadcrumbs resource={resource} actionName={actionName} isBackButton />
          <ActionHeader
            resource={resource}
            action={action}
            tag={tag}
          />
          {isLoading
            ? <Loader />
            : (
              <div>
                <BaseAction
                  action={action}
                  resource={resource}
                  records={records}
                  setTag={this.setTag}
                />
              </div>
            )
          }
        </WrapperBox>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resources: state.resources,
});

export default withNotice(connect(mapStateToProps)(BulkAction));
