/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { formatIntNumber, getFormattedDateNoZeroes } from '../../../../shared/helpers/WVFormatter';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import VideoInList from './VideoInList';
import CreatorInList from './CreatorInList';

const columnTransform= (filtersForCreatorUrls, filtersForVideoUrls) => [
  {
    Header: 'Video Title',
    id: 'title',
    disableSortBy: true,
    accessor: record => (record.title || ''),
    Cell: value => (
      <VideoInList
        record={value.row.original}
        filtersForUrls={filtersForVideoUrls}
      />),
    width: 350,
  },
  {
    Header: 'Creator',
    id: 'name',
    disableSortBy: true,
    accessor: record => (record.name || ''),
    Cell: value => (
      <CreatorInList
        record={value.row.original}
        filtersForUrls={filtersForCreatorUrls}
      />),
    width: 350,
  },
  {
    Header: 'Publish Date',
    id: 'publishDate',
    accessor: record => (record.publishDate || 0),
    Cell: value => getFormattedDateNoZeroes(value.row.original.publishDate),
    width: 150,
  },
  {
    Header: 'Total Views',
    accessor: record => (record.totalViews || 0),
    id: 'totalViews',
    Cell: value => formatIntNumber(value.row.original.totalViews || 0),
    width: 120,
  },
  {
    Header: 'Total Comments',
    id: 'count',
    accessor: record => (record.count || 0),
    Cell: value => formatIntNumber(value.row.original.count || 0),
    width: 140,
  },
  {
    Header: 'Negative Comments',
    id: 'negativeCount',
    accessor: record => (record.negativeCount || 0),
    Cell: value => formatIntNumber(value.row.original.negativeCount || 0),
    width: 160,
  },
  {
    Header: 'Negative Percentage',
    id: 'negativePercentage',
    accessor: record => (record.negativePercentage || 0),
    Cell: value => `${formatIntNumber(value.row.original.negativePercentage || 0, 3)}%`,
    width: 170,
  },
  {
    Header: 'Positive Comments',
    id: 'positiveCount',
    accessor: record => (record.positiveCount || 0),
    Cell: value => formatIntNumber(value.row.original.positiveCount || 0),
    width: 160,
  },
  {
    Header: 'Positive Percentage',
    id: 'positivePercentage',
    accessor: record => (record.positivePercentage || 0),
    Cell: value => `${formatIntNumber(value.row.original.positivePercentage || 0, 3)}%`,
    width: 170,
  },
];

const VideosTable = ({
  data,
  sortBy,
  filtersForCreatorUrls,
  filtersForVideoUrls,
}) => {
  const [version, setVersion] = useState((new Date()));

  useEffect(() => {
    setVersion((new Date()));
  }, [data]);

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    useFlex: true,
    isSortable: true,
    withPagination: false,
    withSearchEngine: false,
    sortBy,
    direction: 'desc',
  };

  return (
    <Row>
      <Col>
        {data.length ? (
          <ReactTableBase
            key={version}
            columns={columnTransform(filtersForCreatorUrls, filtersForVideoUrls)}
            data={data}
            tableConfig={tableConfig}
            disableSortRemove
          />
        ) : (
          <span className="mt-2">No data to show</span>
        )}
      </Col>
    </Row>
  );
}

VideosTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  sortBy: PropTypes.string.isRequired,
  filtersForCreatorUrls: PropTypes.string,
  filtersForVideoUrls: PropTypes.string,
};

VideosTable.defaultProps = {
  data: [],
  filtersForCreatorUrls: '',
  filtersForVideoUrls: '',
};

export default VideosTable;
