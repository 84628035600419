import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

const Actions = React.memo(({
  onSearchChange,
  searchValue,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
  };

  return (
    <div>
      <Row className="mt-2">
        <Col md={8} lg={6} className="mt-2">
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Filenames"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col md={4} lg={6} className="mt-2">
          <Row>
            <Col>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    maxWidth: '160px',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue);
Actions.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default Actions;
