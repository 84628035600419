import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, Button, Label, FormGroup, Input, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import Select from 'react-select';
import moment from 'moment';
import momentTz from 'moment-timezone';
import DatePicker from 'react-datepicker';
import ProgressBar from '../../../../../../../shared/helpers/ProgressBarWithObject';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import RadioButton from '../../../../../../../shared/components/RadioButton';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import { getConstant } from '../../../../../../../shared/helpers/WVConstants';
import { ProgramOptions } from '../../../../../../../shared/helpers/models/Prospect';
import { setCookie } from '../../../../../../../shared/helpers/WVUtilities';
import Alert from '../../../../../../../shared/easydev2/shared/components/Alert';

const prospectTypes = {
  standard: 'standard',
  internal: 'internal',
};

const AddToProspectsModal = ({
  modal,
  title,
  setModalVisible,
  addToProspects,
  getSearchDataToSave,
  loading,
  addToProspectsResults,
  selectedItemsForProspect,
  numberOfFound,
  analysts,
  adminUsers,
  currentAdminUser,
  errorMessages,
  setErrorMessages,
}) => {
  const maxLimitSave = Number(getConstant('prospect', 'uploadProspectsLimit') || 0);
  const [uploading, setUploading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({
    value: null,
    label: '-- Not Assigned --',
  });
  const [uploadVariant, setUploadVariant] = useState('now');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [overwriteAlreadyInHs, setOverwriteAlreadyInHs] = useState(false);
  const [allowDuplicates, setAllowDuplicates] = useState(true);
  const [includeAll, setIncludeAll] = useState('notIncludeAll');
  const [identity, setIdentity] = useState('');
  const [logs, setLogs] = useState({});
  const [errors, setErrors] = useState({ from: '', to: '' });
  const [queue, setQueue] = useState(false);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [name, setName] = useState(`Prospect Group ${WVFormatter.formatToEST(momentTz()).format('M/D/YY h:mma')} by ${currentAdminUser.firstName} ${currentAdminUser.lastName}`);
  const [description, setDescription] = useState('');
  const [submittedBy, setSubmittedBy] = useState({
    value: currentAdminUser ? currentAdminUser._id : null,
    label: currentAdminUser ? `${currentAdminUser.firstName} ${currentAdminUser.lastName}` : '-- Not Assigned --',
  });
  const [analyst, setAnalyst] = useState({
    value: null,
    label: '-- Not Assigned --',
  });
  const [program, setProgram] = useState({
    value: '',
    label: '-- Not Assigned --',
  });
  const [prospectType, setProspectType] = useState(prospectTypes.standard);
  const [prospectOwner, setProspectOwner] = useState(null);
  const reactTags = React.createRef();

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setTags(tagsNew);
  };

  const fetchTags = () => {
    axios({
      method: 'get',
      url: '/api/portal/research/get-prospects-tags',
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const loadBrandsOptions = async (inputValue) => {
    const response = await axios({
      method: 'get',
      url: `/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`,
    });
    if (response.data.success) {
      const res = [
        { value: null, label: '-- Not Assigned --' },
      ];
      response.data.brands.forEach((record) => {
        res.push({
          value: record._id,
          label: record.companyName,
          record,
        });
      });
      return res;
    }
    return [];
  };

  const handleChangeBrand = (selected) => {
    setSelectedBrand({
      value: selected.value,
      label: selected.label,
    });
  };

  const handleChangeAnalyst = (selected) => {
    setAnalyst(selected);
  };

  const handleChangeProgram = (selected) => {
    setProgram(selected);
  };

  const handleChangeType = (e) => {
    setProspectType(e);
    let newName = name;
    if (e === prospectTypes.internal) {
      newName = name.replace('Prospect Group', 'Internal Prospect');
    } else {
      newName = name.replace('Internal Prospect', 'Prospect Group');
    }
    setName(newName);
    setProspectOwner(null);
  };

  const downloadReport = () => {
    const queuedIdentity = addToProspectsResults?.identity ? addToProspectsResults.identity : logs.queuingIdentity;
    document.location = `/api/portal/prospects/get-csv-uploads?identity=${queuedIdentity}`;
  };
  const validateRange = (val, form) => {
    if (form === 'to' && Number(from || 0) > Number(val || 0)) {
      setErrors({ ...errors, to: 'Start must be less then End' });
      return false;
    }
    if (val && Number(val || 0) % 25 !== 0) {
      setErrors({ ...errors, [form]: 'Number must be divisible by 25' });
      return false;
    }
    if (val && Number(val || 0) > numberOfFound) {
      setErrors({ ...errors, [form]: `Number must be less then ${WVFormatter.formatIntNumber(numberOfFound)}` });
      return false;
    }
    if (Number(to || 0) % 25 !== 0 && to !== numberOfFound) {
      setErrors({ ...errors, to: 'Number must be divisible by 25' });
      return false;
    }
    if (Number(from || 0) > numberOfFound) {
      setErrors({ ...errors, from: `Number must be less then ${WVFormatter.formatIntNumber(numberOfFound)}` });
      return false;
    }
    if (Number(from || 0) % 25 !== 0) {
      setErrors({ ...errors, from: 'Number must be divisible by 25' });
      return false;
    }
    if (Number(from || 0) > Number(to || 0)) {
      setErrors({ ...errors, to: 'Start must be less then End' });
      return false;
    }
    setErrors({ from: '', to: '' });
    return true;
  };

  const startAdd = () => {
    setUploading(true);
    if (includeAll === 'custom' && !validateRange()) {
      setUploading(false);
      return false;
    }
    let nameNew = name;
    if (nameNew === '') {
      nameNew = `Started on ${WVFormatter.formatToEST(momentTz()).format('M/D/YY h:mma')} by ${currentAdminUser.firstName} ${currentAdminUser.lastName}`;
    }
    const savedSearchData = getSearchDataToSave(nameNew, 'prospectUploads');
    addToProspects(
      savedSearchData,
      selectedBrand.value, tags, selectedItemsForProspect, overwriteAlreadyInHs, false, false, allowDuplicates, includeAll, true, analyst.value,
      program.value, nameNew, description, { from, to }, prospectType, prospectOwner?.value || null, (uploadVariant === 'schedule' ? selectedDate : null),
      submittedBy.value,
    );
    return true;
  };

  useEffect(() => {
    setCookie('addProspectsProgressBarIdentity', name, 0.05);
    setIdentity(name);
  }, []);

  useEffect(() => {
    if (Number(numberOfFound || 0) > maxLimitSave) {
      setQueue(true);
    } else {
      setQueue(false);
    }
  }, [numberOfFound]);
  useEffect(() => {
    if ((includeAll === 'includeAll' && Number(numberOfFound || 0) > maxLimitSave) || (includeAll === 'custom' && (Number(to || 0) - Number(from || 0)) > maxLimitSave)) {
      setQueue(true);
    } else {
      setQueue(false);
    }
  }, [includeAll, to, from]);

  useEffect(fetchTags, []);

  useEffect(() => {
    setLogs({});
    setTags([]);
    setIncludeAll('notIncludeAll');
    setAnalyst({ value: null, label: '-- Not Assigned --' });
    setSelectedBrand({ value: null, label: '-- Not Assigned --' });
    setProgram({ value: '', label: '-- Not Assigned --' });
    setProspectOwner(null);
    setSubmittedBy({
      value: currentAdminUser ? currentAdminUser._id : null,
      label: currentAdminUser ? `${currentAdminUser.firstName} ${currentAdminUser.lastName}` : '-- Not Assigned --',
    });
    setProspectType(prospectTypes.standard);
    if (modal) {
      setUploading(false);
    }
  }, [modal]);
  useEffect(() => {
    setTo(selectedItemsForProspect.length);
  }, [selectedItemsForProspect]);


  return (
    <Modal
      isOpen={modal}
      toggle={() => { setModalVisible(false); setErrorMessages([]); }}
    >
      <ModalHeader style={{ padding: 0, paddingBottom: '28px', borderBottom: 'none' }} toggle={() => { setModalVisible(false); setErrorMessages([]); }} tag="h4">
        <b>{title}</b>
      </ModalHeader>
      {!loading && !uploading && (
        <div className="modal__body finance-form">
          {selectedItemsForProspect.length === 1 && (
            <div className="mb-3">
              <div>Select the Prospect type you wish to add</div>
              <Row className="theme-light radio-in-prospects">
                <Col md={6} style={{ paddingLeft: '50px' }}>
                  <RadioButton
                    name="action"
                    label={`${'\u00A0'} Standard Prospect`}
                    value={prospectType}
                    radioValue={prospectTypes.standard}
                    onChange={handleChangeType}
                  />
                </Col>
                <Col md={6} style={{ paddingLeft: '50px' }}>
                  <RadioButton
                    name="action"
                    label={`${'\u00A0'} Internal Prospect`}
                    value={prospectType}
                    radioValue={prospectTypes.internal}
                    onChange={handleChangeType}
                  />
                </Col>
              </Row>
            </div>
          )}
          <div>{`You have selected ${selectedItemsForProspect.length} result${selectedItemsForProspect.length !== 1 ? 's' : ''} to add to the list of Prospects`}</div>
          {selectedItemsForProspect.length !== 1 && (
            <div className="theme-light radio-in-prospects">
              <div>
                <RadioButton
                  name="action"
                  label={`${'\u00A0'} Add the ${WVFormatter.formatIntNumber(selectedItemsForProspect.length)} selected result${selectedItemsForProspect.length !== 1 ? 's' : ''}?`}
                  value={includeAll}
                  radioValue="notIncludeAll"
                  onChange={(e) => { setIncludeAll(e); }}
                  onBlur
                />
              </div>
              <div>
                <RadioButton
                  name="action"
                  label={`${'\u00A0'} Add all ${WVFormatter.formatIntNumber(numberOfFound)} result${selectedItemsForProspect.length !== 1 ? 's' : ''}?`}
                  value={includeAll}
                  radioValue="includeAll"
                  onChange={(e) => { setIncludeAll(e); }}
                />
              </div>
              <div>
                <RadioButton
                  name="action"
                  label="&nbsp;&nbsp;Add a custom range of results?"
                  value={includeAll}
                  radioValue="custom"
                  onChange={(e) => { setIncludeAll(e); }}
                />
              </div>
              {includeAll === 'custom' ? (
                <div className="" style={{ paddingLeft: '40px' }}>
                  <Row>
                    <Col className="d-flex">
                      <div className="pr-2 pt-1">Start&nbsp;Result</div>
                      <div>
                        <Input
                          type="text"
                          id="from"
                          name="from"
                          value={from}
                          onChange={(e) => { setFrom(e.target.value); }}
                          onBlur={e => validateRange(e.target.value, 'from')}
                        />
                        {errors.from ? (<small className="text-danger text-sm">{errors.from}</small>) : null}
                      </div>
                    </Col>
                    <Col className="d-flex">
                      <div className="pr-2 pt-1">End&nbsp;Result</div>
                      <div>
                        <Input
                          type="text"
                          id="to"
                          name="to"
                          value={to}
                          onChange={(e) => { setTo(e.target.value); }}
                          onBlur={e => validateRange(e.target.value, 'to')}
                        />
                        {errors.to ? (<small className="text-danger text-sm">{errors.to}</small>) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          )}
          {(includeAll === 'includeAll' && Number(numberOfFound || 0) > maxLimitSave) || (includeAll === 'custom' && (Number(to || 0) - Number(from || 0)) > maxLimitSave) ? (
            <Alert color="light" className="w-100 mt-3">
              <p className="py-2" style={{ color: '#212529' }}>
                The Upload process will be queued since the number of results being added is larger than {maxLimitSave}.{' '}
                {/* eslint-disable-next-line max-len */}
                You will be notified via a Slack message when the upload process has completed. You can monitor the progress in the <a href="/research/upload-queue?customFilters.uploadProspects=true" target="_blank">Prospect Uploads Dashboard</a>.
              </p>
            </Alert>) : null}
          <div className="theme-light radio-in-prospects">
            Schedule Upload Time
            <div className="mb-2">
              <RadioButton
                name="schedule"
                label="&nbsp;Upload Immediately"
                value={uploadVariant}
                radioValue="now"
                className=" mr-2"
                onChange={(e) => { setUploadVariant(e); }}
              />
              <RadioButton
                name="schedule"
                label={(
                  <>&nbsp;Schedule Upload {uploadVariant === 'schedule' ? (
                    <div style={{
                      maxWidth: '200px', display: 'inline-block', maxHeight: '26px', marginTop: '-5px',
                    }}
                    ><DatePicker
                      selected={selectedDate}
                      timeFormat="HH:mm"
                      showTimeInput
                      selectsStart
                      minDate={new Date()}
                      onChange={date => setSelectedDate(date)}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      // placeholderText={'Select Datetime'}
                      className="form-control form-control-sm"
                      dropDownMode="select"
                    />
                    </div>) : null}
                  </>)}
                value={uploadVariant}
                radioValue="schedule"
                className=" mr-2"
                onChange={(e) => { setUploadVariant(e); }}
              />
            </div>
          </div>
          {prospectType === prospectTypes.internal && (
            <FormGroup>
              <Label>Prospect Owner</Label>
              <Select
                value={prospectOwner}
                options={analysts}
                onChange={(selected) => { setProspectOwner(selected); }}
                placeholder=""
                menuPlacement="auto"
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              id="name_prospect_group"
              name="name_prospect_group"
              value={name}
              onChange={(e) => { setName(e.target.value); }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Input
              type="textarea"
              id="description_prospect_group"
              name="description_prospect_group"
              value={description}
              onChange={(e) => { setDescription(e.target.value); }}
            />
          </FormGroup>
          <Row style={{ marginBottom: '1rem' }}>
            <Col md={6}>
              <Label>Brand</Label>
              <SelectAsync
                cacheOptions
                id="brandSelectBox"
                value={selectedBrand}
                defaultOptions
                loadOptions={loadBrandsOptions}
                onChange={handleChangeBrand}
                placeholder=""
              />
            </Col>
            <Col md={6}>
              <Label>Program</Label>
              <Select
                value={program}
                id="programSelectBox"
                options={ProgramOptions}
                onChange={handleChangeProgram}
                placeholder=""
                menuPlacement="auto"
              />
              <div className="small">Optional (can be specified later)</div>
            </Col>
          </Row>
          <Row style={{ marginBottom: '1rem' }}>
            <Col md={6}>
              <Label>Analyst</Label>
              <Select
                value={analyst}
                id="analystSelectBox"
                options={analysts}
                onChange={handleChangeAnalyst}
                placeholder=""
                menuPlacement="auto"
              />
              <div className="small">Optional (can be specified later)</div>
            </Col>
            <Col md={6}>
              <Label>Submitted By</Label>
              <Select
                value={submittedBy}
                id="submittedBySelectBox"
                options={adminUsers}
                onChange={(selected) => { setSubmittedBy(selected); }}
                placeholder=""
                menuPlacement="auto"
              />
              <div className="small">Optional (can be specified later)</div>
            </Col>
          </Row>
          <FormGroup>
            <Label>Tags</Label>
            <ReactTags
              ref={reactTags}
              tags={tags}
              suggestions={tagsSuggestions}
              onDelete={onDeleteTag}
              onAddition={onAdditionTag}
              delimiters={['Enter', ',']}
              allowNew
              placeholderText="Tags"
              addOnBlur
            />
            <div className="small">Optional (can be specified later)</div>
          </FormGroup>
          <FormGroup>
            <Label>Include</Label>
            <div className="theme-light checkbox-in-prospects">
              <CheckBoxField
                name="allowDuplicates"
                label="Allow duplicate Creator prospects when Target Brand is unique"
                value={allowDuplicates}
                onChange={(e) => { setAllowDuplicates(e ? e.target.checked : true); }}
              />
              <CheckBoxField
                name="overwriteAlreadyInHs"
                label="Allow Creators who exist in HubSpot (full or partial match)"
                value={overwriteAlreadyInHs}
                onChange={(e) => { setOverwriteAlreadyInHs(e ? e.target.checked : false); }}
              />
              {/* <CheckBoxField */}
              {/*  name="overwritePossiblyInHs" */}
              {/*  label="Creators that possibly exist in Hubspot (overwrite)" */}
              {/*  value={overwritePossiblyInHs} */}
              {/*  onChange={(e) => { setOverwritePossiblyInHs(e ? e.target.checked : false); }} */}
              {/* /> */}
              {/* <CheckBoxField */}
              {/*  name="overwriteAlreadyProspect" */}
              {/*  label="Creators who are already a Prospect (overwrite)" */}
              {/*  value={overwriteAlreadyProspect} */}
              {/*  onChange={(e) => { setOverwriteAlreadyProspect(e ? e.target.checked : false); }} */}
              {/* /> */}
            </div>
          </FormGroup>
          <div style={{ textAlign: 'center' }}>
            <Button style={{ marginBottom: 0 }} className="btn-sm" color="primary" onClick={startAdd}>Add</Button>
            &nbsp;&nbsp;&nbsp;
            <Button style={{ marginBottom: 0 }} className="modal_cancel btn-sm" color="secondary" onClick={() => { setModalVisible(false); setErrorMessages([]); }}>Cancel</Button>
          </div>
        </div>
      )}
      {uploading && uploadVariant !== 'schedule' && (
        <div className="modal__body finance-form">
          {loading && (
            <h3 className="mb-2">Uploading...</h3>
          )}
          {/* loading ?
            (<h3 className="mb-2">Uploading...</h3>) :
            (<h3 className="mb-2">Results:</h3>)
          */}
          <div>
            {queue ? (
              <Alert color="light" className="w-100">
                <p className="py-2" style={{ color: '#212529' }}>
                  The upload has been successfully scheduled and will begin shortly. You can monitor the progress in the <a href="/research/upload-queue?customFilters.uploadProspects=true" target="_blank">Prospect Uploads Dashboard</a>.
                </p>
              </Alert>
            ) : null}
            {errorMessages && errorMessages.length > 0 && (
              <Alert color="danger" className="w-100">
                <div className="py-2">
                  {errorMessages?.map((msg, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <p key={i}>{msg}</p>
                  ))}
                </div>
              </Alert>
            )}
          </div>
          {!queue ? (<ProgressBar
            afterFunction={() => true}
            topic="uploadChannelsToProspects"
            isShow
            identity={identity}
            receiveResults={res => setLogs(res)}
          />) : null}
          {/* <ProgressBar
            topic="uploadChannelsToProspects"
            showAfterEnd
          /> */}
          {!(queue) ? (
            <div>
              {/* eslint-disable-next-line max-len */}
              <h4>Prospects successfully processed: {loading ? logs?.totalUploaded || 0 : addToProspectsResults?.successfullUploads.length + addToProspectsResults?.excludedHubspot.length + addToProspectsResults?.excludedProspect.length + addToProspectsResults?.excludedBlacklist.length + addToProspectsResults?.errorUploads.length || 0}</h4>
              <h4>Prospects successfully uploaded: {loading ? logs?.fullUploaded || 0 : addToProspectsResults?.successfullUploads.length || 0}</h4>
              <h4>Duplicates: {loading ? logs?.excludedProspect || 0 : addToProspectsResults?.excludedProspect.length || 0}</h4>
              <h4>In Blacklist: {loading ? logs?.excludedBlacklist || 0 : addToProspectsResults?.excludedBlacklist.length || 0}</h4>
              <h4>Prospects not uploaded (errors occurred): {loading ? logs?.errorUploads?.length || 0 : addToProspectsResults?.errorUploads.length || 0}</h4>
              {loading && (
                <div className="text-center mt-3">
                  <a href="/research/upload-queue?customFilters.uploadProspects=true" target="_blank" className="ml-2">
                    <Button style={{ marginBottom: 0, width: '166px' }} className="modal_cancel btn-sm" color="primary">View Uploads</Button>
                  </a>
                </div>
              )}
              {!loading && (
                <div className="text-center mt-3">
                  <a href="/research/upload-queue?customFilters.uploadProspects=true" target="_blank" className="ml-2">
                    <Button style={{ marginBottom: 0, width: '166px' }} className="modal_cancel btn-sm" color="primary">View Uploads</Button>
                  </a>
                  <Button style={{ marginBottom: 0 }} className="btn btn-sm ml-2" color="primary" onClick={downloadReport}>Download Results</Button>&nbsp;&nbsp;
                  <Button style={{ marginBottom: 0, width: '166px' }} className="modal_cancel btn-sm" color="secondary" onClick={() => { setModalVisible(false); setErrorMessages([]); }}>Close</Button>
                </div>
              )}
            </div>
          ) : null}
          {!loading && queue ? (
            <div className="text-right mt-3">
              <Button style={{ marginBottom: 0 }} className="modal_cancel btn-sm" color="secondary" onClick={() => { setModalVisible(false); setErrorMessages([]); }}>Close</Button>
            </div>
          ) : null}
        </div>
      )}
      {uploading && uploadVariant === 'schedule' && (
        <div className="modal__body finance-form">
          <Alert color="light" className="w-100">
            <p className="py-2" style={{ color: '#212529' }}>
              The upload has been successfully schedule to run at {moment(selectedDate).format('M/D/YY')} and {moment(selectedDate).format('h:mma')}
            </p>
          </Alert>
          <div className="text-center mt-3">
            <a href="/research/upload-queue?customFilters.uploadProspects=true" target="_blank" className="ml-2">
              <Button style={{ marginBottom: 0, width: '166px' }} className="modal_cancel btn-sm" color="primary">View Uploads</Button>
            </a>
            <Button style={{ marginBottom: 0, width: '166px' }} className="modal_cancel btn-sm" color="secondary" onClick={() => { setModalVisible(false); setErrorMessages([]); }}>Close</Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

AddToProspectsModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  addToProspects: PropTypes.func.isRequired,
  getSearchDataToSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  addToProspectsResults: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedItemsForProspect: PropTypes.arrayOf(PropTypes.any).isRequired,
  numberOfFound: PropTypes.number.isRequired,
  analysts: PropTypes.arrayOf(PropTypes.any).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentAdminUser: PropTypes.objectOf(PropTypes.any).isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  setErrorMessages: PropTypes.func.isRequired,
};

AddToProspectsModal.defaultProps = {
};

const mapStateToProps = state => ({
  adminUsers: state.ResearchReducer.adminUsers,
});

export default connect(mapStateToProps)(AddToProspectsModal);
