exports.agents = ({ agents }) => agents;
exports.agencyName = ({ agents }) => agents.map(r => r.agency?.agencyName).filter((r, i, a) => r && a.indexOf(r) === i).join(', ');

exports.showField = ({ key, dealType }) => {
  const map = {
    CPA: ['CPA/Ambassador', 'Hybrid'].includes(dealType),
    Compensation: ['Upfront Only', 'Upfront CPM', 'Hybrid', 'Media License', 'Bonus'].includes(dealType),
    Channel: ['Upfront Only', 'Upfront CPM'].includes(dealType),
    CreatorLink: dealType !== 'Media License',
    AdditionalTerms: dealType !== 'Media License',
  };
  return (typeof map[key] === 'undefined') ? true : map[key];
};
