/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import ApiClient from 'ApiClient';

const AdminUserNameReducer = createSlice({
  name: 'adminUserNames',
  initialState: {
    adminUserNames: {},
  },
  reducers: {
    setAdminUserNames(state, action) {
      state.adminUserNames = action.payload;
    },
  },
});

const {
  setAdminUserNames,
} = AdminUserNameReducer.actions;

export const getAndSetAdminUserNames = () => async (dispatch) => {
  const api = new ApiClient();
  const response = await api.client.get('/api/portal/get-all-admin-user-names');
  dispatch(setAdminUserNames(response.data.records));
};

export default AdminUserNameReducer.reducer;
