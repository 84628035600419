/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import queryHasFilter from '@/shared/ViewHelpers/QueryHasFilter';

import ActionHeader from '../../../../shared/components/components/app/action-header';

import Filter from '../../../../shared/components/components/app/filter';

import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';

import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
import UserPropertyType from './components/custom/';


const ResourceAction = (props) => {
  const {
  //  match,
    location,
    resources,
  } = props;
  const [filterVisible, setFilerVisible] = useState(queryHasFilter(location.search));
  const [tag, setTag] = useState('');
  const resource = resources.find(r => r.id === 'ImpactAction');
  if (!resource) {
    return (<NoResourceError resourceId="ImpactAction" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="ImpactAction" actionName="list" />);
  }
  // console.log(queryHasFilter(location.search));

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs resource={resource} actionName="list" />
          <ActionHeader
            resource={resource}
            action={action}
            tag={tag}
            toggleFilter={action.showFilter ? () => setFilerVisible(!filterVisible) : undefined}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <MatTableList
            action={action}
            resource={resource}
            setTag={setTag}
            date={new Date()}
            UserPropertyType={UserPropertyType}
            columnsSettings={{
              actionId: {
                width: 160,
              },
              influencer: {
                width: 180,
              },
              campaignName: {
                width: 100,
              },
              items: {
                width: 100,
              },
              category: {
                width: 150,
              },
              quantity: {
                width: 100,
              },
              payout: {
                width: 100,
              },
              amount: {
                width: 100,
              },
              referringDomain: {
                width: 100,
              },
              sharedId: {
                width: 180,
              },
              eventDate: {
                width: 160,
              },
            }}
            v={2}
          />
        </Col>
      </Row>
      {action.showFilter ? (
        <Filter
          resource={resource}
          isVisible={filterVisible}
          toggleFilter={() => setFilerVisible(!filterVisible)}
        />
      ) : ''}
    </Container>
  );
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
