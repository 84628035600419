/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React from 'react';
import { Modal, ModalHeader, ModalBody, ButtonToolbar, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { hideModalById } from '../../../../../redux/reducers/TableReducer';

export const pauseHistoryModalId = 'PauseHistoryModal';

const PauseHistoryModal = ({ adminUsers }) => {
  const modalInfo = useSelector(state => state.table.modals[pauseHistoryModalId]);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(hideModalById(pauseHistoryModalId));
  };

  if (!modalInfo) return null;
  const { isShow: showUploadModal, data: { rebuyPauseData } } = modalInfo;

  return (
    <Modal
      isOpen={showUploadModal}
      toggle={close}
    >
      <ModalHeader toggle={close} tag="h3">
        Deal Pause History
      </ModalHeader>
      <ModalBody className="pb-0" style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <div className="mt-1 mb-3">
          {rebuyPauseData && rebuyPauseData.history.map((item) => {
            let adminName = '';
            const name = adminUsers.find(admin => (admin._id === item.admin));
            if (name) {
              adminName = `${name.firstName || ''} ${name.lastName || ''}`;
            }
            const startDate = moment.tz(item.start, 'America/New_York');
            let endDate = '';
            if (item.value !== '0' && item.value !== '9999') {
              endDate = moment.tz(item.start, 'America/New_York').add(Number(item.value), 'days').format('MM/DD/YYYY');
            }
            const daysRange = item.value !== '9999' ? `${item.value} days` : 'All Time';
            return (
              <div className="mt-1 mb-3" key={`${adminName}+${Math.random()}`} style={item.value === '9999' ? { color: '#ff4861' } : {}}>
                <span><strong>{item.value === '0' ? 'Restarted' : 'Paused'} by: </strong>{adminName}</span>
                <br />
                {item.value !== '0' ? (
                  <span>
                    <strong>Pause duration: </strong>{daysRange}
                    <br />
                    <strong>Pause date: </strong>{startDate.format('MM/DD/YYYY')}
                    <br />
                    {item.value !== '9999' ?
                      (<span><strong>Restart date: </strong>{endDate}</span>) :
                      null
                    }
                    {item.notes && (
                      // eslint-disable-next-line react/no-danger
                      <div><strong>Notes: </strong><span dangerouslySetInnerHTML={{ __html: item.notes }} /></div>
                    )}
                  </span>
                  ) :
                  (<span><strong>Restart date: </strong>{startDate.format('MM/DD/YYYY')}</span>)
                }
                <hr />
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" color="secondary" onClick={close}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

PauseHistoryModal.propTypes = {
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default PauseHistoryModal;
