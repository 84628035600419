import React, { PureComponent } from 'react';
import { Row, Spinner, Badge } from 'reactstrap';
import { axios } from 'ApiClient';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';

const columnTransform = {
  name: {
    title: 'Name',
    valueFunc: record => (record.name || ''),
    sort: record => (record.name || ''),
    searchFunc: (record, search) => (record.name ? record.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),

  },
  email: {
    title: 'Email',
    valueFunc: record => (record.email || ''),
    sort: record => (record.email || ''),
    searchFunc: (record, search) => (record.email ? record.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),
  },
  id: {
    title: 'Account ID',
    valueFunc: record => (record.id || ''),
    sort: record => (record.id || ''),
  },
  addressCountry: {
    title: 'Address Country',
    valueFunc: record => (record.addressCountry || ''),
    sort: record => (record.addressCountry || ''),
  },
  status: {
    title: 'Payment Status',
    valueFunc: (vendor) => {
      const fieldsToValidate = {
        address1: vendor.address1,
        addressCity: vendor.addressCity,
        addressState: vendor.addressState,
        addressZip: vendor.addressZip,
        addressCountry: vendor.addressCountry,
      };
      const isValid = !!vendor.address1 && !!vendor.addressCity && !!vendor.addressState
        && !!vendor.addressZip && !!vendor.addressCountry;
      // console.log(fieldsToValidate, isValid);
      const missingFields = Object.keys(fieldsToValidate).filter(k => !fieldsToValidate[k] || console.log(k));
      // console.log(Object.keys(fieldsToValidate));
      // console.log(missingFields);
      // console.log(missingFields.join(', '));
      const mc = missingFields.join(', ');

      return (<Badge color={isValid ? 'success' : 'warning'}>{isValid ? 'Ok' : `Unable to complete payment, Missing field(s): ${mc}`}</Badge>);
    },
    sort: record => (record.addressCountry || ''),
  },
};

const csvArrayColumns = [
  {
    label: 'Name',
    func: record => (record.name || ''),
  },
  {
    label: 'Email',
    func: record => (record.email || ''),
  },
  {
    label: 'Account ID',
    func: record => (record.id || ''),
  },
  {
    label: 'Address Country',
    func: record => (record.addressCountry || ''),
  },
  {
    label: 'Payment Status',
    func: (vendor) => {
      const fieldsToValidate = {
        address1: vendor.address1,
        addressCity: vendor.addressCity,
        addressState: vendor.addressState,
        addressZip: vendor.addressZip,
        addressCountry: vendor.addressCountry,
      };
      const isValid = !!vendor.address1 && !!vendor.addressCity && !!vendor.addressState
        && !!vendor.addressZip && !!vendor.addressCountry;
      const missingFields = Object.keys(fieldsToValidate).filter(k => !fieldsToValidate[k] || console.log(k));
      const mc = missingFields.join(', ');

      return (isValid ? 'Ok' : `Unable to complete payment, Missing field(s): ${mc}`);
    },
  },
];

class VendorsTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchConnectedAccounts();
  }

  // METHODS
  fetchConnectedAccounts() {
    this.setState({
      loading: true,
    });

    axios.get('/api/portal/billCom/vendors').then((response) => {
      const { accounts } = response.data;
      this.setState({
        accounts,
        loading: false,
      });
    }).catch((error) => {
      console.log(error);
    });
  }
  //

  render() {
    const { loading, accounts } = this.state;

    return (
      <div>
        <h4 className="il-color-deep-blue font-weight-medium mb-0">Organization Vendors</h4>
        {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        { !loading &&
          <Row>
            <MatTableForRedux
              dataArray={accounts}
              selectable={false}
              columns={columnTransform}
              version={(new Date()).toString()}
              defaultOrder={{
                order: 'desc',
                orderBy: 'email',
              }}
              rowsPerPage={10}
              csvDownload={{
                fileName: 'BillComVendors.csv',
                csvArrayColumns,
              }}
            />
          </Row>
        }
      </div>
    );
  }
}

export default VendorsTable;
