import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card,
  CardBody,
} from 'reactstrap';
import useMediaContent from './useMediaContent';
import mapMediaTypeIcons from '../../../../Scorecard/MediaContent/components/custom/mapMediaTypeIcons';
import DetailsCellValue from '../../../../Influencer/Influencer/influencerProfile/components/DetailsCellValue';
import ScorecardsDropDown from '../../../../Influencer/Influencer/influencerProfile/components/ScorecardsDropDown';
import ReactTableBase from '../../../../../../shared/tables/table/ReactTableBase';
import '../../../../Influencer/Influencer/influencerProfile/components/tableStyling.css';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';

const mapType = mapMediaTypeIcons();
const calculateAverage = (arr) => {
  const totalSum = arr.reduce((acc, answer) => acc + answer.totalScore, 0);
  return totalSum ? Math.floor(totalSum / arr.length) : '';
};

const tableConfig = {
  isEditable: false,
  useFlex: true,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  manualPageSize: [5, 10, 30, 40],
  withSearchEngine: true,
  sortBy: 'createdAt',
  direction: 'desc',
  placeholder: 'Search Details or Content Name',
  searchFieldMinWidth: '50%',
};

const MediaContent = ({ influencers }) => {
  const [shouldFetch, setShouldFetch] = useState(true);
  const mediaData = useMediaContent(influencers.map(i => i._id), shouldFetch, setShouldFetch);

  const columnTransform = useMemo(() => [
    {
      id: 'title',
      Header: 'Details',
      Cell: value => <DetailsCellValue record={value.row.original} />,
      accessor: record => record.title?.toLowerCase(),
      width: 350,
    },
    {
      id: 'type',
      Header: 'Type',
      Cell: value => <img style={{ width: '30px' }} src={`/images/mediacontent/${mapType[value.row.original?.type]}.png`} alt={value.row.original.type} title={value.row.original.type} />,
      accessor: record => record.type,
      width: 50,
    },
    {
      id: 'contentName',
      Header: 'Content Name',
      Cell: value => (
        <>
          <a rel="noreferrer" target="_blank" href={`/scorecard/media-content/edit/${value.row.original._id}`}>
            {value.row.original?.name || '--- Content Name Field Not Set ---'}
          </a>
        </>),
      accessor: record => record.name?.toLowerCase() || '',
      width: 250,
    },
    {
      id: 'createdAt',
      Header: 'Submit Date',
      Cell: value => (value.row.original.createdAt ? moment.utc(value.row.original.createdAt).local().format('MM/DD/YYYY') : ''),
      accessor: record => (record.createdAt ? moment.utc(record.createdAt).local().format('MM/DD/YYYY') : ''),
      width: 90,
    },
    {
      id: 'publishDate',
      Header: 'Publish Date',
      Cell: value => (value.row.original.publishDate ? moment.utc(value.row.original.publishDate).local().format('MM/DD/YYYY') : ''),
      accessor: record => (record.publishDate ? moment.utc(record.publishDate).local().format('MM/DD/YYYY') : ''),
      width: 90,
    },
    {
      id: 'score',
      Header: 'Average Score',
      accessor: record => (record.answers?.length > 0 ? calculateAverage(record.answers) : ''),
      width: 100,
    },
    {
      id: 'scorecards',
      Header: 'Scorecards',
      Cell: value => (
        <>
          {value.row.original.answers.length > 0 ? <ScorecardsDropDown record={value.row.original} /> : <span>No Scorecards</span>}
        </>
      ),
      width: 120,
    },
  ]);

  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Media Content</h2>
      <Card id="mediaConentBlock">
        <CardBody>
          {mediaData?.length > 0 ?
            <ReactTableBase
              columns={columnTransform}
              data={mediaData}
              tableConfig={tableConfig}
            />
          :
            <Alert color="warning">
              <p className="py-2">
                The Creator currently has no Media Content.
              </p>
            </Alert>
          }
        </CardBody>
      </Card>
    </div>
  );
};

MediaContent.propTypes = {
  influencers: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default MediaContent;
