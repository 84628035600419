/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const hubilReducer = createSlice({
  name: 'hubil',
  initialState: {
    contactsRemainingToUpload: null,
    loading: {
      fetchContacts: false,
      fetchDeals: false,
      syncHubSpotStructure: false,
    },
    loadingOffers: {
      syncOffers: false,
    },
    loadingBrands: {
      syncBrands: false,
    },
    error: null,
    lastFetched: new Date(),
  },
  reducers: {
    getFetchContactsStart(state) {
      state.loading = {
        ...state.loading,
        fetchContacts: true,
      };
    },
    getFetchContactsError(state) {
      state.loading = {
        ...state.loading,
        fetchContacts: false,
      };
    },
    getFetchContactsSuccess(state) {
      state.loading = {
        ...state.loading,
        fetchContacts: false,
      };
      state.contactsRemainingToUpload = null;
    },
    getSyncHubSpotStart(state) {
      state.loading = {
        ...state.loading,
        syncHubSpotStructure: true,
      };
    },
    getSyncHubSpotError(state) {
      state.loading = {
        ...state.loading,
        syncHubSpotStructure: false,
      };
    },
    getSyncHubSpotSuccess(state) {
      state.loading = {
        ...state.loading,
        syncHubSpotStructure: false,
      };
    },
    getFetchContactsStatus(state, action) {
      const { contactsRemainingToUpload } = action.payload;
      state.contactsRemainingToUpload = contactsRemainingToUpload;
    },
    getFetchDealsStart(state) {
      state.loading = {
        ...state.loading,
        fetchDeals: true,
      };
    },
    getFetchDealsError(state) {
      state.loading = {
        ...state.loading,
        fetchDeals: false,
      };
    },
    getFetchDealsSuccess(state) {
      state.loading = {
        ...state.loading,
        fetchDeals: false,
      };
    },
    getSyncOffersStart(state) {
      state.loadingOffers = {
        ...state.loadingOffers,
        syncOffers: true,
      };
    },
    getSyncOffersError(state) {
      state.loadingOffers = {
        ...state.loadingOffers,
        syncOffers: false,
      };
    },
    getSyncOffersSuccess(state) {
      state.loadingOffers = {
        ...state.loadingOffers,
        syncOffers: false,
      };
    },
    getSyncBrandsStart(state) {
      state.loadingBrands = {
        ...state.loadingBrands,
        syncBrands: true,
      };
    },
    getSyncBrandsError(state) {
      state.loadingBrands = {
        ...state.loadingBrands,
        syncBrands: false,
      };
    },
    getSyncBrandsSuccess(state) {
      state.loadingBrands = {
        ...state.loadingBrands,
        syncBrands: false,
      };
    },
  },
});
const syncHubspotApi = async () => {
  const api = new ApiClient();
  return api.client.get('/api/portal/migrate-hs-structure-to-dev');
};
const fetchContactsApi = async (identity) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/hubil/fetch-all-contacts', { identity });
};
const fetchContactsProcessStatusApi = async () => {
  const api = new ApiClient();
  return api.client.post('/api/portal/hubil/fetch-all-contacts-status');
};
const fetchAgentsApi = async () => {
  const api = new ApiClient();
  return api.client.post('/api/portal/hubil/fetch-all-agents');
};
const fetchDealsApi = async () => {
  const api = new ApiClient();
  return api.client.post('/api/portal/hubil/fetch-all-deals');
};
const syncOffersApi = async () => {
  const api = new ApiClient();
  return api.client.post('/api/portal/finance/sync-ho-hs-offers');
};
const syncBrandsApi = async () => {
  const api = new ApiClient();
  return api.client.post('/api/portal/finance/sync-ho-hs-brands');
};

export const {
  getFetchContactsStart,
  getFetchContactsSuccess,
  getFetchContactsError,
  getFetchContactsStatus,
  getFetchDealsStart,
  getFetchDealsSuccess,
  getFetchDealsError,
  getSyncOffersStart,
  getSyncOffersSuccess,
  getSyncOffersError,
  getSyncBrandsStart,
  getSyncBrandsError,
  getSyncBrandsSuccess,
  getSyncHubSpotStart,
  getSyncHubSpotError,
  getSyncHubSpotSuccess,
} = hubilReducer.actions;

export const fetchContacts = (callback, identity) => async (dispatch) => {
  try {
    // console.log('HERER');
    console.log('identity', identity);
    dispatch(getFetchContactsStart());
    const response = await fetchContactsApi(identity);
    dispatch(getFetchContactsSuccess());
    if (response.data.success) {
      dispatch(addNotice({
        message: 'All data has been successfully fetched',
        type: 'success',
      }));
    }
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(getFetchContactsError(err));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const syncHubSpotStructure = callback => async (dispatch) => {
  try {
    dispatch(getSyncHubSpotStart());
    const response = await syncHubspotApi();
    dispatch(getSyncHubSpotSuccess());
    if (response.data.success) {
      dispatch(addNotice({
        message: 'HubSpot environments were successful synced',
        type: 'success',
      }));
    }
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(getSyncHubSpotError(err));
    dispatch(addNotice({
      message: 'There was an error syncing data, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const getFetchContactsProcessStatus = callback => async (dispatch) => {
  dispatch(getFetchContactsStart());
  const response = await fetchContactsProcessStatusApi();
  if (response.data.success) {
    dispatch(getFetchContactsStatus(response.data.result));
  }
  dispatch(getFetchContactsSuccess());
  if (callback) {
    callback();
  }
};

export const fetchAgents = callback => async (dispatch) => {
  try {
    dispatch(getFetchContactsStart());
    const response = await fetchAgentsApi();
    dispatch(getFetchContactsSuccess());
    if (response.data.success) {
      dispatch(addNotice({
        message: 'All data has been successfully fetched',
        type: 'success',
      }));
    }
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(getFetchContactsError(err));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const fetchDeals = callback => async (dispatch) => {
  try {
    dispatch(getFetchDealsStart());
    const response = await fetchDealsApi();
    dispatch(getFetchDealsSuccess());
    if (response.data.success) {
      dispatch(addNotice({
        message: 'Deal fetch is in progress.',
        type: 'success',
      }));
    }
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(getFetchDealsError(err));
    dispatch(addNotice({
      message: 'There was an error while fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const syncOffers = callback => async (dispatch) => {
  try {
    dispatch(getSyncOffersStart());
    const response = await syncOffersApi();
    dispatch(getSyncOffersSuccess());
    if (response.data.success) {
      dispatch(addNotice({
        message: 'All data has been successfully synced',
        type: 'success',
      }));
    }
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(getSyncOffersError(err));
    dispatch(addNotice({
      message: 'There was an error while syncing data, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const syncBrands = callback => async (dispatch) => {
  try {
    dispatch(getSyncBrandsStart());
    const response = await syncBrandsApi();
    dispatch(getSyncBrandsSuccess());
    if (response.data.success) {
      dispatch(addNotice({
        message: 'All data has been successfully synced',
        type: 'success',
      }));
    }
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(getSyncBrandsError(err));
    dispatch(addNotice({
      message: 'There was an error while syncing data, Check out console to see more information.',
      type: 'error',
    }));
  }
};


export default hubilReducer.reducer;
