
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const Tags = ({
  tags, setTags, tagsSuggestion, title,
}) => {
  const modifiedTagOptions = tagsSuggestion.map(t => {return { value: t.id, label: t.name }});
  const modifiedTags = tags.map(t => {return { value: t.id, label: t.name }});

  const onTagChange = (change) => {
    const options = change.map(t => {return { id: t.value, name: t.label }});
    setTags(options);
  };

  return (<Select
    className="mr-2"
    isClearable
    isMulti
    isSearchable
    onChange={onTagChange}
    name={title}
    placeholder={title}
    value={modifiedTags}
    options={modifiedTagOptions}
  />);
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTags: PropTypes.func.isRequired,
  tagsSuggestion: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  title: PropTypes.string.isRequired,
};
export default Tags;