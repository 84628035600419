import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WVValidator from '../../../../../../shared/helpers/WVValidator';

const StripeStatusComponent = ({ record, type }) => {
  if (!record.populated || (type === 'creator' && (!record.populated.influencer || !record.populated.influencer.params || !record.populated.influencer.params?.payment))) {
    return '';
  }

  const influencer = type === 'agent' ? record.populated.agent.params : record.populated.influencer.params;
  const { stripe, billCom } = influencer.payment;

  if (stripe.isDefault && !WVValidator.isEmpty(stripe.accountId)) {
    return (
      <span className="text-success">
        <FontAwesomeIcon icon={['fab', 'cc-stripe']} size="2x" />
      </span>
    );
  } else if (billCom.isDefault && !WVValidator.isEmpty(billCom.accountId)) {
    return (
      <span style={{ width: '40px' }}>
        <img src="/images/payments/billComGreen.png" className="img-fluid" alt="Bill.com" style={{ width: '40px' }} />
      </span>
    );
  }

  return '';
};
StripeStatusComponent.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string,
};
StripeStatusComponent.defaultProps = {
  type: 'creator',
};
export default StripeStatusComponent;

