import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button, FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import withNotice from '../../../../../../App/store/with-notice';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import RadioButton from '../../../../../../../shared/components/RadioButton';

const notAssigned = {
  value: null,
  label: 'Not Assigned',
  record: { params: { _id: null, firstname: 'Not Assigned', lastname: '' } },
};

const AssignContactOwnerModal = ({
  isOpen,
  closeFunc,
  setVersion,
  prospectIds,
  adminUsers,
  total,
}) => {
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState(notAssigned);
  const [includeAll, setIncludeAll] = useState(false);

  const handleChangeOwner = async (selectedItem) => {
    setOwner(selectedItem);
  };

  useEffect(() => {
    if (!isOpen) {
      setOwner(notAssigned);
      setIncludeAll(false);
    }
    // console.log(total);
  }, [isOpen]);
  // SUBMIT Data
  const submit = async () => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search);
    console.log('Submit');
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-update-admin-user',
      data: {
        prospectIds,
        adminUserId: owner.value,
        includeAll,
        query: query.toString(),
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        closeFunc();
      }
    });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '425px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Assign Contact Owner</ModalHeader>
      <ModalBody>
        {prospectIds.length !== total && (
          <>
            <div>{`You have selected ${prospectIds.length} Lead${prospectIds.length !== 1 ? 's' : ''} to assign to Contact Owner`}</div>
            <div className="theme-light radio-in-prospects">
              {`Would you like to select all ${WVFormatter.formatIntNumber(total)} Leads?`}<br />
              <RadioButton
                name="action"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                name="action"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>
          </>
        )}
        <Row>
          <Col>
            <FormGroup>
              <Select
                value={owner}
                options={adminUsers}
                onChange={handleChangeOwner}
                placeholder=""
                isDisabled={!!loading}
                menuPlacement="auto"
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="danger" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

AssignContactOwnerModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  prospectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default withNotice(AssignContactOwnerModal);
