import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  bar: {
    width: '100%',
    padding: '10px',
  },
  percentText: {
    paddingTop: '5px',
  },
});

function ProgressBar({ topic, afterFunction, showAfterEnd }) {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const socket = io({
      transports: ['websocket'],
    });
    socket.on(topic, (data) => {
      const percent = 100 - (data * 100);
      setProgress(percent);
      if (percent === 100 && afterFunction) {
        setTimeout(() => afterFunction(), 2000);
      }
    });
    return function cleanup() {
      socket.close();
    };
  }, []);

  if ((!showAfterEnd && progress === 0) || (!showAfterEnd && progress === 99)) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.bar}>
        <LinearProgress variant="determinate" value={progress} />
      </div>
      <div className={classes.percentText}>
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(progress)}%`}
        </Typography>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  /**
   * Subscription topic to listen on
   */
  topic: PropTypes.string.isRequired,
  afterFunction: PropTypes.func,
  showAfterEnd: PropTypes.bool,
};
ProgressBar.defaultProps = {
  /**
   * Some function to run after progress reaches 100%
   */
  afterFunction: null,
  showAfterEnd: false,
};

export default ProgressBar;
