import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { axios } from 'ApiClient';
import TableCellContextMenu from '../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';
import { showModalById } from '../../../../../redux/reducers/TableReducer';
import { modalId } from './RefreshPayableModal';
import Hook from '../../../../../shared/hooks';

const ContextMenu = ({
  p, setVersion,
}) => {
  const { showSuccess } = Hook.useNoticeHook();
  // console.log(p.row.original);
  const record = p.row.original;
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const contextMenuOptions = [{
    label: 'Refresh Payable',
    handler: () => dispatch(showModalById(modalId, { record, updateRecordFunction: p.updateRecordFunction })),
    disabled: record.params?.status === 'complete',
  }];
  contextMenuOptions.push({
    label: 'Refresh Payment Authorization',
    handler: async () => {
      try {
        await axios.post('/api/portal/finance/post-deal-validation-prefill', { ids: [record.params?.deal] });
        // const data = await axios.get(`/api/portal/compliance/get-payment-authorization-checklist?selectedId=${p.row.original._id}`);
        showSuccess('Authorization has been updated');
        setVersion((new Date()).toString());
      } catch (e) {
        console.log(e);
      }
    },
    disabled: record.params?.status === 'complete' || !record.params?.deal,
  });
  return <TableCellContextMenu options={contextMenuOptions} />;
};
ContextMenu.propTypes = {
  p: PropTypes.objectOf(PropTypes.any).isRequired,
  setVersion: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContextMenu;
