import React from 'react';
import PropTypes from 'prop-types';
import ApiClient from 'ApiClient';

import RefreshIcon from 'mdi-react/RefreshIcon';
import {
  Spinner,
  Button,
  Row,
  Col,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,

  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';

import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import withNotice from '../../../../../../containers/App/store/with-notice';
import formater from '../../../../../../shared/helpers/WVFormatter';

import OverlapReportTitleCell from './OverlapReportTitleCell';
import ButtonWithSpinner from '../../../../../../shared/components/ButtonWithSpinner';


const columnTransform = {
  creator: {
    title: 'Creator',
    Component: OverlapReportTitleCell,
    // valueFunc: (record) => {
    //   let channelURL = '';
    //   console.log(record);
    //   if (record.creator && record.creator.accounts) {
    //     record.creator.accounts.forEach((acc) => {
    //       console.log(acc);
    //       if (acc.platform && acc.platform === 'youtube') {
    //         channelURL = `<a href=${acc.account_url} target="_blank"><img src="/images/mediacontent/youtube.png" style="max-width: 15px"></a>`;
    //       }
    //     });
    //   }
    //   // <a href={account.account_url} target="_blank"><img src={`/images/mediacontent/${platformsMapping[account.platform]}`} alt={account.platform} title={account.platform} style={{ maxWidth: '15px' }} /></a>
    //   return (record.details && record.details.title ? record.details.title : '') + channelURL;
    // },
    sort: record => (record.details && record.details.title ? record.details.title : ''),
  },
  affinity: {
    title: 'Affinity',
    valueFunc: record => `${parseInt(record.affinity, 10)}x`,
    sort: record => (parseFloat(record.affinity)),
  },
  overlap: {
    title: 'Overlap',
    valueFunc: record => `${formater.formatNumber(record.overlap * 100)}%`,
    sort: record => (parseFloat(record.overlap)),
  },
  score: {
    title: 'Score',
    valueFunc: record => formater.formatNumber((parseFloat(record.score))),
    sort: record => (parseFloat(record.score)),
  },
};

const downloadCsv = [
  {
    label: 'Creator',
    func: record => (record.details && record.details.title ? record.details.title : ''),
  }, {
    label: 'Channel',
    func: (record) => {
      let channelURL = '';
      if (record.creator && record.creator.accounts) {
        record.creator.accounts.forEach((acc) => {
          if (acc.platform && acc.platform === 'youtube' && acc.account_url) {
            channelURL = acc.account_url;
          }
        });
      }

      return channelURL;
    },
  }, {
    label: 'Affinity',
    func: record => `${parseInt(record.affinity, 10)}x`,
  }, {
    label: 'Overlap',
    func: record => `${formater.formatNumber(record.overlap * 100)}%`,
  }, {
    label: 'Score',
    func: record => formater.formatNumber((parseFloat(record.score))),
  },
];

class OverlapReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thisTitle: '',
      isFetching: false,
      loadingMore: false,
      needToLoadMore: false,
      emptyRows: 0,
      isLimitError: false,
      cantFindChannel: false,
      showFilters: false,
      array: [],
      filteredArray: [],

      filters: {
        affinity: [20, 30],
        overlap: [20, 30],
        score: [20, 30],
        search: '',
      },

      ranges: {
        affinity: [0, 100],
        overlap: [0, 100],
        score: [0, 100],
      },

      dataArrayVersion: '1',

      showLoadDialog: false,
      howManyToLoad: 0,
      loadedRows: 0,
    };


    this.handleLoadUpEverything = this.loadUpEverything.bind(this);
    this.handleToggleFilters = this.toggleFilters.bind(this);
    this.handleSetFilter = this.setFilter.bind(this);
    this.handleSetSearch = this.setSearch.bind(this);
    this.handleSetHowManyToLoad = this.setHowManyToLoad.bind(this);

    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  setHowManyToLoad(value) {
    this.setState({ howManyToLoad: value });
  }

  setFilter(name, value) {
    this.setState((prevState) => {
      const updated = {};
      updated[name] = value;
      return {
        ...prevState,
        filters: Object.assign({}, prevState.filters, updated),
      };
    });

    this.applyFiltersDebounce();
  }

  setSearch(q) {
    this.setFilter('search', q);
  }

  setShowLoadDialog(show) {
    this.setState({ showLoadDialog: show });
  }

  toggleFilters() {
    const { showFilters } = this.state;
    this.setState({ showFilters: !showFilters });
  }


  calculateFiltersRanges() {
    const { array } = this.state;
    const ranges = {
      affinity: [Infinity, -Infinity],
      overlap: [Infinity, -Infinity],
      score: [Infinity, -Infinity],
    };

    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const item of array) {
      if (item.affinity < ranges.affinity[0]) {
        ranges.affinity[0] = Math.floor(item.affinity);
      }
      if (item.affinity > ranges.affinity[1]) {
        ranges.affinity[1] = Math.ceil(item.affinity);
      }

      if (item.overlap * 100 < ranges.overlap[0]) {
        ranges.overlap[0] = Math.floor(item.overlap * 100);
      }
      if (item.overlap * 100 > ranges.overlap[1]) {
        ranges.overlap[1] = Math.ceil(item.overlap * 100);
      }

      if (item.score < ranges.score[0]) {
        ranges.score[0] = Math.floor(item.score);
      }
      if (item.score > ranges.score[1]) {
        ranges.score[1] = Math.ceil(item.score);
      }
    }

    console.log('ranges', ranges);

    this.setState({ ranges, filters: ranges });
  }

  applyFiltersDebounce() {
    clearTimeout(this.__applyFilterDebounce);
    this.__applyFilterDebounce = setTimeout(() => {
      this.applyFilters();
    }, 200);
  }

  applyFilters() {
    const {
      array,
      filters,
      filteredArray,
      dataArrayVersion,
    } = this.state;

    let fSearch = null;
    if (filters.search) {
      fSearch = (filters.search).toLowerCase();
    }

    // eslint-disable-next-line arrow-body-style
    const filtered = array.filter((item) => {
      let s = true;
      if (fSearch) {
        if (item.details.title.toLowerCase().indexOf(fSearch) === -1) {
          s = false;
        }
      }
      return (item.affinity > filters.affinity[0]
        && item.affinity < filters.affinity[1]
        && item.overlap * 100 > filters.overlap[0]
        && item.overlap * 100 < filters.overlap[1]
        && item.score > filters.score[0]
        && item.score < filters.score[1]
        && s);
    });


    console.log('applyFilters', filtered.length, filteredArray);

    // eslint-disable-next-line arrow-body-style
    this.setState((prevState) => {
      console.log(prevState);

      return {
        ...prevState,
        filteredArray: filtered,
        dataArrayVersion: `${(parseInt(dataArrayVersion, 10) + 1)}`,
      };
    });

    this.recalculateEmptyRows();
  }

  recalculateEmptyRows() {
    const emptyRowsCount = this.emptyRowsCount();
    this.setState({
      emptyRows: emptyRowsCount,
      howManyToLoad: emptyRowsCount,
      needToLoadMore: !!emptyRowsCount,
    });
  }

  hasEmptyRows() {
    const { filteredArray } = this.state;

    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const item of filteredArray) {
      if (!item.channelURL) {
        return true;
      }
    }

    return false;
  }

  emptyRowsCount() {
    const { filteredArray } = this.state;
    let count = 0;

    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const item of filteredArray) {
      if (!item.channelURL) {
        count += 1;
      }
    }

    return count;
  }

  async loadUpEverything(forceLoading) {
    const {
      loadingMore,
      // filteredArray,
      dataArrayVersion,
      howManyToLoad,
    } = this.state;

    if (loadingMore) {
      return false;
    }

    // eslint-disable-next-line no-alert
    // alert(forceLoading);

    const emptyRowsOnStart = this.emptyRowsCount();

    if (emptyRowsOnStart >= 800 && !forceLoading) {
      // show count dialog first
      //
      this.setShowLoadDialog(true);
      return false;
    }


    const { creatorId } = this.props;
    this.setState({ loadingMore: true, loadedRows: 0 });

    const maxRuns = 20;
    let curRun = 0;

    let emptyRowsCurrent = 0;
    let loadedRows = 0;

    // eslint-disable-next-line prefer-destructuring
    const items = this.tableRef.current.state.items;
    // console.error('childItems', items);

    const api = new ApiClient();
    do {
      // get list of ids to load first
      const idsToPick = [];
      items.forEach((item) => {
        idsToPick.push(item.creatorId);
      });

      const queryOptions = {
        creatorId,
        moreData: true,
        otherSorts: true,
        idsToPick,
      };

      // eslint-disable-next-line no-await-in-loop
      const resp = await api.client.post('/api/portal/research/do-get-audience-overlap-from-youtube', queryOptions);
      const data = resp.data.overlap.results;

      this.setState((prevState) => {
        const { array } = prevState;
        // eslint-disable-next-line no-restricted-syntax, no-unused-vars
        for (const resultHere of array) {
          // eslint-disable-next-line no-restricted-syntax, no-unused-vars
          for (const resultUpdated of data) {
            if (resultHere.creatorId === resultUpdated.creatorId) {
              // console.error('updating ', resultHere.creatorId);
              Object.assign(resultHere, resultUpdated);
            }
          }
        }
      });

      emptyRowsCurrent = this.emptyRowsCount();
      loadedRows = emptyRowsOnStart - emptyRowsCurrent;
      this.setState({
        loadedRows,
        emptyRows: emptyRowsCurrent,
        dataArrayVersion: `${(parseInt(dataArrayVersion, 10) + 1)}`,
      });

      if (loadedRows < howManyToLoad) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise(res => setTimeout(res, 1000));
      }
      curRun += 1;
    } while (curRun < maxRuns && this.hasEmptyRows() && (loadedRows < howManyToLoad));

    this.setState({ loadingMore: false });
    this.setState({ needToLoadMore: false });

    this.setShowLoadDialog(false);

    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  async searchByURL(url) {
    const api = new ApiClient();

    const cleanURL = url.split('http:').join('https:');

    const resp = await api.client.post('/api/portal/research/do-search-from-youtube', { type: 'creator', value: cleanURL });
    console.log(url);
    console.log(resp);
    if (resp && resp.data && resp.data.data && resp.data.data.creator_ids) {
      return resp.data.data.creator_ids[0];
    }

    return null;
  }

  async fetchData(forceRefresh = false) {
    // const { addNotice } = props;
    let { creatorId } = this.props;

    if (creatorId.indexOf('channel') === 0) {
      const youtubeURL = atob(creatorId.split('channel')[1]);
      creatorId = await this.searchByURL(youtubeURL);
    }

    if (creatorId) {
      const api = new ApiClient();
      this.setState({ isFetching: true });
      try {
        const queryOptions = {
          creatorId,
          getTitle: true,
          force: forceRefresh,
          otherSorts: true,
        };

        const resp = await api.client.post('/api/portal/research/do-get-audience-overlap-from-youtube', queryOptions);
        console.log(resp);

        const data = resp.data.overlap.results;

        let thisTitle = '';
        if (resp.data.thisTitle) {
          // eslint-disable-next-line prefer-destructuring
          thisTitle = resp.data.thisTitle;
        }

        let cacheItemDate = null;
        if (resp.data.cacheItemDate) {
          cacheItemDate = new Date(resp.data.cacheItemDate);
        }

        this.setState({
          isFetching: false,
          array: data,
          filteredArray: data,
          thisTitle,
          cacheItemDate,
        });

        // this.loadUpEverything();

        // if (this.hasEmptyRows()) {
        //   this.setState({ needToLoadMore: true });
        //   this.setState({ emptyRows: this.emptyRowsCount() });
        // }

        this.calculateFiltersRanges();
        this.recalculateEmptyRows();

        return true;
      } catch (e) {
        this.setState({ isLimitError: true, isFetching: false });

        console.error(e);
        creatorId = null;
      }
    } else {
      this.setState({ cantFindChannel: true });
    }

    return false;
  }

  forceRefresh() {
    this.fetchData(true);
  }

  refresh() {
    this.setState({ isLimitError: false });
    this.fetchData();
  }

  render() {
    const {
      isFetching,
      loadingMore,
      needToLoadMore,
      isLimitError,
      cantFindChannel,
      emptyRows,
      filteredArray,
      showFilters,
      filters,
      ranges,
      dataArrayVersion,
      thisTitle,
      cacheItemDate,

      showLoadDialog,
      howManyToLoad,
      loadedRows,
    } = this.state;

    let formattedDate = null;

    try {
      let dateResultsUpdated = cacheItemDate;
      if (!dateResultsUpdated) {
        dateResultsUpdated = new Date();
      }

      formattedDate = dateResultsUpdated.toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' });
      formattedDate = formattedDate.split(`${dateResultsUpdated.getFullYear()},`).join(`${dateResultsUpdated.getFullYear()} @`);
      formattedDate = formattedDate.split(' AM').join('am');
      formattedDate = formattedDate.split(' PM').join('pm');
    } catch (e) {
      formattedDate = '';
    }

    if (cantFindChannel) {
      return (
        <div className="alert--bordered alert alert-danger" style={{ display: 'block', margin: '10px 0 20px 0', padding: '10px' }}>
          Can not find channel on Tubular
        </div>
      );
    }

    if (isLimitError) {
      return (
        <div className="alert--bordered alert alert-info" style={{ display: 'block', margin: '10px 0 20px 0', padding: '10px' }}>
          Looks there&apos;s Tubular limit for this search. Please try again in a minute.
          <Button
            color="primary"
            className="float-right btn-sm"
            onClick={() => this.refresh()}
          >
            <RefreshIcon size={16} />
            Refresh
          </Button>
        </div>
      );
    }

    if (isFetching) {
      return (
        <div className="text-center"><Spinner color="primary" size="lg" /></div>
      );
    }

    return (
      <div>

        <Row>
          <Col>
            <div><h2>{ thisTitle } Audience Overlap </h2></div>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <Tooltip title="Toggle Filters">
              <IconButton
                size="small"
                onClick={this.handleToggleFilters}
                className="material-table__toolbar-button"
                style={{
                  height: '38px',
                }}
              >
                <TuneIcon size="30" />
              </IconButton>
            </Tooltip>
          </Col>
        </Row>

        {showFilters && (
        <Row style={{ marginBottom: '20px' }}>
          <Col lg={3} md={6}>
            <Label className="bold-text" style={{ marginLeft: '0px' }}>
              Title
            </Label>
            <InputGroup>
              <Input
                className="form-control-sm form-control"
                name="search"
                type="search"
                autoComplete="off"
                placeholder="Filter results by creator name"
                value={filters.search}
                onChange={p => this.handleSetSearch(p.target.value)}
                style={{ height: '44px' }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i
                    className="fa fa-search"
                    aria-hidden="true"
                    style={{ color: '#70bbfd', fontSize: 12 }}
                  />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg={3} md={6}>
            <Label className="bold-text" style={{ marginBottom: '15px' }}>
              Affinity
            </Label>
            <Slider
              min={ranges.affinity[0]}
              max={ranges.affinity[1]}
              value={filters.affinity}
              onChange={(event, value) => this.handleSetFilter('affinity', value)}
              valueLabelDisplay="auto"
            />
          </Col>
          <Col lg={3} md={6}>
            <Label className="bold-text" style={{ marginBottom: '15px' }}>
              Overlap
            </Label>
            <Slider
              min={ranges.overlap[0]}
              max={ranges.overlap[1]}
              step={0.01}
              value={filters.overlap}
              onChange={(event, value) => this.handleSetFilter('overlap', value)}
              valueLabelDisplay="auto"
            />
          </Col>
          <Col lg={3} md={6}>
            <Label className="bold-text" style={{ marginBottom: '15px' }}>
              Score
            </Label>
            <Slider
              min={ranges.score[0]}
              max={ranges.score[1]}
              step={0.01}
              value={filters.score}
              onChange={(event, value) => this.handleSetFilter('score', value)}
              valueLabelDisplay="auto"
            />
          </Col>
        </Row>
        )}

        {needToLoadMore && (
          <div className="alert--bordered alert alert-info" style={{ display: 'block', padding: '10px', lineHeight: '30px' }}>
            Due to Tubular limits, detailed channel info present only for the first 15 rows. To cover all available data, we need to load <span>{ emptyRows }</span> more rows, it will take few minutes.

            <div style={{ float: 'right' }}>
              <ButtonWithSpinner
                type="button"
                color="primary"
                disabled={loadingMore}
                size="sm"
                onClick={() => this.handleLoadUpEverything(false)}
                loading={loadingMore}
              >
                <span>Load all data</span>
              </ButtonWithSpinner>
            </div>
          </div>
        )}

        <Modal isOpen={showLoadDialog === true} toggle={() => this.setShowLoadDialog(false)} size="sm">
          <ModalHeader toggle={() => this.setShowLoadDialog(false)}>
            <strong>How many Creators do you wish to include to load data?</strong>
          </ModalHeader>
          <ModalBody>
            <Row style={{ marginTop: '22px' }}>
              <Col xs="3 text-center">
                <i className="fa fa-question-circle" style={{ color: '#70bbfd', fontSize: '2.5em' }} />
                <br />
                {(loadingMore) && (
                  <span>{ loadedRows } / { howManyToLoad } loaded</span>
                )}
              </Col>
              <Col style={{ paddingTop: '2px' }}>

                {!loadingMore && (
                  <Slider
                    min={0}
                    max={emptyRows}
                    value={howManyToLoad}
                    disabled={loadingMore}
                    valueLabelDisplay="on"
                    onChange={(event, value) => this.handleSetHowManyToLoad(value)}
                  />
                )}

                {loadingMore && (
                  <Slider
                    min={0}
                    max={emptyRows}
                    value={[loadedRows, howManyToLoad]}
                    disabled={loadingMore}
                    valueLabelDisplay="auto"
                  />
                )}

              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="modal__footer">
            <ButtonWithSpinner
              type="button"
              color="primary"
              disabled={loadingMore}
              size="sm"
              onClick={() => this.handleLoadUpEverything(true)}
              loading={loadingMore}
            >
              <span>Load Data</span>
            </ButtonWithSpinner>
          </ModalFooter>
        </Modal>


        <MatTableForRedux
          dataArray={filteredArray}
          columns={columnTransform}
          version={dataArrayVersion}
          defaultOrder={{
            order: 'desc',
            orderBy: 'affinity',
          }}
          csvDownload={{
            fileName: 'overlapResults.csv',
            csvArrayColumns: downloadCsv,
          }}
          ref={this.tableRef}
        />

        <div
          className="alert--bordered alert alert-info"
          style={{
          display: 'block',
          margin: '10px 15px 20px 15px',
          padding: '10px',
          lineHeight: '30px',
          clear: 'both',
          }}
        >
          Results last updated on: { formattedDate }
          <Button
            color="primary"
            className="float-right btn-sm"
            onClick={() => this.forceRefresh()}
          >
            <RefreshIcon size={16} />
            Force Refresh
          </Button>
        </div>
      </div>
    );
  }
}


OverlapReport.propTypes = {
  // addNotice: PropTypes.func.isRequired,
  creatorId: PropTypes.string.isRequired,
};
// csvDownload={{
//   fileName: 'AccountsReceivableReport.csv',
//   csvArrayColumns: downloadCsv,
// }}

export default withNotice(OverlapReport);
