import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { Button } from 'reactstrap';
import { unflatten } from 'flat';
import UserPropertyType from './index';
import MatTableList from '../../../../../../../shared/tables/materialTable/MatTableList';
import formatter from '../../../../../../../shared/helpers/WVFormatter';
import ContextMenuInList from './ContextMenuInList';
import ProgressProcess from './ProgressProcess';

const moment = require('moment');


const Table = React.memo(({
  action,
  resource,
  setVersion,
  version,
  adminUsers,
  // handleSelectCheckbox,
}) => (
  <div style={{ marginLeft: '-15px', marginRight: '-15px' }} >
    <MatTableList
      action={action}
      resource={resource}
      date={new Date()}
      version={version}
      UserPropertyType={UserPropertyType}
      showFilters={false}
      showTitle={false}
      sortBy="createdAt"
      direction="desc"
      v={2}
      noActions
      isResizable={false}
      useCheckboxes
      // checkboxHandler={handleSelectCheckbox}
      enableWarning={false}
      notFoundProps={{ title: 'No Uploads were found' }}
      useFlex
      customColumns={[
        {
          Header: 'Upload Owner',
          accessor: () => null,
          id: 'meta.adminUserId',
          width: 160,
          Cell: (p) => {
            const admin = adminUsers.find(item => item.value === p.row.original.params['meta.adminUserId']);
            return admin ? admin.label : '---';
          },
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: 'Name',
          accessor: () => null,
          id: 'title',
          width: 160,
          Cell: p => useMemo(() => {
            const unflattenRecord = unflatten(p.row.original);
            // const parent = unflattenRecord.params?.queueObj?.find(item => item.isParent === true);
            // console.log(parent);
            return unflattenRecord.params.title || '';
          }, [p.row.original]),
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: 'Start Time(ET)',
          accessor: () => null,
          id: 'createdAt',
          width: 130,
          Cell: (p) => {
            // console.log(p.row.original);
            if (p.row.original.params) {
              return formatter.formatToEST(moment(p.row.original.params.upload_start_date || p.row.original.params.createdAt)).format('M/D/YYYY h:mm A');
            }
            return '---';
          },
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: 'Status',
          accessor: () => null,
          id: 'percentComplete',
          width: 100,
          Cell: p => useMemo(
            () => (<ProgressProcess updateRecordFunction={p.updateRecordFunction} record={p.row.original} />),
            [p.row.original],
          ),
          disableSortBy: true,
          className: 'align-middle',
        },
        {
          Header: 'Queued',
          accessor: () => null,
          id: 'totalNew',
          width: 100,
          Cell: p => useMemo(
            () => (
              <div>
                {formatter.formatIntNumberWithPercent(Number(p.row.original.params['meta.totalNew']), Number(p.row.original.params['meta.total']))}
              </div>
            ),
            [p.row.original],
          ),
          disableSortBy: true,
          className: 'align-middle',
        },
        {
          Header: 'Success',
          accessor: record => Number(record.params.successPercent),
          id: 'totalDone',
          width: 100,
          Cell: p => useMemo(
            () => (
              <div>
                {formatter.formatIntNumberWithPercent(Number(p.row.original.params['meta.totalDone']), Number(p.row.original.params['meta.total']))}
              </div>
            ),
            [p.row.original],
          ),
          disableSortBy: true,
          className: 'align-middle',
        },
        {
          Header: 'Duplicates',
          accessor: () => null,
          id: 'totalDuplicates',
          width: 100,
          Cell: p => useMemo(
            () => (
              <div>
                {formatter.formatIntNumberWithPercent(Number(p.row.original.params['meta.totalDuplicates']), Number(p.row.original.params['meta.total']))}
              </div>
            ),
            [p.row.original],
          ),
          disableSortBy: true,
          className: 'align-middle',
        },
        {
          Header: 'Excluded',
          accessor: () => null,
          id: 'totalExcluded',
          width: 100,
          Cell: p => useMemo(
            () => (
              <div>
                {formatter.formatIntNumberWithPercent((Number(p.row.original.params['meta.totalExcluded']) + Number(p.row.original.params['meta.totalInBlacklist'])), Number(p.row.original.params['meta.total']))}
              </div>
            ),
            [p.row.original],
          ),
          disableSortBy: true,
          className: 'align-middle',
        },
        {
          Header: 'Failed',
          accessor: () => null,
          id: 'totalErrors',
          width: 100,
          Cell: p => useMemo(
            () => (
              <div>
                {formatter.formatIntNumberWithPercent(Number(p.row.original.params['meta.totalErrors']), Number(p.row.original.params['meta.total']))}
              </div>
            ),
            [p.row.original],
          ),
          disableSortBy: true,
          className: 'align-middle',
        },
        {
          Header: 'Total',
          accessor: p => p.params['meta.total'] || 0,
          id: 'total',
          width: 100,
          Cell: p => useMemo(
            () => (
              <div>
                {formatter.formatIntNumber(Number(p.row.original.params['meta.total'] || 0))}
              </div>
            ),
            [p.row.original],
          ),
          disableSortBy: true,
          className: 'align-middle',
        },
        {
          Header: 'Elapsed Time',
          accessor: () => null,
          id: 'endDate',
          width: 100,
          Cell: (p) => {
            // console.log(p.row.original);
            let duration = 0;
            if (['done', 'stopped', 'error'].includes(p.row.original.params.status) && p.row.original.params.duration) {
              // const diff = moment(p.row.original.params['queueObj.0.duration']);
              // const days = moment.utc(p.row.original.params.duration).diff(moment(0), 'days');
              ({ duration } = p.row.original.params);
              // return `${days} ${moment(p.row.original.params.duration).utc().format('HH:mm:ss')}`;
            } else if (['done', 'stopped', 'error'].includes(p.row.original.params.status)) { // for old values
              const startTime = moment(p.row.original.params.createdAt).utc();
              const diff = moment(p.row.original.params.endDate || startTime).utc().diff(startTime);
              duration = diff;
              // const days = moment.utc(diff).diff(moment(0), 'days');
              // return `${days} ${moment(diff).utc().format('HH:mm:ss')}`;
            } else {
              const startTime = p.row.original.params.endDate ? moment(p.row.original.params.endDate).utc() : moment(p.row.original.params.createdAt).utc();
              console.log(startTime, 'START TIME');
              const diff = (p.row.original.params.duration || 0) + moment().utc().diff(startTime);
              console.log(diff, 'DIFF');
              duration = diff;
            }
            const durationD = Math.floor(duration / (1000));
            const days = Math.floor(durationD / (24 * 60 * 60));
            const H = Math.floor((durationD - (days * (24 * 60 * 60))) / (60 * 60));
            const M = Math.floor((durationD - (days * (24 * 60 * 60)) - (H * 60 * 60)) / 60);
            const S = (durationD - (days * (24 * 60 * 60)) - (H * 60 * 60) - (60 * M));

            return `${days}d ${H}h ${M}m ${S}s`;
          },
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: '',
          accessor: () => null,
          id: '_id',
          Cell: p => (
            <ContextMenuInList
              record={p.row.original}
              updateTable={() => setVersion(new Date().toString())}
            />
          ),
          /* Cell: p => useMemo(
            () => {
              const csvDownloadLink = `/api/portal/prospects/get-csv-uploads?identity=${p.row.original.params._id}`;
              const value = Math.round(((p.row.original.params.totalDone + p.row.original.params.totalErrors) * 100) / p.row.original.params.total);
              const unflattenRecord = unflatten(p.row.original);
              const parent = unflattenRecord.params?.queueObj?.find(item => item.isParent === true);
              if (!parent) return '';
              return (
                <div>
                  <Button
                    className="btn btn-sm"
                    style={{ marginBottom: 0, marginLeft: (value !== 100 && parent.status !== 'stopped') ? 10 : 0 }}
                    color="primary"
                    onClick={() => window.open(csvDownloadLink, '_blank').focus()}
                  >
                    Download CSV
                  </Button>
                </div>
              );
            },
            [p.row.original],
          ), */
          disableSortBy: true,
          className: 'align-right',
        },
      ]}
    />
  </div>
), (a, a1) => _.isEqual(a.version, a1.version));

Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  // setSingleSelectedHsContactId: PropTypes.func.isRequired,
  // handleSelectCheckbox: PropTypes.func.isRequired,
  // addNotice: PropTypes.func.isRequired,
};
export default Table;
