/* eslint react/prop-types: 0 */
import React, { useCallback } from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import CopyToClipboard from '../../../../../../../shared/components/CopyToClipboard';
import socialIcon from '../../../../../../../shared/components/icons/Social';
import { getConstant } from '../../../../../../../shared/helpers/WVConstants';
import Hook from 'ILHooks';
import { axios } from 'ApiClient';

export default ({ record }) => {
  const [ showModal ] = Hook.useShowModalHook('ConfirmModalDefault');
  const reload = Hook.useReloadTableHook('/api/portal/finance/get-contacts-audit-report');
  const { showSuccess, showError } = Hook.useNoticeHook();
  const updateItem = useCallback(async( parentContactId, prospectId) => {
    const result = await axios.postSafety('/api/portal/finance/post-move-prospect', { parentContactId, prospectId });
    if (result.success) {
      reload();
      showSuccess('Contacts were merged successfuly')
    } else {
      showError(result.error || 'Unable to update Prospect');
    }
  });
  const text = <>
    When you do that action, we will do the following:
    <ol>
        <li>Move target Contact(Influencer).</li>  
    </ol>
  </>

  if (!record.params.rightProspects) return '';
  const advertisers = getConstant('deal', 'allAdvertisers');
  console.log(record.params.rightProspects);
  const items = record.params.rightProspects.map((prospect) => {
    const options = record.params.contacts.map((a) => ({
      value: a?._id,
      label: `${a?.firstname} ${a?.lastname} (${a?.email})`
    }))

    const name = `${prospect?.title}`;
    const email = `${prospect?.email}`;
    const advertiserName = advertisers.find(a => a._id === prospect?.approvalStatus?.brand?.targetAdvertiserId);
    return (
      <div className="mb-4 w-100" key={prospect._id}>
        <div>
          <div
            className=""
          >
            <a href={`/research/internal-prospects?filters.prospectId=${prospect._id}`} target="_blank" rel="noreferrer">
              {name || 'No Title'}
            </a>
          </div>
          <CopyToClipboard
            value={name}
          >
            {name?.length > 50 ? `${name.slice(0, 50)}...` : name}
          </CopyToClipboard>
          <CopyToClipboard
            value={email}
          >
            {email?.length > 50 ? `${email.slice(0, 50)}...` : email}
          </CopyToClipboard>
          <div>
            <strong>Target Brand</strong>: {advertiserName?.companyName || '-'}
          </div>
          <div>
            <strong>IL Approval Status</strong>: {prospect?.approvalStatus?.influencelogic?.status || '-'}
          </div>
          <div>
            <strong>Brand Approval Status</strong>: {prospect?.approvalStatus?.brand?.status || '-'}
          </div>
          <div>
            {prospect?.tubularDetails.accounts.map(t => socialIcon(t))}
          </div>
        </div>
        {!prospect.hsUserId  ? (
          <UncontrolledButtonDropdown className="mt-2">
            <DropdownToggle caret className="mr-2" color="primary" style={{ padding: '5px 25px' }}>
              Move Prospect to ...
            </DropdownToggle>
            <DropdownMenu>
              {options.map(a => (
                <DropdownItem 
                  onClick={() => showModal({
                    title: 'Udate Prospect\'s Contact',
                    message: text,
                    processRecord: async() => {
                      updateItem( a.value, prospect._id);
                    },
                    confirmButtonCaption: 'Update Prospect\'s Contact',
                  })}>
                  {a.label}
                </DropdownItem>
              ))}
              
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        ) : null}
      </div>
    );
  });

  return (
    <div>
      {items}
    </div>);
};
