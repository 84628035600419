import React, { useState } from 'react';
// import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { updateNotificationShow, updateCreatorNotification } from '../../../../../../redux/reducers/admin/NotificationsReducer';

const Component = ({ record, notification, updateRecord }) => {
  const mapStatusArray = { show: 'Show', hide: 'Hide' };
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({ value: record.selected ? 'show' : 'hide', label: mapStatusArray[record.selected ? 'show' : 'hide'] });
  const handleSelectChange = async (selectedValue) => {
    const myAbortController = new AbortController();
    setSelected(selectedValue);
    setLoading(true);
    const value = selectedValue ? selectedValue.value : '';
    if (value === '') {
      return false;
    }
    const responce = await updateNotificationShow({ notificationId: notification.id, ids: [record.id], value });
    console.log(responce);
    setLoading(false);
    updateRecord({
      creator: record,
      creatorNotification: responce.creatorNotifications.length > 0 ? responce.creatorNotifications[0] : null,
      actionUpdate: value,
    });
    return () => myAbortController.abort();
  };

  const mapStatus = Object.keys(mapStatusArray).map(key => ({ value: key, label: mapStatusArray[key] }));
  return (
    <div style={{ minWidth: '150px', maxWidth: '150px' }}>
      <Select
        isDisabled={loading}
        value={selected}
        options={mapStatus}
        onChange={handleSelectChange}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
    </div>
  );
};
Component.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecord: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
};
const mapStateToProps = state => ({
  notification: state.notifications.notification,
});
const mapDispatchToProps = dispatch => ({
  updateRecord: action => dispatch(updateCreatorNotification(action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
