import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withNotice from '../../../../App/store/with-notice';
import StripeBalanceControls from './components/StripeBalanceControls';
import ConnectedAccountsTable from './components/ConnectedAccountsTable';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';

const StripePayouts = ({addNotice}) => (
  <Container>
    <Breadcrumb
      links={[
        { title: 'Home', path: '/' },
        { title: 'Finance', path: null },
        { title: 'Payment Methods', path: null },
        { title: 'Stripe', path: null },
      ]}
      isBackButton
    />
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <h3 className="il-color-deep-blue font-weight-bold">InfluenceLogic Platform Account</h3>
            <hr className="mt-3 mb-4" />
            <StripeBalanceControls addNotice={addNotice} />
            <div className="py-4" />
            <ConnectedAccountsTable />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

StripePayouts.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  rtl: state.rtl,
});

export default withNotice(connect(mapStateToProps)(StripePayouts));
