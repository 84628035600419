/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import {
  Label,
  Spinner,
  Row,
  Col,
  List,
  ListInlineItem,
} from 'reactstrap';
import CheckBoxField from '../CheckBox';
import { PickIcon } from '../../../shared/helpers/Icons';

const BrandBadge = ({ brandData }) => {
  const getBadgeImageBadge = (bd) => {
    if (bd.status === 'In Review') {
      return (
        <>
          <Label className="bold-text"><span>In Review</span></Label>
          <FontAwesomeIcon style={{ marginBottom: '10px', marginLeft: '5px' }} icon="hourglass-half" />
        </>
      );
    } else if (bd.status === 'Rejected') {
      return (
        <>
          <Label className="bold-text"><span className="text-danger">Rejected</span></Label>
          <FontAwesomeIcon style={{ marginBottom: '10px', marginLeft: '5px' }} icon="thumbs-down" className="text-danger" />
        </>
      );
    }
    return (
      <>
        <Label className="bold-text"><span className="text-success">Approved</span></Label>
        <FontAwesomeIcon style={{ marginBottom: '10px', marginLeft: '5px' }} icon="thumbs-up" className="text-success" />
      </>
    );
  };

  const getHistory = (bd) => {
    if (!moment(bd.history).isValid()) {
      return '';
    }
    if (bd.status === 'In Review') {
      return `submitted ${moment.utc(bd.history).local().format('M/DD/YYYY')}`;
    }
    return `on ${moment.utc(bd.history).local().format('M/DD/YYYY')}`;
  };

  return (
    <div style={{ padding: '10px', width: '250px' }}>
      <div style={{
            lineHeight: '60px',
            textAlign: 'center',
           }}
      >
        {brandData.logoPath && (
        <img
          src={brandData.logoPath}
          alt={brandData.brandName}
          title={brandData.brandName}
          style={{
            width: 'auto',
            maxWidth: '180px',
            height: 'auto',
            maxHeight: '50px',
            marginBottom: '10px',
          }}
        />
      )}
        {!brandData.logoPath && (<h3 style={{ padding: '15px' }}><b>{brandData.brandName}</b></h3>)}
      </div>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {getBadgeImageBadge(brandData)}
        <span style={{ paddingLeft: '10px' }}>
          <Label className="bold-text">
            {getHistory(brandData)}
          </Label>
        </span>
      </span>
    </div>);
};

const CreatorBrandApprovalsWidget = ({
  influencerId,
}) => {
  const [loading, setLoading] = useState(true);
  const [brandApprovalsData, setBrandApprovalsData] = useState(null);
  const [approved, setApproved] = useState(true);
  const [rejected, setRejected] = useState(true);
  const [inReview, setInReview] = useState(true);

  useEffect(async () => {
    try {
      axios.get(`/api/portal/influencers/get-prospect-brand-approval-statuses?model=influencer&id=${influencerId}`).then((result) => {
        setLoading(false);
        if (result.data.success) {
          setBrandApprovalsData(result.data.approvals.sort((a, b) => ((a.brandName > b.brandName) ? 1 : -1)));
        }
      });
    } catch (e) {
      console.error(e.message);
      setLoading(false);
    }
  }, [influencerId]);

  const handleFilterChange = (event, setter) => {
    if (event && event.target) {
      const { checked } = event.target;
      setter(checked);
    }
  };

  if (loading) return <div className="text-center"><Spinner color="primary" size="lg" /></div>;

  return (
    <div>
      <h3>
        Brand Approvals & Rejections
        {brandApprovalsData?.length > 0 && (
          <>
            {'   '}
            <a
              title="Open on Internal Prospects page"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-1"
              style={{ fontSize: '22px' }}
              href={`/research/internal-prospects?${brandApprovalsData.map(p => `filters.prospectId=${p.id}`).join('&')}`}
            >
              <PickIcon />
            </a>
          </>
        )}
      </h3>
      <hr />
      <Row>
        <Col>
          <Label className="bold-text">
            Show
          </Label>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginLeft: '20px' }}>
          <List type="inline">
            <ListInlineItem>
              <CheckBoxField
                name="ApprovedBrands"
                label="Approved"
                value={approved}
                onChange={event => handleFilterChange(event, setApproved)}
              />
            </ListInlineItem>
            <ListInlineItem>
              <CheckBoxField
                name="RejectedBrands"
                label="Rejected"
                value={rejected}
                onChange={event => handleFilterChange(event, setRejected)}
              />
            </ListInlineItem>
            <ListInlineItem>
              <CheckBoxField
                name="InReviewBrands"
                label="In Review"
                value={inReview}
                onChange={event => handleFilterChange(event, setInReview)}
              />
            </ListInlineItem>
          </List>
        </Col>
      </Row>
      <Row>
        <Col style={{
                    marginLeft: '20px',
                    display: 'flex',
                    flexFlow: 'wrap',
                    gap: '10px 10px',
                   }}
        >
          {brandApprovalsData && brandApprovalsData.map((ba) => {
            if (ba.status === 'Approved' && approved) {
              return <BrandBadge key={ba.brandName} status="Approved" brandData={ba} />;
            } else if (ba.status === 'Rejected' && rejected) {
              return <BrandBadge key={ba.brandName} status="Rejected" brandData={ba} />;
            } else if (ba.status === 'In Review' && inReview) {
              return <BrandBadge key={ba.brandName} status="In Review" brandData={ba} />;
            }
            return null;
          })}
        </Col>
      </Row>
      <hr />
    </div>
  );
};

CreatorBrandApprovalsWidget.propTypes = {
  influencerId: PropTypes.string.isRequired,
};
export default CreatorBrandApprovalsWidget;
