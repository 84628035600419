/* eslint react/prop-types: 0 */
import React from 'react';
import { Card, CardBody } from 'reactstrap';
// import { PropertyPlace } from 'admin-bro/lib/backend/decorators/property-json.interface';
import PropertyType from '../property-type';
// import WrapperBox from '../ui/wrapper-box';


/**
 * @name ShowAction
 * @category Actions
 * @description Shows a given record.
 * @component
 * @private
 */
const Show = (props) => {
  const { resource, record } = props;
  const properties = resource.showProperties;

  return (
    <Card>
      <CardBody>
        {properties.map(property => (
          <PropertyType
            key={property.name}
            where="show"
            property={property}
            resource={resource}
            record={record}
          />
        ))}
      </CardBody>
    </Card>
  );
};

export default Show;
