import React from 'react';
// import PropTypes from 'prop-types';
// import { unflatten } from 'flat';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import MatTableList from '../../../../../../../shared/tables/materialTable/TableRedux';
// import { goToPage } from '@/shared/helpers/WVUtilities';
// import Hook from '../../../../../../../shared/hooks';
import CreatorInList from './CreatorInList';
import ContextMenuInList from './ContextMenuInList';
import InfluencerCompanyStatus from './InfluencerCompanyStatus';
import InfluencerStatus from './InfluencerStatus';
import CellSelect from '@/shared/components/table/CellSelect';
import {setUrlParamsForTable } from "../../../../../../../redux/reducers/TableReducerDefault";
import Hook from '@/shared/hooks';

const Table = React.memo(({ categories, affiliateManagersSuggestions }) => {
  const dispatch = useDispatch();
  const { showError } = Hook.useNoticeHook();
  const perPage = useSelector(state => state.tableRedux.perPage);
  console.log('perPage', perPage)
  const setDefaultSort = () => {
    dispatch(setUrlParamsForTable({
      page: 1,
      perPage,
      direction: 'desc',
      sortBy: 'createdAt',
    }));
  };

  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Creators Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'fullName',
          Header: () => 'Creator Name',
          accessor: record => (record?.params?.influencerObject?.first_name || ''),
          // eslint-disable-next-line max-len
          Cell: p => (<CreatorInList record={p.row.original}/>),
          width: 250,
          className: 'align-self-center',
        },
        {
          id: 'category',
          Header: () => 'Category',
          accessor: record => (record?.params?.influencerObject?.email || ''),
          width: 150,
          className: 'align-self-center',
          Cell: p => (
            <CellSelect
              name="category"
              label="Category"
              record={p.row.original}
              values={categories}
              update="/api/portal/influencer/post-update-category"
              updateRecordFunction={p.updateRecordFunction}
              afterSaveFunc={({ data: { success, error } }) => {
                console.log(success, 'SOME');
                if (!success) {
                  showError(error || 'Unable To update Category');
                }
              }}
            />
          ),
        },
        {
          id: 'status',
          Header: () => 'Status',
          accessor: record => (record?.params?.status || ''),
          Cell: p => (<InfluencerStatus record={p.row.original}/>),
          width: 150,
          className: 'align-self-center',
        },
        {
          id: 'companyStatus',
          Header: () => 'Company Status',
          accessor: record => (record?.params?.status || ''),
          Cell: p => (<InfluencerCompanyStatus record={p.row.original}/>),
          width: 150,
          className: 'align-self-center',
        },
        {
          id: 'taxForm',
          Header: () => 'Tax Form',
          accessor: record => (record?.params?.taxForm || ''),
          width: 150,
          className: 'align-self-center',
        },
        {
          id: 'contactOwnerName',
          width: 150,
          Header: () => 'Contact Owner',
          accessor: record => (record?.params?.contactOwnerName || ''),
          className: 'align-self-center',
        },
        {
          id: 'affiliateDataObject.account_manager_id',
          width: 150,
          Header: () => 'Account Manager',
          className: 'align-self-center',
          accessor: record => (record?.params.affiliateDataObject?.account_manager_id || ''),
          Cell: (p) => {
            if (p.row.original.params.affiliateDataObject?.account_manager_id && affiliateManagersSuggestions.length > 0) {
              const manager = affiliateManagersSuggestions.find(affiliateManagersSuggestion => affiliateManagersSuggestion.id === p.row.original.params.affiliateDataObject.account_manager_id);
              if (manager && manager.name) return manager.name;
            }
            return '';
          },
        },
          
        {
          id: 'actions',
          Header: () => (
            <Tooltip title="Show new Creators first">
              <IconButton
                id="sortCreatorsButton"
                size="small"
                onClick={() => setDefaultSort()}
                className="material-table__toolbar-button text-right"
                style={{
                  width: '25px',
                  height: '23px',
                }}
              >
                <ClockOutlineIcon size="23" />
              </IconButton>
            </Tooltip>
          ),
          accessor: () => null,
          Cell: p => (
            <ContextMenuInList
              record={p.row.original}
            />
          ),
          disableSortBy: true,
          className: 'align-self-center',
          width: 50,
          maxWidth: 50,
        },
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
