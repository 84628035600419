import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  FormGroup,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import withNotice from '../../../../../../App/store/with-notice';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import RadioButton from '../../../../../../../shared/components/RadioButton';

const defaultOption = { value: 'Needs Review', label: 'Needs Review' };

const BulkUpdateMediaContentURLStatusModal = ({
  isOpen,
  closeFunc,
  setVersion,
  mediaContentIds,
  total,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedUrlStatus, setSelectedUrlStatus] = useState(defaultOption);
  const [includeAll, setIncludeAll] = useState(false);

  const handleChange = async (selectedItem) => {
    setSelectedUrlStatus(selectedItem);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedUrlStatus(defaultOption);
      setIncludeAll(false);
    }
  }, [isOpen]);

  // SUBMIT Data
  const submit = async () => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search);
    axios({
      method: 'post',
      url: '/api/portal/scorecard/media-content/update-url-status',
      data: {
        ids: mediaContentIds,
        urlStatus: selectedUrlStatus.value,
        includeAll,
        query: query.toString(),
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        closeFunc();
      }
    });
    return true;
  };

  const totalToAssign = Math.min(total, 500);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '600px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Update URL Status</ModalHeader>
      <ModalBody>
        {mediaContentIds.length !== total && (
          <>
            <div>{`You have selected ${mediaContentIds.length} result${mediaContentIds.length !== 1 ? 's' : ''} to update URL Status`}</div>
            <div className="theme-light radio-in-prospects mt-2">
              {`Select ${totalToAssign === total ? 'all of' : 'the first'} ${WVFormatter.formatIntNumber(totalToAssign)} results?`}&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="action"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                name="action"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>
          </>
        )}
        <Row>
          <Col>
            <FormGroup>
              <Label className="bold-text">New URL Status</Label>
              <Select
                value={selectedUrlStatus}
                options={[
                  { value: 'Needs Review', label: 'Needs Review' },
                  { value: 'Pending Publication', label: 'Pending Publication' },
                  { value: 'Needs Content URL', label: 'Needs Content URL' },
                  { value: 'Approved', label: 'Approved' },
                  { value: 'Inactive', label: 'Inactive' },
                ]}
                onChange={handleChange}
                placeholder=""
                isDisabled={!!loading}
                menuPlacement="auto"
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};


BulkUpdateMediaContentURLStatusModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  mediaContentIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default withNotice(BulkUpdateMediaContentURLStatusModal);
