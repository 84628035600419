import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import { clearFilterProperty } from '../../../../../../redux/reducers/TableReducerDefault';
import Hook from '../../../../../../shared/hooks';
import DashboardActions from './Table/DashboardActions'




const Actions = React.memo(({
  downloadCsv,
}) => {
  console.log('RELOAD ACRIONS');
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState(getCookie('BrandUserFilters') === 'true');

  const [search, setSearch] = Hook.useTableFilterHookString('fullSearchName');
  const [roleFilter, handleRoleFilters] = Hook.useTableFilterHookCheckbox('role');


  useEffect(() => {
    setCookie('BrandUserFilters', showFilters.toString(), 35);
  }, [showFilters]);
  const resetFilters = () => {
    dispatch(clearFilterProperty());
  };
  return (
    <>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Email, Business Name, First Name, Last Name, Title, Advertiser"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '44px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col md={2} className="text-right">
          <DashboardActions  downloadCsv={downloadCsv}/>
        </Col>
      </Row>
      { showFilters && (
        <Row className="pb-3">
          <Col sm={4}>
            <Row>
              <Col sm="auto">
                <Label className="bold-text">
                  Partner Status
                </Label>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="Owner"
                    label="Owner"
                    value={roleFilter.owner || false}
                    onChange={event => handleRoleFilters(event, 'owner')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="Auditors1"
                    label="Auditor"
                    value={roleFilter.auditor || false}
                    onChange={event => handleRoleFilters(event, 'auditor')}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
});
Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
};

export default Actions;
