import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import { uid } from 'react-uid';
// import PropTypes from 'prop-types';
// import DatePicker from 'react-datepicker';
import Tooltip from '@material-ui/core/Tooltip';

import { Row, Col, Button, Nav, NavLink, NavItem, TabContent, TabPane, Modal, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import formater from '../../../../shared/helpers/WVFormatter';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';


const calculateForDeal = (deal) => {
  let a = deal.payables.reduce((accumulator, currentValue) => {
    // eslint-disable-next-line
    accumulator += Number(currentValue.profit || 0);
    return accumulator;
  }, 0);
  if (deal.bill) {
    a -= Number(deal.billTotal);
  }
  if (deal.currentMonthPayout) {
    a -= Number(deal.currentMonthPayout);
  }
  if (deal.currentMonthRevenue) {
    a += Number(deal.currentMonthRevenue);
  }
  return Number(a || 0);
};

const SummaryReport = ({
  loading, selectedAdvertisers, items, selectedOffer, employees, conversions,
}) => {
  const [csp, setCsp] = useState([]);
  const [influencers, setInfluencers] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [showId, setShowId] = useState(null);
  const getTable = (obj) => {
    const influencersArray = {};
    const cspArray = {};
    console.log(selectedAdvertisers?.value);
    console.log(selectedOffer?.value);
    // console.log(array);
    Object.keys(obj).forEach((key) => {
      console.log(key, obj[key]);
      const array = obj[key].items;
      array
        .filter(i => (!selectedAdvertisers?.value || (
          (!selectedOffer?.value || i.offer._id === selectedOffer.value)
          && (i.offer.advertiser?._id === selectedAdvertisers.value || i.offer.advertiser === selectedAdvertisers.value))
        ))
        .forEach((deal) => {
          if (!deal.influencer) return false;
          if (!influencersArray[deal.tuneId]) {
            influencersArray[deal.tuneId] = {
              deals: { minus3MonthReport: [], minus2MonthReport: [], minus1MonthReport: [] },
              tuneId: deal.tuneId,
              influencer: deal.influencer,
              grossProfit: { minus3MonthReport: 0, minus2MonthReport: 0, minus1MonthReport: 0 },
              offer: deal.offer._id,
              advertiser: deal.offer.advertiser,
              conversions: 0,
              conversionsByDeal: {},
            };
          }
          const currentConversions = Number(conversions[`${deal.tuneId}-${deal.offer.tuneId}`] || 0);

          influencersArray[deal.tuneId].grossProfit[key] += calculateForDeal(deal);
          influencersArray[deal.tuneId].deals[key].push(deal);
          influencersArray[deal.tuneId].conversionsByDeal[`${deal.tuneId}-${deal.offer.tuneId}`] = currentConversions || 0;
          if (!cspArray[deal.accountManagerId]) {
            cspArray[deal.accountManagerId] = {
              deals: { minus3MonthReport: [], minus2MonthReport: [], minus1MonthReport: [] },
              tuneId: deal.accountManagerId,
              influencer: deal.influencer,
              grossProfit: { minus3MonthReport: 0, minus2MonthReport: 0, minus1MonthReport: 0 },
              offer: deal.offer._id,
              advertiser: deal.offer.advertiser,
              account_manager_id: deal.accountManagerId,
              conversions: 0,
              conversionsByDeal: {},
            };
          }
          cspArray[deal.accountManagerId].grossProfit[key] += calculateForDeal(deal);
          cspArray[deal.accountManagerId].deals[key].push(deal);
          cspArray[deal.accountManagerId].conversions += currentConversions || 0;
          cspArray[deal.accountManagerId].conversionsByDeal[`${deal.tuneId}-${deal.offer.tuneId}`] = currentConversions || 0;
          // influencersArray[deal.tuneId].conversions = influencersArray[deal.tuneId].conversions.concat();
          return true;
        });
    });
    console.log([Object.values(influencersArray), Object.values(cspArray)]);
    return [Object.values(influencersArray), Object.values(cspArray)];
  };
  useEffect(() => {
    const [influencersArray, cspArray] = getTable(items);
    setInfluencers(influencersArray);
    setCsp(cspArray);
    // setVersion((new Date()).toString());
  }, [selectedAdvertisers, items, selectedOffer]);

  console.log('report', items);
  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'base',
    direction: 'desc',
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Creator',
        accessor: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
        id: 'Creator',
        Cell: p => useMemo(() => (
          <Tooltip title="Open Creator Profile">
            <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.influencer?._id}`}>{p.value}</a>
          </Tooltip>), [p.row.original]),
        width: 600,
      },
      {
        Header: 'Conversions Count',
        accessor: deal => Object.values(deal.conversionsByDeal).reduce((previousValue, currentValue) => previousValue + currentValue) || 0,
        id: 'conversions',
        Cell: ({ value }) => value,
        width: 300,
      },
      {
        Header: 'Base',
        accessor: deal => (((deal.grossProfit.minus1MonthReport * 0.75) + (deal.grossProfit.minus2MonthReport * 0.5) + (deal.grossProfit.minus3MonthReport * 0.25)) / 3),
        id: 'base',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        width: 300,
      },
      {
        Header: '',
        accessor: p => p?.influencer?._id,
        id: 'action',
        disableGlobalFilter: true,
        width: 65,
        Cell: ({ value }) => useMemo(() => (
          <Tooltip title="Open Calculation Modal">
            <IconButton
              size="small"
              onClick={() => setShowId(value)}
              className="material-table__toolbar-button"
            >
              <InformationOutlineIcon size="15" />
            </IconButton>
          </Tooltip>
        ), [value]),
        disableSortBy: true,
      },
    ],
    [influencers],
  );
  const columnsCsp = useMemo(
    () => [
      {
        Header: 'CSP',
        accessor: (deal) => {
          const empl = employees.find(e => e.tuneId?.toString() === deal.account_manager_id?.toString());
          return `${empl?.firstName || ''} ${empl?.lastName || ''}`;
        },
        id: 'CSP',
        Cell: ({ value }) => value,
        width: 500,
      },
      {
        Header: 'Conversions Count',
        accessor: deal => Object.values(deal.conversionsByDeal).reduce((previousValue, currentValue) => previousValue + currentValue) || 0,
        id: 'conversions',
        Cell: ({ value }) => value,
        width: 300,
      },
      {
        Header: 'Base',
        accessor: deal => (((deal.grossProfit.minus1MonthReport * 0.75) + (deal.grossProfit.minus2MonthReport * 0.5) + (deal.grossProfit.minus3MonthReport * 0.25)) / 3),
        id: 'base',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        width: 300,
      },
    ],
    [csp, employees],
  );

  const selectedDeals1 = showId ? (influencers.find(d => d.influencer?._id === showId)).deals.minus1MonthReport : [];
  const selectedDeals2 = showId ? (influencers.find(d => d.influencer?._id === showId)).deals.minus2MonthReport : [];
  const selectedDeals3 = showId ? (influencers.find(d => d.influencer?._id === showId)).deals.minus3MonthReport : [];
  const calculateDealTotal = () => {
    let total = 0;
    selectedDeals1.map((d) => {
      total += calculateForDeal(d) * 0.75;
      return true;
    });
    selectedDeals2.map((d) => {
      total += calculateForDeal(d) * 0.5;
      return true;
    });
    selectedDeals3.map((d) => {
      total += calculateForDeal(d) * 0.25;
      return true;
    });
    return total;
  };
  return (
    <div>
      {items.minus1MonthReport?.items?.length > 0 ? (
        <Row className="mt-3">
          <Col className="tabs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    setActiveTab('1');
                  }}
                >
                  Creators
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    setActiveTab('2');
                  }}
                >
                  CSP
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>) : null
      }
      {items.minus1MonthReport?.items?.length > 0 ? (
        <Row>
          <Col>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col>
                    {influencers.length > 0 ? (
                      <div className="float-right mr-2 mt-3">
                        <DownloadCSVButton
                          records={influencers}
                          className="mt-2"
                          fileName="Base.csv"
                          csvArrayColumns={[
                            {
                             label: 'Creator',
                             func: deal => `${deal.influencer?.first_name || ''} ${deal.influencer?.last_name || ''} `,
                           }, {
                            label: 'Conversion Counts',
                            func: deal => Object.values(deal.conversionsByDeal).reduce((previousValue, currentValue) => previousValue + currentValue) || 0,
                          }, {
                             label: 'Base',
                             func: deal => (((deal.grossProfit.minus1MonthReport * 0.75) + (deal.grossProfit.minus2MonthReport * 0.5) + (deal.grossProfit.minus3MonthReport * 0.25)) / 3),
                           },
                         ]}
                        >
                          Download CSV
                        </DownloadCSVButton>
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    {!loading && influencers.length > 0 ? (
                      <ReactTableBase
                        key="searchable"
                        columns={columns}
                        data={influencers}
                        tableConfig={tableConfig}
                      />) : null
                    }
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col>
                    {csp.length > 0 ? (
                      <div className="float-right mr-2 mt-3">
                        <DownloadCSVButton
                          records={csp}
                          className="mt-2"
                          fileName="Base.csv"
                          csvArrayColumns={[
                            {
                              label: 'CSP',
                              func: (deal) => {
                                const empl = employees.find(e => e.tuneId?.toString() === deal.account_manager_id?.toString());
                                return `${empl?.firstName || ''} ${empl?.lastName || ''}`;
                              },
                            }, {
                              label: 'Conversion Counts',
                              func: deal => Object.values(deal.conversionsByDeal).reduce((previousValue, currentValue) => previousValue + currentValue) || 0,
                            }, {
                              label: 'Base',
                              func: deal => (((deal.grossProfit.minus1MonthReport * 0.75) + (deal.grossProfit.minus2MonthReport * 0.5) + (deal.grossProfit.minus3MonthReport * 0.25)) / 3),
                            },
                          ]}
                        >
                          Download CSV
                        </DownloadCSVButton>
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    {!loading && csp.length > 0 ? (
                      <ReactTableBase
                        key="searchable2"
                        columns={columnsCsp}
                        data={csp}
                        tableConfig={tableConfig}
                      />) : null
                    }
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>) : null
      }
      <Modal
        isOpen={!!showId}
        toggle={() => setShowId(null)}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Payment History</h4>
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body ltr-support">
          {selectedDeals1.length > 0 ? moment().subtract(1, 'month').format('MMMM YYYY') : ''}
          {selectedDeals1.map(d => (
            <div key={uid(d.hs_object_id)}>
              <h4><a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${d.hs_object_id}/`} target="_blank" rel="noreferrer" >{d.dealname}</a></h4>
              <h5>Revenue calculated from HasOffers (Current Month): {formater.formatCurrency(d.currentMonthRevenue || 0)}</h5>
              <h5>Payout calculated from HasOffers (Current Month): {formater.formatCurrency(d.currentMonthPayout || 0)}</h5>
              <h5>Invoice total payout {formater.formatCurrency(d.invoiceTotal || 0)}</h5>
              <h5>Bill total payout {formater.formatCurrency(d.billTotal || 0)}</h5>
              <h5>Accounts Payable Revenue {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.revenueAmount || 0);
                    return accumulator;
                  }, 0) || 0)}
              </h5>
              <h5>Accounts Payable Payout {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.ilPayoutAmount || 0);
                    return accumulator;
                  }, 0) || 0)}
              </h5>
              <span><strong>Profit:</strong>{' '}
                ({formater.formatCurrency(d.currentMonthRevenue || 0)} -{' '}
                {formater.formatCurrency(d.currentMonthPayout || 0)} -{' '}
                {formater.formatCurrency(d.billTotal || 0)} +{' '}
                {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.revenueAmount || 0);
                    return accumulator;
                  }, 0) || 0)} -{' '}
                {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                  // eslint-disable-next-line
                  accumulator += Number(currentValue.ilPayoutAmount || 0);
                  return accumulator;
                }, 0) || 0)}{' '}) {' '}
                = {formater.formatCurrency(calculateForDeal(d))} * 0.75 = {formater.formatCurrency(calculateForDeal(d) * 0.75)}
              </span>
              <hr />
            </div>
          ))}
          {selectedDeals2.length > 0 ? moment().subtract(2, 'month').format('MMMM YYYY') : ''}
          {selectedDeals2.map(d => (
            <div key={uid(d.hs_object_id)}>
              <h4><a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${d.hs_object_id}/`} target="_blank" rel="noreferrer" >{d.dealname}</a></h4>
              <h5>Revenue calculated from HasOffers (Current Month): {formater.formatCurrency(d.currentMonthRevenue || 0)}</h5>
              <h5>Payout calculated from HasOffers (Current Month): {formater.formatCurrency(d.currentMonthPayout || 0)}</h5>
              <h5>Invoice total payout {formater.formatCurrency(d.invoiceTotal || 0)}</h5>
              <h5>Bill total payout {formater.formatCurrency(d.billTotal || 0)}</h5>
              <h5>Accounts Payable Revenue {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.revenueAmount || 0);
                    return accumulator;
                  }, 0) || 0)}
              </h5>
              <h5>Accounts Payable Payout {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.ilPayoutAmount || 0);
                    return accumulator;
                  }, 0) || 0)}
              </h5>
              <span><strong>Profit:</strong>{' '}
                ({formater.formatCurrency(d.currentMonthRevenue || 0)} -{' '}
                {formater.formatCurrency(d.currentMonthPayout || 0)} -{' '}
                {formater.formatCurrency(d.billTotal || 0)} +{' '}
                {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.revenueAmount || 0);
                    return accumulator;
                  }, 0) || 0)} -{' '}
                {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                  // eslint-disable-next-line
                  accumulator += Number(currentValue.ilPayoutAmount || 0);
                  return accumulator;
                }, 0) || 0)}{' '}) {' '}
                = {formater.formatCurrency(calculateForDeal(d))} * 0.5 = {formater.formatCurrency(calculateForDeal(d) * 0.5)}
              </span>
              <hr />
            </div>
          ))}
          {selectedDeals3.length > 0 ? moment().subtract(3, 'month').format('MMMM YYYY') : ''}
          {selectedDeals3.map(d => (
            <div key={uid(d.hs_object_id)}>
              <h4><a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${d.hs_object_id}/`} target="_blank" rel="noreferrer" >{d.dealname}</a></h4>
              <h5>Revenue calculated from HasOffers (Current Month): {formater.formatCurrency(d.currentMonthRevenue || 0)}</h5>
              <h5>Payout calculated from HasOffers (Current Month): {formater.formatCurrency(d.currentMonthPayout || 0)}</h5>
              <h5>Invoice total payout {formater.formatCurrency(d.invoiceTotal || 0)}</h5>
              <h5>Bill total payout {formater.formatCurrency(d.billTotal || 0)}</h5>
              <h5>Accounts Payable Revenue {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.revenueAmount || 0);
                    return accumulator;
                  }, 0) || 0)}
              </h5>
              <h5>Accounts Payable Payout {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.ilPayoutAmount || 0);
                    return accumulator;
                  }, 0) || 0)}
              </h5>
              <span><strong>Profit:</strong>{' '}
                ({formater.formatCurrency(d.currentMonthRevenue || 0)} -{' '}
                {formater.formatCurrency(d.currentMonthPayout || 0)}  -{' '}
                {formater.formatCurrency(d.billTotal || 0)} +{' '}
                {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                    // eslint-disable-next-line
                    accumulator += Number(currentValue.revenueAmount || 0);
                    return accumulator;
                  }, 0) || 0)} -{' '}
                {formater.formatCurrency(d.payables.reduce((accumulator, currentValue) => {
                  // eslint-disable-next-line
                  accumulator += Number(currentValue.ilPayoutAmount || 0);
                  return accumulator;
                }, 0) || 0)}{' '}) {' '}
                = {formater.formatCurrency(calculateForDeal(d))} * 0.25 = {formater.formatCurrency(calculateForDeal(d) * 0.25)}
              </span>
              <hr />
            </div>
          ))}
          <h4>Total:</h4>
          ({selectedDeals1.map((d, index, array) => (
            <span>{formater.formatCurrency(calculateForDeal(d) * 0.75)} {index !== (array.length - 1) ? '+' : ''}</span>
          ))}
          {Object.keys(selectedDeals1).length === 0 ? '0' : ''}
          {' '}+{' '}
          {selectedDeals2.map((d, index, array) => (
            <span>{formater.formatCurrency(calculateForDeal(d) * 0.5)} {index !== (array.length - 1) ? '+' : ''}</span>
          ))}
          {Object.keys(selectedDeals2).length === 0 ? '0' : ''}
          {' '}+{' '}
          {selectedDeals3.map((d, index, array) => (
            <span>{formater.formatCurrency(calculateForDeal(d) * 0.25)} {index !== (array.length - 1) ? '+' : ''}</span>
          ))}
          {Object.keys(selectedDeals3).length === 0 ? '0' : ''}
          ) / 3
          = {formater.formatCurrency(calculateDealTotal() / 3)}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button color="danger" onClick={() => setShowId(null)}>Close</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};
SummaryReport.propTypes = {
  loading: PropTypes.bool.isRequired,
  selectedAdvertisers: PropTypes.objectOf(PropTypes.any),
  selectedOffer: PropTypes.objectOf(PropTypes.any),
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  employees: PropTypes.arrayOf(PropTypes.any).isRequired,
  conversions: PropTypes.objectOf(PropTypes.any).isRequired,
};
SummaryReport.defaultProps = {
  selectedAdvertisers: null,
  selectedOffer: null,
};

export default withNotice(SummaryReport);
