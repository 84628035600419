import React from 'react';
import PropTypes from 'prop-types';
import { formatIntNumber } from '../../../../shared/helpers/WVFormatter';

const AxisTick = ({
  x,
  y,
  dy,
  payload,
  textAnchor,
  formatLabel,
}) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={dy} textAnchor={textAnchor} fill="#666">
      {formatLabel(payload.value)}
    </text>
  </g>
);

AxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  dy: PropTypes.number,
  payload: PropTypes.objectOf(PropTypes.any),
  textAnchor: PropTypes.string,
  formatLabel: PropTypes.func,
};

AxisTick.defaultProps = {
  x: null,
  y: null,
  dy: 0,
  payload: null,
  textAnchor: 'end',
  formatLabel: formatIntNumber,
};

export default AxisTick;
