import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { resetObject, parseUrl } from '../../../../../../shared/helpers/WVUtilities';
import Panel from '../../../../../../shared/components/filter/Panel';
import {
  setUrl,
  searchUrl,
} from '../../../../../../shared/helpers/Filter';

const Actions = ({
  history,
  addRow,
}) => {
  const name = 'AgenciesList';

  const [_filters, setFilters] = useState({
    filters: parseUrl(),
  });

  const { filters } = _filters;

  const ref = useRef(true);

  const updateFilters = (newFilters, params) => {
    setFilters({
      filters: { ..._filters.filters, ...newFilters },
    });
  };

  useEffect(() => {
    if (ref.current) {
      ref.current = false;
    } else { 
      setTimeout(() => setUrl(_filters.filters, ref, history), 1000);
    }
  }, [_filters]);

  const resetFilters = () => {
    setFilters({
      filters: resetObject(filters),
    });
  };

  return (
    <div>
      <Panel
        name={name}
        search={filters.fullSearchName || ''}
        onSearchChange={val => updateFilters({ fullSearchName: val })}
        searchPlaceholder="Search Agency Name, Contact Name or Contact Email"
        reset={resetFilters}
        actions={[
          {
            onClick: () => addRow(),
            label: 'Add Agency',
          },
          {
            onClick: () => window.open(`/api/resources/Agency/actions/csv?${searchUrl(filters, {}, 'perPage=500000')}`, '_blank').focus(),
            label: 'Download CSV',
          },
        ]}
      />
    </div>
  );
};

Actions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  addRow: PropTypes.func.isRequired,
};

export default Actions;
