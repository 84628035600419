
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { uid } from 'react-uid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import formater from '../../../../shared/helpers/WVFormatter';
import { setCache, getCache } from '../../../../shared/helpers/LocalStorageCache';
import { getMediaChannel } from '../../../../shared/helpers/models/HubilContact';
import TableCellContextMenu from '../../../../shared/components/table/TableCellContextMenu';
import socialIcon from '../../../../shared/components/icons/Social';
import { showModalById } from '../../../../redux/reducers/TableReducer';
import { modalId } from './CreatorModal';
// getCache(`${influencerId}-cachedreport`);
// getCache(`${influencerId}-cachedreport-${startDate}${endDate}`)
export default ({ startDate, endDate, admins }) => {
  const dispatch = useDispatch();
  return [
    {
      Header: 'Creator',
      accessor: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
      id: 'Creator',
      Cell: p => useMemo(() => (
        <div>
          <Tooltip title="Open Creator Profile">
            <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.influencer?._id}`}>{p.value}</a>
          </Tooltip>
          {p.row.original.influencer?.hsContactVid ? (
            <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.row.original?.influencer.hsContactVid}/`} target="_blank" rel="noreferrer">
              <Tooltip title="Open Hubspot Contact">
                <IconButton size="small">
                  <i className="fab fa-hubspot" style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </a>) : null}
          {p.row.original.deals?.length ? (
            <Tooltip title="Open Creator Performance By Deal">
              <IconButton
                size="small"
                onClick={() => {
                  setCache(`${p.row.original.influencer._id}-cachedreport-${startDate}${endDate}`, p.row.original);
                  const win = window.open(`/reports/creators-report-deals/${p.row.original.influencer._id}/${startDate}/${endDate}`, '_blank');
                  console.log(`${p.row.original.influencer._id}-cachedreport-${startDate}${endDate}`);
                  console.log(getCache(`${p.row.original.influencer._id}-cachedreport-${startDate}${endDate}`));
                  win.focus();
                }}
                className="material-table__toolbar-button"
              >
                <OpenInNewIcon size="15" />
              </IconButton>
            </Tooltip>) : null}
          {p.row.original.prospect?.tubularDetails?.accounts?.length > 0 ? (
            <div>
              {p.row.original.prospect?.tubularDetails.accounts.map(t => socialIcon(t))}
            </div>
          ) : (
            <div>
              {['youtube', 'facebook', 'instagram', 'twitter', 'tiktok', 'podcast', 'twitch'].map(platform => socialIcon(getMediaChannel(p.row.original.influencer, platform)))}
            </div>
          )}
        </div>
      ), [p.row.original]),
      width: 300,
    },
    {
      Header: 'Account Manager',
      accessor: p => ((p.adminsAF.map(d => `${d?.firstName || ''} ${d?.lastName || ''}`)).join('')),
      id: 'owner',
      Cell: p => useMemo(() => (
        <ul className="list-unstyled">
          {p.row.original.adminsAF.map(d => (
            <li key={uid(d._id)}>
              {d?.firstName || ''} {d?.lastName || ''}
            </li>))}
        </ul>), [p.row.original]),
      width: 200,
    },
    {
      Header: 'Gross Revenue',
      accessor: deal => (deal.grossRevenue || 0),
      id: 'revenue',
      Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
      Footer: (info) => {
        const totalAge = useMemo(
          () => info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
          [info.rows],
        );
        return <span>{formater.formatCurrency(totalAge)}</span>;
      },
      width: 120,
    },
    {
      Header: 'Gross Profit',
      accessor: deal => (deal.grossProfit || 0),
      id: 'profit',
      Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
      Footer: (info) => {
        const totalAge = useMemo(
          () => info.rows.reduce((sum, row) => Number(row.values.profit) + sum, 0),
          [info.rows],
        );
        return <span>{formater.formatCurrency(totalAge)}</span>;
      },
      width: 120,
    },
    {// 2hearts1seoul
      Header: 'Revenue Conversions',
      accessor: p => Number(p.cpmConversionsCount || 0) + Number(p.conversions || 0),
      id: 'conversions',
      Cell: ({ value, row }) => {
        // console.log('123')
        // console.error('in table', value, row.original);
        const conversions = [];
        row.original.deals.forEach((curr) => {
          conversions.push(...curr.conversionsArray);
        }, {});
        // console.log(conversions, admins);
        const ars = conversions?.reduce((acc, curr) => {
          const admin = admins.find(a => Number(a.affiliateManagerId) === Number(curr.affiliate_manager_id));
          // console.log(admin, curr.affiliate_manager_id)
          if(!admin) return acc;
          if (!acc[admin._id]) { acc[admin._id] = { conversions: [curr], adminAF: admin }; return acc; }
          acc[admin._id].conversions.push(curr);
          return acc;
        }, {}) || {};
        const admin = row.original?.adminsAF ? row.original?.adminsAF[0] : null;
        if(!ars[admin?._id] && admin && row.original.cpmConversionsCount) ars[admin?._id] = { conversions: row.original.cpmConversionsCount, adminAF: admin };

        const adminAFid = admin?._id;
        console.log(row.original, Object.keys(ars || {}), admin, 'Object.keys(ars || {})')
        if (Number(value || 0) > 0 && (Object.values(ars || {}).length > 1 || !Object.keys(ars || {}).includes(adminAFid))) {

          const items = Object.values(ars || {}).map(a => `${a.adminAF?.firstName || ''} ${a.adminAF?.lastName || ''}: ${formater.formatIntNumber(a.conversions?.length || 0)}`)
          return (<div>
            Total: {formater.formatIntNumber(value || 0)}
            <ul>{items.map(a => (<li>{a}</li>))}</ul>
          </div>)
        }
        
        return formater.formatIntNumber(value || 0)
      
      },
      width: 120,
      Footer: (info) => {
        const totalAge = useMemo(
          () => info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
          [info.rows],
        );
        return <span>{formater.formatIntNumber(totalAge)}</span>;
      },
    },
    {
      Header: 'Clicks',
      accessor: p => (p?.clicks || 0),
      id: 'clicks',
      Cell: ({ value }) => useMemo(() => formater.formatIntNumber(value || 0), [value]),
      width: 120,
    },
    {
      Header: 'Category',
      accessor: p => p?.influencer?.category || '',
      id: 'category',
      Cell: ({ value }) => value,
      width: 120,
    },
    {
      Header: '',
      accessor: '_id',
      // eslint-disable-next-line react/prop-types
      Cell: p => (
        <div style={{ textAlign: 'right' }}><TableCellContextMenu
          options={[
            {
              label: 'View Creator Profile',
              handler: () => {
                const win = window.open(`/influencer/profile/${p.row.original.influencer?._id}`, '_blank');
                win.focus();
              },
            },
            {
              label: 'View HubSpot Contact',
              handler: () => {
                const win = window.open(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.row.original?.influencer.hsContactVid}/`, '_blank');
                win.focus();
              },
            },
            { label: 'Open Calculations Modal', handler: () => { dispatch(showModalById(modalId, { creator: p.row.original })); } },
            {
              label: 'Open Creator Performance By Deal',
              handler: () => {
                setCache(`${p.row.original.influencer._id}-cachedreport-${startDate}${endDate}`, p.row.original);
                const win = window.open(`/reports/creators-report-deals/${p.row.original.influencer._id}/${startDate}/${endDate}`, '_blank');
                console.log(`${p.row.original.influencer._id}-cachedreport-${startDate}${endDate}`);
                console.log(getCache(`${p.row.original.influencer._id}-cachedreport-${startDate}${endDate}`));
                win.focus();
              },
            },
          ]}
        />
        </div>),
      disableGlobalFilter: true,
      disableSortBy: true,
      minWidth: 50,
      maxWidth: 50,
    },
  ];
};

