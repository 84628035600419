import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Badge, Modal, ButtonToolbar, Button } from 'reactstrap';
import moment from 'moment-timezone';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HistoryIcon from 'mdi-react/HistoryIcon';
import ReactTableBase from '../../../../../../shared/tables/table/ReactTableBase';
import WVFormatter from '../../../../../../shared/helpers/WVFormatter';

const columns = [
  {
    Header: 'Date (ET)',
    accessor: record => moment(record.params.createdAt || ''),
    id: 'paymentStatus',
    Cell: p => (p.value ? WVFormatter.formatToEST(p.value).format('MM/DD/YY hh:mma') : ''),
    maxWidth: 70,
    width: 70,
  },
  {
    Header: 'Description',
    minWidth: 170,
    accessor: record => record.params.description || '',
    Cell: p => (<span style={{ wordWrap: 'break-word' }} className="px-1">{p.value}</span>),
  },
  {
    Header: () => (<div>Method<br /> <Badge color="warning" style={{ fontSize: '60%' }}>Offline Payment</Badge> <Badge style={{ fontSize: '60%' }} color="success">System Payout</Badge></div>),
    id: 'method',
    accessor: record => record.params.method,
    Cell: p => (<Badge color={p.row.original?.params.isOfflinePayment === 'yes' ? 'warning' : 'success'} style={{ fontSize: '75%' }}>{WVFormatter.capitalize(p.value)}</Badge>),
    maxWidth: 80,
  },
  {
    Header: 'Status',
    accessor: record => record.params.status,
    Cell: p => (p.value === 'Processed' ? (<Badge color="success" style={{ fontSize: '75%' }}>Processed</Badge>) : (<Badge color="danger" style={{ fontSize: '75%' }}>Failed</Badge>)),
    maxWidth: 50,
  },
  {
    Header: 'Notes',
    id: 'notes',
    accessor: record => record.params.details || '',
    Cell: p => (<span style={{ wordWrap: 'break-word' }} className="px-1">{p.value}</span>),
  },
];

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: false,
  isSelectable: false,
  withPagination: true,
  withSearchEngine: true,
  manualPageSize: [5, 10],
  placeholder: 'Search...',
};


class ShowPayableJournals extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showHistory: false,
    };
  }

  toggleHistoryModal = (event) => {
    event.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  }
  // const { payment } = recordUnflatten.params;
  // if (payment.billCom.accountId) {
  //   return '';
  // }
  render() {
    const { showHistory } = this.state;
    const { payable } = this.props;
    const hasJournals = payable.populated && payable.populated.payableJournals && payable.populated.payableJournals.length;
    const { payableJournals } = payable.populated;
    const payableJournal = payableJournals && payableJournals.length ? payableJournals[payableJournals.length - 1] : null;
    return (
      <div>
        <span>
          {!hasJournals ?
          (
            <IconButton
              size="small"
              className="material-table__toolbar-button"
              disabled
            >
              <HistoryIcon size="15" />
            </IconButton>) :
          (
            <Tooltip title="View Payable Journals">
              <IconButton
                size="small"
                className={`material-table__toolbar-button ${payableJournal.params.isOfflinePayment === 'yes' ? 'text-warning' : 'text-success'}`}
                onClick={this.toggleHistoryModal}
                disabled={false}
              >
                <HistoryIcon size="15" />
              </IconButton>
            </Tooltip>)}
        </span>
        { /* HISTORY MODAL */ }
        {
          hasJournals &&
          <Modal isOpen={showHistory} toggle={this.toggleHistoryModal} >
            <div className="modal__header">
              <h4 className="text-modal  modal__title">Payment History</h4>
            </div>
            <div className="ltr-support theme-light">
              <ReactTableBase
                key="searchable"
                columns={columns}
                data={payable.populated.payableJournals}
                tableConfig={tableConfig}
              />
            </div>
            <ButtonToolbar className="modal__footer">
              <Button color="danger" onClick={this.toggleHistoryModal}>Close</Button>
            </ButtonToolbar>
          </Modal>
        }
      </div>
    );
  }
}

ShowPayableJournals.propTypes = {
  payable: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ShowPayableJournals;
