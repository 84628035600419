import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
// import moment from 'moment';
// import ViewHelpers from 'admin-bro/lib/backend/utils/view-helpers';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import SidebarSubCategory from './SidebarSubCategory';
import WVUtilities from '../../../../shared/helpers/WVUtilities';
import { getAllItems } from './MenuItems';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    // eslint-disable-next-line
    currentAdmin: PropTypes.object.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };
  render() {
    // const h = new ViewHelpers();
    const { currentAdmin } = this.props;
    // const startDate = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
    // const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Home" icon="exit" route="/" />
          {getAllItems(currentAdmin._id).map((item) => {
            if (!WVUtilities.isModuleVisibleForAdmin(item.key, currentAdmin)) return null;
            if (!item.items) {
              return (<SidebarLink
                key={uid(item.key)}
                title={item.title}
                route={item.route}
                icon={item.icon}
                test="tes"
              />);
            }
            return (
              <SidebarCategory title={item.title} icon={item.icon} key={uid(item.key)}>
                {item.items ? item.items.map((subItem) => {
                  if (!WVUtilities.isModuleVisibleForAdmin(`${item.key}----${subItem.key}`, currentAdmin)) return null;
                  // console.log(WVUtilities.isModuleVisibleForAdmin(`${item.key}----${subItem.key}`, currentAdmin), `${item.key}----${subItem.key}`);
                  if (!subItem.items) {
                    return (<SidebarLink
                      key={uid(subItem.key)}
                      title={subItem.title}
                      route={subItem.route}
                    />);
                  }
                  return (
                    <SidebarSubCategory title={subItem.title} key={uid(subItem.key)}>
                      {subItem.items ? subItem.items.map((subSubItem) => {
                        // console.log(WVUtilities.isModuleVisibleForAdmin(`${item.key}----${subItem.key}----${subSubItem.key}`, currentAdmin), `${item.key}----${subItem.key}----${subSubItem.key}`);
                        if (!WVUtilities.isModuleVisibleForAdmin(`${item.key}----${subItem.key}----${subSubItem.key}`, currentAdmin)) return null;
                        if (!subSubItem.items) {
                          return (<SidebarLink
                            key={uid(subSubItem.key)}
                            title={subSubItem.title}
                            route={subSubItem.route}
                          />);
                        }
                        return (
                          <SidebarSubCategory title={subSubItem.title} key={uid(subSubItem.key)}>
                            {subSubItem.items ? subSubItem.items.map((subSubSubItem) => {
                              // console.log(WVUtilities.isModuleVisibleForAdmin(`${item.key}----${subItem.key}----${subSubItem.key}`, currentAdmin), `${item.key}----${subItem.key}----${subSubItem.key}----${subSubSubItem.key}`);
                              if (!WVUtilities.isModuleVisibleForAdmin(`${item.key}----${subItem.key}----${subSubItem.key}----${subSubSubItem.key}`, currentAdmin)) return null;
                              return (<SidebarLink
                                key={uid(subSubSubItem.key)}
                                title={subSubSubItem.title}
                                route={subSubSubItem.route}
                              />);
                            }) : null}
                          </SidebarSubCategory>);
                      }) : null}
                    </SidebarSubCategory>);
                }) : null}
              </SidebarCategory>
            );
          })}

          {/*
            WVUtilities.isModuleVisibleForAdmin('Podcasts', currentAdmin) &&
            <SidebarCategory title="Podcast" icon="layers">
              <SidebarLink
                key="Campaign"
                title="Campaign"
                route={h.resourceActionUrl({ resourceId: 'Campaign', actionName: 'list' })}
              />
              <SidebarLink
                key="Podcasts"
                title="Podcasts"
                route={h.resourceActionUrl({ resourceId: 'MediaChannel', actionName: 'list' })}
              />
            </SidebarCategory> */
          }
          {/*
            WVUtilities.isModuleVisibleForAdmin('Influencers', currentAdmin) &&
            <SidebarCategory title="Influencers" icon="users">
              <SidebarLink
                key="Influencers"
                title="Influencers"
                route={h.resourceActionUrl({ resourceId: 'Influencer', actionName: 'list' })}
              />
              <SidebarLink
                key="DealEditor"
                title="Deal Editor"
                route="/influencers/creator-deal-editor"
              />
              <SidebarLink
                key="Deals"
                title="Deals"
                route="/resources/HubilDeal/actions/list"
              />
              <SidebarSubCategory title="Content Scoring">
                <SidebarLink
                  title="Submit Content"
                  route="/scorecard/media-content/new"
                />
                <SidebarLink
                  title="Score Content"
                  route="/scorecard/score-content"
                />
                <SidebarLink
                  title="Media Content"
                  route="/scorecard/content-dashboard?direction=desc&sortBy=createdAt"
                />
                <SidebarLink
                  title="Scorecards"
                  route="/scorecard/scorecard-answers-dashboard?direction=desc&sortBy=scoreDate"
                />
                {
                  // this currently should be visible only for Owners
                  (currentAdmin && currentAdmin.role && currentAdmin.role === 'owner') &&
                  <SidebarLink
                    title="Scorecard Definitions"
                    route="/scorecard/scorecard-dashboard"
                  />
                }
              </SidebarSubCategory>
              <SidebarLink
                title="Notifications"
                route="/resources/PortalNotification/actions/list?direction=desc&sortBy=createdAt"
              />
            </SidebarCategory>
          }
          {
            WVUtilities.isModuleVisibleForAdmin('Brands', currentAdmin) &&
            <SidebarCategory title="Brands" icon="store">
              <SidebarLink
                key="Brands"
                title="Brands"
                route="/resources/Advertiser/actions/list"
              />
              <SidebarLink
                key="Brand Users"
                title="Users"
                route={h.resourceActionUrl({ resourceId: 'BrandUser', actionName: 'list' })}
              />
              <SidebarLink
                key="Opportunities"
                title="Opportunities"
                route={h.resourceActionUrl({ resourceId: 'BrandOpportunity', actionName: 'list' })}
              />
              <SidebarLink
                key="Resources"
                title="Resources"
                route={h.resourceActionUrl({ resourceId: 'BrandResource', actionName: 'list' })}
              />
            </SidebarCategory>
          }
          {
            WVUtilities.isModuleVisibleForAdmin('Agents', currentAdmin) &&
            <SidebarCategory title="Agents" icon="users">
              <SidebarLink
                key="Agent Users"
                title="Users"
                // route={h.resourceActionUrl({ resourceId: 'AgentUser', actionName: 'list' })}
                route="/resources/AgentUser/actions/list?page=1&perPage=10&direction=asc&sortBy=last_name"
              />
            </SidebarCategory>
          }
          {
            WVUtilities.isModuleVisibleForAdmin('Finance', currentAdmin) &&
            <SidebarCategory title="Finance" icon="chart-bars">
              {[
                (
                  <SidebarSubCategory title="Payables" key="Payables">
                    {[
                      (<SidebarLink
                        key="Accounts Payable"
                        title="Accounts Payable"
                        route="/finance/account-payable-report/"
                      />),
                      (<SidebarLink
                        key="Bill List"
                        title="Bills"
                        route="/finance/bills"
                      />),
                    ]}
                  </SidebarSubCategory>
                ),
                (
                  <SidebarSubCategory title="Receivables" key="Receivable">
                    {[
                      (<SidebarLink
                        key="Accounts Receivable"
                        title="Accounts Receivable"
                        route="/finance/account-receivable-report/"
                      />),
                      (<SidebarLink
                        key="Invoice List"
                        title="Invoices"
                        route="/resources/Invoice/actions/list"
                      />),
                    ]}
                  </SidebarSubCategory>
                ),
              ]}
            </SidebarCategory>
          }
          {
            WVUtilities.isModuleVisibleForAdmin('Employees', currentAdmin) &&
            <SidebarLink
              key="Employees"
              title="Employees"
              icon="users"
              route="/resources/Employee/actions/list"
            />
          }
          {
            WVUtilities.isModuleVisibleForAdmin('Reports', currentAdmin) && (
              <SidebarCategory title="Reports" icon="inbox">
                <SidebarLink
                  key="Summary"
                  title="Gross Revenue & Gross Profit Summary"
                  route="/reports/summary-report"
                />
                <SidebarLink
                  key="Creators"
                  title="Creator Ranking"
                  route="/reports/creators-report"
                />
                <SidebarLink
                  key="LTVReport"
                  title="Creator Lifetime Value Report"
                  route="/reports/ltv-report"
                />
                <SidebarLink
                  key="BaseReport"
                  title="Base Performance Report"
                  route="/reports/base-report"
                />
                <SidebarLink
                  key="SalesActivity"
                  title="Sales Activity"
                  route={`/reports/sales-activity?filters.actionDate~~from=${startDate}&filters.actionDate~~to=${endDate}`}
                />
              </SidebarCategory>
            )
          }
          {
            WVUtilities.isModuleVisibleForAdmin('ThirdPartyIntegrations', currentAdmin) &&
            <SidebarCategory title="Third-Party Integrations" icon="license">
              <SidebarSubCategory title="ThumbTack">
                <SidebarLink
                  key="Leads"
                  title="Leads"
                  route={h.resourceActionUrl({ resourceId: 'ThumbTackLead', actionName: 'list' })}
                />
                <SidebarLink
                  key="Messages"
                  title="Messages"
                  route="/thumbtack/messages"
                />
                <SidebarLink
                  key="Templates"
                  title="Templates"
                  route={h.resourceActionUrl({ resourceId: 'ThumbTackReplyTemplate', actionName: 'list' })}
                />
                <SidebarLink
                  key="Sequence Templates"
                  title="Sequence Templates"
                  route={h.resourceActionUrl({ resourceId: 'ThumbTackSequenceTemplate', actionName: 'list' })}
                />
              </SidebarSubCategory>
              <SidebarSubCategory title="Impact">
                <SidebarLink
                  key="Actions"
                  title="Actions"
                  route={h.resourceActionUrl({ resourceId: 'ImpactAction', actionName: 'list' })}
                />
                <SidebarLink
                  key="Action Items"
                  title="Action Items"
                  route={h.resourceActionUrl({ resourceId: 'ImpactActionItem', actionName: 'list' })}
                />
              </SidebarSubCategory>
            </SidebarCategory>
          }
          {
            WVUtilities.isModuleVisibleForAdmin('Admins', currentAdmin) &&
            <SidebarCategory title="Admin Settings" icon="users">
              <SidebarLink
                key="AdminUsers"
                title="Admin Users"
                route={h.resourceActionUrl({ resourceId: 'AdminUser', actionName: 'list' })}
              />
              <SidebarLink
                key="ScheduledJobs"
                title="Jobs"
                route={h.resourceActionUrl({ resourceId: 'ScheduledJob', actionName: 'list' })}
              />
              <SidebarLink
                key="StripeEvents"
                title="Stripe Log"
                route={h.resourceActionUrl({ resourceId: 'StripeEvent', actionName: 'list' })}
              />
              <SidebarLink
                key="StripePayouts"
                title="Stripe Dashboard"
                route="/stripe/payouts"
              />
              <SidebarLink
                key="BillComDashboard"
                title="BillCom Dashboard"
                route="/billCom/dashboard"
              />
              <SidebarLink
                key="DocuSignSettings"
                title="DocuSign Settings"
                route="/docusign-settings"
              />
              <SidebarLink
                key="Dictionary"
                title="Dictionary"
                route={h.resourceActionUrl({ resourceId: 'Dictionary', actionName: 'list' })}
              />
              <SidebarLink
                key="Statistics"
                title="Statistics"
                route={h.resourceActionUrl({ resourceId: 'Statistic', actionName: 'list' })}
              />
              {
                // this currently should be visible only for Owners
                (currentAdmin && currentAdmin.role && currentAdmin.role === 'owner') &&
                <SidebarLink
                  key="Redirect Domains"
                  title="Redirect Domains"
                  route={h.resourceActionUrl({ resourceId: 'RedirectDomain', actionName: 'list' })}
                />
              }
              {
                // this currently should be visible only for Owners
                (currentAdmin && currentAdmin.role && currentAdmin.role === 'owner') &&
                <SidebarLink
                  key="Vanity Urls"
                  title="Vanity Urls"
                  route={h.resourceActionUrl({ resourceId: 'VanityUrl', actionName: 'list' })}
                />
              }
              {
                // this currently should be visible only for Owners
                (currentAdmin && currentAdmin.role && currentAdmin.role === 'owner') &&
                <SidebarLink
                  key="EmailSettings"
                  title="Email Settings"
                  route={h.resourceActionUrl({ resourceId: 'EmailTemplate', actionName: 'list' })}
                />
              }
              {
                // this currently should be visible only for Owners
                (currentAdmin && currentAdmin.role && currentAdmin.role === 'owner') &&
                <SidebarLink
                  key="HubilContacts"
                  title="HubIL Contacts"
                  route={h.resourceActionUrl({ resourceId: 'HubilContact', actionName: 'list' })}
                />
              }
              {
                // this currently should be visible only for Owners
                (currentAdmin && currentAdmin.role && currentAdmin.role === 'owner') &&
                <SidebarLink
                  key="HubilAgents"
                  title="HubIL Agents"
                  route={h.resourceActionUrl({ resourceId: 'HubilAgent', actionName: 'list' })}
                />
              }
              {
                // this currently should be visible only for Owners
                (currentAdmin && currentAdmin.role && currentAdmin.role === 'owner') &&
                <SidebarLink
                  key="HubilDeals"
                  title="HubIL Deals"
                  route={h.resourceActionUrl({ resourceId: 'HubilDeal', actionName: 'list' })}
                />
              }
              {
                // this currently should be visible only for Owners
                (currentAdmin && currentAdmin.role && currentAdmin.role === 'owner') &&
                <SidebarLink
                  key="HubILEvents"
                  title="HubIL Events"
                  route="/resources/HubILEvent/actions/list?direction=desc&sortBy=occurredAt"
                />
              }
            </SidebarCategory>
          }
          {
            WVUtilities.isModuleVisibleForAdmin('Dev', currentAdmin) &&
            <SidebarLink
              key="Logs"
              title="Dev Logs"
              icon="code"
              route={h.resourceActionUrl({ resourceId: 'Log', actionName: 'list' })}
            />
          }
          {
            WVUtilities.isModuleVisibleForAdmin('Mobile', currentAdmin) &&
            <SidebarCategory title="Mobile" icon="smartphone">
              <SidebarLink
                key="PushNotifications"
                title="Push Notifications"
                route="/mobile/push-notifications"
              />
            </SidebarCategory> */
          }
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
/*
 * <SidebarCategory title="Layout" icon="layers">
          <button className="sidebar__link" type="button" onClick={changeToLight}>
            <p className="sidebar__link-title">Light Theme</p>
          </button>
          <button className="sidebar__link" type="button" onClick={changeToDark}>
            <p className="sidebar__link-title">Dark Theme</p>
          </button>
        </SidebarCategory>
 */
