import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { unflatten } from 'flat';
import { axios } from 'ApiClient';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import Hook from '../../../../../../../shared/hooks';

const ApprovalStatus = React.memo(({
  p,
  notes,
}) => {
  const aValues = {
    needsReview: 'Needs Review',
    approved: 'Approved',
    expired: 'Expired',
    rejected: 'Rejected',
  };
  const allowEdit = useSelector((state) => state.taxFormEditRoleCheck.isAllowedToEdit);
  const { updateRecordFunction } = p;
  const [showModal] = Hook.useShowModalHook('NotesModal');

  const rowData = p.row.original.params;
  const val = rowData.approvalStatus.value;
  // console.log(val, rowData, 'valueApp');
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const options = Object.keys(aValues).map(k => ({ value: k, label: aValues[k] }));
  useEffect(() => {
    setValue(val);
  }, [rowData._id, val]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    // setValue(option.label);
    if (option.value === 'expired' || option.value === 'rejected') {
      showModal({
        record: p.row.original, notes, title: 'Tax Form Notes', name: 'statusNote', updateRecordFunction, approvalStatus: option.label, setDisabled,
      });
    } else {
      try {
        setValue(option.label);
        const data = await axios.post('/api/portal/finance/post-update-property-on-tax-form', {
          ids: [rowData._id],
          values: {
            approvalStatus: option.label,
          },
        });
        const record = unflatten(data?.data?.recordPopulated);
        console.log(data);
        updateRecordFunction(rowData._id, { approvalStatus: record.params.approvalStatus });
        setValue(record.params.approvalStatus.value);
        setDisabled(false);
      } catch (e) {
        console.log(e);
        setError(e.message);
        setDisabled(false);
      }
    }
    // setDisabled(false);
    return true;
  };
  return (
    <div style={{ width: '210px' }}>
      <div style={{ width: '150px', float: 'left' }} >
        {
          allowEdit ? (
            <Select
              value={options.filter(option => option.label === value)}
              options={options}
              onChange={handleChange}
              className="statusBox"
              isDisabled={disabled}
              menuPlacement="auto"
              menuPortalTarget={document.body}
            /> 
          ) : (
            <p>{value}</p>
          )
        }
        {error ? (<span className="text-danger">{error}</span>) : null}
      </div>
      <div style={{ marginTop: '6px', marginRight: '6px', float: 'right' }}>
        <Tooltip title='Tax Form Notes'>
          <IconButton
            size="small"
            onClick={() => {
              showModal({
                record: p.row.original, notes, title: 'Tax Form Notes', name: 'statusNote', updateRecordFunction,
              });
            }
            }
          >
            <FontAwesomeIcon icon={['fas', 'file-alt']} color={notes ? '#70bbfd' : ''} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.p, a1.p));

ApprovalStatus.propTypes = {
  p: PropTypes.objectOf(PropTypes.any).isRequired,
  notes: PropTypes.string,
};

ApprovalStatus.defaultProps = {
  notes: '',
};

export default ApprovalStatus;
