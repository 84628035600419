/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../../../shared/components/ButtonWithSpinner';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../../../App/store/with-notice';

export const modalId = 'SyncContentReleaseDateOrder';

const SyncCreatorModal = ({
  updateTable,
  addNotice,
}) => {
  const dispatch = useDispatch();
  const [loadingSyncCreator, setLoadingSyncCreator] = useState(false);

  const modalInfo = useSelector(state => state.table.modals[modalId]);
  if (!modalInfo) return false;
  console.log(modalInfo);
  
  const { data: { record }, isShow } = modalInfo;
  console.log('record: ', record);
  const close = () => dispatch(hideModalById(modalId));
  const syncContentReleaseDate = async () => {
    setLoadingSyncCreator(true);
    try {
      const res = await axios({
        method: 'post',
        url: '/api/portal/prospects/post-sync-content-release-date-invoice',
        data: { invoice: record.id },
      });
      setLoadingSyncCreator(false);
      if (res?.data?.success) {
        addNotice({
          message: 'CPM data was successfully updated with HubSpot deal data',
          type: 'success',
        });
        updateTable();
        close();
      } else if (res?.data?.error) {
        addNotice({
          message: `Content Release Date was not updated. Error: ${res.data.error}`,
          type: 'error',
          duration: 10,
        });
      }
      updateTable();
    } catch (error) {
      console.error(error);
      addNotice({
        message: `Content Release Date was not updated. Error: ${error.message}`,
        type: 'error',
        duration: 10,
      });
      setLoadingSyncCreator(false);
    }
    close();
  };
  if (!modalInfo) return false;
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Sync Data from HubSpot Deals
      </ModalHeader>
      <ModalBody>
        Are you sure you want to overwrite Content Release Date for the Invoice - {record?.params?.invoiceNumber}?
      </ModalBody>
      <ModalFooter>
        <ButtonWithSpinner
          className="btn-sm"
          type="button"
          color="primary"
          onClick={syncContentReleaseDate}
          loading={loadingSyncCreator}
          disabled={loadingSyncCreator}
        >
          <span>Sync</span>
        </ButtonWithSpinner>{' '}
        <Button
          className="btn-sm"
          color="secondary"
          onClick={close}
          disabled={loadingSyncCreator}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

SyncCreatorModal.propTypes = {
  updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

SyncCreatorModal.defaultProps = {

};

export default withNotice(SyncCreatorModal);
