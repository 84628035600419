import PropTypes from 'prop-types';
import React from 'react';
import TableCellContextMenu from '../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';

const ContextMenu = ({
  mediaContentId, mediaUrl, influencerId,
}) => {
  const profileLink = influencerId ? `/influencer/profile/${influencerId}` : '';
  const editLink = `/scorecard/media-content/edit/${mediaContentId}`;

  const handleClickViewVideoContent = () => {
    if (mediaUrl) {
      window.open(mediaUrl, '_blank').focus();
    }
  };

  const handleClickViewCreatorProfile = () => {
    if (profileLink) {
      window.open(profileLink, '_blank').focus();
    }
  };

  const handleClickViewMediaContent = () => {
    if (editLink) {
      window.open(editLink, '_blank').focus();
    }
  };

  const options = [
    { label: 'View Creator Profile', handler: handleClickViewCreatorProfile },
    { label: 'View Media Content', handler: handleClickViewMediaContent },
    { label: 'View YouTube Video', handler: handleClickViewVideoContent },
  ];

  return (
    <div className="text-right">
      <TableCellContextMenu options={options} />
    </div>
  );
};
ContextMenu.propTypes = {
  mediaContentId: PropTypes.string.isRequired,
  mediaUrl: PropTypes.string.isRequired,
  influencerId: PropTypes.string.isRequired,
};

export default ContextMenu;
