/* eslint react/prop-types: 0 */
/* eslint no-underscore-dangle: 0 */
import React from 'react';
import Select from 'react-select';
import ApiClient from 'ApiClient';
import { Card, CardBody } from 'reactstrap';
import withNotice from '../../../../../containers/App/store/with-notice';


class FilterAdvertiserBlock extends React.Component {
  constructor(props) {
    super(props);
    this.selectItem = this.selectItem.bind(this);
    this.state = {
      accountsPayable: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    const { setTag } = this.props;
    if (setTag) {
      setTag('');
    }
  }

  selectItem(e) {
    const { value } = e;
    // this.setState({ selectedCampaign: e });
    const { history, clearParentFilters } = this.props;
    clearParentFilters();
    const search = new URLSearchParams(window.location.search);
    if (value !== '') {
      search.set('filters.accountPayable', value);
    } else {
      search.delete('filters.accountPayable');
    }
    history.push(`${history.location.pathname}?${search.toString()}`);
  }

  fetchData(props) {
    const { addNotice } = props;

    const api = new ApiClient();
    this.setState({ loading: true });
    api.client.get('/api/portal/finance/get-accounts-payable').then((resp) => {
      this.setState({
        loading: false,
        accountsPayable: resp.data.items,
      });
    }).catch(() => {
      this.setState({ loading: false });
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
  }

  handleActionPerformed() {
    this.fetchData(this.props);
  }

  render() {
    const {
      accountsPayable, loading,
    } = this.state;
    const options = accountsPayable.map(item => ({ value: item._id, label: `${item.name} ${item.description ? `(${item.description})` : ''}` }));
    const search = new URLSearchParams(window.location.search);
    const accountsPayableItem = search.get('filters.accountPayable');
    const selected = accountsPayableItem ? options.find(e => e.value === accountsPayableItem.toString()) : '';
    return (
      <Card>
        <CardBody>
          <h4 style={{ marginBottom: '12px' }}>{selected === '' ? 'Select Account Payable' : 'Selected Account Payable'}</h4>
          <Select
            isDisabled={loading}
            value={selected}
            options={options}
            onChange={this.selectItem}
            placeholder="Select Account Payable..."
          />
        </CardBody>
      </Card>
    );
  }
}

export default withNotice(FilterAdvertiserBlock);
