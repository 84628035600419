import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  Row, Col,
  Modal, ModalBody, ModalHeader,
  ButtonToolbar, Button,
  FormGroup, Label,
} from 'reactstrap';
import CheckBoxField from "../../../../../../shared/components/CheckBox";
import hook from '../../../../../../shared/hooks';
import RadioButton from "../../../../../../shared/components/RadioButton";
import DatePicker from "react-datepicker";
import moment from "moment";
import WVFormatter from "../../../../../../shared/helpers/WVFormatter";

const UpdateBulkUnreviewedModal = ({
  isOpen,
  closeFunc,
}) => {
  const [loading, setLoading] = useState(false);
  const [updateHumanCheck, setUpdateHumanCheck] = useState({ sentiment: false, brandRelevancy: false });
  const [typeFilter, setTypeFilter] = useState({ both: true, sentiment: false, relevancy: false });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searching, setSearching] = useState(true);
  const [foundComments, setFoundComments] = useState(0);
  const [limitComments, setLimitComments] = useState(0);
  const [updateTable, setUpdateTable] = useState(false);
  const { showError, showSuccess } = hook.useNoticeHook();

  useEffect(() => {
    if (!isOpen) {
      setUpdateHumanCheck({ sentiment: false, brandRelevancy: false });
      setStartDate(null);
      setEndDate(null);
      setTypeFilter({ both: true, sentiment: false, relevancy: false });
      setUpdateTable(false);
      setFoundComments(0);
      setLimitComments(0);
      setSearching(true);
    }
  }, [isOpen]);

  const handleChangeHumanCheck = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setUpdateHumanCheck({
        ...updateHumanCheck,
        [name]: checked,
      });
    }
  };

  const resetResults = () => {
    setSearching(true);
    setFoundComments(0);
    setLimitComments(0);
    setUpdateHumanCheck({ sentiment: false, brandRelevancy: false });
  };

  const searchComments = () => {
    // setSearching(true);
    axios.get(`/api/portal/comments/get-comments-number-by-date?startDate=${moment(startDate).format('YYYY-MM-DD HH:mm:ss')}&endDate=${moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')}&both=${typeFilter.both}&relevancy=${typeFilter.relevancy}&sentiment=${typeFilter.sentiment}`).then((response) => {
      if (response.data.success) {
        console.log(response.data);
        setFoundComments(response.data.count);
        setLimitComments(response.data.limit || 500);
      }
      setSearching(false);
    });
    console.log(searching);
  };

  const submit = async () => {
    setLoading(true);
    axios({
      method: 'post',
      url: '/api/portal/comments/post-bulk-update-human-sentiment-by-date',
      data: {
        updateHumanCheck,
        startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        typeFilter,
      },
    }).then((response) => {
      if (response.data.success) {
        searchComments();
        setLoading(false);
        showSuccess('Update was successfully completed.');
        setUpdateTable(true);
      }
      if (response.data.error) {
        setLoading(false);
        showError(response.data.error);
      }
    });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc(updateTable)}
      style={{ minWidth: '770px' }}
      className=" theme-light"
    >
      <ModalHeader toggle={() => closeFunc(updateTable)} tag="h4" className="modal-title-statistics">Update Unreviewed Sentiment & Relevancy</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="3" className="text-center mt-auto mb-auto" style={{ color: '#70bbfd' }}> <i
            className="fa fa-question"
            style={{ fontSize: '5em' }}
          />
          </Col>
          <Col xs={9}>
            <div className="theme-light radio-in-prospects">
              <div className="mb-2">Specify a date range to select all <strong>Unreviewed</strong> comments.</div>
              <Row>
                <Col sm={5} style={{ paddingRight: 0 }}>
                  <RadioButton
                    className="no-padding"
                    name="action"
                    label="&nbsp;Both Sentiment & Relevancy"
                    value={typeFilter.both ? 'both' : 'relevancy'}
                    radioValue="both"
                    onChange={(e) => {
                      setTypeFilter({
                        sentiment: false,
                        relevancy: false,
                        both: e === 'both',
                      });
                      resetResults();
                    }}
                  />
                </Col>
                <Col sm={4}>
                  <RadioButton
                    className="no-padding"
                    name="action"
                    label="&nbsp;Sentiment only"
                    value={typeFilter.sentiment ? 'sentiment' : 'relevancy'}
                    radioValue="sentiment"
                    onChange={(e) => {
                      setTypeFilter({
                        sentiment: e === 'sentiment',
                        relevancy: false,
                        both: false,
                      });
                      resetResults();
                    }}
                  />
                </Col>
                <Col sm={3} style={{ paddingLeft: 0 }}>
                  <RadioButton
                    className="no-padding"
                    name="action"
                    label="&nbsp;Relevancy only"
                    value={typeFilter.relevancy ? 'relevancy' : 'sentiment'}
                    radioValue="relevancy"
                    onChange={(e) => {
                      setTypeFilter({
                        relevancy: e === 'relevancy',
                        sentiment: false,
                        both: false,
                      });
                      resetResults();
                    }}
                  />
                </Col>
              </Row>
            </div>
            <Row className="mt-2">
              <Col xs="auto">
                <Label><strong>Start Date</strong></Label>
                <div style={{maxWidth: '120px', marginLeft: '10px', display: 'inline-block'}}>
                  <DatePicker
                    selected={startDate}
                    onChange={setStartDate}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    className="form-control form-control-sm"
                  />
                </div>
              </Col>
              <Col xs="auto" style={{ paddingLeft: '10px' }}>
                <Label><strong>End Date</strong></Label>
                <div style={{ maxWidth: '120px', marginLeft: '10px', display: 'inline-block' }}>
                  <DatePicker
                    selected={endDate}
                    onChange={setEndDate}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={startDate}
                    placeholderText="End Date"
                    dropDownMode="select"
                    className="form-control form-control-sm"
                  />
                </div>
              </Col>
              <Col xs="auto">
                <Button
                  className="btn btn-primary btn-sm"
                  disabled={!startDate || !endDate}
                  onClick={searchComments}
                  // style={{ padding: '7px 25px' }}
                >
                  <i className="fa fa-search" color="white" />&nbsp;
                  {/*<span className="btn-text">Search</span>*/}
                </Button>
              </Col>
            </Row>
            {!searching && (
              <>
                <hr/>
                {foundComments <= limitComments ? (
                  <div>You have selected <strong>{WVFormatter.formatIntNumber(foundComments)} Unreviewed</strong> comments to update.</div>
                ) : (
                  <>
                    <div>The first <strong>{`${WVFormatter.formatIntNumber(limitComments)}`} Unreviewed</strong> comments have been selected from a total of <strong>{`${WVFormatter.formatIntNumber(foundComments)}`}</strong> to update.</div>
                    <div className="mt-2">Run the <strong>Update</strong> again to continue updating the remaining <strong>Unreviewed</strong> comments.</div>
                  </>
                )}
                {foundComments === 0 ? (
                  <div className="mt-2">There are no <strong>Unreviewed</strong> comments for selected date range.</div>
                ) : (
                  <>
                    <div className="theme-light radio-in-prospects mt-2">
                      Would you like to update the associated <strong>Human</strong> values with <br /> their
                      corresponding <strong>AI</strong> values?&nbsp;&nbsp;&nbsp;
                    </div>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <CheckBoxField
                            name="humanSentiment"
                            label="Yes, update <strong>Human</strong> Sentiment"
                            className="inline"
                            value={updateHumanCheck.sentiment}
                            onChange={event => handleChangeHumanCheck(event, 'sentiment')}
                          />
                          <CheckBoxField
                            name="humanBrandRelevancy"
                            label="Yes, update <strong>Human</strong> Brand Relevancy"
                            className="inline ml-4"
                            value={updateHumanCheck.brandRelevancy}
                            onChange={event => handleChangeHumanCheck(event, 'brandRelevancy')}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button
          className="modal_ok btn-sm"
          color="primary"
          onClick={submit}
          disabled={loading || (!updateHumanCheck.sentiment && !updateHumanCheck.brandRelevancy) || foundComments === 0}
        >
          {loading && <span><div className="spinner-border text-light spinner-border-sm"/>  </span>}
          <span>Update</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => closeFunc(updateTable)}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

UpdateBulkUnreviewedModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default UpdateBulkUnreviewedModal;
