import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';
import OverlapReport from './components/OverlapReport';
import withNotice from '../../../../../containers/App/store/with-notice';

class OverlapList extends React.Component {
  componentDidMount() {
    // console.log(this.props.match.params);
  }

  render() {
    const { creatorId } = this.props.match.params;

    return (
      <Container className="dashboard">
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            { title: 'Research', path: null },
            { title: 'Audience Overlap', path: null },
          ]}
          isBackButton
        />
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <OverlapReport creatorId={creatorId} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}


OverlapList.propTypes = {
  // addNotice: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};
// csvDownload={{
//   fileName: 'AccountsReceivableReport.csv',
//   csvArrayColumns: downloadCsv,
// }}

export default withNotice(OverlapList);
