/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from '../../../../../../shared/components/CopyToClipboard';

const getAccountId = record => record.id || null;

const AccountIdComponent = ({
  record,
}) => {
  const [accountId, setAccountId] = useState(getAccountId(record));

  const viewAccount = () => {
    const url = `https://dashboard.stripe.com/${window.location.hostname === 'portal.influencelogic.com' ? '' : 'test/'}connect/accounts/${accountId}`;
    const win = window.open(url, '_blank');
    win.focus();
  };

  useEffect(() => {
    setAccountId(getAccountId(record))
  }, [record]);

  return (
    <div style={{ width: '180px' }}>
      {accountId ? (
        <CopyToClipboard
          title="Copy Account ID"
          value={accountId}
          iconClassName="il-color-deep-blue"
        >
          <a
            title="View Account"
            className="il-color-deep-blue"
            role="button"
            onClick={viewAccount}
          >
            {accountId}
          </a>
        </CopyToClipboard>
      ) : ('-')}
    </div>
  );
}

AccountIdComponent.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default AccountIdComponent;
