import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ButtonToolbar, Modal, ModalHeader, Spinner } from 'reactstrap';
import TableCellContextMenu from './TableCellContextMenu';
import { getScorecardAnswersData } from '../../../../../../redux/reducers/admin/ScoringReducer';
import ScorecardsAnswersForm from '../../../ScorecardAnswersSheet/components/ScorecardsAnswersForm';

const ContextMenuInList = ({
  value,
  dispatch,
  loading,
  scorecardData,
}) => {
  const [showPreview, setShowPreview] = useState(false);

  const history = useHistory();

  const { _id } = value;
  const editLink = `/scorecard/score-content/${value.mediaContent?._id || ''}/${value.mediaContent?._id ? _id : ''}`;

  const toggleDialog = (e) => {
    e.preventDefault();
    setShowPreview(false);
  };

  const handleClickPreview = () => {
    setShowPreview(true);
    dispatch(getScorecardAnswersData(_id));
  };

  const handleClickEdit = () => {
    history.push(editLink);
  };

  const options = [
    { label: 'Preview Scorecard', handler: handleClickPreview },
    { label: 'Edit Scorecard', handler: handleClickEdit },
  ];
  const isFTCCompliant = scorecardData !== null && typeof scorecardData.data !== 'undefined' ? scorecardData.data.isFTCCompliant : null;
  const includesPaidPromotion = scorecardData !== null && typeof scorecardData.data !== 'undefined' ? scorecardData.data.includesPaidPromotion : null;
  const isOscar = scorecardData !== null && typeof scorecardData.data !== 'undefined' ? scorecardData.data.isOscar : null;

  return (
    <div>
      <TableCellContextMenu options={options} />
      <Modal
        isOpen={showPreview}
        toggle={toggleDialog}
        style={{ minWidth: '750px' }}
      >
        <ModalHeader toggle={toggleDialog} tag="h4">Scorecard Preview</ModalHeader>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body">
          {!loading && showPreview && scorecardData && (
            <ScorecardsAnswersForm
              questions={scorecardData.data.scorecard}
              answers={scorecardData}
              view="modal"
              isFTCCompliant={isFTCCompliant}
              includesPaidPromotion={includesPaidPromotion}
              isOscar={isOscar}
              type={scorecardData.data?.mediaContent?.type}
              mediaContentText={scorecardData.data.mediaContent?.contentText}
              mediaContentTranscript={scorecardData.data.mediaContent?.transcriptionText || ''}
            />
          )}
          {loading && showPreview && !scorecardData && (
            <div className="text-center"><Spinner color="primary" size="lg" /></div>
          )}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="btn btn-primary btn-sm" onClick={toggleDialog}>Close</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};
ContextMenuInList.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  scorecardData: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
};

ContextMenuInList.defaultProps = {
  loading: null,
  scorecardData: null,
};

const mapStateToProps = state => ({
  showDeleteDialog: state.score.showDeleteDialog,
  loading: state.score.loading.fetchScorecardAnswersContent,
  scorecardData: state.score.answers,
});
export default connect(mapStateToProps)(ContextMenuInList);
