import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Row,
  Col,
  Label,
} from 'reactstrap';
import { getCookie } from '../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import Panel from './Panel';

const Actions = ({
  dataView,
  setDataView,
  showLabels,
  setShowLabels,
  leadTypes,
  setLeadTypes,
  advertisers,
  setAdvertisers,
  owners,
  setOwners,
  setAdvertisersById,
  setOwnersById,
}) => {
  const filterName = 'LeadOperations';

  const [showFilters, setShowFilters] = useState(getCookie(`${filterName}ShowFilters`) === 'true');

  const setLeadType = (e) => {
    if (e?.target) {
      const { name, checked } = e.target;
      setLeadTypes({
        ...leadTypes,
        [name]: checked,
      });
    }
  };

  return (
    <div>
      <Panel
        name={filterName}
        show={showFilters}
        setShow={setShowFilters}
        advertisers={advertisers}
        setAdvertisers={setAdvertisers}
        owners={owners}
        setOwners={setOwners}
        setAdvertisersById={setAdvertisersById}
        setOwnersById={setOwnersById}
      />
      {showFilters && (
        <Row>
          <Col md={6} lg={4} xl={3}>
            <Label className="bold-text">
              Data View
            </Label>
            <Select
              value={dataView}
              options={[
                { value: 'day', label: 'By Day' },
                { value: 'week', label: 'By Week' },
                { value: 'month', label: 'By Month' },
                { value: 'over_time', label: 'Over Time' },
              ]}
              onChange={setDataView}
              className="mb-2"
            />
            <CheckBoxField
              name="showLabels"
              label="Show Labels on Charts"
              value={showLabels}
              onChange={(e) => { if (e?.target) setShowLabels(e.target.checked); }}
            />
          </Col>
          <Col md={6} lg={4} xl={3}>
            <Label className="bold-text" style={{ marginBottom: '8px' }}>
              Lead Types
            </Label>
            <CheckBoxField
              name="internal"
              label="New Internal"
              value={leadTypes.internal}
              onChange={setLeadType}
            />
            <CheckBoxField
              name="resubmits"
              label="Resubmits"
              value={leadTypes.resubmits}
              onChange={setLeadType}
            />
            <CheckBoxField
              name="standard"
              label="New Creator Search/Uploads"
              value={leadTypes.standard}
              onChange={setLeadType}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

Actions.propTypes = {
  dataView: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataView: PropTypes.func.isRequired,
  showLabels: PropTypes.bool.isRequired,
  setShowLabels: PropTypes.func.isRequired,
  advertisers: PropTypes.arrayOf(PropTypes.any),
  setAdvertisers: PropTypes.func.isRequired,
  owners: PropTypes.arrayOf(PropTypes.any),
  setOwners: PropTypes.func.isRequired,
  leadTypes: PropTypes.objectOf(PropTypes.any).isRequired,
  setLeadTypes: PropTypes.func.isRequired,
  setAdvertisersById: PropTypes.func.isRequired,
  setOwnersById: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  advertisers: [],
  owners: [],
};

export default Actions;
