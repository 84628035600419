import React, { PureComponent } from 'react';
import { Row, Spinner } from 'reactstrap';
import { axios } from 'ApiClient';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';

const columnTransform = {
  name: {
    title: 'Name',
    valueFunc: record => (`${record.first_name || ''} ${record.last_name || ''}`),
    sort: record => (`${record.first_name || ''} ${record.last_name || ''}`),
  },
  email: {
    title: 'Email',
    valueFunc: record => (record.email || ''),
    sort: record => (record.email || ''),
  },
  stripeAccount: {
    title: 'Stripe Account',
    valueFunc: record => (record.payment.stripe.accountId || ''),
    sort: record => (record.payment.stripe.accountId || ''),
  },
  defaultProvider: {
    title: 'Default Payment',
    valueFunc: (record) => {
      let value = '';
      if (record.payment.billCom.isDefault) {
        value = 'Bill.com';
      } else if (record.payment.stripe.isDefault) {
        value = 'Stripe';
      }
      return value;
    },
    sort: (record) => {
      let value = '';
      if (record.payment.billCom.isDefault) {
        value = 'Bill.com';
      } else if (record.payment.stripe.isDefault) {
        value = 'Stripe';
      }
      return value;
    },
  },
};

class ConnectedAccountsTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchConnectedAccounts();
  }

  // METHODS
  fetchConnectedAccounts() {
    this.setState({
      loading: true,
    });

    axios.get('/api/portal/stripe/connected-accounts').then((response) => {
      const { accounts } = response.data;

      this.setState({
        accounts,
        loading: false,
      });
    }).catch((error) => {
      console.log(error);
    });
  }
  //

  render() {
    const { loading, accounts } = this.state;

    return (
      <div>
        <h4 className="il-color-deep-blue font-weight-medium mb-0">Connected Influencer Accounts</h4>
        {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        { !loading &&
          <Row>
            <MatTableForRedux
              dataArray={accounts}
              selectable={false}
              columns={columnTransform}
              version={(new Date()).toString()}
              defaultOrder={{
                order: 'desc',
                orderBy: 'email',
              }}
              rowsPerPage={10}
              hideToolbar
            />
          </Row>
        }
      </div>
    );
  }
}

export default ConnectedAccountsTable;
