import React, { useState } from 'react';
// import ApiClient from 'ApiClient';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
// import { unflatten } from 'flat';
// import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import StopCircleIcon from 'mdi-react/StopCircleIcon';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

const StopProcessComponent = ({ record, updateRecordFunction }) => {
  const [loading, setLoading] = useState(false);
  // const unflattenRecord = unflatten(record);
  // const parent = unflattenRecord.params.queueObj.find(item => item.isParent === true);
  // const parentIndex = unflattenRecord.params.queueObj.findIndex(item => item.isParent === true);

  const stopProcess = async () => {
    setLoading(true);
    // console.log(parent);
    try {
      const stopProcessResp = await axios.post('/api/portal/prospects/post-save-status', { _id: record.params._id, status: 'stopped' });
      if (updateRecordFunction) {
        const duration = stopProcessResp.data?.queuePopulated?.params?.duration || 0;
        console.log(stopProcessResp, duration);
        updateRecordFunction(record.id, {
          status: 'stopped',
          // [`queueObj.${parentIndex}.endDate`]: (new Date()).toString(),
          duration,
        });
        // queuePopulated
        // updateRecordFunction(stopProcessResp?.data?.queuePopulated);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Tooltip
      title="Stop Uploading Prospects"
      key="Stop Uploading Prospects"
      // style={{ float: 'left' }}
    >
      <IconButton
        size="small"
        disabled={loading}
        className="material-table__toolbar-button"
        onClick={() => {
          // console.log('Stop');
          stopProcess().then((res) => {
            console.log('End');
            console.log(res);
          });
        }}
      >
        <StopCircleIcon size="32" />
      </IconButton>
    </Tooltip>
  );
};

StopProcessComponent.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};

export default StopProcessComponent;
