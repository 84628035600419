/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import PropTypes from 'prop-types';
import Select from 'react-select';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';

import { Row, Col, Button, Container, Card, CardBody, CardTitle, Label, Spinner } from 'reactstrap';

import PeriodTabs, { getPeriods } from '../../../../../shared/components/filter/PeriodTabs';
import Columns from './Columns';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import PermissionComponent from '../../../../../shared/components/PermissionComponent';
import withNotice from '../../../../App/store/with-notice';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import Actions from './Actions';
import { CheckPermission } from '../../../../../shared/helpers/WVUtilities';
import { toOptions, selectedCheckboxes } from '../../../../../shared/helpers/Form';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import LineChart from '../../../../../shared/components/charts/Line';
import BarCharts from '../../../../../shared/components/charts/Bar';
import { groupData, groupByKey, calculateSum, calculatePercentage } from '../../../../../shared/helpers/reports/Calculations';
import { toWeek, toMonth, xLabel } from '../../../../../shared/helpers/reports/Charts';
import { isBrandRejected } from '../../../../../shared/helpers/models/Prospect';

const SummaryReport = ({ addNotice }) => {
  const [startDate, setStartDate] = useState(moment().subtract(4, 'month').startOf('day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState((moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).format('YYYY-MM-DD'));

  const [identity] = useState(Math.random().toString(36).substring(2));
  const [loading, setLoading] = useState(false);
  const [barChartsLoading, setBarChartsLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [prospectsData, setProspectsData] = useState([]);

  const [advertisers, setAdvertisers] = useState([]);
  const [owners, setOwners] = useState([]);

  const [dataView, setDataView] = useState({ value: 'week', label: 'By Week' });
  const [leadTypesChecks, setLeadTypesChecks] = useState({ internal: true, resubmits: true, standard: true });

  const [advertisersById, setAdvertisersById] = useState([]);
  const [ownersById, setOwnersById] = useState([]);

  const currentAdvertisers = (advertisers?.length > 0) ? advertisers : toOptions(advertisersById);
  const currentOwners = (owners?.length > 0) ? owners : toOptions(ownersById);

  const [lineChartData, setLineChartData] = useState([]);
  const [leadTypeLines, setLeadTypeLines] = useState([]);
  const [showTotalLine, setShowTotalLine] = useState(true);
  const [showLabels, setShowLabels] = useState(true);

  const [barBreakdown, setBarBreakdown] = useState({ value: 'advertiser', label: 'Brand' });
  const [showBarLabels, setShowBarLabels] = useState(true);

  const [leadSubmissionsData, setLeadSubmissionsData] = useState([]);
  const [ilRejectedData, setIlRejectedData] = useState([]);
  const [brandRejectedData, setBrandRejectedData] = useState([]);

  const [tableData, setTableData] = useState([]);

  const filters = { advertiser: advertisers?.map(a => a.value) || [], owner: owners?.map(o => o.value) || [] };
  const calculateRow = (row) => { row.total = calculateSum(row, selectedCheckboxes(leadTypesChecks, [])); };

  const getDataView = (date) => {
    const m = {
      week: toWeek,
      month: toMonth,
    };
    return m[dataView.value] ? m[dataView.value](date, endDate) : moment(date);
  };

  const leadTypesById = {
    internal: 'New Internal',
    standard: 'New Creator Search/Uploads',
    resubmits: 'Resubmits',
  };

  const currentLeadTypes = toOptions(leadTypesById).filter(line => leadTypesChecks[line.value]);

  const labels = {
    advertiser: currentAdvertisers,
    owner: currentOwners,
    leadType: currentLeadTypes,
  };

  const chartCols = (breakdown, include = () => true) => {
    const isBreakdown = (prospect, _breakdown, value) => {
      if (_breakdown === 'leadType') return prospect[value] > 0;
      return (prospect[_breakdown]?.toString() === value.toString());
    };
    const c = {};
    labels[breakdown].forEach((r) => {
      c[r.value] = (res, row, col) => ((include(row) && isBreakdown(row, breakdown, r.value)) ? res + row[(breakdown === 'leadType') ? col : 'total'] : res);
    });
    return c;
  };

  const afterGroup = (data, actions) => {
    Object.keys(actions).forEach((chart) => {
      const chartData = Object.values(data[chart]).sort((a, b) => (a.title > b.title ? 1 : -1));
      if (dataView.value === 'over_time') {
        chartData.forEach((row, i) => {
          Object.keys(row).forEach((col) => {
            if (col !== 'title') {
              if (i > 0) chartData[i][col] += chartData[i - 1][col];
            }
          });
        });
      }
      actions[chart](chartData);
    });
  };

  const groupForBarChart = (data, include = () => true) => {
    groupByKey(data, row => getDataView(row.date).format('YYYY-MM-DD'), chartCols(barBreakdown.value, include), date => date);
  };

  const checkBrandRejected = row => isBrandRejected({
    brandStatus: row.brandStatus,
    approvalStatus: {
      brand: { history: row.brandStatusHistory },
      influencelogic: { history: row.ilStatusHistory },
    },
  });

  const barChartResults = {
    leadSubmissions: { data: groupForBarChart },
    ilRejected: { data: (data) => { groupForBarChart(data, p => p.ilStatus === 'Rejected'); } },
    brandRejected: { data: (data) => { groupForBarChart(data, checkBrandRejected); } },
  };

  const setBarChartsData = (data) => {
    afterGroup(data, { leadSubmissions: setLeadSubmissionsData, ilRejected: setIlRejectedData, brandRejected: setBrandRejectedData });
  };

  useEffect(() => {
    setFetched(false);
    setProspectsData([]);
  }, [startDate, endDate]);

  useEffect(() => {
    setShowBarLabels(showLabels);
  }, [showLabels]);

  useEffect(() => {
    const v = [...currentLeadTypes];
    if (showTotalLine) {
      v.push({ value: 'total', label: 'Total' });
    }
    setLeadTypeLines(v);
  }, [leadTypesChecks, showTotalLine]);

  useEffect(() => {
    setBarChartsLoading(true);
    setTimeout(() => {
      setBarChartsData(groupData(prospectsData, {
        calculateRow,
        filters,
        results: barChartResults,
      }));
      setBarChartsLoading(false);
    }, 1);
  }, [barBreakdown]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      const lineChartCols = chartCols('leadType');
      if (showTotalLine) lineChartCols.total = (res, row) => res + row.total;
      const tableCols = {
        total: (res, row) => res + row.total,
        ilApproved: (res, row) => ((row.ilStatus === 'Approved') ? res + row.total : res),
        ilRejected: (res, row) => ((row.ilStatus === 'Rejected') ? res + row.total : res),
        brandApproved: (res, row) => ((row.brandStatus === 'Approved') ? res + row.total : res),
        brandRejected: (res, row) => (checkBrandRejected(row) ? res + row.total : res),
      };
      const groupedData = groupData(prospectsData, {
        calculateRow,
        filters,
        results: {
          ...barChartResults,
          lineChart: { data: (data) => { groupByKey(data, row => getDataView(row.date).format('YYYY-MM-DD'), lineChartCols, date => date); } },
          table: {
            data: (data) => {
              groupByKey(data, row => row.advertiser?.toString(), tableCols, id => id && advertisersById[id]);
            },
          },
        },
      });
      setBarChartsData(groupedData);
      const { lineChart, table } = groupedData;
      afterGroup({ lineChart }, { lineChart: setLineChartData });
      Object.keys(table).forEach((v) => {
        Object.keys(tableCols).forEach((c) => {
          if (c !== 'total') {
            const baseKey = ['brandApproved', 'brandRejected'].includes(c) ? 'ilApproved' : 'total';
            table[v][`${c}Percentage`] = calculatePercentage(table[v][c], table[v][baseKey]);
          }
        });
      });
      setTableData(Object.values(table));
      setLoading(false);
    }, 1);
  }, [prospectsData, dataView, leadTypesChecks, advertisers, owners]);

  const fetchReport = async () => {
    setLoading(true);
    setFetched(false);
    try {
      const resp = await axios.post('/api/portal/reports/post-lead-operations', {
        startDate: moment(startDate).startOf('day').toDate(),
        endDate: moment(endDate).endOf('day').toDate(),
        identity,
      });
      if (resp.data.success) {
        setProspectsData(resp.data.prospects);
        setLoading(false);
        setFetched(true);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
    return true;
  };

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: false,
    placeholder: 'Search...',
    sortBy: 'brand',
    direction: 'asc',
    useFlex: true,
  };

  const columns = Columns();
  if (!CheckPermission('research----leadreports----leadoperations')) return (<PermissionComponent />);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Leads', path: false },
              { title: 'Lead Operations Dashboard', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Lead Operations Dashboard</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3" >
                <Col>
                  <PeriodTabs
                    from={startDate}
                    setFrom={(value) => { setStartDate(moment(value).startOf('day').format('YYYY-MM-DD')); }}
                    to={endDate}
                    setTo={(value) => { setEndDate(moment(value).endOf('day').format('YYYY-MM-DD')); }}
                    periods={getPeriods({ lastMonths: [4], showByDate: true })}
                    selected="4_month"
                    disabled={loading}
                  />
                </Col>
                <Col lg="auto">
                  <div>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchReport();
                      }}
                      className="btn-sm"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Actions
                    dataView={dataView}
                    setDataView={setDataView}
                    showLabels={showLabels}
                    setShowLabels={setShowLabels}
                    leadTypes={leadTypesChecks}
                    setLeadTypes={setLeadTypesChecks}
                    advertisers={advertisers}
                    setAdvertisers={setAdvertisers}
                    owners={owners}
                    setOwners={setOwners}
                    setAdvertisersById={setAdvertisersById}
                    setOwnersById={setOwnersById}
                  />
                </Col>
              </Row>
              {loading && (
                <div className="w-100 text-center mt-4"><Spinner color="primary" size="lg" /></div>
              )}
              {!loading && fetched && (
                <>
                  <Row className="mt-3">
                    <Col>
                      <h4 className="float-left mb-3">Lead Submissions</h4>
                      <div className="float-left ml-4">
                        <CheckBoxField
                          name="showTotalLine"
                          label="Show Total Line"
                          value={showTotalLine}
                          onChange={(e) => { if (e?.target) setShowTotalLine(e.target.checked); }}
                        />
                      </div>
                      <div className="clearfix" />
                      <LineChart
                        data={lineChartData}
                        lines={leadTypeLines}
                        xKey="title"
                        showLabels={showLabels}
                        xLabel={v => xLabel(v, dataView.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="4" lg="3">
                      <Label className="bold-text">Bar Breakdown</Label>
                      <Select
                        value={barBreakdown}
                        options={[
                          { value: 'advertiser', label: 'Brand' },
                          { value: 'owner', label: 'Prospect Owner' },
                          { value: 'leadType', label: 'Lead Type' },
                        ]}
                        onChange={(v) => { setBarBreakdown(v); }}
                        className="mb-2"
                      />
                      <CheckBoxField
                        name="showBarLabels"
                        label="Show Labels on Charts"
                        value={showBarLabels}
                        onChange={(e) => { if (e?.target) setShowBarLabels(e.target.checked); }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      {barChartsLoading ? (
                        <div className="w-100 text-center mb-4"><Spinner color="primary" size="lg" /></div>
                      ) : (
                        <BarCharts
                          bars={labels[barBreakdown.value]}
                          xKey="title"
                          stackId="a"
                          showLabels={showBarLabels}
                          xLabel={v => xLabel(v, dataView.value)}
                          charts={{
                            total: { label: 'Lead Submissions', data: leadSubmissionsData, xLabel: 'Submission Date' },
                            ilRejected: { label: 'IL Rejected Leads', data: ilRejectedData, xLabel: 'Submission Date' },
                            brandRejected: { label: 'Brand Rejected Leads', data: brandRejectedData, xLabel: 'Submission Date' },
                          }}
                          col="col-md-6 col-xl-3"
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <ReactTableBase
                        key="searchable"
                        columns={columns}
                        data={tableData}
                        tableConfig={tableConfig}
                      />
                      {tableData?.length > 0 && (
                        <div className="mt-3">
                          <InformationOutlineIcon />
                          &nbsp;
                          By Submission Date
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
