import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch } from 'react-redux';
// import Select from 'react-select';
// import PropTypes from 'prop-types';
// import DatePicker from 'react-datepicker';
import { Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Label, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
// import MatTableForRedux from '../../../../shared/tables/materialTable/components/MatTableForRedux';
import formater from '../../../../shared/helpers/WVFormatter';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
// import Item from '../StatItem';
import { getConstant } from '../../../../shared/helpers/WVConstants';
// import CheckBoxField from '../../../../shared/components/CheckBox';
// import Collapse from '../../../../shared/components/Collapse';
// import HubspotDealLink from '../../../../shared/components/HubspotDealLink';
import TableCellContextMenu from '../../../../shared/components/table/TableCellContextMenu';
import { showModalById } from '../../../../redux/reducers/TableReducer';
import CheckBoxField from '../../../../shared/components/CheckBox';
import Modal, { modalId } from '../EOMReport2/Modal';
import { calculateGRandGPDeals } from '../ReportGroupBy';

/* const dealTypes = {
  hybrid: 'Hybrid',
  cpa: 'CPA/Ambassador',
  upfront: 'Upfront Only',
  upfrontCPM: 'Upfront CPM',
  agentCommission: 'Agent Commission',
  mediaLicense: 'Media License',
  bonus: 'Bonus',
};
 const getTotalPayment = (payments) => {
  let total = 0;
  payments.forEach(({ amount }) => { total += amount; });
  return total || 0;
};
const getAmountByConversion = (deal, payout) => {
  // if ([dealTypes.upfront, dealTypes.upfrontCPM].includes(deal.dealTerms.type_of_deal)) {
  //   return payout;
  // }
  if ([dealTypes.bonus].includes(deal.dealTerms.type_of_deal)) {
    return 0;
  }
  if (deal.dealTerms.cpa_percentage === 0) console.log(deal);
  if (deal.dealTerms.cpa_percentage || deal.dealTerms.cpa_percentage === 0) {
    return Number(payout || 0) * (Number(deal.dealTerms.cpa_percentage || 0) / 100);
  } else if (deal.dealTerms.per_conversion_amount || deal.dealTerms.per_conversion_amoun === 0) {
    return Number(deal.dealTerms.per_conversion_amount);
  }
  if ([dealTypes.cpa].includes(deal.dealTerms.type_of_deal)) {
    return 0;
  }
  return Number(payout || 0);
};
const calculateTotalRevenue = ({ deal, report }) => {
  let totalRevenue = Number(report?.revenue);
  totalRevenue += (Number(deal.dealTerms?.bh_cpm_total_cost || 0));


  return Number(totalRevenue || 0);
};
const calculateConversionsCosts = ({ deal, conversions, invoicePayout }) => {
  let totalPayout = 0;
  conversions.forEach(({ payout }) => {
    totalPayout += getAmountByConversion(deal, payout);
  });

  if ([dealTypes.hybrid].includes(deal.dealTerms.type_of_deal) && deal.dealTerms.guaranteed_payment_recoverable === 'Yes') {
    totalPayout = invoicePayout;
  }
  // if ([dealTypes.upfront, dealTypes.upfrontCPM, dealTypes.bonus].includes(deal.dealTerms.type_of_deal)) {
  //   totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  // }
  return totalPayout;
};

const calculateBillCosts = ({ deal, startDate, endDate }) => {
  const closeDate = deal.brandApprovedDate ? moment.tz(moment(deal.brandApprovedDate).format('YYYY-MM-DD'), 'America/New_York') : null;
  let totalPayout = 0;
  if ([dealTypes.upfront, dealTypes.upfrontCPM].includes(deal.dealTerms.type_of_deal) && closeDate && closeDate >= moment(startDate) && closeDate < moment(endDate).endOf('month')) {
    totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  }
  const closeDateBonus = deal.dealTerms.content_release_date ? moment.tz(moment(deal.dealTerms.content_release_date).format('YYYY-MM-DD'), 'America/New_York') : null;

  if ([dealTypes.bonus].includes(deal.dealTerms.type_of_deal) && closeDateBonus && closeDateBonus >= moment(startDate) && closeDateBonus < moment(endDate).endOf('month')) {
    totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  }
  if ([dealTypes.hybrid].includes(deal.dealTerms.type_of_deal) && closeDate && closeDate >= moment(startDate) && closeDate < moment(endDate)) {
    totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  }
  return totalPayout;
}; */

const GenerateTable = ({
  loading,
  advertisers,
  items,
  startDate,
  endDate,
  // offers,
  // csps,
  // brands,
}) => {
  const [activeTab, setActiveTab] = useState('1');
  const dealPipelines = getConstant('deal', 'pipelines', []);
  const [dealTypeFilters, setDealTypeFilters] = useState({
    'CPA/Ambassador': true,
    Hybrid: true,
    'Upfront Only': true,
    'Upfront CPM': true,
    'Media License': true,
    Bonus: true,
  });
  const handleChangeDealTypeFilters = (event, name) => {
    console.log('event change');
    if (event && event.target) {
      const { checked } = event.target;
      setDealTypeFilters({
        ...dealTypeFilters,
        [name]: checked,
      });
    }
  };
  const dispatch = useDispatch();
  const dealStages = [];
  dealPipelines.forEach((pipeline) => {
    Object.keys(pipeline.stages).forEach((stageKey) => {
      dealStages.push({ id: stageKey, label: pipeline.stages[stageKey] });
    });
  });
  const [brands, dealItems, offers] = useMemo(() => {
    const brandsArray = {};
    const offersArray = {};
    const cspArray = {};
    // const deals = {};
    const deals = calculateGRandGPDeals(items, startDate, endDate);
    /* Object.values(items).forEach((item) => {
      Object.values(item).forEach(({
        deal,
        conversionsArray,
        report,
        offer,
        influencer,
        admin,
        invoicePayment,
      }) => {
        if (!deals[deal._id]) {
          deals[deal._id] = {
            deal,
            conversionsArray,
            report: {
              revenue: Number(report?.Stat?.revenue || 0),
              payout: Number(report?.Stat?.payout || 0),
            },
            offer,
            influencer,
            admin,
            invoicePayout: 0,
          };
        } else {
          deals[deal._id].conversionsArray = deals[deal._id].conversionsArray.concat(conversionsArray);
          deals[deal._id].report.revenue += Number(report?.Stat?.revenue || 0);
          deals[deal._id].report.payout += Number(report?.Stat?.payout || 0);
        }
        if (deal.invoice) {
          const totalPaidForInvoice = getTotalPayment(deal.invoice?.payments || []);
          const diff = Number(deal.dealTerms.guaranteed_payment_contract_amount || 0) - (totalPaidForInvoice || 0);
          console.log(diff);
          if (invoicePayment) {
            // console.log([deals[deal._id].report.payout, Number(invoicePayment?.amount || 0), (deals[deal._id].report.payout - Number(invoicePayment?.amount || 0))]);
            deals[deal._id].invoicePayout += deals[deal._id].report.payout > Number(invoicePayment?.amount || 0) ? (deals[deal._id].report.payout - Number(invoicePayment?.amount || 0)) : 0;
          } else {
            deals[deal._id].invoicePayout += diff >= deals[deal._id].report.payout ? 0 : deals[deal._id].report.payout - diff;
          }
        }
      });
    }); */
    /* Object.values(items).forEach((item) => {
      Object.values(item).forEach(({
        deal,
        conversionsArray,
        report,
        offer,
        influencer,
      }) => {
        if (!deals[deal._id]) {
          deals[deal._id] = {
            deal,
            conversionsArray,
            report: {
              revenue: Number(report?.Stat?.revenue || 0),
              payout: Number(report?.Stat?.payout || 0),
            },
            offer,
            influencer,
          };
        } else {
          deals[deal._id].conversionsArray = deals[deal._id].conversionsArray.concat(conversionsArray);
          deals[deal._id].report.revenue += Number(report?.Stat?.revenue || 0);
          deals[deal._id].report.payout += Number(report?.Stat?.payout || 0);
        }
      });
    }); */
    console.log(Object.values(deals));
    Object.values(deals).forEach(({
      deal,
      conversionsArray,
      report,
      offer,
      admin,
      // invoicePayout,
    }) => {
      /* deals[deal._id].conversionPayout = calculateConversionsCosts({ deal, conversions: conversionsArray || [], invoicePayout });
      deals[deal._id].billPayout = calculateBillCosts({ deal, startDate, endDate });
      deals[deal._id].totalRevenue = calculateTotalRevenue({ deal, report });
      deals[deal._id].totalPayout = deals[deal._id].conversionPayout + deals[deal._id].billPayout;
      deals[deal._id].totalPayoutWithMulligan = deal.dealTerms.mulligan_ && deals[deal._id].totalRevenue < deals[deal._id].totalPayout ? 0 : deals[deal._id].conversionPayout + deals[deal._id].billPayout;
      deals[deal._id].grossProfit = deals[deal._id].totalRevenue - deals[deal._id].totalPayoutWithMulligan;
      */
      // deals[deal._id].grossProfit = (deal.dealTerms.mulligan_ ? deals[deal._id].totalRevenue : deals[deal._id].totalRevenue - deals[deal._id].conversionPayout - deals[deal._id].billPayout);
      // deals[deal._id].totalRevenue - deals[deal._id].conversionPayout - deals[deal._id].billPayout;
      if (!brandsArray[offer.advertiserTuneid]) {
        brandsArray[offer.advertiserTuneid] = {
          tuneId: offer.advertiserTuneid,
          conversionPayout: 0,
          billPayout: 0,
          grossProfit: 0,
          brand: offer.advertiser,
          totalRevenue: 0,
          revenue: 0,
        };
      }
      if (!offersArray[offer.tuneId]) {
        offersArray[offer.tuneId] = {
          conversionPayout: 0,
          billPayout: 0,
          grossProfit: 0,
          tuneId: offer.tuneId,
          totalRevenue: 0,
          revenue: 0,
          offer,
        };
      }
      if (!cspArray[deal?.hubspot_owner_id || 'notAssigned']) {
        cspArray[deal?.hubspot_owner_id || 'notAssigned'] = {
          conversionPayout: 0,
          billPayout: 0,
          grossProfit: 0,
          tuneId: offer.tuneId,
          totalRevenue: 0,
          revenue: 0,
          offer,
          admin,
        };
      }

      brandsArray[offer.advertiserTuneid].conversions = conversionsArray;
      brandsArray[offer.advertiserTuneid].revenue += Number(report.revenue || 0);
      brandsArray[offer.advertiserTuneid].conversionPayout += deals[deal._id].conversionPayout;// calculateConversionsCosts({ deal, conversions: conversionsArray, invoicePayout });
      brandsArray[offer.advertiserTuneid].billPayout += deals[deal._id].billPayout;// calculateBillCosts({ deal, startDate, endDate });
      brandsArray[offer.advertiserTuneid].totalRevenue += deals[deal._id].totalRevenue; // calculateTotalRevenue({ deal, report });
      brandsArray[offer.advertiserTuneid].grossProfit += deals[deal._id].grossProfit;

      offersArray[offer.tuneId].conversions = conversionsArray;
      offersArray[offer.tuneId].revenue += Number(report.revenue || 0);
      offersArray[offer.tuneId].conversionPayout += deals[deal._id].conversionPayout;// calculateConversionsCosts({ deal, conversions: conversionsArray, invoicePayout });
      offersArray[offer.tuneId].billPayout += deals[deal._id].billPayout; // calculateBillCosts({ deal, startDate, endDate });
      offersArray[offer.tuneId].totalRevenue += deals[deal._id].totalRevenue; // calculateTotalRevenue({ deal, report });
      offersArray[offer.tuneId].grossProfit += deals[deal._id].grossProfit;

      /* cspArray[deal?.hubspot_owner_id || 'notAssigned'].conversions = conversionsArray;
      cspArray[deal?.hubspot_owner_id || 'notAssigned'].revenue += Number(report.revenue || 0);
      cspArray[deal?.hubspot_owner_id || 'notAssigned'].conversionPayout += deals[deal._id].conversionPayout;// calculateConversionsCosts({ deal, conversions: conversionsArray, invoicePayout });
      cspArray[deal?.hubspot_owner_id || 'notAssigned'].billPayout += deals[deal._id].billPayout; // calculateBillCosts({ deal, startDate, endDate });
      cspArray[deal?.hubspot_owner_id || 'notAssigned'].totalRevenue += deals[deal._id].totalRevenue; // calculateTotalRevenue({ deal, report });
      cspArray[deal?.hubspot_owner_id || 'notAssigned'].grossProfit = brandsArray[offer.advertiserTuneid].totalRevenue - brandsArray[offer.advertiserTuneid].conversionPayout - brandsArray[offer.advertiserTuneid].billPayout;
      */
    });
    console.log(Object.values(deals));
    return [Object.values(brandsArray), Object.values(deals), Object.values(offersArray), Object.values(cspArray)];
  }, [items]);
  console.log(brands);
  const columnsBrands = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: deal => deal?.brand.companyName || '',
        id: 'Brand',
        Cell: ({ value }) => value || '',
        width: 450,
      },
      {
        Header: 'Conversions Revenue',
        accessor: deal => ((deal.revenue || 0)),
        id: 'revenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Gross Revenue',
        accessor: deal => ((deal.totalRevenue || 0)),
        id: 'grossRevenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Conversion Costs',
        accessor: deal => (Number(deal.conversionPayout || 0)),
        id: 'conversionCosts',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.conversionCosts || 0) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Bill Costs',
        accessor: deal => ((deal.billPayout || 0)),
        id: 'billPayout',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.billPayout) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Gross Profit (Cash)',
        accessor: deal => ((deal.grossProfit || 0)),
        id: 'grossProfit',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossProfit) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
    ],
    [advertisers],
  );
  const columnsOffers = useMemo(
    () => [
      {
        Header: 'Offer',
        accessor: deal => deal?.offer.name || '',
        id: 'Offer',
        Cell: ({ value }) => value || '',
        width: 450,
      },
      {
        Header: 'Conversions Revenue',
        accessor: deal => ((deal.revenue || 0)),
        id: 'revenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Gross Revenue',
        accessor: deal => ((deal.totalRevenue || 0)),
        id: 'grossRevenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Conversion Costs',
        accessor: deal => (Number(deal.conversionPayout || 0)),
        id: 'conversionCosts',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.conversionCosts || 0) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Bill Costs',
        accessor: deal => ((deal.billPayout || 0)),
        id: 'billPayout',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.billPayout) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Gross Profit (Cash)',
        accessor: deal => ((deal.grossProfit || 0)),
        id: 'grossProfit',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossProfit) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
    ],
    [],
  );
  /* const columnsCSP = useMemo(
    () => [
      {
        Header: 'CSP',
        accessor: deal => deal?.admin || '',
        id: 'CSP',
        Cell: ({ value }) => value || '',
        width: 450,
      },
      {
        Header: 'Conversions Revenue',
        accessor: deal => ((deal.revenue || 0)),
        id: 'revenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Gross Revenue',
        accessor: deal => ((deal.totalRevenue || 0)),
        id: 'grossRevenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Conversion Costs',
        accessor: deal => (Number(deal.conversionPayout || 0)),
        id: 'conversionCosts',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.conversionCosts || 0) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Bill Costs',
        accessor: deal => ((deal.billPayout || 0)),
        id: 'billPayout',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.billPayout) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Gross Profit (Cash)',
        accessor: deal => ((deal.grossProfit || 0)),
        id: 'grossProfit',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossProfit) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
    ],
    [],
  ); */

  const columnItems = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: p => `${p?.offer.advertiser.companyName || ''}`,
        id: 'brand',
        Cell: p => useMemo(() => (
          <div style={{ width: '120px' }}>
            {p.value}
          </div>
        ), [p.row.original]),
      },
      {
        Header: 'Offer',
        accessor: p => `${p?.offer?.name || ''}`,
        id: 'offer',
        Cell: p => useMemo(() => (
          <div style={{ width: '120px' }}>
            {p.value}
          </div>
        ), [p.row.original]),
      },
      {
        Header: 'Deal Type',
        accessor: deal => deal.deal.dealTerms.type_of_deal,
        id: 'deal_type',
        Cell: p => (
          <div style={{ width: '120px' }}>
            {p.value}
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: p => p.deal.dealTerms.cpa_status,
        id: 'cpa_status',
        Cell: p => useMemo(() => (
          <div style={{ width: '100px' }}>
            {p.value}
          </div>
        ), [p.value]),
      },
      {
        Header: 'Deal Stage',
        accessor: p => p.deal.dealstage,
        id: 'dealstage',
        Cell: p => useMemo(() => {
          // console.log(p);
          let stage = { id: null, label: '-' };
          if (p.value) {
            stage = dealStages.find(item => item.id === p.value);
          }
          return (
            <div style={{ width: '100px' }}>
              {stage.label}
            </div>
          );
        }, [p.value]),
      },
      {
        Header: 'Creator',
        accessor: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
        id: 'Creator',
        Cell: p => useMemo(() => (
          <div style={{ width: '120px' }}>
            <Tooltip title="Open Creator Profile">
              <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.influencer?._id}`}>{p.value}</a>
            </Tooltip>
          </div>), [p.row.original]),
      },
      /* {
        Header: 'Manager',
        accessor: p => `${p?.employee || ''}`,
        id: 'manager',
        Cell: p => useMemo(() => p.value, [p.row.original]),
      }, */
      {
        Header: 'Brand Approved Date',
        accessor: deal => deal.deal.brandApprovedDate,
        id: 'brandApprovedDate',
        Cell: p => useMemo(() => (
          <div style={{ width: '180px' }}>
            {p.value ? moment(p.value.split('T')[0]).format('MM/DD/YY') : '-'}
          </div>
        ), [p.value]),
      },
      {
        Header: 'Revenue',
        accessor: deal => Number(deal.totalRevenue || 0),
        id: 'revenue',
        Cell: ({ value }) => useMemo(() => (
          <div style={{ width: '100px' }}>
            {formater.formatCurrency(value || 0)}
          </div>), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Costs',
        accessor: deal => (deal.totalPayoutWithMulligan),
        id: 'totalCosts',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => (info.rows.reduce((sum, row) => Number(row.values.totalCosts || 0) + sum, 0)),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Total Mulligan Amount',
        accessor: (deal) => {
          const a = deal.totalPayout - deal.totalPayoutWithMulligan;
          return a;
        },
        id: 'total_mulligan_amount',
        Cell: ({ value }) => useMemo(() => (
          <div style={{ width: '180px' }}>
            {formater.formatCurrency(value || 0)}
          </div>), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.total_mulligan_amount || 0) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Profit',
        accessor: deal => Number(deal.grossProfit || 0),
        id: 'profit',
        Cell: ({ value }) => useMemo(() => (
          <div style={{ width: '80px' }}>
            {formater.formatCurrency(value || 0)}
          </div>), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.profit) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: '',
        accessor: '_id',
        // eslint-disable-next-line react/prop-types
        Cell: p => (
          <div style={{ textAlign: 'right' }}><TableCellContextMenu
            options={[
              { label: 'Display Calculation', handler: () => { dispatch(showModalById(modalId, { record: p.row.original })); } },
            ]}
          />
          </div>),
        disableGlobalFilter: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    [items],
  );


  const tableConfig = {
    isEditable: false,
    isResizable: false,
    useFlex: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'grossProfit',
    direction: 'desc',
  };
  const filteredDealItems = useMemo(() => { // filter items
    const activeDealTypes = Object.keys(dealTypeFilters).filter(k => dealTypeFilters[k]).map(k => k);
    return dealItems.filter(item => activeDealTypes.includes(item?.deal.dealTerms.type_of_deal));
  }, [dealItems, dealTypeFilters]);
  return (
    <div>
      {!loading && brands.length > 0 ? (
        <Row className="mt-3">
          <Col>
            <Row >
              <Col className="tabs">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        setActiveTab('1');
                      }}
                    >
                      Brands
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        setActiveTab('2');
                      }}
                    >
                      Offers
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => {
                        setActiveTab('4');
                      }}
                    >
                      Deals
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col className="mt-3">
                        {brands.length > 0 ? (
                          <div className="float-right mr-2">
                            <DownloadCSVButton
                              records={brands}
                              className="mt-2"
                              fileName="BrandGrossProfitReport.csv"
                              csvArrayColumns={[
                                {
                                  label: 'Brand',
                                  func: (deal) => {
                                    const advertiser = advertisers.find(a => a.tuneId === deal.tuneId);
                                    return advertiser?.companyName || '';
                                  },
                                },
                                {
                                  label: 'Gross Revenue',
                                  func: record => (record.totalRevenue || ''),
                                },
                                {
                                  label: 'Gross Profit',
                                  func: record => (record.grossProfit || ''),
                                },
                              ]}
                            >
                              Download CSV
                            </DownloadCSVButton>
                            <DownloadCSVButton
                              records={dealItems}
                              className="mt-2"
                              fileName="revenue_costs_like_eom.csv"
                              csvArrayColumns={[
                                {
                                  label: 'Brand',
                                  func: p => `${p?.offer?.advertiser?.companyName || ''}`,
                                },
                                {
                                  label: 'Offer',
                                  func: p => `${p?.offer?.name || ''}`,
                                },
                                {
                                  label: 'Deal Type',
                                  func: p => `${p?.deal?.dealTerms.type_of_deal || ''}`,
                                },
                                {
                                  label: 'Deal Stage',
                                  func: (p) => {
                                    let stage = { id: null, label: '-' };
                                    if (p.deal.dealstage) {
                                      stage = dealStages.find(item => item.id === p.deal.dealstage);
                                    }
                                    return stage.label;
                                    // `${p?.deal.dealTerms.cpa_status || ''}`
                                  },
                                },
                                {
                                  label: 'Brand Approved Date',
                                  func: p => `${p.deal?.brandApprovedDate ? moment(p.deal.brandApprovedDate.split('T')[0]).format('MM/DD/YY') : '-'}`,
                                },
                                {
                                  label: 'Revenue',
                                  func: deal => (deal.totalRevenue || 0),
                                },
                                {
                                  label: 'Costs',
                                  func: deal => (deal.conversionPayout || 0),
                                },
                                {
                                  label: 'Bill Costs',
                                  func: deal => (deal.billPayout || 0),
                                },
                                {
                                  label: 'Profit',
                                  func: deal => (deal.grossProfit || 0),
                                },
                            ]}
                            >
                              Download Revenue & Costs CSV
                            </DownloadCSVButton>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        {!loading && brands.length > 0 ? (
                          <ReactTableBase
                            key="searchable"
                            columns={columnsBrands}
                            data={brands}
                            tableConfig={tableConfig}
                          />) : null
                        }
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col className="mt-3">
                        {brands.length > 0 ? (
                          <div className="float-right mr-2">
                            <DownloadCSVButton
                              records={offers}
                              className="mt-2"
                              fileName="OfferGrossProfitReport.csv"
                              csvArrayColumns={[
                                {
                                  label: 'Offer',
                                  func: record => (record?.offer?.name || ''),
                                },
                                {
                                  label: 'Gross Revenue',
                                  func: record => (record.totalRevenue || ''),
                                },
                                {
                                  label: 'Gross Profit',
                                  func: record => (record.grossProfit || ''),
                                },
                              ]}
                            >
                              Download CSV
                            </DownloadCSVButton>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        {!loading && brands.length > 0 ? (
                          <ReactTableBase
                            key="searchable2"
                            columns={columnsOffers}
                            data={offers}
                            tableConfig={tableConfig}
                          />) : null
                        }
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <Row>
                      <Col className="mt-3">
                        {brands.length > 0 ? (
                          <div className="float-right mr-2">
                            <DownloadCSVButton
                              records={filteredDealItems}
                              className="mt-2"
                              fileName="revenue_costs_like_eom.csv"
                              csvArrayColumns={[
                                {
                                  label: 'Brand',
                                  func: p => `${p?.offer?.advertiser?.companyName || ''}`,
                                },
                                {
                                  label: 'Offer',
                                  func: p => `${p?.offer?.name || ''}`,
                                },
                                {
                                  label: 'Deal Type',
                                  func: p => `${p?.deal?.dealTerms.type_of_deal || ''}`,
                                },
                                {
                                  label: 'Deal Stage',
                                  func: (p) => {
                                    let stage = { id: null, label: '-' };
                                    if (p.deal.dealstage) {
                                      stage = dealStages.find(item => item.id === p.deal.dealstage);
                                    }
                                    return stage.label;
                                    // `${p?.deal.dealTerms.cpa_status || ''}`
                                  },
                                },
                                {
                                  label: 'Brand Approved Date',
                                  func: p => `${p.deal?.brandApprovedDate ? moment(p.deal.brandApprovedDate.split('T')[0]).format('MM/DD/YY') : '-'}`,
                                },
                                {
                                  label: 'Revenue',
                                  func: deal => (deal.totalRevenue || 0),
                                },
                                {
                                  label: 'Costs',
                                  func: deal => (deal.conversionPayout || 0),
                                },
                                {
                                  label: 'Bill Costs',
                                  func: deal => (deal.billPayout || 0),
                                },
                                {
                                  label: 'Profit',
                                  func: deal => (deal.grossProfit || 0),
                                },
                            ]}
                            >
                              Download CSV
                            </DownloadCSVButton>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label className="bold-text" style={{ marginBottom: '14px' }}>
                          Deal Type
                        </Label>
                        <FormGroup
                          style={{ marginBottom: '10px' }}
                        >
                          <CheckBoxField
                            name="CPA"
                            label="CPA/Ambassador"
                            value={dealTypeFilters['CPA/Ambassador']}
                            onChange={event => handleChangeDealTypeFilters(event, 'CPA/Ambassador')}
                          />
                        </FormGroup>
                        <FormGroup
                          style={{ marginBottom: '10px' }}
                        >
                          <CheckBoxField
                            name="Hybrid"
                            label="Hybrid"
                            value={dealTypeFilters.Hybrid}
                            onChange={event => handleChangeDealTypeFilters(event, 'Hybrid')}
                          />
                        </FormGroup>
                        <FormGroup
                          style={{ marginBottom: '10px' }}
                        >
                          <CheckBoxField
                            name="UpfrontOnly"
                            label="Upfront Only"
                            value={dealTypeFilters['Upfront Only']}
                            onChange={event => handleChangeDealTypeFilters(event, 'Upfront Only')}
                          />
                        </FormGroup>
                        <FormGroup className="mb-0">
                          <CheckBoxField
                            name="UpfrontCPM"
                            label="Upfront CPM"
                            value={dealTypeFilters['Upfront CPM']}
                            onChange={event => handleChangeDealTypeFilters(event, 'Upfront CPM')}
                          />
                        </FormGroup>
                        <FormGroup className="mb-0">
                          <CheckBoxField
                            name="MediaLicense"
                            label="Media License"
                            value={dealTypeFilters['Media License']}
                            onChange={event => handleChangeDealTypeFilters(event, 'Media License')}
                          />
                        </FormGroup>
                        <FormGroup className="mb-0">
                          <CheckBoxField
                            name="Bonus"
                            label="Bonus"
                            value={dealTypeFilters.Bonus}
                            onChange={event => handleChangeDealTypeFilters(event, 'Bonus')}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        {!loading && brands.length > 0 ? (
                          <ReactTableBase
                            key="searchable4"
                            columns={columnItems}
                            data={filteredDealItems}
                            tableConfig={tableConfig}
                          />) : null
                        }
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Row>) : null
      }
      <Modal />
    </div>
  );
};
GenerateTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  advertisers: PropTypes.arrayOf(PropTypes.any).isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default GenerateTable;
