import React from 'react';
import ReactTags from 'react-tag-autocomplete';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';

class Tags extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tags: [],
      suggestions: [],
    };

    this.reactTags = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  onDelete = (i) => {
    const tags = this.state.tags.slice(0);
    const tag = tags[i];
    tags.splice(i, 1);
    this.setState({ tags });
    const { id } = this.props;
    axios({
      method: 'post',
      url: '/api/portal/tags/remove-agent-tag',
      data: {
        id,
        tag,
      },
    }).then((response) => {
      if (response.data.success === true) {
        console.log('Success');
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  onAddition = (tag) => {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
    const { id } = this.props;
    axios({
      method: 'post',
      url: '/api/portal/tags/add-tag',
      data: {
        type: 'agent',
        id,
        tag,
      },
    }).then((response) => {
      if (response.data.success === true) {
        const updatedTags = tags.map((item) => {
          if (item.name === response.data.newTag.name) {
            return {
              ...item,
              id: response.data.newTag.id,
            };
          }
          return item;
        });
        this.setState({ tags: updatedTags });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  fetchData = () => {
    const { id } = this.props;
    this.setState({ loading: true });
    axios({
      method: 'get',
      url: `/api/portal/tags/get-agent-tags?id=${id}`,
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          tags: response.data.tags,
          suggestions: response.data.suggestions,
          loading: false,
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  render() {
    const { loading } = this.state;
    return (
      <div>
        <h4 className="mb-1">Tags</h4>
        {!loading && (
          <ReactTags
            ref={this.reactTags}
            tags={this.state.tags}
            suggestions={this.state.suggestions}
            onDelete={this.onDelete}
            onAddition={this.onAddition}
            delimiters={['Enter', ',']}
            allowNew
          />
        )}
      </div>
    );
  }
}

export default Tags;
