import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { axios } from 'ApiClient';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../../App/store/with-notice';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';
import {
  changeCurrentCpmProspectId,
  changeCurrentCpmProspectName,
  changeCurrentCpmProspectHsDealId,
  getAdminUsers,
} from '../../../../../../redux/reducers/admin/ResearchReducer';
import RefreshStatisticsOptionsModal from './modals/RefreshStatisticsOptionsModal';
import NotesModal from './custom/NotesModal';
import DealJustificationModal from './custom/DealJustificationModal';
import DealsListModal from './custom/DealsListModal';
import DeleteCpmCreatorModal from './custom/DeleteCpmCreatorModal';
import AddToInsertionOrderModal from './custom/AddToInsertionOrderModal';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import DeleteCpmCreatorFromOrder from '../../CpmInsertionOrderProspects/components/modals/DeleteCpmCreatorFromOrder';
import PublishLookerProspect from './modals/PublishLookerProspect';
/*
const compareApprovalStatus = {
  Approved: 'approved',
  'In Review': 'inReview',
  Rejected: 'rejected',
};

const getApprovalStatusInitial = () => {
  const filters = parseUrl();
  const initialApprovalStatusFilters = {
    Approved: true,
    'In Review': false,
    Rejected: false,
  };
  if (filters?.approvalStatus) {
    Object.keys(initialApprovalStatusFilters).forEach((key) => {
      const rightKey = Object.keys(compareApprovalStatus).find(keyInState => keyInState === key);
      initialApprovalStatusFilters[key] = filters?.approvalStatus.includes(compareApprovalStatus[rightKey]);
    });
  }
  return initialApprovalStatusFilters;
};

const getApprovalsBrandStatusInitial = () => {
  const filters = parseUrl();
  const initialApprovalStatusFilters = {
    Approved: true,
    'In Review': true,
    Rejected: true,
  };
  if (filters?.approvalsBrandStatus) {
    Object.keys(initialApprovalStatusFilters).forEach((key) => {
      const rightKey = Object.keys(compareApprovalStatus).find(keyInState => keyInState === key);
      initialApprovalStatusFilters[key] = filters?.approvalsBrandStatus.includes(compareApprovalStatus[rightKey]);
    });
  }
  return initialApprovalStatusFilters;
};
*/
const getDealAssignedInitial = () => {
  const filters = parseUrl();
  const initialDealAssignedFilters = {
    Assigned: true,
    'Not Assigned': true,
  };
  if (filters?.dealAssigned) {
    Object.keys(initialDealAssignedFilters).forEach((key) => {
      initialDealAssignedFilters[key] = filters?.dealAssigned.includes(key);
    });
  }
  return initialDealAssignedFilters;
};

const getInsertionOrderAssignedInitial = () => {
  const filters = parseUrl();
  const initialInsertionOrderAssignedFilters = {
    Assigned: false,
    'Not Assigned': true,
  };
  if (filters?.insertionOrderAssigned) {
    Object.keys(initialInsertionOrderAssignedFilters).forEach((key) => {
      if (Array.isArray(filters.insertionOrderAssigned)) {
        initialInsertionOrderAssignedFilters[key] = filters.insertionOrderAssigned.includes(key);
      } else initialInsertionOrderAssignedFilters[key] = filters.insertionOrderAssigned === key;
    });
  }
  return initialInsertionOrderAssignedFilters;
};

const getFilterValueInitial = (filterType) => {
  let initialFilterType = '<=';
  const type = new URLSearchParams(window.location.search).get(filterType);
  if (type) {
    initialFilterType = type;
  }
  return initialFilterType;
};

const getSingleDateInitial = () => {
  let singleDate = null;
  const filters = parseUrl();
  const type = new URLSearchParams(window.location.search).get('createdAtType');
  if (type && type !== 'Between') {
    if (type === '>' || type === '>=' || type === '=') {
      if (filters?.['createdAt.from']) {
        singleDate = new Date(filters['createdAt.from']);
      }
    }
    if (type === '<' || type === '<=') {
      singleDate = new Date(filters['createdAt.to']);
    }
  }
  return singleDate;
};

const CPMProspectsListContainer = ({
  addNotice,
  dispatch,
  history,
  action,
  resource,
}) => {
  const [loadingDeleteCpmCreator, setLoadingDeleteCpmCreator] = useState(false);
  const [showDeleteProspectModal, setShowDeleteProspectModal] = useState(false);
  const [cspOwnersSuggestions, setCspOwnersSuggestions] = useState([]);
  const [brandsSuggestions, setBrandsSuggestions] = useState([]);
  const [dealStagesSuggestions, setDealStagesSuggestions] = useState([]);
  const [insertionOrdersSuggestions, setInsertionOrdersSuggestions] = useState([]);
  const [dealStagesOptions, setDealStagesOptions] = useState([]);
  const [newInsertionOrdersSuggestions, setNewInsertionOrdersSuggestions] = useState([]);
  const getTagsInitial = (tagType, returnObject) => {
    const determineName = (tag) => {
      let result = tag;
      if (tagType === 'cspOwners' && cspOwnersSuggestions.length > 0) {
        cspOwnersSuggestions.forEach((cspOwnerSuggestion) => {
          if (tag === cspOwnerSuggestion.id) {
            result = cspOwnerSuggestion.name;
          }
        });
      }
      if (tagType === 'brands' && brandsSuggestions.length > 0) {
        brandsSuggestions.forEach((brandsSuggestion) => {
          if (tag === brandsSuggestion.id) {
            result = brandsSuggestion.name;
          }
        });
      }
      if (tagType === 'dealStages' && dealStagesSuggestions.length > 0) {
        dealStagesSuggestions.forEach((dealStagesSuggestion) => {
          if (tag === dealStagesSuggestion.id) {
            result = dealStagesSuggestion.name;
          }
        });
      }
      if (tagType === 'insertionOrders' && insertionOrdersSuggestions.length > 0) {
        insertionOrdersSuggestions.forEach((insertionOrdersSuggestion) => {
          if (tag === insertionOrdersSuggestion.id) {
            result = insertionOrdersSuggestion.name;
          }
        });
      }
      return result;
    };
    const determineObject = (tag) => {
      let result = { name: determineName(tag) };
      if (tagType === 'cspOwners' && cspOwnersSuggestions.length > 0) {
        cspOwnersSuggestions.forEach((cspOwnerSuggestion) => {
          if (tag === cspOwnerSuggestion.id) {
            result = { name: cspOwnerSuggestion.name, id: cspOwnerSuggestion.id };
          }
        });
      }
      if (tagType === 'dealStages' && dealStagesSuggestions.length > 0) {
        dealStagesSuggestions.forEach((dealStagesSuggestion) => {
          if (tag === dealStagesSuggestion.id) {
            result = { name: dealStagesSuggestion.name, id: dealStagesSuggestion.id };
          }
        });
      }
      if (tagType === 'insertionOrders' && insertionOrdersSuggestions.length > 0) {
        insertionOrdersSuggestions.forEach((insertionOrdersSuggestion) => {
          if (tag === insertionOrdersSuggestion.id) {
            result = { name: insertionOrdersSuggestion.name, id: insertionOrdersSuggestion.id };
          }
        });
      }
      return result;
    };
    const filters = parseUrl();
    const initialTagFilters = returnObject ? {} : [];
    let filtersProspectTags = [];
    if (filters[tagType] && Array.isArray(filters[tagType])) {
      filtersProspectTags = filters[tagType];
    } else if (filters[tagType]) {
      filtersProspectTags = [filters[tagType]];
    }
    filtersProspectTags.forEach((tag) => {
      if (returnObject) {
        initialTagFilters[determineName(tag)] = true;
      } else {
        initialTagFilters.push(determineObject(tag));
      }
    });
    return initialTagFilters;
  };

  const filters = parseUrl();
  const createdAtType = getFilterValueInitial('createdAtType');
  const [dateFrom, setDateFrom] = useState(filters?.['createdAt.from'] && createdAtType === 'Between' ? new Date(filters['createdAt.from']) : null);
  const [dateTo, setDateTo] = useState(filters?.['createdAt.to'] && createdAtType === 'Between' ? new Date(filters['createdAt.to']) : null);
  const [singleDate, setSingleDate] = useState(getSingleDateInitial());
  const [enableDateAdded, setEnableDateAdded] = useState(false);
  const [dateAddedValue, setDateAddedValue] = useState({ value: createdAtType, label: createdAtType });
  const [version, setVersion] = useState((new Date()).toString());
  const [cspOwnersFilter, setCspOwnersFilter] = useState(getTagsInitial('cspOwners', true));
  const [cspOwnersList, setCspOwnersList] = useState(getTagsInitial('cspOwners', false));
  const [brandsFilter, setBrandsFilter] = useState(getTagsInitial('brands', true));
  const [brandsFilterList, setBrandsFilterList] = useState(getTagsInitial('brands', false));
  const [dealStagesFilter, setDealStagesFilter] = useState(getTagsInitial('dealStages', true));
  const [dealStagesFilterList, setDealStagesFilterList] = useState(getTagsInitial('dealStages', false));
  const [insertionOrdersFilter, setInsertionOrdersFilter] = useState(getTagsInitial('insertionOrders', true));
  const [insertionOrdersFilterList, setInsertionOrdersFilterList] = useState(getTagsInitial('insertionOrders', false));
  // const [approvalStatusFilters, setApprovalStatusFilters] = useState(getApprovalStatusInitial());
  // const [approvalsBrandStatusFilters, setApprovalsBrandStatusFilters] = useState(getApprovalsBrandStatusInitial());
  const [dealAssignedFilters, setDealAssignedFilters] = useState(getDealAssignedInitial());
  const [insertionOrderAssignedFilters, setInsertionOrderAssignedFilters] = useState(getInsertionOrderAssignedInitial());
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [brands, setBrands] = useState([]);
  // const [refreshStatisticsOptionsModalVisible, setRefreshStatisticsOptionsModalVisible] = useState(false);
  const [selectedProspectIds, setSelectedProspectIds] = useState([]);
  const [singleSelectedProspectId, setSingleSelectedProspectId] = useState('');
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showDealsListModal, setShowDealsListModal] = useState(false);
  const [disableNote, setDisableNote] = useState(false);
  const [showDealJustificationModal, setShowDealJustificationModal] = useState(false);
  const [showAddToInsertionOrderModal, setShowAddToInsertionOrderModal] = useState(false);
  const [disableDealJustification, setDisableDealJustification] = useState(false);
  const [currentProspect, setCurrentProspect] = useState({});
  const [deals, setDeals] = useState([]);
  const recordsRef = useRef([]);


  /* const prospectContainerRefresh = async (id, overwriteChannelNameAndURL) => {
    try {
      await axios.post('/api/portal/prospects/post-fetch-data-on-cpm-prospects', {
        prospectsIds: [id],
        overwriteChannelNameAndURL,
        identity: Math.random(),
      }).then((response) => {
        // console.log(response);
        if (response.data.success) {
          addNotice({
            message: `Successfully refreshed ${response.data.numberOfProspects} of ${response.data.numberOfProspects} items.`,
            type: 'success',
          });
        } else {
          addNotice({
            message: `Successfully refreshed ${response.data.successfulRefreshed} of ${response.data.numberOfProspects} items.`,
            type: 'error',
            duration: 10,
          });
        }
      });
      setVersion((new Date()).toString());
    } catch (e) {
      console.log(e);
    }
    return true;
  }; */

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }
    search.set('filters.approvalStatus', 'approved');
    search.set('filters.approvalsBrandStatus', 'approved');
    /* if (approvalStatusFilters && Object.keys(approvalStatusFilters).length > 0) {
      search.delete('filters.approvalStatus');
      Object.keys(approvalStatusFilters).forEach((key) => {
        if (!approvalStatusFilters[key]) return false;
        search.append('filters.approvalStatus', compareApprovalStatus[key]);
        return true;
      });
    }
    if (approvalsBrandStatusFilters && Object.keys(approvalsBrandStatusFilters).length > 0) {
      search.delete('filters.approvalsBrandStatus');
      Object.keys(approvalsBrandStatusFilters).forEach((key) => {
        if (!approvalsBrandStatusFilters[key]) return false;
        search.append('filters.approvalsBrandStatus', compareApprovalStatus[key]);
        return true;
      });
    } */
    if (dealAssignedFilters && Object.keys(dealAssignedFilters).length > 0) {
      search.delete('filters.dealAssigned');
      Object.keys(dealAssignedFilters).forEach((key) => {
        if (!dealAssignedFilters[key]) return false;
        search.append('filters.dealAssigned', key);
        return true;
      });
    }
    if (insertionOrderAssignedFilters && Object.keys(insertionOrderAssignedFilters).length > 0) {
      search.delete('filters.insertionOrderAssigned');
      Object.keys(insertionOrderAssignedFilters).forEach((key) => {
        if (!insertionOrderAssignedFilters[key]) return false;
        search.append('filters.insertionOrderAssigned', key);
        return true;
      });
    }
    search.delete('filters.cspOwners');
    if (cspOwnersFilter && Object.keys(cspOwnersFilter).length > 0) {
      Object.keys(cspOwnersFilter).forEach((key) => {
        if (!cspOwnersFilter[key]) return false;
        search.append('filters.cspOwners', key);
        return true;
      });
    }
    search.delete('filters.brands');
    if (brandsFilter && Object.keys(brandsFilter).length > 0) {
      Object.keys(brandsFilter).forEach((key) => {
        if (!brandsFilter[key]) return false;
        search.append('filters.brands', key);
        return true;
      });
    }
    search.delete('filters.dealStages');
    if (dealStagesFilter && Object.keys(dealStagesFilter).length > 0) {
      Object.keys(dealStagesFilter).forEach((key) => {
        if (!dealStagesFilter[key]) return false;
        search.append('filters.dealStages', key);
        return true;
      });
    }
    search.delete('filters.insertionOrders');
    if (insertionOrdersFilter && Object.keys(insertionOrdersFilter).length > 0) {
      Object.keys(insertionOrdersFilter).forEach((key) => {
        if (!insertionOrdersFilter[key]) return false;
        search.append('filters.insertionOrders', key);
        return true;
      });
    }

    search.delete('filters.createdAt.from');
    search.delete('filters.createdAt.to');
    search.delete('createdAtType');
    if (dateFrom && dateTo && dateAddedValue.value === 'Between') {
      search.append('filters.createdAt.from', dateFrom.toISOString());
      search.append('filters.createdAt.to', dateTo.toISOString());
    }
    if (singleDate && singleDate !== '') {
      if (dateAddedValue.value === '>' || dateAddedValue.value === '>=') {
        search.append('filters.createdAt.from', singleDate.toISOString());
      }
      if (dateAddedValue.value === '<' || dateAddedValue.value === '<=') {
        search.append('filters.createdAt.to', singleDate.toISOString());
      }
      if (dateAddedValue.value === '=') {
        search.append('filters.createdAt.from', singleDate.toISOString());
        const nextDay = new Date(singleDate);
        nextDay.setDate(singleDate.getDate() + 1);
        search.append('filters.createdAt.to', nextDay.toISOString());
      }
    }
    if (dateAddedValue.value && ((dateTo && dateFrom) || singleDate)) {
      setEnableDateAdded(true);
      search.append('createdAtType', dateAddedValue.value);
    }

    return search;
  };

  const firstUpdate = useRef(true);
  const setUrl = () => {
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
    }
    // search.set('filters.allRequiredDataIsSet', 'true');
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  useEffect(() => {
    setUrl();
  }, [
    searchValue,
    cspOwnersFilter,
    brandsFilter,
    dealStagesFilter,
    insertionOrdersFilter,
    // approvalStatusFilters,
    // approvalsBrandStatusFilters,
    dealAssignedFilters,
    insertionOrderAssignedFilters,
    enableDateAdded, singleDate, dateTo, dateFrom,
  ]);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.has('createdAtType') && search.get('createdAtType') !== dateAddedValue.value) {
      setUrl();
    }
  }, [dateAddedValue]);

  useEffect(() => {
    setCspOwnersList(getTagsInitial('cspOwners', false));
  }, [
    cspOwnersSuggestions,
  ]);

  useEffect(() => {
    setBrandsFilterList(getTagsInitial('brands', false));
  }, [
    brandsSuggestions,
  ]);

  useEffect(() => {
    setDealStagesFilterList(getTagsInitial('dealStages', false));
  }, [
    dealStagesSuggestions,
  ]);

  useEffect(() => {
    setInsertionOrdersFilterList(getTagsInitial('insertionOrders', false));
  }, [
    insertionOrdersSuggestions,
  ]);

  useEffect(() => {
    // detects if we came back from editing prospect page
    setVersion((new Date()).toString());
  }, [document.location]);

  if (!enableDateAdded && dateAddedValue.value && ((dateTo && dateFrom) || singleDate)) {
    setEnableDateAdded(true);
  }

  const handleSelectCheckbox = (selectedIds) => {
    setSelectedProspectIds(selectedIds);
  };

  const deleteCpmCreator = () => {
    setLoadingDeleteCpmCreator(true);
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-delete-cpm-prospect',
      data: { id: singleSelectedProspectId },
    }).then((res) => {
      setLoadingDeleteCpmCreator(false);
      if (res?.data?.success) {
        addNotice({
          message: 'CPM Creator was successfully deleted.',
          type: 'success',
        });
        setTimeout(() => setVersion((new Date()).toString()), 500);
      } else if (res?.data?.error) {
        addNotice({
          message: `CPM Creator was not deleted. Error: ${res.data.error}`,
          type: 'error',
        });
      }
      setTimeout(() => setShowDeleteProspectModal(false), 500);
    }).catch((error) => {
      console.error(error);
      addNotice({
        message: `CPM Creator was not deleted. Error: ${error.message}`,
        type: 'error',
      });
      setLoadingDeleteCpmCreator(false);
    });
  };

  const fetchCspOwnersSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospect-owners',
    }).then((response) => {
      if (response.data.success === true) {
        setCspOwnersSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchDealStages = () => {
    let options = [];
    const pipelines = getConstant('deal', 'pipelines');
    pipelines.forEach((record) => {
      if (record?.stages) {
        if (record.label === 'Main Pipeline') setDealStagesSuggestions(Object.keys(record.stages).map(key => ({ id: key, name: record.stages[key] })));
        options = options.concat(Object.keys(record.stages).map(key => ({ value: key, label: record.stages[key] })));
      }
    });
    setDealStagesOptions(options);
  };

  const fetchInsertionOrdersSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-cpm-insertion-orders-suggestions',
    }).then((response) => {
      if (response.data.success === true) {
        setInsertionOrdersSuggestions(response.data.suggestions);
        setNewInsertionOrdersSuggestions(response.data.suggestions.filter(suggestion => suggestion.status?.status === 'New'));
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const loadBrandsOptions = async () => {
    const res = [
      { value: null, label: '-- Not Assigned --' },
    ];
    const resTagsBrands = [];
    const response = await axios({
      method: 'get',
      url: '/api/portal/scorecard/media-content/get-brands-data?term=',
    });
    if (response.data.success) {
      response.data.brands.forEach((brandRecord) => {
        res.push({
          value: brandRecord._id,
          label: brandRecord.companyName,
          isDisabled: !brandRecord.displayInUi || (brandRecord.displayInUi && brandRecord.displayInUi === 'No'),
        });
        resTagsBrands.push({
          id: brandRecord._id,
          name: brandRecord.companyName,
          isDisabled: !brandRecord.displayInUi || (brandRecord.displayInUi && brandRecord.displayInUi === 'No'),
        });
      });
    }
    setBrands(res);
    setBrandsSuggestions(resTagsBrands);
  };

  useEffect(() => {
    fetchCspOwnersSuggestions();
    loadBrandsOptions();
    fetchDealStages();
    fetchInsertionOrdersSuggestions();
    dispatch(getAdminUsers(true));
  }, []);

  useEffect(() => {
    fetchCspOwnersSuggestions();
    fetchInsertionOrdersSuggestions();
  }, [version]);

  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'CPM Program', path: null },
          { title: 'CPM Deals', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>CPM Deals</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                onSearchChange={onSearchChange}
                getUrlSearch={getUrlSearch}
                searchValue={searchValue}
                cspOwnersSuggestions={cspOwnersSuggestions}
                setCspOwnersFilter={setCspOwnersFilter}
                setCspOwners={setCspOwnersList}
                cspOwners={cspOwnersList}
                brandsSuggestions={brandsSuggestions}
                setBrandsFilter={setBrandsFilter}
                setBrands={setBrandsFilterList}
                brands={brandsFilterList}
                setDealStagesFilter={setDealStagesFilter}
                setDealStages={setDealStagesFilterList}
                dealStages={dealStagesFilterList}
                dealStagesSuggestions={dealStagesSuggestions}
                setInsertionOrdersFilter={setInsertionOrdersFilter}
                setInsertionOrders={setInsertionOrdersFilterList}
                insertionOrders={insertionOrdersFilterList}
                insertionOrdersSuggestions={insertionOrdersSuggestions}
                dealAssignedFilters={dealAssignedFilters}
                setDealAssignedFilters={setDealAssignedFilters}
                insertionOrderAssignedFilters={insertionOrderAssignedFilters}
                setInsertionOrderAssignedFilters={setInsertionOrderAssignedFilters}
                enableDateAdded={enableDateAdded}
                setEnableDateAdded={setEnableDateAdded}
                dateAddedValue={dateAddedValue}
                setDateAddedValue={setDateAddedValue}
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
                singleDate={singleDate}
                setSingleDate={setSingleDate}
                selectedProspectIds={selectedProspectIds}
                setShowAddToInsertionOrderModal={setShowAddToInsertionOrderModal}
              />
              <Row style={{ marginTop: '-10px' }}>
                <Table
                  handleSelectCheckbox={handleSelectCheckbox}
                  action={action}
                  resource={resource}
                  dispatch={dispatch}
                  brands={brands}
                  addNotice={addNotice}
                  version={version}
                  setVersion={setVersion}
                  recordsRef={recordsRef}
                  setDisableDealJustification={setDisableDealJustification}
                  setDisableNote={setDisableNote}
                  setCurrentProspect={setCurrentProspect}
                  setSingleSelectedProspectId={setSingleSelectedProspectId}
                  setShowDealsListModal={setShowDealsListModal}
                  setShowDeleteProspectModal={setShowDeleteProspectModal}
                  setShowAddToInsertionOrderModal={setShowAddToInsertionOrderModal}
                  setDeals={setDeals}
                  dealStagesOptions={dealStagesOptions}
                />
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <RefreshStatisticsOptionsModal
        updateTable={() => setVersion((new Date()).toString())}
      />
      <NotesModal
        isOpen={showNotesModal}
        closeFunc={() => {
          setCurrentProspect(null);
          setShowNotesModal(false);
        }}
        setVersion={setVersion}
        prospect={currentProspect}
        disabled={disableNote}
      />
      <DealJustificationModal
        isOpen={showDealJustificationModal}
        closeFunc={() => {
          setCurrentProspect(null);
          setShowDealJustificationModal(false);
        }}
        setVersion={setVersion}
        prospect={currentProspect}
        disabled={disableDealJustification}
      />
      <DealsListModal
        isOpen={showDealsListModal}
        closeFunc={() => {
          setShowDealsListModal(false);
          dispatch(changeCurrentCpmProspectId(''));
          dispatch(changeCurrentCpmProspectName(''));
          dispatch(changeCurrentCpmProspectHsDealId(''));
          setVersion((new Date()).toString());
          setDeals([]);
        }}
        deals={deals}
      />
      <DeleteCpmCreatorFromOrder
        updateTable={() => setVersion((new Date()).toString())}
      />
      <DeleteCpmCreatorModal
        isOpen={showDeleteProspectModal}
        closeFunc={() => {
          setVersion((new Date()).toString());
          setShowDeleteProspectModal(false);
          setSingleSelectedProspectId('');
        }}
        loading={loadingDeleteCpmCreator}
        deleteCreator={deleteCpmCreator}
      />
      <AddToInsertionOrderModal
        isOpen={showAddToInsertionOrderModal}
        closeFunc={() => {
          setVersion((new Date()).toString());
          setShowAddToInsertionOrderModal(false);
          setSingleSelectedProspectId('');
          setSelectedProspectIds([]);
        }}
        insertionOrders={newInsertionOrdersSuggestions}
        prospectIds={singleSelectedProspectId ? [singleSelectedProspectId] : selectedProspectIds}
      />
      <PublishLookerProspect />
    </Container>
  );
};

CPMProspectsListContainer.propTypes = {
  addNotice: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withNotice(connect()(withRouter(CPMProspectsListContainer)));
