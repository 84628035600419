import React from 'react';
import { Modal, Spinner, ButtonToolbar, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from 'mdi-react/DownloadIcon';

const ReportModal = ({
  modal,
  loading,
  closeDialog,
  log,
  downloadCSV,
  title,
  type,
  typeTitle,
  preComponent,
}) => (
  <Modal
    isOpen={modal}
    toggle={closeDialog}
  >
    <div className="modal__header">
      <h4 className="text-modal  modal__title">{title}</h4>
    </div>
    <div className="modal__body finance-form">
      {preComponent}
      {!loading ?
        (
          <ul className="text-left">
            <li>Retrieved {log.request.length} {typeTitle}
              {log.request.length > 0 ? (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV(type, 'request')}><DownloadIcon size="20" /></IconButton>) : ''}
            </li>
            <li>Added/Updated {log.added.length} {typeTitle}
              {log.added.length > 0 ? (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV(type, 'added')}><DownloadIcon size="20" /></IconButton>) : ''}
            </li>
            <li>Removed {log.removed.length} {typeTitle}
              {log.removed.length > 0 ? (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV(type, 'removed')}><DownloadIcon size="20" /></IconButton>) : ''}
            </li>
            {log.errors && log.errors.length > 0 &&
              (
                <li className="text-danger">
                  Please check Errors
                  {!loading && log.added.length > 0 && (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV(type, 'errors')}><DownloadIcon size="20" /></IconButton>)}
                </li>
              )
            }
          </ul>
        )
      : (<div><Spinner color="primary" /></div>)
      }
    </div>
    {!loading && (
    <ButtonToolbar className="modal__footer">
      <Button className="modal_cancel btn-sm" color="danger" onClick={closeDialog}>Close</Button>
    </ButtonToolbar>
      )
    }
  </Modal>
);
ReportModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  typeTitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  log: PropTypes.shape({
    request: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    added: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    removed: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    errors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  downloadCSV: PropTypes.func.isRequired,
  preComponent: PropTypes.objectOf(PropTypes.any),
};
ReportModal.defaultProps = {
  preComponent: (<div />),
};
export default ReportModal;
