/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import ApiClient from 'ApiClient';

export const taxFormRoleCheckSlice = createSlice({
  name: 'taxEditRoleCheck',
  initialState: {
    isAllowedToEdit: false,
  },
  reducers: {
    setIsAllowedToEditTaxForm: (state, action) => {
      const { isAllowedToEdit } = action.payload;
      state.isAllowedToEdit = isAllowedToEdit;
    },
  },
});

export const {
  setIsAllowedToEditTaxForm,
} = taxFormRoleCheckSlice.actions;

export const getIfAdminIsAllowedToEditTaxForm = () => async (dispatch) => {
  const api = new ApiClient();
  const response = await api.client.get('/api/portal/get-tax-form-edit-role');
  dispatch(setIsAllowedToEditTaxForm({
    isAllowedToEdit: response.data?.isAllowedToEdit || false
  }));
};

export default taxFormRoleCheckSlice.reducer;
