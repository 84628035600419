import React, { useMemo, useState } from 'react';
import { Col, Row, Card, CardBody, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import useConversions from './useConversions';
import TopButtonsPeriod from '../../../../Reports/TimePeriodFilterExtended';
import Item from '../../../../Reports/StatItem';
import ReactTableBase from '../../../../../../shared/tables/table/ReactTableBase';
import formater from '../../../../../../shared/helpers/WVFormatter';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  useFlex: true,
  withPagination: true,
  manualPageSize: [5, 10, 20, 30, 100],
  withSearchEngine: true,
  sortBy: 'datetime',
  direction: 'desc',
  placeholder: 'Search Offer, Deal, or Creator Name',
  searchFieldMinWidth: '50%',
};

const AgentConversons = ({ influencers, deals }) => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').unix());
  const [endDate, setEndDate] = useState(moment().endOf('day').unix());
  const { conversions, clicks } = useConversions(
    influencers.map(i => i._id),
    setLoading,
    moment.unix(startDate).format('YYYY-MM-DD'),
    moment.unix(endDate).format('YYYY-MM-DD'),
  );

  const findRightDeal = ({
    dealsAll,
    offerId,
  }) => {
    const rightdeal = dealsAll.filter(d => d.dealTerms.offer === offerId);
    return rightdeal[0] || {};
  };

  const columnTransform = useMemo(() => [
    {
      id: 'datetime',
      Header: 'Date',
      Cell: value => (value.row.original.Stat?.datetime ? moment.utc(value.row.original.Stat?.datetime).local().format('MM/DD/YY HH:mm:ss') : ''),
      accessor: record => record.Stat?.datetime,
      width: 150,
    },
    {
      id: 'creator',
      Header: 'Creator Name',
      Cell: value => `${value.row.original.Influencer?.first_name || ''} ${value.row.original.Influencer?.last_name || ''}`,
      accessor: record => `${record.Influencer?.first_name || ''} ${record.Influencer?.last_name || ''}`,
      width: 150,
    },
    {
      id: 'offerName',
      Header: 'Offer Name',
      Cell: value => value.row.original.Offer?.name,
      accessor: record => record.Offer?.name?.toLowerCase(),
      width: 200,
    },
    {
      id: 'goalName',
      Header: 'Goal Name',
      Cell: value => value.row.original.Goal?.name,
      accessor: record => record.Goal?.name?.toLowerCase(),
      width: 150,
    },
    {
      id: 'revenue',
      Header: 'Revenue',
      Cell: value => formater.formatCurrency(value.row.original.Stat?.revenue),
      accessor: record => record.Stat?.revenue,
      width: 100,
    },
    {
      id: 'payout',
      Header: 'Payout',
      Cell: value => formater.formatCurrency(value.row.original.Stat?.payout),
      accessor: record => record.Stat?.payout,
      width: 100,
    },
    {
      id: 'dealName',
      Header: 'Deal Name',
      Cell: value => value.value,
      accessor: (record) => {
        const deal = findRightDeal({ dealsAll: deals, offerId: record.Stat?.offer_id, influencerId: record._id });
        return deal.dealname || '';
      },
      width: 150,
    },
  ], [conversions]);

  let payout = 0;
  let revenue = 0;
  conversions?.forEach((conversion) => {
    if (conversion && conversion.Stat) {
      payout += parseFloat(conversion.Stat.payout);
      revenue += parseFloat(conversion.Stat.revenue);
    }
  });

  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Conversions</h2>
      <Card id="conversionsDatesBlock">
        <CardBody>
          <TopButtonsPeriod
            dateFrom={startDate}
            dateTo={endDate}
            isLoading={loading && !conversions}
            setValue={(param, value) => {
              if (param === 'startDate') {
                setStartDate(moment(value).startOf('day').unix());
              }
              if (param === 'endDate') {
                setEndDate(moment(value).endOf('day').unix());
              }
              setLoading(true);
            }}
            usedForConversions
          />
          <Row className="mt-3">
            <Col md="3">
              <Item
                items={[{
                  label: 'Revenue',
                  value: (!loading && conversions) ? formater.formatCurrency((revenue)) : (<div className="text-center"><Spinner color="primary" size="lg" /></div>),
                }]}
              />
            </Col>
            <Col md="3">
              <Item
                items={[{
                  label: 'Payout',
                  value: (!loading && conversions) ? formater.formatCurrency((payout)) : (<div className="text-center"><Spinner color="primary" size="lg" /></div>),
                }]}
              />
            </Col>
            <Col md="3">
              <Item
                items={[{
                  label: 'Clicks',
                  value: (!loading && conversions) ? clicks : (<div className="text-center"><Spinner color="primary" size="lg" /></div>),
                }]}
              />
            </Col>
            <Col md="3">
              <Item
                items={[{
                  label: 'Revenue Conversions',
                  value: (!loading && conversions) ? conversions.length : (<div className="text-center"><Spinner color="primary" size="lg" /></div>),
                }]}
              />
            </Col>
          </Row>
          {(!loading && conversions) ? (
            <Row className="mt-3">
              <Col>
                {conversions?.length > 0 ?
                  <ReactTableBase
                    columns={columnTransform}
                    data={conversions}
                    tableConfig={tableConfig}
                  />
                :
                  <Alert color="warning">
                    <p className="py-2">
                      The Creators currently have no Conversions.
                    </p>
                  </Alert>
                }
              </Col>
            </Row>
            ) : (<div className="text-center" style={{ minHeight: '200px' }} />)
          }

        </CardBody>
      </Card>
    </div>
  );
};

AgentConversons.propTypes = {
  influencers: PropTypes.arrayOf(PropTypes.any),
  deals: PropTypes.arrayOf(PropTypes.any),
};
AgentConversons.defaultProps = {
  influencers: [],
  deals: [],
};

export default AgentConversons;
