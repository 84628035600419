import React, { useState } from 'react';
import { Col, FormGroup, Input, Modal, ModalBody, ModalHeader, Row, Label, Button } from 'reactstrap';
import { axios } from 'ApiClient';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '../../../../../../../../shared/helpers/ProgressBar';
import CheckBoxField from '../../../../../../../../shared/components/CheckBox';
import Hook from '../../../../../../../../shared/hooks';

export const PublishInsertionOrderChangeLogHistoryModalId = 'PublishInsertionOrderChangeLogHistory';

const defaultIncludeChangesTo = ['date', 'amount', 'status'];
const defaultOptionalColumns = ['hsContactUrl', 'hsDealUrl', 'dateChanged', 'changedBy'];

const useStyles = makeStyles(() => ({
  formLabel: {
    marginLeft: '20px',
  },
  progressBar: {
    paddingTop: '20px',
  },
}));

const PublishInsertionOrderChangeLogHistoryModal = () => {
  const classes = useStyles();
  const [title, setTitle] = useState('Publish Change Log');
  const [condensed, setCondensed] = useState(true);
  const [includeChangesTo, setIncludeChangesTo] = useState(defaultIncludeChangesTo);
  const [optionalColumns, setOptionalColumns] = useState(defaultOptionalColumns);
  const [fileOutput, setFileOutput] = useState('googleSheet');
  const [loading, setLoading] = useState(false);
  const [endUpload, setEndUpload] = useState(false);
  const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');
  const [resultMessage, setResultMessage] = useState([]);


  const { data: dataModal, showModal: showUploadModal, close: closeModal } = Hook.useModalHook(PublishInsertionOrderChangeLogHistoryModalId);
  const { ids } = dataModal;
  if (!ids) return false;

  const close = () => {
    setLoading(false);
    setEndUpload(false);
    setFilePath('');
    setFileName('');
    setTitle('Publish Change Log');
    setCondensed(true);
    setIncludeChangesTo(defaultIncludeChangesTo);
    setOptionalColumns(defaultOptionalColumns);
    setFileOutput('googleSheet');
    closeModal();
  };

  const handleChangeCondensed = e => setCondensed(e?.target?.value === '1');

  const handleChangeFileOutput = e => setFileOutput(e?.target?.value || 'googleSheet');

  const handleChangeIncludeChangesTo = (event, changeValue) => {
    if (event && event.target) {
      const { checked } = event.target;
      let array = Array.isArray(includeChangesTo) ? [...includeChangesTo] : [];
      if (checked) {
        array.push(changeValue);
        setIncludeChangesTo(array);
      } else {
        array = array.filter(value => value !== changeValue);
        setIncludeChangesTo(Array.isArray(array) ? array : []);
      }
    }
  };

  const handleChangeOptionalColumns = (event, changeValue) => {
    if (event && event.target) {
      const { checked } = event.target;
      let array = Array.isArray(optionalColumns) ? [...optionalColumns] : [];
      if (checked) {
        array.push(changeValue);
        setOptionalColumns(array);
      } else {
        array = array.filter(value => value !== changeValue);
        setOptionalColumns(Array.isArray(array) ? array : []);
      }
    }
  };

  const onSave = () => {
    setLoading(true);
    if (fileOutput === 'csv') {
      const search = new URLSearchParams();
      ids.forEach(id => search.append('insertionOrderIds', id));
      includeChangesTo.forEach(change => search.append('includeChangesTo', change));
      optionalColumns.forEach(column => search.append('optionalColumns', column));
      search.set('condensed', condensed);
      console.log('search: ', search.toString());
      const win = window.open(`/resources/insertionorders/download-change-log-csv?${search.toString()}`, '_blank');
      win.focus();
      setLoading(false);
      close();
    } else {
      axios({
        method: 'post',
        url: '/api/portal/prospects/post-publish-insertion-order-change-log',
        data: {
          insertionOrderIds: ids,
          includeChangesTo,
          optionalColumns,
          fileOutput,
          condensed,
        },
      }).then((response) => {
        if (response?.data) {
          if (response.data?.success) {
            setTitle('Change Log Published successfully');
            setResultMessage(['Change Log ', ' was successfully published.']);
            setFilePath(`https://docs.google.com/spreadsheets/d/${response.data.file.id}`);
            setFileName(response.data.file.name);
          }
          if (response.data?.error) {
            setResultMessage([`File was not created. Error: ${response.data.error}`]);
          }
        }
        setLoading(false);
        setEndUpload(true);
      });
    }
  };

  return (
    <Modal isOpen={showUploadModal} toggle={close} style={{ minWidth: '550px', maxWidth: '550px' }} >
      <ModalHeader toggle={close} tag="div">
        <h2 className="m-0" >{title}</h2>
      </ModalHeader>
      <ModalBody className="theme-light" >
        {!loading && !endUpload && (
          <>
            <Label className="bold-text">Change Log Contents</Label>
            <FormGroup>
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Input
                    disabled={loading}
                    type="radio"
                    name="handleChangeLogContents"
                    value="1"
                    className="mt-0"
                    checked={condensed === true}
                    onChange={handleChangeCondensed}
                  />
                }
                label="Condensed Change History (Original & new value only)"
              />
              <br />
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Input
                    disabled={loading}
                    type="radio"
                    name="handleChangeCondensed"
                    value="0"
                    className="mt-0"
                    checked={condensed === false}
                    onChange={handleChangeCondensed}
                  />
                }
                label="Full Change History (All value changes)"
              />
            </FormGroup>
            <Label className="bold-text mt-3">Types of Changes to Include?</Label>
            <FormGroup
              style={{ marginBottom: '10px' }}
            >
              <CheckBoxField
                name="date"
                label="Publish Date (Content Release Date)"
                value={includeChangesTo.includes('date')}
                onChange={event => handleChangeIncludeChangesTo(event, 'date')}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '10px' }}>
              <CheckBoxField
                name="amount"
                label="Deal Amount"
                value={includeChangesTo.includes('amount')}
                onChange={event => handleChangeIncludeChangesTo(event, 'amount')}
              />
            </FormGroup>
            <FormGroup className="mb-0">
              <CheckBoxField
                name="status"
                label="Approval Status"
                value={includeChangesTo.includes('status')}
                onChange={event => handleChangeIncludeChangesTo(event, 'status')}
              />
            </FormGroup>
            <Label className="bold-text mt-3">Change Log File Output</Label>
            <FormGroup>
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Input
                    disabled={loading}
                    type="radio"
                    name="handleChangeFileOutput"
                    value="googleSheet"
                    className="mt-0"
                    checked={fileOutput === 'googleSheet'}
                    onChange={handleChangeFileOutput}
                  />
                }
                label="Google Sheet"
              />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Input
                    disabled={loading}
                    type="radio"
                    name="handleChangeFileOutput"
                    value="csv"
                    className="mt-0"
                    checked={fileOutput === 'csv'}
                    onChange={handleChangeFileOutput}
                  />
                }
                label="CSV"
              />
            </FormGroup>
            <Row className="mt-3">
              <Col md={6}>
                <Label className="bold-text">Required Columns</Label>
                <div>Insertion Order No.</div>
                <div>Insertion Order ID</div>
                <div>Creator Name</div>
                <div>Creator Code</div>
                <div>Change Type</div>
                <div>Original Value</div>
                <div>New Value</div>
              </Col>
              <Col md={6}>
                <Label className="bold-text">Optional Columns</Label>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="hsContactUrl"
                    label="HubSpot Contact URL"
                    value={optionalColumns.includes('hsContactUrl')}
                    onChange={event => handleChangeOptionalColumns(event, 'hsContactUrl')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="hsDealUrl"
                    label="HubSpot Deal URL"
                    value={optionalColumns.includes('hsDealUrl')}
                    onChange={event => handleChangeOptionalColumns(event, 'hsDealUrl')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="dateChanged"
                    label="Date Changed"
                    value={optionalColumns.includes('dateChanged')}
                    onChange={event => handleChangeOptionalColumns(event, 'dateChanged')}
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <CheckBoxField
                    name="changedBy"
                    label="Changed By"
                    value={optionalColumns.includes('changedBy')}
                    onChange={event => handleChangeOptionalColumns(event, 'changedBy')}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        {loading && (
          <div className={classes.progressBar}>
            <ProgressBar
              topic="publishInsertionOrderChangeLogHistory"
            />
          </div>
        )}
        {endUpload && (
          <Row>
            <Col md={1} >
              <FontAwesomeIcon icon={['fa', 'info-circle']} style={{ height: '32px', width: '32px' }} />
            </Col>
            <Col md={11} >
              <h4>
                {resultMessage[0] || ''}
                {fileName && (<b>{fileName}</b>)}
                {resultMessage[1] || ''}
              </h4>
              {filePath && (
                <a href={filePath} target="_blank" rel="noreferrer">
                  View Change Log
                </a>
              )}
            </Col>
          </Row>
        )}
        <Row className="modal__footer" style={{ marginTop: '30px' }}>
          {!endUpload && (
            <Button
              className="btn btn-primary btn-sm"
              onClick={onSave}
              color="primary"
              style={{ margin: '0 10px 0 10px' }}
              disabled={loading || includeChangesTo.length === 0}
            >
              <i className="icomoon-save" />
              <span className="btn-text">Save</span>
            </Button>
          )}
          <Button
            className="modal_cancel btn-sm"
            color="secondary"
            onClick={close}
            style={{ margin: '0 10px 0 10px' }}
          >
            {endUpload ? 'Close' : 'Cancel'}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default PublishInsertionOrderChangeLogHistoryModal;
