import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import _ from 'lodash';

const UrlStatusInList = React.memo(({ record, setVersion, addNotice }) => {
  const rowData = record?.params?.urlStatus;
  const val = (rowData && typeof rowData !== 'undefined') ? { value: rowData, label: rowData } : { value: null, label: '-- Not Assigned --' };
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    setValue(val);
    setDisabled(false);
  }, [record.id, rowData]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    const prevValue = value;
    setValue(option);
    try {
      const data = await axios.post('/api/portal/scorecard/media-content/update-url-status', {
        ids: [record.id],
        urlStatus: option.value,
      });
      if (!data.data.success) {
        setValue(prevValue);
      } else {
        setVersion((new Date()).toString());
        setValue(option);
        addNotice({
          message: 'Content URL Status was updated successfully',
          type: 'success',
        });
      }
    } catch (e) {
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };

  return (
    <div style={{ minWidth: '170px', maxWidth: '170px' }} >
      <Select
        value={value}
        className="urlStatusSelectBox"
        options={[
          { value: 'Needs Review', label: 'Needs Review' },
          { value: 'Pending Publication', label: 'Pending Publication' },
          { value: 'Needs Content URL', label: 'Needs Content URL' },
          { value: 'Approved', label: 'Approved' },
          { value: 'Inactive', label: 'Inactive' },
        ]}
        onChange={handleChange}
        placeholder=""
        isDisabled={disabled}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
      {error ? (<span className="text-danger">{error}</span>) : null}
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

UrlStatusInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};
export default UrlStatusInList;
