import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Modal, ButtonToolbar, Button } from 'reactstrap';
import { uid } from 'react-uid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { formatCurrency, formatIntNumber } from '../../../../shared/helpers/WVFormatter';
import { getConstant } from '../../../../shared/helpers/WVConstants';


const CreatorModal = ({
  show, setShow, selectedDeals, calculateRevenueForDeal, calculateForDeal,
}) => {
  const dealPipelines = getConstant('deal', 'pipelines', []);
  const dealStages = [];
  dealPipelines.forEach((pipeline) => {
    Object.keys(pipeline.stages).forEach((stageKey) => {
      dealStages.push({ id: stageKey, label: pipeline.stages[stageKey] });
    });
  });
  console.log(selectedDeals);
  return (
    <Modal
      isOpen={!!show}
      toggle={() => setShow(null)}
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">Calculation Breakdown</h4>
      </div>
      {/* eslint-disable-next-line react/no-danger */}
      <div className="modal__body ltr-support">
        {selectedDeals.map((record) => {
          const {
            deal: selectedDeal, report, conversionsArray, invoicePayout, payablePayoutAmount, remainingRevenueConversionsAmount,
            payableRevenueAmount, remainingPayoutConversionsAmount, cpmConversionsCount,
          } = record;
          if (!selectedDeal && cpmConversionsCount) return (<div><h4><strong>CPM Conversions Count</strong>: {cpmConversionsCount || 0}</h4></div>);
          const stage = dealStages.find(item => item.id === selectedDeal.dealstage);
          const { brandApprovedDate, dealTerms } = selectedDeal;
          const totalRevenue = calculateRevenueForDeal(record);
          const totalProfit = calculateForDeal(record);
          return (
            <div key={uid(selectedDeal.hs_object_id)}>
              <h4>
                <strong>Deal Name</strong>: {selectedDeal.dealname}{' '}
                <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${selectedDeal.hs_object_id}/`} target="_blank" rel="noreferrer">
                  <Tooltip title="Hubspot Deal">
                    <IconButton size="small">
                      <i className="fab fa-hubspot" style={{ fontSize: '0.8em' }} />
                    </IconButton>
                  </Tooltip>
                </a>
              </h4>
              <h4><strong>Deal Type</strong>: {selectedDeal.dealTerms.type_of_deal}</h4>
              <h4><strong>Brand Approved Date</strong>: {brandApprovedDate ? moment(brandApprovedDate.split('T')[0]).format('MM/DD/YY') : '-'}</h4>
              <h4><strong>Content Release Date</strong>: {dealTerms.content_release_date ? moment(dealTerms.content_release_date.split('T')[0]).format('MM/DD/YY') : '-'}</h4>

              <h4><strong>Deal Stage</strong>: {stage?.label}</h4>
              <h4><strong>Deal Status</strong>: {selectedDeal.dealTerms.cpa_status}</h4>
              <h4><strong>Deal Recoverable</strong>: {selectedDeal.dealTerms.guaranteed_payment_recoverable}</h4>
              <br />
              <strong>Revenue Breakdown</strong>
              <hr className="mt-0" />
              <h4><strong>Payable Revenue Amount</strong>: {formatCurrency(payableRevenueAmount || 0)}</h4>
              <h4><strong>Remaining Revenue Conversion Amount</strong>: {formatCurrency(remainingRevenueConversionsAmount || 0)}</h4>
              <h4><strong>HasOffers Revenue Amount</strong>: {formatCurrency(report?.revenue || 0)}</h4>
              <h4><strong>BH CPM Total Costs</strong>: {formatCurrency(Number(selectedDeal.dealTerms.bh_cpm_total_cost || 0))}</h4>
              <h4><strong>Recoverable Guaranteed Amount</strong>: {selectedDeal.invoice ? formatCurrency(Number(selectedDeal.dealTerms.guaranteed_payment_contract_amount || 0)) : 0}</h4>
              <h4><strong>Recoverable Guaranteed Amount paid Selected Period</strong>: {selectedDeal.invoice ? formatCurrency((totalRevenue - totalProfit) - Number((invoicePayout) || 0)) : 0}</h4>

              <br />
              <strong>Costs Breakdown</strong>
              <hr className="mt-0" />
              <h4><strong>Payable Payout Amount</strong>: {formatCurrency(payablePayoutAmount || 0)}</h4>
              <h4><strong>Remaining Payout Conversion Amount</strong>: {formatCurrency(remainingPayoutConversionsAmount || 0)}</h4>
              <h4><strong>Guaranteed Contract Amount</strong>: {formatCurrency(selectedDeal.dealTerms.guaranteed_payment_contract_amount || 0)}</h4>
              <h4><strong>HasOffers Payout Amount</strong>: {formatCurrency(report.payout)}</h4>
              <br />
              <h4><strong>CPA Percentage</strong>: {selectedDeal.dealTerms.cpa_percentage || selectedDeal.dealTerms.cpa_percentage === 0 ? `${formatIntNumber(selectedDeal.dealTerms.cpa_percentage)}%` : '-'}</h4>
              <h4><strong>HasOffers Payout Amount * CPA Percentage</strong>: {formatCurrency((Number(selectedDeal.dealTerms.cpa_percentage || 0) * Number(report.payout || 0)) / 100)}</h4>
              <br />
              <h4>
                <strong>CPA Rate Per Conversion Amount</strong>: {selectedDeal.dealTerms.per_conversion_amount || selectedDeal.dealTerms.per_conversion_amount === 0 ? formatCurrency(selectedDeal.dealTerms.per_conversion_amount || 0) : '-'}
              </h4>
              <h4><strong>No.conversions * CPA Rate Per Conversion Amount</strong>:{' '}
                {conversionsArray.length} * {formatCurrency(Number(selectedDeal.dealTerms.per_conversion_amount || 0))} = {formatCurrency(Number(conversionsArray.length * Number(selectedDeal.dealTerms.per_conversion_amount || 0)))}
              </h4>
              <br />
              <strong>Gross Profit</strong>
              <hr className="mt-0" />
              <h4><strong>Revenue</strong>: {formatCurrency(totalRevenue)}</h4>
              <h4><strong>Costs</strong>: {formatCurrency(totalRevenue - totalProfit)}</h4>
              <h4><strong>Profit</strong>: {formatCurrency(totalProfit)}</h4>
              <hr />
            </div>
          );
        })}
      </div>
      <ButtonToolbar className="modal__footer">
        <Button color="danger" onClick={() => setShow(null)}>Close</Button>
      </ButtonToolbar>
    </Modal>);
};
CreatorModal.propTypes = {
  selectedDeals: PropTypes.arrayOf(PropTypes.any).isRequired,
  show: PropTypes.bool,
  setShow: PropTypes.func.isRequired,
  calculateForDeal: PropTypes.func.isRequired,
  calculateRevenueForDeal: PropTypes.func.isRequired,
};
CreatorModal.defaultProps = {
  show: false,
};
export default CreatorModal;
