import React, { useState, useEffect, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { Col, Container, Row, Card, CardBody, Spinner, Button } from 'reactstrap';
import withNotice from '../../App/store/with-notice';
import ReactTableBase from '../../../shared/tables/table/ReactTableBase';
import ProgressBar from '@/shared/helpers/ProgressBarWithObject';


export default withNotice(({ addNotice }) => {
  console.log();
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [execute, setExecute] = useState(false);
  const loadMigrations = async () => {
    setLoading(true);
    try {
      const data = await axios({
        method: 'get',
        url: '/api/portal/global/get-migrations',
      });
      console.log(data.data);
      if (data.data.success === true) {
        setStats(data.data.migrations);
      } else {
        setStats([]);
      }
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to get migrations',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  const runMigration = async (migrationName) => {
    setExecute(migrationName);
    try {
      const data = await axios({
        method: 'get',
        url: `/api/portal/global/run-migration?migrationFile=${migrationName}`,
      });
      console.log(data.data);
      if (data.data.success === true) {
        loadMigrations()
      }
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to run migration',
        type: 'Error',
      });
    }
    setExecute(false);
  };

  useEffect(async () => {
    await loadMigrations();
  }, []);
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    useFlex: true,
    withPagination: true,
    manualPageSize: [20, 40],
    withSearchEngine: true,
    sortBy: 'name',
    direction: 'desc',
  };

  const columnTransform = useMemo(() => {
    console.log('match');
    return [{
      Header: () => ('Migration Name'),
      accessor: d => d.name,
      id: 'Name',
      width: 400,

    }, {
      disableSortBy: true,
      disableFilters: true,
      Header: 'Status',
      id: 'value',
      accessor: r => (r.status || ''),
      Cell: ({ value, row }) => {
        if(value === 'success') return 'Success';

        return (<>
          <Button onClick={() => runMigration(row.original.name)} disabled={!!execute} color="primary">Run Migration</Button>
        </>)
      },

      width: 300,
    }];
  }, []);
  return (
    <Container>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3 className="il-color-deep-blue font-weight-bold">Migrations</h3>
                </Col>
              </Row>
              <hr className="mt-3 mb-4" />
              <h4 className=" font-weight-bold">MigrationsList</h4>
              <hr className="mt-3 mb-4" />
              <ProgressBar
                topic={'migration'}
              />
              {loading ? (<div className="text-center"><Spinner /></div>) : null}
              {!loading ? (
                <ReactTableBase
                  key="filesIO"
                  columns={columnTransform}
                  data={stats}
                  tableConfig={tableConfig}
                />) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});


// export default withNotice(connect(mapStateToProps)(PushNotifications));
