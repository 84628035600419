import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import {
  form,
  resources,
  branding,
  paths,
  session,
  dashboard,
  notices,
  pages,
  versions,
  theme,
  rtl,
  sidebar,
  customizer,
  locale,
  assets,
  table,
  tableRedux,
  modals,
} from './store';


import accountPayable from '../../../redux/reducers/admin/AccountsPayableReducer';
import accountsReceivableReducer from '../../../redux/reducers/admin/AccountsReceivableReducer';
import advertiserReducer from '../../../redux/reducers/admin/AdvertiserReducer';
import employeeReducer from '../../../redux/reducers/admin/EmployeeReducer';
import employeeCommissionsReducer from '../../../redux/reducers/admin/EmployeeCommissionsReducer';
import adminDashboardReducer from '../../../redux/reducers/admin/DashboardReducer';
import hubilReducer from '../../../redux/reducers/admin/HubilReducer';
import AgentReducer from '../../../redux/reducers/admin/AgentReducer';
import ResearchReducer from '../../../redux/reducers/admin/ResearchReducer';
// import creatorPayoutReducer from '../../../redux/reducers/creator/Payout';
// import creatorCreatorReducer from '../../../redux/reducers/creator/Creator';
import scoringReducer from '../../../redux/reducers/admin/ScoringReducer';
import creatorsReducer from '../../../redux/reducers/brand/CreatorsReducer';
import notificationReducer from '../../../redux/reducers/admin/NotificationsReducer';
import influencerProfileReducer from '../../../redux/reducers/admin/InfluencerProfileReducer';
import globalSelected from '../../../redux/reducers/admin/GlobalSelectedReducer';
import AdminUserNameReducer from '../../../redux/reducers/admin/AdminUserNameReducer';
import FinanceAdminUserRolecheckReducer from '../../../redux/reducers/admin/FinanceAdminUserRolecheckReducer';

const reducer = combineReducers({
  form,
  resources,
  branding,
  paths,
  session,
  dashboard,
  notices,
  pages,
  versions,
  theme,
  rtl,
  sidebar,
  customizer,
  locale,
  assets,
  table,
  tableRedux,
  modals,
  accountPayable,
  advertiserReducer,
  employeeReducer,
  employeeCommission: employeeCommissionsReducer,
  accountReceivable: accountsReceivableReducer,
  adminDashboard: adminDashboardReducer,
  hubilReducer,
  score: scoringReducer,
  creators: creatorsReducer,
  notifications: notificationReducer,
  influencer: influencerProfileReducer,
  globalSelected,
  AgentReducer,
  ResearchReducer,
  AdminUserNameReducer,
  taxFormEditRoleCheck: FinanceAdminUserRolecheckReducer,
});

// const middleware = [thunk];
const appStore = (initialState = {}) => configureStore({
  reducer,
  middleware: () => [thunk],
  preloadedState: initialState,
  // Enables redux devtools. Allows to time-travel redux store.
  devTools: ['development', 'dev', 'local'].includes(window.process.env.NODE_ENV),
});

export default appStore;
