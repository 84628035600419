/* eslint react/prop-types: 0 */
import React, { useState } from 'react';

import { withRouter } from 'react-router-dom';
import ApiClient from 'ApiClient';
import { Card, CardBody } from 'reactstrap';
// import { PropertyPlace } from 'admin-bro/lib/backend/decorators/property-json.interface';
import PropertyType from '../property-type';
// import WrapperBox from '../ui/wrapper-box';
import Table from '../ui/table';
import { ErrorMessageBox, StyledButton } from '../ui';
import withNotice from '../../../../containers/App/store/with-notice';

/**
 * @name ShowAction
 * @category Actions
 * @description Shows a given record.
 * @component
 * @private
 */
const BulkDelete = (props) => {
  const {
    resource, records, action, addNotice, history,
  } = props;

  const [loading, setLoading] = useState(false);

  if (!records) {
    return (
      <ErrorMessageBox title="No records selected">
        In order to remove records, you have to pick them first.
      </ErrorMessageBox>
    );
  }

  const handleClick = () => {
    const api = new ApiClient();
    setLoading(true);
    const recordIds = records.map(r => r.id);
    api.bulkAction({
      resourceId: resource.id,
      actionName: action.name,
      recordIds,
      method: 'post',
    }).then(((response) => {
      setLoading(false);
      if (response.data.notice) {
        addNotice(response.data.notice);
      }
      if (response.data.redirectUrl) {
        if (response.data.redirectUrl.indexOf('records') >= 0) {
          history.push(response.data.redirectUrl);
        } else {
          history.push(`${response.data.redirectUrl}/actions/list`);
        }
      }
    })).catch((error) => {
      setLoading(false);
      addNotice({
        message: 'There was an error deleting records, Check out console to see more information.',
        type: 'error',
      });
      throw error;
    });
  };

  return (
    <Card className="mt-3">
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Following records will be removed:</h5>
        </div>
        <Table>
          {records.map(record => (
            <tr>
              {resource.listProperties.map(property => (
                <td key={property.name} className={resource.titleProperty.name === property.name ? 'main' : undefined}>
                  <PropertyType
                    where="list"
                    property={property}
                    resource={resource}
                    record={record}
                  />
                </td>
              ))}
            </tr>
          ))}
        </Table>
        <p>
          <StyledButton
            onClick={handleClick}
            className={`is-primary${loading ? ' is-loading' : ''}`}
          >
            {`Confirm the removal of ${records.length} records`}
          </StyledButton>
        </p>
      </CardBody>
    </Card>
  );
};

export default withNotice(withRouter(BulkDelete));
