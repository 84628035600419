/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Bar,
  BarChart,
  Tooltip as TooltipOnLine,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { formatIntNumber, formatCurrency } from '../../../../shared/helpers/WVFormatter';

const chartMargins = {
  top: 10,
  right: 0,
  left: 45,
  bottom: 20,
};

const defaultColors = {
  New: '#2B78E4',
  Existing: '#009E0F',
};

const BarChartComponent = ({
  chartData,
  chartType,
  currency,
}) => {
  const [barColors, setBarColors] = useState(defaultColors);
  const showLabels = false;

  const handleLegendItemMouseEnter = (dataKey) => {
    const localColors = { ...defaultColors };
    // eslint-disable-next-line no-return-assign
    Object.keys(defaultColors).forEach(name => localColors[name] = name === dataKey ? defaultColors[name] : '#edeff0');
    setBarColors(localColors);
  };

  const handleLegendItemMouseLeave = () => {
    setBarColors(defaultColors);
  };

  const CustomizedTooltip = (props) => {
    const getText = (name, value) => `${name}: ${currency ? formatCurrency(value) : formatIntNumber(value)}`;
    if (props.active && props.payload && props.payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#ffffff',
            border: '1px solid #f5f5f5',
            lineHeight: '24px',
            margin: '0',
            padding: '10px',
          }}
        >
          <p className="bold-text">
            {props.label}: {`${
              currency
                ? formatCurrency(props.payload[0].payload[`total${chartType}`])
                : formatIntNumber(props.payload[0].payload[`total${chartType}`])
            }`}
          </p>
          <ul
            style={{
              listStyle: 'none',
              padding: '0',
              margin: '0',
            }}
          >
            <li
              key={`new${chartType}`}
              style={{
                color: defaultColors.New,
              }}
            >
              {getText('New', props.payload[1].value)}
            </li>
            <li
              key={`existing${chartType}`}
              style={{
                color: defaultColors.Existing,
              }}
            >
              {getText('Existing', props.payload[0].value)}
            </li>
          </ul>
        </div>
      );
    }
    return null;
  };

  const CustomizedXAxisTick = ({ x, y, payload }) => {
    const values = [16, 26, 40];
    const shouldBeSplit = payload.value?.includes('-');
    let children = (
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    );
    if (shouldBeSplit) {
      const array = payload.value.split(' - ');
      array.splice(1, 0, '-');
      children = array.map(((string, index) => (
        <text x={0} y={0} dy={values[index]} textAnchor="middle" fill="#666">
          {string}
        </text>
      )));
    }
    return (
      <g transform={`translate(${x},${y})`}>
        {children}
      </g>
    );
  };

  const CustomizedYAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {`${currency ? '$' : ''}${formatIntNumber(payload.value, 0)}`}
      </text>
    </g>
  );

  return (
    <>
      {chartData.length ? (
        <Row>
          <Col md={10} style={{ paddingRight: '0' }}>
            <ResponsiveContainer
              width="100%"
              height={400}
              debounce={1}
            >
              <BarChart
                width="100%"
                height="100%"
                data={chartData}
                margin={chartMargins}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                <Bar
                  key={`barExisting${chartType}`}
                  stackId="a"
                  dataKey={`existing${chartType}`}
                  fill={barColors.Existing}
                  isAnimationActive={false}
                />
                <Bar
                  key={`barNew${chartType}`}
                  stackId="a"
                  dataKey={`new${chartType}`}
                  fill={barColors.New}
                  isAnimationActive={false}
                >
                  {showLabels && (<LabelList
                    position="top"
                    dataKey={`total${chartType}`}
                    formatter={value => (currency ? formatCurrency(value) : formatIntNumber(value))}
                  />)}
                </Bar>
                <XAxis
                  dataKey="label"
                  padding={{ left: 8, right: 8 }}
                  tick={<CustomizedXAxisTick />}
                  interval="preserveStartEnd"
                />
                <YAxis
                  padding={{ top: 8, bottom: 8 }}
                  tick={<CustomizedYAxisTick />}
                  domain={['dataMin', 'dataMax']}
                  interval="preserveStartEnd"
                  allowDecimals={false}
                />
                <TooltipOnLine cursor={{ fill: 'transparent' }} content={<CustomizedTooltip />} />
              </BarChart>
            </ResponsiveContainer>
          </Col>
          <Col
            md={2}
            className="d-flex align-items-center justify-content-center"
          >
            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '0',
              }}
            >
              <li
                key={`legendNew${chartType}`}
                style={{
                  color: barColors.New,
                  cursor: 'pointer',
                  fontWeight: '600',
                }}
                onMouseEnter={() => handleLegendItemMouseEnter('New')}
                onMouseLeave={handleLegendItemMouseLeave}
              >
                &#9632;  New
              </li>
              <li
                key={`legendExisting${chartType}`}
                style={{
                  color: barColors.Existing,
                  cursor: 'pointer',
                  fontWeight: '600',
                }}
                onMouseEnter={() => handleLegendItemMouseEnter('Existing')}
                onMouseLeave={handleLegendItemMouseLeave}
              >
                &#9632;  Existing
              </li>
            </ul>
          </Col>
        </Row>
      ) : (
        <span className="mt-2">No data to chart</span>
      )}
    </>
  );
};

BarChartComponent.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.any),
  chartType: PropTypes.string.isRequired,
  currency: PropTypes.bool,
};

BarChartComponent.defaultProps = {
  chartData: [],
  currency: false,
};

export default BarChartComponent;
