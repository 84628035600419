import React from 'react';
import MatTableList from '../../../../../../../shared/tables/materialTable/TableRedux';
import formater from '../../../../../../../shared/helpers/WVFormatter';
import ContactsList from './ContactsList';
import ProspectsList from './ProspectsList';

const Table = React.memo(() => {
  console.log('table');
  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Contacts Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'count',
          Header: () => 'Youtube Media URL',
          accessor: p => `https://youtube.com/channel/${p.id}`,
          // eslint-disable-next-line max-len
          Cell: p => (
            <div>
              <a
                href={p.value}
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: '4px' }}
              >
                {p.value}
              </a>
              ({formater.formatIntNumber(p.row.original.params.count)})
            </div>
          ),
          width: 150,
          className: '',
        },
        {
          id: 'creatorName',
          Header: () => 'Contacts Name',
          accessor: () => (''),
          // eslint-disable-next-line max-len
          Cell: p => (<ContactsList record={p.row.original} />),
          width: 250,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'creatorCount',
          Header: () => 'Creators For the Group',
          accessor: p => p.params.creatorCount,
          // eslint-disable-next-line max-len
          Cell: p => p.value,
          width: 150,
          className: '',
        },
        {
          id: 'prospects',
          Header: () => 'Prospects List Related to Youtube URL',
          accessor: () => (''),
          // eslint-disable-next-line max-len
          Cell: p => (<ProspectsList record={p.row.original} />),
          width: 250,
          className: '',
          disableSortBy: true,
        },
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
