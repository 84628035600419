/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { axios } from 'ApiClient';
import RefreshIcon from 'mdi-react/RefreshIcon';
import queryHasFilter from '@/shared/ViewHelpers/QueryHasFilter';
import ActionHeader from '../../../../shared/components/components/app/action-header';
import Filter from '../../../../shared/components/components/app/filter';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
import HubspotStatsWidget from '../../../../shared/components/hubspot/HubspotStatsWidget';
import UserPropertyType from './components/custom';
import ButtonWithSpinner from '../../../../shared/components/ButtonWithSpinner';
import { syncHubSpotStructure } from '../../../../redux/reducers/admin/HubilReducer';

class ResourceAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterVisible: queryHasFilter(props.location.search),
      tag: '',
      renewLoading: false,
      fetchLoading: false,
    };
  }
  setChildMethod = (setRefresh, context) => {
    this.refreshMatTable = setRefresh.bind(context);
  }
  syncHsStructure = () => {
    const { dispatch } = this.props;
    dispatch(syncHubSpotStructure());
  }
  handleFetchClick = () => {
    this.setState({ fetchLoading: true });
    axios.post('/api/portal/hubil/post-fetch-dealpipelines').then((response) => {
      if (response.data.success) {
        console.log('Success');
        this.setState({ fetchLoading: false });
        this.refreshMatTable();
      }
    }).catch((error) => {
      this.processError('fetch', error.message);
    });
  };
  handleRenewClick = () => {
    this.setState({ renewLoading: true });
    axios.post('/api/portal/hubil/renew-trial-period').then((response) => {
      if (response.data.success) {
        console.log('Success');
        this.setState({ renewLoading: false });
      }
    }).catch((error) => {
      this.processError('fetch', error.message);
    });
  };

  render() {
    const {
      resources,
      loadingSync,
    } = this.props;

    const {
      filterVisible,
      tag,
      renewLoading,
      fetchLoading,
    } = this.state;
    const resource = resources.find(r => r.id === 'HubilDealPipeline');
    if (!resource) {
      return (<NoResourceError resourceId="HubilDealPipeline" />);
    }
    const action = resource.resourceActions.find(r => r.name === 'list');
    if (!action) {
      return (<NoActionError resourceId="HubilDealPipeline" actionName="list" />);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs resource={resource} actionName="list" />
            <ActionHeader
              resource={resource}
              action={action}
              tag={tag}
              toggleFilter={() => this.setState({ filterVisible: !filterVisible })}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <HubspotStatsWidget version={(new Date()).toString()} />
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ textAlign: 'right' }}>
            <ButtonWithSpinner
              onClick={this.handleFetchClick}
              className="btn btn-sm btn-primary"
              disabled={fetchLoading}
              loading={fetchLoading}
            >
              Fetch DealPipelines from Hubspot
            </ButtonWithSpinner>
            <ButtonWithSpinner
              onClick={this.handleRenewClick}
              className="btn btn-sm btn-primary"
              disabled={renewLoading}
              loading={renewLoading}
            >
              Renew HS Dev Trial
            </ButtonWithSpinner>
            <ButtonWithSpinner
              className="btn btn-sm btn-primary"
              onClick={this.syncHsStructure}
              disabled={loadingSync}
              loading={loadingSync}
            >
              {!loadingSync ? <RefreshIcon size={18} /> : ''}
              Sync HS Structure PROD &gt; DEV
            </ButtonWithSpinner>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <MatTableList
              action={action}
              resource={resource}
              setTag={state => this.setState({ tag: state })}
              date={new Date()}
              UserPropertyType={UserPropertyType}
              columnsSettings={{
                pipeline: {
                  width: 100,
                },
                pipelineLabel: {
                  width: 150,
                },
                publishedDealstage: {
                  width: 160,
                },
                needRateFromCreator: {
                  width: 160,
                },
                inNegotiations: {
                  width: 160,
                },
                verbalAgreement: {
                  width: 160,
                },
                contractSent: {
                  width: 160,
                },
                futurePublishDate: {
                  width: 160,
                },
                contentInReview: {
                  width: 160,
                },
                dealLost: {
                  width: 160,
                },
              }}
              setRefresh={this.setChildMethod}
              hideToolbar
              v={2}
              noActions
            />
          </Col>
        </Row>
        {action.showFilter ? (
          <Filter
            resource={resource}
            isVisible={filterVisible}
            toggleFilter={() => this.setState({ filterVisible: !filterVisible })}
          />
        ) : ''}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  
  resources: state.resources,
  advertisersLog: state.advertiserReducer.logs.advertisers,
  offersLog: state.advertiserReducer.logs.offers,
  loading: state.advertiserReducer.loading.fillAdvertisers,
  loadingSync: state.hubilReducer.loading.syncHubSpotStructure,
});

export default connect(mapStateToProps)(ResourceAction);
