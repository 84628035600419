/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import { setCookie, getCookie } from '../../../../../../shared/helpers/WVUtilities';
import Hook from '../../../../../../shared/hooks';
import PublishDate from './../PublishDate';
import ContentReleaseDate from './../ContentReleaseDate';
import BrandApprovedDate from './../BrandApprovedDate';
import PaymentDate from './../PaymentDate';


const Actions = React.memo(({
  paymentStatusFilters,
  setPaymentStatusFilters,
  statusFilters,
  setStatusFilters,
  dealStageFilters,
  setDealStageFilters,
  dealstageOption,
  paymentRecipientFilters,
  setPaymentRecipientFilters,
  onSearchChange,
  searchValue,
  setShowFetchModalLoading,
  history,
  loadingBrandOptions,
  brandOptions,
  selectedBrands,
  setSelectedBrands,
  csvDownloadLink,
  setInvoiceNumber,
  authorized,
  setAuthorizedFilters,
  publishDateFrom,
  publishDateTo,
  setPublishDateFrom,
  setPublishDateTo,
  setSinglePublishDate,
  singlePublishDate,
  lastPublishDateType,
  setLastPublishDateType,
  //
  paymentMethodFilters,
  setPaymentMethodFilters,
  //
  contentReleaseDateFrom,
  contentReleaseDateTo,
  setContentReleaseDateFrom,
  setContentReleaseDateTo,
  setSingleContentReleaseDate,
  singleContentReleaseDate,
  lastContentReleaseDateType,
  setLastContentReleaseDateType,
  //
  selectedDealTypes,
  setSelectedDealTypes,
  //
  setBrandApprovedDateFrom,
  setBrandApprovedDateTo,
  setSingleBrandApprovedDate,
  setLastBrandApprovedDateType,
  lastBrandApprovedDateType,
  brandApprovedDateFrom,
  brandApprovedDateTo,
  singleBrandApprovedDate,
  //
  setPaymentDateFrom,
  setPaymentDateTo,
  setSinglePaymentDate,
  setLastPaymentDateType,
  lastPaymentDateType,
  paymentDateFrom,
  paymentDateTo,
  singlePaymentDate,
}) => {
  const [showModal] = Hook.useShowModalHook('refreshDeals');
  const [showTotalsModal] = Hook.useShowModalHook('billsTotals');
  const [search, setSearch] = useState(searchValue);
  const [showFilters, setShowFilters] = useState(getCookie('payablesBillsShowFilters') === 'true');
  const [dateSearch, setDateSearch] = useState(null);

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('payablesBillsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const handleChangePaymentStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setPaymentStatusFilters({
        ...paymentStatusFilters,
        [name]: checked,
      });
    }
  };
  const handleChangePaymentRecipientFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setPaymentRecipientFilters({
        ...paymentRecipientFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setStatusFilters({
        ...statusFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeAutorizedFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setAuthorizedFilters({ ...authorized, [name]: checked });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setPaymentStatusFilters({
      paidinfull: false,
      partiallypaid: false,
      unpaid: true,
    });
    setStatusFilters({
      unassigned: false,
      approve: true,
      decline: false,
      ignore: false,
    });
    setSelectedDealTypes([
      { value: 'Upfront Only', label: 'Upfront Only' },
      { value: 'Upfront CPM', label: 'Upfront CPM' },
      { value: 'Hybrid', label: 'Hybrid' },
      { value: 'Bonus', label: 'Bonus' },
    ]);
    const defaultDealStages = dealstageOption.find(k => k.label.toLowerCase() === 'published');
    setDealStageFilters([
      defaultDealStages,
    ]);
    setAuthorizedFilters({
      Authorized: true,
      'Not Authorized': true,
    });
    setBrandApprovedDateFrom(null);
    setBrandApprovedDateTo(null);
    setPaymentDateFrom(null);
    setPaymentDateTo(null);
    setSelectedBrands([]);
    setInvoiceNumber(null);
    setPaymentRecipientFilters({
      agent: true,
      creator: true,
      creatorCOAgent: true,
    });

    setPublishDateFrom('');
    setPublishDateTo('');
    setSinglePublishDate('');
    setLastPublishDateType({ value: '', label: '' });

    setContentReleaseDateFrom('');
    setContentReleaseDateTo('');
    setSingleContentReleaseDate('');
    setLastContentReleaseDateType({ value: '', label: '' });

    setBrandApprovedDateFrom('');
    setBrandApprovedDateTo('');
    setSingleBrandApprovedDate('');
    setLastBrandApprovedDateType({ value: '', label: '' });

    setPaymentDateFrom('');
    setPaymentDateTo('');
    setSinglePaymentDate('');
    setLastPaymentDateType({ value: '', label: '' });

    const urlSearch = new URLSearchParams(window.location.search);
    urlSearch.delete('filters');
    history.push(`${history.location.pathname}?${urlSearch.toString()}`);
  };

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  return (
    <div className="prospects-actions">
      <Row className="mt-3">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search by Creator (Agent) Name, Email or Invoice Number"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '38px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '38px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '38px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '38px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => history.push('/finance/Bill/new')}
              >
                New Bill
              </DropdownItem>
              <DropdownItem onClick={() => {
                showTotalsModal();
              }}
              >
                Display Amount Due Totals
              </DropdownItem>
              <DropdownItem onClick={() => {
                setShowFetchModalLoading(true);
              }}
              >
                Fetch Bills
              </DropdownItem>
              <DropdownItem onClick={() => {
                showModal();
              }}
              >
                Refresh Payment Authorization
              </DropdownItem>
              <DropdownItem onClick={() => window.open(csvDownloadLink, '_blank').focus()}>
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      {showFilters && (
        <>
          <Row className="mt-4">
            <Col sm={4}>
              <FormGroup>
                <Label className="bold-text">
                  Brand(s)
                </Label>
                <Select
                  isMulti
                  name="brandSelect"
                  isDisabled={loadingBrandOptions}
                  isLoading={loadingBrandOptions}
                  isClearable
                  value={!loadingBrandOptions ? selectedBrands : []}
                  options={brandOptions}
                  onChange={setSelectedBrands}
                  placeholder="Select Brand..."
                  style={{ maxHeight: '120px' }}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <Label className="bold-text">
                Deal Types
              </Label>
              <Select
                value={selectedDealTypes}
                options={[
                  { value: 'Upfront Only', label: 'Upfront Only' },
                  { value: 'Upfront CPM', label: 'Upfront CPM' },
                  { value: 'Hybrid', label: 'Hybrid' },
                  { value: 'Bonus', label: 'Bonus' },
                  { value: 'Media License', label: 'Media License' },
                ]}
                onChange={setSelectedDealTypes}
                placeholder="Deal Types..."
                isClearable
                isMulti
              />
            </Col>

            <Col sm={3}>
              <Label className="bold-text">
                Deal Stages
              </Label>
              <Select
                value={dealStageFilters}
                // options={[
                //   { value: 'Published', label: 'Published' },
                //   { value: 'Deal Paid', label: 'Deal Paid' },
                //   { value: 'New Lead', label: 'New Lead' },
                //   { value: 'In Negotiations', label: 'In Negotiations' },
                //   { value: 'Contract Sent', label: 'Contract Sent' },
                //   { value: 'Contract Signed', label: 'Contract Signed' },
                //   { value: 'Content In Review', label: 'Content In Review' },
                //   { value: 'Content Approved', label: 'Content Approved' },
                //   { value: 'Deal Lost or Passed', label: 'Deal Lost or Passed' },
                //   { value: 'BH Passed on Deal', label: 'BH Passed on Deal' },
                //   { value: 'Pricing Review', label: 'Pricing Review' },
                //   { value: 'Pricing Approved', label: 'Pricing Approved' },
                // ]}
                options={dealstageOption}
                onChange={setDealStageFilters}
                placeholder="Deal Stages..."
                isClearable
                isMulti
              />
            </Col>

          </Row>

          <Row className="mt-6">
            <Col sm={3}>
              <PublishDate
                setPublishDateFrom={setPublishDateFrom}
                setPublishDateTo={setPublishDateTo}
                setSinglePublishDate={setSinglePublishDate}
                singlePublishDate={singlePublishDate}
                lastPublishDateType={lastPublishDateType}
                setLastPublishDateType={setLastPublishDateType}
                publishDateFrom={publishDateFrom}
                publishDateTo={publishDateTo}
              />
            </Col>
            <Col sm={3}>
              <ContentReleaseDate
                setReleaseDateFrom={setContentReleaseDateFrom}
                setReleaseDateTo={setContentReleaseDateTo}
                setSingleDate={setSingleContentReleaseDate}
                singleContentReleaseDate={singleContentReleaseDate}
                lastContentReleaseDateType={lastContentReleaseDateType}
                setLastContentReleaseDateType={setLastContentReleaseDateType}
                releaseDateFrom={contentReleaseDateFrom}
                releaseDateTo={contentReleaseDateTo}
              />
            </Col>
            <Col sm={3}>
              <BrandApprovedDate
                setBrandApprovedDateFrom={setBrandApprovedDateFrom}
                setBrandApprovedDateTo={setBrandApprovedDateTo}
                setSingleBrandApprovedDate={setSingleBrandApprovedDate}
                setLastBrandApprovedDateType={setLastBrandApprovedDateType}
                lastBrandApprovedDateType={lastBrandApprovedDateType}
                brandApprovedDateFrom={brandApprovedDateFrom}
                brandApprovedDateTo={brandApprovedDateTo}
                singleBrandApprovedDate={singleBrandApprovedDate}
              />
            </Col>
            <Col sm={3}>
              <PaymentDate
                setPaymentDateFrom={setPaymentDateFrom}
                setPaymentDateTo={setPaymentDateTo}
                setSinglePaymentDate={setSinglePaymentDate}
                setLastPaymentDateType={setLastPaymentDateType}
                lastPaymentDateType={lastPaymentDateType}
                paymentDateFrom={paymentDateFrom}
                paymentDateTo={paymentDateTo}
                singlePaymentDate={singlePaymentDate}
              />
            </Col>
          </Row>

          <Row className="mt-4" style={{ marginTop: '20px' }}>
          <Col sm={2}>
              <div>
                <Label className="bold-text" style={{ marginBottom: '14px' }}>
                  Payment Authorization
                </Label>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="Authorized"
                    label="Authorized"
                    value={authorized.Authorized}
                    onChange={event => handleChangeAutorizedFilters(event, 'Authorized')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="NotAuthorized"
                    label="Not Authorized"
                    value={authorized['Not Authorized']}
                    onChange={event => handleChangeAutorizedFilters(event, 'Not Authorized')}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Approval Status
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="unassigned"
                  label="Unassigned"
                  value={statusFilters.unassigned}
                  onChange={event => handleChangeStatusFilters(event, 'unassigned')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="approve"
                  label="Approve"
                  value={statusFilters.approve}
                  onChange={event => handleChangeStatusFilters(event, 'approve')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="decline"
                  label="Decline"
                  value={statusFilters.decline}
                  onChange={event => handleChangeStatusFilters(event, 'decline')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="ignore"
                  label="Ignore"
                  value={statusFilters.ignore}
                  onChange={event => handleChangeStatusFilters(event, 'ignore')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Payment Status
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="paidinfull"
                  label="Paid In Full"
                  value={paymentStatusFilters.paidinfull}
                  onChange={event => handleChangePaymentStatusFilters(event, 'paidinfull')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="partiallypaid"
                  label="Partially Paid"
                  value={paymentStatusFilters.partiallypaid}
                  onChange={event => handleChangePaymentStatusFilters(event, 'partiallypaid')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="unpaid"
                  label="Unpaid"
                  value={paymentStatusFilters.unpaid}
                  onChange={event => handleChangePaymentStatusFilters(event, 'unpaid')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
            <Label className="bold-text" style={{ marginBottom: '14px' }}>
              Payment Method
            </Label>
            <FormGroup
              style={{ marginBottom: '10px' }}
            >
              <CheckBoxField
                name="Stripe"
                label="Stripe"
                value={paymentMethodFilters.Stripe}
                onChange={event => event.target && setPaymentMethodFilters(f => ({ ...f, Stripe: event.target?.checked }))}
              />
            </FormGroup>
            <FormGroup
              style={{ marginBottom: '10px' }}
            >
              <CheckBoxField
                name="Billcom"
                label="Bill.com"
                value={paymentMethodFilters['Bill.com']}
                onChange={event => event.target && setPaymentMethodFilters(f => ({ ...f, 'Bill.com': event.target?.checked }))}
              />
            </FormGroup>
            <FormGroup
              style={{ marginBottom: '10px' }}
            >
              <CheckBoxField
                name="NotConnected"
                label="Not Connected"
                value={paymentMethodFilters['Not Connected']}
                onChange={event => event.target && setPaymentMethodFilters(f => ({ ...f, 'Not Connected': event.target?.checked }))}
              />
            </FormGroup>
            </Col>
            <Col sm={2}>
              <div>
                <Label className="bold-text" style={{ marginBottom: '14px' }}>
                  Payee
                </Label>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="agent"
                    label="Agent"
                    value={paymentRecipientFilters.agent}
                    onChange={event => handleChangePaymentRecipientFilters(event, 'agent')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="creator"
                    label="Creator"
                    value={paymentRecipientFilters.creator}
                    onChange={event => handleChangePaymentRecipientFilters(event, 'creator')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="creatorCOAgent"
                    label="Creator c/o Agent"
                    value={paymentRecipientFilters.creatorCOAgent}
                    onChange={event => handleChangePaymentRecipientFilters(event, 'creatorCOAgent')}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  a.loadingBrandOptions === a1.loadingBrandOptions &&
  _.isEqual(a.paymentStatusFilters, a1.paymentStatusFilters) &&
  _.isEqual(a.statusFilters, a1.statusFilters) &&
  _.isEqual(a.dealStageFilters, a1.dealStageFilters) &&
  _.isEqual(a.paymentRecipientFilters, a1.paymentRecipientFilters) &&
  //
  _.isEqual(a.publishDateFrom, a1.publishDateFrom) &&
  _.isEqual(a.publishDateTo, a1.publishDateTo) &&
  _.isEqual(a.singlePublishDate, a1.singlePublishDate) &&
  _.isEqual(a.lastPublishDateType, a1.lastPublishDateType) &&
  //
  _.isEqual(a.contentReleaseDateFrom, a1.contentReleaseDateFrom) &&
  _.isEqual(a.contentReleaseDateTo, a1.contentReleaseDateTo) &&
  _.isEqual(a.singleContentReleaseDate, a1.singleContentReleaseDate) &&
  _.isEqual(a.lastContentReleaseDateType, a1.lastContentReleaseDateType) &&
  //
  _.isEqual(a.lastBrandApprovedDateType, a1.lastBrandApprovedDateType) &&
  _.isEqual(a.brandApprovedDateFrom, a1.brandApprovedDateFrom) &&
  _.isEqual(a.brandApprovedDateTo, a1.brandApprovedDateTo) &&
  _.isEqual(a.singleBrandApprovedDate, a1.singleBrandApprovedDate) &&
  //
  _.isEqual(a.lastPaymentDateType, a1.lastPaymentDateType) &&
  _.isEqual(a.paymentDateFrom, a1.paymentDateFrom) &&
  _.isEqual(a.paymentDateTo, a1.paymentDateTo) &&
  _.isEqual(a.singlePaymentDate, a1.singlePaymentDate) &&
  //
  _.isEqual(a.selectedDealTypes, a1.selectedDealTypes) &&
  //
  _.isEqual(a.paymentMethodFilters, a1.paymentMethodFilters) &&
  //
  _.isEqual(a.brandOptions, a1.brandOptions) &&
  _.isEqual(a.selectedBrands, a1.selectedBrands) &&
  _.isEqual(a.apSelected, a1.apSelected) &&
  _.isEqual(a.authorized, a1.authorized) &&
  _.isEqual(a.csvDownloadLink, a1.csvDownloadLink));

const mapStateToProps = state => ({

  resources: state.resources,
});

export default withStyles({
  root: {
    width: '100%',
    minWidth: '200px',
    minHeight: '44px',
    paddingRight: '30px',
    position: 'relative',
    marginBottom: '10px',
  },
  close: {
    position: 'absolute',
    bottom: '1px',
    right: '0px',
  },
})(connect(mapStateToProps)(Actions));
