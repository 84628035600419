import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  InputGroup, Input, InputGroupAddon, InputGroupText,
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Row, Col, Button, Label, FormGroup,
} from 'reactstrap';
import SelectAsync from 'react-select/async';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import moment from 'moment';
import { getCookie, setCookie } from '../../../../../shared/helpers/WVUtilities';
import TimePeriodFilter from './custom/actions/TimePeriodFilter';
import { loadBrandsOptions, loadCreatorsOptions } from '../../../../../shared/helpers/OptionsLoader';
import { setUrl } from './custom/actions/setUrl';
import { dateRangeLabels } from '../../../../../shared/helpers/Dates';
import { fetchDictionary } from '../../../../../shared/helpers/WVRequest';
import Filter from '../../../../../shared/components/filter/TimePeriodFilter';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';
import hooks from '../../../../../shared/hooks';
import Overlay from "../../../../../shared/components/Overlay";
import HumanSentiment from "./custom/actions/HumanSentiment";
import HumanBrandRelevancy from "./custom/actions/HumanBrandRelevancy";
import TypeFilter from "./custom/actions/TypeFilter";
import AiSentimentFilter from "./custom/actions/AiSentimentFilter";
import AiBrandRelevancyFilter from "./custom/actions/AiBrandRelevancyFilter";
import CommentDateFilter from "./custom/actions/CommentDateFilter";
import PublishDateFilter from "./custom/actions/PublishDateFilter";

const dateRangeFilterLabels = dateRangeLabels();

const sentimentFilter = {
  unreviewed: true,
  positive: false,
  negative: false,
  ignore: false,
};

const brandRelevantFilter = {
  unreviewed: true,
  relevant: false,
  ignore: false,
};
const AiSentimentFilterCheck = {
  positive: false,
  negative: false,
  ignore: false,
};
const AiBrandRelevantFilterCheck = {
  relevant: false,
  ignore: false,
};

const AiSentimentScorePrefill = {
  positive: [0, 0.95],
  negative: [0, 0.95],
  ignore: [0, 0.95],
};
const AiBrandRelevantScorePrefill = {
  relevant: [0, 0.95],
  ignore: [0, 0.95],
};

const contentTypeFilter = {
  youtube: true,
  youtubeDescription: true,
};

const Actions = ({
  selectedCommentIds,
  downloadCsv,
  setShowUpdateStatusModal,
  loading,
  setShowUpdateUnreviewedModal,
  setShowTable,
}) => {
  const [useShowForUsers] = hooks.useAccessHook({ hideWhenTeam: ['Auditors'] });
  const history = useHistory();
  let selectedStartDateInitialState = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss')).startOf('month');
  let selectedEndDateInitialState = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss')).endOf('day');
  let selectedInitialRange = '7_days';

  const initialSearch = new URLSearchParams(window.location.search);
  if (initialSearch.has('filters.publishedAt.from') && initialSearch.getAll('filters.publishedAt.from').length > 0) {
    initialSearch.getAll('filters.publishedAt.from').forEach((from) => {
      selectedStartDateInitialState = moment(moment.utc(from).format('YYYY-MM-DD'));
    });
  }
  if (initialSearch.has('filters.publishedAt.to') && initialSearch.getAll('filters.publishedAt.to').length > 0) {
    initialSearch.getAll('filters.publishedAt.to').forEach((to) => {
      selectedEndDateInitialState = moment(moment.utc(to).format('YYYY-MM-DD'));
    });
  }
  if (initialSearch.has('range') && initialSearch.getAll('range').length > 0) {
    initialSearch.getAll('range').forEach((range) => {
      selectedInitialRange = range;
    });
  }
  if (initialSearch.has('filters.sentiment') && initialSearch.getAll('filters.sentiment').length > 0) {
    Object.keys(sentimentFilter).map((key) => {
      sentimentFilter[key] = false;
      return true;
    });
    initialSearch.getAll('filters.sentiment').forEach((sentiment) => {
      sentimentFilter[sentiment] = true;
    });
  }
  if (initialSearch.has('filters.sentimentAnalyzer') && initialSearch.getAll('filters.sentimentAnalyzer').length > 0) {
    Object.keys(brandRelevantFilter).map((key) => {
      brandRelevantFilter[key] = false;
      return true;
    });
    initialSearch.getAll('filters.sentimentAnalyzer').forEach((sentiment) => {
      brandRelevantFilter[sentiment] = true;
    });
  }
  if (initialSearch.has('filters.humanSentiment') && initialSearch.getAll('filters.humanSentiment').length > 0) {
    Object.keys(sentimentFilter).map((key) => {
      sentimentFilter[key] = false;
      return true;
    });
    initialSearch.getAll('filters.humanSentiment').forEach((sentiment) => {
      sentimentFilter[sentiment] = true;
    });
  }
  if (initialSearch.has('filters.brandRelevancy') && initialSearch.getAll('filters.brandRelevancy').length > 0) {
    Object.keys(brandRelevantFilter).map((key) => {
      brandRelevantFilter[key] = false;
      return true;
    });
    initialSearch.getAll('filters.brandRelevancy').forEach((sentiment) => {
      brandRelevantFilter[sentiment] = true;
    });
  }

  if (initialSearch.has('filters.mediaContentType') && initialSearch.getAll('filters.mediaContentType').length > 0) {
    Object.keys(contentTypeFilter).map((key) => {
      contentTypeFilter[key] = false;
      return true;
    });
    initialSearch.getAll('filters.mediaContentType').forEach((item) => {
      contentTypeFilter[item] = true;
    });
  }
  if (initialSearch.has('filters.aiSentimentScoreQ') && initialSearch.getAll('filters.aiSentimentScoreQ').length > 0) {
    const aiSentimentScoreQInitial = initialSearch.get('filters.aiSentimentScoreQ');
    const arr = aiSentimentScoreQInitial.split('/');
    if (arr.length) {
      arr.forEach((item) => {
        if (item.includes('pos')) {
          const values = item.replace('pos', '').split('--');
          AiSentimentFilterCheck.positive = true;
          AiSentimentScorePrefill.positive = [Number(values[0]), Number(values[1])];
        }
        if (item.includes('neg')) {
          const values = item.replace('neg', '').split('--');
          AiSentimentFilterCheck.negative = true;
          AiSentimentScorePrefill.negative = [Number(values[0]), Number(values[1])];
        }
        if (item.includes('ig')) {
          const values = item.replace('ig', '').split('--');
          AiSentimentFilterCheck.ignore = true;
          AiSentimentScorePrefill.ignore = [Number(values[0]), Number(values[1])];
        }
      });
    }
  }
  if (initialSearch.has('filters.aiBrandScoreQ') && initialSearch.getAll('filters.aiBrandScoreQ').length > 0) {
    const aiBrandScoreQInitial = initialSearch.get('filters.aiBrandScoreQ');
    const arr = aiBrandScoreQInitial.split('/');
    if (arr.length) {
      arr.forEach((item) => {
        if (item.includes('rel')) {
          const values = item.replace('rel', '').split('--');
          AiBrandRelevantFilterCheck.relevant = true;
          AiBrandRelevantScorePrefill.relevant = [Number(values[0]), Number(values[1])];
        }
        if (item.includes('ig')) {
          const values = item.replace('ig', '').split('--');
          AiBrandRelevantFilterCheck.ignore = true;
          AiBrandRelevantScorePrefill.ignore = [Number(values[0]), Number(values[1])];
        }
      });
    }
  }

  useEffect(() => {
    let newUrl = `${history.location.pathname}`;
    if (!useShowForUsers) {
      newUrl = `${history.location.pathname}`;
      history.push(newUrl);
    }
  }, [useShowForUsers]);
  const [search, setSearch] = useState('');
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('youtubeCommentsShowFilters') === 'true');
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [filtersCommentSentiment, setFiltersCommentSentiment] = useState(sentimentFilter);
  const [filterBrandRelevant, setFilterBrandRelevant] = useState(brandRelevantFilter);
  const [filterContentType, setFilterContentType] = useState(contentTypeFilter);

  const [dateFrom, setDateFrom] = useState(selectedStartDateInitialState.format('YYYY-MM-DD HH:mm:ss'));
  const [dateTo, setDateTo] = useState(selectedEndDateInitialState.format('YYYY-MM-DD HH:mm:ss'));
  const [selectedButton, setSelectedButton] = useState(selectedInitialRange);

  const [enableCommentDateFilter, setEnableCommentDateFilter] = useState(true);
  const [singleCommentDate, setSingleCommentDate] = useState(null);
  const [commentDateFilterType, setCommentDateFilterType] = useState(dateRangeFilterLabels[3]);
  const [commentDateFrom, setCommentDateFrom] = useState(selectedStartDateInitialState ? selectedStartDateInitialState.toDate() : null);
  const [commentDateTo, setCommentDateTo] = useState(selectedEndDateInitialState ? selectedEndDateInitialState.toDate() : null);

  const [enableVideoPublishDateFilter, setEnableVideoPublishDateFilter] = useState(true);
  const [singleVideoPublishDate, setSingleVideoPublishDate] = useState(null);
  const [videoPublishDateFilterType, setVideoPublishDateFilterType] = useState(dateRangeFilterLabels[1]);
  const [videoPublishDateFrom, setVideoPublishDateFrom] = useState(null);
  const [videoPublishDateTo, setVideoPublishDateTo] = useState(null);
  const [minDate, setMinDate] = useState(null);

  const [aiSentimentFilterCheck, setAiSentimentFilterCheck] = useState(AiSentimentFilterCheck);
  const [aiSentimentPositiveScore, setAiSentimentPositiveScore] = useState(AiSentimentScorePrefill.positive);
  const [aiSentimentNegativeScore, setAiSentimentNegativeScore] = useState(AiSentimentScorePrefill.negative);
  const [aiSentimentIgnoreScore, setAiSentimentIgnoreScore] = useState(AiSentimentScorePrefill.ignore);

  const [aiBrandRelevantFilterCheck, setAiBrandRelevantFilterCheck] = useState(AiBrandRelevantFilterCheck);
  const [aiBrandRelevantScore, setAiBrandRelevantScore] = useState(AiBrandRelevantScorePrefill.relevant);
  const [aiBrandIgnoreScore, setAiBrandIgnoreScore] = useState(AiBrandRelevantScorePrefill.ignore);
  const firstUpdate = useRef(true);

  // const setUrlDebounced = useAsyncDebounce(() => {
  //   setUrl(
  //     history, false,
  //     search, dateFrom, dateTo, selectedButton,
  //     commentDateFrom, commentDateTo, singleCommentDate, commentDateFilterType,
  //     selectedCreators, selectedBrands, filtersCommentSentiment, filterBrandRelevant,
  //     videoPublishDateFrom, videoPublishDateTo, singleVideoPublishDate, videoPublishDateFilterType,
  //     aiSentimentFilterCheck, aiSentimentPositiveScore, aiSentimentNegativeScore, aiSentimentIgnoreScore,
  //     aiBrandRelevantFilterCheck, aiBrandRelevantScore, aiBrandIgnoreScore
  //   );
  // },  2200);

  // useEffect(() => {
  //   if (firstUpdate.current) {
  //     firstUpdate.current = false;
  //     return;
  //   }
  //   setUrlDebounced();
  // }, [
  //   search,
  //   dateFrom, dateTo,
  //   selectedBrands, selectedCreators, filtersCommentSentiment, filterBrandRelevant,
  //   commentDateFrom, commentDateTo, singleCommentDate, commentDateFilterType,
  //   videoPublishDateFrom, videoPublishDateTo, singleVideoPublishDate, videoPublishDateFilterType,
  //   aiSentimentFilterCheck, aiSentimentPositiveScore, aiSentimentNegativeScore, aiSentimentIgnoreScore,
  //   aiBrandRelevantFilterCheck, aiBrandRelevantScore, aiBrandIgnoreScore,
  // ]);

  // useEffect(() => {
  //   if (runUrl) {
  //     setUrl(
  //       history, false,
  //       search, dateFrom, dateTo, selectedButton,
  //       commentDateFrom, commentDateTo, singleCommentDate, commentDateFilterType,
  //       selectedCreators, selectedBrands, filtersCommentSentiment, filterBrandRelevant,
  //       videoPublishDateFrom, videoPublishDateTo, singleVideoPublishDate, videoPublishDateFilterType,
  //       aiSentimentFilterCheck, aiSentimentPositiveScore, aiSentimentNegativeScore, aiSentimentIgnoreScore,
  //       aiBrandRelevantFilterCheck, aiBrandRelevantScore, aiBrandIgnoreScore
  //     );
  //   }
  // }, [runUrl]);

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      // onSearchChange(search);
      // console.log(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('youtubeCommentsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  useEffect(() => {
    fetchDictionary('DATE_RANGE_MIN_VALUE').then((data) => {
      if (data.success) {
        setMinDate(moment(data.records.DATE_RANGE_MIN_VALUE));
      }
    });
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      loadCreatorsOptions('getByIds').then((response) => {
        setSelectedCreators(response);
      });
    }
    if (initialSearch.has('filters.brand') && initialSearch.getAll('filters.brand').length > 0) {
      loadBrandsOptions('getByIds').then((response) => {
        setSelectedBrands(response);
      });
    }
  }, []);

  const fetchComments = () => {
    console.log(firstUpdate.current);
    setShowTable(true);
    setUrl(
      history, false,
      search, dateFrom, dateTo, selectedButton,
      commentDateFrom, commentDateTo, singleCommentDate, commentDateFilterType,
      selectedCreators, selectedBrands, filtersCommentSentiment, filterBrandRelevant,
      videoPublishDateFrom, videoPublishDateTo, singleVideoPublishDate, videoPublishDateFilterType,
      aiSentimentFilterCheck, aiSentimentPositiveScore, aiSentimentNegativeScore, aiSentimentIgnoreScore,
      aiBrandRelevantFilterCheck, aiBrandRelevantScore, aiBrandIgnoreScore, filterContentType,
    );
  };

  const handleChangeSentimentFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersCommentSentiment({
        ...filtersCommentSentiment,
        [name]: checked,
      });
    }
  };
  const handleChangeBrandRelevantFilter = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFilterBrandRelevant({
        ...filterBrandRelevant,
        [name]: checked,
      });
    }
  };
  const handleChangeAiSentimentCheckFilter = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setAiSentimentFilterCheck({
        ...aiSentimentFilterCheck,
        [name]: checked,
      });
    }
  };
  const handleChangeAiBrandRelevantCheckFilter = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setAiBrandRelevantFilterCheck({
        ...aiBrandRelevantFilterCheck,
        [name]: checked,
      });
    }
  };
  const handleChangeContentTypeFilter = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFilterContentType({
        ...filterContentType,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    setSelectedBrands([]);
    setSelectedCreators([]);
    setFiltersCommentSentiment(sentimentFilter);
    setFilterBrandRelevant(brandRelevantFilter);

    setEnableCommentDateFilter(false);
    setCommentDateFilterType(dateRangeFilterLabels[3]);
    setCommentDateFrom(null);
    setCommentDateTo(null);
    setSingleCommentDate(null);

    setEnableVideoPublishDateFilter(false);
    setVideoPublishDateFilterType(dateRangeFilterLabels[1]);
    setVideoPublishDateFrom(null);
    setVideoPublishDateTo(null);
    setSingleVideoPublishDate(null);

    setFiltersCommentSentiment({ unreviewed: true, positive: false, negative: false, ignore: false });
    setFilterBrandRelevant({ unreviewed: true, relevant: false, ignore: false });

    setAiBrandRelevantFilterCheck({ relevant: true, ignore: true });
    setAiSentimentFilterCheck({ positive: true, negative: true, ignore: true });
    setAiSentimentPositiveScore([0, 0.95]);
    setAiSentimentNegativeScore([0, 0.95]);
    setAiSentimentIgnoreScore([0, 0.95]);
    setAiBrandRelevantScore([0, 0.95]);
    setAiBrandIgnoreScore([0, 0.95]);

    setFilterContentType({ youtube: true, youtubeDescription: true });
  };

  return (
    <div className="mb-1">
      {loading && (<Overlay addSpinner={false} />)}
      <Row>
        <Col>
          <AccessComponent hideWhenTeam={['Auditors']}>
            <TimePeriodFilter
              startDateInitial={dateFrom}
              endDateInitial={dateTo}
              selectedButtonInitial={selectedButton}
              setValue={(param, value, range) => {
                if (param === 'startDate') {
                  setDateFrom(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
                  setCommentDateFrom(moment(moment(value).format('YYYY-MM-DD HH:mm:ss')).startOf('day').toDate());
                }
                if (param === 'endDate') {
                  setDateTo(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
                  setCommentDateTo(moment(moment(value).format('YYYY-MM-DD HH:mm:ss')).endOf('day').toDate());
                }
                setSelectedButton(range);
              }}
              minDate={minDate}
            />
          </AccessComponent>
          <AccessComponent showWhenTeam={['Auditors']}>
            <Filter
              startDateInitial={dateFrom}
              endDateInitial={dateTo}
              selectedButtonInitial={selectedButton}
              setValue={(param, value, range) => {
                if (param === 'startDate') {
                  setDateFrom(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
                  setCommentDateFrom(moment(moment(value).format('YYYY-MM-DD HH:mm:ss')).startOf('day').toDate());
                }
                if (param === 'endDate') {
                  setDateTo(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
                  setCommentDateTo(moment(moment(value).format('YYYY-MM-DD HH:mm:ss')).endOf('day').toDate());
                }
                setSelectedButton(range);
              }}
              options={[
                { value: '30_days', label: '30 Days', border: '5px 0 0 5px' },
                { value: '7_days', label: 'Last 7 Days' },
                {
                  value: 'today', label: 'Today', border: '0 5px 5px 0', lastElement: true,
                },
              ]}
              selectDateRangeFunction={(range) => {
                let dFrom;
                let dTo;
                let showCustom = false;
                switch (range) {
                  case '30_days':
                    showCustom = false;
                    dFrom = moment().subtract(31, 'days').startOf('day').toDate();
                    dTo = moment().subtract(1, 'day').endOf('day').toDate();
                    break;
                  case '7_days':
                    showCustom = false;
                    dFrom = moment().subtract(8, 'days').startOf('day').toDate();
                    dTo = moment().subtract(1, 'day').endOf('day').toDate();
                    break;
                  case 'today':
                    showCustom = false;
                    dFrom = moment().startOf('day').toDate();
                    dTo = moment().endOf('day').toDate();
                    break;
                  default:
                    dFrom = moment().startOf('day').toDate();
                    dTo = moment().endOf('day').toDate();
                    break;
                }
                console.log({ dateFrom, dateTo, showCustom });
                return { dateFrom: dFrom, dateTo: dTo, showCustom };
              }
              }
            />
          </AccessComponent>
        </Col>
        <Col lg="auto">
          <div>
            <Row>
              <Col>&nbsp;</Col>
            </Row>
            <Row>
              <Col>
                <Button
                  color="primary"
                  onClick={fetchComments}
                  className="btn-sm"
                  disabled={!!loading}
                >
                  Run
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Comment Text, Creator Name or Video Title"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <AccessComponent hideWhenTeam={['Auditors']}>
          <Col sm={2} className="text-right">
            <UncontrolledButtonDropdown className="w-100">
              <DropdownToggle
                id="actionsMenu"
                caret
                color="primary"
                style={{
                  padding: '4px 25px',
                  height: '42px',
                }}
              >
                Actions
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => { setShowUpdateStatusModal(true); }}
                  disabled={selectedCommentIds.length === 0}
                >
                  Update Sentiment & Relevancy
                </DropdownItem>
                <DropdownItem
                  onClick={() => { setShowUpdateUnreviewedModal(true); }}
                  // disabled={selectedCommentIds.length === 0}
                >
                  Update Unreviewed Sentiment & Relevancy
                </DropdownItem>
                <DropdownItem
                  onClick={downloadCsv}
                >
                  Download CSV
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
        </AccessComponent>
      </Row>
      {showFilters && (
        <>
          <Row className="mt-3">
            <Col md={3}>
              <FormGroup>
                <Label className="bold-text">
                  Brand(s)
                </Label>
                <SelectAsync
                  cacheOptions
                  defaultOptions
                  isClearable
                  isMulti
                  loadOptions={async value => loadBrandsOptions(value)}
                  onChange={setSelectedBrands}
                  placeholder="Select Brand..."
                  value={selectedBrands}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label className="bold-text">
                  Creator(s)
                </Label>
                <SelectAsync
                  cacheOptions
                  defaultOptions
                  isClearable
                  isMulti
                  loadOptions={async value => loadCreatorsOptions(value)}
                  onChange={setSelectedCreators}
                  placeholder="Select Creator..."
                  value={selectedCreators}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
            <AccessComponent hideWhenTeam={['Auditors']}>
              <Col md={3}>
                <PublishDateFilter
                  enableVideoPublishDateFilter={enableVideoPublishDateFilter}
                  setEnableVideoPublishDateFilter={setEnableVideoPublishDateFilter}
                  videoPublishDateFilterType={videoPublishDateFilterType}
                  setVideoPublishDateFilterType={setVideoPublishDateFilterType}
                  dateRangeFilterLabels={dateRangeFilterLabels}
                  setVideoPublishDateFrom={setVideoPublishDateFrom}
                  setVideoPublishDateTo={setVideoPublishDateTo}
                  videoPublishDateFrom={videoPublishDateFrom}
                  videoPublishDateTo={videoPublishDateTo}
                  singleVideoPublishDate={singleVideoPublishDate}
                  setSingleVideoPublishDate={setSingleVideoPublishDate}
                  minDate={minDate}
                />
              </Col>
              <Col md={3}>
                <CommentDateFilter
                  enableCommentDateFilter={enableCommentDateFilter}
                  setEnableCommentDateFilter={setEnableCommentDateFilter}
                  commentDateFilterType={commentDateFilterType}
                  setCommentDateFilterType={setCommentDateFilterType}
                  dateRangeFilterLabels={dateRangeFilterLabels}
                  setCommentDateFrom={setCommentDateFrom}
                  setCommentDateTo={setCommentDateTo}
                  commentDateFrom={commentDateFrom}
                  commentDateTo={commentDateTo}
                  singleCommentDate={singleCommentDate}
                  setSingleCommentDate={setSingleCommentDate}
                  minDate={minDate}
                />
              </Col>
            </AccessComponent>
          </Row>
          <AccessComponent hideWhenTeam={['Auditors']}>
            <Row className="mt-1">
              <Col md={5}>
                <HumanSentiment filtersCommentSentiment={filtersCommentSentiment} handleChangeSentimentFilters={handleChangeSentimentFilters} />
              </Col>
              <Col md={4}>
                <HumanBrandRelevancy handleChangeBrandRelevantFilter={handleChangeBrandRelevantFilter} filterBrandRelevant={filterBrandRelevant} />
              </Col>
              <Col md={3} style={{ paddingRight: 0 }}>
                <TypeFilter filterContentType={filterContentType} handleChangeContentTypeFilter={handleChangeContentTypeFilter} />
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <AiSentimentFilter
                  aiSentimentFilterCheck={aiSentimentFilterCheck}
                  handleChangeAiSentimentCheckFilter={handleChangeAiSentimentCheckFilter}
                  setAiSentimentPositiveScore={setAiSentimentPositiveScore}
                  setAiSentimentNegativeScore={setAiSentimentNegativeScore}
                  setAiSentimentIgnoreScore={setAiSentimentIgnoreScore}
                  aiSentimentPositiveScore={aiSentimentPositiveScore}
                  aiSentimentNegativeScore={aiSentimentNegativeScore}
                  aiSentimentIgnoreScore={aiSentimentIgnoreScore}
                />
              </Col>
              <Col md={5}>
                <AiBrandRelevancyFilter
                  aiBrandRelevantFilterCheck={aiBrandRelevantFilterCheck}
                  handleChangeAiBrandRelevantCheckFilter={handleChangeAiBrandRelevantCheckFilter}
                  aiBrandRelevantScore={aiBrandRelevantScore}
                  setAiBrandRelevantScore={setAiBrandRelevantScore}
                  aiBrandIgnoreScore={aiBrandIgnoreScore}
                  setAiBrandIgnoreScore={setAiBrandIgnoreScore}
                />
              </Col>
              <Col md={2} />
            </Row>
          </AccessComponent>
        </>
      )}
    </div>
  );
};
Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
  setShowUpdateStatusModal: PropTypes.func.isRequired,
  selectedCommentIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  setShowUpdateUnreviewedModal: PropTypes.func.isRequired,
  setShowTable: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  loading: false,
};

const mapStateToProps = state => ({
  total: state.table.total,
  loading: state.table.loading,
});

export default connect(mapStateToProps)(Actions);
