/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import APReportContainer from './components/APReportContainer';
import APReportContainer2 from './components/APReportContainer';
import { getAccountPayableRecord } from '../../../../redux/reducers/admin/AccountsPayableReducer';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import SearchBar from './SearchBar';
import Modal from './components/RefreshPayableModal';
import { addNotice } from '../../../App/store/store';

const AccountPayableList = ({
  match, selectedReport,
  isLoading,
  dispatch,
}) => {
  useEffect(() => {
    if (match && match.params.reportId && (!selectedReport || selectedReport?.id !== match.params.reportId)) {
      dispatch(getAccountPayableRecord(match.params.reportId));

      // history.push(`/finance/account-payable-report-2/${selectedReport?.id}/creators`);
      // this.fetchAccoutPayable(this.props);
    }
  }, [match.params.reportId]);
  console.log(selectedReport, 'selectedReport');
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Account Payable Report', path: '/finance/account-payable-report-2' },
          { title: 'Account Payable Report Dashboard', path: null },
        ]}
        isBackButton
      />
      <Row>
        <Col sm={12}>
          <SearchBar />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {selectedReport && selectedReport.id && match.params.reportId === selectedReport.id ? (
          <Card>
            <CardBody>
              <APReportContainer2 selectedReport={selectedReport} isLoading={isLoading} type={match.params.type} />
            </CardBody>
          </Card>
          ) : null }
        </Col>
      </Row>
      <Modal addNotice={(a) => { dispatch(addNotice(a)); }} />
    </Container>
  );
};

AccountPayableList.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedReport: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool.isRequired,
  // showUpdateAmountModal: PropTypes.bool.isRequired,
};
AccountPayableList.defaultProps = {
  selectedReport: null,
};
const mapStateToProps = state => ({
  selectedReport: state.accountPayable.accountPayable,
  isLoading: state.accountPayable.loading.accountPayable,
  showUpdateAmountModal: state.accountPayable.showUpdateAmountModal,
  resources: state.resources,
});

export default withStyles({
  root: {
    width: '100%',
    minWidth: '250px',
    paddingRight: '30px',
    paddingLeft: '15px',
    position: 'relative',
  },
  close: {
    position: 'absolute',
    bottom: '5px',
    right: '0px',

  },
})(connect(mapStateToProps, null)(AccountPayableList));
