import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VideoCommentsActions from './VideoCommentsActions';
import VideoCommentsTable from './VideoCommentsTable';

const sentimentFilter = {
  Positive: true,
  Negative: true,
  Ignore: true,
};

const brandRelevantFilter = {
  Relevant: true,
  Ignore: true,
};

const VideoComments = ({
  data,
  mediaContentId,
  mediaUrl,
  influencerId,
}) => {
  const [searchText, setSearchText] = useState('');
  const [filtersCommentSentiment, setFiltersCommentSentiment] = useState(sentimentFilter);
  const [filtersBrandRelevant, setFiltersBrandRelevant] = useState(brandRelevantFilter);

  const resetFilters = () => {
    setSearchText('');
    setFiltersCommentSentiment(sentimentFilter);
    setFiltersBrandRelevant(brandRelevantFilter);
  }

  useEffect(() => {
    return resetFilters;
  }, []);

  return (
    <>
      <VideoCommentsActions
        setSearchText={setSearchText}
        setFiltersCommentSentiment={setFiltersCommentSentiment}
        setFiltersBrandRelevant={setFiltersBrandRelevant}
      />
      <VideoCommentsTable
        data={data}
        mediaContentId={mediaContentId}
        mediaUrl={mediaUrl}
        influencerId={influencerId}
        searchText={searchText}
        filtersCommentSentiment={filtersCommentSentiment}
        filtersBrandRelevant={filtersBrandRelevant}
      />
    </>
  );
}

VideoComments.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  mediaContentId: PropTypes.string,
  mediaUrl: PropTypes.string,
  influencerId: PropTypes.string,
};

VideoComments.defaultProps = {
  data: [],
  mediaContentId: '',
  mediaUrl: '',
  influencerId: '',
};

export default VideoComments;
