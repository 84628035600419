import React, { useEffect, useState } from 'react';
import { Row, Col, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import WVFormatter from '../../../../../../shared/helpers/WVFormatter';
import ButtonWithSpinner from '../../../../../../shared/components/ButtonWithSpinner';

function GraySpinner() {
  return (
    <span>
      <div
        className="spinner-border il-text-gray spinner-border-sm"
        style={{ width: '1.5rem', height: '1.5rem', borderWidth: '.10em' }}
      />
    </span>
  );
}

const StripeBalanceControls = ({addNotice}) => {
  const [currentBalance, setCurrentBalance] = useState(0);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [loading, setLoading] = useState({
    balance: false,
    payout: false,
    addBalance: false,
    transfer: false,
  });

  const fetchStripeBalance = () => {
    setLoading({
      ...loading,
      balance: true,
    });

    axios.get('/api/portal/stripe/balance').then((response) => {
      const { balance } = response.data;

      setCurrentBalance(balance.available[0].amount + balance.pending[0].amount)
      setAvailableBalance(balance.available[0].amount);
      setLoading({
        ...loading,
        balance: false,
      });
    }).catch((error) => {
      console.log(error);
      setLoading({
        ...loading,
        balance: false,
      });
    });
  }

  useEffect(() => {
    fetchStripeBalance();
  }, []);

  const setLoadingProperty = (property, value) => {
    setLoading({
      ...loading,
      [property]: value,
    });
  }

  // eslint-disable-next-line no-unused-vars
  const onPayoutButton = () => {
    setLoadingProperty('payout', true);

    axios({
      method: 'post',
      url: '/api/portal/stripe/payout-connected-accounts',
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);
      setLoadingProperty('payout', false);

      if (response.data.success) {
        addNotice({
          message: 'Successfully scheduled payouts for connected accounts.',
          type: 'success',
        });
      } else {
        addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
      fetchStripeBalance();
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
      addNotice({
        message: error.message,
        type: 'error',
      });
    });
  };

  // eslint-disable-next-line no-unused-vars
  const onTransferButton = async () => {
    setLoadingProperty('transfer', true);
    /*
      example of transfers data
      const transfersData = [
       {
         influencer: "Influencer._id",
         amount: 10000, // in cents
         email: "influencer.email",
         destination: "influencer.payment.stripe.accountId"
       },
      ]
    */

    const result = await axios.get('/api/portal/stripe/connected-accounts');
    const { accounts } = result.data;

    const transfersData = accounts.map(account => ({
      // eslint-disable-next-line
      influencer: account._id,
      amount: 5000, // in cents
      email: account.email,
      destination: account.payment.stripe.accountId,
    }));
    // END

    axios({
      method: 'post',
      url: '/api/portal/stripe/transfer-funds',
      data: { transfersData },
    }).then((response) => {
      if (response.data.success) {
        addNotice({
          message: 'Successfully scheduled transfer of platform funds to connected accounts.',
          type: 'success',
        });
      } else {
        addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
      setLoadingProperty('transfer', false);
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
      addNotice({
        message: error.message,
        type: 'error',
      });
      setLoadingProperty('transfer', false);
    });
  }

  const onAddBalanceButton = () => {
    setLoadingProperty('addBalance', true);

    axios({
      method: 'post',
      url: '/api/portal/stripe/charge-platform-account',
    }).then((response) => {
      if (response.data.success) {
        setLoadingProperty('addBalance', false);

        addNotice({
          message: 'Successfully charged platform balance with test $1000 - 3.2% Stripe fee',
          type: 'success',
        });
        fetchStripeBalance();
      }
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
      setLoadingProperty('addBalance', false);
    });
  }

  return (
    <Row className="align-items-center">
      <Col xs="auto">
        <h3>Balance</h3>
        <Row className="align-items-center">
          <Col xs="auto">
            <h3 className="font-weight-bold">
              {WVFormatter.formatCurrency(currentBalance / 100)}
            </h3>
            <h4 className="il-text-gray">
              {WVFormatter.formatCurrency(availableBalance / 100)} available
            </h4>
          </Col>
          <Col xs="auto">
            {loading.balance && GraySpinner()}
          </Col>
        </Row>
      </Col>
      { window.process.env.NODE_ENV !== 'production' &&
        <Col xs="auto">
          <ButtonToolbar>
            <ButtonWithSpinner
              className="px-5 mb-1 btn-sm"
              type="button"
              outline
              color="success"
              loading={loading.addBalance}
              onClick={onAddBalanceButton}
            >
              Charge Account
            </ButtonWithSpinner>
          </ButtonToolbar>
          <h6 className="text-muted">Charge test Stripe account balance with $1000</h6>
        </Col>
      }
    </Row>
  );
}

StripeBalanceControls.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default StripeBalanceControls;
