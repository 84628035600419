
import React, { useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { uid } from 'react-uid';
import IconButton from '@material-ui/core/IconButton';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import formater from '../../../../shared/helpers/WVFormatter';
import { getMediaChannel } from '../../../../shared/helpers/models/HubilContact';
import socialIcon from '../../../../shared/components/icons/Social';

export default ({ handleClickAction, removeTotal }) => [
  {
    Header: 'Creator',
    accessor: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
    id: 'Creator',
    Cell: p => useMemo(() => (
      <div>
        <Tooltip title="Open Creator Profile">
          <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.influencer?._id}`}>{p.value}</a>
        </Tooltip>
        {p.row.original.deals?.length > 0 ? (
          <Tooltip title="Open Calculation Modal">
            <IconButton
              size="small"
              onClick={event => handleClickAction(p.row.original.influencer?._id, event)}
              className="material-table__toolbar-button"
            >
              <InformationOutlineIcon size="15" />
            </IconButton>
          </Tooltip>) : null}
        {p.row.original.prospect?.tubularDetails?.accounts?.length > 0 ? (
          <div>
            {p.row.original.prospect?.tubularDetails.accounts.map(t => socialIcon(t))}
          </div>
        ) : (
          <div>
            {['youtube', 'facebook', 'instagram', 'twitter', 'tiktok', 'podcast', 'twitch'].map(platform => socialIcon(getMediaChannel(p.row.original.influencer, platform)))}
          </div>
        )}
      </div>
    ), [p.row.original]),
    width: 300,
  },
  {
    Header: 'Gross Revenue',
    accessor: deal => (deal.grossRevenue || 0),
    id: 'revenue',
    Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
    ...!removeTotal && {
      Footer: (info) => {
        const totalAge = useMemo(
          () => info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
          [info.rows],
        );
        return <span>{formater.formatCurrency(totalAge)}</span>;
      },
    },
    width: 120,
  },
  {
    Header: 'Gross Profit',
    accessor: deal => (deal.grossProfit || 0),
    id: 'profit',
    Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
    ...!removeTotal && {
      Footer: (info) => {
        const totalAge = useMemo(
          () => info.rows.reduce((sum, row) => Number(row.values.profit) + sum, 0),
          [info.rows],
        );
        return <span>{formater.formatCurrency(totalAge)}</span>;
      },
    },
    width: 120,
  },
  {
    Header: 'Clicks',
    accessor: p => (p?.clicks || 0),
    id: 'clicks',
    Cell: ({ value }) => useMemo(() => formater.formatIntNumber(value || 0), [value]),
    width: 120,
  },
  {
    Header: 'Revenue Conversions',
    accessor: p => (p.conversions || 0),
    id: 'conversions',
    Cell: ({ value }) => useMemo(() => formater.formatIntNumber(value || 0), [value]),
    width: 120,
    ...!removeTotal && {
      Footer: (info) => {
        const totalAge = useMemo(
          () => info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
          [info.rows],
        );
        return <span>{formater.formatIntNumber(totalAge)}</span>;
      },
    },
  },
  {
    Header: 'Promo Code',
    accessor: p => ((p?.deals.map(d => d.deal?.dealTerms.coupon_code || '')).join('')),
    id: 'Coupon Code',
    Cell: p => useMemo(() => (
      <ul >
        {p.row.original.deals.map(d => d.deal?.dealTerms?.coupon_code && (
          <li key={uid(d.deal._id)}>
            {d.deal?.dealTerms.coupon_code}
          </li>))}
      </ul>), [p.row.original]),
    width: 120,
  },
  {
    Header: 'Content Type',
    accessor: p => ((p?.deals.map(d => d.deal?.dealTerms.content_type || '')).join('')),
    id: 'content_type',
    Cell: p => useMemo(() => (
      <ul >
        {p.row.original.deals.map(d => d.deal?.dealTerms?.content_type && (
          <li key={uid(d.deal._id)}>
            {d.deal?.dealTerms.content_type}
          </li>))}
      </ul>), [p.row.original]),
    width: 120,
  },
  {
    Header: 'Deal Owner',
    accessor: p => ((p.admins.map(d => `${d?.firstName || ''} ${d?.lastName || ''}`)).join('')),
    id: 'owner',
    Cell: p => useMemo(() => (
      <ul >
        {p.row.original.admins.map(d => (
          <li key={uid(d._id)}>
            {d?.firstName || ''} {d?.lastName || ''}
          </li>))}
      </ul>), [p.row.original]),
    width: 200,
  },
  {
    Header: 'Account Manager',
    accessor: p => ((p.adminsAF.map(d => `${d?.firstName || ''} ${d?.lastName || ''}`)).join('')),
    id: 'af',
    Cell: p => useMemo(() => (
      <ul >
        {p.row.original.adminsAF.map(d => (
          <li key={uid(d._id)}>
            {d?.firstName || ''} {d?.lastName || ''}
          </li>))}
      </ul>), [p.row.original]),
    width: 200,
  },
];
