import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import withNotice from '../../../../../../App/store/with-notice';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import RadioButton from '../../../../../../../shared/components/RadioButton';

const notAssigned = {
  value: null,
  label: '-- Not Assigned --',
  record: { params: { _id: null, firstname: '-- Not Assigned --', lastname: '' } },
};

const AssignDealOwnerModal = ({
  isOpen,
  closeFunc,
  setVersion,
  prospectIds,
  adminUsers,
  total,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(notAssigned);
  const [type, setType] = useState('select');
  const [includeAll, setIncludeAll] = useState(false);

  const handleChangeOwner = async (selectedItem) => {
    setSelectedOwner(selectedItem);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedOwner(notAssigned);
      setIncludeAll(false);
      setType('select');
    }
    // console.log(total);
  }, [isOpen]);

  // SUBMIT Data
  const submit = async () => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search);
    console.log('Submit');
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-update-deal-owner',
      data: {
        prospectIds,
        type,
        adminUserId: selectedOwner.value,
        includeAll,
        query: query.toString(),
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        closeFunc();
      }
    });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '500px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Assign Deal Owner</ModalHeader>
      <ModalBody>
        {prospectIds.length !== total && (
          <>
            <div>{`You have selected ${prospectIds.length} Lead${prospectIds.length !== 1 ? 's' : ''} to assign Deal Owner`}</div>
            <div className="theme-light radio-in-prospects">
              {`Would you like to select all ${WVFormatter.formatIntNumber(total)} Leads?`}<br />
              <RadioButton
                name="action"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                name="action"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>
          </>
        )}
        <div className="theme-light radio-in-prospects mt-3">
          <div>
            <RadioButton
              name="type"
              label="&nbsp;Set to the Default Deal Owner"
              value={type === 'default' ? 'default' : '-'}
              radioValue="default"
              onChange={setType}
              boldLabel
            />
            <p
              className="help-block il-text-gray ml-4"
              style={{ marginTop: '-8px' }}
            >
              Executes our default Deal Owner assignment algorithm
            </p>
          </div>
          <div
            className="mt-2"
            style={{ zIndex: '999' }}
          >
            <RadioButton
              name="type"
              label="&nbsp;Select a Deal Owner"
              value={type === 'select' ? 'select' : '-'}
              radioValue="select"
              onChange={setType}
              boldLabel
            />
            <Select
              className="ml-4"
              disabled={type !== 'select'}
              value={selectedOwner}
              options={adminUsers}
              onChange={handleChangeOwner}
              placeholder=""
              isDisabled={!!loading}
              menuPlacement="auto"
            />
          </div>
          <div
            className="mt-2"
            style={{ marginLeft: '-10px' }}
          >
            <RadioButton
              name="type"
              label="&nbsp;Set to the currently assigned Contact Owner"
              value={type === 'contactOwner' ? 'contactOwner' : '-'}
              radioValue="contactOwner"
              onChange={setType}
              boldLabel
            />
          </div>
        </div>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="danger" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

AssignDealOwnerModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  prospectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default withNotice(AssignDealOwnerModal);
