import React, { useEffect, useState, useMemo } from 'react';
import { axios } from 'ApiClient';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import PropTypes from 'prop-types';
import Select from 'react-select';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { fetchDictionary } from '../../../../../shared/helpers/WVRequest';

const Filter = ({
  selectedAdvertiser, setSelectedAdvertiser, selectedOffer, setSelectedOffer, selectedDealType, setSelectedDealType, onSearchChange,
  setSelectedCategories, selectedCategories,
}) => {
  const [advertisers, setAdvertisers] = useState([]);
  const [offers, setOffers] = useState([]);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState([]);
  const [search, setSearch] = useState();
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);
  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
  };

  const fetchDictionaryData = () => {
    fetchDictionary('PROSPECT_').then((data) => {
      const { success, records } = data;
      if (success === true) {
        const _categories = records.PROSPECT_CATEGORIES ? JSON.parse(records.PROSPECT_CATEGORIES) : [];
        const _categorySuggestions = _categories.map(row => ({ value: row, label: row }));
        _categorySuggestions.unshift({ value: 'null', label: '-- Not Assigned --' });
        setCategories(_categorySuggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };
  useEffect(() => {
    setLoadingAdvertiser(true);
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      // setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : -1)));
      setOffers(resp.data.offers);
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      /* addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      }); */
    });
    fetchDictionaryData();
  }, []);
  const advertiserOptions = useMemo(() => advertisers.map(a => ({ value: a._id, label: a.companyName })), [advertisers]);
  const offerOptions = useMemo(() => offers.filter(o => !selectedAdvertiser || selectedAdvertiser?.map(a => a.value).includes(o.advertiser._id)).map(a => ({ value: a._id, label: a.name })), [offers, selectedAdvertiser]);
  return (
    <div className="mt-3">
      <Row>
        <Col sm={10}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Creator Name"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row className="mt-3" >
            <Col>
              <Select
                isDisabled={loadingAdvertiser}
                value={selectedAdvertiser}
                options={advertiserOptions}
                onChange={setSelectedAdvertiser}
                placeholder="Select Brand..."
                isMulti
              />
            </Col>
            <Col>
              <Select
                value={selectedOffer}
                options={offerOptions}
                onChange={setSelectedOffer}
                placeholder="Select Offer..."
                isMulti
              />
            </Col>
            <Col>
              <Select
                value={selectedDealType}
                options={[
                  { value: 'Hybrid', label: 'Hybrid' },
                  { value: 'CPA/Ambassador', label: 'CPA/Ambassador' },
                  { value: 'Upfront Only', label: 'Upfront Only' },
                  { value: 'Upfront CPM', label: 'Upfront CPM' },
                  { value: 'Bonus', label: 'Bonus' },
                ]}
                onChange={setSelectedDealType}
                placeholder="Deal Type..."
                isClearable
                isMulti
              />
            </Col>
            <Col>
              <Select
                value={selectedCategories}
                options={categories}
                onChange={setSelectedCategories}
                placeholder="Category..."
                isClearable
                isMulti
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
Filter.propTypes = {
  setSelectedAdvertiser: PropTypes.func.isRequired,
  selectedAdvertiser: PropTypes.arrayOf(PropTypes.any),
  setSelectedOffer: PropTypes.func.isRequired,
  selectedOffer: PropTypes.arrayOf(PropTypes.any),
  setSelectedDealType: PropTypes.func.isRequired,
  selectedDealType: PropTypes.arrayOf(PropTypes.any),
  onSearchChange: PropTypes.func.isRequired,
  setSelectedCategories: PropTypes.func.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.any),
};
Filter.defaultProps = {
  selectedAdvertiser: [],
  selectedOffer: [],
  selectedDealType: [],
  selectedCategories: [],
};
export default Filter;
