import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import UserPropertyType from './components/custom';
import Actions from './components/custom/Actions';
import { parseUrl } from '../../../../shared/helpers/WVUtilities';

const ResourceAction = (props) => {
  const {
    resources,
    history,
  } = props;
  

  const filters = parseUrl();
  const [searchValue, onSearchChange] = useState(filters.filename || '');
  const [version, setVersion] = useState((new Date()).toString());

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.filename', searchValue);
    } else {
      search.delete('filters.filename');
    }
    return search;
  };

  const firstUpdate = useRef(true);
  const setUrl = () => {
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
    }
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  useEffect(() => {
    setUrl();
  }, [
    searchValue,
  ]);

  useEffect(() => {
    setVersion((new Date()).toString());
  }, [document.location]);
  const resource = resources.find(r => r.id === 'CpmConversionUpload');
  if (!resource) {
    return (<NoResourceError resourceId="CpmConversionUpload" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="CpmConversionUpload" actionName="list" />);
  }
  return (
    <Container className="dashboard">
      <Breadcrumbs
        links={[
          { title: 'Home', path: '/' },
          { title: 'Admin', path: false },
          { title: 'HasOffers', path: false },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row>
              <Col>
                <h3>CPM Conversions Uploads</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-0" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                onSearchChange={onSearchChange}
                searchValue={searchValue}
              />
              <div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
                <MatTableList
                  action={action}
                  resource={resource}
                  sortBy="uploadDatetime"
                  direction="desc"
                  date={new Date()}
                  UserPropertyType={UserPropertyType}
                  showTitle={false}
                  version={version}
                  v={2}
                  columnsSettings={{
                    uploadOwner: {
                      width: 60,
                    },
                    uploadDatetime: {
                      width: 50,
                    },
                    uploadType: {
                      width: 50,
                    },
                    brand: {
                      width: 35,
                    },
                    totalRows: {
                      width: 45,
                    },
                    totalSuccess: {
                      width: 40,
                    },
                    totalErrors: {
                      width: 30,
                    },
                  }}
                  noActions
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

ResourceAction.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
