import React, { useState } from 'react';
import { Modal, ButtonToolbar, Button, Label, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import ProgressBar from '../../../../../shared/helpers/ProgressBar';

const useStyles = makeStyles({
  progressBar: {
    paddingTop: '20px',
  },
});

const mapStatusArray = { // move toconstants
  pending: 'Pending',
  approved: 'Approved',
};

const CalculateModal = ({
  modal,
  closeDialog,
  title,
  recalculate,
}) => {
  const classes = useStyles();
  const [isCalculating, setIsCalculating] = useState(false);
  const [status, setStatus] = useState({ value: 'pending', label: mapStatusArray.pending });
  const mapStatus = Object.keys(mapStatusArray).map(key => ({ value: key, label: mapStatusArray[key] }));

  const startCalculation = () => {
    recalculate(status.value);
    setIsCalculating(true);
  };

  const afterCalculationEnded = () => {
    setIsCalculating(false);
    closeDialog();
  };

  return (
    <Modal
      isOpen={modal}
      toggle={closeDialog}
    >
      <ModalHeader toggle={closeDialog} tag="h4">
        {title}
      </ModalHeader>
      <ModalBody
        className="mt-1 mb-1 finance-form"
      >

        <Label>Select the Status for records to recalculate the payout and agent payauts</Label>
        <Select
          value={status}
          options={mapStatus}
          onChange={statusSelected => setStatus(statusSelected)}
          placeholder="Select Status To Change"
        />
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button
          className="modal_cancel btn-sm mr-2"
          color="primary"
          disabled={isCalculating}
          onClick={startCalculation}
        >
          Recalculate
        </Button>
        <Button
          className="modal_cancel btn-sm"
          color="secondary"
          onClick={closeDialog}
        >
          Close
        </Button>
      </ButtonToolbar>

      <div className={classes.progressBar}>
        <ProgressBar
          afterFunction={afterCalculationEnded}
          topic="reCalculateAllPayable"
        />
      </div>
    </Modal>
  );
};
CalculateModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  recalculate: PropTypes.func.isRequired,

};
export default CalculateModal;
