/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../../../shared/components/ButtonWithSpinner';

const DeleteCpmCreatorModal = ({
  isOpen,
  closeFunc,
  loading,
  deleteCreator,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={closeFunc}
    className="delete-modal"
    size="sm"
  >
    <ModalHeader toggle={closeFunc} >
      Delete CPM Deal
    </ModalHeader>
    <ModalBody>
      Are you sure you want to delete the selected CPM Deal?
    </ModalBody>
    <ModalFooter>
      <ButtonWithSpinner
        className="btn-sm"
        type="button"
        color="danger"
        onClick={deleteCreator}
        loading={loading}
        disabled={loading}
      >
        <span>Delete</span>
      </ButtonWithSpinner>{' '}
      <Button
        className="btn-sm"
        color="secondary"
        onClick={closeFunc}
        disabled={loading}
      >
        Cancel
      </Button>
    </ModalFooter>
  </Modal>
);

DeleteCpmCreatorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  deleteCreator: PropTypes.func.isRequired,
};

DeleteCpmCreatorModal.defaultProps = {

};

export default DeleteCpmCreatorModal;
