/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React from 'react';
import { Modal, ButtonToolbar, Button, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { currentTimezoneAggr } from '../../../../../../shared/helpers/Dates';

const ChangeHistoryModal = ({
  modal,
  setModalVisible,
  record,
}) => (
  <Modal
    isOpen={modal}
    toggle={() => setModalVisible(false)}
  >
    <ModalHeader
      toggle={() => { setModalVisible(false); }}
      tag="h3"
      className="modal-title-statistics"
    >
      Status Change History
    </ModalHeader>
    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      {record.params?.history?.status?.length > 0 ? (
        <div className="mt-1 mb-4">
          <div className="mt-3" key={record.params.status}>
            <strong>InfluenceLogic Status History</strong>
            <hr className="mt-2 mb-2" />
            {record.params.history.status.map((item) => {
              const previousName = item.prevStatus || null;
              const currentName = item.newStatus || null;
              return (
                <div
                  style={{ paddingLeft: '15px' }}
                  key={moment(item.dateModified).toString() + moment.now().toString()}
                >
                  <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                  <div className="pl-3">
                    <a href={item.adminUser ? `/resources/AdminUser/records/${item.adminUser}/show` : '#'} target="_blank" rel="noreferrer">
                      {item.adminUserName}
                    </a> - <b>{previousName}</b> changed to <b>{currentName}</b>
                  </div>
                </div>);
            })}
          </div>
        </div>
      ) : null}
      {record.params?.history?.brandStatus?.length > 0 ? (
        <div className="mt-1 mb-4">
          <div className="mt-3" key={record.params.brandStatus}>
            <strong>Brand Status History</strong>
            <hr className="mt-2 mb-2" />
            {record.params.history.brandStatus.map((item) => {
              const previousName = item.prevStatus || null;
              const currentName = item.newStatus || null;
              const user = item.adminUser || item.brandUser;
              return (
                <div
                  style={{ paddingLeft: '15px' }}
                  key={moment(item.dateModified).toString() + moment.now().toString()}
                >
                  <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                  <div className="pl-3">
                    <a href={user ? `/resources/${item.adminUser ? 'AdminUser' : 'BrandUser'}/records/${user}/show` : '#'} target="_blank" rel="noreferrer">
                      {item.adminUserName}
                    </a> - <b>{previousName}</b> changed to <b>{currentName}</b>
                  </div>
                </div>);
            })}
          </div>
        </div>
      ) : null}
    </div>
    <ButtonToolbar className="modal__footer">
      <Button className="modal_cancel btn-sm" color="secondary" onClick={() => setModalVisible(false)}>Close</Button>
    </ButtonToolbar>
  </Modal>
);

ChangeHistoryModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ChangeHistoryModal;
