import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
  Badge,
} from 'reactstrap';
import { unflatten } from 'flat';
import { connect } from 'react-redux';
import { axios } from 'ApiClient';
// import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// import { axios } from 'ApiClient';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../../App/store/with-notice';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';
import DeleteCpmCreatorFromOrder from './modals/DeleteCpmCreatorFromOrder';
import RefreshStatisticsOptionsModal from '../../CpmProspects/components/modals/RefreshStatisticsOptionsModal';
import PublishLookerProspect from '../../CpmProspects/components/modals/PublishLookerProspect';
import UploadInsertionOrderModal from '../../CpmInsertionOrder/components/custom/Modal/UploadInsertionOrderModal';
import PublishInsertionOrderChangeLogHistoryModal from '../../CpmInsertionOrder/components/custom/Modal/PublishInsertionOrderChangeLogHistoryModal';
import FetchContentReleaseDate from './modals/FetchContentReleaseDate';
import AddCreatorsModal from './modals/AddCreatorsModal';
import PublishInsertinOrder from './modals/PublishInsertinOrder';
import PublishLookerInsertinOrder from './modals/PublishLookerInsertinOrder';
// import SuccessModal from '../../../../../../shared/components/modals/SuccessModal';
import { showModalById } from '../../../../../../redux/reducers/TableReducer';
import { ErrorMessageBoxAlert } from '../../../../../../shared/components/components/ui/error404';

const CPMProspectsListContainer = ({
  addNotice,
  dispatch,
  history,
  action,
  resource,
  insertionOrderId,
}) => {
  const filters = parseUrl();
  const [version, setVersion] = useState((new Date()).toString());
  // const [approvalStatusFilters, setApprovalStatusFilters] = useState(getApprovalStatusInitial());
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [insertionOrder, setInsertionOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [startLoadCreators, setStartLoadCreators] = useState(false);
  const [possibleCreators, setPossibleCreators] = useState([]);
  const [error, setError] = useState(null);
  const recordsRef = useRef([]);
  const [dealStageFilters, setDealStageFilters] = useState([]);
  const [dealstagesOptions, setDealstagesOptions] = useState([]);
  const [mainPipelineStages, setMainPipelineStages] = useState({});

  const getInsertionOrder = async () => {
    try {
      setError(null);
      setLoading(true);
      const data = await axios.get(`/api/portal/prospects/get-cpm-insertion-order?id=${insertionOrderId}`);
      setLoading(false);
      console.log(data.data);
      if (data.data.success) {
        setInsertionOrder(unflatten(data.data.record));
      } else {
        setError(data.data.error);
      }
    } catch (e) {
      console.log(e);
      addNotice({
        message: e.message || 'Unable to get Insertion Order',
        type: 'error',
      });
      setLoading(false);
    }
  };

  const fetchPossibleCreators = () => {
    // console.log('Fetch creators');
    // console.log(insertionOrder);
    axios.get(`/api/portal/prospects/get-free-cpm-creators-for-insertion-orders?brand=${insertionOrder.params.brand}`).then((response) => {
      if (response.data.success) {
        setPossibleCreators(response.data.cpmCreators);
      }
    });
    setStartLoadCreators(false);
  };

  useEffect(() => {
    // get main pipeline deals
    const allDealPipeLines = getConstant('deal', 'pipelines', []);
    const mainPipeline = allDealPipeLines.find(a => a.label === 'Main Pipeline');
    setMainPipelineStages(mainPipeline.stages);
  }, []);

  useEffect(() => {
    if (Object.keys(mainPipelineStages).length) {
      const options = [];
      Object.keys(mainPipelineStages).forEach(key => options.push({ value: key, label: mainPipelineStages[key] }));
      setDealstagesOptions(options);
    }
  }, [mainPipelineStages]);

  useEffect(() => {
    if (startLoadCreators) {
      fetchPossibleCreators();
    }
  }, [startLoadCreators]);

  useEffect(() => getInsertionOrder(), [insertionOrderId]);
  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }

    if (dealStageFilters && dealStageFilters.length > 0) {
      search.delete('filters.dealStages');
      for (let i = 0; i < dealStageFilters.length; i += 1) {
        search.append('filters.dealStages', dealStageFilters[i].value);
      }
    } else {
      search.delete('filters.dealStages');
    }

    return search;
  };
  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const firstUpdate = useRef(true);
  const setUrl = () => {
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
    }
    // search.set('filters.allRequiredDataIsSet', 'true');
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  // const setUrlDebounced = useAsyncDebounce(() => {
  //   setUrl();
  // }, 800);

  const downloadCsv = () => {
    const search = getUrlSearch();
    search.append('filters.cpmInsertionOrder', insertionOrderId);
    goToPage(`/api/portal/prospects/get-insertion-order-prospect-csv?${search.toString()}`);
  };

  useEffect(() => {
    setUrl();
    // setUrlDebounced();
  }, [
    searchValue,
    dealStageFilters,
  ]);
  if (error) {
    return (<ErrorMessageBoxAlert title="Insertion Order" actionName="list" >{error}</ErrorMessageBoxAlert>);
  }

  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Insertion Orders', path: '/research/cpm-insertion-order' },
          { title: `Insertion Order${insertionOrder && insertionOrder.params ? ` - ${insertionOrder.params.name}` : ''}`, path: null },
        ]}
        isBackButton
        backButtonUrl="/research/cpm-insertion-order"
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3 className="d-inline-block pr-3">Insertion Order {insertionOrder && insertionOrder.params ? ` - ${insertionOrder.params.name}` : ''}</h3>
                {insertionOrder && insertionOrder.params ? <Badge color="success" style={{ fontSize: '20px' }}>{insertionOrder?.params?.status?.status}</Badge> : null }
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                loading={loading}
                onSearchChange={onSearchChange}
                getUrlSearch={getUrlSearch}
                searchValue={searchValue}
                updateTable={() => setVersion((new Date()).toString())}
                downloadCsv={downloadCsv}
                insertionOrder={insertionOrder}
                getInsertionOrder={getInsertionOrder}
                setStartLoadCreators={setStartLoadCreators}
                dealStageFilters={dealStageFilters}
                setDealStageFilters={setDealStageFilters}
                dealstagesOptions={dealstagesOptions}
              />
              <Row style={{ marginTop: '-10px' }}>
                <Table
                  action={action}
                  resource={resource}
                  // dispatch={dispatch}
                  // addNotice={addNotice}
                  version={version}
                  // setVersion={setVersion}
                  recordsRef={recordsRef}
                  insertionOrderId={insertionOrderId}
                />
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <DeleteCpmCreatorFromOrder
        updateTable={() => setVersion((new Date()).toString())}
      />
      <RefreshStatisticsOptionsModal
        updateTable={() => setVersion((new Date()).toString())}
      />
      <PublishLookerProspect />
      <FetchContentReleaseDate
        updateTable={() => setVersion((new Date()).toString())}
      />
      <UploadInsertionOrderModal updateData={() => { getInsertionOrder(); }} setVersion={setVersion} />
      <PublishInsertionOrderChangeLogHistoryModal />
      <PublishInsertinOrder
        updateTable={(file) => {
          getInsertionOrder();
          dispatch(showModalById('SuccessModal', {
            title: 'Insertion Order Published Successfully',
            message: (
              <span>Insertion Order <strong>{insertionOrder.params?.name || ''}</strong> was successfully published to google sheet file <strong>{file.name || ''}</strong> <br />
                <a href={file.webViewLink} target="_blank" rel="noreferrer" className="py-2 d-inline-block">View Insertion Order</a>
              </span>),
          }));
        }}
      />
      <PublishLookerInsertinOrder
        updateTable={(file) => {
          getInsertionOrder();
          dispatch(showModalById('SuccessModal', {
            title: 'Looker Update Data Published Successfully',
            message: (
              <span>Insertion Order <strong>{insertionOrder.params?.name || ''}</strong> was successfully published to google sheet file <br />
                <a href={file.spreadsheetUrl} target="_blank" rel="noreferrer" className="py-2 d-inline-block">Looker Update Sheet</a>
              </span>),
          }));
        }}
      />
      <AddCreatorsModal possibleCreators={possibleCreators} setVersion={setVersion} />
    </Container>
  );
};

CPMProspectsListContainer.propTypes = {
  addNotice: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  insertionOrderId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withNotice(connect()(withRouter(CPMProspectsListContainer)));
