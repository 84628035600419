import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';

const ScoreActionInList = ({ record }) => {
  const recortUnflatten = unflatten(record);
  const { dealType, special } = recortUnflatten.params;
  return (
    <>
      <span style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '60px',
      }}
      >
        {special === 'Oscar Worthy' && (
          <img src="/images/mediacontent/isOscar.png" style={{ width: '25px', marginRight: '5px' }} className="img-fluid" alt="Oscar Worthy" />
        )}
        {dealType === 'Upfront CPM' && (
          <img src="/images/mediacontent/dealUpfrontCPM.png" style={{ width: '25px', marginRight: '5px' }} className="img-fluid" alt="Upfront CPM Deal" />
        )}
        {/* <a title="Score Content" target="_blank" rel="noreferrer" href={`/scorecard/score-content/${_id}`}><i className="fa fa-tachometer-alt" style={{ fontSize: '20px' }} /></a> */}
        &nbsp;
      </span>
    </>
  );
};
ScoreActionInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default ScoreActionInList;

