import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import CalendarIcon from 'mdi-react/CalendarIcon';

const DatePickerCustomInputForwarded = forwardRef(({ value, onClick, onChange }, ref) => (
  <div className="form-group mb-2">
    <InputGroup size="md">
      <input
        type="text"
        className="form-control form-control-sm"
        value={value}
        onClick={onClick}
        onChange={onChange}
        style={{
            border: '1px solid #ced4da',
            borderRadius: '5px',
          }}
        ref={ref}
      />
      <InputGroupAddon
        addonType="append"
        style={{
            padding: '3px 0 3px 3px',
          }}
      >
        <CalendarIcon
          className="date-picker-icon"
          onClick={onClick}
        />
      </InputGroupAddon>
    </InputGroup>
  </div>
));
DatePickerCustomInputForwarded.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
export default DatePickerCustomInputForwarded;
