import React, { useMemo } from 'react';
import { Row, Col, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';

const GenerateTable = ({
  reportData,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: row => row.status || '',
        id: 'status',
        Cell: ({ value }) => value || '',
        width: 300,
      },
      {
        Header: 'Selected Timeframe',
        accessor: row => row.selectedTimeframe || 0,
        id: 'selectedTimeframe',
        Cell: ({ value }) => (value ? `${value} hrs` : '-'),
        width: 150,
      },
      {
        Header: 'Last 7 Days',
        accessor: row => row.last7Days || 0,
        id: 'last7Days',
        Cell: ({ value }) => (value ? `${value} hrs` : '-'),
        width: 150,
      },
      {
        Header: 'Last 30 Days',
        accessor: row => row.last30Days || 0,
        id: 'last30Days',
        Cell: ({ value }) => (value ? `${value} hrs` : '-'),
        width: 150,
      },
      {
        Header: 'Last 90 Days',
        accessor: row => row.last90Days || 0,
        id: 'last90Days',
        Cell: ({ value }) => (value ? `${value} hrs` : '-'),
        width: 150,
      },
    ],
    [reportData],
  );

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  return (
    <div>
      <Row key="3">
        <Col className="mt-3">
          <Label className="bold-text">Content Status Average Times</Label>
          <ReactTableBase
            key="searchable"
            columns={columns}
            data={reportData}
            tableConfig={tableConfig}
          />
        </Col>
      </Row>
    </div>
  );
};

GenerateTable.propTypes = {
  reportData: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default GenerateTable;
