const moment = require('moment');

exports.toWeek = (date, last) => {
  const m = moment(date);
  let dow = moment(last).day();
  if (dow < m.day()) dow += 7;
  return m.isoWeekday(dow);
};

exports.toMonth = (date, last) => {
  const m = moment(date);
  const d = m.date();
  const day = moment(last).date();
  if (d > day) m.set('month', m.month() + 1);
  return m.set('date', day);
};

exports.xLabel = (value, dataView) => {
  if (!value || value === 'auto') return '';
  const format = (dataView === 'month') ? 'MMM YYYY' : 'MMM D';
  return moment(value).format(format);
};

exports.randomColor = () => `#${Math.random().toString(16).substr(-6)}`;
