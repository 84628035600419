/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../../../shared/components/ButtonWithSpinner';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../../../App/store/with-notice';

export const modalId = 'publishLookerProspect';


const DeleteCpmCreatorModal = ({
  // updateTable,
  addNotice,
}) => {
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [stats, setStats] = useState(null);
  const [results, setResults] = useState(null);
  const [file, setFile] = useState(null);
  const modalInfo = useSelector(state => state.table.modals[modalId]);

  console.log(modalInfo);
  const dispatch = useDispatch();
  if (!modalInfo) return false;
  const { data: { record }, isShow } = modalInfo;
  const close = () => {
    setStats(null);
    setResults(null);
    setFile(null);
    dispatch(hideModalById(modalId));
  };
  const publishInsertionOrder = async () => {
    setStats(null);
    setLoadingPublish(true);
    try {
      const res = await axios({
        method: 'get',
        url: `/api/portal/prospects/get-create-looker-by-prospect?id=${record?.id}`,
      });
      setLoadingPublish(false);
      if (res?.data?.success) {
        const csvDownload = res?.data?.results;
        setStats(res?.data?.stats);
        setResults(csvDownload[csvDownload.length - 1]);
        
        setFile(res?.data?.sheetFile);
        // updateTable(res?.data?.sheetFile, res?.data?.sheetFile);
      } else if (res?.data?.error) {
        addNotice({
          message: `Looker Update Data failed to publish: ${res.data.error}`,
          type: 'error',
        });
      }
      // close();
    } catch (error) {
      console.error(error);
      addNotice({
        message: `Looker Update Data failed to publish: ${error.message}`,
        type: 'error',
      });
      setLoadingPublish(false);
    }
  };
  if (!modalInfo) return false;
  const name = record?.params?.contactName || '';
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Publish Prospect
      </ModalHeader>
      <ModalBody>
        {!stats && (<p>You are about to Publish Looker Update Data from <strong>{ name.trim() }</strong> to Google Sheet File <strong>Looker Update Sheet</strong></p>)}
        {results ? (<div>
          <h5>Prospect was processed:</h5>
          <div className="mt-2">No. rows added: {stats.added || 0}</div>
          <div>No. rows updated: {stats.updated || 0}</div>
          <div>Errors: {stats.errors || 0}</div>
          <div>Total rows: {(stats.added || 0) + (stats.updated || 0) + (stats.errors || 0)}</div>
        </div>) : null}
        {file ? <a href={file.spreadsheetUrl} target="_blank" rel="noreferrer" className="py-2 d-inline-block">Looker Update Sheet</a> : null}
      </ModalBody>
      <ModalFooter>
        <ButtonWithSpinner
          className="btn-sm"
          type="button"
          color="primary"
          onClick={publishInsertionOrder}
          loading={loadingPublish}
          disabled={loadingPublish || stats}
        >
          <span>Publish</span>
        </ButtonWithSpinner> :
        <Button
          className="btn-sm"
          color="secondary"
          onClick={close}
          disabled={loadingPublish}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DeleteCpmCreatorModal.propTypes = {
  // updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

DeleteCpmCreatorModal.defaultProps = {

};

export default withNotice(DeleteCpmCreatorModal);
