const moment = require('moment-timezone');


const getDefaultTargetCac = (brand, type, { settings }) => {
  // console.log(settings, [brand?._id, type]);
  if (!settings) return [0, 0, 0];
  let targetCac = 450;
  let targetP = settings.types ? (settings.types[type] / 100) || 0.25 : 0.25;
  if (brand && settings.brands[brand._id] && settings.brands[brand._id][type]) {
    targetCac = settings.brands[brand._id][type].cac;
    targetP = settings.brands[brand._id][type].percent / 100;
  }
  return [targetCac * (1 + targetP), targetCac, targetP];
};
const calculatePayout = ({ CPApaidConversionsArray, dealForCac }) => {
  if (dealForCac?.dealTerms?.type_of_deal === 'Upfront CPM') {
    return Number(dealForCac?.dealTerms?.bh_cpm_total_cost || 0);
  }
  // eslint-disable-next-line no-param-reassign
  return Number(dealForCac?.dealTerms?.guaranteed_payment_contract_amount || 0) + CPApaidConversionsArray.reduce((p, c) => { p += Number(c.payout || 0); return p; }, 0);
};
const calculateConversionsCount = ({
  CPApaidConversionsArray,
  dealForCac,
  CPMpaidConversions,
  CPMpaidConversionsArray,
  publishDate,
  atDay,
}) => {
  if (dealForCac?.dealTerms?.type_of_deal === 'Upfront CPM') {
    if (atDay && CPMpaidConversionsArray) {
      const momentPublishDate = moment.tz(moment.utc(publishDate).format('YYYY-MM-DD'), 'America/New_York');
      const atDayConversions = CPMpaidConversionsArray.filter(row => row.activityDatetime && (moment.tz(moment.utc(row.activityDatetime).format('YYYY-MM-DD'), 'America/New_York').diff(momentPublishDate, 'days') <= atDay));
      return atDayConversions.length;
    }
    return Number(CPMpaidConversions || 0);
  }
  return CPApaidConversionsArray?.length || 0;
};
const calculateDays = ({ mediaPublishedDate }) => (mediaPublishedDate ? moment.tz('America/New_York').diff(moment.tz(moment.utc(mediaPublishedDate).format('YYYY-MM-DD'), 'America/New_York'), 'days') : 0) + 1;
const getProjectedPercent = (daysCount, { projectedSettings }) => {
  const usedSettings = [];
  const daysCountArray = Object.keys(projectedSettings).map(a => Number(a)).sort((a, b) => a - b);
  // console.log('projectedSettings: ', projectedSettings);
  let sumPercentUsed = 0;
  let remainingPercent = 0;
  daysCountArray.forEach((k) => {
    const item = projectedSettings[k];
    // console.log(item);
    if (Number(k) <= Number(daysCount)) {
      usedSettings.push(item);
      sumPercentUsed += Number(item.value || 0);
    }
    if (Number(item.start) <= Number(daysCount) && Number(item.end) > Number(daysCount)) {
      // console.log((Number(daysCount) - (Number(item.start) - 1)), ((Number(item.end) - Number(item.start)) + 1));
      // console.log((Number(daysCount) - (Number(item.start) - 1)) / ((Number(item.end) - Number(item.start)) + 1), Number(item.value));
      remainingPercent = ((Number(daysCount) - (Number(item.start) - 1)) / ((Number(item.end) - Number(item.start)) + 1)) * Number(item.value);
    }
  });
  // console.log(daysCount, [sumPercentUsed, remainingPercent]);
  return Number(sumPercentUsed) + Number(remainingPercent);
};

exports.calculatePar = (row, settings) => {
  if (!(row && row?.offer && row?.revenue)) return null;
  const {
    latestDeal,
    offer,
    CPApaidConversionsArray,
    CPMpaidConversions,
    publishDate,
    revenue,
  } = row;
  const { advertiser } = offer;
  if (!advertiser || !latestDeal || !publishDate || latestDeal?.dealTerms?.type_of_deal !== 'Upfront CPM') return null;
  const CPMpaidConversionsCount = Number(CPMpaidConversions?.length || 0);
  const conversionsCount = calculateConversionsCount({ CPApaidConversionsArray, dealForCac: latestDeal, CPMpaidConversions: CPMpaidConversionsCount });
  if (!conversionsCount) return null;
  const payout = calculatePayout({ CPApaidConversionsArray, dealForCac: latestDeal });
  const [defaultTargetCac, targetCac] = getDefaultTargetCac(advertiser, latestDeal?.dealTerms?.type_of_deal, settings);
  const days = calculateDays({ mediaPublishedDate: publishDate });
  const cac = (conversionsCount ? (payout / conversionsCount) : null);
  if (cac !== null && cac > 0 && defaultTargetCac >= cac) return null;

  const projectedPercent = days > 0 ? getProjectedPercent(days, settings) : 0;
  if (!projectedPercent) return null;
  const projectedConversions = Number(conversionsCount / projectedPercent) * 100;
  let projectedCac = projectedConversions ? payout / projectedConversions : null; // need payout
  if (projectedCac !== null && projectedCac > 0 && defaultTargetCac >= projectedCac) return null;

  if (days > 360) {
    const conversionsCountAtDay360 = calculateConversionsCount({
      CPApaidConversionsArray,
      dealForCac: latestDeal,
      CPMpaidConversionsArray: CPMpaidConversions,
      publishDate,
      atDay: 360,
    });
    if (!conversionsCountAtDay360) return null;
    projectedCac = revenue / conversionsCountAtDay360;
  }
  return (targetCac / projectedCac) * revenue;
};

exports.calculateConversionsCount = calculateConversionsCount;
exports.calculatePayout = calculatePayout;
exports.getDefaultTargetCac = getDefaultTargetCac;
exports.calculateDays = calculateDays;
exports.getProjectedPercent = getProjectedPercent;
