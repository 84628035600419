import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Container, Card, CardBody, CardTitle } from 'reactstrap';

import Columns from './Columns';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import { getCache } from '../../../../shared/helpers/LocalStorageCache';
import DownloadCSV from './DownloadCSV';
import PermissionComponent from '../../../../shared/components/PermissionComponent';
import ChartComponent from './ChartComponent';
import Total from './Total';
import FilterForTable from './Filter';
import Modal from '../EOMReport2/Modal';
import {
  groupByDate,
} from '../ReportGroupBy';


const SummaryReport = ({ match }) => {
  const [items, setItems] = useState([]);
  const [dealsArray, setDeals] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [stats, setStats] = useState({
    grossRevenue: 0, grossProfit: 0, clicks: 0, conversions: 0, count: 0,
  });

  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [selectedDealType, setSelectedDealType] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  const { influencerId, startDate, endDate } = match.params;
  const creator = useMemo(() => getCache(`${influencerId}-cachedreport-${startDate}${endDate}`), [influencerId, startDate, endDate]);

  const processDeals = (dealsToFetch) => {
    const rightDeals = [];
    const selectedEmployeeArray = selectedEmployee?.map(a => a.value) || null;
    const ids = selectedDealType?.map(a => a.value);
    const idsA = selectedAdvertisers?.map(a => a.value);
    const idsO = selectedOffer?.map(a => a.value);
    let grossRevenue = 0;
    let grossProfit = 0;
    console.log(dealsToFetch);
    const rightDealsToChart = {};
    dealsToFetch.forEach((deal) => {
      if (!deal?.influencer?.affiliateDataObject?.tune_id) return false;
      if (selectedDealType?.length > 0 && !ids.includes(deal?.deal?.dealTerms?.type_of_deal)) {
        return false;
      }
      if (selectedAdvertisers?.length > 0 && !idsA.includes(deal?.offer?.advertiser._id)) {
        return false;
      }
      if (selectedOffer?.length > 0 && !idsO.includes(deal?.offer?._id)) {
        return false;
      }
      if (selectedEmployeeArray &&
        selectedEmployeeArray.length > 0 &&
        !selectedEmployeeArray.includes(deal?.admin?._id)
      ) return false;
      rightDeals.push(deal);
      rightDealsToChart[deal.deal._id] = { deal, items: [deal] };
      grossRevenue += deal?.totalRevenue || 0;
      grossProfit += deal?.grossProfit || 0;
      return true;
    });
    setItems(rightDeals);
    console.log(rightDealsToChart, 'chart');
    const itemsWithDeal = Object.values(rightDealsToChart).map(({ deal, items: itemsSelected }) => {
      const datesFilters = groupByDate(itemsSelected);
      return { option: { _id: deal.deal._id || 'na', label: deal.deal.dealname || 'N/A' }, data: datesFilters };
    });
    let totalConversion = 0;
    Object.values(rightDeals).forEach((deal) => {
      totalConversion += deal.conversionsArray.length || 0;
      totalConversion += deal.cpmConversions.length || 0;
    });

    setStats({
      grossRevenue,
      grossProfit,
      conversions: totalConversion,
    });

    console.log(itemsWithDeal);
    setChartData(itemsWithDeal);
  };
  useEffect(() => {
    processDeals(dealsArray);
  }, [selectedEmployee, dealsArray, selectedDealType, selectedAdvertisers, selectedOffer]);
  useEffect(() => {
    // console.log(`${influencerId}-cachedreport-${startDate}${endDate}`);
    // console.log(creator.deals, 'SET DEALS');
    setDeals(creator?.deals || []);
  }, [creator]);
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40, 100],
    placeholder: 'Search...',
    sortBy: 'revenue',
    direction: 'desc',
    useFlex: true,
  };
  if (!creator) {
    return (<PermissionComponent />);
  }

  const columns = Columns();
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: `Creator Performance for ${creator?.influencer?.first_name} ${creator?.influencer?.last_name}`, path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Creator Performance for {creator.influencer.first_name} {creator.influencer.last_name}</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col>
                  <ChartComponent
                    selectedAdmins={chartData}
                    startDate={match.params.startDate}
                    endDate={match.params.endDate}
                  />
                </Col>
              </Row>
              <Row className="my-2">
                <Col>
                  <Total stats={stats} />
                </Col>
              </Row>
              <Row className="my-2">
                <Col>
                  <FilterForTable
                    selectedEmployee={selectedEmployee}
                    setSelectedEmployee={setSelectedEmployee}
                    selectedAdvertiser={selectedAdvertisers}
                    setSelectedAdvertiser={setSelectedAdvertisers}
                    setSelectedOffer={setSelectedOffer}
                    selectedOffer={selectedOffer}
                    setSelectedDealType={setSelectedDealType}
                    selectedDealType={selectedDealType}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col />
                <Col lg="auto">
                  {dealsArray?.length > 0 ? (
                    <div className="float-left mr-2 mb-0">
                      <DownloadCSV
                        items={items}
                      />
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <ReactTableBase
                    key="searchable"
                    columns={columns}
                    data={items}
                    tableConfig={tableConfig}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal />
    </Container>
  );
};
SummaryReport.propTypes = {
  // addNotice: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withNotice(SummaryReport);
