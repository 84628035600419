import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DownloadCSVButton from '../../../shared/tables/table/DownloadCSVButton';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import Alert from '../../../shared/easydev2/shared/components/Alert';

const getWarningMessageArray = (validationResult, startDate, endDate) => {
  let message = '';
  let url = 'Something wrong';
  if (!validationResult.contact && validationResult.invalidContact === true) {
    message = 'Missing HubSpot Contact';
    url = `/influencer/profile/${validationResult.influencer._id}/`;
  }
  const closeDate = moment(validationResult.deal?.brandApprovedDate);
  if (validationResult.deal && !validationResult.deal.brandApprovedDate) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'No Brand Approved Date';
  }
  if (validationResult.deal && validationResult.deal.brandApprovedDate && closeDate > moment(endDate).endOf('month')) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Brand Approved Date in the future';
  }
  if (message === '' && validationResult.deal && !validationResult.deal.dealTerms.type_of_deal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Incomplete Deal Terms';
  }
  if (message === '' && validationResult.deal && validationResult.deal.dealTerms.type_of_deal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Brand Approved Date in the future or Invalid status';
  }
  if (message === '' && validationResult.unassignedDeal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.unassignedDeal.hs_object_id}/`;
    message = 'Incomplete Offer';
  }
  if (message === '' && validationResult.contact && validationResult.anotherOfferDeal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${validationResult.contact.hs_object_id}/`;
    message = 'Missing Deal or Possible Offer Mismatch';
  }
  if (message === '' && validationResult.contact && !validationResult.deal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${validationResult.contact.hs_object_id}/`;
    message = 'Missing Deal';
  }
  if (message === '') message = validationResult?.error || '';
  return [message, url];
  // return (<span>{message} {url ? (<a href={url} target="_blank" rel="noreferrer">Click here to correct issue</a>) : null } {validationResult.offerName ? `Offer Name: ${validationResult.offerName}` : null}</span>);
};

const getWarningMessage = (validationResult, startDate, endDate) => {
  const [message, url] = getWarningMessageArray(validationResult, startDate, endDate);
  return (<span>{message} {url ? (<a href={url} target="_blank" rel="noreferrer">Click here to correct issue</a>) : null } {validationResult.offerName ? `Offer Name: ${validationResult.offerName}` : null}</span>);
};

const ErrorsV2 = ({ errors, startDate, endDate }) => {
  const errCount = errors?.length || 0;
  const [errModal, displayErrModal] = useState(false);
  const [cspOptions, setCspOptions] = useState([]);
  const [selectedCsps, setSelectedCsps] = useState(null);
  const isCspSelected = csp => console.log(selectedCsps, csp) || !(selectedCsps && selectedCsps.length) || (csp && (selectedCsps.map(row => row.value).indexOf(csp) > -1));

  const columnTransform = {
    message: {
      sort: (e) => e.error || 'zzz',
      title: 'Error Message', 
      valueFunc: e => (
        <div>
          <div className="py-1 text-dark">
            <div>
              <strong>Deal Owner: </strong>
              {e.admin ? (<span>{e.admin.firstName} {e.admin.lastName}</span>) : <span>-</span>}
            </div>
            <div>
              <strong>Creator: </strong>
              {e.influencer ? (
                <a href={`/influencer/profile/${e.influencer._id}/`} target="_blank" rel="noreferrer">
                  {`${e.influencer?.first_name || ''} ${e.influencer?.last_name || ''}`}
                </a>) : null}
            </div>
            <div>
              <strong>Promo Code: </strong>
              {e.creatorCode || (e.deal ? (e.deal?.dealTerms?.coupon_code || '-') : '-')}
            </div>
            {e.deal?.dealname ? (
              <div>
                <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${e.deal.hs_object_id}/`} target="_blank" rel="noreferrer">
                  {`${e.deal?.dealname || ''}`}
                </a>
              </div>) : null}
            <div>{e.error ? (<span>{e.error} {e.dealId ? (<a target="_blank" rel="noreferrer" href={`/influencers/creator-deal-editor/${e.dealId}`}>Open Deal</a>) : null}</span>) : getWarningMessage(e, startDate, endDate)}</div>
          </div>
        </div>
      ),
    },
  };

  useEffect(async () => {
    try {
      const resp = await axios.get('/api/portal/finance/get-deal-owners');
      const admins = resp.data.dealOwners ? resp.data.dealOwners.map(a => ({ value: `${a._id}`, label: `${a.firstName} ${a.lastName}` })) : [];
      setCspOptions(admins);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const filteredItems = useMemo(() => errors.filter(item => isCspSelected(item.admin?._id)), [selectedCsps, errors]);

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {(errCount > 0) && (
                <Alert color="warning">
                  <p className="py-2 text-dark">
                    {errCount} messages need your attention that may affect the current page performance.&nbsp;
                    <button
                      onClick={() => { displayErrModal(true); }}
                      className="link"
                    >
                      See list
                    </button>
                  </p>
                </Alert>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={errModal}
        toggle={() => displayErrModal(false)}
      >
        <ModalHeader toggle={() => displayErrModal(false)} tag="h4">
          Deals Requiring Attention
        </ModalHeader>
        {/* eslint-disable-next-line react/no-danger */}
        <ModalBody className="table-scrollable no-cell-border pb-0 ltr-support theme-light">
          <Row>
            <Col>
              <div>These deals need to be confirmed and possibly fixed before their deal amounts can contribute to the total Gross Revenue amounts.</div>
              <div className="float-right mr-2 mt-0">
                <DownloadCSVButton
                  records={filteredItems}
                  className="mt-0"
                  fileName="revenue_costs_eom.csv"
                  csvArrayColumns={[
                    {
                      label: 'Creator Name',
                      func: p => `${p.influencer?.first_name || ''} ${p.influencer?.last_name || ''}`,
                    },
                    {
                      label: 'Offer',
                      func: p => `${p.deal?.ilDealData?.offer?.name || ''}`,
                    },
                    {
                      label: 'Brand Approved Date',
                      func: p => `${p?.deal?.brandApprovedDate ? moment(p.deal?.brandApprovedDate).format('MM/DD/YYYY') : ''}`,
                    },
                    {
                      label: 'Content Release Date',
                      func: p => `${p?.deal?.dealTerms?.content_release_date ? moment(p.deal?.dealTerms.content_release_date).format('MM/DD/YYYY') : ''}`,
                    },
                    {
                      label: 'HO Revenue',
                      func: (p) => {
                        let total = 0;
                        p?.conversions?.forEach((conv) => { total += Number(conv.revenue || 0); });
                        return total;
                      },
                    },
                    {
                      label: 'HO payout',
                      func: (p) => {
                        let total = 0;
                        p?.conversions?.forEach((conv) => { total += Number(conv.payout || 0); });
                        return total;
                      },
                    },
                    {
                      label: 'Conversions',
                      func: p => (p?.cpmCount ? Number(p?.cpmCount || 0) : (Number(p?.conversions?.length || 0) + Number(p?.cpmConversionsCount || 0))),
                    },
                    {
                      label: 'Message',
                      func: (p) => {
                        const [message] = getWarningMessageArray(p, startDate, endDate);
                        return p.error ? p.error : message;
                      },
                    },
                    {
                      label: 'Link to Deal',
                      func: p => (p.deal ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.deal?.hs_object_id}/` : ''),
                    },
                ]}
                >
                  Download CSV
                </DownloadCSVButton>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
              <Select
                isMulti
                value={selectedCsps}
                options={cspOptions}
                onChange={value => setSelectedCsps(value)}
                placeholder="Select Deal Owner..."
                isClearable
              />
            </Col>
          </Row>
          <MatTableForRedux
            dataArray={filteredItems}
            selectable={false}
            columns={columnTransform}
            version={(new Date()).toString()}
            rowsPerPage={10}
            hideToolbar
            hideHead
          />
        </ModalBody>
      </Modal>
    </>
  );
};

ErrorsV2.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};
export default ErrorsV2;
