const HelloSign = require('hellosign-embedded');
const { axios } = require('ApiClient');
const { standardFields } = require('./CustomAgreementTemplate');

exports.standardFieldKey = (key) => {
  if (!standardFields[key]) return false;
  return standardFields[key].agreementKey || `${key[0].toLowerCase()}${key.substring(1)}`;
};

const customFields = {};
for (let k in standardFields) {
  if (standardFields[k].agreementKey) {
    customFields[standardFields[k].agreementKey] = k;
  }
}

exports.customFieldKey = key => customFields[key] || `${key[0].toUpperCase()}${key.substring(1)}`;

exports.assignedTo = ({ reassignHistory, sendToName, sendToEmail }) => {
  if (reassignHistory?.length > 0) {
    const { name, email } = reassignHistory[reassignHistory.length - 1];
    return { name, email };
  }
  return { name: sendToName, email: sendToEmail };
};

exports.openSignUrl = async (url, setLoading, { engine }) => {
  if (engine === 'dropbox') {
    const client = new HelloSign({
      clientId: 'fcbc52a0194a804eb7eaeb381425544b',
    });
    client.on('open', () => {
      setLoading(false);
    });
    client.on('sign', () => {
      setLoading(true);
      setTimeout(() => {
        window.location.href = '/signing-complete?event=signing_complete';
      }, 10000);
    });
    client.open(url);
  } else {
    window.location.href = url;
  }
};

exports.remind = async (data, addNotice) => {
  const { agreement, setLoading, portal } = data;
  if (!agreement?.envelopeId) {
    addNotice({
      message: 'Envelope ID is missing',
      type: 'error',
    });
  }
  setLoading(true);
  const { envelopeId, engine } = agreement;
  const { email } = this.assignedTo(agreement);
  try {
    const res = await axios.post( `/api/${portal || 'portal'}/agreement/post-remind`, { envelopeId, email, engine });
    setLoading(false);
    if (res.data.success) {
      addNotice({ message: 'Agreement reminder has been sent', type: 'success' });
    } else {
      addNotice({ message: res.data.error, type: 'error' });
    }
  } catch (error) {
    setLoading(false);
    addNotice({ message: error.message, type: 'error' });
  }
};

exports.refresh = (agreement, { updateRow }) => {
  axios.post(
    '/api/portal/refresh-agreement-document',
    { id: agreement._id },
  ).then((res) => {
    if (res.data.success) {
      const {
        _id,
        status,
        creatorDateSigned,
        filePath,
        fileUrl,
      } = res.data.serviceAgreement;
      updateRow(_id, {
        status,
        creatorDateSigned,
        filePath: (!filePath && (status === 'completed')) ? 'true' : filePath,
        fileUrl,
      });
    }
  }).catch((error) => {
    console.log(error);
  });
};
