import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { unflatten } from 'flat';
import { axios } from 'ApiClient';

const TaxFormType = React.memo(({
  p,
}) => {
  const allowEdit = useSelector((state) => state.taxFormEditRoleCheck.isAllowedToEdit);
  const aValues = {
    'W-9': 'W-9',
    'W-8BEN': 'W-8BEN',
    'W-8BEN-E': 'W-8BEN-E',
    'W-8ECI': 'W-8ECI',
    'W-8IMY': 'W-8IMY',
  };

  const { updateRecordFunction } = p;

  const rowData = p.row.original.params;
  const val = rowData.taxFormType;
  // console.log(val, rowData, 'valueApp');
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const options = Object.keys(aValues).map(k => ({ value: k, label: aValues[k] }));
  useEffect(() => {
    setValue(val);
  }, [rowData._id, val]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option.value);
    try {
      const data = await axios.post('/api/portal/finance/post-update-property-on-tax-form', {
        ids: [rowData._id],
        values: {
          taxFormType: option.label,
        },
      });
      const record = unflatten(data?.data?.recordPopulated);
      updateRecordFunction(rowData._id, { taxFormType: record.params.taxFormType });
      setValue(record.params.taxFormType);
    } catch (e) {
      console.log(e);
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };
  return (
    <div style={{ width: '210px' }}>
      <div style={{ width: '150px', float: 'left' }} >
        {
          allowEdit ? (
            <Select
              value={options.filter(option => option.label === value)}
              options={options}
              onChange={handleChange}
              className="statusBox"
              isDisabled={disabled}
              menuPlacement="auto"
              menuPortalTarget={document.body}
            />
          ) : (
            <p>{value}</p>
          )
        }
        {error ? (<span className="text-danger">{error}</span>) : null}
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.p, a1.p));

TaxFormType.propTypes = {
  p: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TaxFormType;
