import React from 'react';
import { Modal, ButtonToolbar, Button, FormGroup, Input, FormFeedback } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ApiClient from 'ApiClient';
import { Months, momentUTCToLocalDate } from '../../../shared/helpers/Dates';
import withNotice from '../../../containers/App/store/with-notice';

class AccountFinanceModal extends React.Component {
  static propTypes = {
    model: PropTypes.objectOf(PropTypes.any).isRequired,
    saveModelFunc: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
    generalLedger: PropTypes.objectOf(PropTypes.any).isRequired,
    modal: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      isFetching: {
        months: false,
        generalLedger: false,
      },
      month: props.model.params.monthPeriod,
      startDate: momentUTCToLocalDate(moment(props.model.params.startDate)).toDate(),
      endDate: momentUTCToLocalDate(moment(props.model.params.endDate)).toDate(),
      name: props.model.params.name,
      description: props.model.params.description || '',
      arrayYears: [],
      selectedGeneralLedger: null,
    };
    this.handleChangeMonth = this.handleChangeMonth.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.saveModel = this.saveModel.bind(this);
    this.handleChangeYear = this.handleChangeYear.bind(this);
  }
  componentDidMount() {
    this.fetchData(this.props);
  }
  getMinDate(month) {
    const year = this.state.selectedGeneralLedger === null ? this.props.model.populated.generalLedger.params.yearPeriod : this.state.selectedGeneralLedger.label;
    return moment({ y: year, M: Number(month) - 1, d: 1 });
  }
  getMaxDate(month) {
    return this.getMinDate(month).endOf('month');
  }
  fetchData(props) {
    const { addNotice, generalLedger } = props;
    const api = new ApiClient();
    this.setState({ isFetching: { generalLedger: true } });
    api.client.get('/api/portal/finance/get-general-ledger').then((resp) => {
      this.setState({
        isFetching: { generalLedger: false },
        arrayYears: resp.data.items,
        selectedGeneralLedger: { value: generalLedger.id, label: generalLedger.params.yearPeriod },
      });
    }).catch(() => {
      this.setState({ isFetching: { generalLedger: false } });
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
  }
  saveModel() {
    const { saveModelFunc } = this.props;
    const {
      startDate, endDate, month, selectedGeneralLedger, name, description,
    } = this.state;
    saveModelFunc({
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      month,
      selectedGeneralLedger,
      name,
      description,
    }, () => this.props.closeDialog());
  }
  handleChangeMonth(e) {
    const { startDate, endDate } = this.state;
    const month = e.value;
    const minDate = this.getMinDate(month);
    const maxDate = this.getMaxDate(month);
    const newStates = { month };
    if (startDate > maxDate.toDate() || startDate < minDate.toDate()) {
      newStates.startDate = minDate.toDate();
    }
    if (endDate < minDate.toDate() || endDate > maxDate.toDate()) {
      newStates.endDate = maxDate.toDate();
    }
    this.setState(newStates);
  }
  handleChangeYear(e) {
    this.setState({ selectedGeneralLedger: e }, () => {
      this.handleChangeMonth({ value: this.state.month });
    });
  }
  handleChangeEnd(e) {
    this.setState({ endDate: e });
  }
  handleChangeStart(e) {
    this.setState({ startDate: e });
  }
  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }
  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }
  render() {
    const {
      isFetching,
      startDate,
      endDate,
      month,
      selectedGeneralLedger,
      arrayYears,
      name,
      description,
    } = this.state;
    const {
      modalTitle,
      modal,
      closeDialog,
      model,
    } = this.props;
    // console.log(model);
    const options = [...Months].map(item => ({ ...item }));
    const selectedMonth = options ? options.find(e => month && e.value.toString() === month.toString()) : '';
    // const selectedMonthGeneral = options ? options.find(e => this.props.model && e.value.toString() === this.props.model.params.monthPeriod.toString()) : '';
    const minDate = this.getMinDate(month);
    const maxDate = this.getMaxDate(month);
    const optionsYears = [...arrayYears].map(item => ({ value: item._id, label: item.yearPeriod }));/* eslint-disable-line */

    return (
      <Modal
        isOpen={modal}
        toggle={closeDialog}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">{modalTitle}</h4>
        </div>
        <div className="modal__body finance-form">
          <FormGroup>
            <b>Name</b>
            <Input
              type="text"
              value={name}
              onChange={this.handleNameChange}
              invalid={model && model.errors.name && !!model.errors.message}
            />
            {model && model.errors.name && (<FormFeedback>{model.errors.name.message}</FormFeedback>)}
          </FormGroup>
          <FormGroup>
            <b>Description</b>
            <Input
              type="text"
              value={description}
              onChange={this.handleDescriptionChange}
            />
          </FormGroup>
          <FormGroup>
            <b>General Ledger</b><br />
            <Select
              isDisabled={isFetching.generalLedger}
              value={selectedGeneralLedger}
              options={optionsYears}
              onChange={this.handleChangeYear}
              placeholder="Select General Ledger..."
            />
          </FormGroup>
          <FormGroup>
            <b>Month</b><br />
            <Select
              value={selectedMonth}
              isDisabled={isFetching.months}
              options={options}
              onChange={this.handleChangeMonth}
              placeholder="Please Select Month"
              className="mb-3"
            />
            {model && model.errors.monthPeriod && (<div className="form__form-group-error">{model.errors.monthPeriod.message}</div>)}
          </FormGroup>
          <FormGroup>
            <b>Date Start</b><br />
            <DatePicker
              selected={startDate}
              onChange={this.handleChangeStart}
              dateFormat="MM/dd/yyyy"
              minDate={minDate.toDate()}
              maxDate={maxDate.toDate()}
              placeholderText="Start Date (ET)"
              dropDownMode="select"
              className="form-control"
            />
            {model && model.errors.startDate && (<div className="form__form-group-error">{model.errors.startDate.message}</div>)}
          </FormGroup>
          <FormGroup>
            <b>Date End</b><br />
            <DatePicker
              selected={endDate}
              onChange={this.handleChangeEnd}
              dateFormat="MM/dd/yyyy"
              placeholderText="End Date (ET)"
              minDate={minDate.toDate()}
              maxDate={maxDate.toDate()}
              dropDownMode="select"
              className="form-control"
            />
            {model && model.errors.endDate && (<div className="form__form-group-error">{model.errors.endDate.message}</div>)}
          </FormGroup>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="mr-3" color="primary" onClick={this.saveModel}>Save</Button>{' '}
          <Button className="modal_cancel" color="danger" onClick={this.props.closeDialog}>Cancel</Button>
        </ButtonToolbar>
      </Modal>
    );
  }
}
export default withNotice(AccountFinanceModal);

