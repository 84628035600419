import React from 'react';
import { NumericFormat as NumberFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';


const CurrencyFieldF = ({
  value,
  setValue,
  disabled,
  placeholder,
  name,
  allowEmptyFormatting,
  onBlur,
}) => (
  <InputGroup className="min-width-150">
    <InputGroupAddon addonType="prepend">
      <InputGroupText>$</InputGroupText>
    </InputGroupAddon>
    <NumberFormat
      thousandSeparator
      prefix=""
      name={name}
      value={value}
      className="form-control"
      inputMode="numeric"
      allowEmptyFormatting={allowEmptyFormatting}
      fixedDecimalScale
      decimalScale={2}
      disabled={disabled}
      onBlur={e => onBlur && onBlur(e)}
      placeholder={placeholder}
      onValueChange={values => setValue(values.floatValue)}
    />
  </InputGroup>
);

CurrencyFieldF.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.number,
  onBlur: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  allowEmptyFormatting: PropTypes.bool,
};
CurrencyFieldF.defaultProps = {
  disabled: false,
  value: null,
  placeholder: '',
  name: 'name',
  allowEmptyFormatting: false,
};

export default CurrencyFieldF;
