exports.envelopeName = (envelope) => {
  const { envelopeId, emailSubject, creator } = envelope;
  const parts = [];
  if (creator) {
    parts.push(creator.name);
  }
  const name = (emailSubject.substr(0, 17).toLowerCase() === 'please docusign: ') ? emailSubject.substr(17) : emailSubject;
  if (name) {
    parts.push(name);
  }
  return (parts.length > 0) ? parts.join(' - ') : envelopeId;
};
