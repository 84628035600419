import React from 'react';
import moment from 'moment-timezone';
import VideoDetails from './table/VideoDetails';
import { currentTimezoneAggr } from '../../../../../../shared/helpers/Dates';
import WVFormatter from '../../../../../../shared/helpers/WVFormatter';
import mapMediaTypeIcons from '../../../MediaContent/components/custom/mapMediaTypeIcons';
import InfluenceLogicStatus from './table/InfluenceLogicStatus';
import BrandStatus from './table/BrandStatus';
import ContextMenu from './table/ContextMenu';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ScoreActionInList from './ScoreActionInList';
// import AdminUserInList from './AdminUserInList';
// import StatusInList from './StatusInList';

const mapType = mapMediaTypeIcons();

const getFormattedDateET = (date) => {
  // per https://influencelogic.atlassian.net/jira/software/c/projects/IL/issues/IL-4872
  // if the date is midnight, then it was set manually, and we can ignore the need to convert it to ET
  let res = '-';
  if (date) {
    const momentDate = moment.utc(date);

    if (momentDate.hour() === 0 && momentDate.minute() === 0 && momentDate.second() === 0) {
      res = momentDate.format('MM/DD/YY');
    } else {
      res = momentDate.tz('America/New_York').format('MM/DD/YY');
    }
  }
  return res;
}

export default function TableColumns(props) {
  const {
    setVersion, setDisableNote, setCurrentContent, setShowNotesModal, setNotesType, setShowStatusHistoryModal,
  } = props;
  return [
    {
      Header: '',
      accessor: () => null,
      id: 'scoreAction',
      Cell: record => (
        <div style={{ width: '40px' }}>
          <span style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '30px',
          }}
          >
            <a
              title="Score Content"
              target="_blank"
              rel="noreferrer"
              href={`/scorecard/score-content/${record.row.original.params?._id}`}
            >
              {/* <FontAwesomeIcon icon="fa-regular fa-ruler-triangle" /> */}
              <i className="fa fa-ruler-combined" style={{ fontSize: '20px' }} />
              {/* <i className="fa fa-tachometer-alt" style={{ fontSize: '20px' }} /> */}
            </a>
            &nbsp;
          </span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
      width: 40,
    },
    {
      Header: 'Video Details',
      accessor: p => (p.params?.creatorFullName),
      id: 'title',
      Cell: record => (
        <div >
          <VideoDetails record={record.row.original} />
        </div>
      ),
      disableSortBy: true,
      className: 'align-middle',
      width: 130,
    },
    {
      Header: 'Creator',
      accessor: p => (p.params?.creatorFullName),
      id: 'creatorFullName',
      Cell: record => (
        <div style={{ paddingRight: '10px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`/influencer/profile/${record.row.original.params.creatorId}`}
          >
            {record.row.original.params.creatorFullName}
          </a>
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
      width: 120,
    },
    {
      Header: 'Type',
      accessor: p => (p.params?.type),
      id: 'typeInList',
      width: 70,
      Cell: value => (
        <div>
          <a href={value.row.original.params.mediaUrl} target="_blank" rel="noreferrer">
            <img
              style={{ width: '30px' }}
              src={`/images/mediacontent/${mapType[value.row.original?.params?.type]}.png`}
              alt={value.row.original.params.type}
              title={value.row.original.params.type}
            />
          </a>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
    },
    {
      Header: 'Brand',
      accessor: p => (p.params?.brand),
      id: 'brand',
      Cell: record => (
        <div>
          <span>{record.row.original.params?.brand || ''}</span>
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
      width: 100,
    },
    {
      Header: 'InfluenceLogic Status',
      accessor: () => null,
      id: 'status',
      Cell: p => (
        <div>
          <InfluenceLogicStatus
            record={p.row.original}
            setVersion={setVersion}
            showNotes={() => { setDisableNote(false); setCurrentContent(p.row.original); setShowNotesModal(true); setNotesType('influenceLogic'); }}
          />
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 200,
    },
    {
      Header: 'Brand Status',
      accessor: () => null,
      id: 'brandStatus',
      Cell: p => (
        <div>
          <BrandStatus
            record={p.row.original}
            setVersion={setVersion}
            showNotes={() => { setDisableNote(false); setCurrentContent(p.row.original); setShowNotesModal(true); setNotesType('brand'); }}
          />
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 200,
    },
    {
      Header: `Submit Date (${currentTimezoneAggr()})`,
      accessor: () => null, // p => (WVFormatter.getServerDateTime(p.params?.createdAt)),
      id: 'createdAt',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{WVFormatter.getServerDateTime(value.row.original.params?.createdAt)}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 160,
    },
    {
      Header: 'Publish Date (ET)',
      accessor: item => (item?.params?.publishDate ? item?.params?.publishDate.split('T')[0] : null), // p => (WVFormatter.getServerDateTime(p.params?.createdAt)),
      id: 'publishDate',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{value.value ? moment(value.value).format('MM/DD/YY') : '-'}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 100,
    },
    {
      Header: 'Brand Approved Date (ET)',
      accessor: item => getFormattedDateET(item?.params?.brandApprovedDate),
      id: 'brandApprovedDate',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{value?.value || '-'}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 160,
    },
    {
      Header: 'Deal Type',
      accessor: p => (p.params?.dealType),
      id: 'dealType',
      Cell: p => (p.row.original.params?.dealType || ''),
      className: 'align-middle',
      disableSortBy: false,
      width: 150,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'contact_actions',
      width: 50,
      Cell: p => (
        <ContextMenu
          record={p.row.original}
          setShowStatusHistoryModal={setShowStatusHistoryModal}
          setCurrentContent={setCurrentContent}
        />
      ),
      disableSortBy: true,
      className: 'd-flex align-items-center align-middle',
    },
  ];
}
