import React, { useState, forwardRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import CalendarIcon from 'mdi-react/CalendarIcon';
import { getLast7Days } from '../../../../../../../shared/helpers/Dates';

const TimePeriodFilter = ({
  setValue,
  isLoading,
  selectedButtonInitial,
  startDateInitial,
  endDateInitial,
}) => {
  const [showCustom, setShowCustom] = useState(selectedButtonInitial === 'custom');
  const [selectedButton, setSelectedButton] = useState(selectedButtonInitial || 'year_to_date');
  const [startDate, setStartDate] = useState(startDateInitial ? moment(startDateInitial).toDate() : moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(endDateInitial ? moment(endDateInitial).toDate() : moment().endOf('month').toDate());
  const selectDateRange = (range) => {
    let dateFrom;
    let dateTo;
    switch (range) {
      case 'today':
        setShowCustom(false);
        dateFrom = moment().startOf('day').toDate();
        dateTo = moment().endOf('day').toDate();
        break;
      case 'current_month':
        setShowCustom(false);
        dateFrom = moment().startOf('month').toDate();
        dateTo = moment().endOf('month').toDate();
        break;
      case 'last_month':
        setShowCustom(false);
        dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
        dateTo = moment().subtract(1, 'month').endOf('month').toDate();
        break;
      case 'last_7_days':
        setShowCustom(false);
        [dateFrom, dateTo] = getLast7Days();
        break;
      case 'year_to_date':
        setShowCustom(false);
        dateFrom = moment().startOf('Year').toDate();
        dateTo = moment().endOf('month').toDate();
        break;
      case 'custom':
        setShowCustom(true);
        dateFrom = moment().startOf('Year').toDate();
        dateTo = moment().endOf('day').toDate();
        break;
      default:
        dateFrom = moment().startOf('month').toDate();
        dateTo = moment().endOf('month').toDate();
        break;
    }
    setStartDate(dateFrom);
    setEndDate(dateTo);
    setValue('startDate', dateFrom, range);
    setValue('endDate', dateTo, range);
    setSelectedButton(range);
  };
  const getText = () => {
    // if (selectedButton === 'all_time') return '';
    let dateText = '';
    if (
      moment(startDate).format('M') === moment(endDate).format('M') &&
      moment(startDate).format('D') === moment(endDate).format('D') &&
      moment(startDate).format('YYYY') === moment(endDate).format('YYYY')) {
      dateText = moment(startDate).format('MMM D, YYYY');
    } else if (selectedButton === 'current_month' || selectedButton === 'year_to_date') {
      dateText = `${moment(startDate).format('MMM D, YYYY')}  —  ${moment().endOf('day').format('MMM D, YYYY')}`;
    } else {
      dateText = `${moment(startDate).format('MMM D, YYYY')}  —  ${moment(endDate).format('MMM D, YYYY')}`;
    }
    return dateText;
  };
  const buttonClass = 'btn-sm mr-0 time-period-filter-buttons-extended';
  // eslint-disable-next-line react/prop-types
  const DatePickerCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div className="form-group mb-2">
      <InputGroup size="md">
        <input
          type="text"
          className="form-control form-control-sm"
          value={value}
          onClick={onClick}
          onChange={onChange}
          style={{
            border: '1px solid #ced4da',
            borderRadius: '5px',
          }}
          ref={ref}
        />
        <InputGroupAddon
          addonType="append"
          style={{
            padding: '3px 0 3px 3px',
          }}
        >
          <CalendarIcon
            className="date-picker-icon"
            onClick={onClick}
          />
        </InputGroupAddon>
      </InputGroup>
    </div>
  ));
  const minDate = moment().subtract(25, 'months');
  return (
    <div>
      <Row style={{ minWidth: '300px' }}>
        <Col lg="auto">
          <h5 style={{ color: 'black', marginBottom: '8px' }} className="bold-text">Review Date</h5>
        </Col>
      </Row>
      <Row style={{ minWidth: '300px' }}>
        <Col lg="auto">
          <Button
            onClick={() => selectDateRange('current_month')}
            className={selectedButton === 'current_month' ? `active ${buttonClass}` : buttonClass}
            style={{ borderRadius: '5px 0 0 5px', borderRight: '0' }}
            size="sm"
            disabled={isLoading}
          >
            Current Month
          </Button>
          <Button
            onClick={() => selectDateRange('last_month')}
            className={selectedButton === 'last_month' ? `active ${buttonClass}` : buttonClass}
            style={{ borderRadius: '0', borderRight: '0' }}
            size="sm"
            disabled={isLoading}
          >
            Last Month
          </Button>
          <Button
            onClick={() => selectDateRange('today')}
            className={selectedButton === 'today' ? `active ${buttonClass}` : buttonClass}
            style={{ borderRadius: '0', borderRight: '0' }}
            size="sm"
            disabled={isLoading}
          >
            Today
          </Button>
          <Button
            onClick={() => selectDateRange('last_7_days')}
            className={selectedButton === 'last_7_days' ? `active ${buttonClass}` : buttonClass}
            style={{ borderRadius: '0', borderRight: '0' }}
            size="sm"
            disabled={isLoading}
          >
            Last 7 days
          </Button>
          <Button
            onClick={() => selectDateRange('year_to_date')}
            className={selectedButton === 'year_to_date' ? `active ${buttonClass}` : buttonClass}
            style={{ borderRadius: '0', borderRight: '0' }}
            size="sm"
            disabled={isLoading}
          >
            Year to Date
          </Button>
          <Button
            onClick={() => selectDateRange('custom')}
            className={selectedButton === 'custom' ? `active ${buttonClass}` : buttonClass}
            style={{ borderRadius: '0 5px 5px 0', borderColor: '#4A69F2' }}
            size="sm"
            disabled={isLoading}
          >
            Custom
          </Button>
        </Col>
        <Col>
          {showCustom ? (
            <Row>
              <Col xs="auto" className="">
                <div style={{ maxWidth: '150px' }}>
                  <DatePicker
                    id="startDateDatePicker"
                    selected={startDate}
                    onChange={date => setStartDate(date) || setValue('startDate', date)}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    customInput={<DatePickerCustomInput />}
                    disabled={isLoading}
                  />
                </div>
              </Col>
              <Col xs="auto" className="">
                <div style={{ maxWidth: '150px' }}>
                  <DatePicker
                    id="endDateDatePicker"
                    selected={endDate}
                    onChange={date => setEndDate(date) || setValue('endDate', date)}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="End Date"
                    dropDownMode="select"
                    customInput={<DatePickerCustomInput />}
                    disabled={isLoading}
                  />
                </div>
              </Col>
              <Col />
            </Row>) : null
          }
        </Col>
      </Row>
      <Row style={{ minWidth: '300px' }}>
        <Col lg="auto">
          <h5 style={{ color: 'black', marginBottom: '8px' }} className="bold-text">{getText()}</h5>
        </Col>
      </Row>
    </div>
  );
};
TimePeriodFilter.propTypes = {
  setValue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  selectedButtonInitial: PropTypes.string,
  startDateInitial: PropTypes.string,
  endDateInitial: PropTypes.string,
};
TimePeriodFilter.defaultProps = {
  isLoading: false,
  selectedButtonInitial: 'current_month',
  startDateInitial: null,
  endDateInitial: null,
};
export default TimePeriodFilter;
