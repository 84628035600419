import React, { useMemo } from 'react';
import { axios } from 'ApiClient';
import TableCellContextMenu from '../../../../../../../../shared/components/table/TableCellContextMenu';
import ApprovalStatus from './ApprovalStatus';
import DescriptionField from './DescriptionField';
import SignedDateField from './SignedDateField';
import MatTableList from '../../../../../../../../shared/tables/materialTable/TableRedux';
import { getServerDate } from '../../../../../../../../shared/helpers/WVFormatter';
import { UploadPriceApprovals } from '../Modal/UploadPriceApprovalsModal';
import Hook from '../../../../../../../../shared/hooks';
import { goToPage } from '../../../../../../../../shared/helpers/WVUtilities';

const Table = React.memo(() => {
  const reload = Hook.useReloadTableHook();
  const [showDeleteModal] = Hook.useShowModalHook('DeleteModalDefault');
  const [showModalUpload] = Hook.useShowModalHook(UploadPriceApprovals);
  return (
    <MatTableList
      noActions
      isResizable={false}
      useFlex
      useCheckboxes
      useMultiPageCheckboxes
      checkboxHandler={() => {}}
      enableWarning={false}
      notFoundProps={{ title: 'No CPM Price Approvals Group Found' }}
      useUnflatten
      customColumns={[
          {
            id: 'search',
            Header: () => 'PA Group Name',
            accessor: record => (record?.params?.name || ''),
            Cell: p => useMemo(() => (
              p.row.original.params?.name ? (
                <a
                  href={`/research/cpm-price-approvals-group-prospects/${p.row.original.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {p.value}
                </a>
              ) : ''), [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
          },
          {
            id: 'createdAt',
            Header: () => 'Date Created',
            accessor: record => (record?.params?.createdAt || ''),
            Cell: p => useMemo(() => (
              p.row.original.params?.createdAt ? (
                <div style={{ width: '160px', paddingRight: '10px' }}>
                  <span>{getServerDate(p.row.original.params.createdAt)}</span>
                </div>
              ) : null), [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
          },
          {
            id: 'brand',
            Header: () => 'Brand',
            Cell: p => p.row.original.populated?.brand?.params?.companyName || '',
            width: 100,
            className: 'd-flex align-items-center',
            sortable: true,
          },
          {
            id: 'status.status',
            Header: () => 'Status',
            Cell: p => useMemo(() => (
              <ApprovalStatus
                record={p.row.original}
                afterSaveFunc={() => reload()}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
            sortable: true,
          },
          {
            id: 'dateSigned',
            Header: () => 'Date Signed',
            accessor: record => (record?.params?.dateSigned || ''),
            Cell: p => useMemo(() => (
              <SignedDateField
                record={p.row.original}
                afterSaveFunc={() => reload()}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
          },
          {
            id: 'description',
            Header: () => 'Description',
            accessor: record => (record?.params?.description || ''),
            Cell: p => <DescriptionField record={p.row.original} />,
            className: 'd-flex align-items-center',
            disableSortBy: true,
            width: 250,
          },
        {
          Header: '',
          accessor: p => (p.params.googleSheetFiles?.length > 0 ? p.params.googleSheetFiles[p.params.googleSheetFiles.length - 1] : null),
          id: 'cpm_prospect_actions_files',
          width: 60,
          maxWidth: 60,
          // eslint-disable-next-line react/prop-types
          Cell: ({ value }) => {
            // console.log(value);
            if (!value) return '';
            // eslint-disable-next-line react/prop-types
            const { fileUrl, fileId } = value;
            return (
              <div>
                <a href={fileUrl} title="Open Google Sheet File" target="_blank" rel="noreferrer" style={{ fontSize: '30px' }} className="pr-2 text-dark"><i className="fa fa-file-excel" /></a>
                <a
                  // eslint-disable-next-line react/prop-types
                  href={`https://docs.google.com/spreadsheets/d/${fileId}/export?format=pdf&gridlines=false`}
                  title="Download PDF File"
                  target="_blank"
                  rel="noreferrer"
                  className="text-dark"
                  style={{ fontSize: '30px' }}
                ><i className="fa fa-file-pdf" />
                </a>
              </div>
            );
          },
          disableSortBy: true,
        },
          {
            Header: '',
            accessor: () => null,
            id: 'cpm_prospect_actions_delete',
            width: 50,
            Cell: (p) => {
              const contextMenuOptions = [
                { label: 'View Price Approvals Group', handler: () => { goToPage(`/research/cpm-price-approvals-group-prospects/${p.row.original.id}`); } },
                { type: 'divider', label: 'divider2' },
                {
                  label: 'Upload PA Group Document',
                  handler: () => showModalUpload({ record: p.row.original, updateData: reload }),
                },
                {
                  label: 'Delete',
                  handler: () => {
                    showDeleteModal({
                      title: 'Delete Price Approval Group',
                      message: (<>Are you sure you want to delete <strong>{p.row.original?.params?.name || 'record'}</strong>?</>),
                      deleteRecord: async (data, addNotice) => {
                        const res = await axios({
                          method: 'post',
                          url: '/api/portal/prospects/post-cpm-price-approvals-delete',
                          data: { id: p.row.original.params._id },
                        });
                        if (!res.data.success) {
                          addNotice({
                            message: `Unable to remove item: ${res.data.error}`,
                            type: 'error',
                          });
                        }
                      },
                      successFunction: () => {
                        reload();
                      },
                    });
                  },
                  disabled: Boolean(p.row.original.populated.cpmInsertionOrder || p.row.original.populated.cpmInvoice),
              },
              ];
              return (
                <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                  <TableCellContextMenu options={contextMenuOptions} />
                </div>
              );
            },
            disableSortBy: true,
            className: 'd-flex align-items-center align-middle',
          },
        ]}
    />
  );
}, () => true);

Table.propTypes = {
  // action: PropTypes.objectOf(PropTypes.any).isRequired,
  // resource: PropTypes.objectOf(PropTypes.any).isRequired,
  // version: PropTypes.string.isRequired,
  // setVersion: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // recordsRef: PropTypes.any.isRequired,
  // handleSelectCheckbox: PropTypes.func.isRequired,
};

export default Table;
