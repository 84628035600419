import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label, Modal, Input, Spinner, Col } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { compile } from 'mathjs';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import StyledButton from '../../../../../../shared/components/components/ui/styled-button';
import {
  showFormulaModalDialog,
  saveFormulaForm,
  getFormulaById, getFormulas,
} from '../../../../../../redux/reducers/admin/ResearchReducer';
import withNotice from '../../../../../App/store/with-notice';
// import {FullWideNotification} from "../../../../../../shared/components/Notification";

const FormulaForm = ({
  showFormulaDialog,
  dispatch,
  id,
  currentFormula,
  tokens,
}) => {
  const [name, setName] = useState('');
  const [formula, setFormula] = useState('');
  const [formatOptions] = useState([
    { value: 'currency', label: 'Currency ($1,000.00)' },
    { value: 'decimal', label: 'Decimal (100.00)' },
    { value: 'integer', label: 'Integer (100)' },
    { value: 'text', label: 'Text (ABC)' },
  ]);
  const [format, setFormat] = useState({ value: 'currency', label: 'Currency' });
  const [errors, setErrors] = useState({});
  const [tokensArr, setTokensArr] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading('true');
      dispatch(getFormulaById(id)).then(() => {
        setLoading(false);
      });
    }
  }, [id]);

  useEffect(() => {
    if (currentFormula) {
      const currentFormat = formatOptions.find(option => option.value === currentFormula.format) || { value: 'currency', label: 'Currency' };
      console.log(currentFormula);
      setName(currentFormula.name);
      setFormula(currentFormula.formula);
      setFormat(currentFormat);
    }
  }, [currentFormula]);

  useEffect(() => {
    if (tokens && tokens !== {}) {
      const arr1 = Object.keys(tokens);
      const arr2 = arr1.splice(Object.keys(tokens).length / 2);
      setTokensArr([arr1, arr2]);
    }
  }, [tokens]);

  const closeFormDialog = () => {
    setName('');
    setFormula('');
    setFormat({ value: 'currency', label: 'Currency' });
    setErrors({});
    dispatch(showFormulaModalDialog(false));
  };

  const copyToken = (e) => {
    let textInput = false;
    try {
      textInput = e.nativeEvent.target.closest('.form__form-group-field').querySelector('input');
    } catch (err) {
      textInput = false;
    }

    e.preventDefault();

    if (textInput) {
      textInput.select();
      document.execCommand('copy');
    }
  };

  const isFormulaValid = (value) => {
    try {
      let isValid = true;
      const usedTokens = value.match(/{{ \w* }}/g);
      if (usedTokens && usedTokens.length > 0) {
        usedTokens.map((item) => {
          if (!Object.keys(tokens).includes(item)) {
            isValid = false;
          }
          return false;
        });
      }
      if (isValid) {
        const res = value.replace(new RegExp('{{ \\w* }}', 'ig'), '1');
        const wrongSymbols = res.match(/[a-zA-Z]+/);
        if (wrongSymbols && wrongSymbols.length > 0) {
          setErrors({
            ...errors,
            formula: `Bad formula, please use only mathematical symbols. Check symbol '${wrongSymbols.join(', ')}'`,
          });
          return false;
        }
        // evaluating formula
        const code = compile(res);
        if (code) {
          console.log(code.evaluate());
        }
        return true;
      }
      setErrors({
        ...errors,
        formula: 'Bad formula, please use allowed tokens',
      });
      return false;
    } catch (e) {
      setErrors({
        ...errors,
        formula: 'Bad formula, please check your math expression.',
      });
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (name === '') {
      setErrors(prev => ({
        ...prev,
        name: 'This field is required',
      }));
    }
    if (typeof format === 'undefined' && typeof format.value === 'undefined') {
      setErrors(prev => ({
        ...prev,
        format: 'This field is required',
      }));
    }
    if (formula === '') {
      setErrors(prev => ({
        ...prev,
        formula: 'This field is required',
      }));
    }
    if (isFormulaValid(formula)) {
      setLoading(true);
      dispatch(saveFormulaForm({
        id,
        name,
        formula,
        format: format.value,
      })).then(() => {
        dispatch(getFormulas());
        closeFormDialog();
        setLoading(false);
      });
    }
  };

  const handleFormatSelectChange = (selectedOption) => {
    if (selectedOption) {
      setFormat(selectedOption);
    }
  };

  return (
    <Modal
      isOpen={showFormulaDialog}
      toggle={closeFormDialog}
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">{id ? 'Edit Formula' : 'Add New Formula'}</h4>
      </div>
      <div className="modal__body finance-form">
        <form onSubmit={handleSubmit}>
          {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
          {!loading && (
            <div>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrors({ ...errors, name: false });
                  }}
                  value={name}
                />
                {errors.name && (<span className="text-danger">{errors.name}</span>)}
              </FormGroup>
              <FormGroup>
                <Label>Format</Label>
                <Select
                  name="formatSelect"
                  value={format}
                  options={formatOptions}
                  onChange={handleFormatSelectChange}
                />
                {errors.format && (<span className="text-danger">{errors.format}</span>)}
              </FormGroup>
              <FormGroup>
                <Label>Formula</Label>
                <Input
                  type="text"
                  onChange={(e) => {
                    setFormula(e.target.value);
                    setErrors({ ...errors, formula: false });
                  }}
                  value={formula}
                />
                {errors.formula && (<span className="text-danger">{errors.formula}</span>)}
              </FormGroup>
              <div style={{ marginBottom: 20 }}>
                <Accordion style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ display: 'inline-flex', paddingLeft: 0 }}
                  >
                    <span>Allowed Tokens</span>
                  </AccordionSummary>
                  <AccordionDetails style={{ paddingLeft: 0 }}>
                    <Col md={6}>
                      {tokensArr.length > 0 && tokensArr[0].map(item => (
                        <FormGroup
                          key={`div_${item}`}
                          className="form__form-group-field"
                          style={{ marginTop: '5px' }}
                        >
                          <input
                            style={{ width: '100%', paddingLeft: '5px' }}
                            readOnly
                            value={item}
                          />
                          <button
                            className="btn btn-primary btn-sm"
                            type="button"
                            title="Copy"
                            style={{
                              padding: '5px 5px 5px 10px',
                              height: '38px',
                              margin: '0px',
                              borderRadius: '0px 5px 5px 0px',
                            }}
                            onClick={e => copyToken(e)}
                          ><ContentCopyIcon size={16} />
                          </button>
                        </FormGroup>
                      ))}
                    </Col>
                    <Col md={6}>
                      {tokensArr.length > 0 && tokensArr[1].map(item => (
                        <FormGroup
                          key={`div_${item}`}
                          className="form__form-group-field"
                          style={{ marginTop: '5px' }}
                        >
                          <input
                            style={{ width: '100%', paddingLeft: '5px' }}
                            readOnly
                            value={item}
                          />
                          <button
                            className="btn btn-primary btn-sm"
                            type="button"
                            title="Copy"
                            style={{
                              padding: '5px 5px 5px 10px',
                              height: '38px',
                              margin: '0px',
                              borderRadius: '0px 5px 5px 0px',
                            }}
                            onClick={e => copyToken(e)}
                          ><ContentCopyIcon size={16} />
                          </button>
                        </FormGroup>
                      ))}
                    </Col>
                  </AccordionDetails>
                </Accordion>
              </div>
              <StyledButton
                type="submit"
                className="is-primary"
              >
                <i className="icomoon-save" />
                <span className="btn-text">Save</span>
              </StyledButton>
              &nbsp;&nbsp;
              <StyledButton
                type="button"
                className="btn-danger"
                onClick={closeFormDialog}
              >
                <i className="icomoon-save" />
                <span className="btn-text">Cancel</span>
              </StyledButton>
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
};


FormulaForm.propTypes = {
  showFormulaDialog: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
  currentFormula: PropTypes.objectOf(PropTypes.any),
  tokens: PropTypes.objectOf(PropTypes.any),
};

FormulaForm.defaultProps = {
  id: null,
  currentFormula: null,
  tokens: [],
};

const mapStateToProps = state => ({
  adminUser: state.session,
  showFormulaDialog: state.ResearchReducer.showFormulaDialog,
  currentFormula: state.ResearchReducer.currentFormula,
  // formulaTokens: state.ResearchReducer.formulaTokens,
});

export default withNotice(connect(mapStateToProps)(FormulaForm));
