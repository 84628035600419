import React, { useState, useEffect, useRef } from 'react';
import { axios } from 'ApiClient';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import TableCellContextMenu from '../../../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';
import DetailsModal from './DetailsModal';
import ProgressModal from './ProgressModal';
import ViewQueryModal from './ViewQueryModal';
import DeleteProspectUploadModal from './DeleteProspectUploadModal';
import { setCookie } from '../../../../../../../shared/helpers/WVUtilities';
import withNotice from '../../../../../../App/store/with-notice';

const ContextMenuInList = ({
  record,
  updateTable,
  addNotice,
}) => {
  const { researchSavedSearch } = unflatten(record.params);
  const [loading, setLoading] = useState(false);
  const [r, setR] = useState(null);
  const [q, setQ] = useState(null);
  const [modal, setModal] = useState(false);
  // const [timeout, setTimeoutObject] = useState(null);
  const [modalProgress, setModalProgress] = useState(false);
  const [modalViewQuery, setModalViewQuery] = useState(false);
  const [modalDeleteUpload, setModalDeleteUpload] = useState(false);
  const [loadingDeleteUpload, setLoadingDeleteUpload] = useState(false);
  const loadLog = async () => {
    if (r && q) {
      setModal(true);
      return false;
    }
    setLoading(true);
    console.log(loading);
    try {
      const recordData = await axios.post('/api/portal/prospects/post-get-prospect-log', { _id: record.params.identity });
      console.log(recordData);
      setR(recordData.data.prospectLog);
      setQ(recordData.data.q);
      setModal(true);
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const loadDetailsLog = async () => {
    setLoading(true);
    console.log(loading);
    try {
      const recordData = await axios.post('/api/portal/prospects/post-get-prospect-log', { _id: record.params.identity });
      console.log(recordData);
      setR(recordData.data.prospectLog);
      setQ(recordData.data.q);
      if (!modalProgress) {
        setModalProgress(true);
      }
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const intervalIdRef = useRef(-1);
  const startLoop = () => {
    // make sure this is not called while the prior interval is running
    // or first call clearInterval(intervalIdRef.current)
    intervalIdRef.current = setInterval(() => loadDetailsLog(), 1000);
  };
  const stopLoop = () => {
    clearInterval(intervalIdRef.current);
  };
  // When component unmounts, clean up the interval:
  useEffect(() => stopLoop, []);
  useEffect(() => {
    if (modalProgress) {
      console.log('startEffect', modalProgress);
      startLoop();
    } else {
      stopLoop();
    }
  }, [modalProgress]);

  const deleteUpload = () => {
    setLoadingDeleteUpload(true);
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-delete-prospect-upload',
      data: {
        identity: record.params.identity,
      },
    }).then((res) => {
      setLoadingDeleteUpload(false);
      if (res?.data?.success) {
        addNotice({
          message: 'Prospect Upload was successfully deleted.',
          type: 'success',
        });
        updateTable();
      } else if (res?.data?.error) {
        addNotice({
          message: `Prospect Upload was not deleted. Error: ${res.data.error}`,
          type: 'error',
        });
      }
      setModalDeleteUpload(false);
    }).catch((error) => {
      console.error(error);
      addNotice({
        message: `Prospect Upload was not deleted. Error: ${error.message}`,
        type: 'error',
      });
      setLoadingDeleteUpload(false);
    });
  };

  const contextMenuOptions = [
    {
      label: 'Details',
      handler: () => {
        loadLog();
      },
    },
    {
      label: 'Download CSV',
      handler: () => {
        const csvDownloadLink = `/api/portal/prospects/get-csv-uploads?identity=${record.params.identity}`;
        window.open(csvDownloadLink, '_blank').focus();
      },
    },
    {
      label: 'Progress Report',
      handler: () => {
        loadDetailsLog();
      },
    },
  ];

  if (record?.params?.status === 'stopped') {
    contextMenuOptions.push({
      label: 'Delete Job',
      handler: () => setModalDeleteUpload(true),
    });
  }

  if (researchSavedSearch && researchSavedSearch._id) {
    contextMenuOptions.push({ type: 'divider', label: 'divider1' });
    contextMenuOptions.push({
      label: 'Search Query',
      handler: () => {
        setCookie('prospectUploadsSavedSearchId', researchSavedSearch._id, 1);
        window.open('/research/search', '_blank').focus();
      },
    });
    contextMenuOptions.push({
      label: 'View Query',
      handler: () => {
        setModalViewQuery(true);
      },
    });
  }

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />
      { (q && r) &&
      <>
        <DetailsModal modal={modal} record={r} setModalVisible={() => setModal(false)} q={q} />
        <ProgressModal modal={modalProgress} record={r} setModalVisible={() => setModalProgress(false)} r={r} />
      </>
      }
      {modalViewQuery && (
        <ViewQueryModal modal={modalViewQuery} setModalVisible={() => setModalViewQuery(false)} search={researchSavedSearch} />
      )}
      {record?.params?.status === 'stopped' && (
        <DeleteProspectUploadModal
          modal={modalDeleteUpload}
          setModalVisible={setModalDeleteUpload}
          loading={loadingDeleteUpload}
          deleteUpload={deleteUpload}
        />
      )}
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(ContextMenuInList);
