/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const GlobalSelected = createSlice({
  name: 'globalSelected',
  initialState: {
    params: {},
    history: [],
  },
  reducers: {
    set(state, action) {
      const valuesObj = action.payload;
      Object.keys(valuesObj).forEach((key) => {
        state.params[key] = valuesObj[key];
      });
    },
    clear(state, action) {
      const keys = action.payload;
      keys.forEach((key) => {
        if (state.params[key]) {
          delete state.params[key];
        }
      });
    },
    setLocation(state, action) {
      const location = action.payload;
      if (location && location.pathname
        && location.pathname.indexOf('login') === -1) {
        state.history.push({ ...location });
      }
    },
  },
});


export const {
  set,
  clear,
  setLocation,
} = GlobalSelected.actions;


export default GlobalSelected.reducer;
