import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { axios } from 'ApiClient';
import TableCellContextMenu from '../../../../../shared/components/table/TableCellContextMenu';
import withNotice from '../../../../App/store/with-notice';
import ExecuteModal from './ExecuteModal';

const ContextMenuInList = ({
  addNotice,
  record,
  updateTable,
  loadingBrandOptions,
  brandOptions,
}) => {
  const [loading, setLoading] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [showDeleteRuleDialog, setShowDeleteRuleDialog] = useState(false);
  const [showExecuteModal, setShowExecuteModal] = useState(false);
  const [executeStatus, setExecuteStatus] = useState('');
  const [executeUrl, setExecuteUrl] = useState('');
  const [executeMessage, setExecuteMessage] = useState('');

  useEffect(() => {
    if (!showExecuteModal) {
      setExecuteStatus('');
      setExecuteUrl('');
      setExecuteMessage('');
    }
  }, [showExecuteModal]);

  const sendRequest = (fieldsToUpdate) => {
    setLoading(true);
    axios.post('/api/portal/compliance/post-edit-content-review-rule', {
      id: record.id,
      fieldsToUpdate,
    }).then((response) => {
      if (response?.data?.success) {
        addNotice({
          message: 'Rule was successfully updated.',
          type: 'success',
        });
        updateTable();
      }
      if (response?.data?.error) {
        console.error(response.data.error);
        addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
      setLoading(false);
    }).catch((error) => {
      if (error) {
        console.error(error);
        addNotice({
          message: error,
          type: 'error',
        });
      }
      setLoading(false);
    });
  }
  const setEnabled = (enabled = true) => {
    sendRequest({ enabled });
    return true;
  }
  const setDeleted = (deleted = true) => {
    sendRequest({ deleted });
    return true;
  }
  const setBrands = (option = 'all') => {
    let advertisers = [];
    if (option === 'all') {
      advertisers = brandOptions.map(({ value }) => value);
    }
    if (option === 'allButPilots') {
      advertisers = brandOptions.filter(item => item.isPilot === 'No').map(({ value }) => value);
    }
    if (option === 'allPilots') {
      advertisers = brandOptions.filter(item => item.isPilot === 'Yes').map(({ value }) => value);
    }
    sendRequest({ advertisers });
    return true;
  }

  const executeJob = () => {
    const identifier = record.params.identifier;
    console.log(`Job ${identifier} started`);
    setExecuting(true);
    axios.post(
      '/api/portal/jobs/execute',
      {
        identifier,
      },
    ).catch((error) => {
      console.log(error);
      setExecuting(false);
    }).then((response) => {
      if (response.data.success === true) {
        setExecuteStatus('Job execution succeed.');
        setExecuteUrl(`/resources/Log/actions/list?filters.jobIdentifier=${identifier}&filters.level=info`);
        setExecuteMessage('You can find its results in the latest ');
        setShowExecuteModal(true);
      } else if (response.data.jobIdentifier) {
        setExecuteStatus('Job execution failed.');
        setExecuteUrl(`/resources/Log/actions/list?filters.jobIdentifier=${identifier}&filters.level=error`);
        setExecuteMessage('Check the latest ');
        setShowExecuteModal(true);
      } else if (response.data.error) {
        setExecuteStatus('Job execution failed.');
        setExecuteUrl('/resources/Log/actions/list?filters.level=error');
        setExecuteMessage('Check the latest ');
        setShowExecuteModal(true);
      }
      setExecuting(false);
    });
  };

  const contextMenuOptions = [
    { label: 'Include All Brands', handler: () => setBrands('all'), disabled: loading || loadingBrandOptions },
    { label: 'Include All Brands - Exclude Pilots', handler: () => setBrands('allButPilots'), disabled: loading || loadingBrandOptions },
    { label: 'Include All Pilots Only', handler: () => setBrands('allPilots'), disabled: loading || loadingBrandOptions },
    { type: 'divider', label: 'divider1' },
    { label: 'Start Rule', handler: setEnabled, disabled: loading },
    { label: 'Pause Rule', handler: () => setEnabled(false), disabled: loading },
    {
      label: executing ? (
        <div
          className="spinner-border"
          style={
            {
              width: '12px',
              height: '12px',
              marginLeft: '3px',
              marginRight: '6px',
              color: '#646777',
            }
          }
        />
      ) : 'Execute Rule Immediately',
      handler: executeJob,
    },
    { type: 'divider', label: 'divider2' },
    { label: 'Delete Rule', handler: () => setShowDeleteRuleDialog(true), disabled: loading },
  ];

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />
      <Modal
        isOpen={showDeleteRuleDialog}
        toggle={() => setShowDeleteRuleDialog(false)}
        size="sm"
        style={{ minWidth: '385px' }}
      >
        <ModalHeader toggle={() => setShowDeleteRuleDialog(false)} tag="h4">
          Delete Content Review Rule
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this Content Review Rule?
        </ModalBody>
        <ButtonToolbar className="modal__footer">
          <Button color="primary" onClick={() => setDeleted(true)} disabled={loading}>Delete</Button>
          &nbsp;&nbsp;
          <Button color="secondary" onClick={() => setShowDeleteRuleDialog(false)}>Cancel</Button>

        </ButtonToolbar>
      </Modal>
      <ExecuteModal
        isOpen={showExecuteModal}
        status={executeStatus}
        url={executeUrl}
        message={executeMessage}
        close={() => setShowExecuteModal(false)}
      />
    </>
  );
};

ContextMenuInList.propTypes = {
  addNotice: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateTable: PropTypes.func.isRequired,
  loadingBrandOptions: PropTypes.bool.isRequired,
  brandOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withNotice(ContextMenuInList);
