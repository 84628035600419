import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../shared/components/CheckBox';

const Actions = React.memo(({
  searchValue,
  loadingCreators,
  selectedCreators,
  creatorOptions,
  loadingOffers,
  selectedOffers,
  offerOptions,
  loadingDomains,
  selectedDomains,
  domainOptions,
  statusFilters,
  showLegacy,
  setSearchValue,
  setSelectedCreators,
  setSelectedOffers,
  setSelectedDomains,
  setStatusFilters,
  setShowLegacy,
  history,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('vanityUrlsShowFilters') === 'true');

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      setSearchValue(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('vanityUrlsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const handleChangeStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setStatusFilters({
        ...statusFilters,
        [name]: checked,
      });
    }
  };

  const handleShowLegacyChange = (event) => {
    if (event && event.target) {
      const { checked } = event.target;
      setShowLegacy(checked);
    }
    return true;
  };

  const resetFilters = () => {
    setSearch('');
    setSearchValue('');
    setStatusFilters({
      Success: true, Error: true,
    });
    setSelectedCreators([]);
    setSelectedOffers([]);
    setSelectedDomains([]);
    setShowLegacy(true);
  };

  const selectItem = (value, type) => {
    switch (type) {
      case 'creator':
        setSelectedCreators(value);
        break;
      case 'offer':
        setSelectedOffers(value);
        break;
      case 'domain':
        setSelectedDomains(value);
        break;
      default:
        return false;
    }
    return true;
  };

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  return (
    <div>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search by Creator Name, Local Path, URL Path, Deal Owner Initials, Channel Name, Landing Page, Coupon Code"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => history.push('/resources/VanityUrl/actions/new')}
              >
                New Vanity URL
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => window.open(`/resources/VanityUrl/download-csv?${new URLSearchParams(window.location.search).toString()}`, '_blank').focus()}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  Creator
                </Label>
                <Select
                  isMulti
                  name="creatorSelect"
                  isDisabled={loadingCreators}
                  isLoading={loadingCreators}
                  isClearable
                  value={selectedCreators}
                  options={creatorOptions}
                  onChange={value => selectItem(value, 'creator')}
                  placeholder="Select Creator..."
                  style={{ maxHeight: '120px' }}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>
                  Offer
                </Label>
                <Select
                  isMulti
                  name="offerSelect"
                  isDisabled={loadingOffers}
                  isLoading={loadingOffers}
                  isClearable
                  value={selectedOffers}
                  options={offerOptions}
                  onChange={value => selectItem(value, 'offer')}
                  placeholder="Select Offer..."
                  style={{ maxHeight: '120px' }}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>
                  Domain
                </Label>
                <Select
                  isMulti
                  name="domainSelect"
                  isDisabled={loadingDomains}
                  isLoading={loadingDomains}
                  isClearable
                  value={selectedDomains}
                  options={domainOptions}
                  onChange={value => selectItem(value, 'domain')}
                  placeholder="Select Domain..."
                  style={{ maxHeight: '120px' }}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                URL Status
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="success"
                  label="Success"
                  value={statusFilters.Success}
                  onChange={event => handleChangeStatusFilters(event, 'Success')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="error"
                  label="Error"
                  value={statusFilters.Error}
                  onChange={event => handleChangeStatusFilters(event, 'Error')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <FormGroup>
                <CheckBoxField
                  name="showLegacy"
                  label="Show Legacy URLs"
                  value={showLegacy}
                  onChange={handleShowLegacyChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  a.loadingCreators === a1.loadingCreators &&
  _.isEqual(a.selectedCreators, a1.selectedCreators) &&
  _.isEqual(a.creatorOptions, a1.creatorOptions) &&
  a.loadingOffers === a1.loadingOffers &&
  _.isEqual(a.selectedOffers, a1.selectedOffers) &&
  _.isEqual(a.offerOptions, a1.offerOptions) &&
  a.loadingDomains === a1.loadingDomains &&
  _.isEqual(a.selectedDomains, a1.selectedDomains) &&
  _.isEqual(a.domainOptions, a1.domainOptions) &&
  _.isEqual(a.statusFilters, a1.statusFilters) &&
  a.showLegacy === a1.showLegacy);

Actions.propTypes = {
  searchValue: PropTypes.string.isRequired,
  loadingCreators: PropTypes.bool.isRequired,
  selectedCreators: PropTypes.arrayOf(PropTypes.any).isRequired,
  creatorOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingOffers: PropTypes.bool.isRequired,
  selectedOffers: PropTypes.arrayOf(PropTypes.any).isRequired,
  offerOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingDomains: PropTypes.bool.isRequired,
  selectedDomains: PropTypes.arrayOf(PropTypes.any).isRequired,
  domainOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  statusFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  showLegacy: PropTypes.bool.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  setSelectedCreators: PropTypes.func.isRequired,
  setSelectedOffers: PropTypes.func.isRequired,
  setSelectedDomains: PropTypes.func.isRequired,
  setStatusFilters: PropTypes.func.isRequired,
  setShowLegacy: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Actions;
