/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../../../shared/components/ButtonWithSpinner';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../../../App/store/with-notice';


export const modalId = 'publishInvoice';

const PublishInvoice = ({
  updateTable,
  addNotice,
}) => {
  const dispatch = useDispatch();
  const [loadingPublish, setLoadingPublish] = useState(false);
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  if (!modalInfo) return false;
  console.log(modalInfo);
  const { data: { record }, isShow } = modalInfo;
  const close = () => dispatch(hideModalById(modalId));
  const publishInsertionOrder = async () => {
    setLoadingPublish(true);
    try {
      const res = await axios({
        method: 'get',
        url: `/api/portal/prospects/get-create-file-by-invoice?id=${record?.id}`,
      });
      setLoadingPublish(false);
      if (res?.data?.success) {
        updateTable(res?.data?.file);
        addNotice({
          message: 'Invoice was generated sucessfully',
          type: 'erSuccessror',
        });
      } else if (res?.data?.error) {
        addNotice({
          message: `Invoice failed to publish: ${res.data.error}`,
          type: 'error',
        });
      }
      close();
    } catch (error) {
      console.error(error);
      addNotice({
        message: `Invoice failed to publish: ${error.message}`,
        type: 'error',
      });
      setLoadingPublish(false);
    }
  };

  if (!modalInfo) return false;
  const count = record.params.googleSheetFiles?.length || 0;
  const name = record?.params?.invoiceNumber || '';
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Publish Invoice
      </ModalHeader>
      <ModalBody>
        You are about to publish Invoice <strong>{ name.trim() }</strong> to Google Sheet File <strong>{`Invoice_${name.trim()}${count > 0 ? `(${count + 1})` : ''}`}</strong>
      </ModalBody>
      <ModalFooter>
        <ButtonWithSpinner
          className="btn-sm"
          type="button"
          color="primary"
          onClick={publishInsertionOrder}
          loading={loadingPublish}
          disabled={loadingPublish}
        >
          <span>Publish</span>
        </ButtonWithSpinner>{' '}
        <Button
          className="btn-sm"
          color="secondary"
          onClick={close}
          disabled={loadingPublish}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PublishInvoice.propTypes = {
  updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

PublishInvoice.defaultProps = {

};

export default withNotice(PublishInvoice);
