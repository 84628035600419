import React from 'react';
import PropTypes from 'prop-types';
import { formatIntNumber } from '../../../../shared/helpers/WVFormatter';

const Tooltip = ({
  active,
  payload,
  label,
  colors,
}) => {
  const getText = (name, value) => `${name}: ${formatIntNumber(value)}`;
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'hsla(0, 0%, 100%, .8)',
          border: '1px solid #f5f5f5',
          lineHeight: '24px',
          margin: '0',
          padding: '10px',
        }}
      >
        {typeof label === 'string' && (
          <p className="bold-text">{label}</p>
        )}
        <ul
          style={{
            listStyle: 'none',
            padding: '0',
            margin: '0',
          }}
        >
          {payload?.map(item => (
            <li
              key={item.name}
              style={{
                color: colors[item.dataKey],
              }}
            >
              {getText(item.name, item.value)}
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  colors: PropTypes.objectOf(PropTypes.any).isRequired,
};

Tooltip.defaultProps = {
  active: false,
  payload: [],
  label: '',
};

export default Tooltip;
