/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { Row, Col, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip as TooltipOnLine,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import ProgressBar from '../../../../../shared/helpers/ProgressBarWithObject';
import { formatIntNumber } from '../../../../../shared/helpers/WVFormatter';

const colorOptions = [
  '#e6194B',
  '#ffbb28',
  '#0088FE',
  '#911eb4',
  '#ff8042',
  '#13c450',
  '#d861b7',
];

const chartMargins = {
  top: 5,
  right: 0,
  left: 45,
  bottom: 40,
};

const CustomizedTooltip = (props) => {
  const getText = (name, value) => `${name}: ${formatIntNumber(value)}`;
  if (props.active && props.payload && props.payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'hsla(0, 0%, 100%, .8)',
          border: '1px solid #f5f5f5',
          lineHeight: '24px',
          margin: '0',
          padding: '10px',
        }}
      >
        <p className="bold-text">{props.label}</p>
        <ul
          style={{
            listStyle: 'none',
            padding: '0',
            margin: '0',
          }}
        >
          {props.payload?.map((item, index) => (
            <li
              key={item.name}
              style={{
                color: colorOptions[index],
              }}
            >
              {getText(item.name, item.value)}
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

const CustomizedXAxisTick = ({ x, y, payload }) => {
  const values = [16, 26, 40];
  const children = payload.value?.includes('-')
    ? payload.value.split(' ').map(((string, index) => (
      <text x={0} y={0} dy={values[index]} textAnchor="middle" fill="#666">
        {string}
      </text>
    ))) : (
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    );
  return (
    <g transform={`translate(${x},${y})`}>
      {children}
    </g>
  );
};

const CustomizedYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
      {formatIntNumber(payload.value)}
    </text>
  </g>
);

const ChartComponent = ({
  tableItems,
  loadingPeriodsData,
  periodsData,
  identityChart,
  chartType,
}) => {
  const [top7, setTop7] = useState([]);
  const [opacity, setOpacity] = useState({});
  const getCurrentChartData = () => {
    const chartData = [];
    if (!loadingPeriodsData && periodsData?.length > 0 && tableItems?.length > 0 && chartType) {
      const localTop7 = [...tableItems]
      // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => ((a[chartType] > b[chartType]) ? -1 : ((b[chartType] > a[chartType]) ? 1 : 0)))
        .slice(0, 7)
        .map(item => item.companyName);
      periodsData.forEach((periodsDataItem) => {
        const chartDataItem = { name: periodsDataItem.period };
        if (periodsDataItem.data?.length > 0) {
          localTop7.forEach((companyName) => {
            const brandData = periodsDataItem.data.find(dataItem => dataItem.companyName === companyName);
            if (brandData) {
              chartDataItem[companyName] = Number(brandData[chartType] || 0);
            }
          });
          chartData.push(chartDataItem);
        }
      });
    }
    return chartData;
  };
  const [currentChartData, setCurrentChartData] = useState(getCurrentChartData());

  useEffect(() => {
    setTop7([...tableItems]
    // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => ((a[chartType] > b[chartType]) ? -1 : ((b[chartType] > a[chartType]) ? 1 : 0)))
      .slice(0, 7)
      .map(item => item.companyName));
    setCurrentChartData(getCurrentChartData());
  }, [chartType, periodsData]);

  useEffect(() => {
    const localOpacity = {};
    // eslint-disable-next-line no-return-assign
    top7.forEach(companyName => localOpacity[companyName] = 1);
    setOpacity(localOpacity);
  }, [top7]);


  const handleMouseEnter = (dataKey) => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(companyName => localOpacity[companyName] = companyName === dataKey ? 1 : 0.2);
    setOpacity(localOpacity);
  };

  const handleMouseLeave = () => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(companyName => localOpacity[companyName] = 1);
    setOpacity(localOpacity);
  };

  return (
    <Row>
      <Col>
        {loadingPeriodsData ? (
          <div className="form-group mt-2">
            Loading Chart Data. Please wait, this might take a while.
            <ProgressBar
              afterFunction={() => true}
              topic="report"
              identity={identityChart}
              isShow={!!loadingPeriodsData}
            />
          </div>
        ) : (
          <>
            {currentChartData.length ? (
              <Row>
                <Col md={10} style={{ paddingRight: '0' }}>
                  <ResponsiveContainer
                    width="100%"
                    height={400}
                    debounce={1}
                  >
                    <LineChart
                      width="100%"
                      height="100%"
                      data={currentChartData}
                      margin={chartMargins}
                    >
                      {top7.map((companyName, index) => (
                        <Line
                          key={`line${companyName}${chartType}`}
                          type="monotone"
                          dataKey={companyName}
                          stroke={colorOptions[index]}
                          strokeOpacity={opacity[companyName] || 1}
                          strokeWidth={2}
                        />
                    ))}
                      <XAxis
                        dataKey="name"
                        padding={{ left: 8, right: 8 }}
                        tick={<CustomizedXAxisTick />}
                        interval="preserveStartEnd"
                      />
                      <YAxis
                        padding={{ top: 8, bottom: 8 }}
                        tick={<CustomizedYAxisTick />}
                        domain={['dataMin', 'dataMax']}
                        interval="preserveStartEnd"
                        allowDecimals={false}
                      />
                      <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                      <TooltipOnLine content={<CustomizedTooltip />} />
                    </LineChart>
                  </ResponsiveContainer>
                </Col>
                <Col
                  md={2}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div>
                    {tableItems?.length > 1 && (
                      <Label className="bold-text">
                        {tableItems?.length > 7 ? 'Top 7 Performers:' : 'Top Performers:'}
                      </Label>
                    )}
                    <ul
                      style={{
                        listStyle: 'none',
                        paddingLeft: '0',
                      }}
                    >
                      {top7.map((item, index) => (
                        <li
                          key={`legend${item}${chartType}`}
                          style={{
                            color: colorOptions[index],
                            cursor: 'pointer',
                            fontWeight: '600',
                          }}
                          onMouseEnter={() => handleMouseEnter(item)}
                          onMouseLeave={handleMouseLeave}
                        >
                          &#9903;  {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : (
              <span className="mt-2">No data to chart</span>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

ChartComponent.propTypes = {
  loadingPeriodsData: PropTypes.bool,
  periodsData: PropTypes.arrayOf(PropTypes.any),
  identityChart: PropTypes.string,
  tableItems: PropTypes.arrayOf(PropTypes.any),
  chartType: PropTypes.string.isRequired,
};

ChartComponent.defaultProps = {
  loadingPeriodsData: false,
  periodsData: [],
  identityChart: '',
  tableItems: [],
};

export default ChartComponent;
