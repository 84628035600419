import React from 'react';
import { Modal, Spinner, ButtonToolbar, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from 'mdi-react/DownloadIcon';

const StripeReportModal = ({
  modal,
  loading,
  closeDialog,
  log,
  downloadCSV,
  title,
  type,
}) => (
  <Modal
    isOpen={modal}
    toggle={closeDialog}
  >
    <div className="modal__header">
      <h4 className="text-modal  modal__title">{title}</h4>
    </div>
    <div className="modal__body finance-form">
      <ul className="text-left">
        {!loading ?
          (
            <li>Processed {log.length} payouts. Download Report for more details.
              {log.length > 0 ? (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV(type, 'logs')}><DownloadIcon size="20" /></IconButton>) : ''}
            </li>
          )
          : (<li>Processing ... <Spinner color="primary" /></li>)
        }
      </ul>
    </div>
    {!loading && (
    <ButtonToolbar className="modal__footer">
      <Button className="modal_cancel btn-sm" color="secondary" onClick={closeDialog}>Close</Button>
    </ButtonToolbar>
      )
    }
  </Modal>
);
StripeReportModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  log: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  downloadCSV: PropTypes.func.isRequired,

};
export default StripeReportModal;
