import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const getName = record => ((record.business_profile?.name ? record.business_profile.name : `${record.individual?.first_name || ''} ${record.individual?.last_name || ''}`.trim()) || null);

const NameComponent = ({
  record,
}) => {
  const [name, setName] = useState(getName(record));

  useEffect(() => {
    setName(getName(record))
  }, [record]);

  return (
    <div style={{ width: '180px' }}>
      {name || '-'}
    </div>
  );
}

NameComponent.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default NameComponent;
