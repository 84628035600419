import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  FormGroup,
} from 'reactstrap';
import CheckBoxField from "../../../../../../shared/components/CheckBox";
import hook from '../../../../../../shared/hooks';

const UpdateHumanSentimentAndRelevancy = ({
  isOpen,
  closeFunc,
  setVersion,
  commentIds,
}) => {
  const [loading, setLoading] = useState(false);
  const [updateHumanCheck, setUpdateHumanCheck] = useState({ sentiment: false, brandRelevancy: false });
  const { showError, showSuccess } = hook.useNoticeHook();

  useEffect(() => {
    if (!isOpen) {
      setUpdateHumanCheck({ sentiment: false, brandRelevancy: false });
    }
  }, [isOpen]);

  const handleChangeHumanCheck = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setUpdateHumanCheck({
        ...updateHumanCheck,
        [name]: checked,
      });
    }
  };

  const submit = async () => {
    setLoading(true);
    axios({
      method: 'post',
      url: '/api/portal/comments/post-bulk-update-human-sentiment',
      data: {
        commentIds,
        updateHumanCheck,
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        closeFunc();
        showSuccess('Update was successfully completed.');
      }
      if (response.data.error) {
        setLoading(false);
        showError(response.data.error);
      }
    });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '770px' }}
      className=" theme-light"
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Update Sentiment Relevancy</ModalHeader>
      <ModalBody>
        {commentIds.length && (
          <Row>
            <Col xs="3" className="text-center mt-auto mb-auto" style={{ color: '#70bbfd' }}> <i
              className="fa fa-question"
              style={{ fontSize: '5em' }}
            />
            </Col>
            <Col xs={9}>
              <div>You have selected <strong>{commentIds.length}</strong> comment{commentIds.length !== 1 ? 's' : ''} to update.</div>
              <div className="theme-light radio-in-prospects mt-2">
                Would you like to update the associated <strong>Human</strong> values with <br /> their
                corresponding <strong>AI</strong> values?&nbsp;&nbsp;&nbsp;
              </div>
              <Row className="mt-3">
                <Col>
                  <FormGroup>
                    <CheckBoxField
                      name="humanSentiment"
                      label="Yes, update <strong>Human</strong> Sentiment"
                      className="inline"
                      value={updateHumanCheck.sentiment}
                      onChange={event => handleChangeHumanCheck(event, 'sentiment')}
                    />
                    <CheckBoxField
                      name="humanBrandRelevancy"
                      label="Yes, update <strong>Human</strong> Brand Relevancy"
                      className="inline ml-4"
                      value={updateHumanCheck.brandRelevancy}
                      onChange={event => handleChangeHumanCheck(event, 'brandRelevancy')}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading || (!updateHumanCheck.sentiment && !updateHumanCheck.brandRelevancy)}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm"/>  </span>}
          <span>Update</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

UpdateHumanSentimentAndRelevancy.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  commentIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
};

export default UpdateHumanSentimentAndRelevancy;
