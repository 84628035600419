import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import withNotice from '../../../../../App/store/with-notice';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import Table from './custom/Table';
import ScheduledTable from './custom/ScheduledTable';
import { getAdminUsers } from '../../../../../../redux/reducers/admin/ResearchReducer';
import Actions from './custom/Actions';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';

const prospectUploadSources = {
  'Creator Search': true,
  'Internal Request': true,
  'Upload CSV': true,
};

const getFilterValueInitial = (filterType) => {
  let initialFilterType = '<=';
  const type = new URLSearchParams(window.location.search).get(filterType);
  if (type) {
    initialFilterType = type;
  }
  return initialFilterType;
};

const getSingleContentReleaseDateInitial = () => {
  let singleDate = null;
  const filters = parseUrl();
  const type = new URLSearchParams(window.location.search).get('startUploadDateType');
  if (type && type !== 'Between') {
    if (type === '>' || type === '>=' || type === '=') {
      if (filters?.['upload_start_date~~from']) {
        singleDate = new Date(filters['upload_start_date~~from']);
      }
    }
    if (type === '<' || type === '<=') {
      singleDate = new Date(filters['upload_start_date~~to']);
    }
  }
  return singleDate;
};

const getSingleSuccessPercentInitial = () => {
  let singleSuccessPercent = null;
  const filters = parseUrl();
  const type = new URLSearchParams(window.location.search).get('successPercentType');
  if (type && type !== 'Between') {
    if (filters?.['successPercent~~min']) {
      singleSuccessPercent = filters?.['successPercent~~min'];
      if (type === '>=' || type === '=') {
        singleSuccessPercent = filters?.['successPercent~~min'];
      }
      if (type === '>') {
        singleSuccessPercent = Number(filters?.['successPercent~~min']);
      }
    } else {
      if (type === '<') {
        singleSuccessPercent = Number(filters?.['successPercent~~max']);
      }
      if (type === '<=') {
        singleSuccessPercent = filters?.['successPercent~~max'];
      }
    }
  }
  return parseInt(singleSuccessPercent, 10);
};

const getSingleDuplicatePercentInitial = () => {
  let singleDuplicatePercent = null;
  const filters = parseUrl();
  const type = new URLSearchParams(window.location.search).get('duplicatePercentType');
  if (type && type !== 'Between') {
    if (filters?.['duplicatePercent~~min']) {
      if (type === '>=' || type === '=') {
        singleDuplicatePercent = filters?.['duplicatePercent~~min'];
      }
      if (type === '>') {
        singleDuplicatePercent = Number(filters?.['duplicatePercent~~min']);
      }
    } else {
      if (type === '<') {
        singleDuplicatePercent = Number(filters?.['duplicatePercent~~max']);
      }
      if (type === '<=') {
        singleDuplicatePercent = filters?.['duplicatePercent~~max'];
      }
    }
  }
  return parseInt(singleDuplicatePercent, 10);
};

const UploadQueueListContainer = ({
  action,
  resource,
  adminUsers,
  dispatch,
  history,
}) => {
  const [activeTab, setActiveTab] = useState('main');
  const [version, setVersion] = useState((new Date()).toString());
  const [uploadTypeFilter, setUploadTypeFilter] = useState(prospectUploadSources);
  const [uploadOwnerFilter, setUploadOwnerFilter] = useState([]);
  const [uploadStartTimeFilter, setUploadStartTimeFilter] = useState('');
  const [enableUploadStartFilter, setEnableUploadStartFilter] = useState(false);
  const [singleUploadStartDate, setSingleUploadStartDate] = useState(getSingleContentReleaseDateInitial());
  const filters = parseUrl();
  const [nameFilter, setNameFilter] = useState(filters.fullSearchName || '');
  const startUploadDateType = getFilterValueInitial('startUploadDateType');
  const [startUploadDateFrom, setStartUploadDateFrom] = useState(filters?.['upload_start_date~~from'] && startUploadDateType === 'Between' ? new Date(filters['upload_start_date~~from']) : null);
  const [startUploadDateTo, setStartUploadDateTo] = useState(filters?.['upload_start_date~~to'] && startUploadDateType === 'Between' ? new Date(filters['upload_start_date~~to']) : null);
  const [uploadStartDateValue, setUploadStartDateValue] = useState({ value: startUploadDateType, label: startUploadDateType });
  const successPercentType = getFilterValueInitial('successPercentType');
  const [successPercentMin, setSuccessPercentMin] = useState(filters?.['successPercent~~min'] && successPercentType === 'Between' ? parseFloat(filters['successPercent~~min']) : null);
  const [successPercentMax, setSuccessPercentMax] = useState(filters?.['successPercent~~max'] && successPercentType === 'Between' ? parseFloat(filters['successPercent~~max']) : null);
  const [singleSuccessPercent, setSingleSuccessPercent] = useState(getSingleSuccessPercentInitial());
  const [enableSuccessPercent, setEnableSuccessPercent] = useState(false);
  const [successPercentValue, setSuccessPercentValue] = useState({ value: successPercentType, label: successPercentType });

  const duplicatePercentType = getFilterValueInitial('duplicatePercentType');
  const [duplicatePercentMin, setDuplicatePercentMin] = useState(filters?.['duplicatePercent~~min'] && duplicatePercentType === 'Between' ? parseFloat(filters['duplicatePercent~~min']) : null);
  const [duplicatePercentMax, setDuplicatePercentMax] = useState(filters?.['duplicatePercent~~max'] && duplicatePercentType === 'Between' ? parseFloat(filters['duplicatePercent~~max']) : null);
  const [singleDuplicatePercent, setSingleDuplicatePercent] = useState(getSingleDuplicatePercentInitial());
  const [enableDuplicatePercent, setEnableDuplicatePercent] = useState(false);
  const [duplicatePercentValue, setDuplicatePercentValue] = useState({ value: duplicatePercentType, label: duplicatePercentType });

  useEffect(() => {
    if (adminUsers.length < 1) {
      dispatch(getAdminUsers(false));
    }
    const search = parseUrl();

    if (search?.prospectUploadType) {
      const tempProspectUploadSources = Object.assign({}, prospectUploadSources);
      Object.keys(prospectUploadSources).forEach((key) => {
        tempProspectUploadSources[key] = search?.prospectUploadType.includes(key);
      });
      setUploadTypeFilter(tempProspectUploadSources);
    }
    if (search?.uploadOwner && uploadOwnerFilter.length === 0 && adminUsers.length > 0) {
      const owners = [];
      if (Array.isArray(search.uploadOwner)) {
        for (let i = 0; i < search.uploadOwner.length; i += 1) {
          const user = adminUsers.find(a => a.value === search.uploadOwner[i]);
          owners.push({ id: user.value, name: user.label });
        }
      } else {
        const user = adminUsers.find(a => a.value === search.uploadOwner);
        owners.push({ id: user.value, name: user.label });
      }
      setUploadOwnerFilter(owners);
    }

    if (search?.successPercentType) {
      setEnableSuccessPercent(true);
    }
    if (search['successPercent~~min']) {
      setSuccessPercentMin(Number(search['successPercent~~min']));
    }
    if (search['successPercent~~max']) {
      setSuccessPercentMax(Number(search['successPercent~~max']));
    }
  }, [adminUsers]);

  const setUrl = () => {
    const search = new URLSearchParams(window.location.search);

    search.delete('filters.fullSearchName');
    if (nameFilter && nameFilter.length > 0) {
      search.set('filters.fullSearchName', nameFilter);
    }
    search.set('customFilters.type', activeTab);
    search.set('page', '1');
    if (uploadTypeFilter && Object.keys(uploadTypeFilter).length > 0) {
      search.delete('filters.prospectUploadType');
      Object.keys(uploadTypeFilter).forEach((key) => {
        if (!uploadTypeFilter[key]) return false;
        search.append('filters.prospectUploadType', key);
        return true;
      });
    }

    if (uploadOwnerFilter.length > 0) {
      search.delete('filters.uploadOwner');
      uploadOwnerFilter.forEach((owner) => {
        search.append('filters.uploadOwner', owner.id || owner);
      });
    }
    if (uploadOwnerFilter.length === 0) {
      search.delete('filters.uploadOwner');
    }

    search.delete('filters.successPercent~~min');
    search.delete('filters.successPercent~~max');
    search.delete('successPercentType');

    if (successPercentMin && successPercentMax && successPercentValue.value === 'Between') {
      search.append('filters.successPercent~~min', successPercentMin);
      search.append('filters.successPercent~~max', successPercentMax);
    }
    if (singleSuccessPercent && singleSuccessPercent !== '') {
      if (successPercentValue.value === '>') {
        search.append('filters.successPercent~~min', Number(singleSuccessPercent) + 1);
      }
      if (successPercentValue.value === '>=') {
        search.append('filters.successPercent~~min', singleSuccessPercent);
      }
      if (successPercentValue.value === '<') {
        search.append('filters.successPercent~~max', Number(singleSuccessPercent) - 1);
      }
      if (successPercentValue.value === '<=') {
        search.append('filters.successPercent~~max', singleSuccessPercent);
      }
      if (successPercentValue.value === '=') {
        search.append('filters.successPercent~~min', singleSuccessPercent);
        search.append('filters.successPercent~~max', singleSuccessPercent);
      }
    }
    if (successPercentValue.value && ((successPercentMin && successPercentMax) || singleSuccessPercent)) {
      setEnableSuccessPercent(true);
      search.append('successPercentType', successPercentValue.value);
    }

    search.delete('filters.duplicatePercent~~min');
    search.delete('filters.duplicatePercent~~max');
    search.delete('duplicatePercentType');

    if (duplicatePercentMin && duplicatePercentMax && duplicatePercentValue.value === 'Between') {
      search.append('filters.duplicatePercent~~min', duplicatePercentMin);
      search.append('filters.duplicatePercent~~max', duplicatePercentMax);
    }
    if (singleDuplicatePercent && singleDuplicatePercent !== '') {
      if (duplicatePercentValue.value === '>') {
        search.append('filters.duplicatePercent~~min', Number(singleDuplicatePercent) + 1);
      }
      if (duplicatePercentValue.value === '>=') {
        search.append('filters.duplicatePercent~~min', singleDuplicatePercent);
      }
      if (duplicatePercentValue.value === '<') {
        search.append('filters.duplicatePercent~~max', Number(singleDuplicatePercent) - 1);
      }
      if (duplicatePercentValue.value === '<=') {
        search.append('filters.duplicatePercent~~max', singleDuplicatePercent);
      }
      if (duplicatePercentValue.value === '=') {
        search.append('filters.duplicatePercent~~min', singleDuplicatePercent);
        search.append('filters.duplicatePercent~~max', singleDuplicatePercent);
      }
    }
    if (duplicatePercentValue.value && ((duplicatePercentMin && duplicatePercentMax) || singleDuplicatePercent)) {
      setEnableDuplicatePercent(true);
      search.append('duplicatePercentType', duplicatePercentValue.value);
    }

    search.delete('filters.upload_start_date~~from');
    search.delete('filters.upload_start_date~~to');
    search.delete('startUploadDateType');
    if (startUploadDateFrom && startUploadDateTo && uploadStartDateValue.value === 'Between') {
      if (startUploadDateFrom < startUploadDateTo) {
        search.append('filters.upload_start_date~~from', startUploadDateFrom.toISOString());
        search.append('filters.upload_start_date~~to', startUploadDateTo.toISOString());
      } else { // switch positions of interval ends
        search.append('filters.upload_start_date~~from', startUploadDateTo.toISOString());
        search.append('filters.upload_start_date~~to', startUploadDateFrom.toISOString());
      }
    }
    if (singleUploadStartDate && singleUploadStartDate !== '') {
      if (uploadStartDateValue.value === '>' || uploadStartDateValue.value === '>=') {
        search.append('filters.upload_start_date~~from', singleUploadStartDate.toISOString());
      }
      if (uploadStartDateValue.value === '<' || uploadStartDateValue.value === '<=') {
        search.append('filters.upload_start_date~~to', singleUploadStartDate.toISOString());
      }
      if (uploadStartDateValue.value === '=') {
        search.append('filters.upload_start_date~~from', singleUploadStartDate.toISOString());
        const nextDay = new Date(singleUploadStartDate);
        nextDay.setDate(singleUploadStartDate.getDate() + 1);
        search.append('filters.upload_start_date~~to', nextDay.toISOString());
      }
    }
    if (uploadStartDateValue.value && ((startUploadDateTo && startUploadDateFrom) || singleUploadStartDate)) {
      setEnableUploadStartFilter(true);
      search.append('startUploadDateType', uploadStartDateValue.value);
    }
    history.push(`${history.location.pathname}?${search.toString()}`);
  };
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 700);

  useEffect(() => {
    setUrlDebounced();
  }, [
    nameFilter,
    uploadTypeFilter,
    uploadOwnerFilter,
    uploadStartTimeFilter,
    enableUploadStartFilter,
    singleUploadStartDate,
    startUploadDateTo,
    startUploadDateFrom,
    uploadStartDateValue,
    enableSuccessPercent,
    singleSuccessPercent,
    successPercentMin,
    successPercentMax,
    successPercentValue,
    enableDuplicatePercent,
    duplicatePercentMin,
    duplicatePercentMax,
    singleDuplicatePercent,
    duplicatePercentValue,
  ]);

  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Prospect Uploads', path: false },
        ]}
        isBackButton
      />
      <Card>
        <CardBody className="leads-recycle-dashboard">
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Prospect Uploads</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          { (adminUsers && adminUsers.length > 0) &&
            <Actions
              onSearchChange={setNameFilter}
              nameFilter={nameFilter}
              uploadTypeFilter={uploadTypeFilter}
              setUploadTypeFilter={setUploadTypeFilter}
              adminUsers={adminUsers.map(user => ({ id: user.value, name: user.label }))}
              setUploadOwnerFilter={setUploadOwnerFilter}
              uploadOwnerFilter={uploadOwnerFilter}
              uploadStartTimeFilter={uploadStartTimeFilter}
              setUploadStartTimeFilter={setUploadStartTimeFilter}
              uploadStartDateValue={uploadStartDateValue}
              setUploadStartDateValue={setUploadStartDateValue}
              enableUploadStartFilter={enableUploadStartFilter}
              setEnableUploadStartFilter={setEnableUploadStartFilter}
              startUploadDateFrom={startUploadDateFrom}
              setStartUploadDateFrom={setStartUploadDateFrom}
              startUploadDateTo={startUploadDateTo}
              setStartUploadDateTo={setStartUploadDateTo}
              singleUploadStartDate={singleUploadStartDate}
              setSingleUploadStartDate={setSingleUploadStartDate}
              successPercentValue={successPercentValue}
              setSuccessPercentValue={setSuccessPercentValue}
              enableSuccessPercent={enableSuccessPercent}
              setEnableSuccessPercent={setEnableSuccessPercent}
              successPercentMin={successPercentMin}
              setSuccessPercentMin={setSuccessPercentMin}
              successPercentMax={successPercentMax}
              setSuccessPercentMax={setSuccessPercentMax}
              singleSuccessPercent={singleSuccessPercent}
              setSingleSuccessPercent={setSingleSuccessPercent}
              enableDuplicatePercent={enableDuplicatePercent}
              duplicatePercentMin={duplicatePercentMin}
              duplicatePercentMax={duplicatePercentMax}
              singleDuplicatePercent={singleDuplicatePercent}
              duplicatePercentValue={duplicatePercentValue}
              setEnableDuplicatePercent={setEnableDuplicatePercent}
              setDuplicatePercentMin={setDuplicatePercentMin}
              setDuplicatePercentMax={setDuplicatePercentMax}
              setSingleDuplicatePercent={setSingleDuplicatePercent}
              setDuplicatePercentValue={setDuplicatePercentValue}
            />
          }
          <Row>
            <Col className="tabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'main' })}
                    onClick={() => {
                      setActiveTab('main');
                      history.push('/research/upload-queue?customFilters.uploadProspects=true&page=1&customFilters.type=main');
                      // setCookie('LeadsActiveTab', 'leads', 2);
                    }}
                  >
                    Uploads
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'scheduled' })}
                    onClick={() => {
                      setActiveTab('scheduled');
                      history.push('/research/upload-queue?customFilters.uploadProspects=true&page=1&customFilters.type=scheduled');
                      // setCookie('LeadsActiveTab', 'reports', 2);
                    }}
                  >
                    Scheduled Uploads
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="main">
              {adminUsers.length > 0 && activeTab === 'main' && (
                <Table
                  action={action}
                  resource={resource}
                  version={version}
                  adminUsers={adminUsers}
                  setVersion={setVersion}
                />
              )}
            </TabPane>
            <TabPane tabId="scheduled">
              {adminUsers.length > 0 && activeTab === 'scheduled' && (
                <ScheduledTable
                  action={action}
                  resource={resource}
                  version={version}
                  adminUsers={adminUsers}
                  setVersion={setVersion}
                />
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Container>
  );
};

UploadQueueListContainer.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  // addNotice: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};
const mapStateToPros = state => ({
  adminUsers: state.ResearchReducer.adminUsers,
});

export default withNotice(connect(mapStateToPros)(withRouter(UploadQueueListContainer)));
