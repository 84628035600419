import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup,
  Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, UncontrolledButtonDropdown,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { axios } from 'ApiClient';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import CalendarIcon from 'mdi-react/CalendarIcon';
import { NumericFormat as NumberFormat } from 'react-number-format';
import withNotice from '../../../../../App/store/with-notice';
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import hooks from '../../../../../../shared/hooks';
import csvHelper from '../../../../../../shared/helpers/CSVHelper';
import { formatCurrency, getServerDate, formatDate } from '../../../../../../shared/helpers/WVFormatter';
import BothYesNoComponent from './BothYesNoComponent';
import { numberRangeLabels } from '../../../../../../shared/helpers/Dates';


const defaultDealTypeFilters = {
  'CPA/Ambassador': true,
  'Upfront Only': true,
  'Upfront CPM': true,
  Hybrid: true,
  Bonus: true,
};

const numberRangeFilters = numberRangeLabels();

const getStatus = (d) => {
  if (d.approvalStatus?.brand?.status === 'Approved' && d.approvalStatus?.influencelogic?.status === 'Approved') {
    return 'Approved';
  } else if (d.approvalStatus?.brand?.status === 'Rejected' && d.approvalStatus?.influencelogic?.status === 'Resubmit') {
    return 'In Review';
  } else if (d.approvalStatus?.brand?.status === 'Rejected' || d.approvalStatus?.influencelogic?.status === 'Rejected') {
    return 'Rejected';
  }
  return 'In Review';
};

const DatePickerCustomInput = ({ value, onClick, onChange, disabled }) => (
  <div className="form-group">
    <InputGroup size="md">
      <input
        type="text"
        className="form-control"
        value={value}
        onClick={onClick}
        onChange={onChange}
        style={{
          border: '1px solid #ced4da',
          borderRadius: '5px',
        }}
        disabled={disabled}
      />
      <InputGroupAddon
        addonType="append"
        style={{
          padding: '7px 0 7px 7px',
        }}
      >
        <CalendarIcon
          className="date-picker-icon"
          onClick={onClick}
        />
      </InputGroupAddon>
    </InputGroup>
  </div>
);



const Filters = ({ allItems, setFilteredItems, addNotice, filteredItems }) => {
  const dealstages = ((getConstant('deal', 'pipelines') || []).find(p => p.label.toLowerCase() === 'main pipeline'))?.stages;
  const pipelines = getConstant('deal', 'pipelines', []);
  const { stages } = pipelines.find(item => item.label.toLowerCase().includes('main pipeline')) || {};
  const stageOptions = Object.entries(stages).map(([key, val]) => ({ value: key, label: val }))
    .filter(a => ['contract signed', 'published', 'deal paid', 'content in review'].includes(a.label.toLowerCase()));

  const [search, setSearch] = useState(() => {
    const saved = localStorage.getItem('pac-search');
    return saved ? JSON.parse(saved) : '';
  });
  const [loadingFilterOptions, setLoadingFilterOptions] = useState(false);
  const [selectedDealOwners, setSelectedDealOwners] = useState(() => {
    const saved = localStorage.getItem('pac-selectedDealOwners');
    return saved ? JSON.parse(saved) : [];
  });
  const [selectedDealStages, setSelectedDealStages] = useState(() => {
    const saved = localStorage.getItem('pac-selectedDealStages');
    return saved ? JSON.parse(saved) : stageOptions;
  });
  const [showFilters, setShowFilters] = useState(() => {
    const saved = localStorage.getItem('pac-showFilters');
    return saved ? JSON.parse(saved) : false;
  });
  const [enableAmountDueFilter, setEnableAmountDueFilter] = useState(() => {
    const saved = localStorage.getItem('pac-enableAmountDueFilter');
    return saved ? JSON.parse(saved) : true;
  });
  const [enableCRDFilter, setEnableCRDFilter] = useState(() => {
    const saved = localStorage.getItem('pac-enableCRDFilter');
    return saved ? JSON.parse(saved) : true;
  });
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState(() => {
    const saved = localStorage.getItem('pac-selectedBrands');
    return saved ? JSON.parse(saved) : [];
  });
  const [dealOwnersOptions, setDealOwnersOptions] = useState([]);
  const [dealTypeFilters, setDealTypeFilters] = useState(() => {
    const saved = localStorage.getItem('pac-dealTypeFilters');
    return saved ? JSON.parse(saved) : defaultDealTypeFilters;
  });
  const [isAuthorizedFilter, setIsAuthorizedFilter] = useState(() => {
    const saved = localStorage.getItem('pac-isAuthorizedFilter');
    return saved ? JSON.parse(saved) : { both: false, yes: false, no: true };
  });
  const [isCreatorBrandApprovedFilter, setIsCreatorBrandApprovedFilter] = useState(() => {
    const saved = localStorage.getItem('pac-isCreatorBrandApprovedFilter');
    return saved ? JSON.parse(saved) : { both: true, yes: false, no: false };
  });
  const [isContractSignedFilter, setIsContractSignedFilter] = useState(() => {
    const saved = localStorage.getItem('pac-isContractSignedFilter');
    return saved ? JSON.parse(saved) : { both: true, yes: false, no: false };
  });
  const [isContentBrandApprovedFilter, setIsContentBrandApprovedFilter] = useState(() => {
    const saved = localStorage.getItem('pac-isContentBrandApprovedFilter');
    return saved ? JSON.parse(saved) : { both: true, yes: false, no: false };
  });
  const [isContentUrlValidFilter, setIsContentUrlValidFilter] = useState(() => {
    const saved = localStorage.getItem('pac-isContentUrlValidFilter');
    return saved ? JSON.parse(saved) : { both: true, yes: false, no: false };
  });
  const [isPublishedFilter, setIsPublishedFilter] = useState(() => {
    const saved = localStorage.getItem('pac-isPublishedFilter');
    return saved ? JSON.parse(saved) : { both: true, yes: false, no: false };
  });
  const [isTaxValidFilter, setIsTaxValidFilter] = useState(() => {
    const saved = localStorage.getItem('pac-isTaxValidFilter');
    return saved ? JSON.parse(saved) : { both: true, yes: false, no: false };
  });
  const [amountDueFilterType, setAmountDueFilterType] = useState(() => {
    const saved = localStorage.getItem('pac-amountDueFilterType');
    return saved ? JSON.parse(saved) : numberRangeFilters[1];
  });
  const [amountDueFrom, setAmountDueFrom] = useState(() => {
    const saved = localStorage.getItem('pac-amountDueFrom');
    console.log('amountDueFrom', saved);
    return saved ? Number(saved) : '';
  });
  const [amountDueTo, setAmountDueTo] = useState(() => {
    const saved = localStorage.getItem('pac-amountDueTo');
    return saved ? Number(saved) : '';
  });
  const [singleAmountDue, setSingleAmountDue] = useState(() => {
    const saved = localStorage.getItem('pac-singleAmountDue');
    return saved ? Number(saved) : 0;
  });
  const [crdDate, setCDRDate] = useState(() => {
    const saved = localStorage.getItem('pac-crdDate');
    const savedCrdDate = saved ? moment(saved).toDate() : null;
    return savedCrdDate ?  savedCrdDate : moment().add(60, 'days').startOf('day').toDate();
  });

  const [showModal] = hooks.useShowModalHook('refreshFilteredDeals');

  const processContent = () => {
    console.log('Filtering');
    if (allItems.length > 0) {
      let items = [...allItems];
      if (search) {
        let parsedSearch = search;
        if (search.includes('//')) {
          // eslint-disable-next-line prefer-destructuring
          parsedSearch = search.split('//')[1];
        }
        if (parsedSearch && parsedSearch.includes('www.')) {
          // eslint-disable-next-line prefer-destructuring
          parsedSearch = parsedSearch.split('www.')[1];
        }
        items = items.filter(item => item.fullSearchName?.toLowerCase().includes(parsedSearch.toLowerCase()));
      }
      if (selectedBrands && selectedBrands.length > 0) {
        items = items.filter(item => (
          selectedBrands.findIndex(element => element.label === item.dealTerms.brand) !== -1
        ));
      }
      if (selectedDealOwners && selectedDealOwners.length > 0) {
        items = items.filter((item) => {
          if (item.adminUserObject?.email) {
            return (
              selectedDealOwners.findIndex(element => element.value === item.adminUserObject.email) !== -1
            );
          }
          return false;
        });
      }
      if (selectedDealStages && selectedDealStages.length > 0) {
        items = items.filter((item) => {
          if (item.dealstage) {
            return (
              selectedDealStages.findIndex(element => element.value === item.dealstage) !== -1
            );
          }
          return false;
        });
      }
      items = items.filter(item => (
        dealTypeFilters[item.dealTerms.type_of_deal]
      ));
      items = items.filter((item) => {
        if (item.authorizedPayment) {
          if (isAuthorizedFilter.yes) {
            return item.authorizedPayment.summaryValidation;
          }
          if (isAuthorizedFilter.no) {
            return !item.authorizedPayment.summaryValidation;
          }
          return true;
        }
        return false;
      });
      // check list filter
      items = items.filter((item) => {
        if (item.authorizedPayment) {
          if (isCreatorBrandApprovedFilter.yes) {
            return item.authorizedPayment.creatorBrandApproved;
          }
          if (isCreatorBrandApprovedFilter.no) {
            return !item.authorizedPayment.creatorBrandApproved;
          }
          return true;
        }
        return false;
      });
      items = items.filter((item) => {
        if (item.authorizedPayment) {
          if (isContractSignedFilter.yes) {
            return item.authorizedPayment.contractSigned;
          }
          if (isContractSignedFilter.no) {
            return !item.authorizedPayment.contractSigned;
          }
          return true;
        }
        return false;
      });
      items = items.filter((item) => {
        if (item.authorizedPayment) {
          if (isContentBrandApprovedFilter.yes) {
            return item.authorizedPayment.contentBrandApproved;
          }
          if (isContentBrandApprovedFilter.no) {
            return !item.authorizedPayment.contentBrandApproved;
          }
          return true;
        }
        return false;
      });
      items = items.filter((item) => {
        if (item.authorizedPayment) {
          if (isContentUrlValidFilter.yes) {
            return item.authorizedPayment.contentUrlValid;
          }
          if (isContentUrlValidFilter.no) {
            return !item.authorizedPayment.contentUrlValid;
          }
          return true;
        }
        return false;
      });
      items = items.filter((item) => {
        if (item.authorizedPayment) {
          if (isPublishedFilter.yes) {
            return item.authorizedPayment.contentPublicAndPublished;
          }
          if (isPublishedFilter.no) {
            return !item.authorizedPayment.contentPublicAndPublished;
          }
          return true;
        }
        return false;
      });
      items = items.filter((item) => {
        if (item.authorizedPayment) {
          if (isTaxValidFilter.yes) {
            return item.payment.taxValid;
          }
          if (isTaxValidFilter.no) {
            return !item.payment.taxValid;
          }
          return true;
        }
        return false;
      });
      if (enableAmountDueFilter && (singleAmountDue >= 0 || (amountDueFrom >= 0 && amountDueTo))) {
        items = items.filter((item) => {
          if (singleAmountDue >= 0) {
            if (amountDueFilterType.value === '=') {
              return (item.bill?.amountDue !== null && item.bill?.amountDue !== undefined && Number(item.bill.amountDue) === singleAmountDue)
                || (item.payable?.ilPayoutAmount !== null && item.payable?.ilPayoutAmount !== undefined && Number(item.payable.ilPayoutAmount) === singleAmountDue);
            }
            if (amountDueFilterType.value === '>') {
              return (item.bill?.amountDue !== null && item.bill?.amountDue !== undefined && Number(item.bill.amountDue) > singleAmountDue)
                || (item.payable?.ilPayoutAmount !== null && item.payable?.ilPayoutAmount !== undefined && Number(item.payable.ilPayoutAmount) > singleAmountDue);
            }
            if (amountDueFilterType.value === '>=') {
              return (item.bill?.amountDue !== null && item.bill?.amountDue !== undefined && Number(item.bill.amountDue) >= singleAmountDue)
                || (item.payable?.ilPayoutAmount !== null && item.payable?.ilPayoutAmount !== undefined && Number(item.payable.ilPayoutAmount) >= singleAmountDue);
            }
            if (amountDueFilterType.value === '<') {
              return (item.bill?.amountDue !== null && item.bill?.amountDue !== undefined && Number(item.bill.amountDue) < singleAmountDue)
                || (item.payable?.ilPayoutAmount !== null && item.payable?.ilPayoutAmount !== undefined && Number(item.payable.ilPayoutAmount) < singleAmountDue);
            }
            if (amountDueFilterType.value === '<=') {
              return (item.bill?.amountDue !== null && item.bill?.amountDue !== undefined && Number(item.bill.amountDue) <= singleAmountDue)
                || (item.payable?.ilPayoutAmount !== null && item.payable?.ilPayoutAmount !== undefined && Number(item.payable.ilPayoutAmount) <= singleAmountDue);
            }
          }
          if (amountDueFrom >= 0 && amountDueTo && amountDueFilterType.value === 'Between') {
            return (item.bill?.amountDue !== null && item.bill?.amountDue !== undefined && Number(item.bill.amountDue) > amountDueFrom && Number(item.bill.amountDue) < amountDueTo)
              || (item.payable?.ilPayoutAmount !== null && item.payable?.ilPayoutAmount !== undefined && Number(item.payable.ilPayoutAmount) > amountDueFrom && Number(item.payable.ilPayoutAmount) < amountDueTo);
          }
          return true;
        });
        if (enableCRDFilter && crdDate) {
          const crd = moment.utc(moment(crdDate).format('YYYY-MM-DD'))
          items = items.filter((item) => {
            if(item.dealTerms.content_release_date && moment.utc(item.dealTerms.content_release_date) >= crd) return false
            return true;
          });
        }
      }

      setFilteredItems(items);
    } else {
      setFilteredItems(allItems);
    }
  };

  useEffect(() => {
    setLoadingFilterOptions(true);
    axios.get('/api/portal/finance/get-advertisers').then((resp) => {
      setBrandsOptions(resp.data.advertisers
        .sort((a, b) => (a.companyName > b.companyName ? 1 : -1))
        .map(a => ({ value: a._id, label: a.companyName })));
    }).catch(() => {
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
    axios.get('/api/portal/finance/get-deal-owners').then((resp) => {
      setDealOwnersOptions(resp.data.dealOwners
        .map(a => ({ value: a.email, label: `${a.firstName || ''} ${a.lastName || ''}` })));
      setLoadingFilterOptions(false);
    }).catch(() => {
      setLoadingFilterOptions(false);
      addNotice({
        message: 'There was an error when try load deal owners, Check out console to see more information.',
        type: 'error',
      });
    });
    // itemsForDownload = allItems;
  }, []);

  useEffect(() => {
    processContent();
    localStorage.setItem('pac-selectedBrands', JSON.stringify(selectedBrands));
    localStorage.setItem('pac-search', JSON.stringify(search));
    localStorage.setItem('pac-selectedDealOwners', JSON.stringify(selectedDealOwners));
    localStorage.setItem('pac-enableAmountDueFilter', JSON.stringify(enableAmountDueFilter));
    localStorage.setItem('pac-enableCRDFilter', JSON.stringify(enableCRDFilter));
    localStorage.setItem('pac-dealTypeFilters', JSON.stringify(dealTypeFilters));
    localStorage.setItem('pac-isAuthorizedFilter', JSON.stringify(isAuthorizedFilter));
    localStorage.setItem('pac-isCreatorBrandApprovedFilter', JSON.stringify(isCreatorBrandApprovedFilter));
    localStorage.setItem('pac-isContractSignedFilter', JSON.stringify(isContractSignedFilter));
    localStorage.setItem('pac-isContentBrandApprovedFilter', JSON.stringify(isContentBrandApprovedFilter));
    localStorage.setItem('pac-isContentUrlValidFilter', JSON.stringify(isContentUrlValidFilter));
    localStorage.setItem('pac-isPublishedFilter', JSON.stringify(isPublishedFilter));
    localStorage.setItem('pac-isTaxValidFilter', JSON.stringify(isTaxValidFilter));
    localStorage.setItem('pac-amountDueFrom', JSON.stringify(amountDueFrom));
    localStorage.setItem('pac-amountDueTo', JSON.stringify(amountDueTo));
    localStorage.setItem('pac-singleAmountDue', JSON.stringify(singleAmountDue));
    localStorage.setItem('pac-crdDate', crdDate.toISOString());
    localStorage.setItem('pac-selectedDealStages', JSON.stringify(selectedDealStages));
    localStorage.setItem('pac-amountDueFilterType', JSON.stringify(amountDueFilterType));
  }, [
    search, selectedBrands, selectedDealOwners,
    dealTypeFilters, selectedDealStages, isAuthorizedFilter,
    isCreatorBrandApprovedFilter, isContentBrandApprovedFilter,
    isContractSignedFilter, isContentUrlValidFilter, isPublishedFilter,
    isTaxValidFilter, singleAmountDue, amountDueTo, amountDueFrom,
    enableAmountDueFilter, allItems, amountDueFilterType, crdDate, enableCRDFilter
  ]);

  useEffect(() => {
    if (showFilters) {
      setEnableAmountDueFilter(true);
    }
    localStorage.setItem('pac-showFilters', JSON.stringify(showFilters));
  }, [showFilters]);

  const resetFilters = () => {
    setSearch('');
    setSelectedBrands([]);
    setSelectedDealOwners([]);
    setSelectedDealStages(stageOptions);
    setEnableAmountDueFilter(true);
    setAmountDueFilterType(numberRangeFilters[1]);
    setSingleAmountDue(0);
    setAmountDueTo('');
    setAmountDueFrom('');
    setIsAuthorizedFilter({ both: false, yes: false, no: true });
    setIsCreatorBrandApprovedFilter({ both: true, yes: false, no: false })
    setIsContractSignedFilter({ both: true, yes: false, no: false });
    setIsContentBrandApprovedFilter({ both: true, yes: false, no: false });
    setIsContentUrlValidFilter({ both: true, yes: false, no: false });
    setIsPublishedFilter({ both: true, yes: false, no: false });
    setIsTaxValidFilter({ both: true, yes: false, no: false });
    setDealTypeFilters(defaultDealTypeFilters);
    setEnableCRDFilter(false);
    setCDRDate(moment().add(60, 'days').startOf('day').toDate());
    localStorage.setItem('pac-showFilters', JSON.stringify(false));
  };

  const downloadCSV = () => {
    console.log('CSV');

    const getCreatorBrandApprovedProspect = (prospects, possibleProspects, offer) => {
      const allProspects = prospects.concat(possibleProspects);
      let creatorBrandApprovedProspect = null;
      let creatorBrandNotApprovedProspect = null;
      if (allProspects && allProspects.length > 0) {
        let filteredProspects = allProspects.filter(item => (item.approvalStatus?.brand?.targetAdvertiserId?.toString() === offer?.advertiser?.toString() && item.approvalStatus.brand.status === 'Approved'));
        let filteredNotApproved = allProspects.filter(item => (item.approvalStatus?.brand?.targetAdvertiserId?.toString() === offer?.advertiser?.toString() && item.approvalStatus.brand.status !== 'Approved'));
        if (filteredProspects.length > 0) {
          // eslint-disable-next-line max-len
          filteredProspects = filteredProspects.sort((a, b) => (a.approvalStatus.brand.history[a.approvalStatus.brand.history.length - 1].dateModified > b.approvalStatus.brand.history[b.approvalStatus.brand.history.length - 1].dateModified ? 1 : -1));
          // eslint-disable-next-line prefer-destructuring
          creatorBrandApprovedProspect = filteredProspects[0];
        }
        if (filteredNotApproved.length > 0) {
          // eslint-disable-next-line max-len
          filteredNotApproved = filteredNotApproved.sort((a, b) => (((a.approvalStatus.brand.history.length && a.approvalStatus.brand.history[a.approvalStatus.brand.history.length - 1].dateModified) || a.createdAt) > ((b.approvalStatus.brand.history.length && b.approvalStatus.brand.history[b.approvalStatus.brand.history.length - 1].dateModified) || b.createdAt) ? 1 : -1));
          // eslint-disable-next-line prefer-destructuring
          creatorBrandNotApprovedProspect = filteredNotApproved[0];
        }
      }
      return { creatorBrandApprovedProspect, creatorBrandNotApprovedProspect };
    };

    const data = [];
    data.push(['Deal Name', 'Creator Name', 'Creator Email', 'Authorized?', 'Brand', 'Deal Owner', 'Deal Type', 'Deal Stage', 'Agency', 'Total Amount', 'Amount Due',
      'Payee Name', 'Payee Email', 'Payment Method', 'Tax Document', 'Creator Brand Approved', 'Contract Signed', 'Content Brand Approved', 'Content Url Validated', 'Publish Date',
      'CPM Price Approved?', 'CPM Insertion Order No.', 'Deal Create Date', 'Brand Approved Date', 'Content Release Date']);
    // console.log(filteredItems);
    filteredItems.forEach((item) => {
      let amount = '';
      let amountDue = '';
      let paymentMethod = '';
      let tax = 'Missing';
      let creatorBrandApproved = '';

      if (item.bill) {
        amount = item.bill.amount;
        amountDue = item.bill.amountDue;
      } else if (item.payable) {
        amount = item.payable.ilPayoutAmount;
        amountDue = item.payable.ilPayoutAmount;
      }

      if (item.payment.data) {
        paymentMethod = item.payment.data.stripe ? 'Stripe' : 'Bill.com';
      }

      if (item.payment?.taxValid) {
        if (item.payment.data?.stripe) {
          tax = 'On File (Stripe)';
        } else if (item.payment.validHistory.length) {
          tax = `On File ${getServerDate((item.payment.validHistory[item.payment.validHistory?.length - 1]).dateModified)}`;
        } else {
          tax = 'On File -';
        }
      }

      const prospectStatus = getCreatorBrandApprovedProspect(item.prospects, item.possibleProspects, item.offer);
      if (item.authorizedPayment?.creatorBrandApproved && prospectStatus.creatorBrandApprovedProspect) {
        creatorBrandApproved = getServerDate(prospectStatus.creatorBrandApprovedProspect.approvalStatus.brand.history[prospectStatus.creatorBrandApprovedProspect.approvalStatus.brand.history.length - 1].dateModified);
      }
      if (!creatorBrandApproved && prospectStatus.creatorBrandNotApprovedProspect) {
        creatorBrandApproved = getStatus(prospectStatus.creatorBrandNotApprovedProspect);
      }
      if (!creatorBrandApproved) creatorBrandApproved = 'Missing';

      let contentBrandApproved = item.authorizedPayment?.contentBrandApproved ? getServerDate(item.brandApprovedDate) : 'No'
      let contentUrlValid = item.mediacontent && item.mediacontent.length && item.authorizedPayment?.contentUrlValid ? getServerDate(item.mediacontent[0]?.urlStatusLastModified) : 'No';
      let contentUrlPublished = '';
      if (item.authorizedPayment?.contentBrandInactive) {
        contentBrandApproved = 'Content Inactive';
        contentUrlValid = 'Content Inactive';
      }

      if( item.authorizedPayment?.contentPublicAndPublished !== null) {
        contentUrlPublished = item.dealTerms?.published_date ? getServerDate(item.dealTerms.published_date) : 'Not Published';
      }
      if( item.authorizedPayment.contentPublicAndPublishedBaD !== null) {
        contentUrlPublished = `${item.brandApprovedDate ? getServerDate(item.brandApprovedDate) : 'Not Published'} (B/A/D)`;
      }


      data.push([
        item.dealname,
        `${item.influencerObject?.first_name || ''} ${item.influencerObject?.last_name || ''}`,
        item.influencerObject?.email || '',
        item.authorizedPayment?.summaryValidation ? 'Yes' : 'No',
        item.dealTerms?.brand || '',
        `${item.adminUserObject?.firstName || ''} ${item.adminUserObject?.lastName || ''}`,
        item.dealTerms.type_of_deal || '',
        item.dealstage ? dealstages[Number(item.dealstage)] : '',
        item.dealTerms.agency_affiliation || '',
        formatCurrency(amount),
        formatCurrency(amountDue),
        item.payment.data?.name || '',
        item.payment.data?.email || '',
        paymentMethod,
        tax,
        creatorBrandApproved,
        item.authorizedPayment?.contractSigned && item.serviceagreement ? getServerDate(item.serviceagreement.creatorDateSigned) : 'No',
        contentBrandApproved,
        contentUrlValid,
        contentUrlPublished,
        // eslint-disable-next-line no-nested-ternary
        item.authorizedPayment?.cpmPriceApproved && item.cpmprospect?.approvalStatus?.history.length ?
          getServerDate(item.cpmprospect.approvalStatus.history[item.cpmprospect.approvalStatus.history.length - 1].dateModified) :
          (
            Number(item.offer?.advertiserTuneid || 0) === 2 && item.dealTerms.type_of_deal === 'Upfront CPM' ?
              'No' : '-'
          ),
        // eslint-disable-next-line no-nested-ternary
        item.authorizedPayment?.cpmIoSigned && item.cpminsertionorder?.name ?
          item.cpminsertionorder.name :
          (
            Number(item.offer?.advertiserTuneid || 0) === 2 && item.dealTerms.type_of_deal === 'Upfront CPM' ?
              'No' : '-'
          ),
        item.createdAt ? formatDate(item.createdAt) : '',
        item.brandApprovedDate ? getServerDate(item.brandApprovedDate) : '',
        item.dealTerms.content_release_date ? getServerDate(item.dealTerms.content_release_date) : '',
      ]);
    });
    return csvHelper.generateCSV('PaymentAuthorizationReport.csv', data);
  };

  return (
    <div className="mt-3">
      <Row className="mt-4">
        <Col>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Creator Name, Creator Email, Channel URL, Deal Name"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>

        <Col sm="auto" className="text-right">
          <UncontrolledButtonDropdown>
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={downloadCSV}
              >
                Download CSV
              </DropdownItem>
              <DropdownItem
                onClick={() => showModal()}
              >
                Refresh Payment Authorization
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      {showFilters && (
        <Row className="mt-2">
          <Col sm={5}>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label className="bold-text">
                    Brand(s)
                  </Label>
                  <Select
                    isDisabled={loadingFilterOptions}
                    value={selectedBrands}
                    options={brandsOptions}
                    onChange={setSelectedBrands}
                    placeholder="Select Brand..."
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label className="bold-text">
                    Deal Owner(s)
                  </Label>
                  <Select
                    isDisabled={loadingFilterOptions}
                    value={selectedDealOwners}
                    options={dealOwnersOptions}
                    onChange={setSelectedDealOwners}
                    placeholder="Select Deal Owner..."
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Deal Stage
                  </Label>
                  <div>
                    {stageOptions.map(({ label, value }) => {
                    console.log('');
                    return (
                      <CheckBoxField
                        key={value}
                        name={value}
                        label={label}
                        value={Boolean(selectedDealStages.find(a => a.value === value) || false)}
                        onChange={(event) => {
                          if (!event.target) return false;
                          setSelectedDealStages((selectedDealStagesA) => {
                            const a = [...selectedDealStagesA];
                            // console.log(a);
                            if (event.target?.checked) {
                              // Add item from options where k === key
                              const optionToAdd = stageOptions.find(option => option.value === value);
                              if (optionToAdd) {
                                a.push(optionToAdd);
                              }
                            } else {
                              // Remove item from a where k === key
                              const indexToRemove = a.findIndex(option => option.value === value);
                              if (indexToRemove !== -1) {
                                a.splice(indexToRemove, 1);
                              }
                            }
                            // console.log(a);
                            return a;
                          });
                          return true;
                        }}
                        className={`inline ${label !== 'Content In Review' ? 'ml-4' : ''}`}
                      />);
                  })}
                </div>
              </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Deal Type(s)
                  </Label>
                  <div>
                    <CheckBoxField
                      name="CPA/Ambassador"
                      label="CPA"
                      value={dealTypeFilters['CPA/Ambassador']}
                      onChange={event => event.target && setDealTypeFilters({
                        ...dealTypeFilters,
                        'CPA/Ambassador': event.target?.checked || false,
                      })}
                      className="inline"
                    />
                    <CheckBoxField
                      name="upfrontonly"
                      label="Upfront Only"
                      value={dealTypeFilters['Upfront Only']}
                      onChange={event => event.target && setDealTypeFilters({
                        ...dealTypeFilters,
                        'Upfront Only': event.target?.checked || false,
                      })}
                      className="inline ml-4"
                    />
                    <CheckBoxField
                      name="upfrontcpm"
                      label="Upfront CPM"
                      value={dealTypeFilters['Upfront CPM']}
                      onChange={event => event.target && setDealTypeFilters({
                        ...dealTypeFilters,
                        'Upfront CPM': event.target?.checked || false,
                      })}
                      className="inline ml-4"
                    />
                    <CheckBoxField
                      name="Hybrid"
                      label="Hybrid"
                      value={dealTypeFilters.Hybrid}
                      onChange={event => event.target && setDealTypeFilters({
                        ...dealTypeFilters,
                        Hybrid: event.target?.checked || false,
                      })}
                      className="inline ml-4"
                    />
                    <CheckBoxField
                      name="Bonus"
                      label="Bonus"
                      value={dealTypeFilters.Bonus}
                      onChange={event => event.target && setDealTypeFilters({
                        ...dealTypeFilters,
                        Bonus: event.target?.checked || false,
                      })}
                      className="inline ml-4"
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Amount Due
                  </Label>
                  <Row>
                    <Col sm={1}>
                      <FormGroup
                        style={{ paddingTop: '8px', paddingLeft: '8px' }}
                      >
                        <CheckBoxField
                          name="amountDueCheck"
                          value={enableAmountDueFilter}
                          onChange={() => { setEnableAmountDueFilter(!enableAmountDueFilter); }}
                          defaultChecked={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={3} style={{ paddingLeft: '20px', paddingRight: 0 }}>
                      <Select
                        value={amountDueFilterType}
                        onChange={(option) => {
                          if ((amountDueFilterType.value !== 'Between' && option.value === 'Between') || (amountDueFilterType.value === 'Between' && option.value !== 'Between')) {
                            setAmountDueFrom('');
                            setAmountDueTo('');
                            setSingleAmountDue(0);
                          }
                          setAmountDueFilterType(option);
                        }}
                        options={numberRangeFilters}
                        isDisabled={!enableAmountDueFilter}
                        // styles={customStyles}
                      />
                    </Col>
                    <Col sm={8}>
                      {amountDueFilterType.value === 'Between' ? (
                        <Row>
                          <Col sm={6} style={{ paddingRight: '8px' }}>
                            <InputGroup className="min-width-100">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>$</InputGroupText>
                              </InputGroupAddon>
                              <NumberFormat
                                key="amountMin"
                                disabled={!enableAmountDueFilter}
                                thousandSeparator
                                prefix=""
                                name="what"
                                value={amountDueFrom}
                                className="form-control form-control-sm"
                                placeholder="Min Amount"
                                inputMode="numeric"
                                allowEmptyFormatting={false}
                                fixedDecimalScale
                                decimalScale={0}
                                onValueChange={values => setAmountDueFrom(values.floatValue)}
                                allowNegative={false}
                                onBlur={() => {
                                  if (
                                    amountDueFrom
                                    && amountDueTo
                                    && (amountDueFrom > amountDueTo)
                                  ) {
                                    setAmountDueTo(amountDueFrom + 1);
                                  }
                                }}
                                style={{ height: '38px' }}
                              />
                            </InputGroup>
                          </Col>
                          <Col sm={6} style={{ paddingLeft: '7px' }}>
                            <InputGroup className="min-width-100">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>$</InputGroupText>
                              </InputGroupAddon>
                              <NumberFormat
                                key="amountMax"
                                disabled={!enableAmountDueFilter}
                                thousandSeparator
                                prefix=""
                                name="what"
                                value={amountDueTo}
                                className="form-control form-control-sm"
                                style={{ height: '38px' }}
                                placeholder="Max Amount"
                                inputMode="numeric"
                                allowEmptyFormatting={false}
                                fixedDecimalScale
                                decimalScale={0}
                                onValueChange={values => setAmountDueTo(values.floatValue)}
                                allowNegative={false}
                                onBlur={() => {
                                  if (
                                    amountDueFrom
                                    && amountDueTo
                                    && (amountDueFrom > amountDueTo)
                                  ) {
                                    setAmountDueFrom(amountDueTo.parseFloat() - 1);
                                  }
                                }}
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col sm={6} style={{ paddingRight: '8px' }}>
                            <InputGroup className="min-width-100">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>$</InputGroupText>
                              </InputGroupAddon>
                              <NumberFormat
                                key="singleAmount"
                                disabled={!enableAmountDueFilter}
                                thousandSeparator
                                prefix=""
                                name="what"
                                value={singleAmountDue}
                                defaultValue={0}
                                className="form-control form-control-sm"
                                placeholder="Amount"
                                inputMode="numeric"
                                // allowEmptyFormatting={false}
                                fixedDecimalScale
                                decimalScale={0}
                                onValueChange={values => setSingleAmountDue(values.floatValue)}
                                allowNegative={false}
                                style={{ height: '38px', width: '47%' }}
                              />
                            </InputGroup>
                          </Col>
                          <Col sm={6} />
                        </Row>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Content Release Date
                  </Label>
                  <Row>
                    <Col sm="auto">
                      <FormGroup
                        style={{ paddingTop: '8px', paddingLeft: '8px' }}
                      >
                        <Label>
                          <CheckBoxField
                            name="CRD"
                            value={enableCRDFilter}
                            onChange={(p) => p.target && setEnableCRDFilter(p.target.checked)}
                            defaultChecked={true}
                            label="Exclude deals with a Content Release Date on or after"
                          />
                          
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup
                      >
                        <DatePicker
                          id="endDateDatePicker"
                          selected={crdDate}
                          onChange={date => setCDRDate(moment(date).startOf('day').toDate())}
                          placeholderText="End Date"
                          dropDownMode="select"
                          dateFormat="MM/dd/yyyy"
                          className='form-control'
                          disabled={!enableCRDFilter}
                          customInput={<DatePickerCustomInput />}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col sm={7} style={{ paddingLeft: '40px' }}>
            <Row>
              <Col sm={12}>
                <Label className="bold-text" style={{ marginBottom: '14px' }}>
                  Is Payment Authorized?
                </Label>
                <BothYesNoComponent
                  filterValue={isAuthorizedFilter}
                  filterAction={setIsAuthorizedFilter}
                  styles={{ display: 'inline-block', marginLeft: '20px' }}
                  name="isAuthorized"
                />
              </Col>
              <Col sm={12}>
                <Label className="bold-text" style={{ marginBottom: '4px' }}>
                  Checklist Items
                </Label>
                <hr style={{ marginTop: '3px' }} />
              </Col>
            </Row>
            <Row>
              <Col sm={5}>
                <FormGroup>
                  <Label className="bold-text" style={{marginBottom: '14px'}}>
                    Creator Brand Approved?
                  </Label>
                  <BothYesNoComponent
                    filterValue={isCreatorBrandApprovedFilter}
                    filterAction={setIsCreatorBrandApprovedFilter}
                    name="brandApproved"
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="bold-text" style={{marginBottom: '14px'}}>
                    Contract Signed?
                  </Label>
                  <BothYesNoComponent
                    filterValue={isContractSignedFilter}
                    filterAction={setIsContractSignedFilter}
                    name="contractSigned"
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="bold-text" style={{marginBottom: '14px'}}>
                    Content Brand Approved?
                  </Label>
                  <BothYesNoComponent
                    filterValue={isContentBrandApprovedFilter}
                    filterAction={setIsContentBrandApprovedFilter}
                    name="contentApproved"
                  />
                </FormGroup>
              </Col>
              <Col sm={5}>
                <FormGroup>
                  <Label className="bold-text" style={{marginBottom: '14px'}}>
                    Content URL Valid?
                  </Label>
                  <BothYesNoComponent
                    filterValue={isContentUrlValidFilter}
                    filterAction={setIsContentUrlValidFilter}
                    name="urlValid"
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="bold-text" style={{marginBottom: '14px'}}>
                    Publish Date?
                  </Label>
                  <BothYesNoComponent
                    filterValue={isPublishedFilter}
                    filterAction={setIsPublishedFilter}
                    name="published"
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="bold-text" style={{marginBottom: '14px'}}>
                    Tax Document?
                  </Label>
                  <BothYesNoComponent
                    filterValue={isTaxValidFilter}
                    filterAction={setIsTaxValidFilter}
                    name="taxValid"
                  />
                </FormGroup>
              </Col>
              <Col sm={2}/>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

Filters.propTypes = {
  allItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  setFilteredItems: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  filteredItems: PropTypes.arrayOf(PropTypes.any).isRequired,
};


export default withNotice(Filters);
