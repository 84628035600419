/* eslint-disable filenames/match-exported */
import React from 'react';
import PropTypes from 'prop-types';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';


const DownloadCSV = ({ items }) => (
  <DownloadCSVButton
    records={items}
    className=""
    fileName="CreatorPerformanceReport.csv"
    csvArrayColumns={[
      {
        label: 'Creator',
        func: deal => `${deal.influencer?.first_name || ''} ${deal.influencer?.last_name || ''} `,
      }, {
        label: 'Gross Revenue',
        func: record => (record.grossRevenue || ''),
      }, {
        label: 'Gross Profit',
        func: record => (record.grossProfit || ''),
      }, {
        label: 'Clicks',
        func: deal => deal?.clicks || 0,
      }, {
        label: 'Revenue Conversions',
        func: deal => deal?.conversions || 0,
      }, {
        label: 'Promo Code',
        func: p => (((p?.deals?.filter(d => d.deal?.dealTerms.coupon_code))?.map(d => d.deal?.dealTerms.coupon_code || ''))?.join(', ')),
      }, {
        label: 'Content Type',
        func: p => (((p?.deals?.filter(d => d.deal?.dealTerms.content_type))?.map(d => d.deal?.dealTerms.content_type || ''))?.join(', ')),
      }, {
        label: 'Deal Owner',
        func: (p) => {
          if (!p.admins) return '';
          const values = p.admins.map(d => `${d?.firstName || ''} ${d?.lastName || ''}`) || [];
          return values.join(', ');
        },
      }, {
        label: 'Hubspot Contact Url',
        func: p => `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.influencer.hsContactVid}/`,
      }, {
        label: 'Creator Tag(s)',
        func: row => row.influencer?.tags?.map(tag => tag.trim()).join(', ') || '',
      }, {
        label: 'Prospect Categories',
        func: row => [...new Set(row.categories?.map(cat => cat.trim()))].join(', ') || '',
      },
    ]}
  >
    Download CSV
  </DownloadCSVButton>);
DownloadCSV.propTypes = {
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
export default DownloadCSV;
