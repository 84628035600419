import func from '../../../../../../shared/hooks';

export default () => [
  {
    initialState: '',
    func: ({ filters }) => filters?.fullSearchName || '',
    filterName: 'fullSearchName',
    funcToUrl: ({ search, item }) => {
      if (item) {
        search.set('filters.fullSearchName', item);
      } else {
        search.delete('filters.fullSearchName');
      }
      return search;
    },
  },
  {
    initialState: {
      2: true,
      3: true,
      '4+': true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'count',
  },
  {
    initialState: {
      0: true,
      1: true,
      '2+': true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'creatorCount',
  },
];
