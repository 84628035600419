import React from 'react';
import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import HubspotDealLink from '../../../../../../shared/components/HubspotDealLink';
// import { Row, Col, Button } from 'reactstrap';

export default class DealNameField extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);
    this.handleToogle = this.handleToogle.bind(this);
    this.api = new ApiClient();
    this.state = {
      isOpenHelper: false,
    };
  }
  handleToogle() {
    this.setState({ isOpenHelper: !this.state.isOpenHelper });
  }


  render() {
    const { record } = this.props;
    let finalDeal;
    if (record.populated.deal) {
      finalDeal = {
        deal: record.populated.deal.params,
      };
    } else if (record.unassignedDeal) {
      finalDeal = {
        deal: record.unassignedDeal,
        unassigned: true,
      };
    } else {
      finalDeal = {};
    }
    return (
      <div>
        {finalDeal && finalDeal.deal ? (<HubspotDealLink
          id={Number(finalDeal.deal ? finalDeal.deal.hs_object_id : 0)}
          title={finalDeal.deal.dealname}
        />) : null}
        {finalDeal && finalDeal.unassigned ? ' (Missing Offer)' : null}
      </div>
    );
  }
}
