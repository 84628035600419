import React, { useEffect, useState } from 'react';
import { axios } from 'ApiClient';
// import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import { InputGroup, Row, Col } from 'reactstrap';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { unflatten } from 'flat';
import _ from 'lodash';

const V30 = React.memo(({
  record, updateRecordFunction,
}) => {
  const [numVideos, setNumVideos] = useState(record.params?.v30 || 0);
  const [loading, setLoading] = useState(false);
  const [changedAmount, setChangedAmount] = useState(undefined);

  useEffect(() => {
    setNumVideos(record.params?.v30 || 0);
    // console.log('setNum views', [record.params?.v30, numVideos]);
  }, [record.params?.v30]);

  const handleChange = async () => {
    if (typeof changedAmount !== 'undefined') {
      setLoading(true);
      const { id } = record;
      const data = await axios.post('/api/portal/prospects/post-update-property-on-cpm-prospect', {
        ids: [id],
        values: {
          v30: changedAmount,
        },
      });
      setLoading(false);
      if (data?.data?.recordPopulated && updateRecordFunction) {
        const r = unflatten(data?.data?.recordPopulated);
        updateRecordFunction(r.id, {
          amount: r.params.amount,
          totalImpressions: r.params.totalImpressions,
          totalPrice: r.params.totalPrice,
          cpm: r.params.cpm,
          v30: r.params.v30,
        });
      }
    }
    return true;
  };

  return (
    <Row noGutters>
      <Col>
        <InputGroup>
          <NumberFormat
            thousandSeparator
            prefix=""
            name="v30"
            value={numVideos}
            className="form-control"
            inputMode="numeric"
            allowEmptyFormatting
            fixedDecimalScale
            decimalScale={0}
            disabled={loading}
            onBlur={handleChange}
            onValueChange={(values) => {
              setChangedAmount(values.floatValue);
              setNumVideos(values.floatValue);
            }}
          />
        </InputGroup>
      </Col>
    </Row>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

V30.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};

export default V30;
