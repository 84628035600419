const filter = (row, filters = {}) => {
  const k = Object.keys(filters);
  for (let i = 0; i <= k.length; i += 1) {
    if (filters[k[i]]?.length > 0 && !filters[k[i]].includes(row[k[i]])) {
      return false;
    }
  }
  return true;
};

exports.calculateSum = (a, keys) => {
  if (!keys) return null;
  let res = 0;
  keys.forEach((k) => {
    res += Number(a[k]);
  });
  return res;
};

exports.calculatePercentage = (a, b) => (b ? (a / b) : null);

exports.groupByKey = ({ row, res }, groupBy, cols, title) => {
  const index = groupBy(row);
  if (index) {
    const t = title(index);
    if (t) {
      if (!res[index]) {
        res[index] = { title: t };
        Object.keys(cols).forEach((c) => { res[index][c] = 0; });
      }
      Object.keys(cols).forEach((c) => {
        res[index][c] = cols[c](res[index][c], row, c);
      });
    }
  }
};

exports.groupData = (data, params = {}) => {
  const {
    calculateRow,
    filters,
    results,
  } = params;
  const res = {};
  Object.keys(results).forEach((k) => { res[k] = {}; });
  data.forEach((row) => {
    if (filter(row, filters)) {
      if (calculateRow) calculateRow(row);
      Object.keys(results).forEach((k) => {
        results[k].data({ row, res: res[k] });
      });
    }
  });
  return res;
};
