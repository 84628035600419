import React from 'react';
import { unflatten } from 'flat';
import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import CurrencyField from '../../../../../../shared/components/form/CurrencyField';

export default class EmployeeCommissionField extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    updateRecordFunction: PropTypes.func.isRequired,
    fieldId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.api = new ApiClient();
    const { record, fieldId } = this.props;
    const fieldValue = record[fieldId].$numberDecimal;
    this.state = {
      fieldValue,
      loading: false,
    };
  }

  handleChange(event) {
    this.setState({ loading: true });
    const { record, updateRecordFunction, fieldId } = this.props;

    let { value } = event.target;
    value = value.replace(',', '').replace(' ', '');
    const { _id } = record;
    const data = new FormData();
    data.append(fieldId, value);
    this.api.recordAction({
      resourceId: 'EmployeeCommission',
      recordId: _id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      this.api.recordAction({
        resourceId: 'EmployeeCommission',
        recordId: _id,
        actionName: 'show',
      }).then((e) => {
        this.setState({ loading: false });
        updateRecordFunction(unflatten(e.data.record), fieldId);
      });
    });
    return true;
  }

  render() {
    const { loading } = this.state;
    let { fieldValue } = this.state;
    fieldValue = parseFloat(fieldValue);
    return (
      <CurrencyField type="text" className="form-control" disabled={loading} defaultValue={fieldValue} onBlur={this.handleChange} />
    );
  }
}
