import React, {useState} from 'react';
import {Card, CardBody, CardTitle, Col, Container, Row} from 'reactstrap';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import Actions from './Actions/Actions';
import Table from "./Table/Table";

const CpaFraudDetection = () => {
  console.log('CpaFraudDetection Report');
  const [creatorsInTimeRange, setCreatorsInTimeRange] = useState([]);
  const [creators, setCreators] = useState([]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Compliance', path: false },
              { title: 'Reports', path: false },
              { title: 'CPA Fraud Detection', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>CPA Fraud Detection</h3>
              </CardTitle>
              <hr />
              <Actions setCreators={setCreators} setCreatorsInTimeRange={setCreatorsInTimeRange} />
              {(creators.length || creatorsInTimeRange.length) ? (
                <Table creators={creators} creatorsInTimeRange={creatorsInTimeRange} />
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CpaFraudDetection;
