import React, { useState } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import { Row, Col, Container, Card, CardBody, CardTitle, Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import Filter from '../FilterNew';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import PermissionComponent from '../../../../shared/components/PermissionComponent';
import { CheckPermission } from '../../../../shared/helpers/WVUtilities';
import GenerateTable from './GenerateTable';
import Filters from './Filters';


const SummaryReport = ({ addNotice }) => {
  
  const [loading, setLoaing] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [fetched, setFetched] = useState(false);


  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedCsps, setSelectedCsps] = useState([]);
  const [adv, setAdvertisersOptions] = useState([]);

  const fetchAmountReport = async (advertisers, csp) => {
    setLoaing(true);
    // setError(null);
    setReportData([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-new-creators-report', {
        startDate,
        endDate,
        identity,
        advertisers,
        csp,
      });
      if (resp.data.success) {
        setLoaing(false);
        setReportData(resp.data.reports);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
    setFetched(true);
  };

  const generateReport = async (advertisers, csp) => {
    await fetchAmountReport(advertisers, csp);
    return true;
  };
  if (!CheckPermission('reports----brandperformance')) return (<PermissionComponent />);
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'New Creators', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>New Creators</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col>
                  <Filter
                    setValue={(param, value) => {
                      console.log(value);
                      if (param === 'startDate') {
                        setStartDate(moment(value).format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).format('YYYY-MM-DD'));
                      }
                    }}
                  />
                </Col>
                <Col lg="auto">
                  {loading ? <Spinner color="primary" size="lg" /> :
                  <Button
                    color="primary"
                    onClick={() => generateReport(selectedAdvertisers?.map(e => e.value), selectedCsps?.map(e => e.value))}
                    className="btn-sm"
                    disabled={!!loading}
                  >
                    Run Report
                  </Button>
                  }
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Filters
                    selectedAdvertisers={selectedAdvertisers || []}
                    setSelectedAdvertisers={setSelectedAdvertisers}
                    selectedCsps={selectedCsps || []}
                    setSelectedCsps={setSelectedCsps}
                    isSaved={false}
                    setAdvertisersOptions={setAdvertisersOptions}
                  />
                </Col>
              </Row>
              <GenerateTable
                reportData={reportData}
                loading={loading}
                fetched={fetched}
                isSaved={false}
                advertisers={adv}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
