import React, { useState } from 'react';
import { connect } from 'react-redux';
import { axios } from 'ApiClient';
import { Row, Col, Button, Container, Card, CardBody, CardTitle, Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import { showModalById } from '../../../../redux/reducers/TableReducer';
import ModalPublish, { modalId as publishModalId } from './ModalPublish';

const SummaryReport = ({ dispatch, addNotice }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({});
  const [orders, setOrders] = useState([]);
  const [selectedKey, setSelectedKey] = useState('pred_output1');

  /* const columns = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: p => `${p?.offer.advertiser.companyName || ''}`,
        id: 'brand',
        Cell: p => useMemo(() => (
          <div style={{ width: '120px' }}>
            {p.value}
          </div>
        ), [p.row.original]),
      },
    ],
    [items],
  ); */

  const fetchReport = async () => {
    setLoading(true);
    // setError(null);
    setItems({});
    setOrders([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-keen-rebuy-triggers', {});
      if (resp.data.value) {
        setItems(resp.data.value);
        setOrders(resp.data.keenRebuyOrder);
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
        duration: 20,
      });
    }
    setLoading(false);
  };

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    useFlex: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'grossProfit',
    direction: 'desc',
  };

  // const filteredItems = useMemo(() => items, [items]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Keen Rebuy Trigger', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Keen Rebuy Trigger</h3>
              </CardTitle>
              <Row className="mt-3">
                <Col />
                <Col lg="auto">
                  <div className="mt-0">
                    <Button
                      color="primary"
                      onClick={() => fetchReport()}
                      className="btn-sm"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => dispatch(showModalById(publishModalId, { }))}
                      className="btn-sm"
                      disabled={!!loading}
                    >
                      Publish Report
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {loading || orders.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="mt-3">
                  <Col>
                    {loading ? (<div className="text-center"><Spinner color="primary" size="lg" /></div>) : (
                      <Nav tabs>
                        {orders.map((item) => {
                          console.log(item);
                          return (
                            <NavItem key={item.value}>
                              <NavLink
                                href="#"
                                className={selectedKey === item.value ? 'active' : ''}
                                onClick={() => {
                                  setSelectedKey(item.value);
                                }}
                              >
                                {item.name}
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </Nav>
                    )}
                    {orders.filter(k => k.value === selectedKey).map((item) => {
                        // console.log(item);
                        const columns = item.columns.map(k => ({
                            Header: k,
                            accessor: p => p[k] || '',
                            id: k,
                            Cell: p => p.value,
                          }));
                        const rows = items[selectedKey];
                        return selectedKey === item.value ? (
                          <div className="mt-3" key={item.value}>
                            <ReactTableBase
                              key={item.value}
                              columns={columns}
                              data={rows}
                              tableConfig={tableConfig}
                            />
                          </div>
                        ) : null;
                      })}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>) : null
      }
      <ModalPublish />
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};


export default connect()(withNotice(SummaryReport));
