import React, { useMemo } from 'react';
import moment from 'moment';
import { Modal, ButtonToolbar, Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
// import MatTableForRedux from '../../../../../shared/tables/materialTable/components/MatTableForRedux';
import formater from '../../../../../shared/helpers/WVFormatter';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';


const CalculationResults = ({
  modal,
  closeDialog,
  title,
  calcData,
  record,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Start Date (ET)',
        accessor: r => r?.accountsPayablesList[0]?.startDate || '',
        Cell: p => formater.getServerDate(moment(p.value || '')),
      },
      {
        Header: 'End Date (ET)',
        accessor: r => r?.accountsPayablesList[0]?.endDate || '',
        Cell: p => formater.getServerDate(moment(p.value || '')),
      },
      {
        Header: 'Status',
        accessor: r => (r.status || ''),
        Cell: p => p.value,
        minWidth: 150,
      },
      {
        Header: 'Payout',
        id: 'payout',
        accessor: r => (Number(r.ilPayoutAmount || 0)),
        Cell: p => formater.formatCurrency(p.value),
        Footer: (info) => {
          const total = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.payout) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(total)}</span>;
        },
      },
    ],
    [],
  );
  let amountCalculation = '';
  let currentMountCalculation = '';
  if (calcData && record.populated.deal?.params?.dealTerms && Object.keys(calcData).length !== 0) {
    console.log(record.populated.deal?.params?.dealTerms.cpa_percentage);
    const percentage = record.populated.deal?.params?.dealTerms?.cpa_percentage;
    currentMountCalculation = percentage
      ? `${formater.formatCurrency((Number(record.params.payoutAmount)))} (Payout Amount) * ${percentage}% (CPA Percentage) =
    ${formater.formatCurrency((Number(percentage) * Number(record.params.payoutAmount)) / 100)} 
    ${Number(record.params.ilPayoutAmount) !== (Number(percentage) * Number(record.params.payoutAmount)) / 100 ? `( Changed to ${formater.formatCurrency(Number(record.params.ilPayoutAmount || 0))})` : ''}`
      : `${calcData.countOfConversions || 0} (Number of Conversions) * ${formater.formatCurrency(Number(record.populated.deal?.params?.dealTerms?.per_conversion_amount || 0))} (Per Conversions Amount) = 
    ${formater.formatCurrency((Number(record.populated.deal?.params?.dealTerms?.per_conversion_amount) * Number(calcData.countOfConversions || 0)))} 
    ${Number(record.params.ilPayoutAmount) !== (Number(record.populated.deal?.params?.dealTerms?.per_conversion_amount) * Number(calcData.countOfConversions || 0))
    ? `( Changed to ${formater.formatCurrency(Number(record.params.ilPayoutAmount || 0))})`
    : ''}`;

    switch (record.populated.deal?.params?.dealTerms?.type_of_deal && record.populated.deal?.params?.dealTerms?.type_of_deal?.toLowerCase()) {
      case 'cpa/ambassador': {
        amountCalculation = currentMountCalculation;
        break;
      } case 'upfront only': {
        amountCalculation = (
          <span>
            <b>Bill Invoice No</b>: {calcData.bill && calcData.bill.invoiceNumber}<br />
            <b>Bill Amount</b>: {formater.formatCurrency(Number(calcData.bill && calcData.bill.amount))}<br />
            <b>Paid Bill Amount</b>: {formater.formatCurrency(Number(calcData.totalPaidBill || 0))}<br />
          </span>
        );
        break;
      } case 'hybrid': {
        amountCalculation = (
          <div>
            <b>Invoice Invoice No.</b>: {calcData.invoice && calcData.invoice.invoiceNumber}<br />
            <b>Invoice Amount</b>: {formater.formatCurrency(Number(calcData.invoice && calcData.invoice.amount))}<br />
            <b>Total Paid with Credits</b>: {formater.formatCurrency(Number(calcData.totalPaidAmountHybrid))}<br />
            <b>Current Month Payout</b>: {currentMountCalculation}<br />
          </div>
        );
        break;
      }
      default:
        amountCalculation = '-';
    }
  }
  if (!record.populated.deal) {
    return null;
  }
  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };
  return (
    <Modal
      isOpen={modal}
      toggle={closeDialog}
    >
      <div className="modal__header">
        <h4 className="text-modal modal__title">{title}</h4>
      </div>
      <div className="ltr-support theme-light">
        {!calcData ? (<div className="text-center"><Spinner color="primary" size="lg" /></div>) : null}
        {calcData ? (<div><b>Deal Type</b> : {record.populated.deal?.params?.dealTerms?.type_of_deal || '-'}</div>) : null}
        {calcData ? (<div><b>Total Paid Amount payables</b> : {formater.formatCurrency(Number(calcData.totalPaidAmount || 0))}</div>) : null}
        {calcData ? amountCalculation : null}
        {calcData ? (<h4 className="mt-3" style={{ marginBottom: '-20px' }}>Payables Items</h4>) : null}
        {calcData ? (
          <ReactTableBase
            key="searchable"
            columns={columns}
            data={calcData?.payables || []}
            tableConfig={tableConfig}
          />
        ) : null}
      </div>
      {calcData ? (
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel btn-sm" color="danger" onClick={closeDialog}>Close</Button>
        </ButtonToolbar>
      ) : null}
    </Modal>
  );
};
CalculationResults.propTypes = {
  modal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  calcData: PropTypes.objectOf(PropTypes.any),
};
CalculationResults.defaultProps = {
  calcData: null,
};
/*
<MatTableForRedux
            dataArray={calcData.payables}
            selectable={false}
            columns={columnTransform}
            version={(new Date()).toString()}
            rowsPerPage={5}
          />
*/
export default CalculationResults;
