import React from 'react';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TableCellContextMenu from '@/shared/components/table/TableCellContextMenu';
import { showUpdateLogoDialog } from '@/redux/reducers/admin/AdvertiserReducer';

const ContextMenuInList = ({
  record,
}) => {
  const dispatch = useDispatch();
  const brand = unflatten(record);
  const offersLink = `/resources/Offer/actions/list?filters.advertiser=${brand.id}&backUrl=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
  const googleFilessLink = `/resources/Advertiser/records/${brand.id}/edit`;

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const clickUploadLogo = () => {
    const { logoUrl, logoSquareUrl } = brand.params;
    const theLogoUrl = typeof logoUrl !== 'undefined' && logoUrl !== null ? logoUrl : '';
    const theLogoSquareUrl = typeof logoSquareUrl !== 'undefined' && logoSquareUrl !== null ? logoSquareUrl : '';
    const brandLogo = {
      id: brand.id,
      logoUrl: theLogoUrl,
      logoSquareUrl: theLogoSquareUrl,
    };
    dispatch(showUpdateLogoDialog(brandLogo));
  };

  const contextMenuOptions = [
    { label: 'Show Offers', handler: () => goToPage(offersLink) },
    { type: 'divider', label: 'divider-1' },
    { label: 'Upload Logos', handler: () => clickUploadLogo() },
    { type: 'divider', label: 'divider-2' },
    { label: 'Google Files', handler: () => goToPage(googleFilessLink) },
  ];

  return (
    <TableCellContextMenu options={contextMenuOptions} />
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContextMenuInList;
