// import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import Hook from '../../../../../../../../shared/hooks';


const compareApprovalStatus = {
  approved: 'Approved',
  inReview: 'In Review',
  rejected: 'Rejected',
  actionRequired: 'Action Required',
  hold: 'Hold',
  pendingEdits: 'Pending Edits',
};
const WideTooltip = withStyles({
  tooltip: {
    width: '500px',
  },
})(Tooltip);

const DefaultApprovalStatus = ({
  val,
  notesVal,
  dealJustificationVal,
  placeholder,
  name,
  p,
}) => {
  const [showModal] = Hook.useShowModalHook('SuccessModal');
  const [showEditModal] = Hook.useShowModalHook('NotesModal');
  const describeValue = (
    <div style={{ fontSize: '14px', maxWidth: '500px', lineHeight: 2 }}>
      <strong>Deal Justification</strong><br />
      <span>{dealJustificationVal}</span>
      <br />
    </div>);

  const functionToShowNotes = () => {
    if (name === 'notes') {
      showEditModal({
        record: p.row.original, notes: notesVal, title: `${placeholder} Notes`, name, updateRecordFunction: p.updateRecordFunction,
      });
    } else if (notesVal) {
      // eslint-disable-next-line react/no-danger
      showModal({ title: `${placeholder} Notes`, message: <span dangerouslySetInnerHTML={{ __html: notesVal || '' }} /> });
    }
  };

  return (
    <div>
      <div style={{ float: 'left' }} >
        {val ? compareApprovalStatus[val] : ''}
      </div>
      <span style={{ marginTop: '0px', marginLeft: '10px', float: 'right' }}>
        {notesVal || name === 'notes' ? (
          <Tooltip title="Additional Notes">
            <IconButton
              size="small"
              onClick={() => functionToShowNotes()}
            >
              <FontAwesomeIcon icon={['fas', 'file-alt']} color={notesVal ? '#70bbfd' : ''} />
            </IconButton>
          </Tooltip>) :
          null
        }
        {dealJustificationVal ? (
          <WideTooltip title={describeValue} placement="bottom-start" >
            <IconButton
              size="small"
              // eslint-disable-next-line react/no-danger
              onClick={() => showModal({ title: 'Notes', message: <span dangerouslySetInnerHTML={{ __html: dealJustificationVal || '' }} /> })}
            >
              <FontAwesomeIcon icon={['fas', 'balance-scale']} color={dealJustificationVal ? '#70bbfd' : ''} />
            </IconButton>
          </WideTooltip>
          ) :
          null
        }
      </span>
    </div>
  );
};

DefaultApprovalStatus.propTypes = {
  val: PropTypes.string.isRequired,
  notesVal: PropTypes.string.isRequired,
  dealJustificationVal: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  p: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DefaultApprovalStatus;
