const InitialFilters = {
  statusFilters: {
    New: true,
    'Pending Edits': true,
    Rescore: true,
    'First Score': true,
    'Second Score': true,
    'Awaiting Brand Approval': true,
    'Brand Approved': true,
  },
  countFilters: {
    0: true,
    1: true,
    '2+': true,
  },
  dealTypeFilters: {
    'CPA/Ambassador': true,
    'Upfront Only': true,
    Hybrid: true,
    'Upfront CPM': true,
    'Agent Commission': true,
    'Media License': true,
  },
  specialCriteriaFilters: {
    'Oscar Worthy': true,
    'Not Oscar Worthy': true,
  },
  categoryFilters: {
    Assigned: true,
    'Not Assigned': true,
  },
  privacyStatus: {
    na: true,
    unlisted: true,
    public: true,
    private: true,
  },
};

export default InitialFilters;
