import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import withNotice from '../../../../../App/store/with-notice';
import Hook from '../../../../../../shared/hooks';

export const modalId = 'mediaContentTotals';

const MediaContentTotals = ({
  fetchTotalsData,
  totals,
  loadingTotals,
}) => {
  const { showModal: isShow, close } = Hook.useModalHook(modalId);
  useEffect(() => {
    if (isShow) {
      fetchTotalsData();
    }
  }, [isShow]);

  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '800px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Media Content Totals
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="4" className="text-center">
            <span style={{ textAlign: 'center', color: 'black' }} >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '12px',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {loadingTotals ? (<div className="spinner-border text-light spinner-border-sm" />) : totals.totalSubmittedContent}
              </span>
              <br style={{ lineHeight: '50%' }} />
              <span style={{ width: '100%', fontSize: '16px' }}>Content Submitted</span>
            </span>
          </Col>
          <Col xs="4" className="text-center">
            <span style={{ textAlign: 'center', color: 'black' }} >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '12px',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {loadingTotals ? (<div className="spinner-border text-light spinner-border-sm" />) : totals.uniqNumberCreators}
              </span>
              <br style={{ lineHeight: '50%' }} />
              <span style={{ width: '100%', fontSize: '16px' }}>No. Unique Creators</span>
            </span>
          </Col>
          <Col xs="4" className="text-center">
            <span style={{ textAlign: 'center', color: 'black' }} >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '12px',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {loadingTotals ? (<div className="spinner-border text-light spinner-border-sm" />) : totals.totalAverageScore}
              </span>
              <br style={{ lineHeight: '50%' }} />
              <span style={{ width: '100%', fontSize: '16px' }}>Average Score</span>
            </span>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <p>Totals based on the current filter(s) applied on the Media Content dashboard</p>
      </ModalFooter>
    </Modal>
  );
};

MediaContentTotals.propTypes = {
  fetchTotalsData: PropTypes.func.isRequired,
  totals: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingTotals: PropTypes.bool.isRequired,
};

export default withNotice(MediaContentTotals);
