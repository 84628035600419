/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  // Col,
  Modal,
  ModalBody,
  ModalHeader,
  // Row,
  Button, Spinner,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
import withNotice from '../../../../../App/store/with-notice';
import ProgressBar from '../../../../../../shared/helpers/ProgressBarWithObject';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';
import { hideModalById } from '../../../../../../redux/reducers/TableReducer';

export const modalCommentAnalysisId = 'runCommentNewSentimentAnalysisModal';
const RunSentimentAnalysis = ({
  afterSaveFunc,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const [startAnalysis, setStartAnalysis] = useState(false);
  const [logs, setLogs] = useState({});
  const [identity] = useState(Math.random().toString(36).substring(2));
  // const [singleCommentStatus, setSingleCommentStatus] = useState('');
  // const [singleNLPCommentStatus, setSingleNLPCommentStatus] = useState('');
  const [singleAiSentiment, setSingleAiSentiment] = useState('');
  const [singleAiBrand, setSingleAiBrand] = useState('');

  const modalInfo = useSelector(state => state.table.modals[modalCommentAnalysisId]);
  const dispatch = useDispatch();
  const close = () => dispatch(hideModalById(modalCommentAnalysisId));
  const { data: { ids }, isShow } = modalInfo || { data: { ids: [] }, isShow: false };

  useEffect(() => {
    if (isShow) {
      setStartAnalysis(false);
      setLogs({});
    }
  }, [isShow]);

  useEffect(() => {
    console.log(logs);
    // if (logs?.flaggedForReview) setSingleCommentStatus('Needs Review');
    // if (logs?.skipped) setSingleCommentStatus('Skipped');
    // if (!logs.flaggedForReview && !logs.skipped) setSingleCommentStatus('Passed Review');
    // if (logs?.nlpResult) setSingleNLPCommentStatus(logs.nlpResult);
    if (logs?.aiSentimentResult) setSingleAiSentiment(logs.aiSentimentResult);
    if (logs?.aiBrandResult) setSingleAiBrand(logs.aiBrandResult);
  }, [logs]);

  const submit = async () => {
    setLoading(true);
    setStartAnalysis(true);
    try {
      axios({
        method: 'post',
        url: '/api/portal/comments/post-run-comments-analysis',
        data: {
          commentIds: ids,
          identity,
        },
      }).then((response) => {
        console.log(response.data);
        if (response.data.success) {
          setLoading(false);
          setLogs({
            totalAnalyzed: response.data.results.analyzed,
            flaggedForReview: response.data.results.flagged,
            // skipped: response.data.results.skipped,
            // nlpResult: response.data.results.nlpResult,
            aiSentimentResult: response.data.results.aiSentimentResult,
            aiBrandResult: response.data.results.aiBrandResult,
          });
          afterSaveFunc();
        }
        if (response.data.error) {
          setLoading(false);
          console.log(response.data.error);
        }
      });
    } catch (err) {
      addNotice({
        message: 'Unable to run Sentiment Analysis for provided comments.',
        type: 'warning',
      });
      console.log(err);
      setLoading(false);
    }
    return true;
  };

  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      style={{ minWidth: '600px' }}
    >
      <ModalHeader toggle={close} tag="h4" className="modal-title-statistics">Run Sentiment Analysis</ModalHeader>
      <ModalBody>
        {!startAnalysis && (
          <>
            {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
            {!loading && (
              <div>{`${ids.length} comment${ids.length !== 1 ? 's' : ''} currently selected.`}</div>
            )}
          </>
        )}
        {ids.length > 1 && startAnalysis && (
          <>
            <ProgressBar
              topic="runManualSentimentNLPAnalysisTopic"
              identity={identity}
              // showAsDefault
              isShow={startAnalysis}
              receiveResults={(res) => {
                if (res.total) {
                  setLogs({
                    totalAnalyzed: res.analyzed,
                    flaggedForReview: res.flagged,
                    // skipped: res.skipped,
                  });
                }
              }}
            />
            {startAnalysis && (
              <div style={{ paddingLeft: '10px', paddingTop: '15px' }}>
                <h5>Comments analyzed: {logs.totalAnalyzed || 0}</h5>
                <h5>Comments flagged for review: {logs.flaggedForReview || 0}</h5>
                {/* <h5>Comments skipped (already analyzed): {logs.skipped || 0}</h5> */}
              </div>
            )}
          </>
        )}
        {startAnalysis && loading && ids.length === 1 && (
          <div className="text-center"><Spinner color="primary" size="lg" /></div>
        )}
        {startAnalysis && !loading && (
          <Alert color="success" className="mt-3 w-100">
            <p className="px-4 py-1" style={{ color: '#212529' }}>
              Sentiment AI Analysis complete.
              <br />
              {ids.length === 1 && (
                <span>
                  {`AI Sentiment analysis result - ${singleAiSentiment}.`}
                  <br />
                  {`AI Brand Relevancy analysis result - ${singleAiBrand}.`}
                </span>
              )}
            </p>
          </Alert>
        )}
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        {!startAnalysis && (
          <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
            {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
            <span>Run Sentiment Analysis</span>
          </Button>
        )}&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="secondary" onClick={close}>
          {startAnalysis && !loading ? 'Close' : 'Cancel'}
        </Button>
      </ButtonToolbar>
    </Modal>
  );
};

RunSentimentAnalysis.propTypes = {
  afterSaveFunc: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(RunSentimentAnalysis);
