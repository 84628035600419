import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  Button,
  Card, CardBody, CardTitle,
  Col, Container, Row,
} from 'reactstrap';
import moment from 'moment';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import TimePeriodFilter from '../../../../shared/components/filter/TimePeriodFilter';
import withNotice from '../../../App/store/with-notice';
// import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import getColumns from './components/table/Columns';
import Filters from './components/actions/Filters';
import hooks from '../../../../shared/hooks';
import LoadingReport from "../../../../shared/components/LoadingReport";

const datePickerOptions = [
  { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'year_to_date', label: 'Year to Date' },
  { value: '6_month', label: 'Last 6 Months' },
  {
    value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
  },
];

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  withSearchEngine: false,
  manualPageSize: [10, 20, 30, 40, 100],
  placeholder: 'Search...',
  sortBy: 'publishDate',
  direction: 'desc',
  useFlex: true,
};

const YTVideoCommentsBreakdownReport = ({ addNotice }) => {
  const [useShowForUsers] = hooks.useAccessHook({ hideWhenTeam: ['Auditors'] });
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [selectedButton, setSelectedButton] = useState('current_month');
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const fetchMediaContentsWithCommentsData = async () => {
    setLoading(true);
    setAllItems([]);
    try {
      const response = await axios.post('/api/portal/reports/post-media-contents-with-comments-data', {
        startDate,
        endDate,
        identity,
      });
      if (response.data.success) {
        setAllItems(response.data.items);
        setFilteredItems(response.data.items);
        setFetched(true);
      }
      // console.log(response.data);
    } catch (error) {
      console.error(error.message);
      addNotice({
        message: error.message,
        type: 'error',
      });
    }
    setLoading(false);
  };

  const columns = getColumns();
  if (!useShowForUsers) return null;
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Statistics', path: false },
              { title: 'YouTube Video Comment Breakdown', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>YouTube Video Comment Breakdown</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col>
                  <TimePeriodFilter
                    startDateInitial={startDate}
                    endDateInitial={endDate}
                    selectedButtonInitial={selectedButton}
                    setValue={(param, value, range) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
                      }
                      setSelectedButton(range);
                    }}
                    title="Publish Date"
                    options={datePickerOptions}
                  />
                </Col>
                <Col lg="auto">
                  <div style={{ marginTop: '26px' }}>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchMediaContentsWithCommentsData();
                      }}
                      className="mr-2 btn-sm mt-xl-0 mt-lg-3 mb-0"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {/* <Row className="mt-3">
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    identity={identity}
                    topic="youtubeVideoCommentReport"
                    isShow={!!loading}
                  />
                </Col>
              </Row> */}
              {!loading && fetched ? (
                <Filters
                  setFilteredItems={setFilteredItems}
                  allItems={allItems}
                />
              ) : null}
              <Row className="mt-3">
                <Col>
                  {!loading && fetched ? (
                    <ReactTableBase
                      key="searchable"
                      columns={columns}
                      data={filteredItems}
                      tableConfig={tableConfig}
                    />) : null
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

YTVideoCommentsBreakdownReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(YTVideoCommentsBreakdownReport);
