import React from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { Col, Row } from 'reactstrap';
import Loader from '../../../../../../shared/components/components/ui/loader';
import formater from '../../../../../../shared/helpers/WVFormatter';
import CurrencyField from '../../../../../../shared/components/form/CurrencyField';
import PercentsField from '../../../../../../shared/components/form/PercentsField';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';

class OfferPayoutsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      goals: [],
      goalsToSave: [],
      dataLoaded: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.calculateAndSaveOfferPayouts = this.calculateAndSaveOfferPayouts.bind(this);
    this.handlePayoutChange = this.handlePayoutChange.bind(this);
    this.updatePayoutForUpfront = this.updatePayoutForUpfront.bind(this);
  }

  componentDidMount() {
    const { offerId, creatorId } = this.props;
    const { loading } = this.state;

    if (offerId !== null && creatorId !== null && !loading) {
      this.fetchData(offerId, creatorId);
    }
  }

  componentDidUpdate(prevProps) {
    const { offerId, creatorId, dealType } = this.props;
    if ((prevProps.offerId !== this.props.offerId || prevProps.creatorId !== this.props.creatorId) && offerId !== null && creatorId !== null) {
      this.fetchData(this.props.offerId, this.props.creatorId);
    }
    if ((prevProps.creatorId !== null && this.props.creatorId === null) || (prevProps.offerId !== null && this.props.offerId === null)) {
      this.resetForm();
    }
    if (prevProps.dealType !== dealType) {
      const { goalsToSave } = this.state;
      if (dealType === 'Upfront CPM' || dealType === 'Upfront Only') {
        goalsToSave.forEach((goal) => {
          // eslint-disable-next-line no-param-reassign
          goal.payout = 0;
        });
      } else {
        goalsToSave.forEach((goal) => {
          // eslint-disable-next-line no-param-reassign
          goal.payout = null;
        });
      }
      this.updatePayoutForUpfront(goalsToSave);
    }
  }

  updatePayoutForUpfront(goalsToSave) {
    this.setState({ goalsToSave });
    this.props.saveOfferPayouts(goalsToSave);
  }

  resetForm() {
    this.setState({
      goals: [],
      goalsToSave: [],
      dataLoaded: false,
    });
    this.props.saveOfferPayouts([]);
  }

  calculateAndSaveOfferPayouts(defaultPayoutValue = null) {
    const { goals, goalsToSave } = this.state;
    goals.forEach((goal) => {
      goalsToSave.push({
        goalId: goal.id,
        payout: typeof goal.payout !== 'undefined' && goal.payout !== null ? parseFloat(goal.payout) : defaultPayoutValue,
        defaultPayout: goal.default_payout,
        percentPayout: typeof goal.percent_payout !== 'undefined' && goal.percent_payout !== null ? parseFloat(goal.percent_payout) : null,
        defaultPercentPayout: goal.default_percent_payout,
        type: goal.type,
      });
    });
    this.setState({
      goalsToSave,
    });
    this.props.saveOfferPayouts(goalsToSave);
  }

  handlePayoutChange(goalId, payout) {
    const { goalsToSave } = this.state;
    goalsToSave.forEach((goal, i) => {
      if (goal.goalId === goalId) {
        if (goal.type === 'dollar') {
          const payoutWithoutCommas = payout ? payout.replaceAll(',', '') : payout;
          const payoutToSet = payoutWithoutCommas !== null && parseFloat(payoutWithoutCommas) !== parseFloat(goal.defaultPayout) ? parseFloat(payoutWithoutCommas) : null;
          goalsToSave[i] = {
            goalId: goal.goalId,
            payout: payoutToSet,
            defaultPayout: goal.defaultPayout,
            percentPayout: goal.defaultPercentPayout,
            defaultPercentPayout: goal.defaultPercentPayout,
            type: goal.type,
          };
        } else {
          const percentPayoutToSet = payout !== null && parseFloat(payout) !== parseFloat(goal.defaultPercentPayout) ? parseFloat(payout) : null;
          goalsToSave[i] = {
            goalId: goal.goalId,
            payout: goal.payout,
            defaultPayout: goal.defaultPayout,
            percentPayout: percentPayoutToSet,
            defaultPercentPayout: goal.defaultPercentPayout,
            type: goal.type,
          };
        }
      }
    });
    this.setState({
      goalsToSave: [...goalsToSave],
    });
    this.props.saveOfferPayouts(goalsToSave);
  }

  fetchData(offerId, creatorId) {
    this.setState({
      loading: true,
    });
    axios({
      method: 'get',
      url: `/api/portal/deals/get-tune-goals?offerId=${offerId}&creatorId=${creatorId}`,
    }).then((response) => {
      const { dealType } = this.props;
      const defaultPayoutValue = (dealType === 'Upfront CPM' || dealType === 'Upfront Only') ? 0 : null;
      if (response.data.success) {
        this.setState({
          loading: false,
          goals: response.data.goals,
          goalsToSave: [],
          dataLoaded: true,
        }, () => { this.calculateAndSaveOfferPayouts(defaultPayoutValue); });
      } else {
        this.setState({
          loading: false,
          dataLoaded: false,
        });
      }
    });
  }

  render() {
    const {
      loading,
      goals,
      goalsToSave,
      dataLoaded,
    } = this.state;
    const { offerName } = this.props;

    return (
      <>
        {offerName} Offer Payouts
        <Row>
          <Col md="1" />
          <Col md="10">
            <Alert icon={false} bordered className="mt-2 mb-3">
              {loading ? <Loader /> :
              <div style={{ padding: '20px' }}>
                {this.props.offerId === null && (
                  <div style={{ textAlign: 'center' }}>
                    Select a Brand and Offer to view Offer payouts.
                  </div>
                )}
                {this.props.offerId !== null && this.props.creatorId === null && (
                  <div style={{ textAlign: 'center' }}>
                    Choose either Existing Creator or New Creator in the Creator Properties section to view Offer payouts.
                  </div>
                )}
                {dataLoaded && goals.length > 0 ? (
                  <>
                    <Row>
                      <Col xs="4"><b>Goal</b></Col>
                      <Col xs="3"><b>Payout</b></Col>
                      <Col xs="1" />
                      <Col xs="4" />
                    </Row>
                    {goals.map((goal) => {
                      let goalToSave;
                      goalsToSave.forEach((_goalToSave) => {
                        if (_goalToSave.goalId === goal.id) {
                          goalToSave = _goalToSave;
                        }
                      });
                      const goalPayout = typeof goal.payout !== 'undefined' && goal.payout !== null ? parseFloat(goal.payout) : null;
                      const goalPercentPayout = typeof goal.percentPayout !== 'undefined' && goal.percentPayout !== null ? goal.percentPayout.toString() : null;
                      const payout = goalToSave && goalToSave.payout !== goal.payout ? goalToSave.payout : goalPayout;
                      let percentPayout = goalToSave && goalToSave.percentPayout !== goal.percentPayout ? goalToSave.percentPayout : goalPercentPayout;
                      percentPayout = percentPayout !== null ? percentPayout.toString() : percentPayout;
                      return (
                        <Row key={`row-${goal.name}-${goal.payout}`}>
                          <Col xs="4" style={{ paddingTop: '5px' }}>
                            {goal.name}
                          </Col>
                          {goal.type === 'dollar' && (
                            <>
                              <Col xs="3" style={{ paddingTop: '5px' }}>
                                <CurrencyField
                                  key={`payout-${goal.id}-${payout}`}
                                  name="payout_amount"
                                  className="form-control"
                                  defaultValue={payout}
                                  allowEmptyFormatting={false}
                                  onBlur={(event) => {
                                    this.handlePayoutChange(goal.id, event.target.value);
                                  }}
                                />
                              </Col>
                              <Col xs="1" style={{ paddingTop: '11px', paddingLeft: '0' }}>
                                {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                <a
                                  onClick={() => {
                                    this.handlePayoutChange(goal.id, null);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                  title={`Reset the payout amount to the default amount of ${formater.formatCurrency(parseFloat(goal.default_payout))}`}
                                >
                                  <i className="fa fa-sync" aria-hidden="true" />
                                </a>
                              </Col>
                              <Col xs="4" style={{ paddingTop: '11px' }}>
                                Default is {formater.formatCurrency(parseFloat(goal.default_payout))}
                              </Col>
                            </>
                          )}
                          {goal.type === 'percent' && (
                            <>
                              <Col xs="3" style={{ paddingTop: '5px' }}>
                                <PercentsField
                                  key={`payout-${goal.id}-${percentPayout}`}
                                  name="payout_percentage"
                                  defaultValue={percentPayout}
                                  onBlur={(event) => {
                                    this.handlePayoutChange(goal.id, event.target.value);
                                  }}
                                />
                              </Col>
                              <Col xs="1" style={{ paddingTop: '11px', paddingLeft: '0' }}>
                                {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                <a
                                  onClick={() => {
                                    this.handlePayoutChange(goal.id, null);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                  title={`Reset the payout % the default % of ${formater.formatNumber(parseFloat(goal.default_percent_payout))}`}
                                >
                                  <i className="fa fa-sync" aria-hidden="true" />
                                </a>
                              </Col>
                              <Col xs="4" style={{ paddingTop: '11px' }}>
                                Default is {formater.formatNumber(parseFloat(goal.default_percent_payout))}%
                              </Col>
                            </>
                          )}
                        </Row>);
                    })}
                  </>
                ) : ''}
              </div>}
            </Alert>
          </Col>
          <Col md="1" />
        </Row>
      </>
    );
  }
}

OfferPayoutsForm.propTypes = {
  offerId: PropTypes.string,
  creatorId: PropTypes.string,
  offerName: PropTypes.string,
  saveOfferPayouts: PropTypes.func.isRequired,
  dealType: PropTypes.string,
};

OfferPayoutsForm.defaultProps = {
  offerId: null,
  creatorId: null,
  offerName: '',
  dealType: '',
};

export default OfferPayoutsForm;
