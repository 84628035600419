/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';
import { uid } from 'react-uid';
import formater from '../../../../../../shared/helpers/WVFormatter';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';

const calculateTotalPayables = (payables) => {
  let payableRevenue = 0;
  let payablePayout = 0;
  payables.forEach((payable) => {
    payableRevenue += (payable.revenueAmount || 0);
    payablePayout += (payable.ilPayoutAmount || 0);
  });
  return [payableRevenue, payablePayout];
};

export default ({ modal, influencer, setModal: setShowModal }) => {
  console.log();
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payablesTotal, setPayablesTotal] = useState([0, 0]);
  const [billTotalAmount, setBillTotalAmount] = useState(0);
  const [invoiceTotalAmount, setInvoiceTotalAmount] = useState(0);
  const [apiProfit, setApiProfit] = useState([0, 0]);
  const [cpmProfit, setCpmProfit] = useState([0, 0]);
  useEffect(async () => {
    if (!modal) return false;
    try {
      setLoading(true);
      const data = await axios.post('/api/portal/finance/post-creator-statistics', {
        id: influencer.id,
      });
      const {
        bills, payables, billTotal, invoiceTotal, deals: dealsResponce, invoices, lastMonthReports, currentMonthReports,
      } = data.data;
      setPayablesTotal(calculateTotalPayables(payables));
      setBillTotalAmount(billTotal);
      setInvoiceTotalAmount(invoiceTotal);
      console.log(calculateTotalPayables(payables));
      console.log(currentMonthReports, lastMonthReports);
      const totalProfitCurrentMonth = currentMonthReports.grossProfitHO; // currentMonthReports.reduce((accumulator, currentValue) => accumulator + (currentValue.revenue - currentValue.calculatedPayout), 0);
      const totalProfitLastMonth = lastMonthReports.grossProfitHO; // reduce((accumulator, currentValue) => accumulator + (currentValue.payableId ? 0 : (currentValue.revenue - currentValue.calculatedPayout)), 0);

      setApiProfit([totalProfitCurrentMonth, totalProfitLastMonth]);
      // const usedOffer = [];
      const cpmPrf = [0, 0];
      dealsResponce.forEach((deal) => {
        cpmPrf[0] += Number(deal.dealTerms.bh_cpm_total_cost || 0);
        cpmPrf[1] += Number(deal.dealTerms.guaranteed_payment_contract_amount || 0);
      });
      setCpmProfit(cpmPrf);
      setDeals(dealsResponce.map((deal) => {
        const dealToReturn = { deal };
        const bill = bills.find(b => b.deal.toString() === deal._id.toString());
        dealToReturn.bill = bill;
        const invoice = invoices.find(b => b.deal.toString() === deal._id.toString());
        dealToReturn.invoice = invoice;
        /* const dealActive = dealsResponce.find(d => d.dealTerms.cpa_status === 'Active' && deal.ilDealData.offer.toString() === d.ilDealData.offer.toString());

        if (usedOffer.includes(deal.ilDealData.offer.toString())) return dealToReturn;
        if ((dealActive && dealActive?._id === deal._id) || !dealActive) {
          const offer = offers.find(o => o._id.toString() === deal.ilDealData.offer.toString());
          console.log(offer, offer.tuneId);
          const currentMonthReport = currentMonthReports.find(report => console.log(report.offerId, offer.tuneId) || Number(report.offerId) === Number(offer.tuneId));
          console.log(currentMonthReport);
          const lastMonthReport = lastMonthReports.find(report => Number(report.offerId) === Number(offer.tuneId));
          console.log(currentMonthReports, lastMonthReport);
          dealToReturn.currentMonthRevenue = currentMonthReport?.revenue || 0;
          dealToReturn.currentMonthPayout = currentMonthReport?.calculatedPayout || 0;
          if (lastMonthReport && !lastMonthReport?.payableId) {
            dealToReturn.lastMonthRevenue = lastMonthReport?.revenue || 0;
            dealToReturn.lastMonthPayout = lastMonthReport?.calculatedPayout || 0;
          }
          console.log(dealToReturn);
          usedOffer.push(deal.ilDealData.offer.toString());
        } */
        return dealToReturn;
      }));
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
    return true;
  }, [modal]);
  const dealPipelines = getConstant('deal', 'pipelines', []);
  const dealStages = [];
  dealPipelines.forEach((pipeline) => {
    Object.keys(pipeline.stages).forEach((stageKey) => {
      dealStages.push({ id: stageKey, label: pipeline.stages[stageKey] });
    });
  });

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={() => setShowModal(false)}
      >
        <ModalHeader toggle={() => setShowModal(false)} tag="h4" className="pl-0">{influencer.params.first_name} {influencer.params.last_name} Gross Profit Calculation</ModalHeader>
        {loading ? (<div className="text-center mt-3"><Spinner color="primary" size="lg" /></div>) : null}
        {!loading ? (
          <div>
            <h5 className="mt-3">Accounts Payable Total Revenue {formater.formatCurrency(payablesTotal[0] || 0)}</h5>
            <h5>Accounts Payable Total Payout {formater.formatCurrency(payablesTotal[1] || 0)}</h5>
            <h5>Invoice Total Payout {formater.formatCurrency(invoiceTotalAmount || 0)}</h5>
            <h5>Bill Total Payout {formater.formatCurrency(billTotalAmount || 0)}</h5>
            <h5>Profit Calculated from HasOffers (Current Month): {formater.formatCurrency(apiProfit[0] || 0)}</h5>
            <h5>Profit Calculated from HasOffers (Last Month): {formater.formatCurrency(apiProfit[1] || 0)}</h5>
            <hr />
            {deals.map((d) => {
              const stage = dealStages.find(item => item.id === d.deal.dealstage);
              return (
                <div key={uid(d.deal.hs_object_id)}>
                  <h4><a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${d.deal.hs_object_id}/`} target="_blank" rel="noreferrer" >{d.deal.dealname}</a></h4>
                  <h5>Promo Code: {d.deal.dealTerms.coupon_code || '-'}</h5>
                  <h5>Content Type: {d.deal.dealTerms.content_type || '-'}</h5>
                  <h5><strong>Deal Type</strong>: {d.deal.dealTerms.type_of_deal}</h5>
                  <h5><strong>Brand Approved Date</strong>: {d.deal.brandApprovedDate ? moment(d.deal.brandApprovedDate.split('T')[0]).format('MM/DD/YY') : '-'}</h5>
                  <h5><strong>Content Release Date</strong>: {d.deal.content_release_date ? moment(d.deal.content_release_date.split('T')[0]).format('MM/DD/YY') : '-'}</h5>

                  <h5><strong>Deal Stage</strong>: {stage?.label}</h5>
                  <h5><strong>Deal Status</strong>: {d.deal.dealTerms.cpa_status}</h5>
                  <h5><strong>Deal Recoverable</strong>: {d.deal.dealTerms.guaranteed_payment_recoverable}</h5>
                  <h5><strong>Guaranteed Contract Amount</strong>: {formater.formatCurrency(d.deal.dealTerms.guaranteed_payment_contract_amount || 0)}</h5>
                  <h5><strong>BH CPM Total Costs</strong>: {formater.formatCurrency(Number(d.deal.dealTerms.bh_cpm_total_cost || 0))}</h5>
                  <hr />
                  <h5>Bill: {d.bill ? (<a href={`/resources/Bill/actions/list?filters.fullSearchName=${d.bill.invoiceNumber}`} target="_blank" rel="noreferrer">{d.bill.invoiceNumber}</a>) : '-'}</h5>
                  <h5>Invoice: {d.invoice ? (<a href={`/resources/Invoice/actions/list?filters.invoiceNumber=${d.invoice.invoiceNumber}`} target="_blank" rel="noreferrer">{d.invoice.invoiceNumber}</a>) : '-'}</h5>{' '}
                  <hr />
                </div>
              );
            })}
            <span><strong>Gross Profit:</strong>{' '}
              {formater.formatCurrency(payablesTotal[0] || 0)} -{' '}
              {formater.formatCurrency(payablesTotal[1] || 0)} -{' '}
              {formater.formatCurrency(billTotalAmount || 0)} +{' '}
              {formater.formatCurrency(apiProfit[0] || 0)} +{' '}
              {formater.formatCurrency(apiProfit[1] || 0)} +
              {formater.formatCurrency(Number(cpmProfit[0] || 0))}
              {' '}
              = {formater.formatCurrency((
                  Number((payablesTotal[0] || 0)) -
                  Number((payablesTotal[1] || 0)) -
                  Number(billTotalAmount || 0)
                ) + (
                  Number((apiProfit[0] || 0)) +
                  Number((apiProfit[1] || 0))
                ) + (
                  Number(cpmProfit[0] || 0)
                ))}
            </span>
          </div>) : null}
        <ModalFooter className="modal__footer">
          <Button
            className="btn-sm modal_cancel"
            color="secondary"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
