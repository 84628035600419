// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col, Button, Label, FormGroup,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import Select from 'react-select';
import IntervalDatePickerField from '../../../Prospects/components/custom/IntervalDatePickerField';
import { getCookie, setCookie } from '../../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';

const Actions = React.memo(({
  dealFilters,
  setDealFilters,
  recycledFilters,
  setRecycledFilters,
  lastActivityDateValue,
  setLastActivityDateValue,
  enableLastAd,
  setEnableLastAd,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  singleDate,
  setSingleDate,

  enableRecycledDate,
  setEnableRecycledDate,
  recycledDateValue,
  setRecycledDateValue,
  recycledDateFrom,
  setRecycledDateFrom,
  recycledDateTo,
  setRecycledDateTo,
  recycledSingleDate,
  setRecycledSingleDate,

  onSearchChange,
  tagsSuggestions,
  setTagsFilter,
  setTags,
  tags,
  getUrlSearch,
  searchValue,
  ownersSuggestions,
  setOwnersFilter,
  setOwners,
  owners,
  showRecycleModal,
  disableRecycle,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('researchRecycleLeadsShowFilters') === 'true');

  useEffect(() => {
    setCookie('researchRecycleLeadsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const reactTagsTags = React.createRef();
  const reactTagsOwners = React.createRef();

  const lastActivityDateLabels = [
    { value: '=', label: '=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'Between', label: 'Between' },
  ];
  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
      height: '44px',
    }),
  };
  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);
  useEffect(() => {
    if (lastActivityDateValue?.value === 'Between') {
      setSingleDate(null);
    }
    if (lastActivityDateValue?.value !== 'Between') {
      setDateFrom(null);
      setDateTo(null);
    }
  }, [lastActivityDateValue]);

  const setFiltersTag = (tagsNew) => {
    const tagsFilterNew = {};
    if (tagsNew.length > 0) {
      tagsNew.map((item) => {
        tagsFilterNew[item.name] = true;
        return true;
      });
    }
    setTagsFilter(tagsFilterNew);
  };

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setFiltersTag(tagsNew);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setFiltersTag(tagsNew);
    setTags(tagsNew);
  };

  const setFiltersOwner = (ownersNew) => {
    const ownersFilterNew = {};
    if (ownersNew.length > 0) {
      ownersNew.map((item) => {
        ownersFilterNew[item.id] = true;
        return true;
      });
    }
    setOwnersFilter(ownersFilterNew);
  };

  const onDeleteOwner = (i) => {
    const ownersNew = owners.slice(0);
    ownersNew.splice(i, 1);
    setFiltersOwner(ownersNew);
    setOwners(ownersNew);
  };

  const onAdditionOwner = (owner) => {
    const ownersNew = [].concat(owners, owner);
    setFiltersOwner(ownersNew);
    setOwners(ownersNew);
  };

  const handleChangeLastADCheckBox = ({ target }) => {
    setEnableLastAd(!enableLastAd);
    if (!target.checked) {
      setSingleDate(null);
      setDateTo(null);
      setDateFrom(null);
    }
  };
  const handleChangeRecycledDateCheckBox = ({ target }) => {
    setEnableRecycledDate(!enableRecycledDate);
    if (!target.checked) {
      setRecycledSingleDate(null);
      setRecycledDateTo(null);
      setRecycledDateFrom(null);
    }
  };

  const handleChangeDealsFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setDealFilters({
        ...dealFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeRecycledFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setRecycledFilters({
        ...recycledFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setDealFilters({
      Published: true,
      'Not Published': true,
    });
    setRecycledFilters({
      Yes: true,
      No: true,
    });
    setTags([]);
    setTagsFilter({});
    setOwners([]);
    setOwnersFilter({});
    setEnableLastAd(false);
    setLastActivityDateValue({ value: '<=', label: '<=' });
    setSingleDate(null);
    setDateTo(null);
    setDateFrom(null);
    setEnableRecycledDate(false);
    setRecycledDateValue({ value: '<=', label: '<=' });
    setRecycledSingleDate(null);
    setRecycledDateTo(null);
    setRecycledDateFrom(null);
  };

  return (
    <div>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search First Name, Last Name, Email"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                disabled={disableRecycle}
                onClick={() => { showRecycleModal(false); }}
              >
                Recycle
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => window.open(`/resources/recycled-leads/download-csv?${getUrlSearch().toString()}`, '_blank').focus()}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row className="mb-2">
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '8px' }}>
                Deals
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="PublishedDeals"
                  label="Published"
                  value={dealFilters.Published}
                  onChange={event => handleChangeDealsFilters(event, 'Published')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="NotPublishedDeals"
                  label="Not Published"
                  value={dealFilters['Not Published']}
                  onChange={event => handleChangeDealsFilters(event, 'Not Published')}
                />
              </FormGroup>
            </Col>
            <Col sm={1}>
              <Label className="bold-text" style={{ marginBottom: '8px' }}>
                Recycled?
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="YesRecycled"
                  label="Yes"
                  value={recycledFilters.Yes}
                  onChange={event => handleChangeRecycledFilters(event, 'Yes')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="NoRecycled"
                  label="No"
                  value={recycledFilters.No}
                  onChange={event => handleChangeRecycledFilters(event, 'No')}
                />
              </FormGroup>
            </Col>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Row>
                    <Col sm={1} />
                    <Col sm={11} style={{ paddingLeft: '20px' }}>
                      <Label className="bold-text" style={{ marginBottom: '8px' }}>
                        Last Activity Date
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={1}>
                      <FormGroup
                        style={{ paddingTop: '13px', paddingLeft: '8px' }}
                      >
                        <CheckBoxField
                          name="lastActivityDateCheckBox"
                          value={enableLastAd}
                          onChange={e => e.target && handleChangeLastADCheckBox(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={5} style={{ paddingLeft: '20px' }}>
                      <Select
                        value={lastActivityDateValue}
                        onChange={setLastActivityDateValue}
                        options={lastActivityDateLabels}
                        isDisabled={!enableLastAd}
                        styles={customStyles}
                      />
                    </Col>
                    <Col sm={6}>
                      {lastActivityDateValue.value === 'Between' ? (
                        <div style={{ marginRight: '-30px' }} className="pb-2">
                          <IntervalDatePickerField
                            onChange={({ start, end }) => {
                              setDateFrom(start);
                              end?.setHours(23, 59, 59);
                              setDateTo(end);
                            }}
                            startDate={dateFrom}
                            endDate={dateTo}
                            maxDate={new Date()}
                            placeholderFrom="Start Date"
                            placeholderTo="End Date"
                            verticalFields
                            disabled={!enableLastAd}
                          />
                        </div>
                      ) : (
                        <div className="date-picker datepicker-in-prospects">
                          <DatePicker
                            selected={singleDate}
                            onChange={(date) => {
                              if (lastActivityDateValue.value === '<=' || lastActivityDateValue.value === '>') {
                                date?.setHours(23, 59, 59);
                              }
                              setSingleDate(date);
                            }}
                            dateFormat="MMM d, yyyy"
                            maxDate={new Date()}
                            placeholderText="Select Date"
                            dropDownMode="select"
                            isClearable
                            wrapperClassName="date-picker--interval w-100"
                            className="form-control form-control-sm datepicker-in-prospects w-100"
                            disabled={!enableLastAd}
                          />
                          {singleDate === null && (
                            <svg
                              className="mdi-icon"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              style={{
                                position: 'absolute',
                                top: '13px',
                                right: '22px',
                                fill: '#999',
                                pointerEvents: 'none',
                              }}
                            >
                              <path
                                d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                              />
                            </svg>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col sm={6}>
                  <Row>
                    <Col sm={1} />
                    <Col sm={11} style={{ paddingLeft: '20px' }}>
                      <Label className="bold-text" style={{ marginBottom: '8px' }}>
                        Recycled Date
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={1}>
                      <FormGroup
                        style={{ paddingTop: '13px', paddingLeft: '8px' }}
                      >
                        <CheckBoxField
                          name="recycledDateCheckBox"
                          value={enableRecycledDate}
                          onChange={e => e.target && handleChangeRecycledDateCheckBox(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={5} style={{ paddingLeft: '20px' }}>
                      <Select
                        value={recycledDateValue}
                        onChange={setRecycledDateValue}
                        options={lastActivityDateLabels}
                        isDisabled={!enableRecycledDate}
                        styles={customStyles}
                      />
                    </Col>
                    <Col sm={6}>
                      {recycledDateValue.value === 'Between' ? (
                        <div style={{ marginRight: '-30px' }} className="pb-2">
                          <IntervalDatePickerField
                            onChange={({ start, end }) => {
                              setRecycledDateFrom(start);
                              end?.setHours(23, 59, 59);
                              setRecycledDateTo(end);
                            }}
                            startDate={recycledDateFrom}
                            endDate={recycledDateTo}
                            maxDate={new Date()}
                            placeholderFrom="Start Date"
                            placeholderTo="End Date"
                            verticalFields
                            disabled={!enableRecycledDate}
                          />
                        </div>
                      ) : (
                        <div className="date-picker datepicker-in-prospects">
                          <DatePicker
                            selected={recycledSingleDate}
                            onChange={(date) => {
                              if (recycledDateValue.value === '<=' || recycledDateValue.value === '>') {
                                date?.setHours(23, 59, 59);
                              }
                              setRecycledSingleDate(date);
                            }}
                            dateFormat="MMM d, yyyy"
                            maxDate={new Date()}
                            placeholderText="Select Date"
                            dropDownMode="select"
                            isClearable
                            wrapperClassName="date-picker--interval w-100"
                            className="form-control form-control-sm datepicker-in-prospects"
                            disabled={!enableRecycledDate}
                          />
                          {recycledSingleDate === null && (
                            <svg
                              className="mdi-icon"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              style={{
                                position: 'absolute',
                                top: '13px',
                                right: '22px',
                                fill: '#999',
                                pointerEvents: 'none',
                              }}
                            >
                              <path
                                d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                              />
                            </svg>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Label className="bold-text" style={{ marginBottom: '8px' }}>
                Influencer Tag(s)
              </Label>
              <ReactTags
                ref={reactTagsTags}
                tags={tags}
                suggestions={tagsSuggestions}
                onDelete={onDeleteTag}
                onAddition={onAdditionTag}
                delimiters={['Enter', ',']}
                placeholderText="Influencer Tag name(s)"
              />
            </Col>
            <Col sm={6}>
              <Label className="bold-text" style={{ marginBottom: '8px' }}>
                Contact Owner(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsOwners}
                tags={owners}
                suggestions={ownersSuggestions}
                onDelete={onDeleteOwner}
                onAddition={onAdditionOwner}
                delimiters={['Enter', ',']}
                placeholderText="Contact Owner name(s)"
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  // a.lastActivityDateValue === a1.lastActivityDateValue &&
  a.enableLastAd === a1.enableLastAd &&
  a.enableRecycledDate === a1.enableRecycledDate &&
  _.isEqual(a.singleDate, a1.singleDate) &&
  _.isEqual(a.recycledSingleDate, a1.recycledSingleDate) &&
  _.isEqual(a.dateTo, a1.dateTo) &&
  _.isEqual(a.recycledDateTo, a1.recycledDateTo) &&
  _.isEqual(a.dateFrom, a1.dateFrom) &&
  _.isEqual(a.recycledDateFrom, a1.recycledDateFrom) &&
  _.isEqual(a.selectedRecordIds, a1.selectedRecordIds) &&
  _.isEqual(a.tags, a1.tags) &&
  _.isEqual(a.owners, a1.owners) &&
  _.isEqual(a.recycledFilters, a1.recycledFilters) &&
  _.isEqual(a.dealFilters, a1.dealFilters) &&
  _.isEqual(a.tagsSuggestions, a1.tagsSuggestions) &&
  _.isEqual(a.ownersSuggestions, a1.ownersSuggestions) &&
  _.isEqual(a.recycledDateValue, a1.recycledDateValue) &&
  _.isEqual(a.lastActivityDateValue, a1.lastActivityDateValue));
Actions.propTypes = {
  dealFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setDealFilters: PropTypes.func.isRequired,
  recycledFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setRecycledFilters: PropTypes.func.isRequired,
  lastActivityDateValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setLastActivityDateValue: PropTypes.func.isRequired,
  dateFrom: PropTypes.objectOf(PropTypes.any),
  setDateFrom: PropTypes.func.isRequired,
  dateTo: PropTypes.objectOf(PropTypes.any),
  setDateTo: PropTypes.func.isRequired,
  singleDate: PropTypes.objectOf(PropTypes.any),
  setSingleDate: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  getUrlSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  tagsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsFilter: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  ownersSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setOwnersFilter: PropTypes.func.isRequired,
  setOwners: PropTypes.func.isRequired,
  owners: PropTypes.arrayOf(PropTypes.any).isRequired,
  showRecycleModal: PropTypes.func.isRequired,
  disableRecycle: PropTypes.bool.isRequired,
  enableLastAd: PropTypes.bool.isRequired,
  setEnableLastAd: PropTypes.func.isRequired,
  enableRecycledDate: PropTypes.bool.isRequired,
  setEnableRecycledDate: PropTypes.func.isRequired,
  // selectedRecordIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  recycledDateValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setRecycledDateValue: PropTypes.func.isRequired,
  recycledDateFrom: PropTypes.objectOf(PropTypes.any),
  setRecycledDateFrom: PropTypes.func.isRequired,
  recycledDateTo: PropTypes.objectOf(PropTypes.any),
  setRecycledDateTo: PropTypes.func.isRequired,
  recycledSingleDate: PropTypes.objectOf(PropTypes.any),
  setRecycledSingleDate: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  dateFrom: null,
  dateTo: null,
  singleDate: null,
  recycledDateFrom: null,
  recycledDateTo: null,
  recycledSingleDate: null,
};

export default Actions;
