import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { unflatten } from 'flat';
import { axios } from 'ApiClient';
import { useSelector } from 'react-redux';

const DealJustification = React.memo(({
  p,
}) => {
  const dealJustificationOption = useSelector(state => state.tableRedux.tablesProperties || []).find(d => d.filterName === 'dealJustification')?.options || [];

  const { updateRecordFunction } = p;

  const rowData = p.row.original.params;
  const val = rowData.dealJustification;
  // console.log(val, rowData, 'valueJustification');
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setValue(val);
  }, [rowData._id, val]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option.value);
    try {
      const data = await axios.post('/api/portal/prospects/post-update-property-on-cpm-prospect', {
        ids: [rowData._id],
        values: {
          dealJustification: option.value,
        },
      });
      const record = unflatten(data?.data?.recordPopulated);
      updateRecordFunction(rowData._id, { dealJustification: record.params.dealJustification });
      setValue(record.params.dealJustification);
    } catch (e) {
      console.log(e);
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };
  return (
    <div className="w-100">
      <Select
        value={dealJustificationOption.filter(option => option.value === value)}
        options={dealJustificationOption}
        onChange={handleChange}
        className="statusBox"
        placeholder="Justification"
        isDisabled={disabled}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
      {error ? (<span className="text-danger">{error}</span>) : null}
    </div>
  );
}, (a, a1) => _.isEqual(a.p, a1.p));

DealJustification.propTypes = {
  p: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DealJustification;
