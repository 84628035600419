import React from 'react';
import { unflatten } from 'flat';
import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import PercentsField from '../../../../../../shared/components/form/PercentsField';

export default class EmployeeCommissionReportField extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    updateRecordFunction: PropTypes.func.isRequired,
    fieldId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.api = new ApiClient();
    const { record, fieldId } = this.props;
    let fieldValue = '';
    if (fieldId === 'commissionRate') {
      fieldValue = record[fieldId].$numberDecimal;
    } else {
      fieldValue = record[fieldId];
    }
    this.state = {
      fieldValue,
      loading: false,
    };
  }

  handleChange(event) {
    this.setState({ loading: true });
    const { record, updateRecordFunction, fieldId } = this.props;

    const { value } = event.target;
    const { _id } = record;
    const data = new FormData();
    data.append(fieldId, value);
    this.api.recordAction({
      resourceId: 'EmployeeCommissionReport',
      recordId: _id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      this.api.recordAction({
        resourceId: 'EmployeeCommissionReport',
        recordId: _id,
        actionName: 'show',
      }).then((e) => {
        this.setState({ loading: false });
        updateRecordFunction(unflatten(e.data.record), fieldId);
      });
    });
    return true;
  }

  render() {
    const { loading, fieldValue } = this.state;
    const { fieldId } = this.props;
    return (
      <>
        {fieldId === 'commissionRate' ? <PercentsField disabled={loading} defaultValue={fieldValue} onBlur={this.handleChange} />
          : <input type="text" className="form-control" disabled={loading} defaultValue={fieldValue} onBlur={this.handleChange} />}
      </>
    );
  }
}
