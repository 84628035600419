import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import ReactTableBase from '../../../../../../shared/tables/table/ReactTableBase';
import TableCellContextMenu from '../../../../../../shared/components/table/TableCellContextMenu';
import CreateSettingModal from './CreateSettingModal';
import DeleteModalDialog from './DeleteModalDialog';
import {
  getMyWatchlistsForGrid,
  getMySavedSearchesForGrid,
} from '../../../../../../redux/reducers/admin/ResearchReducer';

const SettingsListContainer = ({
  dispatch,
  watchlists,
  loadingWatchlists,
  savedSearches,
  loadingSavedSearches,
}) => {
  const [activeTab, setActiveTab] = useState('watchlists');
  const [editId, setEditId] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const editSetting = (id) => {
    setEditId(id);
    setCreateModalVisible(true);
  };

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search names',
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value }) => value,
        width: 300,
      },
      {
        Header: 'Owner',
        accessor: 'adminUserOwner',
        Cell: ({ value }) => `${value?.firstName ? value.firstName : ''} ${value?.lastName ? value.lastName : ''}`.trim(),
        disableGlobalFilter: true,
        width: 300,
      },
      {
        Header: 'Visibility',
        accessor: 'visibility',
        Cell: ({ value }) => value.charAt(0).toUpperCase() + value.slice(1),
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: '',
        accessor: 'canBeEditedByCurrentUser',
        Cell: (p) => {
          if (p.value.includes('YES_')) {
            return (
              <div
                style={{ textAlign: 'right' }}
              >
                <TableCellContextMenu
                  options={[
                    { label: 'Edit', handler: () => { editSetting(p.value.slice(4, p.value.length)); } },
                    { label: 'Delete', handler: () => { setDeleteId(p.value.slice(4, p.value.length)); setDeleteModalVisible(true); } },
                  ]}
                />
              </div>);
          }
          return null;
        },
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 50,
        maxWidth: 50,
      },
    ],
    [],
  );

  const newWatchlistSaved = () => {
    dispatch(getMyWatchlistsForGrid());
  };

  const watchlistDeleted = () => {
    dispatch(getMyWatchlistsForGrid());
  };

  const searchSaved = () => {
    dispatch(getMySavedSearchesForGrid());
  };

  const searchDeleted = () => {
    dispatch(getMySavedSearchesForGrid());
  };

  useEffect(() => {
    dispatch(getMyWatchlistsForGrid());
    dispatch(getMySavedSearchesForGrid());
  }, []);

  return (
    <>
      <Row>
        <Col md="3">
          <h2 style={{ marginBottom: '15px' }}>Settings</h2>
        </Col>
        <Col md="6" />
        <Col md="3" style={{ textAlign: 'right' }}>
          {activeTab === 'watchlists' && (
            <button
              onClick={() => { setEditId(false); setCreateModalVisible(true); }}
              className="btn btn-primary btn-sm mt-3 mb-0"
            >
              Add List
            </button>)}
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row className="mb-3">
            <Col className="tabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'watchlists' })}
                    onClick={() => {
                      setActiveTab('watchlists');
                    }}
                  >
                    Watchlist
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'savedSearches' })}
                    onClick={() => {
                      setActiveTab('savedSearches');
                    }}
                  >
                    Saved Searches
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="research-dashboard-container">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="watchlists">
                  {!loadingWatchlists && (
                    <ReactTableBase
                      key="watchlists"
                      columns={columns}
                      data={watchlists}
                      tableConfig={tableConfig}
                    />
                  )}
                </TabPane>
                <TabPane tabId="savedSearches">
                  {!loadingSavedSearches && (
                    <ReactTableBase
                      key="savedSearches"
                      columns={columns}
                      data={savedSearches}
                      tableConfig={tableConfig}
                    />
                  )}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <CreateSettingModal
        isOpen={createModalVisible}
        recordId={editId}
        toggleModal={() => setCreateModalVisible(false)}
        onSaveRecordFunc={activeTab === 'watchlists' ? newWatchlistSaved : searchSaved}
        resourceId={activeTab === 'watchlists' ? 'ResearchWatchlist' : 'ResearchSavedSearch'}
      />
      <DeleteModalDialog
        isOpen={deleteModalVisible}
        id={deleteId}
        toggleModal={() => setDeleteModalVisible(false)}
        onDeleteRecordFunc={activeTab === 'watchlists' ? watchlistDeleted : searchDeleted}
        resourceId={activeTab === 'watchlists' ? 'ResearchWatchlist' : 'ResearchSavedSearch'}
      />
    </>
  );
};
SettingsListContainer.propTypes = {
  loadingWatchlists: PropTypes.bool.isRequired,
  watchlists: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  loadingSavedSearches: PropTypes.bool.isRequired,
  savedSearches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  dispatch: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  loadingWatchlists: state.ResearchReducer.loading.watchlistsGrid,
  watchlists: state.ResearchReducer.watchlistsForGrid,
  loadingSavedSearches: state.ResearchReducer.loading.savedSearchesGrid,
  savedSearches: state.ResearchReducer.savedSearchesForGrid,
});

export default connect(mapStateToProps)(withRouter(SettingsListContainer));
