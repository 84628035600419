import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import CreatorInList from './CreatorInList';
import BrandStatus from './BrandStatus';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import MatTableList from '../../../../../../../shared/tables/materialTable/MatTableList';
import UserPropertyType from './index';
import InfluencelogicStatus from './InfluencelogicStatus';
import TargetBrand from './TargetBrand';
import RejectionReason from './RejectionReason';
import TableCellContextMenu from './TableCellContextMenu';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { modalId } from './modals/ResubmitProspectModal';
import { modalId as editTagsModalId } from './modals/EditTagsModal';
import ErrorBoundary from '../../../../../../../shared/components/ErrorBoundary';


const Table = React.memo(({
  action,
  resource,
  version,
  setDisableNote,
  setCurrentProspect,
  setShowNotesModal,
  setShowDetailsModal,
  setShowHistoryModal,
  setShowEditTagsModal,
  setPortal,
  goToEditProspectPage,
}) => {
  const dispatch = useDispatch();
  return (
    <MatTableList
      action={action}
      resource={resource}
      date={new Date()}
      version={version}
      UserPropertyType={UserPropertyType}
      showFilters={false}
      showTitle={false}
      sortBy="createdAt"
      direction="desc"
      v={2}
      noActions
      isResizable={false}
      useFlex
      notFoundProps={{ title: 'No Internal Prospects found' }}
      useUnflatten
      customColumns={[
        {
          id: 'fullSearchName',
          Header: () => 'Creator',
          accessor: () => 'fullSearchName',
          width: 300,
          className: 'd-flex align-items-center',
          Cell: p => useMemo(
            () => (
              <ErrorBoundary portal="admin.internalProspects.creator" partial tiny >
                <CreatorInList
                  record={p.row.original}
                  editHandler={() => { goToEditProspectPage(p.row.original.id); }}
                />
              </ErrorBoundary>
            ),
            [p.row.original],
          ),
        },
        {
          id: 'createdAt',
          Header: () => 'Create Date (ET)',
          className: 'd-flex align-items-center',
          accessor: () => '',
          width: 140,
          Cell: p => useMemo(() => (
            <div style={{ width: '160px', paddingRight: '10px' }}>
              <span>{WVFormatter.getServerDateTime(p.row.original.params?.createdAt)}</span>
            </div>
          ), [p.row.original]),
        },
        {
          id: 'prospectOwner',
          width: 140,
          className: 'd-flex align-items-center',
          accessor: () => '',
          Header: () => 'Prospect Owner',
          Cell: p => useMemo(() => (
            <div>
              <span>{p.row.original?.populated?.prospectOwner && p.row.original.populated.prospectOwner.params ?
                `${p.row.original.populated.prospectOwner.params.firstName} ${p.row.original.populated.prospectOwner.params.lastName}` :
                '-- Not Assigned --'
              }
              </span>
            </div>
          ), [p.row.original]),
          disableSortBy: true,
        },
        {
          id: 'resubmitDate',
          Header: () => 'Submit Date (ET)',
          className: 'd-flex align-items-center',
          accessor: () => '',
          width: 140,
          Cell: p => useMemo(() => (
            <div style={{ width: '160px', paddingRight: '10px' }}>
              <span>{WVFormatter.getServerDateTime(p.row.original.params?.resubmitDate)}</span>
            </div>
          ), [p.row.original]),
        },
        {
          id: 'adminUser',
          width: 125,
          className: 'd-flex align-items-center',
          accessor: () => '',
          Header: () => 'Submitted By',
          Cell: p => useMemo(() => (
            <div>
              <span>{p.row.original?.populated?.adminUser && p.row.original.populated.adminUser.params ?
                `${p.row.original.populated.adminUser.params.firstName} ${p.row.original.populated.adminUser.params.lastName}` :
                '-- Not Assigned --'
              }
              </span>
            </div>
          ), [p.row.original]),
          disableSortBy: true,
        },
        {
          id: 'approvalStatus.influencelogic.status',
          Header: () => 'InfluenceLogic Status',
          Cell: p => useMemo(() => (
            <ErrorBoundary portal="admin.internalProspects.ilstatus" partial tiny >
              <InfluencelogicStatus
                record={p.row.original}
                setShowNotesModal={setShowNotesModal}
                setCurrentProspect={setCurrentProspect}
                setDisableNote={setDisableNote}
                setPortal={setPortal}
              />
            </ErrorBoundary>
          ), [p.row.original]),
          width: 140,
          className: 'd-flex align-items-center',
        },
        {
          id: 'approvalStatus.brand.status',
          Header: () => 'Brand Status',
          Cell: p => useMemo(() => (
            <ErrorBoundary portal="admin.internalProspects.brandstatus" partial tiny >
              <BrandStatus
                record={p.row.original}
                setShowNotesModal={setShowNotesModal}
                setCurrentProspect={setCurrentProspect}
                setDisableNote={setDisableNote}
                setPortal={setPortal}
              />
            </ErrorBoundary>
            ), [p.row.original]),
          width: 160,
          className: 'd-flex align-items-center',
        },
        {
          width: 160,
          id: 'rejectionReason.value',
          className: 'd-flex align-items-center',
          Header: () => 'Rejection Reason',
          Cell: p => useMemo(() => (
            <ErrorBoundary portal="admin.internalProspects.reason" partial tiny >
              <RejectionReason
                record={p.row.original}
                setShowNotesModal={setShowNotesModal}
                setCurrentProspect={setCurrentProspect}
                setDisableNote={setDisableNote}
                setPortal={setPortal}
              />
            </ErrorBoundary>
            ), [p.row.original]),
        },
        {
          id: 'approvalStatus.brand.targetAdvertiserId',
          Header: () => 'Target Brand',
          accessor: () => '',
          width: 120,
          className: 'd-flex align-items-center',
          Cell: p => useMemo(() => (
            <ErrorBoundary portal="admin.internalProspects.reason" partial tiny >
              <TargetBrand
                record={p.row.original}
              />
            </ErrorBoundary>
          ), [p.row.original]),
        },
        {
          id: 'resubmitStatus',
          Header: () => 'Resubmitted',
          width: 120,
          Cell: p => useMemo(() => (
            <div className="text-center">
              {p.row.original.params.resubmitStatus ? 'Yes' : 'No'}
            </div>
          ), [p.row.original]),
          className: 'd-flex align-items-center justify-content-center',
        },
        {
          Header: '',
          accessor: () => null,
          id: 'actionProspect',
          width: 50,
          Cell: (p) => {
            const contextMenuOptions = [
              { label: 'Details', handler: () => { setCurrentProspect(p.row.original); setShowDetailsModal(true); } },
              {
                label: 'Change History',
                handler: () => { setCurrentProspect(p.row.original); setShowHistoryModal(true); },
              },
              {
                label: 'Edit Tags',
                handler: () => { dispatch(showModalById(editTagsModalId, { record: p.row.original, updateRecordFunction: p.updateRecordFunction })); },
              },
            ];
            if (
              (p.row.original.params?.approvalStatus?.brand?.status === 'Rejected' && p.row.original.params?.approvalStatus?.influencelogic?.status === 'Approved')
              || p.row.original.params?.approvalStatus?.influencelogic?.status === 'Rejected' || p.row.original.params?.approvalStatus?.influencelogic?.status === 'Hold'
              ) {
              contextMenuOptions.push({
                label: 'Resubmit',
                handler: () => { dispatch(showModalById(modalId, { record: p.row.original, updateRecordFunction: p.updateRecordFunction })); },
              });
            }
            return (
              <div style={{ paddingTop: '8px' }} className="text-right">
                <TableCellContextMenu options={contextMenuOptions} />
              </div>
            );
          },
          disableSortBy: true,
          className: 'd-flex align-items-center align-middle',
        },
      ]}
    />
  );
}, (a, a1) => _.isEqual(a.version, a1.version) && _.isEqual(a.brandSafetySearchTerms, a1.brandSafetySearchTerms) && _.isEqual(a.brands, a1.brands));

Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  // setVersion: PropTypes.func.isRequired,
  setCurrentProspect: PropTypes.func.isRequired,
  setShowNotesModal: PropTypes.func.isRequired,
  goToEditProspectPage: PropTypes.func.isRequired,

  setDisableNote: PropTypes.func.isRequired,

  setShowDetailsModal: PropTypes.func,
  setShowHistoryModal: PropTypes.func,
  setShowEditTagsModal: PropTypes.func,
  setPortal: PropTypes.func.isRequired,
};

Table.defaultProps = {
  setShowDetailsModal: () => {},
  setShowHistoryModal: () => {},
  setShowEditTagsModal: () => {},
};
export default Table;
