import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import withNotice from '../../../../App/store/with-notice';

const dealTypeOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Expired', label: 'Expired' },
];

const getStatusesFromRecord = (record) => {
  if (record?.params?.dealCPAStatuses?.length > 0) {
    return dealTypeOptions.filter(item => record.params.dealCPAStatuses.includes(item.value));
  }
  return [];
}

const DealCPAStatusesInList = ({ addNotice, record }) => {
  const [loading, setLoading] = useState(false);
  const [dealStatuses, setDealStatuses] = useState(getStatusesFromRecord(record));

  useEffect(async () => {
    setDealStatuses(getStatusesFromRecord(record));
  }, [record?.params?.dealCPAStatuses]);

  const sendRequest = (newValue, oldValue) => {
    setLoading(true);

    axios.post('/api/portal/compliance/post-edit-content-review-rule', {
      id: record.id,
      fieldsToUpdate: { dealCPAStatuses: newValue || [] },
    }).then((response) => {
      if (response?.data?.success) {
        addNotice({
          message: 'Rule was successfully updated.',
          type: 'success',
        });
      }
      if (response?.data?.error) {
        console.error(response.data.error);
        setDealStatuses(oldValue);
        addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
      setLoading(false);
    });
    return true;
  };

  const onChange = (values) => {
    const oldValue = dealStatuses;
    setDealStatuses(values);
    const newValue = values.map(item => item.value);
    sendRequest(newValue, oldValue);
  };

  return (
    <div className="w-100" style={{ marginBottom: 0 }}>
      <Select
        className="dealCPAStatuses"
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Deal Status"
        value={dealStatuses}
        onChange={onChange}
        options={dealTypeOptions}
        isDisabled={loading}
        isMulti
      />
    </div>
  );
};

DealCPAStatusesInList.propTypes = {
  addNotice: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withNotice(DealCPAStatusesInList);
