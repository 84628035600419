import React, { useState } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import { Row, Col, Button, Container, Card, CardBody, CardTitle } from 'reactstrap';
import PropTypes from 'prop-types';
import Filter from '../TimePeriodFilterExtended';
// import LastMonthFilter from '../LastMonthFilter';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import MatTableForRedux from '../../../../shared/tables/materialTable/components/MatTableForRedux';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../App/store/with-notice';
import GenerateTable from './GenerateTable';


const SummaryReport = ({ addNotice }) => {
  const [loading, setLoaing] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));

  const auditReport = (r) => {
    const messages = [];
    const rep = { ...r };
    const {
      report, deal, admin, offer, reports,
    } = rep;
    if (!offer) {
      messages.push({ message: 'Unable to find right Offer for the Report' });
      rep.isDeleted = true;
    }
    if (!deal) {
      messages.push({ message: 'Unable to find right Deal for the Report' });
      rep.isDeleted = true;
    }
    if (deal && !admin) {
      messages.push({ message: 'Unable to find right Admin User for the Report' });
      rep.existStatus = 'info';
    }
    if (admin && !admin.affiliateManagerId) {
      messages.push({ message: `Admin ${admin?.firstName || ''} ${admin?.lastName || ''} hasn't assigned to the right Account Manager` });
      rep.existStatus = 'info';
    }
    const affiliateManagerIds = reports.map(({ Stat }) => Number(Stat.affiliate_manager_id));
    if (admin && report && !affiliateManagerIds.includes(Number(admin?.affiliateManagerId))/* Number(report.Stat.affiliate_manager_id) !== */) {
      messages.push({
        message: `Hubspot Deal Owner: ${admin.firstName || ''} ${admin.lastName || ''}  does not match with HasOffers ${report?.AffiliateManager.full_name}`,
      });
      rep.existStatus = 'info';
    }
    if (admin && report && affiliateManagerIds.length > 1) {
      messages.push({
        message: `There are ${affiliateManagerIds.length} Account Managers for selected period`,
      });
    }

    return { ...rep, messages };
  };

  const fetchAmountReport = async () => {
    setLoaing(true);
    // setError(null);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-report-for-audit', {
        startDate,
        endDate,
        identity,
      });
      if (resp.data.success) {
        setReportData(resp.data.items.map(r => auditReport(r)).filter(r => r.messages.length > 0));
        setLoaing(false);
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Audit Report', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <CardTitle>
              <h3>Audit Report</h3>
            </CardTitle>
            <Row className="mt-3">
              <Col>
                <Filter
                  setValue={(param, value) => {
                    console.log(value);
                    if (param === 'startDate') {
                      setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                    }
                    if (param === 'endDate') {
                      setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                    }
                  }}
                />
              </Col>
              <Col lg="auto">
                <div>
                  <Button
                    color="primary"
                    onClick={async () => {
                      await fetchAmountReport();
                      return false;
                    }}
                    className="btn-sm"
                    disabled={!!loading}
                  >
                    Run Report
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <ProgressBar
                  afterFunction={() => true}
                  topic="report"
                  identity={identity}
                  isShow={!!loading}
                />
              </Col>
            </Row>
            <GenerateTable
              reportData={reportData}
              loading={loading}
            />
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
