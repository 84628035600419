import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from 'mdi-react/SquareEditOutlineIcon';
import ExternalLinkIcon from 'mdi-react/ExternalLinkIcon';
import FileOutlineIcon from 'mdi-react/FileOutlineIcon';
import DownloadIcon from 'mdi-react/DownloadIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import UndoIcon from 'mdi-react/UndoIcon';
import RefreshDocument from './components/RefreshDocument';

const Cols = {
  offer: {
    title: 'Offer',
    valueFunc: record => ((record.type === 'keen') ? 'Service Provider Agreement' : record.offers[0].name),
    sort: record => ((record.type === 'keen') ? 'Service Provider Agreement' : record.offers[0].name),
  },
  deal_name: {
    title: 'Deal Name',
    valueFunc: record => (record.deals.length ? record.deals[0]?.dealname : ''),
    sort: record => (record.deals.length ? record.deals[0]?.dealname : ''),
    filter: record => (record.deals.length ? record.deals[0]?.dealname : ''),
  },
  deal_type: {
    title: 'Deal Type',
    valueFunc: record => (record.deals.length ? record.deals[0]?.dealTerms?.type_of_deal : ''),
    sort: record => (record.deals.length ? record.deals[0]?.dealTerms?.type_of_deal : ''),
    filter: record => (record.deals.length ? record.deals[0]?.dealTerms?.type_of_deal : ''),
  },
  status: {
    title: 'Status',
    valueFunc: (record) => {
      if (record.status === 'completed') {
        return (
          <div className="marker" style={{ color: '#fff', backgroundColor: 'green' }}>Complete</div>
        );
      }
      if (record.status === 'deleted') {
        return (
          <div className="marker" style={{ color: '#fff', backgroundColor: '#ed9f8a' }}>Deleted</div>
        );
      }
      return (
        <div className="marker" style={{ color: '#fff', backgroundColor: 'orange' }}>Pending</div>
      );
    },
    sort: (record) => {
      if (record.status === 'completed') return 1;
      if (record.status === 'deleted') return 2;
      return 0;
    },
    filter: (record) => {
      if (record.status === 'completed') return 'Complete';
      if (record.status === 'deleted') return 'Deleted';
      return 'Pending';
    },
  },
  date_sent: {
    title: 'Date Sent',
    valueFunc: record => (moment(record.createdAt).format('M/D/YYYY')),
    sort: record => (moment(record.createdAt).format('YYYY-MM-DD')),
  },
  date_signed: {
    title: 'Date Signed',
    valueFunc: record => (record.creatorDateSigned ? moment(record.creatorDateSigned).format('M/D/YYYY') : ''),
    sort: record => (record.creatorDateSigned ? moment(record.creatorDateSigned).format('YYYY-MM-DD') : ''),
  },
  actions: {
    title: '',
    valueFunc: (record) => {
      const hsDealId = (record.deals.length && record.deals[0].hs_object_id) ? record.deals[0].hs_object_id : null;
      const styleDisabled = { color: '#ccc' };
      return (
        <div style={{ textAlign: 'right' }}>
          <Tooltip title="Edit">
            <Link to={`/service-agreements/edit/${record._id}`}>
              <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall">
                <EditIcon style={{ width: '15px', height: '15px' }} />
              </button>
            </Link>
          </Tooltip>
          {hsDealId ? (
            <Tooltip title="Open in HubSpot">
              <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${hsDealId}`} target="_blank" rel="noopener noreferrer">
                <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall">
                  <ExternalLinkIcon style={{ width: '15px', height: '15px' }} />
                </button>
              </a>
            </Tooltip>
          ) : (
            <Tooltip title="Open in HubSpot">
              <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall" style={styleDisabled}>
                <ExternalLinkIcon style={{ width: '15px', height: '15px' }} />
              </button>
            </Tooltip>
          )}
          {(record.filePath && (record.status !== 'deleted')) ? (
            <Tooltip title="Preview Document">
              <Link to={`/service-agreement/preview-document?id=${record._id}`} target="_blank">
                <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall">
                  <FileOutlineIcon style={{ width: '15px', height: '15px' }} />
                </button>
              </Link>
            </Tooltip>
          ) : (
            <Tooltip title="Preview Document">
              <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall" style={styleDisabled}>
                <FileOutlineIcon style={{ width: '15px', height: '15px' }} />
              </button>
            </Tooltip>
          )}
          {(record.filePath && (record.status !== 'deleted')) ? (
            <Tooltip title="Download Document">
              <Link to={`/service-agreement/download-document?id=${record._id}`} target="_blank">
                <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall">
                  <DownloadIcon style={{ width: '15px', height: '15px' }} />
                </button>
              </Link>
            </Tooltip>
          ) : (
            <Tooltip title="Download Document">
              <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall" style={styleDisabled}>
                <DownloadIcon style={{ width: '15px', height: '15px' }} />
              </button>
            </Tooltip>
          )}
          <RefreshDocument
            agreement={record}
          />
          {(record.status === 'deleted') ? (
            <Tooltip title="Undo Deleted Document">
              <a href={`/service-agreement/undo-deleted-document?id=${record._id}`}>
                <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall">
                  <UndoIcon style={{ width: '15px', height: '15px' }} />
                </button>
              </a>
            </Tooltip>
          ) : (
            <Tooltip title="Delete Document">
              <a href={`/service-agreement/delete-document?id=${record._id}`}>
                <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall">
                  <TrashCanOutlineIcon style={{ width: '15px', height: '15px' }} />
                </button>
              </a>
            </Tooltip>
          )}
        </div>
      );
    },
    disableSortBy: true,
  },
};

export default Cols;
