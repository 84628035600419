import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addNotice } from '../../../../App/store/store';
import Container from './components/CPMPricingApprovalsProspects';
import {
  ErrorMessageBoxAlert,
  NoActionError,
  NoResourceError,
} from '../../../../../shared/components/components/ui/error404';
// import CPMPricingApprovalsProspects from './components/CPMPricingApprovalsProspects';

const resourceId = 'CpmProspect';

const ResourceAction = (props) => {
  const {
    resources,
    history,
    location,
    match,
  } = props;
  const resource = resources.find(r => r.id === resourceId);
  if (!resource) {
    return (<NoResourceError resourceId={resourceId} />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId={resourceId} actionName="list" />);
  }
  if (!match?.params?.cpmPriceAppGroupId) {
    return (<ErrorMessageBoxAlert title="CPM Price Approvals Group" actionName="list" >No CPM Price Approvals Group Found</ErrorMessageBoxAlert>);
  }
  return (<Container
    filterVisible
    resource={resource}
    action={action}
    date={new Date()}
    history={history}
    location={location}
    addNotice={addNotice}
    cpmPriceAppGroupId={match.params.cpmPriceAppGroupId}
  />
  );
};
ResourceAction.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
