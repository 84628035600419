import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

// import formater from '../../../../shared/helpers/WVFormatter';
// import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
// import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';

const GenerateTable = ({
  reportData, loading,
}) => {
  const columnsBrands = useMemo(
    () => [
      {
        Header: 'Offer',
        accessor: deal => deal?.offer?.name || '',
        id: 'Offer',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Creator (Influencers data)',
        accessor: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
        id: 'Creator',
        Cell: p => useMemo(() => (
          <Tooltip title="Open Creator Profile">
            <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.influencer?._id}`}>{p.value}</a>
          </Tooltip>), [p.row.original]),
        width: 300,
      },
      {
        Header: 'Deal Name',
        accessor: deal => deal?.deal?.dealname || '',
        id: 'dealname',
        Cell: p => useMemo(() => (p.row.original.deal ? (
          <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original.deal?.hs_object_id}/`} target="_blank" rel="noreferrer" >{p.value}</a>) : ' - '), [p.row.original]),
        width: 150,
      },
      {
        Header: 'Deal Owner',
        accessor: deal => `${deal?.admin?.firstName || ''} ${deal?.admin?.lastName || ''}`,
        id: 'admin',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Account Manager',
        accessor: deal => deal.report?.AffiliateManager.full_name || '',
        id: 'affiliateNamager',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Message',
        accessor: deal => deal?.messages?.length || '',
        id: 'message',
        Cell: ({ row }) => row.original.messages.map(e => (<div>{e?.message}</div>)),
        width: 300,
      },
    ],
    [reportData],
  );

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    useFlex: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'offer',
    direction: 'desc',
  };

  return (
    <div>
      {!loading && reportData.length > 0 ? (
        <Row>
          <Col className="mt-3">
            {!loading && reportData.length > 0 ? (
              <ReactTableBase
                key="searchable"
                columns={columnsBrands}
                data={reportData}
                tableConfig={tableConfig}
              />) : null
            }
          </Col>
        </Row>
      ) : null
      }
    </div>
  );
};
GenerateTable.propTypes = {
  reportData: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default GenerateTable;
