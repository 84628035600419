import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavItem, NavLink, Nav, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
// import ApiClient from 'ApiClient';
import { axios } from 'ApiClient';
import moment from 'moment';
import classnames from 'classnames';
// import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import EditIcon from 'mdi-react/EditIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom';
import AccountFinanceModal from '../../../../../shared/components/reportDates/AccountFinanceModal';
import { momentLocaleToServerUTC, Months } from '../../../../../shared/helpers/Dates';
import {
  fillAccountPayables, editAccountPayableRecord, fetchStripe,
  fillUnpaidAccountPayables, getPayablesStats,
} from '../../../../../redux/reducers/admin/AccountsPayableReducer';
import { addNotice } from '../../../../App/store/store';
import ReportModal from '../../../../../shared/components/modals/ReportModal';
import StripeBalanceControls from './StripeBalanceControls';
import AccountFinanceDates from '../../../../../shared/components/reportDates/AccountFinanceDates';
import PayablesTable from './PayablesTable';
import PayablesTableAgent from './PayablesTableAgent';
import BillComMFARefreshComponent from '../../CreatorPayment/components/BillComMFARefreshComponent';
import csvHelper from '../../../../../shared/helpers/CSVHelper';
import StatisticsControl from './StatisticsControl';
import TotalControl from './TotalControl';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';

const RefreshModals = connect(state => ({
  loading: state.accountPayable.loading,
  payablesLog: state.accountPayable.logs.payables,
}))(({
  modals, selectedReportItem, payablesLog, loading, setModals, downloadCSV,
}) => {
  console.log(selectedReportItem, 'SELECTED');

  return (
    <ReportModal
      type="payablesLog"
      typeTitle="Influencers"
      title={`Updating AP report - ${selectedReportItem.params.name}`}
      modal={modals.refresh}
      closeDialog={() => setModals(m => ({ ...m, refresh: false }))}
      log={payablesLog}
      loading={loading.fillPayables}
      downloadCSV={downloadCSV}
    />);
});
const SelectedMonthGeneral = ({ selectedReport, dispatch, refreshTable }) => {
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const selectedReportItem = selectedReport.params;
  const options = [...Months].map(item => ({ ...item }));
  const selectedMonthGeneral = options ? options.find(e => selectedReportItem && e.value.toString() === selectedReportItem.monthPeriod.toString()) : '';
  const handleDeleteDialogSubmit = () => {
    axios({
      method: 'post',
      url: '/api/portal/finance/delete-payable',
      data: {
        reportId: selectedReport.id,
      },
    }).then((response) => {
      if (response.data.success) {
        setDeleteDialogVisible(false)
        dispatch(addNotice({
          message: 'Selected Report was deleted successfully',
          type: 'success',
        }));
        setTimeout(() => {
          window.location.assign('/finance/account-payable-report/')
        }, 3000);
      } else {
        dispatch(addNotice({
          message: response.data.error,
          type: 'error',
        }));
      }
    }).catch((error) => {
      console.error('CATCH ERROR: ', error);
      dispatch(addNotice({
        message: error.message,
        type: 'error',
      }));
    });
  };
  const apChanged = ({
    startDate, endDate, month, selectedGeneralLedger, name, description,
  }, closeModal, context) => {
    dispatch(editAccountPayableRecord(selectedReport.id, {
      startDate: momentLocaleToServerUTC(moment(startDate)).toISOString(),
      endDate: momentLocaleToServerUTC(moment(endDate)).toISOString(),
      monthPeriod: month,
      generalLedger: selectedGeneralLedger.value,
      name,
      description,
    }, () => {
      closeModal.bind(context)();
      dispatch(addNotice({
        message: 'Data has been saved successfully. Updating the Table.',
        type: 'success',
      }));
      refreshTable(true);
    }));
  };
  return (
    <Row>
      <Col md="11" xs="11">
        <h5 className="bold-text">{selectedReportItem.name} - {selectedMonthGeneral ? selectedMonthGeneral.label : ''} ({selectedReport.populated.generalLedger.params.yearPeriod})</h5>
        <h5 className="subhead">{selectedReportItem.description}</h5>
      </Col>
      {selectedReportItem.status === 'new' ? (
        <Col md="1" xs="1" className="text-right">
          <IconButton
            onClick={() => setEditDialogVisible(true)}
            className="material-table__toolbar-button"
            size="small"
          >
            <EditIcon size="16" />
          </IconButton>
          <IconButton
            onClick={() => setDeleteDialogVisible(true)}
            className="material-table__toolbar-button"
            size="small"
          >
            <DeleteIcon size="16" />
          </IconButton>
        </Col>
      ) : ''}
      <Modal isOpen={deleteDialogVisible} toggle={() => setDeleteDialogVisible(false)} className="delete-modal">
        <ModalHeader toggle={() => setDeleteDialogVisible(false)} />
        <ModalBody>
          Are you sure you want to delete the selected report?
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-sm"
            color="danger"
            onClick={() => handleDeleteDialogSubmit(false)}
          >
            Delete
          </Button>{' '}
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setDeleteDialogVisible(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <AccountFinanceModal
        model={selectedReport}
        saveModelFunc={apChanged}
        modalTitle="Accounts Payable Edit Form"
        generalLedger={selectedReport.populated.generalLedger}
        modal={editDialogVisible}
        closeDialog={() => setEditDialogVisible(false)}
      />
    </Row>
  );
};
SelectedMonthGeneral.propTypes = {
  selectedReport: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshTable: PropTypes.func.isRequired,
};

const APReportContainer2 = ({
  selectedReport,
  loading,
  dispatch,
  type,
  history,
  // resources,
  stripe,
  ...props

}) => {
  const [issue, setIssue] = useState({
    invalidBalance: false,
    invalidCount: false,
    invalidIndividualPaymentCompleted: false,
  });
  const [amounts, setAmounts] = useState({ stripeSelectedAmount: 0, totalSelectedAmount: 0 });
  const [mfaChallenged, setMfaChallenged] = useState(false);
  const [modals, setModals] = useState({
    refresh: false,
    stripe: false,
    calculate: false,
    audit: false,
    auditAgent: false,
    offlinePayouts: false,
    chartOfAccount: false,
  });
  const refreshTable = (isForce) => {
    dispatch(fillAccountPayables(selectedReport.id, isForce));
    setModals(m => ({ ...m, refresh: true }));
    // this.setState(state => ({ modals: { ...state.modals, refresh: true } }));
  };
  const refreshUnpaidTable = () => {
    dispatch(fillUnpaidAccountPayables(selectedReport.id));
    setModals(m => ({ ...m, refresh: true }));
  };

  console.log(moment, issue);
  const saveAccountPayable = ({ startDate, endDate }) => {
    dispatch(editAccountPayableRecord(selectedReport.id, {
      startDate: momentLocaleToServerUTC(moment(startDate)).toISOString(),
      endDate: momentLocaleToServerUTC(moment(endDate)).toISOString(),
    }, () => {
      dispatch(addNotice({
        message: 'New dates was saved successfully. Updating the Table.',
        type: 'success',
      }));
      refreshTable(true);
    }));
  };
  const downloadCSV = (reportType, name) => {
    // console.log(type, name);
    console.log(props);
    const data = props[reportType] && props[reportType][name] ? props[reportType][name] : null;
    console.log(data, [reportType, name]);
    if (name === 'errors') {
      return csvHelper.downloadArrayCSV(data, 'message', `${reportType}-${name}.csv`);
    }
    if (reportType === 'stripeLog' && name === 'logs') {
      return csvHelper.downloadObjectColumndsCSV(data, {
        _id: 'ID',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        method: 'Method',
        transferId: 'Transfer ID',
        transferGroup: 'transferGroup',
        status: 'Status',
        payable: 'Payable ID',
        description: 'Description',
        details: 'Details',
      }, `${reportType}-${name}.csv`);
    }
    return data ? csvHelper.downloadObjectCSV(data, `${reportType}-${name}.csv`) : false;
  };

  useEffect(() => {
    dispatch(fetchStripe());
    dispatch(getPayablesStats(selectedReport.id));
  }, []);
  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <SelectedMonthGeneral selectedReport={selectedReport} dispatch={dispatch} refreshTable={refreshTable} />
        </div>
        <Row className="mb-3 finance-container">
          <Col xl="3" lg="6" className="align-items-stretch mb-3">
            <AccountFinanceDates
              model={selectedReport}
              loading={loading}
              saveModelFunc={saveAccountPayable}
            />
          </Col>
          <Col xl="3" lg="6" className="align-items-stretch mb-3">
            <StatisticsControl />
          </Col>
          <Col xl="3" lg="6" className="align-items-stretch mb-3">
            <TotalControl />
          </Col>
          <Col xl="3" lg="6" className="align-items-stretch mb-3">
            <StripeBalanceControls
              stripeSelectedAmount={amounts.stripeSelectedAmount}
              selectedAmount={amounts.totalSelectedAmount}
              currentBalance={stripe.currentBalance}
              availableBalance={stripe.availableBalance}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12" xl="6">
            <Alert color="danger" className="alert--neutral" isOpen={!!issue.invalidBalance}>
              <p><span className="bold-text">Warning!</span> Please check Stripe Balance</p>
            </Alert>
            <Alert color="danger" className="alert--neutral" isOpen={!!issue.invalidCount}>
              <p><span className="bold-text">Warning!</span> You must select at least one approved and payment provider connected Creator in order to send payouts.</p>
            </Alert>
            <Alert color="danger" className="alert--neutral" isOpen={!!issue.invalidIndividualPaymentCompleted}>
              <p><span className="bold-text">Warning!</span> This payment has been sucessfully completed already</p>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Row className="mt-3">
              <Col className="tabs">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: type !== 'agents' })}
                      onClick={() => {
                        history.push(`/finance/account-payable-report-2/${selectedReport?.id}/creators`);
                        // this.setState({ activeTab: '1' });
                        // getTable();
                      }}
                    >
                      Creators
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: type === 'agents' })}
                      onClick={() => {
                        history.push(`/finance/account-payable-report-2/${selectedReport?.id}/agents`);
                        // this.setState({ activeTab: '2' });
                      }}
                    >
                      Agents
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Col>
        </Row>
        {type !== 'agents' ? (
          <div className="mt-3">
            <PayablesTable
              loading={loading}
              selectedReport={selectedReport}
              showStripeModal={() => this.setState(state => ({ modals: { ...state.modals, stripe: true } }))}
              setIssue={setIssue}
              // isBillComMFASessionTrusted={isBillComMFASessionTrusted}
              challengeMFAReauthentication={() => setMfaChallenged(true)}
              availableBalance={0}
              refreshTable={() => refreshTable(false)}
              refreshUnpaidTable={refreshUnpaidTable}
              setAmount={({ stripeSelectedAmount: a, totalSelectedAmount: b }) => setAmounts({ stripeSelectedAmount: a, totalSelectedAmount: b })}
              addNotice={data => dispatch(addNotice(data))}
              goToCommission={() => history.push(`/finance/employee-commissions-report/${selectedReport.id}`)}
              goToJournal={() => history.push(`/resources/PayableJournal/actions/list?filters.accountPayable=${selectedReport.id}`)}
              history={history}
              downloadCSV={downloadCSV}
            />
          </div>
        ) : (
          <div className="mt-3">
            <PayablesTableAgent
              loading={loading}
              selectedReport={selectedReport}
              showStripeModal={() => this.setState(state => ({ modals: { ...state.modals, stripe: true } }))}
              setIssue={setIssue}
              // isBillComMFASessionTrusted={isBillComMFASessionTrusted}
              challengeMFAReauthentication={() => setMfaChallenged(true)}
              availableBalance={0}
              refreshTable={() => refreshTable(false)}
              refreshUnpaidTable={refreshUnpaidTable}
              setAmount={({ stripeSelectedAmount: a, totalSelectedAmount: b }) => setAmounts({ stripeSelectedAmount: a, totalSelectedAmount: b })}
              addNotice={data => dispatch(addNotice(data))}
              goToCommission={() => history.push(`/finance/employee-commissions-report/${selectedReport.id}`)}
              goToJournal={() => history.push(`/resources/PayableJournal/actions/list?filters.accountPayable=${selectedReport.id}`)}
              history={history}
              downloadCSV={downloadCSV}
            />
          </div>
        )}
      </CardBody>
      <RefreshModals selectedReportItem={selectedReport} loading={loading} modals={modals} setModals={setModals} downloadCSV={downloadCSV} />
      {mfaChallenged && <BillComMFARefreshComponent
        addNotice={data => dispatch(addNotice(data))}
        onAuthenticationFinished={() => setMfaChallenged(false)}
      />}
    </Card>
  );
};
APReportContainer2.propTypes = {
  selectedReport: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  stripe: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
  // resources: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  loading: state.accountPayable.loading,
  payables: state.accountPayable.payables,
  payablesAgent: state.accountPayable.payablesAgent,
  payablesLog: state.accountPayable.logs.payables,
  stripeLog: state.accountPayable.logs.stripePayouts,
  version: state.accountPayable.lastFetched.toString(),
  unassignedDealFetched: state.accountPayable.unassignedDealFetched,
  stripe: state.accountPayable.stripe,
});

export default connect(mapStateToProps, null)(withRouter(APReportContainer2));
