import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import CheckBoxField from '../../../../shared/components/CheckBox';

const sentimentFilter = {
  Positive: true,
  Negative: true,
  Ignore: true,
};

const brandRelevantFilter = {
  Relevant: true,
  Ignore: true,
};

const VideoCommentsActions = ({
  setSearchText,
  setFiltersCommentSentiment,
  setFiltersBrandRelevant,
}) => {
  const [searchTextLocal, setSearchTextLocal] = useState('');
  const [filtersCommentSentimentLocal, setFiltersCommentSentimentLocal] = useState(sentimentFilter);
  const [filtersBrandRelevantLocal, setFiltersBrandRelevantLocal] = useState(brandRelevantFilter);

  const resetFilters = () => {
    setSearchText('');
    setFiltersCommentSentiment(sentimentFilter);
    setFiltersBrandRelevant(brandRelevantFilter);
  }

  useEffect(() => {
    return resetFilters;
  }, []);

  const setSearchTextDebounced = useAsyncDebounce(() => setSearchText(searchTextLocal), 400);

  useEffect(() => {
    setSearchTextDebounced();
  }, [searchTextLocal]);

  const setFiltersCommentSentimentDebounced = useAsyncDebounce(() => setFiltersCommentSentiment(filtersCommentSentimentLocal), 400);

  useEffect(() => {
    setFiltersCommentSentimentDebounced();
  }, [filtersCommentSentimentLocal]);

  const setFiltersBrandRelevantDebounced = useAsyncDebounce(() => setFiltersBrandRelevant(filtersBrandRelevantLocal), 400);

  useEffect(() => {
    setFiltersBrandRelevantDebounced();
  }, [filtersBrandRelevantLocal]);

  const handleChangeBrandRelevantCheckFilter = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersBrandRelevantLocal({
        ...filtersBrandRelevantLocal,
        [name]: checked,
      });
    }
  };

  const handleChangeCommentSentimentCheckFilter = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersCommentSentimentLocal({
        ...filtersCommentSentimentLocal,
        [name]: checked,
      });
    }
  };

  return (
    <Row className="mt-3">
      <Col>
        <h4 className="pb-3">Video Comments</h4>
          <Row>
            <Col md={5} style={{ paddingTop: '18px' }}>
              <Input
                type="search"
                className="form-control"
                placeholder="Search Comments..."
                value={searchTextLocal}
                onChange={event => setSearchTextLocal(event.target.value)}
              />
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className="bold-text">
                  Sentiment
                </Label>
                <div style={{ marginBottom: '10px' }} >
                  <CheckBoxField
                    name="CommentSentimentPositive"
                    label="Positive"
                    value={filtersCommentSentimentLocal.Positive}
                    onChange={event => handleChangeCommentSentimentCheckFilter(event, 'Positive')}
                    className="inline"
                  />
                  <CheckBoxField
                    name="CommentSentimentNegative"
                    label="Negative"
                    value={filtersCommentSentimentLocal.Negative}
                    onChange={event => handleChangeCommentSentimentCheckFilter(event, 'Negative')}
                    className="inline ml-4"
                  />
                  <CheckBoxField
                    name="CommentSentimentIgnore"
                    label="Ignore"
                    value={filtersCommentSentimentLocal.Ignore}
                    onChange={event => handleChangeCommentSentimentCheckFilter(event, 'Ignore')}
                    className="inline ml-4"
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label className="bold-text">
                  Brand Relevancy
                </Label>
                <div style={{ marginBottom: '10px' }} >
                  <CheckBoxField
                    name="AiBrandRelevant"
                    label="Relevant"
                    value={filtersBrandRelevantLocal.Relevant}
                    onChange={event => handleChangeBrandRelevantCheckFilter(event, 'Relevant')}
                    className="inline"
                  />
                  <CheckBoxField
                    name="AiBrandRelevantIgnore"
                    label="Ignore"
                    value={filtersBrandRelevantLocal.Ignore}
                    onChange={event => handleChangeBrandRelevantCheckFilter(event, 'Ignore')}
                    className="inline ml-4"
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
      </Col>
    </Row>
  );
}

VideoCommentsActions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  mediaContentId: PropTypes.string,
  mediaUrl: PropTypes.string,
  influencerId: PropTypes.string,
};

VideoCommentsActions.defaultProps = {
  data: [],
  mediaContentId: '',
  mediaUrl: '',
  influencerId: '',
};

export default VideoCommentsActions;
