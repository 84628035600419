import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AiSentiment = ({ label }) => {
  let textStyle = 'text-dark';
  let icon = '';
  let value = '';
  if (label) {
    switch (label) {
      case 'Positive':
        textStyle = 'text-success';
        icon = 'thumbs-up';
        break;
      case 'Negative':
        textStyle = 'text-danger';
        icon = 'thumbs-down';
        break;
      case 'Ignore':
        textStyle = 'text-dark';
        icon = '';
        break;
      case 'Relevant':
        textStyle = 'text-success';
        icon = 'thumbs-up';
        break;
      default:
        textStyle = 'text-dark';
        icon = '';
        break;
    }
    value = label;
    // value = value[0].toUpperCase() + value.slice(1).toLowerCase();
  }

  return (
    <div>
      {label ?
        (
          <div>
            <span className={textStyle}>{value}</span>
            {' '}
            {icon && (<FontAwesomeIcon icon={icon} className={textStyle}/>)}
          </div>
        ) : ''
      }
    </div>
  );
};

AiSentiment.propTypes = {
  label: PropTypes.string.isRequired,
};

export default AiSentiment;
