import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addNotice } from '../../../../App/store/store';
import LeadsListContainer from './components/LeadsListContainer';
import { NoActionError, NoResourceError } from '../../../../../shared/components/components/ui/error404';

const ResourceAction = (props) => {
  const {
    resources,
    history,
    location,
  } = props;
  const [tag, setTag] = useState('');
  const resource = resources.find(r => r.id === 'Prospect');
  if (!resource) {
    return (<NoResourceError resourceId="Prospect" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="Prospect" actionName="list" />);
  }

  return (<LeadsListContainer
    filterVisible
    tag={tag}
    setTag={setTag}
    resource={resource}
    action={action}
    date={new Date()}
    history={history}
    location={location}
    addNotice={addNotice}
  />
  );
};
ResourceAction.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
