/* eslint react/prop-types: 0 */
import React, { useState, useEffect, useMemo } from 'react';
import Icon from 'mdi-react/LinkIcon';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import { unflatten } from 'flat';
import ApiClient, { axios }  from 'ApiClient';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import Placeholder from '../../../../shared/components/components/ui/placeholder';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import StyledButton from '../../../../shared/components/components/ui/styled-button';

// import CheckBoxField from '../../../../shared/components/CheckBox';

const payBy = {
  0: 'check',
  1: 'ach',
  2: 'rpps',
  5: 'intlEPmt',
  6: 'offline',
  7: 'vCard',
  11: 'wallet',
};
const BillComList = (props) => {
  const {
    match,
    history,
    type,
  } = props;
  const [record, setRecord] = useState(null);
  const [billComAccounts, setBillComAccounts] = useState([]);
  const [loading, setLoading] = useState(null);
  const [selected, setSelected] = useState(null);
  const [enteredText, setEnteredText] = useState('');
  const [searchedText, setSearchedText] = useState('');
  const [showSuccessDialog, setShowSuccessDialog] = useState('');
  const [vendorEmail, setVendorEmail] = useState('');
  const [vendorName, setVendorName] = useState('');

  const doSearch = async () => {
    setLoading(true);
    const response = await axios.get(`/api/utilities/billCom/search-vendors?term=${enteredText}`);
    setBillComAccounts(response?.data?.accounts || []);
    setLoading(false);
  };
  const connectAction = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: 'post',
        url: '/api/portal/finance/post-update-billcom-data',
        data: {
          type,
          id: record.id,
          billComAccoundId: selected,
        },
      });
      if (response.data.success === true) {
        const account = billComAccounts.find(b => b.id === selected);
        if (account) {
          setVendorEmail(account.paymentEmail || '');
          setVendorName(account.name || '');
        }
      } else {
        throw new Error(response.data.error);
      }

      setShowSuccessDialog(true);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (searchedText !== '') doSearch();
    return true;
  }, [searchedText]);
  useEffect(() => {
    if (showSuccessDialog === false && record) {
      if (type === 'agent') {
        history.push(`/agent/profile/${encodeURIComponent(record?.params?._id)}`);
      } else {
        history.push(`/influencer/profile/${encodeURIComponent(record?.params?._id)}`);
      }
    }
  }, [showSuccessDialog]);
  const fetchData = async (id) => {
    try {
      const api = new ApiClient();
      setLoading(true);
      const res = await api.recordAction({
        resourceId: type === 'agent' ? 'AgentUser' : 'Influencer',
        recordId: id,
        actionName: 'show',
      });
      setEnteredText(res.data.record.params?.email);
      setSearchedText(res.data.record.params?.email);
      setRecord(unflatten(res.data.record));
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (
      (match && match.params.id)
    ) {
      fetchData(match.params.id);
    }
    return true;
  }, [match.params.id]);
  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    manualPageSize: [10, 20, 30, 40],
    withSearchEngine: true,
    sortBy: 'name',
    direction: 'desc',
  };

  const columnTransform = useMemo(() => {
    console.log('match');
    return [{
      Header: () => (''),
      accessor: d => d.id,
      id: 'id',
      minWidth: 40,
      maxWidth: 40,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (
        <FormGroup className="m-0">
          <Radio
            checked={selected === value}
            className="material-table__checkbox"
            onClick={() => setSelected(selected === value ? null : value)}
            size="small"
          />
        </FormGroup>),
      disableSortBy: true,
      disableFilters: true,
    }, {
      Header: 'Vendor Name',
      id: 'name',
      accessor: r => (r.name?.toLowerCase() || ''),
      Cell: value => value.row.original.name,
      width: 300,
    }, {
      Header: 'Email',
      accessor: r => (r.advertiser?.toLowerCase() || ''),
      Cell: value => value.row.original.email,
      width: 500,
    }, {
      Header: 'Pay Via',
      accessor: r => (r.payBy?.toLowerCase() || ''),
      Cell: ({ value }) => payBy[value],
      width: 100,
    }, {
      Header: 'Bill Currency',
      accessor: r => r.billCurrency,
      Cell: ({ value }) => value,
      width: 100,
    }];
  }, [selected]);
  const links = useMemo(() => {
    let l = [{ title: 'Home', path: '/' }];
    if (type === 'agent') {
      l = [
        ...l,
        ...[{ title: 'Agent Users', path: '/resources/AgentUser/actions/list' }],
        ...[{ title: 'Agent Users BillCom', path: false }],
      ];
    }
    if (type === 'creator') {
      l = [
        ...l,
        ...[{ title: 'Creators', path: '/resources/Influencer/actions/list' }],
        ...[{ title: 'Creator Users BillCom', path: false }],
      ];
    }
    return l;
  }, [type]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          {/* <Breadcrumbs resource={resource} actionName="list" isBackButton /> */}
          <Breadcrumbs
            isBackButton
            links={links}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="agent-users-dashboard-container">
          <Card>
            <CardBody>
              <Col>
                <div className="card__title">
                  <h5 className="bold-text">Connect BillCom Account for {!loading || record?.params?.first_name ? `${record?.params?.first_name || ''} ${record?.params?.last_name || ''}` : ''}</h5>
                </div>
                <Row>
                  <Col>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Creator name, @username, or email"
                      value={enteredText}
                      onKeyDown={(event) => { if (event.code === 'Enter' || event.keycode === 13) setSearchedText(enteredText); }}
                      onChange={(event) => { setEnteredText(event.target.value); }}
                    />
                  </Col>
                  <Col xs="auto">
                    <StyledButton
                      type="button"
                      className="btn btn-primary btn-lg"
                      disabled={!!loading}
                      onClick={() => setSearchedText(enteredText)}
                      style={{ padding: '7px 25px' }}
                    >
                      <i className="fa fa-search" />&nbsp;
                      <span className="btn-text">Search</span>
                    </StyledButton>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!loading && billComAccounts.length > 0 ? (
                      <Button
                        color="primary"
                        size="sm"
                        onClick={connectAction}
                        disabled={!selected}
                      >
                        <Icon style={{ width: '15px', height: '15px', marginTop: '-2px' }} />
                        Connect
                      </Button>) : null}
                  </Col>
                </Row>
                {!loading && billComAccounts.length > 0 ? (
                  <ReactTableBase
                    key="billComAccounts"
                    columns={columnTransform}
                    data={billComAccounts}
                    tableConfig={tableConfig}
                  />) : null}
                {loading ? (<div className="text-center"><Spinner color="primary" size="lg" /></div>) : null}
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={showSuccessDialog === true} toggle={() => setShowSuccessDialog(false)} size="sm">
        <ModalHeader toggle={() => setShowSuccessDialog(false)}>
          Success
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="3 text-center">
              <i className="fa fa-info-circle" style={{ color: '#70bbfd', fontSize: '5em' }} />
            </Col>
            <Col>
              <h4>
                The Bill.com vendor {vendorName} ({vendorEmail}) was successfully connected
                to agent {record?.params?.first_name ? `${record?.params?.first_name || ''} ${record?.params?.last_name || ''}` : ''} (${record?.params?.email})
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="modal__footer">
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setShowSuccessDialog(false)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};


export default BillComList;
