import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal, ModalHeader, ModalFooter } from 'reactstrap';

const strip = (htmlString) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = htmlString;

  return tmp.textContent || tmp.innerText;
};

const DescriptionField = ({ record }) => {
  const { description } = record.params;
  const [showModal, setShowModal] = useState(false);
  const clearDescription = useMemo(() => strip(description), [description]);
  const result = useMemo(() => {
    let r = '';
    if (clearDescription.length > 50) {
      r = clearDescription.slice(0, 50);
      r += '...';
    } else {
      r = clearDescription;
    }
    return r;
  }, [description]);

  return (
    <>
      <span>
        {result}
        &nbsp;&nbsp;&nbsp;
        {result && result.length > 50 &&
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" onClick={() => { setShowModal(true); }}><i className="fas fa-external-link-alt" /></a>
        }
      </span>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <ModalHeader toggle={() => setShowModal(false)} tag="h4" >Description</ModalHeader>
        <div className="modal__body my-3">{clearDescription}</div>
        <ModalFooter>
          <ButtonToolbar className="modal__footer">
            <Button
              className="modal_cancel btn-sm"
              color="danger"
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </ButtonToolbar>
        </ModalFooter>
      </Modal>
    </>
  );
};
DescriptionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default DescriptionField;

