import React, { useEffect, useState, useMemo } from 'react';
import { axios } from 'ApiClient';
import {
  Row,
  Col,
  Button,
  Label,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

const Actions = ({
  selectedAdvertiser,
  setSelectedAdvertiser,
  selectedDealType,
  setSelectedDealType,
  selectedEmployee,
  setSelectedEmployee,
  setSelectedCreator,
  selectedCreator,
}) => {
  const [advertisers, setAdvertisers] = useState([]);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState([]);

  const loadCreatorsOptions = async (value) => {
    const response = await axios.get('/api/portal/influencer/get-creator-search', {
        params: {
          value,
          defaultSearch: 'a',
        },
      });

    return response?.data?.records?.map(record => ({
      value: record.params?._id || record._id,
      label: record.params ? `${record.params.first_name} ${record.params.last_name}` : `${record.first_name} ${record.last_name}`,
      record,
    })) || [];
  };

  const resetFilters = () => {
    setSelectedCreator([]);
    setSelectedEmployee([]);
    setSelectedAdvertiser([]);
    setSelectedDealType([]);
  };


  useEffect(() => {
    setLoadingAdvertiser(true);
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      // setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : -1)));
      // setAdvertisers(Object.values(brands));
      // setOffers(resp.data.offers);
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      /* addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      }); */
    });
  }, []);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    setLoadingAdvertiser(true);
    axios.get('/api/portal/reports/get-account-managers').then((resp) => {
      setEmployees(resp.data.accountManagers);
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      /* addNotice({
        message: 'There was an error when try load employees, Check out console to see more information.',
        type: 'error',
      }); */
    });
  }, []);
  const advertiserOptions = useMemo(() => advertisers.map(a => ({ value: a._id, label: a.companyName })), [advertisers]);
  const employeeOptions = useMemo(() => {
    const i = employees.map(item => ({ value: item.affiliateManagerId, label: `${item.firstName || ''} ${item.lastName || ''}`, item }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [employees]);

  return (
    <div className="mt-3">
      <Row>
        <Col sm={10}>
          <Row>
            <Col>
              <Label className="font-weight-bold">Brand(s)</Label>
              <Select
                isDisabled={loadingAdvertiser}
                value={selectedAdvertiser}
                options={advertiserOptions}
                onChange={setSelectedAdvertiser}
                placeholder="Select Brand..."
                isMulti
              />
            </Col>
            <Col>
              <Label className="font-weight-bold">Account Manager(s)</Label>
              <Select
                isDisabled={loadingAdvertiser}
                value={selectedEmployee}
                options={employeeOptions}
                onChange={setSelectedEmployee}
                placeholder="Select Account Manager(s)..."
                isMulti
              />
            </Col>
            <Col>
              <Label className="font-weight-bold">Deal Type(s)</Label>
              <Select
                value={selectedDealType}
                options={[
                  { value: 'CPA/Ambassador', label: 'CPA/Ambassador' },
                  { value: 'Hybrid', label: 'Hybrid' },
                  { value: 'Upfront Only', label: 'Upfront Only' },
                  { value: 'Upfront CPM', label: 'Upfront CPM' },
                ]}
                onChange={setSelectedDealType}
                placeholder="Deal Type..."
                isClearable
                isMulti
              />
            </Col>
            <Col>
              <Label className="bold-text">
                Creator(s)
              </Label>
              <SelectAsync
                cacheOptions
                defaultOptions
                isClearable
                isMulti
                loadOptions={async value => loadCreatorsOptions(value)}
                onChange={setSelectedCreator}
                placeholder="Select Creator..."
                value={selectedCreator}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={2} style={{ paddingTop: '29px' }}>
          <Tooltip title="Reset Filters">
            <Button
              color="secondary"
              size="sm"
              onClick={resetFilters}
              style={{
                width: '100%',
                height: '38px',
              }}
            >
              <i className="fa fa-sync" aria-hidden="true" />&nbsp;
              <span className="btn-text">Reset</span>
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};
Actions.propTypes = {
  setSelectedAdvertiser: PropTypes.func.isRequired,
  selectedAdvertiser: PropTypes.arrayOf(PropTypes.any),
  setSelectedDealType: PropTypes.func.isRequired,
  selectedDealType: PropTypes.arrayOf(PropTypes.any),
  setSelectedEmployee: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.arrayOf(PropTypes.any),
  setSelectedCreator: PropTypes.func.isRequired,
  selectedCreator: PropTypes.arrayOf(PropTypes.any),
};
Actions.defaultProps = {
  selectedAdvertiser: [],
  selectedDealType: [],
  selectedEmployee: [],
  selectedCreator: [],
};
export default Actions;
