import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Modal,
  ModalHeader,
} from 'reactstrap';
// import PropTypes from 'prop-types';
import withNotice from '../../../../../../App/store/with-notice';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';
import CopyToClipboard from '../../../../../../../shared/components/CopyToClipboardLeft';
import { CopyIcon } from '../../../../../../../shared/helpers/Icons';

export const modalId = 'keywordsHashtagsModal';


const KeywordsHashtags = () => {
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  console.log(modalInfo);
  const dispatch = useDispatch();
  const { data: { record }, isShow } = modalInfo || { data: {} };
  const close = () => dispatch(hideModalById(modalId));
  if (!modalInfo) return false;

  console.log(record);

  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
    >
      <ModalHeader toggle={close} tag="h4">
        <h3>Keywords & Hashtags</h3>
      </ModalHeader>
      <div
        className="modal__body mt-3 mb-3"
      >
        <h4 className="my-3 font-weight-bold">Keywords <CopyToClipboard value={record.keywords.join(', ')} icon={(<CopyIcon />)} className="text-blue" /></h4>
        {record.keywords.join(', ')}
        <h4 className="my-3 font-weight-bold">Hashtags <CopyToClipboard value={record.hashtags.join(', ')} icon={(<CopyIcon />)} className="text-blue" /></h4>
        {record.hashtags.join(', ')}
      </div>
      <ButtonToolbar className="modal__footer mt-3">
        <Button color="secondary" onClick={close}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

KeywordsHashtags.propTypes = {
  // isOpen: PropTypes.bool,
  // toggleModal: PropTypes.func.isRequired,
  // updateTable: PropTypes.func.isRequired,
  // addNotice: PropTypes.func.isRequired,
  // prospectId: PropTypes.string,
};

/* RefreshStatisticsOptionsModal.defaultProps = {
  isOpen: false,
  prospectId: '',
}; */


export default withNotice(KeywordsHashtags);
