/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useState, useEffect } from 'react';
// import { unflatten } from 'flat';
import { Modal, ButtonToolbar, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { axios } from 'ApiClient';
import { uid } from 'react-uid';
import { currentTimezoneAggr } from '../../../../../../../../shared/helpers/Dates';

const StatusHistoryModal = ({
  modal,
  setModalVisible,
  record,
  editableDates,
}) => {
  const [approvalStatus, setApprovalStatus] = useState(null);

  const updateStatusHistory = (data, type) => {
    const { id } = record;
    const _status = { ...approvalStatus };
    Object.keys(data).forEach((i) => {
      Object.keys(data[i]).forEach((key) => {
        _status[type].history[i][key] = data[i][key];
      });
    });
    setApprovalStatus(_status);
    axios.post('/api/portal/prospects/post-update-history', {
      data,
      id,
      type,
    });
  };

  useEffect(() => {
    setApprovalStatus({ ...record.params?.approvalStatus });
  }, [record]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModalVisible(false)}
    >
      <div className="modal__header">
        <button className="lnr lnr-cross modal__close-btn" style={{ right: '15px' }} type="button" onClick={() => setModalVisible(false)} />
        <h3 className="text-modal  modal__title">Status History</h3>
      </div>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <div><strong>{moment(record.params?.createdAt).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
        <div className="pl-3 mb-4">
          <a href={record.params?.adminUser ? `/resources/AdminUser/records/${record.params.adminUser}/show` : '#'}>
            {record.populated?.adminUser?.params?.firstName || ''} {record.populated?.adminUser?.params?.lastName || ''}
          </a> - Record created
        </div>
        {record.params?.category?.history?.length > 0 ? (
          <div className="mt-1 mb-4">
            <h4 className="font-weight-bold">Category Change History</h4>
            <hr className="mt-1" />
            {record.params.category.history.map((item) => {
              const previousValue = item.previousValue || null;
              const currentValue = item.selectedValue || null;
              return (
                <div key={moment(item.dateModified).toString() + moment.now().toString()}>
                  <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                  <div className="pl-3"><a href={item.adminUser ? `/resources/AdminUser/records/${item.adminUser}/show` : '#'}>{item.adminUserName}</a> - <u>{previousValue || '[Not Assigned]'}</u> changed to <u>{currentValue || '[Not Assigned]'}</u></div>
                </div>);
            })}
          </div>
        ) : null}
        {record.params?.analystUserHistory?.length > 0 ? (
          <div className="mt-1 mb-4">
            <h4 className="font-weight-bold">Analysts Change History</h4>
            <hr className="mt-1" />
            {record.params.analystUserHistory.map((item) => {
              const previousValue = item.previousAnalystUser || null;
              const currentValue = item.selectedAnalystUser || null;
              const previousName = item.previousAnalystUserName || null;
              const currentName = item.selectedAnalystUserName || null;
              return (
                <div key={moment(item.dateModified).toString() + moment.now().toString()}>
                  <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                  <div className="pl-3"><a href={item.adminUser ? `/resources/AdminUser/records/${item.adminUser}/show` : '#'}>{item.adminUserName}</a> - <u><a href={previousValue ? `/resources/AdminUser/records/${previousValue}/show` : '#'}>{previousName || ''}</a></u> changed to <u><a href={currentValue ? `/resources/AdminUser/records/${currentValue}/show` : '#'}>{currentName || ''}</a></u></div>
                </div>);
            })}
          </div>
        ) : null}
        {record.params?.approvalStatus.brand?.targetAdvertiserHistory?.length > 0 ? (
          <div className="mt-1 mb-4">
            <h4 className="font-weight-bold">Target Brand Change History</h4>
            <hr className="mt-1" />
            {record.params.approvalStatus.brand?.targetAdvertiserHistory.map((item) => {
              const previousValue = item.previousTargetAdvertiserId;
              const previousName = item.previousTargetAdvertiserName;
              const currentValue = item.selectedTargetAdvertiserId;
              const currentName = item.selectedTargetAdvertiserName;
              return (
                <div key={moment(item.dateModified).toString() + moment.now().toString()}>
                  <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                  <div className="pl-3"><a href={item.adminUser ? '/resources/AdminUser/records/' + item.adminUser + '/show' : '#'}>{item.adminUserName || '---'}</a> - <u><a href={previousValue ? '/resources/Advertiser/records/' + previousValue + '/show' : '#'}>{previousName || '---'}</a></u> changed to <u><a href={currentValue ? '/resources/Advertiser/records/' + currentValue + '/show' : '#'}>{currentName || '---'}</a></u></div>
                </div>);
            })}
          </div>
        ) : null}
        {record.params?.approvalStatus.influencelogic?.history?.length > 0 ? (
          <div className="mt-1 mb-4">
            <h4 className="font-weight-bold">InfluenceLogic Status History</h4>
            <hr className="mt-1" />
            {approvalStatus?.influencelogic?.history.map((item, index, array) => {
              let dateModified = item.dateModified ? new Date(item.dateModified) : null;
              const previousValue = item.previousStatus || (array[index - 1] ? array[index - 1].selectedStatus : null);
              const currentValue = array[index]?.selectedStatus;
              const adminUser = record?.populated['approvalStatus.influencelogic.history.' + index + '.adminUser'] || {};
              const adminUserName = item.adminUserName ? item.adminUserName : (adminUser?.params?.firstName || '') + ' ' + (adminUser?.params?.lastName || '').trim();
              return (
                <div key={uid(index)} className="mt-2">
                  {editableDates && (
                    <DatePicker
                      onChange={(value) => { dateModified = value; updateStatusHistory({ [index]: { dateModified: dateModified.toISOString() } }, 'influencelogic'); }}
                      selected={dateModified}
                      dateFormat="M/d/yyyy h:mmaa"
                      placeholderText=""
                      dropDownMode="select"
                      className="form-control"
                      showTimeInput
                    />
                  )}
                  <strong>{editableDates ? ' ' : moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong>
                  <div className="pl-3"><a href={item.adminUser ? '/resources/AdminUser/records/' + item.adminUser + '/show' : '#'}>{adminUserName}</a> - <u>{previousValue || ''}</u> changed to <u>{currentValue}</u></div>
                </div>
              );
            })}
          </div>
        ) : null}
        {record.params?.rejectionReason?.history?.length > 0 ? (
          <div className="mt-1 mb-4">
            <h4 className="font-weight-bold">Rejection Reason Change History</h4>
            <hr className="mt-1" />
            {record.params.rejectionReason.history.map((item) => {
              const previousValue = item.previousValue || null;
              const currentValue = item.selectedValue || null;
              return (
                <div key={moment(item.dateModified).toString() + moment.now().toString()}>
                  <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                  <div className="pl-3"><a href={item.adminUser ? `/resources/AdminUser/records/${item.adminUser}/show` : '#'}>{item.adminUserName}</a> - <u>{previousValue || '[Not Assigned]'}</u> changed to <u>{currentValue || '[Not Assigned]'}</u></div>
                </div>
              );
            })}
          </div>
        ) : null}
        {record.params?.approvalStatus.brand?.history?.length > 0 ? (
          <div className="mt-1 mb-4">
            <h4 className="font-weight-bold">Brand Status History</h4>
            <hr className="mt-1" />
            {approvalStatus?.brand?.history.map((item, index, array) => {
              let { dateModified } = item;
              const previousValue = array[index - 1]?.selectedStatus;
              const currentValue = array[index]?.selectedStatus;
              const adminUser = record?.populated['approvalStatus.brand.history.' + index + '.brandUser'] || record?.populated['approvalStatus.brand.history.' + index + '.adminUser'] || {};
              const adminUserName = item.adminUserName ? item.adminUserName : (item.brandUserName ? item.brandUserName : (adminUser?.params?.firstName || '') + ' ' + (adminUser?.params?.lastName || '').trim());
              return (
                <div key={uid(index)} className="mt-2">
                  {editableDates && (
                    <DatePicker
                      onChange={(value) => { dateModified = value; updateStatusHistory({ [index]: { dateModified: dateModified.toISOString() } }, 'brand'); }}
                      selected={dateModified ? new Date(dateModified) : null}
                      dateFormat="M/d/yyyy h:mmaa"
                      placeholderText=""
                      dropDownMode="select"
                      className="form-control"
                      showTimeInput
                    />
                  )}
                  <strong>{editableDates ? ' ' : moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong>
                  <div className="pl-3"><a href={item.adminUser ? '/resources/AdminUser/records/' + item.adminUser + '/show' : (item.brandUser ? '/resources/BrandUser/records/' + item.brandUser + '/show' : '#')}>{adminUserName}</a> - <u>{previousValue || ''}</u> changed to <u>{currentValue}</u></div>
                </div>
              );
            })}
          </div>
        ) : null}
        {record.params?.programHistory?.length > 0 ? (
          <div className="mt-1 mb-4">
            <h4 className="font-weight-bold">Program Change History</h4>
            <hr className="mt-1" />
            {record.params.programHistory.map((item) => {
              const previousValue = item.previousProgram;
              const currentValue = item.selectedProgram;
              return (
                <div key={moment(item.dateModified).toString() + moment.now().toString()}>
                  <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                  <div className="pl-3"><a href={item.adminUser ? `/resources/AdminUser/records/${item.adminUser}/show` : '#'}>{item.adminUserName || '---'}</a> - <u>{previousValue || '---'}</u> changed to <u>{currentValue || '---'}</u></div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => setModalVisible(false)}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

StatusHistoryModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  editableDates: PropTypes.bool,
};

StatusHistoryModal.defaultProps = {
  editableDates: false,
};

export default StatusHistoryModal;
