import React from 'react';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { formatIntNumber } from '../../helpers/WVFormatter';

class HubspotStatsWidget extends React.Component {
  static propTypes = {
    version: PropTypes.string,
  }
  static defaultProps = {
    version: '',
  }
  constructor(props) {
    super(props);
    this.state = {
      remaining: 0,
      daily: 0,
    };
  }
  componentDidMount() {
    this.fetchHubpotStats();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.version !== this.props.version) {
      this.fetchHubpotStats();
    }
  }
  fetchHubpotStats() {
    axios.get('/api/portal/global/get-hubspot-stats').then((response) => {
      const { remaining, daily } = response.data;
      this.setState({
        remaining,
        daily,
      });
    }).catch((error) => {
      console.log(error);
    });
  }
  render() {
    const { remaining, daily } = this.state;
    return (
      <Card body className="card-bordered card" style={{ padding: '20px', height: '100%' }}>
        <h4 className="mb-1"><strong>Daily Ratelimit Remaining:</strong> <span className="">{formatIntNumber(remaining)} from {formatIntNumber(daily)}</span></h4>
      </Card>
    );
  }
}
export default HubspotStatsWidget;
