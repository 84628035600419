/* eslint-disable filenames/match-exported */
const shouldActionReFetchData = (
  currentMatchParams,
  newMatchParams,
) => {
  const {
    resourceId,
    recordId,
    actionName,
  } = currentMatchParams;
  const {
    resourceId: newResourceId,
    recordId: newRecordId,
    actionName: newActionName,
  } = newMatchParams;

  return resourceId !== newResourceId
    || recordId !== newRecordId
    || actionName !== newActionName;
};

export default shouldActionReFetchData;
