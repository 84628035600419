import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';

const InfluenceLogicStatus = React.memo(({
  record, showNotes, setVersion,
}) => {
  const options = [
    { label: 'New', value: 'New', isdisabled: true },
    { label: 'First Score', value: 'First Score', isdisabled: true },
    { label: 'Second Score', value: 'Second Score', isdisabled: true },
    { label: 'Pending Edits', value: 'Pending Edits' },
    { label: 'Rescore', value: 'Rescore' },
    { label: 'Approved', value: 'Awaiting Brand Approval', isdisabled: record.params.scorecardsCount < 2 },
    { label: 'Brand Approved', value: 'Brand Approved', isdisabled: true },
  ];
  const [selectedOption, setSelectedOption] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const notesVal = (record && record.params.notes) ? record.params.notes : '';

  useEffect(() => {
    const _value = record?.params.status || '';
    const selectedValue = _value || null;
    setSelectedOption(options.find(option => option.value === selectedValue));
  }, [record.id, record.params.status]);

  const handleChange = async (selectedItem) => {
    const { value } = selectedItem;
    setSelectedOption(selectedItem);
    setDisabled(true);
    // console.log(record);
    try {
      axios.post('/api/portal/compliance/post-update-status', {
        ids: [record.id],
        status: value,
        date: new Date(),
        type: 'influenceLogic',
      }).then((response) => {
        if (response.data.success) {
          setDisabled(false);
          if (setVersion) setVersion(new Date().toString());
        } else {
          console.log(response.data);
        }
      });
    } catch (e) {
      console.log(e.message);
      setDisabled(false);
    }
    if (value === 'Pending Edits') {
      showNotes();
    }
    return true;
  };

  return (
    <div style={{ width: '180px' }}>
      <div style={{ width: '150px', float: 'left' }} >
        <Select
          value={selectedOption}
          options={options}
          onChange={handleChange}
          placeholder=""
          isDisabled={disabled}
          menuPortalTarget={document.body}
          isOptionDisabled={option => option.isdisabled}
        />
      </div>
      {showNotes && (
        <div style={{ marginTop: '6px', marginRight: '6px', float: 'right' }}>
          <Tooltip title="IL Status Notes">
            <IconButton
              size="small"
              onClick={showNotes}
            >
              <FontAwesomeIcon icon={[notesVal !== '' ? 'fas' : 'far', 'file-alt']} />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

InfluenceLogicStatus.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  showNotes: PropTypes.func,
  setVersion: PropTypes.func,
};

InfluenceLogicStatus.defaultProps = {
  showNotes: null,
  setVersion: null,
};

export default InfluenceLogicStatus;
