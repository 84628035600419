import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CopyToClipboard from '../../../../shared/components/CopyToClipboard';

const CreatorInList = React.memo(({ record, filtersForUrls }) => {
  return (
    <div
      className="d-flex"
      style={{ maxWidth: '350px' }}
    >
      <div
        className="align-self-center flex-column"
      >
        <div className="firstAndLastName">
          <a
            title="Open Creator Profile"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-0"
            style={{ fontSize: '14px' }}
            href={`/influencer/profile/${record.creatorId}`}
          >
            {record.name}
          </a>
          {record.creatorContact && true && (
            <div
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginLeft: '5px',
                marginTop: '-2px',
              }}
            >
              <a
                title="Show HubSpot Contact"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-0"
                style={{ fontSize: '16px' }}
                href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.creatorContact}`}
              >
                <i className="fab fa-hubspot"/>
              </a>
            </div>
          )}
          <div
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              marginLeft: '5px',
              marginTop: '-2px',
            }}
          >
            <a
              title="Open Creator Comments Analysis Report"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-0"
              style={{ fontSize: '16px' }}
              href={`/reports/creator-comments-analysis?creator=${record.creatorId}${filtersForUrls}`}
            >
              <i className="fas fa-external-link-alt"/>
            </a>
          </div>
        </div>
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginTop: '2px'
          }}
          className="block-in-cell"
        >
          <CopyToClipboard
            value={record.creatorEmail}
            title="Copy Email"
          >
            {record.creatorEmail}
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

CreatorInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  filtersForUrls: PropTypes.string,
};

CreatorInList.defaultProps = {
  filtersForUrls: '',
};

export default CreatorInList;
