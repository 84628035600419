import React from 'react';
import ImpactActionShowAdditionalInfoButton
  from '../../../../../../shared/components/ImpactActionShowAdditionalInfoButton';

export default (props) => {
  const { record } = props;
  const index = window.location.href.indexOf('resources') >= 0 ? window.location.href.indexOf('resources') : window.location.href.indexOf('reports');
  const url = `${window.location.href.substring(0, index)}influencer/profile/${record.params['influencer._id']}`;
  let influencer = record.params.sharedId || 'N/A';
  if (typeof record.params.creatorName !== 'undefined') {
    if (!record.params.creatorName.includes('null')) influencer = record.params.creatorName;
  }
  const result = ((typeof record.params['influencer._id'] !== 'undefined')
    ? (
      <span>
        <a href={url} target="_blank" rel="noreferrer">
          {influencer}
        </a>
        {' '}
        <ImpactActionShowAdditionalInfoButton
          record={record}
        />
      </span>
    )
    : influencer);
  return result;
};
