/* eslint react/prop-types: 0 */
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Card, CardBody, DropdownToggle, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
// import ApiClient from 'ApiClient';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
// import APReportContainer from './components/APReportContainer';
// import APReportContainer2 from '../AccountPayableReports2/components/APReportContainer';
// import { getAccountPayableRecord } from '../../../../redux/reducers/admin/AccountsPayableReducer';
import UserPropertyType from './components/custom';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import { parseUrl } from '../../../../shared/helpers/WVUtilities';
import { getConstant } from '../../../../shared/helpers/WVConstants';
import ReportFilter from './ReportFilter';
import SearchBar from './SearchBar';
import PayableReport from './PayableReport';

const CustomCsvModal = ({ isOpen, closeModal }) => {
  const [query, setQuery] = useState('');
  useEffect(() => {
    setQuery('');
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={() => closeModal(false)} className="delete-modal">
      <ModalHeader toggle={() => closeModal(false)} >Download Payout Transfers by Period</ModalHeader>
      <ModalBody>
        <ReportFilter setQuery={setQuery} />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            const win = window.open(`/api/portal/finance/get-csv-report?${query}`, '_blank');
            win.focus();
            console.log(`/api/portal/finance/get-csv-report?${query}`);
            // document.location.assign(`/api/portal/finance/get-csv-report?${query}`);
          }}
          className="mt-2"
        >
          Download
        </Button>
        <Button
          className="btn-sm"
          color="secondary"
          onClick={() => closeModal(false)}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>);
};
CustomCsvModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
const Actions = ({ selected, options, handleChangeAP }) => {
  console.log();
  const [show, setShow] = useState(false);
  return (
    <div className="prospects-actions">
      <Row className="mt-3">
        <Col sm={8}>
          <Select
            value={selected}
            options={options}
            onChange={handleChangeAP}
            placeholder="Select"
          />
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  disabled={!selected?.value}
                  onClick={() => handleChangeAP(null)}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '38px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => {
                  const win = window.open('/api/portal/finance/get-csv-report', '_blank');
                  win.focus();
                }}
              > Download All Payout Transfers
              </DropdownItem>
              <DropdownItem onClick={() => setShow(true)} > Download Payout Transfers by Period</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <CustomCsvModal isOpen={show} closeModal={setShow} />
    </div>
  );
};


const AccountPayableList = ({
  resources,
  history,
}) => {
  const selectedFilter = parseUrl();
  const options = useMemo(() => {
    const gl = getConstant('AP', 'generalLedger');
    return gl.map(g => ({ value: g._id, label: g.name, year: g.yearPeriod }));
  }, []);
  const currentYear = Number((new Date()).getFullYear());
  const currentYearGL = options.find(g => Number(g.year) === currentYear);
  const currentFilter = selectedFilter?.generalLedger && options.find(g => Number(g.year) === currentYear);

  const [selected, setSelected] = useState(currentFilter || currentYearGL);


  const setUrl = () => {
    const search = new URLSearchParams(window.location.search);
    if (selected) {
      search.set('filters.generalLedger', selected.value);
    } else {
      search.delete('filters.generalLedger');
    }
    search.set('page', '1');
    console.log(search.toString());
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  useEffect(() => {
    setUrl();
  }, [selected, selectedFilter?.generalLedger]);

  const handleChangeAP = async (ap) => {
    setSelected(ap);
  };

  console.log(selectedFilter, currentYearGL);

  const resource = resources.find(r => r.id === 'AccountsPayable');
  const action = resource.resourceActions.find(r => r.name === 'list');

  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Account Payable Report', path: null },
        ]}
        isBackButton
      />
      <Row>
        <Col sm={12}>
          <SearchBar />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <PayableReport />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Actions selected={selected} options={options} handleChangeAP={handleChangeAP} />
                </Col>
              </Row>
              <Row>
                <MatTableList
                  action={action}
                  resource={resource}
                  setTag={() => true}
                  date={new Date()}
                  UserPropertyType={UserPropertyType}
                  v={2}
                  noActions
                  sortBy="endDate"
                  direction="desc"
                  showFilters
                  useFlex
                  filters={{
                    isDeleted: 'false',
                    ...selected?.value && !selectedFilter?.generalLedger && { generalLedger: selected?.value },
                  }}
                  isResizable={false}
                  showSearch={false}
                  showTitle={false}
                  columnsSettings={{
                    name: {
                      width: 250,
                    },
                    description: {
                      width: 300,
                    },
                    startDate: {
                      width: 120,
                    },
                    endDate: {
                      width: 120,
                    },
                    generalLedger: {
                      width: 120,
                    },
                    payables: {
                      disableSortBy: true,
                      width: 200,
                    },
                  }}
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

/* class AccountPayableList_ extends React.Component {
  constructor(props) {
    super(props);
    const filters = this.parseUrl();
    this.state = {
      apSelected: (filters && filters.generalLedger ? { value: filters.generalLedger } : { year: (new Date()).getFullYear() }),
    };
    this.loadAPOptions = this.loadAPOptions.bind(this);
    this.handleChangeAP = this.handleChangeAP.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    if (match) {
      dispatch(getAccountPayableRecord(match.params.reportId));
      // this.fetchAccoutPayable(this.props);
    }
  }
  shouldComponentUpdate(newProps) {
    const { match, dispatch } = newProps;
    // console.log(match, this.props.match);
    if (
      (match && match.params.reportId && !this.props.match.params.reportId)
      || (match && match.params.reportId && this.props.match.params.reportId !== match.params.reportId)
      || (match && !match.params.reportId && this.props.match.params.reportId)
      || (match && !match.params.reportId && !this.props.match.params.reportId)
    ) {
      // console.log(match.params.reportId, 'fetching');
      // dispatch(getAccountPayableRecord(match.params.reportId));
    }
    return true;
  }
  setUrl = () => {
    const { history } = this.props;
    const { apSelected } = this.state;
    const search = new URLSearchParams(window.location.search);
    if (apSelected) {
      search.set('filters.generalLedger', apSelected.value);
    } else {
      search.delete('filters.generalLedger');
    }
    search.set('page', '1');
    console.log(search.toString());
    history.push(`${history.location.pathname}?${search.toString()}`);
  }
  parseUrl = () => {
    const filter = {};
    const query = new URLSearchParams(window.location.search);
    // eslint-disable-next-line no-unused-vars, no-restricted-syntax
    for (const entry of query.entries()) {
      const [key, value] = entry;
      if (key.match('filters.')) {
        filter[key.replace('filters.', '')] = value;
      }
    }
    return filter;
  }
  handleChangeAP = async (ap) => {
    this.setState({ apSelected: ap }, () => {
      this.setUrl();
    });
  }
  loadAPOptions = async (inputValue) => {
    const { apSelected } = this.state;
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'GeneralLedger',
      query: inputValue,
    });
    const res = [];
    records.forEach((record) => {
      res.push({
        value: record.id,
        label: record.params.name,
        params: record.params,
        record,
      });
    });
    if (!apSelected.label && apSelected.value && res.find(r => r.value === apSelected.value)) {
      this.setState({ apSelected: res.find(r => r.value === apSelected.value) });
    }
    console.log(apSelected, res);
    if (!apSelected.value && !apSelected.label && apSelected.year) {
      this.handleChangeAP(res.find(r => r.record.params.yearPeriod === apSelected.year));
      // this.setState({ apSelected: res.find(r => r.record.params.yearPeriod === apSelected.year) });
    }
    return res;
  }
  render() {
    const {
      selectedReport,
      isLoading,
      resources,
      match,
      // dispatch,
      // showUpdateAmountModal,
      classes,
    } = this.props;
    const resource = resources.find(r => r.id === 'AccountsPayable');
    const action = resource.resourceActions.find(r => r.name === 'list');
    // console.log(this.props);
    return (
      <Container className="dashboard">
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            { title: 'Account Payable Report', path: null },
          ]}
          isBackButton
        />
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <SearchAccountPayableReport />
                  </Col>
                  <Col lg="auto">
                    <Link to="/reports/calculate-gross-profit">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                      >
                        Calculate Gross Profit
                      </Button>
                    </Link>
                  </Col>
                  <Col lg="auto">
                    <Link to="/finance/account-payable-report/new">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                      >
                        New AP Report
                      </Button>
                    </Link>
                  </Col>
                  <Col lg="auto">
                    <Link to="/resources/AccountsPayable/actions/list">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                      >
                        View AP Reports
                      </Button>
                    </Link>
                  </Col>
                  <Col lg="auto">
                    <Link to="/finance/creator-payment">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                      >
                        Send Payment
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {selectedReport && selectedReport.id && !isLoading && (<APReportContainer2 selectedReport={selectedReport} isLoading={isLoading} type={match.params.type} />) }
            {!match.params.reportId && (<MatTableList
              action={action}
              resource={resource}
              setTag={() => true}
              date={new Date()}
              UserPropertyType={UserPropertyType}
              v={2}
              noActions
              sortBy="endDate"
              direction="desc"
              showFilters
              useFlex
              isResizable={false}
              showSearch={false}
              columnsSettings={{
                name: {
                  width: 250,
                },
                description: {
                  width: 300,
                },
                startDate: {
                  width: 120,
                },
                endDate: {
                  width: 120,
                },
                generalLedger: {
                  width: 120,
                },
                payables: {
                  disableSortBy: true,
                  width: 200,
                },
              }}
              additionalActions={[{
                  type: 'custom',
                  title: 'Select Year',
                  action: () => {},
                  element: (
                    <div className={classes.root}>
                      <Label className="font-weight-bold">Select General Ledger</Label>
                      <Select
                        cacheOptions
                        value={this.state.apSelected}
                        defaultOptions
                        onChange={this.handleChangeAP}
                        placeholder="Select"
                      />
                      <div className={classes.close} >
                        {this.state.apSelected && this.state.apSelected.value && (
                          <IconButton
                            onClick={() => this.handleChangeAP(null)}
                            size="small"
                          >
                            <CloseCircleOutlineIcon />
                          </IconButton>)}
                      </div>
                    </div>),
                  row: 1,
                  reset: () => {
                    this.setState({ apSelected: null });
                  },
              }, {
                type: 'custom',
                title: 'Change Created Date',
                action: () => (<ReportFilter />),
                element: (<ReportFilter />),
                reset: () => {

                },
              }]}
            />)}
          </Col>
        </Row>
      </Container>
    );
  }
} */

AccountPayableList.propTypes = {
  // match: PropTypes.objectOf(PropTypes.any).isRequired,
  // selectedReport: PropTypes.objectOf(PropTypes.any),
  // isLoading: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  // showUpdateAmountModal: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  selectedReport: state.accountPayable.accountPayable,
  isLoading: state.accountPayable.loading.accountPayable,
  showUpdateAmountModal: state.accountPayable.showUpdateAmountModal,
  resources: state.resources,
});

export default withStyles({
  root: {
    width: '100%',
    minWidth: '250px',
    paddingRight: '30px',
    paddingLeft: '15px',
    position: 'relative',
  },
  close: {
    position: 'absolute',
    bottom: '5px',
    right: '0px',

  },
})(connect(mapStateToProps, null)(AccountPayableList));
