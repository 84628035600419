/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ViewHelpers from '@/shared/ViewHelpers';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import UserPropertyType from './components/custom';
import { currentTimezoneAggr } from '../../../../shared/helpers/Dates';

const ResourceAction = (props) => {
  const {
    resources,
  } = props;

  const h = new ViewHelpers();
  const resource = resources.find(r => r.id === 'PortalNotification');
  resource.listProperties.forEach((l) => {
    // eslint-disable-next-line no-param-reassign
    if (l.name === 'startDate') l.custom.label = `Start Date (${currentTimezoneAggr()})`;
    // eslint-disable-next-line no-param-reassign
    if (l.name === 'endDate') l.custom.label = `End Date (${currentTimezoneAggr()})`;
    // eslint-disable-next-line no-param-reassign
    if (l.name === 'createdAt') l.custom.label = `Date Created (${currentTimezoneAggr()})`;
  });
  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <Breadcrumbs resource={resource} actionName="list" isBackButton />
          <h3>Notifications Dashboard</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={h.resourceActionUrl({ resourceId: 'PortalNotification', actionName: 'new' })}>
            <button className="btn btn-primary btn-sm">Add Notification</button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <MatTableList
            resource={resource}
            UserPropertyType={UserPropertyType}
            sortBy="_id"
            direction="asc"
            columnsSettings={{
              notificationName: {
                width: 350,
              },
              createdAt: {
                width: 150,
              },
              displayMessage: {
                width: 160,
              },
              startDate: {
                width: 160,
              },
              endDate: {
                width: 160,
              },
              actionsCustom: {
                width: 30,
              },
            }}
            date={new Date()}
            showFilters
            hideToolbar
            v={2}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
