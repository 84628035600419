/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Legend = ({
  keys,
  colors,
  opacity,
  opacityColors,
  setOpacity,
  icon,
  show,
}) => {
  const [defaultOpacity, setDefaultOpacity] = useState(opacity || opacityColors);
  const gray = opacity ? '0.2' : '#edeff0';

  useEffect(() => {
    const _opacity = opacity || opacityColors;
    const opacityKeys = Object.keys(_opacity) || [];
    const defaultOpacityKeys = Object.keys(defaultOpacity) || [];
    const len = Math.max(opacityKeys.length, defaultOpacityKeys.length);
    for (let i = 0; i < len; i += 1) {
      if (opacityKeys[i] !== defaultOpacityKeys[i]) {
        setDefaultOpacity(_opacity);
        break;
      }
    }
  }, [opacity, opacityColors]);

  const handleMouseEnter = (key) => {
    if (setOpacity) {
      const values = { ...defaultOpacity };
      // eslint-disable-next-line no-return-assign
      Object.keys(values).forEach((k) => { if (k !== key) values[k] = gray; });
      setOpacity(values);
    }
  };

  const handleMouseLeave = () => {
    if (setOpacity) {
      setOpacity(defaultOpacity);
    }
  };

  return show ? (
    <ul
      style={{
        listStyle: 'none',
        paddingLeft: '0',
        maxHeight: '280px',
        overflow: 'auto',
      }}
    >
      {keys.map(row => colors[row.value] && (
        <li
          key={row.value}
          style={{
            color: colors[row.value],
            cursor: 'pointer',
            fontWeight: '600',
          }}
          onMouseEnter={() => handleMouseEnter(row.value)}
          onMouseLeave={handleMouseLeave}
        >
          {icon}  {row.label}
        </li>
      ))}
    </ul>
  ) : '';
};

Legend.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.any).isRequired,
  colors: PropTypes.objectOf(PropTypes.any).isRequired,
  opacity: PropTypes.objectOf(PropTypes.any),
  opacityColors: PropTypes.objectOf(PropTypes.any),
  setOpacity: PropTypes.func,
  icon: PropTypes.string,
  show: PropTypes.bool,
};

Legend.defaultProps = {
  opacity: null,
  opacityColors: null,
  setOpacity: null,
  icon: String.fromCharCode(9632),
  show: true,
};

export default Legend;
