import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// import MatTableForRedux from '../../../../../shared/tables/materialTable/components/MatTableForRedux';
import { getCreators } from '../../../../../redux/reducers/admin/AgentReducer';
import PaymentMethods from './PaymentMethods';
import Actions from './Actions';
import EnablePaymentSelect from './PayeeSelect';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';
// import CreateTableData from '../../../../../shared/tables/CreateData';

// const columnTransform = {
//   creatorName: {
//     title: 'Creator Name',
//     valueFunc: record => (`${record.first_name || ''} ${record.last_name || ''}`),
//     sort: record => (`${record.first_name || ''} ${record.last_name || ''}`),
//     searchFunc: (record, search) => (`${record.first_name || ''} ${record.last_name || ''}`.toLowerCase().indexOf(search.toLowerCase()) !== -1),
//   },
//   enablePayments: {
//     title: 'Enable Payments',
//     Component: EnablePaymentSelect,
//     // alignment: 'center',
//   },
//   paymentMethods: {
//     title: 'Payment Methods',
//     // alignment: 'center',
//     Component: PaymentMethods,
//   },
// };

const CreatorsListContainer = ({
  match,
  dispatch,
  loading,
  creators,
  currentAgent,
}) => {
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };
  const getData = (agentId) => {
    if (match) {
      dispatch(getCreators(agentId));
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Creator Name',
        accessor: p => `${p?.first_name || ''} ${p?.last_name || ''}`,
        Cell: ({ value }) => value,
        width: 500,
      },
      {
        Header: 'Enable Payments',
        accessor: () => {},
        Cell: p => useMemo(() => (<EnablePaymentSelect record={p.row.original} />), [p.row.original]),
        disableGlobalFilter: true,
        width: 200,
      },
      {
        Header: 'Payment Methods',
        accessor: () => {},
        Cell: p => useMemo(() => (<PaymentMethods record={p.row.original} />), [p.row.original]),
        disableGlobalFilter: true,
        width: 200,
      },
      {
        Header: '',
        id: 'actions',
        accessor: () => {},
        Cell: p => useMemo(() => (<Actions record={p.row.original} meta={{ closeFunction: () => getData(match.params.agentId) }} />), [p.row.original]),
        disableGlobalFilter: true,
        width: 100,
      },
    ],
    [],
  );

  useEffect(() => {
    if (match.params && match.params.agentId) {
      getData(match.params.agentId);
    }
  }, []);

  return (
    <>
      <Row>
        <Col md="3">
          <h2 style={{ marginBottom: '15px' }}>Creators</h2>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <h4 style={{ marginBottom: '15px' }}>
            {`Agent: ${currentAgent && currentAgent.first_name ? currentAgent.first_name : ''} ${currentAgent && currentAgent.last_name ? currentAgent.last_name : ''}`}
          </h4>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col sm="12" className="agent-creators-list-dashboard">
              {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
              {!loading && creators.length > 0 && (
                // <MatTableForRedux
                //   dataArray={creators}
                //   columns={columnTransform}
                //   version={(new Date()).toString()}
                //   hideToolbar
                // />
                <ReactTableBase
                  key="searchable"
                  columns={columns}
                  data={creators}
                  tableConfig={tableConfig}
                />
              )}
            </Col>
          </Row>
          {!loading && creators.length === 0 && (
            <Alert color="warning">
              <p className="py-2 text-dark">
                {`${currentAgent.first_name} ${currentAgent.last_name} currently has no creators yet.`}
              </p>
            </Alert>
          )}
        </CardBody>
      </Card>
    </>
  );
};
CreatorsListContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  currentAgent: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  creators: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  dispatch: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  loading: state.AgentReducer.loading.creators,
  creators: state.AgentReducer.creators,
  currentAgent: state.AgentReducer.currentAgent,
});

export default connect(mapStateToProps)(withRouter(CreatorsListContainer));
