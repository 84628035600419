import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import Select from 'react-select';
import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';

import { Row, Col, Button, Card, CardBody, FormGroup, Spinner } from 'reactstrap';
import Filter from '../../../Reports/FilterNew';
import Columns from '../../../Reports/CreatorsReport2/Columns';
import Total from '../../../Reports/CreatorsReport2/Total';
import GenerationInfo from '../../../Reports/GenerationInfoV2';
import Errors from '../../../Reports/Errors';

import withNotice from '../../../../App/store/with-notice';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import DownloadCSV from '../../../Reports/CreatorsReport2/DownloadCSV';
import { getConstant } from '../../../../../shared/helpers/WVConstants';
import PermissionComponent from '../../../../../shared/components/PermissionComponent';
import { CheckPermission } from '../../../../../shared/helpers/WVUtilities';
import CreatorModal from '../../../Reports/CreatorsReport2/CreatorModal';

import {
  convertDealsForCreators,
  groupByDealResp,
  groupByCreator,
} from '../../../Reports/ReportGroupBy';


const calculateForDeal = (deal) => {
  const a = (deal.remainingRevenueConversionsAmount + deal.payableRevenueAmount + (Number(deal.billRevenue || 0))) - (deal.payablePayoutAmount) -
  (deal.totalPayoutWithMulligan !== 0 ? (deal.remainingPayoutConversionsAmount + deal.billPayout) : 0);
  return a;
};
const calculateRevenueForDeal = (deal) => {
  const a = deal.remainingRevenueConversionsAmount + deal.payableRevenueAmount + (Number(deal.billRevenue || 0));
  return a;
};
const SummaryReport = ({ addNotice, influencer: influencerObject }) => {
 
  const [loading, setLoaing] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState(null);
  const [showId, setShowId] = useState(null);
  const [loadingAdvertiser, setLoaingAdvertiser] = useState(false);
  const [selectedContentType, setSelectedContentType] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [items, setItems] = useState([]);
  const [dealsForInfluencer, setDeals] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [isByDate, setIsByDate] = useState(false);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [mc, setMc] = useState({});
  const [stats, setStats] = useState({
    grossRevenue: 0, grossProfit: 0, clicks: 0, conversions: 0, count: 0,
  });
  const [fetched, setFetched] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedStage, setSelectedStage] = useState(null);
  const [errors, setErrors] = useState([]);

  const processDeals = (dealsToFetch) => {
    // const influencers = {};
    const rightDeals = [];
    let grossRevenue = 0;
    let grossProfit = 0;
    let clicks = 0;
    const selectedContentTypesArray = selectedContentType?.map(a => a.value) || null;
    const selectedEmployeeArray = selectedEmployee?.map(a => a.value) || null;

    // console.log(selectedContentTypesArray);
    // console.log(mc);
    // const countwithConversions = {};
    dealsToFetch.forEach((deal) => {
      // console.log(deal?.dealTerms?.content_type);
      if (!deal?.influencer?.affiliateDataObject?.tune_id) return false;
      console.log(mc[deal?.influencer._id]);
      if (selectedStage && selectedStage.value === 1 && mc[deal?.influencer._id] > 1) {
        return false;
      }
      if (selectedStage && selectedStage.value === 2 && (!mc[deal?.influencer._id] || mc[deal?.influencer._id] <= 1)) {
        return false;
      }
      if (selectedContentTypesArray &&
        selectedContentTypesArray.length > 0 &&
        !selectedContentTypesArray.includes(deal?.deal?.dealTerms?.content_type)
      ) return false;
      if (selectedEmployeeArray &&
        selectedEmployeeArray.length > 0 &&
        !selectedEmployeeArray.includes(deal?.adminAF?._id)
      ) return false;
      // if (selectedTags && !selectedTags.some(item => tags.includes(item))) return false;
      if (selectedTags?.length > 0) {
        const fields = tags.filter(tag => selectedTags.map(t => t.value).includes(tag.tagName));
        console.log(fields, deal?.influencer._id);
        if (!fields.some(f => f.creators.includes(deal?.influencer._id))) return false;
      }
      rightDeals.push(deal);
      grossRevenue += deal.deal ? calculateRevenueForDeal(deal) : 0;
      grossProfit += deal.deal ? calculateForDeal(deal) : 0;
      clicks += Number(deal.report?.clicks || 0);
      // conversions += Number(deal.conversionsArray?.length || 0);
      return true;
    });

    const influencers = groupByCreator(rightDeals);
    let totalConversion = 0;
    Object.values(influencers).forEach((influencer) => {
      influencers[influencer.tuneId].conversions += Number(influencers[influencer.tuneId].cpmConversionsCount || 0);
      totalConversion += influencers[influencer.tuneId].conversions;
      influencers[influencer.tuneId].countMediaContent = mc[influencer.influencer._id];
    });

    console.log(influencers);
    const filteredInfluencers = Object.values(influencers);
    setStats({
      grossRevenue,
      grossProfit,
      clicks,
      conversions: totalConversion,
      count: filteredInfluencers.filter(c => c.conversions > 0).length,
    });
    setItems(filteredInfluencers);
  };
  useEffect(() => {
    if (dealsForInfluencer) processDeals(dealsForInfluencer);
  }, [selectedContentType, selectedEmployee, selectedTags, selectedStage, dealsForInfluencer]);
  useEffect(() => {
    const api = new ApiClient();
    api.client.get('/api/portal/finance/get-deal-owners').then((resp) => {
      setEmployees(resp.data.dealOwners);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load employees, Check out console to see more information.',
        type: 'error',
      });
    });
    api.client.get('/api/portal/reports/get-tags').then((resp) => {
      setTags(resp.data.tags);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load Tags, Check out console to see more information.',
        type: 'error',
      });
    });
    api.client.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      // setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands));
      setLoaingAdvertiser(false);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);
  const selectedDeals = showId ? dealsForInfluencer.filter(d => d.influencer._id === showId) : [];
  const fetchAmountReport = async () => {
    setLoaing(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    console.log({
      advertiser: selectedAdvertisers?.value || null,
      creators: [influencerObject.id] || [],
    });
    try {
      axios.post('/api/portal/reports/post-creators-by-brand-full-contents', {
        startDate,
        endDate,
        advertisers: selectedAdvertisers ? [selectedAdvertisers?.value] : null,
        // offer: selectedOffer?.value,
        creators: [influencerObject.id], // internal _id
        employees: [], //selectedEmployee?.map(i => i.value),
        identity,
        // ...includedLastMonth && { estimatePrevious, includePrevious, selectedStatuses },
      }).then((resp) => {
        console.log('Media Contents: ', resp.data.mediaContentns);
        const mediaContentCount = {};
        resp.data.mediaContentns.forEach((mContent) => {
          if (!mediaContentCount[mContent.influencer]) mediaContentCount[mContent.influencer] = 0;
          if (mContent.publishDate) mediaContentCount[mContent.influencer] += 1;
        });
        // setPToCategories(pToR);
        setMc(mediaContentCount);
      });
      const resp = await axios.post('/api/portal/reports/post-creators-by-brand-full', {
        startDate,
        endDate,
        advertisers: selectedAdvertisers?.value ? [selectedAdvertisers.value] : null,
        // offer: selectedOffer?.value,
        creators: [influencerObject.id], // internal _id
        employees: selectedEmployee?.map(i => i.value),
        identity,
        // ...includedLastMonth && { estimatePrevious, includePrevious, selectedStatuses },

      });
      if (resp.data.success) {
        console.log(resp.data.mediaContentns);
        // const mediaContentCount = {};
        // resp.data.mediaContentns.forEach((mContent) => {
        //  if (!mediaContentCount[mContent.influencer]) mediaContentCount[mContent.influencer] = 0;
        //  if (mContent.publishDate) mediaContentCount[mContent.influencer] += 1;
        // });

        // setCpmConversions(resp.data.cpmConversions);
        const deals = groupByDealResp(resp.data.items);
        convertDealsForCreators(deals, startDate, endDate);
        setDeals(Object.values(deals));
        // setPToTags(pToR);
        setFetched(true);
        // setMc(mediaContentCount);
        setLoaing(false);
        setErrors(resp.data.notUsedConversions);
        // setVersion((new Date()).toString());
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const employeeOptions = useMemo(() => {
    const i = employees.map(item => ({ value: item._id, label: `${item.firstName || ''} ${item.lastName || ''}` }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [employees]);
  const handleClickAction = (influencerId) => {
    setShowId(influencerId);
    // console.log(influencerId);
  };

  const contentTypes = getConstant('mediaContent', 'types', {});
  const contentTypeOptions = useMemo(() => Object.keys(contentTypes).map(t => ({ value: t, label: contentTypes[t] })), [contentTypes]);
  const tagsOption = useMemo(() => {
    const i = Object.keys(tags).map(key => ({ value: tags[key].tagName, label: tags[key].tagName }));
    return i;
  }, [tags]);
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40, 100],
    placeholder: 'Search...',
    sortBy: 'revenue',
    direction: 'desc',
  };
  const options = useMemo(() => {
    const i = advertisers.filter(b => b.displayInUi === 'Yes').map(item => ({ value: item._id, label: item.companyName }));
    i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [advertisers]);

  const columns = useMemo(() => Columns({ handleClickAction, removeTotal: true }), [items]);
  if (!CheckPermission('reports----creators')) return (<PermissionComponent />);
  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Creator Performance</h2>
      <Row>
        <Col>
          <div>
            <Card>
              <CardBody>
                <Row className="mt-3" >
                  <Col>
                    <Filter
                      setValue={(param, value, isByDateSelected = false) => {
                        console.log(value);
                        if (param === 'startDate') {
                          setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                        }
                        if (param === 'endDate') {
                          setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                        }
                        setIsByDate(isByDateSelected);
                      }}
                    />
                  </Col>
                  <Col lg="auto">
                    <div>
                      <Button
                        color="primary"
                        onClick={async () => {
                          if (!isByDate) {
                            await fetchAmountReport();
                          } else {
                            // await fetchAmountReportByDates();
                          }
                          return true;
                        }}
                        className="mr-2 btn-sm mt-xl-0 mt-lg-3 mb-0"
                        disabled={!!loading}
                      >
                        Run Report
                      </Button>
                      <GenerationInfo />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <FormGroup>
                      <Select
                        isDisabled={loadingAdvertiser}
                        value={selectedAdvertisers}
                        options={options}
                        onChange={setSelectedAdvertisers}
                        placeholder="Select Brand..."
                        isClearable
                      />
                    </FormGroup>
                  </Col>
                  <Col />
                </Row>
                {!loading && fetched && errors.length > 0 ? (
                  <Errors errors={errors} />
                ) : null }
                {loading ? (<div className="text-center"><Spinner /></div>) : null}
                {fetched ? (
                  <Row className="my-2">
                    <Col>
                      <Total stats={stats} removeTotal />
                    </Col>
                  </Row>
                ) : null}
                {fetched ? (
                  <Row className="mt-3" >
                    <Col>
                      <Select
                        isDisabled={loadingAdvertiser}
                        value={selectedEmployee}
                        options={employeeOptions}
                        onChange={setSelectedEmployee}
                        placeholder="Select Account Manager..."
                        isMulti
                      />
                    </Col>
                    <Col>
                      <Select
                        value={selectedContentType}
                        options={contentTypeOptions}
                        onChange={setSelectedContentType}
                        placeholder="Select Content Type..."
                        isMulti
                      />
                    </Col>
                    <Col>
                      <Select
                        isDisabled={loadingAdvertiser}
                        value={selectedTags}
                        options={tagsOption}
                        onChange={setSelectedTags}
                        placeholder="Select Tag..."
                        isMulti
                      />
                    </Col>
                    <Col>
                      <Select
                        value={selectedStage}
                        options={[
                          { value: 1, label: 'Test Buy' },
                          { value: 2, label: 'Rebuy' },
                        ]}
                        onChange={setSelectedStage}
                        placeholder="Campaign Stage..."
                        isClearable
                      />
                    </Col>
                  </Row>
                ) : null}
                <Row className="mt-3">
                  <Col />
                  <Col lg="auto">
                    {dealsForInfluencer.length > 0 ? (
                      <div className="float-left mr-0 mb-0">
                        <DownloadCSV items={items} />
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    {!loading && fetched ? (
                      <ReactTableBase
                        key="searchable"
                        columns={columns}
                        data={items}
                        tableConfig={tableConfig}
                      />) : null
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
      <CreatorModal selectedDeals={selectedDeals} show={!!showId} setShow={setShowId} calculateForDeal={calculateForDeal} calculateRevenueForDeal={calculateRevenueForDeal} />
    </div>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
  influencer: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withNotice(SummaryReport);
