/* eslint-disable filenames/match-exported */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import { calculateDays } from '../../../../../shared/helpers/CACHelper';
import { formatCurrency, formatNumber } from '../../../../../shared/helpers/WVFormatter';


const WideTooltip = withStyles({
  tooltip: {
    width: '500px',
  },
})(Tooltip);

const getNumber = (str) => (str !== null && /^[0-9]+(\.[0-9]+)?$/.test(str)) ? Number(str) : 0;

const PredictedCAC = React.memo(({
  projectedPercent,
  projectedConversions,
  projectedCac,
  defaultTargetCac,
  publishDate,
  allow,
}) => {
  const valueNumber = getNumber(projectedCac);
  const value = projectedCac === null || projectedCac === '-' ? '-' : formatCurrency(valueNumber);
  const days = calculateDays({ mediaPublishedDate: publishDate });
  if (days < 16) return '-';

  const describeValue = (
    <div style={{ fontSize: '14px', maxWidth: '500px', lineHeight: 2 }}>
      <span><strong>Target CAC</strong>: {formatCurrency(Number(defaultTargetCac))}</span><br />
      <strong>Projected Percent %</strong>: {formatNumber(getNumber(projectedPercent))}<br />
      <strong>Projected Revenue Conversions</strong>: {formatNumber(getNumber(projectedConversions))}<br />
      <strong>Projected CAC</strong>: {projectedCac !== '-' ? formatCurrency(getNumber(projectedCac)) : '-'}<br />
      {/* eslint-disable-next-line max-len */}
      <span><strong>Predicted CAC</strong> of <strong>{projectedCac !== '-' ? formatCurrency(getNumber(projectedCac)) : '-'}</strong> {allow ? 'is less than ' : 'is over'} the <strong>{formatCurrency(getNumber(defaultTargetCac))}</strong> target</span><br />
    </div>);

  return (
    <>
      <WideTooltip title={describeValue}><span className={allow ? 'text-success' : 'text-danger'}>{value}</span></WideTooltip>{' '}
      {allow ? (
        <FontAwesomeIcon icon="thumbs-up" className="text-success" />
      ) : (
        <FontAwesomeIcon icon="thumbs-down" className="text-danger" />
      )
      }
    </>
  );
}, (a, a1) => _.isEqual(a.projectedPercent, a1.projectedPercent)
  && _.isEqual(a.projectedConversions, a1.projectedConversions)
  && _.isEqual(a.projectedCac, a1.projectedCac)
  && _.isEqual(a.defaultTargetCac, a1.defaultTargetCac)
  && _.isEqual(a.allow, a1.allow)
  && _.isEqual(a.publishDate, a1.publishDate));

PredictedCAC.propTypes = {
  projectedPercent: PropTypes.any,
  projectedConversions: PropTypes.any,
  projectedCac: PropTypes.any,
  defaultTargetCac: PropTypes.any,
  allow: PropTypes.bool,
  publishDate: PropTypes.any,
};
PredictedCAC.defaultProps = {
  projectedPercent: '-',
  projectedConversions: '-',
  projectedCac: '-',
  defaultTargetCac: '-',
  allow: false,
  publishDate: '',
};

export default PredictedCAC;
