import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import HistoryIcon from 'mdi-react/HistoryIcon';
import StatusComponent from './custom/StatusComponent';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import UserPropertyType from './custom/index';
import StripeStatusComponent from './custom/StripeStatusComponent';
import DealNameField from './custom/DealNameField';
// import PayoutAmount from './custom/PayoutAmount';
// import RevenueAmount from './custom/RevenueAmount';
import ILPayoutAmount from './custom/ILPayoutAmount';
import formater from '../../../../../shared/helpers/WVFormatter';
import HubspotContactLink from '../../../../../shared/components/HubspotContactLink';

const TableAgent = React.memo(({
  action,
  resource,
  version,
  // recordsRef,
  filters,
}) => (
  <MatTableList
    action={action}
    resource={resource}
    date={new Date()}
    version={version}
    UserPropertyType={UserPropertyType}
    showFilters={false}
    filters={filters}
    showTitle={false}
    v={2}
    noActions
    isResizable={false}
    useFlex
    useCheckboxes
    useMultiPageCheckboxes
    checkboxHandler={() => {}}
    notFoundProps={{ title: 'No Agent Payables Found' }}
    useUnflatten
    // recordsRef={recordsRef}
    customColumns={useMemo(
        () => [
          {
            Header: 'Payment Method',
            accessor: record => record.params?.payment || '',
            id: 'paymentStatus',
            Cell: p => useMemo(() => (<StripeStatusComponent record={p.row.original} type="agent" />), [p.row.original]),
          },
          {
            Header: () => (
              <div>
                Status
                <br />
                <span className="text-warning"><HistoryIcon /></span> - Offline Payout
                <br />
                <span className="text-success"><HistoryIcon className="text-success" /></span> - System Payout
              </div>),
            minWidth: 210,
            id: 'status',
            width: 210,
            accessor: record => record.params.status || '',
            Cell: p => useMemo(() => (<StatusComponent record={p.row.original} type="agent" updateRecordFunction={p.updateRecordFunction} />), [p.row.original]),
          },
          {
            Header: 'Agent Name',
            accessor: record => `${record?.populated?.agent?.params?.first_name} ${record?.populated?.agent?.params?.last_name}`.toLowerCase(),
            Cell: p => useMemo(() => (
              <HubspotContactLink
                id={Number(p.row.original?.populated?.agent?.params?.hsContactVid || 0)}
                title={`${p.row.original?.populated?.agent?.params?.first_name || ''} ${p.row.original?.populated?.agent?.params?.last_name || ''}`}
              />), [p.row.original]),
            // Cell: p => useMemo(() => `${p.row.original?.populated?.agent?.params?.first_name} ${p.row.original?.populated?.agent?.params?.last_name}`, [p.row.original]),
          },
          {
            Header: 'Agent Email',
            accessor: record => (record?.populated?.agent?.params?.email?.toLowerCase() || ''),
            Cell: p => (<Tooltip title="Open Agent Profile"><a href={`/agent/profile/${p.row.original.populated.agent.id}`} target="_blank" rel="noreferrer" >{p.row.original?.populated?.agent?.params?.email}</a></Tooltip>),
          },
          {
            Header: 'Deal Name',
            id: 'Deal Name',
            accessor: (record) => {
              if (record?.deal?.dealname) {
                return record.deal.dealname;
              }
              return '';
            },
            Cell: p => useMemo(() => (<DealNameField record={p.row.original} type="agent" updateRecordFunction={p.updateRecordFunction} />), [p.row.original]),
          },
          {
            Header: 'Creators Payout',
            accessor: record => Number(record.params.totalCreatorAmount || 0),
            Cell: p => useMemo(() => formater.formatCurrency(p.value), [p.row.original]),
          },
          {
            Header: 'Amount',
            minWidth: 200,
            width: 200,
            accessor: record => Number(record?.params?.ilPayoutAmount),
            Cell: p => useMemo(() => (<ILPayoutAmount record={p.row.original} type="agent" updateRecordFunction={p.updateRecordFunction} />), [p.row.original]),
          },
          /* {
            Header: '',
            id: 'actions',
            accessor: record => Number(record.params.revenueAmount || 0) - Number(record.params.ilPayoutAmount || 0),
            Cell: p => useMemo(() => (<ShowPayableJournalsButton
              payable={p.row.original}
            />), [p.row.original]),
          }, */
          /* {
            Header: 'Actions',
            accessor: record => Number(record?.params?._id),
            Cell: p => useMemo(() => (<IconButton onClick><i className="fa fa-info" style={{ fontSize: '2rem' }} /></IconButton>), [p.row.original]),
          }, */
        ],
        [version],
      )}
  />
), (a, a1) => _.isEqual(a.version, a1.version) && _.isEqual(a.brandSafetySearchTerms, a1.brandSafetySearchTerms) && _.isEqual(a.brands, a1.brands));

TableAgent.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,

  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // recordsRef: PropTypes.any.isRequired,
};

TableAgent.defaultProps = {
};
export default TableAgent;
