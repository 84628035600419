/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React from 'react';
import { Modal, ButtonToolbar, Button, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { currentTimezoneAggr } from '../../../../../../shared/helpers/Dates';

const StatusHistoryModal = ({
  modal,
  setModalVisible,
  record,
}) => (
  <Modal
    isOpen={modal}
    toggle={() => setModalVisible(false)}
  >
    <ModalHeader
      toggle={() => { setModalVisible(false); }}
      tag="h3"
      className="modal-title-statistics"
    >
      Review Status Change History
    </ModalHeader>
    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      {record?.params?.creatorReviewStatus?.reviewStatusHistory?.length > 0 ? (
        <div className="mt-1 mb-4">
          {record.params.creatorReviewStatus.reviewStatusHistory.map((item) => {
            if (item.status?.length > 0) {
              return (
                <div className="mt-3" key={item.currentStatus}>
                  <div>
                    <strong>{`${moment(item.status[0].dateModified).format('MMMM YYYY')} Review (${item.currentStatus})`}</strong>
                  </div>
                  <hr className="mt-2 mb-2" />
                  {item.status.map((statusItem) => {
                    const previousName = statusItem.prevStatus || null;
                    const currentName = statusItem.newStatus || null;
                    return (
                      <div
                        style={{ paddingLeft: '15px' }}
                        key={moment(statusItem.dateModified).toString() + moment.now().toString()}
                      >
                        <div>
                          <strong>{moment(statusItem.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong>
                        </div>
                        <div className="pl-3">
                          <a
                            href={statusItem.adminUser ? `/resources/AdminUser/records/${statusItem.adminUser}/show` : '#'}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {statusItem.adminUserName}
                          </a> - <b>{previousName}</b> changed to <b>{currentName}</b>
                        </div>
                      </div>);
                  })}
                </div>
              );
            }
            return null;
          })}
        </div>
      ) : 'There is nothing to see.'}
    </div>
    <ButtonToolbar className="modal__footer">
      <Button className="modal_cancel btn-sm" color="secondary" onClick={() => setModalVisible(false)}>Close</Button>
    </ButtonToolbar>
  </Modal>
);

StatusHistoryModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

StatusHistoryModal.defaultProps = {

};

export default StatusHistoryModal;
