import { unflatten } from 'flat';
// import React from 'react';
import PropTypes from 'prop-types';

const AdminUserInList = ({ record }) => {
  const recordUnflatten = unflatten(record);
  const { submitterAdminUserId } = recordUnflatten.populated;
  let val;
  if (submitterAdminUserId && (submitterAdminUserId.params?.firstName || submitterAdminUserId.params?.lastName)) {
    val = `${submitterAdminUserId.params.firstName} ${submitterAdminUserId.params.lastName}`;
  } else {
    val = submitterAdminUserId?.params ? submitterAdminUserId.params.email : '';
  }
  return val;
};
AdminUserInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default AdminUserInList;

