import React, { useEffect, useState, useMemo } from 'react';
import { axios } from 'ApiClient';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';


const Filter = ({
  selectedAdvertiser, setSelectedAdvertiser, selectedOffer, setSelectedOffer, selectedDealType, setSelectedDealType, selectedEmployee, setSelectedEmployee,
}) => {
  const [advertisers, setAdvertisers] = useState([]);
  const [offers, setOffers] = useState([]);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState([]);

  useEffect(() => {
    setLoadingAdvertiser(true);
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      // setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands));
      setOffers(resp.data.offers);
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      /* addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      }); */
    });
  }, []);
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    setLoadingAdvertiser(true);
    axios.get('/api/portal/finance/get-deal-owners').then((resp) => {
      setEmployees(resp.data.dealOwners);
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      /* addNotice({
        message: 'There was an error when try load employees, Check out console to see more information.',
        type: 'error',
      }); */
    });
  }, []);
  const advertiserOptions = useMemo(() => advertisers.filter(b => b.displayInUi === 'Yes').map(a => ({ value: a._id, label: a.companyName })), [advertisers]);
  const offerOptions = useMemo(() => offers.filter(o => !selectedAdvertiser || selectedAdvertiser?.map(a => a.value).includes(o.advertiser._id)).map(a => ({ value: a._id, label: a.name })), [offers, selectedAdvertiser]);
  const employeeOptions = useMemo(() => {
    const i = employees.map(item => ({ value: item._id, label: `${item.firstName || ''} ${item.lastName || ''}`, item }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [employees]);
  return (
    <Row className="mt-3" >
      <Col>
        <Select
          isDisabled={loadingAdvertiser}
          value={selectedEmployee}
          options={employeeOptions}
          onChange={setSelectedEmployee}
          placeholder="Select Deal Owner..."
          isMulti
        />
      </Col>
      <Col>
        <Select
          isDisabled={loadingAdvertiser}
          value={selectedAdvertiser}
          options={advertiserOptions}
          onChange={setSelectedAdvertiser}
          placeholder="Select Brand..."
          isMulti
        />
      </Col>
      <Col>
        <Select
          value={selectedOffer}
          options={offerOptions}
          onChange={setSelectedOffer}
          placeholder="Select Offer..."
          isMulti
        />
      </Col>
      <Col>
        <Select
          value={selectedDealType}
          options={[
            { value: 'Hybrid', label: 'Hybrid' },
            { value: 'CPA/Ambassador', label: 'CPA/Ambassador' },
            { value: 'Upfront Only', label: 'Upfront Only' },
            { value: 'Upfront CPM', label: 'Upfront CPM' },
            { value: 'Bonus', label: 'Bonus' },
          ]}
          onChange={setSelectedDealType}
          placeholder="Deal Type..."
          isClearable
          isMulti
        />
      </Col>
    </Row>);
};
Filter.propTypes = {
  setSelectedAdvertiser: PropTypes.func.isRequired,
  selectedAdvertiser: PropTypes.arrayOf(PropTypes.any),
  setSelectedOffer: PropTypes.func.isRequired,
  selectedOffer: PropTypes.arrayOf(PropTypes.any),
  setSelectedDealType: PropTypes.func.isRequired,
  selectedDealType: PropTypes.arrayOf(PropTypes.any),
  setSelectedEmployee: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.arrayOf(PropTypes.any),
};
Filter.defaultProps = {
  selectedAdvertiser: [],
  selectedOffer: [],
  selectedDealType: [],
  selectedEmployee: [],
};
export default Filter;
