import React from 'react';
import { Row, Col, Card, CardBody, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import moment from 'moment';
// import DatePicker from 'react-datepicker';
import RefreshIcon from 'mdi-react/RefreshIcon';
import EditIcon from 'mdi-react/EditIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
// import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
// import withNotice from '../../../../App/store/with-notice';
import AccountFinanceModal from '../../../../../shared/components/reportDates/AccountFinanceModal';
import formater from '../../../../../shared/helpers/WVFormatter';
import { momentLocaleToServerUTC, Months } from '../../../../../shared/helpers/Dates';
import AccountFinanceDates from '../../../../../shared/components/reportDates/AccountFinanceDates';
import { getAccountReceivables, fillAccountReceivables, editAccountReceivableRecord } from '../../../../../redux/reducers/admin/AccountsReceivableReducer';
import { addNotice } from '../../../../App/store/store';
import ReportModal from '../../../../../shared/components/modals/ReportModal';
import csvHelper from '../../../../../shared/helpers/CSVHelper';

const columnTransform = {
  advertiserName: {
    title: 'Advertiser',
    valueFunc: record => (record.populated && record.populated.advertiser ? record.populated.advertiser.params.companyName : ''),
    sort: record => (record.populated && record.populated.advertiser ? record.populated.advertiser.params.companyName : ''),
  },
  amountReceived: {
    title: 'Revenue',
    valueFunc: record => formater.formatNumber((parseFloat(record.params.amountReceived))),
    sort: record => (parseFloat(record.params.amountReceived)),
  },
};

/*
const columns = [
  { name: 'advertiserName', label: 'Advertiser', id: 'advertiserName' },
  { name: 'amountReceived', label: 'Revenue', id: 'amountReceived' },
]; */
const downloadCsv = [
  {
    label: 'Advertiser',
    func: record => (record.populated && record.populated.advertiser ? record.populated.advertiser.params.companyName : ''),
  }, {
    label: 'Revenue',
    func: record => formater.formatNumber((parseFloat(record.params.amountReceived))),
  },
];
// const resourceIdName = 'AccountsReceivable';
class ARReportContainer extends React.Component {
  /* static getMinDate(month, yearPeriod) {
    return moment({ y: yearPeriod, M: Number(month) - 1, d: 1 });
  }
  static getMaxDate(month, yearPeriod) {
    return ARReportContainer.getMinDate(month, yearPeriod).endOf('month');
  } */

  constructor(props) {
    super(props);
    this.state = {
      isButtonLoading: false,
      // array: [],
      // startDate: momentUTCToLocalDate(moment(props.selectedReport.params.startDate)).toDate(),
      // endDate: momentUTCToLocalDate(moment(props.selectedReport.params.endDate)).toDate(),
      // yearPeriod: props.selectedReport.populated && props.selectedReport.populated.generalLedger
      //   ? props.selectedReport.populated.generalLedger.params.yearPeriod : null,
      editDialogVisible: false,
      deleteDialogVisible: false,
      modals: {
        refresh: false,
      },
    };
    this.refreshTable = this.refreshTable.bind(this);
    this.getTable = this.getTable.bind(this);
    this.selectEvent = this.selectEvent.bind(this);
    this.apChanged = this.apChanged.bind(this);
    // this.handleChangeStart = this.handleChangeStart.bind(this);
    // this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.showEditDialog = this.showEditDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.showDeleteDialog = this.showDeleteDialog.bind(this);
    this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this);
    this.handleDeleteDialogSubmit = this.handleDeleteDialogSubmit.bind(this);
    this.saveAccountReceivable = this.saveAccountReceivable.bind(this);

    // this.selectPayableReport = this.selectPayableReport.bind(this);
  }

  componentDidMount() {
    this.getTable();
  }
  /* componentDidUpdate(prevProps) {
    this.shouldUpdateDates(prevProps);
    this.shouldUpdateYear(prevProps);
  } */

  getTable = () => {
    const { selectedReport, dispatch } = this.props;
    dispatch(getAccountReceivables(selectedReport.id));
  }
  refreshTable = (isForce) => {
    const { selectedReport, dispatch } = this.props;
    dispatch(fillAccountReceivables(selectedReport.id, isForce));
    this.setState(state => ({ modals: { ...state.modals, refresh: true } }));
  }
  /*
  getTable = () => {
    const { addNotice, selectedReport } = this.props;
    // const api = new ApiClient();
    axios({
      method: 'get',
      url: `/api/get-receivables?reportId=${selectedReport.id}`,
    }).then((response) => {
      if (response.data) {
        this.setState({ array: response.data.records });
      } else {
        addNotice({
          message: 'There was an error fetching records, Check out console to see more information.',
          type: 'error',
        });
        this.setState({ isButtonLoading: false });
      }
    }).catch((error) => {
      console.error(error);
      addNotice({
        message: 'Unnable To Refresh Table',
        type: 'error',
      });
    });
  }
  */
  /*  generateTable = array => [...array].map((item) => {
    const newItem = {
      record: item,
      id: item.id,
      advertiserName: item.populated && item.populated.advertiser ? item.populated.advertiser.params.companyName : '',
      amountReceived: parseFloat(item.params.amountReceived).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    };
    return newItem;
  });
  */
  selectEvent = () => {

  }
  /*
  refreshTable = (isForce) => {
    const { addNotice, selectedReport } = this.props;
    this.setState({ isButtonLoading: true });
    // const api = new ApiClient();
    axios({
      method: 'post',
      url: '/api/portal/finance/fill-receivables',
      data: { reportId: selectedReport.id, isForce },
    }).then((response) => {
      if (response.data.success === true) {
        addNotice({
          message: 'New items were added.',
          type: 'success',
        });
        this.setState({ isButtonLoading: false });
        this.getTable();
      } else {
        addNotice({
          message: 'An error occurred while fetching records. Check out console to see more information.',
          type: 'error',
        });
        this.setState({ isButtonLoading: false });
      }
    }).catch((error) => {
      console.error(error);
      addNotice({
        message: 'Unnable To Refresh Table',
        type: 'error',
      });
    });
  }
  */
  /* saveAccountReceivable() {
    const { selectedReport } = this.props;
    const { startDate, endDate } = this.state;
    const api = new ApiClient();
    const data = new FormData();
    data.append('startDate', momentLocaleToServerUTC(moment(startDate)).toISOString());
    data.append('endDate', momentLocaleToServerUTC(moment(endDate)).toISOString());
    api.recordAction({
      resourceId: resourceIdName,
      recordId: selectedReport.id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      this.refreshTable(true);
    });
  } */
  /* saveAccountReceivable({ startDate, endDate }) {
    const { selectedReport, dispatch } = this.props;
    dispatch(editAccountReceivableRecord(selectedReport.id, {
      startDate: momentLocaleToServerUTC(moment(startDate)).toISOString(),
      endDate: momentLocaleToServerUTC(moment(endDate)).toISOString(),
    }, () => {
      dispatch(addNotice({
        message: 'New dates was saved successfully. Updating the Table.',
        type: 'success',
      }));
      this.refreshTable(true);
    }));
  } */
  saveAccountReceivable({ startDate, endDate }) {
    const { selectedReport, dispatch } = this.props;
    dispatch(editAccountReceivableRecord(selectedReport.id, {
      startDate: momentLocaleToServerUTC(moment(startDate)).toISOString(),
      endDate: momentLocaleToServerUTC(moment(endDate)).toISOString(),
    }, () => {
      dispatch(addNotice({
        message: 'New dates was saved successfully. Updating the Table.',
        type: 'success',
      }));
      this.refreshTable(true);
    }));
  }
  apChanged({
    startDate, endDate, month, selectedGeneralLedger, name, description,
  }, closeModal, context) {
    const { selectedReport, dispatch } = this.props;
    dispatch(editAccountReceivableRecord(selectedReport.id, {
      startDate: momentLocaleToServerUTC(moment(startDate)).toISOString(),
      endDate: momentLocaleToServerUTC(moment(endDate)).toISOString(),
      monthPeriod: month,
      generalLedger: selectedGeneralLedger.value,
      name,
      description,
    }, () => {
      closeModal.bind(context)();
      dispatch(addNotice({
        message: 'Data has been saved successfully. Updating the Table.',
        type: 'success',
      }));
      this.refreshTable(true);
    }));
  }
  /* apChanged({
    startDate, endDate, month, selectedGeneralLedger, name, description,
  }) {
    const { selectedReport, addNotice, refreshBlock } = this.props;
    const api = new ApiClient();
    const data = new FormData();
    data.append('startDate', momentLocaleToServerUTC(moment(startDate)).toISOString());
    data.append('endDate', momentLocaleToServerUTC(moment(endDate)).toISOString());
    data.append('monthPeriod', month);
    data.append('generalLedger', selectedGeneralLedger.value);
    data.append('name', name);
    data.append('description', description);
    api.recordAction({
      resourceId: resourceIdName,
      recordId: selectedReport.id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((e) => {
      if (e.data.redirectUrl) {
        this.closeDialog();
        if (refreshBlock) {
          refreshBlock();
        }
        this.refreshTable(true);
        addNotice({
          message: 'Data has been saved successfully. Now updating the Table...',
          type: 'success',
        });
      }
    });
  }
*/
  /* shouldUpdateDates(prevProps) {
    const start = this.props.selectedReport.params.startDate;
    const end = this.props.selectedReport.params.endDate;
    if (start !== prevProps.selectedReport.params.startDate || end !== prevProps.selectedReport.params.endDate) {
      this.setState({
        startDate: momentUTCToLocalDate(moment(start)).toDate(),
        endDate: momentUTCToLocalDate(moment(end)).toDate(),
      });
    }
  } */

  /* shouldUpdateYear(prevProps) {
    if (this.props.selectedReport.params.generalLedger !== prevProps.selectedReport.params.generalLedger
      && this.props.selectedReport.populated && this.props.selectedReport.populated.generalLedger) {
      this.setState({
        yearPeriod: this.props.selectedReport.populated.generalLedger.params.yearPeriod,
      });
    }
  } */

  showEditDialog() {
    this.setState({ editDialogVisible: true });
  }
  closeDialog() {
    this.setState({ editDialogVisible: false });
  }

  showDeleteDialog() {
    this.setState({ deleteDialogVisible: true });
  }

  handleDeleteDialogClose() {
    this.setState({ deleteDialogVisible: false });
  }

  handleDeleteDialogSubmit() {
    const { selectedReport, dispatch } = this.props;
    axios({
      method: 'post',
      url: '/api/portal/finance/delete-receivable',
      data: {
        reportId: selectedReport.id,
      },
    }).then((response) => {
      if (response.data.success) {
        this.setState({ deleteDialogVisible: false });
        dispatch(addNotice({
          message: 'Selected Report was deleted successfully',
          type: 'success',
        }));
        setTimeout(() => {
          this.props.history.push('/finance/account-receivable-report/');
          window.location.reload();
        }, 3000);
      } else {
        dispatch(addNotice({
          message: response.data.error,
          type: 'error',
        }));
      }
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
      addNotice({
        message: error.message,
        type: 'error',
      });
    });
  }
  downloadCSV = (type, name) => {
    // console.log(type, name);
    const data = this.props[type] && this.props[type][name] ? this.props[type][name] : null;
    if (name === 'errors') {
      return csvHelper.downloadArrayCSV(data, 'message', `${type}-${name}.csv`);
    }
    return data ? csvHelper.downloadObjectCSV(data, `${type}-${name}.csv`) : false;
  }
  closeModal = () => {
    const { loading } = this.props;
    if (!loading.fillPayables) {
      this.setState(state => ({ modals: { ...state.modals, refresh: false } }));
      // this.setState({ isModalShow: false });
    }
  }

  // handleChangeStart = startDate => this.setState({ startDate }, () => this.saveAccountReceivable());
  // handleChangeEnd = endDate => this.setState({ endDate }, () => this.saveAccountReceivable());

  render() {
    // console.log(this.props);
    const {
      isButtonLoading,
      // startDate,
      // endDate,
      // yearPeriod,
      editDialogVisible,
      deleteDialogVisible,
      modals,
    } = this.state;
    const {
      selectedReport,
      loading,
      receivables,
      version,
      receivableLog,
    } = this.props;
    const selectedReportItem = selectedReport.params;
    // const minDate = this.constructor.getMinDate(selectedReport.params.monthPeriod, yearPeriod);
    // const maxDate = this.constructor.getMaxDate(selectedReport.params.monthPeriod, yearPeriod);
    const options = [...Months].map(item => ({ ...item }));
    const selectedMonthGeneral = options ? options.find(e => selectedReportItem && e.value.toString() === selectedReportItem.monthPeriod.toString()) : '';
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <Row>
              <Col md="11" xs="11">
                <h5 className="bold-text">{selectedReportItem.name} - {selectedMonthGeneral ? selectedMonthGeneral.label : ''} ({selectedReport.populated.generalLedger.params.yearPeriod})</h5>
                <h5 className="subhead">{selectedReportItem.description}</h5>
              </Col>
              <Col md="1" xs="1" className="text-right">
                <IconButton
                  onClick={this.showEditDialog}
                  className="material-table__toolbar-button"
                  size="small"
                >
                  <EditIcon size="16" />
                </IconButton>
                <IconButton
                  onClick={this.showDeleteDialog}
                  className="material-table__toolbar-button"
                  size="small"
                >
                  <DeleteIcon size="16" />
                </IconButton>
              </Col>
            </Row>
          </div>
          <Row className="mb-3 finance-container">
            <Col xl="4" lg="3">
              <AccountFinanceModal
                model={selectedReport}
                saveModelFunc={this.apChanged}
                modalTitle="Accounts Receivable Edit Form"
                generalLedger={selectedReport.populated.generalLedger}
                modal={editDialogVisible}
                closeDialog={this.closeDialog}
              />
            </Col>
            <Col xl="4" lg="6" className="align-items-stretch mb-3">
              <AccountFinanceDates
                model={selectedReport}
                loading={{ editingFields: [] }}
                saveModelFunc={this.saveAccountReceivable}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Button
                color="primary"
                className="float-right btn-sm"
                onClick={() => this.refreshTable(false)}
                disabled={!!isButtonLoading}
              >
                <RefreshIcon size={16} />
                Refresh
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              {loading.receivables && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
              {!loading.receivables && receivables.length > 0 && (
                <MatTableForRedux
                  dataArray={receivables}
                  selectedEvent={this.selectEvent}
                  csvDownload={{
                    fileName: 'AccountsReceivableReport.csv',
                    csvArrayColumns: downloadCsv,
                  }}
                  updateRecordFunc={this.updateRecord}
                  selectable
                  columns={columnTransform}
                  version={version}
                />
              )}
            </Col>
          </Row>
          <Modal isOpen={deleteDialogVisible} toggle={this.handleDeleteDialogClose} className="delete-modal">
            <ModalHeader toggle={this.handleDeleteDialogClose} />
            <ModalBody>
              Are you sure you want to delete the selected report?
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn-sm"
                color="danger"
                onClick={this.handleDeleteDialogSubmit}
              >
                Delete
              </Button>{' '}
              <Button
                className="btn-sm"
                color="secondary"
                onClick={this.handleDeleteDialogClose}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <ReportModal
            type="receivableLog"
            typeTitle="Companies"
            title="Fill AR Reports"
            modal={modals.refresh}
            closeDialog={this.closeModal}
            log={receivableLog}
            loading={loading.fillReceivables}
            downloadCSV={this.downloadCSV}
          />
        </CardBody>
      </Card>
    );
  }
}

/* ARReportContainer.propTypes = {
  addNotice: PropTypes.func.isRequired,
  refreshBlock: PropTypes.func.isRequired,
  selectedReport: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}; */
ARReportContainer.propTypes = {
  loading: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedReport: PropTypes.objectOf(PropTypes.any).isRequired,
  receivables: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  dispatch: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
  receivableLog: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
const mapStateToProps = state => ({
  loading: state.accountReceivable.loading,
  receivables: state.accountReceivable.receivables,
  receivableLog: state.accountReceivable.logs.receivables,
  version: state.accountReceivable.lastFetched.toString(),
});
export default connect(mapStateToProps, null)(ARReportContainer);
// export default withNotice(withRouter(ARReportContainer));
