/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import IntervalDatePickerField from '../../../Influencer/Research/Prospects/components/custom/IntervalDatePickerField';

const lastActivityDateLabels = [
  { value: '=', label: '=' },
  { value: '>', label: '>' },
  { value: '>=', label: '>=' },
  { value: '<', label: '<' },
  { value: '<=', label: '<=' },
  { value: 'Between', label: 'Between' },
];
const customStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#e9ecef' : 'white',
    height: '44px',
  }),
};
const ContentReleaseDate = ({
  setReleaseDateFrom, setReleaseDateTo, setSingleDate, lastContentReleaseDateType, setLastContentReleaseDateType, releaseDateFrom, releaseDateTo, singleContentReleaseDate,
}) => {
  const [showModal, setShowModal] = useState(!!(releaseDateFrom || releaseDateTo || false));

  const handleCRCheckBox = ({ target }) => {
    setShowModal(!showModal);
    if (!target.checked) {
      setSingleDate(null);
      setReleaseDateTo(null);
      setReleaseDateFrom(null);
    }
  };
  return (
    <div className="mt-3">
      <Label className="bold-text" style={{ marginBottom: '8px' }}>
        Content Release Date
      </Label>
      <Row>
        <Col sm={2}>
          <FormGroup
            style={{ paddingTop: '13px' }}
          >
            <CheckBoxField
              name="ContentReleaseDate"
              value={showModal}
              onChange={e => e.target && handleCRCheckBox(e)}
            />
          </FormGroup>
        </Col>
        <Col sm={4} style={{ paddingLeft: '0px' }} >
          <Select
            value={lastContentReleaseDateType}
            onChange={(option) => {
              if ((lastContentReleaseDateType !== 'Between' && option.value === 'Between') || (lastContentReleaseDateType === 'Between' && option.value !== 'Between')) {
                setReleaseDateFrom(null);
                setReleaseDateTo(null);
                setSingleDate(null);
              }
              setLastContentReleaseDateType(option);
            }}
            options={lastActivityDateLabels}
            isDisabled={!showModal}
            styles={customStyles}
          />
        </Col>
        <Col sm={6}>
          {lastContentReleaseDateType.value === 'Between' ? (
            <div style={{ marginRight: '-30px' }} className="pb-2">
              <IntervalDatePickerField
                onChange={({ start, end }) => {
                  setReleaseDateFrom(start);
                  end?.setHours(23, 59, 59);
                  setReleaseDateTo(end);
                }}
                startDate={releaseDateFrom}
                endDate={releaseDateTo}
                maxDate={new Date()}
                placeholderFrom="Start Date"
                placeholderTo="End Date"
                verticalFields
                disabled={!showModal}
              />
            </div>
          ) : (
            <div className="date-picker datepicker-in-prospects">
              <DatePicker
                selected={singleContentReleaseDate}
                onChange={(date) => {
                  if (lastContentReleaseDateType.value === '<=' || lastContentReleaseDateType.value === '>') {
                    date?.setHours(23, 59, 59);
                  }
                  setSingleDate(date);
                }}
                dateFormat="MMM d, yyyy"
                maxDate={new Date()}
                placeholderText="Select Date"
                dropDownMode="select"
                isClearable
                wrapperClassName="date-picker--interval w-100"
                className="form-control form-control-sm datepicker-in-prospects w-100"
                disabled={!showModal}
              />
              {singleContentReleaseDate === null && (
                <svg
                  className="mdi-icon"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  style={{
                    position: 'absolute',
                    top: '13px',
                    right: '22px',
                    fill: '#999',
                    pointerEvents: 'none',
                  }}
                >
                  <path
                    d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                  />
                </svg>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ContentReleaseDate;
