/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import MediaContentListContainer from './components/MediaContentListContainer';


const ResourceAction = (props) => {
  const {
    resources,
  } = props;
  const resource = resources.find(r => r.id === 'MediaContent');
  if (!resource) {
    return (<NoResourceError resourceId="MediaContent" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="MediaContent" actionName="list" />);
  }
  return (
    <MediaContentListContainer
      resource={resource}
      action={action}
    />
  );
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
