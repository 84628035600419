import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  FormGroup,
} from 'reactstrap';
import { axios } from 'ApiClient';
import { CKEditor } from 'ckeditor4-react';
import {unflatten} from "flat";
import Hook from '@/shared/hooks';

const modalId = 'NotesModal';

const NotesModal = () => {
  const [loading, setLoading] = useState(false);
  const [notesState, setNotes] = useState('');
  const allowEdit = useSelector((state) => state.taxFormEditRoleCheck.isAllowedToEdit);
  const { data: dataModal, showModal: isOpen, close: closeModal } = Hook.useModalHook(modalId);

  console.log(dataModal, 'DATA MODAL');

  const {
    record, updateRecordFunction, notes, title, name, approvalStatus, setDisabled,
  } = dataModal;


  useEffect(() => {
    if (isOpen && notes) {
      setNotes(notes);
    } else setNotes('');
  }, [record, notes]);
  if (!dataModal || !dataModal.record) return false;

  const handleInputChange = (e) => {
    const { editor } = e;
    setNotes(editor.getData());
  };

  const close = () => {
    closeModal();
    if (setDisabled) setDisabled(false);
  };

  const submit = async () => {
    setLoading(true);

    const { id } = record;
    try {
      if (approvalStatus && setDisabled) {
        const data = await axios.post('/api/portal/finance/post-update-property-on-tax-form', {
          ids: [id],
          values: {
            approvalStatus,
          },
        });
        const record = unflatten(data?.data?.recordPopulated);
        updateRecordFunction(id, { approvalStatus: record.params.approvalStatus });
        setDisabled(false);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      const response = await axios.post('/api/portal/finance/post-update-property-on-tax-form', {
        ids: [id],
        values: {
          [name]: notesState,
        },
      });
      setLoading(false);
      const _record = unflatten(response.data?.recordPopulated);
      if (_record && updateRecordFunction) {
        // console.log(_record.params[name]);
        updateRecordFunction(id, { [name]: _record.params[name] });
      }
      close();
    } catch (e) {
      console.log(e);
    }
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => { close(); }}
      style={{ minWidth: '670px' }}
    >
      <ModalHeader
        toggle={() => {
          setNotes('');
          close();
        }}
        tag="h4"
        className="modal-title-statistics pl-0"
      >
        {title || ''}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {record ? (
              <FormGroup>
                { allowEdit ?
                    <CKEditor
                      id="bodyEditor"
                      name="itemText"
                      activeClass="form-control"
                      initData={notesState}
                      onChange={(e) => { handleInputChange(e); }}
                      config={{versionCheck: false}}
                    />
                  :
                  <span dangerouslySetInnerHTML={{ __html: notesState }} />
                  }
              </FormGroup>
            ) : null}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        { allowEdit && (
          <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading} style={{ marginRight: '10px' }}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
            <span>Save</span>
          </Button>)
        }
        <Button className="modal_cancel btn-sm" onClick={() => { close(); }}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};
export default NotesModal;
