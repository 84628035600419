import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
// import moment from 'moment';
import formater from '../../../../../shared/helpers/WVFormatter';
import CopyToClipboard from '../../../../../shared/components/CopyToClipboard';


const DealInfoModal = ({ isOpen, toggleModal, deal }) => {
  const [dealData, setDealDataItems] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isOpen === true) {
      setLoading(true);
      axios.get(`/api/portal/finance/get-deal-data?dealId=${deal.id}`).then((res) => {
        if (res.data) {
          setDealDataItems(res.data.dealInfo);
        }
        setLoading(false);
      }).catch((error) => {
        console.error(error);
      });
    }
    return () => {
      // setPayableItems([]);
    };
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="delete-modal ltr-support" size="lg" style={{ maxWidth: '800px' }}>
      <ModalHeader>{deal.params.dealname } details</ModalHeader>
      <ModalBody>
        {!loading ? (
          <div>
            <b>CPA Percentage</b>: {deal.params.dealTerms?.cpa_percentage || 0}%<br />
            <b>Per Conversion Amount</b>: {formater.formatCurrency(Number(deal.params.dealTerms?.per_conversion_amount || 0))}<br />
            <b>CPA Status</b>: {deal.params.dealTerms?.cpa_status || '-'}<br />
            <b>Guaranteed Payment Contract Amount</b>: {formater.formatCurrency(Number(deal.params.dealTerms?.guaranteed_payment_contract_amount || 0))}<br />
            <b>Guaranteed Payment Recoverable</b>: {deal.params.dealTerms?.guaranteed_payment_recoverable || '-'}<br />
            <b>Coupon Code</b>: <CopyToClipboard value={deal.params.dealTerms?.coupon_code || ''}>{deal.params.dealTerms?.coupon_code || '-'}</CopyToClipboard><br />
            <b>Referral Url</b>: <CopyToClipboard value={deal.params.dealTerms?.referral_url || ''}>{deal.params.dealTerms?.referral_url || '-'}</CopyToClipboard><br />
            <b>Type Of Deal</b>: {deal.params.dealTerms?.type_of_deal || '-'}<br />
            {['Hybrid', 'Upfront Only', 'Upfront CPM', 'Bonus'].includes(deal.params.dealTerms?.type_of_deal) ? (
              <div>
                <hr />
                {dealData.bill ? (
                  <div>
                    <b>Bill Invoice No</b>: {dealData.bill && (
                      // eslint-disable-next-line max-len
                      <a href={`/resources/Bill/actions/list?filters.fullSearchName=${dealData.bill.invoiceNumber}&page=1&filters.paymentStatus=paidinfull&filters.paymentStatus=partiallypaid&filters.paymentStatus=unpaid&filters.status=unassigned&filters.status=approve&filters.status=decline&filters.status=ignore&filters.dealObject.dealstage=Published&filters.dealObject.dealstage=Deal+Paid&filters.dealObject.dealstage=Other&filters.typeOfDeal=Hybrid&filters.typeOfDeal=Upfront+Only&filters.typeOfDeal=Upfront+CPM&filters.typeOfDeal=Media+License&filters.typeOfDeal=Bonus`} target="_blank" rel="noopener noreferrer">
                        {dealData.bill.invoiceNumber}
                      </a>
                      )}<br />
                    <b>Bill Amount</b>: {formater.formatCurrency(Number(dealData.bill && dealData.bill.amount))}<br />
                    <b>Paid Bill Amount</b>: {formater.formatCurrency(Number(dealData.totalPaidBill || 0))}<br />
                  </div>) : null}
                {dealData.invoice ? (
                  <div>
                    <b>Invoice Invoice No.</b>: {dealData.invoice && (
                      <a href={`/resources/Invoice/actions/list?filters.invoiceNumber=${dealData.invoice.invoiceNumber}&page=1`} target="_blank" rel="noopener noreferrer">
                        {dealData.invoice.invoiceNumber}
                      </a>
                    )}<br />
                    <b>Invoice Amount</b>: {formater.formatCurrency(Number(dealData.invoice && dealData.invoice.amount))}<br />
                    <b>Total Paid with Credits</b>: {formater.formatCurrency(Number(dealData.totalPaidAmountHybrid))}<br />
                  </div>) : null}
              </div>) : null}
          </div>) : (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};
DealInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  deal: PropTypes.objectOf(PropTypes.any).isRequired,
};
DealInfoModal.defaultProps = {
  isOpen: false,
};
export default DealInfoModal;
