/* eslint react/prop-types: 0 */
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  CardBody, CardTitle, Card, Spinner,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { axios } from 'ApiClient';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import WVFormatter from '../../../../../shared/helpers/WVFormatter';
import mapMediaTypeIcons from './custom/mapMediaTypeIcons';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import ScorecardContextMenu from './custom/ScorecardContextMenu';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  manualPageSize: [5, 10, 30, 40],
  sortBy: 'createdAt',
  direction: 'desc',
};

const MediContentListContainer = () => {
  // const [version, setVersion] = useState((new Date()).toString());
  // const [totals, setTotals] = useState({ quantity: '0', saleAmount: '$0', payout: '$0' });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [averageScore, setAverageScore] = useState(0);
  const [mediaContentData, setMediaContentData] = useState({ creator: '', name: '', type: '' });

  const { mediaContentId } = useParams();
  const fetchData = async () => {
    const resp = await axios.get(`/api/portal/scorecard/answers/get-scorecard-answers?id=${mediaContentId}`);
    if (resp.data.success) {
      // console.log(resp.data.data);
      let total = 0;
      let itemLength = resp.data.data.length;
      resp.data.data.forEach((item) => {
        if (typeof item.totalScore !== 'undefined' && item.totalScore !== null) {
          total += item.totalScore;
        } else {
          itemLength -= 1;
        }
      });
      if (total > 0) {
        total = Math.round(total / itemLength);
      }
      setAverageScore(total);
      setData(resp.data.data);
      const creator = `${resp.data.data[0].data.mediaContent?.influencer.first_name} ${resp.data.data[0].data.mediaContent?.influencer.last_name}`.trim();
      setMediaContentData({
        creator,
        name: resp.data.data[0].data.mediaContent?.name,
        type: resp.data.data[0].data.mediaContent?.type,
        mediaUrl: resp.data.data[0].data.mediaContent?.mediaUrl,
      });
    }
  };

  useEffect(async () => {
    await fetchData().then(() => {
      setLoading(false);
    });
  }, []);

  const mapType = mapMediaTypeIcons();
  const customColumns = useMemo(() => [
    {
      Header: 'Scored By',
      accessor: p => (p.scoreBy),
      id: 'scoredBy',
      className: 'align-middle',
      disableSortBy: true,
    },
    {
      Header: 'Score Date',
      accessor: p => (WVFormatter.formatDateForCreatorsPaymentsTables(p.data?.scoreDate)),
      id: 'submittedBy',
      disableSortBy: true,
      className: 'align-middle',
    },
    {
      Header: 'Compliant?',
      accessor: p => (p.data?.isFTCCompliant),
      id: 'status',
      minWidth: 150,
      maxWidth: 150,
      Cell: (value) => {
        let isFTCCompliant = 'N/A';
        if (value.row.original.data?.isFTCCompliant) isFTCCompliant = 'Yes';
        if (value.row.original.data?.isFTCCompliant === false) isFTCCompliant = 'No';
        return (
          <span>
            {isFTCCompliant}
          </span>
        );
      },
      className: 'align-middle text-center',
      disableSortBy: true,
    },
    {
      Header: 'Score',
      accessor: p => (p.totalScore),
      id: 'score',
      Cell: value => <span>{value.row.original.data?.totalScore ? value.row.original.data?.totalScore : 0}</span>,
      className: 'align-middle text-center',
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'contextMenu',
      Cell: value => <ScorecardContextMenu value={value.row.original.data} />,
      className: 'align-middle text-right',
      disableSortBy: true,
      width: 50,
      minWidth: 50,
    },
  ], []);

  const links = [
    { title: 'Home', path: '/' },
    { title: 'Compliance', path: false },
    { title: 'Media Content', path: false },
    { title: 'Scorecards', path: false },
  ];

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs links={links} isBackButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <h3>Scorecards</h3>
                <hr className="mt-3 mb-4" />
              </CardTitle>
              <Row style={{ marginBottom: '12px' }}>
                <Col xs="4">
                  <h4>{(data.length > 0) ? mediaContentData.creator : ''}</h4>
                  <span>
                    {(data.length > 0) ? mediaContentData.name : ''}&nbsp;&nbsp;&nbsp;
                    <a href={mediaContentData.mediaUrl} target="_blank" rel="noreferrer">
                      <img style={{ width: '30px' }} src={`/images/mediacontent/${mapType[mediaContentData.type]}.png`} alt={mediaContentData.type} title={mediaContentData.type} />
                    </a>
                  </span>
                </Col>
                <Col xs="4">
                  <Row
                    style={{
                      padding: '15px 15px',
                      border: '1px solid #cccccc',
                      borderRadius: '5px',
                    }}
                  >
                    <Col xs="12" className="text-center">
                      <span style={{ textAlign: 'center', color: 'black' }} >
                        <span
                          style={{
                            fontSize: '20px',
                            fontWeight: '700',
                            marginBottom: '12px',
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          {averageScore}
                        </span>
                        <br style={{ lineHeight: '50%' }} />
                        <span style={{ width: '100%', fontSize: '16px' }}>Average Score</span>
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col xs="4" />
              </Row>
              <Row>
                <Col md={8} />
                <Col md={4}>
                  <div className="float-right">
                    <a href={`/resources/MediaContentResource/download-scorecards-csv?filters.mediaContentId=${mediaContentId}`} className="btn btn-primary btn-sm">Download</a>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="media-content-scorecard-container">
                  {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
                  {!loading && data.length > 0 && (
                    <ReactTableBase
                      columns={customColumns}
                      data={data}
                      tableConfig={tableConfig}
                    />
                  )}
                  {!loading && data.length < 1 && (
                    <Alert color="warning">
                      <p className="py-2">
                        The Media Content currently has no Scorecards.
                      </p>
                    </Alert>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  
  resources: state.resources,
  // match: PropTypes.objectOf(PropTypes.any).isRequired,
});

export default connect(mapStateToProps)(MediContentListContainer);
