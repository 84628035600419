import React from 'react';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-react/InfoOutlineIcon';

const CustomCheckbox = withStyles({
  root: {
    color: '#70bbfd',
    '&$checked': {
      color: '#70bbfd',
    },
  },
  checked: {},
})(React.forwardRef((props, ref) => <Checkbox color="default" size="small" {...props} ref={ref} />));
const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;
  React.useEffect(() => {
    if (resolvedRef.current) resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  // console.log(rest);
  return (
    <div style={{ paddingTop: '4px' }}>
      {rest.disabled && rest.disableMessage ? (
        <Tooltip title={rest.disableMessage}>
          <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall" >
            <EditIcon />
          </button>
        </Tooltip>
      ) : (<CustomCheckbox
        name={Math.random().toString(36).slice(2)}
        label=""
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        checked={!rest.disabled && rest.checked}
        onClick={e => !rest.disabled && e.target && rest.onChange(e)}
        disabled={rest.disabled}
        ref={resolvedRef}
      />)}
    </div>
  );
});
IndeterminateCheckbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  indeterminate: PropTypes.any.isRequired,
  // eslint-disable-next-line react/require-default-props,react/forbid-prop-types
  disableMessage: PropTypes.any,
};
IndeterminateCheckbox.defaultProps = {
  disableMessage: null,
};
export default IndeterminateCheckbox;
