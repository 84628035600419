import React, { useState } from 'react';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import TableCellContextMenu from '../../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';
import withNotice from '../../../../../App/store/with-notice';
import Hook from '../../../../../../shared/hooks';

const ContextMenuInList = ({
  record,
  updateRecordFunction,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteModal] = Hook.useShowModalHook('DeleteModalDefault');

  const viewAccount = () => {
    const url = `https://dashboard.stripe.com/${window.location.hostname === 'portal.influencelogic.com' ? '' : 'test/'}connect/accounts/${record.id}`;
    const win = window.open(url, '_blank');
    win.focus();
  };

  const deleteAccount = () => {
    setLoading(true);

    axios.post(
      '/api/portal/finance/post-delete-stripe-account',
      { accountId: record.id },
    ).then((response) => {
      if (response.data.success) {
        addNotice({
          message: 'Stripe account successfully deleted',
          type: 'info',
        });
        setLoading(false);
        updateRecordFunction({ id: record.id });
      } else {
        if (response.data.error) {
          addNotice({
            message: response.data.error,
            type: 'error',
          });
          setLoading(false);
        }
        addNotice({
          message: 'Could not delete Stripe account',
          type: 'error',
        });
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }

  const contextMenuOptions = [
    { label: 'View Account', handler: viewAccount },
    { type: 'divider', label: 'divider1' },
    { label: `${loading ? 'Deleting...' : 'Delete Account'}`,
      handler: () => showDeleteModal({
        title: 'Delete Stripe Account',
        message: (<>Are you sure you want to delete this Stripe account?</>),
        deleteRecord: deleteAccount,
      }),
    },
  ];

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
}

export default withNotice(ContextMenuInList);
