import React, { useEffect, useState, useCallback } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { withRouter } from 'react-router-dom';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';
// import withNotice from '../../../../App/store/with-notice';
import filterProperties from './custom/ActionProperties';
import Hook from '../../../../../shared/hooks';
import { fetchDictionary } from '@/shared/helpers/WVRequest';
// import { getConstant } from '../../../../../shared/helpers/WVConstants';

const Creator = ({
  history,
  resource,
}) => {
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [offersSuggestions, setOffersSuggestions] = useState([]);
  const [affiliateManagersSuggestions, setAffiliateManagersSuggestions] = useState([]);
  const [ownersSuggestions, setOwnersSuggestions] = useState([]);
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [searchUrl, setPrefill, loadPage, , useLoadOrDebounce] = Hook.usePrefillHook({
    resourceId: resource.id,
    initialState: {
      sortBy: 'createdAt',
      direction: 'desc',
      page: 1,
      perPage: 10,
    },
  });
  // const [hasSearchUrlChangedFromNull, setHasSearchUrlChangedFromNull] = useState(false);


  const fetchTagSuggestions = () => {
    axios.getSafety('/api/portal/tags/get-creator-tags?id=undefined').then((data) => {
      if (data.success === true) {
        setTagsSuggestions(data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchOffersSuggestions = async () => {
    axios.getSafety('/api/portal/finance/get-offers').then((data) => {
      const brands = {};
      data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      setOffersSuggestions(data.offers.map(o => ({
        id: o._id,
        name: o.name,
        // value: o._id,
        // label: o.name,
      })));
    })
  };

  const fetchAffiliateManagersSuggestions = async () => {
    axios.getSafety('/api/portal/employees/options-key-tune-id').then((data) => {
      if (data.success === true) {
        setAffiliateManagersSuggestions(data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchOwnersSuggestions = () => {
    axios.getSafety('/api/portal/prospects/get-prospect-owners').then((data) => {
      if (data.success === true) {
        setOwnersSuggestions(data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };
  const fetchDictionaryData = () => {
    fetchDictionary('PROSPECT_').then((data) => {
      const { success, records } = data;
      if (success === true) {
        const _categories = records.PROSPECT_CATEGORIES ? JSON.parse(records.PROSPECT_CATEGORIES) : [];
        setCategories(_categories);
        const _categorySuggestions = _categories.map(row => ({ id: row, name: row }));
        _categorySuggestions.unshift({ id: 'null', name: '-- Not Assigned --' });
        setCategorySuggestions(_categorySuggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };
  useEffect(() => {
    fetchOffersSuggestions();
    fetchAffiliateManagersSuggestions();
    fetchOwnersSuggestions();
    fetchDictionaryData();
    fetchTagSuggestions();
  }, []);



  useEffect(() => {
    if (offersSuggestions?.length > 0
        && affiliateManagersSuggestions?.length > 0
        && ownersSuggestions?.length > 0
        && categorySuggestions?.length > 0
        && tagsSuggestions?.length > 0
    ) {
      console.log('Allow Start Load');
      setPrefill(filterProperties({
        offersSuggestions,
        affiliateManagersSuggestions,
        ownersSuggestions,
        categorySuggestions,
        tagsSuggestions,
      }));
    }
  }, [categorySuggestions, offersSuggestions, affiliateManagersSuggestions, ownersSuggestions, tagsSuggestions]);
  const setUrl = () => {
    loadPage();
  };
  const downloadCsv = useCallback(() => {
    window.open(`/resources/Influencer/download-csv?${searchUrl}`, '_blank').focus();
  }, [searchUrl]);
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 1000);

  useLoadOrDebounce({
    loadFunc: () => loadPage(), loadDebounceFunc: () => setUrlDebounced(), setSearchUrl: () => history.push(`${history.location.pathname}?${searchUrl}`)
  })
  /* 
  useEffect(() => {
    if (searchUrl === null) return false;
    history.push(`${history.location.pathname}?${searchUrl}`);
    if (!isPageChanged.filters || hasSearchUrlChangedFromNull === false) {
      setHasSearchUrlChangedFromNull(true);
      return loadPage();
    }
    return setUrlDebounced();
  }, [
    searchUrl,
  ]);
  */
  // console.log(searchUrl, 'SEARCH');
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Creators', path: null },
          { title: 'Dashboard', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Creators</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                downloadCsv={downloadCsv}
                categories={categories}
              />
              <Row style={{ marginTop: '-10px' }}>
                {searchUrl !== null ? <Table categories={categories} affiliateManagersSuggestions={affiliateManagersSuggestions}/> : null}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>  
  );
};
Creator.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withRouter(Creator);
