import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import {
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import CheckBoxField from '../CheckBox';
import IntervalDatePickerField from '../../../containers/admin/Influencer/Research/Prospects/components/custom/IntervalDatePickerField';

const Period = ({
  name,
  label,
  enabled,
  setEnabled,
  operator,
  setOperator,
  value,
  setValue,
  to,
  setTo,
}) => {
  const dateFrom = value ? new Date(value) : null;
  const dateTo = to ? new Date(to) : null;

  useEffect(() => { if (!enabled) { setValue(null); setTo(null); } }, [enabled]);
  useEffect(() => { if (value) setEnabled(true); }, [value]);
  useEffect(() => { if (to) setEnabled(true); }, [to]);

  const options = [
    { value: '=', label: '=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'Between', label: 'Between' },
  ];

  const setDate = (val, update) => {
    update(val ? val.toISOString() : null);
  };

  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
      height: '44px',
    }),
  };

  return (
    <>
      <Row>
        <Col sm={1} />
        <Col sm={11} style={{ paddingLeft: '20px' }}>
          <Label className="bold-text">
            {label}
          </Label>
        </Col>
      </Row>
      <Row>
        <Col sm={1}>
          <FormGroup
            style={{ paddingTop: '13px', paddingLeft: '8px' }}
          >
            <CheckBoxField
              name={`${name}CheckBox`}
              value={enabled}
              onChange={e => e.target && setEnabled(e.target.checked)}
            />
          </FormGroup>
        </Col>
        <Col sm={5} style={{ paddingLeft: '20px' }}>
          <Select
            value={operator ? options.find(opt => opt.value === operator) : null}
            onChange={o => setOperator(o.value)}
            options={options}
            isDisabled={!enabled}
            styles={customStyles}
          />
        </Col>
        <Col sm={6}>
          {operator === 'Between' ? (
            <div style={{ marginRight: '-30px' }} className="pb-2">
              <IntervalDatePickerField
                onChange={({ start, end }) => {
                  setDate(start, setValue);
                  end?.setHours(23, 59, 59);
                  setDate(end, setTo);
                }}
                startDate={dateFrom}
                endDate={dateTo}
                maxDate={new Date()}
                placeholderFrom="Start"
                placeholderTo="End"
                verticalFields
                disabled={!enabled}
              />
            </div>
          ) : (
            <div className="date-picker datepicker-in-prospects">
              <DatePicker
                selected={dateFrom}
                maxDate={new Date()}
                onChange={val => setDate(val, setValue)}
                dateFormat="MMM d, yyyy"
                placeholderText="Select Date"
                dropDownMode="select"
                isClearable
                wrapperClassName="date-picker--interval w-100"
                className="form-control form-control-sm datepicker-in-prospects w-100"
                disabled={!enabled}
              />
              {value === null && (
                <svg
                  className="mdi-icon"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  style={{
                    position: 'absolute',
                    top: '13px',
                    right: '22px',
                    fill: '#999',
                    pointerEvents: 'none',
                  }}
                >
                  <path
                    d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                  />
                </svg>
              )}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

Period.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  setEnabled: PropTypes.func.isRequired,
  operator: PropTypes.string,
  setOperator: PropTypes.func.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  to: PropTypes.string,
  setTo: PropTypes.func.isRequired,
};

Period.defaultProps = {
  operator: null,
  value: null,
  to: null,
};

export default Period;

export const getStartDate = (value, operator) => {
  switch (operator) {
    case '<': case '<=': return '1970-01-01';
    case '>': return value ? moment(value).add(1, 'days').format('YYYY-MM-DD') : null;
    default: return value;
  }
};

export const getEndDate = (from, to, operator) => {
  switch (operator) {
    case '>': case '>=': return moment().format('YYYY-MM-DD');
    case '<': return from ? moment(from).subtract(1, 'days').format('YYYY-MM-DD') : null;
    case 'Between': return to;
    default: return from;
  }
};
