import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import HistoryIcon from 'mdi-react/HistoryIcon';
import StatusComponent from './custom/StatusComponent';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import UserPropertyType from './custom/index';
import StripeStatusComponent from './custom/StripeStatusComponent';
import DealNameField from './custom/DealNameField';
import PayoutAmount from './custom/PayoutAmount';
import RevenueAmount from './custom/RevenueAmount';
import ILPayoutAmount from './custom/ILPayoutAmount';
import Authorized from './custom/Authorized';
import formater from '../../../../../shared/helpers/WVFormatter';
import { getConstant } from '../../../../../shared/helpers/WVConstants';
import ContextMenu from './ContextMenu';



const Table = ({
  action,
  resource,
  version,
  // recordsRef,
  filters,
  setVersion,
}) => {
  const pipelines = getConstant('deal', 'pipelines', []);
  const allStages = {};
  pipelines?.forEach((p) => {
    const stages = Object.keys(p.stages).map((k) => {
      if (p.label.toLowerCase() === 'Main Pipeline'.toLowerCase()) allStages[k] = p.stages[k];
      return { value: k, label: p.stages[k] };
    });
    return {
      id: p.id,
      stages,
    };
  });
  return (
    <MatTableList
      action={action}
      resource={resource}
      date={new Date()}
      version={version}
      UserPropertyType={UserPropertyType}
      showFilters={false}
      filters={filters}
      showTitle={false}
      v={2}
      noActions
      isResizable={false}
      useFlex
      useCheckboxes
      useMultiPageCheckboxes
      checkboxHandler={() => {}}
      notFoundProps={{ title: 'No Payables found' }}
      useUnflatten
      // recordsRef={recordsRef}
      customColumns={useMemo(
        () => [
          {
            Header: 'Payment Method',
            minWidth: 80,
            maxWidth: 80,
            width: 80,
            accessor: record => record.params?.payment || '',
            id: 'paymentStatus',
            Cell: p => useMemo(() => (<StripeStatusComponent record={p.row.original} type="creator" />), [p.row.original]),
            disableSortBy: true,
            className: 'd-flex align-items-center',
          },
          {
            Header: 'Authorized?',
            accessor: (record) => {
              if (record?.populated?.deal?.params?.authorizedPayment) {
                return record?.populated?.deal?.params?.authorizedPayment?.summaryValidation;
              }
              return '';
            },
            className: 'd-flex align-items-center',
            Cell: p => useMemo(() => (<Authorized record={p.row.original} />), [p.row.original]),
            disableSortBy: true,
            width: 100,
          },
          {
            Header: () => (
              <div>
                Status
                <br />
                <span className="text-warning"><HistoryIcon /></span> - Offline Payout
                <br />
                <span className="text-success"><HistoryIcon className="text-success" /></span> - System Payout
              </div>),
            minWidth: 210,
            id: 'status',
            width: 210,
            accessor: record => record.params.status || '',
            Cell: p => useMemo(() => (<StatusComponent record={p.row.original} type="creator" updateRecordFunction={p.updateRecordFunction} />), [p.row.original]),
            className: 'd-flex align-items-center',
          },
          {
            Header: 'Creator Name',
            id: 'creatorName',
            accessor: record => `${record?.populated?.influencer?.params?.first_name?.toLowerCase() || ''} ${record?.populated?.influencer?.params?.first_name?.toLowerCase() || ''}`,
            Cell: p => useMemo(() => (
              <Tooltip title="Open Creator Profile">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/influencer/profile/${p.row.original?.populated?.influencer?.params._id}`}
                >
                  {p.row.original?.populated?.influencer?.params.first_name} {p.row.original?.populated?.influencer?.params.last_name}
                </a>
              </Tooltip>
            ), [p.row.original]),
            className: 'd-flex align-items-center',
          },
          {
            Header: () => (
              <div >
                Creator Email
              </div>),
            id: 'creatorEmail',
            accessor: record => record?.populated?.influencer?.params?.email?.toLowerCase() || '',
            Cell: p => useMemo(() => {
              let agentInfo = `${p.row.original.populated?.agent?.params?.first_name || ''} ${p.row.original.populated?.agent?.params?.last_name || ''} (${p.row.original.populated?.agent?.params?.email || ''})`;
              if (p.row.original.populated.agent?.params?.payment?.billCom.isDefault) {
                agentInfo = `BillCom Account: ${p.row.original.populated.agent?.params?.payment?.billCom.name || ''} (${p.row.original.populated.agent?.params?.payment.billCom.email || ''})`;
              }

              if (p.row.original.populated?.agent?.params.payment.stripe.isDefault) {
                agentInfo = `Stripe Account: ${p.row.original.populated.agent.params.payment.stripe.email || ''}`;
              }
              const additional = p.row.original.populated.agent ? (
                <Tooltip
                  title={`${p.row.original.populated.influencer?.params?.payment?.enablePayments === false ?
                    'The agent will receive payment for this creator.'
                    : ''} ${agentInfo}`}
                >
                  <a href={`/agent/profile/${p.row.original.populated.agent.id}`} target="_blank" rel="noreferrer"><img
                    style={{
                      position: 'absolute', right: '10px', top: '5px', maxWidth: '25px',
                    }}
                    src={`/images/creator/agent-${p.row.original.populated.influencer?.params?.payment?.enablePayments === false ? 'enabled' : 'disabled'}.png`}
                    alt={p.row.original.populated.influencer?.params?.payment?.enablePayments === false ? 'Enabled Agent' : 'Disabled Agent'}
                  />
                  </a>
                </Tooltip>
              ) : null;
              return (
                <div style={{ position: 'relative', textWrap: 'wrap', maxWidth: '100%'}}>
                  <div style={{ marginRight: '40px', textWrap: 'wrap'}}>{p.row.original.populated && p.row.original.populated.influencer ? p.row.original.populated.influencer.title : ''}</div>{additional}
                </div>);
            }, [p.row.original]),
            className: 'd-flex align-items-center',
            width: 250,
          },
          {
            Header: 'Offer',
            id: 'offer',
            accessor: record => (record?.populated?.offer?.title || ''),
            className: 'd-flex align-items-center',
          },
          {
            Header: 'Deal Name',
            id: 'deal',
            accessor: (record) => {
              if (record?.deal?.dealname) {
                return record.deal.dealname;
              } else if (record?.unassignedDeal?.dealname) {
                return record.unassignedDeal.dealname;
              }
              return '';
            },
            Cell: p => useMemo(() => (<DealNameField record={p.row.original} type="creator" updateRecordFunction={p.updateRecordFunction} />), [p.row.original]),
            minWidth: 200,
            width: 200,
            className: 'd-flex align-items-center',
          },
          {
            Header: 'Deal Type',
            id: 'typeOfDeal',
            className: 'd-flex align-items-center',
            width: 120,
            accessor: record => (record?.populated?.deal?.params?.dealTerms?.type_of_deal ? record.populated.deal.params.dealTerms.type_of_deal : ''),
            // Cell: p => useMemo(() => (<IlPayoutAmount record={p.row.original} type="agent" updateRecordFunction={updateRecord} />), [p.row.original]),
          },
          {
            Header: 'Deal Stage',
            id: 'dealstage',
            className: 'd-flex align-items-center',
            accessor: record => (record?.populated?.deal?.params?.dealstage ? allStages[record.populated.deal.params.dealstage] : ''),
            disableSortBy: true,
            // Cell: p => useMemo(() => (<IlPayoutAmount record={p.row.original} type="agent" updateRecordFunction={updateRecord} />), [p.row.original]),
          },
          {
            Header: 'Employee',
            id: 'employee',
            className: 'd-flex align-items-center',
            accessor: record => (record.populated.employee ? (`${record.populated.employee.params.firstName || ''} ${record.populated.employee.params.lastName || ''}`) : ''),
            // Cell: p => useMemo(() => (<IlPayoutAmount record={p.row.original} type="agent" updateRecordFunction={updateRecord} />), [p.row.original]),
          },
          {
            Header: 'Revenue',
            id: 'revenueAmount',
            minWidth: 170,
            width: 170,
            className: 'd-flex align-items-center',
            accessor: record => Number(record?.params?.revenueAmount),
            Cell: p => useMemo(() => (<RevenueAmount record={p.row.original} type="creator" updateRecordFunction={p.updateRecordFunction} />), [p.row.original]),
          },
          {
            Header: 'HO Payout',
            id: 'payoutAmount',
            minWidth: 170,
            width: 170,
            className: 'd-flex align-items-center',
            accessor: record => Number(record?.params?.payoutAmount),
            Cell: p => useMemo(() => (<PayoutAmount record={p.row.original} type="creator" updateRecordFunction={p.updateRecordFunction} />), [p.row.original]),
          },
          {
            Header: 'IL Payout Amount',
            id: 'ilPayoutAmount',
            minWidth: 200,
            width: 200,
            className: 'd-flex align-items-center',
            accessor: 'ilPayoutAmount',
            Cell: p => useMemo(() => (<ILPayoutAmount v={(new Date())} record={p.row.original} type="creator" updateRecordFunction={p.updateRecordFunction} />), [p.row.original]),
          },
          {
            Header: 'Profit',
            className: 'd-flex align-items-center',
            accessor: record => Number(record.params.revenueAmount || 0) - Number(record.params.ilPayoutAmount || 0),
            Cell: p => useMemo(() => formater.formatCurrency(p.value), [p.row.original]),
            disableSortBy: true,
          },
          {
            Header: '',
            id: 'actions',
            minWidth: 50,
            width: 50,
            accessor: record => record.id,
            Cell: p => <ContextMenu p={p} setVersion={setVersion} />,
            disableSortBy: true,
            className: 'd-flex align-items-center',
          },
        ],
        [version],
      )}
    />
  );
}
Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  setVersion: PropTypes.func.isRequired,

  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // recordsRef: PropTypes.any.isRequired,
};

Table.defaultProps = {
};
export default Table;
