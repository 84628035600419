import React from 'react';
import PropTypes from 'prop-types';

const RelevancyInList = ({ label }) => {
  let textStyle = 'text-dark';
  let value = '';
  if (label) {
    switch (label) {
      case 'Ignore':
        textStyle = 'il-text-gray';
        break;
      case 'Relevant':
        textStyle = 'text-success';
        break;
      default:
        textStyle = 'il-text-gray';
        break;
    }
    value = label;
  }

  return (
    <div>
      {label ?
        (
          <div>
            <span className={textStyle}>{value}</span>
          </div>
        ) : ''
      }
    </div>
  );
};

RelevancyInList.propTypes = {
  label: PropTypes.string.isRequired,
};

export default RelevancyInList;
