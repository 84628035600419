import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

const BrandApprovedDateField = ({
  record,
  updateRecordFunction,
  afterSaveFunc,
  isEditAllowed,
}) => {
  const { brandApprovedDate, status } = record.params;
  const [selectedDate, setDate] = useState(brandApprovedDate ? moment(moment.utc(brandApprovedDate).format('YYYY-MM-DD')).toDate() : null);
  const [disabled, setDisabled] = useState(false);
  // useEffect(() => {
  //   setDate(dateSigned ? new Date(dateSigned) : null);
  // }, [dateSigned, status]);

  useEffect(() => {
    setDate(brandApprovedDate ? moment(moment.utc(brandApprovedDate).format('YYYY-MM-DD')).toDate() : null);
  }, [brandApprovedDate]);

  const updateField = async (date) => {
    setDisabled(true);
    try {
      const data = await axios.post('/api/portal/compliance/post-brand-approved-date-update', {
        id: record.id,
        value: moment.utc(moment(date).format('YYYY-MM-DD')),
      });
      if (data?.data?.recordPopulated && updateRecordFunction) {
        updateRecordFunction(data?.data?.recordPopulated);
      } else {
        updateRecordFunction(record.id);
        afterSaveFunc();
      }
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
    setDisabled(false);
  };

  if (status !== 'Brand Approved') return '';

  return (
    <>
      {!isEditAllowed ? (
        <div style={{ paddingRight: '10px' }}>
          <span>{record.params?.brandApprovedDate ? moment(record.params.brandApprovedDate).format('MM/DD/YY') : '-'}</span>
        </div>
      ) : (
        <DatePicker
          selected={selectedDate}
          onChange={(date) => {
            console.log(date);
            setDate(date);
            updateField(date);
          }}
          dateFormat="MMM d, yyyy"
          placeholderText="Select B/A/D"
          dropDownMode="select"
          // isClearable
          wrapperClassName="date-picker--interval w-100"
          className="form-control form-control-sm w-100"
          disabled={disabled}
        />
      )}
    </>
  );
};
BrandApprovedDateField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func,
  afterSaveFunc: PropTypes.func,
  isEditAllowed: PropTypes.bool.isRequired,
};
BrandApprovedDateField.defaultProps = {
  updateRecordFunction: () => {},
  afterSaveFunc: () => {},
};
export default BrandApprovedDateField;

