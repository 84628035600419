import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WVValidator from '../../../../../../../shared/helpers/WVValidator';

const PaymentMethod = ({ record }) => {
  if (!record || (record.params?.userType === 'Creator' && (!record.params.influencerObject || !record.params.influencerObject.payment))) {
    return '';
  }

  const recordToUse = record.params?.userType === 'Agent' ? record.params.agentUser : record.params.influencerObject;
  const { stripe, billCom } = recordToUse.payment;

  if (stripe.isDefault && !WVValidator.isEmpty(stripe.accountId)) {
    return (
      <span className="text-success" style={{ textAlign: 'center', display: 'block', marginRight: '1em' }}>
        <FontAwesomeIcon icon={['fab', 'cc-stripe']} size="2x" />
      </span>
    );
  } else if (billCom.isDefault && !WVValidator.isEmpty(billCom.accountId)) {
    return (
      <span style={{ textAlign: 'center', display: 'block', marginRight: '1em' }}>
        <img src="/images/payments/billComGreen.png" className="img-fluid" alt="Bill.com" style={{ width: '40px' }} />
      </span>
    );
  }

  return '';
};
PaymentMethod.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PaymentMethod;
