import React, { useCallback, useState, useEffect } from 'react';
// import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import MatTableList from '@/shared/tables/materialTable/TableRedux';
// import formater from '../../../../../../../shared/helpers/WVFormatter';
import CreatorInList from './CreatorInList';
import { getConstant } from '@/shared/helpers/WVConstants';
// import TableCellContextMenu from '@/shared/components/table/TableCellContextMenu';
import { Button, Card, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import Hook from 'ILHooks';
import { axios } from 'ApiClient';
// import PaymentMethod from './PaymentMethod';
// import Payments from './Payments';
// import Hook from '@/shared/hooks';

const DealStage = ({ record }) => {
  const dealstages = (getConstant('deal', 'pipelines').find(p => p.label === 'Main Pipeline')).stages;
  const r = record;
  let dealStage = '-';
  if (r.params.dealObject && r.params.dealObject.dealstage) {
    dealStage = '-';

    if (dealstages[r.params.dealObject.dealstage]) dealStage = dealstages[r.params.dealObject.dealstage];
  }
  return (
    <div>
      { dealStage }
    </div>
  );
};
DealStage.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
const removeMergedContact = async ({ parentContactId, contactId }) => {
  try {
    await axios.post('/api/portal/finance/post-remove-merged-contact', {
      parentContactId, contactId
    })
  } catch (e) {

  }
  
}
const  CardToGenerateItem = ({ title, array}) => (
  <Card
    style={{
      border: '1px solid #eee',
      paddingBottom: '0px',
      marginBottom: '10px'
    }}
  >
    <CardHeader>
      {title}
    </CardHeader>
    <ListGroup flush>
      {array?.length > 0 ? array.map((a) => (
        <ListGroupItem>
         {a}
        </ListGroupItem>
      )) : (
        <ListGroupItem>
        No Data
        </ListGroupItem>
      )}
      
    </ListGroup>
  </Card>
);


const ContactItem = ({ record, showModal, item, success }) => {
  
  const text = <>
    When you do that action, we will do the following:
    <ol>
        <li>Remove the Contact from the database.</li>
        <li>Update any related prospects.</li>
        <li>Assign Influencer</li>
    </ol>
  </>
  const influnecer = item.influencers.find(b => String(b.hsContactVid) === String(record.hs_object_id));
  const prospects = item.prospects.filter(b => String(b.hsUserId) === String(record.hs_object_id));
  
  return (
    <div>
      <CardToGenerateItem
          title={(<a
            href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.hs_object_id}/`}
            target="_blank"
            title="Open Hubspot Contact"
            rel="noreferrer noopener"
          >
            {record.firstname} {record.lastname}
          </a>)}
          array={record ? [
            (<>
              <strong>Email: </strong>{record.email} <br />
              <strong>Hubspot Contact Id: </strong>{record.hs_object_id} <br />
              <strong>Influencers: </strong>{influnecer ? (<>{influnecer.fullName} </>) : 'No Influencer'} <br />
            </>)
          ] : []}
      />
      <div>
        <div>
          <CardToGenerateItem
            title="CpmProspects"
            array={prospects?.length  ? prospects.map(m =>
              (<>
                <strong>IL Status:</strong> {m.approvalStatus?.influencelogic?.status}<br />
                <strong>Brand Status:</strong> {m.approvalStatus?.influencelogic?.status}<br />
              </>)
              ) : []}
          />
        </div>
      </div>
      { record._id !== item._id ? <div className='mt-2'>
        <Button
          color="primary"
          size="sm"
          onClick={() => showModal({
            title: 'Remove Merged Contact',
            message: text,
            processRecord: async() => {
              await removeMergedContact({ parentContactId: item.hs_object_id, contactId: record.hs_object_id });
              success();
            },
            confirmButtonCaption: 'Remove Contact',
          })}>Remove {record.firstname || ''} {record.lastname || ''} From Our DB</Button>
      </div> : null}
    </div>
  );
};
ContactItem.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

const Table = React.memo(() => {
  const [ showModal ] = Hook.useShowModalHook('ConfirmModalDefault');
  const { showSuccess } = Hook.useNoticeHook();
  const [offers, setOffers] = useState([])
  console.log('table');
  const reload = Hook.useReloadTableHook('/api/portal/finance/get-merged-contacts');
  const success = useCallback(() => {
    showSuccess('Deal was merged successfully');
    reload();
  }, [reload, showSuccess])
  useEffect(() => {
    axios.getSafety('/api/portal/finance/get-offers').then((data) => {
      const brands = {};
      data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      setOffers(data.offers);
    })
  }, [])
  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Payments Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'fullSearchName',
          Header: () => 'Creator',
          accessor: () => (''),
          // eslint-disable-next-line max-len
          Cell: p => (<CreatorInList record={p.row.original} />),
          width: 260,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'detailsParent',
          Header: () => 'Current Contact',
          accessor: (record) => record.params.matchContacts.length,
          // eslint-disable-next-line max-len
          Cell: p => (
            <div>
              {p.row.original.params?.matchContacts?.filter(r => r._id === p.row.original.params._id).map(a => (<ContactItem item={p.row.original.params} record={a} showModal={showModal} success={success} offers={offers} />))}
            </div>
          ),
          width: 400,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'details',
          Header: () => 'Merged Contact',
          accessor: (record) => record.params.matchContacts.length,
          // eslint-disable-next-line max-len
          Cell: p => (
            <div>
              {p.row.original.params?.matchContacts?.filter(r => r._id !== p.row.original.params._id).map(a => (<ContactItem item={p.row.original.params} record={a} showModal={showModal} success={success} offers={offers} />))}
            </div>
          ),
          width: 400,
          className: '',
          disableSortBy: true,
        },
        /* {
          Header: '',
          accessor: () => null,
          id: 'actions',
          width: 50,
          Cell: (p) => {
            console.log(p)
            const contextMenuOptions = [
              
            ];
            return (
              <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                <TableCellContextMenu options={contextMenuOptions} />
              </div>
            );
          },
          disableSortBy: true,
          className: 'd-flex align-items-center align-middle',
        }, */
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
