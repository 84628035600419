/* eslint react/prop-types: 0 */
import React, { useCallback } from 'react';
import _ from 'lodash';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { axios } from 'ApiClient';
import CopyToClipboard from '../../../../../../../shared/components/CopyToClipboard';
import Hook from 'ILHooks';


export default React.memo(({ record }) => {
  const [ showModal ] = Hook.useShowModalHook('ConfirmModalDefault');
  const reload = Hook.useReloadTableHook('/api/portal/finance/get-contacts-audit-report');
  const { showSuccess, showError } = Hook.useNoticeHook();
  const updateItem = useCallback(async( parentContactId, contactId) => {
    const result = await axios.postSafety('/api/portal/finance/post-merge-influnecer', { parentContactId, contactId });
    if (result.success) {
      reload();
      showSuccess('Contacts were merged successfuly')
    } else {
      showError(result.error || 'Unable to merge Contacts');
    }
  });
  const text = <>
    When you do that action, we will do the following:
    <ol>
        <li>Run Hubspot Command to merge Contacts</li>
        <li>Remove the Contact from the database.</li>
        <li>Move Bills from source Influencer to target Influencer.</li>
        <li>Move Media Contents from source Influencer to target Influencer.</li>
        <li>Move Payable from source Influencer to target Influencer.</li>
        <li>Move ServiceAggrement from source Influencer to target Influencer.</li>
        <li>Move Vanity Url from source Influencer to target Influencer.</li>
        <li>Move ImpactAction from source Influencer to target Influencer.</li>
        <li>Move ImpactActionItem from source Influencer to target Influencer.</li>  
    </ol>
  </>
  const items = record.params.contacts.map((contact) => {
    const options = record.params.contacts.map((a) => ({
      value: a?._id,
      label: `${a?.firstname} ${a?.lastname} (${a?.email})`
    })).filter(a => a.value !== contact._id)
    console.log(options, 'options');
    const name = `${contact?.firstname} ${contact?.lastname}`;
    const { mediaChannels: hsMedia } = contact || {};
    const mediaChannels = (() => {
      if (!record) return false;
      const returnObj = [];
      const m = hsMedia || {};
      const getoObj = t => (
        <a
          href={t.url}
          target="_blank"
          rel="noreferrer"
          key={t.platform}
          style={{ marginRight: '4px' }}
        >
          <img
            src={`/images/mediacontent/${t.platform}.png`}
            alt={t.platform}
            title={t.title}
            style={{ width: '20px' }}
          />
        </a>);
      // eslint-disable-next-line no-useless-escape
      const addhttp = url => `${!/^(?:f|ht)tps?:\/\//.test(url) ? 'https://' : ''}${url}`;

      if (m.youtube_channel_url) {
        const url = addhttp(m.youtube_channel_url);
        returnObj.push(getoObj({ platform: 'youtube', url, title: 'youtube' }));
      }
      if (m.facebook_channel_url) {
        const url = addhttp(m.facebook_channel_url);
        returnObj.push(getoObj({ platform: 'facebook', url, title: 'facebook' }));
      }
      if (m.instagram_channel) {
        const url = addhttp(m.instagram_channel);
        returnObj.push(getoObj({ platform: 'instagram', url, title: 'instagram' }));
      }
      return returnObj;
    })();
    if (!contact) return null;
    
    const { creator, adminUserObject } = contact;
    return (
      <div className="mb-4 w-100 ml-2" key={contact.hs_object_id}>
        <div>
          <div
            className=""
          >
            <strong>Contact: </strong>
            {contact.hs_object_id ? (
              <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${contact.hs_object_id}/`} target="_blank" rel="noreferrer">
                {name}
              </a>) : null}
          </div>
          <CopyToClipboard
            value={contact.email}
          >
            {contact?.email?.length > 50 ? `${contact.email.slice(0, 50)}...` : contact.email}
          </CopyToClipboard>
          <div className="" >{mediaChannels}</div>
        </div>
        {creator ? (
          <div>
            <div
              className=""
            >
              <strong>Creator: </strong>
              {creator._id ? (
                <a href={`/influencer/profile/${creator._id}`} target="_blank" rel="noreferrer">
                  {creator.first_name} {creator.last_name}
                </a>) : null}
            </div>
            <CopyToClipboard
              value={creator.email}
            >
              {creator?.email?.length > 50 ? `${creator.email.slice(0, 50)}...` : creator.email}
            </CopyToClipboard>
          </div>
        ) : (<div><strong>Creator: </strong> No Creator Assigned</div>)}
        {adminUserObject ? (
          <div>
            <div
              className=""
            >
              <strong>Contact Owner: </strong>
              <div>{contact.adminUserObject?.firstName || ''} {contact.adminUserObject?.lastName || ''}</div>
            </div>
            <CopyToClipboard
              value={adminUserObject?.email}
            >
              {adminUserObject?.email?.length > 50 ? `${adminUserObject?.email.slice(0, 50)}...` : adminUserObject?.email}
            </CopyToClipboard>
          </div>
        ) : (<div><strong>Contact Owner: </strong> No Contact Owner Assigned</div>)}
          <UncontrolledButtonDropdown className="mt-2">
            <DropdownToggle caret className="mr-2" color="primary" style={{ padding: '5px 25px' }}>
              Merge contact to ...
            </DropdownToggle>
            <DropdownMenu>
               {options.map(a => (
                <DropdownItem 
                  onClick={() => showModal({
                    title: 'Merge Contacts',
                    message: text,
                    processRecord: async() => {
                      updateItem( a.value, contact._id);
                    },
                    confirmButtonCaption: 'Remove Contact',
                  })}>
                  {a.label}
                </DropdownItem>
               ))}
              
            </DropdownMenu>
          </UncontrolledButtonDropdown>
      </div>
    );
  });

  return (
    <div>
      {items}
    </div>);
}, (a, a1) => _.isEqual(a.record, a1.record));
