import React, { useMemo } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col, Spinner, Card, CardBody, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { capitalize } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formater from '../../../../../shared/helpers/WVFormatter';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
// import { getConstant } from '../../../../../shared/helpers/WVConstants';
import HubspotDealLink from '../../../../../shared/components/HubspotDealLink';
import Item from '../../../Reports/StatItem';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';


const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  manualPageSize: [5, 10, 30, 40],
  withSearchEngine: true,
  sortBy: 'date',
  direction: 'desc',
  placeholder: 'Search Notes or Deal Name',
  searchFieldMinWidth: '50%',
};

const billDotCom = {
  0: 'Paid in full',
  1: 'Open',
  2: 'Partial payment',
  4: 'Scheduled',
};

const getPaymentStatus = (row) => {
  if (row.billComTransfer) {
    return billDotCom[row.billComTransfer.paymentStatus];
  } else if (row.payable) {
    return capitalize(row.payable.status);
  } else if (row.bill?.paymentStatus) {
    if (row.bill.paymentStatus === 'paidinfull') {
      return 'Paid in Full';
    }
    return capitalize(row.bill.paymentStatus);
  } else if (row.method === 'stripe' && row.stripeTransfer?.id) {
    return 'Success';
  }
  return '-';
};

const Payments = ({
  payments, loading, /* influencer, location, */ billComIssue, stripeIssue, deals,
}) => {
  const { role, teams } = window.REDUX_STATE.session;
  const hasAccessToPayable = ['superAdmin', 'owner'].includes(role) && teams && teams.length > 0 && (teams.includes('management') || teams.includes('finance') || teams.includes('super_powers') || teams.includes('engineering'));

  const columnTransform = useMemo(() => [
    {
      id: 'date',
      Header: 'Date (ET)',
      Cell: value => formater.formatToEST(moment(value.value)).format('M/D/YYYY h:mm A'),
      accessor: record => record.paymentDate,
    },
    {
      id: 'amount',
      Header: 'Amount',
      Cell: ({ value }) => formater.formatCurrency(value),
      accessor: record => record.amount,
    },
    {
      id: 'method',
      Header: 'Method',
      Cell: ({ value, row }) => useMemo(() => {
        const methodRight = value && ['wire', 'check', 'venmo', 'billCom', 'stripe', 'other', 'payPal'].includes(value) ? value : 'other';
        // const methods = getConstant('creator', 'paymentMethod', {});

        return row.original.isOffline ? (
          <Tooltip title="Offline Payout">
            <div>
              <Badge
                color="secondary"
              >
                <div
                  style={{
                    height: '25px',
                    width: '50px',
                    backgroundImage: `url(/images/payments/${methodRight}.png)`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </Badge>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="System Payout">
            { methodRight === 'billCom' ?
              (<img src="/images/payments/billCom-logo.png" style={{ width: '50px' }} className="img-fluid" alt="Bill.com" />)
              : (<div style={{ color: '#32325d' }}><FontAwesomeIcon icon={['fab', 'stripe']} style={{ fontSize: '3em' }} /></div>)
            }
          </Tooltip>

        );
      }),
      accessor: record => record.method || '',
    },
    {
      id: 'billInvoice',
      Header: 'Invoice Number',
      Cell: (value) => {
        const record = value.row.original;
        return (
          <a
            href={`/resources/Bill/actions/list?filters.fullSearchName=${record.bill?.invoiceNumber || ''}`}
            target="_blank"
            rel="noreferrer noopener"
            className=""
          >
            {record.bill?.invoiceNumber}
          </a>);
      },
      accessor: record => record.bill?.invoiceNumber || '',
    },
    {
      id: 'paymentStatus',
      Header: 'Status',
      Cell: value => getPaymentStatus(value.row.original),
      accessor: record => getPaymentStatus(record),
    },
    {
      id: 'accountsPayable',
      Header: 'Accounts Payable',
      accessor: record => record.payable?.accountsPayable?.name || '',
      Cell: (value) => {
        const record = value.row.original;
        const dealRecord = record.payable ? record.payable.deal : record.bill?.deal;
        const dealObject = deals.find(d => d.id === (dealRecord?._id || dealRecord));
        const dealName = dealObject?.params?.dealname || '';
        return hasAccessToPayable && record.payable?.accountsPayable?.name ? (
          <a
            href={`/finance/account-payable-report-2/${record.payable?.accountsPayable?._id || ''}/creators?filters.searchFullName=${dealName}`}
            target="_blank"
            rel="noreferrer noopener"
            className=""
          >
            {record.payable?.accountsPayable?.name}
          </a>
        ) : (
          <>{record.payable?.accountsPayable?.name}</>
        );
      },
    },
    {
      id: 'deal',
      Header: 'Deal',
      accessor: (record) => {
        const dealId = record.payable ? record.payable.deal : record.bill?.deal;
        if (!dealId) return '';
        const deal = deals.find(d => d.id === dealId);
        return deal?.title || '';
      },
      Cell: (value) => {
        const record = value.row.original;
        const dealRecord = record.payable ? record.payable.deal : record.bill?.deal;
        if (!dealRecord) return '';
        const dealObject = deals.find(d => d.id === (dealRecord?._id || dealRecord));
        if (!dealObject || !dealObject.params?.hs_object_id) {
          return (<Badge color="danger" className="table-rounded">Missing Deal</Badge>);
        }
        return (<HubspotDealLink
          id={Number(dealObject.params.hs_object_id)}
          title={dealObject.title}
        />);
      },
    },
    /* {
      id: 'transferId',
      Header: 'Transfer Id',
      accessor: record => (record.stripeTransfer ? record.stripeTransfer.id || '' : record.billComTransfer?.id || ''),
      Cell: value => value.value,
    }, */
    {
      id: 'notes',
      Header: 'Notes',
      accessor: record => record?.payment?.notes || record?.payment?.description || '',
    },
  ], [deals, payments]);
  const totalPayout = useMemo(() => {
    let total = 0;
    payments.forEach((payment) => {
      total += Number(payment?.amount || 0);
    });
    return total;
  }, [payments]);
  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Payment History</h2>
      <Card id="paymentBlock section-payment-history">
        <CardBody>
          {payments.length > 0 ? (
            <Row className="mb-3">
              <Col sm="2" md="4" />
              <Col lg="4" md="4" sm="12">
                <Item items={[{
                  label: 'Total Paid Amount',
                  value: formater.formatCurrency(totalPayout || 0),
                }]}
                />
              </Col>
            </Row>) : null}
          <div className="transfers-table">
            {billComIssue ? (
              <Alert color="warning" bordered icon isOpen={billComIssue !== null}>
                <div className="alert__content">
                  <p><span className="bold-text">Warning!</span> {billComIssue}</p>
                </div>
              </Alert>
            ) : null}
            {stripeIssue ? (
              <Alert color="warning" bordered icon isOpen={stripeIssue !== null}>
                <div className="alert__content">
                  <p><span className="bold-text">Warning!</span> {stripeIssue}</p>
                </div>
              </Alert>
            ) : null}
            {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
            { !loading &&
              <Row>
                <Col>
                  {payments.length > 0 ?
                    <ReactTableBase
                      columns={columnTransform}
                      data={payments}
                      tableConfig={tableConfig}
                    />
                    :
                    <Alert color="warning" bordered icon>
                      <p className="py-2">
                        The Creator currently has no Payments.
                      </p>
                    </Alert>
                  }
                </Col>
              </Row>
            }
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

Payments.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.any).isRequired,
  // influencer: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  // location: PropTypes.objectOf(PropTypes.any).isRequired,
  deals: PropTypes.arrayOf(PropTypes.any).isRequired,
  billComIssue: PropTypes.string,
  stripeIssue: PropTypes.string,
};
Payments.defaultProps = {
  // influencer: null,
  billComIssue: null,
  stripeIssue: null,
};

export default Payments;
