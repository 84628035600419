import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Input, Label, Button } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import Alert from '../../../../../../../shared/easydev2/shared/components/Alert';
import withNotice from '../../../../../../App/store/with-notice';
import { getMediaChannelNameKey, getMediaChannelUrlKey } from '../../../../../../../shared/helpers/models/HubilContact';
import WVFormatter from '@/shared/helpers/WVFormatter';

const EditMediaChannels = ({
  isOpen,
  toggle,
  name,
  saveUrl,
  afterSave,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const [platform, setPlatform] = useState({ label: 'YouTube', value: 'youtube_channel_url' });
  const [mediaUrl, setMediaUrl] = useState('');
  const [disableSearch, setDisableSearch] = useState(true);
  const [mediaChannels, setMediaChannels] = useState({});
  const [tubularCreatorId, setTubularCreatorId] = useState(null);
  const [podcastGenresOptionsLoading, setPodcastGenresOptionsLoading] = useState(false);
  const [podcastGenresOptions, setPodcastGenresOptions] = useState([]);
  const [podcastGenres, setPodcastGenres] = useState([]);
  const [disableMediaProperties, setDisableMediaProperties] = useState(true);
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('Creator was found');
  const [alertColor, setAlertColor] = useState('success');
  const [blacklistAlert, setBlacklistAlert] = useState({ status: false, type: 'email' });

  const tubularChannelsArray = ['youtube', 'instagram', 'facebook', 'twitter', 'tiktok'];
  const channelsArray = ['YouTube', 'Instagram', 'Facebook', 'Twitter', 'TikTok'];

  const hsMediaChannelPlatforms = [
    { label: 'YouTube', value: 'youtube_channel_url', domain: 'youtube.com' },
    { label: 'Instagram', value: 'instagram_channel', domain: 'instagram.com' },
    { label: 'Facebook', value: 'facebook_channel_url', domain: 'facebook.com' },
    { label: 'Twitter', value: 'twitterhandle', domain: 'twitter.com' },
    // { label: 'TikTok', value: 'tiktok_channel_url' },
    // { label: 'Podcast', value: 'apple_podcast_url' },
  ];

  const reset = () => {
    setMediaChannels({});
    setMediaUrl('');
    setPodcastGenres([]);
    setShowAlert(false);
    setDisableMediaProperties(true);
    setBlacklistAlert({ status: false, type: 'email' });
    toggle();
  };

  const setMediaChannelsProperty = (key, value) => {
    setMediaChannels(m => ({
      ...m,
      [key]: WVFormatter.normalizeUrl(value),
    }));
  };

  const changePlatform = (selected) => {
    setPlatform(selected);
    setMediaUrl('');
    setDisableSearch(true);
    setMediaChannels({});
    setDisableMediaProperties(true);
  };

  const validateMediaUrl = (value) => {
    if (value) {
      const urlItem = hsMediaChannelPlatforms.find(item => item.label === platform.label);
      if (value.toString().includes(urlItem.domain)) {
        setDisableSearch(false);
      } else {
        setDisableSearch(true);
      }
    }
  };

  const checkRequiredFields = () => {
    if (mediaUrl.length > 10) {
      validateMediaUrl(mediaUrl);
    }
  };

  const changeMediaUrl = (e) => {
    setMediaUrl(e.target.value);
    checkRequiredFields();
    if (e.target.value.length < 11 && !disableSearch) setDisableSearch(true); setDisableMediaProperties(true);
    if (errors.mediaUrl && e.target.value.length > 0) setErrors({ ...errors, mediaUrl: false });
  };

  const save = () => {
    setLoading(true);
    const mediaChannelsMapping = {
      youtube_channel_name: '',
      youtube_channel_description: '',
      youtube_channel_url: '',
      apple_podcast_url: '',
      podcast_genre: '',
      podcast_title: '',
      instagram_channel_name: '',
      instagram_channel: '',
      facebook_channel_url: '',
      tiktok_channel_name: '',
      tiktok_channel_url: '',
      twitch_account: '',
      twitterhandle: '',
    };
    const data = { ...mediaChannelsMapping, ...mediaChannels };
    axios.post(saveUrl, { data }).then((response) => {
      setLoading(false);
      reset();
      if (response.data.success) {
        afterSave({ mediaChannels: { ...mediaChannels, tubularCreatorId } });
        addNotice({
          message: 'Media Channels have been successfully updated',
          type: 'success',
        });
      } else {
        addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
    });
  };

  const fetchPodcastGenres = () => {
    setPodcastGenresOptionsLoading(true);
    axios.get('/api/portal/research/get-podcast-genres').then((res) => {
      if (res.data && res.data.success) {
        if (res.data.podcastGenresOptions) {
          setPodcastGenresOptions(res.data.podcastGenresOptions);
        }
      }
      setPodcastGenresOptionsLoading(false);
    }).catch((error) => {
      console.error(error);
      setPodcastGenresOptionsLoading(false);
    });
  };

  const uploadTubularData = () => {
    setLoading(true);
    setMediaChannels({});
    axios.get(`/api/portal/prospects/get-data-from-tubular?searchQuery=${mediaUrl}`).then((response) => {
      console.log(response, 'response')
      if (response.data.success && response.data.creator) {
        const mediaData = {};
        response.data.creator.accounts.forEach((account) => {
          if (tubularChannelsArray.includes(account.platform)) {
            if (account.platform === 'youtube' && platform?.value === 'youtube_channel_url') {
              setMediaUrl(WVFormatter.normalizeUrl(account.account_url));
            }
            const platformKey = getMediaChannelNameKey(account.platform);
            if (platformKey) {
              mediaData[platformKey] = account.title;
            }
            mediaData[getMediaChannelUrlKey(account.platform)] = (platform.label.toLowerCase() === account.platform && account.platform !== 'youtube') ? mediaUrl : WVFormatter.normalizeUrl(account.account_url);
          }
        });
        setMediaChannels({
          ...mediaData,
        });
        if (response.data?.creator?.creator_id) setTubularCreatorId(response.data.creator.creator_id);
        axios.get(`/api/portal/blacklist/get-item-by-creator-id?tubularCreatorId=${response.data.creator.creator_id}`).then((resp) => {
          if (resp.data.success) {
            if (resp.data.result) {
              setBlacklistAlert({ status: true, type: 'creator' });
            } else {
              setBlacklistAlert({ status: false, type: 'creator' });
            }
          }
        });
        setAlertMessage('Creator was found.');
        setAlertColor('success');
        setShowAlert(true);
      }
      if (response.data.success && !response.data.creator) {
        setMediaChannels({
          [platform.value.toLowerCase()]: WVFormatter.normalizeUrl(mediaUrl)
        });
        setAlertMessage('Creator was not found.');
        setAlertColor('warning');
        setShowAlert(true);
      }
      if (!response.data.success) {
        setMediaChannels({
          [platform.value.toLowerCase()]: WVFormatter.normalizeUrl(mediaUrl)
        });
        setAlertMessage(response.data.error || 'Creator was not found.');
        setAlertColor('warning');
        setShowAlert(true);
      }
      setLoading(false);
      setDisableMediaProperties(false);
    });
  };
  // console.log(mediaChannels, 'mediaChannels');
  // console.log(mediaChannels[getMediaChannelUrlKey('youtube')])

  const colorStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  useEffect(fetchPodcastGenres, []);
  useEffect(checkRequiredFields, [mediaUrl]);

  return (
    <Modal isOpen={isOpen} toggle={reset}>
      <ModalHeader toggle={reset} tag="h4">Edit Media Channels</ModalHeader>
      <ModalBody className="dashboard">
        <Row>
          <Col md="4">
            <FormGroup>
              <Label>Platform</Label>
              <Select
                value={platform}
                options={hsMediaChannelPlatforms}
                onChange={changePlatform}
                placeholder=""
                // isClearable
              />
            </FormGroup>
            <div className="grey-small-text grey-minus-margin">Media URL Platform (Required)</div>
          </Col>
          <Col md="8">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormGroup style={{ width: '92%' }}>
                <Label>Media Url</Label>
                <Input
                  type="text"
                  id="media_url_contact"
                  name="media_url_contact"
                  onChange={changeMediaUrl}
                  value={mediaUrl}
                  // onBlur={validateMediaUrl}
                />
              </FormGroup>
              <div>
                <Button
                  type="button"
                  className="is-primary"
                  onClick={uploadTubularData}
                  style={{ marginTop: '33px', marginLeft: '10px', width: '130px' }}
                  disabled={disableSearch}
                >
                  {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                  {!loading && <span className="lnr lnr-magnifier font-weight-bold">  </span>}
                  <span>Search</span>
                </Button>
              </div>
            </div>
            <div className="grey-small-text grey-minus-margin" style={errors.mediaUrl ? { color: 'red' } : {}}>Media URL (Required)</div>
          </Col>
        </Row>
        {blacklistAlert.status && (
          <Alert color="warning" className="w-100 mt-2">
            <p className="py-2 text-dark">
              Creator is in Blacklist. <a href="/influencers/contacts" target="_blank" rel="noopener">Manage Blacklist</a>
            </p>
          </Alert>
        )}
        <Alert color={alertColor} isOpen={showAlert} toggle={() => { setShowAlert(false); }} className="w-100 standard-close-cross">
          <p className="py-2" style={{ color: '#212529' }}>
            {alertMessage}
          </p>
        </Alert>
        {!(disableMediaProperties || blacklistAlert.status) ? (
          <div className="pt-3 dashboard modal__body finance-form" style={{ fontSize: '100%' }}>
            <h4>Media Channel Properties</h4>
            <hr />
            <Row>
              <Col md={6}>
                <div className="grey-small-text grey-minus-margin mb-3">Specify the Contact&apos;s Media Channel Details</div>
              </Col>
            </Row>
            {channelsArray.map(channel => (
              <div key={`channel-${channel}`} className="mb-2">
                <h5 style={{ fontSize: '17px', marginBottom: '5px', fontWeight: 'bold' }}>{channel}</h5>
                <Row>
                  {!['Twitter', 'Facebook'].includes(channel) && (
                    <Col md={6}>
                      <FormGroup>
                        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                        <label htmlFor={`${channel.toLowerCase()}_channel_name`}>{`${channel} Channel Name`}</label>
                        <Input
                          type="text"
                          id={`${channel.toLowerCase()}_channel_name`}
                          name={`${channel.toLowerCase()}_channel_name`}
                          placeholder=""
                          autoComplete="off"
                          onChange={e => setMediaChannelsProperty(getMediaChannelNameKey(channel.toLowerCase()), e.target.value)}
                          value={mediaChannels[getMediaChannelNameKey(channel.toLowerCase())] || ''}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  <Col md={!['Twitter', 'Facebook'].includes(channel) ? 6 : 12}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor={`${channel.toLowerCase()}_channel_url`}>{`${channel} ${channel === 'TikTok' ? 'URL' : 'Channel URL'}`}</label>
                      <Input
                        type="text"
                        id={`${channel.toLowerCase()}_channel_url`}
                        name={`${channel.toLowerCase()}_channel_url`}
                        placeholder=""
                        autoComplete="off"
                        // readOnly={platform?.value?.includes(channel.toLowerCase())}
                        onChange={e => setMediaChannelsProperty(getMediaChannelUrlKey(channel.toLowerCase()), WVFormatter.normalizeUrl(e.target.value))}
                        value={mediaChannels[getMediaChannelUrlKey(channel.toLowerCase())] || ''}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            ))}
            <h5 style={{ fontSize: '17px', marginBottom: '5px', fontWeight: 'bold' }}>Podcast</h5>
            <Row>
              <Col md={6}>
                <FormGroup>
                  {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                  <label htmlFor="podcast_title">Podcast Title</label>
                  <Input
                    type="text"
                    id="podcast_title"
                    name="podcast_title"
                    placeholder=""
                    autoComplete="off"
                    onChange={e => setMediaChannelsProperty('podcast_title', e.target.value)}
                    value={mediaChannels.podcast_title || ''}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <div className="w-100 research-watchlists">
                    <Label>
                      Podcast Genre(s)
                    </Label>
                    <Select
                      isMulti
                      name="podcastGenresSelect"
                      isLoading={podcastGenresOptionsLoading}
                      value={podcastGenres}
                      options={podcastGenresOptions}
                      onChange={(value) => {
                        setPodcastGenres(value);
                        setMediaChannelsProperty('podcast_genre', value?.length > 0 ? value.map(genre => genre.label).join(', ') : null);
                      }}
                      placeholder="Select Genres..."
                      style={{ maxHeight: '120px' }}
                      styles={colorStyles}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                  <label htmlFor="podcast_url">Podcast URL</label>
                  <Input
                    type="text"
                    id="podcast_url"
                    name="podcast_url"
                    placeholder=""
                    autoComplete="off"
                    onChange={e => setMediaChannelsProperty('apple_podcast_url', e.target.value)}
                    value={mediaChannels.apple_podcast_url || ''}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={save} disabled={disableMediaProperties || blacklistAlert.status || Object.values(mediaChannels || {}).filter(a => a).length === 0}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          Save
        </Button>
        <Button color="secondary" onClick={reset}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

EditMediaChannels.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  name: PropTypes.string,
  saveUrl: PropTypes.string.isRequired,
  afterSave: PropTypes.func,
  addNotice: PropTypes.func.isRequired,
};

EditMediaChannels.defaultProps = {
  isOpen: false,
  name: null,
  afterSave: () => {},
};

export default withNotice(EditMediaChannels);
