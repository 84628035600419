import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import UserPropertyType from './custom';
import Actions from './custom/Actions';
import PeriodTabs from '../../../../../../shared/components/filter/PeriodTabs';
import { getStartDate, getEndDate } from '../../../../../../shared/components/filter/Period';
import MatTableList from '../../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../../shared/components/BreadCrumbs';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';
import {
  getOperator,
  dateFromFilters,
  endDateFromFilters,
} from '../../../../../../shared/helpers/Filter';

const ListContainer = (props) => {
  const {
    resource,
    action,
    history,
  } = props;

  const initialFilterParams = parseUrl();

  const [version] = useState((new Date()).toString());

  let initialFrom = dateFromFilters(initialFilterParams, 'activityDatetime');
  let initialTo = endDateFromFilters(initialFilterParams, 'activityDatetime');

  if (!(initialFrom || initialTo)) {
    initialFrom = moment().startOf('month').toISOString();
    initialTo = moment().endOf('day').toISOString();
  }

  const [activityDateOperator, setActivityDateOperator] = useState(getOperator('activityDatetime') || 'Between');
  const [activityDate, setActivityDate] = useState(initialFrom);
  const [activityDateTo, setActivityDateTo] = useState(initialTo);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  return (
    <Container className="dashboard">
      <Breadcrumbs
        links={[
          { title: 'Home', path: '/' },
          { title: 'Reports', path: false },
          { title: 'CPM Conversions', path: false },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row>
              <Col>
                <h3>CPM Conversions</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-0" />
          </CardTitle>
          <Row className="mt-3">
            <Col>
              <PeriodTabs
                selectButton={[selectedPeriod, setSelectedPeriod]}
                from={getStartDate(activityDate, activityDateOperator)}
                setFrom={(val) => { setActivityDate(val); setActivityDateOperator('Between'); }}
                to={getEndDate(activityDate, activityDateTo, activityDateOperator)}
                setTo={(val) => { setActivityDateTo(val); setActivityDateOperator('Between'); }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Actions
                history={history}
                initialFilterParams={initialFilterParams}
                setSelectedPeriod={setSelectedPeriod}
                activityDateOperator={activityDateOperator}
                setActivityDateOperator={setActivityDateOperator}
                activityDate={activityDate}
                setActivityDate={setActivityDate}
                activityDateTo={activityDateTo}
                setActivityDateTo={setActivityDateTo}
              />
              <div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
                <MatTableList
                  action={action}
                  resource={resource}
                  sortBy="activityDatetime"
                  direction="desc"
                  date={new Date()}
                  UserPropertyType={UserPropertyType}
                  showTitle={false}
                  isResizable={false}
                  version={version}
                  v={2}
                  noActions
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

ListContainer.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default ListContainer;
