import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Row, Col, Label } from 'reactstrap';


const ReportFilter = ({ setQuery }) => {
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  useEffect(() => {
    setQuery(`startDate=${moment(startDate).startOf('day').toISOString()}&endDate=${moment(endDate).endOf('day').toISOString()}`);
  }, [endDate, startDate]);
  return (
    <Col>
      <Row style={{ minWidth: '300px' }}>
        <Col>
          <Label>Start Date</Label>
          <div>
            <DatePicker
              selected={startDate}
              onChange={setStartDate}
              dateFormat="MM/dd/yyyy"
              maxDate={new Date()}
              placeholderText="Start Date"
              dropDownMode="select"
              className="form-control"
            />
          </div>
        </Col>
        <Col>
          <Label >End Date</Label>
          <div>
            <DatePicker
              selected={endDate}
              onChange={setEndDate}
              dateFormat="MM/dd/yyyy"
              maxDate={new Date()}
              placeholderText="End Date"
              dropDownMode="select"
              className="form-control"
            />
          </div>
        </Col>
        <Col lg="auto">
          <Label >{' '}</Label>
        </Col>
      </Row>
    </Col>
  );
};
ReportFilter.propTypes = {
  setQuery: PropTypes.func.isRequired,
};

export default ReportFilter;
