import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import moment from 'moment';
// import formater from '../../../../../shared/helpers/WVFormatter';
// import CopyToClipboard from '../../../../../shared/components/CopyToClipboard';


const InfoModal = ({ isOpen, toggleModal, record }) => {
  const [data, setDataItems] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isOpen === true) {
      setLoading(true);
      axios.get(`/api/portal/global/get-stats?name=${record._id}`).then((res) => {
        if (res.data) {
          const data1 = res.data.stats.map(({ date, value }) => ({
            name: moment(date).format('M/D/YY'),
            uv: Number(value || 0),
            pv: moment(date).format('M/D/YY'),
            amt: moment(date).format('M/D/YY'),
          }));
          setDataItems(data1);
        }
        setLoading(false);
      }).catch((error) => {
        console.error(error);
      });
    }
    return () => {
      // setPayableItems([]);
    };
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="delete-modal ltr-support" size="lg" style={{ maxWidth: '800px' }}>
      <ModalHeader>{record._id } details</ModalHeader>
      <ModalBody>
        {!loading ? (
          <div>
            <LineChart width={760} height={400} data={data}>
              <Line type="monotone" dataKey="uv" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
            </LineChart>
          </div>) : (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};
InfoModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
InfoModal.defaultProps = {
  isOpen: false,
};
export default InfoModal;
