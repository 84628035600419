import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { unflatten } from 'flat';

// import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../../App/store/with-notice';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';
import { momentUTCToLocalDate } from '../../../../../../shared/helpers/Dates';

import DeleteModalDialog from './custom/Modal/DeleteDialog';
import NewInsertionOrder from './custom/Modal/NewInvoice';
import MoveContentToNextPeriodModal from './custom/Modal/MoveContentToNextPeriodModal';
import UploadInvoiceModal from '../../CpmInvoice/components/custom/Modal/UploadInvoiceModal';
import Filter from '../../../../Reports/TimePeriodFilterExtended';
import FileHistroryModal from './custom/Modal/FileHistroryModal';
import Total from './custom/Total';


const compareApprovalStatus = {
  New: 'New', Sent: 'Sent', Received: 'Received', Paid: 'Paid',
};

const getApprovalStatusInitial = () => {
  const filters = parseUrl();
  const initialApprovalStatusFilters = {
    New: true,
    Sent: true,
    Received: true,
    Paid: true,
  };
  if (filters?.status) {
    Object.keys(initialApprovalStatusFilters).forEach((key) => {
      const rightKey = Object.keys(compareApprovalStatus).find(keyInState => keyInState === key);
      initialApprovalStatusFilters[key] = filters?.status.includes(compareApprovalStatus[rightKey]);
    });
  }
  return initialApprovalStatusFilters;
};


const CPMProspectsListContainer = ({
  addNotice,
  dispatch,
  history,
  action,
  resource,
}) => {
  const filters = parseUrl();
  const [version, setVersion] = useState((new Date()).toString());
  const [approvalStatusFilters, setApprovalStatusFilters] = useState(getApprovalStatusInitial());
  const [searchValue, onSearchChange] = useState(filters.search || '');
  // eslint-disable-next-line no-unused-vars
  const [metaTotal, setMetaTotal] = useState(0);
  // const [recordToRemove, setRecordToRemove] = useState(null);
  const [allRecords, setAllRecords] = useState([]);
  const [dateFrom, setDateFrom] = useState(moment('1970-01-01').startOf('day').format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(moment().endOf('day').format('YYYY-MM-DD'));

  const statuses = ['new', 'sent', 'received', 'paid'];

  const fetchRecords = () => {
    const res = [];
    const sendRequest = async (page) => {
      const api = new ApiClient();
      const response = await api.resourceAction({
        actionName: 'list',
        resourceId: resource.id,
        params: { page, perPage: 1000 },
      });

      const respCPM = await axios.post('/api/portal/prospects/post-price-per-invoice');
      
      const records = response.data?.records;
      // const total = response.data?.meta?.total || 0;
      records.forEach((_row) => {
        const row = unflatten(_row);
        const { startDate, endDate, status } = row.params;
        res.push({
          id: row.id,
          startDate,
          endDate,
          status: status.status?.toLowerCase(),
          amount: (respCPM?.data?.cpmStats?.find(c => String(c._id) === String(row.id)))?.amount || 0/* row.populated?.cpmProspects ? calculateTotal(row.populated.cpmProspects, r => r.params?.totalPrice || 0) : 0*/,
        });
      });
      console.log(res)
      setAllRecords(res);
    };
    sendRequest(1);
  };

  const initTotals = () => {
    const totals = { all: { totalPayout: 0 } };
    statuses.forEach((status) => {
      totals[status] = { totalPayout: 0 };
    });
    return totals;
  };

  const [stats, setStats] = useState(initTotals());

  const calculateTotals = () => {
    console.log('calculateTotals', dateFrom, dateTo, allRecords);
    const totals = initTotals();
    allRecords.forEach((row) => {
      console.log(row);
      const startDate = momentUTCToLocalDate(moment(row.startDate)).format('YYYY-MM-DD');
      const endDate = momentUTCToLocalDate(moment(row.endDate)).format('YYYY-MM-DD');
      if (startDate >= dateFrom && endDate >= dateFrom && startDate <= dateTo && endDate <= dateTo) {
        totals.all.totalPayout += row.amount;
        if (row.status && statuses.includes(row.status)) {
          totals[row.status].totalPayout += row.amount;
        }
      }
    });
    setStats(totals);
  };

  const recordsRef = useRef([]);
  // const deleteInsertionOrder = async (record) => {
  //   setRecordToRemove(record);
  // };
  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.search', searchValue);
    } else {
      search.delete('filters.search');
    }
    if (approvalStatusFilters && Object.keys(approvalStatusFilters).length > 0) {
      search.delete('filters.status');
      Object.keys(approvalStatusFilters).forEach((key) => {
        if (!approvalStatusFilters[key]) return false;
        search.append('filters.status', compareApprovalStatus[key]);
        return true;
      });
    }

    return search;
  };
  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const firstUpdate = useRef(true);
  const setUrl = () => {
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
    }
    // search.set('filters.allRequiredDataIsSet', 'true');
    history.push(`${history.location.pathname}?${search.toString()}`);
  };
  // const setUrlDebounced = useAsyncDebounce(() => {
  //   setUrl();
  // }, 800);
  const downloadCsv = () => {
    const search = getUrlSearch();
    goToPage(`/api/portal/prospects/get-invoice-csv?${search.toString()}`);
  };

  useEffect(fetchRecords, []);
  useEffect(calculateTotals, [allRecords, dateFrom, dateTo]);

  useEffect(() => {
    setUrl();
    // setUrlDebounced();
  }, [
    searchValue,
    approvalStatusFilters,
  ]);

  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'CPM Program', path: null },
          { title: 'Invoice', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Invoices</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Filter
            startDateInitial="1970-01-01"
            endDateInitial={new Date().toString()}
            selectedButtonInitial="all_time"
            setValue={(param, value) => {
              console.log('setDate');
              if (param === 'startDate') {
                setDateFrom(moment(value).startOf('day').format('YYYY-MM-DD'));
              }
              if (param === 'endDate') {
                setDateTo(moment(value).endOf('day').format('YYYY-MM-DD'));
              }
            }}
          />
          <Total stats={stats} />
          <Row>
            <Col>
              <Actions
                onSearchChange={onSearchChange}
                getUrlSearch={getUrlSearch}
                searchValue={searchValue}
                approvalStatusFilters={approvalStatusFilters}
                setApprovalStatusFilters={setApprovalStatusFilters}
                setVersion={setVersion}
                downloadCsv={downloadCsv}
              />
              <Row style={{ marginTop: '-10px' }}>
                <Table
                  action={action}
                  resource={resource}
                  dispatch={dispatch}
                  addNotice={addNotice}
                  version={version}
                  setVersion={setVersion}
                  setMetaTotal={setMetaTotal}
                  recordsRef={recordsRef}
                />
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <UploadInvoiceModal setVersion={setVersion} />
      <MoveContentToNextPeriodModal />
      <NewInsertionOrder updateTable={() => setVersion((new Date()).toString())} />
      <DeleteModalDialog afterSaveFunc={() => setVersion((new Date()).toString())} />
      <FileHistroryModal />
    </Container>
  );
};

CPMProspectsListContainer.propTypes = {
  addNotice: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withNotice(connect()(withRouter(CPMProspectsListContainer)));
