import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button, FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import withNotice from '../../../containers/App/store/with-notice';
import { s, formatIntNumber } from '../../helpers/WVFormatter';
import RadioButton from '../RadioButton';

const AssignModal = ({
  isOpen,
  closeFunc,
  setVersion,
  params,
  collection,
  ids,
  total,
  addNotice,
}) => {
  const {
    name,
    label,
    values,
    disableOption,
    updateUrl,
    useHistory,
  } = params;

  const notAssigned = { value: null, label: '-- Not Assigned --' };
  const options = values?.map(value => ({ value, label: value, isDisabled: disableOption ? disableOption(value) : false })) || [];
  options.unshift(notAssigned);

  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(notAssigned);
  const [includeAll, setIncludeAll] = useState(false);

  const totalToAssign = Math.min(total, 2500);

  useEffect(() => {
    if (!isOpen) {
      setSelectedOption(notAssigned);
      setIncludeAll(false);
    }
  }, [isOpen]);

  const submit = async () => {
    setLoading(true);
    const { value } = selectedOption;
    const query = new URLSearchParams(window.location.search);
    const url = updateUrl || `/api/portal/${collection.name}/post-update-many`;
    const paramsKey = useHistory ? 'paramsWithHistory' : 'params';
    axios({
      method: 'post',
      url,
      data: {
        ids,
        [paramsKey]: {
          [name]: value,
        },
        includeAll,
        query: query.toString(),
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        closeFunc();
      }
      if (response.data.error) {
        setLoading(false);
        addNotice({
          message: response.data.error,
          type: 'error',
          duration: 15,
        });
      }
    });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '500px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Assign {label}</ModalHeader>
      <ModalBody>
        {ids.length < totalToAssign && (
          <>
            <div>{`You have selected ${ids.length} ${collection.label}${s(ids.length)} to assign a ${label}.`}</div>
            <div className="theme-light radio-in-prospects">
              {`Would you like to select ${totalToAssign === total ? 'all' : 'the first'} ${formatIntNumber(totalToAssign)} ${collection.label}s?`}&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="action"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                name="action"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>
          </>
        )}
        <Row>
          <Col>
            <FormGroup>
              <Select
                value={selectedOption}
                className="assignProgramInput"
                options={options}
                onChange={setSelectedOption}
                placeholder=""
                isDisabled={!!loading}
                menuPlacement="auto"
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

AssignModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  setVersion: PropTypes.func.isRequired,
  params: PropTypes.objectOf(PropTypes.any).isRequired,
  collection: PropTypes.objectOf(PropTypes.any).isRequired,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  total: PropTypes.number.isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(AssignModal);
