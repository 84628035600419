/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import { Link } from 'react-router-dom';

export default class InfluencersInList extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { record } = this.props;

    const items = [];
    const itemsNames = {};

    let i = 0;
    while (record.params[`influencersArray.${i}.first_name`] || record.params[`influencersArray.${i}.last_name`]) {
      let name = record.params[`influencersArray.${i}.first_name`];
      if (record.params[`influencersArray.${i}.last_name`]) {
        // eslint-disable-next-line prefer-template
        name += ' ' + record.params[`influencersArray.${i}.last_name`];
      }

      let hsContactVid = null;
      if (record.params[`influencersArray.${i}.hsContactVid`]) {
        hsContactVid = record.params[`influencersArray.${i}.hsContactVid`];
      }

      const id = record.params[`influencersArray.${i}._id`];

      if (!itemsNames[name]) {
        itemsNames[name] = true;
        items.push({ name, hsContactVid, id });
      }
      i += 1;
    }


    return (
      <div>
        {items.map((item) => {
          return (
            <span key={`${item.name}`} style={{ display: 'block' }}>
              <Link to={`/influencer/profile/${item.id}`} target="_blank">{item.name}</Link>

              {item.hsContactVid ? (
                <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${item.hsContactVid}/`} target="_blank" rel="noreferrer">
                  <Tooltip title="HubSpot Contact">
                    <IconButton size="small">
                      <i className="fab fa-hubspot" style={{ fontSize: '0.8em' }} />
                    </IconButton>
                  </Tooltip>
                </a>
              ) : null}
            </span>
          );
        })}
      </div>
    );
  }
}
