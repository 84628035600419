import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import UserPropertyType from './index';
import TableCellContextMenu from './TableCellContextMenu';
import MatTableList from '../../../../../../../shared/tables/materialTable/MatTableList';
// import { getServerDate } from '../../../../../../../shared/helpers/WVFormatter';
import CreatorInList from './CreatorInList';
import ContentReleaseDate from './ContentReleaseDate';
import V30 from '../../../CpmProspects/components/custom/V30';
import { formatCurrency } from '../../../../../../../shared/helpers/WVFormatter';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { DeleteCpmCreatorModalId } from '../modals/DeleteCpmCreatorFromOrder';
import { modalId as refreshModalId } from '../../../CpmProspects/components/modals/RefreshStatisticsOptionsModal';

import { modalId as publishModalId } from '../../../CpmProspects/components/modals/PublishLookerProspect';

const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};
const aValues = {
  approved: 'Approved',
  inReview: 'In Review',
  rejected: 'Rejected',
};
const Table = React.memo(({
  action,
  resource,
  version,
  recordsRef,
  insertionOrderId,
}) => {
  const dispatch = useDispatch();
  console.log('REBUILD TABLE');
  return (
    <MatTableList
      action={action}
      resource={resource}
      date={new Date()}
      version={version}
      UserPropertyType={UserPropertyType}
      showFilters={false}
      filters={{
        cpmInsertionOrder: insertionOrderId,
      }}
      showTitle={false}
      sortBy="ioId"
      direction="asc"
      v={2}
      noActions
      isResizable={false}
      useFlex
      useCheckboxes
      recordsRef={recordsRef}
      enableWarning={false}
      notFoundProps={{ title: 'No CPM Deals Found' }}
      useUnflatten
      customColumns={[
          {
            id: 'fullSearchName',
            Header: () => 'Creator',
            accessor: record => (record?.params?.name || ''),
            Cell: p => (
              <CreatorInList
                record={p.row.original}
              />
            ),
            width: 200,
            className: 'd-flex align-items-center',
          },
          {
            id: 'mediaUrl',
            Header: () => 'Channel Name',
            accessor: record => (record?.params?.mediaUrl || ''),
            Cell: p => useMemo(() => {
              const { contact } = p.row.original?.populated;
              const { tubularDetails } = p.row.original?.params;
              let youtubeAccount = null;

              if (tubularDetails && tubularDetails.accounts) {
                youtubeAccount = tubularDetails.accounts.find(account => account.platform === 'youtube');
              }
              if (!youtubeAccount && contact?.params && contact.params.mediaChannels?.youtube_channel_name && contact.params.mediaChannels?.youtube_channel_url) {
                youtubeAccount = {
                  title: contact.params.mediaChannels.youtube_channel_name,
                  url: contact.params.mediaChannels.youtube_channel_url,
                };
              }
              console.log(youtubeAccount, 'youtubeAccount');
              return youtubeAccount ? (
                <div>
                  {youtubeAccount.title}
                  <a
                    title="Show Media Url"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={youtubeAccount.url}
                  >
                    {' '}<i className="fab fa-youtube" />
                  </a>
                </div>
              ) : '';
            }, [p.row.original]),
            width: 150,
            className: 'd-flex align-items-center',
            disableSortBy: true,
          },
          {
            id: 'ioId',
            Header: () => 'ID',
            accessor: record => (record?.params?.ioId || ''),
            Cell: p => p.row.original.params.ioId || '',
            width: 80,
            className: 'd-flex align-items-center',
          },
          {
            id: 'cpmInvoice',
            Header: () => 'Invoice No.',
            accessor: record => (record?.params?.cpmInvoice || ''),
            Cell: p => p.row.original.populated?.cpmInvoice?.params?.invoiceNumber || '',
            width: 100,
            className: 'd-flex align-items-center',
            sortable: true,
          },
          {
            id: 'dealObj.dealstage',
            Header: () => 'Deal Stage',
            accessor: record => (record?.params?.dealObject?.dealstage || ''),
            Cell: p => useMemo(() => {
              const { pipelines } = window.constants.deal;
              const { pipeline } = p.row.original.populated?.deal?.params || {};
              const dealstages = pipeline ? pipelines.find(pr => pr.id.toString() === pipeline.toString()) : null;
              console.log(p.value, dealstages);
              return (
                <div style={{ width: '120px' }}>
                  {dealstages ? dealstages.stages[p.value] : '-'}
                  {p.row.original.populated?.deal?.params?.hs_object_id && (
                    <a
                      title="Show Deal"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original.populated?.deal?.params.hs_object_id}`}
                    >
                      {' '}
                      <i className="fab fa-hubspot" />
                    </a>
                  )}
                </div>
              );
            }, [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
            disableSortBy: true,
          },
          {
            id: 'approvalStatus.status',
            Header: () => 'Status',
            accessor: record => (record?.params?.approvalStatus.status ? aValues[record?.params?.approvalStatus.status] : ''),
            Cell: p => p.value || '',
            width: 100,
            className: 'd-flex align-items-center',
          },
          {
            id: 'contentReleaseDate.currentDate',
            Header: () => 'Content Release Date',
            accessor: record => (record?.params?.contentReleaseDate?.currentDate || ''),
            Cell: p => useMemo(() => <ContentReleaseDate record={p.row.original} />, [p.row.original]),
            className: 'd-flex align-items-center',
            width: 150,
          },
          {
            id: 'code',
            Header: () => 'Code',
            accessor: record => (record?.populated?.deal?.params?.dealTerms.coupon_code || ''),
            Cell: p => (<span style={{ wordBreak: 'break-word' }} >{p.value}</span>),
            className: 'd-flex align-items-center',
            disableSortBy: true,
            width: 80,
          },
          {
            id: 'v30',
            Header: () => 'V30',
            accessor: record => (record?.params?.v30 || ''),
            Cell: p => useMemo(() => (
              <V30
                record={p.row.original}
                updateRecordFunction={p.updateRecordFunction}
                showSync={p.row.original?.populated?.cpmInsertionOrder.params.status.status === 'New'}
              />
            ), [p.row.original]),
            className: 'd-flex align-items-center',
            width: 120,
          },
          {
            id: 'totalPrice',
            Header: () => 'Approved Amount',
            accessor: record => (record?.params?.totalPrice || 0),
            Cell: p => formatCurrency(Number(p.value || 0)),
            className: 'd-flex align-items-center',
            width: 150,
          },
          {
            id: 'cpm',
            Header: () => 'CPM',
            accessor: record => (record?.params?.cpm || 0),
            Cell: p => formatCurrency(Number(p.value || 0)),
            className: 'd-flex align-items-center',
            width: 70,
          },
          {
            Header: '',
            accessor: () => null,
            id: 'cpm_prospect_actions_delete',
            width: 50,  
            Cell: (p) => {
              const contextMenuOptions = [
                { label: 'View Creator Profile', disabled: !p.row.original?.params?.influencer, handler: () => goToPage(`/influencer/profile/${p.row.original?.params?.influencer}`) },
                { label: 'Edit Deal', disabled: !p.row.original?.params?.deal, handler: () => goToPage(`/influencers/creator-deal-editor/${p.row.original?.params?.deal}`) },
                { type: 'divider', label: 'divider1' },
                { label: 'View HubSpot Contact', disabled: !p.row.original?.params?.contact, handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.row.original?.params?.hsUserId}`) },
                { label: 'View HubSpot Deal', disabled: !p.row.original?.params?.deal, handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original?.params?.hsDealId}`) },
                { type: 'divider', label: 'divider2' },
                { label: 'Refresh Statistics', handler: () => dispatch(showModalById(refreshModalId, { record: p.row.original })) },
                { type: 'divider', label: 'divider3' },
                { label: 'Publish Looker Update Data', handler: () => dispatch(showModalById(publishModalId, { record: p.row.original })), disabled: (!p.row.original?.populated?.cpmInsertionOrder) },
                { type: 'divider', label: 'divider4' },
                { label: 'Delete', handler: () => dispatch(showModalById(DeleteCpmCreatorModalId, { record: p.row.original })), disabled: (p.row.original?.populated?.cpmInsertionOrder?.params?.status?.status === 'Signed') },

              ];
              return (
                <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                  <TableCellContextMenu options={contextMenuOptions} />
                </div>
              );
            },
            disableSortBy: true,
            className: 'd-flex align-items-center align-middle',
          },
        ]}
    />
  );
}, (a, a1) => _.isEqual(a.version, a1.version));

Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  recordsRef: PropTypes.any.isRequired,
  insertionOrderId: PropTypes.string.isRequired,
};

export default Table;
