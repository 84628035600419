import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { axios } from 'ApiClient';
import { Modal, ModalHeader, Row, Col, Spinner } from 'reactstrap';
import { closeUpdateLogoDialog } from '../../../../../../redux/reducers/admin/AdvertiserReducer';
import DropZoneField from '../../../../../../shared/components/form/DropZone';
import { StyledButton } from '../../../../../../shared/components/components/ui';
import { addNotice } from '../../../../../App/store/store';

const UploadLogoModalInList = ({
  showUpdateLogoDialog,
  brandLogo,
  dispatch,
  handleRefresh,
}) => {
  const [logo, setLogo] = useState([]);
  const [logoSquare, setLogoSquare] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showUpdateLogoDialog) {
      if (brandLogo.logoUrl) {
        setLogo([{
          name: brandLogo.logoUrl,
          path: brandLogo.logoUrl,
          preview: brandLogo.logoUrl,
        }]);
      } else {
        setLogo([]);
      }
      if (brandLogo.logoSquareUrl) {
        setLogoSquare([{
          name: brandLogo.logoSquareUrl,
          path: brandLogo.logoSquareUrl,
          preview: brandLogo.logoSquareUrl,
        }]);
      } else {
        setLogoSquare([]);
      }
    }
  }, [showUpdateLogoDialog]);

  const closeDialog = () => {
    setLogo([]);
    setLogoSquare([]);
    dispatch(closeUpdateLogoDialog());
  };

  const handleChangeLogo = (files) => {
    setLogo(files);
  };

  const handleChangeLogoSquare = (files) => {
    setLogoSquare(files);
  };

  const onSubmit = () => {
    setLoading(true);
    const data = new FormData();
    data.append('logoFile', logo[0]);
    data.append('logoSquareFile', logoSquare[0]);
    data.append('brand', brandLogo.id);
    axios({
      method: 'post',
      url: '/api/portal/brands/save-brand-logo',
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    }).then((response) => {
      setLoading(false);
      // console.log("SUCCESS RESPONSE", response);
      if (response.data.success) {
        dispatch(addNotice({
          message: 'Brand logo has been updated successfully.',
          type: 'success',
        }));
      } else {
        dispatch(addNotice({
          message: response.data.error,
          type: 'error',
        }));
      }
      closeDialog();
      handleRefresh();
    }).catch((error) => {
      console.log(error.message);
    });
  };

  return (
    <Modal
      isOpen={showUpdateLogoDialog}
      toggle={closeDialog}
    >
      <ModalHeader toggle={closeDialog} tag="h3" className="modal-title-statistics">Upload Logos</ModalHeader>
      {/* eslint-disable-next-line react/no-danger */}
      <div className="modal__body">
        <Row style={{ marginTop: '10px' }} className="modal-row-padding">
          <Col>
            <h4 className="il-color-deep-blue font-weight-bold mt-5">Full Logo Image</h4>
            <hr className="mt-3 mb-4" />
            <DropZoneField
              input={{
                name: 'logo',
                id: 'logo',
                value: logo,
                onChange: handleChangeLogo,
              }}
              customHeight
            />
            <h4 className="il-color-deep-blue font-weight-bold mt-5">Square Logo Image</h4>
            <hr className="mt-3 mb-4" />
            <DropZoneField
              input={{
                name: 'logoSquare',
                id: 'logoSquare',
                value: logoSquare,
                onChange: handleChangeLogoSquare,
              }}
              customHeight
            />
          </Col>
        </Row>
      </div>
      <Row className="modal__footer" style={{ marginTop: '30px' }}>
        <StyledButton
          className="btn btn-primary btn-sm"
          onClick={onSubmit}
          style={{ margin: '0 10px 0 10px' }}
        >
          {loading && (<><Spinner color="primary" size="sm" />&nbsp;&nbsp;</>)}
          <i className="icomoon-save" />
          <span className="btn-text">Save</span>
        </StyledButton>
        <StyledButton
          className="btn btn-primary btn-sm"
          onClick={closeDialog}
          style={{ margin: '0 10px 0 10px' }}
        >
          Close
        </StyledButton>
      </Row>
    </Modal>
  );
};


UploadLogoModalInList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  showUpdateLogoDialog: PropTypes.bool.isRequired,
  brandLogo: PropTypes.objectOf(PropTypes.any).isRequired,
  handleRefresh: PropTypes.func,
};

UploadLogoModalInList.defaultProps = {
  handleRefresh: null,
};

const mapStateToProps = state => ({
  showUpdateLogoDialog: state.advertiserReducer.showUpdateLogoDialog,
  brandLogo: state.advertiserReducer.brandLogo,
});

export default connect(mapStateToProps, null)(UploadLogoModalInList);
