import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MoneyIcon from 'mdi-react/MoneyIcon';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { axios } from 'ApiClient';
import {
  changeCurrentCpmProspectId,
  changeCurrentCpmProspectName,
} from '../../../../../../../redux/reducers/admin/ResearchReducer';

const DealInList = React.memo(({
  record,
  openDealsModal,
  dispatch,
}) => {
  const [deals, setDeals] = useState([]);
  const [title, setTitle] = useState('View Contact\'s Deals');

  const getDeals = async () => {
    if (record.params.hsUserId) {
      try {
        const response = await axios.get(`/api/portal/prospects/get-deals-for-cpm-prospect?hsUserId=${record.params.hsUserId}&prospectId=${record.params._id}`);
        if (response?.data?.deals && response.data.deals.length > 0) {
          setDeals(response.data.deals);
        }
      } catch (e) {
        setDeals([]);
        console.log(e.message);
      }
    }
  };

  useEffect(() => {
    if (record.params?.hsUserId) {
      getDeals();
    } else setDeals([]);
    if (record.params?.cpmInsertionOrder) {
      setTitle('Creator prospect is already associated with an Insertion Order. To change this deal, please remove the creator prospect from the Insertion Order.');
    } else setTitle('View Contact\'s Deals');
  }, [record.params._id]);

  return (
    <span>
      {record?.params?.dealObject?.dealname || 'Not Assigned'}
      {' '}
      {deals?.length > 0 && (
        <Tooltip
          title={title}
        >
          <span>
            <IconButton
              size="small"
              onClick={() => {
                dispatch(changeCurrentCpmProspectId(record.params?._id || ''));
                dispatch(changeCurrentCpmProspectName(record.params?.contactName?.trim() ? record.params.contactName : 'CPM Creator'));
                openDealsModal(deals);
              }}
              className="material-table__toolbar-button"
              style={{ color: '#70bbfd' }}
              disabled={!!record.params?.cpmInsertionOrder}
            >
              <MoneyIcon size="24" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </span>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

DealInList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  openDealsModal: PropTypes.func.isRequired,
};

export default connect()(DealInList);
