import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';

const HumanBrandRelevancy = ({ filterBrandRelevant, handleChangeBrandRelevantFilter }) => (
  <FormGroup>
    <Label className="bold-text">
      Human Brand Relevancy&nbsp;
      {/* <Tooltip title="Info for Human Brand Relevancy">
                      <IconButton
                        size="small"
                        onClick={() => false}
                        className="material-table__toolbar-button"
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </IconButton>
                    </Tooltip> */}
    </Label>
    <div>
      <CheckBoxField
        name="UnreviewedBrand"
        label="Unreviewed"
        className="inline"
        value={filterBrandRelevant.unreviewed}
        onChange={event => handleChangeBrandRelevantFilter(event, 'unreviewed')}
      />
      <CheckBoxField
        name="RelevantBrand"
        label="Relevant"
        className="inline ml-4"
        value={filterBrandRelevant.relevant}
        onChange={event => handleChangeBrandRelevantFilter(event, 'relevant')}
      />
      <CheckBoxField
        name="IgnoreBrand"
        label="Ignore"
        className="inline ml-4"
        value={filterBrandRelevant.ignore}
        onChange={event => handleChangeBrandRelevantFilter(event, 'ignore')}
      />
    </div>
  </FormGroup>
);

HumanBrandRelevancy.propTypes = {
  filterBrandRelevant: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeBrandRelevantFilter: PropTypes.func.isRequired,
};

export default HumanBrandRelevancy;
