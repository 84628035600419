import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const getStatus = (record) => {
  if (record?.requirements?.disabled_reason !== null) {
    return 'Restricted';
  } else if (record?.requirements?.eventually_due?.length > 0) {
    return 'Enabled';
  }
  return 'Complete';
}

const getColor = (record) => {
  if (record?.requirements?.disabled_reason !== null) {
    return '#962448'; // #FF4861FF
  } else if (record?.requirements?.eventually_due?.length > 0) {
    return '#015097'; // #70bbfd #165290
  }
  return '#11600f'; // #28a745
}

const StatusComponent = ({
  record,
}) => {
  const [status, setStatus] = useState(getStatus(record));
  const [color, setColor] = useState(getColor(record));

  useEffect(() => {
    setStatus(getStatus(record))
    setColor(getColor(record))
  }, [record]);

  return (
      <span style={{ color }}>{status}</span>
    );
}

StatusComponent.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default StatusComponent;
