import React, { useEffect, useState } from 'react';
import { axios } from 'ApiClient';
// import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import { InputGroup } from 'reactstrap';
import { NumericFormat as NumberFormat } from 'react-number-format';
import _ from 'lodash';

const NumberOfVideos = React.memo(({
  record, updateRecordFunction,
}) => {
  const [numVideos, setNumVideos] = useState(record.params?.numVideos?.numVideos || 0);
  const [loading, setLoading] = useState(false);
  const [changedAmount, setChangedAmount] = useState(undefined);

  useEffect(() => {
    setNumVideos(record.params?.numVideos?.numVideos || 0);
  }, [record]);

  const handleChange = async () => {
    if (typeof changedAmount !== 'undefined') {
      setLoading(true);
      const { id } = record;
      const data = await axios.post('/api/portal/prospects/post-update-property-on-cpm-prospect', {
        ids: [id],
        values: {
          numVideos: changedAmount,
        },
      });
      setLoading(false);
      if (data?.data?.recordPopulated && updateRecordFunction) {
        updateRecordFunction(data?.data?.recordPopulated);
      }
    }
    return true;
  };

  return (
    <InputGroup>
      <NumberFormat
        thousandSeparator
        prefix=""
        name="numVideos"
        value={numVideos}
        className="form-control"
        inputMode="numeric"
        allowEmptyFormatting
        fixedDecimalScale
        decimalScale={0}
        disabled={loading}
        defaultValue={0}
        onBlur={handleChange}
        onValueChange={(values) => {
          setChangedAmount(values.floatValue);
          setNumVideos(values.floatValue);
        }}
      />
    </InputGroup>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

NumberOfVideos.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};

export default NumberOfVideos;
