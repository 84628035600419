import React from 'react';
import { useDispatch } from 'react-redux';
import { getLanguageNameFromIso } from '../../../../../../../shared/helpers/WVUtilities';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import TableCellContextMenu from '../../../Prospects/components/custom/TableCellContextMenu';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { modalId } from '../modals/KeywordsHashtags';
import { modalId as modalApprovedId } from '../modals/ShowRejectedApprovedBrands';
import { modalId as modalHubspotDealsId } from '../modals/ShowHubspotDeals';
import { PickIcon, ShieldIcon, AddressIcon } from '../../../../../../../shared/helpers/Icons';

const iconsArray = (video, dispatch) => {
  const icons = [];
  if (video.row.original.prospects?.length) {
    icons.push((
      <a
        title="Open Prospects"
        target="_blank"
        rel="noopener noreferrer"
        className="mr-3"
        style={{ fontSize: '18px' }}
        href={`/research/internal-prospects?${video.row.original.prospects.map(p => `filters.prospectId=${p._id}`).join('&')}`}
      >
        <PickIcon />
      </a>));
  }
  if (video.row.original.hsUserId) {
    icons.push((
      <a
        title="Show HubSpot Contact"
        target="_blank"
        rel="noopener noreferrer"
        className="mr-3"
        style={{ fontSize: '18px' }}
        href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${video.row.original.hsUserId}`}
      >
        <i className="fab fa-hubspot" />
      </a>));
  }
  if (video.row.original.influencer) {
    icons.push((
      <a
        title="Creator Profile"
        target="_blank"
        rel="noreferrer"
        className="mr-3"
        href={`/influencer/profile/${video.row.original.influencer}`}
        style={{ fontSize: '18px' }}
      >
        <AddressIcon />
      </a>));
  }

  if (video.row.original.prospects?.length) {
    icons.push((
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        tabIndex={0}
        title="Show Approved Rejected Brands"
        className="mr-3"
        onClick={() => {
          dispatch(showModalById(modalApprovedId, { record: video.row.original }));
        }}
        onKeyUp={() => {}}
        style={{ fontSize: '20px' }}
      >
        <ShieldIcon />
      </a>));
  }
  return icons;
};


export default function ResearchVideoColumnsInSearchTable(currentWatchlistId, chooseSearchItems, addItemsToTheProspects, showErrorNotice) {
  const dispatch = useDispatch();
  return [
    {
      Header: '',
      accessor: () => null,
      id: 'title',
      className: '',
      Cell: video => (
        <>
          <div className="d-flex">
            {video.row.original.thumbnail_url && (
              <img
                className="my-auto"
                style={{
                  height: '65px',
                  width: 'auto',
                  marginRight: '10px',
                }}
                src={video.row.original.thumbnail_url}
                alt=""
              />
            )}
            <div className="my-auto">
              <b>
                <a
                  className="research-video-title"
                  href={video.row.original.video_url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {video.row.original.title}
                </a>
              </b>
              <span style={{ fontSize: '13px', color: '#aaa' }}>
                {typeof video.row.original.publisher !== 'undefined' && video.row.original.publisher !== null ? `by ${video.row.original.publisher.creator_name} | ` : ''}
                {typeof video.row.original.language !== 'undefined' && video.row.original.language !== null ? getLanguageNameFromIso(video.row.original.language) : ''}
              </span>
              {(video.row.original.hsUserId || video.row.original.influencer || video.row.original.prospects?.length) ? (
                <br />
              ) : null}
              {iconsArray(video, dispatch)}
            </div>
          </div>
        </>),
      disableSortBy: true,
      minWidth: 45,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'video_platforms',
      className: '',
      Cell: (video) => {
        let imgPath = '';
        if (video.row.original.platform === 'youtube') {
          imgPath = '/images/mediacontent/youtube.png';
        } else if (video.row.original.platform === 'facebook') {
          imgPath = '/images/mediacontent/facebookWatchVideo.png';
        } else if (video.row.original.platform === 'instagram') {
          imgPath = '/images/mediacontent/instagram.png';
        } else if (video.row.original.platform === 'twitter') {
          imgPath = '/images/mediacontent/twitter.png';
        } else if (video.row.original.platform === 'twitch') {
          imgPath = '/images/mediacontent/twitch.png';
        }
        return (
          <div style={{ textAlign: 'center' }}>
            <img style={{ maxWidth: '30px' }} src={imgPath} alt="" />
          </div>);
      },
      disableSortBy: true,
      minWidth: 45,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'published',
      className: '',
      Cell: video => (
        <div style={{ textAlign: 'center' }}>
          <b>Published on</b><br />
          {WVFormatter.getFormatedDate(video.row.original.publish_date)}
        </div>),
      disableSortBy: true,
      minWidth: 45,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'views',
      className: '',
      Cell: video => (
        <div style={{ textAlign: 'center' }}>
          <b>Views</b><br />
          {WVFormatter.formatIntNumber(video.row.original.views)}
        </div>),
      disableSortBy: true,
      minWidth: 45,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'category',
      className: '',
      Cell: video => (
        <div style={{ textAlign: 'center' }}>
          <b>YouTube Category</b><br />
          {video.row.original.youtube_category}
        </div>),
      disableSortBy: true,
      minWidth: 45,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'actions',
      className: '',
      Cell: (video) => {
        const contextMenuOptions = [
          {
            label: 'Show Keywords & Hashtags',
            handler: () => {
              // eslint-disable-next-line no-unused-expressions
              // chooseSearchItems([video.row.original?.publisher?.creator_id], currentWatchlistId) :
              dispatch(showModalById(modalId, { record: video.row.original }));
            },
          },
          {
            label: 'Show HubSpot Deals',
            disabled: !video.row.original.hsUserId,
            handler: () => {
              // eslint-disable-next-line no-unused-expressions
              // chooseSearchItems([video.row.original?.publisher?.creator_id], currentWatchlistId) :
              dispatch(showModalById(modalHubspotDealsId, { record: video.row.original }));
            },
          },
          {
            label: 'Add to Watchlist',
            handler: () => {
              // eslint-disable-next-line no-unused-expressions
              video.row.original?.publisher?.creator_id ?
                chooseSearchItems([video.row.original?.publisher?.creator_id], currentWatchlistId) :
                showErrorNotice('Sorry, we are missing an identifier for the creator of this content so we can not save this item to a watchlist.');
            },
          },
          {
            label: 'Add to Prospects',
            handler: () => {
              // eslint-disable-next-line no-unused-expressions
              video.row.original?.publisher?.creator_id ?
                addItemsToTheProspects(video.row.original?.publisher?.creator_id) :
                showErrorNotice('Creator ID information is missing and cannot be added to a watchlist or Prospects.');
            },
          },
        ];
        return (
          <div style={{ textAlign: 'center' }}>
            {video.row.original.loading === true && (
              <>
                <br />
                <span className="pr-2">
                  <div className="spinner-border spinner-border-sm" />
                </span>
              </>
            )}
            {video.row.original.loading === 'saved' && (
              <>
                <br />
                Saved to the Watchlist
                <br />
              </>
            )}
            {(typeof video.row.original.loading === 'undefined'
              || (video.row.original.loading !== true && video.row.original.loading !== 'saved')
            ) && (
              <>
                <TableCellContextMenu options={contextMenuOptions} />
              </>
            )}
          </div>);
      },
      disableSortBy: true,
    },
  ];
}
