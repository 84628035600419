import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { Link } from 'react-router-dom';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import Search from '../filter/Search';
import { setCookie } from '../../helpers/WVUtilities';

const Panel = ({
  name,
  show,
  setShow,
  search,
  onSearchChange,
  searchPlaceholder,
  reset,
  actions,
}) => {
  useEffect(() => {
    if (typeof show !== 'undefined') {
      setCookie(`${name}ShowFilters`, show.toString(), 35);
    }
  }, [show]);

  return (
    <Row className="mt-4">
      <Col md={8} className="mb-3">
        <Search
          placeholder={searchPlaceholder}
          value={search}
          onChange={onSearchChange}
        />
      </Col>
      <Col md={4}>
        <Row>
          <Col md={4} style={{ paddingRight: '0' }}>
            {typeof show !== 'undefined' && (
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={reset}
                  style={{
                    width: '100%',
                    maxWidth: '160px',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            )}
          </Col>
          <Col md={2} style={{ paddingLeft: '24px' }}>
            {typeof show !== 'undefined' && (
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShow(!show)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            )}
          </Col>
          <Col md={6} className="text-right">
            {actions?.length > 0 && (
              <UncontrolledButtonDropdown className="w-100">
                <DropdownToggle
                  id="actionsMenu"
                  caret
                  color="primary"
                  style={{
                    padding: '4px 25px',
                    height: '42px',
                  }}
                >
                  Actions
                </DropdownToggle>
                <DropdownMenu right>
                  {actions.map((row, i) => (row.to ? (
                    <DropdownItem key={uid(i)} tag={Link} to={row.to}>{row.label}</DropdownItem>
                  ) : (
                    <DropdownItem key={uid(i)} onClick={row.onClick}>{row.label}</DropdownItem>
                  )
                  ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Panel.propTypes = {
  name: PropTypes.string.isRequired,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  search: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

Panel.defaultProps = {
  show: undefined,
  setShow: () => {},
};

export default Panel;
