/* eslint-disable no-prototype-builtins,react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import PlaylistPlusIcon from 'mdi-react/PlaylistPlusIcon';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import withNotice from '../../../../../../../App/store/with-notice';
import {
  getBrandSafetySearchTerms,
  updateBrandSafetySearchTerms,
} from '../../../../../../../../redux/reducers/admin/ResearchReducer';
import BrandSafetySearchTermsListItem from '../BrandSafetySearchTermsListItem';

const BrandSafetySearchTermsModal = ({
  dispatch,
  addNotice,
  isOpen,
  toggleModal,
  loadingBrandSafetySearchTerms,
  brandSafetySearchTerms,
}) => {
  const [showAddField, setShowAddField] = useState(false);
  const [localBrandSafetySearchTerms, setLocalBrandSafetySearchTerms] = useState([]);
  const [errors, setErrors] = useState({});
  const [newSearchTermInput, setNewSearchTermInput] = useState('');
  const [enableNegativeMarginOnTermsList, setEnableNegativeMarginOnTermsList] = useState(false);

  const messagesEndRef = useRef(null);
  const termsListRef = useRef(null);

  const scrollToBottom = () => {
    if (!loadingBrandSafetySearchTerms && localBrandSafetySearchTerms.length > 0) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setLocalBrandSafetySearchTerms(brandSafetySearchTerms);
    scrollToBottom();
  }, [brandSafetySearchTerms]);

  useEffect(() => {
    setEnableNegativeMarginOnTermsList(termsListRef.current && termsListRef.current.scrollHeight > termsListRef.current.clientHeight);
    scrollToBottom();
  }, [localBrandSafetySearchTerms]);

  useEffect(async () => {
    if (isOpen) {
      dispatch(getBrandSafetySearchTerms());
    }
  }, [isOpen]);

  const onNewSearchTermChange = (newSearchTerm) => {
    setNewSearchTermInput(newSearchTerm);
    setErrors({ ...errors, newSearchItemInput: (newSearchTerm && newSearchTerm.trim()) ? '' : 'Please enter a search term' });
  };

  const addNewSearchTerm = () => {
    if (newSearchTermInput) {
      setLocalBrandSafetySearchTerms([...localBrandSafetySearchTerms, newSearchTermInput]);
      setTimeout(() => {
        setShowAddField(false);
        setNewSearchTermInput('');
        scrollToBottom();
      }, 250);
    } else {
      setErrors({ ...errors, newSearchItemInput: 'Please enter a search term' });
    }
  };

  const deleteSearchTerm = (index) => {
    if (localBrandSafetySearchTerms && typeof localBrandSafetySearchTerms[index] !== 'undefined') {
      const newLocalBrandSafetySearchTerms = [...localBrandSafetySearchTerms];
      newLocalBrandSafetySearchTerms.splice(index, 1);
      setLocalBrandSafetySearchTerms(newLocalBrandSafetySearchTerms);
    } else {
      addNotice({
        message: 'Sorry, something went wrong. Please refresh the page and try again.',
        type: 'error',
      });
      console.error(`Missing array element: localBrandSafetySearchTerms[${index}]`);
      console.log('localBrandSafetySearchTerms array: ', localBrandSafetySearchTerms);
    }
  };

  const updateSearchTerm = (term, index) => {
    if (localBrandSafetySearchTerms && typeof localBrandSafetySearchTerms[index] !== 'undefined') {
      const newLocalBrandSafetySearchTerms = [...localBrandSafetySearchTerms];
      newLocalBrandSafetySearchTerms.splice(index, 1, term && term.trim() ? term.trim() : '');
      setLocalBrandSafetySearchTerms(newLocalBrandSafetySearchTerms);
    } else {
      addNotice({
        message: 'Sorry, something went wrong. Please refresh the page and try again.',
        type: 'error',
      });
      console.error(`Missing array element: localBrandSafetySearchTerms[${index}]`);
      console.info('localBrandSafetySearchTerms array: ', localBrandSafetySearchTerms);
    }
  };

  const saveSearchTerms = async () => {
    dispatch(updateBrandSafetySearchTerms({ brandSafetySearchTerms: [...new Set(localBrandSafetySearchTerms.filter(el => el))] }));
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
      size="lg"
      style={{
        maxWidth: '900px',
        fontSize: '105%',
        fontStretch: '90%',
      }}
    >
      <div className="modal__header mb-0">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
        <h4 className="text-modal modal__title mb-1">
          Brand Safety Search Terms
        </h4>
      </div>
      <div
        className="modal__body mb-0"
      >
        {showAddField
          ? (
            <FormGroup
              className="mt-2"
              style={{ padding: '5px' }}
            >
              <InputGroup size="md">
                <Input
                  id="newSearchItemInput"
                  name="newSearchItemInput"
                  type="text"
                  bsSize="md"
                  placeholder="Enter new search term"
                  invalid={typeof errors.newSearchItemInput !== 'undefined' && errors.newSearchItemInput !== ''}
                  onChange={event => onNewSearchTermChange(event?.target?.value)}
                  onKeyPress={(event) => { if (event.key === 'Enter') addNewSearchTerm(); }}
                  value={newSearchTermInput}
                />
                <InputGroupAddon addonType="append">
                  <Tooltip title="Add new Search Term to the List">
                    <Button
                      disabled={typeof errors.newSearchItemInput !== 'undefined' && errors.newSearchItemInput !== ''}
                      style={{
                        maxHeight: '38px',
                        border: '1px solid #70bbfd',
                        borderRadius: '0 0.25rem 0.25rem 0',
                        paddingRight: '0',
                      }}
                      color="primary"
                      className="form__form-group-button mb-0"
                      onClick={addNewSearchTerm}
                    ><PlaylistPlusIcon />
                    </Button>
                  </Tooltip>
                </InputGroupAddon>
                <FormFeedback>{errors.newSearchItemInput}</FormFeedback>
              </InputGroup>
            </FormGroup>
          )
          : (
            <div
              className="mt-2"
              style={{ height: '38px', marginRight: '5px' }}
            >
              <Button
                color="primary"
                style={{ float: 'right', paddingTop: '8px', paddingBottom: '7px' }}
                onClick={() => setShowAddField(true)}
              >
                Add Search Term
              </Button>
            </div>
          )
        }
        <h4>
          Search Terms
        </h4>
        <hr className="mt-2 mb-3" />
        {loadingBrandSafetySearchTerms && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
        {!loadingBrandSafetySearchTerms && localBrandSafetySearchTerms.length === 0 && (
          <span>Safety Terms list is empty.</span>
        )}
        {!loadingBrandSafetySearchTerms && localBrandSafetySearchTerms.length > 0 && (
          <div
            className={`tokens-list${enableNegativeMarginOnTermsList ? ' tokens-list-negative-margin' : ''}`}
            style={{ padding: '5px', maxHeight: '400px', overflowY: 'auto' }}
            ref={termsListRef}
          >
            {localBrandSafetySearchTerms.map((term, index) => (
              <BrandSafetySearchTermsListItem
                key={`brandSafetySearchTermsListItem${index}`}
                deleteTerm={() => deleteSearchTerm(index)}
                updateTerm={(searchTerm, indexInList) => updateSearchTerm(searchTerm, indexInList)}
                indexInList={index}
                brandSafetySearchTerm={term}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
        )
        }
      </div>
      <ButtonToolbar className="modal__footer mt-3">
        <Button color="primary" onClick={saveSearchTerms}>{loadingBrandSafetySearchTerms && (<span><div className="spinner-border text-light spinner-border-sm" />  </span>)}Save</Button>
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

BrandSafetySearchTermsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  loadingBrandSafetySearchTerms: PropTypes.bool.isRequired,
  brandSafetySearchTerms: PropTypes.arrayOf(PropTypes.string).isRequired,
};

BrandSafetySearchTermsModal.defaultProps = {
  isOpen: false,
};


const mapStateToProps = state => ({
  loadingBrandSafetySearchTerms: state.ResearchReducer.loading.brandSafetySearchTerms,
  brandSafetySearchTerms: state.ResearchReducer.brandSafetySearchTerms,
});

export default connect(mapStateToProps)(withNotice(BrandSafetySearchTermsModal));
