import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NoResourceError, NoActionError } from '../../../../../shared/components/components/ui/error404';
import UploadQueueListContainer from './components/UploadQueueListContainer';

const UploadQueueList = (props) => {
  const { resources, history } = props;

  const resource = resources.find(r => r.id === 'Queuing');
  if (!resource) {
    return (<NoResourceError resourceId="Queuing" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="Queuing" actionName="list" />);
  }
  return (
    <UploadQueueListContainer
      action={action}
      history={history}
      resource={resource}
    />
  );
};
UploadQueueList.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(UploadQueueList);
