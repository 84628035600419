import React, { useCallback, useState, useEffect, useMemo } from 'react';
// import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import MatTableList from '@/shared/tables/materialTable/TableRedux';
// import formater from '../../../../../../../shared/helpers/WVFormatter';
import CreatorInList from './CreatorInList';
import { getConstant } from '@/shared/helpers/WVConstants';
// import TableCellContextMenu from '@/shared/components/table/TableCellContextMenu';
import { Button, Card, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import Hook from 'ILHooks';
import { axios } from 'ApiClient';
import { formatCurrency, formatDate } from '@/shared/helpers/WVFormatter';
// import PaymentMethod from './PaymentMethod';
// import Payments from './Payments';
// import Hook from '@/shared/hooks';

const DealStage = ({ record }) => {
  const dealstages = (getConstant('deal', 'pipelines').find(p => p.label === 'Main Pipeline')).stages;
  const r = record;
  let dealStage = '-';
  if (r.params.dealObject && r.params.dealObject.dealstage) {
    dealStage = '-';

    if (dealstages[r.params.dealObject.dealstage]) dealStage = dealstages[r.params.dealObject.dealstage];
  }
  return (
    <div>
      { dealStage }
    </div>
  );
};
DealStage.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
const removeMergedDeal = async ({ parentInfluencerId, influencerId }) => {
  try {
    await axios.post('/api/portal/finance/post-remove-merged-influencers', {
      parentInfluencerId, influencerId
    })
  } catch (e) {

  }
  
}

const  CardToGenerateItem = ({ title, array}) => (
  <Card
    style={{
      border: '1px solid #eee',
      paddingBottom: '0px',
      marginBottom: '10px'
    }}
  >
    <CardHeader>
      {title}
    </CardHeader>
    <ListGroup flush>
      {array?.length > 0 ? array.map((a) => (
        <ListGroupItem>
         {a}
        </ListGroupItem>
      )) : (
        <ListGroupItem>
        No Data
        </ListGroupItem>
      )}
      
    </ListGroup>
  </Card>
);
const DealItem = ({ record, showModal, item, success, offers }) => {
  
  const text = <>
    When you do that action, we will do the following:
    <ol>
        
    </ol>
  </>
  const bill = item.bills.find(b => String(b.influencer) === String(record._id));
  const contact = item.contacts.find(b => String(b.hs_object_id) === String(record.hsContactVid));
  const mediaContents = item.mediacontents.filter(b => String(b.influencer) === String(record._id));
  const payables = item.payables.filter(b => String(b.influencer) === String(record._id));
  const serviceagreements = item.serviceagreements.filter(b => String(b.influencer) === String(record._id));
  const cpmprospects = item.cpmprospects.filter(b => String(b.influencer) === String(record._id));
  // const invoices = item.invoices.find(b => String(b.deal) === String(record._id));
  const offersObj = useMemo(() => {
    return offers.reduce((prev, curr) => { prev[curr._id] = curr; return prev; }, {})
  }, [offers])
  
  return (
    <div>

      <CardToGenerateItem
          title={<a
            href={`/influencer/profile/${record._id}`}
            target="_blank"
            title="Open Creator Profile"
            rel="noreferrer noopener"
          >
            {record.fullName}
          </a>}
          array={record ? [
            (<>
              <strong>First Name: </strong>{record.first_name} <br />
              <strong>Last Name: </strong>{record.last_name} <br />
              <strong>Email: </strong>{record.email} <br />
              <strong>Company: </strong>{record.affiliateDataObject?.company } <br />
            </>)
          ] : []}
        />
      {contact ? <CardToGenerateItem
          title='Hubspot Contact'
          array={contact ? [
            (<>
                <a
                  href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${contact.hs_object_id}/`}
                  target="_blank"
                  title="Open Hubspot Contact"
                  rel="noreferrer noopener"
                >
                  {contact.firstname} {contact.lastname}
                </a><br />
              <strong>Email: </strong>{contact.email} <br />
              <strong>Hubspot Contact Id: </strong>{contact.hs_object_id} <br />
            </>)
          ] : []}
      /> : null}
      <div>
        <CardToGenerateItem
          title="Bill"
          array={bill ? [
            (<>
              <strong>Status</strong>: {bill.paymentStatus  || '(No Status)'} <br />
              <strong>Amount</strong>: {formatCurrency(bill.amount || 0)} <br />
              <strong>Invoice Number:</strong>: {bill.invoiceNumber || ''} <br />
            </>)
          ] : []}
        />
      </div>
      <div>
        <CardToGenerateItem
          title="Media Contents"
          array={mediaContents?.length  ? mediaContents.map(m =>
            (<>
              <strong>Name</strong>: {m.name} <br />
              <strong>Title</strong>: {m.title} <br />
              <strong>Url Status</strong> {m.urlStatus || '(No Status)'} <br />
              <strong>Status:</strong> {m.status  || '(No Status)'}
            </>)
            ) : []}
        />
      </div>
      <div>
        <CardToGenerateItem
          title="Payables"
          array={payables?.length  ? payables.map(m =>
            (<>
              <strong>{offersObj[m.offer]?.name}</strong> <br />
              <strong>Created At</strong>:{formatDate(m.createdAt)}<br />
              <strong>Status</strong>:{m.status  || '(No Status)'}<br />
              <strong>Amount</strong>:{formatCurrency(m.ilPayoutAmount || 0)}
            </>)
            ) : []}
        />
      </div>
      <div>
        <CardToGenerateItem
          title="ServiceAgreements"
          array={serviceagreements?.length  ? serviceagreements.map(m =>
            (<>
              <strong>Created At</strong>:{formatDate(m.createdAt)}<br />
              <strong>Status</strong>:{m.status  || '(No Status)'}<br />
            </>)
            ) : []}
        />
      </div>
      <div>
        <div>
          <CardToGenerateItem
            title="CpmProspects"
            array={cpmprospects?.length  ? cpmprospects.map(m =>
              (<>
                <strong>IL Status</strong> {m.approvalStatus.status}<br />
                <strong>Brand Status</strong> {m.approvalsBrandStatus.status}<br />
                <strong>Amount</strong> {formatCurrency(m.amount.amount)}<br />
              </>)
              ) : []}
          />
        </div>
      </div>
      { record._id !== item._id ? <div className='mt-2'>
        <Button
          color="primary"
          size="sm"
          onClick={() => showModal({
            title: 'Merge Creator',
            message: text,
            processRecord: async() => {
              await removeMergedDeal({ parentInfluencerId: item._id, influencerId: record._id });
              success();
            },
            confirmButtonCaption: 'Merge Creator',
          })}>Merge {record.fullName}</Button>
      </div> : null}
    </div>
  );
};
DealItem.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

const Table = React.memo(() => {
  const [ showModal ] = Hook.useShowModalHook('ConfirmModalDefault');
  const { showSuccess } = Hook.useNoticeHook();
  const [offers, setOffers] = useState([])
  console.log('table');
  const reload = Hook.useReloadTableHook('/api/portal/finance/get-merged-influencers');
  const success = useCallback(() => {
    showSuccess('Deal was merged successfully');
    reload();
  }, [reload, showSuccess])
  useEffect(() => {
    axios.getSafety('/api/portal/finance/get-offers').then((data) => {
      const brands = {};
      data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      setOffers(data.offers);
    })
  }, [])
  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Influencers Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'fullSearchName',
          Header: () => 'Creator',
          accessor: () => (''),
          // eslint-disable-next-line max-len
          Cell: p => (<CreatorInList record={p.row.original} />),
          width: 260,
          className: '',
          disableSortBy: true,
        },
        /* {
          id: 'createdAt',
          Header: () => 'Created At',
          accessor: p => "",
          // eslint-disable-next-line max-len
          Cell: p => moment(p.row.original.params.createdAt).tz('America/New_York').format('MM/DD/YYYY'),
          className: '',
        },
        {
          id: 'dealTerms.type_of_deal',
          Header: () => 'Deal Type',
          accessor: record => (record?.params?.dealTerms.type_of_deal || ''),
          // eslint-disable-next-line max-len
          Cell: p => (p.value),
          width: 100,
          className: '',
        },
        {
          id: 'dealstage',
          Header: () => 'Deal Stage',
          accessor: record => (record?.params?.dealstage || ''),
          Cell: p => (<DealStage record={p.row.original} />),
          className: '',
          width: 100,
          disableSortBy: true,
        }, */
        {
          id: 'detailsParent',
          Header: () => 'Current Creator',
          accessor: (record) => record.params.mergedInfluencers.length,
          // eslint-disable-next-line max-len
          Cell: p => (
            <div>
              {p.row.original.params?.mergedInfluencers?.filter(r => r._id === p.row.original.params._id).map(a => (<DealItem item={p.row.original.params} record={a} showModal={showModal} success={success} offers={offers} />))}
            </div>
          ),
          width: 400,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'details',
          Header: () => 'Merged Creator',
          accessor: (record) => record.params.mergedInfluencers.length,
          // eslint-disable-next-line max-len
          Cell: p => (
            <div>
              {p.row.original.params?.mergedInfluencers?.filter(r => r._id !== p.row.original.params._id).map(a => (<DealItem item={p.row.original.params} record={a} showModal={showModal} success={success} offers={offers} />))}
            </div>
          ),
          width: 400,
          className: '',
          disableSortBy: true,
        },
        /* {
          Header: '',
          accessor: () => null,
          id: 'actions',
          width: 50,
          Cell: (p) => {
            console.log(p)
            const contextMenuOptions = [
              
            ];
            return (
              <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                <TableCellContextMenu options={contextMenuOptions} />
              </div>
            );
          },
          disableSortBy: true,
          className: 'd-flex align-items-center align-middle',
        }, */
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
