import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';


const SelectFilter = ({
  filterProp,
  filterAction,
  title,
  styles,
  defaultValue,
  version,
}) => {
  const [values, setValues] = useState([]);
  const onChange = (value, startFilterAction = true) => {
    // console.log(value);
    const filters = {};
    Object.keys(filterProp).map((filterName) => {
      filters[filterName] = !!(value && value.find(v => v.value === filterName));
      return true;
    });
    if (startFilterAction) filterAction(filters);
    setValues(value);
  };
  const options = Object.keys(filterProp).map(k => ({
    value: k,
    label: k,
  }));

  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue, false);
    }
  }, [version]);

  return (
    <FormGroup style={{ minWidth: '150px', marginBottom: 0, ...styles }} className="mr-2">
      <Select
        defaultValue={defaultValue || options.map(o => ({ ...o }))}
        value={values}
        isMulti
        name="colors"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        placeholder={title || 'Select...'}
      />
    </FormGroup>
  );
};

SelectFilter.propTypes = {
  filterProp: PropTypes.objectOf(PropTypes.bool).isRequired,
  // filterTitle: PropTypes.string.isRequired,
  filterAction: PropTypes.func.isRequired,
  // style: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.any),
  defaultValue: PropTypes.arrayOf(PropTypes.any),
  version: PropTypes.string,
};
SelectFilter.defaultProps = {
  title: null,
  styles: {},
  defaultValue: null,
  version: (new Date()).toString(),
};

export default SelectFilter;
