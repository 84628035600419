import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTags from 'react-tag-autocomplete';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import IntervalDatePickerField from '../../../../Influencer/Research/Prospects/components/custom/IntervalDatePickerField';
import ProgressBar from '../../../../../../shared/helpers/ProgressBarWithObject';

const Actions = React.memo(({
  fetchAllContacts,
  onSearchChange,
  searchValue,
  ownersSuggestions,
  setOwnersFilter,
  setOwners,
  owners,
  blackListFilters,
  setBlackListFilters,
  complianceFilters,
  setComplianceFilters,
  firstConversionDateValue,
  setFirstConversionDateValue,
  enableFirstConversionDate,
  setEnableFirstConversionDate,
  firstConversionDateFrom,
  setFirstConversionDateFrom,
  firstConversionDateTo,
  setFirstConversionDateTo,
  singleFirstConversionDate,
  setSingleFirstConversionDate,
  lastConversionDateValue,
  setLastConversionDateValue,
  enableLastConversionDate,
  setEnableLastConversionDate,
  lastConversionDateFrom,
  setLastConversionDateFrom,
  lastConversionDateTo,
  setLastConversionDateTo,
  singleLastConversionDate,
  setSingleLastConversionDate,
  loadingContacts,
  afterContactsFetch,
  role,
  type,
  pseudoUrl,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('hubilContactsDashboardShowFilters') === 'true');

  const reactTagsOwners = React.createRef();

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('hubilContactsDashboardShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const setFiltersOwner = (ownersNew) => {
    const ownersFilterNew = {};
    if (ownersNew.length > 0) {
      ownersNew.map((item) => {
        ownersFilterNew[item.id] = true;
        return true;
      });
    }
    setOwnersFilter(ownersFilterNew);
  };

  const onDeleteOwner = (i) => {
    const ownersNew = owners.slice(0);
    ownersNew.splice(i, 1);
    setFiltersOwner(ownersNew);
    setOwners(ownersNew);
  };

  const onAdditionOwner = (owner) => {
    const ownersNew = [].concat(owners, owner);
    setFiltersOwner(ownersNew);
    setOwners(ownersNew);
  };

  const handleChangeBlackListFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setBlackListFilters({
        ...blackListFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeComplianceFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setComplianceFilters({
        ...complianceFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setBlackListFilters({
      Yes: true,
      No: true,
    });
    setComplianceFilters({
      'Not Yet Published': true,
      'Under Review': true,
      'Non-Compliant': true,
      'Fix in Progress': true,
      'Non-Response': true,
      Compliant: true,
      Terminated: true,
      'Not Set': true,
    });
    setOwners([]);
    setOwnersFilter({});
    setEnableFirstConversionDate(false);
    setFirstConversionDateValue({ value: '<=', label: '<=' });
    setSingleFirstConversionDate(null);
    setFirstConversionDateTo(null);
    setFirstConversionDateFrom(null);

    setEnableLastConversionDate(false);
    setLastConversionDateValue({ value: '<=', label: '<=' });
    setSingleLastConversionDate(null);
    setLastConversionDateTo(null);
    setLastConversionDateFrom(null);
  };

  const handleChangeFirstConversionDateCheckBox = ({ target }) => {
    setEnableFirstConversionDate(!enableFirstConversionDate);
    if (!target.checked) {
      setSingleFirstConversionDate(null);
      setFirstConversionDateTo(null);
      setFirstConversionDateFrom(null);
    }
  };

  const handleChangeLastConversionDateCheckBox = ({ target }) => {
    setEnableLastConversionDate(!enableLastConversionDate);
    if (!target.checked) {
      setSingleLastConversionDate(null);
      setLastConversionDateTo(null);
      setLastConversionDateFrom(null);
    }
  };

  const conversionDateLabels = [
    { value: '=', label: '=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'Between', label: 'Between' },
  ];

  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
      height: '44px',
    }),
  };

  return (
    <div>
      <Row className="mt-2">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search HubSpot Contact, Company, Email, Media Channel URLs..."
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => window.open(`/resources/hubil${type}s/download-csv/${pseudoUrl}`, '_blank').focus()}
              >
                Download CSV
              </DropdownItem>
              {role === 'owner' && (
                <>
                  <DropdownItem divider />
                  <DropdownItem
                    disabled={loadingContacts}
                    onClick={fetchAllContacts}
                  >
                    {loadingContacts ? 'Fetching Contacts, please wait...' : 'Fetch All Contacts'}
                  </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          { loadingContacts && <ProgressBar topic="fetchAllContacts" afterFunction={afterContactsFetch} /> }
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row>
            <Col sm={5}>
              <Label className="bold-text">
                Contact Owner(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsOwners}
                tags={owners}
                suggestions={ownersSuggestions}
                onDelete={onDeleteOwner}
                onAddition={onAdditionOwner}
                delimiters={['Enter', ',']}
                placeholderText="Contact Owner name(s)"
              />
              <Row className="mt-3">
                <Col sm={7}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Compliance Status
                  </Label>
                  <Row>
                    <Col sm={6}>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="NotYetPublished"
                          label="Not Yet Published"
                          value={complianceFilters['Not Yet Published']}
                          onChange={event => handleChangeComplianceFilters(event, 'Not Yet Published')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="UnderReview"
                          label="Under Review"
                          value={complianceFilters['Under Review']}
                          onChange={event => handleChangeComplianceFilters(event, 'Under Review')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="Non-Compliant"
                          label="Non-Compliant"
                          value={complianceFilters['Non-Compliant']}
                          onChange={event => handleChangeComplianceFilters(event, 'Non-Compliant')}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <CheckBoxField
                          name="FixinProgress"
                          label="Fix in Progress"
                          value={complianceFilters['Fix in Progress']}
                          onChange={event => handleChangeComplianceFilters(event, 'Fix in Progress')}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="Non-Response"
                          label="Non-Responsive"
                          value={complianceFilters['Non-Response']}
                          onChange={event => handleChangeComplianceFilters(event, 'Non-Response')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="Compliant"
                          label="Compliant"
                          value={complianceFilters.Compliant}
                          onChange={event => handleChangeComplianceFilters(event, 'Compliant')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="Terminated"
                          label="Terminated"
                          value={complianceFilters.Terminated}
                          onChange={event => handleChangeComplianceFilters(event, 'Terminated')}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <CheckBoxField
                          name="NotSet"
                          label="Not Set"
                          value={complianceFilters['Not Set']}
                          onChange={event => handleChangeComplianceFilters(event, 'Not Set')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col sm={5}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Blacklist?
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="onTheBlacklist"
                      label="Yes"
                      value={blackListFilters.Yes}
                      onChange={event => handleChangeBlackListFilters(event, 'Yes')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="notOnTheBlacklist"
                      label="No"
                      value={blackListFilters.No}
                      onChange={event => handleChangeBlackListFilters(event, 'No')}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col sm={7}>
              <Row>
                <Col sm={6}>
                  <Row>
                    <Col sm={1} />
                    <Col sm={11} style={{ paddingLeft: '20px' }}>
                      <Label className="bold-text" style={{ marginBottom: '8px' }}>
                        First Conversion Date
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={1}>
                      <FormGroup
                        style={{ paddingTop: '13px', paddingLeft: '8px' }}
                      >
                        <CheckBoxField
                          name="firstActivityDateCheckBox"
                          value={enableFirstConversionDate}
                          onChange={e => e.target && handleChangeFirstConversionDateCheckBox(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={5} style={{ paddingLeft: '20px' }}>
                      <Select
                        value={firstConversionDateValue}
                        onChange={(option) => {
                          if ((firstConversionDateValue !== 'Between' && option.value === 'Between') || (firstConversionDateValue === 'Between' && option.value !== 'Between')) {
                            setFirstConversionDateFrom(null);
                            setFirstConversionDateTo(null);
                            setSingleFirstConversionDate(null);
                          }
                          setFirstConversionDateValue(option);
                        }}
                        options={conversionDateLabels}
                        isDisabled={!enableFirstConversionDate}
                        styles={customStyles}
                      />
                    </Col>
                    <Col sm={6}>
                      {firstConversionDateValue.value === 'Between' ? (
                        <div style={{ marginRight: '-30px' }} className="pb-2">
                          <IntervalDatePickerField
                            onChange={({ start, end }) => {
                              setFirstConversionDateFrom(start);
                              end?.setHours(23, 59, 59);
                              setFirstConversionDateTo(end);
                            }}
                            startDate={firstConversionDateFrom}
                            endDate={firstConversionDateTo}
                            maxDate={new Date()}
                            placeholderFrom="Start Date"
                            placeholderTo="End Date"
                            verticalFields
                            disabled={!enableFirstConversionDate}
                          />
                        </div>
                      ) : (
                        <div className="date-picker datepicker-in-prospects">
                          <DatePicker
                            selected={singleFirstConversionDate}
                            onChange={(date) => {
                              if (firstConversionDateValue.value === '<=' || firstConversionDateValue.value === '>') {
                                date?.setHours(23, 59, 59);
                              }
                              setSingleFirstConversionDate(date);
                            }}
                            dateFormat="MMM d, yyyy"
                            maxDate={new Date()}
                            placeholderText="Select Date"
                            dropDownMode="select"
                            isClearable
                            wrapperClassName="date-picker--interval w-100"
                            className="form-control form-control-sm datepicker-in-prospects w-100"
                            disabled={!enableFirstConversionDate}
                          />
                          {singleFirstConversionDate === null && (
                            <svg
                              className="mdi-icon"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              style={{
                                position: 'absolute',
                                top: '13px',
                                right: '22px',
                                fill: '#999',
                                pointerEvents: 'none',
                              }}
                            >
                              <path
                                d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                              />
                            </svg>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col sm={6}>
                  <Row>
                    <Col sm={1} />
                    <Col sm={11} style={{ paddingLeft: '20px' }}>
                      <Label className="bold-text" style={{ marginBottom: '8px' }}>
                        Last Conversion Date
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={1}>
                      <FormGroup
                        style={{ paddingTop: '13px', paddingLeft: '8px' }}
                      >
                        <CheckBoxField
                          name="lastActivityDateCheckBox"
                          value={enableLastConversionDate}
                          onChange={e => e.target && handleChangeLastConversionDateCheckBox(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={5} style={{ paddingLeft: '20px' }}>
                      <Select
                        value={lastConversionDateValue}
                        onChange={(option) => {
                          if ((lastConversionDateValue !== 'Between' && option.value === 'Between') || (lastConversionDateValue === 'Between' && option.value !== 'Between')) {
                            setLastConversionDateFrom(null);
                            setLastConversionDateTo(null);
                            setSingleLastConversionDate(null);
                          }
                          setLastConversionDateValue(option);
                        }}
                        options={conversionDateLabels}
                        isDisabled={!enableLastConversionDate}
                        styles={customStyles}
                      />
                    </Col>
                    <Col sm={6}>
                      {lastConversionDateValue.value === 'Between' ? (
                        <div style={{ marginRight: '-30px' }} className="pb-2">
                          <IntervalDatePickerField
                            onChange={({ start, end }) => {
                              setLastConversionDateFrom(start);
                              end?.setHours(23, 59, 59);
                              setLastConversionDateTo(end);
                            }}
                            startDate={lastConversionDateFrom}
                            endDate={lastConversionDateTo}
                            maxDate={new Date()}
                            placeholderFrom="Start Date"
                            placeholderTo="End Date"
                            verticalFields
                            disabled={!enableLastConversionDate}
                          />
                        </div>
                      ) : (
                        <div className="date-picker datepicker-in-prospects">
                          <DatePicker
                            selected={singleLastConversionDate}
                            onChange={(date) => {
                              if (lastConversionDateValue.value === '<=' || lastConversionDateValue.value === '>') {
                                date?.setHours(23, 59, 59);
                              }
                              setSingleLastConversionDate(date);
                            }}
                            dateFormat="MMM d, yyyy"
                            maxDate={new Date()}
                            placeholderText="Select Date"
                            dropDownMode="select"
                            isClearable
                            wrapperClassName="date-picker--interval w-100"
                            className="form-control form-control-sm datepicker-in-prospects w-100"
                            disabled={!enableLastConversionDate}
                          />
                          {singleLastConversionDate === null && (
                            <svg
                              className="mdi-icon"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              style={{
                                position: 'absolute',
                                top: '13px',
                                right: '22px',
                                fill: '#999',
                                pointerEvents: 'none',
                              }}
                            >
                              <path
                                d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                              />
                            </svg>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.type === a1.type &&
  a.pseudoUrl === a1.pseudoUrl &&
  a.searchValue === a1.searchValue &&
  a.loadingContacts === a1.loadingContacts &&
  _.isEqual(a.blackListFilters, a1.blackListFilters) &&
  _.isEqual(a.complianceFilters, a1.complianceFilters) &&
  _.isEqual(a.owners, a1.owners) &&
  _.isEqual(a.ownersSuggestions, a1.ownersSuggestions) &&
  _.isEqual(a.enableFirstConversionDate, a1.enableFirstConversionDate) &&
  _.isEqual(a.firstConversionDateValue, a1.firstConversionDateValue) &&
  _.isEqual(a.singleFirstConversionDate, a1.singleFirstConversionDate) &&
  _.isEqual(a.firstConversionDateFrom, a1.firstConversionDateFrom) &&
  _.isEqual(a.firstConversionDateTo, a1.firstConversionDateTo) &&
  _.isEqual(a.enableLastConversionDate, a1.enableLastConversionDate) &&
  _.isEqual(a.lastConversionDateValue, a1.lastConversionDateValue) &&
  _.isEqual(a.singleLastConversionDate, a1.singleLastConversionDate) &&
  _.isEqual(a.lastConversionDateFrom, a1.lastConversionDateFrom) &&
  _.isEqual(a.lastConversionDateTo, a1.lastConversionDateTo));
Actions.propTypes = {
  fetchAllContacts: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  ownersSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setOwnersFilter: PropTypes.func.isRequired,
  setOwners: PropTypes.func.isRequired,
  owners: PropTypes.arrayOf(PropTypes.any).isRequired,
  setBlackListFilters: PropTypes.func.isRequired,
  blackListFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setComplianceFilters: PropTypes.func.isRequired,
  complianceFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  enableFirstConversionDate: PropTypes.bool.isRequired,
  setEnableFirstConversionDate: PropTypes.func.isRequired,
  firstConversionDateValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setFirstConversionDateValue: PropTypes.func.isRequired,
  firstConversionDateFrom: PropTypes.objectOf(PropTypes.any),
  setFirstConversionDateFrom: PropTypes.func.isRequired,
  firstConversionDateTo: PropTypes.objectOf(PropTypes.any),
  setFirstConversionDateTo: PropTypes.func.isRequired,
  singleFirstConversionDate: PropTypes.objectOf(PropTypes.any),
  setSingleFirstConversionDate: PropTypes.func.isRequired,
  enableLastConversionDate: PropTypes.bool.isRequired,
  setEnableLastConversionDate: PropTypes.func.isRequired,
  lastConversionDateValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setLastConversionDateValue: PropTypes.func.isRequired,
  lastConversionDateFrom: PropTypes.objectOf(PropTypes.any),
  setLastConversionDateFrom: PropTypes.func.isRequired,
  lastConversionDateTo: PropTypes.objectOf(PropTypes.any),
  setLastConversionDateTo: PropTypes.func.isRequired,
  singleLastConversionDate: PropTypes.objectOf(PropTypes.any),
  setSingleLastConversionDate: PropTypes.func.isRequired,
  loadingContacts: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  afterContactsFetch: PropTypes.func.isRequired,
  pseudoUrl: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

Actions.defaultProps = {
  firstConversionDateFrom: null,
  firstConversionDateTo: null,
  singleFirstConversionDate: null,
  lastConversionDateFrom: null,
  lastConversionDateTo: null,
  singleLastConversionDate: null,
};

const mapStateToProps = state => ({
  role: state.session.role,
});

export default connect(mapStateToProps)(Actions);
