/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const advertiserReducer = createSlice({
  name: 'employees',
  initialState: {
    loading: {
      fillEmployees: false,
    },
    logs: {
      employees: {
        request: [],
        added: [],
        removed: [],
        errors: [],
      },
    },
    error: null,
    lastFetched: new Date(),
  },
  reducers: {
    getFillEmployeesStart(state) {
      state.loading = {
        ...state.loading,
        fillEmployees: true,
      };
      state.logs = {
        ...state.logs,
        employees: {
          request: [],
          added: [],
          removed: [],
          errors: [],
        },
      };
    },
    getFillEmployeesError(state, action) {
      console.log(state, action);
      state.loading = {
        ...state.loading,
        fillEmployees: false,
      };
    },
    getFillEmployeesSuccess(state, action) {
      const { employees } = action.payload;
      state.loading = {
        ...state.loading,
        fillEmployees: false,
      };
      state.logs = {
        ...state.logs,
        employees,
      };
      state.error = null;
    },
  },
});
const fillEmployeesApi = async () => {
  const api = new ApiClient();
  return api.client.post('/api/portal/finance/post-fill-employee');
};

export const {
  getFillEmployeesStart,
  getFillEmployeesError,
  getFillEmployeesSuccess,
} = advertiserReducer.actions;

export const fillEmployees = callback => async (dispatch) => {
  try {
    dispatch(getFillEmployeesStart());
    const response = await fillEmployeesApi();
    dispatch(getFillEmployeesSuccess({
      employees: response.data.employeesLog,
    }));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(getFillEmployeesError(err));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};


export default advertiserReducer.reducer;
