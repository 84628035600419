// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { modalId } from './Modal/NewInvoice';
import { MoveContentToNextPeriodModalId } from './Modal/MoveContentToNextPeriodModal';


const Actions = React.memo(({
  onSearchChange,
  searchValue,
  approvalStatusFilters,
  setApprovalStatusFilters,
  downloadCsv,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('cpmInvoiceShowFilters') === 'true');
  // const [showNewIsertionOrder, setShowNewIsertionOrder] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('cpmInvoiceShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const handleChangeApprovalStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setApprovalStatusFilters({
        ...approvalStatusFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setApprovalStatusFilters({
      New: true, Sent: true, Received: true, Paid: true,
    });
  };

  return (
    <div>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Name, Description"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => dispatch(showModalById(modalId))}
              >
                New Invoice
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => dispatch(showModalById(MoveContentToNextPeriodModalId))}
              >
                Move Content To Next Period
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={downloadCsv}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row className="mt-3">
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Status
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="new"
                  label="New"
                  value={approvalStatusFilters.New}
                  onChange={event => handleChangeApprovalStatusFilters(event, 'New')}
                />
              </FormGroup>
              <FormGroup style={{ marginBottom: '10px' }}>
                <CheckBoxField
                  name="sent"
                  label="Sent"
                  value={approvalStatusFilters.Sent}
                  onChange={event => handleChangeApprovalStatusFilters(event, 'Sent')}
                />
              </FormGroup>
              <FormGroup style={{ marginBottom: '10px' }}>
                <CheckBoxField
                  name="received"
                  label="Received"
                  value={approvalStatusFilters.Received}
                  onChange={event => handleChangeApprovalStatusFilters(event, 'Received')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="paid"
                  label="Paid"
                  value={approvalStatusFilters.Paid}
                  onChange={event => handleChangeApprovalStatusFilters(event, 'Paid')}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  _.isEqual(a.approvalStatusFilters, a1.approvalStatusFilters));
Actions.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  // setVersion: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setApprovalStatusFilters: PropTypes.func.isRequired,
  approvalStatusFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  downloadCsv: PropTypes.func.isRequired,
};

export default Actions;
