// const moment = require('moment');
const _ = require('lodash');

exports.setUrl = ({
  history,
  search, dateFrom, dateTo, selectedButton,
  selectedCreators, selectedBrands, selectedTypes,
  selectedDealTypes, selectedIlStatuses, selectedBrandStatuses, resetDealFilter,
  // filtersILStatus, filtersBrandStatus,
}) => {
  const searchUrl = new URLSearchParams(window.location.search);
  const oldUrl = `${history.location.pathname}?${searchUrl.toString()}`;

  // if (removeBeforeSet) {
  //   searchUrl = new URLSearchParams();
  // }
  if (resetDealFilter) {
    searchUrl.delete('filters.deal');
  }

  if (search) {
    searchUrl.set('filters.fullSearchName', search);
  } else {
    searchUrl.delete('filters.fullSearchName');
  }

  if (dateFrom !== '' && searchUrl.get('filters.createdAt.from') !== dateFrom) {
    searchUrl.delete('filters.createdAt.from');
    searchUrl.append('filters.createdAt.from', dateFrom);
  }
  if (dateTo !== '' && searchUrl.get('filters.createdAt.to') !== dateTo) {
    searchUrl.delete('filters.createdAt.to');
    searchUrl.append('filters.createdAt.to', dateTo);
  }
  if (searchUrl.get('range') !== selectedButton) {
    searchUrl.delete('range');
    searchUrl.append('range', selectedButton);
  }

  const filterCreators = searchUrl.getAll('filters.creator');
  let deleteFilterCreators = false;

  if (!selectedCreators || selectedCreators.length === 0) {
    searchUrl.delete('filters.creator');
  }
  if (selectedCreators) {
    filterCreators.forEach((creator) => {
      if (selectedCreators.filter(item => item.value === creator).length === 0) {
        deleteFilterCreators = true;
      }
    });
    if (deleteFilterCreators) {
      searchUrl.delete('filters.creator');
    }
    selectedCreators.forEach((creator) => {
      if (creator.value !== '' && (!filterCreators.includes(creator.value) || deleteFilterCreators)) {
        searchUrl.append('filters.creator', creator.value);
      }
    });
  }
  const searchCreatorsEmails = (searchUrl.getAll('filters.creator') || []).map(email => decodeURI(email)).sort();
  const filterCreatorsEmails = (selectedCreators || []).map(option => decodeURI(option.value)).sort();
  const isEqual = _.isEqual(searchCreatorsEmails, filterCreatorsEmails);
  if (!isEqual) {
    if (!selectedCreators || selectedCreators.length === 0) {
      searchUrl.delete('filters.creator');
    }
    if (selectedCreators) {
      filterCreators.forEach((creator) => {
        if (selectedCreators.filter(item => item.value === creator).length === 0) {
          deleteFilterCreators = true;
        }
      });
      if (deleteFilterCreators) {
        searchUrl.delete('filters.creator');
      }
      selectedCreators.forEach((selectedCreator) => {
        if (selectedCreator.value !== '' && (!filterCreators.includes(selectedCreator.value) || deleteFilterCreators)) {
          searchUrl.append('filters.creator', selectedCreator.value);
        }
      });
    }
  }

  const filterBrands = searchUrl.getAll('filters.brand');
  let deleteFilterBrands = false;

  if (!selectedBrands || selectedBrands.length === 0) {
    searchUrl.delete('filters.brand');
  }
  if (selectedBrands) {
    filterBrands.forEach((brand) => {
      if (selectedBrands.filter(item => item.value === brand).length === 0) {
        deleteFilterBrands = true;
      }
    });
    if (deleteFilterBrands) {
      searchUrl.delete('filters.brand');
    }
    selectedBrands.forEach((brand) => {
      if (brand.value !== '' && (!filterBrands.includes(brand.value) || deleteFilterBrands)) {
        searchUrl.append('filters.brand', brand.value);
      }
    });
  }

  const filtersTypes = searchUrl.getAll('filters.type');
  let deleteFilterTypes = false;

  if (!selectedTypes || selectedTypes.length === 0) {
    searchUrl.delete('filters.type');
  }
  if (selectedTypes) {
    filtersTypes.forEach((type) => {
      if (selectedTypes.filter(item => item.value === type).length === 0) {
        deleteFilterTypes = true;
      }
    });
    if (deleteFilterTypes) {
      searchUrl.delete('filters.type');
    }
    selectedTypes.forEach((selectedType) => {
      if (selectedType.value !== '' && (!filtersTypes.includes(selectedType.value) || deleteFilterTypes)) {
        searchUrl.append('filters.type', selectedType.value);
      }
    });
  }

  const filtersDealTypes = searchUrl.getAll('filters.dealType');
  let deleteFilterDealTypes = false;

  if (!selectedDealTypes || selectedDealTypes.length === 0) {
    searchUrl.delete('filters.dealType');
  }
  if (selectedDealTypes) {
    filtersDealTypes.forEach((type) => {
      if (selectedDealTypes.filter(item => item.value === type).length === 0) {
        deleteFilterDealTypes = true;
      }
    });
    if (deleteFilterDealTypes) {
      searchUrl.delete('filters.dealType');
    }
    selectedDealTypes.forEach((selectedType) => {
      if (selectedType.value !== '' && (!filtersDealTypes.includes(selectedType.value) || deleteFilterDealTypes)) {
        searchUrl.append('filters.dealType', selectedType.value);
      }
    });
  }

  searchUrl.delete('filters.status');
  let allSwitchedOffStatus = true;
  if (selectedIlStatuses) {
    Object.keys(selectedIlStatuses).forEach((key) => {
      if (selectedIlStatuses[key]) {
        allSwitchedOffStatus = false;
        searchUrl.append('filters.status', key);
      }
    });
  }
  if (allSwitchedOffStatus) {
    searchUrl.append('filters.status', 'Not Exist');
  }

  searchUrl.delete('filters.brandStatus');
  let allSwitchedOffBrandStatus = true;
  if (selectedBrandStatuses) {
    Object.keys(selectedBrandStatuses).forEach((key) => {
      if (selectedBrandStatuses[key]) {
        allSwitchedOffBrandStatus = false;
        searchUrl.append('filters.brandStatus', key);
      }
    });
  }
  if (allSwitchedOffBrandStatus) {
    searchUrl.append('filters.brandStatus', 'Not Exist');
  }
  // searchUrl.delete('filters.reviewStatus');
  // if (filtersReviewStatus['Needs Review'] || filtersReviewStatus['Pending Edits'] || filtersReviewStatus.Approved || filtersReviewStatus.Terminated) {
  //   Object.keys(filtersReviewStatus).forEach((status) => {
  //     if (filtersReviewStatus[status]) {
  //       searchUrl.append('filters.reviewStatus', status);
  //     }
  //   });
  // }
  // console.log('SET URL');

  let newUrl = `${history.location.pathname}?${searchUrl.toString()}`;
  if (oldUrl !== newUrl) {
    searchUrl.set('page', '1');
    newUrl = `${history.location.pathname}?${searchUrl.toString()}`;
    history.push(newUrl);
  }
};
