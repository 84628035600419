import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Col,
  Row,
  Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { NumericFormat as NumberFormat } from 'react-number-format';
import withNotice from '../../../App/store/with-notice';

const PeriodItems = {
  15: {
    start: 1, end: 15,
  },
  30: {
    start: 16, end: 30,
  },
  60: {
    start: 31, end: 60,
  },
  90: {
    start: 61, end: 90,
  },
  120: {
    start: 91, end: 120,
  },
  150: {
    start: 121, end: 150,
  },
  180: {
    start: 151, end: 180,
  },
  210: {
    start: 181, end: 210,
  },
  240: {
    start: 211, end: 240,
  },
  270: {
    start: 241, end: 270,
  },
  300: {
    start: 271, end: 300,
  },
  330: {
    start: 301, end: 330,
  },
  360: {
    start: 331, end: 360,
  },
};
const ItemElem = ({
  item, setValue,
}) => {
  const {
    start, end, value,
  } = item;
  const onChange = (v) => {
    setValue(v);
  };

  return (
    <Row className="align-items-center mt-3">
      <Col sm="auto" className="">
        <Label className="mb-0">Days {start} - {end}</Label>
      </Col>
      <Col>
        <NumberFormat
          thousandSeparator
          suffix="%"
          name="upfrontCPM"
          value={value || 0}
          className="form-control"
          inputMode="numeric"
          // allowEmptyFormatting
          fixedDecimalScale
          decimalScale={2}
          onValueChange={(values) => {
            onChange(values.floatValue);
          }}
        />
      </Col>
    </Row>
  );
};

ItemElem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired,
};

const ProjectedSettingsComponent = () => {
  const [settings, setSettings] = useState({ items: {}, types: {} });
  const [loading, setLoading] = useState(false);

  const fetchSettingsFromDictionary = async () => {
    try {
      const resp = await axios.get('/api/portal/reports/get-projected-rebuy-settings');
      setSettings(resp.data.settings || { items: {}, types: {} });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchSettingsFromDictionary();
  }, []);

  const saveSettings = async () => {
    try {
      setLoading(true);
      await axios.post('/api/portal/reports/post-save-projected-rebuy-settings', { settings });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const list = useMemo(() => {
    if (!settings?.items) return [];
    const listArray = [];
    Object.keys(PeriodItems).forEach((days) => {
      if (Number(days) <= Number(settings?.types['Upfront CPM'])) {
        listArray.push({ ...PeriodItems[days], key: days, value: settings?.items[days]?.value || 0 });
      }
    });
    return listArray;
  }, [settings?.types['Upfront CPM'], settings]);

  const setValue = (index, value) => {
    const settingsClone = { ...(settings?.items || {}) };
    const itemk = PeriodItems[index];
    settingsClone[index] = {
      value, key: index, ...itemk,
    };
    setSettings(s => ({ ...s, items: settingsClone }));
  };

  const onChangeValue = (type, value) => {
    setSettings(s => ({ ...s, types: { ...s.types, [type]: value } }));
  };

  console.log(settings);

  return (
    <>
      <h4 className="font-weight-bold mt-3">Default Target Threshold Percentages</h4>
      <hr />
      <Row className="align-items-center mb-3">
        <Col>
          <Row className="align-items-center">
            <Col sm="2" className="">
              <Label className="mb-0">Brand</Label>
            </Col>
            <Col>
              BetterHelp
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col sm="2" className="">
              <Label className="mb-0">Deal Type</Label>
            </Col>
            <Col>
              Upfront CPM
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="align-items-center mb-3">
        <Col>
          <Row className="align-items-center">
            <Col sm="auto" className="">
              <Label className="mb-0">Days To Hit Target CAC</Label>
            </Col>
            <Col>
              <NumberFormat
                thousandSeparator
                name="upfrontCPM"
                value={settings?.types['Upfront CPM'] || 360}
                className="form-control"
                inputMode="numeric"
                // allowEmptyFormatting
                fixedDecimalScale
                decimalScale={0}
                onValueChange={(values) => {
                  onChangeValue('Upfront CPM', values.floatValue);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <h4 className="font-weight-bold mt-3">Average percentage of conversions</h4>
      <hr />
      <Row>
        {list.map(item => (
          <Col
            sm={6}
            key={item.key}
          >
            <ItemElem
              item={{ ...item }}
              setValue={e => setValue(item.key, e)}
            />
          </Col>
        ))}
      </Row>
      <div className="d-flex justify-content-center mt-3">
        <Button
          className="mb-0"
          color="primary"
          onClick={saveSettings}
          disabled={loading}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default withNotice(ProjectedSettingsComponent);
