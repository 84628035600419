/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ActionHeader from '../../../../../../shared/components/components/app/action-header';
import MatTableList from '../../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../../shared/components/BreadCrumbs';
import UserPropertyType from '../../components/custom';
import CheckboxManyFilter from '../../../../../../shared/components/CheckboxManyFilter';

const initialFiltersType = {
  YouTube: true,
  'YouTube Description Text': true,
  Podcast: true,
  'Podcast Script Text': true,
  'Instagram Story': true,
  'Instagram Post': true,
  'Instagram TV Video': true,
  TikTok: true,
  'Facebook Post': true,
  'Facebook Watch Video': true,
  'Twitter Post': true,
  LinkTree: true,
  Website: true,
  Email: true,
  Text: true,
};

class ResourceAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: (new Date()).toString(),
      filtersType: initialFiltersType,
    };

    this.setFiltersType = this.setFiltersType.bind(this);
  }

  setFiltersType = (filters) => {
    this.setState({ filtersType: filters.data });
  }

  render() {
    const {
      resource,
      filterVisible,
      tag,
      setTag,
      action,
    } = this.props;

    const {
      version,
      filtersType,
    } = this.state;

    const links = [
      { title: 'Home', path: '/' },
      { title: 'Compliance', path: false },
      { title: 'Scorecards', path: false },
    ];

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs links={links} isBackButton />
            <ActionHeader
              resource={resource}
              action={action}
              tag={tag}
            />
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <CheckboxManyFilter
              filterProp={filtersType}
              filterTitle="Type Filters"
              filterAction={this.setFiltersType}
            />
          </Col>
          <Col md={3}>
            <a
              href="/resources/ScorecardAnswersSheetResource/download-csv"
              className="btn btn-primary btn-sm float-right"
              download
            >
              Download CSV
            </a>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <MatTableList
              action={action}
              filters={{ 'mediaContent.type': filtersType }}
              resource={resource}
              setTag={setTag}
              date={new Date()}
              sortBy="scoreDate"
              direction="desc"
              version={version}
              UserPropertyType={UserPropertyType}
              columnsSettings={{
                'mediaContent.name': {
                  width: 200,
                },
                'influencer.email': {
                  width: 200,
                },
                'adminUser.email': {
                  width: 200,
                },
                'mediaContent.type': {
                  width: 200,
                },
                scoreDate: {
                  width: 150,
                },
                totalScore: {
                  width: 100,
                },
                isFTCCompliant: {
                  width: 150,
                },
                mediaContentId: {
                  width: 150,
                },
              }}
              showFilters={filterVisible}
              hideToolbar
              v={2}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
