import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup
} from 'reactstrap';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../shared/helpers/WVUtilities';
import { clearFilterProperty } from '../../../../../../redux/reducers/TableReducerDefault';
import Hook from '../../../../../../shared/hooks';
import CheckBoxField from '@/shared/components/CheckBox'
import AmountFilter from '@/shared/components/filter/AmountFilter'

const Actions = React.memo(({
  downloadCsv,
}) => {
  console.log('RELOAD ACRIONS');
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState(getCookie('payablesBillsShowFilters') === 'true');

  const [search, setSearch] = Hook.useTableFilterHookString('fullSearchName');

  const [paymentMethodFilters, handleChangepaymentMethodFilters] = Hook.useTableFilterHookCheckbox('paymentMethod');

  const [dealStageFilters, handleChangeDealStageFilters, dealstagesOptions] = Hook.useTableFilterHookTags('dealstages');
  const [amountOptions, setAmountOptions] = Hook.useTableFilterHook('ilPayoutAmount')

  useEffect(() => {
    setCookie('cpmPricingApprovalsFilters', showFilters.toString(), 35);
  }, [showFilters]);
  const resetFilters = () => {
    dispatch(clearFilterProperty());
  };
  return (
    <div>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Contact Name, Email, Media Channels"
              value={search || ''}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => downloadCsv()}>
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row>
            <Col sm={3}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Payment Method
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Stripe"
                  label="Stripe"
                  value={paymentMethodFilters.Stripe}
                  onChange={event => handleChangepaymentMethodFilters(event, 'Stripe')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Billcom"
                  label="Bill.com"
                  value={paymentMethodFilters['Bill.com']}
                  onChange={event => handleChangepaymentMethodFilters(event, 'Bill.com')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="NotConnected"
                  label="Not Connected"
                  value={paymentMethodFilters['Not Connected']}
                  onChange={event => handleChangepaymentMethodFilters(event, 'Not Connected')}
                />
              </FormGroup>
            </Col>
            <Col sm="6">
              <Label className="bold-text">
                Deal Stage(s)
              </Label>
              <Select
                value={dealStageFilters}
                options={dealstagesOptions}
                onChange={e => handleChangeDealStageFilters(e || [])}
                className="dateOptions"
                placeholder="Deal Stage"
                menuPlacement="auto"
                menuPortalTarget={document.body}
                isMulti
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    minHeight: '45px',
                  }),
                }}
              />
              <div className='mt-3'>
                <Label className="bold-text">
                  InfluenceLogic Payout Amount
                </Label>
                <AmountFilter
                  enable={amountOptions.enable}
                  filterType={amountOptions.filterType}
                  amountFrom={amountOptions.amountFrom}
                  amountTo={amountOptions.amountTo}
                  setValues={(prop) => {
                    console.log('setValue', prop)
                    if (prop.singleAmount && prop.singleAmount !== amountOptions.amountFrom) {
                      setAmountOptions({ ...amountOptions, amountFrom: prop.singleAmount })
                    }
                    else {
                      const [key] = Object.keys(prop);
                      if(amountOptions[key] !== prop[key]) setAmountOptions({ ...amountOptions, ...prop })
                    }
                    
                  }}
                />
              </div>
              
            </Col>
          </Row>
        </>
      )}
    </div>
  );
});
Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
};

export default Actions;
