import React, { useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../../App/store/with-notice';

import DeleteModalDialog from '../components/custom/Modal/DeleteDialog';
import UploadInsertionOrderModal from './custom/Modal/UploadInsertionOrderModal';
import FileHistroryModal from './custom/Modal/FileHistroryModal';
import PublishLookerInsertinOrder from '../../CpmInsertionOrderProspects/components/modals/PublishLookerInsertinOrder';
import PublishInsertionOrderChangeLogHistoryModal from './custom/Modal/PublishInsertionOrderChangeLogHistoryModal';
import FetchContentReleaseDate from './custom/Modal/FetchContentReleaseDate';
import PublishLookerInsertinOrderBulk from './custom/Modal/PublishLookerInsertinOrder';
// import { preLoadFilters } from '../../../../../../redux/reducers/TableReducer';
import Hook from '../../../../../../shared/hooks';

const CPMProspectsListContainer = ({
  addNotice,
  history,
  action,
  resource,
}) => {
  const dispatch = useDispatch();
  // const [version, setVersion] = useState((new Date()).toString());
  const recordsRef = useRef([]);
  const [searchUrl, setPrefill, loadPage, , useLoadOrDebounce] = Hook.usePrefillHook({
    resourceId: resource.id,
    initialState: {
      sortBy: 'createdAt',
      direction: 'desc',
      page: 1,
      perPage: 10,
    },
  });

  useEffect(() => {
    setPrefill([
      {
        initialState: {
          New: true,
          'In Review': true,
          Signed: true,
        },
        func: ({ initialState, filters }) => {
          const init = { ...initialState };
          if (filters?.status) {
            Object.keys(initialState).forEach((key) => {
              init[key] = filters?.status.includes(key);
            });
          }
          return init;
        },
        filterName: 'status',
      },
    ]);
  }, []);

  const setUrl = () => {
    loadPage();
  };
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 1000);
  useLoadOrDebounce({
    loadFunc: () => loadPage(), loadDebounceFunc: () => setUrlDebounced(), setSearchUrl: () => history.push(`${history.location.pathname}?${searchUrl}`)
  });

  /* useEffect(() => {
    if (searchUrl === null) return false;
    history.push(`${history.location.pathname}?${searchUrl || ''}`);
    console.log(searchUrl, 'STRT LOAD DATA');

    // console.log(isPageChanged, 'isPageChanged');
    if (!isPageChanged.filters) {
      return loadPage();
    }
    return setUrlDebounced();
  }, [
    searchUrl,
  ]); */

  // const searchUrl = useSelector(state => state.table.tableFilterUrl);
  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  // const firstUpdate = useRef(true);
  /* const setUrl = () => {
    const s = new URLSearchParams(searchUrl);
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      s.set('page', '1');
    }
    // search.set('filters.allRequiredDataIsSet', 'true');
    history.push(`${history.location.pathname}?${s.toString()}`);
  }; */
  const downloadCsv = () => {
    const search = searchUrl;
    goToPage(`/api/portal/prospects/get-insertion-order-csv?${search}`);
  };
  /* const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 800);
*/
  /* useEffect(() => {
    if (searchUrl === null) return false;
    return setUrlDebounced();
  }, [
    searchUrl,
  ]); */
  console.log(searchUrl, 'SEARCH');
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'CPM Program', path: null },
          { title: 'Insertion Orders', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Insertion Orders</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                // setVersion={setVersion}
                downloadCsv={downloadCsv}
              />
              <Row style={{ marginTop: '-10px' }}>
                {searchUrl ? <Table
                  action={action}
                  resource={resource}
                  dispatch={dispatch}
                  addNotice={addNotice}
                  // version={version}
                  // setVersion={setVersion}
                  recordsRef={recordsRef}
                /> : null}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <DeleteModalDialog afterSaveFunc={() => loadPage()} />
      <UploadInsertionOrderModal setVersion={() => loadPage()} />
      <FetchContentReleaseDate setVersion={() => loadPage()} />
      <FileHistroryModal />
      <PublishLookerInsertinOrder />
      <PublishInsertionOrderChangeLogHistoryModal />
      <PublishLookerInsertinOrderBulk />
    </Container>
  );
};
CPMProspectsListContainer.propTypes = {
  addNotice: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withNotice(withRouter(CPMProspectsListContainer));
