import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import UserPropertyType from './index';
import TableCellContextMenu from './TableCellContextMenu';
import ApprovalStatus from './ApprovalStatus';
import DescriptionField from './DescriptionField';
import PaidDateField from './PaidDateField';
import MatTableList from '../../../../../../../shared/tables/materialTable/MatTableList';
import { getServerDate } from '../../../../../../../shared/helpers/WVFormatter';
import { modalId } from './Modal/DeleteDialog';
import { modalId as uploadInvoiceModal } from './Modal/UploadInvoiceModal';
import { modalId as fileHistoryModalId } from './Modal/FileHistroryModal';
import Hook from '../../../../../../../shared/hooks';

const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

const Table = React.memo(({
  action,
  resource,
  version,
  setVersion,
  setMetaTotal,
  recordsRef,
}) => {
  // const dispatch = useDispatch();
  const [showFileHistoryModalId] = Hook.useShowModalHook(fileHistoryModalId);
  const [showUploadInvoiceModal] = Hook.useShowModalHook(uploadInvoiceModal);
  const [showDeleteModal] = Hook.useShowModalHook(modalId);
  return (
    <MatTableList
      action={action}
      resource={resource}
      date={new Date()}
      version={version}
      UserPropertyType={UserPropertyType}
      showFilters={false}
      showTitle={false}
      sortBy="createdAt"
      direction="desc"
      v={2}
      noActions
      isResizable={false}
      useFlex
      useCheckboxes
      setMetaTotal={setMetaTotal}
      recordsRef={recordsRef}
      enableWarning={false}
      notFoundProps={{ title: 'No CPM Invoices Found' }}
      useUnflatten
      customColumns={[
          {
            id: 'search',
            Header: () => 'Invoice No.',
            accessor: record => (record?.params?.invoiceNumber || ''),
            Cell: p => useMemo(() => (
              p.row.original.params?.invoiceNumber ? (
                <a
                  href={`/research/invoice-prospects/${p.row.original.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {p.value}
                </a>
              ) : ''), [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
          },
          {
            id: 'startDate',
            Header: () => 'Start Date',
            accessor: record => (record?.params?.startDate || ''),
            Cell: p => useMemo(() => (
              p.row.original.params?.createdAt ? (
                <div style={{ width: '160px', paddingRight: '10px' }}>
                  <span>{getServerDate(p.row.original.params.startDate)}</span>
                </div>
              ) : null), [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
          },
          {
            id: 'endDate',
            Header: () => 'End Date',
            accessor: record => (record?.params?.endDate || ''),
            Cell: p => useMemo(() => (
              p.row.original.params?.createdAt ? (
                <div style={{ width: '160px', paddingRight: '10px' }}>
                  <span>{getServerDate(p.row.original.params.endDate)}</span>
                </div>
              ) : null), [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
          },
          {
            id: 'brand',
            Header: () => 'Brand',
            Cell: p => p.row.original.populated?.brand?.params?.companyName || '',
            width: 130,
            className: 'd-flex align-items-center',
            sortable: true,
          },
          {
            id: 'status.status',
            Header: () => 'Status',
            Cell: p => useMemo(() => (
              <ApprovalStatus
                record={p.row.original}
                afterSaveFunc={() => setVersion((new Date()).toString())}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 150,
            className: 'd-flex align-items-center',
            sortable: true,
          },
          {
            id: 'datePaid',
            Header: () => 'Date Paid',
            accessor: record => (record?.params?.dateDaid || ''),
            Cell: p => useMemo(() => (
              <PaidDateField
                record={p.row.original}
                afterSaveFunc={() => setVersion((new Date()).toString())}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 150,
            className: 'd-flex align-items-center',
          },
          {
            id: 'description',
            Header: () => 'Description',
            accessor: record => (record?.params?.description || ''),
            Cell: p => <DescriptionField record={p.row.original} />,
            className: 'd-flex align-items-center',
            disableSortBy: true,
          },
          {
            Header: '',
            accessor: p => (p.params.googleSheetFiles?.length > 0 ? p.params.googleSheetFiles[p.params.googleSheetFiles.length - 1] : null),
            id: 'cpm_prospect_actions_files',
            width: 60,
            maxWidth: 60,
            // eslint-disable-next-line react/prop-types
            Cell: ({ value }) => {
              // console.log(value);
              if (!value) return '';
              // eslint-disable-next-line react/prop-types
              const { fileUrl, fileId } = value;
              return (
                <div>
                  <a href={fileUrl} title="Open Google Sheet File" target="_blank" rel="noreferrer" style={{ fontSize: '30px' }} className="pr-2 text-dark"><i className="fa fa-file-excel" /></a>
                  <a
                    // eslint-disable-next-line react/prop-types
                    href={`https://docs.google.com/spreadsheets/d/${fileId}/export?format=pdf&gridlines=false`}
                    title="Download PDF File"
                    target="_blank"
                    rel="noreferrer"
                    className="text-dark"
                    style={{ fontSize: '30px' }}
                  ><i className="fa fa-file-pdf" />
                  </a>
                </div>
              );
            },
            disableSortBy: true,
          },
          {
            Header: '',
            accessor: () => null,
            id: 'cpm_prospect_actions_delete',
            width: 50,
            Cell: (p) => {
              // console.log(p.row.original);
              const contextMenuOptions = [
                { type: 'header', label: 'Invoice' },
                { label: 'View', handler: () => goToPage(`/research/invoice-prospects/${p.row.original.id}`) },
                {
                  label: 'View Publish History',
                  handler: () => showFileHistoryModalId({ record: p.row.original }),
                  disabled: !(p.row?.original?.params?.googleSheetFiles && p.row.original.params.googleSheetFiles.length > 0),
                },
                {
                  label: 'Upload Signed PDF',
                  handler: () => showUploadInvoiceModal({ record: p.row.original }),
                  disabled: !(p.row?.original?.params?.googleSheetFiles && p.row.original.params.googleSheetFiles.length > 0),
                },
                { type: 'divider', label: 'divider1' },
                { label: 'Delete', handler: () => showDeleteModal({ record: p.row.original }), disabled: (p.row?.original?.params?.status?.status !== 'New' || p.row?.original?.params?.cpmProspects?.length > 0) },
              ];
              return (
                <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                  <TableCellContextMenu options={contextMenuOptions} />
                </div>
              );
            },
            disableSortBy: true,
            className: 'd-flex align-items-center align-middle',
          },
        ]}
    />
  );
}, (a, a1) => _.isEqual(a.version, a1.version) && _.isEqual(a.brands, a1.brands));

Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  setVersion: PropTypes.func.isRequired,
  setMetaTotal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  recordsRef: PropTypes.any.isRequired,
};

export default Table;
