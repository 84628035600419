/* eslint react/prop-types: 0 */
/* eslint-disable camelcase */
import React from 'react';
import { unflatten } from 'flat';
import _ from 'lodash';

export default React.memo((props) => {
  const record = unflatten(props.record);
  const { params: { ftc_compliance_status } } = record;

  if (ftc_compliance_status) {
    return (
      <span>
        {ftc_compliance_status}
      </span>
    );
  }
  return '';
}, (a, a1) => _.isEqual(a.record, a1.record));
