import React, { useState, useEffect } from 'react';
import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import CurrencyField from '../../../../../../shared/components/form/CurrencyField';


const RevenueAmount = ({ record, updateRecordFunction }) => {
  const a = record.params.revenueAmount;
  const [amount, setAmount] = useState(a);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setAmount(a);
  }, [a]);
  const handleChange = (event, value) => {
    // console.log(event);
    // this.setState({ loading: true });
    setLoading(true);
    setAmount(value);
    // const { value } = event.target;
    const api = new ApiClient();
    const { id } = record;
    const data = new FormData();
    data.append('revenueAmount', value);
    api.recordAction({
      resourceId: 'Payable',
      recordId: id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      api.recordAction({
        resourceId: 'Payable',
        recordId: id,
        actionName: 'show',
      }).then(() => {
        setLoading(false);
        updateRecordFunction(id, { revenueAmount: value });
        // updateRecordFunction(e.data.record, 'payoutAmount');
      });
    });
    return true;
  };
  return (
    <div className="px-2"><CurrencyField placeholder="Payout Amount" disabled={loading || record.checkboxDisable} defaultValue={amount} onBlur={handleChange} /></div>
  );
};
RevenueAmount.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};
export default RevenueAmount;


/* export default class RevenueAmount extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    updateRecordFunction: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.api = new ApiClient();
    const { record } = this.props;
    const amount = record.params.revenueAmount;
    this.state = {
      amount,
      loading: false,
    };
  }
  handleChange(event, value) {
    // console.log(event, value);
    this.setState({ loading: true });
    const { record, updateRecordFunction } = this.props;

    // const { value } = event.target;
    const { id } = record;
    const data = new FormData();
    data.append('revenueAmount', value);
    this.api.recordAction({
      resourceId: 'Payable',
      recordId: id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      this.api.recordAction({
        resourceId: 'Payable',
        recordId: id,
        actionName: 'show',
      }).then(() => {
        this.setState({ loading: false });
        updateRecordFunction(id, { revenueAmount: value });
        // updateRecordFunction(e.data.record, 'revenueAmount');
      });
    });
    return true;
  }

  render() {
    // const { record } = this.props;
    const { loading, amount } = this.state;
    const { record } = this.props;
    return (
      <div className="px-2"><CurrencyField placeholder="Payout Amount" disabled={loading || record.checkboxDisable} defaultValue={amount} onBlur={this.handleChange} /></div>
    );
  }
} */
