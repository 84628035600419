import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { CKEditor } from 'ckeditor4-react';
import PropTypes from 'prop-types';
import {
  showChangeMediaContentStatusDialog,
  updateMediaContentStatus,
} from '../../../../../../../redux/reducers/admin/ScoringReducer';

const UpdatePendingStatusModal = ({
  showChangeStatusDialog,
  dispatch,
  mediaContentObj,
  setVersion,
}) => {
  const [selectedNotes, setSelectedNotes] = useState(null);
  const [futureStatus, setFutureStatus] = useState('Rescore');

  const handleChangeStatusDialogClose = () => {
    dispatch(showChangeMediaContentStatusDialog(false));
  };

  const handleChangeNotes = (event) => {
    const { editor } = event;
    setSelectedNotes(editor.getData());
  };

  const handleChangeStatusDialogSubmit = () => {
    const status = mediaContentObj.status === 'New' || mediaContentObj.status === 'Rescore' ? 'Pending Edits' : 'Rescore';
    const data = { notes: selectedNotes, status };
    dispatch(updateMediaContentStatus(mediaContentObj.id, data, mediaContentObj.status));
    dispatch(showChangeMediaContentStatusDialog(false));
    setSelectedNotes(null);
    setVersion((new Date()).toString());
  };

  useEffect(() => {
    if (showChangeStatusDialog && mediaContentObj?.status && (mediaContentObj.status === 'New' || mediaContentObj.status === 'Rescore')) {
      setFutureStatus('Pending Edits');
    }
  }, [showChangeStatusDialog]);

  return (
    <Modal
      isOpen={showChangeStatusDialog}
      toggle={handleChangeStatusDialogClose}
      size="sm"
      style={{ maxWidth: '700px' }}
    >
      <ModalHeader toggle={handleChangeStatusDialogClose} tag="h4">{`Change Media Content Status from ${mediaContentObj?.status} to ${futureStatus}`}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Notes</Label>
          <CKEditor
            config={{versionCheck: false}}
            id="bodyEditor"
            name="body"
            initData={mediaContentObj?.notes ? mediaContentObj.notes : ''}
            onChange={handleChangeNotes}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-sm"
          color="primary"
          onClick={handleChangeStatusDialogSubmit}
        >
          Save
        </Button>{' '}
        <Button
          className="btn-sm"
          color="secondary"
          onClick={handleChangeStatusDialogClose}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UpdatePendingStatusModal.propTypes = {
  showChangeStatusDialog: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  mediaContentObj: PropTypes.objectOf(PropTypes.any),
  setVersion: PropTypes.func.isRequired,
};

UpdatePendingStatusModal.defaultProps = {
  mediaContentObj: {},
};

const mapStateToProps = state => ({
  showChangeStatusDialog: state.score.showChangeStatusDialog,
  mediaContentObj: state.score.mediaContent,
});

export default connect(mapStateToProps)(UpdatePendingStatusModal);
