import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Spinner } from 'reactstrap';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';

const columnTransform = {
  email: {
    title: 'Email',
    valueFunc: record => (record.email || ''),
    sort: record => (record.email || ''),
    searchFunc: (record, search) => (record.email ? record.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),
  },
  name: {
    title: 'Name',
    valueFunc: record => (record.name || ''),
    sort: record => (record.name || ''),
    searchFunc: (record, search) => (record.name ? record.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),
  },
  id: {
    title: 'Account ID',
    valueFunc: record => (record.id || ''),
    sort: record => (record.id || ''),
  },
  addressCountry: {
    title: 'Country',
    valueFunc: record => (record.addressCountry || ''),
    sort: record => (record.addressCountry || ''),
  },
  phone: {
    title: 'Phone',
    valueFunc: record => (record.phone || record.paymentPhone || ''),
    sort: record => (record.phone || record.paymentPhone || ''),
  },
};

const csvArrayColumns = [
  {
    label: 'Email',
    func: record => (record.email || ''),
  },
  {
    label: 'Name',
    func: record => (record.name || ''),
  },
  {
    label: 'Account ID',
    func: record => (record.id || ''),
  },
  {
    label: 'Address Country',
    func: record => (record.addressCountry || ''),
  },
  {
    label: 'Phone',
    func: record => (record.phone || record.paymentPhone || ''),
  },
];

class NonMatchedVendorsTable extends PureComponent {
  render() {
    const {
      loading,
      accounts,
    } = this.props;

    return (
      <div>
        <h4 className="il-color-deep-blue font-weight-medium mb-0 my-3">Unnmatched Accounts</h4>
        {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        { !loading &&
          <Row>
            <MatTableForRedux
              dataArray={accounts}
              selectable={false}
              columns={columnTransform}
              version={(new Date()).toString()}
              defaultOrder={{
                order: 'desc',
                orderBy: 'email',
              }}
              rowsPerPage={10}
              csvDownload={{
                fileName: 'UnnmatchedBillComAccounts.csv',
                csvArrayColumns,
              }}
            />
          </Row>
        }
      </div>
    );
  }
}

NonMatchedVendorsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default NonMatchedVendorsTable;
