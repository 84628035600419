import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Spinner } from 'reactstrap';
import { Collapse } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ApiClient from 'ApiClient';
import SelectAsync from 'react-select/async';
import PropTypes from 'prop-types';
import StyledButton from '../../../../../../shared/components/components/ui/styled-button';
import {
  fetchMediaContent,
  getScorecardAnswers,
  getScorecardQuestions,
} from '../../../../../../redux/reducers/admin/ScoringReducer';
import ScorecardsAnswersForm from '../ScorecardsAnswersForm';
import MediaContentDetails from '../MediaContentDetails';
import Breadcrumbs from '../../../../../../shared/components/BreadCrumbs';

class ScorecardAnswersSheetEdit extends React.Component {
  static async loadMediaContentOptions(inputValue) {
    const api = new ApiClient();
    const response = await api.client.get(`/api/portal/scorecard/search/get-media-content-data?term=${inputValue}`);
    return response.data.mediaContent.map(data => ({
      value: data._id,
      label: data.name,
      data,
    }));
  }

  constructor(props) {
    super(props);
    this.api = new ApiClient();
    this.handleCancelAction = this.handleCancelAction.bind(this);
    this.handleChangeSearchContent = this.handleChangeSearchContent.bind(this);
    this.handleChangeFTPCompliant = this.handleChangeFTPCompliant.bind(this);
    this.handleChangePaidPromotion = this.handleChangePaidPromotion.bind(this);
    this.handleChangeOscarWorthy = this.handleChangeOscarWorthy.bind(this);
    this.handleScoreButtonClick = this.handleScoreButtonClick.bind(this);
    this.selected = null;
    this.state = {
      selectedMediaContent: null,
      selectedAnswers: null,
      createNew: false,
      open: false,
      disableScoreButton: true,
      hideForm: false,
      answerIdFromUrl: null,
      isFTCCompliant: null,
      includesPaidPromotion: null,
      isOscar: null,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    if (match && match.params && match.params.mediaContentId) {
      dispatch(fetchMediaContent(match.params.mediaContentId)).then(() => {
        this.forceSearchOnChange();
      });
    }
  }

  forceSearchOnChange() {
    const { mediaContent, match } = this.props;
    if (match && match.params && match.params.answersId) {
      this.setState({ answerIdFromUrl: match.params.answersId });
    }
    if (mediaContent) {
      this.handleChangeSearchContent(mediaContent);
    }
  }

  handleChangeFTPCompliant(e) {
    this.setState({
      isFTCCompliant: e.target.value === '1',
    });
  }
  handleChangePaidPromotion(e) {
    this.setState({
      includesPaidPromotion: e.target.value === '1',
    });
  }
  handleChangeOscarWorthy(e) {
    this.setState({
      isOscar: e.target.value === '1',
    });
  }
  handleChangeSearchContent(selected) {
    const { dispatch, match } = this.props;
    if (selected && selected.data) {
      this.setState({
        selectedMediaContent: selected.data,
        open: false,
        selectedAnswers: null,
        createNew: false,
      });
      dispatch(getScorecardAnswers(selected.data._id)).then(() => {
        this.setState({ open: true, disableScoreButton: false });
        dispatch(getScorecardQuestions(selected.data.type)).then(() => {
          const { answerIdFromUrl } = this.state;
          if (match && match.params && match.params.answersId && answerIdFromUrl) {
            const { answers } = this.props;
            const result = answers.filter(item => (item.data._id === match.params.answersId));
            if (result.length > 0) {
              this.setState({
                selectedAnswers: result[0],
                createNew: false,
                hideForm: false,
                answerIdFromUrl: null,
                isFTCCompliant: typeof result[0].data !== 'undefined' ? result[0].data.isFTCCompliant : null,
                includesPaidPromotion: typeof result[0].data !== 'undefined' ? result[0].data.includesPaidPromotion : null,
                isOscar: typeof result[0].data !== 'undefined' ? result[0].data.isOscar : null,
              });
            }
          }
        });
      });
    }
  }
  handleScoreButtonClick() {
    const { answers, adminUser } = this.props;
    let existingAnswer = [];
    if (answers && answers.length > 0) {
      existingAnswer = answers.filter(item => (item.key === adminUser._id));
    }
    if (existingAnswer.length > 0) {
      this.setState({
        selectedAnswers: existingAnswer[0],
        isFTCCompliant: typeof existingAnswer[0].data !== 'undefined' ? existingAnswer[0].data.isFTCCompliant : null,
        includesPaidPromotion: typeof existingAnswer[0].data !== 'undefined' ? existingAnswer[0].data.includesPaidPromotion : null,
        isOscar: typeof existingAnswer[0].data !== 'undefined' ? existingAnswer[0].data.isOscar : null,
        createNew: false,
        hideForm: false,
      });
    } else {
      this.setState({
        selectedAnswers: null,
        createNew: true,
        hideForm: false,
        isFTCCompliant: null,
        includesPaidPromotion: null,
        isOscar: null,
      });
    }
  }
  handleCancelAction() {
    this.setState({ hideForm: true });
  }
  render() {
    const {
      selectedMediaContent,
      open,
      selectedAnswers,
      createNew,
      disableScoreButton,
      hideForm,
      isFTCCompliant,
      includesPaidPromotion,
      isOscar,
    } = this.state;
    const {
      answers,
      loading,
      scorecardData,
      adminUser,
      mediaContent,
    } = this.props;
    let selectedMediaContentOption = {};
    if (selectedMediaContent) {
      selectedMediaContentOption = {
        value: selectedMediaContent._id,
        label: selectedMediaContent.name,
      };
    } else if (mediaContent && mediaContent.data) {
      selectedMediaContentOption = {
        value: mediaContent.data._id,
        label: mediaContent.data.name,
      };
    }
    let scoredByName;
    let titleScorecard;
    let scorecardQuestions;
    if (createNew && scorecardData) {
      scoredByName = `${adminUser?.firstName} ${adminUser?.lastName}`;
      titleScorecard = 'New Scorecard';
      scorecardQuestions = scorecardData.scorecard;
    }
    if (selectedAnswers) {
      scoredByName = selectedAnswers.scoreBy;
      titleScorecard = 'Edit Scorecard';
      scorecardQuestions = selectedAnswers.data.scorecard;
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs
              isBackButton
              links={[
                { title: 'Home', path: '/' },
                { title: 'Compliance', path: false },
                { title: 'Score Content', path: false },
              ]}
            />
          </Col>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="row justify-content-center">
                  <Col sm={7}>
                    <h3>Score Content</h3>
                  </Col>
                </CardTitle>
                <div className="row justify-content-center">
                  <Col sm={7}>
                    <h4>Search</h4>
                    <FormGroup row>
                      <Col sm={9}>
                        <SelectAsync
                          cacheOptions
                          value={selectedMediaContentOption}
                          defaultOptions
                          loadOptions={ScorecardAnswersSheetEdit.loadMediaContentOptions}
                          onChange={this.handleChangeSearchContent}
                        />
                      </Col>
                      <Col sm={3}>
                        <StyledButton
                          type="button"
                          className="is-primary"
                          disabled={disableScoreButton}
                          onClick={this.handleScoreButtonClick}
                        >
                          <i className="icomoon-save" />
                          <span className="btn-text">Score</span>
                        </StyledButton>
                      </Col>
                    </FormGroup>
                    {(loading) &&
                    (
                      <div className="text-center"><Spinner color="primary" size="lg" /></div>
                    )
                    }
                    {answers && answers.length > 0 && (
                      <Collapse in={open} timeout="auto">
                        <h4 style={{ marginBottom: 10 }}>Existing Scorecards</h4>
                        <Divider style={{ marginBottom: 10 }} />
                        <Row style={{ marginBottom: 10 }}>
                          <Col sm={7}>
                            <Typography gutterBottom variant="h6">Scored By</Typography>
                          </Col>
                          <Col sm={2}>
                            <Typography gutterBottom variant="h6" align="center">Score</Typography>
                          </Col>
                          <Col sm={3} />
                        </Row>
                        {answers.map(item => (
                          <Row key={item.key}>
                            <Col sm={7}>
                              <Typography gutterBottom variant="subtitle2">{item.scoreBy}</Typography>
                            </Col>
                            <Col sm={2}>
                              <Typography gutterBottom variant="body2" align="center">{item.totalScore || 0}</Typography>
                            </Col>
                            <Col sm={3}>
                              <StyledButton
                                type="button"
                                className="is-primary"
                                onClick={() => {
                                  this.setState({
                                    selectedAnswers: item,
                                    createNew: false,
                                    hideForm: false,
                                    isFTCCompliant: typeof item.data !== 'undefined' ? item.data.isFTCCompliant : null,
                                  });
                                }}
                                style={{ marginLeft: 12, marginBottom: 10 }}
                              >
                                <i className="icomoon-save" />
                                <span className="btn-text">Edit</span>
                              </StyledButton>
                            </Col>
                          </Row>
                        ))}
                      </Collapse>
                    )}
                  </Col>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {(selectedAnswers || (createNew && scorecardData)) && !hideForm && (
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <MediaContentDetails
                    title={titleScorecard}
                    scoredBy={scoredByName}
                    mediaContent={selectedMediaContent}
                  />
                  <ScorecardsAnswersForm
                    answers={selectedAnswers}
                    questions={scorecardQuestions}
                    mediaContentId={selectedMediaContent._id}
                    mediaContentText={selectedMediaContent.contentText}
                    mediaContentTranscript={selectedMediaContent.transcriptionText || ''}
                    type={selectedMediaContent.type}
                    allowChangeStatus
                    action="edit"
                    handleCancelAction={this.handleCancelAction}
                    isFTCCompliant={isFTCCompliant}
                    includesPaidPromotion={includesPaidPromotion}
                    isOscar={isOscar}
                    handleChangeFTPCompliant={this.handleChangeFTPCompliant}
                    handleChangePaidPromotion={this.handleChangePaidPromotion}
                    handleChangeOscarWorthy={this.handleChangeOscarWorthy}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

ScorecardAnswersSheetEdit.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  scorecardData: PropTypes.objectOf(PropTypes.any),
  adminUser: PropTypes.objectOf(PropTypes.any).isRequired,
  mediaContent: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

ScorecardAnswersSheetEdit.defaultProps = {
  loading: null,
  answers: null,
  scorecardData: null,
  mediaContent: null,
};

const mapStateToProps = state => ({
  loading: state.score.loading.fetchScorecardAnswersContent,
  answers: state.score.answers,
  scorecardData: state.score.scorecard,
  adminUser: state.session,
  mediaContent: state.score.mediaContent,
});


export default connect(mapStateToProps)(ScorecardAnswersSheetEdit);
