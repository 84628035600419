import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
// import TopbarMail from './TopbarMail';
// import TopbarNotification from './TopbarNotification';
// import TopbarSearch from './TopbarSearch';
// import { UserProps } from '../../../shared/prop-types/ReducerProps';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    // eslint-disable-next-line
    session: PropTypes.object.isRequired,
    // eslint-disable-next-line
  };

  constructor() {
    super();
    this.state = {
      hsTrialDayLeft: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    // console.log('Fetch Date');
    if (process.env.NODE_ENV !== 'production') {
      axios.get('/api/portal/hubil/get-hs-trial-day-left').then((response) => {
        if (response.data.success) {
          this.setState({ hsTrialDayLeft: response.data.days });
        }
      });
    }
  };

  render() {
    const {
      changeMobileSidebarVisibility, changeSidebarVisibility, session,
    } = this.props;
    const { hsTrialDayLeft } = this.state;

    return (
      <div className="topbar">
        <div className="topbar__wrapper" style={window.process.env.NODE_ENV !== 'production' ? { backgroundColor: '#7cbbff' } : {}}>
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className="topbar__logo" to="/" />
            {window.process.env.NODE_ENV !== 'production' && hsTrialDayLeft && (
              <span style={{ margin: 'auto 30px' }}>{`We  have ${hsTrialDayLeft} days left in our Developer Test Portal trial.`}</span>
            )}
          </div>
          <div className="topbar__right">
            {/* <TopbarSearch /> */}
            <TopbarProfile session={session} />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
