import { unflatten } from 'flat';
import Record from '../components/components/Record';

// import formater from '../helpers/WVFormatter';

export default class AgentDeal extends Record {
  // eslint-disable-next-line class-methods-use-this
  initRecord() {
    return {
      record: {},
      stateName: 'deal',
      resource: 'HubilDeal',
      schema: {
        contact_email: {
          title: 'Contact Email',
          required: true,
        },
        first_name: {
          title: 'First Name',
          required: true,
        },
        last_name: {
          title: 'Last Name',
          required: true,
        },
        company: {
          title: 'Company',
          required: true,
        },
        dealname: {
          title: 'Deal Name',
          required: true,
        },
        type: {
          title: 'Type',
        },
        agent: {
          title: 'Agent',
        },
        agentUser: {
          title: 'Agent',
        },
        pipeline: {},
        dealstage: {},
        hsOwner: {
          title: 'Hubspot Owner',
          required: true,
        },
        commission_percentage: {
          title: 'Percentage',
          validate: function validate(value) {
            console.log(this.get('type'));
            if (!this.get('type') || (this.get('type') === 'percentage' && value === null)) {
              throw new Error('Invalid percent');
            }
            return true;
          },
        },
        commission_amount: {
          title: 'Amount',
          validate: function validate(value) {
            console.log(this.get('type'));
            if (!this.get('type') || (this.get('type') === 'amount' && value === null)) {
              throw new Error('Invalid Amount');
            }
            return true;
          },
        },

      },
    };
  }
  async fetchByContact(id) {
    try {
      const resp = await this.api.client.get(`/api/portal/agent/get-deal-data?agentContactId=${id}`);
      if (!resp.data.deal) throw new Error('Invalid Responce');
      const res = unflatten(resp.data.deal);
      this.setRecord({
        params: {
          agentUser: res?.params?.agentUser?._id,
          contact_email: res.params.agentsContact.email,
          first_name: res.params.agentsContact.firstname,
          last_name: res.params.agentsContact.lastname,
          company: res.params.agentsContact.company,
          agent: res.params.agentsContact._id,
          dealname: res.params.dealname,
          pipeline: res.params.pipeline,
          dealstage: res.params.dealstage,
          commission_percentage: res.params.dealTerms.commission_percentage,
          commission_amount: res.params.dealTerms.commission_amount,
          type: res.params.dealTerms.commission_amount ? 'amount' : 'percentage',
          hsOwner: res.params.hubspotOwner?.hsUserId,
          selectedAgent: { value: res.params.agentsContact._id, label: res.params.agentsContact.email },
          selectedHSOwnerOption: { value: res.params.hubspotOwner?.hsUserId, label: `${res.params.hubspotOwner?.firstName || ''} ${res.params.hubspotOwner?.lastName || ''}` },
        },
        errors: {},
        id: res?.params._id,
        populated: {},
      });
    } catch (e) {
      console.log(e);
    }
    console.log(this.params);
  }
  async fetchRecord(id) {
    const resp = await this.api.client.get(`/api/portal/agent/get-deal-data?agentId=${id}`);
    const res = unflatten(resp.data.deal);
    this.setRecord({
      params: {
        agentUser: id,
        agentUserObj: res?.params?.agentUser || null,
        agentsContact: res?.params?.agentsContact || null,
        contact_email: res.params.agentsContact?.email || '',
        first_name: res.params.agentsContact?.firstname || '',
        last_name: res.params.agentsContact?.lastname || '',
        company: res.params.agentsContact?.company || '',
        agent: res.params.agentsContact?._id || null,
        dealname: res.params.dealname || '',
        pipeline: res.params.pipeline || '',
        dealstage: res.params.dealstage || '',
        commission_percentage: res.params.dealTerms?.commission_percentage || (res.params.dealTerms?.commission_amount ? '' : 100),
        commission_amount: res.params.dealTerms?.commission_amount || null,
        type: res.params.dealTerms?.commission_amount ? 'amount' : 'percentage',
        hsOwner: res.params.hubspotOwner?.hsUserId || '',
        selectedAgent: res.params.agentsContact ? { value: res.params.agentsContact._id, label: res.params.agentsContact.email } : null,
        selectedHSOwnerOption: res.params.hubspotOwner ? { value: res.params.hubspotOwner?.hsUserId, label: `${res.params.hubspotOwner?.firstName || ''} ${res.params.hubspotOwner?.lastName || ''}` } : null,
      },
      errors: {},
      id: res?.params._id,
      populated: {},
    });
    console.log(this.params);
  }
  async clear() {
    this.setRecord({
      params: {
        contact_email: '',
        first_name: '',
        last_name: '',
        company: '',
        agent: null,
        dealname: '',
        pipeline: null,
        dealstage: null,
        commission_percentage: 100,
        commission_amount: 0,
        type: 'percentage',
        hsOwner: null,
        selectedAgent: null,
        selectedHSOwnerOption: null,
      },
      errors: {},
      id: null,
      populated: {},
    });
  }
  async updateItemF() {
    const params = this.getSchemaParams();
    const res = await this.api.client.post('/api/portal/agent/post-save-deal-editor', {
      ...params,
      _id: this.id,
    });
    if (res.data.success === true) {
      return this.getModel();
    }
    if (res.data.error) {
      throw new Error(res.data.error);
    }
    throw new Error('Undable to update deal record');
  }
  async newItemF() {
    const params = this.getSchemaParams();
    const res = await this.api.client.post('/api/portal/agent/post-save-deal-editor', {
      ...params,
    });
    if (res.data.success === true) {
      return this.getModel();
    }
    if (res.data.error) {
      throw new Error(res.data.error);
    }
    throw new Error('Undable to create deal record');
  }
}
