// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import TuneIcon from 'mdi-react/TuneIcon';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { getCookie, setCookie } from '../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../shared/components/CheckBox';

const Actions = React.memo(({
  csvDownloadLink,
  searchValue,
  onSearchChange,
  tags,
  setTags,
  setTagsFilter,
  tagsSuggestions,
  addAgent,
  taxForm,
  setTaxForm,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('agentShowFilters') === 'true');

  const reactTags = React.createRef();

  useEffect(() => {
    setCookie('agentShowFilters', showFilters.toString(), 35);
  }, [showFilters]);
  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 500));
  }, [search]);

  const prepareTagsFilter = (tagsUpdated) => {
    const tagsFilterUpdated = {};
    if (tagsUpdated.length > 0) {
      tagsUpdated.map((item) => {
        tagsFilterUpdated[item.name] = true;
        return true;
      });
    } else {
      tagsFilterUpdated.getAllRecords = true;
    }
    return tagsFilterUpdated;
  };

  const onTagDelete = (i) => {
    const tagsUpdated = tags.slice(0);
    tagsUpdated.splice(i, 1);
    setTags(tagsUpdated);
    setTagsFilter(prepareTagsFilter(tagsUpdated));
  };

  const onTagAddition = (newTag) => {
    const tagsUpdated = [].concat(tags, newTag);
    setTags(tagsUpdated);
    setTagsFilter(prepareTagsFilter(tagsUpdated));
  };
  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setTags([]);
    setTagsFilter({ getAllRecords: true });
  };

  const handleChangeTaxFormFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setTaxForm({
        ...taxForm,
        [name]: checked,
      });
    }
  };

  return (
    <>
      <Row className="mt-3">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search First Name, Last Name, Company Name, Email"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '44px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col md={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={addAgent}>New Agent</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => window.open(csvDownloadLink, '_blank').focus()}>Download CSV</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <Row>
          <Col lg={6}>
            <Label className="bold-text">
              Tax Form
            </Label>
            <FormGroup style={{ marginBottom: '0' }}>
              <ReactTags
                ref={reactTags}
                tags={tags}
                suggestions={tagsSuggestions}
                onDelete={onTagDelete}
                onAddition={onTagAddition}
                delimiters={['Enter', ',']}
                allowNew
                placeholderText="Tags"
              />
            </FormGroup>
          </Col>
          <Col sm="auto">
            <Label className="bold-text">
              Tax Form
            </Label>
            <FormGroup style={{ marginBottom: '0' }}>
              <CheckBoxField
                name="taxFormYes"
                label="On File"
                value={taxForm.Yes}
                onChange={event => handleChangeTaxFormFilters(event, 'Yes')}
              />
            </FormGroup>
            <FormGroup>
              <CheckBoxField
                name="taxFormNo"
                label="Missing"
                value={taxForm.No}
                onChange={event => handleChangeTaxFormFilters(event, 'No')}
              />
            </FormGroup>
          </Col>
          <Col />
        </Row>
      )}
    </>
  );
}, (a, a1) =>
  a.csvDownloadLink === a1.csvDownloadLink &&
  a.searchValue === a1.searchValue &&
  _.isEqual(a.tags, a1.tags) &&
  _.isEqual(a.taxForm, a1.taxForm) &&
  _.isEqual(a.tagsSuggestions, a1.tagsSuggestions));
  Actions.propTypes = {
  csvDownloadLink: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTags: PropTypes.func.isRequired,
  setTagsFilter: PropTypes.func.isRequired,
  tagsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  addAgent: PropTypes.func.isRequired,
  taxForm: PropTypes.objectOf(PropTypes.any).isRequired,
  setTaxForm: PropTypes.func.isRequired,
};
export default Actions;
