import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Select from 'react-select';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import IntervalDatePickerField
  from '../../../../../Influencer/Research/Prospects/components/custom/IntervalDatePickerField';

const customStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#e9ecef' : 'white',
  }),
};

const CommentDateFilter = ({
  enableCommentDateFilter,
  setEnableCommentDateFilter,
  commentDateFilterType,
  setCommentDateFilterType,
  dateRangeFilterLabels,
  setCommentDateFrom,
  setCommentDateTo,
  commentDateFrom,
  commentDateTo,
  singleCommentDate,
  setSingleCommentDate,
  minDate,
}) => (
  <>
    <Label className="bold-text">Comment Date</Label>
    <Row>
      <Col sm={1}>
        <FormGroup
          style={{ paddingTop: '8px', paddingLeft: '8px' }}
        >
          <CheckBoxField
            name="CommentDate"
            value={enableCommentDateFilter}
            onChange={() => { setEnableCommentDateFilter(!enableCommentDateFilter); }}
          />
        </FormGroup>
      </Col>
      <Col sm={4} style={{ paddingLeft: '20px', paddingRight: 0 }}>
        <Select
          value={commentDateFilterType}
          onChange={(option) => {
            setCommentDateFilterType(option);
          }}
          options={dateRangeFilterLabels}
          isDisabled={!enableCommentDateFilter}
          styles={customStyles}
        />
      </Col>
      <Col sm={6} style={{ paddingRight: '0px' }}>
        {commentDateFilterType.value === 'Between' ? (
          <div style={{ marginRight: '-30px' }}>
            <IntervalDatePickerField
              onChange={({ start, end }) => {
                setCommentDateFrom(start);
                end?.setHours(23, 59, 59);
                setCommentDateTo(end);
              }}
              startDate={commentDateFrom}
              endDate={commentDateTo}
              maxDate={new Date()}
              minDate={minDate?.toDate()}
              placeholderFrom="Start Date"
              placeholderTo="End Date"
              verticalFields
              disabled={!enableCommentDateFilter}
              datePickerCustomClass="datepicker-in-profile"
            />
          </div>
        ) : (
          <div className="date-picker datepicker-in-profile">
            <DatePicker
              selected={singleCommentDate}
              onChange={(date) => {
                if (commentDateFilterType.value === '<=' || commentDateFilterType.value === '>') {
                  date?.setHours(23, 59, 59);
                }
                setSingleCommentDate(date);
              }}
              dateFormat="MMM d, yyyy"
              maxDate={new Date()}
              minDate={minDate?.toDate()}
              placeholderText="Select Date"
              dropDownMode="select"
              isClearable
              wrapperClassName="date-picker--interval w-100"
              className="form-control form-control-sm datepicker-in-profile w-100"
              disabled={!enableCommentDateFilter}
            />
            {singleCommentDate === null && (
              <svg
                className="mdi-icon"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '22px',
                  fill: '#999',
                  pointerEvents: 'none',
                }}
              >
                <path
                  d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                />
              </svg>
            )}
          </div>
        )}
      </Col>
    </Row>
  </>
);

CommentDateFilter.propTypes = {
  enableCommentDateFilter: PropTypes.bool.isRequired,
  setEnableCommentDateFilter: PropTypes.func.isRequired,
  commentDateFilterType: PropTypes.objectOf(PropTypes.any).isRequired,
  setCommentDateFilterType: PropTypes.func.isRequired,
  dateRangeFilterLabels: PropTypes.arrayOf(PropTypes.any).isRequired,
  setCommentDateFrom: PropTypes.func.isRequired,
  setCommentDateTo: PropTypes.func.isRequired,
  commentDateFrom: PropTypes.string,
  commentDateTo: PropTypes.string,
  singleCommentDate: PropTypes.objectOf(PropTypes.any),
  setSingleCommentDate: PropTypes.func.isRequired,
  minDate: PropTypes.objectOf(PropTypes.any),
};

CommentDateFilter.defaultProps = {
  commentDateFrom: null,
  commentDateTo: null,
  singleCommentDate: null,
  minDate: null,
};

export default CommentDateFilter;
