// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
} from 'reactstrap';
import SelectAsync from 'react-select/async';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import IntervalDatePicker from '../../../Bill/components/custom/IntervalDatePicker';
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import { setCookie, getCookie } from '../../../../../../shared/helpers/WVUtilities';

const Actions = React.memo(({
  searchString,
  paymentStatusFilters,
  setPaymentStatusFilters,
  onSearchChange,
  createDateFrom,
  setCreateDateFrom,
  createDateTo,
  setCreateDateTo,
  searchValue,
  apSelected,
  setApSelected,
  loadAPOptions,
  setShowFetchModalLoading,
  setShowNewInvoice,
  setErrors,
  fetchBills,
  classes,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [showFilters, setShowFilters] = useState(getCookie('receivablesInvoicesShowFilters') === 'true');
  const [dateSearch, setDateSearch] = useState(null);

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('receivablesInvoicesShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const handleChangePaymentStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setPaymentStatusFilters({
        ...paymentStatusFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setPaymentStatusFilters({
      paidinfull: true,
      partiallypaid: true,
      unpaid: true,
    });
    setCreateDateFrom(null);
    setCreateDateTo(null);
    setApSelected({});
  };

  return (
    <div className="prospects-actions">
      <Row className="mt-3">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search by Creator Name, Creator Email, Invoice Number and Amount"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '38px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '38px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => setShowNewInvoice(true)}
              >
                New Invoice
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setShowFetchModalLoading(true);
                  setErrors([]);
                  fetchBills();
                }}
              >
                Fetch Invoices
              </DropdownItem>
              <DropdownItem
                onClick={() => window.open(`/resources/Invoice/download-all-csv?${searchString}`, '_blank').focus()}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      {showFilters && (
        <>
          <Row className="mt-4">
            <Col sm={6}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Payment Status
              </Label>
              <Row>
                <Col sm={3}>
                  <FormGroup>
                    <CheckBoxField
                      name="paidinfull"
                      label="Paid In Full"
                      value={paymentStatusFilters.paidinfull}
                      onChange={event => handleChangePaymentStatusFilters(event, 'paidinfull')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <CheckBoxField
                      name="partiallypaid"
                      label="Partially Paid"
                      value={paymentStatusFilters.partiallypaid}
                      onChange={event => handleChangePaymentStatusFilters(event, 'partiallypaid')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <CheckBoxField
                      name="unpaid"
                      label="Unpaid"
                      value={paymentStatusFilters.unpaid}
                      onChange={event => handleChangePaymentStatusFilters(event, 'unpaid')}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col sm={4}>
              <IntervalDatePicker
                onChange={({ start, end }) => {
                  setCreateDateFrom(start);
                  end?.setHours(23, 59, 59);
                  // console.log(start, end);
                  setCreateDateTo(end);
                }}
                startDate={createDateFrom}
                endDate={createDateTo}
                maxDate={new Date()}
                placeholderFrom="Add Start Date"
                placeholderTo="Add End Date"
                titleFrom="Created Start Date (ET)"
                titleTo="Created End Date (ET)"
              />
            </Col>
            <Col sm={2}>
              <div
                className={classes.root}
                style={{
                  paddingRight: '0',
                }}
              >
                <Label className="bold-text">Select Accounts Payable</Label>
                <SelectAsync
                  cacheOptions
                  styles={{
                    control: provided => ({
                      ...provided,
                      minHeight: '44px',
                      maxHeight: '44px',
                      height: '44px',
                      width: '100%',
                    }),
                    indicatorsContainer: provided => ({
                      ...provided,
                      minHeight: '44px',
                      maxHeight: '44px',
                      height: '44px',
                    }),
                    valueContainer: provided => ({
                      ...provided,
                      minHeight: '44px',
                      maxHeight: '44px',
                      height: '44px',
                    }),
                  }}
                  value={apSelected}
                  defaultOptions
                  loadOptions={loadAPOptions}
                  onChange={setApSelected}
                  placeholder="Select"
                />
                <div
                  className={classes.close}
                  style={{
                    right: '42px',
                    bottom: '6px',
                    position: 'absolute',
                  }}
                >
                  {apSelected && apSelected.value && (
                    <IconButton
                      onClick={() => setApSelected(null)}
                      size="small"
                    >
                      <CloseCircleOutlineIcon />
                    </IconButton>)}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  _.isEqual(a.paymentStatusFilters, a1.paymentStatusFilters) &&
  _.isEqual(a.createDateTo, a1.createDateTo) &&
  _.isEqual(a.createDateFrom, a1.createDateFrom) &&
  _.isEqual(a.apSelected, a1.apSelected));

Actions.propTypes = {
  paymentStatusFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setPaymentStatusFilters: PropTypes.func.isRequired,
  apSelected: PropTypes.objectOf(PropTypes.any),
  onSearchChange: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  setApSelected: PropTypes.func.isRequired,
  loadAPOptions: PropTypes.func.isRequired,
  setShowFetchModalLoading: PropTypes.func.isRequired,
  setShowNewInvoice: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  fetchBills: PropTypes.func.isRequired,
  createDateFrom: PropTypes.objectOf(PropTypes.any),
  createDateTo: PropTypes.objectOf(PropTypes.any),
  setCreateDateFrom: PropTypes.func.isRequired,
  setCreateDateTo: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

Actions.defaultProps = {
  apSelected: null,
  createDateFrom: null,
  createDateTo: null,
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default withStyles({
  root: {
    width: '100%',
    minWidth: '200px',
    minHeight: '44px',
    paddingRight: '30px',
    position: 'relative',
    marginBottom: '10px',
  },
  close: {
    position: 'absolute',
    bottom: '1px',
    right: '0px',
  },
})(connect(mapStateToProps)(Actions));
