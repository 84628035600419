/* eslint react/prop-types: 0 */
import React from 'react';
// import { unflatten } from 'flat';
// import _ from 'lodash';
import { Link } from 'react-router-dom';
import CopyToClipboard from '@/shared/components/CopyToClipboard';


export default React.memo((props) => {

  const record = props.record?.params?.influencerObject;
  console.log(props, record);
  let prospect = null;
  const title = prospect?.title || '';


  const name = record?.fullName;
  if (!record) return null;
  return (
    <div
      className="d-flex"
    >
      <div
        className="align-self-center flex-column"
        style={{ marginLeft: '8px' }}
      >
        <div
          className=""
        >
          <Link
            className={title ? '' : 'bold-text'}
            to={`/influencer/profile/${record._id}`}
            rel="noreferrer"
            target="_blank"
          >
            {name}
          </Link>
        </div>
        <div
          className=""
        >
          <span
            className="bold-text"
          >
            {record.affiliateDataObject?.company || ''}
          </span>
        </div>
        {record.email ? (
          <CopyToClipboard
            value={record?.email || ''}
          >
            {record.email.length > 25 ? `${record.email.slice(0, 25)}...` : record.email}
          </CopyToClipboard>
        ) : null}
      </div>
    </div>
  );
});
