import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { NumericFormat as NumberFormat } from 'react-number-format';
import {
  InputGroup,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col
} from 'reactstrap';
import CheckBoxField from '../CheckBox';
import { numberRangeLabels } from '@/shared/helpers/Dates';


const AmountFilter = ({
  enable,
  filterType,
  amountFrom,
  amountTo,
  setValues
}) => {
  const numberRangeFilters = numberRangeLabels();
  const [enableAmountDueFilter, setEnableAmountDueFilter] = useState(false);
  const [amountDueFilterType, setAmountDueFilterType] = useState(numberRangeFilters[1]);
  const [amountDueFrom, setAmountDueFrom] = useState(0);
  const [amountDueTo, setAmountDueTo] = useState(0);
  const [singleAmountDue, setSingleAmountDue] = useState(0);

  useEffect(() => {
    if (typeof filterType === 'undefined') return false;
    if (filterType) setAmountDueFilterType(numberRangeFilters.find(f => f.value === filterType));
  }, [filterType])
  useEffect(() => {
    if (typeof amountFrom === 'undefined') return false;
    setAmountDueFrom(amountFrom);
    setSingleAmountDue(amountFrom);
  }, [amountFrom])
  useEffect(() => {
    if (typeof amountTo === 'undefined') return false;
    setAmountDueTo(amountTo);
  }, [amountTo])
  useEffect(() => {
    if (typeof enable === 'undefined') return false;
    setEnableAmountDueFilter(enable);
  }, [enable])

  // useEffect(() => {
  //   setValues({
  //     enable, filterType, amountFrom, amountTo, singleAmountDue,
  //   });
  // }, [enable, filterType, amountFrom, amountTo, singleAmountDue])
  return (
    <Row>
      <Col sm={1}>
        <FormGroup
          style={{ paddingTop: '8px', paddingLeft: '8px' }}
        >
          <CheckBoxField
            name="amountDueCheck"
            value={enableAmountDueFilter}
            onChange={(e) => {
              setEnableAmountDueFilter(!enableAmountDueFilter);
              if (e && e.target) setValues({ enable: !enableAmountDueFilter });
            }}
            defaultChecked={true}
          />
        </FormGroup>
      </Col>
      <Col sm={3} style={{ paddingLeft: '20px', paddingRight: 0 }}>
        <Select
          value={amountDueFilterType}
          onChange={(option) => {
            if ((amountDueFilterType.value !== 'Between' && option.value === 'Between') || (amountDueFilterType.value === 'Between' && option.value !== 'Between')) {
              setAmountDueFrom(0);
              setAmountDueTo(0);
              setSingleAmountDue(0);
            }
            setAmountDueFilterType(option);
            setValues({ filterType: option.value });
          }}
          options={numberRangeFilters}
          isDisabled={!enableAmountDueFilter}
          // styles={customStyles}
        />
      </Col>
      <Col sm={8}>
        {amountDueFilterType.value === 'Between' ? (
          <Row>
            <Col sm={6} style={{ paddingRight: '8px' }}>
              <InputGroup className="min-width-100">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <NumberFormat
                  key="amountMin"
                  disabled={!enableAmountDueFilter}
                  thousandSeparator
                  prefix=""
                  name="what"
                  value={amountDueFrom}
                  className="form-control form-control-sm"
                  placeholder="Min Amount"
                  inputMode="numeric"
                  allowEmptyFormatting={false}
                  fixedDecimalScale
                  decimalScale={0}
                  onValueChange={values => setAmountDueFrom(values.floatValue) || setValues({ amountFrom: values.floatValue })}
                  allowNegative={false}
                  onBlur={() => {
                    if (
                      amountDueFrom
                      && amountDueTo
                      && (amountDueFrom > amountDueTo)
                    ) {
                      
                      setAmountDueTo(amountDueFrom + 1);
                    }
                  }}
                  style={{ height: '38px' }}
                />
              </InputGroup>
            </Col>
            <Col sm={6} style={{ paddingLeft: '7px' }}>
              <InputGroup className="min-width-100">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <NumberFormat
                  key="amountMax"
                  disabled={!enableAmountDueFilter}
                  thousandSeparator
                  prefix=""
                  name="what"
                  value={amountDueTo}
                  className="form-control form-control-sm"
                  style={{ height: '38px' }}
                  placeholder="Max Amount"
                  inputMode="numeric"
                  allowEmptyFormatting={false}
                  fixedDecimalScale
                  decimalScale={0}
                  onValueChange={values => setAmountDueTo(values.floatValue) || setValues({ amountTo: values.floatValue })}
                  allowNegative={false}
                  onBlur={() => {
                    if (
                      amountDueFrom
                      && amountDueTo
                      && (amountDueFrom > amountDueTo)
                    ) {
                      setAmountDueFrom(amountDueTo.parseFloat() - 1);
                    }
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={6} style={{ paddingRight: '8px' }}>
              <InputGroup className="min-width-100">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <NumberFormat
                  key="singleAmount"
                  disabled={!enableAmountDueFilter}
                  thousandSeparator
                  prefix=""
                  name="what"
                  value={singleAmountDue}
                  defaultValue={0}
                  className="form-control form-control-sm"
                  placeholder="Amount"
                  inputMode="numeric"
                  // allowEmptyFormatting={false}
                  fixedDecimalScale
                  decimalScale={0}
                  onValueChange={values => setSingleAmountDue(values.floatValue) || setValues({ singleAmount: values.floatValue })}
                  allowNegative={false}
                  style={{ height: '38px', width: '47%' }}
                />
              </InputGroup>
            </Col>
            <Col sm={6} />
          </Row>
        )}
      </Col>
    </Row>
  );
};

AmountFilter.propTypes = {
  enable: PropTypes.bool.isRequired,
  filterType: PropTypes.string.isRequired,
  amountFrom: PropTypes.number.isRequired,
  amountTo: PropTypes.number.isRequired,
  setValues: PropTypes.func.isRequired,
};

export default AmountFilter;
