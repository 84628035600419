import React, {useState} from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import getTableColumns from './Columns';
import { getCookie, setCookie } from '../../../../../../shared/helpers/WVUtilities';
import ReactTableBase from '../../../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from "../../../../../../shared/tables/table/DownloadCSVButton";
import { getServerDate } from "../../../../../../shared/helpers/WVFormatter";

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  withSearchEngine: false,
  manualPageSize: [10, 20, 30, 40, 100],
  placeholder: 'Search...',
  sortBy: 'conversion_rate',
  direction: 'desc',
  useFlex: true,
};

const Table = ({ creators, creatorsInTimeRange }) => {
  const [activeTab, setActiveTab] = useState(getCookie('CPAFraudActiveTab') || 'newCpa');
  console.log('Table');

  const csvArrayColumns = [
    {
      label: 'Creator',
      func: record => (`${record.first_name || ''} ${record.last_name || ''}`),
    },
    {
      label: 'Deal Owner',
      func: record => (`${record.dealOwner?.firstName || ''} ${record.dealOwner?.lastName || ''}`),
    },
    {
      label: 'Brand',
      func: record => (record.deal?.dealTerms?.brand || ''),
    },
    {
      label: 'Date of First Conversion',
      func: record => (record.date_of_first_conversion ? getServerDate(record.date_of_first_conversion) : ''),
    },
    {
      label: 'URL Validation Status',
      func: record => (record.mediaContent?.urlStatus ? record.mediaContent.urlStatus : 'No Status'),
    },
    {
      label: 'Last Publication Date',
      func: record => (record.mediaContent?.publishDate ? getServerDate(record.mediaContent.publishDate) : ''),
    },
    {
      label: 'All Time Conversion',
      func: record => (record.conversions?.length ? record.conversions.length : 0),
    },
    {
      label: 'Conversion in Timeframe',
      func: record => (record.conversionsInTimeframe?.length ? record.conversionsInTimeframe.length : 0),
    },
    {
      label: 'Clicks in Timeframe',
      func: record => (record.rate?.clicks ? Number(record.rate.clicks) : 0),
    },
    {
      label: 'Conversion Rate',
      func: record => (record.rate ? `${Number(record.rate.ltr).toFixed(2)}%` : 0),
    },
  ];

  const columns = getTableColumns();

  return (
    <>
      <Row>
        <Col className="tabs">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'newCpa' })}
                onClick={() => {
                  setActiveTab('newCpa');
                  setCookie('CPAFraudActiveTab', 'newCpa', 2);
                }}
              >
                New CPA Creators
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'cvrCreators' })}
                onClick={() => {
                  setActiveTab('cvrCreators');
                  setCookie('CPAFraudActiveTab', 'cvrCreators', 2);
                }}
              >
                CVR Leading Creators
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="newCpa">
              <Row>
                <Col className="mt-9">
                  <h3 style={{marginTop: '1.5rem', marginLeft: '1rem'}}>New CPA Creators</h3>
                </Col>
                <Col className="mt-3">
                  {creatorsInTimeRange.length > 0 ? (
                    <div className="float-right mr-2">
                      <DownloadCSVButton
                        records={creatorsInTimeRange}
                        className="mt-2"
                        fileName="NewCpaCreators.csv"
                        csvArrayColumns={csvArrayColumns}
                      >
                        Download CSV
                      </DownloadCSVButton>
                    </div>
                  ) : null}
                </Col>
              </Row>
              <hr style={{ marginTop: 0 }}/>
              <ReactTableBase
                key="searchable"
                columns={columns}
                data={creatorsInTimeRange}
                tableConfig={tableConfig}
              />
            </TabPane>
            <TabPane tabId="cvrCreators">
              <Row>
                <Col className="mt-9">
                  <h3 style={{marginTop: '1.5rem', marginLeft: '1rem'}}>CVR Leading Creators</h3>
                </Col>
                <Col className="mt-3">
                  {creators.length > 0 ? (
                    <div className="float-right mr-2">
                      <DownloadCSVButton
                        records={creators}
                        className="mt-2"
                        fileName="CvrLeadingCreators.csv"
                        csvArrayColumns={csvArrayColumns}
                      >
                        Download CSV
                      </DownloadCSVButton>
                    </div>
                  ) : null}
                </Col>
              </Row>
              <hr style={{ marginTop: 0 }}/>
              <ReactTableBase
                key="searchable"
                columns={columns}
                data={creators}
                tableConfig={tableConfig}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default Table;
