import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  CardBody,
  CardTitle,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import ProgressBar from '../../../../../shared/helpers/ProgressBarWithObject';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import withNotice from '../../../../App/store/with-notice';
import Filter from './MonthlyTimePeriodFilterForwarded';
import POWL5CsTable from './POWL5CsTable';
import { showModalById } from '../../../../../redux/reducers/TableReducer';
import POWL5cSettingsModal, { modalId } from './POWL5CsSettingsModal';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';

const initialSelectedRes = {
  all: false,
  notPilot: false,
  pilot: false,
};
const Report = ({ addNotice }) => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState((moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [brandOptions, setBrandOptions] = useState([]);
  const [loadingBrandOptions, setLoadingBrandOptions] = useState(false);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedRes, setSelectedRes] = useState(initialSelectedRes);
  const [selectedAdvertisersShowTotalConversions, setSelectedAdvertisersShowTotalConversions] = useState([]);
  const [selectedResShow0, setSelectedResShow0] = useState(initialSelectedRes);
  const [settings, setSettings] = useState([]);
  const [reportData, setReportData] = useState({});
  const dispatch = useDispatch();

  const showModal = () => {
    dispatch(showModalById(modalId));
  }

  const getDefaultBrands = () => {
    const defaultBrands = [];
    // Show 5 brands by default in this order: BetterHelp, Aura, Mint Mobile, Keen, SNHU
    const BetterHelp = brandOptions.find(elem => elem.label === 'BetterHelp');
    if (BetterHelp) defaultBrands.push(BetterHelp);
    const Aura = brandOptions.find(elem => elem.label === 'Aura');
    if (Aura) defaultBrands.push(Aura);
    const MintMobile = brandOptions.find(elem => elem.label === 'Mint Mobile');
    if (MintMobile) defaultBrands.push(MintMobile);
    const Keen = brandOptions.find(elem => elem.label === 'Keen');
    if (Keen) defaultBrands.push(Keen);
    const SNHU = brandOptions.find(elem => elem.label === 'SNHU');
    if (SNHU) defaultBrands.push(SNHU);
    return defaultBrands;
  }

  const getDefaultBrandsShow0 = () => {
    const defaultBrands = [];
    const Aura = brandOptions.find(elem => elem.label === 'Aura');
    if (Aura) defaultBrands.push(Aura);
    return defaultBrands;
  }

  useEffect(() => {
    setSelectedAdvertisers(getDefaultBrands());
    setSelectedAdvertisersShowTotalConversions(getDefaultBrandsShow0());
  }, [brandOptions]);

  const getOptionItemsFromSelectedBrandTypes = (types) => {
    let optionsItems = [];
    const selectNotPilot = types.all === true || types.notPilot === true;
    const selectPilot = types.all === true || types.pilot === true;
    if (selectNotPilot === true) {
      optionsItems = brandOptions.filter(a => a.isPilot === 'No');
    }
    if (selectPilot === true) {
      optionsItems = optionsItems.concat(brandOptions.filter(a => a.isPilot === 'Yes'));
    }
    return optionsItems;
  }

  useEffect(() => {
    setSelectedAdvertisers(getOptionItemsFromSelectedBrandTypes(selectedRes));
  }, [selectedRes]);

  useEffect(() => {
    setSelectedAdvertisersShowTotalConversions(getOptionItemsFromSelectedBrandTypes(selectedResShow0));
  }, [selectedResShow0]);

  const fetchSettingsFromDictionary = async () => {
    try {
      const resp = await axios.get('/api/portal/reports/get-powl5cs-settings');
      setSettings(resp.data.settings || []);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchBrandOptions = async () => {
    const api = new ApiClient();
    setLoadingBrandOptions(true);
    api.client.get('/api/portal/compliance/get-tune-id-brand-options').then((resp) => {
      setBrandOptions(resp.data.brandOptions);
      setLoadingBrandOptions(false);
    }).catch(() => {
      setLoadingBrandOptions(false);
      addNotice({
        message: 'There was an error trying to load brand options. Check out console to see more information.',
        type: 'error',
      });
    });
  };

  useEffect(() => {
    fetchBrandOptions();
    fetchSettingsFromDictionary();
  }, []);

  const getListOfMonths = () => {
    const start = moment(startDate).startOf('month');
    const end = moment(endDate).endOf('month');
    const months = [];

    while (start.isSameOrBefore(end)) {
      months.push(start.format('MMMM YYYY'));
      start.add(1, 'month');
    }

    return months;
  }

  const checkSettings = () => {
    if (selectedAdvertisers?.length > 0) {
      const months = getListOfMonths();
      const missing = [];

      months.forEach((month) => {
        let message = '';
        const monthSettings = settings.find(s => s.period === month);
        if (monthSettings?.values && Array.isArray(monthSettings.values) && monthSettings.values.length > 0) {
          selectedAdvertisers.forEach((advertiser) => {
            const advertiserSettings = monthSettings.values.find(s => s.tuneId === advertiser.value);
            if (!advertiserSettings) {
              if (message.length === 0) {
                message = `${month}: ${advertiser.label}`;
              } else {
                message = `${message}, ${advertiser.label}`;
              }
            }
          });
        } else {
          message = month;
        }
        if (message.length > 0) missing.push(message);
      });

      if (missing.length > 0) {
        addNotice({
          message: `Some settings are missing - update them before running the report. ${missing.join('. ')}.`,
          type: 'warning',
          duration: 300,
        });

        return false;
      }
      return true;
    } else {
      addNotice({
        message: `Select Brands to run Report.`,
        type: 'warning',
      });

      return false;
    }
  }

  const resetFilters = () => {
    timeFilterRef?.current?.selectDateRange('current_month');
    setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
    setEndDate((moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).format('YYYY-MM-DD'));
    setReportData({});
    setSelectedAdvertisers(getDefaultBrands());
    setSelectedRes(initialSelectedRes);
    setSelectedAdvertisersShowTotalConversions(getDefaultBrandsShow0());
    setSelectedResShow0(initialSelectedRes);
  }

  const handleChangeSelectedBrands = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setSelectedRes({
        ...selectedRes,
        [name]: checked,
      });
    }
  };

  const handleChangeSelectedBrandsShow0 = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setSelectedResShow0({
        ...selectedResShow0,
        [name]: checked,
      });
    }
  };

  const fetchReport = async () => {
    if (checkSettings()) {
      setLoading(true);
      try {
        const resp = await axios.post('/api/portal/reports/post-report-powl5cs', {
          startDate,
          endDate,
          tuneIds: selectedAdvertisers?.map(row => row.value),
          showTotalConversionsTuneIds: selectedAdvertisersShowTotalConversions?.map(row => row.value),
          identity,
        });
        setLoading(false);
        if (resp.data.success) {
          const { tableData, monthlyCompletion } = resp.data;
          setReportData({ tableData, monthlyCompletion });
        } else {
          throw new Error(resp.data.error);
        }
      } catch (e) {
        console.error(e);
        addNotice({
          message: e.message,
          type: 'error',
        });
      }
    }
  };

  const timeFilterRef = useRef(null);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Management', path: false },
              { title: 'POWL 5Cs Report', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>POWL 5Cs Report</h3>
                <Button
                  color="primary"
                  onClick={showModal}
                  className="my-0 btn-sm float-right"
                  style={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    marginRight: '0',
                    marginBottom: '0',
                  }}
                  disabled={!!loading}
                >
                  Projected Target Settings
                </Button>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col>
                  <Filter
                    ref={timeFilterRef}
                    startDateInitial={startDate}
                    endDateInitial={endDate}
                    setValue={(param, value) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                    usedForConversions
                    isLoading={!!loading}
                    options={[
                      { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
                      { value: 'last_month', label: 'Last Month' },
                      { value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true },
                    ]}
                    selectDateRangeFunction={(range) => {
                      let dateFrom;
                      let dateTo;
                      let showCustom = false;
                      switch (range) {
                        case 'current_month':
                          showCustom = false;
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = (moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).toDate();
                          break;
                        case 'last_month':
                          showCustom = false;
                          dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
                          dateTo = moment().subtract(1, 'month').endOf('month').toDate();
                          break;
                        case 'custom':
                          showCustom = true;
                          dateFrom = moment().startOf('Year').toDate();
                          dateTo = moment().subtract(1, 'month').endOf('month').toDate();
                          break;
                        default:
                          showCustom = false;
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = (moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).toDate();
                          break;
                      }
                      console.log({ dateFrom, dateTo, showCustom });
                      return { dateFrom, dateTo, showCustom };
                    }}
                  />
                </Col>
                <Col lg="auto">
                  <div>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchReport();
                        return false;
                      }}
                      className="my-0 btn-sm"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3" >
                <Col md={5}>
                  <Label className="bold-text">
                    Select Brands to get POWL numbers for
                  </Label>
                  <Select
                    isDisabled={loadingBrandOptions}
                    value={selectedAdvertisers}
                    options={brandOptions}
                    onChange={setSelectedAdvertisers}
                    placeholder="Select Brands..."
                    isClearable
                    isMulti
                  />
                  <Row className="mt-3">
                    <Col md={12}>
                      <div
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="all"
                          label="Select All Brands"
                          value={selectedRes.all}
                          onChange={event => handleChangeSelectedBrands(event, 'all')}
                          className="inline"
                        />
                        <CheckBoxField
                          name="notPilot"
                          label="Select No Pilot Brands"
                          value={selectedRes.notPilot}
                          onChange={event => handleChangeSelectedBrands(event, 'notPilot')}
                          className="inline ml-4"
                        />
                        <CheckBoxField
                          name="pilot"
                          label="Select Pilot Brands"
                          value={selectedRes.pilot}
                          onChange={event => handleChangeSelectedBrands(event, 'pilot')}
                          className="inline ml-4"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={5}>
                  <Label className="bold-text">
                    Show total number of conversions for selected Brands
                  </Label>
                  <Select
                    isDisabled={loadingBrandOptions}
                    value={selectedAdvertisersShowTotalConversions}
                    options={brandOptions}
                    onChange={setSelectedAdvertisersShowTotalConversions}
                    placeholder="Select Brands..."
                    isClearable
                    isMulti
                  />
                  <Row className="mt-3">
                    <Col md={12}>
                      <div
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="allShow0"
                          label="Select All Brands"
                          value={selectedResShow0.all}
                          onChange={event => handleChangeSelectedBrandsShow0(event, 'all')}
                          className="inline"
                        />
                        <CheckBoxField
                          name="notPilotShow0"
                          label="Select No Pilot Brands"
                          value={selectedResShow0.notPilot}
                          onChange={event => handleChangeSelectedBrandsShow0(event, 'notPilot')}
                          className="inline ml-4"
                        />
                        <CheckBoxField
                          name="pilotShow0"
                          label="Select Pilot Brands"
                          value={selectedResShow0.pilot}
                          onChange={event => handleChangeSelectedBrandsShow0(event, 'pilot')}
                          className="inline ml-4"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={2} style={{ paddingTop: '31px' }}>
                  <Tooltip title="Reset Filters">
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={resetFilters}
                      style={{
                        width: '100%',
                        padding: '4px 8px',
                        height: '38px',
                      }}
                    >
                      <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                      <span className="btn-text">Reset</span>
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    topic="report"
                    identity={identity}
                    isShow={!!loading}
                  />
                </Col>
              </Row>
              {!loading && reportData?.tableData?.length > 0 && (
                <POWL5CsTable
                  data={reportData.tableData}
                  monthlyCompletion={reportData.monthlyCompletion}
                  endDate={endDate}
                />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  <POWL5cSettingsModal
    updateSettings={fetchSettingsFromDictionary}
  />
</Container>
)
  ;
};

Report.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(Report);
