import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
} from 'reactstrap';
import withNotice from '../../../../../../../App/store/with-notice';

const EditProspectModal = ({
  isOpen,
  closeFunc,
  setVersion,
  prospectIds,
}) => {
  const [loading, setLoading] = useState(false);

  // SUBMIT Data
  const submit = async () => {
    setLoading(true);

    axios({
      method: 'post',
      url: '/api/portal/prospects/post-delete-prospects',
      data: {
        prospectIds,
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        closeFunc();
      }
    });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Delete Prospect{prospectIds?.length > 1 ? 's' : ''}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <span>{prospectIds?.length > 1 ? 'Are you sure you would like to delete all selected prospects?' : 'Are you sure you would like to delete the selected prospect?'}</span>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Delete</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};


EditProspectModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  prospectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
};

export default withNotice(EditProspectModal);
