const moment = require('moment');

exports.setUrl = ({
  history, removeBeforeSet,
  search, dateFrom, dateTo, selectedButton,
  // lPublishedDateFrom, lPublishedDateTo, singleLPublishedDate, lPublishedDateFilterType,
  selectedCreators, selectedBrands, filtersReviewStatus,
  lastReviewDateFrom, lastReviewDateTo, singleLastReviewDate, lastReviewDateFilterType,
}) => {
  let searchUrl = new URLSearchParams(window.location.search);
  const oldUrl = `${history.location.pathname}?${searchUrl.toString()}`;

  if (removeBeforeSet) {
    searchUrl = new URLSearchParams();
  }

  if (search) {
    searchUrl.set('filters.fullSearchName', search);
  } else {
    searchUrl.delete('filters.fullSearchName');
  }

  searchUrl.delete('lastReviewDateType');
  searchUrl.delete('filters.lastReviewDate.from');
  searchUrl.delete('filters.lastReviewDate.to');
  searchUrl.delete('range');
  if (dateFrom !== '') {
    searchUrl.append('filters.lastReviewDate.from', dateFrom);
  }
  if (dateTo !== '') {
    searchUrl.append('filters.lastReviewDate.to', dateTo);
  }

  if (lastReviewDateFrom && lastReviewDateFrom !== '') {
    searchUrl.delete('filters.lastReviewDate.from');
    // searchUrl.append('filters.lastReviewDate.from', lastReviewDateFrom);
    const from = lastReviewDateFrom ? moment.utc(moment(lastReviewDateFrom).format('YYYY-MM-DD')).toDate() : '';
    searchUrl.append('filters.lastReviewDate.from', from.toISOString());
  }
  if (lastReviewDateTo && lastReviewDateTo !== '') {
    searchUrl.delete('filters.lastReviewDate.to');
    // searchUrl.append('filters.lastReviewDate.to', lastReviewDateTo);
    const to = lastReviewDateTo ? moment.utc(moment(lastReviewDateTo).format('YYYY-MM-DD')).endOf('day').toDate() : '';
    searchUrl.append('filters.lastReviewDate.to', to.toISOString());
  }
  if (singleLastReviewDate && singleLastReviewDate !== '') {
    searchUrl.delete('filters.lastReviewDate.from');
    searchUrl.delete('filters.lastReviewDate.to');
    const single = singleLastReviewDate ? moment.utc(moment(singleLastReviewDate).format('YYYY-MM-DD')).toDate() : '';
    if (lastReviewDateFilterType.value === '>' || lastReviewDateFilterType.value === '>=') {
      searchUrl.append('filters.lastReviewDate.from', single.toISOString());
    }
    if (lastReviewDateFilterType.value === '<' || lastReviewDateFilterType.value === '<=') {
      searchUrl.append('filters.lastReviewDate.to', single.toISOString());
    }
    if (lastReviewDateFilterType.value === '=') {
      searchUrl.append('filters.lastReviewDate.from', single.toISOString());
      const nextDay = new Date(singleLastReviewDate);
      nextDay.setDate(singleLastReviewDate.getDate() + 1);
      searchUrl.append('filters.lastReviewDate.to', nextDay.toISOString());
    }
  }
  if (lastReviewDateFilterType.value && ((lastReviewDateFrom && lastReviewDateTo) || singleLastReviewDate)) {
    searchUrl.append('lastReviewDateType', lastReviewDateFilterType.value);
  }

  searchUrl.delete('filters.latestDealPublishedDate.from');
  searchUrl.delete('filters.latestDealPublishedDate.to');
  // searchUrl.delete('range');
  // if (dateFrom !== '') {
  //   searchUrl.append('filters.latestDealPublishedDate.from', dateFrom);
  // }
  // if (dateTo !== '') {
  //   searchUrl.append('filters.latestDealPublishedDate.to', dateTo);
  // }
  searchUrl.append('range', selectedButton);

  // searchUrl.delete('filters.publishedAt.from');
  // searchUrl.delete('filters.publishedAt.to');
  // searchUrl.delete('publishedDateType');
  //
  // if (lPublishedDateFrom && lPublishedDateFrom !== '') {
  //   const from = lPublishedDateFrom ? moment.utc(moment(lPublishedDateFrom).format('YYYY-MM-DD')).toDate() : '';
  //   searchUrl.delete('filters.latestDealPublishedDate.from');
  //   searchUrl.append('filters.latestDealPublishedDate.from', from.toISOString());
  // }
  // if (lPublishedDateTo && lPublishedDateTo !== '') {
  //   const to = lPublishedDateTo ? moment.utc(moment(lPublishedDateTo).format('YYYY-MM-DD')).endOf('day').toDate() : '';
  //   searchUrl.delete('filters.latestDealPublishedDate.to');
  //   searchUrl.append('filters.latestDealPublishedDate.to', to.toISOString());
  // }
  // if (singleLPublishedDate && singleLPublishedDate !== '') {
  //   searchUrl.delete('filters.latestDealPublishedDate.from');
  //   searchUrl.delete('filters.latestDealPublishedDate.to');
  //   const single = singleLPublishedDate ? moment.utc(moment(singleLPublishedDate).format('YYYY-MM-DD')).toDate() : '';
  //   if (lPublishedDateFilterType.value === '>' || lPublishedDateFilterType.value === '>=') {
  //     searchUrl.append('filters.latestDealPublishedDate.from', single.toISOString());
  //   }
  //   if (lPublishedDateFilterType.value === '<' || lPublishedDateFilterType.value === '<=') {
  //     searchUrl.append('filters.latestDealPublishedDate.to', single.toISOString());
  //   }
  //   if (lPublishedDateFilterType.value === '=') {
  //     searchUrl.append('filters.latestDealPublishedDate.from', single.toISOString());
  //     const nextDay = new Date(singleLPublishedDate);
  //     nextDay.setDate(singleLPublishedDate.getDate() + 1);
  //     searchUrl.append('filters.latestDealPublishedDate.to', nextDay.toISOString());
  //   }
  // }
  // if (lPublishedDateFilterType.value && ((lPublishedDateFrom && lPublishedDateTo) || singleLPublishedDate)) {
  //   // setEnablePublishDateFilter(true);
  //   searchUrl.append('publishedDateType', lPublishedDateFilterType.value);
  // }

  const filterCreators = searchUrl.getAll('filters.creator');
  let deleteFilterCreators = false;

  if (!selectedCreators || selectedCreators.length === 0) {
    searchUrl.delete('filters.creator');
  }
  if (selectedCreators) {
    filterCreators.forEach((creator) => {
      if (selectedCreators.filter(item => item.value === creator).length === 0) {
        deleteFilterCreators = true;
      }
    });
    if (deleteFilterCreators) {
      searchUrl.delete('filters.creator');
    }
    selectedCreators.forEach((creator) => {
      if (creator.value !== '' && (!filterCreators.includes(creator.value) || deleteFilterCreators)) {
        searchUrl.append('filters.creator', creator.value);
      }
    });
  }

  const filterBrands = searchUrl.getAll('filters.brand');
  let deleteFilterBrands = false;

  if (!selectedBrands || selectedBrands.length === 0) {
    searchUrl.delete('filters.brand');
  }
  if (selectedBrands) {
    filterBrands.forEach((brand) => {
      if (selectedBrands.filter(item => item.label === brand).length === 0) {
        deleteFilterBrands = true;
      }
    });
    if (deleteFilterBrands) {
      searchUrl.delete('filters.brand');
    }
    selectedBrands.forEach((brand) => {
      if (brand.value !== '' && (!filterBrands.includes(brand.label) || deleteFilterBrands)) {
        searchUrl.append('filters.brand', brand.label);
      }
    });
  }

  // const filtersTypes = searchUrl.getAll('filters.type');
  searchUrl.delete('filters.reviewStatus');
  if (filtersReviewStatus['Needs Review'] || filtersReviewStatus['Pending Edits'] || filtersReviewStatus.Approved || filtersReviewStatus.Terminated) {
    Object.keys(filtersReviewStatus).forEach((status) => {
      if (filtersReviewStatus[status]) {
        searchUrl.append('filters.reviewStatus', status);
      }
    });
  }
  // console.log('SET URL');

  let newUrl = `${history.location.pathname}?${searchUrl.toString()}`;
  if (oldUrl !== newUrl) {
    searchUrl.set('page', '1');
    newUrl = `${history.location.pathname}?${searchUrl.toString()}`;
    history.push(newUrl);
  }
};
