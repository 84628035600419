import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { unflatten } from 'flat';
import moment from 'moment-timezone';
import UserPropertyType from './index';
import MatTableList from '../../../../../../../shared/tables/materialTable/MatTableList';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import HsUserInList from './HsUserInList';
import HsDealInList from './HsDealInList';
import CopyToClipboard from '../../../../../../../shared/components/CopyToClipboard';


const LogsTable = React.memo(({
  action,
  resource,
  version,
  pseudoUrlLogs,
  setPseudoUrlLogs,
  tableTag,
  setTableTag,
}) => {
  const search = new URLSearchParams(pseudoUrlLogs);
  const importId = search.get('filters.importLog');

  return (
    <MatTableList
      action={action}
      resource={resource}
      sortBy="actionDate"
      direction="desc"
      setTag={setTableTag}
      date={new Date().toString()}
      UserPropertyType={UserPropertyType}
      tag={tableTag}
      v={2}
      version={version}
      showFilters={false}
      showTitle={false}
      noActions
      isResizable={false}
      enabledStyles={false}
      // enableWarning={false}
      pseudoUrlFiltering
      pseudoUrl={pseudoUrlLogs}
      updatePseudoUrl={setPseudoUrlLogs}
      customColumns={[
        {
          id: 'actionDate',
          accessor: () => '',
          Header: () => 'Import Date (ET)',
          Cell: p => useMemo(() => (
            WVFormatter.formatToEST(moment(p.row.original.params?.actionDate || '')).format('M/D/YY h:mma')
          ), [p.row.original]),
          width: 100,
        },
        {
          id: 'fullSearchName',
          Header: () => 'Creator',
          accessor: () => '',
          Cell: p => useMemo(
            () => (
              <div>
                <div>
                  {p.row.original.params.title}
                </div>
                <div>
                  {`${p.row.original.params.firstname || ''} ${p.row.original.params.lastname || ''}`}
                </div>
                <div>
                  <CopyToClipboard value={p.row.original.params.email}>
                    {p.row.original.params.email}
                  </CopyToClipboard>
                </div>
              </div>
            ),
            [p.row.original],
          ),
          width: 200,
        },
        {
          id: 'hsUserId',
          Header: () => 'HubSpot Contact',
          Cell: p => useMemo(() => (
            <div
              // className="text-center"
              style={{ width: '100%' }}
            >
              <HsUserInList record={p.row.original} />
            </div>
          ), [p.row.original.id]),
          width: 200,
        },
        {
          id: 'uploadHistory.hsDealId',
          Header: () => 'HubSpot Deal',
          Cell: p => useMemo(() => {
            const unFlatP = unflatten(p.row.original.params);
            let importIndex = -1;
            if (unFlatP.uploadHistory?.length > 0) {
              if (importId) {
                importIndex = unFlatP.uploadHistory.findIndex(item => item.log.toString() === importId);
              } else {
                importIndex = unFlatP.uploadHistory.length - 1;
              }
            }
            return (
              <div
                style={{ width: '100%' }}
              >
                {importIndex !== -1 && unFlatP.uploadHistory[importIndex].hsDealId ?
                  <HsDealInList hsDealId={unFlatP.uploadHistory[importIndex].hsDealId.toString()} />
                  : ''}
              </div>
            );
          }, [p.row.original]),
          width: 200,
        },
        {
          id: 'status',
          Header: () => (<div className="text-center">Status</div>),
          Cell: p => useMemo(() => {
            const unFlatP = unflatten(p.row.original.params);
            let importIndex = -1;
            if (unFlatP.uploadHistory?.length > 0) {
              if (importId) {
                importIndex = unFlatP.uploadHistory.findIndex(item => item.log.toString() === importId);
              } else {
                importIndex = unFlatP.uploadHistory.length - 1;
              }
            }

            return (
              <div
                style={{ width: '100%' }}
              >
                {importIndex !== -1 && unFlatP.uploadHistory[importIndex].status === 'Success' &&
                  <span className="text-success">Success</span>
                }
                {importIndex !== -1 && unFlatP.uploadHistory[importIndex].status === 'Fail' &&
                  <div>
                    <p className="text-danger">Fail</p>
                    <span>Error: {unFlatP.uploadHistory[importIndex].errorMessage}</span>
                  </div>
                }
              </div>
            );
          }, [p.row.original]),
          width: 70,
        },
      ]}
    />
  );
}, (a, a1) => _.isEqual(a.version, a1.version) && _.isEqual(a.pseudoUrlLogs, a1.pseudoUrlLogs));

LogsTable.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  pseudoUrlLogs: PropTypes.string.isRequired,
  setPseudoUrlLogs: PropTypes.func.isRequired,
  tableTag: PropTypes.string.isRequired,
  setTableTag: PropTypes.func.isRequired,
  // setVersion: PropTypes.func.isRequired,
};
export default LogsTable;
