import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';


const StatItem = ({ items }) => (
  <div
    style={{
      padding: '15px 15px',
      border: '1px solid #cccccc',
      borderRadius: '5px',
      height: '100%',
    }}
  >
    <Row>
      {items.map(({ label, value }) => (
        <Col key={label}>
          <div style={{ textAlign: 'center', color: 'black' }} >
            <div
              style={{
                fontSize: '20px',
                fontWeight: '700',
                marginBottom: '12px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {value}
            </div>
            <div style={{ width: '100%', fontSize: '16px' }}>{label}</div>
          </div>
        </Col>
      ))}
    </Row>
  </div>);
StatItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.objectOf(PropTypes.any),
    ]).isRequired,
  })).isRequired,
};
export default StatItem;
