import React, { useEffect, useState } from 'react';
import { Col, Card, CardBody, Row, Container, Label, FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { axios } from 'ApiClient';
// import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import CheckBoxField from '../../../../shared/components/CheckBox';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';

// import { RTLProps } from '../../../shared/prop-types/ReducerProps';

const HasOffersConversions = () => {
  const [dealTypeFilters, setDealTypeFilters] = useState({
    greater0: true,
    equal0: false,
    overwite: false,
  });
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [minDate, setMinDate] = useState(moment().subtract(90, 'days').toDate());
  const [maxDate, setMaxDate] = useState(moment().toDate());
  const handleChangeDealTypeFilters = (event, name) => {
    console.log('event change');
    if (event && event.target) {
      const { checked } = event.target;
      setDealTypeFilters({
        ...dealTypeFilters,
        [name]: checked,
      });
    }
  };
  const uniqueId = React.useMemo(() => moment().toISOString(), []);
  console.log('1');
  const getConversions = async () => {
    setLoading(true);
    console.log('get converions');
    const resp = await axios.post('/api/portal/conversions/post-fill-conversions', {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      greater0: dealTypeFilters.greater0,
      equal0: dealTypeFilters.equal0,
      overwite: dealTypeFilters.overwite,
      identity: uniqueId,
    });
    if (resp.data.success) {
      console.log(resp.data.stats);
      console.log('added', resp.data.stats.added);
      console.log('errors', resp.data.stats.errors.length);
      setStats(resp.data.stats);
      setModal(true);
    }
    setLoading(false);
  };
  console.log('Loading', uniqueId);
  useEffect(() => {
    // if(moment(endDate).diff(moment(startDate)))
    if (moment(endDate).subtract(90, 'day') > moment().subtract(25, 'month')) {
      setMinDate(moment(endDate).subtract(90, 'day').toDate());
    } else {
      setMinDate(moment().subtract(25, 'month').toDate());
    }
    if (moment(startDate).add(90, 'day') > moment()) {
      setMaxDate(moment().toDate());
    } else {
      setMaxDate(moment(startDate).add(90, 'day').toDate());
    }
    console.log(minDate, maxDate);
  }, [startDate, endDate]);
  return (
    <Container className="dashboard">
      <div id="adminDashBoard" style={{ width: '100%' }}>
        <Card>
          <CardBody className="panel__body">
            <div className="panel__content">
              <Row>
                <Col>
                  <h4 className=" font-weight-bold">HasOffers Conversions</h4>
                  <hr className="mb-1" />
                </Col>
              </Row>
              <br />
              <Row>
                <Col xl="6" >
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Revenue Amount
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="greater0"
                      label="Include Conversions with Revenue greater than $0"
                      value={dealTypeFilters.greater0}
                      onChange={event => handleChangeDealTypeFilters(event, 'greater0')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="equal0"
                      label="Include Conversions with Revenue equal to $0"
                      value={dealTypeFilters.equal0}
                      onChange={event => handleChangeDealTypeFilters(event, 'equal0')}
                    />
                  </FormGroup>
                </Col>
                <Col xl="6" lg="7" className="summary-div">
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Existing Conversions
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="overwite"
                      label="Overwrite Existing Conversions"
                      value={dealTypeFilters.overwite}
                      onChange={event => handleChangeDealTypeFilters(event, 'overwite')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Start Date</Label>
                  <DatePicker
                    selected={startDate}
                    onChange={setStartDate}
                    dateFormat="MM/dd/yyyy"
                    maxDate={maxDate}
                    minDate={minDate}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    className="form-control  ml-2"
                  />
                </Col>
                <Col>
                  <Label >End Date</Label>
                  <DatePicker
                    selected={endDate}
                    onChange={setEndDate}
                    dateFormat="MM/dd/yyyy"
                    maxDate={maxDate}
                    minDate={minDate}
                    placeholderText="End Date"
                    dropDownMode="select"
                    className="form-control ml-2"
                  />
                </Col>
              </Row>
              {loading && (<ProgressBar
                afterFunction={() => true}
                topic="reFetchAllConversions"
                identity={uniqueId}
                isShow={!!loading}
              />)}
              <Row className="mt-3">
                <Col>
                  <Button onClick={() => getConversions()} color="primary" disabled={!!loading} >Get Conversions</Button>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </div>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="sm">
        <ModalHeader toggle={() => setModal(false)}>
          Success
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="3 text-center">
              <i className="fa fa-info-circle" style={{ color: '#70bbfd', fontSize: '5em' }} />
            </Col>
            <Col>
              <h4>
                Total Found: {stats.total} items
              </h4>
              <hr />
              <h4>
                Revenue greater than $0: {stats.totalGt0} items
              </h4>
              <h4>
                Revenue equal to $0: {stats.total0} items
              </h4>
              <hr />
              <h4>
                Overwrited Existing Conversions: {stats.replacedLogs} items
              </h4>
              <h4>
                Ignored Existing Conversions: {stats.ignoredLogs} items
              </h4>
              <h4>
                Errors: {stats.errors?.length} items
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="modal__footer">
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setModal(false)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};


export default HasOffersConversions;
