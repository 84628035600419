import PropTypes from 'prop-types';
import React from 'react';
import TableCellContextMenu from '../../../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';

const ContextMenuInList = ({ record, setCurrentContact, setShowStatusHistoryModal }) => {
  const profileLink = record.params.influencerObject?._id ? `/influencer/profile/${record.params.influencerObject?._id}` : '';
  const hubspotLink = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.params.hs_object_id}`;
  // const videoLink = `https://www.youtube.com/watch?v=${record.params.videoId}`;

  const handleClickViewCreatorProfile = () => {
    if (profileLink) {
      window.open(profileLink, '_blank').focus();
    }
  };

  const handleClickViewHubspotContact = () => {
    if (hubspotLink) {
      window.open(hubspotLink, '_blank').focus();
    }
  };
  //
  // const handleClickViewYouTubeVideo = () => {
  //   if (videoLink) {
  //     window.open(videoLink, '_blank').focus();
  //   }
  // };

  const handleClickViewSentimentHistory = () => {
    setCurrentContact(record);
    setShowStatusHistoryModal(true);
  };

  const options = [
    { label: 'View Compliance Status History', handler: handleClickViewSentimentHistory },
    { type: 'divider', label: 'divider0' },
    { label: 'View Creator Profile', handler: handleClickViewCreatorProfile, disabled: !profileLink },
    { label: 'View HubSpot Contact', handler: handleClickViewHubspotContact },
    // { type: 'divider', label: 'divider1' },
    // { label: 'View Channel', handler: () => { console.log('View Channel'); } },
  ];

  return (
    <div className="text-right">
      <TableCellContextMenu options={options} />
    </div>
  );
};
ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setShowStatusHistoryModal: PropTypes.func.isRequired,
  setCurrentContact: PropTypes.func.isRequired,
};

export default ContextMenuInList;
