import React from 'react';
import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, CardTitle, FormGroup, Label, Spinner, Input } from 'reactstrap';
import ApiClient from 'ApiClient';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import Select from 'react-select';
import PropTypes from 'prop-types';
// import { unflatten } from 'flat';
// import DatePicker from 'react-datepicker';
import CurrencyField from '../../../../shared/components/form/CurrencyField';
import StyledButton from '../../../../shared/components/components/ui/styled-button';
import withNotice from '../../../App/store/with-notice';
// import { getConstant } from '../../../../shared/helpers/WVConstants';
// import ModalSimple from '../../../../shared/components/ModalSimple';
import AgentDeal from '../../../../shared/recordCollection/AgentDeal';
import PercentsField from '../../../../shared/components/form/PercentsField';
// import formater from '../../../../shared/helpers/WVFormatter';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import { getConstant } from '../../../../shared/helpers/WVConstants';


class DealAgentForm extends React.Component {
  static async loadHubIlAgentOptions(inputValue) {
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'HubilAgent',
      query: inputValue,
    });
    const res = [];
    records.forEach((record) => {
      if (record.params.email !== null) {
        res.push({
          value: record.id,
          label: record.params.email,
          params: record.params,
          record,
        });
      }
    });
    return [{
      value: null,
      label: '--- Create New Agent Contact ---',
      params: {
        _id: null, firstname: '', lastname: '', email: '',
      },
    }].concat(res);
  }
  static async loadHSOwnerOptions(inputValue) {
    const api = new ApiClient();
    const response = await api.client.get(`/api/portal/adminUsers/get-hubspot-users-local?term=${inputValue}`);
    return response.data.hsOwners.map(item => ({
      value: item._id,
      label: `${item.firstName} ${item.lastName}`,
      item,
    }));
  }

  constructor(props) {
    super(props);
    this.model = new AgentDeal({}, this);

    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleChangeInfluencer = this.handleChangeInfluencer.bind(this);
    this.fetchData = this.fetchData.bind(this);
    // this.handleChangeAmount = this.handleChangeAmount.bind(this);
    // this.handleChangeDeal = this.handleChangeDeal.bind(this);
    this.state = {
      // showModalQuestion: false,
      // confirmUpdate: false,
      // isUpdateAmount: false,
      // dealList: [],
      // selectedInfluencer: null,
      loading: {
        form: false,
      },
      // pipelines: [],
      // selectedPipelineOption: null,
      // selectedHSOwnerOption: null,
      // stageValue: null,
      // selectedAgent: null,
      deal: this.model.getModel(),
    };
  }

  componentDidMount() {
    this.fetchData();
  }
  shouldComponentUpdate(newProps) {
    const { match } = newProps;
    if (
      (match && match.params.agentId && !this.props.match.params.agentId)
      || (match && match.params.agentId && this.props.match.params.agentId !== match.params.agentId)
      || (match && !match.params.agentId && this.props.match.params.agentId)
    ) {
      this.fetchData();
    }
    return true;
  }
  async loadPipelineOptions() {
    const pipelines = getConstant('deal', 'pipelinesOrdered');
    const pipelinesArr = pipelines.map(record => ({
      value: record.id,
      label: record.label,
      stages: record.stages.map(s => ({ id: s.id, label: s.label, title: s.label })),
      record: { id: record.id, title: record.label, label: record.label },
    }));
    this.setState({
      pipelines: pipelinesArr,
    });
    return pipelinesArr;
    /* console.log(this);
    const response = await axios.get(`/api/portal/hubil/search-pipeline?pipelineName=${inputValue}`);
    const { success, pipelines } = response.data;
    if (success) {
      const pipelinesArr = pipelines.map(record => ({
        value: record.id,
        label: record.title,
        stages: record.stages,
        record,
      }));
      this.setState({
        pipelines: pipelinesArr,
      });
      return pipelinesArr;
    }
    return []; */
  }
  async fetchData() {
    console.log('fetch', this);
    this.loadPipelineOptions('');
    if (this.props.match.params.agentId) {
      this.setState({
        loading: {
          form: true,
        },
      });
      await this.model.fetchRecord(this.props.match.params.agentId);
      this.setState({
        loading: {
          form: false,
        },
      });
    }
  }
  async fetchDataByContact(id) {
    if (!id || this.model.get('agentUser')) return false;
    console.log('fetch', this);
    this.setState({
      loading: {
        form: true,
      },
    });
    await this.model.fetchByContact(id);
    this.setState({
      loading: {
        form: false,
      },
    });
    return true;
  }
  handleChangeAmount(e, value) {
    const amount = Number(value);
    this.model.set('amount', amount, () => {
      this.showAmountChangeModal();
    });
    this.model.validateParam('amount');
    // this.showAmountChangeModal();
  }

  async handleSubmit(event) {
    event.preventDefault();
    await this.model.validate();
    if (this.model.haveErrors()) {
      return true;
    }
    this.setState({
      loading: {
        form: true,
      },
    });
    try {
      await this.model.save();
    } catch (e) {
      console.log(e);
      this.props.addNotice({
        message: e.message,
        type: 'error',
      });
      this.setState({
        loading: {
          form: false,
        },
      });
      return false;
    }
    this.setState({
      loading: {
        form: false,
      },
    });
    this.props.addNotice({
      message: 'New Agent Deal has been added/updated successfully',
      type: 'success',
    });
    setTimeout(() => {
      this.props.history.push(`/agent/profile/${this.model.get('agentUserObj')._id}`);
    }, 3000);
    // this.model.clear();

    return false;
  }
  render() {
    const {
      deal,
      // dealList,
      // selectedAgent,
      // pipelines,
      // selectedHSOwnerOption,
      // selectedPipelineOption,
      // stageValue,
      pipelines,
    } = this.state;
    const selectedPipelineOption = pipelines?.find(p => p.value?.toString() === this.model.get('pipeline')?.toString()) || null;
    const stagesOptions = selectedPipelineOption?.stages.map(stage => ({ value: stage.id, label: stage.label })) || [];
    console.log(deal);
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs
              isBackButton
              links={[
                { title: 'Home', path: '/' },
                { title: 'Agent Users', path: false },
                { title: 'Deal Editor', path: false },
              ]}
            />
          </Col>
          <Col>
            <Card>
              <CardTitle className="row justify-content-center">
                <Col xs="12">
                  <h3>Agent Deal Editor</h3>
                </Col>
              </CardTitle>
              <CardBody>
                {this.model.get('agentUserObj') ? (
                  <div>
                    <h3 className="">{this.model.get('agentUserObj').first_name || ''} {this.model.get('agentUserObj').last_name || ''}</h3>
                    <h5 className="mb-3">{this.model.get('agentUserObj').email || ''}</h5>
                  </div>
                ) : null}
                {this.state.loading.form &&
                  (
                    <div className="text-center"><Spinner color="primary" size="lg" /></div>
                  )
                }
                {!this.state.loading.form && (
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <Label>Agent Contact</Label>
                      <div className="grey-small-text grey-minus-margin">Specify the Agents{'\''}s HubSpot Contact record details</div>
                      <SelectAsync
                        cacheOptions
                        value={this.model.get('selectedAgent')}
                        defaultOptions
                        isDisabled={!!this.model.get('agentUser') && this.model.get('agentsContact')}
                        loadOptions={DealAgentForm.loadHubIlAgentOptions}
                        onChange={(e) => {
                          console.log(e);
                          // this.setState({ selectedAgent: e });
                          this.model.set('selectedAgent', e);
                          this.model.set('agent', e.value);
                          this.model.set('first_name', e.params.first_name);
                          this.model.set('last_name', e.params.last_name);
                          this.model.set('contact_email', e.params.email);
                          this.model.set('company', e.params.company);
                          this.fetchDataByContact(e.value);
                        }}
                        placeholder=""
                      />
                      {this.model.getErrorView('agent')}
                    </FormGroup>
                    {!this.model.get('agent') && (
                      <FormGroup>
                        <Label>Contact Email</Label>
                        <Input
                          type="text"
                          id="contact_email"
                          name="contact_email"
                          placeholder="Contact Email"
                          autoComplete="off"
                          onChange={e => this.model.set('contact_email', e.target.value)}
                          value={this.model.get('contact_email')}
                        />
                        <div className="grey-small-text">Make Sure you don{'\''}t have creator contact with email above</div>
                        {this.model.getErrorView('contact_email')}
                      </FormGroup>
                    )}
                    <Row>
                      <Col md="4" sm="6" xs="12">
                        <FormGroup>
                          <Label>First Name</Label>
                          <Input
                            type="text"
                            id="first_name"
                            name="first_name"
                            placeholder="First Name"
                            autoComplete="off"
                            onChange={e => this.model.set('first_name', e.target.value)}
                            value={this.model.get('first_name')}
                          />
                          {this.model.getErrorView('first_name')}
                        </FormGroup>
                      </Col>
                      <Col md="4" sm="6" xs="12">
                        <FormGroup>
                          <Label>Last Name</Label>
                          <Input
                            type="text"
                            id="last_name"
                            name="last_name"
                            placeholder="Last Name"
                            autoComplete="off"
                            onChange={e => this.model.set('last_name', e.target.value)}
                            value={this.model.get('last_name')}
                          />
                          {this.model.getErrorView('last_name')}
                        </FormGroup>
                      </Col>
                      <Col md="4" sm="6" xs="12">
                        <FormGroup>
                          <Label>Company</Label>
                          <Input
                            type="text"
                            id="company"
                            name="company"
                            placeholder="Company"
                            autoComplete="off"
                            onChange={e => this.model.set('company', e.target.value)}
                            value={this.model.get('company')}
                          />
                          {this.model.getErrorView('company')}
                        </FormGroup>
                      </Col>
                    </Row>
                    <br /><br />
                    <h4>Deal Details</h4>
                    <hr />
                    <div className="grey-small-text grey-minus-margin">Specify the Agent&apos;s HubSpot Deal record details</div>
                    <FormGroup>
                      <Label>Deal Name</Label>
                      <Input
                        type="text"
                        id="dealname"
                        name="dealname"
                        autoComplete="off"
                        placeholder="Deal Name"
                        onChange={e => this.model.set('dealname', e.target.value)}
                        value={this.model.get('dealname')}
                      />
                      {this.model.getErrorView('dealname')}
                    </FormGroup>
                    <Row>
                      <Col md="4" xs="12">
                        <FormGroup>
                          <Label>Owner</Label>
                          <SelectAsync
                            cacheOptions
                            value={this.model.get('selectedHSOwnerOption')}
                            defaultOptions
                            loadOptions={DealAgentForm.loadHSOwnerOptions}
                            onChange={(e) => {
                              this.model.set('hsOwner', e?.item?.hsUserId);
                              this.model.set('selectedHSOwnerOption', e);
                            }}
                            placeholder="Please Select HubSpot Owner"
                          />
                          {this.model.getErrorView('hsOwner')}
                        </FormGroup>
                      </Col>
                      <Col md="4" xs="12">
                        <FormGroup>
                          <Label>Pipeline</Label>
                          <Select
                            value={selectedPipelineOption}
                            options={pipelines}
                            onChange={(e) => {
                              this.model.set('pipeline', e.value);
                              this.model.set('selectedPipelineOption', e);
                            }}
                            placeholder="Please Select Pipeline"
                          />
                          {this.model.getErrorView('pipeline')}
                        </FormGroup>
                      </Col>
                      <Col md="4" xs="12">
                        <FormGroup>
                          <Label>Deal Stage</Label>
                          <Select
                            name="dealstage"
                            value={stagesOptions.find(s => s.value?.toString() === this.model.get('dealstage')?.toString())}
                            isDisabled={!selectedPipelineOption}
                            options={stagesOptions}
                            onChange={(e) => {
                              this.model.set('dealstage', e.value);
                              this.model.set('stageValue', e);
                              // this.setState({ stageValue: e });
                            }}
                          />
                          {this.model.getErrorView('dealstage')}
                        </FormGroup>
                      </Col>
                    </Row>
                    <br /><br />
                    <h4>Deal Terms</h4>
                    <hr />
                    <div className="grey-small-text grey-minus-margin">Specify the Agent&apos;s HubSpot Deal Terms</div>
                    <div>
                      <Row>
                        <Col md={6} style={{ paddingLeft: '50px' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Input
                                  type="radio"
                                  name="cpaType"
                                  value="percentage"
                                  className="mt-0"
                                  checked={this.model.get('type') === 'percentage'}
                                  onChange={e => (e?.target?.checked && this.model.set('type', 'percentage')) || (!this.model.get('commission_percentage') && this.model.set('commission_percentage', 100))}
                                />
                              }
                              label="Commission Percentage"
                            />
                            <PercentsField
                              name="commission_percentage"
                              disabled={this.model.get('type') !== 'percentage'}
                              defaultValue={Number(this.model.get('commission_percentage')) || 0}
                              onBlur={e => this.model.set('commission_percentage', e.target.value) || this.model.set('commission_amount', 0)}
                            />
                            {this.model.getErrorView('commission_percentage')}
                          </FormGroup>
                        </Col>
                        <Col md={6} style={{ paddingLeft: '50px' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Input
                                  type="radio"
                                  name="cpaType"
                                  value="flatRate"
                                  className="mt-0"
                                  checked={this.model.get('type') === 'amount'}
                                  onChange={e => (e?.target.checked && this.model.set('type', 'amount')) || (!this.model.get('commission_amount') && this.model.set('commission_amount', 0))}
                                />
                              }
                              label="Commission Amount"
                            />
                            <CurrencyField
                              name="commission_amount"
                              className="form-control"
                              disabled={this.model.get('type') !== 'amount'}
                              defaultValue={this.model.get('commission_amount') || 0}
                              onBlur={e => this.model.set('commission_amount', e.target.value) || this.model.set('commission_percentage', 0)}
                            />
                            {this.model.getErrorView('commission_amount')}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="text-right">
                      <StyledButton
                        type="submit"
                        className="is-primary"
                      >
                        <i className="icomoon-save" />
                        <span className="btn-text">Save</span>
                      </StyledButton>
                      &nbsp;&nbsp;
                      <Link to="/resources/Bill/actions/list">
                        <StyledButton
                          type="button"
                          className="btn-danger"
                        >
                          <i className="icomoon-save" />
                          <span className="btn-text">Cancel</span>
                        </StyledButton>
                      </Link>
                    </div>
                  </form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
DealAgentForm.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  // resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(DealAgentForm);
