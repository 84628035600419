import React from 'react';
import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getConstant } from '../../../../../shared/helpers/WVConstants';

export default class StatusBulkComponent extends React.Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.api = new ApiClient();
    this.mapStatusArray = getConstant('AP', 'status', {});
    this.state = {
      selected: null,
    };
  }

  handleSelectChange(selected) {
    this.setState({ selected });
    const { action } = this.props;
    action({ status: selected.value });
    return true;
  }

  render() {
    const {
      selected,
      status,
    } = this.state;
    const mapStatus = Object.keys(this.mapStatusArray).map(key => ({ value: key, label: this.mapStatusArray[key], isDisabled: (['complete', 'failed'].includes(key)) }));
    return status === null ? 'Unable To find Influencer' : (
      <div style={{ minWidth: '150px', maxWidth: '150px' }} className="float-left">
        <Select
          value={selected}
          options={mapStatus}
          onChange={this.handleSelectChange}
          placeholder="Select Status"
          isDisabled={this.props.disabled || false}
        />
      </div>
    );
  }
}
