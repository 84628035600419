import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useAsyncDebounce } from 'react-table';
import OfflinePayouts from './OfflinePayouts';
import WVValidator from '../../../../../shared/helpers/WVValidator';
import StatusBulkComponent from './StatusBulkComponent';
import TableAgent from './TableAgent';
import ActionsAgent from './ActionsAgent';
// import AuditAgentReportModal from './AuditAgentReportModal';
import {
  diselectAll,
} from '../../../../../redux/reducers/TableReducer';
import {
  getPayablesStats, editPayablesBulk,
} from '../../../../../redux/reducers/admin/AccountsPayableReducer';

const PayablesTable = ({
  loading,
  selectedReport,
  addNotice,
  setAmount,
  selectedItems,
  selectedRecordsObject,
  history,
  setIssue,
  refreshTable,
  refreshUnpaidTable,
  challengeMFAReauthentication,
  // sendStripePayouts,
  downloadCSV,
  dispatch,
  // goToJournal,

}) => {
  const [loadingMFA, setLoadingMFA] = useState(false);
  const [version, setVersion] = useState((new Date()).toString());
  const [showTable, setShowTable] = useState(false);
  const [modals, setModals] = useState({
    chartOfAccount: false,
    offlinePayouts: false,
    auditAgent: false,
  });

  const getStripeSelectedAmount = () => {
    let totalSelectedAmount = 0;
    selectedRecordsObject.forEach((record) => {
      if (
        selectedItems.indexOf(record.id) >= 0
        && record?.params?.status === 'approved'
        && (
          (record?.populated?.influencer?.params?.payment.enablePayments
            && !WVValidator.isEmpty(record?.populated?.influencer?.params?.payment?.stripe?.accountId)
            && !WVValidator.isEmpty(record?.populated?.influencer?.params?.payment?.stripe?.isDefault))
          || (
            !record?.populated?.influencer?.params?.payment.enablePayments
            && record?.populated?.agent?.params?.payment?.stripe?.accountId
            && record?.populated?.agent?.params?.payment?.stripe?.isDefault
          )
        )
      ) {
        totalSelectedAmount += Number(record.params.ilPayoutAmount);
      }
    });
    return totalSelectedAmount;
  };
  const getTotalSelectedAmount = () => {
    let totalSelectedAmount = 0;
    selectedRecordsObject.forEach((record) => {
      if (
        selectedItems.indexOf(record.id) >= 0
        && record?.params?.status === 'approved'
        && record.populated
        && (
          !WVValidator.isEmpty(record.populated?.influencer?.params?.payment.stripe.accountId) || !WVValidator.isEmpty(record.populated?.influencer?.params?.payment.billCom.accountId)
          || !WVValidator.isEmpty(record.populated?.agent?.params?.payment.stripe.accountId) || !WVValidator.isEmpty(record.populated?.agent?.params?.payment.billCom.accountId)
        )
      ) {
        // console.log(record.populated.agent);
        totalSelectedAmount += Number(record.params.ilPayoutAmount);
      }
    });
    // console.log(totalSelectedAmount);
    return totalSelectedAmount;
  };
  useEffect(() => setAmount({ stripeSelectedAmount: getStripeSelectedAmount(), totalSelectedAmount: getTotalSelectedAmount() }), [selectedItems]);
  const refresh = () => {
    setVersion((new Date()).toString());
    dispatch(diselectAll());
    dispatch(getPayablesStats(selectedReport.id));
  };
  const setTableShow = useAsyncDebounce(() => {
    setShowTable(true);
  }, 1000);
  useEffect(() => {
    setTableShow();
  }, [window.location.href])
  const resource = window.REDUX_STATE.resources.find(r => r.id === 'PayableAgent');
  const action = resource.resourceActions.find(r => r.name === 'list');
  return (
    <div>
      <Row className="mt-0">
        <Col>
          <h5 className="text-right">{ `Selected ${selectedItems.length} item${selectedItems.length > 1 ? 's' : ''}`}
            {selectedItems.length > 0 && (<Tooltip title="Deselect All Items"><IconButton onClick={() => dispatch(diselectAll())} size="small"><CloseCircleOutlineIcon /></IconButton></Tooltip>)}
          </h5>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col><ActionsAgent
          loadingMFA={loadingMFA}
          loading={loading}
          setLoadingMFA={setLoadingMFA}
          getStripeSelectedAmount={getStripeSelectedAmount}
          getTotalSelectedAmount={getTotalSelectedAmount}
          selectedReport={selectedReport}
          refreshTable={refreshTable}
          refreshUnpaidTable={refreshUnpaidTable}
          setIssue={setIssue}
          showOfflinePayout={() => setModals(m => ({ ...m, offlinePayouts: true }))}
          challengeMFAReauthentication={challengeMFAReauthentication}
          // sendStripePayouts={sendStripePayouts}
          downloadCSV={downloadCSV}
          history={history}
          refresh={refresh}
          showAuditModal={() => setModals(m => ({ ...m, auditAgent: true }))}
        />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="auto">
          <StatusBulkComponent
            action={(values) => {
              dispatch(editPayablesBulk(selectedReport.id, selectedItems, values, () => refresh()));
            }}
            disabled={selectedItems.length === 0}
          />
        </Col>
        <Col />
      </Row>
      {showTable ? ( <TableAgent
        action={action}
        resource={resource}
        filters={{ accountsPayable: selectedReport.id }}
        version={version}
      />) : (<div className="text-center text-primary"><Spinner /></div>)}
     
      <OfflinePayouts
        loading={false}
        addNotice={addNotice}
        type="agent"
        hideModal={(r) => {
          if (r) {
            refresh();
          }
          setModals({ ...modals, offlinePayouts: false });
          setVersion((new Date()).toString());
          // this.setState(state => ({ modals: { ...state.modals, offlinePayouts: false } }));
        }}
        title="Offline Payout"
        show={modals.offlinePayouts}
        selectedIds={selectedItems}
      />
      {/* <AuditAgentReportModal
        loading={false}
        closeDialog={this.closeAuditAgentReportModal}
        title="Audit Agent"
        selectedReport={selectedReport}
        modal={modals.auditAgent}
        dataArray={payables}
        version={version}
      /> */}
    </div>
  );
};

PayablesTable.propTypes = {
  loading: PropTypes.objectOf(PropTypes.any).isRequired,
  // sendStripePayouts: PropTypes.func.isRequired,
  selectedReport: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  setIssue: PropTypes.func.isRequired,

  // isBillComMFASessionTrusted: PropTypes.func.isRequired,
  challengeMFAReauthentication: PropTypes.func.isRequired,
  // availableBalance: PropTypes.number.isRequired,
  addNotice: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedRecordsObject: PropTypes.arrayOf(PropTypes.any).isRequired,
  // bulkActions: PropTypes.func.isRequired,
  refreshTable: PropTypes.func.isRequired,
  refreshUnpaidTable: PropTypes.func.isRequired,
  downloadCSV: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  selectedItems: state.table.selectedRecordsIdsMultiselect,
  selectedRecordsObject: state.table.selectedRecordsObject,
});

export default connect(mapStateToProps, null)(PayablesTable);
