import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { unflatten } from 'flat';
import AdminBroTable from '../tableAdminBro/AdminBroTableRedux';
import { updateRecordFunction as update } from '../../../redux/reducers/TableReducerDefault';
import Hook from '../../hooks';

const TableRedux = ({
  useCheckboxes, checkboxHandler, customColumns, displayProgressBar, isResizable, useFlex,
  tableConfig, enabledStyles, enableWarning, notFoundProps,
}) => {
  const {
    records, loading, page, perPage, resourceId, direction, sortBy, total, lastFetched,
  } = Hook.useTableHook();
  /* const records = useSelector(state => state.tableRedux.records);
  const loading = useSelector(state => state.tableRedux.loading);
  const page = useSelector(state => state.tableRedux.page);
  const perPage = useSelector(state => state.tableRedux.perPage);
  const resourceId = useSelector(state => state.tableRedux.resourceId);
  const direction = useSelector(state => state.tableRedux.direction);
  const sortBy = useSelector(state => state.tableRedux.sortBy);
  const total = useSelector(state => state.tableRedux.total);
  const lastFetched = useSelector(state => state.tableRedux.lastFetched); */
  // console.log('RELOAD RECORDS', lastFetched);
  const dispatch = useDispatch();
  const updateRecordFunction = (newRecord, data) => dispatch(update(unflatten(newRecord), data));
  console.log(records, 'RECORDS');
  return (
    <Card>
      <CardBody>
        <AdminBroTable
          version={lastFetched}
          direction={direction}
          edit
          history={{}}
          isLoading={loading}
          key="searchable2"
          page={page}
          perPage={perPage}
          records={records}
          updateRecordFunction={updateRecordFunction}
          UserPropertyType={{}}
          resource={{ id: resourceId }}
          selectable
          sortBy={sortBy}
          total={total}
          tableConfig={{
            isEditable: false,
            isResizable,
            useFlex,
            isSortable: true,
            withPagination: true,
            withSearchEngine: true,
            manualPageSize: [10, 20, 30, 40, 50, 75, 100],
            placeholder: 'Search by First name...',
            ...tableConfig,
          }}
          useCheckboxes={useCheckboxes}
          checkboxHandler={checkboxHandler}
          customColumns={customColumns}
          displayProgressBar={displayProgressBar}
          enabledStyles={enabledStyles}
          enableWarning={enableWarning}
          notFoundProps={notFoundProps}
        />
      </CardBody>
    </Card>
  );
};

TableRedux.propTypes = {
  useCheckboxes: PropTypes.bool,
  checkboxHandler: PropTypes.func,
  customColumns: PropTypes.arrayOf(PropTypes.any),
  displayProgressBar: PropTypes.bool,
  isResizable: PropTypes.bool,
  useFlex: PropTypes.bool,
  tableConfig: PropTypes.objectOf(PropTypes.any),
  enabledStyles: PropTypes.bool,
  enableWarning: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  notFoundProps: PropTypes.objectOf(PropTypes.any),
};

TableRedux.defaultProps = {
  useCheckboxes: false,
  checkboxHandler: null,
  customColumns: [],
  displayProgressBar: true,
  isResizable: false,
  useFlex: true,
  tableConfig: {},
  enabledStyles: true,
  enableWarning: true,
  notFoundProps: {},
};

export default TableRedux;
/*
class MatTableList extends React.Component {
  static propTypes = {
    resource: PropTypes.objectOf(PropTypes.any).isRequired,
    setRefresh: PropTypes.func,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    version: PropTypes.string,
    setTag: PropTypes.func,
    addNotice: PropTypes.func,
    radioButtonHandler: PropTypes.func,
    selectCheckboxHandler: PropTypes.func,
    showFilters: PropTypes.bool,
    showSearch: PropTypes.bool,
    hideToolbar: PropTypes.bool,
    hideFilterDropdown: PropTypes.bool,
    noActions: PropTypes.bool,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    csvDownload: PropTypes.objectOf(PropTypes.any),
    UserPropertyType: PropTypes.objectOf(PropTypes.any).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    filters: PropTypes.objectOf(PropTypes.any),
    direction: PropTypes.string,
    sortBy: PropTypes.string,
    additionalActions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    meta: PropTypes.objectOf(PropTypes.any),
    v: PropTypes.number,
    showTitle: PropTypes.bool,
    columnsSettings: PropTypes.objectOf(PropTypes.any),
    useCheckboxes: PropTypes.bool,
    checkboxHandler: PropTypes.func,
    setMetaTotal: PropTypes.func,
    customColumns: PropTypes.arrayOf(PropTypes.any),
    page: PropTypes.number,
    perPage: PropTypes.number,
    displayProgressBar: PropTypes.bool,
    isResizable: PropTypes.bool,
    useFlex: PropTypes.bool,
    pseudoUrlFiltering: PropTypes.bool,
    pseudoUrl: PropTypes.string,
    updatePseudoUrl: PropTypes.func,
    tableConfig: PropTypes.objectOf(PropTypes.any),
    enabledStyles: PropTypes.bool,
    enableWarning: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    recordsRef: PropTypes.any,
    notFoundProps: PropTypes.objectOf(PropTypes.any),
    dispatch: PropTypes.func.isRequired,
    useUnflatten: PropTypes.bool,
  };

  static defaultProps = {
    setRefresh: null,
    setTag: null,
    addNotice: null,
    showFilters: false,
    showSearch: true,
    version: null,
    filters: {},
    radioButtonHandler: null,
    selectCheckboxHandler: () => {},
    direction: 'asc',
    sortBy: null,
    csvDownload: {},
    additionalActions: null,
    meta: null,
    noActions: false,
    hideToolbar: false,
    hideFilterDropdown: false,
    v: 1,
    showTitle: true,
    columnsSettings: {},
    useCheckboxes: false,
    checkboxHandler: null,
    setMetaTotal: () => {},
    customColumns: [],
    page: 1,
    perPage: 10,
    displayProgressBar: true,
    isResizable: true,
    useFlex: true,
    pseudoUrlFiltering: false,
    pseudoUrl: '',
    updatePseudoUrl: () => {},
    tableConfig: {},
    enabledStyles: true,
    enableWarning: true,
    recordsRef: {},
    notFoundProps: {},
    useUnflatten: false,
  };
  static getPage = () => {
    const query = new URLSearchParams(window.location.search);
    // nst filters = parseUrl();
    return [Number(query.get('page')), Number(query.get('perPage'))];
  };

  constructor(props) {
    super(props);
    this.handleActionPerformed = this.handleActionPerformed.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.selectCheckboxHandler = this.selectCheckboxHandler.bind(this);
    const [page, perPage] = MatTableList.getPage();
    this.state = {
      records: [],
      page: !page || props.page === page ? props.page : page,
      perPage: !perPage || props.perPage === perPage ? props.perPage : perPage,
      total: 0,
      loading: true,
      direction: props.direction || 'asc',
      sortBy: props.sortBy || undefined,
      selectedRecords: [],
    };
    if (this.props.setRefresh) {
      this.props.setRefresh(this.fetchData, this);
    }
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  shouldComponentUpdate(newProps) {
    const {
      resource,
      location,
      version,
      pseudoUrl,
      filters,
      history,
    } = this.props;
    const [page] = getPages();
    if (!_.isEqual(filters, newProps.filters) && page !== 1) {
      setFirstPage(history);
      return false;
    }
    if (resource.id !== newProps.resource.id
      || location.search !== newProps.location.search
      || pseudoUrl !== newProps.pseudoUrl
      || (version && version !== newProps.version)
      || !_.isEqual(filters, newProps.filters)) {
      this.fetchData(newProps);
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    const { setTag } = this.props;
    if (setTag) {
      setTag('');
    }
  }

  deleteItem(id) {
    const { resource, addNotice } = this.props;
    this.setState({ loading: true });
    const api = new ApiClient();
    api.recordAction({
      resourceId: resource.id, actionName: 'delete', recordId: id,
    }).then((response) => {
      addNotice(response.data.notice);
      this.handleActionPerformed();
    }).catch(() => {
      addNotice({
        message: 'An error occurred while trying to remove the item.',
        type: 'error',
      });
    });
  }

  selectCheckboxHandler(selectedItems) {
    const selectedArray = [...selectedItems.entries()]
      .filter(({ 1: v }) => v === true)
      .map(([k]) => k);
    const { selectCheckboxHandler } = this.props;
    selectCheckboxHandler(selectedArray);
  }

  fetchData(props = this.props) {
    const {
      resource, setTag, addNotice, dispatch, useMultiPageCheckboxes, tableProperties, tableFilterUrl,
    } = props;
    const { resource: oldResource } = this.props;
    const { selectedRecords, direction, sortBy } = this.state;

    const api = new ApiClient();
    this.setState({ loading: true });
    dispatch(setLoading(true));
    let query = new URLSearchParams(url);
    if (tableProperties?.length > 0) query = new URLSearchParams(tableFilterUrl);
    if (sortBy && !query.get('sortBy')) {
      query.set('direction', direction);
      query.set('sortBy', sortBy);
    }
    // location.search += '&filters.status=New|pending';
    api.resourceAction({
      actionName: 'list',
      resourceId: resource.id,
      params: query,
    }).then((response) => {
      const listActionReponse = response.data;
      let recordsToSet = listActionReponse.records;
      const recordIds = [];
      if (this.props.useUnflatten) {
        recordsToSet = listActionReponse.records.map((item) => {
          recordIds.push({ id: item.id });
          return unflatten(item);
        });
      }
      // console.log(recordsToSet);
      this.setState({
        records: recordsToSet,
        page: listActionReponse.meta.page,
        perPage: listActionReponse.meta.perPage,
        total: listActionReponse.meta.total,
        direction: listActionReponse.meta.direction,
        sortBy: listActionReponse.meta.sortBy,
        selectedRecords: oldResource.id === resource.id ? selectedRecords : [],
        loading: false,
      });
      console.log(recordIds);
      dispatch(setState({
        records: recordIds.length > 0 && !useMultiPageCheckboxes ? recordIds : recordsToSet,
        page: listActionReponse.meta.page,
        perPage: listActionReponse.meta.perPage,
        total: listActionReponse.meta.total,
        direction: listActionReponse.meta.direction,
        sortBy: listActionReponse.meta.sortBy,
        useMultiPageCheckboxes,
      }));
      if (typeof this.props.recordsRef !== 'undefined' && this.props.recordsRef) {
        // console.log(recordsToSet);
        this.props.recordsRef.current = recordsToSet;
      }
      const { setMetaTotal } = this.props;
      setMetaTotal(listActionReponse.meta.total);
      if (setTag) {
        if (typeof response.data.meta.total === 'undefined') {
          setTag('');
        } else {
          setTag(response.data.meta.total.toString());
        }
      }
    }).catch((err) => {
      console.log(err);
      addNotice({
        message: 'There was an error fetching records, Check out console to see more information.',
        type: 'error',
      });
    });
  }

  handleActionPerformed() {
    this.fetchData(this.props);
  }

  // if you want update full record use just newRecord
  // if update just one params set newRecord as ID
  updateRecordFunction = (newRecord, data) => {
    let formattedNewRecord = newRecord;
    console.log('UPDATE', formattedNewRecord);
    if (this.props.useUnflatten) {
      formattedNewRecord = unflatten(newRecord);
    }
    console.log('UPDATE', formattedNewRecord);
    console.log('UPDATE', this.state.records);
    const { records } = this.state;
    const { dispatch } = this.props;
    let newData = [];
    if (typeof formattedNewRecord !== 'string' && formattedNewRecord?.id && !data) {
      newData = records.map((item) => {
        const newItem = { ...item };
        if (
          (item?.id === formattedNewRecord.id)
        ) {
          // newItem[name].record = newRecord;
          console.log('SET', formattedNewRecord);
          return formattedNewRecord;
        }
        return newItem;
      });
    } else if (data && formattedNewRecord) {
      newData = records.map((item) => {
        const newItem = { ...item };
        if (
          (item?.id === formattedNewRecord)
        ) {
          console.log('SET', formattedNewRecord);
          console.log('WAS', newItem);
          console.log('data', data);
          console.log('NOW', { ...newItem, params: { ...newItem.params, ...data } });
          return { ...newItem, params: { ...newItem.params, ...data } };
        }
        return newItem;
      });
    }
    // console.log('UPDATE', newData);
    this.setState({ records: newData });
    dispatch(setRecords({
      records: newData,
    }));
    this.props.recordsRef.current = newData;
  };

  render() {
    // console.log('RENDER_TABLE');
    const {
      resource, location, history, radioButtonHandler,
      csvDownload, meta, hideFilterDropdown, hideToolbar, noActions, showSearch, v, showTitle, columnsSettings,
      useCheckboxes, checkboxHandler, customColumns, displayProgressBar, isResizable, useFlex,
      pseudoUrlFiltering, pseudoUrl, updatePseudoUrl, tableConfig, enabledStyles, enableWarning, notFoundProps,
    } = this.props;
    const {
      records, page, perPage, total,
      loading, direction, sortBy,
    } = this.state;
    return (
      <Card>
        <CardBody>
          <AdminBroTable
            actionPerformed={this.handleActionPerformed}
            direction={direction}
            edit
            history={history}
            isLoading={loading}
            key="searchable2"
            location={location}
            noActions={noActions}
            page={page}
            perPage={perPage}
            radioButtonHandler={radioButtonHandler}
            records={records}
            updateRecordFunction={this.updateRecordFunction}
            resource={resource}
            selectable
            sortBy={sortBy}
            total={total}
            meta={meta}
            tableConfig={{
              isEditable: false,
              isResizable,
              useFlex,
              isSortable: true,
              withPagination: true,
              withSearchEngine: true,
              manualPageSize: [10, 20, 30, 40, 50, 75, 100],
              placeholder: 'Search by First name...',
              ...tableConfig,
            }}
            columnsSettings={columnsSettings}
            useCheckboxes={useCheckboxes}
            checkboxHandler={checkboxHandler}
            customColumns={customColumns}
            displayProgressBar={displayProgressBar}
            enabledStyles={enabledStyles}
            enableWarning={enableWarning}
            notFoundProps={notFoundProps}
          />
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  tableProperties: state.table.tablesProperties,
  tableFilterUrl: state.table.tableFilterUrl,
});

export default connect(mapStateToProps)(withNotice(withRouter(MatTableList)));
*/
