import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatter from '../../../../shared/helpers/WVFormatter';
import CreatorInList from './Table/CreatorInList';
import CAC from './Table/CAC';
import PredictedCAC from './Table/PredictedCAC';

const getCellStyles = (record) => {
  if (record?.deal?.inBlackList) {
    return { color: '#ff4861', fontWeight: 'bold' };
  }
  return {};
};

const getCACValueForSorting = (value, positive) => {
  let result = 0.001;
  if (typeof value !== 'undefined' && value !== null) {
    if (typeof value === 'string') {
      if (/^[0-9]+(\.[0-9]+)?$/.test(value)) {
        result = Number(value);
      }
    } else result = value;
  }

  return positive ? result : -result;
};

const ColumnsByPublish = () => {
  return [
    {
      Header: "Creator",
      accessor: (record) =>
        `${record?.creator?.first_name || ""} ${
          record?.creator?.last_name || ""
        }`,
      id: "Creator",
      Cell: (record) =>
        useMemo(
          () => (
            <CreatorInList
              record={record?.row?.original?.creator}
              prospects={record?.row?.original?.prospects}
            />
          ),
          [record?.row?.original?.creator, record?.row?.original?.prospects]
        ),
      width: 300,
    },
    {
      Header: "Account Manager",
      accessor: (record) =>
        `${record?.admin?.firstName || ""} ${record?.admin?.lastName || ""}`,
      id: "accountManager",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return <span style={styles}>{record?.value}</span>;
      },
      width: 100,
    },
    {
      Header: "Brand",
      accessor: (record) => record?.brand || "",
      id: "brand",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return <span style={styles}>{record?.value}</span>;
      },
      width: 100,
    },
    {
      Header: "New or Existing",
      accessor: (record) => (record?.isNew ? "New" : "Existing"),
      id: "isNew",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return <span style={styles}>{record?.value}</span>;
      },
      width: 100,
    },
    {
      Header: "Deal Name",
      accessor: (record) => record?.deal?.dealname || "",
      Cell: (record) =>
        useMemo(() => {
          const styles = getCellStyles(record?.row?.original);
          return (
            <div style={styles}>
              {record?.value}{" "}
              {record?.row?.original?.deal?.hs_object_id ? (
                <a
                  href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${record?.row?.original?.deal?.hs_object_id}/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tooltip title="Open Hubspot Deal">
                    <IconButton size="small">
                      <i
                        className="fab fa-hubspot text-primary"
                        style={{ fontSize: "0.8em" }}
                      />
                    </IconButton>
                  </Tooltip>
                </a>
              ) : null}
            </div>
          );
        }, [record?.row?.original, record?.value]),
      width: 120,
    },
    {
      Header: "Type Of Deal",
      accessor: (record) => record?.typeOfDeal || "",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return <span style={styles}>{record?.value}</span>;
      },
      width: 125,
    },
    {
      Header: "Publish Date",
      accessor: (record) => record?.publishDate || null,
      id: "publishDate",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {record?.value
              ? moment
                  .tz(
                    moment.utc(record.value).format("YYYY-MM-DD"),
                    "America/New_York"
                  )
                  .format("MM/DD/YYYY")
              : ""}
          </span>
        );
      },
      width: 100,
    },
    {
      Header: "Days Since/Between Publish",
      accessor: (record) => {
        if (record?.publishDate) {
          let nextPublishDate = moment.utc();
          if (record.nextDealFirstPublishDate) {
            nextPublishDate = moment.utc(record.nextDealFirstPublishDate);
          }
          return moment.tz(nextPublishDate.format('YYYY-MM-DD'), 'America/New_York').diff(moment.tz(moment.utc(record.publishDate).format('YYYY-MM-DD'), 'America/New_York'), 'days')
        }
        return '-';
      },
      id: "days",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        let value = '';
        if (record?.row?.original.publishDate) {
          let nextPublishDate = moment.utc();
          if (record.row.original.nextDealFirstPublishDate) {
            nextPublishDate = moment.utc(record.row.original.nextDealFirstPublishDate);
          }
          value = moment.tz(nextPublishDate.format('YYYY-MM-DD'), 'America/New_York')
            .diff(moment.tz(moment.utc(record.row.original.publishDate)
              .format('YYYY-MM-DD'), 'America/New_York'), 'days')
        }
        return (
          <span style={styles}>
            {value}
          </span>
        );
      },
      width: 100,
    },
    {
      Header: "Number of Publishes on Deal",
      accessor: (record) => record?.numberOfPublishesOnDeal,
      id: "numberOfPublishesOnDeal",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {record?.value !== null && /^[0-9]+(\.[0-9]+)?$/.test(record.value)
              ? formatter.formatIntNumber(Number(record.value))
              : record.value}
          </span>
        );
      },
      width: 100,
    },
    {
      Header: "Spend In Timeframe",
      accessor: (record) => Number(record?.spendInTimeframe || 0),
      id: "spendInTimeframe",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {formatter.formatCurrency(record?.value || 0)}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: "Spend To Date",
      accessor: (record) => Number(record?.spendToDate || 0),
      id: "spendToDate",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {formatter.formatCurrency(record?.value || 0)}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: "Revenue Conversions In Timeframe",
      accessor: (record) => record?.conversionsInTimeframe,
      id: "conversionsInTimeframe",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {record?.value !== null && /^[0-9]+(\.[0-9]+)?$/.test(record.value)
              ? formatter.formatIntNumber(Number(record.value))
              : record.value}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: "Revenue Conversions To Date",
      accessor: (record) => record?.conversionsToDate,
      id: "conversionsToDate",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {record?.value !== null && /^[0-9]+(\.[0-9]+)?$/.test(record.value)
              ? formatter.formatIntNumber(Number(record.value))
              : record.value}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: "Predicted Revenue Conversions",
      accessor: (record) => record?.projectedConversions,
      id: "projectedConversions",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <>
            {record?.row?.original?.brand === "BetterHelp" &&
            record?.row?.original?.typeOfDeal === "Upfront CPM" ? (
              <span style={styles}>
                {record?.value !== null &&
                /^[0-9]+(\.[0-9]+)?$/.test(record.value)
                  ? formatter.formatIntNumber(Number(record.value), 0)
                  : record.value}
              </span>
            ) : (
              "-"
            )}
          </>
        );
      },
      width: 120,
    },
    {
      Header: "CAC In Timeframe",
      accessor: (record) => getCACValueForSorting(record?.cacInTimeframe, record?.positiveCacInTimeframe),
      sortType: (rowA, rowB) => {
        const valueA = getCACValueForSorting(rowA?.original?.cacInTimeframe, rowA?.original?.positiveCacInTimeframe);
        const valueB = getCACValueForSorting(rowB?.original?.cacInTimeframe, rowB?.original?.positiveCacInTimeframe);
        if (valueA > valueB) return 1;
        if (valueB > valueA) return -1;
        return 0;
      },
      id: "cacInTimeframe",
      Cell: (record) =>
        useMemo(() => {
          return (
            <div>
              {record?.row?.original?.typeOfDeal === "CPA/Ambassador" ? (
                <FontAwesomeIcon icon="thumbs-up" className="text-success" />
              ) : (
                <CAC
                  cac={record?.row?.original?.cacInTimeframe}
                  targetCac={record?.row?.original?.targetCac}
                  defaultTargetCac={record?.row?.original?.defaultTargetCac}
                  targetCacPercentage={
                    record?.row?.original?.targetCacPercentage
                  }
                  allow={record?.row?.original?.positiveCacInTimeframe || false}
                />
              )}
            </div>
          );
        }, [record?.value, record?.row?.original]),
      width: 120,
    },
    {
      Header: "CAC To Date",
      accessor: (record) => getCACValueForSorting(record?.cacToDate, record?.positiveCacToDate),
      sortType: (rowA, rowB) => {
        const valueA = getCACValueForSorting(rowA?.original?.cacToDate, rowA?.original?.positiveCacToDate);
        const valueB = getCACValueForSorting(rowB?.original?.cacToDate, rowB?.original?.positiveCacToDate);
        if (valueA > valueB) return 1;
        if (valueB > valueA) return -1;
        return 0;
      },
      id: "cacToDate",
      Cell: (record) =>
        useMemo(
          () => (
            <div>
              {record?.row?.original?.typeOfDeal === "CPA/Ambassador" ? (
                <FontAwesomeIcon icon="thumbs-up" className="text-success" />
              ) : (
                <CAC
                  cac={record?.row?.original?.cacToDate}
                  targetCac={record?.row?.original?.targetCac}
                  defaultTargetCac={record?.row?.original?.defaultTargetCac}
                  targetCacPercentage={
                    record?.row?.original?.targetCacPercentage
                  }
                  allow={record?.row?.original?.positiveCacToDate || false}
                />
              )}
            </div>
          ),
          [record?.value, record?.row?.original]
        ),
      width: 120,
    },
    {
      Header: "Predicted CAC",
      accessor: (record) => getCACValueForSorting(record?.projectedCac, record?.positiveProjectedCac),
      sortType: (rowA, rowB) => {
        const valueA = getCACValueForSorting(rowA?.original?.projectedCac, rowA?.original?.positiveProjectedCac);
        const valueB = getCACValueForSorting(rowB?.original?.projectedCac, rowB?.original?.positiveProjectedCac);
        if (valueA > valueB) return 1;
        if (valueB > valueA) return -1;
        return 0;
      },
      id: "projectedCac",
      Cell: (record) =>
        useMemo(
          () => (
            <>
              {record?.row?.original?.brand === "BetterHelp" && record?.row?.original?.typeOfDeal === "Upfront CPM" ? (
                <PredictedCAC
                  projectedPercent={record?.row?.original?.projectedPercent}
                  projectedConversions={record?.row?.original?.projectedConversions}
                  projectedCac={record?.row?.original?.projectedCac}
                  defaultTargetCac={record?.row?.original?.defaultTargetCac}
                  publishDate={record?.row?.original?.publishDate}
                  allow={record?.row?.original?.positiveProjectedCac}
                />
              ) : (
                "-"
              )}
            </>
          ),
          [record?.value, record?.row?.original]
        ),
      width: 120,
    },
    {
      Header: "Gross PAR",
      accessor: (record) => record?.grossPar,
      id: "grossPar",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <>
            {record?.row?.original?.brand === "BetterHelp" &&
            record?.row?.original?.typeOfDeal === "Upfront CPM" &&
            !record?.row?.original?.positiveCacToDate &&
            !record?.row?.original?.positiveProjectedCac &&
            record?.value !== "-" ? (
              <span style={styles}>
                {formatter.formatCurrency(record?.value || 0)}
              </span>
            ) : (
              "-"
            )}
          </>
        );
      },
      width: 120,
    },
    {
      Header: "Net PAR",
      accessor: (record) => record?.netPar,
      id: "netPar",
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <>
            {record?.row?.original?.brand === "BetterHelp" &&
            record?.row?.original?.typeOfDeal === "Upfront CPM" &&
            !record?.row?.original?.positiveCacToDate &&
            !record?.row?.original?.positiveProjectedCac &&
            record?.value !== "-" ? (
              <span style={styles}>
                {formatter.formatCurrency(record?.value || 0)}
              </span>
            ) : (
              "-"
            )}
          </>
        );
      },
      width: 120,
    },
  ];
};

export default ColumnsByPublish;
