import React, { useState, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';

// import PropTypes from 'prop-types';
// import DatePicker from 'react-datepicker';
import ApiClient from 'ApiClient';
import { Row, Col, Container, Card, CardBody, CardTitle, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import SelectAsync from 'react-select/async';

// import MatTableForRedux from '../../../../shared/tables/materialTable/components/MatTableForRedux';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import formater from '../../../../shared/helpers/WVFormatter';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';

// import CheckBoxField from '../../../../shared/components/CheckBox';
// import Collapse from '../../../../shared/components/Collapse';
// import HubspotDealLink from '../../../../shared/components/HubspotDealLink';


const calculateForDeal = (deal) => {
  let a = deal.payables.reduce((accumulator, currentValue) => {
    // eslint-disable-next-line
    accumulator += Number(currentValue.profit || 0);
    return accumulator;
  }, 0);
  if (deal.bill) {
    a -= Number(deal.billTotal);
  }
  if (deal.invoice) {
    a += Number(deal.invoiceTotal);
  }
  if (deal.currentMonthPayout) {
    a -= Number(deal.currentMonthPayout);
  }
  if (deal.currentMonthRevenue) {
    a += Number(deal.currentMonthRevenue);
  }
  return Number(a || 0);
};
const calculateRevenueForDeal = (deal) => {
  let a = deal.payables.reduce((accumulator, currentValue) => {
    // eslint-disable-next-line
    accumulator += Number(currentValue.revenueAmount || 0);
    return accumulator;
  }, 0);
  if (deal.currentMonthRevenue) {
    a += Number(deal.currentMonthRevenue);
  }
  return Number(a || 0);
};


const SummaryReport = ({ addNotice }) => {
  const [loading, setLoaing] = useState(false);
  const [ap, setAp] = useState(null);
  const [payables, setPayables] = useState([]);

  // const [advertisers, setAdvertisers] = useState([]);
  const [items, setItems] = useState([]);

  const [identity] = useState(Math.random().toString(36).substring(2));
  // const [error, setError] = useState(null);
  // const [version, setVersion] = useState((new Date()).toString());
  const includedLastMonth = moment(ap?.params?.startDate).toDate() < moment().startOf('month').toDate() && moment(ap?.params?.endDate).toDate() > moment().subtract(1, 'month').startOf('month').toDate();
  const loadAPOptions = async (inputValue) => {
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'AccountsPayable',
      query: inputValue,
    });
    const res = [];
    records.forEach((record) => {
      res.push({
        value: record.id,
        label: record.params.name,
        params: record.params,
        record,
      });
    });
    return res;
  };
  /* const columnsBrands = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: (deal) => {
          const advertiser = advertisers.find(a => a.tuneId === deal.tuneId);
          return advertiser?.companyName || '';
        },
        id: 'Brand',
        Cell: ({ value }) => value,
      },
      {
        Header: 'Gross Revenue',
        accessor: deal => ((deal.grossRevenue || 0)),
        id: 'grossRevenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Gross Profit',
        accessor: deal => ((deal.grossProfit || 0)),
        id: 'grossProfit',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossProfit) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
    ],
    [advertisers],
  ); */
  const columnsDeals = useMemo(
    () => [
      {
        Header: 'Deal',
        accessor: deal => deal.dealname,
        id: 'dealname',
        Cell: ({ value }) => value,
        width: 250,
      },
      {
        Header: 'Gross Revenue',
        accessor: deal => calculateRevenueForDeal(deal),
        id: 'grossRevenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Gross Payable Payout',
        accessor: deal => deal.payables.reduce((accumulator, currentValue) => {
          // eslint-disable-next-line
          accumulator += Number(currentValue.profit || 0);
          return accumulator;
        }, 0),
        id: 'grosspayablePayout',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grosspayablePayout) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Bill',
        accessor: deal => deal.billTotal || 0,
        id: 'bill',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.bill) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'invoice',
        accessor: deal => deal.invoiceTotal || 0,
        id: 'invoice',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.invoice) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Gross Profit',
        accessor: deal => calculateForDeal(deal),
        id: 'grossProfit',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossProfit) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
    ],
    [],
  );
  const columnsPayables = useMemo(
    () => [
      {
        Header: 'Payable Deal',
        accessor: record => (record.populated?.deal?.params?._id ? record.populated?.deal?.params?.dealname : 'NOT FOUND DEAL'),
        id: 'dealname',
        Cell: ({ value }) => value,
        width: 500,
      },
      {
        Header: 'Gross Revenue',
        accessor: record => record?.params?.revenueAmount,
        id: 'grossRevenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 100,
      },
      {
        Header: 'Gross Payout',
        accessor: record => record?.params?.ilPayoutAmount,
        id: 'grossPayout',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossPayout) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 100,
      },
      {
        Header: 'Gross Profit',
        accessor: record => (record?.params?.revenueAmount || 0) - (record?.params?.ilPayoutAmount || 0),
        id: 'grossProfit',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossProfit) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 100,
      },
    ],
    [],
  );
  const columnsPayablesDeals = useMemo(
    () => [
      {
        Header: 'Deal for Report',
        accessor: ({ deal }) => deal.dealname,
        id: 'dealname',
        Cell: ({ value }) => value,
        width: 250,
      },
      {
        Header: 'Payable Deal',
        accessor: ({ payable }) => (payable.populated?.deal?.params?._id ? payable.populated?.deal?.params?.dealname : 'NOT FOUND DEAL'),
        id: 'dealnamePayable',
        Cell: ({ value }) => value,
        width: 250,
      },
      {
        Header: 'Gross Revenue for Report',
        accessor: ({ deal }) => (deal.payables ? deal.payables[0]?.revenueAmount : 0),
        id: 'grossRevenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Gross Revenue Payable',
        accessor: ({ payable }) => payable?.params?.revenueAmount,
        id: 'grossRevenuePayable',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenuePayable) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 180,
      },
      {
        Header: 'Gross Payout for Report',
        accessor: ({ deal }) => (deal.payables ? deal.payables[0]?.ilPayoutAmount : 0),
        id: 'grossPayout',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 180,
      },
      {
        Header: 'Gross Payout Payable',
        accessor: ({ payable }) => payable?.params?.ilPayoutAmount,
        id: 'grossPayoutPayable',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenuePayable) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 180,
      },
    ],
    [],
  );

  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  const fetchAmountReport = async () => {
    setLoaing(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-fetch-all-deals', {
        startDate: moment(ap.params.startDate).format('YYYY-MM-DD'),
        endDate: moment(ap.params.endDate).format('YYYY-MM-DD'),
        identity,
        ...includedLastMonth && { estimatePrevious: false, includePrevious: true, selectedStatuses: ['approved', 'complete', 'pending', 'failed'] },
      });
      if (resp.data.success) {
        setItems(resp.data.items);
        setLoaing(false);
        // setVersion((new Date()).toString());
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }

      const respPayables = await axios.get(`/api/portal/finance/get-payouts?reportId=${ap.value}`);
      if (respPayables.data) {
        setPayables(respPayables.data.records);
        setLoaing(false);
        // setVersion((new Date()).toString());
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const [notFoundDealsArray, notFoundPayablesArray, notMatchPayablesArray, totalSumReport, totalSumAP, missingDealSum, totalSumReportP, totalSumAPP, missingDealSumP] = useMemo(() => {
    const foundPayables = [];
    const notFoundDeals = [];
    const notFoundPayables = [];
    const notMatchPayables = [];
    let sum1 = 0;
    let sum2 = 0;
    let sum3 = 0;

    let sum1P = 0;
    let sum2P = 0;
    let sum3P = 0;
    items.forEach((deal) => {
      const payablesArray = [];
      payables.forEach((payable) => {
        if (payable.populated.deal && payable.populated.deal?.params?.hs_object_id.toString() === deal.hs_object_id.toString()) {
          payablesArray.push(payable);
        }
      });
      if (payablesArray.length === 1
        && (payablesArray[0].id !== deal.payables[0]?._id
          || Math.floor(payablesArray[0]?.params?.revenueAmount) !== Math.floor(deal.payables[0]?.revenueAmount)
          || Math.floor(payablesArray[0]?.params?.ilPayoutAmount) !== Math.floor(deal.payables[0]?.ilPayoutAmount)
        )) {
        console.log('payables Not Match', [payablesArray[0], deal]);
        notMatchPayables.push({ payable: payablesArray[0], deal });
      }
      if (payablesArray.length === 1) foundPayables.push(payablesArray[0]);
      sum1 += deal.payables.reduce((accumulator, currentValue) => {
      // eslint-disable-next-line
        accumulator += Number(currentValue.revenueAmount || 0);
        return accumulator;
      }, 0);
      sum1P += deal.payables.reduce((accumulator, currentValue) => {
        // eslint-disable-next-line
        accumulator += Number(currentValue.ilPayoutAmount || 0);
        return accumulator;
      }, 0);
      if (payablesArray.length > 1) console.log('found 2', [payablesArray, deal]);
      if (payablesArray.length === 0) {
        console.log('found 0', deal);
        notFoundDeals.push(deal);
      }
    });
    payables.forEach((payable) => {
      sum2 += payable.params.revenueAmount || 0;
      sum2P += payable.params.ilPayoutAmount || 0;
      if (!foundPayables.find(p => p.id === payable.id)) {
        sum3 += payable.params.revenueAmount || 0;
        sum3P += payable.params.ilPayoutAmount || 0;
        console.log('missing payable', payable);
        notFoundPayables.push(payable);
      }
    });
    console.log('sum revenue for not found', sum3);
    console.log('total payable revenue', sum2);
    console.log([sum1, sum2, sum3]);
    window.auditData = {
      notFoundDeals, notFoundPayables, notMatchPayables,
    };
    return [notFoundDeals, notFoundPayables, notMatchPayables, sum1, sum2, sum3, sum1P, sum2P, sum3P];
  }, [payables]);
  console.log(notMatchPayablesArray);

  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem' }}>
              <CardTitle>
                <h3>AUDIT</h3>
              </CardTitle>
              <Row>
                <Col>
                  <h5>Total Sum report revenue {formater.formatCurrency(totalSumReport)}</h5>
                  <h5>Total Sum AP Report revenue {formater.formatCurrency(totalSumAP)}</h5>
                  <h5>Missing deal Revenue {formater.formatCurrency(missingDealSum)}</h5>
                  <h5>Total Sum report payout {formater.formatCurrency(totalSumReportP)}</h5>
                  <h5>Total Sum AP Report payout {formater.formatCurrency(totalSumAPP)}</h5>
                  <h5>Missing deal payout {formater.formatCurrency(missingDealSumP)}</h5>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <SelectAsync
                    cacheOptions
                    value={ap}
                    defaultOptions
                    loadOptions={loadAPOptions}
                    onChange={a => setAp(a)}
                    placeholder="Select"
                  />
                </Col>
                <Col lg="auto">
                  <Button
                    color="primary"
                    onClick={async () => {
                      await fetchAmountReport();
                    }}
                    className=""
                    disabled={!!loading}
                  >
                    Run Report
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    topic="report"
                    identity={identity}
                    isShow={!!loading}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!loading && notFoundDealsArray.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody style={{ padding: '2rem' }}>
                <CardTitle>
                  <h3>Report Items that we didn{'\''}t find in AP Payables</h3>
                </CardTitle>
                <Row >
                  <Col className="mt-3">
                    {!loading && items.length > 0 ? (
                      <ReactTableBase
                        key="searchable"
                        columns={columnsDeals}
                        data={notFoundDealsArray}
                        tableConfig={tableConfig}
                      />) : null
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>) : null
      }
      {!loading && notFoundPayablesArray.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody style={{ padding: '2rem' }}>
                <CardTitle>
                  <h3>AP Report Items that we didn{'\''}t find  in Reports</h3>
                </CardTitle>
                <Row >
                  <Col className="mt-3">
                    {!loading && items.length > 0 ? (
                      <ReactTableBase
                        key="searchable"
                        columns={columnsPayables}
                        data={notFoundPayablesArray}
                        tableConfig={tableConfig}
                      />) : null
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>) : null
      }
      {!loading && notMatchPayablesArray.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody style={{ padding: '2rem' }}>
                <CardTitle>
                  <h3>We find both items but Revenue or Payables do not match</h3>
                </CardTitle>
                <Row >
                  <Col className="mt-3">
                    {!loading && items.length > 0 ? (
                      <ReactTableBase
                        key="searchable"
                        columns={columnsPayablesDeals}
                        data={notMatchPayablesArray}
                        tableConfig={tableConfig}
                      />) : null
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>) : null
      }
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
