import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableCellContextMenu from '../../../../Research/Prospects/components/custom/TableCellContextMenu';
const ContextMenuInList = ({
  record,
  dispatch,
}) => {
  const i = record;

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const contextMenuOptions = [
    { label: 'Edit', handler: () => goToPage(`/create-notification/${i.id}`) },
  ];

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, null)(ContextMenuInList);
