/* eslint-disable filenames/match-exported */
import React, { useState } from 'react';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import { Modal, Button, ModalHeader, ModalFooter, ModalBody, Row, Col } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from 'mdi-react/InformationIcon';
import { formatIntNumber, formatNumber } from '../../../../../shared/helpers/WVFormatter';

const TVRInList = ({
  record,
}) => {
  const videoStatistic = unflatten(record).params;
  const [showModal, setShowModal] = useState(false);
  const er30 = videoStatistic?.tvr?.er30 ? formatNumber(videoStatistic.tvr.er30) : 'n/a';

  return (
    <>
      <span>{er30}</span>
      {videoStatistic?.tvr && Object.keys(videoStatistic.tvr).length > 0 && (
        <>
          <Tooltip title="Show all TVR values">
            <IconButton
              size="small"
              onClick={() => setShowModal(!showModal)}
              className="material-table__toolbar-button"
              style={{ marginTop: '-2px' }}
            >
              <InfoIcon size="15" />
            </IconButton>
          </Tooltip>
          <Modal
            isOpen={showModal}
            toggle={() => setShowModal(!showModal)}
            className="theme-light ltr-support login-modal-form"
            size="sm"
          >
            <ModalHeader toggle={() => setShowModal(!showModal)} className="bold-text">
              TVR Values
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm={6}>
                  <div>
                    <span className="bold-text">V1: </span>
                    <span>{videoStatistic?.tvr?.v1 ? formatNumber(videoStatistic.tvr.v1) : 'n/a'}</span>
                  </div>
                  <div>
                    <span className="bold-text">V2: </span>
                    <span>{videoStatistic?.tvr?.v2 ? formatIntNumber(videoStatistic.tvr.v30) : 'n/a'}</span>
                  </div>
                  <div>
                    <span className="bold-text">V3: </span>
                    <span>{videoStatistic?.tvr?.v3 ? formatIntNumber(videoStatistic.tvr.v30) : 'n/a'}</span>
                  </div>
                  <div>
                    <span className="bold-text">V7: </span>
                    <span>{videoStatistic?.tvr?.v7 ? formatIntNumber(videoStatistic.tvr.v30) : 'n/a'}</span>
                  </div>
                  <div>
                    <span className="bold-text">V30: </span>
                    <span>{videoStatistic?.tvr?.v30 ? formatIntNumber(videoStatistic.tvr.v30) : 'n/a'}</span>
                  </div>
                </Col>
                <Col sm={6}>
                  <div>
                    <span className="bold-text">ER1: </span>
                    <span>{videoStatistic?.tvr?.er1 ? formatNumber(videoStatistic.tvr.er1) : 'n/a'}</span>
                  </div>
                  <div>
                    <span className="bold-text">ER2: </span>
                    <span>{videoStatistic?.tvr?.er2 ? formatNumber(videoStatistic.tvr.er2) : 'n/a'}</span>
                  </div>
                  <div>
                    <span className="bold-text">ER3: </span>
                    <span>{videoStatistic?.tvr?.er3 ? formatNumber(videoStatistic.tvr.er3) : 'n/a'}</span>
                  </div>
                  <div>
                    <span className="bold-text">ER7: </span>
                    <span>{videoStatistic?.tvr?.er7 ? formatNumber(videoStatistic.tvr.er7) : 'n/a'}</span>
                  </div>
                  <div>
                    <span className="bold-text">ER30: </span>
                    <span>{er30}</span>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn-sm"
                color="secondary"
                onClick={() => setShowModal(!showModal)}
                style={{ marginBottom: '4px' }}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
};

TVRInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TVRInList;
