import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row, Col, Button, Container, Card, CardBody, CardTitle, Label, FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../App/store/with-notice';
import Filter from './TimePeriodFilterCustom';
import ChartComponentCommentsPercentage from './ChartComponentCommentsPercentage';
import ChartComponentCommentsWithSentinement from './ChartComponentCommentsWithSentinement';
import ChartComponentCommentsTotal from './ChartComponentCommentsTotal';
import ChartComponentCreatorsPositive from './ChartComponentCreatorsPositive';
import ChartComponentCreatorsNegative from './ChartComponentCreatorsNegative';
import ChartComponentVideosPositive from './ChartComponentVideosPositive';
import ChartComponentVideosNegative from './ChartComponentVideosNegative';
import CheckBoxField from '../../../../shared/components/CheckBox';
import hooks from '../../../../shared/hooks';
import LoadingReport from "../../../../shared/components/LoadingReport";

const dataOptions = [
  { value: 'byDay', label: 'By Day' },
  { value: 'week', label: 'By Week' },
  { value: 'month', label: 'By Month' },
  { value: 'total', label: 'Over Time' },
];

const YouTubeCommentsAnalysisReport = ({ addNotice }) => {
  const [useShowForUsers] = hooks.useAccessHook({ hideWhenTeam: ['Auditors'] });
  const [loading, setLoading] = useState(false);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [advertisers, setAdvertisers] = useState([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [selectedOption, setSelectedOption] = useState(dataOptions[0]);
  const [periodsData, setPeriodsData] = useState({
    comments: [],
    influencersNegative: [],
    influencersPositive: [],
    mediaContentPositive: [],
    mediaContentNegative: [],
  });
  const advertiserOptions = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    const i = advertisers.map(item => ({ value: item._id, label: item.companyName })).sort((a, b) => ((a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)));
    return i;
  }, [advertisers]);

  const customStyles = {
    control: provided => ({
      ...provided,
      minHeight: '31px',
      height: '31px',
    }),

    valueContainer: provided => ({
      ...provided,
      height: '32px',
      padding: '0 6px',
    }),
    placeholder: provided => ({
      ...provided,
      paddingBottom: '1px',
    }),
    singleValue: provided => ({
      ...provided,
      paddingBottom: '1px',
    }),

    // input: provided => ({
    //   ...provided,
    //   margin: '0px',
    // }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      height: '31px',
      paddingBottom: '1px',
    }),
  };

  const commentsStats = useMemo(() => {
    const sumAll = ({
      count, negativeCount, neutralCount, positiveCount, label,
    }, item) => {
      console.log(item);
      return {
        count: count + item?.count || 0,
        negativeCount: negativeCount + item?.negativeCount || 0,
        neutralCount: neutralCount + item?.neutralCount || 0,
        positiveCount: positiveCount + item?.positiveCount || 0,
        positivePercentage: (count + item?.count || 0) ? ((positiveCount + item?.positiveCount || 0) / (count + item?.count || 0)) : 0,
        negativePercentage: (count + item?.count || 0) ? ((negativeCount + item?.negativeCount || 0) / (count + item?.count || 0)) : 0,
        label: label || item?.label,
      };
    };
    const total = selectedOption.value;
    const startMoment = moment(startDate).startOf('day');
    const endMoment = moment() > moment(endDate).endOf('day') ? moment(endDate).endOf('day') : moment().endOf('day');
    const chartData = {};
    const chartDataMonth = {};
    const chartDataWeek = {};
    const totalData = {};
    const days = endMoment.diff(startMoment, 'day');
    const months = endMoment.diff(startMoment, 'month');
    const weeks = endMoment.diff(startMoment, 'weeks') + 1;

    for (let i = 0; i <= days; i += 1) {
      const momentDate = startMoment.clone().add(i, 'day');
      const key = momentDate.format('YYYY-MM-DD');
      chartData[key] = {
        label: momentDate.format('M/D/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
      totalData[key] = {
        label: momentDate.format('M/D/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
    }
    for (let i = 0; i <= months; i += 1) {
      const momentDate = startMoment.clone().add(i, 'month');
      const key = momentDate.format('YYYY-MM');
      chartDataMonth[key] = {
        label: momentDate.format('M/YY'), count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
    }
    for (let i = 0; i <= weeks; i += 1) {
      const momentDate = startMoment.clone().add(i, 'week').startOf('week');
      const key = momentDate.format('YYYY-w');
      // console.log(key, moment(key, 'YYYY-w').startOf('week'), endMoment);
      if (momentDate > endMoment) break;
      chartDataWeek[key] = {
        label: `${moment(key, 'YYYY-w').startOf('week').format('M/D/YY')} - ${moment(key, 'YYYY-w').endOf('week').format('M/D/YY')}`,
        count: 0,
        negativeCount: 0,
        neutralCount: 0,
        positiveCount: 0,
        positivePercentage: 0,
        negativePercentage: 0,
      };
    }


    periodsData.comments.forEach(({
      label, count, negativeCount, neutralCount, positiveCount,
    }) => {
      const week = moment(label).format('YYYY-w');
      const byMonthKey = label.substring(0, 7);
      chartData[label] = sumAll({
        count, negativeCount, neutralCount, positiveCount,
      }, chartData[label]);
      chartDataMonth[byMonthKey] = sumAll({
        count, negativeCount, neutralCount, positiveCount, label: moment(label).format('M/YY'),
      }, chartDataMonth[byMonthKey]);
      chartDataWeek[week] = sumAll({
        count, negativeCount, neutralCount, positiveCount,
      }, chartDataWeek[week]);
    });

    if (total === 'total') {
      let beforeValue = {
        count: 0, negativeCount: 0, neutralCount: 0, positiveCount: 0,
      };
      Object.keys(chartData).forEach((day) => {
        const valueForDays = chartData[day];
        totalData[day] = sumAll(beforeValue, valueForDays);
        beforeValue = { ...totalData[day] };
      });
    }
    console.log(Object.values(chartData));
    if (total === 'month') return Object.values(chartDataMonth);
    if (total === 'week') return Object.values(chartDataWeek);
    if (total === 'total') return Object.values(totalData);
    return Object.values(chartData);
  }, [selectedOption, periodsData.comments]);

  useEffect(() => {
    const api = new ApiClient();
    setLoadingAdvertiser(true);
    api.client.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser._id]) brands[offer.advertiser._id] = { ...offer.advertiser };
      });
      setAdvertisers(Object.values(brands));
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load advertisers. Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);

  const setPeriodDataForTypes = (comments, influencersNegative, influencersPositive, mediaContentPositive, mediaContentNegative) => {
    const array = {
      comments,
      influencersNegative,
      influencersPositive,
      mediaContentPositive,
      mediaContentNegative,
    };
    setPeriodsData(array);
  };

  const fetchReport = async () => {
    setLoading(true);
    try {
      const resp = await axios.post('/api/portal/reports/post-report-youtube-comments-analysis', {
        startDate,
        endDate,
        advertiserId: selectedAdvertiser?.value || null,
        identity,
      });
      setLoading(false);
      if (resp.data.success) {
        const {
          comments, influencersNegative, influencersPositive, mediaContentPositive, mediaContentNegative,
        } = resp.data;
        setPeriodDataForTypes(comments, influencersNegative, influencersPositive, mediaContentPositive, mediaContentNegative);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  if (!useShowForUsers) {
    return null;
  }
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Statistics', path: false },
              { title: 'YouTube Comments Analysis', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>YouTube Comments Analysis</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col md={5}>
                  <Label className="bold-text">Published At</Label>
                  <Filter
                    setValue={(param, value) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                  />
                </Col>
                <Col md={4}>
                  <Label className="bold-text">Brand</Label>
                  <Select
                    isDisabled={loadingAdvertiser}
                    value={selectedAdvertiser}
                    options={advertiserOptions}
                    onChange={setSelectedAdvertiser}
                    placeholder="Select Brand..."
                    isClearable
                    styles={customStyles}
                  />
                </Col>
                <Col md={3}>
                  <div
                    className="d-flex"
                    style={{
                      float: 'right',
                      paddingTop: '31px',
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchReport();
                        return false;
                      }}
                      className="my-0 btn-sm"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {/* <Row className="mt-3" >
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    topic="report"
                    identity={identity}
                    isShow={!!loading}
                  />
                </Col>
              </Row> */}
              {!loading && periodsData.comments.length > 0 && (
                <>
                  <Row className="mt-3">
                    <Col>
                      <div
                        className="form-group mt-3"
                        style={{ width: '250px' }}
                      >
                        <Select
                          placeholder="Select Property to Chart..."
                          value={selectedOption}
                          options={dataOptions}
                          onChange={setSelectedOption}
                        />
                      </div>
                    </Col>
                    <Col />
                  </Row>
                  <Row className="mt-3">
                    <Col md={8}>
                      <FormGroup>
                        <CheckBoxField
                          name="showLabels"
                          label="Show Labels on Chart"
                          value={showLabels}
                          onChange={e => setShowLabels(e.target?.checked || false)}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Label className="bold-text">Percent Positive & Negative Comments</Label>
                      <ChartComponentCommentsPercentage
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Label className="bold-text">Positive and Negative Comments</Label>
                      <ChartComponentCommentsWithSentinement
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Label className="bold-text">Total Comments</Label>
                      <ChartComponentCommentsTotal
                        periodsData={commentsStats}
                        showLabels={showLabels}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg={6} md={12}>
                      <Label className="bold-text">Top 10 Creators with Highest Percentage of Positive Comments</Label>
                      <ChartComponentCreatorsPositive
                        periodsData={periodsData.influencersPositive}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <Label className="bold-text">Top 10 Creators with Highest Percentage of Negative Comments</Label>
                      <ChartComponentCreatorsNegative
                        periodsData={periodsData.influencersNegative}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg={6} md={12}>
                      <Label className="bold-text">Top 10 Videos with the Highest Percentage of Positive Comments</Label>
                      <ChartComponentVideosPositive
                        periodsData={periodsData.mediaContentPositive}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <Label className="bold-text">Top 10 Videos with the Highest Percentage of Negative Comments</Label>
                      <ChartComponentVideosNegative
                        periodsData={periodsData.mediaContentNegative}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

YouTubeCommentsAnalysisReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(YouTubeCommentsAnalysisReport);
