import React, { PureComponent } from 'react';
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  InputGroupAddon,
  InputGroup,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import EyeIcon from 'mdi-react/EyeIcon';
import Alert from '../../../shared/easydev2/shared/components/Alert';
import WVValidator from '../../../shared/helpers/WVValidator';
import config from '../../../shared/config/config';
import { initializeSession } from '../../App/store/store';
import Footer from '../../creator/LogIn/components/Footer';

const recaptchaRef = React.createRef();

class ResetPassword extends PureComponent {
  constructor() {
    super();

    this.state = {
      newPassword: '',
      confirmPassword: '',
      showNewPassword: false,
      showConfirmPassword: false,
      recaptchaToken: '',
      errors: {},
      isLoadingData: false,
      isPopoverOpen: false,
    };
  }

  onRecaptchaChange = (value) => {
    this.setState({
      recaptchaToken: value,
    });
  };


  onDataChanged = (event) => {
    const { name, value } = event.target;
    const state = {
      ...this.state,
      [name]: value,
    };

    delete state.errors[name]; // clear errors

    this.setState(state);
  }

  onSubmit = () => {
    if (this.state.isLoadingData) { return false; }
    if (!this.validateErrors()) { return false; }

    const { dispatch } = this.props;

    this.setState({
      isLoadingData: true,
    });

    axios({
      method: 'post',
      url: '/api/portal/reset-password',
      data: {
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
        token: this.props.match.params.token,
        recaptchaToken: this.state.recaptchaToken,
      },
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);

      if (response.data.success === true) {
        dispatch(initializeSession(response.data.user));
        window.location.replace('/');
      } else {
        this.handleSubmitError(response.data.error);
      }
    }).catch((error) => {
      // console.log('CATCH ERROR: ',error);
      this.handleSubmitError(error.message);
    });
    return null;
  }

  handleSubmitError(error) {
    recaptchaRef.current.reset();

    const state = {
      ...this.state,
      isLoadingData: false,
    };

    state.errors.global = error;

    this.setState(state);
  }

  showNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };

  showConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  togglePopover = () => {
    this.setState({
      isPopoverOpen: !this.state.isPopoverOpen,
    });
  };

  validateErrors() {
    const errors = {};

    if (WVValidator.isEmpty(this.state.newPassword)) {
      errors.newPassword = 'Please provide your new password!';
    } else if (WVValidator.isEmpty(this.state.confirmPassword)) {
      errors.confirmPassword = 'Please confirm your password!';
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      errors.global = 'Passwords do not match!';
    } else if (WVValidator.isEmpty(this.state.recaptchaToken)) {
      errors.global = 'Please verify that you are not a robot!';
    } else if (!WVValidator.isValidPassword(this.state.newPassword)) {
      errors.newPassword = 'This password does not meet requirements!';
    }
    this.setState({
      ...this.state,
      errors,
    });

    return Object.entries(errors).length === 0;
  }

  render() {
    const {
      newPassword,
      confirmPassword,
      showNewPassword,
      showConfirmPassword,
      errors,
      isLoadingData,
    } = this.state;
    return (
      <div>
        <div className="account account--not-photo login-admin-bg">
          <div className="account__wrapper">
            <div className="account__card" style={{ minWidth: '330px' }}>
              <div className="account__head-login">
                <img src="/images/general/logo_dark_small.png" alt="IL Admin Portal" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <h2>Admin Portal</h2>
              </div>
              <h4>Create a New Password</h4>
              <br />
              <FormGroup>
                <Label for="passwordInput">New Password</Label>
                <InputGroup size="lg">
                  <Input
                    name="newPassword"
                    id="passwordInput"
                    type={showNewPassword ? 'text' : 'password'}
                    bsSize="lg"
                    invalid={Object.prototype.hasOwnProperty.call(errors, 'newPassword')}
                    onChange={this.onDataChanged}
                    value={newPassword}
                  />
                  <Popover
                    id="popover-new-password"
                    placement="left"
                    isOpen={this.state.isPopoverOpen}
                    target="passwordInput"
                    trigger="focus"
                    toggle={this.togglePopover}
                  >
                    <PopoverHeader>Password requirements:</PopoverHeader>
                    <PopoverBody>
                      <p>
                        at least 1 Upper Case letter<br />
                        at least 1 Lower Case letter<br />
                        at least 1 number<br />
                        at least 1 special character ($.,)(!%^*#)<br />
                        at least 8 characters in length<br />
                        New Password and Confirm Password should match
                      </p>
                    </PopoverBody>
                  </Popover>
                  <InputGroupAddon addonType="append">
                    <button
                      className={`form__form-group-button${showNewPassword ? ' active' : ''}`}
                      onClick={e => this.showNewPassword(e)}
                    ><EyeIcon />
                    </button>
                  </InputGroupAddon>
                  <FormFeedback>{errors.newPassword}</FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label for="confirmPassword">Confirm Password</Label>
                <InputGroup size="lg">
                  <Input
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    bsSize="lg"
                    invalid={Object.prototype.hasOwnProperty.call(errors, 'confirmPassword')}
                    onChange={this.onDataChanged}
                    value={confirmPassword}
                  />
                  <InputGroupAddon addonType="append">
                    <button
                      className={`form__form-group-button${showConfirmPassword ? ' active' : ''}`}
                      onClick={e => this.showConfirmPassword(e)}
                    ><EyeIcon />
                    </button>
                  </InputGroupAddon>
                  <FormFeedback>{errors.confirmPassword}</FormFeedback>
                </InputGroup>
              </FormGroup>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={config().recaptcha.siteKey}
                onChange={this.onRecaptchaChange}
              />
              {
                Object.prototype.hasOwnProperty.call(errors, 'global') &&
                <Alert color="danger">
                  <p>{errors.global}</p>
                </Alert>
              }
              <Button
                color="primary"
                size="lg"
                block
                onClick={this.onSubmit}
                style={{ marginTop: '15px' }}
              >
                {isLoadingData && <span><div className="spinner-border text-light spinner-border-sm" /></span>}
                <span>Create Password and Login</span>
              </Button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

ResetPassword.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'reset_password',
})(ResetPassword);
