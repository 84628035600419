import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  Input,
  // Alert,
} from 'reactstrap';
import Select from 'react-select';
import WVFormatter from '../../../../../../../../shared/helpers/WVFormatter';
import Alert from '../../../../../../../../shared/easydev2/shared/components/Alert';

const AssignAnalystList = ({
  multipleAnalystsData,
  setMultipleAnalystsData,
  analysts,
  total,
  setDisableSubmit,
}) => {
  const [newAnalystCount, setNewAnalystCount] = useState('');
  const selectedAnalystIds = multipleAnalystsData.map(row => row.analystId);

  const analystSelect = i => (
    <FormGroup>
      <Select
        value={i ? analysts.filter(row => row.value === multipleAnalystsData[i].analystId) : analysts.filter(row => row.value === null)}
        options={analysts.filter(row => selectedAnalystIds.indexOf(row.value) === -1 || (i && multipleAnalystsData[i].analystId === row.value))}
        onChange={(selected) => {
          const newData = [...multipleAnalystsData];
          if (i) {
            newData[i].analystId = selected.value;
            newData[i].name = selected.label;
          } else {
            newData.push({
              analystId: selected.value,
              name: selected.label,
              prospectsCount: newAnalystCount,
            });
            setNewAnalystCount('');
          }
          setMultipleAnalystsData(newData);
        }}
        placeholder=""
        menuPlacement="auto"
      />
    </FormGroup>
  );

  const prospectsInput = i => (
    <FormGroup>
      <Input
        type="number"
        min="0"
        value={i ? multipleAnalystsData[i].prospectsCount : newAnalystCount}
        onChange={(e) => {
          if (i) {
            const newData = [...multipleAnalystsData];
            newData[i].prospectsCount = e.target.value;
            setMultipleAnalystsData(newData);
          } else {
            setNewAnalystCount(e.target.value);
          }
        }}
      />
    </FormGroup>
  );

  const deleteRow = (i) => {
    setMultipleAnalystsData(multipleAnalystsData.filter((row, j) => Number(j) !== Number(i)));
  };

  const totalToAssign = multipleAnalystsData?.length > 0 ? multipleAnalystsData.map(row => Number(row.prospectsCount)).reduce((a, b) => a + b) : 0;
  const remainingUnassigned = total - totalToAssign;
  const style = {
    borderTop: '1px solid rgb(204, 204, 204)',
    borderBottom: '1px solid rgb(204, 204, 204)',
    paddingTop: '16px',
    marginTop: '16px',
    marginBottom: '16px',
  };
  const alertStyle = { marginTop: '12px' };
  const alertTextStyle = { color: '#212529' };

  useEffect(() => {
    setDisableSubmit(totalToAssign <= 0 || remainingUnassigned < 0);
  }, [remainingUnassigned]);

  return (
    <>
      <Row>
        <Col md="1" />
        <Col md="8"><b>Analyst</b></Col>
        <Col md="3"><b># Prospects</b></Col>
      </Row>
      <div style={style}>
        {Object.keys(multipleAnalystsData).map(i => (
          <Row key={i}>
            <Col md="1">
              <button
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  margin: 0,
                  padding: '12px',
                }}
                onClick={() => { deleteRow(i); }}
              >
                <i className="fa fa-times" />&nbsp;
              </button>
            </Col>
            <Col md="8">{analystSelect(i)}</Col>
            <Col md="3">{prospectsInput(i)}</Col>
          </Row>
        ))}
        <Row>
          <Col md="1" />
          <Col md="8">{analystSelect()}</Col>
          <Col md="3">{prospectsInput()}</Col>
        </Row>
      </div>
      <Row>
        <Col md="9" className="text-right"><b>Total To Assign</b></Col>
        <Col md="3">{WVFormatter.formatIntNumber(totalToAssign)}</Col>
      </Row>
      {remainingUnassigned > 0 && (
        <>
          <Row>
            <Col md="9" className="text-right"><b>Remaining Unassigned</b></Col>
            <Col md="3">{WVFormatter.formatIntNumber(remainingUnassigned)}</Col>
          </Row>
          <Alert color="warning" style={alertStyle}>
            <p className="px-3 py-2" style={alertTextStyle}>
              {remainingUnassigned} prospects not assigned. The last {remainingUnassigned} prospects will not be assigned
            </p>
          </Alert>
        </>
      )}
      {remainingUnassigned < 0 && (
        <Alert color="warning" style={alertStyle}>
          <p className="px-3 py-2" style={alertTextStyle}>
            Cannot assign more prospects than results
          </p>
        </Alert>
      )}
    </>
  );
};

AssignAnalystList.propTypes = {
  multipleAnalystsData: PropTypes.arrayOf(PropTypes.any).isRequired,
  setMultipleAnalystsData: PropTypes.func.isRequired,
  analysts: PropTypes.arrayOf(PropTypes.any).isRequired,
  total: PropTypes.number.isRequired,
  setDisableSubmit: PropTypes.func.isRequired,
};

export default AssignAnalystList;
