/* eslint-disable filenames/match-exported */
import { axios } from 'ApiClient';

async function CheckYTChannelUrl(data) {
  let results = [];
  const promises = [];
  async function fetchData(tubularCreator) {
    results = Object.assign([], results);
    results.push({ ...tubularCreator, loading: false });
    const url = tubularCreator.accounts.filter(account => account.platform === 'youtube');
    if (url && url[0]?.account_url && tubularCreator.existStatus !== 'info') {
      await axios({
        method: 'get',
        url: `/api/portal/prospects/get-youtube-channel-data-by-id?url=${url[0].account_url}`,
      }).then((response) => {
        if (response?.data?.exist) {
          results = Object.assign([], results);
          const index = results.findIndex(item => item.creator_id === tubularCreator.creator_id);
          results[index] = {
            ...tubularCreator,
            existStatus: 'warning',
            email: response.data.data.email,
            hsUserId: response.data.data.hsUserId,
            loading: false,
          };
        }
      });
    }
  }

  data.forEach((item) => {
    promises.push(fetchData(item));
  });
  await Promise.all(promises);
  return results;
}

export default CheckYTChannelUrl;
