/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../../../shared/components/ButtonWithSpinner';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../../../App/store/with-notice';


export const DeleteCpmCreatorModalId = 'deleteCPMProspectFromOrder';

const DeleteCpmCreatorModal = ({
  updateTable,
  addNotice,
}) => {
  const dispatch = useDispatch();
  const [loadingDeleteCpmCreator, setLoadingDeleteCpmCreator] = useState(false);

  const modalInfo = useSelector(state => state.table.modals[DeleteCpmCreatorModalId]);
  if (!modalInfo) return false;
  console.log(modalInfo);
  const { data: { record }, isShow } = modalInfo;
  const close = () => dispatch(hideModalById(DeleteCpmCreatorModalId));
  const deleteCpmCreator = async () => {
    setLoadingDeleteCpmCreator(true);
    try {
      const res = await axios({
        method: 'post',
        url: '/api/portal/prospects/post-delete-cpm-prospect-from-insertion-order',
        data: { id: record?.id, insertionOrder: record.params?.cpmInsertionOrder },
      });
      setLoadingDeleteCpmCreator(false);
      if (res?.data?.success) {
        addNotice({
          message: 'CPM Creator was successfully deleted.',
          type: 'success',
        });
        updateTable();
        close();
      } else if (res?.data?.error) {
        addNotice({
          message: `CPM Creator was not deleted. Error: ${res.data.error}`,
          type: 'error',
        });
      }
      updateTable();
    } catch (error) {
      console.error(error);
      addNotice({
        message: `CPM Creator was not deleted. Error: ${error.message}`,
        type: 'error',
      });
      setLoadingDeleteCpmCreator(false);
    }
  };
  if (!modalInfo) return false;
  const name = `${record?.params?.contactObject?.firstname} ${record?.params?.contactObject?.lastname}`;
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Delete CPM Creator from Insertion Order
      </ModalHeader>
      <ModalBody>
        Are you sure you want to delete <strong>{ name.trim() || 'record'}</strong> from the <strong>{record?.populated?.cpmInsertionOrder?.params?.name}</strong>?
      </ModalBody>
      <ModalFooter>
        <ButtonWithSpinner
          className="btn-sm"
          type="button"
          color="danger"
          onClick={deleteCpmCreator}
          loading={loadingDeleteCpmCreator}
          disabled={loadingDeleteCpmCreator}
        >
          <span>Delete</span>
        </ButtonWithSpinner>{' '}
        <Button
          className="btn-sm"
          color="secondary"
          onClick={close}
          disabled={loadingDeleteCpmCreator}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DeleteCpmCreatorModal.propTypes = {
  updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

DeleteCpmCreatorModal.defaultProps = {

};

export default withNotice(DeleteCpmCreatorModal);
