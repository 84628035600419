/* eslint react/prop-types: 0 */
import React from 'react';
import ImpactClickShowAdditionalInfoButton
  from '../../../../../shared/components/ImpactClickShowAdditionalInfoButton';

export default (props) => {
  const { record } = props;
  const index = window.location.href.indexOf('resources') >= 0 ? window.location.href.indexOf('resources') : window.location.href.indexOf('reports');
  const url = `${window.location.href.substring(0, index)}influencer/profile/${record.params['influencer._id']}`;
  let influencer = '-- Not Assigned --';
  if (record.params.creatorName && record.params.creatorName.trim()) influencer = record.params.creatorName;
  return ((typeof record.params['influencer._id'] !== 'undefined')
    ? (
      <>
        <a href={url} target="_blank" rel="noreferrer">
          {influencer}
        </a>
        {' '}
        <ImpactClickShowAdditionalInfoButton
          record={record}
        />
      </>
    )
    : (
      <span className="text-secondary">
        {influencer}
        {' '}
        <ImpactClickShowAdditionalInfoButton
          record={record}
        />
      </span>
    )
  );
};
