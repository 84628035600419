import React, { useEffect, useState, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonToolbar,
  Modal,
  Button,
  ModalHeader,
  Col,
  Row,
  Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import { axios } from 'ApiClient';
import { NumericFormat as NumberFormat } from 'react-number-format';
import IconButton from '@material-ui/core/IconButton';
import { hideModalById, setState } from '../../../../redux/reducers/TableReducer';
import withNotice from '../../../App/store/with-notice';


export const modalId = 'RebuySettingsModal';

const ItemElem = ({
  item, advertisers, setValue, index, removeValue,
}) => {
  const {
    advertiser, cac, percent, type,
  } = item;
  const types = [
    { value: 'Hybrid', label: 'Hybrid' },
    { value: 'Upfront Only', label: 'Upfront Only' },
    { value: 'Upfront CPM', label: 'Upfront CPM' },
  ];
  const advertiserOptions = useMemo(() => advertisers.filter(b => b.displayInUi === 'Yes').map(a => ({ label: a.companyName, value: a._id })), [advertisers]);

  const selectedType = useMemo(() => types.find(t => t.value === type), [type]);
  const selectedAdvertiser = useMemo(() => advertiserOptions.find(t => t.value === advertiser), [advertiser]);

  const onChange = (key, value) => {
    setValue({ ...item, [key]: value });
  };

  return (
    <Row className="mt-3">
      <Col>
        <Label className="my-0"><small>Brand</small></Label>
        <Select
          value={selectedAdvertiser}
          options={advertiserOptions}
          onChange={value => onChange('advertiser', value.value)}
          placeholder="Select Brand..."
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              height: '38px',
              fontSize: '16px',
            }),
            menu: baseStyles => ({
              ...baseStyles,
              fontSize: '14px',
            }),
          }}
        />
      </Col>
      <Col>
        <Label className="my-0"><small>Deal Type</small></Label>
        <Select
          value={selectedType}
          options={types}
          onChange={value => onChange('type', value.value)}
          placeholder="Deal Type..."
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              height: '38px',
              fontSize: '16px',
            }),
            menu: baseStyles => ({
              ...baseStyles,
              fontSize: '14px',
            }),
          }}
        />
      </Col>
      <Col>
        <Label className="my-0"><small>Target CAC</small></Label>
        <NumberFormat
          thousandSeparator
          prefix="$"
          name="cac"
          value={cac}
          className="form-control"
          inputMode="numeric"
          // allowEmptyFormatting
          fixedDecimalScale
          decimalScale={2}
          onValueChange={(values) => {
            onChange('cac', values.floatValue);
          }}
        />
      </Col>
      <Col>
        <Label className="my-0"><small>Threshold %</small></Label>
        <NumberFormat
          thousandSeparator
          suffix="%"
          name="cac"
          value={percent}
          className="form-control"
          inputMode="numeric"
          // allowEmptyFormatting
          fixedDecimalScale
          decimalScale={2}
          onValueChange={(values) => {
            onChange('percent', values.floatValue);
          }}
        />
      </Col>
      <Col sm="auto" className="pt-3">
        <IconButton
          size="small"
          onClick={() => removeValue(index)}
          className="material-table__toolbar-button mt-3"
        >
          <i className="fa fa-times text-danger" />
        </IconButton>
      </Col>
    </Row>
  );
};

ItemElem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  advertisers: PropTypes.arrayOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired,
  removeValue: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  // adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  // currentAdminUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

const InfoModal = () => {
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const [settings, setSettings] = useState({ brands: {}, types: {} });
  const [loading, setLoading] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const dispatch = useDispatch();
  const fetchSettingsFromDictionary = async () => {
    try {
      const resp = await axios.get('/api/portal/reports/get-rebuy-settings');
      setSettings(resp.data.settings || { brands: {} });
      setState();
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (modalInfo) {
      fetchSettingsFromDictionary();
    }
  }, [modalInfo]);
  useEffect(() => {
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      // setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : -1)));
    }).catch(() => {
    });
  }, []);

  const close = () => {
    dispatch(hideModalById(modalId));
  };
  const saveSettings = async () => {
    try {
      setLoading(true);
      await axios.post('/api/portal/reports/post-save-rebuy-settings', { settings });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  const list = useMemo(() => {
    if (!settings?.brands) return [];
    return Object.keys(settings?.brands).map(key => ({ ...settings?.brands[key], key }));
  }, [settings]);
  if (!modalInfo) return false;
  const { isShow: showUploadModal } = modalInfo;
  const setValue = (index, {
    advertiser, cac, percent, type,
  }) => {
    const settingsClone = { ...(settings?.brands || {}) };
    settingsClone[index] = {
      advertiser, cac, percent, type, key: index,
    };
    setSettings(s => ({ ...s, brands: settingsClone }));
  };

  const onChangeValue = (type, value) => {
    setSettings(s => ({ ...s, types: { ...s.types, [type]: value } }));
  };
  const deleteValue = (index) => {
    console.log(index, settings?.brands);
    const settingsClone = { ...(settings?.brands || {}) };
    delete settingsClone[index];
    setSettings(s => ({ ...s, brands: settingsClone }));
  };
  // if (['Bonus'].includes(selectedDeal.dealTerms.type_of_deal)) brandApprovedDate = selectedDeal.dealTerms.content_release_date;
  return (
    <Modal
      isOpen={showUploadModal}
      toggle={close}
      size="lg"
    >
      <ModalHeader toggle={() => close()} tag="h3">
        Deal Rebuy Settings
      </ModalHeader>
      <div className="modal__body ltr-support px-3 pt-3">
        <h4 className="font-weight-bold mt-3">Default Target Threshold Percentages</h4>
        <hr />
        <Row className="align-items-center mb-3">
          <Col>
            <Row className="align-items-center">
              <Col sm="auto" className="">
                <Label className="mb-0">Upfront CPM</Label>
              </Col>
              <Col>
                <NumberFormat
                  thousandSeparator
                  suffix="%"
                  name="upfrontCPM"
                  value={settings?.types['Upfront CPM'] || 0}
                  className="form-control"
                  inputMode="numeric"
                  // allowEmptyFormatting
                  fixedDecimalScale
                  decimalScale={2}
                  onValueChange={(values) => {
                    onChangeValue('Upfront CPM', values.floatValue);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="align-items-center">
              <Col sm="auto" className="">
                <Label className="mb-0">Upfront Only</Label>
              </Col>
              <Col>
                <NumberFormat
                  thousandSeparator
                  suffix="%"
                  name="upfront"
                  value={settings.types['Upfront Only'] || 0}
                  className="form-control"
                  inputMode="numeric"
                  // allowEmptyFormatting
                  fixedDecimalScale
                  decimalScale={2}
                  onValueChange={(values) => {
                    onChangeValue('Upfront Only', values.floatValue);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="align-items-center">
              <Col sm="auto" className="">
                <Label className="mb-0">Hybrid</Label>
              </Col>
              <Col>
                <NumberFormat
                  thousandSeparator
                  suffix="%"
                  name="hybrid"
                  value={settings.types.Hybrid || 0}
                  className="form-control"
                  inputMode="numeric"
                  // allowEmptyFormatting
                  fixedDecimalScale
                  decimalScale={2}
                  onValueChange={(values) => {
                    onChangeValue('Hybrid', values.floatValue);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <h4 className="font-weight-bold mt-3">Target CAC & Threshold Percentages</h4>
        <hr />
        <IconButton
          size="small"
          onClick={async () => {
            setValue(moment().format('x'), {
              advertiser: advertisers[0]._id, type: 'Hybrid', cac: 450, percent: 25,
            });
          }}
          className="material-table__toolbar-button"
        >
          <i className="fa fa-plus text-primary" />
        </IconButton>
        {list.map(item => (<ItemElem item={item} advertisers={advertisers} removeValue={deleteValue} setValue={e => setValue(item.key, e)} index={item.key} />))}
      </div>
      <ButtonToolbar className="modal__footer">
        <Button color="primary" onClick={saveSettings} disabled={loading} className="mr-2">Save</Button>
        <Button color="secondary" onClick={close} disabled={loading}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

export default withNotice(InfoModal);
