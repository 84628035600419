/* eslint react/prop-types: 0 */
/* eslint no-restricted-syntax: 0 */
/* eslint react/jsx-no-bind: 0 */
/* eslint no-unused-vars: 0 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
// import { PropertyPlace } from 'admin-bro/lib/backend/decorators/property-json.interface';

import StyledButton from '../ui/styled-button';
import PropertyType from '../property-type';
import Label from '../ui/label';
import StyledInput from '../ui/styled-input';
import theme from '../../../../theme';

const FilterWrapper = styled.section`
  background: #fff;
  flex-shrink: 0;
  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  z-index: 100;
  width: ${theme.sizes.sidebarWidth};
  border-left: 1px solid ${theme.colors.borderOnDark};
  padding-top: 60px;
  transition: width 0.5s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  &.filter-hidden {
    width: 0;
    transition: width 0.5s;
  }
`;

const FilterLink = styled.a`
  color: ${theme.colors.filterDefaultText};
  & > span {
    opacity: 0.5;
    color: ${theme.colors.lightText};
    border: 1px solid ${theme.colors.lightText};
    border-radius: 3px;
    padding: 8px 10px;
    margin-right: ${theme.sizes.padding};
  }
  &:hover {
    color: ${theme.colors.primary};
    & span{
      color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
      opacity: 1;
    }
  }
`;

const FilterContent = styled.section`
  padding: ${theme.sizes.paddingLayout};
  width: ${theme.sizes.sidebarWidth};
  min-width: ${theme.sizes.sidebarWidth};
  overflow: hidden;
  min-height: 100%;

  & a, & button {
    margin: ${theme.sizes.paddingMin} 0;
    width: 100%;
  }
`;

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.state = {
      filter: this.parseQuery(),
    };
    // console.log('started FILTER', props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match } = this.props;
    if (nextProps.match.params.resourceId !== match.params.resourceId) {
      this.setState({ filter: {} });
    }
  }

  parseQuery() {
    const { location } = this.props;
    const filter = {};
    const query = new URLSearchParams(location.search);
    for (const entry of query.entries()) {
      const [key, value] = entry;
      if (key.match('filters.')) {
        filter[key.replace('filters.', '')] = value;
      }
    }
    return filter;
  }

  handleSubmit(event) {
    event.preventDefault();
    const { filter } = this.state;
    const { history } = this.props;
    const search = new URLSearchParams(window.location.search);
    Object.keys(filter).forEach((key) => {
      if (filter[key] !== '') {
        search.set(`filters.${key}`, filter[key]);
      } else {
        search.delete(`filters.${key}`);
      }
    });
    search.set('page', '1');
    history.push(`${history.location.pathname}?${search.toString()}`);
    return false;
  }

  resetFilter(event) {
    const { history } = this.props;
    event.preventDefault();
    const filteredSearch = new URLSearchParams();
    const search = new URLSearchParams(window.location.search);
    for (const key of search.keys()) {
      if (!key.match('filters.')) {
        filteredSearch.set(key, search.get(key));
      }
    }
    const query = filteredSearch.toString() === '' ? `?${filteredSearch.toString()}` : '';
    history.push(history.location.pathname + query);
    this.setState({ filter: {} });
  }

  handleChange(propertyName, value) {
    if ((propertyName).params) {
      throw new Error('you can not pass RecordJSON to filters');
    }
    this.setState(state => ({
      filter: {
        ...state.filter,
        [propertyName]: value,
      },
    }));
  }
  /*
   *
   */
  render() {
    // console.log('RENDER FILTER', this.props);
    const { resource, isVisible, toggleFilter } = this.props;
    const { filter } = this.state;
    const properties = resource.filterProperties;
    return (
      <FilterWrapper className={isVisible ? undefined : 'filter-hidden'}>
        <FilterContent>
          <FilterLink onClick={toggleFilter}>
            <span><i className="fas fa-arrow-right" /></span>
            Filter
          </FilterLink>
          <form onSubmit={this.handleSubmit.bind(this)} className="mt-2">
            {properties.map(property => (
              <PropertyType
                key={property.name}
                where="filter"
                onChange={this.handleChange}
                property={property}
                filter={filter}
                resource={resource}
              />
            ))}
            <StyledButton className="is-primary">
              Apply Changes
            </StyledButton>
            <StyledButton
              as="a"
              className="is-text"
              onClick={this.resetFilter}
            >
              Clear filters
            </StyledButton>
          </form>
        </FilterContent>
      </FilterWrapper>
    );
  }
}

export default withRouter(Filter);
