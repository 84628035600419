import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip as TooltipOnLine,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import { formatIntNumber, formatCurrency } from '../../../../shared/helpers/WVFormatter';

const chartMargins = {
  top: 10,
  right: 0,
  left: 45,
  bottom: 20,
};

const defaultColors = {
  new: '#2B78E4',
  existing: '#009E0F',
};

const ChartComponent = ({
  chartData,
  chartType,
}) => {
  const [opacity, setOpacity] = useState({});
  const handleLegendItemMouseEnter = (dataKey) => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(key => localOpacity[key] = ((key === dataKey) ? 1 : 0.2));
    setOpacity(localOpacity);
  };

  const handleLegendItemMouseLeave = () => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(key => localOpacity[key] = 1);
    setOpacity(localOpacity);
  };

  const CustomizedTooltip = (props) => {
    const getText = (name, value) => `${name}: ${chartType === 'Spend' ? formatCurrency(value) : formatIntNumber(value)}`;
    let label = props.label;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateRegex.test(label)) {
      label = moment(label).format('MMM D, YYYY');
    }
    if (props.active && props.payload && props.payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#ffffff',
            border: '1px solid #f5f5f5',
            lineHeight: '24px',
            margin: '0',
            padding: '10px',
          }}
        >
          <p className="bold-text">
            {label}: {`${
              chartType === 'Spend'
                ? formatCurrency(props.payload[0].payload[`new${chartType}`] + props.payload[0].payload[`existing${chartType}`])
                : formatIntNumber(props.payload[0].payload[`new${chartType}`] + props.payload[0].payload[`existing${chartType}`])
            }`}
          </p>
          <ul
            style={{
              listStyle: 'none',
              padding: '0',
              margin: '0',
            }}
          >
            <li
              key={`new${chartType}`}
              style={{
                color: defaultColors.new,
              }}
            >
              {getText('New', props.payload[1].value)}
            </li>
            <li
              key={`existing${chartType}`}
              style={{
                color: defaultColors.existing,
              }}
            >
              {getText('Existing', props.payload[0].value)}
            </li>
          </ul>
        </div>
      );
    }
    return null;
  };

  const CustomizedXAxisTick = ({ x, y, payload }) => {
    const values = [16, 26, 40];
    let value = payload.value;
    const shouldBeSplit = value?.includes(' - ');
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateRegex.test(value)) {
      value = moment(value).format('MMM D, YYYY');
    }
    let children = (
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {value}
      </text>
    );
    if (shouldBeSplit) {
      const array = value.split(' - ');
      array.splice(1, 0, '-');
      children = array.map(((string, index) => (
        <text x={0} y={0} dy={values[index]} textAnchor="middle" fill="#666">
          {string}
        </text>
      )));
    }
    return (
      <g transform={`translate(${x},${y})`}>
        {children}
      </g>
    );
  };

  const CustomizedYAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {`${chartType === 'Spend' ? '$' : ''}${formatIntNumber(payload.value, chartType === 'Spend' ? 2 : 0)}`}
      </text>
    </g>
  );

  return (
    <>
      {chartData.length ? (
        <Row>
          <Col md={10} style={{ paddingRight: '0' }}>
            <ResponsiveContainer
              width="100%"
              height={205}
              debounce={1}
            >
              <LineChart
                width="100%"
                height="100%"
                data={chartData}
                margin={chartMargins}
              >
                <Line
                  key={`lineExisting${chartType}`}
                  dataKey={`existing${chartType}`}
                  type="monotone"
                  name={`existing${chartType}`}
                  stroke={defaultColors.existing}
                  strokeOpacity={opacity.existing || 1}
                  strokeWidth={2}
                  dot={false}
                />
                <Line
                  key={`lineNew${chartType}`}
                  dataKey={`new${chartType}`}
                  type="monotone"
                  name={`new${chartType}`}
                  stroke={defaultColors.new}
                  strokeOpacity={opacity.new || 1}
                  strokeWidth={2}
                  dot={false}
                />
                <XAxis
                  dataKey="label"
                  padding={{ left: 8, right: 8 }}
                  tick={<CustomizedXAxisTick />}
                  interval="preserveStartEnd"
                />
                <YAxis
                  padding={{ top: 8, bottom: 8 }}
                  tick={<CustomizedYAxisTick />}
                  domain={[0, 'auto']}
                  interval="preserveStartEnd"
                  allowDecimals={false}
                />
                <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                <TooltipOnLine content={<CustomizedTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </Col>
          <Col
            md={2}
            className="d-flex align-items-center justify-content-center"
          >
            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '0',
              }}
            >
              <li
                key={`legendNew${chartType}`}
                style={{
                  color: defaultColors.new,
                  cursor: 'pointer',
                  fontWeight: '600',
                }}
                onMouseEnter={() => handleLegendItemMouseEnter('new')}
                onMouseLeave={handleLegendItemMouseLeave}
              >
                —  New
              </li>
              <li
                key={`legendExisting${chartType}`}
                style={{
                  color: defaultColors.existing,
                  cursor: 'pointer',
                  fontWeight: '600',
                }}
                onMouseEnter={() => handleLegendItemMouseEnter('existing')}
                onMouseLeave={handleLegendItemMouseLeave}
              >
                —  Existing
              </li>
            </ul>
          </Col>
        </Row>
      ) : (
        <span className="mt-2">No data to chart</span>
      )}
    </>
  );
};

ChartComponent.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.any),
  chartType: PropTypes.string.isRequired,
};

ChartComponent.defaultProps = {
  chartData: [],
};

export default ChartComponent;
