import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';


class PercentsField extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
  };

  static defaultProps = {
    defaultValue: '',
    disabled: false,
    name: null,
  };
  render() {
    const {
      disabled,
      defaultValue,
      onBlur,
      name,
    } = this.props;

    return (
      <InputGroup className="min-width-150">
        <input type="text" className="form-control" disabled={disabled} defaultValue={defaultValue} name={name} onBlur={onBlur} />
        <InputGroupAddon addonType="append">
          <InputGroupText>%</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

export default PercentsField;
