import React, { useState } from 'react';
import { Col, Row, CardBody, Card, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import withNotice from '../../../../../App/store/with-notice';
import SelectFilter from '../../../../../../shared/components/SelectFilter';
import Cols from '../../../../Influencer/Influencer/influencerProfile/ServiceAgreement/Cols';
import useServiceAgreements from './useServiceAgreements';
import Hook from '../../../../../../shared/hooks';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';
import { remind } from '../../../../../../shared/helpers/models/ServiceAgreement';
import ActionModal from '../../../../../../shared/components/modals/Action';
import Resend from '../../../../Influencer/ServiceAgreement/components/Resend';

const AgentServiceAgreements = ({
  influencers,
  dispatch,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const [openAssignmentsModal] = Hook.useShowModalHook('SuccessModal');
  const [agreement, setAgreement] = useState(null);
  const [resendModalIsOpen, openResendModal] = useState(false);
  const [resending, setResending] = useState(false);
  const { agreements } = useServiceAgreements(influencers.map(i => i._id), setLoading);
  const [statusFilter, setStatusFilter] = useState({
    Pending: true,
    Complete: true,
    Deleted: true,
  });

  const [dealTypeFilter, setDealTypeFilter] = useState({
    Hybrid: true,
    'CPA/Ambassador': true,
    'Upfront Only': true,
    'Upfront CPM': true,
    'Media License': true,
    Bonus: true,
  });

  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Service Agreements</h2>
      <Card>
        <CardBody>
          {loading ? (<div className="text-center"><Spinner color="primary" size="lg" /></div>) : null}
          {!loading && agreements.length > 0 && (
            <>
              <Row style={{ marginBottom: '-16px' }}>
                <Col xs="auto">
                  <SelectFilter
                    key="122"
                    title="Status"
                    filterProp={statusFilter}
                    filterAction={v => setStatusFilter(v)}
                  />
                </Col>
                <Col xs="auto">
                  <SelectFilter
                    key="123"
                    title="Deal Type"
                    filterProp={dealTypeFilter}
                    filterAction={v => setDealTypeFilter(v)}
                  />
                </Col>
              </Row>
              <MatTableForRedux
                dataArray={agreements}
                selectable={false}
                columns={Cols({
                  dispatch,
                  setAgreement,
                  openAssignmentsModal,
                  openResendModal,
                })}
                version={(new Date()).toString()}
                defaultOrder={{
                    order: 'asc',
                    orderBy: 'brand',
                }}
                rowsPerPage={10}
                hideToolbar
                filters={{
                    status: statusFilter,
                    deal_type: dealTypeFilter,
                }}
              />
              <ActionModal
                isOpen={resendModalIsOpen}
                toggle={openResendModal}
                label="Resend Notification (Dropbox)"
                process={() => remind({ agreement, setLoading: setResending }, addNotice)}
                confirmButtonCaption="Send"
                loading={resending}
              >
                <Resend agreement={agreement} />
              </ActionModal>
            </>
          )}
          {!loading && agreements.length === 0 && (
            <Row><Col><Alert color="warning"><p className="py-2">The Creator currently has no signed Service Agreements.</p></Alert></Col></Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
AgentServiceAgreements.propTypes = {
  influencers: PropTypes.arrayOf(PropTypes.any),
  dispatch: PropTypes.func,
  addNotice: PropTypes.func.isRequired,
};
AgentServiceAgreements.defaultProps = {
  influencers: [],
  dispatch: () => {},
};

export default withNotice(AgentServiceAgreements);
