import React, { useEffect, useMemo, useState } from 'react';
import { Col, Card, Row, CardBody, Badge, Spinner,Label, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
// import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import ReactTableBase from '../../../../../../shared/tables/table/ReactTableBase';
// import MatTableForRedux from '../../../../../../shared/tables/materialTable/components/MatTableForRedux';
import WVFormatter from '../../../../../../shared/helpers/WVFormatter';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import withNotice from '../../../../../App/store/with-notice';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';
import CheckBoxField from '@/shared/components/CheckBox';
import { axios } from 'ApiClient';

// const allBrands = getConstant('deal', 'allAdvertisers', {});
// const getBrand = (id) => {
//   const fBrands = {};
//   if (allBrands.length) {
//     allBrands.forEach((brand) => {
//       fBrands[brand._id] = brand.companyName;
//     });
//   }
//   return fBrands[id];
// };

const columnTransform = [
  {
    id: 'date',
    Header: 'Date',
    Cell: value => value.value,
    accessor: record => {
      if (record.paymentDate) {
        return WVFormatter.formatDateForCreatorsPaymentsTables(record.paymentDate);
      }
      if (!record.paymentDate && record.payableJournals && record.payableJournals.length > 0) {
        const payableJournal = record.payableJournals[record.payableJournals.length - 1];
        return WVFormatter.formatDateForCreatorsPaymentsTables(payableJournal.createdAt);
      }
      return '-';
    }
  },
  {
    id: 'Agent',
    Header: 'Agent',
    accessor: record => record.agent || '',
    Cell: p => (p.row.original.agent
      ? (
        <a href={`/agent/profile/${p.row.original.agentId}`} target="_blank" rel="noreferrer">
          {p.row.original.agent}
        </a>
      )
      : ''),
    width: 50,
  },
  {
    id: 'Creator',
    Header: 'Creator',
    accessor: record => record.creator || '',
    Cell: p => (p.row.original.creator
      ? (
        <a href={`/influencer/profile/${p.row.original.creatorId}`} target="_blank" rel="noreferrer">
          {p.row.original.creator}
        </a>
      )
      : ''),
    width: 50,
  },
  {
    id: 'Brand',
    Header: 'Brand',
    Cell: value => value.value || '',
    accessor: record => record.brandName || '',
    width: 250,
  },
  {
    id: 'amount',
    Header: 'Amount',
    Cell: value => (WVFormatter.formatCurrency(value.value || 0)),
    accessor: record => Number(record.amount || 0),
    width: 90,
  },
  {
    id: 'Provider',
    Header: 'Provider',
    Cell: p => {
      const record = p.row.original;
      const methodRight = record.method && ['wire', 'check', 'venmo', 'billCom', 'stripe', 'other', 'payPal'].includes(record.method) ? record.method : 'other';
      const methods = getConstant('creator', 'paymentMethod', {});
      // console.log(methods);
      return (
        <Badge
          color="success"
          title={`Payment method: ${methods[methodRight]}`}
        >
          <div
            style={{
              height: '25px',
              width: '50px',
              backgroundImage: `url(/images/payments/${methodRight}.png)`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Badge>
      );
    },
    accessor: record => record.method,
    width: 90,
  },
  {
    id: 'description',
    Header: 'Description',
    accessor: record => (record?.description || ''),
    width: 100,
  },

];

const tableConfig = {
  isEditable: false,
  useFlex: true,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  manualPageSize: [5, 10, 30, 40],
  withSearchEngine: false,
  sortBy: 'date',
  direction: 'desc',
};

const AgentPayments = ({ agentId }) => {
  const [loading, setLoading] = useState(false);
  const [payables, setPayables] = useState([]);
  const [billComIssue, setBillComIssue] = useState(null);
  const [stripeIssue, setStripeIssue] = useState(null);
  const [agentFilter, setAgentFilter] = useState({
    currentAgent: true,
    noAgent: false,
    anotherAgents: false,
  });
  const handleAgentFilters = (event, name) => {
    if(!event.target) return false;
    setAgentFilter((e) => ({ ...e, [name]: event.target.checked }));
  }
  useEffect(() => {
    setLoading(true);
    setBillComIssue(null);
    setStripeIssue(null);

    axios.get(`/api/portal/agent/get-agent-payments?id=${agentId}`).then((response) => {
      setLoading(false);
      setBillComIssue(response.data?.billComIssue);
      setStripeIssue(response.data?.stripeIssue);
      setPayables(response.data?.payables || []);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }, [agentId]);

  const data = useMemo(() => {
    if(payables.length === 0) return [];
    return payables.filter(a => {
      console.log(agentFilter, a, agentId)
      if(!agentFilter.anotherAgents && a.agentId && String(a.agentId) !== String(agentId)) return false;
      if(!agentFilter.currentAgent && a.agentId && String(a.agentId) === String(agentId)) return false;
      if(!agentFilter.noAgent && !a.agentId) return false;

      return true;
    })
  }, [agentFilter, payables])

  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Agent Payments</h2>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <div className="transfers-table">
                {billComIssue ? (
                  <Alert color="warning" className="alert--neutral" isOpen={billComIssue !== null}>
                    <p className="py-2"><span className="bold-text">Warning!</span> {billComIssue}</p>
                  </Alert>
                 ) : null}
                {stripeIssue ? (
                  <Alert color="warning" className="alert--neutral" isOpen={stripeIssue !== null}>
                    <p className="py-2"><span className="bold-text">Warning!</span> {stripeIssue}</p>
                  </Alert>
                ) : null}
                {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
                { !loading && payables.length > 0 &&
                  (<>
                    <Col sm="auto">
                      <Label className="bold-text">
                        Agent Filters
                      </Label>
                      <FormGroup style={{ marginBottom: '0' }}>
                        <CheckBoxField
                          name="currentAgent"
                          label="Current Agent"
                          value={agentFilter.currentAgent}
                          onChange={event => handleAgentFilters(event, 'currentAgent')}
                        />
                      </FormGroup>
                      <FormGroup style={{ marginBottom: '0' }} >
                        <CheckBoxField
                          name="missingAgent"
                          label="Missing Agent"
                          value={agentFilter.noAgent}
                          onChange={event => handleAgentFilters(event, 'noAgent')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <CheckBoxField
                          name="anotherAgent"
                          label="Another Agent"
                          value={agentFilter.anotherAgents}
                          onChange={event => handleAgentFilters(event, 'anotherAgents')}
                        />
                      </FormGroup>
                    </Col>
                    <ReactTableBase
                      columns={columnTransform}
                      data={data}
                      tableConfig={tableConfig}
                    />
                  </>)
                  /* <MatTableForRedux
                    dataArray={payables}
                    selectable={false}
                    columns={columnTransform}
                    version={(new Date()).toString()}
                    defaultOrder={{
                      order: 'desc',
                      orderBy: 'date',
                     }}
                    rowsPerPage={10}
                    hideToolbar
                  /> */
                }
                { !loading && payables.length === 0 &&
                  <Alert color="warning"><p className="py-2">Agent has no payments.</p></Alert>
                }
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

AgentPayments.propTypes = {
  agentId: PropTypes.string.isRequired,
};

export default withNotice(AgentPayments);
