import React, { useEffect, useState, useMemo } from 'react';
import { Col, Card, CardBody, Row, Container, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { axios } from 'ApiClient';
// import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';

// import { RTLProps } from '../../../shared/prop-types/ReducerProps';

const StatsReport = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});
  const [brands, setBrands] = useState([]);
  const [startDate, setStartDate] = useState(moment().toDate());
  const [minDate] = useState(moment().subtract(760, 'days').toDate());
  const [maxDate] = useState(moment().toDate());
  const uniqueId = React.useMemo(() => moment().toISOString(), []);
  console.log('1');
  const fetchByDate = async (date) => {
    const resp = await axios.post('/api/portal/conversions/post-conversions-total-stats', {
      date,
    });
    if (resp.data.success) {
      console.log(resp.data.stats);
      setStats(resp.data.stats || {});
      setBrands(resp.data.brands);
    }
  };
  const getConversions = async () => {
    setLoading(true);
    console.log('get converions');
    const resp = await axios.post('/api/portal/conversions/post-fill-conversions-total-stats', {
      date: moment(startDate).format('YYYY-MM-DD'),
    });
    if (resp.data.success) {
      console.log(resp.data.stats);
      setModal(true);
      fetchByDate(moment(startDate).format('YYYY-MM-DD'));
    }
    setLoading(false);
  };

  useEffect(async () => {
    fetchByDate(moment(startDate).format('YYYY-MM-DD'));
  }, [startDate]);
  console.log('Loading', uniqueId);
  const generateItem = (key) => {
    const item = stats[key];
    console.log(item, stats);
    const brand = brands?.find(b => b._id.toString() === key) || {};
    console.log(brands, brand);
    return (
      <div>
        <h4 className="font-weight-bold mt-3">{brand.companyName} - Revenue {'>'} $0</h4>
        <div>Revenue Count: {(Number(item.cpa || 0) + Number(item.cpm || 0))}</div>
        <div>CPA Program: {item.cpa || 0}</div>
        <div>CPM Program: {item.cpm || 0}</div>
        <h4 className="font-weight-bold mt-3">{brand.companyName} - Revenue = $0</h4>
        <div>Revenue Count: {(Number(item.cpa0 || 0) + Number(item.cpm0 || 0))}</div>
        <div>CPA Program: {item.cpa0 || 0}</div>
        <div>CPM Program: {item.cpm0 || 0}</div>
      </div>);
  };

  const someEntry = useMemo(() => {
    const list = Object.keys(stats).map(key => generateItem(key));
    console.log(list);
    return list;
  }, [stats, brands]);

  return (
    <Container className="dashboard">
      <div id="adminDashBoard" style={{ width: '100%' }}>
        <Card>
          <CardBody className="panel__body">
            <div className="panel__content">
              <Row>
                <Col>
                  <h4 className=" font-weight-bold">Store Daily Conversion Totals</h4>
                  <hr className="mb-1" />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Label>Select Date to fetch statistics</Label>
                  <DatePicker
                    selected={startDate}
                    onChange={setStartDate}
                    dateFormat="MM/dd/yyyy"
                    maxDate={maxDate}
                    minDate={minDate}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    className="form-control  ml-2"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {someEntry}
                  {/* Object.keys(stats).length > 0 ? Object.keys(stats).map(key => generateItem(key)) : '' */}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Button onClick={() => getConversions()} color="primary" disabled={!!loading} >Refresh Daily Conversion Totals</Button>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </div>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="sm">
        <ModalHeader toggle={() => setModal(false)}>
          Success
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="3 text-center">
              <i className="fa fa-info-circle" style={{ color: '#70bbfd', fontSize: '5em' }} />
            </Col>
            <Col>
              New Report has been changed successfully.
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="modal__footer">
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setModal(false)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};


export default StatsReport;
