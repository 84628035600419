/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ButtonToolbar,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import ApiClient from 'ApiClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import MatTable from '@/shared/tables/table/MatTableToTable';
import PayableJournalItemInfoButton from '../../../../shared/components/PayableJournalItemInfoButton';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import FilterAccountPayableBlock from './components/FilterAccountPayableBlock';

const columnTransform = {
  transferGroup: {
    title: 'Transfer Group',
    valueFunc: record => (record.params.transferGroup || ''),
    sort: record => (record.params.transferGroup || ''),
  },
  createdAt: {
    title: 'Date',
    valueFunc: (record) => {
      if (!record || !record.params) { return ''; }
      return DateTime.fromISO(record.params.createdAt).toLocaleString(DateTime.DATETIME_SHORT);
    },
    sort: record => (record.params.createdAt || ''),
  },
  influencer: {
    title: 'Creator',
    valueFunc: (record) => {
      if (!record?.populated?.payable?.populated?.influencer) { return ''; }
      return (<span className={record.params.paymentDestination === 'creator' ? 'text-success' : ''}>{record.populated.payable.populated.influencer.email}</span>);
    },
    sort: (record) => {
      if (!record?.populated?.payable?.populated?.influencer) { return ''; }
      return record.populated.payable.populated.influencer.email;
    },
    searchFunc: (record, search) => {
      if (!record || !record.populated || !record.populated.payable || !record.populated.payable.populated || !record.populated.payable.populated.influencer) { return false; }
      return record.populated.payable.populated.influencer.email.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
  },
  agent: {
    title: 'Agent',
    valueFunc: record => (
      <span className={record.params.paymentDestination === 'agent' || record.params.paymentDestination === 'agent_fee' ? 'text-success' : ''}>
        {record.populated?.payable ? (record.populated?.payable?.populated?.agent?.email || '') : (record.populated?.payableAgent?.populated?.agent?.email || '')}
      </span>),
    sort: record => (record.populated?.payable ? (record.populated?.payable?.populated?.agent?.email || '') : (record.populated?.payableAgent?.populated?.agent?.email || '')),
    searchFunc: (record, search) => {
      if (!record?.populated?.payable?.populated?.agent || !record?.populated?.payableAgent?.populated?.agent) { return false; }
      return (record.populated?.payable
        ? (record.populated.payable.populated?.agent?.email.toLowerCase().indexOf(search.toLowerCase()) !== -1)
        : (record.populated.payableAgent.populated?.agent?.email.toLowerCase().indexOf(search.toLowerCase()) !== -1));
    },
  },

  hoPayoutAmount: {
    title: 'HO Payout Amount',
    valueFunc: (record) => {
      if (record?.populated?.payable?.params.payoutAmount
      ) {
        return parseFloat(record.populated.payable.params.payoutAmount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
      if (record?.populated?.payableAgent?.params.payoutAmount
      ) {
        return parseFloat(record.populated.payableAgent.params.payoutAmount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
      return '';
    },
    sort: (record) => {
      if (record?.populated?.payable?.params?.payoutAmount
      ) {
        return Number(record.populated.payable.params.payoutAmount);
      }
      if (record?.populated?.payableAgent?.params?.payoutAmount
      ) {
        return Number(record.populated.payableAgent.params.payoutAmount);
      }
      return '';
    },
  },
  ilPayoutAmount: {
    title: 'IL Payout Amount',
    valueFunc: (record) => {
      if (
        record?.populated?.payable?.params.ilPayoutAmount
      ) {
        return parseFloat(record.populated.payable.params.ilPayoutAmount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
      if (
        record?.populated?.payableAgent?.params.ilPayoutAmount
      ) {
        return parseFloat(record.populated.payableAgent.params.ilPayoutAmount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
      return '';
    },
    sort: (record) => {
      if (record?.populated?.payable?.params?.ilPayoutAmount
      ) {
        return Number(record.populated.payable.params.ilPayoutAmount);
      }
      if (record?.populated?.payableAgent?.params?.ilPayoutAmount
      ) {
        return Number(record.populated.payableAgent.params.ilPayoutAmount);
      }
      return '';
    },
  },
  transferId: {
    title: 'Transfer Id',
    valueFunc: (record) => {
      if (!record || !record.params || !record.params.transferId) { return ''; }

      const { transferId, method } = record.params;

      let icon;
      if (method === 'stripe') {
        icon = ['fab', 'cc-stripe'];
      } else if (method === 'billCom') {
        icon = ['fas', 'dollar-sign'];
      }

      return (
        <span>
          <FontAwesomeIcon icon={icon} size="2x" />
          { ' '}
          { transferId }
        </span>
      );
    },
    sort: record => (record.params.transferId || ''),
  },
  description: {
    title: 'Description',
    valueFunc: record => (record.params.description || ''),
    sort: record => (record.params.description || ''),
  },
  status: {
    title: 'Status',
    valueFunc: record => (record.params.status || ''),
    sort: record => (record.params.status || ''),
  },
  details: {
    title: 'Details',
    valueFunc: record => (record.params.details || ''),
    sort: record => (record.params.details || ''),
  },
  id: {
    alignment: 'right',
    valueFunc: record => (
      <PayableJournalItemInfoButton
        itemId={record.id}
        key={`infoItem${record.id}`}
      />)
    ,
  },
};

const downloadCsv = [
  {
    label: 'Transfer Group',
    func: record => (record.params.transferGroup || ''),
  },
  {
    label: 'Description',
    func: record => (record.params.description || ''),
  },
  {
    label: 'Date',
    func: (record) => {
      if (!record || !record.params) { return ''; }
      return DateTime.fromISO(record.params.createdAt).toLocaleString(DateTime.DATETIME_SHORT);
    },
  },
  {
    label: 'HO Payout Amount',
    func: (record) => {
      if (record
        && record.populated
        && record.populated.payable
        && record.populated.payable.params
        && record.populated.payable.params.payoutAmount
      ) {
        return parseFloat(record.populated.payable.params.payoutAmount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
      return '';
    },
  },
  {
    label: 'IL Payout Amount',
    func: (record) => {
      if (record
        && record.populated
        && record.populated.payable
        && record.populated.payable.params
        && record.populated.payable.params.ilPayoutAmount
      ) {
        return parseFloat(record.populated.payable.params.ilPayoutAmount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
      return '';
    },
  },
  {
    label: 'Method',
    func: record => (record.params.method || ''),
  },
  {
    label: 'Transfer Id',
    func: record => (record.params.transferId || ''),
  },
  {
    label: 'Description',
    func: record => (record.params.description || ''),
  },
  {
    label: 'Status',
    func: record => (record.params.status || ''),
  },
  {
    label: 'Details',
    func: record => (record.params.details || ''),
  },
];

const valuetext = value => `$${value}`;

class PayableJournalList extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      filteredRecords: [],
      loading: false,
      filters: {
        hoPayoutAmount: [0, 1],
        ilPayoutAmount: [0, 1],
        transferId: '',
        description: '',
        details: '',
        status: '',
      },
    };

    this.fetchPayableJournalItems = this.fetchPayableJournalItems.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.onFilterDataChanged = this.onFilterDataChanged.bind(this);
  }

  componentDidMount() {
    this.fetchPayableJournalItems(this.props);
  }

  shouldComponentUpdate(nextProps) {
    const {
      location,
      version,
    } = this.props;
    if (location.search !== nextProps.location.search
      || (version && version !== nextProps.version)) {
      this.fetchPayableJournalItems(nextProps);
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    const { setTag } = this.props;
    if (setTag) {
      setTag('');
    }
  }

  onFilterDataChanged = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({ ...prevState, filters: { ...prevState.filters, [name]: value } }));
  };

  onHOAmountsFilterDataChanged = (event) => {
    const { name, value } = event.target;
    const { hoPayoutAmount } = this.state.filters;

    if (name === 'hoPayoutAmountMin') {
      hoPayoutAmount[0] = value;
    } else if (name === 'hoPayoutAmountMax') {
      hoPayoutAmount[1] = value;
    }

    this.setState(prevState => ({ ...prevState, filters: { ...prevState.filters, hoPayoutAmount } }));
  };

  onILAmountsFilterDataChanged = (event) => {
    const { name, value } = event.target;
    const { ilPayoutAmount } = this.state.filters;

    if (name === 'ilPayoutAmountMin') {
      ilPayoutAmount[0] = value;
    } else if (name === 'ilPayoutAmountMax') {
      ilPayoutAmount[1] = value;
    }

    this.setState(prevState => ({ ...prevState, filters: { ...prevState.filters, ilPayoutAmount } }));
  };

  fetchPayableJournalItems(props = this.props) {
    const {
      location,
      setTag,
      addNotice,
      resources,
    } = props;

    const api = new ApiClient();
    const query = new URLSearchParams(location.search);
    const resource = resources.find(r => r.id === 'PayableJournal');
    if (resource) {
      const action = resource.resourceActions.find(r => r.name === 'list');
      if (action) {
        this.setState({
          loading: true,
        });
        api.resourceAction({
          actionName: 'list',
          resourceId: resource.id,
          params: query,
        }).then((response) => {
          const listActionReponse = response.data;
          let hoPayoutAmountSliderMin = 0;
          let hoPayoutAmountSliderMax = 1;
          let ilPayoutAmountSliderMin = 0;
          let ilPayoutAmountSliderMax = 1;
          listActionReponse.records.forEach((record) => {
            if (record.populated
              && record.populated.payable
              && record.populated.payable.params
            ) {
              if (record.populated.payable.params.payoutAmount) {
                const hoPayoutAmountSliderMinCandidate = Math.floor(parseFloat(record.populated.payable.params.payoutAmount));
                const hoPayoutAmountSliderMaxCandidate = Math.ceil(parseFloat(record.populated.payable.params.payoutAmount));
                if (hoPayoutAmountSliderMinCandidate < hoPayoutAmountSliderMin) hoPayoutAmountSliderMin = hoPayoutAmountSliderMinCandidate;
                if (hoPayoutAmountSliderMaxCandidate > hoPayoutAmountSliderMax) hoPayoutAmountSliderMax = hoPayoutAmountSliderMaxCandidate;
              }
              if (record.populated.payable.params.ilPayoutAmount) {
                const ilPayoutAmountSliderMinCandidate = Math.floor(parseFloat(record.populated.payable.params.ilPayoutAmount));
                const ilPayoutAmountSliderMaxCandidate = Math.ceil(parseFloat(record.populated.payable.params.ilPayoutAmount));
                if (ilPayoutAmountSliderMinCandidate < ilPayoutAmountSliderMin) ilPayoutAmountSliderMin = ilPayoutAmountSliderMinCandidate;
                if (ilPayoutAmountSliderMaxCandidate > ilPayoutAmountSliderMax) ilPayoutAmountSliderMax = ilPayoutAmountSliderMaxCandidate;
              }
            }
          });
          this.setState(prevState => ({
            ...prevState,
            records: listActionReponse.records,
            loading: false,
            filters: {
              ...prevState.filters,
              hoPayoutAmount: [hoPayoutAmountSliderMin, hoPayoutAmountSliderMax],
              ilPayoutAmount: [ilPayoutAmountSliderMin, ilPayoutAmountSliderMax],
            },
            hoPayoutAmountSlider: {
              ...prevState.hoPayoutAmountSlider,
              marks: [
                {
                  value: hoPayoutAmountSliderMin,
                  label: valuetext(hoPayoutAmountSliderMin),
                },
                {
                  value: hoPayoutAmountSliderMax,
                  label: valuetext(hoPayoutAmountSliderMax),
                },
              ],
              min: hoPayoutAmountSliderMin,
              max: hoPayoutAmountSliderMax,
            },
            ilPayoutAmountSlider: {
              ...prevState.ilPayoutAmountSlider,
              marks: [
                {
                  value: ilPayoutAmountSliderMin,
                  label: valuetext(ilPayoutAmountSliderMin),
                },
                {
                  value: ilPayoutAmountSliderMax,
                  label: valuetext(ilPayoutAmountSliderMax),
                },
              ],
              min: ilPayoutAmountSliderMin,
              max: ilPayoutAmountSliderMax,
            },
          }), () => this.filterRecords());
          if (setTag) {
            if (typeof response.data.meta.total === 'undefined') {
              setTag('');
            } else {
              setTag(response.data.meta.total.toString());
            }
          }
        }).catch(() => {
          addNotice({
            message: 'There was an error fetching records. Check out console to see more information.',
            type: 'error',
          });
          this.setState({
            loading: false,
          });
        });
      }
    }
  }

  filterRecords() {
    this.setState({
      loading: true,
    });
    const {
      records,
      filters: {
        hoPayoutAmount,
        ilPayoutAmount,
        transferId,
        description,
        details,
        status,
      },
    } = this.state;

    const filterHOPayoutAmount = (record) => {
      const min = hoPayoutAmount[0];
      const max = hoPayoutAmount[1];

      const isBiggerThanMin = payout => (Math.floor(parseFloat(payout)) >= min);
      const isSmallerThanMax = payout => (Math.ceil(parseFloat(payout)) <= max);

      if (record.populated
        && record.populated.payable
        && record.populated.payable.params
        && record.populated.payable.params.payoutAmount
      ) {
        return (isBiggerThanMin(record.populated.payable.params.payoutAmount) && isSmallerThanMax(record.populated.payable.params.payoutAmount));
      } else if (min === 0) return true;
      return false;
    };

    const filterILPayoutAmount = (record) => {
      const min = ilPayoutAmount[0];
      const max = ilPayoutAmount[1];

      const isBiggerThanMin = payout => (Math.floor(parseFloat(payout)) >= min);
      const isSmallerThanMax = payout => (Math.ceil(parseFloat(payout)) <= max);

      if (record.populated
        && record.populated.payable
        && record.populated.payable.params
        && record.populated.payable.params.ilPayoutAmount
      ) {
        return (isBiggerThanMin(record.populated.payable.params.ilPayoutAmount) && isSmallerThanMax(record.populated.payable.params.ilPayoutAmount));
      } else if (min === 0) return true;
      return false;
    };

    const filterTransferId = recordParams => (transferId.length === 0) || (recordParams.transferId && recordParams.transferId.includes(transferId));

    const filterDescription = recordParams => (description.length === 0) || (recordParams.description && recordParams.description.includes(description));

    const filterDetails = recordParams => (details.length === 0) || (recordParams.details && recordParams.details.includes(details));

    const filterStatus = recordParams => (status.length === 0) || (recordParams.status && recordParams.status === status);

    const filterParams = record => (record.params
        && filterTransferId(record.params)
        && filterDescription(record.params)
        && filterDetails(record.params)
        && filterStatus(record.params)
    );

    this.setState({
      loading: false,
      filteredRecords: records.filter(record => (record && filterHOPayoutAmount(record) && filterILPayoutAmount(record) && filterParams(record))),
    });
  }

  clearFilters() {
    this.setState(prevState => ({
      filters: {
        hoPayoutAmount: [
          prevState.hoPayoutAmountSlider.min,
          prevState.hoPayoutAmountSlider.max,
        ],
        ilPayoutAmount: [
          prevState.ilPayoutAmountSlider.min,
          prevState.ilPayoutAmountSlider.max,
        ],
        transferId: '',
        description: '',
        details: '',
        status: '',
      },
      hoPayoutAmountSlider: {
        ...prevState.hoPayoutAmountSlider,
        marks: [
          {
            value: prevState.hoPayoutAmountSlider.min,
            label: valuetext(prevState.hoPayoutAmountSlider.min),
          },
          {
            value: prevState.hoPayoutAmountSlider.max,
            label: valuetext(prevState.hoPayoutAmountSlider.max),
          },
        ],
      },
      ilPayoutAmountSlider: {
        ...prevState.ilPayoutAmountSlider,
        marks: [
          {
            value: prevState.ilPayoutAmountSlider.min,
            label: valuetext(prevState.ilPayoutAmountSlider.min),
          },
          {
            value: prevState.ilPayoutAmountSlider.max,
            label: valuetext(prevState.ilPayoutAmountSlider.max),
          },
        ],
      },
    }), () => this.filterRecords());
  }

  render() {
    const {
      location,
      history,
      resources,
    } = this.props;
    const {
      filteredRecords,
      loading,
      filters: {
        hoPayoutAmount,
        ilPayoutAmount,
        transferId,
        description,
        details,
        status,
      },
    } = this.state;

    const resource = resources.find(r => r.id === 'PayableJournal');
    if (!resource) {
      return (<NoResourceError resourceId="PayableJournal" />);
    }
    const action = resource.resourceActions.find(r => r.name === 'list');
    if (!action) {
      return (<NoActionError resourceId="PayableJournal" actionName="list" />);
    }

    const search = new URLSearchParams(window.location.search);
    const id = search.get('filters.accountPayable');

    return (
      <Container className="dashboard">
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            {
              title: 'Account Payable Report',
              path: id ? `/finance/account-payable-report/${id}` : '/finance/account-payable-report',
            },
            { title: 'Payable Journal', path: null },
          ]}
          isBackButton
        />
        <Row>
          <Col md={12}>
            <FilterAccountPayableBlock
              location={location}
              history={history}
              clearParentFilters={this.clearFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <h4 style={{ marginBottom: '12px' }}>Filters</h4>
                { /* AMOUNTS FILTERS */ }
                <Row className="mb-3">
                  <Col md={3}>
                    <Label>HO Payout Amount, min</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="hoPayoutAmountMin"
                        type="number"
                        value={hoPayoutAmount[0]}
                        onChange={this.onHOAmountsFilterDataChanged}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={3}>
                    <Label>HO Payout Amount, max</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="hoPayoutAmountMax"
                        type="number"
                        value={hoPayoutAmount[1]}
                        onChange={this.onHOAmountsFilterDataChanged}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={3}>
                    <Label>IL Payout Amount, min</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="ilPayoutAmountMin"
                        type="number"
                        value={ilPayoutAmount[0]}
                        onChange={this.onILAmountsFilterDataChanged}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={3}>
                    <Label>IL Payout Amount, max</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="ilPayoutAmountMax"
                        type="number"
                        value={ilPayoutAmount[1]}
                        onChange={this.onILAmountsFilterDataChanged}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Label>Search Transfer ID</Label>
                    <Input
                      name="transferId"
                      type="text"
                      value={transferId}
                      onChange={this.onFilterDataChanged}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Search Description</Label>
                    <Input
                      name="description"
                      type="text"
                      value={description}
                      onChange={this.onFilterDataChanged}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Search Details</Label>
                    <Input
                      name="details"
                      type="text"
                      value={details}
                      onChange={this.onFilterDataChanged}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Filter Status</Label>
                    <div className="control has-icons-left">
                      <select
                        disabled={loading}
                        className="form-control"
                        value={status}
                        onChange={this.onFilterDataChanged}
                        placeholder="Select Status..."
                        name="status"
                      >
                        <option value="">All statuses</option>
                        <option value="Scheduled">Scheduled</option>
                        <option value="Processed">Processed</option>
                        <option value="Failed">Failed</option>
                      </select>
                    </div>
                  </Col>
                </Row>
                <ButtonToolbar>
                  <Button
                    className="px-5 btn btn-sm"
                    type="button"
                    color="primary"
                    style={{ marginBottom: '10px' }}
                    onClick={this.filterRecords}
                    disabled={loading}
                  >
                    {this.state.isLoadingData && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                    <span>Apply Filters</span>
                  </Button>
                  <Button
                    className="px-5 btn btn-sm"
                    type="button"
                    color="danger"
                    style={{ marginBottom: '10px' }}
                    onClick={this.clearFilters}
                    disabled={loading}
                  >
                    Clear Filters
                  </Button>
                </ButtonToolbar>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <MatTable
                  dataArray={filteredRecords}
                  selectable={false}
                  columns={columnTransform}
                  defaultOrder={{
                    order: 'desc',
                    orderBy: 'transferGroup',
                  }}
                  rowsPerPage={25}
                  version={moment().format('x')}
                  csvDownload={{
                    fileName: 'PayableJournalList.csv',
                    csvArrayColumns: downloadCsv,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(PayableJournalList);
