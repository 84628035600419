import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonToolbar,
  Modal,
  Button,
  ModalHeader,
} from 'reactstrap';
import Select from 'react-select';
import { CKEditor } from 'ckeditor4-react';
import { axios } from 'ApiClient';
import { hideModalById } from '../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../App/store/with-notice';


export const modalId = 'RebuyPauseModal';

const InfoModal = () => {
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('3');
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState(undefined);

  const close = () => {
    setNotes(undefined);
    dispatch(hideModalById(modalId));
  };

  if (!modalInfo) return null;
  const { isShow: showUploadModal, data: { afterSavePause, deal, type } } = modalInfo;
  const savePause = async () => {
    try {
      setLoading(true);
      const resp = await axios.post('/api/portal/reports/post-save-pause-rebuy-settings', {
        dealId: deal._id,
        type,
        daysCount: selected,
        notes,
      });
      if (resp.data.success) {
        afterSavePause(resp.data.deal);
        close();
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  console.log(afterSavePause);
  const options = [
    { value: '3', label: '3 Days' },
    { value: '7', label: '7 Days' },
    { value: '14', label: '14 Days' },
    { value: '30', label: '30 Days' },
    { value: '45', label: '45 Days' },
    { value: '60', label: '60 Days' },
    { value: '9999', label: 'All Time' },
  ];
  const selectedType = options.find(o => o._id === selected);
  // if (['Bonus'].includes(selectedDeal.dealTerms.type_of_deal)) brandApprovedDate = selectedDeal.dealTerms.content_release_date;

  const handleChangeNotes = (event) => {
    const { editor } = event;
    setNotes(editor.getData());
  };

  return (
    <Modal
      isOpen={showUploadModal}
      toggle={close}
      size="sm"
    >
      <ModalHeader toggle={() => close()} tag="h3">
        {type === 'pause' ? 'Pause' : 'Restart'} Deal For Rebuy
      </ModalHeader>
      <div className="modal__body ltr-support px-3 pt-3">
        <h4>{deal.dealname}</h4>
        <hr />
        {type === 'pause' ? (
          <>
            <Select
              value={selectedType}
              options={options}
              onChange={value => setSelected(value.value)}
              placeholder="Select Pause Duration"
            />
            <p>Notes</p>
            <CKEditor
              config={{versionCheck: false}}
              id="notesEditor"
              name="notes"
              onChange={handleChangeNotes}
            />
          </>
        ) : null}
      </div>
      <ButtonToolbar className="modal__footer">
        <Button color="primary" onClick={() => savePause()} className="mr-2" disabled={loading}>{type === 'pause' ? 'Pause' : 'Restart'}</Button>
        <Button color="secondary" onClick={close} disabled={loading}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

export default withNotice(InfoModal);
