import { unflatten } from 'flat';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { axios } from 'ApiClient';
import withNotice from '../../../../App/store/with-notice';

const NameInput = ({ addNotice, record, property }) => {
  const recordUnflatten = unflatten(record);
  const rowData = recordUnflatten.params;
  const val = rowData[property] || '';
  const [value, setValue] = useState(val);
  const [invalid, setInvalid] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => setValue(val), [val]);

  const sendRequest = (newValue) => {
    setDisabled(true);
    axios.post('/api/portal/compliance/post-edit-content-review-rule', {
      id: record.id,
      fieldsToUpdate: { [property]: newValue },
    }).then((response) => {
      if (response?.data?.success) {
        addNotice({
          message: 'Rule was successfully updated.',
          type: 'success',
        });
      }
      if (response?.data?.error) {
        console.error(response.data.error);
        addNotice({
          message: response.data.error,
          type: 'error',
          duration: 30,
        });
      }
      setDisabled(false);
    });
    return true;
  };

  const checkValidity = (newValue) => {
    if (newValue?.length > 2) {
      setInvalid(false);
      return true;
    } else {
      setInvalid(true);
      return false;
    }
  }

  const handleChange = async (newValue) => {
    setValue(newValue);
    if (val !== newValue && checkValidity(newValue)) {
      sendRequest(newValue);
    }
  };

  return (
    <Input
      type="textarea"
      id={`${property}${record.id}`}
      name={`${property}${record.id}`}
      className="w-100"
      rows="1"
      invalid={invalid}
      onChange={e => setValue(e.target.value)}
      value={value}
      onBlur={() => handleChange(value)}
      disabled={disabled}
    />
  );
};
NameInput.propTypes = {
  addNotice: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  property: PropTypes.string.isRequired,
};
export default withNotice(NameInput);
