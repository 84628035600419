import React, { useState } from 'react';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import { Modal, ButtonToolbar, Button } from 'reactstrap';
import TableCellContextMenu from '../../../Prospects/components/custom/TableCellContextMenu';
import EditModal from './EditModal';
import DeleteProspectUploadModal from './DeleteProspectUploadModal';
import withNotice from '../../../../../../App/store/with-notice';

const ScheduledContextMenuInList = ({
  record,
  setVersion,
  addNotice,
}) => {
  // const user = unflatten(record);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [modalDeleteUpload, setModalDeleteUpload] = useState(false);
  const [loadingDeleteUpload, setLoadingDeleteUpload] = useState(false);

  const loadLog = async () => {
    setModal(true);
  };

  const uploadNow = async () => {
    setLoading(true);
    console.log(loading);
    try {
      const recordData = await axios.post('/api/portal/prospects/post-start-upload-now', { _id: record.params._id });
      console.log(recordData);
      setSuccessModal(true);
      setVersion((new Date()).toString());
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUpload = () => {
    setLoadingDeleteUpload(true);
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-delete-prospect-upload',
      data: {
        identity: record.params.identity,
      },
    }).then((res) => {
      setLoadingDeleteUpload(false);
      if (res?.data?.success) {
        addNotice({
          message: 'Prospect Upload was successfully deleted.',
          type: 'success',
        });
        setVersion((new Date()).toString());
      } else if (res?.data?.error) {
        addNotice({
          message: `Prospect Upload was not deleted. Error: ${res.data.error}`,
          type: 'error',
        });
      }
      setModalDeleteUpload(false);
    }).catch((error) => {
      console.error(error);
      addNotice({
        message: `Prospect Upload was not deleted. Error: ${error.message}`,
        type: 'error',
      });
      setLoadingDeleteUpload(false);
    });
  };

  const contextMenuOptions = [
    {
      label: 'Edit Scheduled Start Time',
      handler: () => {
        loadLog();
      },
    },
    {
      label: 'Upload Now',
      handler: () => {
        uploadNow();
      },
    },
    {
      label: 'Delete Job',
      handler: () => setModalDeleteUpload(true),
    },
  ];

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />
      <EditModal modal={modal} record={record} setModalVisible={() => setModal(false)} setVersion={setVersion} />
      <Modal
        isOpen={successModal}
        toggle={() => setSuccessModal(false)}
      >
        <div className="modal__header">
          <h3 className="text-modal  modal__title">Details</h3>
        </div>
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <div className="mt-1 mb-4">
            The Upload will be started soon.
          </div>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel btn-sm" color="secondary" onClick={() => setSuccessModal(false)}>Close</Button>
        </ButtonToolbar>
      </Modal>
      <DeleteProspectUploadModal
        modal={modalDeleteUpload}
        setModalVisible={setModalDeleteUpload}
        loading={loadingDeleteUpload}
        deleteUpload={deleteUpload}
      />
    </>
  );
};

ScheduledContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(ScheduledContextMenuInList);
