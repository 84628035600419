/* eslint-disable filenames/match-exported */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import { getDefaultTargetCac, calculateConversionsCount, calculatePayout, getProjectedPercent, calculateDays } from '../../helpers/CACHelper';
import { formatCurrency, formatNumber } from '../../helpers/WVFormatter';


const WideTooltip = withStyles({
  tooltip: {
    width: '500px',
  },
})(Tooltip);

const PredictedCAC = React.memo(({
  dealForCac, CPApaidConversionsArray, CPMpaidConversionsCount, brand, settings, mediaPublishedDate,
}) => {
  // console.log({ dealForCac, CPApaidConversionsArray, CPMpaidConversionsCount, brand, settings, mediaPublishedDate }, 'def');
  const conversionsCount = calculateConversionsCount({ CPApaidConversionsArray, dealForCac, CPMpaidConversions: CPMpaidConversionsCount });
  const payout = calculatePayout({ CPApaidConversionsArray, dealForCac });
  if (!brand || !dealForCac || dealForCac?.dealTerms?.type_of_deal !== 'Upfront CPM' || !conversionsCount) return '-';
  const [defaultTargetCac] = getDefaultTargetCac(brand, dealForCac?.dealTerms.type_of_deal, settings);
  const days = calculateDays({ mediaPublishedDate });
  if (days < 16) return '-';
  const cac = (conversionsCount ? (payout / conversionsCount) : null);
  const allowShow = cac !== null && cac > 0 && defaultTargetCac >= cac;
  if (allowShow) return '-';

  const projectedPercent = days > 0 ? getProjectedPercent(days, settings) : 0;

  const projectedConversions = Number(conversionsCount / projectedPercent) * 100;
  const projectedCac = projectedConversions ? payout / projectedConversions : null; // need payout
  const value = projectedCac === null ? '-' : formatCurrency(projectedCac);
  // const cacProjection = projectedPercent ? Number((defaultTargetCac / (projectedPercent || 1)) * 100) : 0;
  const allow = projectedCac !== null && projectedCac > 0 && defaultTargetCac >= projectedCac;
  const describeValue = (
    <div style={{ fontSize: '14px', maxWidth: '500px', lineHeight: 2 }}>
      <span><strong>Target CAC</strong>: {formatCurrency(Number(defaultTargetCac))}</span><br />
      <strong>Projected Percent %</strong>: {formatNumber(Number(projectedPercent))}<br />
      <strong>Projected Conversions</strong>: {formatNumber(projectedConversions)}<br />
      <strong>Projected CAC</strong>: {projectedCac !== '-' ? formatCurrency(Number(projectedCac || 0)) : '-'}<br />
      {/* eslint-disable-next-line max-len */}
      <span><strong>Predicted CAC</strong> of <strong>{projectedCac !== '-' ? formatCurrency(Number(projectedCac || 0)) : '-'}</strong> {allow ? 'is less than ' : 'is over'} the <strong>{formatCurrency(defaultTargetCac)}</strong> target</span><br />
    </div>);

  return (
    <>
      <WideTooltip title={describeValue}><span className={allow ? 'text-success' : 'text-danger'}>{value}</span></WideTooltip>{' '}
      {allow ? (
        <FontAwesomeIcon icon="thumbs-up" className="text-success" />
      ) : (
        <FontAwesomeIcon icon="thumbs-down" className="text-danger" />
      )
      }
    </>
  );
}, (a, a1) => _.isEqual(a.dealForCac, a1.dealForCac));

PredictedCAC.propTypes = {
  dealForCac: PropTypes.objectOf(PropTypes.any),
  brand: PropTypes.objectOf(PropTypes.any),
  CPApaidConversionsArray: PropTypes.arrayOf(PropTypes.any),
  CPMpaidConversionsCount: PropTypes.number,
  settings: PropTypes.objectOf(PropTypes.any),
  mediaPublishedDate: PropTypes.string,
};
PredictedCAC.defaultProps = {
  dealForCac: null,
  brand: null,
  settings: {},
  CPApaidConversionsArray: [],
  CPMpaidConversionsCount: 0,
  mediaPublishedDate: '',
};

export default PredictedCAC;
