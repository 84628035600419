import React, { PureComponent } from 'react';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import WVFormatter from '../../../../../shared/helpers/WVFormatter';

class StripeBalanceControls extends PureComponent {
  static propTypes = {
    stripeSelectedAmount: PropTypes.number,
    selectedAmount: PropTypes.number,
    availableBalance: PropTypes.number,
  }
  static defaultProps = {
    stripeSelectedAmount: 0,
    selectedAmount: 0,
    availableBalance: 0,
  }
  render() {
    return (
      <Card body className="card-bordered card " style={{ padding: '20px', height: '100%' }}>
        <h4 className="mb-1"><strong>Stripe Balance:</strong> <span className="float-right">{WVFormatter.formatCurrency(this.props.availableBalance / 100)}</span></h4>
        <h4 className={`mb-1 ${(this.props.stripeSelectedAmount * 100) > this.props.availableBalance ? 'text-danger' : 'text-success'}`}>
          <strong>Payout Amount:</strong> <span className="float-right">{WVFormatter.formatCurrency(this.props.selectedAmount)}</span>
        </h4>
      </Card>
    );
  }
}
export default StripeBalanceControls;
