import { useState, useEffect } from 'react';
import { axios } from 'ApiClient';

function useConversions(influencerId, shouldFetch, setShouldFetch, startDate, endDate) {
  const [conversions, setConversions] = useState([]);
  const [cpmConversions, setCpmConversions] = useState([]);
  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    async function fetchData() {
      axios({
        method: 'get',
        url: `/api/portal/deals/conversions?influencerId=${influencerId}&dateFrom=${startDate}&dateTo=${endDate}`,
      }).then((response) => {
        if (response?.data?.success && response.data.conversions) {
          setConversions(response?.data?.conversions || []);
        }
        if (response?.data?.success && response.data.clicks) {
          setClicks(response?.data?.clicks || 0);
        }
        if (response?.data?.success && response.data.cpmConversions) {
          setCpmConversions(response.data.cpmConversions);
        }
        setShouldFetch(false);
      });
    }

    if (shouldFetch && influencerId) {
      fetchData();
    }
  }, [influencerId, shouldFetch]);

  return { conversions, clicks, cpmConversions };
}

export default useConversions;
