import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  // Alert,
  Card,
  CardBody,
} from 'reactstrap';
import { capitalize } from 'lodash';
import useMediaContent from './components/useMediaContent';
import mapMediaTypeIcons from '../../../Scorecard/MediaContent/components/custom/mapMediaTypeIcons';
import DetailsCellValue from './components/DetailsCellValue';
import ScorecardsDropDown from './components/ScorecardsDropDown';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import { getAndSetAdminUserNames } from '../../../../../redux/reducers/admin/AdminUserNameReducer';
import './components/tableStyling.css';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';

const mapType = mapMediaTypeIcons();
const calculateAverage = (arr) => {
  const totalSum = arr.reduce((acc, answer) => acc + answer.totalScore, 0);
  return totalSum ? Math.floor(totalSum / arr.length) : '';
};

const tableConfig = {
  isEditable: false,
  useFlex: true,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  manualPageSize: [5, 10, 30, 40],
  withSearchEngine: true,
  sortBy: 'createdAt',
  direction: 'desc',
  placeholder: 'Search Details or Content Name',
  searchFieldMinWidth: '50%',
};

let adminUserNamesWereFetched = false;

const MediaContent = ({ influencer }) => {
  const dispatch = useDispatch();
  const mediaData = useMediaContent(influencer.id);

  const columnTransform = useMemo(() => [
    {
      id: 'title',
      Header: 'Details',
      Cell: value => <DetailsCellValue record={value.row.original} />,
      accessor: record => record.title?.toLowerCase(),
      width: 350,
    },
    {
      id: 'type',
      Header: 'Type',
      Cell: value => <img style={{ width: '30px' }} src={`/images/mediacontent/${mapType[value.row.original?.type]}.png`} alt={value.row.original.type} title={value.row.original.type} />,
      accessor: record => record.type,
      width: 50,
    },
    {
      id: 'contentName',
      Header: 'Content Name',
      Cell: value => (
        <>
          <a rel="noreferrer" target="_blank" href={`/scorecard/media-content/edit/${value.row.original._id}`}>
            {value.row.original?.name || '--- Content Name Field Not Set ---'}
          </a>
        </>),
      accessor: record => record.name?.toLowerCase() || '',
      width: 250,
    },
    {
      id: 'createdAt',
      Header: 'Submit Date',
      Cell: value => (value.row.original.createdAt ? moment.utc(value.row.original.createdAt).local().format('MM/DD/YYYY') : ''),
      accessor: record => record.createdAt,
      width: 90,
    },
    {
      id: 'brandApprovedDate',
      Header: 'Brand Approved Date (ET)',
      Cell: value => (value.row.original.brandApprovedDate ? moment.tz(value.row.original.brandApprovedDate, 'America/New_York').format('MM/DD/YYYY') : ''),
      accessor: record => record.brandApprovedDate,
      width: 90,
    },
    {
      id: 'publishedDate',
      Header: 'Publish Date',
      Cell: value => (value.row.original?.publishDate ? moment.utc(value.row.original.publishDate).format('MM/DD/YYYY') : ''),
      accessor: record => record?.publishDate,
      width: 90,
    },
    {
      Header: 'Privacy Status',
      accessor: record => record.privacyStatus,
      id: 'privacyStatus',
      Cell: (record) => {
        const status = record.row.original.privacyStatus;
        let value = (status && status.length > 0) ? capitalize(status.trim()) : '-';
        return value;
      },
      className: 'align-middle',
      disableSortBy: false,
      width: 100,
    },
    {
      id: 'score',
      Header: 'Average Score',
      accessor: record => (record.answers?.length > 0 ? calculateAverage(record.answers) : ''),
      width: 100,
    },
    {
      id: 'scorecards',
      Header: 'Scorecards',
      Cell: value => (
        <>
          {value.row.original.answers.length > 0 ? <ScorecardsDropDown record={value.row.original} /> : <span>No Scorecards</span>}
        </>
      ),
      width: 120,
    },
  ]);

  if (!adminUserNamesWereFetched) {
    dispatch(getAndSetAdminUserNames());
    adminUserNamesWereFetched = true;
  }

  return (
    <Card id="mediaConentBlock section-media-content">
      <CardBody>
        {mediaData.length > 0 ?
          <ReactTableBase
            columns={columnTransform}
            data={mediaData}
            tableConfig={tableConfig}
            disableSortRemove
          />
          :
          <Alert color="warning" bordred icon>
            <p className="py-2">
              The Creator currently has no Media Content.
            </p>
          </Alert>
      }
      </CardBody>
    </Card>
  );
};

MediaContent.propTypes = {
  influencer: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default MediaContent;
