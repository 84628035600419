/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import queryHasFilter from '@/shared/ViewHelpers/QueryHasFilter';
import RefreshIcon from 'mdi-react/RefreshIcon';
import ActionHeader from '../../../../shared/components/components/app/action-header';
import Filter from '../../../../shared/components/components/app/filter';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
import UserPropertyType from '../HubilDeal/components/custom';
import ButtonWithSpinner from '../../../../shared/components/ButtonWithSpinner';
import { fetchAgents } from '../../../../redux/reducers/admin/HubilReducer';
import HubspotStatsWidget from '../../../../shared/components/hubspot/HubspotStatsWidget';
import ProgressBar from '../../../../shared/helpers/ProgressBar';
import HubilAgentForm from './components/custom/HubilAgentForm';

class ResourceAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterVisible: queryHasFilter(props.location.search),
      v: (new Date()).toString(),
      hsAgentDialogVisible: false,
    };
    this.refreshTable = this.refreshTable.bind(this);
  }
  setChildMethod = (setRefresh, context) => {
    this.refreshMatTable = setRefresh.bind(context);
  }
  refreshTable = () => {
    const { dispatch } = this.props;
    dispatch(fetchAgents(() => {
      this.refreshMatTable();
      this.setState({ v: (new Date()).toString() });
    }));
  }

  openDialogHsAgent = () => {
    this.setState({ hsAgentDialogVisible: true });
  }

  closeDialogHsAgent = () => {
    this.setState({ hsAgentDialogVisible: false });
    this.refreshMatTable();
  }

  render() {
    const {
      resources,
      loading,
    } = this.props;

    const {
      filterVisible,
      tag,
      v,
      hsAgentDialogVisible,
    } = this.state;
    const resource = resources.find(r => r.id === 'HubilAgent');
    if (!resource) {
      return (<NoResourceError resourceId="HubilAgent" />);
    }
    const action = resource.resourceActions.find(r => r.name === 'list');
    if (!action) {
      return (<NoActionError resourceId="HubilAgent" actionName="list" />);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs resource={resource} actionName="list" />
            <ActionHeader
              resource={resource}
              action={action}
              tag={tag}
              toggleFilter={() => this.setState({ filterVisible: !filterVisible })}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <HubspotStatsWidget version={v} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ProgressBar topic="fetchAllAgents" />
          </Col>
          <Col md={4}>
            <ButtonWithSpinner
              className="btn btn-sm btn-primary float-right"
              onClick={this.refreshTable}
              disabled={loading}
              loading={loading}
            >
              {!loading ? <RefreshIcon size={18} /> : ''}
              Fetch all agents from HS
            </ButtonWithSpinner>
          </Col>
          <Col md={2}>
            <Button
              className="w-100"
              size="sm"
              color="primary"
              onClick={this.openDialogHsAgent}
            >
              Create HS Agent
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <MatTableList
              action={action}
              resource={resource}
              setTag={state => this.setState({ tag: state })}
              date={new Date()}
              UserPropertyType={UserPropertyType}
              sortBy="_id"
              direction="asc"
              columnsSettings={{
                hs_object_id: {
                  width: 100,
                },
                email: {
                  width: 250,
                },
                firstname: {
                  width: 150,
                },
                lastname: {
                  width: 150,
                },
                num_associated_deals: {
                  width: 100,
                },
                archived: {
                  width: 100,
                },
              }}
              setRefresh={this.setChildMethod}
              showFilters
              hideToolbar
              v={2}
            />
          </Col>
        </Row>
        {action.showFilter ? (
          <Filter
            resource={resource}
            isVisible={filterVisible}
            toggleFilter={() => this.setState({ filterVisible: !filterVisible })}
          />
        ) : ''}
        <HubilAgentForm
          modal={hsAgentDialogVisible}
          modalTitle="New Agent HubSpot Contact"
          closeDialog={this.closeDialogHsAgent}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({

  resources: state.resources,
  loading: state.hubilReducer.loading.fetchContacts,
});

export default connect(mapStateToProps)(ResourceAction);
