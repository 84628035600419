import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { Input, Button, Row, Col, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ButtonWithSpinner from '../../../../../shared/components/ButtonWithSpinner';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';

const csvArrayColumns = [
  {
    label: 'Email',
    func: record => (record.vendor.email || ''),
  },
  {
    label: 'IL Name',
    func: record => (`${record.account.first_name} ${record.account.last_name}`),
  },
  {
    label: 'Bill.com Name',
    func: record => (record.vendor.name || ''),
  },
  {
    label: 'Account ID',
    func: record => (record.vendor.id || ''),
  },
  {
    label: 'Address Country',
    func: record => (record.vendor.addressCountry || ''),
  },
  {
    label: 'Phone',
    func: record => (record.vendor.phone || record.vendor.paymentPhone || ''),
  },
];

class MatchedVendorsTable extends PureComponent {
  constructor(props) {
    super(props);

    // const accounts = props.accounts.map((account) => {
    //   const _account = { ...account };
    //   _account.collectionName = 'creator';
    //   return _account;
    // });

    this.state = {
      accounts: props.accounts,
      selectedIds: [],
      lastUpdated: new Date(),
      loading: {
        importVendors: false,
      },
      modals: {
        importVendors: false,
      },
    };
  }

  onTableRowTypeChanged = (id, value) => {
    const index = this.state.accounts.findIndex(account => account.id === id);
    if (index > -1) {
      // this.state.accounts[index].collectionName = value; // WTF
    }
  }

  onTableRowSelected = (rows) => {
    const selectedIds = [];
    [...rows.entries()].forEach(([id, isSelected]) => {
      if (isSelected) {
        selectedIds.push(id);
      }
    });

    this.setState({ selectedIds });
  }

  onImportVendorsButton = () => {
    if (!this.validateErrors()) { return; }

    this.toggleImportVendorsModal();
  }

  toggleImportVendorsModal = () => {
    this.setState(prevState => ({ modals: { importVendors: !prevState.modals.importVendors } }));
  }

  processImportVendors = () => {
    this.setState({ loading: { importVendors: true } });
    const { accounts, selectedIds } = this.state;

    const accountsToUpdate = [];
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const id of selectedIds) {
      const account = accounts.find(_account => _account.id === id);
      if (account) {
        accountsToUpdate.push({
          id: account.vendor.id,
          email: account.vendor.email,
          paymentCountry: account.vendor.addressCountry,
          name: account.vendor.name,
          collectionName: account.collectionName,
        });
      }
    }

    axios({
      method: 'post',
      url: '/api/portal/billCom/update-matched-accounts',
      data: {
        accounts: accountsToUpdate,
      },
    }).then((response) => {
      if (response.data.success === true) {
        // remove imported accounts
        const filteredAccounts = accounts.filter(account => !selectedIds.includes(account.id));

        this.setState({
          loading: { importVendors: false },
          modals: { importVendors: false },
          selectedIds: [],
          accounts: [...filteredAccounts],
          lastUpdated: new Date(),
        });
        this.props.addNotice({
          message: 'Successfully imported selected vendors!',
          type: 'success',
        });
        return;
      }

      this.processError(response.data.error);
    }).catch((error) => {
      this.processError(error.message);
    });
  }

  validateErrors() {
    const { accounts, selectedIds } = this.state;

    // check for email duplicates
    const selectedEmails = [];
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const id of selectedIds) {
      const account = accounts.find(_account => _account.id === id);
      if (account) {
        const { email } = account.vendor;
        if (!selectedEmails.includes(email)) {
          selectedEmails.push(email);
        } else {
          this.props.addNotice({
            message: `There is duplicate email in selected accounts: ${email}`,
            type: 'error',
          });
          return false;
        }
      }
    }
    return true;
  }

  processError(errorMessage) {
    this.setState({ loading: { importVendors: false } });
    this.props.addNotice({
      message: errorMessage || 'Something went wrong!',
      type: 'error',
    });
  }

  render() {
    const columnTransform = {
      email: {
        title: 'Email',
        valueFunc: record => (record.vendor.email || ''),
        sort: record => (record.vendor.email || ''),
        searchFunc: (record, search) => (record.vendor.email ? record.vendor.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),
      },
      ilName: {
        title: 'IL Name',
        valueFunc: record => (`${record.account.first_name} ${record.account.last_name}`),
        sort: record => (`${record.account.first_name} ${record.account.last_name}`),
        searchFunc: (record, search) => (`${record.account.first_name} ${record.account.last_name}`.toLowerCase().indexOf(search.toLowerCase()) !== -1),
      },
      billComName: {
        title: 'Bill.com Name',
        valueFunc: record => (record.vendor.name || ''),
        sort: record => (record.vendor.name || ''),
        searchFunc: (record, search) => (record.vendor.name ? record.vendor.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),
      },
      accountId: {
        title: 'Account ID',
        valueFunc: record => (record.vendor.id || ''),
        sort: record => (record.vendor.id || ''),
      },
      collectionName: {
        title: 'Type',
        valueFunc: record => (
          <div style={{ minWidth: '100px', maxWidth: '100px', marginBottom: 0 }}>
            <Input
              type="select"
              onChange={e => this.onTableRowTypeChanged(record.id, e.target.value)}
              defaultValue={record.collectionName}
            >
              <option value="agent">Agent</option>
              <option value="creator" >Creator</option>
            </Input>
          </div>
        ),
        sort: record => (record.collectionName || 'Creator'),
      },
      addressCountry: {
        title: 'Country',
        valueFunc: record => (record.vendor.addressCountry || ''),
        sort: record => (record.vendor.addressCountry || ''),
      },
      phone: {
        title: 'Phone',
        valueFunc: record => (record.vendor.phone || record.vendor.paymentPhone || ''),
        sort: record => (record.vendor.phone || record.vendor.paymentPhone || ''),
      },
    };

    const {
      accounts,
      loading,
      modals,
      selectedIds,
      lastUpdated,
    } = this.state;
    return (
      <div>
        <Row className="align-items-center">
          <Col>
            <h4 className="il-color-deep-blue font-weight-medium mb-0">New Matched Accounts</h4>
          </Col>
          <Col xs="auto">
            <Button
              type="button"
              color="primary"
              disabled={selectedIds.length < 1}
              onClick={this.onImportVendorsButton}
            >
              Import Matched
            </Button>
          </Col>
        </Row>
        {this.props.loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        {
          !this.props.loading &&
          <Row>
            <MatTableForRedux
              dataArray={accounts}
              selectable
              selectedEvent={this.onTableRowSelected}
              columns={columnTransform}
              version={lastUpdated.toString()}
              defaultOrder={{
                order: 'desc',
                orderBy: 'email',
              }}
              rowsPerPage={10}
              csvDownload={{
                fileName: 'MatchedBillComAccounts.csv',
                csvArrayColumns,
              }}
            />
          </Row>
        }
        { /* Confirm payment modal */ }
        <Modal isOpen={modals.importVendors} toggle={this.toggleImportVendorsModal} style={{ minWidth: '200px' }} className="delete-modal">
          <ModalHeader>Import Vendors</ModalHeader>
          <ModalBody>
            <p>
              Please confirm that you wish to import selected accounts to InfluenceLogic database.
            </p>
          </ModalBody>
          <ModalFooter>
            <ButtonWithSpinner
              type="button"
              color="primary"
              onClick={this.processImportVendors}
              loading={loading.importVendors}
            >
              <span>Import Vendors</span>
            </ButtonWithSpinner>{' '}
            <Button color="secondary" onClick={this.toggleImportVendorsModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

MatchedVendorsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default MatchedVendorsTable;
