const moment = require('moment');

exports.setUrl = (
  history, removeBeforeSet = false,
  search, dateFrom, dateTo, selectedButton,
  commentDateFrom, commentDateTo, singleCommentDate, commentDateFilterType,
  selectedCreators, selectedBrands, filtersCommentSentiment, filterBrandRelevant,
  videoPublishDateFrom, videoPublishDateTo, singleVideoPublishDate, videoPublishDateFilterType,
  aiSentimentFilterCheck, aiSentimentPositiveScore, aiSentimentNegativeScore, aiSentimentIgnoreScore,
  aiBrandRelevantFilterCheck, aiBrandRelevantScore, aiBrandIgnoreScore, filterContentType
) => {
  let searchUrl = new URLSearchParams(window.location.search);
  const oldUrl = `${history.location.pathname}?${searchUrl.toString()}`;

  if (removeBeforeSet) {
    searchUrl = new URLSearchParams();
  }

  if (search) {
    searchUrl.set('filters.fullSearchName', search);
  } else {
    searchUrl.delete('filters.fullSearchName');
  }

  searchUrl.delete('filters.videoPublishDate.from');
  searchUrl.delete('filters.videoPublishDate.to');
  searchUrl.delete('videoPublishedDateType');

  if (videoPublishDateFrom && videoPublishDateFrom !== '') {
    searchUrl.delete('filters.videoPublishDate.from');
    searchUrl.append('filters.videoPublishDate.from', videoPublishDateFrom);
  }
  if (videoPublishDateTo && videoPublishDateTo !== '') {
    searchUrl.delete('filters.videoPublishDate.to');
    searchUrl.append('filters.videoPublishDate.to', videoPublishDateTo);
  }
  if (singleVideoPublishDate && singleVideoPublishDate !== '') {
    searchUrl.delete('filters.videoPublishDate.from');
    searchUrl.delete('filters.videoPublishDate.to');
    if (videoPublishDateFilterType.value === '>' || videoPublishDateFilterType.value === '>=') {
      searchUrl.append('filters.videoPublishDate.from', singleVideoPublishDate.toISOString());
    }
    if (videoPublishDateFilterType.value === '<' || videoPublishDateFilterType.value === '<=') {
      searchUrl.append('filters.videoPublishDate.to', singleVideoPublishDate.toISOString());
    }
    if (videoPublishDateFilterType.value === '=') {
      searchUrl.append('filters.videoPublishDate.from', singleVideoPublishDate.toISOString());
      const nextDay = new Date(singleVideoPublishDate);
      nextDay.setDate(singleVideoPublishDate.getDate() + 1);
      searchUrl.append('filters.videoPublishDate.to', nextDay.toISOString());
    }
  }
  if (videoPublishDateFilterType.value && ((videoPublishDateFrom && videoPublishDateTo) || singleVideoPublishDate)) {
    searchUrl.append('videoPublishedDateType', videoPublishDateFilterType.value);
  }

  searchUrl.delete('filters.publishedAt.from');
  searchUrl.delete('filters.publishedAt.to');
  searchUrl.delete('range');
  if (dateFrom !== '') {
    searchUrl.append('filters.publishedAt.from', dateFrom);
  }
  if (dateTo !== '') {
    searchUrl.append('filters.publishedAt.to', dateTo);
  }
  searchUrl.append('range', selectedButton);

  // searchUrl.delete('filters.publishedAt.from');
  // searchUrl.delete('filters.publishedAt.to');
  searchUrl.delete('publishedDateType');

  if (commentDateFrom && commentDateFrom !== '') {
    const from = commentDateFrom ? moment.utc(moment(commentDateFrom).format('YYYY-MM-DD')).toDate() : '';
    searchUrl.delete('filters.publishedAt.from');
    searchUrl.append('filters.publishedAt.from', from.toISOString());
  }
  if (commentDateTo && commentDateTo !== '') {
    const to = commentDateTo ? moment.utc(moment(commentDateTo).format('YYYY-MM-DD')).endOf('day').toDate() : '';
    searchUrl.delete('filters.publishedAt.to');
    searchUrl.append('filters.publishedAt.to', to.toISOString());
  }
  if (singleCommentDate && singleCommentDate !== '') {
    searchUrl.delete('filters.publishedAt.from');
    searchUrl.delete('filters.publishedAt.to');
    const single = singleCommentDate ? moment.utc(moment(singleCommentDate).format('YYYY-MM-DD')).toDate() : '';
    if (commentDateFilterType.value === '>' || commentDateFilterType.value === '>=') {
      searchUrl.append('filters.publishedAt.from', single.toISOString());
    }
    if (commentDateFilterType.value === '<' || commentDateFilterType.value === '<=') {
      searchUrl.append('filters.publishedAt.to', single.toISOString());
    }
    if (commentDateFilterType.value === '=') {
      searchUrl.append('filters.publishedAt.from', single.toISOString());
      const nextDay = new Date(singleCommentDate);
      nextDay.setDate(singleCommentDate.getDate() + 1);
      searchUrl.append('filters.publishedAt.to', nextDay.toISOString());
    }
  }
  if (commentDateFilterType.value && ((commentDateFrom && commentDateTo) || singleCommentDate)) {
    // setEnablePublishDateFilter(true);
    searchUrl.append('publishedDateType', commentDateFilterType.value);
  }

  const filterCreators = searchUrl.getAll('filters.creator');
  let deleteFilterCreators = false;

  // console.log('SELECTED: ', selectedCreators);
  if (!selectedCreators || selectedCreators.length === 0) {
    searchUrl.delete('filters.creator');
  }
  if (selectedCreators) {
    filterCreators.forEach((creator) => {
      if (selectedCreators.filter(item => item.value === creator).length === 0) {
        deleteFilterCreators = true;
      }
    });
    if (deleteFilterCreators) {
      searchUrl.delete('filters.creator');
    }
    selectedCreators.forEach((creator) => {
      if (creator.value !== '' && (!filterCreators.includes(creator.value) || deleteFilterCreators)) {
        searchUrl.append('filters.creator', creator.value);
      }
    });
  }

  const filterBrands = searchUrl.getAll('filters.brand');
  let deleteFilterBrands = false;

  if (!selectedBrands || selectedBrands.length === 0) {
    searchUrl.delete('filters.brand');
  }
  if (selectedBrands) {
    filterBrands.forEach((brand) => {
      if (selectedBrands.filter(item => item.value === brand).length === 0) {
        deleteFilterBrands = true;
      }
    });
    if (deleteFilterBrands) {
      searchUrl.delete('filters.brand');
    }
    selectedBrands.forEach((brand) => {
      if (brand.value !== '' && (!filterBrands.includes(brand.value) || deleteFilterBrands)) {
        searchUrl.append('filters.brand', brand.value);
      }
    });
  }

  // const filtersTypes = searchUrl.getAll('filters.type');
  searchUrl.delete('filters.humanSentiment');
  if (filtersCommentSentiment.unreviewed || filtersCommentSentiment.positive || filtersCommentSentiment.neutral || filtersCommentSentiment.negative || filtersCommentSentiment.ignore) {
    Object.keys(filtersCommentSentiment).forEach((sentiment) => {
      if (filtersCommentSentiment[sentiment]) {
        searchUrl.append('filters.humanSentiment', sentiment);
      }
    });
  }
  searchUrl.delete('filters.brandRelevancy');
  if (filterBrandRelevant.unreviewed || filterBrandRelevant.relevant || filterBrandRelevant.ignore) {
    Object.keys(filterBrandRelevant).forEach((sentiment) => {
      if (filterBrandRelevant[sentiment]) {
        searchUrl.append('filters.brandRelevancy', sentiment);
      }
    });
  }

  searchUrl.delete('filters.aiSentimentScoreQ');
  searchUrl.delete('filters.aiBrandScoreQ');
  let aiSentimentFilterQuery = '';
  if (aiSentimentFilterCheck.positive) {
    aiSentimentFilterQuery = aiSentimentFilterQuery.concat(`pos${aiSentimentPositiveScore[0]}--${aiSentimentPositiveScore[1]}`);
  }
  if (aiSentimentFilterCheck.negative) {
    aiSentimentFilterQuery = aiSentimentFilterQuery.concat(`/neg${aiSentimentNegativeScore[0]}--${aiSentimentNegativeScore[1]}`);
  }
  if (aiSentimentFilterCheck.ignore) {
    aiSentimentFilterQuery = aiSentimentFilterQuery.concat(`/ig${aiSentimentIgnoreScore[0]}--${aiSentimentIgnoreScore[1]}`);
  }
  if (aiSentimentFilterQuery) {
    searchUrl.append('filters.aiSentimentScoreQ', aiSentimentFilterQuery);
  }
  aiSentimentFilterQuery = '';
  if (aiBrandRelevantFilterCheck.relevant) {
    aiSentimentFilterQuery = aiSentimentFilterQuery.concat(`rel${aiBrandRelevantScore[0]}--${aiBrandRelevantScore[1]}`);
  }
  if (aiBrandRelevantFilterCheck.ignore) {
    aiSentimentFilterQuery = aiSentimentFilterQuery.concat(`/ig${aiBrandIgnoreScore[0]}--${aiBrandIgnoreScore[1]}`);
  }
  if (aiSentimentFilterQuery) {
    searchUrl.append('filters.aiBrandScoreQ', aiSentimentFilterQuery);
  }

  searchUrl.delete('filters.mediaContentType');
  if (filterContentType.youtube || filterContentType.youtubeDescription) {
    Object.keys(filterContentType).forEach((item) => {
      if (filterContentType[item]) {
        searchUrl.append('filters.mediaContentType', item);
      }
    });
  }

  let newUrl = `${history.location.pathname}?${searchUrl.toString()}`;
  if (oldUrl !== newUrl) {
    searchUrl.set('page', '1');
    newUrl = `${history.location.pathname}?${searchUrl.toString()}`;
    history.push(newUrl);
  }
};
