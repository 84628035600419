/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const ResearchReducer = createSlice({
  name: 'agents',
  initialState: {
    loading: {
      formulas: false,
      saveFormula: false,
      channels: false,
      search: false,
      uploadChannels: false,
      refetchData: false,
      watchlists: false,
      watchlistsGrid: false,
      savedSearchesOptions: false,
      savedSearchesGrid: false,
      prospectUploadsSavedSearchesForGrid: false,
      prospectsGrid: true,
      uploadProspects: false,
      hubspotImport: false,
      addToProspects: false,
      brandSafetySearchTerms: true,
      extensionData: false,
      videoFacets: false,
      creatorFacets: false,
      prospectTubularStatistics: false,
    },
    currentFormula: null,
    formulas: [],
    formulaTokens: {},
    channels: [],
    searchResults: [],
    videoFacetsResults: [],
    creatorFacetsResults: [],
    searchMetadata: {
      total: 0,
    },
    showFormulaDialog: false,
    showDeleteDialog: false,
    searchTokens: {
      creator: { 0: '' },
      video: { 0: '' },
      podcast: { 0: '' },
    },
    uploadChannelsDialogVisible: false,
    uploadResults: {},
    uploadProspectsResults: {
      successfullUploads: [],
      successfullPartially: [],
      duplicatesUploads: [],
      errorUploads: [],
    },
    addToProspectsResults: {
      successfullUploads: [],
      excludedHubspot: [],
      excludedProspect: [],
      excludedBlacklist: [],
      errorUploads: [],
    },
    hubspotImportResults: {
      totalCreated: [],
      errorsOccurred: [],
    },
    uploadProspectsError: '',
    creatorsGridCreated: new Date(),
    watchlists: [],
    watchlistsForGrid: [],
    savedSearchesOptions: [],
    savedSearchesForGrid: [],
    prospectUploadsSavedSearchesForGrid: [],
    prospectsForGrid: [],
    brandSafetySearchTerms: [],
    extensionVersion: '',
    adminUsers: [],
    adminUsersAll: [],
    prospectTubularStatistics: {},
    currentLeadId: '',
    currentDealOwnerId: '',
    currentCpmProspectId: '',
    currentCpmProspectName: '',
    currentCpmProspectHsDealId: '',
  },
  reducers: {
    // setLoading(state, action) {
    //   state.loading.formulas = action.payload;
    // },
    getFormulasStart(state) {
      state.loading = {
        ...state.loading,
        formulas: true,
      };
    },
    getFormulasSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        formulas: false,
      };
      state.formulas = data.records;
      state.formulaTokens = data.tokens;
    },
    getFormulaByIdSuccess(state, action) {
      state.currentFormula = action.payload.data;
    },
    getFormulasError(state) {
      state.loading = {
        ...state.loading,
        formulas: false,
      };
    },
    saveFormulaStart(state) {
      state.loading = {
        ...state.loading,
        saveFormula: true,
      };
    },
    saveFormulaError(state) {
      state.loading = {
        ...state.loading,
        saveFormula: false,
      };
    },
    saveFormulaSuccess(state) {
      state.loading = {
        ...state.loading,
        saveFormula: false,
      };
    },
    showFormulaDialog(state, action) {
      state.showFormulaDialog = action.payload.show;
      if (!action.payload.show) {
        state.showFormulaDialog = false;
      }
    },
    showDeleteDialog(state, action) {
      state.showDeleteDialog = action.payload.show;
      if (!action.payload.show) {
        state.showDeleteDialog = false;
      }
    },
    setCurrentLeadId(state, action) {
      state.currentLeadId = action.payload.leadId;
    },
    setCurrentCpmProspectId(state, action) {
      state.currentCpmProspectId = action.payload.cpmProspectId;
    },
    setCurrentCpmProspectName(state, action) {
      state.currentCpmProspectName = action.payload.cpmProspectName;
    },
    setCurrentCpmProspectHsDealId(state, action) {
      state.currentCpmProspectHsDealId = action.payload.cpmProspectHsDealId;
    },
    setCurrentDealOwnerId(state, action) {
      state.currentDealOwnerId = action.payload.dealOwnerId;
    },
    doSearchStart(state) {
      state.loading = {
        ...state.loading,
        search: true,
      };
    },
    doSearchSuccess(state, action) {
      const { data, type, pageNumber } = action.payload;
      state.loading = {
        ...state.loading,
        search: false,
      };
      state.searchMetadata = {
        scrollToken: data.scroll_token,
        total: data.total,
      };
      state.searchTokens[type][pageNumber] = data.scroll_token;

      let key = 'creators';
      if (type === 'video') {
        key = 'videos';
      } else if (type === 'podcast') {
        key = 'results';
      }

      state.searchResults = data[key];
    },
    doSearchError(state) {
      state.loading = {
        ...state.loading,
        search: false,
      };
    },
    fetchTubularVideoFacetsStart(state) {
      state.loading = {
        ...state.loading,
        videoFacets: true,
      };
    },
    fetchTubularVideoFacetsSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        videoFacets: false,
      };
      state.videoFacetsResults = data.facets;
    },
    fetchTubularVideoFacetsError(state) {
      state.loading = {
        ...state.loading,
        videoFacets: false,
      };
    },
    fetchTubularCreatorFacetsStart(state) {
      state.loading = {
        ...state.loading,
        creatorFacets: true,
      };
    },
    fetchTubularCreatorFacetsSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        creatorFacets: false,
      };
      state.creatorFacetsResults = data.facets;
    },
    fetchTubularCreatorFacetsError(state) {
      state.loading = {
        ...state.loading,
        creatorFacets: false,
      };
    },
    loadProspectTubularStatisticsStart(state) {
      state.loading = {
        ...state.loading,
        prospectTubularStatistics: true,
      };
    },
    loadProspectTubularStatisticsSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        prospectTubularStatistics: false,
      };
      state.prospectTubularStatistics = data;
    },
    loadProspectTubularStatisticsError(state) {
      state.loading = {
        ...state.loading,
        prospectTubularStatistics: false,
      };
    },
    setResultLoading(state, action) {
      const { item, result } = action.payload;
      const updatedArr = [];
      state.searchResults.map((resultItem) => {
        if (typeof resultItem.creator_id !== 'undefined' && resultItem.creator_id === item) {
          resultItem.loading = result;
        }
        if (typeof resultItem.publisher !== 'undefined' && typeof resultItem.publisher.creator_id !== 'undefined' && resultItem.publisher.creator_id === item) {
          resultItem.loading = result;
        }
        updatedArr.push(resultItem);
        return true;
      });
      state.searchResults = updatedArr;
    },
    saveChannel(state, action) {
      const { item } = action.payload;
      const updatedArr = [];
      state.searchResults.map((resultItem) => {
        if (resultItem.creator_id === item.creator_id) {
          resultItem.saved = true;
        }
        updatedArr.push(resultItem);
        return true;
      });
      state.searchResults = updatedArr;
    },
    doClearSearchResults(state) {
      state.searchResults = [];
      state.searchMetadata = {
        total: 0,
      };
      state.searchTokens = {
        creator: { 0: '' },
        video: { 0: '' },
        podcast: { 0: '' },
      };
    },
    uploadChannelsStart(state) {
      state.loading = {
        ...state.loading,
        uploadChannels: true,
      };
    },
    uploadChannelsSuccess(state, action) {
      const {
        successfullUploads,
        errorChannels,
        totalProcessed,
        existRecords,
      } = action.payload;
      state.loading = {
        ...state.loading,
        uploadChannels: false,
      };
      state.uploadResults = {
        success: successfullUploads,
        errors: errorChannels,
        existRecords,
        total: totalProcessed,
      };
    },
    uploadChannelsError(state) {
      state.loading = {
        ...state.loading,
        uploadChannels: false,
      };
    },
    uploadProspectsStart(state) {
      state.loading = {
        ...state.loading,
        uploadProspects: true,
        uploadResults: {},
      };
      state.uploadProspectsError = '';
    },
    uploadProspectsSuccess(state, action) {
      const {
        successfullUploads,
        successfullPartially,
        duplicatesUploads,
        errorUploads,
      } = action.payload;
      state.loading = {
        ...state.loading,
        uploadProspects: false,
      };
      state.uploadProspectsResults = {
        successfullUploads,
        successfullPartially,
        duplicatesUploads,
        errorUploads,
      };
    },
    uploadProspectsError(state, action) {
      state.loading = {
        ...state.loading,
        uploadProspects: false,
      };
      state.uploadProspectsError = action.payload;
    },
    uploadChannelsToProspectsStart(state) {
      state.loading = {
        ...state.loading,
        addToProspects: true,
      };
    },
    uploadChannelsToProspectsSuccess(state, action) {
      const {
        successfullUploads,
        excludedHubspot,
        excludedProspect,
        excludedBlacklist,
        errorUploads,
        identity,
      } = action.payload;
      state.loading = {
        ...state.loading,
        addToProspects: false,
      };
      state.addToProspectsResults = {
        successfullUploads,
        excludedHubspot,
        excludedProspect,
        excludedBlacklist,
        errorUploads,
        identity,
      };
    },
    uploadChannelsToProspectsError(state) {
      state.loading = {
        ...state.loading,
        addToProspects: false,
      };
    },
    hubspotImportStart(state) {
      state.loading = {
        ...state.loading,
        hubspotImport: true,
      };
    },
    hubspotImportSuccess(state, action) {
      const {
        totalCreated,
        errorsOccurred,
      } = action.payload;
      state.loading = {
        ...state.loading,
        hubspotImport: false,
      };
      state.hubspotImportResults = {
        totalCreated,
        errorsOccurred,
      };
    },
    hubspotImportError(state) {
      state.loading = {
        ...state.loading,
        hubspotImport: false,
      };
    },
    clearHubspotImportResultsLog(state) {
      state.hubspotImportResults = {
        totalCreated: [],
        errorsOccurred: [],
      };
    },
    saveUploadChannelsDialogVisible(state, action) {
      const { visible } = action.payload;
      state.uploadChannelsDialogVisible = visible;
    },
    updateCreatorsGrid(state) {
      state.creatorsGridCreated = new Date();
    },
    refetchDataStart(state) {
      state.loading = {
        ...state.loading,
        refetchData: true,
      };
    },
    refetchDataSuccess(state) {
      state.loading = {
        ...state.loading,
        refetchData: false,
      };
    },
    refetchDataError(state) {
      state.loading = {
        ...state.loading,
        refetchData: false,
      };
    },
    getMyWatchlistsStart(state) {
      state.loading = {
        ...state.loading,
        watchlists: true,
      };
    },
    getMyWatchlistsError(state) {
      state.loading = {
        ...state.loading,
        watchlists: false,
      };
    },
    getMyWatchlistsSuccess(state, action) {
      const { data } = action.payload;
      state.watchlists = data;
      state.loading = {
        ...state.loading,
        watchlists: false,
      };
    },
    getMyWatchlistsForGridStart(state) {
      state.loading = {
        ...state.loading,
        watchlistsForGrid: true,
      };
    },
    getMyWatchlistsForGridError(state) {
      state.loading = {
        ...state.loading,
        watchlistsForGrid: false,
      };
    },
    getMyWatchlistsForGridSuccess(state, action) {
      const { data } = action.payload;
      state.watchlistsForGrid = data;
      state.loading = {
        ...state.loading,
        watchlistsForGrid: false,
      };
    },
    getMySavedSearchesOptionsStart(state) {
      state.loading = {
        ...state.loading,
        savedSearchesOptions: true,
      };
    },
    getMySavedSearchesOptionsError(state) {
      state.loading = {
        ...state.loading,
        savedSearchesOptions: false,
      };
    },
    getMySavedSearchesOptionsSuccess(state, action) {
      const { data } = action.payload;
      state.savedSearchesOptions = data;
      state.loading = {
        ...state.loading,
        savedSearchesOptions: false,
      };
    },
    getMySavedSearchesForGridStart(state) {
      state.loading = {
        ...state.loading,
        savedSearchesForGrid: true,
      };
    },
    getMySavedSearchesForGridError(state) {
      state.loading = {
        ...state.loading,
        savedSearchesForGrid: false,
      };
    },
    getMySavedSearchesForGridSuccess(state, action) {
      const { data } = action.payload;
      state.savedSearchesForGrid = data;
      state.loading = {
        ...state.loading,
        savedSearchesForGrid: false,
      };
    },
    getProspectUploadsSavedSearchesForGridStart(state) {
      state.loading = {
        ...state.loading,
        prospectUploadsSavedSearchesForGrid: true,
      };
    },
    getProspectUploadsSavedSearchesForGridError(state) {
      state.loading = {
        ...state.loading,
        prospectUploadsSavedSearchesForGrid: false,
      };
    },
    getProspectUploadsSavedSearchesForGridSuccess(state, action) {
      const { data } = action.payload;
      state.prospectUploadsSavedSearchesForGrid = data;
      state.loading = {
        ...state.loading,
        prospectUploadsSavedSearchesForGrid: false,
      };
    },
    getBrandSafetySearchTermsStart(state) {
      state.loading = {
        ...state.loading,
        brandSafetySearchTerms: true,
      };
    },
    getBrandSafetySearchTermsError(state) {
      state.loading = {
        ...state.loading,
        brandSafetySearchTerms: false,
      };
    },
    getBrandSafetySearchTermsSuccess(state, action) {
      const { data } = action.payload;
      state.brandSafetySearchTerms = data;
      state.loading = {
        ...state.loading,
        brandSafetySearchTerms: false,
      };
    },
    updateBrandSafetySearchTermsStart(state) {
      state.loading = {
        ...state.loading,
        brandSafetySearchTerms: true,
      };
    },
    updateBrandSafetySearchTermsError(state) {
      state.loading = {
        ...state.loading,
        brandSafetySearchTerms: false,
      };
    },
    updateBrandSafetySearchTermsSuccess(state, action) {
      const { data } = action.payload;
      state.brandSafetySearchTerms = data;
      state.loading = {
        ...state.loading,
        brandSafetySearchTerms: false,
      };
    },
    updateResearchSearchGrid(state, action) {
      const { grid } = action.payload;
      state.searchResults = grid;
    },
    getExtensionVersionStart(state) {
      state.loading = {
        ...state.loading,
        extensionData: true,
      };
    },
    getExtensionVersionError(state) {
      state.loading = {
        ...state.loading,
        extensionData: false,
      };
    },
    getExtensionVersionSuccess(state, action) {
      const { data } = action.payload;
      state.extensionVersion = data.version;
      state.loading = {
        ...state.loading,
        extensionData: false,
      };
    },
    getAdminUsersStart(state) {
      state.loading = {
        ...state.loading,
        prospectsGrid: true,
      };
    },
    getAdminUsersError(state) {
      state.loading = {
        ...state.loading,
        prospectsGrid: false,
      };
    },
    getAdminUsersSuccess(state, action) {
      const { data } = action.payload;
      state.adminUsers = data;
      state.loading = {
        ...state.loading,
        prospectsGrid: false,
      };
    },
    getAdminUsersAllSuccess(state, action) {
      const { data } = action.payload;
      state.adminUsersAll = data;
    },
    formulaTokens: {},
  },
});
export const {
  // setLoading,
  getFormulasStart,
  getFormulasSuccess,
  getFormulasError,
  saveFormulaStart,
  saveFormulaSuccess,
  saveFormulaError,
  getFormulaByIdSuccess,
  showFormulaDialog,
  showDeleteDialog,
  setCurrentLeadId,
  setCurrentCpmProspectId,
  setCurrentCpmProspectName,
  setCurrentCpmProspectHsDealId,
  setCurrentDealOwnerId,
  doSearchStart,
  doSearchSuccess,
  doSearchError,
  setResultLoading,
  saveChannel,
  doClearSearchResults,
  uploadChannelsStart,
  uploadChannelsSuccess,
  uploadChannelsError,
  uploadProspectsStart,
  uploadProspectsSuccess,
  uploadProspectsError,
  uploadChannelsToProspectsStart,
  uploadChannelsToProspectsSuccess,
  uploadChannelsToProspectsError,
  saveUploadChannelsDialogVisible,
  hubspotImportStart,
  hubspotImportSuccess,
  hubspotImportError,
  updateCreatorsGrid,
  refetchDataStart,
  refetchDataSuccess,
  refetchDataError,
  getMyWatchlistsStart,
  getMyWatchlistsError,
  getMyWatchlistsSuccess,
  getMyWatchlistsForGridStart,
  getMyWatchlistsForGridError,
  getMyWatchlistsForGridSuccess,
  getMySavedSearchesOptionsStart,
  getMySavedSearchesOptionsError,
  getMySavedSearchesOptionsSuccess,
  getMySavedSearchesForGridStart,
  getMySavedSearchesForGridError,
  getMySavedSearchesForGridSuccess,
  getProspectUploadsSavedSearchesForGridStart,
  getProspectUploadsSavedSearchesForGridError,
  getProspectUploadsSavedSearchesForGridSuccess,
  getBrandSafetySearchTermsStart,
  getBrandSafetySearchTermsError,
  getBrandSafetySearchTermsSuccess,
  updateBrandSafetySearchTermsStart,
  updateBrandSafetySearchTermsError,
  updateBrandSafetySearchTermsSuccess,
  getExtensionVersionStart,
  getExtensionVersionError,
  getExtensionVersionSuccess,
  fetchTubularVideoFacetsStart,
  fetchTubularVideoFacetsError,
  fetchTubularVideoFacetsSuccess,
  fetchTubularCreatorFacetsStart,
  fetchTubularCreatorFacetsError,
  fetchTubularCreatorFacetsSuccess,
  loadProspectTubularStatisticsStart,
  loadProspectTubularStatisticsError,
  loadProspectTubularStatisticsSuccess,
  updateResearchSearchGrid,
  getAdminUsersStart,
  getAdminUsersError,
  getAdminUsersSuccess,
  getAdminUsersAllSuccess,
  clearHubspotImportResultsLog,
} = ResearchReducer.actions;

const getFormulasApi = async () => {
  const api = new ApiClient();
  return api.client.get('/api/portal/research/get-formulas');
};

const getFormulaByIdApi = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/research/get-formula?id=${id}`);
};

const doSearchApi = async (type, value, filters, sortOptions, nextPageToken, pageNumber) => {
  const api = new ApiClient();
  return api.client.post(
    '/api/portal/research/do-search',
    {
      type,
      value,
      filters,
      sortOptions,
      nextPageToken,
      pageNumber,
    },
  );
};

const saveSearchItemApi = async (channelId, currentWatchlist, isEpisodes) => {
  const api = new ApiClient();
  let channelType;
  if ((`${channelId}`).indexOf('podcast_') === 0) {
    channelType = 'podcast';
    channelId = (`${channelId}`).split('podcast_').join('');
  }
  return api.client.post(
    '/api/portal/research/save-search-item',
    {
      channelId,
      channelType,
      currentWatchlist,
      isEpisodes,
    },
  );
};

const fetchTubularVideoFacetsApi = async (value, filters) => {
  const api = new ApiClient();
  return api.client.post(
    '/api/portal/research/fetch-video-facets',
    {
      value,
      filters,
    },
  );
};

const fetchTubularCreatorFacetsApi = async (value, filters) => {
  const api = new ApiClient();
  return api.client.post(
    '/api/portal/research/fetch-creator-facets',
    {
      value,
      filters,
    },
  );
};

const saveFormulaItem = async (data) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/research/save-formula', data);
};

const deleteFormulaItem = async (data) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/research/delete-formula', data);
};

const uploadChannelsApi = async (watchlistId, selectedAgent, file) => {
  const api = new ApiClient();
  const formData = new FormData();
  formData.append('watchlistId', watchlistId);
  formData.append('selectedAgent', selectedAgent);
  formData.append('file', file);
  return api.client.post('/api/portal/research/upload-channels', formData);
};

const uploadProspectsApi = async (tags, advertiserId, analystId, file, queue, identity, prospectGroupsData, scheduleDate) => {
  const { name, description } = prospectGroupsData;
  const api = new ApiClient();
  const formData = new FormData();
  formData.append('tags', JSON.stringify(tags));
  formData.append('advertiserId', advertiserId);
  formData.append('queue', queue);
  formData.append('analystId', analystId);
  formData.append('file', file);
  formData.append('identity', identity);
  formData.append('name', name);
  formData.append('description', description);
  formData.append('scheduleDate', scheduleDate);
  return api.client.post('/api/portal/research/upload-prospects', formData);
};

const hubspotImportApi = async (selectedProspectIds, includeAll, createDeals, list, query, identity) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/prospects/post-hubspot-import', {
    selectedProspectIds, includeAll, createDeals, list, query, identity,
  });
};

const uploadSearchToProspectsApi = async (data) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/research/upload-channels-to-prospects', data);
};

const uploadProspectsApiQueuing = async (tags, advertiserId, file) => {
  const api = new ApiClient();
  const formData = new FormData();
  formData.append('tags', JSON.stringify(tags));
  formData.append('advertiserId', advertiserId);
  formData.append('file', file);

  return api.client.post('/api/portal/prospects/post-upload-prospects-to-queuing', formData);
};

const refetchDataApi = async (channelIds) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/research/refetch-data', { channelIds });
};

const getMyWatchlistsApi = async (nocreatenew) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/research/get-my-watchlists${nocreatenew ? '?nocreatenew=true' : ''}`);
};

const getMyWatchlistsForGridApi = async () => {
  const api = new ApiClient();
  return api.client.get('/api/portal/research/get-my-watchlists-for-grid');
};

const getMySavedSearchesApi = async () => {
  const api = new ApiClient();
  return api.client.get('/api/portal/research/get-my-saved-searches');
};

const getMySavedSearchesForGridApi = async () => {
  const api = new ApiClient();
  return api.client.get('/api/portal/research/get-my-saved-searches-for-grid');
};

const getProspectUploadsSavedSearchesForGridApi = async () => {
  const api = new ApiClient();
  return api.client.get('/api/portal/research/get-prospect-uploads-saved-searches-for-grid');
};

const getBrandSafetySearchTermsAPI = async () => {
  const api = new ApiClient();
  return api.client.get('/api/portal/research/get-brand-safety-search-terms');
};

const getExtensionVersionAPI = async () => {
  const api = new ApiClient();
  return api.client.get('/api/portal/research/get-extension-version');
};

const getAdminUsersAPI = async (hsActiveOnly) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/prospects/get-all-admin-users?hsActiveOnly=${hsActiveOnly}`);
};

const updateBrandSafetySearchTermsAPI = async (data) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/research/set-brand-safety-search-terms', data);
};

const getProspectTubularStatisticsApi = async (prospectId) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/research/post-prospect-tubular-stats', { prospectId });
};

export const getFormulas = id => async (dispatch) => {
  try {
    dispatch(getFormulasStart());
    const response = await getFormulasApi(id);
    if (response.data) {
      dispatch(getFormulasSuccess({ data: response.data.data }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getFormulasError());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getFormulaById = id => async (dispatch) => {
  try {
    const response = await getFormulaByIdApi(id);
    if (response.data) {
      dispatch(getFormulaByIdSuccess({ data: response.data.data }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const saveFormulaForm = data => async (dispatch) => {
  try {
    dispatch(saveFormulaStart());
    const response = await saveFormulaItem(data);
    if (response.data.success) {
      dispatch(addNotice({
        message: 'Research Formula has been successfully saved',
        type: 'success',
      }));
      dispatch(saveFormulaSuccess());
    } else {
      throw new Error('There was an error while saving data, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(saveFormulaError(err));
    dispatch(addNotice({
      message: 'There was an error while saving data, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const deleteFormulaForm = id => async (dispatch) => {
  try {
    dispatch(saveFormulaStart());
    const response = await deleteFormulaItem({ id });
    if (response.data.success) {
      dispatch(addNotice({
        message: 'Research Formula has been successfully removed',
        type: 'success',
      }));
      dispatch(saveFormulaSuccess());
    } else {
      throw new Error('There was an error while removing data, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(saveFormulaError(err));
    dispatch(addNotice({
      message: 'There was an error while removing data, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const showFormulaModalDialog = value => async (dispatch) => {
  try {
    dispatch(showFormulaDialog({ show: value }));
  } catch (err) {
    dispatch(showFormulaDialog({ show: false }));
    dispatch(addNotice({
      message: 'There was an error while showing Formula dialog, Check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const showDeleteModalDialog = value => async (dispatch) => {
  try {
    dispatch(showDeleteDialog({ show: value }));
  } catch (err) {
    dispatch(showDeleteDialog({ show: false }));
    dispatch(addNotice({
      message: 'There was an error while showing Delete Formula dialog, Check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const changeCurrentLeadId = value => async (dispatch) => {
  try {
    dispatch(setCurrentLeadId({ leadId: value }));
  } catch (err) {
    dispatch(setCurrentLeadId({ leadId: '' }));
    dispatch(addNotice({
      message: 'There was an error, check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const changeCurrentCpmProspectId = value => async (dispatch) => {
  try {
    dispatch(setCurrentCpmProspectId({ cpmProspectId: value }));
  } catch (err) {
    dispatch(setCurrentCpmProspectId({ cpmProspectId: '' }));
    dispatch(addNotice({
      message: 'There was an error, check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const changeCurrentCpmProspectName = value => async (dispatch) => {
  try {
    dispatch(setCurrentCpmProspectName({ cpmProspectName: value }));
  } catch (err) {
    dispatch(setCurrentCpmProspectName({ cpmProspectName: '' }));
    dispatch(addNotice({
      message: 'There was an error, check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const changeCurrentCpmProspectHsDealId = value => async (dispatch) => {
  try {
    dispatch(setCurrentCpmProspectHsDealId({ cpmProspectHsDealId: value }));
  } catch (err) {
    dispatch(setCurrentCpmProspectHsDealId({ cpmProspectHsDealId: '' }));
    dispatch(addNotice({
      message: 'There was an error, check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const changeCurrentDealOwnerId = value => async (dispatch) => {
  try {
    dispatch(setCurrentDealOwnerId({ dealOwnerId: value }));
  } catch (err) {
    dispatch(setCurrentDealOwnerId({ dealOwnerId: '' }));
    dispatch(addNotice({
      message: 'There was an error, check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const clearHubspotImportResults = () => (dispatch) => {
  try {
    dispatch(clearHubspotImportResultsLog());
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const doTubularSearch = (type, value, filters, sortOptions, nextPageToken, pageNumber) => async (dispatch) => {
  try {
    dispatch(doSearchStart());
    const response = await doSearchApi(type, value, filters, sortOptions, nextPageToken, pageNumber);
    if (response.data) {
      if (response.data.success) {
        dispatch(doSearchSuccess({ data: response.data.data, type, pageNumber }));
      } else {
        console.log('errorFetchTubularData', response.data.error);
        throw new Error('An error occurred while fetching data. Please, try again later.');
      }
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(doSearchError());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const saveSearchItemToTheList = (items, currentWatchlist, isEpisodes = false) => async (dispatch) => {
  for (let i = 0; i < items.length; i += 1) {
    try {
      dispatch(setResultLoading({ item: items[i], result: true }));
      // eslint-disable-next-line no-await-in-loop
      const response = await saveSearchItemApi(items[i], currentWatchlist, isEpisodes);
      if (response.data.success) {
        dispatch(setResultLoading({ item: items[i], result: 'saved' }));
        setTimeout(() => { dispatch(setResultLoading({ item: items[i], result: null })); }, 3000);
        let message = `Creator "${response.data.creator?.title}" has been added to the Watchlist "${response.data.watchlist?.name}"`;
        if (response.data.creator?.platform && response.data.creator.platform === 'podcast') {
          message = `Creator "${response.data.creator.title}" has been added to the list "Podcasts Creators"`;
        }
        dispatch(addNotice({
          message,
          type: 'success',
        }));
      } else {
        if (response.data.error) {
          console.log('Error in saveSearchItemToTheList', response.data);
          throw new Error(response.data.error);
        }
        if (response.data.warning) {
          dispatch(addNotice({
            message: response.data.warning,
            type: 'warning',
          }));
          dispatch(setResultLoading({ item: items[i], result: false }));
        }
      }
    } catch (err) {
      console.log('Error in saveSearchItemToTheList', err);
      dispatch(setResultLoading({ item: items[i], result: false }));
      dispatch(addNotice({
        message: err.message,
        type: 'error',
      }));
    }
  }
  return true;
};

export const clearSearchResults = () => async (dispatch) => {
  dispatch(doClearSearchResults());
};

export const fetchTubularVideoFacets = (value, filters) => async (dispatch) => {
  try {
    dispatch(fetchTubularVideoFacetsStart());
    const response = await fetchTubularVideoFacetsApi(value, filters);
    if (response.data) {
      if (response.data.success) {
        dispatch(fetchTubularVideoFacetsSuccess({ data: response.data.data }));
      } else {
        console.log('error at fetchTubularVideoFacets()', response.data.error);
        throw new Error('An error related to Tubular restrictions occurred while loading ancillary data.');
      }
    } else {
      throw new Error('An error occurred while loading ancillary data. Check out console to see more information.');
    }
  } catch (err) {
    dispatch(fetchTubularVideoFacetsError());
    dispatch(addNotice({
      message: err.message,
      type: 'warning',
    }));
  }
};

export const fetchTubularCreatorFacets = (value, filters) => async (dispatch) => {
  try {
    dispatch(fetchTubularCreatorFacetsStart());
    const response = await fetchTubularCreatorFacetsApi(value, filters);
    if (response.data) {
      if (response.data.success) {
        dispatch(fetchTubularCreatorFacetsSuccess({ data: response.data.data }));
      } else {
        console.log('error at fetchTubularCreatorFacets()', response.data.error);
        throw new Error('An error related to Tubular restrictions occurred while loading ancillary data.');
      }
    } else {
      throw new Error('An error occurred while loading ancillary data. Check out console to see more information.');
    }
  } catch (err) {
    dispatch(fetchTubularCreatorFacetsError());
    dispatch(addNotice({
      message: err.message,
      type: 'warning',
    }));
  }
};

export const uploadChannels = (watchlistId, selectedAgent, file) => async (dispatch) => {
  try {
    dispatch(uploadChannelsStart());
    const response = await uploadChannelsApi(watchlistId, selectedAgent, file);
    if (response.data.success) {
      if (response.data.errorChannels.length > 0) {
        dispatch(addNotice({
          message: `Couldn't import the following creators: ${response.data.errorChannels.join(', ')}. Please, add them manually.`,
          type: 'error',
          duration: 300,
        }));
      }
      dispatch(uploadChannelsSuccess(response.data));
      dispatch(updateCreatorsGrid());
    } else {
      dispatch(uploadChannelsError());
      throw new Error('There was an error while saving data, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(uploadChannelsError());
    dispatch(addNotice({
      message: 'There was an error while uploading data, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const uploadProspects = (tags, advertiserId, analystId, file, queue, identity, prospectGroupsData, scheduleDate) => async (dispatch) => {
  try {
    dispatch(uploadProspectsStart());
    const response = await uploadProspectsApi(tags, advertiserId, analystId, file, queue, identity, prospectGroupsData, scheduleDate);
    if (response.data.success) {
      dispatch(uploadProspectsSuccess(response.data));
    } else {
      dispatch(uploadProspectsError(response.data?.error || ''));
      // throw new Error('There was an error while saving data, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(uploadProspectsError());
    dispatch(addNotice({
      message: 'There was an error while uploading data, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const hubspotImport = (selectedProspectIds, includeAll, createDeals, list, query, identity) => async (dispatch) => {
  try {
    dispatch(hubspotImportStart());
    const response = await hubspotImportApi(selectedProspectIds, includeAll, createDeals, list, query, identity);
    if (response.data.success) {
      dispatch(hubspotImportSuccess(response.data));
    } else {
      dispatch(hubspotImportError(response.data?.error || ''));
    }
  } catch (err) {
    dispatch(hubspotImportError());
    dispatch(addNotice({
      message: 'There was an error while importing data, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const uploadSearchToProspects = (data, setErrorMessages) => async (dispatch) => {
  try {
    dispatch(uploadChannelsToProspectsStart());
    const response = await uploadSearchToProspectsApi(data);
    if (response.data.success) {
      if (response.data.errorUploads.length > 0) {
        /* dispatch(addNotice({
          message: `Couldn't import the following creators: ${response.data.errorUploads.join(', ')}. Please, add them manually.`,
          type: 'error',
          duration: 300,
        })); */
        setErrorMessages(response.data.errorUploads);
      }
      dispatch(uploadChannelsToProspectsSuccess(response.data));
      // dispatch(updateCreatorsGrid());
    } else {
      dispatch(uploadChannelsToProspectsError());
      throw new Error('There was an error while saving data, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(uploadChannelsToProspectsError());
    dispatch(addNotice({
      message: 'There was an error while uploading data, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const uploadProspectsQueuing = (tags, advertiserId, file) => async (dispatch) => {
  try {
    dispatch(uploadProspectsStart());
    const response = await uploadProspectsApiQueuing(tags, advertiserId, file);
    if (response.data.success) {
      dispatch(uploadProspectsSuccess(response.data));
    } else {
      dispatch(uploadProspectsError(response.data?.error || ''));
      // throw new Error('There was an error while saving data, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(uploadProspectsError());
    dispatch(addNotice({
      message: 'There was an error while uploading data, Check out console to see more information.',
      type: 'error',
    }));
  }
};


export const refetchData = selectedItems => async (dispatch) => {
  try {
    dispatch(refetchDataStart());
    const response = await refetchDataApi(selectedItems);
    if (response.data.success) {
      const message = selectedItems.length === 1 && typeof response.data.lastCreatorTitle !== 'undefined' && response.data.lastCreatorTitle !== ''
        ? `Successfully re-fetched data from Tubular for the Creator "${response.data.lastCreatorTitle}"`
        : 'Successfully re-fetched data from Tubular';
      dispatch(addNotice({
        message,
        type: 'success',
      }));
      dispatch(refetchDataSuccess());
      dispatch(updateCreatorsGrid());
    } else {
      dispatch(refetchDataError());
      throw new Error('There was an error while re-fetching data, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(refetchDataError());
    dispatch(addNotice({
      message: 'There was an error while re-fetching data, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const setUploadChannelsDialogVisible = visible => async (dispatch) => {
  dispatch(saveUploadChannelsDialogVisible({ visible }));
};

export const getMyWatchlists = (nocreatenew = false) => async (dispatch) => {
  try {
    dispatch(getMyWatchlistsStart());
    const response = await getMyWatchlistsApi(nocreatenew);
    if (response.data.success) {
      dispatch(getMyWatchlistsSuccess({ data: response.data.data }));
    } else {
      dispatch(getMyWatchlistsError());
    }
  } catch (err) {
    dispatch(getMyWatchlistsError());
    dispatch(addNotice({
      message: 'There was an error while fetching list of your watchlists, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const getMyWatchlistsForGrid = () => async (dispatch) => {
  try {
    dispatch(getMyWatchlistsForGridStart());
    const response = await getMyWatchlistsForGridApi();
    if (response.data.success) {
      dispatch(getMyWatchlistsForGridSuccess({ data: response.data.data }));
    } else {
      dispatch(getMyWatchlistsForGridError());
    }
  } catch (err) {
    dispatch(getMyWatchlistsForGridError());
    dispatch(addNotice({
      message: 'There was an error while fetching list of your watchlists for grid, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const getMySavedSearches = () => async (dispatch) => {
  try {
    dispatch(getMySavedSearchesOptionsStart());
    const response = await getMySavedSearchesApi();
    if (response.data.success) {
      dispatch(getMySavedSearchesOptionsSuccess({ data: response.data.data }));
    } else {
      dispatch(getMySavedSearchesOptionsError());
    }
  } catch (err) {
    dispatch(getMySavedSearchesOptionsError());
    dispatch(addNotice({
      message: 'There was an error while fetching list of your saved searches, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const getMySavedSearchesForGrid = () => async (dispatch) => {
  try {
    dispatch(getMySavedSearchesForGridStart());
    const response = await getMySavedSearchesForGridApi();
    if (response.data.success) {
      dispatch(getMySavedSearchesForGridSuccess({ data: response.data.data }));
    } else {
      dispatch(getMySavedSearchesForGridError());
    }
  } catch (err) {
    dispatch(getMySavedSearchesForGridError());
    dispatch(addNotice({
      message: 'There was an error while fetching list of your saved searches for grid, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const getProspectUploadsSavedSearchesForGrid = () => async (dispatch) => {
  try {
    dispatch(getProspectUploadsSavedSearchesForGridStart());
    const response = await getProspectUploadsSavedSearchesForGridApi();
    if (response.data.success) {
      dispatch(getProspectUploadsSavedSearchesForGridSuccess({ data: response.data.data }));
    } else {
      dispatch(getProspectUploadsSavedSearchesForGridError());
    }
  } catch (err) {
    dispatch(getProspectUploadsSavedSearchesForGridError());
    dispatch(addNotice({
      message: 'There was an error while fetching list of Prospect Uploads saved searches for grid, Check out console to see more information.',
      type: 'error',
    }));
  }
};
export const getBrandSafetySearchTerms = () => async (dispatch) => {
  try {
    dispatch(getBrandSafetySearchTermsStart());
    const response = await getBrandSafetySearchTermsAPI();
    if (response.data.success) {
      dispatch(getBrandSafetySearchTermsSuccess({ data: response.data.data }));
    } else {
      dispatch(getBrandSafetySearchTermsError());
    }
  } catch (err) {
    dispatch(getBrandSafetySearchTermsError());
    dispatch(addNotice({
      message: 'There was an error while fetching list of brand safety search Terms, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const getExtensionVersion = () => async (dispatch) => {
  try {
    dispatch(getExtensionVersionStart());
    const response = await getExtensionVersionAPI();
    if (response.data.success) {
      dispatch(getExtensionVersionSuccess({ data: response.data }));
    } else {
      dispatch(getExtensionVersionError());
    }
  } catch (err) {
    dispatch(getExtensionVersionError());
    dispatch(addNotice({
      message: 'There was an error while fetching extension data, check out console to see more information.',
      type: 'error',
    }));
  }
};

export const getAdminUsers = hsActiveOnly => async (dispatch) => {
  try {
    dispatch(getAdminUsersStart());
    const response = await getAdminUsersAPI(hsActiveOnly);
    if (response.data.success) {
      dispatch(getAdminUsersSuccess({ data: response.data.data }));
    } else {
      dispatch(getAdminUsersError());
    }
  } catch (err) {
    dispatch(getAdminUsersError());
    dispatch(addNotice({
      message: 'There was an error while fetching list of brand safety search Terms, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const getAdminUsersAll = () => async (dispatch) => {
  try {
    const response = await getAdminUsersAPI(false);
    if (response.data.success) {
      dispatch(getAdminUsersAllSuccess({ data: response.data.data }));
    }
  } catch (err) {
    dispatch(addNotice({
      message: 'There was an error while fetching list of brand safety search Terms, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const updateBrandSafetySearchTerms = data => async (dispatch) => {
  try {
    dispatch(updateBrandSafetySearchTermsStart());
    const response = await updateBrandSafetySearchTermsAPI(data);
    if (response.data.success) {
      dispatch(updateBrandSafetySearchTermsSuccess({ data: response.data.data }));
      dispatch(addNotice({
        message: 'List of Brand Safety Search Terms was updated.',
        type: 'success',
      }));
    } else {
      dispatch(updateBrandSafetySearchTermsError());
      dispatch(addNotice({
        message: 'There was an error while updating list of brand safety search Terms, Check out console to see more information.',
        type: 'error',
      }));
    }
  } catch (err) {
    dispatch(updateBrandSafetySearchTermsError());
    dispatch(addNotice({
      message: 'There was an error while updating list of brand safety search Terms, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const updateResearchSearchRow = value => async (dispatch) => {
  try {
    dispatch(updateResearchSearchGrid({ grid: value }));
  } catch (err) {
    dispatch(addNotice({
      message: 'There was an error while updating ResearchSearch row, Check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const loadProspectTubularStatistics = prospectId => async (dispatch) => {
  try {
    dispatch(loadProspectTubularStatisticsStart());
    const response = await getProspectTubularStatisticsApi(prospectId);
    // console.log(response.data.tubularStats);
    if (response.data) {
      dispatch(loadProspectTubularStatisticsSuccess({ data: response.data.tubularStats }));
    } else {
      throw new Error('There was an error fetching statistics, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(loadProspectTubularStatisticsError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export default ResearchReducer.reducer;
