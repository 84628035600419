import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const TargetBrand = React.memo(({
  record,
}) => {
  const rowData = record.populated.approvalStatus?.brand?.targetAdvertiserId;

  return (
    <div style={{ minWidth: '120px', maxWidth: '150px' }} >
      <span>{rowData?.params?.companyName || '-- Not Assigned --'}</span>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

TargetBrand.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TargetBrand;
