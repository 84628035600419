import React from 'react';
import { Container, Row, Col, FormGroup, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { List } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import StyledButton from '../../../../shared/components/components/ui/styled-button';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../containers/App/store/with-notice';
import BrandOpportunityCardOrdering from './components/BrandOpportunityCardOrdering';

class BrandOpportunityOrdering extends React.Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.moveItem = this.moveItem.bind(this);
    this.state = this.constructor.defaultState;
  }

  componentDidMount() {
    this.loadData();
  }
  static defaultState = {
    loading: {
      saveOrdering: false,
      loadOpportunities: false,
    },
    opportunities: [],
  };

  loadData() {
    const { addNotice } = this.props;
    this.setState(state => ({
      loading: {
        ...state.loading,
        loadOpportunities: true,
      },
    }));
    axios.get('/api/portal/brands/get-opportunities').then((response) => {
      const { success, opportunities } = response.data;
      if (success) {
        this.setState(state => ({
          opportunities,
          loading: {
            ...state.loading,
            loadOpportunities: false,
          },
        }));
      } else {
        addNotice({
          message: 'An error occurred while fetching data',
          type: 'warning',
        });
      }
      this.setState(state => ({
        loading: {
          ...state.loading,
          loadOpportunities: false,
        },
      }));
    }).catch((error) => {
      console.log(error);
    });
  }
  handleSubmit(event) {
    const { opportunities } = this.state;
    const { addNotice } = this.props;
    event.preventDefault();
    this.setState(state => ({
      loading: {
        ...state.loading,
        saveOrdering: true,
      },
    }));

    axios({
      method: 'post',
      url: '/api/portal/brands/save-brand-opportunity-ordering',
      data: { opportunities },
    }).then((response) => {
      const { success } = response.data;
      if (success) {
        addNotice({
          message: 'New ordering has been saved',
          type: 'success',
        });
      } else {
        addNotice({
          message: 'An error occurred while saving ordering data',
          type: 'warning',
        });
      }
      this.setState(state => ({
        loading: {
          ...state.loading,
          saveOrdering: false,
        },
      //  filesToDelete: [],
      }));
    }).catch((error) => {
      console.log(error);
      this.setState(state => ({
        loading: {
          ...state.loading,
          saveOrdering: false,
        },
      }));
    });
    return false;
  }

  moveItem(dragIndex, hoverIndex) {
    const { opportunities } = this.state;
    // eslint-disable-next-line array-callback-return
    opportunities.map((file, index) => {
      if (opportunities[index].order >= dragIndex && opportunities[index].order <= hoverIndex) {
        opportunities[index].order -= 1;
      } else if (opportunities[index].order <= dragIndex && opportunities[index].order >= hoverIndex) {
        opportunities[index].order += 1;
      }
    });
    opportunities[dragIndex].order = hoverIndex;
    opportunities.sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
    this.setState({
      opportunities,
    });
  }

  renderOpportunity(opportunity, index) {
    return (
      <BrandOpportunityCardOrdering
        key={`opportunity-${index}`}
        opportunity={opportunity}
        index={index}
        moveItem={this.moveItem}
      />
    );
  }

  render() {
    const {
      loading,
      opportunities,
    } = this.state;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs
              isBackButton
              links={[
                { title: 'Home', path: '/' },
                { title: 'Brand Opportunities', path: '/resources/BrandOpportunity/actions/list' },
                { title: 'Change Order', path: false },
              ]}
            />
          </Col>
          <Col>
            <h3>Change Order of Brand Opportunities</h3>
            {loading.loadOpportunities &&
            (
              <div className="text-center"><Spinner color="primary" size="lg" /></div>
            )
            }
            {!loading.loadOpportunities && (
              <Row>
                <Col md={12} style={{ padding: '0 30px' }}>
                  <DndProvider backend={HTML5Backend}>
                    <List>
                      {(opportunities && opportunities.length > 0) && opportunities.map((opportunity, index) => this.renderOpportunity(opportunity, index))}
                    </List>
                  </DndProvider>
                  <FormGroup style={{ marginBottom: 0 }}>
                    <StyledButton
                      type="button"
                      onClick={this.handleSubmit}
                      disabled={loading.saveOrdering}
                      className="is-primary"
                      style={{ marginTop: 20 }}
                    >
                      {loading.saveOrdering && (<><Spinner color="primary" size="sm" />&nbsp;&nbsp;</>)}
                      <i className="icomoon-save" />
                      <span className="btn-text">Save</span>
                    </StyledButton>
                    <StyledButton
                      type="button"
                      className="is-primary btn-danger"
                      onClick={() => { window.history.back(); }}
                      style={{ marginTop: 20 }}
                    >
                      <span className="btn-text">Cancel</span>
                    </StyledButton>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

BrandOpportunityOrdering.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(BrandOpportunityOrdering);
