// import moment from 'moment';
import { unflatten } from 'flat';
import func from '../../../../../../shared/hooks';

export default () => [
  {
    initialState: {
      Creator: true,
      Agent: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'userType',
  },
  {
    initialState: {
      'Needs Review': true,
      'Approved': false,
      'Expired': false,
      'Rejected': false,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'approvalStatus',
  },

  {
    initialState: {
      billCom: true,
      stripe: true,
      notAssigned: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'paymentMethod',
  },

  {
    initialState: {
      'W-9': true,
      'W-8BEN': true,
      'W-8BEN-E': true,
      'W-8ECI': true,
      'W-8IMY': true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'taxFormType',
  },
  // {
  //   initialState: { tags: [] },
  //   func: func.getFromUrlSelectfunction,
  //   /* ({ filters, property }) => {
  //     let items = (Array.isArray(filters?.contentReleaseDateMonth) ? filters?.contentReleaseDateMonth : [filters?.contentReleaseDateMonth]);
  //     items = filters?.contentReleaseDateMonth ? items : [];
  //     console.log(property, filters, 'property');
  //     const tags = items?.map((tag) => {
  //       const prop = property.options.find(a => a.value === tag);
  //       if (!prop) return null;
  //       return { value: prop.value, label: prop.label };
  //     }).filter(a => a) || [];
  //     return { tags };
  //   }, */
  //   funcToUrl: func.getToUrlSelectfunction,
  //   /* ({ search, item }) => {
  //     search.delete('filters.contentReleaseDateMonth');
  //     item?.tags?.forEach((a) => {
  //       search.append('filters.contentReleaseDateMonth', a.value);
  //     });
  //     return search;
  //   }, */
  //   options: brandOptions,
  //   filterName: 'brand',
  // },

  // {
  //   initialState: {
  //     enable: false,
  //   },
  //   func: func.getFromUrlCheckboxFunction,
  //   funcToUrl: func.getToUrlCheckboxFunction,
  //   filterName: 'enableYearSigned',
  // },
  // {
  //   initialState: {
  //     tags: yearSignedFilterTypeOptions.filter(a => ['between'].includes(a.label.toLowerCase()))
  //   },
  //   func: func.getFromUrlSelectfunction,
  //   filterName: 'yearSignedFilterType',
  //   funcToUrl: func.getToUrlSelectfunction,
  //   options: yearSignedFilterTypeOptions,
  // },
  // {
  //   initialState: { from: null, to: null },
  //   func: ({ filters }) => {
  //     const from = filters['yearSigned.from'] ? (filters['yearSigned.from']) : null;
  //     const to = filters['yearSigned.to'] ? (filters['yearSigned.to']) : null;
  //     return { from, to };
  //   },
  //   funcToUrl: ({ search, item }) => {
  //     if (item.from) {
  //       search.set('filters.yearSigned.from', item.from);
  //     } else {
  //       search.delete('filters.yearSigned.from');
  //     }
  //     if (item.to) {
  //       search.set('filters.yearSigned.to', item.to);
  //     } else {
  //       search.delete('filters.yearSigned.to');
  //     }
  //     return search;
  //   },
  //   filterName: 'yearSigned',
  // },
  {
    initialState: {
      enable: false,
      filterType: 'Between',
      ysFrom: 0,
      ysTo: 0,
    },
    func: ({ initialState: IS, filters: f, filterName }) => {
      const filters = unflatten(f);
      console.log({ initialState: IS, filters, filterName }, 'INITIAL');
      const init = { ...(IS || {}) };
      if (filters[filterName]?.enable) init.enable = String(filters[filterName].enable) === 'true'
      if (filters[filterName]?.filterType) init.filterType = String(filters[filterName].filterType)
      if (filters[filterName]?.amountFrom) init.amountFrom = Number(filters[filterName].amountFrom)
      if (filters[filterName]?.amountTo) init.amountTo = Number(filters[filterName].amountTo)
      console.log('return INIT', init)
      return init;
    },
    funcToUrl: ({ search, item, filterName }) => {
      console.log(item, "itemitemitemitemitem")
      search.delete(`filters.${filterName}.enable`);
      search.delete(`filters.${filterName}.filterType`);
      search.delete(`filters.${filterName}.amountFrom`);
      search.delete(`filters.${filterName}.amountTo`);

      if (item.enable) search.set(`filters.${filterName}.enable`, String(item.enable))
      if (item.filterType && item.enable) search.set(`filters.${filterName}.filterType`, String(item.filterType))
      if (typeof item.amountFrom !== 'undefined' && item.enable) search.set(`filters.${filterName}.amountFrom`, String(item.amountFrom))
      if (typeof item.amountTo !== 'undefined' && item.enable) search.set(`filters.${filterName}.amountTo`, String(item.amountTo))
      return search;
    },
    filterName: 'yearSigned',
  },

];
