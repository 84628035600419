import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import withNotice from '../../../../App/store/with-notice';
import CopyToClipboard from '../../../../../shared/components/CopyToClipboard';

const StripeCreatorProfile = ({
    accountId,
  }) => {
  const [loading, setLoading] = useState(true);
  const [vendor, setVendor] = useState({});
  console.log(accountId, 'accountId');
  useEffect(async () => {
    if (!accountId) return false;
    setLoading(true);
    const data = await axios.get(`/api/portal/finance/get-stripe-account-data?accountId=${accountId}`);
    console.log('DATA', data);

    if (data.data.success === true) setVendor(data.data.account);
    // console.log(vendor);
    setLoading(false);
    return true;
  }, [accountId]);

  const result = useMemo(() => {
    let r = '';
    if (vendor?.email?.length > 11) {
      r = vendor?.email?.slice(0, 11);
      r += '...';
    } else {
      r = vendor?.email;
    }
    return r;
  }, [vendor.email]);

  return !loading ? (
    <div >
      <div>{vendor.business_profile?.name || ''}</div>
      <div>
        <CopyToClipboard value={vendor.email || ''}>
          <a className="il-text-gray" href={`mailto:${vendor.email}`}>
            {result}
          </a>
        </CopyToClipboard>
      </div>
    </div>
  ) : null;
};

StripeCreatorProfile.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default withNotice(StripeCreatorProfile);
