import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import formater from '../../../../shared/helpers/WVFormatter';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';

const BoxedText = ({
  // eslint-disable-next-line react/prop-types
  value, titleText,
}) => (
  <Col
    style={{
          padding: '15px 15px',
          border: '1px solid #cccccc',
          borderRadius: '5px',
        }}
    lg="2"
    md="4"
    xs="12"
    className="text-center"
  >
    <span style={{ textAlign: 'center', color: 'black' }} >
      <span
        style={{
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '12px',
                  width: '100%',
                  textAlign: 'center',
                }}
      >
        {value}
      </span>
      <br style={{ lineHeight: '50%' }} />
      <span style={{ width: '100%', fontSize: '16px' }}>{titleText}</span>
    </span>
  </Col>
);

const SummaryReport = ({
  reportData, loading, fetched, advertisers,
}) => {
  const reports = useMemo(() => {
    const stats = {};
    reportData.forEach((r) => {
      if (!stats[r.conversion.advertiser_id]) {
        const adv = advertisers.find(a => a.tuneId?.toString() === r.conversion.advertiser_id?.toString());
        stats[r.conversion.advertiser_id] = { adversiser: adv, count: 0, activeCount: 0 };
      }
      if (r.isActive) {
        stats[r.conversion.advertiser_id].activeCount += 1;
      } else if (!r.isActive) {
        stats[r.conversion.advertiser_id].count += 1;
      }
    });
    return Object.values(stats);
  }, [reportData]);

  const columnsBrands = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: deal => deal.adversiser?.label || '',
        id: 'Brand',
        Cell: ({ value }) => value || '',
        width: 250,
      },
      {
        Header: 'Active Creators',
        accessor: deal => Number((deal.activeCount || 0)),
        id: 'activeCount',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalActive = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.activeCount) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalActive)}</span>;
        },
        width: 200,
      },
      {
        Header: 'New Creators',
        accessor: deal => Number((deal.count || 0)),
        id: 'leads',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalNew = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.leads) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalNew)}</span>;
        },
        width: 200,
      },
      {
        Header: 'New Creator Percentage',
        accessor: deal => Number((deal.count || 0)) / Number((deal.activeCount || 1)),
        id: 'newPercentage',
        Cell: ({ value }) => formater.formatIntNumberAndAddPercentSign(value),
        Footer: (info) => {
          const totalNew = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.leads) + sum, 0),
            [info.rows],
          );
          const totalActive = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.activeCount) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumberAndAddPercentSign(totalNew / totalActive)}</span>;
        },
        width: 200,
      },
    ],
    [advertisers],
  );
  const getTotal = useMemo(() => {
    let newCount = 0;
    let activeCount = 0;
    for (let i = 0; i < reportData.length; i += 1) {
      if (reportData[i].isActive) {
        activeCount += 1;
      } else {
        newCount += 1;
      }
    }
    const percent = (newCount / (activeCount || 1));
    return { newCount, activeCount, percent };
  }, [reportData]);

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    useFlex: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'Brand',
    direction: 'asc',
  };
  return (
    <div>
      {!loading && fetched ? (
        <Row style={{ margin: '10px', justifyContent: 'space-evenly' }}>
          <BoxedText
            value={formater.formatIntNumber(getTotal.activeCount)}
            titleText="Active Creators"
          />
          <BoxedText
            value={formater.formatIntNumber(getTotal.newCount)}
            titleText="New Creators"
          />
          <BoxedText
            value={formater.formatIntNumberAndAddPercentSign(getTotal.percent)}
            titleText="New Creator Percentage"
          />
        </Row>) : null}
      {!loading && fetched ? (
        <Row>
          <Col>
            <Row>
              <Col className="mt-3">
                {reportData.length > 0 ? (
                  <div className="float-right mr-2">
                    <DownloadCSVButton
                      records={reports}
                      className="mt-2"
                      fileName="LeadsPerformance.csv"
                      csvArrayColumns={[
                        {
                          label: 'Brand',
                          func: deal => deal.adversiser?.label || '',
                        },
                        {
                          label: 'Active Creators',
                          func: deal => formater.formatIntNumber(Number((deal.activeCount || 0))),
                        },
                        {
                          label: 'New Creators',
                          func: deal => formater.formatIntNumber(Number((deal.count || 0))),
                        },
                      ]}
                    >
                      Download CSV
                    </DownloadCSVButton>
                  </div>
                ) : null}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="mt-3">
                {!loading && fetched ? (
                  <ReactTableBase
                    key="searchable2"
                    columns={columnsBrands}
                    data={reports}
                    tableConfig={tableConfig}
                    isSaved
                  />) : null
                }
              </Col>
            </Row>
          </Col>
        </Row>) : null
      }
    </div>
  );
};
SummaryReport.propTypes = {
  reportData: PropTypes.arrayOf(PropTypes.any).isRequired,
  // initialData: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  fetched: PropTypes.bool.isRequired,
  advertisers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withNotice(SummaryReport);
