/* eslint react/prop-types: 0 */
import React from 'react';
import styled from 'styled-components';
// import { PropertyPlace } from 'admin-bro/lib/backend/decorators/property-json.interface';
import ActionButton from '../action-button';
import PropertyType from '../../property-type';
import Dropdown from '../../ui/dropdown';
import Placeholder from '../../ui/placeholder';
import theme from '../../../../../theme';

const Td = styled.td`
  &&& {
    color: ${theme.colors.defaultText};
    & a:not(.in-dropdown) {
      color: ${theme.colors.primary};
    }
    &.main {
      font-weight: bold;
    }
    &.selected {
      border-left: ${theme.sizes.paddingMin} ${theme.colors.primary} solid;
    }
    &.not-selected {
      border-left: ${theme.sizes.paddingMin} solid transparent;
    }
  }
`;

export default class RecordInList extends React.PureComponent {
  render() {
    const {
      resource, record,
      actionPerformed, isLoading,
      onSelect, isSelected,
    } = this.props;
    const { recordActions } = record;
    return (
      <tr>
        <Td className={isSelected ? 'selected' : 'not-selected'}>
          {record.bulkActions.length ? (
            <input
              type="checkbox"
              onChange={() => onSelect(record)}
              checked={isSelected}
            />
          ) : null}
        </Td>
        {resource.listProperties.map(property => (
          <Td key={property.name} className={resource.titleProperty.name === property.name ? 'main' : undefined}>
            {isLoading ? (
              <Placeholder style={{ height: 14 }} />
            ) : (
              <PropertyType
                key={property.name}
                where="list"
                property={property}
                resource={resource}
                record={record}
              />
            )}
          </Td>
        ))}
        <Td key="options">
          {recordActions.length ? (
            <Dropdown className="is-right is-hoverable">
              {recordActions.map(action => (
                <ActionButton
                  action={action}
                  key={action.name}
                  resourceId={resource.id}
                  recordId={record.id}
                  actionPerformed={actionPerformed}
                  className="is-white in-dropdown"
                />
              ))}
            </Dropdown>
          ) : ''}
        </Td>
      </tr>
    );
  }
}
