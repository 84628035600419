import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

const RejectionReason = React.memo(({
  record,
  setShowNotesModal,
  setCurrentProspect,
  setDisableNote,
  setPortal,
}) => {
  const rowData = record.params;
  const val = (typeof rowData !== 'undefined' && typeof rowData.rejectionReason?.value !== 'undefined') ? rowData.rejectionReason?.value : '';
  const notesVal = (typeof rowData !== 'undefined' && typeof rowData.rejectionReason?.notes !== 'undefined') ? rowData.rejectionReason?.notes : '';
  const [value, setValue] = useState(val);

  useEffect(() => {
    setValue(val);
  }, [record.id, val]);
  if (!val || val === '') return '-';

  return (
    <div className="text-center">
      {value && (
        <span >{value}</span>
      )}
      {' '}
      <div style={{ float: 'right', marginLeft: '10px' }}>
        <Tooltip title="Rejection Reason Notes">
          <IconButton
            size="small"
            onClick={() => { setPortal('rejectionReason'); setDisableNote(true); setCurrentProspect(record); setShowNotesModal(true); }}
          >
            <FontAwesomeIcon icon={[notesVal !== '' ? 'fas' : 'far', 'file-alt']} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

RejectionReason.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setShowNotesModal: PropTypes.func.isRequired,
  setCurrentProspect: PropTypes.func.isRequired,
  setDisableNote: PropTypes.func.isRequired,
  setPortal: PropTypes.func.isRequired,
};
export default RejectionReason;
