import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';
import SettingsListContainer from './components/SettingsListContainer';

const SettingsList = () => (
  <Container className="dashboard">
    <Breadcrumb
      links={[
        { title: 'Home', path: '/' },
        { title: 'Research', path: null },
        { title: 'Settings', path: null },
      ]}
      isBackButton
    />
    <Row>
      <Col md={12}>
        <SettingsListContainer />
      </Col>
    </Row>
  </Container>
);

export default SettingsList;
