import React, { useMemo, useState, useEffect } from 'react';
import { Modal, ButtonToolbar, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select';
// import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import ButtonWithSpinner from '../../../../..//shared/components/ButtonWithSpinner';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';


const ChartOfAccountModal = ({
  modal,
  loading,
  closeDialog,
  billComPayables,
  submitDialog,
  type,
  // title,
}) => {
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    flex: true,
  };
  const [selectedAccounts, setSelectedAccounts] = useState({});
  const [chartsOfAccounts, setChartsOfAccounts] = useState([]);
  const [showError, setShowError] = useState(false);
  const [load, setLoad] = useState(false);
  useEffect(async () => {
    const chartsOfAccountsRes = await axios.get('/api/portal/finance/get-charts-of-accounts');
    setChartsOfAccounts(chartsOfAccountsRes.data?.data.map(d => ({ ...d, label: d.title })) || []);
  }, []);
  useEffect(async () => {
    const sValues = { ...selectedAccounts };
    billComPayables.forEach((p) => {
      const creatorAccount = p.populated?.influencer?.params?.payment.billCom.isDefault
        ? p.populated?.influencer?.params?.payment.billCom.chartOfAccount
        : p.populated?.influencer?.params?.payment.stripe.chartOfAccount;
      const agentAccount = p.populated?.influencer?.params?.payment.billCom.isDefault
        ? p.populated?.agent?.params?.payment.billCom.chartOfAccount
        : p.populated?.agent?.params?.payment.stripe.chartOfAccount;
      const defaultChartOfAcount = p.populated?.influencer?.params?.payment.enablePayments
        ? creatorAccount
        : agentAccount;
      if (defaultChartOfAcount) {
        sValues[p.id] = defaultChartOfAcount;
      }
    });
    setSelectedAccounts(sValues);
  }, [billComPayables]);

  const submit = () => {
    if (Object.keys(selectedAccounts).length !== billComPayables.length) {
      setShowError(true);
    } else {
      setShowError(false);
      setLoad(true);
      submitDialog(selectedAccounts);
    }
  };
  const columns = useMemo(
    () => (type !== 'agent' ? [
      {
        Header: 'Creator Name',
        accessor: record => `${record?.populated?.influencer?.params?.first_name} ${record?.populated?.influencer?.params?.last_name}`,
        Cell: p => useMemo(() => (
          <Tooltip title="Open Creator Profile">
            <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.populated.influencer.params._id}`}>{p.value}</a>
          </Tooltip>), [p.row.original, p.value]),
      },
      {
        Header: 'Agent Name',
        accessor: record => (record?.populated?.agent ? `${record?.populated?.agent?.params?.first_name || ''} ${record?.populated?.agent?.params?.last_name || ''}` : null),
        Cell: p => p.value,
      },
      {
        Header: 'Country Code',
        accessor: record => (record.populated?.influencer?.params?.payment.enablePayments
          ? (record?.populated?.influencer?.params?.payment?.countryCode || '')
          : (record?.populated?.agent?.params?.payment?.countryCode || '')),
        Cell: p => p.value,
      },
      {
        Header: 'Chart of Account',
        accessor: record => record.id || '',
        Cell: (p) => {
          const selectedValue = selectedAccounts[p.value] ? selectedAccounts[p.value] : null;
          // console.log(selectedValue);
          return (<Select
            menuPortalTarget={document.body}
            value={chartsOfAccounts.find(c => c.value === selectedValue)}
            options={chartsOfAccounts}
            onChange={({ value }) => setSelectedAccounts({ ...selectedAccounts, [p.value]: value })}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />);
        },
      },
    ] : [
      {
        Header: 'Agent Name',
        accessor: record => (record?.populated?.agent ? `${record?.populated?.agent?.params?.first_name || ''} ${record?.populated?.agent?.params?.last_name || ''}` : null),
        Cell: p => p.value,
      },
      {
        Header: 'Chart of Account',
        accessor: record => record.id || '',
        Cell: (p) => {
          const selectedValue = selectedAccounts[p.value] ? selectedAccounts[p.value] : null;
          // console.log(selectedValue);
          return (<Select
            value={chartsOfAccounts.find(c => c.value === selectedValue)}
            options={chartsOfAccounts}
            onChange={({ value }) => setSelectedAccounts({ ...selectedAccounts, [p.value]: value })}
          />);
        },
      },
    ]),
    [chartsOfAccounts, selectedAccounts],
  );
  console.log(selectedAccounts);
  console.log(billComPayables);
  return (
    <Modal
      isOpen={modal}
      toggle={closeDialog}
      size="lg"
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">Specify Chart Of Accounts</h4>
      </div>
      <div className="p-2 theme-light">
        <Alert color="danger" className="alert--neutral" isOpen={!!showError}>
          <p><span className="bold-text">Warning!</span> Please fill all chart of account fields</p>
        </Alert>
        <ReactTableBase
          key="searchable"
          columns={columns}
          data={billComPayables}
          tableConfig={tableConfig}
        />
      </div>
      {!loading && (
      <ButtonToolbar className="modal__footer">
        <ButtonWithSpinner
          size="sm"
          color="primary"
          onClick={submit}
          loading={load}
          className="mr-2"
        >
          Save
        </ButtonWithSpinner>
        <Button className="modal_cancel btn-sm" color="danger" onClick={closeDialog}>Close</Button>
      </ButtonToolbar>
        )
      }
    </Modal>
  );
};
ChartOfAccountModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  submitDialog: PropTypes.func.isRequired,
  billComPayables: PropTypes.arrayOf(PropTypes.shape).isRequired,

};
export default ChartOfAccountModal;
