import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Button,
  Label,
} from 'reactstrap';
import _ from 'lodash';
import { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import IntervalDatePickerField from '../../../../../Finance/Bill/components/custom/IntervalDatePicker';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import { getCookie, setCookie } from '../../../../../../../shared/helpers/WVUtilities';

const LogsActions = React.memo(({
  onSearchChange,
  searchValue,
  statusFilters,
  setStatusFilters,
  logsDateFrom,
  setLogsDateFrom,
  logsDateTo,
  setLogsDateTo,
  pseudoUrlLogs,
  importLogId,
  setImportLogId,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [showFilters, setShowFilters] = useState(getCookie('researchLeadsLogsShowFilters') === 'true');
  const [dateSearch, setDateSearch] = useState(null);

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('researchLeadsLogsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const loadImportLogOptions = async (inputValue) => {
    const results = [];
    const response = await axios({
      method: 'get',
      url: `/api/portal/prospects/get-lead-import-logs?term=${inputValue}`,
    });
    if (response.data.success) {
      response.data.importLogs.forEach((log) => {
        results.push({
          value: log._id,
          label: log.name,
        });
      });
    }
    return results;
  };

  const handleChangeStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setStatusFilters({
        ...statusFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setStatusFilters({
      Success: true,
      Fail: true,
    });
    setImportLogId(null);
    setLogsDateFrom(null);
    setLogsDateTo(null);
  };

  return (
    <div className="prospects-actions">
      <Row className="mt-4">
        <Col sm={8}>
          <InputGroup
            style={{ marginTop: '1px' }}
          >
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search by Lead Name or Email"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '38px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => window.open(`/resources/leads/download-import-logs-csv${pseudoUrlLogs}`, '_blank').focus()}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <Row>
          <Col sm={8}>
            <Row>
              <Col sm={2}>
                <Label className="bold-text" style={{ marginBottom: '14px' }}>
                  Status
                </Label>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="Success"
                    label="Success"
                    value={statusFilters.Success}
                    onChange={event => handleChangeStatusFilters(event, 'Success')}
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <CheckBoxField
                    name="Fail"
                    label="Fail"
                    value={statusFilters.Fail}
                    onChange={event => handleChangeStatusFilters(event, 'Fail')}
                  />
                </FormGroup>
              </Col>
              <Col sm={10}>
                <FormGroup>
                  <Label className="bold-text">Select Import Group</Label>
                  <SelectAsync
                    cacheOptions
                    styles={{
                    control: provided => ({
                      ...provided,
                      minHeight: '44px',
                      maxHeight: '44px',
                      height: '44px',
                      width: '100%',
                    }),
                    indicatorsContainer: provided => ({
                      ...provided,
                      minHeight: '44px',
                      maxHeight: '44px',
                      height: '44px',
                    }),
                    valueContainer: provided => ({
                      ...provided,
                      minHeight: '44px',
                      maxHeight: '44px',
                      height: '44px',
                    }),
                  }}
                    id="importLog"
                    value={importLogId}
                    defaultOptions
                    loadOptions={loadImportLogOptions}
                    onChange={e => setImportLogId(e)}
                    placeholder="Select Import Group"
                    isClearable
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col sm={4}>
            <IntervalDatePickerField
              onChange={({ start, end }) => {
                setLogsDateFrom(start);
                end?.setHours(23, 59, 59);
                // console.log(start, end);
                setLogsDateTo(end);
              }}
              startDate={logsDateFrom}
              endDate={logsDateTo}
              maxDate={new Date()}
              placeholderFrom="Start Date (ET)"
              placeholderTo="End Date (ET)"
              titleFrom="Import Start Date (ET)"
              titleTo="Import End Date (ET)"
            />
          </Col>
        </Row>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  _.isEqual(a.logsDateFrom, a1.logsDateFrom) &&
  _.isEqual(a.logsDateTo, a1.logsDateTo) &&
  _.isEqual(a.statusFilters, a1.statusFilters) &&
  a.importLogId === a1.importLogId &&
  a.pseudoUrlLogs === a1.pseudoUrlLogs);
LogsActions.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  statusFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setStatusFilters: PropTypes.func.isRequired,
  logsDateFrom: PropTypes.objectOf(PropTypes.any),
  logsDateTo: PropTypes.objectOf(PropTypes.any),
  setLogsDateFrom: PropTypes.func.isRequired,
  setLogsDateTo: PropTypes.func.isRequired,
  pseudoUrlLogs: PropTypes.string.isRequired,
  importLogId: PropTypes.objectOf(PropTypes.any),
  setImportLogId: PropTypes.func.isRequired,
};
LogsActions.defaultProps = {
  logsDateFrom: null,
  logsDateTo: null,
  importLogId: null,
};
export default LogsActions;
