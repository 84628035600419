import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  FormGroup, Input,
  Modal,
} from 'reactstrap';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import ApiClient from 'ApiClient';
import withNotice from '../../../../../App/store/with-notice';


const ModalComponent = ({
  isOpen,
  record,
  toggleModal,
  onUpdateRecordFunc,
  addNotice,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const [tagName, setTagName] = useState('');
  useEffect(async () => {
    setTagName(typeof record.tagName !== 'undefined' ? record.tagName : '');
  }, [record]);

  const closeFormDialog = () => {
    toggleModal();
    setLoading(false);
  };

  const submit = () => {
    if (!tagName || tagName.length === 0) {
      addNotice({
        message: 'No Tag name supplied.',
        type: 'error',
      });
      return false;
    }
    const api = new ApiClient();
    setLoading(true);
    const data = new FormData();
    data.append('tagName', tagName.trim());
    if (Object.keys(record).length === 0) {
      data.append('type', type);
      api.resourceAction({
        resourceId: 'UserTag',
        actionName: 'new',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        onUpdateRecordFunc();
        closeFormDialog();
        addNotice({
          message: 'Tag has been saved',
          type: 'success',
        });
      });
    } else {
      api.recordAction({
        resourceId: 'UserTag',
        recordId: record._id || null,
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        onUpdateRecordFunc();
        closeFormDialog();
        addNotice({
          message: 'Tag has been saved',
          type: 'success',
        });
      });
    }
    return true;
  };

  return (
    <div style={{ display: 'inline' }}>
      <Modal
        isOpen={isOpen}
        toggle={closeFormDialog}
        modalClassName="ltr-support"
        className="modal-dialog"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeFormDialog} />
          <h4 className="text-modal  modal__title">{Object.keys(record).length === 0 ? 'Create New Tag' : 'Rename Tag'}</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12}>
              <FormGroup>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label htmlFor="email">Tag Name</label>
                <Input
                  type="text"
                  id="tagName"
                  name="tagName"
                  placeholder=""
                  onChange={e => setTagName(e.target.value)}
                  value={tagName}
                  disabled={loading}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_ok btn-sm" color="primary" onClick={submit}>
            {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
            <span>Save</span>
          </Button>{' '}
          <Button className="modal_cancel btn-sm" onClick={closeFormDialog}>Cancel</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onUpdateRecordFunc: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};


const mapStateToProps = state => ({
  adminUser: state.session,
});

export default withNotice(connect(mapStateToProps)(ModalComponent));
