import React, { useState } from 'react';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import TableCellContextMenu from '@/shared/components/table/TableCellContextMenu';

const ContextMenuInList = ({
  record,
  refreshHubspot,
}) => {
  const agent = unflatten(record);
  const { hsContactVid } = agent.params;
  const [spyLoginLoading, setSpyLoginLoading] = useState(false);

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const spyLogin = () => {
    setSpyLoginLoading(true);
    axios.post(
      '/api/portal/agent/spy-login',
      {
        userId: agent.id,
      },
    ).then((response) => {
      const { spyLoginToken, baseUrl } = response.data;
      if (spyLoginToken && baseUrl) {
        goToPage(`${baseUrl}/login-secret/${spyLoginToken}`, '_blank');
      }
      setSpyLoginLoading(false);
    }).catch((error) => {
      console.log(error);
      setSpyLoginLoading(false);
    });
  };

  const contextMenuOptions = [
    { label: `${spyLoginLoading ? 'Please wait...' : 'Spy Login'}`, handler: spyLogin },
    { type: 'divider', label: 'divider1' },
    { label: 'View Agent Profile', handler: () => goToPage(`/agent/profile/${agent.id}`) },
    { label: 'View Agent Deal', handler: () => goToPage(`/resources/AgentUser/agent-deal-editor/${agent.id}`) },
  ];

  if (hsContactVid) {
    contextMenuOptions.push({ type: 'divider', label: 'divider2' });
    contextMenuOptions.push({ label: 'Refresh HubSpot Data', handler: () => refreshHubspot(agent.id) });
  }

  return (
    <TableCellContextMenu options={contextMenuOptions} />
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  refreshHubspot: PropTypes.func.isRequired,
};

export default ContextMenuInList;
