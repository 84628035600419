import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { faInfoCircle, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { components } from 'react-select';
import SelectAsync from 'react-select/async';
// import { uid } from 'react-uid';
import ApiClient from 'ApiClient';
import { Row, Col, Button, Container, Card, CardBody, CardTitle, Label, FormGroup, Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import Filter from '../FilterPrevious';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import formater from '../../../../shared/helpers/WVFormatter';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
import TableCellContextMenu from '../../../../shared/components/table/TableCellContextMenu';
import CheckBoxField from '../../../../shared/components/CheckBox';
import { getConstant } from '../../../../shared/helpers/WVConstants';
import GenerationInfo from '../EOMReport2Affiliate/GenerationInfo';
// import Errors from '../ErrorsV2';
import ModalEOM, { modalId } from '../EOMReport2/Modal';
import { showModalById } from '../../../../redux/reducers/TableReducer';
import { getIssueMessage } from '../ReportCalculation';
import LoadingReport from "../../../../shared/components/LoadingReport";


const dealTypes = {
  hybrid: 'Hybrid',
  cpa: 'CPA/Ambassador',
  upfront: 'Upfront Only',
  upfrontCPM: 'Upfront CPM',
  agentCommission: 'Agent Commission',
  mediaLicense: 'Media License',
  bonus: 'Bonus',
};

const formatCreatorOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div>{record?.params.first_name} {record?.params.last_name}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record?.params.email}
    </div>
  </div>
);

const creatorOption = (props) => {
  const { record } = props.data;
  return (
    <components.Option {...props} >
      <div>
        <div className="bold-text">{record.params.first_name} {record.params.last_name}</div>
        <div >{record.params['affiliateDataObject.company']}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record.params.email}
        </div>
      </div>
    </components.Option>
  );
};
const getWarningMessageArray = (validationResult, startDate, endDate) => {
  let message = '';
  let url = 'Something wrong';
  if (!validationResult.contact && validationResult.invalidContact === true) {
    message = 'Missing HubSpot Contact';
    url = `/influencer/profile/${validationResult.influencer._id}/`;
  }
  const closeDate = moment(validationResult.deal?.brandApprovedDate);
  if (validationResult.deal && !validationResult.deal.brandApprovedDate) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'No Brand Approved Date';
  }
  if (validationResult.deal && validationResult.deal.brandApprovedDate && closeDate > moment(endDate).endOf('month')) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Brand Approved Date in the future';
  }
  if (message === '' && validationResult.deal && !validationResult.deal.dealTerms.type_of_deal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Incomplete Deal Terms';
  }
  if (message === '' && validationResult.deal && validationResult.deal.dealTerms.type_of_deal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Brand Approved Date in the future or Invalid status';
  }
  if (message === '' && validationResult.unassignedDeal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.unassignedDeal.hs_object_id}/`;
    message = 'Incomplete Offer';
  }
  if (message === '' && validationResult.contact && validationResult.anotherOfferDeal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${validationResult.contact.hs_object_id}/`;
    message = 'Missing Deal or Possible Offer Mismatch';
  }
  if (message === '' && validationResult.contact && !validationResult.deal) {
    console.log(validationResult, 'validationResult');
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${validationResult.contact.hs_object_id}/`;
    message = 'Missing Deal';
  }
  if (message === '') message = validationResult?.error || '';
  return [message, url];
  // return (<span>{message} {url ? (<a href={url} target="_blank" rel="noreferrer">Click here to correct issue</a>) : null } {validationResult.offerName ? `Offer Name: ${validationResult.offerName}` : null}</span>);
};


const SummaryReport = ({ addNotice, dispatch }) => {
  const aValues = getConstant('deal', 'published', []);
  const paidValues = getConstant('deal', 'paid', []);
  const dealPipelines = getConstant('deal', 'pipelines', []);
  const dealStagesLost = getConstant('deal', 'dealLost', []);
  const dealStages = [];
  dealPipelines.forEach((pipeline) => {
    Object.keys(pipeline.stages).forEach((stageKey) => {
      dealStages.push({ id: stageKey, label: pipeline.stages[stageKey] });
    });
  });
  const [loading, setLoaing] = useState(false);
  const [loadingAdvertiser, setLoaingAdvertiser] = useState(false);
  const [items, setItems] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [offers, setOffers] = useState([]);
  // const [employees, setEmployees] = useState([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  // const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [includeSa, setIncludeSa] = useState(false);
  const [respData, setRespData] = useState({});
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
  // const [errors, setErrors] = useState([]);
  const [identity] = useState(Math.random().toString(36).substring(2));

  const [cspOptions, setCspOptions] = useState([]);
  const [selectedCsps, setSelectedCsps] = useState(null);
  const isCspSelected = csp => !(selectedCsps && selectedCsps.length) || (csp && (selectedCsps.map(row => row.value).indexOf(csp) > -1));

  // const [showId, setShowId] = useState(null);
  const [dealTypeFilters, setDealTypeFilters] = useState({
    'CPA/Ambassador': true,
    Hybrid: true,
    'Upfront Only': true,
    'Upfront CPM': true,
    'Media License': true,
    Bonus: true,
  });
  const [selectedRes, setSelectedRes] = useState({
    all: false,
    notPilot: false,
    pilot: false,
  });

  const handleChangeSelectedBrands = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setSelectedRes({
        ...dealTypeFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeDealTypeFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setDealTypeFilters({
        ...dealTypeFilters,
        [name]: checked,
      });
    }
  };
  useEffect(async () => {
    try {
      const resp = await axios.get('/api/portal/finance/get-deal-owners');
      const admins = resp.data.dealOwners ? resp.data.dealOwners.filter(a => a.affiliateManagerId).map(a => ({ value: `${a._id}`, label: `${a.firstName} ${a.lastName}` })) : [];
      setCspOptions(admins);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    let optionsItems = [];
    if (selectedRes.notPilot === true) {
      optionsItems = advertisers.filter(a => a.isPilot === 'No').map(item => ({ value: item.tuneId, label: item.companyName }));
    }
    if (selectedRes.pilot === true) {
      optionsItems = optionsItems.concat(advertisers.filter(a => a.isPilot === 'Yes').map(item => ({ value: item.tuneId, label: item.companyName })));
    }

    setSelectedAdvertisers(optionsItems);
  }, [selectedRes]);

  useEffect(() => {
    const api = new ApiClient();
    setLoaingAdvertiser(true);
    api.client.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      setOffers(resp.data.offers);
      const brandValues = Object.values(brands);
      brandValues.sort((a, b) => a.companyName?.localeCompare(b.companyName));
      setAdvertisers(brandValues);
      setLoaingAdvertiser(false);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
    /* api.client.get('/api/portal/finance/get-employees').then((resp) => {
      setEmployees(resp.data.employees);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    }); */
  }, []);

  const columnsError = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: p => `${p?.offer.advertiser.companyName || ''}`,
        id: 'brand',
        Cell: p => useMemo(() => (
          <div style={{ width: '120px' }}>
            {p.value}
          </div>
        ), [p.row.original]),
      },
      {
        Header: 'Offer',
        accessor: p => `${p?.offer?.name || ''}`,
        id: 'offer',
        Cell: p => useMemo(() => (
          <div style={{ width: '120px' }}>
            {p.value}
          </div>
        ), [p.row.original]),
      },
      {
        Header: 'Deal Type',
        accessor: deal => deal.deal?.dealTerms?.type_of_deal,
        id: 'deal_type',
        Cell: p => (p.value ? (
          <div style={{ width: '180px' }}>
            {p.value}{' '}

            <a
              href={`/resources/HubilDeal/actions/list?filters.fullSearchName=${p.row.original.influencer?.first_name || ''}+
${p.row.original.influencer?.last_name || ''}&page=1&filters.status=Active&filters.dealTypes=Hybrid&filters.dealTypes=CPA%2FAmbassador&filters.dealTypes=Agent+Commission&filters.dealTypes=Media+License&filters.dealTypes=Bonus`}
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title="Influencer Deal List">
                <IconButton size="small">
                  <i className="fa fa-list-alt" style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </a>
            <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original.deal?.hs_object_id}/`} target="_blank" rel="noreferrer">
              <Tooltip title="Hubspot Deal">
                <IconButton size="small">
                  <i className="fab fa-hubspot" style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </a>
            {p.row.original.mc ? (
              <Tooltip title="Media Content">
                <IconButton
                  size="small"
                  onClick={() =>
                    window.open(`/scorecard/content-dashboard?filters.deal=${p.row.original._id}
    &filters.createdAt~~from=${moment().subtract(2, 'years').format('YYYY-MM-DD')}&filters.createdAt~~to=${moment().format('YYYY-MM-DD')}&range=custom`, '_blank').focus()}
                  className=""
                >
                  <FontAwesomeIcon icon={faVideo} />
                </IconButton>
              </Tooltip>) : null}
          </div>
        ) : ''),
      },
      {
        Header: 'Status',
        accessor: p => p.deal?.dealTerms.cpa_status || '-',
        id: 'cpa_status',
        Cell: p => useMemo(() => (
          <div style={{ width: '100px' }}>
            {p.value}
          </div>
        ), [p.value]),
      },
      {
        Header: 'Deal Stage',
        accessor: p => p.deal?.dealstage || null,
        id: 'dealstage',
        Cell: p => useMemo(() => {
          // console.log(p);
          let stage = { id: null, label: '-' };
          if (p.value) {
            stage = dealStages.find(item => item.id === p.value);
          }
          return (
            <div style={{ width: '100px' }}>
              {stage.label}  {p.row.original.deal?.additionDeal ? ' (Possible Deal?)' : ''}
            </div>
          );
        }, [p.value]),
      },
      {
        Header: 'Brand Approved Date',
        accessor: (deal) => {
          if ([dealTypes.bonus].includes(deal.deal?.dealTerms.type_of_deal)) return deal.deal.dealTerms.content_release_date;
          return deal.deal?.brandApprovedDate;
        },
        id: 'brandApprovedDate',
        Cell: p => useMemo(() => (
          <div style={{ width: '180px' }}>
            {p.value ? moment(p.value.split('T')[0]).format('MM/DD/YY') : '-'}
          </div>
        ), [p.value]),
      },
      {
        Header: 'Creator',
        accessor: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
        id: 'Creator',
        Cell: p => 
          <div style={{ width: '120px' }}>
            {p.row.original.influencer ? (<Tooltip title="Open Creator Profile">
              <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.influencer?._id}`}>{String(p.value || '').trim() || p.row.original.influencer.email || ' '}</a>
            </Tooltip>) : null}
            <div>Promo Code: {p.row.original.promoCode ? (<a href={`/resources/HubilDeal/actions/list?filters.fullSearchName=${p.row.original.promoCode}`} target="_blank" rel="noreferrer">{p.row.original.promoCode}</a>) :  '(Unknown)'}</div>
          </div>
      },
      {
        Header: 'Account Manager',
        accessor: p => (
          <div style={{ width: '120px' }}>
            {`${p?.adminAF?.firstName || ''} ${p?.adminAF?.lastName || ''}`}
          </div>),
        id: 'owner',
        Cell: p => useMemo(() => p.value, [p.row.original]),
      },
      {
        Header: 'Revenue Conversions',
        accessor: p => Number(p.conversionsArray?.length || 0) + Number(p.totalCpmConversions || 0),
        id: 'initialConversions',
        Cell: p => useMemo(() => p.value, [p.row.original]),
        width: 200,
        minWidth: 200,
      },
      {
        Header: 'Revenue',
        accessor: deal => Number(deal.totalRevenue || 0),
        id: 'revenue',
        Cell: ({ value }) => useMemo(() => (
          <div style={{ width: '100px' }}>
            {formater.formatCurrency(value || 0)}
          </div>), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Costs',
        accessor: deal => (deal.totalPayoutWithMulligan),
        id: 'costs',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => (info.rows.reduce((sum, row) => Number(row.values.costs || 0) + sum, 0)),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Total Mulligan Amount',
        accessor: (deal) => {
          const a = ((deal.totalPayout || 0) - deal.totalPayoutWithMulligan);
          return a;
        },
        id: 'total_mulligan_amount',
        Cell: ({ value }) => useMemo(() => (
          <div style={{ width: '180px' }}>
            {formater.formatCurrency(value || 0)}
          </div>), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.total_mulligan_amount || 0) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Profit',
        accessor: deal => Number(deal.grossProfit || 0),
        id: 'profit',
        Cell: ({ value }) => useMemo(() => (
          <div style={{ width: '80px' }}>
            {formater.formatCurrency(value || 0)}
          </div>), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.profit) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Message',
        accessor: deal => deal.message || '',
        id: 'message',
        Cell: ({ value }) => value,
      },
      {
        Header: '',
        accessor: '_id',
        // eslint-disable-next-line react/prop-types
        Cell: p => (p.row.original.deal ? (
          <div style={{ textAlign: 'right' }}><TableCellContextMenu
            options={[
              { label: 'Display Calculation', handler: () => { dispatch(showModalById(modalId, { record: p.row.original })); } },
            ]}
          />
          </div>) : null),
        disableGlobalFilter: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    [items],
  );


  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'initialConversions',
    direction: 'desc',
  };
  const fillReport = (data, err) => {
    const itemsToReturn = [];
    Object.values(data.items).forEach((dealr) => {
      const deal = { ...dealr };
      const message = getIssueMessage(dealr, paidValues, aValues, includeSa);
      console.log(message);
      // eslint-disable-next-line no-param-reassign
      deal.message = message;
      if (message) {
        // eslint-disable-next-line no-param-reassign
        deal.mc = data.mediaContents.find(m => m.deal.toString() === deal.deal._id.toString());
        if (deal.mc) {
          // eslint-disable-next-line no-param-reassign
          deal.message = getIssueMessage(deal, paidValues, aValues, includeSa);
        }
        console.log(message);
      }
      deal.promoCode = deal.promoCode ? deal.promoCode : deal.deal?.dealTerms?.coupon_code
      itemsToReturn.push(deal);
      return true;
    });
    err.forEach((e) => {
      const [message] = getWarningMessageArray(e, startDate, endDate);
      console.log(message);
      if (e.deal && e.cpmCount > 0 && dealStagesLost.includes(e.deal.dealstage)) {
        return false;
      }
      itemsToReturn.push({
        offer: e.offer || { advertiser: { companyName: 'BetterHelp' }, name: 'BetterHelp CPM' },
        influencer: e.influencer,
        deal: e.deal,
        conversionsArray: e.cpmCount ? [] : e.conversions,
        admin: e.admin,
        adminAF: e.adminAF,
        message: e.error ? e.error : message,
        dealType: e.deal?.dealTerms?.type_of_deal,
        totalRevenue: e.conversions?.reduce((accumulator, currentValue) => accumulator + Number(currentValue.revenue), 0) || 0,
        totalCpmConversions: e.cpmCount || 0,
        existStatus: 'warning',
        promoCode: e.promoCode ? e.promoCode : e.deal?.dealTerms?.coupon_code
      });
      return true;
    });
    console.log(itemsToReturn);
    return itemsToReturn;
  };
  useEffect(() => {
    /* const itemsCloned = respData.items;
    if (itemsCloned && itemsCloned.length > 0) {
      const itemsR = fillReport(respData);
      console.log(itemsR);
      setItems(itemsR);
    } */
    if (respData && respData?.items && Object.values(respData?.items)) setItems(fillReport(respData, respData.notUsedConversions));
  }, [includeSa, respData]);
  const fetchAmountReport = async () => {
    setLoaing(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-eom-issues-reports', {
        startDate,
        endDate,
        advertisers: selectedAdvertisers?.map(a => a.value),
        offers: selectedOffer?.map(o => o.value),
        creators: selectedInfluencers?.map(i => i.value), // internal _id
        employees: [], // selectedEmployee?.map(i => i.value),
        identity,
      });
      if (resp.data.success) {
        setLoaing(false);
        // setErrors(resp.data.notUsedConversions);
        console.log(resp.data.items);
        setRespData(resp.data);
        // setItems(resp.data.items);
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      setLoaing(false);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const selectItem = (value) => {
    setSelectedAdvertisers(value);
    // setVersion((new Date()).toString());
  };

  const loadCreatorsOptions = async (value) => {
    const response = await axios.get('/api/portal/influencer/get-creator-search', {
      params: {
        value,
      },
    });
    return response.data.records.map(record => ({
      value: record.params._id,
      label: record.params.email,
      record,
    }));
  };

  const options = useMemo(() => {
    const i = advertisers.map(item => ({ value: item.tuneId, label: item.companyName }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [advertisers]);
  /* const employeeOptions = useMemo(() => {
    const i = employees.map(item => ({ value: item.tuneId, label: `${item.firstName || ''} ${item.lastName || ''}` }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [employees]); */

  const optionsOffer = useMemo(() => {
    const returnValues = [];
    if (selectedAdvertisers) {
      selectedAdvertisers.forEach((sa) => {
        const i = offers
          .filter(o => (selectedAdvertisers && selectedAdvertisers.length === 0) || Number(o?.advertiserTuneid || 0) === Number(sa.value || 0))
          .map(item => ({ value: item.tuneId, label: item.name }));
        returnValues.push({ label: sa.label, options: i });
      });
    }
    // returnValues.unshift({ value: null, label: ' -- All -- ' });
    return returnValues;
  }, [offers, selectedAdvertisers]);
  const fItems = useMemo(() => { // filter items
    const activeDealTypes = Object.keys(dealTypeFilters).filter(k => dealTypeFilters[k]).map(k => k);
    return items.filter(item => (activeDealTypes.length === 6 && !item?.deal?.dealTerms?.type_of_deal && isCspSelected(item.adminAF?._id)) || (activeDealTypes.includes(item?.deal?.dealTerms?.type_of_deal) && isCspSelected(item.adminAF?._id)));
  }, [items, dealTypeFilters, selectedCsps]);

  const filteredItemsErrors = useMemo(() => { // filter items
    const activeDealTypes = Object.keys(dealTypeFilters).filter(k => dealTypeFilters[k]).map(k => k);
    return items.filter(item => (activeDealTypes.length === 6 && !item?.deal?.dealTerms?.type_of_deal && isCspSelected(item.adminAF?._id))
      || (item.message && activeDealTypes.includes(item?.deal?.dealTerms.type_of_deal) && isCspSelected(item.adminAF?._id)));
  }, [items, dealTypeFilters, selectedCsps]);
  const filteredItems = useMemo(() => { // filter items
    const activeDealTypes = Object.keys(dealTypeFilters).filter(k => dealTypeFilters[k]).map(k => k);
    return items.filter(item => (activeDealTypes.length === 6 && !item?.deal?.dealTerms?.type_of_deal && isCspSelected(item.adminAF?._id))
      || (activeDealTypes.includes(item?.deal?.dealTerms.type_of_deal) && !item.message && isCspSelected(item.adminAF?._id)));
  }, [items, dealTypeFilters, selectedCsps]);
  const getTotalRevenue = () => {
    let total = 0;
    fItems.forEach((i) => {
      total += i.totalRevenue;
    });
    return total;
  };
  const getTotalRevenueI = () => {
    let total = 0;
    filteredItems.forEach((i) => {
      total += i.totalRevenue;
    });
    return total;
  };
  const getTotalRevenueIssues = () => {
    let total = 0;
    filteredItemsErrors.forEach((i) => {
      total += i.totalRevenue;
    });
    return total;
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Conversions Audit Report', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Conversions Audit Report</h3>
              </CardTitle>
              <Row className="mt-3">
                <Col>
                  <Filter
                    setValue={(param, value) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('month').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('month').format('YYYY-MM-DD'));
                      }
                    }}
                    order={['current_month', 'last_month', 'custom']}
                  />
                </Col>
                <Col lg="auto">
                  <div className="mt-0">
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchAmountReport();
                      }}
                      className="btn-sm"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                    <GenerationInfo
                      startDate={startDate}
                    />
                  </div>
                </Col>
                {items.length > 0 ? (
                  <Col lg="auto">
                    <div className="float-right mr-2 mt-0">
                      <DownloadCSVButton
                        records={filteredItemsErrors}
                        className="mt-0"
                        fileName="eom_issues.csv"
                        csvArrayColumns={[
                          {
                            label: 'Brand',
                            func: p => `${p?.offer?.advertiser?.companyName || ''}`,
                          },
                          {
                            label: 'Offer',
                            func: p => `${p?.offer?.name || ''}`,
                          },
                          {
                            label: 'Deal Type',
                            func: p => `${p?.deal?.dealTerms?.type_of_deal || ''}`,
                          },
                          {
                            label: 'Status',
                            func: p => `${p?.deal?.dealTerms.cpa_status || ''}`,
                          },
                          {
                            label: 'Deal Stage',
                            func: (p) => {
                              let stage = { id: null, label: '-' };
                              if (p.deal?.dealstage) {
                                stage = dealStages.find(item => item.id === p.deal.dealstage);
                              }
                              return stage.label;
                              // `${p?.deal.dealTerms.cpa_status || ''}`
                            },
                          },
                          {
                            label: 'Creator',
                            func: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
                          },
                          {
                            label: 'Account Manager',
                            func: p => `${p?.adminAF?.firstName || ''} ${p?.adminAF?.lastName || ''}`,
                          },
                          {
                            label: 'Brand Approved Date',
                            func: p => `${p?.deal?.brandApprovedDate ? moment(p.deal?.brandApprovedDate.split('T')[0]).format('MM/DD/YY') : '-'}`,
                          },
                          {
                            label: 'Revenue',
                            func: deal => (deal.totalRevenue || 0),
                          },
                          {
                            label: 'Costs',
                            func: deal => (deal.totalPayoutWithMulligan || 0),
                          },
                          {
                            label: 'Total Mulligan Amount',
                            func: (deal) => {
                              const a = ((deal.totalPayout || 0) - (deal.totalPayoutWithMulligan || 0));
                              return a || 0;
                            },
                          },
                          {
                            label: 'Profit',
                            func: deal => (deal.grossProfit || 0),
                          },
                          {
                            label: 'Is Deal Addition',
                            func: deal => (deal.deal?.additionDeal ? 'Yes' : 'No'),
                          },
                          {
                            label: 'Message',
                            func: deal => (deal.message),
                          },
                      ]}
                      >
                        Download CSV
                      </DownloadCSVButton>
                    </div>
                  </Col>
                ) : null}
              </Row>
              <Row className="mt-3" >
                <Col>
                  <Select
                    isDisabled={loadingAdvertiser}
                    value={selectedAdvertisers}
                    options={options}
                    onChange={selectItem}
                    placeholder="Select Brand..."
                    isClearable
                    isMulti
                  />
                  <Row className="mt-3">
                    <Col>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="all"
                          label="Select All Brands (Include All Brands)"
                          value={selectedRes.all}
                          onChange={event => handleChangeSelectedBrands(event, 'all')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="notPilot"
                          label="Select All Brands, No Pilot Brands"
                          value={selectedRes.notPilot}
                          onChange={event => handleChangeSelectedBrands(event, 'notPilot')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="pilot"
                          label="Select Pilot Brands Only"
                          value={selectedRes.pilot}
                          onChange={event => handleChangeSelectedBrands(event, 'pilot')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Select
                    isDisabled={loadingAdvertiser}
                    value={selectedOffer}
                    options={optionsOffer}
                    onChange={setSelectedOffer}
                    placeholder="Select Offer..."
                    isClearable
                    isMulti
                  />
                </Col>
                <Col>
                  <SelectAsync
                    cacheOptions
                    isMulti
                    loadOptions={async value => loadCreatorsOptions(value)}
                    onChange={setSelectedInfluencers}
                    placeholder="Search Influencer..."
                    formatOptionLabel={formatCreatorOptionLabel}
                    components={{ Option: creatorOption }}
                  />
                </Col>
                <Col sm={2}>
                  <FormGroup>
                    <Select
                      isMulti
                      value={selectedCsps}
                      options={cspOptions}
                      onChange={value => setSelectedCsps(value)}
                      placeholder="Select Account Manager..."
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Deal Type
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="CPA"
                      label="CPA/Ambassador"
                      value={dealTypeFilters['CPA/Ambassador']}
                      onChange={event => handleChangeDealTypeFilters(event, 'CPA/Ambassador')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Hybrid"
                      label="Hybrid"
                      value={dealTypeFilters.Hybrid}
                      onChange={event => handleChangeDealTypeFilters(event, 'Hybrid')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="UpfrontOnly"
                      label="Upfront Only"
                      value={dealTypeFilters['Upfront Only']}
                      onChange={event => handleChangeDealTypeFilters(event, 'Upfront Only')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="UpfrontCPM"
                      label="Upfront CPM"
                      value={dealTypeFilters['Upfront CPM']}
                      onChange={event => handleChangeDealTypeFilters(event, 'Upfront CPM')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="MediaLicense"
                      label="Media License"
                      value={dealTypeFilters['Media License']}
                      onChange={event => handleChangeDealTypeFilters(event, 'Media License')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="Bonus"
                      label="Bonus"
                      value={dealTypeFilters.Bonus}
                      onChange={event => handleChangeDealTypeFilters(event, 'Bonus')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="invludeSA"
                      label="Include Service Agreement Issues for active CPA or Hybrid deal"
                      value={includeSa}
                      onChange={event => event && setIncludeSa(event.target.checked)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    topic="report"
                    identity={identity}
                    isShow={!!loading}
                  />
                </Col>
              </Row> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!loading && items.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col xs="auto">
                    <h5>Gross Revenue - HasOffers
                      <Tooltip title="Total Revenue reported by HasOffers for all revenue producing conversions">
                        <IconButton
                          size="small"
                          onClick={() => false}
                          className="material-table__toolbar-button"
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </IconButton>
                      </Tooltip>
                    </h5>
                    {!loading && items.length > 0 ? (
                      <h3>{formater.formatCurrency(getTotalRevenue())}</h3>
                    ) : null}
                  </Col>
                  <Col xs="auto">
                    <h5>Gross Revenue - InfluenceLogic
                      <Tooltip title="Total Revenue reported for all HasOffer revenue producing conversions that have a valid InfluenceLogic deal">
                        <IconButton
                          size="small"
                          onClick={() => false}
                          className="material-table__toolbar-button"
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </IconButton>
                      </Tooltip>
                    </h5>
                    {!loading && items.length > 0 ? (
                      <h3>{formater.formatCurrency(getTotalRevenueI())}</h3>
                    ) : null}
                  </Col>
                  <Col xs="auto">
                    <h5>Gross Revenue - Different
                      <Tooltip title="Difference between the HasOffers and InfluenceLogic Gross Revenue amounts">
                        <IconButton
                          size="small"
                          onClick={() => false}
                          className="material-table__toolbar-button"
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </IconButton>
                      </Tooltip>
                    </h5>
                    {!loading && items.length > 0 ? (
                      <h3>{formater.formatCurrency(getTotalRevenueIssues())}</h3>
                    ) : null}
                  </Col>
                  <Col />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null }
      {!loading && items.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Alert color="info">
                  <p className="py-2">
                    The deals highlighted in orange need to be confirmed and fixed before they can contribute to the total Gross Revenue amounts.
                  </p>
                </Alert>
                <Row className="mt-3">
                  <Col>
                    {!loading && items.length > 0 ? (
                      <ReactTableBase
                        key="searchableError"
                        columns={columnsError}
                        data={filteredItemsErrors}
                        tableConfig={tableConfig}
                      />) : null
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>) : null
      }
      <ModalEOM />
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};


export default connect()(withNotice(SummaryReport));
