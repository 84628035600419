import React, { useState, useMemo } from 'react';
import { axios } from 'ApiClient';
// import PropTypes from 'prop-types';
// import DatePicker from 'react-datepicker';
import { Row, Col, Button, Label, Container, Card, CardBody, CardTitle, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import MatTableForRedux from '../../../../shared/tables/materialTable/components/MatTableForRedux';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import formater from '../../../../shared/helpers/WVFormatter';
import withNotice from '../../../App/store/with-notice';
import HubspotDealLink from '../../../../shared/components/HubspotDealLink';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import { getConstant } from '../../../../shared/helpers/WVConstants';
import CheckBoxField from '../../../../shared/components/CheckBox';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
// import CheckBoxField from '../../../../shared/components/CheckBox';
// import Collapse from '../../../../shared/components/Collapse';
// import HubspotDealLink from '../../../../shared/components/HubspotDealLink';


const calculateForDeal = (deal) => {
  let a = deal.payables.reduce((accumulator, currentValue) => {
    // eslint-disable-next-line
    accumulator += Number(currentValue.profit || 0);
    return accumulator;
  }, 0);
  if (deal.bill) {
    a -= Number(deal.billTotal);
  }
  if (deal.currentMonthPayout) {
    a -= Number(deal.currentMonthPayout);
  }
  if (deal.currentMonthRevenue) {
    a += Number(deal.currentMonthRevenue);
  }
  return Number(a || 0);
};

const SummaryReport = ({ addNotice }) => {
  const [loading, setLoaing] = useState(false);
  const [loadingUpdate, setLoaingUpdate] = useState(false);
  const [items, setItems] = useState([]);
  const [updateItems, setUpdateItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const selectCheckbox = (id, value) => {
    console.log(id, value);
    const s = [...selectedItems];
    if (value) s.push(id);
    else {
      const index = selectedItems.indexOf(id);
      if (index > -1) s.splice(index, 1);
    }
    console.log(s);
    setSelectedItems(s);
  };
  const columnsProfit = useMemo(
    () => [
      {
        Header: () => (<CheckBoxField
          name="selectAll"
          label=""
          value={selectedItems.length === items.length}
          onChange={({ target }) => {
            if (!target) return false;
            if (target.checked) {
              setSelectedItems(items.map(item => item._id));
            } else {
              setSelectedItems([]);
            }
            return true;
          }}
        />),
        accessor: deal => deal._id,
        id: 'id',
        minWidth: 40,
        maxWidth: 40,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <FormGroup className="m-0">
            <CheckBoxField
              name={value}
              label=""
              value={selectedItems.includes(value)}
              onChange={({ target }) => target && (selectCheckbox(value, target.checked))}
            />
          </FormGroup>),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Deal Name',
        accessor: deal => deal.dealname,
        id: 'dealname',
        Cell: p => useMemo(() => (<HubspotDealLink
          id={Number(p.row.original.hs_object_id)}
          title={p.value}
        />), [p.row.original]),
        width: 300,
      },
      {
        Header: 'Creator Email',
        accessor: deal => (deal?.payables && deal.payables[0] && deal.payables[0]?.influencer?.email),
        id: 'email',
        Cell: ({ value }) => value || 'Unable to find email',
        width: 250,
      },
      {
        Header: 'Current Deal Amount',
        accessor: deal => Number(deal.amount || 0),
        id: 'amount',
        Cell: ({ value }) => formater.formatCurrency(value),
        width: 200,
      },
      {
        Header: 'Payment(s)',
        accessor: () => null,
        id: 'payments',
        Cell: p => (
          <ul>
            {/* eslint-disable-next-line react/prop-types */}
            {p.row.original.payables.map(r => (
              <li key={r._id}>
                {r.name} - {formater.formatCurrency(Number(r.revenueAmount || 0))} - {formater.formatCurrency(Number(r.ilPayoutAmount || 0))} = {formater.formatCurrency(Number(r.profit || 0))}
              </li>))}
          </ul>
        ),
        disableSortBy: true,
        disableFilters: true,
        width: 100,
      },
      {
        Header: 'Bill',
        accessor: deal => Number(deal.billTotal || 0),
        id: 'bills',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <ul>
            {value && formater.formatCurrency(Number(value))}
          </ul>
        ),
        width: 100,
      },
      {
        Header: 'Invoice',
        accessor: deal => Number(deal.invoiceTotal || 0),
        id: 'invoice',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <ul>
            {value && formater.formatCurrency(Number(value))}
          </ul>
        ),
        width: 100,
      },
      {
        Header: 'Gross Profit',
        accessor: deal => calculateForDeal(deal),
        id: 'grossRevenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 150,
      },
    ],
    [items, selectedItems],
  );

  const columnsUpdated = useMemo(
    () => [
      {
        Header: 'Deal Name',
        accessor: deal => deal.dealName,
        id: 'dealname',
        Cell: p => useMemo(() => (<HubspotDealLink
          id={Number(p.row.original.hs_object_id)}
          title={p.value}
        />), [p.row.original]),
        width: 400,
      },
      {
        Header: 'Old Amount',
        accessor: deal => Number(deal.amount || 0),
        id: 'amount',
        Cell: ({ value }) => formater.formatCurrency(value),
        width: 250,
      },
      {
        Header: 'New Amount',
        accessor: deal => Number(deal.totalProfit || 0),
        id: 'newamount',
        Cell: ({ value }) => formater.formatCurrency(value),
        width: 250,
      },
      {
        Header: 'Status',
        accessor: d => d.status === 'success',
        id: 'status',
        Cell: p => (p.value ? (<span className="text-success">Success</span>) : (<span className="text-danger">{p.row.original.error}</span>)),
        width: 250,
      },
    ],
    [updateItems],
  );

  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'grossProfit',
    direction: 'desc',
  };

  const fetchAmountReport = async () => {
    setLoaing(true);
    const resp = await axios.post('/api/portal/finance/post-fetch-all-deals');
    if (resp.data.success) {
      setItems(resp.data.items);
      setLoaing(false);
    } else {
      addNotice({
        type: 'error',
        message: resp.data.error,
      });
      setLoaing(false);
    }
  };
  const saveAmounts = async () => {
    setLoaingUpdate(true);
    const dealAmount = {};
    selectedItems.map((id) => {
      const selectedAmount = items.find(item => item._id.toString() === id.toString());
      let amount = selectedAmount.payables.reduce((accumulator, currentValue) => {
        // eslint-disable-next-line
        accumulator += Number(currentValue.profit || 0);
        return accumulator;
      }, 0);
      if (selectedAmount.bill) {
        amount -= Number(selectedAmount.billTotal);
      }
      dealAmount[id] = Number(amount || 0);
      return id;
    });
    const resp = await axios.post('/api/portal/finance/post-update-amount-deals', { dealAmount });
    if (resp.data.success) {
      setLoaingUpdate(false);
      setUpdateItems(resp.data.dealUpdateResults.map(r => ({ ...r, id: r._id })));
    } else {
      setLoaingUpdate(false);
      // this.setState({ error: resp.data.error });
      addNotice({
        type: 'error',
        message: resp.data.error,
      });
    }
  };
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Calculate Gross Profit', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem' }}>
              <CardTitle>
                <h3>Calculate Gross Profit</h3>
              </CardTitle>
              <Row className="mt-3">
                <Col lg="auto">
                  <Label>{' '}</Label>
                  <div>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchAmountReport();
                      }}
                      className=""
                      disabled={!!loading}
                    >
                      Calculate Gross Profit
                    </Button>
                  </div>
                </Col>
              </Row>
              {!loading && !loadingUpdate && (
                <Row>
                  <Col xs="auto">
                    <Button
                      color="primary"
                      onClick={saveAmounts}
                      disabled={selectedItems.length === 0}
                    >
                      Update Selected Deals
                    </Button>
                  </Col>
                  <Col />
                </Row>
              )}
              <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    topic={getConstant('AP', 'calculateDealVariable')}
                    identity={identity}
                    isShow={!!loading}
                  />
                  {loadingUpdate && (<ProgressBar
                    afterFunction={() => null}
                    topic={getConstant('AP', 'updateDealVariable')}
                    receiveResults={({ dealUpdateResult }) => {
                      const ui = [...updateItems];
                      const newDealUpdateResult = { ...dealUpdateResult };
                        if (dealUpdateResult) {
                          newDealUpdateResult.id = newDealUpdateResult._id;
                          ui.push(newDealUpdateResult);
                          console.log(ui);
                        }
                      setUpdateItems(ui);
                    }}
                  />)}
                </Col>
              </Row>
              {!loadingUpdate && updateItems.length > 0 ? (
                <Row>
                  <Col>
                    <Card>
                      <CardBody style={{ padding: '2rem' }}>
                        <CardTitle>
                          <h3>Updated Items report{' '}
                            {items.length > 0 ? (
                              <div className="float-right mr-0 my-0">
                                <DownloadCSVButton
                                  records={updateItems}
                                  className="my-0"
                                  fileName="UpdatedDeals.csv"
                                  csvArrayColumns={[
                                    {
                                      label: 'Deal Name',
                                      func: deal => (deal ? deal.dealname : ''),
                                    }, {
                                      label: 'Deal Link',
                                      func: deal => (deal ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal.hs_object_id}/` : ''),
                                    }, {
                                      label: 'Gross Profit Amount',
                                      func: deal => formater.formatNumber(Number(deal.totalProfit || 0)),
                                    }, {
                                      label: 'Status',
                                      func: deal => (deal.status === 'success' ? 'success' : 'error'),
                                    }, {
                                      label: 'Error',
                                      func: deal => deal.error,
                                    },
                                 ]}
                                >
                                  Download CSV
                                </DownloadCSVButton>
                              </div>
                            ) : null}
                          </h3>
                        </CardTitle>
                        <Row >
                          <Col className="mt-3">
                            {!loadingUpdate && updateItems.length > 0 ? (
                              <ReactTableBase
                                key="searchable"
                                columns={columnsUpdated}
                                data={updateItems}
                                tableConfig={tableConfig}
                              />) : null
                            }
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>) : null
              }
              {!loading && items.length > 0 ? (
                <Row>
                  <Col>
                    <Card>
                      <CardBody style={{ padding: '2rem' }}>
                        <CardTitle>
                          <h3>Deals{' '}
                            {items.length > 0 ? (
                              <div className="float-right my-0">
                                <DownloadCSVButton
                                  records={items}
                                  className="my-0"
                                  fileName="deals.csv"
                                  csvArrayColumns={[
                                    {
                                      label: 'Deal Name',
                                      func: deal => (deal ? deal.dealname : ''),
                                    }, {
                                      label: 'Deal Link',
                                      func: deal => (deal ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal.hs_object_id}/` : ''),
                                    }, {
                                      label: 'Gross Profit Amount',
                                      func: deal => formater.formatNumber(Number(deal.totalProfit || 0)),
                                    }, {
                                      label: 'Gross Profit Amount',
                                      func: deal => formater.formatNumber(Number(deal.amount || 0)),
                                    },
                                 ]}
                                >
                                  Download CSV
                                </DownloadCSVButton>
                              </div>
                            ) : null}
                          </h3>
                        </CardTitle>
                        <Row >
                          <Col className="mt-3">
                            {!loading && items.length > 0 ? (
                              <ReactTableBase
                                key="searchable"
                                columns={columnsProfit}
                                data={items}
                                tableConfig={tableConfig}
                              />) : null
                            }
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>) : null
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
