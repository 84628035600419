/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useAsyncDebounce } from 'react-table';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
} from 'reactstrap';
import { axios } from 'ApiClient';
import MatTableList from '../../../../../../shared/tables/materialTable/MatTableList';
// import Breadcrumbs from '../../../../../../shared/components/BreadCrumbsResource';
import Breadcrumbs from '../../../../../../shared/components/BreadCrumbs';
import UserPropertyType from '../../components/custom';
import ContextMenuInList from './ContextMenuInList';
import AgentForm from './AgentForm';
import Actions from './Actions';

const AgentUserListContainer = ({
  resource,
  setTag,
  action,
}) => {
  const [loading, setLoading] = useState(false);
  const [agentDialogVisible, setAgentDialogVisible] = useState(false);
  const [version, setVersion] = useState((new Date()).toString());
  const [csvDownloadLink, setCsvDownloadLink] = useState('/resources/AgentUser/download-csv');
  const [search, setSearch] = useState('');
  const [searchValueDebounced, setSearchValueDebounced] = useState('');
  const [tags, setTags] = useState([]);
  const [taxForm, setTaxForm] = useState({ Yes: true, No: true });
  const [tagsFilter, setTagsFilter] = useState({ getAllRecords: true });
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [tableFilters, setTableFilters] = useState({ tags: tagsFilter, taxForm });

  const startSearch = useAsyncDebounce(() => {
    setSearchValueDebounced(search);
  }, 400);

  const onSearchChange = (searchValue) => {
    setSearch(searchValue);
    startSearch();
  };

  const fetchTagSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/tags/get-agent-tags?id=undefined',
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const refreshHubspot = (agentId) => {
    setLoading(true);
    axios.post('/api/portal/agent/post-refresh-hubspot', { id: agentId }).then(() => setLoading(false));
  };

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign,no-return-assign,no-sequences
    const params = window.location.search.replace('?', '').split('&').reduce((r, e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});
    if (typeof params.tagId !== 'undefined' && typeof params.tagName !== 'undefined') {
      const tagsFilterUpdated = {};
      tagsFilterUpdated[params.tagName] = true;
      setTags([{ id: params.tagId, name: params.tagName }]);
      setTagsFilter(tagsFilterUpdated);
    }
    fetchTagSuggestions();
  }, []);

  useEffect(() => {
    const updatedTableFilters = { tags: tagsFilter, taxForm };
    if (searchValueDebounced) updatedTableFilters.fullSearchName = searchValueDebounced;
    setTableFilters(updatedTableFilters);
  }, [searchValueDebounced, tagsFilter, taxForm]);

  useEffect(() => {
    let params = '';
    // eslint-disable-next-line no-restricted-syntax,no-unused-vars
    for (const [column, values] of Object.entries(tableFilters)) {
      let disableAll = true;
      if (typeof values === 'string') {
        disableAll = false;
        params += `&filters.${column}=${values}`;
      } else {
        // eslint-disable-next-line no-unused-vars,no-restricted-syntax
        for (const field in values) {
          if (values[field]) {
            disableAll = false;
            if (field !== 'getAllRecords') {
              params += `&filters.${column}=${field}`;
            }
          }
        }
      }
      if (disableAll) {
        params += `&filters.${column}=Not Existing Value ${Date.now()}`;
      }
    }
    setCsvDownloadLink(`/resources/AgentUser/download-csv?${params.slice(1)}`);
  }, [tableFilters]);

  return (
    <Container className="dashboard full-width">
      <Row>
        <Col md={12}>
          {/* <Breadcrumbs resource={resource} actionName="list" isBackButton /> */}
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Agent Users', path: false },
            ]}
          />
        </Col>
      </Row>
      {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
      {!loading && (
        <Card>
          <CardBody>
            <CardTitle>
              <h3>Agents</h3>
              <hr className="mt-3 mb-4" />
            </CardTitle>
            <Actions
              csvDownloadLink={csvDownloadLink}
              searchValue={search}
              onSearchChange={onSearchChange}
              tags={tags}
              setTags={setTags}
              setTagsFilter={setTagsFilter}
              tagsSuggestions={tagsSuggestions}
              addAgent={() => setAgentDialogVisible(true)}
              taxForm={taxForm}
              setTaxForm={setTaxForm}
            />
            <div style={{ margin: '0px -20px' }}>
              <MatTableList
                action={action}
                filters={tableFilters}
                resource={resource}
                setTag={setTag}
                date={new Date()}
                version={version}
                UserPropertyType={UserPropertyType}
                columnsSettings={{
                  fullName: {
                    width: 300,
                  },
                  email: {
                    width: 400,
                  },
                  companyName: {
                    width: 300,
                  },
                  numCreators: {
                    width: 150,
                  },
                  paymentMethod: {
                    width: 150,
                  },
                  validTaxForm: {
                    Header: 'Tax Form',
                    accessor: p => p.params['validTaxForm.value'] || false,
                    Cell: p => (
                      p.value ? 'On File' : 'Missing'
                    ),
                    disableSortBy: true,
                    className: 'align-self-center',
                    width: 100,
                    maxWidth: 100,
                  },
                  contextMenuAgent: {
                    Header: '',
                    accessor: () => null,
                    Cell: p => (
                      <ContextMenuInList
                        record={p.row.original}
                        refreshHubspot={refreshHubspot}
                      />
                    ),
                    disableSortBy: true,
                    className: 'align-self-center',
                    width: 30,
                    maxWidth: 30,
                  },
                }}
                showFilters={false}
                showTitle={false}
                isResizable={false}
                hideToolbar
                sortBy="last_name"
                direction="asc"
                meta={{ closeFunction: () => setVersion((new Date()).toString()) }}
                v={2}
                noActions
              />
            </div>
          </CardBody>
        </Card>
      )}
      <AgentForm
        modal={agentDialogVisible}
        modalTitle="New Agent"
        closeDialog={() => setAgentDialogVisible(false)}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(AgentUserListContainer);
