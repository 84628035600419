/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import RadioButton from '../../../../shared/components/RadioButton';
import DropZoneField from '../../../../shared/components/form/DropZone';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';

const links = [
  { title: 'Home', path: '/' },
  { title: 'Admin', path: false },
  { title: 'HasOffers', path: false },
];
const CpmConversions = () => {
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [existingAction, setExistingAction] = useState('truncate');
  const [files, setFiles] = useState([]);
  const [progressData, setProgressData] = useState({});

  useEffect(() => {
    const api = new ApiClient();
    setLoading(true);
    api.client.get('/api/portal/finance/get-advertisers').then((resp) => {
      const advertisers = resp.data.advertisers.filter(row => row.companyName === 'BetterHelp').map(row => ({ value: row._id, label: row.companyName }));
      setBrands(advertisers);
      setSelectedBrand(advertisers.find(row => row.label === 'BetterHelp'));
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, []);

  const upload = () => {
    setUploading(true);
    setProcessing(true);
    try {
      const data = new FormData();
      data.append('identity', identity);
      data.append('csv', files[0]);
      data.append('brand', selectedBrand.value);
      data.append('brandName', selectedBrand.label.trim());
      data.append('existingAction', existingAction);
      axios.post('/api/portal/conversions/post-cpm', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((res) => {
        setProcessing(false);
        if (res.data.success) {
          const {
            totalRows,
            totalSuccess,
            totalErrors,
          } = res.data;
          setProgressData({
            totalRows,
            totalSuccess,
            totalErrors,
          });
        } else {
          setUploading(false);
          setError(res.data.error);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const receiveProgress = (data) => {
    setProgressData(data);
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs links={links} isBackButton />
        </Col>
      </Row>
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col className="pt-1">
                <h3>CPM Conversions</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
            {loading && (
              <div className="text-center"><Spinner color="primary" size="lg" /></div>
            )}
            {!loading && uploading && (
              <Row>
                <Col>
                  {processing && (
                    <>
                      Uploading Conversions...
                      <ProgressBar
                        afterFunction={() => true}
                        topic="cpmConversions"
                        identity={identity}
                        receiveResults={receiveProgress}
                      />
                    </>
                  )}
                  Total number of rows processed: {progressData.totalRows}<br />
                  Total number of rows successfully uploaded: {progressData.totalSuccess}<br />
                  Total number of rows not uploaded: {progressData.totalErrors}<br />
                </Col>
              </Row>
            )}
            {error && (
              <Row>
                <Col>
                  {error}
                </Col>
              </Row>
            )}
            {!loading && !uploading && !error && (
              <>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <Label className="bold-text">Target Brand</Label>
                      <Select
                        value={selectedBrand}
                        options={brands}
                        onChange={(v) => { setSelectedBrand(v); }}
                        placeholder=""
                        menuPlacement="auto"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="d-none d-xl-block" xl="2" />
                  <Col lg="8" xl="6">
                    <Label className="bold-text">
                      Existing Conversions
                    </Label>
                    <FormGroup className="m-0">
                      <RadioButton
                        name="existingAction"
                        label="&nbsp;Truncate and delete all existing conversions"
                        value={existingAction}
                        radioValue="truncate"
                        onChange={(v) => { setExistingAction(v); }}
                      />
                    </FormGroup>
                    <FormGroup className="m-0">
                      <RadioButton
                        name="existingAction"
                        label="&nbsp;Append and add new conversions"
                        value={existingAction}
                        radioValue="append"
                        onChange={(v) => { setExistingAction(v); }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-4 pb-4">
                    <DropZoneField
                      input={{
                        id: 'uploadfile',
                        name: 'uploadfile',
                        value: files,
                        onChange: (f) => { setFiles(f.map((row) => { const _row = row; _row.preview = '/images/creator/documentsIcon.png'; return _row; })); },
                        accept: '.csv, application/vnd.ms-excel, text/csv',
                      }}
                      accept=".csv, application/vnd.ms-excel, text/csv"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button
                      className="btn-sm"
                      color="primary"
                      onClick={upload}
                      disabled={files.length === 0}
                    >
                      Upload Conversions
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </CardTitle>
        </CardBody>
      </Card>
    </Container>
  );
};

export default CpmConversions;
