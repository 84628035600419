import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  Label,
  FormGroup,
  Spinner,
} from 'reactstrap';
import ReactTags from 'react-tag-autocomplete';
import withNotice from '../../../../../../../App/store/with-notice';
import CheckBoxField from '../../../../../../../../shared/components/CheckBox';
import WVFormatter from '../../../../../../../../shared/helpers/WVFormatter';
import RadioButton from '../../../../../../../../shared/components/RadioButton';

const ManageTagsModal = ({
  isOpen,
  closeFunc,
  setVersion,
  prospectIds,
  total,
}) => {
  const reactNewTags = React.createRef();
  const reactDeletedTags = React.createRef();
  const [loading, setLoading] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [newTags, setNewTags] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);
  const [includeAll, setIncludeAll] = useState(false);

  const onDeleteNewTag = (i) => {
    const updatedTags = newTags.slice(0);
    updatedTags.splice(i, 1);
    setNewTags(updatedTags);
  };

  const onAddNewTag = (tag) => {
    const tagsNew = [].concat(newTags, tag);
    setNewTags(tagsNew);
  };

  const onDeleteCurrentTag = (i) => {
    const updatedDeletedTags = [...deletedTags];
    updatedDeletedTags.push(currentTags[i]);
    setDeletedTags(updatedDeletedTags);
    const updatedTags = currentTags.slice(0);
    updatedTags.splice(i, 1);
    setCurrentTags(updatedTags);
  };

  const toggleModal = () => {
    setNewTags([]);
    setDeletedTags([]);
    setTagsSuggestions([]);
    setCurrentTags([]);
    setIncludeAll(false);
    closeFunc();
  };

  const submit = async () => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search).toString();

    axios({
      method: 'post',
      url: '/api/portal/prospects/post-update-tags',
      data: {
        prospectIds,
        newTags,
        deletedTags,
        includeAll,
        query,
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        toggleModal();
      }
    });
    return true;
  };

  const fetchTags = () => {
    setLoadingTags(true);
    const query = new URLSearchParams(window.location.search).toString();
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospects-tags',
      params: {
        prospectIds,
        includeAll,
        query,
      },
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
        setCurrentTags(response.data.tags);
        setDeletedTags([]);
        setLoadingTags(false);
      }
    }).catch((err) => {
      setLoadingTags(false);
      console.log(err);
    });
  };

  useEffect(() => {
    if (isOpen) fetchTags();
    setCurrentTags([]);
    setNewTags([]);
    setDeletedTags([]);
    setIncludeAll(false);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) fetchTags();
  }, [includeAll]);

  useEffect(() => {
    if (newTags.length > 0 && deletedTags.length > 0) {
      setConfirmationText(`Add ${newTags.length} tag${newTags.length > 1 ? 's' : ''} and remove ${deletedTags.length} tag${deletedTags.length > 1 ? 's' : ''} from the selected Prospects.`);
    } else if (newTags.length > 0) {
      setConfirmationText(`Add ${newTags.length} tag${newTags.length > 1 ? 's' : ''} to the selected Prospects.`);
    } else if (deletedTags.length > 0) {
      setConfirmationText(`Remove ${deletedTags.length} tag${deletedTags.length > 1 ? 's' : ''} from the selected Prospects.`);
    } else {
      setConfirmed(false);
      setConfirmationText('');
    }
  }, [newTags, deletedTags]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className=" theme-light"
    >
      <ModalHeader toggle={toggleModal} tag="h4">Manage Tags</ModalHeader>
      <ModalBody className="pb-0">
        <div className="modal__body finance-form">
          {prospectIds.length !== total && (
            <>
              <div>{`You have selected ${prospectIds.length} Prospect${prospectIds.length !== 1 ? 's' : ''} to manage its tags.`}</div>
              <div className="theme-light radio-in-prospects">
                {`Select all of ${WVFormatter.formatIntNumber(total)} Prospects?`}&nbsp;&nbsp;&nbsp;
                <RadioButton
                  name="action"
                  label="&nbsp;Yes"
                  value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                  radioValue="includeAll"
                  onChange={(e) => {
                    setIncludeAll(e === 'includeAll');
                  }}
                />
                <RadioButton
                  name="action"
                  label="&nbsp;No"
                  value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                  radioValue="notIncludeAll"
                  onChange={(e) => {
                    setIncludeAll(e === 'includeAll');
                  }}
                />
              </div>
            </>
          )}
          <Row>
            <Col>
              <Label>Add New Tag(s)</Label>
              <ReactTags
                ref={reactNewTags}
                tags={newTags}
                suggestions={tagsSuggestions}
                onDelete={onDeleteNewTag}
                onAddition={onAddNewTag}
                delimiters={['Enter', ',']}
                allowNew
                placeholderText="New Tags"
                addOnBlur
              />
            </Col>
          </Row>
          {loadingTags
            ? (
              <div className="text-center mt-3 mb-3">
                <Spinner color="primary" size="lg" />
              </div>
            ) : (
              <Row className="mt-3 mb-3">
                <Col>
                  <Label>Remove Existing Tag(s)</Label>
                  <ReactTags
                    ref={reactDeletedTags}
                    tags={currentTags}
                    onDelete={onDeleteCurrentTag}
                    onAddition={() => {}}
                    delimiters={['Enter', ',']}
                    placeholderText=""
                    addOnBlur
                  />
                </Col>
              </Row>
            )
          }
          {(newTags.length > 0 || deletedTags.length > 0) && (
            <FormGroup
              className="font-weight-bold"
              style={{ fontSize: '14px' }}
            >
              <CheckBoxField
                onChange={event => setConfirmed(event.target?.checked || false)}
                value={confirmed}
                name="confirmationCheckbox"
                label={confirmationText}
              />
            </FormGroup>
          )}
        </div>
        {loading && includeAll && (
          <div className="w-100 text-center">
            <p className="bold-text" style={{ color: '#70bbfd' }}>This may take a while...</p>
          </div>
        )}
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading || loadingTags || !confirmed}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" onClick={toggleModal}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};


ManageTagsModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  prospectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default withNotice(ManageTagsModal);
