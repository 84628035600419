import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const LoadingReport = ({ loading, title, description }) => loading ? (
  <div className="text-center text-blue my-3" >
    <div><Spinner size="large" /></div>
    <h5 className="mt-1">{title || ''}</h5>
    <h5>{description || ''}</h5>
  </div>
) : null;

LoadingReport.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

LoadingReport.defaultProps = {
  loading: false,
  title: 'Generating the report...',
  description: 'This may take several minutes depending on the date range specified.'
};

export default LoadingReport;


