import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row,
} from 'reactstrap';
import { axios } from 'ApiClient';
import Select from 'react-select';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';

const AdminTeam = ({ addNotice }) => {
  const [teams, setTeams] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTeams = () => {
    setLoading(true);
    axios.get('/api/portal/global/get-all-admin-data').then((response) => {
      if (response.data.success) {
        setUserOptions(response.data.adminUsers.map(user => ({ value: user._id, label: `${user.firstName || ''} ${user.lastName || ''}` })));
        const adminTeams = response.data.adminTeams.map(team => ({
          ...team,
          teamUsers: team.teamUsers.length ? team.teamUsers.map(item => ({ value: item._id, label: `${item.firstName || ''} ${item.lastName || ''}` })) : [],
        }));
        setTeams(adminTeams);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  // useEffect(() => {
  //   // console.log('USERS: ', users);
  //   console.log('TEAMS1234455: ', teams);
  // }, [teams]);

  const changeTeam = (index, type, value) => {
    const newTeams = teams.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [type]: value,
        };
      }
      return item;
    });
    console.log('NEW TEAMS', newTeams);
    setTeams(newTeams);
  };

  const submit = () => {
    setLoading(true);
    axios.post('/api/portal/global/post-save-team-data', {
      teams,
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        addNotice({
          message: 'Teams were updated successfully.',
          type: 'success',
          duration: 5,
        });
      } else {
        setLoading(false);
        addNotice({
          message: 'Can not update Teams data.',
          type: 'error',
          duration: 5,
        });
      }
    });
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Admin', path: false },
              { title: 'Teams', path: false },
            ]}
            isBackButton
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          <CardTitle>
            <h3>Admin Teams</h3>
            <hr className="mt-3 mb-4" />
          </CardTitle>
          {teams.length > 0 && teams.map((team, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Row key={index}>
              <Col md={1} />
              <Col md={3}>
                <FormGroup>
                  <Label className="bold-text">
                    Team Name
                  </Label>
                  <Input
                    id={team.teamName}
                    name="teamName"
                    value={team.teamName}
                    onChange={(event) => { console.log(event.target.value); changeTeam(index, 'teamName', event.target.value); }}
                    disabled={loading}
                    style={{ width: '100%' }}
                  />
                </FormGroup>
              </Col>
              <Col md={1} />
              <Col md={6}>
                <FormGroup>
                  <Label className="bold-text">
                    Team Members
                  </Label>
                  <Select
                    isMulti
                    options={userOptions}
                    value={team.teamUsers}
                    onChange={(values) => { console.log(values); changeTeam(index, 'teamUsers', values); }}
                    isDisabled={loading}
                  />
                </FormGroup>
              </Col>
              <Col md={1} />
            </Row>
          ))}
          <ButtonToolbar style={{ justifyContent: 'center' }}>
            <Button className="btn-sm" color="primary" onClick={submit} >
              {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
              <span>Save</span>
            </Button>
          </ButtonToolbar>
        </CardBody>
      </Card>
    </Container>
  );
};

AdminTeam.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(AdminTeam);
