/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, Spinner } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import ViewHelpers from 'admin-bro/lib/backend/utils/view-helpers';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import { fillUsers, getNotification, updateCreatorNotification, editNotificationCreatorsBulk } from '../../../../redux/reducers/admin/NotificationsReducer';
import StatusComponent from './components/custom/StatusComponent';
import StatusComponentUserSelect from './components/custom/StatusComponentUserSelect';
// import StatusBulkComponent from './components/StatusBulkComponent';
import CheckboxFilter from '../../../../shared/components/CheckboxFilter';
import ButtonWithSpinner from '../../../../shared/components/ButtonWithSpinner';

const columnTransform = {
  fullName: {
    title: 'Full Name',
    valueFunc: record => `${record.params.first_name} ${record.params.last_name}`,
    sort: record => `${record.params.first_name} ${record.params.last_name}`,
    searchFunc: (record, search) => (record ? `${record.params.first_name} ${record.params.last_name}`.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),
    filter: (record) => {
      if (record.params.payment) {
        const { payment } = record.params;
        if (payment.stripe && payment.stripe.accountId) {
          return 'Stripe';
        }
        if (payment.billCom && payment.billCom.accountId) {
          return 'Bill.com';
        }
      }
      return 'Not Connected';
    },
  },
  email: {
    title: 'Email',
    valueFunc: record => record.params.email,
    sort: record => record.params.email,
    searchFunc: (record, search) => (record ? record.params.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),
  },
  showNotification: {
    title: 'Show Notification',
    Component: StatusComponent,
    sort: record => record.selected,
    // filter: record => 0,
  },
  creatorOverride: {
    title: 'Creator Override Show Notification',
    Component: StatusComponentUserSelect,
    sort: record => (record.selectedNotification ? record.selectedNotification.showOverride : '0'),
    // filter: (record) => 0,
  },
};
const ResourceAction = (props) => {
  const {
    resources,
    loading,
    notification,
    users,
    version,
    getNotificationDispatch,
    fillUsersDispatch,
    updateRecord,
    bulkChangeStatus,

  } = props;
  console.log(notification);
  const { notificationId } = props.match.params;
  const [selected, setSeleted] = useState([]);
  const [stripeStatusFilters, setstripeStatusFilters] = useState({
    Stripe: true,
    'Bill.com': true,
    'Not Connected': true,
  });

  useEffect(() => {
    const myAbortController = new AbortController();
    console.log(notificationId);
    getNotificationDispatch();
    fillUsersDispatch();
    return () => {
      myAbortController.abort();
    };
  }, [notificationId]);
  const bulkChangeStatusFunc = (value) => {
    bulkChangeStatus(notification, selected, value);
  };
  const resource = resources.find(r => r.id === 'CreatorNotification');

  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <Breadcrumbs resource={resource} actionName="list" isBackButton />
        </Col>
      </Row>
      <Row>
        <Col>
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Col>
                <div className="card__title">
                  <h5 className="bold-text">{notification && notification.params ? `${notification.params.notificationName} Dashboard` : null}</h5>
                </div>
              </Col>
              {loading.users && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
              {!loading.users && (
                <MatTableForRedux
                  dataArray={users}
                  columns={columnTransform}
                  version={version}
                  rowsPerPage={10}
                  filters={{
                    fullName: stripeStatusFilters,
                  }}
                  selectedEvent={(rows) => {
                    const selectedIds = [];
                    [...rows.entries()].forEach(([key, value]) => {
                      if (value) {
                        selectedIds.push(key);
                      }
                    });
                    setSeleted(selectedIds);
                  }}
                  selectable
                  updateRecordFunc={updateRecord}
                  filtersSection={[{
                    component: (
                      <CheckboxFilter
                        filterProp={stripeStatusFilters}
                        filterTitle="Payment Method"
                        filterAction={({ data }) => setstripeStatusFilters(data)}
                        style={{ paddingTop: 0 }}
                        classname="btn-sm"
                        key="paymentMethod"
                      />
                    ),
                  }]}
                  actionSection={[
                    {
                      component: (
                        <ButtonWithSpinner onClick={() => bulkChangeStatusFunc('show')} disabled={selected.length === 0 || loading.updating} loading={loading.updating} color="primary" key="show" >Show Notification</ButtonWithSpinner>
                      ),
                    },
                    {
                      component: (
                        <ButtonWithSpinner onClick={() => bulkChangeStatusFunc('hide')} disabled={selected.length === 0 || loading.updating} loading={loading.updating} color="primary" key="hide" >Hide Notification</ButtonWithSpinner>
                      ),
                    },
                  ]}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  resources: state.resources,
  loading: state.notifications.loading,
  users: state.notifications.users,
  notification: state.notifications.notification,
  version: state.notifications.lastFetched.toString(),
});
const mapDispatchToProps = (dispatch, ownProps) => {
  console.log(ownProps);
  return {
    getNotificationDispatch: () => dispatch(getNotification(ownProps.match.params.notificationId)),
    fillUsersDispatch: () => dispatch(fillUsers(ownProps.match.params.notificationId)),
    updateRecord: action => dispatch(updateCreatorNotification(action)),
    bulkChangeStatus: (notification, selected, value) => dispatch(editNotificationCreatorsBulk(notification.id, selected, value)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ResourceAction);
