/* eslint-disable filenames/match-exported */
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IconButton, withStyles } from '@material-ui/core';
import InfoCircleOutlineIcon from 'mdi-react/InfoCircleOutlineIcon';
import Tooltip from '@material-ui/core/Tooltip';
import formatter from '../../../../../shared/helpers/WVFormatter';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import DownloadCSVButton from '../../../../../shared/tables/table/DownloadCSVButton';

const getBackgroundColor = (data, monthlyCompletion) => {
  if (data.target > 0) {
    const percentage = formatter.roundToInt((data.actual / data.target) * 100);
    if (percentage >= monthlyCompletion) {
      return '#01ff00'
    } else if (percentage < monthlyCompletion && percentage >= (monthlyCompletion - 20)) {
      return '#ffff00';
    } else return '#fe0000';
  }
  return '#ffffff';
}

const getCellValueForCSV = (data, monthlyCompletion, selectedProperties) => {
  if (data.target > 0) {
    const percentage = `${formatter.roundToInt((data.actual / data.target) * 100)}%`;
    const count = `${data.actual} / ${data.target}`;
    if (selectedProperties?.count && selectedProperties?.percent) {
      return `${count} (${percentage})`;
    } else if (selectedProperties?.count) {
      return count;
    }
    return percentage;
  } else {
    return 'Target is 0';
  }
};

const getCellContent = (data, monthlyCompletion, selectedProperties) => {
  if (data.target > 0) {
    const percentage = formatter.roundToInt((data.actual / data.target) * 100);
    const count = `${formatter.formatIntNumber(data.actual)} / ${formatter.formatIntNumber(data.target)}`;
    return (
      <>
        {selectedProperties?.count && (
          <p className="text-center" style={{ color: 'black', fontSize: 'larger' }}>{count}</p>
        )}
        {selectedProperties?.percent && (
          <p className="text-center" style={{ color: 'black', fontSize: 'larger' }}>{`${percentage}%`}</p>
        )}
      </>
    );
  } else {
    return (
      <div className="color-danger text-center">
        <span>Target is 0</span>
      </div>
    );
  }
};

const getRowComponent = (row, monthlyCompletion, selectedProperties) => {
  return (
    <tr key={row.brand}>
      <td className="bold-text px-4">{row.brand}</td>
      <td
        className="px-4 py-2"
        style={{ backgroundColor: getBackgroundColor(row.contracts, monthlyCompletion) }}
      >
        {getCellContent(row.contracts, monthlyCompletion, selectedProperties)}
      </td>
      <td
        className="px-4 py-2"
        style={{ backgroundColor: getBackgroundColor(row.content, monthlyCompletion) }}
      >
        {getCellContent(row.content, monthlyCompletion, selectedProperties)}
      </td>
      <td
        className="px-4 py-2"
        style={{ backgroundColor: getBackgroundColor(row.creators, monthlyCompletion) }}
      >
        {getCellContent(row.creators, monthlyCompletion, selectedProperties)}
      </td>
      <td
        className="px-4 py-2"
        style={{ backgroundColor: getBackgroundColor(row.conversions, monthlyCompletion) }}
      >
        {getCellContent(row.conversions, monthlyCompletion, selectedProperties)}
      </td>
    </tr>
  );
};

const getMonthlyCompletionText = (monthlyCompletion, endDate) => `${monthlyCompletion}% of monthly completion - ${moment(endDate).format('M/D/YYYY')}`

const WideTooltip = withStyles({
  tooltip: {
    width: '500px',
  },
})(Tooltip);

const HeaderItem = ({ label, description }) => (
  <div>
    <span style={{ width: '100%', fontSize: '16px' }}>{label}</span>
    <WideTooltip title={<div style={{ fontSize: '12px', maxWidth: '500px', lineHeight: 1.5 }}>{description}</div>}>
      <IconButton size="small" style={{ marginTop: '-3px' }}>
        <InfoCircleOutlineIcon size="17" className='text-blue'/>
      </IconButton>
    </WideTooltip>
  </div>
);

HeaderItem.propTypes = {
  label: PropTypes.string.isRequired,
};

const POWL5CsTable = ({
  monthlyCompletion,
  data,
  endDate,
}) => {
  const [selectedProperties, setSelectedProperties] = useState({
    count: true,
    percent: true
  });

  const handleChangeSelectedProperties = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setSelectedProperties({
        ...selectedProperties,
        [name]: checked,
      });
    }
  };

  const getHeaderItem = (label, description) => (
    <HeaderItem
      label={label}
      description={description()}
    />
  );

  return (
    <div>
      <Row className="mt-3">
        <Col className="d-flex">
          <h4 className="font-weight-bold">POWL Numbers</h4>
          <div
            style={{ marginLeft: '40px', marginBottom: '10px' }}
          >
            <CheckBoxField
              name="count"
              label="Show Counts"
              value={selectedProperties.count}
              onChange={event => handleChangeSelectedProperties(event, 'count')}
              className="inline"
            />
            <CheckBoxField
              name="percent"
              label="Show Percentages"
              value={selectedProperties.percent}
              onChange={event => handleChangeSelectedProperties(event, 'percent')}
              className="inline ml-4"
            />
          </div>
          <div style={{ marginLeft: '15px', marginTop: '-6px' }}>
            <DownloadCSVButton
              records={data}
              className=""
              fileName="POWL5CsReport.csv"
              csvArrayColumns={[
                {
                  label: 'Brand',
                  func: row => row.brand,
                },
                {
                  label: 'Contracts',
                  func: record => getCellValueForCSV(record.contracts, monthlyCompletion, selectedProperties),
                },
                {
                  label: 'Content',
                  func: record => getCellValueForCSV(record.content, monthlyCompletion, selectedProperties),
                },
                {
                  label: 'Active Creators',
                  func: record => getCellValueForCSV(record.creators, monthlyCompletion, selectedProperties),
                },
                {
                  label: 'Conversions',
                  func: record => getCellValueForCSV(record.conversions, monthlyCompletion, selectedProperties),
                },
              ]}
            >
              Download CSV
            </DownloadCSVButton>
          </div>
        </Col>
      </Row>
      <Row key="3">
        <Col md={12}>
          <table border="1">
            <tbody style={{ border: '2px', borderColor: 'black' }}>
              <tr>
                <td> </td>
                <td className="bold-text px-4 py-2 text-center">
                  {getHeaderItem('Contracts', () => (<>Count of all contracts that have been signed and marked as <mark className="text-white">Complete</mark></>))}
                </td>
                <td className="bold-text px-4 py-2 text-center">
                  {getHeaderItem('Content', () => (<>Count of all content submitted to the Media Content system</>))}
                </td>
                <td className="bold-text px-4 py-2 text-center">
                  {getHeaderItem('Active Creators', () => (<>Count of all creators that have at least 1 revenue conversion in the specified date range. Note: This count excludes Aura CPM creators</>))}
                </td>
                <td className="bold-text px-4 py-2 text-center">
                  {getHeaderItem('Conversions', () => (<>
                      <p><strong>CPA, Upfront, Hybrid Deals (Keen and Mint Mobile)</strong>
                        <ul className="list bullet">
                          <li>Count of all actions in the Impact system in the specified date range</li>
                        </ul>
                      </p>
                      <p><strong>CPA, Upfront, Hybrid Deals</strong>
                        <ul className="list bullet">
                          <li>Count of all conversions from HasOffers where <mark className="text-white">Revenue {'>'} $0</mark> and <mark className="text-white">Payout {'>'}= $0</mark></li>
                        </ul>
                      </p>
                      <p>
                        <strong className="pt-3">Upfront CPM Deals (BetterHelp Only)</strong><br/>
                        <ul className="list bullet">
                          <li>Count of all conversions with a status of <mark className="text-white">Paid</mark></li>
                          <li>All <mark className="text-white">Sign Up</mark> and <mark className="text-white">Refunded</mark> conversions are ignored</li>
                          <li>CPM conversion data is uploaded and saved daily between <strong>9-10 am ET</strong></li>
                        </ul>
                      </p>
                      <p>
                        <strong className="pt-3">Upfront CPM Deals (Aura Only)</strong><br/>
                        <ul className="list bullet">
                          <li>When Aura is selected in the <strong>Show total number of conversions for selected Brands</strong> filter, Aura CPM conversions (conversions with <mark className="text-white">Revenue = $0</mark> and <mark className="text-white">Payout = $0</mark>) will be included in conversion count</li>
                          <li>When Aura is not selected, only Aura CPA, Upfront, and Hybrid conversions will be counted as stated above</li>
                        </ul>
                      </p>
                    </>))}
                </td>
              </tr>
              {data.map(row => getRowComponent(row, monthlyCompletion, selectedProperties))}
              <tr style={{
                borderBottomStyle: 'hidden',
                borderRightStyle: 'hidden',
                borderLeftStyle: 'hidden'
              }}>
                <td colSpan="5" className="bold-text text-right py-1">{getMonthlyCompletionText(monthlyCompletion, endDate)}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
};

POWL5CsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  monthlyCompletion: PropTypes.number.isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default POWL5CsTable;
