import ApiClient from 'ApiClient';
import { unflatten } from 'flat';
import Record from './Record';

class RecordHook extends Record {
  constructor({
    record = {},
    stateName = '',
    resource = '',
    schema = null,
  }, setRecord) {
    super({}, null);
    this.api = new ApiClient();
    const defaultProps = this.initRecord();
    this.setRecordFunction = setRecord;
    this.params = record.params || defaultProps.record.params || {};
    this.errors = record.errors || defaultProps.record.errors || {};
    this.populated = record.populated || defaultProps.record.populated || {};
    this.stateName = stateName || defaultProps.stateName;
    this.resourceName = resource || defaultProps.resource;
    this.schema = schema || defaultProps.schema;
    this.id = this.params._id || null;
  }

  set(name, value) {
    this.params[name] = value;
    this.errors[name] = undefined;
    this.setRecordFunction({
      params: this.params,
      errors: this.errors,
      id: this.id,
      populated: this.populated,
    });
  }
  async fetchRecord(id) {
    const res = await this.api.recordAction({
      resourceId: this.resourceName,
      recordId: id,
      actionName: 'show',
    });
    this.setRecord(unflatten(res.data.record));
  }
  setRecord({
    params,
    errors,
    id,
    populated,
  }) {
    console.log(this);
    this.params = params;
    this.errors = errors;
    this.populated = populated;
    this.id = id;
    this.setRecordFunction({
      params: this.params,
      errors: this.errors,
      id: this.id,
      populated: this.populated,
    });
  }

  addError(prop, error) {
    this.errors[prop] = { message: error };
    this.setRecordFunction({
      params: this.params,
      errors: this.errors,
      id: this.id,
      populated: this.populated,
    });
  }
  removeError(prop) {
    if (this.errors[prop]) {
      delete this.errors[prop];
    }
    this.setRecordFunction({
      params: this.params,
      errors: this.errors,
      id: this.id,
      populated: this.populated,
    });
  }
}
export default RecordHook;
