import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import TableCellContextMenu from '../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';
import DisConnectModal from '../../../Agents/AgentBillCom/DisConnectModal';

const ContextMenuInList = ({
  influencer,
  meta,
}) => {
  const { hsContactVid } = influencer;
  const [spyLoginLoading, setSpyLoginLoading] = useState(false);
  const [disconnectPaymentDialogVisible, showDisconnectPayment] = useState(false);

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const spyLogin = () => {
    setSpyLoginLoading(true);
    axios.post(
      '/api/portal/influencers/spy-login',
      {
        userId: influencer._id,
      },
    ).then((response) => {
      const { spyLoginToken, baseUrl } = response.data;
      if (spyLoginToken && baseUrl) {
        goToPage(`${baseUrl}/login-secret/${spyLoginToken}`, '_blank');
      }
      setSpyLoginLoading(false);
    }).catch((error) => {
      console.log(error);
      setSpyLoginLoading(false);
    });
  };

  const contextMenuOptions = [
    { label: `${spyLoginLoading ? 'Please wait...' : 'Spy Login'}`, handler: spyLogin },
    { type: 'divider', label: 'divider1' },
    { label: 'View Creator Profile', handler: () => goToPage(`/influencer/profile/${influencer._id}`) },
  ];

  if (hsContactVid) {
    contextMenuOptions.push({ label: 'View HubSpot Contact', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${hsContactVid}/`) });
  }

  contextMenuOptions.push({ type: 'divider', label: 'divider2' });
  contextMenuOptions.push({ label: 'Connect Payment Method', handler: () => goToPage(`/resources/Influencer/creator-bill-com/${influencer._id}`) });
  contextMenuOptions.push({ label: 'Disconnect Payment Method', handler: () => showDisconnectPayment(true) });

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />
      <DisConnectModal
        modal={disconnectPaymentDialogVisible}
        toggle={() => showDisconnectPayment(false)}
        agent={{ params: influencer, id: influencer._id }}
        meta={meta}
        type="creator"
      />
    </>
  );
};

ContextMenuInList.propTypes = {
  influencer: PropTypes.objectOf(PropTypes.any).isRequired,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContextMenuInList;
