/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

export default class OfferInList extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { record } = this.props;

    let offerName = null;
    try {
      offerName = record.populated.offer.params.name;
    } catch (e) {
      offerName = '';
    }

    let linkTo = null;
    try {
      linkTo = `/resources/Offer/records/${encodeURIComponent(record.populated.offer.params._id)}/show`;
    } catch (e) {
      linkTo = null;
    }

    return (
      <div>
        <Link target="_blank" to={linkTo}>{ offerName }</Link>
      </div>
    );
  }
}
