import { axios } from 'ApiClient';
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ButtonToolbar,
  Button,
} from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import ProgressBar from '../../../../../../shared/helpers/ProgressBarWithObject';

const columnTransform = {
  contactName: {
    title: 'Contact Name',
    valueFunc: ({ contactName, contactUrl }) => (<a href={contactUrl} target="_blank" rel="noreferrer" >{contactName}</a>),
  },
  dealName: {
    title: 'Deal Name',
    valueFunc: ({ dealName, dealUrl }) => (<a href={dealUrl} target="_blank" rel="noreferrer" >{dealName}</a>),
  },
  error: {
    title: 'Error',
    valueFunc: ({ error }) => error,
  },
};

const downloadCsv = [
  {
    label: 'Contact Name',
    func: ({ contactName }) => contactName || '',
  },
  {
    label: 'Deal Name',
    func: ({ dealName }) => dealName || '',
  },
  {
    label: 'Contact Url',
    func: ({ contactUrl }) => contactUrl || '',
  },
  {
    label: 'Deal Url',
    func: ({ dealUrl }) => dealUrl || '',
  },
  {
    label: 'Error',
    func: ({ error }) => error || '',
  },
];


const FetchBillsModal = ({ show, showModal }) => {
  const uniqueId = React.useMemo(() => moment().toISOString(), []);
  const [showFetchModalLoadingButton, setShowFetchModalLoadingButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const fetchBills = async () => {
    await axios({
      method: 'post',
      url: '/api/portal/bill/post-fetch-all-bills',
      data: { type: 'bill', identity: uniqueId },
    });
    setShowFetchModalLoadingButton(true);
  };
  useEffect(() => {
    if (show) {
      setShowFetchModalLoadingButton(false);
      setErrors([]);
      fetchBills();
    }
  }, [show]);

  return (
    <Modal
      isOpen={show}
      modalClassName="ltr-support"
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">Fetching Bills</h4>
      </div>
      <div className="modal__body">
        <ProgressBar
          afterFunction={() => setShowFetchModalLoadingButton(true)}
          topic={`reFetchAllBils${uniqueId}`}
          receiveResults={(res) => {
            if (res.error) {
              setErrors((e) => {
                const newErrors = e.concat([]);
                if (res.error) {
                  newErrors.push({ ...res });
                }
                return newErrors;
              });
            }
          }}
        />
        {errors?.length > 0 ? (
          <MatTableForRedux
            dataArray={errors}
            selectable={false}
            columns={columnTransform}
            version={errors.length.toString()}
            rowsPerPage={5}
            csvDownload={{
                fileName: 'BillsErrors.csv',
                csvArrayColumns: downloadCsv,
              }}
          />
          ) : null}
      </div>
      {showFetchModalLoadingButton && (
      <ButtonToolbar className="modal__footer">
        <Button
          className="modal_cancel btn-sm"
          onClick={() => {
            setShowFetchModalLoadingButton(false);
            showModal(false);
          }}
        >
          Close
        </Button>
      </ButtonToolbar>
        )}
    </Modal>
  );
};
FetchBillsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  // record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default FetchBillsModal;
