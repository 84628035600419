import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';

const StatItem = ({ items }) => (
  <div
    style={{
      margin: '4px auto 0',
      padding: '5px 15px 0',
      border: '1px solid #cccccc',
      borderRadius: '5px',
      height: '100%',
      maxWidth: '200px',
    }}
  >
    {items.map(({ label, value }, i) => (
      <div style={{ textAlign: 'center', color: 'black' }} key={uid(i)} >
        <div
          style={{
            fontSize: '20px',
            fontWeight: '700',
            width: '100%',
            textAlign: 'center',
            marginTop: '12px',
          }}
        >
          {value}
        </div>
        <h6
          style={{
            width: '100%',
            fontSize: '16px',
          }}
        >
          {label}
        </h6>
      </div>
    ))}
  </div>);
StatItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.objectOf(PropTypes.any),
    ]).isRequired,
  })).isRequired,
};
export default StatItem;
