/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { CKEditor } from 'ckeditor4-react';

const ItemTypes = {
  CARD: 'card',
};

const BrandOpportunityHighlightCard = ({
  styles, highlight, index, moveItem, handleInputChange, removeItem,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    type: ItemTypes.CARD,
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { type: ItemTypes.CARD, id: highlight.order, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  let opacityStyle = {};
  if (isDragging) {
    opacityStyle = {
      opacity: 0.5,
    };
  }

  drag(drop(ref));

  return (
    <div ref={ref} key={`div-${index}`} style={{ ...styles.fileZone, ...opacityStyle }}>
      {/* <Input name="itemText" value={highlight.itemText} onChange={(e) => { handleInputChange(e, index); }} /> */}
      {/* <br /> */}
      <CKEditor
        config={{versionCheck: false}}
        id="bodyEditor"
        name="itemText"
        type="inline"
        activeClass="form-control"
        initData={highlight.itemText ? highlight.itemText : ''}
        onChange={(e) => { handleInputChange(e, index, 'itemText'); }}
        style={{
          display: 'block',
          width: '100%',
          padding: '0.375rem 0.75rem',
          fontSize: '1rem',
          fontWeight: 400,
          color: '#495057',
          backgroundColor: '#fff',
          backgroundClip: 'padding-box',
          border: '1px solid #ced4da',
          borderRadius: '0.25rem',
          transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        }}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
      <a style={{ color: '#ff0000' }} role="button" onClick={() => { removeItem(index); }}>Delete&nbsp;&nbsp;<i className="fas fa-trash fas-trash" /></a>
      <br />
    </div>
  );
};

export default BrandOpportunityHighlightCard;
