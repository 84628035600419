import moment from 'moment';

export default ({
  startDate,
  endDate,
  selectedAdmins,
  itemsByArray,
  total,
}) => {
  const startMoment = moment(startDate).startOf('day');
  const endMoment = moment() > moment(endDate).endOf('day') ? moment(endDate).endOf('day') : moment().endOf('day');
  const chartData = {};
  const chartDataMonth = {};
  const chartDataWeek = {};
  const days = endMoment.diff(startMoment, 'day');
  const months = endMoment.diff(startMoment, 'month');
  const weeks = endMoment.diff(startMoment, 'weeks') + 1;
  // const [keysInValue, total] = selectedOption.value.split('-');
  const top7ByValue = {};
  selectedAdmins.forEach(({ data, option }) => {
    Object.keys(data).forEach((key) => {
      const periodsDataItem = data[key];
      if (!top7ByValue[option._id]) top7ByValue[option._id] = { value: 0, data, option };
      top7ByValue[option._id].value += periodsDataItem;
    });
  });
  // setTotalAll(Object.values(top7ByValue)[0]?.value);
  // console.log(top7ByValue);
  const values = Object.values(top7ByValue).sort((a, b) => b.value - a.value)
    .slice(0, 7)
    .map(({ option, data }) => ({ option, data }));
  for (let i = 0; i <= days; i += 1) {
    const momentDate = startMoment.clone().add(i, 'day');
    const key = momentDate.format('YYYY-MM-DD');
    chartData[key] = { date: momentDate.format('M/D/YY'), items: {} };
    values.forEach(({ option }) => { chartData[key][option._id] = 0; });
  }
  for (let i = 0; i <= months; i += 1) {
    const momentDate = startMoment.clone().add(i, 'month');
    const key = momentDate.format('YYYY-MM');
    chartDataMonth[key] = { date: momentDate.format('M/YY'), items: {} };
    values.forEach(({ option }) => { chartDataMonth[key][option._id] = 0; });
  }
  for (let i = 0; i <= weeks; i += 1) {
    const momentDate = startMoment.clone().add(i, 'week').startOf('week');
    const key = momentDate.format('YYYY-w');
    // console.log(key, moment(key, 'YYYY-w').startOf('week'), endMoment);
    if (momentDate > endMoment) break;
    chartDataWeek[key] = { date: `${moment(key, 'YYYY-w').startOf('week').format('M/D/YY')} - ${moment(key, 'YYYY-w').endOf('week').format('M/D/YY')}`, items: {} };
    values.forEach(({ option }) => { chartDataWeek[key][option._id] = 0; });
  }
  // console.log(chartDataWeek);
  values.forEach(({ data, option }) => {
    Object.keys(data).forEach((key) => {
      const week = moment(key).format('YYYY-w');
      const byMonthKey = key.substring(0, 7);
      console.log(byMonthKey, 'By Month');
      const periodsDataItem = data[key];
      if (!chartData[key]) chartData[key] = { date: key };
      chartData[key][option._id] = periodsDataItem;
      if (!chartDataMonth[byMonthKey]) chartDataMonth[byMonthKey] = { date: moment(key).format('M/YY'), items: {} };
      if (!chartDataMonth[byMonthKey][option._id]) chartDataMonth[byMonthKey][option._id] = 0;
      chartDataMonth[byMonthKey][option._id] += periodsDataItem;
      if (!chartDataWeek[week]) chartDataWeek[week] = { date: `${moment(key, 'YYYY-w').startOf('week').format('M/D/YY')} - ${moment(key, 'YYYY-w').endOf('week').format('M/D/YY')}`, items: {} };
      if (!chartDataWeek[week][option._id]) chartDataWeek[week][option._id] = 0;
      chartDataWeek[week][option._id] += periodsDataItem;
      (itemsByArray ? (itemsByArray[key] || []) : []).forEach((a) => {
        // console.log([byMonthKey, chartDataMonth, a, chartDataMonth[byMonthKey].items]);
        chartDataMonth[byMonthKey].items[a] = true;
        // console.log([byMonthKey, chartDataMonth, a, chartDataMonth[byMonthKey].items]);
        chartDataWeek[week].items[a] = true;
        // console.log([byMonthKey, chartDataMonth, a, chartDataMonth[byMonthKey].items]);
      });
    });
  });
  console.log(chartDataMonth, 'By Month');
  if (total === 'total' && !itemsByArray) {
    let beforeValue = {};
    Object.keys(chartData).forEach((day) => {
      const valueForDays = chartData[day];
      // const beforeValueItem = { ...chartData[day] };
      // console.log(beforeValue, 'prev');
      Object.keys(valueForDays).forEach((key) => {
        if (key === 'date') return false;
        chartData[day][key] += Number(beforeValue[key] || 0);
        return true;
      });
      // console.log(beforeValue, 'prev');
      beforeValue = { ...chartData[day] };
      // console.log(beforeValue, 'next');
    });
  }
  if (total === 'total' && itemsByArray) {
    // console.log(itemsByArray);
    const beforeValue = {};
    Object.keys(chartData).forEach((day) => {
      const valuesForCurrentDay = itemsByArray[day] || [];
      valuesForCurrentDay.forEach((i) => { beforeValue[i] = true; });
      // console.log(day, Object.keys(beforeValue).length);
      const valueForDays = chartData[day];
      // const beforeValueItem = { ...chartData[day] };
      // console.log(beforeValue, 'prev');
      Object.keys(valueForDays).forEach((key) => {
        if (key === 'date') return false;
        chartData[day][key] = Object.keys(beforeValue).length;
        return true;
      });
    });
  }
  // console.log(chartDataMonth, chartDataWeek);
  if (total === 'month' && itemsByArray) {
    Object.keys(chartDataMonth).forEach((day) => {
      chartDataMonth[day].na = Object.keys(chartDataMonth[day].items).length;
    });
  }
  if (total === 'week' && itemsByArray) {
    Object.keys(chartDataWeek).forEach((day) => {
      chartDataWeek[day].na = Object.keys(chartDataWeek[day].items).length;
    });
  }
  // setTop7(values);
  console.log(Object.values(chartData));
  if (total === 'month') return Object.values(chartDataMonth);
  if (total === 'week') return Object.values(chartDataWeek);
  return Object.values(chartData);
  // return Object.values(chartData);
};
