import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';

const CreatorDealPortalHubilDuplicateModal = ({
  modal,
  setModalVisible,
  hsObjectId,
}) => (
  <Modal
    isOpen={modal}
    toggle={() => { setModalVisible(false); }}
    className="delete-modal"
    style={{ minWidth: '550px' }}
  >
    <ModalHeader toggle={() => { setModalVisible(false); }} tag="h4">
      Possible Duplicate Contact
    </ModalHeader>
    <ModalBody style={{ marginTop: '20px' }}>
      <Row>
        <Col xs="3 text-center">
          <i className="fa fa-info-circle" style={{ color: '#70bbfd', fontSize: '5em' }} />
        </Col>
        <Col><h5>A HubSpot Contact already exists with this media channel URL. Double check to be sure you are not creating a duplicate Contact.</h5></Col>
      </Row>
    </ModalBody>
    <ModalFooter className="modal__footer">
      <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${hsObjectId}`} target="_blank" rel="noopener noreferrer">
        <Button
          className="btn-sm"
          color="primary"
          onClick={() => { console.log('View'); }}
        >
          View Contact
        </Button>
      </a>{' '}
      <Button
        className="btn-sm"
        color="primary"
        onClick={() => { setModalVisible(false); }}
        style={{ width: '136px' }}
      >
        Close
      </Button>
    </ModalFooter>
  </Modal>
);

CreatorDealPortalHubilDuplicateModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  hsObjectId: PropTypes.string,
};

CreatorDealPortalHubilDuplicateModal.defaultProps = {
  hsObjectId: '',
};

export default CreatorDealPortalHubilDuplicateModal;
