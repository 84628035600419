import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { unflatten } from 'flat';

const SignedDateField = ({ record, updateRecordFunction, afterSaveFunc }) => {
  const { status, dateSigned } = record.params;
  const [selectedDate, setDate] = useState(dateSigned ? moment(moment.utc(dateSigned).format('YYYY-MM-DD')).toDate() : null);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setDate(dateSigned ? new Date(dateSigned) : null);
  }, [dateSigned, status]);

  useEffect(() => {
    setDate(dateSigned ? moment(moment.utc(dateSigned).format('YYYY-MM-DD')).toDate() : null);
  }, [dateSigned, status]);

  const updateField = async (date) => {
    setDisabled(true);
    try {
      const data = await axios.post('/api/portal/prospects/post-cpm-price-approvals-update', {
        ids: [record.id],
        values: {
          dateSigned: moment.utc(moment(date).format('YYYY-MM-DD')).toDate(),
        },
      });
      if (data?.data?.recordPopulated && updateRecordFunction) {
        updateRecordFunction(unflatten(data?.data?.recordPopulated));
      } else {
        updateRecordFunction(record.id);
        afterSaveFunc();
      }
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
    setDisabled(false);
  };

  if (status.status !== 'Signed') return '';

  return (
    <>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => {
          console.log(date);
          setDate(date);
          updateField(date);
        }}
        dateFormat="MMM d, yyyy"
        placeholderText="Select Date Signed"
        dropDownMode="select"
        isClearable
        wrapperClassName="date-picker--interval w-100"
        className="form-control form-control-sm w-100"
        disabled={disabled}
      />
    </>
  );
};
SignedDateField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func,
  afterSaveFunc: PropTypes.func,
};
SignedDateField.defaultProps = {
  updateRecordFunction: () => {},
  afterSaveFunc: () => {},
};
export default SignedDateField;

