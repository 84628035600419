import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableCellContextMenu from '@/shared/components/table/TableCellContextMenu';
import MatTableList from '@/shared/tables/materialTable/TableRedux';
import { goToPage } from '@/shared/helpers/WVUtilities';
import NameInList from './NameInList';
import ApprovalStatus from "./ApprovalStatus";
import PaymentMethod from "./PaymentMethod";
import TaxFormType from "./TaxFormType";
import YearSigned from "./YearSigned";
import moment from "moment/moment";
import axios from "axios";
import Hook from "@/shared/hooks";
import AccessComponent from "@/shared/components/auth/AccessComponent";
import { TaxFormUploadModalId } from '@/shared/components/modals/TaxFormUploadModal';

const Table = React.memo(({ setTaxUser, setTaxFormId, setTaxPerson }) => {
  const [showDeleteModal] = Hook.useShowModalHook('DeleteModalDefault');
  const [showModalTaxFormUpload] = Hook.useShowModalHook(TaxFormUploadModalId);
  const reload = Hook.useReloadTableHook();
  const allowEdit = useSelector((state) => state.taxFormEditRoleCheck.isAllowedToEdit);

  const setTaxUserAndShowModal = (recordToUse, isAgent, taxId) => {
    setTaxUser(recordToUse);
    setTaxFormId(taxId);
    if (isAgent){
      setTaxPerson('agent');
    } else {
      setTaxPerson('creator');
    }
    showModalTaxFormUpload({ source: 'portal' });
  };

  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Tax Forms Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'name',
          Header: () => 'Name',
          accessor: (record) => {
            const isAgent = record.params?.userType === 'Agent';
            const recordToUse = isAgent
              ? record?.params?.agentUser
              : record?.params?.influencerObject;
            return (recordToUse.first_name || '');
          },
          Cell: p => (
            <NameInList record={p.row.original} />
          ),
          width: 250,
        },
        {
          id: 'approvalStatus',
          Header: () => 'Approval Status',
          accessor: record => (record?.params?.approvalStatus?.value || ''),
          Cell: p => <ApprovalStatus p={p} notes={p.row.original.params?.statusNote?.value} />,
          width: 200,
          className: 'd-flex align-items-center',
        },
        {
          id: 'paymentMethod',
          Header: () => 'Payment Method',
          accessor: () => null,
          Cell: p => (
            <PaymentMethod record={p.row.original} />
          ),
          className: 'align-self-center',
          disableSortBy: true,
        },
        {
          id: 'taxForm',
          Header: () => 'Tax Form',
          accessor: record => (record?.params?.taxFormType || ''),
          Cell: p => (
            <TaxFormType p={p} />
          ),
          className: 'd-flex align-items-center',
          width: 160,
          disableSortBy: true,
        },
        {
          id: 'yearSigned',
          Header: () => 'Year Signed',
          accessor: record => (record?.params?.yearSigned || ''),
          Cell: p => (
            <YearSigned p={p} />
          ),
          className: 'd-flex align-items-center',
          width: 160,
          disableSortBy: true,
        },
        {
          id: 'uploadDate',
          Header: () => 'Upload Date',
          accessor: record => (record?.params?.uploadDate || ''),
          Cell: p => useMemo(() => (p.value
            ? <span style={{ textAlign: 'center', display: 'block', marginRight: '1em' }}>{moment(p.value.split('T')[0]).format('MM/DD/YY')}</span>
            : '-'),
            [p.row.original]),
          className: 'align-self-center',
        },
        {
          id: 'viewDoc',
          Header: () => '',
          accessor: record => (record?.params?.fileUrl || ''),
          Cell: p => (
            <div style={{ width: '50px' }}>
              <AccessComponent showWhenTeam={['management', 'super_powers', 'finance', 'engineering']} showWhenRole={['superAdmin', 'owner']} and>
                <a href={`/api/portal/global/get-view?url=${p.row.original.params?.filePath}`} title="View Tax Document"
                   target="_blank" rel="noreferrer"
                   style={{fontSize: '30px'}} className="pr-2 text-dark"><i className="fa fa-file-pdf"/></a>
              </AccessComponent>
            </div>
          ),
          className: 'd-flex align-items-center',
          disableSortBy: true,
          width: 50,
        },
        {
          Header: '',
          accessor: () => null,
          id: 'actions',
          width: 50,
          Cell: (p) => {
            const contextMenuOptions = [];
            const isAgent = p.row.original.params?.userType === 'Agent';
            const recordToUse = isAgent
                    ? p.row.original?.params?.agentUser
                    : p.row.original?.params?.influencerObject;
            contextMenuOptions.push({
              label: 'View Tax Document',
              handler: () => goToPage(`/api/portal/global/get-view?url=${p.row.original.params?.filePath}`),
              // disabled: !taxForm.params.dealObject?.authorizedPayment?.summaryValidation,
            });
            contextMenuOptions.push({type: 'divider', label: 'divider1' });
            contextMenuOptions.push({
              label: 'Upload Tax Form',
              handler: () => setTaxUserAndShowModal(recordToUse, isAgent, p.row.original.params._id),
            });
            if (allowEdit) {
              contextMenuOptions.push({type: 'divider', label: 'divider2' });
              contextMenuOptions.push({
                label: 'Delete',
                handler: () => {
                  showDeleteModal({
                    title: 'Remove Tax Form',
                    message: (<>Are you sure you want to remove Tax Form for '<strong>{recordToUse?.first_name} {recordToUse?.last_name}</strong>'?</>),
                    deleteRecord: async () => {
                      await axios({
                        method: 'post',
                        url: `/api/portal/finance/post-remove-tax-form`,
                        data: {
                          id: p.row.original.params._id,
                        },
                      });
                    },
                    successFunction: () => {
                      reload();
                    },
                    okMessage: 'Remove',
                  });
                },
              });
            }
            
            return (
              <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                <TableCellContextMenu options={contextMenuOptions} />
              </div>
            );
          },
          disableSortBy: true,
          className: 'd-flex align-items-center align-middle',
        },
      ]}
    />
  );
});

Table.propTypes = {
  setTaxUser: PropTypes.func.isRequired,
  setTaxFormId: PropTypes.func.isRequired,
  setTaxPerson: PropTypes.func.isRequired,
};

export default Table;
