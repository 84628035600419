import React from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { FormGroup, Input, FormFeedback, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import withNotice from '../../../../App/store/with-notice';
import WVValidator from '../../../../../shared/helpers/WVValidator';
import ButtonWithSpinner from '../../../../../shared/components/ButtonWithSpinner';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';


class BillComSearchModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: props.influencer?.params?.email,
      vendors: props.vendors,
      errors: {},
      loading: {
        searchBillCom: false,
        updateCreator: false,
      },
      isModal: true,
      lastUpdated: new Date(),
      selectedIds: [],
    };
  }

  onInputChange = (event) => {
    const fieldName = event.target.name;
    const errors = { ...this.state.errors };
    errors[fieldName] = undefined;
    this.setState({
      [fieldName]: event.target.value,
      errors,
    });
  }

  onSearchBillComButton = () => {
    if (!this.validateSearchErrors()) { return; }

    this.setState({ loading: { searchBillCom: true } });

    axios({
      method: 'get',
      url: `/api/utilities/billCom/search-vendors?term=${this.state.searchTerm}`,
    }).then((response) => {
      console.log(response);
      if (response.data.success === true && response.data.accounts) {
        this.setState({
          vendors: response.data.accounts,
          loading: { searchBillCom: false },
          lastUpdated: new Date(),
        });
      } else {
        this.processError('searchBillCom', response.data.error);
      }
    }).catch((error) => {
      this.processError('searchBillCom', error.message);
    });
  }

  onUpdateCreatorButton = () => {
    if (!this.validateUpdateCreatorErrors()) { return; }

    const { influencer } = this.props;
    const { selectedIds } = this.state;

    this.setState({ loading: { updateCreator: true } });

    axios({
      method: 'post',
      url: '/api/portal/influencer/post-update-billcom-data',
      data: {
        id: influencer.id,
        billComAccoundId: selectedIds[0],
      },
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          loading: { updateCreator: false },
          isModal: false,
        });
        this.props.addNotice({
          message: 'Successfully updated creator\'s profile with selected bill.com data!',
          type: 'success',
        });
      } else {
        this.processError('updateCreator', response.data.error);
      }
    }).catch((error) => {
      this.processError('updateCreator', error.message);
    });
  }

  onTableRowSelected = (rows) => {
    const selectedIds = [];
    [...rows.entries()].forEach(([id, isSelected]) => {
      if (isSelected) {
        selectedIds.push(id);
      }
    });
    this.setState({
      selectedIds,
      errors: {},
    });
  }

  validateSearchErrors() {
    const { searchTerm } = this.state;

    const errors = {};
    if (WVValidator.isEmpty(searchTerm)) {
      errors.searchTerm = 'Please provide your search term!';
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  validateUpdateCreatorErrors() {
    const { selectedIds } = this.state;

    const errors = {};
    if (!(selectedIds.length === 1)) {
      errors.updateCreator = 'Please select only one account!';
    }
    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  processError(key, error) {
    const message = error || 'Something went wrong!';

    this.setState({ loading: { [key]: false } });
    this.props.addNotice({
      message,
      type: 'error',
    });
  }

  toggleModal = () => {
    // const { mfaChallenge } = this.state.modals;
    // if (mfaChallenge === true) {
    //   // hide modal, send a message
    //   this.props.onAuthenticationFinished();
    // }
    this.setState({ isModal: false });
  }

  render() {
    const { influencer } = this.props;
    const {
      searchTerm,
      vendors,
      isModal,
      errors,
      loading,
      lastUpdated,
      selectedIds,
    } = this.state;

    const billComAccoundId = influencer.params?.payment?.billCom?.accountId;
    const columnTransform = {
      name: {
        title: 'Name',
        valueFunc: record => (<span className={record.id === billComAccoundId ? 'text-success' : ''}>{record.name || ''}</span>),
        sort: record => (record.name || ''),
        searchFunc: (record, search) => (record.name ? record.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),

      },
      email: {
        title: 'Email',
        valueFunc: record => (<span className={record.id === billComAccoundId ? 'text-success' : ''}>{record.email || ''}</span>),
        sort: record => (record.email || ''),
        searchFunc: (record, search) => (record.email ? record.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),
      },
      id: {
        title: 'Account ID',
        valueFunc: record => (<span className={record.id === billComAccoundId ? 'text-success' : ''}>{record.id || ''}</span>),
        sort: record => (record.id || ''),
      },
      addressCountry: {
        title: 'Address Country',
        valueFunc: record => (<span className={record.id === billComAccoundId ? 'text-success' : ''}>{record.addressCountry || ''}</span>),
        sort: record => (record.addressCountry || ''),
      },
    };

    return (
      <Modal isOpen={isModal} toggle={this.toggleModal} size="lg" style={{ minWidth: '80%' }} className="delete-modal">
        <ModalHeader>Bill.com Vendors Search</ModalHeader>
        <ModalBody className='ltr-support theme-light'>
          <Row>
            <Col xs="3">
              <FormGroup>
                <Input
                  type="text"
                  name="searchTerm"
                  placeholder="Search bill.com"
                  value={searchTerm}
                  onChange={this.onInputChange}
                  invalid={!!errors.searchTerm}
                />
                <FormFeedback>{errors.searchTerm}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <ButtonWithSpinner
                type="button"
                color="primary"
                size="lg"
                onClick={this.onSearchBillComButton}
                loading={loading.searchBillCom}
              >
                <span>Search Bill.com</span>
              </ButtonWithSpinner>
            </Col>
          </Row>
          <MatTableForRedux
            dataArray={vendors}
            selectable
            selectedEvent={this.onTableRowSelected}
            columns={columnTransform}
            version={lastUpdated.toString()}
            rowsPerPage={10}
            hideFilterDropdown
            hideToolbar
            defaultOrder={{
              order: 'desc',
              orderBy: 'email',
            }}
          />
        </ModalBody>
        <ModalFooter>
          {
            errors.updateCreator &&
            <h5 className="text-danger">{errors.updateCreator}</h5>
          }
          <ButtonWithSpinner
            type="button"
            color="primary"
            onClick={this.onUpdateCreatorButton}
            loading={loading.updateCreator}
            disabled={selectedIds.length < 1}
          >
            <span>Update Creator</span>
          </ButtonWithSpinner>{' '}
          <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

BillComSearchModal.propTypes = {
  influencer: PropTypes.objectOf(PropTypes.any).isRequired,
  vendors: PropTypes.arrayOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
  // history: PropTypes.objectOf(PropTypes.any).isRequired,
  // onAuthenticationFinished: PropTypes.func.isRequired,
};

export default withNotice(BillComSearchModal);
