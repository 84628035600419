import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';

class ModalComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    modal: PropTypes.bool,
    rtl: RTLProps.isRequired,
    clickYes: PropTypes.func.isRequired,
    clickNo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
    modal: false,
  };
  constructor(props) {
    super(props);
    const { modal } = props;
    // console.log('RENDER MODAL INIT', modal);
    this.state = {
      modal,
    };

    this.toggle = this.toggle.bind(this);
  }
  componentDidUpdate(prevProps) {
    this.shouldUpdateStateModal(prevProps);
  }
  shouldUpdateStateModal(prevProps) {
    if (prevProps.modal !== this.props.modal && this.props.modal !== this.state.modal) {
      this.setState({ modal: this.props.modal });
    }
  }

  toggle() {
    const { clickNo } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    // console.log(clickNo);
    if (this.state.modal && clickNo) {
      clickNo();
    }
  }
  render() {
    console.log('RENDER MODAL', this.props);
    const {
      color, title, message, colored, header,
      rtl, clickYes,
    } = this.props;
    const { modal } = this.state;
    let Icon;

    switch (color) {
      case 'primary':
        Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'success':
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        Icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });
    // console.log('RENDER MODAL', modal);
    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName={`${rtl.direction}-support`}
          className={`modal-dialog--${color} ${modalClass}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn btn-sm" type="button" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            {message}
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_ok" outline={colored} color={color} onClick={clickYes}>Yes</Button>
            <Button className="modal_cancel" color="danger" onClick={this.toggle}>No</Button>{' '}
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(ModalComponent);
