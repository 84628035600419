import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import Breadcrumbs from '../../../../../../shared/components/BreadCrumbs';
import FormResource from '../../../../../../shared/components/form/Resource';
import fetchResourceData from '../../../../../../shared/helpers/WVRequest';
import TemplateForm from './custom/Form'

const Form = (props) => {
  const {
    resources,
    match,
  } = props;

  const { id } = match.params;
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [loadingAgencies, setLoadingAgencies] = useState(false);
  const [dropboxTemplates, setDropboxTemplates] = useState({});
  const [loadingDropboxTemplates, setLoadingDropboxTemplates] = useState(false);

  const fetchTemplates = (query = '') => {
    setLoadingDropboxTemplates(true);
    axios.get(`/api/portal/agreement/list-templates${query}`).then((response) => {
      const { success, templates } = response.data;
      if (success) {
        setDropboxTemplates(templates);
      }
      setLoadingDropboxTemplates(false);
    });
  };

  useEffect(() => {
    setLoadingAgencies(true);
    fetchResourceData('Agency', {}, 'perPage=5000&sortBy=agencyName&direction=asc').then((res) => {
      setAgencyOptions(res.records.map(r => ({ value: r.id.toString(), label: r.params.agencyName })));
      setLoadingAgencies(false);
    });
    fetchTemplates();
  }, []);

  const fields = {
    templateName: { required: true },
    dealTypes: { required: true, type: 'array' },
    dropboxTemplateId: { required: true },
    customFields: {
      customDataField: { required: true },
      ilUILabel: { required: true },
      dataType: { required: true },
      readOnly: { required: true },
      required: { required: true },
    },
  };

  return (
    <Container className="dashboard">
      <Breadcrumbs
        links={[
          { title: 'Home', path: '/' },
          { title: 'Custom Service Agreements', path: '/resources/CustomAgreementTemplate/actions/list' },
          { title: 'Custom Service Agreement Template', path: false },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row>
              <Col>
                <h3>Custom Service Agreement Template</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-6" />
          </CardTitle>
          <Row>
            <Col>
              <FormResource
                resource={resources.find(r => r.id === 'CustomAgreementTemplate')}
                fields={fields}
                view={(data, update) => (
                  <TemplateForm
                    data={data}
                    update={update}
                    agencyOptions={agencyOptions}
                    loadingAgencies={loadingAgencies}
                    dropboxTemplates={dropboxTemplates}
                    loadingDropboxTemplates={loadingDropboxTemplates}
                    refreshTemplates={() => { fetchTemplates('?refresh=1') }}
                  />
                )}
                id={id === 'new' ? null: id}
                close={() => { window.history.back(); }}
                addNotice={() => {}}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

Form.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(Form);
