import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactTableBase from '../../../../../../shared/tables/table/ReactTableBase';
import TableCellContextMenu from '../../../../../../shared/components/table/TableCellContextMenu';
import CreateWatchlistModal from './CreateWatchlistModal';
import DeleteModalDialog from './DeleteModalDialog';
import {
  getMyWatchlistsForGrid,
} from '../../../../../../redux/reducers/admin/ResearchReducer';

const WatchlistsListContainer = ({
  dispatch,
  watchlists,
  loading,
}) => {
  const [editId, setEditId] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [newListModalVisible, setNewListModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const editWatchlist = (id) => {
    setEditId(id);
    setNewListModalVisible(true);
  };

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    useFlex: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search list names',
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value }) => value,
        width: 200,
      },
      {
        Header: 'Visibility',
        accessor: 'visibility',
        Cell: ({ value }) => value.charAt(0).toUpperCase() + value.slice(1),
        disableGlobalFilter: true,
        width: 200,
      },
      {
        Header: '',
        accessor: '_id',
        Cell: p => (
          <div style={{ textAlign: 'right' }}><TableCellContextMenu
            options={[
              { label: 'Edit', handler: () => { editWatchlist(p.value); } },
              { label: 'Delete', handler: () => { setDeleteId(p.value); setDeleteModalVisible(true); } },
            ]}
          />
          </div>),
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 50,
        maxWidth: 50,
      },
    ],
    [],
  );

  const newWatchlistSaved = () => {
    dispatch(getMyWatchlistsForGrid());
  };

  const watchlistDeleted = () => {
    dispatch(getMyWatchlistsForGrid());
  };

  useEffect(() => {
    dispatch(getMyWatchlistsForGrid());
  }, []);

  return (
    <>
      <Row>
        <Col md="3">
          <h2 style={{ marginBottom: '15px' }}>Lists</h2>
        </Col>
        <Col md="6" />
        <Col md="3" style={{ textAlign: 'right' }}>
          <button onClick={() => { setEditId(false); setNewListModalVisible(true); }} className="btn btn-primary btn-sm mt-2">Add List</button>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col sm="12" className="research-dashboard-container">
              {!loading && (
                <ReactTableBase
                  key="searchable"
                  columns={columns}
                  data={watchlists}
                  tableConfig={tableConfig}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <CreateWatchlistModal
        isOpen={newListModalVisible}
        listId={editId}
        toggleModal={() => setNewListModalVisible(false)}
        onSaveRecordFunc={newWatchlistSaved}
      />
      <DeleteModalDialog
        isOpen={deleteModalVisible}
        id={deleteId}
        toggleModal={() => setDeleteModalVisible(false)}
        onDeleteRecordFunc={watchlistDeleted}
      />
    </>
  );
};
WatchlistsListContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  watchlists: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  dispatch: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  loading: state.ResearchReducer.loading.watchlistsGrid,
  watchlists: state.ResearchReducer.watchlistsForGrid,
});

export default connect(mapStateToProps)(withRouter(WatchlistsListContainer));
