import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
// import ReactTags from 'react-tag-autocomplete';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import { clearFilterProperty } from '../../../../../../../redux/reducers/TableReducerDefault';
import Hook from '../../../../../../../shared/hooks';
import Tags from '../../../../../../../shared/hooks/components/Tags';

/* const CspOwnerTags = ({
  tags, setTags, tagsSuggestion, title,
}) => {
  const onDeleteCspOwner = (i) => {
    const cspOwnersNew = tags.slice(0);
    cspOwnersNew.splice(i, 1);
    // setFiltersCspOwners(cspOwnersNew);
    setTags(cspOwnersNew);
  };

  const onAdditionCspOwner = (cspOwner) => {
    const cspOwnersNew = [].concat(tags, cspOwner);
    // setFiltersCspOwners(cspOwnersNew);
    setTags(cspOwnersNew);
  };

  console.log(tagsSuggestion, 'TAGS');
  return (<ReactTags
    className="mr-2"
    // ref={ref}
    tags={tags}
    suggestions={tagsSuggestion}
    onDelete={onDeleteCspOwner}
    onAddition={onAdditionCspOwner}
    delimiters={['Enter', ',']}
    placeholderText={title}
    suggestionsTransform={(query, suggestions) => suggestions?.filter(a => a.name.toLowerCase().includes(query.toLowerCase())) || suggestions}
  />);
};
CspOwnerTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTags: PropTypes.func.isRequired,
  tagsSuggestion: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  title: PropTypes.string.isRequired,
}; */

const Actions = React.memo(({
  downloadCsv,
}) => {
  console.log('RELOAD ACRIONS');
  const [showModal] = Hook.useShowModalHook('UpdateStatusOfSelectedProspectsModal');
  const [showFilters, setShowFilters] = useState(getCookie('cpmInsertionOrdersShowFilters') === 'true');
  const dispatch = useDispatch();
  const [selectedIds] = Hook.useSelectedIdsTableHook(); // useSelector(state => state.table.selectedRecordsIdsMultiselect);
  const reload = Hook.useReloadTableHook();
  const [search, setSearch] = Hook.useTableFilterHookString('fullSearchName');
  const [cspOwners, setCspOwners] = Hook.useTableFilterHook('cspOwners');
  const [dealJustification, setDealJustification, dealJustificationOptions] = Hook.useTableFilterHookTags('dealJustification');
  const [contentReleaseDateMonth, setContentReleaseDateMonth, contentReleaseDateMontOptions] = Hook.useTableFilterHookTags('contentReleaseDateMonth');
  const [dealstages, setDealstages, dealstagesOptions] = Hook.useTableFilterHookTags('dealStages');
  const [cpmPriceApprovals, setCpmPriceApprovals, cpmPriceApprovalsOptions] = Hook.useTableFilterHookTags('cpmPriceApprovals');

  const [paGroupId, setPaGroupId] = Hook.useTableFilterHookCheckbox('paGroupId');
  const [approvalStatus, setApprovalStatus] = Hook.useTableFilterHookCheckbox('approvalStatus', {
    approved: false,
    inReview: true,
    pendingEdits: true,
    actionRequired: true,
    hold: false,
    rejected: false,
  });

  const [approvalsBrandStatus, setApprovalBrandStatus] = Hook.useTableFilterHookCheckbox('approvalsBrandStatus');
  const adminUsers = useSelector(state => state.ResearchReducer.adminUsers);
  // console.log(selectedIds, 'selectedIds');
  useEffect(() => {
    setCookie('cpmPricingApprovalsFilters', showFilters.toString(), 35);
  }, [showFilters]);
  const resetFilters = () => {
    dispatch(clearFilterProperty());
  };
  return (
    <div>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Creator Name, CPM Deal Name, Email, PromoCode"
              value={search || ''}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={downloadCsv}
              >
                Download CSV
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => showModal({ selectedIds, refresh: reload })}
                disabled={(selectedIds?.length || 0) === 0}
              >
                Update PA Status
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row className="mt-3">
            <Col sm={3}>
              <Label className="bold-text">
                Deal Owner(s)
              </Label>
              <Tags
                tags={cspOwners?.tags || []}
                setTags={tags => setCspOwners({ tags })}
                tagsSuggestion={adminUsers.map(a => ({ id: a.value, name: a.label }))}
                title="Deal Owner(s)"
              />
            </Col>
            <Col sm={3}>
              <Label className="bold-text">
                Justification
              </Label>
              <Tags
                tags={dealJustification}
                setTags={tags => setDealJustification(tags)}
                tagsSuggestion={dealJustificationOptions.map(a => ({ id: a.value, name: a.label }))}
                title="Justification"
              />
            </Col>
            <Col sm={3}>
              <Label className="bold-text">
                PA Group Name(s)
              </Label>
              <Tags
                tags={cpmPriceApprovals}
                setTags={tags => setCpmPriceApprovals(tags)}
                tagsSuggestion={cpmPriceApprovalsOptions}
                title="PA Group Name(s)"
              />
            </Col>
            <Col sm={3}>
              <Label className="bold-text">
                Month(s)
              </Label>
              <Select
                value={contentReleaseDateMonth}
                options={contentReleaseDateMontOptions}
                onChange={e => setContentReleaseDateMonth(e || [])}
                className="dateOptions"
                placeholder="Month(s)"
                menuPlacement="auto"
                menuPortalTarget={document.body}
                isMulti
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    minHeight: '45px',
                  }),
                }}
              />
            </Col>
            <Col sm={3} className="mt-3">
              <Label className="bold-text">
                Deal Stage(s)
              </Label>
              <Select
                value={dealstages}
                options={dealstagesOptions}
                onChange={e => setDealstages(e || [])}
                className="dateOptions"
                placeholder="Deal Stage"
                menuPlacement="auto"
                menuPortalTarget={document.body}
                isMulti
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    minHeight: '45px',
                  }),
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                IL Pricing Approval Status
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Approved"
                  label="Approved"
                  value={approvalStatus?.approved || false}
                  onChange={event => setApprovalStatus(event, 'approved')}
                />
              </FormGroup>
              <FormGroup style={{ marginBottom: '10px' }}>
                <CheckBoxField
                  name="inreview"
                  label="In Review"
                  value={approvalStatus?.inReview || false}
                  onChange={event => setApprovalStatus(event, 'inReview')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="pendingEdits"
                  label="Pending Edits"
                  value={approvalStatus?.pendingEdits || false}
                  onChange={event => setApprovalStatus(event, 'pendingEdits')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="actionRequired"
                  label="Action Required"
                  value={approvalStatus?.actionRequired || false}
                  onChange={event => setApprovalStatus(event, 'actionRequired')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="hold"
                  label="Hold"
                  value={approvalStatus?.hold || false}
                  onChange={event => setApprovalStatus(event, 'hold')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="rejected"
                  label="Rejected"
                  value={approvalStatus?.rejected || false}
                  onChange={event => setApprovalStatus(event, 'rejected')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Brand Pricing Approcal Status
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Approved1"
                  label="Approved"
                  value={approvalsBrandStatus?.approved || false}
                  onChange={event => setApprovalBrandStatus(event, 'approved')}
                />
              </FormGroup>
              <FormGroup style={{ marginBottom: '10px' }}>
                <CheckBoxField
                  name="inreview1"
                  label="In Review"
                  value={approvalsBrandStatus?.inReview || false}
                  onChange={event => setApprovalBrandStatus(event, 'inReview')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="pendingEdits1"
                  label="Pending Edits"
                  value={approvalsBrandStatus?.pendingEdits || false}
                  onChange={event => setApprovalBrandStatus(event, 'pendingEdits')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="hold1"
                  label="Hold"
                  value={approvalsBrandStatus?.hold || false}
                  onChange={event => setApprovalBrandStatus(event, 'hold')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="rejected1"
                  label="Rejected"
                  value={approvalsBrandStatus?.rejected || false}
                  onChange={event => setApprovalBrandStatus(event, 'rejected')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                PA Group Assigned
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="assigned"
                  label="Assigned"
                  value={paGroupId?.assigned}
                  onChange={event => setPaGroupId(event, 'assigned')}
                />
              </FormGroup>
              <FormGroup style={{ marginBottom: '10px' }}>
                <CheckBoxField
                  name="notAssigned"
                  label="Not Assigned"
                  value={paGroupId?.notAssigned}
                  onChange={event => setPaGroupId(event, 'notAssigned')}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
});
Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
};

export default Actions;
