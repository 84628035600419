import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LockOutlineIcon from 'mdi-react/LockOutlineIcon';
import PoundIcon from 'mdi-react/PoundIcon';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { CKEditor } from 'ckeditor4-react';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import { axios } from 'ApiClient';
import Select from 'react-select';
import withNotice from '../../../App/store/with-notice';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import CheckBoxField from '../../../../shared/components/CheckBox';
import EmailTemplatePreviewModal from '../../../../shared/components/modals/EmailTemplatePreviewModal';
import ButtonWithSpinner from '../../../../shared/components/ButtonWithSpinner';
import WVValidator from '../../../../shared/helpers/WVValidator';

const ucfirst = string => string.charAt(0).toUpperCase() + string.slice(1);

// eslint-disable-next-line arrow-body-style
const SlackChannelOptions = (channels) => {
  return channels.map(channel => ({
    value: channel.name,
    label: (
      <>
        {channel.is_private ? <LockOutlineIcon size="14px" /> : <PoundIcon size="14px" />}
        <span style={{ paddingRight: '5px' }}> {channel.name}</span>
      </>
    ),
  }));
};

// eslint-disable-next-line arrow-body-style
const FunctionlOptions = (funs) => {
  return funs.map(funName => ({
    value: funName,
    label: funName,
  }));
};

class JobNotificationSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchScheduledJob = this.fetchScheduledJob.bind(this);
    this.fetchJobNotification = this.fetchJobNotification.bind(this);
    this.fetchDataSources = this.fetchDataSources.bind(this);
    this.fetchSlackChannels = this.fetchSlackChannels.bind(this);
    this.fetchSlackFunctions = this.fetchSlackFunctions.bind(this);
    this.showEmailPreviewModal = this.showEmailPreviewModal.bind(this);
    this.showBasicPreviewModal = this.showBasicPreviewModal.bind(this);
    this.closePreviewModal = this.closePreviewModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleBccEmailAddressesInputChange = this.handleBccEmailAddressesInputChange.bind(this);
    this.handleCKEditorDataChange = this.handleCKEditorDataChange.bind(this);
    this.handlePreviewTokensChange = this.handlePreviewTokensChange.bind(this);
    this.handleDataSelectChange = this.handleDataSelectChange.bind(this);
    this.copyToken = this.copyToken.bind(this);
    this.saveJobNotification = this.saveJobNotification.bind(this);
    this.getSelectionFor = this.getSelectionFor.bind(this);
    this.getSelectionsFor = this.getSelectionsFor.bind(this);
    this.getTokensListFrom = this.getTokensListFrom.bind(this);
    this.getDataSourcesNoteFor = this.getDataSourcesNoteFor.bind(this);

    this.state = {
      scheduledJob: null,
      jobNotification: {
        scheduledJob: null,
        email: {
          isEnabled: false,
          useWrapping: false,
          fromEmailName: '',
          fromEmailEmail: '',
          bccEmailAddresses: [],
          subject: '',
          body: '',
          dataSourcesTypes: [],
        },
        notification: {
          isEnabled: false,
          category: 'marketing',
          title: '',
          body: '',
          sendOffer: false,
          dataSourcesTypes: [],
        },
        hubspotTask: {
          isEnabled: false,
          title: '',
          body: '',
          dataSourcesTypes: [],
        },
        slackNotification: {
          isEnabled: false,
          channelName: {
            value: '',
            label: '',
          },
          functionName: {
            value: '',
            label: '',
          },
        },
      },
      loading: {
        scheduledJob: false,
        jobNotification: false,
        emailModalData: false,
        notificationModalData: false,
        hubspotTaskModalData: false,
        slackChannels: false,
        slackFunctions: false,
        dataSources: false,
        saveJobNotification: false,
      },
      dropdownOpen: {
        email: false,
        notification: false,
        hubspotTask: false,
      },
      previewModal: false,
      previewTitle: '',
      previewBody: '',
      dataSources: [],
      slackChannels: [],
      slackFunctions: [],
      previewTypeCreators: {
        email: false,
        notification: false,
        hubspotTask: false,
      },
      previewTypeAdmins: {
        email: false,
        notification: false,
        hubspotTask: false,
      },
      previewTypeAgents: {
        email: false,
        notification: false,
        hubspotTask: false,
      },
      previewTypeBrands: {
        email: false,
        notification: false,
        hubspotTask: false,
      },
      errors: {
        fromEmailEmail: null,
        bccEmailAddresses: null,
      },
      selectedCreatorOption: {
        email: null,
        notification: null,
        hubspotTask: null,
      },
      selectedAdminOption: {
        email: null,
        notification: null,
        hubspotTask: null,
      },
      selectedAgentOption: {
        email: null,
        notification: null,
        hubspotTask: null,
      },
      selectedBrandOption: {
        email: null,
        notification: null,
        hubspotTask: null,
      },
    };
  }

  componentDidMount() {
    this.fetchScheduledJob();
    this.fetchJobNotification();
    this.fetchDataSources();
    this.fetchSlackChannels();
    this.fetchSlackFunctions();
  }

  getSelectionFor(optionType, notificationType) {
    if (optionType && notificationType) {
      const {
        dataSources,
        jobNotification,
      } = this.state;
      const options = {
        creators: [],
        admins: [],
        agents: [],
        brands: [],
      };

      if (dataSources) {
        if (dataSources.creators) {
          dataSources.creators.forEach((user) => {
            const option = {
              value: user._id,
              label: `${user.first_name} ${user.last_name}`.trim(),
            };
            options.creators.push(option);
          });
        }

        if (dataSources.admins) {
          dataSources.admins.forEach((user) => {
            const option = {
              value: user._id,
              label: `${user.firstName} ${user.lastName}`.trim(),
            };
            options.admins.push(option);
          });
        }

        if (dataSources.agents) {
          dataSources.agents.forEach((user) => {
            const option = {
              value: user._id,
              label: `${user.first_name} ${user.last_name}`.trim(),
            };
            options.agents.push(option);
          });
        }

        if (dataSources.brands) {
          dataSources.brands.forEach((user) => {
            const option = {
              value: user._id,
              label: `${user.firstName} ${user.lastName}`.trim(),
            };
            options.brands.push(option);
          });
        }
      }

      const previewTypeProperty = `previewType${ucfirst(optionType)}s`;
      const selectedOptionProperty = `selected${ucfirst(optionType)}Option`;

      if (!jobNotification[notificationType].dataSourcesTypes || jobNotification[notificationType].dataSourcesTypes.length === 0 || jobNotification[notificationType].dataSourcesTypes.includes(optionType)) {
        return (
          <FormGroup>
            <CheckBoxField
              name={`previewType${ucfirst(optionType)}s${ucfirst(notificationType)}`}
              label={`Use ${ucfirst(optionType)}'s tokens in preview`}
              value={this.state[previewTypeProperty][notificationType]}
              onChange={event => this.handlePreviewTokensChange(event, `previewType${ucfirst(optionType)}s`, notificationType)}
            />
            <Select
              name={`${optionType}Select`}
              placeholder={`Select a ${ucfirst(optionType)}`}
              isDisabled={!this.state[previewTypeProperty][notificationType]}
              value={this.state[selectedOptionProperty][notificationType]}
              options={options[`${optionType}s`]}
              onChange={selected => this.handleDataSelectChange(selected, `selected${ucfirst(optionType)}Option`, notificationType)}
            />
          </FormGroup>
        );
      }
      return null;
    }
    return null;
  }

  getSelectionsFor(notificationType) {
    const { loading } = this.state;
    return (
      <Col
        md={2}
        style={{ paddingTop: '30px' }}
      >
        {this.getSelectionFor('creator', notificationType)}
        {this.getSelectionFor('admin', notificationType)}
        {this.getSelectionFor('agent', notificationType)}
        {this.getSelectionFor('brand', notificationType)}
        <Button
          className="w-100"
          color="primary"
          onClick={() => {
            if (notificationType === 'email') {
              this.showEmailPreviewModal();
            } else this.showBasicPreviewModal(notificationType);
          }}
          disabled={loading.dataSources}
        >
          Show Preview
        </Button>
      </Col>
    );
  }

  getTokensListFrom(tokens) {
    return tokens.map(value => (
      <FormGroup
        key={`div_${value}`}
        className="form__form-group-field"
        style={{ marginTop: '5px' }}
      >
        <input
          style={{ width: '100%', paddingLeft: '5px' }}
          readOnly
          value={value}
        />
        <button
          className="btn btn-primary btn-sm"
          type="button"
          title="Copy"
          style={{
            padding: '5px 5px 5px 10px',
            height: '38px',
            margin: '0px',
            borderRadius: '0px 5px 5px 0px',
          }}
          onClick={e => this.copyToken(e)}
        >
          <ContentCopyIcon size={16} />
        </button>
      </FormGroup>
    ));
  }

  getDataSourcesNoteFor(notificationType) {
    const { jobNotification } = this.state;
    if (jobNotification[notificationType].dataSourcesTypes && jobNotification[notificationType].dataSourcesTypes.length > 0) {
      return (
        <span
          className="font-weight-bold"
          style={{ color: 'red' }}
        >
          Attention! The data sources do not change dynamically. You are limited to the tokens that work with the data sources used in this type of notification.
          <br />
          Current data sources:&nbsp;
          <span
            className="font-weight-bold"
            style={{ textTransform: 'capitalize' }}
          >
            {jobNotification[notificationType].dataSourcesTypes.join(', ')}.
          </span>
          <br />
          Contact the Engineering Department for changes in data sources.
        </span>
      );
    }
    return null;
  }

  saveJobNotification = async function saveJobNotification() {
    this.setState(prevState => ({
      ...prevState,
      loading: {
        ...prevState.loading,
        saveJobNotification: true,
      },
    }));
    const {
      scheduledJob,
      jobNotification,
    } = this.state;
    const { match: { params: { jobId } }, addNotice } = this.props;
    const scheduledJobId = scheduledJob?.id || jobNotification?.scheduledJob || jobId;

    axios.post(
      '/api/portal/job-notifications/save-job-notification',
      {
        scheduledJobId,
        email: jobNotification.email,
        notification: jobNotification.notification,
        hubspotTask: jobNotification.hubspotTask,
        slackNotification: {
          ...jobNotification.slackNotification,
          channelName: jobNotification.slackNotification.channelName?.value,
          functionName: jobNotification.slackNotification.functionName?.value,
        },
      },
    ).then((response) => {
      if (response.data.success && response.data.jobNotification) {
        this.setState(prevState => ({
          ...prevState,
          loading: {
            ...prevState.loading,
            saveJobNotification: false,
          },
          jobNotification: response.data.jobNotification,
        }));
        addNotice({
          message: 'Job Notification saved successfully.',
          type: 'success',
        });
      } else {
        addNotice({
          message: response.data.error || 'Failed to save Job Notification.',
          type: 'error',
        });
        this.setState(prevState => ({
          ...prevState,
          loading: {
            ...prevState.loading,
            saveJobNotification: false,
          },
        }));
      }
    }).catch((error) => {
      console.log(error);
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          saveJobNotification: false,
        },
      }));
      addNotice({
        message: error.message || error,
        type: 'error',
      });
    });
  };

  showEmailPreviewModal = async function showEmailPreviewModal() {
    this.setState(prevState => ({
      ...prevState,
      loading: {
        ...prevState.loading,
        emailModalData: true,
      },
      previewModal: true,
    }));
    const {
      jobNotification: {
        email: {
          useWrapping,
          subject,
          body,
        },
      },
      previewTypeCreators,
      previewTypeAdmins,
      previewTypeAgents,
      previewTypeBrands,
      selectedCreatorOption,
      selectedAdminOption,
      selectedAgentOption,
      selectedBrandOption,
    } = this.state;

    const templateWrapping = useWrapping ? 'wrapping-influencelogic-default' : 'wrapping-disabled';

    let creator = null;
    let admin = null;
    let agent = null;
    let brand = null;

    if (previewTypeCreators.email && selectedCreatorOption.email && selectedCreatorOption.email.value) {
      creator = selectedCreatorOption.email.value;
    }
    if (previewTypeAdmins.email && selectedAdminOption.email && selectedAdminOption.email.value) {
      admin = selectedAdminOption.email.value;
    }
    if (previewTypeAgents.email && selectedAgentOption.email && selectedAgentOption.email.value) {
      agent = selectedAgentOption.email.value;
    }
    if (previewTypeBrands.email && selectedBrandOption.email && selectedBrandOption.email.value) {
      brand = selectedBrandOption.email.value;
    }

    axios.post(
      '/api/portal/emailTemplates/email-template-preview-universal-users',
      {
        templateWrapping,
        influencer: creator,
        admin,
        agent,
        brand,
        subject,
        body,
      },
    ).then((response) => {
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          emailModalData: false,
        },
        previewTitle: response.data.emailSubject,
        previewBody: response.data.emailBody,
      }));
    }).catch((error) => {
      console.log(error);
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          emailModalData: false,
        },
        previewModal: false,
      }));
    });
  };

  showBasicPreviewModal = async function showBasicPreviewModal(type) {
    this.setState(prevState => ({
      ...prevState,
      loading: {
        ...prevState.loading,
        [`${type}ModalData`]: true,
      },
      previewModal: true,
    }));
    const {
      jobNotification: {
        [type]: {
          title,
          body,
        },
      },
      previewTypeCreators,
      previewTypeAdmins,
      previewTypeAgents,
      previewTypeBrands,
      selectedCreatorOption,
      selectedAdminOption,
      selectedAgentOption,
      selectedBrandOption,
    } = this.state;

    let creator = null;
    let admin = null;
    let agent = null;
    let brand = null;

    if (previewTypeCreators[type] && selectedCreatorOption[type] && selectedCreatorOption[type].value) {
      creator = selectedCreatorOption[type].value;
    }
    if (previewTypeAdmins[type] && selectedAdminOption[type] && selectedAdminOption[type].value) {
      admin = selectedAdminOption[type].value;
    }
    if (previewTypeAgents[type] && selectedAgentOption[type] && selectedAgentOption[type].value) {
      agent = selectedAgentOption[type].value;
    }
    if (previewTypeBrands[type] && selectedBrandOption[type] && selectedBrandOption[type].value) {
      brand = selectedBrandOption[type].value;
    }

    axios.post(
      '/api/portal/job-notifications/notification-preview-universal-users',
      {
        title,
        body,
        influencer: creator,
        admin,
        agent,
        brand,
      },
    ).then((response) => {
      const { previewTitle, previewBody } = response.data;
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          [`${type}ModalData`]: false,
        },
        previewTitle,
        previewBody,
      }));
    }).catch((error) => {
      console.log(error);
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          emailModalData: false,
        },
        previewModal: false,
      }));
    });
  };

  closePreviewModal() {
    this.setState({
      previewModal: false,
      previewTitle: '',
      previewBody: '',
    });
  }

  handleCKEditorDataChange(event, type) {
    const { editor } = event;
    this.setState(prevState => ({
      ...prevState,
      jobNotification: {
        ...prevState.jobNotification,
        [type]: {
          ...prevState.jobNotification[type],
          body: editor.getData(),
        },
      },
    }));
  }

  handleInputChange(event, type) {
    const { value, name } = event.target;
    this.setState(prevState => ({
      ...prevState,
      jobNotification: {
        ...prevState.jobNotification,
        [type]: {
          ...prevState.jobNotification[type],
          [name]: name === 'body' ? value.replace(/[\r\n\v]+/g, '') : value,
        },
      },
    }));
    if (name === 'fromEmailEmail') this.validateEmailAddress(value);
  }

  handleSelectChange(event, name, type) {
    this.setState(prevState => ({
      ...prevState,
      jobNotification: {
        ...prevState.jobNotification,
        [type]: {
          ...prevState.jobNotification[type],
          [name]: { value: event.value, label: event.label },
        },
      },
    }));
  }

  handleBccEmailAddressesInputChange(event) {
    const { value } = event.target;
    if (value) {
      let valid = true;
      const array = value.split(',');
      array.forEach((email) => {
        // eslint-disable-next-line no-useless-escape
        if (!(WVValidator.isEmail(email) || email === '{{csp_email}}')) {
          valid = false;
        }
      });
      if (valid) {
        this.setState(prevState => ({
          ...prevState,
          jobNotification: {
            ...prevState.jobNotification,
            email: {
              ...prevState.jobNotification.email,
              bccEmailAddresses: array,
            },
          },
          errors: {
            ...prevState.errors,
            bccEmailAddresses: null,
          },
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          jobNotification: {
            ...prevState.jobNotification,
            email: {
              ...prevState.jobNotification.email,
              bccEmailAddresses: array,
            },
          },
          errors: {
            ...prevState.errors,
            bccEmailAddresses: 'Check the emails for validity!',
          },
        }));
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        jobNotification: {
          ...prevState.jobNotification,
          email: {
            ...prevState.jobNotification.email,
            bccEmailAddresses: [],
          },
        },
        errors: {
          ...prevState.errors,
          bccEmailAddresses: null,
        },
      }));
    }
  }

  handleCheckboxChange(event, type, name) {
    if (event && event.target) {
      const { checked } = event.target;
      this.setState(prevState => ({
        ...prevState,
        jobNotification: {
          ...prevState.jobNotification,
          [type]: {
            ...prevState.jobNotification[type],
            [name]: checked,
          },
        },
      }));
    }
  }

  validateEmailAddress(value) {
    if (value) {
      // eslint-disable-next-line no-useless-escape
      if (WVValidator.isEmail(value) || value === '{{csp_email}}') {
        this.setState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            fromEmailEmail: '',
          },
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            fromEmailEmail: 'Check the email for validity!',
          },
        }));
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          fromEmailEmail: 'From Email Address is required',
        },
      }));
    }
  }

  copyToken = (e) => {
    let textInput = false;
    try {
      textInput = e.nativeEvent.target.closest('.form__form-group-field').querySelector('input');
    } catch (err) {
      textInput = false;
    }

    e.preventDefault();

    if (textInput) {
      textInput.select();
      document.execCommand('copy');

      this.props.addNotice({
        message: 'Copied...',
        type: 'success',
      });
    }
  };

  fetchScheduledJob() {
    const { match: { params: { jobId } }, addNotice } = this.props;
    if (jobId) {
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          scheduledJob: true,
        },
      }));

      if (jobId) {
        const url = `${window.location.href.substring(0, window.location.href.indexOf('job-notifications'))}api/portal/job-notifications/find-job`;
        axios.post(url, { jobId })
          .then((response) => {
            if (response.data.success && response.data.scheduledJob) {
              this.setState(prevState => ({
                ...prevState,
                scheduledJob: response.data.scheduledJob,
                loading: {
                  ...prevState.loading,
                  scheduledJob: false,
                },
              }));
            } else if (response.data.error) {
              addNotice({
                message: response.data.error,
                type: 'error',
              });
            }
          })
          .catch((error) => {
            console.log(error);
            addNotice({
              message: error.message || error,
              type: 'error',
            });
          });
      }
    }
  }

  fetchJobNotification() {
    const { match: { params: { jobId } }, addNotice } = this.props;
    if (jobId) {
      const url = `${window.location.href.substring(0, window.location.href.indexOf('job-notifications'))}api/portal/job-notifications/find-job-notification`;
      axios.post(url, { jobId })
        .then((response) => {
          if (response.data.success && response.data.jobNotification) {
            this.setState(prevState => ({
              ...prevState,
              jobNotification: response.data.jobNotification,
              loading: {
                ...prevState.loading,
                jobNotification: false,
              },
            }));
          } else if (response.data.error) {
            addNotice({
              message: response.data.error,
              type: 'error',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          addNotice({
            message: error.message || error,
            type: 'error',
          });
        });
    }
  }

  fetchDataSources() {
    this.setState(prevState => ({
      ...prevState,
      loading: {
        ...prevState.loading,
        dataSources: false,
      },
    }));

    axios.get('/api/portal/emailTemplates/preview-data').then((response) => {
      this.setState(prevState => ({
        ...prevState,
        dataSources: {
          creators: response.data.dataSources.influencers || [],
          admins: response.data.dataSources.admins || [],
          agents: response.data.dataSources.agents || [],
          brands: response.data.dataSources.brands || [],
        },
        loading: {
          ...prevState.loading,
          dataSources: false,
        },
      }));
    }).catch((error) => {
      console.log(error);
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          dataSources: false,
        },
      }));
    });
  }

  fetchSlackChannels() {
    const { addNotice } = this.props;
    const url = `${window.location.href.substring(0, window.location.href.indexOf('job-notifications'))}api/portal/slack/get-channels-for-bot`;
    axios.get(url)
      .then((response) => {
        if (response.data.success && response.data.channels) {
          this.setState(prevState => ({
            ...prevState,
            slackChannels: response.data.channels.sort((a, b) => (a.name > b.name ? 1 : -1)),
            loading: {
              ...prevState.loading,
              slackChannels: false,
            },
          }));
        } else if (response.data.error) {
          addNotice({
            message: response.data.error,
            type: 'error',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        addNotice({
          message: error.message || error,
          type: 'error',
        });
      });
  }

  fetchSlackFunctions() {
    const { addNotice } = this.props;
    const url = `${window.location.href.substring(0, window.location.href.indexOf('job-notifications'))}api/portal/notification/get-slack-functions`;
    axios.get(url)
      .then((response) => {
        if (response.data.success && response.data.methods) {
          this.setState(prevState => ({
            ...prevState,
            slackFunctions: response.data.methods,
            loading: {
              ...prevState.loading,
              slackFunctions: false,
            },
          }));
        } else if (response.data.error) {
          addNotice({
            message: response.data.error,
            type: 'error',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        addNotice({
          message: error.message || error,
          type: 'error',
        });
      });
  }

  handlePreviewTokensChange(event, propertyName, notificationType) {
    if (event && event.target && propertyName && notificationType) {
      const { checked } = event.target;
      this.setState(prevState => ({
        ...prevState,
        [propertyName]: {
          ...prevState[propertyName],
          [notificationType]: checked,
        },
      }));
    }
  }

  handleDataSelectChange(selectedOption, optionType, notificationType) {
    if (selectedOption && optionType && notificationType) {
      this.setState(prevState => ({
        ...prevState,
        [optionType]: {
          ...prevState[optionType],
          [notificationType]: selectedOption,
        },
      }));
    }
  }

  render() {
    const {
      scheduledJob,
      previewTypeAdmins,
      previewTypeCreators,
      previewTypeBrands,
      previewTypeAgents,
      jobNotification,
      loading,
      errors,
      previewTitle,
      previewBody,
      previewModal,
      slackChannels,
      slackFunctions,
    } = this.state;

    const emailAddressHelpBlockClassName = errors.fromEmailEmail ? 'help-block color-danger' : 'help-block';
    const displayEmailAddressHelpBlock = errors.fromEmailEmail ? 'block' : 'none';
    const bccEmailAddressesHelpBlockClassName = errors.bccEmailAddresses ? 'help-block color-danger' : 'help-block';
    const displayBccEmailAddressesHelpBlock = errors.bccEmailAddresses ? 'block' : 'none';


    const tokens = {
      email: [
        '{{date}}',
        '{{il_creator_portal_url}}',
        '{{il_admin_portal_url}}',
        '{{il_agent_portal_url}}',
        '{{il_brand_portal_url}}',
      ],
      notification: [
        '{{date}}',
        '{{il_creator_portal_url}}',
        '{{il_admin_portal_url}}',
        '{{il_agent_portal_url}}',
        '{{il_brand_portal_url}}',
      ],
      hubspotTask: [
        '{{date}}',
        '{{il_creator_portal_url}}',
        '{{il_admin_portal_url}}',
        '{{il_agent_portal_url}}',
        '{{il_brand_portal_url}}',
      ],
    };
    const creatorTokens = [
      '{{il_creator_first_name}}',
      '{{il_creator_last_name}}',
      '{{il_creator_email}}',
      '{{il_creator_reset_pw_url}}',
      '{{il_creator_payout_amount}}',
      '{{il_device_verification_code}}',
      '{{brandNameOpportunity}}',
      '{{il_deal_brand}}',
      '{{il_deal_offer}}',
      '{{csp_name}}',
      '{{csp_firstName}}',
      '{{csp_lastName}}',
      '{{csp_email}}',
    ];
    const adminTokens = [
      '{{il_admin_first_name}}',
      '{{il_admin_last_name}}',
      '{{il_admin_email}}',
      '{{il_admin_reset_pw_url}}',
    ];
    const agentTokens = [
      '{{il_creator_first_name}}',
      '{{il_creator_last_name}}',
      '{{il_creator_email}}',
      '{{il_creator_reset_pw_url}}',
      '{{il_creator_payout_amount}}',
      '{{il_device_verification_code}}',
      '{{brandNameOpportunity}}',
    ];
    const brandTokens = [
      '{{il_brand_first_name}}',
      '{{il_brand_last_name}}',
      '{{il_brand_email}}',
      '{{il_brand_reset_pw_url}}',
    ];

    if (previewTypeCreators.email) tokens.email.push(...creatorTokens);
    if (previewTypeCreators.notification) tokens.notification.push(...creatorTokens);
    if (previewTypeCreators.hubspotTask) tokens.hubspotTask.push(...creatorTokens);

    if (previewTypeAdmins.email) tokens.email.push(...adminTokens);
    if (previewTypeAdmins.notification) tokens.notification.push(...adminTokens);
    if (previewTypeAdmins.hubspotTask) tokens.hubspotTask.push(...adminTokens);

    if (previewTypeAgents.email) tokens.email.push(...agentTokens);
    if (previewTypeAgents.notification) tokens.notification.push(...agentTokens);
    if (previewTypeAgents.hubspotTask) tokens.hubspotTask.push(...agentTokens);

    if (previewTypeBrands.email) tokens.email.push(...brandTokens);
    if (previewTypeBrands.notification) tokens.notification.push(...brandTokens);
    if (previewTypeBrands.hubspotTask) tokens.hubspotTask.push(...brandTokens);

    const title = `Job Notification Settings${scheduledJob && scheduledJob.name ? ` - ${scheduledJob.name}` : ''}`;

    return (
      <Container>
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            { title: 'Scheduled Jobs', path: '/resources/ScheduledJob/actions/list' },
            { title: 'Notifications', path: null },
          ]}
          isBackButton
        />

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="il-color-deep-blue font-weight-bold">{title}</h3>
                  </Col>
                </Row>
                <hr className="mt-3 mb-4" />


                <FormGroup
                  className="font-weight-bold"
                >
                  <CheckBoxField
                    id="emailIsEnabled"
                    name="emailIsEnabled"
                    label="Enable Emails"
                    value={jobNotification.email.isEnabled}
                    onChange={(e) => { this.handleCheckboxChange(e, 'email', 'isEnabled'); }}
                  />
                </FormGroup>

                <div
                  className="mx-3 mb-4"
                  style={{ display: jobNotification.email.isEnabled ? 'block' : 'none' }}
                >

                  <FormGroup>
                    <CheckBoxField
                      name="useWrapping"
                      label="Use InfluenceLogic wrapping"
                      value={jobNotification.email.useWrapping}
                      onChange={(e) => { this.handleCheckboxChange(e, 'email', 'useWrapping'); }}
                    />
                  </FormGroup>

                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label for="emailFromEmailName">From Email Name</Label>
                        <Input
                          id="emailFromEmailName"
                          name="fromEmailName"
                          value={jobNotification.email.fromEmailName}
                          onChange={(e) => { this.handleInputChange(e, 'email'); }}
                          style={{ width: '100%' }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label for="emailAddress">From Email Address</Label>
                        <Input
                          id="emailFromEmailEmail"
                          name="fromEmailEmail"
                          value={jobNotification.email.fromEmailEmail}
                          onChange={(e) => { this.handleInputChange(e, 'email'); }}
                          style={{ width: '100%' }}
                        />
                        <span
                          className={emailAddressHelpBlockClassName}
                          style={{ display: displayEmailAddressHelpBlock }}
                        >
                          {errors.fromEmailEmail}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label for="bccEmailAddresses">BCC Emails (comma separated)</Label>
                    <Input
                      id="bccEmailAddresses"
                      name="bccEmailAddresses"
                      value={jobNotification.email.bccEmailAddresses ? jobNotification.email.bccEmailAddresses.join(',') : ''}
                      onChange={this.handleBccEmailAddressesInputChange}
                      style={{ width: '100%' }}
                    />
                    <span
                      className={bccEmailAddressesHelpBlockClassName}
                      style={{ display: displayBccEmailAddressesHelpBlock }}
                    >
                      {errors.bccEmailAddresses}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label for="emailSubject">Subject</Label>
                    <Input
                      id="emailSubject"
                      name="subject"
                      value={jobNotification.email.subject}
                      onChange={(e) => { this.handleInputChange(e, 'email'); }}
                      style={{ width: '100%' }}
                    />
                  </FormGroup>
                  {jobNotification.email.isEnabled ? (
                    <Row>
                      <Col md={7}>
                        <FormGroup style={{ width: '100%' }}>
                          <Label for="emailBodyEditor">Email Body</Label>
                          <CKEditor
                            config={{versionCheck: false}}
                            id="emailBodyEditor"
                            name="body"
                            activeClass="form-control"
                            initData={jobNotification.email.body}
                            onChange={e => this.handleCKEditorDataChange(e, 'email')}
                          />
                        </FormGroup>
                      </Col>
                      {this.getSelectionsFor('email')}
                      <Col
                        md={3}
                        style={{ paddingTop: '28px' }}
                      >
                        <div
                          style={{ paddingTop: '1px' }}
                        >
                          <span className="font-weight-bold">
                            Email Subject & Body Tokens
                          </span>
                          <ul
                            className="tokens-list"
                            style={{ padding: '2px', maxHeight: '382px', overflowY: 'auto' }}
                          >
                            {this.getTokensListFrom(tokens.email)}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  ) : null }
                  
                  {this.getDataSourcesNoteFor('email')}
                </div>

                <FormGroup
                  className="font-weight-bold"
                >
                  <CheckBoxField
                    id="notificationIsEnabled"
                    name="notificationIsEnabled"
                    label="Enable Push Notifications"
                    value={jobNotification.notification.isEnabled}
                    onChange={(e) => { this.handleCheckboxChange(e, 'notification', 'isEnabled'); }}
                    style={{ fontSize: '150%' }}
                  />
                </FormGroup>

                <div
                  className="mx-3 mb-4"
                  style={{ display: jobNotification.notification.isEnabled ? 'block' : 'none' }}
                >
                  <FormGroup>
                    <CheckBoxField
                      id="notificationSendOffer"
                      name="sendOffer"
                      label="Enable Offer information to be included to allow users to click and view Offer"
                      value={jobNotification.notification.sendOffer}
                      onChange={(e) => { this.handleCheckboxChange(e, 'notification', 'sendOffer'); }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="notificationCategory">Category</Label>
                    <Input
                      type="select"
                      name="category"
                      id="notificationCategory"
                      bsSize="sm"
                      onChange={(e) => { this.handleInputChange(e, 'notification'); }}
                      value={jobNotification.notification.category}
                      style={{ width: '40%' }}
                    >
                      <option value="marketing" key="marketing">Marketing - Engagement Purposes</option>)
                      <option value="service" key="service">Service - Notify about our system changes</option>)
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="notificationTitle">Title</Label>
                    <Input
                      id="notificationTitle"
                      name="title"
                      value={jobNotification.notification.title}
                      onChange={(e) => { this.handleInputChange(e, 'notification'); }}
                      style={{ width: '100%' }}
                    />
                  </FormGroup>

                  <Row>
                    <Col md={7}>
                      <FormGroup>
                        <Label for="notificationBody">Body</Label>
                        <Input
                          id="notificationBody"
                          name="body"
                          type="textarea"
                          value={jobNotification.notification.body}
                          onChange={(e) => { this.handleInputChange(e, 'notification'); }}
                          style={{ width: '100%', minHeight: '150px' }}
                        />
                      </FormGroup>
                    </Col>
                    {this.getSelectionsFor('notification')}
                    <Col
                      md={3}
                      style={{ paddingTop: '28px' }}
                    >
                      <div
                        style={{ paddingTop: '1px' }}
                      >
                        <span className="font-weight-bold">
                          Notification Title & Body Tokens
                        </span>
                        <ul
                          style={{ padding: '0' }}
                        >
                          {this.getTokensListFrom(tokens.notification)}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  {this.getDataSourcesNoteFor('notification')}
                </div>

                <FormGroup
                  className="font-weight-bold"
                >
                  <CheckBoxField
                    id="hubspotTaskIsEnabled"
                    name="hubspotTaskIsEnabled"
                    label="Enable HubSpot Tasks"
                    value={jobNotification.hubspotTask.isEnabled}
                    onChange={(e) => { this.handleCheckboxChange(e, 'hubspotTask', 'isEnabled'); }}
                    style={{ fontSize: '150%' }}
                  />
                </FormGroup>

                <div
                  className="mx-3 mb-4"
                  style={{ display: jobNotification.hubspotTask.isEnabled ? 'block' : 'none' }}
                >
                  <FormGroup>
                    <Label for="hubspotTaskTitle">Title</Label>
                    <Input
                      id="hubspotTaskTitle"
                      name="title"
                      value={jobNotification.hubspotTask.title}
                      onChange={(e) => { this.handleInputChange(e, 'hubspotTask'); }}
                      style={{ width: '100%' }}
                    />
                  </FormGroup>
                  {jobNotification.email.isEnabled ? (
                    <Row>
                      <Col md={7}>
                        <FormGroup style={{ width: '100%' }}>
                          <Label for="hubspotTaskBodyEditor">Body</Label>
                          <CKEditor
                            config={{versionCheck: false}}
                            id="hubspotTaskBodyEditor"
                            name="body"
                            activeClass="form-control"
                            initData={jobNotification.hubspotTask.body}
                            onChange={e => this.handleCKEditorDataChange(e, 'hubspotTask')}
                          />
                        </FormGroup>
                      </Col>
                      {this.getSelectionsFor('hubspotTask')}
                      <Col
                        md={3}
                        style={{ paddingTop: '28px' }}
                      >
                        <div
                          style={{ paddingTop: '1px' }}
                        >
                          <span className="font-weight-bold">
                            Task Title & Body Tokens
                          </span>
                          <ul
                            className="tokens-list"
                            style={{ padding: '2px', maxHeight: '382px', overflowY: 'auto' }}
                          >
                            {this.getTokensListFrom(tokens.hubspotTask)}
                          </ul>
                        </div>
                      </Col>
                    </Row>) : null
                  }
                  {this.getDataSourcesNoteFor('hubspotTask')}
                </div>

                <FormGroup
                  className="font-weight-bold"
                >
                  <CheckBoxField
                    id="slackIsEnabled"
                    name="slackIsEnabled"
                    label="Enable Slack"
                    value={jobNotification.slackNotification.isEnabled}
                    onChange={(e) => { this.handleCheckboxChange(e, 'slackNotification', 'isEnabled'); }}
                    style={{ fontSize: '150%' }}
                  />
                </FormGroup>

                <div
                  className="mx-3 mb-5"
                  style={{ display: jobNotification.slackNotification.isEnabled ? 'block' : 'none' }}
                >
                  <FormGroup>
                    <Label for="slackChannelName">Channel Name</Label>
                    <div style={{ width: '40%' }}>
                      <Select
                        id="slackChannelName"
                        onChange={e => this.handleSelectChange(e, 'channelName', 'slackNotification')}
                        value={jobNotification.slackNotification.channelName}
                        disabled={loading.slackChannels}
                        options={SlackChannelOptions(slackChannels)}
                        placeholder="Select a channel"
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label for="slackFunctionName">Function Name</Label>
                    <div style={{ width: '40%' }}>
                      <Select
                        id="slackFunctionName"
                        onChange={e => this.handleSelectChange(e, 'functionName', 'slackNotification')}
                        value={jobNotification.slackNotification.functionName}
                        disabled={loading.slackFunctions}
                        options={FunctionlOptions(slackFunctions)}
                        placeholder="Select a function"
                      />
                    </div>
                  </FormGroup>
                </div>

                <ButtonWithSpinner
                  className="px-5 mb-1 btn-sm"
                  type="button"
                  outline
                  color="success"
                  disabled={errors.fromEmailEmail}
                  loading={loading.saveJobNotification}
                  onClick={this.saveJobNotification}
                >
                  Save Job Notification
                </ButtonWithSpinner>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EmailTemplatePreviewModal
          subject={previewTitle}
          body={previewBody}
          modal={previewModal}
          closeDialog={this.closePreviewModal}
          loading={loading.emailModalData || loading.notificationModalData || loading.hubspotTaskModalData}
        />
      </Container>
    );
  }
}

JobNotificationSettings.propTypes = {
  addNotice: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withNotice(JobNotificationSettings);
