import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  ModalFooter,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { IconButton } from '@material-ui/core';
// import Tooltip from '@material-ui/core/Tooltip';
// import { checkIfImageExists } from '../../../../../shared/helpers/WVUtilities';

const TabsOrModal = React.memo(({
  record, searchTerms, openTabs, setOpenTabs,
}) => {
  const [showModal, setShowModal] = useState(false);

  const newSearchTerms = [...searchTerms];
  const youtube = record?.params?.tubularDetails?.accounts?.find(t => t.platform === 'youtube');
  const instagram = record?.params?.tubularDetails?.accounts?.find(t => t.platform === 'instagram');
  if (youtube) newSearchTerms.push('YouTube Channel');
  if (instagram) newSearchTerms.push('Instagram Channel');
  const openNewTabs = () => {
    setOpenTabs(false);
    const wTabs = { ...(window?.customTabs || {}) };
    let isBlocked = false;
    newSearchTerms.forEach((term) => {
      if (!wTabs[term] || wTabs[term].closed) {
        const tryOpenWindow = window.open('', '_blank');
        if (!tryOpenWindow) {
          isBlocked = true;
          return false;
        }
        tryOpenWindow.blur();
        wTabs[term] = tryOpenWindow;
      }
      return true;
    });
    if (isBlocked) {
      // console.log(wTabs);
      Object.values(wTabs).forEach(t => t && t.close());
      setShowModal(true);
    } else {
      // console.log(wTabs);
      newSearchTerms.forEach((term) => {
        if (['YouTube Channel', 'Instagram Channel'].includes(term)) {
          wTabs[term].location.href = term === 'YouTube Channel' ? `${youtube.url}/videos` : instagram.url;
        } else {
          // console.log(term);
          const query = new URLSearchParams();
          const title = (record?.params.firstname || record?.params.lastname ? `${record.params.firstname} ${record.params.lastname}` : (record?.params.title || ''));
          query.set('q', `${title} ${term}`);
          wTabs[term].location.href = `https://www.google.com/search?${query.toString()}`;
        }
      });
      window.customTabs = wTabs;
    }
  };
  useEffect(() => {
    if (openTabs) {
      openNewTabs();
    }
  }, [openTabs]);

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <ModalHeader toggle={() => setShowModal(false)} tag="h4" className="pl-0">Safety Check</ModalHeader>
        <div>
          <div className="pt-3">Disable your Pop-Up Blocker for this site if you wish to automatically open search results in new tabs.</div>
          <ListGroup className="mt-3">
            {
              newSearchTerms.map((term) => {
                const query = new URLSearchParams();
                const title = (record?.params?.firstname || record?.params?.lastname ? `${record.params.firstname} ${record.params.lastname}` : (record?.params?.title || ''));
                query.set('q', `${title} ${term}`);
                let url = `https://www.google.com/search?${query.toString()}`;
                if (['YouTube Channel', 'Instagram Channel'].includes(term)) {
                  url = term === 'YouTube Channel' ? `${youtube.url}/videos` : instagram.url;
                }
                return (<ListGroupItem key={term} >{term} <a href={url} target="_blank" rel="noreferrer"><i className="fa fa-external-link-alt float-right" /></a></ListGroupItem>);
              })
            }
          </ListGroup>
        </div>
        <ModalFooter className="modal__footer">
          <Button
            className="btn-sm modal_cancel"
            color="secondary"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}, (a, a1) => _.isEqual(a.record, a1.record) && _.isEqual(a.openTabs, a1.openTabs) && _.isEqual(a.searchTerms, a1.searchTerms));

TabsOrModal.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  searchTerms: PropTypes.arrayOf(PropTypes.any).isRequired,
  openTabs: PropTypes.bool.isRequired,
  setOpenTabs: PropTypes.func.isRequired,
};

TabsOrModal.defaultProps = {
  record: null,
};

export default TabsOrModal;
