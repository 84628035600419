import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Modal,
  ModalHeader,
  Spinner,
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { axios } from 'ApiClient';
import withNotice from '../../../../../../App/store/with-notice';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';
import ReactTableBase from '../../../../../../../shared/tables/table/ReactTableBase';
import Alert from '../../../../../../../shared/easydev2/shared/components/Alert';


export const modalId = 'showHubpotDeals';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSelectable: false,
  useFlex: true,
  isSortable: true,
  withPagination: true,
  manualPageSize: [5, 10, 15, 20],
  withSearchEngine: false,
  sortBy: 'date',
  direction: 'desc',
};

const ShowHubspotDeals = () => {
  const [loading, setLoading] = useState(false);
  const [deals, setDeals] = useState([]);
  const [error, setError] = useState([]);
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  console.log(modalInfo);
  const dispatch = useDispatch();
  const { data: { record }, isShow } = modalInfo || { data: {} };
  const close = () => dispatch(hideModalById(modalId));


  console.log(record);
  const fetchBrandApprovals = () => {
    setLoading(true);
    const { hsUserId } = record;
    // console.log('prospectId user id', prospectId);
    if (!hsUserId) {
      // console.log('no user id', record.params);
      return false;
    }


    try {
      axios.get(`/api/portal/prospects/get-deals-for-prospect?hsUserId=${hsUserId}`).then((result) => {
        if (result.data.success) {
          setDeals(result.data.deals);
        } else {
          setError(result.data.error);
        }
        setLoading(false);
      });
    } catch (e) {
      console.error(e.message);
      setLoading(false);
    }
    //  setLastAxiosCall(now);
    return true;
  };
  useEffect(() => {
    if (isShow) {
      fetchBrandApprovals();
    }
  }, isShow);
  if (!modalInfo) return false;

  const columnTransform = [{
    Header: 'Deal Name',
    id: 'name',
    accessor: r => (r.name || ''),
    // eslint-disable-next-line react/prop-types
    Cell: ({ value, row }) => (
      <div>
        <b>
          { /* eslint-disable-next-line react/prop-types */ }
          {row.original.hsObjectId ? (
            <a
              title="Show HubSpot Deal"
              target="_blank"
              rel="noopener noreferrer"
              className=""
              // eslint-disable-next-line react/prop-types
              href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${row.original.hsObjectId}`}
            >
              {value}
            </a>
          ) : null}
        </b>
      </div>),
  }, {
    Header: 'Deal Owner',
    id: 'owner',
    accessor: r => (r.owner || ''),
  },
  {
    Header: 'Creator Name',
    id: 'creator',
    accessor: r => (`${r.influencer.first_name || ''} ${r.influencer.last_name || ''}`),
    // eslint-disable-next-line react/prop-types
    Cell: ({ value, row }) => (
      <div>
        { /* eslint-disable-next-line react/prop-types */ }
        <b>{row.original.influencer ? (
          <a
            title="Show Influencer"
            target="_blank"
            rel="noopener noreferrer"
            className=""
            // eslint-disable-next-line react/prop-types
            href={`/influencer/profile/${row.original.influencer._id}`}
          >
            {value}
          </a>
        ) : null}
        </b>
      </div>),
  },
  {
    Header: 'Type',
    id: 'type',
    accessor: r => (r.type || ''),
  },
  {
    Header: 'Stage',
    id: 'stage',
    accessor: r => (r.stage || ''),
  },
  {
    Header: 'Brand',
    id: 'brand',
    accessor: r => (r.brand || ''),
  }];
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
      style={{ minWidth: '900px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Hubspot Deals
      </ModalHeader>
      <div
        className="mt-3 mb-3"
      >
        {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        {!loading && deals.length === 0 ? (
          <Alert color="warning">
            <p className="py-2 text-dark">
              {error}
            </p>
          </Alert>
        ) : null}
        {!loading && deals.length > 0 ? (
          <ReactTableBase
            key="filesIO"
            columns={columnTransform}
            data={deals}
            tableConfig={tableConfig}
          />
        ) : null}
      </div>
      <ButtonToolbar className="modal__footer mt-3">
        <Button color="secondary" onClick={close}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

ShowHubspotDeals.propTypes = {

};


export default withNotice(ShowHubspotDeals);
