import React from 'react';
import moment from 'moment';
import { Button, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import ReactTableBase from '../../../../../../../../shared/tables/table/ReactTableBase';
import { currentTimezoneAggr } from '../../../../../../../../shared/helpers/Dates';
import Hook from '../../../../../../../../shared/hooks';

export const modalId = 'fileHistoryModal';


const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSelectable: false,
  useFlex: true,
  isSortable: true,
  withPagination: true,
  manualPageSize: [5, 10, 15, 20],
  withSearchEngine: false,
  sortBy: 'date',
  direction: 'desc',

};

const FileHistroryModal = () => {
  const { data: dataModal, showModal: showUploadModal, close } = Hook.useModalHook(modalId);
  const { record } = dataModal;
  if (!record) return false;

  const { googleSheetFiles: files } = record.params;
  const columnTransform = [{
    Header: 'Name',
    id: 'name',
    accessor: r => (r.title || ''),
  }, {
    id: 'date',
    Header: `Created Date (${currentTimezoneAggr()})`,
    accessor: r => (r.createdTime || ''),
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => moment(value).format('M/D/YY h:mma'),
  }, {
    Header: 'Links',
    accessor: r => (r.fileUrl || ''),
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value, row }) => (
      <div>
        <a href={value} title="Open Google Sheet File" target="_blank" rel="noreferrer" style={{ fontSize: '20px' }} className="pr-2 text-dark"><i className="fa fa-file-excel" /></a>
        <a
          // eslint-disable-next-line react/prop-types
          href={`https://docs.google.com/spreadsheets/d/${row.original.fileId}/export?format=pdf&gridlines=false`}
          title="Download PDF File"
          target="_blank"
          rel="noreferrer"
          className="text-dark"
          style={{ fontSize: '20px' }}
        ><i className="fa fa-file-pdf" />
        </a>
      </div>
    ),
  }];
  return (
    <Modal isOpen={showUploadModal} toggle={close}>
      <ModalHeader toggle={close} tag="h4">
        Publish History
      </ModalHeader>
      <ModalBody className="ltr-support">
        <ReactTableBase
          key="filesIO"
          columns={columnTransform}
          data={files}
          tableConfig={tableConfig}
        />
        <Row className="modal__footer" style={{ marginTop: '30px' }}>
          <Button
            className="btn btn-secondary btn-sm"
            onClick={close}
            color="secondary"
            style={{ margin: '0 10px 0 10px' }}
          >
            Close
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default FileHistroryModal;
