import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { showChangeMediaContentStatusDialog } from '../../../../../../redux/reducers/admin/ScoringReducer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { modalId as notesModalId } from '../custom/NotesModal';
import {showModalById} from '../../../../../../redux/reducers/TableReducer';

const StatusInList = ({ record, dispatch }) => {
  const recortUnflatten = unflatten(record);
  const {
    status, _id, notes, scorecardsCount, brandNotes,
  } = recortUnflatten.params;

  const clickChangeStatusIcon = () => {
    const data = {
      id: _id,
      status,
      notes,
    };
    dispatch(showChangeMediaContentStatusDialog(true, data));
  };

  let icon = '';
  if (status === 'New' || status === 'Rescore') {
    icon = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a title="Pending Edits" role="button">
        <IconButton size="small" key={`${_id}fa-hourglass`} onClick={clickChangeStatusIcon}><i className="fa fa-hourglass" /></IconButton>
      </a>
    );
  }
  if (status === 'Pending Edits') {
    icon = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a title="Rescore" role="button">
        <IconButton size="small" key={`${_id}fa-retweet`} onClick={clickChangeStatusIcon}><i className="fas fa-retweet" /></IconButton>
      </a>
    );
  }
  const notesIcon = (
    <Tooltip title="Notes">
      <IconButton
        size="small"
        onClick={() => { dispatch(showModalById(notesModalId, { record })); }}
      >
        <FontAwesomeIcon icon={[notes || brandNotes ? 'fas' : 'far', 'file-alt']} />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <span>
        {status} ({scorecardsCount})
        &nbsp;
        {icon}
        {icon && (
          <span>&nbsp;</span>
        )}
        {notesIcon}
      </span>
    </>
  );
};
StatusInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};
export default connect()(StatusInList);

