/* eslint react/prop-types: 0 */
import React from 'react';
import styled from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';

import theme from '../../../../theme';

const StyledLink = styled(NavLink).attrs({
  className: 'is-sortable text-small',
})`
  color: ${theme.colors.lightText};

  &.active {
    color: ${theme.colors.primary};
  }

  & > i {
    margin-left: ${theme.sizes.padding}
  }
`;


class SortLink extends React.PureComponent {
  constructor(props) {
    super(props);
    this.isActive = this.isActive.bind(this);
  }

  isActive() {
    const { sortBy, property } = this.props;
    return sortBy === property.name;
  }

  render() {
    const { property, location, direction } = this.props;
    const query = new URLSearchParams(location.search);
    const opositeDirection = (this.isActive() && direction === 'asc') ? 'desc' : 'asc';
    const sortedByClass = `icomoon-dropdown-${direction === 'asc' ? 'open' : 'close'}`;

    query.set('direction', opositeDirection);
    query.set('sortBy', property.name);

    return (
      <StyledLink to={{ search: query.toString() }} isActive={this.isActive}>
        {property.label}
        {this.isActive() ? (<i className={sortedByClass} />) : ''}
      </StyledLink>
    );
  }
}

export default withRouter(SortLink);
