import React, { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { unflatten } from 'flat';

const fetchAgent = async (id) => {
  if (id) {
    const response = await axios.post('/api/portal/agent/post-fetch-agent-by-id', { id });
    if (response?.data?.success && response.data.agent) {
      return response.data.agent;
    }
  }
  return null;
}

const AgentEmail = ({ record }) => {
  const [bill, setBill] = useState(unflatten(record));
  const [agentUser, setAgentUser] = useState({});
  const [agentInfo, setAgentInfo] = useState('');
  const [additionalTitle, setAdditionalTitle] = useState('');
  const [agentUserId, setAgentUserId] = useState('');

  useEffect(() => {
    if (record?.id !== bill.id) {
      setAgentUser({});
      setBill(unflatten(record));
    }
  }, [record]);

  useEffect(async () => {
    if (bill?.params?.agentUser) {
      setAgentUser(bill?.params?.agentUser);
    } else if (
      bill?.params?.influencerObject?.payment?.payee === 'creatorCOAgent'
      && bill?.populated?.deal?.params?.ilDealData?.agentUsers?.length > 0
    ) {
      const agentUserObject = await fetchAgent(bill.populated.deal.params.ilDealData.agentUsers[0]);
      setAgentUser(agentUserObject);
    }
  }, [bill]);

  useEffect(() => {
    if (agentUser) {
      setAgentUserId(agentUser._id);

      setAgentInfo(() => {
        let temp = (agentUser.first_name || agentUser.last_name || agentUser.email) ? `${agentUser.first_name || ''} ${agentUser.last_name || ''} (${agentUser.email || ''})` : '';
        if (agentUser.payment?.billCom?.isDefault) {
          temp = `BillCom Account: ${(agentUser.payment.billCom.name || agentUser.payment.billCom.email) ? `${agentUser.payment.billCom.name || ''} (${agentUser.payment.billCom.email || ''})` : 'missing'}`;
        }
        if (agentUser.payment?.stripe?.isDefault) {
          temp = `Stripe Account: ${agentUser.payment.stripe.email || 'missing'}`;
        }
        return temp;
      });

      setAdditionalTitle(() => {
        let temp = '';
        if (bill?.params?.influencerObject?.payment?.payee === 'agent') {
          temp = 'The agent will receive payment for this creator.';
        }
        if (bill?.params?.influencerObject?.payment?.payee === 'creatorCOAgent') {
          temp = 'The Creator will receive payment c/o the Agent.';
        }
        return temp;
      });
    }
  }, [agentUser]);

  const additional = agentUserId ? (
    <div
      style={{
            position: 'absolute', right: '10px', maxWidth: '25px',
          }}
    >
      <Tooltip
        title={`${additionalTitle} ${agentInfo}`.trim()}
      >
        <a
          href={`/agent/profile/${agentUserId}`}
          target="_blank" rel="noreferrer">
          <img
            style={{ maxWidth: '25px' }}
            src={`/images/creator/agent-${bill?.params?.influencerObject?.payment?.enablePayments === false ? 'enabled' : 'disabled'}.png`}
            alt={bill?.params?.influencerObject?.payment?.enablePayments === false ? 'Enabled Agent' : 'Disabled Agent'}
          />
        </a>
      </Tooltip>
    </div>
  ) : null;

  return (
    <div style={{ position: 'relative', display: 'flex' }}>
      <div style={{ marginRight: '20px', maxWidth: '240px' }}>{bill?.params?.influencerObject?.email || ''}</div>
      {additional}
    </div>
  );
};

AgentEmail.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AgentEmail;
