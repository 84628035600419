import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  FormGroup,
} from 'reactstrap';
import { CKEditor } from 'ckeditor4-react';

const NotesModal = ({
  isOpen,
  closeFunc,
  setVersion,
  comment,
  disabled,
  source,
}) => {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [brandRelevantNotes, setBrandRelevantNotes] = useState('');
  const [loadingNotes, setLoadingNotes] = useState(false);

  const fetchNotes = (editor) => {
    setLoadingNotes(true);
    axios({
      method: 'get',
      url: `/api/portal/comments/get-notes?id=${comment.id}`,
    }).then((response) => {
      if (response.data.success === true) {
        if (editor) editor.setData(source === 'sentimentLabel' ? response.data.humanNotes : response.data.brandRelevantNotes);
        setNotes(response.data.humanNotes);
        setBrandRelevantNotes(response.data.brandRelevantNotes);
        setLoadingNotes(false);
      }
    }).catch((err) => {
      console.log(err);
      setLoadingNotes(false);
    });
  };
  useEffect(() => {
    if (isOpen && comment && disabled) {
      fetchNotes();
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    const { editor } = e;
    if (source === 'sentimentLabel') {
      setNotes(editor.getData());
    } else {
      setBrandRelevantNotes(editor.getData());
    }
  };

  // SUBMIT Data
  const submit = async () => {
    setLoading(true);

    axios({
      method: 'post',
      url: '/api/portal/comments/post-save-notes',
      data: {
        id: comment.id,
        params: {
          sentimentLabel: {
            notes,
          },
          brandRelevancyLabel: {
            notes: brandRelevantNotes,
          },
          // 'sentimentLabel.notes': notes,
          // 'brandRelevancyLabel.notes': brandRelevantNotes,
        },
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        setNotes('');
        setBrandRelevantNotes('');
        closeFunc();
      }
    });
    return true;
  };

  const close = () => {
    setNotes('');
    setBrandRelevantNotes('');
    closeFunc();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={close}
      style={{ minWidth: '670px' }}
    >
      <ModalHeader
        toggle={close}
        tag="h4"
        className="modal-title-statistics pl-0"
      >
        {source === 'sentimentLabel' ? 'Sentiment Notes' : 'Brand Relevancy Notes'}
        {loadingNotes && (
          <span>
            <div className="spinner-border il-text-gray spinner-border-sm" />
          </span>
        )}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {comment && !disabled && (
              <FormGroup>
                <CKEditor
                  config={{versionCheck: false}}
                  readOnly={loadingNotes}
                  id="bodyEditor"
                  name="itemText"
                  activeClass="form-control"
                  initData={source === 'sentimentLabel' ? notes : brandRelevantNotes}
                  onChange={(e) => { handleInputChange(e); }}
                  onInstanceReady={({ editor }) => fetchNotes(editor)}
                />
              </FormGroup>
            )}
            {comment && disabled && (
              <FormGroup>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: notes }} />
              </FormGroup>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        {!disabled && (
          <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading} style={{ marginRight: '10px' }}>
            {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
            <span>Save</span>
          </Button>
        )}
        <Button className="modal_cancel btn-sm" onClick={close}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};


NotesModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  comment: PropTypes.objectOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  source: PropTypes.string,
};

NotesModal.defaultProps = {
  source: '',
};

export default NotesModal;
