/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import ActionHeader from '../../../../../../shared/components/components/app/action-header';
import MatTableList from '../../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../../shared/components/BreadCrumbs';
import UserPropertyType from '../../components/custom';
import CheckboxManyFilter from '../../../../../../shared/components/CheckboxManyFilter';
import StyledButton from '../../../../../../shared/components/components/ui/styled-button';


const initialFiltersType = {
  YouTube: true,
  'YouTube Shorts': true,
  'YouTube Description Text': true,
  Podcast: true,
  'Podcast Script Text': true,
  'Instagram Story': true,
  'Instagram Post': true,
  'Instagram Reels': true,
  'Instagram TV Video': true,
  TikTok: true,
  'Facebook Post': true,
  'Facebook Watch Video': true,
  'Twitter Post': true,
  LinkTree: true,
  Website: true,
  Email: true,
  Text: true,
};

class ResourceAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: (new Date()).toString(),
      filtersType: initialFiltersType,
    };

    this.setFiltersType = this.setFiltersType.bind(this);
  }

  setFiltersType = (filters) => {
    this.setState({ filtersType: filters.data });
  }

  render() {
    const {
      //  match,
      resource,
      filterVisible,
      tag,
      setTag,
      action,
      // dispatch,
    } = this.props;
    const {
      version,
      filtersType,
    } = this.state;
    const links = [{ title: 'Home', path: '/' }, { title: 'Compliance', path: false }, { title: 'Scorecard Definitions', path: false }];
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs links={links} isBackButton />
            <ActionHeader
              resource={resource}
              action={action}
              tag={tag}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="/resources/Scorecard/actions/new">
              <StyledButton
                type="button"
                className="is-primary"
              >
                <i className="icomoon-save" />
                <span className="btn-text">New Scorecard</span>
              </StyledButton>
            </Link>
            <CheckboxManyFilter
              filterProp={filtersType}
              filterTitle="Type Filters"
              filterAction={this.setFiltersType}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="scorecard-definition-dashboard-container">
            <MatTableList
              action={action}
              filters={{ type: filtersType }}
              resource={resource}
              setTag={setTag}
              date={new Date()}
              version={version}
              UserPropertyType={UserPropertyType}
              columnsSettings={{
                name: {
                  width: 200,
                },
                type: {
                  width: 150,
                },
                version: {
                  width: 100,
                },
                description: {
                  width: 400,
                },
              }}
              showFilters={filterVisible}
              hideToolbar
              v={2}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
