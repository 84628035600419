import func from '@/shared/hooks';

export default ({ dealstages }) => [
  {
    initialState: '',
    func: ({ filters }) => filters?.fullSearchName || '',
    filterName: 'fullSearchName',
    funcToUrl: ({ search, item }) => {
      if (item) {
        search.set('filters.fullSearchName', item);
      } else {
        search.delete('filters.fullSearchName');
      }
      return search;
    },
  },
  {
    initialState: {
      tags: []
   },
    func: func.getFromUrlSelectfunction,
    filterName: 'dealstages',
    funcToUrl: func.getToUrlSelectfunction,
    options: dealstages,
  },
  {
    initialState: {
      Hybrid: true,
      'Upfront Only': true,
      'Upfront CPM': true,
      'Media License': true,
      Bonus: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'typeOfDeal',
  },
  {
    initialState: {
      0: true,
      1: true,
      '2+': true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'count',
  },

];
