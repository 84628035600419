import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { checkImagePromise, parseUrl } from '../../../../../../../../shared/helpers/WVUtilities';
import HighLight from '../../../../../../../../shared/components/HighLight';
import {
  AddressIcon,
} from '../../../../../../../../shared/helpers/Icons';

const CreatorInList = React.memo(({
  record,
}) => {
  // if (!record.params.tubularDetails) console.log(record);
  const [image, setImage] = useState('/images/user/defaultAvatar.png');
  const filters = parseUrl();
  const search = filters.fullSearchName || '';
  const recordY = record.params.tubularDetails?.accounts.find(a => a.platform === 'youtube');
  const recordI = record.params.tubularDetails?.accounts.find(a => a.platform === 'instagram');
  const recordF = record.params.tubularDetails?.accounts.find(a => a.platform === 'facebook');
  const recordT = record.params.tubularDetails?.accounts.find(a => a.platform === 'twitter');
  const recordTwitch = record.params.tubularDetails?.accounts.find(a => a.platform === 'twitch');

  useEffect(async () => {
    setImage('/images/user/defaultAvatar.png');
    if (recordY && await checkImagePromise(recordY.thumbnailUrl)) {
      setImage(recordY.thumbnailUrl);
    } else if (recordI && await checkImagePromise(recordI.thumbnailUrl)) {
      setImage(recordI.thumbnailUrl);
    } else if (recordF && await checkImagePromise(recordF.thumbnailUrl)) {
      setImage(recordF.thumbnailUrl);
    } else if (recordT && await checkImagePromise(recordT.thumbnailUrl)) {
      setImage(recordT.thumbnailUrl);
    } else if (recordTwitch && await checkImagePromise(recordTwitch.thumbnailUrl)) {
      setImage(recordTwitch.thumbnailUrl);
    }
  }, [record.id]);

  return (
    <div
      className="d-flex"
      style={{ minWidth: '350px' }}
    >
      <img
        src={image}
        alt={record.params.title}
        style={{ width: '50px', height: 'auto' }}
        className="align-self-center"
      />
      <div
        className="align-self-center flex-column"
        style={{ marginLeft: '8px' }}
      >
        <div className="firstAndLastName">
          {/* eslint-disable-next-line no-nested-ternary */}
          <HighLight search={search}>{record.params.contactName ? record.params.contactName : record.params.influencerName ? record.params.influencerName : ''}</HighLight>
          {record.params.hsUserId && (
            <a
              title="Show HubSpot Contact"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.params.hsUserId}`}
            >
              {' '}
              <i className="fab fa-hubspot" />
            </a>
          )}
          {record.params.tubularDetails?.tubularCreatorId ? (
            <a
              title="Show Tubular Labs"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://tubularlabs.com/creator/${record.params.tubularDetails.tubularCreatorId}`}
            >
              {' '}
              <i className="fa fa-flask" />
            </a>
          ) : null}
          {record.populated?.influencer?.id ? (
            <div className="" style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <a
                title="Creator Profile"
                target="_blank"
                rel="noreferrer"
                href={`/influencer/profile/${record.populated?.influencer?.id}`}
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
                className="mx-1"
              >
                <AddressIcon />
              </a>
            </div>
          ) : null}
          {record.populated?.deal?.params.ilDealData?.agentUsers?.length > 0 ?
            (
              <a
                title="Agent Profile"
                target="_blank"
                rel="noopener noreferrer"
                href={`/agent/profile/${record.populated.deal.params.ilDealData?.agentUsers[0]}`}
              >
                {' '}
                <i className="fa fa-user-secret" />
              </a>
            ) : null
          }
        </div>
        <div className="" >
          {record.params.tubularDetails?.accounts.map(t => (
            <a
              href={t.url}
              target="_blank"
              rel="noreferrer"
              key={t.platform}
              style={{ marginRight: '4px' }}
            >
              <img
                src={`/images/mediacontent/${t.platform}.png`}
                alt={t.platform}
                title={t.title}
                style={{ width: '20px' }}
              />
            </a>))}
          {!record.params?.tubularDetails && record.populated?.influencer?.params.mediaChannels.youtube_channel_url && (
            <a
              href={record.populated.influencer.params.mediaChannels.youtube_channel_url}
              target="_blank"
              rel="noreferrer"
              key="youtube"
              style={{marginRight: '4px'}}
            >
              <img
                src="/images/mediacontent/youtube.png"
                alt="youtube"
                title={record.populated.influencer.params.mediaChannels.youtube_channel_name || 'YouTube Channel'}
                style={{width: '20px'}}
              />
            </a>
          )}
          {!record.params?.tubularDetails && record.populated?.influencer?.params.mediaChannels.instagram_channel && (
            <a
              href={record.populated.influencer.params.mediaChannels.instagram_channel}
              target="_blank"
              rel="noreferrer"
              key="instagram"
              style={{marginRight: '4px'}}
            >
              <img
                src="/images/mediacontent/instagram.png"
                alt="instagram"
                title={record.populated.influencer.params.mediaChannels.instagram_channel_name || 'Instagram Profile'}
                style={{width: '20px'}}
              />
            </a>
          )}
          {!record.params?.tubularDetails && record.populated?.influencer?.params.mediaChannels.facebook_channel_url && (
            <a
              href={record.populated.influencer.params.mediaChannels.facebook_channel_url}
              target="_blank"
              rel="noreferrer"
              key="facebook"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/facebook.png"
                alt="facebook"
                title="Facebook Profile"
                style={{ width: '20px' }}
              />
            </a>
          )}
          {!record.params?.tubularDetails && record.populated?.influencer?.params.mediaChannels.twitterhandle && (
            <a
              href={record.populated.influencer.params.mediaChannels.twitterhandle}
              target="_blank"
              rel="noreferrer"
              key="twitter"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/twitter.png"
                alt="twitter"
                title="Twitter Profile"
                style={{ width: '20px' }}
              />
            </a>
          )}
          {!record.params?.tubularDetails && record.populated?.influencer?.params.mediaChannels.tiktok_channel_url && (
            <a
              href={record.populated.influencer.params.mediaChannels.tiktok_channel_url}
              target="_blank"
              rel="noreferrer"
              key="tiktok"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/tiktok.png"
                alt="twitter"
                title="TikTok Channel"
                style={{ width: '20px' }}
              />
            </a>
          )}
          {!record.params?.tubularDetails && record.populated?.influencer?.params.mediaChannels.twitch_account && (
            <a
              href={record.populated.influencer.params.mediaChannels.twitch_account}
              target="_blank"
              rel="noreferrer"
              key="twitch"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/twitch.png"
                alt="twitch"
                title="Twitch Account"
                style={{ width: '20px' }}
              />
            </a>
          )}
          {record.params.listenNotesDetails?.url && (
            <a
              href={record.params.listenNotesDetails.url}
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/podcast_128.png"
                alt="podcast"
                title={record.params.listenNotesDetails.title}
                style={{ width: '20px' }}
              />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}, (a, a1) => a.record.id === a1.record.id);

CreatorInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CreatorInList;
