import React, { useState, useEffect } from 'react';
import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import CurrencyField from '../../../../../../shared/components/form/CurrencyField';


const PayoutAmount = ({ record, updateRecordFunction }) => {
  const a = record.params.payoutAmount;
  const [amount, setAmount] = useState(a);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setAmount(a);
  }, [a]);
  console.log(a);

  const handleChange = (event, value) => {
    // console.log(event);
    // this.setState({ loading: true });
    setLoading(true);
    setAmount(value);
    // const { value } = event.target;
    const api = new ApiClient();
    const { id } = record;
    const data = new FormData();
    data.append('payoutAmount', value);
    api.recordAction({
      resourceId: 'Payable',
      recordId: id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      api.recordAction({
        resourceId: 'Payable',
        recordId: id,
        actionName: 'show',
      }).then((res) => {
        console.log(res, 'RESSSS');
        setLoading(false);
        updateRecordFunction(id, { payoutAmount: value, ilPayoutAmount: res.data?.record?.params?.ilPayoutAmount || 0 });
        // updateRecordFunction(e.data.record, 'payoutAmount');
      });
    });
    return true;
  };
  return (
    <div className="px-2"><CurrencyField placeholder="Payout Amount" disabled={loading || record.checkboxDisable} defaultValue={amount} onBlur={handleChange} /></div>
  );
};
PayoutAmount.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};
export default PayoutAmount;
/* export default class PayoutAmount extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    updateRecordFunction: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.api = new ApiClient();
    const { record } = this.props;
    const amount = record.params.payoutAmount;
    this.state = {
      amount,
      loading: false,
    };
  }

  handleChange(event, value) {
    // console.log(event);
    this.setState({ loading: true });
    const { record, updateRecordFunction } = this.props;

    // const { value } = event.target;
    const { id } = record;
    const data = new FormData();
    data.append('payoutAmount', value);
    this.api.recordAction({
      resourceId: 'Payable',
      recordId: id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      this.api.recordAction({
        resourceId: 'Payable',
        recordId: id,
        actionName: 'show',
      }).then(() => {
        this.setState({ loading: false });
        updateRecordFunction(id, { payoutAmount: value });
        // updateRecordFunction(e.data.record, 'payoutAmount');
      });
    });
    return true;
  }

  render() {
    const { loading, amount } = this.state;
    const { record } = this.props;
    return (
      <div className="px-2"><CurrencyField placeholder="Payout Amount" disabled={loading || record.checkboxDisable} defaultValue={amount} onBlur={this.handleChange} /></div>
    );
  }
} */
