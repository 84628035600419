import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ButtonToolbar,
} from 'reactstrap';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import PermissionsDialog from '../../PermissionsDialog';
import { addNotice } from '../../../../../App/store/store';

const Actions = React.memo(({
  onSearchChange,
  searchValue,
  showDeleted,
  setShowDeleted,
  availablePermissions,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [openPermissionsDialog, setOpenPermissionsDialog] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);
  const [finalPermissions, setFinalPermissions] = useState([]);
  const [finalAdminUsers, setFinalAdminUsers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  const toggleDialog = () => {
    setOpenPermissionsDialog(!openPermissionsDialog);
  };

  const fetchAdminUsers = async () => {
    const response = await axios.get('/api/portal/adminuser/get');
    const suggestions = [];
    if (response.data.success === true && response.data.adminUsers?.length > 0) {
      response.data.adminUsers.forEach((user) => {
        suggestions.push({
          id: user._id,
          name: `${user.firstName} ${user.lastName} ${user.email}`,
          permissions: user.permissions ? [...user.permissions] : [],
        });
      });
    }
    return suggestions;
  };

  const saveAdminUsersPermissions = async () => {
    const permissionsSubSet = finalPermissions.filter(p => p.shouldUpdate === true);

    const response = await axios.post('/api/portal/adminuser/save-permissions', {
      adminUsers: finalAdminUsers.map(a => a.id),
      permissions: permissionsSubSet,
    });

    if (response.data.success) {
      toggleDialog();
      setFinalAdminUsers([]);
      setFinalPermissions([]);
      setAdminUsers([]);
    } else {
      dispatch(addNotice({
        message: response.data.error || 'Failed to save user permissions',
        type: 'error',
        duration: 10,
      }));
    }
  };

  const permissionsHandler = async () => {
    if (adminUsers.length === 0) {
      const users = await fetchAdminUsers();
      setAdminUsers(users);
    }

    toggleDialog();
  };

  const handleShowDeleted = (event) => {
    if (event && event.target) {
      const { checked } = event.target;
      setShowDeleted(checked);
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setShowDeleted(false);
  };

  return (
    <div className="prospects-actions">
      <Row className="mt-3">
        <Col sm={6}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search by Name and Email"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col
          sm={2}
          className="text-right"
          style={{ paddingTop: '12px' }}
        >
          <CheckBoxField
            name="showDeleted"
            label="Show deleted users"
            value={showDeleted}
            onChange={handleShowDeleted}
          />
        </Col>
        <Col sm={2}>
          <Tooltip title="Reset Filters">
            <Button
              color="secondary"
              size="sm"
              onClick={resetFilters}
              style={{
                width: '100%',
                padding: '4px 8px',
                height: '42px',
              }}
            >
              <i className="fa fa-sync" aria-hidden="true" />&nbsp;
              <span className="btn-text">Reset</span>
            </Button>
          </Tooltip>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 8px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => window.open('/resources/AdminUser/actions/new', '_self').focus()}
              >
                Add New User
              </DropdownItem>
              <DropdownItem
                onClick={permissionsHandler}
              >
                Update Permissions
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <div >
        <Modal
          isOpen={openPermissionsDialog}
          toggle={toggleDialog}
          id="adminUserPermissionModal"
          style={{ minWidth: '60%' }}
        >
          <ModalHeader toggle={toggleDialog} tag="h1">Update Permissions</ModalHeader>
          <PermissionsDialog
            adminUsers={adminUsers}
            availablePermissions={availablePermissions}
            setFinalPermissions={setFinalPermissions}
            setFinalAdminUsers={setFinalAdminUsers}
            finalPermissions={finalPermissions}
            finalAdminUsers={finalAdminUsers}
          />
          <ButtonToolbar className="modal__footer">
            <Button color="primary" onClick={saveAdminUsersPermissions}>Save</Button>
             &nbsp;&emsp;&nbsp;&emsp;
            <Button color="secondary" onClick={toggleDialog}>Cancel</Button>
          </ButtonToolbar>
        </Modal>
      </div>
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  a.showDeleted === a1.showDeleted);

Actions.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  showDeleted: PropTypes.bool.isRequired,
  setShowDeleted: PropTypes.func.isRequired,
  availablePermissions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default withStyles({
  root: {
    width: '100%',
    minWidth: '200px',
    minHeight: '44px',
    paddingRight: '30px',
    position: 'relative',
    marginBottom: '10px',
  },
  close: {
    position: 'absolute',
    bottom: '1px',
    right: '0px',
  },
})(connect(mapStateToProps)(Actions));
