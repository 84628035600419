import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import ApiClient from 'ApiClient';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import StyledButton from '../../../../shared/components/components/ui/styled-button';
import recordToFormData from '../../../../shared/components/components/actions/record-to-form-data';
import { Months, momentLocaleToServerUTC } from '../../../../shared/helpers/Dates';
import { addAccountPayableRecord } from '../../../../redux/reducers/admin/AccountsPayableReducer';
import { addNotice } from '../../../App/store/store';
import csvHelper from '../../../../shared/helpers/CSVHelper';
import ReportModal from '../../../../shared/components/modals/ReportModal';

class AccountPayableNew extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiClient();
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.selectAccountPayable = this.selectAccountPayable.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.selected = null;
    this.state = {
      selected: null,
      modal: false,
      record: {
        params: {},
        errors: {},
        populated: {},
      },
    };
  }

  // shouldComponentUpdate(newProps) {
  //   return true;
  // }
  componentDidUpdate(prevProps) {
    this.shouldUpdateRecord(prevProps);
    // this.shouldGetTable(prevProps);
  }

  getMinDate(month) {
    const year = this.state.selected ? this.state.selected.params.yearPeriod : moment().format('YYYY');
    return moment({ y: year, M: Number(month) - 1, d: 1 });
  }
  getMaxDate(month) {
    return this.getMinDate(month).endOf('month');
  }

  getResourceAndAction() {
    const { resources } = this.props;
    const resourceId = 'AccountsPayable';
    const resource = resources.find(r => r.id === resourceId);
    return {
      resource: resource || undefined,
      action: undefined,
    };
  }
  static requiredData = [
    'generalLedger', 'startDate', 'endDate', 'monthPeriod', 'name',
  ];
  async loadOptions(inputValue) {
    console.log(this.props);
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'GeneralLedger',
      query: inputValue,
    });
    return records.map(record => ({
      value: record.id,
      label: record.title,
      record,
    }));
  }
  shouldUpdateRecord(prevProps) {
    if (this.props.accountPayable
        && (!prevProps.accountPayable || !prevProps.accountPayable.id)
        && (this.props.accountPayable.id)
    ) {
      this.setState(state => ({
        record: {
          ...state.record,
          errors: this.props.accountPayable.errors,
          id: this.props.accountPayable.id,
        },
      }));
    }
    if (this.props.accountPayable
        && (Object.keys(this.props.accountPayable.errors).length > 0)
        && ((prevProps.accountPayable && !_.isEqual(this.props.accountPayable.errors, prevProps.accountPayable.errors)) || !prevProps.accountPayable)
    ) {
      this.setState(state => ({
        record: {
          ...state.record,
          errors: this.props.accountPayable.errors,
        },
      }));
    }
  }
  selectAccountPayable(e) {
    const { startDate, endDate } = this.state.record.params;
    const month = e.value;
    const minDate = this.getMinDate(month);
    const maxDate = this.getMaxDate(month);
    // const newStates = { month };
    const startDateO = new Date(startDate);
    const endDateO = new Date(endDate);
    if (startDateO > maxDate.toDate() || startDateO < minDate.toDate()) {
      // newStates.startDate = minDate.toDate();
      this.handleChange('startDate', minDate.toISOString());
    }
    if (endDateO < minDate.toDate() || endDateO > maxDate.toDate()) {
      this.handleChange('endDate', maxDate.toISOString());
    }
    this.handleChange('monthPeriod', month);
  }
  downloadCSV = (type, name) => {
    const data = this.props[type] && this.props[type][name] ? this.props[type][name] : null;
    if (name === 'errors') {
      return csvHelper.downloadArrayCSV(data, 'message', `${type}-${name}.csv`);
    }
    return data ? csvHelper.downloadObjectCSV(data, `${type}-${name}.csv`) : false;
  }
  closeDialog = () => {
    const { record } = this.state;
    const { history, accountPayable } = this.props;
    if (!accountPayable || !accountPayable.id) {
      this.setState({ modal: false });
    } else {
      history.push(`/finance/account-payable-report-2/${record.id}/creators`);
    }
  }

  handleChange(propertyOrRecord, value) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        record: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        record: {
          ...state.record,
          params: {
            ...state.record.params,
            [propertyOrRecord]: value,
          },
          errors: {
            ...state.record.errors,
            [propertyOrRecord]: undefined,
          },
        },
      }));
    }
  }
  handleChangeSelect(selected) {
    const { value } = selected;
    if (selected) {
      this.setState({ selected: selected.record });
      // this.selected = selected.record;
    }
    const { record } = this.state;
    this.handleChange('generalLedger', value);
    this.handleChange('startDate', this.getMinDate(record.params.monthPeriod).toISOString());
    this.handleChange('endDate', this.getMaxDate(record.params.monthPeriod).toISOString());
  }
  handleInputChange(name, event) {
    this.handleChange(name, event.target.value);
  }
  handleChangeEnd(e) {
    this.handleChange('endDate', moment(e).toISOString());
  }
  handleChangeStart(e) {
    this.handleChange('startDate', moment(e).toISOString());
  }
  handleSubmit(event) {
    event.preventDefault();
    // const { resource } = this.getResourceAndAction();
    const { record } = this.state;
    const { dispatch } = this.props;
    let isValid = true;
    this.constructor.requiredData.forEach((item) => {
      if (!record.params[item]) {
        isValid = false;
        this.setState(state => ({
          record: {
            ...state.record,
            errors: {
              ...state.record.errors,
              [item]: { message: 'This field is required' },
            },
          },
        }));
      }
    });
    if (!isValid) {
      dispatch(addNotice({
        message: 'Please Check form. Seems you did not fill some fields',
        type: 'warning',
      }));
      return true;
    }
    this.setState({
      modal: true,
    });
    const formData = recordToFormData(record);
    formData.set('startDate', momentLocaleToServerUTC(moment(record.params.startDate)).toISOString());
    formData.set('endDate', momentLocaleToServerUTC(moment(record.params.endDate)).toISOString());
    dispatch(addAccountPayableRecord(formData));
    return false;
  }
  render() {
    const {
      record,
      modal,
      selected,
    } = this.state;
    const { loading, logs } = this.props;
    const minDate = record.params.monthPeriod ? this.getMinDate(record.params.monthPeriod) : moment();
    const maxDate = record.params.monthPeriod ? this.getMaxDate(record.params.monthPeriod) : moment();
    const startDate = record.params.startDate ? new Date(record.params.startDate) : new Date();
    const endDate = record.params.endDate ? new Date(record.params.endDate) : new Date();
    let selectedOption = {};
    if (selected) {
      selectedOption = {
        value: selected.id,
        label: selected.title,
        record: selected,
      };
    }
    console.log(selectedOption);
    console.log(record);
    const selectedMonth = record.params.monthPeriod ? Months.find(item => Number(item.value) === Number(record.params.monthPeriod)) : '';

    return (
      <Container className="dashboard">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label>General Ledger</Label>
                    <SelectAsync
                      cacheOptions
                      value={selectedOption}
                      defaultOptions
                      loadOptions={this.loadOptions}
                      onChange={this.handleChangeSelect}
                      placeholder="Please Select General Ledger"
                    />
                    {record.errors.generalLedger && (<div className="form__form-group-error">{record.errors.generalLedger.message}</div>)}
                  </FormGroup>
                  <FormGroup>
                    <Label>Month Period</Label>
                    <Select
                      value={selectedMonth}
                      options={Months}
                      onChange={this.selectAccountPayable}
                      placeholder="Please Select Month"
                      className="mb-3"
                    />
                    {record.errors.monthPeriod && (<div className="form__form-group-error">{record.errors.monthPeriod.message}</div>)}
                  </FormGroup>
                  <FormGroup>
                    <Label>Start Date (ET)</Label>
                    <div>
                      <DatePicker
                        selected={startDate}
                        onChange={this.handleChangeStart}
                        dateFormat="MM/dd/yyyy"
                        minDate={minDate.toDate()}
                        maxDate={maxDate.toDate()}
                        placeholderText="Start Date"
                        dropDownMode="select"
                        disabled={!selectedMonth}
                        className="form-control"
                      />
                    </div>
                    {record.errors.startDate && (<div className="form__form-group-error">{record.errors.startDate.message}</div>)}
                  </FormGroup>
                  <FormGroup>
                    <Label>End Date (ET)</Label>
                    <div>
                      <DatePicker
                        selected={endDate}
                        onChange={this.handleChangeEnd}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="End Date"
                        minDate={minDate.toDate()}
                        maxDate={maxDate.toDate()}
                        disabled={!selectedMonth}
                        dropDownMode="select"
                        className="form-control"
                      />
                    </div>
                    {record.errors.endDate && (<div className="form__form-group-error">{record.errors.endDate.message}</div>)}
                  </FormGroup>
                  <FormGroup>
                    <Label>Name</Label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      onChange={e => this.handleInputChange('name', e)}
                      value={record.params.name || ''}
                      invalid={!!record.errors.name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Description</Label>
                    <Input
                      type="text"
                      id="description"
                      name="description"
                      onChange={e => this.handleInputChange('description', e)}
                      value={record.params.description || ''}
                      invalid={!!record.errors.description}
                    />
                  </FormGroup>
                  <StyledButton
                    type="submit"
                    className={`is-primary${loading.accountPayable ? ' is-loading' : ''}`}
                  >
                    <i className="icomoon-save" />
                    <span className="btn-text">Save</span>
                  </StyledButton>
                  <StyledButton
                    type="button"
                    className="btn-danger"
                    onClick={() => {
                      this.props.history.push('/finance/account-payable-report-2/');
                    }}
                  >
                    <i className="icomoon-save" />
                    <span className="btn-text">Cancel</span>
                  </StyledButton>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ReportModal
          type="logs"
          typeTitle="Influencers"
          title="Saving AP"
          modal={modal}
          closeDialog={this.closeDialog}
          log={logs}
          loading={loading.fillPayables}
          preComponent={
            !loading.accountPayable ?
              (
                <ul className="text-left">
                  <li>Saving AccountPayable: {record.id ? 'Success' : 'Please check errors in form'}
                  </li>
                </ul>
              )
            : (<div />)
          }
          downloadCSV={this.downloadCSV}
        />
      </Container>
    );
  }
}

AccountPayableNew.propTypes = {
  // match: PropTypes.objectOf(PropTypes.any).isRequired,
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  accountPayable: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.objectOf(PropTypes.any).isRequired,
  logs: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

AccountPayableNew.defaultProps = {
  accountPayable: null,
};

const mapStateToProps = state => ({
  resources: state.resources,
  accountPayable: state.accountPayable.accountPayable,
  loading: state.accountPayable.loading,
  logs: state.accountPayable.logs.payables,
});


export default connect(mapStateToProps)(AccountPayableNew);
