import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import ApiClient from 'ApiClient';
import { Row, Col, Button, Container, Card, CardBody, CardTitle, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import Filter from '../FilterNew';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../App/store/with-notice';
import GenerateRable from './GenerateTable';
import Errors from '../ErrorsV2';
import GenerationInfo from '../GenerationInfoV2';
import PermissionComponent from '../../../../shared/components/PermissionComponent';
import { CheckPermission } from '../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../shared/components/CheckBox';
import LoadingReport from "../../../../shared/components/LoadingReport";

const SummaryReport = ({ addNotice }) => {
  const [loading, setLoading] = useState(false);
  const [loadingAdvertiser, setLoaingAdvertiser] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState(null);
  const [items, setItems] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [errors, setErrors] = useState([]);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [ignoreDealstages, setIgnoreDealstages] = useState(true);
  const [ignoreWarning, setIgnoreWarning] = useState(false);
  const [warningsReport, setWarningsReport] = useState([]);
  // const [error, setError] = useState(null);
  // const [version, setVersion] = useState((new Date()).toString());
  useEffect(() => {
    const api = new ApiClient();
    setLoaingAdvertiser(true);
    api.client.get('/api/portal/finance/get-advertisers').then((resp) => {
      setAdvertisers(resp.data.advertisers);
      setLoaingAdvertiser(false);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);

  const fetchAmountReport = async () => {
    setLoading(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-fetch-all-deals-by-date', {
        startDate,
        endDate,
        identity,
        ignoreDealstages,
      });
      if (resp.data.success) {
        setErrors(resp.data.notUsedConversions);
        setLoading(false);
        setItems(resp.data.items);
        setWarningsReport(resp.data.warningsReport || []);
        // setVersion((new Date()).toString());
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  const options = advertisers.map(item => ({ value: item.tuneId, label: item.companyName }));
  options.unshift({ value: null, label: ' -- All -- ' });
  if (!CheckPermission('reports----summary')) return (<PermissionComponent />);
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Gross Revenue & Gross Profit Summary V3', path: false },
            ]}
          />
        </Col>
      </Row>
      {!loading && errors.length > 0 ? (
        <Errors errors={errors} />
      ) : null }
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Gross Revenue & Gross Profit Summary</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col>
                  <Filter
                    setValue={(param, value) => {
                      console.log(value);
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('month').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('month').format('YYYY-MM-DD'));
                      }
                    }}
                  />
                </Col>
                <Col lg="auto">
                  <div>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchAmountReport();
                        return false;
                      }}
                      className="btn-sm mr-2"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                    <GenerationInfo />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="theme-light radio-in-prospects">
                    <FormGroup className="mb-0 color-text" inline>
                      <CheckBoxField
                        name="ignorewarning"
                        label="Ignore Deal Stages for all Upfront Deal types"
                        value={ignoreDealstages}
                        onChange={({ target }) => target && (setIgnoreDealstages(target.checked))}
                      />
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              {items && items.length > 0 ? (
                <Row>
                  <Col xs="12">
                    <div className="theme-light radio-in-prospects">
                      <FormGroup className="mb-0 color-text" inline>
                        <CheckBoxField
                          name="ignorewarning"
                          label="Ignore deal warnings and errors when calculating Gross Revenue"
                          value={ignoreWarning}
                          onChange={({ target }) => target && (setIgnoreWarning(target.checked))}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>) : null}
              {/* <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    topic="report"
                    identity={identity}
                    isShow={!!loading}
                  />
                </Col>
              </Row> */}
              <GenerateRable
                items={items}
                loading={loading}
                advertisers={advertisers}
                loadingAdvertiser={loadingAdvertiser}
                setSelectedAdvertisers={setSelectedAdvertisers}
                selectedAdvertisers={selectedAdvertisers}
                ignoreWarning={ignoreWarning}
                warningsReport={warningsReport}
                startDate={startDate}
                endDate={endDate}
              />
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(SummaryReport);
