import React, { useState } from 'react';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import { unflatten } from 'flat';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from 'mdi-react/RefreshIcon';
import StopProcessInList from './StopProcessComponent';
import StartProcessInList from './StartProcessComponent';

const ProgressProcess = ({ record, updateRecordFunction }) => {
  const [loading, setLoading] = useState(false);
  const unflattenRecord = unflatten(record);
  const value = unflattenRecord.params?.meta ?
    // eslint-disable-next-line max-len
    Math.round(((unflattenRecord.params.meta.totalDone + unflattenRecord.params.meta.totalErrors + unflattenRecord.params.meta.totalExcluded + unflattenRecord.params.meta.totalDuplicates + unflattenRecord.params.meta.totalInBlacklist) * 100) / (unflattenRecord.params.meta.total || 1))
    : 0;
  const loadProcess = async () => {
    setLoading(true);
    console.log(loading);
    try {
      const recordData = await axios.post('/api/portal/prospects/post-get-queue-item', { _id: record.id });
      if (updateRecordFunction) {
        setLoading(false);
        updateRecordFunction(record.id, {
          'meta.totalDone': recordData.data.queuePopulated.params.totalDone,
          'meta.totalErrors': recordData.data.queuePopulated.params.totalErrors,
          'meta.totalDuplicates': recordData.data.queuePopulated.params.totalDuplicates,
          'meta.totalExcluded': recordData.data.queuePopulated.params.totalExcluded,
          'meta.totalInBlacklist': recordData.data.queuePopulated.params.totalInBlacklist,
          'meta.totalNew': recordData.data.queuePopulated.params.totalNew,
          'meta.total': recordData.data.queuePopulated.params.total,
          endDate: recordData.data.queuePopulated.endDate,
          duration: recordData.data.queuePopulated.duration,
          status: recordData.data.queuePopulated.status,
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }

    // if (value !== 100 && unflattenRecord.params.status !== 'stopped') {
    //   setTimeout(() => loadProcess(), 10000);
    // }
  };
  // useEffect(() => {
  //  if (value !== 100 && unflattenRecord.params.status !== 'stopped' && !loading) {
  //    setTimeout(() => loadProcess(), 10000);
  //  }
  // , [unflattenRecord.params.status, value]);
  let color = 'primary';
  if (unflattenRecord.params.status === 'paused') color = 'warning';
  if (!unflattenRecord.params?.meta) return '';
  return (
    <div className="d-flex" style={{ alignItems: 'center' }}>
      <div style={{ width: '50px' }}>
        { /* eslint-disable-next-line no-nested-ternary */ }
        {(value !== 100 && unflattenRecord.params.status !== 'stopped') ? (
          <StopProcessInList
            updateRecordFunction={updateRecordFunction}
            record={record}
          />
        ) : (unflattenRecord.params.status === 'stopped' ? (<StartProcessInList
          updateRecordFunction={updateRecordFunction}
          record={record}
        />) : null)}
      </div>
      <div style={{ width: '150px' }}>
        {unflattenRecord.params.status !== 'stopped' ?
          (<Progress color={value === 100 ? 'success' : color} value={value}>{`${value}%`}</Progress>) :
          (<Progress color="danger" value={100}>Canceled</Progress>)
        }
      </div>
      <div>
        {(value !== 100 && unflattenRecord.params.status !== 'stopped') ? (
          <Tooltip title="Refresh Upload Statistics">
            <IconButton
              id="filtersMenuButton"
              size="small"
              onClick={() => loadProcess()}
              disabled={loading}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>) : null}
      </div>
    </div>
  );
};

ProgressProcess.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};

export default ProgressProcess;
