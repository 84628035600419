/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const employeeCommissionsReducer = createSlice({
  name: 'employeeCommission',
  initialState: {
    loading: {
      employeeCommission: false,
    },
    reports: [],
    commissions: {},
    payableReport: null,
  },
  reducers: {
    getEmployeeCommissionReportDataStart(state) {
      state.loading = {
        ...state.loading,
        employeeCommission: true,
      };
    },
    getEmployeeCommissionReportDataSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        employeeCommission: false,
      };
      state.error = null;
      state.reports = data.employeeCommissionData.reports;
      state.commissions = data.employeeCommissionData.commissions;
      state.payableReport = data.payableReport;
    },
    getEmployeeCommissionReportDataError(state) {
      state.loading = {
        ...state.loading,
        employeeCommission: false,
      };
    },
    getEmployeeCommissionReportDataClear(state) {
      state.loading = {
        ...state.loading,
        employeeCommission: false,
      };
      state.error = null;
      state.reports = [];
      state.commissions = {};
      state.payableReport = null;
    },
    updateEmployeeCommissionReportRecord(state, action) {
      const { id, record } = action.payload;
      const newRecords = [];
      state.reports.forEach((employeeCommissionReport) => {
        if (employeeCommissionReport._id === id) {
          const newItem = record.params;
          // eslint-disable-next-line no-unused-vars,guard-for-in,no-restricted-syntax
          for (const property in record.populated) {
            newItem[property] = record.populated[property].params;
          }
          newRecords.push(newItem);
        } else {
          newRecords.push(employeeCommissionReport);
        }
      });
      state.reports = newRecords;
    },
    updateEmployeeCommissionRecord(state, action) {
      const { id, record } = action.payload;
      const newRecords = [];
      Object.values(state.commissions).forEach((employeeCommissionArr) => {
        employeeCommissionArr.forEach((commission) => {
          if (commission._id === id) {
            const newItem = record.params;
            // eslint-disable-next-line no-unused-vars,guard-for-in,no-restricted-syntax
            for (const property in record.populated) {
              newItem[property] = record.populated[property].params;
            }
            newItem.profit = (parseFloat(newItem.revenueAmount.$numberDecimal) - parseFloat(newItem.payoutAmount.$numberDecimal)) + parseFloat(newItem.adjustmentAmount.$numberDecimal);
            newItem.employeeCommissionReport = commission.employeeCommissionReport;
            newRecords.push(newItem);
          } else {
            newRecords.push(commission);
          }
        });
      });
      const newCommissions = {};
      newRecords.forEach((commission) => {
        if (typeof newCommissions[commission.advertiser._id] === 'undefined') {
          newCommissions[commission.advertiser._id] = [];
        }
        newCommissions[commission.advertiser._id].push(commission);
      });
      state.commissions = newCommissions;
    },
    calculateData(state) {
      const newRecords = [];
      state.reports.forEach((employeeCommissionReport) => {
        employeeCommissionReport.totalProfit = 0.0;
        Object.values(state.commissions).forEach((commissionArr) => {
          commissionArr.forEach((commission) => {
            if (commission.employeeCommissionReport._id === employeeCommissionReport._id) {
              employeeCommissionReport.totalProfit += parseFloat(commission.profit);
            }
          });
        });
        employeeCommissionReport.commissionAmount = (employeeCommissionReport.totalProfit / 100) * employeeCommissionReport.commissionRate.$numberDecimal;
        newRecords.push(employeeCommissionReport);
      });
      state.reports = newRecords;
    },
  },
});

export const {
  // get employee commission record
  getEmployeeCommissionReportDataStart,
  getEmployeeCommissionReportDataSuccess,
  getEmployeeCommissionReportDataError,
  getEmployeeCommissionReportDataClear,
  updateEmployeeCommissionReportRecord,
  updateEmployeeCommissionRecord,
  calculateData,
} = employeeCommissionsReducer.actions;

const getEmployeeCommissionReportApi = async (payableReportId) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/finance/get-employee-commission-reports?payableReportId=${payableReportId}`);
};

export const reCalculateData = () => async (dispatch) => {
  dispatch(calculateData());
};

export const getEmployeeCommissionReportData = payableReportId => async (dispatch) => {
  try {
    if (!payableReportId) {
      dispatch(getEmployeeCommissionReportDataClear());
    } else {
      dispatch(getEmployeeCommissionReportDataStart());
      const response = await getEmployeeCommissionReportApi(payableReportId);
      if (response.data && response.data.employeeCommissionData) {
        dispatch(getEmployeeCommissionReportDataSuccess({ data: response.data }));
        dispatch(calculateData());
      } else {
        throw new Error('There was an error fetching records, Check out console to see more information.');
      }
    }
  } catch (err) {
    dispatch(getEmployeeCommissionReportDataError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const updateEmployeeCommissionsReport = payableReportId => async (dispatch) => {
  dispatch(getEmployeeCommissionReportDataStart());
  const api = new ApiClient();
  const response = await api.client.post(`/api/portal/finance/clear-employee-commission-reports?payableReportId=${payableReportId}`);
  if (response.data.success) {
    dispatch(getEmployeeCommissionReportData(payableReportId));
  }
};


export default employeeCommissionsReducer.reducer;
