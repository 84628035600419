import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../App/store/with-notice';
import filterProperties from './custom/ActionProperties';
import BillComPayables from './custom/Modal/BillComPayables';
import MarkPaidModal from './custom/Modal/MarkPaidModal';
import Hook from '../../../../../shared/hooks';
import { getConstant } from '../../../../../shared/helpers/WVConstants';


const ContainerD = ({
  history,
}) => {
  const dealstages = (getConstant('deal', 'pipelines').find(p => p.label === 'Main Pipeline')).stages;
  const [searchUrl, setPrefill, loadPage, , useLoadOrDebounce] = Hook.usePrefillHook({
    loadTableUrl: '/api/portal/finance/get-unpaid-payable-audit-report',
    initialState: {
      sortBy: 'createdAt',
      direction: 'asc',
      page: 1,
      perPage: 10,
    },
  });

  useEffect(() => {
    console.log('Allow Start Load');
    setPrefill(filterProperties({
      dealstages: Object.keys(dealstages).map(d => ({ value: d, label: dealstages[d] })),
    }));
  }, []);
  const setUrl = () => {
    loadPage();
  };
  const downloadCsv = () => {
    window.open(`/api/portal/finance/get-unpaid-payable-audit-report-csv?${searchUrl}`, '_blank').focus();
  };
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 2000);

  useLoadOrDebounce({
    loadFunc: () => loadPage(), loadDebounceFunc: () => setUrlDebounced(), setSearchUrl: () => history.push(`${history.location.pathname}?${searchUrl}`)
  })
  console.log(searchUrl, 'SEARCH');
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Finance', path: null },
          { title: 'Unpaid Payables', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Unpaid Payables</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <div className="tabs mb-3">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className=""
                  onClick={() => {
                    history.push(`/reports/bill-audits`);
                    // this.setState({ activeTab: '1' });
                    // getTable();
                  }}
                >
                  Bills
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="active"
                  onClick={() => {
                    history.push(`/reports/payable-audits`);
                    // this.setState({ activeTab: '2' });
                  }}
                >
                  CPA
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <Row>
            <Col>
              {searchUrl !== null ? <Actions
                downloadCsv={downloadCsv}
              /> : null}
              <Row>
                {searchUrl !== null ? <Table /> : null}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <BillComPayables />
      <MarkPaidModal />
    </Container>
  );
};
ContainerD.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withNotice(withRouter(ContainerD));
