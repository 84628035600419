import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';

const AiBrandRelevancyFilter = ({
  aiBrandRelevantFilterCheck,
  handleChangeAiBrandRelevantCheckFilter,
  aiBrandRelevantScore,
  setAiBrandRelevantScore,
  aiBrandIgnoreScore,
  setAiBrandIgnoreScore,
}) => (
  <FormGroup>
    <Label className="bold-text">
      AI Brand Relevancy&nbsp;
    </Label>
    <Row>
      <Col md={2}>
        <CheckBoxField
          name="AiBrandRelevant"
          label="Relevant"
          value={aiBrandRelevantFilterCheck.relevant}
          onChange={event => handleChangeAiBrandRelevantCheckFilter(event, 'relevant')}
        />
      </Col>
      <Col md={7}>
        <Slider
          min={0}
          max={1}
          value={aiBrandRelevantScore}
          onChange={(event, value) => setAiBrandRelevantScore(value)}
          marks={[
            { value: 0, label: '0' },
            { value: 0.25, label: '.25' },
            { value: 0.5, label: '0.5' },
            { value: 0.75, label: '.75' },
            { value: 1, label: '1' },
          ]}
          step={0.01}
          track="normal"
          valueLabelDisplay="auto"
          disabled={!aiBrandRelevantFilterCheck.relevant}
        />
      </Col>
    </Row>
    <Row>
      <Col md={2}>
        <CheckBoxField
          name="AiBrandRelevantIgnore"
          label="Ignore"
          value={aiBrandRelevantFilterCheck.ignore}
          onChange={event => handleChangeAiBrandRelevantCheckFilter(event, 'ignore')}
        />
      </Col>
      <Col md={7}>
        <Slider
          min={0}
          max={1}
          value={aiBrandIgnoreScore}
          onChange={(event, value) => setAiBrandIgnoreScore(value)}
          marks={[
            { value: 0, label: '0' },
            { value: 0.25, label: '.25' },
            { value: 0.5, label: '0.5' },
            { value: 0.75, label: '.75' },
            { value: 1, label: '1' },
          ]}
          step={0.01}
          track="normal"
          valueLabelDisplay="auto"
          disabled={!aiBrandRelevantFilterCheck.ignore}
        />
      </Col>
    </Row>
  </FormGroup>
);

AiBrandRelevancyFilter.propTypes = {
  aiBrandRelevantFilterCheck: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeAiBrandRelevantCheckFilter: PropTypes.func.isRequired,
  aiBrandRelevantScore: PropTypes.arrayOf(PropTypes.any).isRequired,
  setAiBrandRelevantScore: PropTypes.func.isRequired,
  aiBrandIgnoreScore: PropTypes.arrayOf(PropTypes.any).isRequired,
  setAiBrandIgnoreScore: PropTypes.func.isRequired,
};

export default AiBrandRelevancyFilter;
