import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Card, CardBody, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import useConversions from './components/useConversions';
import TopButtonsPeriod from '../../../Reports/TimePeriodFilterExtended';
import Item from '../../../Reports/StatItem';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import formater from '../../../../../shared/helpers/WVFormatter';
import { isDealRight } from '../../../Reports/ReportCalculation';
import SelectFilter from '../../../../../shared/components/SelectFilter';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  useFlex: true,
  withPagination: true,
  manualPageSize: [5, 10, 20, 30, 100],
  withSearchEngine: true,
  sortBy: 'datetime',
  direction: 'desc',
  placeholder: 'Search Offer or Deal Name',
  searchFieldMinWidth: '50%',
};

const Conversions = ({ influencer, deals }) => {
  const [shouldFetch, setShouldFetch] = useState(true);
  const [startDate, setStartDate] = useState(moment().startOf('month').unix());
  const [endDate, setEndDate] = useState(moment().endOf('day').unix());
  const [allConversions, setAllConversions] = useState([]);
  const [conversionsNumber, setConversionsNumber] = useState(0);
  const [conversionTypeFilter, setConversionTypeFilter] = useState({
    'Non-CPM Conversions': true,
    'CPM Conversions': true,
  });
  const [conversionsData, setConversionsData] = useState({
    revenue: 0,
    payout: 0,
    clicks: 0,
  });
  const { conversions, clicks, cpmConversions } = useConversions(influencer?.id, shouldFetch, setShouldFetch, moment.unix(startDate).format('YYYY-MM-DD'), moment.unix(endDate).format('YYYY-MM-DD'));

  const findRightDeal = ({
    dealsAll,
    offerId,
    influencerId,
  }) => {
    let dealright = null;
    // console.log(dealsAll);
    const dealsActive = dealsAll.filter(d =>
      d.params.ilDealData?.offer?.tuneId?.toString() === offerId.toString()
      && d.params.ilDealData?.influencer?.toString() === influencerId.toString()
      && d.params.dealTerms.cpa_status === 'Active');
    if (dealsActive.length === 1) {
      ([dealright] = dealsActive);
    } else if (dealsActive.length > 1) {
      dealsActive.sort((a, b) => Math.abs(Date.now() - new Date(a.brandApprovedDate)) - Math.abs(Date.now() - new Date(b.brandApprovedDate)));
      ([dealright] = dealsActive);
      // console.log(dealsActive);
    }
    let deal = null;
    if (dealright) {
      deal = dealright;
    } else {
      const dealExpired = dealsAll.find(d => d.params?.ilDealData?.offer?.tuneId?.toString() === offerId.toString()
        && d.params?.ilDealData?.influencer?.toString() === influencerId.toString() && d.params?.dealTerms?.cpa_status !== 'Expired');
      deal = dealExpired;
    }
    if (!deal) {
      const dealExpired = dealsAll.find(d => d.params?.ilDealData?.offer?.tuneId?.toString() === offerId.toString()
        && d.params?.ilDealData?.influencer?.toString() === influencerId?.toString());
      deal = dealExpired;
    }
    if (!deal) {
      return {};
    }
    return deal;
  };
  const columnTransform = useMemo(() => [
    {
      id: 'datetime',
      Header: 'Date',
      Cell: value => (value.row.original.Stat?.datetime ? moment.utc(value.row.original.Stat?.datetime).local().format('MM/DD/YY HH:mm:ss') : ''),
      accessor: record => record.Stat?.datetime,
      width: 150,
    },
    {
      id: 'offerName',
      Header: 'Offer Name',
      Cell: value => value.row.original.Offer?.name,
      accessor: record => record.Offer?.name?.toLowerCase(),
      width: 300,
    },
    {
      id: 'goalName',
      Header: 'Goal Name',
      Cell: value => value.row.original.Goal?.name || '-',
      accessor: record => record.Goal?.name?.toLowerCase() || '-',
      width: 150,
    },
    {
      id: 'revenue',
      Header: 'Revenue',
      Cell: value => formater.formatCurrency(value.row.original.Stat?.revenue),
      accessor: record => record.Stat?.revenue,
      width: 100,
    },
    {
      id: 'payout',
      Header: 'Payout',
      Cell: value => formater.formatCurrency(value.row.original.Stat?.payout),
      accessor: record => record.Stat?.payout,
      width: 100,
    },
    {
      id: 'dealName',
      Header: 'Deal Name',
      Cell: value => value.value,
      // deals.find(it => Number(it.params.dealTerms.offer) === Number(value.row.original.Stat.offer_id) && it.params.dealTerms.cpa_status === 'Active')?.params?.dealname || '',
      accessor: (record) => {
        if (record.Stat.offer_id) {
          const dealsFiltered = deals.filter(d => isDealRight(d.params, moment.utc(startDate), moment.utc(endDate)));
          const deal = findRightDeal({ dealsAll: dealsFiltered, offerId: record.Stat.offer_id, influencerId: influencer?.id });
          return deal.params?.dealname || '-';
        }
        return '-';
      },
      width: 150,
    },
  ], [deals, startDate, endDate]);

  let payout = 0;
  let revenue = 0;
  conversions.forEach((conversion) => {
    if (conversion && conversion.Stat) {
      payout += parseFloat(conversion.Stat.payout);
      revenue += parseFloat(conversion.Stat.revenue);
    }
  });

  useEffect(() => {
    let all = [];
    let conversionsCount = 0;
    if (conversions.length > 0 || cpmConversions.length > 0) {
      all = [].concat(...conversions, ...cpmConversions);
      conversionsCount = conversions.length + cpmConversions.length;
    }
    setAllConversions(all);
    setConversionsNumber(conversionsCount);
    setConversionsData({
      revenue,
      payout,
      clicks,
    });
  }, [shouldFetch]);

  useEffect(() => {
    let finalConversions = [];
    let finalConversionsNumber = 0;
    if (conversionTypeFilter['Non-CPM Conversions'] && conversions.length > 0) {
      finalConversions = finalConversions.concat(...conversions);
      finalConversionsNumber += conversions.length;
      setConversionsData({
        revenue,
        payout,
        clicks,
      });
    } else {
      setConversionsData({
        revenue: 0,
        payout: 0,
        clicks: 0,
      });
    }
    if (conversionTypeFilter['CPM Conversions'] && cpmConversions.length > 0) {
      finalConversions = finalConversions.concat(...cpmConversions);
      finalConversionsNumber += cpmConversions.length;
    }
    setAllConversions(finalConversions);
    setConversionsNumber(finalConversionsNumber);
  }, [conversionTypeFilter]);
  // console.log('conversions', conversions);
  // console.log('clicks', clicks);
  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Conversions</h2>
      <Card id="conversionsDatesBlock section-conversions">
        <CardBody>
          <TopButtonsPeriod
            dateFrom={startDate}
            dateTo={endDate}
            isLoading={!(influencer && !shouldFetch)}
            setValue={(param, value) => {
              console.log(value);
              if (param === 'startDate') {
                setStartDate(moment(value).startOf('day').unix());
              }
              if (param === 'endDate') {
                setEndDate(moment(value).endOf('day').unix());
              }
              setShouldFetch(true);
            }}
            usedForConversions
          />
          <Row className="mt-2">
            <Col>
              <SelectFilter
                key="123"
                title="Select Conversions Type"
                filterProp={conversionTypeFilter}
                filterAction={v => setConversionTypeFilter(v)}
                styles={{ maxWidth: '400px' }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="3">
              <Item
                items={[{
                  label: 'Revenue Amount',
                  value: influencer && !shouldFetch ? formater.formatCurrency((conversionsData.revenue)) : (<div className="text-center"><Spinner color="primary" size="lg" /></div>),
                }]}
              />
            </Col>
            <Col md="3">
              <Item
                items={[{
                  label: 'Payout Amount',
                  value: influencer && !shouldFetch ? formater.formatCurrency((conversionsData.payout)) : (<div className="text-center"><Spinner color="primary" size="lg" /></div>),
                }]}
              />
            </Col>
            <Col md="3">
              <Item
                items={[{
                  label: 'Clicks',
                  value: influencer && !shouldFetch ? formater.formatIntNumber(conversionsData.clicks) : (<div className="text-center"><Spinner color="primary" size="lg" /></div>),
                }]}
              />
            </Col>
            <Col md="3">
              <Item
                items={[{
                  label: 'Revenue Conversions',
                  value: influencer && !shouldFetch ? formater.formatIntNumber(conversionsNumber) : (<div className="text-center"><Spinner color="primary" size="lg" /></div>),
                }]}
              />
            </Col>
          </Row>
          {influencer && !shouldFetch ? (
            <Row className="mt-3">
              <Col>
                {allConversions.length > 0 ?
                  <ReactTableBase
                    columns={columnTransform}
                    data={allConversions}
                    tableConfig={tableConfig}
                  />
                :
                  <Alert color="warning" bordered icon>
                    <p className="py-2">
                      The Creator currently has no Conversions.
                    </p>
                  </Alert>
                }
              </Col>
            </Row>
          ) : <div className="text-center mt-3"><Spinner color="primary" size="lg" /></div>}

        </CardBody>
      </Card>
    </div>
  );
};

Conversions.propTypes = {
  influencer: PropTypes.objectOf(PropTypes.any),
  deals: PropTypes.arrayOf(PropTypes.any),
};
Conversions.defaultProps = {
  influencer: null,
  deals: [],
};

export default Conversions;
