import React from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { Modal } from 'reactstrap';

export default class Execute extends React.Component {
  static propTypes = {
    identifier: PropTypes.string,
    run: PropTypes.bool,
    setExecuted: PropTypes.func.isRequired,
  };

  static defaultProps = {
    identifier: null,
    run: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      status: '',
      url: '',
      message: '',
    };

    this.close = this.close.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { identifier, run } = this.props;
    if (identifier && run && run !== prevProps.run) {
      this.executeJob();
    }
  }

  close() {
    this.setState({ isOpen: false });
  }

  executeJob = () => {
    const { identifier, setExecuted } = this.props;
    console.log(`Job ${identifier} started`);
    axios.post(
      '/api/portal/jobs/execute',
      {
        identifier,
      },
    ).catch((error) => {
      console.log(error);
      setExecuted(identifier);
    }).then((response) => {
      if (response.data.success === true) {
        setExecuted(identifier);
        this.setState({
          isOpen: true,
          status: 'Job execution succeed.',
          url: `/resources/Log/actions/list?filters.jobIdentifier=${identifier}&filters.level=info`,
          message: 'You can find its results in the corresponding Slack channel or check the latest ',
        });
      } else if (response.data.jobIdentifier) {
        setExecuted(identifier);
        this.setState({
          isOpen: true,
          status: 'Job execution failed.',
          url: `/resources/Log/actions/list?filters.jobIdentifier=${identifier}&filters.level=error`,
          message: 'Check the latest ',
        });
      } else if (response.data.error) {
        setExecuted(identifier);
        this.setState({
          isOpen: true,
          status: 'Job execution failed.',
          url: '/resources/Log/actions/list?filters.level=error',
          message: 'Check the latest ',
        });
      }
    });
  };

  render() {
    const {
      isOpen,
      status,
      url,
      message,
    } = this.state;
    return (
      <div style={{ display: 'inline' }}>
        <Modal
          isOpen={isOpen}
          toggle={this.close}
          modalClassName="ltr-support"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.close} />
          </div>
          <div className="modal__body">
            <p>
              {status}
              <br />
              {message}<a href={url} target="_blank" rel="noopener noreferrer">Logs</a>
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}

