import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import withNotice from '../../../../App/store/with-notice';
import Hook from '../../../../../shared/hooks';

export const modalId = 'billsTotals';

const AmountDueTotalsModal = ({
  fetchTotalsData,
  totals,
  loadingTotals,
}) => {
  const { showModal: isShow, close } = Hook.useModalHook(modalId);
  useEffect(() => {
    if (isShow) {
      fetchTotalsData();
    }
  }, [isShow]);

  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '800px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Amount Due Totals
      </ModalHeader>
      <ModalBody>
        <Row >
          <Col xs="3" className="text-center">
            <span style={{ textAlign: 'center', color: 'black' }} >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '12px',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {loadingTotals ?
                  <Spinner color="primary" size="sm" />
                  : totals.amountDue
                }
              </span>
              <br style={{ lineHeight: '50%' }} />
              <span style={{ width: '100%', fontSize: '16px' }}>Total</span>
            </span>
          </Col>
          <Col xs="3" className="text-center">
            <span style={{ textAlign: 'center', color: 'black' }} >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '12px',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {loadingTotals ?
                  <Spinner color="primary" size="sm" />
                  : totals.stripe
                }
              </span>
              <br style={{ lineHeight: '50%' }} />
              <span style={{ width: '100%', fontSize: '16px' }}>Stripe</span>
            </span>
          </Col>
          <Col xs="3" className="text-center">
            <span style={{ textAlign: 'center', color: 'black' }} >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '12px',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {loadingTotals ?
                  <Spinner color="primary" size="sm" />
                  : totals.billcom
                }
              </span>
              <br style={{ lineHeight: '50%' }} />
              <span style={{ width: '100%', fontSize: '16px' }}>Bill.com</span>
            </span>
          </Col>
          <Col xs="3" className="text-center">
            <span style={{ textAlign: 'center', color: 'black' }} >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '12px',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {loadingTotals ?
                  <Spinner color="primary" size="sm" />
                  : totals.unverified
                }
              </span>
              <br style={{ lineHeight: '50%' }} />
              <span style={{ width: '100%', fontSize: '16px' }}>Unverified Method</span>
            </span>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <p>Totals based on the current filter(s) applied on the Bills dashboard</p>
      </ModalFooter>
    </Modal>
  );
};

AmountDueTotalsModal.propTypes = {
  fetchTotalsData: PropTypes.func.isRequired,
  totals: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingTotals: PropTypes.bool.isRequired,
};

export default withNotice(AmountDueTotalsModal);
