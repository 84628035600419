import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Label,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import ApiClient from 'ApiClient';
import withNotice from '../../../App/store/with-notice';
import { getCookie, setCookie, getShuffledColorsForCharts } from '../../../../shared/helpers/WVUtilities';
import formatter from '../../../../shared/helpers/WVFormatter';
// import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import { fetchDictionary } from '../../../../shared/helpers/WVRequest';
import Filter from '../../../../shared/components/filter/TimePeriodFilterForwarded';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import CheckBoxField from '../../../../shared/components/CheckBox';
import GenerateTable from './GenerateTable';
import BarChartComponent from './BarChartComponent';
import LoadingReport from "../../../../shared/components/LoadingReport";

const chartDataTypes = [
  'contentReviewed',
  'urlValidation',
  'creatorSafetyReviews',
  // 'commentsReviewed',
  'contentStatusAverageTimes',
];

const statusOrder = {
  contentReviewed: {
    'Needs Review': 1,
    'Pending Edits': 2,
    Approved: 3,
    Terminated: 4,
  },
  urlValidation: {
    'Needs Review': 1,
    'Pending Publication': 2,
    'Needs Content URL': 3,
    Approved: 4,
    Inactive: 5,
  },
  creatorSafetyReviews: {
    'Needs Review': 1,
    'Pending Edits': 2,
    Approved: 3,
    Terminated: 4,
  },
  // commentsReviewed: {
  //   'Needs Review': 1,
  //   'Passed Analysis': 2,
  //   Positive: 3,
  //   Neutral: 4,
  //   Negative: 5,
  // },
};

const sortKeysByStatusOrder = (arr, dataType) => {
  arr.sort((a, b) => {
    const nameA = a.split(': ')[0];
    const nameB = b.split(': ')[0];
    const statusA = a.split(': ')[1];
    const statusB = b.split(': ')[1];
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return statusOrder[dataType][statusA] - statusOrder[dataType][statusB];
  });

  return arr;
};

const initialHistoricalContentReviewStatusFilters = {
  'Needs Review': true,
  'Pending Edits': true,
  Approved: true,
  Terminated: true,
};

const initialURLStatusFilters = {
  'Needs Review': true,
  'Pending Publication': true,
  'Needs Content URL': true,
  Approved: true,
  Inactive: true,
};

const initialCreatorSafetyReviewStatusFilters = {
  'Needs Review': true,
  'Pending Edits': true,
  Approved: true,
  Terminated: true,
};

// const initialCommentSentimentStatusFilters = {
//   'Needs Review': true,
//   'Passed Analysis': true,
//   Positive: true,
//   Neutral: true,
//   Negative: true,
// };

const dataOptions = [
  { value: 'byDay', label: 'By Day' },
  { value: 'week', label: 'By Week' },
  { value: 'month', label: 'By Month' },
  { value: 'total', label: 'Over Time' },
];

const Item = ({ label, value }) => (
  <div
    style={{
      padding: '15px 15px',
      border: '1px solid #cccccc',
      borderRadius: '5px',
      height: '100%',
    }}
  >
    <div style={{ textAlign: 'center', color: 'black' }} >
      <span
        style={{
          fontSize: '20px',
          fontWeight: '700',
          marginBottom: '12px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {value}
      </span>
      <span style={{ width: '100%', fontSize: '16px' }}>{label}</span>
    </div>
  </div>
);

Item.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
};

const Report = ({ addNotice }) => {
  const api = new ApiClient();
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingTotals, setLoadingTotals] = useState(false);
  const [hasLoadedReport, setHasLoadedReport] = useState(false);
  const [loadingBrandOptions, setLoadingBrandOptions] = useState(false);
  const [loadingEmployeeOptions, setLoadingEmployeeOptions] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [brandOptions, setBrandOptions] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [colorOptions, setColorOptions] = useState({
    pendingCreatorSafetyReviews: '-',
    inPendingEditsFor48Hrs: '-',
    needsContentUrl24Hrs: '-',
    urlsPendingPublication24Hrs: '-',
    contentReviewed: [],
    urlValidation: [],
    creatorSafetyReviews: [],
    // commentsReviewed: [],
  });
  const [reportData, setReportData] = useState(chartDataTypes.reduce((accumulator, value) => ({ ...accumulator, [value]: { data: [], keys: [], names: [] } }), {}));
  const [totalsData, setTotalsData] = useState({
    creatorSafetyReviews: '-',
    inPendingEditsFor48Hrs: '-',
    needsContentUrl24Hrs: '-',
    urlsPendingPublication24Hrs: '-',
  });
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [defaultEmployees, setDefaultEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [filtersUrlStatus, setFiltersUrlStatus] = useState(initialURLStatusFilters);
  const [filtersHistoricalContentReviewStatus, setFiltersHistoricalContentReviewStatus] = useState(initialHistoricalContentReviewStatusFilters);
  const [filtersCreatorSafetyReviewStatus, setFiltersCreatorSafetyReviewStatus] = useState(initialCreatorSafetyReviewStatusFilters);
  // const [filtersCommentSentimentStatus, setFiltersCommentSentimentStatus] = useState(initialCommentSentimentStatusFilters);
  const [selectedOption, setSelectedOption] = useState(dataOptions[0]);
  const [showLabels, setShowLabels] = useState(false);
  const [showFilters, setShowFilters] = useState(getCookie('complianceMetricsShowFilters') === 'true');

  useEffect(() => {
    setCookie('complianceMetricsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const fetchDictionaryData = async () => {
    setLoadingEmployeeOptions(true);
    await fetchDictionary('COMPLIANCE_METRICS_DASHBOARD').then((data) => {
      const { success, records } = data;
      console.log('records: ', records);
      if (success === true) {
        const dictionaryData = records.COMPLIANCE_METRICS_DASHBOARD ? JSON.parse(records.COMPLIANCE_METRICS_DASHBOARD) : {};
        console.log('dictionaryData: ', dictionaryData);
        if (dictionaryData?.defaultEmployees && Array.isArray(dictionaryData.defaultEmployees)) {
          const localSelectedEmployees = [];
          dictionaryData.defaultEmployees.forEach((email) => {
            const found = employeeOptions.find(employeeOption => employeeOption.email === email);
            if (found) localSelectedEmployees.push(found);
          });
          if (localSelectedEmployees.length > 0) {
            setDefaultEmployees(localSelectedEmployees);
            if (selectedEmployees.length > 0) {
              setSelectedEmployees([...selectedEmployees, ...localSelectedEmployees]);
            } else setSelectedEmployees(localSelectedEmployees);
          }
        }
      }
      setLoadingEmployeeOptions(false);
    }).catch((err) => {
      setLoadingEmployeeOptions(false);
      console.log(err);
    });
  };

  useEffect(() => {
    fetchDictionaryData();
  }, [employeeOptions]);

  const fetchTotals = async () => {
    setLoadingTotals(true);
    api.client.get('/api/portal/compliance/get-metrics-totals').then((resp) => {
      setTotalsData(resp.data.totals);
      setLoadingTotals(false);
    }).catch(() => {
      setLoadingTotals(false);
      addNotice({
        message: 'There was an error trying to load totals data. Check out console to see more information.',
        type: 'error',
      });
    });
  };

  useEffect(() => {
    fetchTotals();

    setLoadingBrandOptions(true);
    api.client.get('/api/portal/compliance/get-brand-options').then((resp) => {
      setBrandOptions(resp.data.brandOptions);
      setLoadingBrandOptions(false);
    }).catch(() => {
      setLoadingBrandOptions(false);
      addNotice({
        message: 'There was an error trying to load brand options. Check out console to see more information.',
        type: 'error',
      });
    });

    setLoadingEmployeeOptions(true);
    api.client.get('/api/portal/compliance/get-employee-options').then((resp) => {
      setEmployeeOptions(resp.data.employeeOptions);
      setLoadingEmployeeOptions(false);
    }).catch(() => {
      setLoadingEmployeeOptions(false);
      addNotice({
        message: 'There was an error trying to load employee options. Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);

  const fetchReport = async () => {
    setLoadingReport(true);
    setHasLoadedReport(false);
    setReportData(chartDataTypes.reduce((accumulator, value) => ({ ...accumulator, [value]: { data: [], keys: [] } }), {}));
    try {
      const resp = await axios.post('/api/portal/compliance/post-metrics-report', {
        startDate,
        endDate,
        brands: selectedBrands?.map(row => row.value),
        employees: selectedEmployees?.map(row => row.value),
        urlStatus: Object.keys(filtersUrlStatus).filter(key => filtersUrlStatus[key] === true),
        historicalContentReviewStatus: Object.keys(filtersHistoricalContentReviewStatus).filter(key => filtersHistoricalContentReviewStatus[key] === true),
        creatorSafetyReviewStatus: Object.keys(filtersCreatorSafetyReviewStatus).filter(key => filtersCreatorSafetyReviewStatus[key] === true),
        // commentSentimentStatus: Object.keys(filtersCommentSentimentStatus).filter(key => filtersCommentSentimentStatus[key] === true),
        identity,
      });
      setLoadingReport(false);
      if (resp.data.success) {
        const { complianceMetricsData } = resp.data;
        setReportData(complianceMetricsData);
        setHasLoadedReport(true);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const handleChangeUrlStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersUrlStatus({
        ...filtersUrlStatus,
        [name]: checked,
      });
    }
  };

  const handleChangeHistoricalContentReviewStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersHistoricalContentReviewStatus({
        ...filtersHistoricalContentReviewStatus,
        [name]: checked,
      });
    }
  };

  const handleChangeCreatorSafetyReviewStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersCreatorSafetyReviewStatus({
        ...filtersCreatorSafetyReviewStatus,
        [name]: checked,
      });
    }
  };

  // const handleChangeCommentSentimentStatusFilters = (event, name) => {
  //   if (event && event.target) {
  //     const { checked } = event.target;
  //     setFiltersCommentSentimentStatus({
  //       ...filtersCommentSentimentStatus,
  //       [name]: checked,
  //     });
  //   }
  // };

  const timeFilterRef = useRef(null);

  const resetFilters = () => {
    timeFilterRef?.current?.selectDateRange('current_month');
    setSelectedOption(dataOptions[0]);
    setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
    setEndDate((moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).format('YYYY-MM-DD'));
    setSelectedBrands([]);
    setSelectedEmployees(defaultEmployees);
    setFiltersUrlStatus(initialURLStatusFilters);
    setFiltersHistoricalContentReviewStatus(initialHistoricalContentReviewStatusFilters);
    setFiltersCreatorSafetyReviewStatus(initialCreatorSafetyReviewStatusFilters);
    // setFiltersCommentSentimentStatus(initialCommentSentimentStatusFilters);
  };

  function sortObjectPropertiesByKeys(obj, type) {
    const sortedKeys = sortKeysByStatusOrder(Object.keys(obj), type);
    const sortedObject = {};
    sortedKeys.forEach((key) => {
      sortedObject[key] = obj[key];
    });
    return sortedObject;
  }

  const formattedData = useMemo(() => {
    const formattedDataObject = Object.keys(reportData).reduce((a, v) => ({ ...a, [v]: null }), {});
    const total = selectedOption.value;
    Object.keys(reportData).forEach((type) => {
      if (['contentReviewed', 'urlValidation', 'creatorSafetyReviews'].includes(type)) {
        const { data, keys } = reportData[type];

        const sumAll = ({
          dataEntries, label,
        }, item) => {
          let totalCount = item?.totalCount || 0;
          const entries = item?.entries || keys.reduce((a, v) => ({ ...a, [v]: 0 }), {});
          dataEntries?.forEach((entry) => {
            entries[entry.key] += entry.value;
            totalCount += entry.value;
          });
          return {
            label: label || item?.label,
            entries: sortObjectPropertiesByKeys(entries, type),
            totalCount,
          };
        };

        const startMoment = moment(startDate).startOf('day');
        const endMoment = moment() > moment(endDate).endOf('day') ? moment(endDate).endOf('day') : moment().endOf('day');
        const chartData = {};
        const chartDataMonth = {};
        const chartDataWeek = {};
        const totalData = {};
        const days = endMoment.diff(startMoment, 'day');
        const months = endMoment.diff(startMoment, 'month');
        const weeks = endMoment.diff(startMoment, 'weeks') + 1;

        for (let i = 0; i <= days; i += 1) {
          const momentDate = startMoment.clone().add(i, 'day');
          const key = momentDate.format('YYYY-MM-DD');
          chartData[key] = {
            label: momentDate.format('M/D/YY'), totalCount: 0, entries: keys.reduce((a, v) => ({ ...a, [v]: 0 }), {}),
          };
        }
        for (let i = 0; i <= months; i += 1) {
          const momentDate = startMoment.clone().add(i, 'month');
          const key = momentDate.format('YYYY-MM');
          chartDataMonth[key] = {
            label: momentDate.format('M/YY'), totalCount: 0, entries: keys.reduce((a, v) => ({ ...a, [v]: 0 }), {}),
          };
        }
        for (let i = 0; i <= weeks; i += 1) {
          const momentDate = startMoment.clone().add(i, 'week').startOf('week');
          const key = momentDate.format('YYYY-w');
          if (momentDate > endMoment) break;
          chartDataWeek[key] = {
            label: `${moment(key, 'YYYY-w').startOf('week').format('M/D/YY')} - ${moment(key, 'YYYY-w').endOf('week').format('M/D/YY')}`,
            totalCount: 0,
            entries: keys.reduce((a, v) => ({ ...a, [v]: 0 }), {}),
          };
        }

        data?.forEach(({
          date, entries,
        }) => {
          const week = moment(date).format('YYYY-w');
          const byMonthKey = date.substring(0, 7);
          chartData[date] = sumAll({
            dataEntries: entries,
          }, chartData[date]);
          chartDataMonth[byMonthKey] = sumAll({
            dataEntries: entries, label: moment(date).format('MMM YYYY'),
          }, chartDataMonth[byMonthKey]);
          chartDataWeek[week] = sumAll({
            dataEntries: entries,
          }, chartDataWeek[week]);
        });

        if (total === 'total') {
          const chartDataKeys = Object.keys(chartData);
          const totalKey = `${chartDataKeys[0]} - ${chartDataKeys[chartDataKeys.length - 1]}`;
          const totalObject = { label: totalKey, totalCount: 0, entries: {} };
          chartDataKeys.forEach((dayKey) => {
            if (chartData[dayKey]?.totalCount) totalObject.totalCount += chartData[dayKey].totalCount;
            if (chartData[dayKey]?.entries) {
              Object.keys(chartData[dayKey].entries).forEach((entryKey) => {
                if (totalObject.entries[entryKey]) {
                  totalObject.entries[entryKey] += chartData[dayKey].entries[entryKey];
                } else totalObject.entries[entryKey] = chartData[dayKey].entries[entryKey];
              });
            }
          });
          totalData[totalKey] = totalObject;
        }
        if (total === 'month') {
          formattedDataObject[type] = Object.values(chartDataMonth).map(item => ({ label: item.label, totalCount: item.totalCount, ...item.entries }));
        } else if (total === 'week') {
          formattedDataObject[type] = Object.values(chartDataWeek).map(item => ({ label: item.label, totalCount: item.totalCount, ...item.entries }));
        } else if (total === 'total') {
          formattedDataObject[type] = Object.values(totalData).map(item => ({ label: item.label, totalCount: item.totalCount, ...item.entries }));
        } else formattedDataObject[type] = Object.values(chartData).map(item => ({ label: item.label, totalCount: item.totalCount, ...item.entries }));
      }
    });
    return formattedDataObject;
  }, [selectedOption, reportData]);

  useEffect(() => {
    setColorOptions({
      contentReviewed: getShuffledColorsForCharts((reportData?.contentReviewed?.names || []).length),
      urlValidation: getShuffledColorsForCharts((reportData?.urlValidation?.names || []).length),
      creatorSafetyReviews: getShuffledColorsForCharts((reportData?.creatorSafetyReviews?.names || []).length),
      // commentsReviewed: getShuffledColorsForCharts((reportData?.commentsReviewed?.names || []).length),
    });
  }, [reportData]);

  const totalBox = (key, label) => (
    <Col
      key={`totalbox${key}`}
    >
      <Item
        label={label}
        value={loadingTotals ? (<><Spinner color="primary" size="sm" /><br /></>) : (<h3>{totalsData[key] === '-' ? '-' : formatter.formatIntNumber(totalsData[key])}</h3>)}
      />
    </Col>
  );

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Compliance', path: false },
              { title: 'Metrics', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Compliance Metrics Dashboard</h3>
              </CardTitle>
              <hr />
              <Row className="pb-2">
                <Col md={1} />
                <Col md={10}>
                  <Label className="bold-text">
                    {`Today (${moment().format('MMM D, YYYY')}):`}
                  </Label>
                  <Row>
                    {totalBox('creatorSafetyReviews', 'Creator Safety Reviews')}
                    {totalBox('inPendingEditsFor48Hrs', 'In Pending Edits For 48 Hrs+')}
                    {totalBox('needsContentUrl24Hrs', 'Needs Content URL 24 Hrs+')}
                    {totalBox('urlsPendingPublication24Hrs', 'URLs Pending Publication 24 Hrs+')}
                  </Row>
                </Col>
                <Col md={1} />
              </Row>
              <hr />
              <Row className="mt-3">
                <Col>
                  <Label className="bold-text">Review Date</Label>
                  <Filter
                    ref={timeFilterRef}
                    startDateInitial={startDate}
                    endDateInitial={endDate}
                    setValue={(param, value) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                    usedForConversions
                    isLoading={!!loadingReport}
                    options={[
                      { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
                      { value: 'last_month', label: 'Last Month' },
                      { value: 'last_90_days', label: 'Last 90 Days' },
                      { value: 'year_to_date', label: 'Year to Date' },
                      {
                        value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
                      },
                    ]}
                    selectDateRangeFunction={(range) => {
                      let dateFrom;
                      let dateTo;
                      let showCustom = false;
                      switch (range) {
                        case 'current_month':
                          showCustom = false;
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = moment().toDate();
                          break;
                        case 'last_month':
                          showCustom = false;
                          dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
                          dateTo = moment().subtract(1, 'month').endOf('month').toDate();
                          break;
                        case 'last_90_days':
                          showCustom = false;
                          dateFrom = moment().subtract(90, 'day').startOf('day').toDate();
                          dateTo = moment().toDate();
                          break;
                        case 'year_to_date':
                          showCustom = false;
                          dateFrom = moment().startOf('Year').toDate();
                          dateTo = moment().toDate();
                          break;
                        case 'custom':
                          showCustom = true;
                          dateFrom = moment().startOf('Year').toDate();
                          dateTo = moment().toDate();
                          break;
                        default:
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = (moment().endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).toDate();
                          break;
                      }
                      return { dateFrom, dateTo, showCustom };
                    }
                  }
                  />
                </Col>
                <Col lg="auto">
                  <div style={{ paddingTop: '30px' }}>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchReport();
                        return false;
                      }}
                      className="my-0 btn-sm"
                      disabled={!!loadingReport}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2" >
                <Col md={5}>
                  <Label className="bold-text">Brands</Label>
                  <Select
                    isDisabled={loadingBrandOptions}
                    value={selectedBrands}
                    options={brandOptions}
                    onChange={setSelectedBrands}
                    placeholder="Select Brands..."
                    isClearable
                    isMulti
                  />
                </Col>
                <Col md={5}>
                  <Label className="bold-text">Employees</Label>
                  <Select
                    isDisabled={loadingEmployeeOptions}
                    value={selectedEmployees}
                    options={employeeOptions}
                    onChange={setSelectedEmployees}
                    placeholder="Select Employees..."
                    isClearable
                    isMulti
                  />
                </Col>
                <Col sm={2} style={{ paddingTop: '29px' }}>
                  <Row>
                    <Col style={{ paddingRight: '0' }}>
                      <Tooltip title="Reset Filters">
                        <Button
                          color="secondary"
                          size="sm"
                          onClick={resetFilters}
                          style={{
                            width: '100%',
                            padding: '4px 8px',
                            height: '38px',
                          }}
                        >
                          <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                          <span className="btn-text">Reset</span>
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col lg="auto">
                      <Tooltip title="Toggle additional filters">
                        <IconButton
                          id="filtersMenuButton"
                          size="small"
                          onClick={() => setShowFilters(!showFilters)}
                          className="material-table__toolbar-button"
                          style={{
                            height: '38px',
                          }}
                        >
                          <TuneIcon size="30" />
                        </IconButton>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {showFilters && (
                <Row>
                  <Col md={2}>
                    <Label className="bold-text" style={{ marginBottom: '8px' }}>
                      Historical Content Review Status
                    </Label>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="needsReviewStatus"
                        label="Needs Review"
                        value={filtersHistoricalContentReviewStatus['Needs Review']}
                        onChange={event => handleChangeHistoricalContentReviewStatusFilters(event, 'Needs Review')}
                      />
                    </FormGroup>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="pendingEditsStatus"
                        label="Pending Edits"
                        value={filtersHistoricalContentReviewStatus['Pending Edits']}
                        onChange={event => handleChangeHistoricalContentReviewStatusFilters(event, 'Pending Edits')}
                      />
                    </FormGroup>
                    <FormGroup style={{ marginBottom: '10px' }} >
                      <CheckBoxField
                        name="ApprovedStatus"
                        label="Approved"
                        value={filtersHistoricalContentReviewStatus.Approved}
                        onChange={event => handleChangeHistoricalContentReviewStatusFilters(event, 'Approved')}
                      />
                    </FormGroup>
                    <FormGroup style={{ marginBottom: '0' }} >
                      <CheckBoxField
                        name="TerminatedStatus"
                        label="Terminated"
                        value={filtersHistoricalContentReviewStatus.Terminated}
                        onChange={event => handleChangeHistoricalContentReviewStatusFilters(event, 'Terminated')}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <Label className="bold-text" style={{ marginBottom: '8px' }}>
                      URL Status
                    </Label>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="reviewUrlStatus"
                        label="Needs Review"
                        value={filtersUrlStatus['Needs Review']}
                        onChange={event => handleChangeUrlStatusFilters(event, 'Needs Review')}
                      />
                    </FormGroup>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="pendingPublicationUrlStatus"
                        label="Pending Publication"
                        value={filtersUrlStatus['Pending Publication']}
                        onChange={event => handleChangeUrlStatusFilters(event, 'Pending Publication')}
                      />
                    </FormGroup>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="noUrlUrlStatus"
                        label="Needs Content Url"
                        value={filtersUrlStatus['Needs Content URL']}
                        onChange={event => handleChangeUrlStatusFilters(event, 'Needs Content URL')}
                      />
                    </FormGroup>
                    <FormGroup style={{ marginBottom: '10px' }} >
                      <CheckBoxField
                        name="ApprovedUrlStatus"
                        label="Approved"
                        value={filtersUrlStatus.Approved}
                        onChange={event => handleChangeUrlStatusFilters(event, 'Approved')}
                      />
                    </FormGroup>
                    <FormGroup
                      style={{ marginBottom: '0px' }}
                    >
                      <CheckBoxField
                        name="inactive"
                        label="Inactive"
                        value={filtersUrlStatus.Inactive}
                        onChange={event => handleChangeUrlStatusFilters(event, 'Inactive')}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label className="bold-text">
                        Creator Safety Review Status
                      </Label>
                      <FormGroup style={{ marginBottom: '10px' }} >
                        <CheckBoxField
                          name="NeedsReview"
                          label="Needs Review"
                          // className="inline"
                          value={filtersCreatorSafetyReviewStatus['Needs Review']}
                          onChange={event => handleChangeCreatorSafetyReviewStatusFilters(event, 'Needs Review')}
                        />
                      </FormGroup>
                      <FormGroup style={{ marginBottom: '10px' }} >
                        <CheckBoxField
                          name="PendingEdits"
                          label="Pending Edits"
                          // className="inline ml-4"
                          value={filtersCreatorSafetyReviewStatus['Pending Edits']}
                          onChange={event => handleChangeCreatorSafetyReviewStatusFilters(event, 'Pending Edits')}
                        />
                      </FormGroup>
                      <FormGroup style={{ marginBottom: '10px' }} >
                        <CheckBoxField
                          name="Approved"
                          label="Approved"
                          // className="inline ml-4"
                          value={filtersCreatorSafetyReviewStatus.Approved}
                          onChange={event => handleChangeCreatorSafetyReviewStatusFilters(event, 'Approved')}
                        />
                      </FormGroup>
                      <FormGroup style={{ marginBottom: '0' }} >
                        <CheckBoxField
                          name="Terminated"
                          label="Terminated"
                          // className="inline ml-4"
                          value={filtersCreatorSafetyReviewStatus.Terminated}
                          onChange={event => handleChangeCreatorSafetyReviewStatusFilters(event, 'Terminated')}
                        />
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  {/* <Col md={2}>
                    <FormGroup>
                      <Label className="bold-text">
                        Comment Sentiment Status
                      </Label>
                      <FormGroup style={{ marginBottom: '10px' }} >
                        <CheckBoxField
                          name="sentimentNeedsReview"
                          label="Needs Review"
                          value={filtersCommentSentimentStatus['Needs Review']}
                          onChange={event => handleChangeCommentSentimentStatusFilters(event, 'Needs Review')}
                        />
                      </FormGroup>
                      <FormGroup style={{ marginBottom: '10px' }} >
                        <CheckBoxField
                          name="PassedAnalysis"
                          label="Passed Analysis"
                          value={filtersCommentSentimentStatus['Passed Analysis']}
                          onChange={event => handleChangeCommentSentimentStatusFilters(event, 'Passed Analysis')}
                        />
                      </FormGroup>
                      <FormGroup style={{ marginBottom: '10px' }} >
                        <CheckBoxField
                          name="Positive"
                          label="Positive"
                          value={filtersCommentSentimentStatus.Positive}
                          onChange={event => handleChangeCommentSentimentStatusFilters(event, 'Positive')}
                        />
                      </FormGroup>
                      <FormGroup style={{ marginBottom: '10px' }} >
                        <CheckBoxField
                          name="Neutral"
                          label="Neutral"
                          value={filtersCommentSentimentStatus.Neutral}
                          onChange={event => handleChangeCommentSentimentStatusFilters(event, 'Neutral')}
                        />
                      </FormGroup>
                      <FormGroup style={{ marginBottom: '0' }} >
                        <CheckBoxField
                          name="Negative"
                          label="Negative"
                          value={filtersCommentSentimentStatus.Negative}
                          onChange={event => handleChangeCommentSentimentStatusFilters(event, 'Negative')}
                        />
                      </FormGroup>
                    </FormGroup>
                  </Col> */}
                </Row>
              )}
              <Row>
                <Col>
                  <LoadingReport loading={loadingReport} />
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    topic="report"
                    identity={identity}
                    isShow={!!loadingReport}
                  />
                </Col>
              </Row> */}
              {!loadingReport && hasLoadedReport && (
                <>
                  <Row>
                    <Col>
                      <div
                        style={{ width: '250px' }}
                      >
                        <Select
                          placeholder="Select Property to Chart..."
                          value={selectedOption}
                          options={dataOptions}
                          onChange={setSelectedOption}
                          className="mb-2"
                        />
                        <FormGroup>
                          <CheckBoxField
                            name="showLabels"
                            label="Show Labels on Chart"
                            value={showLabels}
                            onChange={e => setShowLabels(e.target?.checked || false)}
                            disabled={loadingReport}
                          />
                        </FormGroup>
                      </div>
                    </Col>
                    <Col />
                  </Row>
                  <Row key="1" className="mt-3 mb-2">
                    <Col md={12}>
                      <Label className="bold-text">Historical Content Reviewed by Employee</Label>
                      <BarChartComponent
                        chartData={formattedData.contentReviewed}
                        chartType="contentReviewed"
                        keys={reportData?.contentReviewed?.keys || []}
                        defaultColors={(reportData?.contentReviewed?.names || []).reduce((accumulator, value, index) => ({ ...accumulator, [value]: colorOptions.contentReviewed[index] }), {})}
                        showLabels={showLabels}
                        statuses={Object.keys(initialHistoricalContentReviewStatusFilters)}
                      />
                    </Col>
                  </Row>
                  <Row key="2" className="mt-3 mb-2">
                    <Col md={12}>
                      <Label className="bold-text">URL Validation by Employee </Label>
                      <BarChartComponent
                        chartData={formattedData.urlValidation}
                        chartType="urlValidation"
                        keys={reportData?.urlValidation?.keys || []}
                        defaultColors={(reportData?.urlValidation?.names || []).reduce((accumulator, value, index) => ({ ...accumulator, [value]: colorOptions.urlValidation[index] }), {})}
                        showLabels={showLabels}
                        statuses={Object.keys(initialURLStatusFilters)}
                      />
                    </Col>
                  </Row>
                  <Row key="3" className="mt-3 mb-2">
                    <Col md={12}>
                      <Label className="bold-text">Creator Safety Reviews by Employee</Label>
                      <BarChartComponent
                        chartData={formattedData.creatorSafetyReviews}
                        chartType="creatorSafetyReviews"
                        keys={reportData?.creatorSafetyReviews?.keys || []}
                        defaultColors={(reportData?.creatorSafetyReviews?.names || []).reduce((accumulator, value, index) => ({ ...accumulator, [value]: colorOptions.creatorSafetyReviews[index] }), {})}
                        showLabels={showLabels}
                        statuses={Object.keys(initialCreatorSafetyReviewStatusFilters)}
                      />
                    </Col>
                  </Row>
                  {/* <Row key="4" className="mt-3 mb-2">
                    <Col md={12}>
                      <Label className="bold-text">Comments Reviewed by Employee</Label>
                      <BarChartComponent
                        chartData={formattedData.commentsReviewed}
                        chartType="commentsReviewed"
                        keys={reportData?.commentsReviewed?.keys || []}
                        defaultColors={(reportData?.commentsReviewed?.names || []).reduce((accumulator, value, index) => ({ ...accumulator, [value]: colorOptions.commentsReviewed[index] }), {})}
                        showLabels={showLabels}
                        statuses={Object.keys(initialCommentSentimentStatusFilters)}
                      />
                    </Col>
                  </Row> */}
                </>
              )}
              {Array.isArray(reportData.contentStatusAverageTimes) && reportData.contentStatusAverageTimes?.length > 0 && (
                <GenerateTable
                  key="5"
                  reportData={Array.isArray(reportData.contentStatusAverageTimes) ? reportData.contentStatusAverageTimes : []}
                  loading={loadingReport}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Report.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(Report);
