import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button, FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { axios } from 'ApiClient';
import withNotice from '../../../../../../App/store/with-notice';
import ProgressBar from '../../../../../../../shared/helpers/ProgressBar';
import csvHelper from '@/shared/helpers/CSVHelper';
import formater from '@/shared/helpers/WVFormatter';

const useStyles = makeStyles({
  progressBar: {
    paddingTop: '20px',
  },
});

const AddToInsertionOrderModal = ({
  isOpen,
  closeFunc,
  prospectIds,
  insertionOrders,
}) => {
  const classes = useStyles();
  const [selectedInsertionOrder, setSelectedInsertionOrder] = useState(null);
  const [results, setResults] = useState([]);
  const [loadingAddToInsertionOrder, setLoadingAddToInsertionOrder] = useState(false);
  const [resultMessage, setResultMessage] = useState('');

  const handleChangeOwner = async (selectedItem) => {
    setSelectedInsertionOrder(selectedItem);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedInsertionOrder(null);
      setResultMessage('');
    }
    // console.log(total);
  }, [isOpen]);

  const addToInsertionOrder = (cpmInsertionOrderId) => {
    setLoadingAddToInsertionOrder(true);
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-add-cpm-prospects-to-insertion-order',
      data: {
        cpmInsertionOrderId,
        cpmProspectIds: prospectIds,
      },
    }).then((res) => {
      setLoadingAddToInsertionOrder(false);
      if (res?.data?.success) {
        setResults(res.data.cpmProspects);
        setResultMessage(res?.data?.message);
      } else if (res?.data?.error) {
        setResultMessage(`Error happened while adding CPM Creator${prospectIds.length === 1 ? '' : 's'} to the Insertion Order. Error: ${res.data.error}`);
      }
    }).catch((error) => {
      console.error(error);
      setResultMessage(`Error happened while adding CPM Creator${prospectIds.length === 1 ? '' : 's'} to the Insertion Order. Error: ${error.message}`);
      setLoadingAddToInsertionOrder(false);
    });
  };
  const downloadResults = (e) => {
    e.preventDefault()
    const data = [[
      'Creator Name',
      'Creator Email',
      'Creator Profile URL',
      'Deal Name',
      'Deal URL',
      'Deal Owner Name',
      'Pricing Approval Group',
      'Insertion Order',
      'Invoice Number',
      'CPM Total Cost',
      'Current Pricing Approval Status',
      'PA Submit Date',
      'Justification',
      'status'
    ]];
    results.forEach((record) => {
      data.push([
        `${record.influencer?.first_name || ''} ${record.influencer?.last_name || ''}`,
        record.influencer?.email || '',
        `${window.location.protocol}//${window.location.host}/influencer/profile/${record.influencer?._id || ''}`,
        record.deal?.dealname || '',
        record.deal ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${record.deal?.hs_object_id}` : '-',
        `${record.deal?.adminUserObject?.firstName || ''} ${record.deal?.adminUserObject?.lastName || ''}`,
        record.cpmPriceApprovals?.name || '-',
        record.cpmInsertionOrder?.name || '-',
        record.cpmInvoice?.name || '-',
        formater.formatCurrency(record.totalPrice || 0),
        record?.cpmPriceApprovals?.status?.status || '-',
        record.createdAt || '-',
        record.dealJustification || '-',
        record.addingStatus,
      ]);
    });
    return csvHelper.generateCSV('CPMPrespects.csv', data);
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '425px' }}
    >
      <ModalHeader
        toggle={() => closeFunc()}
        tag="h4"
        className="modal-title-statistics"
      >
        {loadingAddToInsertionOrder ? 'Adding Creators' : 'Add to Insertion Order'}
      </ModalHeader>
      <ModalBody>
        {resultMessage !== '' ? (
          <div>
            {resultMessage}
            <ul>
              <li>Deals successfully added: {results.filter(a => a.addingStatus === 'Added')?.length || 0}</li>
              <li>Duplicate deals detected: {results.filter(a => a.addingStatus === 'Duplicate')?.length || 0}</li>
              <li>No deals detected: {results.filter(a => a.addingStatus === 'NoDeal')?.length || 0}</li>
              <li>Brand Missmatch deals detected: {results.filter(a => a.addingStatus === 'BrandMissmatch')?.length || 0}</li>
            </ul>
            <div><a href="#1" onClick={downloadResults}>Download</a> Results in CSV</div>
          </div>
        ) : (
          <>
            <div style={loadingAddToInsertionOrder ? { display: 'none' } : { }} >
              <span>
                <FontAwesomeIcon icon={['fas', 'info-circle']} />
                {` You have selected ${prospectIds.length} Creator${prospectIds.length !== 1 ? 's' : ''}`}
              </span>
              <Row className="mt-2">
                <Col>
                  <FormGroup>
                    <Select
                      value={selectedInsertionOrder}
                      options={insertionOrders}
                      onChange={handleChangeOwner}
                      placeholder="Select Insertion Order"
                      isDisabled={!!loadingAddToInsertionOrder}
                      menuPlacement="auto"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div
              style={loadingAddToInsertionOrder ? { } : { display: 'none' }}
            >
              <div className="mb-2">
                {`Adding Creator${prospectIds.length !== 1 ? 's' : ''} to Insertion Order ${selectedInsertionOrder?.label}...`}
              </div>
              <div
                className={classes.progressBar}
              >
                <ProgressBar
                  topic="addCpmProspectsToInsertionOrder"
                />
              </div>
            </div>
          </>
        )}
      </ModalBody>
      <ButtonToolbar
        className="modal__footer"
        style={loadingAddToInsertionOrder ? { display: 'none' } : { }}
      >
        {resultMessage !== '' ? (
          <Button
            className="modal_cancel btn-sm"
            color="secondary"
            onClick={() => closeFunc()}
          >
            Close
          </Button>
        ) : (
          <>
            <Button
              className="modal_ok btn-sm"
              color="primary"
              onClick={() => {
                addToInsertionOrder(selectedInsertionOrder.value);
              }}
              disabled={loadingAddToInsertionOrder}
            >
              {loadingAddToInsertionOrder && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
              <span>Add</span>
            </Button>&nbsp;&nbsp;
            <Button
              className="modal_cancel btn-sm"
              color="secondary"
              onClick={() => closeFunc()}
            >
              Cancel
            </Button>
          </>
        )}
      </ButtonToolbar>
    </Modal>
  );
};

AddToInsertionOrderModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  prospectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  insertionOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withNotice(AddToInsertionOrderModal);
