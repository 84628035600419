import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  FormGroup,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import { CKEditor } from 'ckeditor4-react';
import withNotice from '../../../../../../App/store/with-notice';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import RadioButton from '../../../../../../../shared/components/RadioButton';

const defaultOption = { value: 'Needs Review', label: 'Needs Review' };

const BulkUpdateMediaContentReviewStatusModal = ({
  isOpen,
  closeFunc,
  setVersion,
  mediaContentIds,
  total,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedReviewStatus, setSelectedReviewStatus] = useState(defaultOption);
  const [includeAll, setIncludeAll] = useState(false);
  const [notes, setNotes] = useState('');

  const handleChange = async (selectedItem) => {
    setSelectedReviewStatus(selectedItem);
  };

  const handleNotesInputChange = (e) => {
    const { editor } = e;
    setNotes(editor.getData());
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedReviewStatus(defaultOption);
      setIncludeAll(false);
    }
  }, [isOpen]);

  // SUBMIT Data
  const submit = async () => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search);
    axios({
      method: 'post',
      url: '/api/portal/scorecard/media-content/bulk-update-review-status',
      data: {
        ids: mediaContentIds,
        status: selectedReviewStatus.value,
        includeAll,
        query: query.toString(),
        notes: selectedReviewStatus.value === 'Pending Edits' ? notes : false,
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        closeFunc();
      }
    });
    return true;
  };

  const totalToAssign = Math.min(total, 500);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '600px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Update Review Status</ModalHeader>
      <ModalBody>
        {mediaContentIds.length !== total && (
          <>
            <div>{`You have selected ${mediaContentIds.length} result${mediaContentIds.length !== 1 ? 's' : ''} to update Review Status`}</div>
            <div className="theme-light radio-in-prospects mt-2">
              {`Select ${totalToAssign === total ? 'all of' : 'the first'} ${WVFormatter.formatIntNumber(totalToAssign)} results?`}&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="action"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                name="action"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>
          </>
        )}
        <Row>
          <Col>
            <FormGroup>
              <Label className="bold-text">New Review Status</Label>
              <Select
                value={selectedReviewStatus}
                options={[
                  { value: 'Needs Review', label: 'Needs Review' },
                  { value: 'Pending Edits', label: 'Pending Edits' },
                  { value: 'Approved', label: 'Approved' },
                  { value: 'Terminated', label: 'Terminated' },
                ]}
                onChange={handleChange}
                placeholder=""
                isDisabled={!!loading}
                menuPlacement="auto"
              />
              {selectedReviewStatus.value === 'Pending Edits' && (
                <>
                  <Label className="bold-text mt-3">Review Notes</Label>
                  <CKEditor
                    config={{versionCheck: false}}
                    id="bodyEditor"
                    name="itemText"
                    activeClass="form-control"
                    initData={notes}
                    onChange={(e) => { handleNotesInputChange(e); }}
                  />
                </>
              )}
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};


BulkUpdateMediaContentReviewStatusModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  mediaContentIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default withNotice(BulkUpdateMediaContentReviewStatusModal);
