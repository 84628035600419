/* eslint react/prop-types: 0 */
import React from 'react';
// import { axios } from 'ApiClient';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import queryHasFilter from '@/shared/ViewHelpers/QueryHasFilter';
import RefreshIcon from 'mdi-react/RefreshIcon';
import ActionHeader from '../../../../shared/components/components/app/action-header';
import Filter from '../../../../shared/components/components/app/filter';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
import UserPropertyType from '../HubilDeal/components/custom';
import ButtonWithSpinner from '../../../../shared/components/ButtonWithSpinner';
import {
  fetchContacts,
  getFetchContactsProcessStatus,
  syncHubSpotStructure,
} from '../../../../redux/reducers/admin/HubilReducer';
import HubspotStatsWidget from '../../../../shared/components/hubspot/HubspotStatsWidget';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import { setCookie, getCookie } from '../../../../shared/helpers/WVUtilities';

class ResourceAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterVisible: queryHasFilter(props.location.search),
      v: (new Date()).toString(),
      identity: '',
      fetchContactsLoading: false,
    };
    this.refreshTable = this.refreshTable.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
    this.afterFetch = this.afterFetch.bind(this);
  }
  componentDidMount() {
    this.checkStatus();
  }
  setChildMethod = (setRefresh, context) => {
    this.refreshMatTable = setRefresh.bind(context);
  }
  refreshTable = () => {
    const { dispatch } = this.props;
    const newIdentity = Math.random().toString(36).substring(2);
    setCookie('fetchContactsIdentity', newIdentity, 0.05);
    setCookie('fetchContactsLoading', true, 0.05);
    this.setState({ identity: newIdentity });
    dispatch(fetchContacts(() => {}, newIdentity));
  }
  syncHsStructure = () => {
    const { dispatch } = this.props;
    dispatch(syncHubSpotStructure());
  }
  checkStatus = () => {
    const { dispatch } = this.props;
    const savedIdentity = getCookie('fetchContactsIdentity');
    if (savedIdentity) {
      this.setState({ identity: savedIdentity }, () => {
        dispatch(getFetchContactsProcessStatus(() => {}));
      });
    }
    const loading = getCookie('fetchContactsLoading');
    // console.log(loading);
    if (loading) {
      this.setState({ fetchContactsLoading: true });
    }
  }

  afterFetch = () => {
    setCookie('fetchContactsLoading', false, 0.5);
    this.refreshMatTable();
    this.setState({ v: (new Date()).toString(), fetchContactsLoading: false });
  }

  render() {
    const {
      resources,
      loading,
      contactsRemainingToUpload,
    } = this.props;
    // console.log(loading, contactsRemainingToUpload);

    const {
      filterVisible,
      tag,
      v,
      identity,
      fetchContactsLoading,
    } = this.state;
    console.log('LOADING', fetchContactsLoading);
    const resource = resources.find(r => r.id === 'HubilContact');
    if (!resource) {
      return (<NoResourceError resourceId="HubilContact" />);
    }
    const action = resource.resourceActions.find(r => r.name === 'list');
    if (!action) {
      return (<NoActionError resourceId="HubilContact" actionName="list" />);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs resource={resource} actionName="list" />
            <ActionHeader
              resource={resource}
              action={action}
              tag={tag}
              toggleFilter={() => this.setState({ filterVisible: !filterVisible })}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <HubspotStatsWidget version={v} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ProgressBar
              topic="fetchAllContacts"
              afterFunction={this.afterFetch}
              identity={identity}
              isShow={loading || fetchContactsLoading}
            />
          </Col>
          <Col md={6}>
            <ButtonWithSpinner
              className="btn btn-sm btn-primary float-right"
              onClick={this.refreshTable}
              disabled={loading || !!contactsRemainingToUpload || fetchContactsLoading}
              loading={loading || !!contactsRemainingToUpload || fetchContactsLoading}
            >
              {!loading ? <RefreshIcon size={18} /> : ''}
              Fetch all contacts from HS
            </ButtonWithSpinner>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <MatTableList
              action={action}
              resource={resource}
              setTag={state => this.setState({ tag: state })}
              date={new Date()}
              UserPropertyType={UserPropertyType}
              sortBy="_id"
              direction="asc"
              columnsSettings={{
                hs_object_id: {
                  width: 100,
                },
                email: {
                  width: 250,
                },
                firstname: {
                  width: 150,
                },
                lastname: {
                  width: 150,
                },
                num_associated_deals: {
                  width: 100,
                },
                archived: {
                  width: 100,
                },
              }}
              setRefresh={this.setChildMethod}
              showFilters
              hideToolbar
              v={2}
            />
          </Col>
        </Row>
        {action.showFilter ? (
          <Filter
            resource={resource}
            isVisible={filterVisible}
            toggleFilter={() => this.setState({ filterVisible: !filterVisible })}
          />
        ) : ''}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  
  resources: state.resources,
  loading: state.hubilReducer.loading.fetchContacts,
  contactsRemainingToUpload: state.hubilReducer.contactsRemainingToUpload,
});

export default connect(mapStateToProps)(ResourceAction);
