/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const AccountReceivableReducer = createSlice({
  name: 'accountReceivable',
  initialState: {
    receivables: [],
    loading: {
      receivables: false,
      fillReceivables: false,
      accountReceivable: false,
      editingFields: [],
      createNew: false,
    },
    logs: {
      receivables: {
        request: [],
        added: [],
        removed: [],
        errors: [],
      },
    },
    errors: [],
    error: null,
    lastFetched: new Date(),
    accountReceivable: null,
  },
  reducers: {
    getAccountReceivableRecordStart(state) {
      // console.log(state);
      state.loading = {
        ...state.loading,
        accountReceivable: true,
      };
    },
    getAccountReceivableRecordSuccess(state, action) {
      const { record } = action.payload;
      state.loading = {
        ...state.loading,
        accountReceivable: false,
        editingFields: [],
      };
      state.error = null;
      state.accountReceivable = record;
    },
    getAccountReceivableRecordError(state) {
      // console.log(state, action);
      state.loading = {
        ...state.loading,
        accountReceivable: false,
      };
    },
    saveAccountReceivableRecordStart(state) {
      state.loading = {
        ...state.loading,
        accountReceivable: true,
      };
      state.error = null;
      state.accountReceivable = null;
    },
    saveAccountReceivableRecordSuccess(state) {
      state.loading = {
        ...state.loading,
        accountReceivable: false,
        editingFields: [],
      };
      state.error = null;
    },
    saveAccountReceivableRecordError(state, action) {
      const { record } = action.payload;
      // console.log(state, action);
      state.loading = {
        ...state.loading,
        accountReceivable: false,
      };
      state.accountReceivable = record;
    },
    getAccountReceivableRecordClear(state) {
      state.loading = {
        ...state.loading,
        accountReceivable: false,
      };
      state.error = null;
      state.accountReceivable = null;
    },
    setEditingFieldRecord(state, action) {
      const { edit } = action.payload;
      state.loading = {
        ...state.loading,
        accountReceivable: false,
        editingFields: edit,
      };
    },

    getAccountReceivableStart(state) {
      // console.log(state);
      state.loading = {
        ...state.loading,
        receivables: true,
      };
    },
    getAccountReceivableSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        receivables: false,
      };
      state.error = null;
      state.lastFetched = new Date();
      state.receivables = data;
    },
    updateReceivableRecord(state, action) {
      const { id, record } = action.payload;
      const newRecord = [];
      state.receivables.forEach((Receivable) => {
        if (Receivable.id === id) {
          // console.log('UPDATE', Receivable);
          newRecord.push(record);
        } else {
          newRecord.push(Receivable);
        }
      });
      state.lastFetched = new Date();
      state.receivables = newRecord;
    },
    getAccountReceivableError(state) {
      // console.log(state, action);
      state.loading = {
        ...state.loading,
        receivables: false,
      };
    },
    getFillAccountReceivablesStart(state) {
      state.loading = {
        ...state.loading,
        receivables: true,
        fillReceivables: true,
      };
      state.logs = {
        ...state.logs,
        receivables: {
          request: [],
          added: [],
          removed: [],
          errors: [],
        },
      };
      state.error = null;
    },
    getFillAccountReceivablesSuccess(state, action) {
      const { receivablesLog } = action.payload;
      // console.log(action);
      state.loading = {
        ...state.loading,
        receivables: true,
        fillReceivables: false,
      };
      state.logs = {
        ...state.logs,
        receivables: receivablesLog,
      };
      state.error = null;
      // state.payables = items;
    },
    getFillAccountReceivablesError(state, acton) {
      state.loading = {
        ...state.loading,
        receivables: false,
        fillReceivables: false,
      };
      state.error = acton.payload;
    },
  },
});

const getAccountReceivableApi = async (id) => {
  const api = new ApiClient();
  return api.recordAction({
    resourceId: 'AccountsReceivable',
    recordId: id,
    actionName: 'show',
  });
};

const getReceivablesApi = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/finance/get-receivables?reportId=${id}`);
};
const fillReceivablesApi = async (id, isForce) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/finance/fill-receivables', { reportId: id, isForce });
};


export const {
  // fill table
  getFillAccountReceivablesStart,
  getFillAccountReceivablesError,
  getFillAccountReceivablesSuccess,
  // get payables
  getAccountReceivableStart,
  getAccountReceivableSuccess,
  getAccountReceivableError,
  // get account payable reocrd
  getAccountReceivableRecordStart,
  getAccountReceivableRecordSuccess,
  getAccountReceivableRecordError,
  getAccountReceivableRecordClear,
  // create new
  saveAccountReceivableRecordStart,
  saveAccountReceivableRecordSuccess,
  saveAccountReceivableRecordError,
  setEditingFieldRecord,
  clearEditingFieldRecord,
  // update table
  updateReceivableRecord,
} = AccountReceivableReducer.actions;

export const getAccountReceivableRecord = id => async (dispatch) => {
  try {
    if (!id) {
      dispatch(getAccountReceivableRecordClear());
    } else {
      dispatch(getAccountReceivableRecordStart());
      const response = await getAccountReceivableApi(id);
      if (response.data && response.data.record) {
        dispatch(getAccountReceivableRecordSuccess({ record: response.data.record }));
      } else {
        throw new Error('There was an error fetching records, Check out console to see more information.');
      }
    }
  } catch (err) {
    dispatch(getAccountReceivableRecordError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const editAccountReceivableRecord = (id, values, callback) => async (dispatch) => {
  try {
    dispatch(setEditingFieldRecord({ edit: Object.keys(values) }));
    const api = new ApiClient();
    const data = new FormData();
    Object.keys(values).forEach((key) => {
      data.append(key, values[key]);
    });
    await api.recordAction({
      resourceId: 'AccountsReceivable',
      recordId: id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const response = await getAccountReceivableApi(id);
    if (response.data && response.data.record) {
      dispatch(getAccountReceivableRecordSuccess({ record: response.data.record }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
    if (callback) {
      callback(response.data.record);
    }
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getAccountReceivables = id => async (dispatch) => {
  try {
    dispatch(getAccountReceivableStart());
    const response = await getReceivablesApi(id);
    if (response.data) {
      dispatch(getAccountReceivableSuccess({ data: response.data.records }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
    // dis
  } catch (err) {
    dispatch(getAccountReceivableError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};


export const fillAccountReceivables = (id, isForce) => async (dispatch) => {
  try {
    // console.log(id, isForce);
    dispatch(getFillAccountReceivablesStart());
    const response = await fillReceivablesApi(id, isForce);
    dispatch(getFillAccountReceivablesSuccess({ receivablesLog: response.data.receivableLog }));
    dispatch(getAccountReceivables(id));
  } catch (err) {
    dispatch(getFillAccountReceivablesError(err));
  }
};


export const addAccountReceivableRecord = data => async (dispatch) => {
  try {
    dispatch(saveAccountReceivableRecordStart());
    const api = new ApiClient();
    const response = await api.resourceAction({
      resourceId: 'AccountsReceivable',
      actionName: 'new',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.data && response.data.record && response.data.redirectUrl) {
      dispatch(getAccountReceivableRecord(response.data.record.id));
      dispatch(fillAccountReceivables(response.data.record.id));
    } else {
      dispatch(saveAccountReceivableRecordError({ record: response.data.record }));
    }
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};


export default AccountReceivableReducer.reducer;
