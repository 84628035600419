import React from 'react';
import { FormGroup, Col, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import withNotice from '../../../../../containers/App/store/with-notice';
import BrandOpportunityHighlightCard from './BrandOpportunityHighlightCard';
import StyledButton from '../../../../../shared/components/components/ui/styled-button';

class BrandOpportunityHighlightSelection extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.moveItem = this.moveItem.bind(this);
    this.addNewHighlight = this.addNewHighlight.bind(this);
  }

  handleInputChange(e, index, name) {
    const { highlights, saveHighlights } = this.props;
    const { editor } = e;
    highlights[index][name] = editor.getData();
    saveHighlights(highlights);
  }

  addNewHighlight() {
    const { highlights, saveHighlights } = this.props;
    highlights.push({
      itemText: '',
      order: highlights.length,
    });
    saveHighlights(highlights);
  }

  removeItem(index) {
    const { highlights, saveHighlights } = this.props;
    highlights.splice(index, 1);
    // eslint-disable-next-line array-callback-return
    highlights.map((file, i) => {
      highlights[i].order = i;
    });
    saveHighlights(highlights);
  }

  moveItem(dragIndex, hoverIndex) {
    const { highlights, saveHighlights } = this.props;
    // eslint-disable-next-line array-callback-return
    highlights.map((file, index) => {
      if (highlights[index].order >= dragIndex && highlights[index].order <= hoverIndex) {
        highlights[index].order -= 1;
      } else if (highlights[index].order <= dragIndex && highlights[index].order >= hoverIndex) {
        highlights[index].order += 1;
      }
    });
    highlights[dragIndex].order = hoverIndex;
    highlights.sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
    saveHighlights(highlights);
  }

  renderFile(highlight, index) {
    const { styles } = this.props;
    return (
      <BrandOpportunityHighlightCard
        key={`highlight-${index}`}
        styles={styles}
        highlight={highlight}
        index={index}
        moveItem={this.moveItem}
        handleInputChange={this.handleInputChange}
        removeItem={this.removeItem}
      />
    );
  }

  render() {
    const {
      styles,
      title,
      highlights,
    } = this.props;
    return (
      <FormGroup row key={`${title}`}>
        <Label sm={2} style={styles.label}>{title}</Label>
        <Col sm={10}>
          <DndProvider backend={HTML5Backend}>
            <List>
              {(highlights && highlights.length > 0) && highlights.map((highlight, index) => this.renderFile(highlight, index))}
            </List>
          </DndProvider>
          <StyledButton
            type="button"
            className="is-primary"
            onClick={this.addNewHighlight}
            style={{ marginTop: 20 }}
          >
            <span className="btn-text">Add New Highlight</span>
          </StyledButton>
        </Col>
      </FormGroup>
    );
  }
}

BrandOpportunityHighlightSelection.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    itemText: PropTypes.string,
    order: PropTypes.number,
  })).isRequired,
  title: PropTypes.string.isRequired,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  saveHighlights: PropTypes.func.isRequired,
  // addNotice: PropTypes.func.isRequired,
};

export default withNotice(BrandOpportunityHighlightSelection);
