import React from 'react';
import InfoOutlineIcon from 'mdi-react/InfoOutlineIcon';
import Tooltip from '@material-ui/core/Tooltip';

export default class PayableJournalItemInfoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  showItem = () => {
    // eslint-disable-next-line react/prop-types
    const { itemId } = this.props;
    this.setState({ loading: true });
    const portal = window.location.href.split('.influencelogic')[0];
    if (itemId && portal) {
      const win = window.open(`${portal}.influencelogic.com/resources/PayableJournal/records/${itemId}/show`, '_blank');
      win.focus();
    }
    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;
    return loading ? (
      <div
        className="spinner-border"
        style={
          {
            width: '12px',
            height: '12px',
            marginLeft: '3px',
            marginRight: '6px',
            color: '#646777',
          }
        }
      />
    ) : (
      <Tooltip title="Info">
        <button
          className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall"
          onClick={this.showItem}
        >
          <InfoOutlineIcon
            style={{ width: '15px', height: '15px' }}
          />
        </button>
      </Tooltip>
    );
  }
}

