import React, { useState, forwardRef } from 'react';
import moment from 'moment';
// import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { Row, Col, Button, InputGroup, InputGroupAddon } from 'reactstrap';
import CalendarIcon from 'mdi-react/CalendarIcon';


// eslint-disable-next-line react/prop-types
const DatePickerCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <div className="form-group mb-2">
    <InputGroup size="md" >
      <input
        type="text"
        className="form-control form-control-sm"
        value={value}
        onClick={onClick}
        onChange={onChange}
        style={{
          border: '1px solid #ced4da',
          borderRadius: '5px',
        }}
        ref={ref}
      />
      <InputGroupAddon
        addonType="append"
        style={{
          padding: '3px 0 7px 7px',
        }}
      >
        <CalendarIcon
          className="date-picker-icon"
          onClick={onClick}
        />
      </InputGroupAddon>
    </InputGroup>
  </div>
));

const FilterNew = ({
  setValue, showByDate, startDateLimit, showLast6Month,
}) => {
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate());
  const [showCustom, setShowCustom] = useState(false);
  const [showCustomDay, setShowCustomDay] = useState(false);
  const [showCustomDates, setShowCustomDates] = useState(false);
  const [selectedButton, setSelectedButton] = useState('current_month');
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const selectDateRange = (range) => {
    let dateFrom;
    let dateTo;
    switch (range) {
      case 'day':
        setShowCustomDay(true);
        setShowCustom(false);
        setShowCustomDates(false);
        dateFrom = moment().startOf('day').toDate();
        dateTo = moment().endOf('day').toDate();
        break;
      case 'current_month':
        setShowCustom(false);
        setShowCustomDates(false);
        setShowCustomDay(false);
        dateFrom = moment().startOf('month').toDate();
        dateTo = moment().endOf('day').toDate();
        break;
      case 'last_month':
        setShowCustom(false);
        setShowCustomDates(false);
        setShowCustomDay(false);
        dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
        dateTo = moment().subtract(1, 'month').endOf('month').toDate();
        break;
      case 'year_to_date':
        setShowCustom(false);
        setShowCustomDates(false);
        setShowCustomDay(false);
        dateFrom = moment().startOf('Year').toDate();
        dateTo = moment().endOf('month').toDate();
        break;
      case '6_month':
        setShowCustom(false);
        setShowCustomDates(false);
        setShowCustomDay(false);
        dateFrom = moment().subtract(5, 'month').startOf('month').toDate();
        dateTo = moment().endOf('month').toDate();
        break;
      case 'custom':
        setShowCustomDates(false);
        setShowCustom(true);
        setShowCustomDay(false);
        dateFrom = moment().startOf('Year').toDate();
        dateTo = moment().endOf('day').toDate();
        break;
      case 'custom_dates':
        setShowCustomDates(true);
        setShowCustom(false);
        setShowCustomDay(false);
        dateFrom = moment().startOf('Year').toDate();
        dateTo = moment().endOf('month').toDate();
        break;
      default:
        dateFrom = moment().startOf('Year').toDate();
        dateTo = moment().endOf('month').toDate();
        break;
    }
    setEndDate(dateTo);
    setStartDate(dateFrom);
    setValue('startDate', dateFrom);
    setValue('endDate', dateTo);
    setSelectedButton(range);
  };
  const getText = () => {
    let dateText = '';
    if (
      moment(startDate).format('M') === moment(endDate).format('M') &&
      moment(startDate).format('D') === moment(endDate).format('D') &&
      moment(startDate).format('YYYY') === moment(endDate).format('YYYY')) {
      dateText = moment(startDate).format('MMM D, YYYY');
    } else {
      dateText = `${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate) > moment() ? moment().format('MMM D, YYYY') : moment(endDate).format('MMM D, YYYY')}`;
    }
    return `${dateText} (ET)`;
  };
  const minDate = startDateLimit || moment().subtract(24, 'months');
  // console.log(minDate);
  const buttonClass = 'btn-sm mr-0 time-period-filter-buttons-extended';
  return (
    <div>
      <Row style={{ minWidth: '300px' }}>
        <Col lg="auto">
          {showByDate ? (
            <Button
              onClick={() => selectDateRange('day')}
              // color="primary"
              className={selectedButton === 'day' ? `active ${buttonClass}` : buttonClass}
              style={{ borderRadius: '5px 0 0 5px', borderColor: '#4A69F2' }}
            >
              Day
            </Button>
          ) : null}
          <Button
            key="b1"
            onClick={() => selectDateRange('current_month')}
            className={selectedButton === 'current_month' ? `active ${buttonClass}` : buttonClass}
            style={showByDate ? { borderRadius: '0', borderLeft: '0', borderRight: '0' } : { borderRadius: '5px 0 0 5px', borderRight: '0' }}
          >
            Current Month
          </Button>
          <Button
            key="b2"
            onClick={() => selectDateRange('last_month')}
            className={selectedButton === 'last_month' ? `active ${buttonClass}` : buttonClass}
            style={{ borderRadius: '0', borderRight: '0' }}
          >
            Last Month
          </Button>
          <Button
            key="b3"
            onClick={() => selectDateRange('year_to_date')}
            className={selectedButton === 'year_to_date' ? `active ${buttonClass}` : buttonClass}
            style={{ borderRadius: '0', borderRight: '0' }}
          >
            Year to Date
          </Button>
          {showLast6Month ? (
            <Button
              onClick={() => selectDateRange('6_month')}
              // color="primary"
              className={selectedButton === '6_month' ? `active ${buttonClass}` : buttonClass}
              style={{ borderRadius: '0', borderRight: '0' }}
            >
              Last 6 Months
            </Button>
          ) : null}
          <Button
            onClick={() => selectDateRange('custom')}
            // color="primary"
            className={selectedButton === 'custom' ? `active ${buttonClass}` : buttonClass}
            style={showByDate ? { borderRadius: '0', borderRight: '0' } : { borderRadius: '0 5px 5px 0', borderColor: '#4A69F2' }}
          >
            {showByDate ? 'Monthly' : 'Custom'}
          </Button>
          {showByDate ? (
            <Button
              onClick={() => selectDateRange('custom_dates')}
              // color="primary"
              className={selectedButton === 'custom_dates' ? `active ${buttonClass}` : buttonClass}
              style={{ borderRadius: '0 5px 5px 0', borderColor: '#4A69F2' }}
            >
              Custom
            </Button>
          ) : null}
        </Col>
        <Col lg="auto">
          {showCustom ? (
            <Row>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date) || setValue('startDate', date)}
                    dateFormat="MM/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    className="form-control"
                    showMonthYearPicker
                    customInput={<DatePickerCustomInput />}
                  />
                </div>
              </Col>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(moment(date).endOf('month').toDate()) || setValue('endDate', moment(date).endOf('month').toDate())}
                    dateFormat="MM/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="End Date"
                    dropDownMode="select"
                    className="form-control"
                    showMonthYearPicker
                    customInput={<DatePickerCustomInput />}
                  />
                </div>
              </Col>
            </Row>) : null
          }
          {showCustomDates ? (
            <Row>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date) || setValue('startDate', date, true)}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    className="form-control"
                    customInput={<DatePickerCustomInput />}
                  />
                </div>
              </Col>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(moment(date).toDate()) || setValue('endDate', moment(date).toDate(), true)}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="End Date"
                    dropDownMode="select"
                    className="form-control"
                    customInput={<DatePickerCustomInput />}
                  />
                </div>
              </Col>
            </Row>) : null
          }
          {showCustomDay ? (
            <Row>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date) || setValue('startDate', date, true) || setEndDate(date) || setValue('endDate', date, true)}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    className="form-control"
                    customInput={<DatePickerCustomInput />}
                  />
                </div>
              </Col>
            </Row>) : null
          }
        </Col>
      </Row>
      <Row style={{ minWidth: '300px' }}>
        <Col>
          <h5 style={{ color: 'black', marginBottom: '8px' }} className="bold-text">{getText()}</h5>
        </Col>
      </Row>
    </div>
  );
};
FilterNew.propTypes = {
  setValue: PropTypes.func.isRequired,
  showByDate: PropTypes.bool,
  startDateLimit: PropTypes.objectOf(PropTypes.any),
  showLast6Month: PropTypes.bool,
};
FilterNew.defaultProps = {
  showByDate: false,
  startDateLimit: null,
  showLast6Month: false,
};
export default FilterNew;
