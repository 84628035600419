// import { unflatten } from 'flat';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, FormGroup, FormFeedback } from 'reactstrap';
import { axios } from 'ApiClient';

const MediaUrlInList = ({ record, setVersion, addNotice }) => {
  const key = 'mediaUrl';
  const recordUnflatten = record;
  const rowData = recordUnflatten.params;
  let val = '';
  if (rowData[key] && rowData[key] !== null) val = rowData[key];
  const [value, setValue] = useState(val);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => setValue(val), [val]);

  const handleChange = async (newValue) => {
    if (rowData[key] !== newValue) {
      setError(null);
      setDisabled(true);
      let finalUrl = newValue;
      // https://www.youtube.com/live/-WiNqCvvQSw?si=-BPuF-WQG9mYOQS1&t=792
      // https://youtube.com/watch?v=-WiNqCvvQSw&t=792s
      if (newValue && newValue.includes('/live/')) {
        const urlArr = newValue.split('?');
        if (urlArr.length > 1) {
          finalUrl = urlArr[0].replace('/live/', '/watch?v=');
        }
        if (newValue && newValue.includes('&t=')) {
          const timeRange = newValue.split('&t=');
          finalUrl = finalUrl.concat(`&t=${timeRange[1]}`);
        }
      }

      setValue(finalUrl);
      try {
        const res = await axios.post('/api/portal/scorecard/media-content/update-media-url', {
          id: record.id,
          url: finalUrl,
        });
        if (!res.data.success) {
          setValue(rowData[key]);
          setError(res.data.error);
        } else {
          setValue(finalUrl);
          setVersion((new Date()).toString());
          addNotice({
            message: 'Content URL was updated successfully',
            type: 'success',
          });
          if (res.data.message) {
            addNotice({
              message: 'Unable to get video data from YouTube with provided URL. Please verify the URL is valid.',
              type: 'warning',
            });
          }
        }
      } catch (e) {
        setDisabled(false);
      }
      setDisabled(false);
    }
    return true;
  };

  return (
    <FormGroup>
      <Input
        type="text"
        id="mediaUrl"
        name="mediaUrl"
        placeholder="Media URL"
        onChange={e => setValue(e.target.value)}
        value={value}
        onBlur={() => handleChange(value)}
        disabled={disabled}
        style={{ width: '250px' }}
        invalid={!!error}
      />
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};
MediaUrlInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};
export default MediaUrlInList;
