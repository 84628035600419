import React, { useState } from 'react';

// import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
// import IconButton from '@material-ui/core/IconButton';
// import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';

import { Button, Modal, ButtonToolbar } from 'reactstrap';


const GenerationInfoV2 = () => {
  const [showReport, setShowReport] = useState(false);
  return (
    <div className="float-right mt-1">
      <Tooltip title="View details about how this report is generated">
        <IconButton
          size="small"
          onClick={() => setShowReport(true)}
          className="material-table__toolbar-button"
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </IconButton>
      </Tooltip>
      <Modal
        isOpen={showReport}
        toggle={() => setShowReport(false)}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Report Details</h4>
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body ltr-support">
          <div className="text-modal  modal__title">GROSS PROFIT Calculations(One of the item is right) </div>
          <div className="pb-3">
            <ul>
              <li>CPA
                <ul className="list">
                  <li>Status = Active</li>
                  <li>Status = Pending && Brand Approved Date in specified date range</li>
                </ul>
              </li>
              <li>Upfront Only & Upfront CPM
                <ul className="list">
                  <li>Brand Approved Date is in specified date range</li>
                  <li>Status = Active</li>
                </ul>
              </li>
              <li>Hybrid
                <ul className="list">
                  <li>Deal Stage is Deal Paid OR Published and Brand Approved Date is in specified date range</li>
                  <li>Status = Active</li>
                </ul>
              </li>
              <li>Bonus
                <ul className="list">
                  <li>Content Release Date is in specified date range</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button color="danger" onClick={() => setShowReport(false)}>Close</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};
GenerationInfoV2.propTypes = {
  /* startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  estimatePrevious: PropTypes.bool.isRequired,
  includePrevious: PropTypes.bool.isRequired,
  isByDate: PropTypes.bool.isRequired,
  selectedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired, */
};
export default GenerationInfoV2;
