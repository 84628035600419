import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Modal,
  ModalHeader,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import Select from 'react-select';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Hook from '../../../../../../../../shared/hooks';
import RadioButton from '../../../../../../../../shared/components/RadioButton';
import WVFormatter from '../../../../../../../../shared/helpers/WVFormatter';

const modalId = 'UpdateStatusOfSelectedProspectsModal';

const CustomCheckbox = withStyles({
  root: {
    color: '#70bbfd',
    '&$checked': {
      color: '#70bbfd',
    },
  },
  checked: {},
})(React.forwardRef((props, ref) => <Checkbox color="default" size="small" {...props} ref={ref} />));

const UpdateStatusOfSelectedProspectsModal = () => {
  const { showError, showSuccess } = Hook.useNoticeHook();
  const ilStatusOptions = [
    { value: 'approved', label: 'Approved' },
    { value: 'inReview', label: 'In Review' },
    { value: 'pendingEdits', label: 'Pending Edits' },
    { value: 'hold', label: 'Hold' },
    { value: 'rejected', label: 'Rejected' },
  ];

  const { data: dataModal, showModal: isOpen, close } = Hook.useModalHook(modalId);

  console.log(dataModal, 'DATA MMODAL');

  const {
    selectedIds,
    refresh,
  } = dataModal;

  const metaTotal = useSelector(state => state.tableRedux.total);
  const url = useSelector(state => state.tableRedux.tableFilterUrl);

  const [loading, setLoading] = useState(false);
  const [updateILOption, setUpdateILOption] = useState(false);
  const [selectedILOption, setSelectedILOption] = useState({ value: 'inReview', label: 'In Review' });
  const [updateBrandOption, setUpdateBrandOption] = useState(false);
  const [selectedBrandOption, setSelectedBrandOption] = useState({ value: 'inReview', label: 'In Review' });
  const [includeAll, setIncludeAll] = useState(false);

  const updateSelectedProspectsStatuses = async (updateILStatus = false, ilStatus, updateBrandStatus = false, brandStatus) => {
    try {
      setLoading(true);
      const response = await axios.post('/api/portal/prospects/post-bulk-update-cpm-statuses', {
        prospectIds: selectedIds,
        updateILStatus,
        ilStatus,
        updateBrandStatus,
        brandStatus,
        includeAll,
        url,
      });
      if (response && response.data && response.data.success) {
        refresh();
      } else {
        if (response && response.data && response.data.error) {
          console.log(response.data.error);
        }
        showError('Failed to update selected prospects. Please refresh the page and try again');
      }
      setLoading(false);
      close();
      showSuccess('Successfully updated selected items');
    } catch (error) {
      console.log(error);
      showError('Failed to update selected prospects. Please refresh the page and try again');
      close();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setUpdateILOption(false);
      setSelectedILOption({ value: 'inReview', label: 'In Review' });
      setUpdateBrandOption(false);
      setSelectedBrandOption({ value: 'inReview', label: 'In Review' });
      setIncludeAll(false);
    }
  }, [isOpen]);

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => close()}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
      size="lg"
      style={{
        maxWidth: '900px',
        fontSize: '105%',
        fontStretch: '90%',
      }}
    >
      <ModalHeader
        toggle={() => {
          close();
        }}
        tag="h4"
        className=" pl-0"
      >
        Update Status
      </ModalHeader>
      <div
        className="modal__body mt-0 mb-3"
      >
        <h4 className="py-2">You have selected {selectedIds?.length || 0} CPM Deals.</h4>
        <div className="theme-light radio-in-prospects">
          {`Select all of ${WVFormatter.formatIntNumber(metaTotal)} CPM Deals?`}&nbsp;&nbsp;&nbsp;
          <RadioButton
            name="action"
            label="&nbsp;Yes"
            value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
            radioValue="includeAll"
            onChange={(e) => {
              setIncludeAll(e === 'includeAll');
            }}
            disabled={Number(metaTotal || 0) > 100}
          />
          <RadioButton
            name="action"
            label="&nbsp;No"
            value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
            radioValue="notIncludeAll"
            onChange={(e) => {
              setIncludeAll(e === 'includeAll');
            }}
            isabled={Number(metaTotal || 0) > 100}
          />
        </div>
        <h4 className="py-2">What status(es) would you like to update?</h4>
        <div className="d-flex">
          <CustomCheckbox
            id="updateInfluenceLogicStatus"
            name="ilCheckbox"
            label=""
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            checked={updateILOption}
            onClick={e => setUpdateILOption(!!e?.target?.checked)}
          />
          <span className="bold-text" style={{ paddingTop: '5px' }}>Update IL PA Status?</span>
        </div>
        <div
          className="w-100 research-watchlists"
          style={{ paddingTop: '6px', paddingLeft: '38px', paddingRight: '15px' }}
        >
          <Select
            isDisabled={!updateILOption}
            name="ilStatusSelect"
            value={selectedILOption}
            options={ilStatusOptions}
            onChange={setSelectedILOption}
            styles={colourStyles}
          />
        </div>
      </div>
      <div
        className="modal__body mt-3 mb-3"
      >
        <div className="d-flex">
          <CustomCheckbox
            name="brandCheckbox"
            label=""
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            checked={updateBrandOption}
            onClick={e => setUpdateBrandOption(!!e?.target?.checked)}
          />
          <span className="bold-text" style={{ paddingTop: '6px' }}>Update Brand PA Status?</span>
        </div>
        <div
          className="w-100 research-watchlists"
          style={{ paddingTop: '6px', paddingLeft: '38px', paddingRight: '15px' }}
        >
          <Select
            isDisabled={!updateBrandOption}
            name="brandStatusSelect"
            value={selectedBrandOption}
            options={ilStatusOptions}
            onChange={setSelectedBrandOption}
            styles={colourStyles}
          />
        </div>
      </div>
      <ButtonToolbar className="modal__footer mt-3">
        <Button
          color="primary"
          disabled={!(updateILOption || updateBrandOption) || loading}
          onClick={() => updateSelectedProspectsStatuses(updateILOption, selectedILOption.value, updateBrandOption, selectedBrandOption.value)}
        >
          Update
        </Button>
        <Button color="secondary" onClick={() => close()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

UpdateStatusOfSelectedProspectsModal.propTypes = {
  // addNotice: PropTypes.func.isRequired,
};


UpdateStatusOfSelectedProspectsModal.defaultProps = {
};


export default UpdateStatusOfSelectedProspectsModal;
