import React from 'react';
import PropTypes from 'prop-types';
import TableCellContextMenu from '../../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';

const ContextMenuInList = ({
  record,
  edit,
  openRoster
}) => {
  const contextMenuOptions = [
    { label: 'View Agency Roster', handler: () => { openRoster(record.id); } },
    { label: 'Edit', handler: () => edit(record.id) },
  ];

  return (
    <TableCellContextMenu options={contextMenuOptions} />
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  edit: PropTypes.func.isRequired,
  openRoster: PropTypes.func.isRequired,
};

export default ContextMenuInList;
