/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../../../shared/components/ButtonWithSpinner';

const DeleteProspectUploadModal = ({
  modal,
  setModalVisible,
  loading,
  deleteUpload,
}) => (
  <Modal
    isOpen={modal}
    toggle={() => setModalVisible(false)}
    className="delete-modal"
    size="sm"
  >
    <ModalHeader toggle={() => setModalVisible(false)} >
      Delete Prospect Upload
    </ModalHeader>
    <ModalBody>
      Are you sure you want to delete the selected Prospect Upload?
    </ModalBody>
    <ModalFooter>
      <ButtonWithSpinner
        className="btn-sm"
        type="button"
        color="danger"
        onClick={deleteUpload}
        loading={loading}
        disabled={loading}
      >
        <span>Delete</span>
      </ButtonWithSpinner>{' '}
      <Button
        className="btn-sm"
        color="secondary"
        onClick={() => setModalVisible(false)}
        disabled={loading}
      >
        Cancel
      </Button>
    </ModalFooter>
  </Modal>
);

DeleteProspectUploadModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  deleteUpload: PropTypes.func.isRequired,
};

DeleteProspectUploadModal.defaultProps = {

};

export default DeleteProspectUploadModal;
