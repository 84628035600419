// import { unflatten } from 'flat';
import React, { useEffect, useState } from 'react';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Select from 'react-select';
import { getConstant } from '../../../../../../../shared/helpers/WVConstants';

const BrandStatus = React.memo(({
  record,
  afterSaveFunc,
  setShowNotesModal,
  setCurrentProspect,
  setDisableNote,
  setPortal,
  updateRecordFunction,
}) => {
  // const key = 'approvalStatus.brand.status';
  // const notesKey = 'approvalStatus.brand.notes';

  const rowData = record.params;
  const val = (typeof rowData !== 'undefined' && typeof rowData.approvalStatus.brand?.status !== 'undefined') ? rowData.approvalStatus.brand?.status : '';
  const notesVal = (typeof rowData !== 'undefined' && typeof rowData.approvalStatus.brand?.notes !== 'undefined') ? rowData.approvalStatus.brand?.notes : '';
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(val);
  const aValues = getConstant('prospect', 'brandStatuses', {});

  const options = Object.keys(aValues).map(k => ({ value: k, label: aValues[k] }));

  useEffect(() => {
    setValue(val);
  }, [record.id, val]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option.value);
    try {
      const data = await axios.post('/api/portal/prospects/post-update-status', {
        prospectIds: [record.id],
        status: option.value,
        section: 'brand',
      });
      if (data?.data?.prospectPopulated && updateRecordFunction) {
        updateRecordFunction(data?.data?.prospectPopulated);
      } else {
        updateRecordFunction(record.id, { approvalStatus: { brand: { status: option.value } } });
        afterSaveFunc();
      }
    } catch (e) {
      console.log(e);
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };

  return (
    <div style={{ width: '150px' }}>
      <div style={{ width: '120px', float: 'left' }} >
        <Select
          value={options.filter(option => option.value === value)}
          options={options}
          onChange={handleChange}
          className="brandStatusBox"
          placeholder="Brand Status"
          isDisabled={disabled || rowData.inBlackList}
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
        {error ? (<span className="text-danger">{error}</span>) : null}
      </div>
      <div style={{ marginTop: '6px', marginRight: '6px', float: 'right' }}>
        <Tooltip title="Brand Notes">
          <IconButton
            size="small"
            onClick={() => { setPortal('brand'); setDisableNote(false); setCurrentProspect(record); setShowNotesModal(true); }}
          >
            <FontAwesomeIcon icon={[notesVal !== '' ? 'fas' : 'far', 'file-alt']} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

BrandStatus.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  afterSaveFunc: PropTypes.func.isRequired,
  setShowNotesModal: PropTypes.func.isRequired,
  setCurrentProspect: PropTypes.func.isRequired,
  setDisableNote: PropTypes.func.isRequired,
  setPortal: PropTypes.func.isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};
export default BrandStatus;
