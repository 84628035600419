import React, { useState, useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  Input,
  Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Hook from 'ILHooks';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { NumericFormat as NumberFormat } from 'react-number-format';
import IconButton from '@material-ui/core/IconButton';
import { uid } from 'react-uid';
import Tooltip from '@material-ui/core/Tooltip';
import { getConstant } from '@/shared/helpers/WVConstants';
import DownloadCSVButton from '@/shared/tables/table/DownloadCSVButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@/shared/easydev2/shared/components/Alert';
import CheckBox from '@/shared/components/CheckBox';


export const modalId = 'createBulkDealModal';
/* const justifications = [
  { label: 'Test', value: 'Test' },
  { label: 'Rebuy, Actual CAC', value: 'Rebuy, Actual CAC' },
  { label: 'Rebuy, Predicted CAC', value: 'Rebuy, Predicted CAC' },
  { label: 'Price Adjusted Rebuy to Actual', value: 'Price Adjusted Rebuy to Actual' },
  { label: 'Price Adjusted Rebuy to Predicted', value: 'Price Adjusted Rebuy to Predicted' },
  { label: 'Marginal Rebuy, Actual CAC', value: 'Marginal Rebuy, Actual CAC' },
  { label: 'Marginal Rebuy, Predicted CAC', value: 'Marginal Rebuy, Predicted CAC' },
]; */

const Item = ({ items }) => (
  <div
    style={{
      padding: '15px 15px',
      border: '1px solid #cccccc',
      borderRadius: '5px',
      height: '100%',
    }}
  >
    <Row>
      {items.map(({ label, value }) => (
        <Col key={uid(label)}>
          <div style={{ textAlign: 'center', color: 'black', fontWeight: 'bold' }} >
            <span style={{ width: '100%', fontSize: '16px' }}>{label}</span>
            <span
              style={{
                fontSize: '20px',
                fontWeight: '700',
                marginBottom: '12px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {value}
            </span>
          </div>
        </Col>
      ))}
    </Row>
  </div>);
Item.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.any),
    ]).isRequired,
  })).isRequired,
};


const DealItem = ({
  deal, setValue, templeteDeal, index, removeValue, validateDeals, agents,
}) => {
  console.log(templeteDeal);
  const justifications = getConstant('deal', 'dealJustification').map(a => ({ value: a, label: a }));
  const {
    dealname, content_release_date, amount, dealstage, justification, notes, error
  } = deal;
  const [errors, setErrors] = useState({})
  const [agentObject, setAgentObject] = useState(null)
  const [isAgent, setIsAgent] = useState(null)
  const loadAgentsOptions = async (inputValue) => {
    if (!inputValue) return agents.map(record => ({
      value: record._id,
      label: record.email !== null ? record.email : `${record.first_name} ${record.last_name}`,
      record,
    }));
    const response = inputValue ? await axios.get('/api/portal/agent/get-agent-search', {
      params: {
        value: inputValue,
      },
    }) : { records: [] };
    const contacts = response.data.records.map(record => ({
      value: record.id,
      label: record.params.email !== null ? record.params.email : `${record.params.first_name} ${record.params.last_name}`,
      record,
    }));
    return contacts;
  }
  const onChange = (key, value) => {
    const requiredKeys = ['dealname', 'content_release_date', 'amount', 'dealstage', 'justification']
    ;
    setValue({ [key]: value });
    if(requiredKeys.includes(key) && !value) {
      setErrors(e => ({ ...e, [key]: true }));
    } else {
      setErrors(e => ({ ...e, [key]: false}));
    }
    setTimeout(() => validateDeals(), 100);
  };

  const pipelines = getConstant('deal', 'pipelines', []);
  const pipeline = pipelines.find(p => String(p.id) === String(templeteDeal.pipeline) )
  const dealStageOptons = Object.keys(pipeline.stages)?.map(p => ({
    value: p,
    label: pipeline.stages[p],
  }));
  useEffect(() => {
    const dealName = `${templeteDeal.influencerObject.first_name} ${templeteDeal.influencerObject.last_name} - ${templeteDeal.offer.name} ${moment(content_release_date).format('MMMM YYYY')}`;
    onChange('dealname', dealName)

  }, [content_release_date]);
  const isUpfrontCpm = templeteDeal.dealTerms.type_of_deal === 'Upfront CPM';
  const showError = (key) => {
    console.log(errors, 'errors')
    if(errors[key] === true) {
      return <span className="text-danger">This Field is Required</span>
    }
    return null;
  }
  useEffect(() => {
    const agent = agents?.length > 0 ? agents[0] : null;
    // const dealName = `${templeteDeal.influencerObject.first_name} ${templeteDeal.influencerObject.last_name} - ${templeteDeal.offer.name} ${moment(content_release_date).format('MMMM YYYY')}`;
    // onChange('agent', agent?._id)
    if (agent) {
      setIsAgent(true);
      setAgentObject({ value: agent._id, label: `${agent.first_name} ${agent.last_name}`})
    } else {
      setIsAgent(false);
    }



  }, [agents])
  useEffect(() => {
    console.log(agentObject, 'agentObject');
    if (!isAgent) {
      onChange('agent', null)
    } else {
      onChange('agent', agentObject?.value)
    }
  }, [agentObject, isAgent])
  const dealStageToShow = [
    dealStageOptons.find(a => a.label === 'New Lead') || {},
    dealStageOptons.find(a => a.label === 'Initial Outreach') || {},
    dealStageOptons.find(a => a.label === 'In Negotiations') || {},
    dealStageOptons.find(a => a.label === 'Offer Sent') || {},
    ...(isUpfrontCpm ? [dealStageOptons.find(a => a.label === 'Pricing Review') || {}] : [])
  ];
  useEffect(() => {
    const PRStage = dealStageOptons.find(a => a.label === 'Pricing Review');
    const newStage = dealStageOptons.find(a => a.label === 'New Lead');
    if (isUpfrontCpm && PRStage) {
      onChange('dealstage', PRStage.value)
    } else if (!isUpfrontCpm && newStage) {
      onChange('dealstage', newStage.value)
    }
  }, [])
  console.log(dealStageToShow, 'dealStageToShow')
  return (
    <Row className="mt-3">
      <Col>
        {error ? (<Alert color="danger"><p>{error || 'Unable to Validate Form'}</p></Alert>) : null}
        <Row>
          <Col sm="auto">
            <CheckBox
              label="Is deal related to an Agent?"
              value={isAgent}
              onChange={(e) => e.target && setIsAgent(e.target.checked)}
              className=" mt-2"
            />
          </Col>
          <Col>
            {isAgent ? <SelectAsync
              value={agentObject}
              defaultOptions
              loadOptions={loadAgentsOptions}
              onChange={(selected) => setAgentObject(selected)}
              placeholder="Please Select Agent Hubspot Contact"
              isClearable
            /> : null}
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <Label className="my-0"><small>Content Release Date</small></Label>
            <DatePicker
              selected={moment(content_release_date).toDate()}
              onChange={date => onChange('content_release_date', moment(date).format('YYYY-MM-DD'))}
              dateFormat="MM/dd/yyyy"
              // maxDate={new Date()}
              // minDate={minDate.toDate()}
              placeholderText="Content Release Date"
              dropDownMode="select"
              className="form-control"
              wrapperClassName="mb-0"

            />
            {showError('content_release_date')}
          </Col>
          <Col sm='9'>
            <Label className="my-0"><small>Deal Name</small></Label>
            <Input value={dealname} onChange={(e) => onChange('dealname', e.target.value) } />
            {showError('dealname')}
          </Col>
          <Col sm='6'>
            <Label className="my-0">
              <small>Deal Amount</small>
              <Tooltip title="Guarantee Payment Contract Amount">
                <IconButton
                  size="small"
                  style={{ fontSize: '13px' }}
                >
                  <i className="fa fa-info-circle" />
                </IconButton>
              </Tooltip>
            </Label>
            <NumberFormat
              invalid
              thousandSeparator
              prefix="$"
              name="amount"
              value={amount}
              className="form-control"
              inputMode="numeric"
              // allowEmptyFormatting
              fixedDecimalScale
              decimalScale={2}
              onValueChange={(values) => {
                onChange('amount', values.floatValue);
              }}
            />
            {showError('amount')}
          </Col>
          <Col sm='6'>
            <Label className="my-0"><small>Deal Stage (select "Pricing Review" to submit for pricing approval)</small></Label>
            <small></small>
            <Select
              value={dealStageOptons.find(d => String(d.value) === String(dealstage))}
              options={dealStageToShow.filter(a => a?.value)}
              onChange={value => onChange('dealstage', value.value)}
              placeholder="Deal Stage..."
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  height: '38px',
                  fontSize: '16px',
                }),
                menu: baseStyles => ({
                  ...baseStyles,
                  fontSize: '14px',
                }),
              }}
            />
            {showError('dealstage')}
          </Col>
          {isUpfrontCpm ? (<Col sm='6'>
            <Label className="my-0"><small>Justification</small></Label>
            <Select
              value={justifications.find(j => j.value === justification)}
              options={justifications}
              onChange={value => onChange('justification', value.value)}
              placeholder="Justification..."
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  height: '38px',
                  fontSize: '16px',
                }),
                menu: baseStyles => ({
                  ...baseStyles,
                  fontSize: '14px',
                }),
              }}
            />
            {showError('justification')}
          </Col>) : null}
          {isUpfrontCpm ? (<Col sm='6'>
            <Label className="my-0"><small>Notes (optional)</small> </Label>
            <Input value={notes} onChange={(e) => onChange('notes', e.target.value) } />
          </Col>) : null}


        </Row>
      </Col>
      <Col sm="auto" className="pt-3">
        <IconButton
          size="small"
          onClick={() => removeValue(index)}
          className="material-table__toolbar-button mt-3"
        >
          <i className="fa fa-times text-danger" />
        </IconButton>
      </Col>
      <Col xs="12"><hr /></Col>
    </Row>
  );
};


DealItem.propTypes = {
  deal: PropTypes.objectOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired,
};



const BulkDealCreationModal = () => {
  const { showError } = Hook.useNoticeHook();
  const [deals, setDeals] = useState({});
  const [createdDeals, setCreatedDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [maxNumber, setMaxNumber] = useState(10);
  const list = useMemo(() => {
    console.log(deals)
    return Object.keys(deals).map(key => ({ ...deals[key], key }));
  }, [deals]);
  const setValue = (index, data, isNew) => {
    setDeals(s => {
      console.log(data, s);
      const settingsClone = { ...s };
      return { ...s, [index]: { ...settingsClone[index], ...data } };
    }
    );
  };

  const deleteValue = useCallback((index) => {
    const dealsClone = { ...(deals || {}) };
    delete dealsClone[index];
    setDeals(s => ({ ...dealsClone }));
  }, [deals, setDeals]);

  const { data: dataModal, showModal: isOpen, close } = Hook.useModalHook(modalId);
  useEffect(async () => {
    if(!isOpen) return false;
    try {
      const a = await axios.post('/api/portal/reports/post-get-deal-creation-settings');
      const number = a.data.maxNumber;
      setMaxNumber(Number(number))
    } catch (e) {
      showError(e.message || 'Unable to Get Deal Creation Settings')
      console.log(e);
    }
  }, [isOpen]);

  if (!dataModal || !dataModal.deal) return false;
  const { deal, offer, agents } = dataModal;
  console.log(offer, 'offerofferoffer');



  /*
  {
    content_release_date, dealname, amount, dealstage, justification, note
  }
  */

  const pipelines = getConstant('deal', 'pipelines', []);
  const pipeline = pipelines.find(p => String(p.id) === String(deal.pipeline) )
  console.log(list, 'list')
  const validateDeal = async (templeteDeal, dealObject) => {
    try{
      const requiredKeys = ['dealname', 'content_release_date', 'amount', 'dealstage'];
      const isValid = requiredKeys.every(a => dealObject[a] && String(dealObject[a]).trim());
      console.log(isValid, 'isValid')
      if (!isValid) {
        throw new Error('Missing Required Fields')
      }
      if (templeteDeal.dealTerms.type_of_deal === 'Upfront CPM' && !dealObject.justification) {
        throw new Error('Missing Justification')
      }
      return true;
    } catch (e) {
      return e.message;
    }
  };

  const validateDeals = async () => {
    const dealsTovalidate = Object.keys(deals);
    console.log(dealsTovalidate, 'Deals Keys');
    let totalValid = true;
    const dealsTo = { ...deals };
    for (let index = 0; index < dealsTovalidate.length; index++) {
      const indexDeal = dealsTovalidate[index];
      const element = deals[indexDeal];
      console.log(element, 'el')
      try {
        const valid = await validateDeal(deal, element);
        console.log(valid, 'valid');
        if(valid === true) {
          element['error'] = null;
          dealsTo[indexDeal] = element;
        } else {
          element['error'] = valid;
          dealsTo[indexDeal] = element;
          totalValid = false;
        }
      } catch(e) {

      }

    }
    setDeals(dealsTo)
    return totalValid;
  };

  const saveDeal = async (templateDealId, dealObject) => {
    try{
      const createdDeal = await axios.post('/api/portal/reports/post-create-deal-rebuy', { templateDealId, dealObject })
      if (createdDeal.data.success) return createdDeal.data.deal;

      throw new Error(createdDeal.data.error || 'Unable to Create Deal');
    } catch (e) {
      throw new Error(e.message);
    }
  };
  const saveDeals = async () => {

    const totalValid = await validateDeals();
    console.log(totalValid, 'totalValid')
    if(!totalValid){
      return false;
    }
    const dealsToSave = Object.values(deals);
    setLoading(true);

    for (let index = 0; index < dealsToSave.length; index++) {
      const element = dealsToSave[index];
      try {
        const createdDealResp = await saveDeal(deal._id, element);
        setCreatedDeals((s) => ([...s, { createdDeal: createdDealResp, error: null, dealObject: element }]))
      } catch(e) {
        setCreatedDeals((s) => ([...s, { createdDeal: null, error: e.message, dealObject: element }]))
      }

    }
    setLoading(false);
  };
  const closeModal = () => {
    setLoading(false);
    setDeals({});
    setCreatedDeals([])
    close();
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeModal()}
      style={{ minWidth: '1000px' }}
      className="ltr-support theme-light"
    >
      <ModalHeader
        toggle={() => closeModal()}
        tag="h4"
        className="modal-title-statistics pl-0 "
      >
        Create Deals
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="">
            <Item
              items={[
                {
                  label: 'Pipeline',
                  value: (<h4>{pipeline.label}</h4>),
                },
                {
                  label: 'DealType',
                  value: (<h4>{deal.dealTerms.type_of_deal}</h4>),
                },
                {
                  label: 'Deal Owner',
                  value: (<h4>{deal.adminUserObject.firstName} {deal.adminUserObject.lastName}</h4>),
                },
                {
                  label: 'Brand',
                  value: (<h4>{offer.advertiser.companyName}</h4>),
                },
                {
                  label: 'Agents',
                  value: (<div>
                    {agents?.length > 0 ? agents.map(agent => (<h4>{agent.first_name} {agent.last_name}</h4>)) : 'No Agents'}
                    </div>),
                },
                {
                  label: 'Promo Code',
                  value: (<h4>{deal.dealTerms.coupon_code}</h4>),
                },
              ]}
            />
          </Col>
        </Row>
        {loading || createdDeals?.length > 0 ? (
          <div className="mt-3">
            <LinearProgress variant="determinate" value={Math.ceil(createdDeals.length / Object.keys(deals).length  * 100)} />
            <ul className="mt-3">
              {createdDeals.map(a => (<li key={uid(a.dealObject)} className="my-2">Deal: <strong>{a.dealObject.dealname}</strong> {a.createdDeal ? ' was Created Successfully' : ` received error ${a.error}`}</li>))}
            </ul>

            <DownloadCSVButton
              records={createdDeals}
              className="mt-0"
              fileName="DealResults.csv"
              csvArrayColumns={[
                {
                  label: 'Deal Name',
                  func: p => `${p.dealObject.dealname}`,
                },
                {
                  label: 'Deal Id',
                  func: p => `${p.createdDeal?._id}`,
                },
                {
                  label: 'Deal HS Id',
                  func: p => `${p.createdDeal?.hs_object_id}`,
                },
                {
                  label: 'Deal Link',
                  func: p => p.createdDeal ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.createdDeal?.hs_object_id}` : null,
                },
                {
                  label: 'Creator Name',
                  func: p => `${p.createdDeal?.influencerObject?.first_name || ''} ${p.createdDeal?.influencerObject?.last_name || ''}`,
                },
                {
                  label: 'Creator Email',
                  func: p => `${p.createdDeal?.influencerObject?.email || ''}`,
                },
                {
                  label: 'Deal Owner Name',
                  func: p => `${p.createdDeal?.adminUserObject?.firstName || ''} ${p.createdDeal?.adminUserObject?.lastName || ''}`,
                },
                {
                  label: 'Deal Owner Email',
                  func: p => `${p.createdDeal?.adminUserObject?.email || ''}`,
                },
                {
                  label: 'Error',
                  func: p => p.error || '',
                },
              ]}
            >
              <i className="fa fa-download color-white" /> Download Results
            </DownloadCSVButton>
          </div>
        ) : (
          <Row>
            <Col className="">
              {Object.keys(deals).length > 0 ? (<Alert color={Object.keys(deals).length=== maxNumber ? 'warning' : 'info'} className="mt-2"><p>Creating <strong>{Object.keys(deals).length}</strong> deal(s). Remaining <strong>{maxNumber - Object.keys(deals).length}</strong> </p></Alert>) : null}
              <Button
                onClick={async () => {
                  setValue(moment().format('x'), {
                    content_release_date: moment().format('YYYY-MM-DD'), dealname: '', amount: 0, dealstage: null, justification: null, notes: '', isAgent: false, agent: null,
                  }, true);
                }}
                disabled={Object.keys(deals).length >= maxNumber}
                className="btn-sm mt-3"
                color="primary"
              >
                <i className="fa fa-plus color-white" /> Add Deal
              </Button>
              {list.map(item => console.log(item) || (<DealItem deal={item} removeValue={deleteValue} setValue={e => setValue(item.key, e)} index={item.key} templeteDeal={deal} validateDeals={() => validateDeals} agents={agents} />))}
            </Col>
          </Row>
        )
      }
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="btn-sm" color="primary" onClick={() => saveDeals()} disabled={Object.keys(deals).length === 0 || loading || createdDeals.length > 0 }>Save Deals</Button>
        <Button className="modal_cancel btn-sm ml-2" onClick={() => closeModal()}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

export default BulkDealCreationModal;

