import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Modal,
  ModalHeader,
} from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import withNotice from '../../../../../../App/store/with-notice';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';

export const modalId = 'refreshCPMProspectFromOrder';

const RefreshStatisticsOptionsModal = ({
  updateTable,
  addNotice,
}) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState({ value: false, label: 'No, do not overwrite' });
  const [loading, setLoading] = useState(false);
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  if (!modalInfo) return false;
  console.log(modalInfo);
  
  const { data: { record }, isShow } = modalInfo;
  const close = () => dispatch(hideModalById(modalId));

  const onRefreshFunc = async (id, overwriteChannelNameAndURL) => {
    try {
      setLoading(true);
      const response = await axios.post('/api/portal/prospects/post-fetch-data-on-cpm-prospects', {
        prospectsIds: [id],
        overwriteChannelNameAndURL,
        identity: Math.random(),
      });
      if (response.data.success) {
        addNotice({
          message: `Successfully refreshed ${response.data.numberOfProspects} of ${response.data.numberOfProspects} items.`,
          type: 'success',
        });
      } else {
        addNotice({
          message: `Successfully refreshed ${response.data.successfulRefreshed} of ${response.data.numberOfProspects} items.`,
          type: 'error',
          duration: 10,
        });
      }
      setLoading(false);
      updateTable();
      close();
      setSelectedOption({ value: false, label: 'No, do not overwrite' });
    } catch (e) {
      console.log(e);
    }
    return true;
  };


  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
      size="lg"
      style={{
        maxWidth: '900px',
        fontSize: '105%',
        fontStretch: '90%',
      }}
    >
      <ModalHeader toggle={close} tag="h4">
        Refresh Statistics
      </ModalHeader>
      <div
        className="modal__body mt-3 mb-3"
      >
        <p>
          The CPM prospect&apos;s channel titles, URLs and email may have been edited already and can possibly be updated when refreshing data from Tubular and ListenNotes.
          <br />
          Would you like to allow overwriting of this data?
        </p>
        <div className="w-100 mt-2">
          <Select
            name="select"
            value={selectedOption}
            options={
              [
                { value: false, label: 'No, do not overwrite' },
                { value: true, label: 'Yes, overwrite' },
              ]
            }
            onChange={setSelectedOption}
            styles={colourStyles}
          />
        </div>
      </div>
      <ButtonToolbar className="modal__footer mt-3">
        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            onRefreshFunc(record.id, selectedOption.value);
            // close();
            // setSelectedOption({ value: false, label: 'No, do not overwrite' });
          }}
        >
          Refresh
        </Button>
        <Button color="secondary" onClick={close}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

RefreshStatisticsOptionsModal.propTypes = {
  // isOpen: PropTypes.bool,
  // toggleModal: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  // prospectId: PropTypes.string,
};

/* RefreshStatisticsOptionsModal.defaultProps = {
  isOpen: false,
  prospectId: '',
}; */


export default withNotice(RefreshStatisticsOptionsModal);
