import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class OverlapReportTitleCell extends React.Component {
  render() {
    const { record } = this.props;

    let title = '';
    if (record.details) {
      // eslint-disable-next-line prefer-destructuring
      title = record.details.title;
    }

    let channelURL = '';
    if (record.creator && record.creator.accounts) {
      record.creator.accounts.forEach((acc) => {
        if (acc.platform && acc.platform === 'youtube') {
          channelURL = acc.account_url;
        }
      });
    }

    return (
      <span>{ title } {channelURL && (<a href={channelURL} target="_blank" rel="noreferrer"><img alt="youtube" src="/images/mediacontent/youtube.png" style={{ maxWidth: '15px' }} /></a>)}</span>
    );
  }
}

OverlapReportTitleCell.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OverlapReportTitleCell;
