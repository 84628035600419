// import { unflatten } from 'flat';
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { axios } from 'ApiClient';
import { unflatten } from 'flat';
import { getConstant } from '../../../../../../../../shared/helpers/WVConstants';

const ApprovalStatus = React.memo(({
  record,
  afterSaveFunc,
  updateRecordFunction,
}) => {
  const statuses = getConstant('prospect', 'cpmInsertionOrderStatus');

  const rowData = record.params;
  const val = rowData?.status?.status || '';
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const options = useMemo(() => statuses.map(k => ({ value: k, label: k })), []);
  useEffect(() => {
    setValue(val);
  }, [record.id, val]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option.value);
    try {
      const data = await axios.post('/api/portal/prospects/post-cpm-price-approvals-update', {
        ids: [record.id],
        values: {
          status: option.value,
        },
      });
      if (data?.data?.recordPopulated && updateRecordFunction) {
        updateRecordFunction(unflatten(data?.data?.recordPopulated));
      } else {
        updateRecordFunction(record.id);
        afterSaveFunc();
      }
    } catch (e) {
      console.log(e);
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };
  return (
    <div className="w-100">
      <Select
        value={options.filter(option => option.value === value)}
        options={options}
        onChange={handleChange}
        className="statusBox"
        placeholder="Approval Status"
        isDisabled={disabled}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
      {error ? (<span className="text-danger">{error}</span>) : null}
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

ApprovalStatus.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  afterSaveFunc: PropTypes.func.isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};

export default ApprovalStatus;
