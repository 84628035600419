/* eslint react/prop-types: 0 */
import React, { useMemo } from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import getCurrentChartData from './CalculateForTables';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import { formatIntNumber } from '../../../../shared/helpers/WVFormatter';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  isFreezeHeader: true,
  withPagination: true,
  withSearchEngine: true,
  manualPageSize: [10, 20, 30, 40],
  placeholder: 'Search...',
  sortBy: 'date',
  direction: 'asc',
  useFlex: true,
};

const TableComponent = ({
  selectedAdmins,
  startDate,
  endDate,
  total,
  itemsByArray,
}) => {
  const getCalculatedChartData = () => {
    const tablesData = {};
    const table = {};
    Object.keys(selectedAdmins).forEach((k) => {
      const chartData = getCurrentChartData({
        startDate,
        endDate,
        selectedAdmins: selectedAdmins[k],
        itemsByArray: itemsByArray[k],
        total,
      });
      chartData.forEach((c) => {
        if (!table[c.date]) table[c.date] = { date: c.date };
        table[c.date][k] = c.na;
      });
      console.log(chartData);
      tablesData[k] = chartData;
    });
    return Object.values(table);
    // return Object.values(chartData);
  };
  // const [currentChartData] = useState(getCurrentChartData());

  const currentChartData = useMemo(getCalculatedChartData, [selectedAdmins, total, itemsByArray, startDate, endDate]);
  console.log(currentChartData, 'table');
  // console.log(opacity);
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: p => moment.utc(p?.date, 'M/D/YY').toISOString(),
        id: 'date',
        Cell: p => useMemo(() => (
          <div style={{ width: '120px' }}>
            {p.row.original.date}
          </div>
        ), [p.row.original]),
        isFreeze: true,
      },
      {
        Header: 'Contacts',
        accessor: p => Number(p?.chartDataContacts || 0),
        id: 'chartDataContacts',
        Cell: p => formatIntNumber(p.value),
        className: 'text-center',
      },
      {
        Header: 'Contracts',
        accessor: p => Number(p?.chartDataContracts || 0),
        id: 'chartDataContracts',
        Cell: p => formatIntNumber(p.value),
        className: 'text-center',
      },
      {
        Header: 'Content',
        accessor: p => Number(p?.chartDataContent || 0),
        id: 'chartDataContent',
        Cell: p => formatIntNumber(p.value),
        className: 'text-center',
      },
      {
        Header: 'YouTube Content',
        accessor: p => Number(p?.chartDataContentYoutube || 0),
        id: 'chartDataContentYoutube',
        Cell: p => formatIntNumber(p.value),
        className: 'text-center',
      },
      {
        Header: 'Total Conversions',
        accessor: p => Number(p?.chartDataConversionsTotal || 0),
        id: 'chartDataConversionsTotal',
        Cell: p => formatIntNumber(p.value),
        className: 'text-center',
      },
      {
        Header: 'Conversions',
        accessor: p => Number(p?.chartDataConversions || 0),
        id: 'chartDataConversions',
        Cell: p => formatIntNumber(p.value),
        className: 'text-center',
      },
      {
        Header: 'Creators',
        accessor: p => Number(p?.chartDataCreators || 0),
        id: 'chartDataCreators',
        Cell: p => formatIntNumber(p.value),
        className: 'text-center',
      },
    ],
    [],
  );
  return (
    <Row>
      <Col>
        <>
          <Row>
            <Col />
            <Col sm="auto" >
              <DownloadCSVButton
                records={currentChartData}
                className=""
                fileName="CreatorPerformanceReport.csv"
                csvArrayColumns={[
                  {
                    label: 'Date',
                    func: data => data.date,
                  }, {
                    label: 'Contacts',
                    func: data => Number(data.chartDataContacts || 0),
                  }, {
                    label: 'Contracts',
                    func: data => Number(data.chartDataContracts || 0),
                  }, {
                    label: 'Content',
                    func: data => Number(data.chartDataContent || 0),
                  }, {
                    label: 'YouTube Content',
                    func: data => Number(data.chartDataContentYoutube || 0),
                  }, {
                    label: 'Total Conversions',
                    func: data => Number(data.chartDataConversionsTotal || 0),
                  }, {
                    label: 'Conversions',
                    func: data => Number(data.chartDataConversions || 0),
                  }, {
                    label: 'Creators',
                    func: data => Number(data.chartDataCreators || 0),
                  },
                ]}
              >
                Download CSV
              </DownloadCSVButton>
            </Col>
          </Row>
          <ReactTableBase
            key="searchable"
            columns={columns}
            data={currentChartData}
            tableConfig={tableConfig}
          />
        </>
      </Col>
    </Row>
  );
};

TableComponent.propTypes = {
  selectedAdmins: PropTypes.objectOf(PropTypes.any),
  total: PropTypes.string,
};

TableComponent.defaultProps = {
  selectedAdmins: [],
  total: '',
};

export default TableComponent;
