/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { Label, FormGroup, Button, DropdownMenu, DropdownItem, UncontrolledButtonDropdown, DropdownToggle, InputGroup, Input, InputGroupAddon, InputGroupText, Col, Row } from 'reactstrap';

import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';

import Select from 'react-select';
import SelectAsync from 'react-select/async';

import ApiClient from 'ApiClient';

const colourStyles = {
  multiValue: styles => ({
    ...styles,
    backgroundColor: '#70bbfd',
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: '#a2e1fd',
      color: '#70bbfd',
    },
  }),
};

export default class PromoCodesFilters extends React.Component {
  static propTypes = {
    changed: PropTypes.func.isRequired,
    downloadCSV: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      influencers: [],
      exists: '',
      existsOption: null,
      showFilters: false,
      isDownloadingCSV: false,
    };

    this.handleResetFilters = this.resetFilters.bind(this);
    this.handleSetSearchValue = this.setSearchValue.bind(this);
    this.handleSetShowFilters = this.setShowFilters.bind(this);
    this.handleDoDownloadCSV = this.doDownloadCSV.bind(this);

    this.handleInfluencersLoadOptions = this.influencersLoadOptions.bind(this);
    this.handleOfferLoadOptions = this.offersLoadOptions.bind(this);

    this.api = new ApiClient();
  }

  setSearchValue(q) {
    this.setState({ searchValue: q });
    this.applyFiltersDebounce();
  }

  setShowFilters(show) {
    this.setState({ showFilters: show });
  }

  resetFilters() {
    this.setState({ searchValue: '' });
    this.setState({ influencers: [] });
    this.setState({ offers: [] });
    this.setState({ exists: '' });
    this.setState({ existsOption: null });

    this.applyFiltersDebounce();
    return true;
  }

  async doDownloadCSV() {
    const {
      downloadCSV,
    } = this.props;
    this.setState({ isDownloadingCSV: true });

    try {
      await downloadCSV();
    } catch (e) {
      console.error(e);
    }

    this.setState({ isDownloadingCSV: false });
  }

  applyFiltersDebounce() {
    clearTimeout(this.__applyFilterDebounce);
    this.__applyFilterDebounce = setTimeout(() => {
      this.applyFilters();
    }, 200);
  }

  applyFilters() {
    const {
      changed,
    } = this.props;
    const {
      searchValue,
      influencers,
      exists,
      offers,
    } = this.state;

    changed({
      searchValue,
      influencers,
      offers,
      exists,
    });
  }

  influencersLoadOptions = async (inputValue) => {
    const resourceName = 'Influencer';
    const records = await this.api.searchRecords({
      resourceId: resourceName,
      query: inputValue,
    });
    return records.map(record => ({
      value: record.params._id,
      label: `${record.params.first_name ? record.params.first_name : ''} ${record.params.last_name ? record.params.last_name : ''}`,
      record,
    })).sort((a, b) => ((a.label < b.label) ? -1 : 1));
  }

  offersLoadOptions = async (inputValue) => {
    const resourceName = 'Offer';
    const records = await this.api.searchRecords({
      resourceId: resourceName,
      query: inputValue,
    });
    return records.map(record => ({
      value: record.params._id,
      label: `${record.params.name || ''}`,
      record,
    })).sort((a, b) => ((a.label < b.label) ? -1 : 1));
  }

  render() {
    const {
      showFilters,
      isDownloadingCSV,
      offers,
      searchValue,
      influencers,
      existsOption,
    } = this.state;

    return (
      <div>
        <Row className="mt-4">
          <Col sm={8}>
            <InputGroup>
              <Input
                className="form-control-sm form-control"
                name="search"
                type="search"
                autoComplete="off"
                placeholder="Search Promo Code"
                value={searchValue}
                onChange={p => this.handleSetSearchValue(p.target.value)}
                style={{ height: '38px' }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i
                    className="fa fa-search"
                    aria-hidden="true"
                    style={{ color: '#70bbfd', fontSize: 12 }}
                  />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col sm={2}>
            <Row>
              <Col sm={8} style={{ paddingRight: '0' }}>
                <Tooltip title="Reset Filters">
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={this.handleResetFilters}
                    style={{
                      width: '100%',
                      padding: '4px 8px',
                      height: '38px',
                    }}
                  >
                    <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                    <span className="btn-text">Reset</span>
                  </Button>
                </Tooltip>
              </Col>
              <Col sm={1} style={{ paddingLeft: '24px' }}>
                <Tooltip title="Toggle Filters">
                  <IconButton
                    id="filtersMenuButton"
                    size="small"
                    onClick={() => this.handleSetShowFilters(!showFilters)}
                    className="material-table__toolbar-button"
                    style={{
                      height: '38px',
                    }}
                  >
                    <TuneIcon size="30" />
                  </IconButton>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col md={2} className="text-right">
            <UncontrolledButtonDropdown className="w-100">
              <DropdownToggle
                id="actionsMenu"
                caret
                color="primary"
                disabled={isDownloadingCSV}
                style={{
                  padding: '4px 25px',
                  height: '38px',
                }}
              >
                {
                  isDownloadingCSV &&
                  <span className="pr-2">
                    <div className="spinner-border spinner-border-sm primary" />
                  </span>
                }
                Actions
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => this.handleDoDownloadCSV()}>Download CSV</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
        </Row>
        <Row className="mt-3" style={{ display: (showFilters ? 'flex' : 'none') }}>
          <Col md={6}>
            <FormGroup>
              <Label className="bold-text">
                Show Promo Codes
              </Label>

              <Select
                value={existsOption}
                options={[
                    { value: '', label: 'Any' },
                    { value: 'both', label: 'Exist in both platforms' },
                    { value: 'betterhelp', label: 'Exist only in BetterHelp HO' },
                    { value: 'influencelogic', label: 'Exist only in InfluenceLogic HO' },
                  ]}
                onChange={(option) => {
                  this.setState({ exists: option.value, existsOption: option });
                  this.applyFiltersDebounce();
                }}
                placeholder="Exists on"
                className="mb-3"
                isDisabled={false}
              />

            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="bold-text">
                Influencer(s)
              </Label>
              <SelectAsync
                cacheOptions
                defaultOptions
                isClearable
                isMulti
                loadOptions={this.handleInfluencersLoadOptions}
                onChange={(value) => {
                  this.setState({ influencers: value });
                  this.applyFiltersDebounce();
                }}
                placeholder="Select Influencer..."
                value={influencers}
                styles={colourStyles}
              />
            </FormGroup>
            <FormGroup>
              <Label className="bold-text">
                Offer(s)
              </Label>
              <SelectAsync
                cacheOptions
                defaultOptions
                isClearable
                isMulti
                loadOptions={this.handleOfferLoadOptions}
                onChange={(value) => {
                  this.setState({ offers: value });
                  this.applyFiltersDebounce();
                }}
                placeholder="Select Offer..."
                value={offers}
                styles={colourStyles}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}
