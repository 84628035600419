import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import Actions from './custom/Actions';
import ContextMenuInList from './custom/ContextMenuInList';
import MatTableList from '../../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../../shared/components/BreadCrumbs';

const ListContainer = (props) => {
  const {
    resource,
    action,
    history,
  } = props;
  const [version] = useState((new Date()).toString());

  return (
    <Container className="dashboard">
      <Breadcrumbs
        links={[
          { title: 'Home', path: '/' },
          { title: 'Service Agreements', path: false },
          { title: 'Custom Service Agreements', path: false },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row>
              <Col>
                <h3>Custom Service Agreements</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-0" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                history={history}
              />
              <div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
                <MatTableList
                  action={action}
                  resource={resource}
                  sortBy="templateName"
                  direction="asc"
                  date={new Date()}
                  UserPropertyType={{}}
                  showTitle={false}
                  showFilters={false}
                  isResizable={false}
                  version={version}
                  v={2}
                  useFlex
                  hideToolbar
                  noActions
                  columnsSettings={{
                    contextMenu: {
                      Header: '',
                      accessor: () => null,
                      Cell: p => (
                        <ContextMenuInList
                          record={p.row.original}
                          history={history}
                        />
                      ),
                      disableSortBy: true,
                      className: 'align-self-center',
                      width: 30,
                      maxWidth: 30,
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

ListContainer.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default ListContainer;
