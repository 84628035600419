import React from 'react';
import { 
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
 } from 'reactstrap';


const DashboardActions = ({ downloadCsv }) => {
 
  return (
    <>
      <UncontrolledButtonDropdown className="w-100">
        <DropdownToggle
          id="actionsMenu"
          caret
          color="primary"
          style={{
            padding: '4px 25px',
            height: '42px',
          }}
        >
          Actions
        </DropdownToggle>
        <DropdownMenu right>
            <DropdownItem onClick={() => downloadCsv()}>Download CSV</DropdownItem>
            <DropdownItem onClick={() => window.open('/resources/BrandUser/actions/new', '_blank').focus()}>Add New User</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </>
  );
};
DashboardActions.propTypes = {

};


export default DashboardActions;
