import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
// import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Spinner } from 'reactstrap';
import ApiClient, { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import Select from 'react-select';
import PropTypes from 'prop-types';
// import { unflatten } from 'flat';
import DatePicker from 'react-datepicker';
import CurrencyField from '../../../../shared/components/form/CurrencyField';
import StyledButton from '../../../../shared/components/components/ui/styled-button';
import withNotice from '../../../App/store/with-notice';
import { getConstant } from '../../../../shared/helpers/WVConstants';
import ModalSimple from '../../../../shared/components/ModalSimple';
import Bill from '../../../../shared/recordCollection/Bill';
import formater from '../../../../shared/helpers/WVFormatter';


class BillForm extends React.Component {
  static async loadInfluencerOptions(inputValue) {
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'Influencer',
      query: inputValue,
    });
    const res = [];
    records.forEach((record) => {
      if (record.params.email !== null) {
        res.push({
          value: record.id,
          label: record.params.email,
          params: record.params,
          record,
        });
      }
    });
    return res;
  }
  constructor(props) {
    super(props);
    this.model = new Bill({}, this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeInfluencer = this.handleChangeInfluencer.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleChangeDeal = this.handleChangeDeal.bind(this);
    this.state = {
      showModalQuestion: false,
      confirmUpdate: false,
      isUpdateAmount: false,
      dealList: [],
      selectedInfluencer: null,
      loading: {
        form: false,
      },
      bill: this.model.getModel(),
    };
  }

  componentDidMount() {
    this.fetchData();
  }
  shouldComponentUpdate(newProps) {
    const { match } = newProps;
    if (
      (match && match.params.billId && !this.props.match.params.billId)
      || (match && match.params.billId && this.props.match.params.billId !== match.params.billId)
      || (match && !match.params.billId && this.props.match.params.billId)
    ) {
      this.fetchData();
    }
    return true;
  }

  getDeals(influencerId) {
    const { bill } = this.state;
    axios({
      method: 'get',
      url: `/api/portal/influencer/get-deals-by-influencer?id=${influencerId}&object=bill`,
    }).then((response) => {
      let deals = [];
      if (response.data.success === true) {
        if (response.data.deals !== null) {
          deals = response.data.deals.map(deal => ({ value: deal._id, label: deal.dealname, deal }));
        }
      } else {
        this.props.addNotice({
          message: 'An error occurred ',
          type: 'warning',
        });
      }
      if (this.model.isNew()) {
        this.setState({
          dealList: deals,
        });
      } else {
        const dealListDisplay = [...deals];
        if (!this.model.isNew() && this.model.get('deal')) {
          dealListDisplay.push({ value: bill.populated.deal.params._id, label: bill.populated.deal.params.dealname, deal: bill.populated.deal.params });
        }
        this.setState({
          dealList: dealListDisplay,
        });
      }
    });
  }
  async fetchData() {
    this.setState({
      showModalQuestion: false,
      confirmUpdate: false,
      isUpdateAmount: false,
      loading: { form: true },
    });
    if (this.props.match.params.billId) {
      await this.model.fetchRecord(this.props.match.params.billId);
      this.setState({
        loading: {
          form: false,
        },
      }, () => {
        if (this.state.bill.populated.influencer) {
          this.getDeals(this.state.bill.populated.influencer.params._id);
        }
      });
    } else {
      this.setState({
        loading: {
          form: false,
        },
      });
    }
    return null;
  }

  handleChangeInfluencer(selected) {
    if (selected) {
      this.getDeals(selected.record.params._id);
      this.setState({ selectedInfluencer: selected });
      this.model.set('influencer', selected.record.params._id !== null ? selected.record.params._id : '');
      this.model.set('deal', null);
    }
  }

  handleChangeAmount(e, value) {
    const amount = Number(value);
    this.model.set('amount', amount, () => {
      this.showAmountChangeModal();
    });
    this.model.validateParam('amount');
    // this.showAmountChangeModal();
  }
  handleChangeDeal(e) {
    const { dealList } = this.state;
    this.model.set('deal', e.value);
    let dealAmount = 0;
    const selectedDealOption = e.value ? dealList.find(deal => deal.value.toString() === e.value.toString()) : {};
    if (selectedDealOption && Object.keys(selectedDealOption).length !== 0) {
      dealAmount = selectedDealOption.deal.dealTerms.guaranteed_payment_contract_amount;
    }
    if (dealAmount) {
      this.model.set('amount', dealAmount);
    }
    // this.showAmountChangeModal();
  }
  showAmountChangeModal() {
    const { dealList } = this.state;
    const selectedDealOption = this.model.get('deal') ? dealList.find(deal => deal.value.toString() === this.model.get('deal').toString()) : {};
    console.log(selectedDealOption.deal.dealTerms.guaranteed_payment_contract_amount, this.model.get('amount'))
    if (selectedDealOption
      && Object.keys(selectedDealOption).length !== 0
      && ['Upfront Only', 'Upfront CPM', 'Hybrid', 'Media License', 'Bonus'].includes(selectedDealOption.deal.dealTerms.type_of_deal)
    ) {
      if (Number(selectedDealOption.deal.dealTerms.guaranteed_payment_contract_amount) !== Number(this.model.get('amount'))) {
        this.setState({
          confirmUpdate: false,
          showModalQuestion: true,
          isUpdateAmount: false,
        });
        return true;
      }
      this.setState({
        confirmUpdate: false,
        showModalQuestion: false,
        isUpdateAmount: false,
      });
      return false;
    }
    this.setState({
      confirmUpdate: false,
      showModalQuestion: false,
      isUpdateAmount: false,
    });
    return false;
  }
  async handleSubmit(event) {
    event.preventDefault();
    const { history } = this.props;

    await this.model.validate();
    this.model.set('isUpdateAmount', this.state.isUpdateAmount);
    if (this.model.haveErrors()) {
      return true;
    }
    if (!this.state.confirmUpdate && this.showAmountChangeModal()) {
      return false;
    }
    this.setState({
      loading: {
        form: true,
      },
    });
    try {
      await this.model.save();
    } catch (e) {
      console.log(e);
      this.props.addNotice({
        message: e.message,
        type: 'error',
      });
      this.setState({
        loading: {
          form: false,
        },
      });
      return false;
    }
    this.setState({
      loading: {
        form: false,
      },
    });
    this.props.addNotice({
      message: 'New Bill has been added/updated successfully',
      type: 'success',
    });
    history.push('/resources/Bill/actions/list');
    return false;
  }
  render() {
    const {
      bill,
      dealList,
      selectedInfluencer,
      showModalQuestion,
      confirmUpdate,
      isUpdateAmount,
    } = this.state;
    let dealAmount = 0;
    let dealStage = null;
    const statusValues = getConstant('bill', 'status', {});
    const statusOptions = Object.keys(statusValues).map(key => ({ label: statusValues[key], value: key }));
    let selectedInfluencerOption = {};
    if (selectedInfluencer) {
      selectedInfluencerOption = { ...selectedInfluencer };
    }
    const selectedDealOption = this.model.get('deal') ? dealList.find(deal => deal.value.toString() === this.model.get('deal').toString()) : {};
    if (selectedDealOption && Object.keys(selectedDealOption).length !== 0) {
      dealStage = selectedDealOption.deal.dealstage;
    }
    const statusOption = bill.params.status ? statusOptions.find(status => status.value.toString() === bill.params.status.toString()) : {};
    console.log(bill);

    const paidDealstage = getConstant('deal', 'paid', []);
    return (
      <Container className="dashboard">
        <Row>
          <Col>
            <Card>
              <CardBody>
                {this.state.loading.form &&
                  (
                    <div className="text-center"><Spinner color="primary" size="lg" /></div>
                  )
                }
                {!this.state.loading.form && (
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <Label>Creator</Label>
                      {this.model.id ? (
                        <span>: {this.model.getP('influencer', 'first_name')} {this.model.getP('influencer', 'last_name')}</span>
                      ) : (
                        <SelectAsync
                          cacheOptions
                          value={selectedInfluencerOption}
                          defaultOptions
                          loadOptions={BillForm.loadInfluencerOptions}
                          onChange={this.handleChangeInfluencer}
                          placeholder=""
                        />
                        )}
                      {this.model.getErrorView('influencer')}
                    </FormGroup>
                    <hr />
                    <FormGroup>
                      <Label>Deal</Label>
                      <Select
                        value={selectedDealOption}
                        options={dealList}
                        isDisabled={!dealList || dealList.length === 0}
                        onChange={this.handleChangeDeal}
                        placeholder="Select Deal"
                      />
                      {this.model.getErrorView('deal')}
                      <span className="form__form-group-description">Note: We show just Unique deal. If you can not find deal for selected influencer Bill already exist</span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Amount</Label>
                      <CurrencyField
                        placeholder="Payout Amount"
                        disabled={paidDealstage.includes(dealStage)}
                        defaultValue={typeof bill.params !== 'undefined' && typeof bill.params.amount !== 'undefined' ? Number(this.model.get('amount')) : (dealAmount || 0)}
                        onBlur={this.handleChangeAmount}
                      />
                      {this.model.getErrorView('amount')}
                      {bill.params.payments && bill.params.payments.length > 0 && (<span className="form__form-group-description">Min Amount: {formater.formatCurrency(this.model.getTotalPaid())}</span>)}
                      {confirmUpdate && (
                        <div className="form__form-group-description">
                          Note: We will { isUpdateAmount ? '' : ' NOT '} update Hubspot Deal Guaranteed Payment Contract Amount. To change click{' '}
                          <strong tabIndex={0} role="button" onClick={() => this.setState({ showModalQuestion: true })} onKeyDown={() => true}>Here</strong>
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label>Due Date</Label>
                      <div>
                        <DatePicker
                          onChange={e => this.model.set('dueDate', e)}
                          selected={typeof bill.params.dueDate === 'string' ? new Date(bill.params.dueDate) : bill.params.dueDate}
                          dateFormat="M/d/yyyy h:mmaa"
                          placeholderText={`Due Date (${moment.tz(moment.tz.guess()).zoneAbbr()})`}
                          className="form-control"
                          minDate={new Date()}
                          showTimeInput
                        />
                      </div>
                      {this.model.getErrorView('dueDate')}
                    </FormGroup>
                    <FormGroup>
                      <Label>Status</Label>
                      <Select
                        value={statusOption}
                        options={statusOptions}
                        onChange={e => this.model.set('status', e.value)}
                        placeholder="Status"
                        isDisabled={bill.params.payments && bill.params.payments.length > 0}
                      />
                      {this.model.getErrorView('status')}
                      {bill.params.payments && bill.params.payments.length > 0 && (<span className="form__form-group-description">The bill status can not be changed because payments have already been made to the Creator.</span>)}
                    </FormGroup>
                    <StyledButton
                      type="submit"
                      className="is-primary"
                    >
                      <i className="icomoon-save" />
                      <span className="btn-text">Save</span>
                    </StyledButton>
                    &nbsp;&nbsp;
                    <Link to="/resources/Bill/actions/list">
                      <StyledButton
                        type="button"
                        className="btn-danger"
                      >
                        <i className="icomoon-save" />
                        <span className="btn-text">Cancel</span>
                      </StyledButton>
                    </Link>
                  </form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalSimple
          title="Update Hubspot Amount?"
          message={`You are changing the payment amount. Do you wish to also update the HubSpot Deal and set the Guaranteed Payment Amount to ${formater.formatCurrency(this.model.get('amount'))}`}
          color="primary"
          modal={showModalQuestion}
          clickYes={() => {
            this.setState({ isUpdateAmount: true, showModalQuestion: false, confirmUpdate: true });
          }}
          clickNo={() => {
            console.log('NO');
            this.setState({ isUpdateAmount: false, showModalQuestion: false, confirmUpdate: true });
          }}
        />
      </Container>
    );
  }
}

BillForm.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  // resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
};


export default withNotice(BillForm);
