import React from 'react';
import { unflatten } from 'flat';

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const params = unflatten(props.record?.params);
  let categories = [];
  if (params?.itemsArray?.length > 0) {
    // eslint-disable-next-line array-callback-return,consistent-return
    categories = [...new Set(params.itemsArray.map((item) => { if (item?.category) return item?.category; }))];
  }
  return (<span style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }} >{categories.join(', ')}</span>);
};
