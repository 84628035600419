import React from 'react';
import { Badge } from 'reactstrap';
import MatTableList from '../../../../../../../shared/tables/materialTable/TableRedux';
import ContextMenuInList from './ContextMenuInList';
import IsPilot from './IsPilot';
import IsDisplayInUi from './IsDisplayInUi';
import Inactive from './Inactive';
import CopyToClipboard from '@/shared/components/CopyToClipboard';
import WVFormatter from '@/shared/helpers/WVFormatter';

const Table = React.memo(() => {
  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Brand Users Found' }}
      useUnflatten
      customColumns={[
        {
          width: 120,
          id: 'companyName',
          Header: () => 'Company',
          accessor: record => (record?.params?.companyName || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          isFreeze: true,
          className: 'd-flex align-items-center',
        },
        {
          width: 110,
          id: 'inactive',
          Header: () => 'Inactive',
          accessor: record => (record?.params?.inactive || ''),
          Cell : (p) => <Inactive record={p.row.original} updateRecordFunction={p.updateRecordFunction} />,
          className: 'd-flex align-items-center',
        },
        {
          width: 110,
          id: 'isPilot',
          Header: () => 'Is Pilot',
          accessor: record => (record?.params?.isPilot || ''),
          Cell : (p) => <IsPilot record={p.row.original} updateRecordFunction={p.updateRecordFunction} />,
          className: 'd-flex align-items-center',
        },
        {
          width: 110,
          id: 'displayInUi',
          Header: () => 'Display',
          accessor: record => (record?.params?.displayInUi || ''),
          Cell : (p) => <IsDisplayInUi record={p.row.original} updateRecordFunction={p.updateRecordFunction} />,
          className: 'd-flex align-items-center',
        },
        {
          id: 'city',
          Header: () => 'City',
          accessor: record => (record?.params?.city || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          className: 'd-flex align-items-center',
          width: 80,
        },
        {
          width: 100,
          id: 'regionState',
          Header: () => 'State/Region',
          accessor: record => (record?.params?.regionState || ''),
          className: 'd-flex align-items-center',
        },
        {
          width: 80,
          id: 'country',
          Header: () => 'Country',
          accessor: record => (record?.params?.country || ''),
          className: 'd-flex align-items-center',
        },
        {
          width: 80,
          id: 'postalCode',
          Header: () => 'Postal Code',
          accessor: record => (record?.params?.postalCode || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          className: 'd-flex align-items-center',
        },
        {
          width: 70,
          id: 'tuneId',
          Header: () => 'Tune Id',
          accessor: record => (record?.params?.tuneId || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          className: 'd-flex align-items-center',
        },
        {
          width: 60,
          id: 'status',
          Header: () => 'Status',
          accessor: record => (record?.params?.firstName || ''),
          className: 'd-flex align-items-center',
          Cell: r => (<Badge color={r.row.original?.params?.status === 'active' ? 'success' : 'secondary'}>{WVFormatter.capitalize(r.row.original.params?.status)}</Badge>),
        },          
        {
          id: 'actions',
          Header: ' ',
          accessor: () => {},
          Cell: p => (
            <ContextMenuInList
              record={p.row.original}
            />
          ),
          disableSortBy: true,
          className: 'd-flex align-items-center',
          width: 30,
          maxWidth: 30,
        },
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
