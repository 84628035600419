import React from 'react';
import { getCountryFromIso } from '../../../../../../../shared/helpers/WVUtilities';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import TableCellContextMenu from '../../../Prospects/components/custom/TableCellContextMenu';
import { AddressIcon } from '../../../../../../../shared/helpers/Icons';

export default function ResearchCreatorColumnsInSearchTable(currentWatchlistId, chooseSearchItems, addItemsToTheProspects, setSingleSelectedCreatorId, showErrorNotice, setOpenRelatedPodcastModal) {
  return [
    {
      Header: '',
      accessor: () => null,
      id: 'title',
      className: '',
      Cell: creator => React.useMemo(() => (
        <div className="d-flex" style={{ width: '300px' }}>
          {creator.row.original.creator_id
            ? (
              <button
                style={{
                  padding: '0',
                  margin: '2px 10px 0 0',
                  border: 'none',
                  float: 'left',
                  backgroundColor: 'white',
                }}
                onClick={() => setSingleSelectedCreatorId(creator.row.original.creator_id)}
              >
                <img
                  style={{
                    maxWidth: '60px',
                  }}
                  src={creator.row.original.thumbnail_url}
                  alt={creator.row.original.title}
                />
              </button>
            )
            : (
              <img
                align="left"
                style={{
                  maxWidth: '60px',
                  marginRight: '10px',
                }}
                src={creator.row.original.thumbnail_url}
                alt={creator.row.original.title}
              />
            )
          }
          <div className="my-auto">
            <b>{creator.row.original.title}</b>
            <br />
            <span style={{ fontSize: '13px', color: '#aaa' }}>
              {typeof creator.row.original.industry !== 'undefined' && creator.row.original.industry !== null ? `${creator.row.original.industry.title} | ` : ''}
              {typeof creator.row.original.country !== 'undefined' && creator.row.original.country !== null ? getCountryFromIso(creator.row.original.country) : ''}
            </span>
            {creator.row.original.creator_id && (
              <br />
            )}
            {creator.row.original.creator_id && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
              <a
                role="button"
                title="Details"
                onClick={() => setSingleSelectedCreatorId(creator.row.original.creator_id)}
                style={{
                  fontSize: '19px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  marginTop: '2px',
                  color: '#70bbfd',
                }}
              >
                <i className="fa fa-info-circle" />
              </a>
            )}
            {creator.row.original.creator_id && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
              <span
                role="button"
                onClick={() => {
                  // setSingleSelectedCreatorId(creator.row.original.creator_id);
                  setOpenRelatedPodcastModal({ isOpen: true, title: creator.row.original.title });
                }}
                className="ml-2"
              >
                <img src="/images/mediacontent/podcast.png" alt="Related Podcasts" title="Related Podcasts" style={{ maxWidth: '18px' }} />
              </span>
            )}
            {creator.row.original.hsUserId && (
              <div style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '2px' }}>
                <a
                  title="Show HubSpot Contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2"
                  style={{ fontSize: '18px' }}
                  href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${creator.row.original.hsUserId}`}
                >
                  <i className="fab fa-hubspot" />
                </a>
              </div>
            )}
            {creator.row.original.influencer && (
              <span>
                  &nbsp;&nbsp;&nbsp;
                <a
                  title="Creator Profile"
                  target="_blank"
                  rel="noreferrer"
                  href={`/influencer/profile/${creator.row.original.influencer}`}
                  style={{
                    fontSize: '18px',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: '2px',
                  }}
                >
                  <AddressIcon />
                </a>
              </span>
            )}
          </div>
        </div>), [creator.row.original, setOpenRelatedPodcastModal, setSingleSelectedCreatorId]),
      disableSortBy: true,
      minWidth: 300,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'platforms',
      className: '',
      Cell: creator => React.useMemo(() => {
        const res = [];
        if (creator?.row.original.accounts?.length > 0) {
          creator.row.original.accounts.map((account) => {
            const platformsMapping = {
              youtube: 'youtube.png',
              facebook: 'facebookWatchVideo.png',
              instagram: 'instagram.png',
              twitter: 'twitterPost.png',
              twitch: 'twitch.png',
            };
            if (typeof platformsMapping[account.platform] !== 'undefined') {
              res.push((
                <span key={`${creator.row.original.creator_id}-${account.platform}`}>
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a href={account.account_url} target="_blank"><img src={`/images/mediacontent/${platformsMapping[account.platform]}`} alt={account.platform} title={account.platform} style={{ maxWidth: '15px' }} /></a>&nbsp;&nbsp;
                </span>));
            }
            return res;
          });
        }
        return (
          <div style={{ textAlign: 'center' }}>
            {res?.map(resItem => resItem)}
          </div>);
      }, [creator.row.original]),
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'score',
      className: '',
      Cell: creator => React.useMemo(() => (
        <div style={{ textAlign: 'center' }}>
          <b>Score</b><br />
          {creator.row.original.accounts?.map((account) => {
            if (account.platform === 'youtube') {
              return account.influencer_score !== null ? Math.ceil(parseFloat(account.influencer_score)) : '';
            }
            return '';
          })}
        </div>), [creator.row.original]),
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'v30',
      className: '',
      Cell: creator => React.useMemo(() => (
        <div style={{ textAlign: 'center' }}>
          <b>V30</b><br />
          {creator.row.original.accounts?.map((account) => {
            if (account.platform === 'youtube') {
              return account.monthly_v30 !== null ? WVFormatter.formatIntNumber(account.monthly_v30) : '';
            }
            return '';
          })}
        </div>), [creator.row.original]),
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'er30',
      className: '',
      Cell: creator => React.useMemo(() => (
        <div style={{ textAlign: 'center' }}>
          <b>ER30</b><br />
          {creator.row.original.accounts?.map((account) => {
            if (account.platform === 'youtube') {
              return account.monthly_er30 !== null ? WVFormatter.formatIntNumber(account.monthly_er30) : '';
            }
            return '';
          })}
        </div>), [creator.row.original]),
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'perc_male',
      className: '',
      Cell: creator => React.useMemo(() => (
        <div style={{ textAlign: 'center' }}>
          <b>% Male</b><br />
          {creator.row.original.accounts?.length > 0 ? creator.row.original.accounts.map((account) => {
            if (account.platform === 'youtube') {
              if (typeof account.audience_genders !== 'undefined' && account.audience_genders !== null) {
                let res = '';
                // eslint-disable-next-line no-lone-blocks
                if (account.audience_genders?.length > 0) {
                  res = account.audience_genders.map((gender) => {
                    if (gender.gender === 'male') {
                      return `${gender.value.toFixed(2)}%`;
                    }
                    return '';
                  });
                }
                return res;
              }
            }
            return '';
          }) : ''}
        </div>), [creator.row.original]),
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'perc_female',
      className: '',
      Cell: creator => React.useMemo(() => (
        <div style={{ textAlign: 'center' }}>
          <b>% Female</b><br />
          {creator.row.original.accounts?.length > 0 ? creator.row.original.accounts.map((account) => {
            if (account.platform === 'youtube') {
              if (typeof account.audience_genders !== 'undefined' && account.audience_genders !== null) {
                let res = '';
                // eslint-disable-next-line no-lone-blocks
                if (account.audience_genders?.length > 0) {
                  res = account.audience_genders.map((gender) => {
                    if (gender.gender === 'female') {
                      return `${gender.value.toFixed(2)}%`;
                    }
                    return '';
                  });
                }
                return res;
              }
            }
            return '';
          }) : ''}
        </div>), [creator.row.original]),
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'perc_us',
      className: '',
      Cell: creator => React.useMemo(() => (
        <div style={{ textAlign: 'center' }}>
          <b>% US</b><br />
          {creator.row.original.accounts?.length > 0 ? creator.row.original.accounts.map((account) => {
            if (account.platform === 'youtube') {
              if (typeof account.audience_locations !== 'undefined' && account.audience_locations !== null) {
                let res = '';
                // eslint-disable-next-line no-lone-blocks
                if (account.audience_locations?.length > 0) {
                  res = account.audience_locations.map((location) => {
                    if (location.country === 'US') {
                      return `${location.value.toFixed(2)}%`;
                    }
                    return '';
                  });
                }
                return res;
              }
            }
            return '';
          }) : ''}
        </div>), [creator.row.original]),
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'perc_age25',
      className: '',
      Cell: creator => React.useMemo(() => (
        <div style={{ textAlign: 'center' }}>
          <b>% Over 25 YO</b><br />
          {creator.row.original.accounts?.length > 0 ? creator.row.original.accounts.map((account) => {
            if (account.platform === 'youtube') {
              if (typeof account.audience_ages !== 'undefined' && account.audience_ages !== null) {
                let res = '';
                // eslint-disable-next-line no-lone-blocks
                if (account.audience_ages?.length > 0) {
                  res = account.audience_ages.map((age) => {
                    if (age.range === 'ages_25_55_plus') {
                      return `${age.value.toFixed(2)}%`;
                    }
                    return '';
                  });
                }
                return res;
              }
            }
            return '';
          }) : ''}
        </div>), [creator.row.original]),
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'actions',
      className: '',
      Cell: creator => React.useMemo(() => {
        const contextMenuOptions = [
          {
            label: 'Add to Watchlist',
            handler: () => {
              // eslint-disable-next-line no-unused-expressions
              creator.row.original?.creator_id ?
                chooseSearchItems([creator.row.original?.creator_id], currentWatchlistId) :
                showErrorNotice('Sorry, we are missing an identifier for the creator, so we can not save this item to a watchlist.');
            },
          },
          {
            label: 'Add to Prospects',
            handler: () => {
              // eslint-disable-next-line no-unused-expressions
              creator.row.original?.creator_id ?
                addItemsToTheProspects(creator.row.original?.creator_id) :
                showErrorNotice('Creator ID information is missing and cannot be added to a watchlist or Prospects.');
            },
          },
          {
            label: 'View Audience Overlap',
            handler: () => {
              // eslint-disable-next-line no-unused-expressions
              creator.row.original?.creator_id ?
                (document.location = `/research/overlap/${creator.row.original.creator_id}`) :
                showErrorNotice('Creator ID information is missing');
            },
          },
        ];
        return (
          <div style={{ textAlign: 'center' }}>
            {creator.row.original.loading === true && (
              <>
                <br />
                <span className="pr-2">
                  <div className="spinner-border spinner-border-sm" />
                </span>
              </>
            )}
            {creator.row.original.loading === 'saved' && (
              <>
                <br />
                <span>
                  Saved
                </span>
              </>
            )}
            {(typeof creator.row.original.loading === 'undefined'
              || (creator.row.original.loading !== true && creator.row.original.loading !== 'saved')
            ) && (
              <>
                <TableCellContextMenu options={contextMenuOptions} />
              </>
            )}
          </div>
        );
      }, [creator.row.original, currentWatchlistId, chooseSearchItems, addItemsToTheProspects]),
      disableSortBy: true,
    },
  ];
}
