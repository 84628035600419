import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  Modal,
} from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from 'mdi-react/InformationIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import { unflatten } from 'flat';

class ImpactActionShowAdditionalInfoButton extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    record: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  render() {
    const {
      modal,
    } = this.state;

    const impactAction = unflatten(this.props.record.params);

    return (
      <div style={{ display: 'inline' }}>
        <Tooltip title="Show Additional Information">
          <IconButton
            size="small"
            onClick={this.toggle}
            className="material-table__toolbar-button"
          >
            <InfoIcon size="15" />
          </IconButton>
        </Tooltip>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
          className="modal-dialog"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          </div>
          <div className="modal__body">
            <Row>
              <Col md={5}>
                <span className="bold-text">Status</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.state || 'n/a'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Action Tracker Name</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.actionTrackerName || 'n/a'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Promo Code</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.promoCode || 'n/a'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Referring Type</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.referringType || 'n/a'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Referring Domain</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.referringDomain || 'n/a'}</span>
              </Col>
            </Row>
            <hr className="mt-3 mb-3" />
            <Row>
              <Col md={5}>
                <span className="bold-text">Customer Area</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.customerArea || 'n/a'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Customer City</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.customerCity || 'n/a'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Customer Region</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.customerRegion || 'n/a'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Customer Country</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.customerCountry || 'n/a'}</span>
              </Col>
            </Row>
            <hr className="mt-3 mb-3" />
            <Row>
              <Col md={5}>
                <span className="bold-text">Sub ID1</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.subId1 || 'n/a'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Sub ID2</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.subId2 || 'n/a'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Sub ID3</span>
              </Col>
              <Col md={7}>
                <span>{impactAction.subId3 || 'n/a'}</span>
              </Col>
            </Row>
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_ok btn-sm" color="primary" onClick={this.toggle}>
              <span>Close</span>
            </Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default ImpactActionShowAdditionalInfoButton;
