/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect, useRef } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ReactQr from 'react-awesome-qr';

const QrCodeComponent = ({
  url, dealname, showIcon,
}) => {
  const [qrCodeBase64, setQrCodeBase64] = useState(null);
  const [qrPopoverOpen, setQrPopoverOpen] = useState(false);
  const wrapperRef = useRef(null);
  const [showQrCodeTargetId] = useState(`showQrCode_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)}`);

  let urlWithQR = url.trim();
  if (urlWithQR.indexOf('?') !== -1) {
    urlWithQR += '&aff_unique1=qr';
  } else {
    urlWithQR += '?aff_unique1=qr';
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        // console.log(ref.current);
        if (ref.current && !ref.current?.contains(event.target)) {
          setQrPopoverOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const downloadQr = () => {
    const name = dealname ? `QR Code ${dealname}.png` : 'QR Code.png';
    if (qrCodeBase64) {
      const link = document.createElement('a');
      document.body.appendChild(link); // for Firefox
      link.setAttribute('href', qrCodeBase64);
      link.setAttribute('download', name);
      link.click();
    }
  };

  return (
    <>
      {!showIcon && (' ')}
      <Popover
        placement="right"
        isOpen={qrPopoverOpen}
        target={showQrCodeTargetId}
        trigger="click"
        toggle={() => setQrPopoverOpen(!qrPopoverOpen)}
      >
        <PopoverBody
          style={{ width: '218px', height: '240px' }}
        >
          <div
            ref={wrapperRef}
          >
            <a
              className="il-color-deep-blue bold-text"
              role="button"
              onClick={downloadQr}
              style={{ fontSize: '150%' }}
            >
              Download QR Code
            </a>
            <ReactQr
              text={urlWithQR}
              size={1000}
              callback={setQrCodeBase64}
            />
          </div>
        </PopoverBody>
      </Popover>
      <Tooltip title="Show Referral QR Code">
        <button
          id={showQrCodeTargetId}
          className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall "
          onClick={() => setQrPopoverOpen(!qrPopoverOpen)}
          style={!showIcon ? { color: 'inherit', padding: '0px' } : {}}
        >
          {showIcon ? (
            <i className="fa fa-qrcode" />
          ) : (<span style={{ fontSize: '14px' }}>Show Referral QR Code</span>)}
        </button>
      </Tooltip>
    </>
  );
};

QrCodeComponent.propTypes = {
  url: PropTypes.string.isRequired,
  dealname: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
};

QrCodeComponent.defaultProps = {
  showIcon: true,
};

export default QrCodeComponent;
