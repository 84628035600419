/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { unflatten } from 'flat';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const AgentReducer = createSlice({
  name: 'agents',
  initialState: {
    loading: {
      creators: false,
      payment: false,
      additionalContacts: false,
    },
    creators: [],
    additionalContacts: [],
    currentAgent: {},
    adminUser: {},
    employee: {},
  },
  reducers: {
    setLoading(state, action) {
      state.loading.creators = action.payload;
    },
    setLoadingByName(state, action) {
      const { isLoading, section } = action.payload;
      state.loading = { ...state.loading, [section]: isLoading };
    },
    setAgent(state, action) {
      state.currentAgent = unflatten(action.payload);
    },
    getCreatorsStart(state) {
      state.loading = {
        ...state.loading,
        creators: true,
      };
    },
    getCreatorsSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        creators: false,
      };
      state.creators = data.creators;
      state.currentAgent = data.agent;
      state.currentAgent.contact = data.hubilAgent;
      state.adminUser = data.adminUser || {};
      state.employee = data.employee || {};
    },
    getCreatorsError(state) {
      state.loading = {
        ...state.loading,
        creators: false,
      };
    },
    getAdditionalContactsStart(state) {
      state.loading = {
        ...state.loading,
        additionalContacts: true,
      };
    },
    getAdditionalContactsSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        additionalContacts: false,
      };
      state.additionalContacts = data.additionalContacts;
    },
    getAdditionalContactsError(state) {
      state.loading = {
        ...state.loading,
        additionalContacts: false,
      };
    },
    addAdditionalContactStart(state) {
      state.loading = {
        ...state.loading,
        additionalContacts: true,
      };
    },
    addAdditionalContactSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        additionalContacts: false,
      };
      state.additionalContacts = data.additionalContacts;
    },
    addAdditionalContactError(state) {
      state.loading = {
        ...state.loading,
        additionalContacts: false,
      };
    },
    editAdditionalContactStart(state) {
      state.loading = {
        ...state.loading,
        additionalContacts: true,
      };
    },
    editAdditionalContactSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        additionalContacts: false,
      };
      state.additionalContacts = data.additionalContacts;
    },
    editAdditionalContactError(state) {
      state.loading = {
        ...state.loading,
        additionalContacts: false,
      };
    },
    deleteAdditionalContactStart(state) {
      state.loading = {
        ...state.loading,
        additionalContacts: true,
      };
    },
    deleteAdditionalContactSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        additionalContacts: false,
      };
      state.additionalContacts = data.additionalContacts;
    },
    deleteAdditionalContactError(state) {
      state.loading = {
        ...state.loading,
        additionalContacts: false,
      };
    },
  },
});
export const {
  setLoading,
  setLoadingByName,
  setAgent,
  getCreatorsStart,
  getCreatorsSuccess,
  getCreatorsError,
  getAdditionalContactsStart,
  getAdditionalContactsSuccess,
  getAdditionalContactsError,
  addAdditionalContactStart,
  addAdditionalContactSuccess,
  addAdditionalContactError,
  editAdditionalContactStart,
  editAdditionalContactSuccess,
  editAdditionalContactError,
  deleteAdditionalContactStart,
  deleteAdditionalContactSuccess,
  deleteAdditionalContactError,
} = AgentReducer.actions;

const getCreatorsApi = async (agentId) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/agent/get-agent-creators?agentId=${agentId}`);
};

export const getCreators = agentId => async (dispatch) => {
  try {
    dispatch(getCreatorsStart());
    const response = await getCreatorsApi(agentId);
    if (response.data) {
      dispatch(getCreatorsSuccess({ data: response.data.data }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getCreatorsError());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

const getAdditionalContactsApi = async (agentId) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/agent/get-additional-contacts?agentId=${agentId}`);
};

export const getAdditionalContacts = agentId => async (dispatch) => {
  try {
    dispatch(getAdditionalContactsStart());
    const response = await getAdditionalContactsApi(agentId);
    if (response.data?.success) {
      dispatch(getAdditionalContactsSuccess({ data: response.data }));
    } else if (response.data?.error) {
      throw new Error(response.data.error);
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getAdditionalContactsError());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
      duration: 30,
    }));
  }
};

const addAdditionalContactApi = async (agentId, contact) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/agent/post-add-additional-contact', { agentId, contact });
};

export const addAdditionalContact = (agentId, contact) => async (dispatch) => {
  try {
    dispatch(addAdditionalContactStart());
    const response = await addAdditionalContactApi(agentId, contact);
    if (response.data?.success) {
      dispatch(addAdditionalContactSuccess({ data: response.data }));
      dispatch(addNotice({
        message: 'Additional Contact successfully added.',
        type: 'success',
      }));
    } else if (response.data?.error) {
      throw new Error(response.data.error);
    } else {
      throw new Error('There was an error while adding an additional contact, check out console to see more information.');
    }
  } catch (err) {
    console.log(err);
    dispatch(addAdditionalContactError());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
      duration: 30,
    }));
  }
};

const editAdditionalContactApi = async (agentId, contact) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/agent/post-edit-additional-contact', { agentId, contact });
};

export const editAdditionalContact = (agentId, contact) => async (dispatch) => {
  try {
    dispatch(editAdditionalContactStart());
    const response = await editAdditionalContactApi(agentId, contact);
    if (response.data?.success) {
      dispatch(editAdditionalContactSuccess({ data: response.data }));
      dispatch(addNotice({
        message: 'Additional Contact successfully updated.',
        type: 'success',
      }));
    } else if (response.data?.error) {
      throw new Error(response.data.error);
    } else {
      throw new Error('There was an error while editing an additional contact, check out console to see more information.');
    }
  } catch (err) {
    dispatch(editAdditionalContactError());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
      duration: 30,
    }));
  }
};

const deleteAdditionalContactApi = async (agentId, contact) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/agent/post-delete-additional-contact', { agentId, contact });
};

export const deleteAdditionalContact = (agentId, contact) => async (dispatch) => {
  try {
    dispatch(deleteAdditionalContactStart());
    const response = await deleteAdditionalContactApi(agentId, contact);
    if (response.data?.success) {
      dispatch(deleteAdditionalContactSuccess({ data: response.data }));
      dispatch(addNotice({
        message: 'Additional Contact successfully deleted.',
        type: 'success',
      }));
    } else if (response.data?.error) {
      throw new Error(response.data.error);
    } else {
      throw new Error('There was an error while deleting an additional contact, check out console to see more information.');
    }
  } catch (err) {
    dispatch(deleteAdditionalContactError());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
      duration: 30,
    }));
  }
};

export const editPayment = (id, value) => async (dispatch) => {
  try {
    dispatch(setLoadingByName({ section: 'payment', isLoading: true }));
    const api = new ApiClient();
    const response = await api.client.post('/api/portal/agent/post-save-payment-value', { id, value });
    dispatch(setAgent(response.data.agentUser));
    dispatch(setLoadingByName({ section: 'payment', isLoading: false }));
  } catch (e) {
    console.log(e);
  }
};

export const refreshHubspot = () => async (dispatch, getState) => {
  const { currentAgent } = getState().AgentReducer;
  try {
    if (!currentAgent) {
      throw new Error('Invalid Agent Selected');
    } else if (!currentAgent.hsContactVid) {
      throw new Error('HubSpot Contact ID has not been set for Selected Agent');
    } else {
      dispatch(setLoadingByName({ section: 'creators', isLoading: true }));
      const api = new ApiClient();
      await api.client.post('/api/portal/agent/post-refresh-hubspot', { id: currentAgent._id });
      dispatch(setLoadingByName({ section: 'creators', isLoading: false }));
    }
  } catch (err) {
    dispatch(getCreatorsError());
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export default AgentReducer.reducer;
