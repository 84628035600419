import React from 'react';
import { Modal, ButtonToolbar, Button, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

const DealValidationModal = ({
  modal,
  closeDialog,
  failedPayouts,
}) => {
  console.log('Test Deal Validation');
  // console.log(failedPayouts);

  return (
    <Modal
      isOpen={modal}
      toggle={closeDialog}
    >
      <ModalHeader toggle={closeDialog} tag="h4">
        Deal Validation Error
      </ModalHeader>
      <ModalBody
        className="mt-1 mb-1 finance-form"
      >
        <span>Next Payouts have an issues with Deal validation:</span>
        {failedPayouts && failedPayouts.length > 0 && (
          <ul className="list-items-color-blue">
            {failedPayouts.map(item => (
              <li key={item.record.id}>
                {`${item.record.populated.influencer.params.first_name} ${item.record.populated.influencer.params.last_name}:`}
                <ul className="list-items-color-blue">
                  {item.validation.validationData?.map(error => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        )}
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button
          className="modal_cancel btn-sm"
          color="secondary"
          onClick={closeDialog}
        >
          Close
        </Button>
      </ButtonToolbar>
    </Modal>
  );
};
DealValidationModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  failedPayouts: PropTypes.arrayOf(PropTypes.any),
  closeDialog: PropTypes.func.isRequired,

};

DealValidationModal.defaultProps = {
  failedPayouts: [],
};

export default DealValidationModal;
