import React, { useState, useMemo, useEffect } from 'react';

import { axios } from 'ApiClient';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Row, Col, Container, Card, CardBody, CardTitle, Label, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import Filter from '../../../../shared/components/filter/TimePeriodFilter';
import Errors from '../ErrorsV2';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import { clear } from '../../../../shared/helpers/LocalStorageCache';
// import PermissionComponent from '../../../../shared/components/PermissionComponent';
// import { CheckPermission } from '../../../../shared/helpers/WVUtilities';
import Column from './ColumnsCreators';
import Actions from './Actions';
// import { showModalById } from '../../../../redux/reducers/TableReducer';
import Modal from './SettingsModal';
import ProjectedModal from './Modals/ProjectedSettingsModal';
import CPASettingsModal from './Modals/CPASettingsModal';
import ModalPause from './Modals/RebuyPauseModal';
import PauseHistoryModal from './Modals/PauseHistoryModal';
import CreatorLookupModal from './Modals/CreatorLookupModal';
import MediaContentsModals from './Modals/MediaContentsModals';
import BulkDealCreationModal from './Modals/BulkDealCreationModal';
import LoadingReport from "../../../../shared/components/LoadingReport";
import { fetchStreamAsObject } from '../../../../shared/helpers/WVRequest';
// import localResp from './localResp';


const SummaryReport = ({ addNotice }) => {
  // if (!CheckPermission('reports----creators')) return (<PermissionComponent />);
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState([]);
  const [admins, setAdmins] = useState([]);


  const [startDate, setStartDate] = useState(moment().subtract(10, 'years').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState((moment().endOf('day')).format('YYYY-MM-DD'));

  const [errors, setErrors] = useState([]);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [fetched, setFetched] = useState(false);

  const [searchValue, onSearchChange] = useState();
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedDealType, setSelectedDealType] = useState([]);
  const [selectedPauseDuration, setSelectedPauseDuration] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [ROASValue, setROASvalue] = useState(0);
  const [showPredictedCAC, setShowPredictedCAC] = useState({ assigned: true, not: true });
  const [showRebuy, setShowRebuy] = useState(false);
  const [settings, setSettings] = useState({});
  const [projectedSettings, setProjectedSettings] = useState({});
  const [cpaSettings, setCpaSettings] = useState({});
  const [minDate, setMinDate] = useState(null);

  useEffect(() => {
    const a = async () => {
      const resp = await axios.get('/api/portal/reports/get-media-content-min-date');
      setStartDate(resp.data.minDate);
      setMinDate(resp.data.minDate);
    }
    a();
  }, []);
  const admin = useSelector(state => state.session);
  console.log(admin);
  const fetchAmountReport = async () => {
    setLoading(true);
    setItems([]);
    clear();
    try {
      const data = await fetchStreamAsObject(fetch('/api/portal/reports/post-rebuy-trigger-report', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate,
          endDate,
          // brand: selectedAdvertisers?.value,
          identity,
        })
      }))
      /* const resp = await axios.post('/api/portal/reports/post-rebuy-trigger-report', {
        startDate,
        endDate,
        // brand: selectedAdvertisers?.value,
        identity,
      });*/
      // const resp = {
      //   data: localResp,
      // };
      console.log(data, 'data');

      if (data.success) {
        setItems(data.deals);
        setAdmins(data.adminsData);
        const settingsToUse = {
          types: data.cacSettings?.types || {},
          brands: {},
        };
        Object.values(data.cacSettings?.brands || {}).forEach((item) => {
          if (!settingsToUse.brands[item.advertiser]) settingsToUse.brands[item.advertiser] = {};
          if (!settingsToUse.brands[item.advertiser][item.type]) settingsToUse.brands[item.advertiser][item.type] = item;
        });
        setSettings(settingsToUse || { brands: {}, types: {} });
        setProjectedSettings(data.projSettings?.items);
        setCpaSettings(data.cpaSetting);
        setFetched(true);
        setLoading(false);
        setErrors([]);
      } else {
        throw new Error(data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
      setFetched(false);
      setLoading(false);
      setErrors([]);
    }
    return true;
  };
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40, 100],
    placeholder: 'Search...',
    sortBy: 'cac',
    direction: 'asc',
    useFlex: true,
  };

  /* const getDefaultTargetCac = (brand, type) => {
    if (settings[brand._id] && settings[brand._id][type]) return settings[brand._id][type].cac;
    return 450;
  };
  const getDefaultTargetP = (brand, type) => {
    if (settings[brand._id] && settings[brand._id][type]) return settings[brand._id][type].percent / 100;
    return 0.25;
  }; */
  /* useEffect(() => {
    if (minDate) {
      fetchAmountReport();
    }
  }, [startDate, endDate, minDate]); */
  const afterSavePause = useMemo(() => (deal) => {
    setItems((itemsFromState) => {
      const newArray = [...itemsFromState];
      const index = newArray.findIndex(i => i.latestDeal._id === deal._id);
      const oldDeal = { ...newArray[index] };
      console.log('FOUND DEAL', newArray[index], deal);
      // eslint-disable-next-line no-param-reassign
      newArray[index] = { ...oldDeal, latestDeal: deal };
      return newArray;
    });
    console.log('FOUND DEAL', items);
  }, [items]);

  const filteredItems = useMemo(() => {
    const getDefaultTargetCac = (brand, type) => {
      let targetCac = 450;
      let targetP = settings.types ? (settings.types[type] / 100) || 0.25 : 0.25;
      if (settings.brands && settings.brands[brand._id] && settings.brands[brand._id][type]) {
        targetCac = settings.brands[brand._id][type].cac;
        targetP = settings.brands[brand._id][type].percent / 100;
      }
      return targetCac * (1 + targetP);
    };

    console.log('start fill', items);
    const now = moment();
    return items.filter((deal) => {
      let allow = true;
      let isCpaDeal = false;
      if (!settings.brands) return false;
      // eslint-disable-next-line no-param-reassign
      deal.inBlackList = deal.latestDeal?.rebuyPause?.started && moment(deal.latestDeal.rebuyPause?.end) > now;

      if (allow && deal.latestDeal.dealTerms.type_of_deal === 'CPA/Ambassador') {
        isCpaDeal = true;
        // console.log('CPA Allow');
        const diffDays = now.diff(deal.publishDate, 'days');
        const currSettings = cpaSettings.brands && cpaSettings.brands[deal.offer.advertiser._id];
        if (currSettings) {
          allow = false;
          const dayRange = Object.keys(currSettings.items).find(k => diffDays <= Number(k));
          if (currSettings.items[dayRange] && currSettings.items[dayRange].value > 0 && deal.CPApaidConversionsArray?.length >= currSettings.items[dayRange].value) {
            allow = true;
          }
        } else {
          allow = false;
        }
        // if (diffDays > 120) {
        //   allow = false;
        // } else {
        //   // console.log('CPA Allow 120 days');
        //   // console.log(deal);
        //   const currSettings = cpaSettings.brands && cpaSettings.brands[deal.latestDeal.offer.advertiser._id];
        //   if (currSettings) {
        //     // console.log('Settings exists');
        //     allow = false;
        //     const dayRange = Object.keys(currSettings.items).find(k => diffDays <= Number(k));
        //     if (currSettings.items[dayRange].value > 0 && deal.CPApaidConversionsArray.length >= currSettings.items[dayRange].value) {
        //       allow = true;
        //     }
        //   } else {
        //     allow = false;
        //   }
        // }
      }
      if (allow && ((!showRebuy && deal.latestDeal.rebuyPause?.started))) {
        allow = moment(deal.latestDeal.rebuyPause?.end) < now;
      }
      if (allow && showRebuy && selectedPauseDuration && selectedPauseDuration.length > 0) {
        allow = false;
        if (deal.inBlackList) {
          const duration = deal.latestDeal.rebuyPause.history[deal.latestDeal.rebuyPause.history.length - 1].value;
          console.log('duration', duration);
          allow = selectedPauseDuration.map(a => String(a.value)).includes(String(duration));
          console.log('allow', allow);
        }
      }
      if (allow) {
        allow = !deal.contractSent;
      }
      if (allow && selectedAdvertisers && selectedAdvertisers.length > 0) {
        allow = selectedAdvertisers.map(a => String(a.value)).includes(String(deal?.offer?.advertiser?._id));
      }

      const name = `${deal?.creator?.first_name} ${deal?.creator?.last_name} ${deal?.creator?.email}`;
      if (allow && searchValue) {
        allow = name.toLowerCase().includes(searchValue.trim().toLowerCase());
      }

      if (allow && selectedEmployee && selectedEmployee.length > 0) {
        allow = selectedEmployee.map(a => String(a.value)).includes(String(deal?.adminAF?._id));
      }
      if (allow && selectedDealType && selectedDealType.length > 0) {
        allow = selectedDealType.map(a => String(a.value)).includes(String(deal?.latestDeal?.dealTerms.type_of_deal));
      }
      if (allow && !isCpaDeal) {
        const p = ROASValue / 100;
        const targetCac = getDefaultTargetCac(deal?.offer?.advertiser, deal.latestDeal.dealTerms.type_of_deal);

        const over = deal.cac ? ((deal.cac - targetCac) / targetCac) : 0;
        console.log(over, deal.cac, `CALC ${targetCac}`);
        if (over <= p) console.log(over, p, 'with P');
        allow = deal.cac > 0 && over <= p;
      }
      if (allow && !isCpaDeal && ((showPredictedCAC.assigned && !showPredictedCAC.not) || (showPredictedCAC.not && !showPredictedCAC.assigned))) {
        let willShowValue = true;
        if (deal.latestDeal?.dealTerms?.type_of_deal !== 'Upfront CPM') willShowValue = false;
        const cac = (Number((deal.CPApaidConversionsArray?.length || 0) + (deal.CPMpaidConversions?.length || 0)) !== 0 ? Number(deal.cac || 0) : '-');
        const targetCac1 = getDefaultTargetCac(deal.offer?.advertiser, deal.latestDeal.dealTerms.type_of_deal);
        const allowT = cac !== '-' && cac > 0 && targetCac1 >= cac;
        if (allowT) willShowValue = false;
        const days = (deal.publishDate ? moment.tz('America/New_York').diff(moment.tz(moment.utc(deal.publishDate).format('YYYY-MM-DD'), 'America/New_York'), 'days') : 0);
        if (days < 16) willShowValue = false;

        allow = (showPredictedCAC.assigned && willShowValue) || (showPredictedCAC.not && !willShowValue);
      }
      /* if (allow) {
        let willShowValue = true;
        if (deal.latestDeal?.dealTerms?.type_of_deal !== 'Upfront CPM') willShowValue = false;
        const cac = (Number(deal.CPApaidConversionsArray.length + deal.CPMpaidConversions.length) !== 0 ? Number(deal.cac || 0) : '-');
        const targetCac1 = getDefaultTargetCac(deal.offer.advertiser, deal.latestDeal.dealTerms.type_of_deal);
        const allowT = cac !== '-' && cac > 0 && targetCac1 >= cac;
        if (allowT) willShowValue = false;
        const days = (deal.publishDate ? moment.tz('America/New_York').diff(moment.tz(moment.utc(deal.publishDate).format('YYYY-MM-DD'), 'America/New_York'), 'days') : 0);
        if (days < 16) willShowValue = false;

        allow = !willShowValue;
      } */

      return allow;
    });
  }, [items, selectedEmployee, selectedAdvertisers, selectedDealType, searchValue, ROASValue, showRebuy, settings, selectedPauseDuration, showPredictedCAC, cpaSettings]);
  console.log(filteredItems);
  const columns = Column({ settings, afterSavePause, projectedSettings });
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Rebuy Trigger Report', path: false },
            ]}
          />
        </Col>
      </Row>
      {!loading && fetched && errors?.length > 0 ? (
        <Errors errors={errors} startDate={startDate} endDate={endDate} />
      ) : null }
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3 className="d-inline">Rebuy Trigger Report </h3>
              </CardTitle>
              <hr />
              {minDate ? (
                <Row className="mt-3" >
                  <Col>
                    <Label className="bold-text">Publish Date</Label>
                    <Filter
                      startDateInitial={moment(startDate).toISOString()}
                      endDateInitial={moment(endDate).endOf('day').toISOString()}
                      selectedButtonInitial="last_2_years"
                      setValue={(param, value) => {
                        if (param === 'startDate') {
                          setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                        }
                        if (param === 'endDate') {
                          setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                        }
                      }}
                      usedForConversions
                      isLoading={!!loading}
                      options={[
                        { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
                        { value: 'last_month', label: 'Last Month' },
                        { value: 'year_to_date', label: 'Year to Date' },
                        { value: 'last_6_month', label: 'Last 6 Months' },
                        { value: 'last_2_years', label: 'All Time' },
                        {
                          value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
                        },
                      ]}
                      selectDateRangeFunction={(range) => {
                        let dateFrom;
                        let dateTo;
                        let showCustom = false;
                        switch (range) {
                          case 'current_month':
                            showCustom = false;
                            dateFrom = moment().startOf('month').toDate();
                            dateTo = moment().endOf('day').toDate();
                            break;
                          case 'last_month':
                            showCustom = false;
                            dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
                            dateTo = moment().subtract(1, 'month').endOf('month').toDate();
                            break;
                          case 'last_6_month':
                            showCustom = false;
                            dateFrom = moment().subtract(6, 'month').startOf('day').toDate();
                            dateTo = moment().endOf('day').toDate();
                            break;
                          case 'year_to_date':
                            showCustom = false;
                            dateFrom = moment().startOf('year').toDate();
                            dateTo =  moment().endOf('day').toDate();
                            break;
                          case 'last_2_years':
                            showCustom = false;
                            dateFrom = moment().subtract(120, 'months').toDate();
                            dateTo = moment().endOf('day').toDate();
                            // dateFrom = usedForConversions ? moment().subtract(24, 'months').toDate() : moment(allTimeStartDate || '2019-01-01').startOf('day').toDate();
                            // dateTo = moment().endOf('day').toDate();
                            break;
                          case 'custom':
                            showCustom = true;
                            dateFrom = moment().startOf('Year').toDate();
                            dateTo = moment().endOf('day').toDate();
                            break;
                          default:
                            dateFrom = moment().startOf('month').toDate();
                            dateTo = moment().endOf('day').toDate();
                            break;
                        }
                        console.log({ dateFrom, dateTo, showCustom });
                        return { dateFrom, dateTo, showCustom };
                      }
                    }
                    />
                  </Col>
                  <Col lg="auto">
                    <div className="mt-0">
                      <Button
                        color="primary"
                        onClick={async () => {
                          await fetchAmountReport();
                        }}
                        className="btn-sm"
                        disabled={!!loading}
                      >
                        Run Report
                      </Button>
                    </div>
                  </Col>
                </Row>) : null
              }
              {minDate ? (
                <Row className="mt-3">
                  <Col>
                    <Actions
                      selectedEmployee={selectedEmployee}
                      setSelectedEmployee={setSelectedEmployee}
                      selectedAdvertiser={selectedAdvertisers}
                      setSelectedAdvertiser={setSelectedAdvertisers}
                      setSelectedDealType={setSelectedDealType}
                      selectedDealType={selectedDealType}
                      setSelectedPauseDuration={setSelectedPauseDuration}
                      selectedPauseDuration={selectedPauseDuration}
                      onSearchChange={onSearchChange}
                      setROASValue={setROASvalue}
                      ROASvalue={ROASValue}
                      items={filteredItems}
                      settings={settings}
                      showRebuy={showRebuy}
                      setShowRebuy={setShowRebuy}
                      projectedSettings={projectedSettings}
                      showPredictedCAC={showPredictedCAC}
                      setShowPredictedCAC={setShowPredictedCAC}
                    />
                  </Col>
                </Row>) : null
              }
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {/* loading ? (
                <Row className="mt-3">
                  <Col className="text-center">
                    <Spinner size="lg" />
                  </Col>
                </Row>) : null
              */}
              {!loading && fetched ? (
                <Row className="mt-3">
                  <Col>
                    <ReactTableBase
                      key="searchable"
                      columns={columns}
                      data={filteredItems}
                      tableConfig={tableConfig}
                    />
                  </Col>
                </Row>) : null
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal />
      <ProjectedModal />
      <ModalPause />
      <CPASettingsModal />
      {admins?.length ? (
        <PauseHistoryModal adminUsers={admins} />
      ) : null}
      {items?.length ? (
        <CreatorLookupModal deals={items} settings={settings} />
      ) : null}
      <MediaContentsModals />
      <BulkDealCreationModal />
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(SummaryReport);
