import React, { useState, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';

// import PropTypes from 'prop-types';
// import DatePicker from 'react-datepicker';
// import ApiClient from 'ApiClient';
import { Row, Col, Container, Card, CardBody, CardTitle, Button } from 'reactstrap';
import PropTypes from 'prop-types';

// import MatTableForRedux from '../../../../shared/tables/materialTable/components/MatTableForRedux';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import formater from '../../../../shared/helpers/WVFormatter';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';

// import CheckBoxField from '../../../../shared/components/CheckBox';
// import Collapse from '../../../../shared/components/Collapse';
// import HubspotDealLink from '../../../../shared/components/HubspotDealLink';


/* const calculateForDeal = (deal) => {
  let a = deal.payables.reduce((accumulator, currentValue) => {
    // eslint-disable-next-line
    accumulator += Number(currentValue.profit || 0);
    return accumulator;
  }, 0);
  if (deal.bill) {
    a -= Number(deal.billTotal);
  }
  if (deal.invoice) {
    a += Number(deal.invoiceTotal);
  }
  if (deal.currentMonthPayout) {
    a -= Number(deal.currentMonthPayout);
  }
  if (deal.currentMonthRevenue) {
    a += Number(deal.currentMonthRevenue);
  }
  return Number(a || 0);
}; */
/* const calculateRevenueForDeal = (deal) => {
  let a = deal.payables.reduce((accumulator, currentValue) => {
    // eslint-disable-next-line
    accumulator += Number(currentValue.revenueAmount || 0);
    return accumulator;
  }, 0);
  if (deal.currentMonthRevenue) {
    a += Number(deal.currentMonthRevenue);
  }
  return Number(a || 0);
};
*/

const SummaryReport = ({ addNotice }) => {
  const [loading, setLoaing] = useState(false);

  // const [advertisers, setAdvertisers] = useState([]);
  const [items, setItems] = useState([]);

  const [identity] = useState(Math.random().toString(36).substring(2));
  // const [error, setError] = useState(null);
  // const [version, setVersion] = useState((new Date()).toString());

  /* const columnsBrands = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: (deal) => {
          const advertiser = advertisers.find(a => a.tuneId === deal.tuneId);
          return advertiser?.companyName || '';
        },
        id: 'Brand',
        Cell: ({ value }) => value,
      },
      {
        Header: 'Gross Revenue',
        accessor: deal => ((deal.grossRevenue || 0)),
        id: 'grossRevenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Gross Profit',
        accessor: deal => ((deal.grossProfit || 0)),
        id: 'grossProfit',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossProfit) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
    ],
    [advertisers],
  ); */
  const columnsHybridDeals = useMemo(
    () => [
      {
        Header: 'Deal',
        accessor: deal => deal.dealname,
        id: 'dealname',
        Cell: ({ value }) => value,
        width: 250,
      },
      {
        Header: 'Gross Revenue',
        accessor: deal => deal.currentMonthRevenue,
        id: 'grossRevenue',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grossRevenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Gross Payable Payout',
        accessor: deal => deal.currentMonthPayout,
        id: 'grosspayablePayout',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.grosspayablePayout) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'This Month Invoice Amount',
        accessor: deal => deal.invoiceTotal || 0,
        id: 'invoice',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.invoice) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 250,
      },
      {
        Header: 'Invoice Amount',
        accessor: deal => deal.invoice?.amount || 0,
        id: 'invoiceAmount',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.invoiceAmount) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
        width: 150,
      },
      {
        Header: 'Invoice Payments',
        accessor: deal => deal.invoice?.payments || [],
        id: 'invoicePayment',
        Cell: ({ value }) => useMemo(() => {
          console.log(value);
          return (<div>{value.map(p => (<div key={p.date}>{formater.getServerDateTime(p.accountsPayable.startDate)} {p.name} - {formater.formatCurrency(p.amount)}</div>))}</div>);
        }, [value]),
        width: 150,
      },
      {
        Header: 'Not filtered Conversions',
        accessor: deal => deal.initialConversions || 0,
        id: 'initialConversions',
        Cell: ({ value }) => useMemo(() => {
          console.log(value);
          return (<div>{value.map(p => (<div key={p.date}>{formater.getServerDateTime(p.date)}: {formater.formatCurrency(p.revenue)} - {formater.formatCurrency(p.payout)}</div>))}</div>);
        }, [value]),
        width: 200,
      },
      {
        Header: 'Filtered Conversions',
        accessor: deal => deal.conversions || 0,
        id: 'Conversions',
        Cell: ({ value }) => useMemo(() => {
          console.log(value);
          return (<div>{value.map(p => (<div key={p.date}> {formater.getServerDateTime(p.date)}: {formater.formatCurrency(p.revenue)} - {formater.formatCurrency(p.payout)}</div>))}</div>);
        }, [value]),
        width: 200,
      },
      /* {
        Header: 'JSON',
        accessor: deal => JSON.stringify(deal),
        id: 'json',
        Cell: ({ value }) => value,
      }, */
    ],
    [],
  );

  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  const fetchAmountReport = async () => {
    setLoaing(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-fetch-all-deals', {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
        identity,
      });
      if (resp.data.success) {
        setItems(resp.data.items);
        setLoaing(false);
        // setVersion((new Date()).toString());
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const [hybridDealsArray] = useMemo(() => {
    const hybridDeals = [];
    items.forEach((deal) => {
      if (deal.dealType === 'Hybrid') {
        hybridDeals.push(deal);
      }
    });
    window.auditData = {
      hybridDeals,
    };
    return [hybridDeals];
  }, [items]);

  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem' }}>
              <CardTitle>
                <h3>AUDIT CURRENT MONTH</h3>
              </CardTitle>
              <Row className="mt-3">
                <Col lg="auto">
                  <Button
                    color="primary"
                    onClick={async () => {
                      await fetchAmountReport();
                    }}
                    className=""
                    disabled={!!loading}
                  >
                    Run Report
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    topic="report"
                    identity={identity}
                    isShow={!!loading}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!loading && hybridDealsArray.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody style={{ padding: '2rem' }}>
                <CardTitle>
                  <h3>Hybrid current Month</h3>
                </CardTitle>
                <Row >
                  <Col className="mt-3">
                    <ReactTableBase
                      key="searchable3"
                      columns={columnsHybridDeals}
                      data={hybridDealsArray}
                      tableConfig={tableConfig}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>) : null
      }
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
