import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import MatTableList from '../../../../../../../shared/tables/materialTable/TableRedux';
import formater from '../../../../../../../shared/helpers/WVFormatter';
import CreatorInList from './CreatorInList';
import { getConstant } from '@/shared/helpers/WVConstants';
import TableCellContextMenu from '@/shared/components/table/TableCellContextMenu';
import PaymentMethod from './PaymentMethod';
import Payments from './Payments';
import Hook from '@/shared/hooks';

const DealStage = ({ record }) => {
  const dealstages = (getConstant('deal', 'pipelines').find(p => p.label === 'Main Pipeline')).stages;
  const r = record;
  let dealStage = '-';
  if (r.params.dealObject && r.params.dealObject?.dealstage) {
    dealStage = '-';

    if (dealstages[r.params.dealObject.dealstage]) dealStage = dealstages[r.params.dealObject.dealstage];
  }
  return (
    <div>
      { dealStage }
    </div>
  );
};
DealStage.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

const Table = React.memo(() => {
  console.log('table');
  const [showPaymentModal] = Hook.useShowModalHook('markBillAsPaid');
  const reload = Hook.useReloadTableHook('/api/portal/finance/get-unpaid-payable-audit-report');
  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Payments Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'fullSearchName',
          Header: () => 'Creator',
          accessor: () => (''),
          // eslint-disable-next-line max-len
          Cell: p => (<CreatorInList record={p.row.original} />),
          width: 260,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'createdAt',
          Header: () => 'Created At',
          accessor: p => "",
          // eslint-disable-next-line max-len
          Cell: p => moment(p.row.original.params.createdAt).tz('America/New_York').format('MM/DD/YYYY'),
          className: '',
        },
        {
          id: 'paymentMethod',
          Header: () => 'Payment Method',
          accessor: p => "",
          // eslint-disable-next-line max-len
          Cell: p => (<PaymentMethod record={p.row.original} />),
          disableSortBy: true,
          className: '',
        },
        {
          id: 'dealObject.dealTerms.brand',
          Header: () => 'Brand',
          accessor: record => (record?.params?.dealObject?.dealTerms.brand || ''),
          Cell: p => (p.value),
          width: 100,
          className: '',
        },
        {
          id: 'dealObject.dealTerms.type_of_deal',
          Header: () => 'Deal Type',
          accessor: record => (record?.params?.dealObject?.dealTerms.type_of_deal || ''),
          // eslint-disable-next-line max-len
          Cell: p => (p.value),
          width: 100,
          className: '',
        },
        {
          id: 'dealstage',
          Header: () => 'Deal Stage',
          accessor: record => (record?.params?.dealObject?.dealstage || ''),
          Cell: p => (<DealStage record={p.row.original} />),
          className: '',
          width: 100,
          disableSortBy: true,
        },
        {
          id: 'details',
          Header: () => 'Details',
          accessor: (record) => record.populated.accountsPayable.params.name,
          // eslint-disable-next-line max-len
          Cell: p => {
            const payableId = p.row.original.params.accountsPayable;
            const email = p.row.original.params.influencerObject.email;
            return (
              <div>                                                                                                                    
                <span>{p.value || ''}</span> <a target="_blank" rel="noreferrer" href={`/finance/account-payable-report-2/${payableId}/creators?page=1&filters.typeOfDeal=CPA%2FAmbassador&filters.typeOfDeal=Upfront+Only&filters.typeOfDeal=Hybrid&filters.typeOfDeal=Bonus&filters.typeOfDeal=Upfront+CPM&filters.typeOfDeal=Unassigned&filters.status=pending&filters.status=approved&filters.status=complete&filters.status=failed&filters.dealExists=Exists&filters.dealExists=Missing&filters.paymentStatus=Stripe&filters.paymentStatus=Bill.com&filters.paymentStatus=Not+Connected&filters.authorized=Authorized&filters.authorized=Not+Authorized&filters.searchFullName=${encodeURIComponent(email)}`} title="Open Payable"><i className="fa fa-link" /></a>
              </div>
            );
          },
          width: 100,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'amount',
          Header: () => 'Amount From HO',
          accessor: record => (record?.params?.payoutAmount || ''),
          Cell: p => (formater.formatCurrency(Number(p.value || 0))),
          className: 'd-flex align-items-center',
          
        },
        {
          id: 'amountDue',
          Header: () => 'Amount',
          accessor: record => (record?.params?.ilPayoutAmount || ''),
          Cell: p => (formater.formatCurrency(Number(p.value || 0))),
          className: 'd-flex align-items-center',
        },
        {
          id: 'billComPayments',
          Header: () => 'Bill.com Payments',
          accessor: p => "",
          // eslint-disable-next-line max-len
          Cell: p => (<Payments record={p.row.original} />),
          width: 180,
          className: 'd-flex align-items-center',
          disableSortBy: true,
        },
        {
          Header: '',
          accessor: () => null,
          id: 'actions',
          width: 50,
          Cell: (p) => {
            console.log(p)
            const contextMenuOptions = [
              {
                label: 'View HubSpot Deal',
                handler: () => window.open(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original?.params?.dealObject.hs_object_id}/`, '_blank').focus()
              },
              {
                label: 'Mark As Paid',
                handler: () => showPaymentModal({
                  deal: p.row.original.params.dealObject,
                  payable: p.row.original.params,
                  influencer: p.row.original.params.influencerObject,
                  reload,
                }),
              },
            ];
            return (
              <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                <TableCellContextMenu options={contextMenuOptions} />
              </div>
            );
          },
          disableSortBy: true,
          className: 'd-flex align-items-center align-middle',
        },
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
