import React from 'react';
import CopyToClipboardLeft from '../../../../../../shared/components/CopyToClipboardLeft';
import { CopyIcon } from '../../../../../../shared/helpers/Icons';

export default ({ property, record }) => {
  const value = record?.[property.name];
  return value ? (
    <CopyToClipboardLeft
      value={value}
      icon={(<span style={{color: '#70bbfd'}}><CopyIcon/></span>)}
    >
      {value}
    </CopyToClipboardLeft>
  ) : '';
};
