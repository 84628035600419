/* eslint react/prop-types: 0 */
import React from 'react';
import styled from 'styled-components';

import RecordInList from './record-in-list';
import RecordsTableHeader from './records-table-header';
import NoRecords from './no-records';
import Table from '../../ui/table';

import SelectedRecords from './selected-records';

const RecordsTableWrapper = styled.section`
  position: relative;
`;


const RecordsTable = (props) => {
  const {
    resource, records,
    actionPerformed, sortBy,
    direction, isLoading,
    onSelect, selectedRecords,
    onSelectAll,
  } = props;
  if (!records.length) {
    return (<NoRecords resource={resource} />);
  }

  const selectedAll = !!records.find(record => (
    selectedRecords.find(selected => selected.id === record.id)
  ));

  return (
    <RecordsTableWrapper>
      <SelectedRecords
        resource={resource}
        selectedRecords={selectedRecords}
      />
      <Table>
        <RecordsTableHeader
          properties={resource.listProperties}
          titleProperty={resource.titleProperty}
          direction={direction}
          sortBy={sortBy}
          onSelectAll={onSelectAll}
          selectedAll={selectedAll}
        />
        <tbody>
          {records.map(record => (
            <RecordInList
              record={record}
              resource={resource}
              key={record.id}
              actionPerformed={actionPerformed}
              isLoading={isLoading}
              onSelect={onSelect}
              isSelected={!!selectedRecords.find(selected => selected.id === record.id)}
            />
          ))}
        </tbody>
      </Table>
    </RecordsTableWrapper>
  );
};

export default RecordsTable;
