import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';

const HumanSentiment = ({ filtersCommentSentiment, handleChangeSentimentFilters }) => (
  <FormGroup>
    <Label className="bold-text">
      Human Sentiment&nbsp;
      {/* <Tooltip title="Info for Human Sentiment">
                    <IconButton
                      size="small"
                      onClick={() => false}
                      className="material-table__toolbar-button"
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </IconButton>
                  </Tooltip> */}
    </Label>
    <div>
      <CheckBoxField
        name="Unreviewed"
        label="Unreviewed"
        className="inline"
        value={filtersCommentSentiment.unreviewed}
        onChange={event => handleChangeSentimentFilters(event, 'unreviewed')}
      />
      <CheckBoxField
        name="Positive"
        label="Positive"
        className="inline ml-4"
        value={filtersCommentSentiment.positive}
        onChange={event => handleChangeSentimentFilters(event, 'positive')}
      />
      <CheckBoxField
        name="Negative"
        label="Negative"
        className="inline ml-4"
        value={filtersCommentSentiment.negative}
        onChange={event => handleChangeSentimentFilters(event, 'negative')}
      />
      <CheckBoxField
        name="Ignore"
        label="Ignore"
        className="inline ml-4"
        value={filtersCommentSentiment.ignore}
        onChange={event => handleChangeSentimentFilters(event, 'ignore')}
      />
    </div>
  </FormGroup>
);

HumanSentiment.propTypes = {
  filtersCommentSentiment: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeSentimentFilters: PropTypes.func.isRequired,
};

export default HumanSentiment;
