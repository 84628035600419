import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import queryHasFilter from '@/shared/ViewHelpers/QueryHasFilter';
import Breadcrumbs from '../../shared/components/BreadCrumbsResource';
import ActionHeader from '../../shared/components/components/app/action-header';
import Filter from '../../shared/components/components/app/filter';
import { NoResourceError, NoActionError } from '../../shared/components/components/ui/error404';

import BaseAction from '../../shared/components/components/app/base-action-component';


const ResourceAction = (props) => {
  const { match, location } = props;
  const { resourceId, actionName } = match.params;
  // console.log('start resource action', props);
  const { resources } = window.REDUX_STATE;
  const [filterVisible, setFilerVisible] = useState(queryHasFilter(location.search));
  const [tag, setTag] = useState('');
  const resource = resources.find(r => r.id === resourceId);
  if (!resource) {
    return (<NoResourceError resourceId={resourceId} />);
  }
  const action = resource.resourceActions.find(r => r.name === actionName);
  if (!action) {
    return (<NoActionError resourceId={resourceId} actionName={actionName} />);
  }


  /*
 * <ActionHeader
        resource={resource}
        action={action}
        tag={tag}
        toggleFilter={action.showFilter ? () => setFilerVisible(!filterVisible) : undefined}
      />
  <BaseAction action={action} resource={resource} setTag={setTag} />
  {action.showFilter ? (
        <Filter
          resource={resource}
          isVisible={filterVisible}
          toggleFilter={() => { setFilerVisible(!filterVisible) }}
        />
      ) : ''}
 */
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            resource={resource}
            actionName={actionName}
            isBackButton
            isBackAllowHistory
          />
          <ActionHeader
            resource={resource}
            action={action}
            tag={tag}
            toggleFilter={action.showFilter ? () => setFilerVisible(!filterVisible) : undefined}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div>
            <BaseAction action={action} resource={resource} setTag={setTag} />
          </div>
        </Col>
      </Row>
      {action.showFilter ? (
        <Filter
          resource={resource}
          isVisible={filterVisible}
          toggleFilter={() => { setFilerVisible(!filterVisible); }}
        />
      ) : ''}
    </Container>
  );
};

ResourceAction.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  paths: state.paths,
  resources: state.resources,
  session: state.session,
});

export default connect(mapStateToProps)(ResourceAction);