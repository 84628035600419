import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ReactTableBase from '../../../../../../shared/tables/table/ReactTableBase';
import { getFormulas, showFormulaModalDialog, showDeleteModalDialog } from '../../../../../../redux/reducers/admin/ResearchReducer';
import FormulaForm from './FormulaForm';
import DeleteModalDialog from './DeleteModalDialog';

const FormulasListContainer = ({
  match,
  dispatch,
  formulas,
  loading,
  showFormulaDialog,
  showDeleteDialog,
  formulaTokens,
}) => {
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value }) => value,
      },
      {
        Header: 'Formula',
        accessor: 'formula',
        Cell: ({ value }) => value,
        disableGlobalFilter: true,
      },
      {
        Header: 'Format',
        accessor: 'format',
        Cell: ({ value }) => {
          switch (value) {
            case 'currency':
              return 'Currency ($1,000.00)';
            case 'decimal':
              return 'Decimal (100.00)';
            case 'integer':
              return 'Integer (100)';
            case 'text':
              return 'Text (ABC)';
            default:
              return 'Not set';
          }
        },
        disableGlobalFilter: true,
      },
      {
        Header: '',
        accessor: '_id',
        id: 'action',
        disableGlobalFilter: true,
        width: 65,
        Cell: ({ value }) => useMemo(() => (
          <div>
            <Tooltip title="Edit Formula">
              <IconButton
                size="small"
                onClick={() => {
                  setEditId(value);
                  dispatch(showFormulaModalDialog(true));
                }}
                className="material-table__toolbar-button"
              >
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            <Tooltip title="Delete Formula">
              <IconButton
                size="small"
                onClick={() => {
                  setDeleteId(value);
                  dispatch(showDeleteModalDialog(true));
                }}
                className="material-table__toolbar-button"
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Tooltip>
          </div>
        ), [value]),
        disableSortBy: true,
      },
    ],
    [],
  );
  const getData = () => {
    if (match) {
      dispatch(getFormulas());
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!showFormulaDialog) {
      setEditId(null);
    }
  }, [showFormulaDialog]);

  useEffect(() => {
    if (!showDeleteDialog) {
      setDeleteId(null);
    }
  }, [showDeleteDialog]);

  const addFormula = () => {
    dispatch(showFormulaModalDialog(true));
  };

  return (
    <>
      <Row>
        <Col md="3">
          <h2 style={{ marginBottom: '15px' }}>Formulas</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <button onClick={addFormula} className="btn btn-primary btn-sm mt-2">Add Formula</button>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col sm="12" className="research-dashboard-container">
              {!loading && (
                <ReactTableBase
                  key="searchable"
                  columns={columns}
                  data={formulas}
                  tableConfig={tableConfig}
                />
              )}
            </Col>
          </Row>
          <FormulaForm id={editId} tokens={formulaTokens} />
          <DeleteModalDialog id={deleteId} />
        </CardBody>
      </Card>
    </>
  );
};
FormulasListContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  formulas: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  dispatch: PropTypes.func.isRequired,
  showFormulaDialog: PropTypes.bool.isRequired,
  showDeleteDialog: PropTypes.bool.isRequired,
  formulaTokens: PropTypes.objectOf(PropTypes.any),
};
FormulasListContainer.defaultProps = {
  formulas: null,
  formulaTokens: null,
};
const mapStateToProps = state => ({
  loading: state.ResearchReducer.loading.formulas,
  formulas: state.ResearchReducer.formulas,
  showFormulaDialog: state.ResearchReducer.showFormulaDialog,
  showDeleteDialog: state.ResearchReducer.showDeleteDialog,
  formulaTokens: state.ResearchReducer.formulaTokens,
});

export default connect(mapStateToProps)(withRouter(FormulasListContainer));
