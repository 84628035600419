const { checkImagePromise } = require('../WVUtilities');

exports.ProgramOptions = [
  { value: '', label: '-- Not Assigned --' },
  { value: 'CPA', label: 'CPA' },
  { value: 'CPM', label: 'CPM' },
];

exports.getLogoUrl = async (prospect) => {
  const platforms = ['youtube', 'instagram', 'facebook', 'twitter'];
  for (let i = 0; i < platforms.length; i += 1) {
    const platform = platforms[i];
    const account = prospect?.tubularDetails?.accounts.find(a => a.platform === platform);
    // eslint-disable-next-line no-await-in-loop
    if (account?.thumbnailUrl && await checkImagePromise(account.thumbnailUrl)) {
      return account.thumbnailUrl;
    }
  }
  return '/images/user/defaultAvatar.png';
};

exports.isBrandRejected = (prospect) => {
  const { brandStatus } = prospect;
  const brandHistory = prospect.approvalStatus?.brand?.history || [];
  const ilHistory = prospect.approvalStatus?.influencelogic?.history || [];
  if (brandStatus !== 'Rejected') {
    return false;
  }
  for (let i = 0; i < ilHistory.length; i += 1) {
    if (
      ['Approved', 'Hold', 'In Review', 'Rejected', 'Resubmit'].includes(ilHistory[i].selectedStatus)
      && ilHistory[i].dateModified > brandHistory[brandHistory.length - 1].dateModified
    ) {
      return false;
    }
  }
  return true;
};
