import React, { useState, useEffect, useCallback } from 'react';
import {
  FormGroup,
  Label,
  Modal,
  Spinner,
  Input,
  ModalHeader,
  ModalBody,
  ButtonToolbar,
  Button,
} from 'reactstrap';
import ApiClient, { axios } from 'ApiClient';
import Select, { components } from 'react-select';
import SelectAsync from 'react-select/async';
import recordToFormData from '@/shared/components/components/actions/record-to-form-data';
import Hook from 'ILHooks';
import Alert from '@/shared/easydev2/shared/components/Alert';

const formatContactOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div>{record?.params.firstname} {record?.params.lastname}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record?.params.email}
    </div>
  </div>
);

const contactOption = (props) => {
  const { record } = props.data;
  return (
    <components.Option {...props} >
      <div>
        <div>{record?.params.firstname} {record?.params.lastname}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record?.params.email}
        </div>
      </div>
    </components.Option>
  );
};

const requiredData = [
  'contact', 'account_manager_id', 'company', 'email', 'first_name', 'last_name',
];
export const modalId = 'InfluencerForm';


const InfluencersForm = () => {
  const { data: dataModal, showModal: modal, close: closeDialog } = Hook.useModalHook(modalId);
  const { showError, showSuccess, showWarning } = Hook.useNoticeHook()
  const [category, setCategory] = useState(null);
  const [selectedHubilContact, setSelectedHubilContact] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [loading, setLoading] = useState({ form: false });
  const [influencer, setInfluencer] = useState({ params: {}, errors: {} });
  const [hsContactInBlacklist, setHsContactInBlacklist] = useState(false);


  const loadOwnersOptions = useCallback(async (inputValue) => {
    console.log(inputValue, ApiClient);
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'Employee',
      query: inputValue,
    });
    return records.map(record => ({
      value: record.id,
      label: record.params.email,
      record,
    }));
  }, [ApiClient]);

  const loadHubilContactsOptions = useCallback(async (inputValue) => {
    const data = inputValue ? await axios.getSafety('/api/portal/hubil/get-contact-search', {
      params: {
        param: 'email',
        value: inputValue,
      },
    }) : { records: [] };
    return data?.records?.map(record => ({
      value: record.id,
      label: record.params.email !== null ? record.params.email : `${record.params.firstname} ${record.params.lastname}`,
      params: record.params,
      record,
    })) || [];
  }, [axios]);

  useEffect(() => {
    if (modal) {
      setInfluencer({
        params: { email: '', first_name: '', last_name: '', company: '', contact: '' },
        errors: {},
      });
    }
  }, [modal]);

  if (!dataModal) return false;
  const handleChange = (key, value) => {
    setInfluencer(prev => ({
      ...prev,
      params: {
        ...prev.params,
        [key]: value,
      },
      errors: {
        ...prev.errors,
        [key]: undefined,
      },
    }));
  };

  const handleChangeCategory = (selected) => {
    setCategory(selected);
    handleChange('category', undefined);
  };

  const handleChangeHubilContact = (selected) => {
    if (selected) {
      const { params } = selected.record;
      setSelectedHubilContact(selected.record);
      handleChange('contact', params._id ?? '');
      handleChange('email', params.email ?? '');
      handleChange('first_name', params.firstname ?? '');
      handleChange('last_name', params.lastname ?? '');
      handleChange('company', params.company ?? '');

      if (params.hubspot_owner_id) {
        axios.getSafety(`/api/portal/influencers/get-employee-by-hs-id?hsUserId=${params.hubspot_owner_id}`)
          .then((data) => {
            const { success, employee } = data;
            if (success && employee) {
              handleChange('account_manager_id', employee.tuneId);
              setSelectedOwner({ id: employee.tuneId, title: employee.email });
            }
          });
      }
      setHsContactInBlacklist(!!params.blacklist_);
    }
  };

  const handleChangeOwnerSelect = (selected) => {
    if (selected) {
      setSelectedOwner(selected.record);
      handleChange('account_manager_id', selected.record.params.tuneId ?? '');
    }
  };

  const handleInputChange = (name, event) => {
    const { type, checked, value } = event.target;
    handleChange(name, type === 'checkbox' ? checked : value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    requiredData.forEach((item) => {
      if (!influencer.params[item]) {
        isValid = false;
        handleChange(item, undefined);
      }
    });

    if (!category?.value) {
      isValid = false;
      handleChange('category', undefined);
    }

    if (!isValid) {
      showWarning('Please Check form. Seems you did not fill some fields');
      return;
    }

    const formData = recordToFormData(influencer);
    setLoading({ form: true });

    axios.post('/api/portal/influencers/create-tune-influencer', Object.fromEntries(formData))
      .then((response) => {
        const { success, affiliateId, error } = response.data;
        setLoading({ form: false });
        if (success) {
          setSelectedHubilContact(null);
          setSelectedOwner(null);
          closeFormDialog();
          showSuccess('New Influencer has been successfully added');
          axios.postSafety('/api/portal/finance/post-save-influencer', { tuneId: affiliateId, category: category?.value });
        } else {
          showError(error);
        }
      }).catch((error) => {
        console.error(error);
        setLoading({ form: false });
        showError('There was an error creating record.');
      });
  };

  const closeFormDialog = () => {
    setCategory(null);
    setSelectedHubilContact(null);
    setSelectedOwner(null);
    setHsContactInBlacklist(false);
    closeDialog();
  };

  const selectedHubilContactOption = selectedHubilContact && {
    value: selectedHubilContact.id,
    label: selectedHubilContact.title,
    record: {
      params: {
        _id: selectedHubilContact.id,
        firstname: selectedHubilContact.params.firstname,
        lastname: selectedHubilContact.params.lastname,
        email: selectedHubilContact.params.email,
      },
    },
  };

  const selectedOwnerOption = selectedOwner && {
    value: selectedOwner.id,
    label: selectedOwner.title,
  };
  const { modalTitle, categories } = dataModal;

  return (
    <Modal isOpen={modal} toggle={closeFormDialog}>
      <ModalHeader toggle={closeFormDialog} tag="h4" className="modal-title-statistics">
        {modalTitle}
      </ModalHeader>
      <ModalBody>
        {loading.form ? (
          <div className="text-center"><Spinner color="primary" size="lg" /></div>
        ) : (
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>HubSpot Contact</Label>
              {selectedHubilContact && (
                <>&nbsp;&nbsp;<a title="View Contact on HubSpot" rel="noreferrer" target="_blank" href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${selectedHubilContact.params.hs_object_id}/`}><i className="fas fa-external-link-alt" /></a></>
              )}
              <SelectAsync
                cacheOptions
                value={selectedHubilContactOption}
                defaultOptions
                loadOptions={loadHubilContactsOptions}
                onChange={handleChangeHubilContact}
                placeholder="Select Hubpost Contact"
                formatOptionLabel={formatContactOptionLabel}
                components={{ Option: contactOption }}
              />
              {influencer.errors.contact && (<span className="text-danger">{influencer.errors.contact}</span>)}
              {!influencer.errors.contact && (<small style={{ color: 'grey' }}>Contact data is used to help pre-fill all remaining fields</small>)}
              {hsContactInBlacklist && (
                <Alert color="warning" className="w-100 mt-3" bordered icon>
                  <p className="py-2 text-dark">
                    This email address is on the blacklist and blocked from being added. <a href="/influencers/contacts" target="_blank" rel="noopener">Manage Blacklist</a>
                  </p>
                </Alert>
              )}
            </FormGroup>
            <hr />
            <FormGroup>
              <Label>Account Manager</Label>
              <SelectAsync
                cacheOptions
                value={selectedOwnerOption}
                defaultOptions
                loadOptions={loadOwnersOptions}
                onChange={handleChangeOwnerSelect}
                placeholder="Select Account Manager"
                isDisabled={hsContactInBlacklist}
              />
              {influencer.errors.account_manager_id && (<span className="text-danger">{influencer.errors.account_manager_id}</span>)}
            </FormGroup>
            <FormGroup>
              <Label>Company</Label>
              <Input
                type="text"
                id="company"
                name="company"
                placeholder='Company'
                onChange={(e) => handleInputChange('company', e)}
                value={influencer.params.company || ''}
                disabled={hsContactInBlacklist}
              />
              {influencer.errors.company && (<span className="text-danger">{influencer.errors.company}</span>)}
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="text"
                id="email"
                name="email"
                placeholder='Email'
                onChange={(e) => handleInputChange('email', e)}
                value={influencer.params.email || ''}
                disabled={hsContactInBlacklist}
              />
              {influencer.errors.email && (<span className="text-danger">{influencer.errors.email}</span>)}
            </FormGroup>
            <FormGroup>
              <Label>First Name</Label>
              <Input
                type="text"
                id="first_name"
                name="first_name"
                placeholder='First Name'
                onChange={(e) => handleInputChange('first_name', e)}
                value={influencer.params.first_name || ''}
                disabled={hsContactInBlacklist}
              />
              {influencer.errors.first_name && (<span className="text-danger">{influencer.errors.first_name}</span>)}
            </FormGroup>
            <FormGroup>
              <Label>Last Name</Label>
              <Input
                type="text"
                id="last_name"
                name="last_name"
                placeholder='Last Name'
                onChange={(e) => handleInputChange('last_name', e)}
                value={influencer.params.last_name || ''}
                disabled={hsContactInBlacklist}
              />
              {influencer.errors.last_name && (<span className="text-danger">{influencer.errors.last_name}</span>)}
            </FormGroup>
            <FormGroup>
              <Label>Category</Label>
              <Select
                value={category}
                options={categories}
                onChange={handleChangeCategory}
                placeholder="Category"
                isDisabled={hsContactInBlacklist}
              />
              {influencer.errors.category && (<span className="text-danger">{influencer.errors.category}</span>)}
            </FormGroup>
            <ButtonToolbar className="modal__footer">
              <Button
                className="modal_ok btn-sm is-primary"
                color="primary"
                type="submit"
                disabled={hsContactInBlacklist}
              >
                Save
              </Button>
              &nbsp;&nbsp;
              <Button
                className="modal_cancel btn-sm"
                color="secondary"
                onClick={closeFormDialog}
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        )}
      </ModalBody>
    </Modal>
  );
};

export default InfluencersForm;
