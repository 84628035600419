import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

export default class ExecuteModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    status: PropTypes.string,
    url: PropTypes.string,
    message: PropTypes.string,
    close: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    status: '',
    url: '',
    message: '',
  };

  render() {
    const {
      isOpen,
      status,
      url,
      message,
      close,
    } = this.props;
    return (
      <div style={{ display: 'inline' }}>
        <Modal
          isOpen={isOpen}
          toggle={close}
          modalClassName="ltr-support"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={close} />
          </div>
          <div className="modal__body">
            <p>
              {status}
              <br />
              {message}<a href={url} target="_blank" rel="noopener noreferrer">Logs</a>
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}

