import React from 'react';
import PropTypes from 'prop-types';

const HubspotDealLink = ({
  id,
  title,
}) => (
  <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${id}/`} target="_blank" title={title || 'Open Hubspot Deal'} rel="noreferrer noopener">{title || 'Open Hubspot Deal'}</a>
);
HubspotDealLink.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};
export default HubspotDealLink;
