import React, { useEffect, useState } from 'react';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CurrencyField from '../../../../../../../shared/components/form/CurrencyFieldF';

const Amount = React.memo(({
  record, updateRecordFunction,
}) => {
  const [amount, setAmount] = useState(record.params?.amount?.amount || 0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAmount(record.params?.amount?.amount || 0);
  }, [record]);

  const handleChange = async () => {
    setLoading(true);

    const { id } = record;
    const data = await axios.post('/api/portal/prospects/post-update-property-on-cpm-prospect', {
      ids: [id],
      values: {
        amount,
      },
    });
    setLoading(false);
    if (data?.data?.recordPopulated && updateRecordFunction) {
      updateRecordFunction(data?.data?.recordPopulated);
    }
    return true;
  };
  return (
    <div className="px-2">
      <CurrencyField
        placeholder="Payout Amount"
        disabled={loading}
        value={amount}
        setValue={value => setAmount(value)}
        onBlur={handleChange}
      />
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

Amount.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};

export default Amount;
