/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import { withStyles } from '@material-ui/core/styles';
import MatTableList from '../../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../../shared/components/BreadCrumbs';
import UserPropertyType from '../../components/custom';
import Actions from './Actions';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';

const links = [
  { title: 'Home', path: '/' },
  { title: 'Admin', path: false },
  { title: 'Users', path: false },
];

const ResourceAction = ({
  history,
  resource,
  setTag,
  action,
}) => {
  const filters = parseUrl();
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [version, setVersion] = useState((new Date()).toString());
  const [showDeleted, setShowDeleted] = useState(false);

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    search.delete('filters.deleted');
    if (showDeleted === false) {
      search.append('filters.deleted', false);
    }
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }
    return search;
  };

  const setUrl = () => {
    const search = getUrlSearch();
    search.set('page', '1');
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 500);

  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
    showDeleted,
  ]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs links={links} isBackButton />
        </Col>
      </Row>
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Admin Users</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Actions
            onSearchChange={onSearchChange}
            searchValue={searchValue}
            showDeleted={showDeleted}
            setShowDeleted={setShowDeleted}
            availablePermissions={resource?.properties?.permissions?.availableValues || ''}
          />
          <div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
            <MatTableList
              showTitle={false}
              isResizable={false}
              action={action}
              resource={resource}
              setTag={setTag}
              date={new Date()}
              showFilters={false}
              hideToolbar
              UserPropertyType={UserPropertyType}
              sortBy="firstName"
              direction="asc"
              version={version}
              meta={{ closeFunction: () => setVersion((new Date()).toString()) }}
              columnsSettings={{
                firstName: {
                  width: 100,
                },
                lastName: {
                  width: 100,
                },
                email: {
                  width: 250,
                },
                role: {
                  width: 150,
                },
                createdAt: {
                  width: 150,
                },
                lastLoginDate: {
                  width: 150,
                },
                actions: {
                  width: 50,
                },
              }}
              v={2}
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default withStyles({
  root: {
    width: '100%',
    minWidth: '200px',
    paddingRight: '30px',
    position: 'relative',
    marginBottom: '10px',
  },
  close: {
    position: 'absolute',
    bottom: '1px',
    right: '0px',

  },
})(connect(mapStateToProps)(ResourceAction));
