/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, ButtonToolbar, Button, ModalHeader, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import PropertyHistoryComponent from './PropertyHistoryComponent';
import { setShowReviewStatusHistoryDialog } from '../../../../../../../redux/reducers/admin/ScoringReducer';
import Select from 'react-select';

const propertiesOptions = [
  { value: 'all', label: 'All Properties'},
  { value: 'status', label: 'Content Review Status'},
  { value: 'title', label: 'Title' },
  { value: 'contentText', label: 'Description' },
  { value: 'duration', label: 'Duration' },
  { value: 'privacyStatus', label: 'Privacy Status' },
];

const concatArrays = (...arrays) => {
  return [].concat(...arrays);
};

const sortByDateModified = (arr) => {
  arr.sort((a, b) => new Date(b.dateModified) - new Date(a.dateModified));
  return arr;
};

const ReviewStatusHistoryModal = ({
  showHistoryReviewStatusDialog,
  mediaContentObj,
  dispatch,
}) => {
  const [mediaContent, setMediaContent] = useState(mediaContentObj?.params || null);
  const [changesHistory, setChangesHistory] = useState([]);
  const [propertyOption, setPropertyOption] = useState({ value: 'all', label: 'All Properties'});

  useEffect(() => {
    setMediaContent(mediaContentObj?.params || null);
  }, [mediaContentObj]);

  useEffect(() => {
    const statusArray = ['all', 'status'].includes(propertyOption.value) ? (mediaContent?.contentReviewStatus?.reviewStatusHistory || [])
      .reduce((acc, cur) => {
        const items = cur.status.map(i => ({ ...i, property: 'status', statusCode: cur.statusCode }))
        acc = acc.concat(items);
        return acc;
      }, []) : [];
    const titleArray = ['all', 'title'].includes(propertyOption.value) ? (mediaContent?.history?.title || []).map(i => ({ ...i, property: 'title' })) : [];
    const contentTextArray = ['all', 'contentText'].includes(propertyOption.value) ? (mediaContent?.history?.contentText || []).map(i => ({ ...i, property: 'contentText' })) : [];
    const privacyStatusArray = ['all', 'privacyStatus'].includes(propertyOption.value) ? (mediaContent?.history?.privacyStatus || []).map(i => ({ ...i, property: 'privacyStatus' })) : [];
    const durationArray = ['all', 'duration'].includes(propertyOption.value) ? (mediaContent?.history?.duration || []).map(i => ({ ...i, property: 'duration' })) : [];

    setChangesHistory(
      sortByDateModified(
        concatArrays(
          statusArray,
          titleArray,
          contentTextArray,
          privacyStatusArray,
          durationArray,
        ),
      )
    );
  }, [mediaContent, propertyOption]);

  const toggle = () => {
    setChangesHistory([]);
    setPropertyOption({ value: 'all', label: 'All Properties'});
    dispatch(setShowReviewStatusHistoryDialog(false));
  }

  return (
    <Modal
      isOpen={showHistoryReviewStatusDialog}
      toggle={toggle}
    >
      <ModalHeader
        toggle={toggle}
        tag="h3"
        className="modal-title-statistics"
      >
        Content Review History
      </ModalHeader>
      <div style={{ minHeight: '400px', maxHeight: '600px', overflowY: 'auto' }}>
        <div style={{ marginTop: '15px', marginBottom: '30px' }}>
          <span>
            <strong>Current Content Review Status: </strong>
            {mediaContent?.contentReviewStatus?.currentStatus || 'N/A'}
          </span>
        </div>
        <span style={{ color: '#70bbfd', marginRight: '15px', fontSize: '18px' }}>Change History:</span>
        <FormGroup
          style={{ minWidth: '180px', maxWidth: '180px', marginBottom: 0 }}
          className="d-inline-block"
        >
          <Select
            value={propertyOption}
            options={propertiesOptions}
            onChange={setPropertyOption}
          />
        </FormGroup>
        {changesHistory.length > 0 ? (
          <div
            className="mt-2"
            style={{ maxHeight: '400px', overflowY: 'auto' }}
          >
          {changesHistory.map((item) => (
            <PropertyHistoryComponent
              key={`property${item.property}${item.dateModified}`}
              item={item}
            />
          ))}
        </div>
        ) : <p>There is no updates yet.</p>}
      </div>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" color="secondary" onClick={toggle}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
}

ReviewStatusHistoryModal.propTypes = {
  showHistoryReviewStatusDialog: PropTypes.bool.isRequired,
  mediaContentObj: PropTypes.objectOf(PropTypes.any),
  dispatch: PropTypes.func.isRequired,
};

ReviewStatusHistoryModal.defaultProps = {
  mediaContentObj: {},
};

const mapStateToProps = state => ({
  showHistoryReviewStatusDialog: state.score.showHistoryReviewStatusDialog,
  mediaContentObj: state.score.mediaContent,
});

export default connect(mapStateToProps)(ReviewStatusHistoryModal);
