import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Input } from 'reactstrap';
import { splitString, fixInputCursor } from '../Form';
import { momentLocaleToServerUTC, momentUTCToLocalDate } from '../Dates';
import CurrencyField from '../../components/form/CurrencyField';

const standardFieldDefaults = {
  dataType : 'text',
  readOnly: 1,
  required: 1,
};

export const standardFields = {
  Creator: {
    key: 'Creator',
    agreementKey: 'creatorName',
    defaults: { ...standardFieldDefaults, ilUILabel: 'Creator Name' },
  },
  Brand: {
    key: 'Brand',
    defaults: { ...standardFieldDefaults, ilUILabel: 'Brand' },
  },
  CreatorContact: {
    key: 'CreatorContact',
    defaults: {
      dataType: 'text',
      readOnly: 0,
      required: 1,
      ilUILabel: 'Contact Name',
    },
  },
  CreatorEmail: {
    key: 'CreatorEmail',
    defaults: {
      dataType: 'text',
      readOnly: 0,
      required: 1,
      ilUILabel: 'Contact Email',
    },
  },
  CreatorLink: {
    key: 'CreatorLink',
    agreementKey: 'creatorUrl',
    defaults: { ...standardFieldDefaults, ilUILabel: 'Creator Vanity URL' },
  },
  AdditionalTerms: {
    key: 'AdditionalTerms',
    defaults: {
      dataType: 'textarea',
      readOnly: 0,
      required: 0,
      ilUILabel: 'Additional Terms',
    },
  },
  Compensation: {
    key: 'Compensation',
    agreementKey: 'compensationText',
    defaults: { ...standardFieldDefaults, ilUILabel: 'Compensation' },
  },
  CPA: {
    key: 'CPA',
    agreementKey: 'cpaText',
  },
  Company: {
    key: 'Company',
    defaults: {
      dataType: 'text',
      readOnly: 0,
      required: 1,
      ilUILabel: 'Company',
    },
  },
  Channel: {
    key: 'Channel',
    agreementKey: 'creatorChannel',
    defaults: { ...standardFieldDefaults, ilUILabel: 'Creator Channel URL' },
  },
  AgentContact: {
    key: 'AgentContact',
    defaults: {
      dataType: 'text',
      readOnly: 1,
      required: 0,
      ilUILabel: 'Agent Contact',
    },
  },
  AgentEmail: {
    key: 'AgentEmail',
    defaults: {
      dataType: 'text',
      readOnly: 1,
      required: 0,
      ilUILabel: 'Agent Email',
    },
  },
  Agency: {
    key: 'Agency',
    agreementKey: 'agencyName',
    defaults: {
      dataType: 'text',
      readOnly: 1,
      required: 0,
      ilUILabel: 'Agency',
    },
  },
  FacebookAccessText: { key: 'FacebookAccessText' },
  ExclusivityText: { key: 'ExclusivityText' },
};

export const dataTypes = {
  m: 'Date (Month, Jan)',
  my: 'Date (Month/Year, Jan, 2024)',
  mdy: 'Date (Month/Day/Year, Jan 1, 2024)',
  y: 'Date (Year, 2024)',
  currency: 'Currency (USD)',
  text: 'Text',
  textarea: 'Textarea',
};

const dateFormats = {
  m: 'MMM',
  my: 'MMM, yyyy',
  mdy: 'MMM d, yyyy',
  y: 'yyyy',
};

export const CustomInput = ({
  customDataField,
  value,
  dataType,
  onChange,
  disabled,
  maxLength,
  maxLines,
}) => {
  const onChangeTextarea = (e) => {
    const { name, value, selectionStart } = e.target;
    const val = (maxLines > 1) ? splitString(value, maxLength) : value;
    onChange(name, val, () => {
      if (val !== value) fixInputCursor(e.target, selectionStart);
    });
  };

  switch (dataType) {
    case 'text':
      return (
        <Input
          name={customDataField}
          value={value}
          onChange={(e) => { onChange(customDataField, e.target.value) }}
          disabled={disabled}
        />  
      );
    case 'textarea':
      return (
        <Input
          type="textarea"
          name={customDataField}
          value={value}
          onChange={onChangeTextarea}
          disabled={disabled}
        />  
      );
    case 'currency':
      return (
        <CurrencyField
          name={customDataField}
          defaultValue={value ? parseFloat(value.replace(/,/g, '')) : null}
          onBlur={(e) => { onChange(customDataField, e.target.value) }}
          disabled={disabled}
        />
      );
    default: 
      return (
        <DatePicker
          onChange={(v) => { onChange(customDataField, momentLocaleToServerUTC(moment(v)).toISOString()) }}
          selected={value ? momentUTCToLocalDate(moment(value)).toDate() : null}
          dateFormat={dateFormats[dataType]}
          showMonthYearPicker={['m','my'].includes(dataType)}
          showYearPicker={dataType === 'y'}
          className="form-control"
        />  
      );
  }
};
