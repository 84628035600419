import React from 'react';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WVFormatter from '../../../../../shared/helpers/WVFormatter';

const StatisticsControl = ({ stats }) => {
  console.log(stats);
  return (
    <Card body className="card-bordered card" style={{ padding: '20px', height: '100%' }}>
      <h4 className="mb-1"><strong>Total Creator Payouts:</strong> <span className="float-right">{WVFormatter.formatIntNumber(stats.total)}</span></h4>
      <h4 className="mb-1"><strong>No. Unique Active Creators:</strong> <span className="float-right">{WVFormatter.formatIntNumber(stats.totalUniques)}</span></h4>
      <h4 className="mb-1"><strong>No. Connected Creators:</strong> <span className="float-right">{WVFormatter.formatIntNumberWithPercent(stats.noConnected, stats.total)}</span></h4>
      <h4 className="mb-1"><strong>No. Paid Creators:</strong> <span className="float-right">{WVFormatter.formatIntNumberWithPercent(stats.noPaid, stats.total)}</span></h4>
      <h4 className="mb-1"><strong>Creators Missing Deals:</strong> <span className="float-right">{WVFormatter.formatIntNumberWithPercent((stats.total - stats.noConnected), stats.total)}</span></h4>
    </Card>
  );
};

StatisticsControl.propTypes = {
  stats: PropTypes.objectOf(PropTypes.any).isRequired,
};
const mapStateToProps = state => ({
  stats: state.accountPayable.stats,
});

export default connect(mapStateToProps, null)(StatisticsControl);
