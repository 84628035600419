import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import moment from 'moment';
import PropTypes from 'prop-types';

// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import {
  ButtonToolbar,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import { hideModalById } from '../../../../redux/reducers/TableReducer';
import withNotice from '../../../App/store/with-notice';
import { getConstant } from '../../../../shared/helpers/WVConstants';

export const modalId = 'EOMShowPublishModal';

const ModalPublish = ({ addNotice }) => {
  const dealPipelines = getConstant('deal', 'pipelines', []);
  const dealStages = [];
  dealPipelines.forEach((pipeline) => {
    Object.keys(pipeline.stages).forEach((stageKey) => {
      dealStages.push({ id: stageKey, label: pipeline.stages[stageKey] });
    });
  });
  const [loading, setLoaing] = useState(false);
  const [link, setLink] = useState(null);
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const dispatch = useDispatch();
  if (!modalInfo) return false;
  const { data: { filters }, isShow: showUploadModal } = modalInfo;
  const publishAmountReport = async () => {
    setLoaing(true);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-publish-all-deals-by-date-eom', filters);
      console.log(resp.data.items);
      if (resp.data.success) {
        console.log('success');
        setLink(resp.data.fileLink);
      } else {
        setLink(null);
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      setLoaing(false);
      addNotice({
        message: e.message,
        type: 'error',
        duration: 20,
      });
      dispatch(hideModalById(modalId));
    }
    setLoaing(false);
  };
  const close = () => {
    setLink(null);
    setLoaing(false);
    dispatch(hideModalById(modalId));
  };
  // if (['Bonus'].includes(selectedDeal.dealTerms.type_of_deal)) brandApprovedDate = selectedDeal.dealTerms.content_release_date;
  return (
    <Modal
      isOpen={showUploadModal}
      toggle={close}
    >
      <ModalHeader toggle={() => close()} tag="h3">
        Publish Report
      </ModalHeader>
      {/* eslint-disable-next-line react/no-danger */}
      <ModalBody>
        {!link ? `Are you sure you want to publish report ${moment(filters.startDate).format('MMMM YYYY')}?` : (<div>The file has been generated successfully <a href={link} target="_blank" rel="noreferrer" >Open File</a></div>)}
        <ProgressBar
          afterFunction={() => true}
          topic="reportPublish"
          identity={filters.identity}
          isShow={!!loading}
        />
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        {!link && (<Button color="primary" onClick={publishAmountReport} disabled={loading} className="mr-2" >Publish</Button>)}
        <Button color="secondary" onClick={close}>{link ? 'Close' : 'Cancel'}</Button>
      </ButtonToolbar>
    </Modal>
  );
};
ModalPublish.propTypes = {
  addNotice: PropTypes.func.isRequired,
};


export default withNotice(ModalPublish);
