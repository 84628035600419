import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Spinner } from 'reactstrap';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';

const columnTransform = [
  {
    Header: 'Creator',
    accessor: record => (record.influencer?.toLowerCase() || ''),
    Cell: value => value.row.original.influencer || '',
    width: 250,
  },
  {
    Header: 'Brand',
    accessor: record => (record.advertiser?.toLowerCase() || ''),
    Cell: value => value.row.original.advertiser || '',
    width: 250,
  },
  {
    Header: 'Offer',
    accessor: record => (record.offer?.toLowerCase() || ''),
    Cell: value => value.row.original.offer || '',
    width: 250,
  },
  {
    Header: 'Clicks',
    accessor: 'clicks',
    Cell: ({ value }) => (Number(value || 0) <= 0 ? '' : Number(value || 0)),
    width: 250,
  },
];

const tableConfig = {
  isEditable: false,
  isResizable: false,
  useFlex: true,
  isSortable: true,
  withPagination: true,
  manualPageSize: [5, 10, 20, 30, 40],
  withSearchEngine: true,
  sortBy: 'clicks',
  direction: 'desc',
};

class TopClicksPanel extends PureComponent {
  static propTypes = {
    version: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    loading: PropTypes.objectOf(PropTypes.any).isRequired,
  };
  render() {
    const { version, items, loading } = this.props;
    return (
      <Row>
        <Col xs="12"><h4 className="font-weight-bold">Top Influencers by Clicks</h4></Col>
        {loading.clicks && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        {!loading.clicks ? (
          <div style={{ margin: '20px', width: '100%' }}>
            <ReactTableBase
              key={version}
              columns={columnTransform}
              data={items}
              tableConfig={tableConfig}
            />
          </div>
        ) : ''}
      </Row>
    );
  }
}
const mapStateToProps = state => ({
  loading: state.adminDashboard.loading,
  items: state.adminDashboard.topStats.clicks,
  version: state.adminDashboard.lastFetched.toString(),
});
export default connect(mapStateToProps)(TopClicksPanel);
