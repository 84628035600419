import React from 'react';
import PropTypes from 'prop-types';
import UserPropertyType from './custom';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';

const Table = ({
  action, resource, version, customColumns, handleSelectCheckbox, setMetaTotal, setSourceNotesModal,
}) => (
  <>
    {customColumns.length > 0 && (
      <MatTableList
        action={action}
        resource={resource}
        date={new Date().toString()}
        UserPropertyType={UserPropertyType}
        customColumns={customColumns}
        v={2}
        hideToolbar
        showTitle={false}
        isResizable={false}
        noActions
        sortBy="publishedAt"
        direction="desc"
        useCheckboxes
        checkboxHandler={handleSelectCheckbox}
        version={version}
        useUnflatten
        setMetaTotal={setMetaTotal}
        tableConfig={{ manualPageSize: [10, 25, 50, 75, 100] }}
        perPage={100}
      />
    )}
  </>
);

Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  customColumns: PropTypes.arrayOf(PropTypes.any),
  handleSelectCheckbox: PropTypes.func.isRequired,
  setMetaTotal: PropTypes.func.isRequired,
};

Table.defaultProps = {
  customColumns: [],
};

export default Table;
