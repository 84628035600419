import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';

const TitleInList = ({ record }) => {
  const recortUnflatten = unflatten(record);
  const { title, hubilDeal } = recortUnflatten.params;
  let icon = '';
  const hsLink = typeof hubilDeal !== 'undefined' && typeof hubilDeal[0] !== 'undefined' ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${hubilDeal[0].hs_object_id}` : false;

  if (hsLink) {
    icon = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
      <a
        title="Show HubSpot Deal"
        role="button"
        onClick={() => window.open(hsLink, '_blank').focus()}
        style={{ color: '#70bbfd' }}
      >
        <i className="fab fa-hubspot" style={{ fontSize: '18px' }} />
      </a>
    );
  }

  return (
    <>
      <span>
        {title}
        &nbsp;
        {icon}
      </span>
    </>
  );
};
TitleInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default TitleInList;

