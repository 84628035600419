import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TableCellContextMenu from '../../../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';

import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { modalId } from '../../../../MediaContent/components/custom/modals/RefreshYoutubeDialog';

const ContextMenu = ({
  record, setShowStatusHistoryModal, setCurrentContent, dispatch,
}) => {
  const {
    _id, hubilDeal, scorecards, status,
  } = record.params;
  const profileLink = record.params.influencer._id ? `/influencer/profile/${record.params.influencer._id}` : '';
  const hubspotLink = hubilDeal[0]?.hs_object_id ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${hubilDeal[0].hs_object_id}` : '';
  const editLink = `/scorecard/media-content/edit/${_id}`;
  const scorecardLink = `/scorecard/scorecard-answers-dashboard/${_id}`;
  const scoreLink = `/scorecard/score-content/${_id}`;

  const handleClickScorecards = () => {
    if (scorecardLink) {
      window.open(scorecardLink, '_blank').focus();
    }
  };
  const handleClickViewVideoContent = () => {
    if (record.params.mediaUrl) {
      window.open(record.params.mediaUrl, '_blank').focus();
    }
  };
  const handleClickViewCreatorProfile = () => {
    if (profileLink) {
      window.open(profileLink, '_blank').focus();
    }
  };

  const handleClickViewHubspotDeal = () => {
    if (hubspotLink) {
      window.open(hubspotLink, '_blank').focus();
    }
  };
  const handleClickViewMediaContent = () => {
    if (editLink) {
      window.open(editLink, '_blank').focus();
    }
  };

  const handleClickScoreContent = () => {
    window.open(scoreLink, '_blank').focus();
  };

  const handleClickViewChangeHistory = () => {
    setCurrentContent(record);
    setShowStatusHistoryModal(true);
  };

  const options = [
    { label: 'Scorecards', handler: handleClickScorecards, disabled: scorecards?.length === 0 },
    { type: 'divider', label: 'divider0' },
    { label: 'View Change History', handler: handleClickViewChangeHistory },
    { type: 'divider', label: 'divider1' },
    { label: 'Score Content', handler: handleClickScoreContent, disabled: (status === 'Awaiting Brand Approval' || status === 'Brand Approved') },
    { type: 'divider', label: 'divider2' },
    { label: 'View Video Content', handler: handleClickViewVideoContent },
    { label: 'View Creator Profile', handler: handleClickViewCreatorProfile },
    { label: 'View Media Content', handler: handleClickViewMediaContent },
    { type: 'divider', label: 'divider3' },
    { label: 'View HubSpot Deal', handler: handleClickViewHubspotDeal },
    { type: 'divider', label: 'divider4' },
    { label: 'Refresh YouTube Data', handler: () => dispatch(showModalById(modalId, { record })) },
  ];

  return (
    <div className="text-right">
      <TableCellContextMenu options={options} />
    </div>
  );
};
ContextMenu.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setShowStatusHistoryModal: PropTypes.func.isRequired,
  setCurrentContent: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ContextMenu);
