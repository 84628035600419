import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner, Button, Label, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import Select from 'react-select';
import MoneyIcon from 'mdi-react/MoneyIcon';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';
import { StyledButton } from '../../../../../../../shared/components/components/ui';
import ReactTableBase from '../../../../../../../shared/tables/table/ReactTableBase';
import { getConstant } from '../../../../../../../shared/helpers/WVConstants';
import { getServerDate } from '../../../../../../../shared/helpers/WVFormatter';
import CheckBoxField from '@/shared/components/CheckBox'
import csvHelper from '@/shared/helpers/CSVHelper';
import formater from '@/shared/helpers/WVFormatter';

export const AddCreatorsModalId = 'InsertionOrderAddCreators';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSelectable: true,
  useFlex: true,
  isSortable: false,
  withPagination: true,
  manualPageSize: [5, 10, 15, 20],
  withSearchEngine: false,
};

const AddCreatorsModal = ({ possibleCreators, setVersion }) => {
  const [loading, setLoading] = useState(false);
  const [endAdding, setEndAdding] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [selected, setSelected] = useState([]);
  const [dealStageFilter, setDealStageFilter] = useState([]);
  // const [dealStageOptions, setDealStageOptions] = useState([]);
  const [paFilter, setpaFilter] = useState([]);
  // const [creatorsFiltered, setCreatorsFiltered] = useState([]);
  const [results, setResults] = useState([]);
  const pipelines = getConstant('deal', 'pipelines', []);
  const { stages } = pipelines.find(item => item.label.toLowerCase() === 'main pipeline');
  const stageOptions = Object.entries(stages).map(([key, val]) => ({ value: key, label: val }));
  const [cpmPriceApprovalsSuggestions, setCpmPriceApprovalsSuggestions] = useState([]);
  // console.log(stageOptions);
  const [approvalStatus, setApprovalStatusState] = useState({
    approved: true,
    inReview: false,
    pendingEdits: false,
    actionRequired: false,
    hold: false,
    rejected: false,
  });
  const [approvalsBrandStatus, setBrandApprovalStatus] = useState({
    approved: true,
    inReview: false,
    pendingEdits: false,
    hold: false,
    rejected: false,
  });

  const setApprovalStatus = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setApprovalStatusState((s) => ({ ...s, [name]: checked }));
    }
  }
  const setApprovalBrandStatus = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setBrandApprovalStatus((s) => ({ ...s, [name]: checked }));
    }
  }
  const columnTransform = [
    {
      id: 'creatorName',
      Header: 'Creator',
      accessor: value => `${value.row?.original.contact?.firstname || ''} ${value.row?.original.contact?.lastname || ''}`,
      Cell: value => (
        <div>
          <span>
            {`${value.row?.original.contact?.firstname || ''} ${value.row?.original.contact?.lastname || ''}`}
            {value.row?.original.contact?.hs_object_id && (
              <a
                href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${value.row.original.contact.hs_object_id}/`}
                target="_blank"
                rel="noreferrer noopener"
                title="Open HubSpot Contact"
                style={{ marginLeft: '5px' }}
              >
                <i className="fab fa-hubspot" />
              </a>
            )}
            {value.row?.original.deal?.hs_object_id && (
              <a
                href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${value.row.original.deal.hs_object_id}/`}
                target="_blank"
                rel="noreferrer noopener"
                title="Open HubSpot Deal"
                style={{ marginLeft: '5px' }}
              >
                <MoneyIcon size="20" />
              </a>
            )}
          </span>
        </div>
      ),
      width: 300,
    },
    {
      id: 'dealName',
      Header: 'Deal Name',
      Cell: value => (
        <div>
          <span>{value.row?.original.deal?.dealname || ''}</span>
        </div>
      ),
    },
    {
      id: 'dealStage',
      Header: 'Deal Stage',
      Cell: (value) => {
        let stage = '';
        if (value.row?.original?.deal?.dealstage) {
          stage = stageOptions.find(item => (item.value === value.row.original.deal.dealstage));
        }
        // console.log(value.row);
        return (
          <div>
            <span>{stage ? stage.label : ''}</span>
          </div>
        );
      },
    },
    {
      id: 'status',
      Header: 'CPM Prospect Status',
      Cell: value => (
        <div>
          <span>{value.row?.original.approvalStatus?.status[0].toUpperCase() + value.row?.original.approvalStatus?.status.substring(1) || ''}</span>
        </div>
      ),
    },
    {
      id: 'createdAt',
      Header: 'Date Added',
      Cell: value => (
        <div>
          <span>{getServerDate(value.row.original.createdAt)}</span>
        </div>
      ),
    },
    {
      id: 'contentReleaseDate.currentDate',
      Header: 'Content Release Date',
      Cell: value => (
        <div>
          <span>{value.row.original.contentReleaseDate?.currentDate ? getServerDate(value.row.original.contentReleaseDate.currentDate) : '-'}</span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (pipelines.length > 0) {
      // const { stages } = pipelines.find(item => item.label.toLowerCase() === 'main pipeline');
      // const stageOptions = Object.entries(stages).map(([key, val]) => ({ value: key, label: val }));
      // console.log(stageOptions);
      // setDealStageOptions(stageOptions);
      setDealStageFilter([stageOptions.find(item => item.label.toLowerCase() === 'contract signed')]);
    }
  }, [possibleCreators]);

  /* useEffect(() => {
    console.log(possibleCreators, 'possibleCreators');
    const selectedStagesArray = dealStageFilter.map(a => a.value);
    const result = possibleCreators.filter(creator => {
      let returnResp = true;
      if (returnResp && selectedStagesArray?.length > 0 && !selectedStagesArray?.includes(creator?.deal?.dealstage)) returnResp = false;
      // if (returnResp && selectedStagesArray?.length > 0 && !selectedStagesArray?.includes(creator?.deal?.dealstage)) returnResp = false;

     



      return returnResp;
    });
    console.log(result, 'result');
    setCreatorsFiltered(result);
  }, [dealStageFilter, approvalStatus, approvalsBrandStatus, possibleCreators]); */

  const creatorsFiltered = useMemo(() => {
    const selectedStagesArray = dealStageFilter.map(a => a.value);
    const approvedStatusArray = Object.keys(approvalStatus).filter(k => approvalStatus[k]);
    const approvalsBrandStatusArray = Object.keys(approvalsBrandStatus).filter(k => approvalsBrandStatus[k]);
    const selectedPaArray = paFilter.map(a => a.value);
    const result = possibleCreators.filter(creator => {
      let returnResp = true;
      if (returnResp && selectedStagesArray?.length > 0 && !selectedStagesArray?.includes(creator?.deal?.dealstage)) returnResp = false;
      if (returnResp && approvedStatusArray?.length > 0 && !approvedStatusArray?.includes(creator?.approvalStatus.status)) returnResp = false;
      if (returnResp && approvalsBrandStatusArray?.length > 0 && !approvalsBrandStatusArray?.includes(creator?.approvalsBrandStatus.status)) returnResp = false;
      if (returnResp && selectedPaArray?.length > 0 && !selectedPaArray?.includes(creator?.cpmPriceApprovals)) returnResp = false;
      return returnResp;
    });
    console.log(result, 'result');
    return  result;
  }, [dealStageFilter, approvalStatus, approvalsBrandStatus, possibleCreators, paFilter])

  const modalInfo = useSelector(state => state.table.modals[AddCreatorsModalId]);
  const dispatch = useDispatch();

  useEffect(async () => {
    if (!modalInfo?.isShow) return false;
    try {
      const resp = await axios.get('/api/portal/prospects//get-cpm-price-approvals-suggestions');
      if (resp.data.success) {
        setCpmPriceApprovalsSuggestions(resp.data.suggestions);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
    }
  }, [modalInfo?.isShow]);
  if (!modalInfo) return false;
  const { data: { record }, isShow: showUploadModal } = modalInfo;

  const close = () => {
    dispatch(hideModalById(AddCreatorsModalId));
    // setLoading(false);
    setEndAdding(false);
    setVersion(new Date().toString());
    setBrandApprovalStatus({
      approved: true,
      inReview: false,
      pendingEdits: false,
      hold: false,
      rejected: false,
    });
    setApprovalStatusState({
      approved: true,
      inReview: false,
      pendingEdits: false,
      actionRequired: false,
      hold: false,
      rejected: false,
    })
  };
  const onAdd = () => {
    setLoading(true);
    const data = {
      cpmCreators: selected,
      ioId: record.id,
    };
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-add-creators-to-insertion-order',
      data,
    }).then((response) => {
      if (response.data.success) {
        setResultMessage('Creators were added successfully.');
        setResults(response.data.cpmProspects || []);
      } else {
        setResultMessage('Creators were not added.');
      }
      setLoading(false);
      setEndAdding(true);
    });
  };
  const downloadResults = () => {
    const data = [[
      'Creator Name',
      'Creator Email',
      'Creator Profile URL',
      'Deal Name',
      'Deal URL',
      'Deal Owner Name',
      'Pricing Approval Group',
      'Insertion Order',
      'Invoice Number',
      'CPM Total Cost',
      'Current Pricing Approval Status',
      'PA Submit Date',
      'Justification',
      'status'
    ]];
    results.forEach((record) => {
      data.push([
        `${record.influencer?.first_name || ''} ${record.influencer?.last_name || ''}`,
        record.influencer?.email || '',
        `${window.location.protocol}//${window.location.host}/influencer/profile/${record.influencer?._id || ''}`,
        record.deal?.dealname || '',
        record.deal ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${record.deal?.hs_object_id}` : '-',
        `${record.deal?.adminUserObject?.firstName || ''} ${record.deal?.adminUserObject?.lastName || ''}`,
        record.cpmPriceApprovals?.name || '-',
        record.cpmInsertionOrder?.name || '-',
        record.cpmInvoice?.name || '-',
        formater.formatCurrency(record.totalPrice || 0),
        record?.cpmPriceApprovals?.status?.status || '-',
        record.createdAt || '-',
        record.dealJustification || '-',
        record.addingStatus,
      ]);
    });
    return csvHelper.generateCSV('CPMPrespects.csv', data);
  }

  return (
    <Modal isOpen={showUploadModal} toggle={close} size="lg" style={{ maxWidth: '1250px' }}>
      <ModalHeader toggle={close} tag="h4">
        Add Creators
      </ModalHeader>
      <ModalBody>
        {!endAdding && (
          <div className="theme-light">
            <Row className="mb-5">
              <Col sm={6}>
                <p className="mb-1 font-weight-bold">Deal Stages</p>
                <Select
                  isDisabled={stageOptions.length === 0}
                  value={dealStageFilter}
                  options={stageOptions}
                  onChange={setDealStageFilter}
                  placeholder="Select Deal Stage..."
                  isMulti
                />
                 <p className="mb-1 font-weight-bold">PA Group</p>
                <Select
                  isDisabled={cpmPriceApprovalsSuggestions.length === 0}
                  value={paFilter}
                  options={cpmPriceApprovalsSuggestions}
                  onChange={setpaFilter}
                  placeholder="Select PA Group..."
                  isMulti
                />
              </Col>
              <Col sm={3}>
                <Label className="bold-text" style={{ marginBottom: '14px' }}>
                  IL Pricing Approval Status
                </Label>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="Approved"
                    label="Approved"
                    value={approvalStatus?.approved || false}
                    onChange={event => setApprovalStatus(event, 'approved')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '10px' }}>
                  <CheckBoxField
                    name="inreview"
                    label="In Review"
                    value={approvalStatus?.inReview || false}
                    onChange={event => setApprovalStatus(event, 'inReview')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="pendingEdits"
                    label="Pending Edits"
                    value={approvalStatus?.pendingEdits || false}
                    onChange={event => setApprovalStatus(event, 'pendingEdits')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="actionRequired"
                    label="Action Required"
                    value={approvalStatus?.actionRequired || false}
                    onChange={event => setApprovalStatus(event, 'actionRequired')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="hold"
                    label="Hold"
                    value={approvalStatus?.hold || false}
                    onChange={event => setApprovalStatus(event, 'hold')}
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <CheckBoxField
                    name="rejected"
                    label="Rejected"
                    value={approvalStatus?.rejected || false}
                    onChange={event => setApprovalStatus(event, 'rejected')}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <Label className="bold-text" style={{ marginBottom: '14px' }}>
                  Brand Pricing Approval Status 
                </Label>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="Approved1"
                    label="Approved"
                    value={approvalsBrandStatus?.approved || false}
                    onChange={event => setApprovalBrandStatus(event, 'approved')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '10px' }}>
                  <CheckBoxField
                    name="inreview1"
                    label="In Review"
                    value={approvalsBrandStatus?.inReview || false}
                    onChange={event => setApprovalBrandStatus(event, 'inReview')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="pendingEdits1"
                    label="Pending Edits"
                    value={approvalsBrandStatus?.pendingEdits || false}
                    onChange={event => setApprovalBrandStatus(event, 'pendingEdits')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="hold1"
                    label="Hold"
                    value={approvalsBrandStatus?.hold || false}
                    onChange={event => setApprovalBrandStatus(event, 'hold')}
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <CheckBoxField
                    name="rejected1"
                    label="Rejected"
                    value={approvalsBrandStatus?.rejected || false}
                    onChange={event => setApprovalBrandStatus(event, 'rejected')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactTableBase
                  key="addCreatorsModal"
                  id="addCreatorsModal"
                  columns={columnTransform}
                  data={creatorsFiltered}
                  tableConfig={tableConfig}
                  updateSelect={(select, rows) => {
                    const selectedMap = Object.keys(select).map((sel) => {
                      const c = rows.find(r => r.id === sel);
                      return c?.original._id;
                    });
                    // console.log(selectedMap);
                    setSelected(selectedMap);
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
        {loading && (
          <div className="text-center"><Spinner color="primary" size="lg" />&nbsp;&nbsp;</div>
        )}
        {endAdding && (
          <div className="">
            <h4>{resultMessage}</h4>
            <ul>
              <li>Deals successfully added: {results.filter(a => a.addingStatus === 'Added')?.length || 0}</li>
              <li>Duplicate deals detected: {results.filter(a => a.addingStatus === 'Duplicate')?.length || 0}</li>
              <li>No deals detected: {results.filter(a => a.addingStatus === 'NoDeal')?.length || 0}</li>
              <li>Brand Missmatch deals detected: {results.filter(a => a.addingStatus === 'BrandMissmatch')?.length || 0}</li>
            </ul>
            <div>
              <Button
                className="btn btn-secondary btn-sm"
                onClick={downloadResults}
                style={{ margin: '0 10px 0 10px' }}
              >
                Download Results in CSV
              </Button>
            </div>
          </div>
        )}
        <Row className="modal__footer" style={{ marginTop: '30px' }}>
          {!endAdding && (
            <StyledButton
              className="btn btn-primary btn-sm"
              onClick={onAdd}
              style={{ margin: '0 10px 0 10px' }}
              disabled={loading || selected.length === 0}
            >
              <i className="icomoon-save" />
              <span className="btn-text">Add</span>
            </StyledButton>
          )}
          <Button
            className="btn btn-secondary btn-sm"
            onClick={close}
            style={{ margin: '0 10px 0 10px' }}
          >
            {endAdding ? 'Close' : 'Cancel'}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

AddCreatorsModal.propTypes = {
  possibleCreators: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
};

export default AddCreatorsModal;
