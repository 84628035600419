const getMediaChannelNameKey = (platform) => {
  switch (platform) {
    case 'podcast': return 'podcast_title';
    case 'twitch': case 'twitter': return null;
    default: return `${platform}_channel_name`;
  }
};

const getMediaChannelUrlKey = (platform) => {
  switch (platform) {
    case 'podcast': return 'apple_podcast_url';
    case 'instagram': return 'instagram_channel';
    case 'twitch': return 'twitch_account';
    case 'twitter': return 'twitterhandle';
    default: return `${platform}_channel_url`;
  }
};

const getMediaChannelUrl = (row, platform) => {
  const value = row.mediaChannels[getMediaChannelUrlKey(platform)];
  if (value && platform === 'twitter' && !value.includes('/')) {
    return `https://twitter.com/${value}`;
  }
  return value;
};

exports.getMediaChannel = (row, platform) => {
  if (!(row && row.mediaChannels && platform)) {
    return { url: null, title: null, platform };
  }
  const key = getMediaChannelNameKey(platform);
  return {
    url: getMediaChannelUrl(row, platform),
    title: key ? row.mediaChannels[key] : platform.charAt(0).toUpperCase() + platform.slice(1),
    platform,
  };
};

exports.getMediaChannelNameKey = getMediaChannelNameKey;
exports.getMediaChannelUrlKey = getMediaChannelUrlKey;
