import React from 'react';
import {
  Modal,
  ModalHeader,
  ButtonToolbar,
  Button,
} from 'reactstrap';
import { uid } from 'react-uid';
import PropTypes from 'prop-types';
import ApiClient from 'ApiClient';
import DropZoneField from '../../../../../shared/components/form/DropZone';
import ProgressBar from '../../../../../shared/helpers/ProgressBar';

class ImportTags extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    uploading: PropTypes.bool.isRequired,
    processImportTags: PropTypes.func.isRequired,
    showResults: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleChangeTagsFile = this.handleChangeTagsFile.bind(this);
    this.state = {
      results: {},
      errMessage: '',
    };
  }

  handleChangeTagsFile(files) {
    if (files.length > 0) {
      const { processImportTags, showResults } = this.props;
      this.setState({ errMessage: '', results: [] });
      processImportTags();
      const formData = new FormData();
      formData.append('tagsFile', files[0]);
      const api = new ApiClient();
      api.client.post('/api/portal/influencers/import-tags', formData).then((res) => {
        if (res.data.success) {
          const {
            creatorsAssignedTags,
            agentsAssignedTags,
            tagsNotAssigned,
            creatorsWithNoTags,
            agentsWithNoTags,
            errors,
          } = res.data;
          this.setState({
            results: {
              creatorsAssignedTags,
              agentsAssignedTags,
              tagsNotAssigned,
              creatorsWithNoTags,
              agentsWithNoTags,
              errors,
            },
          });
          showResults();
        } else {
          this.setState({ errMessage: `Error: ${res?.data?.error || ''}` });
          showResults();
        }
      }).catch((err) => {
        this.setState({ errMessage: `Error: ${err.message}` });
        showResults();
      });
    }
  }

  render() {
    const {
      isOpen,
      toggle,
      loading,
      uploading,
    } = this.props;
    const {
      results,
      errMessage,
    } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader
          toggle={toggle}
          tag="h4"
          className="modal-title-statistics"
        >
          Import Tags
        </ModalHeader>
        {!loading && !uploading && (
          <div className="modal__body">
            <DropZoneField
              input={{
                name: 'tagsFile',
                value: '',
                accept: '.csv, application/vnd.ms-excel, text/csv',
                onChange: this.handleChangeTagsFile,
              }}
              customHeight
            />
          </div>
        )}
        {uploading && (
          <div className="modal__body">
            <h3 className="mb-2">{loading ? 'Importing...' : 'Results:'}</h3>
            <ProgressBar
              topic="importTags"
              showAfterEnd
            />
            {errMessage && (
              <div style={{ color: 'red' }}><small>{errMessage}</small></div>
            )}
            {results?.errors && (
              <div style={{ color: 'red', lineHeight: 1 }}>
                {results.errors.map((err, i) => (
                  <div key={uid(i)}><small>Error: {err}</small></div>
                ))}
              </div>
            )}
            {!loading && !errMessage && (
              <div>
                <h4>Creators assigned tags: {results.creatorsAssignedTags}</h4>
                <h4>Agents assigned tags: {results.agentsAssignedTags}</h4>
                <h4>Tags not assigned: {results.tagsNotAssigned}</h4>
                <h4>Creators with no Tags: {results.creatorsWithNoTags}</h4>
                <h4>Agents with no Tags: {results.agentsWithNoTags}</h4>
              </div>
            )}
          </div>
        )}
        {!loading && (
          <ButtonToolbar className="modal__footer">
            <Button className="modal_cancel btn-sm" color="secondary" onClick={toggle}>{uploading && !loading ? 'Done' : 'Close'}</Button>
          </ButtonToolbar>
        )}
      </Modal>
    );
  }
}

export default ImportTags;
