import React from 'react';
import moment from 'moment';
// import { unflatten } from 'flat';
// import { useSelector } from 'react-redux';
import MatTableList from '../../../../../../../shared/tables/materialTable/TableRedux';
import ContextMenuInList from './ContextMenuInList';
import RoleInList from './RoleInList';
import CopyToClipboard from '@/shared/components/CopyToClipboard';

const Table = React.memo(() => {
  return (
    <MatTableList
      noActions
      isResizable={false}
      useFlex
      useCheckboxes
      useMultiPageCheckboxes
      checkboxHandler={() => {}}
      enableWarning={false}
      notFoundProps={{ title: 'No Brand Users Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'firstName',
          Header: () => 'First Name',
          accessor: record => (record?.params?.firstName || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          width: 80,
          className: 'd-flex align-items-center',
        },
        {
          id: 'lastName',
          Header: () => 'Last Name',
          accessor: record => (record?.params?.lastName || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          width: 80,
          className: 'd-flex align-items-center',
        },
        {
          id: 'email',
          Header: () => 'Email',
          accessor: record => (record?.params?.email || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          width: 150,
          className: 'd-flex align-items-center',
        },
        {
          id: 'title',
          Header: () => 'Title',
          accessor: record => (record?.params?.title || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          width: 100,
          className: 'd-flex align-items-center',
        },
        {
          id: 'businessName',
          Header: () => 'Business Name',
          accessor: record => (record?.params?.businessName || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          width: 80,
          className: 'd-flex align-items-center',
        },
        {
          id: 'advertiser',
          Header: () => 'Advertiser',
          accessor: record => (record?.params?.advertiser || ''),
          Cell : (p) => p.row.original.populated?.advertiser?.params?.companyName || '-',
          width: 100,
          className: 'd-flex align-items-center',
        },
        {
          id: 'role',
          Header: () => 'Role',
          accessor: record => (record?.params?.role || ''),
          Cell: p => (
            <RoleInList
              record={p.row.original}
              updateRecordFunction={p.updateRecordFunction}
            />
          ),
          width: 100,
          className: 'd-flex align-items-center',
        },
        {
          id: 'joinDate',
          Header: () => 'Join Date',
          accessor: record => (record?.params?.joinDate || ''),
          Cell: p => (
            p.value ? moment(p.value).format('MM/DD/YYYY') : '-'
          ),
          width: 80,
          className: 'd-flex align-items-center',
        },
          
        {
          id: 'actions',
          Header: '',
          accessor: () => null,
          Cell: p => (
            <ContextMenuInList
              record={p.row.original}
            />
          ),
          disableSortBy: true,
          className: 'd-flex align-items-center',
          width: 30,
          maxWidth: 30,
        },
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
