import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner,
} from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CKEditor } from 'ckeditor4-react';
import {
  showChangeMediaContentApprovedStatusDialog,
  updateMediaContentStatus,
} from '../../../../../../../redux/reducers/admin/ScoringReducer';

const UpdateStatusModal = ({
  showChangeApprovedStatusDialog,
  dispatch,
  loadingMediaContentScorecards,
  mediaContentObj,
  mediaContentScorecards,
  setVersion,
}) => {
  const [checkedStatus, setCheckedStatus] = useState('Awaiting Brand Approval');
  const [selectedNotes, setSelectedNotes] = useState(null);

  const handleChangeApprovedStatusDialogSubmit = () => {
    const data = {};
    if (checkedStatus === 'Pending Edits') {
      data.notes = selectedNotes;
    }
    data.status = checkedStatus;
    dispatch(updateMediaContentStatus(mediaContentObj?.params?.id, data, mediaContentObj?.params?.status));
    dispatch(showChangeMediaContentApprovedStatusDialog(false));
    setVersion((new Date()).toString());
  };

  const handleChangeNotes = (event) => {
    const { editor } = event;
    setSelectedNotes(editor.getData());
  };

  useEffect(() => {
    if (mediaContentObj?.params?.status && (mediaContentObj.params.status === 'Second Score' || mediaContentObj.params.status === 'Brand Approved')) {
      setCheckedStatus('Awaiting Brand Approval');
      setSelectedNotes(mediaContentObj.params.notes);
    }
    if (mediaContentObj?.params?.status && mediaContentObj.params.status === 'Awaiting Brand Approval') {
      setCheckedStatus('Brand Approved');
      setSelectedNotes(mediaContentObj.params.notes);
    }
  }, [mediaContentObj?.params?.status]);

  return (
    <Modal
      isOpen={showChangeApprovedStatusDialog}
      toggle={() => dispatch(showChangeMediaContentApprovedStatusDialog(false))}
      size="sm"
      className=" theme-light"
    >
      <ModalHeader tag="h3" toggle={() => dispatch(showChangeMediaContentApprovedStatusDialog(false))}>
        Update Media Content Status
      </ModalHeader>
      <ModalBody>
        {loadingMediaContentScorecards ? (
          <div className="text-center"><Spinner color="primary" size="lg" /></div>) : null}
        {!loadingMediaContentScorecards && mediaContentScorecards && (
          <Row>
            <Col xs="3" className="text-center mt-auto mb-auto" style={{ color: '#70bbfd' }}> <i
              className="fa fa-question"
              style={{ fontSize: '5em' }}
            />
            </Col>
            <Col xs="9">
              <p>Confirm you would like to change status of the following content...</p>
              <p className="font-weight-bold">{mediaContentObj?.params?.name}</p>
              <br />
              {mediaContentScorecards.length > 0 && (
                <div>
                  <p>{`Along with the following scorecard${mediaContentScorecards.length > 1 ? 's:' : ':'}`}</p>
                  {mediaContentScorecards.map(item => (
                    <p className="font-weight-bold" key={item.key}>{`${item.scoreBy} - ${item.totalScore}`}</p>
                  ))}
                </div>
              )}
              <Row style={{ marginTop: '15px', marginLeft: '15px' }}>
                <Col xs="6">
                  <FormControlLabel
                    control={
                      <Input
                        type="radio"
                        name="Awaiting Brand Approval"
                        value="Awaiting Brand Approval"
                        disabled={mediaContentObj?.params?.status === 'Awaiting Brand Approval'}
                        className="mt-0"
                        checked={checkedStatus === 'Awaiting Brand Approval'}
                        onChange={(e) => {
                          setCheckedStatus(e.target.value);
                        }}
                      />
                    }
                    label="Awaiting Brand Approval"
                  />
                </Col>
                <Col xs="6">
                  <FormControlLabel
                    control={
                      <Input
                        type="radio"
                        name="Brand Approved"
                        value="Brand Approved"
                        disabled={mediaContentObj?.params?.status === 'Brand Approved'}
                        className="mt-0"
                        checked={checkedStatus === 'Brand Approved'}
                        onChange={(e) => {
                          setCheckedStatus(e.target.value);
                        }}
                      />
                    }
                    label="Brand Approved"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: '15px', marginLeft: '15px' }}>
                <Col md="12" xs="12">
                  <FormControlLabel
                    control={
                      <Input
                        type="radio"
                        name="Pending Edits"
                        value="Pending Edits"
                        className="mt-0"
                        checked={checkedStatus === 'Pending Edits'}
                        onChange={(e) => {
                          setCheckedStatus(e.target.value);
                        }}
                      />
                    }
                    label="Awaiting pending edits to the content before approving?"
                  />
                </Col>
              </Row>
            </Col>
            {checkedStatus === 'Pending Edits' && (
              <Col sm={12}>
                <Row>
                  <Col>
                    <CKEditor
                      id="edit-notes"
                      name="notes"
                      initData={mediaContentObj?.params?.notes ? mediaContentObj.params.notes : ''}
                      onChange={handleChangeNotes}
                      config={{
                        height: '150px',
                        versionCheck: false,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        )}
      </ModalBody>
      <ModalFooter className="modal__footer">
        <Button
          className="btn-sm"
          color="danger"
          onClick={handleChangeApprovedStatusDialogSubmit}
        >
          Change
        </Button>{' '}
        <Button
          className="btn-sm"
          color="secondary"
          onClick={() => dispatch(showChangeMediaContentApprovedStatusDialog(false))}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UpdateStatusModal.propTypes = {
  showChangeApprovedStatusDialog: PropTypes.bool.isRequired,
  loadingMediaContentScorecards: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  mediaContentObj: PropTypes.objectOf(PropTypes.any),
  mediaContentScorecards: PropTypes.arrayOf(PropTypes.any),
  setVersion: PropTypes.func.isRequired,
};

UpdateStatusModal.defaultProps = {
  mediaContentObj: {},
  mediaContentScorecards: [],
};

const mapStateToProps = state => ({
  showChangeApprovedStatusDialog: state.score.showChangeApprovedStatusDialog,
  loadingMediaContentScorecards: state.score.loading.fetchScorecardContent,
  mediaContentObj: state.score.mediaContent,
  mediaContentScorecards: state.score.mediaContentScorecards,
});

export default connect(mapStateToProps)(UpdateStatusModal);
