import { Button, ButtonToolbar, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SelectAsync from 'react-select/async';
import { axios } from 'ApiClient';
import ReactTags from 'react-tag-autocomplete';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import RadioButton from '../../../../../../../shared/components/RadioButton';
import ProgressBar from '../../../../../../../shared/helpers/ProgressBarWithObject';
import csvHelper from '../../../../../../../shared/helpers/CSVHelper';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';


const RecycleContactModal = ({
  isOpen,
  closeFunc,
  contactIds,
  addNotice,
  numberOfFound,
  RECYCLE_CONTACTS_LIMIT,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState({
    value: null,
    label: '',
  });
  const reviewStatusDefault = { label: 'In Review', value: 'In Review' };
  const [ilStatus, setIlStatus] = useState(reviewStatusDefault);
  const [brandStatus, setBrandStatus] = useState(reviewStatusDefault);
  const [tags, setTags] = useState([]);
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [includeAll, setIncludeAll] = useState(false);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [recyclingResults, setRecyclingResults] = useState([]);
  const [fetchFreshData, setFetchFreshData] = useState(false);
  const [defaultBrand, setDefaultBrand] = useState({
    value: null,
    label: '',
  });

  const reactTags = React.createRef();

  const fetchTargetBrand = (hsUserId) => {
    axios({
      method: 'get',
      url: `/api/portal/prospects/get-hs-contact-target-brand?hsUserId=${hsUserId}`,
    }).then((response) => {
      if (response.data.success && response.data.brand.value) {
        setSelectedBrand({
          value: response.data.brand.value,
          label: response.data.brand.label,
        });
      } else if (defaultBrand.value) setSelectedBrand(defaultBrand);
    }).catch((err) => {
      console.log(err);
    });
  };

  const loadBrandsOptions = async (inputValue) => {
    const response = await axios({
      method: 'get',
      url: `/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`,
    });
    if (response.data.success) {
      const res = [
        { value: null, label: '-- Not Assigned --' },
      ];
      response.data.brands.forEach((record) => {
        res.push({
          value: record._id,
          label: record.companyName,
          record,
        });
        if (record.companyName.toLowerCase() === 'betterhelp') {
          setDefaultBrand({
            value: record._id,
            label: record.companyName,
          });
        }
      });
      return res;
    }
    return [];
  };

  const loadIlStatus = async inputValue => Object.values(window.constants.prospect.status)
    .map(s => ({ label: s, value: s }))
    .filter(s => s.value.toLowerCase().includes(inputValue.toLowerCase()));

  const loadBrandStatus = async inputValue => Object.values(window.constants.prospect.brandStatuses)
    .map(s => ({ label: s, value: s }))
    .filter(s => s.value.toLowerCase().includes(inputValue.toLowerCase()));

  const handleChangeBrand = (selected) => {
    setSelectedBrand({
      value: selected.value,
      label: selected.label,
    });
  };

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setTags(tagsNew);
  };

  const fetchTags = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospects-tags?suggestionsOnly=true',
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const doRecycle = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-recycle-contacts',
      data: {
        contactIds,
        ilStatus: ilStatus.value,
        brandStatus: brandStatus.value,
        tags,
        brandId: selectedBrand.value,
        includeAll,
        query: new URLSearchParams(window.location.search).toString(),
        identity,
        fetchFreshData,
      },
    }).then((response) => {
      if (response.data.success === true) {
        setRecyclingResults(response.data.resultsArr);
      } else {
        addNotice({
          message: 'An error occurred',
          type: 'error',
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const downloadReport = () => {
    const data = [['First Name', 'Last Name', 'Email', 'Media Channel Url', 'Status', 'Description']];
    recyclingResults.forEach((record) => {
      data.push(Object.values(record));
    });
    return csvHelper.generateCSV('contact-recycling-results.csv', data);
  };

  useEffect(() => {
    if (isOpen) {
      fetchTags();
      if (contactIds.length === 1) fetchTargetBrand(contactIds[0]);
    }
    setBrandStatus(reviewStatusDefault);
    setIlStatus(reviewStatusDefault);
    setTags([]);
    setIncludeAll(false);
    setRecyclingResults([]);
    setLoading(false);
    setFetchFreshData(false);
    if (!isOpen) {
      setSelectedBrand({
        value: null,
        label: '',
      });
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '800px' }}
    >
      <ModalHeader style={{ padding: 0 }} toggle={() => closeFunc()} tag="h4">Recycle {contactIds.length === 1 ? 'Contact' : 'Contacts'}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {loading && (
              <ProgressBar
                afterFunction={() => true}
                topic="recycleLeads"
                isShow
                identity={identity}
              />
            )}
            {!loading && (
              <>
                {contactIds.length > 1 && numberOfFound > contactIds.length && (
                  <>
                    <div>{`You have selected ${contactIds.length} contact${contactIds.length !== 1 ? 's' : ''} to Recycle`}</div>
                    <br />
                    <Row className="theme-light radio-in-prospects">
                      <Col md={9}>
                        {parseInt(RECYCLE_CONTACTS_LIMIT, 10) < numberOfFound ?
                          `Would you like to select first ${WVFormatter.formatIntNumber(RECYCLE_CONTACTS_LIMIT)} of ${WVFormatter.formatIntNumber(numberOfFound)} Contact to Recycle?` :
                          `Would you like to select all ${WVFormatter.formatIntNumber(numberOfFound)} Contact to Recycle?`
                        }
                      </Col>
                      <Col md={3}>
                        <RadioButton
                          name="actionRecycle"
                          label="&nbsp;Yes"
                          value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                          radioValue="includeAll"
                          onChange={(e) => { setIncludeAll(e === 'includeAll'); }}
                        />
                        <RadioButton
                          name="actionRecycle"
                          label="&nbsp;No"
                          value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                          radioValue="notIncludeAll"
                          onChange={(e) => { setIncludeAll(e === 'includeAll'); }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <div className="theme-light">
                  <CheckBoxField
                    name="fetchFreshData"
                    label="Fetch fresh data from Tubular (recycling process will take more time)"
                    value={fetchFreshData}
                    onChange={event => setFetchFreshData(event ? event.target.checked : false)}
                  />
                </div>
                <hr />
                <div>
                  <FormGroup>
                    <Label>Target Brand</Label>
                    <SelectAsync
                      cacheOptions
                      value={selectedBrand}
                      defaultOptions
                      loadOptions={loadBrandsOptions}
                      onChange={handleChangeBrand}
                      placeholder=""
                    />
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <Label>InfluenceLogic Review Status</Label>
                    <SelectAsync
                      cacheOptions
                      value={ilStatus}
                      defaultOptions
                      loadOptions={loadIlStatus}
                      onChange={(input) => { setIlStatus(input); }}
                      placeholder=""
                    />
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <Label>Brand Review Status</Label>
                    <SelectAsync
                      cacheOptions
                      value={brandStatus}
                      defaultOptions
                      loadOptions={loadBrandStatus}
                      onChange={(input) => { setBrandStatus(input); }}
                      placeholder=""
                    />
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <Label>Tags</Label>
                    <ReactTags
                      ref={reactTags}
                      tags={tags}
                      suggestions={tagsSuggestions}
                      onDelete={onDeleteTag}
                      onAddition={onAdditionTag}
                      delimiters={['Enter', ',']}
                      allowNew
                      placeholderText="Tags associated with Prospects"
                      addOnBlur
                    />
                    <div className="small">Optional (can be specified later)</div>
                  </FormGroup>
                </div>
              </>
            )}
          </Col>
        </Row>
        {recyclingResults.length > 0 && (
          <div className="mt-3 text-center">
            <Button className="btn btn-sm mr-1 mb-0" color="primary" onClick={() => closeFunc()}>Close</Button>
            <Button className="btn btn-sm ml-1 mb-0" color="secondary" onClick={downloadReport}>Download Results</Button>
          </div>
        )}
      </ModalBody>
      {!loading && (
        <ButtonToolbar className="modal__footer">
          <Button color="primary" className="btn-primary btn btn-sm" onClick={doRecycle}>Recycle</Button>
          &nbsp;&nbsp;
          <Button className="modal_cancel btn-sm" onClick={() => closeFunc()}>Cancel</Button>
        </ButtonToolbar>
      )}
    </Modal>
  );
};

RecycleContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  contactIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  addNotice: PropTypes.func.isRequired,
  numberOfFound: PropTypes.number.isRequired,
  RECYCLE_CONTACTS_LIMIT: PropTypes.string.isRequired,
};


export default RecycleContactModal;
