import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  Modal,
  ModalBody,
  ModalHeader,
  Button, Col, Row,
} from 'reactstrap';
import withNotice from '../../../../../../../App/store/with-notice';

const MissingInfoModal = ({
  isOpen,
  closeFunc,
  setVersion,
  prospect,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={() => closeFunc()}
    style={{ minWidth: '500px' }}
  >
    <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Missing Contact Information</ModalHeader>
    <ModalBody>
      <Row>
        <Col xs="3" className="text-center mt-2" style={{ color: '#70bbfd' }}> <i className="fa fa-info" style={{ fontSize: '5em' }} /></Col>
        <Col xs="9">
          You&apos;re unable to update the status until the following required values are specified.
          <br /><br />
          {!prospect.firstname ? <b>First Name<br /></b> : ''}
          {!prospect.lastname ? <b>Last Name<br /></b> : ''}
          {!prospect.email ? <b>Email<br /></b> : ''}
        </Col>
      </Row>
    </ModalBody>
    <ButtonToolbar className="modal__footer">
      <Button className="modal_ok btn-sm" color="primary" onClick={() => window.open(`/research/prospects/edit/${prospect._id}`)}>
        <span>Edit</span>
      </Button>&nbsp;&nbsp;
      <Button className="modal_cancel btn-sm" color="secondary" onClick={() => { setVersion(new Date().toString()); closeFunc(); }}>Close</Button>
    </ButtonToolbar>
  </Modal>
);

MissingInfoModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  prospect: PropTypes.objectOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
};

export default withNotice(MissingInfoModal);
