import Record from '../components/components/RecordHook';

export default class OfflineAgentPayouts extends Record {
  // eslint-disable-next-line class-methods-use-this
  initRecord() {
    return {
      record: {},
      schema: {
        date: {
          title: 'Payment Date',
          required: true,
        },
        paymentMethod: {
          title: 'Payment Method',
          required: true,
        },
        notes: {
          title: 'Notes',
          required: false,
        },
      },
    };
  }
  async updateItemF() {
    throw new Error('Undable to update payout record', this.id);
  }
  async newItemF() {
    const record = await this.api.client.post('/api/portal/finance/post-save-payable-agent-transaction', {
      ids: this.get('payableIds'),
      paymentMethod: this.get('paymentMethod'),
      date: this.get('date').toISOString(),
      notes: this.get('notes').toString(),
    });
    if (!record.data.success) {
      throw new Error(record.data.notice.message);
    }
    return this.getModel();
  }
}
