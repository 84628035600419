/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import MediaContentScorecardsContainer from './components/MediaContentScorecardsContainer';


const ResourceAction = (props) => {
  const {
    //  match,
    // location,
    resources,
  } = props;
  const [tag, setTag] = useState('');
  const resource = resources.find(r => r.id === 'MediaContent');
  if (!resource) {
    return (<NoResourceError resourceId="MediaContent" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="MediaContent" actionName="list" />);
  }
  
  return (
    <MediaContentScorecardsContainer
      filterVisible
      tag={tag}
      setTag={setTag}
      resource={resource}
      action={action}
    />
  );
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
