import React from 'react';
import { Modal, Spinner, ButtonToolbar, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from 'mdi-react/DownloadIcon';

const ReportModal = ({
  modal,
  loading,
  closeDialog,
  advertisersLog,
  offersLog,
  downloadCSV,
}) => (
  <Modal
    isOpen={modal}
    toggle={closeDialog}
  >
    <div className="modal__header">
      <h4 className="text-modal  modal__title">Fill Advertisers</h4>
    </div>
    <div className="modal__body finance-form">
      {!loading ?
        (
          <ul className="text-left">
            <li>Retrieved {advertisersLog.request.length} Advertisers
              {advertisersLog.request.length > 0 ? (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV('advertisersLog', 'request')}><DownloadIcon size="20" /></IconButton>) : ''}
            </li>
            <li>Added/Updated {advertisersLog.added.length} Advertisers
              {advertisersLog.added.length > 0 ? (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV('advertisersLog', 'added')}><DownloadIcon size="20" /></IconButton>) : ''}
            </li>
            <li>Removed {advertisersLog.removed.length} Advertisers
              {advertisersLog.removed.length > 0 ? (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV('advertisersLog', 'removed')}><DownloadIcon size="20" /></IconButton>) : ''}
            </li>
            {advertisersLog.errors && advertisersLog.errors.length > 0 &&
              (
                <li className="text-danger">
                  Please check Errors
                  {!loading && advertisersLog.added.length > 0 && (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV('advertisersLog', 'errors')}><DownloadIcon size="20" /></IconButton>)}
                </li>
              )
            }
            <li>Retrieved {offersLog.request.length} Offers
              {offersLog.request.length > 0 ? (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV('offersLog', 'request')}><DownloadIcon size="20" /></IconButton>) : ''}
            </li>
            <li>Added/Updated {offersLog.added.length} Offers
              {offersLog.added.length > 0 ? (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV('offersLog', 'added')}><DownloadIcon size="20" /></IconButton>) : ''}
            </li>
            <li>Removed {offersLog.removed.length} Offers
              {offersLog.removed.length > 0 ? (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV('offersLog', 'removed')}><DownloadIcon size="20" /></IconButton>) : ''}
            </li>
            {offersLog.errors && offersLog.errors.length > 0 &&
              (
                <li className="text-danger">
                  Please check Errors
                  {!loading && advertisersLog.added.length > 0 && (<IconButton className="material-table__toolbar-button" size="medium" onClick={() => downloadCSV('offersLog', 'errors')}><DownloadIcon size="20" /></IconButton>)}
                </li>
              )
            }
          </ul>
        )
      : (<div><Spinner color="primary" /></div>)
      }
    </div>
    {!loading && (
    <ButtonToolbar className="modal__footer">
      <Button className="modal_cancel btn-sm" color="danger" onClick={closeDialog}>Close</Button>
    </ButtonToolbar>
      )
    }
  </Modal>
);
ReportModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  advertisersLog: PropTypes.shape({
    request: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    added: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    removed: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    errors: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }).isRequired,
  offersLog: PropTypes.shape({
    request: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    added: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    removed: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    errors: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }).isRequired,
  downloadCSV: PropTypes.func.isRequired,

};
export default ReportModal;
