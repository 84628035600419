exports.setCache = (name, value) => {
  const { localStorage } = window;
  const date = new Date();
  localStorage.setItem(name, JSON.stringify({ date, value }));
};

exports.getCache = (name, duration = 3600 * 1000) => {
  const { localStorage } = window;
  const date = new Date();
  date.setTime(date.getTime() - duration);
  const value = localStorage.getItem(name);
  if (!value) return null;
  const obj = JSON.parse(value);

  const cacheDate = new Date(obj.date);
  if (cacheDate > date) return obj.value;
  return null;
};

exports.clear = (name) => {
  const { localStorage } = window;
  if (name) {
    localStorage.removeItem(name);
  } else {
    localStorage.clear();
  }
};
