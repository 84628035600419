import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonToolbar, Modal, Button, ModalHeader, Col, Row, Label
} from 'reactstrap';
import { axios } from 'ApiClient';
import Select from 'react-select';
import moment from 'moment/moment';
import withNotice from '../../../../App/store/with-notice';
import { hideModalById } from '../../../../../redux/reducers/TableReducer';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import NumericInput from './NumericInput';

export const modalId = 'POWL5CsSettingsModal';

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const POWL5CsSettingsModal = ({ addNotice, updateSettings }) => {
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState({ value: moment().format('MMMM YYYY'), label: moment().format('MMMM YYYY') });
  const [selectedMonthSettings, setSelectedMonthSettings] = useState([]);
  const dispatch = useDispatch();

  const fetchSettingsFromDictionary = async () => {
    setLoading(true);
    try {
      const resp = await axios.get('/api/portal/reports/get-powl5cs-settings');
      setSettings(resp.data.settings || []);
      if (resp.data.settings && selectedMonth) {
        let newSettings = [];
        const monthSettings = resp.data.settings.find(s => s.period === selectedMonth.value);
        if (monthSettings?.values && Array.isArray(monthSettings.values) && monthSettings.values.length > 0) {
          newSettings = monthSettings.values;
        }
        setSelectedMonthSettings(newSettings);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (modalInfo) {
      fetchSettingsFromDictionary();
    }
  }, [modalInfo]);

  useEffect(() => {
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : -1)));
    }).catch((e) => {
      console.log(e);
    });
  }, []);

  const advertiserOptions = useMemo(() => advertisers.filter(b => b.displayInUi === 'Yes').map(a => ({ label: a.companyName, value: a.tuneId })), [advertisers]);

  useEffect(() => {
    if (selectedMonth?.value) {
      let newSettings = [];
      const monthSettings = settings.find(s => s.period === selectedMonth.value);
      if (monthSettings?.values && Array.isArray(monthSettings.values) && monthSettings.values.length > 0) {
        newSettings = monthSettings.values;
      }
      setSelectedMonthSettings(newSettings);
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (selectedMonth?.value) {
      const settingsClone = [...settings];
      const index = settingsClone.findIndex(item => item.period === selectedMonth.value);
      if (index !== -1) {
        settingsClone[index].values = selectedMonthSettings;
      } else if (selectedMonth) {
        settingsClone.push({
          period: selectedMonth.value,
          values: selectedMonthSettings
        })
      }
      setSettings(settingsClone);
    }
  }, [selectedMonthSettings]);

  const close = () => {
    dispatch(hideModalById(modalId));
    setSettings([]);
    setSelectedMonthSettings([]);
    setLoading(false);
    setSelectedAdvertiser(null);
    setSelectedMonthSettings([]);
  };
  const saveSettings = async () => {
    try {
      setLoading(true);
      await axios.post('/api/portal/reports/post-save-powl5cs-settings', { settings });
      updateSettings();
      close();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const addAdvertiser = () => {
    if (selectedAdvertiser) {
      const alreadyExists = selectedMonthSettings.find(elem => elem.tuneId === selectedAdvertiser.value);
      if (alreadyExists) {
        addNotice({
          message: 'This brand already exists in the settings for selected month.',
          type: 'warning',
        });
      } else {
        const row = {
          brand: selectedAdvertiser.label,
          tuneId: selectedAdvertiser.value,
          contracts: 1,
          content: 1,
          creators: 1,
          conversions: 1,
        };
        const newMonthSettings = [row, ...selectedMonthSettings];
        setSelectedMonthSettings(newMonthSettings);
        setSelectedAdvertiser(null);
      }
    } else {
      addNotice({
        message: 'Select a brand to add.',
        type: 'warning',
      });
    }
  }

  const getMonthsOptions = () => {
    const startDate = moment('2024-01-01');
    const endDate = moment().endOf('year').add(1, 'year');
    const months = [];

    while (startDate.isBefore(endDate)) {
      months.push({ value: startDate.format('MMMM YYYY'), label: startDate.format('MMMM YYYY') });
      startDate.add(1, 'month');
    }

    return months;
  };

  const monthsOptions = getMonthsOptions();

  const removeRow = (tuneId) => {
    setSelectedMonthSettings(selectedMonthSettings.filter(item => item.tuneId !== tuneId))
  };

  const updateRowProperty = (tuneId, value, property) => {
    const selectedMonthSettingsClone = [...selectedMonthSettings];
    const index = selectedMonthSettingsClone.findIndex(item => item.tuneId === tuneId);
    if (index !== -1) {
      selectedMonthSettingsClone[index][property] = value;
    }
    setSelectedMonthSettings(selectedMonthSettingsClone);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: row => row.brand || '',
        id: 'brand',
        Cell: ({ value }) => (<div style={{ minWidth: '170px'}}>{value || ''}</div>),
      },
      {
        Header: 'Contracts',
        accessor: row => Number(row.contracts || 0),
        id: 'contracts',
        Cell: ({ value, row: { original: { tuneId } } }) => (
          <NumericInput
            originalValue={value}
            updateRowProperty={newValue => updateRowProperty(tuneId, newValue, 'contracts')}
            property="contracts"
          />
        ),
        width: 100,
      },
      {
        Header: 'Content',
        accessor: row => Number(row.content || 0),
        id: 'content',
        Cell: ({ value, row: { original: { tuneId } } }) => (
          <NumericInput
            originalValue={value}
            updateRowProperty={newValue => updateRowProperty(tuneId, newValue, 'content')}
            property="content"
          />
        ),
        width: 100,
      },
      {
        Header: 'Active Creators',
        accessor: row => Number(row.creators || 0),
        id: 'creators',
        Cell: ({ value, row: { original: { tuneId } } }) => (
          <NumericInput
            originalValue={value}
            updateRowProperty={newValue => updateRowProperty(tuneId, newValue, 'creators')}
            property="creators"
          />
        ),
        width: 100,
      },
      {
        Header: 'Conversions',
        accessor: row => Number(row.conversions || 0),
        id: 'conversions',
        Cell: ({ value, row: { original: { tuneId } } }) => (
          <NumericInput
            originalValue={value}
            updateRowProperty={newValue => updateRowProperty(tuneId, newValue, 'conversions')}
            property="conversions"
          />
        ),
        width: 100,
      },
      {
        Header: '',
        accessor: row => row.tuneId,
        id: 'delete',
        Cell: ({ value }) => (
          <div style={{ maxWidth: '20px', marginTop: '-6px' }}>
            <button
              title="Remove this setting"
              className="close"
              type="button"
              onClick={() => removeRow(value)}
            >
              <i
                className="fa fa-plus color-danger"
                style={{
                  width: '15px',
                  height: '15px',
                  display: 'inline-block',
                  transform: 'rotate(45deg)',
                }}
              />
            </button>
          </div>
        ),
        width: 30,
      },
    ],
    [selectedMonthSettings],
  );

  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withDragAndDrop: true,
    withPagination: false,
    withSearchEngine: false,
    placeholder: 'Search...',
  };

  const updateDraggableData = (result) => {
    const reorderedData = reorder(
      selectedMonthSettings,
      result.source.index,
      result.destination.index,
    );
    setSelectedMonthSettings(reorderedData);
  };

  if (!modalInfo) return false;
  const { isShow } = modalInfo;

  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      size="md"
      style={{ minWidth: '900px' }}
    >
      <ModalHeader toggle={() => close()} tag="h3">
        Monthly Projection Target Settings
      </ModalHeader>
      <div className="modal__body ltr-support px-3 pt-3">
        <Row className="align-items-center mb-3">
          <Col md={4}>
            <Label className="my-0">Show Settings for Month:</Label>
          </Col>
          <Col>
            <Select
              value={selectedMonth}
              options={monthsOptions}
              onChange={setSelectedMonth}
              placeholder="Select Month..."
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  height: '38px',
                  fontSize: '16px',
                }),
                menu: baseStyles => ({
                  ...baseStyles,
                  fontSize: '14px',
                }),
              }}
            />
          </Col>
        </Row>
        {selectedMonth && (
          <>
            <Row>
              <Col md={6}>
                <h4 className="font-weight-bold mt-3">Projection Targets - {selectedMonth.value}</h4>
              </Col>
              <Col md={4} style={{ marginTop: '8px' }}>
                <Select
                  value={selectedAdvertiser}
                  options={advertiserOptions}
                  onChange={setSelectedAdvertiser}
                  placeholder="Select Brand..."
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      height: '38px',
                      fontSize: '16px',
                    }),
                    menu: baseStyles => ({
                      ...baseStyles,
                      fontSize: '14px',
                    }),
                  }}
                  isDisabled={loading}
                />
              </Col>
              <Col md={2} style={{ marginTop: '8px' }}>
                <Button
                  color="primary"
                  onClick={addAdvertiser}
                  disabled={!selectedAdvertiser}
                  style={{ height: '38px' }}
                  className="w-100"
                >
                  + Add
                </Button>
              </Col>
            </Row>
            <div
              style={{
                maxHeight: '450px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              <ReactTableBase
                key="searchable"
                columns={columns}
                data={selectedMonthSettings}
                updateDraggableData={updateDraggableData}
                tableConfig={tableConfig}
              />
            </div>
          </>
        )}
      </div>
      <ButtonToolbar
        className="modal__footer"
        style={selectedMonthSettings?.length > 0  ? { marginTop: '-32px' } : {}}
      >
        <Button color="primary" onClick={saveSettings} disabled={loading} className="mr-2">Save</Button>
        <Button color="secondary" onClick={close} disabled={loading}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

export default withNotice(POWL5CsSettingsModal);
