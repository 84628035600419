import React, { useState, useEffect } from 'react';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  FormGroup,
} from 'reactstrap';
import { CKEditor } from 'ckeditor4-react';
import { axios } from 'ApiClient';
import Hook from '../../../../../../../../shared/hooks';

const modalId = 'NotesModal';

const NotesModal = () => {
  const [loading, setLoading] = useState(false);
  const [notesState, setNotes] = useState('');
  const { data: dataModal, showModal: isOpen, close } = Hook.useModalHook(modalId);

  console.log(dataModal, 'DATA MMODAL');

  const {
    record: prospect, updateRecordFunction, notes, title, name,
  } = dataModal;


  useEffect(() => {
    if (isOpen && notes) {
      setNotes(notes);
    } else setNotes('');
  }, [prospect, notes]);
  if (!dataModal || !dataModal.record) return false;

  const handleInputChange = (e) => {
    const { editor } = e;
    setNotes(editor.getData());
  };

  const submit = async () => {
    setLoading(true);

    const { id } = prospect;
    try {
      const response = await axios.post('/api/portal/prospects/post-update-property-on-cpm-prospect', {
        ids: [id],
        values: {
          [name]: notesState,
        },
      });
      setLoading(false);
      const _record = response.data?.recordPopulated;
      if (_record && updateRecordFunction) {
        updateRecordFunction(id, { [name]: _record.params[name] });
      }
      close();
    } catch (e) {
      console.log(e);
    }
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => { close(); }}
      style={{ minWidth: '670px' }}
    >
      <ModalHeader
        toggle={() => {
          setNotes('');
          close();
        }}
        tag="h4"
        className="modal-title-statistics pl-0"
      >
        {title || ''}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {prospect ? (
              <FormGroup>
                <CKEditor
                  config={{versionCheck: false}}
                  id="bodyEditor"
                  name="itemText"
                  activeClass="form-control"
                  initData={notesState}
                  onChange={(e) => { handleInputChange(e); }}
                />
              </FormGroup>
            ) : null}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading} style={{ marginRight: '10px' }}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>
        <Button className="modal_cancel btn-sm" onClick={() => { close(); }}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};
export default NotesModal;
