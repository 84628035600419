import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Admin from './Admin';


const WrappedRoutes = ({ admin }) => (
  <div>
    {admin && admin._id ? <Route path="/" component={Admin} /> : <Redirect to="/login" />}
  </div>
);
WrappedRoutes.propTypes = {
  admin: PropTypes.objectOf(PropTypes.any),
};
WrappedRoutes.defaultProps = {
  admin: null,
};

export default connect(state => ({
  admin: state.session,
}))(WrappedRoutes);
