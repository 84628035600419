import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { unflatten } from 'flat';
import { axios } from 'ApiClient';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import Hook from '../../../../../../../../shared/hooks';

const ApprovalStatus = React.memo(({
  p,
  placeholder,
  notes,
  name,
  noteName,
}) => {
  const aValues = {
    approved: 'Approved',
    inReview: 'In Review',
    pendingEdits: 'Pending Edits',
    hold: 'Hold',
    rejected: 'Rejected',
    actionRequired: 'Action Required',
  };
  if (name !== 'approvalStatus') delete aValues.actionRequired;
  const { updateRecordFunction } = p;
  const [showModal] = Hook.useShowModalHook('NotesModal');

  const rowData = p.row.original.params;
  const val = rowData[name]?.status;
  // console.log(val, rowData, 'valueApp');
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const options = Object.keys(aValues).map(k => ({ value: k, label: aValues[k] }));
  useEffect(() => {
    setValue(val);
  }, [rowData._id, val]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option.value);
    try {
      const data = await axios.post('/api/portal/prospects/post-update-property-on-cpm-prospect', {
        ids: [rowData._id],
        values: {
          [name]: option.value,
        },
      });
      const record = unflatten(data?.data?.recordPopulated);
      console.log(record.params[name]);
      updateRecordFunction(rowData._id, { [name]: record.params[name] });
      setValue(record.params[name].status);
    } catch (e) {
      console.log(e);
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };
  return (
    <div style={{ width: '210px' }}>
      <div style={{ width: '150px', float: 'left' }} >
        <Select
          value={options.filter(option => option.value === value)}
          options={options}
          onChange={handleChange}
          className="statusBox"
          placeholder={placeholder}
          isDisabled={disabled}
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
        {error ? (<span className="text-danger">{error}</span>) : null}
      </div>
      <div style={{ marginTop: '6px', marginRight: '6px', float: 'right' }}>
        <Tooltip title={`${placeholder} Notes`}>
          <IconButton
            size="small"
            onClick={() => {
              showModal({
                record: p.row.original, notes, title: `${placeholder} Notes`, name: noteName, updateRecordFunction,
              });
            }
            }
          >
            <FontAwesomeIcon icon={['fas', 'file-alt']} color={notes ? '#70bbfd' : ''} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.p, a1.p));

ApprovalStatus.propTypes = {
  p: PropTypes.objectOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  noteName: PropTypes.string.isRequired,
};

export default ApprovalStatus;
