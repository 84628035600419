import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import withNotice from '../../../../App/store/with-notice';

const getAdvertisersFromRecord = (record, brandOptions) => {
  if (record?.params?.advertisers?.length > 0 && brandOptions?.length > 0) {
    return brandOptions.filter(item => record.params.advertisers.includes(item.value));
  }
  return [];
}

const AdvertisersInList = ({
  addNotice,
  record,
  loadingBrandOptions,
  brandOptions,
}) => {
  const [loading, setLoading] = useState(false);
  const [advertisers, setAdvertisers] = useState(getAdvertisersFromRecord(record, brandOptions));

  useEffect(async () => {
    setAdvertisers(getAdvertisersFromRecord(record, brandOptions));
  }, [record?.params?.advertisers, brandOptions]);

  const sendRequest = (newValue, oldValue) => {
    setLoading(true);

    axios.post('/api/portal/compliance/post-edit-content-review-rule', {
      id: record.id,
      fieldsToUpdate: { advertisers: newValue || [] },
    }).then((response) => {
      if (response?.data?.success) {
        addNotice({
          message: 'Rule was successfully updated.',
          type: 'success',
        });
      }
      if (response?.data?.error) {
        console.error(response.data.error);
        setAdvertisers(oldValue);
        addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
      setLoading(false);
    });
    return true;
  };

  const onChange = (values) => {
    const oldValue = advertisers;
    setAdvertisers(values);
    const newValue = values.map(item => item.value);
    sendRequest(newValue, oldValue);
  };

  return (
    <div className="w-100" style={{ marginBottom: 0 }}>
      <Select
        className="adverisers"
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Brands"
        value={advertisers}
        onChange={onChange}
        options={brandOptions}
        isDisabled={loading || loadingBrandOptions}
        isMulti
        styles={{
          control: provided => ({
            ...provided,
            maxHeight: '100px',
            width: '100%',
          }),
          indicatorsContainer: provided => ({
            ...provided,
            maxHeight: '100px',
          }),
          valueContainer: provided => ({
            ...provided,
            maxHeight: '98px',
            overflowY: 'auto',
          }),
        }}
      />
    </div>
  );
};

AdvertisersInList.propTypes = {
  addNotice: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingBrandOptions: PropTypes.bool.isRequired,
  brandOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withNotice(AdvertisersInList);
