import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  FormGroup,
  Modal,
} from 'reactstrap';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import SelectAsync from 'react-select/async';
import ApiClient from 'ApiClient';
import withNotice from '../../../../../App/store/with-notice';


const ModalComponent = ({
  isOpen,
  record,
  toggleModal,
  onUpdateRecordFunc,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const [assignTo, setAssignTo] = useState({});

  useEffect(async () => {
    setAssignTo({});
  }, [isOpen]);

  const closeFormDialog = () => {
    toggleModal();
    setLoading(false);
  };
  const api = new ApiClient();

  const submit = () => {
    if (typeof assignTo.value === 'undefined') {
      return;
    }
    setLoading(true);
    const data = new FormData();
    data.append('assignFrom', record._id);
    data.append('assignTo', assignTo.value);
    api.client.post('/api/portal/influencers/merge-tags', data).then((response) => {
      setLoading(false);
      if (response.data.success) {
        onUpdateRecordFunc();
        closeFormDialog();
        addNotice({
          message: 'The selected Tags have been merged',
          type: 'success',
        });
      } else {
        addNotice({
          message: 'An error occurred',
          type: 'error',
        });
      }
    }).catch((error) => {
      setLoading(false);
      console.log(error);
      addNotice({
        message: 'An error occurred',
        type: 'error',
      });
    });
  };

  const loadTagsOption = async (inputValue) => {
    const response = await api.client.get(`/api/portal/influencers/get-tags-by-name?term=${inputValue}&type=${record.type}`);
    // eslint-disable-next-line array-callback-return,consistent-return
    return response.data.tags.filter((item) => {
      if (item._id === record._id) {
        return false;
      }
      return true;
    }).map(item => ({
      value: item._id,
      label: item.tagName,
    }));
  };

  return (
    <div style={{ display: 'inline' }}>
      <Modal
        isOpen={isOpen}
        toggle={closeFormDialog}
        modalClassName="ltr-support"
        className="modal-dialog"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeFormDialog} />
          <h4 className="text-modal  modal__title">Merge Tags</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12}>
              <FormGroup>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label htmlFor="email">{`Delete the Tag "${record.tagName}" and assign Creators to`}</label>
                <SelectAsync
                  id="assignTo"
                  cacheOptions
                  value={assignTo}
                  defaultOptions
                  loadOptions={loadTagsOption}
                  onChange={e => setAssignTo(e)}
                  placeholder="Please Select Tag to assign the Creators"
                  disabled={loading}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_ok btn-sm" color="primary" onClick={submit}>
            {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
            <span>Save</span>
          </Button>{' '}
          <Button className="modal_cancel btn-sm" onClick={closeFormDialog}>Cancel</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onUpdateRecordFunc: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  adminUser: state.session,
});

export default withNotice(connect(mapStateToProps)(ModalComponent));
