/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { uid } from 'react-uid';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, Collapse, Row, Col, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import Hook from '../../../../../../../shared/hooks';
import { axios } from 'ApiClient';
import ReactTableBase from '@/shared/tables/table/ReactTableBase';
import formatter from '../../../../../../../shared/helpers/WVFormatter'

export const modalId = 'billComPayables';

const paymentStatus = {
  0: 'Paid in full',
  1: 'Open',
  2: 'Partial payment',
  4: 'Scheduled',
  6: 'In process',
};
const columnTransform = [
  {
    id: 'date',
    Header: 'Created Time',
    
    accessor: record => (record?.createdTime) || '',
    Cell: value => formatter.formatDate(value.value),
  },
  {
    id: 'invoiceNumber',
    Header: 'Invoice Number',
    
    accessor: record => (record?.invoiceNumber) || '',
    Cell: value => value.value || '',
  },
  {
    id: 'invoiceDate',
    Header: 'Invoice Date',
    
    accessor: record => (record?.invoiceDate) || '',
    Cell: value => formatter.formatDate(value.value),
  },
  {
    id: 'description',
    Header: 'Description',
    accessor: record => (record?.description) || '',
    Cell: value => <div className="text-break">{value.value || ''}</div>,
  },
  {
    id: 'dueDate',
    Header: 'Due Date',
    
    accessor: record => (record?.dueDate) || '',
    Cell: value => formatter.formatDate(value.value),
  },
  {
    id: 'active',
    Header: 'Is Active',
    
    accessor: record => (String(record?.isActive) === '1' ? 'Active' : 'Inactive') || '',
    Cell: value => value.value || '',
  },
  {
    id: 'amount',
    Header: 'Amount',
    Cell: value => formatter.formatCurrency(Number(value.value || 0)),
    accessor: record => (record?.amount) || '',
  },
  {
    id: 'status',
    Header: 'Payment Status',
    
    accessor: record => (paymentStatus[record?.paymentStatus]) || '',
    Cell: value => value.value || '',
  },
];
const tableConfig = {
  isEditable: false,
  isResizable: true,
  isSortable: true,
  withPagination: true,
  manualPageSize: [5, 10, 15],
  withSearchEngine: true,
  sortBy: 'date',
  direction: 'desc',
  searchFieldMinWidth: '50%',
};

const Items = ({ items }) => (
  <div
    style={{
      padding: '15px 15px',
      border: '1px solid #cccccc',
      borderRadius: '5px',
      height: '100%',
    }}
  >
    <Row>
      {items.map(({ label, value }) => (
        <Col key={uid(label)}>
          <div style={{ textAlign: 'center', color: 'black', fontWeight: 'bold' }} >
            <span style={{ width: '100%', fontSize: '16px' }}>{label}</span>
            <span
              style={{
                fontSize: '20px',
                fontWeight: '700',
                marginBottom: '12px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {value}
            </span>
          </div>
        </Col>
      ))}
    </Row>
  </div>);
Items.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.any),
    ]).isRequired,
  })).isRequired,
};

const Item = ({ vendor }) => {
  const [collapse, setCollapse] = useState(false);

  console.log(vendor);
  if(!vendor) return null;
  return (
    <div key={vendor.id}>
      <div>
        <h4 className="py-2">
          {vendor.name} {vendor.email}
          <Button
            color="primary"
            className='ml-2 text-left'
            id={vendor.id}
            onClick={() => setCollapse(!collapse)}
            size='sm'
          >{collapse ? 'Hide' : 'Show'} Payments { ` found by ${vendor.findBy}`}</Button>
        </h4>
      </div>
      <Collapse isOpen={collapse}>
        <ReactTableBase
          id={vendor.id}
          columns={columnTransform}
          data={vendor.payments || []}
          tableConfig={tableConfig}
        />
        
      </Collapse>
    </div>
  )
  
}

const BillComPayables = () => {
  const { showError } = Hook.useNoticeHook();
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const { data: dataModal, showModal: isShow, close: closeModal } = Hook.useModalHook(modalId);

  const close = () => {
    setVendors([]);
    setLoading(false);
    closeModal();
  }
  useEffect(() => {
    if (!isShow) {
      setLoading(false);
    }
  }, [isShow]);
  useEffect(async() => {
    if(!isShow) return false;
    setLoading(true);
    try {
      const res = await axios({
        method: 'post',
        url: '/api/portal/finance/post-get-bill-com-payments',
        data: { dealId: dataModal.deal._id, influencer: dataModal?.influencer?._id, agents: dataModal.agentUsers?.map(a => a._id) },
      });
      setLoading(false);
      if (res?.data?.success) {
        setVendors(res.data.vendors);
      } else if (res?.data?.error) {
        showError(res?.data?.error)
      }
      // close();
    } catch (error) {
      console.error(error);
      showError(error.message || 'Unable to get right Payments')
      setLoading(false);
    }
  }, [isShow])
  const invoiceNumber = useMemo(() => {
    const dealInvoice = vendors.find(v => v.findBy === 'Invoice Number');
    return dealInvoice?.name || '---';
  }, [vendors])
  if (!dataModal?.deal) return false;
  console.log(dataModal);

  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '1200px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Select Offline Payment
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="">
            <Items
              items={[
                {
                  label: 'DealType',
                  value: (<h4>{dataModal.deal.dealTerms.type_of_deal}</h4>),
                },
                {
                  label: 'Bill Create Date',
                  value: (<h4>{formatter.formatDate(dataModal.bill.createdAt)}</h4>),
                },
                {
                  label: 'Brand Approved Date',
                  value: (<h4>{formatter.formatDate(dataModal.deal.brandApprovedDate)}</h4>),
                },
                {
                  label: 'Deal Owner',
                  value: (<h4>{dataModal.deal?.adminUserObject?.firstName} {dataModal.deal?.adminUserObject?.lastName}</h4>),
                },
                {
                  label: 'Brand',
                  value: (<h4>{dataModal.deal.dealTerms.brand}</h4>),
                },
                {
                  label: 'Agency Invoice Number',
                  value: (<h4>{invoiceNumber}</h4>),
                },
                {
                  label: 'Promo Code',
                  value: (<h4>{dataModal.deal.dealTerms.coupon_code}</h4>),
                },
              ]}
            />
          </Col>
        </Row>
        {loading ? <div className="text-center mt-2"><Spinner size="large" /></div> : null}
        {vendors.map(v => (<Item vendor={v}/>))}
        
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-sm"
          color="secondary"
          onClick={close}
          disabled={loading}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

BillComPayables.propTypes = {
  updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

BillComPayables.defaultProps = {

};

export default (BillComPayables);
