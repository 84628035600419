import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Col, Modal, ModalHeader, Row } from 'reactstrap';

const DealValidationModal = ({ showModal, setShowModal, validationData }) => {
  // const [showModal, setShowModal] = useState(false);
  console.log('here');

  // useEffect(() => {
  //   setShowModal(isOpen);
  //   console.log('Here');
  // }, [isOpen]);

  return (
    <Modal
      isOpen={showModal}
      toggle={() => { setShowModal(false); }}
    >
      <ModalHeader
        toggle={() => { setShowModal(false); }}
        tag="h4"
      >
        Deal Validation Results
      </ModalHeader>
      <div className="modal__body finance-form ltr-support">
        <Row className="mt-3">
          <Col xs={3} className="text-center mt-2" style={{ color: '#70bbfd' }}> <i className="fa fa-exclamation-triangle" style={{ fontSize: '5em' }} /></Col>
          <Col xs={9}>
            <span><strong>Unable to send payment</strong></span>
            <ul className="list-items-color-blue">
              {validationData.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => { setShowModal(false); }}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

DealValidationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  validationData: PropTypes.arrayOf(PropTypes.any),
};

DealValidationModal.defaultProps = {
  validationData: [],
};

export default DealValidationModal;
