import { useState, useEffect } from 'react';
import { axios } from 'ApiClient';

function useServiceAgreements(influencerIds, setLoading) {
  const [agreements, setAgreements] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (influencerIds.length > 0) {
        setLoading(true);
        const idSet = new Set();
        for (let i = 0; i < influencerIds.length; i += 1) {
          idSet.add(influencerIds[i]);
        }
        axios({
          method: 'get',
          url: `/api/portal/agent-agreements?influencerIds=${[...idSet]}`,
        }).then((response) => {
          if (response?.data?.success && response.data.agreements) {
            setAgreements(response?.data?.agreements || []);
          }
          setLoading(false);
        }).catch((err) => {
          console.log(err);
          setLoading(false);
        });
      }
    }

    if (influencerIds) {
      fetchData();
    }
  }, [influencerIds.length]);

  return { agreements };
}

export default useServiceAgreements;
