import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PaymentMethods = ({ record }) => {
  const recordUnflatten = unflatten(record);
  return (
    <div>
      <div className={`float-left mr-2 mt-1 ${recordUnflatten.payment.stripe.accountId ? 'text-success' : 'text-secondary'}`}>
        <FontAwesomeIcon icon={['fab', 'cc-stripe']} style={{ fontSize: '2em' }} />
      </div>
      <div className="float-left mr-2 mt-1">
        <img src={`/images/payments/billCom${recordUnflatten.payment.billCom.accountId ? 'Green' : 'Gray'}.png`} style={{ width: '30px' }} className="img-fluid" alt="Bill.com" />
      </div>
    </div>
  );
};
PaymentMethods.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default PaymentMethods;
