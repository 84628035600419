import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import { clearFilterProperty } from '../../../../../../redux/reducers/TableReducerDefault';
import Hook from '../../../../../../shared/hooks';
// import { numberRangeLabels } from "../../../../../../shared/helpers/Dates";
// import Select from "react-select";
// import {NumericFormat as NumberFormat} from "react-number-format";
// import AmountFilter from "../../../../../../shared/components/filter/AmountFilter";
// import useTableFilterHook from "../../../../../../shared/hooks/useTableFilterHook";
// import IntervalDatePicker from './Table/IntervalDatePicker';
// import Tags from '../../../../../../shared/hooks/components/Tags';

// const colourStyles = {
//   multiValue: styles => ({
//     ...styles,
//     backgroundColor: '#70bbfd',
//   }),
//   multiValueLabel: styles => ({
//     ...styles,
//     color: 'white',
//   }),
//   multiValueRemove: styles => ({
//     ...styles,
//     color: 'white',
//     ':hover': {
//       backgroundColor: '#a2e1fd',
//       color: '#70bbfd',
//     },
//   }),
// };

// const numberRangeFilters = numberRangeLabels();

const Actions = React.memo(({
  downloadCsv,
}) => {
  console.log('RELOAD ACTIONS');
  // const history = useHistory();
  const dispatch = useDispatch();
  // const [showModal] = Hook.useShowModalHook('refreshDeals');
  // const [showTotalsModal] = Hook.useShowModalHook('billsTotals');
  const [showFilters, setShowFilters] = useState(getCookie('taxFormShowFilters') === 'true');

  const [search, setSearch] = Hook.useTableFilterHookString('fullSearchName');
  // const [selectedBrands, setSelectedBrands, brandOptions] = Hook.useTableFilterHookTags('brand');
  const [displayFilters, handleChangeDisplayFilters] = Hook.useTableFilterHookCheckbox('userType');
  const [approvalStatusFilters, handleChangeApprovalStatusFilters] = Hook.useTableFilterHookCheckbox('approvalStatus');
  const [paymentMethodFilters, handleChangePaymentMethodFilters] = Hook.useTableFilterHookCheckbox('paymentMethod');
  const [taxFormFilters, handleChangeTaxFormFilters] = Hook.useTableFilterHookCheckbox('taxFormType');

  // const [dealStageFilters, handleChangeDealStageFilters, dealstagesOptions] = Hook.useTableFilterHookTags('dealstages');

  // const [yearSignedFilters, setYearSignedFilters] = Hook.useTableFilterHook('yearSigned');
  // const [enableYearSignedFilter, handleChangeYSFilters] = Hook.useTableFilterHookCheckbox('enableYearSigned');
  // const [yearSignedFilterType, handleChangeYearSignedFilterType, ysTypeOptions] = Hook.useTableFilterHookTags('yearSignedFilterType');
  // const [yearSignedFrom, handleChangeYearSignedFrom] = useState('');
  // const [yearSignedTo, handleChangeYearSignedTo] = useState('');
  // const [singleYearSigned, handleChangeSingleYearSigned] = useState('');
  // const [yearSignedOptions, setYearSignedOptions] = Hook.useTableFilterHook('yearSigned')
  // console.log(selectedIds, 'selectedIds');
  useEffect(() => {
    setCookie('taxFormShowFilters', showFilters.toString(), 35);
  }, [showFilters]);
  const resetFilters = () => {
    dispatch(clearFilterProperty());
  };
  return (
    <div>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Creator or Agent Name, Email or Company"
              value={search || ''}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => downloadCsv()}>
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      {showFilters && (
        <>
          <Row className="mt-4">
            <Col md={6}>
              <Row>
                <Col sm={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Display
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Creator"
                      label="Creators"
                      value={displayFilters.Creator}
                      onChange={event => handleChangeDisplayFilters(event, 'Creator')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Agent"
                      label="Agents"
                      value={displayFilters.Agent}
                      onChange={event => handleChangeDisplayFilters(event, 'Agent')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3} className="pl-0">
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Approval Status
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="NeedsReview"
                      label="Needs Review"
                      value={approvalStatusFilters['Needs Review']}
                      onChange={event => handleChangeApprovalStatusFilters(event, 'Needs Review')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Approved"
                      label="Approved"
                      value={approvalStatusFilters.Approved}
                      onChange={event => handleChangeApprovalStatusFilters(event, 'Approved')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Expired"
                      label="Expired"
                      value={approvalStatusFilters.Expired}
                      onChange={event => handleChangeApprovalStatusFilters(event, 'Expired')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Rejected"
                      label="Rejected"
                      value={approvalStatusFilters.Rejected}
                      onChange={event => handleChangeApprovalStatusFilters(event, 'Rejected')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Payment Method
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="billCom"
                      label="Bill.com"
                      value={paymentMethodFilters.billCom}
                      onChange={event => handleChangePaymentMethodFilters(event, 'billCom')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="stripe"
                      label="Stripe"
                      value={paymentMethodFilters.stripe}
                      onChange={event => handleChangePaymentMethodFilters(event, 'stripe')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="notAssigned"
                      label="Not Assigned"
                      value={paymentMethodFilters.notAssigned}
                      onChange={event => handleChangePaymentMethodFilters(event, 'notAssigned')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Tax Form
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="W-9"
                      label="W-9"
                      value={taxFormFilters['W-9']}
                      onChange={event => handleChangeTaxFormFilters(event, 'W-9')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="W-8BEN"
                      label="W-8BEN"
                      value={taxFormFilters['W-8BEN']}
                      onChange={event => handleChangeTaxFormFilters(event, 'W-8BEN')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="W-8BEN-E"
                      label="W-8BEN-E"
                      value={taxFormFilters['W-8BEN-E']}
                      onChange={event => handleChangeTaxFormFilters(event, 'W-8BEN-E')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="W-8ECI"
                      label="W-8ECI"
                      value={taxFormFilters['W-8ECI']}
                      onChange={event => handleChangeTaxFormFilters(event, 'W-8ECI')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="W-8IMY"
                      label="W-8IMY"
                      value={taxFormFilters['W-8IMY']}
                      onChange={event => handleChangeTaxFormFilters(event, 'W-8IMY')}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={3} />
            {/* <Col md={3}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Year Signed
              </Label>
              <AmountFilter
                enable={yearSignedOptions.enable}
                filterType={yearSignedOptions.filterType}
                amountFrom={yearSignedOptions.amountFrom}
                amountTo={yearSignedOptions.amountTo}
                setValues={(prop) => {
                  console.log('setValue', prop)
                  if (prop.singleAmount && prop.singleAmount !== yearSignedOptions.amountFrom) {
                    setYearSignedOptions({ ...yearSignedOptions, amountFrom: prop.singleAmount })
                  }
                  else {
                    const [key] = Object.keys(prop);
                    if(yearSignedOptions[key] !== prop[key]) setYearSignedOptions({ ...yearSignedOptions, ...prop })
                  }
                }}
              /> */}
              {/* <Row style={{ marginTop: '8px' }}>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '8px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="enable"
                      value={enableYearSignedFilter.enable}
                      onChange={event => handleChangeYSFilters(event, 'enable')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={4} style={{ paddingLeft: '20px', paddingRight: 0 }}>
                  <Select
                    value={yearSignedFilterType}
                    onChange={(option) => {
                      if ((yearSignedFilterType.value !== 'Between' && option.value === 'Between') || (yearSignedFilterType.value === 'Between' && option.value !== 'Between')) {
                        handleChangeYearSignedFrom(null);
                        handleChangeYearSignedTo(null);
                        handleChangeSingleYearSigned(null);
                      }
                      handleChangeYearSignedFilterType(option || []);
                    }}
                    options={ysTypeOptions}
                    isDisabled={!enableYearSignedFilter}
                    // styles={customStyles}
                  />
                </Col>
                <Col sm={7}>
                  {yearSignedFilterType.value === 'Between' ? (
                    <div>
                      <NumberFormat
                        key="ysMin"
                        disabled={!enableYearSignedFilter}
                        prefix=""
                        name="what"
                        value={yearSignedFrom}
                        className="form-control form-control-sm"
                        placeholder="Start Year"
                        inputMode="numeric"
                        allowEmptyFormatting={false}
                        fixedDecimalScale
                        decimalScale={0}
                        onValueChange={values => handleChangeYearSignedFrom(values.floatValue)}
                        allowNegative={false}
                        onBlur={() => {
                          if (
                            yearSignedFrom
                            && yearSignedTo
                            && (yearSignedFrom > yearSignedTo)
                          ) {
                            handleChangeYearSignedTo(yearSignedFrom + 1);
                          }
                        }}
                        style={{ height: '38px' }}
                      />
                      <NumberFormat
                        key="ysMax"
                        disabled={!enableYearSignedFilter}
                        prefix=""
                        name="what"
                        value={yearSignedTo}
                        className="form-control form-control-sm"
                        style={{ marginTop: '16px', height: '38px' }}
                        placeholder="End Year"
                        inputMode="numeric"
                        allowEmptyFormatting={false}
                        fixedDecimalScale
                        decimalScale={0}
                        onValueChange={values => handleChangeYearSignedTo(values.floatValue)}
                        allowNegative={false}
                        onBlur={() => {
                          if (
                            yearSignedFrom
                            && yearSignedTo
                            && (yearSignedFrom > yearSignedTo)
                          ) {
                            handleChangeYearSignedFrom(yearSignedTo.parseFloat() - 1);
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <NumberFormat
                        key="singleYS"
                        disabled={!enableYearSignedFilter}
                        prefix=""
                        name="what"
                        value={singleYearSigned}
                        className="form-control form-control-sm"
                        placeholder="Count"
                        inputMode="numeric"
                        allowEmptyFormatting={false}
                        fixedDecimalScale
                        decimalScale={0}
                        onValueChange={values => handleChangeSingleYearSigned(values.floatValue)}
                        allowNegative={false}
                        style={{ height: '38px' }}
                      />
                    </div>
                  )}
                </Col>
              </Row> */}
            {/* </Col> */}
            <Col md={3} />
          </Row>
        </>
      )}
    </div>
  );
});
Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
};

export default Actions;
