import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { axios } from 'ApiClient';
import { Row, Col, Button, Modal, ButtonToolbar, FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';

import withNotice from '../../App/store/with-notice';
import CheckBoxField from '../../../shared/components/CheckBox';
// import RadioButton from '../../../shared/components/RadioButton';
import { getConstant } from '../../../shared/helpers/WVConstants';

const SummaryReport = ({
  startDate, endDate, save, saveReportModal, savedReportModal, setSavedReportModal, setSaveReportModal, saveReport, setSaveReport, type, ignorePeriod,
}) => {
  const reportsNames = getConstant('report', 'typeToName', {});
  const [value, setValue] = useState(reportsNames[type] || '');
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const maxLimitSave = Number(getConstant('report', 'generateReportMonthCount') || 6);
  const shouldShowMessage = !ignorePeriod && moment(endDate).diff(moment(startDate), 'month') > maxLimitSave;

  useEffect(() => (shouldShowMessage ? setSaveReport(true) : null), [shouldShowMessage]);
  useEffect(async () => {
    try {
      const resp = await axios.get('/api/portal/finance/get-deal-owners');
      setEmployees(resp.data.dealOwners);
    } catch (e) {
      console.log(e);
    }
  }, []);
  /* useEffect(async () => {
    try {
      console.log(keyPermission);
      const empl = employees.filter(admin => admin.permissions.find(p => p === keyPermission));
      console.log(empl, employees);
      // setSelectedEmployees(empl.map(item => ({ value: item._id, label: `${item.firstName || ''} ${item.lastName || ''}` })));
    } catch (e) {
      console.log(e);
    }
  }, [keyPermission, employees]); */

  const employeeOptions = useMemo(() => {
    const i = employees.map(item => ({ value: item._id, label: `${item.firstName || ''} ${item.lastName || ''}` }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [employees]);
  return (
    <div>
      {!shouldShowMessage ? (
        <Row>
          <Col xs="12">
            <div className="theme-light radio-in-prospects">
              <FormGroup className="mb-0 color-text" inline>
                <CheckBoxField
                  name="savereport"
                  label="Save Report"
                  value={saveReport}
                  onChange={({ target }) => target && (setSaveReport(target.checked) || console.log(target.checked))}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
      ) : null}
      <Modal
        isOpen={savedReportModal}
        toggle={() => setSavedReportModal(false)}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Saved Report?</h4>
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body ltr-support">
          The Report was saved successfully, and will be generated soon.
        </div>
        <ButtonToolbar className="modal__footer">
          <Button color="danger" onClick={() => setSavedReportModal(false)}>Close</Button>
        </ButtonToolbar>
      </Modal>
      <Modal
        isOpen={saveReportModal}
        toggle={() => setSaveReportModal(false)}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Saved Report</h4>
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body ltr-support">
          Are you sure you with to save this report?
          <FormGroup className="mt-2">
            <Label>Report Name</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Report Name"
              value={value}
              onChange={e => setValue(e.target.value)}
              style={{
                border: '1px solid #ced4da',
                borderRadius: '5px',
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Users allowed to view generated report</Label>
            <Select
              isMulti
              value={selectedEmployees}
              options={employeeOptions}
              onChange={setSelectedEmployees}
              placeholder="Select Admin User..."
              isClearable
            />
          </FormGroup>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            color="primary"
            onClick={() => save(value, selectedEmployees?.map(s => s.value) || [])}
            className="mr-1"
          >Save
          </Button>{' '}
          <Button color="danger" onClick={() => setSaveReportModal(false)}>Close</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};
SummaryReport.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  saveReportModal: PropTypes.bool.isRequired,
  savedReportModal: PropTypes.bool.isRequired,
  setSavedReportModal: PropTypes.func.isRequired,
  setSaveReportModal: PropTypes.func.isRequired,
  saveReport: PropTypes.bool.isRequired,
  setSaveReport: PropTypes.func.isRequired,
  // keyPermission: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  ignorePeriod: PropTypes.bool,
};
SummaryReport.defaultProps = {
  ignorePeriod: false,
};
export default withNotice(SummaryReport);
