// import { unflatten } from 'flat';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import ApiClient from 'ApiClient';

const CreatorLastName = ({ record }) => {
  const key = 'lastname';
  const recordUnflatten = record;
  const rowData = recordUnflatten.params;
  let val = '';
  if (rowData[key] && rowData[key] !== null) val = rowData[key];
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => setValue(val), [val]);

  const handleChange = async (newValue) => {
    setValue(newValue);
    const data = new FormData();
    data.append(key, newValue || '');
    const api = new ApiClient();
    setDisabled(true);
    api.recordAction({
      resourceId: 'Prospect',
      recordId: record.id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      setDisabled(false);
    });
    return true;
  };

  return (
    <Input
      type="text"
      id="lastname"
      name="lastname"
      placeholder="Last Name"
      onChange={e => setValue(e.target.value)}
      value={value}
      onBlur={() => handleChange(value)}
      disabled={disabled}
    />
  );
};
CreatorLastName.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default CreatorLastName;
