import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ApiClient from 'ApiClient';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import withNotice from '../../../App/store/with-notice';

const SummaryReport = ({
  addNotice, isSaved, selectedAdvertisers, setSelectedAdvertisers, selectedCsps, setSelectedCsps, initialData, setAdvertisersOptions,
}) => {
  const [advertisers, setAdvertisers] = useState([]);
  const [cspOptions, setCspOptions] = useState([]);
  const [loadingAdvertiser, setLoaingAdvertiser] = useState(false);
  useEffect(() => {
    const api = new ApiClient();
    setLoaingAdvertiser(true);
    api.client.get('/api/portal/finance/get-advertisers').then((resp) => {
      setAdvertisers(resp.data.advertisers.filter(b => b.displayInUi === 'Yes').map(e => ({ value: e._id, label: `${e.companyName}` })));
      setAdvertisersOptions(resp.data.advertisers.map(e => ({ value: e._id, label: `${e.companyName}`, tuneId: e.tuneId })));
      setLoaingAdvertiser(false);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load advertisers, Check out console to see more information.',
        type: 'error',
      });
    });
    api.client.get('/api/portal/finance/get-deal-owners').then((resp) => {
      setCspOptions(resp.data.dealOwners.map(e => ({ value: e._id, label: `${e.firstName} ${e.lastName}` })));
    }).catch((e) => {
      console.log(e);
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load deal Owners, Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);

  useEffect(() => {
    if (cspOptions.length > 0 && initialData?.selectedCSP?.length > 0) {
      setSelectedCsps(initialData?.selectedCSP.map(cspId => cspOptions.find(s => s.value.toString() === cspId.toString())));
    }
    if (advertisers.length > 0 && initialData?.selectedAdvertisers?.length > 0) {
      console.log('setAdv', advertisers);
      setSelectedAdvertisers(initialData?.selectedAdvertisers.map(cspId => advertisers.find(s => s.value.toString() === cspId.toString())));
    }
  }, [initialData, cspOptions, advertisers]);

  return (
    <Row key="2">
      <Col>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                Brand(s)
              </Label>
              <Select
                isDisabled={loadingAdvertiser || isSaved}
                value={selectedAdvertisers}
                options={advertisers}
                onChange={e => setSelectedAdvertisers(e)}
                placeholder="Select Brand..."
                isClearable
                isMulti
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                Deal Owner(s)
              </Label>
              <Select
                isMulti
                value={selectedCsps}
                options={cspOptions}
                onChange={i => setSelectedCsps(i)}
                placeholder="Select Deal Owner..."
                isClearable
                isDisabled={isSaved}
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
  selectedAdvertisers: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCsps: PropTypes.arrayOf(PropTypes.any).isRequired,
  isSaved: PropTypes.bool.isRequired,
  setSelectedAdvertisers: PropTypes.func.isRequired,
  setSelectedCsps: PropTypes.func.isRequired,
  setAdvertisersOptions: PropTypes.func.isRequired,
  initialData: PropTypes.objectOf(PropTypes.any),
};
SummaryReport.defaultProps = {
  initialData: {},
};


export default withNotice(SummaryReport);
