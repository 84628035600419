import React, {useEffect, useState} from 'react';
import {Button, Col, FormGroup, Label, Row} from 'reactstrap';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import moment from 'moment/moment';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import TimePeriodFilter from '../../../../../../shared/components/filter/TimePeriodFilter';
import { getCookie, setCookie } from '../../../../../../shared/helpers/WVUtilities';
import { loadCreatorsOptions } from '../../../../../../shared/helpers/OptionsLoader';
import hook from '../../../../../../shared/hooks';
import LoadingReport from "../../../../../../shared/components/LoadingReport";

const datePickerOptions = [
  { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'last_7_days', label: 'Last 7 days' },
  { value: 'last_30_days', label: 'Last 30 days' },
  { value: 'year_to_date', label: 'Year to Date' },
  // { value: '6_month', label: 'Last 6 Months' },
  {
    value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
  },
];

const colourStyles = {
  multiValue: styles => ({
    ...styles,
    backgroundColor: '#70bbfd',
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: '#a2e1fd',
      color: '#70bbfd',
    },
  }),
};

const UrlStatusOptions = [
  { value: 'Needs Review', label: 'Needs Review' },
  { value: 'Pending Publication', label: 'Pending Publication' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Inactive', label: 'Inactive' },
  { value: 'No Status', label: 'No Status' },
];

const Actions = ({ setCreators, setCreatorsInTimeRange }) => {
  const [startDate, setStartDate] = useState(moment().subtract(29, 'day').startOf('day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD'));
  const [selectedButton, setSelectedButton] = useState('last_30_days');
  const [loading, setLoading] = useState(false);
  const [loadingFilterOptions, setLoadingFilterOptions] = useState(false);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [dealOwnersOptions, setDealOwnersOptions] = useState([]);
  const [showFilters, setShowFilters] = useState(getCookie('cpaFraudDetectionFilters') === 'true');
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedDealOwners, setSelectedDealOwners] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [selectedUrlStatus, setSelectedUrlStatus] = useState([]);

  const { showError } = hook.useNoticeHook();

  useEffect(() => {
    setLoadingFilterOptions(true);
    axios.get('/api/portal/finance/get-advertisers').then((resp) => {
      setBrandsOptions(resp.data.advertisers.filter(b => b.displayInUi === 'Yes')
        .sort((a, b) => (a.companyName > b.companyName ? 1 : -1))
        .map(a => ({ value: a._id, label: a.companyName })));
    }).catch(() => {
      showError('There was an error when try load campaigns, Check out console to see more information.')
    });
    axios.get('/api/portal/finance/get-deal-owners').then((resp) => {
      const options = resp.data.dealOwners.map(a => ({ value: a.hsUserId, label: `${a.firstName || ''} ${a.lastName || ''}` }));

      setDealOwnersOptions([{ value: 'No Deal Owner', label: 'No Deal Owner' }, ...options]);
      setLoadingFilterOptions(false);
    }).catch(() => {
      setLoadingFilterOptions(false);
      showError('There was an error when try load deal owners, Check out console to see more information.');
    });
    // itemsForDownload = allItems;
  }, []);

  useEffect(() => {
    setCookie('youtubeCommentsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const resetFilters = () => {
    setSelectedBrands([]);
    setSelectedCreators([]);
    setSelectedDealOwners([]);
    setSelectedUrlStatus([]);
  };

  const runReport = () => {
    setLoading(true);
    setCreators([]);
    setCreatorsInTimeRange([]);
    console.log('Run Report');
    axios.post(
      '/api/portal/compliance-report/post-cpa-fraud-report-data',
      {
        startDate,
        endDate,
        brands: selectedBrands,
        creators: selectedCreators,
      },
    ).then((response) => {
      // console.log(response.data.creators);
      if (response.data.creators) {
        const urlStatusArr = selectedUrlStatus.map(status => status.value);
        const ownersArr = selectedDealOwners.map(d => d.value);
        const { creators } = response.data;

        const filteredCreators = [];
        creators.forEach((creator) => {
          let allowToShow = false;
          if (urlStatusArr.length) {
            allowToShow = !!(creator.mediaContent?.urlStatus && urlStatusArr.includes(creator.mediaContent.urlStatus));
            if (!allowToShow) allowToShow = !creator.mediaContent?.urlStatus && urlStatusArr.includes('No Status');
          }
          if (ownersArr.length) {
            if ((urlStatusArr.length && allowToShow) || !urlStatusArr.length) {
              allowToShow = !!(creator.dealOwner?.hsUserId && ownersArr.includes(creator.dealOwner.hsUserId));
              if (!allowToShow) allowToShow = !creator.dealOwner?.hsUserId && ownersArr.includes('No Deal Owner');
            }
          }
          if (urlStatusArr.length === 0 && ownersArr.length === 0) {
            allowToShow = true;
          }
          if (allowToShow) {
            filteredCreators.push(creator);
          }
        });
        const finalCreators = filteredCreators
          .filter(c => (c.inTimeRange === false) && c.conversionsInTimeframe.length > 0)
          .sort((a, b) => (a.rate?.ltr > b.rate?.ltr) ? 1 : -1);
        const finalInTimeRangeCreators = filteredCreators
          .filter(c => c.inTimeRange)
          .sort((a, b) => (a.rate?.ltr > b.rate?.ltr) ? 1 : -1);
        setCreators(finalCreators);
        setCreatorsInTimeRange(finalInTimeRangeCreators);
      }
      setLoading(false);
    });
  };

  return (
    <div className="mb-1">
      <Row className="mt-3">
        <Col>
          <TimePeriodFilter
            startDateInitial={startDate}
            endDateInitial={endDate}
            selectedButtonInitial={selectedButton}
            setValue={(param, value, range) => {
              if (param === 'startDate') {
                setStartDate(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
              }
              if (param === 'endDate') {
                setEndDate(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
              }
              setSelectedButton(range);
            }}
            title="Date of First Conversion"
            options={datePickerOptions}
            minDate={moment().subtract(6, 'months').toDate()}
            includeToday
          />
        </Col>
        <Col sm={1} style={{ paddingLeft: '24px' }} className="text-right">
          <Tooltip title="Toggle Filters">
            <IconButton
              id="filtersMenuButton"
              size="small"
              onClick={() => setShowFilters(!showFilters)}
              className="material-table__toolbar-button"
              style={{
                height: '42px',
                marginTop: '20px',
              }}
            >
              <TuneIcon size="30" />
            </IconButton>
          </Tooltip>
        </Col>
        <Col lg="auto">
          <div style={{ marginTop: '26px' }}>
            <Button
              color="primary"
              onClick={runReport}
              className="mr-2 btn-sm mt-xl-0 mt-lg-3 mb-0"
              disabled={!!loading}
            >
              Run Report
            </Button>
          </div>
        </Col>
      </Row>
      {showFilters && (
        <>
        <Row className="mt-3">
          <Col md={3}>
            <FormGroup>
              <Label className="bold-text">
                Brand(s)
              </Label>
              <Select
                isDisabled={loadingFilterOptions}
                value={selectedBrands}
                options={brandsOptions}
                onChange={setSelectedBrands}
                placeholder="Select Brand..."
                isMulti
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label className="bold-text">
                Deal Owner(s)
              </Label>
              <Select
                isDisabled={loadingFilterOptions}
                value={selectedDealOwners}
                options={dealOwnersOptions}
                onChange={setSelectedDealOwners}
                placeholder="Select Deal Owner..."
                isMulti
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label className="bold-text">
                Creator(s)
              </Label>
              <SelectAsync
                cacheOptions
                defaultOptions
                isClearable
                isMulti
                loadOptions={async value => loadCreatorsOptions(value)}
                onChange={setSelectedCreators}
                placeholder="Select Creator..."
                value={selectedCreators}
                styles={colourStyles}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label className="bold-text">
                URL Validation Status
              </Label>
              <Select
                isDisabled={loadingFilterOptions}
                value={selectedUrlStatus}
                options={UrlStatusOptions}
                onChange={setSelectedUrlStatus}
                placeholder="Select Url Status..."
                isMulti
              />
            </FormGroup>
          </Col>
          <Col md={1}>
            <Tooltip title="Reset Filters">
              <Button
                color="secondary"
                size="sm"
                onClick={resetFilters}
                // className="mr-2 btn-sm mt-xl-0 mt-lg-3 mb-0"
                style={{
                  marginTop: '30px',
                  width: '100%',
                  padding: '4px 8px',
                  height: '38px',
                }}
              >
                <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                <span className="btn-text">Reset</span>
              </Button>
            </Tooltip>
          </Col>
        </Row>
        </>
      )}
      <Row>
        <Col>
          <LoadingReport loading={loading} />
        </Col>
      </Row>
    </div>
  );
};

Actions.propTypes = {
  setCreators: PropTypes.func.isRequired,
};

export default Actions;
