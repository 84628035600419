import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';

const Tags = ({
  values,
  setValues,
  suggestions,
  placeholder,
}) => {
  const [tags, setTags] = useState(values ? suggestions.filter(row => values.includes(row.id)) : null);

  const onDelete = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setTags(tagsNew);
    setValues(tagsNew.map(row => row.id));
  };

  const onAddition = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setTags(tagsNew);
    setValues(tagsNew.map(row => row.id));
  };

  useEffect(() => setTags(suggestions.filter(row => values.includes(row.id))), [values]);

  return (
    <ReactTags
      ref={React.createRef()}
      tags={tags}
      suggestions={suggestions}
      onDelete={onDelete}
      onAddition={onAddition}
      delimiters={['Enter', ',']}
      placeholderText={placeholder}
    />
  );
};

Tags.propTypes = {
  values: PropTypes.arrayOf(PropTypes.any),
  setValues: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string.isRequired,
};

Tags.defaultProps = {
  values: [],
};

export default Tags;
