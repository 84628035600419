import React, { useState } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import { Row, Col, Button, Container, Card, CardBody, CardTitle, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import Filter from '../TimePeriodFilterExtended';
// import LastMonthFilter from '../LastMonthFilter';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import MatTableForRedux from '../../../../shared/tables/materialTable/components/MatTableForRedux';
// import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../App/store/with-notice';
// import CheckBoxField from '../../../../shared/components/CheckBox';
// import Collapse from '../../../../shared/components/Collapse';
// import HubspotDealLink from '../../../../shared/components/HubspotDealLink';
import GenerateTable from './GenerateTable';
import SaveReport from '../SaveReport';
import CheckBoxField from '../../../../shared/components/CheckBox';
import LoadingReport from "../../../../shared/components/LoadingReport";


const SummaryReport = ({ addNotice }) => {
  const [loading, setLoaing] = useState(false);
  const [cspOptions, setCspOptions] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [prospectsByBrand, setProspectsByBrand] = useState({});
  const [contractsSignedByBrand, setContractsSignedByBrand] = useState({});
  const [contractsSignedByOffer, setContractsSignedByOffer] = useState({});
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [ignoreWarning, setIgnoreWarning] = useState(false);
  const [saveReport, setSaveReport] = useState(false);
  const [saveReportModal, setSaveReportModal] = useState(false);
  const [savedReportModal, setSavedReportModal] = useState(false);
  const [cpmConversions, setCpmConversions] = useState([]);
  const [cpmDeals, setCpmDeals] = useState([]);

  const setContractsSigned = (agreements) => {
    const _contractsSignedByBrand = {};
    const _contractsSignedByOffer = {};
    agreements.forEach((agreement) => {
      const adminUserId = (agreement.deals && agreement.deals[0]) ? agreement.deals[0].hubspot_owner_id : null;
      if (agreement.advertisers && agreement.advertisers[0] && agreement.advertisers[0].tuneId) {
        if (typeof _contractsSignedByBrand[agreement.advertisers[0].tuneId] === 'undefined') {
          _contractsSignedByBrand[agreement.advertisers[0].tuneId] = [];
        }
        _contractsSignedByBrand[agreement.advertisers[0].tuneId].push({ adminUserId });
      }
      if (agreement.offers && agreement.offers[0] && agreement.offers[0].tuneId) {
        if (typeof _contractsSignedByOffer[agreement.offers[0].tuneId] === 'undefined') {
          _contractsSignedByOffer[agreement.offers[0].tuneId] = [];
        }
        _contractsSignedByOffer[agreement.offers[0].tuneId].push({ adminUserId });
      }
    });
    setContractsSignedByBrand(_contractsSignedByBrand);
    setContractsSignedByOffer(_contractsSignedByOffer);
  };
  const setProspects = (prospects) => {
    const _prospectsByBrand = {};
    // const _prospectsByBrandByAdmin = {};
    prospects.forEach((prospect) => {
      if (!_prospectsByBrand[prospect.advertiserId]) _prospectsByBrand[prospect.advertiserId] = [];
      _prospectsByBrand[prospect.advertiserId].push(prospect);

      // if (!_prospectsByBrandByAdmin[`${prospect.ownerUser}_${prospect.advertiserId}`]) _prospectsByBrandByAdmin[`${prospect.ownerUser}_${prospect.advertiserId}`] = [];
      // _prospectsByBrandByAdmin[`${prospect.ownerUser}_${prospect.advertiserId}`].push(prospect);
    });
    console.log(_prospectsByBrand);
    setProspectsByBrand(_prospectsByBrand);
  };
  const saveReportToSave = async (name, admins) => {
    setLoaing(true);
    // setError(null);
    try {
      const resp = await axios.post('/api/portal/reports/post-save-brand-performance-report', {
        startDate,
        endDate,
        identity,
        name,
        admins,
      });
      if (resp.data.success) {
        setLoaing(false);
        setSaveReportModal(false);
        setSavedReportModal(true);
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const fetchAmountReport = async () => {
    setLoaing(true);
    // setError(null);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-report-for-brand', {
        startDate,
        endDate,
        identity,
        ignoreWarning,
      });
      if (resp.data.success) {
        setContractsSigned(resp.data.signedAgreements);
        setProspects(resp.data.prospects);
        setCspOptions(resp.data.adminUserOptions);
        setReportData(resp.data.items);
        setCpmConversions(resp.data.cpmConversions);
        const offers = resp.data.offersArray;
        const deals = [];
        resp.data.cpmDeals.forEach((cpmDeal) => {
          const deal = { ...cpmDeal };
          deal.offer = offers.find(o => String(o._id) === String(deal.ilDealData.offer));
          deals.push(deal);
        });
        console.log(resp.data.cpmDeals, deals);
        setCpmDeals(deals);
        setLoaing(false);
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Brand Performance', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Brand Performance</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col>
                  <Filter
                    setValue={(param, value) => {
                      console.log(value);
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                  />
                </Col>
                <Col lg="auto">
                  <div className="mt-2">
                    <SaveReport
                      startDate={startDate}
                      endDate={endDate}
                      save={(name, admins) => saveReportToSave(name, admins)}
                      saveReportModal={saveReportModal}
                      savedReportModal={savedReportModal}
                      setSaveReportModal={setSaveReportModal}
                      setSavedReportModal={setSavedReportModal}
                      saveReport={saveReport}
                      setSaveReport={setSaveReport}
                      type="brandPerformance"
                    />
                  </div>
                </Col>
                <Col lg="auto">
                  <div>
                    <Button
                      color="primary"
                      onClick={async () => {
                        if (saveReport) {
                          setSaveReportModal(true);
                          return false;
                        }
                        await fetchAmountReport();
                        return false;
                      }}
                      className="my-0 btn-sm"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="theme-light radio-in-prospects">
                    <FormGroup className="mb-0 color-text" inline>
                      <CheckBoxField
                        name="ignorewarning"
                        label="Ignore deal warnings and errors when calculating Gross Revenue"
                        value={ignoreWarning}
                        onChange={({ target }) => target && (setIgnoreWarning(target.checked))}
                      />
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    topic="report"
                    identity={identity}
                    isShow={!!loading}
                  />
                </Col>
              </Row> */}
              <GenerateTable
                contractsSignedByBrand={contractsSignedByBrand}
                contractsSignedByOffer={contractsSignedByOffer}
                prospectsByBrand={prospectsByBrand}
                reportData={reportData}
                cspOptions={cspOptions}
                loading={loading}
                cpmConversions={cpmConversions}
                cpmDeals={cpmDeals}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
