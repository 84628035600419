/* eslint-disable filenames/match-exported */
import React, { PureComponent } from 'react';
import { Button, Row, Col, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import BillComMFARefreshComponent from '../../../Finance/CreatorPayment/components/BillComMFARefreshComponent';

class MFAStatus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isTrusted: false,
      mfaChallenged: false,
      loading: {
        status: false,
        mfaRequest: false,
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  // HANDLERS
  onMFAReauthenticationFinished = () => {
    this.setState({ mfaChallenged: false });
    this.fetchData();
  }

  challengeMFAReauthentication = () => {
    this.setState({ mfaChallenged: true });
    // axios.get('/api/portal/finance/get-send-mfa-slack-message').then((response) => {
    //   console.log(response.data);
    // });
  }

  // METHODS
  fetchData() {
    this.setState({ loading: { status: true } });

    axios.get('/api/portal/billCom/mfa-status').then((response) => {
      if (response.data.success) {
        const { isTrusted } = response.data;
        this.setState({
          isTrusted,
          loading: {
            status: false,
          },
        });
        return;
      }

      this.processError(response.error);
    }).catch((error) => {
      this.processError(error.message);
    });
  }
  //

  processError(message) {
    this.setState({ loading: { status: false } });
    this.props.addNotice({
      message: `Bill.com MFA status: ${message || 'something went wrong!'}`,
      type: 'error',
    });
  }

  render() {
    const { isTrusted, loading, mfaChallenged } = this.state;

    return (
      <div>
        <Row className="align-items-center">
          <Col xs="auto" className="pr-1">
            <h3 className="il-color-deep-blue">MFA Session Status:</h3>
          </Col>
          <Col xs="auto">
            {
              loading.status ?
                <Spinner color="primary" size="sm" />
              :
                <h3 className={isTrusted ? 'text-success' : ''}>{isTrusted ? 'Trusted' : 'Not Trusted'}</h3>
            }
          </Col>
          {
            (!loading.status) &&
            <Col xs="auto">
              <Button
                className="px-3 mb-0 btn-sm"
                type="button"
                color="primary"
                onClick={this.challengeMFAReauthentication}
              >
                Request MFA session
              </Button>
            </Col>
          }
        </Row>
        { /* MFA Challenge modals */
          mfaChallenged &&
          <BillComMFARefreshComponent
            addNotice={this.props.addNotice}
            onAuthenticationFinished={this.onMFAReauthenticationFinished}
          />
        }
      </div>
    );
  }
}

MFAStatus.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default MFAStatus;
