/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
/* eslint react/prop-types: 0 */
import React from 'react';
import { unflatten } from 'flat';
import _ from 'lodash';

export default React.memo((props) => {
  const record = unflatten(props.record);
  const { params: { fullName, hsContactVid } } = record;

  return (
    <>
      <a
        className="bold-text"
        role="button"
        href={`/agent/profile/${record.id}`}
        target="_blank"
        title="Open Agent Profile"
        rel="noreferrer noopener"
      >
        {fullName}
      </a>
      {hsContactVid && (
        <a
          className="ml-3"
          href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${hsContactVid}/`}
          target="_blank"
          title="Open Hubspot Contact"
          rel="noreferrer noopener"
        >
          <i className="fab fa-hubspot" style={{ fontSize: '1.5em' }} />
        </a>
      )}
    </>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));
