import React from 'react';
import SelectAsync from 'react-select/async';
import { components } from 'react-select';
import { axios } from 'ApiClient';
import { FormGroup } from 'reactstrap';

const formatOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div >{record.params.first_name} {record.params.last_name}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record.params.email}
    </div>
  </div>
);
const option = (props) => {
  const { record } = props.data;
  console.log(record);
  return (
    <components.Option {...props} >
      <div>
        <div className="bold-text">{record.params.first_name} {record.params.last_name}</div>
        <div >{record.params['affiliateDataObject.company']}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record.params.email}
        </div>
      </div>
    </components.Option>
  );
};

const loadCreatorsOptions = async (value) => {
  const response = await axios.get('/api/portal/influencer/get-creator-search', {
    params: {
      value,
    },
  });
  return response.data.records.map(record => ({
    value: record.params._id,
    label: record.params.email,
    record,
  }));
};

const SearchBlock = () => {
  const handleChangeInfluencer = async (selected) => {
    document.location = `/influencer/profile/${selected.value}`;
  };
  return (
    <div className="d-flex justify-content-center">
      <FormGroup style={{ minWidth: '350px', maxWidth: '350px', marginBottom: 0 }}>
        <SelectAsync
          cacheOptions
          loadOptions={async value => loadCreatorsOptions(value)}
          onChange={handleChangeInfluencer}
          placeholder="Search"
          formatOptionLabel={formatOptionLabel}
          components={{ Option: option }}
        />
      </FormGroup>
    </div>
  );
};
SearchBlock.propTypes = {

};
SearchBlock.defaultProps = {

};
export default SearchBlock;
