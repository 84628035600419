import React, { useState, useRef, useEffect, useMemo } from 'react';
// import { Link } from 'react-router-dom';
import { Col, Row, CardBody, Card, Spinner, Button, FormGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from 'mdi-react/SendIcon';
import Tooltip from '@material-ui/core/Tooltip';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import withNotice from '../../../../App/store/with-notice';
import SelectFilter from '../../../../../shared/components/SelectFilter';
import Cols from './ServiceAgreement/Cols';
import AdminTable from './AdminTable';
import UploadModal from './ServiceAgreement/UploadModal';
import Hook from '../../../../../shared/hooks';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';
import { getDealsFilterTypes } from '../../../../../shared/helpers/Filter';
import { remind } from '../../../../../shared/helpers/models/ServiceAgreement';
import ActionModal from '../../../../../shared/components/modals/Action';
import Resend from '../../ServiceAgreement/components/Resend';

const ServiceAgreement = ({
  agreements, deals, loading, influencer, location, reloadAgreements, dispatch, addNotice,
}) => {
  const scrollRef = useRef(null);
  useEffect(() => {
    // console.log(location.hash === '#serviceAgreement');
    // console.log(scrollRef.current);
    if (!loading && influencer && location.hash === '#serviceAgreement') {
      setTimeout(() => {
        console.log(scrollRef.current.offsetTop);
        // window.history.pushState('', document.title, window.location.pathname + window.location.search);
        scrollRef.current.scrollIntoView();
      }, 1000);
    }
  }, [influencer]);
  const [statusFilter, setStatusFilter] = useState({
    Pending: true,
    Complete: true,
    Deleted: true,
  });
  const [dealTypeFilter, setDealTypeFilter] = useState(getDealsFilterTypes());
  const [search, setSearch] = useState('');

  const data = useMemo(() => agreements.filter(a => !search || a.deals[0]?.dealname?.toLowerCase().includes(search.toLowerCase())), [search, agreements]);
  // console.log(data);
  /* const additionalActions = [
    {
      type: 'custom',
      func: (r) => {
        console.log(r);
        return (
          );
      },
    },
    {
      type: 'custom',
      func: (r) => {
        console.log(r);
        return ();
      },
    },
    {
      type: 'custom',
      func: () => (),
    },
  ];*/
  const [uploadModalIsOpen, openUploadModal] = useState(false);
  const [openAssignmentsModal] = Hook.useShowModalHook('SuccessModal');
  const [agreement, setAgreement] = useState(null);
  const [resendModalIsOpen, openResendModal] = useState(false);
  const [resending, setResending] = useState(false);
  const disableSendAgreement = influencer && !(influencer.params.first_name && (influencer.params.first_name.toLowerCase() !== 'null') && influencer.params.last_name && (influencer.params.last_name.toLowerCase() !== 'null'));
  return (
    <div ref={scrollRef}>
      <h2 className="section-title mt-0 mb-4">Service Agreements</h2>
      <Card id="section-service-agreements">
        <CardBody>
          <Row>
            <Col />
            <Col xs="auto">
              {influencer && deals && (
                <Button
                  color="primary"
                  id="upload-agreement-btn"
                  className="radius-button"
                  onClick={() => openUploadModal(true)}
                  disabled={influencer?.params?.inBlackList}
                >
                  Upload
                </Button>
              )}
              {influencer && deals && (
                <UploadModal
                  isOpen={!!uploadModalIsOpen}
                  toggle={() => openUploadModal(false)}
                  influencer={influencer}
                  deals={deals}
                  reloadAgreements={reloadAgreements}
                />
              )}
              {influencer && disableSendAgreement && (
                <Button
                  color="primary"
                  id="sent-agreement-btn"
                  className="radius-button"
                  disabled
                >
                  <Icon className="mt-0" />
                  Send Agreements
                </Button>
              )}
              {influencer && !disableSendAgreement && (
                <Tooltip title="Send Agreement">
                  <Link to={`/send-agreement/${influencer.id}`}>
                    <Button
                      color="primary"
                      className="radius-button"
                      disabled={influencer?.params?.inBlackList}
                    >
                      <Icon className="mt-0" />
                      Send Agreements
                    </Button>
                  </Link>
                </Tooltip>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {loading ? (<div className="text-center"><Spinner color="primary" size="lg" /></div>) : null}
              {!loading && agreements && agreements.length > 0 ? (
                <AdminTable>
                  <Row style={{ marginBottom: '-16px' }}>
                    <Col xs="auto">
                    <FormGroup className="mr-3">
                      <Input
                        placeholder="Search by Deal name"
                        type="search"
                        onChange={e => setSearch(e.target?.value || '')}
                        value={search}
                        style={{ minWidth: '300px' }}
                        bsSize="md"
                        className="mt-0 mt-0"
                      />
                    </FormGroup>
                    </Col>
                    <Col xs="auto">
                    <SelectFilter
                        key="122"
                        title="Status"
                        filterProp={statusFilter}
                        defaultValue={[
                          'Pending',
                          'Complete',
                          'Deleted',
                        ].map(a => ({ value: a, label: a }))}
                        filterAction={v => setStatusFilter(v)}
                      />
                    </Col>
                    <Col>
                      <SelectFilter
                        key="123"
                        title="Deal Type"
                        filterProp={dealTypeFilter}
                        defaultValue={Object.keys(getDealsFilterTypes()).map(a => ({ value: a, label: a }))}
                        filterAction={v => setDealTypeFilter(v)}
                      />
                    </Col>
                  </Row>
                  <MatTableForRedux
                    dataArray={data}
                    selectable={false}
                    columns={Cols({
                      dispatch,
                      setAgreement,
                      openAssignmentsModal,
                      openResendModal,
                    })}
                    version={(new Date()).toString()}
                    defaultOrder={{
                      order: 'asc',
                      orderBy: 'brand',
                    }}
                    rowsPerPage={10}
                    hideToolbar
                    // additionalActions={additionalActions}
                    defaultSearchColumn="deal_name"
                    filters={{
                      status: statusFilter,
                      deal_type: dealTypeFilter,

                    }}
                  />
                  <ActionModal
                    isOpen={resendModalIsOpen}
                    toggle={openResendModal}
                    label="Resend Notification (Dropbox)"
                    process={() => remind({ agreement, setLoading: setResending }, addNotice)}
                    confirmButtonCaption="Send"
                    loading={resending}
                  >
                    <Resend agreement={agreement} />
                  </ActionModal>
                </AdminTable>
              ) : !loading && (<Alert color="warning"><p className="py-2">The Creator currently has no signed Service Agreements.</p></Alert>)}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
ServiceAgreement.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.any).isRequired,
  deals: PropTypes.arrayOf(PropTypes.any).isRequired,
  influencer: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  reloadAgreements: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};
ServiceAgreement.defaultProps = {
  influencer: null,
};

export default withNotice(ServiceAgreement);
