import React, { useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { axios } from 'ApiClient';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CopyToClipboard from '../../../../../../shared/components/CopyToClipboardLeft';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import { CopyIcon } from '../../../../../../shared/helpers/Icons';
import { getServerDate, formatCurrency } from '../../../../../../shared/helpers/WVFormatter';
import TableCellContextMenu from '../../../../../../shared/components/table/TableCellContextMenu';
import Hook from "../../../../../../shared/hooks";


const getCreatorBrandApprovedProspect = (prospects, possibleProspects, offer) => {
  // console.log('RUN');
  if (!prospects) console.log('No Prospects');
  const allProspects = prospects.concat(possibleProspects);
  let creatorBrandApprovedProspect = null;
  let creatorBrandNotApprovedProspect = null;
  if (allProspects && allProspects.length > 0) {
    let filteredNotApproved = allProspects.filter(item => (item.approvalStatus?.brand?.targetAdvertiserId?.toString() === offer?.advertiser?.toString() && item.approvalStatus.brand.status !== 'Approved'));
    let filteredProspects = allProspects.filter(item => (item.approvalStatus?.brand?.targetAdvertiserId?.toString() === offer?.advertiser?.toString() && item.approvalStatus.brand.status === 'Approved'));
    if (filteredProspects.length > 0) {
      // eslint-disable-next-line max-len
      filteredProspects = filteredProspects.sort((a, b) => (a.approvalStatus.brand?.history[a.approvalStatus.brand.history.length - 1]?.dateModified > b.approvalStatus.brand.history[b.approvalStatus.brand.history.length - 1]?.dateModified ? 1 : -1));
      // eslint-disable-next-line prefer-destructuring
      creatorBrandApprovedProspect = filteredProspects[0];
    }
    if (filteredNotApproved.length > 0) {
      // console.log('filteredNotApproved', filteredNotApproved);
      // eslint-disable-next-line max-len
      filteredNotApproved = filteredNotApproved.sort((a, b) => (((a.approvalStatus.brand.history.length && a.approvalStatus.brand.history[a.approvalStatus.brand.history.length - 1]?.dateModified) || a.createdAt) > ((b.approvalStatus.brand.history.length && b.approvalStatus.brand.history[b.approvalStatus.brand.history.length - 1]?.dateModified) || b.createdAt) ? 1 : -1));
      // eslint-disable-next-line prefer-destructuring
      creatorBrandNotApprovedProspect = filteredNotApproved[0];
    }
  }
  return { creatorBrandApprovedProspect, creatorBrandNotApprovedProspect };
};

const getStatus = (d) => {
  if (d.approvalStatus?.brand?.status === 'Approved' && d.approvalStatus?.influencelogic?.status === 'Approved') {
    return 'Approved';
  } else if (d.approvalStatus?.brand?.status === 'Rejected' && d.approvalStatus?.influencelogic?.status === 'Resubmit') {
    return 'In Review';
  } else if (d.approvalStatus?.brand?.status === 'Rejected' || d.approvalStatus?.influencelogic?.status === 'Rejected') {
    return 'Rejected';
  }
  return 'In Review';
};

const getHistory = (d) => {
  const brandUpdateCount = d.approvalStatus?.brand?.history.length || 0;
  const brandDate = d.approvalStatus?.brand?.history[brandUpdateCount - 1]?.dateModified;
  // check if it has been approved
  if (d.approvalStatus?.brand?.status === 'Approved') {
    return brandDate || '';
  }

  const influenceLogicUpdateCount = d.approvalStatus?.influencelogic?.history.length || 0;
  const inReviewStatuses = ['Mining', 'In Review', 'Resubmit'];

  // new prospect == show the date the prospect record created
  if (inReviewStatuses.includes(d.approvalStatus?.influencelogic?.status)) {
    if (influenceLogicUpdateCount === 0) {
      return d.createdAt;
    }

    // locate last time it was submited for review
    let lastInReviewSubmitDate = null;
    for (let i = 0; i < influenceLogicUpdateCount; i += 1) {
      if (inReviewStatuses.includes(d.approvalStatus?.influencelogic?.history[i]?.selectedStatus)) {
        lastInReviewSubmitDate = d.approvalStatus?.influencelogic.history[i]?.dateModified;
      }
    }
    return lastInReviewSubmitDate;
  }

  // deal with rejected status
  const ilDate = d.approvalStatus?.influencelogic?.history[influenceLogicUpdateCount - 1]?.dateModified;
  const ilMomentDate = moment(ilDate);
  const brandMomentDate = moment(brandDate);

  // return what ever date is the most recent (influencelogic vs brand update history)
  if (brandUpdateCount > 0 && influenceLogicUpdateCount > 0) {
    if (ilMomentDate > brandMomentDate) {
      return ilDate;
    }
    return brandDate;
  } else if (brandUpdateCount === 0 && influenceLogicUpdateCount > 0) {
    return ilDate;
  } else if (brandUpdateCount > 0 && influenceLogicUpdateCount === 0) {
    return brandDate;
  }

  // could not be determined
  return '';
};

const getAgentsMessage = (agents) => {
  return agents.map((agent) => {
    return (
      <li key={agent._id}>
        <a href={`/agent/profile/${agent._id}`} target="_blank" rel="noreferrer">
          {`${agent.first_name || ''} ${agent.last_name || ''}`}
        </a>&nbsp;
        -&nbsp;
        <CopyToClipboard
          value={agent.email || ''}
          icon={(<span style={{color: '#70bbfd'}}><CopyIcon/></span>)}
        />
        <span>{agent.email || ''}</span>
      </li>);
  });
};

const getAPMessage = (allPayables) => {
  return allPayables.map((payable) => {
    return (
      <li key={payable.accountsPayable}>
        <a href={`/finance/account-payable-report-2/${payable.accountsPayable}/creators`} target="_blank" rel="noreferrer">
          {payable.ap.name || ''}
        </a>&nbsp;
        -&nbsp;
        <span>Payout Amount {formatCurrency(payable.ilPayoutAmount)}</span>
      </li>);
  });
};

const Columns = ({updateRecordFunction, addNotice }) => {
  // console.log('Columns');
  const dealstages = ((getConstant('deal', 'pipelines') || []).find(p => p.label.toLowerCase() === 'main pipeline'))?.stages;
  const [showModal] = Hook.useShowModalHook('SuccessModal');

  // console.log(dealstages);
  return [
    {
      Header: 'Deal Name',
      id: 'dealName',
      Cell: p => useMemo(() => (
        <div>
          <span>{p.row.original.dealname}</span>
          <a
            href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original?.hs_object_id}/`}
            target="_blank"
            rel="noreferrer"
            style={{ color: '#70bbfd', marginLeft: '6px' }}
            title="Open Hubspot Deal"
          >
            <i className="fab fa-hubspot" style={{ fontSize: '1em' }} />
          </a>
        </div>
      ), [p.row.original]),
      width: 250,
    },
    {
      Header: 'Creator',
      accessor: p => `${p?.influencerObject?.first_name || ''} ${p?.influencerObject?.last_name || ''}`,
      id: 'Creator',
      Cell: p => useMemo(() => (
        <div>
          <Tooltip title="Open Creator Profile">
            {/* eslint-disable-next-line max-len */}
            <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.ilDealData?.influencer}`}>{`${p.row.original.influencerObject?.first_name || ''} ${p.row.original.influencerObject?.last_name || ''}`}</a>
          </Tooltip>
          {p.row.original?.influencer?.hsContactVid ? (
            <a
              href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.row.original?.influencer?.hsContactVid}/`}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#70bbfd', marginLeft: '6px' }}
              title="Open Hubspot Contact"
            >
              <i className="fab fa-hubspot" style={{ fontSize: '1em' }} />
            </a>) : null}
          <br />
          {p.row.original.influencerObject?.email ? (
            <div>
              <CopyToClipboard
                value={p.row.original.influencerObject?.email || ''}
                icon={(<span style={{ color: '#70bbfd' }}><CopyIcon /></span>)}
              />
              <span>{p.row.original.influencerObject?.email || ''}</span>
            </div>
            // <CopyToClipboard value={p.row.original.influencerObject?.email}>{p.row.original.influencerObject?.email}</CopyToClipboard>
          ) : null}
        </div>
      ), [p.row.original]),
      width: 300,
    },
    {
      Header: 'Authorized?',
      id: 'Authorized',
      Cell: p => useMemo(() => (
        <FontAwesomeIcon
          icon={p.row.original.authorizedPayment?.summaryValidation ? 'thumbs-up' : 'thumbs-down'}
          className={p.row.original.authorizedPayment?.summaryValidation ? 'text-success' : 'text-danger'}
        />
      ), [p.row.original]),
      width: 75,
    },
    {
      Header: 'Brand',
      accessor: p => (p.dealTerms?.brand),
      id: 'brand',
      Cell: p => useMemo(() => {
        // console.log(p.row.original);
        return (
          <span>{p.row.original.dealTerms?.brand || ''}</span>
        );
      }, [p.row.original]),
      width: 200,
    },
    {
      Header: 'Deal Owner',
      id: 'dealOwner',
      Cell: p => useMemo(() => (
        <span>
          {p.row.original.adminUserObject && `${p.row.original.adminUserObject.firstName || ''} ${p.row.original.adminUserObject.lastName || ''}`}
        </span>
      ), [p.row.original]),
      width: 200,
    },
    {
      Header: 'Deal Type',
      accessor: p => (p.dealTerms.type_of_deal),
      id: 'dealType',
      Cell: p => useMemo(() => (
        <span>
          {p.row.original.dealTerms.type_of_deal || ''}
        </span>
      ), [p.row.original]),
      width: 200,
    },
    {
      Header: 'Deal Stage',
      id: 'dealStage',
      accessor: deal => (deal.dealstage ? dealstages[Number(deal.dealstage)] : ''),
      Cell: p => useMemo(() => {
        const dealStage = p.row.original.dealstage ? dealstages[Number(p.row.original.dealstage)] : '-';
        return dealStage || '-';
      }, [p.row.original]),
      width: 200,
    },
    {
      Header: 'Content Release Date',
      id: 'content_release_date',
      accessor: deal => deal.dealTerms.content_release_date,
      Cell: p => {
        // console.log(p.value, 'CRD')
        const m = moment.utc(p.value).format('MM/DD/YYYY')
        return p.value ? m : '-'
      },
      width: 200,
    },    
    {
      Header: 'Agency',
      id: 'agency',
      accessor: deal => deal.dealTerms.agency_affiliation || '',
      Cell: p => useMemo(() => (
        <div>
          {p.row.original.dealTerms?.agency_affiliation && (
            <div>
              {p.row.original.dealTerms.agency_affiliation}
            </div>
          )}
          {p.row.original.agentUsers.length > 0 && (
            <div>
              <CopyToClipboard
                value={p.row.original.agentUsers[0].email || ''}
                icon={(<span style={{ color: '#70bbfd' }}><CopyIcon /></span>)}
              />
              <span>{p.row.original.agentUsers[0].email || ''}</span>
              {p.row.original.agentUsers.length > 1 && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                    className="text-blue bold-text"
                  // href="#"
                  role="button"
                  onClick={() => showModal({ title: 'Deal Agents', message: (<ul>{getAgentsMessage(p.row.original.agentUsers)}</ul>), width: '500px' }) }
                > ({p.row.original.agentUsers.length})</a>
              )}
            </div>
          )}
        </div>
      ), [p.row.original]),
      width: 240,
    },
    {
      Header: 'Total Amount',
      id: 'amount',
      accessor: (deal) => {
        if (deal.bill) return deal.bill.amount;
        if (deal.payable) {
          // console.log(deal, 'DEAL');
          const sum = deal.allPayables.reduce((total, current) => { total+= Number(current.ilPayoutAmount); return total; }, 0)
          // deal.payable.ilPayoutAmount;
          return sum;
        }
        return '';
      },
      Cell: p => useMemo(() => (
        <div>
          {p.row.original.bill && (
            <div>
              <div>{formatCurrency(p.row.original.bill.amount)}</div>
              <div><strong>IL Invoice: {p.row.original.bill.invoiceNumber}</strong></div>
            </div>
          )}
          {p.row.original.payable && (
            <div>
              <div>{formatCurrency(p.row.original.allPayables.reduce((total, current) => { total+= Number(current.ilPayoutAmount); return total; }, 0))}</div>
              <div>
                <strong>AP Report: {(p.row.original.payable?.ap?.name || '')}</strong>
                {p.row.original.allPayables.length > 1 && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    className="text-blue bold-text"
                    // href="#"
                    role="button"
                    onClick={() => showModal({ title: 'AP Reports', message: (<ul>{getAPMessage(p.row.original.allPayables)}</ul>), width: '500px' }) }
                  > ({p.row.original.allPayables.length})</a>
                )}
              </div>
            </div>
          )}
        </div>
      ), [p.row.original]),
      width: 200,
    },
    {
      Header: 'Amount Due',
      id: 'amountDue',
      accessor: (deal) => {
        if (deal.bill) return deal.bill.amountDue;
        if (deal.payable) return deal.payable.ilPayoutAmount;
        return '';
      },
      Cell: p => useMemo(() => (
        <div>
          {p.row.original.bill && (
            <div>
              <div>{formatCurrency(p.row.original.bill.amountDue)}</div>
            </div>
          )}
          {p.row.original.payable && (
            <div>
              <div>{formatCurrency(p.row.original.payable.ilPayoutAmount)}</div>
            </div>
          )}
        </div>
      ), [p.row.original]),
      width: 200,
    },


    {
      Header: 'Payee',
      id: 'payee',
      Cell: p => useMemo(() => (
        <div>
          {p.row.original.payment?.data ? (
            <div>
              <div className="">
                <span>{p.row.original.payment.data.name || ''}</span>
                {p.row.original.payment.data.stripe ? (
                  <div className="ml-2 mt-1 text-success d-inline">
                    <FontAwesomeIcon icon={['fab', 'cc-stripe']} style={{ fontSize: '1.1em' }} />
                  </div>) : (
                    <div className="ml-2 mt-1 d-inline">
                      <img src="/images/payments/billComGreen.png" style={{ width: '20px' }} className="img-fluid" alt="Bill.com" />
                    </div>
                ) }
              </div>
              <div>
                <CopyToClipboard
                  value={p.row.original.payment.data?.email || ''}
                  icon={(<span style={{ color: '#70bbfd' }}><CopyIcon /></span>)}
                />
                <span>{p.row.original.payment.data?.email || ''}</span>
              </div>
            </div>
          ) : (
            <div className="text-danger">Missing</div>
          )}
        </div>
      ), [p.row.original]),
      width: 200,
    },
    {
      Header: 'Tax Document',
      id: 'tax',
      Cell: p => useMemo(() => (
        <div>
          {p.row.original.payment?.taxValid ? (
            <div className="text-success">
              <span>On File </span>
              {/* eslint-disable-next-line no-nested-ternary,max-len */}
              <span>{p.row.original.payment.data?.stripe ? '(Stripe)' : (p.row.original.payment.validHistory.length ? getServerDate((p.row.original.payment.validHistory[p.row.original.payment.validHistory?.length - 1])?.dateModified) : '-')}</span>
            </div>
            ) : (
              <div className="text-danger">
                <a className="text-danger" target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.ilDealData?.influencer}`}>Missing</a>
              </div>
          )}
        </div>
      ), [p.row.original]),
      // {
      //   const deal = p.row.original;
      //   const { influencer } = deal;
      //   let creatorPayment = // check Influencer payments
      //     (
      //       influencer?.payment?.stripe?.accountId
      //       && influencer?.payment?.stripe?.isDefault) ? { ...influencer?.payment?.stripe, stripe: true } : null;
      //
      //   creatorPayment = (
      //     influencer?.payment?.billCom?.accountId
      //       && influencer?.payment?.billCom?.isDefault) ? { ...influencer?.payment?.billCom, billCom: true } : creatorPayment;
      //
      //   if (deal.ilDealData.agentUsers?.length > 0) console.log(deal.agentUsers, 'AGENTS');
      //   let agentPayment = null;
      //   deal.agentUsers.forEach((agent) => {
      //     if (
      //       (
      //         agent?.payment?.stripe?.accountId
      //         && agent?.payment?.stripe?.isDefault)
      //       ||
      //       (
      //         agent?.payment?.billCom?.accountId
      //         && agent?.payment?.billCom?.isDefault)
      //     ) {
      //       agentPayment = agent?.payment?.stripe?.isDefault ? { ...agent?.payment?.stripe, stripe: true, agent } : { ...agent?.payment?.billCom, billCom: true, agent };
      //     }
      //   });
      //   const payment = influencer?.payment.enablePayments ? creatorPayment : agentPayment;
      //   // console.log(payment);
      //   const taxValid = influencer?.payment.enablePayments ?
      //     Boolean(influencer?.validTaxForm?.value || creatorPayment?.stripe) : Boolean(agentPayment?.agent?.validTaxForm?.value || agentPayment?.stripe);
      //   const validHistory = influencer?.payment.enablePayments ? (p.row.original.influencer?.validTaxForm?.history || []) : (agentPayment?.agent?.validTaxForm?.history || []);
      //   return (
      //     taxValid ? (
      //       <div className="text-success">
      //         <span>On File </span>
      //         {/* eslint-disable-next-line no-nested-ternary */}
      //         <span>{payment?.stripe ? '(Stripe)' : (validHistory.length ? getServerDate((validHistory[validHistory?.length - 1]).dateModified) : '-')}</span>
      //       </div>
      //     ) : (
      //       <div className="text-danger">
      //         <a className="text-danger" target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.ilDealData?.influencer}`}>Missing</a>
      //       </div>
      //     )
      //   );
      // }, [p.row.original]),
      width: 100,
    },
    {
      Header: 'Creator Brand Approved',
      id: 'creatorBrandApproved',
      accessor: (deal) => {
        const prospectStatus = getCreatorBrandApprovedProspect(deal.prospects, deal.possibleProspects, deal.offer);
        // console.log('creatorBrandApprovedProspect', creatorBrandApprovedProspect);
        return (deal.authorizedPayment?.creatorBrandApproved && prospectStatus.creatorBrandApprovedProspect ?
          prospectStatus.creatorBrandApprovedProspect.approvalStatus.brand.history[prospectStatus.creatorBrandApprovedProspect.approvalStatus.brand.history.length - 1]?.dateModified : '');
      },
      Cell: p => useMemo(() => {
        // console.log(p.row.original.prospects);
        const { prospects, possibleProspects, offer } = p.row.original;
        const prospectStatus = getCreatorBrandApprovedProspect(prospects, possibleProspects, offer);
        return (
          p.row.original.authorizedPayment?.creatorBrandApproved && prospectStatus.creatorBrandApprovedProspect ? (
            <div className="text-success text-center">
              <span>{getServerDate(prospectStatus.creatorBrandApprovedProspect.approvalStatus.brand.history[prospectStatus.creatorBrandApprovedProspect.approvalStatus.brand.history.length - 1]?.dateModified)}</span>
            </div>
          ) : (
            <div className="text-danger text-center">
              {prospectStatus.creatorBrandNotApprovedProspect ?
                (
                  <div>
                    <span>No </span>
                    <br />
                    <span>{`(${getStatus(prospectStatus.creatorBrandNotApprovedProspect)} - ${getServerDate(getHistory(prospectStatus.creatorBrandNotApprovedProspect))})`}</span>
                  </div>
                ) :
                  <span>Missing</span>
              }
            </div>
          )
        );
      }, [p.row.original]),
      width: 100,
    },
    {
      Header: 'Contract Signed',
      id: 'contractSigned',
      accessor: deal => (deal.authorizedPayment?.contractSigned && deal.serviceagreement ? deal.serviceagreement.creatorDateSigned : ''),
      Cell: p => useMemo(() => (
        p.row.original.authorizedPayment?.contractSigned && p.row.original.serviceagreement ? (
          <div className="text-success">
            <span>{getServerDate(p.row.original.serviceagreement.creatorDateSigned)}</span>
          </div>
        ) : (
          <div className="text-danger">
            <a className="text-danger" target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.ilDealData?.influencer}`}>No</a>
          </div>
        )
      ), [p.row.original]),
      width: 100,
    },
    {
      Header: 'Content Brand Approved',
      id: 'contentBrandApproved',
      accessor: deal => (deal.authorizedPayment?.contentBrandApproved ? deal.brandApprovedDate : ''),
      Cell: p => useMemo(() => {
          let result = '-';
          if (p.row.original.dealTerms.type_of_deal !== 'Bonus') {
            result = p.row.original.authorizedPayment?.contentBrandApproved ? (
              <div className="text-success">
                <span>{getServerDate(p.row.original.brandApprovedDate)}</span>
              </div>
            ) : (
              <div className="text-danger">
                {/* eslint-disable-next-line max-len */}
                <a className="text-danger" target="_blank" rel="noopener noreferrer" href={`/compliance/content-management?filters.deal=${p.row.original._id}`}>No</a>
              </div>
            );
            if (p.row.original?.authorizedPayment?.contentBrandInactive) {
              result = (<div className="text-success">
                <span>Content Inactive</span>
              </div>);
            }
          }
        return result;
      }, [p.row.original]),
      width: 100,
    },
    {
      Header: 'Content Url Validated',
      id: 'contentUrlValidated',
      accessor: deal => {
        let mediacontent = deal.mediacontent.find(m => (m?.brandStatus === 'Approved' && m.urlStatus === 'Approved'));
        if (!mediacontent) mediacontent = deal.mediacontent.find(m => (m?.brandStatus === 'Approved'));
        if (!mediacontent && deal.mediacontent) mediacontent = deal.mediacontent[deal.mediacontent.length - 1];
        return mediacontent?.urlStatusLastModified

      },
      // (deal.mediacontent && deal.mediacontent.length && deal.authorizedPayment?.contentUrlValid ? deal.mediacontent[0].urlStatusLastModified : '')
      Cell: p => useMemo(() => {
        let result = '-';
        let mediacontent = p.row.original.mediacontent.find(m => (m?.brandStatus === 'Approved' && m.urlStatus === 'Approved'));
        if (!mediacontent) mediacontent = p.row.original.mediacontent.find(m => (m?.brandStatus === 'Approved'));
        if (!mediacontent && p.row.original.mediacontent) mediacontent = p.row.original.mediacontent[p.row.original.mediacontent.length - 1];

        if (p.row.original.dealTerms.type_of_deal !== 'Bonus') {
          result = p.row.original.authorizedPayment?.contentUrlValid && p.row.original.mediacontent && mediacontent?.urlStatusLastModified ? (
            <div className="text-success">
              <span>{getServerDate(mediacontent?.urlStatusLastModified)}</span>
            </div>
          ) : (
            // eslint-disable-next-line max-len
            <a className="text-danger" target="_blank" rel="noopener noreferrer" href={`/scorecard/content-url-validation?page=1&filters.deal=${p.row.original._id}`}>No</a>
          )
          if (p.row.original?.authorizedPayment?.contentBrandInactive) {
            result = (<div className="text-success">
              <span>Content Inactive</span>
            </div>);
          }
        }
        return result;
      }, [p.row.original]),
      width: 100,
    },
    {
      Header: 'Publish Date',
      accessor: deal => (deal.authorizedPayment?.contentPublicAndPublished && deal.dealTerms?.published_date ? deal.dealTerms.published_date : ''),
      id: 'publishDate',
      Cell: p => useMemo(() => {
        let result = '-';
        if (p.row.original.dealTerms.type_of_deal !== 'Bonus') {
          if(p.row.original.authorizedPayment?.contentPublicAndPublished !== null) {
            result = p.row.original.authorizedPayment?.contentPublicAndPublished && p.row.original.dealTerms.published_date ? (
              <div className="text-success">
                <span>{getServerDate(p.row.original.dealTerms.published_date)}</span>
              </div>
            ) : (
              <div className="text-danger">Not Published</div>
            )
          }
          if(p.row.original.authorizedPayment?.contentPublicAndPublishedBaD !== null) {
            result = p.row.original.authorizedPayment?.contentPublicAndPublishedBaD && p.row.original.brandApprovedDate ? (
              <div className="text-success">
                <span>{getServerDate(p.row.original.brandApprovedDate)} (B/A/D)</span>
              </div>
            ) : (
              <div className="text-danger">Not Published (B/A/D)</div>
            )
          }
        }
        if (p.row.original?.authorizedPayment?.contentBrandInactive) {
          result = (<div className="text-success">
            <span>Content Inactive</span>
          </div>);
        }
        return result;
      }, [p.row.original]),
      width: 100,
    },
    {
      Header: 'CPM Price Approved?',
      id: 'isCpmPA',
      Cell: p => useMemo(() => (
        // eslint-disable-next-line no-nested-ternary
        p.row.original.authorizedPayment?.cpmPriceApproved && p.row.original.cpmprospect?.approvalStatus?.history ? (
          <div className="text-success">
            <span>{getServerDate(p.row.original.cpmprospect?.approvalStatus?.history[p.row.original.cpmprospect.approvalStatus.history.length - 1]?.dateModified)}</span>
          </div>
        ) : (Number(p.row.original.offer?.advertiserTuneid || 0) === 2 && p.row.original.dealTerms.type_of_deal === 'Upfront CPM' ? (
          <div className="text-danger">No</div>
        ) : '-')
      ), [p.row.original]),
      width: 100,
    },
    {
      Header: 'CPM Insertion Order No.',
      id: 'ioNumber',
      Cell: p => useMemo(() => (
        // eslint-disable-next-line no-nested-ternary
        p.row.original.authorizedPayment?.cpmIoSigned && p.row.original.cpminsertionorder?.name ? (
          <div className="text-success">
            {p.row.original.cpminsertionorder.name}
          </div>
        ) : (Number(p.row.original.offer?.advertiserTuneid || 0) === 2 && p.row.original.dealTerms.type_of_deal === 'Upfront CPM' ? (
          <div className="text-danger">No</div>
        ) : '-')
      ), [p.row.original]),
      width: 100,
    },
    {
      Header: '',
      accessor: '_id',
      // eslint-disable-next-line react/prop-types
      Cell: p => (
        <div style={{ textAlign: 'right' }}>
          <TableCellContextMenu
            options={[
              {
                label: 'Refresh Payment Authorization',
                handler: async () => {
                  try {
                    await axios.post('/api/portal/finance/post-deal-validation-prefill', { ids: [p.row.original._id] });
                    const data = await axios.get(`/api/portal/compliance/get-payment-authorization-checklist?selectedId=${p.row.original._id}`);
                    updateRecordFunction(data.data.records[0]);
                    addNotice({
                      message: 'Authorization has been updated',
                      type: 'success',
                    });
                  } catch (e) {
                    console.log(e);
                  }
                },
              },
            ]}
          />
        </div>),
      disableGlobalFilter: true,
      disableSortBy: true,
      minWidth: 50,
      maxWidth: 50,
    },

  ];
};

export default Columns;

