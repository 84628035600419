import React from 'react';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import CopyToClipboard from '../../../../../../../shared/components/CopyToClipboard';

export default function ResearchPodcastColumnsInSearchTable(currentWatchlistId, addSearchItemToTheList, showErrorNotice) {
  return [
    {
      Header: '',
      accessor: () => null,
      id: 'title',
      className: '',
      Cell: podcast => (
        <>
          <div className="d-flex">
            {podcast.row.original.thumbnail && (
              <img
                className="my-auto"
                style={{
                  height: '95px',
                  width: 'auto',
                  marginRight: '10px',
                }}
                src={podcast.row.original.thumbnail}
                alt=""
              />
            )}
            <div className="my-auto">
              {typeof podcast.row.original.podcast !== 'undefined' && podcast.row.original.podcast !== null
                ? (
                  <>
                    <b>
                      <a
                        className="research-video-title"
                        href={`https://podcasts.apple.com/us/podcast/id${podcast.row.original.itunes_id}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{
                          display: 'inline-block',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '350px',
                        }}
                      >
                        {podcast.row.original.podcast.title_original}
                      </a>
                    </b>
                    <span
                      style={{
                        fontSize: '13px',
                        color: '#aaa',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '350px',
                      }}
                    >
                      {typeof podcast.row.original.podcast.publisher_original !== 'undefined' && podcast.row.original.podcast.publisher_original !== null ? `${podcast.row.original.podcast.publisher_original}` : ''}
                    </span>
                    <span
                      style={{
                        fontSize: '13px',
                        color: '#aaa',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '350px',
                      }}
                    >
                      {typeof podcast.row.original.title_original !== 'undefined' && podcast.row.original.title_original !== null ? `${podcast.row.original.title_original}` : ''}
                    </span>
                    <br />
                    <b>
                      <a
                        href={podcast.row.original.audio}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Audio
                      </a>
                    </b>
                  </>
                )
                : (
                  <>
                    <b>
                      <a
                        className="research-video-title"
                        href={podcast.row.original.website}
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{
                          display: 'inline-block',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '350px',
                        }}
                      >
                        {podcast.row.original.title_original}
                      </a>
                    </b>
                    <span
                      style={{
                        fontSize: '13px',
                        color: '#aaa',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '350px',
                      }}
                    >
                      {typeof podcast.row.original.publisher_original !== 'undefined' && podcast.row.original.publisher_original !== null ? `${podcast.row.original.publisher_original}` : ''}
                    </span>
                    <span
                      className="bold-text"
                      style={{
                        fontSize: '13px',
                        color: '#aaa',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '350px',
                      }}
                    >
                      {typeof podcast.row.original.total_episodes !== 'undefined' && podcast.row.original.total_episodes !== null ? `${podcast.row.original.total_episodes} Episodes` : ''}
                    </span>
                    <div
                      style={{
                        width: '350px',
                      }}
                    >
                      {typeof podcast.row.original.email !== 'undefined' && podcast.row.original.email !== null && (
                        <span
                          style={{
                            fontSize: '13px',
                            color: '#aaa',
                            display: 'inline-block',
                          }}
                        >
                          <CopyToClipboard value={podcast.row.original.email || ''}>
                            {podcast.row.original.email}
                          </CopyToClipboard>
                        </span>
                      )}
                      <span style={{ display: 'inline-block' }}>
                        {podcast.row.original.hsUserId && (
                          <a
                            title="Show HubSpot Contact"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ml-3"
                            style={{ fontSize: '18px' }}
                            href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${podcast.row.original.hsUserId}`}
                          >
                            <i className="fab fa-hubspot" />
                          </a>
                        )}
                        {podcast.row.original.influencer && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a
                              title="Creator Profile"
                              target="_blank"
                              rel="noreferrer"
                              href={`/influencer/profile/${podcast.row.original.influencer}`}
                              style={{ fontSize: '18px' }}
                            >
                              <i className="fa fa-info-circle" />
                            </a>
                          </>
                        )}
                      </span>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </>),
      disableSortBy: true,
      minWidth: 150,
      maxWidth: 350,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'type',
      className: '',
      Cell: (podcast) => {
        const imgPath = typeof podcast.row.original.podcast !== 'undefined' && podcast.row.original.podcast !== null ? '/images/mediacontent/episode_128.png' : '/images/mediacontent/podcast_128.png';
        const title = typeof podcast.row.original.podcast !== 'undefined' && podcast.row.original.podcast !== null ? 'Episode' : 'Podcast';
        return (
          <div style={{ textAlign: 'center' }}>
            <img
              style={{ maxWidth: '30px' }}
              src={imgPath}
              title={title}
              alt={title}
            />
          </div>);
      },
      disableSortBy: true,
      minWidth: 45,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'listen_score',
      className: '',
      Cell: podcast => (
        <div style={{ textAlign: 'center', width: '150px' }}>
          <b>Listen Score</b><br />
          {/* eslint-disable-next-line no-nested-ternary */}
          {typeof podcast.row.original.podcast !== 'undefined' && podcast.row.original.podcast !== null
            ? typeof podcast.row.original.podcast.listen_score !== 'undefined' && podcast.row.original.podcast.listen_score !== null
              ? podcast.row.original.podcast.listen_score : '-'
            : typeof podcast.row.original.listen_score !== 'undefined' && podcast.row.original.listen_score !== null
              ? podcast.row.original.listen_score : '-'
          }
        </div>),
      disableSortBy: true,
      minWidth: 45,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'listen_score_global_rank',
      className: '',
      Cell: podcast => (
        <div style={{ textAlign: 'center', width: '150px' }}>
          <b>Global Rank</b>
          <br />
          {/* eslint-disable-next-line no-nested-ternary */}
          {typeof podcast.row.original.podcast !== 'undefined' && podcast.row.original.podcast !== null
            ? typeof podcast.row.original.podcast.listen_score_global_rank !== 'undefined' && podcast.row.original.podcast.listen_score_global_rank !== null
              ? podcast.row.original.podcast.listen_score_global_rank : '-'
            : typeof podcast.row.original.listen_score_global_rank !== 'undefined' && podcast.row.original.listen_score_global_rank !== null
              ? podcast.row.original.listen_score_global_rank : '-'
          }
        </div>),
      disableSortBy: true,
      minWidth: 45,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'published',
      className: '',
      Cell: podcast => (
        <div style={{ textAlign: 'center', width: '150px' }}>
          <b>Published Date</b>
          <br />
          {WVFormatter.getFormatedDate(podcast.row.original.latest_pub_date_ms ? podcast.row.original.latest_pub_date_ms : podcast.row.original.pub_date_ms)}
        </div>),
      disableSortBy: true,
      minWidth: 45,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'itunes_id',
      className: '',
      Cell: podcast => (
        <div style={{ textAlign: 'center', width: '150px' }}>
          <b>iTunes ID</b>
          <br />
          <CopyToClipboard value={podcast.row.original.itunes_id ? podcast.row.original.itunes_id.toString() : ''}>
            <a
              href={`https://podcasts.apple.com/us/podcast/id${podcast.row.original.itunes_id}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {podcast.row.original.itunes_id}
            </a>
          </CopyToClipboard>
        </div>),
      disableSortBy: true,
      minWidth: 45,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'actions',
      className: '',
      Cell: podcast => (
        <div style={{ textAlign: 'center' }}>
          {podcast.row.original.loading === true && (
            <>
              <br />
              <span className="pr-2">
                <div className="spinner-border spinner-border-sm" />
              </span>
            </>
          )}
          {podcast.row.original.loading === 'saved' && (
            <>
              <br />
              <span>
                Saved
              </span>
            </>
          )}
          {(typeof podcast.row.original.loading === 'undefined'
            || (podcast.row.original.loading !== true && podcast.row.original.loading !== 'saved')
          ) && (
            <>
              <br />
              <button
                className="btn btn-primary"
                style={{ padding: '5px 5px 5px 10px' }}
                title="Add to List"
                onClick={() => {
                  if (podcast.row.original.id) {
                    addSearchItemToTheList([`podcast_${podcast.row.original.id}`], currentWatchlistId);
                  } else {
                    showErrorNotice('Creator ID information is missing and cannot be added to a watchlist or Prospects.');
                  }
                }}
              >
                <i className="fas fa-plus-circle" />
              </button>
            </>
          )}
        </div>),
      disableSortBy: true,
    },
  ];
}
