import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';

const TypeFilter = ({ filterContentType, handleChangeContentTypeFilter }) => (
  <>
    <Label className="bold-text" style={{ marginBottom: '8px' }}>
      Type
    </Label>
    <div>
      <CheckBoxField
        name="youtube"
        label="YouTube"
        className="inline"
        value={filterContentType.youtube}
        onChange={event => handleChangeContentTypeFilter(event, 'youtube')}
      />
      <CheckBoxField
        name="youtubeDescription"
        label="YouTube Description Text"
        className="inline ml-4"
        value={filterContentType.youtubeDescription}
        onChange={event => handleChangeContentTypeFilter(event, 'youtubeDescription')}
      />
    </div>
  </>
);

TypeFilter.propTypes = {
  filterContentType: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeContentTypeFilter: PropTypes.func.isRequired,
};

export default TypeFilter;
