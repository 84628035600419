import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import Select from 'react-select';
import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';

import { Row, Col, Button, Container, Card, CardBody, CardTitle, FormGroup } from 'reactstrap';

import Filter from '../FilterNew';
import Columns from '../ColumnsCreators';
import Errors from '../../ErrorsV2';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
// import ProgressBar from '../../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../../App/store/with-notice';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import { clear } from '../../../../../shared/helpers/LocalStorageCache';
import DownloadCSVCreatorDeal from '../DownloadCSVCreatorDeal';
import GenerationInfo from '../../GenerationInfoV2';
import ChartComponent from '../ChartComponent';
import Total from '../Total';
import FilterForTable from './Filter';
import Modal from '../CreatorModal';
import CheckBoxField from '@/shared/components/CheckBox';
// { _id : ObjectId('5e74e985282d5e00299bcd11') }
import {
  convertDealsForCreators,
  groupByDealResp,
  groupByCreator,
  groupByDate,
} from '../../ReportGroupBy';
import LoadingReport from "../../../../../shared/components/LoadingReport";

const SummaryReport = ({ addNotice }) => {
  const [loading, setLoaing] = useState(false);
  const [loadingAdvertiser, setLoaingAdvertiser] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [items, setItems] = useState([]);
  const [dealsCsv, setDealsForCsv] = useState([]);
  const [dealsArray, setDeals] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState((moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState({ startDate, endDate });
  const [errors, setErrors] = useState([]);
  const [identity] = useState(Math.random().toString(36).substring(2));
  //   const [pToCategories, setPToCategories] = useState([]);
  // const [mc, setMc] = useState({});
  const [fetched, setFetched] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [stats, setStats] = useState({
    grossRevenue: 0, grossProfit: 0, clicks: 0, conversions: 0, count: 0,
  });

  const [searchValue, onSearchChange] = useState();
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [selectedDealType, setSelectedDealType] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showClicks, setShowClicks] = useState(false);


  const processDeals = (dealsToFetch) => {
    const rightDeals = [];
    const selectedEmployeeArray = selectedEmployee?.map(a => a.value) || null;
    const csps = {};
    let grossRevenue = 0;
    let grossProfit = 0;
    dealsToFetch.forEach((deal) => {
      if (!deal?.influencer?.affiliateDataObject?.tune_id) return false;
      const name = `${deal?.influencer?.first_name} ${deal?.influencer?.last_name} ${deal?.influencer?.email}`;
      if (searchValue && !name.toLowerCase().includes(searchValue.toLowerCase())) return false;
      const ids = selectedDealType?.map(a => a.value);
      if (selectedDealType?.length > 0 && !ids.includes(deal?.deal?.dealTerms?.type_of_deal)) {
        return false;
      }

      const idsC = selectedCategories?.map(a => a.value.toLowerCase());
      if (selectedCategories?.length > 0
        && !idsC.includes(String(deal?.influencer?.category || 'null').toLowerCase())
      ) {
        return false;
      }

      const idsA = selectedAdvertisers?.map(a => a.value);
      if (selectedAdvertisers?.length > 0 && !idsA.includes(deal?.offer?.advertiser._id)) {
        return false;
      }
      const idsO = selectedOffer?.map(a => a.value);
      if (selectedOffer?.length > 0 && !idsO.includes(deal?.offer?._id)) {
        return false;
      }
      if (selectedEmployeeArray &&
        selectedEmployeeArray.length > 0 &&
        !selectedEmployeeArray.includes(deal?.admin?._id)
      ) return false;
      rightDeals.push(deal);
      if (deal?.deal?.hubspot_owner_id) {
        if (!csps[deal.deal.hubspot_owner_id]) csps[deal.deal.hubspot_owner_id] = { admin: deal.admin, items: [] };
        csps[deal.deal.hubspot_owner_id].items.push(deal);
      }
      grossRevenue += deal?.totalRevenue || 0;
      grossProfit += deal?.grossProfit || 0;
      return true;
    });
    const itemsWithDeal = Object.values(csps).map(({ admin, items: itemsSelected }) => {
      const datesFilters = groupByDate(itemsSelected);
      return { option: { _id: admin?._id || 'na', label: admin ? `${admin.firstName} ${admin.lastName}` : 'N/A' }, data: datesFilters };
    });
    let totalConversion = 0;
    Object.values(rightDeals).forEach((deal) => {
      totalConversion += deal.conversionsArray.length || 0;
      totalConversion += deal.cpmConversions.length || 0;
    });

    const influencers = groupByCreator(Object.values(rightDeals));
    console.log(influencers);
    setItems(Object.values(influencers));
    setDealsForCsv(Object.values(rightDeals));
    setStats({
      grossRevenue,
      grossProfit,
      conversions: totalConversion,
    });

    console.log(itemsWithDeal);
    setChartData(itemsWithDeal);
  };
  useEffect(() => {
    processDeals(dealsArray);
  }, [selectedEmployee, dealsArray, selectedDealType, selectedAdvertisers, selectedOffer, searchValue, selectedCategories]);
  useEffect(() => {
    const api = new ApiClient();
    setLoaingAdvertiser(true);
    api.client.get('/api/portal/finance/get-deal-owners').then((resp) => {
      setEmployees(resp.data.dealOwners);
      setLoaingAdvertiser(false);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load employees, Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);
  const fetchAmountReport = async () => {
    setLoaing(true);
    setItems([]);
    clear();
    setSelectedDate({ startDate, endDate });
    try {
      const resp = await axios.post('/api/portal/reports/post-creators-by-brand-full-by-csp', {
        startDate,
        endDate,
        advertisers: null,
        creators: null, // internal _id
        employees: selectedEmployee?.map(i => i.value),
        identity,
        showClicks,
      });
      if (resp.data.success) {
        console.log(resp.data);
        const deals = groupByDealResp(resp.data.items);
        console.log(deals);
        convertDealsForCreators(deals, startDate, endDate);
        console.log(deals);
        console.log(Object.values(deals).map(d => ({
          dealId: d.deal?._id, totalRevenue: d.totalRevenue, hsContactVid: d.deal?.hsContactVid, mull: d.totalPayoutWithMulligan, totalProfit: d.grossProfit,
        })));
        setDeals(Object.values(deals));
        setFetched(true);
        setLoaing(false);
        setErrors(resp.data.notUsedConversions);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
    return true;
  };
  const employeeOptions = useMemo(() => {
    const i = employees.map(item => ({ value: item._id, label: `${item.firstName || ''} ${item.lastName || ''}`, item }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [employees]);
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40, 100],
    placeholder: 'Search...',
    sortBy: 'revenue',
    direction: 'desc',
    useFlex: true,
  };

  const columns = Columns({ startDate, endDate });
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Creator Performance by Deal Owner', path: false },
            ]}
            ComponentInCenter={() => {
              return !loading && fetched && errors.length > 0 ? (
                <Errors errors={errors} startDate={startDate} endDate={endDate} />
              ) : ''
            }}
          />
        </Col>
      </Row>
      
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Creator Performance by Deal Owner</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3" >
                <Col>
                  <Filter
                    setValue={(param, value) => {
                      console.log(value);
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                    showLast6Month
                  />
                </Col>
                <Col lg="auto">
                  <div>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchAmountReport();
                      }}
                      className="mr-2 btn-sm mt-xl-0 mt-lg-3 mb-0"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                    <GenerationInfo />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Select
                    isDisabled={loadingAdvertiser}
                    value={selectedEmployee}
                    options={employeeOptions}
                    onChange={setSelectedEmployee}
                    placeholder="Select Deal Owner..."
                    isMulti
                  />
                </Col>
                <Col sm="auto">
                  <FormGroup className="mt-2 color-text" inline>
                    <CheckBoxField
                      name='clicks'
                      label="Show creators with clicks and no revenue"
                      value={showClicks}
                      onChange={({ target }) => target && setShowClicks(target.checked)}
                    />
                  </FormGroup>
                </Col>
                <Col />
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    identity={identity}
                    topic="report"
                    isShow={!!loading}
                  />
                </Col>
              </Row> */}
              <Row className="mt-3">
                <Col>
                  {!loading && fetched ? (
                    <ChartComponent
                      selectedAdmins={chartData}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                    />) : null
                  }
                </Col>
              </Row>
              {fetched && !loading ? (
                <Row className="my-2">
                  <Col>
                    <Total stats={stats} />
                  </Col>
                </Row>
              ) : null}
              {fetched && !loading ? (
                <Row className="my-2">
                  <Col>
                    <FilterForTable
                      selectedAdvertiser={selectedAdvertisers}
                      setSelectedAdvertiser={setSelectedAdvertisers}
                      setSelectedOffer={setSelectedOffer}
                      selectedOffer={selectedOffer}
                      setSelectedDealType={setSelectedDealType}
                      selectedDealType={selectedDealType}
                      onSearchChange={onSearchChange}
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                    />
                  </Col>
                </Row>
              ) : null}
              {fetched && !loading ? (
                <Row className="mt-3">
                  <Col />
                  <Col lg="auto">
                    {items.length > 0 ? (
                      <div className="float-left mr-2 mb-0">
                        <DownloadCSVCreatorDeal
                          items={items}
                          deals={dealsCsv}
                        />
                      </div>
                    ) : null}
                  </Col>
                </Row>
              ) : null}
              <Row className="mt-3">
                <Col>
                  {!loading && fetched ? (
                    <ReactTableBase
                      key="searchable"
                      columns={columns}
                      data={items}
                      tableConfig={tableConfig}
                    />) : null
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal />
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(SummaryReport);
