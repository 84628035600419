/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint react/prop-types: 0 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import ApiClient from 'ApiClient';
import ViewHelpers from '@/shared/ViewHelpers';
import { Button } from 'reactstrap';
// import StyledLink from '../ui/styled-link';

import withNotice from '../../../../containers/App/store/with-notice';


/**
 * Renders Button for an action
 *
 * @private
 * @component
 */
class ActionButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  href() {
    const {
      action, resourceId, recordId, recordIds,
    } = this.props;
    const h = new ViewHelpers();
    const { name: actionName, actionType } = action;

    switch (actionType) {
      case 'record':
        if (!recordId) {
          throw new Error('You have to speficy "recordId" for record action');
        }
        return h.recordActionUrl({ resourceId, recordId, actionName });
      case 'resource':
        return h.resourceActionUrl({ resourceId, actionName });
      case 'bulk':
        return h.bulkActionUrl({ resourceId, recordIds, actionName });
      default:
        throw new Error('"actionType" should be either record, resource or bulk');
    }
  }

  callApi() {
    const {
      action, resourceId, recordId, location,
      history, actionPerformed, addNotice, recordIds,
    } = this.props;

    const api = new ApiClient();
    let promise;

    switch (action.actionType) {
      case 'record':
        if (!recordId) {
          throw new Error('You have to speficy "recordId" for record action');
        }
        promise = api.recordAction({
          resourceId, actionName: action.name, recordId,
        });
        break;
      case 'resource':
        promise = api.resourceAction({
          resourceId, actionName: action.name,
        });
        break;
      case 'bulk':
        if (!recordIds) {
          throw new Error('You have to speficy "recordIds" for bulk action');
        }
        promise = api.bulkAction({
          resourceId, actionName: action.name, recordIds,
        });
        break;
      default:
        throw new Error('"actionType" should be either record, resource or bulk');
    }

    promise.then((response) => {
      const { data } = response;
      if (data.notice) {
        addNotice(data.notice);
      }
      if (data.redirectUrl && location.pathname !== data.redirectUrl) {
        history.push(data.redirectUrl);
      }
      if (actionPerformed) {
        actionPerformed(action.name);
      }
    }).catch((error) => {
      throw error;
    });
  }

  handleClick(event) {
    const { action } = this.props;

    if (action.guard && !confirm(action.guard)) {
      event.preventDefault();
      return;
    }
    if (typeof action.component !== 'undefined' && action.component === false) {
      event.preventDefault();
      this.callApi();
    }
  }


  render() {
    const {
      action, className,
    } = this.props;
    let icon = (<i className="lnr lnr-pencil" />);
    if (action.name === 'delete') {
      icon = (<i className="lnr lnr-trash" />);
    }
    if (action.name === 'info') {
      icon = (<i className="lnr lnr-eye" />);
    }

    return (
      <Button
        outline
        className={`${className || ''}`}
        size="sm"
        onClick={this.handleClick}
        tag="a"
        href={this.href()}
      >
        {icon}
        {action.label}
      </Button>
    );
  }
}


// TODO - remove this hack
export default withRouter(withNotice(ActionButton));
/* <StyledLink
          to={this.href()}
          className={`${className || ''}`}
          onClick={this.handleClick}
        >
          <span className="icon">
            <i className={action.icon} />
          </span>
          <span className="btn-text">
            {action.label}
          </span>
        </StyledLink>
 */
