import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Spinner, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import EmployeeCommissionReportField from './components/custom/EmployeeCommissionReportField';
import { getEmployeeCommissionReportData, updateEmployeeCommissionReportRecord, reCalculateData, updateEmployeeCommissionsReport } from '../../../../redux/reducers/admin/EmployeeCommissionsReducer';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import formater from '../../../../shared/helpers/WVFormatter';
import EmployeeCommissionsTable from './components/EmployeeCommissionsTable';
import csvHelper from '../../../../shared/helpers/CSVHelper';
import { determineStatusLabel } from './components/custom/StatusComponent';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';

const columnsCommissionsSummary = {
  employee: {
    title: 'Employee',
    valueFunc: record => (record.employee ? `${record.employee.firstName} ${record.employee.lastName}` : ''),
    sort: record => (record.employee ? `${record.employee.firstName} ${record.employee.lastName}` : ''),
  },
  goalsTotal: {
    title: 'Goal Target',
    Component: EmployeeCommissionReportField,
    sort: record => Number(record.goalsTotal),
  },
  goalsCount: {
    title: 'Goals Achieved',
    Component: EmployeeCommissionReportField,
    sort: record => Number(record.goalsCount),
  },
  commissionRate: {
    title: 'Commission Rate',
    Component: EmployeeCommissionReportField,
    sort: record => parseFloat(record.commissionRate.$numberDecimal),
  },
  totalProfit: {
    title: 'Total Profit',
    valueFunc: record => (record.totalProfit ? formater.formatCurrency(record.totalProfit) : formater.formatCurrency(0)),
    sort: record => parseFloat(record.totalProfit),
  },
  commissionAmount: {
    title: 'Commission Amount',
    valueFunc: record => (record.commissionAmount ? formater.formatCurrency(record.commissionAmount) : formater.formatCurrency(0)),
    sort: record => parseFloat(record.commissionAmount),
  },
};

class EmployeeCommissionsReport extends React.Component {
  constructor(props) {
    super(props);
    this.updateRecord = this.updateRecord.bind(this);
    this.showPromptDialog = this.showPromptDialog.bind(this);
    this.handlePromptDialogClose = this.handlePromptDialogClose.bind(this);
    this.handlePromptDialogSubmit = this.handlePromptDialogSubmit.bind(this);
    this.generateCSVReport = this.generateCSVReport.bind(this);
    this.state = {
      promptDialogVisible: false,
    };
  }
  componentDidMount() {
    const { match } = this.props;
    if (match && match.params.payableReportId) {
      this.fetchEmployeeCommission(this.props);
    }
  }

  fetchEmployeeCommission(props) {
    const newProps = props || this.props;
    const { match } = newProps;
    const { dispatch } = this.props;
    dispatch(getEmployeeCommissionReportData(match.params.payableReportId));
  }
  updateRecord(id, record) {
    const { dispatch } = this.props;
    dispatch(updateEmployeeCommissionReportRecord({ id, record }));
    dispatch(reCalculateData());
  }
  showPromptDialog() {
    this.setState({ promptDialogVisible: true });
  }

  handlePromptDialogClose() {
    this.setState({ promptDialogVisible: false });
  }
  handlePromptDialogSubmit() {
    this.handlePromptDialogClose();
    const { dispatch, match } = this.props;
    if (match && match.params.payableReportId) {
      dispatch(updateEmployeeCommissionsReport(match.params.payableReportId));
    }
  }
  generateCSVReport() {
    const { payableReport, commissions } = this.props;
    let { reports } = this.props;
    const data = [['Commission Summary'], ['Employee', 'Goal Target', 'Goals Achieved', 'Commission Rate', 'Total Profit', 'Commission Amount']];
    reports = reports.slice().sort((a, b) => b.totalProfit - a.totalProfit);
    reports.forEach((report) => {
      const itemRow = [];
      itemRow.push(`${report.employee.firstName} ${report.employee.lastName}`);
      itemRow.push(report.goalsTotal);
      itemRow.push(report.goalsCount);
      itemRow.push(`${parseFloat(report.commissionRate.$numberDecimal)}%`);
      itemRow.push(formater.formatCurrency(report.totalProfit));
      itemRow.push(formater.formatCurrency(report.commissionAmount));
      data.push(itemRow);
    });

    data.push(['']);
    data.push(['Brand Summary']);

    Object.values(commissions).forEach((commissionArr) => {
      data.push([''], [commissionArr[0].advertiser.companyName]);
      data.push(['Employee', 'Revenue', 'Payout', 'Adjustment', 'Profit', 'Status']);
      // eslint-disable-next-line no-param-reassign
      commissionArr = commissionArr.slice().sort((a, b) => b.revenueAmount.$numberDecimal - a.revenueAmount.$numberDecimal);
      commissionArr.forEach((commission) => {
        const itemRow = [];
        itemRow.push(`${commission.employeeCommissionReport.employee.firstName} ${commission.employeeCommissionReport.employee.lastName}`);
        itemRow.push(formater.formatCurrency(commission.revenueAmount.$numberDecimal));
        itemRow.push(formater.formatCurrency(commission.payoutAmount.$numberDecimal));
        itemRow.push(formater.formatCurrency(commission.adjustmentAmount.$numberDecimal));
        itemRow.push(formater.formatCurrency(commission.profit));
        itemRow.push(determineStatusLabel(commission.status));
        data.push(itemRow);
      });
    });

    csvHelper.generateCSV(`${payableReport.name} commissions.csv`, data);
  }
  render() {
    const {
      promptDialogVisible,
    } = this.state;
    const {
      dispatch, reports, commissions, loading, payableReport,
    } = this.props;
    const version = moment().format('x');
    return (
      <Container className="dashboard">
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            { title: 'Account Payable Report', path: payableReport ? `/finance/account-payable-report/${payableReport._id}` : '/finance/account-payable-report' },
            { title: 'Employee Commissions Report', path: null },
          ]}
          isBackButton
        />
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="bold-text">Employee Commissions Report</h3>
                    <h5>{payableReport !== null && (payableReport.name)}</h5>
                    {loading.employeeCommission && (<div className="text-center"><br /><Spinner color="primary" size="lg" /></div>)}
                    {!loading.employeeCommission && reports.length > 0 && (
                      <>
                        <Row>
                          <Col>
                            <br /><br />
                            <h4>Commission Summary</h4>
                          </Col>
                          <Col lg="auto">
                            <Button
                              className="btn-sm"
                              color="primary"
                              onClick={this.showPromptDialog}
                            >
                              Refresh
                            </Button>
                          </Col>
                          <Col lg="auto">
                            <Button color="primary" onClick={this.generateCSVReport}>Download</Button>
                          </Col>
                        </Row>
                        <Row>
                          <MatTableForRedux
                            dataArray={reports}
                            updateRecordFunc={this.updateRecord}
                            defaultOrder={{
                              order: 'desc',
                              orderBy: 'totalProfit',
                            }}
                            columns={columnsCommissionsSummary}
                            version={version}
                            totals={[{ totalProfit: 'money' }, { commissionAmount: 'money' }]}
                          />
                        </Row>
                        <Row>
                          <Col>
                            <h4>Brand Summary</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {/* eslint-disable-next-line react/no-array-index-key */}
                            {Object.values(commissions).map((commissionsArr, advertiserId) => (<EmployeeCommissionsTable key={advertiserId} dispatch={dispatch} commissionsArr={commissionsArr} />))}
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={promptDialogVisible} toggle={this.handlePromptDialogClose} className="delete-modal">
          <ModalHeader toggle={this.handlePromptDialogClose} />
          <ModalBody>
            Are you sure you want to refresh all report&apos;s data?
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-sm"
              color="danger"
              onClick={this.handlePromptDialogSubmit}
            >
              Refresh
            </Button>{' '}
            <Button
              className="btn-sm"
              color="secondary"
              onClick={this.handlePromptDialogClose}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

EmployeeCommissionsReport.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.objectOf(PropTypes.any).isRequired,
  reports: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  commissions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  payableReport: PropTypes.objectOf(PropTypes.any),
};
EmployeeCommissionsReport.defaultProps = {
  payableReport: null,
};
const mapStateToProps = state => ({
  loading: state.employeeCommission.loading,
  reports: state.employeeCommission.reports,
  commissions: state.employeeCommission.commissions,
  payableReport: state.employeeCommission.payableReport,
});

export default connect(mapStateToProps, null)(EmployeeCommissionsReport);
