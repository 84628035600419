import React, { useEffect, useState, useMemo } from 'react';
import { axios } from 'ApiClient';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  FormGroup,
} from 'reactstrap';
import moment from 'moment-timezone';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import PropTypes from 'prop-types';
import Select from 'react-select';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import TuneIcon from 'mdi-react/TuneIcon';
import csvHelper from '../../../../shared/helpers/CSVHelper';
import { calculatePar } from '../../../../shared/helpers/CACHelper';
import CheckBoxField from '../../../../shared/components/CheckBox';
import { showModalById } from '../../../../redux/reducers/TableReducer';
import { modalId } from './Modals/CreatorLookupModal';


const Actions = ({
  selectedAdvertiser, setSelectedAdvertiser, selectedDealType, setSelectedDealType, selectedEmployee, setSelectedEmployee,
  onSearchChange, items, ROASvalue, setROASValue, settings, showRebuy, setShowRebuy, setSelectedPauseDuration, selectedPauseDuration, projectedSettings, showPredictedCAC, setShowPredictedCAC,
}) => {
  const [advertisers, setAdvertisers] = useState([]);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState([]);
  const [search, setSearch] = useState();
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(true);
  const dispatch = useDispatch();

  const generateCSVReport = (csvArrayColumns) => {
    const data = [csvArrayColumns.map(column => column.label)];
    items.forEach((record) => {
      const itemRow = [];
      csvArrayColumns.forEach((column) => {
        itemRow.push(column.func(record));
      });
      data.push(itemRow);
    });
    if (csvArrayColumns.find(c => c.total)) {
      const totals = [];
      csvArrayColumns.forEach((column, i) => {
        if (column.total === true) totals[i] = (data.slice(1)).reduce((sum, row) => Number(row[i] || 0) + sum, 0);
        else if (column.total?.toString().length > 0) totals[i] = column.total;
        else totals[i] = '';
      });
      data.push(totals);
      console.log(totals);
    }
    console.log(data);
    csvHelper.generateCSV('CreatorPerformanceReport.csv', data);
  };

  const getProjectedPercent = (daysCount) => {
    const usedSettings = [];
    const daysCountArray = Object.keys(projectedSettings).map(a => Number(a)).sort((a, b) => a - b);
    let sumPercentUsed = 0;
    let remainingPercent = 0;
    daysCountArray.forEach((k) => {
      const item = projectedSettings[k];
      console.log(item);
      if (Number(k) <= Number(daysCount)) {
        usedSettings.push(item);
        sumPercentUsed += Number(item.value || 0);
      }
      if (Number(item.start) <= Number(daysCount) && Number(item.end) >= Number(daysCount)) {
        console.log((Number(daysCount) - (Number(item.start) - 1)), ((Number(item.end) - Number(item.start)) + 1));
        console.log((Number(daysCount) - (Number(item.start) - 1)) / ((Number(item.end) - Number(item.start)) + 1), Number(item.value));
        remainingPercent = ((Number(daysCount) - (Number(item.start) - 1)) / ((Number(item.end) - Number(item.start)) + 1)) * Number(item.value);
      }
    });
    console.log('projectedPercdntage');
    console.log(daysCount, [sumPercentUsed, remainingPercent]);
    return Number(sumPercentUsed) + Number(remainingPercent);
  };
  const getDefaultTargetCac = (brand, type) => {
    let targetCac = 450;
    let targetP = (settings.types[type] / 100) || 0.25;
    if (settings.brands[brand._id] && settings.brands[brand._id][type]) {
      targetCac = settings.brands[brand._id][type].cac;
      targetP = settings.brands[brand._id][type].percent / 100;
    }
    return targetCac * (1 + targetP);
  };

  const generateCSVReportStart = async () => {
    const columns = [
      {
        label: 'Creator Name',
        func: deal => `${deal.creator?.first_name || ''} ${deal.creator?.last_name || ''}`,
      }, {
        label: 'Creator Email',
        func: deal => `${deal.creator?.email || ''}`,
      }, {
        label: 'Brand',
        func: deal => `${deal.offer?.advertiser?.companyName || ''}`,
      }, {
        label: 'Deal Name',
        func: deal => `${deal.latestDeal?.dealname || ''}`,
      }, {
        label: 'DealType',
        func: deal => `${deal.latestDeal?.dealTerms.type_of_deal || ''}`,
      }, {
        label: 'Deal Owner',
        func: (p) => {
          if (!p.admin) return '';
          return `${p.admin?.firstName || ''} ${p.admin?.lastName || ''}`;
        },
      }, {
        label: 'Revenue',
        func: record => (record.revenue || ''),
      }, {
        label: 'Payout',
        func: record => (record.payout || ''),
      }, {
        label: 'Gross Profit',
        func: record => Number(record.revenue || 0) - Number(record.payout || 0),
      }, {
        label: 'Conversions',
        func: deal => (deal.CPApaidConversionsArray.length + deal.CPMpaidConversions.length),
      },
      {
        label: 'Rebuy Pause',
        func: deal => `${deal.latestDeal?.rebuyPause?.started ? 'Yes' : ''}`,
      },
      {
        label: 'Rebuy Pause Duration',
        func: (deal) => {
          if (deal.latestDeal?.rebuyPause?.started) {
            const rebHistory = deal.latestDeal?.rebuyPause.history;
            return rebHistory[rebHistory.length - 1]?.value || '';
          }
          return '';
        },
      },
      {
        label: 'Rebuy Pause Notes',
        func: (deal) => {
          if (deal.latestDeal?.rebuyPause?.started && deal.latestDeal?.rebuyPause.notes) {
            return deal.latestDeal?.rebuyPause.notes;
          }
          return '';
        },
      },
      {
        label: 'Promo Code',
        func: deal => `${deal.latestDeal?.dealTerms?.coupon_code || ''}`,
      },
      {
        label: 'Publish Date',
        func: p => (p.publishDate ? moment.tz(moment.utc(p.publishDate).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') : ''),
      },
      {
        label: 'Days Since Last Publish',
        func: p => (p.publishDate ? moment.tz('America/New_York').diff(moment.tz(moment.utc(p.publishDate).format('YYYY-MM-DD'), 'America/New_York'), 'days') : '-'),
      },
      {
        label: 'CAC',
        func: p => p.cac,
      }, {
        label: 'Target Cac Threshold',
        func: (deal) => {
          const targetCac = getDefaultTargetCac(deal.offer.advertiser, deal.latestDeal.dealTerms.type_of_deal);
          return targetCac;
        },
      },
      {
        label: 'Rebuy?',
        func: (deal) => {
          const targetCac = getDefaultTargetCac(deal.offer.advertiser, deal.latestDeal.dealTerms.type_of_deal);
          if (!deal.cac) return '';
          return (targetCac >= deal.cac ? 'Y' : 'N');
        },
      },
      {
        label: '$ Over/Under Target CAC Threshold',
        func: (deal) => {
          const targetCac = getDefaultTargetCac(deal.offer.advertiser, deal.latestDeal.dealTerms.type_of_deal);
          return (Number(deal.cac || 0) - targetCac);
        },
      },
      {
        label: '% Over/Under Target CAC Threshold',
        func: (deal) => {
          const targetCac = getDefaultTargetCac(deal.offer.advertiser, deal.latestDeal.dealTerms.type_of_deal);
          const over = deal.cac ? ((deal.cac - targetCac) / targetCac) : '-';
          return over * 100;
        },
      },
      {
        label: 'Predicted CAC',
        func: (deal) => {
          if (deal.latestDeal?.dealTerms?.type_of_deal !== 'Upfront CPM') return '-';
          const cac = (Number(deal.CPApaidConversionsArray.length + deal.CPMpaidConversions.length) !== 0 ? Number(deal.cac || 0) : '-');
          const targetCac = getDefaultTargetCac(deal.offer.advertiser, deal.latestDeal.dealTerms.type_of_deal);
          const allow = cac !== '-' && cac > 0 && targetCac >= cac;
          if (allow) return '-';
          const days = (deal.publishDate ? moment.tz('America/New_York').diff(moment.tz(moment.utc(deal.publishDate).format('YYYY-MM-DD'), 'America/New_York'), 'days') : 0);
          console.log(days, 'Project Days');
          if (days < 16) return '-';
          const projectedPercent = days > 0 ? getProjectedPercent(days) : 0;
          const conversionsCount = Number(deal.CPMpaidConversions.length);
          const projectedConversions = Number(conversionsCount / projectedPercent) * 100;
          const projectedCac = projectedConversions ? deal.payout / projectedConversions : '-';
          return projectedCac;
        },
      },
      {
        label: 'PAR',
        func: deal => (calculatePar(deal, { settings, projectedSettings }) || '-'),
      },
      {
        label: 'Creator Profile URL',
        func: deal => `${window.location.origin}/influencer/profile/${deal.creator._id}`,
      },
      {
        label: 'HubSpot Deal URL',
        func: deal => `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal?.latestDeal.hs_object_id}/`,
      },
      {
        label: 'HubSpot Contact URL',
        func: deal => `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${deal?.creator?.hsContactVid}/`,
      },
    ];
    generateCSVReport(columns);
  };


  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);
  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
  };


  useEffect(() => {
    setLoadingAdvertiser(true);
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      // setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : -1)));
      // setAdvertisers(Object.values(brands));
      // setOffers(resp.data.offers);
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      /* addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      }); */
    });
  }, []);
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    setLoadingAdvertiser(true);
    axios.get('/api/portal/finance/get-deal-owners').then((resp) => {
      setEmployees(resp.data.dealOwners);
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      /* addNotice({
        message: 'There was an error when try load employees, Check out console to see more information.',
        type: 'error',
      }); */
    });
  }, []);
  const advertiserOptions = useMemo(() => advertisers.filter(b => b.displayInUi === 'Yes').map(a => ({ value: a._id, label: a.companyName })), [advertisers]);
  const employeeOptions = useMemo(() => {
    const i = employees.map(item => ({ value: String(item._id), label: `${item.firstName || ''} ${item.lastName || ''}`, item }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [employees]);

  const lowestPercentage = useMemo(() => {
    if (!settings || !settings.types) return 25;
    const array = Object.values(settings.types);
    return Math.min(...array);
  }, [settings]);
  const handleChangeCreatorAssignedFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setShowPredictedCAC({
        ...showPredictedCAC,
        [name]: checked,
      });
    }
  };

  return (
    <div className="mt-3">
      <Row>
        <Col sm={6}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Creator Name"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '38px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    height: '38px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col />
        <Col sm="auto" className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '38px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem disabled={false} onClick={() => { generateCSVReportStart(); }}>
                <Tooltip title="Include">
                  <>Download CSV</>
                </Tooltip>
              </DropdownItem>
              <DropdownItem disabled={false} onClick={() => { dispatch(showModalById(modalId)); }}>
                Creator Lookup
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row className="mt-3">
            <Col>
              <Label className="font-weight-bold">Brand(s)</Label>
              <Select
                isDisabled={loadingAdvertiser}
                value={selectedAdvertiser}
                options={advertiserOptions}
                onChange={setSelectedAdvertiser}
                placeholder="Select Brand..."
                isMulti
              />
            </Col>
            <Col>
              <Label className="font-weight-bold">Deal Owner(s)</Label>
              <Select
                isDisabled={loadingAdvertiser}
                value={selectedEmployee}
                options={employeeOptions}
                onChange={setSelectedEmployee}
                placeholder="Select Deal Owner..."
                isMulti
              />
            </Col>
            <Col>
              <Label className="font-weight-bold">Deal Type(s)</Label>
              <Select
                value={selectedDealType}
                options={[
                  { value: 'Hybrid', label: 'Hybrid' },
                  { value: 'Upfront Only', label: 'Upfront Only' },
                  { value: 'Upfront CPM', label: 'Upfront CPM' },
                  { value: 'CPA/Ambassador', label: 'CPA/Ambassador' },
                ]}
                onChange={setSelectedDealType}
                placeholder="Deal Type..."
                isClearable
                isMulti
              />
            </Col>
            <Col />
          </Row>
          <Row className="mt-3">
            <Col xs={10} className="mt-3">
              <Row>
                <Col xs={6} style={{ paddingLeft: '40px' }}>
                  <Row>
                    <Label className="font-weight-bold">
                      <Tooltip title="The percentage amount that a deal's CAC is from the Target CAC Threshold dollar amount">
                        <span>% from Target CAC Threshold</span>
                      </Tooltip>
                    </Label>
                  </Row>
                  <Row>
                    <Col xs={10}>
                      <span style={{ marginLeft: '10px' }}>
                        <Slider
                          min={0}
                          max={200}
                          value={ROASvalue}
                          onChange={(event, value) => setROASValue(value)}
                          marks={[
                          { value: 0, label: '0%' },
                          { value: 25, label: '25%' },
                          { value: 50, label: '50%' },
                          { value: 75, label: '75%' },
                          { value: 100, label: '100%' },
                          { value: 125, label: '125%' },
                          { value: 150, label: '150%' },
                          { value: 175, label: '175%' },
                          { value: 200, label: '200%' },
                        ]}
                          step={5}
                          track="inverted"
                          valueLabelDisplay="auto"
                          valueLabelFormat={value => `${value}%`}
                        />
                      </span>
                    </Col>
                    <Col xs={2}>
                      <Tooltip title={`Reset ROAS to the defauls minimum threshold percentage (${lowestPercentage}%)`}>
                        <IconButton
                          id="filtersMenuButton"
                          size="small"
                          onClick={() => setROASValue(Number(lowestPercentage))}
                          className="material-table__toolbar-button"
                        >
                          <i className="fa fa-redo" />
                        </IconButton>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Col>
                    <Label className="bold-text" style={{ marginBottom: '14px' }}>
                      Predicted CAC?
                    </Label>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="AssignedCreator"
                        label="Assigned"
                        value={showPredictedCAC.assigned}
                        onChange={event => handleChangeCreatorAssignedFilters(event, 'assigned')}
                      />
                    </FormGroup>
                    <FormGroup
                      style={{ marginBottom: '0' }}
                    >
                      <CheckBoxField
                        name="NotAssignedCreator"
                        label="Not Assigned"
                        value={showPredictedCAC.not}
                        onChange={event => handleChangeCreatorAssignedFilters(event, 'not')}
                      />
                    </FormGroup>
                  </Col>
                </Col>
                <Col xs={3}>
                  <FormGroup className="text-center float-right">
                    <CheckBoxField
                      className=" mt-1"
                      name="stripe"
                      label="Show Paused Rebuy Deals"
                      value={showRebuy}
                      onChange={({ target }) => {
                        if (target) {
                          setShowRebuy(target.checked);
                          setSelectedPauseDuration([]);
                        }
                        }
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col xs={2} className="mt-3">
              {showRebuy && (
                <div>
                  <Label className="font-weight-bold">
                    Pause Duration(s)
                  </Label>
                  <Select
                    value={selectedPauseDuration}
                    options={[
                      { value: '3', label: '3 Days' },
                      { value: '7', label: '7 Days' },
                      { value: '14', label: '14 Days' },
                      { value: '30', label: '30 Days' },
                      { value: '45', label: '45 Days' },
                      { value: '60', label: '60 Days' },
                      { value: '9999', label: 'All Time' },
                    ]}
                    onChange={setSelectedPauseDuration}
                    placeholder="Pause Durations..."
                    isClearable
                    isMulti
                  />
                </div>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
Actions.propTypes = {
  setROASValue: PropTypes.func.isRequired,
  ROASvalue: PropTypes.number,
  setSelectedAdvertiser: PropTypes.func.isRequired,
  selectedAdvertiser: PropTypes.arrayOf(PropTypes.any),
  setSelectedDealType: PropTypes.func.isRequired,
  selectedDealType: PropTypes.arrayOf(PropTypes.any),
  setSelectedEmployee: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.arrayOf(PropTypes.any),
  selectedPauseDuration: PropTypes.arrayOf(PropTypes.any),
  setSelectedPauseDuration: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  settings: PropTypes.objectOf(PropTypes.any).isRequired,
  setShowRebuy: PropTypes.func.isRequired,
  showRebuy: PropTypes.bool.isRequired,
  projectedSettings: PropTypes.objectOf(PropTypes.any).isRequired,
  showPredictedCAC: PropTypes.objectOf(PropTypes.any).isRequired,
  setShowPredictedCAC: PropTypes.func.isRequired,
};
Actions.defaultProps = {
  selectedAdvertiser: [],
  selectedDealType: [],
  selectedEmployee: [],
  selectedPauseDuration: [],
  ROASvalue: 25,
};
export default Actions;
