import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import Select from 'react-select';
import { uid } from 'react-uid';
import ApiClient from 'ApiClient';
import { Row, Col, Button, Container, Card, CardBody, CardTitle, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../App/store/with-notice';
import GenerateTable from './GenerateTable';
import LastMonthFilter from '../LastMonthFilter';
import SaveReport from '../SaveReport';
import Alert from '../../../../shared/easydev2/shared/components/Alert';


const getWarningMessage = (validationResult) => {
  let message = '';
  let url = '';
  if (!validationResult.contact && validationResult.invalidContact === true) {
    message = 'Missing HubSpot Contact';
    url = `/influencer/profile/${validationResult.influencer._id}/`;
  }
  if (validationResult.deal && !validationResult.deal.dealTerms.type_of_deal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Incomplete Deal Terms';
  }
  if (message === '' && validationResult.unassignedDeal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.unassignedDeal.hs_object_id}/`;
    message = 'Incomplete Offer';
  }
  if (message === '' && validationResult.contact && validationResult.anotherOfferDeal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${validationResult.contact.hs_object_id}/`;
    message = 'Missing Deal or Possible Offer Mismatch';
  }
  if (message === '' && validationResult.contact && !validationResult.deal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${validationResult.contact.hs_object_id}/`;
    message = 'Missing Deal';
  }
  return (<span>{message} {url ? (<a href={url} target="_blank" rel="noreferrer">Click here to correct issue</a>) : null } {validationResult.offerName ? `Offer Name: ${validationResult.offerName}` : null}</span>);
};

const SummaryReport = ({ addNotice }) => {
  const [loading, setLoaing] = useState(false);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [offers, setOffers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [estimatePrevious, setEstimatePrevious] = useState(false);
  const [includePrevious, setIncludePrevious] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState(['complete']);
  const [errors, setErrors] = useState([]);
  const [conversions, setConversions] = useState({});

  const [saveReport, setSaveReport] = useState(false);
  const [saveReportModal, setSaveReportModal] = useState(false);
  const [savedReportModal, setSavedReportModal] = useState(false);


  useEffect(() => {
    const api = new ApiClient();
    setLoadingAdvertiser(true);
    api.client.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands));
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
    api.client.get('/api/portal/finance/get-employees').then((resp) => {
      setEmployees(resp.data.employees);
    }).catch(() => {
      setLoadingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);

  const saveReportToSave = async (name, admins) => {
    setLoaing(true);
    // setError(null);
    setItems([]);
    try {
      const resp = await axios.post('/api/portal/reports/post-save-base-report', {
        startDate: moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        identity,
        estimatePrevious,
        includePrevious,
        selectedStatuses,
        selectedAdvertisers,
        selectedOffer,
        name,
        admins,
      });
      if (resp.data.success) {
        setLoaing(false);
        setSaveReportModal(false);
        setSavedReportModal(true);
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const fetchAmountReport = async () => {
    setLoaing(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-base-report', {
        startDate: moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        identity,
        estimatePrevious,
        includePrevious,
        selectedStatuses,

      });
      if (resp.data.success) {
        setConversions(resp.data.conversions);
        setItems({
          minus3MonthReport: resp.data.minus3MonthReport,
          minus2MonthReport: resp.data.minus2MonthReport,
          minus1MonthReport: resp.data.minus1MonthReport,
        });
        setErrors(resp.data.notUsedConversions);
        // setBrands(Object.values(brandsArray));
        // setInfluencers(Object.values(influencersArray));
        // setCsp(Object.values(cspArray));
        setLoaing(false);
        // setItems(resp.data.items.map(r => ({ ...r, id: r._id })));
        // setVersion((new Date()).toString());
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  const options = useMemo(() => {
    const i = advertisers.filter(b => b.displayInUi === 'Yes').map(item => ({ value: item._id, label: item.companyName }));
    i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [advertisers]);

  const optionsOffer = useMemo(() => {
    const i = offers
      .filter(o => !selectedAdvertisers || selectedAdvertisers.value === o.advertiser._id)
      .map(item => ({ value: item._id, label: item.name }));
    i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [offers, selectedAdvertisers]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Base Performance Report', path: false },
            ]}
          />
        </Col>
      </Row>
      {!loading && items.length > 0 && errors.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody>
                {errors.map((e, i) => (
                  <Alert color="warning" key={uid(i)}>
                    <p className="py-2 text-dark">
                      {e.error ? (<span>{e.error}</span>) : getWarningMessage(e)}
                    </p>
                  </Alert>))
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null }
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Base Performance Report</h3>
              </CardTitle>
              <hr />
              <Row>
                <Col className="mt-0">
                  <h5 style={{ color: 'black' }} className="font-weight-bold">{`${moment().subtract(3, 'month').startOf('month').format('MMM D, YYYY')} - ${moment().subtract(1, 'month').endOf('month').format('MMM D, YYYY')}`} (ET)</h5>
                </Col>
                <Col lg="auto">
                  <SaveReport
                    keyPermission="reports----basereport"
                    type="baseReport"
                    startDate={moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD')}
                    endDate={moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}
                    save={(name, admins) => saveReportToSave(name, admins)}
                    saveReportModal={saveReportModal}
                    savedReportModal={savedReportModal}
                    setSaveReportModal={setSaveReportModal}
                    setSavedReportModal={setSavedReportModal}
                    saveReport={saveReport}
                    setSaveReport={setSaveReport}
                  />
                </Col>
                <Col lg="auto">
                  <div>
                    <Button
                      color="primary"
                      onClick={async () => {
                        if (saveReport) {
                          setSaveReportModal(true);
                          return false;
                        }
                        await fetchAmountReport();
                        return false;
                      }}
                      className="mt-0 mb-0 btn-sm"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Label>Brand</Label>
                  <Select
                    isDisabled={loadingAdvertiser}
                    value={selectedAdvertisers}
                    options={options}
                    onChange={setSelectedAdvertisers}
                    placeholder="Select Brand..."
                    isClearable
                  />
                </Col>
                <Col>
                  <Label>Offer</Label>
                  <Select
                    isDisabled={loadingAdvertiser}
                    value={selectedOffer}
                    options={optionsOffer}
                    onChange={setSelectedOffer}
                    placeholder="Select Offer..."
                    isClearable
                  />
                </Col>
              </Row>
              <LastMonthFilter
                setEstimatePrevious={setEstimatePrevious}
                setIncludePrevious={setIncludePrevious}
                estimatePrevious={estimatePrevious}
                includePrevious={includePrevious}
                startDate={moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD')}
                endDate={moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}
                selectedStatuses={selectedStatuses}
                addStatus={(key, isChecked) => {
                  const status = [...selectedStatuses];
                  if (isChecked === true) {
                    status.push(key);
                  } else {
                    const index = status.indexOf(key);
                    if (index > -1) status.splice(index, 1);
                  }
                  setSelectedStatuses(status);
                }}
              />
              <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    identity={identity}
                    topic="report"
                    isShow={!!loading}
                  />
                </Col>
              </Row>
              <GenerateTable
                items={items}
                loading={loading}
                selectedAdvertisers={selectedAdvertisers}
                selectedOffer={selectedOffer}
                employees={employees}
                conversions={conversions}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
