import React from 'react';
import PropTypes from 'prop-types';
// import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch } from 'react-redux';
import CopyToClipboard from '../../../../../../../shared/components/CopyToClipboard';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';
import { modalId } from '../../modals/CommentViewModal';

const Comment = ({ record }) => {
  const dispatch = useDispatch();
  // const status = record.row.original.params?.sentimentStatus || '';

  // const describeValue = status ? status.history[0].initialValue : 'No lexicon data check yet.';

  return (
    <div>
      <CopyToClipboard value={record.row.original.params?.textOriginal || ''}>
        {record.row.original.params?.textDisplay.length > 110 ?
          // eslint-disable-next-line react/no-danger
          (<span dangerouslySetInnerHTML={{ __html: `${record.row.original.params?.textDisplay.toString().substring(0, 110) || ''}... ` }} />) :
          // eslint-disable-next-line react/no-danger
          (<span dangerouslySetInnerHTML={{ __html: `${record.row.original.params?.textDisplay || ''}  ` }} />)
        }
      </CopyToClipboard>
      {record.row.original.params?.textDisplay.length > 110 ?
        (
          <span>
            <br />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
            <a className="text-blue" role="button" onClick={() => { dispatch(showModalById(modalId, { text: record.row.original.params.textOriginal })); }}>Read More</a>
          </span>
        ) : null
      }
    </div>
  );
};

Comment.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Comment;
