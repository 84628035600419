import React, { useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';
// import withNotice from '../../../../App/store/with-notice';
import filterProperties from './custom/ActionProperties';
import Hook from '../../../../../shared/hooks';
// import { getConstant } from '../../../../../shared/helpers/WVConstants';

const Creator = ({
  history,
  resource,
}) => {
  const [searchUrl, setPrefill, loadPage, , useLoadOrDebounce] = Hook.usePrefillHook({
    resourceId: resource.id,
    initialState: {
      sortBy: 'createdAt',
      direction: 'desc',
      page: 1,
      perPage: 10,
    },
  });

  useEffect(() => {
    setPrefill(filterProperties({}));
  }, []);
  const setUrl = () => {
    loadPage();
  };
  const downloadCsv = useCallback(() => {
    window.open(`/resources/Influencer/download-csv?${searchUrl}`, '_blank').focus();
  }, [searchUrl]);
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 1000);

  useLoadOrDebounce({
    loadFunc: () => loadPage(), loadDebounceFunc: () => setUrlDebounced(), setSearchUrl: () => history.push(`${history.location.pathname}?${searchUrl}`)
  })
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Creators', path: null },
          { title: 'Dashboard', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Notification Templates</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              <Actions
                downloadCsv={downloadCsv}
              />
              <Row style={{ marginTop: '-10px' }}>
                {searchUrl !== null ? <Table /> : null}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>  
  );
};
Creator.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withRouter(Creator);
