import React from 'react';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WVFormatter from '../../../../../shared/helpers/WVFormatter';

const TotalControl = ({
  totalBillAmount, totalInvoiceAmount, totalRevenueAmount, totalPayoutAmount,
}) => {
  let totalProfit = totalRevenueAmount;
  totalProfit -= Number(totalPayoutAmount || 0);
  totalProfit -= Number(totalBillAmount || 0);
  // totalProfit += Number(totalInvoiceAmount || 0);
  // console.log(totalProfit, 'PROFIT');
  return (
    <Card body className="card-bordered card" style={{ padding: '20px', height: '100%' }}>
      <h4 className="mb-1"><strong>Revenue:</strong> <span className="float-right">{WVFormatter.formatCurrency(totalRevenueAmount)}</span></h4>
      <h4 className="mb-1"><strong>Payout:</strong> <span className="float-right">{WVFormatter.formatCurrency(totalPayoutAmount)}</span></h4>
      <h4 className="mb-1"><strong>Bill Payout:</strong> <span className="float-right">{WVFormatter.formatCurrency(totalBillAmount)}</span></h4>
      <h4 className="mb-1"><strong>Invoice Amount:</strong> <span className="float-right">{WVFormatter.formatCurrency(totalInvoiceAmount)}</span></h4>
      <h4 className="mb-1"><strong>Profit:</strong> <span className="float-right">{WVFormatter.formatCurrency(totalProfit)}</span></h4>
    </Card>
  );
};
TotalControl.propTypes = {
  totalBillAmount: PropTypes.number.isRequired,
  totalInvoiceAmount: PropTypes.number.isRequired,
  totalRevenueAmount: PropTypes.number.isRequired,
  totalPayoutAmount: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  totalRevenueAmount: state.accountPayable.stats.totalRevenue,
  totalPayoutAmount: state.accountPayable.stats.totalPayout,
  totalBillAmount: state.accountPayable.totalBillAmount,
  totalInvoiceAmount: state.accountPayable.totalInvoiceAmount,
});

export default connect(mapStateToProps, null)(TotalControl);
