/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle, Badge,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import { withStyles } from '@material-ui/core/styles';
import MatTableList from '../../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../../shared/components/BreadCrumbs';
import UserPropertyType from '../../components/custom';
import Actions from './Actions';
import { fillEmployees } from '../../../../../../redux/reducers/admin/EmployeeReducer';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';
import WVFormatter from '../../../../../../shared/helpers/WVFormatter';
import csvHelper from '../../../../../../shared/helpers/CSVHelper';
import ReportModal from '../../../../../../shared/components/modals/ReportModal';

const links = [
  { title: 'Home', path: '/' },
  { title: 'Admin', path: false },
  { title: 'Employees', path: false },
];

const ResourceAction = ({
  history,
  resource,
  setTag,
  action,
  employeeLog,
  loading,
  dispatch,
}) => {
  const filters = parseUrl();
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [version, setVersion] = useState((new Date()).toString());
  const [showRefreshModal, setShowRefreshModal] = useState(false);

  const refreshTable = () => {
    dispatch(fillEmployees(() => {
      setVersion((new Date()).toString());
    }));
    setShowRefreshModal(true);
  };

  const downloadCSV = (type, name) => {
    console.log(type, name);
    const data = employeeLog && employeeLog[name] ? employeeLog[name] : null;
    if (name === 'errors') {
      return csvHelper.downloadArrayCSV(data, 'message', `employeeLog-${name}.csv`);
    }
    return data ? csvHelper.downloadObjectCSV(data, `employeeLog-${name}.csv`) : false;
  };

  const closeModal = () => {
    if (!loading) {
      setShowRefreshModal(false);
    }
  };

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }
    return search;
  };

  const setUrl = () => {
    const search = getUrlSearch();
    search.set('page', '1');
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 500);

  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
  ]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs links={links} isBackButton />
        </Col>
      </Row>
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Employees</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Actions
            onSearchChange={onSearchChange}
            searchValue={searchValue}
            refreshTable={refreshTable}
          />
          <div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
            <MatTableList
              isResizable={false}
              showTitle={false}
              action={action}
              resource={resource}
              setTag={setTag}
              date={new Date()}
              showFilters={false}
              hideToolbar
              UserPropertyType={UserPropertyType}
              sortBy="firstName"
              direction="asc"
              version={version}
              meta={{ closeFunction: () => setVersion((new Date()).toString()) }}
              columnsSettings={{
                firstName: {
                  width: 150,
                },
                lastName: {
                  width: 150,
                },
                email: {
                  width: 300,
                },
                tuneId: {
                  width: 100,
                },
                title: {
                  width: 250,
                },
                status: {
                  width: 100,
                  Cell: r => (<Badge color={r.row.original?.params?.status === 'active' ? 'success' : 'secondary'}>{WVFormatter.capitalize(r.row.original.params?.status)}</Badge>),
                },
                actions: {
                  width: 0,
                },
              }}
              v={2}
            />
          </div>
        </CardBody>
      </Card>
      <ReportModal
        type="employeeLog"
        typeTitle="Employees"
        title="Fill Employees Table"
        modal={showRefreshModal}
        closeDialog={closeModal}
        log={employeeLog}
        loading={loading}
        downloadCSV={downloadCSV}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  
  resources: state.resources,
  employeeLog: state.employeeReducer.logs.employees,
  loading: state.employeeReducer.loading.fillEmployees,
});

export default withStyles({
  root: {
    width: '100%',
    minWidth: '200px',
    paddingRight: '30px',
    position: 'relative',
    marginBottom: '10px',
  },
  close: {
    position: 'absolute',
    bottom: '1px',
    right: '0px',

  },
})(connect(mapStateToProps)(ResourceAction));
