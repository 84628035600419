/* eslint-disable filenames/match-exported */
import React from 'react';
import PropTypes from 'prop-types';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';

const DownloadCSV = ({ items }) => (
  <DownloadCSVButton
    records={items}
    className=""
    fileName="CreatorPerformanceReport.csv"
    csvArrayColumns={[
      {
        label: 'Creator Name',
        func: deal => `${deal.influencer?.first_name || ''} ${deal.influencer?.last_name || ''}`,
      }, {
        label: 'Creator Email',
        func: deal => `${deal.influencer?.email || ''}`,
      }, {
        label: 'Deal Owner',
        func: p => `${p.admin?.firstName || ''} ${p.admin?.lastName || ''}`,
      }, {
        label: 'Brand',
        func: p => `${p?.offer?.advertiser.companyName || ''}`,
      }, {
        label: 'Offer',
        func: p => `${p?.offer?.name || ''}`,
      }, {
        label: 'Deal Type',
        func: p => p.deal?.dealTerms.type_of_deal || '',
      }, {
        label: 'Gross Revenue',
        func: record => (record.totalRevenue || ''),
      }, {
        label: 'Gross Profit',
        func: record => (record.grossProfit || ''),
      }, {
        label: 'Revenue Conversions',
        func: p => Number(p.conversionsArray?.length || 0) + Number(p.cpmConversions?.length || 0),
      }, {
        label: 'Hubspot Contact Url',
        func: p => `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.influencer.hsContactVid}/`,
      },
      {
        label: 'Hubspot Deal Url',
        func: p => `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.deal.hs_object_id}/`,
      }, {
        label: 'Category',
        func: p => p?.influencer?.category || '',
      },
    ]}
  >
    Download CSV
  </DownloadCSVButton>);
DownloadCSV.propTypes = {
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
export default DownloadCSV;
