/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { axios } from 'ApiClient';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import ButtonWithSpinner from '../../../../../../../../shared/components/ButtonWithSpinner';
import withNotice from '../../../../../../../App/store/with-notice';
import Hook from '../../../../../../../../shared/hooks';

const useStyles = makeStyles(() => ({
  formLabel: {
    marginLeft: '20px',
  },
}));

export const modalId = 'SyncContentReleaseDateOrderBulk';

const SyncCreatorModal = ({
  addNotice,
}) => {
  const classes = useStyles();
  const [loadingSyncCreator, setLoadingSyncCreator] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [rightIds, setRightIds] = useState([]);
  const { data: dataModal, showModal: isShow, close: closeModal } = Hook.useModalHook(modalId);
  const { ids } = dataModal;
  useEffect(async () => {
    if (selectAll) {
      setLoading(true);
      const search = new URLSearchParams(window.location.search);
      search.set('perPage', '1000');
      search.set('page', '1');
      console.log(search, search.toString());
      console.log(Object.fromEntries(search));
      // const params = Object.fromEntries(search);
      const data = await axios({
        method: 'get',
        url: `/api/resources/CpmInsertionOrder/actions/list?${search.toString()}`,
      });
      const { records } = data.data;
      console.log(data.data.records);
      setRightIds(records.map(r => r.id));
      setLoading(false);
    } else {
      setRightIds(ids);
    }
  }, [selectAll]);

  useEffect(async () => {
    if (ids?.length > 0) {
      setRightIds(ids);
    }
  }, [ids]);
  if (!ids) return false;
  const close = () => {
    setSelectAll(false);
    setRightIds([]);
    closeModal();
  };
  const deleteCpmCreator = async () => {
    setLoadingSyncCreator(true);
    try {
      for (let i = 0; i < rightIds.length; i += 1) {
        try {
          const id = rightIds[i];
          // eslint-disable-next-line no-await-in-loop
          await axios({
            method: 'post',
            url: '/api/portal/prospects/post-sync-content-release-date-insertion-order',
            data: { insertionOrder: id },
          });
          setProgress(((i + 1) / rightIds.length) * 100);
        } catch (error) {
          console.log(error);
        }
      }
      setLoadingSyncCreator(false);
      addNotice({
        message: 'CPM data was successfully updated with HubSpot deal data',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      addNotice({
        message: `Content Release Date was not updated. Error: ${error.message}`,
        type: 'error',
        duration: 10,
      });
      setLoadingSyncCreator(false);
    }
    close();
  };
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Sync Data from HubSpot Deals
      </ModalHeader>
      <ModalBody className="theme-light">
        {loadingSyncCreator ? '' : `You have selected ${rightIds?.length || 0} Insertion Orders to fetch updated Content Release Dates. This will be saved to the CPM Prospect records only. It will not update any published documents.`}
        {loadingSyncCreator && <LinearProgress variant="determinate" value={progress} />}
        {loadingSyncCreator ? '' : (
          <div className="mt-3">
            <Label className="bold-text">Select all Insertion Orders?</Label>
            <FormGroup>
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Input
                    type="radio"
                    name="handleChangeLogContents"
                    value="1"
                    className="mt-0"
                    checked={selectAll === true}
                    onChange={e => e?.target && setSelectAll(Number(e?.target?.value) === 1)}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Input
                    type="radio"
                    name="handleChangeCondensed"
                    value="0"
                    className="mt-0"
                    checked={selectAll === false}
                    onChange={e => e?.target && setSelectAll(Number(e?.target?.value) === 1)}
                  />
                }
                label="No"
              />
            </FormGroup>
          </div>)}
      </ModalBody>
      <ModalFooter>
        <ButtonWithSpinner
          className="btn-sm"
          type="button"
          color="primary"
          onClick={deleteCpmCreator}
          loading={loadingSyncCreator}
          disabled={loadingSyncCreator || loading}
        >
          <span>Sync</span>
        </ButtonWithSpinner>{' '}
        <Button
          className="btn-sm"
          color="secondary"
          onClick={close}
          disabled={loadingSyncCreator}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

SyncCreatorModal.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

SyncCreatorModal.defaultProps = {

};

export default withNotice(SyncCreatorModal);
