import Record from '../components/components/Record';
import formater from '../helpers/WVFormatter';

export default class Bill extends Record {
  // eslint-disable-next-line class-methods-use-this
  initRecord() {
    return {
      record: {},
      stateName: 'bill',
      resource: 'Bill',
      schema: {
        amount: {
          title: 'Amount',
          required: true,
          // eslint-disable-next-line object-shorthand,func-names
          validate: function (value) {
            const amount = Number(value);
            if (amount < this.getTotalPaid()) {
              throw new Error(`Min value: ${formater.formatCurrency(Number(this.getTotalPaid()))}`);
            }
            return true;
          },
        },
        influencer: {
          title: 'Creator',
          required: true,
        },
        deal: {
          title: 'Deal',
          required: true,
        },
        status: {
          title: 'Status',
          required: true,
        },
        dueDate: {
          title: 'dueDate',
          required: true,
        },
      },
    };
  }
  async updateItemF() {
    const params = this.getSchemaParams();
    const res = await this.api.client.post('/api/portal/influencer/post-edit-bill', {
      ...params,
      isUpdateAmount: this.params.isUpdateAmount,
      _id: this.id,
    });
    if (res.data.success === true) {
      return this.getModel();
    }
    throw new Error('Undable to update Bill record');
  }
  async newItemF() {
    const data = this.paramsToForm();
    data.set('dueDate', this.get('dueDate').toString());
    const record = await this.api.resourceAction({
      resourceId: this.resourceName,
      actionName: 'new',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (!record.data.record.id) {
      throw new Error(record.data.notice.message);
    }
    if (this.params.isUpdateAmount) {
      await this.api.client.post('/api/portal/influencer/post-edit-bill', {
        _id: this.id,
        isUpdateAmount: this.params.isUpdateAmount,
      });
    }
    return record.data.record;
  }
  getTotalPaid() {
    let payoutAmount = 0;
    if (!this.params.payments) {
      return 0;
    }
    this.params.payments.forEach((payout) => {
      if (payout.transactionStatus === 'failure') { return false; }
      payoutAmount += Number(payout.amount);
      return true;
    });
    return payoutAmount;
  }
}
