import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

const Search = ({
  placeholder,
  value,
  onChange,
}) => {
  const [term, setTerm] = useState(value);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => { setTerm(value); }, [value]);

  const updateValue = (newValue) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(setTimeout(() => {
      onChange(newValue);
    }, 1000));
  };

  return (
    <InputGroup>
      <Input
        className="form-control-sm form-control"
        name="search"
        type="search"
        autoComplete="off"
        placeholder={placeholder}
        value={term}
        onChange={(p) => { setTerm(p.target.value); updateValue(p.target.value); }}
        style={{ height: '42px' }}
      />
      <InputGroupAddon addonType="append">
        <InputGroupText>
          <i
            className="fa fa-search"
            aria-hidden="true"
            style={{ color: '#70bbfd', fontSize: 12 }}
          />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Search;
