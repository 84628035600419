/* eslint react/prop-types: 0 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import ApiClient from 'ApiClient';
import { Card, CardBody } from 'reactstrap';
// import WrapperBox from '../ui/wrapper-box';
import withNotice from '../../../../containers/App/store/with-notice';
import RecordsTable from '../app/records-table/records-table';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.handleActionPerformed = this.handleActionPerformed.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.state = {
      records: [],
      page: 1,
      perPage: 20,
      total: 0,
      loading: true,
      direction: 'asc',
      sortBy: undefined,
      selectedRecords: [],
    };
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  shouldComponentUpdate(newProps) {
    const { resource, location } = this.props;
    if (resource.id !== newProps.resource.id
       || location.search !== newProps.location.search) {
      this.fetchData(newProps);
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    const { setTag } = this.props;
    if (setTag) {
      setTag('');
    }
  }

  fetchData(props) {
    const {
      location, resource, setTag, addNotice,
    } = props;
    const { resource: oldResource } = this.props;
    const { selectedRecords } = this.state;

    const api = new ApiClient();
    this.setState({ loading: true });
    const query = new URLSearchParams(location.search);
    api.resourceAction({
      actionName: 'list',
      resourceId: resource.id,
      params: query,
    }).then((response) => {
      const listActionReponse = response.data;
      this.setState({
        records: listActionReponse.records,
        page: listActionReponse.meta.page,
        perPage: listActionReponse.meta.perPage,
        total: listActionReponse.meta.total,
        direction: listActionReponse.meta.direction,
        sortBy: listActionReponse.meta.sortBy,
        selectedRecords: oldResource.id === resource.id ? selectedRecords : [],
        loading: false,
      });
      if (setTag) {
        if (typeof response.data.meta.total === 'undefined') {
          setTag('');
        } else {
          setTag(response.data.meta.total.toString());
        }
      }
    }).catch(() => {
      addNotice({
        message: 'There was an error fetching records, Check out console to see more information.',
        type: 'error',
      });
    });
  }

  handleActionPerformed() {
    this.fetchData(this.props);
  }

  handleSelect(record) {
    const { selectedRecords } = this.state;
    const selectedIndex = selectedRecords.findIndex(selected => selected.id === record.id);
    if (selectedIndex < 0) {
      this.setState({ selectedRecords: [...selectedRecords, record] });
    } else {
      const newSelectedRecords = [...selectedRecords];
      newSelectedRecords.splice(selectedIndex, 1);
      this.setState({ selectedRecords: newSelectedRecords });
    }
  }

  handleSelectAll() {
    const { records, selectedRecords } = this.state;

    const missing = records.filter(record => (
      !selectedRecords.find(selected => selected.id === record.id)
      && record.bulkActions.length
    ));
    if (missing.length) {
      this.setState({ selectedRecords: [...selectedRecords, ...missing] });
    } else {
      const newSelectedRecords = selectedRecords.filter(selected => (
        !records.find(record => record.id === selected.id)
      ));
      this.setState({ selectedRecords: newSelectedRecords });
    }
  }

  render() {
    const { resource } = this.props;
    const {
      records,
      loading, direction, sortBy, selectedRecords,
    } = this.state;
    return (
      <Card>
        <CardBody>
          <RecordsTable
            resource={resource}
            records={records}
            actionPerformed={this.handleActionPerformed}
            onSelect={this.handleSelect}
            onSelectAll={this.handleSelectAll}
            selectedRecords={selectedRecords}
            direction={direction}
            sortBy={sortBy}
            isLoading={loading}
          />
        </CardBody>
      </Card>
    );
  }
}

export default withNotice(withRouter(List));
