import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { unflatten } from 'flat';
import { axios } from 'ApiClient';
import { getConstant } from '../../../../../../../../shared/helpers/WVConstants';

const DealStage = React.memo(({
  p,
}) => {
  const dealstages = (getConstant('deal', 'pipelines').find(a => a.label === 'Main Pipeline')).stages;
  const { updateRecordFunction } = p;

  const rowData = p.row.original.params;
  const val = rowData.dealObject?.dealstage;
  // console.log(val, rowData, 'valueJustification');
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setValue(val);
  }, [rowData._id, val]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option.value);
    try {
      const data = await axios.post('/api/portal/prospects/post-update-property-on-cpm-prospect', {
        ids: [rowData._id],
        values: {
          dealStage: option.value,
        },
      });
      const record = unflatten(data?.data?.recordPopulated);
      updateRecordFunction(rowData._id, { dealObject: { ...rowData.dealObject, dealstage: option.value } });
      setValue(record.params.dealJustification);
    } catch (e) {
      console.log(e);
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };
  return (
    <div className="w-100">
      <Select
        value={value ? { value, label: dealstages[value] } : null}
        options={Object.keys(dealstages).map(a => ({ value: a, label: dealstages[a] }))}
        onChange={handleChange}
        className="statusBox"
        placeholder="Dealstage"
        isDisabled={Boolean(!rowData.dealObject) || disabled}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
      {error ? (<span className="text-danger">{error}</span>) : null}
    </div>
  );
}, (a, a1) => _.isEqual(a.p, a1.p));

DealStage.propTypes = {
  p: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DealStage;
