/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { Modal, ModalHeader, Button, ButtonToolbar, Input, Col, Row, FormGroup } from 'reactstrap';
import { axios } from 'ApiClient';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';


const AddInfluencerById = (props) => {
  const {
  //  match,
    errorFunc,
    saveAffiliate,
    hideModal,
    modalInfluencer,
  } = props;
  const [getLoadingInfluencer, setGetLoadingInfluencer] = useState(false);
  const [affiliate, setAffiliate] = useState(null);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [searchColumnSelected, setSearchColumn] = useState('tuneId' || null);
  const visibleColumns = { tuneId: 'Tune ID', email: 'Email' };
  const selectColumn = (e) => {
    setSearchColumn(e.target.value);
  };
  const influencersCall = () => {
    setError(null);
    setGetLoadingInfluencer(true);
    axios.post('/api/portal/finance/post-fill-influencer', { value, searchColumnSelected }).then((res) => {
      setGetLoadingInfluencer(false);
      if (res.data.affiliateUser) {
        const user = res.data.affiliateUser;
        setAffiliate(user);
      } else {
        setError(`Unable To find affiliate by ${visibleColumns[searchColumnSelected]}: ${value}`);
      }
    }).catch(() => {
      setGetLoadingInfluencer(false);
      errorFunc('Unable to get Affiliate by API');
    });
  };
  const hideModalFunc = (e) => {
    setAffiliate(null);
    setValue(null);
    setError(null);
    setSearchColumn('tuneId');
    hideModal(e);
  };
  return (
    <Modal
      isOpen={modalInfluencer}
      toggle={hideModalFunc}
      size="sm"
    >
      <ModalHeader
        toggle={hideModalFunc}
        tag="h4"
      >
        Find Influencer by Tune Id
      </ModalHeader>
      <div className="modal__body pt-4">
        <Row>
          <Col>
            <FormGroup style={{ minWidth: '200px' }}>
              <Input onChange={e => setValue(e.target.value)} placeholder={`Affiliate ${visibleColumns[searchColumnSelected]}`} bsSize="sm" />
            </FormGroup>
          </Col>
          <Col>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              bsSize="sm"
              onChange={selectColumn}
              defaultValue=""
            >
              {Object.keys(visibleColumns).map(key => (<option value={key} key={key}>{visibleColumns[key]}</option>))}
            </Input>
          </Col>
        </Row>
        {error ? (
          <Alert color="warning" icon bordered><p className="py-2">{error}</p></Alert>
        ) : null}
        {affiliate && value ? (<h4><strong>{`${affiliate.first_name || ''} ${affiliate.last_name || ''} ( ${affiliate.email || ''} )`}</strong><br /><small>Click `Save` to add this user to DB</small></h4>) : null}
      </div>

      <ButtonToolbar className="modal__footer">
        <Button disabled={!value || getLoadingInfluencer} onClick={influencersCall} color="primary" className="mr-2">Find Affiliate</Button>
        {affiliate && value && (
          <Button
            className="modal_cancel btn-sm mr-2"
            color="primary"
            onClick={(e) => {
              saveAffiliate(affiliate.affiliate_id);
              hideModalFunc(e);
            }}
          >
            Save
          </Button>
        )}
        <Button className="modal_cancel btn-sm" color="secondary" onClick={hideModalFunc}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};


export default AddInfluencerById;
