import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addNotice } from '../../../App/store/store';
import HubilContactsListContainer from './components/HubilContactsListContainer';

const ResourceAction = (props) => {
  const {
    history,
    location,
  } = props;
  const [tag, setTag] = useState('');
  return (<HubilContactsListContainer
    filterVisible
    tag={tag}
    setTag={setTag}
    date={new Date()}
    history={history}
    location={location}
    addNotice={addNotice}
  />
  );
};
ResourceAction.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
