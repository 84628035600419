import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';

const AiSentimentFilter = ({
  aiSentimentFilterCheck,
  handleChangeAiSentimentCheckFilter,
  aiSentimentPositiveScore,
  setAiSentimentPositiveScore,
  aiSentimentNegativeScore,
  setAiSentimentNegativeScore,
  aiSentimentIgnoreScore,
  setAiSentimentIgnoreScore,
}) => (
  <FormGroup>
    <Label className="bold-text">
      AI Sentiment&nbsp;
    </Label>
    <Row>
      <Col md={2}>
        <CheckBoxField
          name="AiSentimentPositive"
          label="Positive"
          value={aiSentimentFilterCheck.positive}
          onChange={event => handleChangeAiSentimentCheckFilter(event, 'positive')}
        />
      </Col>
      <Col md={7}>
        <Slider
          min={0}
          max={1}
          value={aiSentimentPositiveScore}
          onChange={(event, value) => setAiSentimentPositiveScore(value)}
          marks={[
            { value: 0, label: '0' },
            { value: 0.25, label: '.25' },
            { value: 0.5, label: '0.5' },
            { value: 0.75, label: '.75' },
            { value: 1, label: '1' },
          ]}
          step={0.01}
          track="normal"
          valueLabelDisplay="auto"
          disabled={!aiSentimentFilterCheck.positive}
        />
      </Col>
    </Row>
    <Row>
      <Col md={2}>
        <CheckBoxField
          name="AiSentimentNegative"
          label="Negative"
          // className="inline ml-4"
          value={aiSentimentFilterCheck.negative}
          onChange={event => handleChangeAiSentimentCheckFilter(event, 'negative')}
        />
      </Col>
      <Col md={7}>
        <Slider
          min={0}
          max={1}
          value={aiSentimentNegativeScore}
          onChange={(event, value) => setAiSentimentNegativeScore(value)}
          marks={[
            { value: 0, label: '0' },
            { value: 0.25, label: '.25' },
            { value: 0.5, label: '0.5' },
            { value: 0.75, label: '.75' },
            { value: 1, label: '1' },
          ]}
          step={0.01}
          track="normal"
          valueLabelDisplay="auto"
          disabled={!aiSentimentFilterCheck.negative}
        />
      </Col>
    </Row>
    <Row>
      <Col md={2}>
        <CheckBoxField
          name="AiSentimentIgnore"
          label="Ignore"
          // className="inline ml-4"
          value={aiSentimentFilterCheck.ignore}
          onChange={event => handleChangeAiSentimentCheckFilter(event, 'ignore')}
        />
      </Col>
      <Col md={7}>
        <Slider
          min={0}
          max={1}
          value={aiSentimentIgnoreScore}
          onChange={(event, value) => setAiSentimentIgnoreScore(value)}
          marks={[
            { value: 0, label: '0' },
            { value: 0.25, label: '.25' },
            { value: 0.5, label: '0.5' },
            { value: 0.75, label: '.75' },
            { value: 1, label: '1' },
          ]}
          step={0.01}
          track="normal"
          valueLabelDisplay="auto"
          disabled={!aiSentimentFilterCheck.ignore}
        />
      </Col>
    </Row>
  </FormGroup>
);

AiSentimentFilter.propTypes = {
  aiSentimentFilterCheck: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeAiSentimentCheckFilter: PropTypes.func.isRequired,
  aiSentimentPositiveScore: PropTypes.arrayOf(PropTypes.any).isRequired,
  setAiSentimentPositiveScore: PropTypes.func.isRequired,
  aiSentimentNegativeScore: PropTypes.arrayOf(PropTypes.any).isRequired,
  setAiSentimentNegativeScore: PropTypes.func.isRequired,
  aiSentimentIgnoreScore: PropTypes.arrayOf(PropTypes.any).isRequired,
  setAiSentimentIgnoreScore: PropTypes.func.isRequired,
};

export default AiSentimentFilter;
