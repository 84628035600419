/* eslint-disable filenames/match-exported */
import styled from 'styled-components';
import theme from '../../../../../../theme';

const Th = styled.th`
  &&& {
    font-size: ${theme.fonts.min};
    text-transform: uppercase;
    color: ${theme.colors.lightText};
    font-weight: normal;
    padding: ${theme.sizes.padding};
    letter-spacing: 0.1em;
    border: none;
  }
`;

export default Th;
