import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CopyToClipboardLeft from '../../../../shared/components/CopyToClipboardLeft';
import { CopyIcon } from '../../../../shared/helpers/Icons';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

const truncateStyle = {
  maxWidth: '350px',
  fontSize: '14px',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
};

const TextInList = React.memo(({ record }) => {
  return (
    <div className="d-flex">
      <div
        className="block-in-cell"
        style={{ display: 'inline' }}
      >
        <Tooltip
          title={record.textOriginal}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="ml-0"
            style={truncateStyle}
            href={`https://www.youtube.com/watch?v=${record.videoId}&lc=${record.id}`}
          >
            {record.textOriginal}
          </a>
        </Tooltip>
      </div>
      <div
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '5px',
        }}
      >
        <CopyToClipboardLeft
          value={record.textOriginal}
          icon={(<CopyIcon />)}
          className="text-blue"
          title="Copy text"
        />
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

TextInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TextInList;
