import React, { useState, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Row, Col, Container, Card, CardBody, CardTitle, Label, Button} from 'reactstrap';
import Filter from '../../../../shared/components/filter/TimePeriodFilter';
import Errors from '../ErrorsV2';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import Column from './ColumnsCreators';
import Actions from './Actions';
import LoadingReport from "../../../../shared/components/LoadingReport";


const NewCreatorsReport2 = ({ addNotice }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoaing] = useState(false);

  const [startDate, setStartDate] = useState(moment().subtract(3, 'month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState((moment().endOf('day')).format('YYYY-MM-DD'));

  const [errors, setErrors] = useState([]);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [fetched, setFetched] = useState(false);

  const [searchValue, onSearchChange] = useState();
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedDealType, setSelectedDealType] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const filterCpaConversions = (conversions, startMoment, endMoment) => {
    console.log(conversions);
    return conversions.filter((c) => {
      const time = moment.tz(moment.utc(c.datetime).format('YYYY-MM-DD'), 'America/New_York');
      return time >= startMoment && time < endMoment;
    });
  };
  const filterCpmConversions = (conversions, startMoment, endMoment) => {
    console.log(conversions);
    return conversions.filter((c) => {
      const time = moment(c.activityDatetime);
      return time >= startMoment && time < endMoment;
    });
  };
  const fetchAmountReport = async () => {
    setLoaing(true);
    setItems([]);
    try {
      const resp = await axios.post('/api/portal/reports/post-new-creator-report', {
        startDate,
        endDate,
        brands: selectedAdvertisers?.map(a => a.value),
        identity,
      });
      console.log(resp.data);

      if (resp.data.success && resp.data.creators) {
        const itemsForRes = resp.data.creators.map((deal) => {
          const ret = { ...deal };
          ret.startPublishDate = deal.publishDate;
          if (ret.dealsCount.length > 1) {
            let minPublishDate = null;
            ret.dealsCount.forEach((d) => {
              if (d._id !== deal.latestDeal._id && ret.startPublishDate < d.publishDate && (!minPublishDate || minPublishDate > d.publishDate)) {
                minPublishDate = d.publishDate;
              }
            });
            ret.endPublishDate = minPublishDate || moment.tz(endDate, 'America/New_York').endOf('day').toISOString();
            ret.endPublishDateTotal = minPublishDate || moment.tz('America/New_York').endOf('day').toISOString();
          } else {
            ret.endPublishDate = moment.tz(endDate, 'America/New_York').endOf('day').toISOString();
            ret.endPublishDateTotal = moment.tz('America/New_York').endOf('day').toISOString();
          }
          ret.allConversions = resp.data.conversionsByBrandByCreator[`${deal.creator._id}-${deal.offer.advertiser._id}`] || [];
          ret.cpaCurrent = filterCpaConversions(ret.allConversions.CPApaidConversionsArray, moment(ret.startPublishDate), moment(ret.endPublishDate));
          ret.cpaTotal = filterCpaConversions(ret.allConversions.CPApaidConversionsArray, moment(ret.startPublishDate), moment(ret.endPublishDateTotal));
          ret.cpmCurrent = filterCpmConversions(ret.allConversions.CPMpaidConversions, moment(ret.startPublishDate), moment(ret.endPublishDate));
          ret.cpmTotal = filterCpmConversions(ret.allConversions.CPMpaidConversions, moment(ret.startPublishDate), moment(ret.endPublishDateTotal));

          // eslint-disable-next-line no-param-reassign, max-len
          ret.revenue = ret.latestDeal.dealTerms.type_of_deal === 'Upfront CPM' && ret.offer.advertiser.companyName === 'BetterHelp' ? ret.latestDeal.dealTerms.bh_cpm_total_cost : ret.cpaCurrent.reduce((p, c) => { p += Number(c.revenue || 0); return p; }, 0);
          // eslint-disable-next-line no-param-reassign
          ret.payout = ret.latestDeal.dealTerms.guaranteed_payment_contract_amount + ret.cpaCurrent.reduce((p, c) => { p += Number(c.payout || 0); return p; }, 0);
          // eslint-disable-next-line no-param-reassign
          ret.mediaContentsCount = ret.dealsCount.reduce((prev, next) => { prev += next.mediaContents?.length || 0; return prev; }, 0);

          return ret;
        });
        console.log(itemsForRes);
        setItems(itemsForRes);
        setFetched(true);
        setLoaing(false);
        setErrors([]);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
      setFetched(false);
      setLoaing(false);
      setErrors([]);
    }
    return true;
  };
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40, 100],
    placeholder: 'Search...',
    sortBy: 'publishDate',
    direction: 'desc',
    useFlex: true,
  };

  // useEffect(() => {
  //   fetchAmountReport();
  // }, [startDate, endDate, selectedAdvertisers]);

  const filteredItems = useMemo(() => {
    console.log('start fill', items);
    const itemsToReturn = items.filter((deal) => {
      let allow = true;
      if (allow) {
        allow = !deal.contractSent;
      }
      if (allow) {
        const { mediaContents } = deal;
        mediaContents.sort((a, b) => (moment.utc(a.publishDate) > moment.utc(b.publishDate) ? 1 : -1));
        allow = moment.utc(mediaContents[0].publishDate) >= moment.utc(startDate) && moment.utc(mediaContents[0].publishDate) < moment.utc(endDate);
        if (allow) {
          // eslint-disable-next-line no-param-reassign
          deal.firstPublishDate = mediaContents[0].publishDate;
          // eslint-disable-next-line no-param-reassign
          deal.latestDeal = deal.deals.find(d => String(d._id) === String(mediaContents[0].deal));
        }
      }
      const name = `${deal?.creator?.first_name} ${deal?.creator?.last_name} ${deal?.creator?.email}`;
      if (allow && searchValue) {
        allow = name.toLowerCase().includes(searchValue.trim().toLowerCase());
      }

    if (allow && selectedEmployee && selectedEmployee.length > 0) {
        allow = selectedEmployee.map(a => String(a.value)).includes(String(deal?.adminAF?._id));
      }
      if (allow && selectedDealType && selectedDealType.length > 0) {
        allow = selectedDealType.map(a => String(a.value)).includes(String(deal?.latestDeal?.dealTerms.type_of_deal));
      }
      return allow;
    });
    // const advertisers = selectedAdvertisers?.map(a => String(a.value)) || [];
    // const offers = selectedAdvertisers?.flatMap(a => a.offers) || [];
    //   .reduce((prev, next) => { const a = prev.concat(next || []); return a; }, []) || [];
    // console.log(offers, 'OFFERS');
    // console.log(selectedAdvertisers, 'OFFERS a');
    return itemsToReturn.map((res) => {
      const a = { ...res };
      // a.mediaContentCount = advertisers?.length > 0 ? a.mediaContents?.filter(d => advertisers.includes(String(d.advertiser))).length : a.mediaContents.length;
      return a;
    });
  }, [items, selectedEmployee, selectedDealType, searchValue]);
  const columns = Column();
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'New Creator Performance', path: false },
            ]}
          />
        </Col>
      </Row>
      {!loading && fetched && errors.length > 0 ? (
        <Errors errors={errors} startDate={startDate} endDate={endDate} />
      ) : null }
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3 className="d-inline">New Creator Performance</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3" >
                <Col>
                  <Label className="bold-text">Publish Date</Label>
                  <Filter
                    startDateInitial={moment(startDate).toDate()}
                    endDateInitial={moment(endDate).endOf('day').toDate()}
                    selectedButtonInitial="last_3_month"
                    setValue={(param, value) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                    usedForConversions
                    isLoading={!!loading}
                    options={[
                      { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
                      { value: 'last_month', label: 'Last Month' },
                      { value: 'year_to_date', label: 'Year to Date' },
                      { value: 'last_3_month', label: 'Last 3 Months' },
                      { value: 'last_6_month', label: 'Last 6 Months' },
                      { value: 'last_2_years', label: 'All Time' },
                      {
                        value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
                      },
                    ]}
                    selectDateRangeFunction={(range) => {
                      let dateFrom;
                      let dateTo;
                      let showCustom = false;
                      switch (range) {
                        case 'current_month':
                          showCustom = false;
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = (moment().endOf('day')).toDate();
                          break;
                        case 'last_month':
                          showCustom = false;
                          dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
                          dateTo = moment().subtract(1, 'month').endOf('month').toDate();
                          break;
                        case 'last_3_month':
                            showCustom = false;
                            dateFrom = moment().subtract(3, 'month').startOf('day').toDate();
                            dateTo = (moment().endOf('day')).toDate();
                            break;
                        case 'last_6_month':
                          showCustom = false;
                          dateFrom = moment().subtract(6, 'month').startOf('day').toDate();
                          dateTo = (moment().endOf('day')).toDate();
                          break;
                        case 'year_to_date':
                          showCustom = false;
                          dateFrom = moment().startOf('Year').toDate();
                          dateTo = (moment().endOf('day')).toDate();
                          break;
                        case 'last_2_years':
                          showCustom = false;
                          dateFrom = moment().subtract(24, 'months').toDate();
                          dateTo = (moment().endOf('day')).toDate();
                          // dateFrom = usedForConversions ? moment().subtract(24, 'months').toDate() : moment(allTimeStartDate || '2019-01-01').startOf('day').toDate();
                          // dateTo = moment().endOf('day').toDate();
                          break;
                        case 'custom':
                          showCustom = true;
                          dateFrom = moment().startOf('Year').toDate();
                          dateTo = moment().subtract(1, 'day').endOf('day').toDate();
                          break;
                        default:
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = moment().subtract(1, 'day').endOf('day').toDate();
                          break;
                      }
                      console.log({ dateFrom, dateTo, showCustom });
                      return { dateFrom, dateTo, showCustom };
                    }
                  }
                  />
                </Col>
                <Col lg="auto">
                  <div style={{ paddingTop: '30px' }}>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchAmountReport();
                        return false;
                      }}
                      className="my-0 btn-sm"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {/* loading ? (
                <Row className="mt-3">
                  <Col className="text-center">
                    <Spinner size="lg" />
                  </Col>
                </Row>) : null
              */}
              {!loading && fetched ? (
                <Row className="mt-3">
                  <Col>
                    <Actions
                      selectedEmployee={selectedEmployee}
                      setSelectedEmployee={setSelectedEmployee}
                      selectedAdvertiser={selectedAdvertisers}
                      setSelectedAdvertiser={setSelectedAdvertisers}
                      setSelectedDealType={setSelectedDealType}
                      selectedDealType={selectedDealType}
                      onSearchChange={onSearchChange}
                      items={filteredItems}
                    />
                  </Col>
                </Row>) : null
              }
              {!loading && fetched ? (
                <Row className="mt-3">
                  <Col>
                    <ReactTableBase
                      key="searchable"
                      columns={columns}
                      data={filteredItems}
                      tableConfig={tableConfig}
                    />
                  </Col>
                </Row>) : null
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
NewCreatorsReport2.propTypes = {
  addNotice: PropTypes.func.isRequired,
  // dispatch: PropTypes.func.isRequired,
};
export default withNotice(NewCreatorsReport2);
