import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { formatIntNumber } from '../../../../shared/helpers/WVFormatter';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
import SentimentInList from './SentimentInList';
import RelevancyInList from './RelevancyInList';
import ContextMenu from './ContextMenu';
import TextInList from './TextInList';
import { getUserTimezoneAbbreviation } from '../../../../shared/helpers/WVUtilities';
import LoadingReport from '../../../../shared/components/LoadingReport';

const userTimezone = getUserTimezoneAbbreviation();

const getCommentDate = (date) => {
  return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('M/D/YYYY');
}

const csvArrayColumnsVideos= [
  {
    label: 'Comment',
    func: record => (record.textOriginal || ''),
  },
  {
    label: 'Sentiment',
    func: record => (record.sentiment || ''),
  },
  {
    label: 'Brand Relevancy',
    func: record => (record.relevancy || ''),
  },
  {
    label: `Comment Date ${userTimezone}`,
    func: record => getCommentDate(record.publishedAt),
  },
  {
    label: 'No. Likes',
    func: record => record.likeCount || 0,
  },
];

const tableConfig = {
  isEditable: false,
  isResizable: false,
  useFlex: true,
  isSortable: true,
  withPagination: true,
  withSearchEngine: false,
  manualPageSize: [10, 20, 30, 40],
  sortBy: 'publishedAt',
  direction: 'desc',
};

const columnTransform = (mediaContentId, mediaUrl, influencerId) => [
  {
    Header: 'Comment',
    id: 'textOriginal',
    disableSortBy: true,
    accessor: record => (record.textOriginal || ''),
    Cell: record => (
      <div className="w-100">
        <TextInList record={record.row.original}/>
      </div>
    ),
    width: 350,
  },
  {
    Header: 'Sentiment',
    id: 'sentiment',
    accessor: record => (record.sentiment || '-'),
    Cell: value => (
      <SentimentInList
        label={value.row.original.sentiment || ''}
      />),
    width: 120,
  },
  {
    Header: 'Brand Relevancy',
    id: 'relevancy',
    accessor: record => (record.relevancy || '-'),
    Cell: value => (
      <RelevancyInList
        label={value.row.original.relevancy || ''}
      />),
    width: 160,
  },
  {
    Header: `Comment Date (${userTimezone})`,
    id: 'publishedAt',
    accessor: record => (record.publishedAt || 0),
    Cell: value => getCommentDate(value.row.original.publishedAt),
    width: 160,
  },
  {
    Header: 'No. Likes',
    id: 'likeCount',
    accessor: record => (record.likeCount || 0),
    Cell: value => formatIntNumber(value.row.original.likeCount || 0),
    width: 80,
  },
  {
    Header: '',
    id: 'contextMenu',
    disableSortBy: true,
    accessor: null,
    Cell: () => (
      <ContextMenu
        mediaContentId={mediaContentId}
        mediaUrl={mediaUrl}
        influencerId={influencerId}
      />),
    width: 50,
  },
];

const VideoCommentsTable = ({
  data,
  mediaContentId,
  mediaUrl,
  influencerId,
  searchText,
  filtersCommentSentiment,
  filtersBrandRelevant,
}) => {
  const [filtering, setFiltering] = useState(false);
  const [version, setVersion] = useState((new Date()));
  const [filteredData, setFilteredData] = useState(data);

  const filterTableData = () => {
    let filtered = [...data];
    setFilteredData([]);

    if (searchText) {
      const regex = new RegExp(searchText, 'i');
      filtered = filtered.filter(record => regex.test(record.textOriginal));
    }

    const fcs = Object.keys(filtersCommentSentiment).filter(key => filtersCommentSentiment[key] === true);
    if (fcs?.length > 0 && fcs.length < 3) {
      filtered = filtered.filter(record => fcs.includes(record.sentiment));
    }

    const fbr = Object.keys(filtersBrandRelevant).filter(key => filtersBrandRelevant[key] === true);
    if (fbr?.length > 0 && fbr.length < 2) {
      filtered = filtered.filter(record => fbr.includes(record.relevancy));
    }

    setFilteredData(filtered);
    setFiltering(false);
  };

  useEffect(() => {
    setFiltering(true);
    filterTableData();
  }, [
    data,
    searchText,
    filtersCommentSentiment,
    filtersBrandRelevant,
  ]);

  useEffect(() => {
    setVersion((new Date()));
  }, [filteredData]);

  return (
    <Row className="mt-3">
      <Col>
        {data.length ? (
          <>
            <div
              style={{
                position: 'absolute',
                right: '15px',
                top: '-138px'
              }}
            >
              <DownloadCSVButton
                className=""
                records={filteredData}
                fileName={`Video Comments.csv`}
                csvArrayColumns={csvArrayColumnsVideos}
              >
                Download CSV
              </DownloadCSVButton>
            </div>
            <div className={filtering ? 'd-none' : ''}>
              <ReactTableBase
                key={version}
                columns={columnTransform(mediaContentId, mediaUrl, influencerId)}
                data={filteredData}
                tableConfig={tableConfig}
                disableSortRemove
              />
            </div>
            {filtering && (
              <Row>
                <Col>
                  <LoadingReport
                    loading={filtering}
                    title="Filtering comments..."
                    description="We will be done shortly."
                  />
                </Col>
              </Row>
            )}
          </>
        ) : (
          <span className="mt-2">No data to show</span>
        )}
      </Col>
    </Row>
  );
}

VideoCommentsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  mediaContentId: PropTypes.string,
  mediaUrl: PropTypes.string,
  influencerId: PropTypes.string,
};

VideoCommentsTable.defaultProps = {
  data: [],
  mediaContentId: '',
  mediaUrl: '',
  influencerId: '',
};

export default VideoCommentsTable;
