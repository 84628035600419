import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';
import FormulasListContainer from './components/FormulasListContainer';

const FormulasList = () => (
  <Container className="dashboard">
    <Breadcrumb
      links={[
        { title: 'Home', path: '/' },
        { title: 'Research', path: null },
        { title: 'Formulas', path: null },
      ]}
      isBackButton
      backButtonUrl="/research/creators"
    />
    <Row>
      <Col md={12}>
        <FormulasListContainer />
      </Col>
    </Row>
  </Container>
);

export default FormulasList;
