import React from 'react';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import moment from 'moment';

const PublishDateInList = ({
  record,
}) => {
  const videoStatistic = unflatten(record).params;
  const value = videoStatistic?.publishedAt?.split('T')[0] || false;

  return (<span>{value ? moment(value).format('MM/DD/YYYY') : 'n/a'}</span>);
};

PublishDateInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PublishDateInList;
