import React from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import withNotice from '../../../App/store/with-notice';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import NonMatchedVendorsTable from './components/NonMatchedVendorsTable';
import MatchedVendorsTable from './components/MatchedVendorsTable';

class ImportVendors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingFetch: false,
      matchedAccounts: [],
      nonMatchedAccounts: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ loadingFetch: true });

    axios.post('/api/portal/billCom/match-vendors').then((response) => {
      if (response.data.success) {
        const { matchedAccounts, nonMatchedAccounts } = response.data;

        // set ids for matched account object for correct work with UI component
        const matchedAccountsWithIds = matchedAccounts.map((_account) => {
          const account = _account;
          account.id = account.vendor.id;
          return account;
        });
        //

        this.setState({
          matchedAccounts: matchedAccountsWithIds,
          nonMatchedAccounts,
          loadingFetch: false,
        });
        return;
      }

      this.processError('fetch', response.data.error);
    }).catch((error) => {
      this.processError('fetch', error.message);
    });
  }

  processError(errorName, errorMessage) {
    const message = errorMessage || 'Something went wrong!';

    this.setState({ [errorName]: false });

    this.props.addNotice({
      message,
      type: 'error',
    });
  }

  render() {
    const {
      loadingFetch,
      matchedAccounts,
      nonMatchedAccounts,
    } = this.state;

    return (
      <Container className="dashboard">
        <Breadcrumb
          links={[
            { title: 'Bill.com Dashboard', path: '/billCom/dashboard' },
            { title: 'Import New Vendors', path: null },
          ]}
          isBackButton
        />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Import New Vendors</h5>
                  <h5 className="subhead">Connect matched by email vendors to IL influencers</h5>
                </div>
                <MatchedVendorsTable
                  loading={loadingFetch}
                  accounts={matchedAccounts}
                  addNotice={this.props.addNotice}
                  key={matchedAccounts.length ? matchedAccounts[0].vendor.id : 0}
                />
                <div className="py-4" />
                <NonMatchedVendorsTable
                  loading={loadingFetch}
                  accounts={nonMatchedAccounts}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

ImportVendors.propTypes = {
  // history: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(ImportVendors);
