import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import _ from 'lodash';

const ContactOwner = React.memo(({
  record,
  afterSaveFunc,
  adminUsers,
  updateRecordFunction,
  loadingAdminUsers,
}) => {
  const val = (typeof record.populated.ownerUser !== 'undefined' && record.populated.ownerUser) ?
    { value: record.populated.ownerUser.id, label: `${record.populated.ownerUser.params.firstName} ${record.populated.ownerUser.params.lastName}` } :
    { value: null, label: '-- Not Assigned --' };
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    setValue(val);
    setDisabled(false);
  }, [record.id, record.populated?.ownerUser?.id]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option);
    try {
      const data = await axios.post('/api/portal/prospects/post-update-admin-user', {
        prospectIds: [record.id],
        adminUserId: option.value,
      });
      if (data?.data?.prospectPopulated && updateRecordFunction) {
        updateRecordFunction(data?.data?.prospectPopulated);
      } else {
        afterSaveFunc();
      }
    } catch (e) {
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };

  return (
    <div style={{ minWidth: '155px', paddingRight: '5px' }} >
      <Select
        isLoading={loadingAdminUsers}
        value={value}
        options={adminUsers}
        className="contactOwnerSelector"
        onChange={handleChange}
        placeholder=""
        isDisabled={disabled}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
      {error ? (<span className="text-danger">{error}</span>) : null}
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record)
  && _.isEqual(a.adminUsers, a1.adminUsers)
  && a.loadingAdminUsers === a1.loadingAdminUsers);

ContactOwner.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  afterSaveFunc: PropTypes.func.isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
  loadingAdminUsers: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  loadingAdminUsers: state.ResearchReducer.loading.prospectsGrid,
  adminUsers: state.ResearchReducer.adminUsers,
});

export default connect(mapStateToProps)(ContactOwner);
