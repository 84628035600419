import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label, Input, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import CloseIcon from 'mdi-react/CloseIcon';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withNotice from '../../../App/store/with-notice';
import StyledButton from '../../../../shared/components/components/ui/styled-button';
import {
  addQuestionToScorecardCategory,
  deleteQuestionFromScorecardCategory,
} from '../../../../redux/reducers/admin/ScoringReducer';

class ScorecardsQuestionCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      newQuestion: {
        params: {
          questionType: 'Scored',
        },
        errors: {},
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitQuestion = this.handleSubmitQuestion.bind(this);
    this.handleCancelForm = this.handleCancelForm.bind(this);
    this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
    this.showForm = this.showForm.bind(this);
    this.handleRadioInputChange = this.handleRadioInputChange.bind(this);
  }
  static requiredScoredData = [
    'question', 'minVal', 'maxVal', 'scale',
  ];
  static requiredBinaryData = [
    'question',
  ];

  showForm() {
    this.setState({
      showForm: true,
    });
  }

  handleChange(name, event) {
    const { newQuestion } = this.state;
    newQuestion.params[name] = event.target.value;
    this.setState({
      newQuestion,
    });
  }

  handleRadioInputChange(name, value) {
    const { newQuestion } = this.state;
    newQuestion.params[name] = value;
    this.setState({
      newQuestion,
    });
  }

  handleSubmitQuestion() {
    const { newQuestion } = this.state;
    const { questionCategory } = this.props;
    newQuestion.errors = {};
    let isValid = true;
    const requiredData = newQuestion.params.questionType === 'Scored' ? this.constructor.requiredScoredData : this.constructor.requiredBinaryData;
    requiredData.forEach((item) => {
      if (!newQuestion.params[item]) {
        isValid = false;
        newQuestion.errors[item] = 'This field is required';
      }
    });
    if (!isValid) {
      this.setState({
        newQuestion,
      });
      return true;
    }
    const { dispatch } = this.props;
    dispatch(addQuestionToScorecardCategory(questionCategory.reactId, newQuestion.params));
    this.handleCancelForm();
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  handleDeleteQuestion(index) {
    const { dispatch } = this.props;
    const { questionCategory } = this.props;
    dispatch(deleteQuestionFromScorecardCategory(questionCategory.reactId, index));
  }

  handleCancelForm() {
    this.setState({
      newQuestion: {
        params: {
          questionType: 'Scored',
        },
        errors: {},
      },
      showForm: false,
    });
  }

  render() {
    const {
      questionCategory,
    } = this.props;
    const {
      newQuestion,
      showForm,
    } = this.state;
    const displayAddButton = typeof questionCategory._id === 'undefined' || questionCategory._id === null;
    return (
      <>
        <div className="dashboard container">
          <div className="card card-bordered" style={{ paddingBotton: '0' }}>
            <div className="card-body">
              <h4>{questionCategory.categoryName}</h4>
              <br />
              <div className="row">
                {questionCategory.questions.map((question, index) => {
                  console.log(question);
                  return (
                    <div key={uid(question)} className="col-2">
                      <div className="card card-bordered">
                        {displayAddButton && (
                          <div style={{ textAlign: 'right' }}>
                            <IconButton
                              onClick={() => { this.handleDeleteQuestion(index); }}
                              className="material-table__toolbar-button"
                              size="small"
                            >
                              <CloseIcon size={16} />
                            </IconButton>
                          </div>
                        )}
                        <div className="card-body" style={displayAddButton ? { paddingTop: 0 } : {}}>
                          <b>Question:</b> {question.question}<br />
                          <b>Help Text:</b> {question.helpText}<br />
                          {question.questionType === 'Scored' && (
                            <div>
                              <b>Minimum:</b> {question.minVal}<br />
                              <b>Maximum:</b> {question.maxVal}<br />
                              <b>Scale:</b> {question.scale}
                            </div>
                          )}
                          {question.questionType === 'Binary' && (
                            <div>
                              <b>Yes / No</b> <br />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {displayAddButton && (
                <div>
                  {!showForm && (
                    <div className="container">
                      <br />
                      <div>
                        <StyledButton
                          onClick={this.showForm}
                          type="button"
                          className="is-primary"
                        >
                          <span className="btn-text">Add Question</span>
                        </StyledButton>
                      </div>
                    </div>
                  )}
                  {showForm && (
                    <div className="container">
                      <br />
                      <FormGroup>
                        <Label>Question Name</Label>
                        <Input
                          type="text"
                          id="question"
                          name="question"
                          onChange={e => this.handleChange('question', e)}
                          value={typeof newQuestion.params.question !== 'undefined' && newQuestion.params.question !== null ? newQuestion.params.question : ''}
                          invalid={!!newQuestion.errors.question}
                        />
                        {newQuestion.errors.question && (<span className="text-danger">{newQuestion.errors.question}</span>)}
                      </FormGroup>

                      <FormGroup>
                        <Label>Help Text</Label>
                        <textarea
                          id="helpText"
                          name="helpText"
                          className="form-control"
                          onChange={e => this.handleChange('helpText', e)}
                          value={typeof newQuestion.params.helpText !== 'undefined' && newQuestion.params.helpText !== null ? newQuestion.params.helpText : ''}
                        />
                        {newQuestion.errors.helpText && (<span className="text-danger">{newQuestion.errors.helpText}</span>)}
                      </FormGroup>

                      <FormGroup>
                        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                        <label htmlFor="firstname">Question Type</label>
                        <Row>
                          <Col sm={3} style={{ paddingLeft: '50px' }}>
                            <FormControlLabel
                              control={
                                <Input
                                  type="radio"
                                  name="isScored"
                                  value={newQuestion.params.questionType}
                                  className="mt-0"
                                  id="isScoredTrue"
                                  checked={newQuestion.params.questionType === 'Scored'}
                                  onChange={() => { this.handleRadioInputChange('questionType', 'Scored'); }}
                                />
                              }
                              label="Scored"
                            />
                          </Col>
                          <Col sm={3} style={{ paddingLeft: '50px' }}>
                            <FormControlLabel
                              control={
                                <Input
                                  type="radio"
                                  name="isBinary"
                                  className="mt-0"
                                  id="isBinaryTrue"
                                  value={newQuestion.params.questionType}
                                  checked={newQuestion.params.questionType === 'Binary'}
                                  onChange={() => { this.handleRadioInputChange('questionType', 'Binary'); }}
                                />
                              }
                              label="Yes/No"
                              style={{ fontSize: '14px' }}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      {newQuestion.params.questionType === 'Scored' && (
                        <div>
                          <FormGroup>
                            <Label>Minimum Score</Label>
                            <Input
                              type="text"
                              id="minVal"
                              name="minVal"
                              onChange={e => this.handleChange('minVal', e)}
                              value={typeof newQuestion.params.minVal !== 'undefined' && newQuestion.params.minVal !== null ? newQuestion.params.minVal : ''}
                              invalid={!!newQuestion.errors.minVal}
                            />
                            {newQuestion.errors.minVal && (<span className="text-danger">{newQuestion.errors.minVal}</span>)}
                          </FormGroup>

                          <FormGroup>
                            <Label>Maximum Score</Label>
                            <Input
                              type="text"
                              id="maxVal"
                              name="maxVal"
                              onChange={e => this.handleChange('maxVal', e)}
                              value={typeof newQuestion.params.maxVal !== 'undefined' && newQuestion.params.maxVal !== null ? newQuestion.params.maxVal : ''}
                              invalid={!!newQuestion.errors.maxVal}
                            />
                            {newQuestion.errors.maxVal && (<span className="text-danger">{newQuestion.errors.maxVal}</span>)}
                          </FormGroup>

                          <FormGroup>
                            <Label>Scale of Score</Label>
                            <Input
                              type="text"
                              id="scale"
                              name="scale"
                              onChange={e => this.handleChange('scale', e)}
                              value={typeof newQuestion.params.scale !== 'undefined' && newQuestion.params.scale !== null ? newQuestion.params.scale : ''}
                              invalid={!!newQuestion.errors.scale}
                            />
                            {newQuestion.errors.scale && (<span className="text-danger">{newQuestion.errors.scale}</span>)}
                          </FormGroup>
                        </div>
                      )}

                      <StyledButton
                        onClick={this.handleSubmitQuestion}
                        type="button"
                        className="is-primary"
                      >
                        <span className="btn-text">Save</span>
                      </StyledButton>
                      <StyledButton
                        onClick={this.handleCancelForm}
                        type="button"
                        className="is-primary btn-danger"
                      >
                        <span className="btn-text">Cancel</span>
                      </StyledButton>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <br />
      </>
    );
  }
}

ScorecardsQuestionCategory.propTypes = {
  questionCategory: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({

});

export default withNotice(connect(mapStateToProps)(ScorecardsQuestionCategory));
