import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
// import ApiClient from 'ApiClient';
import ARReportContainer from './components/ARReportContainer';
import SearchAccountReceivableReport from './components/SearchAccountReceivableReport';
import { getAccountReceivableRecord } from '../../../../redux/reducers/admin/AccountsReceivableReducer';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import UserPropertyType from './components/custom';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';

class AccountReceivableList extends React.Component {
  componentDidMount() {
    const { match, dispatch } = this.props;
    if (match) {
      dispatch(getAccountReceivableRecord(match.params.reportId));
      // this.fetchAccoutPayable(this.props);
    }
  }
  shouldComponentUpdate(newProps) {
    const { match, dispatch } = newProps;
    // console.log(match, this.props.match);
    if (
      (match && match.params.reportId && !this.props.match.params.reportId)
      || (match && match.params.reportId && this.props.match.params.reportId !== match.params.reportId)
      || (match && !match.params.reportId && this.props.match.params.reportId)
      || (match && !match.params.reportId && !this.props.match.params.reportId)
    ) {
      // console.log(match.params.reportId, 'fetching');
      dispatch(getAccountReceivableRecord(match.params.reportId));
    }
    return true;
  }
  /* shouldComponentUpdate(newProps, newState) {
    console.log('update Component', newState);
    const { match } = newProps;
    // const { selectedReport } = newState;
    console.log('update Component', newProps);
    console.log('update Component', this.props);
    if (match && match.params.reportId && !this.props.match.params.reportId) {
      this.fetchAccoutReceivable(newProps);
      return false;
    }
    return true;
  } */


  /* selectReceivableReport(selectedReport) {
    this.setState({ selectedReport });
  }

  fetchAccoutReceivable(props) {
    console.log('start ', props);
    const { addNotice, match } = props;
    const api = new ApiClient();
    this.setState({
      isLoading: true,
    });
    api.recordAction({
      resourceId: 'AccountsReceivable',
      recordId: match.params.reportId,
      actionName: 'show',
    }).then((response) => {
      this.setState({
        isLoading: false,
        selectedReport: response.data.record,
      });
    }).catch((error) => {
      addNotice({
        message: 'There was an error fething the record, Check out console to see more information.',
        type: 'error',
      });
      throw error;
    });
  } */
  render() {
    const {
      selectedReport,
      isLoading,
      match,
      resources,
    } = this.props;
    const resource = resources.find(r => r.id === 'AccountsReceivable');
    const action = resource.resourceActions.find(r => r.name === 'list');
    return (
      <Container className="dashboard">
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            { title: 'Account Receivable Report', path: null },
          ]}
          isBackButton
        />
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <SearchAccountReceivableReport selectReceivableReport={this.selectReceivableReport} selectedReport={selectedReport} />
                  </Col>
                  <Col lg="auto">
                    <Link to="/finance/account-receivable-report/new">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                      >
                        New AR Report
                      </Button>
                    </Link>
                  </Col>
                  <Col lg="auto">
                    <Link to="/resources/AccountsReceivable/actions/list">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                      >
                        View AR Reports
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {selectedReport && (<ARReportContainer selectedReport={selectedReport} isLoading={isLoading} />)}
            {!match.params.reportId && (<MatTableList
              action={action}
              resource={resource}
              setTag={() => true}
              date={new Date()}
              UserPropertyType={UserPropertyType}
              columnsSettings={{
                startDate: {
                  width: 150,
                },
                endDate: {
                  width: 150,
                },
                name: {
                  width: 150,
                },
                description: {
                  width: 150,
                },
                generalLedger: {
                  width: 120,
                },
                payables: {
                  width: 400,
                },
              }}
              sortBy="startDate"
              v={2}
            />)}
          </Col>
        </Row>
      </Container>
    );
  }
}

AccountReceivableList.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedReport: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
AccountReceivableList.defaultProps = {
  selectedReport: null,
};
const mapStateToProps = state => ({
  selectedReport: state.accountReceivable.accountReceivable,
  isLoading: state.accountReceivable.loading.accountReceivable,
  resources: state.resources,
});
export default connect(mapStateToProps, null)(AccountReceivableList);
