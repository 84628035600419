import React, { useState, useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import _ from 'lodash';
import PropTypes from 'prop-types';
// import { unflatten } from 'flat';

const AnalystUser = React.memo(({
  record,
  analysts,
  afterSaveFunc,
  updateRecordFunction,
  miningMode,
}) => {
  // const record = unflatten(props.record);
  // const { afterSaveFunc, analysts } = props;
  // const { analystUser } = record?.populated;
  const notAssigned = {
    value: null,
    label: '-- Not Assigned --',
    record: { params: { _id: null, firstname: '-- Not Assigned --', lastname: '' } },
  };
  const [analyst, setAnalyst] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setAnalyst(null);
    if (record?.populated?.analystUser && record.populated.analystUser.params) {
      setAnalyst({
        value: record.populated.analystUser.id,
        label: `${record.populated.analystUser.params.firstName} ${record.populated.analystUser.params.lastName}`,
        record: record?.populated.analystUser,
      });
    }
    if (!record?.populated?.analystUser) {
      setAnalyst(notAssigned);
    }
    // console.log(record);
  }, [record.id, record.params.analystUser]);

  const handleChangeAnalyst = async (selectedItem) => {
    const previousAnalystUser = analyst.value || null;
    const previousAnalystUserName = analyst.value ? analyst.label : null;
    setDisabled(true);
    setAnalyst(selectedItem);
    try {
      await axios.post('/api/portal/prospects/post-update-analyst', {
        prospectIds: [record.id],
        selectedAnalystUser: selectedItem.value,
        selectedAnalystUserName: selectedItem.label,
        previousAnalystUser,
        previousAnalystUserName,
      }).then((response) => {
        if (response.data.success) {
          setDisabled(false);
          if (response?.data?.prospectPopulated && updateRecordFunction) {
            updateRecordFunction(response?.data?.prospectPopulated);
          } else {
            afterSaveFunc();
          }
          // afterSaveFunc();
        }
      });
    } catch (e) {
      console.log(e.message);
      setDisabled(false);
    }
    return true;
  };
  return (
    <div className="d-flex" style={{ minWidth: '190px', maxWidth: '190px', marginBottom: 0 }}>
      <div className="pr-0 analystUser">
        <FormGroup style={{ minWidth: '160px', maxWidth: '160px', marginBottom: 0 }}>
          <Select
            options={analysts}
            value={analyst}
            onChange={handleChangeAnalyst}
            placeholder=""
            isDisabled={!!disabled || (miningMode && record.params?.inBlackList)}
            menuPlacement="auto"
            menuPortalTarget={document.body}
          />
        </FormGroup>
      </div>
    </div>
  );
}, (a, a1) => a.record.id === a1.record.id && _.isEqual(a.record.params.analystUser, a1.record.params.analystUser));

AnalystUser.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  analysts: PropTypes.arrayOf(PropTypes.any).isRequired,
  afterSaveFunc: PropTypes.func.isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
  miningMode: PropTypes.bool,
};

AnalystUser.defaultProps = {
  miningMode: false,
};

export default AnalystUser;
