import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Col, Modal, ModalHeader, Row } from 'reactstrap';

const Warning = ({
  showModal,
  setShowModal,
  title,
  header,
  errors,
}) => (
  <Modal
    isOpen={showModal}
    toggle={() => { setShowModal(false); }}
  >
    <ModalHeader
      toggle={() => { setShowModal(false); }}
      tag="h4"
    >
      {title}
    </ModalHeader>
    <div className="modal__body finance-form ltr-support">
      <Row className="mt-3">
        <Col xs={3} className="text-center mt-2" style={{ color: '#70bbfd' }}> <i className="fa fa-exclamation-triangle" style={{ fontSize: '5em' }} /></Col>
        <Col xs={9}>
          <span><strong>{header}</strong></span>
          <ul className="list-items-color-blue">
            {errors.map(error => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
    <ButtonToolbar className="modal__footer">
      <Button className="modal_cancel btn-sm" color="secondary" onClick={() => { setShowModal(false); }}>Close</Button>
    </ButtonToolbar>
  </Modal>
);

Warning.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.any),
};

Warning.defaultProps = {
  errors: [],
};

export default Warning;
