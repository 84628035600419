import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { hideModalById } from '../../../../../../../../redux/reducers/TableReducer';
import Table from './CpmProspectsTable';
import { getConstant } from '../../../../../../../../shared/helpers/WVConstants';
import WVFormatter from '../../../../../../../../shared/helpers/WVFormatter';
import RadioButton from '../../../../../../../../shared/components/RadioButton';
import { NoActionError, NoResourceError } from '../../../../../../../../shared/components/components/ui/error404';
import {
  currentTimezoneAggr,
  momentLocaleToServerUTC,
  momentUTCToLocalDate,
} from '../../../../../../../../shared/helpers/Dates';

export const MoveContentToNextPeriodModalId = 'MoveContentToNextPeriod';

const MoveContentToNextPeriodModal = ({
  resources,
}) => {
  const pipelines = getConstant('deal', 'pipelines', []);
  const { stages } = pipelines?.find(item => item.label.toLowerCase() === 'main pipeline') || { stages: {} };


  const [loading, setLoading] = useState(false);
  const [endMoving, setEndMoving] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [selected, setSelected] = useState([]);
  const [dealStageOptions, setDealStageOptions] = useState(Object.entries(stages)?.map(([key, val]) => ({ value: key, label: val })) || []);
  const [dealStageFilter, setDealStageFilter] = useState(dealStageOptions.filter(item => ['contract sent', 'contract signed'].includes(item.label.toLowerCase())) || []);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [contentReleaseDate, setContentReleaseDate] = useState(() => {
    const now = new Date();
    if (now.getMonth() === 11) {
      return new Date(now.getFullYear() + 1, 0, 1);
    }
    return new Date(now.getFullYear(), now.getMonth() + 1, 1);
  });
  const [metaTotal, setMetaTotal] = useState(0);
  const [version, setVersion] = useState((new Date()).toISOString());
  // eslint-disable-next-line max-len
  const [pseudoUrl, setPseudoUrl] = useState(`?filters.insertionOrderAssigned=Assigned&filters.invoiceAssigned=Not Assigned${dealStageFilter && dealStageFilter.length > 0 ? dealStageFilter.map(dealStage => `&filters.dealStages=${dealStage.value}`).join('') : ''}&filters.currentContentReleaseDate~~to=${endDate}`);
  const [includeAll, setIncludeAll] = useState(false);
  const recordsRef = useRef([]);

  useEffect(() => {
    if (stages) {
      const stageOptions = Object.entries(stages).map(([key, val]) => ({ value: key, label: val }));
      setDealStageOptions(stageOptions);
      setDealStageFilter(stageOptions.filter(item => ['contract sent', 'contract signed'].includes(item.label.toLowerCase())));
    }
  }, [stages]);

  useEffect(() => {
    setVersion((new Date()).toISOString());
  }, [pseudoUrl]);

  const getUrlSearch = () => {
    const search = new URLSearchParams(pseudoUrl);
    search.delete('filters.dealStages');
    if (dealStageFilter && dealStageFilter.length > 0) {
      dealStageFilter.forEach((stage) => {
        if (stage?.value) {
          search.append('filters.dealStages', stage.value);
          return true;
        }
        return false;
      });
    }
    search.delete('filters.currentContentReleaseDate~~from');
    search.delete('filters.currentContentReleaseDate~~to');
    if (startDate) {
      if (typeof startDate === 'string') {
        search.append('filters.currentContentReleaseDate~~from', moment(startDate).toISOString());
      } else {
        search.append('filters.currentContentReleaseDate~~from', moment(startDate).format('MM/DD/YY'));
      }
    }
    if (endDate) {
      if (typeof endDate === 'string') {
        search.append('filters.currentContentReleaseDate~~to', moment(endDate).toISOString());
      } else {
        search.append('filters.currentContentReleaseDate~~to', endDate.toISOString());
      }
    }
    search.delete('filters.insertionOrderAssigned');
    search.append('filters.insertionOrderAssigned', 'Assigned'); // added to Insertion Order
    search.delete('filters.invoiceAssigned');
    search.append('filters.invoiceAssigned', 'Not Assigned'); // NOT added to invoice
    return search;
  };

  const firstUpdate = useRef(true);
  const setUrl = () => {
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
    }
    setPseudoUrl(`?${search.toString()}`);
  };

  useEffect(() => {
    setUrl();
  }, [
    dealStageFilter,
    startDate,
    endDate,
  ]);

  const modalInfo = useSelector(state => state.table.modals[MoveContentToNextPeriodModalId]);
  const dispatch = useDispatch();
  if (!modalInfo) return false;
  const { isShow: showUploadModal } = modalInfo;

  const close = () => {
    dispatch(hideModalById(MoveContentToNextPeriodModalId));
    setEndMoving(false);
  };

  const handleSelectCheckbox = (selectedIds) => {
    setSelected(selectedIds);
  };

  const onMove = () => {
    setLoading(true);
    const data = {
      prospectIds: selected,
      includeAll,
      query: pseudoUrl,
      contentReleaseDate: momentLocaleToServerUTC(moment(contentReleaseDate)).toISOString(),
    };
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-update-content-release-date',
      data,
    }).then((response) => {
      if (response.data.success) {
        setResultMessage('Content Release Date was moved successfully.');
      } else {
        setResultMessage('Content Release Date was not moved.');
      }
      setLoading(false);
      setEndMoving(true);
    });
  };

  const resource = resources.find(r => r.id === 'CpmProspect');
  if (!resource) {
    return (<NoResourceError resourceId="CpmProspect" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="CpmProspect" actionName="list" />);
  }

  return (
    <Modal isOpen={showUploadModal} toggle={close} size="lg" style={{ maxWidth: '1000px' }}>
      <ModalHeader toggle={close} tag="h4">
        Move Content to Next Period
      </ModalHeader>
      <ModalBody>
        {!endMoving && selected.length !== metaTotal && (
          <>
            <div>{`You have selected ${selected.length} Prospect${selected.length !== 1 ? 's' : ''} to move its Content Release Date.`}</div>
            <div className="theme-light radio-in-prospects">
              {`Select all of ${WVFormatter.formatIntNumber(metaTotal)} Prospects?`}&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="action"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                name="action"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>
          </>
        )}
        {dealStageOptions.length > 0 && !endMoving && (
          <div className="theme-light">
            <Row className="mb-3">
              <Col>
                <p className="mb-1 font-weight-bold">Deal Stages</p>
                <Select
                  isDisabled={dealStageOptions.length === 0}
                  value={dealStageFilter}
                  options={dealStageOptions}
                  onChange={setDealStageFilter}
                  placeholder="Select Deal Stage..."
                  isMulti
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <p className="mb-1 font-weight-bold">Content Release Date</p>
                <Row>
                  <Col>
                    <div>
                      <DatePicker
                        selected={startDate}
                        onChange={setStartDate}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Start Date"
                        dropDownMode="select"
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm w-100"
                        isClearable
                      />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <DatePicker
                        selected={endDate}
                        onChange={setEndDate}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="End Date"
                        dropDownMode="select"
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm w-100"
                        isClearable
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-0">
              <Col md={6}>
                <FormGroup>
                  <p className="mb-1 font-weight-bold">{`Set New Content Release Date (${currentTimezoneAggr()})`}</p>
                  <div>
                    <DatePicker
                      onChange={setContentReleaseDate}
                      selected={contentReleaseDate ? momentUTCToLocalDate(moment(contentReleaseDate)).toDate() : null}
                      dateFormat="MM/dd/yyyy"
                      minDate={new Date()}
                      placeholderText="Set New Content Release Date"
                      dropDownMode="select"
                      wrapperClassName="date-picker--interval w-100"
                      className="form-control form-control-sm w-100"
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Table
                handleSelectCheckbox={handleSelectCheckbox}
                action={action}
                resource={resource}
                pseudoUrl={pseudoUrl}
                setPseudoUrl={setPseudoUrl}
                stages={stages}
                version={version}
                recordsRef={recordsRef}
                setMetaTotal={setMetaTotal}
              />
            </Row>
          </div>
        )}
        {loading && (
          <div className="text-center"><Spinner color="primary" size="lg" />&nbsp;&nbsp;</div>
        )}
        {endMoving && (
          <div className="text-center">
            <h4>{resultMessage}</h4>
          </div>
        )}
        <Row className="modal__footer" style={{ marginTop: '30px' }}>
          {!endMoving && (
            <Button
              className="btn btn-primary btn-sm"
              onClick={onMove}
              color="primary"
              style={{ margin: '0 10px 0 10px' }}
              disabled={loading || selected.length === 0}
            >
              <i className="icomoon-save" />
              <span className="btn-text">Move Date</span>
            </Button>
          )}
          <Button
            className="modal_cancel btn-sm"
            color="secondary"
            onClick={close}
            style={{ margin: '0 10px 0 10px' }}
          >
            {endMoving ? 'Close' : 'Cancel'}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

MoveContentToNextPeriodModal.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(MoveContentToNextPeriodModal);
