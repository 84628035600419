import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { currentTimezoneAggr } from '../../../../../shared/helpers/Dates';

const Assignments = ({ agreement }) => {
  return (
    <>
      {(agreement.sendToName || agreement.sendToEmail) && (
        <div>
          <strong>Send To:</strong> {agreement.sendToName} ({agreement.sendToEmail})
        </div>
      )}
      {(agreement.signeeName || agreement.signeeEmail) && (
        <div>
          <strong>Signee:</strong> {agreement.signeeName} ({agreement.signeeEmail})
        </div>
      )}
      {agreement.reassignHistory?.length > 0 && (
        <>
          <h4 className="mt-3">Reassignment History</h4>
          <hr />
          {agreement.reassignHistory.map(row => (
            <div key={moment(row.reassignedAt).toString() + moment.now().toString()}>
              <div className="mt-3"><strong>{moment(row.reassignedAt).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
              <div className="pl-3"><strong>{row.reassignedBy}</strong> {row.reassignedBy ? 'r' : 'R'}eassigned to <i>{row.name} ({row.email})</i></div>
              {row.reassignmentReason && (
                <div className="pl-3">Reassignment Reason: <i>{row.reassignmentReason}</i></div>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

Assignments.propTypes = {
  agreement: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Assignments;
