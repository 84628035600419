import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  Input,
  Row,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import AccountPlusOutlineIcon from 'mdi-react/AccountPlusOutlineIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import _ from 'lodash';
import WVValidator from '../../../../../shared/helpers/WVValidator';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import {
  addAdditionalContact,
  deleteAdditionalContact,
  editAdditionalContact,
} from '../../../../../redux/reducers/admin/AgentReducer';
import withNotice from '../../../../App/store/with-notice';
import Hook from '../../../../../shared/hooks';

const AdditionalContactsInList = ({
  agentId,
  isNew,
  setShowNew,
  record,
  dispatch,
  loading,
  addNotice,
}) => {
  const [contact, setContact] = useState(record);
  const [showUpdateContact, setShowUpdateContact] = useState(false);
  const [errors, setErrors] = useState({});
  const [showDeleteModal] = Hook.useShowModalHook('DeleteModalDefault');

  const setContactProperty = (property, value) => {
    setContact({ ...contact, [property]: value });
  };

  const checkContactValidity = () => {
    const newErrors = { ...errors };
    newErrors.firstName = WVValidator.isEmpty(contact?.firstName?.trim()) ? 'This field is required' : false;
    newErrors.lastName = WVValidator.isEmpty(contact?.lastName?.trim()) ? 'This field is required' : false;
    newErrors.email = WVValidator.isEmail(contact?.email) ? false : 'A valid email is required';
    setErrors(newErrors);

    setShowUpdateContact((!isNew && !_.isEqual(record, contact)));
  };

  const trimmedContact = () => {
    const result = { ...contact };
    result.firstName = contact.firstName.trim();
    result.lastName = contact.lastName.trim();
    result.email = contact.email.trim();
    result.title = (contact.title || '').trim();
    return result;
  };

  const editContact = () => {
    let hasErrors = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key]) hasErrors = true;
    });
    if (!hasErrors) {
      dispatch(editAdditionalContact(agentId, trimmedContact()));
    }
  };

  const addNewContact = () => {
    let hasErrors = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key]) hasErrors = true;
    });
    if (!hasErrors) {
      dispatch(addAdditionalContact(agentId, trimmedContact()));
      setTimeout(() => setShowNew(false), 1000);
    } else {
      addNotice({
        message: 'Can not add new Additional Contact. Please check required fields.',
        type: 'warning',
      });
    }
  };

  const cancelNew = () => {
    setContact({});
    setShowNew(false);
  };

  useEffect(() => {
    setContact(record);
  }, [record]);

  useEffect(() => {
    checkContactValidity();
  }, [contact]);

  return (
    <Row>
      <Col sm={9}>
        <Row>
          <Col sm={3}>
            <FormGroup>
              <Input
                disabled={loading}
                className="form-control-sm form-control"
                name="firstName"
                type="text"
                placeholder="First Name"
                value={contact?.firstName || ''}
                onChange={p => setContactProperty('firstName', p.target.value)}
                style={{ height: '38px' }}
              />
              {errors.firstName && (<span className="text-danger">{errors.firstName}</span>)}
            </FormGroup>
          </Col>
          <Col sm={3}>
            <FormGroup>
              <Input
                disabled={loading}
                className="form-control-sm form-control"
                name="lastName"
                type="text"
                placeholder="Last Name"
                value={contact?.lastName || ''}
                onChange={p => setContactProperty('lastName', p.target.value)}
                style={{ height: '38px' }}
              />
              {errors.lastName && (<span className="text-danger">{errors.lastName}</span>)}
            </FormGroup>
          </Col>
          <Col sm={3}>
            <FormGroup>
              <Input
                disabled={loading}
                className="form-control-sm form-control"
                name="email"
                type="text"
                placeholder="Email"
                value={contact?.email || ''}
                onChange={p => setContactProperty('email', p.target.value)}
                style={{ height: '38px' }}
              />
              {errors.email && (<span className="text-danger">{errors.email}</span>)}
            </FormGroup>
          </Col>
          <Col sm={3}>
            <FormGroup>
              <Input
                disabled={loading}
                className="form-control-sm form-control"
                name="title"
                type="text"
                placeholder="Title"
                value={contact?.title || ''}
                onChange={p => setContactProperty('title', p.target.value)}
                style={{ height: '38px' }}
              />
              {errors.title && (<span className="text-danger">{errors.title}</span>)}
            </FormGroup>
          </Col>
        </Row>
      </Col>
      <Col sm={3}>
        <Row >
          <Col>
            <div style={{ paddingTop: '9px' }}>
              <FormGroup>
                <CheckBoxField
                  disabled={loading}
                  name={`sendPaymentEmails${contact._id}`}
                  label="Send Payment Emails?"
                  value={contact?.sendPaymentEmails || false}
                  onChange={e => setContactProperty('sendPaymentEmails', e.target?.checked || false)}
                />
              </FormGroup>
            </div>
          </Col>
          <Col sm="auto" style={{ paddingTop: '4px' }}>
            {isNew ? (
              <>
                <Tooltip title="Save new Additional Contact">
                  <IconButton
                    style={{ color: '#70bbfd' }}
                    size="small"
                    onClick={addNewContact}
                  >
                    <AccountPlusOutlineIcon style={{ width: '24px', height: '24px' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton
                    style={{ color: '#ff4861' }}
                    size="small"
                    onClick={cancelNew}
                  >
                    <CloseIcon style={{ width: '24px', height: '24px' }} />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                {(!isNew && showUpdateContact) && (
                  <Tooltip title="Save updated Contact">
                    <IconButton
                      style={{ color: '#4ce1b6' }}
                      size="small"
                      onClick={editContact}
                    >
                      <CheckIcon style={{ width: '24px', height: '24px' }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Delete Contact">
                  <IconButton
                    style={{ color: '#ff4861' }}
                    size="small"
                    onClick={() => showDeleteModal({
                      title: 'Delete Additional Contact',
                      message: (<>Are you sure you want to delete this additional contact?</>),
                      deleteRecord: () => {
                        dispatch(deleteAdditionalContact(agentId, contact));
                      },
                    })}
                  >
                    <TrashCanOutlineIcon style={{ width: '24px', height: '24px' }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

AdditionalContactsInList.propTypes = {
  agentId: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  setShowNew: PropTypes.func,
  record: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

AdditionalContactsInList.defaultProps = {
  isNew: false,
  record: {},
  setShowNew: () => {},
};

const mapStateToProps = state => ({
  loading: state.AgentReducer.loading.additionalContacts,
});

export default connect(mapStateToProps)(withNotice(AdditionalContactsInList));
