import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { unflatten } from 'flat';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Container,
  Card,
  CardBody,
  Col,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { CKEditor } from 'ckeditor4-react';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import DropZoneField from '../../../../shared/components/form/DropZone';
import ApiClient, { axios } from 'ApiClient';
import Hooks from '../../../../shared/hooks';
import { useHistory, useParams } from 'react-router-dom';
import CheckBoxField from '@/shared/components/CheckBox';

const CreateNotification = () => {
  const { showError, showSuccess } = Hooks.useNoticeHook();
  const  history = useHistory();
  const [editors, setEditors] = useState();
  const [formData, setFormData] = useState({
    notificationName: '',
    type: ['notification', 'headLine'],
    headlineText: '',
    notificationText: '',
    headLineNotificationText: '',
    defaultDuration: 0,
    imageUrl: '',
    imagePath: '',
    actionButtonUrl: '',
    portal: 'agent',
    file: null,
  });
  const { id } = useParams();

  useEffect(async() => {
    if (id && editors?.notificationText && editors?.headLineNotificationText) {
      const api = new ApiClient();
      const res = await api.recordAction({
        resourceId: 'PortalNotification',
        recordId: id,
        actionName: 'show',
      });
      console.log(unflatten(res.data.record.params));
      setFormData({
        ...unflatten(res.data.record.params)
      });
      editors.notificationText?.setData(res.data.record.params.notificationText)
      editors.headLineNotificationText?.setData(res.data.record.params.headLineNotificationText)
      if (res.data.record.params.imageUrl) {
        setFormData((state) => ({
          ...state,
          file: {
            name: res.data.record.params.imagePath,
            preview: res.data.record.params.imageUrl,
          }
        }));
      }
    }
  }, [id, editors])
  


  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    console.log(e);
    setFormData((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCKEditorDataChange = (event) => {
    console.log(event, 'event')
    const { editor} = event;
    setFormData((state) => ({
      ...state,
      [editor.name]: editor.getData(),
    }));
  };

  const handleChangeFile = (files) => {
    const file = files.length ? files[0] : '';
    setFormData((state) => ({
      ...state,
      file,
    }));
  }

  const validateForm = () => {
    const newErrors = {};

    if (!formData.notificationName) {
      newErrors.notificationName = 'Notification name is required';
    }
    if (!formData.type) {
      newErrors.type = 'Notification key is required';
    }
    if (!formData.headlineText) {
      newErrors.headlineText = 'Headline is required';
    }
    if (!formData.notificationText) {
      newErrors.notificationText = 'Message is required';
    }
    if (formData.actionButtonUrl && !/^https?:\/\/\S+$/i.test(formData.actionButtonUrl)) {
      newErrors.actionButtonUrl = 'Invalid URL format';
    }

    setErrors(newErrors);
    console.log(newErrors)
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const formDataToSubmit = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if(Array.isArray(value)) {
          value.forEach((v, i) => formDataToSubmit.append(`${key}.${i}`, v));
        } else {
          formDataToSubmit.append(key, value);
        }

        
      });
      if(!id) {
        const response = await axios.post('/api/resources/PortalNotification/actions/new', formDataToSubmit, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if(response.data.notice.type === 'error') {
          showError(response.data.notice.message)
        }
        if(response.data.notice.type === 'success') {
          showSuccess(response.data.notice.message)
          history.push("/notifications");
        }
      } else {
        const response = await axios.post(`/api/resources/PortalNotification/records/${id}/edit`, formDataToSubmit, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if(response.data.notice.type === 'error') {
          showError(response.data.notice.message)
        }
        if(response.data.notice.type === 'success') {
          showSuccess(response.data.notice.message)
          history.push("/notifications");
        }
      }
      // window.location.href = '/notifications';
      
      // Handle successful form submission (e.g., show success message, clear form)
    } catch (error) {
      showError(error.message);
      console.error('Error submitting form:', error);
      // Handle form submission error (e.g., show error message)
    } finally {
      setIsSubmitting(false);
    }
  };

  const tokens = [
    '{{date}}',
    '{{il_creator_portal_url}}',
    '{{il_admin_portal_url}}',
    '{{il_agent_portal_url}}',
    '{{il_brand_portal_url}}',


    
  ];
  if (formData.portal === 'agent') {
    tokens.push(...[
      '{{il_agent_first_name}}',
      '{{il_agent_last_name}}',
      '{{il_agent_email}}',
    ])
  } else {
    tokens.push(...['{{il_creator_first_name}}',
    '{{il_creator_last_name}}',
    '{{il_creator_email}}',
    '{{il_creator_reset_pw_url}}',
    '{{il_creator_payout_amount}}',
    '{{il_device_verification_code}}',
    '{{brandNameOpportunity}}',
    '{{il_deal_brand}}',
    '{{il_deal_offer}}',
    '{{csp_name}}',
    '{{csp_firstName}}',
    '{{csp_lastName}}',
    '{{csp_email}}'])
  }
  const copyToken = (e) => {
    let textInput = false;
    try {
      textInput = e.nativeEvent.target.closest('.form__form-group-field').querySelector('input');
    } catch (err) {
      textInput = false;
    }

    e.preventDefault();

    if (textInput) {
      textInput.select();
      document.execCommand('copy');
    }
  };

  const getTokensListFrom = (tokensArray) => {
    return tokensArray.map(value => (
      <FormGroup
        key={`div_${value}`}
        className="form__form-group-field"
        style={{ marginTop: '5px' }}
      >
        <input
          style={{ width: '100%', paddingLeft: '5px' }}
          readOnly
          value={value}
        />
        <button
          className="btn btn-primary btn-sm"
          type="button"
          title="Copy"
          style={{
            padding: '5px 5px 5px 10px',
            height: '38px',
            margin: '0px',
            borderRadius: '0px 5px 5px 0px',
          }}
          onClick={e => copyToken(e)}
        >
          <ContentCopyIcon size={16} />
        </button>
      </FormGroup>
    ));
  }
  const portalOptions = [{value: 'agent', label: 'Agent'}, {value: 'creator', label: 'Creator'}];
  return (
    <Container className="dashboard">
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="notificationName">Name</Label>
              <Input
                readOnly={!!id}
                type="text"
                name="notificationName"
                id="notificationName"
                value={formData.notificationName}
                onChange={(e) => handleChange(e)}
                invalid={!!errors.notificationName}
              />
              <FormFeedback>{errors.notificationName}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="portal">Portal</Label>
              <Select
                options={portalOptions}
                value={portalOptions.find(a => a.value === formData.portal)}
                onChange={(e) => handleChange({target: { name: 'portal', value: e.value }})}
              />
            </FormGroup>
            <FormGroup>
              <Label for="headlineText">Headline</Label>
              <Input
                type="text"
                name="headlineText"
                id="headlineText"
                value={formData.headlineText}
                onChange={handleChange}
                invalid={!!errors.headlineText}
              />
              <FormFeedback>{errors.headlineText}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <CheckBoxField
                  name="Active1"
                  label="Show Notification Field"
                  value={formData.type.includes('notification')}
                  onChange={event => {
                    if (!event) return false;
                    const newType = formData.type.includes('notification')
                      ? formData.type.filter(item => item !== 'notification') // Remove 'notification' if it exists
                      : [...formData.type, 'notification']; // Add 'notification' if it doesn't exist
                    handleChange({ target: { name: 'type', value: newType } });
                  }}
                />
              </FormGroup>
            {formData.type.includes('notification') ? (
              <Row>
                
              <Col md={9}>
                <FormGroup style={{ width: '100%' }}>
                  <Label for="notificationText">Notification Message (1000 characters limit)</Label>
                  <CKEditor
                      config={{versionCheck: false}}
                      id="notificationText"
                      name="notificationText"
                      activeClass="form-control"
                      initData={formData.notificationText}
                      onChange={(e) => handleCKEditorDataChange(e)}
                      onInstanceReady={({ editor }) => setEditors(state => ({ ...state, [editor.name]: editor }))}
                    />
                </FormGroup>
                <FormFeedback>{errors.notificationText}</FormFeedback>
              </Col>
              <Col
                md={3}
                style={{ paddingTop: '28px' }}
              >
                <div
                  style={{ paddingTop: '1px' }}
                >
                  <span className="font-weight-bold">
                    Task Title & Body Tokens
                  </span>
                  <ul
                    className="tokens-list"
                    style={{ padding: '2px', maxHeight: '382px', overflowY: 'auto' }}
                  >
                    {getTokensListFrom(tokens)}
                  </ul>
                </div>
              </Col>
            </Row>
            ) : null}
            <FormGroup>
            <CheckBoxField
                name="Activew"
                label="Show Headline Field"
                value={formData.type.includes('headLine')}
                onChange={event => {
                  if (!event) return false;
                  const newType = formData.type.includes('headLine')
                    ? formData.type.filter(item => item !== 'headLine') // Remove 'notification' if it exists
                    : [...formData.type, 'headLine']; // Add 'notification' if it doesn't exist
                  handleChange({ target: { name: 'type', value: newType } });
                }}
              />
            </FormGroup>
            {formData.type.includes('headLine') ? (
            <Row>
              <Col md={9}>
                <FormGroup style={{ width: '100%' }}>
                  <Label for="notificationText">Headline Message</Label>
                  <CKEditor
                      config={{versionCheck: false}}
                      id="headLineNotificationText"
                      name="headLineNotificationText"
                      initData={formData.headLineNotificationText}
                      activeClass="form-control"
                      onChange={(e) => handleCKEditorDataChange(e)}
                      onInstanceReady={({ editor }) => setEditors(state => ({ ...state, [editor.name]: editor }))}
                    />
                </FormGroup>
                <FormFeedback>{errors.notificationText}</FormFeedback>
              </Col>
              <Col
                md={3}
                style={{ paddingTop: '28px' }}
              >
                <div
                  style={{ paddingTop: '1px' }}
                >
                  <span className="font-weight-bold">
                    Task Title & Body Tokens
                  </span>
                  <ul
                    className="tokens-list"
                    style={{ padding: '2px', maxHeight: '382px', overflowY: 'auto' }}
                  >
                    {getTokensListFrom(tokens)}
                  </ul>
                </div>
              </Col>
            </Row>) : null}

            <FormGroup>
              <Label for="defaultDuration">Default Duration (ms)</Label>
              <Input
                type="number"
                name="defaultDuration"
                id="defaultDuration"
                value={formData.defaultDuration || 0}
                onChange={(e) => handleChange({ target: { name: e.target.name, value: Number(e.target.value || 0)}})}
              />
            </FormGroup>

            <FormGroup>
              <Label for="imagePath">Image Path</Label>
                <DropZoneField
                  input={{
                    name: 'file',
                    id: 'file',
                    value: formData.file ? [formData.file] : null,
                    onChange: handleChangeFile,
                  }}
                />
            </FormGroup>

            <FormGroup>
              <Label for="actionButtonUrl">Action Button URL</Label>
              <Input
                type="url"
                name="actionButtonUrl"
                id="actionButtonUrl"
                value={formData.actionButtonUrl}
                onChange={handleChange}
                invalid={!!errors.actionButtonUrl}
                
              />
              <FormFeedback>{errors.actionButtonUrl}</FormFeedback>
            </FormGroup>
            <div className='text-right'>
              <Button color="primary" type="submit" disabled={isSubmitting} >Save Notification</Button>
              <Link to="/notifications">
                <Button color="danger" type="button">Cancel</Button>
              </Link>
            </div>
            
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default CreateNotification;
