import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from "../../../../../../shared/components/RadioButton";

const BothYesNoComponent = ({
  name,
  filterValue,
  filterAction,
  styles,
}) => (
  <div style={styles}>
    <RadioButton
      name={name}
      label="&nbsp;Both"
      value={filterValue.both ? 'both' : 'no'}
      radioValue="both"
      onChange={(e) => {
        filterAction({
          yes: false,
          no: false,
          both: e === 'both',
        });
      }}
    />
    <RadioButton
      name={name}
      label="&nbsp;Yes"
      value={filterValue.yes ? 'yes' : 'no'}
      radioValue="yes"
      onChange={(e) => {
        filterAction({
          both: false,
          no: false,
          yes: e === 'yes',
        });
      }}
      className="radio-custom-success"
    />
    <RadioButton
      name={name}
      label="&nbsp;No"
      value={filterValue.no ? 'no' : 'yes'}
      radioValue="no"
      onChange={(e) => {
        filterAction({
          yes: false,
          both: false,
          no: e === 'no',
        });
      }}
      className="radio-custom-danger"
    />
  </div>
);

BothYesNoComponent.propTypes = {
  filterValue: PropTypes.objectOf(PropTypes.any).isRequired,
  filterAction: PropTypes.func.isRequired,
  styles: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string.isRequired,
};

BothYesNoComponent.defaultProps = {
  styles: {},
};

export default BothYesNoComponent;
