import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PaymentMethod = React.memo(({
  record,
}) => {
  const { influencerObject, agentUsers } = record.params;
  const getPaymentMethod = (object) => {
    const data = (<div>
        <div className={`float-left mr-2 mt-1 ${object?.payment?.stripe?.accountId ? 'text-success' : 'text-secondary'}`}>
          <FontAwesomeIcon icon={['fab', 'cc-stripe']} style={{ fontSize: '1.6em' }} />
        </div>
      <div className="float-left mr-2 mt-1">
        <img src={`/images/payments/billCom${object?.payment?.billCom?.accountId ? 'Green' : 'Gray'}.png`} style={{ width: '30px' }} className="img-fluid" alt="Bill.com" />
      </div>
    </div>);

    return data;
  }

  const getAgentPaymentMethod = (agent, influencer) => {
    let agentInfo = `${agent?.first_name || ''} ${agent?.last_name || ''} (${agent?.email || ''})`;
    if (agent?.payment?.billCom.isDefault) {
      agentInfo = `BillCom Account: ${agent?.payment.billCom.name || ''} (${agent?.payment.billCom?.email || ''})`;
    }
    if (agent?.payment.stripe.isDefault) {
      agentInfo = `Stripe Account: ${agent.payment.stripe?.email || ''}`;
    }
    const additional = agent?._id ? (
      <div className='d-flex flex-row'>
        <Tooltip
          title={`${influencer.payment.enablePayments === false ?
            'The agent received payment for this creator.'
            : ''} ${agentInfo}`}
        >
          <a href={`/agent/profile/${agent._id}`} target="_blank" rel="noreferrer" className='pr-2'><img
            style={{
              maxWidth: '25px',
            }}
            src={`/images/creator/agent-${influencer.payment.enablePayments === false ? 'enabled' : 'disabled'}.png`}
            alt={influencer.payment.enablePayments === false ? 'Enabled Agent' : 'Disabled Agent'}
          />
          </a>
        </Tooltip>
        {getPaymentMethod(agent)}
      </div>
    ) : null;
    return additional;
  }

  
  return (
    <div className="w-100">
      <div className="w-100 d-flex">
        {getPaymentMethod(influencerObject)}
      </div>
      {agentUsers.map(agent => (
        <div className="w-100 f-flex">
          {getAgentPaymentMethod(agent, influencerObject)}
        </div>
      ))}
    </div>);
}, (a, a1) => _.isEqual(a.record, a1.record));

PaymentMethod.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PaymentMethod;
