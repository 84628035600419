import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
// import moment from 'moment';
import PropTypes from 'prop-types';
// import DatePicker from 'react-datepicker';
import { Row, Col, Label, Container, Card, CardBody, CardTitle, Button } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { uid } from 'react-uid';
// import Filter from '../Filter';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import MatTableForRedux from '../../../../shared/tables/materialTable/components/MatTableForRedux';
// import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
// import formater from '../../../../shared/helpers/WVFormatter';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
import HubspotDealLink from '../../../../shared/components/HubspotDealLink';
import RadioButton from '../../../../shared/components/RadioButton';
// import PermissionComponent from '../../../../shared/components/PermissionComponent';
// import { CheckPermission } from '../../../../shared/helpers/WVUtilities';


const SummaryReport = ({ addNotice }) => {
  // if (!CheckPermission('reports----duplicateactivedeals')) return (<PermissionComponent />);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsSelected, setItemsSelected] = useState({});

  const fetchAmountReport = async () => {
    setLoading(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-duplicate-deals-report', {});
      if (resp.data.success) {
        setItems(resp.data.items);
        setLoading(false);
        // setVersion((new Date()).toString());
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      setLoading(false);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  const updateAmount = async () => {
    setLoading(true);
    try {
      const postData = {};
      Object.keys(itemsSelected).forEach((is) => {
        const [influencer, offer] = is.split('_');
        postData[is] = {
          enabled: itemsSelected[is],
          allDeals: ((items.find(item => item.influencer._id === influencer && item.offer._id === offer))?.deals?.map(d => d._id) || []),
        };
      });
      const resp = await axios.post('/api/portal/reports/post-save-duplicate', {
        influencers: postData,
      });
      if (resp.data.success) {
        setItemsSelected({});
        addNotice({
          message: 'Statuses have been changed successfully',
          type: 'success',
        });
        await fetchAmountReport();
        setLoading(false);
        // setVersion((new Date()).toString());
      } else {
        addNotice({
          message: resp.data.error,
          type: 'error',
        });
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      setLoading(false);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  useEffect(() => {
    fetchAmountReport();
  }, []);
  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Creator, Deal Owner or Offer...',
    sortBy: 'revenue',
    direction: 'desc',
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Creator',
        accessor: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
        id: 'Creator',
        Cell: p => useMemo(() => (
          <Tooltip title="Open Creator Profile">
            <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.influencer?._id}`}>{p.value}</a>
          </Tooltip>), [p.row.original]),
        width: 200,
      },
      {
        Header: 'Offer',
        accessor: p => p?.offer?.name || '',
        id: 'offer',
        Cell: p => p.value,
        width: 200,
      },
      {
        Header: 'Deal Owner(s)',
        accessor: (p) => {
          const dOwn = {};
          p.deals.forEach((deal) => {
            if (deal.adminUser) dOwn[deal.adminUser._id] = deal.adminUser;
          });
          return Object.values(dOwn).map(d => `${d.firstName} ${d.lastName}`).join(' ');
        },
        id: 'dOwn',
        Cell: (p) => {
          const dOwn = {};
          p.row.original.deals.forEach((deal) => {
            if (deal.adminUser) dOwn[deal.adminUser._id] = deal.adminUser;
          });
          return (
            <ul className="list-unstyled">
              {Object.values(dOwn).map(d => (
                <li key={uid(d._id)}>
                  {d.firstName || ''} {d.lastName || ''}
                </li>))}
            </ul>);
        },
        width: 200,
      },
      {
        Header: 'Deals',
        accessor: p => p?.deals[0]?.dealname || '',
        id: 'deals',
        Cell: p => useMemo(() => (
          <ul className="list-unstyled">
            {p.row.original.deals.map(d => (
              <li key={uid(d._id)}>
                <RadioButton
                  name={`${p.row.original.influencer._id}_${p.row.original.offer._id}`}
                  label={(<HubspotDealLink title={d.dealname} id={d.hs_object_id} />)}
                  value={itemsSelected[`${p.row.original.influencer._id}_${p.row.original.offer._id}`] || null}
                  radioValue={d._id}
                  onChange={value => value && setItemsSelected({ ...itemsSelected, [`${p.row.original.influencer._id}_${p.row.original.offer._id}`]: d._id })}
                />
              </li>))}
          </ul>), [p.row.original, itemsSelected[`${p.row.original.influencer._id}_${p.row.original.offer._id}`]]),
        width: 300,
      },
    ],
    [itemsSelected],
  );
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Duplicate Active Deals Audit', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Duplicate Active Deals Audit</h3>
                <div className="grey-small-text">
                  Select the one Active deal in the Deals column. The remaining deals will be marked as Expired. Click on the deal name to review the deal in HubSpot
                </div>
              </CardTitle>
              <Row className="mt-3" >
                <Col />
                <Col lg="auto">
                  <Label>{' '}</Label>
                  <div>
                    {items.length > 0 ? (
                      <div className="float-right mr-2">
                        <Button
                          disabled={Object.values(itemsSelected).length === 0 || loading}
                          color="primary"
                          className="mt-2"
                          onClick={() => setItemsSelected({})}
                        >
                          Reset
                        </Button>
                        <DownloadCSVButton
                          records={items}
                          className="mt-2"
                          fileName="ActiveDealsReport.csv"
                          csvArrayColumns={[
                            {
                              label: 'Creator',
                              func: deal => `${deal.influencer?.first_name || ''} ${deal.influencer?.last_name || ''}`,
                            },
                            {
                              label: 'Offer',
                              func: p => p?.offer?.name || '',
                            },
                            {
                              label: 'Deal Owner',
                              func: (p) => {
                                const dOwn = {};
                                p.deals.forEach((deal) => {
                                  if (deal.adminUser) dOwn[deal.adminUser._id] = deal.adminUser;
                                });
                                return Object.values(dOwn).map(d => `${d.firstName} ${d.lastName}`).join(', ');
                              },
                            },
                            {
                              label: 'Deals',
                              func: p => (p.deals.map(d => `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${d.hs_object_id}/`)).join(', '),
                            },
                         ]}
                        >
                          Download CSV
                        </DownloadCSVButton>
                        <Button
                          disabled={Object.values(itemsSelected).length === 0 || loading}
                          color="primary"
                          className="mt-2"
                          onClick={() => updateAmount()}
                        >
                          Save Selected
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  {!loading && items.length > 0 ? (
                    <ReactTableBase
                      key="searchable"
                      columns={columns}
                      data={items}
                      tableConfig={tableConfig}
                    />) : null
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
