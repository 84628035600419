import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row, Col, Container, Card, CardBody, CardTitle,
} from 'reactstrap';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import { NoResourceError } from '../../../../shared/components/components/ui/error404';
import Table from './components/Table';
import Actions from './components/Actions';
import NotesModal from './components/modals/NotesModal';
import getTableColumns from './components/custom/TableColumns';
import UpdateReviewStatus from './components/modals/UpdateReviewStatus';
import StatusHistoryModal from './components/modals/StatusHistoryModal';
import SafetyCheckModal from './components/modals/SafetyCheckModal';

const CreatorReview = ({
  resources, brandSafetySearchTerms,
}) => {
 

  const [disableNote, setDisableNote] = useState(false);
  const [currentContact, setCurrentContact] = useState({});
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showStatusHistoryModal, setShowStatusHistoryModal] = useState(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [version, setVersion] = useState((new Date()).toString());
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [openTabs, setOpenTabs] = useState(false);
  // const [metaTotal, setMetaTotal] = useState(0);

  const customColumns = useMemo(() => getTableColumns(setDisableNote, setCurrentContact, setShowNotesModal, setVersion, setShowStatusHistoryModal, setOpenTabs), []);

  const handleSelectCheckbox = (selectedIds) => {
    setSelectedContactIds(selectedIds);
  };

  const downloadCsv = () => {
    const search = new URLSearchParams(window.location.search);
    const url = `/api/portal/hubil/get-creator-safety-review-csv?${search.toString()}`;
    const win = window.open(url, '_blank');
    win.focus();
  };
  const closeStatusModal = (updated = false) => {
    setShowUpdateStatusModal(false);
    if (updated) {
      setVersion(new Date().toString());
    }
  };
  const resource = resources.find(r => r.id === 'HubilContact');
  if (!resource) {
    return (<NoResourceError resourceId="HubilContact" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Compliance', path: false },
              { title: 'Creator Safety Review', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <h3>Creator Safety Review</h3>
                <hr className="mt-3 mb-4" />
              </CardTitle>
              <Actions
                setShowUpdateStatusModal={setShowUpdateStatusModal}
                selectedCommentIds={selectedContactIds}
                downloadCsv={downloadCsv}
              />
              <Row>
                <Table
                  action={action}
                  resource={resource}
                  version={version}
                  customColumns={customColumns}
                  handleSelectCheckbox={handleSelectCheckbox}
                />
              </Row>
            </CardBody>
          </Card>
          <NotesModal
            isOpen={showNotesModal}
            setVersion={setVersion}
            disabled={disableNote}
            closeFunc={() => { setShowNotesModal(false); }}
            record={currentContact}
          />
          <UpdateReviewStatus
            isOpen={showUpdateStatusModal}
            closeFunc={closeStatusModal}
            ids={selectedContactIds}
          />
          <StatusHistoryModal
            record={currentContact}
            modal={showStatusHistoryModal}
            setModalVisible={setShowStatusHistoryModal}
          />
          <SafetyCheckModal
            record={currentContact}
            openTabs={openTabs}
            setOpenTabs={setOpenTabs}
            searchTerms={brandSafetySearchTerms}
          />
        </Col>
      </Row>
    </Container>
  );
};
CreatorReview.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandSafetySearchTerms: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  
  resources: state.resources,
  brandSafetySearchTerms: state.ResearchReducer.brandSafetySearchTerms,
});

export default connect(mapStateToProps)(withNotice(CreatorReview));
