/* eslint react/prop-types: 0 */
import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip as TooltipOnLine,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';


import { formatIntNumber, formatCurrency } from '../../../../shared/helpers/WVFormatter';

const dataOptions = [
  { value: 'revenue', label: 'Gross Revenue By Day' },
  { value: 'revenue-total', label: 'Gross Revenue Over Time' },
  { value: 'revenue-month', label: 'Gross Revenue By Month' },
  { value: 'profit', label: 'Gross Profit By Day' },
  { value: 'profit-total', label: 'Gross Profit Over Time' },
  { value: 'profit-month', label: 'Gross Profit By Month' },
  { value: 'conversionsCount', label: 'Revenue Conversions Count By Day' },
  { value: 'conversionsCount-total', label: 'Revenue Conversions Count Over Time' },
  { value: 'conversionsCount-month', label: 'Revenue Conversions Count By Month' },
];

const colorOptions = [
  '#e6194B',
  '#ffbb28',
  '#0088FE',
  '#911eb4',
  '#ff8042',
  '#13c450',
  '#d861b7',
];

const chartMargins = {
  top: 5,
  right: 0,
  left: 45,
  bottom: 40,
};

const CustomizedTooltip = (props) => {
  // const prefix = props.selected !== 'conversionsCount' ? '$' : '';
  const getText = (name, value) => `${name}: ${props.selected.split('-')[0] !== 'conversionsCount' ? formatCurrency(value) : formatIntNumber(value)}`;
  if (props.active && props.payload && props.payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'hsla(0, 0%, 100%, .8)',
          border: '1px solid #f5f5f5',
          lineHeight: '24px',
          margin: '0',
          padding: '10px',
        }}
      >
        <p className="bold-text">{props.label}</p>
        <ul
          style={{
            listStyle: 'none',
            padding: '0',
            margin: '0',
          }}
        >
          {props.payload?.map((item, index) => (
            <li
              key={item.name}
              style={{
                color: colorOptions[index],
              }}
            >
              {getText(item.name, item.value)}
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};
const CustomizedXAxisTick = ({ x, y, payload }) => {
  const values = [16, 26, 40];
  const children = payload.value?.includes('-')
    ? payload.value.split(' ').map(((string, index) => (
      <text x={0} y={0} dy={values[index]} textAnchor="middle" fill="#666">
        {string}
      </text>
    ))) : (
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    );
  return (
    <g transform={`translate(${x},${y})`}>
      {children}
    </g>
  );
};

const CustomizedYAxisTick = ({
  x, y, payload, selected,
}) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} textAnchor="end" fill="#666">
      {selected.split('-')[0] !== 'conversionsCount' ? '$' : ''}{formatIntNumber(payload.value)}
    </text>
  </g>
);

const ChartComponent = ({
  selectedAdmins,
  startDate,
  endDate,
}) => {
  const [selectedOption, setSelectedOption] = useState(dataOptions[0]);
  const [opacity, setOpacity] = useState({});
  const [top7, setTop7] = useState([]);
  const getCurrentChartData = () => {
    const startMoment = moment(startDate).startOf('day');
    const endMoment = moment() > moment(endDate).endOf('day') ? moment(endDate).endOf('day') : moment().endOf('day');
    const chartData = {};
    const chartDataMonth = {};
    const days = endMoment.diff(startMoment, 'day');
    const months = endMoment.diff(startMoment, 'month');
    const [keysInValue, total] = selectedOption.value.split('-');
    const top7ByValue = {};
    selectedAdmins.forEach(({ data, option }) => {
      Object.keys(data).forEach((key) => {
        const periodsDataItem = data[key];
        if (!top7ByValue[option._id]) top7ByValue[option._id] = { value: 0, data, option };
        top7ByValue[option._id].value += periodsDataItem[keysInValue];
      });
    });
    console.log(top7ByValue);
    const values = Object.values(top7ByValue).sort((a, b) => b.value - a.value)
      .slice(0, 7)
      .map(({ option, data }) => ({ option, data }));
    for (let i = 0; i <= days; i += 1) {
      const momentDate = startMoment.clone().add(i, 'day');
      const key = momentDate.format('YYYY-MM-DD');
      chartData[key] = { date: momentDate.format('M/D/YY') };
      values.forEach(({ option }) => { chartData[key][option._id] = 0; });
    }
    for (let i = 0; i <= months; i += 1) {
      const momentDate = startMoment.clone().add(i, 'month');
      const key = momentDate.format('YYYY-MM');
      chartDataMonth[key] = { date: momentDate.format('M/YY') };
      values.forEach(({ option }) => { chartDataMonth[key][option._id] = 0; });
    }
    console.log(values);

    values.forEach(({ data, option }) => {
      Object.keys(data).forEach((key) => {
        const byMonthKey = key.substring(0, 7);
        const periodsDataItem = data[key];
        if (!chartData[key]) chartData[key] = { date: key };
        chartData[key][option._id] = periodsDataItem[keysInValue];
        if (!chartDataMonth[byMonthKey]) chartDataMonth[byMonthKey] = { date: byMonthKey };
        chartDataMonth[byMonthKey][option._id] += periodsDataItem[keysInValue];
      });
    });
    let beforeValue = {};
    if (total === 'total') {
      Object.keys(chartData).forEach((day) => {
        const valueForDays = chartData[day];
        // const beforeValueItem = { ...chartData[day] };
        // console.log(beforeValue, 'prev');
        Object.keys(valueForDays).forEach((key) => {
          if (key === 'date') return false;
          chartData[day][key] += Number(beforeValue[key] || 0);
          return true;
        });
        // console.log(beforeValue, 'prev');
        beforeValue = { ...chartData[day] };
        // console.log(beforeValue, 'next');
      });
    }

    setTop7(values);
    console.log(Object.values(chartData));
    return total === 'month' ? Object.values(chartDataMonth) : Object.values(chartData);
  };
  // const [currentChartData] = useState(getCurrentChartData());

  const currentChartData = useMemo(getCurrentChartData, [selectedAdmins, selectedOption]);

  // console.log(currentChartData);
  const handleMouseEnter = (dataKey) => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(companyName => localOpacity[companyName] = companyName === dataKey ? 1 : 0.2);
    setOpacity(localOpacity);
  };

  const handleMouseLeave = () => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(companyName => localOpacity[companyName] = 1);
    setOpacity(localOpacity);
  };
  useEffect(() => {
    const localOpacity = {};
    // eslint-disable-next-line no-return-assign
    top7.forEach(({ option }) => { localOpacity[option._id] = 1; });
    setOpacity(localOpacity);
  }, [top7]);
  // console.log(opacity);
  return (
    <Row>
      <Col>
        <>
          {currentChartData.length ? (
            <>
              <div
                className="form-group mt-3"
                style={{ width: '250px' }}
              >
                <Select
                  placeholder="Select Property to Chart..."
                  value={selectedOption}
                  options={dataOptions}
                  onChange={setSelectedOption}
                />
              </div>
              <Row>
                <Col md={10} style={{ paddingRight: '0' }}>
                  <ResponsiveContainer
                    width="100%"
                    height={400}
                    debounce={1}
                  >
                    <LineChart
                      width="100%"
                      height="100%"
                      data={currentChartData}
                      margin={chartMargins}
                    >
                      {top7.map(({ option }, index) => (
                        <Line
                          key={`line${option._id}${selectedOption?.value}`}
                          type="monotone"
                          dataKey={option._id}
                          name={`${option.label}`}
                          stroke={colorOptions[index]}
                          strokeOpacity={opacity[option._id] || 1}
                          strokeWidth={2}
                          dot={false}
                        />
                    ))}
                      <XAxis
                        dataKey="date"
                        // padding={{ left: 8, right: 8 }}
                        tick={<CustomizedXAxisTick />}
                        interval="preserveStartEnd"
                      />
                      <YAxis
                        // padding={{ top: 8, bottom: 8 }}
                        tick={<CustomizedYAxisTick selected={selectedOption?.value} />}
                        domain={[0, 'dataMax']}
                        interval="preserveStartEnd"
                        allowDecimals={false}
                        // tickFormatter={value => (selectedOption?.value !== 'conversionsCount' ? formatCurrency(value) : value)}
                      />
                      <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                      <TooltipOnLine content={<CustomizedTooltip selected={selectedOption?.value} />} />
                    </LineChart>
                  </ResponsiveContainer>
                </Col>
                <Col
                  md={2}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div>
                    <ul
                      style={{
                        listStyle: 'none',
                        paddingLeft: '0',
                      }}
                    >
                      {top7.map(({ option }, index) => (
                        <li
                          key={`legend${option._id}${selectedOption?.value}`}
                          style={{
                            color: colorOptions[index],
                            cursor: 'pointer',
                            fontWeight: '600',
                          }}
                          onMouseEnter={() => handleMouseEnter(option._id)}
                          onMouseLeave={handleMouseLeave}
                        >
                          &#9903;  {`${option.label}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <span className="mt-2">No data to chart</span>
          )}
        </>
      </Col>
    </Row>
  );
};

ChartComponent.propTypes = {
  selectedAdmins: PropTypes.arrayOf(PropTypes.any),
};

ChartComponent.defaultProps = {
  selectedAdmins: [],
};

export default ChartComponent;
