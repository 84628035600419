import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  ButtonToolbar,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import withNotice from '../../App/store/with-notice';
import CheckBoxField from '../../../shared/components/CheckBox';


class DocuSignSettings extends PureComponent {
  static options(data) {
    return Object.keys(data).map(k => ({ value: k, label: data[k] }));
  }

  constructor() {
    super();
    this.state = {
      enableCreatorPortal: 0,
      cpaServiceAgreementTemplateId: null,
      cpaServiceAgreementTemplateIds: [],
      upfrontServiceAgreementTemplateId: null,
      upfrontServiceAgreementTemplateIds: [],
      upfrontCpmServiceAgreementTemplateId: null,
      upfrontCpmServiceAgreementTemplateIds: [],
      hybridServiceAgreementTemplateId: null,
      hybridServiceAgreementTemplateIds: [],
      keenServiceProviderAgreement: null,
      keenServiceProviderAgreements: [],
      medialicenseServiceAgreement: null,
      medialicenseServiceAgreements: [],
      bonusServiceAgreementTemplateId: null,
      bonusServiceAgreementTemplateIds: [],
      dsTemplates: [],
      isLoadingData: false,
      errors: {},
    };
  }

  componentDidMount() {
    document.title = 'DocuSign Settings';
    this.fetchData();
  }

  onSubmit = () => {
    if (!this.validateErrors()) { return false; }
    if (this.state.isLoadingData) { return false; }
    this.setState({ isLoadingData: true });

    axios({
      method: 'post',
      url: '/api/portal/docusign-settings',
      data: {
        enableCreatorPortal: this.state.enableCreatorPortal,
        cpaServiceAgreementTemplateId: this.state.cpaServiceAgreementTemplateId,
        cpaServiceAgreementTemplateIds: this.state.cpaServiceAgreementTemplateIds,
        upfrontServiceAgreementTemplateId: this.state.upfrontServiceAgreementTemplateId,
        upfrontServiceAgreementTemplateIds: this.state.upfrontServiceAgreementTemplateIds,
        upfrontCpmServiceAgreementTemplateId: this.state.upfrontCpmServiceAgreementTemplateId,
        upfrontCpmServiceAgreementTemplateIds: this.state.upfrontCpmServiceAgreementTemplateIds,
        hybridServiceAgreementTemplateId: this.state.hybridServiceAgreementTemplateId,
        hybridServiceAgreementTemplateIds: this.state.hybridServiceAgreementTemplateIds,
        keenServiceProviderAgreement: this.state.keenServiceProviderAgreement,
        keenServiceProviderAgreements: this.state.keenServiceProviderAgreements,
        medialicenseServiceAgreement: this.state.medialicenseServiceAgreement,
        medialicenseServiceAgreements: this.state.medialicenseServiceAgreements,
        bonusServiceAgreementTemplateId: this.state.bonusServiceAgreementTemplateId,
        bonusServiceAgreementTemplateIds: this.state.bonusServiceAgreementTemplateIds,
      },
    }).then((response) => {
      if (response.data.success) {
        this.props.addNotice({
          message: 'DocuSign settings have been updated successfully.',
          type: 'success',
        });

        this.props.history.push('/');
      } else {
        this.processSubmitError(response.data.error);
      }
    }).catch((error) => {
      this.processSubmitError(error.message);
    });
    return null;
  }

  onCancel = () => {
    this.props.history.goBack();
  }

  fetchData() {
    this.setState({
      isLoadingData: true,
    });
    axios.get('/api/portal/docusign-settings').then((response) => {
      if (response.data.success) {
        const {
          docusignSettings,
          dsTemplates,
        } = response.data;

        const cpaServiceAgreementTemplateIds = docusignSettings.cpaServiceAgreementTemplateIds || [];
        cpaServiceAgreementTemplateIds.push(null);

        const upfrontServiceAgreementTemplateIds = docusignSettings.upfrontServiceAgreementTemplateIds || [];
        upfrontServiceAgreementTemplateIds.push(null);

        const upfrontCpmServiceAgreementTemplateIds = docusignSettings.upfrontCpmServiceAgreementTemplateIds || [];
        upfrontCpmServiceAgreementTemplateIds.push(null);

        const hybridServiceAgreementTemplateIds = docusignSettings.hybridServiceAgreementTemplateIds || [];
        hybridServiceAgreementTemplateIds.push(null);

        const keenServiceProviderAgreements = docusignSettings.keenServiceProviderAgreements || [];
        keenServiceProviderAgreements.push(null);

        const medialicenseServiceAgreements = docusignSettings.medialicenseServiceAgreements || [];
        medialicenseServiceAgreements.push(null);

        const bonusServiceAgreementTemplateIds = docusignSettings.bonusServiceAgreementTemplateIds || [];
        bonusServiceAgreementTemplateIds.push(null);

        dsTemplates.unshift({ value: '', label: '[Not Specified]' });

        this.setState({
          enableCreatorPortal: (+response.data.docusignSettings.enableCreatorPortal).toString(),
          cpaServiceAgreementTemplateId: response.data.docusignSettings.cpaServiceAgreementTemplateId,
          cpaServiceAgreementTemplateIds,
          upfrontServiceAgreementTemplateId: response.data.docusignSettings.upfrontServiceAgreementTemplateId,
          upfrontServiceAgreementTemplateIds,
          upfrontCpmServiceAgreementTemplateId: response.data.docusignSettings.upfrontCpmServiceAgreementTemplateId,
          upfrontCpmServiceAgreementTemplateIds,
          hybridServiceAgreementTemplateId: response.data.docusignSettings.hybridServiceAgreementTemplateId,
          hybridServiceAgreementTemplateIds,
          keenServiceProviderAgreement: response.data.docusignSettings.keenServiceProviderAgreement,
          keenServiceProviderAgreements,
          medialicenseServiceAgreement: response.data.docusignSettings.medialicenseServiceAgreement,
          medialicenseServiceAgreements,
          bonusServiceAgreementTemplateId: response.data.docusignSettings.bonusServiceAgreementTemplateId,
          bonusServiceAgreementTemplateIds,
          dsTemplates: response.data.dsTemplates,
          isLoadingData: false,
        });
      }
    }).catch((error) => {
      console.log(error);
      this.setState({
        isLoadingData: false,
      });
    });
  }

  templateName = dealtype => (['keenServiceProviderAgreement', 'medialicenseServiceAgreement'].includes(dealtype) ? dealtype : `${dealtype}ServiceAgreementTemplateId`);

  handleChange = (name, value) => {
    const state = {
      ...this.state,
      [name]: value,
    };
    this.setState(state);
  }

  handleChangeArray = (name, index, e) => {
    const arr = this.state[name];
    arr[index] = e.value || null;
    if (arr.filter(row => row === null).length === 0) {
      arr.push(null);
    }
    const state = {
      ...this.state,
      [name]: arr,
    };
    delete state.errors[name];
    this.setState(state);
    this.forceUpdate();
  }

  handleChangeEnable = (e) => {
    this.handleChange('enableCreatorPortal', e.value);
  }

  handleChangeCpaTemplateId = (e) => {
    this.handleChange('cpaServiceAgreementTemplateId', e.value);
  }

  handleChangeUpfrontTemplateId = (e) => {
    this.handleChange('upfrontServiceAgreementTemplateId', e.value);
  }

  handleChangeUpfrontCpmTemplateId = (e) => {
    this.handleChange('upfrontCpmServiceAgreementTemplateId', e.value);
  }

  handleChangeHybridTemplateId = (e) => {
    this.handleChange('hybridServiceAgreementTemplateId', e.value);
  }

  handleChangeKeenTemplateId = (e) => {
    this.handleChange('keenServiceProviderAgreement', e.value);
  }

  handleChangeMedialicenseTemplateId = (e) => {
    this.handleChange('medialicenseServiceAgreement', e.value);
  }

  handleChangeBonusTemplateId = (e) => {
    this.handleChange('bonusServiceAgreementTemplateId', e.value);
  }

  handleChangeDefaultTemplate = (e, index, dealtype) => {
    if (e && e.target) {
      const { checked } = e.target;
      const name = this.templateName(dealtype);
      if (checked) {
        const listName = `${name}s`;
        const selected = this.state[listName];
        const { errors } = this.state;
        delete errors[listName];
        this.setState({ [name]: selected[index] || null, errors });
      } else {
        this.setState({ [name]: null });
      }
    }
  }

  validateErrors() {
    const errors = {};
    const keys = [
      'cpaServiceAgreementTemplateId',
      'upfrontServiceAgreementTemplateId',
      'upfrontCpmServiceAgreementTemplateId',
      'hybridServiceAgreementTemplateId',
      'keenServiceProviderAgreement',
      'medialicenseServiceAgreement',
      'bonusServiceAgreementTemplateId',
    ];
    keys.forEach((defaultKey) => {
      const defaultVal = this.state[defaultKey];
      const key = `${defaultKey}s`;
      const val = this.state[key]?.filter(id => id);
      if (val && val.length > 1 && !defaultVal) {
        errors[key] = 'Please select default template.';
      }
      if (!val || val.length === 0) {
        errors[key] = 'Please select template.';
      }
    });
    this.setState({ errors });
    return Object.entries(errors).length === 0;
  }

  processSubmitError(error) {
    const state = {
      ...this.state,
      isLoadingData: false,
    };

    this.props.addNotice({
      message: error,
      type: 'error',
    });

    this.setState(state);
  }

  selectTemplates(dealtype) {
    const { dsTemplates } = this.state;
    const defaultName = this.templateName(dealtype);
    const name = `${defaultName}s`;
    const selected = this.state[name];
    const defaultTemplate = this.state[defaultName];
    /* eslint-disable react/no-array-index-key */
    return selected.map((id, index) => (
      <Row key={`${dealtype}-${index}`}>
        <Col sm="9">
          <Select
            id={`cpa-${id}`}
            value={dsTemplates.filter(option => option.value === id)}
            options={dsTemplates.filter(option => option.value === id || !selected.includes(option.value))}
            onChange={e => this.handleChangeArray(name, index, e)}
          />
        </Col>
        <Col sm="3" className="pl-3 pt-2">
          {id && selected.filter(_id => _id).length > 1 && (
            <CheckBoxField
              name={`${dealtype}-${index}-default`}
              value={id === defaultTemplate}
              label="Default?"
              onChange={e => this.handleChangeDefaultTemplate(e, index, dealtype)}
            />
          )}
        </Col>
      </Row>
    ));
  }

  render() {
    const {
      enableCreatorPortal,
      isLoadingData,
      errors,
    } = this.state;
    const enableCreatorPortalOptions = this.constructor.options(['Off', 'On']);
    return (
      <div>
        {isLoadingData && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        {!isLoadingData &&
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="page-title">DocuSign Settings</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 6, offset: 3 }} sm={12}>
                <Card>
                  <CardBody>
                    <section>
                      <FormGroup>
                        <Label>Enable in Creator Portal</Label>
                        <Select
                          value={enableCreatorPortalOptions.filter(option => option.value === enableCreatorPortal)}
                          options={enableCreatorPortalOptions}
                          onChange={this.handleChangeEnable}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>CPA Service Agreement Template</Label>
                        {this.selectTemplates('cpa')}
                        {errors.cpaServiceAgreementTemplateIds && (<div className="form__form-group-error">{errors.cpaServiceAgreementTemplateIds}</div>)}
                      </FormGroup>
                      <FormGroup>
                        <Label>Upfront Service Agreement Template</Label>
                        {this.selectTemplates('upfront')}
                        {errors.upfrontServiceAgreementTemplateIds && (<div className="form__form-group-error">{errors.upfrontServiceAgreementTemplateIds}</div>)}
                      </FormGroup>
                      <FormGroup>
                        <Label>Upfront CPM Service Agreement Template</Label>
                        {this.selectTemplates('upfrontCpm')}
                        {errors.upfrontCpmServiceAgreementTemplateIds && (<div className="form__form-group-error">{errors.upfrontCpmServiceAgreementTemplateIds}</div>)}
                      </FormGroup>
                      <FormGroup>
                        <Label>Hybrid Service Agreement Template</Label>
                        {this.selectTemplates('hybrid')}
                        {errors.hybridServiceAgreementTemplateIds && (<div className="form__form-group-error">{errors.hybridServiceAgreementTemplateIds}</div>)}
                      </FormGroup>
                      <FormGroup>
                        <Label>Keen Service Provider Agreement Template</Label>
                        {this.selectTemplates('keenServiceProviderAgreement')}
                        {errors.keenServiceProviderAgreements && (<div className="form__form-group-error">{errors.keenServiceProviderAgreements}</div>)}
                      </FormGroup>
                      <FormGroup>
                        <Label>Media License Agreement Template</Label>
                        {this.selectTemplates('medialicenseServiceAgreement')}
                        {errors.medialicenseServiceAgreements && (<div className="form__form-group-error">{errors.medialicenseServiceAgreements}</div>)}
                      </FormGroup>
                      <FormGroup>
                        <Label>Bonus Service Agreement Template</Label>
                        {this.selectTemplates('bonus')}
                        {errors.bonusServiceAgreementTemplateIds && (<div className="form__form-group-error">{errors.bonusServiceAgreementTemplateIds}</div>)}
                      </FormGroup>
                    </section>
                    <ButtonToolbar className="form__button-toolbar mt-5">
                      <Button
                        className="px-5 btn btn-sm"
                        type="button"
                        color="primary"
                        onClick={this.onSubmit}
                      >
                        {this.state.isLoadingData && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                        <span>Save</span>
                      </Button>
                      <Button
                        className="px-5 btn btn-sm"
                        type="button"
                        onClick={this.onCancel}
                        disabled={this.state.isLoadingData}
                      >
                        Cancel
                      </Button>
                    </ButtonToolbar>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </div>
    );
  }
}

DocuSignSettings.propTypes = {
  addNotice: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withNotice(DocuSignSettings);
