import React, { useMemo } from 'react';
// import moment from 'moment';
import { Modal, ButtonToolbar, Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import formater from '../../../../../shared/helpers/WVFormatter';
// import formater from '../../../../../shared/helpers/WVFormatter';

const CalculationResultsAgent = ({
  modal,
  closeDialog,
  // title,
  calcData,
  record,
}) => {

  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: r => r.status || '',
        Cell: p => p.value,
      },
      {
        Header: 'Creator',
        accessor: r => `${r?.influencer?.first_name} ${r?.influencer?.last_name}`.toLowerCase(),
        Cell: p => useMemo(() => `${p.row.original?.influencer?.first_name} ${p.row.original?.influencer?.last_name}`, [p.row.original]),
      },
      {
        Header: 'Creator Email',
        accessor: r => (r?.influencer.email.toLowerCase() || ''),
        Cell: p => p.row.original?.influencer?.email,
        minWidth: 200,
      },
      {
        Header: 'Amount',
        id: 'amount',
        accessor: r => Number(r?.ilPayoutAmount) || 0,
        Cell: p => formater.formatCurrency(p.value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.amount) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
    ],
    [],
  );
  // console.log(calcData?.payables);
  const totalCreatorPayout = useMemo(() => calcData?.payables?.reduce((sum, row) => Number(row.ilPayoutAmount) + sum, 0), [calcData?.payables]);
  if (!record.deal) {
    return null;
  }
  return (
    <Modal
      size="lg"
      isOpen={modal}
      toggle={closeDialog}
      style={{ minWidth: '900px', width: '100%' }}
    >
      <div className="modal__header">
        <h4 className="text-modal modal__title">Agent Result Calculation</h4>
      </div>
      <div className="ltr-support theme-light">
        {calcData?.deal ? (
          <div>
            <div><b>Comission Percentage</b> : {calcData?.deal?.dealTerms.commission_percentage || '-'}</div>
            <div><b>Comission Amount</b> : {formater.formatCurrency(calcData?.deal?.dealTerms.commission_amount || 0)}</div>
            <div><b>Total Creator Payables</b> : { formater.formatCurrency(totalCreatorPayout || 0)}</div>
            <div><b>Total Agent Payable</b> : {formater.formatCurrency(record?.params?.ilPayoutAmount)}</div>
            <div><b>Calculated Total Agent Payable</b> :&nbsp;
              {formater.formatCurrency((calcData?.deal?.dealTerms.commission_percentage ? ((Number(calcData?.deal?.dealTerms.commission_percentage) * totalCreatorPayout) / 100) : record.deal?.dealTerms.commission_amount)) }
            </div>
            <div className="mt-3">
              <ReactTableBase
                key="searchable"
                columns={columns}
                data={calcData?.payables || []}
                tableConfig={tableConfig}
              />
            </div>
          </div>
        ) : (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
      </div>
      {calcData ? (
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel btn-sm" color="danger" onClick={closeDialog}>Close</Button>
        </ButtonToolbar>
      ) : null}
    </Modal>
  );
};
CalculationResultsAgent.propTypes = {
  modal: PropTypes.bool.isRequired,
  // title: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  calcData: PropTypes.objectOf(PropTypes.any),
};
CalculationResultsAgent.defaultProps = {
  calcData: null,
};
export default CalculationResultsAgent;
