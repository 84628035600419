import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import formater from '../../../../shared/helpers/WVFormatter';
import Item from '../StatItem';


const Total = ({ stats, removeTotal }) => (
  <Row className="" >
    <Col>
      <Item
        items={[{
          label: 'Gross Revenue',
          value: formater.formatCurrency(stats.grossRevenue),
        }]}
      />
    </Col>
    <Col>
      <Item
        items={[{
          label: 'Gross Profit',
          value: formater.formatCurrency(stats.grossProfit),
        }]}
      />
    </Col>
    {!removeTotal ? (
      <Col>
        <Item
          items={[{
            label: 'Active Creators',
            value: formater.formatIntNumber(stats.count),
          }]}
        />
      </Col>
    ) : null}
    <Col>
      <Item
        items={[{
          label: 'Clicks',
          value: formater.formatIntNumber(stats.clicks),
        }]}
      />
    </Col>
    <Col>
      <Item
        items={[{
          label: 'Revenue Conversions',
          value: formater.formatIntNumber(stats.conversions),
        }]}
      />
    </Col>

  </Row>);
Total.propTypes = {
  stats: PropTypes.objectOf(PropTypes.any).isRequired,
  removeTotal: PropTypes.bool,
};
Total.defaultProps = {
  removeTotal: false,
};
export default Total;
