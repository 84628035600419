import React, { useState} from 'react';
// import RefreshIcon from 'mdi-react/RefreshIcon';
import { 
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
 } from 'reactstrap';
import AgentForm from '../../../../AgentUser/components/custom/AgentForm';
import Hook from '../../../../../../../shared/hooks';

const DashboardActions = ({ downloadCsv }) => {
  const [agentDialogVisible, setAgentDialogVisible] = useState(false);
  const refreshTable = Hook.useReloadTableHook();

  return (
    <>
      <UncontrolledButtonDropdown className="w-100">
        <DropdownToggle
          id="actionsMenu"
          caret
          color="primary"
          style={{
            padding: '4px 25px',
            height: '42px',
          }}
        >
          Actions
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => setAgentDialogVisible(true)}>New Agent</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => downloadCsv()}>Download CSV</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      <AgentForm
        modal={agentDialogVisible}
        modalTitle="New Agent"
        closeDialog={() => { refreshTable(); setAgentDialogVisible(false); }}
      />
    </>
  );
};
DashboardActions.propTypes = {

};


export default DashboardActions;
