import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, Button, FormGroup, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { axios } from 'ApiClient';
import csvHelper from '../../../../../../../shared/helpers/CSVHelper';
import ProgressBar from '../../../../../../../shared/helpers/ProgressBarWithObject';
import RadioButton from '../../../../../../../shared/components/RadioButton';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';
import { hubspotImport } from '../../../../../../../redux/reducers/admin/ResearchReducer';

const HubspotImportModal = ({
  modal,
  title,
  setModalVisible,
  loading,
  hubspotImportResults,
  selectedProspectIds,
  numberOfFound,
  dispatch,
  hubspotImportSelectAllVisible,
  UPLOAD_LEADS_LIMIT,
}) => {
  const [uploading, setUploading] = useState(false);
  const [selectedList, setSelectedList] = useState('');
  const [includeAll, setIncludeAll] = useState(false);
  const [createDeals, setCreateDeals] = useState(true);
  const [addToList, setAddToList] = useState(false);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [logs, setLogs] = useState({});
  const [listOptions, setListOptions] = useState([]);

  const loadListsOptions = async () => {
    const response = await axios({
      method: 'get',
      url: '/api/portal/hubil/get-all-contact-lists',
    });
    if (response.data.success) {
      const res = [];
      response.data.lists.forEach((record) => {
        res.push({
          value: record.listId,
          label: record.name,
          record,
        });
      });
      setListOptions(res);
    }
  };

  const handleChangeList = (selected) => {
    setSelectedList(selected || null);
  };

  const downloadReport = () => {
    const data = [['First Name', 'Last Name', 'Email', 'Brand', 'Contact URL', 'Deal URL', 'Result']];
    hubspotImportResults.totalCreated.forEach((record) => {
      data.push(Object.values(record));
    });
    hubspotImportResults.errorsOccurred.forEach((record) => {
      data.push(Object.values(record));
    });
    return csvHelper.generateCSV('hubspot-import-results.csv', data);
  };

  const startAdd = () => {
    setUploading(true);
    const query = new URLSearchParams(window.location.search);
    dispatch(hubspotImport(selectedProspectIds, includeAll, createDeals, selectedList || false, query.toString(), identity));
  };
  useEffect(() => { setIncludeAll(false); loadListsOptions(); }, [modal]);
  useEffect(() => {
    console.log('logs: ', logs);
    console.log('logs.errorsOccurred: ', logs?.errorsOccurred);
    console.log('Array.isArray(logs.errorsOccurred): ', Array.isArray(logs?.errorsOccurred));
    console.log('logs.errorsOccurred.length: ', logs?.errorsOccurred?.length);
  }, [logs]);

  const onCloseModal = () => {
    setModalVisible(false);
    setUploading(false);
    setLogs({});
    setCreateDeals(true);
    setIncludeAll(false);
    setAddToList(false);
    setSelectedList('');
  };
  return (
    <Modal
      isOpen={modal}
      toggle={() => { onCloseModal(); }}
    >
      <ModalHeader style={{ padding: 0, paddingBottom: '28px', borderBottom: 'none' }} toggle={() => { onCloseModal(); }} tag="h4">
        <b>{title}</b>
      </ModalHeader>
      {!loading && !uploading && (
        <div className="modal__body finance-form">
          {hubspotImportSelectAllVisible && (
            <>
              <div>{`You have selected ${selectedProspectIds.length} result${selectedProspectIds.length !== 1 ? 's' : ''} to add to the list of Prospects`}</div>
              {numberOfFound > selectedProspectIds.length && (
                <Row className="theme-light radio-in-prospects">
                  <Col md={7}>
                    {parseInt(UPLOAD_LEADS_LIMIT, 10) < numberOfFound ?
                      `Would you like to select first ${WVFormatter.formatIntNumber(UPLOAD_LEADS_LIMIT)} of ${WVFormatter.formatIntNumber(numberOfFound)} results?` :
                      `Would you like to select all ${WVFormatter.formatIntNumber(numberOfFound)} results?`
                    }
                  </Col>
                  <Col md={5}>
                    <RadioButton
                      name="action"
                      label="&nbsp;Yes"
                      value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                      radioValue="includeAll"
                      onChange={(e) => { setIncludeAll(e === 'includeAll'); }}
                    />
                    <RadioButton
                      name="action"
                      label="&nbsp;No"
                      value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                      radioValue="notIncludeAll"
                      onChange={(e) => { setIncludeAll(e === 'includeAll'); }}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
          <Row className="theme-light radio-in-prospects">
            <Col md={7}>
              Create HubSpot Deals for each Contact?
            </Col>
            <Col md={5}>
              <RadioButton
                name="actionCreateDeals"
                label="&nbsp;Yes"
                value={createDeals === true ? 'createDeals' : 'notCreateDeals'}
                radioValue="createDeals"
                onChange={(e) => { setCreateDeals(e === 'createDeals'); }}
              />
              <RadioButton
                name="actionCreateDeals"
                label="&nbsp;No"
                value={createDeals === true ? 'createDeals' : 'notCreateDeals'}
                radioValue="notCreateDeals"
                onChange={(e) => { setCreateDeals(e === 'createDeals'); }}
              />
            </Col>
          </Row>
          <Row className="theme-light radio-in-prospects">
            <Col md={7}>
              Add Contacts to a Hubspot list?
            </Col>
            <Col md={5}>
              <RadioButton
                name="actionAddToList"
                label="&nbsp;Yes"
                value={addToList === true ? 'addToList' : 'notAddToList'}
                radioValue="addToList"
                onChange={(e) => { setAddToList(e === 'addToList'); }}
              />
              <RadioButton
                name="actionAddToList"
                label="&nbsp;No"
                value={addToList === true ? 'addToList' : 'notAddToList'}
                radioValue="notAddToList"
                onChange={(e) => { setAddToList(e === 'addToList'); }}
              />
            </Col>
          </Row>
          {addToList && (
            <FormGroup>
              <CreatableSelect
                name="list"
                placeholder="List"
                value={selectedList}
                options={listOptions}
                onChange={handleChangeList}
                isClearable
              />
            </FormGroup>
          )}
          <div className="text-center mt-3">
            <Button style={{ marginBottom: 0 }} className="btn-sm" color="primary" onClick={startAdd}>Import</Button>
            &nbsp;&nbsp;&nbsp;
            <Button style={{ marginBottom: 0 }} className="modal_cancel btn-sm" color="secondary" onClick={() => { onCloseModal(); }}>Cancel</Button>
          </div>
        </div>
      )}
      {uploading && (
        <div className="modal__body finance-form">
          {loading ?
            (<h3 className="mb-2">Uploading...</h3>) :
            (<h3 className="mb-2">Results:</h3>)
          }
          <ProgressBar
            afterFunction={() => true}
            topic="importProspectsToHubspot"
            isShow
            identity={identity}
            receiveResults={(res) => {
              console.log(res);
              setLogs(res);
            }}
          />
          <div>
            <h4>Leads successfully created: {logs?.totalCreated || 0}</h4>
            <h4>Duplicate contacts found: {logs?.totalDuplicates || 0}</h4>
            <h4>Deals successfully created: {logs?.totalDealsCreated || 0}</h4>
            <h4>Leads not created (errors occurred): {hubspotImportResults?.errorsOccurred && Array.isArray(hubspotImportResults.errorsOccurred) ? hubspotImportResults.errorsOccurred.length : (hubspotImportResults.errorsOccurred || 0)}</h4>
            {!loading && (
              <div className="text-center mt-3">
                <Button style={{ marginBottom: 0 }} className="btn btn-sm" color="primary" onClick={downloadReport}>Download Results</Button>&nbsp;&nbsp;
                <Button style={{ marginBottom: 0 }} className="modal_cancel btn-sm" color="secondary" onClick={() => { onCloseModal(); }}>Close</Button>
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

HubspotImportModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hubspotImportResults: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedProspectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  numberOfFound: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  hubspotImportSelectAllVisible: PropTypes.bool.isRequired,
  UPLOAD_LEADS_LIMIT: PropTypes.string.isRequired,
};

HubspotImportModal.defaultProps = {

};

export default HubspotImportModal;
