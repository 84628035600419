
import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreatorInList from './Table/CreatorInList';
import formater from '../../../../shared/helpers/WVFormatter';
import TableCellContextMenu from '../../../../shared/components/table/TableCellContextMenu';
import { modalId } from './Modals/RebuyPauseModal';
import { modalId as BulkDealCreationModal } from './Modals/BulkDealCreationModal';
import { pauseHistoryModalId } from './Modals/PauseHistoryModal';
import { showModalById } from '../../../../redux/reducers/TableReducer';
import CAC from '../../../../shared/components/cac/CAC';
import PAR from '../../../../shared/components/cac/PAR';
import PredictedCAC from '../../../../shared/components/cac/PredictedCAC';
import { getDefaultTargetCac, getProjectedPercent, calculateDays, calculateConversionsCount, calculatePar } from '../../../../shared/helpers/CACHelper';
import Hook from '@/shared/hooks';
const getCellStyles = (deal) => {
  if (deal.inBlackList) {
    console.log(deal.latestDeal.rebuyPause.history[deal.latestDeal.rebuyPause.history.length - 1].value);
  }
  if (deal.inBlackList && String(deal.latestDeal.rebuyPause.history[deal.latestDeal.rebuyPause.history.length - 1].value) === '9999') {
    return { color: '#ff4861', fontWeight: 'bold' };
  }
  return {};
};

const ColumnsCreators = ({ settings, afterSavePause, projectedSettings }) => {
  const dispatch = useDispatch();
  console.log();
  const [showModal] = Hook.useShowModalHook('mediaContentsCacModal')
  const [showCreateDealModal] = Hook.useShowModalHook(BulkDealCreationModal)
  const s = { settings, projectedSettings };
  /* const getDefaultTargetCac1 = (brand, type) => {
    console.log(settings, [brand._id, type]);
    let targetCac = 450;
    let targetP = settings.types ? (settings.types[type] / 100) || 0.25 : 0.25;
    if (settings.brands[brand._id] && settings.brands[brand._id][type]) {
      targetCac = settings.brands[brand._id][type].cac;
      targetP = settings.brands[brand._id][type].percent / 100;
    }
    return [targetCac * (1 + targetP), targetCac, targetP];
  };

  const getProjectedPercent = (daysCount) => {
    const usedSettings = [];
    const daysCountArray = Object.keys(projectedSettings).map(a => Number(a)).sort((a, b) => a - b);
    let sumPercentUsed = 0;
    let remainingPercent = 0;
    daysCountArray.forEach((k) => {
      const item = projectedSettings[k];
      console.log(item);
      if (Number(k) <= Number(daysCount)) {
        usedSettings.push(item);
        sumPercentUsed += Number(item.value || 0);
      }
      if (Number(item.start) <= Number(daysCount) && Number(item.end) >= Number(daysCount)) {
        console.log((Number(daysCount) - (Number(item.start) - 1)), ((Number(item.end) - Number(item.start)) + 1));
        console.log((Number(daysCount) - (Number(item.start) - 1)) / ((Number(item.end) - Number(item.start)) + 1), Number(item.value));
        remainingPercent = ((Number(daysCount) - (Number(item.start) - 1)) / ((Number(item.end) - Number(item.start)) + 1)) * Number(item.value);
      }
    });
    console.log('projectedPercdntage');
    console.log(daysCount, [sumPercentUsed, remainingPercent]);
    return Number(sumPercentUsed) + Number(remainingPercent);
  }; */
  return [
    {
      Header: 'Creator',
      accessor: p => `${p?.creator?.first_name || ''} ${p?.creator?.last_name || ''}`,
      id: 'Creator',
      Cell: p => useMemo(() => (
        <CreatorInList record={p.row.original.creator} prospects={p.row.original.prospects} />
      ), [p.row.original.creator]),
      width: 300,
    },
    {
      Header: 'Brand',
      accessor: p => (p?.offer?.advertiser?.companyName || ''),
      id: 'brand',
      Cell: (p) => {
        const styles = getCellStyles(p.row.original);
        return (
          <span style={styles}>
            {p.value}
          </span>
        );
      },
      width: 100,
    },
    {
      Header: 'Deal Owner',
      accessor: p => (`${p.admin?.firstName || ''} ${p.admin?.lastName || ''}`),
      id: 'dealOwner',
      Cell: (p) => {
        const styles = getCellStyles(p.row.original);
        return (
          <span style={styles}>
            {p.value}
          </span>
        );
      },
      width: 100,
    },
    {
      Header: 'Deal Name',
      accessor: deal => (deal.latestDeal?.dealname || ''),
      Cell: p => useMemo(() => {
        console.log();
        const styles = getCellStyles(p.row.original);
        return (
          <div style={styles}>
            {p.value}{' '}
            {p.row.original.latestDeal.hs_object_id ? (
              <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original?.latestDeal.hs_object_id}/`} target="_blank" rel="noreferrer">
                <Tooltip title="Open Hubspot Deal">
                  <IconButton size="small">
                    <i className="fab fa-hubspot text-primary" style={{ fontSize: '0.8em' }} />
                  </IconButton>
                </Tooltip>
              </a>) : null}
          </div>
        );
      }, [p.row.original.latestDeal]),
      width: 120,
    },
    {
      Header: 'Type Of Deal',
      accessor: deal => (deal.latestDeal?.dealTerms?.type_of_deal || ''),
      Cell: (p) => {
        const styles = getCellStyles(p.row.original);
        return (
          <span style={styles}>
            {p.value}
          </span>
        );
      },
      width: 125,
    },
    {
      Header: 'Promo Code',
      accessor: deal => (deal.latestDeal?.dealTerms?.coupon_code || ''),
      Cell: (p) => {
        const styles = getCellStyles(p.row.original);
        return (
          <span style={styles}>
            {p.value}
          </span>
        );
      },
      width: 125,
    },
    {
      Header: 'Publish Date',
      accessor: p => p.publishDate || null,
      id: 'publish_date',
      Cell: (p) => {
        const styles = getCellStyles(p.row.original);
        return (
          <span style={styles}>
            {p.value ? moment.tz(moment.utc(p.value).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') : ''}
          </span>
        );
      },
      width: 100,
    },
    {
      Header: 'Days Since Last Publish',
      accessor: p => (p.publishDate ? moment.tz('America/New_York').diff(moment.tz(moment.utc(p.publishDate).format('YYYY-MM-DD'), 'America/New_York'), 'days') : '-'),
      id: 'days',
      Cell: (p) => {
        const styles = getCellStyles(p.row.original);
        return (
          <span style={styles}>
            {p.value}
          </span>
        );
      },
      width: 100,
    },
    {
      Header: 'Revenue',
      accessor: deal => Number(deal?.revenue || 0),
      id: 'revenue',
      // Cell: p => formater.formatCurrency(p.value || 0),
      Cell: (p) => {
        const styles = getCellStyles(p.row.original);
        return (
          <span style={styles}>
            {formater.formatCurrency(p.value || 0)}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: 'Costs',
      accessor: deal => Number(deal?.payout || 0),
      id: 'costs',
      // Cell: p => formater.formatCurrency(p.value || 0),
      Cell: (p) => {
        const styles = getCellStyles(p.row.original);
        return (
          <span style={styles}>
            {formater.formatCurrency(p.value || 0)}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: 'Gross Profit',
      accessor: deal => Number(deal?.revenue || 0) - Number(deal?.payout || 0),
      id: 'gp',
      // Cell: p => formater.formatCurrency(p.value || 0),
      Cell: (p) => {
        const styles = getCellStyles(p.row.original);
        return (
          <span style={styles}>
            {formater.formatCurrency(p.value || 0)}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: 'Conversions',
      accessor: ({ CPApaidConversionsArray, CPMpaidConversions, latestDeal }) => calculateConversionsCount({ CPApaidConversionsArray, dealForCac: latestDeal, CPMpaidConversions: CPMpaidConversions?.length }),
      id: 'conversions',
      // Cell: p => formater.formatIntNumber(p.value),
      Cell: (p) => {
        const styles = getCellStyles(p.row.original);
        return (
          <span style={styles}>
            {formater.formatIntNumber(p.value)}
          </span>
        );
      },
      width: 120,
    },

    {
      Header: 'CAC',
      accessor: deal => (Number(deal.CPApaidConversionsArray.length + deal.CPMpaidConversions.length) !== 0 ? Number(deal.cac || 0) : '-'),
      id: 'cac',
      Cell: p => useMemo(() => (
        <div>
          {p.row.original?.latestDeal.dealTerms.type_of_deal === 'CPA/Ambassador' ? (
            <FontAwesomeIcon icon="thumbs-up" className="text-success" />
          ) : (
            <div>
              <CAC
                dealForCac={p.row.original?.latestDeal}
                CPApaidConversionsArray={p.row.original?.CPApaidConversionsArray}
                CPMpaidConversionsCount={Number(p.row.original.CPMpaidConversions?.length || 0)}
                brand={p.row.original.offer.advertiser}
                settings={s}
              />
              {' '}
              {p.row.original.latestDeal.mediaContents.length > 1 ? (
                <IconButton size="small" onClick={() => showModal({
                  deal: p.row.original.latestDeal,
                  allCPApaidConversionsArray: p.row.original.allCPApaidConversionsArray,
                  allCPMpaidConversions: p.row.original.allCPMpaidConversions
                })}>
                  <i className="fab fa-youtube" style={{ fontSize: '0.8em' }} />
                </IconButton>
                ) : null}
            </div>)
          }
        </div>
      ), [p.value, p.row.original]),

      /* Cell: p => useMemo(() => {
        // if (p.value === '-') return '-';
        const valueNumber = p.value === '-' ? 0 : p.value;
        // eslint-disable-next-line max-len
        const [targetCac, defaultTargetCac, percent] = getDefaultTargetCac(p.row.original.offer.advertiser, p.row.original.latestDeal.dealTerms.type_of_deal, s);
        const value = p.value === '-' ? '-' : formater.formatCurrency(p.value);
        const allow = p.value !== '-' && p.value > 0 && targetCac >= p.value;
        const describeValue = (
          <div style={{ fontSize: '14px', maxWidth: '500px', lineHeight: 2 }}>
            <span><strong>Target CAC</strong>: {formater.formatCurrency(Number(defaultTargetCac))}</span><br />
            <strong>Target Threshold %</strong>: {Number(percent) * 100}<br />
            <strong>Target CAC Threshold</strong>: {formater.formatCurrency(Number(targetCac))}<br />
            <strong>Amount Over Target CAC Threshold</strong>: {valueNumber ? formater.formatCurrency(Number(p.value || 0) - Number(targetCac)) : '-'}<br />
            <strong>Percent Over Target CAC Threshold</strong>: {valueNumber ? `${formater.formatNumber(((Number(p.value || 0) - Number(targetCac)) / Number(targetCac)) * 100, 2)}%` : '-'}<br />
            <span><strong>CAC</strong> of <strong>{valueNumber ? formater.formatCurrency(Number(p.value || 0)) : '-'}</strong>
            {allow ? 'is less than ' : 'is over'} the <strong>{formater.formatCurrency(targetCac)}</strong> threshold</span><br />
          </div>);
        return (
          <>
            <WideTooltip title={describeValue}><span className={allow ? 'text-success' : 'text-danger'}>{value}</span></WideTooltip>{' '}
            {allow ? (
              <FontAwesomeIcon icon="thumbs-up" className="text-success" />
            ) : (
              <FontAwesomeIcon icon="thumbs-down" className="text-danger" />
            )
            }
          </>
        );
      }, [p.value, p.row.original.latestDeal._id]),
      */
      width: 120,
    },
    {
      Header: 'Predicted CAC',
      accessor: (deal) => {
        if (deal.latestDeal?.dealTerms?.type_of_deal !== 'Upfront CPM') return '-';
        const cac = (Number(deal?.CPApaidConversionsArray?.length + deal?.CPMpaidConversions?.length) !== 0 ? Number(deal.cac || 0) : '-');
        const [targetCac] = getDefaultTargetCac(deal.offer.advertiser, deal.latestDeal.dealTerms.type_of_deal, s);
        const allow = cac !== '-' && cac > 0 && targetCac >= cac;
        if (allow) return '-';

        const days = calculateDays({ mediaPublishedDate: deal.publishDate });
        console.log(days, 'Project Days');
        if (days < 16) return '-';
        const projectedPercent = days > 0 ? getProjectedPercent(days, s) : 0;
        const conversionsCount = Number(deal.CPMpaidConversions?.length);
        const projectedConversions = Number(conversionsCount / projectedPercent) * 100;
        const projectedCac = projectedConversions ? deal.revenue / projectedConversions : '-';

        return projectedCac;
      },
      id: 'projectedCac',

      Cell: p => useMemo(() => (
        <PredictedCAC
          dealForCac={p.row.original?.latestDeal}
          CPApaidConversionsArray={[]}
          CPMpaidConversionsCount={Number(p.row.original.CPMpaidConversions?.length)}
          brand={p.row.original.offer.advertiser}
          settings={s}
          mediaPublishedDate={p.row.original?.publishDate}
        />
      ), [p.row.original]),
      /*
      Cell: p => useMemo(() => {
        if (p.value === '-') return '-';
        // eslint-disable-next-line max-len
        const [defaultTargetCac] = getDefaultTargetCac1(p.row.original.offer.advertiser, p.row.original.latestDeal.dealTerms.type_of_deal);// Number(settings ? settings[p.row.original?.offer?.advertiser?._id] || 450 : 450);
        const days = (p.row.original.publishDate ? moment.tz('America/New_York').diff(moment.tz(moment.utc(p.row.original.publishDate).format('YYYY-MM-DD'), 'America/New_York'), 'days') : 0);
        console.log(days, 'Project Days');
        const projectedPercent = days > 0 ? getProjectedPercent(days) : 0;
        const conversionsCount = Number(p.row.original.CPMpaidConversions.length);
        const projectedConversions = Number(conversionsCount / projectedPercent) * 100;
        const projectedCac = projectedConversions ? p.row.original.revenue / projectedConversions : '-';
        const value = projectedCac === '-' ? '-' : formater.formatCurrency(projectedCac);
        const cacProjection = projectedPercent ? Number((defaultTargetCac / (projectedPercent || 1)) * 100) : 0;
        const allow = projectedCac !== '-' && p.value > 0 && cacProjection >= projectedCac;
        const describeValue = (
          <div style={{ fontSize: '14px', maxWidth: '500px', lineHeight: 2 }}>
            <span><strong>Target CAC</strong>: {formater.formatCurrency(Number(defaultTargetCac))}</span><br />
            <strong>Projected Percent %</strong>: {formater.formatNumber(Number(projectedPercent))}<br />
            <strong>Projected Conversions</strong>: {formater.formatNumber(projectedConversions)}<br />
            <strong>Projected CAC</strong>: {projectedCac !== '-' ? formater.formatCurrency(Number(projectedCac || 0)) : '-'}<br />
            <strong>CAC Projection</strong>: {formater.formatCurrency(cacProjection || 0)}<br />
            <span><strong>Predicted CAC</strong> of <strong>{projectedCac !== '-' ? formater.formatCurrency(Number(projectedCac || 0)) : '-'}
            </strong> {allow ? 'is less than ' : 'is over'} the <strong>{formater.formatCurrency(cacProjection)}</strong> threshold</span><br />
          </div>);
        return (
          <>
            <WideTooltip title={describeValue}><span className={allow ? 'text-success' : 'text-danger'}>{value}</span></WideTooltip>{' '}
            {allow ? (
              <FontAwesomeIcon icon="thumbs-up" className="text-success" />
            ) : (
              <FontAwesomeIcon icon="thumbs-down" className="text-danger" />
            )
            }
          </>
        );
      }, [p.value, p.row.original.latestDeal._id]),
      */
      width: 120,
    },
    {
      Header: 'PAR',
      accessor: deal => (calculatePar(deal, s) || '-'),
      id: 'par',
      Cell: p => useMemo(() => (
        <PAR
          row={p.row.original}
          settings={s}
        />
      ), [p.row.original]),
      width: 120,
    },
    {
      Header: '',
      accessor: '_id',
      // eslint-disable-next-line react/prop-types
      Cell: (p) => {
        let type = 'pause';
        const deal = p.row.original.latestDeal;
        if (((deal.rebuyPause?.started))) {
          type = moment(deal.rebuyPause?.end) < moment() ? 'pause' : 'restart';
        }
        let disabled = true;
        if (deal.rebuyPause?.history && deal.rebuyPause.history.length > 0) {
          disabled = false;
        }

        return (
          <div style={{ textAlign: 'right' }}><TableCellContextMenu
            options={[
              {
                label: `${type === 'pause' ? 'Pause' : 'Restart'} Rebuy`,
                handler: () => {
                  dispatch(showModalById(modalId, { deal: p.row.original.latestDeal, afterSavePause, type }));
                },
              },
              {
                label: 'Pause History',
                handler: () => {
                  dispatch(showModalById(pauseHistoryModalId, { rebuyPauseData: deal.rebuyPause }));
                },
                disabled,
              },
              {
                label: 'Create Deals',
                handler: () => {
                  showCreateDealModal({ deal: p.row.original.latestDeal, offer: p.row.original.offer, agents: p.row.original.agents });
                },
              },
            ]}
          />
          </div>);
      },
      disableGlobalFilter: true,
      disableSortBy: true,
      minWidth: 50,
      maxWidth: 50,
    },
  ];
};

export default ColumnsCreators;

