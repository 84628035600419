// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { axios } from 'ApiClient';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import { getConstant } from '../../../../../../../shared/helpers/WVConstants';

const InfluencelogicStatus = React.memo(({
  record,
  afterSaveFunc,
  setShowNotesModal,
  setCurrentProspect,
  setDisableNote,
  setPortal,
  updateRecordFunction,
  missingDataFunction,
  miningMode,
}) => {
  // const key = 'approvalStatus.influencelogic.status';
  // const notesKey = 'approvalStatus.influencelogic.notes';

  const aValues = getConstant('prospect', miningMode ? 'miningStatuses' : 'status', {});

  const rowData = record.params;
  const val = (typeof rowData !== 'undefined' && typeof rowData.approvalStatus?.influencelogic?.status !== 'undefined') ? rowData.approvalStatus.influencelogic?.status : '';
  const notesVal = (typeof rowData !== 'undefined' && typeof rowData.approvalStatus?.influencelogic?.notes !== 'undefined') ? rowData.approvalStatus.influencelogic?.notes : '';

  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const options = Object.keys(aValues).map(k => ({ value: k, label: aValues[k], isDisabled: !miningMode && ['In Review', 'Approved', 'Rejected'].includes(k) && !rowData.category?.value }));
  useEffect(() => {
    setValue(val);
  }, [record.id, val]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option.value);
    try {
      const data = await axios.post('/api/portal/prospects/post-update-status', {
        prospectIds: [record.id],
        status: option.value,
        miningMode,
      });
      if (data.data.success === false && data.data.reason === 'missing_data') {
        missingDataFunction(data.data.prospect);
      } else if (data?.data?.prospectPopulated && updateRecordFunction) {
        updateRecordFunction(data?.data?.prospectPopulated);
      } else {
        updateRecordFunction(record.id, { approvalStatus: { influencelogic: { status: option.value } } });
        afterSaveFunc();
      }
    } catch (e) {
      console.log(e);
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };
  return (
    <div style={{ width: '180px' }}>
      <div style={{ width: '150px', float: 'left' }} >
        <Select
          value={options.filter(option => option.value === value)}
          options={options}
          onChange={handleChange}
          className="influenceLogicStatusBox"
          placeholder="Influencelogic Status"
          isDisabled={disabled || rowData.inBlackList}
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
        {error ? (<span className="text-danger">{error}</span>) : null}
      </div>
      <div style={{ marginTop: '6px', marginRight: '6px', float: 'right' }}>
        <Tooltip title="IL Notes">
          <IconButton
            size="small"
            onClick={() => { setPortal('influenceLogic'); setDisableNote(false); setCurrentProspect(record); setShowNotesModal(true); }}
          >
            <FontAwesomeIcon icon={[notesVal !== '' ? 'fas' : 'far', 'file-alt']} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record) && a.miningMode === a1.miningMode);

InfluencelogicStatus.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  afterSaveFunc: PropTypes.func.isRequired,
  setShowNotesModal: PropTypes.func.isRequired,
  setCurrentProspect: PropTypes.func.isRequired,
  setDisableNote: PropTypes.func.isRequired,
  setPortal: PropTypes.func.isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
  missingDataFunction: PropTypes.func.isRequired,
  miningMode: PropTypes.bool,
};

InfluencelogicStatus.defaultProps = {
  miningMode: false,
};
export default InfluencelogicStatus;
