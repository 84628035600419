/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../../../../shared/components/ButtonWithSpinner';
import { hideModalById } from '../../../../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../../../../App/store/with-notice';


export const modalId = 'publishPaGroup';

const PublishPAGroupModal = ({
  getCpmPriceApprovalsGroup,
  addNotice,
}) => {
  const dispatch = useDispatch();
  const [loadingPublish, setLoadingPublish] = useState(false);
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  if (!modalInfo) return false;
  console.log(modalInfo);
  
  const { data: { record }, isShow } = modalInfo;
  const close = () => dispatch(hideModalById(modalId));
  const publishPaGroup = async () => {
    setLoadingPublish(true);
    try {
      const res = await axios({
        method: 'get',
        url: `/api/portal/prospects/get-create-file-by-pa-group?id=${record?._id}`,
      });
      setLoadingPublish(false);
      if (res?.data?.success) {
        addNotice({
          message: 'Price Approvals Group was published successfully.',
          type: 'success',
        });
        getCpmPriceApprovalsGroup();
        // updateTable(res?.data?.file);
      } else if (res?.data?.error) {
        addNotice({
          message: `Price Approvals Group failed to publish: ${res.data.error}`,
          type: 'error',
        });
      }
      close();
    } catch (error) {
      console.error(error);
      addNotice({
        message: `Price Approvals Group failed to publish: ${error.message}`,
        type: 'error',
      });
      setLoadingPublish(false);
    }
  };

  if (!modalInfo) return false;
  const count = record.googleSheetFiles?.length || 0;
  const name = record?.name || '';
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Publish Price Approval Group
      </ModalHeader>
      <ModalBody>
        You are about to publish Price Approval Group <strong>{ name.trim() }</strong> to Google Sheet File <strong>{`PA_Group_${name.trim()}${count > 0 ? `(${count + 1})` : ''}`}</strong>
      </ModalBody>
      <ModalFooter>
        <ButtonWithSpinner
          className="btn-sm"
          type="button"
          color="primary"
          onClick={publishPaGroup}
          loading={loadingPublish}
          disabled={loadingPublish}
        >
          <span>Publish</span>
        </ButtonWithSpinner>{' '}
        <Button
          className="btn-sm"
          color="secondary"
          onClick={close}
          disabled={loadingPublish}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PublishPAGroupModal.propTypes = {
  // updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  getCpmPriceApprovalsGroup: PropTypes.func.isRequired,
};

export default withNotice(PublishPAGroupModal);
