import React, { useEffect, useState, useMemo } from 'react';
import { axios } from 'ApiClient';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { fetchDictionary } from '../../../../../shared/helpers/WVRequest';

const CreatorCategory = ({ creatorId, category, reloadInfluecner }) => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryLocal, setCategory] = useState([]);
  const fetchDictionaryData = () => {
    setLoading(true);
    fetchDictionary('PROSPECT_').then((data) => {
      const { success, records } = data;
      if (success === true) {
        const _categories = records.PROSPECT_CATEGORIES ? JSON.parse(records.PROSPECT_CATEGORIES) : [];
        setCategories(_categories);
      }
      setLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    fetchDictionaryData();
  }, []);

  const handleChange = async (selectedItem) => {
    setCategory(selectedItem);
    setLoading(true);
    try {
      axios.post('/api/portal/influencer/post-update-category', {
        id: creatorId,
        params: {
          category: selectedItem.value,
        },
      }).then(() => {
        setLoading(false);
        reloadInfluecner();
      });
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
    return true;
  };
  const options = useMemo(() => {
    let _options = [];
    if (categories?.length > 0) {
      const notAssigned = { value: null, label: '-- Not Assigned --' };
      _options = categories.map(value => ({ value, label: value }));
      _options.unshift(notAssigned);
    }
    return _options;
  }, [categories]);

  useEffect(() => {
    console.log(category, 'CATEGORY');
    setCategory(options.find(option => option.value === category));
  }, [category, options]);

  return (
    <div>
      <h4 className="mb-1">Category</h4>
      <Select
        value={categoryLocal}
        options={options}
        onChange={handleChange}
        placeholder="Category"
        isDisabled={loading}
        menuPortalTarget={document.body}
      />
    </div>
  );
};


CreatorCategory.propTypes = {
  creatorId: PropTypes.string.isRequired,
  category: PropTypes.string,
  reloadInfluecner: PropTypes.func.isRequired,
};
CreatorCategory.defaultProps = {
  category: null,
};
export default CreatorCategory;
