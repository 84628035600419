import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import AdminDashboard from './components/AdminDashboard';
// import { RTLProps } from '../../../shared/prop-types/ReducerProps';

const DefaultDashboard = () => (
  <Container className="dashboard">
    <Row>
      <Col>
        <AdminDashboard />
      </Col>
    </Row>
  </Container>
);

/* DefaultDashboard.propTypes = {
  rtl: RTLProps.isRequired,
}; */

export default connect(() => ({
//  rtl: state.rtl,
}))(DefaultDashboard);
