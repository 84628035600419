/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import Hook from '../../../../../../../shared/hooks';
import formater from '@/shared/helpers/WVFormatter';
/* import Alert from '@/shared/easydev2/shared/components/Alert';
import CurrencyField from '@/shared/components/form/CurrencyField';
import CheckBoxField from '@/shared/components/CheckBox';*/
import { axios } from 'ApiClient';

export const modalId = 'markBillAsPaid';


const MarkPaidModal = () => {
  const { showError, showSuccess } = Hook.useNoticeHook();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState(`Marking Offline Bill.com payment as Paid In Full - ${moment().format('MM/DD/YY')}`);
  const [destination, setDestination] = useState('creator');
  const { data: dataModal, showModal: isShow, close: closeModal } = Hook.useModalHook(modalId);

  const close = () => {
    setLoading(false);
    setDestination('creator');
    setNotes(`Marking Offline Bill.com payment as Paid In Full - ${moment().format('MM/DD/YY')}`)
    closeModal();
  }
  const submitPayment = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: 'post',
        url: '/api/portal/finance/post-save-wire-payment',
        data: { destination, notes, bill: dataModal.bill._id},
      });
      setLoading(false);
      if (res?.data?.success) {
        showSuccess('New Payment was added sucessfully');
        close();
        if (dataModal.reload) dataModal.reload();
      } else if (res?.data?.error) {
        showError(res?.data?.error)
      }
      // close();
    } catch (error) {
      console.error(error);
      showError(error.message || 'Unable to get right Payments')
      setLoading(false);
    }
  })

  if (!dataModal?.deal) return false;
  console.log(dataModal);
  const { bill, influencer } = dataModal;
  console.log(influencer);



  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      // style={{ minWidth: '1000px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Mark  Offline payment as Paid?
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="">
          <>
                  {bill && bill.agent ? (
                    <h4>Who would you like to pay?</h4>
                  ) : null}
                  {bill && bill.agent? (
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          checked={destination === 'creator'}
                          name="destination"
                          value="creator"
                          onChange={() => setDestination('creator')}
                        /> Creator
                      </Label>
                    </FormGroup>
                  ) : null
                  }
                  {bill && bill.agent ? (
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          checked={destination === 'agent'}
                          name="destination"
                          value="agent"
                          onChange={() => setDestination('agent')}
                        /> Agent
                      </Label>
                    </FormGroup>
                  ) : null
                  }
                  {bill.agentUsers.map(agent => (
                    <FormGroup>
                      <Label>Agent: { agent?.first_name || ''} {agent?.last_name || ''}({agent?.email || ''})</Label>
                    </FormGroup>
                  ))}
                  
                  <FormGroup>
                    <Label>Creator: {influencer?.first_name || ''} {influencer?.last_name || ''}</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label>Payments enabled for Creator?: {influencer?.payment?.enablePayments ? 'Yes' : 'No' }</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label>Invoice No: {bill && bill.invoiceNumber}</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label>Amount: {bill && formater.formatCurrency(bill.amount)}</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label>Amount Due: {formater.formatCurrency(Number(bill.amountDue || 0)) }</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label>Payment Method: Wire</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label>Amount</Label>
                    <Input
                      readOnly
                      id="amount"
                      name="amount"
                      placeholder="Amount"
                      value={formater.formatCurrency(Number(bill.amount || 0))}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Notes</Label>
                    <Input
                      type="textarea"
                      id="notes"
                      name="notes"
                      placeholder="Notes"
                      onChange={e => setNotes(e.target?.value || '')}
                      value={notes}
                    />
                  </FormGroup>
                </>
          </Col>
        </Row>        
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-sm"
          color="primary"
          onClick={submitPayment}
          disabled={loading}
        >
          Save
        </Button>
        <Button
          className="btn-sm"
          color="secondary"
          onClick={close}
          disabled={loading}
        >
          Cancel
        </Button>

        
      </ModalFooter>
    </Modal>
  );
};

MarkPaidModal.propTypes = {
  updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

MarkPaidModal.defaultProps = {

};

export default (MarkPaidModal);
