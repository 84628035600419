/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from 'mdi-react/InformationIcon';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip as TooltipOnLine } from 'recharts';
import {
  formatIntNumber,
  formatDateForCreatorsPaymentsTables,
  formatToMT,
} from '../../../../../shared/helpers/WVFormatter';

const CustomizedTooltip = (props) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${formatIntNumber(props.payload[0].value)} views on ${props.label}`}</p>
      </div>
    );
  }

  return null;
};

const CustomizedXAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
      {payload.value}
    </text>
  </g>
);

const CustomizedYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
      {formatIntNumber(payload.value)}
    </text>
  </g>
);

const ViewsInList = ({
  record,
}) => {
  const videoStatistic = unflatten(record).params;
  const [showModal, setShowModal] = useState(false);
  const value = videoStatistic?.views?.length > 0 ? videoStatistic.views[videoStatistic.views.length - 1] : false;
  const values = videoStatistic?.views?.length > 0 ? videoStatistic.views.map(data => (
    {
      name: formatToMT(data.datetime, 'M/D/YY'),
      uv: Number(data.views || 0),
      amt: formatToMT(data.views, 'M/D/YY h:mma'),
    }))
    : null;

  return value ? (
    <span>
      <Tooltip
        title={`Data on ${formatDateForCreatorsPaymentsTables(value.datetime)}`}
      >
        <span>{formatIntNumber(value.views)}</span>
      </Tooltip>
      {' '}
      <Tooltip title="Show Views History">
        <IconButton
          size="small"
          onClick={() => setShowModal(!showModal)}
          className="material-table__toolbar-button"
          style={{ marginTop: '-2px' }}
        >
          <InfoIcon size="15" />
        </IconButton>
      </Tooltip>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        className="theme-light ltr-support login-modal-form"
        size="lg"
        style={{ maxWidth: '800px' }}
      >
        <ModalHeader toggle={() => setShowModal(!showModal)} className="bold-text">
          Views History
        </ModalHeader>
        <ModalBody>
          <div>
            <AreaChart
              width={730}
              height={400}
              data={values}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#70bbfd" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#70bbfd" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="name"
                padding={{ left: 8, right: 8 }}
                tick={<CustomizedXAxisTick />}
              />
              <YAxis
                padding={{ top: 8, bottom: 8 }}
                tick={<CustomizedYAxisTick />}
                domain={['dataMin', 'dataMax']}
                interval="preserveStartEnd"
              />
              <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
              <TooltipOnLine content={<CustomizedTooltip />} />
              <Area
                type="monotone"
                dataKey="uv"
                stroke="#70bbfd"
                fillOpacity={1}
                fill="url(#colorUv)"
                activeDot={{ r: 3 }}
                dot={{ r: 3 }}
              />
            </AreaChart>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setShowModal(!showModal)}
            style={{ marginBottom: '4px' }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  ) : 'n/a';
};

ViewsInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewsInList;
