import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from './BreadCrumbs';

class BreadCrumbsResource extends React.PureComponent {
  static propTypes = {
    isBackButton: PropTypes.bool,
    resource: PropTypes.objectOf(PropTypes.any).isRequired,
    record: PropTypes.objectOf(PropTypes.any),
    actionName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isBackButton: true,
    record: null,
  };

  renderResource() {
    const { resource } = this.props;
    return { title: resource.name, path: resource.href };
  }

  renderAction() {
    const { actionName, resource, record } = this.props;
    const action = resource.resourceActions.find(a => a.name === actionName)
      || (record && record.recordActions.find(a => a.name === actionName));
    if (action) {
      return { title: action.label, path: null };
    }
    return null;
  }

  render() {
    // const { actionName } = this.props;
    const links = [{ title: 'Home', path: '/' }];
    const link1 = this.renderResource();
    if (link1) {
      links.push(link1);
    }
    const link2 = this.renderAction();
    if (link2) {
      links.push(link2);
    }
    // let backPath = '/';
    // if (actionName !== 'list') {
    //   backPath = link1.path;
    // }
    // console.log(backPath, 'back');
    return (
      <Breadcrumb links={links} isBackButton={this.props.isBackButton} />
    );
  }
}

export default BreadCrumbsResource;
