import React from 'react';
import { CKEditor } from 'ckeditor4-react';
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Spinner, Input } from 'reactstrap';
import ApiClient, { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import PropTypes from 'prop-types';
import Select from 'react-select';
import StyledButton from '../../../../shared/components/components/ui/styled-button';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import DropZoneField from '../../../../shared/components/form/DropZone';
import withNotice from '../../../../containers/App/store/with-notice';
import BrandOpportunityHighlightSelection from './components/BrandOpportunityHighlightSelection';
import { getConstant } from '../../../../shared/helpers/WVConstants';

const styles = {
  label: {
    maxWidth: '150px',
  },
  fileZone: {
    border: '1px dashed #ccc',
    padding: '30px',
    margin: '20px 0',
    cursor: 'move',
  },
};

class BrandOpportunityNew extends React.Component {
  static async loadBrandOptions(inputValue) {
    const api = new ApiClient();
    const response = await api.client.get(`/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`);

    return response.data.brands.filter(b => b.displayInUi === 'Yes').map(record => ({
      value: record._id,
      label: record.companyName,
      record,
    }));
  }

  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeBrand = this.handleChangeBrand.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleChangeLogoUrl = this.handleChangeLogoUrl.bind(this);
    this.saveHighlights = this.saveHighlights.bind(this);
    this.loadPipelineOptions = this.loadPipelineOptions.bind(this);
    this.handleChangePipeline = this.handleChangePipeline.bind(this);
    this.state = this.constructor.defaultState;
  }

  componentDidMount() {
    const { match } = this.props;
    if (match && match.params && match.params.opportunityId) {
      this.loadData(match.params.opportunityId);
    }
  }
  static defaultState = {
    selectedBrand: null,
    loading: {
      loadBrandOpportunity: false,
      formSubmit: false,
    },
    record: {
      params: {
        highlights: [],
      },
      errors: {},
      populated: {},
    },
    showPreviewLink: {
      logo: true,
    },
    highlightsVersion: 0,
    filesToDelete: [],
    selectedPipeline: null,
    pipelines: [],
    stageOptions: [],
  };
  static requiredData = [
    'brand', 'title', 'pipeline', 'dealstage',
  ];
  async loadPipelineOptions() {
    const pipelines = getConstant('deal', 'pipelines');
    const pipelinesArr = pipelines.map(record => ({
      value: record.id,
      label: record.label,
      stages: Object.keys(record.stages).map(key => ({ id: key, label: record.stages[key], title: record.stages[key] })),
      record: { id: record.id, title: record.label },
    }));
    this.setState({
      pipelines: pipelinesArr,
    });
    return pipelinesArr;
    /* const response = await axios.get(`/api/portal/hubil/search-pipeline?pipelineName=${inputValue}`);
    const { success, pipelines } = response.data;
    if (success) {
      const pipelinesArr = pipelines.map(record => ({
        value: record.id,
        label: record.title,
        stages: record.stages,
        record,
      }));
      this.setState({
        pipelines: pipelinesArr,
      });
      return pipelinesArr;
    }
    return []; */
  }

  loadData(opportunityId) {
    const { addNotice } = this.props;
    this.setState(state => ({
      loading: {
        ...state.loading,
        loadBrandResource: true,
      },
    }));
    axios.get(`/api/portal/brands/get-brand-opportunity-data?brandOpportunityId=${opportunityId}`).then((response) => {
      const { success, brandOpportunity } = response.data;
      if (success) {
        if (brandOpportunity.logoUrl) {
          brandOpportunity.logoUrlObj = {
            path: brandOpportunity.logoUrl,
            preview: brandOpportunity.logoUrl,
          };
        } else {
          brandOpportunity.logoUrlObj = null;
        }
        let selectedPipeline = {};
        if (typeof brandOpportunity.pipeline !== 'undefined' && brandOpportunity.pipeline !== null && brandOpportunity.pipeline.id !== null) {
          selectedPipeline = {
            id: brandOpportunity.pipeline.id,
            title: false,
          };
          this.handleChangePipeline({ record: selectedPipeline, stages: brandOpportunity.pipeline.stages });
          brandOpportunity.pipeline = selectedPipeline.id;
        }
        this.setState({
          record: {
            params: brandOpportunity,
            errors: {},
            populated: {},
          },
          selectedBrand: {
            _id: brandOpportunity.brandObj._id,
            companyName: brandOpportunity.brandObj.companyName,
          },
          selectedPipeline,
        });
      } else {
        addNotice({
          message: 'An error occurred while fetching data',
          type: 'warning',
        });
      }
      this.setState(state => ({
        loading: {
          ...state.loading,
          loadBrandResource: false,
        },
      }));
    }).catch((error) => {
      console.log(error);
    });
  }

  handleChange(propertyOrRecord, value) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        record: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        record: {
          ...state.record,
          params: {
            ...state.record.params,
            [propertyOrRecord]: value,
          },
          errors: {
            ...state.record.errors,
            [propertyOrRecord]: undefined,
          },
        },
      }));
    }
  }
  handleInputChange(name, event) {
    this.handleChange(name, event.target.value);
  }
  handleSelectChange(value, element) {
    this.handleChange(element.name, value.value);
  }
  handleChangeBrand(selected) {
    if (selected) {
      this.setState({
        selectedBrand: selected.record,
      });
      this.handleChange('brand', selected.record._id);
    }
  }
  handleChangeDescription(event) {
    const { editor } = event;
    this.handleChange('description', editor.getData());
  }
  handleChangeLogoUrl(files) {
    const file = files.length ? files[0] : '';
    this.handleChange('logoUrlObj', file);
    if (!file) {
      if (this.state.record.params.logoPath) {
        const { filesToDelete } = this.state;
        filesToDelete.push(this.state.record.params.logoPath);
        this.setState(() => ({
          filesToDelete,
        }));
      }
      this.handleChange('logoUrl', file);
    }
    this.setState(state => ({
      showPreviewLink: {
        ...state.showPreviewLink,
        logo: false,
      },
    }));
  }
  handleChangePipeline(selected) {
    if (selected) {
      this.setState({
        selectedPipeline: selected.record,
      });
      this.handleChange('pipeline', selected.record.id);
      const options = [];
      if (typeof selected.stages !== 'undefined' && selected.stages.length > 0) {
        let stageSet = false;
        selected.stages.forEach((stage) => {
          if (!stage.archived) {
            options.push({ value: stage.id, label: stage.label });
            if (!stageSet) {
              this.handleSelectChange({ value: stage.id }, { name: 'dealstage' });
              stageSet = true;
            }
          }
        });
      }
      this.setState({
        stageOptions: options,
      });
    }
  }
  saveHighlights = (highlights) => {
    this.handleChange('highlights', highlights);
    this.setState(state => ({
      highlightsVersion: state.highlightsVersion + 1,
    }));
  }
  handleSubmit(event) {
    const { record, filesToDelete } = this.state;
    const { addNotice } = this.props;
    event.preventDefault();
    let isValid = true;
    this.constructor.requiredData.forEach((item) => {
      if (!record.params[item]) {
        isValid = false;
        this.setState(state => ({
          record: {
            ...state.record,
            errors: {
              ...state.record.errors,
              [item]: 'This field is required',
            },
          },
        }));
      }
    });
    if (!isValid) {
      addNotice({
        message: 'Please Check the form. Seems  like you have not filled some required fields',
        type: 'warning',
      });
      return true;
    }
    const data = new FormData();
    Object.keys(record.params).forEach((key) => {
      let valueToAdd = record.params[key];
      if (typeof valueToAdd === 'object' && !['logoUrlObj'].includes(key)) {
        valueToAdd = JSON.stringify(valueToAdd);
      }
      data.append(key, valueToAdd);
    });
    data.append('filesToDelete', JSON.stringify(filesToDelete));
    this.setState(state => ({
      loading: {
        ...state.loading,
        formSubmit: true,
      },
    }));

    axios({
      method: 'post',
      url: '/api/portal/brands/save-brand-opportunity',
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    }).then((response) => {
      const { success } = response.data;
      if (success) {
        addNotice({
          message: 'All data has been saved',
          type: 'success',
        });
        const { match } = this.props;
        if (!match || !match.params || !match.params.opportunityId) {
          this.setState(this.constructor.defaultState);
        }
      } else {
        addNotice({
          message: 'An error occurred while saving data',
          type: 'warning',
        });
      }
      this.setState(state => ({
        loading: {
          ...state.loading,
          formSubmit: false,
        },
        filesToDelete: [],
      }));
    }).catch((error) => {
      console.log(error);
      this.setState(state => ({
        loading: {
          ...state.loading,
          formSubmit: false,
        },
      }));
    });
    return false;
  }
  render() {
    const {
      record,
      selectedBrand,
      loading,
      showPreviewLink,
      highlightsVersion,
      selectedPipeline,
      pipelines,
      stageOptions,
    } = this.state;
    let selectedBrandOption = {};
    if (selectedBrand) {
      selectedBrandOption = {
        value: selectedBrand._id,
        label: selectedBrand.companyName,
      };
    }
    const { match } = this.props;
    const isEditForm = !!((match && match.params && match.params.opportunityId));
    const logoValue = typeof record.params !== 'undefined' && typeof record.params.logoUrlObj !== 'undefined' && record.params.logoUrlObj !== null && record.params.logoUrlObj !== '' ?
      [record.params.logoUrlObj] : '';

    let selectedPipelineOption = {};
    if (selectedPipeline) {
      if (selectedPipeline.title === false) {
        pipelines.forEach((pipeline) => {
          if (pipeline.value === selectedPipeline.id) {
            selectedPipeline.title = pipeline.label;
          }
        });
      }
      selectedPipelineOption = {
        value: selectedPipeline.id,
        label: selectedPipeline.title,
      };
    }

    const stageValue = stageOptions.filter(option => typeof record !== 'undefined' && typeof record.params.dealstage !== 'undefined' && option.value === record.params.dealstage);
    if (stageValue.length === 0 && stageOptions.length > 0) {
      stageValue.push(stageOptions[0]);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs
              isBackButton
              links={[
                { title: 'Home', path: '/' },
                { title: 'Brand Opportunities', path: '/resources/BrandOpportunity/actions/list' },
                { title: isEditForm ? 'Edit Brand Opportunity' : 'Create Brand Opportunity', path: false },
              ]}
            />
          </Col>
          <Col>
            <Card>
              <CardBody style={{ padding: '2rem' }}>
                <CardTitle>
                  <h3>{isEditForm ? 'Edit Brand Opportunity' : 'Create Brand Opportunity'}</h3>
                </CardTitle>
                {loading.loadBrandResource &&
                (
                  <div className="text-center"><Spinner color="primary" size="lg" /></div>
                )
                }
                {!loading.loadBrandResource && (
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup row>
                      <Label sm={2} style={styles.label}>Brand</Label>
                      <Col sm={10}>
                        <SelectAsync
                          cacheOptions
                          value={selectedBrandOption}
                          defaultOptions
                          loadOptions={BrandOpportunityNew.loadBrandOptions}
                          onChange={this.handleChangeBrand}
                          placeholder="Please Select Brand"
                        />
                        {record.errors.brand && (<span className="text-danger">{record.errors.brand}</span>)}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="bodyEditor" sm={2} style={styles.label}>Title</Label>
                      <Col sm={10}>
                        <Input
                          name="title"
                          value={typeof record.params !== 'undefined' && typeof record.params.title !== 'undefined' && record.params.title !== null ? record.params.title : ''}
                          onChange={(e) => { this.handleInputChange('title', e); }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="bodyEditor" sm={2} style={styles.label}>Description</Label>
                      <Col sm={10}>
                        <CKEditor
                          config={{versionCheck: false}}
                          id="bodyEditor"
                          name="body"
                          initData={typeof record.params !== 'undefined' && typeof record.params.description !== 'undefined' && record.params.description !== null ? record.params.description : ''}
                          onChange={this.handleChangeDescription}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={2} style={styles.label}>Pipeline</Label>
                      <Col sm={10}>
                        <SelectAsync
                          cacheOptions
                          value={selectedPipelineOption}
                          defaultOptions
                          loadOptions={this.loadPipelineOptions}
                          onChange={this.handleChangePipeline}
                          placeholder="Please Select Pipeline"
                        />
                        {record.errors.pipeline && (<span className="text-danger">{record.errors.pipeline}</span>)}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={2} style={styles.label}>Deal Stage</Label>
                      <Col sm={10}>
                        <Select
                          name="dealstage"
                          value={stageValue}
                          options={stageOptions}
                          onChange={this.handleSelectChange}
                        />
                        {record.errors.dealstage && (<span className="text-danger">{record.errors.dealstage}</span>)}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={2} style={styles.label}>Logo</Label>
                      <Col sm={10}>
                        <DropZoneField
                          input={{
                            name: 'logoUrl',
                            id: 'logoUrl',
                            value: logoValue,
                            onChange: this.handleChangeLogoUrl,
                          }}
                          customHeight
                        />
                        {logoValue !== '' && logoValue.length > 0 && showPreviewLink.logo && (
                          <a href={logoValue[0].path} target="_blank" rel="noopener noreferrer">Preview&nbsp;&nbsp;<i className="fas fa-external-link-alt" /></a>
                        )}
                      </Col>
                    </FormGroup>
                    <BrandOpportunityHighlightSelection
                      highlights={record.params.highlights}
                      title="Highlights"
                      styles={styles}
                      addNotice={this.props.addNotice}
                      saveHighlights={this.saveHighlights}
                      highlightsVersion={highlightsVersion}
                    />
                    <FormGroup style={{ marginBottom: 0 }}>
                      <StyledButton
                        type="submit"
                        disabled={loading.formSubmit}
                        className="is-primary"
                        style={{ marginTop: 20 }}
                      >
                        {loading.formSubmit && (<><Spinner color="primary" size="sm" />&nbsp;&nbsp;</>)}
                        <i className="icomoon-save" />
                        <span className="btn-text">Save</span>
                      </StyledButton>
                      <StyledButton
                        type="button"
                        className="is-primary btn-danger"
                        onClick={() => { window.history.back(); }}
                        style={{ marginTop: 20 }}
                      >
                        <span className="btn-text">Cancel</span>
                      </StyledButton>
                    </FormGroup>
                  </form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

BrandOpportunityNew.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(BrandOpportunityNew);
