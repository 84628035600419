import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { axios } from 'ApiClient';
import WVFormatter from '../../../../../../../shared/helpers/WVFormatter';

const ViewGrossProfitModal = ({
  isOpen,
  closeFunc,
  hsContactId,
}) => {
  const [loading, setLoading] = useState(true);
  const [grossProfit, setGrossProfit] = useState(0);

  const fetchData = () => {
    axios.get(`/api/portal/hubil/get-gross-profit-by-contact?hsContactId=${hsContactId}`)
      .then((response) => {
        if (response.data.success) {
          setGrossProfit(response.data.grossProfit);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      fetchData();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '385px' }}
    >
      <ModalHeader style={{ padding: 0 }} toggle={() => closeFunc()} tag="h4">Gross Profit</ModalHeader>
      <ModalBody>
        <Row>
          <Col className="text-center">
            {loading && (
              <span><div className="spinner-border spinner-border-sm" /></span>
            )}
            {!loading && (
              <span style={{ fontSize: '22px' }}>{`${WVFormatter.formatCurrency(grossProfit)}`}</span>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" onClick={() => closeFunc()}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

ViewGrossProfitModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  hsContactId: PropTypes.string.isRequired,
};

export default ViewGrossProfitModal;
