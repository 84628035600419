import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Container from './components/Brands';
import { NoActionError, NoResourceError } from '../../../../shared/components/components/ui/error404';

const resourceId = 'Advertiser';

const ResourceAction = (props) => {
  const {
    resources,
    history,
    location,
  } = props;
  const resource = resources.find(r => r.id === resourceId);
  if (!resource) {
    return (<NoResourceError resourceId={resourceId} />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId={resourceId} actionName="list" />);
  }
  return (<Container
    filterVisible
    resource={resource}
    action={action}
    date={new Date()}
    history={history}
    location={location}
    />
  );
};
ResourceAction.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
