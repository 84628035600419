import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ButtonToolbar,
  ModalBody,
  Button,
} from 'reactstrap';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import withNotice from '../../../../../../../App/store/with-notice';
import Hook from '../../../../../../../../shared/hooks';

export const modalId = 'deleteInsertionOrder';

const DeleteModalDialog = ({
  afterSaveFunc,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const { data: dataModal, showModal: showDeleteDialog, close } = Hook.useModalHook(modalId);
  if (!dataModal) return false;
  const { record } = dataModal;
  console.log(dataModal, 'modalInfo');
  const deleteRecord = async () => {
    try {
      setLoading(true);
      await axios.post('/api/portal/prospects/post-cpm-insertion-order-delete', {
        id: record.id,
      });
      setLoading(false);
      close();
      addNotice({
        message: 'The Insertion Order has been removed successfully',
        type: 'success',
      });
      afterSaveFunc();
    } catch (e) {
      addNotice({
        message: e.message || 'Unable to remove Insertion Order',
        type: 'error',
      });
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <Modal
      size="sm"
      isOpen={showDeleteDialog}
      toggle={close}
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Are you sure you want to remove?
      </ModalHeader>
      <ModalBody>
        Are you sure you want to delete the <strong>{'"'}{record?.params?.name || ''}{'"'}</strong>?<br />
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button color="danger" onClick={deleteRecord} disabled={loading || !record}>Delete</Button>&nbsp;&nbsp;
        <Button color="secondary" onClick={close}>Cancel</Button>

      </ButtonToolbar>
    </Modal>
  );
};


DeleteModalDialog.propTypes = {
  // showDeleteDialog: PropTypes.bool.isRequired,
  // closeFormDialog: PropTypes.func.isRequired,
  // record: PropTypes.objectOf(PropTypes.any),
  afterSaveFunc: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(DeleteModalDialog);
