import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup
} from 'reactstrap';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '@/shared/helpers/WVUtilities';
import { clearFilterProperty } from 'ILRedux/TableReducerDefault';
import Hook from '@/shared/hooks';
import CheckBoxField from '@/shared/components/CheckBox'

const Actions = React.memo(({
  downloadCsv,
}) => {
  console.log('RELOAD ACRIONS');
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState(getCookie('payablesBillsShowFilters') === 'true');

  const [search, setSearch] = Hook.useTableFilterHookString('fullSearchName');

  const [dealTypeFilters, handleChangeDealTypeFilters] = Hook.useTableFilterHookCheckbox('typeOfDeal');
  const [dealStageFilters, handleChangeDealStageFilters, dealstagesOptions] = Hook.useTableFilterHookTags('dealstages');

  useEffect(() => {
    setCookie('cpmPricingApprovalsFilters', showFilters.toString(), 35);
  }, [showFilters]);
  const resetFilters = () => {
    dispatch(clearFilterProperty());
  };
  return (
    <div>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Influencer Name, Email, Deal Name"
              value={search || ''}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => downloadCsv()}>
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row>
            <Col sm={3}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Deal Type
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Hybrid"
                  label="Hybrid"
                  value={dealTypeFilters.Hybrid}
                  onChange={event => handleChangeDealTypeFilters(event, 'Hybrid')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="UpfrontOnly"
                  label="Upfront Only"
                  value={dealTypeFilters['Upfront Only']}
                  onChange={event => handleChangeDealTypeFilters(event, 'Upfront Only')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="UpfrontCPM"
                  label="Upfront CPM"
                  value={dealTypeFilters['Upfront CPM']}
                  onChange={event => handleChangeDealTypeFilters(event, 'Upfront CPM')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="MediaLicense"
                  label="Media License"
                  value={dealTypeFilters['Media License']}
                  onChange={event => handleChangeDealTypeFilters(event, 'Media License')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Bonus"
                  label="Bonus"
                  value={dealTypeFilters.Bonus}
                  onChange={event => handleChangeDealTypeFilters(event, 'Bonus')}
                />
              </FormGroup>
            </Col>
            <Col sm="6">
              <Label className="bold-text">
                Deal Stage(s)
              </Label>
              <Select
                value={dealStageFilters}
                options={dealstagesOptions}
                onChange={e => handleChangeDealStageFilters(e || [])}
                className="dateOptions"
                placeholder="Deal Stage"
                menuPlacement="auto"
                menuPortalTarget={document.body}
                isMulti
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    minHeight: '45px',
                  }),
                }}
              />              
            </Col>
          </Row>
        </>
      )}
    </div>
  );
});
Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
};

export default Actions;
