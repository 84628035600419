/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useMemo, useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../../../shared/components/ButtonWithSpinner';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../../../App/store/with-notice';
import ProgressBar from '../../../../../../../shared/helpers/ProgressBarWithObject';
import csvHelper from '../../../../../../../shared/helpers/CSVHelper';

export const modalId = 'publishLookerInsertionOrder';


const Progress = ({ isShow, afterFunction, identity }) => {
  const [stats, setStats] = useState({});
  if (!isShow) return '';
  return (
    <div>
      <h5>Publishing Looker Update Sheet...</h5>
      <ProgressBar
        topic="lookerFileUpdate"
        identity={identity}
        isShow={isShow}
        receiveResults={d => console.log(d) || (d.stats && setStats(d.stats))}
        afterFunction={afterFunction}
      />
      <div className="mt-2">No. rows added: {stats.added || 0}</div>
      <div>No. rows updated: {stats.updated || 0}</div>
      <div>Errors: {stats.errors || 0}</div>
      <div>Total rows: {(stats.added || 0) + (stats.updated || 0) + (stats.errors || 0)}</div>
    </div>);
};
Progress.propTypes = {
  isShow: PropTypes.bool.isRequired,
  afterFunction: PropTypes.func.isRequired,
  identity: PropTypes.string.isRequired,
};

const DeleteCpmCreatorModal = ({
  // updateTable,
  addNotice,
}) => {
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [results, setResults] = useState([]);
  const [file, setFile] = useState(null);
  const modalInfo = useSelector(state => state.table.modals[modalId]);

  console.log(modalInfo);
  const dispatch = useDispatch();
  const identity = useMemo(() => (new Date()).toISOString(), [modalInfo?.isShow]);
  if (!modalInfo) return false;
  const { data: { record }, isShow } = modalInfo;
  const close = () => {
    setShowStats(false);
    setResults([]);
    setFile(null);
    dispatch(hideModalById(modalId));
  };
  const publishInsertionOrder = async () => {
    setShowStats(true);
    setLoadingPublish(true);
    try {
      const res = await axios({
        method: 'get',
        url: `/api/portal/prospects/get-create-looker-by-insertion-order?id=${record?.id}&identity=${identity}&brandId=${record?.params?.brand}`,
      });
      setLoadingPublish(false);
      if (res?.data?.success) {
        const csvDownload = res?.data?.results.concat([]);
        setResults(csvDownload);
        setFile(res?.data?.sheetFile);
        // updateTable(res?.data?.sheetFile, res?.data?.sheetFile);
      } else if (res?.data?.error) {
        addNotice({
          message: `Looker Update Data failed to publish: ${res.data.error}`,
          type: 'error',
        });
      }
      // close();
    } catch (error) {
      console.error(error);
      addNotice({
        message: `Looker Update Data failed to publish: ${error.message}`,
        type: 'error',
      });
      setLoadingPublish(false);
    }
  };
  if (!modalInfo) return false;
  const name = record?.params?.name;
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Publish Insertion Order
      </ModalHeader>
      <ModalBody>
        {!showStats && (<p>You are about to Publish Looker Update Data from <strong>{ name.trim() }</strong> to Google Sheet File <strong>Looker Update Sheet</strong></p>)}
        <Progress
          isShow={showStats}
          afterFunction={() => {}}
          identity={identity}
        />

        {file ? <a href={file.spreadsheetUrl} target="_blank" rel="noreferrer" className="py-2 d-inline-block">Looker Update Sheet</a> : null}
      </ModalBody>
      <ModalFooter>
        {results.length === 0 ?
          <ButtonWithSpinner
            className="btn-sm"
            type="button"
            color="primary"
            onClick={publishInsertionOrder}
            loading={loadingPublish}
            disabled={loadingPublish || showStats}
          >
            <span>Publish</span>
          </ButtonWithSpinner> :
          <Button
            className="btn-sm"
            color="primary"
            onClick={() => csvHelper.generateCSV(`results-${name}.csv`, results)}
          >
            Download Results
          </Button>}{' '}
        <Button
          className="btn-sm"
          color="secondary"
          onClick={close}
          disabled={loadingPublish}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DeleteCpmCreatorModal.propTypes = {
  // updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

DeleteCpmCreatorModal.defaultProps = {

};

export default withNotice(DeleteCpmCreatorModal);
