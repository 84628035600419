import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import EmployeesListContainer from './components/custom/EmployeesListContainer';

const ResourceAction = (props) => {
  const {
    resources,
    history,
    location,
  } = props;
  const [tag, setTag] = useState('');
  const resource = resources.find(r => r.id === 'Employee');
  if (!resource) {
    return (<NoResourceError resourceId="Employee" />);
  }

  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="Employee" actionName="list" />);
  }


  return (<EmployeesListContainer
    filterVisible
    tag={tag}
    setTag={setTag}
    resource={resource}
    action={action}
    date={new Date()}
    history={history}
    location={location}
  />
  );
};
ResourceAction.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
