// eslint-disable-next-line filenames/match-exported
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { Button } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import csvHelper from '../../../../shared/helpers/CSVHelper';


const DownloadCSVCreator = ({ items: records, admins }) => {
  const [loading, setLoading] = useState(false);

  const generateCSVReport = (csvArrayColumns) => {
    const data = [csvArrayColumns.map(column => column.label)];
    records.forEach((record) => {
      const itemRow = [];
      csvArrayColumns.forEach((column) => {
        itemRow.push(column.func(record));
      });
      data.push(itemRow);
    });
    if (csvArrayColumns.find(c => c.total)) {
      const totals = [];
      csvArrayColumns.forEach((column, i) => {
        if (column.total === true) totals[i] = (data.slice(1)).reduce((sum, row) => Number(row[i] || 0) + sum, 0);
        else if (column.total?.toString().length > 0) totals[i] = column.total;
        else totals[i] = '';
      });
      data.push(totals);
      console.log(totals);
    }
    console.log(data);
    csvHelper.generateCSV('CreatorPerformanceReport.csv', data);
  };
  const generateCSVReportStart = async () => {
    setLoading(true);
    const resp = await axios.post('/api/portal/reports/post-get-contacts', {});
    const contacts = resp.data.contacts.reduce((acc, current) => { acc[current.hs_object_id] = current; return acc; }, {});

    const columns = [
      {
        label: 'Creator Name',
        func: deal => `${deal.influencer?.first_name || ''} ${deal.influencer?.last_name || ''}`,
      }, {
        label: 'Creator Email',
        func: deal => `${deal.influencer?.email || ''}`,
      }, {
        label: 'Deal Owner',
        func: (p) => {
          if (!p.admins) return '';
          const values = p.admins.map(d => `${d?.firstName || ''} ${d?.lastName || ''}`) || [];
          return values.join(', ');
        },
      }, {
        label: 'Gross Revenue',
        func: record => (record.grossRevenue || ''),
      }, {
        label: 'Gross Profit',
        func: record => (record.grossProfit || ''),
      }, {
        label: 'Revenue Conversions',
        func: p => Number(p.conversions || 0) + Number(p.cpmConversionsCount || 0),
      }, {
        label: 'Revenue Conversion Managers',
        func: p => {
          const conversions = [];
          p.deals.forEach((curr) => {
            conversions.push(...curr.conversionsArray);
          }, {});
          // console.log(conversions, admins);
          const ars = conversions?.reduce((acc, curr) => {
            const admin = admins.find(a => Number(a.affiliateManagerId) === Number(curr.affiliate_manager_id));
            // console.log(admin, curr.affiliate_manager_id)
            if(!admin) return acc;
            if (!acc[admin._id]) { acc[admin._id] = { conversions: [curr], adminAF: admin }; return acc; }
            acc[admin._id].conversions.push(curr);
            return acc;
          }, {}) || {};
          
          if(!ars[p.adminAF?._id] && p.adminAF && p.cpmConversionsCount) ars[p.adminAF?._id] = { conversions: p.cpmConversionsCount, adminAF: p.adminAF };

        
          // console.log(ars, 'ars')
          if (Object.values(ars || {}).length > 1) {

            const items = Object.values(ars || {}).map(a => `${a.adminAF?.firstName || ''} ${a.adminAF?.lastName || ''}`)
            return items.join(',')
          }
          return '';
        },
      }, {
        label: 'Revenue Conversions by Manager',
        func: p => {
          const conversions = [];
          p.deals.forEach((curr) => {
            conversions.push(...curr.conversionsArray);
          }, {});
          // console.log(conversions, admins);
          const ars = conversions?.reduce((acc, curr) => {
            const admin = admins.find(a => Number(a.affiliateManagerId) === Number(curr.affiliate_manager_id));
            // console.log(admin, curr.affiliate_manager_id)
            if(!admin) return acc;
            if (!acc[admin._id]) { acc[admin._id] = { conversions: [curr], adminAF: admin }; return acc; }
            acc[admin._id].conversions.push(curr);
            return acc;
          }, {}) || {};
          
          if(!ars[p.adminAF?._id] && p.adminAF && p.cpmConversionsCount) ars[p.adminAF?._id] = { conversions: p.cpmConversionsCount, adminAF: p.adminAF };

        
          // console.log(ars, 'ars')
          if (Object.values(ars || {}).length > 1) {

            const items = Object.values(ars || {}).map(a => `${a.conversions?.length || 0}`)
            return items.join(',')
          }
          return '';
        },
      }, {
        label: 'Clicks',
        func: deal => deal?.clicks || 0,
      }, {
        label: 'Hubspot Contact Url',
        func: p => `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.influencer.hsContactVid}/`,
      },
      {
        label: 'YouTube Channel URL',
        func: p => contacts[p.influencer.hsContactVid]?.mediaChannels.youtube_channel_url || '',
      }, {
        label: 'Category',
        func: p => p?.influencer?.category || '',
      },
    ];
    generateCSVReport(columns);
    setLoading(false);
  };
  return (
    <Tooltip title="Download CSV report">
      <Button
        color="primary"
        onClick={generateCSVReportStart}
        disabled={loading}
      >
        Download CSV
      </Button>
    </Tooltip>
  );
};
DownloadCSVCreator.propTypes = {
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default DownloadCSVCreator;
