import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CopyToClipboard from '../../../../../../../../shared/components/CopyToClipboardLeft';
import {
  CopyIcon,
} from '../../../../../../../../shared/helpers/Icons';

const DealInList = React.memo(({
  record,
}) => {
  console.log();
  return (
    <span>
      {record?.params?.dealObject?.dealname || 'Not Assigned'}

      {record.params.dealObject ? (
        <a
          title="Show HubSpot Contact"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${record.params.dealObject.hs_object_id}`}
        >
          {' '}
          <i className="fab fa-hubspot" />
        </a>
      ) : null}
      {' '}
      <div className="mt-1">
        <strong className="" style={{ color: '#1e4aa1' }}>{record?.params?.dealObject?.dealTerms?.coupon_code || ''}</strong>
        <CopyToClipboard
          value={record?.params?.dealObject?.dealTerms?.coupon_code || ''}
          icon={(<span style={{ color: '#70bbfd' }}><CopyIcon /></span>)}
        />
      </div>

    </span>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

DealInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect()(DealInList);
