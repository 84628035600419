import React, { forwardRef } from 'react';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import PropTypes from 'prop-types';

const SearchForm = forwardRef(({
  isFetching, handleKeyDown, onSearchChange, value, handleSubmit,
}, ref) => (
  <form className="form" onSubmit={e => handleSubmit(e)}>
    <div className="form__form-group">
      <div className="form__form-group-field">
        <input
          name="search"
          type="text"
          autoComplete="off"
          placeholder="Search..."
          disabled={isFetching}
          value={value}
          onChange={e => onSearchChange(e.target.value)}
          onKeyDown={e => handleKeyDown(e)}
          ref={ref}
        />
        <div className="form__form-group-icon pointer">
          <MagnifyIcon onClick={e => handleKeyDown(e)} />
        </div>
      </div>
    </div>
  </form>
));

SearchForm.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  value: PropTypes.string,
  handleKeyDown: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

SearchForm.defaultProps = {
  value: '',
};
export default SearchForm;
