import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { Row, Spinner } from 'reactstrap';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';

const columnTransform = {
  email: {
    title: 'Email',
    valueFunc: record => (record.email || ''),
    sort: record => (record.email || ''),
    searchFunc: (record, search) => (record.email ? record.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),
  },
  name: {
    title: 'Name',
    valueFunc: record => (`${record.first_name} ${record.last_name}`),
    sort: record => (`${record.first_name} ${record.last_name}`),
    searchFunc: (record, search) => (`${record.first_name} ${record.last_name}`.toLowerCase().indexOf(search.toLowerCase()) !== -1),
  },
};

class MobileCreatorsTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      influencers: [],
      lastUpdated: new Date(),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  onImportVendorsButton = () => {
    if (!this.validateErrors()) { return; }

    this.toggleImportVendorsModal();
  }

  toggleImportVendorsModal = () => {
    this.setState(prevState => ({ modals: { importVendors: !prevState.modals.importVendors } }));
  }

  fetchData() {
    this.setState({ loading: true });

    axios.get('/api/portal/mobile/sns-influencers').then((response) => {
      if (response.data.success) {
        const { influencers } = response.data;

        const influencersWithIds = influencers.map((_influencer) => {
          const influencer = _influencer;
          influencer.id = influencer._id;
          return influencer;
        });

        this.setState({
          influencers: influencersWithIds,
          loading: false,
        });
        return;
      }

      this.setState({ loading: true });
    }).catch((error) => {
      console.log(error.message);
      this.setState({ loading: true });
    });
  }

  render() {
    const {
      influencers,
      loading,
      lastUpdated,
    } = this.state;

    return (
      <div>
        <h4 className="il-color-deep-blue font-weight-medium mb-0">Select Creators</h4>
        {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        {
          !loading &&
          <Row>
            <MatTableForRedux
              dataArray={influencers}
              selectable
              selectedEvent={this.props.selectedEvent}
              columns={columnTransform}
              version={lastUpdated.toString()}
              defaultOrder={{
                order: 'desc',
                orderBy: 'email',
              }}
              rowsPerPage={20}
            />
          </Row>
        }
      </div>
    );
  }
}

MobileCreatorsTable.propTypes = {
  selectedEvent: PropTypes.func.isRequired,
};

export default MobileCreatorsTable;
