import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  Button,
  ButtonToolbar,
  FormGroup, Input,
  InputGroup,
  Modal,
} from 'reactstrap';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import withNotice from '../../../../../../App/store/with-notice';


const ModalComponent = ({
  isOpen,
  record,
  toggleModal,
  onUpdateRecordFunc,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('');
  const [email, setEmail] = useState('');

  useEffect(async () => {
    setNote(typeof record.researchCreatorNotes !== 'undefined' ? record.researchCreatorNotes : '');
    setEmail(typeof record['researchCreator.email'] !== 'undefined' ? record['researchCreator.email'] : '');
  }, [record]);

  const closeFormDialog = () => {
    toggleModal();
    setLoading(false);
  };

  const submit = () => {
    setLoading(true);
    const data = new FormData();
    data.append('notes', note);
    data.append('email', email);
    data.append('id', record['researchCreator._id'] || null);
    axios({
      method: 'post',
      url: '/api/portal/research/post-update-creator-account',
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    }).then(() => {
      onUpdateRecordFunc();
      closeFormDialog();
      addNotice({
        message: 'Channel Properties have been saved',
        type: 'success',
      });
    });
    return true;
  };

  return (
    <div style={{ display: 'inline' }}>
      <Modal
        isOpen={isOpen}
        toggle={closeFormDialog}
        modalClassName="ltr-support"
        className="modal-dialog"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeFormDialog} />
          <h4 className="text-modal  modal__title">Properties</h4>
        </div>
        <div className="modal__body">
          <Row>
            <Col md={12}>
              <FormGroup>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="off"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  disabled={loading}
                />
              </FormGroup>
              <FormGroup>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label htmlFor="note">Notes</label>
                <InputGroup size="md">
                  <textarea
                    id="note"
                    name="note"
                    value={note}
                    rows={5}
                    onChange={(e) => { setNote(e.target.value); }}
                    disabled={loading}
                    className="form-control"
                    style={{ width: '100%', padding: '5px' }}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_ok btn-sm" color="primary" onClick={submit}>
            {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
            <span>Save</span>
          </Button>{' '}
          <Button className="modal_cancel btn-sm" onClick={closeFormDialog}>Cancel</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onUpdateRecordFunc: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  adminUser: state.session,
});

export default withNotice(connect(mapStateToProps)(ModalComponent));
