import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import Alert from '../../../shared/easydev2/shared/components/Alert';

const getWarningMessage = (validationResult) => {
  let message = '';
  let url = '';
  if (!validationResult.contact && validationResult.invalidContact === true) {
    message = 'Missing HubSpot Contact';
    url = `/influencer/profile/${validationResult.influencer._id}/`;
  }
  if (validationResult.deal && !validationResult.deal.dealTerms.type_of_deal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Incomplete Deal Terms';
  }
  if (validationResult.deal && validationResult.deal.dealTerms.type_of_deal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Invalid Deal Type';
  }
  if (message === '' && validationResult.unassignedDeal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${validationResult.unassignedDeal.hs_object_id}/`;
    message = 'Incomplete Offer';
  }
  if (message === '' && validationResult.contact && validationResult.anotherOfferDeal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${validationResult.contact.hs_object_id}/`;
    message = 'Missing Deal or Possible Offer Mismatch';
  }
  if (message === '' && validationResult.contact && !validationResult.deal) {
    url = `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${validationResult.contact.hs_object_id}/`;
    message = 'Missing Deal';
  }
  return (<span>{message} {url ? (<a href={url} target="_blank" rel="noreferrer">Click here to correct issue</a>) : null } {validationResult.offerName ? `Offer Name: ${validationResult.offerName}` : null}</span>);
};

const Errors = ({ errors }) => {
  const errCount = errors?.length || 0;
  const [errModal, displayErrModal] = useState(false);
  const columnTransform = {
    message: {
      valueFunc: e => (
        <Alert color="warning">
          <p className="py-2 text-dark">
            {e.error ? (<span>{e.error} {e.dealId ? (<a target="_blank" rel="noreferrer" href={`/influencers/creator-deal-editor/${e.dealId}`}>Open Deal</a>) : null}</span>) : getWarningMessage(e)}
          </p>
        </Alert>
      ),
    },
  };
  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {(errCount > 0) && (
                <Alert color="warning">
                  <p className="py-2 text-dark">
                    {errCount} messages need your attention that may affect the current page performance.&nbsp;
                    <button
                      onClick={() => { displayErrModal(true); }}
                      className="link"
                    >
                      See list
                    </button>
                  </p>
                </Alert>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={errModal}
        toggle={() => displayErrModal(false)}
      >
        <ModalHeader toggle={() => displayErrModal(false)} tag="h4">
          Messages Requiring Attention
        </ModalHeader>
        {/* eslint-disable-next-line react/no-danger */}
        <ModalBody className="table-scrollable no-cell-border pb-0">
          <MatTableForRedux
            dataArray={errors}
            selectable={false}
            columns={columnTransform}
            version={(new Date()).toString()}
            rowsPerPage={10}
            hideToolbar
            hideHead
          />
        </ModalBody>
      </Modal>
    </>
  );
};

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
export default Errors;
