/* eslint react/prop-types: 0 */
/* eslint no-underscore-dangle: 0 */
import React from 'react';
import Select from 'react-select';
import ApiClient from 'ApiClient';
import { Card, CardBody } from 'reactstrap';
// import WrapperBox from '../ui/wrapper-box';
import withNotice from '../../../../../containers/App/store/with-notice';


class FilterCampaignBlock extends React.Component {
  constructor(props) {
    super(props);
    this.selectCampaign = this.selectCampaign.bind(this);
    this.state = {
      capmaigns: [],
      // selectedCampaign: '',
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  /* shouldComponentUpdate() {
    const { capmaigns } = this.state;
    console.log(this.props, 'COMPONENT LIST UPDATE');
    if (capmaigns.length === 0) {
      // this.fetchData(newProps);
      return false;
    }
    return true;
  } */
  componentWillUnmount() {
    const { setTag } = this.props;
    if (setTag) {
      setTag('');
    }
  }

  selectCampaign(e) {
    const { value } = e;
    // this.setState({ selectedCampaign: e });
    const { history } = this.props;
    const search = new URLSearchParams(window.location.search);
    if (value !== '') {
      search.set('filters.campaigns', value);
    } else {
      search.delete('filters.campaigns');
    }
    search.set('page', '1');
    history.push(`${history.location.pathname}?${search.toString()}`);
  }

  fetchData(props) {
    const { addNotice } = props;

    const api = new ApiClient();
    this.setState({ loading: true });
    api.client.get('/api/portal/finance/get-campaigns').then((resp) => {
      this.setState({
        loading: false,
        capmaigns: resp.data.campaigns,
      });
    }).catch(() => {
      this.setState({ loading: false });
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
  }

  handleActionPerformed() {
    this.fetchData(this.props);
  }

  render() {
    const {
      capmaigns, loading,
    } = this.state;
    const options = capmaigns.map(item => ({ value: item._id, label: item.keyword }));
    const search = new URLSearchParams(window.location.search);
    const campaign = search.get('filters.campaigns');
    const selected = campaign ? options.find(e => e.value === campaign.toString()) : '';
    return (
      <Card>
        <CardBody>
          <Select
            isDisabled={loading}
            value={selected}
            options={options}
            onChange={this.selectCampaign}
            placeholder="Select keyword..."
          />
        </CardBody>
      </Card>
    );
  }
}

export default withNotice(FilterCampaignBlock);
