import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'

import PropTypes from 'prop-types';
import _ from 'lodash';
import Hook from '../../../../../../../shared/hooks';

const Payments = React.memo(({
  record,
}) => {
  const [showPaymentModal] = Hook.useShowModalHook('billComPayables')
  const { agentUsers, influencerObject, dealObject, ...payable } = record.params;
  const { accountsPayable: { params: accountsPayable} } = record.populated;
  console.log(accountsPayable, 'accountsPayable')
  if (!agentUsers?.length) {
    return (
      <div className="w-100">
        <Button className="w-100 mb-0" onClick={() => showPaymentModal({ influencer: influencerObject, deal: dealObject, payable, accountsPayable })} color="primary">
          Show Creator
        </Button>
      </div>);
  }
  return (
    <div className="w-100 mb-0" >
      <UncontrolledButtonDropdown>
        <DropdownToggle caret color="primary" className='mb-0'>Show Payments</DropdownToggle>
        <DropdownMenu>
          { agentUsers ? <DropdownItem onClick={() => showPaymentModal({ deal: dealObject, payable, agentUsers, accountsPayable})} >Show Agent</DropdownItem>: null}
          <DropdownItem onClick={() => showPaymentModal({ influencer: influencerObject, deal: dealObject, payable, accountsPayable })}>Show Creator</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>);
}, (a, a1) => _.isEqual(a.record, a1.record));

Payments.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Payments;
