/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
} from 'reactstrap';
import _ from 'lodash';
import { axios } from 'ApiClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import withNotice from '../../../../../../App/store/with-notice';

const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

const DealsListModal = memo(({
  isOpen,
  closeFunc,
  deals,
  currentCpmProspectId,
  currentCpmProspectName,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);

  const handleChange = async (dealId, hsDealId, brandId, selected) => {
    setLoading(true);
    try {
      const response = await axios.post(
        '/api/portal/prospects/post-update-deal-on-cpm-prospect',
        {
          prospectId: currentCpmProspectId,
          dealId: selected ? null : dealId,
          hsDealId: selected ? null : hsDealId,
          brandId: brandId || null,
        },
      );
      if (response?.data?.success) {
        setLoading(false);
        closeFunc();
      }
      if (response?.data?.error) {
        setLoading(false);
        addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
    } catch (e) {
      setLoading(false);
      console.log(e.message);
    }
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '900px' }}
    >
      <ModalHeader
        toggle={() => closeFunc()}
        tag="h3"
        className="modal-title-statistics"
      >
        {`${currentCpmProspectName} - Upfront CPM Deals`}
      </ModalHeader>
      <ModalBody>
        <div
          className="tokens-list"
          style={{ maxHeight: '300px', overflowY: 'auto' }}
        >
          {deals.map((deal, index) => (
            <div
              className="w-100"
              key={deal.hsObjectId}
            >
              <Row className="ml-0 mr-0">
                <Col md={10} className="pl-0 pt-1">
                  <a
                    className="bold-text"
                    role="button"
                    onClick={() => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal.hsObjectId}/`)}
                  >
                    {deal.name}
                    <FontAwesomeIcon className="ml-2" icon={faExternalLinkAlt} />
                  </a>
                </Col>
                <Col md={2} className="d-flex align-items-center align-middle justify-content-center">
                  <Button
                    disabled={(loading || currentCpmProspectId === '') || deal.disabled}
                    className="btn-sm w-100 m-0"
                    color={deal.selected ? 'danger' : 'primary'}
                    title={deal.selected ? 'Remove association with this Deal for CPM Creator' : 'Select this Deal for CPM Creator'}
                    onClick={() => handleChange(deal.id, deal.hsObjectId, deal.brandId, deal.selected)}
                  >
                    {deal.selected ? 'Deselect' : 'Select'}
                  </Button>
                </Col>
              </Row>
              <Row className=" ml-0 mr-0 mt-1">
                <Col md={10} className="d-flex justify-content-between">
                  <div className="text-center">
                    <span className="bold-text align-bottom">
                      {deal.owner}
                    </span>
                    <br />
                    <span className="text-black-50">
                      Deal Owner
                    </span>
                  </div>
                  <div className="text-center">
                    <span className="bold-text align-bottom">
                      {deal.brand}
                    </span>
                    <br />
                    <span className="text-black-50">
                      Brand
                    </span>
                  </div>
                  <div className="text-center">
                    <span className="bold-text align-bottom">
                      {deal.type}
                    </span>
                    <br />
                    <span className="text-black-50">
                      Deal Type
                    </span>
                  </div>
                  <div className="text-center">
                    <span className="bold-text align-bottom">
                      {deal.stage}
                    </span>
                    <br />
                    <span className="text-black-50">
                      Deal Stage
                    </span>
                  </div>
                  <div className="text-center">
                    <span className="bold-text align-bottom">
                      {deal.status}
                    </span>
                    <br />
                    <span className="text-black-50">
                      Deal Status
                    </span>
                  </div>
                </Col>
              </Row>
              {deals.length - 1 > index && (<hr className="mt-3 mb-2" />)}
            </div>
          ))}
        </div>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => closeFunc()}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
}, (a, a1) => a.isOpen === a1.isOpen && _.isEqual(a.deals, a1.deals));

DealsListModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  deals: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentCpmProspectId: PropTypes.string.isRequired,
  currentCpmProspectName: PropTypes.string.isRequired,
  addNotice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentCpmProspectId: state.ResearchReducer.currentCpmProspectId,
  currentCpmProspectName: state.ResearchReducer.currentCpmProspectName,
});

export default withNotice(connect(mapStateToProps)(DealsListModal));
