import { Button, ButtonToolbar, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import SelectAsync from 'react-select/async';
import { axios } from 'ApiClient';
import ReactTags from 'react-tag-autocomplete';
import Select from 'react-select';
import { ProgramOptions } from '../../../../../../../../shared/helpers/models/Prospect';
import RadioButton from '../../../../../../../../shared/components/RadioButton';
import WVFormatter from '../../../../../../../../shared/helpers/WVFormatter';
import ProgressBar from '../../../../../../../../shared/helpers/ProgressBarWithObject';
import { setCookie } from '../../../../../../../../shared/helpers/WVUtilities';
import csvHelper from '../../../../../../../../shared/helpers/CSVHelper';
import ReactTableBase from '../../../../../../../../shared/tables/table/ReactTableBase';
import { fetchDictionary } from '../../../../../../../../shared/helpers/WVRequest';

const prospectTypes = {
  standard: 'standard',
  internal: 'internal',
};

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSelectable: false,
  useFlex: true,
  isSortable: false,
  withPagination: true,
  manualPageSize: [5, 10, 15, 20],
  withSearchEngine: false,
};

const CloneProspectModal = ({
  isOpen,
  closeFunc,
  analysts,
  prospect,
  prospectIds,
  addNotice,
  setVersion,
  total,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState({
    value: null,
    label: '-- Not Assigned --',
  });
  const [tags, setTags] = useState([]);
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [brands, setBrands] = useState([]);
  const [analyst, setAnalyst] = useState({
    value: null,
    label: '-- Not Assigned --',
  });
  const [program, setProgram] = useState({
    value: null,
    label: '-- Not Assigned --',
  });
  const [prospectType, setProspectType] = useState(prospectTypes.standard);
  const [prospectOwner, setProspectOwner] = useState(null);
  const [includeAll, setIncludeAll] = useState(false);
  const [bulkCloning, setBulkCloning] = useState(false);
  const [identity, setIdentity] = useState('');
  const [logs, setLogs] = useState({});
  const [cloneResults, setCloneResults] = useState([]);
  const [cloneLimit, setCloneLimit] = useState(200);
  const name = `Bulk Cloning from ${Date.now().toString()}`;

  const reactTags = React.createRef();

  const loadBrandsOptions = async () => {
    const res = [
      { value: null, label: '-- Not Assigned --' },
    ];
    let response = {};
    if (prospect?.params?._id && prospectIds?.length === 0) {
      response = await axios({
        method: 'get',
        url: `/api/portal/prospects/get-unique-target-brands?prospectId=${prospect.id}`,
      });
      if (response.data.success) {
        response.data.brands.forEach((brandRecord) => {
          res.push({
            value: brandRecord._id,
            label: brandRecord.companyName,
            disabled: response.data.existingBrandIds.findIndex(el => el === brandRecord._id) !== -1,
          });
        });
      }
    } else {
      response = await axios({
        method: 'get',
        url: '/api/portal/scorecard/media-content/get-brands-data?term=',
      });
      if (response.data.success) {
        response.data.brands.forEach((record) => {
          res.push({
            value: record._id,
            label: record.companyName,
            // record,
          });
        });
      }
    }
    setBrands(res);
  };

  const handleChangeType = (e) => {
    setProspectType(e);
    setProspectOwner(null);
  };

  const handleChangeBrand = (selected) => {
    setSelectedBrand({
      value: selected.value,
      label: selected.label,
    });
  };

  const handleChangeAnalyst = (selected) => {
    setAnalyst(selected);
  };

  const handleChangeProgram = (selected) => {
    setProgram(selected);
  };

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setTags(tagsNew);
  };

  const fetchTags = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospects-tags',
      params: {
        prospectIds: prospect?.params?._id ? [prospect.params._id] : prospectIds,
      },
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
        setTags(response.data.tags);
      }
    }).catch((err) => {
      console.log(err);
    });
  };
  const fetchDictionaryLimit = () => {
    fetchDictionary('PROSPECTS_CLONE_LIMIT').then((data) => {
      const { success, records } = data;
      if (success === true) {
        setCloneLimit(Number(records.PROSPECTS_CLONE_LIMIT));
      }
    });
  };

  useEffect(() => {
    if (prospect?.params?.program) {
      setProgram({
        value: prospect.params.program,
        label: prospect.params.program,
      });
    }
    if (prospect?.params?.analystUser) {
      const analystUserIndex = analysts.findIndex(el => el.value === prospect.params.analystUser);
      if (analystUserIndex !== -1) {
        setAnalyst(analysts[analystUserIndex]);
      }
    }
    // if (prospect?.params?.tags.length > 0) {
    //   setTags(prospect.params.tags);
    // }
  }, [prospect]);

  // useEffect(() => {
  //   if (cloneResults.length > 0) {
  //     const failed = cloneResults.filter(item => item.status === 'Failed');
  //   }
  // }, [cloneResults.length]);

  useEffect(() => {
    if (isOpen) {
      fetchTags();
      loadBrandsOptions();
      fetchDictionaryLimit();
      // console.log(prospect);
    }
    setLoading(false);
    if (!isOpen) {
      setSelectedBrand({
        value: null,
        label: '-- Not Assigned --',
      });
      setAnalyst({ value: null, label: '-- Not Assigned --' });
      setProgram({ value: null, label: '-- Not Assigned --' });
      setTags([]);
      setProspectType(prospectTypes.standard);
      setBulkCloning(false);
      setCloneResults([]);
      setLogs({});
    }
  }, [isOpen]);
  useEffect(() => {
    setCookie('bulkCloningProgressBarIdentity', name, 0.05);
    setIdentity(name);
  }, []);

  const downloadReport = () => {
    const data = [['Name', 'Email', 'Status', 'Error']];
    cloneResults.forEach(item => data.push([item.name, item.email, item.status, item.error]));
    return csvHelper.generateCSV('clone-results.csv', data);
  };

  const columnTransform = [
    {
      id: 'name',
      Header: 'Name',
      accessor: value => `${value.row?.original.name || ''}`,
      Cell: value => (
        <div>
          <span>
            {`${value.row?.original.name || ''}`}
          </span>
        </div>
      ),
      width: 250,
    },
    {
      id: 'email',
      Header: 'Email',
      Cell: value => (
        <div>
          <span>{value.row?.original.email || '-'}</span>
        </div>
      ),
      width: 250,
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: value => (
        <div>
          <span>{value.row?.original?.status || ''}</span>
        </div>
      ),
    },
  ];

  const doClone = () => {
    setLoading(true);
    if (prospectIds?.length > 0 && !prospect?.params?._id) {
      setBulkCloning(true);
      const query = new URLSearchParams(window.location.search).toString();
      axios({
        method: 'post',
        url: '/api/portal/prospects/post-bulk-clone-prospects',
        data: {
          analyst: analyst.value,
          program: program.value,
          tags,
          brandId: selectedBrand.value,
          type: prospectType,
          prospectOwner: prospectOwner?.value || null,
          includeAll,
          prospectIds,
          query,
          cloneLimit,
          // logName: name,
        },
      }).then((response) => {
        if (response.data.success === true) {
          console.log(response.data);
          setCloneResults(response.data.resultProspects);
          setLoading(false);
          setVersion((new Date()).toString());
        } else {
          addNotice({
            message: 'An error occurred',
            type: 'error',
          });
        }
      }).catch((err) => {
        console.log(err);
      });
      setLoading(false);
    } else {
      axios({
        method: 'post',
        url: '/api/portal/prospects/post-clone-prospect',
        data: {
          prospectId: prospect.id,
          analyst: analyst.value,
          program: program.value,
          tags,
          brandId: selectedBrand.value,
          type: prospectType,
          prospectOwner: prospectOwner?.value || null,
        },
      }).then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          closeFunc();
          addNotice({
            message: 'Selected Prospect has been cloned successfully',
            type: 'success',
          });
          setVersion((new Date()).toString());
        } else {
          addNotice({
            message: 'An error occurred',
            type: 'error',
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => { closeFunc(); setBulkCloning(false); }}
      style={{ minWidth: '800px' }}
    >
      <ModalHeader toggle={() => { closeFunc(); setBulkCloning(false); }} tag="h4">Clone Prospect</ModalHeader>
      <ModalBody>
        {!bulkCloning && !loading && !prospect?.params?._id && prospectIds?.length > 0 && prospectIds?.length !== total && (
          <>
            <div className="mt-1">{`You have selected ${prospectIds?.length} prospect${prospectIds?.length !== 1 ? 's' : ''} to clone.`}</div>
            <div className="theme-light radio-in-prospects mb-3">
              {`Would you like to select ${total < cloneLimit ? `all ${WVFormatter.formatIntNumber(total)}` : `first ${WVFormatter.formatIntNumber(cloneLimit)}`} Prospects?`}&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="action1"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                name="action1"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>
          </>
        )}
        <Row>
          <Col>
            {!bulkCloning && loading && (<div className="w-100 text-center"><Spinner color="primary" size="lg" /></div>)}
            {!bulkCloning && !loading && (
              <>
                <div className="mb-3">
                  <div>Select the Prospect type you wish to clone</div>
                  <Row className="theme-light radio-in-prospects">
                    <Col md={6} style={{ paddingLeft: '50px' }}>
                      <RadioButton
                        name="action"
                        label={`${'\u00A0'} Standard Prospect`}
                        value={prospectType}
                        radioValue={prospectTypes.standard}
                        onChange={handleChangeType}
                      />
                    </Col>
                    <Col md={6} style={{ paddingLeft: '50px' }}>
                      <RadioButton
                        name="action"
                        label={`${'\u00A0'} Internal Prospect`}
                        value={prospectType}
                        radioValue={prospectTypes.internal}
                        onChange={handleChangeType}
                      />
                    </Col>
                  </Row>
                </div>
                {prospectType === prospectTypes.internal && (
                  <FormGroup>
                    <Label>Prospect Owner</Label>
                    <Select
                      value={prospectOwner}
                      options={analysts}
                      onChange={(selected) => { setProspectOwner(selected); }}
                      placeholder=""
                      menuPlacement="auto"
                    />
                  </FormGroup>
                )}
                <div>
                  <FormGroup>
                    <Label>Target Brand</Label>
                    <Select
                      value={selectedBrand}
                      options={brands}
                      onChange={handleChangeBrand}
                      placeholder=""
                      menuPlacement="auto"
                      isOptionDisabled={option => option.disabled}
                    />
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <Label>Analyst</Label>
                    <Select
                      value={analyst}
                      options={analysts}
                      onChange={handleChangeAnalyst}
                      placeholder=""
                      menuPlacement="auto"
                    />
                    <div className="small">Optional (can be specified later)</div>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <Label>Program</Label>
                    <Select
                      value={program}
                      options={ProgramOptions}
                      onChange={handleChangeProgram}
                      placeholder=""
                      menuPlacement="auto"
                    />
                    <div className="small">Optional (can be specified later)</div>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <Label>Tags</Label>
                    <ReactTags
                      ref={reactTags}
                      tags={tags}
                      suggestions={tagsSuggestions}
                      onDelete={onDeleteTag}
                      onAddition={onAdditionTag}
                      delimiters={['Enter', ',']}
                      allowNew
                      placeholderText="Tags associated with Prospects"
                      addOnBlur
                    />
                    <div className="small">Optional (can be specified later)</div>
                  </FormGroup>
                </div>
              </>
            )}
          </Col>
        </Row>
      </ModalBody>
      {bulkCloning ? (<ProgressBar
        afterFunction={() => true}
        topic="bulkCloneProspects"
        isShow
        identity={identity}
        receiveResults={res => setLogs(res)}
      />) : null}
      {bulkCloning ? (
        <div>
          <h4>Prospects successfully cloned: {logs?.totalCloned || 0}</h4>
          <h4>Prospects not cloned (errors occurred): {logs?.errorsOccurred || 0}</h4>
        </div>
      ) : null}
      {!bulkCloning && !loading && (
        <ButtonToolbar className="modal__footer">
          <Button color="primary" className="btn-primary btn btn-sm" onClick={doClone}>Clone</Button>
          &nbsp;&nbsp;
          <Button className="modal_cancel btn-sm" onClick={() => { closeFunc(); setBulkCloning(false); }}>Cancel</Button>
        </ButtonToolbar>
      )}
      {bulkCloning && !loading && cloneResults?.length > 0 && (
        <div>
          <Row>
            <Col>
              <ReactTableBase
                key="cloneResultsModal"
                id="cloneResultsModal"
                columns={columnTransform}
                data={cloneResults.filter(item => item.status === 'Failed')}
                tableConfig={tableConfig}
                notFoundProps={{ title: 'All Prospects have been cloned successfully' }}
              />
            </Col>
          </Row>
          <ButtonToolbar className="modal__footer">
            <Button color="primary" className="btn-primary btn btn-sm" onClick={downloadReport}>Download Results</Button>
            &nbsp;&nbsp;
            <Button className="modal_cancel btn-sm" onClick={() => { closeFunc(); }}>Cancel</Button>
          </ButtonToolbar>
        </div>
      )}
    </Modal>
  );
};

CloneProspectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  analysts: PropTypes.arrayOf(PropTypes.any).isRequired,
  prospect: PropTypes.objectOf(PropTypes.any),
  addNotice: PropTypes.func.isRequired,
  setVersion: PropTypes.func.isRequired,
  prospectIds: PropTypes.arrayOf(PropTypes.any),
  total: PropTypes.number,
};

CloneProspectModal.defaultProps = {
  prospect: {},
  prospectIds: [],
  total: 0,
};

export default CloneProspectModal;
