import React from 'react';
import ApiClient from 'ApiClient';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import Select from 'react-select';

const mapStatusArray = { // move toconstants
  0: 'Pending',
  1: 'Approved',
  2: 'Rejected',
  3: 'Paid',
};

export function determineStatusLabel(status) {
  return mapStatusArray[status];
}

export default class StatusComponent extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    updateRecordFunction: PropTypes.func.isRequired,
    fieldId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.api = new ApiClient();
    const { record } = this.props;
    const { status } = record;
    this.state = {
      selected: { value: status, label: mapStatusArray[status] },
      status,
      loading: false,
    };
    this.setPopoverOpen = this.setPopoverOpen.bind(this);
  }
  setPopoverOpen() {
    this.setState(state => ({ popoverOpen: !state.popoverOpen }));
  }
  handleSelectChange(selected) {
    this.setState({ selected });
    this.setState({ loading: true });
    const { record, updateRecordFunction, fieldId } = this.props;
    const value = selected ? selected.value : '';
    if (value === '') {
      return false;
    }
    const { _id } = record;
    const data = new FormData();
    data.append('status', value);
    this.api.recordAction({
      resourceId: 'EmployeeCommission',
      recordId: _id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      this.api.recordAction({
        resourceId: 'EmployeeCommission',
        recordId: _id,
        actionName: 'show',
      }).then((e) => {
        this.setState({ loading: false });
        updateRecordFunction(unflatten(e.data.record), fieldId);
      });
    });
    return true;
  }

  render() {
    const {
      selected,
      loading,
      status,
    } = this.state;
    const mapStatus = Object.keys(mapStatusArray).map(key => ({ value: key, label: mapStatusArray[key] }));
    return status === null ? '' : (
      <div style={{ minWidth: '170px', maxWidth: '170px' }}>
        <div style={{ minWidth: '150px', maxWidth: '150px' }} className="float-left">
          <Select
            isDisabled={loading}
            value={selected}
            options={mapStatus}
            onChange={this.handleSelectChange}
            menuPlacement="auto"
            menuPortalTarget={document.body}
          />
        </div>
      </div>
    );
  }
}
