import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { Button } from 'reactstrap';
import { unflatten } from 'flat';
import UserPropertyType from './index';
import MatTableList from '../../../../../../../shared/tables/materialTable/MatTableList';
import formatter from '../../../../../../../shared/helpers/WVFormatter';
import ScheduledContextMenuInList from './ScheduledContextMenuInList';

const moment = require('moment');


const ScheduledTable = React.memo(({
  action,
  resource,
  // addNotice,
  version,
  adminUsers,
  setVersion,
  // handleSelectCheckbox,
}) => (
  <div style={{ marginLeft: '-15px', marginRight: '-15px' }} >
    <MatTableList
      action={action}
      resource={resource}
      date={new Date()}
      version={version}
      UserPropertyType={UserPropertyType}
      showFilters={false}
      showTitle={false}
      sortBy="createdAt"
      direction="desc"
      v={2}
      noActions
      isResizable={false}
      useCheckboxes
      // checkboxHandler={handleSelectCheckbox}
      enableWarning={false}
      notFoundProps={{ title: 'No Scheduled Uploads found' }}
      useFlex
      customColumns={[
        {
          Header: 'Upload Owner',
          accessor: () => null,
          id: 'meta.adminUserId',
          width: 160,
          Cell: (p) => {
            const admin = adminUsers.find(item => item.value === p.row.original.params['meta.adminUserId']);
            return admin ? admin.label : '---';
          },
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: 'Name',
          accessor: () => null,
          id: 'title',
          width: 160,
          Cell: p => useMemo(() => {
            const unflattenRecord = unflatten(p.row.original);
            // const parent = unflattenRecord.params?.queueObj?.find(item => item.isParent === true);
            // console.log(parent);
            return unflattenRecord.params.title || '';
          }, [p.row.original]),
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: 'Start Time(ET)',
          accessor: () => null,
          id: 'createdAt',
          width: 130,
          Cell: (p) => {
            // console.log(p.row.original);
            // console.log(adminUsers);
            if (p.row.original.params) {
              return formatter.formatToEST(moment(p.row.original.params.upload_start_date || p.row.original.params.createdAt)).format('M/D/YYYY h:mm A');
            }
            return '---';
          },
          disableSortBy: false,
          className: 'align-middle',
        },
        {
          Header: 'Total',
          accessor: () => null,
          id: 'total',
          width: 100,
          Cell: p => useMemo(
            () => (
              <div>
                {p.row.original.params['meta.total']}
              </div>
            ),
            [p.row.original],
          ),
          disableSortBy: true,
          className: 'align-middle',
        },
        {
          Header: '',
          accessor: () => null,
          id: '_id',
          Cell: p => (
            <ScheduledContextMenuInList
              record={p.row.original}
              setVersion={setVersion}
            />
          ),
          disableSortBy: true,
          className: 'align-right',
        },
      ]}
    />
  </div>
), (a, a1) => _.isEqual(a.version, a1.version));

ScheduledTable.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  // setSingleSelectedHsContactId: PropTypes.func.isRequired,
  // handleSelectCheckbox: PropTypes.func.isRequired,
  // addNotice: PropTypes.func.isRequired,
};
export default ScheduledTable;
