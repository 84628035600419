/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner } from 'reactstrap';
import { axios } from 'ApiClient';
import moment from 'moment';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import NameComponent from './NameComponent';
import AccountIdComponent from './AccountIdComponent';
import EmailComponent from './EmailComponent';
import BalanceComponent from './BalanceComponent';
import StatusComponent from './StatusComponent';
import ContextMenuInList from './ContextMenuInList';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Select from 'react-select';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from 'mdi-react/RefreshIcon';

const capitalizeFirstLetter = (str) => str.split('_')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ');

const columnTransform = {
  name: {
    title: 'Name',
    Component: NameComponent,
    width: 180,
    sort: record => ((record.business_profile?.name ? record.business_profile.name : `${record.individual?.first_name || ''} ${record.individual?.last_name || ''}`.trim()) || 'яяя'),
  },
  email: {
    title: 'Email',
    Component: EmailComponent,
    sort: record => (record.email || 'яяя'),
    width: 200,
  },
  stripeAccount: {
    title: 'Account ID',
    Component: AccountIdComponent,
    sort: record => (record.id || 'яяя'),
    width: 180,
  },
  country: {
    title: 'Country',
    width: 100,
    valueFunc: record => {
      if (record.country) {
        if (record.country === 'US') return 'US';
        if (record.country === 'GB') return 'UK';
        if (record.country === 'CA') return 'Canada';
      }
      return '-';
    },
    sort: record => (record.country || 'яяя'),
  },
  tos: {
    title: 'Agreement',
    valueFunc: record => (record.tos_acceptance?.service_agreement ? capitalizeFirstLetter(record.tos_acceptance.service_agreement) : 'Full'),
    sort: record => (record.tos_acceptance?.service_agreement || 'Full'),
  },
  status: {
    title: 'Status',
    Component: StatusComponent,
    sort: record => {
      if (record.requirements?.disabled_reason !== null) {
        return 'Restricted';
      } else if (record.requirements?.eventually_due?.length > 0) {
        return 'Enabled';
      }
      return 'Completed';
    },
  },
  balance: {
    title: 'Balance',
    Component: BalanceComponent,
  },
  json: {
    title: 'Required Fields',
    width: 200,
    valueFunc: record => (<div>
      {record.requirements?.disabled_reason ? (<div><strong>Disabled Reason:</strong> {record.requirements.disabled_reason}</div>): ''}
      {record.requirements?.currently_due?.length > 0 ? <strong>Fields You Must Fill</strong> : ''}
      <ul className="text-danger">{record.requirements?.currently_due?.map(a => (<li>{a}</li>))}</ul>
      {record.requirements?.eventually_due.length > 0 ? <strong>Fields You May Fill</strong> : ''}
      <ul>{record.requirements?.eventually_due?.map(a => (<li>{a}</li>))}</ul>
    </div>),
    sort: record => ((record.requirements?.currently_due?.length || 0) + (record.requirements?.eventually_due?.length || 0)),
  },
  defaultCurrency: {
    title: 'Default Currency',
    valueFunc: record => (record.default_currency ? record.default_currency.toUpperCase() : '-'),
    sort: record => (record.default_currency || 'яяя'),
  },
  bankAccountCountry: {
    title: 'Bank Account Country',
    valueFunc: record => {
      if (record.external_accounts?.data?.length > 0) {
        return record.external_accounts.data[0]?.country || '-';
      }
      return '-';
    },
    sort: record => {
      if (record.external_accounts?.data?.length > 0) {
        return record.external_accounts.data[0]?.country || 'яяя';
      }
      return 'яяя';
    },
  },
  bankAccountCurrency: {
    title: 'Bank Account Currency',
    valueFunc: record => {
      if (record.external_accounts?.data?.length > 0) {
        return record.external_accounts.data[0]?.currency ? record.external_accounts.data[0].currency.toUpperCase() : '-';
      }
      return '-';
    },
    sort: record => {
      if (record.external_accounts?.data?.length > 0) {
        return record.external_accounts.data[0]?.currency || 'яяя';
      }
      return 'яяя';
    },
  },
  menu: {
    title: '',
    Component: ContextMenuInList,
    width: 50,
    disableSortBy: true,
  },
};

const countryOptions = [
  { value: 'US', label: 'US' },
  { value: 'GB', label: 'UK' },
  { value: 'CA', label: 'Canada' },
]

const ConnectedAccountsTable = () => {
  const [accounts, setAccounts] = useState([]);
  const [date, setDate] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedCountries, setSelectedCountries] = useState([]);

  const fetchConnectedAccounts = (refresh = false) => {
    setLoading(true);

    axios.post(
      '/api/portal/finance/post-fetch-stripe-accounts-for-dashboard',
      { refresh },
      ).then((response) => {
      const { accounts, date } = response.data;

      setAccounts(accounts);
      setDate(date);
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchConnectedAccounts();
  }, []);

  const filterAccounts = () => {
    if (search === '' && selectedCountries.length === 0) {
      setFilteredAccounts(accounts);
    } else {
      let countriesArray = null;
      if (selectedCountries?.length > 0) {
        countriesArray = selectedCountries.map(i => i.value);
      }
      setFilteredAccounts(accounts.filter((record) => {
        let searchMatch = true;
        let countriesMatch = true;
        if (search?.length > 0) {
          let regex = new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
          searchMatch = regex.test(`${record.business_profile?.name ? record.business_profile.name : `${record.individual?.first_name || ''} ${record.individual?.last_name || ''}`.trim()} ${record.email || ''} ${record.id || ''}`.trim());
        }
        if (countriesArray && record.country) {
          countriesMatch = countriesArray.includes(record.country);
        }
        return (searchMatch && countriesMatch);
      }));
    }
  }

  useEffect(() => {
    filterAccounts();
  }, [accounts,search, selectedCountries]);

  const resetFilters = () => {
    setSearch('');
    setSelectedCountries([]);
  }

  const removeAccount = (accountId) => {
    const array = [...accounts];
    const index = array.findIndex(obj => obj.id === accountId);
    if (index !== -1) array.splice(index, 1);
    setAccounts(array);
  }

  return (
    <div>
      <h4 className="il-color-deep-blue font-weight-medium mb-0">Connected Stripe Accounts</h4>
      <div className="mt-3">
        <Row>
          <Col sm={6}>
            <InputGroup>
              <Input
                className="form-control-sm form-control"
                name="search"
                type="search"
                autoComplete="off"
                placeholder="Search Name, Email, Account ID"
                value={search}
                onChange={p => setSearch(p.target.value)}
                style={{ height: '38px' }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i
                    className="fa fa-search"
                    aria-hidden="true"
                    style={{
                      color: '#70bbfd',
                      fontSize: 12
                    }}
                  />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col sm={4}>
            <Select
              value={selectedCountries}
              options={countryOptions}
              onChange={setSelectedCountries}
              placeholder="Select Countries..."
              isMulti
              isClearable
            />
          </Col>
          <Col sm={2}>
            <Row>
              <Col sm={8} style={{ paddingRight: '0' }}>
                <Tooltip title="Reset Filters">
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={resetFilters}
                    style={{
                      width: '100%',
                      height: '38px',
                    }}
                  >
                    <i className="fa fa-sync" aria-hidden="true"/>&nbsp;
                    <span className="btn-text">Reset</span>
                  </Button>
                </Tooltip>
              </Col>
              <Col sm={2} style={{ paddingLeft: '24px' }}>
                <Tooltip
                  title={`Refresh list${date ? `. Last updated: ${moment(date).format('LLL')}` : ''}`}
                >
                  <IconButton
                    disabled={loading}
                    id="refreshButton"
                    size="small"
                    onClick={() => fetchConnectedAccounts(true)}
                    className="material-table__toolbar-button"
                    style={{
                      height: '42px',
                    }}
                  >
                    <RefreshIcon size="30" />
                  </IconButton>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {loading ? (<div className="text-center w-100"><Spinner color="primary" size="lg"/></div>) : (
        <Row>
          <MatTableForRedux
            dataArray={filteredAccounts}
            selectable={false}
            columns={columnTransform}
            updateRecordFunc={(accountId) => removeAccount(accountId)}
            version={(new Date()).toString()}
            defaultOrder={{
              order: 'asc',
              orderBy: 'email',
            }}
            rowsPerPage={10}
            hideToolbar
          />
        </Row>
      )}
    </div>
  );
}

export default ConnectedAccountsTable;
