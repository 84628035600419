import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, Button } from 'reactstrap';
import { connect } from 'react-redux';
import withNotice from '../../../App/store/with-notice';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import MFAStatus from './components/MFAStatus';
import ConnectedAccountsTable from './components/ConnectedAccountsTable';
import VendorsTable from './components/VendorsTable';

class BillComDashboard extends PureComponent {
  componentDidMount() {
    document.title = 'IL Admin: Bill.com Dashboard';
  }

  render() {
    return (
      <Container>
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            { title: 'Bill.com Dashboard', path: null },
          ]}
          isBackButton
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="il-color-deep-blue font-weight-bold">Bill.com Dashboard</h3>
                  </Col>
                  <Col xs="12" lg="auto">
                    <Link to="/billCom/import-vendors">
                      <Button
                        className="mb-0"
                        color="primary"
                        size="sm"
                        outline
                      >
                        Import New Vendors
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <hr className="mt-3 mb-4" />
                <MFAStatus addNotice={this.props.addNotice} />
                <div className="py-4" />
                <ConnectedAccountsTable />
                <div className="py-3" />
                <VendorsTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

BillComDashboard.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  rtl: state.rtl,
});

export default withNotice(connect(mapStateToProps)(BillComDashboard));
