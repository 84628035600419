import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '@/shared/components/BreadCrumbs';
import filterProperties from './custom/ActionProperties';
import Hook from '@/shared/hooks';
import { getConstant } from '@/shared/helpers/WVConstants';


const ContainerD = ({
  history,
}) => {
  const dealstages = (getConstant('deal', 'pipelines').find(p => p.label === 'Main Pipeline')).stages;
  const [searchUrl, setPrefill, loadPage, , useLoadOrDebounce] = Hook.usePrefillHook({
    loadTableUrl: '/api/portal/finance/get-merged-deals',
    initialState: {
      sortBy: 'createdAt',
      direction: 'asc',
      page: 1,
      perPage: 10,
    },
  });

  useEffect(() => {
    console.log('Allow Start Load');
    setPrefill(filterProperties({
      dealstages: Object.keys(dealstages).map(d => ({ value: d, label: dealstages[d] })),
    }));
  }, []);
  const setUrl = () => {
    loadPage();
  };
  const downloadCsv = () => {
    // window.open(`/api/portal/finance/get-merged-deals-csv?${searchUrl}`, '_blank').focus();
  };
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 2000);

  useLoadOrDebounce({
    loadFunc: () => loadPage(), loadDebounceFunc: () => setUrlDebounced(), setSearchUrl: () => history.push(`${history.location.pathname}?${searchUrl}`)
  })

  console.log(searchUrl, 'SEARCH');
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Finance', path: null },
          { title: 'Deal Merge Audit', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Deal Merge Audit</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              {searchUrl !== null ? <Actions
                downloadCsv={downloadCsv}
              /> : null}
              <Row>
                {searchUrl !== null ? <Table /> : null}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};
ContainerD.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withRouter(ContainerD);
