import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button, FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import withNotice from '../../../../../../../App/store/with-notice';
import WVFormatter from '../../../../../../../../shared/helpers/WVFormatter';
import RadioButton from '../../../../../../../../shared/components/RadioButton';
import { ProgramOptions } from '../../../../../../../../shared/helpers/models/Prospect';

const notAssigned = {
  value: null,
  label: 'Not Assigned',
};

const AssignProgramModal = ({
  isOpen,
  closeFunc,
  setVersion,
  prospectIds,
  total,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const [program, setProgram] = useState(notAssigned);
  const [includeAll, setIncludeAll] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setProgram(notAssigned);
      setIncludeAll(false);
    }
  }, [isOpen]);

  const submit = async () => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search);
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-update-program',
      data: {
        prospectIds,
        program: program.value,
        includeAll,
        query: query.toString(),
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        closeFunc();
      }
      if (response.data.error) {
        setLoading(false);
        addNotice({
          message: response.data.error,
          type: 'error',
          duration: 15,
        });
      }
    });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '500px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Assign Program</ModalHeader>
      <ModalBody>
        {prospectIds?.length !== total && (
          <>
            <div>{`You have selected ${prospectIds?.length} Lead${prospectIds?.length !== 1 ? 's' : ''} to assign Program to.`}</div>
            <div className="theme-light radio-in-prospects">
              {`Would you like to select all ${WVFormatter.formatIntNumber(total)} Leads?`}&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="action"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                name="action"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>
          </>
        )}
        <Row>
          <Col>
            <FormGroup>
              <Select
                value={program}
                className="assignProgramInput"
                options={ProgramOptions}
                onChange={setProgram}
                placeholder=""
                isDisabled={!!loading}
                menuPlacement="auto"
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

AssignProgramModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  prospectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(AssignProgramModal);
