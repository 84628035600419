import React from 'react';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import TableCellContextMenu from '../../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';

const ContextMenuInList = ({
  record,
}) => {
  const { params } = unflatten(record);

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const contextMenuOptions = [{ label: 'Edit', handler: () => goToPage(`/influencers/creator-deal-editor/${params._id}`) }];

  if (params.hs_object_id) {
    contextMenuOptions.push({ label: 'View HubSpot Deal', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${params.hs_object_id}`) });
  }

  if (params.contactObject?.hs_object_id) {
    contextMenuOptions.push({ label: 'View HubSpot Contact', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${params.contactObject.hs_object_id}/`) });
  }

  return (
    <TableCellContextMenu options={contextMenuOptions} />
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContextMenuInList;
