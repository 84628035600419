import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { axios } from 'ApiClient';
import moment from 'moment';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import { currentTimezoneAggr } from '../../../../../shared/helpers/Dates';

class OnboardingStatus extends React.Component {
  static dateCompleted(value, status) {
    return (value && status === 'complete') ? `${moment(value).format('MMM D, YYYY, h:mma')} (${currentTimezoneAggr()})` : 'Incomplete';
  }

  static propTypes = {
    influencer: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    influencer: null,
  };

  constructor(props) {
    super(props);
    this.onChangeStepStatus = this.onChangeStepStatus.bind(this);
    this.state = {
      step1Status: false,
      step2Status: false,
      step3Status: false,
      step1DateCompletedLabel: 'Incomplete',
      step2DateCompletedLabel: 'Incomplete',
      step3DateCompletedLabel: 'Incomplete',
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { influencer } = nextProps;
    this.setState({
      step1Status: influencer?.params?.onboarding?.step1Status === 'complete',
      step2Status: influencer?.params?.onboarding?.step2Status === 'complete',
      step3Status: influencer?.params?.onboarding?.step3Status === 'complete',
      step1DateCompletedLabel: this.constructor.dateCompleted(influencer?.params?.onboarding?.step1DateCompleted, influencer?.params?.onboarding?.step1Status),
      step2DateCompletedLabel: this.constructor.dateCompleted(influencer?.params?.onboarding?.step2DateCompleted, influencer?.params?.onboarding?.step2Status),
      step3DateCompletedLabel: this.constructor.dateCompleted(influencer?.params?.onboarding?.step3DateCompleted, influencer?.params?.onboarding?.step3Status),
    });
  }

  onChangeStepStatus(event) {
    if (event && event.target) {
      const { checked, name } = event.target;
      const { influencer } = this.props;
      const step = name[4];
      this.setState({ [name]: checked });
      axios.post(
        '/api/portal/influencers/change-onboarding-status',
        {
          influencerId: influencer.id,
          step,
          value: checked,
        },
      ).then((response) => {
        const { dateCompleted, status } = response.data;
        this.setState({ [`step${step}DateCompletedLabel`]: OnboardingStatus.dateCompleted(dateCompleted, status) });
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  render() {
    const {
      step1Status,
      step2Status,
      step3Status,
      step1DateCompletedLabel,
      step2DateCompletedLabel,
      step3DateCompletedLabel,
    } = this.state;
    return (
      <div>
        <h4 className="mt-4 mb-1">Onboarding Status</h4>
        <p className="mt-0 mb-3">Manage & override onboarding process (if necessary). Check Step to mark complete, Uncheck Step to mark incomplete.</p>
        <Row style={{ color: '#646777' }}>
          <Col md="4">
            <CheckBoxField
              name="step3Status"
              label="Step1 - Setup Payment Method"
              value={step3Status}
              onChange={this.onChangeStepStatus}
            />
            <div style={{ marginLeft: '30px', marginTop: '-8px' }}>
              {step3DateCompletedLabel}
            </div>
          </Col>
          <Col md="4">
            <CheckBoxField
              name="step1Status"
              label="Step2 - Sign Agreement"
              value={step1Status}
              onChange={this.onChangeStepStatus}
            />
            <div style={{ marginLeft: '30px', marginTop: '-8px' }}>
              {step1DateCompletedLabel}
            </div>
          </Col>
          <Col md="4">
            <CheckBoxField
              name="step2Status"
              label="Step3 - Review Brand Resources"
              value={step2Status}
              onChange={this.onChangeStepStatus}
            />
            <div style={{ marginLeft: '30px', marginTop: '-8px' }}>
              {step2DateCompletedLabel}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default OnboardingStatus;
