import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button,
  Label,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../shared/components/CheckBox';

const Actions = React.memo(({
  searchValue,
  setSearchValue,
  enabledFilters,
  setEnabledFilters,
  lastRunSucceedFilters,
  setLastRunSucceedFilters,
  inProgressFilters,
  setInProgressFilters,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('scheduledJobsDashboardShowFilters') === 'true');

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      setSearchValue(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('scheduledJobsDashboardShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const handleChangeEnabledFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setEnabledFilters({
        ...enabledFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeLastRunSucceedFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setLastRunSucceedFilters({
        ...lastRunSucceedFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeInProgressFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setInProgressFilters({
        ...inProgressFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    setSearchValue('');
    setEnabledFilters({
      Yes: true,
      No: true,
    });
    setLastRunSucceedFilters({
      Yes: true,
      No: true,
    });
    setInProgressFilters({
      Yes: true,
      No: true,
    });
  };

  return (
    <div>
      <Row>
        <Col sm={9} className="mb-0 pb-0">
          <InputGroup className="mb-0 pb-0">
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Name, Description, Slack Channel, Identifier..."
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={3} className="mb-0 pb-0">
          <Row>
            <Col className="mb-0 pb-0" sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col className="mb-0 pb-0" sm={4} style={{ paddingLeft: '30px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      {showFilters && (
        <>
          <Row>
            <Col sm={3}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Status
              </Label>
              <div
                style={{ marginBottom: '0px' }}
              >
                <CheckBoxField
                  name="yesEnabled"
                  label="Running"
                  value={enabledFilters.Yes}
                  onChange={event => handleChangeEnabledFilters(event, 'Yes')}
                  className="inline"
                />
                <CheckBoxField
                  name="noEnabled"
                  label="Stopped"
                  value={enabledFilters.No}
                  onChange={event => handleChangeEnabledFilters(event, 'No')}
                  className="inline ml-4"
                />
              </div>
            </Col>
            <Col sm={3}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Last Run Succeed?
              </Label>
              <div
                style={{ marginBottom: '0px' }}
              >
                <CheckBoxField
                  name="yesLastRunSucceed"
                  label="Yes"
                  value={lastRunSucceedFilters.Yes}
                  onChange={event => handleChangeLastRunSucceedFilters(event, 'Yes')}
                  className="inline"
                />
                <CheckBoxField
                  name="noLastRunSucceed"
                  label="No"
                  value={lastRunSucceedFilters.No}
                  onChange={event => handleChangeLastRunSucceedFilters(event, 'No')}
                  className="inline ml-4"
                />
              </div>
            </Col>
            <Col sm={3}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                In Progress?
              </Label>
              <div
                style={{ marginBottom: '0px' }}
              >
                <CheckBoxField
                  name="yesInProgress"
                  label="Yes"
                  value={inProgressFilters.Yes}
                  onChange={event => handleChangeInProgressFilters(event, 'Yes')}
                  className="inline"
                />
                <CheckBoxField
                  name="noInProgress"
                  label="No"
                  value={inProgressFilters.No}
                  onChange={event => handleChangeInProgressFilters(event, 'No')}
                  className="inline ml-4"
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  _.isEqual(a.enabledFilters, a1.enabledFilters) &&
  _.isEqual(a.lastRunSucceedFilters, a1.lastRunSucceedFilters) &&
  _.isEqual(a.inProgressFilters, a1.inProgressFilters));
Actions.propTypes = {
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  enabledFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setEnabledFilters: PropTypes.func.isRequired,
  lastRunSucceedFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setLastRunSucceedFilters: PropTypes.func.isRequired,
  inProgressFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setInProgressFilters: PropTypes.func.isRequired,
};

export default connect()(Actions);
