import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Modal,
  ModalHeader,
  Label,
  Spinner,
  Col,
  Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import withNotice from '../../../../../../App/store/with-notice';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';

export const modalId = 'showRejectedApprovedBrands';
const BrandItem = ({ brandData }) => {
  const getBadgeImageBadge = (bd) => {
    if (bd.status === 'In Review') {
      return (
        <>
          <Label className="bold-text"><span>In Review</span></Label>
          <FontAwesomeIcon style={{ marginBottom: '10px', marginLeft: '5px' }} icon="hourglass-half" />
        </>
      );
    } else if (bd.status === 'Rejected') {
      return (
        <>
          <Label className="bold-text"><span className="text-danger">Rejected</span></Label>
          <FontAwesomeIcon style={{ marginBottom: '10px', marginLeft: '5px' }} icon="thumbs-down" className="text-danger" />
        </>
      );
    }
    return (
      <>
        <Label className="bold-text"><span className="text-success">Approved</span></Label>
        <FontAwesomeIcon style={{ marginBottom: '10px', marginLeft: '5px' }} icon="thumbs-up" className="text-success" />
      </>
    );
  };

  const getHistory = (bd) => {
    if (!moment(bd.history).isValid()) {
      return '';
    }
    if (bd.status === 'In Review') {
      return `created ${moment.utc(bd.history).local().format('M/DD/YYYY')}`;
    }
    return `on ${moment.utc(bd.history).local().format('M/DD/YYYY')}`;
  };

  return (
    <Col className="my-3" md={6}>
      <div style={{
             display: 'flex',
              justifyContent: 'left',
           }}
      >
        {brandData.logoPath && (
        <img
          src={brandData.logoPath}
          alt={brandData.brandName}
          title={brandData.brandName}
          style={{
            width: 'auto',
            maxWidth: '180px',
            height: 'auto',
            maxHeight: '30px',
            marginBottom: '10px',
          }}
        />
      )}
        {!brandData.logoPath && (<h3><b>{brandData.brandName}</b></h3>)}
      </div>
      <span style={{ display: 'flex', alignItems: 'left' }}>
        {getBadgeImageBadge(brandData)}
        <span style={{ paddingLeft: '10px' }}>
          <Label className="bold-text">
            {getHistory(brandData)}
          </Label>
        </span>
      </span>
    </Col>);
};
BrandItem.propTypes = {
  brandData: PropTypes.objectOf(PropTypes.any).isRequired,
};

const ShowRejectedApprovedBrands = () => {
  const [loading, setLoading] = useState(false);
  const [brandApprovals, setBrandApprovals] = useState(false);
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  console.log(modalInfo);
  const dispatch = useDispatch();
  const { data: { record }, isShow } = modalInfo || { data: {} };
  const close = () => dispatch(hideModalById(modalId));


  console.log(record);
  const fetchBrandApprovals = () => {
    setLoading(true);
    const prospectId = record.prospects[0]._id;
    // console.log('prospectId user id', prospectId);
    if (!prospectId) {
      // console.log('no user id', record.params);
      return false;
    }


    try {
      axios.get(`/api/portal/influencers/get-prospect-brand-approval-statuses?model=prospect&id=${prospectId}`).then((result) => {
        if (result.data.success) {
          setBrandApprovals(result.data.approvals.sort((a, b) => ((a.brandName > b.brandName) ? 1 : -1)));
        }
        setLoading(false);
      });
    } catch (e) {
      console.error(e.message);
      setLoading(false);
    }
    //  setLastAxiosCall(now);
    return true;
  };
  useEffect(() => {
    if (isShow) {
      fetchBrandApprovals();
    }
  }, [isShow]);
  if (!modalInfo) return false;

  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
    >
      <ModalHeader toggle={close} tag="h4">
        Approved Status History
      </ModalHeader>
      <div
        className="mt-3 mb-3"
      >
        {loading && (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        <Row>
          {!loading && brandApprovals?.length > 0 && brandApprovals.map(ba => <BrandItem key={ba.brandName} status={ba.status} brandData={ba} />)}
        </Row>
      </div>
      <ButtonToolbar className="modal__footer mt-3">
        <Button color="secondary" onClick={close}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

ShowRejectedApprovedBrands.propTypes = {

};


export default withNotice(ShowRejectedApprovedBrands);
