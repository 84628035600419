import React, { useState, useEffect } from 'react';
import { Modal, ButtonToolbar, Button, Label, FormGroup, Spinner } from 'reactstrap';
import ApiClient from 'ApiClient';
import SelectAsync from 'react-select/async';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DropZoneField from '../../../../../../../shared/components/form/DropZone';
import ProgressBar from '../../../../../../../shared/helpers/ProgressBar';
import { getMyWatchlists } from '../../../../../../../redux/reducers/admin/ResearchReducer';

const ImportCreatorsModal = ({
  modal,
  title,
  setModalVisible,
  importRecords,
  loading,
  uploadResults,
  dispatch,
  watchlists,
  defaultWatchlist,
}) => {
  const realDefaultWatchlist = defaultWatchlist === null ? { value: 'master', label: 'Master List' } : defaultWatchlist;
  const [selectedAgent, setSelectedAgent] = useState({
    value: null,
    label: '',
  });
  const [uploading, setUploading] = useState(false);
  const [currentWatchlist, setCurrentWatchlist] = useState(realDefaultWatchlist);
  useEffect(() => {
    dispatch(getMyWatchlists());
  }, []);
  useEffect(() => {
    setCurrentWatchlist(defaultWatchlist);
  }, [defaultWatchlist]);
  const loadAgentsOptions = async (inputValue) => {
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'AgentUser',
      query: inputValue,
    });
    const res = [];
    records.forEach((record) => {
      if (record.params.email !== null) {
        res.push({
          value: record.id,
          label: record.params.email,
          params: record.params,
          record,
        });
      }
    });
    return res;
  };
  const handleChangeAgent = (selected) => {
    setSelectedAgent({
      value: selected.record.id,
      label: selected.record.title,
    });
  };
  const handleChangeFile = (files) => {
    setUploading(true);
    importRecords(currentWatchlist.value, selectedAgent, files);
  };

  const currentWatchlistChanged = (e) => {
    setCurrentWatchlist(e);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => { setModalVisible(false); setUploading(false); }}
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">{title}</h4>
      </div>
      {!loading && !uploading && (
        <div className="modal__body finance-form">
          <FormGroup>
            <Label>Watchlist</Label>
            <div className="research-watchlists">
              <Select
                value={currentWatchlist}
                options={watchlists}
                onChange={(e) => { currentWatchlistChanged(e); }}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label>Agent (Optional)</Label>
            <SelectAsync
              cacheOptions
              value={selectedAgent}
              defaultOptions
              loadOptions={loadAgentsOptions}
              onChange={handleChangeAgent}
              placeholder=""
            />
          </FormGroup>
          <DropZoneField
            input={{
              id: 'uploadfile',
              name: 'uploadfile',
              value: loading ? [{ preview: '/images/loading.gif' }] : '',
              onChange: handleChangeFile,
              accept: '.csv, application/vnd.ms-excel, text/csv',
            }}
            customHeight
            accept=".csv, application/vnd.ms-excel, text/csv"
          />
        </div>
      )}
      {uploading && (
        <div className="modal__body finance-form">
          {loading ?
            (<h3 className="mb-2">Uploading...</h3>) :
            (<h3 className="mb-2">Results:</h3>)
          }
          <ProgressBar
            topic="uploadResearchCreators"
            showAfterEnd
          />
          <div>
            <h4>Successfully uploaded: {loading ? (<Spinner color="primary" size="sm" />) : uploadResults.success}</h4>
            {typeof uploadResults.errors !== 'undefined' ?
              (<h4>Errors encountered: {loading ? (<Spinner color="primary" size="sm" />) : uploadResults.errors.length}</h4>) :
              <h4>Errors encountered: {loading ? (<Spinner color="primary" size="sm" />) : 'Errors encountered: 0'}</h4>
            }
            <h4>Duplicates found: {loading ? (<Spinner color="primary" size="sm" />) : uploadResults.existRecords}</h4>
            <h4>Total processed: {loading ? (<Spinner color="primary" size="sm" />) : uploadResults.total}</h4>
          </div>
        </div>
      )}
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" color="danger" onClick={() => { setModalVisible(false); setUploading(false); }}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

ImportCreatorsModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  importRecords: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  uploadResults: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  watchlists: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  defaultWatchlist: PropTypes.objectOf(PropTypes.any),
};

ImportCreatorsModal.defaultProps = {
  defaultWatchlist: null,
};

export default ImportCreatorsModal;
