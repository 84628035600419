import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  Card, CardBody, CardTitle, Col, Container, Row, Spinner,
} from 'reactstrap';
import getTableColumns from './Table/Columns';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import withNotice from '../../../../App/store/with-notice';
import Filters from './Actions/Filters';
import RefreshDeals from './Actions/RefreshDeals';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  withSearchEngine: false,
  manualPageSize: [10, 20, 30, 40, 100],
  placeholder: 'Search...',
  sortBy: 'publishDate',
  direction: 'desc',
  useFlex: true,
  isFreezeHeader: true,
};

const PaymentAuthorizationChecklist = ({ addNotice }) => {
  const [loading, setLoading] = useState(true);
  const [allItems, setAllItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [fetched, setFetched] = useState(false);

  const fetchChecklist = () => {
    setLoading(true);
    setAllItems([]);
    try {
      console.time('Fetching data');
      axios.get('/api/portal/compliance/get-payment-authorization-checklist').then((response) => {
        if (response.data.success) {
          setAllItems(response.data.records);
          // setFilteredItems(response.data.records);
          setFetched(true);
        }
        console.timeEnd('Fetching data');
        setLoading(false);
      });
    } catch (error) {
      console.error(error.message);
      addNotice({
        message: error.message,
        type: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChecklist();
    // console.log(allItems);
  }, []);
  const updateRecordFunction = (record) => {
    const index = filteredItems.findIndex(f => String(f._id) === String(record._id));
    // console.log(index, 'ITEM INDEX');
    const items = [...filteredItems];
    items[index] = record;
    setFilteredItems(items);
    const indexA = allItems.findIndex(f => String(f._id) === String(record._id));
    console.log(index, 'ITEM INDEX');
    const itemsA = [...allItems];
    itemsA[indexA] = record;
    setAllItems(itemsA);
  };

  const columns = getTableColumns({ updateRecordFunction, addNotice });

  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Payment Authorization Checklist</h3>
              </CardTitle>
              <hr />
              {!loading && fetched ? (
                <Filters
                  setFilteredItems={setFilteredItems}
                  allItems={allItems}
                  filteredItems={filteredItems}
                />
              ) : null}
              <Row className="mt-3">
                <Col>
                  {loading && (<div className="text-center"><Spinner color="primary" size="lg" /> </div>)}
                  {!loading && fetched ? (
                    <ReactTableBase
                      key="searchable"
                      columns={columns}
                      data={filteredItems}
                      tableConfig={tableConfig}
                    />) : null
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <RefreshDeals updateTable={() => fetchChecklist()} dealIds={filteredItems?.map(a => a._id) || []} />
    </Container>
  );
};

PaymentAuthorizationChecklist.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(PaymentAuthorizationChecklist);
