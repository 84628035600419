import React, { useEffect, useState } from 'react';
import {
  Col, Row,
} from 'reactstrap';
import { axios } from 'ApiClient';
import Select from 'react-select';
import Hook from '../../../../shared/hooks';


const DealCreation = () => {
  const { showError, showSuccess} = Hook.useNoticeHook();
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    try {
      setLoading(true);
      const a = await axios.post('/api/portal/reports/post-get-deal-creation-settings');
      const number = a.data.maxNumber;
      setSelectedNumber({ value: number, label: number })
    } catch (e) {
      showError(e.message || 'Unable to Get Deal Creation Settings')
      console.log(e);
    }
    setLoading(false);
  }, []);

  const saveSettings = async (item) => {
    setSelectedNumber(item)
    try {
      setLoading(true);
      await axios.post('/api/portal/reports/post-save-deal-creation-settings', { maxNumber: item.value });
      showSuccess('Deal creation settings was saved successfully')

    } catch (e) {
      showError(e.message || 'Unable to Save Deal Creation Settings')
      console.log(e);
    }
    setLoading(false);
  };
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].map(a => ({ value: a, label: a }))
  return (
    <>
      <Row className="align-items-center mb-3">
        <Col sm="auto">
          <h4 className="font-weight-bold mt-3 mb-2">Maximum no. deals able to create</h4>
          <Select
            value={selectedNumber}
            isDisabled={loading}
            options={items}
            onChange={item => saveSettings(item)}
            placeholder="Maximum no. deals able to create"
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                height: '38px',
                fontSize: '16px',
              }),
              menu: baseStyles => ({
                ...baseStyles,
                fontSize: '14px',
              }),
            }}
          />
        </Col>
        <Col/>
      </Row>
    </>
  );
};

export default DealCreation;
