import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  Row,
  Col,
  Label,
  Spinner,
} from 'reactstrap';
import {
  getCookie,
  fromArray,
  toArray,
  resetObject,
} from '../../../../../../../shared/helpers/WVUtilities';
import Tags from '../../../../../../../shared/components/form/Tags';
import CheckboxList from '../../../../../../../shared/components/form/CheckboxList';
import Panel from '../../../../../../../shared/components/filter/Panel';
import PeriodFilter from '../../../../../../../shared/components/filter/Period';
import {
  setUrl,
  searchUrl,
  setPeriodFilters,
  getOperator,
} from '../../../../../../../shared/helpers/Filter';
import {
  fillCheckboxes,
  selectedCheckboxes,
} from '../../../../../../../shared/helpers/Form';

const Actions = ({
  history,
  initialFilterParams,
  setSelectedPeriod,
  activityDateOperator,
  setActivityDateOperator,
  activityDate,
  setActivityDate,
  activityDateTo,
  setActivityDateTo,
}) => {
  const name = 'CpmConversionsList';

  const checkboxFilters = {
    creatorAssigned: ['Assigned', 'Not Assigned'],
    action: ['Sign Up', 'Paid', 'Refund'],
  };

  const [showFilters, setShowFilters] = useState(getCookie(`${name}ShowFilters`) === 'true');
  const [_filters, setFilters] = useState({
    filters: initialFilterParams,
    filterParams: { activityDatetimeType: getOperator('activityDatetime') },
  });

  const { filters, filterParams } = _filters;

  const [creatorSuggestions, setCreatorSuggestions] = useState([]);
  const [activityDateEnabled, setActivityDateEnabled] = useState(true);

  const ref = useRef(true);

  const updateFilters = (newFilters, params) => {
    setFilters({
      filters: { ..._filters.filters, ...newFilters },
      params: { ..._filters.params, ...params || {} },
    });
  };

  useEffect(() => {
    axios.get('/api/portal/conversions/get-cpm-creator-tags').then((res) => {
      if (res.data.success) setCreatorSuggestions(res.data.tags);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => setUrl(_filters.filters, ref, history, _filters.params), 1000);
  }, [
    _filters,
  ]);

  useEffect(() => { setPeriodFilters(updateFilters, 'activityDatetime', activityDateOperator, activityDate, activityDateTo); }, [activityDateOperator, activityDate, activityDateTo]);

  const updateActivityDatePeriod = (value, update) => { setSelectedPeriod('custom'); update(value); };
  const changeActivityDateOperator = (value) => { updateActivityDatePeriod(value, setActivityDateOperator); };
  const changeActivityDate = (value) => { updateActivityDatePeriod(value, setActivityDate); };
  const changeActivityDateTo = (value) => { updateActivityDatePeriod(value, setActivityDateTo); };

  const resetFilters = () => {
    setFilters({
      filters: resetObject(filters),
      params: { activityDatetimeType: null },
    });
    setActivityDateEnabled(false);
  };

  return (
    <div>
      <Panel
        name={name}
        show={showFilters}
        setShow={setShowFilters}
        search={filters.creatorCode || ''}
        onSearchChange={val => updateFilters({ creatorCode: val })}
        searchPlaceholder="Search Creator Code"
        reset={resetFilters}
        actions={[
          {
            onClick: () => window.open(`/api/portal/conversions/get-cpm-csv?${searchUrl(filters, filterParams).toString()}`, '_blank').focus(),
            label: 'Download CSV',
          },
        ]}
      />
      {showFilters && (
        <>
          <Row>
            <Col sm={6}>
              <Row>
                <Col>
                  <Label className="bold-text">
                    Creator(s)&nbsp;&nbsp;
                  </Label>
                  {creatorSuggestions?.length > 0 ? (
                    <Tags
                      values={toArray(filters.creator)}
                      setValues={values => updateFilters({ creator: fromArray(values) })}
                      suggestions={creatorSuggestions}
                      placeholder="Creator name(s)"
                    />
                  ) : (
                    <Spinner color="primary" size="sm" />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6} xl={4} className="pt-3">
                  <CheckboxList
                    label="Creators"
                    values={fillCheckboxes(toArray(filters.creatorAssigned), checkboxFilters.creatorAssigned)}
                    setValues={values => updateFilters({ creatorAssigned: selectedCheckboxes(values, 'false') })}
                  />
                </Col>
                <Col md={6} xl={4} className="pt-3">
                  <CheckboxList
                    label="Actions"
                    values={fillCheckboxes(toArray(filters.action), checkboxFilters.action)}
                    setValues={values => updateFilters({ action: selectedCheckboxes(values, 'false') })}
                  />
                </Col>
                <Col className="d-none d-xl-block" xl={4} />
              </Row>
            </Col>
            <Col sm={6}>
              <PeriodFilter
                name="activityDate"
                label="Activity Date"
                enabled={activityDateEnabled}
                setEnabled={setActivityDateEnabled}
                operator={activityDateOperator}
                setOperator={changeActivityDateOperator}
                value={activityDate}
                setValue={changeActivityDate}
                to={activityDateTo}
                setTo={changeActivityDateTo}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

Actions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  initialFilterParams: PropTypes.objectOf(PropTypes.any).isRequired,
  setSelectedPeriod: PropTypes.func.isRequired,
  activityDateOperator: PropTypes.string,
  setActivityDateOperator: PropTypes.func.isRequired,
  activityDate: PropTypes.string,
  setActivityDate: PropTypes.func.isRequired,
  activityDateTo: PropTypes.string,
  setActivityDateTo: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  activityDateOperator: null,
  activityDate: null,
  activityDateTo: null,
};

export default Actions;
