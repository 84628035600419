import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/RefreshIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { axios } from 'ApiClient';

export default class RefreshDocument extends React.Component {
  static propTypes = {
    agreement: PropTypes.objectOf(PropTypes.any).isRequired,
    updateRow: PropTypes.func,
  };

  static defaultProps = {
    updateRow: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  refresh = () => {
    const { agreement, updateRow } = this.props;
    this.setState({ loading: true });
    axios.post(
      '/api/portal/refresh-agreement-document',
      { id: agreement._id },
    ).then((res) => {
      this.setState({ loading: false });
      if (res.data.success) {
        const {
          _id,
          status,
          creatorDateSigned,
          filePath,
          fileUrl,
        } = res.data.serviceAgreement;
        updateRow(_id, {
          status,
          creatorDateSigned,
          filePath: (!filePath && (status === 'completed')) ? 'true' : filePath,
          fileUrl,
        });
      }
    }).catch((error) => {
      console.log(error);
      this.setState({ loading: false });
    });
  };

  button() {
    const { agreement } = this.props;
    return (agreement.envelopeId && (agreement.status !== 'deleted')) ? (
      <Tooltip title="Refresh Document">
        <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall" onClick={() => this.refresh(agreement._id)}>
          <Icon style={{ width: '15px', height: '15px' }} />
        </button>
      </Tooltip>
    ) : (
      <Tooltip title="Refresh Document">
        <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall" style={{ color: '#ccc' }}>
          <Icon style={{ width: '15px', height: '15px' }} />
        </button>
      </Tooltip>
    );
  }

  render() {
    const { loading } = this.state;
    return loading ? (
      <div
        className="spinner-border"
        style={
          {
            width: '12px',
            height: '12px',
            marginLeft: '3px',
            marginRight: '6px',
            color: '#646777',
          }
        }
      />
    ) : this.button();
  }
}
