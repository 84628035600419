import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  Modal,
  ModalBody,
  ModalHeader,
  Button, Col, Row,
} from 'reactstrap';
import withNotice from '../../../containers/App/store/with-notice';

const ModalInfoBase = ({
  isOpen,
  closeFunc,
  setVersion,
  title,
  bodyText,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={() => closeFunc()}
    style={{ minWidth: '500px' }}
  >
    <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">{title}</ModalHeader>
    <ModalBody>
      <Row>
        <Col xs="3" className="text-center mt-2" style={{ color: '#70bbfd' }}> <i className="fa fa-info" style={{ fontSize: '5em' }} /></Col>
        <Col xs="9">
          {bodyText.map((item) => {
            if (item.newLine) {
              return (
                <span key={item.text.trim()} style={item.style}><br />{item.text}</span>
              );
            }
            return (<span key={item.text.trim()} style={item.style}>{item.text}</span>);
            })}
        </Col>
      </Row>
    </ModalBody>
    <ButtonToolbar className="modal__footer">
      <Button
        className="modal_cancel btn-sm"
        color="danger"
        onClick={() => {
          if (setVersion) {
            setVersion(new Date().toString());
          }
          closeFunc();
        }}
      >
        Close
      </Button>
    </ButtonToolbar>
  </Modal>
);

ModalInfoBase.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setVersion: PropTypes.func,
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.arrayOf(PropTypes.any).isRequired,
};

ModalInfoBase.defaultProps = {
  setVersion: null,
};

export default withNotice(ModalInfoBase);
