/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import BillComList from '../../../Agents/AgentBillCom/billComList';

const ResourceAction = (props) => {
  const {
    match,
    history,
  } = props;

  return (
    <BillComList
      match={match}
      history={history}
      type="creator"
    />
  );
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
