import React from 'react';
import { Modal, ButtonToolbar, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

const DetailsModal = React.memo(({
  modal,
  setModalVisible,
  record,
}) => (
  <Modal
    isOpen={modal}
    toggle={() => setModalVisible(false)}
  >
    <div className="modal__header">
      <h3 className="text-modal  modal__title">Details</h3>
    </div>
    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      <div className="mt-1 mb-4">
        {record.populated?.prospectGroupUploadLog?.params && (
          <>
            <h4 className="font-weight-bold">Upload Name</h4>
            <hr className="mt-1 mb-2" />
            <div className="mb-3">
              <div className="pl-3">{record.populated.prospectGroupUploadLog.params.name}</div>
            </div>
            <h4 className="font-weight-bold">Upload Description</h4>
            <hr className="mt-1 mb-2" />
            <div className="mb-3">
              <div className="pl-3">{record.populated.prospectGroupUploadLog.params.description || '-'}</div>
            </div>
            <h4 className="font-weight-bold">Upload Source</h4>
            <hr className="mt-1 mb-2" />
            <div className="mb-3">
              <div className="pl-3">{record.populated.prospectGroupUploadLog.params.uploadSource}</div>
            </div>
          </>
        )}
        <h4 className="font-weight-bold">Prospect Type</h4>
        <hr className="mt-1 mb-2" />
        <div className="mb-3">
          <div className="pl-3">{record.params?.isInternal ? 'Internal' : 'Standard'}</div>
        </div>
        {record.params?.isInternal && (
          <div>
            <h4 className="font-weight-bold">Prospect Owner</h4>
            <hr className="mt-1 mb-2" />
            <div className="mb-3">
              <div className="pl-3">{`${record.populated?.prospectOwner?.params?.firstName || ''} ${record.populated?.prospectOwner?.params?.lastName || ''}`}</div>
            </div>
          </div>
        )}
        <h4 className="font-weight-bold">Tags</h4>
        <hr className="mt-1" />
        <div>
          <div className="pl-3">{record.params?.tags?.length > 0 ? record.params.tags.join(', ') : '-'}</div>
        </div>
      </div>
    </div>
    <ButtonToolbar className="modal__footer">
      <Button className="modal_cancel btn-sm" color="secondary" onClick={() => setModalVisible(false)}>Close</Button>
    </ButtonToolbar>
  </Modal>
), (a, a1) => _.isEqual(a.record, a1.record) && _.isEqual(a.modal, a1.modal));

DetailsModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

DetailsModal.defaultProps = {

};

export default DetailsModal;
