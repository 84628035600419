// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import IntervalDatePickerField from '../../../Prospects/components/custom/IntervalDatePickerField';

const Actions = React.memo(({
  onSearchChange,
  getUrlSearch,
  searchValue,
  cspOwnersSuggestions,
  setCspOwnersFilter,
  setCspOwners,
  cspOwners,
  setBrandsFilter,
  setBrands,
  brands,
  brandsSuggestions,
  setDealStagesFilter,
  setDealStages,
  dealStages,
  dealStagesSuggestions,
  // approvalStatusFilters,
  // setApprovalStatusFilters,
  // approvalsBrandStatusFilters,
  // setApprovalsBrandStatusFilters,

  dealAssignedFilters,
  setDealAssignedFilters,
  insertionOrderAssignedFilters,
  setInsertionOrderAssignedFilters,
  setInsertionOrdersFilter,
  setInsertionOrders,
  insertionOrders,
  insertionOrdersSuggestions,
  dateAddedValue,
  setDateAddedValue,
  enableDateAdded,
  setEnableDateAdded,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  singleDate,
  setSingleDate,
  selectedProspectIds,
  setShowAddToInsertionOrderModal,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('cpmCreatorsShowFilters') === 'true');

  const reactTagsOwners = React.createRef();
  const reactTagsBrands = React.createRef();
  const reactTagsDealStages = React.createRef();
  const reactTagsInsertionOrders = React.createRef();

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('cpmCreatorsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const setFiltersCspOwners = (cspOwnersNew) => {
    const cspOwnersFilterNew = {};
    if (cspOwnersNew.length > 0) {
      cspOwnersNew.map((item) => {
        cspOwnersFilterNew[item.id] = true;
        return true;
      });
    }
    setCspOwnersFilter(cspOwnersFilterNew);
  };

  const onDeleteCspOwner = (i) => {
    const cspOwnersNew = cspOwners.slice(0);
    cspOwnersNew.splice(i, 1);
    setFiltersCspOwners(cspOwnersNew);
    setCspOwners(cspOwnersNew);
  };

  const onAdditionCspOwner = (cspOwner) => {
    const cspOwnersNew = [].concat(cspOwners, cspOwner);
    setFiltersCspOwners(cspOwnersNew);
    setCspOwners(cspOwnersNew);
  };

  const setFiltersBrands = (brandsNew) => {
    const brandsFilterNew = {};
    if (brandsNew.length > 0) {
      brandsNew.map((item) => {
        brandsFilterNew[item.id] = true;
        return true;
      });
    }
    setBrandsFilter(brandsFilterNew);
  };

  const onDeleteBrand = (i) => {
    const brandsNew = brands.slice(0);
    brandsNew.splice(i, 1);
    setFiltersBrands(brandsNew);
    setBrands(brandsNew);
  };

  const onAdditionBrand = (brand) => {
    const brandsNew = [].concat(brands, brand);
    setFiltersBrands(brandsNew);
    setBrands(brandsNew);
  };

  const setFiltersDealStages = (dealStagesNew) => {
    const dealStagesFilterNew = {};
    if (dealStagesNew.length > 0) {
      dealStagesNew.map((item) => {
        dealStagesFilterNew[item.id] = true;
        return true;
      });
    }
    setDealStagesFilter(dealStagesFilterNew);
  };

  const onDeleteDealStage = (i) => {
    const dealStagesNew = dealStages.slice(0);
    dealStagesNew.splice(i, 1);
    setFiltersDealStages(dealStagesNew);
    setDealStages(dealStagesNew);
  };

  const onAdditionDealStage = (dealStage) => {
    const dealStagesNew = [].concat(dealStages, dealStage);
    setFiltersDealStages(dealStagesNew);
    setDealStages(dealStagesNew);
  };

  const setFiltersInsertionOrders = (insertionOrdersNew) => {
    const insertionOrdersFilterNew = {};
    if (insertionOrdersNew.length > 0) {
      insertionOrdersNew.map((item) => {
        insertionOrdersFilterNew[item.id] = true;
        return true;
      });
    }
    setInsertionOrdersFilter(insertionOrdersFilterNew);
  };

  const onDeleteInsertionOrder = (i) => {
    const insertionOrdersNew = insertionOrders.slice(0);
    insertionOrdersNew.splice(i, 1);
    setFiltersInsertionOrders(insertionOrdersNew);
    setInsertionOrders(insertionOrdersNew);
  };

  const onAdditionInsertionOrder = (insertionOrder) => {
    const insertionOrdersNew = [].concat(insertionOrders, insertionOrder);
    setFiltersInsertionOrders(insertionOrdersNew);
    setInsertionOrders(insertionOrdersNew);
  };

  /* const handleChangeApprovalStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setApprovalStatusFilters({
        ...approvalStatusFilters,
        [name]: checked,
      });
    }
  };
  const handleChangeApprovalBrandFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setApprovalsBrandStatusFilters({
        ...approvalsBrandStatusFilters,
        [name]: checked,
      });
    }
  }; */

  const handleChangeDealAssignedFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setDealAssignedFilters({
        ...dealAssignedFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeInsertionOrderAssignedFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setInsertionOrderAssignedFilters({
        ...insertionOrderAssignedFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    /* setApprovalStatusFilters({
      Approved: true,
      'In Review': false,
      Rejected: false,
    });
    setApprovalsBrandStatusFilters({
      Approved: true,
      'In Review': true,
      Rejected: true,
    }); */
    setDealAssignedFilters({
      Assigned: true,
      'Not Assigned': true,
    });
    setInsertionOrderAssignedFilters({
      Assigned: false,
      'Not Assigned': true,
    });
    setBrands([]);
    setBrandsFilter({});
    setCspOwners([]);
    setCspOwnersFilter({});
    setDealStages([]);
    setDealStagesFilter({});
    setInsertionOrders([]);
    setInsertionOrdersFilter({});
    setEnableDateAdded(false);
    setDateAddedValue({ value: '<=', label: '<=' });
    setSingleDate(null);
    setDateTo(null);
    setDateFrom(null);
  };

  const handleChangeDateAddedCheckBox = ({ target }) => {
    setEnableDateAdded(!enableDateAdded);
    if (!target.checked) {
      setSingleDate(null);
      setDateTo(null);
      setDateFrom(null);
    }
  };

  const dateAddedLabels = [
    { value: '=', label: '=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'Between', label: 'Between' },
  ];

  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
      height: '44px',
    }),
  };

  return (
    <div>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Name, Email"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                disabled={selectedProspectIds.length === 0}
                onClick={() => { setShowAddToInsertionOrderModal(true); }}
              >
                Add to Insertion Order
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => window.open(`/resources/cpmprospects/download-csv?${getUrlSearch().toString()}`, '_blank').focus()}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row>
            <Col sm={4}>
              <Label className="bold-text">
                Brand(s)
              </Label>
              <ReactTags
                ref={reactTagsBrands}
                tags={brands}
                suggestions={brandsSuggestions}
                onDelete={onDeleteBrand}
                onAddition={onAdditionBrand}
                delimiters={['Enter', ',']}
                placeholderText="Brand name(s)"
              />
            </Col>
            <Col sm={4}>
              <Label className="bold-text">
                Deal Owner(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsOwners}
                tags={cspOwners}
                suggestions={cspOwnersSuggestions}
                onDelete={onDeleteCspOwner}
                onAddition={onAdditionCspOwner}
                delimiters={['Enter', ',']}
                placeholderText="Deal Owner name(s)"
              />
            </Col>
            <Col sm={4}>
              <Label className="bold-text">
                Deal Stage(s)
              </Label>
              <ReactTags
                ref={reactTagsDealStages}
                tags={dealStages}
                suggestions={dealStagesSuggestions}
                onDelete={onDeleteDealStage}
                onAddition={onAdditionDealStage}
                delimiters={['Enter', ',']}
                placeholderText="Deal Stage name(s)"
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={6} >
              <Row>
                {/* <Col sm={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Approval IL Status
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Approved"
                      label="Approved"
                      value={approvalStatusFilters.Approved}
                      onChange={event => handleChangeApprovalStatusFilters(event, 'Approved')}
                    />
                  </FormGroup>
                  <FormGroup style={{ marginBottom: '10px' }}>
                    <CheckBoxField
                      name="InReview"
                      label="In Review"
                      value={approvalStatusFilters['In Review']}
                      onChange={event => handleChangeApprovalStatusFilters(event, 'In Review')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="Rejected"
                      label="Rejected"
                      value={approvalStatusFilters.Rejected}
                      onChange={event => handleChangeApprovalStatusFilters(event, 'Rejected')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Approval Brand Status
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Approved1"
                      label="Approved"
                      value={approvalsBrandStatusFilters.Approved}
                      onChange={event => handleChangeApprovalBrandFilters(event, 'Approved')}
                    />
                  </FormGroup>
                  <FormGroup style={{ marginBottom: '10px' }}>
                    <CheckBoxField
                      name="InReview1"
                      label="In Review"
                      value={approvalsBrandStatusFilters['In Review']}
                      onChange={event => handleChangeApprovalBrandFilters(event, 'In Review')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="Rejected1"
                      label="Rejected"
                      value={approvalsBrandStatusFilters.Rejected}
                      onChange={event => handleChangeApprovalBrandFilters(event, 'Rejected')}
                    />
                  </FormGroup>
                </Col>
                */}
                <Col sm={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Deal Assigned?
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="AssignedDealOwner"
                      label="Assigned"
                      value={dealAssignedFilters.Assigned}
                      onChange={event => handleChangeDealAssignedFilters(event, 'Assigned')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="NotAssignedDealOwner"
                      label="Not Assigned"
                      value={dealAssignedFilters['Not Assigned']}
                      onChange={event => handleChangeDealAssignedFilters(event, 'Not Assigned')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Insertion Order?
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="AssignedInsertionOrder"
                      label="Assigned"
                      value={insertionOrderAssignedFilters.Assigned}
                      onChange={event => handleChangeInsertionOrderAssignedFilters(event, 'Assigned')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="NotAssignedInsertionOrder"
                      label="Not Assigned"
                      value={insertionOrderAssignedFilters['Not Assigned']}
                      onChange={event => handleChangeInsertionOrderAssignedFilters(event, 'Not Assigned')}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col sm={4}>
              <Row>
                <Col sm={1} />
                <Col sm={11} style={{ paddingLeft: '20px' }}>
                  <Label className="bold-text" style={{ marginBottom: '8px' }}>
                    Date Added
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '13px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="lastActivityDateCheckBox"
                      value={enableDateAdded}
                      onChange={e => e.target && handleChangeDateAddedCheckBox(e)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={5} style={{ paddingLeft: '20px' }}>
                  <Select
                    value={dateAddedValue}
                    onChange={(option) => {
                      if ((dateAddedValue !== 'Between' && option.value === 'Between') || (dateAddedValue === 'Between' && option.value !== 'Between')) {
                        setDateFrom(null);
                        setDateTo(null);
                        setSingleDate(null);
                      }
                      setDateAddedValue(option);
                    }}
                    options={dateAddedLabels}
                    isDisabled={!enableDateAdded}
                    styles={customStyles}
                  />
                </Col>
                <Col sm={6}>
                  {dateAddedValue.value === 'Between' ? (
                    <div style={{ marginRight: '-30px' }} className="pb-2">
                      <IntervalDatePickerField
                        onChange={({ start, end }) => {
                          setDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setDateTo(end);
                        }}
                        startDate={dateFrom}
                        endDate={dateTo}
                        maxDate={new Date()}
                        placeholderFrom="Start Date"
                        placeholderTo="End Date"
                        verticalFields
                        disabled={!enableDateAdded}
                      />
                    </div>
                  ) : (
                    <div className="date-picker datepicker-in-prospects">
                      <DatePicker
                        selected={singleDate}
                        onChange={(date) => {
                          if (dateAddedValue.value === '<=' || dateAddedValue.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          setSingleDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm datepicker-in-prospects w-100"
                        disabled={!enableDateAdded}
                      />
                      {singleDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '13px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm={2}>
              <Label className="bold-text">
                Insertion Order(s)
              </Label>
              <ReactTags
                ref={reactTagsInsertionOrders}
                tags={insertionOrders}
                suggestions={insertionOrdersSuggestions}
                onDelete={onDeleteInsertionOrder}
                onAddition={onAdditionInsertionOrder}
                delimiters={['Enter', ',']}
                placeholderText="Insertion Order name(s)"
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  // _.isEqual(a.approvalStatusFilters, a1.approvalStatusFilters) &&
  // _.isEqual(a.approvalsBrandStatusFilters, a1.approvalsBrandStatusFilters) &&
  _.isEqual(a.dealAssignedFilters, a1.dealAssignedFilters) &&
  _.isEqual(a.insertionOrderAssignedFilters, a1.insertionOrderAssignedFilters) &&
  _.isEqual(a.cspOwners, a1.cspOwners) &&
  _.isEqual(a.brands, a1.brands) &&
  _.isEqual(a.dealStages, a1.dealStages) &&
  _.isEqual(a.insertionOrders, a1.insertionOrders) &&
  _.isEqual(a.brandsSuggestions, a1.brandsSuggestions) &&
  _.isEqual(a.cspOwnersSuggestions, a1.cspOwnersSuggestions) &&
  _.isEqual(a.dealStagesSuggestions, a1.dealStagesSuggestions) &&
  _.isEqual(a.insertionOrdersSuggestions, a1.insertionOrdersSuggestions) &&
  _.isEqual(a.enableDateAdded, a1.enableDateAdded) &&
  _.isEqual(a.dateAddedValue, a1.dateAddedValue) &&
  _.isEqual(a.singleDate, a1.singleDate) &&
  _.isEqual(a.dateFrom, a1.dateFrom) &&
  _.isEqual(a.dateTo, a1.dateTo) &&
  _.isEqual(a.selectedProspectIds, a1.selectedProspectIds));
Actions.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  getUrlSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  cspOwnersSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setCspOwnersFilter: PropTypes.func.isRequired,
  setCspOwners: PropTypes.func.isRequired,
  cspOwners: PropTypes.arrayOf(PropTypes.any).isRequired,
  setBrandsFilter: PropTypes.func.isRequired,
  setBrands: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setDealStagesFilter: PropTypes.func.isRequired,
  setDealStages: PropTypes.func.isRequired,
  dealStages: PropTypes.arrayOf(PropTypes.any).isRequired,
  dealStagesSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setInsertionOrdersFilter: PropTypes.func.isRequired,
  setInsertionOrders: PropTypes.func.isRequired,
  insertionOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
  insertionOrdersSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  // setApprovalStatusFilters: PropTypes.func.isRequired,
  // approvalStatusFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  // setApprovalsBrandStatusFilters: PropTypes.func.isRequired,
  // approvalsBrandStatusFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setDealAssignedFilters: PropTypes.func.isRequired,
  dealAssignedFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  insertionOrderAssignedFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setInsertionOrderAssignedFilters: PropTypes.func.isRequired,
  enableDateAdded: PropTypes.bool.isRequired,
  setEnableDateAdded: PropTypes.func.isRequired,
  dateAddedValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setDateAddedValue: PropTypes.func.isRequired,
  dateFrom: PropTypes.objectOf(PropTypes.any),
  setDateFrom: PropTypes.func.isRequired,
  dateTo: PropTypes.objectOf(PropTypes.any),
  setDateTo: PropTypes.func.isRequired,
  singleDate: PropTypes.objectOf(PropTypes.any),
  setSingleDate: PropTypes.func.isRequired,
  selectedProspectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setShowAddToInsertionOrderModal: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  dateFrom: null,
  dateTo: null,
  singleDate: null,
};

export default Actions;
