/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Card, CardBody } from 'reactstrap';

const ItemTypes = {
  CARD: 'card',
};

const BrandOpportunityCardOrdering = ({
  opportunity, index, moveItem,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id: opportunity.order, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  let opacityStyle = {
    cursor: 'move',
  };
  if (isDragging) {
    opacityStyle = {
      opacity: 0.5,
    };
  }

  drag(drop(ref));

  return (
    <div ref={ref} key={`div-${index}`} style={opacityStyle}>
      <Card>
        <CardBody style={{ padding: '2rem', border: '1px solid #ccc' }}>
          {opportunity.logoUrl && (<img style={{ maxWidth: '200px', maxHeight: '120px' }} src={opportunity.logoUrl} alt="" />)}
          <h3 style={{ color: 'rgb(1, 80, 151)', display: 'inline', marginLeft: '30px' }}>{opportunity.title}</h3>
        </CardBody>
      </Card>
    </div>
  );
};

export default BrandOpportunityCardOrdering;
