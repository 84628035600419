import React from 'react';
import { CKEditor } from 'ckeditor4-react';
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Spinner, Input } from 'reactstrap';
import ApiClient, { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import Select from 'react-select';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledButton from '../../../../shared/components/components/ui/styled-button';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import DropZoneField from '../../../../shared/components/form/DropZone';
import withNotice from '../../../../containers/App/store/with-notice';
import BrandResourceFilesSection from './components/BrandResourceFilesSection';

const styles = {
  label: {
    maxWidth: '150px',
  },
  fileZone: {
    border: '1px dashed #ccc',
    padding: '30px',
    margin: '20px 0',
    cursor: 'move',
  },
  fileZoneOr: {
    textAlign: 'center',
    paddingTop: '80px',
    fontWeight: 'bold',
  },
  uploadRow: {
    border: '1px solid #ccc',
  },
  uploadYouTubeUrl: {
    paddingTop: '80px',
  },
};

class BrandResourceNew extends React.Component {
  static async loadBrandOptions(inputValue) {
    const api = new ApiClient();
    const response = await api.client.get(`/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`);

    return response.data.brands.filter(b => b.displayInUi === 'Yes').map(record => ({
      value: record._id,
      label: record.companyName,
      record,
    }));
  }

  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeBrand = this.handleChangeBrand.bind(this);
    this.handleChangeIntroText = this.handleChangeIntroText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeLogoUrl = this.handleChangeLogoUrl.bind(this);
    this.handleChangeVideoUrl = this.handleChangeVideoUrl.bind(this);
    this.handleChangeIsDeleted = this.handleChangeIsDeleted.bind(this);
    this.saveFilesData = this.saveFilesData.bind(this);
    this.selected = null;
    this.state = this.constructor.defaultState;
  }

  componentDidMount() {
    const { match } = this.props;
    if (match && match.params && match.params.resourceId) {
      this.loadData(match.params.resourceId);
    }
  }
  static requiredData = [
    'brand',
  ];
  static defaultState = {
    selectedBrand: null,
    loading: {
      loadBrandResource: false,
      formSubmit: false,
    },
    record: {
      params: {
        education: [],
        brandGuidelines: [],
        creativeAssets: [],
      },
      errors: {},
      populated: {},
    },
    showPreviewLink: {
      logo: true,
      video: true,
    },
    filesVersion: 0,
    filesToDelete: [],
  };

  loadData(resourceId) {
    const { addNotice } = this.props;
    this.setState(state => ({
      loading: {
        ...state.loading,
        loadBrandResource: true,
      },
    }));
    axios.get(`/api/portal/brands/get-brand-resource-data?brandResourceId=${resourceId}`).then((response) => {
      const { success, brandResource } = response.data;
      if (success) {
        if (brandResource.logoUrl) {
          brandResource.logoUrlObj = {
            path: brandResource.logoUrl,
            preview: brandResource.logoUrl,
          };
        } else {
          brandResource.logoUrlObj = null;
        }
        if (brandResource.videoUrl) {
          brandResource.videoUrlObj = {
            path: brandResource.videoUrl,
            preview: '/images/resources/file-icon.png',
          };
        } else {
          brandResource.videoUrlObj = null;
        }
        this.setState({
          record: {
            params: brandResource,
            errors: {},
            populated: {},
          },
          selectedBrand: {
            _id: brandResource.brandObj._id,
            companyName: brandResource.brandObj.companyName,
          },
        });
      } else {
        addNotice({
          message: 'An error occurred while fetching data',
          type: 'warning',
        });
      }
      this.setState(state => ({
        loading: {
          ...state.loading,
          loadBrandResource: false,
        },
      }));
    }).catch((error) => {
      console.log(error);
    });
  }

  handleChange(propertyOrRecord, value) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        record: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        record: {
          ...state.record,
          params: {
            ...state.record.params,
            [propertyOrRecord]: value,
          },
          errors: {
            ...state.record.errors,
            [propertyOrRecord]: undefined,
          },
        },
      }));
    }
  }
  handleInputChange(name, event) {
    this.handleChange(name, event.target.value);
  }
  handleChangeBrand(selected) {
    if (selected) {
      this.setState({
        selectedBrand: selected.record,
      });
      this.handleChange('brand', selected.record._id);
    }
  }
  handleChangeIntroText(event) {
    const { editor } = event;
    this.handleChange('introText', editor.getData());
  }
  handleChangeLogoUrl(files) {
    const file = files.length ? files[0] : '';
    this.handleChange('logoUrlObj', file);
    if (!file) {
      if (this.state.record.params.logoPath) {
        const { filesToDelete } = this.state;
        filesToDelete.push(this.state.record.params.logoPath);
        this.setState(() => ({
          filesToDelete,
        }));
      }
      this.handleChange('logoUrl', file);
    }
    this.setState(state => ({
      showPreviewLink: {
        ...state.showPreviewLink,
        logo: false,
      },
    }));
  }
  handleChangeVideoUrl(files) {
    const file = files.length ? files[0] : '';
    if (typeof file.path !== 'undefined') {
      file.preview = '/images/resources/file-icon.png';
    }
    this.handleChange('videoUrlObj', file);
    if (!file) {
      if (this.state.record.params.videoPath) {
        const { filesToDelete } = this.state;
        filesToDelete.push(this.state.record.params.videoPath);
        this.setState(() => ({
          filesToDelete,
        }));
      }
      this.handleChange('videoUrl', file);
    }
    this.setState(state => ({
      showPreviewLink: {
        ...state.showPreviewLink,
        video: false,
      },
    }));
  }
  handleChangeIsDeleted = (e) => {
    this.handleChange('isDeleted', e.value);
  }
  saveFilesData = (filesIndex, files, deletedFile) => {
    this.handleChange(filesIndex, files);
    this.setState(state => ({
      filesVersion: state.filesVersion + 1,
    }));
    if (deletedFile) {
      const { filesToDelete } = this.state;
      filesToDelete.push(deletedFile);
      this.setState(() => ({
        filesToDelete,
      }));
    }
  }
  handleSubmit(event) {
    const { record, filesToDelete } = this.state;
    const { addNotice } = this.props;
    event.preventDefault();
    let isValid = true;
    this.constructor.requiredData.forEach((item) => {
      if (!record.params[item]) {
        isValid = false;
        this.setState(state => ({
          record: {
            ...state.record,
            errors: {
              ...state.record.errors,
              [item]: 'This field is required',
            },
          },
        }));
      }
    });
    if (!isValid) {
      addNotice({
        message: 'Please Check the form. Seems  like you have not filled some required fields',
        type: 'warning',
      });
      return true;
    }
    const data = new FormData();
    Object.keys(record.params).forEach((key) => {
      let valueToAdd = record.params[key];
      if (typeof valueToAdd === 'object' && !['logoUrlObj', 'videoUrlObj'].includes(key)) {
        valueToAdd = JSON.stringify(valueToAdd);
      }
      data.append(key, valueToAdd);
    });
    data.append('filesToDelete', JSON.stringify(filesToDelete));
    this.setState(state => ({
      loading: {
        ...state.loading,
        formSubmit: true,
      },
    }));

    axios({
      method: 'post',
      url: '/api/portal/brands/save-brand-resource',
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    }).then((response) => {
      const { success } = response.data;
      if (success) {
        addNotice({
          message: 'All data has been saved',
          type: 'success',
        });
        const { match } = this.props;
        if (!match || !match.params || !match.params.resourceId) {
          this.setState(this.constructor.defaultState);
        }
      } else {
        addNotice({
          message: 'An error occurred while saving data',
          type: 'warning',
        });
      }
      this.setState(state => ({
        loading: {
          ...state.loading,
          formSubmit: false,
        },
        filesToDelete: [],
      }));
    }).catch((error) => {
      console.log(error);
      this.setState(state => ({
        loading: {
          ...state.loading,
          formSubmit: false,
        },
      }));
    });
    return false;
  }
  render() {
    const {
      record,
      selectedBrand,
      loading,
      showPreviewLink,
      filesVersion,
    } = this.state;
    let selectedBrandOption = {};
    if (selectedBrand) {
      selectedBrandOption = {
        value: selectedBrand._id,
        label: selectedBrand.companyName,
      };
    }
    const { match } = this.props;
    const isEditForm = !!((match && match.params && match.params.resourceId));
    const logoValue = typeof record.params !== 'undefined' && typeof record.params.logoUrlObj !== 'undefined' && record.params.logoUrlObj !== null && record.params.logoUrlObj !== '' ?
      [record.params.logoUrlObj] : '';
    const videoValue = typeof record.params !== 'undefined' && typeof record.params.videoUrlObj !== 'undefined' && record.params.videoUrlObj !== null && record.params.videoUrlObj !== '' ?
      [record.params.videoUrlObj] : '';
    const isDeleted = typeof record.params !== 'undefined' && typeof record.params.isDeleted !== 'undefined' && record.params.isDeleted !== null ?
      record.params.isDeleted : 0;
    const isDeletedValue = isDeleted ? { value: 1, label: 'No' } : { value: 0, label: 'Yes' };

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs
              isBackButton
              links={[
                { title: 'Home', path: '/' },
                { title: 'Brand Resources', path: '/resources/BrandResource/actions/list' },
                { title: isEditForm ? 'Edit Brand Resource' : 'Create Brand Resource', path: false },
              ]}
            />
          </Col>
          <Col>
            <Card>
              <CardBody style={{ padding: '2rem' }}>
                <CardTitle>
                  <h3>{isEditForm ? 'Edit Brand Resource' : 'Create Brand Resource'}</h3>
                </CardTitle>
                {loading.loadBrandResource &&
                (
                  <div className="text-center"><Spinner color="primary" size="lg" /></div>
                )
                }
                {!loading.loadBrandResource && (
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup row>
                      <Label sm={2} style={styles.label}>Is Active</Label>
                      <Col sm={10}>
                        <Select
                          value={isDeletedValue}
                          options={[
                            { value: 0, label: 'Yes' },
                            { value: 1, label: 'No' },
                          ]}
                          onChange={this.handleChangeIsDeleted}
                        />
                        {record.errors.isDeleted && (<span className="text-danger">{record.errors.isDeleted}</span>)}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={2} style={styles.label}>Brand</Label>
                      <Col sm={10}>
                        <SelectAsync
                          cacheOptions
                          value={selectedBrandOption}
                          defaultOptions
                          loadOptions={BrandResourceNew.loadBrandOptions}
                          onChange={this.handleChangeBrand}
                          placeholder="Please Select Brand"
                        />
                        {record.errors.brand && (<span className="text-danger">{record.errors.brand}</span>)}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="bodyEditor" sm={2} style={styles.label}>Intro Text</Label>
                      <Col sm={10}>
                        <CKEditor
                          config={{versionCheck: false}}
                          id="bodyEditor"
                          name="body"
                          initData={typeof record.params !== 'undefined' && typeof record.params.introText !== 'undefined' && record.params.introText !== null ? record.params.introText : ''}
                          onChange={this.handleChangeIntroText}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={2} style={styles.label}>Logo</Label>
                      <Col sm={10}>
                        <DropZoneField
                          input={{
                            name: 'logoUrl',
                            id: 'logoUrl',
                            value: logoValue,
                            onChange: this.handleChangeLogoUrl,
                          }}
                          customHeight
                        />
                        {logoValue !== '' && logoValue.length > 0 && showPreviewLink.logo && (
                          <a href={logoValue[0].path} target="_blank" rel="noopener noreferrer">Preview&nbsp;&nbsp;<i className="fas fa-external-link-alt" /></a>
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={2} style={styles.label}>Video</Label>
                      <Col sm={10}>
                        <Row>
                          <Col sm={6} style={{ paddingLeft: '50px' }}>
                            <FormControlLabel
                              control={
                                <Input
                                  type="radio"
                                  name="videoType"
                                  value="upload"
                                  className="mt-0"
                                  checked={typeof record.params.videoType === 'undefined' || record.params.videoType === 'upload'}
                                  onChange={(e) => { this.handleInputChange('videoType', e); }}
                                />
                              }
                              label="Upload Video"
                            />
                          </Col>
                          <Col sm={6} style={{ paddingLeft: '50px' }}>
                            <FormControlLabel
                              control={
                                <Input
                                  type="radio"
                                  name="videoType"
                                  value="url"
                                  className="mt-0"
                                  checked={record.params.videoType === 'url'}
                                  onChange={(e) => { this.handleInputChange('videoType', e); }}
                                />
                              }
                              label="Enter URL of the video"
                            />
                          </Col>
                        </Row>
                        {(typeof record.params.videoType === 'undefined' || record.params.videoType === 'upload') &&
                        (
                          <>
                            <DropZoneField
                              input={{
                                name: 'videoUrl',
                                id: 'videoUrl',
                                value: videoValue,
                                accept: 'video/x-flv, video/mp4, application/x-mpegURL, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv',
                                onChange: this.handleChangeVideoUrl,
                              }}
                            />
                            {videoValue !== '' && videoValue.length > 0 && showPreviewLink.video && (
                              <a href={videoValue[0].path} target="_blank" rel="noopener noreferrer">Preview&nbsp;&nbsp;<i className="fas fa-external-link-alt" /></a>
                            )}
                          </>
                        )}
                        {record.params.videoType === 'url' &&
                        (
                          <>
                            <Input name="videoUrlExternal" value={record.params.videoUrlExternal} onChange={(e) => { this.handleInputChange('videoUrlExternal', e); }} />
                          </>
                        )}
                      </Col>
                    </FormGroup>
                    {selectedBrand && record.params.education && (
                      <BrandResourceFilesSection
                        files={record.params.education}
                        filesIndex="education"
                        title="Education"
                        styles={styles}
                        addNotice={this.props.addNotice}
                        saveFilesData={this.saveFilesData}
                        filesVersion={filesVersion}
                        company={selectedBrand.companyName}
                      />
                      )}
                    {selectedBrand && record.params.brandGuidelines && (
                      <BrandResourceFilesSection
                        files={record.params.brandGuidelines}
                        filesIndex="brandGuidelines"
                        title="Brand Guidelines"
                        styles={styles}
                        addNotice={this.props.addNotice}
                        saveFilesData={this.saveFilesData}
                        filesVersion={filesVersion}
                        company={selectedBrand.companyName}
                      />
                    )}
                    {selectedBrand && record.params.creativeAssets && (
                      <BrandResourceFilesSection
                        files={record.params.creativeAssets}
                        filesIndex="creativeAssets"
                        title="Creative Assets"
                        styles={styles}
                        addNotice={this.props.addNotice}
                        saveFilesData={this.saveFilesData}
                        filesVersion={filesVersion}
                        company={selectedBrand.companyName}
                      />
                    )}
                    <FormGroup style={{ marginBottom: 0 }}>
                      <StyledButton
                        type="submit"
                        disabled={loading.formSubmit}
                        className="is-primary"
                        style={{ marginTop: 20 }}
                      >
                        {loading.formSubmit && (<><Spinner color="primary" size="sm" />&nbsp;&nbsp;</>)}
                        <i className="icomoon-save" />
                        <span className="btn-text">Save</span>
                      </StyledButton>
                      <StyledButton
                        type="button"
                        className="is-primary btn-danger"
                        onClick={() => { window.history.back(); }}
                        style={{ marginTop: 20 }}
                      >
                        <span className="btn-text">Cancel</span>
                      </StyledButton>
                    </FormGroup>
                  </form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

BrandResourceNew.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(BrandResourceNew);
