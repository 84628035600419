import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, CardBody, Card, Badge, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { axios } from 'ApiClient';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import WVValidator from '../../../../../../shared/helpers/WVValidator';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import AdminTable from '../../../../Influencer/Influencer/influencerProfile/AdminTable';
import QrCodeComponent from '../../../../Influencer/Influencer/influencerProfile/QrCodeComponent';
import DealStageOption from '../../../../Influencer/Influencer/influencerProfile/components/DealStageOption';
import DealStatusOption from '../../../../Influencer/Influencer/influencerProfile/components/DealStatusOption';
import withNotice from '../../../../../App/store/with-notice';
import { updateDeal } from '../../../../../../redux/reducers/admin/InfluencerProfileReducer';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';
import TableCellContextMenu from '../../../../../../containers/admin/Influencer/Research/Prospects/components/custom/TableCellContextMenu';
import DealInfoModal from '../../../../../../containers/admin/Influencer/Influencer/influencerProfile/DealInfoModal';
import DealsFilter from './components/DealsFilter';

const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

const AgentDeals = ({ deals, addNotice, setShouldRefetch }) => {
  const pipelines = getConstant('deal', 'pipelines', []);
  const allStages = {};
  const dealStageOptions = pipelines?.map((p) => {
    const stages = Object.keys(p.stages).map((k) => {
      if (p.label.toLowerCase() === 'Main Pipeline'.toLowerCase()) allStages[k] = p.stages[k];
      return { value: k, label: p.stages[k] };
    });
    return {
      id: p.id,
      stages,
    };
  });

  const dispatch = useDispatch();
  const [rightDeals, setRightDeals] = useState([]);
  const [clickDeal, setClickDeal] = useState(null);
  const [redirects, setRedirects] = useState([]);
  const [version, setVersion] = useState((new Date()).toString());

  const statusChange = async (deal, value) => {
    dispatch(updateDeal(deal._id, value));
    setShouldRefetch(true);
  };

  const getVanityUrlRedirect = async (payload) => {
    try {
      const resp = await axios.post('/api/portal/find-vanity-url-redirect', payload);
      if (resp.data.success && resp.data.resultUrls) {
        setRedirects(resp.data.resultUrls);
      }
    } catch (e) {
      console.error('Got error!', e);
    }
  };

  useEffect(async () => {
    if (deals?.length > 0) {
      const dealPack = [];
      deals.forEach((d) => {
        if (d && d.dealTerms?.coupon_code && d.ilDealData.offer?._id) {
          const payLoad = {
            dealId: d._id,
            offerId: d.ilDealData.offer._id,
            couponCode: d.dealTerms.coupon_code,
          };
          dealPack.push(payLoad);
        }
      });

      const payload = {
        deals: dealPack,
        qr: true,
      };
      getVanityUrlRedirect(payload);
    }
  }, [deals]);

  const getValidationStatus = (deal) => {
    let color = 'success';
    let message = 'Valid';

    if (WVValidator.isEmpty(deal.ilDealData?.offer)) {
      color = 'danger';
      message = 'Missing Offer';
    } else if (WVValidator.isEmpty(deal.dealTerms?.type_of_deal)) {
      color = 'danger';
      message = 'Missing Deal Type';
    } else if (WVValidator.isEmpty(deal.ilDealData?.influencer)) {
      return (
        <Badge color="danger" className="table-rounded">
          Missing Creator{' '}
          <Tooltip title="Fix Deal">
            <button className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall" />
          </Tooltip>
        </Badge>);
    }
    return (<Badge color={color} className="table-rounded">{message}</Badge>);
  };

  const columnTransform = {
    name: {
      title: 'Deal Name',
      valueFunc: deal => (
        <strong>
          {deal?.dealname}
        </strong>
      ),
      sort: deal => deal?.dealname,
    },
    offer: {
      title: 'Offer',
      valueFunc: deal => (<strong>{deal?.ilDealData?.offer?.name}</strong>),
      sort: deal => deal?.ilDealData?.offer?.name,
    },
    dealtype: {
      title: 'Deal Type',
      valueFunc: deal => (<strong>{deal.dealTerms?.type_of_deal}</strong>),
      sort: deal => deal.dealTerms?.type_of_deal,
    },
    dealStage: {
      title: 'Deal Stage',
      valueFunc: deal => (
        <DealStageOption
          dealStageOptons={dealStageOptions}
          deal={deal}
          addNotice={addNotice}
          statusChange={statusChange}
        />
      ),
      sort: deal => deal.dealstage,
      width: 200,
    },
    status: {
      title: 'Status',
      valueFunc: deal => (
        <DealStatusOption
          deal={deal}
          statusChange={statusChange}
        />
      ),
      sort: deal => deal.dealTerms?.cpa_status || '',
    },
    brandApprovedDate: {
      title: 'Brand Approved Date',
      valueFunc: deal => (deal.brandApprovedDate ? moment.utc(deal.brandApprovedDate).format('MM/DD/YYYY') : ''),
      sort: deal => deal.brandApprovedDate || '',
    },
    publishedDate: {
      title: 'Publish Date',
      valueFunc: deal => (deal?.dealTerms?.published_date ? moment.utc(deal.dealTerms.published_date).format('MM/DD/YYYY') : ''),
      sort: deal => deal?.dealTerms?.published_date || '',
    },
    content_release_date: {
      title: 'Content Release Date',
      valueFunc: deal => (deal.dealTerms?.content_release_date ? moment.utc(deal.dealTerms.content_release_date).format('MM/DD/YYYY') : ''),
      sort: deal => deal.dealTerms?.content_release_date || '',
    },
    promoCode: {
      title: 'Promo Code',
      valueFunc: deal => (<strong>{deal.dealTerms?.coupon_code}</strong>),
      sort: deal => deal.dealTerms?.coupon_code || '',
    },
    validation: {
      title: 'Valid?',
      valueFunc: deal => getValidationStatus(deal),
      sort: () => 1,
    },
    actions: {
      title: ' ',
      valueFunc: (deal) => {
        const contextMenuOptions = [
          { label: 'Deal Information', handler: () => { setClickDeal(deal._id); } },
          { type: 'divider', label: 'divider1' },
          { label: 'Edit Deal', handler: () => goToPage(`/influencers/creator-deal-editor/${deal._id}`) },
          { type: 'divider', label: 'divider2' },
          { label: 'View Hubspot Deal', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal ? deal.hs_object_id : 0}/`) },
          { label: 'View Hubspot Contact ', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${deal.contact?.hs_object_id || 0}/`) },
        ];
        const key = redirects.find(r => r.dealId === deal._id);
        if (key && key.resultUrl) {
          contextMenuOptions.push({ type: 'divider', label: 'divider3' });
          contextMenuOptions.push({
            label: 'Show QR Code',
            type: 'popover',
            popover: <QrCodeComponent
              dealname={deal?.dealname?.trim()}
              url={key.resultUrl}
              showIcon={false}
            />,
          });
        }
        return (
          <>
            <TableCellContextMenu options={contextMenuOptions} />
            <DealInfoModal
              isOpen={clickDeal && clickDeal === deal._id}
              deal={{ params: deal, id: deal._id }}
              toggleModal={() => setClickDeal(null)}
            />
          </>
        );
      },
      sort: () => 1,
      disableSortBy: true,
      width: 50,
    },
  };

  return (
    <div key={`deals-${deals && deals.length > 0 ? deals[0]._id : 'loading'}`}>
      <h2 className="section-title mt-0 mb-4">Hubspot Deals</h2>
      <Card>
        <CardBody>
          <DealsFilter
            deals={deals}
            allStages={allStages}
            setRightDeals={setRightDeals}
            setVersion={setVersion}
          />
          <Row>
            <Col>
              {!deals ? (<div className="text-center"><Spinner color="primary" size="lg" /></div>) : null}
              {deals && deals.length > 0 && rightDeals.length > 0 ? (
                <AdminTable>
                  <MatTableForRedux
                    dataArray={rightDeals}
                    selectable={false}
                    columns={columnTransform}
                    version={version}
                    hideToolbar
                    defaultOrder={{
                      order: 'desc',
                      orderBy: 'dealname',
                    }}
                    rowsPerPage={10}
                  />
                </AdminTable>
              ) : (<Alert color="warning"><p className="py-2">The Agent currently has no deals.</p></Alert>)}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
AgentDeals.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
  setShouldRefetch: PropTypes.func.isRequired,
};

export default withNotice(AgentDeals);
