import React, { useState } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import { Row, Col, Button, Container, Card, CardBody, CardTitle } from 'reactstrap';
import PropTypes from 'prop-types';
import Filter from '../FilterNew';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import GenerateTable from './GenerateTable';
import LoadingReport from "../../../../shared/components/LoadingReport";


const SummaryReport = ({ addNotice }) => {
  const [loading, setLoaing] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));

  const auditReport = (r) => {
    const messages = [];
    const rep = { ...r };
    const {
      error, deals,
    } = rep;
    const {
      deal, offer, admin, report, influencer,
    } = deals[0] || {};
    if (!offer && report) {
      messages.push('Unable to find right Offer for the Report');
      rep.isDeleted = true;
    }
    if (!deal && report) {
      messages.push('Unable to find right Deal for the Report');
      rep.isDeleted = true;
    }
    if (deal && !admin) {
      messages.push('Unable to find right Admin User for the Report');
      rep.existStatus = 'info';
    }
    if (admin && !admin.affiliateManagerId) {
      messages.push(`Admin ${admin?.firstName || ''} ${admin?.lastName || ''} hasn't assigned to the right Account Manager`);
      rep.existStatus = 'info';
    }

    if (admin && influencer && (Number(influencer?.affiliateDataObject?.account_manager_id) !== Number(admin?.affiliateManagerId))) {
      messages.push('It appears there is an owner mismatch between Hubspot, HasOffers, or IL internal database');
      rep.existStatus = 'info';
    }
    if (error) messages.push(error);

    return { messages, ...deals[0] };
  };

  const fetchAmountReport = async () => {
    setLoaing(true);

    try {
      const resp = await axios.post('/api/portal/reports/post-report-for-audit-v2', {
        startDate,
        endDate,
        identity,
      });
      if (resp.data.success) {
        setReportData(resp.data.items.map(r => auditReport(r)).filter(r => r.messages.length > 0));
        setLoaing(false);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Deal Audit Report', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <CardTitle>
              <h3>Deal Audit Report</h3>
            </CardTitle>
            <Row className="mt-3">
              <Col>
                <Filter
                  setValue={(param, value) => {
                    console.log(value);
                    if (param === 'startDate') {
                      setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                    }
                    if (param === 'endDate') {
                      setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                    }
                  }}
                />
              </Col>
              <Col lg="auto">
                <div>
                  <Button
                    color="primary"
                    onClick={async () => {
                      await fetchAmountReport();
                      return false;
                    }}
                    className="btn-sm"
                    disabled={!!loading}
                  >
                    Run Report
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <LoadingReport loading={loading} />
              </Col>
            </Row>
            {/* <Row>
              <Col>
                {loading && (
                  <div className="text-center w-100">
                    <Spinner color="primary" size="lg" />
                    <br /> Generating the report... <br />
                    This may take some time, depending on the date range specified.
                  </div>)}
              </Col>
            </Row> */}
            <GenerateTable
              reportData={reportData}
              loading={loading}
            />
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
