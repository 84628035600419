import React from 'react';
import PropTypes from 'prop-types';

const PermissionComponent = ({ message }) =>
  (
    <div className="text-center">
      <div className="not-found__content">
        <h1 className="not-found__info mt-5">Ooops!</h1>
        <h3 className=" mb-3">{message || 'You do not have access to this page.'}</h3>
        <a href="/" className="btn btn-primary radius-button">Back Home</a>
      </div>
    </div>
  );
  PermissionComponent.propTypes = {
  message: PropTypes.string,
};
PermissionComponent.defaultProps = {
  message: '',
};
export default PermissionComponent;
