// import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';

const VideoDetails = ({ record }) => {
  const { title, hubilDeal, contentText } = record.params;
  let icon = '';
  const hsLink = typeof hubilDeal !== 'undefined' && typeof hubilDeal[0] !== 'undefined' ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${hubilDeal[0].hs_object_id}` : false;
  if (hsLink) {
    icon = (<a title="Show HubSpot Deal" target="_blank" rel="noopener noreferrer" href={hsLink}><i className="fab fa-hubspot" style={{ fontSize: '18px' }} /></a>);
  }
  let title2 = contentText;
  if (!title && contentText && contentText.length > 60) {
    title2 = `${contentText.substring(0, 60)}...`;
  }
  if (!title && !contentText && hubilDeal[0]) {
    title2 = hubilDeal[0].dealname;
  }

  return (
    <>
      <span>
        {title || title2 || ''}
        &nbsp;
        {icon}
      </span>
    </>
  );
};
VideoDetails.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default VideoDetails;

