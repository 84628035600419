import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EyeIcon from 'mdi-react/EyeIcon';
import withNotice from '../../../../../App/store/with-notice';
import { Spinner } from 'reactstrap';
import { formatIntNumber } from '../../../../../../shared/helpers/WVFormatter'
import { axios } from 'ApiClient';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const BalanceComponent = ({
  record,
  addNotice,
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(false);

  const fetchBalance = () => {
    setLoading(true);

    axios.post(
      '/api/portal/finance/post-fetch-stripe-account-balance',
      { accountId: record.id },
    ).then((response) => {
      if (response.data.success && response.data.balance) {
        setBalance(response.data.balance);
      } else {
        if (response.data.error) {
          addNotice({
            message: response.data.error,
            type: 'error',
          });
        }
        addNotice({
          message: 'Could not retrieve Stripe account\'s balance',
          type: 'error',
        });
      }
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (show) fetchBalance();
  }, [show]);

  return (
    <div style={{ width: '100px' }}>
      {show ? (
        <>
          {loading ? (
            <div className="text-center">
              <Spinner color="primary" size="sm" />
            </div>
          ) : (
            <>
              {balance?.available?.length > 0 && (
                <p>{`Available: ${balance.available[0].currency.toUpperCase()} ${formatIntNumber(balance.available[0].amount)}`}</p>
              )}
              {balance?.connect_reserved?.length > 0 && (
                <p>{`Reserved: ${balance.connect_reserved[0].currency.toUpperCase()} ${formatIntNumber(balance.connect_reserved[0].amount)}`}</p>
              )}
              {balance?.pending?.length > 0 && (
                <p>{`Pending: ${balance.pending[0].currency.toUpperCase()} ${formatIntNumber(balance.pending[0].amount)}`}</p>
              )}
            </>
          )}
        </>
      ) : (
        <Tooltip title="View Balance">
          <IconButton
            size="small"
            onClick={() => setShow(!show)}
            className="material-table__toolbar-button"
          >
            <EyeIcon className="il-color-deep-blue" size="20" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

BalanceComponent.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
}

export default withNotice(BalanceComponent);
