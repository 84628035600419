/* eslint-disable filenames/match-exported */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import { formatCurrency, formatNumber } from '../../../../../shared/helpers/WVFormatter';

const WideTooltip = withStyles({
  tooltip: {
    width: '500px',
  },
})(Tooltip);

const getNumber = (str) => (str !== null && /^[0-9]+(\.[0-9]+)?$/.test(str)) ? Number(str) : 0;

const CAC = React.memo(({
  cac, targetCac, defaultTargetCac, targetCacPercentage, allow
}) => {
  const valueNumber = getNumber(cac);
  const value = cac === null || cac === '-' ? '-' : formatCurrency(valueNumber);
  const describeValue = (
    <div style={{ fontSize: '14px', maxWidth: '500px', lineHeight: 2 }}>
      <span><strong>Target CAC</strong>: {formatCurrency(getNumber(defaultTargetCac))}</span><br />
      <strong>Target Threshold %</strong>: {getNumber(targetCacPercentage) * 100}<br />
      <strong>Target CAC Threshold</strong>: {formatCurrency(getNumber(targetCac))}<br />
      <strong>Amount Over Target CAC Threshold</strong>: {valueNumber ? formatCurrency(getNumber(cac) - getNumber(targetCac)) : '-'}<br />
      <strong>Percent Over Target CAC Threshold</strong>: {valueNumber ? `${formatNumber(((getNumber(cac) - getNumber(targetCac)) / getNumber(targetCac)) * 100, 2)}%` : '-'}<br />
      {/* eslint-disable-next-line max-len */}
      <span><strong>CAC</strong> of <strong>{valueNumber ? formatCurrency(getNumber(cac)) : '-'}</strong> {allow ? 'is less than ' : 'is over'} the <strong>{formatCurrency(getNumber(targetCac))}</strong> threshold</span><br />
    </div>);
  return (
    <>
      <WideTooltip title={describeValue}><span className={allow ? 'text-success' : 'text-danger'}>{value}</span></WideTooltip>{' '}
      {allow ? (
        <FontAwesomeIcon icon="thumbs-up" className="text-success" />
      ) : (
        <FontAwesomeIcon icon="thumbs-down" className="text-danger" />
      )
      }
    </>
  );
}, (a, a1) => _.isEqual(a.cac, a1.cac) && _.isEqual(a.targetCac, a1.targetCac) && _.isEqual(a.defaultTargetCac, a1.defaultTargetCac) && _.isEqual(a.targetCacPercentage, a1.targetCacPercentage) && _.isEqual(a.allow, a1.allow));

CAC.propTypes = {
  cac: PropTypes.any,
  targetCac: PropTypes.any,
  defaultTargetCac: PropTypes.any,
  targetCacPercentage: PropTypes.any,
  allow: PropTypes.bool,
};
CAC.defaultProps = {
  cac: '-',
  targetCac: '-',
  defaultTargetCac: '-',
  targetCacPercentage: '-',
  allow: false,
};

export default CAC;
