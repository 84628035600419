import React, { useState } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Filter from '../../../../../Reports/TimePeriodFilterExtended';
import withNotice from '../../../../../../App/store/with-notice';
import GenerateTable from '../../../../../Reports/LeadsPerformanceReport/GenerateTableEmpty';
// import SaveReport from '../../../../../Reports/SaveReport';


const SummaryReport = ({ addNotice }) => {
  const [loading, setLoaing] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));

  // const [saveReport, setSaveReport] = useState(false);
  // const [saveReportModal, setSaveReportModal] = useState(false);
  // const [savedReportModal, setSavedReportModal] = useState(false);
  // const [info, setInfo] = useState({});
  const [fetched, setFetched] = useState(false);

  const fetchAmountReport = async (advertisers, csp) => {
    setLoaing(true);
    // setError(null);
    setReportData([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-leads-report', {
        startDate,
        endDate,
        identity,
        advertisers,
        csp,
      });
      if (resp.data.success) {
        setFetched(true);
        setLoaing(false);
        const reports = {};
        resp.data.items.forEach((r) => { reports[r._id] = r; });
        console.log(reports);
        resp.data.itemsNotAssigned.forEach((r) => {
          if (!reports[r._id]) {
            reports[r._id] = { _id: r._id };
          }
          reports[r._id].notassignedCount = r.count;
        });
        setReportData(Object.values(reports));
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  /* const saveReportToSave = async (name, admins) => {
    const { advertisers, csp } = info;
    setLoaing(true);
    try {
      const resp = await axios.post('/api/portal/reports/post-save-leads-performance-report', {
        startDate,
        endDate,
        identity,
        advertisers,
        csp,
        name,
        admins,
      });
      if (resp.data.success) {
        setLoaing(false);
        setSaveReportModal(false);
        setSavedReportModal(true);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  }; */

  return (
    <div className="w-100 px-3">
      <Row >
        <Col>
          <Row className="mt-3">
            <Col>
              <Filter
                setValue={(param, value) => {
                  if (param === 'startDate') {
                    setStartDate(moment(value).format('YYYY-MM-DD'));
                  }
                  if (param === 'endDate') {
                    setEndDate(moment(value).format('YYYY-MM-DD'));
                  }
                }}
              />
            </Col>
          </Row>
          { /* <SaveReport
            startDate={startDate}
            endDate={endDate}
            save={(name, admins) => saveReportToSave(name, admins)}
            saveReportModal={saveReportModal}
            savedReportModal={savedReportModal}
            setSaveReportModal={setSaveReportModal}
            setSavedReportModal={setSavedReportModal}
            saveReport={saveReport}
            setSaveReport={setSaveReport}
            type="leadsPerformance"
            ignorePeriod
          /> */ }
        </Col>
      </Row>
      <GenerateTable
        reportData={reportData}
        loading={loading}
        isSaved={false}
        fetched={fetched}
        generateReport={async (advertisers, csp) => {
          /* setInfo({ advertisers, csp });
          if (saveReport) {
            setSaveReportModal(true);
            return false;
          } */
          await fetchAmountReport(advertisers, csp);
          return true;
        }}
      />
    </div>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(SummaryReport);
