import React from 'react';
import { Link } from 'react-router-dom';
import {
  FormGroup, Label, Modal, Spinner, Input, Row, Col, ButtonToolbar, Button, ModalHeader,
} from 'reactstrap';
import ApiClient from 'ApiClient';
import Select from 'react-select';
import moment from 'moment-timezone';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import CurrencyField from '../../../../../../shared/components/form/CurrencyField';
// import StyledButton from '../../../../../../shared/components/components/ui/styled-button';
import withNotice from '../../../../../App/store/with-notice';
import formater from '../../../../../../shared/helpers/WVFormatter';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import { currentTimezoneAggr } from '../../../../../../shared/helpers/Dates';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';
import { fetchStripe } from '../../../../../../redux/reducers/admin/AccountsPayableReducer';
import CheckBoxField from '../../../../../../shared/components/CheckBox';

class BillPaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiClient();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.calculateAmountDue = this.calculateAmountDue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    // this.validateDeal = this.validateDeal.bind(this);
    this.checkForSignedInsertionOrder = this.checkForSignedInsertionOrder.bind(this);
    this.state = {
      bill: null,
      error: null,
      showUntrustedSessionError: false,
      chartsOfAccounts: [],
      loading: {
        form: true,
      },
      payout: {
        params: {},
        errors: {},
        disable: false,
        // populated: {},
      },
      validation: {
        isValid: true,
        errors: [],
      },
      hasInsertionOrder: false,
      hasSignedInsertionOrder: false,
      insertionOrderChecked: null,
      sendEmail: false,
    };
    // console.log(this.props.modal, 'TABLE MODAL');
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.modal === false && this.props.modal !== false) {
      await this.props.dispatch(fetchStripe());
      await this.fetchData();
      await this.calculateAmountDue();
    }
  }

  getTotalPaid() {
    let payoutAmount = 0;
    if (!this.state.bill || !this.state.bill.params.payments) {
      return 0;
    }
    this.state.bill.params.payments.forEach((payout) => {
      if (payout.transactionStatus === 'failure') { return false; }
      payoutAmount += Number(payout.amount);
      return true;
    });
    return payoutAmount;
  }
  static requiredData = [
    'amount',
    'method',
    'destination',
  ];
  handleChange(propertyOrRecord, value) {
    // console.log(propertyOrRecord, value);
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        payout: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        payout: {
          ...state.payout,
          params: {
            ...state.payout.params,
            [propertyOrRecord]: value,
          },
          errors: {
            ...state.payout.errors,
            [propertyOrRecord]: undefined,
          },
        },
      }));
      if (propertyOrRecord === 'destination') {
        let paymentAccount = null;

        const destinationObject = value !== 'agent' 
          ? this.state.bill?.populated?.influencer
          : this.state.bill?.populated?.agent;

        if(destinationObject?.params?.payment?.billCom?.isDefault) paymentAccount = destinationObject.params.payment.billCom;
        if(destinationObject?.params?.payment?.stripe?.isDefault) paymentAccount = destinationObject.params.payment.stripe;
      
        
        const defaultChartOfAcount = paymentAccount?.chartOfAccount
        console.log(destinationObject, defaultChartOfAcount, destinationObject?.params.payment.stripe, destinationObject?.params.payment.billCom.isDefault);

        if (destinationObject?.params.payment.billCom.isDefault) {
          this.handleChange('method', 'billcom');
        } else if (destinationObject?.params.payment.stripe.isDefault) {
          this.handleChange('method', 'stripe');
        } else {
          this.handleChange('method', null);
        }
        if (defaultChartOfAcount) {
          this.handleChange('chartOfAccount', defaultChartOfAcount);
        }
      }
      if (propertyOrRecord === 'method') {
        this.setState({
          sendEmail: ['stripe', 'billcom'].includes(value),
        });
      }
    }
  }

  async checkForSignedInsertionOrder(bill) {
    if (
      bill?.populated?.deal?.params?.dealTerms?.brand === 'BetterHelp'
      && bill?.populated?.deal?.params?.dealTerms?.type_of_deal === 'Upfront CPM'
    ) {
      const res = await this.api.client.post('/api/portal/influencer/post-check-deal-for-signed-insertion-order', {
        deal: bill?.params?.deal,
      });
      if (res.data.success === false) {
        this.setState({ insertionOrderChecked: false });
        if (res.data.error) console.log('Error: ', res.data.error);
      }
      if (res.data.success === true) {
        this.setState({
          hasInsertionOrder: res.data.hasInsertionOrder || false,
          hasSignedInsertionOrder: res.data.hasSignedInsertionOrder || false,
          insertionOrderChecked: true,
        });
      }
    } else {
      this.setState({
        hasInsertionOrder: true,
        hasSignedInsertionOrder: true,
        insertionOrderChecked: true,
      });
    }
  }

  async calculateAmountDue() {
    const {
      bill,
    } = this.state;
    const amountDue = (Number(bill?.params?.amount || 0) - Number(this.getTotalPaid()) || 0);
    this.setState(state => ({
      payout: {
        ...state.payout,
        params: {
          ...state.payout.params,
          amount: amountDue,
        },
      },
    }));
  }

  async fetchData() {
    this.setState({
      payout: {
        params: {
          amount: undefined,
          notes: undefined,
        },
        errors: {},
      },
      loading: {
        form: true,
      },
    });
    const res = await this.api.recordAction({
      resourceId: 'Bill',
      recordId: this.props.bill.id,
      actionName: 'show',
    });
    const chartsOfAccountsRes = await this.api.client.get('/api/portal/finance/get-charts-of-accounts');
    const bill = unflatten(res.data.record);
    this.checkForSignedInsertionOrder(bill);
    const defaultChartOfAcount = bill.populated?.influencer?.params?.payment?.enablePayments
      ? bill?.populated?.influencer?.params.payment.billCom.chartOfAccount
      : bill?.populated?.agent?.params.payment.billCom.chartOfAccount;
    if (defaultChartOfAcount) {
      this.handleChange('chartOfAccount', defaultChartOfAcount);
      this.handleChange('dueDate', moment().add(14, 'days').toISOString());
      this.handleChange('processDate', moment().add(9, 'days').toISOString());
    }

    this.setState({
      bill,
      chartsOfAccounts: chartsOfAccountsRes.data?.data.map(d => ({ ...d, label: d.title })) || [],
      loading: {
        form: false,
      },
    }, () => {
      this.handleChange('destination', this.state.bill.populated?.influencer?.params?.payment?.enablePayments ? 'creator' : 'agent');
    });
    console.log(this.state);
    console.log(this.props, 'TABLE');

    return null;
  }

  /* async validateDeal() {
    this.setState({
      loading: {
        form: true,
      },
    });
    const validationResponse = await this.api.client.get(`/api/portal/finance/get-deal-validation-check?id=${this.props.bill.params.deal}`);
    if (validationResponse?.data.success && validationResponse.data.data?.isValid) {
      this.setState({
        validation: {
          isValid: true,
          errors: [],
        },
      });
      await this.props.dispatch(fetchStripe());
      await this.fetchData();
    } else {
      console.log(validationResponse.data.data.validationData);
      this.setState({
        loading: {
          form: false,
        },
        validation: {
          isValid: false,
          errors: validationResponse.data.data.validationData,
        },
      });
    }
  } */
  handleChangeAmount(e, value) {
    const amount = Number(value);
    this.handleChange('amount', amount);
    if (this.state.payout.params.method === 'stripe' && this.props.stripe.availableBalance < (amount * 100)) {
      this.setState(state => ({
        payout: {
          ...state.payout,
          errors: {
            ...state.payout.errors,
            amount: 'There are insufficient funds in the Stripe account to perform this transfer.',
          },
          disable: true,
        },
      }));
    } else if ((amount + this.getTotalPaid()) > this.state.bill.params.amount) {
      this.setState(state => ({
        payout: {
          ...state.payout,
          errors: {
            ...state.payout.errors,
            amount: 'The amount to be paid should not exceed the amount owed.',
          },
          disable: true,
        },
      }));
    } else {
      this.setState(state => ({
        payout: {
          ...state.payout,
          disable: false,
        },
      }));
    }
  }
  async handleSubmit(event) {
    console.log('SEND FORM');
    event.preventDefault();
    this.setState({ error: null, showUntrustedSessionError: false });
    const { payout, bill, sendEmail } = this.state;
    let isValid = true;
    this.constructor.requiredData.forEach((item) => {
      if (!payout.params[item]) {
        isValid = false;
        this.setState(state => ({
          payout: {
            ...state.payout,
            errors: {
              ...state.payout.errors,
              [item]: 'This field is required',
            },
          },
        }));
      }
      if (item === 'amount') {
        if (payout.params.amount + this.getTotalPaid() > this.state.bill.params.amount) {
          isValid = false;
          this.setState(state => ({
            payout: {
              ...state.payout,
              errors: {
                ...state.payout.errors,
                amount: 'The amount you are trying to pay is more than the amount due.',
              },
            },
          }));
        }
      }
    });
    if (payout.params.method === 'billcom' && !payout.params.chartOfAccount) {
      isValid = false;
      this.setState(state => ({
        payout: {
          ...state.payout,
          errors: {
            ...state.payout.errors,
            chartOfAccount: 'This field is required',
          },
        },
      }));
    }
    if (payout.params.method === 'billcom' && !payout.params.dueDate) {
      isValid = false;
      this.setState(state => ({
        payout: {
          ...state.payout,
          errors: {
            ...state.payout.errors,
            dueDate: 'Invalid Due Date',
          },
        },
      }));
    }
    if (payout.params.method === 'billcom' && !payout.params.processDate) {
      isValid = false;
      this.setState(state => ({
        payout: {
          ...state.payout,
          errors: {
            ...state.payout.errors,
            processDate: 'Invalid Due Date',
          },
        },
      }));
    }
    if (payout.params.method === 'billcom' && !payout.params.invoiceNumber) {
      isValid = false;
      this.setState(state => ({
        payout: {
          ...state.payout,
          errors: {
            ...state.payout.errors,
            invoiceNumber: 'This field is required',
          },
        },
      }));
    }
    if (payout.params.method === 'billcom' && payout.params.invoiceNumber.length > 21) {
      isValid = false;
      this.setState(state => ({
        payout: {
          ...state.payout,
          errors: {
            ...state.payout.errors,
            invoiceNumber: 'Maximum length cannot be greater than 21 characters.',
          },
        },
      }));
    }
    if (!isValid) {
      // this.props.addNotice({
      // message: 'Please Check form. Seems you did not fill some fields',
      // type: 'warning',
      // });
      return true;
    }
    this.setState({
      loading: {
        form: true,
      },
    });
    const res = await this.api.client.post('/api/portal/influencer/post-add-payout', {
      amount: payout.params.amount,
      notes: payout.params.notes,
      method: payout.params.method,
      destination: payout.params.destination,
      billId: bill.id,
      chartOfAccount: payout.params.chartOfAccount,
      invoiceNumber: payout.params.method === 'billcom' ? payout.params.invoiceNumber : null,
      dueDate: payout.params.method === 'billcom' && payout.params.dueDate ? moment(payout.params.dueDate).toISOString() : null,
      processDate: payout.params.method === 'billcom' && payout.params.processDate ? moment(payout.params.processDate).toISOString() : null,
      sendEmail,
    });
    this.setState({
      loading: {
        form: false,
      },
    });
    if (res.data.success === false) {
      if (res.data.error === 'ILBillComErrorMFAInvalid') {
        this.setState({ showUntrustedSessionError: true });
      } else {
        this.setState({ error: res.data.error });
      }
    } else {
      this.closeFormDialog({ isUpdate: true });
      this.props.addNotice({
        message: 'New Payment processed successfully',
        type: 'Success',
      });
    }
    return false;
  }
  closeFormDialog({ isUpdate = false }) {
    const { closeDialog } = this.props;
    this.setState({
      bill: null,
      validation: {
        isValid: true,
        errors: [],
      },
      hasInsertionOrder: false,
      hasSignedInsertionOrder: false,
      insertionOrderChecked: null,
    });
    closeDialog(isUpdate);
  }

  render() {
    const { modalTitle, modal } = this.props;
    const {
      bill,
      payout,
      chartsOfAccounts,
      validation,
    } = this.state;
    // console.log(payout.params, chartsOfAccounts.find(method => method.value === payout.params.chartOfAccount));
    const paymentMethodValues = getConstant('bill', 'paymentMethod', {});
    const amountDue = (Number(bill?.params?.amount || 0) - Number(this.getTotalPaid()) || 0);
    const paymentMethod = [];

    if (bill && bill.populated.influencer && bill.populated.influencer.params.payment.billCom.isDefault) {
      paymentMethod.push({ value: 'billcom', label: paymentMethodValues.billcom });
    }
    if (bill && bill.populated.influencer && bill.populated.influencer.params.payment.stripe.isDefault) {
      paymentMethod.push({ value: 'stripe', label: paymentMethodValues.stripe });
    }
    paymentMethod.push({ value: 'check', label: paymentMethodValues.check });
    paymentMethod.push({ value: 'wire', label: paymentMethodValues.wire });
    const agentPaymentMethod = [];

    if (bill && bill.populated.agent && bill.populated.agent.params.payment.billCom.isDefault) {
      agentPaymentMethod.push({ value: 'billcom', label: paymentMethodValues.billcom });
    }
    if (bill && bill.populated.agent && bill.populated.agent.params.payment.stripe.isDefault) {
      agentPaymentMethod.push({ value: 'stripe', label: paymentMethodValues.stripe });
    }
    agentPaymentMethod.push({ value: 'check', label: paymentMethodValues.check });
    agentPaymentMethod.push({ value: 'wire', label: paymentMethodValues.wire });
    if (this.state.payout.params?.destination === 'agent' && !bill?.populated.agent && modal) {
      return (
        <Modal
          isOpen={modal}
          toggle={this.closeFormDialog}
          modalClassName="ltr-support"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn btn-sm" type="button" onClick={this.closeFormDialog} />
            <h4 className="text-modal  modal__title">{modalTitle}</h4>
          </div>
          <div className="modal__body finance-form ltr-support">
            <Alert color="danger" isOpen>
              <div className="alert__content">
                <p>Unable to find a valid payment method</p>
              </div>
            </Alert>
          </div>
        </Modal>);
    }

    return (
      <Modal
        isOpen={modal}
        toggle={this.closeFormDialog}
        // modalClassName="ltr-support"
      >
        {/* <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn btn-sm" type="button" onClick={this.closeFormDialog} />
          <h4 className="text-modal  modal__title">{modalTitle}</h4>
        </div> */}
        <ModalHeader
          toggle={this.closeFormDialog}
          tag="h4"
        >
          {modalTitle}
        </ModalHeader>
        <div className="finance-form ltr-support theme-light modal-body">
          {!this.state.showUntrustedSessionError && this.state.error ? (
            <Alert color="danger" isOpen>
              <div className="alert__content">
                <p>{this.state.error}</p>
              </div>
            </Alert>
            ) : null}
          {this.state.showUntrustedSessionError && (
            <Alert color="danger" isOpen>
              <div className="alert__content">
                <p>Untrusted Session. Please set your MFA Session <Link to="/billCom/dashboard" className="text-white font-weight-bold">here</Link></p>
              </div>
            </Alert>
          )}
          {this.state.insertionOrderChecked === false && (
            <Alert color="warning" isOpen>
              <div className="alert__content">
                <p>Failed to check for signed Insertion Order</p>
              </div>
            </Alert>
          )}
          {this.state.insertionOrderChecked && this.state.hasInsertionOrder && !this.state.hasSignedInsertionOrder && (
            <Alert color="warning" isOpen>
              <div className="alert__content">
                <p>{`Deal ${bill?.populated?.deal?.params?.dealname ? `${bill?.populated?.deal?.params?.dealname} ` : ''}is associated with an Unsigned Insertion Order`}</p>
              </div>
            </Alert>
          )}
          {this.state.insertionOrderChecked && !this.state.hasInsertionOrder && !this.state.hasSignedInsertionOrder && (
            <Alert color="warning" isOpen>
              <div className="alert__content">
                <p>{`Deal ${bill?.populated?.deal?.params?.dealname ? `${bill?.populated?.deal?.params?.dealname} ` : ''}is not currently associated with an Insertion Order`}</p>
              </div>
            </Alert>
          )}
          {this.state.loading.form &&
            (
              <div className="text-center mt-4"><Spinner color="primary" size="lg" /></div>
            )
          }
          {!this.state.loading.form &&
          (
            <form onSubmit={this.handleSubmit} className="mt-3">
              {!validation.isValid && (
                <Row>
                  <Col xs={3} className="text-center mt-2" style={{ color: '#70bbfd' }}> <i className="fa fa-exclamation-triangle" style={{ fontSize: '5em' }} /></Col>
                  <Col xs={9}>
                    <span><strong>Unable to send payment</strong></span>
                    <ul className="list-items-color-blue">
                      {validation.errors.length > 0 && validation.errors.map(error => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              )}
              {validation.isValid && (
                <>
                  {bill && bill.populated.agent && bill.populated.agent.id ? (
                    <Label check><b>Who would you like to pay? </b></Label>
                  ) : null}
                  <div style={{ padding: '10px', paddingBottom: '15px' }}>
                    {bill && bill.populated.agent && bill.populated.agent.id ? (
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            checked={payout.params.destination === 'creator'}
                            name="destination"
                            value="creator"
                            onChange={() => this.handleChange('destination', 'creator')}
                          /> Creator
                        </Label>
                      </FormGroup>
                    ) : null}
                    {bill && bill.populated.agent && bill.populated.agent.id ? (
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            checked={payout.params.destination === 'agent'}
                            name="destination"
                            value="agent"
                            onChange={() => this.handleChange('destination', 'agent')}
                          /> Agent
                        </Label>
                      </FormGroup>
                  ) : null
                  }
                  </div>
                  {payout.params?.destination === 'agent' ? (
                    <FormGroup>
                      <Label><b>Agent: &nbsp;</b> { bill?.populated?.agent?.params.first_name || ''} {bill?.populated?.agent?.params.last_name || ''}</Label>
                    </FormGroup>
                  ) : null}
                  <FormGroup>
                    <Label><b>Creator: &nbsp;</b> {bill?.populated?.influencer?.params.first_name || ''} {bill?.populated?.influencer?.params.last_name || ''}</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label><b>Brand: &nbsp;</b> {bill?.populated?.deal?.params?.dealTerms?.brand || ''}</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label><b>Payments enabled for Creator?: &nbsp;</b> &nbsp; {bill?.populated?.influencer?.params?.payment?.enablePayments ? 'Yes' : 'No' }</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label><b>Invoice No: &nbsp;</b> {bill && bill.params.invoiceNumber}</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label><b>Amount Due: &nbsp;</b> {formater.formatCurrency(amountDue)}</Label>
                  </FormGroup>
                  {payout.params?.destination === 'creator' ? (
                    <FormGroup>
                      <Label><b>Creator Payment Method &nbsp;</b></Label>
                      <Select
                        value={paymentMethod.find(method => method.value === payout.params.method)}
                        options={paymentMethod}
                        onChange={e => this.handleChange('method', e.value)}
                        placeholder="Payment Method"
                      />
                      {payout.errors.method && (<div className="form__form-group-error">{payout.errors.method}</div>)}
                    </FormGroup>
                  ) : null
                  }
                  {payout.params?.destination === 'agent' ? (
                    <FormGroup>
                      <Label><b>Agent Payment Method &nbsp;</b></Label>
                      <Select
                        value={agentPaymentMethod.find(method => method.value === payout.params.method)}
                        options={agentPaymentMethod}
                        onChange={e => this.handleChange('method', e.value)}
                        placeholder="Payment Method"
                      />
                      {payout.errors.method && (<div className="form__form-group-error">{payout.errors.method}</div>)}
                    </FormGroup>
                  ) : null
                  }
                  <FormGroup>
                    <Label><b>Payment Amount &nbsp;</b></Label>
                    <CurrencyField
                      placeholder="Payout Amount"
                      disabled={false}
                      defaultValue={payout.params.amount}
                      onBlur={this.handleChangeAmount}
                    />
                    {/* {payout.errors.amount && (<div className="form__form-group-error">{payout.errors.amount}</div>)} */}
                    {payout.errors.amount && (
                      <Alert color="warning" className="mt-2">
                        <p className="py-2 text-dark">
                          {(!payout.params.amount || payout.params.amount === 0) ?
                            'Amount to be paid must be greater than $0.00' :
                            payout.errors.amount
                          }
                        </p>
                      </Alert>
                    )}
                    {bill && bill.params.payments && bill.params.payments.length > 0 && (<span className="form__form-group-description">Max Amount: {formater.formatCurrency((bill.params.amount || 0) - (this.getTotalPaid() || 0))}</span>)}
                  </FormGroup>
                  {payout.params.method === 'billcom' ? (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label><b>Invoice Number &nbsp;</b></Label>
                          <Input
                            name="invoiceNumber"
                            value={payout.params.invoiceNumber || ''}
                            onChange={e => this.handleChange('invoiceNumber', e.target.value)}
                          />
                          {payout.errors.invoiceNumber && (<div className="form__form-group-error">{payout.errors.invoiceNumber}</div>)}
                          <span className="form__form-group-description">Maximum length cannot be greater than 21 characters.</span>
                        </FormGroup>
                      </Col>
                    </Row>) : null}
                  {payout.params.method === 'billcom' ? (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label><b>Process Date ({currentTimezoneAggr()}) &nbsp;</b></Label>
                          <DatePicker
                            onChange={e => this.handleChange('processDate', e.toString())}
                            selected={typeof payout.params.processDate === 'string' ? new Date(payout.params.processDate) : payout.params.processDate}
                            dateFormat="M/d/yyyy h:mmaa"
                            placeholderText="Process Date"
                            className="form-control"
                            minDate={new Date()}
                            filterDate={(date) => {
                              const day = date.getDay();
                              return day !== 0 && day !== 6;
                            }}
                            showTimeInput
                          />
                          {payout.errors.processDate && (<div className="form__form-group-error">{payout.errors.processDate}</div>)}
                          <span className="form__form-group-description">Select a bank business day in the calendar</span>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label><b>Due Date ({currentTimezoneAggr()}) &nbsp;</b></Label>
                          <DatePicker
                            onChange={e => this.handleChange('dueDate', e.toString())}
                            selected={typeof payout.params.dueDate === 'string' ? new Date(payout.params.dueDate) : payout.params.dueDate}
                            dateFormat="M/d/yyyy h:mmaa"
                            placeholderText="Due Date"
                            className="form-control"
                            minDate={new Date()}
                            showTimeInput
                          />
                          {payout.errors.dueDate && (<div className="form__form-group-error">{payout.errors.dueDate}</div>)}
                        </FormGroup>
                      </Col>
                    </Row>) : null}
                  {['billcom', 'stripe'].includes(payout.params.method) ? (
                    <FormGroup>
                      <Label>
                        {payout.params?.destination === 'agent' ?
                          ' Chart of Accounts for Agent: '
                          + ` ${bill?.populated?.agent?.params.first_name || ''} ${bill?.populated?.agent?.params.last_name || ''} `
                          + `${bill?.populated?.agent?.record?.params.payment.countryCode ? `(${bill?.populated?.agent?.record?.params.payment.countryCode || ''})` : ''}`
                          : ' Chart of Accounts for Creator: '
                          + ` ${bill?.populated?.influencer?.params.first_name || ''} ${bill?.populated?.influencer?.params.last_name || ''} `
                          + `${bill?.populated?.influencer?.record?.params.payment.countryCode ? `(${bill?.populated?.influencer?.record?.params.payment.countryCode || ''})` : ''}`
                        }
                      </Label>
                      <Select
                        value={chartsOfAccounts.find(method => method.value === (payout.params.chartOfAccount))}
                        options={chartsOfAccounts}
                        onChange={e => this.handleChange('chartOfAccount', e.value)}
                      />
                      {payout.errors.chartOfAccount && (<div className="form__form-group-error">{payout.errors.chartOfAccount}</div>)}
                    </FormGroup>) : null}
                  <FormGroup>
                    <Label><b>Notes &nbsp;</b></Label>
                    <Input
                      type="textarea"
                      id="notes"
                      name="notes"
                      placeholder="Notes"
                      onChange={e => this.handleChange('notes', e.target.value)}
                      value={typeof payout.params !== 'undefined' && typeof payout.params.notes !== 'undefined' ? payout.params.notes : ''}
                    />
                  </FormGroup>
                  {payout.params.method ? (
                    <FormGroup>
                      <CheckBoxField
                        name="sendPayment"
                        label="Send Payment Email?"
                        value={this.state.sendEmail}
                        onChange={event => event.target && this.setState({ sendEmail: event.target.checked })}
                      />
                    </FormGroup>
                  ) : null}
                </>
              )}
            </form>
          )
          }
        </div>
        {!this.state.loading.form && (
          <ButtonToolbar className="modal__footer">
            {validation.isValid && (
              <Button
                className="modal_ok btn-sm mr-2"
                color="primary"
                onClick={e => this.handleSubmit(e)}
                disabled={this.state.loading.form || this.state.payout.disable}
              >
                {this.state.loading.form && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                <span>Send</span>
              </Button>
            )}
            <Button className="modal_cancel btn-sm" color="secondary" onClick={this.closeFormDialog}>{validation.isValid ? 'Cancel' : 'Close'}</Button>
          </ButtonToolbar>)}
      </Modal>
    );
  }
}

BillPaymentForm.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  bill: PropTypes.objectOf(PropTypes.any).isRequired,
  stripe: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

BillPaymentForm.defaultProps = {};

const mapStateToProps = state => ({
  stripe: state.accountPayable.stripe,
});

export default withNotice(connect(mapStateToProps)(BillPaymentForm));
