import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
// import classnames from 'classnames';
// import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import Select, { components } from 'react-select';
import SelectAsync from 'react-select/async';
// import PropTypes from 'prop-types';
// import DatePicker from 'react-datepicker';
import ApiClient from 'ApiClient';
import { Row, Col, Button, Container, Card, CardBody, CardTitle, Label, FormGroup /* TabContent, TabPane, Nav, NavLink, NavItem */ } from 'reactstrap';
import PropTypes from 'prop-types';
import Filter from '../FilterPrevious';
// import LastMonthFilter from '../LastMonthFilter';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import MatTableForRedux from '../../../../shared/tables/materialTable/components/MatTableForRedux';
import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import formater from '../../../../shared/helpers/WVFormatter';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
import TableCellContextMenu from '../../../../shared/components/table/TableCellContextMenu';
import CheckBoxField from '../../../../shared/components/CheckBox';
import { getConstant } from '../../../../shared/helpers/WVConstants';
import GenerationInfo from '../GenerationInfoV2';
import Modal, { modalId } from './Modal';
import { showModalById } from '../../../../redux/reducers/TableReducer';
import Errors from '../ErrorsV2';
import ModalPublish, { modalId as publishModalId } from './ModalPublish';
// import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
// import CheckBoxField from '../../../../shared/components/CheckBox';
// import Collapse from '../../../../shared/components/Collapse';
// import HubspotDealLink from '../../../../shared/components/HubspotDealLink';

const dealTypes = {
  hybrid: 'Hybrid',
  cpa: 'CPA/Ambassador',
  upfront: 'Upfront Only',
  upfrontCPM: 'Upfront CPM',
  agentCommission: 'Agent Commission',
  mediaLicense: 'Media License',
  bonus: 'Bonus',
};
/* const getLatestPayment = function (payments) {
  const sortedpayments = [...payments].sort((a, b) => (new Date(a.date)) - (new Date(b.date)));
  return sortedpayments[sortedpayments.length - 1];
}; */
const getTotalPayment = (payments) => {
  let total = 0;
  payments.forEach(({ amount }) => { total += amount; });
  return total || 0;
};
const getAmountByConversion = (deal, payout) => {
  // if ([dealTypes.upfront, dealTypes.upfrontCPM].includes(deal.dealTerms.type_of_deal)) {
  //   return payout;
  // }
  if ([dealTypes.bonus].includes(deal.dealTerms.type_of_deal)) {
    return 0;
  }
  if (deal.dealTerms.cpa_percentage === 0) console.log(deal);
  if (deal.dealTerms.cpa_percentage || deal.dealTerms.cpa_percentage === 0) {
    return Number(payout || 0) * (Number(deal.dealTerms.cpa_percentage || 0) / 100);
  } else if (deal.dealTerms.per_conversion_amount || deal.dealTerms.per_conversion_amoun === 0) {
    return Number(deal.dealTerms.per_conversion_amount);
  }
  if ([dealTypes.cpa].includes(deal.dealTerms.type_of_deal)) {
    return 0;
  }
  return Number(payout || 0);
};
const calculateBillRevenue = ({ deal, startDate, endDate }) => {
  let totalRevenue = 0;
  // eslint-disable-next-line import/no-dynamic-require, global-require
  // const moment = global?.MODULES ? require(`${global?.MODULES || ''}moment-timezone`) : require('moment-timezone');
  const closeDate = deal.brandApprovedDate ? moment.tz(moment(deal.brandApprovedDate).format('YYYY-MM-DD'), 'America/New_York') : null;
  if ([dealTypes.upfrontCPM].includes(deal.dealTerms.type_of_deal) && closeDate && closeDate >= moment(startDate) && closeDate < moment(endDate).endOf('month')) {
    totalRevenue += (Number(deal.dealTerms?.bh_cpm_total_cost || 0));
  }
  return totalRevenue;
};
const calculateTotalRevenue = ({
  deal, report, startDate, endDate,
}) => {
  let totalRevenue = Number(report?.revenue);
  totalRevenue += calculateBillRevenue({ deal, startDate, endDate });
  return Number(totalRevenue || 0);
};
/* const calculateTotalRevenue = ({ deal, report }) => {
  let totalRevenue = Number(report?.revenue);
  totalRevenue += (Number(deal.billrevenue || 0));


  return Number(totalRevenue || 0);
}; */
const calculateConversionsCosts = ({ deal, conversions, invoicePayout }) => {
  let totalPayout = 0;
  conversions.forEach(({ payout }) => {
    totalPayout += getAmountByConversion(deal, payout);
  });

  if ([dealTypes.hybrid].includes(deal.dealTerms.type_of_deal) && deal.dealTerms.guaranteed_payment_recoverable === 'Yes') {
    totalPayout = invoicePayout;
  }
  // if ([dealTypes.upfront, dealTypes.upfrontCPM, dealTypes.bonus].includes(deal.dealTerms.type_of_deal)) {
  //   totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  // }
  return totalPayout;
};

const calculateBillCosts = ({ deal, startDate, endDate }) => {
  // const aValues = getConstant('deal', 'published', []);
  // const paidValues = getConstant('deal', 'paid', []);
  // const isPaid = !aValues.includes(deal.dealstage.toString()) || !paidValues.includes(deal.dealstage.toString());

  const closeDate = deal.brandApprovedDate ? moment.tz(moment(deal.brandApprovedDate).format('YYYY-MM-DD'), 'America/New_York') : null;
  let totalPayout = 0;
  if ([dealTypes.upfront, dealTypes.upfrontCPM].includes(deal.dealTerms.type_of_deal) && closeDate && closeDate >= moment(startDate) && closeDate < moment(endDate).endOf('month')) {
    totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  }
  const closeDateBonus = deal.dealTerms.content_release_date ? moment.tz(moment(deal.dealTerms.content_release_date).format('YYYY-MM-DD'), 'America/New_York') : null;

  if ([dealTypes.bonus].includes(deal.dealTerms.type_of_deal) && closeDateBonus && closeDateBonus >= moment(startDate) && closeDateBonus < moment(endDate).endOf('month')) {
    totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  }

  /* console.log([
    [dealTypes.hybrid].includes(deal.dealTerms.type_of_deal),
    closeDate >= moment(startDate),
    closeDate < moment(endDate),
    closeDate,
  ]); */
  if ([dealTypes.hybrid].includes(deal.dealTerms.type_of_deal) && closeDate && closeDate >= moment(startDate) && closeDate < moment(endDate).endOf('month')) {
    totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  }
  return totalPayout;
};


const formatCreatorOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div>{record?.params.first_name} {record?.params.last_name}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record?.params.email}
    </div>
  </div>
);

const creatorOption = (props) => {
  const { record } = props.data;
  return (
    <components.Option {...props} >
      <div>
        <div className="bold-text">{record.params.first_name} {record.params.last_name}</div>
        <div >{record.params['affiliateDataObject.company']}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record.params.email}
        </div>
      </div>
    </components.Option>
  );
};

const SummaryReport = ({ addNotice, dispatch }) => {
  const dealPipelines = getConstant('deal', 'pipelines', []);
  const dealStages = [];
  dealPipelines.forEach((pipeline) => {
    Object.keys(pipeline.stages).forEach((stageKey) => {
      dealStages.push({ id: stageKey, label: pipeline.stages[stageKey] });
    });
  });
  const [loading, setLoaing] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loadingAdvertiser, setLoaingAdvertiser] = useState(false);
  const [items, setItems] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [offers, setOffers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [ignoreDealstages, setIgnoreDealstages] = useState(true);
  const [ignoreDealstagesFull, setIgnoreDealstagesFull] = useState(false);
  const [ignoreBAD, setIgnoreBAD] = useState(false);
  const [ignoreStatus, setIgnoreStatus] = useState(false);
  const [dealTypeFilters, setDealTypeFilters] = useState({
    'CPA/Ambassador': true,
    Hybrid: true,
    'Upfront Only': true,
    'Upfront CPM': true,
    'Media License': true,
    Bonus: true,
  });
  const [selectedRes, setSelectedRes] = useState({
    all: false,
    notPilot: false,
    pilot: false,
  });
  // const [activeTab, setActiveTab] = useState('1');
  // const getIssueMessage = (item) => {
    // if (!item.deal?.dealstage) return 'No Dealstage';
    // if (!aValues.includes(item.deal.dealstage.toString()) && !paidValues.includes(item.deal.dealstage.toString())) return 'Invalid Dealstage';
    // return null;
  // };
  const handleChangeSelectedBrands = (event, name) => {
    console.log('event Change selected');
    if (event && event.target) {
      const { checked } = event.target;
      setSelectedRes({
        ...dealTypeFilters,
        [name]: checked,
      });
    }
  };
  const handleChangeDealTypeFilters = (event, name) => {
    console.log('event change');
    if (event && event.target) {
      const { checked } = event.target;
      setDealTypeFilters({
        ...dealTypeFilters,
        [name]: checked,
      });
    }
  };

  useEffect(() => {
    let optionsItems = [];
    console.log(advertisers);
    if (selectedRes.notPilot === true) {
      optionsItems = advertisers.filter(a => a.isPilot === 'No').map(item => ({ value: item.tuneId, label: item.companyName }));
    }
    if (selectedRes.pilot === true) {
      optionsItems = optionsItems.concat(advertisers.filter(a => a.isPilot === 'Yes').map(item => ({ value: item.tuneId, label: item.companyName })));
    }
    console.log(optionsItems);

    setSelectedAdvertisers(optionsItems);
  }, [selectedRes]);
  // const [error, setError] = useState(null);
  // const [version, setVersion] = useState((new Date()).toString());
  // const includedLastMonth = moment(startDate).toDate() < moment().startOf('month').toDate() && moment(endDate).toDate() > moment().subtract(1, 'month').startOf('month').toDate();
  useEffect(() => {
    const api = new ApiClient();
    setLoaingAdvertiser(true);
    api.client.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      setOffers(resp.data.offers);
      const sortedBrands = Object.values(brands);
      sortedBrands.sort((a, b) => {
        if (a.companyName < b.companyName) return -1;
        if (a.companyName > b.companyName) return 1;
        return 0;
      });
      setAdvertisers(sortedBrands);
      setLoaingAdvertiser(false);
    }).catch((err) => {
      setLoaingAdvertiser(false);
      console.error(err);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
    api.client.get('/api/portal/finance/get-employees').then((resp) => {
      setEmployees(resp.data.employees);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: p => `${p?.offer.advertiser.companyName || ''}`,
        id: 'brand',
        Cell: p => useMemo(() => (
          <div style={{ width: '120px' }}>
            {p.value}
          </div>
        ), [p.row.original]),
      },
      {
        Header: 'Offer',
        accessor: p => `${p?.offer?.name || ''}`,
        id: 'offer',
        Cell: p => useMemo(() => (
          <div style={{ width: '120px' }}>
            {p.value}
          </div>
        ), [p.row.original]),
      },
      {
        Header: 'Deal Type',
        accessor: deal => deal.deal.dealTerms.type_of_deal,
        id: 'deal_type',
        Cell: p => (
          <div style={{ width: '120px' }}>
            {p.value}
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: p => p.deal.dealTerms.cpa_status,
        id: 'cpa_status',
        Cell: p => useMemo(() => (
          <div style={{ width: '100px' }}>
            {p.value}
          </div>
        ), [p.value]),
      },
      {
        Header: 'Deal Stage',
        accessor: p => p.deal.dealstage,
        id: 'dealstage',
        Cell: p => useMemo(() => {
          // console.log(p);
          let stage = { id: null, label: '-' };
          if (p.value) {
            stage = dealStages.find(item => item.id === p.value);
          }
          return (
            <div style={{ width: '100px' }}>
              {stage.label}  {p.row.original.deal.additionDeal ? ' (Possible Deal?)' : ''}
            </div>
          );
        }, [p.value]),
      },
      {
        Header: 'Creator',
        accessor: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
        id: 'Creator',
        Cell: p => useMemo(() => (
          <div style={{ width: '120px' }}>
            <Tooltip title="Open Creator Profile">
              <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.influencer?._id}`}>{p.value}</a>
            </Tooltip>
          </div>), [p.row.original]),
      },
      {
        Header: 'Deal Owner',
        accessor: p => `${p?.admin?.firstName || ''} ${p?.admin?.lastName || ''}`,
        id: 'manager',
        Cell: p => useMemo(() => p.value, [p.row.original]),
      },
      {
        Header: 'Brand Approved Date',
        accessor: (deal) => {
          if ([dealTypes.bonus].includes(deal.deal.dealTerms.type_of_deal)) return deal.deal.dealTerms.content_release_date;
          return deal.deal.brandApprovedDate;
        },
        id: 'brandApprovedDate',
        Cell: p => useMemo(() => (
          <div style={{ width: '180px' }}>
            {p.value ? moment(p.value.split('T')[0]).format('MM/DD/YY') : '-'}
          </div>
        ), [p.value]),
      },
      {
        Header: 'Revenue',
        accessor: deal => Number(deal.totalRevenue || 0),
        id: 'revenue',
        Cell: ({ value }) => useMemo(() => (
          <div style={{ width: '100px' }}>
            {formater.formatCurrency(value || 0)}
          </div>), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Costs',
        accessor: deal => (deal.totalPayoutWithMulligan),
        id: 'costs',
        Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => (info.rows.reduce((sum, row) => Number(row.values.costs || 0) + sum, 0)),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Total Mulligan Amount',
        accessor: (deal) => {
          const a = ((deal.totalPayout || 0) - deal.totalPayoutWithMulligan);
          return a;
        },
        id: 'total_mulligan_amount',
        Cell: ({ value }) => useMemo(() => (
          <div style={{ width: '180px' }}>
            {formater.formatCurrency(value || 0)}
          </div>), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.total_mulligan_amount || 0) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: 'Profit',
        accessor: deal => Number(deal.grossProfit || 0),
        id: 'profit',
        Cell: ({ value }) => useMemo(() => (
          <div style={{ width: '80px' }}>
            {formater.formatCurrency(value || 0)}
          </div>), [value]),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.profit) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatCurrency(totalAge)}</span>;
        },
      },
      {
        Header: '',
        accessor: '_id',
        // eslint-disable-next-line react/prop-types
        Cell: p => (
          <div style={{ textAlign: 'right' }}><TableCellContextMenu
            options={[
              { label: 'Display Calculation', handler: () => { dispatch(showModalById(modalId, { record: p.row.original })); } },
            ]}
          />
          </div>),
        disableGlobalFilter: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    [items],
  );

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    useFlex: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'grossProfit',
    direction: 'desc',
  };

  const fetchAmountReport = async () => {
    setLoaing(true);
    // setError(null);
    setItems([]);
    // setVersion((new Date()).toString());
    try {
      const resp = await axios.post('/api/portal/reports/post-fetch-all-deals-by-date-eom', {
        startDate,
        endDate,
        advertisers: selectedAdvertisers?.map(a => a.value),
        offers: selectedOffer?.map(o => o.value),
        creators: selectedInfluencers?.map(i => i.value), // internal _id
        employees: selectedEmployee?.map(i => i.value),
        identity,
        ignoreDealstages,
        ignoreBAD,
        ignoreStatus,
        ignoreDealstagesFull,
      });
      console.log(resp.data.items);
      if (resp.data.success) {
        setLoaing(false);
        setErrors(resp.data.notUsedConversions);
        // const influencers = {};
        const deals = {};

        Object.values(resp.data.items).forEach((item) => {
          Object.values(item).forEach(({
            deal,
            conversionsArray,
            report,
            offer,
            influencer,
            admin,
            invoicePayment,
          }) => {
            if (!deals[deal._id]) {
              deals[deal._id] = {
                deal,
                conversionsArray,
                report: {
                  revenue: Number(report?.Stat?.revenue || 0),
                  payout: Number(report?.Stat?.payout || 0),
                },
                offer,
                influencer,
                admin,
                invoicePayout: 0,
              };
            } else {
              deals[deal._id].conversionsArray = deals[deal._id].conversionsArray.concat(conversionsArray);
              deals[deal._id].report.revenue += Number(report?.Stat?.revenue || 0);
              deals[deal._id].report.payout += Number(report?.Stat?.payout || 0);
            }
            if (deal.invoice) {
              const totalPaidForInvoice = getTotalPayment(deal.invoice?.payments || []);
              const diff = Number(deal.dealTerms.guaranteed_payment_contract_amount || 0) - (totalPaidForInvoice || 0);
              console.log(diff);
              if (invoicePayment) {
                // console.log([deals[deal._id].report.payout, Number(invoicePayment?.amount || 0), (deals[deal._id].report.payout - Number(invoicePayment?.amount || 0))]);
                deals[deal._id].invoicePayout += deals[deal._id].report.payout > Number(invoicePayment?.amount || 0) ? (deals[deal._id].report.payout - Number(invoicePayment?.amount || 0)) : 0;
              } else {
                deals[deal._id].invoicePayout += diff >= deals[deal._id].report.payout ? 0 : deals[deal._id].report.payout - diff;
              }
            }
          });
        });
        Object.values(deals).forEach(({
          deal,
          conversionsArray,
          report,
          invoicePayout,
        }) => {
          deals[deal._id].conversionPayout = calculateConversionsCosts({ deal, conversions: conversionsArray || [], invoicePayout });
          deals[deal._id].billPayout = calculateBillCosts({ deal, startDate, endDate });
          deals[deal._id].totalRevenue = calculateTotalRevenue({
            deal, report, startDate, endDate,
          });
          deals[deal._id].totalPayout = deals[deal._id].conversionPayout + deals[deal._id].billPayout;
          deals[deal._id].totalPayoutWithMulligan = deal.dealTerms.mulligan_ && deals[deal._id].totalRevenue < deals[deal._id].totalPayout ? 0 : deals[deal._id].conversionPayout + deals[deal._id].billPayout;
          deals[deal._id].grossProfit = deals[deal._id].totalRevenue - deals[deal._id].totalPayoutWithMulligan;


          // deals[deal._id].totalPayout = deals[deal._id].conversionPayout + deals[deal._id].billPayout;
          // deals[deal._id].grossProfit = (deal.dealTerms.mulligan_ ? deals[deal._id].totalRevenue : deals[deal._id].totalRevenue - deals[deal._id].conversionPayout - deals[deal._id].billPayout);
          deals[deal._id].message = null; // getIssueMessage({ deal });
          // deals[deal._id].invoicePayout = invoicePayout;
        });
        console.log(deals);
        setItems(Object.values(deals));
        // setDeals(resp.data.items);
        // setVersion((new Date()).toString());
      } else {
        throw new Error(resp.data.error);
        // setError(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      setLoaing(false);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };
  const selectItem = (value) => {
    setSelectedAdvertisers(value);
    // setVersion((new Date()).toString());
  };

  const loadCreatorsOptions = async (value) => {
    const response = await axios.get('/api/portal/influencer/get-creator-search', {
      params: {
        value,
      },
    });
    return response.data.records.map(record => ({
      value: record.params._id,
      label: record.params.email,
      record,
    }));
  };

  const options = useMemo(() => {
    const i = advertisers.map(item => ({ value: item.tuneId, label: item.companyName }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [advertisers]);
  const employeeOptions = useMemo(() => {
    const i = employees.map(item => ({ value: item.tuneId, label: `${item.firstName || ''} ${item.lastName || ''}` }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [employees]);
  console.log(selectedAdvertisers, offers);

  const optionsOffer = useMemo(() => {
    const returnValues = [];
    if (selectedAdvertisers) {
      selectedAdvertisers.forEach((sa) => {
        const i = offers
          .filter(o => (selectedAdvertisers && selectedAdvertisers.length === 0) || Number(o?.advertiserTuneid || 0) === Number(sa.value || 0))
          .map(item => ({ value: item.tuneId, label: item.name }));
        returnValues.push({ label: sa.label, options: i });
      });
    }
    // returnValues.unshift({ value: null, label: ' -- All -- ' });
    return returnValues;
  }, [offers, selectedAdvertisers]);
  const filteredItems = useMemo(() => { // filter items
    const activeDealTypes = Object.keys(dealTypeFilters).filter(k => dealTypeFilters[k]).map(k => k);
    return items.filter(item => activeDealTypes.includes(item?.deal.dealTerms.type_of_deal) && !item.message);
  }, [items, dealTypeFilters]);

  const getTotalRevenue = () => {
    let total = 0;
    filteredItems.forEach((i) => {
      total += i.totalRevenue;
    });
    return total;
  };
  const getTotalProfit = () => {
    let total = 0;
    filteredItems.forEach((i) => {
      total += i.grossProfit;
    });
    return total;
  };

  /* const filteredItemsErrors = useMemo(() => { // filter items
    const activeDealTypes = Object.keys(dealTypeFilters).filter(k => dealTypeFilters[k]).map(k => k);
    return items.filter(item => item.message && activeDealTypes.includes(item?.dealType));
  }, [items, dealTypeFilters]); */
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Finance EOM Revenue & Costs', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Finance EOM Revenue & Costs</h3>
              </CardTitle>
              <Row className="mt-3">
                <Col>
                  <Filter
                    setValue={(param, value) => {
                      console.log(value);
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('month').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('month').format('YYYY-MM-DD'));
                      }
                    }}
                  />
                </Col>
                <Col lg="auto">
                  <div className="mt-0">
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchAmountReport();
                      }}
                      className="btn-sm"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                    <Button
                      color="primary"
                      onClick={async () => {
                        dispatch(showModalById(publishModalId, {
                          filters: {
                            startDate,
                            endDate,
                            identity,
                          },
                        }));
                      }}
                      className="btn-sm"
                      disabled={!!loading}
                    >
                      Publish Report
                    </Button>
                    <GenerationInfo />
                  </div>
                </Col>
                {items.length > 0 ? (
                  <Col lg="auto">
                    <div className="float-right mr-2 mt-0">
                      <DownloadCSVButton
                        records={filteredItems}
                        className="mt-0"
                        fileName="revenue_costs_eom.csv"
                        csvArrayColumns={[
                          {
                            label: 'Brand',
                            func: p => `${p?.offer?.advertiser?.companyName || ''}`,
                          },
                          {
                            label: 'Offer',
                            func: p => `${p?.offer?.name || ''}`,
                          },
                          {
                            label: 'Deal Type',
                            func: p => `${p?.deal?.dealTerms?.type_of_deal || ''}`,
                          },
                          {
                            label: 'Status',
                            func: p => `${p?.deal.dealTerms.cpa_status || ''}`,
                          },
                          {
                            label: 'Deal Stage',
                            func: (p) => {
                              let stage = { id: null, label: '-' };
                              if (p.deal.dealstage) {
                                stage = dealStages.find(item => item.id === p.deal.dealstage);
                              }
                              return stage.label;
                              // `${p?.deal.dealTerms.cpa_status || ''}`
                            },
                          },
                          {
                            label: 'Creator',
                            func: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
                          },
                          {
                            label: 'Deal Owner',
                            func: p => `${p?.admin?.firstName || ''} ${p?.admin?.lastName || ''}`,
                          },
                          {
                            label: 'Brand Approved Date',
                            func: p => `${p?.deal?.brandApprovedDate ? moment(p.deal?.brandApprovedDate.split('T')[0]).format('MM/DD/YY') : '-'}`,
                          },
                          {
                            label: 'Revenue',
                            func: deal => (deal.totalRevenue || 0),
                          },
                          {
                            label: 'Costs',
                            func: deal => (deal.totalPayoutWithMulligan || 0),
                          },
                          {
                            label: 'Total Mulligan Amount',
                            func: (deal) => {
                              const a = ((deal.totalPayout || 0) - deal.totalPayoutWithMulligan);
                              return a || 0;
                            },
                          },
                          {
                            label: 'Profit',
                            func: deal => (deal.grossProfit || 0),
                          },
                          {
                            label: 'Is Deal Addition',
                            func: deal => (deal.deal.additionDeal ? 'Yes' : 'No'),
                          },
                      ]}
                      >
                        Download CSV
                      </DownloadCSVButton>
                    </div>
                  </Col>
                ) : null}
              </Row>
              <Row className="mt-3" >
                <Col>
                  <Select
                    isDisabled={loadingAdvertiser}
                    value={selectedAdvertisers}
                    options={options}
                    onChange={selectItem}
                    placeholder="Select Advertiser..."
                    isClearable
                    isMulti
                  />
                  <Row className="mt-3">
                    <Col>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="all"
                          label="Select All Brands (Include All Brands)"
                          value={selectedRes.all}
                          onChange={event => handleChangeSelectedBrands(event, 'all')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="notPilot"
                          label="Select All Brands, No Pilot Brands"
                          value={selectedRes.notPilot}
                          onChange={event => handleChangeSelectedBrands(event, 'notPilot')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="pilot"
                          label="Select Pilot Brands Only"
                          value={selectedRes.pilot}
                          onChange={event => handleChangeSelectedBrands(event, 'pilot')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Select
                    isDisabled={loadingAdvertiser}
                    value={selectedOffer}
                    options={optionsOffer}
                    onChange={setSelectedOffer}
                    placeholder="Select Offer..."
                    isClearable
                    isMulti
                  />
                </Col>
                <Col>
                  <SelectAsync
                    cacheOptions
                    isMulti
                    loadOptions={async value => loadCreatorsOptions(value)}
                    onChange={setSelectedInfluencers}
                    placeholder="Search Influencer..."
                    formatOptionLabel={formatCreatorOptionLabel}
                    components={{ Option: creatorOption }}
                  />
                </Col>
                <Col>
                  <Select
                    value={selectedEmployee}
                    options={employeeOptions}
                    onChange={setSelectedEmployee}
                    placeholder="Select Employee..."
                    isMulti
                  />
                </Col>
                <Col>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Deal Type
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="CPA"
                      label="CPA/Ambassador"
                      value={dealTypeFilters['CPA/Ambassador']}
                      onChange={event => handleChangeDealTypeFilters(event, 'CPA/Ambassador')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Hybrid"
                      label="Hybrid"
                      value={dealTypeFilters.Hybrid}
                      onChange={event => handleChangeDealTypeFilters(event, 'Hybrid')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="UpfrontOnly"
                      label="Upfront Only"
                      value={dealTypeFilters['Upfront Only']}
                      onChange={event => handleChangeDealTypeFilters(event, 'Upfront Only')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="UpfrontCPM"
                      label="Upfront CPM"
                      value={dealTypeFilters['Upfront CPM']}
                      onChange={event => handleChangeDealTypeFilters(event, 'Upfront CPM')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="MediaLicense"
                      label="Media License"
                      value={dealTypeFilters['Media License']}
                      onChange={event => handleChangeDealTypeFilters(event, 'Media License')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="Bonus"
                      label="Bonus"
                      value={dealTypeFilters.Bonus}
                      onChange={event => handleChangeDealTypeFilters(event, 'Bonus')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="theme-light radio-in-prospects">
                    <FormGroup className="mb-0 color-text" inline>
                      <CheckBoxField
                        name="ignorewarning0"
                        label="Ignore Deal Stages for all Upfront Deal types"
                        value={ignoreDealstages}
                        onChange={({ target }) => target && (setIgnoreDealstages(target.checked))}
                      />
                    </FormGroup>
                  </div>
                  <Label>Match Dropdown Conversions?</Label>
                  <div className="theme-light radio-in-prospects">
                    <FormGroup className="mb-0 color-text" inline>
                      <CheckBoxField
                        name="ignorewarning1"
                        label="Brand Approved Date is IGNORED"
                        value={ignoreBAD}
                        onChange={({ target }) => target && (setIgnoreBAD(target.checked))}
                      />
                    </FormGroup>
                  </div>
                  <div className="theme-light radio-in-prospects">
                    <FormGroup className="mb-0 color-text" inline>
                      <CheckBoxField
                        name="ignorewarning2"
                        label="Deal Status is IGNORED "
                        value={ignoreStatus}
                        onChange={({ target }) => target && (setIgnoreStatus(target.checked))}
                      />
                    </FormGroup>
                  </div>
                  <div className="theme-light radio-in-prospects">
                    <FormGroup className="mb-0 color-text" inline>
                      <CheckBoxField
                        name="ignorewarning3"
                        label="Deal Stage != Deal Paid OR Published"
                        value={ignoreDealstagesFull}
                        onChange={({ target }) => target && (setIgnoreDealstagesFull(target.checked))}
                      />
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProgressBar
                    afterFunction={() => true}
                    topic="report"
                    identity={identity}
                    isShow={!!loading}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!loading && items.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <h5>Gross Revenue</h5>
                {!loading && items.length > 0 ? (
                  <h3>{formater.formatCurrency(getTotalRevenue())}</h3>
                ) : null}
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h5>Gross Profit</h5>
                    {!loading && items.length > 0 ? (
                      <h3> {formater.formatCurrency(getTotalProfit())}</h3>
                    ) : null}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null }
      {!loading && items.length > 0 && errors.length > 0 ? (
        <Errors errors={errors} startDate={startDate} endDate={endDate} />
      ) : null }
      {!loading && items.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="mt-3">
                  <Col>
                    {!loading && items.length > 0 ? (
                      <ReactTableBase
                        key="searchable"
                        columns={columns}
                        data={filteredItems}
                        tableConfig={tableConfig}
                      />
                      ) : null
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>) : null
      }
      <Modal />
      <ModalPublish />
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};


export default connect()(withNotice(SummaryReport));
