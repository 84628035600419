/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import FormatListBulleted from 'mdi-react/FormatListBulletedIcon';

export default class DealsInList extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { record } = this.props;

    let length = 0;
    if (record && record.populated) {
      while (record.populated[`deals.${length}`]) {
        length += 1;
      }
    }
    const numberToShow = length;

    let linkTo = null;
    if (numberToShow) {
      try {
        linkTo = `/resources/HubilDeal/actions/list?filters.fullSearchName=${encodeURIComponent(record.populated.promoCode.params.code)}`;
      } catch (e) {
        linkTo = null;
      }
    }

    return (
      <div>
        <span style={{ verticalAlign: 'middle' }}>{ numberToShow }</span>
        {linkTo ? (
          <Link target="_blank" to={linkTo}><IconButton className="material-table__toolbar-button" size="small"><FormatListBulleted size="20" /></IconButton></Link>
          ) : null}
        {!linkTo ? (
          <IconButton disabled className="material-table__toolbar-button" size="small"><FormatListBulleted size="20" /></IconButton>
          ) : null}
      </div>
    );
  }
}
