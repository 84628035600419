/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { Modal, ButtonToolbar, Button, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { currentTimezoneAggr } from '../../../../../../../shared/helpers/Dates';
import { setShowUrlStatusHistoryDialog } from '../../../../../../../redux/reducers/admin/ScoringReducer';

const UrlStatusHistoryModal = ({
  showHistoryUrlStatusDialog,
  mediaContentObj,
  dispatch,
}) => (
  <Modal
    isOpen={showHistoryUrlStatusDialog}
    toggle={() => dispatch(setShowUrlStatusHistoryDialog(false))}
  >
    <ModalHeader
      toggle={() => dispatch(setShowUrlStatusHistoryDialog(false))}
      tag="h3"
      className="modal-title-statistics"
    >
      URL Status History
    </ModalHeader>
    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      {mediaContentObj?.params?.history?.urlStatus?.length > 0 ? (
        <div className="mt-1 mb-4">
          <h4 className="font-weight-bold">URL Status Change History</h4>
          <hr className="mt-1" />
          {mediaContentObj.params.history.urlStatus.map((item) => {
            const previousName = item.prevStatus || null;
            const currentName = item.newStatus || null;
            return (
              <div key={moment(item.dateModified).toString() + moment.now().toString()}>
                <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                <div className="pl-3">
                  <a href={item.adminUser ? `/resources/AdminUser/records/${item.adminUser}/show` : '#'} target="_blank" rel="noreferrer">
                    {item.adminUserName}
                  </a> - <b>{previousName}</b> changed to <b>{currentName}</b>
                </div>
              </div>);
          })}
        </div>
      ) : null}
    </div>
    <ButtonToolbar className="modal__footer">
      <Button className="modal_cancel btn-sm" color="secondary" onClick={() => dispatch(setShowUrlStatusHistoryDialog(false))}>Close</Button>
    </ButtonToolbar>
  </Modal>
);

UrlStatusHistoryModal.propTypes = {
  showHistoryUrlStatusDialog: PropTypes.bool.isRequired,
  mediaContentObj: PropTypes.objectOf(PropTypes.any),
  dispatch: PropTypes.func.isRequired,
};

UrlStatusHistoryModal.defaultProps = {
  mediaContentObj: {},
};

const mapStateToProps = state => ({
  showHistoryUrlStatusDialog: state.score.showHistoryUrlStatusDialog,
  mediaContentObj: state.score.mediaContent,
});

export default connect(mapStateToProps)(UrlStatusHistoryModal);
