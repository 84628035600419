
const dealTypes = {
  hybrid: 'Hybrid',
  cpa: 'CPA/Ambassador',
  upfront: 'Upfront Only',
  upfrontCPM: 'Upfront CPM',
  agentCommission: 'Agent Commission',
  mediaLicense: 'Media License',
  bonus: 'Bonus',
};
/* const getLatestPayment = function (payments) {
  const sortedpayments = [...payments].sort((a, b) => (new Date(a.date)) - (new Date(b.date)));
  return sortedpayments[sortedpayments.length - 1];
}; */
const getTotalPayment = (payments) => {
  let total = 0;
  payments.forEach(({ amount }) => { total += amount; });
  return total || 0;
};
const getAmountByConversion = (deal, payout) => {
  // if ([dealTypes.upfront, dealTypes.upfrontCPM].includes(deal.dealTerms.type_of_deal)) {
  //   return payout;
  // }
  if ([dealTypes.bonus].includes(deal.dealTerms.type_of_deal)) {
    return 0;
  }
  // if (deal.dealTerms.cpa_percentage === 0) console.log(deal);
  if (deal.dealTerms.cpa_percentage || deal.dealTerms.cpa_percentage === 0) {
    return Number(payout || 0) * (Number(deal.dealTerms.cpa_percentage || 0) / 100);
  } else if (deal.dealTerms.per_conversion_amount || deal.dealTerms.per_conversion_amoun === 0) {
    return Number(deal.dealTerms.per_conversion_amount);
  }
  if ([dealTypes.cpa].includes(deal.dealTerms.type_of_deal)) {
    return 0;
  }
  return Number(payout || 0);
};
const calculateBillRevenue = ({ deal, startDate, endDate }) => {
  let totalRevenue = 0;
  // eslint-disable-next-line import/no-dynamic-require, global-require
  const moment = global?.MODULES ? require(`${global?.MODULES || ''}moment-timezone`) : require('moment-timezone');
  const closeDate = deal.brandApprovedDate ? moment.tz(moment.utc(deal.brandApprovedDate).format('YYYY-MM-DD'), 'America/New_York') : null;
  if ([dealTypes.upfrontCPM].includes(deal.dealTerms.type_of_deal) && closeDate && closeDate >= moment.tz(startDate, 'America/New_York') && closeDate < moment.tz(endDate, 'America/New_York').endOf('month')) {
    totalRevenue += (Number(deal.dealTerms?.bh_cpm_total_cost || 0));
  }
  return totalRevenue;
};
const calculateTotalRevenue = ({
  deal, report, startDate, endDate,
}) => {
  let totalRevenue = Number(report?.revenue);
  totalRevenue += calculateBillRevenue({ deal, startDate, endDate });
  return Number(totalRevenue || 0);
};
const calculateConversionsCosts = ({ deal, conversions, invoicePayout }) => {
  let totalPayout = 0;
  conversions.forEach(({ payout }) => {
    totalPayout += getAmountByConversion(deal, payout);
  });

  if ([dealTypes.hybrid].includes(deal.dealTerms.type_of_deal) && deal.dealTerms.guaranteed_payment_recoverable === 'Yes') {
    totalPayout = invoicePayout;
  }
  // if ([dealTypes.upfront, dealTypes.upfrontCPM, dealTypes.bonus].includes(deal.dealTerms.type_of_deal)) {
  //   totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  // }
  return totalPayout;
};

const calculateBillCosts = ({ deal, startDate, endDate }) => {
  // eslint-disable-next-line import/no-dynamic-require, global-require
  const moment = global?.MODULES ? require(`${global?.MODULES || ''}moment-timezone`) : require('moment-timezone');
  // const moment = ;
  // const aValues = getConstant('deal', 'published', []);
  // const paidValues = getConstant('deal', 'paid', []);
  // const isPaid = !aValues.includes(deal.dealstage.toString()) || !paidValues.includes(deal.dealstage.toString());

  const closeDate = deal.brandApprovedDate ? moment.tz(moment.utc(deal.brandApprovedDate).format('YYYY-MM-DD'), 'America/New_York') : null;
  let totalPayout = 0;
  if ([dealTypes.upfront, dealTypes.upfrontCPM].includes(deal.dealTerms.type_of_deal) && closeDate && closeDate >= moment.tz(startDate, 'America/New_York') && closeDate < moment.tz(endDate, 'America/New_York').endOf('month')) {
    totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  }
  const closeDateBonus = deal.dealTerms.content_release_date ? moment.tz(moment.utc(deal.dealTerms.content_release_date).format('YYYY-MM-DD'), 'America/New_York') : null;

  if ([dealTypes.bonus].includes(deal.dealTerms.type_of_deal) && closeDateBonus && closeDateBonus >= moment.tz(startDate, 'America/New_York') && closeDateBonus < moment.tz(endDate, 'America/New_York').endOf('month')) {
    totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  }
  if ([dealTypes.hybrid].includes(deal.dealTerms.type_of_deal) && closeDate && closeDate >= moment.tz(startDate, 'America/New_York') && closeDate < moment.tz(endDate, 'America/New_York').endOf('month')) {
    totalPayout += Number(deal.dealTerms.guaranteed_payment_contract_amount);
  }
  return totalPayout;
};

const isDealRight = (deal, startMomentUTC, endMomentUTC) => {
  const { deal: { published, paid } } = window?.constants || {};
  // eslint-disable-next-line import/no-dynamic-require, global-require
  const moment = global?.MODULES ? require(`${global?.MODULES || ''}moment-timezone`) : require('moment-timezone');
  if (deal.dealTerms.type_of_deal === dealTypes.cpa
    && (
      deal.dealTerms.cpa_status === 'Active'
      || (
        deal.dealTerms.cpa_status === 'Pending'
        && moment.utc(deal.brandApprovedDate).isBetween(startMomentUTC, endMomentUTC)
      )
    )
  ) return true;
  if (deal.dealTerms.type_of_deal === dealTypes.bonus
    && deal.dealTerms.cpa_status && moment.utc(deal.dealTerms.content_release_date).isBetween(startMomentUTC, endMomentUTC)
    && [].concat(paid, published).includes(deal.dealstage.toString())
  ) return true;
  if (deal.dealTerms.type_of_deal === dealTypes.hybrid
    && (
      deal.dealTerms.cpa_status === 'Active'
      || (
        [].concat(paid, published).includes(deal.dealstage.toString())
        && moment.utc(deal.brandApprovedDate).isBetween(startMomentUTC, endMomentUTC)
      )
    )
  ) return true;
  if ([dealTypes.upfront, dealTypes.upfrontCPM].includes(deal.dealTerms.type_of_deal)
    && (
      deal.dealTerms.cpa_status === 'Active'
      || (
        moment.utc(deal.brandApprovedDate).isBetween(startMomentUTC, endMomentUTC)
      )
    )
  ) return true;
  return false;
};

const getIssueMessage = (item, paidValues, aValues, includeSa) => {
  // eslint-disable-next-line import/no-dynamic-require, global-require
  const moment = global?.MODULES ? require(`${global?.MODULES || ''}moment-timezone`) : require('moment-timezone');
  if (item.deal?.dealTerms?.cpa_status !== 'Active' && ['Hybrid', 'CPA/Ambassador'].includes(item.deal?.dealTerms?.type_of_deal)) return 'No active CPA or Hybrid deals.';
  if (item.deals?.length > 1 && ['Hybrid', 'CPA/Ambassador'].includes(item.deal?.dealTerms?.type_of_deal)) return 'Able to find another possible deal';

  if (includeSa && ['Hybrid', 'CPA/Ambassador'].includes(item.deal?.dealTerms?.type_of_deal) && !item.sa) return 'No Service Agreement for active CPA or Hybrid deal.';
  if (['Hybrid', 'CPA/Ambassador'].includes(item.deal?.dealTerms?.type_of_deal) && (!item.deal?.brandApprovedDate || !item.mc)) return 'No Brand Approved media content for active CPA or Hybrid deal.';

  if (item.deal?.dealTerms?.cpa_status !== 'Active' && item.deal?.dealTerms?.cpa_status !== 'Pending'
    && item.deal?.dealTerms?.cpa_status !== 'Paid') return `Invalid ${['Hybrid', 'Upfront Only', 'Upfront CPM', 'Media License'].includes(item.deal?.dealTerms?.type_of_deal) ? '' : 'CPA'} Status`;
  if (['Hybrid', 'Upfront Only', 'Upfront CPM', 'Media License'].includes(item.deal.dealTerms.type_of_deal)) {
    if (!item.deal?.brandApprovedDate && item.mc) {
      return 'No Brand Approved Date';
    }

    if (!item.deal?.dealstage) return 'No Dealstage';
    if (!item.deal?.brandApprovedDate) return 'Missing content for Deal';

    if (!aValues.includes(item.deal.dealstage.toString()) && !paidValues.includes(item.deal.dealstage.toString())) return 'Invalid Dealstage';
    // console.log(item.deal?.brandApprovedDate, 'CCDATE');
    const closeDate = item.deal?.brandApprovedDate ? moment.utc(String(item.deal?.brandApprovedDate).split('T')[0]) : null;
    // console.log(closeDate, 'CCDATE');
    if (closeDate > moment.utc()) return 'Brand Approved Date is in the future';
  }
  if (['Bonus'].includes(item.deal.dealTerms.type_of_deal)) {
    // console.log(aValues.includes(item.deal.dealstage.toString()), paidValues.includes(item.deal.dealstage.toString()));
    if (!aValues.includes(item.deal.dealstage.toString()) && !paidValues.includes(item.deal.dealstage.toString())) return 'Invalid Dealstage';
  }

  return null;
};

module.exports = {
  dealTypes,
  getTotalPayment,
  getAmountByConversion,
  calculateTotalRevenue,
  calculateConversionsCosts,
  calculateBillCosts,
  calculateBillRevenue,
  isDealRight,
  getIssueMessage,
};
