import React, { useMemo } from 'react';
import { currentTimezoneAggr } from '../../../../../../shared/helpers/Dates';
import WVFormatter from '../../../../../../shared/helpers/WVFormatter';
import ContextMenuInList from './table/ContextMenuInList';
import Comment from './table/Comment';
import CellSelect from '../../../../../../shared/components/table/CellSelect';
import AiSentiment from "./table/AiSentiment";

const sValues = ['Unreviewed', 'Positive', 'Negative', 'Ignore'];
const brandValues = ['Unreviewed', 'Relevant', 'Ignore'];

export default function TableColumns(setDisableNote, setCurrentComment, setShowNotesModal, useShowForUsers, setSourceNotesModal) {
  console.log('Icon test');
  return [
    {
      Header: 'Video Details',
      accessor: () => null,
      id: 'videoTitle',
      Cell: record => (
        <div style={{ width: '200px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.youtube.com/watch?v=${record.row.original.params.videoId}`}
          >
            {record.row.original.params?.videoTitle?.length > 30 ? `${record.row.original.params.videoTitle}...` : record.row.original.params.videoTitle}
          </a>
          <p>Likes: {record.row.original.params?.likeCount || 0} / Replies: {record.row.original.params?.totalReplyCount || 0}</p>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
      width: 200,
    },
    {
      Header: 'Type',
      accessor: p => (p.mediaContentType || ''),
      id: 'mediaContentType',
      Cell: record => (
        <div style={{width: '150px'}}>
          <span>{record.row.original.params?.mediaContentType || ''}</span>
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 150,
    },
    {
      Header: `Publish Date (${currentTimezoneAggr()})`,
      accessor: p => (WVFormatter.getServerDateTime(p.params?.videoPublishDate)),
      id: 'videoPublishDate',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{WVFormatter.getServerDateTime(value.row.original.params?.videoPublishDate)}</span>
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 170,
    },
    {
      Header: 'Creator',
      // accessor: p => (p.params?.creatorName),
      id: 'creatorName',
      Cell: record => (
        <div style={{ width: '150px', paddingRight: '10px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`/influencer/profile/${record.row.original.params.influencer}`}
          >
            {record.row.original.params.creatorName}
          </a>
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
      width: 150,
    },
    {
      Header: 'Brand',
      // accessor: p => (p.params?.brand),
      id: 'brandName',
      Cell: record => (
        <div style={{ width: '150px', paddingRight: '10px' }}>
          <span>{record.row.original.params?.brandName || ''}</span>
        </div>
      ),
      disableSortBy: false,
      // className: 'align-middle',
      width: 150,
    },
    {
      Header: 'Comment',
      accessor: () => null,
      id: 'textDisplay',
      width: 250,
      Cell: record => (
        <div style={{ width: '250px' }}>
          <Comment record={record} />
        </div>
      ),
      // className: 'align-middle',
      disableSortBy: true,
    },
    {
      Header: `Comment Date (${currentTimezoneAggr()})`,
      accessor: p => (WVFormatter.getServerDateTime(p.params?.createdAt)),
      id: 'publishedAt',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{WVFormatter.getServerDateTime(value.row.original.params?.publishedAt)}</span>
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 170,
    },
    {
      Header: 'AI Sentiment',
      accessor: p => (p.params?.aiSentimentLabel),
      id: 'aiSentimentLabel',
      Cell: record => (
        <div style={{ width: '130px' }}>
          <AiSentiment label={record.row.original.params?.aiSentimentLabel || ''} />
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 130,
    },
    {
      Header: 'AI Score',
      accessor: p => Number(p.params?.aiSentimentScoreDouble || 0),
      id: 'aiSentimentScoreDouble',
      Cell: record => (
        <div style={{width: '95px'}}>
          <span>{Number(record.row.original.params?.aiSentimentScore.$numberDecimal).toFixed(2) || ''}</span>
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 95,
    },
    {
      Header: 'Human Sentiment',
      id: 'sentimentLabel',
      Cell: p => useMemo(() => (
        <CellSelect
          name="sentimentLabel"
          label="Human Sentiment"
          record={p.row.original}
          values={sValues}
          update="/api/portal/comments/post-save"
          showNotes={() => { setDisableNote(false); setCurrentComment(p.row.original); setSourceNotesModal('sentimentLabel'); setShowNotesModal(true); }}
          afterSaveFunc={(response) => {
            const { record } = response.data;
            if (['Negative'].includes(record.params['sentimentLabel.value'])) {
              setDisableNote(false); setCurrentComment(p.row.original); setSourceNotesModal('sentimentLabel'); setShowNotesModal(true);
            }
          }}
          useHistory
          disableNotAssigned
        />
      ), [p.row.original]),
      className: 'align-middle text-center',
      disableSortBy: true,
      width: 180,
    },
    {
      Header: 'AI Brand Relevancy',
      id: 'aiBrandRelevancyLabel',
      accessor: p => (p.params?.aiBrandRelevancyLabel),
      Cell: record => (
        <div style={{ width: '130px' }}>
          <AiSentiment label={record.row.original.params?.aiBrandRelevancyLabel || ''} />
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 130,
    },
    {
      Header: 'AI Brand Relevancy Score',
      id: 'aiBrandRelevancyScoreDouble',
      accessor: p => Number(p.params?.aiBrandRelevancyScoreDouble || 0),
      Cell: record => (
        <div style={{width: '80px'}}>
          <span>{Number(record.row.original.params?.aiBrandRelevancyScore.$numberDecimal).toFixed(2) || ''}</span>
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 80,
    },
    {
      Header: 'Human Brand Relevancy',
      id: 'brandRelevancyLabel',
      Cell: p => useMemo(() => (
        <CellSelect
          name="brandRelevancyLabel"
          label="Human Brand Relevancy"
          record={p.row.original}
          values={brandValues}
          update="/api/portal/comments/post-save"
          showNotes={() => { setDisableNote(false); setCurrentComment(p.row.original); setSourceNotesModal('brandRelevancyLabel'); setShowNotesModal(true); }}
          // afterSaveFunc={(response) => {
          //   const { record } = response.data;
          //   if (['Negative'].includes(record.params['brandRelevancyLabel.value'])) {
          //     setDisableNote(false); setCurrentComment(p.row.original); setShowNotesModal(true);
          //   }
          // }}
          useHistory
          disableNotAssigned
        />
      ), [p.row.original]),
      className: 'align-middle text-center',
      disableSortBy: true,
      width: 200,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'comments_actions',
      width: 50,
      Cell: p => (
        <ContextMenuInList
          record={p.row.original}
          // setShowStatusHistoryModal={setShowStatusHistoryModal}
          // setCurrentComment={setCurrentComment}
        />
      ),
      disableSortBy: true,
      className: 'd-flex align-items-center align-middle',
    },
  ];
}
