import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button, FormGroup,
} from 'reactstrap';
// import SelectAsync from 'react-select/async';
import Select from 'react-select';
import withNotice from '../../../../../../../App/store/with-notice';
import WVFormatter from '../../../../../../../../shared/helpers/WVFormatter';
import RadioButton from '../../../../../../../../shared/components/RadioButton';
import AssignAnalystList from './AssignAnalystList';

// const loadUsersOptions = async (value) => {
//   const response = await axios.get('/api/portal/prospects/get-analyst-user-search', {
//     params: {
//       value,
//     },
//   });
//   const analystUsers = response.data.records.map(record => ({
//     value: record.params._id,
//     label: `${record.params.firstName} ${record.params.lastName}`,
//     record,
//   }));
//   return [{ value: null, label: 'Not Assigned', record: { params: { _id: null, firstname: 'Not Assigned', lastname: '' } } }].concat(analystUsers);
// };

const notAssigned = {
  value: null,
  label: '-- Not Assigned --',
  record: { params: { _id: null, firstname: '-- Not Assigned --', lastname: '' } },
};

const AssignAnalystModal = ({
  isOpen,
  closeFunc,
  setVersion,
  prospectIds,
  analysts,
  total,
}) => {
  const [loading, setLoading] = useState(false);
  const [analyst, setAnalyst] = useState(notAssigned);
  const [includeAll, setIncludeAll] = useState(false);
  const [multipleAnalysts, setMultipleAnalysts] = useState(false);
  const [multipleAnalystsData, setMultipleAnalystsData] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleChangeAnalyst = async (selectedItem) => {
    setAnalyst(selectedItem);
  };

  useEffect(() => {
    if (!isOpen) {
      setAnalyst(notAssigned);
      setIncludeAll(false);
      setMultipleAnalysts(false);
    }
    console.log(total);
  }, [isOpen]);
  useEffect(() => {
    if (!(includeAll && multipleAnalysts)) {
      setDisableSubmit(false);
    }
  }, [includeAll, multipleAnalysts]);
  // SUBMIT Data
  const submit = async () => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search);
    console.log('Submit');
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-update-analyst',
      data: {
        prospectIds,
        selectedAnalystUser: analyst.value,
        selectedAnalystUserName: analyst.label,
        includeAll,
        multipleAnalysts,
        multipleAnalystsData,
        query: query.toString(),
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        setMultipleAnalystsData([]);
        closeFunc();
      }
    });
    return true;
  };

  const totalToAssign = Math.min(total, 5000);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '600px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Assign Analyst</ModalHeader>
      <ModalBody>
        {prospectIds?.length !== total && (
          <>
            <div>{`You have selected ${prospectIds?.length || 0} result${prospectIds?.length !== 1 ? 's' : ''} to assign to Analyst`}</div>
            <div className="theme-light radio-in-prospects mt-2">
              {`Select ${totalToAssign === total ? 'all of' : 'the first'} ${WVFormatter.formatIntNumber(totalToAssign)} results?`}&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="action"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                name="action"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>
          </>
        )}
        {includeAll === true && (
          <>
            <div className="theme-light radio-in-prospects">
              Divide between multiple Analysts?&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="multipleAnalysts"
                label="&nbsp;Yes"
                value={multipleAnalysts === true ? 'yes' : 'no'}
                radioValue="yes"
                onChange={() => {
                  setMultipleAnalysts(true);
                }}
              />
              <RadioButton
                name="multipleAnalysts"
                label="&nbsp;No"
                value={multipleAnalysts === true ? 'yes' : 'no'}
                radioValue="no"
                onChange={() => {
                  setMultipleAnalysts(false);
                }}
              />
            </div>
          </>
        )}
        {includeAll === true && multipleAnalysts === true ? (
          <AssignAnalystList
            multipleAnalystsData={multipleAnalystsData}
            setMultipleAnalystsData={setMultipleAnalystsData}
            analysts={analysts}
            total={totalToAssign}
            setDisableSubmit={setDisableSubmit}
          />
        ) : (
          <Row>
            <Col>
              <FormGroup>
                <Select
                  value={analyst}
                  options={analysts}
                  onChange={handleChangeAnalyst}
                  placeholder=""
                  isDisabled={!!loading}
                  menuPlacement="auto"
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading || disableSubmit}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};


AssignAnalystModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  prospectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  analysts: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default withNotice(AssignAnalystModal);
