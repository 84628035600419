exports.landingPageOptions = (offerUrls) => {
  const opts = Object.values(offerUrls)
    .filter(row => row.OfferUrl?.id)
    .map(row => ({
      value: row.OfferUrl.id,
      label: `${row.OfferUrl.name} (${row.OfferUrl.id})`,
    }))
    .sort((a, b) => ((a.label < b.label) ? -1 : 1));
  if (opts.length > 0) {
    opts.unshift({
      value: '',
      label: '',
    });
  }
  return opts;
};
