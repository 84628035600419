import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/InfoCircleOutlineIcon'

const Info = ({ field }) => {
  const data = {
    PostingDate: 'Posting-Date',
    CompensationDetails: 'Compensation-Details',
    DeliverableCount: 'Deliverable-Count',
    DeliverableLength: 'Deliverable-Length',
    DeliverableTime: 'Deliverable-Time',
    TerminationDate: 'Termination-Date',
  };
  return data[field] ? (
    <a
      href={`https://influencelogic.atlassian.net/wiki/spaces/IED/pages/147783692/Agency+Service+Agreements#${data[field]}`}
      target="_blank"
      rel="noreferrer"
      style={{ marginLeft: '5px' }}
    >
      <Icon size="17" className="text-blue" />
    </a>
  ) : '';
};

Info.propTypes = {
  field: PropTypes.string.isRequired,
};

export default Info;
