import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import formater from '../../../../../shared/helpers/WVFormatter';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from '../../../../../shared/tables/table/DownloadCSVButton';

const GenerateTable = ({
  reportData,
}) => {
  const { items, totals } = reportData;
  const columns = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: row => row.companyName || '',
        id: 'Brand',
        Cell: ({ value }) => value || '',
        width: 250,
        isFreeze: true,
      },
      {
        Header: 'Total Conversions',
        accessor: row => ((row.conversionsTotal || 0)),
        id: 'totalconversions',
        Cell: ({ value }) => formater.formatIntNumber(value),
        // Footer: () => <span>{formater.formatIntNumber(totals?.conversions || 0)}</span>,
        Footer: (info) => {
          const t = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.totalconversions) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(t)}</span>;
        },
        width: 240,
      },
      {
        Header: 'Conversions',
        accessor: row => ((row.conversions || 0)),
        id: 'conversions',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const t = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(t)}</span>;
        },
        width: 240,
      },
      {
        Header: 'Content',
        accessor: row => Number((row.content || 0)),
        id: 'content',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const t = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.content) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(t)}</span>;
        },
        width: 240,
      },
      {
        Header: 'YouTube Content',
        accessor: row => Number((row.youtubeContent || 0)),
        id: 'youtubeContent',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const t = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.youtubeContent) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(t)}</span>;
        },
        width: 240,
      },
      {
        Header: 'Contracts',
        accessor: row => Number((row.contracts || 0)),
        id: 'contracts',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const t = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.contracts) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(t)}</span>;
        },
        width: 240,
      },
      {
        Header: 'Active Creators',
        accessor: row => Number((row.creators || 0)),
        id: 'creators',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => <span>{formater.formatIntNumber(totals.creators)}</span>,
        width: 200,
      },
      {
        Header: 'Contacts',
        accessor: row => Number((row.contacts || 0)),
        id: 'contacts',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const t = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.contacts) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(t)}</span>;
        },
        width: 100,
      },
    ],
    [items, totals],
  );

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    isFreezeHeader: true,
    useFlex: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'creators',
    direction: 'desc',
  };

  return (
    <div>
      <Row key="3">
        <Col className="mt-3">
          <div
            style={{
              position: 'absolute',
              right: '15px',
            }}
          >
            <DownloadCSVButton
              records={items}
              className=""
              fileName="5CsReport.csv"
              csvArrayColumns={[
                {
                  label: 'Brand',
                  func: row => row.companyName,
                },
                {
                  label: 'Total Conversions',
                  func: record => (record.conversionsTotal || 0),
                  total: totals.conversionsTotal || 0,
                },
                {
                  label: 'Conversions',
                  func: record => (record.conversions || 0),
                  total: totals.conversions || 0,
                },
                {
                  label: 'All Content',
                  func: record => (record.content || 0),
                  total: totals.content || 0,
                },
                {
                  label: 'YouTube Content',
                  func: record => (record.youtubeContent || 0),
                  total: totals.youtubeContent || 0,
                },
                {
                  label: 'Contracts',
                  func: record => (record.contracts || 0),
                  total: totals.contracts || 0,
                },
                {
                  label: 'Active Creators',
                  func: record => (record.creators || 0),
                  total: totals.creators || 0,
                },
                {
                  label: 'Contacts',
                  func: record => (record.contacts || 0),
                  total: totals.contacts || 0,
                },
              ]}
            >
              Download CSV
            </DownloadCSVButton>
          </div>
          <ReactTableBase
            key="searchable"
            columns={columns}
            data={items}
            tableConfig={tableConfig}
          />
        </Col>
      </Row>
    </div>
  );
};

GenerateTable.propTypes = {
  reportData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default GenerateTable;
