import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label
} from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import classnames from 'classnames';
import ApiClient from 'ApiClient';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, withStyles } from '@material-ui/core';
import InfoCircleOutlineIcon from 'mdi-react/InfoCircleOutlineIcon'
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
// import ProgressBar from '../../../../../shared/helpers/ProgressBarWithObject';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import withNotice from '../../../../App/store/with-notice';
import Filter from '../../../../../shared/components/filter/TimePeriodFilter';
import GenerateTable from './GenerateTable';
import formater from '../../../../../shared/helpers/WVFormatter';
import ChartComponent from './ChartComponent';
import LoadingReport from '../../../../../shared/components/LoadingReport';



const chartDataTypes = [
  'content',
  'contacts',
  'contracts',
  'conversionsAndCreators',
];
const WideTooltip = withStyles({
  tooltip: {
    width: '500px',
  },
})(Tooltip);

const Item = ({ label, value, description }) => console.log(description) || (
  <div
    style={{
      padding: '15px 15px',
      border: '1px solid #cccccc',
      borderRadius: '5px',
      height: '100%',
    }}
  >
    <div style={{ textAlign: 'center', color: 'black' }} >
      <span
        style={{
          fontSize: '20px',
          fontWeight: '700',
          marginBottom: '12px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {value}
      </span>
      <span style={{ width: '100%', fontSize: '16px' }}>{label}</span>
      <WideTooltip title={<div style={{ fontSize: '12px', maxWidth: '500px', lineHeight: 1.5 }}>{description}</div>}>
        <IconButton size="small" style={{ marginTop: '-3px' }}>
          <InfoCircleOutlineIcon size="17" className='text-blue'/>
        </IconButton>
      </WideTooltip>
    </div>
  </div>
);

Item.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
};

const Report = ({ addNotice }) => {
  const [loading, setLoading] = useState(false);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState((moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).format('YYYY-MM-DD'));
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [identityChart] = useState(chartDataTypes.reduce((accumulator, value) => ({ ...accumulator, [value]: Math.random().toString(36).substring(3) }), {}));
  const [advertisers, setAdvertisers] = useState([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [liveData, setLiveData] = useState(false);
  const [reportData, setReportData] = useState({});
  const [loadingPeriodsData, setLoadingPeriodsData] = useState(chartDataTypes.reduce((accumulator, value) => ({ ...accumulator, [value]: false }), {}));
  const [periodsData, setPeriodsData] = useState(chartDataTypes.reduce((accumulator, value) => ({ ...accumulator, [value]: [] }), {}));
  const [cspOptions, setCspOptions] = useState([]);
  const [selectedCsps, setSelectedCsps] = useState([]);
  const [activeTab, setActiveTab] = useState('content');

  const advertiserOptions = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    const i = advertisers.map(item => ({ value: item.tuneId, label: item.companyName })).sort((a, b) => ((a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [advertisers]);

  const [selectedRes, setSelectedRes] = useState({
    all: false,
    notPilot: false,
    pilot: false,
  });

  useEffect(() => {
    let optionsItems = [];
    const selectNotPilot = selectedRes.all === true || selectedRes.notPilot === true;
    const selectPilot = selectedRes.all === true || selectedRes.pilot === true;
    if (selectNotPilot === true) {
      optionsItems = advertisers.filter(a => a.isPilot === 'No').map(item => ({ value: item.tuneId, label: item.companyName }));
    }
    if (selectPilot === true) {
      optionsItems = optionsItems.concat(advertisers.filter(a => a.isPilot === 'Yes').map(item => ({ value: item.tuneId, label: item.companyName })));
    }
    setSelectedAdvertisers(optionsItems);
  }, [selectedRes]);

  useEffect(() => {
    const api = new ApiClient();
    setLoadingAdvertiser(true);
    api.client.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      setAdvertisers(Object.values(brands));
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load advertisers. Check out console to see more information.',
        type: 'error',
      });
    });
    api.client.get('/api/portal/reports/get-csp-options').then((resp) => {
      setCspOptions(resp.data.cspOptions);
    }).catch(() => {
      addNotice({
        message: 'There was an error when try load CSP. Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);

  const handleChangeSelectedBrands = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setSelectedRes({
        ...selectedRes,
        [name]: checked,
      });
    }
  };
  const setPeriodDataForTypes = (items) => {
    const days = moment(endDate).endOf('day').diff(moment(startDate), 'day');
    console.log(days);
    const chartData = [];
    for (let i = 0; i <= days; i += 1) {
      const momentDate = moment(startDate).clone().add(i, 'day');
      const key = momentDate.format('YYYY-MM-DD');
      chartData.push(key);
    }
    console.log(chartData);
    const array = {
      //  content: ,
      contacts: chartData.map(date => ({ period: date, data: items.map(brand => ({ companyName: brand.companyName, contacts: (brand.contacts.find(c => c.date === date))?.value || 0 })) })),
      contracts: chartData.map(date => ({ period: date, data: items.map(brand => ({ companyName: brand.companyName, contracts: (brand.contracts.find(c => c.date === date))?.value || 0 })) })),
      // 'contracts',
      conversionsAndCreators: chartData.map(date => ({
        period: date,
        data: items.map(brand => ({ companyName: brand.companyName, conversions: (brand.conversions.find(c => c.date === date))?.value || 0, creators: (brand.creators.find(c => c.date === date))?.value || 0, conversionsTotal: (brand.conversionsTotal.find(c => c.date === date))?.value || 0, })),
      })),
      content: chartData.map(date => ({
        period: date,
        data: items.map(brand => ({ companyName: brand.companyName, content: (brand.content.find(c => c.date === date))?.value || 0, youtubeContent: (brand.youtubeContent.find(c => c.date === date))?.value || 0 })),
      })),
    };
    console.log(array);
    setPeriodsData(array);
  };

  const fetchReport = async () => {
    setLoading(true);
    setActiveTab('conversionsTotal');
    try {
      setLoadingPeriodsData(chartDataTypes.reduce((accumulator, value) => ({ ...accumulator, [value]: false }), {}));
      const resp = await axios.post('/api/portal/cs-report/post-report7c', {
        startDate,
        endDate,
        tuneIds: selectedAdvertisers?.map(row => row.value),
        show0: false,
        cspEmails: selectedCsps?.map(row => row.value),
        identity,
        liveData,
      });
      setLoading(false);
      if (resp.data.success && resp.data.items) {
        const { items, totals } = resp.data;
        const itemsCalcualted = items.map(({
          companyName, contacts, content, contracts, conversions, creators, youtubeContent, conversionsTotal
        }) => ({
          companyName,
          // eslint-disable-next-line no-param-reassign
          contacts: contacts.reduce((a, c) => { a += c.value; return a; }, 0),
          // eslint-disable-next-line no-param-reassign
          content: content.reduce((a, c) => { a += c.value; return a; }, 0),
          // eslint-disable-next-line no-param-reassign
          contracts: contracts.reduce((a, c) => { a += c.value; return a; }, 0),
          // eslint-disable-next-line no-param-reassign
          conversions: conversions.reduce((a, c) => { a += c.value; return a; }, 0),
          conversionsTotal: conversionsTotal.reduce((a, c) => { a += c.value; return a; }, 0),
          // eslint-disable-next-line no-param-reassign
          creators: Object.values(creators.reduce((a, c) => {
            // eslint-disable-next-line no-param-reassign
            c.creators?.forEach((cr) => { a[cr] = true; });
            return a;
          }, {})).length,
          creatorsValue: creators.reduce((a, c) => {
            // eslint-disable-next-line no-param-reassign
            a += c.value;
            return a;
          }, 0),
          // eslint-disable-next-line no-param-reassign
          youtubeContent: youtubeContent.reduce((a, c) => { a += c.value; return a; }, 0),
        }));
        console.log(itemsCalcualted);
        setReportData({ items: itemsCalcualted, totals });
        setPeriodDataForTypes(items);
        // setLoadingPeriodsData(chartDataTypes.reduce((accumulator, value) => ({ ...accumulator, [value]: false }), {}));
        // fetchChartData();
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const totalBox = (key, label, description) => console.log(description()) || (
    <Col
      key={`totalbox${key}`}
    >
      <Item
        label={label}
        value={loading ? (<Spinner color="primary" size="sm" />) : (<h3>{formater.formatIntNumber(reportData.totals[key])}</h3>)}
        description={description()}
      />
    </Col>
  );

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Company Summary', path: false },
              { title: '5Cs', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>5Cs</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3">
                <Col>
                  <Filter
                    startDateInitial={moment(startDate).toDate()}
                    endDateInitial={moment(endDate).endOf('day').toDate()}
                    setValue={(param, value) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                    usedForConversions
                    isLoading={!!loading}
                    options={[
                      { value: 'today', label: 'Today', border: '5px 0 0 5px' },
                      { value: 'yesterday', label: 'Yesterday' },
                      { value: 'last_7_days', label: 'Last 7 Days' },
                      { value: 'current_month', label: 'Current Month' },
                      { value: 'last_month', label: 'Last Month' },
                      { value: 'year_to_date', label: 'Year to Date' },
                      { value: 'last_2_years', label: 'Last 2 Years' },
                      {
                        value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
                      },
                    ]}
                    selectDateRangeFunction={(range) => {
                      let dateFrom;
                      let dateTo;
                      let showCustom = false;
                      switch (range) {
                        case 'today':
                          showCustom = false;
                          dateFrom = moment().startOf('day').toDate();
                          dateTo = moment().endOf('day').toDate();
                          break;
                        case 'yesterday':
                          showCustom = false;
                          dateFrom = moment().subtract(1, 'day').startOf('day').toDate();
                          dateTo = moment().subtract(1, 'day').endOf('day').toDate();
                          break;
                        case 'current_month':
                          showCustom = false;
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = (moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).toDate();
                          break;
                        case 'last_month':
                          showCustom = false;
                          dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
                          dateTo = moment().subtract(1, 'month').endOf('month').toDate();
                          break;
                        case 'year_to_date':
                          showCustom = false;
                          dateFrom = moment().startOf('Year').toDate();
                          dateTo = (moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).toDate();
                          break;
                        case 'last_2_years':
                          showCustom = false;
                          dateFrom = moment().subtract(24, 'months').toDate();
                          dateTo = (moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).toDate();
                          // dateFrom = usedForConversions ? moment().subtract(24, 'months').toDate() : moment(allTimeStartDate || '2019-01-01').startOf('day').toDate();
                          // dateTo = moment().endOf('day').toDate();
                          break;
                        case 'last_7_days':
                          showCustom = false;
                          dateFrom = moment().subtract(7, 'day').startOf('day');
                          dateTo = moment().subtract(1, 'day').endOf('day');
                          break;
                        case 'custom':
                          showCustom = true;
                          dateFrom = moment().startOf('Year').toDate();
                          dateTo = moment().subtract(1, 'day').endOf('day').toDate();
                          break;
                        default:
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = moment().subtract(1, 'day').endOf('day').toDate();
                          break;
                      }
                      console.log({ dateFrom, dateTo, showCustom });
                      return { dateFrom, dateTo, showCustom };
                    }
                  }
                  />
                </Col>
                <Col lg="auto">
                  <div>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchReport();
                        return false;
                      }}
                      className="my-0 btn-sm"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3" >
                <Col md={6}>
                  <Select
                    isDisabled={loadingAdvertiser}
                    value={selectedAdvertisers}
                    options={advertiserOptions}
                    onChange={setSelectedAdvertisers}
                    placeholder="Select Brand(s)..."
                    isClearable
                    isMulti
                  />
                  <Row className="mt-3">
                    <Col md={12}>
                      <FormGroup
                        inline
                        check
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="all"
                          label="Select All Brands"
                          value={selectedRes.all}
                          onChange={event => handleChangeSelectedBrands(event, 'all')}
                        />
                      </FormGroup>
                      <FormGroup
                        inline
                        check
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="notPilot"
                          label="Select No Pilot Brands"
                          value={selectedRes.notPilot}
                          onChange={event => handleChangeSelectedBrands(event, 'notPilot')}
                        />
                      </FormGroup>
                      <FormGroup
                        inline
                        check
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="pilot"
                          label="Select Pilot Brands"
                          value={selectedRes.pilot}
                          onChange={event => handleChangeSelectedBrands(event, 'pilot')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Select
                    value={selectedCsps}
                    options={cspOptions}
                    onChange={setSelectedCsps}
                    placeholder="Select Deal Owner(s)..."
                    isClearable
                    isMulti
                  />
                  <Row className="mt-3">
                    <Col md={8}>
                      <FormGroup>
                        <Label>
                          <CheckBoxField
                            name="previewTypeInfluencers"
                            label=""
                            value={liveData}
                            onChange={e => e.target && setLiveData(e.target?.checked)}
                            className=" float-left"
                          />
                          Use real-time conversion data
                          <WideTooltip title={<div style={{ fontSize: '12px', maxWidth: '500px', lineHeight: 1.5 }}>Use real-time conversion data from HasOffers.<br/><strong>Note:</strong> This will increase the time required to generate the report.</div>}>
                            <IconButton size="small" style={{ marginTop: '-3px' }}>
                              <InfoCircleOutlineIcon size="17" className='text-blue'/>
                            </IconButton>
                          </WideTooltip>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {!loading && reportData?.totals && (
                <Row className="mt-3">
                  {totalBox('conversionsTotal', 'Total Conversions', () => (<>
                    <p><strong>CPA, Upfront, Hybrid Deals</strong><br />
                    <ul className='list bullet'>
                      <li>Count of all conversions from HasOffers where <mark  className="text-white">Revenue {'>'}= $0</mark> and <mark className="text-white">Payout {'>'}= $0</mark></li>
                      <li>All <mark  className="text-white">Rejected</mark> conversions are ignored</li>
                    </ul>
                    </p>
                    <p >
                    <strong className='pt-3'>Upfront CPM Deals</strong><br />
                    <ul className='list bullet'>
                      <li>Count of all conversions with a status of <mark className="text-white">Sign Up</mark> and <mark className="text-white">Paid</mark></li>
                      <li>All <mark className="text-white">Refunded</mark> conversions are ignored</li>
                      <li>CPM conversion data is uploaded and saved daily between <string>9-10 am ET</string></li>
                    </ul>
                   </p>
                  </>))}
                  {totalBox('conversions', 'Conversions', () => (<>
                    <p><strong>CPA, Upfront, Hybrid Deals</strong>
                    <ul className='list bullet'>
                      <li>Count of all conversions from HasOffers where <mark  className="text-white">Revenue {'>'} $0</mark> and <mark  className="text-white">Payout {'>'}= $0</mark></li>
                      <li>All <mark  className="text-white">Rejected</mark> conversions are ignored</li>
                    </ul>
                    </p>
                    <p >
                    <strong className='pt-3'>Upfront CPM Deals</strong><br />
                    <ul className='list bullet'>
                      <li>Count of all conversions with a status of <mark className="text-white">Paid</mark></li>
                      <li>All <mark className="text-white">Sign Up</mark> and <mark className="text-white">Refunded</mark> conversions are ignored</li>
                      <li>CPM conversion data is uploaded and saved daily between <string>9-10 am ET</string></li>
                    </ul>
                    </p>
                  </>) )}
                  {totalBox('content', 'All Content', () => 'Count of all content submitted to the Media Content system')}
                  {totalBox('youtubeContent', 'YouTube Content', () => (<>Count off all <mark className="text-white">YouTube</mark> and <mark className="text-white">YouTube Description Text</mark> content types submitted to the Media Content system</>))}
                  {totalBox('contracts', 'Contracts', () => (<>Count of all contracts that have been signed and marked as <mark className="text-white">Complete</mark></>))}
                  {totalBox('creators', 'Active Creators', () => (<>Count of all creators that have at least 1 conversion in the specified date range</>))}
                  {totalBox('contacts', 'Contacts', () => (<>Count of all <mark className="text-white">Leads</mark> imported into HubSpot from the Portal</>))}
                </Row>
              )}
              {!loading && reportData?.items?.length > 0 ? [(
                <Row key="1" className="mt-3 mb-2">
                  <Col className="tabs">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 'conversionsTotal' })}
                          onClick={() => {
                            setActiveTab('conversionsTotal');
                          }}
                        >
                          <span>
                            Total Conversions
                            {loadingPeriodsData.conversionsAndCreators && (
                              <>
                                {' '}
                                <Spinner color="primary" size="sm" />
                              </>
                            )}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 'conversions' })}
                          onClick={() => {
                            setActiveTab('conversions');
                          }}
                        >
                          <span>
                            Conversions
                            {loadingPeriodsData.conversionsAndCreators && (
                              <>
                                {' '}
                                <Spinner color="primary" size="sm" />
                              </>
                            )}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 'content' })}
                          onClick={() => {
                            setActiveTab('content');
                          }}
                        >
                          <span>
                            Content
                            {loadingPeriodsData.content && (
                              <>
                                {' '}
                                <Spinner color="primary" size="sm" />
                              </>
                            )}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 'youtubeContent' })}
                          onClick={() => {
                            setActiveTab('youtubeContent');
                          }}
                        >
                          <span>
                            YouTube Content
                            {loadingPeriodsData.content && (
                              <>
                                {' '}
                                <Spinner color="primary" size="sm" />
                              </>
                            )}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 'contracts' })}
                          onClick={() => {
                            setActiveTab('contracts');
                          }}
                        >
                          <span>
                            Contracts
                            {loadingPeriodsData.contracts && (
                              <>
                                {' '}
                                <Spinner color="primary" size="sm" />
                              </>
                            )}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 'creators' })}
                          onClick={() => {
                            setActiveTab('creators');
                          }}
                        >
                          <span>
                            Active Creators
                            {loadingPeriodsData.conversionsAndCreators && (
                              <>
                                {' '}
                                <Spinner color="primary" size="sm" />
                              </>
                            )}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 'contacts' })}
                          onClick={() => {
                            setActiveTab('contacts');
                          }}
                        >
                          <span>
                            Contacts
                            {loadingPeriodsData.contacts && (
                              <>
                                {' '}
                                <Spinner color="primary" size="sm" />
                              </>
                            )}
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>
              ), (
                <TabContent key="2" activeTab={activeTab}>
                  <TabPane tabId="conversionsTotal">
                    <ChartComponent
                      loadingPeriodsData={loadingPeriodsData.conversionsAndCreators}
                      periodsData={periodsData.conversionsAndCreators}
                      identityChart={identityChart.conversionsAndCreators}
                      tableItems={reportData.items || []}
                      chartType="conversionsTotal"
                    />
                  </TabPane>
                  <TabPane tabId="conversions">
                    <ChartComponent
                      loadingPeriodsData={loadingPeriodsData.conversionsAndCreators}
                      periodsData={periodsData.conversionsAndCreators}
                      identityChart={identityChart.conversionsAndCreators}
                      tableItems={reportData.items || []}
                      chartType="conversions"
                    />
                  </TabPane>
                  <TabPane tabId="content">
                    <ChartComponent
                      loadingPeriodsData={loadingPeriodsData.content}
                      periodsData={periodsData.content}
                      identityChart={identityChart.content}
                      tableItems={reportData.items || []}
                      chartType="content"
                    />
                  </TabPane>
                  <TabPane tabId="youtubeContent">
                    <ChartComponent
                      loadingPeriodsData={loadingPeriodsData.content}
                      periodsData={periodsData.content}
                      identityChart={identityChart.content}
                      tableItems={reportData.items || []}
                      chartType="youtubeContent"
                    />
                  </TabPane>
                  <TabPane tabId="contracts">
                    <ChartComponent
                      loadingPeriodsData={loadingPeriodsData.contracts}
                      periodsData={periodsData.contracts}
                      identityChart={identityChart.contracts}
                      tableItems={reportData.items || []}
                      chartType="contracts"
                    />
                  </TabPane>
                  <TabPane tabId="creators">
                    <ChartComponent
                      loadingPeriodsData={loadingPeriodsData.conversionsAndCreators}
                      periodsData={periodsData.conversionsAndCreators}
                      identityChart={identityChart.conversionsAndCreators}
                      tableItems={reportData.items || []}
                      chartType="creators"
                    />
                  </TabPane>
                  <TabPane tabId="contacts">
                    <ChartComponent
                      loadingPeriodsData={loadingPeriodsData.contacts}
                      periodsData={periodsData.contacts}
                      identityChart={identityChart.contacts}
                      tableItems={reportData.items || []}
                      chartType="contacts"
                    />
                  </TabPane>
                </TabContent>
              ), (
                <GenerateTable
                  key="3"
                  reportData={reportData}
                  loading={loading}
                />
              )] : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Report.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(Report);
