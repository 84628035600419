import React from 'react';
import ApiClient from 'ApiClient';
import SearchCard from '../../../../../shared/search/SearchCard';
// import withNotice from '../../../../../containers/App/store/with-notice';

class SearchAccountPayableReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      array: [],
    };

    // this.selectPayableReport = this.selectPayableReport.bind(this);
  }
  componentDidMount() {
    this.fetchData(this.props);
  }

  fetchData() {
    // const { addNotice } = props;

    const api = new ApiClient();
    this.setState({ isFetching: true });
    api.client.get('/api/portal/finance/get-accounts-payable').then((resp) => {
      this.setState({
        isFetching: false,
        array: resp.data.items,
      });
    }).catch(() => {
      this.setState({ isFetching: false });
      /* addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      }); */
    });
  }

  render() {
    const { isFetching, array } = this.state;
    const newArray = [...array].map((item) => {
      const newItem = { ...item };
      return { id: newItem._id, title: newItem.name };/* eslint-disable-line */
    });
    return (
      <SearchCard isFetching={isFetching} array={newArray} linkTail="finance/account-payable-report" linkTailAfter="creators" />
    );
  }
}
export default SearchAccountPayableReport;
