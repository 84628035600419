import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row, Col, Container, Card, CardBody, CardTitle,
} from 'reactstrap';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import { NoResourceError } from '../../../../shared/components/components/ui/error404';
import Table from './components/Table';
import Actions from './components/Actions';
import getTableColumns from './components/custom/TableColumns';
import RefreshYoutubeDialog from '../MediaContent/components/custom/modals/RefreshYoutubeDialog';
import hooks from '../../../../shared/hooks';
import NotesModal from "../AiCommentMonitoring/components/modals/NotesModal";
import CommentViewModal from "./components/modals/CommentViewModal";
import RunSentimentAnalysis from "../AiCommentMonitoring/components/modals/RunCommentAllSentimentAnalysis";
import UpdateHumanSentimentAndRelevancy from "./components/modals/UpdateHumanSentimentAndRelevancy";
import UpdateBulkUnreviewedModal from "./components/modals/UpdateBulkUnreviewedModal";

const AiCommentMonitoring = ({ resources }) => {
  const [useShowForUsers] = hooks.useAccessHook({ hideWhenTeam: ['Auditors'] });
  const [disableNote, setDisableNote] = useState(false);
  const [currentComment, setCurrentComment] = useState({});
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [sourceNotesModal, setSourceNotesModal] = useState('');
  // const [showStatusHistoryModal, setShowStatusHistoryModal] = useState(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [showUpdateUnreviewedModal, setShowUpdateUnreviewedModal] = useState(false);
  const [version, setVersion] = useState((new Date()).toString());
  const [selectedCommentIds, setSelectedCommentIds] = useState([]);
  const [metaTotal, setMetaTotal] = useState(0);
  const [showTable, setShowTable] = useState(false);

  const customColumns = useMemo(() => getTableColumns(setDisableNote, setCurrentComment, setShowNotesModal, useShowForUsers, setSourceNotesModal), []);

  const handleSelectCheckbox = (selectedIds) => {
    setSelectedCommentIds(selectedIds);
    console.log(metaTotal);
  };

  const downloadCsv = () => {
    const search = new URLSearchParams(window.location.search);
    const url = `/api/portal/comments/get-ai-comments-csv?${search.toString()}`;
    const win = window.open(url, '_blank');
    win.focus();
    // console.log('downloadCsv');
  };

  const resource = resources.find(r => r.id === 'YoutubeComment');
  if (!resource) {
    return (<NoResourceError resourceId="YoutubeComment" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Compliance', path: false },
              { title: 'Comment Monitoring - AI Powered', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <h3>AI Comment Monitoring</h3>
                <hr className="mt-3 mb-4" />
              </CardTitle>
              <Actions
                setShowUpdateStatusModal={setShowUpdateStatusModal}
                selectedCommentIds={selectedCommentIds}
                downloadCsv={downloadCsv}
                setShowUpdateUnreviewedModal={setShowUpdateUnreviewedModal}
                setShowTable={setShowTable}
              />
              <Row className="ai-comments-dashboard">
                {showTable && (
                  <Table
                    action={action}
                    resource={resource}
                    version={version}
                    customColumns={customColumns}
                    handleSelectCheckbox={handleSelectCheckbox}
                    setMetaTotal={setMetaTotal}
                  />
                )}
              </Row>
            </CardBody>
          </Card>
          <NotesModal
            isOpen={showNotesModal}
            setVersion={setVersion}
            disabled={disableNote}
            closeFunc={() => { setShowNotesModal(false); }}
            comment={currentComment}
            source={sourceNotesModal}
          />
          <CommentViewModal />
          <RunSentimentAnalysis afterSaveFunc={() => setVersion((new Date()).toString())} />
          <UpdateHumanSentimentAndRelevancy
            isOpen={showUpdateStatusModal}
            closeFunc={() => { setShowUpdateStatusModal(false); }}
            commentIds={selectedCommentIds}
            setVersion={setVersion}
          />
          <UpdateBulkUnreviewedModal
            isOpen={showUpdateUnreviewedModal}
            closeFunc={(updateTable) => {
              setShowUpdateUnreviewedModal(false);
              if (updateTable) setVersion((new Date()).toString());
            }}
          />
        </Col>
      </Row>
      <RefreshYoutubeDialog afterSaveFunc={() => setVersion((new Date()).toString())} />
    </Container>
  );
};

AiCommentMonitoring.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(withNotice(AiCommentMonitoring));
