import React, { useMemo, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select';
import { axios } from 'ApiClient';
import { connect } from 'react-redux';
import withNotice from '../../../App/store/with-notice';

import { getAdminUsers } from '../../../../redux/reducers/admin/ResearchReducer';
import ReactTableBase from '@/shared/tables/table/ReactTableBase';
import { getConstant } from '@/shared/helpers/WVConstants';
import TableWithFilters from './TableWithFilters';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  useFlex: true,
  isSortable: true,
  withPagination: true,
  withSearchEngine: false,
  manualPageSize: [10, 20, 30, 40],
  sortBy: 'offer',
  direction: 'desc',
};

const getLink = (text) => {
  if (text.indexOf('{') > -1) {
    const messageBody = JSON.parse(text);
    const { promoCode, brandName, errorMessage } = messageBody;
    const url = `/resources/HubilDeal/actions/list?filters.fullSearchName=${promoCode}&filters.brands=${brandName}`;
    return <div>{errorMessage} <a target="_blank" rel="noopener noreferrer" href={url}>{promoCode}</a> </div>;
  }
  return text;
};

const SummaryReport = ({
  addNotice, reportData, loading, adminUsers, dispatch,
}) => {
  const [dealMap, setDealMap] = useState([]);
  const [filteredReportData, setFilteredReportData] = useState(reportData);
  const [dealStagesSuggestions, setDealStagesSuggestions] = useState([]);

  const updateOwner = (index, adminId, type, hubilContact) => {
    const dm = dealMap.find(d => Number(d.index) === Number(index));
    console.log('hubilContact is', hubilContact);
    if (dm) {
      const adminRecord = adminUsers.find(a => a.value === adminId);
      const data = new FormData();

      if (type === 'contactOwner') {
        dm.contactOwnerSelect = adminRecord;
        dm.updatingContactOwner = true;
        data.append('newContactOwnerId', adminId);
        data.append('hubilContact', hubilContact?.hs_object_id);
      }
      if (type === 'dealOwner') {
        dm.dealOwnerSelect = adminRecord;
        dm.updatingDealOwner = true;
        data.append('newDealOwnerId', adminId);
      }

      setDealMap([...dealMap, dm]);
      const dat = reportData.find(r => r.index === index);

      if ((type === 'dealOwner') && (!dat || !dat.deal)) {
        addNotice({
          message: 'Update failed! Deal not found',
          type: 'error',
        });
        return;
      }

      if (dat?.deal?._id) {
        data.append('dealId', dat.deal._id);
      }

      axios({
        method: 'post',
        url: '/api/portal/update-hubspot-owner',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
      }).then((response) => {
        const { success } = response.data;
        if (success) {
          let who = 'Contact Owner';
          if (dm.updatingDealOwner) {
            who = 'Deal Owner';
          }
          addNotice({
            message: `${who} has been updated`,
            type: 'success',
          });
        } else {
          console.error('update failed', response);
          addNotice({
            message: 'Update failed',
            type: 'warning',
          });
        }
        dm.updatingContactOwner = false;
        dm.updatingDealOwner = false;
        setDealMap([...dealMap, dm]);
      }).catch((error) => {
        console.error('here is the error', error);
        addNotice({
          message: error.message,
          type: 'error',
        });
      });
    } else {
      console.log('Not updating ->', index, adminId);
    }
  };

  const updateAffiliateManager = (index, adminId) => {
    const dm = dealMap.find(d => Number(d.index) === Number(index));
    if (dm) {
      const adminRecord = adminUsers.find(a => a.value === adminId);
      const data = new FormData();
      dm.affiliateManagerSelect = adminRecord;
      dm.updatingAffiliateManager = true;
      data.append('affiliateManagerId', adminId);
      setDealMap([...dealMap, dm]);
      const dat = reportData.find(r => r.index === index);

      if (!dat.influencer || !dat.influencer._id) {
        addNotice({
          message: 'Update failed! Influencer not found',
          type: 'error',
        });
        return;
      }

      data.append('influencerId', dat.influencer._id);

      setTimeout(() => {
        // this relates to https://influencelogic.atlassian.net/browse/IL-4103
        const deal = dealMap.find(d => Number(d.index) === Number(index));
        if (deal.updatingAffiliateManager) {
          addNotice({
            message: 'Account Manager update is taking a long time. Force-enabling dropdown selection.',
            type: 'warning',
          });
          deal.updatingAffiliateManager = false;
          setDealMap([...dealMap, deal]);
        }
      }, 5000);

      axios({
        method: 'post',
        url: '/api/portal/update-affiliate-manager',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
      }).then((response) => {
        const { success } = response.data;
        dm.updatingAffiliateManager = false;
        setDealMap([...dealMap, dm]);
        if (success) {
          addNotice({
            message: 'Account Manager has been updated',
            type: 'success',
          });
        } else {
          addNotice({
            message: 'Update failed',
            type: 'warning',
          });
        }
      }).catch((error) => {
        console.error('Try catch error', error);
        addNotice({
          message: error.message,
          type: 'error',
        });
      });
    } else {
      console.log('Not updating ->', index, adminId);
    }
  };

  useEffect(() => {
    if (adminUsers.length < 1) {
      dispatch(getAdminUsers(true));
    }

    if (dealStagesSuggestions.length < 1) {
      fetchDealStagesSuggestions();
    }
  }, []);

  useEffect(() => {
    const allDeals = [];
    if (reportData.length > 0) {
      for (let i = 0; i < reportData.length; i += 1) {
        const deal = {
          index: reportData[i].index,
          dealOwner: reportData[i].admin ? reportData[i].admin : null,
          dealOwnerSelect: reportData[i].admin ? adminUsers.find(c => c.value === reportData[i].admin?._id) : {},
          contactOwnerSelect: reportData[i].hubilContact ? adminUsers.find(c => c.value === reportData[i].hubilContact.adminUserObject?._id) : {},
          affiliateManagerSelect: reportData[i].influencer?.affiliateDataObject ? adminUsers.find(c => String(c.affiliateManagerId) === String(reportData[i].influencer?.affiliateDataObject.account_manager_id)) : {},
          missingDeal: !reportData[i].deal,
          updatingAffiliateManager: false,
          updatingDealOwner: false,
          updatingContactOwner: false,
        };
        allDeals.push(deal);
      }
      console.log('allDeals', allDeals);
      setDealMap(allDeals);
    }
    setFilteredReportData(reportData);
  }, [reportData]);

  const columnsBrands = useMemo(
    () => [
      {
        Header: 'Creator',
        accessor: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
        id: 'Creator',
        Cell: p => useMemo(() => (
          <Tooltip title="Open Creator Profile">
            <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.influencer?._id}`}>{p.value}</a>
          </Tooltip>), [p.row.original]),
        width: 150,
      },
      {
        Header: 'Brand Name',
        accessor: deal => deal?.offer?.advertiser?.companyName || '',
        id: 'Brand Name',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Offer',
        accessor: deal => deal?.offer?.name || '',
        id: 'Offer',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Deal Name',
        accessor: d => d?.deal?.dealname || '',
        id: 'dealname',
        Cell: p => useMemo(() => (p.row.original.deal ? (
          <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original.deal?.hs_object_id}/`} target="_blank" rel="noreferrer" >{p.value}</a>) : ' - '), [p.row.original]),
        width: 150,
      },
      {
        Header: 'Deal Type',
        accessor: d => d.deal?.dealTerms?.type_of_deal || '',
        id: 'Deal Type',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Deal Status',
        accessor: d => d.deal?.dealTerms?.cpa_status || '',
        id: 'Deal Status',
        Cell: ({ value }) => value || '',
        width: 150,
      },
      {
        Header: 'Deal Stage',
        accessor: d => d?.deal?.dealstage ? dealStagesSuggestions[d?.deal?.dealstage] : '',
        id: 'Deal Stage',
        Cell: ({ value }) => value || '',
        func: deal => deal?.deal?.dealstage ? dealStagesSuggestions[deal?.deal?.dealstage] : '',
        width: 150,
      },
      {
        Header: 'Deal Owner',
        accessor: d => `${d?.admin?.firstName || ''} ${d?.admin?.lastName || ''}`,
        id: 'admin',
        Cell: (p) => {
          const dealData = dealMap.find(c => c.index === p.row.original.index);
          if (!dealData) {
            return null;
          }
          return (<Select
            value={dealData?.dealOwnerSelect}
            options={adminUsers}
            isDisabled={dealData.missingDeal || dealData.updatingDealOwner}
            onChange={(s) => {
              if (s.value) {
                updateOwner(p.row.original.index, s.value, 'dealOwner');
              }
            }}
            placeholder=""
            menuPlacement="auto"
          />);
        },
        width: 150,
      },
      {
        Header: 'Contact Owner',
        accessor: d => `${d?.hubilContact?.adminUserObject?.firstName || ''} ${d?.hubilContact?.adminUserObject?.lastName || ''}`,
        id: 'contactOwner',
        Cell: (p) => {
          const dealData = dealMap.find(c => c.index === p.row.original.index);
          if (!dealData) {
            return null;
          }
          return (<Select
            value={dealData?.contactOwnerSelect}
            options={adminUsers}
            isDisabled={dealData.updatingContactOwner}
            onChange={(s) => {
              if (s.value) {
                updateOwner(p.row.original.index, s.value, 'contactOwner', p.row.original.hubilContact);
              }
            }}
            placeholder=""
            menuPlacement="auto"
          />);
        },
        width: 150,
      },
      {
        Header: 'Account Manager',
        accessor: d => d.influencer?.affiliateDataObject?.account_manager_id || '',
        id: 'affiliateManager',
        Cell: (p) => {
          const dealData = dealMap.find(c => c.index === p.row.original.index);
          if (!dealData) {
            return null;
          }
          return (<Select
            value={dealData?.affiliateManagerSelect}
            key={p.row.original.index}
            options={adminUsers.filter(r => r.affiliateManagerId)}
            isDisabled={dealData.updatingAffiliateManager}
            onChange={(s) => {
              if (s.value) {
                updateAffiliateManager(p.row.original.index, s.value);
              }
            }}
            placeholder=""
            menuPlacement="auto"
          />);
        },
        width: 150,
      },
      {
        Header: 'Message',
        accessor: deal => deal?.messages?.join(' ') || '',
        id: 'message',
        // eslint-disable-next-line react/no-array-index-key
        Cell: ({ row }) => (row.original.messages.map((e, i) => (<div key={`${row.original.index}-${i}`}>{getLink(e)}</div>))),
        width: 300,
      },
    ],
    [dealMap],
  );

  const fetchDealStagesSuggestions = () => {
    const dealStages = ((getConstant('deal', 'pipelines') || []).find(p => p.label.toLowerCase() === 'main pipeline'))?.stages;
    setDealStagesSuggestions(dealStages);
  };

  return (
    <div>
      {!loading && reportData.length > 0 ? (
        <Row>
          <Col>
            <Row>
              <Col className="mt-1">
                {(!loading && dealMap.length > 0) ? (
                  <TableWithFilters
                    unFilteredReportData={reportData}
                    setFilteredReportData={setFilteredReportData}
                    adminUsers={adminUsers}
                  />) : null
                }
              </Col>
            </Row>
            <Row>
              <Col>
                {(!loading && filteredReportData && filteredReportData.length > 0) && (
                  <ReactTableBase
                    key="searchable"
                    columns={columnsBrands}
                    data={filteredReportData}
                    tableConfig={tableConfig}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>) : null
      }
    </div>
  );
};
SummaryReport.propTypes = {
  reportData: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  adminUsers: state.ResearchReducer.adminUsers,
});

export default withNotice(connect(mapStateToProps)(SummaryReport));
