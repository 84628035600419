/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Popover, PopoverBody,
  Row,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import Select from 'react-select';
import { connect } from 'react-redux';
import ReactQr from 'react-awesome-qr';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import QrcodeIcon from 'mdi-react/QrcodeIcon';
import withNotice from '../../../../../containers/App/store/with-notice';
import { createVanityURL, editVanityURL } from '../../../../../redux/reducers/admin/InfluencerProfileReducer';
import recordToFormData from '../../../../../shared/components/components/actions/record-to-form-data';
import OfferHelper from '../../../../../shared/helpers/Offer';


const EditVanityURLModal = ({
  isOpen, toggleModal, influencer, vanityURL, dispatch, addNotice,
}) => {
  const [offerOptions, setOfferOptions] = useState([]);
  const [selectedOfferOption, setSelectedOfferOption] = useState(null);
  const [domainOptions, setDomainOptions] = useState([]);
  const [selectedDomainOption, setSelectedDomainOption] = useState(null);
  const [managerOptions, setManagerOptions] = useState([]);
  const [selectedManagerOption, setSelectedManagerOption] = useState(null);
  const [selectedLandingPageOption, setSelectedLandingPageOption] = useState(null);
  if (typeof influencer?.params?.employee !== 'undefined' && influencer?.params?.employee !== null) {
    const defaultManagerOption = { value: influencer.params.employee._id, label: `${influencer.params.employee.firstName} ${influencer.params.employee.lastName}` };
    if (selectedManagerOption === null) {
      setSelectedManagerOption(defaultManagerOption);
    }
  }

  const [channelName, setChannelName] = useState('');
  const [localPath, setLocalPath] = useState('');
  const [landingPage, setLandingPage] = useState('');
  const [landingPageOptions, setLandingPageOptions] = useState([]);
  const [landingPagePlaceholder, setLandingPagePlaceholder] = useState('');
  const [showVanityURL, setShowVanityURL] = useState('');
  const [showResultURL, setShowResultURL] = useState('');
  const [lgcCode, setLgcCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    offer: false,
    redirectDomain: false,
    accountManager: false,
    localPath: false,
    channelName: false,
  });
  const [qrCodeBase64, setQrCodeBase64] = useState(null);
  const [qrPopoverOpen, setQrPopoverOpen] = useState(false);
  const wrapperRef = useRef(null);
  const [showQrCodeTargetId] = useState(`showQrCode_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)}`);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        // console.log(ref.current);
        if (ref.current && !ref.current?.contains(event.target)) {
          setQrPopoverOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const downloadQr = () => {
    const name = selectedOfferOption?.label ? `QR Code ${selectedOfferOption.label}.png` : 'QR Code.png';
    if (qrCodeBase64) {
      const link = document.createElement('a');
      document.body.appendChild(link); // for Firefox
      link.setAttribute('href', qrCodeBase64);
      link.setAttribute('download', name);
      link.click();
    }
  };

  useEffect(() => {
    if (isOpen === true) {
      setChannelName(decodeURIComponent(vanityURL?.params?.affSub4) || '');
      setLocalPath(() => {
        let localPathToSet = '';
        if (vanityURL && vanityURL.params) {
          if (vanityURL.params.type === 'other') {
            localPathToSet = vanityURL.params.affSub || vanityURL.params.localPath;
          }
          if (vanityURL.params.type === 'betterhelp') {
            localPathToSet = vanityURL.params.source || vanityURL.params.localPath;
          }
        }
        return localPathToSet;
      });
      setLandingPage(vanityURL?.params?.urlId || '');
      setShowVanityURL(() => {
        let showVanityURLToSet = '';
        let localPathToSet = '';
        if (vanityURL && vanityURL.params) {
          if (vanityURL.params.type === 'other') {
            localPathToSet = vanityURL.params.affSub || vanityURL.params.localPath;
          }
          if (vanityURL.params.type === 'betterhelp') {
            localPathToSet = vanityURL.params.source || vanityURL.params.localPath;
          }
          if (vanityURL.params.redirectDomain && vanityURL.params.redirectDomain.domain) {
            showVanityURLToSet = `https://${selectedDomainOption?.label}/${localPathToSet}`;
          }
        }
        return showVanityURLToSet;
      });
      setShowResultURL(() => {
        let showResultURLToSet = '';
        if (vanityURL && vanityURL.params) {
          if (vanityURL.params.redirectDomain && vanityURL.params.redirectDomain.domain) {
            if (vanityURL.params.type === 'other') {
              showResultURLToSet =
                // eslint-disable-next-line max-len
                `https://go.influencerlogic.com/aff_c?offer_id=${vanityURL.params.offerId}&aff_id=${vanityURL.params.affiliateId}&aff_sub=${vanityURL.params.affSub}&aff_sub2=${vanityURL.params.affSub2}&aff_sub4=${vanityURL.params.affSub4}${vanityURL.params.urlId ? `&url_id=${vanityURL.params.urlId}` : ''}`;
              if (vanityURL.params.lgcCode !== '') {
                showResultURLToSet += `&aff_sub5=${vanityURL.params.lgcCode}`;
              }
            }
            if (vanityURL.params.type === 'betterhelp') {
              showResultURLToSet =
                `https://go.influencerlogic.com/aff_c?offer_id=${vanityURL.params.offerId}&aff_id=${vanityURL.params.affiliateId}&source=${vanityURL.params.source}`;
            }
          }
        }
        return showResultURLToSet;
      });
      setLgcCode(vanityURL.params.lgcCode || '');
    }
  }, [vanityURL]);

  useEffect(() => {
    if (isOpen === true) {
      setLoading(true);
      const paramOffer = vanityURL?.params?.offer._id ? `?offerId=${vanityURL.params.offer._id}` : '';
      axios.get(`/api/portal/data-for-vanity-urls${paramOffer}`).then((res) => {
        if (res.data && res.data.success) {
          if (res.data.offerOptions) {
            setOfferOptions(res.data.offerOptions);
            if (vanityURL && vanityURL.params && vanityURL.params.offer && vanityURL.params.offer._id) {
              const found = res.data.offerOptions.find(offerOption => offerOption.value === vanityURL.params.offer._id);
              setSelectedOfferOption(found);
            }
          }
          if (res.data.redirectDomainOptions) {
            setDomainOptions(res.data.redirectDomainOptions);
            if (vanityURL && vanityURL.params && vanityURL.params.redirectDomain && vanityURL.params.redirectDomain._id) {
              const found = res.data.redirectDomainOptions.find(redirectDomainOption => redirectDomainOption.value === vanityURL.params.redirectDomain._id);
              setSelectedDomainOption(found);
            }
          }
          if (res.data.managerOptions) {
            setManagerOptions(res.data.managerOptions);
            if (vanityURL && vanityURL.params && vanityURL.params.accountManager && vanityURL.params.accountManager._id) {
              const found = res.data.managerOptions.find(managerOption => managerOption.value === vanityURL.params.accountManager._id);
              setSelectedManagerOption(found);
            }
          }
          if (res.data.offerUrls) {
            const landingPageOptionsData = OfferHelper.landingPageOptions(res.data.offerUrls);
            setLandingPageOptions(landingPageOptionsData);
            setLandingPagePlaceholder((landingPageOptionsData.length > 0) ? 'Set Landing Page Id (if needed)...' : 'No Landing Pages available');
            if (vanityURL && vanityURL.params && vanityURL.params.urlId) {
              setSelectedLandingPageOption(landingPageOptionsData.find(option => option.value.toString() === vanityURL.params.urlId.toString()));
            }
          }
        }
        setLoading(false);
      }).catch((error) => {
        console.error(error);
      });
    } else {
      setOfferOptions([]);
      setSelectedOfferOption(null);
      setDomainOptions([]);
      setSelectedDomainOption(null);
      setManagerOptions([]);
      setSelectedManagerOption(null);
      setSelectedLandingPageOption(null);
      setChannelName('');
      setLocalPath('');
      setLandingPage('');
      setLandingPageOptions([]);
      setLandingPagePlaceholder('');
      setShowVanityURL('');
      setShowResultURL('');
      setLgcCode('');
      setLoading(false);
      setErrors({
        offer: false,
        redirectDomain: false,
        accountManager: false,
        localPath: false,
        channelName: false,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (vanityURL && isOpen === true) {
      if (selectedDomainOption) {
        setShowVanityURL(`https://${selectedDomainOption.label}/${localPath}`);
        if (['trybetterhelp.com', 'tryonlinetherapy.com', 'getfaithful.com'].includes(selectedDomainOption.label)) {
          setShowResultURL(`https://go.influencerlogic.com/aff_c?offer_id=${selectedOfferOption.tuneId}&aff_id=${vanityURL.params.affiliateId}&source=${localPath.trim()}`);
        } else {
          // eslint-disable-next-line no-return-assign,no-param-reassign
          let resultUrl = `https://go.influencerlogic.com/aff_c?offer_id=${selectedOfferOption.tuneId}&aff_id=${vanityURL.params.affiliateId}&aff_sub=${localPath.trim()}${selectedManagerOption && selectedManagerOption.label ? `&aff_sub2=${selectedManagerOption.label.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '').toUpperCase()}` : ''}&aff_sub4=${encodeURIComponent(channelName.trim())}${landingPage ? `&url_id=${landingPage}` : ''}`;
          if (lgcCode !== '') {
            resultUrl += `&aff_sub5=${lgcCode.trim()}`;
          }
          setShowResultURL(resultUrl);
        }
      }
    }
  }, [selectedOfferOption, selectedDomainOption, localPath, selectedManagerOption, channelName, landingPage, lgcCode, selectedLandingPageOption]);

  const saveVanityURL = () => {
    const data = recordToFormData(vanityURL || { params: {} });
    if (influencer?.id && influencer?.params?.affiliateData?.tune_id) {
      let hasErrors = false;
      const newErrors = { ...errors };
      let type = 'other';
      if (typeof vanityURL === 'undefined' || vanityURL === null) {
        data.set('isLegacy', false);
        data.set('influencer', influencer.id);
        data.set('affiliateId', influencer.params.affiliateData.tune_id);
      }

      if (selectedOfferOption) {
        if (selectedOfferOption.value) {
          data.set('offer', selectedOfferOption.value);
        } else {
          newErrors.offer = 'There is a problem with selected Offer. Take a screenshot and contact the Engineering Department.';
          hasErrors = true;
        }
        if (selectedOfferOption && selectedOfferOption.tuneId) {
          data.set('offerId', selectedOfferOption.tuneId);
        } else {
          newErrors.offer = 'There is a problem with selected Offer. Take a screenshot and contact the Engineering Department.';
          hasErrors = true;
        }
      } else {
        newErrors.offer = 'Select the Offer!';
        hasErrors = true;
      }

      if (selectedDomainOption) {
        if (selectedDomainOption.value) {
          data.set('redirectDomain', selectedDomainOption.value);
        } else {
          newErrors.redirectDomain = 'There is a problem with selected Redirect Domain. Take a screenshot and contact the Engineering Department.';
          hasErrors = true;
        }
        if (selectedDomainOption.label) {
          type = ['trybetterhelp.com', 'tryonlinetherapy.com', 'getfaithful.com'].includes(selectedDomainOption.label) ? 'betterhelp' : 'other';
          data.set('type', type);
        } else {
          newErrors.redirectDomain = 'There is a problem with selected Redirect Domain. Take a screenshot and contact the Engineering Department.';
          hasErrors = true;
        }
      } else {
        newErrors.redirectDomain = 'Select the Redirect Domain!';
        hasErrors = true;
      }

      if (localPath) {
        if (type === 'other') {
          data.set('localPath', localPath.trim());
          data.set('affSub', localPath.trim());
        } else if (type === 'betterhelp') {
          data.set('localPath', localPath.trim());
          data.set('source', localPath.trim());
        }
      } else {
        newErrors.localPath = 'Set the URL Path!';
        hasErrors = true;
      }

      if (selectedManagerOption) {
        if (selectedManagerOption.value) {
          data.set('accountManager', selectedManagerOption.value);
        } else {
          newErrors.accountManager = 'There is a problem with selected Account Manager. Take a screenshot and contact the Engineering Department.';
          hasErrors = true;
        }
        if (selectedManagerOption.label) {
          // eslint-disable-next-line no-return-assign,no-param-reassign
          const initials = selectedManagerOption.label.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '').toUpperCase();
          data.set('affSub2', initials.trim());
        } else {
          newErrors.accountManager = 'There is a problem with selected Account Manager. Take a screenshot and contact the Engineering Department.';
          hasErrors = true;
        }
      } else {
        newErrors.accountManager = 'Select the Account Manager!';
        hasErrors = true;
      }
      if (channelName) {
        data.set('affSub4', encodeURIComponent(channelName.trim()));
      } else {
        newErrors.channelName = 'Set the Channel Name!';
        hasErrors = true;
      }
      if (landingPage) {
        data.set('urlId', landingPage);
      } else {
        data.set('urlId', '');
      }
      if (lgcCode && selectedDomainOption && selectedDomainOption.label === 'trylgc.com') {
        data.set('lgcCode', lgcCode.trim());
      } else {
        data.set('lgcCode', '');
      }
      if (type === 'other') {
        // eslint-disable-next-line max-len
        let resultUrl = `https://go.influencerlogic.com/aff_c?offer_id=${data.get('offerId')}&aff_id=${data.get('affiliateId')}&aff_sub=${data.get('affSub')}&aff_sub2=${data.get('affSub2')}&aff_sub4=${data.get('affSub4')}${data.get('urlId') ? `&url_id=${data.get('urlId')}` : ''}`;
        if (data.get('lgcCode') !== '') {
          resultUrl += `&aff_sub5=${data.get('lgcCode')}`;
        }
        data.set(
          'resultUrl',
          resultUrl.trim(),
        );
      }
      if (type === 'betterhelp') {
        data.set(
          'resultUrl',
          `https://go.influencerlogic.com/aff_c?offer_id=${data.get('offerId')}&aff_id=${data.get('affiliateId')}${data.get('urlId') ? `&url_id=${data.get('urlId')}` : ''}&source=${data.get('source')}`,
        );
      }

      setErrors(newErrors);

      if (!hasErrors) {
        dispatch(vanityURL ? editVanityURL(influencer.id, vanityURL.id, data) : createVanityURL(influencer.id, data));
        toggleModal();
        setSelectedLandingPageOption(null);
        setLandingPage('');
        setChannelName('');
        setLocalPath('');
        setSelectedManagerOption(null);
        setSelectedDomainOption(null);
        setSelectedOfferOption(null);
        setErrors({
          offer: false,
          redirectDomain: false,
          accountManager: false,
          localPath: false,
          channelName: false,
        });
      }
    } else {
      addNotice({
        message: 'There is a problem with Creator data. Take a screenshot and contact the Engineering Department.',
        type: 'error',
      });
    }
  };

  const selectItem = (value, type) => {
    switch (type) {
      case 'offer':
        setSelectedOfferOption(value);
        setErrors({ ...errors, offer: false });
        break;
      case 'domain':
        setSelectedDomainOption(value);
        setErrors({ ...errors, redirectDomain: false });
        break;
      case 'manager':
        setSelectedManagerOption(value);
        setErrors({ ...errors, accountManager: false });
        break;
      default:
        return false;
    }
    return true;
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'localPath':
        setLocalPath(value);
        if (value.length > 0) setErrors({ ...errors, localPath: false });
        break;
      case 'channelName':
        setChannelName(value);
        if (value.length > 0) setErrors({ ...errors, channelName: false });
        break;
      case 'lgcCode':
        setLgcCode(value);
        break;
      default:
        return false;
    }
    return true;
  };

  const handleChangeOffer = async (selected) => {
    selectItem(selected, 'offer');
    setLandingPage('');
    setSelectedLandingPageOption(null);
    if (!selected.value) {
      setLandingPagePlaceholder('Select Offer for loading Landing Pages');
    } else {
      setLandingPagePlaceholder('Loading Landing Pages...');
      const response = await axios.get(`/api/portal/offers/urls?offerId=${selected.value}`);
      if (!response.data.success) {
        setLandingPagePlaceholder(response.data.error);
      } else {
        const options = OfferHelper.landingPageOptions(response.data.offerUrls);
        setLandingPageOptions(options);
        setLandingPagePlaceholder((options.length > 0) ? 'Set Landing Page Id (if needed)...' : 'No Landing Pages available');
      }
    }
  };

  const handleChangeLandingPage = async (selectedOption) => {
    setSelectedLandingPageOption(selectedOption);
    setLandingPage(selectedOption.value);
    if (selectedOption.value === '') {
      setLgcCode('');
    }
  };

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
    control: base => ({
      ...base,
      height: '38px',
      minHeight: '38px',
    }),
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="delete-modal ltr-support"
      size="lg"
      style={{ maxWidth: '800px' }}
    >
      <ModalHeader>
        <span className="modal-title" style={{ fontSize: '22px', fontWeight: '700' }} >{vanityURL ? 'Edit Vanity URL' : 'Add New Vanity URL'}</span>
      </ModalHeader>
      <ModalBody>
        {!loading ? (
          <div>
            <Row style={{ marginBottom: '15px' }}>
              <Col
                xs="3"
                style={{ paddingTop: '6px' }}
              >
                <span style={{ fontSize: '18px', fontWeight: '700' }}>Offer</span>
              </Col>
              <Col xs="9">
                <Select
                  name="offerSelect"
                  onChange={handleChangeOffer}
                  value={selectedOfferOption}
                  options={offerOptions}
                  styles={colourStyles}
                  placeholder="Select Offer..."
                />
                {errors.offer && (
                  <span
                    className="help-block color-danger"
                    style={{ display: 'block' }}
                  >
                    {errors.offer}
                  </span>
                )}
              </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }}>
              <Col
                xs="3"
                style={{ paddingTop: '6px' }}
              >
                <span style={{ fontSize: '18px', fontWeight: '700' }}>Redirect Domain</span>
              </Col>
              <Col xs="9">
                <Select
                  name="offerSelect"
                  onChange={value => selectItem(value, 'domain')}
                  value={selectedDomainOption}
                  options={domainOptions}
                  styles={colourStyles}
                  placeholder="Select Redirect Domain..."
                />
                {errors.redirectDomain && (
                  <span
                    className="help-block color-danger"
                    style={{ display: 'block' }}
                  >
                    {errors.redirectDomain}
                  </span>
                )}
              </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }}>
              <Col
                xs="3"
                style={{ paddingTop: '6px' }}
              >
                <span style={{ fontSize: '18px', fontWeight: '700' }}>URL Path</span>
              </Col>
              <Col
                xs="auto"
                style={{ paddingTop: '6px', paddingRight: '0' }}
              >
                {selectedDomainOption?.label && (
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: '700',
                      paddingRight: '5px',
                    }}
                  >
                    {`www.${selectedDomainOption?.label}/`}
                  </p>
                )}
              </Col>
              <Col
                style={{ paddingLeft: '0' }}
              >
                <Input
                  name="localPath"
                  onChange={onInputChange}
                  value={localPath}
                  style={{ minHeight: '38px' }}
                  placeholder="Set URL Path..."
                />
                {errors.localPath && (
                  <span
                    className="help-block color-danger"
                    style={{ display: 'block' }}
                  >
                    {errors.localPath}
                  </span>
                )}
              </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }}>
              <Col
                xs="3"
                style={{ paddingTop: '6px' }}
              >
                <span style={{ fontSize: '18px', fontWeight: '700' }}>Account Manager</span>
              </Col>
              <Col xs="9">
                <Select
                  name="managerSelect"
                  onChange={value => selectItem(value, 'manager')}
                  value={selectedManagerOption}
                  options={managerOptions}
                  styles={colourStyles}
                  placeholder="Select Account Manager..."
                />
                {errors.accountManager && (
                  <span
                    className="help-block color-danger"
                    style={{ display: 'block' }}
                  >
                    {errors.accountManager}
                  </span>
                )}
              </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }}>
              <Col
                xs="3"
                style={{ paddingTop: '6px' }}
              >
                <span style={{ fontSize: '18px', fontWeight: '700' }}>Channel Name</span>
              </Col>
              <Col xs="9">
                <Input
                  name="channelName"
                  onChange={onInputChange}
                  value={channelName}
                  style={{ minHeight: '38px' }}
                  placeholder="Set Channel Name..."
                />
                {errors.channelName && (
                  <span
                    className="help-block color-danger"
                    style={{ display: 'block' }}
                  >
                    {errors.channelName}
                  </span>
                )}
              </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }}>
              <Col
                xs="3"
                style={{ paddingTop: '6px' }}
              >
                <span style={{ fontSize: '18px', fontWeight: '700' }}>Landing Page</span>
              </Col>
              <Col xs="9">
                <Select
                  name="landingPage"
                  onChange={handleChangeLandingPage}
                  value={selectedLandingPageOption}
                  options={landingPageOptions}
                  placeholder={landingPagePlaceholder}
                />
              </Col>
            </Row>
            {selectedDomainOption && selectedDomainOption.label === 'trylgc.com' && selectedLandingPageOption && selectedLandingPageOption.value !== '' && (
              <Row style={{ marginBottom: '15px' }}>
                <Col
                  xs="3"
                  style={{ paddingTop: '6px' }}
                >
                  <span style={{ fontSize: '18px', fontWeight: '700' }}>Coupon Code</span>
                </Col>
                <Col xs="9">
                  <Input
                    name="lgcCode"
                    onChange={onInputChange}
                    value={lgcCode}
                    style={{ minHeight: '38px' }}
                    placeholder="LGC code"
                  />
                  {errors.lgcCode && (
                    <span
                      className="help-block color-danger"
                      style={{ display: 'block' }}
                    >
                      {errors.lgcCode}
                    </span>
                  )}
                </Col>
              </Row>
            )}
            {vanityURL && (
              <>
                <Row style={{ marginTop: '30px' }} >
                  <Col
                    xs="3"
                    style={{ paddingTop: '6px' }}
                  >
                    <span style={{ fontSize: '18px', fontWeight: '700' }}>Results</span>
                  </Col>
                </Row>
                <Row
                  style={{ margin: '8px 0 15px 0' }}
                >
                  <Col
                    xs="12"
                    style={{ border: '1px solid #70bbfd', borderRadius: '5px', padding: '15px' }}
                  >
                    <Row>
                      <Col
                        xs="3"
                        style={{ paddingTop: '6px' }}
                      >
                        <span style={{ fontSize: '18px', fontWeight: '700' }}>Vanity URL</span>
                      </Col>
                      <Col xs="9">
                        <InputGroup size="md">
                          <Input
                            disabled
                            name="vanityURL"
                            value={showVanityURL}
                            style={{ minHeight: '38px' }}
                          />
                          {showResultURL && (
                            <InputGroupAddon addonType="append">
                              <Popover
                                id="popoverQrCode"
                                placement="right"
                                isOpen={qrPopoverOpen}
                                target={showQrCodeTargetId}
                                trigger="click"
                                toggle={() => setQrPopoverOpen(!qrPopoverOpen)}
                              >
                                <PopoverBody
                                  style={{ width: '218px', height: '240px' }}
                                >
                                  <div
                                    ref={wrapperRef}
                                  >
                                    <a
                                      className="il-color-deep-blue bold-text"
                                      role="button"
                                      onClick={downloadQr}
                                      style={{ fontSize: '150%' }}
                                    >
                                      Download QR Code
                                    </a>
                                    <ReactQr
                                      text={`${showResultURL.trim()}${showResultURL.trim().indexOf('?') !== -1 ? '&' : '?'}aff_unique1=qr`}
                                      size={1000}
                                      callback={setQrCodeBase64}
                                    />
                                  </div>
                                </PopoverBody>
                              </Popover>
                              <Tooltip title="Show QR Code">
                                <button
                                  id={showQrCodeTargetId}
                                  style={{
                                    maxHeight: '38px',
                                    border: '1px solid #ced4da',
                                    borderRadius: '0 0.25rem 0.25rem 0',
                                  }}
                                  type="button"
                                  className={`form__form-group-button${qrPopoverOpen ? ' active' : ''}`}
                                  onClick={() => setQrPopoverOpen(!qrPopoverOpen)}
                                ><QrcodeIcon />
                                </button>
                              </Tooltip>
                            </InputGroupAddon>
                          )}
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" />
                      <Col xs="9">
                        <div
                          className="d-flex justify-content-center align-content-center"
                          style={{ padding: '8px' }}
                        >
                          <i className="fa fa-arrow-down" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs="3"
                        style={{ paddingTop: '6px' }}
                      >
                        <span style={{ fontSize: '18px', fontWeight: '700' }}>Redirect URL</span>
                      </Col>
                      <Col xs="9">
                        <Input
                          disabled
                          name="resultURL"
                          value={showResultURL}
                          style={{ minHeight: '38px' }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </div>) : (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => { saveVanityURL(); }}>Save</Button>
        <Button color="danger" onClick={toggleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

EditVanityURLModal.propTypes = {
  addNotice: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  influencer: PropTypes.objectOf(PropTypes.any),
  vanityURL: PropTypes.objectOf(PropTypes.any),
  dispatch: PropTypes.func.isRequired,
};

EditVanityURLModal.defaultProps = {
  isOpen: false,
  influencer: null,
  vanityURL: null,
};

const mapStateToProps = state => ({
  influencer: state.influencer.influencer,
});

export default withNotice(connect(mapStateToProps)(EditVanityURLModal));
