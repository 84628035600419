import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { connect } from 'react-redux';
import ApiClient from 'ApiClient';
import withNotice from '../../../App/store/with-notice';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import UserPropertyType from '../../Jobs/components/custom';
import { NoActionError, NoResourceError } from '../../../../shared/components/components/ui/error404';
import { StyledButton } from '../../../../shared/components/components/ui';
import AccessComponent from '../../../../shared/components/auth/AccessComponent';
import AdvertisersInList from './custom/AdvertisersInList';
import DealCPAStatusesInList from './custom/DealCPAStatusesInList';
import EnabledStatusInList from './custom/EnabledStatusInList';
import DaysInterval from './custom/DaysInterval';
import NameInput from './custom/NameInput';
import DescriptionInput from './custom/DescriptionInput';
import StartDatetimeInList from './custom/StartDatetimeInList';
import ContextMenuInList from './custom/ContextMenuInList';
import AddContentReviewRuleModal from './custom/AddContentReviewRuleModal';

const Report = ({ resources, addNotice }) => {
  const api = new ApiClient();
  const [version, setVersion] = useState((new Date()).toString());
  const [brandOptions, setBrandOptions] = useState([]);
  const [loadingBrandOptions, setLoadingBrandOptions] = useState(false);
  const [showAddRuleModal, setShowAddRuleModal] = useState(false);

  useEffect(() => {
    setLoadingBrandOptions(true);
    api.client.get('/api/portal/compliance/get-brand-options').then((resp) => {
      setBrandOptions(resp.data.brandOptions);
      setLoadingBrandOptions(false);
    }).catch(() => {
      setLoadingBrandOptions(false);
      addNotice({
        message: 'There was an error trying to load brand options. Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);

  const resource = resources.find(r => r.id === 'ScheduledJob');
  if (!resource) {
    return (<NoResourceError resourceId="ScheduledJob" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="ScheduledJob" actionName="list" />);
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Historical Content Review', path: '/scorecard/content-review?page=1&filters.status=Brand+Approved&filters.reviewStatus=Needs+Review' },
              { title: 'Content Review Rules', path: false },
            ]}
          />
        </Col>
      </Row>
      <AccessComponent
        showWhenTeam={['super_powers', 'management', 'compliance']}
        className="float-right"
      >
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle>
                  <h3>Content Review Rules</h3>
                  <StyledButton
                    className="btn btn-primary btn-sm"
                    style={{
                      position: 'absolute',
                      top: '19px',
                      right: '21px',
                      marginRight: '0',
                      marginBottom: '0',
                    }}
                    onClick={() => setShowAddRuleModal(true)}
                  >
                    <i className="fa fa-plus"/>
                    <span className="btn-text"> Add Rule</span>
                  </StyledButton>
                </CardTitle>
                <hr/>
                <div style={{
                  marginLeft: '-17px',
                  marginRight: '-17px',
                  marginTop: '-15px',
                  marginBottom: '-30px',
                }}>
                  <MatTableList
                    action={action}
                    filters={{
                      type: 'contentReviewRules',
                      deleted: 'false'
                    }}
                    resource={resource}
                    date={new Date()}
                    version={version}
                    UserPropertyType={UserPropertyType}
                    useFlex
                    isResizable={false}
                    showTitle={false}
                    showFilters={false}
                    noActions
                    useUnflatten
                    customColumns={[
                      {
                        id: 'name',
                        width: 250,
                        disableSortBy: true,
                        accessor: () => 'name',
                        Header: () => 'Name',
                        Cell: p => useMemo(() => (<NameInput
                          record={p.row.original}
                          property="name"
                        />), [p.row.original.id, p.row.original.params?.name]),
                      },
                      {
                        id: 'description',
                        width: 300,
                        disableSortBy: true,
                        accessor: () => 'description',
                        Header: () => 'Description',
                        Cell: p => useMemo(() => (<DescriptionInput
                          record={p.row.original}
                          property="description"
                        />), [p.row.original.id, p.row.original.params?.description]),
                      },
                      {
                        id: 'advertisers',
                        width: 265,
                        disableSortBy: true,
                        accessor: () => 'advertisers',
                        Header: () => 'Brands',
                        Cell: p => useMemo(() => {
                          return (
                            <AdvertisersInList
                              record={p.row.original}
                              brandOptions={brandOptions}
                              loadingBrandOptions={loadingBrandOptions}
                            />
                          );
                        }, [p.row.original.id, p.row.original.params?.advertisers, brandOptions, loadingBrandOptions]),
                      },
                      {
                        id: 'dealCPAStatuses',
                        width: 265,
                        disableSortBy: true,
                        accessor: () => 'dealCPAStatuses',
                        Header: () => 'Deal Status',
                        Cell: p => useMemo(() => (
                          <DealCPAStatusesInList
                            record={p.row.original}
                          />
                        ), [p.row.original.id, p.row.original.params?.dealCPAStatuses]),
                      },
                      {
                        id: 'intervalMinDays',
                        width: 75,
                        disableSortBy: true,
                        accessor: () => 'intervalMinDays',
                        Header: () => 'Interval Min Days',
                        Cell: p => useMemo(() => (<DaysInterval
                          record={p.row.original}
                          property="intervalMinDays"
                        />), [p.row.original.id, p.row.original.params?.intervalMinDays]),
                      },
                      {
                        id: 'intervalMaxDays',
                        width: 75,
                        disableSortBy: true,
                        accessor: () => 'intervalMaxDays',
                        Header: () => 'Interval Max Days',
                        Cell: p => useMemo(() => (<DaysInterval
                          record={p.row.original}
                          property="intervalMaxDays"
                        />), [p.row.original.id, p.row.original.params?.intervalMaxDays]),
                      },
                      {
                        id: 'startDatetime',
                        width: 160,
                        disableSortBy: true,
                        accessor: () => 'startDatetime',
                        Header: () => 'Start Datetime',
                        Cell: p => useMemo(() => (
                          <StartDatetimeInList
                            record={p.row.original}
                          />
                        ), [p.row.original.id, p.row.original.params?.startDatetime]),
                      },
                      {
                        id: 'enabled',
                        width: 50,
                        disableSortBy: true,
                        accessor: () => 'enabled',
                        Header: () => 'Status',
                        Cell: p => useMemo(() => (
                          <EnabledStatusInList
                            record={p.row.original}
                          />
                        ), [p.row.original.id, p.row.original.params?.enabled]),
                      },
                      {
                        id: 'actionJob',
                        Header: '',
                        accessor: () => null,
                        width: 50,
                        maxWidth: 50,
                        disableSortBy: true,
                        Cell: (p) => useMemo(() => (
                          <ContextMenuInList
                            record={p.row.original}
                            updateTable={() => setVersion((new Date()).toString())}
                            loadingBrandOptions={loadingBrandOptions}
                            brandOptions={brandOptions}
                          />
                        ), [p.row.original.id, brandOptions, loadingBrandOptions]),
                        className: 'd-flex align-items-center align-middle',
                      },
                    ]}
                    hideToolbar
                    sortBy="createdAt"
                    direction="asc"
                    meta={{ closeFunction: () => setVersion((new Date()).toString()) }}
                    v={2}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </AccessComponent>
      <AddContentReviewRuleModal
        isOpen={showAddRuleModal}
        close={() => setShowAddRuleModal(false)}
        updateTable={() => setVersion((new Date()).toString())}
        loadingBrandOptions={loadingBrandOptions}
        brandOptions={brandOptions}
      />
    </Container>
  );
};

Report.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  addNotice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(withNotice(Report));
