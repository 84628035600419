import React from 'react';
import PropTypes from 'prop-types';

const SentimentInList = ({ label }) => {
  let textStyle = 'text-dark';
  let value = '';
  if (label) {
    switch (label) {
      case 'Negative':
        textStyle = 'text-danger';
        break;
      case 'Ignore':
        textStyle = 'il-text-gray';
        break;
      case 'Positive':
        textStyle = 'text-success';
        break;
      default:
        textStyle = 'il-text-gray';
        break;
    }
    value = label;
  }

  return (
    <div>
      {label ?
        (
          <div>
            <span className={textStyle}>{value}</span>
          </div>
        ) : ''
      }
    </div>
  );
};

SentimentInList.propTypes = {
  label: PropTypes.string.isRequired,
};

export default SentimentInList;
