import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Col,
  Modal,
  Row,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import VideoIcon from 'mdi-react/VideoIcon';
import BookOpenPageVariantIcon from 'mdi-react/BookOpenPageVariantIcon';
import FlagVariantIcon from 'mdi-react/FlagVariantIcon';
import TagIcon from 'mdi-react/TagIcon';
import EarthIcon from 'mdi-react/EarthIcon';
import BriefcaseIcon from 'mdi-react/BriefcaseIcon';
import YoutubeIcon from 'mdi-react/YoutubeIcon';
import InstagramIcon from 'mdi-react/InstagramIcon';
import TwitterIcon from 'mdi-react/TwitterIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import withNotice from '../../containers/App/store/with-notice';
import { getLanguageNameFromIso } from '../helpers/WVUtilities';

const numberWithCommas = (x) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

const CreatorDetailsModal = ({
  isOpen, toggleModal, creatorId, addNotice,
}) => {
  const [title, setTitle] = useState('');
  const [score, setScore] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [description, setDescription] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [themes, setThemes] = useState([]);
  const [themesString, setThemesString] = useState('');
  const [country, setCountry] = useState('');
  const [genre, setGenre] = useState('');
  const [language, setLanguage] = useState('');
  const [industry, setIndustry] = useState('');
  if (creatorId === null || typeof creatorId === 'undefined') {
    toggleModal();
    addNotice({
      message: 'Sorry, there is a problem with the data we got for this creator.',
      type: 'error',
    });
  }
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen === true && creatorId !== null && typeof creatorId !== 'undefined') {
      setLoading(true);
      axios({
        method: 'get',
        url: '/api/portal/research/get-tubular-creator-details',
        params: {
          creatorId,
        },
      }).then((res) => {
        if (res.data && res.data.success && res.data.creatorDetails) {
          setTitle(res.data.creatorDetails.title || '');
          setThumbnailUrl(res.data.creatorDetails.thumbnail_url || '');
          setDescription(res.data.creatorDetails.description || '');
          setAccounts(res.data.creatorDetails.accounts || []);
          setThemes(res.data.creatorDetails.topics || []);
          const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
          setCountry(res.data.creatorDetails.country ? regionNames.of(res.data.creatorDetails.country) : '');
          setGenre((res.data.creatorDetails.genre && res.data.creatorDetails.genre.title) ? res.data.creatorDetails.genre.title : '');
          setLanguage(getLanguageNameFromIso(res.data.creatorDetails.language));
          setIndustry((res.data.creatorDetails.industry && res.data.creatorDetails.industry.title) ? res.data.creatorDetails.industry.title : '');
        } else {
          toggleModal();
          addNotice({
            message: res.data.error || 'Unexpected error happened.',
            type: 'error',
          });
        }
        setLoading(false);
      }).catch((error) => {
        toggleModal();
        addNotice({
          message: error.message || 'Unexpected error happened.',
          type: 'error',
        });
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const youtubeAccount = accounts.find(account => account.platform === 'youtube');
      if (youtubeAccount && youtubeAccount.influencer_score) {
        setScore(numberWithCommas(Number(youtubeAccount.influencer_score).toFixed()));
      } else {
        setScore(numberWithCommas(Number(accounts[0].influencer_score || 0).toFixed()));
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (themes && themes.length > 0) {
      let resultingString = '';
      for (let i = 0; i < themes.length; i += 1) {
        resultingString = `${resultingString}${i !== 0 ? ', ' : ''}${themes[i].title}`;
      }
      setThemesString(resultingString);
    }
  }, [themes]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
      size="lg"
      style={{
        maxWidth: '900px',
        fontSize: '105%',
        fontStretch: '90%',
      }}
    >
      <div className="modal__header mb-0">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
        <h4 className="text-modal modal__title mb-1">
          {loading ? 'Loading data...' : title}
        </h4>
        {!loading && (
          <span>
            {`Influencer Score: ${score}`}
          </span>
        )}
        <hr className="my-2" style={{ height: '3px' }} />
      </div>
      <div className="modal__body mt-0" style={{ fontSize: '100%' }}>
        {!loading
          ? (
            <Row>
              <Col
                xs="2"
              >
                <img
                  style={{
                    width: '100%',
                  }}
                  src={thumbnailUrl}
                  alt={title}
                />
              </Col>
              <Col
                xs="10"
              >
                <Row>
                  <Col xs="12">
                    <span>
                      {description}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs="6" className="d-flex">
                    <VideoIcon />
                    <span
                      style={{
                        margin: '0 5px 0 5px',
                      }}
                    >
                      Platforms:
                    </span>
                    {/* eslint-disable-next-line array-callback-return,consistent-return */}
                    {accounts.map((account) => {
                      switch (account.platform) {
                        case 'youtube':
                          return (
                            <a
                              href={account.account_url}
                              target="_blank"
                              rel="noreferrer noopener"
                              style={{ marginLeft: '5px' }}
                              key={`${creatorId}-${account.platform}`}
                            >
                              <YoutubeIcon
                                title={account.platform}
                              />
                            </a>
                          );
                        case 'instagram':
                          return (
                            <a
                              href={account.account_url}
                              target="_blank"
                              rel="noreferrer noopener"
                              style={{ marginLeft: '5px' }}
                              key={`${creatorId}-${account.platform}`}
                            >
                              <InstagramIcon
                                title={account.platform}
                              />
                            </a>
                          );
                        case 'twitter':
                          return (
                            <a
                              href={account.account_url}
                              target="_blank"
                              rel="noreferrer noopener"
                              style={{ marginLeft: '5px' }}
                              key={`${creatorId}-${account.platform}`}
                            >
                              <TwitterIcon
                                title={account.platform}
                              />
                            </a>
                          );
                        case 'facebook':
                          return (
                            <a
                              href={account.account_url}
                              target="_blank"
                              rel="noreferrer noopener"
                              style={{ marginLeft: '5px' }}
                              key={`${creatorId}-${account.platform}`}
                            >
                              <FacebookIcon
                                title={account.platform}
                              />
                            </a>
                          );
                        case 'tiktok':
                          return (
                            <a
                              className="research-modal-tiktok-icon"
                              href={account.account_url}
                              target="_blank"
                              rel="noreferrer noopener"
                              style={{ marginLeft: '5px' }}
                              key={`${creatorId}-${account.platform}`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 2859 3333"
                                shapeRendering="geometricPrecision"
                                textRendering="geometricPrecision"
                                imageRendering="optimizeQuality"
                                style={{ height: '20px', width: '24px' }}
                              >
                                <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
                              </svg>
                            </a>
                          );
                        default: return null;
                      }
                    })
                  }
                  </Col>
                  <Col xs="6" className="d-flex">
                    <BookOpenPageVariantIcon />
                    <span
                      style={{
                        margin: '0 5px 0 5px',
                      }}
                    >
                      Themes:
                    </span>
                    <span>
                      {themesString}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="6" className="d-flex">
                    <FlagVariantIcon />
                    <span
                      style={{
                        margin: '0 5px 0 5px',
                      }}
                    >
                      Country:
                    </span>
                    <span>
                      {country}
                    </span>
                  </Col>
                  <Col xs="6" className="d-flex">
                    <TagIcon />
                    <span
                      style={{
                        margin: '0 5px 0 5px',
                      }}
                    >
                      Genre:
                    </span>
                    <span>
                      {genre}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="6" className="d-flex">
                    <EarthIcon />
                    <span
                      style={{
                        margin: '0 5px 0 5px',
                      }}
                    >
                      Language:
                    </span>
                    <span>
                      {language}
                    </span>
                  </Col>
                  <Col xs="6" className="d-flex">
                    <BriefcaseIcon />
                    <span
                      style={{
                        margin: '0 5px 0 5px',
                      }}
                    >
                      Industry:
                    </span>
                    <span>
                      {industry}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>)
          : (
            <div className="text-center w-100">
              <Spinner color="primary" size="lg" />
            </div>
          )
        }
      </div>
      <ButtonToolbar className="modal__footer mt-4">
        <Button color="primary" onClick={toggleModal}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

CreatorDetailsModal.propTypes = {
  addNotice: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  creatorId: PropTypes.string,
};

CreatorDetailsModal.defaultProps = {
  isOpen: false,
  creatorId: null,
};

export default withNotice(CreatorDetailsModal);
