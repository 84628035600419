import React, { useState, useEffect, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { Col, Container, Row, Card, CardBody, Spinner } from 'reactstrap';
import withNotice from '../../../App/store/with-notice';
import WVFormatter from '../../../../shared/helpers/WVFormatter';
// import ButtonWithSpinner from '../../../shared/components/ButtonWithSpinner';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';


// import { ConsoleIcon } from 'mdi-react';


export default withNotice(({ addNotice }) => {
  console.log();
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});
  const loadStats = async () => {
    setLoading(true);
    try {
      const data = await axios({
        method: 'get',
        url: '/api/portal/global/get-hubspot-rate-api',
      });
      console.log(data.data);
      if (data.data.success === true) {
        setStats(data.data.data);
      } else {
        setStats({});
      }
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to get HS',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  useEffect(async () => {
    await loadStats();
  }, []);
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    useFlex: true,
    withPagination: true,
    manualPageSize: [20, 40],
    withSearchEngine: true,
    sortBy: 'name',
    direction: 'desc',
  };

  const columnTransform = useMemo(() => {
    console.log('match');
    return [{
      Header: () => ('Property Name'),
      accessor: d => d.name,
      id: 'Name',
      width: 400,
      disableSortBy: true,
      disableFilters: true,
    }, {
      Header: 'Value',
      id: 'value',
      accessor: r => (r.value || ''),
      Cell: ({ value }) => (Number.isNaN(Number(value)) ? value : WVFormatter.formatIntNumber(Number(value || 0))),

      width: 300,
    }];
  }, []);


  return (
    <Container>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3 className="il-color-deep-blue font-weight-bold">Hubspot API</h3>
                </Col>
              </Row>
              <hr className="mt-3 mb-4" />
              <h4 className=" font-weight-bold">Rate API Stats</h4>
              <hr className="mt-3 mb-4" />
              {loading ? (<div className="text-center"><Spinner /></div>) : null}
              {!loading ? (
                <ReactTableBase
                  key="filesIO"
                  columns={columnTransform}
                  data={Object.keys(stats).map(k => ({ name: k, value: stats[k] }))}
                  tableConfig={tableConfig}
                />) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});


// export default withNotice(connect(mapStateToProps)(PushNotifications));
