/* eslint react/prop-types: 0 */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-param-reassign */
import React, { PureComponent, useMemo } from 'react';
// import PropTypes from 'prop-types';
// import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';

// import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { Spinner, Col, Container, Row, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
// import IconButton from '@material-ui/core/IconButton';
// import TuneIcon from 'mdi-react/TuneIcon';

import withNotice from '../../../App/store/with-notice';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
// import MatTableForRedux from '../../../../shared/tables/materialTable/components/MatTableForRedux';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import TableCellContextMenu from '../../../../shared/components/table/TableCellContextMenu';

import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
import UserPropertyType from './components/custom';
import InfluencersInList from './components/custom/InfluencersInList';
import VanityURLsInList from './components/custom/VanityURLsInList';
import DealsInList from './components/custom/DealsInList';
import OfferInList from './components/custom/OfferInList';

import PromoCodesFilters from './components/custom/PromoCodesFilters';

const transformations = {
  betterhelpHasOffers: (record) => {
    let i = 0;
    while (record.params[`domains.${i}`]) {
      if (record.params[`domains.${i}`] === 'betterhelp') {
        return 'Yes';
      }
      i += 1;
    }

    return 'No';
  },
  influenceLogicHasOffers: (record) => {
    let i = 0;
    while (record.params[`domains.${i}`]) {
      if (record.params[`domains.${i}`] === 'influencelogic') {
        return 'Yes';
      }
      i += 1;
    }

    return 'No';
  },
};

// const downloadCsv = [
//   {
//     label: 'Code',
//     func: record => record.params.code,
//   },
//   {
//     label: 'BetterHelp HasOffers',
//     func: record => transformations.betterhelpHasOffers(record),
//   },
//   {
//     label: 'InfluenceLogic HasOffers',
//     func: record => transformations.influenceLogicHasOffers(record),
//   },
// ];

const downloadCsv = [
  {
    label: 'Code',
    func: record => record.code,
  },
  {
    label: 'BetterHelp HasOffers',
    func: record => record.offersOnHO.find(d => (d.indexOf('betterhelp:') !== -1)) ? 'Yes' : 'No',
  },
  {
    label: 'InfluenceLogic HasOffers',
    func: record => record.offersOnHO.find(d => (d.indexOf('influencelogic:') !== -1)) ? 'Yes' : 'No',
  },
  // {
  //   label: 'offerNameOnBetterhelp',
  //   func: record => record.offerNameOnBetterhelp,
  // },
  // {
  //   label: 'offerNameOnInfluenceLogic',
  //   func: record => record.offerNameOnInfluenceLogic,
  // },
  {
    label: 'offersOnHO',
    func: record => record.offersOnHO.join(','),
  },
  {
    label: 'influencerId',
    func: record => record.influencerId,
  },
  {
    label: 'influencerName',
    func: record => record.influencerName,
  },
  {
    label: 'offer',
    func: record => record.offer,
  },
  {
    label: 'offerId',
    func: record => record.offerId,
  },
  {
    label: 'offerName',
    func: record => record.offerName,
  },
  {
    label: 'deals',
    func: record => record.deals.map(d => d.dealName).join(','),
  },
  {
    label: 'vanityURLs',
    func: record => record.vanityURLs.map(d => d.vanityURL).filter((x, i, a) => a.indexOf(x) === i).join(','),
  },
  // {
  //   label: 'vanityURLType',
  //   func: record => record.vanityURLType,
  // },
  // {
  //   label: 'vanityURL',
  //   func: record => record.vanityURL,
  // },
  // {
  //   label: 'vanityURLId',
  //   func: record => record.vanityURLId,
  // },

  // {
  //   label: 'dealName',
  //   func: record => record.dealName,
  // },
  // {
  //   label: 'dealId',
  //   func: record => record.dealId,
  // },
];

const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

class PromoCodesReport extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      isFetching: false,
      isDownloadingCSV: false,
      showFilters: false,
      array: [],
      filteredArray: [],
      // dataArrayVersion: '1',
      filters: {
        code: '',
      },
    };


    this.handleDoDownloadCSV = this.doDownloadCSV.bind(this);
    this.handleFiltersChanged = this.filtersChanged.bind(this);

    this.tableRef = React.createRef();
    this.csvButtonRef = React.createRef();
  }

  componentDidMount() {
    document.title = 'IL Admin: Promo Codes';
  }

  async doDownloadCSV() {
    this.setState({ isDownloadingCSV: true });
    await this.fetchEverything();
    this.csvButtonRef.current.generateCSVReport();

    this.setState({ isDownloadingCSV: false });
  }

  filtersChanged(updatedFilters) {
    if (updatedFilters.influencers && updatedFilters.influencers.length) {
      updatedFilters.influencers = updatedFilters.influencers.map(item => item.value).join(',');
    } else {
      delete updatedFilters.influencers;
    }

    if (updatedFilters.offers && updatedFilters.offers.length) {
      updatedFilters.offers = updatedFilters.offers.map(item => item.value).join(',');
    } else {
      delete updatedFilters.offers;
    }

    if (!updatedFilters.searchValue) {
      delete updatedFilters.searchValue;
    }

    // eslint-disable-next-line arrow-body-style
    this.setState((prevState) => {
      const s = {
        ...prevState,
      };

      // eslint-disable-next-line object-shorthand
      s.filters = updatedFilters;
      return s;
    });
  }

  promoCodesToRecords(data) {
    const ret = [];

    const offerNamesDictionary = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const dataItem of data) {
      let influencersLength = 0;
      let vanityURLsLength = 0;
      let dealsLength = 0;
      let offersLength = 0;
      let offersOnHOLength = 0;

      while (dataItem.populated[`influencers.${influencersLength}`]) {
        influencersLength += 1;
      }
      while (dataItem.populated[`vanityURLs.${vanityURLsLength}`]) {
        vanityURLsLength += 1;
      }
      while (dataItem.populated[`deals.${dealsLength}`]) {
        dealsLength += 1;
      }
      while (dataItem.populated[`offers.${offersLength}`]) {
        offersLength += 1;
      }
      while (dataItem.params[`offersOnHO.${offersOnHOLength}`]) {
        offersOnHOLength += 1;
      }
      console.error('offersOnHOLength', offersOnHOLength);

      const getoffersOnHO = (filterByOrderId = null) => {
        const ohsret = [];
        for (let offersOnHON = 0; offersOnHON < offersOnHOLength; offersOnHON += 1) {
          const offfersOnHOName = dataItem.params[`offersOnHO.${offersOnHON}`];
          if (!filterByOrderId) {
            ohsret.push(offfersOnHOName);
          } else if (offfersOnHOName.indexOf(`:${filterByOrderId}:`) !== -1) {
            ohsret.push(offfersOnHOName);
          }
        }
        return ohsret;
      };

      console.error(getoffersOnHO());

      const relatedVanityURLs = (influencerId) => {
        const vuret = [];
        for (let vanityURLN = 0; vanityURLN < vanityURLsLength; vanityURLN += 1) {
          const innerData = dataItem.populated[`vanityURLs.${vanityURLN}`].params;
          if (innerData.influencer && innerData.influencer === influencerId) {
            console.error(dataItem);
            vuret.push(innerData);
          }
        }
        return vuret;
      };

      const relatedDeals = (influencerId) => {
        const deret = [];
        for (let dealN = 0; dealN < dealsLength; dealN += 1) {
          const innerData = dataItem.populated[`deals.${dealN}`].params;
          if (innerData['ilDealData.influencer'] && innerData['ilDealData.influencer'] === influencerId) {
            deret.push(innerData);
          }
        }
        return deret;
      };

      const offerNameByItsId = (offerId) => {
        for (let offerN = 0; offerN < offersLength; offerN += 1) {
          if (dataItem.populated[`offers.${offerN}`].params._id === offerId) {
            return dataItem.populated[`offers.${offerN}`].params.name;
          }
        }
        return null;
      };

      console.log(influencersLength, this);

      for (let influencerN = 0; influencerN < influencersLength; influencerN += 1) {
        const influencerId = dataItem.params[`influencers.${influencerN}`];
        let influencerName = dataItem.populated[`influencers.${influencerN}`].params.first_name;
        influencerName += ' ';
        influencerName += dataItem.populated[`influencers.${influencerN}`].params.last_name;
        const vanityURLs = relatedVanityURLs(influencerId);
        const deals = relatedDeals(influencerId);
        // const deals = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const vanityURL of vanityURLs) {
          try {
            ret.push({
              influencerId,
              influencerName,
              offerNameOnInfluenceLogic: dataItem.params['offerNames.influencelogic'],
              offerNameOnBetterhelp: dataItem.params['offerNames.betterhelp'],
              offer: vanityURL.offer,
              offerId: vanityURL.offerId,
              offerName: offerNameByItsId(vanityURL.offer),
              vanityURLType: vanityURL.type,
              vanityURL: vanityURL.localPath,
              vanityURLId: vanityURL._id,
              isOnBetterHelp: transformations.betterhelpHasOffers(dataItem),
              isOnInfluenceLogic: transformations.influenceLogicHasOffers(dataItem),
              code: dataItem.params.code,
              offersOnHO: getoffersOnHO(vanityURL.offerId),
            });
          } catch (e) {
            console.error(e);
          }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const deal of deals) {
          try {
            ret.push({
              influencerName,
              influencerId,
              offerNameOnInfluenceLogic: dataItem.params['offerNames.influencelogic'],
              offerNameOnBetterhelp: dataItem.params['offerNames.betterhelp'],
              // deal: deal,
              offer: deal['ilDealData.offer._id'],
              offerName: deal['ilDealData.offer.name'],
              offerId: deal['ilDealData.offer.tuneId'],
              dealName: deal?.dealname,
              dealId: deal?._id,
              isOnBetterHelp: transformations.betterhelpHasOffers(dataItem),
              isOnInfluenceLogic: transformations.influenceLogicHasOffers(dataItem),
              code: dataItem.params.code,
              offersOnHO: getoffersOnHO(deal['ilDealData.offer.tuneId']),
            });

            if (deal['ilDealData.offer._id'] && deal['ilDealData.offer.name'] && !offerNamesDictionary[deal['ilDealData.offer._id']]) {
              offerNamesDictionary[deal['ilDealData.offer._id']] = deal['ilDealData.offer.name'];
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
    }

    // try to fill empty offer names
    // eslint-disable-next-line no-restricted-syntax
    for (const item of ret) {
      if (item.offer && !item.offerName && offerNamesDictionary[item.offer]) {
        item.offerName = offerNamesDictionary[item.offer];
      }
    }

    const groupedBy = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const item of ret) {
      const groupedById = `${item.influencerId}-${item.code}-${item.offer}`;
      if (!groupedBy[groupedById]) {
        groupedBy[groupedById] = item;
        groupedBy[groupedById].deals = [];
        groupedBy[groupedById].vanityURLs = [];
      }

      if (item.dealId) {
        groupedBy[groupedById].deals.push({
          dealId: item.dealId,
          dealName: item.dealName,
        });
      }

      if (item.vanityURLId) {
        groupedBy[groupedById].vanityURLs.push({
          vanityURLId: item.vanityURLId,
          vanityURLType: item.vanityURLType,
          vanityURL: item.vanityURL,
        });
      }
    }

    return Object.values(groupedBy);
    // return ret;
  }

  async fetchEverything() {
    const {
      resources,
    } = this.props;

    const resource = resources.find(r => r.id === 'PromoCode');
    const api = new ApiClient();
    const query = new URLSearchParams();

    query.set('perPage', 100000);
    // query.set('filters._id', '643754a355297f6dd093ccf2');

    await new Promise((res) => {
      api.resourceAction({
        actionName: 'list',
        resourceId: resource.id,
        params: query,
      }).then((response) => {
        const data = response.data.records;


        const converted = this.promoCodesToRecords(data);
        console.log('converted', converted);

        this.setState({ filteredArray: converted });

        console.error('listActionReponse', data);
        res();
      }).catch((e) => {
        console.error(e);
        res();
      });
    });
  }

  render() {
    const {
      filteredArray,
      isFetching,
      filters,
    } = this.state;

    const {
      resources,
    } = this.props;

    const resource = resources.find(r => r.id === 'PromoCodeItem');
    const action = resource.resourceActions.find(r => r.name === 'list');

    if (isFetching) {
      return (
        <div className="text-center"><Spinner color="primary" size="lg" /></div>
      );
    }

    return (
      <Container>
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            { title: 'Promo Codes', path: null },
          ]}
          isBackButton={false}
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="il-color-deep-blue font-weight-bold">Promo Codes</h3>
                  </Col>
                </Row>

                <div style={{ position: 'relative', zIndex: 9 }}>
                  <PromoCodesFilters
                    changed={updatedFilters => this.handleFiltersChanged(updatedFilters)}
                    downloadCSV={this.handleDoDownloadCSV}
                  />
                </div>

                <div style={{ display: 'none' }}>
                  <DownloadCSVButton
                    records={filteredArray}
                    className="mt-2 hidden"
                    fileName="promocodes.csv"
                    csvArrayColumns={downloadCsv}
                    ref={this.csvButtonRef}
                  >
                    Download CSV
                  </DownloadCSVButton>
                </div>

                <Row>
                  <MatTableList
                    action={action}
                    resource={resource}
                    filters={filters}
                    UserPropertyType={UserPropertyType}
                    date={new Date()}
                    sortBy="code"
                    direction="asc"
                    useFlex
                    showTitle={false}
                    isResizable={false}
                    noActions
                    columnsSettings={{
                      code: {
                        width: 150,
                      },
                      _id: {
                        width: 150,
                      },
                    }}
                    customColumns={[
                      {
                        id: 'searchField',
                        Header: () => 'Code',
                        accessor: () => 'code',
                        Cell: p => useMemo(() => {
                          let code = '';
                          if (p.row && p.row.original && p.row.original.populated && p.row.original.populated.promoCode) {
                            // eslint-disable-next-line prefer-destructuring
                            code = p.row.original.populated.promoCode.params.code;
                          }
                          return (
                            <strong>{ code }</strong>
                          );
                        }, [p.row.original.id]),
                        className: 'align-self-center',
                        width: 320,
                      },
                      {
                        Header: 'Creators',
                        accessor: () => null,
                        id: 'creators',
                        Cell: record => (
                          <div style={{ paddingRight: '10px' }}>
                            <InfluencersInList
                              record={record.row.original}
                            />
                          </div>
                        ),
                        className: 'align-middle',
                        disableSortBy: true,
                        width: 250,
                      },
                      {
                        Header: 'Offer',
                        accessor: () => null,
                        id: 'offer',
                        Cell: record => (
                          <div style={{ paddingRight: '3px' }}>
                            <OfferInList
                              record={record.row.original}
                            />
                          </div>
                        ),
                        className: 'align-middle',
                        disableSortBy: true,
                        width: 120,
                      },
                      {
                        Header: 'BetterHelp HasOffers',
                        accessor: () => null,
                        id: 'betterhelp',
                        Cell: p => useMemo(() => {
                          let there = 'No';
                          if (p.row && p.row.original && p.row.original.params) {
                            let offersOnHOLength = 0;
                            while (p.row.original.params[`offersOnHO.${offersOnHOLength}`]) {
                              if (p.row.original.params[`offersOnHO.${offersOnHOLength}`].indexOf('betterhelp') !== -1) {
                                there = 'Yes';
                              }
                              offersOnHOLength += 1;
                            }
                          }

                          return (
                            <span>{ there }</span>
                          );
                        }, [p.row.original.id]),
                        className: 'align-middle',
                        disableSortBy: true,
                        width: 200,
                      },
                      {
                        Header: 'InfluenceLogic HasOffers',
                        accessor: () => null,
                        id: 'influencelogic',
                        Cell: p => useMemo(() => {
                          let there = 'No';
                          if (p.row && p.row.original && p.row.original.params) {
                            let offersOnHOLength = 0;
                            while (p.row.original.params[`offersOnHO.${offersOnHOLength}`]) {
                              if (p.row.original.params[`offersOnHO.${offersOnHOLength}`].indexOf('influencelogic') !== -1) {
                                there = 'Yes';
                              }
                              offersOnHOLength += 1;
                            }
                          }

                          return (
                            <span>{ there }</span>
                          );
                        }, [p.row.original.id]),
                        className: 'align-middle',
                        disableSortBy: true,
                        width: 200,
                      },
                      {
                        Header: 'Deals',
                        accessor: () => null,
                        id: 'deals',
                        Cell: record => (
                          <div style={{ paddingRight: '3px' }}>
                            <DealsInList
                              record={record.row.original}
                            />
                          </div>
                        ),
                        className: 'align-middle',
                        disableSortBy: true,
                        width: 120,
                      },
                      {
                        Header: 'Vanity URLs',
                        accessor: () => null,
                        id: 'vanityURLs',
                        Cell: record => (
                          <div style={{ paddingRight: '3px' }}>
                            <VanityURLsInList
                              record={record.row.original}
                            />
                          </div>
                        ),
                        className: 'align-middle',
                        disableSortBy: true,
                        width: 120,
                      },
                      // VanityURLsInList
                      // {
                      //   Header: 'Vanity URLs',
                      //   accessor: () => null,
                      //   id: 'vanityURLs',
                      //   Cell: p => useMemo(() => {
                      //     let length = 0;
                      //     if (p.row && p.row.original && p.row.original.populated) {
                      //       while (p.row.original.populated[`vanityURLs.${length}`]) {
                      //         length += 1;
                      //       }
                      //     }

                      //     return (
                      //       <span>{ length }</span>
                      //     );
                      //   }, [p.row.original.id]),
                      //   className: 'align-middle',
                      //   disableSortBy: true,
                      //   width: 250,
                      // },
                      // {
                      //   id: 'betterhelp',
                      //   Header: () => 'BetterHelp HasOffers',
                      //   accessor: () => 'betterhelp',
                      //   Cell: p => useMemo(() => transformations.betterhelpHasOffers(p.row.original), [p.row.original.id]),
                      //   className: 'align-self-center',
                      //   disableSortBy: true,
                      //   width: 220,
                      // },
                      // {
                      //   id: 'influencelogic',
                      //   Header: () => 'InfluenceLogic HasOffers',
                      //   accessor: () => 'influencelogic',
                      //   Cell: p => useMemo(() => transformations.influenceLogicHasOffers(p.row.original), [p.row.original.id]),
                      //   className: 'align-self-center',
                      //   disableSortBy: true,
                      //   width: 220,
                      // },
                      {
                        Header: '',
                        accessor: () => null,
                        id: 'actions',
                        width: 50,
                        Cell: (p) => {
                          const contextMenuOptions = [
                            {
                              label: 'View Creator Profile',
                              disabled: !p.row.original?.params?.influencer,
                              handler: () => goToPage(`/influencer/profile/${p.row.original?.params?.influencer}`),
                            },
                            {
                              label: 'View Hubpost Contact',
                              disabled: !p.row.original?.populated?.influencer?.params?.hsContactVid,
                              handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.row.original?.populated?.influencer?.params?.hsContactVid}`),
                            },
                            { type: 'divider', label: 'divider1' },
                            {
                              label: 'View Deals with Promo Code',
                              disabled: !p.row.original?.populated?.promoCode?.params?.code,
                              handler: () => goToPage(`/resources/HubilDeal/actions/list?filters.fullSearchName=${encodeURIComponent(p.row.original?.populated?.promoCode?.params?.code)}&filters.brands=${
                                encodeURIComponent(p.row.original?.params?.brandName)}`),
                            },
                            {
                              label: 'View Vanity URLs',
                              disabled: !p.row.original?.populated?.influencer?.params?.email,
                              handler: () => goToPage(`/resources/VanityUrl/actions/list?filters.creatorEmail=${encodeURIComponent(p.row.original?.populated?.influencer?.params?.email)}&filters.offerName=${
                                encodeURIComponent(p.row.original?.populated?.offer?.params?.name)}`),
                            },
                            { type: 'divider', label: 'divider2' },
                            {
                              label: 'View Offer in InfluenceLogic HasOffers',
                              disabled: !p.row.original?.populated?.offer?.params?.tuneId,
                              handler: () => goToPage(`https://influencelogic.hasoffers.com/admin/offers/view/${p.row.original?.populated?.offer?.params?.tuneId}`),
                            },
                            {
                              label: 'Go to BetterHelp HasOffers',
                              handler: () => goToPage('https://betterhelp.hasoffers.com'),
                            },
                          ];
                          return (
                            <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                              <TableCellContextMenu options={contextMenuOptions} />
                            </div>
                          );
                        },
                        disableSortBy: true,
                        className: 'd-flex align-items-center align-middle',
                      },
                    ]}
                    v={2}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

// PromoCodesReport.propTypes = {
//   addNotice: PropTypes.func.isRequired,
// };

const mapStateToProps = state => ({
  rtl: state.rtl,
  
  resources: state.resources,
});

export default withNotice(connect(mapStateToProps)(PromoCodesReport));
