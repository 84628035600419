import React, { useState, useMemo, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Bar as BarC,
  BarChart,
  Tooltip as TooltipOnLine,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import AxisTick from './parts/AxisTick';
import Label from './parts/Label';
import Tooltip from './parts/Tooltip';
import Legend from './parts/Legend';
import { randomColor } from '../../helpers/reports/Charts';

const chartMargins = {
  top: 25,
  right: 0,
  left: 45,
  bottom: 40,
};

const Bar = ({
  charts,
  bars,
  xKey,
  stackId,
  showLabels,
  xLabel,
  col,
}) => {
  const colors = [
    '#e6194B',
    '#0088FE',
    '#13c450',
    '#ffbb28',
    '#911eb4',
    '#000000',
    '#d861b7',
    '#ff8042',
  ];

  const [filledBars, setFilledBars] = useState([]);

  const defaultColors = useMemo(() => {
    const _colors = {};
    filledBars.forEach((bar, i) => { _colors[bar.value] = colors[i] || randomColor(); });
    return _colors;
  }, [filledBars]);

  const [barColors, setBarColors] = useState(defaultColors);

  useEffect(() => {
    setBarColors(defaultColors);
  }, [defaultColors]);

  useEffect(() => {
    const barsData = {};
    Object.keys(charts).forEach((chart) => {
      const chartData = charts[chart].data;
      chartData.forEach((row) => {
        Object.keys(row).forEach((c) => {
          if (c !== 'title' && row[c] > 0) {
            barsData[c] = true;
          }
        });
      });
    });
    setFilledBars(bars.filter(r => barsData[r.value]));
  }, [charts]);

  return (
    <Row>
      <Col>
        <Row>
          {Object.keys(charts).map(c => (
            <Col key={c} className={col} style={{ paddingRight: '0' }}>
              <h4 className="text-center">{charts[c].label}</h4>
              <ResponsiveContainer
                width="100%"
                height={400}
                debounce={1}
              >
                <BarChart
                  width="100%"
                  height="100%"
                  data={charts[c].data}
                  margin={chartMargins}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                  {filledBars.map((bar, i) => barColors[bar.value] && (
                    <BarC
                      key={bar.value}
                      name={bar.label}
                      stackId={stackId}
                      dataKey={bar.value}
                      fill={barColors[bar.value]}
                      isAnimationActive={false}
                      label={(showLabels && i === filledBars.length - 1) ? <Label /> : null}
                    />
                  ))}
                  <XAxis
                    dataKey={xKey}
                    tick={<AxisTick textAnchor="middle" formatLabel={xLabel} dy={16} />}
                    interval="preserveStartEnd"
                    label={charts[c].xLabel ? { value: charts[c].xLabel, dy: 28 } : null}
                  />
                  <YAxis
                    padding={{ top: 8, bottom: 8 }}
                    tick={<AxisTick />}
                    domain={[0, 'dataMax']}
                    interval="preserveStartEnd"
                    allowDecimals={false}
                  />
                  <TooltipOnLine cursor={{ fill: 'transparent' }} content={<Tooltip colors={defaultColors} />} />
                </BarChart>
              </ResponsiveContainer>
            </Col>
          ))}
          <Col
            className={`${col} d-flex align-items-center justify-content-center`}
          >
            <Legend
              keys={filledBars}
              colors={defaultColors}
              opacityColors={barColors}
              setOpacity={setBarColors}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Bar.propTypes = {
  charts: PropTypes.objectOf(PropTypes.any).isRequired,
  bars: PropTypes.arrayOf(PropTypes.any).isRequired,
  xKey: PropTypes.string.isRequired,
  stackId: PropTypes.string,
  showLabels: PropTypes.bool,
  xLabel: PropTypes.func.isRequired,
  col: PropTypes.string.isRequired,
};

Bar.defaultProps = {
  stackId: null,
  showLabels: false,
};

export default Bar;
