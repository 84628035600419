import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

const ContactProfile = ({
  loading,
  contact,
}) => {
  console.log(contact);
  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Hubspot Contact</h2>
      <Card id="section-hubspot-contact">
        <CardBody>
          {!loading && contact && (
            <Row className="mt-3">
              <Col>
                <h5>Name</h5>
              </Col>
              <Col>
                <h5>Email</h5>
              </Col>
              <Col>
                <h5>Company</h5>
              </Col>
              <Col>
                <h5>Accounts Receivable?</h5>
              </Col>
            </Row>
          )}
          {!loading && contact && (
            <hr />
          )}
          {!loading && contact && (
            <Row>
              <Col>
                <div>
                  <strong>{`${contact.firstname || ''} ${contact.lastname || ''}`}</strong>&nbsp;&nbsp;&nbsp;
                  <a
                    href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${contact ? contact.hs_object_id : 0}/`}
                    target="_blank"
                    title="Open Hubspot Contact"
                    rel="noreferrer noopener"
                  >
                    <i className="fab fa-hubspot" style={{fontSize: '1.2em'}}/>
                  </a>
                </div>
              </Col>
              <Col>
                <strong>{contact.email}</strong>
              </Col>
              <Col>
                <strong>{contact.company || '-'}</strong>
              </Col>
              <Col>
                <strong>{contact.accounts_receivable ? 'Yes' : 'No'}</strong>
              </Col>
            </Row>
          )}
          {!loading && !contact && (
            <Row>
              <Col>
                <h5>There is no HubSpot contact attached yet.</h5>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
ContactProfile.propTypes = {
  contact: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
};
ContactProfile.defaultProps = {
  contact: null,
};
export default ContactProfile;
