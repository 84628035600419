import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import SelectAsync from 'react-select/async';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import withNotice from '../../../../../../../App/store/with-notice';
import Alert from '../../../../../../../../shared/easydev2/shared/components/Alert';
import Hook from '../../../../../../../../shared/hooks';

export const modalId = 'NewPriceApprovals';

const NewPriceApprovals = ({ addNotice }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);
  // const [startId, setStartId] = useState(0);
  const [error, setError] = useState(null);

  const { data: dataModal, showModal: isOpen, close } = Hook.useModalHook(modalId);


  useEffect(async () => {
    setName('');
    setDescription('');
    setSelectedBrand(null);
    setError(null);
    // setStartId(null);
    // if (isOpen) {
    //   const response = await axios({
    //     method: 'get',
    //     url: '/api/portal/prospects/get-next-start-id',
    //   });
    //   if (response.data.success) {
    //     setStartId(Number(response.data.startPaId));
    //   }
    // }
  }, [isOpen]);

  if (!dataModal) return false;
  const { reload } = dataModal;
  /* const handleInputChange = (e) => {
    const { editor } = e;
    setDescription(editor.getData());
  }; */
  const loadBrandsOptions = async (inputValue) => {
    const response = await axios({
      method: 'get',
      url: `/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`,
    });
    if (response.data.success) {
      const res = [];
      response.data.brands.forEach((record) => {
        res.push({
          value: record._id,
          label: record.companyName,
          record,
        });
      });
      return res;
    }
    return [];
  };

  const saveCPMPriceApprovalsGroup = () => {
    setError(null);
    const data = {
      name,
      // startId,
      description,
      brand: selectedBrand?.value || null,
    };
    const validationErrors = {};
    if (!selectedBrand) validationErrors.brand = true;
    if (!name) validationErrors.name = true;
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      axios.post('/api/portal/prospects/post-add-cpm-price-approvals-group', { ...data }).then((response) => {
        if (response.data.success) {
          setLoading(false);
          addNotice({
            message: 'Price Approvals has been saved',
            type: 'success',
          });
          reload();
          close();
        } else {
          setLoading(false);
          setError(response.data?.error || 'Unable to save Price Approvals');
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => close()}
      style={{ minWidth: '670px' }}
    >
      <ModalHeader
        toggle={() => close()}
        tag="h4"
        className="modal-title-statistics pl-0"
      >
        New Price Approvals Group
      </ModalHeader>
      <ModalBody>
        {error ? (
          <Alert color="danger" bordered icon><p className="py-2">{error}</p></Alert>
        ) : null}
        <Row>
          <Col>
            <FormGroup>
              <Label>PA Group Name</Label>
              <Input
                type="test"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (e.target.value) {
                    setErrors(er => ({
                      ...er,
                      brand: false,
                    }));
                  }
                }}
              />
              <div className="grey-small-text grey-minus-margin" style={errors.name ? { color: 'red' } : {}}>Required</div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Target Brand</Label>
              <SelectAsync
                cacheOptions
                value={selectedBrand}
                defaultOptions
                loadOptions={loadBrandsOptions}
                onChange={(selected) => {
                  setSelectedBrand(selected);
                  if (selected.value) {
                    setErrors(e => ({
                      ...e,
                      brand: false,
                    }));
                  }
                }}
                placeholder=""
              />
              <div className="grey-small-text grey-minus-margin" style={errors.brand ? { color: 'red' } : {}}>Required</div>
            </FormGroup>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <FormGroup>
              <Label>Starting Price Approvals Group ID</Label>
              <Input
                type="number"
                value={startId}
                onChange={(e) => {
                  setStartId(e.target.value);
                  if (e.target.value) {
                    setErrors(er => ({
                      ...er,
                      startId: false,
                    }));
                  }
                }}
              />
              <div className="grey-small-text grey-minus-margin" style={errors.startId ? { color: 'red' } : {}}>Required</div>
            </FormGroup>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <FormGroup>
              <Label>Description</Label>
              <Input
                type="textarea"
                name="itemText"
                activeClass="form-control w-100"
                value={description}
                onChange={(e) => { setDescription(e.target.value); }}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={saveCPMPriceApprovalsGroup} disabled={loading} style={{ marginRight: '10px' }}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>
        <Button className="modal_cancel btn-sm" onClick={() => close()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

NewPriceApprovals.propTypes = {
  addNotice: PropTypes.func.isRequired,
};


export default withNotice(NewPriceApprovals);
