import React from 'react';
import PropTypes from 'prop-types';

const Label = ({
  x, y, width, stroke, value,
}) => (value ? (
  <text x={(x - 6) + (width / 2)} y={y} dy={-6} dx={6} fill={stroke} fontSize={13} textAnchor="middle" fontWeight="bold">
    {value}
  </text>
) : null);

Label.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  stroke: PropTypes.number,
  value: PropTypes.number,
};

Label.defaultProps = {
  x: null,
  y: null,
  width: 0,
  stroke: 0,
  value: null,
};

export default Label;
