import React, { } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import CreatorBrandApprovalsWidget from '../../../../../shared/components/prospecting/CreatorBrandApprovalsWidget';


const BrandApprovals = ({
  influencer,
}) => (
  <div>
    <h2 className="section-title mt-0 mb-4">Compliance</h2>
    <Card>
      <CardBody>
        <Row>
          <Col>
            { influencer && <CreatorBrandApprovalsWidget influencerId={influencer.id} /> }
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>
);

BrandApprovals.propTypes = {
  influencer: PropTypes.objectOf(PropTypes.any),
};
BrandApprovals.defaultProps = {
  influencer: null,
};
export default BrandApprovals;
