import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ApiClient, { axios } from 'ApiClient';
import { unflatten } from 'flat';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, ButtonToolbar, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import SelectAsync from 'react-select/async';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withNotice from '../../../App/store/with-notice';
import ButtonWithSpinner from '../../../../shared/components/ButtonWithSpinner';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import CurrencyField from '../../../../shared/components/form/CurrencyField';
import WVFormatter from '../../../../shared/helpers/WVFormatter';
import WVValidator from '../../../../shared/helpers/WVValidator';
import BillComMFARefreshComponent from './components/BillComMFARefreshComponent';
import DealValidationModal from './components/DealValidationModal';

class CreatorPayment extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiClient();

    this.state = {
      chartOfAccount: null,
      payable: null,
      accountsPayable: null,
      influencer: null,
      employee: null,
      offer: null,
      amount: 0,
      mfaChallenged: false,
      errors: {},
      loading: {
        fetchPayable: false,
        sendPaymentForm: false,
      },
      modals: {
        sendPayment: false,
        dealValidation: false,
      },
      validationErrors: [],
      chartsOfAccounts: [],
    };
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.payableId) {
      this.fetchPayable();
    }
  }

  onSendPaymentButton = () => {
    this.setState({ loading: { sendPaymentForm: true } });
    if (!this.validateErrors()) { return; }

    // Deal Validation
    let dealIsValid = false;
    const { payable } = this.state;
    if (payable?.params?.deal) {
      axios.get(`/api/portal/finance/get-deal-validation-check?id=${payable.params.deal || ''}`).then((response) => {
        if (response.data.success) {
          this.setState({
            modals: {
              dealValidation: !response.data.data.isValid,
            },
            validationErrors: response.data.data.validationData,
          });
          dealIsValid = response.data.data.isValid;
        }
      });
    } else {
      dealIsValid = true;
    }
    this.setState({ loading: { sendPaymentForm: false } });

    if (dealIsValid) {
      this.toggleSendPaymentModal();
    }
  }

  onMFAReauthenticationFinished = () => {
    this.setState({ mfaChallenged: false });
  }

  challengeMFAReauthentication = () => {
    this.setState({ mfaChallenged: true });
  }

  fetchPayable = async () => {
    const { payableId } = this.props.match.params;

    this.setState({ loading: { fetchPayable: true } });

    const response = await this.api.recordAction({
      resourceId: 'Payable',
      recordId: payableId,
      actionName: 'show',
    });

    if (!response.data || !response.data.record || !response.data.record.populated) {
      this.setState({ loading: { fetchPayable: false } });
      return;
    }

    const payable = response.data.record;
    const { populated } = payable;

    let accountsPayable;
    let influencer;
    let employee;
    let offer;

    if (populated.accountsPayable) {
      accountsPayable = {
        value: populated.accountsPayable.params._id,
        label: populated.accountsPayable.params.name,
        record: populated.accountsPayable,
      };
    }
    if (populated.influencer) {
      influencer = {
        value: populated.influencer.params._id,
        label: `${populated.influencer.params.email} - ${populated.influencer.params.first_name} ${populated.influencer.params.last_name}`,
        record: populated.influencer,
      };
    }
    if (populated.employee) {
      employee = {
        value: populated.employee.params._id,
        label: populated.employee.params.email,
        record: populated.employee,
      };
    }
    if (populated.offer) {
      offer = {
        value: populated.offer.params._id,
        label: populated.offer.params.name,
        record: populated.offer,
      };
    }
    if (populated.offer) {
      offer = {
        value: populated.offer.params._id,
        label: populated.offer.params.name,
        record: populated.offer,
      };
    }
    const chartsOfAccountsRes = await this.api.client.get('/api/portal/finance/get-charts-of-accounts');
    console.log(populated.influencer?.params['payment.billCom.chartOfAccount'], 'some');
    this.setState({
      payable,
      accountsPayable,
      influencer,
      employee,
      offer,
      amount: payable.params.ilPayoutAmount,
      loading: { fetchPayable: false },
      chartsOfAccounts: chartsOfAccountsRes.data?.data.map(d => ({ ...d, label: d.title })) || [],
      chartOfAccount: populated.influencer?.params['payment.billCom.chartOfAccount'],
    });
  }

  processPayment = () => {
    if (!this.validateErrors()) { return; }

    this.setState({
      loading: { sendPayment: true },
    });

    const {
      payable,
      accountsPayable,
      influencer,
      employee,
      offer,
      amount,
      chartOfAccount,
    } = this.state;
    axios({
      method: 'post',
      url: '/api/portal/finance/send-creator-payment',
      data: {
        accountsPayableId: accountsPayable.value,
        influencerId: influencer.value,
        employeeId: employee.value,
        offerId: offer.value,
        amount,
        payableId: payable ? payable.params._id : null,
        chartOfAccount,
      },
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);
      if (response.data.success !== true) {
        // Z: request BillCom MFA session
        if (response.data.error === 'ILBillComErrorMFAInvalid') {
          this.setState({
            loading: { sendPayment: false },
            modals: { sendPayment: false },
          });

          this.challengeMFAReauthentication();
          return;
        }
        // END Z;

        this.processPaymentError(response.data.error);
        return;
      }

      // SUCCESS
      this.setState({
        accountsPayable: null,
        influencer: null,
        employee: null,
        offer: null,
        amount: 0,
        loading: { sendPayment: false },
        modals: { sendPayment: false },
      });

      this.props.addNotice({
        message: 'Your payment has been successfully sent!',
        type: 'success',
      });
    }).catch((error) => {
      // console.log('CATCH ERROR: ', error);
      this.processPaymentError(error.message);
    });
  }

  validateErrors() {
    const {
      accountsPayable,
      influencer,
      employee,
      offer,
      amount,
    } = this.state;

    const errors = {};
    if (!accountsPayable || !accountsPayable.value) {
      errors.accountsPayable = 'Please select Accounts Payable.';
    }
    if (!influencer || !influencer.value || !influencer.record) {
      errors.influencer = 'Please select Influencer.';
    } else if (influencer && influencer.record) {
      const influencerData = unflatten(influencer.record.params);
      const { stripe, billCom } = influencerData.payment;
      if (WVValidator.isEmpty(stripe.accountId) && WVValidator.isEmpty(billCom.accountId)) {
        errors.influencer = 'Selected Influencer doesn\'t have payment options available.';
      }
    }

    if (!employee || !employee.value) {
      errors.employee = 'Please select Employee.';
    }
    if (!offer || !offer.value) {
      errors.offer = 'Please select Offer.';
    }

    if (!amount || !parseFloat(amount) || parseFloat(amount) < 1 || parseFloat(amount) > 5000) {
      errors.amount = 'Amount should be in a range $1 - $5000';
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  processPaymentError(error) {
    const message = error || 'Something went wrong!';

    this.setState({
      loading: { sendPayment: false },
      modals: { sendPayment: false },
    });

    this.props.addNotice({
      message,
      type: 'error',
    });
  }

  // METHODS
  accountsPayableLoadOptions = async (inputValue) => {
    const records = await this.api.searchRecords({
      resourceId: 'AccountsPayable',
      query: inputValue,
    });
    return records.map(record => ({
      value: record.params._id,
      label: record.params.name,
      record,
    }));
  }

  influencersLoadOptions = async (inputValue) => {
    const records = await this.api.searchRecords({
      resourceId: 'Influencer',
      query: inputValue,
    });
    return records.map(record => ({
      value: record.params._id,
      label: `${record.params.email} - ${record.params.first_name ? record.params.first_name : ''} ${record.params.last_name ? record.params.last_name : ''}`,
      record,
    }));
  }

  employeesLoadOptions = async (inputValue) => {
    const records = await this.api.searchRecords({
      resourceId: 'Employee',
      query: inputValue,
    });
    return records.map(record => ({
      value: record.params._id,
      label: record.params.email,
      record,
    }));
  }

  offersLoadOptions = async (inputValue) => {
    const { influencer } = this.state;
    if (!influencer) { return []; }

    const response = await axios({
      method: 'get',
      url: '/api/portal/influencers/offers',
      params: {
        searchQuery: inputValue,
        influencerId: influencer.value,
      },
    });
    // console.log(response);
    if (response.data.success) {
      return response.data.offers.map(record => ({
        value: record._id,
        label: record.name,
        record,
      }));
    }

    return [];
  }
  //
  handleChangeSelect = (name, selected) => {
    const { errors } = this.state;
    delete errors[`${name}`];

    this.setState({
      [`${name}`]: selected,
      errors,
    });
  }

  handleAmountChange = (event, value) => {
    const { errors } = this.state;
    delete errors.amount;

    this.setState({
      amount: value,
      errors,
    });
  }

  toggleSendPaymentModal = () => {
    this.setState(prevState => ({ modals: { sendPayment: !prevState.modals.sendPayment } }));
  }

  renderFormControls() {
    const {
      accountsPayable,
      influencer,
      employee,
      offer,
      amount,
      loading,
      errors,
      chartOfAccount,
      chartsOfAccounts,
    } = this.state;

    if (loading.fetchPayable) {
      return null;
    }
    console.log(influencer);

    return (
      <section>
        <FormGroup>
          <Label>Accounts Payable *</Label>
          <SelectAsync
            cacheOptions
            value={accountsPayable}
            defaultOptions
            loadOptions={this.accountsPayableLoadOptions}
            onChange={selected => this.handleChangeSelect('accountsPayable', selected)}
            placeholder="Please Select Accounts Payable"
          />
          {errors.accountsPayable && (<span className="text-danger">{errors.accountsPayable}</span>)}
        </FormGroup>
        <Row className="align-items-end">
          <Col>
            <FormGroup>
              <Label>Influencer *</Label>
              <SelectAsync
                cacheOptions
                value={influencer}
                defaultOptions
                loadOptions={this.influencersLoadOptions}
                onChange={selected => this.handleChangeSelect('influencer', selected)}
                placeholder="Please Select Influencer"
              />
              {errors.influencer && (<span className="text-danger">{errors.influencer}</span>)}
            </FormGroup>
          </Col>
          <Col className="pl-1" xs="auto">
            {this.renderPaymentIcon()}
          </Col>
        </Row>
        <FormGroup>
          <Label>Employee *</Label>
          <SelectAsync
            cacheOptions
            value={employee}
            defaultOptions
            loadOptions={this.employeesLoadOptions}
            onChange={selected => this.handleChangeSelect('employee', selected)}
            placeholder="Please Select Employee"
          />
          {errors.employee && (<span className="text-danger">{errors.employee}</span>)}
        </FormGroup>
        <FormGroup>
          <Label>Offer *</Label>
          <SelectAsync
            key={influencer ? influencer.value : ''}
            cacheOptions
            value={offer}
            defaultOptions
            loadOptions={this.offersLoadOptions}
            onChange={selected => this.handleChangeSelect('offer', selected)}
            placeholder="Please Select Offer"
          />
          {errors.offer && (<span className="text-danger">{errors.offer}</span>)}
        </FormGroup>
          <FormGroup>
            <Label>Chart of account {influencer?.record?.params['payment.countryCode'] ? `(${influencer?.record?.params['payment.countryCode']})` : ''}
            </Label>
            <Select
              value={chartsOfAccounts.find(method => method.value === chartOfAccount)}
              options={chartsOfAccounts}
              onChange={e => this.setState({ chartOfAccount: e.value })}
            />
            {errors.chartOfAccount && (<span className="text-danger">{errors.chartOfAccount}</span>)}
          </FormGroup>
        <FormGroup>
          <Label>Amount *</Label>
          <CurrencyField
            placeholder="Amount"
            defaultValue={amount}
            onBlur={this.handleAmountChange}
            invalid={errors.amount !== undefined}
          />
          {errors.amount && (<span className="text-danger">{errors.amount}</span>)}
        </FormGroup>
        <ButtonToolbar className="form__button-toolbar mt-4">
          <ButtonWithSpinner
            color="primary"
            onClick={this.onSendPaymentButton}
            loading={this.state.loading.sendPaymentForm}
          >
            Send Payment
          </ButtonWithSpinner>
        </ButtonToolbar>
      </section>
    );
  }

  renderPaymentIcon() {
    const { influencer } = this.state;
    if (!influencer || !influencer.record) { return null; }

    const influencerData = unflatten(influencer.record.params);
    const { stripe, billCom } = influencerData.payment;

    if (stripe.isDefault && !WVValidator.isEmpty(stripe.accountId)) {
      return (
        <span className="text-success">
          <FontAwesomeIcon className="mb-3" icon={['fab', 'cc-stripe']} size="3x" />
        </span>
      );
    } else if (billCom.isDefault && !WVValidator.isEmpty(billCom.accountId)) {
      return (
        <img src="/images/payments/billComGreen.png" className="img-fluid mb-3" style={{ width: '44px' }} alt="Bill.com" />
      );
    }

    return null;
  }

  render() {
    const {
      influencer,
      amount,
      mfaChallenged,
      loading,
      modals,
    } = this.state;

    return (
      <Container className="dashboard">
        <Breadcrumb
          links={[
            { title: 'Finance', path: '/finance/account-payable-report' },
            { title: 'Send Payment', path: null },
          ]}
          isBackButton
        />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Send Payment</h5>
                  <h5 className="subhead">Send a payment to a creator</h5>
                </div>
                {loading.fetchPayable && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
                { this.renderFormControls() }
                { /* Confirm payment modal */ }
                <Modal isOpen={modals.sendPayment} toggle={this.toggleSendPaymentModal} style={{ minWidth: '200px' }} className="delete-modal">
                  <ModalHeader>Send Payment</ModalHeader>
                  <ModalBody>
                    <p>
                      Please confirm that you wish to send{' '}
                      <span className="font-weight-bold">{WVFormatter.formatCurrency(amount)}</span>
                      {' '} to {' '}
                      <span className="font-weight-bold">{influencer ? influencer.record.params.email : ''}</span>
                      . After you complete this step, you will no longer be able to cancel the payment.
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <ButtonWithSpinner
                      type="button"
                      color="primary"
                      onClick={this.processPayment}
                      loading={loading.sendPayment}
                    >
                      <span>Send Payment</span>
                    </ButtonWithSpinner>{' '}
                    <Button color="secondary" onClick={this.toggleSendPaymentModal}>Cancel</Button>
                  </ModalFooter>
                </Modal>
                { /* MFA modals */
                  mfaChallenged &&
                  <BillComMFARefreshComponent
                    addNotice={this.props.addNotice}
                    onAuthenticationFinished={this.onMFAReauthenticationFinished}
                  />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <DealValidationModal
          showModal={this.state.modals.dealValidation}
          setShowModal={(value) => { this.setState({ modals: { dealValidation: value } }); }}
          validationData={this.state.validationErrors}
        />
      </Container>
    );
  }
}

CreatorPayment.propTypes = {
  // history: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  // resources: state.resources,
  // accountPayable: state.accountPayable.accountPayable,
  loading: state.accountPayable.loading,
  // logs: state.accountPayable.logs.payables,
});

export default withNotice(connect(mapStateToProps)(CreatorPayment));
