import React from 'react';
import { unflatten } from 'flat';

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const params = unflatten(props.record?.params);
  let string = '';
  if (params?.itemsArray?.length > 0) {
    for (let i = 0; i < params.itemsArray.length; i += 1) {
      let { sku } = params.itemsArray[i];
      if (sku && params.itemsArray[i].quantity) {
        sku = `${sku} (${params.itemsArray[i].quantity})`;
      }
      if (sku) {
        if (string) {
          string = `${string}, ${sku}`;
        } else string = `${sku}`;
      }
    }
  }

  return (<span style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }} >{string}</span>);
};
