import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { unflatten } from 'flat';
import { axios } from 'ApiClient';

const YearSigned = React.memo(({
  p,
}) => {
  const allowEdit = useSelector((state) => state.taxFormEditRoleCheck.isAllowedToEdit);
  const currentYear = new Date().getFullYear();
  const yearsOptions = [];
  for (let i = 0; i < 6; i++) {
    const year = (currentYear - i).toString();
    yearsOptions.push({ value: year, label: year });
  }

  const { updateRecordFunction } = p;

  const rowData = p.row.original.params;
  const val = rowData.yearSigned;
  // console.log(val, rowData, 'valueApp');
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setValue(val);
  }, [rowData._id, val]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option.value);
    try {
      const data = await axios.post('/api/portal/finance/post-update-property-on-tax-form', {
        ids: [rowData._id],
        values: {
          yearSigned: option.value,
        },
      });
      const record = unflatten(data?.data?.recordPopulated);
      updateRecordFunction(rowData._id, { yearSigned: record.params.yearSigned });
      setValue(record.params.yearSigned);
    } catch (e) {
      console.log(e);
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };
  return (
    <div style={{ width: '210px' }}>
      <div style={{ width: '150px', float: 'left' }} >
        {
          allowEdit ? (
            <Select
            value={yearsOptions.filter(option => Number(option.value) === Number(value))}
            options={yearsOptions}
            onChange={handleChange}
            className="statusBox"
            isDisabled={disabled}
            menuPlacement="auto"
            menuPortalTarget={document.body}
          />
          ) : (
            <p>{value}</p>
          )
        }
        {error ? (<span className="text-danger">{error}</span>) : null}
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.p, a1.p));

YearSigned.propTypes = {
  p: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default YearSigned;
