import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import withNotice from '../../../../../App/store/with-notice';

const bonusDealTypeOptons = [
  { value: '', label: 'Not Set', isDisabled: true },
  { value: 'Pending', label: 'Pending' },
  { value: 'Paid', label: 'Paid' },
];
const dealTypeOptons = [
  { value: '', label: 'Not Set', isDisabled: true },
  { value: 'Active', label: 'Active' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Expired', label: 'Expired' },
];

const DealStatusOption = ({
  deal, statusChange,
}) => {
  const [updatingDealStatus, setUpdatingDealStatus] = useState(false);
  const [dealState, setDealState] = useState({});

  useEffect(async () => {
    if (deal.params) {
      setDealState(deal.params);
    } else {
      setDealState(deal);
    }
  }, [deal]);

  const changeDealStatusHandler = (e) => {
    setUpdatingDealStatus(true);
    statusChange(deal, { cpa_status: e.label });
  };

  return (
    <div style={{ minWidth: '120px', marginBottom: 0, float: 'left' }}>
      <Select
        className="deal-status"
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Deal Status"
        value={{ value: dealState.dealTerms?.cpa_status, label: dealState.dealTerms?.cpa_status }}
        onChange={v => changeDealStatusHandler(v)}
        options={dealState.dealTerms?.type_of_deal === 'Bonus' ? bonusDealTypeOptons : dealTypeOptons}
        isDisabled={updatingDealStatus}
      />
    </div>
  );
};

DealStatusOption.propTypes = {
  deal: PropTypes.objectOf(PropTypes.any).isRequired,
  statusChange: PropTypes.func.isRequired,
};

export default withNotice(DealStatusOption);
