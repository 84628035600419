import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import Select from 'react-select';
import {
  Button,
  ButtonToolbar,
  Modal,
} from 'reactstrap';
import { connect } from 'react-redux';
import ProgressBar from '../../../../../../../../shared/helpers/ProgressBarWithObject';
// import withNotice from '../../../../../../App/store/with-notice';
import { addNotice } from '../../../../../../../App/store/store';

const UpdateAllModal = ({
  isOpen,
  closeFunc,
  prospectsIds,
  setVersion,
  dispatch,
}) => {
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [loading, selLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ value: false, label: 'No, do not overwrite' });

  const prospectContainerRefresh = async () => {
    selLoading(true);
    if (!isOpen) return false;
    try {
      await axios.post('/api/portal/prospects/post-fetch-data', {
        prospectsIds,
        identity,
        overwriteChannelNameAndURL: selectedOption.value,
      }).then((response) => {
        // console.log(response);
        if (response.data.success) {
          dispatch(addNotice({
            message: `Successfully refreshed ${response.data.numberOfProspects} of ${response.data.numberOfProspects} items.`,
            type: 'success',
          }));
        } else {
          dispatch(addNotice({
            message: `Successfully refreshed ${response.data.successfulRefreshed} of ${response.data.numberOfProspects} items.`,
            type: 'error',
            duration: 10,
          }));
        }
      });
      setVersion((new Date()).toString());
      setSelectedOption({ value: false, label: 'No, do not overwrite' });
      closeFunc();
    } catch (e) {
      console.log(e);
    }
    selLoading(false);
    return true;
  };

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => !loading && closeFunc()}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
      size="lg"
      style={{
        maxWidth: '900px',
        fontSize: '105%',
        fontStretch: '90%',
      }}
    >
      <div className="modal__header mb-0">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => !loading && closeFunc()} />
        <h4 className="text-modal modal__title mb-1">
          Refresh Statistics
        </h4>
      </div>
      <div className="modal__body mt-3 mb-3">
        {loading
          ? (
            <div>
              <ProgressBar
                afterFunction={() => true}
                identity={identity}
                topic="prospects"
                isShow
              />
            </div>)
          : (
            <>
              <p>
                The prospect&apos;s channel titles, URLs and email may have been edited already and can possibly be updated when refreshing data from Tubular and ListenNotes.
                <br />
                Would you like to allow overwriting of this data?
              </p>
              <div className="w-100 mt-2">
                <Select
                  name="select"
                  value={selectedOption}
                  options={
                    [
                      { value: false, label: 'No, do not overwrite' },
                      { value: true, label: 'Yes, overwrite' },
                    ]
                  }
                  onChange={setSelectedOption}
                  styles={colourStyles}
                />
              </div>
            </>
          )
        }
      </div>
      <ButtonToolbar className="modal__footer mt-3">
        <Button
          disabled={loading}
          color="primary"
          onClick={prospectContainerRefresh}
        >
          Refresh
        </Button>
        <Button
          disabled={loading}
          color="secondary"
          onClick={closeFunc}
        >
          Cancel
        </Button>
      </ButtonToolbar>
    </Modal>
  );
};


UpdateAllModal.propTypes = {
  prospectsIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  closeFunc: PropTypes.func.isRequired,
  setVersion: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default connect(() => ({}))(UpdateAllModal);
