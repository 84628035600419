
import func from '../../../../../../shared/hooks';

const ActionProperties = () => [
  {
    initialState: {
      Yes: true,
      No: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'isPilot',
  },
  {
    initialState: {
      Yes: true,
      No: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'inactive',
  },
  {
    initialState: {
      Yes: true,
      No: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'displayInUi',
  },

];

export default ActionProperties;
