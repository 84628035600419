import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import React from 'react';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import SpyLogInButton from '../../../../../shared/components/SpyLogInButton';
import ConnectAgentButton from '../../../Agents/AgentBillCom/connectButton';
import DisConnectAgentButton from '../../../Agents/AgentBillCom/disConnectButton';

const Actions = ({ record, meta }) => {
  const influencer = unflatten(record);
  console.log(influencer);
  return (
    <>
      <div style={{ minWidth: '100px' }} >
        <Link to={`/influencer/profile/${influencer._id}`} target="_blank">
          <Tooltip title="Creator Profile">
            <IconButton size="small">
              <i className="fa fa-info-circle" style={{ fontSize: '0.8em' }} />
            </IconButton>
          </Tooltip>
        </Link>
        <SpyLogInButton
          userId={influencer._id}
          url="/api/portal/influencers/spy-login"
        />
        {influencer.hsContactVid ? (
          <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${influencer.hsContactVid}/`} target="_blank" rel="noreferrer">
            <Tooltip title="Creator Profile">
              <IconButton size="small">
                <i className="fab fa-hubspot" style={{ fontSize: '0.8em' }} />
              </IconButton>
            </Tooltip>
          </a>
        ) : null}
        <ConnectAgentButton
          agent={{ params: influencer, id: influencer._id }}
          type="creator"
        />
        <DisConnectAgentButton
          agent={{ params: influencer, id: influencer._id }}
          meta={meta}
          type="creator"
        />
      </div>
    </>
  );
};
Actions.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Actions;
