import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import {
  updateDeal,
  getInfluencer,
} from '../../../../../../redux/reducers/admin/InfluencerProfileReducer';
import withNotice from '../../../../../App/store/with-notice';

const DealStageOption = ({
  deal, dealStageOptons, addNotice,
}) => {
  const [updatingDealStage, setUpdatingDealStage] = useState(false);
  const [dealState, setDealState] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newSelectedDealStage, setNewSelectedDealStage] = useState(null);
  const [dealStageNotes, setDealStageNotes] = useState('');
  const dispatch = useDispatch();

  useEffect(async () => {
    if (deal.params?.pipeline) {
      setDealState(deal.params);
    } else {
      setDealState(deal);
    }
    if (deal.params?.dealTerms.closed_lost_reason) {
      setDealStageNotes(deal.params.dealTerms.closed_lost_reason);
      setNewSelectedDealStage({value: deal.params.dealstage})
    }
  }, [deal]);

  const handleInputChange = (e) => {
    // Enforce a max note length of 5000 characters
    let textValue = e.target.value;
    if(textValue.length > 5000) {
      addNotice({
        message: 'Please, keep note length down to 5,000 characters.',
        type: 'warning',
      });
      textValue = textValue.substring(0, 5000);
    }

    setDealStageNotes(textValue);
  };

  const resetDealStageNotes = () => {
    setIsDialogOpen(false);
    setUpdatingDealStage(false);
  };

  const changeDealStagesHandler = async (e) => {
    if (e && e.value) {
      const mainPipeLine = dealStageOptons.find(op => op.pipeLineName === 'Main Pipeline');
      const lostOrPassed = mainPipeLine.stages.find(mp => mp.label === "Deal Lost or Passed");
      setUpdatingDealStage(true);

      // special logic for closedOrPassed deals
      if (lostOrPassed.value === e.value) {
        if (isDialogOpen === false) {
          setIsDialogOpen(true);
          setNewSelectedDealStage(e);
        } else if (isDialogOpen === true && newSelectedDealStage !== null) {
          dispatch(updateDeal(deal.id, {
            dealstage: newSelectedDealStage.value,
            dealStageNotes,
          }));
          setTimeout(() => {
            dispatch(getInfluencer(deal.params.ilDealData.influencer));
          }, 1000);
          setIsDialogOpen(false);
        }
      } else {
        // handle regular deal
        dispatch(updateDeal(deal.id, {
          dealstage: e.value,
        }));
      }
      
    } else {
      addNotice({
        message: 'Deal stage not found.',
        type: 'error',
      });
    }
  };

  const dealStageValue = dealStageOptons.find(s => s.id === dealState.pipeline)?.stages.filter(option => option.value === dealState.dealstage) || '';
  if(dealStageValue === '' || dealStageValue.length === 0) return null;

  return (
    <>
      <div style={{ display: 'flex', marginBottom: 0}}>
        <div style={{ float: 'left', minWidth: '140px' }}>
          <Select
            className="deal-stage"
            menuPlacement="auto"
            menuPortalTarget={document.body}
            placeholder="Deal Stage"
            value={dealStageValue}
            onChange={v => changeDealStagesHandler(v)}
            options={dealStageOptons.find(s => s.id === dealState.pipeline)?.stages}
            isDisabled={updatingDealStage}
          />
        </div>
        {
          (dealStageValue[0]?.label === 'Deal Lost or Passed') &&
            <div style={{ float: 'right', paddingTop: '6px', paddingRight: '5px', paddingLeft: '5px' }}>
              <Tooltip title="IL Notes">
                <IconButton
                  size="small"
                  onClick={() => setIsDialogOpen(true)}
                >
                  <FontAwesomeIcon icon={[dealStageNotes !== '' ? 'fas' : 'far', 'file-alt']} />
                </IconButton>
              </Tooltip>
            </div>
        }
      </div>
      <Modal
        isOpen={isDialogOpen}
        toggle={() => setIsDialogOpen(!isDialogOpen) }
        style={{ minWidth: '670px' }}
      >
        <ModalHeader
          toggle={() => {
            setIsDialogOpen(!isDialogOpen);
          }}
          tag="h4"
          className="modal-title-statistics pl-0"
        >
          {'Notes'}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
              <Label>Reason for setting the deal stage to "Deal Lost / Passed"</Label>
              <Input
                type="textarea"
                id="notes"
                name="notes"
                onChange={e => handleInputChange(e)}
                value={dealStageNotes}
              />
            </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ButtonToolbar className="modal__footer">
          <Button
            className="modal_ok btn-sm"
            color="primary"
            onClick={() => changeDealStagesHandler(newSelectedDealStage)}
            style={{ marginRight: '10px' }}
          >
            Save
          </Button>
          <Button
            className="modal_cancel btn-sm"
            onClick={() => resetDealStageNotes()}
          >
            Cancel
          </Button>
        </ButtonToolbar>
      </Modal>
    </>
  );
};

DealStageOption.propTypes = {
  dealStageOptons: PropTypes.arrayOf(PropTypes.any).isRequired,
  deal: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(DealStageOption);
