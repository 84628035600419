import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
// import { axios } from 'ApiClient';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import { clearFilterProperty } from '../../../../../../../redux/reducers/TableReducerDefault';
import { modalId as newPriceApprovalsModal } from './Modal/NewPriceApprovals';
import Hook from '../../../../../../../shared/hooks';

const Actions = ({ downloadCsv }) => {
  const reload = Hook.useReloadTableHook();
  const [showFilters, setShowFilters] = useState(getCookie('cpmInsertionOrdersShowFilters') === 'true');
  const dispatch = useDispatch();
  const [search, setSearch] = Hook.useTableFilterHookString('search');
  const [approvalStatusFilters, setApprovalStatus] = Hook.useTableFilterHookCheckbox('status', {
    New: true,
    'In Review': true,
    Signed: true,
  });
  const [showNewPriceApprovalsModal] = Hook.useShowModalHook(newPriceApprovalsModal);
  useEffect(() => {
    setCookie('cpmInsertionOrdersShowFilters', showFilters.toString(), 35);
  }, [showFilters]);
  const resetFilters = () => {
    dispatch(clearFilterProperty(['status', 'search']));
  };
  return (
    <div>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Name, Brand, Description"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => showNewPriceApprovalsModal({ reload })}
              >
                New Price Approvals Group
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => downloadCsv()}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row className="mt-3">
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Status
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="new"
                  label="New"
                  value={approvalStatusFilters.New || false}
                  onChange={event => setApprovalStatus(event, 'New')}
                />
              </FormGroup>
              <FormGroup style={{ marginBottom: '10px' }}>
                <CheckBoxField
                  name="inreview"
                  label="In Review"
                  value={approvalStatusFilters['In Review'] || false}
                  onChange={event => setApprovalStatus(event, 'In Review')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="signed"
                  label="Signed"
                  value={approvalStatusFilters.Signed || false}
                  onChange={event => setApprovalStatus(event, 'Signed')}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
Actions.propTypes = {
  // onSearchChange: PropTypes.func.isRequired,
  // setVersion: PropTypes.func.isRequired,
  /* searchValue: PropTypes.string.isRequired,
  setApprovalStatusFilters: PropTypes.func.isRequired,
  approvalStatusFilters: PropTypes.objectOf(PropTypes.any).isRequired, */
  downloadCsv: PropTypes.func.isRequired,
  // selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Actions;
