
import func from '../../../../../../shared/hooks';

const ActionProperties = ({ tagsSuggestions }) => [
  {
    initialState: {
      Yes: true,
      No: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'taxForm',
  },

  {
    initialState: { tags: [] },
    func: func.getFromUrlTagsfunction,
    funcToUrl: func.getToUrlTagsfunction,
    options: tagsSuggestions.map(t => ({ id: t.name, name: t.name })),
    filterName: 'tags',
  },

];

export default ActionProperties;
