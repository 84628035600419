/* eslint-disable react/prop-types */
import { unflatten } from 'flat';
import React from 'react';

const DealStage = ({ record, mainPipelineStages }) => {
  const r = unflatten(record);
  let dealStageName = '';

  try {
    dealStageName = mainPipelineStages[r.params?.dealObject?.dealstage];
  } catch (Error) {
    console.log('Error', Error);
  }

  return (
    <div>
      { dealStageName }
    </div>
  );
};

export default DealStage;
