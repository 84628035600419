import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import StyledButton from '../../../../../../shared/components/components/ui/styled-button';
import {
  showDeleteModalDialog, deleteFormulaForm, getFormulas,
} from '../../../../../../redux/reducers/admin/ResearchReducer';
// import WVValidator from '../../../../../../shared/helpers/WVValidator';
import withNotice from '../../../../../App/store/with-notice';

const DeleteModalDialog = ({
  showDeleteDialog,
  dispatch,
  id,
}) => {
  const [loading, setLoading] = useState(false);

  const closeFormDialog = () => {
    setLoading(false);
    dispatch(showDeleteModalDialog(false));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    // console.log(id);
    if (id) {
      dispatch(deleteFormulaForm(id)).then(() => {
        dispatch(getFormulas());
        closeFormDialog();
        setLoading(false);
      });
    }
  };

  return (
    <Modal
      isOpen={showDeleteDialog}
      toggle={closeFormDialog}
    >
      <div className="modal__header">
        {/* <h4 className="text-modal  modal__title">Delete Formula</h4> */}
      </div>
      <div className="modal__body finance-form">
        <form onSubmit={handleSubmit}>
          {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
          {!loading && (
            <div>
              <h4>Are you sure you want to delete the selected Formula?</h4>
            </div>
          )}
        </form>
      </div>
      <div className="modal__footer">
        <StyledButton
          type="button"
          className="btn-danger"
          onClick={handleSubmit}
        >
          <i className="icomoon-save" />
          <span className="btn-text">Delete</span>
        </StyledButton>
        &nbsp;&nbsp;
        <StyledButton
          type="button"
          className="is-primary"
          onClick={closeFormDialog}
        >
          <i className="icomoon-save" />
          <span className="btn-text">Cancel</span>
        </StyledButton>
      </div>
    </Modal>
  );
};


DeleteModalDialog.propTypes = {
  showDeleteDialog: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
};

DeleteModalDialog.defaultProps = {
  id: null,
};

const mapStateToProps = state => ({
  adminUser: state.session,
  showDeleteDialog: state.ResearchReducer.showDeleteDialog,
});

export default withNotice(connect(mapStateToProps)(DeleteModalDialog));
