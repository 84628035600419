import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { 
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
 } from 'reactstrap';
 import AccessComponent from '@/shared/components/auth/AccessComponent';
 import InfluencersForm, { modalId } from '../Modal/InfluencersForm';
 import ImportTagsModal from '../../../../Influencer/components/ImportTags';
 import AddInfluencerByUser from '../../../../Influencer/components/AddInfluencerById';
 import Hook from '@/shared/hooks';
 import ProgressBar from '@/shared/helpers/ProgressBarWithObject';
import { useSelector } from 'react-redux';


const DashboardActions = ({ categories, downloadCsv }) => {
  const reload = Hook.useReloadTableHook();
  const { showError, showSuccess } = Hook.useNoticeHook();
  const [ showInfluencerModal ] = Hook.useShowModalHook(modalId);
  // const [influencerDialogVisible, setInfluencerDialogVisible] = useState(false);
  const [modalInfluencer, setModalInfluencer] = useState(false);
  const [modal, setModal] = useState(false);
  const [importTagsIsOpen, setImportTagsIsOpen] = useState(false);
  const [isImportingTags, setIsImportingTags] = useState(false);
  const [isImportTagsProcess, setIsImportTagsProcess] = useState(false);
  const [loadingInfluencer, setLoadingInfluencer] = useState(false);

  const [loading, setLoading] = useState(false);

  const identity = useSelector(state => state.session._id);

  const processImportTags = () => {
    setIsImportingTags(true);
    setIsImportTagsProcess(true);
  };

  const fillInfluencersCall = () => {
    setLoading(true);
    setModal(true);
    axios.post('/api/portal/finance/post-fill-influencers').then((result) => {
      console.log(result);
      setModal(false);
      reload();
      showSuccess('Affiliates were added/updated successfully');
    }).catch(() => {
      setLoading(false);
      setModal(false);
      showError('There was an error with loading Creators data. Check out console to see more information.');
    });
  };
  const saveInfluencersCall = (tuneId) => {
    setLoadingInfluencer(true);
    axios.post('/api/portal/finance/post-save-influencer', { tuneId }).then((res) => {
      setLoadingInfluencer(false);
      if (res.data.success) {
        setModalInfluencer(false);
        reload();
        showSuccess('Affiliate was added/updated successfully');
      } else {
        showError(res.data.error);
      }
    }).catch(() => {
      setLoadingInfluencer(false);
      showError('There was an error with loading Creators data. Check out console to see more information.');
    });
  };
  const openImportTags = () => {
    setImportTagsIsOpen(true);
  };
  const toggleImportTags = () => {
    if (!isImportingTags) {
      setImportTagsIsOpen(false);
      setIsImportTagsProcess(false);
    }
  };
  const onImportTagsComplete = () => {
    setIsImportingTags(false);
  };
  return (
    <>
      <UncontrolledButtonDropdown className="w-100">
        <DropdownToggle
          id="actionsMenu"
          caret
          color="primary"
          style={{
            padding: '4px 25px',
            height: '42px',
          }}
        >
          Actions
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => showInfluencerModal({
            modalTitle: "New Creator",
            categories: categories.map(value => ({ value: value.id, label: value.name }))
          })}>New Creator</DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            onClick={openImportTags}
          >
            Import Tags
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => setModalInfluencer(true)} disabled={loadingInfluencer} >Refresh Specific Creator</DropdownItem>
          <DropdownItem onClick={fillInfluencersCall} disabled={loading} >Refresh All Creators</DropdownItem>
          <DropdownItem divider />
          <AccessComponent hideWhenTeam={['Auditors']}>
            <DropdownItem onClick={() => downloadCsv()}>Download CSV</DropdownItem>
          </AccessComponent>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      <InfluencersForm />
      <ImportTagsModal
        isOpen={importTagsIsOpen}
        loading={isImportingTags}
        uploading={isImportTagsProcess}
        processImportTags={processImportTags}
        showResults={onImportTagsComplete}
        toggle={toggleImportTags}
      />
      <AddInfluencerByUser
        errorFunc={error => showError(error)}
        saveAffiliate={saveInfluencersCall}
        modalInfluencer={modalInfluencer}
        hideModal={() => setModalInfluencer(false)}
        loadingInfluencer={loadingInfluencer}
      />
      
      <Modal
        isOpen={modal}
        toggle={() => true}
        size="sm"
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Refreshing Creators</h4>
        </div>
        <div className="modal__body finance-form">
          {!loading ? <div>All Creators were added successfully</div> : (
            <div>
              <h5 className="mb-3">This process can take up to several minutes</h5>
              <ProgressBar topic="influencersWebSocket" identity={identity} />
            </div>)
          }
        </div>
      </Modal>
    </>
  );
};
DashboardActions.propTypes = {

};


export default DashboardActions;
