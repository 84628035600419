/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ApiClient from 'ApiClient';

const roles = {
  owner: 'Owner',
  auditor: 'Auditor',
};

export default ({ record, updateRecordFunction }) => {
    const [selected, setSelected ] = useState(null)
    const [loading, setLoading ] = useState(false)
    const rolesMap = Object.keys(roles).map(key => ({
      value: key,
      label: roles[key],
    }));
    useEffect(() => {
      console.log(record.params.role);
      setSelected( record.params.role ? {
        value: record.params.role,
        label: roles[record.params.role],
      } : null)
    }, [record.params.role])

    const handleSelectChange = (selected) => {
      const api = new ApiClient();
      setSelected(selected);
      setLoading(true);
      const value = selected ? selected.value : '';
      if (value === '') {
        return false;
      }
  
      const data = new FormData();
      data.append('role', value);
      api.recordAction({
        resourceId: 'BrandUser',
        recordId: record.id,
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((e) => {
        setLoading(false);
        console.log(e);
        if(e.status === 200) updateRecordFunction(record.id, { role: selected.value });

      });
    }
    // console.log("LSD isEditable", isEditable)
    return (
      <div style={{ minWidth: '100px' }}>
        <Select
          isDisabled={loading}
          value={selected}
          options={rolesMap}
          onChange={handleSelectChange}
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
      </div>
    );
}
