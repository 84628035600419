import React, { useState, forwardRef, useEffect } from 'react';
import moment from 'moment';
// import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { Row, Col, Button, InputGroup, InputGroupAddon } from 'reactstrap';
import CalendarIcon from 'mdi-react/CalendarIcon';

// eslint-disable-next-line react/prop-types
const DatePickerCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <div className="form-group">
    <InputGroup size="md">
      <input
        type="text"
        className="form-control form-control-sm"
        value={value}
        onClick={onClick}
        onChange={onChange}
        style={{
          border: '1px solid #ced4da',
          borderRadius: '5px',
        }}
        ref={ref}
      />
      <InputGroupAddon
        addonType="append"
        style={{
          padding: '3px 0 7px 7px',
        }}
      >
        <CalendarIcon
          className="date-picker-icon"
          onClick={onClick}
        />
      </InputGroupAddon>
    </InputGroup>
  </div>
));

const FilterPrevious = ({ setValue, order }) => {
  const [endDate, setEndDate] = useState(moment().subtract(1, 'month').endOf('month').toDate());
  const [showCustom, setShowCustom] = useState(false);
  const [selectedButton, setSelectedButton] = useState(order[0]);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').startOf('month').toDate());
  const selectDateRange = (range) => {
    let dateFrom;
    let dateTo;
    switch (range) {
      case 'current_month':
        setShowCustom(false);
        dateFrom = moment().startOf('month').toDate();
        dateTo = moment().endOf('day').toDate();
        break;
      case 'last_month':
        setShowCustom(false);
        dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
        dateTo = moment().subtract(1, 'month').endOf('month').toDate();
        break;
      case 'year_to_date':
        setShowCustom(false);
        dateFrom = moment().startOf('Year').toDate();
        dateTo = moment().endOf('month').toDate();
        break;
      case 'custom':
        setShowCustom(true);
        dateFrom = moment().startOf('Year').toDate();
        dateTo = moment().endOf('day').toDate();
        break;
      default:
        dateFrom = moment().startOf('Year').toDate();
        dateTo = moment().endOf('month').toDate();
        break;
    }
    setEndDate(dateTo);
    setStartDate(dateFrom);
    setValue('startDate', dateFrom);
    setValue('endDate', dateTo);
    setSelectedButton(range);
  };
  useEffect(() => selectDateRange(order[0]), []);
  const getText = () => {
    let dateText = '';
    if (
      moment(startDate).format('M') === moment(endDate).format('M') &&
      moment(startDate).format('D') === moment(endDate).format('D') &&
      moment(startDate).format('YYYY') === moment(endDate).format('YYYY')) {
      dateText = moment(startDate).format('MMM D, YYYY');
    } else {
      dateText = `${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate) > moment() ? moment().format('MMM D, YYYY') : moment(endDate).format('MMM D, YYYY')}`;
    }
    return `${dateText} (ET)`;
  };
  const buttonClass = 'btn-sm mr-0 time-period-filter-buttons-extended';
  const getStyleForObject = (item) => {
    if (order.at(0) === item) return { borderRadius: '5px 0 0 5px', borderRight: '0' };
    if (order.at(-1) === item) return { borderRadius: '0 5px 5px 0', borderColor: '#4A69F2' };
    return { borderRadius: '0', borderRight: '0' };
  };
  const items = {
    last_month: (
      <Button
        key="b1"
        onClick={() => selectDateRange('last_month')}
        className={selectedButton === 'last_month' ? `active ${buttonClass}` : buttonClass}
        style={getStyleForObject('last_month')}
      >
        Last Month
      </Button>),
    current_month: (
      <Button
        key="b2"
        onClick={() => selectDateRange('current_month')}
        className={selectedButton === 'current_month' ? `active ${buttonClass}` : buttonClass}
        style={getStyleForObject('current_month')}
      >
        Current Month
      </Button>),
    custom: (
      <Button
        onClick={() => selectDateRange('custom')}
        // color="primary"
        className={selectedButton === 'custom' ? `active ${buttonClass}` : buttonClass}
        style={getStyleForObject('custom')}
      >
        Custom
      </Button>),
  };
  // const firstItem = items[order[0]];
  // firstItem.style = ;
  return (
    <div>
      <Row style={{ minWidth: '300px' }}>
        <Col lg="auto">
          {order.map(o => items[o])}
        </Col>
        <Col lg="auto">
          {showCustom ? (
            <Row>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date) || setValue('startDate', date)}
                    dateFormat="MM/yyyy"
                    maxDate={new Date()}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    className="form-control"
                    showMonthYearPicker
                    customInput={<DatePickerCustomInput />}
                  />
                </div>
              </Col>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(moment(date).endOf('month').toDate()) || setValue('endDate', moment(date).endOf('month').toDate())}
                    dateFormat="MM/yyyy"
                    maxDate={new Date()}
                    placeholderText="End Date"
                    dropDownMode="select"
                    className="form-control"
                    showMonthYearPicker
                    customInput={<DatePickerCustomInput />}
                  />
                </div>
              </Col>
            </Row>) : null
          }
        </Col>
      </Row>
      <Row style={{ minWidth: '300px' }}>
        <Col>
          <h5 style={{ color: 'black' }} className="bold-text">{getText()}</h5>
        </Col>
      </Row>
    </div>
  );
};
FilterPrevious.propTypes = {
  setValue: PropTypes.func.isRequired,
  order: PropTypes.arrayOf(PropTypes.string),
};
FilterPrevious.defaultProps = {
  order: ['last_month', 'current_month', 'custom'],
};
export default FilterPrevious;
