import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import ApiClient from 'ApiClient';
import { Row, Col, Label, FormGroup, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import formater from '../../../../shared/helpers/WVFormatter';
import Item from '../StatItem';
import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';

const SummaryReport = ({
  addNotice, reportData, loading, generateReport, isSaved, initialData, fetched,
}) => {
  const [loadingAdvertiser, setLoaingAdvertiser] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [cspOptions, setCspOptions] = useState([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedCsps, setSelectedCsps] = useState([]);

  useEffect(() => {
    const api = new ApiClient();
    setLoaingAdvertiser(true);
    api.client.get('/api/portal/finance/get-advertisers').then((resp) => {
      setAdvertisers(resp.data.advertisers.map(e => ({ value: e._id, label: `${e.companyName}` })));
      setLoaingAdvertiser(false);
    }).catch(() => {
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load advertisers, Check out console to see more information.',
        type: 'error',
      });
    });
    api.client.get('/api/portal/finance/get-deal-owners').then((resp) => {
      setCspOptions(resp.data.dealOwners.map(e => ({ value: e._id, label: `${e.firstName} ${e.lastName}` })));
    }).catch((e) => {
      console.log(e);
      setLoaingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load deal Owners, Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);

  useEffect(() => {
    if (cspOptions.length > 0 && initialData?.selectedCSP?.length > 0) {
      setSelectedCsps(initialData?.selectedCSP.map(cspId => cspOptions.find(s => s.value.toString() === cspId.toString())));
    }
    if (advertisers.length > 0 && initialData?.selectedAdvertisers?.length > 0) {
      console.log('setAdv', advertisers);
      setSelectedAdvertisers(initialData?.selectedAdvertisers.map(cspId => advertisers.find(s => s.value.toString() === cspId.toString())));
    }
  }, [initialData, cspOptions, advertisers]);
  const columnsBrands = useMemo(
    () => [
      {
        Header: 'Brand',
        accessor: (deal) => {
          const adv = advertisers.find(a => a.value.toString() === deal?._id?.toString());
          return adv?.label || '';
        },
        id: 'Brand',
        Cell: ({ value }) => value || 'Unassigned',
        width: 250,
      },
      {
        Header: 'No. Leads Assigned',
        accessor: deal => Number((deal.count || 0)),
        id: 'leads',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.leads) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalAge)}</span>;
        },
        width: 200,
      },
      {
        Header: 'Leads Ready for Assignment',
        accessor: deal => Number((deal.notassignedCount || 0)),
        id: 'notassignedleads',
        Cell: ({ value }) => formater.formatIntNumber(value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.notassignedleads) + sum, 0),
            [info.rows],
          );
          return <span>{formater.formatIntNumber(totalAge)}</span>;
        },
        width: 200,
      },
    ],
    [advertisers],
  );
  const getTotal = useMemo(() => {
    const a = reportData.reduce((sum, row) => Number(row.count || 0) + sum, 0);
    return a;
  }, [reportData]);
  const getTotalNotAssigned = useMemo(() => {
    const a = reportData.reduce((sum, row) => Number(row.notassignedCount || 0) + sum, 0);
    return a;
  }, [reportData]);

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    useFlex: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    sortBy: 'Brand',
    direction: 'asc',
  };
  return (
    <div>
      <Row key="2">
        <Col>
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  Brand(s)
                </Label>
                <Select
                  isDisabled={loadingAdvertiser || isSaved}
                  value={selectedAdvertisers}
                  options={advertisers}
                  onChange={e => setSelectedAdvertisers(e)}
                  placeholder="Select Brand..."
                  isClearable
                  isMulti
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>
                  Deal Owner(s)
                </Label>
                <Select
                  isMulti
                  value={selectedCsps}
                  options={cspOptions}
                  onChange={i => setSelectedCsps(i)}
                  placeholder="Select Deal Owner..."
                  isClearable
                  isDisabled={isSaved}
                />
              </FormGroup>
            </Col>
            {!isSaved ? (
              <Col sm="auto">
                <FormGroup>
                  <Button
                    style={{ marginTop: '30px' }}
                    color="primary"
                    onClick={() => generateReport(selectedAdvertisers?.map(e => e.value), selectedCsps?.map(e => e.value))}
                    className=""
                    disabled={!!loading}
                  >
                    Run Report
                  </Button>
                </FormGroup>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
      {!loading && fetched ? (
        <Row style={{ marginBottom: '12px' }}>
          <Col lg="4" md="4" />
          <Col lg="2" md="4">
            <Item
              items={[{
                label: 'No. Leads Assigned',
                value: formater.formatIntNumber(getTotal),
              }]}
            />
          </Col>
          <Col lg="2" md="4">
            <Item
              items={[{
                label: 'Leads Ready For Assignment',
                value: formater.formatIntNumber(getTotalNotAssigned),
              }]}
            />
          </Col>
        </Row>) : null}
      {!loading && fetched ? (
        <Row>
          <Col>
            <Row>
              <Col className="mt-3">
                {reportData.length > 0 ? (
                  <div className="float-right mr-2">
                    <DownloadCSVButton
                      records={reportData}
                      className="mt-2"
                      fileName="LeadsPerformance.csv"
                      csvArrayColumns={[
                        {
                          label: 'Brand',
                          func: (deal) => {
                            const adv = advertisers.find(a => a.value.toString() === deal?._id.toString());
                            return adv?.label || '';
                          },
                        },
                        {
                          label: 'No. Leads Assigned',
                          func: deal => formater.formatIntNumber(Number((deal.count || 0))),
                        },
                        {
                          label: 'Leads Ready for Assignment',
                          func: deal => formater.formatIntNumber(Number((deal.notassignedCount || 0))),
                        },
                      ]}
                    >
                      Download CSV
                    </DownloadCSVButton>
                  </div>
                ) : null}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="mt-3">
                {!loading && fetched ? (
                  <ReactTableBase
                    key="searchable2"
                    columns={columnsBrands}
                    data={reportData}
                    tableConfig={tableConfig}
                    isSaved
                  />) : null
                }
              </Col>
            </Row>
          </Col>
        </Row>) : null
      }
    </div>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired,
  isSaved: PropTypes.bool.isRequired,
  reportData: PropTypes.arrayOf(PropTypes.any).isRequired,
  initialData: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  fetched: PropTypes.bool.isRequired,
};
SummaryReport.defaultProps = {
  initialData: {},
};

export default withNotice(SummaryReport);
