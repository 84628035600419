import React, { useMemo } from 'react';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import CopyToClipboard from '../../../../../../shared/components/CopyToClipboard';
import formatter from '../../../../../../shared/helpers/WVFormatter';

export default () => {
  console.log('Columns');
  return [
    {
      Header: 'Creator',
      accessor: p => `${p?.content.influencer?.first_name || ''} ${p?.content.influencer?.last_name || ''}`,
      id: 'Creator',
      Cell: p => useMemo(() => (
        <div>
          {/* <span>{`${p.row.original.content.influencer.first_name || ''} ${p.row.original.content.influencer.last_name || ''}`}</span> */}
          <Tooltip title="Open Creator Profile">
            {/* eslint-disable-next-line max-len */}
            <a target="_blank" rel="noopener noreferrer" href={`/influencer/profile/${p.row.original.content.influencer?._id}`}>{`${p.row.original.content.influencer.first_name || ''} ${p.row.original.content.influencer.last_name || ''}`}</a>
          </Tooltip>
          {p.row.original.content.influencer?.hsContactVid ? (
            <a
              href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.row.original?.content.influencer.hsContactVid}/`}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#70bbfd', marginLeft: '6px' }}
              title="Open Hubspot Contact"
            >
              <i className="fab fa-hubspot" style={{ fontSize: '1em' }} />
            </a>) : null}
          {p.row.original.content.influencer.companyName ? (
            <p>{p.row.original.content.influencer.companyName}</p>
          ) : null}
          <br />
          {p.row.original.content.influencer.email ? (
            <CopyToClipboard value={p.row.original.content.influencer.email}>{p.row.original.content.influencer.email}</CopyToClipboard>
          ) : null}
        </div>
      ), [p.row.original]),
      width: 300,
    },
    {
      Header: 'Brand',
      accessor: p => (p.content.advertiser?.companyName),
      id: 'brand',
      Cell: p => useMemo(() => (
        <span>{p.row.original.content.advertiser?.companyName}</span>
      ), [p.row.original]),
      width: 200,
    },
    {
      Header: 'Video Title',
      accessor: () => null,
      id: 'title',
      Cell: p => useMemo(() => (
        <span>{p.row.original.content.title || ''}</span>
      ), [p.row.original]),
      width: 300,
      disableSortBy: true,
    },
    {
      Header: 'Publish Date',
      accessor: p => (p?.content.publishDate ? p?.content.publishDate.split('T')[0] : null),
      id: 'publishDate',
      Cell: p => (
        <div style={{ paddingRight: '10px' }}>
          <span>{p.row.original.content.publishDate ? moment(p.row.original.content.publishDate).format('MM/DD/YYYY') : '-'}</span>
        </div>
      ),
      className: 'align-middle',
      width: 160,
    },
    {
      Header: 'Upfront Cost',
      accessor: p => (p.content.deal.dealTerms.guaranteed_payment_contract_amount || 0),
      id: 'upfrontCost',
      Cell: p => useMemo(() => (
        <div>{formatter.formatCurrency(Number(p.row.original.content.deal.dealTerms.guaranteed_payment_contract_amount || 0))}</div>
      ), [p.row.original]),
      width: 150,
    },
    {
      Header: 'Total Views',
      accessor: p => (p.views || 0),
      id: 'views',
      Cell: p => useMemo(() => (
        <div>{formatter.formatIntNumber(Number(p.row.original.views || 0))}</div>
      ), [p.row.original]),
      width: 150,
    },
    {
      Header: 'Total Comments',
      accessor: p => (p.commentsData.totalComments || 0),
      id: 'totalComments',
      Cell: p => useMemo(() => (
        <div>{formatter.formatIntNumber(Number(p.row.original.commentsData.totalComments || 0))}</div>
      ), [p.row.original]),
      width: 100,
    },
    {
      Header: 'Positive Comments',
      accessor: p => (p.commentsData.positiveNumber || 0),
      id: 'positiveNumber',
      Cell: p => useMemo(() => (
        <div>{formatter.formatIntNumber(Number(p.row.original.commentsData.positiveNumber || 0))}</div>
      ), [p.row.original]),
      width: 100,
    },
    {
      Header: 'Positive Percentage',
      accessor: p => (p.commentsData.positivePercent || 0),
      id: 'positivePercentage',
      Cell: p => useMemo(() => (
        <div>{formatter.formatIntNumberAndAddPercentSign(Number(p.row.original.commentsData.positivePercent || 0))}</div>
      ), [p.row.original]),
      width: 100,
    },
    {
      Header: 'Negative Comments',
      accessor: p => (p.commentsData.negativeNumber || 0),
      id: 'negativeNumber',
      Cell: p => useMemo(() => (
        <div>{formatter.formatIntNumber(Number(p.row.original.commentsData.negativeNumber || 0))}</div>
      ), [p.row.original]),
      width: 100,
    },
    {
      Header: 'Negative Percentage',
      accessor: p => (p.commentsData.negativePercent || 0),
      id: 'negativePercentage',
      Cell: p => useMemo(() => (
        <div>{formatter.formatIntNumberAndAddPercentSign(Number(p.row.original.commentsData.negativePercent || 0))}</div>
      ), [p.row.original]),
      width: 100,
    },
  ];
};

