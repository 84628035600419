/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useMemo, useState, useEffect } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
// import { useDispatch, useSelector } from 'react-redux';
import { unflatten } from 'flat';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonWithSpinner from '../../../../../../../../shared/components/ButtonWithSpinner';
// import { hideModalById } from '../../../../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../../../../App/store/with-notice';
import ProgressBar from '../../../../../../../../shared/helpers/ProgressBarWithObject';
import csvHelper from '../../../../../../../../shared/helpers/CSVHelper';
import Hook from '../../../../../../../../shared/hooks';

export const modalId = 'publishLookerInsertionOrderBulk';

const useStyles = makeStyles(() => ({
  formLabel: {
    marginLeft: '20px',
  },
}));

const Progress = ({ isShow, afterFunction, identity }) => {
  const [stats, setStats] = useState({});
  if (!isShow) return '';
  return (
    <div>
      <h5>Publishing Looker Update Sheet...</h5>
      <ProgressBar
        topic="lookerFileUpdate"
        identity={identity}
        isShow={isShow}
        receiveResults={d => console.log(d) || (d.stats && setStats(d.stats))}
        afterFunction={afterFunction}
      />
      <div className="mt-2">No. rows added: {stats.added || 0}</div>
      <div>No. rows updated: {stats.updated || 0}</div>
      <div>Errors: {stats.errors || 0}</div>
      <div>Total rows: {(stats.added || 0) + (stats.updated || 0) + (stats.errors || 0)}</div>
    </div>);
};
Progress.propTypes = {
  isShow: PropTypes.bool.isRequired,
  afterFunction: PropTypes.func.isRequired,
  identity: PropTypes.string.isRequired,
};

const DeleteCpmCreatorModal = ({
  // updateTable,
  addNotice,
}) => {
  const classes = useStyles();
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [progress, setProgress] = useState(0);
  const [results, setResults] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [rightRecordsAll, setRightRecords] = useState([]);

  const { data: dataModal, showModal: isShow, close: closeModal } = Hook.useModalHook(modalId);
  // const { ids } = dataModal;

  // const modalInfo = useSelector(state => state.table.modals[modalId]);
  // const records = useSelector(state => state.table.records);

  const [selectedRecords] = Hook.useSelectedRecordsTableHook();

  const identity = useMemo(() => (new Date()).toISOString(), [isShow]);
  const rightRecords = selectedRecords?.filter(a => a?.params?.status?.status === 'Signed') || [];
  useEffect(async () => {
    if (selectAll) {
      setLoading(true);
      const search = new URLSearchParams(window.location.search);
      search.set('perPage', '1000');
      search.set('page', '1');
      console.log(search, search.toString());
      console.log(Object.fromEntries(search));
      // const params = Object.fromEntries(search);
      const data = await axios({
        method: 'get',
        url: `/api/resources/CpmInsertionOrder/actions/list?${search.toString()}`,
      });
      console.log(data.data.records);
      console.log(unflatten(data.data.records));
      setRightRecords(data.data.records?.filter(a => a?.params['status.status'] === 'Signed'));
      setLoading(false);
    } else {
      setRightRecords(rightRecords);
    }
  }, [selectAll]);


  useEffect(() => {
    if (rightRecords.length > 0) {
      console.log('setRecords');
      setRightRecords(rightRecords);
    }
  }, [rightRecords]);

  if (!dataModal) return false;
  // const { data: { ids }, isShow } = modalInfo;
  // console.log(records, ids);
  const close = () => {
    setShowStats(false);
    setResults([]);
    setFile(null);
    setSelectAll(false);
    setRightRecords([]);
    closeModal();
  };
  console.log(rightRecordsAll, 'ALL');
  const publishInsertionOrder = async () => {
    setShowStats(true);
    setLoadingPublish(true);
    try {
      for (let i = 0; i < rightRecordsAll.length; i += 1) {
        try {
          const record = rightRecordsAll[i];
          // eslint-disable-next-line no-await-in-loop
          const res = await axios({
            method: 'get',
            url: `/api/portal/prospects/get-create-looker-by-insertion-order?id=${record?.id}&identity=${identity}&brandId=${record?.params?.brand}`,
          });
          setProgress(((i + 1) / rightRecordsAll.length) * 100);

          if (res?.data?.success) {
            const csvDownload = res?.data?.results.concat(results);
            setResults(csvDownload);
            setFile(res?.data?.sheetFile);
            // updateTable(res?.data?.sheetFile, res?.data?.sheetFile);
          }
        } catch (error) {
          console.log(error);
        }
      }
      setLoadingPublish(false);
      close();
    } catch (error) {
      console.error(error);
      addNotice({
        message: `Looker Update Data failed to publish: ${error.message}`,
        type: 'error',
      });
      setLoadingPublish(false);
    }
  };
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Publish Insertion Order
      </ModalHeader>
      <ModalBody>
        {!showStats && !loadingPublish && (<p>You have selected { rightRecordsAll?.length || 0 } Insertion Orders to publish updated CPM Prospect record data to the Looker Update Sheet. Please note: if an Insertion Order is not signed, it will be skipped.</p>)}
        {loadingPublish ? '' : (
          <div className="mt-3">
            <Label className="bold-text">Select all Insertion Orders?</Label>
            <FormGroup>
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Input
                    type="radio"
                    name="handleChangeLogContents"
                    value="1"
                    className="mt-0"
                    checked={selectAll === true}
                    onChange={e => e?.target && setSelectAll(Number(e?.target?.value) === 1)}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Input
                    type="radio"
                    name="handleChangeLogContents"
                    value="0"
                    className="mt-0"
                    checked={selectAll === false}
                    onChange={e => e?.target && setSelectAll(Number(e?.target?.value) === 1)}
                  />
                }
                label="No"
              />
            </FormGroup>
          </div>)}
        {loadingPublish && <LinearProgress variant="determinate" value={progress} />}
        {file ? <a href={file.spreadsheetUrl} target="_blank" rel="noreferrer" className="py-2 d-inline-block">Looker Update Sheet</a> : null}
      </ModalBody>
      <ModalFooter>
        {results.length === 0 ?
          <ButtonWithSpinner
            className="btn-sm"
            type="button"
            color="primary"
            onClick={publishInsertionOrder}
            loading={loadingPublish}
            disabled={loadingPublish || showStats || loading}
          >
            <span>Publish</span>
          </ButtonWithSpinner> :
          <Button
            className="btn-sm"
            color="primary"
            onClick={() => csvHelper.generateCSV('results.csv', results)}
          >
            Download Results
          </Button>}{' '}
        <Button
          className="btn-sm"
          color="secondary"
          onClick={close}
          disabled={loadingPublish}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DeleteCpmCreatorModal.propTypes = {
  // updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

DeleteCpmCreatorModal.defaultProps = {

};

export default withNotice(DeleteCpmCreatorModal);
