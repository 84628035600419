import {
  Button, ButtonToolbar, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import { hideModalById } from '../../../../../../../../redux/reducers/TableReducer';
import ReactTags from 'react-tag-autocomplete';
import Hook from '../../../../../../../../shared/hooks';
import ButtonWithSpinner from '../../../../../../../../shared/components/ButtonWithSpinner';

export const modalId = 'editProspectTagsModal';

const EditTagsModal = () => {
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const { showError, showSuccess } = Hook.useNoticeHook();

  const reactTags = useRef([]);


  const modalInfo = useSelector(state => state.table.modals[modalId]) || { isShow: false, data: { record: null } };
  console.log(modalInfo);
  const dispatch = useDispatch();
  const { data: { record, updateRecordFunction }, isShow } = modalInfo;
  const close = () => dispatch(hideModalById(modalId));

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setTags(tagsNew);
  };

  const fetchTags = () => {
    axios({
      method: 'get',
      url: `/api/portal/research/get-prospects-tags?id=${record?.id}`,
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
        setTags(response.data.tags);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    if (isShow) {
      fetchTags();
    }
    setLoading(false);
    if (!isShow) {
      setLoading(false);
      setTags([]);
    }
  }, [isShow]);


  const saveTags = async () => {
    setLoading(true);
    const response = await axios({
      method: 'post',
      url: '/api/portal/prospects/post-update-prospect-tags',
      data: {
        id: record.id,
        tags,
      },
    });
    close();
    if (response?.data?.success) {
      showSuccess('Tags was updated successfully.');
    } else {
      showError('Something went wrong. Tags were not updated.');
    }
    // console.log(updateRecordFunction, response?.data?.recordPopulated);
    if (response?.data?.recordPopulated && updateRecordFunction) {
      updateRecordFunction(response?.data?.recordPopulated);
    }
    // console.log('Update Tags', response);
  };
  if (!record) return false;

  return (
    <Modal
      isOpen={isShow}
      toggle={() => { close(); }}
      style={{ minWidth: '600px' }}
    >
      <ModalHeader toggle={() => { close(); }} tag="h4">Edit Internal Prospect Tags</ModalHeader>
      <ModalBody>
        <Row className="dashboard">
          <Col>
            <div>
              <FormGroup>
                <Label>Tags</Label>
                <ReactTags
                  ref={reactTags}
                  tags={tags}
                  suggestions={tagsSuggestions}
                  onDelete={onDeleteTag}
                  onAddition={onAdditionTag}
                  delimiters={['Enter', ',']}
                  allowNew
                  placeholderText="Tags"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <ButtonWithSpinner className="btn-primary btn btn-sm" onClick={saveTags} disabled={!!loading} loading={loading}>Save</ButtonWithSpinner>
        &nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" onClick={() => { close(); }}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

export default EditTagsModal;
