import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import EmployeeCommissionField from './custom/EmployeeCommissionField';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import statusComponent from './custom/StatusComponent';
import {
  reCalculateData,
  updateEmployeeCommissionRecord,
} from '../../../../../redux/reducers/admin/EmployeeCommissionsReducer';
import formater from '../../../../../shared/helpers/WVFormatter';

const columnsAdvertiserSummary = {
  employee: {
    title: 'Employee',
    valueFunc: record => (record.employeeCommissionReport.employee ? `${record.employeeCommissionReport.employee.firstName} ${record.employeeCommissionReport.employee.lastName}` : ''),
    sort: record => (record.employeeCommissionReport.employee ? `${record.employeeCommissionReport.employee.firstName} ${record.employeeCommissionReport.employee.lastName}` : ''),
  },
  revenueAmount: {
    title: 'Revenue',
    valueFunc: record => (record.revenueAmount ? formater.formatCurrency(record.revenueAmount.$numberDecimal) : formater.formatCurrency(0)),
    sort: record => parseFloat(record.revenueAmount.$numberDecimal),
  },
  payoutAmount: {
    title: 'Payout',
    valueFunc: record => (record.payoutAmount ? formater.formatCurrency(record.payoutAmount.$numberDecimal) : formater.formatCurrency(0)),
    sort: record => parseFloat(record.payoutAmount.$numberDecimal),
  },
  adjustmentAmount: {
    title: 'Adjustment',
    Component: EmployeeCommissionField,
    sort: record => parseFloat(record.adjustmentAmount.$numberDecimal),
  },
  profit: {
    title: 'Profit',
    valueFunc: record => (record.profit ? formater.formatCurrency(record.profit) : formater.formatCurrency(0)),
    sort: record => parseFloat(record.profit),
  },
  status: {
    title: 'Status',
    Component: statusComponent,
    sort: record => record.status,
  },
};

class EmployeeCommissionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.updateRecord = this.updateRecord.bind(this);
  }

  updateRecord(id, record) {
    const { dispatch } = this.props;
    dispatch(updateEmployeeCommissionRecord({ id, record }));
    dispatch(reCalculateData());
  }

  render() {
    const { commissionsArr } = this.props;
    const version = moment().format('x');
    return (
      <Row>
        <Col>
          <h5 style={{ position: 'absolute', top: '22px', left: '44px' }}><b>{commissionsArr[0].advertiser.companyName}</b></h5>
          <MatTableForRedux
            dataArray={commissionsArr}
            updateRecordFunc={this.updateRecord}
            defaultOrder={{
              order: 'desc',
              orderBy: 'revenueAmount',
            }}
            columns={columnsAdvertiserSummary}
            version={version}
            totals={[{ revenueAmount: 'money' }, { payoutAmount: 'money' }, { adjustmentAmount: 'money' }, { profit: 'money' }]}
          />
        </Col>
      </Row>
    );
  }
}
EmployeeCommissionsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  commissionsArr: PropTypes.arrayOf(PropTypes.any).isRequired,
};
EmployeeCommissionsTable.defaultProps = {

};
// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({
  // commissionsArr: state.commissionsArr,
});

export default connect(mapStateToProps, null)(EmployeeCommissionsTable);
