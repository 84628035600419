import React, { useEffect, useState } from 'react';
import { axios } from 'ApiClient';
// import ApiClient from 'ApiClient';
import PropTypes from 'prop-types';
import { InputGroup, Row, Col } from 'reactstrap';
import { NumericFormat as NumberFormat } from 'react-number-format';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const V30 = React.memo(({
  record, updateRecordFunction, showSync,
}) => {
  const [numVideos, setNumVideos] = useState(record.params?.v30 || 0);
  const [loading, setLoading] = useState(false);
  const [changedAmount, setChangedAmount] = useState(undefined);

  useEffect(() => {
    setNumVideos(record.params?.v30 || 0);
    // console.log('setNum views', [record.params?.v30, numVideos]);
  }, [record.params?.v30]);

  const handleChange = async () => {
    if (typeof changedAmount !== 'undefined') {
      setLoading(true);
      const { id } = record;
      const data = await axios.post('/api/portal/prospects/post-update-property-on-cpm-prospect', {
        ids: [id],
        values: {
          v30: changedAmount,
        },
      });
      setLoading(false);
      if (data?.data?.recordPopulated && updateRecordFunction) {
        updateRecordFunction(data?.data?.recordPopulated);
      }
    }
    return true;
  };
  const syncV30 = async () => {
    setLoading(true);
    const { id } = record;
    const data = await axios.post('/api/portal/prospects/post-sync-v30-cpm-prospect', {
      id,
    });
    setLoading(false);
    if (data?.data?.recordPopulated && updateRecordFunction) {
      updateRecordFunction(data?.data?.recordPopulated);
    }
  };

  return (
    <Row noGutters>
      <Col>
        <InputGroup>
          <NumberFormat
            thousandSeparator
            prefix=""
            name="v30"
            value={numVideos}
            className="form-control"
            inputMode="numeric"
            allowEmptyFormatting
            fixedDecimalScale
            decimalScale={0}
            disabled={loading}
            onBlur={handleChange}
            onValueChange={(values) => {
              setChangedAmount(values.floatValue);
              setNumVideos(values.floatValue);
            }}
          />
        </InputGroup>
      </Col>
      {showSync ? (
        <Col sm="auto" className="pt-1 pl-1">
          <Tooltip title="Sync V30 from Hubspot">
            <IconButton
              size="small"
              onClick={() => syncV30()}
            >
              <FontAwesomeIcon icon="sync" spin={loading} size="xs" />
            </IconButton>
          </Tooltip>
        </Col>
        ) : null}
    </Row>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

V30.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
  showSync: PropTypes.bool,
};
V30.defaultProps = {
  showSync: false,
};

export default V30;
