import React, { useState, useEffect, forwardRef } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Label,
  Container,
  Card,
  CardBody,
  CardTitle,
  FormGroup, Input, Button,
  InputGroup, InputGroupAddon, InputGroupText, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import { NoResourceError } from '../../../../shared/components/components/ui/error404';
import UserPropertyType from './custom';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import PermissionComponent from '../../../../shared/components/PermissionComponent';
import { CheckPermission, getCookie, parseUrl, setCookie } from '../../../../shared/helpers/WVUtilities';
import { getLast7Days } from '../../../../shared/helpers/Dates';
import CheckBoxField from '../../../../shared/components/CheckBox';

// eslint-disable-next-line react/prop-types
const DatePickerCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <div className="form-group">
    <InputGroup size="md">
      <input
        type="text"
        className="form-control form-control-sm"
        value={value}
        onClick={onClick}
        onChange={onChange}
        style={{
          border: '1px solid #ced4da',
          borderRadius: '5px',
        }}
        ref={ref}
      />
      <InputGroupAddon
        addonType="append"
        style={{
          padding: '3px 0 7px 7px',
        }}
      >
        <CalendarIcon
          className="date-picker-icon"
          onClick={onClick}
        />
      </InputGroupAddon>
    </InputGroup>
  </div>
));

const getCreatorAssignedInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    Assigned: true,
    'Not Assigned': true,
  };
  if (filters?.creatorAssigned) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters?.creatorAssigned.includes(key);
    });
  }
  return initialFilters;
};

const getBrandAssignedInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    Assigned: true,
    'Not Assigned': true,
  };
  if (filters?.brandAssigned) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters?.brandAssigned.includes(key);
    });
  }
  return initialFilters;
};

const VideoStatisticsReport = ({
  addNotice,
  resources,
  history,
}) => {
  
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loadingTotals, setLoadingTotals] = useState(true);
  const [totals, setTotals] = useState({ count: '0', views: '0' });
  const selectedCreatorsInitialState = [];
  const initialSearch = new URLSearchParams(window.location.search);
  if (initialSearch.has('filters.creatorEmail') && initialSearch.getAll('filters.creatorEmail').length > 0) {
    initialSearch.getAll('filters.creatorEmail').forEach((creatorEmail) => {
      selectedCreatorsInitialState.push({ value: creatorEmail, label: creatorEmail });
    });
  }
  const [creatorOptions, setCreatorOptions] = useState(selectedCreatorsInitialState);
  const [selectedCreators, setSelectedCreators] = useState(selectedCreatorsInitialState);
  const [brandOptions, setBrandOptions] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [creatorAssignedFilters, setCreatorAssignedFilters] = useState(getCreatorAssignedInitial());
  const [brandAssignedFilters, setBrandAssignedFilters] = useState(getBrandAssignedInitial());
  const [searchValue, setSearchValue] = useState('');
  const [tag, setTag] = useState('');
  const [downloadCSVLink, setDownloadCSVLink] = useState(`/reports/video-statistics/download-csv?${new URLSearchParams(window.location.search).toString()}`);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'));
  const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'));
  const [sortBy] = useState('publishedAt');
  const [direction] = useState('desc');
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [showCustom, setShowCustom] = useState(false);
  const [selectedButton, setSelectedButton] = useState('current_month');
  const [showFilters, setShowFilters] = useState(getCookie('videoStatisticsReportShowFilters') === 'true');

  useEffect(() => {
    setCookie('videoStatisticsReportShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const selectDateRange = (range) => {
    let dateFrom;
    let dateTo;
    switch (range) {
      case 'current_month':
        setShowCustom(false);
        dateFrom = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
        dateTo = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
      case 'last_month':
        dateFrom = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
        dateTo = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
        break;
      case 'year_to_date':
        setShowCustom(false);
        dateFrom = moment().startOf('Year').format('YYYY-MM-DD HH:mm:ss');
        dateTo = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
      case 'last_7_days':
        setShowCustom(false);
        [dateFrom, dateTo] = getLast7Days();
        break;
      case 'custom':
        setShowCustom(true);
        dateFrom = moment().startOf('Year').format('YYYY-MM-DD HH:mm:ss');
        dateTo = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
      default:
        dateFrom = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
        dateTo = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
    }
    setEndDate(dateTo);
    setStartDate(dateFrom);
    setSelectedButton(range);
  };

  const getText = () => {
    let dateText = '';
    if (
      moment(startDate).format('M') === moment(endDate).format('M') &&
      moment(startDate).format('D') === moment(endDate).format('D') &&
      moment(startDate).format('YYYY') === moment(endDate).format('YYYY')) {
      dateText = `${moment(startDate).format('MMM D, YYYY')} ET`;
    } else {
      dateText = `${moment(startDate).format('MMM D, YYYY')} ET  —  ${moment(endDate).format('MMM D, YYYY')} ET`;
    }
    return dateText;
  };

  const fetchFilterData = async () => {
    setLoadingFilters(true);
    try {
      const resp = await axios.post('/api/portal/reports/post-fetch-video-statistics-data-sets', { identity });
      if (resp?.data?.success) {
        setCreatorOptions(resp?.data?.creatorOptions || []);
        const foundOptions = [];
        const search = new URLSearchParams(window.location.search);
        if (search.has('filters.creatorEmail') && search.getAll('filters.creatorEmail').length > 0) {
          search.getAll('filters.creatorEmail').forEach((creatorEmail) => {
            const foundOption = resp?.data?.creatorOptions?.find(option => option.value === creatorEmail);
            if (foundOption) foundOptions.push(foundOption);
          });
        }
        if (foundOptions.length > 0) setSelectedCreators(foundOptions);
        setBrandOptions(resp?.data?.brandOptions || []);
        setCategoriesOptions(resp?.data?.youTubeCategoriesOptions || []);
        setLoadingFilters(false);
      } else {
        throw new Error(resp?.data?.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const fetchTotalsData = async () => {
    setLoadingTotals(true);
    try {
      const search = new URLSearchParams(window.location.search);
      const resp = await axios.get(`/api/portal/fetch-video-statistics-totals-data?${search.toString()}`);
      if (resp?.data?.success) {
        setTotals({
          count: resp.data.count,
          views: resp.data.views,
        });
        setLoadingTotals(false);
      } else {
        throw new Error(resp?.data?.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  let fetchTotalsDataTimer = setTimeout(() => console.log('timer'), 1500);

  const restartFetchTotalsDataTimer = () => {
    clearTimeout(fetchTotalsDataTimer);
    fetchTotalsDataTimer = setTimeout(fetchTotalsData, 500);
  };

  useEffect(async () => {
    fetchFilterData();
  }, []);

  const setUrl = () => {
    const search = new URLSearchParams(window.location.search);

    search.delete('filters.searchValue');
    if (searchValue) search.append('filters.searchValue', searchValue);

    search.delete('filters.publishedAt.from');
    search.delete('filters.publishedAt.to');
    if (startDate !== '') {
      search.append('filters.publishedAt.from', startDate);
    }
    if (endDate !== '') {
      search.append('filters.publishedAt.to', endDate);
    }

    search.delete('filters.brand');
    if (selectedBrands) {
      selectedBrands.forEach((selectedBrand) => {
        if (selectedBrand.value !== '') {
          search.append('filters.brand', selectedBrand.value);
        }
      });
    }

    search.delete('filters.creatorEmail');
    if (selectedCreators) {
      selectedCreators.forEach((selectedCreator) => {
        if (selectedCreator.value !== '') {
          search.append('filters.creatorEmail', selectedCreator.value);
        }
      });
    }

    search.delete('filters.youtube_category');
    if (selectedCategories) {
      selectedCategories.forEach((selectedCategory) => {
        if (selectedCategory.value !== '') {
          search.append('filters.youtube_category', selectedCategory.value);
        }
      });
    }

    if (brandAssignedFilters && Object.keys(brandAssignedFilters).length > 0) {
      search.delete('filters.brandAssigned');
      let allSwitchedOff = true;
      Object.keys(brandAssignedFilters).forEach((key) => {
        if (!brandAssignedFilters[key]) return false;
        search.append('filters.brandAssigned', key);
        allSwitchedOff = false;
        return true;
      });
      if (allSwitchedOff) {
        search.append('filters.brandAssigned', 'Not Exist');
      }
    }

    if (creatorAssignedFilters && Object.keys(creatorAssignedFilters).length > 0) {
      search.delete('filters.creatorAssigned');
      let allSwitchedOff = true;
      Object.keys(creatorAssignedFilters).forEach((key) => {
        if (!creatorAssignedFilters[key]) return false;
        search.append('filters.creatorAssigned', key);
        allSwitchedOff = false;
        return true;
      });
      if (allSwitchedOff) {
        search.append('filters.creatorAssigned', 'Not Exist');
      }
    }

    search.set('page', '1');
    setDownloadCSVLink(`/reports/video-statistics/download-csv?${search.toString()}`);
    history.push(`${history.location.pathname}?${search.toString()}`);
    restartFetchTotalsDataTimer();
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 500);

  useEffect(async () => {
    setUrlDebounced();
  }, [
    startDate,
    endDate,
    selectedBrands,
    selectedCreators,
    selectedCategories,
    brandAssignedFilters,
    creatorAssignedFilters,
    searchValue,
  ]);

  const selectItem = (value, type) => {
    switch (type) {
      case 'creator':
        setSelectedCreators(value);
        break;
      case 'brand':
        setSelectedBrands(value);
        break;
      case 'category':
        setSelectedCategories(value);
        break;
      default:
        return false;
    }
    return true;
  };

  const handleChangeBrandAssignedFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setBrandAssignedFilters({
        ...brandAssignedFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeCreatorAssignedFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setCreatorAssignedFilters({
        ...creatorAssignedFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    const search = new URLSearchParams(window.location.search);
    setSearchValue('');
    setSelectedCreators([]);
    setSelectedBrands([]);
    setSelectedCategories([]);
    setBrandAssignedFilters({
      Assigned: true,
      'Not Assigned': true,
    });
    setCreatorAssignedFilters({
      Assigned: true,
      'Not Assigned': true,
    });
    setDownloadCSVLink(`/reports/video-statistics/download-csv?${search.toString()}`);
  };

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  const resource = resources.find(r => r.id === 'VideoStatistic');
  if (!resource) {
    return (<NoResourceError resourceId="VideoStatistic" />);
  }
  if (!CheckPermission('reports----statistics----videostatistics')) return (<PermissionComponent />);
  const action = resource.resourceActions.find(r => r.name === 'list');

  const buttonClass = 'btn-sm mr-0 time-period-filter-buttons-extended';

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Video Statistics', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <h3>Video Statistics</h3>
                <hr className="mt-3 mb-4" />
              </CardTitle>
              <Row>
                <Col>
                  <div>
                    <Label className="bold-text">
                      Publish Date
                    </Label>
                    <Row style={{ minWidth: '300px' }}>
                      <Col lg="auto">
                        <Button
                          onClick={() => selectDateRange('current_month')}
                          className={selectedButton === 'current_month' ? `active ${buttonClass}` : buttonClass}
                          style={{ borderRadius: '5px 0 0 5px', borderRight: '0' }}
                        >
                          Current Month
                        </Button>
                        <Button
                          onClick={() => selectDateRange('last_month')}
                          className={selectedButton === 'last_month' ? `active ${buttonClass}` : buttonClass}
                          style={{ borderRadius: '0', borderRight: '0' }}
                        >
                          Last Month
                        </Button>
                        <Button
                          onClick={() => selectDateRange('year_to_date')}
                          className={selectedButton === 'year_to_date' ? `active ${buttonClass}` : buttonClass}
                          style={{ borderRadius: '0', borderRight: '0' }}
                        >
                          Year to Date
                        </Button>
                        <Button
                          onClick={() => selectDateRange('last_7_days')}
                          className={selectedButton === 'last_7_days' ? `active ${buttonClass}` : buttonClass}
                          style={{ borderRadius: '0', borderRight: '0' }}
                        >
                          Last 7 days
                        </Button>
                        <Button
                          onClick={() => selectDateRange('custom')}
                          // color="primary"
                          className={selectedButton === 'custom' ? `active ${buttonClass}` : buttonClass}
                          style={{ borderRadius: '0 5px 5px 0', borderColor: '#4A69F2' }}
                        >
                          Custom
                        </Button>
                      </Col>
                      <Col lg="auto">
                        {showCustom ? (
                          <Row>
                            <Col xs="auto">
                              <div style={{ maxWidth: '120px' }}>
                                <DatePicker
                                  selected={moment(startDate).toDate()}
                                  onChange={date => setStartDate(moment(date).format('YYYY-MM-DD HH:mm:ss'))}
                                  dateFormat="MM/dd/yyyy"
                                  maxDate={new Date()}
                                  placeholderText="Start Date"
                                  dropDownMode="select"
                                  className="form-control"
                                  customInput={<DatePickerCustomInput />}
                                />
                              </div>
                            </Col>
                            <Col xs="auto">
                              <div style={{ maxWidth: '120px' }}>
                                <DatePicker
                                  selected={moment(endDate).toDate()}
                                  onChange={date => setEndDate(moment(date).format('YYYY-MM-DD HH:mm:ss'))}
                                  dateFormat="MM/dd/yyyy"
                                  maxDate={new Date()}
                                  placeholderText="End Date"
                                  dropDownMode="select"
                                  className="form-control"
                                  customInput={<DatePickerCustomInput />}
                                />
                              </div>
                            </Col>
                          </Row>) : null
                        }
                      </Col>
                    </Row>
                    <Row style={{ minWidth: '300px' }}>
                      <Col>
                        <h5 style={{ color: 'black', paddingBottom: '8px' }} className="bold-text">{getText()}</h5>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginBottom: '12px' }}>
                <Col xs="4" />
                <Col xs="2" style={{ marginRight: '20px' }}>
                  <Row
                    style={{
                      padding: '15px 15px',
                      border: '1px solid #cccccc',
                      borderRadius: '5px',
                    }}
                  >
                    <Col xs="12">
                      <p style={{ textAlign: 'center', color: 'black' }} >
                        <span
                          style={{
                            fontSize: '20px',
                            fontWeight: '700',
                            marginBottom: '12px',
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          {loadingTotals ? (<div className="spinner-border text-light spinner-border-sm" />) : totals.count}
                        </span>
                        <br style={{ lineHeight: '50%' }} />
                        <span style={{ width: '100%', fontSize: '16px' }}>No. Videos</span>
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs="2">
                  <Row
                    style={{
                      padding: '15px 15px',
                      border: '1px solid #cccccc',
                      borderRadius: '5px',
                    }}
                  >
                    <Col xs="12">
                      <p style={{ textAlign: 'center', color: 'black' }} >
                        <span
                          style={{
                            fontSize: '20px',
                            fontWeight: '700',
                            marginBottom: '12px',
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          {loadingTotals ? (<div className="spinner-border text-light spinner-border-sm" />) : totals.views}
                        </span>
                        <br style={{ lineHeight: '50%' }} />
                        <span style={{ width: '100%', fontSize: '16px' }}>No. Views</span>
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs="4" />
              </Row>
              <Row className="mt-4">
                <Col sm={8}>
                  <InputGroup>
                    <Input
                      className="form-control-sm form-control"
                      name="search"
                      type="search"
                      autoComplete="off"
                      placeholder="Search by Creator Name, Media Content Title, Channel Name, URL"
                      value={searchValue}
                      onChange={p => setSearchValue(p.target.value)}
                      style={{ height: '38px' }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{ color: '#70bbfd', fontSize: 12 }}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col sm={2}>
                  <Row>
                    <Col sm={8} style={{ paddingRight: '0' }}>
                      <Tooltip title="Reset Filters">
                        <Button
                          color="secondary"
                          size="sm"
                          onClick={resetFilters}
                          style={{
                            width: '100%',
                            padding: '4px 8px',
                            height: '38px',
                          }}
                        >
                          <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                          <span className="btn-text">Reset</span>
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col sm={1} style={{ paddingLeft: '24px' }}>
                      <Tooltip title="Toggle Filters">
                        <IconButton
                          id="filtersMenuButton"
                          size="small"
                          onClick={() => setShowFilters(!showFilters)}
                          className="material-table__toolbar-button"
                          style={{
                            height: '38px',
                          }}
                        >
                          <TuneIcon size="30" />
                        </IconButton>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col md={2} className="text-right">
                  <UncontrolledButtonDropdown className="w-100">
                    <DropdownToggle
                      id="actionsMenu"
                      caret
                      color="primary"
                      style={{
                        padding: '4px 25px',
                        height: '38px',
                      }}
                    >
                      Actions
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => window.open(downloadCSVLink, '_blank').focus()}>Download CSV</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </Col>
              </Row>
              { showFilters && (
                <>
                  <Row>
                    <Col sm={4}>
                      <FormGroup>
                        <Label className="bold-text">
                          Brand(s)
                        </Label>
                        <Select
                          isMulti
                          name="brandSelect"
                          disabled={loadingFilters}
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={selectedBrands}
                          options={brandOptions}
                          onChange={value => selectItem(value, 'brand')}
                          placeholder="Select Brand..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label className="bold-text">
                          Creator(s)
                        </Label>
                        <Select
                          isMulti
                          name="creatorSelect"
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={selectedCreators}
                          options={creatorOptions}
                          onChange={value => selectItem(value, 'creator')}
                          placeholder="Select Creator..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label className="bold-text">
                          YouTube Categories
                        </Label>
                        <Select
                          isMulti
                          name="creatorSelect"
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={selectedCategories}
                          options={categoriesOptions}
                          onChange={value => selectItem(value, 'category')}
                          placeholder="Select YouTube Categories..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <Label className="bold-text" style={{ marginBottom: '14px' }}>
                        Brand Assigned?
                      </Label>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="AssignedBrand"
                          label="Assigned"
                          value={brandAssignedFilters.Assigned}
                          onChange={event => handleChangeBrandAssignedFilters(event, 'Assigned')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '0' }}
                      >
                        <CheckBoxField
                          name="NotAssignedBrand"
                          label="Not Assigned"
                          value={brandAssignedFilters['Not Assigned']}
                          onChange={event => handleChangeBrandAssignedFilters(event, 'Not Assigned')}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={2}>
                      <Label className="bold-text" style={{ marginBottom: '14px' }}>
                        Creator Assigned?
                      </Label>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="AssignedCreator"
                          label="Assigned"
                          value={creatorAssignedFilters.Assigned}
                          onChange={event => handleChangeCreatorAssignedFilters(event, 'Assigned')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '0' }}
                      >
                        <CheckBoxField
                          name="NotAssignedCreator"
                          label="Not Assigned"
                          value={creatorAssignedFilters['Not Assigned']}
                          onChange={event => handleChangeCreatorAssignedFilters(event, 'Not Assigned')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}

              <Row className="mt-3">
                <MatTableList
                  action={action}
                  resource={resource}
                  sortBy={sortBy}
                  direction={direction}
                  setTag={setTag}
                  date={new Date()}
                  UserPropertyType={UserPropertyType}
                  columnsSettings={{
                    mediaContent: {
                      width: 370,
                    },
                    creatorName: {
                      width: 200,
                    },
                    brand: {
                      width: 200,
                    },
                    youtube_category: {
                      width: 220,
                    },
                    publishedAt: {
                      width: 200,
                    },
                    lastViews: {
                      width: 120,
                    },
                    'tvr.er30': {
                      width: 100,
                    },
                    actions: {
                      width: 30,
                      disableSortBy: true,
                    },
                  }}
                  tag={tag}
                  v={2}
                  hideToolbar
                  showTitle={false}
                  isResizable={false}
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
VideoStatisticsReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(withNotice(VideoStatisticsReport));
