import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import ViewHelpers from '@/shared/ViewHelpers';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumb from '../../../../shared/components/BreadCrumbs';
import UserPropertyType from './components/custom';
import FilterAdvertiserBlock from './components/FilterAdvertiserBlock';

const h = new ViewHelpers();
const ResourceAction = (props) => {
  const {
  //  match,
    location,
    resources,
    history,
  } = props;
  const resource = resources.find(r => r.id === 'Offer');
  if (!resource) {
    return (<NoResourceError resourceId="Offer" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="Offer" actionName="list" />);
  }
  let backUrl = null;
  const search = new URLSearchParams(window.location.search);
  if (search.get('backUrl') !== null) {
    backUrl = decodeURIComponent(search.get('backUrl'));
  }

  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Advertiser', path: h.resourceActionUrl({ resourceId: 'Advertiser', actionName: 'list' }) },
          { title: 'Offers', path: null },
        ]}
        isBackButton
        backPath={backUrl}
      />
      <Row>
        <Col md={12}>
          <FilterAdvertiserBlock location={location} history={history} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <MatTableList
            action={action}
            resource={resource}
            date={new Date()}
            UserPropertyType={UserPropertyType}
            sortBy="_id"
            direction="asc"
            columnsSettings={{
              name: {
                width: 250,
              },
              description: {
                width: 450,
              },
              tuneId: {
                width: 100,
              },
              status: {
                width: 100,
              },
            }}
            v={2}
          />
        </Col>
      </Row>
    </Container>
  );
};
ResourceAction.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
