/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import withNotice from '../../../../App/store/with-notice';

const PayeeSelect = (props) => {
  const { record, addNotice } = props;
  const payeeOptions = [
    { value: 'agent', label: 'Pay Agent' },
    { value: 'creator', label: 'Pay Creator' },
    { value: 'creatorCOAgent', label: 'Pay Creator c/o Agent' },
  ];
  const [value, setValue] = useState({ value: 'creator', label: 'Pay Creator' });
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const result = payeeOptions.find(item => (item.value === record.payment.payee));
    setValue(result);
  }, []);

  const handleChangePayee = (selectedItem) => {
    setDisabled(true);
    const data = new FormData();
    data.append('payee', selectedItem.value);
    data.append('id', record._id);
    axios({
      method: 'post',
      url: '/api/portal/influencer/post-save-payee-value',
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    }).then((response) => {
      const { success } = response.data;
      if (success) {
        setValue(selectedItem);
        setDisabled(false);
        addNotice({
          message: 'All data has been saved',
          type: 'success',
        });
      } else {
        addNotice({
          message: 'An error occurred while saving data',
          type: 'warning',
        });
      }
    }).catch((error) => {
      console.log(error);
      addNotice({
        message: 'An error occurred while saving data',
        type: 'warning',
      });
    });
  };

  return (
    <div className="d-flex" style={{ minWidth: '240px', maxWidth: '240px', marginBottom: 0 }}>
      <div className="pr-0">
        <FormGroup style={{ minWidth: '210px', maxWidth: '210px', marginBottom: 0 }}>
          <Select
            value={value}
            onChange={handleChangePayee}
            options={payeeOptions}
            isDisabled={!!disabled}
            menuPlacement="auto"
            menuPortalTarget={document.body}
          />
        </FormGroup>
      </div>
    </div>
  );
};

PayeeSelect.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(PayeeSelect);
