import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
// import moment from 'moment';
import ProgressBar from '../../../../../../shared/helpers/ProgressBarWithObject';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
// import formater from '../../../../../../shared/helpers/WVFormatter';
// import CopyToClipboard from '../../../../../../shared/components/CopyToClipboard';


const FillBrandModal = ({ isOpen, toggleModal }) => {
  const [updatedDealsItems, setUpdatedDealsItems] = useState([]);
  const [brandsCount, setCountBrands] = useState(0);
  const [loadingUpdate, setloadingUpdate] = useState(false);
  const [brandsCountTotal, setCountBrandsTotal] = useState(0);
  const [countMiss, setCountMiss] = useState(0);
  const [loading, setLoading] = useState(false);
  const funcName = 'updateAllBrands';
  useEffect(() => {
    if (isOpen === true) {
      setLoading(true);
      axios.get('/api/portal/hubil/get-count-brands').then((res) => {
        if (res.data) {
          setCountBrands(res.data.count);
          setCountBrandsTotal(res.data.totalCount);
          setCountMiss(res.data.countMiss);
        }
        setLoading(false);
      }).catch((error) => {
        console.error(error);
      });
    }
    return () => {
      // setPayableItems([]);
    };
  }, [isOpen]);
  const updateBrands = () => {
    setloadingUpdate(true);
    axios.post('/api/portal/hubil/post-update-all-brands').then((res) => {
      setUpdatedDealsItems(res.data.deals);
      setloadingUpdate(false);
    }).catch((error) => {
      console.error(error);
    });
  };
  const columnTransform = {
    dealname: {
      title: 'Deal Name',
      valueFunc: deal => (
        <a
          href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal.deal.hs_object_id}`}
          target="_blank"
          // without
          rel="noreferrer"
        >{deal.deal.dealname}<br />
        </a>
      ),
      sort: deal => deal.deal.dealname,
    },
    offerName: {
      title: 'Offer Name',
      valueFunc: deal => (<strong>{deal.deal.ilDealData.offer.name || ''}</strong>),
      sort: ({ deal }) => deal.ilDealData.offer.name,
    },
    status: {
      title: 'Brand Name',
      valueFunc: deal => (<strong>{deal.brand}</strong>),
      sort: deal => deal.brand,
    },
    message: {
      title: 'message',
      valueFunc: deal => (<strong>{deal.message}</strong>),
      sort: deal => deal.message,
    },

  };
  const csvArrayColumns = [
    {
      label: 'ID',
      func: record => (record.deal._id || ''),
    },
    {
      label: 'Deal name',
      func: record => (record.deal.dealname || ''),
    },
    {
      label: 'Hubspot Id',
      func: record => (record.deal.hs_object_id || ''),
    },
    {
      label: 'Offer Name',
      func: record => (record.deal.ilDealData.offer.name || ''),
    },
    {
      label: 'Brand Name',
      func: record => (record.brand || ''),
    },
    {
      label: 'Message',
      func: record => (record.message || ''),
    },
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="delete-modal ltr-support" size="lg" style={{ maxWidth: '800px' }}>
      <ModalHeader>
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => !loading && toggleModal()} />
        {brandsCount} Deals Should be Updated
      </ModalHeader>
      <ModalBody className='ltr-support theme-light'>
        {!loading ? (
          <div>
            <p>No. Deals: {brandsCountTotal}</p>
            <p>No. Deals Missing Offers: {countMiss}</p>
            <p className="mb-3">No. Deals Brands to Update: {brandsCount}</p>
          </div>
          ) : (<div className="text-center w-100"><Spinner color="primary" size="lg" /></div>)}
        {loadingUpdate && (<ProgressBar
          afterFunction={() => true}
          topic={funcName}
          showAsDefault
        />)}
        {updatedDealsItems && updatedDealsItems.length > 0 ? (
          <MatTableForRedux
            dataArray={updatedDealsItems}
            selectable={false}
            columns={columnTransform}
            version={(new Date()).toString()}
            defaultOrder={{
              order: 'desc',
              orderBy: 'dealname',
            }}
            rowsPerPage={10}
            csvDownload={{
              fileName: 'dealBrandUpdate.csv',
              csvArrayColumns,
            }}
          />
        ) : null}
      </ModalBody>
      <ModalFooter>
        {!loadingUpdate && (<Button color="primary" onClick={updateBrands}>Update Deal Brands({brandsCount})</Button>)}
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};
FillBrandModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
};
FillBrandModal.defaultProps = {
  isOpen: false,
};
export default FillBrandModal;
