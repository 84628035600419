/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ApiClient from 'ApiClient';
import withNotice from '../App/store/with-notice';
import Breadcrumbs from '../../shared/components/BreadCrumbsResource';
import ActionHeader from '../../shared/components/components/app/action-header';
import Loader from '../../shared/components/components/ui/loader';
import BaseActionComponent from '../../shared/components/components/app/base-action-component';
import { NoResourceError, NoActionError, NoRecordError } from '../../shared/components/components/ui/error404';
import shouldActionRefetchData from '../../shared/components/components/routes/utils/should-action-re-fetch-data';


class RecordAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: undefined,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.fetchRecord(match.params);
  }

  shouldComponentUpdate(newProps) {
    const { match } = this.props;
    if (shouldActionRefetchData(match.params, newProps.match.params)) {
      this.fetchRecord(newProps.match.params);
      return false;
    }
    return true;
  }

  getResourceAndAction(name) {
    const { match, resources } = this.props;
    const { resourceId, actionName } = match.params;
    const { record } = this.state;

    const nameToCheck = name || actionName;
    const resource = resources.find(r => r.id === resourceId);
    const action = record && record.recordActions.find(r => r.name === nameToCheck);
    return {
      resource: resource || undefined,
      action: action || undefined,
    };
  }

  fetchRecord({ actionName, recordId, resourceId }) {
    const { addNotice } = this.props;
    const api = new ApiClient();
    this.setState({
      isLoading: true,
      record: undefined,
    });
    api.recordAction({
      resourceId,
      recordId,
      actionName,
    }).then((response) => {
      this.setState({
        isLoading: false,
        record: response.data.record,
      });
    }).catch((error) => {
      addNotice({
        message: 'There was an error fetching the record, Check out console to see more information.',
        type: 'error',
      });
      throw error;
    });
  }

  render() {
    const { match } = this.props;
    const { actionName, recordId, resourceId } = match.params;
    const { record, isLoading } = this.state;

    const { resource, action } = this.getResourceAndAction();

    if (!resource) {
      return (<NoResourceError resourceId={resourceId} />);
    }
    if (!action && !isLoading) {
      return (<NoActionError resourceId={resourceId} actionName={actionName} />);
    }

    if (!record && !isLoading) {
      return (<NoRecordError resourceId={resourceId} recordId={recordId} />);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col>
            <Breadcrumbs
              resource={resource}
              actionName={actionName}
              record={record}
              isBackButton
              isBackAllowHistory
            />
            <ActionHeader
              resource={resource}
              recordId={recordId}
              action={action}
              record={record}
            />
            {isLoading
              ? <Loader />
              : (
                <div>
                  <BaseActionComponent
                    action={action}
                    resource={resource}
                    record={record}
                  />
                </div>
              )
            }
          </Col>
        </Row>
      </Container>
    );
  }
}


const mapStateToProps = state => ({
  resources: state.resources,
});


export default withNotice(connect(mapStateToProps)(RecordAction));
