import React, { useState } from 'react';
import { unflatten } from 'flat';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import { Modal, Button, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ApiClient from 'ApiClient';
import TableCellContextMenu from '@/shared/components/table/TableCellContextMenu';
import Hook from '../../../../../../../shared/hooks'

const ContextMenuInList = ({
  record,
}) => {
  const brandUser = unflatten(record);
  const [spyLoginLoading, setSpyLoginLoading] = useState(false);
  const [loading, setLoading] = useState(null);
  const [showRemoveUser, setShowRemoveUser] = useState(false);
  const { showError, showSuccess } = Hook.useNoticeHook();

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const handleDeleteDialogSubmit = async () => {
    setLoading(true);
    const api = new ApiClient();
    const response = await api.recordAction({
      resourceId: 'BrandUser', actionName: 'delete', recordId: brandUser.id,
    });

    console.log('Response of deleting user', response);
    if (response.status === 200) {
      showSuccess('The selected Brand user has been deleted')
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      showError('Couldn\'t delete the selected user')
    }

    setLoading(false);
    setShowRemoveUser(!showRemoveUser);
  };

  const spyLogin = () => {
    setSpyLoginLoading(true);
    axios.post(
      '/api/portal/brands/spy-login',
      {
        userId: brandUser.id,
      },
    ).then((response) => {
      const { spyLoginToken, baseUrl } = response.data;
      if (spyLoginToken && baseUrl) {
        goToPage(`${baseUrl}/login-secret/${spyLoginToken}`, '_blank');
      }
      setSpyLoginLoading(false);
    }).catch((error) => {
      console.log(error);
      setSpyLoginLoading(false);
    });
  };

  const contextMenuOptions = [
    { label: `${spyLoginLoading ? 'Please wait...' : 'Spy Login'}`, handler: spyLogin },
    { type: 'divider', label: 'divider1' },
    { label: 'Edit', handler: () => goToPage(`/resources/BrandUser/records/${brandUser.id}/edit`) },
    { label: 'Show', handler: () => goToPage(`/resources/BrandUser/records/${brandUser.id}/show`) },
    { label: 'Delete', handler: () => setShowRemoveUser(!showRemoveUser) },
  ];

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />

      <Modal isOpen={showRemoveUser} toggle={() => setShowRemoveUser(!showRemoveUser)} className="delete-modal" size="sm">
        <ModalHeader toggle={() => setShowRemoveUser(!showRemoveUser)} >
          Delete User
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete the selected Brand User?<br />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loading}
            className="btn-sm"
            color="danger"
            onClick={handleDeleteDialogSubmit}
          >
            Delete
          </Button>{' '}
          <Button
            disabled={loading}
            className="btn-sm"
            color="secondary"
            onClick={() => setShowRemoveUser(!showRemoveUser)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContextMenuInList;
