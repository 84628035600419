import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import SearchAccountPayableReport from './components/SearchAccountPayableReport';


const SearchBar = () => (
  <Card>
    <CardBody>
      <Row>
        <Col>
          <SearchAccountPayableReport />
        </Col>
        <Col lg="auto">
          <Link to="/reports/calculate-gross-profit">
            <Button
              color="primary"
              outline
              size="sm"
            >
              Calculate Gross Profit
            </Button>
          </Link>
        </Col>
        <Col lg="auto">
          <Link to="/finance/account-payable-report/new">
            <Button
              color="primary"
              outline
              size="sm"
            >
              New AP Report
            </Button>
          </Link>
        </Col>
        <Col lg="auto">
          <Link to="/resources/AccountsPayable/actions/list">
            <Button
              color="primary"
              outline
              size="sm"
            >
              View AP Reports
            </Button>
          </Link>
        </Col>
        <Col lg="auto">
          <Link to="/finance/creator-payment">
            <Button
              color="primary"
              outline
              size="sm"
            >
              Send Payment
            </Button>
          </Link>
        </Col>
      </Row>
    </CardBody>
  </Card>);
export default SearchBar;
