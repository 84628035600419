import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { setCookie } from '../../../../../shared/helpers/WVUtilities';
import { fetchDictionary } from '../../../../../shared/helpers/WVRequest';
import { fromOptions } from '../../../../../shared/helpers/Form';

const Panel = ({
  name,
  show,
  setShow,
  advertisers,
  setAdvertisers,
  owners,
  setOwners,
  setAdvertisersById,
  setOwnersById,
}) => {
  const [loadingAdvertisers, setLoadingAdvertisers] = useState(false);
  const [loadingOwners, setLoadingOwners] = useState(false);
  const [allAdvertisers, setAllAdvertisers] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    setLoadingAdvertisers(true);
    setLoadingOwners(true);
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      if (resp.data.success) {
        const brands = {};
        const advertisersById = {};
        resp.data.offers.forEach((offer) => {
          const brandId = offer.advertiser._id.toString();
          if (!brands[brandId] && offer.advertiser.companyName) {
            brands[brandId] = { ...offer.advertiser };
            advertisersById[brandId] = offer.advertiser.companyName;
          }
        });
        setAllAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : -1)));
        setAdvertisersById(advertisersById);
      }
      setLoadingAdvertisers(false);
    }).catch(() => {
      setLoadingAdvertisers(false);
    });

    axios.get('/api/portal/prospects/get-all-admin-users').then((resp) => {
      if (resp.data.success) {
        setOwnerOptions(resp.data.data);
        setOwnersById(fromOptions(resp.data.data));
      }
      setLoadingOwners(false);
    }).catch(() => {
      setLoadingOwners(false);
    });

    fetchDictionary('LEAD_OPS_METRICS_DASHBOARD').then((data) => {
      const { success, records } = data;
      if (success === true) {
        const dictionaryData = records.LEAD_OPS_METRICS_DASHBOARD ? JSON.parse(records.LEAD_OPS_METRICS_DASHBOARD) : {};
        setManagers(dictionaryData.defaultOwners || []);
      }
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    setOwners(ownerOptions.filter(o => managers.includes(o.label)));
  }, [ownerOptions, managers]);

  useEffect(() => {
    setCookie(`${name}ShowFilters`, show.toString(), 35);
  }, [show]);

  const resetFilters = () => {
    setAdvertisers([]);
    setOwners(ownerOptions.filter(o => managers.includes(o.label)));
  }

  const advertiserOptions = useMemo(() => allAdvertisers.filter(b => b.displayInUi === 'Yes').map(a => ({ value: a._id, label: a.companyName })), [allAdvertisers]);

  return (
    <Row className="mt-3">
      <Col md={6} xl={4}>
        <Label className="font-weight-bold">Brands</Label>
        <Select
          isDisabled={loadingAdvertisers}
          value={advertisers}
          options={advertiserOptions}
          onChange={setAdvertisers}
          placeholder="Select Brand..."
          isMulti
        />
      </Col>
      <Col md={6} xl={4}>
        <Label className="font-weight-bold">Prospect Owner</Label>
        <Select
          isDisabled={loadingOwners}
          value={owners}
          options={ownerOptions}
          onChange={setOwners}
          placeholder="Select Prospect Owner..."
          isMulti
        />
      </Col>
      <Col xl={4}>
        <Row>
          <Col sm={2} md={1} xl={2} style={{ paddingLeft: '24px', paddingTop: '28px' }}>
            <Tooltip title="Toggle Filters">
              <IconButton
                id="filtersMenuButton"
                size="small"
                onClick={() => setShow(!show)}
                className="material-table__toolbar-button"
                style={{
                  height: '42px',
                }}
              >
                <TuneIcon size="30" />
              </IconButton>
            </Tooltip>
          </Col>
          <Col sm={4} style={{ paddingRight: '0', paddingTop: '28px' }}>
            <Tooltip title="Reset Filters">
              <Button
                color="secondary"
                size="sm"
                onClick={resetFilters}
                style={{
                  width: '100%',
                  maxWidth: '160px',
                  padding: '4px 8px',
                  height: '42px',
                }}
              >
                <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                <span className="btn-text">Reset</span>
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Panel.propTypes = {
  name: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  advertisers: PropTypes.arrayOf(PropTypes.any),
  setAdvertisers: PropTypes.func.isRequired,
  owners: PropTypes.arrayOf(PropTypes.any),
  setOwners: PropTypes.func.isRequired,
  setAdvertisersById: PropTypes.func.isRequired,
  setOwnersById: PropTypes.func.isRequired,
};

Panel.defaultProps = {
  advertisers: [],
  owners: [],
};

export default Panel;
