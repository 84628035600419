import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonToolbar,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withNotice from '../../../../../../containers/App/store/with-notice';


const CreateSettingModal = ({
  isOpen,
  toggleModal,
  recordId,
  addNotice,
  onSaveRecordFunc,
  resourceId,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [name, setName] = useState('');
  const [visibility, setVisibility] = useState('public');

  const saveList = async () => {
    setLoadingSave(true);
    axios.post(
      '/api/portal/research/edit-setting',
      {
        resourceId,
        recordId,
        name,
        visibility,
      },
    ).then((response) => {
      if (response.data.success) {
        setTimeout(() => {
          onSaveRecordFunc();
          setLoadingSave(false);
          toggleModal();
          addNotice({
            message: recordId === false
              ? 'New Watchlist has been successfully created'
              : `The ${resourceId === 'ResearchWatchlist' ? 'Watchlist' : 'Saved Search'} has been successfully saved`,
            type: 'success',
          });
          setName('');
          setVisibility('public');
        }, 1000);
      } else {
        setLoadingSave(false);
        addNotice({
          message: response.data.error || 'Failed to save this search.',
          type: 'error',
          duration: 10,
        });
      }
    }).catch((error) => {
      setLoadingSave(false);
      console.log(`Error while saving the ${resourceId === 'ResearchWatchlist' ? 'Watchlist' : 'Saved Search'}: `, error);
      addNotice({
        message: error.message || error,
        type: 'error',
        duration: 10,
      });
    });
  };

  useEffect(async () => {
    if (recordId !== false) {
      const api = new ApiClient();
      setLoading(true);
      const response = await api.recordAction({
        resourceId,
        actionName: 'show',
        recordId,
      });
      // console.log(response);
      setLoading(false);
      if (response.status === 200 && response.data?.record && typeof response.data?.record !== 'undefined') {
        setName(response.data.record.params.name);
        setVisibility(response.data.record.params.visibility);
      } else {
        toggleModal();
        addNotice({
          message: 'Unexpected error happened.',
          type: 'error',
        });
      }
    } else {
      setName('');
      setVisibility('public');
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
      size="lg"
      style={{
        maxWidth: '900px',
        fontSize: '105%',
        fontStretch: '90%',
      }}
    >
      <div className="modal__header mb-0">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
        <h4 className="text-modal modal__title mb-1">
          {recordId === false ? 'Add List' : `Edit ${resourceId === 'ResearchWatchlist' ? 'List' : 'Saved Search'}`}
        </h4>
        {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
        {!loading && (
          <div>
            <br />
            <FormGroup>
              <Label>Name</Label>
              <Input
                type="text"
                id="name"
                name="name"
                onChange={e => setName(e.target.value)}
                value={name}
              />
            </FormGroup>
            <FormGroup>
              <Label>Visibility</Label>
              <Row>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FormControlLabel
                  control={
                    <Input
                      type="radio"
                      name="visibility"
                      value="public"
                      className="mt-0"
                      checked={visibility === 'public'}
                      onChange={() => { setVisibility('public'); }}
                    />
                  }
                  label="Public"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FormControlLabel
                  control={
                    <Input
                      type="radio"
                      name="visibility"
                      value="private"
                      className="mt-0"
                      checked={visibility === 'private'}
                      onChange={() => { setVisibility('private'); }}
                    />
                  }
                  label="Private"
                />
              </Row>
            </FormGroup>
          </div>
        )}
      </div>
      <ButtonToolbar className="modal__footer mt-4">
        <Button color="primary" onClick={saveList}>{loadingSave && (<span><div className="spinner-border text-light spinner-border-sm" />  </span>)}Save</Button>
        <Button color="danger" onClick={toggleModal}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

CreateSettingModal.propTypes = {
  addNotice: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired,
  recordId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  onSaveRecordFunc: PropTypes.func.isRequired,
};

CreateSettingModal.defaultProps = {
  isOpen: false,
};

export default withNotice(CreateSettingModal);
