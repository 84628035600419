import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IntervalDatePickerField from './IntervalDatePickerField';
import DownloadCsvModal from './modals/DownloadCsvModal';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import { setCookie, getCookie } from '../../../../../../../shared/helpers/WVUtilities';

const Actions = React.memo(({
  ilFilters,
  setILFilters,
  brandFilters,
  setBrandFilters,
  matchFilters,
  setMatchFilters,
  analystFilters,
  setAnalystFilters,
  categoryFilters,
  setCategoryFilters,
  programFilters,
  setProgramFilters,
  missingInfoFilters,
  setMissingInfoFilters,
  onSearchChange,
  tagsBrandsSuggestions,
  tagsSuggestions,

  setTagsFilter,
  setTagsIncludeBrandsFilter,
  setTagsExcludeBrandsFilter,

  isInternalFilters,
  setIsInternalFilters,

  inBlacklistFilters,
  setInBlacklistFilters,

  createDateFrom,
  setCreateDateFrom,
  createDateTo,
  setCreateDateTo,

  setTags,
  tags,

  tagsIncludeBrands,
  setTagsIncludeBrands,
  tagsExcludeBrands,
  setTagsExcludeBrands,

  categories,
  categoriesSuggestions,
  setTagsCategoriesFilter,
  tagsCategories,
  setTagsCategories,

  rejectionReasons,
  rejectionReasonsSuggestions,
  setTagsRejectionReasonsFilter,
  tagsRejectionReasons,
  setTagsRejectionReasons,

  analystsSuggestions,
  setTagsAnalystsFilter,
  setAnalysts,
  analysts,

  prospectCreatorsSuggestions,
  setTagsProspectCreatorsFilter,
  setProspectCreators,
  prospectCreators,

  prospectOwnerSuggestions,
  setTagsProspectOwnersFilter,
  setProspectOwners,
  prospectOwners,

  prospectGroupUploadLog,
  setProspectGroupUploadLog,

  setUpdateStatusModalVisible,

  setProspectIdsToDelete,
  setShowDeleteModal,

  setShowRefreshModal,
  getUrlSearch,
  searchValue,
  setUploadCsvModalVisible,
  setShowManageTagsModal,

  selectedProspectIds,
  setBrandSafetySearchTermsModalVisible,
  setShowAssignAnalystModal,
  setShowAssignProgramModal,
  setShowAssignModal,
  setAssignModalParams,

  miningMode,
  currentAdminUser,
  disableBlacklistActions,

  setShowCloneModal,
  setShowAllTagsModal,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [showFilters, setShowFilters] = useState(getCookie(`research${miningMode ? 'Mining' : ''}ProspectsShowFilters`) === 'true');
  const [dateSearch, setDateSearch] = useState(null);
  const [showDownloadCsvModal, setshowDownloadCsvModal] = useState(false);
  const reactTagsTags = React.createRef();
  const reactTagsIncludeBrands = React.createRef();
  const reactTagsExcludeBrands = React.createRef();
  const reactTagsAnalysts = React.createRef();
  const reactTagsProspectOwners = React.createRef();
  const reactTagsProspectCreators = React.createRef();
  const reactTagsCategories = React.createRef();
  const reactTagsRejectionReasons = React.createRef();
  const getBrandsSuggestionsForExclude = () => {
    const _suggestions = [...tagsBrandsSuggestions];
    const index = _suggestions.findIndex(element => element.id === 'Not Assigned');
    if (index > -1) _suggestions.splice(index, 1);
    return _suggestions;
  };

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie(`research${miningMode ? 'Mining' : ''}ProspectsShowFilters`, showFilters.toString(), 35);
  }, [showFilters]);

  const setFiltersTag = (tagsNew) => {
    const tagsFilterNew = {};
    if (tagsNew.length > 0) {
      tagsNew.map((item) => {
        tagsFilterNew[item.name] = true;
        return true;
      });
    }
    setTagsFilter(tagsFilterNew);
  };

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setFiltersTag(tagsNew);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setFiltersTag(tagsNew);
    setTags(tagsNew);
  };

  const setFiltersTagIncludeBrand = (tagsBrandsNew) => {
    const tagsBrandsFilterNew = {};
    if (tagsBrandsNew.length > 0) {
      tagsBrandsNew.map((item) => {
        tagsBrandsFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsIncludeBrandsFilter(tagsBrandsFilterNew);
  };

  const onDeleteTagIncludeBrand = (i) => {
    const tagsBrandsNew = tagsIncludeBrands.slice(0);
    tagsBrandsNew.splice(i, 1);
    setFiltersTagIncludeBrand(tagsBrandsNew);
    setTagsIncludeBrands(tagsBrandsNew);
  };

  const onAdditionTagIncludeBrand = (tag) => {
    const tagsBrandsNew = [].concat(tagsIncludeBrands, tag);
    setFiltersTagIncludeBrand(tagsBrandsNew);
    setTagsIncludeBrands(tagsBrandsNew);
  };

  const setFiltersTagExcludeBrand = (tagsBrandsNew) => {
    const tagsBrandsFilterNew = {};
    if (tagsBrandsNew.length > 0) {
      tagsBrandsNew.map((item) => {
        tagsBrandsFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsExcludeBrandsFilter(tagsBrandsFilterNew);
  };

  const onDeleteTagExcludeBrand = (i) => {
    const tagsBrandsNew = tagsExcludeBrands.slice(0);
    tagsBrandsNew.splice(i, 1);
    setFiltersTagExcludeBrand(tagsBrandsNew);
    setTagsExcludeBrands(tagsBrandsNew);
  };

  const onAdditionTagExcludeBrand = (tag) => {
    const tagsBrandsNew = [].concat(tagsExcludeBrands, tag);
    setFiltersTagExcludeBrand(tagsBrandsNew);
    setTagsExcludeBrands(tagsBrandsNew);
  };

  const setFiltersAnalyst = (analystsNew) => {
    const analystsFilterNew = {};
    if (analystsNew.length > 0) {
      analystsNew.map((item) => {
        analystsFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsAnalystsFilter(analystsFilterNew);
  };

  const onDeleteAnalyst = (i) => {
    const analystsNew = analysts.slice(0);
    analystsNew.splice(i, 1);
    setFiltersAnalyst(analystsNew);
    setAnalysts(analystsNew);
  };

  const onAdditionAnalyst = (analyst) => {
    const analystsNew = [].concat(analysts, analyst);
    setFiltersAnalyst(analystsNew);
    setAnalysts(analystsNew);
  };

  const setFiltersCategory = (categoriesNew) => {
    const categoriesFilterNew = {};
    if (categoriesNew.length > 0) {
      categoriesNew.map((item) => {
        categoriesFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsCategoriesFilter(categoriesFilterNew);
  };

  const onDeleteCategory = (i) => {
    const categoriesNew = tagsCategories.slice(0);
    categoriesNew.splice(i, 1);
    setFiltersCategory(categoriesNew);
    setTagsCategories(categoriesNew);
  };

  const onAdditionCategory = (category) => {
    const categoriesNew = [].concat(tagsCategories, category);
    setFiltersCategory(categoriesNew);
    setTagsCategories(categoriesNew);
  };

  const setFiltersRejectionReason = (rejectionReasonsNew) => {
    const rejectionReasonsFilterNew = {};
    if (rejectionReasonsNew.length > 0) {
      rejectionReasonsNew.map((item) => {
        rejectionReasonsFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsRejectionReasonsFilter(rejectionReasonsFilterNew);
  };

  const onDeleteRejectionReason = (i) => {
    const rejectionReasonsNew = tagsRejectionReasons.slice(0);
    rejectionReasonsNew.splice(i, 1);
    setFiltersRejectionReason(rejectionReasonsNew);
    setTagsRejectionReasons(rejectionReasonsNew);
  };

  const onAdditionRejectionReason = (rejectionReason) => {
    const rejectionReasonsNew = [].concat(tagsRejectionReasons, rejectionReason);
    setFiltersRejectionReason(rejectionReasonsNew);
    setTagsRejectionReasons(rejectionReasonsNew);
  };

  const setFiltersProspectCreator = (prospectCreatorsNew) => {
    const prospectCreatorsFilterNew = {};
    if (prospectCreatorsNew.length > 0) {
      prospectCreatorsNew.map((item) => {
        prospectCreatorsFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsProspectCreatorsFilter(prospectCreatorsFilterNew);
  };

  const onDeleteProspectCreator = (i) => {
    const prospectCreatorsNew = prospectCreators.slice(0);
    prospectCreatorsNew.splice(i, 1);
    setFiltersProspectCreator(prospectCreatorsNew);
    setProspectCreators(prospectCreatorsNew);
  };

  const onAdditionProspectCreator = (prospectCreator) => {
    const prospectCreatorsNew = [].concat(prospectCreators, prospectCreator);
    setFiltersProspectCreator(prospectCreatorsNew);
    setProspectCreators(prospectCreatorsNew);
  };

  const setFiltersProspectOwner = (prospectOwnersNew) => {
    const prospectOwnersFilterNew = {};
    if (prospectOwnersNew.length > 0) {
      prospectOwnersNew.map((item) => {
        prospectOwnersFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsProspectOwnersFilter(prospectOwnersFilterNew);
  };

  const onDeleteProspectOwner = (i) => {
    const prospectOwnersNew = prospectOwners.slice(0);
    prospectOwnersNew.splice(i, 1);
    setFiltersProspectOwner(prospectOwnersNew);
    setProspectOwners(prospectOwnersNew);
  };

  const onAdditionProspectOwner = (prospectOwner) => {
    const prospectOwnersNew = [].concat(prospectOwners, prospectOwner);
    setFiltersProspectOwner(prospectOwnersNew);
    setProspectOwners(prospectOwnersNew);
  };

  const loadProspectGroupUploadLogOptions = async (inputValue) => {
    const results = [];
    const response = await axios({
      method: 'get',
      url: `/api/portal/prospects/get-prospect-group-upload-log?term=${inputValue}`,
    });
    if (response.data.success) {
      response.data.importLogs.forEach((log) => {
        results.push({
          value: log._id,
          label: log.name,
        });
      });
    }
    return results;
  };

  const updateStatusClicked = () => {
    setUpdateStatusModalVisible(true);
  };

  const deleteProspectsClicked = () => {
    setProspectIdsToDelete(selectedProspectIds);
    setShowDeleteModal(true);
  };

  // const assignAnalystClicked = () => {
  //   // setProspectIdsToDelete(selectedProspectIds);
  //   // setShowDeleteModal(true);
  //   console.log(selectedProspectIds);
  // };
  const uploadCsv = () => {
    setUploadCsvModalVisible(true);
  };

  const handleChangeILFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setILFilters({
        ...ilFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeBrandFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setBrandFilters({
        ...brandFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeMatchFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setMatchFilters({
        ...matchFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeAnalystFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setAnalystFilters({
        ...analystFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeCategoryFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setCategoryFilters({
        ...categoryFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeProgramFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setProgramFilters({
        ...programFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeIsInternalFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setIsInternalFilters({
        ...isInternalFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeIsBlacklistFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setInBlacklistFilters({
        ...inBlacklistFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeMissingInfoFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setMissingInfoFilters({
        ...missingInfoFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setILFilters(miningMode ? {
      Mining: true,
      Rejected: false,
      'In Review': false,
      Approved: false,
    } : {
      Approved: true,
      Hold: true,
      'In Review': true,
      Mining: true,
      Rejected: true,
      Resubmit: true,
    });
    setBrandFilters(miningMode ? { } : {
      Approved: true,
      'In Review': true,
      Hold: true,
      Rejected: true,
      'In Appeals': true,
    });
    setMatchFilters({
      Match: true,
      'Possible Match': true,
      'No Match': true,
    });
    setAnalystFilters(miningMode ? { } : {
      Assigned: true,
      'Not Assigned': true,
    });
    setCategoryFilters({
      Assigned: true,
      'Not Assigned': true,
    });
    setProgramFilters({
      'Not Assigned': true,
      CPA: true,
      CPM: true,
    });
    setIsInternalFilters({
      Standard: true,
      Internal: true,
    });
    setInBlacklistFilters({
      Yes: true,
      No: true,
    });
    setMissingInfoFilters({
      'First Name': false,
      'Last Name': false,
      Email: false,
    });
    setTags([]);
    setTagsFilter({});
    setTagsIncludeBrands([]);
    setTagsExcludeBrands([]);
    setTagsCategories([]);
    setTagsRejectionReasons([]);
    setTagsIncludeBrandsFilter({});
    setTagsExcludeBrandsFilter({});
    setAnalysts(miningMode ? [{ name: `${currentAdminUser.firstName ? currentAdminUser.firstName : ''} ${currentAdminUser.lastName ? currentAdminUser.lastName : ''}`.trim(), id: currentAdminUser._id }] : []);
    setTagsAnalystsFilter(miningMode ? { [currentAdminUser._id]: true } : {});
    setTagsProspectCreatorsFilter({});
    setTagsProspectOwnersFilter({});
    setTagsCategoriesFilter({});
    setTagsRejectionReasonsFilter({});
    setProspectGroupUploadLog(null);
    setCreateDateFrom(null);
    setCreateDateTo(null);
  };

  const showAssignCategory = () => {
    setAssignModalParams({
      name: 'category',
      label: 'Category',
      values: categories || [],
      updateUrl: '/api/portal/prospects/post-update-many-by-creator',
      useHistory: true,
    });
    setShowAssignModal(true);
  };

  const showAssignRejectionReason = () => {
    setAssignModalParams({
      name: 'rejectionReason',
      label: 'Rejection Reason',
      values: rejectionReasons || [],
      disableOption: value => ['Not Brand Safe', 'Other'].includes(value),
      useHistory: true,
    });
    setShowAssignModal(true);
  };

  return (
    <div className="prospects-actions">
      <Row className="mt-3">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search First Name, Last Name, Email, YouTube Channel"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem disabled={selectedProspectIds.length === 0 || disableBlacklistActions} onClick={() => updateStatusClicked()}>
                <Tooltip title="Update Status">
                  <>Update Status</>
                </Tooltip>
              </DropdownItem>
              <DropdownItem disabled={selectedProspectIds.length === 0} onClick={() => setShowAssignAnalystModal(true)}>
                <Tooltip title="Assign Analyst">
                  <>Assign Analyst</>
                </Tooltip>
              </DropdownItem>
              <DropdownItem
                disabled={selectedProspectIds.length === 0 || (miningMode && disableBlacklistActions)}
                onClick={() => { setShowAssignProgramModal(true); }}
              >
                Assign Program
              </DropdownItem>
              <DropdownItem
                disabled={selectedProspectIds.length === 0}
                onClick={showAssignCategory}
              >
                Assign Category
              </DropdownItem>
              <DropdownItem
                disabled={selectedProspectIds.length === 0}
                onClick={showAssignRejectionReason}
              >
                Assign Rejection Reason
              </DropdownItem>
              <DropdownItem
                disabled={selectedProspectIds.length === 0}
                onClick={() => setShowCloneModal(true)}
              >
                Clone
              </DropdownItem>
              <DropdownItem disabled={selectedProspectIds.length === 0} onClick={() => deleteProspectsClicked()}>
                <Tooltip title="Delete">
                  <>Delete</>
                </Tooltip>
              </DropdownItem>
              {miningMode === false && (
                <DropdownItem disabled={selectedProspectIds.length === 0} onClick={() => setShowManageTagsModal(true)}>
                  <Tooltip title="Manage Tags">
                    <>Manage Tags</>
                  </Tooltip>
                </DropdownItem>
              )}
              <DropdownItem divider />
              <DropdownItem
                disabled={selectedProspectIds.length === 0}
                onClick={() => setShowRefreshModal(true)}
              >
                Refresh Statistics
              </DropdownItem>
              <DropdownItem divider />
              {miningMode === false && (
                <DropdownItem onClick={uploadCsv}>
                  <Tooltip title="Upload CSV">
                    <>Upload CSV</>
                  </Tooltip>
                </DropdownItem>
              )}
              <DropdownItem
                onClick={() => setshowDownloadCsvModal(true)}
              >
                Download CSV
              </DropdownItem>
              {miningMode === false && (
                <>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => setBrandSafetySearchTermsModalVisible(true)}>
                    <Tooltip title="Brand Search Terms">
                      <>Brand Search Terms</>
                    </Tooltip>
                  </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
          <DownloadCsvModal
            isOpen={showDownloadCsvModal}
            closeFunc={() => setshowDownloadCsvModal(false)}
            getUrlSearch={getUrlSearch}
          />
        </Col>
      </Row>
      {showFilters && (
        <>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label className="bold-text">Prospect Group</Label>
                <SelectAsync
                  cacheOptions
                  styles={{
                    control: provided => ({
                      ...provided,
                      minHeight: '44px',
                      maxHeight: '44px',
                      height: '44px',
                      width: '100%',
                    }),
                    indicatorsContainer: provided => ({
                      ...provided,
                      minHeight: '44px',
                      maxHeight: '44px',
                      height: '44px',
                    }),
                    valueContainer: provided => ({
                      ...provided,
                      minHeight: '44px',
                      maxHeight: '44px',
                      height: '44px',
                    }),
                  }}
                  id="importLog"
                  value={prospectGroupUploadLog}
                  defaultOptions
                  loadOptions={loadProspectGroupUploadLogOptions}
                  onChange={e => setProspectGroupUploadLog(e)}
                  placeholder="Select Prospect Group"
                  isClearable
                />
              </FormGroup>
            </Col>
            <Col sm={3} style={{ paddingRight: '5px' }}>
              <Label className="bold-text">
                Tag(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsTags}
                tags={tags}
                suggestions={tagsSuggestions}
                onDelete={onDeleteTag}
                onAddition={onAdditionTag}
                delimiters={['Enter', ',']}
                placeholderText="Tag name(s)"
                maxSuggestionsLength={20}
              />
            </Col>
            <Col sm={3} style={{ paddingLeft: 0, marginTop: '28px' }}>
              <Tooltip title="All Tags">
                <IconButton size="small" onClick={() => setShowAllTagsModal(true)} className="mt-2"><FontAwesomeIcon icon={['fas', 'tags']} style={{ fontSize: '1.2em' }} color="#70bbfd" /></IconButton>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            {miningMode ? (
              <>
                <Col sm="3">
                  <Label className="bold-text">
                    Added by
                  </Label>
                  <ReactTags
                    ref={reactTagsProspectCreators}
                    tags={prospectCreators}
                    suggestions={prospectCreatorsSuggestions}
                    onDelete={onDeleteProspectCreator}
                    onAddition={onAdditionProspectCreator}
                    delimiters={['Enter', ',']}
                    placeholderText="Added by name(s)"
                  />
                </Col>
                <Col sm="3">
                  <Label className="bold-text">
                    Include Brand(s)
                  </Label>
                  <ReactTags
                    ref={reactTagsIncludeBrands}
                    tags={tagsIncludeBrands}
                    suggestions={tagsBrandsSuggestions}
                    onDelete={onDeleteTagIncludeBrand}
                    onAddition={onAdditionTagIncludeBrand}
                    delimiters={['Enter', ',']}
                    placeholderText="Brand name(s)"
                  />
                </Col>
                <Col sm="3">
                  <Label className="bold-text">
                    Analyst(s)
                  </Label>
                  <ReactTags
                    className="mr-2"
                    ref={reactTagsAnalysts}
                    tags={analysts}
                    suggestions={analystsSuggestions}
                    onDelete={onDeleteAnalyst}
                    onAddition={onAdditionAnalyst}
                    delimiters={['Enter', ',']}
                    placeholderText="Analyst name(s)"
                  />
                </Col>
              </>
            ) : (
              <>
                <Col sm="3">
                  <Label className="bold-text">
                    Added by
                  </Label>
                  <ReactTags
                    ref={reactTagsProspectCreators}
                    tags={prospectCreators}
                    suggestions={prospectCreatorsSuggestions}
                    onDelete={onDeleteProspectCreator}
                    onAddition={onAdditionProspectCreator}
                    delimiters={['Enter', ',']}
                    placeholderText="Added by name(s)"
                  />
                </Col>
                <Col sm="3">
                  <Label className="bold-text">
                    Include Brand(s)
                  </Label>
                  <ReactTags
                    ref={reactTagsIncludeBrands}
                    tags={tagsIncludeBrands}
                    suggestions={tagsBrandsSuggestions}
                    onDelete={onDeleteTagIncludeBrand}
                    onAddition={onAdditionTagIncludeBrand}
                    delimiters={['Enter', ',']}
                    placeholderText="Brand name(s)"
                  />
                </Col>
                <Col sm="3">
                  <Label className="bold-text">
                    Analyst(s)
                  </Label>
                  <ReactTags
                    className="mr-2"
                    ref={reactTagsAnalysts}
                    tags={analysts}
                    suggestions={analystsSuggestions}
                    onDelete={onDeleteAnalyst}
                    onAddition={onAdditionAnalyst}
                    delimiters={['Enter', ',']}
                    placeholderText="Analyst name(s)"
                  />
                </Col>
              </>
            )}
            <Col sm="3">
              <Label className="bold-text">
                Prospect Owner(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsProspectOwners}
                tags={prospectOwners}
                suggestions={prospectOwnerSuggestions}
                onDelete={onDeleteProspectOwner}
                onAddition={onAdditionProspectOwner}
                delimiters={['Enter', ',']}
                placeholderText="Prospect Owner name(s)"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm="3">
              <Label className="bold-text">
                Categories
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsCategories}
                tags={tagsCategories}
                suggestions={categoriesSuggestions}
                onDelete={onDeleteCategory}
                onAddition={onAdditionCategory}
                delimiters={['Enter', ',']}
                placeholderText="Categories"
              />
            </Col>
            <Col sm="3">
              <Label className="bold-text">
                Also Not Included In Brand(s)
                {'  '}
                <Tooltip title="Using this filter can significantly impact performance">
                  <IconButton size="small" style={{ marginTop: '-3px' }}>
                    <i className="fa fa-info-circle" style={{ fontSize: '0.8em' }} />
                  </IconButton>
                </Tooltip>
              </Label>
              <ReactTags
                ref={reactTagsExcludeBrands}
                tags={tagsExcludeBrands}
                suggestions={getBrandsSuggestionsForExclude()}
                onDelete={onDeleteTagExcludeBrand}
                onAddition={onAdditionTagExcludeBrand}
                delimiters={['Enter', ',']}
                placeholderText="Brand name(s)"
              />
            </Col>
            <Col sm="6">
              <Label className="bold-text">
                Rejection Reasons
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsRejectionReasons}
                tags={tagsRejectionReasons}
                suggestions={rejectionReasonsSuggestions}
                onDelete={onDeleteRejectionReason}
                onAddition={onAdditionRejectionReason}
                delimiters={['Enter', ',']}
                placeholderText="Rejection Reasons"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={miningMode ? 6 : 9}>
              <Row>
                <Col sm={miningMode ? 3 : 2}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    InfluenceLogic Status
                  </Label>
                  {miningMode ? (
                    <>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="MiningIL"
                          label="Mining"
                          value={ilFilters.Mining}
                          onChange={event => handleChangeILFilters(event, 'Mining')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="RejectedIL"
                          label="Rejected"
                          value={ilFilters.Rejected}
                          onChange={event => handleChangeILFilters(event, 'Rejected')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '0' }}
                      >
                        <CheckBoxField
                          name="InReviewIL"
                          label="In Review"
                          value={ilFilters['In Review']}
                          onChange={event => handleChangeILFilters(event, 'In Review')}
                        />
                        <CheckBoxField
                          name="ApprovedIL"
                          label="Approved"
                          value={ilFilters.Approved}
                          onChange={event => handleChangeILFilters(event, 'Approved')}
                        />
                      </FormGroup>
                    </>
                  ) : (
                    <>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="ApprovedIL"
                          label="Approved"
                          value={ilFilters.Approved}
                          onChange={event => handleChangeILFilters(event, 'Approved')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="HoldingIL"
                          label="Hold"
                          value={ilFilters.Hold}
                          onChange={event => handleChangeILFilters(event, 'Hold')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="InReviewIL"
                          label="In Review"
                          value={ilFilters['In Review']}
                          onChange={event => handleChangeILFilters(event, 'In Review')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="MiningIL"
                          label="Mining"
                          value={ilFilters.Mining}
                          onChange={event => handleChangeILFilters(event, 'Mining')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="RejectedIL"
                          label="Rejected"
                          value={ilFilters.Rejected}
                          onChange={event => handleChangeILFilters(event, 'Rejected')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="ResubmitIL"
                          label="Resubmit"
                          value={ilFilters.Resubmit}
                          onChange={event => handleChangeILFilters(event, 'Resubmit')}
                        />
                      </FormGroup>
                    </>
                  )}
                </Col>
                { miningMode === false && (
                  <Col sm={2} id="brandFiltersGroup">
                    <Label className="bold-text" style={{ marginBottom: '14px' }}>
                      Brand Status
                    </Label>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="ApprovedBrand"
                        label="Approved"
                        value={brandFilters.Approved}
                        onChange={event => handleChangeBrandFilters(event, 'Approved')}
                      />
                    </FormGroup>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="InReviewBrand"
                        label="In Review"
                        value={brandFilters['In Review']}
                        onChange={event => handleChangeBrandFilters(event, 'In Review')}
                      />
                    </FormGroup>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="HoldBrand"
                        label="Hold"
                        value={brandFilters.Hold}
                        onChange={event => handleChangeBrandFilters(event, 'Hold')}
                      />
                    </FormGroup>
                    <FormGroup>
                      <CheckBoxField
                        name="RejectedBrand"
                        label="Rejected"
                        value={brandFilters.Rejected}
                        onChange={event => handleChangeBrandFilters(event, 'Rejected')}
                      />
                    </FormGroup>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="InAppealsBrand"
                        label="In Appeals"
                        value={brandFilters['In Appeals']}
                        onChange={event => handleChangeBrandFilters(event, 'In Appeals')}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col sm={miningMode ? 3 : 2}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    InfluenceLogic Contact
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Match"
                      label="Match"
                      value={matchFilters.Match}
                      onChange={event => handleChangeMatchFilters(event, 'Match')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="PossibleMatch"
                      label="Possible Match"
                      value={matchFilters['Possible Match']}
                      onChange={event => handleChangeMatchFilters(event, 'Possible Match')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <CheckBoxField
                      name="NoMatch"
                      label="No Match"
                      value={matchFilters['No Match']}
                      onChange={event => handleChangeMatchFilters(event, 'No Match')}
                    />
                  </FormGroup>
                </Col>
                { miningMode === false && (
                  <Col sm={2}>
                    <Label className="bold-text" style={{ marginBottom: '14px' }}>
                      Analysts
                    </Label>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="AssignedAnalysts"
                        label="Assigned"
                        value={analystFilters.Assigned}
                        onChange={event => handleChangeAnalystFilters(event, 'Assigned')}
                      />
                    </FormGroup>
                    <FormGroup>
                      <CheckBoxField
                        name="NotAssignedAnalysts"
                        label="Not Assigned"
                        value={analystFilters['Not Assigned']}
                        onChange={event => handleChangeAnalystFilters(event, 'Not Assigned')}
                      />
                    </FormGroup>
                    <Label className="bold-text" style={{ marginBottom: '14px' }}>
                      Category?
                    </Label>
                    <FormGroup
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckBoxField
                        name="AssignedCategories"
                        label="Assigned"
                        value={categoryFilters.Assigned}
                        onChange={event => handleChangeCategoryFilters(event, 'Assigned')}
                      />
                    </FormGroup>
                    <FormGroup>
                      <CheckBoxField
                        name="NotAssignedCategories"
                        label="Not Assigned"
                        value={categoryFilters['Not Assigned']}
                        onChange={event => handleChangeCategoryFilters(event, 'Not Assigned')}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col sm={miningMode ? 3 : 2}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Program
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="NotAssignedProgram"
                      label="Not Assigned"
                      value={programFilters['Not Assigned']}
                      onChange={event => handleChangeProgramFilters(event, 'Not Assigned')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="CPA"
                      label="CPA"
                      value={programFilters.CPA}
                      onChange={event => handleChangeProgramFilters(event, 'CPA')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <CheckBoxField
                      name="CPM"
                      label="CPM"
                      value={programFilters.CPM}
                      onChange={event => handleChangeProgramFilters(event, 'CPM')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={miningMode ? 3 : 2}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Missing Contact Info?
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="FirstName"
                      label="First Name"
                      value={missingInfoFilters['First Name']}
                      onChange={event => handleChangeMissingInfoFilters(event, 'First Name')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="LastName"
                      label="Last Name"
                      value={missingInfoFilters['Last Name']}
                      onChange={event => handleChangeMissingInfoFilters(event, 'Last Name')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <CheckBoxField
                      name="Email"
                      label="Email"
                      value={missingInfoFilters.Email}
                      onChange={event => handleChangeMissingInfoFilters(event, 'Email')}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col sm={1}>
              {miningMode === false && (
                <>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Prospect Type
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Standard"
                      label="Standard"
                      value={isInternalFilters.Standard}
                      onChange={event => handleChangeIsInternalFilters(event, 'Standard')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <CheckBoxField
                      name="Internal"
                      label="Internal"
                      value={isInternalFilters.Internal}
                      onChange={event => handleChangeIsInternalFilters(event, 'Internal')}
                    />
                  </FormGroup>
                </>
              )}
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Blacklist?
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Yes"
                  label="Yes"
                  value={inBlacklistFilters.Yes}
                  onChange={event => handleChangeIsBlacklistFilters(event, 'Yes')}
                />
              </FormGroup>
              <FormGroup>
                <CheckBoxField
                  name="No"
                  label="No"
                  value={inBlacklistFilters.No}
                  onChange={event => handleChangeIsBlacklistFilters(event, 'No')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Prospect Creation Date (ET)
              </Label>
              <IntervalDatePickerField
                verticalFields
                onChange={({ start, end }) => {
                  setCreateDateFrom(start);
                  end?.setHours(23, 59, 59);
                  // console.log(start, end);
                  setCreateDateTo(end);
                }}
                startDate={createDateFrom}
                endDate={createDateTo}
                maxDate={new Date()}
                placeholderFrom="Add Start Date"
                placeholderTo="Add End Date"
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  _.isEqual(a.ilFilters, a1.ilFilters) &&
  _.isEqual(a.brandFilters, a1.brandFilters) &&
  _.isEqual(a.matchFilters, a1.matchFilters) &&
  _.isEqual(a.analystFilters, a1.analystFilters) &&
  _.isEqual(a.categoryFilters, a1.categoryFilters) &&
  _.isEqual(a.programFilters, a1.programFilters) &&
  _.isEqual(a.isInternalFilters, a1.isInternalFilters) &&
  _.isEqual(a.inBlacklistFilters, a1.inBlacklistFilters) &&
  _.isEqual(a.missingInfoFilters, a1.missingInfoFilters) &&
  _.isEqual(a.selectedProspectIds, a1.selectedProspectIds) &&
  _.isEqual(a.tags, a1.tags) &&
  _.isEqual(a.tagsIncludeBrands, a1.tagsIncludeBrands) &&
  _.isEqual(a.tagsExcludeBrands, a1.tagsExcludeBrands) &&
  _.isEqual(a.tagsCategories, a1.tagsCategories) &&
  _.isEqual(a.tagsRejectionReasons, a1.tagsRejectionReasons) &&
  _.isEqual(a.tagsBrandsSuggestions, a1.tagsBrandsSuggestions) &&
  _.isEqual(a.tagsSuggestions, a1.tagsSuggestions) &&
  _.isEqual(a.analysts, a1.analysts) &&
  _.isEqual(a.prospectCreators, a1.prospectCreators) &&
  _.isEqual(a.prospectOwners, a1.prospectOwners) &&
  _.isEqual(a.prospectGroupUploadLog, a1.prospectGroupUploadLog) &&
  _.isEqual(a.createDateTo, a1.createDateTo) &&
  _.isEqual(a.createDateFrom, a1.createDateFrom) &&
  _.isEqual(a.analystsSuggestions, a1.analystsSuggestions) &&
  _.isEqual(a.prospectOwnerSuggestions, a1.prospectOwnerSuggestions) &&
  _.isEqual(a.categoriesSuggestions, a1.categoriesSuggestions) &&
  _.isEqual(a.rejectionReasonsSuggestions, a1.rejectionReasonsSuggestions) &&
  _.isEqual(a.prospectCreatorsSuggestions, a1.prospectCreatorsSuggestions));

Actions.propTypes = {
  ilFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setILFilters: PropTypes.func.isRequired,
  brandFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setBrandFilters: PropTypes.func.isRequired,
  matchFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setMatchFilters: PropTypes.func.isRequired,
  analystFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setAnalystFilters: PropTypes.func.isRequired,
  categoryFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setCategoryFilters: PropTypes.func.isRequired,
  programFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setProgramFilters: PropTypes.func.isRequired,
  isInternalFilters: PropTypes.objectOf(PropTypes.any),
  setIsInternalFilters: PropTypes.func,
  inBlacklistFilters: PropTypes.objectOf(PropTypes.any),
  setInBlacklistFilters: PropTypes.func,
  missingInfoFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setMissingInfoFilters: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  setShowRefreshModal: PropTypes.func.isRequired,
  getUrlSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,

  tagsBrandsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  tagsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,

  setTagsFilter: PropTypes.func.isRequired,
  setTagsIncludeBrandsFilter: PropTypes.func.isRequired,
  setTagsExcludeBrandsFilter: PropTypes.func.isRequired,

  setTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,

  tagsIncludeBrands: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsIncludeBrands: PropTypes.func.isRequired,
  tagsExcludeBrands: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsExcludeBrands: PropTypes.func.isRequired,

  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  categoriesSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsCategoriesFilter: PropTypes.func.isRequired,
  tagsCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsCategories: PropTypes.func.isRequired,

  rejectionReasons: PropTypes.arrayOf(PropTypes.any).isRequired,
  rejectionReasonsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsRejectionReasonsFilter: PropTypes.func.isRequired,
  tagsRejectionReasons: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsRejectionReasons: PropTypes.func.isRequired,

  setUpdateStatusModalVisible: PropTypes.func.isRequired,

  setProspectIdsToDelete: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,

  setUploadCsvModalVisible: PropTypes.func.isRequired,
  setShowManageTagsModal: PropTypes.func.isRequired,

  selectedProspectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setBrandSafetySearchTermsModalVisible: PropTypes.func.isRequired,

  setShowAssignAnalystModal: PropTypes.func.isRequired,
  setShowAssignProgramModal: PropTypes.func.isRequired,
  setShowAssignModal: PropTypes.func.isRequired,
  setAssignModalParams: PropTypes.func.isRequired,

  setTagsAnalystsFilter: PropTypes.func.isRequired,
  setAnalysts: PropTypes.func.isRequired,
  analysts: PropTypes.arrayOf(PropTypes.any).isRequired,
  analystsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,

  setTagsProspectCreatorsFilter: PropTypes.func,
  setProspectCreators: PropTypes.func,
  prospectCreators: PropTypes.arrayOf(PropTypes.any),
  prospectCreatorsSuggestions: PropTypes.arrayOf(PropTypes.any),

  setTagsProspectOwnersFilter: PropTypes.func,
  setProspectOwners: PropTypes.func,
  prospectOwners: PropTypes.arrayOf(PropTypes.any),
  prospectOwnerSuggestions: PropTypes.arrayOf(PropTypes.any),

  prospectGroupUploadLog: PropTypes.objectOf(PropTypes.any),
  setProspectGroupUploadLog: PropTypes.func,

  setShowCloneModal: PropTypes.func.isRequired,

  createDateFrom: PropTypes.objectOf(PropTypes.any),
  createDateTo: PropTypes.objectOf(PropTypes.any),
  setCreateDateFrom: PropTypes.func.isRequired,
  setCreateDateTo: PropTypes.func.isRequired,

  miningMode: PropTypes.bool,
  currentAdminUser: PropTypes.objectOf(PropTypes.any).isRequired,
  disableBlacklistActions: PropTypes.bool.isRequired,
  setShowAllTagsModal: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  createDateFrom: null,
  createDateTo: null,
  miningMode: false,
  prospectGroupUploadLog: null,
  setTagsProspectCreatorsFilter: () => {},
  prospectCreators: [],
  prospectCreatorsSuggestions: [],
  setTagsProspectOwnersFilter: () => {},
  setProspectOwners: () => {},
  prospectOwners: [],
  prospectOwnerSuggestions: [],
  setProspectCreators: () => {},
  setProspectGroupUploadLog: () => {},
  isInternalFilters: {},
  setIsInternalFilters: () => {},
  inBlacklistFilters: {},
  setInBlacklistFilters: () => {},
};

const mapStateToProps = state => ({
  currentAdminUser: state.session,
});

export default connect(mapStateToProps)(Actions);
