/* eslint react/prop-types: 0 */
import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import { formatIntNumber, formatIntNumberAndAddPercentSign } from '../../../../shared/helpers/WVFormatter';
import DownloadCSVButton from '../../../../shared/tables/table/DownloadCSVButton';
import CreatorInList from './Table/CreatorInList';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  withSearchEngine: true,
  manualPageSize: [10, 20, 30, 40],
  placeholder: 'Search...',
  sortBy: 'conversions',
  direction: 'desc',
};

const TableComponentCreator = ({
  data: dataItems,
}) => {
  const getCalculatedChartDataTotal = () => {
    let total = 0;
    dataItems.forEach((k) => {
      total += k.total;
    });
    return total;
    // return Object.values(chartData);
  };
  // const [currentChartData] = useState(getCurrentChartData());

  const currentChartDataTotal = useMemo(getCalculatedChartDataTotal, [dataItems]);
  console.log(currentChartDataTotal, 'table');
  // console.log(opacity);
  const columns = useMemo(
    () => [
      {
        Header: 'Creator',
        accessor: p => `${p?.creator?.first_name || ''} ${p?.creator?.last_name || ''}`,
        id: 'Creator',
        Cell: p => useMemo(() => (
          <CreatorInList record={p.row.original.creator} prospects={p.row.original.creator.prospectsArray} />
        ), [p.row.original]),
        width: 300,
      },
      {
        Header: 'Category',
        accessor: p => String(p?.creator.category || ''),
        id: 'creators',
        Cell: p => p.value,
      },
      {
        Header: 'Revenue Conversions',
        accessor: p => Number(p?.total || 0),
        id: 'conversions',
        Cell: p => formatIntNumber(p.value),
        Footer: (info) => {
          const totalAge = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
            [info.rows],
          );
          return <span>{formatIntNumber(totalAge)}</span>;
        },
      },
      {
        Header: '% Total Conversions',
        accessor: p => Number(p?.total || 0),
        id: 'perPenversions',
        Cell: p => formatIntNumberAndAddPercentSign(currentChartDataTotal ? p.value / currentChartDataTotal : 0),

      },
    ],
    [],
  );
  return (
    <Row>
      <Col>
        <>
          <Row>
            <Col />
            <Col sm="auto" >
              <DownloadCSVButton
                records={dataItems}
                className=""
                fileName="ConversionsByCategory.csv"
                csvArrayColumns={[
                  {
                    label: 'First Name',
                    func: data => data.creator?.first_name || '',
                  },
                  {
                    label: 'Last Name',
                    func: data => data.creator?.last_name || '',
                  },
                  {
                    label: 'Email',
                    func: data => data.creator?.email || '',
                  },
                  {
                    label: 'YouTube Channel',
                    func: data => data.creator?.mediaChannels?.youtube_channel_url || '',
                  },
                  {
                    label: 'Creator Profile',
                    func: data => (data.creator?._id ? `${window.location.origin}/influencer/profile/${data.creator._id}` : ''),
                  },
                  {
                    label: 'Category',
                    func: data => data.creator?.category || '',
                  }, {
                    label: 'Conversions',
                    func: data => Number(data.total || 0),
                  },
                  {
                    label: '% Total Conversions',
                    func: data => formatIntNumberAndAddPercentSign(currentChartDataTotal ? data.total / currentChartDataTotal : 0),
                  },
                ]}
              >
                Download CSV
              </DownloadCSVButton>
            </Col>
          </Row>
          <ReactTableBase
            key="searchable"
            columns={columns}
            data={dataItems}
            tableConfig={tableConfig}
          />
        </>
      </Col>
    </Row>
  );
};

TableComponentCreator.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

TableComponentCreator.defaultProps = {
  data: [],
};

export default TableComponentCreator;
