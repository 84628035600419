/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../../shared/components/ButtonWithSpinner';
import withNotice from '../../../../../App/store/with-notice';
import ProgressBar from '../../../../../../shared/helpers/ProgressBarWithObject';
import Hook from '../../../../../../shared/hooks';

export const modalId = 'refreshFilteredDeals';


const Progress = ({ isShow, afterFunction, identity }) => {
  if (!isShow) return '';
  return (
    <div>
      <h5>Updating Checklist...</h5>
      <ProgressBar
        topic="fillCheckDeals"
        identity={identity}
        isShow={isShow}
        afterFunction={afterFunction}
      />
    </div>);
};
Progress.propTypes = {
  isShow: PropTypes.bool.isRequired,
  afterFunction: PropTypes.func.isRequired,
  identity: PropTypes.string.isRequired,
};

const RefreshDeals = ({
  updateTable,
  addNotice,
  dealIds,
}) => {
  const [loadingPublish, setLoadingPublish] = useState(false);
  const { data: dataModal, showModal: isShow, close } = Hook.useModalHook(modalId);
  const identity = useMemo(() => (new Date()).toISOString(), [isShow]);
  useEffect(() => {
    if (!isShow) {
      setLoadingPublish(false);
    }
  }, [isShow]);
  const refreshDeals = async () => {
    setLoadingPublish(true);
    try {
      const res = await axios({
        method: 'post',
        url: '/api/portal/finance/post-deal-validation-prefill',
        data: { identity, ids: dealIds },
      });
      setLoadingPublish(false);
      if (res?.data?.success) {
        updateTable();
        close();
        addNotice({
          message: 'Filtered Deals has been updated successfully',
          type: 'success',
        });
      } else if (res?.data?.error) {
        addNotice({
          message: `Update deals failed: ${res.data.error}`,
          type: 'error',
        });
      }
      // close();
    } catch (error) {
      console.error(error);
      addNotice({
        message: `Update deals failed: ${error.message}`,
        type: 'error',
      });
      setLoadingPublish(false);
    }
  };
  if (!dataModal) return false;

  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Refresh Payment Authorization for Filtered Deals
      </ModalHeader>
      <ModalBody>
        <p>Are you sure you want to overwrite Payment Check for filtered deals</p>
        <Progress
          isShow={loadingPublish}
          afterFunction={() => {}}
          identity={identity}
        />
      </ModalBody>
      <ModalFooter>
        <ButtonWithSpinner
          className="btn-sm"
          type="button"
          color="primary"
          onClick={refreshDeals}
          loading={loadingPublish}
          disabled={loadingPublish}
        >
          <span>Refresh</span>
        </ButtonWithSpinner>{' '}
        <Button
          className="btn-sm"
          color="secondary"
          onClick={close}
          disabled={loadingPublish}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

RefreshDeals.propTypes = {
  updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  dealIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

RefreshDeals.defaultProps = {

};

export default withNotice(RefreshDeals);
