/* eslint-disable filenames/match-exported */
import React from 'react';
import { Card, CardBody } from 'reactstrap';
// import WrapperBox from '../ui/wrapper-box';
import ErrorBoundary from './error-boundary';

import list from '../actions/list';
import edit from '../actions/edit';
import newAction from '../actions/new';
import show from '../actions/show';
import bulkDelete from '../actions/bulk-delete';


class BaseActionComponent extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.setState({ isClient: false });
  // }

  componentDidMount() {
    // this.setState({ isClient: true });
  }

  render() {
    // console.log('RENDER BASE ACTION', this.props);
    const {
      // eslint-disable-next-line react/prop-types
      resource, action, record, records, setTag,
    } = this.props;

    const actions = {
      list,
      new: newAction,
      edit,
      show,
      bulkDelete,
    };
    // eslint-disable-next-line react/prop-types
    const Action = actions[action.name];
    // if (isClient && action.component) {
    //  Action = AdminBro.UserComponents[action.component];
    // }
    if (Action) {
      return (
        <ErrorBoundary>
          <Action
            action={action}
            resource={resource}
            record={record}
            records={records}
            setTag={setTag}
            date={new Date()}
          />
        </ErrorBoundary>
      );
    }
    return Action || (
      <Card>
        <CardBody>
          <div className="notification">
            You have to implement action component for your Action.
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default BaseActionComponent;
