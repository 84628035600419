/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { axios } from 'ApiClient';
import { Spinner } from 'reactstrap';

export default React.memo(({ hsDealId }) => {
  const [hsData, setHsData] = useState(null);
  const [loading, setLoading] = useState(false);
  let retriesCount = 0;

  const fetchData = () => {
    if (hsDealId && retriesCount <= 6) {
      setLoading(true);
      axios.get(`/api/portal/prospects/get-hs-deal-data?hsDealId=${hsDealId}`).then((result) => {
        if (result.data.success && result.data.data) {
          setLoading(false);
          setHsData(result.data.data);
        } else {
          retriesCount += 1;
          setTimeout(fetchData, 5000);
        }
      });
    } else setLoading(false);
  };

  useEffect(async () => {
    fetchData();
  }, [hsDealId]);

  return (
    <div>
      {hsData ? (
        hsData.dealname
      ) : (<Spinner color="primary" size="sm" style={loading ? {} : { display: 'none' }} />)}
      <a
        title="HubSpot Deal"
        target="_blank"
        rel="noreferrer"
        href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${hsDealId}/`}
      >
        <i className="fas fa-money-bill-alt" style={{ fontSize: '1.4em', marginLeft: '5px' }} />
      </a>
    </div>
  );
}, (a, a1) => _.isEqual(a.hsDealId, a1.hsDealId));
