import React from 'react';
import PropTypes from 'prop-types';
import Container from './components/Container';

const DealMergeAuditReport = (props) => {
  const {
    history,
    location,
  } = props;
  return (<Container
    filterVisible
    date={new Date()}
    history={history}
    location={location}
  />
  );
};
DealMergeAuditReport.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default DealMergeAuditReport;
