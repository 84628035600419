import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { axios } from 'ApiClient';
import TableCellContextMenu from '../../../../../../../../shared/components/table/TableCellContextMenu';
import CreatorInList from './CreatorInList';
import formater from '../../../../../../../../shared/helpers/WVFormatter';

const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

export default ({
  getCpmPriceApprovalsGroup, groupId, groupStatus, showDeleteModal,
}) => {
  // const [showDeleteModal] = Hook.useShowModalHook('DeleteModalDefault');
  const mapStatuses = {
    approved: 'Approved',
    inReview: 'In Review',
    rejected: 'Rejected',
    actionRequired: 'Action Required',
    hold: 'Hold',
    pendingEdits: 'Pending Edits',
  };
  return [
    {
      Header: 'Creator',
      accessor: p => `${p?.influencer?.first_name || ''} ${p?.influencer?.last_name || ''}`,
      id: 'Creator',
      Cell: p => useMemo(() => (
        <CreatorInList record={p.row.original} />
      ), [p.row.original]),
      width: 350,
      isFreeze: true,
    },
    {
      Header: 'Promo Code',
      id: 'promocode',
      Cell: p => (
        p.row.original.deal.dealTerms?.coupon_code || ''
      ),
      width: 125,
      className: 'd-flex align-items-center',
    },
    // {
    //   Header: 'PA Name',
    //   // accessor: p => (`${p.admin?.firstName || ''} ${p.admin?.lastName || ''}`),
    //   id: 'paName',
    //   Cell: () => (
    //     <span>PA Name</span>
    //   ),
    //   width: 100,
    // },
    {
      Header: 'Brand Safety',
      Cell: p => useMemo(() => {
        // console.log(p.row.original.prospect);
        // let date = '';
        const record = p.row.original.deal;
        // console.log('Deal',record);
        // if (p.row.original.prospect?.approvalStatus?.brand.history.length > 0 && p.row.original.prospect.approvalStatus.brand.status === 'Approved') {
        //   date = p.row.original.prospect.approvalStatus.brand.history[p.row.original.prospect.approvalStatus.brand.history.length - 1].dateModified;
        // }
        // return (
        //   <span>{date ? moment.tz(moment.utc(date).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') : '-'}</span>
        // );
        return record?.authorizedPayment?.creatorBrandApproved ? 'Approved' : 'Not Approved';
      }, [p.row.original]),
      width: 120,
      className: 'd-flex align-items-center',
    },
    {
      Header: 'Justification',
      Cell: p => useMemo(() => (
        <span>{p.row.original.dealJustification || ''}</span>
      ), [p.row.original]),
      width: 120,
      className: 'd-flex align-items-center',
    },
    {
      Header: 'Month',
      Cell: p => useMemo(() => {
        // console.log(p.row.original.contentReleaseDate);
        let date = '';
        if (p.row.original.contentReleaseDate?.currentDate) {
          date = moment.utc(p.row.original.contentReleaseDate.currentDate);
          date = date.format('MMMM, YYYY');
        }
        return (
          <span>{date}</span>
        );
      }, [p.row.original]),
      width: 120,
      className: 'd-flex align-items-center',
    },
    {
      Header: 'IL PA Status',
      Cell: p => useMemo(() => (
        <span>{mapStatuses[p.row.original.approvalStatus.status] || ''}</span>
      ), [p.row.original]),
      width: 120,
      className: 'd-flex align-items-center',
    },
    {
      Header: 'Brand PA Status',
      Cell: p => useMemo(() => (
        <span>{mapStatuses[p.row.original.approvalsBrandStatus.status] || ''}</span>
      ), [p.row.original]),
      width: 120,
      className: 'd-flex align-items-center',
    },
    {
      Header: 'V30',
      Cell: p => useMemo(() => (
        <span>{p.row.original.v30 ? formater.formatIntNumber(p.row.original.v30) : ''}</span>
      ), [p.row.original]),
      width: 120,
      className: 'd-flex align-items-center',
    },
    {
      Header: 'Total Price',
      Cell: p => useMemo(() => (
        <span>{formater.formatCurrency(p.row.original.totalPrice)}</span>
      ), [p.row.original]),
      width: 120,
      className: 'd-flex align-items-center',
    },
    {
      Header: 'CPM',
      Cell: p => useMemo(() => (
        <span>{formater.formatCurrency(p.row.original.cpm)}</span>
      ), [p.row.original]),
      width: 120,
      className: 'd-flex align-items-center',
    },
    {
      Header: '',
      accessor: '_id',
      // eslint-disable-next-line react/prop-types
      Cell: (p) => {
        const type = 'pause';
        console.log(type);

        return (
          <div style={{ textAlign: 'right' }}><TableCellContextMenu
            options={[
              {
                label: 'View HubSpot Contact',
                handler: () => {
                  goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.row.original.hsUserId}`);
                },
              },
              {
                label: 'View HubSpot Deal',
                handler: () => {
                  goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original.deal.hs_object_id}/`);
                },
              },
              { type: 'divider', label: 'divider2' },
              {
                label: 'Remove Deal from PA Group',
                handler: () => {
                  showDeleteModal({
                    title: 'Remove Deal from Price Approval Group',
                    message: (<>Are you sure you want to remove '<strong>{`${p.row.original.deal?.dealname}` || 'record'}</strong>' from the price approval group?</>),
                    deleteRecord: async () => {
                      await axios({
                        method: 'post',
                        url: '/api/portal/prospects/post-delete-cpm-prospect-from-approvals',
                        data: {
                          id: p.row.original._id,
                          groupId,
                        },
                      });
                    },
                    successFunction: () => {
                      getCpmPriceApprovalsGroup();
                    },
                    okMessage: 'Remove',
                  });
                },
                disabled: groupStatus !== 'New',
              },
            ]}
          />
          </div>);
      },
      disableGlobalFilter: true,
      disableSortBy: true,
      minWidth: 50,
      maxWidth: 50,
    },
  ];
};

