import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../App/store/with-notice';
import filterProperties from './custom/ActionProperties';
import Hook from '../../../../../shared/hooks';

const ContainerD = ({
  history,
}) => {
  const [searchUrl, setPrefill, loadPage, isPageChanged] = Hook.usePrefillHook({
    loadTableUrl: '/api/portal/finance/get-contacts-audit-report',
    initialState: {
      sortBy: 'count',
      direction: 'desc',
      page: 1,
      perPage: 10,
    },
  });

  useEffect(() => {
    console.log('Allow Start Load');
    setPrefill(filterProperties());
  }, []);
  const setUrl = () => {
    loadPage();
  };
  const downloadCsv = () => {
    window.open(`/api/portal/finance/get-contacts-audit-csv?${searchUrl}`, '_blank').focus();
  };
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 1000);

  useEffect(() => {
    if (searchUrl === null) return false;
    history.push(`${history.location.pathname}?${searchUrl}`);
    if (!isPageChanged.filters) {
      return loadPage();
    }
    return setUrlDebounced();
  }, [
    searchUrl,
  ]);
  console.log(searchUrl, 'SEARCH');
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Finance', path: null },
          { title: 'Contact Audit Page', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Contact Audit Page</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              {searchUrl !== null ? <Actions
                downloadCsv={downloadCsv}
              /> : null}
              <Row style={{ marginTop: '-10px' }}>
                {searchUrl !== null ? <Table /> : null}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};
ContainerD.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withNotice(withRouter(ContainerD));
