import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { Row, Col, Container, Card, CardBody, CardTitle, Label, Button } from 'reactstrap';
import Filter from '../../../../shared/components/filter/TimePeriodFilter';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
// import ProgressBar from '../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../App/store/with-notice';
import { clear } from '../../../../shared/helpers/LocalStorageCache';
// import localResp from './localResp';
import ChartComponent from './ChartComponent';
import TableComponent from './TableComponent';
import Errors from './Errors';
import Total from './Total';
import LoadingReport from "../../../../shared/components/LoadingReport";

const dataOptions = [
  { value: 'byDay', label: 'By Day' },
  { value: 'week', label: 'By Week' },
  { value: 'month', label: 'By Month' },
  { value: 'total', label: 'Over Time' },
];


const SummaryReport = ({ addNotice }) => {
  // if (!CheckPermission('reports----creators')) return (<PermissionComponent />);
  const [loading, setLoaing] = useState(false);

  const [items, setItems] = useState([]);
  const [tableItems, setTableItems] = useState([]);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);

  const [startDate, setStartDate] = useState(moment().subtract(3, 'month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState((moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).format('YYYY-MM-DD'));

  const [errors, setErrors] = useState([]);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [fetched, setFetched] = useState(false);
  const [selectedDate, setSelectedDate] = useState({ startDate, endDate });
  const [selectedOption, setSelectedOption] = useState(dataOptions[0]);

  const fetchAmountReport = async () => {
    setSelectedDate({ startDate, endDate });
    setLoaing(true);
    setItems([]);
    clear();
    try {
      const resp = await axios.post('/api/portal/reports/post-conversions-by-category-date', {
        startDate,
        endDate,
        brands: selectedAdvertisers?.map(a => a.value) || [],
        identity,
      });
      console.log(resp.data);

      if (resp.data.success) {
        const returnTableItems = [];
        const chartDataConversions = {};
        resp.data.creatorsWithCategory.forEach((influencer) => {
          if (!returnTableItems[influencer.category]) returnTableItems[influencer.category] = { category: influencer.category, creators: [], total: 0 };
          returnTableItems[influencer.category].creators.push(influencer);

          if (!chartDataConversions[influencer.category]) chartDataConversions[influencer.category] = { option: { _id: influencer.category || 'na', label: influencer.category || 'NA' }, data: {} };
          influencer.conversions.forEach(({ date, count }) => {
            const key = moment.utc(date).format('YYYY-MM-DD');
            if (!chartDataConversions[influencer.category].data[key]) chartDataConversions[influencer.category].data[key] = 0;
            chartDataConversions[influencer.category].data[key] += count;

            returnTableItems[influencer.category].total += count;
          });
          influencer.CPMconversions.forEach(({ date, count }) => {
            const key = moment.utc(date).format('YYYY-MM-DD');
            if (!chartDataConversions[influencer.category].data[key]) chartDataConversions[influencer.category].data[key] = 0;
            chartDataConversions[influencer.category].data[key] += count;

            returnTableItems[influencer.category].total += count;
          });
        });

        console.log(chartDataConversions);
        setItems(Object.values(chartDataConversions));
        setTableItems(Object.values(returnTableItems));
        setFetched(true);
        setLoaing(false);
        setErrors(resp.data.errors);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
      setFetched(false);
      setLoaing(false);
      setErrors([]);
    }
    return true;
  };
  useEffect(() => {
    setLoadingAdvertiser(true);
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      // setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : -1)));
      // eslint-disable-next-line no-nested-ternary
      // setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : (a.companyName < b.companyName ? -1 : 0))));
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);
  const advertiserOptions = useMemo(() => advertisers.map(a => ({ value: a._id, label: a.companyName })), [advertisers]);
  // useEffect(() => {
  //   fetchAmountReport();
  // }, [startDate, endDate, selectedAdvertisers]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Category Performance By Brand', path: false },
            ]}
          />
        </Col>
      </Row>
      {fetched && errors?.length ? (
        <Errors errors={errors} />
      ) : null}
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3 className="d-inline">Category Performance By Brand</h3>
              </CardTitle>
              <hr />
              <Row className="mt-3" >
                <Col>
                  <Label className="bold-text">Conversion Date Range</Label>
                  <Filter
                    startDateInitial={moment(startDate).toDate()}
                    endDateInitial={moment(endDate).endOf('day').toDate()}
                    selectedButtonInitial="last_3_month"
                    setValue={(param, value) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                    usedForConversions
                    isLoading={!!loading}
                    options={[
                      { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
                      { value: 'last_month', label: 'Last Month' },
                      { value: 'year_to_date', label: 'Year to Date' },
                      { value: 'last_3_month', label: 'Last 3 Months' },
                      { value: 'last_6_month', label: 'Last 6 Months' },
                      {
                        value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
                      },
                    ]}
                    selectDateRangeFunction={(range) => {
                      let dateFrom;
                      let dateTo;
                      let showCustom = false;
                      switch (range) {
                        case 'current_month':
                          showCustom = false;
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = (moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).toDate();
                          break;
                        case 'last_month':
                          showCustom = false;
                          dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
                          dateTo = moment().subtract(1, 'month').endOf('month').toDate();
                          break;
                        case 'last_3_month':
                          showCustom = false;
                          dateFrom = moment().subtract(3, 'month').startOf('day').toDate();
                          dateTo = (moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).toDate();
                          break;
                        case 'last_6_month':
                          showCustom = false;
                          dateFrom = moment().subtract(6, 'month').startOf('day').toDate();
                          dateTo = (moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).toDate();
                          break;
                        case 'year_to_date':
                          showCustom = false;
                          dateFrom = moment().startOf('year').toDate();
                          dateTo = (moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).toDate();
                          break;
                        case 'custom':
                          showCustom = true;
                          dateFrom = moment().startOf('Year').toDate();
                          dateTo = moment().subtract(1, 'day').endOf('day').toDate();
                          break;
                        default:
                          dateFrom = moment().startOf('month').toDate();
                          dateTo = moment().subtract(1, 'day').endOf('day').toDate();
                          break;
                      }
                      console.log({ dateFrom, dateTo, showCustom });
                      return { dateFrom, dateTo, showCustom };
                    }
                  }
                  />
                </Col>
                <Col lg="auto">
                  <div>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await fetchAmountReport();
                      }}
                      className="mr-2 btn-sm mt-xl-0 mt-lg-3 mb-0"
                      disabled={!!loading}
                    >
                      Run Report
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Select
                    isDisabled={loadingAdvertiser}
                    value={selectedAdvertisers}
                    options={advertiserOptions}
                    onChange={setSelectedAdvertisers}
                    placeholder="Select Brand..."
                    isMulti
                  />
                </Col>
                <Col />
              </Row>

              <Row className="mt-3">
                <Col sm="auto" className="pt-3" >
                  <div
                    className="form-group mt-3"
                    style={{ width: '250px' }}
                  >
                    {fetched ? (
                      <Select
                        placeholder="Select Property to Chart..."
                        value={selectedOption}
                        options={dataOptions}
                        onChange={setSelectedOption}
                      />) : null
                    }
                  </div>
                </Col>
                <Col/>
              </Row>
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {/* loading ? (
                <Row className="mt-3">
                  <Col className="text-center">
                    <Spinner size="lg" />
                  </Col>
                </Row>) : null
              */}
              {!loading && fetched ? (
                <Total tableItems={tableItems} errors={errors} type="category" />
                ) : null
              }
              {!loading && fetched ? (
                <Row className="mt-3">
                  <Col className="">
                    <ChartComponent
                      selectedAdmins={items}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Contacts"
                      isLoading={loading}
                      total={selectedOption?.value}
                      showLabels={false}
                    />
                  </Col>
                </Row>) : null
              }
              <Row>
                <Col>
                  {fetched && !loading ? (
                    <TableComponent
                      data={tableItems}
                      errors={errors}
                    />) : null}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(SummaryReport);
