import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import { Row, Col, Container, Card, CardBody, CardTitle, FormGroup, Label } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, withStyles } from '@material-ui/core';
import InfoCircleOutlineIcon from 'mdi-react/InfoCircleOutlineIcon'

import Filter from './TimePeriodFilter';
import CheckBoxField from '../../../../shared/components/CheckBox';

import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';

import { clear } from '../../../../shared/helpers/LocalStorageCache';

// import GenerationInfo from '../GenerationInfoV2';
import ChartComponent from './ChartComponent';
import TableComponent from './TableComponent';

const dataOptions = [
  { value: 'byDay', label: 'By Day' },
  { value: 'week', label: 'By Week' },
  { value: 'month', label: 'By Month' },
  { value: 'total', label: 'Over Time' },
];

const WideTooltip = withStyles({
  tooltip: {
    width: '500px',
  },
})(Tooltip);

const SummaryReport = ({ addNotice }) => {
  const [loading, setLoaing] = useState(false);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState((moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState({ startDate, endDate });

  const [identity] = useState(Math.random().toString(36).substring(2));
  //   const [pToCategories, setPToCategories] = useState([]);
  // const [mc, setMc] = useState({});
  const [fetched, setFetched] = useState(false);
  // const [show0, setShow0] = useState(false);
  const [liveData, setLiveData] = useState(false);

  
  const [showLabels, setShowLabels] = useState(false);
  const [chartDataConversions, setChartDataConversions] = useState([]);
  const [chartDataConversionsTotal, setChartDataConversionsTotal] = useState([]);
  const [chartDataContacts, setChartDataContacts] = useState([]);
  const [chartDataCreators, setChartDataCreators] = useState([]);
  const [chartDataCreatorsArray, setChartDataCreatorsArray] = useState([]);
  const [chartDataContracts, setChartDataContracts] = useState([]);
  const [chartDataContent, setChartDataContent] = useState([]);
  const [chartDataContentYoutube, setChartDataContentYoutube] = useState([]);

  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(dataOptions[0]);
  const [totalCreators, setTotalCreators] = useState(0);


  useEffect(() => {
    setLoadingAdvertiser(true);
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      // setOffers(resp.data.offers);
      setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : -1)));
      // eslint-disable-next-line no-nested-ternary
      // setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : (a.companyName < b.companyName ? -1 : 0))));
      setLoadingAdvertiser(false);
    }).catch(() => {
      setLoadingAdvertiser(false);
      addNotice({
        message: 'There was an error when try load campaigns, Check out console to see more information.',
        type: 'error',
      });
    });
  }, []);
  const fetchAmountReport = useAsyncDebounce(async () => {
    setLoaing(true);
    clear();
    setSelectedDate({ startDate, endDate });
    try {
      const resp = await axios.post('/api/portal/reports/post-report5c-by-date', {
        startDate,
        endDate,
        advertisers: [selectedAdvertisers.value],
        identity,
        liveData,
      });
      if (resp.data.success) {
        setFetched(true);
        setLoaing(false);
        console.log(resp.data);
        const _chartDataConversions = {};
        resp.data.conversions.forEach((data) => {
          if (!_chartDataConversions[data.brand]) _chartDataConversions[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Conversions' }, data: {} };
          _chartDataConversions[data.brand].data[data.date] = data.value;
        });

        const _chartDataConversionsTotal = {};
        resp.data.conversionsTotal.forEach((data) => {
          if (!_chartDataConversionsTotal[data.brand]) _chartDataConversionsTotal[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Total Conversions' }, data: {} };
          _chartDataConversionsTotal[data.brand].data[data.date] = data.value;
        });

        const _setChartDataContacts = {};
        resp.data.contacts.forEach((data) => {
          if (!_setChartDataContacts[data.brand]) _setChartDataContacts[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Contacts' }, data: {} };
          _setChartDataContacts[data.brand].data[data.date] = data.value;
        });
        const _setChartDataCreators = {};
        const _setChartDataCreatorsArray = {};
        const uniqueCreators = {};
        resp.data.creators.forEach((data) => {
          if (!_setChartDataCreators[data.brand]) _setChartDataCreators[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Creators' }, data: {} };
          _setChartDataCreators[data.brand].data[data.date] = data.value;
          _setChartDataCreatorsArray[data.date] = data.creators;
          data.creators.forEach((creator) => { uniqueCreators[creator] = true; });
        });
        const _setChartDataContracts = {};
        resp.data.contracts.forEach((data) => {
          if (!_setChartDataContracts[data.brand]) _setChartDataContracts[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Contracts' }, data: {} };
          _setChartDataContracts[data.brand].data[data.date] = data.value;
        });
        const _setChartDataContent = {};
        resp.data.content.forEach((data) => {
          if (!_setChartDataContent[data.brand]) _setChartDataContent[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Content' }, data: {} };
          _setChartDataContent[data.brand].data[data.date] = data.value;
        });

        const _setChartDataContentYoutube = {};
        resp.data.youtubeContent.forEach((data) => {
          if (!_setChartDataContentYoutube[data.brand]) _setChartDataContentYoutube[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Youtube Content' }, data: {} };
          _setChartDataContentYoutube[data.brand].data[data.date] = data.value;
        });

        setChartDataContacts(Object.values(_setChartDataContacts));
        setChartDataConversions(Object.values(_chartDataConversions));

        setChartDataConversionsTotal(Object.values(_chartDataConversionsTotal))
        setChartDataCreators(Object.values(_setChartDataCreators));
        setChartDataCreatorsArray(_setChartDataCreatorsArray);
        setChartDataContracts(Object.values(_setChartDataContracts));
        setChartDataContent(Object.values(_setChartDataContent));
        setChartDataContentYoutube(Object.values(_setChartDataContentYoutube));
        setTotalCreators(Object.keys(uniqueCreators).length);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
    return true;
  }, 500);
  useEffect(() => {
    if (selectedAdvertisers?.value) {
      setFetched(true);
      fetchAmountReport();
    }
  }, [startDate, endDate, selectedAdvertisers, liveData]);
  const advertiserOptions = useMemo(() => advertisers.map(a => ({ value: a._id, label: a.companyName })), [advertisers]);
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Company Overview', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Company Overview </h3>
              </CardTitle>
              <hr />
              <Row className="mt-3" >
                <Col>
                  <Filter
                    setValue={(param, value) => {
                      console.log(value);
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                    isLoading={loading}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Select
                    isDisabled={loadingAdvertiser}
                    value={selectedAdvertisers}
                    options={advertiserOptions}
                    onChange={setSelectedAdvertisers}
                    placeholder="Select Brand..."
                  />
                </Col>
                <Col />
              </Row>
              <Row className="mt-3">
                <Col md={8}>
                  <FormGroup>
                    <Label>
                      <CheckBoxField
                        name="previewTypeInfluencers"
                        label=""
                        value={liveData}
                        onChange={e => e.target && setLiveData(e.target?.checked)}
                        className=" float-left"
                      />
                      Use real-time conversion data
                      <WideTooltip title={<div style={{ fontSize: '12px', maxWidth: '500px', lineHeight: 1.5 }}>Use real-time conversion data from HasOffers.<br/><strong>Note:</strong> This will increase the time required to generate the report.</div>}>
                        <IconButton size="small" style={{ marginTop: '-3px' }}>
                          <InfoCircleOutlineIcon size="17" className='text-blue'/>
                        </IconButton>
                      </WideTooltip>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div
                    className="form-group mt-3"
                    style={{ width: '250px' }}
                  >
                    {fetched ? (
                      <Select
                        placeholder="Select Property to Chart..."
                        value={selectedOption}
                        options={dataOptions}
                        onChange={setSelectedOption}
                      />) : null
                    }
                  </div>
                </Col>
                <Col />
              </Row>
              {fetched ? (
                <Row className="mt-3">
                  <Col md={8}>
                    <FormGroup>
                      <CheckBoxField
                        name="showLabels"
                        label="Show Labels on Chart"
                        value={showLabels}
                        onChange={e => setShowLabels(e.target?.checked || false)}
                      />
                    </FormGroup>
                  </Col>
                </Row>) : null}
              <Row className="mt-3">
                <Col sm={6}>
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataContacts}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Contacts"
                      isLoading={loading}
                      total={selectedOption?.value}
                      showLabels={showLabels}
                    />) : null
                  }
                </Col>
                <Col sm={6}>
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataContracts}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Contracts"
                      isLoading={loading}
                      total={selectedOption?.value}
                      showLabels={showLabels}
                    />) : null
                    }
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={6}>
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataContent}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Content"
                      isLoading={loading}
                      total={selectedOption?.value}
                      showLabels={showLabels}
                    />) : null
                  }
                </Col>
                <Col sm={6} >
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataContentYoutube}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="YouTube Content"
                      isLoading={loading}
                      total={selectedOption?.value}
                      showLabels={showLabels}
                    />) : null
                  }
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={6}>
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataConversionsTotal}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Total Conversions"
                      isLoading={loading}
                      total={selectedOption?.value}
                      showLabels={showLabels}
                    />) : null
                  }
                </Col>
                <Col sm={6}>
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataConversions}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Conversions"
                      isLoading={loading}
                      total={selectedOption?.value}
                      showLabels={showLabels}
                    />) : null
                  }
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={6}>
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataCreators}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Creators"
                      isLoading={loading}
                      total={selectedOption?.value}
                      totalValue={totalCreators}
                      itemsByArray={chartDataCreatorsArray}
                      showLabels={showLabels}
                    />) : null
                  }
                </Col>
              </Row>
              <Row>
                <Col>
                  {fetched && !loading ? (
                    <TableComponent
                      selectedAdmins={{
                        chartDataContacts, chartDataContracts, chartDataContent, chartDataContentYoutube, chartDataConversions, chartDataCreators, chartDataConversionsTotal
                      }}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      total={selectedOption?.value}
                      itemsByArray={{ chartDataCreators: chartDataCreatorsArray }}
                    />) : null}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(SummaryReport);
