import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Select from 'react-select';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import IntervalDatePickerField
  from '../../../../../Influencer/Research/Prospects/components/custom/IntervalDatePickerField';

const customStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#e9ecef' : 'white',
  }),
};

const PublishDateFilter = ({
  enableVideoPublishDateFilter,
  setEnableVideoPublishDateFilter,
  videoPublishDateFilterType,
  setVideoPublishDateFilterType,
  dateRangeFilterLabels,
  setVideoPublishDateFrom,
  setVideoPublishDateTo,
  videoPublishDateFrom,
  videoPublishDateTo,
  singleVideoPublishDate,
  setSingleVideoPublishDate,
  minDate,
}) => (
  <>
    <Label className="bold-text">Publish Date</Label>
    <Row>
      <Col sm={1}>
        <FormGroup
          style={{ paddingTop: '8px', paddingLeft: '8px' }}
        >
          <CheckBoxField
            name="VideoPublishDate"
            value={enableVideoPublishDateFilter}
            onChange={() => { setEnableVideoPublishDateFilter(!enableVideoPublishDateFilter); }}
          />
        </FormGroup>
      </Col>
      <Col sm={4} style={{ paddingLeft: '20px', paddingRight: 0 }}>
        <Select
          value={videoPublishDateFilterType}
          onChange={(option) => {
            // if ((publishDateFilterType.value !== 'Between' && option.value === 'Between') || (publishDateFilterType.value === 'Between' && option.value !== 'Between')) {
            //   setVideoPublishDateFrom(null);
            //   setVideoPublishDateTo(null);
            //   setSingleVideoPublishDate(null);
            // }
            setVideoPublishDateFilterType(option);
          }}
          options={dateRangeFilterLabels}
          isDisabled={!enableVideoPublishDateFilter}
          styles={customStyles}
        />
      </Col>
      <Col sm={6} style={{ paddingRight: '0px' }}>
        {videoPublishDateFilterType.value === 'Between' ? (
          <div style={{ marginRight: '-30px' }}>
            <IntervalDatePickerField
              onChange={({ start, end }) => {
                setVideoPublishDateFrom(start);
                end?.setHours(23, 59, 59);
                setVideoPublishDateTo(end);
              }}
              startDate={videoPublishDateFrom}
              endDate={videoPublishDateTo}
              maxDate={new Date()}
              minDate={minDate?.toDate()}
              placeholderFrom="Start Date"
              placeholderTo="End Date"
              verticalFields
              disabled={!enableVideoPublishDateFilter}
              datePickerCustomClass="datepicker-in-profile"
            />
          </div>
        ) : (
          <div className="date-picker datepicker-in-profile">
            <DatePicker
              selected={singleVideoPublishDate}
              onChange={(date) => {
                if (videoPublishDateFilterType.value === '<=' || videoPublishDateFilterType.value === '>') {
                  date?.setHours(23, 59, 59);
                }
                setSingleVideoPublishDate(date);
              }}
              dateFormat="MMM d, yyyy"
              maxDate={new Date()}
              minDate={minDate?.toDate()}
              placeholderText="Select Date"
              dropDownMode="select"
              isClearable
              wrapperClassName="date-picker--interval w-100"
              className="form-control form-control-sm datepicker-in-profile w-100"
              disabled={!enableVideoPublishDateFilter}
            />
            {singleVideoPublishDate === null && (
              <svg
                className="mdi-icon"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '22px',
                  fill: '#999',
                  pointerEvents: 'none',
                }}
              >
                <path
                  d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                />
              </svg>
            )}
          </div>
        )}
      </Col>
    </Row>
  </>
);

PublishDateFilter.propTypes = {
  enableVideoPublishDateFilter: PropTypes.bool.isRequired,
  setEnableVideoPublishDateFilter: PropTypes.func.isRequired,
  videoPublishDateFilterType: PropTypes.objectOf(PropTypes.any).isRequired,
  setVideoPublishDateFilterType: PropTypes.func.isRequired,
  dateRangeFilterLabels: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVideoPublishDateFrom: PropTypes.func.isRequired,
  setVideoPublishDateTo: PropTypes.func.isRequired,
  videoPublishDateFrom: PropTypes.string,
  videoPublishDateTo: PropTypes.string,
  singleVideoPublishDate: PropTypes.objectOf(PropTypes.any),
  setSingleVideoPublishDate: PropTypes.func.isRequired,
  minDate: PropTypes.objectOf(PropTypes.any),
};

PublishDateFilter.defaultProps = {
  videoPublishDateFrom: null,
  videoPublishDateTo: null,
  singleVideoPublishDate: null,
  minDate: null,
};

export default PublishDateFilter;
