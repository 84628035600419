import React from 'react';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { currentTimezoneAggr } from '../../../../../../shared/helpers/Dates';
import WVFormatter from '../../../../../../shared/helpers/WVFormatter';
import ContextMenuInList from './table/ContextMenuInList';
import ComplianceStatus from './table/ComplianceStatus';

// const sValues = ['Needs Review', 'Pending Edits', 'Approved', 'Terminated'];

export default function TableColumns(setDisableNote, setCurrentContact, setShowNotesModal, setVersion, setShowStatusHistoryModal, setOpenTabs) {
  console.log('test and run!!!');
  return [
    {
      Header: 'Creator',
      accessor: () => null,
      id: 'fullName',
      Cell: record => (
        <div style={{ width: '200px' }}>
          {record.row.original.params?.influencerObject?._id ? (
            <a
              title="Show Creator Profile"
              target="_blank"
              rel="noopener noreferrer"
              href={`/influencer/profile/${record.row.original.params.influencerObject._id}`}
            >
              {`${record.row.original.params.firstname || ''} ${record.row.original.params.lastname || ''}`}
            </a>
          ) : (
            <span>{`${record.row.original.params.firstname || ''} ${record.row.original.params.lastname || ''}`}</span>
          )}
          &nbsp;
          <a
            title="Show HubSpot Contact"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.row.original.params.hs_object_id}`}
          >
            <i className="fab fa-hubspot" />
          </a>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
      width: 200,
    },
    {
      Header: 'Channel',
      accessor: () => null,
      id: 'channel',
      Cell: record => (
        <div style={{ width: '200px' }}>
          {record.row.original.params.mediaChannels && record.row.original.params.mediaChannels.youtube_channel_url && (
            <a
              href={record.row.original.params.mediaChannels.youtube_channel_url}
              target="_blank"
              rel="noreferrer"
              key="youtube"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/youtube.png"
                alt="youtube"
                title={record.row.original.params.mediaChannels.youtube_channel_name || 'YouTube Channel'}
                style={{ width: '20px' }}
              />
            </a>
          )}
          {record.row.original.params.mediaChannels && record.row.original.params.mediaChannels.instagram_channel && (
            <a
              href={record.row.original.params.mediaChannels.instagram_channel}
              target="_blank"
              rel="noreferrer"
              key="instagram"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/instagram.png"
                alt="instagram"
                title={record.row.original.params.mediaChannels.instagram_channel_name || 'Instagram Profile'}
                style={{ width: '20px' }}
              />
            </a>
          )}
          {record.row.original.params.mediaChannels && record.row.original.params.mediaChannels.facebook_channel_url && (
            <a
              href={record.row.original.params.mediaChannels.facebook_channel_url}
              target="_blank"
              rel="noreferrer"
              key="facebook"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/facebook.png"
                alt="facebook"
                title="Facebook Profile"
                style={{ width: '20px' }}
              />
            </a>
          )}
          {record.row.original.params.mediaChannels && record.row.original.params.mediaChannels.twitterhandle && (
            <a
              href={record.row.original.params.mediaChannels.twitterhandle}
              target="_blank"
              rel="noreferrer"
              key="twitter"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/twitter.png"
                alt="twitter"
                title="Twitter Profile"
                style={{ width: '20px' }}
              />
            </a>
          )}
          {record.row.original.params.mediaChannels && record.row.original.params.mediaChannels.tiktok_channel_url && (
            <a
              href={record.row.original.params.mediaChannels.tiktok_channel_url}
              target="_blank"
              rel="noreferrer"
              key="tiktok"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/tiktok.png"
                alt="twitter"
                title="TikTok Channel"
                style={{ width: '20px' }}
              />
            </a>
          )}
          {record.row.original.params.mediaChannels && record.row.original.params.mediaChannels.twitch_account && (
            <a
              href={record.row.original.params.mediaChannels.twitch_account}
              target="_blank"
              rel="noreferrer"
              key="twitch"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/twitch.png"
                alt="twitch"
                title="Twitch Account"
                style={{ width: '20px' }}
              />
            </a>
          )}
          {record.row.original.params.mediaChannels && record.row.original.params.mediaChannels.apple_podcast_url && (
            <a
              href={record.row.original.params.mediaChannels.apple_podcast_url}
              target="_blank"
              key="podcast"
              rel="noreferrer"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/podcast_128.png"
                alt="podcast"
                title={record.row.original.params.mediaChannels.podcast_title || 'Apple Podcast'}
                style={{ width: '20px' }}
              />
            </a>
          )}
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
      width: 180,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'safetyCheck',
      Cell: record => (
        <div style={{ width: '50px', marginRight: '10px' }}>
          <Tooltip title="Safety Check">
            <IconButton onClick={() => { setCurrentContact(record.row.original); setOpenTabs(true); }}><i className="fa fa-shield-alt" /></IconButton>
          </Tooltip>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
      width: 50,
    },
    {
      Header: 'Brands',
      accessor: () => null,
      id: 'brands',
      Cell: (record) => {
        let brands = [];
        if (record.row.original.populated?.deals?.length > 0) {
          brands = record.row.original.populated.deals.map(deal => deal.params.dealTerms.brand || '');
        }
        if (brands.length > 0) brands = [...new Set(brands)];
        return (
          <div style={{ width: '200px' }}>
            <span>{brands.join(', ')}</span>
          </div>
        );
      },
      className: 'align-middle',
      disableSortBy: true,
      width: 200,
    },
    {
      Header: 'Compliance Status',
      accessor: () => null,
      id: 'creatorReviewStatus',
      Cell: p => (
        <div>
          {p.row.original ? (
            <ComplianceStatus
              record={p.row.original}
              showNotes={() => { setDisableNote(false); setCurrentContact(p.row.original); setShowNotesModal(true); }}
              setVersion={setVersion}
            />
          ) : (
            <span>Terminated</span>
          )}
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: true,
      width: 200,
    },
    {
      Header: `Last Review Date (${currentTimezoneAggr()})`,
      accessor: p => (WVFormatter.getServerDateTime(p.params?.creatorReviewStatus?.currentStatusTimestamp)),
      id: 'creatorReviewStatus.currentStatusTimestamp',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{WVFormatter.getServerDateTime(value.row.original.params?.creatorReviewStatus?.currentStatusTimestamp)}</span>
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 170,
    },
    // {
    //   Header: `Latest Publish Date (${currentTimezoneAggr()})`,
    //   accessor: p => (WVFormatter.getServerDateTime(p.params?.latestDealPublishedDate)),
    //   id: 'latestDealPublishedDate',
    //   Cell: value => (
    //     <div style={{ paddingRight: '10px' }}>
    //       <span>{WVFormatter.getServerDateTime(value.row.original.params?.latestDealPublishedDate)}</span>
    //     </div>
    //   ),
    //   className: 'align-middle text-center',
    //   disableSortBy: false,
    //   width: 170,
    // },
    {
      Header: '',
      accessor: () => null,
      id: 'contact_actions',
      width: 50,
      Cell: p => (
        <ContextMenuInList
          record={p.row.original}
          setShowStatusHistoryModal={setShowStatusHistoryModal}
          setCurrentContact={setCurrentContact}
        />
      ),
      disableSortBy: true,
      className: 'd-flex align-items-center align-middle',
    },
  ];
}
