import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { axios } from 'ApiClient';
import CreatorInList from './CreatorInList';
import TableCellContextMenu from '../../../../../../../../shared/components/table/TableCellContextMenu';
import MatTableList from '../../../../../../../../shared/tables/materialTable/TableRedux';
import HsDealInList from './DealInList';

// import NumberOfVideos from './NumberOfVideos';
import CAC from '../../../../../../../../shared/components/cac/CAC';
import PAR from '../../../../../../../../shared/components/cac/PAR';
import PredictedCAC from '../../../../../../../../shared/components/cac/PredictedCAC';
import DefaultApprovalStatus from './DefaultApprovalStatus';
import LastPublishDate from '../../../../CpmPricingApprovals/components/custom/Table/LastPublishDate';
// import DealJustification from './DealJustification';
import { formatCurrency, formatIntNumber } from '../../../../../../../../shared/helpers/WVFormatter';
import { goToPage } from '../../../../../../../../shared/helpers/WVUtilities';
import Hook from '../../../../../../../../shared/hooks';
import { calculateDays, calculateConversionsCount, getProjectedPercent, calculatePayout } from '../../../../../../../../shared/helpers/CACHelper';
import { getConstant } from '../../../../../../../../shared/helpers/WVConstants';

const Table = React.memo(({
  settings,
  projectedSettings,
  // handleSelectCheckbox,
}) => {
  const [showModal] = Hook.useShowModalHook('refreshCPMProspectFromOrder');
  const [showDeleteModal] = Hook.useShowModalHook('DeleteModalDefault');
  const [showHistoryModal] = Hook.useShowModalHook('HistoryModal');
  const reload = Hook.useReloadTableHook();
  const dealstages = (getConstant('deal', 'pipelines').find(p => p.label === 'Main Pipeline')).stages;
  return (
    <MatTableList
      useFlex
      useCheckboxes
      checkboxHandler={() => {}}
      enableWarning={true}
      notFoundProps={{ title: 'No CPM Deals Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'creatorName',
          Header: () => 'Creator Name',
          accessor: record => (record?.params?.influencerName || ''),
          Cell: p => useMemo(
            () => (
              <CreatorInList
                record={p.row.original}
              />
            ),
            [p.row.original],
          ),
          width: 350,
          className: 'd-flex align-items-center',
          isFreeze: true,
        },
        {
          id: 'agentName',
          Header: () => 'Agent',
          accessor: record => (record?.params?.influencerName || ''),
          Cell: p => useMemo(
            () => {
                const { agentUsers } = p.row.original.params;
                return agentUsers?.length > 0 ? (
                  <>
                    {agentUsers.map(rightAgent => (
                      <div>
                        <a
                          title="Agent Profile"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/agent/profile/${rightAgent._id}`}
                        >
                          {`${rightAgent.first_name || ''} ${rightAgent.last_name || ''}`}
                        </a>
                      </div>
                    )) || '-'
                    }
                  </>) : '';
            },
            [p.row.original],
          ),
          width: 150,
          className: 'd-flex align-items-center',
        },
        {
          id: 'cpmPriceApprovals',
          Header: () => 'PA Group Name',
          accessor: () => (''),
          Cell: p => useMemo(() => {
            const record = p.row.original;
            const val = (typeof record.populated.cpmPriceApprovals !== 'undefined' && record.populated.cpmPriceApprovals) ?
              record.populated.cpmPriceApprovals.params :
              null;
            return val ? `${val.name}` : null;
          }, [p.row.original]),
          className: 'd-flex align-items-center',
        },
        {
          id: 'cspOwner',
          Header: () => 'Deal Owner',
          accessor: () => '',
          Cell: p => useMemo(() => {
            const record = p.row.original;
            const val = (typeof record.populated.cspOwner !== 'undefined' && record.populated.cspOwner) ?
              record.populated.cspOwner.params :
              null;
            return val ? `${val.firstName} ${val.lastName}` : null;
          }, [p.row.original]),
          className: 'd-flex align-items-center',
          // sortable: true,
          // disableSortBy: false,
        },
        {
          id: 'brandSafety',
          Header: () => 'Brand Safety',
          Cell: p => useMemo(() => {
            const record = p.row.original.populated?.deal?.params;
            return record?.authorizedPayment?.creatorBrandApproved ? 'Approved' : 'Not Approved';
          }, [p.row.original]),
          className: 'd-flex align-items-center',
          // sortable: true,
          // disableSortBy: false,
        },
        {
          id: 'dealJustification',
          Header: () => 'Justification',
          accessor: p => p.params.dealJustification,
          Cell: p => p.value,
          className: 'd-flex align-items-center',
        },
        {
          id: 'contentReleaseDate.currentDate',
          Header: () => 'Month',
          accessor: p => (p.params.contentReleaseDate?.currentDate ? moment.utc(p.params.contentReleaseDate?.currentDate).format('MMMM, YYYY') : null),
          className: 'd-flex align-items-center',
        },
        {
          id: 'approvalStatus.status',
          Header: () => 'IL PA Status',
          Cell: p => useMemo(() => (
            <DefaultApprovalStatus
              placeholder="IL PA Status"
              val={p.row.original.params?.approvalStatus?.status || ''}
              notesVal={p.row.original.params?.notes || ''}
              name="notes"
              p={p}
            />), [p.row.original]),
          width: 220,
          className: 'd-flex align-items-center',
          sortable: false,
        },
        {
          id: 'approvalsBrandStatus.status',
          Header: () => 'Brand PA Status',
          Cell: p => useMemo(() => (
            <DefaultApprovalStatus
              placeholder="Brand PA Status"
              val={p.row.original.params?.approvalsBrandStatus?.status || ''}
              notesVal={p.row.original.params?.brandNotes || ''}
              name="brandNotes"
              p={p}
            />), [p.row.original]),
          width: 220,
          className: 'd-flex align-items-center',
          sortable: false,
        },
        {
          id: 'deal',
          Header: () => 'Deal',
          accessor: record => (record?.params?.dealName || ''),
          Cell: p => useMemo(() => (
            <HsDealInList
              record={p.row.original}
              openDealsModal={() => {
              }}
            />), [p.row.original]),
          width: 200,
          className: 'd-flex align-items-center',
          disableSortBy: true,
        },
        {
          id: 'dealStage',
          Header: () => 'Deal Stage',
          // accessor: record => (record?.params?.dealName || ''),
          Cell: p => useMemo(() => {
            const dealStage = p.row.original.params?.dealObject?.dealstage ? dealstages[Number(p.row.original.params.dealObject.dealstage)] : '-';
            return dealStage || '-';
          }, [p.row.original]),
          width: 200,
          className: 'd-flex align-items-center',
          disableSortBy: true,
        },
        {
          id: 'v30',
          Header: () => 'V30',
          accessor: record => (record?.params?.v30),
          Cell: p => formatIntNumber(p.value || 0),
          width: 120,
          className: 'd-flex align-items-center',
        },
        {
          id: 'amount.amount',
          Header: () => 'Video Price',
          accessor: record => Number(record?.params?.amount?.amount || 0),
          Cell: p => formatCurrency(p.value || 0),
          width: 180,
          className: 'd-flex align-items-center',
        },
        {
          id: 'cpm',
          Header: () => 'CPM',
          accessor: record => (record?.params?.cpm || 0),
          Cell: p => formatCurrency(Number(p.row.original.params.cpm || 0)),
          width: 120,
          className: 'd-flex align-items-center',
        },
        {
          id: 'totalPrice',
          Header: () => 'Total Price',
          accessor: record => (record?.params?.totalPrice),
          Cell: p => formatCurrency(Number(p.row.original.params.totalPrice || 0)),
          width: 120,
          className: 'd-flex align-items-center',
        },
        {
          id: 'lastPublicationDate',
          Header: () => 'Last Publication Date',
          accessor: p => (p.params.mediaPublishedDate ? moment.tz(moment.utc(p.params.mediaPublishedDate).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') : '-'),
          Cell: p => (<LastPublishDate p={p} />),
          className: 'd-flex align-items-center',
        },
        {
          Header: 'Days Since Last Publish',
          accessor: p => calculateDays(p.params),
          id: 'days',
          Cell: p => (
            <span>
              {formatIntNumber(p.value)}   ({formatIntNumber(Number(getProjectedPercent(p.value, { settings, projectedSettings }) || 0), 2)}%)
            </span>
          ),
          width: 100,
          sortable: false,
          disableSortBy: true,
        },
        {
          Header: 'Conversions',
          accessor: deal => Number(calculateConversionsCount(deal.params) || 0),
          id: 'conversions',
          // Cell: p => formater.formatIntNumber(p.value),
          Cell: p => (
            <span>
              {formatIntNumber(p.value)}
            </span>
          ),
          width: 120,
          sortable: false,
          disableSortBy: true,
        },
        {
          Header: 'Price of Recent Deal',
          accessor: deal => deal.params?.dealForCac ? calculatePayout({ dealForCac: deal.params?.dealForCac, CPApaidConversionsArray: deal.params.CPApaidConversionsArray }) : 0,
          id: 'PriceofRecentDeal',
          // Cell: p => formater.formatIntNumber(p.value),
          Cell: p => p.row.original.params?.dealForCac ? (
            <span>
              {formatCurrency(p.value)}
            </span>
          ) : null,
          width: 120,
          sortable: false,
          disableSortBy: true,
        },
        {
          Header: 'CAC',
          accessor: () => null,
          id: 'cac',
          Cell: p => useMemo(() => (
            <CAC
              dealForCac={p.row.original.params?.dealForCac}
              CPApaidConversionsArray={p.row.original.params?.CPApaidConversionsArray || []}
              CPMpaidConversionsCount={p.row.original.params?.CPMpaidConversions || 0}
              brand={p.row.original.params?.targetBrandObject}
              settings={{ settings, projectedSettings }}
            />), [p.value, p.row.original]),
          width: 120,
          sortable: false,
          disableSortBy: true,
        },
        {
          Header: 'Predicted CAC',
          accessor: () => null,
          id: 'projectedCac',
          Cell: p => useMemo(() => (
            <PredictedCAC
              dealForCac={p.row.original.params?.dealForCac}
              CPApaidConversionsArray={p.row.original.params?.CPApaidConversionsArray || []}
              CPMpaidConversionsCount={p.row.original.params?.CPMpaidConversions || 0}
              brand={p.row.original.params?.targetBrandObject}
              settings={{ settings, projectedSettings }}
              mediaPublishedDate={p.row.original.params?.mediaPublishedDate || ''}
            />
          ), [p.value, p.row.original]),
          width: 120,
          sortable: false,
          disableSortBy: true,
        },
        {
          Header: 'PAR',
          accessor: () => null,
          id: 'par',
          Cell: p => useMemo(() => (
            <PAR
              row={{
                latestDeal: p.row.original.params?.dealForCac,
                offer: { advertiser: p.row.original.params?.targetBrandObject },
                CPApaidConversionsArray : p.row.original.params?.CPApaidConversionsArray || [],
                CPMpaidConversions: new Array(p.row.original.params?.CPMpaidConversions || 0).fill(1),
                publishDate: p.row.original.params?.mediaPublishedDate,
                revenue: Number(p.row.original.params?.dealForCac?.dealTerms?.bh_cpm_total_cost || 0)
              }}
              settings={{ settings, projectedSettings }}
            />), [p.value, p.row.original]),
          width: 120,
          sortable: false,
          disableSortBy: true,
        },
        {
          Header: '',
          accessor: () => null,
          id: 'cpm_prospect_actions',
          width: 50,
          Cell: (p) => {
            const contextMenuOptions = [
              {
                label: 'Change History',
                handler: () => showHistoryModal({ record: p.row.original, title: 'Status History' }),
              },
              { type: 'divider', label: 'divider00' },
              {
                label: 'Remove from Price Approvals',
                handler: () => {
                  showDeleteModal({
                    title: 'Delete Cpm Deal',
                    message: 'Are you sure you want to delete the selected CPM Deal?',
                    deleteRecord: async () => {
                      await axios({
                        method: 'post',
                        url: '/api/portal/prospects/post-delete-cpm-prospect',
                        data: { id: p.row.original.params._id },
                      });
                    },
                    successFunction: () => {
                      reload();
                    },
                  });
                },
                disabled: Boolean(p.row.original.populated.cpmInsertionOrder || p.row.original.populated.cpmInvoice),
              },
              { type: 'divider', label: 'divider0' },
              {
                label: 'View Tubular Labs Profile',
                handler: () => { goToPage(`https://tubularlabs.com/creator/${p.row.original.params.tubularDetails.tubularCreatorId}`); },
              },
            ];
            if (p.row.original.params.influencer) {
              contextMenuOptions.push({ label: 'View Creator Profile', handler: () => goToPage(`/influencer/profile/${p.row.original.params.influencer}`) });
            }

            if (p.row.original.populated?.deal?.params.ilDealData?.agentUsers?.length > 0) {
              contextMenuOptions.push({ label: 'View Agent Profile', handler: () => goToPage(`/agent/profile/${p.row.original.populated.deal.params.ilDealData?.agentUsers[0]}`) });
            }
            if (p.row.original.params.hsUserId) {
              contextMenuOptions.push({ label: 'View HubSpot Contact', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.row.original.params.hsUserId}/`) });
            }


            if (p.row.original.params.dealObject?.hs_object_id) {
              contextMenuOptions.push({ label: 'View HubSpot Deal', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original.params.dealObject.hs_object_id}/`) });
            }

            contextMenuOptions.push({ type: 'divider', label: 'divider2' });
            contextMenuOptions.push({
              label: 'Refresh Statistics',
              handler: () => showModal({ record: p.row.original }),
            });

            return (
              <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                <TableCellContextMenu options={contextMenuOptions} />
              </div>
            );
          },
          disableSortBy: true,
          className: 'd-flex align-items-center align-middle',
        },
      ]}
    />
  );
});

Table.propTypes = {
  settings: PropTypes.objectOf(PropTypes.any).isRequired,
  projectedSettings: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};

export default Table;
