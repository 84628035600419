import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  FormFeedback,
  Button,
  ButtonToolbar,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import WVValidator from '../../../shared/helpers/WVValidator';
import withNotice from '../../App/store/with-notice';
import { initializeSession } from '../../App/store/store';


class AccountSettings extends PureComponent {
  constructor() {
    super();

    // const { adminUser } = this.props;

    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      showOldPassword: false,
      showNewPassword: false,
      showRepeatPassword: false,
      errors: {},
      isLoadingData: false,
      isPopoverOpen: false,
    };
  }

  componentDidMount() {
    document.title = 'IL Admin: Account Settings';
    const { adminUser } = this.props;
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      email: adminUser.email,
      firstName: adminUser.firstName,
      lastName: adminUser.lastName,
    });
  }

  onDataChanged = (event) => {
    const { name, value } = event.target;
    const state = {
      ...this.state,
      [name]: value,
    };

    delete state.errors[name]; // clear errors

    this.setState(state);
  }

  onSubmit = () => {
    if (this.state.isLoadingData) { return false; }
    if (!this.validateErrors()) { return false; }

    const { dispatch } = this.props;

    this.setState({
      isLoadingData: true,
    });

    axios({
      method: 'post',
      url: '/api/portal/account-settings',
      data: {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword,
        repeatPassword: this.state.repeatPassword,
      },
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);
      if (response.data.success === true && response.data.user) {
        this.props.addNotice({
          message: 'Your account settings have been updated successfully.',
          type: 'success',
        });

        dispatch(initializeSession(response.data.user));

        this.props.history.push('/');
      } else {
        this.processSubmitError(response.data.error);
      }
    }).catch((error) => {
      // console.log('CATCH ERROR: ',error);
      this.processSubmitError(error.message);
    });
    return null;
  }

  onCancel = () => {
    this.props.history.goBack();
  }

  processSubmitError(error) {
    const state = {
      ...this.state,
      isLoadingData: false,
    };

    this.props.addNotice({
      message: error,
      type: 'error',
    });

    this.setState(state);
  }

  showOldPassword = () => {
    this.showPassword('showOldPassword');
  };
  showNewPassword = () => {
    this.showPassword('showNewPassword');
  };
  showRepeatPassword = () => {
    this.showPassword('showRepeatPassword');
  };

  showPassword = (property) => {
    this.setState({
      [`${property}`]: !this.state[`${property}`],
    });
  }

  validateErrors() {
    const errors = {};
    const {
      email, firstName, lastName, oldPassword, newPassword, repeatPassword,
    } = this.state;

    if (!WVValidator.isEmail(email)) {
      errors.email = 'Please check your email format!';
    }
    if (WVValidator.isEmpty(firstName)) {
      errors.firstName = 'Please provide your first name.';
    }
    if (WVValidator.isEmpty(lastName)) {
      errors.lastName = 'Please provide your last name.';
    }

    if (WVValidator.isEmpty(oldPassword) &&
      WVValidator.isEmpty(newPassword) &&
      WVValidator.isEmpty(repeatPassword)) {
      this.setState({
        ...this.state,
        errors,
      });
      return Object.entries(errors).length === 0;
    }

    if (WVValidator.isEmpty(this.state.oldPassword)) {
      errors.oldPassword = 'Please provide your old password!';
    }
    if (WVValidator.isEmpty(this.state.newPassword)) {
      errors.newPassword = 'Please provide your new password!';
    }
    if (this.state.newPassword.length < 8) {
      errors.newPassword = 'Yor password must consist of at least 8 symbols';
    } else if (!WVValidator.isValidPassword(this.state.newPassword)) {
      // eslint-disable-next-line
      errors.newPassword = 'Your password must consist of at least 1 number, 1 capital letter, 1 lowercase letter and 1 special character ($.,)(!%^*#)';
    }
    if (WVValidator.isEmpty(this.state.repeatPassword)) {
      errors.repeatPassword = 'Please confirm your new password!';
    }
    if (this.state.newPassword !== this.state.repeatPassword) {
      errors.repeatPassword = 'Passwords do not match!';
    }

    this.setState({
      ...this.state,
      errors,
    });

    return Object.entries(errors).length === 0;
  }

  togglePopover = () => {
    this.setState({
      isPopoverOpen: !this.state.isPopoverOpen,
    });
  };

  render() {
    const { adminUser } = this.props;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Account Settings</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} sm={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{`${adminUser.firstName} ${adminUser.lastName}`}</h5>
                  <h5 className="subhead">{`${adminUser.role}`}</h5>
                </div>
                {/* INFORMATION */}
                <section>
                  <h4 className="il-color-deep-blue font-weight-bold mt-5">Information</h4>
                  <hr className="mt-3 mb-4" />
                  <FormGroup>
                    <Label for="emailInput">Email</Label>
                    <Input
                      name="email"
                      type="email"
                      bsSize="lg"
                      value={this.state.email}
                      invalid={Object.prototype.hasOwnProperty.call(this.state.errors, 'email')}
                      onChange={this.onDataChanged}
                    />
                    <FormFeedback>{this.state.errors.email}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="firstNameInput">First Name</Label>
                    <Input
                      name="firstName"
                      type="text"
                      bsSize="lg"
                      value={this.state.firstName}
                      invalid={Object.prototype.hasOwnProperty.call(this.state.errors, 'firstName')}
                      onChange={this.onDataChanged}
                    />
                    <FormFeedback>{this.state.errors.firstName}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="lastNameInput">Last Name</Label>
                    <Input
                      name="lastName"
                      type="text"
                      bsSize="lg"
                      value={this.state.lastName}
                      invalid={Object.prototype.hasOwnProperty.call(this.state.errors, 'lastName')}
                      onChange={this.onDataChanged}
                    />
                    <FormFeedback>{this.state.errors.lastName}</FormFeedback>
                  </FormGroup>
                </section>
                {/* PASSWORD */}
                <section>
                  <h4 className="il-color-deep-blue font-weight-bold mt-5">Change Password</h4>
                  <hr className="mt-3 mb-4" />
                  <FormGroup>
                    <Label for="oldPasswordInput">Old Password</Label>
                    <InputGroup size="lg">
                      <Input
                        name="oldPassword"
                        type={this.state.showOldPassword ? 'text' : 'password'}
                        bsSize="lg"
                        invalid={Object.prototype.hasOwnProperty.call(this.state.errors, 'oldPassword')}
                        onChange={this.onDataChanged}
                      />
                      <InputGroupAddon addonType="append">
                        <button
                          className={`form__form-group-button${this.state.showOldPassword ? ' active' : ''}`}
                          onClick={e => this.showOldPassword(e)}
                          data-property-name="showOldPassword"
                        ><EyeIcon />
                        </button>
                      </InputGroupAddon>
                      <FormFeedback>{this.state.errors.oldPassword}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for="newPasswordInput">New Password</Label>
                    <InputGroup size="lg">
                      <Input
                        id="newPasswordInput"
                        name="newPassword"
                        type={this.state.showNewPassword ? 'text' : 'password'}
                        bsSize="lg"
                        invalid={Object.prototype.hasOwnProperty.call(this.state.errors, 'newPassword')}
                        onChange={this.onDataChanged}
                      />
                      <Popover
                        id="popover-new-password"
                        placement="left"
                        isOpen={this.state.isPopoverOpen}
                        target="newPasswordInput"
                        trigger="focus"
                        toggle={this.togglePopover}
                      >
                        <PopoverHeader>Password requirements:</PopoverHeader>
                        <PopoverBody>
                          <p>
                            at least 1 Upper Case letter<br />
                            at least 1 Lower Case letter<br />
                            at least 1 number<br />
                            at least 1 special character ($.,)(!%^*#)<br />
                            at least 8 characters in length<br />
                            New Password and Confirm Password should match
                          </p>
                        </PopoverBody>
                      </Popover>
                      <InputGroupAddon addonType="append">
                        <button
                          className={`form__form-group-button${this.state.showNewPassword ? ' active' : ''}`}
                          onClick={e => this.showNewPassword(e)}
                        ><EyeIcon />
                        </button>
                      </InputGroupAddon>
                      <FormFeedback>{this.state.errors.newPassword}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for="passwordInput">Repeat New Password</Label>
                    <InputGroup size="lg">
                      <Input
                        name="repeatPassword"
                        type={this.state.showRepeatPassword ? 'text' : 'password'}
                        bsSize="lg"
                        invalid={Object.prototype.hasOwnProperty.call(this.state.errors, 'repeatPassword')}
                        onChange={this.onDataChanged}
                      />
                      <InputGroupAddon addonType="append">
                        <button
                          className={`form__form-group-button${this.state.showRepeatPassword ? ' active' : ''}`}
                          onClick={e => this.showRepeatPassword(e)}
                        ><EyeIcon />
                        </button>
                      </InputGroupAddon>
                      <FormFeedback>{this.state.errors.repeatPassword}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </section>
                {/* BUTTONS */}
                <ButtonToolbar className="form__button-toolbar mt-5">
                  <Button
                    className="px-5 btn btn-sm"
                    type="button"
                    color="primary"
                    onClick={this.onSubmit}
                  >
                    {this.state.isLoadingData && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                    <span>Save</span>
                  </Button>
                  <Button
                    className="px-5 btn btn-sm"
                    type="button"
                    onClick={this.onCancel}
                    disabled={this.state.isLoadingData}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

AccountSettings.propTypes = {
  dispatch: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  adminUser: PropTypes.shape.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  rtl: state.rtl,
  adminUser: state.session,
});

export default withNotice(connect(mapStateToProps)(AccountSettings));
