/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { useAsyncDebounce } from 'react-table';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import queryHasFilter from '@/shared/ViewHelpers/QueryHasFilter';
import Filter from '../../../shared/components/components/app/filter';
import { NoResourceError, NoActionError } from '../../../shared/components/components/ui/error404';
import MatTableList from '../../../shared/tables/materialTable/MatTableList';
import TableCellContextMenu from '../../../shared/components/table/TableCellContextMenu';
import UserPropertyType from './components/custom/';
import EditModal from './components/custom/modals/Edit';
import ExecuteModal from './components/custom/modals/Execute';
import DeleteModal from '../../../shared/components/modals/Delete';
import { currentTimezoneAggr } from '../../../shared/helpers/Dates';
import { getDeleteMessage } from '../../../shared/helpers/Model';
import Breadcrumb from '../../../shared/components/BreadCrumbs';
import Actions from './components/custom/Actions'

const compareFilters = {
  Yes: 'Yes',
  No: 'No',
};

const ResourceAction = (props) => {
  const {
    history,
    location,
    resources,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const [enabledFilters, setEnabledFilters] = useState({
    Yes: true,
    No: true,
  });
  const [lastRunSucceedFilters, setLastRunSucceedFilters] = useState({
    Yes: true,
    No: true,
  });
  const [inProgressFilters, setInProgressFilters] = useState({
    Yes: true,
    No: true,
  });
  const [filterVisible, setFilerVisible] = useState(queryHasFilter(location.search));
  // eslint-disable-next-line no-unused-vars
  const [tag, setTag] = useState('');
  const [currentRecord, setCurrentRecord] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [executing, setExecuting] = useState({});
  const resource = resources.find(r => r.id === 'ScheduledJob');

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.searchField', searchValue);
    } else {
      search.delete('filters.searchField');
    }
    if (enabledFilters && Object.keys(enabledFilters).length > 0) {
      search.delete('filters.enabled');
      Object.keys(enabledFilters).forEach((key) => {
        if (!enabledFilters[key]) return false;
        search.append('filters.enabled', compareFilters[key]);
        return true;
      });
    }
    if (lastRunSucceedFilters && Object.keys(lastRunSucceedFilters).length > 0) {
      search.delete('filters.lastRunSucceed');
      Object.keys(lastRunSucceedFilters).forEach((key) => {
        if (!lastRunSucceedFilters[key]) return false;
        search.append('filters.lastRunSucceed', compareFilters[key]);
        return true;
      });
    }
    if (inProgressFilters && Object.keys(inProgressFilters).length > 0) {
      search.delete('filters.inProgress');
      Object.keys(inProgressFilters).forEach((key) => {
        if (!inProgressFilters[key]) return false;
        search.append('filters.inProgress', compareFilters[key]);
        return true;
      });
    }
    return search;
  };

  const setUrl = () => {
    const search = getUrlSearch();
    search.set('page', '1');
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 500);

  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
    enabledFilters,
    lastRunSucceedFilters,
    inProgressFilters,
  ]);

  if (!resource) {
    return (<NoResourceError resourceId="ScheduledJob" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="ScheduledJob" actionName="list" />);
  }
  resource.listProperties.forEach((l) => {
    // eslint-disable-next-line no-param-reassign
    if (l.name === 'nextRunDatetime') l.label = `Next Run (${currentTimezoneAggr()})`;
    // eslint-disable-next-line no-param-reassign
    if (l.name === 'startDatetime') l.label = `Start Date (${currentTimezoneAggr()})`;
    // eslint-disable-next-line no-param-reassign
    if (l.name === 'lastRunDatetime') l.label = `Last Run (${currentTimezoneAggr()})`;
  });

  const run = (identifier) => {
    setExecuting({
      ...executing,
      [identifier]: true,
    });
  };

  const setExecuted = (identifier) => {
    setExecuting({
      ...executing,
      [identifier]: false,
    });
  };

  const viewLogs = (identifier) => {
    if (identifier) {
      const win = window.open(`/resources/Log/actions/list?filters.jobIdentifier=${identifier}`, '_blank');
      win.focus();
    }
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumb
            links={[
              { title: 'Home', path: '/' },
              { title: 'Admin', path: null },
              { title: 'Jobs', path: null },
            ]}
          />
          <Card className="mb-0 pb-0">
            <CardBody className="mb-0 pb-0">
              <CardTitle>
                <Row className="pb-1">
                  <Col md="4" className="pt-1">
                    <h3>Scheduled Jobs</h3>
                  </Col>
                </Row>
                <hr className="mt-2"/>
              </CardTitle>
              <Actions
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                enabledFilters={enabledFilters}
                setEnabledFilters={setEnabledFilters}
                lastRunSucceedFilters={lastRunSucceedFilters}
                setLastRunSucceedFilters={setLastRunSucceedFilters}
                inProgressFilters={inProgressFilters}
                setInProgressFilters={setInProgressFilters}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <MatTableList
            action={action}
            resource={resource}
            setTag={setTag}
            date={new Date()}
            UserPropertyType={UserPropertyType}
            sortBy="name"
            direction="asc"
            isResizable={false}
            showTitle={false}
            columnsSettings={{
              name: {
                width: 200,
                Cell: p => (
                  <div>
                    {executing[p.row.original.params.identifier] && (
                      <div
                        className="spinner-border"
                        style={
                          {
                            width: '12px',
                            height: '12px',
                            marginLeft: '3px',
                            marginRight: '6px',
                            color: '#646777',
                          }
                        }
                      />
                    )}
                    {p.row.original.params.name}
                  </div>
                ),
              },
              description: {
                width: 300,
                Cell: p => (
                  <Tooltip title={p.row.original.params.description}>
                    <div>
                      {p.row.original.params.description?.length > 32 ? `${p.row.original.params.description.substring(0, 32)}...` : p.row.original.params.description}
                    </div>
                  </Tooltip>
                ),
              },
              startDatetime: {
                width: 150,
              },
              enabled: {
                width: 150,
              },
              lastRunDatetime: {
                width: 150,
              },
              nextRunDatetime: {
                width: 150,
              },
              frequency: {
                width: 120,
              },
              actionJob: {
                Header: '',
                accessor: () => null,
                id: 'job_actions',
                width: 50,
                Cell: (p) => {
                  const contextMenuOptions = [
                    { label: 'Edit', handler: () => { setCurrentRecord(p.row.original); setIsEdit(true); } },
                    { label: 'Job Notification Settings', handler: () => { document.location = `/job-notifications/${p.row.original.id}`; } },
                    {
                      label: executing[p.row.original.params.identifier] ? (
                        <div
                          className="spinner-border"
                          style={
                            {
                              width: '12px',
                              height: '12px',
                              marginLeft: '3px',
                              marginRight: '6px',
                              color: '#646777',
                            }
                          }
                        />
                      ) : 'Execute Job Immediately',
                      handler: () => { setCurrentRecord(p.row.original); run(p.row.original.params.identifier); },
                    },
                    { label: 'View Logs', handler: () => { viewLogs(p.row.original.params.identifier); } },
                    { label: 'Delete Job', handler: () => { setCurrentRecord(p.row.original); setIsDelete(true); } },
                  ];
                  return (
                    <div style={{ paddingTop: '8px' }} className="text-right">
                      <TableCellContextMenu options={contextMenuOptions} />
                    </div>
                  );
                },
                disableSortBy: true,
                className: 'd-flex align-items-center align-middle',
              },
            }}
            hideToolbar
            noActions
            v={2}
          />
        </Col>
      </Row>
      {action.showFilter ? (
        <Filter
          resource={resource}
          isVisible={filterVisible}
          toggleFilter={() => setFilerVisible(!filterVisible)}
        />
      ) : ''}
      <EditModal record={currentRecord} isOpen={isEdit} setIsOpen={setIsEdit} />
      <ExecuteModal identifier={currentRecord?.params?.identifier} run={executing[currentRecord?.params?.identifier]} setExecuted={setExecuted} />
      <DeleteModal id={currentRecord?.id} isOpen={isDelete} setIsOpen={setIsDelete} message={getDeleteMessage(currentRecord?.params)} resource={resource} />
    </Container>
  );
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
