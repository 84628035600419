/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { unflatten } from 'flat';
import _ from 'lodash';
import { axios } from 'ApiClient';
import { Spinner } from 'reactstrap';

export default React.memo((props) => {
  const record = unflatten(props.record);
  const [hsData, setHsData] = useState(null);
  const [loading, setLoading] = useState(false);
  let retriesCount = 0;

  const fetchData = () => {
    if (record.params.hsUserId && retriesCount < 4) {
      setLoading(true);
      axios.get(`/api/portal/prospects/get-hs-user-data?hsUserId=${record.params.hsUserId}`).then((result) => {
        if (result.data.success && result.data.data) {
          setLoading(false);
          setHsData(result.data.data);
        } else {
          retriesCount += 1;
          setTimeout(fetchData, 5000);
        }
      });
    } else setLoading(false);
  };

  useEffect(async () => {
    fetchData();
  }, [record.id]);

  return (
    <div>
      {record.params.hsUserId && hsData ? (
        `${hsData.firstname ? `${hsData.firstname} ` : ''}${hsData.lastname ? `${hsData.lastname} ` : ''}`
      ) : (<Spinner color="primary" size="sm" style={loading ? {} : { display: 'none' }} />)}
      {record.params.hsUserId && (
        <a
          title="HubSpot Contact"
          target="_blank"
          rel="noreferrer"
          href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.params.hsUserId}/`}
        >
          <i className="fab fa-hubspot" style={{ fontSize: '1.4em', marginLeft: '5px' }} />
        </a>
      )}
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));
