
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
// import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import formater from '../../../../shared/helpers/WVFormatter';
import TableCellContextMenu from '../../../../shared/components/table/TableCellContextMenu';
import { showModalById } from '../../../../redux/reducers/TableReducer';
import { modalId } from '../EOMReport2/Modal';

export default () => {
  const dispatch = useDispatch();
  return [
    {
      Header: 'Brand',
      accessor: p => `${p?.offer?.advertiser.companyName || ''}`,
      id: 'brand',
      Cell: p => useMemo(() => (
        <div style={{ width: '120px' }}>
          {p.value}
        </div>
      ), [p.row.original]),
    },
    {
      Header: 'Offer',
      accessor: p => `${p?.offer?.name || ''}`,
      id: 'offer',
      Cell: p => useMemo(() => (
        <div style={{ width: '120px' }}>
          {p.value}
        </div>
      ), [p.row.original]),
    },
    {
      Header: 'Deal Type',
      accessor: p => p.deal?.dealTerms.type_of_deal || '',
      Cell: p => (
        <div>
          <span>{p.value}</span>
          {p.row.original.deal ? (
            <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original?.deal.hs_object_id}/`} target="_blank" rel="noreferrer">
              <Tooltip title="Open Hubspot Deal">
                <IconButton size="small">
                  <i className="fab fa-hubspot" style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </a>) : null}
        </div>
      ),
      id: 'Deal Type',
      width: 120,
    },
    {
      Header: 'Gross Revenue',
      accessor: deal => (deal.totalRevenue || 0),
      id: 'revenue',
      Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
      Footer: (info) => {
        const totalAge = useMemo(
          () => info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
          [info.rows],
        );
        return <span>{formater.formatCurrency(totalAge)}</span>;
      },
      width: 120,
    },
    {
      Header: 'Gross Profit',
      accessor: deal => (deal.grossProfit || 0),
      id: 'profit',
      Cell: ({ value }) => useMemo(() => formater.formatCurrency(value || 0), [value]),
      Footer: (info) => {
        const totalAge = useMemo(
          () => info.rows.reduce((sum, row) => Number(row.values.profit) + sum, 0),
          [info.rows],
        );
        return <span>{formater.formatCurrency(totalAge)}</span>;
      },
      width: 120,
    },
    {
      Header: 'Revenue Conversions',
      accessor: p => Number(p.conversionsArray?.length || 0) + Number(p.cpmConversions?.length || 0),
      id: 'conversions',
      Cell: ({ value }) => useMemo(() => formater.formatIntNumber(value || 0), [value]),
      width: 200,
      Footer: (info) => {
        const totalAge = useMemo(
          () => info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
          [info.rows],
        );
        return <span>{formater.formatIntNumber(totalAge)}</span>;
      },
    },
    {
      Header: 'Clicks',
      accessor: p => (p?.report?.clicks || 0),
      id: 'clicks',
      Cell: ({ value }) => useMemo(() => formater.formatIntNumber(value || 0), [value]),
      width: 120,
    },
    {
      Header: 'Account Manager',
      accessor: p => ((`${p.adminAF?.firstName || ''} ${p.adminAF?.lastName || ''}`)),
      id: 'owner',
      width: 200,
    },
    {
      Header: '',
      accessor: '_id',
      // eslint-disable-next-line react/prop-types
      Cell: p => (
        <div style={{ textAlign: 'right' }}><TableCellContextMenu
          options={[
            { label: 'Display Calculation', handler: () => { dispatch(showModalById(modalId, { record: p.row.original })); } },
            {
              label: 'Open Hubspot Deal',
              handler: () => {
                const win = window.open(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original?.deal.hs_object_id}/`, '_blank');
                win.focus();
              },
            },
          ]}
        />
        </div>),
      disableGlobalFilter: true,
      disableSortBy: true,
      minWidth: 50,
      maxWidth: 50,
    },
  ];
};

