import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CopyToClipboardLeft from '../../../../shared/components/CopyToClipboardLeft';
import { CopyIcon } from '../../../../shared/helpers/Icons';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

const truncateStyle = {
  maxWidth: '305px',
  fontSize: '14px',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
};

const VideoInList = React.memo(({ record, filtersForUrls }) => {
  return (
    <div
      className="d-flex"
      style={{ maxWidth: '350px' }}
    >
      <div
        className="block-in-cell"
        style={{ display: 'inline' }}
      >
        <Tooltip
          title={record.title}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="ml-0"
            style={truncateStyle}
            href={record.mediaUrl}
          >
            {record.title}
          </a>
        </Tooltip>
      </div>
      <div
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '5px',
        }}
      >
        <CopyToClipboardLeft
          value={record.mediaUrl}
          icon={(<CopyIcon />)}
          className="text-blue"
          title="Copy Video URL"
        />
      </div>
      <div
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '5px',
          marginTop: '-2px',
        }}
      >
        <a
          title="Open Video Comments Analysis Report"
          target="_blank"
          rel="noopener noreferrer"
          className="ml-0"
          style={{ fontSize: '16px' }}
          href={`/reports/video-comments-analysis?mediaUrl=${record.mediaUrl}${filtersForUrls}`}
        >
          <i className="fas fa-external-link-alt"/>
        </a>
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

VideoInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  filtersForUrls: PropTypes.string,
};

VideoInList.defaultProps = {
  filtersForUrls: '',
};

export default VideoInList;
