/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const advertiserReducer = createSlice({
  name: 'advertiser',
  initialState: {
    loading: {
      fillAdvertisers: false,
    },
    logs: {
      advertisers: {
        request: [],
        added: [],
        removed: [],
        errors: [],
      },
      offers: {
        request: [],
        added: [],
        removed: [],
        errors: [],
      },
    },
    showUpdateLogoDialog: false,
    brandLogo: {
      id: null,
      logoUrl: '',
      logoSquareUrl: '',
    },
    error: null,
    lastFetched: new Date(),
  },
  reducers: {
    getFillAdvertiserStart(state) {
      state.loading = {
        ...state.loading,
        fillAdvertisers: true,
      };
      state.logs = {
        ...state.logs,
        advertisers: {
          request: [],
          added: [],
          removed: [],
          errors: [],
        },
        offers: {
          request: [],
          added: [],
          removed: [],
          errors: [],
        },
      };
    },
    getFillAdvertiserError(state) {
      state.loading = {
        ...state.loading,
        fillAdvertisers: false,
      };
    },
    getFillAdvertiserSuccess(state, action) {
      const { advertisers, offers } = action.payload;
      state.loading = {
        ...state.loading,
        fillAdvertisers: false,
      };
      state.logs = {
        ...state.logs,
        advertisers,
        offers,
      };
      state.error = null;
    },
    saveShowUpdateLogoDialog(state, action) {
      state.showUpdateLogoDialog = action.payload.show;
      state.brandLogo = action.payload.brandLogo;
      if (!action.payload.show) {
        state.brandLogo = {};
      }
    },
  },
});
const fillAdvertisersApi = async () => {
  const api = new ApiClient();
  return api.client.post('/api/portal/finance/post-fill-advertiser');
};

export const {
  getFillAdvertiserStart,
  getFillAdvertiserError,
  getFillAdvertiserSuccess,
  saveShowUpdateLogoDialog,
} = advertiserReducer.actions;

export const fillAdvertisers = callback => async (dispatch) => {
  try {
    dispatch(getFillAdvertiserStart());
    const response = await fillAdvertisersApi();
    dispatch(getFillAdvertiserSuccess({
      advertisers: response.data.advertisersLog,
      offers: response.data.offersLog,
    }));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(getFillAdvertiserError(err));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const showUpdateLogoDialog = brandLogo => async (dispatch) => {
  dispatch(saveShowUpdateLogoDialog({ show: true, brandLogo }));
};

export const closeUpdateLogoDialog = () => async (dispatch) => {
  dispatch(saveShowUpdateLogoDialog({ show: false }));
};


export default advertiserReducer.reducer;
