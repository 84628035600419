/* eslint-disable no-param-reassign */
const {
  /* dealTypes, */
  getAmountByConversion,
  getTotalPayment,
  calculateTotalRevenue,
  calculateBillRevenue,
  calculateConversionsCosts,
  calculateBillCosts,
  getIssueMessage,
} = require('./ReportCalculation');

const calculateForDeal = (deal) => {
  const a = (deal.remainingRevenueConversionsAmount + deal.payableRevenueAmount + (Number(deal.billRevenue || 0))) - (deal.payablePayoutAmount) -
  (deal.totalPayoutWithMulligan !== 0 ? (deal.remainingPayoutConversionsAmount + deal.billPayout) : 0);
  return a;
};
const calculateRevenueForDeal = (deal) => {
  const a = deal.remainingRevenueConversionsAmount + deal.payableRevenueAmount + (Number(deal.billRevenue || 0));
  return a;
};

const calculateForHODeal = (deal) => {
  const a = (deal.remainingRevenueConversionsAmount + deal.payableRevenueAmount) - deal.payablePayoutAmount -
  (deal.totalPayoutWithMulligan !== 0 ? (deal.remainingPayoutConversionsAmount) : 0);
  return a;
};
const calculateRevenueHOForDeal = (deal) => {
  const a = deal.remainingRevenueConversionsAmount + deal.payableRevenueAmount;
  return a;
};

const groupByDealResp = (items) => {
  const deals = {};
  Object.values(items).forEach((item) => {
    // console.log(item);
    Object.values(item).forEach(({
      deal,
      conversionsArray,
      report,
      offer,
      influencer,
      prospect,
      admin,
      adminAF,
      invoicePayment,
      payable,
      cpmConversionsCount,
      cpmConversions,
    }) => {
      if (!deal) {
        deals[influencer?._id] = {
          deal: null,
          conversionsArray,
          report: {
            revenue: Number(report?.Stat?.revenue || 0),
            payout: Number(report?.Stat?.payout || 0),
            clicks: Number(report?.Stat?.clicks || 0),
          },
          offer,
          influencer,
          admin,
          adminAF,
          invoicePayout: 0,
          payables: [],
          conversionsForCalculation: [],
          cpmConversionsCount: cpmConversionsCount || 0,
          cpmConversions: cpmConversions || [],
        };
        return true;
      }
      if (!deals[deal._id]) {
        deals[deal._id] = {
          deal,
          conversionsArray,
          report: {
            revenue: Number(report?.Stat?.revenue || 0),
            payout: Number(report?.Stat?.payout || 0),
            clicks: Number(report?.Stat?.clicks || 0),
          },
          offer,
          influencer,
          prospect,
          admin,
          adminAF,
          invoicePayout: 0,
          payables: [],
          conversionsForCalculation: [],
          cpmConversions: cpmConversions || [],
          cpmConversionsCount: cpmConversionsCount || 0,
        };
      } else {
        deals[deal._id].conversionsArray = deals[deal._id].conversionsArray.concat(conversionsArray || []);
        deals[deal._id].cpmConversions = deals[deal._id].cpmConversions.concat(cpmConversions || []);
        deals[deal._id].cpmConversionsCount += cpmConversionsCount || 0;
        deals[deal._id].report.revenue += Number(report?.Stat?.revenue || 0);
        deals[deal._id].report.payout += Number(report?.Stat?.payout || 0);
        deals[deal._id].report.clicks += Number(report?.Stat?.clicks || 0);
        deals[deal._id].offer = offer?._id ? offer : deals[deal._id].offer;
        deals[deal._id].admin = deals[deal._id].admin || admin;
        deals[deal._id].adminAF = deals[deal._id].adminAF || adminAF;
      }
      // console.log(deals[deal._id].cpmConversionsCount, 'CPM CONVERSIONS1', deals[deal._id].cpmConversions?.length);
      if (payable) deals[deal._id].payables.push(payable);
      deals[deal._id].conversionsForCalculation = deals[deal._id].conversionsForCalculation.concat(payable ? [] : conversionsArray);
      // console.log(deal);
      if (deal.invoice) {
        const totalPaidForInvoice = getTotalPayment(deal.invoice?.payments || []);
        const diff = Number(deal.dealTerms.guaranteed_payment_contract_amount || 0) - (totalPaidForInvoice || 0);
        let totalPayoutConversion = 0;
        conversionsArray.forEach(({ payout }) => {
          totalPayoutConversion += getAmountByConversion(deal, payout);
        });

        // console.log(diff, deal.dealname);
        // if (deal.dealname === 'Alvini Frazier - Aura November 2022') console.log(deal);
        if (invoicePayment) {
          // console.log([deals[deal._id].report.payout, Number(invoicePayment?.amount || 0), (deals[deal._id].report.payout - Number(invoicePayment?.amount || 0))]);
          // invoicePayout how many amount need paid this month only for conversions
          deals[deal._id].invoicePayout += Number(totalPayoutConversion || 0) > Number(invoicePayment?.amount || 0) ? (Number(totalPayoutConversion || 0) - Number(invoicePayment?.amount || 0)) : 0;
        } else {
          // console.log(diff, Number(totalPayoutConversion || 0));
          deals[deal._id].invoicePayout += diff >= Number(totalPayoutConversion || 0) ? 0 : Number(totalPayoutConversion || 0) - diff;
          // console.log(deals[deal._id].invoicePayout);
        }
        // console.log([Number(totalPayoutConversion || 0), deals[deal._id].invoicePayout], 'INVOICE PAYOUR');
      }
      return true;
    });
  });
  return deals;
};

const convertDealsForCreators = (deals, startDate, endDate) => {
  Object.values(deals).forEach(({
    deal,
    conversionsArray,
    report,
    invoicePayout,
    payables,
  }) => {
    if (!deal) return false;
    deals[deal._id].conversionPayout = calculateConversionsCosts({ deal, conversions: conversionsArray || [], invoicePayout });
    deals[deal._id].billPayout = calculateBillCosts({ deal, startDate, endDate });
    deals[deal._id].billRevenue = calculateBillRevenue({ deal, startDate, endDate });
    deals[deal._id].totalRevenue = calculateTotalRevenue({
      deal, report, startDate, endDate,
    });
    deals[deal._id].totalPayoutWithMulligan = deal.dealTerms.mulligan_ && deals[deal._id].totalRevenue < deals[deal._id].totalPayout ? 0 : deals[deal._id].conversionPayout + deals[deal._id].billPayout;
    deals[deal._id].grossProfit = deals[deal._id].totalRevenue - deals[deal._id].totalPayoutWithMulligan;
    deals[deal._id].message = null; // getIssueMessage({ deal });
    deals[deal._id].payablePayoutAmount = payables.reduce((accumulator, currentValue) => {
      // eslint-disable-next-line
      accumulator += Number(currentValue.ilPayoutAmount || 0);
      return accumulator;
    }, 0);
    deals[deal._id].totalPayout = deals[deal._id].conversionPayout + deals[deal._id].billPayout;
    deals[deal._id].remainingRevenueConversionsAmount = deals[deal._id].conversionsForCalculation.reduce((accumulator, currentValue) => {
      // eslint-disable-next-line
      accumulator += Number(currentValue.revenue || 0);
      return accumulator;
    }, 0);
    deals[deal._id].payableRevenueAmount = payables.reduce((accumulator, currentValue) => {
      // eslint-disable-next-line
      accumulator += Number(currentValue.revenueAmount || 0);
      return accumulator;
    }, 0);
    deals[deal._id].remainingPayoutConversionsAmount = deals[deal._id].conversionsForCalculation.reduce((accumulator, currentValue) => {
      // eslint-disable-next-line
      accumulator += getAmountByConversion(deal, Number(currentValue.payout || 0));
      return accumulator;
    }, 0);
    deals[deal._id].clearRemainingPayoutConversionsAmount = deals[deal._id].conversionsForCalculation.reduce((accumulator, currentValue) => {
      // eslint-disable-next-line
      accumulator += getAmountByConversion(deal, Number(currentValue.payout || 0));
      return accumulator;
    }, 0);

    if (['Hybrid'].includes(deal.dealTerms.type_of_deal) && deal.dealTerms.guaranteed_payment_recoverable === 'Yes') {
      deals[deal._id].remainingPayoutConversionsAmount = invoicePayout - deals[deal._id].payablePayoutAmount;
    }
    deals[deal._id].totalPayoutWithMulligan = deal.dealTerms.mulligan_
      && !deals[deal._id].payablePayoutAmount
      && deals[deal._id].totalRevenue < (deals[deal._id].remainingPayoutConversionsAmount + deals[deal._id].billPayout) ? 0 : deals[deal._id].conversionPayout + deals[deal._id].billPayout;
    deals[deal._id].grossProfit = deals[deal._id].totalRevenue - deals[deal._id].totalPayoutWithMulligan;
    // deals[deal._id].invoicePayout = invoicePayout;
    return true;
  });
};

const groupByCreator = (dealsToFetch) => {
  const influencers = {};
  dealsToFetch.forEach((deal) => {
    if (!deal.influencer) return false;
    if (!influencers[deal.influencer?.affiliateDataObject?.tune_id]) {
      influencers[deal.influencer.affiliateDataObject.tune_id] = {
        hsContactVid: deal.influencer?.hsContactVid,
        deals: [],
        tuneId: deal.influencer.affiliateDataObject.tune_id,
        influencer: deal.influencer,
        prospect: deal.prospect,
        clicks: 0,
        conversions: 0,
        grossRevenue: 0,
        grossProfit: 0,
        admins: [],
        adminsAF: [],
        countMediaContent: 0,
        grossRevenueHO: 0,
        grossProfitHO: 0,
        cpmConversionsCount: 0,
        cpmConversions: [],
      };
    }
    influencers[deal.influencer.affiliateDataObject.tune_id].grossRevenue += deal.deal ? calculateRevenueForDeal(deal) : 0;
    influencers[deal.influencer.affiliateDataObject.tune_id].grossProfit += deal.deal ? calculateForDeal(deal) : 0;
    influencers[deal.influencer.affiliateDataObject.tune_id].grossRevenueHO += deal.deal ? calculateRevenueHOForDeal(deal) : 0;
    influencers[deal.influencer.affiliateDataObject.tune_id].grossProfitHO += deal.deal ? calculateForHODeal(deal) : 0;
    influencers[deal.influencer.affiliateDataObject.tune_id].clicks += Number(deal.report?.clicks || 0);
    influencers[deal.influencer.affiliateDataObject.tune_id].conversions += Number(deal.conversionsArray?.length || 0);
    influencers[deal.influencer.affiliateDataObject.tune_id].deals.push(deal);
    // console.log(deal.cpmConversionsCount, 'CPM CONVERSIONS', deal.cpmConversions.length);
    influencers[deal.influencer.affiliateDataObject.tune_id].cpmConversionsCount += Number(deal.cpmConversionsCount || 0);
    influencers[deal.influencer.affiliateDataObject.tune_id].cpmConversions = influencers[deal.influencer.affiliateDataObject.tune_id].cpmConversions.concat(deal.cpmConversions || []);
    if (deal?.admin && !influencers[deal.influencer.affiliateDataObject.tune_id].admins.find(a => a._id === deal?.admin?._id)) {
      influencers[deal.influencer.affiliateDataObject.tune_id].admins.push(deal.admin);
    }
    if (deal?.adminAF && !influencers[deal.influencer.affiliateDataObject.tune_id].adminsAF.find(a => a._id === deal?.adminAF?._id)) {
      influencers[deal.influencer.affiliateDataObject.tune_id].adminsAF.push(deal.adminAF);
    }
    return true;
  });

  return influencers;
};

const groupByDate = (dealsToFetch) => {
  // eslint-disable-next-line import/no-dynamic-require, global-require
  const moment = global?.MODULES ? require(`${global?.MODULES || ''}moment-timezone`) : require('moment-timezone');
  const dates = {};
  const fillStatValue = (key, { payout, revenue, conversionsCount }) => {
    if (!dates[key]) {
      dates[key] = {
        payout: 0, revenue: 0, conversionsCount: 0, profit: 0,
      };
    }
    if (payout) {
      dates[key].payout += Number(payout);
      dates[key].profit -= Number(payout);
    }
    if (revenue) {
      dates[key].revenue += Number(revenue);
      dates[key].profit += Number(revenue);
    }
    if (conversionsCount) dates[key].conversionsCount += Number(conversionsCount);
  };

  dealsToFetch.forEach((deal) => {
    deal.conversionsArray.forEach((conversion) => {
      const momentDate = moment.tz(conversion.datetime, 'America/New_York');
      const key = momentDate.format('YYYY-MM-DD');
      fillStatValue(key, { ...conversion, conversionsCount: 1 });
    });
    if (deal.billPayout && deal.deal.brandApprovedDate && deal.deal.dealTerms.type_of_deal !== 'Bonus') {
      const momentDate = moment.utc(deal.deal.brandApprovedDate);
      const key = momentDate.format('YYYY-MM-DD');
      const payout = deal.deal.dealTerms.mulligan_ && !deal.payablePayoutAmount && deal.totalRevenue < (deal.remainingPayoutConversionsAmount + deal.billPayout) ? 0 : deal.billPayout;
      fillStatValue(key, { payout });
    }
    if (deal.billPayout && deal.deal.dealTerms.content_release_date && deal.deal.dealTerms.type_of_deal === 'Bonus') {
      // console.log(moment.utc(deal.deal.brandApprovedDate).format('YYYY-MM-DD'));
      const momentDate = moment.utc(deal.deal.dealTerms.content_release_date);
      const key = momentDate.format('YYYY-MM-DD');
      // console.log(key, deal.deal);
      // console.log(key);
      const payout = deal.deal.dealTerms.mulligan_ && !deal.payablePayoutAmount && deal.totalRevenue < (deal.remainingPayoutConversionsAmount + deal.billPayout) ? 0 : deal.billPayout;

      fillStatValue(key, { payout });
    }
    if (deal.billRevenue && deal.deal.brandApprovedDate) {
      const momentDate = moment.utc(deal.deal.brandApprovedDate);
      const key = momentDate.format('YYYY-MM-DD');
      fillStatValue(key, { revenue: deal.billRevenue });
    }
    deal.cpmConversions.forEach((conversion) => {
      const momentDate = moment(conversion.date).tz('America/New_York');
      const key = momentDate.format('YYYY-MM-DD');
      fillStatValue(key, { conversionsCount: 1 });
    });

    return true;
  });

  return dates;
};

const calculateGRandGPDeals = (items, startDate, endDate) => {
  const deals = {};
  Object.values(items).forEach((item) => {
    Object.values(item).forEach(({
      deal,
      conversionsArray,
      report,
      offer,
      influencer,
      admin,
      adminAF,
      invoicePayment,
    }) => {
      if (!deals[deal._id]) {
        deals[deal._id] = {
          deal,
          conversionsArray,
          report: {
            revenue: Number(report?.Stat?.revenue || 0),
            payout: Number(report?.Stat?.payout || 0),
          },
          offer,
          influencer,
          admin,
          adminAF,
          invoicePayout: 0,
        };
      } else {
        deals[deal._id].conversionsArray = deals[deal._id].conversionsArray.concat(conversionsArray);
        deals[deal._id].report.revenue += Number(report?.Stat?.revenue || 0);
        deals[deal._id].report.payout += Number(report?.Stat?.payout || 0);
      }
      if (deal.invoice) {
        const totalPaidForInvoice = getTotalPayment(deal.invoice?.payments || []);
        const diff = Number(deal.dealTerms.guaranteed_payment_contract_amount || 0) - (totalPaidForInvoice || 0);
        // console.log(diff);
        if (invoicePayment) {
          // console.log([deals[deal._id].report.payout, Number(invoicePayment?.amount || 0), (deals[deal._id].report.payout - Number(invoicePayment?.amount || 0))]);
          deals[deal._id].invoicePayout += deals[deal._id].report.payout > Number(invoicePayment?.amount || 0) ? (deals[deal._id].report.payout - Number(invoicePayment?.amount || 0)) : 0;
        } else {
          deals[deal._id].invoicePayout += diff >= deals[deal._id].report.payout ? 0 : deals[deal._id].report.payout - diff;
        }
      }
    });
  });
  Object.values(deals).forEach(({
    deal,
    conversionsArray,
    report,
    invoicePayout,
  }) => {
    deals[deal._id].conversionPayout = calculateConversionsCosts({ deal, conversions: conversionsArray || [], invoicePayout });
    deals[deal._id].billPayout = calculateBillCosts({ deal, startDate, endDate });
    deals[deal._id].billRevenue = calculateBillRevenue({ deal, startDate, endDate });
    deals[deal._id].totalRevenue = calculateTotalRevenue({
      deal, report, startDate, endDate,
    });
    deals[deal._id].totalPayout = deals[deal._id].conversionPayout + deals[deal._id].billPayout;
    deals[deal._id].totalPayoutWithMulligan = deal.dealTerms.mulligan_ && deals[deal._id].totalRevenue < deals[deal._id].totalPayout ? 0 : deals[deal._id].conversionPayout + deals[deal._id].billPayout;
    deals[deal._id].grossProfit = deals[deal._id].totalRevenue - deals[deal._id].totalPayoutWithMulligan;
  });
  return deals;
};


const getWarningMessageArray = (validationResult, startDate, endDate) => {
  // eslint-disable-next-line import/no-dynamic-require, global-require
  const moment = global?.MODULES ? require(`${global?.MODULES || ''}moment-timezone`) : require('moment-timezone');
  let message = '';
  let url = 'Something wrong';
  if (!validationResult.influencer) {
    message = 'Invalid Influencer';
    url = `${global.gConfig ? global.gConfig.baseUrl : ''}`;
  }
  if (!validationResult.contact && validationResult.invalidContact === true) {
    message = 'Missing HubSpot Contact';
    url = `${global.gConfig ? global.gConfig.baseUrl : ''}/influencer/profile/${validationResult.influencer._id}/`;
  }
  const portalId = global?.gConfig?.hubspot?.portalId ? global.gConfig.hubspot.portalId : window.constants.hubspot.portalId;
  const closeDate = moment.utc(validationResult.deal?.brandApprovedDate);
  if (validationResult.deal && !validationResult.deal.brandApprovedDate) {
    url = `https://app.hubspot.com/contacts/${portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'No Brand Approved Date';
  }
  if (validationResult.deal && validationResult.deal.brandApprovedDate && closeDate > moment.utc(endDate).endOf('month')) {
    url = `https://app.hubspot.com/contacts/${portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Brand Approved Date in the future';
  }
  if (message === '' && validationResult.deal) {
    if (!validationResult.deal.dealTerms.type_of_deal) {
      url = `https://app.hubspot.com/contacts/${portalId}/deal/${validationResult.deal.hs_object_id}/`;
      message = 'Incomplete Deal Terms';
    } else if (!validationResult.deal.brandApprovedDate) {
      url = `https://app.hubspot.com/contacts/${portalId}/deal/${validationResult.deal.hs_object_id}/`;
      message = 'Missing Brand Approved Date';
    } else {
      url = `https://app.hubspot.com/contacts/${portalId}/deal/${validationResult.deal.hs_object_id}/`;
      message = 'Invalid Brand Approved Date With Current Deal Status';
    }
  }
  /* if (message === '' && validationResult.deal && !validationResult.deal.dealTerms.type_of_deal) {
    url = `https://app.hubspot.com/contacts/${portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Incomplete Deal Terms';
  }
  if (message === '' && validationResult.deal && validationResult.deal.dealTerms.type_of_deal) {
    url = `https://app.hubspot.com/contacts/${portalId}/deal/${validationResult.deal.hs_object_id}/`;
    message = 'Brand Approved Date in the future or Invalid status';
  } */
  if (message === '' && validationResult.unassignedDeal) {
    url = `https://app.hubspot.com/contacts/${portalId}/deal/${validationResult.unassignedDeal.hs_object_id}/`;
    message = 'Incomplete Offer';
  }
  if (message === '' && validationResult.contact && validationResult.anotherOfferDeal) {
    url = `https://app.hubspot.com/contacts/${portalId}/contact/${validationResult.contact.hs_object_id}/`;
    message = 'Missing Deal or Possible Offer Mismatch';
  }
  if (message === '' && validationResult.contact && !validationResult.deal) {
    url = `https://app.hubspot.com/contacts/${portalId}/contact/${validationResult.contact.hs_object_id}/`;
    message = 'Missing Deal';
  }
  return [message, url];
  // return (<span>{message} {url ? (<a href={url} target="_blank" rel="noreferrer">Click here to correct issue</a>) : null } {validationResult.offerName ? `Offer Name: ${validationResult.offerName}` : null}</span>);
};

const getWarningMessage = (validationResult, startDate, endDate) => {
  // console.log('start warning message');
  const [message, url] = getWarningMessageArray(validationResult, startDate, endDate);
  return { validationResult, message, url };
  // return (<span>{message} {url ? (<a href={url} target="_blank" rel="noreferrer">Click here to correct issue</a>) : null } {validationResult.offerName ? `Offer Name: ${validationResult.offerName}` : null}</span>);
};

const convertvalidationToMessages = (validationResults, startDate, endDate) => {
  const messages = [];
  validationResults.forEach((vr) => {
    messages.push(getWarningMessage(vr, startDate, endDate));
  });
  return messages;
};


module.exports = {
  convertDealsForCreators,
  groupByDealResp,
  groupByCreator,
  calculateRevenueForDeal,
  calculateForDeal,
  calculateGRandGPDeals,
  convertvalidationToMessages,
  getWarningMessage,
  getIssueMessage,
  groupByDate,
};
