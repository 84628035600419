import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import queryHasFilter from '@/shared/ViewHelpers/QueryHasFilter';

import ActionHeader from '../../../../shared/components/components/app/action-header';
import Filter from '../../../../shared/components/components/app/filter';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
import withNotice from '../../../App/store/with-notice';

import UserPropertyType from './components/custom';

class ResourceAction extends React.Component {
  static propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      filterVisible: queryHasFilter(props.location.search),
      tag: '',
    };
  }
  render() {
    // const [filterVisible, setFilerVisible] = useState();
    // const [tag, setTag] = useState('');
    const {
      resources,
    } = this.props;

    const {
      filterVisible,
      tag,
    } = this.state;
    const resource = resources.find(r => r.id === 'AccountsReceivable');
    if (!resource) {
      return (<NoResourceError resourceId="AccountsReceivable" />);
    }
    const action = resource.resourceActions.find(r => r.name === 'list');
    if (!action) {
      return (<NoActionError resourceId="AccountsReceivable" actionName="list" />);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs resource={resource} actionName="list" />
            <ActionHeader
              resource={resource}
              action={action}
              tag={tag}
              toggleFilter={() => this.setState({ filterVisible: !filterVisible })}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <MatTableList
              action={action}
              resource={resource}
              setTag={state => this.setState({ tag: state })}
              date={new Date()}
              UserPropertyType={UserPropertyType}
              sortBy="startDate"
              direction="asc"
              columnsSettings={{
                startDate: {
                  width: 150,
                },
                endDate: {
                  width: 150,
                },
                name: {
                  width: 150,
                },
                description: {
                  width: 150,
                },
                generalLedger: {
                  width: 120,
                },
                payables: {
                  width: 400,
                },
              }}
              v={2}
            />
          </Col>
        </Row>
        {action.showFilter ? (
          <Filter
            resource={resource}
            isVisible={filterVisible}
            toggleFilter={() => this.setState({ filterVisible: !filterVisible })}
          />
        ) : ''}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default withNotice(connect(mapStateToProps)(ResourceAction));
